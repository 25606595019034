import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import * as RoleService from "../../service/roleRightMgt";
import { Formik } from "formik";
import * as Yup from "yup";
import AuditLog from "../audit/auditlog";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  Tabs,
  Tab,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { setDisplayLoader } from "../redux/piDataStore";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field contain at least two characters")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "*Insert only normal character"
    )
    .required("*Role name is required"),
});

const AddRole = (props) => {
  const { showForm, edit, onClose, editRole, onAddCallBack } = props;
  const nameForm = "Add Role";
  const [roleData, setRoleData] = useState({
    id: 0,
    name: "",
    is_active: true,
  });
  const [allSelect, setAllSelect] = useState(false);
  const [isRoleNameExist, setIsRoleNameExist] = useState("");
  const [moduleData, setModuleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedDataId, setCheckedDataId] = useState([]);
  const [removeCheckedDataId, setRemoveCheckedDataId] = useState([]);
  const [valuePermissionData, setvaluePermissionData] = useState([]);
  const [search, setSearchStr] = useState("");
  const [checkid, setCheckid] = useState([]);
  const [permissionData, setPermissionData] = useState({
    permission_ids: [],
    group_id: 0,
  });

  const dispatch = useDispatch();

  const saveRole = (data) => {
    if (checkedDataId.length > 0) {
      setIsLoading(true);
      RoleService.saveRoleRights(data)
        .then((response) => {
          onAddCallBack(response);
          let groupId = response.data.dataList;
          saveRightsPermission(groupId.id);
          setIsLoading(false);
          handleData();
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      toast.warn("*Please assign atleast one permission.");
    }
  };

  const isRoleExists = (e) => {
    let data = {
      name: e.target.value,
    };
    RoleService.isRoleExist(data).then((res) => {
      if (res.data.dataList.is_exist === true) {
        toast.error("Role Name is Already Exist");
      }
    });
  };

  const getPermissionData = (searchstr) => {
    dispatch(setDisplayLoader("Display"));
    RoleService.getPermissionValue(searchstr)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let perValue = [];
        let res = response?.data?.dataList?.result;
        for (let dd in res) {
          perValue.push(res[dd]);
        }
        // console.log("perValue",perValue)
        setModuleData(perValue);
        if (edit === true) {
          updateUserPermission(editRole.id);
        }
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.detail)
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updatePermissionValue = (e, value) => {
    // value.checkData=e.target.checked
    let data = moduleData;
    data?.map((res) => {
      res?.map((x) => {
        x?.permissions?.map((p) => {
          if (p.id == value.id) {
            p.checkData = e.target.checked;
          }
        });
        x?.child?.map((z) => {
          z?.permissions?.map((y) => {
            if (y.id == value.id) {
              y.checkData = e.target.checked;
            }
          });
        });
      });
    });
    setModuleData(data);
    setRemoveCheckedDataId([...removeCheckedDataId, value.id]);
    if (e.target.checked) {
      setCheckedDataId([...checkedDataId, value.id]);
    } else {
      const index = checkedDataId.indexOf(value.id);
      if (index > -1) {
        checkedDataId.splice(index, 1);
      }
    }
  };

  const updateUserPermission = (values) => {
    let data = { group_id: values };
    RoleService.saveUserDataId(data)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          // console.log("Permissiondata",response.data.dataList.permission_ids)
          setvaluePermissionData(response.data.dataList.permission_ids);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveRightsPermission = (value) => {
    if (value !== null && checkedDataId.length > 0) {
      permissionData.group_id = value;
      permissionData.permission_ids = checkedDataId;
      if (checkedDataId.length > 0) {
        RoleService.saveRightsPermission(permissionData).then((response) => {
          toast.success(response.data.message);
        });
      }
    }
  };

  const updateGroup = (data) => {
    RoleService.updateGroup(data)
      .then((response) => {
        if (removeCheckedDataId.length > 0) {
          removePermissionsData(response?.data?.dataList?.id);
        }
        let groupId = response.data.dataList;
        if (checkedDataId.length > 0) {
          saveRightsPermission(groupId.id);
        }
        onAddCallBack(response);
        toast.success(response.data.message);
        handleData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const removePermissionsData = (value) => {
    setIsLoading(true);
    const set1 = new Set();
    let handleRemove = [];
    removeCheckedDataId.forEach((ele) => {
      set1.add(ele);
    });
    let aa = [...set1];
    valuePermissionData.forEach((value) => {
      if (aa.includes(value)) {
        handleRemove.push(value);
      }
    });
    permissionData.group_id = value;
    permissionData.permission_ids = handleRemove;
    if (handleRemove.length > 0) {
      RoleService.removePermissions(permissionData)
        .then((response) => {
          toast.success(response.data.message);
          handleData();
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const handlePermission = (values) => {
    let saveData = {
      id: roleData.id,
      name: values.name,
      is_active: roleData.is_active,
    };
    if (values.id) {
      updateGroup(saveData);
    } else {
      saveRole(saveData);
    }
  };

  useEffect(() => {
    getPermissionData();
  }, []);

  const handleData = () => {
    onClose();
  };

  useEffect(() => {
    let data = moduleData;
    let count = 0;
    data?.map((x) => {
      x.map((y) => {
        valuePermissionData?.map((c) => {
          y.permissions
            .filter((someobject) => someobject.id == c)
            .forEach((v) => {
              count += 1;
              v["checkData"] = true;
            });
        });
        y?.child?.map((j) => {
          valuePermissionData?.map((c) => {
            j.permissions
              .filter((someobject) => someobject.id == c)
              .forEach((v) => {
                count += 1;
                v["checkData"] = true;
              });
          });
        });
      });
      if (
        count === valuePermissionData?.length &&
        valuePermissionData?.length !== 0
      ) {
        setAllSelect(true);
      }
    });
    // console.log("Datalength",data.length )
    // console.log("count>>>>>>>>>>>>>",count1,count2);
    // if(count1 === data?.length){
    //   setAllSelect(true);
    // }else{
    //   setAllSelect(false);
    // }
    let handleData = JSON.parse(JSON.stringify(data));
    setModuleData(handleData);
  }, [valuePermissionData, editRole]);

  const handleSearch = (e) => {
    // console.log(">>>>>>>>>>>>>",e.target.value)
    if (e.target.value.length > 2) {
      // getPermissionData((e.target.value.toLowerCase()))
      // console.log("moduleData",moduleData)
      // setSearchStr(e.target.value);
      let data = moduleData;
      let data1 = [];
      let arry = [];
      data.map((res) => {
        res.map((x, index) => {
          if (x.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            if (arry.includes(x.id)) {
              console.log("");
            } else {
              arry.push(x.id);
              data1.push(x);
            }
          }

          x.child?.map((p, index) => {
            // console.log("Child",x.id)
            if (p.name.toLowerCase().includes(e.target.value.toLowerCase())) {
              if (arry.includes(x.id)) {
                console.log("");
              } else {
                arry.push(x.id);
                data1.push(x);
              }
            }
          });
        });
      });
      let data2 = [];
      // console.log("Data1",data1)
      data2.push(data1);
      // console.log("Data2",data2)
      setModuleData(data2);
    }
    if (e.target.value.length === 0) {
      getPermissionData("");
    }
  };

  const handleSelectAll = (e) => {
    let data = moduleData;
    setAllSelect((prev) => !prev);
    if (e.target.checked === true) {
      let data = moduleData;
      let handleId = [];
      data?.map((x) => {
        x.map((y) => {
          y.permissions?.map((c) => {
            c["checkData"] = e.target.checked;
            handleId.push(c.id);
          });
          y?.child?.map((j) => {
            j.permissions?.map((z) => {
              z["checkData"] = e.target.checked;
              handleId.push(z.id);
            });
          });
        });
      });
      let handleData = JSON.parse(JSON.stringify(data));
      setCheckedDataId(handleId);
      setModuleData(handleData);
    } else {
      let data = moduleData;
      let handleId = [];
      data?.map((x) => {
        x.map((y) => {
          y.permissions?.map((c) => {
            c["checkData"] = e.target.checked;
            handleId.push(c.id);
          });
          y?.child?.map((j) => {
            j.permissions?.map((z) => {
              z["checkData"] = e.target.checked;
              handleId.push(z.id);
            });
          });
        });
      });
      let handleData = JSON.parse(JSON.stringify(data));
      setCheckedDataId([]);
      setModuleData(handleData);
      setRemoveCheckedDataId(handleId);
    }
  };

  useEffect(() => {
    if (editRole) {
      Object.keys(editRole).forEach((item) => {
        if (roleData[item] !== undefined && roleData[item] !== null) {
          roleData[item] = editRole[item];
        }
      });
    }
  }, [editRole]);

  return (
    <>
      <Formik
        initialValues={roleData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isRoleNameExist !== true) {
            handlePermission(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form>
            <div class="content-wrapper-inner">
              <div class="innerheadsec pb-0">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Add Role</h4>
                    {/* <Breadcrumb>
                                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/">
                                                Role Management
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item href="/role">
                                                Role & Permission
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>Add Role</Breadcrumb.Item>
                                        </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mb-3"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Role Name<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Enter Role Name"
                        class="form-control"
                        onChange={handleChange}
                        onBlur={(e) => {
                          isRoleExists(e, "code");
                          handleBlur(e);
                        }}
                        value={values.name}
                        maxLength="30"
                      />
                      {touched.name && errors.name ? (
                        <div className="small text-danger">{errors.name}</div>
                      ) : isRoleNameExist === true ? (
                        <div className="small text-danger">
                          *Role Name already exists
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label className="d-block mb-2">
                        Status<span class="text-danger">*</span>
                      </label>
                      <label class="logCheck d-inline-block mr-4">
                        Active
                        <input
                          type="radio"
                          name="is_active"
                          value={roleData.is_active}
                          onChange={(e) => {
                            setRoleData({
                              ...roleData,
                              is_active: true,
                            });
                          }}
                          checked={roleData.is_active === true ? true : false}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="logCheck d-inline-block">
                        Inactive
                        <input
                          type="radio"
                          name="is_active"
                          value={roleData.is_active}
                          onChange={(e) => {
                            setRoleData({
                              ...roleData,
                              is_active: false,
                            });
                          }}
                          checked={roleData.is_active === false ? true : false}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="row align-items-center">
                      <div class="col-md-8">
                        <div class="perhead">
                          <h4>Role Permission</h4>
                          <p class="border-0 mb-0">
                            Assign and select desire permission
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Modules..."
                            onChange={(e) => handleSearch(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="border-bottom mb-3"></div>

                    <div class="table-responsive">
                      <table className="table table-bordered permission-box">
                        <tr>
                          <td class="pt-0">
                            <h4>Administrator Access</h4>
                          </td>
                          <td class="pt-0" colSpan={10}>
                            <label className="logCheck d-inline-block">
                              Select All
                              <input
                                onClick={(e) => handleSelectAll(e)}
                                type="checkbox"
                                name="permission"
                                checked={allSelect}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        {moduleData.length == 0 ? (
                          <div></div>
                        ) : (
                          // <div
                          //   class="spinner-border text-primary mt-3"
                          //   role="status"
                          //   style={{ marginLeft: "70%" }}
                          // >
                          //   <span class="sr-only">Loading...</span>
                          // </div>
                          <>
                            {moduleData?.map((value, index) => (
                              <>
                                {value?.map((item, ind) => (
                                  <>
                                    <tr key={`_data${index}`}>
                                      <td>
                                        <h5>
                                          <i className="far fa-arrow-alt-circle-right"></i>
                                          {item.name}
                                        </h5>
                                      </td>
                                      {item?.permissions?.map((data, i) => (
                                        <>
                                          {data.code === "A" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "D_G" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "M_A" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "E" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "V" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "R" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "M_W_V" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "D" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                          {data.code === "U" && (
                                            <td>
                                              <label className="logCheck d-inline-block">
                                                {data?.codename}
                                                <input
                                                  type="checkbox"
                                                  name="permission"
                                                  value={checkedDataId}
                                                  onClick={(e) => {
                                                    updatePermissionValue(
                                                      e,
                                                      data
                                                    );
                                                  }}
                                                  checked={data.checkData}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </td>
                                          )}
                                        </>
                                      ))}
                                    </tr>
                                    {item?.child?.map((value, idx) => (
                                      <>
                                        <tr key={`value${idx}`}>
                                          <td>
                                            <h6>
                                              <i className="fas fa-chevron-right"></i>{" "}
                                              {value?.name}
                                            </h6>
                                          </td>
                                          {value?.permissions?.map(
                                            (data, i) => (
                                              <>
                                                {data.code === "A" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "D_G" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "M_A" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "E" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "V" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "R" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "M_W_V" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "D" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                                {data.code === "U" && (
                                                  <td>
                                                    <label className="logCheck d-inline-block">
                                                      {data?.codename}
                                                      <input
                                                        type="checkbox"
                                                        name="permission"
                                                        value={checkedDataId}
                                                        onClick={(e) => {
                                                          updatePermissionValue(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                        checked={data.checkData}
                                                      />
                                                      <span className="checkmark"></span>
                                                    </label>
                                                  </td>
                                                )}
                                              </>
                                            )
                                          )}
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        )}
                      </table>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <button
                      class="btn btn-outline-secondary mr-3"
                      type="button"
                      onClick={(e) => onClose()}
                    >
                      <i class="far fa-arrow-alt-circle-left"></i> Back
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main"
                      type="button"
                      onClick={handleSubmit}
                    >
                      <i class="far fa-check-circle"></i> Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddRole;
