import { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import {getTransporter}  from "../../../../service/LRGRPortal/transporterService";
import {ewayBillDetails} from "../../../../service/LRGRPortal/transportinvoiceservices";
import { uploadImages } from "../../../../service/header";
//import * as TransportServices from "../../../../service/LRGRPortal/transportinvoiceservices";
import {getPlant} from "../../../../service/LRGRPortal/plantService";
import * as BillProcessService from "../../../../service/LRGRPortal/billtaggingservices";
import moment, { invalid } from "moment";
import ViewTable from "../../Transport/Table/ViewTable";
import Select from "react-select";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { uploadFile } from "../../../../service/policy";
//import { uploadFile } from "../../../../service/Cms-newsService";
import { useDispatch } from "react-redux";
//import ModularTable from "../modulartable";
//import simage from './../../../../assets/images/simage.png'
import { Attachments } from '../Attachment/Attachments';



const Table = (props)=>{
    let status = "D";
    let edit = true;
    let editPolicy = true;

    const [billNumber, setBillNumber] = useState("");
    const [billAmount, setBillAmount] = useState("");
    const [totalFreight,setTotalFreight] = useState();
    const [plant, setPlant] = useState([]);
    const [billTagList,setBillTagList] = useState([]);
    const [transporterList, setTransporterList] = useState([]);
    const [transporter, setTransporter] = useState([]);
    const [selectedPlant,setSelectedPlant] = useState();
    const [selectedTransporter,setSelectedTransporter] = useState();
    const [invoicedate, setInvoicedate] = useState({start_date:"", end_date:"", bill_date: null});
    const [attachmentlist ,setAttachmentlist] = useState([{"type":"INV", "temp_name":""},{"type":"BILL", "temp_name":""},{"type":"OTH", "temp_name":""}])
    
   

    const [pageId, setPageId] = useState(-1);
    const [remark, setRemark] = useState("");
    const [updatedTableData, setUpdatedTableData] = useState([[]]);
    const [exDataDown, setExDataDown] = useState({});
    const [marketSize, setMarketSizeData] = useState({});
    const [mode, setMode] = useState();
    const [ewayList, setEwayList] = useState([])
    const [epodList, setEpodList] = useState([])
    const [viewForm, setViewForm] = useState(false);
    // const [loader, setLoader] = useState(true);
    const [importshow, importsetShow] = useState(false);
    const [downloadButton, setDownloadButton] = useState(false);
    const importuser = () => importsetShow(true);
    const [isLoading, setIsLoading] = useState(false);
    const importclose = () => importsetShow(false);
    let involist = [];
    let unique_id = [];
    const [attachmentError, setAttachmentError] = useState(true);
    const [Errorattachment, setErrorattachment] = useState("");
    const [errorAttachment, setErrorAttachment] = useState(false);
    const [errorAttachment1, setErrorAttachment1] = useState(false);
    const [imgSize, setImgSize] = useState(false);
    const { editForm, data, branchcopy1 } = props;
    const [attachment, setAttachment] = useState([]);

    const [file, setfiles] = useState([]);
    const [attachmentTypeError, setattachmentTypeError] = useState("");
    const [uploadDataIm, setUploadDataIm] = useState([]);
    const [external, setExternal] = useState(false);
     const [requestID, setRequestID] = useState("");
     const [plantID, setPlantID] = useState("");
     const [approvalid, setApprovalid] = useState();
    // const [toDate, setToDate] = useState("");
    
   
   
  

            const [freezeCTA, setFreezeCTA] = useState(false);
         
            const approvals = [{id:1,status:"P"},{id:2,status:"A"},{id:3,status:"D"},{id:4,status:"R"}]
          const aprvllist=[approvals];

          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth() + 1; //January is 0!
          var yyyy = today.getFullYear();

            if (dd < 10) {
              dd = '0' + dd;
            }

            if (mm < 10) {
              mm = '0' + mm;
            } 
                
            today = yyyy + '-' + mm + '-' + dd;
            const [date, setDate] = useState(today);
   
    const [approvalList, setApprovalList] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    };
  // show Plant,Transporter & Date 
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));

    if (!params.get("mode")) return;
    if (params.get("mode") == "create") {
      getPlant()
        .then((res) => {
          setPlant(res?.data?.dataList);

        })
        .catch((err) => {
          console.log(err);
        });
       

    } else if ((params.get("mode") == "edit") || (params.get("mode") == "view") || (params.get("mode") == "approve")){
     
      setRequestID(params.get("id"))
      setBillNumber(params.get("bill_number"))
      setBillAmount(params.get("bill_amount"))
      setPlantID(params.get("plantID"))

      setInvoicedate({
        ...invoicedate,
        start_date:params.get("from_date"),
        end_date:params.get("to_date"),
        bill_date:params.get("bill_date"),

      })
    
      getPlant()
        .then((res) => {
        
          let index = res.data.dataList.find((el) => el.plant_id == params.get("plantID"));
          setPlant([index])
        })
        .catch((err) => {
          console.log(err);
        });
          if(params.get("plantID") !=undefined && params.get("plantID") !=null){

          //  let queryParm = "plant_id=" + selectedPlant;
          let queryParm = "plant_id=" + params.get("plantID")
            getTransporter(queryParm)
             .then((response) => {
           
              let index = response.data.dataList.find((el) => el.transporter_id == params.get("trans_id"));
              setTransporter([index]);
             })
             .catch((error) => {
               console.log(
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                   "failed"
               );
             });
          }
    }
    if (params.get("mode") == "edit" || params.get("mode") == "view" || params.get("mode") == "approve") {
      let query = "";
      query = "?request_id=" + params.get("id");
      BillProcessService.getInvoiceReqListID(query)
        .then((res) => {
          if (res.status == 200) {
           
            setBillTagList(res.data.dataList);
            if(res.data?.data_list2.length){
              const tempData = [];
              tempData.push(res.data?.data_list2[0]);
              if(res.data?.data_list2[1] !== undefined){
                tempData.push(res.data?.data_list2[1])
              }else{
                tempData.push({
                  "type": "BILL",
                  "temp_name": ""
                  });
              }
              if(res.data?.data_list2[2] !== undefined){
                tempData.push(res.data?.data_list2[2])
              }else{
                tempData.push({
                  "type": "OTH",
                  "temp_name": ""
                  });
              }
              setAttachmentlist(tempData);
            }else{
              setAttachmentlist([{
                    "type": "INV",
                    "temp_name": ""
                  },
                  {
                    "type": "BILL",
                    "temp_name": ""
                },
                {
                    "type": "OTH",
                    "temp_name": ""
                }]);
            }
            let totalfr = 0;
            if(res.data.dataList.length !=0){
              res.data.dataList.map((ele) =>{
                   totalfr = totalfr + ele.freight;
                  })
            }
              setTotalFreight(totalfr) 
          }


        })
        .catch((err) => console.log(err));
    }

  }, [mode]);
  

   

        const searchBillList = ()=>{

          if(selectedPlant !=undefined && selectedPlant != "" && selectedTransporter !=undefined && selectedTransporter != "" ){
            handleLoader();
            if(moment(invoicedate.end_date).format("YYYY-MM-DD") !="Invalid date" && moment(invoicedate.start_date).format("YYYY-MM-DD") !="Invalid date" && invoicedate.end_date != "" && invoicedate.start_date_date != ""){
            let query= "";
            query = `?start_date=${moment(invoicedate.start_date).format("YYYY-MM-DD")}&end_date=${moment(invoicedate.end_date).format("YYYY-MM-DD")}&plant_id=${selectedPlant}&transporter_id=${selectedTransporter}`  
           
          BillProcessService.
          getInvofrReqList(query).
          then((response) => {   
            if(response.data.length != 0){
         
            setBillTagList(response?.data)
            let totalfr = 0;
            if(response.data.length !=0){
              response?.data.map((ele) =>{
                   totalfr = totalfr + ele.freight;
                  })
            }
              setTotalFreight(totalfr) 
          }else{
            setBillTagList([])
            toast.error("No Data available");
          }
                }).catch((error)=>{
            
                    console.log((error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                      "failed")
                    }).finally(()=>{ handleLoader(false);})

            }else{
                      
              toast.error("Please select valid Date");
            }
          }else{
            toast.error("Please select valid Plant and Transporter");
          }
          
        }
         const onChangefreightHandler = (frnumber,index,type) =>{
          if(frnumber.length>0){
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="freight"){
                  elem["freight"]=frnumber;
                }
                return elem;
              }else{
                return elem;
              }
            }))
           
          }else{
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="freight"){
                  elem["freight"]=null;
                }
                return elem;
              }else{
                return elem;
              }
            }))
          }


          let totalfr = 0;
          if(billTagList.length !=0){
                billTagList.map((ele) =>{
                 totalfr = Number(totalfr) + Number(ele.freight);
              
                })

                setTotalFreight(totalfr)
          }
         
    
          
         }

         const onChangehamaliHandler = (hmnumber,index,type) =>{
          if(hmnumber.length>0){
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="hamali"){
                  elem["hamali"]=hmnumber;
                }
                return elem;
              }else{
                return elem;
              }
            }))
          }else{
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="hamali"){
                  elem["hamali"]=null;
                }
                return elem;
              }else{
                return elem;
              }
            }))
          }
          }
          

          const onChangeotherHandler = (onumber,index,type) =>{
            if(onumber.length>0){
              setBillTagList(billTagList.map((elem,i)=>{
                if(index ==i){
                  if(type=="other"){
                    elem["other_charges"]=onumber;
                  }
                  return elem;
                }else{
                  return elem;
                }
              }))
            }else{
              setBillTagList(billTagList.map((elem,i)=>{
                if(index ==i){
                  if(type=="other"){
                    elem["other_charges"]=null;
                  }
                  return elem;
                }else{
                  return elem;
                }
              }))
            }
           }

         const onChangecartageHandler = (crnumber,index,type) =>{
          if(crnumber.length>0){
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="cartage"){
                  elem["cartage"]=crnumber;
                }
                return elem;
              }else{
                return elem;
              }
            }))
          }else{
            setBillTagList(billTagList.map((elem,i)=>{
              if(index ==i){
                if(type=="cartage"){
                  elem["cartage"]=null;
                }
                return elem;
              }else{
                return elem;
              }
            }))
          }
         }

    const onChangeBillNo = (billnumber) =>{
        if(billnumber.length != 0){
          setBillNumber(billnumber)
        }else{
          setBillNumber(null)
        }
    }

    const onChangeBillAmount = (billamount) =>{
      if(billamount.length != 0){
          
        setBillAmount(billamount)

      }else{
        setBillAmount(null)
      }
    }

    const onChangetotalfreight = (totalfreight) =>{
          let totalfr = 0;
      if(billTagList.length !=0){
            billTagList.map((ele) =>{
             totalfr = totalfr + ele.freight;
            })
      }
      if(totalfreight.length !=0){
        setTotalFreight(totalfr)
      }
    }
    const deleteAttachment = (id) => {
      let data = { ...file };
      Object.entries(data).map((key, value) => {
        let val = key[0];
        if (val == id) {
          delete data[val];
        }
      });
      setfiles(data);
    };
    const attachments123 = (index) => {
      let data = [];
      let data2 = file[index] != undefined ? file[index] : "";
      data =
        file[index] != undefined ? (
          <>
            <div class="uploaded-image mr-1">
              <a
                href={URL.createObjectURL(data2)}
                target={"_blank"}
                rel="noreferrer"
              >
                {data2.name.includes(".pdf") ? (
                  <img
                    src={"../images/policy-icon/pdf.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".xlsx") ? (
                  <img
                    src={"../images/policy-icon/xlsx.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".xls") ? (
                  <img
                    src={"../images/policy-icon/xlsx.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".doc") ? (
                  <img
                    src={"../images/policy-icon/docs.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".docs") ? (
                  <img
                    src={"../images/policy-icon/docs.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".pptx") ? (
                  <img
                    src={"../images/policy-icon/ppt.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : data2.name.includes(".mp4") ? (
                  <img
                    src={"../images/policy-icon/video.png"}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(data2)}
                    alt="pic"
                    width="70px"
                    height="70px"
                  />
                )}
              </a>
              <button
                type="button"
                onClick={(e) => {
                  deleteAttachment(index);
                }}
                className="btn btn-blanktd text-danger ml-2"
              >
                <i className="far fa-trash-alt text-danger"></i>
              </button>
            </div>
          </>
        ) : (
          ""
        );
      return data;
    };

    const downloadError = () => {
      const url = window.URL.createObjectURL(new Blob([exDataDown]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Skill.xlsx.csv"); //or any other extensio
      document.body.appendChild(link);
      link.click();
    };

    const onProfileChange = async (e, index) => {
      dispatch(setDisplayLoader("Display"));
      let file = e.target.files;
      setfiles((prev) => ({ ...prev, [index]: e.target.files[0] }));
      let files1 = [];
      let filename = [];
      let myAllPic = [];
      for (let i = 0; i < file.length; i++) {
        let data = e.target.files[i];
        let myUrl = URL.createObjectURL(e.target.files[i]);
        myAllPic.push(myUrl);
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await uploadImages(fileData).then((response) => {
          dispatch(setDisplayLoader("Hide"));
          let name = { name: data.name };
          filename.push(name);
          files1.push(response.data);
        });
      }
      setAttachment([]);
      if (index === 0) {
        setErrorAttachment(false);
      } else {
        setErrorAttachment1(false);
      }
      uploadDataIm[index] = files1[0];
    };
   
    const closeModal = () => {
      importsetShow(false);
      setDownloadButton(false);
    };


    const plantChangeHandler = (code) => {
      setSelectedPlant(code)
    
         if(code !=undefined && code !=null && code != ""){ 
          handleLoader();
          let queryParm = "plant_id=" + code 
           
            getTransporter(queryParm)
             .then((response) => {
         
               setTransporter(response?.data?.dataList);
             })

             .catch((error) => {
             
               console.log(
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                   "failed"
               );
             }).finally((e)=>{
              handleLoader(false)
             });
          }

  }
  const onView = (data) => {
    setViewForm(true);
    let queryParm = "?invoice_number=" + data;
    ewayBillDetails(queryParm)
    .then((res) => {

     setEwayList(res?.data?.ewaybill_data[0])
     setEpodList(res?.data?.epod_data[0])

    })
    .catch((err) => {
      console.log(err);
    });
    
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  
  const TransporterChangeHandler = (code) => {
    setSelectedTransporter(code)
}

  

  
    //To fetch past data
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      setApprovalid(params.get("approvalID"))
      
     
      let query = "";
      query = "?request_id=" + params.get("id");
      if (params.get("mode") != "create") {
        handleLoader();
        BillProcessService.
          getInvoiceapprovalLogs(query)
          .then((res) => {
         
            setApprovalList(res.data);
  
          })
          .catch((err) => console.log(err))
          .finally(()=>{
            handleLoader(false);
          });
      }
      // setLoader(false);

    }, []);
  
  
  
    //Set table data after past data is fetched
    const openFilter = () => {
      importuser();
      setIsLoading(false);
    };
    
   

       //EVENT HANDLERS

       const onValidation = (type)=>{

        if(billTagList.length !=0){
          let flag_data = false;
          let flag_partial = false;
          let dupRow = billTagList
          involist = [];
          unique_id = [];
          dupRow.map((el,i)=>{
            flag_data = false;
              if(el.hamali == null) {
                flag_data = true;
                flag_partial = true;
             }
             else if (el.cartage == null) {
                flag_data = true;
                flag_partial = true;
            }
            else if (el.freight == null) {
                flag_data = true;
                flag_partial = true;
             }
         
            if(flag_data){
              involist.push(el.invoice_number)
            
             
             }
         
          
          })
      
      
            if(flag_partial){
              if(type == "P"){
                if (freezeCTA) return;
           
              }else{
                confirmDialog(type)
              }
            }else{
              confirmDialog(type)
            }
        }else{
          toast.error("No Invoice added");
        }
        
      }










  const onSaveHandler = (event) => {

    if(billNumber != "" && billNumber != null){
      if(billAmount != "" && billAmount != null && Number(billAmount)>0){
       
          if(invoicedate.bill_date != null && invoicedate.bill_date != ""){
    if (freezeCTA) return;
      let flag = false;
      let query = "";
      
    if (mode == "create"){
      if(invoicedate.bill_date !=null){
      
        query = "?plant_id=" + selectedPlant
        + "&from_date=" + moment(invoicedate.start_date).format("YYYY-MM-DD")
        + "&to_date=" + moment(invoicedate.end_date).format("YYYY-MM-DD")
        + "&transporter_id=" + selectedTransporter
        + "&status=" + "D" 
        + "&bill_date=" + moment(invoicedate.bill_date).format("YYYY-MM-DD")
        + "&bill_amount=" + billAmount    
        + "&bill_number=" + billNumber 
      }else{
      
        
        query = "?plant_id=" + selectedPlant
        + "&from_date=" + moment(invoicedate.start_date).format("YYYY-MM-DD")
        + "&to_date=" + moment(invoicedate.end_date).format("YYYY-MM-DD")
        + "&transporter_id=" + selectedTransporter
        + "&status=" + "D" 
        + "&bill_date=" + "null"
        + "&bill_amount=" + billAmount    
        + "&bill_number=" + billNumber 
      }
      let data_list2 = [];
      if(attachmentlist[0]?.temp_name !== ''){
        data_list2.push(attachmentlist[0]);
      }
      if(attachmentlist[1]?.temp_name !== ''){
        data_list2.push(attachmentlist[1]);
      }
      if(attachmentlist[2]?.temp_name !== ''){
        data_list2.push(attachmentlist[2]);
      }
      let datal = {
        dataList: billTagList,
        data_list2: data_list2
      };
      handleLoader();
      BillProcessService.
      postInvofrRequest(query,datal)
        .then((res) => {
          toast.success("Save succesfull!");
          setTimeout(() => {
            navigate("/billprocess");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save failed!");
          console.log("error", err)
          setTimeout(() => {
            navigate("/billprocess");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }

    if (mode == "edit") {
      let data_list2 = [];
      if(attachmentlist[0]?.id !== undefined){
        data_list2[0] = attachmentlist[0];
      }
      else{
        if(attachmentlist[0]?.temp_name !== ''){
          data_list2[0] = attachmentlist[0];
        }
      }
      if(attachmentlist[1]?.id !== undefined){
        data_list2[1] = attachmentlist[1];
      }
      else{
        if(attachmentlist[1]?.temp_name !== ''){
          data_list2[1] = attachmentlist[1];
        }
      }
      if(attachmentlist[2]?.id !== undefined){
        data_list2[2] = attachmentlist[2];
      }
      else{
        if(attachmentlist[2]?.temp_name !== ''){
          data_list2[2] = attachmentlist[2];
        }
      }
      if(invoicedate.bill_date !=null){
       
        query = "?status=" + "D" 
        + "&request_id=" +  requestID
        + "&bill_date=" + moment(invoicedate.bill_date).format("YYYY-MM-DD")
        + "&bill_amount=" + billAmount    
        + "&bill_number=" + billNumber 
      }else{
   
        query = "?&status=" + "D" 
        + "&request_id=" +  requestID
        + "&bill_date=" + "null"
        + "&bill_amount=" + billAmount    
        + "&bill_number=" + billNumber 
      }
      let datal = {
        dataList: billTagList,
        data_list2: data_list2
      };
         
      handleLoader();
      BillProcessService.
      UpdateInvofrRequest(query,datal)
        .then((res) => {
        
          toast.success("Save succesfull!");
          setTimeout(() => {
            navigate("/billprocess");
          }, 1000);
        })
        .catch((err) => {
          
          toast.error("Save failed!");
          setTimeout(() => {
            navigate("/billprocess");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }
      }else{
        toast.error("Please enter bill date!");
      }
    }else{
    toast.error("Please enter bill amount!");
    }

    }else{
    toast.error("Please enter bill number!");
    }
      };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

 
  const onSubmitHandler = () => {
    let data_list2 = [];
    if(attachmentlist[0]?.temp_name === ''){
      toast.error("Please upload Invoice attachment"); return false;
    }
    else{
      data_list2[0] = attachmentlist[0];
    }
    if(billNumber != "" && billNumber != null){
      if(billAmount != "" && billAmount != null && Number(billAmount)>0){
        if(Number(billAmount) == Number(totalFreight)){
          if(invoicedate.bill_date != null && invoicedate.bill_date != ""){
            if (mode == "create"){
              let query = "";
              query = "?plant_id=" + selectedPlant
                        + "&from_date=" + moment(invoicedate.start_date).format("YYYY-MM-DD")
                        + "&to_date=" + moment(invoicedate.end_date).format("YYYY-MM-DD")
                        + "&transporter_id=" + selectedTransporter
                        + "&status=" + "P" 
                        + "&bill_date=" + moment(invoicedate.bill_date).format("YYYY-MM-DD")
                        + "&bill_amount=" + billAmount    
                        + "&bill_number=" + billNumber  

                        if(attachmentlist[1]?.temp_name !== ''){
                          data_list2[1] = attachmentlist[1];
                        }
                        if(attachmentlist[2]?.temp_name !== ''){
                          data_list2[2] = attachmentlist[2];
                        }
                        let datal = {
                          dataList: billTagList,
                          data_list2: data_list2
                        };
                   

              BillProcessService.
              postInvofrRequest(query,datal)
                .then((res) => {
                  toast.success("Submit succesfull!");
                  setTimeout(() => {
                    navigate("/billprocess");
                  }, 1000);
                })
                .catch((err) => {
                  toast.error("Submit failed!");
                  console.log("error", err)
                  setTimeout(() => {
                    navigate("/billprocess");
                  }, 1000);
                }).finally(()=>{
                  handleLoader(false);
                });
            }
        
        
          if (mode == "edit") {
            let query = "";
            if(invoicedate.bill_date !=null){
              
              query = "?status=" + "P" 
              + "&request_id=" +  requestID
              + "&bill_date=" + moment(invoicedate.bill_date).format("YYYY-MM-DD")
              + "&bill_amount=" + billAmount    
              + "&bill_number=" + billNumber 
            }else{
             
              query = "?&status=" + "P" 
              + "&request_id=" +  requestID
              + "&bill_date=" + "null"
              + "&bill_amount=" + billAmount    
              + "&bill_number=" + billNumber 
            }
            if(attachmentlist[1]?.id !== undefined){
              data_list2[1] = attachmentlist[1];
            }
            else{
              if(attachmentlist[1]?.temp_name !== ''){
                data_list2[1] = attachmentlist[1];
              }
            }
            if(attachmentlist[2]?.id !== undefined){
              data_list2[2] = attachmentlist[2];
            }
            else{
              if(attachmentlist[2]?.temp_name !== ''){
                data_list2[2] = attachmentlist[2];
              }
            }
            let datal = {
              dataList: billTagList,
              data_list2: data_list2
            };
             
            handleLoader();
            BillProcessService.
            UpdateInvofrRequest(query,datal)
              .then((res) => {
              
                toast.success("Submit succesfull!");
                setTimeout(() => {
                  navigate("/billprocess"); 
                }, 1000);
              })
              .catch((err) => {
              
                //toast.error("Submit failed!");
                toast.success("Submit failed!");
                
                setTimeout(() => {
                  navigate("/billprocess"); 
                }, 1000);
              }).finally(()=>{
                handleLoader(false);
              });  
              // setLoader(false);
          }
          }else{
            // setLoader(false);
            toast.error("Please select bill date!");
          }
        }else{
          // setLoader(false);
          toast.error("Total Freight and Bill Amount should be equal.");
        }
  
       
      }else{
        // setLoader(false);
        toast.error("Please fill valid bill amount!");
      }
      
  }else{
    // setLoader(false);
       toast.error("Please fill valid bill number!");
    }
    
};

  const onCancelHandler = () => {
    return navigate("/billprocess");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/billprocess");
  };

  // approval/reject validate
  const validate = (value) => {
    let data = {
      request_id: requestID,
      plant_id: plantID,
      approval_id: approvalid,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    handleLoader();
    BillProcessService.
      postApproverAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/billprocess");
        }, 2000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      }).finally(()=>{
        handleLoader(false);
      });
  };



  // (function () {
  //   if (loader) {
  //     dispatch(setDisplayLoader("Display"));
  //   } else {
  //     dispatch(setDisplayLoader("Hide"));
  //   }
  // })();
  

//JSX markup language
    return (
      <>
 
         <div class="row">
       <div class="col-md-12">
     
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Bill Process
          </h4>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div className="row"
        >
      

    <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
                <b>Plant Name: </b>
              </div>
              <div className={styles["valueboxone"]}>
              <div style={{ display: "flex", gap: "10px" }}>
              <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                minWidth: "120px",
              }}
              disabled={mode == "edit" || mode =="view"}
               
              value={selectedPlant}
               classNamePrefix="select"
              onChange={(e) => {
                 plantChangeHandler(e.target.value);
              }}
            >
             
             {mode =="create"?
             <option value={""} label={"Select Plant"}/>:
             ""
             } 
              {mode =="create" && plant.length >=0  &&
                plant?.map((el, index) => {
                  return <option  key={index} value={el.plant_id} label={el.plant_name +"(" + el.plant_code +")"} />;
                })}
              
              {mode !=="create" && plant.length >=0  &&
                plant?.map((el, index) => {
                  return <option   key={index} value={el.plant_id} label={el.plant_name +"(" + el.plant_code +")"} />;
                })}
              
            </select>
           
           
          </div>
              </div>
            </div> 
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
                <b>Transporter : </b>
              </div>
              <div className={styles["valueboxone"]}>
              <div style={{ display: "flex", gap: "10px" }}>
              <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                // color: "#0195DA",
                minWidth: "150px",
              }}
              disabled={mode == "edit" || mode =="view"}
               
              value={selectedTransporter}
               classNamePrefix="select"
              onChange={(e) => {
                
                TransporterChangeHandler(e.target.value);
              }}
            >
             
             {mode =="create"?
             <option value={""} label={"Select Transporter"}/>:
             ""
             } 
              {mode =="create" && transporter.length >=0  &&
                transporter?.map((el, index) => {
                  return <option  key={index} value={el.transporter_id} label={el.transporter_name +"(" + el.transporter_code +")"} />;
                })}
              
              {mode !=="create" && transporter.length >=0  &&
                transporter?.map((el, index) => {
                  return <option   key={index} value={el.transporter_id} label={el.transporter_name +"(" + el.transporter_code +")"} />;
                })}
            </select>
           
           
          </div>
              </div>
            </div> 
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
           
                 <b>From Date : </b>
              </div>
              <div className={styles["valueboxone"]}>
                 
            <input
              type="date"
              class="form-control"
              name="from_date"
              disabled={mode =="view"}
              onChange={(e) => {
                setInvoicedate({
                  ...invoicedate,
                  start_date: e.target.value,
                })
              }}
              value={invoicedate.start_date}
            />
              </div>
            </div>
          <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
           
                 <b>To Date : </b>
              </div>
              <div className={styles["valueboxone"]}>
                 
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  disabled={mode =="view"}
                  onChange={(e) => {
                    setInvoicedate({
                      ...invoicedate,
                      end_date: e.target.value,
                    })
                  }}
                  value={invoicedate.end_date}/>

              </div>

          </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["valueboxone"]}>
                <div style={{ display: "flex", gap: "8px" }}>
                    {
                        mode=="create" && mode !=undefined ?(
                          <button type="button" class="btn btn-success btn-sm" onClick={searchBillList}>Search</button>
                        ):(
                              ""
                        )
                      }
               </div>
              </div>
            </div>
          {/* </div> */}
{/* 
        </div> */}
         </div>

            {billTagList.length != 0 && (
  <div className="row billrow">
  <div className="col-md-12 my-2">
    <div className="border-top md-3"> </div>
  </div>
  {/* <div
    className={styles["heading"]}
    style={{
      paddingTop: "0",
      paddingBottom: "15px",
      // justifyContent: "start",
    }}
  > */}
    {/* <div
      className={styles["transport-portal-label"]}
      style={{ display: "flex" }}
    > */}
    <div
        style={{
          zIndex: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "35px",
        }}
      >
        <div className={styles["transport-portal-label"]}>
          <b>Bill No. <span class="text-danger">*</span>: </b>
        </div>
      <div className={styles["valueboxone"]}>
        <div style={{ display: "flex", gap: "6px" }}>
           <input className={styles["inputtext"]} type="text"  maxlength="20"  disabled={mode =="view"} onChange={(e)=>onChangeBillNo(e.target.value)} value={billNumber} name="billno" id="billno"  placeholder="Bill number"/> 
        </div>
      </div>
    </div> 

      <div
        style={{
          zIndex: 0,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <div className={styles["transport-portal-label"]}>
          <b>Bill Amount<span class="text-danger">*</span> :</b>
        </div>
        <div className={styles["valueboxone"]}>

        <div style={{ display: "flex", gap: "10px" }}>
         <input className={styles["inputtext"]}  type="number"  disabled={mode =="view"} onChange={(e)=>onChangeBillAmount(e.target.value)} value={billAmount} name="billamount" id="billamount"   onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');" placeholder="Bill Amount"/>
     
     
    </div>
        </div>
      </div> 

      <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
              }}
            >
              <div className={styles["transport-portal-label"]}>
           
                 <b>Bill Date<span class="text-danger">*</span> : </b>
              </div>
              <div className={styles["valueboxone"]}>
                 
                <input
                  type="date"
                  class="form-control"
                  name="bill_date"
                  disabled={mode =="view"}
                  min={invoicedate.start_date}
         
        onChange={(e) => {
          setInvoicedate({
            ...invoicedate,
            bill_date: e.target.value,
          })
        }}
        value={invoicedate.bill_date}/>

              </div>

          </div>
      {/* <div
        style={{
          zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "4px",
        }}
      >
        <div className={styles["transport-portal-label"]}>
     
           <b>Bill Date : </b>
        </div>
        <div className={styles["valueboxone"]}>
           
      <input
        type="date"
        class="form-control"
        name="from_date"
        min={invoicedate.start_date}
         max={date}
        onChange={(e) => {
          setInvoicedate({
            ...invoicedate,
            bill_date: e.target.value,
          })
        }}
        value={invoicedate.bill_date}
      />
        </div>
      </div> */}
    {/* </div> */}
  {/* </div> */}
  </div>
            )}
         

        <div className="table-responsive">
        <table class="table table-striped table-bordered tablecured">
        <thead>
            <tr>
                <th>Invoice/<br></br>STF No.</th>
                <th>Date of Invoice</th>
                <th>Customer Name</th>
                <th>Customer<br></br> Code</th>
                <th>City</th>
                <th>Qty in Case/<br></br>Drum/Pack</th>
                <th>Qty in<br></br> Kg/Ltr</th>               
                <th>Transporter Name </th>
                <th>SubTransporter Name</th>
                <th>LR/RR Number</th>
                <th>LR/RR Date</th>
                <th>Vehicle<br></br> Number</th>            
                <th>Shipping Delay</th>
                <th>Loading <br></br> Charges </th>
                <th>Freight <span style={{color:"red"}}>*</span></th>
                <th>Cartage </th>
                <th>Other Charges</th>
                <th>Per Kg/Ltr Rate</th>
                <th>Shipping<br></br> Mode</th>
                <th>Truck Type</th>
                <th>EWAY/EPOD Detail</th>                         
            </tr>
            </thead>
          
            <tbody>
                {(mode!=undefined) && (mode=="view" || mode=="approve") &&  
                 (billTagList !=undefined && billTagList.map(el=>{
             
    return <tr style={{ backgroundColor: el.for_my_approval ? "#92ff40" : "", fontWeight: el.for_my_approval ? "bold" : "" }}>
                       <td>{el.invoice_number }</td>
                       <td>{moment(el?.invoice_date).format("DD-MM-YYYY")}</td>
                       <td>{el.customer_name}</td>
                       <td>{el.customer_code}</td>
                       <td>{el.customer_city}</td>  
                       <td>{el.qty_case_box}</td>
                       <td>{el.qty_kg_ltr}</td>                   
                       <td>{el.transporter_id__name}</td>
                       <td>{el.sub_transporter_id__name}</td>
                       <td>{el.lr_gr_number}</td>
                       <td>{moment(el?.lr_gr_date).format("DD-MM-YYYY")}</td>
                       <td>{el.vehicle_number}</td>
                       <td>{el.shipping_delay}</td> 
                       <td>{el.hamali}</td>
                       <td>{el.freight}</td>
                       <td>{el.cartage}</td> 
                       <td>{el.other_charges}</td> 
                       <td>{(el.freight/el.qty_kg_ltr).toFixed(2)}</td>
                       <td>{el.shipping_mode}</td>
                       <td>{el.truck_id__type}</td> 
                       <td> 
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(el.invoice_number);
                              }}
                            >
                              <img src="../images/eye.png" alt="" />
                            </button>
                        </td>
                        
                  </tr>
                         }))         
                     }

                     {billTagList.length  ? (
                    (mode!=undefined) && (mode=="create" ||mode=="edit") && 
                   (billTagList !=undefined && (billTagList.map((el, i)=>{    

         return <tr style={{ backgroundColor: el.for_my_approval ? "#92ff40" : "", fontWeight: el.for_my_approval ? "bold" : "" }}>
                       <td>{el.invoice_number }</td>
                       <td>{moment(el?.invoice_date).format("DD-MM-YYYY")}</td>
                       <td>{el.customer_name}</td>
                       <td>{el.customer_code}</td>
                       <td>{el.customer_city}</td>
                       <td>{el.qty_case_box}</td>
                       <td>{el.qty_kg_ltr}</td>
                       <td>{el.transporter_id__name}</td>
                       <td>{el.sub_transporter_id__name}</td>
                       <td>{el.lr_gr_number}</td>
                       <td>{moment(el?.lr_gr_date).format("DD-MM-YYYY")}</td>
                       <td>{el.vehicle_number}</td>
                       <td>{el.shipping_delay}</td>

                       <td className={styles["transport-portal-table"]} 
                style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                <input className="inputhtStyle" type="number" maxlength="12" value={el.hamali} style={{ width: "100%",padding:"2px" }}
                 onChange={(e) => onChangehamaliHandler(e.target.value,i, "hamali")}  onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');"/>         </td>

                       <td className={styles["transport-portal-table"]} 
                style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                <input className="inputhtStyle" type="number" maxlength="12" value={el.freight} style={{ width: "100%",padding:"2px" }}
                 onChange={(e) => onChangefreightHandler(e.target.value,i, "freight")} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');" />         </td>

             

                  <td className={styles["transport-portal-table"]} 
                style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                <input className="inputhtStyle" type="number" maxlength="12" value={el.cartage} style={{ width: "100%",padding:"2px" }}
                 onChange={(e) => onChangecartageHandler(e.target.value,i, "cartage")} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');" />         </td>

                 <td className={styles["transport-portal-table"]} 
                style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                <input className="inputhtStyle" type="number" maxlength="12" value={el.other_charges} style={{ width: "100%",padding:"2px" }}
                 onChange={(e) => onChangeotherHandler(e.target.value,i, "other")} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');" />         </td>
                    
                       <td>{(el.freight/el.qty_kg_ltr).toFixed(2)}</td>
                       <td>{el.shipping_mode}</td>
                       <td>{el.truck_id__type}</td> 
                       <td> 
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(el.invoice_number);
                              }}
                            >
                              <img src="../images/eye.png" alt="" />
                            </button>
                        </td>
                        
                     </tr>
                                            })) 
                                          )  ) : (
                                            <tr>
                                              <td colSpan={21}> No Record Found</td>
                                            </tr> )}
                            </tbody>
        </table>
      </div>
      {billTagList.length ?  <Attachments {...{attachmentlist ,setAttachmentlist,showAction: !['view','approve'].includes(mode) }} /> : null}
    <div
          style={{
            zIndex: 0,
            position: "relative",
            display: "flex",
            alignItems: "center",
            float:"right",
            marginRight: "80px",
            marginBottom: "10px",
          }}
        >
          <div className={styles["billprocess-portal-label"]}>
            <b>Total Freight :</b>
          </div>
          <div className={styles["valueboxone"]}>
          <div style={{ display: "flex", gap: "10px" }}>
            <input className={styles["inputtext"]} onChange={(e)=>onChangetotalfreight(e)} type="text" name="totalfreight" id="totalfreight" value={totalFreight} placeholder="Total Freight" disabled/>
        
      </div>
          </div>
        </div> 

    
  
    

        
        { approvalList?.length > 0 &&
        mode !=undefined && (mode !="create") && (
          <div className={styles["table_contain"]}>
            <div className={styles["transport-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured tablcuredlastnew"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                    <th>Action At</th>
                    <th>Action By</th>
                    <th>Action Status</th>
                    <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>

                    
                      {approvalList &&
                        approvalList.map((el, i) => {
                          let status;

                          // const findPendingIndex =
                          //   approvalList.findIndex(
                          //     (ele) => ele.status == "P"
                          //   );

                          if (el.status == "D") status = "Saved";
                          else if (el.status == "P") status = "Pending";
                          else if (el.status == "A") status = "Approved";
                          else if (el.status == "R") status = "Rejected";
                          else if (el.status == "RP") status = "Auto Rejected";


                        
                          return (
                            <tr>
                              <td>
                                {status == "Pending"
                                  ? "-"
                                  : el.action_at == "None"
                                    ? "-"
                                    : moment(el.action_at)
                                      .utc()
                                      .format("DD-MM-YYYY")}
                              </td>
                              <td>{el.pending_at_first_name}</td>
                              <td>
                                {status}
                              </td>
                              <td>
                                {el.remarks == "None"
                                  ? "-"
                                  : el.remarks
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                                      </table>
              </div>
            </div>
          </div>
        )}
      
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  // confirmDialog("P");
                  onValidation("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  // confirmDialog("D");
                  onValidation("D");
                }}
              >
                Save
              </div>
            </div>
          )}
      
           { mode == "approve" ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    maxlength="200"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-danger" onClick={onCancelHandler}>
                Cancel
              </div>
            </div>
          )} 
        </div>
      </div>
    </div> 
   


    
   <Modal
        show={importshow}
        onHide={closeModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Bill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  {isLoading ? (
                    <div class="loadingss">
                      <p style={{ color: "#3498db" }}>
                        Please wait while uploading the data.
                      </p>
                      <span>
                        <i></i>
                        <i></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <i className="fas fa-file-upload fa-2x"></i>
                      <p>Upload the Bill file</p>
                      <button className="btn btn-primary-inner" type="button">
                        DRAG AND DROP HERE OR CHOOSE FILE
                      </button>
                      <div className="mt-3 downloadtemplate">
                        <button className="editbtn">
                          <i className="far fa-file-excel"></i>
                          <a href="skill-master-template.xlsx" download>
                            DOWNLOAD TEMPLATE
                          </a>
                        </button>
                      </div>
                    </>
                  )}

                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e)}
                  />
                </div>

                {downloadButton && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>Check the uploaded file in case of error.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> 


      {true ? (
        <>
          {/* <ViewPlant
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          /> */}
        </>
      ) : (
        ""
      )}

{viewForm ? (
        <>
          <ViewTable
            viewForm={viewForm}
            onViewClose={onViewClose}
            epodList = {epodList}
            ewayList = {ewayList}

          />
        </>
      ) : (
        ""
      )}
    
        </>

        
        
    )
    
}




export default Table;