import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getEmployee = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};
export const getMultiUser = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/survey/get-dropdown-data?organisation_id=${id}&type=User`
  );
};
export const getVendorCard = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_vendor_card`
  );
};
export const getEmployeeData = async (employee_code) => {
  return axios.get(`${AUTH_BASE_URL}/users?employee_code=${employee_code}`);
};
export const getPersonalArea = async () => {
  return axios.get(`${AUTH_BASE_URL}/personal-area/listByOrg`);
};
export const getEmpSubGroup = async () => {
  return axios.get(`${AUTH_BASE_URL}/groups/getEmp_subGroup`);
};
export const getCompany = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
};
export const getGlCode = async (organisation_id, code_start_with) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-gl-code/list?${
      organisation_id === null ||
      organisation_id === undefined ||
      organisation_id === ""
        ? ""
        : `organisation_id=${organisation_id}`
    }&code_start_with=${code_start_with}`
  );
};
export const getCostCentre = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-cost-center/list?organisation_id=${id}`
  );
};

export const uploadAttachment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getUserGl = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanseother_expense_gl_mapping?${queryParm}`
  );
};

export const saveUserGl = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_gl_mapping`,
    data
  );
};
export const isExists = async (user_id, organisation_id, gl_type) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/gl_mapping_exists?${
      user_id === null || user_id === undefined || user_id === ""
        ? ""
        : `user_id=${user_id}`
    }${
      organisation_id === null ||
      organisation_id === undefined ||
      organisation_id === ""
        ? ""
        : `&organisation_id=${organisation_id}`
    }&gl_type=${gl_type}`
  );
};

export const updateUserGl = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_gl_mapping/${id}`,
    data
  );
};

export const deleteData = async (id) => {
  return axios.delete(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_gl_mapping/${id}`
  );
};

export const getOtherExpensePetty = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_petty?${queryParm}`
  );
};

export const saveOtherExpensePetty = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_petty`,
    data
  );
};

export const isPettyUserExists = async (param,data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/petty_user_exists?${param}`,
    data
  );
};


export const updateOtherExpensePetty = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_petty/${id}`,
    data
  );
};

export const saveGiUserTable = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_assign_gi`,
    data
  );
};

export const updateGiUserTable = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_assign_gi/${id}`,
    data
  );
};

export const getGiUserTable = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/other_expense_assign_gi?${params}`);
};

export const glCostCenterVerify = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/gl-cost-center-verify?${params}`);
};
