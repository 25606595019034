import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getCropDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/pop/crop/drop-down`);
}

export const getLanguageDropdown = async (crop_id)=>{
    let query = `?crop_id=${crop_id}`
    return axios.get(`${AUTH_BASE_URL}/pop/language/drop-down${query}`);
}

export const getPopItemDropdown = async (crop_id,language_id)=>{
    let query = `?crop_id=${crop_id}&language_id=${language_id}`
    return axios.get(`${AUTH_BASE_URL}/pop/pop-item/drop-down${query}`);
}

export const getProductItemDropdown = async (crop_id,language_id,pop_item_id)=>{
    let query = `?crop_id=${crop_id}&language_id=${language_id}&pop_item_id=${pop_item_id}`
    return axios.get(`${AUTH_BASE_URL}/pop/product/drop-down${query}`);
}


export const postPopItem = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/pop`,body);
}

export const putCropPopPlanner = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/pop`,body);
}

export const getLastPopPlanner = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/pop/last_pop_items${query}`)
}

export const getPopPlan = async () =>{
    return axios.get(`${AUTH_BASE_URL}/pop`)
}
export const getTerritory = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
}

export const getMonthDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/overall-strategy/overall-month/drop-down`);
}

export const getPopItemApproval = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/pop/approver-pop-item-logs?pop_trans_id=${query}`);
}

export const postPopItemAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/pop/action-pop-item`,body);
}
