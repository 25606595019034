import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import * as TravelListingAPI from "../../service/travelService";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as vehicleService from "../../service/vehiclelog";

function ViewTravelList() {
  let { state } = useLocation();
  const [traveluserDetails, setTraveluserDetails] = useState([]);
  const [travelDetails, setTravelDetails] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [daDetails, setDADetails] = useState([]);
  const [vehicleData, setVehicalData] = useState({});
  const [hotelDetails, setHotelDetails] = useState([]);
  const [vehicleAssignValues, setVehicleAssignValues] = useState();

  const [conveyanceDetails, setConveyanceDetails] = useState([]);
  const [newticketDetails, set_newTicketDetails] = useState([]);
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const userDetails = useSelector(selectUserData);
  const daType = {
    0: "Local",
    100: "RHQ",
    155: "NH",
  };

  const [delayAmt, setdelayAmt] = useState(0);
  const [contAmount, setContAmount] = useState([]);
  const [fExp, setFExp] = useState("");
  const [currencyList, setCurrencyList] = useState([]);

  const [travelchats, setTravelChats] = useState([]);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [foreignDetails, setForeignDetails] = useState({
    hotelDetail: [],
    foodDetail: [],
    inciDaDetail: [],
    conveyDetail: [],
  });

  const [finalDetails, set_finalDetails] = useState({
    final_userclaimself: 0,
    final_userclaimcompany: 0,
    final_claim: 0,
    final_approvedCompExp: 0,
    final_approvedSelfExp: 0,
    final_approvedTotal: 0,
    final_netAmt: 0,
  });

  const [vehicleLogSums, set_vehicleLogSums] = useState({
    id: 381,
    travel_id: 8253,
    travel_date: "2023-08-23",
    start_kms: 0,
    is_exception: null,
    mode_id: 4,
    close_kms: 0,
    total_kms: 0,
    fuel_quantity: 0,
    fuel_amount: 0,
    driver_da_value: 0,
    other_expenses: 0,
    total_amount: 0,
    driver_daily_wages: 0,
    user_id: 5590,
    washing_amount: 0,
    appr_driver_daily_wages: 0,
    appr_fuel_amount: 0,
    appr_other_expenses: 0,
    appr_washing_amount: 0,
    appr_driver_da_value: null,
    approved_amount: 0,
    vehicle_log_id: null,
    user_name: "Ravi Kumar",
    modee_name: "Car",
  });
  const [ticketSums, setticketSums] = useState({
    taxamt: 0.0,
    other: 0.0,
    billamt: 0.0,
    claimed: 0.0,
    apprtax: 0.0,
    approther: 0.0,
    appramt: 0.0,
  });
  const [hotelSums, sethotelSums] = useState({
    taxamt: 0.0,
    billamt: 0.0,
    claimed: 0.0,
    totalclaim: 0.0,
    apprtax: 0.0,
    apprbill: 0.0,
    apprclaim: 0.0,
    appramt: 0.0,
    apprbasic: 0.0,
  });

  const [allApproved, set_allApproved] = useState({
    totalHotelValue: 0.0,
    totalHotelValue2: 0.0,

    totalTicketValue: 0.0,
    totalTicketValue2: 0.0,

    totalDaValue: 0.0,
    totalDaValue2: 0.0,

    totalVehLogValue: 0.0,
    totalVehLogValue2: 0.0,

    totalConveValue: 0.0,
    totalConveValue2: 0.0,

    totaltikbyself: 0.0,
    totaltikbycompany: 0.0,

    total_da_byself: 0.0,
    total_da_bycompany: 0.0,
    total_hotel_byself: 0.0,
    total_conve_byself: 0.0,

    totalForegHotel: 0.0,
    totalForegHotel2: 0.0,
    totalForegHotelself: 0.0,
    totalForegHotelcomp: 0.0,

    totalForegFood: 0.0,
    totalForegFood2: 0.0,
    totalForegFoodself: 0.0,
    totalForegFoodcomp: 0.0,

    totalForegDaValue: 0.0,
    totalForegDaValue2: 0.0,
    totalForegDaValueself: 0.0,
    totalForegDaValuecomp: 0.0,

    totalForegConveValue: 0.0,
    totalForegConveValue2: 0.0,
    totalForegConveValueself: 0.0,
    totalForegConveValuecomp: 0.0,
  });

  const location = useLocation();
  const [backpath, set_backPath] = useState(null);

  const dangerRow = { color: "#f00" };

  const calculateDelayDeductionPercent = (calcDaya) => {
    let userr = traveluserDetails[0]?.other_details;

    let claimDaysFromJoining = moment(travelDetails?.dispatch_date).diff(
      moment(userr?.date_of_joining, "YYYY-MM-DD"),
      "days"
    );
    let days = calcDaya + " Days";
    let deductedPercent = 100;
    console.log(`....`, days, claimDaysFromJoining);

    if (claimDaysFromJoining < 61) {
      days = "0 Days";
    }
    if (
      ["D1", "D2", "C1"].includes(userr?.emp_sub_group) ||
      userr?.sales_dist == "CONS"
    ) {
      days = "0 Days";
    }
    days = days.split(" ");
    if (days[0] <= 7) {
      deductedPercent = 0;
    } else if (days[0] >= 8 && days[0] <= 20) {
      deductedPercent = 5;
    }
    console.log(`ded per`, deductedPercent);
    return deductedPercent;
  };

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  const Closewin = () => {
    window.close();
  };

  window.onafterprint = () => {
    setTimeout(() => {
      document.getElementById("print-btn-1").onclick = Print;
      document.getElementById("print-btn-3").onclick = Print;
      document.getElementById("print-btn-2").onclick = Closewin;
      // document.getElementById("print-btn-2").onclick = Print;
    }, 2000);
  };

  const getTraveluserDetailsById = (id) => {
    TravelListingAPI.getTravelById(id).then((response) => {
      setTraveluserDetails(response?.data?.dataList?.result);
      getTravelExpenseVehicleSummary(
        response?.data?.dataList?.result[0]?.created_by_id,
        id
      );
    });
  };

  const handleKey1 = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_free !== "" && is_free !== undefined && is_free !== null) {
      queryParm = queryParm + "&is_free=" + is_free;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  function calcApprovedTicket(_ndata) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      ticketSums.taxamt += data?.tax_amount;
      ticketSums.other += data?.other_charges;
      ticketSums.billamt += data?.taxable_amount;
      ticketSums.claimed +=
        data?.taxable_amount + data?.tax_amount + data?.other_charges;
      ticketSums.apprtax += data?.appr_tax_amount;
      ticketSums.approther += data?.appr_other_charges;
      ticketSums.appramt +=
        data?.appr_taxable_amount +
        data?.appr_tax_amount +
        data?.appr_other_charges;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_total_bill_amount;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_total_bill_amount;

          break;
      }

      totalticketvalue += data?.tax_bill_amount;

      totalticketvalue2 += data?.appr_total_bill_amount;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalTicketValue: totalticketvalue,
      totalTicketValue2: totalticketvalue2,
      totaltikbyself: totalticketself,
      totaltikbycompany: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  const calcApprovedConveyance = (_ndata, modif = "") => {
    let totalConveValue = 0,
      totalConveValue2 = 0;
    // console.log(`convey`,_ndata);

    _ndata?.forEach((data) => {
      totalConveValue += data?.amount;
      totalConveValue2 += data?.approved_amount;
    });

    // setTotalConveValue(totalConveValue)
    // setTotalConveValue2(totalConveValue2)

    // allApproved.totalConveValue=totalConveValue
    // allApproved.totalConveValue2=totalConveValue2

    //   if(modif){

    //     set_allApproved((prev) => ({
    //     ...prev,
    //     // totalDaValue: da,
    //     totalDaValue2: da,
    //     // total_da_byself: da,

    //   }))
    // }
    // else{

    set_allApproved((pre) => {
      return {
        ...pre,
        totalConveValue: totalConveValue,
        totalConveValue2: totalConveValue2,
        total_conve_byself: totalConveValue2,
      };
    });

    // }

    // finalDetails.final_approvedTotal=totalTicketValue2+totalConveValue2
    // finalDetails.final_approvedSelfExp+=totalConveValue
  };

  function calcApprovedForegHotel(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegHotel: totalticketvalue,
      totalForegHotel2: totalticketvalue2,
      totalForegHotelself: totalticketself,
      totalForegHotelcomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegFood(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.currency_name !== 'INR' ? data?.appr_amount * data?.appr_exchange_rate : data?.amount_in_forex * 1;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegFood: totalticketvalue,
      totalForegFood2: totalticketvalue2,
      totalForegFoodself: totalticketself,
      totalForegFoodcomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegDA(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegDaValue: totalticketvalue,
      totalForegDaValue2: totalticketvalue2,
      totalForegDaValueself: totalticketself,
      totalForegDaValuecomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegConvey(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegConveValue: totalticketvalue,
      totalForegConveValue2: totalticketvalue2,
      totalForegConveValueself: totalticketself,
      totalForegConveValuecomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedDA(_ndata, modif = "") {
    let da = 0,
      da2 = 0;
    let totalticketComp = 0,
      totalticketself = 0;

    console.log(`calcda..`, _ndata);

    _ndata?.forEach((data) => {
      // console.log(`da..`, data?.SelectedKey?.amt ? data?.SelectedKey?.amt : data?.appr_da_amount);
      da = da + data?.gross_total;
      da2 = da2 + data?.appr_da_amount;
      // if (data?.SelectedKey[0]?.label?.toLowerCase()?.includes("company")){
      //   totalticketComp += ( (data?.SelectedKey[0]?.amt >= 0 ? data?.SelectedKey[0]?.amt : data?.appr_da_amount))
      // }else{
      //   totalticketself += ( (data?.SelectedKey[0]?.amt >= 0 ? data?.SelectedKey[0]?.amt : data?.appr_da_amount))
      // }
      // totalConveValue2+=data?.approved_amount
    });

    // setTotalDaValue(da)
    // setTotalDaValue2(da)
    // allApproved.totalDaValue=da
    // allApproved.totalDaValue2=da

    if (modif) {
      set_allApproved((prev) => ({
        ...prev,
        // totalDaValue: da,
        totalDaValue2: da2,
        // total_da_byself: totalticketself,
        // total_da_bycompany: totalticketComp,
      }));
    } else {
      set_allApproved((prev) => ({
        ...prev,
        totalDaValue: da,
        totalDaValue2: da2,
        // total_da_byself: totalticketself,
        // total_da_bycompany: totalticketComp,
      }));
    }

    // finalDetails.final_approvedTotal=totalTicketValue2+totalConveValue2
  }

  function calcApprovedHotel(_ndata) {
    let totalhotval = 0,
      totalhotval2 = 0;

    _ndata?.map((data) => {
      hotelSums.billamt += data?.taxable_amount;
      hotelSums.claimed += data?.claim_amount;
      hotelSums.taxamt += data?.tax_amount;
      hotelSums.apprtax += data?.appr_tax_amount;
      hotelSums.totalclaim += data?.total_claim_amount;
      hotelSums.apprbasic += data?.appr_bill_amount;
      hotelSums.apprclaim += data?.appr_claim_amount;
      hotelSums.appramt += data?.appr_amount;

      totalhotval += data?.total_claim_amount;
      totalhotval2 += data?.appr_amount;
    });

    // setTotalHotelValue(totalhotval)
    // setTotalHotelValue2(totalhotval2)

    // allApproved.totalHotelValue=totalhotval
    // allApproved.totalHotelValue2=totalhotval2

    console.log(`on change hotel ...`);

    set_allApproved((prev) => ({
      ...prev,
      totalHotelValue: totalhotval,
      totalHotelValue2: totalhotval2,
      total_hotel_byself: totalhotval2,
    }));
  }

  function calcApprovedVehicle(_ndata, modif = "") {
    let totalVehval = 0,
      totalVehval2 = 0;

    // set_vehicleLogSums

    for (const [key, value] of Object.entries(vehicleLogSums)) {
      console.log(`${key}: ${value}`);
      if (typeof value == "number") {
        vehicleLogSums[key] = 0;
      }

      if (typeof value == "string") {
        vehicleLogSums[key] = "";
      }
    }

    _ndata?.map((data) => {
      for (const [key, value] of Object.entries(data)) {
        console.log(`${key}: ${value}`);
        if (typeof value == "number") {
          vehicleLogSums[key] += value;
        }
      }
    });

    _ndata?.map((data) => {
      totalVehval += data?.total_amount;
      totalVehval2 += data?.approved_amount;
    });

    if (modif) {
      set_allApproved((prev) => ({
        ...prev,
        // totalDaValue: da,
        totalVehLogValue2: totalVehval2,
        // total_da_byself: da,
      }));
    } else {
      set_allApproved((prev) => ({
        ...prev,
        totalVehLogValue: totalVehval,
        totalVehLogValue2: totalVehval2,
        // total_da_byself: da,
      }));
    }

    console.log(`on change vehicle ...`);

    set_allApproved((prev) => ({
      ...prev,
      totalVehLogValue: totalVehval,
      totalVehLogValue2: totalVehval2,
      // total_hotel_byself : totalhotval2
    }));
  }

  const todaysD = new Date();

  const fDate = moment(todaysD).format("DDMMYYYY");

  const getTravelDetailsById = (id) => {
    TravelListingAPI.trav_detail(id)
      .then((resp) => {
        setTravelDetails(resp?.data?.dataList);

        // calcApprovedTicket(resp?.data?.dataList?.ticket_expense)
        // calcApprovedHotel(resp?.data?.dataList?.hotel_expense)
        // calcApprovedDA(resp?.data?.dataList?.da_expense)

        // calcApprovedConveyance(resp?.data?.dataList?.conveyance_expense)
        // setVehicleDetails(resp?.data?.dataList?.vehicle_expense)
        // calcApprovedVehicle(resp?.data?.dataList?.vehicle_expense)

        let newtiks = resp?.data?.dataList?.ticket_expense?.map((data) => {
          if (data.tax_bill_amount == null) {
            data.tax_bill_amount =
              data?.taxable_amount + data?.other_charges + data?.tax_amount;
          }
          data.appr_other_charges =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_other_charges == null
              ? data.other_charges
                ? data.other_charges
                : 0.0
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? // ? data.appr_tax_rate
                data.appr_other_charges
              : data.other_charges
              ? data.other_charges
              : 0.0;
          data.appr_tax_amount = data.appr_tax_amount
            ? data.appr_tax_amount
            : data.tax_amount
            ? data.tax_amount
            : 0.0;
          data.appr_tax_rate =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_tax_rate == null
              ? data.tax_rate_id
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_tax_rate
              : data.tax_rate_id;
          data.appr_taxable_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_taxable_amount == null
              ? data.taxable_amount
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_taxable_amount
              : data.taxable_amount;
          data.appr_total_bill_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_total_bill_amount == null
              ? data.tax_bill_amount
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_total_bill_amount
              : data.tax_bill_amount;
          return data;
        });

        set_newTicketDetails(newtiks);
        calcApprovedTicket(newtiks);
        let usdData = [{ id: "", name: "", amount: 0 }];

        if (resp?.data?.dataList?.request_type == "International") {
          let data = resp?.data?.dataList?.currency_data;
          // ? JSON.parse(resp?.data?.dataList?.currency_data.replace(/'/g, '"'))
          // : "";
          // console.log(`before if setfexp`,data);
          if (data?.length > 0) {
            let tempCurrencyList = [];
            data.forEach((obj) => {
              // let temp = Object.entries(obj)[0];
              // if (temp[0] === "USD") usdData = obj.USD == "" ? 0 : parseInt(obj.USD);
              // if (temp[1] !== "") tempCurrencyList.push(obj);

              obj.amount = parseFloat(obj?.amount ? obj?.amount : 0);
            });
            // setFExp(usdData);
            usdData = data;
            setCurrencyList(data);
            console.log(`setfexp`, usdData, fExp);
          }
        } else {
          console.log(`not international`);
        }

        let hotelData = resp?.data?.dataList?.hotel_expense.map((data) => {
          if (!data?.tax_amount) {
            data.tax_amount = 0.0;
          }
          data.appr_tax_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_tax_amount
              : data.tax_amount;

          data.appr_bill_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_bill_amount
              : data.taxable_amount;

          data.appr_claim_amount =
            resp?.data?.dataList?.approval_reasons?.length == 0
              ? data?.claim_amount
              : data?.appr_claim_amount;

          data.appr_amount =
            // resp?.data?.dataList?.approval_reasons?.length>0 && !data.appr_amount ? data.total_claim_amount :
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data?.appr_amount === null
                ? data.total_claim_amount
                : data.appr_amount
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data.total_claim_amount
              : data.appr_claim_amount + data.appr_tax_amount;

          data.appr_tax_rate_id =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            !data?.appr_tax_rate_id
              ? data?.tax_rate_id
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data?.tax_rate_id
              : data?.appr_tax_rate_id;
          data.appr_tax_rate_value =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            !data?.appr_tax_rate_value
              ? data?.tax_rate_value
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data?.tax_rate_value
              : data?.appr_tax_rate_value;

          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          return data;
        });
        setHotelDetails(hotelData);
        calcApprovedHotel(hotelData);

        let conveyanc = resp?.data?.dataList?.conveyance_expense.map((data) => {
          data.approved_amount =
            data.approved_amount == null ? data.amount : data?.approved_amount;
          return data;
        });

        setConveyanceDetails(conveyanc);
        calcApprovedConveyance(conveyanc);

        let vehicledetaills = resp?.data?.dataList?.vehicle_expense.map(
          (data) => {
            data.washing_amount =
              data.washing_amount != null ? data.washing_amount : 0;

            data.appr_fuel_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_fuel_amount == null
                ? data.fuel_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_fuel_amount
                : data.fuel_amount;
            data.appr_driver_daily_wages =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_driver_daily_wages == null
                ? data.driver_daily_wages
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_driver_daily_wages
                : data.driver_daily_wages;
            data.appr_driver_da_value =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_driver_da_value == null
                ? data.driver_da_value
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_driver_da_value
                : data.driver_da_value;
            data.appr_other_expenses =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_other_expenses == null
                ? data.other_expenses
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_other_expenses
                : data.other_expenses;
            data.appr_washing_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_washing_amount == null
                ? data.washing_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_washing_amount
                : data.washing_amount;
            data.approved_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.approved_amount == null
                ? data.total_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.approved_amount
                : data.total_amount;

            return data;
          }
        );
        setVehicleDetails(vehicledetaills);
        calcApprovedVehicle(vehicledetaills);

        let dadata = resp?.data?.dataList?.da_expense.map((data) => {
          data.appr_da_percent =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_da_percent
              : data?.da_percent
              ? parseInt(data?.da_percent)
              : 0;
          data.appr_da_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_da_amount
              : data?.gross_total;
          data.appr_da_remarks =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data?.appr_da_remarks
              : data?.remarks;
          return data;
        });
        let timeCalcN = dadata?.map((dd, i) => {
          let tt = dd?.hours;

          dd.dropDownKey = [
            { value: 0, label: "No DA Claim", isDisable: false },
          ];

          dd.SelectedKey = [
            {
              value: dd?.appr_da_percent,
              label: dd?.remarks,
              isDisable: false,
            },
          ];

          if (tt > 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 20,
                label: "All Food Provided by the company",
                isDisable: false,
              },
              {
                value: 40,
                label: "Lunch and Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 50,
                label: "Breakfast and Lunch/Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 70,
                label: "Lunch/Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 80,
                label: "Breakfast provided by the company",
                isDisable: false,
              },
              {
                value: 100,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          } else if (tt > 8 && tt <= 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 50,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          } else if (tt > 4 && tt <= 8) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 30,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          }
          let foundSelectedEle = false;

          if (!dd.da_percent) {
            dd.dropDownKey.forEach((_item, index) => {
              console.log(
                `detail..comparing id ${dd?.id} ${dd?.da_date} ${dd?.remarks} with ${_item.label}`
              );
              if (foundSelectedEle) _item.isDisable = true;
              if (
                _item.label.toLowerCase() == dd?.appr_da_remarks?.toLowerCase()
              ) {
                foundSelectedEle = true;
                dd.da_percent = _item.value;
                dd.appr_da_percent = _item.value;
                dd.SelectedKey = [
                  {
                    value: _item.value,
                    label: _item.label,
                    isDisable: false,
                  },
                ];

                // return false
              }
            });
          }

          // else if (tt > 4) {
          //   dd.dropDownKey = [
          //     { value: 0, label: "No DA Claim" },
          //     {
          //       value: 30,
          //       label: "All food expenses paid by the employee",
          //     },
          //   ];
          // }
          return dd;
        });

        setDADetails(timeCalcN);
        calcApprovedDA(timeCalcN);

        let foregHotel = resp?.data?.dataList?.foreign_expense_hotel.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              : 0.0;
            // data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;
            data.amount_in_inr = data?.currency_name!=='INR' ? data.appr_exchange_rate * data.amount_in_forex : 1*data?.amount_in_forex;
            data.appr_exchange_rate = data?.currency_name !== 'INR' ? data.appr_exchange_rate : 1;

            return data;
          }
        );
        let foregFood = resp?.data?.dataList?.foreign_food_expense.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              : 0.0;
            data.amount_in_inr = data?.currency_name!=='INR' ? data.appr_exchange_rate * data.amount_in_forex : 1*data?.amount_in_forex;
            data.appr_amount_inr = data?.currency_name !== 'INR' ? data.appr_exchange_rate * data.amount_in_forex : 1*data?.amount_in_forex;
            data.appr_exchange_rate = data?.currency_name !== 'INR' ? data.appr_exchange_rate : 1;

            return data;
          }
        );
        let foregDA = resp?.data?.dataList?.foreign_expense_da.map((data) => {
          // if(!data?.currency_name){
          data.currency_name = !data?.currency_name
            ? data.ent_currency_name
            : data.currency_name;
          // }
          data.appr_amount =
            data?.appr_amount == null ? data.amount_in_forex : data.appr_amount;
          data.appr_exchange_rate = usdData[
            usdData?.findIndex((obj) => obj?.name === data?.currency_name)
          ]?.amount
            ? usdData[
                usdData?.findIndex((obj) => obj?.name === data?.currency_name)
              ]?.amount
            : 0.0;
          data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;
          data.ent_amount_in_inr =
            data?.ent_amount_in_forex * data.appr_exchange_rate;

          return data;
        });
        let foregConv = resp?.data?.dataList?.foreign_expense_conveyance.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              : 0.0;
            data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;
            data.appr_exchange_rate = data?.currency_name !== 'INR' ? data.appr_exchange_rate : 1;

            return data;
          }
        );

        setForeignDetails({
          ...foreignDetails,
          hotelDetail: foregHotel,
          foodDetail: foregFood,
          inciDaDetail: foregDA,
          conveyDetail: foregConv,
        });

        calcApprovedForegHotel(foregHotel, true);
        calcApprovedForegFood(foregFood, true);
        calcApprovedForegDA(foregDA, true);
        calcApprovedForegConvey(foregConv, true);
      })
      .catch((error) => {
        toast.error("Error!");
        console.log(`details`, error);
      });
  };

  let abortTravelExpenseVehicleSummary = null;

  const getTravelExpenseVehicleSummary = (userId, travelId) => {
    abortTravelExpenseVehicleSummary = new AbortController();
    TravelListingAPI.getTravelExpenseVehicleSummary(
      userId,
      travelId,
      abortTravelExpenseVehicleSummary.signal
    ).then((res) => {
      if (res.status) setVehicalData(res.data.dataList);
    });
  };

  const getTravelChat = (id) => {
    TravelListingAPI.getTravelChat(id).then((response) => {
      console.log("chat->", response?.data?.dataList?.result);
      setTravelChats(response?.data?.dataList?.result);
    });
  };

  const getContinentEntt = (travel_id) => {
    TravelListingAPI.getContinentEntt(travel_id)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setContAmount(data);
      })
      .catch((error) => {
        console.log("failed foreign entitlement");
      });
  };

  const getVehicleAssignData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    vehicleService
      .getVehicleAssignData(
        handleKey1(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          is_free,
          user_id
        )
      )
      .then((response) => {
        setVehicleAssignValues(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (!location?.state) {
      let id = location.search.slice(1);
      state = { travel_id: id, backpath: null };
    }
    let travel_id = state?.travel_id;
    set_backPath(location?.state?.backpath);

    let user_id = location?.state?.user_id;
    let travelexp_approval_id = location?.state?.travelexp_approval_id;
    let localGroup = "";

    userDetails.groups.every((data) => {
      if (data?.name.toLowerCase() === "ssc user"||data?.name.toLowerCase() === "ssc manager"||data?.name.toLowerCase() === "ssc head") {
        localGroup = data?.name.toLowerCase();
        // setInputsDisabled(
        //   localGroup == "ssc manager" || localGroup == "ssc head"
        // );
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    getContinentEntt(travel_id);
    getTravelChat(travel_id);
    getTraveluserDetailsById(travel_id);

    getTravelDetailsById(travel_id);
  }, [state]);

  useEffect(() => {
    let delayamt = 0;

    if (travelDetails?.travel_detail?.length > 0) {
      delayamt = (userGroup._userGroup == "ssc head"?0:calculateDelayDeductionPercent(
        moment(travelDetails?.dispatch_date).diff(
          moment(
            travelDetails?.travel_detail[
              travelDetails?.travel_detail.length - 1
            ]?.to_date,
            "YYYY-MM-DD"
          ),
          "days"
        )
      ));
      setdelayAmt(delayamt);
    }

    let finalClaim =
      allApproved.totalTicketValue +
      allApproved.totalConveValue +
      allApproved.totalDaValue +
      allApproved.totalHotelValue +
      allApproved.totalVehLogValue +
      allApproved.totalForegHotel +
      allApproved.totalForegFood +
      allApproved.totalForegDaValue +
      allApproved.totalForegConveValue 
    let finalSelfsum =
      allApproved.totaltikbyself +
      allApproved.totalDaValue2 +
      allApproved.total_hotel_byself +
      allApproved.total_conve_byself +
      allApproved?.totalVehLogValue2 +
      allApproved.totalForegHotelself +
      allApproved.totalForegFoodself +
      allApproved.totalForegDaValueself +
      allApproved.totalForegConveValueself 
    let finalcomp =
      allApproved.totaltikbycompany +
      allApproved.totalForegHotelcomp +
      allApproved.totalForegFoodcomp +
      allApproved.totalForegDaValuecomp +
      allApproved.totalForegConveValuecomp;

    let final_claimself =
      allApproved.totaltikbyself +
      allApproved.totalDaValue +
      allApproved.totalHotelValue +
      allApproved?.totalVehLogValue +
      allApproved.totalConveValue +
      allApproved.totalForegHotelself +
      allApproved.totalForegFoodself +
      allApproved.totalForegDaValueself +
      allApproved.totalForegConveValueself 

    let final_claimcompany =
      allApproved.totaltikbycompany +
      allApproved.totalForegHotelcomp +
      allApproved.totalForegFoodcomp +
      allApproved.totalForegDaValuecomp +
      allApproved.totalForegConveValuecomp;

    let final_apptotal =
      allApproved.totalTicketValue2 +
      allApproved.totalConveValue2 +
      allApproved.totalDaValue2 +
      allApproved.totalHotelValue2 +
      allApproved.totalVehLogValue2 +
      allApproved.totalForegHotel2 +
      allApproved.totalForegFood2 +
      allApproved.totalForegDaValue2 +
      allApproved.totalForegConveValue2 
    console.log(`eee1`, allApproved);
    console.log(`eee2`, finalSelfsum);
    console.log(`eee3`, finalcomp);
    // console.log(`eee`, final_apptotal);
    // console.log(`eee`, final_apptotal);
    // console.log(`eee`, final_apptotal);

    set_finalDetails((pre) => ({
      ...pre,
      final_userclaimself: final_claimself?.toFixed(2),
      final_userclaimcompany: final_claimcompany?.toFixed(2),
      final_claim: finalClaim?.toFixed(2),
      final_approvedCompExp: finalcomp?.toFixed(2),
      final_approvedSelfExp: finalSelfsum?.toFixed(2),
      final_approvedTotal: final_apptotal,

      final_netAmt: (
        final_apptotal -
        (delayamt * final_apptotal) / 100
      )?.toFixed(2),
    }));

    // finalDetails.final_approvedCompExp=allApproved.totaltikbycompany
    // finalDetails.final_approvedSelfExp=allApproved.totaltikbyself + allApproved.total_da_byself + allApproved.total_hotel_byself
    // finalDetails.final_claim=finalDetails.final_approvedCompExp+finalDetails.final_approvedSelfExp

    // finalDetails.final_approvedTotal=allApproved.totalTicketValue2+allApproved.totalConveValue2+allApproved.totalDaValue2+allApproved.totalHotelValue2+allApproved.totalVehLogValue2

    // finalDetails.final_netAmt= finalDetails.final_approvedTotal -delayamt

    // console.log(`finalDetails`, finalDetails);
  }, [allApproved]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="row">
              <div class="col-md-12">
                <div class="content-wrapper-inner content-wrapper-inner2">
                  <div class="innerheadsec">
                    <div className="row">
                      <div class="col-md-12">
                        <div class="defaultchatgptquest text-right">
                          {/* <Link
                            to="/other-listing"
                            class="badge badge-secondary"
                          >
                            <i class="fas fa-arrow-circle-left"></i> Back
                          </Link> */}
                          <button
                            class="badge badge-primary"
                            id="print-btn-3"
                            onClick={() => Print()}
                          >
                            <i class="fas fa-print"></i> Print
                          </button>
                        </div>
                      </div>
                    </div>

                    <div id="printablediv">
                      <h3 class="policyhead">
                        {traveluserDetails?.length > 0
                          ? traveluserDetails[0]?.organisation_name
                          : "PI INDUSTRIES LIMITED!"}

                        <br />
                        <strong class="text-dark f-15">
                          Travel Expense Claim
                        </strong>
                      </h3>

                      {traveluserDetails?.map((data) => {
                        return (
                          <>
                            <div class="d-flex justify-content-between">
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Emp No</label>
                                  <p>{data?.emp_code}</p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Name</label>
                                  <p>{data?.user_name}</p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Grade</label>
                                  <p>{data?.other_details?.emp_sub_group}</p>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Head Quarters</label>
                                  <p>{data?.other_details?.location}</p>
                                </div>
                              </div>

                              <div className="col-md-2">
                                <div className="form-group innergroup">
                                  <label>Intimation Number</label>
                                  <p>
                                    {data?.travel_intimation_number
                                      ? data?.travel_intimation_number
                                      : `PITI${fDate}${data?.emp_code}${data?.created_by_id}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Deputation</label>
                                  <p className="text-danger">
                                    {" "}
                                    {traveluserDetails[0]?.action_code === "10"
                                      ? "Yes"
                                      : "No" ?? "-"}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Vendor Code</label>
                                  <p>{data?.vendor_code}</p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>SAP Trip No.</label>
                                  <p>{data?.sap_trip_number}</p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Tour Applied on</label>
                                  <p>
                                    {moment
                                      .parseZone(data?.created_at)
                                      .format("DD-MM-YYYY HH:mm ")}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Tour Edit on</label>
                                  <p>
                                    {data?.tour_edit_on
                                      ? moment
                                          .parseZone(data?.tour_edit_on)
                                          .format("DD-MM-YYYY HH:mm ")
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Approved on</label>
                                  <p>
                                    {data?.approval_date &&
                                      moment
                                        .parseZone(data?.approval_date)
                                        .format("DD-MM-YYYY HH:mm ")}
                                    {/* expense_approver_remark_data */}
                                    {/* {state?.expense_approver_remark_data.length ===
                                2
                                  ? moment(
                                      state?.expense_approver_remark_data[1]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("DD-MM-YYYY")
                                  : state?.expense_approver_remark_data
                                      .length === 1
                                  ? moment(
                                      state?.expense_approver_remark_data[0]
                                        ?.updated_at
                                    )
                                      .utc()
                                      .format("DD-MM-YYYY")
                                  : ""} */}
                                  </p>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Zone</label>
                                  <p>{data?.other_details?.sales_dist}</p>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>From Location</label>
                                  <p>
                                    {/* {data?.travel_detail_data[0]?.from_city_name} */}
                                    {/* {                            
                            travelDetails?.travel_detail?.length>0 && travelDetails?.travel_detail[0]?.from_city_name
                          } */}

                                    {travelDetails?.travel_detail?.length > 0 &&
                                      (travelDetails?.travel_detail[0]
                                        ?.from_city_name === "Other"
                                        ? travelDetails?.travel_detail[0]
                                            ?.from_city_input
                                        : travelDetails?.travel_detail[0]
                                            ?.from_city_name)}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Departure Date</label>
                                  <p>
                                    {/* {moment(
                              data?.travel_detail_data[0]?.from_date
                            ).format("DD-MM-YYYY")} */}
                                    {travelDetails?.travel_detail?.length > 0 &&
                                      moment(
                                        travelDetails?.travel_detail[0]
                                          ?.from_date +
                                          " " +
                                          travelDetails?.travel_detail[0]
                                            ?.from_time
                                      ).format("DD-MM-YYYY HH:mm ")}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>To Location</label>
                                  <p>
                                    {data?.travel_detail_data[0]
                                      ?.to_city_name === "Other"
                                      ? data?.travel_detail_data[0]
                                          ?.to_city_input
                                      : data?.travel_detail_data[0]
                                          ?.to_city_name}
                                    {/* {data?.travel_detail_data[0]?.to_city_name} */}
                                    {/* {                            
                            travelDetails?.travel_detail?.length>0 && travelDetails?.travel_detail[travelDetails?.travel_detail?.length-1]?.from_city_name
                          } */}

                                    {/* {travelDetails?.travel_detail?.length > 0 &&
                                      (travelDetails?.travel_detail[
                                        travelDetails?.travel_detail?.length - 1
                                      ]?.from_city_name === "Other"
                                        ? travelDetails?.travel_detail[
                                            travelDetails?.travel_detail
                                              ?.length - 1
                                          ]?.from_city_input
                                        : travelDetails?.travel_detail[
                                            travelDetails?.travel_detail
                                              ?.length - 1
                                          ]?.from_city_name)} */}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Arrival Date</label>
                                  <p>
                                    {/* {moment(
                              data?.travel_detail_data[data?.travel_detail_data.length-1].to_date
                            ).format("DD-MM-YYYY")} */}

                                    {travelDetails?.travel_detail?.length > 0 &&
                                      moment(
                                        travelDetails?.travel_detail[
                                          travelDetails?.travel_detail?.length -
                                            1
                                        ]?.to_date +
                                          " " +
                                          travelDetails?.travel_detail[
                                            travelDetails?.travel_detail
                                              ?.length - 1
                                          ]?.to_time
                                      ).format("DD-MM-YYYY HH:mm ")}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Days</label>
                                  <p>
                                    {
                                      // parseInt(
                                      //   ((new Date(data?.travel_detail_data[1]?.to_date))
                                      //     - (new Date(data?.travel_detail_data[0]?.from_date)))
                                      //   / (1000 * 60 * 60 * 24), 10
                                      // ) + 1

                                      travelDetails?.travel_detail?.length >
                                        0 &&
                                        moment(
                                          travelDetails?.travel_detail[
                                            travelDetails?.travel_detail
                                              ?.length - 1
                                          ]?.to_date
                                        ).diff(
                                          moment(
                                            travelDetails?.travel_detail[0]
                                              ?.from_date,
                                            "YYYY-MM-DD"
                                          ),
                                          "days"
                                        ) + 1
                                    }
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Territory</label>
                                  <p class="text-danger">
                                    {data?.territory_name ?? "No"}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Date of Joining</label>
                                  <p>
                                    {moment(
                                      data?.other_details?.date_of_joining
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                              </div>

                              {/* </div>
                    <div className="d-flex justify-content-between"> */}

                              {/* </div> */}

                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Employee Status</label>
                                  <p>Active</p>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Reporting Person</label>
                                  <p>{data?.reporting_person}</p>
                                </div>
                              </div>

                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Exception Approved By</label>
                                  <p>
                                    {data?.expense_exception
                                      ? `${
                                          data?.expense_approver_remark_data[
                                            data?.expense_approver_remark_data
                                              ?.length - 1
                                          ]?.approver_name
                                        } (${
                                          data?.expense_approver_remark_data[
                                            data?.expense_approver_remark_data
                                              ?.length - 1
                                          ]?.approver_username
                                        })`
                                      : "-"}
                                    {/* {state?.is_exception == true
                                    ? state?.approver_remark_data[0]?.approver_name
                                    : "-"}
                                  {state?.is_exception == true
                                    ? state?.travel_detail_data[0]?.approver_code
                                    : ""} */}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>
                                    Delay Deduction -{" "}
                                    {travelDetails?.travel_detail?.length > 0
                                      ? moment(
                                          travelDetails?.dispatch_date
                                        ).diff(
                                          moment(
                                            travelDetails?.travel_detail[
                                              travelDetails?.travel_detail
                                                .length - 1
                                            ]?.to_date,
                                            "YYYY-MM-DD"
                                          ),
                                          "days"
                                        ) + " days"
                                      : ""}
                                    {/* {
                            travelDetails?.travel_detail?.length>0 ? moment(travelDetails?.dispatch_date).diff(moment(travelDetails?.travel_detail[travelDetails?.travel_detail?.length - 1]?.to_date, "YYYY-MM-DD"), "days") : ""

                          } Days */}
                                  </label>

                                  <p>{`${delayAmt}%`}</p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-group innergroup">
                                  <label>Expense Claimed On</label>
                                  <p>
                                    {moment
                                      .parseZone(data?.claim_date)
                                      .format("DD-MM-YYYY HH:mm ")}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group innergroup"></div>
                              </div>

                              {/* <div class="col-md-6 viewformaftersubmit">
                            <div class="form-group innergroup">
                              <label>Download Attachments</label>
                              <p>
                                <span class="mr-3">
                                  <i class="fas fa-file-download"></i> file.pdf
                                </span>{" "}
                                <span class="mr-3">
                                  <i class="fas fa-file-download"></i> file-01.pdf
                                </span>
                              </p>
                            </div>
                          </div> */}
                            </div>
                          </>
                        );
                      })}

                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>Travel Intimation</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-4">
                        <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo">
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>Departure Date</th>
                              <th>Departure Time</th>
                              <th>Places Visited</th>
                              <th>To</th>
                              <th>Reaching Date</th>
                              <th>Reaching Time</th>
                              {/* {traveluserDetails[0]?.other_details?.sales_dist && <th>HQ</th>} */}
                              <th>HQ</th>
                              <th>Purpose</th>
                              <th>Mode</th>
                              <th>Colleague Empcode</th>
                              <th>Territory</th>
                              <th>Hotel</th>
                            </tr>
                          </thead>
                          <tbody>
                            {travelDetails?.travel_detail?.length > 0 ? (
                              travelDetails?.travel_detail?.map((data) => {
                                // return res?.travel_detail_data?.map((data, index) => {
                                return (
                                  <tr>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.request_type == "International"
                                        ? data?.from_continent_name +
                                          `-${data?.from_country_name}-${
                                            data?.from_city_name == "Other"
                                              ? data?.from_city_input
                                              : data?.from_city_name
                                          }`
                                        : data?.from_city_name == "Other"
                                        ? data?.from_city_input
                                        : data?.from_city_name}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {moment(data?.from_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {moment(
                                        "2023-1-1 " + data?.from_time
                                      ).format("HH:mm")}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.additional_information}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.request_type == "International"
                                        ? data?.to_continent_name +
                                          `-${data?.to_country_name}-${
                                            data?.to_city_name == "Other"
                                              ? data?.to_city_input
                                              : data?.to_city_name
                                          }`
                                        : data?.to_city_name == "Other"
                                        ? data?.to_city_input
                                        : data?.to_city_name}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {moment(data?.to_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {moment(
                                        "2023-1-1 " + data?.to_time
                                      ).format("HH:mm")}
                                    </td>
                                    {/* {traveluserDetails[0]?.other_details?.sales_dist && <td>{data?.hq === true ?`Yes` : `No`}</td>} */}
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.hq === true ? `Yes` : `No`}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.purpose}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.mode_name}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      {data?.colleague_user_code}
                                    </td>
                                    <td
                                      class={
                                        data?.reason_for_exception_name
                                          ? `text-danger`
                                          : ""
                                      }
                                    >
                                      No
                                    </td>
                                    {/* {data?.hotel === true ? (
                                  <td>YES</td>
                                ) : (
                                  <td>NO</td>
                                )} */}
                                    {data?.stay_needed ? (
                                      <td
                                        class={
                                          data?.reason_for_exception_name
                                            ? `text-danger`
                                            : ""
                                        }
                                      >
                                        Yes
                                      </td>
                                    ) : (
                                      <td
                                        class={
                                          data?.reason_for_exception_name
                                            ? `text-danger`
                                            : ""
                                        }
                                      >
                                        No
                                      </td>
                                    )}
                                  </tr>
                                );
                                // });
                              })
                            ) : (
                              <tr>
                                <td colSpan={20}>No Records Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {travelDetails?.ticket_expense?.length > 0 && (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>Ticket Details</strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-3">
                            <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Boarding On</th>
                                  <th>Ticket Between</th>

                                  <th>Paid By</th>
                                  <th>Mode</th>
                                  <th>Vendor Name</th>
                                  <th>Invoice No</th>
                                  <th>Invoice Date</th>
                                  <th>PNR</th>
                                  <th>Airline</th>
                                  <th>Ven Type</th>
                                  <th>Ven. State</th>
                                  <th>Ven. GSTNo</th>
                                  <th>Tax %</th>
                                  <th>Tax Amt.</th>
                                  <th>Other</th>
                                  <th>Bill Amt.</th>
                                  <th>Total Claim</th>
                                  {/* <th>Appr. Base Amt.</th> */}
                                  <th>Appr. Tax %</th>
                                  <th>Appr. Tax Amt.</th>
                                  <th>Appr. Other Amt.</th>
                                  <th>Appr. Amount</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {newticketDetails?.map((data) => {
                                  return (
                                    <tr>
                                      <td>
                                        {data?.travel_date &&
                                          moment
                                            .parseZone(data?.travel_date)
                                            .format("DD-MM-YYYY")}
                                      </td>
                                      <td>
                                        {data?.from_location_name == "Other"
                                          ? data?.from_city_input
                                          : data?.from_location_name}
                                        -
                                        {data?.to_location_name == "Other"
                                          ? data?.to_city_input
                                          : data?.to_location_name}
                                      </td>

                                      <td>
                                        {data?.paid_by
                                          ? data?.paid_by[0].toUpperCase() +
                                            data?.paid_by.slice(1)
                                          : ""}
                                      </td>
                                      <td>{data?.modee_name}</td>
                                      <td>{data?.vendor_name}</td>
                                      <td>{data?.invoice_no}</td>
                                      <td>
                                        {moment
                                          .parseZone(data?.invoice_date)
                                          .format("DD-MM-YYYY")}
                                      </td>
                                      <td>
                                        {data?.modee_name === "Air" ||
                                        data?.modee_name === "Train"
                                          ? data?.pnr_no
                                          : data?.ticket_no}
                                      </td>
                                      <td>{data?.airline_name}</td>
                                      <td>{data?.vendor_category}</td>
                                      <td>{data?.vendor_gst_state}</td>
                                      <td>{data?.vendor_gst_no}</td>
                                      <td>
                                        {data?.tax_rate_name
                                          ? data?.tax_rate_name
                                          : 0.0}
                                      </td>
                                      <td>{data?.tax_amount?.toFixed(2)}</td>
                                      {/* <td><input type="text" value={""} /></td> */}
                                      <td>{data?.other_charges?.toFixed(2)}</td>
                                      <td>
                                        {data?.taxable_amount?.toFixed(2)}
                                      </td>
                                      <td>
                                        {(
                                          data?.taxable_amount +
                                          data?.tax_amount +
                                          data?.other_charges
                                        ).toFixed(2)}
                                      </td>
                                      {/* <td><input type="text" value={""} /></td> */}
                                      <td>
                                        {data?.appr_tax_rate_name
                                          ? data?.appr_tax_rate_name
                                          : 0.0}
                                      </td>
                                      <td>
                                        {data?.appr_tax_amount?.toFixed(2)}
                                      </td>
                                      <td>
                                        {data?.appr_other_charges?.toFixed(2)}
                                      </td>
                                      <td>
                                        {(
                                          data?.appr_taxable_amount +
                                          data?.appr_tax_amount +
                                          data?.appr_other_charges
                                        ).toFixed(2)}
                                      </td>
                                      {/* <td>-</td> */}
                                    </tr>
                                  );
                                })}
                                <tr className="font-weight-bold">
                                  <td></td>
                                  <td></td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{ticketSums.taxamt?.toFixed(2)}</td>
                                  <td>{ticketSums.other?.toFixed(2)}</td>
                                  <td>{ticketSums.billamt?.toFixed(2)}</td>
                                  <td>{ticketSums.claimed?.toFixed(2)}</td>

                                  <td></td>
                                  <td>{ticketSums.apprtax?.toFixed(2)}</td>
                                  <td>{ticketSums.approther?.toFixed(2)}</td>
                                  <td>{ticketSums.appramt?.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="pl-3 mb-3 border-bottom">
                            <div class="row">
                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "TICKET"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Reason:{" "}
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "TICKET"
                                          )
                                        ].reason
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-sm-3">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied:{" "}
                                  <i class="fas fa-rupee-sign f-12"></i>
                                  {allApproved.totalTicketValue?.toFixed(2)}
                                </p>
                              </div>

                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "TICKET"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Approved:{" "}
                                    <i class="fas fa-rupee-sign f-12"></i>
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "TICKET"
                                          )
                                        ].approved_amount?.toFixed(2)
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {hotelDetails?.length > 0 && (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>Hotel Details</strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-3">
                            <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Check In Date</th>
                                  <th>Check Out Date</th>
                                  <th>City / Entitlement (Days)</th>
                                  <th>Stay At</th>
                                  {/* <th>Paid By</th> */}
                                  <th>Hotel Name</th>
                                  <th>Ven Type</th>
                                  <th>Ven. State</th>
                                  <th>Ven. GSTNo</th>
                                  <th>Invoice</th>
                                  <th>Bill Date</th>
                                  <th>Sharing</th>
                                  <th>Bill Basic Amt</th>

                                  <th>Claim Amt.</th>
                                  <th>Tax %</th>
                                  <th>Tax Amt.</th>
                                  {/* <th>Other Charges</th> */}
                                  <th>Total Claim Amt.</th>
                                  <th>Appr. Bill Base Amt</th>
                                  <th>Appr. Claim Amt.</th>
                                  <th>Appr. Tax Rate</th>
                                  <th>Appr. Tax Amt.</th>
                                  {/* <th>Appr. Other Charges</th> */}
                                  <th>Approved Amount</th>
                                  <th>Exc.Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {hotelDetails?.length > 0 ? (
                                  hotelDetails?.map((data) => {
                                    return (
                                      <tr>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {moment
                                            .parseZone(data?.check_in_date)
                                            .format("DD-MM-YYYY")}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {moment(data?.check_out_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.city_name === "Other"
                                            ? data?.city_input
                                            : data?.city_name}
                                          -{data?.city_category}-
                                          {data?.city_entitlement}({data?.days})
                                          {/* {data?.city_name}-
                                          {data?.city_category} -
                                          {data?.city_entitlement}({data?.days}) */}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.stay_at}
                                        </td>
                                        {/* <td style={data?.is_exception ? dangerRow : null}>{data?.expense_by}</td> */}
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.hotel_name}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.hotel_vendor_category}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.hotel_state_name}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.hotel_gst_no}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.invoice_no}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.stay_at
                                            ?.toLowerCase()
                                            ?.includes("friend")
                                            ? "-"
                                            : data?.invoice_date
                                            ? moment(data?.invoice_date).format(
                                                "DD-MM-YYYY"
                                              )
                                            : ""}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.sharing === false
                                            ? "No"
                                            : `${data?.person_one_username}` +
                                              `${
                                                data?.person_two_username
                                                  ? `, ` +
                                                    data?.person_two_username
                                                  : ``
                                              }`}
                                        </td>

                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.taxable_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.claim_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.tax_rate_value}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.tax_amount?.toFixed(2)}
                                        </td>
                                        {/* <td style={data?.is_exception ? dangerRow : null}>{data?.other_charges?.toFixed(2)}</td> */}
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.total_claim_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.appr_bill_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.appr_claim_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.appr_tax_rate_value}
                                          {/* <input
                                type="text"
                                value={data?.tax_rate_value}
                                onChange={(e) => {
                                  handleChange(e, "appr_tax_rate");
                                }}
                              /> */}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.appr_tax_amount?.toFixed(2)}
                                          {/* <input
                                type="text"
                                value={data?.tax_amount}
                                onChange={(e) => {
                                  handleChange(e, "appr_tax_amount");
                                }}
                              /> */}
                                        </td>
                                        {/* <td style={data?.is_exception ? dangerRow : null}>
                                  {data?.other_charges?.toFixed(2)}
                                  
                                </td> */}
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.appr_amount?.toFixed(2)}
                                          {/* <input
                                type="text"
                                value={data?.total_claim_amount}
                                onChange={(e) => {
                                  handleChange(e, "appr_amount");
                                }}
                              /> */}
                                        </td>
                                        <td
                                          style={
                                            data?.is_exception
                                              ? dangerRow
                                              : null
                                          }
                                        >
                                          {data?.reason_for_exception_name}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={20}>No Records Found...</td>
                                  </tr>
                                )}
                                <tr className="font-weight-bold">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  {/* <td>> */}
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{hotelSums.billamt?.toFixed(2)}</td>

                                  <td>{hotelSums.claimed?.toFixed(2)}</td>
                                  <td></td>
                                  <td>{hotelSums.taxamt?.toFixed(2)}</td>
                                  {/* <td>> */}
                                  <td>{hotelSums.totalclaim?.toFixed(2)}</td>
                                  <td>{hotelSums.apprbasic?.toFixed(2)}</td>
                                  <td>{hotelSums.apprclaim?.toFixed(2)}</td>
                                  <td></td>
                                  <td>{hotelSums.apprtax?.toFixed(2)}</td>
                                  {/* <td>> */}
                                  <td>{hotelSums.appramt?.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="pl-3 mb-3 border-bottom">
                            <div class="row">
                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "HOTEL"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Reason:{" "}
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "HOTEL"
                                          )
                                        ].reason
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-sm-3">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied:{" "}
                                  <i class="fas fa-rupee-sign f-12"></i>
                                  {allApproved.totalHotelValue?.toFixed(2)}
                                </p>
                              </div>

                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "HOTEL"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Approved:{" "}
                                    <i class="fas fa-rupee-sign f-12"></i>
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "HOTEL"
                                          )
                                        ].approved_amount?.toFixed(2)
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {daDetails?.length > 0 && (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>DA Details</strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-3">
                            <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Hours-Claim</th>
                                  <th>City/Entitlement</th>
                                  <th>Total Claim Amt</th>
                                  <th>Remarks</th>
                                  {/* <th>Places</th> */}
                                  {/* <th>Day/Km</th> */}
                                  <th>Day/Leave</th>
                                  <th>Approved DA</th>
                                  <th>Total Appr. Amt.</th>
                                </tr>
                              </thead>
                              <tbody>
                                {daDetails?.length > 0 ? (
                                  daDetails?.map((data) => {
                                    return (
                                      <tr>
                                        <td>
                                          {moment(data?.da_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>{data?.hours?.toFixed(2)}</td>
                                        <td>
                                          {" "}
                                          {data?.city_name === "Other"
                                            ? data?.city_input
                                            : data?.city_name}
                                          -{data?.category}-{" "}
                                          {data?.entitlement
                                            ? data?.entitlement?.toFixed(2)
                                            : "0.00"}
                                          {/* {data?.city_name}-{data?.category}-{data?.entitlement?.toFixed(2)} */}
                                        </td>
                                        <td>{data?.gross_total?.toFixed(2)}</td>
                                        <td class="mx-100">
                                          {data?.remarks}
                                          {/* All food expenses paid by the employee (30%) */}
                                        </td>
                                        {/* <td>ok</td> */}
                                        <td className="text-danger">
                                          {data?.purpose
                                            ?.toLowerCase()
                                            ?.includes("leave")
                                            ? "Leave"
                                            : data?.purpose
                                                ?.toLowerCase()
                                                ?.includes("holiday")
                                            ? weekdays[
                                                new Date(data?.da_date).getDay()
                                              ] == "Sunday"
                                              ? "Sunday"
                                              : "Holiday"
                                            : ""}
                                        </td>
                                        {/* <td>{
                            data?.purpose?.toLowerCase()?.includes("leave")
                            ?"Leave"
                            :data?.purpose?.toLowerCase()?.includes("holiday")
                            ?"Holiday"
                            :"-"
                            }</td> */}
                                        <td class="mw-100">
                                          {data?.remarks == "Headquarter DA"
                                            ? data?.appr_da_remarks
                                            : data?.dropDownKey?.map((opt) =>
                                                opt?.value ==
                                                data?.SelectedKey[0].value
                                                  ? opt?.label
                                                  : ""
                                              )}
                                        </td>
                                        <td>
                                          {data?.appr_da_amount?.toFixed(2)}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={20}>No Records Found...</td>
                                  </tr>
                                )}

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <strong>
                                      {allApproved.totalDaValue?.toFixed(2)}
                                    </strong>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td>
                                    <strong>
                                      {allApproved.totalDaValue2?.toFixed(2)}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="pl-3 mb-3 border-bottom">
                            <div class="row">
                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "DA"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Reason:{" "}
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) => obj.expense_type === "DA"
                                          )
                                        ].reason
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-sm-3">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied:{" "}
                                  <i class="fas fa-rupee-sign f-12"></i>
                                  {allApproved?.totalDaValue?.toFixed(2)}
                                </p>
                              </div>

                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "DA"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Approved:{" "}
                                    <i class="fas fa-rupee-sign f-12"></i>
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) => obj.expense_type === "DA"
                                          )
                                        ].approved_amount?.toFixed(2)
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {vehicleDetails?.length > 0 && (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>
                                Vehicle Log Details Vehicle No:-{" "}
                                {traveluserDetails[0]?.vehicle_no
                                  ? traveluserDetails[0].vehicle_no
                                  : ""}
                              </strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-3">
                            <table class="viewpoprinttab viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Starting Kms</th>
                                  <th>Closing Kms</th>
                                  <th>Total Kms</th>
                                  <th>Fuel Qty</th>
                                  <th>Fuel Amount</th>
                                  <th>Appr. Fuel Amt.</th>
                                  <th>Driver Daily Wages</th>
                                  <th>Appr. Wages</th>
                                  <th>Driver DA</th>
                                  <th>Driver DA Val</th>
                                  <th>Appr. Drv. DA Val</th>
                                  <th>Other</th>
                                  <th>Appr. Other</th>
                                  <th>Washing</th>
                                  <th>Appr. Washing</th>
                                  <th>Travel With</th>
                                  <th>Partner Name</th>
                                  <th>Vehicle Type</th>
                                  <th>Total</th>
                                  <th>Total Appr. Amt.</th>
                                </tr>
                              </thead>

                              <tbody>
                                {vehicleDetails?.length > 0
                                  ? vehicleDetails?.map((data) => (
                                      <tr>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {moment
                                            .parseZone(data?.travel_date)
                                            .format("DD-MM-YYYY")}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.start_kms}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.close_kms}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.total_kms}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.fuel_quantity?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.fuel_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.appr_fuel_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.driver_daily_wages?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.appr_driver_daily_wages?.toFixed(
                                            2
                                          )}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.driver_da_type == ""
                                            ? "Local"
                                            : daType[data?.driver_da_type]}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.driver_da_value?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.appr_driver_da_value?.toFixed(
                                            2
                                          )}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.other_expenses?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.appr_other_expenses?.toFixed(
                                            2
                                          )}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.washing_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.appr_washing_amount?.toFixed(
                                            2
                                          )}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.travel_with}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.partner_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.modee_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.total_amount?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.approved_amount.toFixed(2)}
                                        </td>
                                      </tr>
                                    ))
                                  : ""}

                                {vehicleDetails?.length > 0 && (
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.total_kms}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.fuel_quantity}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.fuel_amount.toFixed(2)}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.appr_fuel_amount.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.driver_daily_wages.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.appr_driver_daily_wages.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.driver_da_value.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.appr_driver_da_value.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.other_expenses.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.appr_other_expenses.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.washing_amount.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      {vehicleLogSums?.appr_washing_amount.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td></td>

                                    <td></td>
                                    <td></td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.total_amount.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {vehicleLogSums?.approved_amount.toFixed(
                                          2
                                        )}
                                      </strong>
                                    </td>
                                    <td></td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div class="pl-3 mb-3 border-bottom">
                            <div class="row">
                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "VEHICLE_LOG"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Reason:{" "}
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "VEHICLE_LOG"
                                          )
                                        ].reason
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-sm-3">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied:{" "}
                                  <i class="fas fa-rupee-sign f-12"></i>
                                  {allApproved.totalVehLogValue?.toFixed(2)}
                                </p>
                              </div>

                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "VEHICLE_LOG"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Approved:{" "}
                                    <i class="fas fa-rupee-sign f-12"></i>
                                    {userGroup._userGroup == "ssc user"
                                      ? allApproved.totalVehLogValue2?.toFixed(
                                          2
                                        )
                                      : travelDetails?.approval_reasons
                                          ?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type === "VEHICLE_LOG"
                                          )
                                        ].approved_amount?.toFixed(2)
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {vehicleData["monthly limit"] ? (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>VEHICLE SUMMARY</strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-4">
                            <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Monthly KM limit</th>
                                  <th>KM Travelled in current Month</th>
                                  <th>
                                    KM Travelled - in current travel claim
                                  </th>
                                  <th>Travelled KM MTD</th>
                                  <th>Excess KM</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{vehicleData["monthly limit"]}</td>
                                  <td>{vehicleData["km traveled"]}</td>
                                  <td>
                                    {vehicleData["current travel km travelled"]} - ({vehicleData["current_month_current_travel_km_travelled"]} Current Month KM)
                                  </td>
                                  <td>{vehicleData["traveled km MTD"]}</td>
                                  <td>{vehicleData["Excess KM"]}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {conveyanceDetails?.length > 0 && (
                        <>
                          <div class="mb-2 ml-3">
                            <h6 class="text-theme mb-0">
                              <i class="far fa-arrow-alt-circle-right"></i>{" "}
                              <strong>Local Conveyance Details</strong>
                            </h6>
                          </div>

                          <div class="table-responsive mb-3">
                            <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>From-To</th>

                                  <th>Travel By</th>
                                  <th>Vendor State</th>
                                  <th>Vendor Name</th>
                                  <th>Invoice Number</th>
                                  <th>Invoice Date</th>
                                  <th>Amount</th>
                                  <th>Approved Amount</th>
                                </tr>
                              </thead>

                              <tbody>
                                {conveyanceDetails?.length > 0
                                  ? conveyanceDetails?.map((data) => (
                                      <tr>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {moment
                                            .parseZone(data?.conveyance_date)
                                            .format("DD-MM-YYYY")}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.from_location_name}-
                                          {data?.to_location_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.mode_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.vendor_state_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.vendor_name}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.vendor_invoice}
                                        </td>

                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.mode_name
                                            ?.toLowerCase()
                                            ?.includes("rickshaw") ||
                                          data?.mode_name
                                            ?.toLowerCase()
                                            ?.includes("riks") ||
                                          data?.mode_name
                                            ?.toLowerCase()
                                            ?.includes("public transport")
                                            ? "-"
                                            : moment
                                                .parseZone(data?.invoice_date)
                                                .format("DD-MM-YYYY")}
                                        </td>

                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.amount?.toFixed(2)}
                                        </td>
                                        <td
                                          class={
                                            data?.is_exception
                                              ? `text-danger`
                                              : ""
                                          }
                                        >
                                          {data?.approved_amount?.toFixed(2)}
                                        </td>
                                      </tr>
                                    ))
                                  : "No Records Found..."}
                                <tr>
                                  <td></td>
                                  <td></td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {" "}
                                    <strong>
                                      {allApproved.totalConveValue?.toFixed(2)}{" "}
                                    </strong>
                                  </td>
                                  <td>
                                    {" "}
                                    <strong>
                                      {allApproved.totalConveValue2?.toFixed(2)}{" "}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="pl-3 mb-3 border-bottom">
                            <div class="row">
                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "VEHICLE_LOG"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Reason:{" "}
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type ===
                                              "LOCAL_CONVEYANCE"
                                          )
                                        ].reason
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="col-sm-3">
                                {/* <p style={{color:'black', float:'left'}}></p> */}
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Applied:{" "}
                                  <i class="fas fa-rupee-sign f-12"></i>
                                  {allApproved.totalConveValue?.toFixed(2)}
                                </p>
                              </div>

                              {travelDetails?.approval_reasons?.findIndex(
                                (obj) => obj.expense_type === "LOCAL_CONVEYANCE"
                              ) > -1 ? (
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Approved:{" "}
                                    <i class="fas fa-rupee-sign f-12"></i>
                                    {travelDetails?.approval_reasons?.length > 0
                                      ? travelDetails?.approval_reasons[
                                          travelDetails?.approval_reasons?.findIndex(
                                            (obj) =>
                                              obj.expense_type ===
                                              "LOCAL_CONVEYANCE"
                                          )
                                        ].approved_amount?.toFixed(2)
                                      : ""}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {traveluserDetails?.length > 0
                        ? traveluserDetails[0]?.request_type ==
                            "International" && (
                            <>
                              {" "}
                              <div>
                                <h6>
                                  Foreign Expenses ( Boarding & Lodging
                                  Entitlement (USD : {contAmount ?? "0"} , INR :{" "}
                                  {(
                                    contAmount *
                                      currencyList[
                                        currencyList.findIndex(
                                          (obj) => obj?.name == "USD"
                                        )
                                      ]?.amount ?? 1
                                  )
                                    //  fExp
                                    ?.toFixed(2)}
                                  ))
                                </h6>
                              </div>
                              <div>
                                <h6>
                                  Exchange Rates [{" "}
                                  {currencyList?.map((cdata) =>
                                    cdata?.amount ? (
                                      <span>
                                        {cdata?.name}:
                                        {cdata?.amount?.toFixed(2)}{" "}
                                      </span>
                                    ) : (
                                      " "
                                    )
                                  )}
                                  ]
                                </h6>
                              </div>
                              {foreignDetails.hotelDetail.length > 0 ? (
                                <>
                                  <div class="mb-2 ml-3">
                                    <h6 class="text-theme mb-0">
                                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                                      <strong>Hotel Details</strong>
                                    </h6>
                                  </div>

                                  <div class="table-responsive mb-3">
                                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                      <thead>
                                        <tr>
                                          <th>Hotel Name</th>
                                          <th>Stay From</th>
                                          <th>Stay Till</th>
                                          <th>Paid By</th>
                                          <th>Vendor Invoice</th>
                                          <th>Invoice Date</th>
                                          <th>Currency</th>
                                          <th>Amt(in Forex)</th>
                                          <th>Amount (in INR)</th>
                                          <th>Approved Amt (in Forex)</th>
                                          <th>Exchange Rate</th>
                                          <th>Approved Amt (in INR)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {foreignDetails.hotelDetail?.map(
                                          (fordata, idx) => (
                                            <tr>
                                              <td>{fordata.hotel_name}</td>
                                              <td>
                                                {fordata.check_in_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.check_in_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>
                                                {fordata.check_out_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.check_out_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>{fordata.paid_by}</td>
                                              <td>{fordata.vendor_invoice}</td>
                                              <td>
                                                {fordata.invoice_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.invoice_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>{fordata.currency_name}</td>
                                              <td>
                                                {fordata.amount_in_forex?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.amount_in_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_exchange_rate?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>

                                  <div class="pl-3 mb-3 border-bottom">
                                    <div class="row">
                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type === "FOREIGN_HOTEL"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Reason:{" "}
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_HOTEL"
                                                  )
                                                ].reason
                                              : ""}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="col-sm-3">
                                        {/* <p style={{color:'black', float:'left'}}></p> */}
                                        <p
                                          style={{
                                            color: "black",
                                            marginTop: "10px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Applied:{" "}
                                          <i class="fas fa-rupee-sign f-12"></i>
                                          {allApproved.totalForegHotel?.toFixed(
                                            2
                                          )}
                                        </p>
                                      </div>

                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type === "FOREIGN_HOTEL"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Approved:{" "}
                                            <i class="fas fa-rupee-sign f-12"></i>
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_HOTEL"
                                                  )
                                                ].approved_amount?.toFixed(2)
                                              : allApproved.totalForegHotel2?.toFixed(
                                                  2
                                                )}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {foreignDetails.foodDetail.length > 0 ? (
                                <>
                                  <div class="mb-2 ml-3">
                                    <h6 class="text-theme mb-0">
                                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                                      <strong>Food Details</strong>
                                    </h6>
                                  </div>

                                  <div class="table-responsive mb-3">
                                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}
                                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                      <thead>
                                        <tr>
                                          <th>Date</th>
                                          <th>Vendor Name</th>
                                          <th>Paid By</th>
                                          <th>Currency</th>
                                          <th>Amount(in Forex)</th>
                                          <th>Amount (in INR)</th>
                                          <th>Approved Amt (in Forex)</th>
                                          <th>Exchange Rate</th>
                                          <th>Approved Amt (in INR)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {foreignDetails.foodDetail?.map(
                                          (fordata, idx) => (
                                            <tr>
                                              <td>
                                                {" "}
                                                {fordata.food_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.food_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>{fordata.vendor_name} </td>
                                              <td>{fordata.paid_by}</td>
                                              <td>{fordata.currency_name}</td>
                                              <td>
                                                {fordata.amount_in_forex?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.amount_in_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount?.toFixed(
                                                  2
                                                )}{" "}
                                              </td>
                                              <td>
                                                {fordata.appr_exchange_rate?.toFixed(
                                                  2
                                                )}{" "}
                                              </td>
                                              <td>
                                                {fordata.appr_amount_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>

                                  <div class="pl-3 mb-3 border-bottom">
                                    <div class="row">
                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type === "FOREIGN_FOOD"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Reason:{" "}
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_FOOD"
                                                  )
                                                ].reason
                                              : ""}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="col-sm-3">
                                        {/* <p style={{color:'black', float:'left'}}></p> */}
                                        <p
                                          style={{
                                            color: "black",
                                            marginTop: "10px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Applied:{" "}
                                          <i class="fas fa-rupee-sign f-12"></i>
                                          {allApproved.totalForegFood?.toFixed(
                                            2
                                          )}
                                        </p>
                                      </div>

                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type === "FOREIGN_FOOD"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Approved:{" "}
                                            <i class="fas fa-rupee-sign f-12"></i>
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_FOOD"
                                                  )
                                                ].approved_amount?.toFixed(2)
                                              : allApproved.totalForegFood2?.toFixed(
                                                  2
                                                )}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {foreignDetails.inciDaDetail.length > 0 ? (
                                <>
                                  <div class="mb-2 ml-3">
                                    <h6 class="text-theme mb-0">
                                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                                      <strong>Incidental Details</strong>
                                    </h6>
                                  </div>

                                  <div class="table-responsive mb-3">
                                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                      <thead>
                                        <tr>
                                          <th>Date</th>
                                          <th>Ent Currency</th>
                                          <th>Ent Amount(In Forex)</th>
                                          <th>Ent Amount(In INR)</th>
                                          <th>Currency</th>
                                          <th>Amount(in Forex)</th>
                                          <th>Amount (in INR)</th>
                                          <th>Approved Amt (in Forex)</th>
                                          <th>Exchange Rate</th>
                                          <th>Approved Amt (in INR)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {foreignDetails.inciDaDetail?.map(
                                          (fordata, idx) => (
                                            <tr>
                                              <td>
                                                {fordata.incidental_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.incidental_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>
                                                {fordata.ent_currency_name}
                                              </td>
                                              <td>
                                                {fordata.ent_amount_in_forex?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.ent_amount_in_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>{fordata.currency_name}</td>
                                              <td>
                                                {fordata.amount_in_forex?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.amount_in_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_exchange_rate?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>

                                  <div class="pl-3 mb-3 border-bottom">
                                    <div class="row">
                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) => obj.expense_type === "HOTEL"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Reason:{" "}
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_DA"
                                                  )
                                                ].reason
                                              : ""}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="col-sm-3">
                                        {/* <p style={{color:'black', float:'left'}}></p> */}
                                        <p
                                          style={{
                                            color: "black",
                                            marginTop: "10px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Applied:{" "}
                                          <i class="fas fa-rupee-sign f-12"></i>
                                          {allApproved.totalForegDaValue?.toFixed(
                                            2
                                          )}
                                        </p>
                                      </div>

                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type === "FOREIGN_DA"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Approved:{" "}
                                            <i class="fas fa-rupee-sign f-12"></i>
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_DA"
                                                  )
                                                ].approved_amount?.toFixed(2)
                                              : allApproved.totalForegDaValue2?.toFixed(
                                                  2
                                                )}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {foreignDetails.conveyDetail.length > 0 ? (
                                <>
                                  <div class="mb-2 ml-3">
                                    <h6 class="text-theme mb-0">
                                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                                      <strong>Conveyance Details</strong>
                                    </h6>
                                  </div>

                                  <div class="table-responsive mb-3">
                                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                      <thead>
                                        <tr>
                                          <th>Date</th>
                                          <th>Travel Mode</th>
                                          <th>From</th>
                                          <th>To</th>
                                          <th>Paid By</th>
                                          <th>Currency</th>
                                          <th>Amount(in Forex)</th>
                                          <th>Amount (in INR)</th>
                                          <th>Approved Amt (in Forex)</th>
                                          <th>Exchange Rate</th>
                                          <th>Approved Amt (in INR)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {foreignDetails.conveyDetail?.map(
                                          (fordata, idx) => (
                                            <tr>
                                              <td>
                                                {fordata.conveyance_date &&
                                                  moment
                                                    .parseZone(
                                                      fordata.conveyance_date
                                                    )
                                                    .format("DD-MM-YYYY")}
                                              </td>
                                              <td>{fordata.modee_name}</td>
                                              <td>{fordata.from_location}</td>
                                              <td>{fordata.to_location}</td>
                                              <td>{fordata.paid_by}</td>
                                              <td>{fordata.currency_name}</td>
                                              <td>
                                                {fordata.amount_in_forex?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.amount_in_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_exchange_rate?.toFixed(
                                                  2
                                                )}
                                              </td>
                                              <td>
                                                {fordata.appr_amount_inr?.toFixed(
                                                  2
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>

                                  <div class="pl-3 mb-3 border-bottom">
                                    <div class="row">
                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) => obj.expense_type === "HOTEL"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Reason:{" "}
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_CONVEYANCE"
                                                  )
                                                ].reason
                                              : ""}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div className="col-sm-3">
                                        {/* <p style={{color:'black', float:'left'}}></p> */}
                                        <p
                                          style={{
                                            color: "black",
                                            marginTop: "10px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          Applied:{" "}
                                          <i class="fas fa-rupee-sign f-12"></i>
                                          {allApproved.totalForegConveValue?.toFixed(
                                            2
                                          )}
                                        </p>
                                      </div>

                                      {travelDetails?.approval_reasons?.findIndex(
                                        (obj) =>
                                          obj.expense_type ===
                                          "FOREIGN_CONVEYANCE"
                                      ) > -1 ? (
                                        <div class="col-md-3">
                                          <p
                                            style={{
                                              color: "black",
                                              marginTop: "10px",
                                              fontWeight: "700",
                                            }}
                                          >
                                            Approved:{" "}
                                            <i class="fas fa-rupee-sign f-12"></i>
                                            {travelDetails?.approval_reasons
                                              ?.length > 0
                                              ? travelDetails?.approval_reasons[
                                                  travelDetails?.approval_reasons?.findIndex(
                                                    (obj) =>
                                                      obj.expense_type ===
                                                      "FOREIGN_CONVEYANCE"
                                                  )
                                                ].approved_amount?.toFixed(2)
                                              : allApproved.totalForegConveValue2?.toFixed(
                                                  2
                                                )}
                                          </p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )
                        : ""}

                      <div class="d-flex justify-content-start viewformaftersubmit m-0">
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Expense By Self</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_userclaimself}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Expense By Company</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_userclaimcompany}
                            </p>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Total Claim Amt.</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_claim}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Appr. Exp. By Company</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_approvedCompExp}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-start viewformaftersubmit m-0">
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Appr. Exp. By Self</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_approvedSelfExp}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Total Appr. Amt.</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {finalDetails?.final_approvedTotal?.toFixed(2)}
                            </p>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Deducted Amt.</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {(
                                finalDetails?.final_claim -
                                finalDetails?.final_approvedTotal
                              )?.toFixed(2)}
                            </p>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>
                              Delay:{" "}
                              <span class="text-danger">
                                {travelDetails?.travel_detail?.length > 0
                                  ? moment(travelDetails?.dispatch_date).diff(
                                      moment(
                                        travelDetails?.travel_detail[
                                          travelDetails?.travel_detail.length -
                                            1
                                        ]?.to_date,
                                        "YYYY-MM-DD"
                                      ),
                                      "days"
                                    ) + " days"
                                  : ""}{" "}
                              </span>
                              Deduction Percent: (
                              <span class="text-danger">
                                {/* <i class="fas fa-rupee-sign f-12"></i> */}
                                {/* { 
                               travelDetails ?  moment(travelDetails?.dispatch_date).diff(moment(travelDetails?.travel_detail[travelDetails?.travel_detail.length-1]?.to_date, "YYYY-MM-DD"), "days") > 7 ? (finalDetails.final_approvedTotal*.05).toFixed(2) : 0 : 0
                              }
                       */}

                                {travelDetails?.ssc_manager_status=='F'?0.0:`${delayAmt}%`}
                              </span>
                              )
                            </label>

                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {travelDetails?.ssc_manager_status=='F'?0.0:(delayAmt
                                ? (
                                    (delayAmt *
                                      finalDetails?.final_approvedTotal) /
                                    100
                                  )?.toFixed(2)
                                : 0.0)}
                              {/* <i class="fas fa-rupee-sign f-12"></i> {delayAmt?(delayAmt*finalDetails?.final_approvedTotal/100)?.toFixed(2):0.00} */}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex justify-content-start viewformaftersubmit m-0">
                        <div class="col-md-3">
                          <div class="form-group innergroup">
                            <label>Net Amount</label>
                            <p>
                              <i class="fas fa-rupee-sign f-12"></i>{" "}
                              {travelDetails?.ssc_manager_status=='F'?finalDetails?.final_approvedTotal?.toFixed(2):finalDetails?.final_netAmt}
                            </p>
                          </div>
                        </div>
                      </div>

                      {travelchats?.length > 0 ? (
                        <div class="row">
                          <div
                            className="col-md-12 text-white"
                            style={{ backgroundColor: "#0195d4" }}
                          >
                            Query Chain
                          </div>
                          {travelchats?.length
                            ? travelchats.map((cdata, idx) =>
                                cdata?.created_by_id !=
                                cdata?.travel_created_by_id ? (
                                  <div className="col-md-12">
                                    <b>SSC</b>

                                    <p>
                                      {cdata?.message}
                                      {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                                      {cdata?.attachment_list?.map(
                                        (attdata, aidx) => (
                                          <Link
                                            target="_blank"
                                            class="ml-2"
                                            to={attdata?.attachment_path}
                                          >
                                            <i class="fas fa-paperclip text-theme"></i>
                                          </Link>
                                        )
                                      )}

                                      <span className="ml-3">
                                        {moment
                                          .parseZone(cdata?.created_at)
                                          .format("DD-MM-YYYY HH:mm:ss")}
                                      </span>
                                    </p>
                                  </div>
                                ) : (
                                  <div className="col-md-12">
                                    <b>User</b>

                                    <p>
                                      {cdata?.message}
                                      {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                                      {cdata?.attachment_list?.map(
                                        (attdata, aidx) => (
                                          <Link
                                            target="_blank"
                                            class="ml-2"
                                            to={attdata?.attachment_path}
                                          >
                                            <i class="fas fa-paperclip text-theme"></i>
                                          </Link>
                                        )
                                      )}
                                      <span className="ml-3">
                                        {moment
                                          .parseZone(cdata?.created_at)
                                          .format("DD-MM-YYYY HH:mm:ss")}
                                      </span>
                                    </p>
                                  </div>
                                )
                              )
                            : ""}

                          {/* <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Total Claim Amt.</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i> {finalDetails?.final_claim}
                    </p>
                  </div>
                </div> */}
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Expense Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-4">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th>GL Type</th>
                          <th>Add Info</th>
                          <th>Bill No</th>
                          <th>Date</th>
                          <th>Reason for Expense</th>
                          <th>Reason for Deduction</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state?.data?.expense_data?.map((ed, i) => {
                          return (
                            <tr>
                              {console.log(ed, "edaa")}
                              <td>{ed?.gl_desc}</td>
                              <td>{ed?.remarks}</td>
                              <td>{ed?.bill_number}</td>
                              <td>
                                {" "}
                                {moment
                                  .utc(ed?.created_at)
                                  .format("DD-MM-YYYY,HH:mm")}
                              </td>
                              <td>-</td>
                              <td>-</td>
                              <td>{ed?.bill_amount}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}

                      {/* <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Remarks</strong>
                    </h6>
                  </div> */}

                      {/* <div class="table-responsive mb-4">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th>Approver</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center mt-3 pb-3">
              {state?.backpath ? (
                <Link to={backpath} class="btn btn-outline-secondary mr-2">
                  Back
                </Link>
              ) : (
                <></>
              )}

              <button
                class="btn btn-primary-inner bpi-main ml-3 mr-3"
                type="button"
                id="print-btn-2"
                onClick={Closewin}
              >
                Close
              </button>

              <button
                class="btn btn-primary-inner bpi-main"
                type="button"
                id="print-btn-1"
                onClick={Print}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTravelList;
