import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


  export const saveVPF = async (obj) => {
    return axios.post(`${AUTH_BASE_URL}/vpf`, obj);
  }
  export const getVPFDetails = async(queryParm)=>{
    return axios.get(`${AUTH_BASE_URL}/vpf?${queryParm}`)
  }
  export const updateVpf = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/vpf/vpf_update`, data);
  }
  export const editVpf = async (id, data) =>{
    return axios.put(`${AUTH_BASE_URL}/vpf/${id}`,data)
  }
  export const approveVPF = async (obj) => {
    return axios.post(`${AUTH_BASE_URL}/vpf/approve_vpf`, obj);
  }
  export const getExportValue = async (queryParm) => {
    return axios({
        url: `${AUTH_BASE_URL}/vpf/export?${queryParm}`,
        method: "GET",
        responseType: "blob",
    });
};
export const getCurrentvpf = async(queryParm)=>{
  return axios.get(`${AUTH_BASE_URL}/vpf/get_current_vpf?${queryParm}`)
}