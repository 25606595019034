import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as VendorService from "../../service/cashbookService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCashBookVendor = (props) => {
  const { addnewshow, onClose, onAddCallBack, editModuleData, edit } = props;
  console.log(editModuleData, "editModuleData");
  const options = [
    { value: "Registered", label: "Registered" },
    { value: "Unregistered", label: "Unregistered" },
    { value: "Composition", label: "Composition" },
  ];

  const [makingType, setMakingType] = useState([]);
  const [makingValue, setMakingValue] = useState();
  console.log("makingValue --  ==>>>", makingValue);
  const [gstCatValue, setGstCatValue] = useState();
  const [cashbookData, setCashbookData] = useState([]);
  const [cashBookVendor, setCashBookVendor] = useState({
    name: "",
    gst_no: undefined,
    gst_state_id: undefined,
    gst_type: "",
    vendor_address: "",
    is_active: true,
  });
  const [formValidation, setFormValidation] = useState({
    name: "",
    gst_no: "",
    gst_state_id: "",
    gst_type: "",
    vendor_address: "",
  });

  const getAllStateList = async (paginate) => {
    await VendorService.getAllStateList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setMakingType(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const pushManufactureId = (e) => {
    setMakingValue(e);
    setCashBookVendor({
      ...cashBookVendor,
      gst_state_id: e.value,
    });
  };
  const pushCategoryId = (e) => {
    setGstCatValue(e);
    setCashBookVendor({
      ...cashBookVendor,
      gst_type: e.value,
    });
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let regexName = /^[A-Za-z]+$/;
    if (!cashBookVendor.name) {
      isError = true;
      formerr.name = "*Vendor Name is required ";
      setFormValidation(formerr);
    } else if (regexName.test(cashBookVendor.name) == false) {
      // isError = true;
      // formerr.name = "*Vendor Name can contain only alphabets";
      // setFormValidation(formerr);
    }
    if (
      regexforGst.test(cashBookVendor.gst_no) == false &&
      cashBookVendor.gst_type == "Registered"
    ) {
      isError = true;
      formerr.gst_no = "*Wrong GST number";
      setFormValidation(formerr);
    }

    if (
      !cashBookVendor.gst_state_id &&
      cashBookVendor.gst_type == "Registered"
    ) {
      isError = true;
      formerr.gst_state_id = "GST State is required";
      setFormValidation(formerr);
    }
    if (!cashBookVendor.gst_type) {
      isError = true;
      formerr.gst_type = "*GST Type is required";
      setFormValidation(formerr);
    }
    if (!cashBookVendor.gst_no && cashBookVendor.gst_type == "Registered") {
      isError = true;
      formerr.gst_no = "*GST No is required";
      setFormValidation(formerr);
    }

    if (!cashBookVendor.vendor_address) {
      isError = true;
      formerr.vendor_address = "*Vendor Address is required";
      setFormValidation(formerr);
    }
    // if (!cashBookVendor.issue_problem) {
    //   isError = true;
    //   formerr.issue_problem = "*Document Problem is Description required";
    //   setFormValidation(formerr);
    // }
    return isError;
  };
  const getAllCashbookList = (paginate) => {
    VendorService.getAllCashbookList(paginate)
      .then((response) => {
        setCashbookData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const updateVendor = async (id, data) => {
    await VendorService.updateVendor(id, data)
      .then((response) => {
        if (response.data.code == 200) {
          toast.success("Vendor Updated Successfully");
          onAddCallBack();
          onClose();
          getAllCashbookList(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const saveVendor = async () => {
    await VendorService.saveVendor(cashBookVendor)
      .then((response) => {
        if (response.data.code == 200) {
          toast.success("Vendor Created Successfully");
          onAddCallBack();
          onClose();
          getAllCashbookList(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidateForm = (cashBookVendor) => {
    if (edit == false) {
      if (handleValidate()) return false;
      saveVendor();
    } else {
      if (handleValidate()) return false;
      updateVendor(cashBookVendor?.id, cashBookVendor);
    }
  };
  const DataPush = (events) => {
    setCashBookVendor({
      ...cashBookVendor,
      is_active: events.target.value == "true" ? true : false,
    });
  };
  {
    console.log(editModuleData, "editModuleData");
  }
  useEffect(() => {
    getAllStateList(false);
  }, []);

  useEffect(() => {
    if (editModuleData) {
      // setCashBookVendor(editModuleData);
      Object.keys(editModuleData).forEach((item) => {
        if (item === "gst_type") {
          let value = options?.filter((obj) => {
            if (obj.value === editModuleData[item]) {
              return true;
            }
          })[0];
          setGstCatValue(value);
        }
        if (item === "gst_state_id") {
          let value = makingType?.filter((obj) => {
            if (obj.value === Number(editModuleData[item])) {
              return true;
            }
          })[0];
          setMakingValue(value);
          setCashBookVendor((prev) => ({
            ...prev,
            gst_state_id: value?.value,
          }));
        } else {
          cashBookVendor[item] = editModuleData[item];
        }
      });
    }
  }, [editModuleData, makingType]);

  return (
    <div>
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title> {edit === true ? "Edit" : "Add"} Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vendor Name"
                      name="name"
                      maxLength={100}
                      onChange={(e) => {
                        setCashBookVendor({
                          ...cashBookVendor,
                          name: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          name: "",
                        });
                      }}
                      value={cashBookVendor?.name}
                    />
                    <div className="small text-danger">
                      {formValidation.name}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      GST Category<span class="text-danger">*</span>
                    </label>
                    {/* <select class="form-control newbgselect">
                      <option value="">Select</option>
                      <option value="Registered">Registered</option>
                      <option value="Unregistered">Unregistered</option>
                      <option value="Composition">Composition</option>
                    </select> */}

                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="gst_type"
                      onChange={(e) => {
                        pushCategoryId(e);
                        setFormValidation({
                          ...formValidation,
                          gst_type: "",
                        });
                      }}
                      value={gstCatValue}
                    />
                    <div className="small text-danger">
                      {formValidation.gst_type}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      GST State<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={makingType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="gst_state_id"
                      onChange={(e) => {
                        pushManufactureId(e);
                        setFormValidation({
                          ...formValidation,
                          gst_state_id: "",
                        });
                      }}
                      value={makingValue}
                    />
                    {console.log("makingValue==>>>", makingValue)}
                    {console.log("makingType==>>>", makingType)}
                    {cashBookVendor.gst_type == "Registered" ? (
                      <div className="small text-danger">
                        {formValidation.gst_state_id}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>GST No</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter GST No"
                      name="gst_no"
                      maxLength={15}
                      onChange={(e) => {
                        setCashBookVendor({
                          ...cashBookVendor,
                          gst_no: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          gst_no: "",
                        });
                      }}
                      value={cashBookVendor?.gst_no}
                    />

                    {cashBookVendor.gst_type == "Registered" ? (
                      <div className="small text-danger">
                        {formValidation.gst_no}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {console.log(formValidation, "formValidation ")}
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Address<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vendor Address"
                      name="vendor_address"
                      maxLength={50}
                      onChange={(e) => {
                        setCashBookVendor({
                          ...cashBookVendor,
                          vendor_address: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          vendor_address: "",
                        });
                      }}
                      value={cashBookVendor.vendor_address}
                    />
                    <div className="small text-danger">
                      {formValidation.vendor_address}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="active"
                    >
                      Active
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={true}
                        checked={
                          cashBookVendor?.is_active == true ? true : false
                        }
                        onChange={(e) => {
                          DataPush(e);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="Inactive"
                    >
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={false}
                        onChange={(e) => {
                          DataPush(e);
                        }}
                        checked={
                          cashBookVendor?.is_active == true ? false : true
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => onClose()}>
            Cancel
          </button>

          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              handleValidateForm(cashBookVendor);
            }}
            type="submit"
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCashBookVendor;
