import React, { useEffect, useState } from "react";
import { Tab, ProgressBar, Spinner } from "react-bootstrap";
import * as HeaderService from "../../service/jv_service";
import * as otvService from "../../service/otvServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NonPoBulkImport = (props) => {
  const { importclose, importValue } = props;
  const [uploadData, setUploadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [excelListImport, setExcelListImport] = useState([]);
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });

  const onProfileChange = (e, flag) => {
    setShowImport({ ...showImport, downloadFlag: false });
    const files = e.target.files[0];
    if (
      files.type === "text/csv" ||
      files.type === "image/xls" ||
      files.type === "image/xlsx" ||
      files.type === "image/XLSX" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      files.type === "application/vnd.ms-excel"
    ) {
      const fileData = new FormData();
      console.log(files, "profile changed");
      fileData.append("uploaded_file", files);
      HeaderService.uploadImages(fileData).then((response) => {
        if (flag === "import_NonPo_data") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
      });
    } else {
      toast.error("Only csv, xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const importExelNOnPo = (values, type) => {
    setShowImport({ ...showImport, importFlag: false });
    setIsLoading(true);
    otvService
      .importExelNOnPo(values, type)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          setShowImport({ ...showImport, importFlag: true });
          setIsLoading(false);
          getDirectoryDetails();
        }
      })
      .catch((error) => {
        toast.error(error.message.data.detail);
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDirectoryDetails = () => {
    otvService
      .getDirectoryDetails()
      .then((response) => {
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} record sucessfully`
          );
        }
        if (
          response?.data?.dataList[0]?.records_uploaded === 0 &&
          response?.data?.dataList[0]?.records_failed === 0
        ) {
          toast.warn("No Record Found");
        }
        if (response?.data?.dataList[0]?.records_failed > 0) {
          toast.error(
            `${response?.data?.dataList[0]?.records_failed} record unsucessfull`
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    setShowImport({
      ...showImport,
      importFlag: false,
      downloadFlag: false,
    });
  }, [importclose]);

  return (
    <div class="px-4 pb-2">
      <ToastContainer autoClose={1000} />
      <div class="row justify-content-center">
        <div class="col-md-11">
          <div className="drapdroparea upload-btn-wrapper text-center">
            <i class="fas fa-file-upload fa-2x"></i>
            <p>Upload the template file with updated data</p>
            <button class="btn btn-primary-inner">
              DRAG AND DROP HERE OR CHOOSE FILE
            </button>
            <div className="mt-3 downloadtemplate">
              <button class="editbtn">
                <i class="far fa-file-excel"></i>{" "}
                <a 
                href={importValue === "Non PO Vendor Payment Request" ? "/non_po_bulk.xls" : "/non_reimb_bulk.xls"} 
                download>
                  DOWNLOAD TEMPLATE {importValue === "Non PO Vendor Payment Request" && ( "1(With Cost Center)" )}
                </a>
              </button>
            </div>
            {importValue === "Non PO Vendor Payment Request" && (
            <div className="mt-3 downloadtemplate">
              <button class="editbtn">
                <i class="far fa-file-excel"></i>{" "}
                <a href="/non_po_bulk_2.xls" download>
                  DOWNLOAD TEMPLATE 2(With Business Area)
                </a>
              </button>
            </div>
            )}
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              className="form-control"
              onChange={(e) => onProfileChange(e, "import_NonPo_data")}
            />
          </div>

          {showImport.downloadFlag === true && (
            <div className="drapdroparea upload-btn-wrapper text-center">
              <span class="badge bad-status mb-3 badge-warning">
                {uploadData?.original_name}
                <i
                  class="far fa-times-circle"
                  onClick={() => {
                    setUploadData({});
                    setIsLoading(false);
                    setShowImport({
                      ...showImport,
                      importFlag: false,
                      downloadFlag: false,
                    });
                  }}
                ></i>
              </span>
              {isLoading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  as="span"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                showImport.importFlag === false && (
                  <button
                    className="btn btn-primary-inner"
                    type="button"
                    onClick={() => {
                      if (importValue === "Non PO Vendor Payment Request") {
                        importExelNOnPo(uploadData, "NONPO");
                      } else {
                        importExelNOnPo(uploadData, "NONREIMB");
                      }
                    }}
                  >
                    Proceed to Upload
                  </button>
                )
              )}
            </div>
          )}
          {showImport.importFlag === true && (
            <div className="drapdroparea upload-btn-wrapper text-center">
              <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
              <p>
                The system was unable to read few records from the uploaded
                file.
                <br />
                Please correct or remove those records from the file and upload
                again.
              </p>
              <span className="badge bad-status mb-3 badge-success">
                Records Succeded:
                {excelListImport[0]?.records_uploaded}
              </span>
              <span className="badge bad-status mb-3 badge-danger">
                Records Failed:
                {excelListImport[0]?.records_failed}
              </span>
              <div className="mt-3 downloadtemplate">
                <p>Check the uploaded file in case of error.</p>
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={() => {
                    downloadError();
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          )}

          {/* <table class="table uploadrecordstab">
            <thead>
              <tr>
                <th>Upload Date</th>
                <th>Uploaded By</th>
                <th>File Name</th>
                <th>Records Uploaded</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-theme">01-12-2022</td>
                <td>Aniket Singh</td>
                <td>employee_user.csv</td>
                <td>500</td>
              </tr>
              <tr>
                <td class="text-theme">01-12-2022</td>
                <td>Aniket Singh</td>
                <td>employee_user.csv</td>
                <td>500</td>
              </tr>
              <tr>
                <td class="text-theme">01-12-2022</td>
                <td>Aniket Singh</td>
                <td>employee_user.csv</td>
                <td>500</td>
              </tr>
              <tr>
                <td class="text-theme">01-12-2022</td>
                <td>Aniket Singh</td>
                <td>employee_user.csv</td>
                <td>500</td>
              </tr>
              <tr>
                <td class="text-theme">01-12-2022</td>
                <td>Aniket Singh</td>
                <td>employee_user.csv</td>
                <td>500</td>
              </tr>
            </tbody>
          </table> */}
        </div>

        {/* <div class="col-md-12">
          <div class="border-top my-3"></div>
        </div>

        <div class="col-md-12 mt-3 text-center">
          <button class="btn btn-outline-primary mr-2">Save</button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </div> */}

        <div class="col-md-12">
          <div class="travel-note">
            <h4>Notes :-</h4>
            <ul>
              <li>Maximum 100 line items are allowed in one request ID.</li>
              <li>
                First line in excel file should be as per specified format.
              </li>
              <li>
                Please make sure column data doesn't contain comma in entire
                format.
              </li>
              <li>Bill date must be in dd-mm-yyyy format.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonPoBulkImport;
