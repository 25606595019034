import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as CreateSkillService from "../../service/createSkill";
import { injectStyle } from "react-toastify/dist/inject-style";
import { object } from "yup";
import moment from "moment";
import ViewSkill from "../SkillMaster/ViewSkill";
if (typeof window !== "undefined") {
  injectStyle();
}

const ApproveSkill = (props) => {
  const [error, setError] = useState(false);
  const [approvalData, setApprovalData] = useState([]);

  const { onAddCallBack, isDisplay } = props;
  const keyData = {
    id: 0,
    skill_id: 0,
    employee_id: 0,
    current_level: "",
    planned_level: "",
    actual_level: "",
    status: "",
    reason: "",
    other: "",
    remarks: "",
    quarter: "",
    year: "",
  };
  const [planData, setPlanData] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [reviewData, setReviewData] = useState({
    id: 0,
    skill_id: 0,
    employee_id: 0,
    current_level: "",
    planned_level: "",
    actual_level: "",
    status: "",
    reason: "",
    other: "",
    remarks: "",
    quarter: isDisplay.quarter,
    year: isDisplay.year,
  });

  const getSKillPlan = (sort_by, planId) => {
    CreateSkillService.getSKillPlan(sort_by, planId, "approve")
      .then((response) => {
        let obj = response?.data?.dataList?.result.map((x) => {
          return {
            ...x,
            quarter: isDisplay.quarter,
            year: isDisplay.year,
          };
        });
        setPlanData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const validatereasons = () => {
    let finalData = planData.map((obj) => {
      let curObj = {};
      Object.keys(obj).map((x) => {
        if (Object.keys(keyData).includes(x)) {
          curObj[x] = obj[x];
        }
      });
      return curObj;
    });
    let count = 0;
    finalData.map((obj) => {
      if (
        (obj.status === "Pending" || obj.status === "Incomplete") &&
        (obj?.reason == null || obj?.reason == "")
      )
        count = count + 1;
    });
    if (count != 0) return false;
    else return true;
  };
  const updateReviewSkill = (status) => {
    let data = {};
    let finalData = approvalData.map((obj) => {
      let curObj = {};
      Object.keys(obj).map((x) => {
        if (Object.keys(keyData).includes(x)) {
          curObj[x] = obj[x];
        }
      });
      curObj["approval_status"] = status
      curObj["approval_remark"] = reviewData.remarks;
      return curObj;
    });
    data["payload"] = finalData;
    data["remarks"] = reviewData.remarks;
    data["action"] = "approve"
    data["plan_id"] = isDisplay.plan
    if (validatereasons()) {
      CreateSkillService.updateReviewSkill(data)
        .then((response) => {
          if (response.data.status === true && response.data.code === 200) {
            toast.success(response.data.message);
            getSKillPlan("id", isDisplay?.plan);
            onAddCallBack("Status");
            setReviewData({
              ...reviewData,
              remarks: "",
            });
          }
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const handleValidate = (status) => {
    if (reviewData?.remarks?.length == 0) {
      setError(true);
    } else {
      updateReviewSkill(status);
    }
  };

  const changeData = (value, name, index) => {
    let data = JSON.parse(JSON.stringify(planData));
    data[index][`${name}`] = value;
    setPlanData(data);
  };

  const onView = async (data) => {
    const response = await CreateSkillService.getSkillById(data.skill_Id)
    setViewForm(true);
    setViewFormData(response.data.dataList.result[0]);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  useEffect(() => {
    if (isDisplay?.plan !== undefined) {
      getSKillPlan("id", isDisplay?.plan);
    }
  }, [isDisplay]);

  const changeDropdown = (e, data) => {
    console.log(e.target.checked, data)
    if (e.target.checked) {
      let tempData = [...approvalData]
      tempData.push(data)
      setApprovalData(tempData)
    } else {
      let tempData = [...approvalData]
      tempData = tempData.filter(el => el.id != data.id)
      setApprovalData(tempData)
    }
  }

  const selectAll = (e) => {
    if (e.target.checked) {
      let tempData = [...approvalData]
      tempData = planData
      setApprovalData(tempData)
    } else {
      setApprovalData([])
    }
  }

  return (
    <>
      {isDisplay?.is_review && planData.length != 0 && planData[0].approval_status != "approved" ? (
        <>
          <div className="px-4 pb-2">
            <h5 className="text-dark fa-20 border-bottom pb-2 my-3">
              <strong>
                Approve Skill Plan for Quarter:{" "}
                {isDisplay?.quarter?.replace("-", " to ")} {isDisplay?.year}
              </strong>
            </h5>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered tablecured viewpo innergroup leavetableapply vehiclelog">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      name="select_all_skill"
                      id="select_all_skill"
                      value="select_all_skill"
                      onClick={(e) => {
                        selectAll(e)
                      }}
                      checked={approvalData.length == planData.length}
                      style={{
                        border: "none"
                      }}
                    />
                    <span className="checkmark"></span>
                  </th>
                  <th>Skill</th>
                  <th>Skill Description</th>
                  <th>Employee Code</th>
                  <th>Current Level</th>
                  <th>Planned Level</th>
                  <th>Actual Level</th>
                  <th>Status</th>
                  <th>Reason</th>
                  <th>Other</th>
                  <th>Updated At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {planData?.map((data, index) => (
                  <>
                    <tr key={`plan${index}`}>
                      <td>
                        <input
                          type="checkbox"
                          name={data?.id}
                          id={data?.id}
                          value={data?.id}
                          onClick={(e) => {
                            changeDropdown(e, data)
                          }}
                          checked={approvalData.findIndex(el => el.id == data?.id) == -1 ? false : true}
                        />
                        <span className="checkmark"></span>
                      </td>
                      <td>{data?.skill_Id}</td>
                      <td>{data?.skill_description}</td>
                      <td>
                        {data?.employee_code}- {data?.employee_name}
                      </td>
                      <td> {data?.current_level}</td>
                      <td>{data?.planned_level}</td>
                      <td>
                        {planData[index]?.actual_level}
                      </td>
                      <td>
                        {planData[index]?.status}
                      </td>
                      <td>
                        {planData[index]?.reason}
                      </td>
                      <td>
                        {planData[index]?.other}
                      </td>
                      <td> {moment
                        .utc(data?.timestamp)
                        .format("Do MMM YYYY, H:mm:ss A")}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-blanktd text-primary"
                          onClick={() => {
                            onView(data);
                          }}
                        >
                          <img src="images/eye.png" alt="" />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {planData?.length !== 0 ? (
            <>
              <div className="px-4 pb-2">
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group innergroup">
                        <label>
                          Remarks<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Please provide consolidated remarks for Approve..."
                          maxLength={150}
                          name="remarks"
                          onChange={(e) => {
                            setError(false);
                            console.log(
                              "e.target.value",
                              e.target.value.length
                            );
                            setReviewData({
                              ...reviewData,
                              remarks: e.target.value.trimStart(),
                            });
                          }}
                          value={reviewData?.remarks}
                        ></textarea>
                        {error && (
                          <div className="small text-danger">
                            *Remark is required
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 text-center">
                      <button
                        className="btn btn-primary-inner bpi-main"
                        type="submit"
                        onClick={() => handleValidate("approved")}
                      >
                        Approved
                      </button>
                      <button
                        className="btn btn-primary-inner bpi-main"
                        style={{ marginLeft: 10, background: "#FF0000", color: "#FFF", border: "none" }}
                        type="submit"
                        onClick={() => handleValidate("rejected")}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <p style={{ color: "#abaaa6", marginTop: "47px", marginLeft: "25px" }}>
          Approve skill plan is not open yet.
        </p>
      )}
      {viewForm ? (
        <>
          <ViewSkill
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ApproveSkill;
