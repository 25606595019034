import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";

const ExchangeModal = (props) => {
  const {
    visibleExchange,
    onCloseButtonClick,
    getRateList,
    state,
    exchangeDataUsd,
    checkActiveTab,
    updateCurrency,
    handleBackData,
  } = props;
  const [gridDataNew, setGridDataNew] = useState(props.gridDataNew);
  const [rateListData, setRateListData] = useState();
  const [editRateData, setEditRateData] = useState();
  const [usd, setUsd] = useState();
  const [currencyDataCurrent, setCurrencyDataCurrent] = useState();

  const updateRateList = async (id, data) => {
    let idx = data.findIndex((obj) => obj?.name == "USD");
    data[idx]["amount"] = usd;
    let payload = {
      courier_company: "",
      document_no: "",
      currency_data: data,
    };
    await TravelListingAPI.updateRateData(id, payload)
      .then((response) => {
        updateCurrency(data);
        handleBackData();
        getRateList();
        onCloseButtonClick();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRateListValue = async () => {
    await TravelListingAPI.getRateList()
      .then((response) => {
        let res = response?.data?.dataList?.currency_converter[0];
        setEditRateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let usdData = exchangeDataUsd.filter((obj) => obj?.name == "USD");
    setUsd(usdData.length ? usdData[0].amount : 0);
    setCurrencyDataCurrent(exchangeDataUsd);
  }, [exchangeDataUsd]);

  useEffect(() => {
    getRateListValue();
  }, [checkActiveTab === "Foreign Expense"]);

  useEffect(() => {
    let data = [];
    if (
      state?.currency_data !== "" &&
      state?.currency_data !== null &&
      state?.currency_data !== undefined
    ) {
      let currenctData = JSON.parse(state?.currency_data.replace(/'/g, '"'));
      let usdData = currenctData.filter((obj) => obj?.name == "USD");
      setUsd(usdData.length ? usdData[0].amount : 0);
      setCurrencyDataCurrent(currenctData);
    } else {
      state?.country_currency?.map((value, i) => {
        let finalJSON = {
          id: value.id,
          name: value.name,
        };
        data?.push(finalJSON);
        let exist = data?.filter((each) => {
          if (Object.entries(each)[1][1] === "USD") {
            return true;
          }
        });
        if (exist?.length === 0) {
          data.push({
            id: 5,
            name: "USD",
          });
        }
        setCurrencyDataCurrent(data);
      });
    }
  }, [state]);

  useEffect(() => {
    if (gridDataNew?.currency_data !== undefined)
      setRateListData(JSON.parse(gridDataNew?.currency_data)[0]);
    // setCurrencyDataCurrent(JSON.parse(gridDataNew?.currency_data));
  }, [gridDataNew]);

  return (
    <Modal
      show={visibleExchange}
      onHide={onCloseButtonClick}
      size="lg"
      backdrop="static"
      keyboard={false}
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Exchange Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Provide exchnage rates for below currencies in INR.</p>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group innergroup">
              <label>USD</label>
              <input
                type="text"
                name="invoice_no"
                className="form-control"
                maxLength={16}
                placeholder="Enter USD"
                autocomplete="off"
                onChange={(e) => {
                  setUsd(e.target.value);
                }}
                value={usd}
              />
            </div>
          </div>
          {currencyDataCurrent?.map((data, index) =>
            data?.name !== "INR" && data?.name !== "USD" ? (
              <div className="col-md-6">
                <div className="form-group innergroup">
                  <label>{data?.name}</label>
                  <input
                    type="text"
                    name="invoice_no"
                    className="form-control"
                    maxLength={16}
                    placeholder={`Enter INR Amt per ${data?.name}`}
                    autocomplete="off"
                    onChange={(e) => {
                      let cur = JSON.parse(JSON.stringify(currencyDataCurrent));
                      cur[index]["amount"] = e.target.value;
                      setCurrencyDataCurrent(cur);
                    }}
                    value={data?.amount}
                  />
                </div>
              </div>
            ) : (
              <></>
            )
          )}
          {/* <div className="col-md-6">
            <div className="form-group innergroup">
              <label>EUR</label>
              <input
                type="text"
                name="invoice_no"
                className="form-control"
                maxLength={16}
                placeholder="Enter EUR"
                autocomplete="off"
                onChange={(e) => {
                  setRateListData({ ...rateListData, etb: e.target.value });
                }}
                value={rateListData?.etb}
              />
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          class="btn btn-primary-inner bpi-main"
          variant="primary"
          onClick={onCloseButtonClick}
        >
          Cancel
        </button>
        <button
          class="btn btn-primary-inner bpi-main"
          variant="primary"
          onClick={() => updateRateList(state?.id, currencyDataCurrent)}
        >
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExchangeModal;
