import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


  export const AddGlApprover = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/gl_approver`,
      Data
    );
  }
  export const uploadGlFile = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/gl_approver/uploadattachment`,
      Data
    );
  }
  export const importExel = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/travel-n-expanse/import`,
      method: "POST",
      responseType: "blob",
      data: data,
    });
  };
  export const getDirectoryDetails = async () => {
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/import_details`);
  };
  export const updateGlApprover = async (Data) => {
    return axios.put(
      `${AUTH_BASE_URL}/gl_approver`,
      Data
    );
  }
  export const getGlApprover = async (pageNo,pageSize,sortBy,paginate,search,status,glcode,approvers,requester) => {
    return axios.get(
      `${AUTH_BASE_URL}/gl_approver?page_no=${pageNo}&search=${search}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&status=${status}&glcode=${glcode}&approvers=${approvers}&requester=${requester}`
    );
  }
  export const deleteGl = async (id) => {
    return axios.delete(
      `${AUTH_BASE_URL}/gl_approver?id=${id}`
      
    );
  }
  export const deleteattachment = async (id) => {
    return axios.delete(
      `${AUTH_BASE_URL}/gl_approver/deleteattachment?id=${id}`
      
    );
  }
  export const AddGlcost = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/gl_approver/costcenter`,Data);
  }
  export const getGlcost = async (pageNo,pageSize,sortBy,paginate,search,status,glcode,costcenter,user_id) => {
    return axios.get(
      `${AUTH_BASE_URL}/gl_approver/costcenter?page_no=${pageNo}&search=${search}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&status=${status}&glcode=${glcode}&user_id=${user_id}&costcenter=${costcenter}`
    );
  }

  export const ImportBl = async (Data) => {
    return axios({
      url: `${AUTH_BASE_URL}/gl_approver/gl_import_balancesheet`,
      method: "POST",
      responseType: "blob",
      data:Data
    });
  }
  export const ImportBlRecords = async (Data) => {
    return axios({
      url: `${AUTH_BASE_URL}/gl_approver/gl_import_balancesheet_records`,
      method: "POST",
      // responseType: "blob",
      data:Data
    });
  }

  export const getbl = async (pageNo,pageSize,sortBy,paginate,search,status,glcode,level,user_id,blcode,month,year,approvers) => {
    return axios.get(
      `${AUTH_BASE_URL}/gl_approver/getBalancesheet?page_no=${pageNo}&search=${search}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&status=${status}&glcode=${glcode}&user_id=${user_id}&level=${level}&blcode=${blcode}&year=${year}&month=${month}&approvers=${approvers}`
    );
  }
  export const companySignoff = async () => {
    return axios.get(
      `${AUTH_BASE_URL}/gl_approver/companylevelsignoff`
    );
  }
  export const freezeMapping = async () => {
    return axios.get(
      `${AUTH_BASE_URL}/gl_approver/FreezeMapping`
    );
  }
  export const Changeuserstaus = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/gl_approver/changeuserstaus`,Data);
  }

  export const getExportGLData = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/gl_approver/export_gl_data?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };