import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import * as TravelListingAPI from "../../service/travelService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function OtherExpPrintview() {
  const { state } = useLocation();
  const [traveluserDetails, setTraveluserDetails] = useState([]);
  const [travelDetails, setTravelDetails] = useState([]);
  const [finalTotals, setfinalTotals] = useState({
    totalApplied: 0,
    totalApproved: 0,
  });

  const location = useLocation();
  const [backpath, set_backPath] = useState(null);

  const Print = () => {
    let originalContents = document.body.innerHTML;
    let printContents = document.getElementById("printablediv").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };
  window.onafterprint = () => {
    setTimeout(() => {
      document.getElementById("print-btn-1").onclick = Print;
    }, 2000);
  };

  function goback() {
    window.location.href = backpath;
  }

  const getTraveluserDetailsById = (id) => {
    TravelListingAPI.getTravelById(id).then((response) => {
      setTraveluserDetails(response?.data?.dataList?.result);
    });
  };

  const getTravelDetailsById = (id) => {
    TravelListingAPI.trav_detail(id)
      .then((resp) => {
        setTravelDetails(resp?.data?.dataList);
      })
      .catch((error) => {
        toast.error("Error!");
      });
  };

  useEffect(() => {
    let travel_id = state?.travel_id;
    set_backPath(location?.state?.backpath);
    let predata = state?.predata;
    let total1 = 0,
      total2 = 0;
    predata?.expense_data?.every((data) => {
      total1 += data?.bill_amount;
      total2 += data?.approved_amount;

      return true;
    });
    setfinalTotals({
      totalApplied: total1,
      totalApproved: total2,
    });
    setTraveluserDetails(predata);

    let user_id = location?.state?.user_id;
    let travelexp_approval_id = location?.state?.travelexp_approval_id;

    // getTraveluserDetailsById(travel_id);
    // getTravelDetailsById(travel_id);
  }, [state]);
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-lg-12">
            <div class="row" id="printablediv">
              <div class="col-md-12">
                <div class="content-wrapper-inner content-wrapper-inner2">
                  <div class="innerheadsec">
                    <div className="row">
                      <div class="col-md-12">
                        <div class="defaultchatgptquest text-right">
                          {/* <Link
                            to="/other-listing"
                            class="badge badge-secondary"
                          >
                            <i class="fas fa-arrow-circle-left"></i> Back
                          </Link>
                          <button
                            class="badge badge-primary"
                            onClick={() => Print()}
                          >
                            <i class="fas fa-print"></i> Print
                          </button> */}
                        </div>
                        <h3 class="policyhead">
                          {traveluserDetails?.expense_type == "petty"
                            ? `${traveluserDetails?.organisation_name}`
                            : `${traveluserDetails?.user_organisation_name}`}
                          <br />
                          <strong class="text-dark f-15">
                            {traveluserDetails?.expense_type == "petty"
                              ? "Petty"
                              : "Other"}{" "}
                            Expense Claim
                          </strong>
                        </h3>
                      </div>
                    </div>

                    <div class="d-flex viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Emp No</label>
                          <p>{traveluserDetails?.user_code}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Name</label>
                          <p>{traveluserDetails?.user_name}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Vendor Code</label>
                          <p>{traveluserDetails?.user_vendor_code}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Request ID</label>
                          {/* <p >{traveluserDetails?.expense_type=="petty"?`${traveluserDetails?.organisation_code}PE`:`${traveluserDetails?.user_organisation_code}OE`}{traveluserDetails?.id}</p> */}
                          <p>
                            {traveluserDetails?.request_id
                              ? traveluserDetails?.request_id
                              : traveluserDetails?.expense_type == "petty"
                              ? `${traveluserDetails?.organisation_code}PE`
                              : `${traveluserDetails?.user_organisation_code}OE` +
                                traveluserDetails?.id}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Zone</label>
                          <p>{traveluserDetails?.zone}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Location</label>
                          <p>{traveluserDetails?.user_location}</p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Grade</label>
                          <p>{traveluserDetails?.user_grade}</p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Applied on</label>
                          <p>
                            {moment
                              .parseZone(traveluserDetails?.created_at)
                              .format("DD-MM-YYYY hh:mm A")}
                          </p>
                        </div>
                      </div>

                      {/* <div class="col-md-3">
                            <div class="form-group innergroup">
                              <label>Status</label>
                              <p>Active</p>
                            </div>
                          </div> */}
                    </div>
                    <div class="d-flex viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Reporting Manager</label>
                          {/* <p>{traveluserDetails?.approver_remark_data?.map((data,idx)=>(
                                <span>{data?.approver_position=="Reporting Manager" && data?.approver_name} </span>
                              ))}</p> */}
                          <p>
                            {traveluserDetails?.rm_name +
                              `(${traveluserDetails?.rm_username})`}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Exception Approved By</label>
                          <p>
                            {traveluserDetails?.is_exception
                              ? traveluserDetails?.exception_approver
                                  ?.approver_name +
                                `(${traveluserDetails?.exception_approver?.approver_username})`
                              : "-"}
                          </p>
                        </div>
                      </div>

                      {/* <div class="col-md-6 viewformaftersubmit">
                        <div class="form-group innergroup">
                          <label>Download Attachments</label>
                          <p>
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i> file.pdf
                            </span>{" "}
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i> file-01.pdf
                            </span>
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Expense Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="table table-striped table-bordered tablecured viewpoOther innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Sr. No. </th>
                          <th>GL Type</th>
                          {traveluserDetails?.expense_type?.toLowerCase() ==
                          "petty" ? (
                            <th>Cost Center</th>
                          ) : (
                            ""
                          )}
                          {state?.showPrintData != false ? (
                            <th>Travel By/Machine No</th>
                          ) : (
                            ""
                          )}
                          <th>Add. Info</th>
                          <th>Bill No</th>
                          <th>Date</th>

                          {traveluserDetails?.expense_type?.toLowerCase() ==
                          "petty" ? (
                            state?.showPrintData != false ? (
                              <th>For Month Of</th>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}

                          <th>Description</th>
                          <th>Reason For Expense</th>
                          <th>Reason For Deduction</th>
                          <th>Amount ₹</th>
                          <th>Appr Amount ₹</th>
                        </tr>
                      </thead>
                      <tbody>
                        {traveluserDetails?.expense_data?.length > 0 ? (
                          traveluserDetails?.expense_data?.map((data, idx) => {
                            return (
                              <tr>
                                <td>{idx + 1}</td>
                                <td>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip
                                        className="bs-tooltip-bottom tooltip"
                                        id="overlay-example"
                                      >
                                        <div class="tooltip-inner text-left">
                                          {data?.gl_account +
                                            "-" +
                                            data?.gl_desc}
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <div>
                                      {data?.gl_account + "-" + data?.gl_desc}
                                    </div>
                                  </OverlayTrigger>
                                </td>
                                {traveluserDetails?.expense_type?.toLowerCase() ==
                                "petty" ? (
                                  <td>
                                    {data?.cost_center_code +
                                      "-" +
                                      data?.cost_center_name}
                                  </td>
                                ) : (
                                  ""
                                )}
                                {state?.showPrintData != false ? (
                                  <td>
                                    {data?.travel_by
                                      ? data?.travel_by
                                      : data?.machine_no}
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>{data?.add_info}</td>
                                <td>{data?.bill_number}</td>
                                <td>
                                  {moment
                                    .parseZone(data?.bill_date)
                                    .format("DD-MM-YYYY")}
                                </td>

                                {traveluserDetails?.expense_type?.toLowerCase() ==
                                "petty" ? (
                                  state?.showPrintData != false ? (
                                    <td>
                                      {data?.for_month_of &&
                                        moment
                                          .parseZone(data?.for_month_of)
                                          .format("MMMM")}
                                    </td>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                <td>
                                  {data?.bill_desc ? data?.bill_desc : "NA"}
                                </td>
                                <td>
                                  {/* {data?.remarks} */}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip
                                        className="bs-tooltip-bottom tooltip"
                                        id="overlay-example"
                                      >
                                        <div class="tooltip-inner text-left">
                                          {data?.reason_for_expense}
                                        </div>
                                      </Tooltip>
                                    }
                                  >
                                    <div>{data?.reason_for_expense}</div>
                                  </OverlayTrigger>
                                </td>
                                <td>{data?.reason_for_deduction}</td>
                                <td>{data?.bill_amount?.toFixed(2)}</td>
                                {traveluserDetails?.ssc_user_status ? (
                                  <td>{data?.approved_amount?.toFixed(2)}</td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={20}>No Records Found...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Remarks</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Approver Name</th>
                          <th>Approver Level</th>
                          <th>Status</th>
                          <th>Approved Date</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {traveluserDetails?.approver_remark_data?.length > 0 ? (
                          traveluserDetails?.approver_remark_data?.map(
                            (data, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {`${data?.approver_name} (${data?.approver_username})`}
                                  </td>
                                  <td>{data?.approver_type}</td>
                                  <td>
                                    {data?.status == "A" ? "Approved" : ""}
                                  </td>
                                  <td>
                                    {data?.updated_at
                                      ? moment
                                          .parseZone(data?.updated_at)
                                          .format("DD-MM-YYYY hh:mm A")
                                      : "-"}
                                  </td>
                                  <td>{data?.remark}</td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={20}>No Records Found...</td>
                          </tr>
                        )}

                        {traveluserDetails?.ssc_user_status && (
                          <tr>
                            <td>
                              {traveluserDetails?.approver_remark_data?.length +
                                1}
                            </td>
                            <td>
                              {`${traveluserDetails?.is_picked_by_user_name} (${traveluserDetails?.is_picked_by_user_code})`}
                            </td>
                            <td>Approver 2</td>
                            <td>
                              {traveluserDetails?.ssc_user_status == "A"
                                ? "Approved"
                                : ""}
                            </td>
                            <td>
                              {moment
                                .parseZone(
                                  traveluserDetails?.ssc_user_approved_at
                                )
                                ?.format("DD-MM-YYYY")}
                            </td>
                            <td>{/* {data?.remark} */}</td>
                          </tr>
                        )}

                        {traveluserDetails?.ssc_manager_status && (
                          <tr>
                            <td>
                              {traveluserDetails?.approver_remark_data?.length +
                                2}
                            </td>
                            <td>
                              {`${traveluserDetails?.ssc_manager_user_name} (${traveluserDetails?.ssc_manager_user_code})`}
                            </td>
                            <td>Approver 3</td>
                            <td>
                              {traveluserDetails?.ssc_user_status == "A"
                                ? "Approved"
                                : ""}
                            </td>
                            <td>
                              {moment
                                .parseZone(
                                  traveluserDetails?.ssc_user_approved_at
                                )
                                ?.format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>{/* {data?.remark} */}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div className="d-flex justify-content-end">
                      <div class="d-flex flex-column align-items-end">
                        Applied Amount ₹ {finalTotals.totalApplied?.toFixed(2)}{" "}
                        &nbsp;&nbsp;&nbsp;
                        {traveluserDetails?.ssc_user_status
                          ? `Approved Amount ₹ ` +
                            finalTotals.totalApproved?.toFixed(2)
                          : ""}
                        <div className="mt-5 mr-5">Signature</div>
                        {/* <div class="col-md-3 float-right">
                        
                        <div class="form-group innergroup">
                          <label>
                          <span class="text-danger">*</span>
                          </label>
                          
                          <input
                            type="number"
                            disabled
                            class="form-control"
                            placeholder="Enter Applied Amount"
                            value={finalTotals.totalApplied}
                            
                          />
                        </div>
                      </div> */}
                        {/* <div class="col-md-3 float-right">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            disabled
                            class="form-control"
                            placeholder="Enter Approved Amount"
                            value={finalTotals.totalApproved}

                          />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center mt-3 pb-3">
              <Link to={backpath} class="btn btn-outline-secondary mr-2">
                Back
              </Link>
              <button
                class="btn btn-primary-inner bpi-main"
                type="button"
                id="print-btn-1"
                onClick={Print}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherExpPrintview;
