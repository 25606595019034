import React, { useEffect, useMemo, useState } from "react";

import {
  Tab,
  Modal,
  Tabs,
  Dropdown,
  Spinner,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import Sidebar from "./sidebar";
import { CustomBreadcrumb } from "./custom-breadcrump";
import * as MediclaimService from "../../service/medicalService";
import { getAllOrganisation, uploadImages } from "../../service/organisation";
import CustomPagination from "../CashbookProfile/CustomPagination";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import * as leaveService from "../../service/leaveService";
import * as EmpService from "../../service/employee";
import * as UserService from "../../service/userService";

const __Tabs = {
  purchase: "purchase",
  approved: "approved",
  Topup: "Topup",
};

const CRUMB_ITEMS = [
  { to: "/home", title: "Home" },
  { to: "/", title: "Me@PI" },
  { active: true, title: "Declaration Report" },
];

const noDataFound = (howMuchSpan, loading) => {
  return (
    <tr>
      <td colSpan={howMuchSpan} className="text-center">
        {" "}
        {loading ? "Loading" : "No Record Found"}
      </td>
    </tr>
  );
};

function MediclaimReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("approved");
  const initialValues = {
    list: [],
    totalPages: 0,
    query: { page_size: 10, page_no: 1, paginate: true },
  };
  const [data, setData] = useState(initialValues);

  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [userList, setUserList] = useState([]);
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [psaVal, setPsaVal] = useState([]);
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });
  const [excelListImport, setExcelListImport] = useState([]);
  const [uploadData, setUploadData] = useState({});
  const [exDataDown, setExDataDown] = useState({});
  const [syncData, setSyncData] = useState(false);
  const [branchData, setBranchData] = useState([]);

  const companyOptions = useMemo(
    () =>
      companies.map((item) => ({
        value: item?.id,
        label: `${item?.name} (${item?.code})`,
      })),
    [companies]
  );
  const dispatch = useDispatch();

  const employeeOptions = useMemo(
    () =>
      employees.map((item) => ({
        value: item?.id,
        label: `${item?.full_name} (${item?.username})`,
      })),
    [employees]
  );

  console.log("employeeOptions===>>>>", employeeOptions);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const filterShow = () => {
    filteropen();
    LocationFilter();
    getBranchFilter();
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getBranchFilter = () => {
    UserService.getBranchFilter()
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };


  const getCompanies = () => {
    getAllOrganisation("false")
      .then((response) => {
        if (response.data.code === 200) {
          setCompanies(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getEmployees = () => {
    MediclaimService.allUsers("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          let data = response?.data?.dataList?.result?.splice(0, 0, {
            id: "ALL",
            full_name: "ALL",
            username: "-",
          });
          setEmployees(response?.data?.dataList?.result);
        } else {
          toast.warn(response.data.message);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getData = async (query = {}) => {
    setIsLoading(true);
    try {
      let response = null;
      switch (activeTab) {
        case "purchase":
          response = await MediclaimService.mediclaimReportBHRReportList(query);
          break;

        case "approved":
          // query['voluntary_top_up'] = 'False'
          response = await MediclaimService.mediclaimReportDDList(query);
          break;

        case "Topup":
          query["voluntary_top_up"] = "True";
          response = await MediclaimService.mediclaimReportDDList(query);
          break;

        default:
          break;
      }
      if (response.data.code === 200) {
        setData({
          list: response?.data?.dataList?.result,
          totalPages: response?.data?.dataList?.paginated_data?.totalPages,
          query: {
            ...query,
            page_no: response?.data?.dataList?.paginated_data?.currentPage,
          },
        });
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageClick = (page) => {
    const query = { ...data.query, page_no: page?.selected + 1 };
    getData(query);
  };

  const handlePageCountChange = (count) => {
    const query = { ...data.query, page_size: count };
    getData(query);
  };

  const handleChange = (text) => {
    const query = { ...data.query, search: text };
    getData(query);
  };

  const handleDownloadExcel = async () => {
    try {
      dispatch(setDisplayLoader("Display"));
      const response = await MediclaimService.exportMedicalReport(data?.query);
      dispatch(setDisplayLoader("Hide"));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reports.xlsx");
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(
        "🚀 ~ file: mediclaim-report.js:99 ~ handleDownloadExcel ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    setData((prev) => ({ ...prev, list: [] }));
    getData();
    resetForm();
  }, [activeTab]);

  useEffect(() => {
    if (
      (show === true && companies.length === 0 && employees.length === 0) ||
      syncData
    ) {
      getCompanies();
      getEmployees();
    }
  }, [show, syncData]);

  const openSyncModal = () => {
    setSyncData(true);
  };

  const reportSync = () => {
    if (userList?.length > 0) {
      setSyncData(false);
      dispatch(setDisplayLoader("Display"));
      let users =
        userList[0]?.value === "ALL"
          ? "All"
          : userList?.map((each) => {
            return each?.value;
          });
      MediclaimService.syncReportData(users)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(setDisplayLoader("Hide"));
            toast.success(
              "Report synced successfully. Kindly check mediclaim report."
            );
          } else {
            dispatch(setDisplayLoader("Hide"));
          }
        })
        .catch((err) => {
          dispatch(setDisplayLoader("Hide"));
        });
    } else {
      toast.error("Kindly select user.");
    }
  };

  /* MODAL HANDLING */
  const initialValuesFilterModal = {
    to_date: "",
    is_active: "",
    from_date: "",
    company_id: "",
    employee_id: [],
    psa_id: [],
    bu: "",
  };

  const validationSchemaFilterModal = Yup.object().shape({
    to_date: Yup.string().optional(),
    from_date: Yup.string().optional(),
    is_active: Yup.string().optional(),
    company_id: Yup.string().optional(),
    employee_id: Yup.array().optional(),
    psa_id: Yup.array().optional(),
    bu: Yup.string().optional(),
  });
  const getSelectFeildProps = (options, key) => {
    return {
      value: options
        ? Array.isArray(values[key])
          ? options.filter((option) => values[key].includes(option.value))
          : options.find((option) => option.value === values[key])
        : "",
      onChange: (option) => {
        if (Array.isArray(option)) {
          return setFieldValue(
            key,
            option.map((item) => item.value)
          );
        }
        setFieldValue(key, option?.value);
      },
    };
  };

  const formik = useFormik({
    initialValues: initialValuesFilterModal,
    validationSchema: validationSchemaFilterModal,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let _values = { ...values };

        /* modifying  */
        // _values['employee_id'] = _values['employee_id'].join(',')
        _values["employee_id"] = _values["employee_id"]?.toString();
        _values["psa_id"] = _values["psa_id"]?.toString();

        Object.keys(_values).forEach((key) => {
          if (
            _values[key] === "" ||
            _values[key] === undefined ||
            _values[key] === null
          ) {
            delete _values[key];
          }
        });
        if (activeTab === __Tabs.approved) {
          _values["voluntary_top_up"] = "False";
          getData(_values);
        } else {
          _values["voluntary_top_up"] = "True";
          getData(_values);
        }
        console.log(
          "🚀 ~ file: mediclaim-report.js:218 ~ onSubmit: ~ _values:",
          _values
        );
        filterclose();
      } catch (error) {
        console.log(
          "🚀 ~ file: mediclaim-report.js:180 ~ onSubmit: ~ error:",
          error
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onProfileChange = (e, flag) => {
    setShowImport({ ...showImport, downloadFlag: false });
    const files = e.target.files[0];
    if (
      files?.type === "image/xls" ||
      files?.type === "image/xlsx" ||
      files?.type === "image/XLSX" ||
      files?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      uploadImages(fileData).then((response) => {
        if (flag === "mediclaim-card") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
      });
    } else {
      toast.error("Only xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const importExel = (values) => {
    setShowImport({ ...showImport, importFlag: false });
    setIsLoading(true);
    MediclaimService.importExel(values)
      .then((response) => {
        setExDataDown(response.data);
        getDirectoryDetails();
        setShowImport({ ...showImport, importFlag: true });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let errorData = blobToString(error.response.data);
          console.log(errorData, "Error");
          let dataValueErr = JSON.parse(errorData);
          toast.error(dataValueErr.detail);
        } else {
          toast.error(error?.response?.data?.detail?.msg);
        }
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getDirectoryDetails = () => {
    leaveService
      .getLeaveImportDetails()
      .then((response) => {
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} record sucessfully`
          );
        }
        if (
          response?.data?.dataList[0]?.records_uploaded === 0 &&
          response?.data?.dataList[0]?.records_failed === 0
        ) {
          toast.warn("No Record Found");
        }
        if (response?.data?.dataList[0]?.records_failed > 0) {
          toast.error(
            `${response?.data?.dataList[0]?.records_failed} record unsucessfull`
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
    importclose();
  };

  function blobToString(b) {
    var u, x;
    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open("GET", u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
  }

  const { values, handleSubmit, getFieldProps, setFieldValue, resetForm } =
    formik;

  return (
    <>
      {syncData && (
        <Modal
          show={syncData}
          onHide={() => {
            setSyncData(false);
            setUserList([]);
          }}
          size="lg"
          backdrop="static"
          keyboard={false}
          className="modaldefaultclose modaldefaultclosecenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Mediclaim Report Sync</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="row">
                <div className="col-md-2"></div>
                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>Users</label>
                    <Select
                      name="users"
                      isMulti
                      options={employeeOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        if (e[0]?.value === "ALL") {
                          setUserList(e);
                        } else {
                          setUserList(e);
                        }
                      }}
                      value={userList}
                    />
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={() => reportSync()}
            >
              Apply
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {importshow && (
        <Modal
          show={importshow}
          onHide={() => {
            importclose();
          }}
          size="lg"
          backdrop="static"
          keyboard={false}
          className="modaldefaultclose modaldefaultclosecenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Mediclaim Card Import</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row">
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i class="fas fa-file-upload fa-2x"></i>
                    <p>Upload the template file with updated data</p>
                    <button class="btn btn-primary-inner">
                      DRAG AND DROP HERE OR CHOOSE FILE
                    </button>
                    <div className="mt-3 downloadtemplate">
                      <button class="editbtn">
                        <i class="far fa-file-excel"></i>
                        <a href="\mediclaim-card.xlsx" download>
                          DOWNLOAD TEMPLATE
                        </a>
                      </button>
                    </div>
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      className="form-control"
                      onChange={(e) => onProfileChange(e, "mediclaim-card")}
                    />
                  </div>
                  {showImport.downloadFlag === true && (
                    <div className="drapdroparea upload-btn-wrapper text-center">
                      <span class="badge bad-status mb-3 badge-warning">
                        {uploadData?.original_name}
                        <i
                          class="far fa-times-circle"
                          onClick={() => {
                            setUploadData({});
                            setShowImport({
                              ...showImport,
                              importFlag: false,
                              downloadFlag: false,
                            });
                          }}
                        ></i>
                      </span>
                      {isLoading ? (
                        <Spinner
                          animation="border"
                          variant="primary"
                          as="span"
                          role="status"
                          aria-hidden="true"
                        ></Spinner>
                      ) : (
                        showImport.importFlag === false && (
                          <button
                            className="btn btn-primary-inner"
                            type="button"
                            onClick={() => importExel(uploadData)}
                          >
                            Proceed to Upload
                          </button>
                        )
                      )}
                    </div>
                  )}
                  {showImport.importFlag === true && (
                    <div className="drapdroparea upload-btn-wrapper text-center">
                      <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                      <p>
                        The system was unable to read few records from the
                        uploaded file.
                        <br />
                        Please correct or remove those records from the file and
                        upload again.
                      </p>
                      <span className="badge bad-status mb-3 badge-success">
                        Records Succeded:
                        {excelListImport[0]?.records_uploaded}
                      </span>
                      <span className="badge bad-status mb-3 badge-danger">
                        Records Failed:
                        {excelListImport[0]?.records_failed}
                      </span>
                      <div className="mt-3 downloadtemplate">
                        <p>Check the uploaded file in case of error.</p>
                        <button
                          className="btn btn-primary-inner"
                          type="button"
                          onClick={() => {
                            downloadError();
                          }}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div class="row">
        {/* <div class="col-md-3">
          <Sidebar />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Reports</h4>
                  <CustomBreadcrumb items={CRUMB_ITEMS} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  {activeTab !== __Tabs.purchase && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              relation, gender, age, marital status, location,
                              premium, zone, full name, department,
                              organisation, designation, user_id, sum insured
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search relation, gender, age..."
                          onChange={(e) => handleChange(e.target.value)}
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
                <div class="col-lg-8">
                  {activeTab !== __Tabs.purchase && (
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        class="btn btn-primary-inner"
                        onClick={openSyncModal}
                      >
                        <i class="fas fa-sync-alt mr-2 f-14"></i>Report Sync
                      </button>
                      <button class="btn btn-danger" onClick={importuser}>
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Import
                      </button>
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {/* <Dropdown.Item href="#" onClick={() => window.print()}>
                                                        <i class="fas fa-print"></i>Print
                                                    </Dropdown.Item> */}
                          {/* <Dropdown.Item href="#">
                                                        <i class="far fa-file-alt"></i>CSV
                                                    </Dropdown.Item> */}
                          <Dropdown.Item
                            href="#"
                            disabled={data?.list?.length === 0}
                            onClick={() => handleDownloadExcel()}
                          >
                            <i class="far fa-file-excel"></i>Excel
                          </Dropdown.Item>
                          {/* <Dropdown.Item href="#">
                                                        <i class="far fa-file-pdf"></i>Pdf
                                                    </Dropdown.Item> */}
                          {/* <Dropdown.Item href="#">
                                                        <i class="far fa-copy"></i>Copy
                                                    </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filterShow}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs
                onSelect={(k) => setActiveTab(k)}
                id="approvaltabs"
                defaultActiveKey="approved"
              >
                {/* <Tab
                  eventKey="purchase"
                  title={
                    <React.Fragment>
                      BHR Report/Employee Mediclaim
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Employee No</th>
                          <th>Employee Name</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data?.list) && data?.list?.length > 0
                          ? data.list.map((item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td class="text-theme fw-bold">
                                    {item?.employee_no || ""}
                                  </td>
                                  <td>{item?.user_name || ""}</td>
                                  <td>
                                    <a
                                      href={item?.url}
                                      target="_blank"
                                      class="btn btn-blanktd text-primary"
                                    >
                                      <i class="fas fa-download"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          : noDataFound(4, isLoading)}
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination
                    onChange={(e) => handlePageCountChange(e.target.value)}
                    handlePageClick={(page) => handlePageClick(page)}
                    pageCount={data.totalPages}
                  />
                </Tab> */}
                <Tab
                  eventKey="approved"
                  title={
                    <React.Fragment>
                      Mediclaim Dependent Declaration
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Emp No</th>
                          <th>Emp Status</th>
                          <th>Company</th>
                          <th>Name of Employee</th>
                          <th>Department</th>
                          <th>Designation</th>
                          <th>Relation</th>
                          <th>Gender</th>
                          <th>ESG</th>
                          <th>DOB</th>
                          <th>Age</th>
                          <th>Marital Status</th>
                          <th>DOJ</th>
                          <th>DOR</th>
                          <th>Location</th>
                          <th>Sum Insured</th>
                          <th>Premium</th>
                          <th>Family Premium</th>
                          <th>Parents Premium</th>
                          <th>CGST</th>
                          <th>SGST</th>
                          <th>Total</th>
                          <th>Submit Date</th>
                          <th>Zone</th>
                          <th>Card Number</th>
                          {/* <th>Attachment</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data?.list) && data?.list.length > 0
                          ? data.list.map((item) => {
                            return (
                              <tr>
                                <td>{item?.user_code || ""}</td>
                                <td>
                                  {item?.user_active ? (
                                    <span className="badge bad-status badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bad-status badge-danger">
                                      In-Active
                                    </span>
                                  )}
                                </td>
                                <td>{item?.organisation_name || ""}</td>
                                <td>
                                  {item?.name === null
                                    ? item?.full_name
                                    : item?.name}
                                </td>
                                <td>{item?.department_name || ""}</td>
                                <td>{item?.designation_name || ""}</td>
                                <td>{item?.relation || ""}</td>
                                <td>{item?.gender || ""}</td>
                                <td>{item?.employee_sub_group || ""}</td>
                                <td>{item?.dob || ""}</td>
                                <td>{item?.age || ""}</td>
                                <td>
                                  {item?.relation === "Self"
                                    ? item?.marital_status
                                    : ""}
                                </td>
                                <td>{item?.doj || ""}</td>
                                <td>{item?.dor || ""}</td>
                                <td>{item?.location || ""}</td>
                                <td>{item?.sum_insured || ""}</td>
                                <td>{item?.premium || ""}</td>
                                <td>{item?.family_premium || ""}</td>
                                <td>{item?.parents_premium || ""}</td>
                                <td>{item?.cgst || ""}</td>
                                <td>{item?.sgst || ""}</td>
                                <td>{item?.total || ""}</td>
                                <td>
                                  {item?.submit_date !== null
                                    ? moment(item?.submit_date).format(
                                      "DD-MM-YYYY"
                                    )
                                    : ""}
                                </td>
                                <td>{item?.zone || ""}</td>
                                <td>{item?.card_no}</td>
                                {/* <td>
                                    <a
                                      href={item?.attachment || ""}
                                      target="_blank"
                                    >
                                      <i class="fas fa-paperclip"></i>
                                    </a>
                                  </td> */}
                              </tr>
                            );
                          })
                          : noDataFound(23, isLoading)}
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination
                    onChange={(e) => handlePageCountChange(e.target.value)}
                    handlePageClick={(page) => handlePageClick(page)}
                    pageCount={data.totalPages}
                  />
                </Tab>
                <Tab
                  eventKey="Topup"
                  title={<React.Fragment>Topup Declaration</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Emp No</th>
                          <th>Company</th>
                          <th>Name of Employee</th>
                          <th>Department</th>
                          <th>Designation</th>
                          <th>Relation</th>
                          <th>Gender</th>
                          <th>ESG</th>
                          <th>DOB</th>
                          <th>Age</th>
                          <th>Marital Status</th>
                          <th>Location</th>
                          <th>Sum Insured</th>
                          <th>Top Up Type</th>
                          <th>Top Up-Option 1</th>
                          <th>Top Up-Option 2</th>
                          <th>Submit Date</th>
                          <th>Zone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data?.list) && data?.list?.length > 0
                          ? data.list.map((item) => {
                            return (
                              <tr>
                                <td class="fw-bold text-theme">
                                  {item?.user_code || ""}
                                </td>
                                <td>{item?.organisation_name || ""}</td>{" "}
                                {/* company */}
                                <td>{item?.name || ""}</td>
                                <td>{item?.department_name || ""}</td>
                                <td>{item?.designation_name || ""}</td>
                                <td>{item?.relation || ""}</td>
                                <td>{item?.gender || ""}</td>
                                <td>{item?.employee_sub_group || ""}</td>
                                <td>{item?.dob || ""}</td>
                                <td>{item?.age || ""}</td>
                                {/* age */}
                                <td>{item?.marital_status || ""}</td>{" "}
                                {/* mar status */}
                                <td>{item?.location || ""}</td>{" "}
                                {/* location */}
                                <td>{item?.sum_insured || ""}</td>{" "}
                                {/* sum inssured */}
                                <td>{item?.premium_type || ""}</td>{" "}
                                {/* premium */}
                                <td>
                                  {(item?.premium_type === "Single" &&
                                    item?.top_up_premium) ||
                                    ""}
                                </td>{" "}
                                {/* premium */}
                                <td>
                                  {(item?.premium_type === "Double" &&
                                    item?.top_up_premium) ||
                                    ""}
                                </td>{" "}
                                {/* premium */}
                                <td>
                                  {moment(item?.submit_date).format(
                                    "DD-MM-YYYY"
                                  ) || ""}
                                </td>{" "}
                                {/* submited date */}
                                <td>{item?.zone || ""}</td> {/* zone */}
                              </tr>
                            );
                          })
                          : noDataFound(16, isLoading)}
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination
                    onChange={(e) => handlePageCountChange(e.target.value)}
                    handlePageClick={(page) => handlePageClick(page)}
                    pageCount={data.totalPages}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group innergroup">
              <label>Company</label>
              <Select
                options={companyOptions}
                classNamePrefix="select"
                className="basic-multi-select"
                {...getSelectFeildProps(companyOptions, "company_id")}
              />
            </div>
            <div class="form-group innergroup">
              <label>Employee Code</label>
              <Select
                isMulti
                options={employeeOptions}
                classNamePrefix="select"
                className="basic-multi-select"
                {...getSelectFeildProps(employeeOptions, "employee_id")}
              />
            </div>
            <div class="form-group innergroup">
              <label>From Date</label>
              <input
                type="date"
                class="form-control"
                {...getFieldProps("from_date")}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div class="form-group innergroup">
              <label>To Date</label>
              <input
                type="date"
                class="form-control"
                {...getFieldProps("to_date")}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div class="form-group innergroup">
              <label>Employee Status</label>
              <select
                {...getFieldProps("is_active")}
                class="form-control newbgselect"
              >
                <option value="None">Select</option>
                <option value="True">Active</option>
                <option value="False">Inactive</option>
              </select>
            </div>
            <div className="form-group innergroup">
              <label>
                Select Location<span class="text-danger"></span>
              </label>
              <Select
                isMulti
                name="sub_personal_area"
                options={subPersonalArea}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                {...getSelectFeildProps(subPersonalArea, "psa_id")}
              />
            </div>
            <div className="form-group innergroup">
              <label>Business Unit</label>
              <select
                className="form-control newbgselect"
                name="business_units_id"
                {...getFieldProps("bu")}
                style={{ cursor: "pointer" }}
              >
                <option value="" label="Select Business Unit" disabled />
                {renderBranchOptions(branchData)}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn btn-outline-danger"
              onClick={() => {
                resetForm();
                filterclose();
              }}
            >
              Cancel
            </button>
            <button class="btn btn-primary-inner bpi-main">Apply</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default MediclaimReport;
