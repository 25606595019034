import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import InputField from "../../common/InputField";
const InputBoxAdd = (props) => {
  const { setQuestionData, questionData, categoryName,
    setDataOptionValue, dataOptionValue, qid, isViewingorPublished, set_imageLocalPreviewDict, imageLocalPreviewDict, QuestionDataChange } =
    props;

  return (
    <>
      <form className="filterform innergroup">
        <Row>
          <InputField
            setQuestionData={setQuestionData}
            questionData={questionData}
            dataOptionValue={dataOptionValue}
            setDataOptionValue={setDataOptionValue}
            qid={qid}
            categoryName={categoryName}
            isViewingorPublished={isViewingorPublished}
            set_imageLocalPreviewDict={set_imageLocalPreviewDict}
            imageLocalPreviewDict={imageLocalPreviewDict}
            QuestionDataChange={QuestionDataChange}
          />
        </Row>
      </form>
    </>
  );
};

export default InputBoxAdd;
