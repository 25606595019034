import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Breadcrumb } from "react-bootstrap";
import Select from "react-select";
import AuditLog from "../audit/auditlog";
import { useDispatch, useSelector } from "react-redux";
import LeaveTypeGrid from "./LeaveTypeGrid";
import * as leaveDataService from "../../service/leaveService";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import * as moment from "moment";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { selectFinalUrlPermission } from "../redux/piDataStore";

if (typeof window !== "undefined") {
  injectStyle();
}

const validationSchema = Yup.object({
  leave_type: Yup.string().required("*Leave Type is required"),
  dob_child: Yup.string().optional("*Date of Birth is required"),
  no_child: Yup.string().optional("*Number of Child is required"),
  to_date: Yup.string().required("*To Date is required"),
  from_type: Yup.string().optional("*From Type is required"),
  to_type: Yup.string().optional("*To Type is required"),
  // leave_duration: Yup.string().required("*Leave Duration is required"),
});

const optionalSchema = Yup.object({
  // option_holiday: Yup.string().required("*Holiday Events is required"),
  // remarks: Yup.string().required("*Remarks is required"),
});

function ApplyLeave(props) {
  const {
    edit,
    leaveList,
    onClose,
    onAddCallBack,
    lastLeave,
    mandatory,
    weekOff,
    attendanceWeekOff,
    backDate,
    editState,
    editBackDate,
    userLeaves,
  } = props;
  const nameForm = "Apply Leave";
  const [leaveDataRes, setLeaveDataRes] = useState([]);
  const [dataSave, setDataSave] = useState([
    {
      code: "DEF",
    },
  ]);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [ReasonList, setReasonList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [reasonListData, setReasonListData] = useState([]);
  const [holidateListData, setholidateListData] = useState([]);
  const [dataLeave, setDataLeave] = useState([]);
  const [filename, setfilename] = useState("");
  const [imgSize, setImgSize] = useState(false);
  const [attachmentsValidate, setAttachmentsValidate] = useState(true);
  const [isClub, setIsClub] = useState(false);
  const [link, setLink] = useState(null);
  const [curLeaveCode, setCurLeaveCode] = useState("");
  const [canApply, setCanApply] = useState(false);
  const [monthCycle, setMonthCycle] = useState(false);
  const [backDateWeekOff, setBackDateWeekOff] = useState(null);
  const [formErrors, setFormErrors] = useState({
    leaveType: false,
    reason: false,
    fromType: false,
    toType: false,
    isWeekOffFrom: false,
    isWeekOffTo: false,
  });
  const [minimumDate, setMinimumDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [maxDate, setMaxDate] = useState("");
  // `${moment(new Date()).format("YYYY")}-${moment(new Date()).format("MM")}-22`
  const [usersRule, setUsersRule] = useState([]);
  const [isLwp, setIsLwp] = useState(false);
  const [exist, setExist] = useState(false);
  const [uploadAttachments, setUploadAttachments] = useState({
    attachments: {},
  });
  const userData = useSelector(selectUserData);
  const [applyLeave, setApplyLeave] = useState({
    leave_type: "",
    leave_reason: "",
    dob_child: "",
    no_child: "",
    from_date: "",
    to_date: "",
    from_type: "",
    to_type: "",
    leave_duration: "",
    remarks: "",
    attachment: "",
    option_holiday: "",
  });
  const [leaveGap, setLeaveGap] = useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: "",
    days: "",
  });
  const dispatch = useDispatch();
  let condition = "true";

  const getLeaveManageReasonList = (id) => {
    leaveDataService
      .getLeaveManageReasonList(id)
      .then((response) => {
        let ReasonData = [];
        let count = 0;
        response?.data?.dataList?.result.map((x) => {
          let data = { value: x.id, label: x.reason };
          if (count === 0 && edit === false) {
            setReasonListData(data);
            setApplyLeave((prev) => ({
              ...prev,
              leave_reason: data?.value,
            }));
            count = count + 1;
          }
          ReasonData.push(data);
        });
        setReasonList(ReasonData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    if (edit) {
      handleShowValidate(editState?.leave_type_id, leaveList);
    }
  }, [edit, leaveList]);


  const resetData = () => {
    if (moment(new Date()).date() > 22) {
      setMonthCycle(true);
      setMinimumDate(
        `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
          "MM"
        )}-21`
      );
    }
    setLeaveGap((prev) => ({
      ...prev,
      days: editState?.optional_holiday !== null ? 1 : "",
    }));
    if (edit === false) {
      setApplyLeave((prev) => ({
        ...prev,
        leave_type: "",
      }));
    }
    setApplyLeave((prev) => ({
      ...prev,
      from_date: "",
      from_type: "",
      to_date: "",
      to_type: "",
      remarks: "",
      leave_duration: "",
      attachment: "",
      option_holiday: "",
    }));
    if (edit === false) {
      setDataSave([
        {
          code: "DEF",
        },
      ]);
    }
    setUploadAttachments({
      attachments: {},
    });
    setfilename("");
    if (editState?.optional_holiday === null) {
      setReasonListData([]);
    }
  };

  const getHolidayList = (paginate) => {
    dispatch(setDisplayLoader("Display"));
    let query = `self_data=${true}&paginate=${false}`;
    let previousMonth = moment(new Date()).month() === 0 ? 0 : moment(new Date()).subtract(1, "month").month();
    leaveDataService
      .getHolidayListByUser(query)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let HolidayData = [];
        response?.data?.dataList?.result
          .filter((obj) => {
            if (
              moment(obj?.day_date).month() >= previousMonth &&
              obj?.type === "optional"
            ) {
              return true;
            }
          })
          .map((x) => {
            let data = {
              value: x.id,
              key: x?.day_date,
              label: moment(x.day_date).format("DD-MM-YYYY") + " " + x.name,
            };
            HolidayData.push(data);
          });
        setHolidayList(HolidayData);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const saveApplyLeave = (data) => {
    if (condition == "true") {
      condition = "false";
      leaveDataService
        .saveApplyLeave(data)
        .then((response) => {
          console.log({response})
          if(response.data.message =='Tour initiated already, you cannot apply this leave'){
            toast.error(response.data.message);
          }else{
            condition = "true";
            setLeaveDataRes(response?.data?.dataList?.result);
            onAddCallBack(response);
            toast.success(response.data.message);
          }
          
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const updateApplyLeave = (id, values) => {
    if (
      (reasonListData?.label.toLowerCase() === "sickness" &&
        parseFloat(leaveGap.days) >= 3 &&
        Object.keys(uploadAttachments?.attachments).length === 0 &&
        editState?.attachment === "") ||
      ((curLeaveCode === "MTL" ||
        curLeaveCode === "FPL" ||
        curLeaveCode === "PTL") &&
        Object.keys(uploadAttachments?.attachments).length === 0 &&
        editState?.attachment === "")
    ) {
      toast.error("Attachment is required.");
    } else if (
      curLeaveCode === "PL" &&
      reasonListData?.label.toLowerCase() === "leave for lta" &&
      parseFloat(leaveGap.days) < 5
    ) {
      toast.error("PLTA is not allowed for less than 5 days.");
    } else {
      if (
        (dataSave[0]?.allow_half_day === false ||
          (applyLeave?.from_type !== "" && applyLeave?.to_type !== "")) &&
        leaveGap?.days !== 0
      ) {
        let obj = {
          leave_type: editState?.leave_type_id,
          leave_reason:
            editState?.option_holiday !== null
              ? editState?.reasons[0]?.reason_id
              : applyLeave?.leave_reason,
          dob_child: applyLeave?.dob_child,
          no_child: applyLeave?.no_child,
          from_date: applyLeave?.from_date,
          to_date: applyLeave?.to_date,
          from_type: applyLeave?.from_type,
          to_type: applyLeave?.to_type,
          leave_duration: leaveGap?.days,
          remarks: applyLeave?.remarks,
          attachment:
            uploadAttachments.attachments?.original_name !== undefined
              ? uploadAttachments.attachments
              : "",
          option_holiday: Number(applyLeave?.option_holiday),
        };
        console.log(obj);
        leaveDataService
          .updateApplyLeave(id, obj)
          .then((response) => {
            if(response.code=400){
              toast.error(response.data.message);
            }
            toast.success("Leave Updated Successfully.");
            onAddCallBack(response);
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      } else {
        setFormErrors((prev) => ({
          ...prev,
          fromType: applyLeave?.from_type === "" ? true : false,
          toType: applyLeave?.to_type === "" ? true : false,
        }));
      }
    }
  };

  const leaveAllocationListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`leaveAllocation${index}`}
        value={`${item?.leave_type_id}`}
        code={`${item?.code}`}
        label={`${item?.leave_type_name}`}
        leave={`${item?.leaves}`}
      >{`${item?.leave_type_name}`}</option>
    ));
  };

  const handleSetReasonValue = (e) => {
    let reasonValue = applyLeave;
    reasonValue.leave_reason = e.value;
    setReasonListData(e);
  };

  const handleHolidate = (e) => {
    setholidateListData(e);
    let reasonValue = applyLeave;
    reasonValue.option_holiday = e.value;
    reasonValue.from_date = e.key;
    reasonValue.to_date = e.key;
  };

  const handleShowValidate = (value, leaveList) => {
    let arr = leaveList?.length > 0 ? leaveList : dataLeave;
    let dataList = arr.filter((data) => {
      if (data.leave_type_id === Number(value)) {
        return data;
      }
    });
    setDataSave(dataList);
  };

  const onProfileChange = (e, name) => {
    const files = e.target.files[0];
    setLink(undefined);
    setImgSize(false);
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    dispatch(setDisplayLoader("Display"));
    leaveDataService.uploadImages(fileData).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (name === "attachments") {
        setUploadAttachments({
          ...uploadAttachments,
          attachments: response.data,
        });
        setfilename(response.data.original_name);

        setAttachmentsValidate(false);
      }
      if (response?.data?.status === false) {
        toast.error(response?.data?.message)
      }
    });
  };

  const validateJoiningDate = () => {
    if (reasonListData.length === 0 && curLeaveCode !== "OL") {
      toast.warning("Kindly select reason.");
      return 0;
    } else {
      let curDate = moment(new Date());
      let rule = usersRule?.filter((obj) => {
        let from_ad =
          obj?.allocate_type === "NSF"
            ? false
            : obj?.allocate_type === "SF"
              ? true
              : "A";
        if (from_ad === "A") {
          if (obj?.leave_type_id === Number(applyLeave?.leave_type)) {
            return true;
          }
        } else {
          if (
            obj?.leave_type_id === Number(applyLeave?.leave_type) &&
            userData?.is_sf_user === from_ad
          ) {
            return true;
          }
        }
      });
      if (
        rule?.length > 0 &&
        ["COL", "OL", "PL", "MTL"].includes(curLeaveCode) === false
      ) {
        let joiningDays = rule[0]?.joining_days;
        let difference = curDate.diff(
          moment(userData?.joiningDate?.date_of_joining, "YYYY-MM-DD"),
          "days"
        );
        if (difference < 0) {
          return Math.abs(difference) + joiningDays;
        } else if (difference === 0) {
          return joiningDays;
        } else if (difference > 0 && difference < joiningDays) {
          return Math.abs(joiningDays - difference);
        } else if (difference >= joiningDays) {
          return 0;
        }
      } else {
        let difference = curDate.diff(
          moment(userData?.joiningDate?.date_of_joining, "YYYY-MM-DD"),
          "days"
        );
        if (
          (curLeaveCode === "PL" || curLeaveCode === "MTL") &&
          userData?.emp_status?.toLowerCase() !== "confirmed"
        ) {
          if (difference > 240) {
            return 0;
          } else {
            return 240 - difference;
          }
        } else {
          return 0;
        }
      }
    }
  };

  const checkDateIsWeekOff = (val, type) => {
    let weekOffData = backDateWeekOff === null ? attendanceWeekOff : backDateWeekOff;
    let value = moment(val);
    if (value.isBefore(moment(new Date()))) {
      if (weekOffData?.includes(value.format("YYYY-MM-DD"))) {
        setFormErrors((prev) => ({
          ...prev,
          isWeekOffFrom: type === "from" && true,
          isWeekOffTo: type === "to" && true,
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          isWeekOffFrom: type === "from" && false,
          isWeekOffTo: type === "to" && false,
        }));
      }
    } else {
      if (
        value.format("dddd").toUpperCase().slice(0, 3) === weekOff?.first_off ||
        (value.format("dddd").toUpperCase().slice(0, 3) ===
          weekOff?.second_off &&
          weekOff?.alternate_off.includes(String(Math.ceil(value.date() / 7))))
      ) {
        setFormErrors((prev) => ({
          ...prev,
          isWeekOffFrom: type === "from" && true,
          isWeekOffTo: type === "to" && true,
        }));
      } else {
        setFormErrors((prev) => ({
          ...prev,
          isWeekOffFrom: type === "from" && false,
          isWeekOffTo: type === "to" && false,
        }));
      }
    }
  };

  function handleData(values) {
    if (
      (reasonListData?.label.toLowerCase() === "sickness" &&
        parseFloat(leaveGap.days) >= 3 &&
        Object.keys(uploadAttachments?.attachments).length === 0) ||
      ((curLeaveCode === "MTL" || curLeaveCode === "FPL") &&
        Object.keys(uploadAttachments?.attachments).length === 0)
    ) {
      toast.error("Attachment is required.");
    } else if (
      curLeaveCode === "PL" &&
      reasonListData?.label.toLowerCase() === "leave for lta" &&
      parseFloat(leaveGap.days) < 5
    ) {
      toast.error("PLTA is not allowed for less than 5 days.");
    } else {
      let checkJoining = validateJoiningDate();
      if (
        checkJoining === 0 &&
        exist === false &&
        canApply === false &&
        parseFloat(leaveGap.days) !== 0 &&
        formErrors?.isWeekOffFrom === false &&
        formErrors?.isWeekOffTo === false
      ) {
        if (
          (values?.from_type !== "" && values?.to_type !== "") ||
          dataSave[0]?.allow_half_day === false
        ) {
          let saveData = {
            leave_type: Number(applyLeave.leave_type),
            leave_reason: Number(applyLeave.leave_reason),
            no_child: applyLeave.no_child,
            from_date:
              curLeaveCode === "OL"
                ? applyLeave?.from_date
                : values.from_date === ""
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : values.from_date,
            to_date:
              curLeaveCode === "OL" ? applyLeave?.to_date : values.to_date,
            from_type:
              dataSave[0]?.allow_half_day === true ? values.from_type : "",
            to_type: dataSave[0]?.allow_half_day === true ? values.to_type : "",
            leave_duration: parseFloat(leaveGap.days),
            remarks: values.remarks,
            option_holiday: Number(applyLeave.option_holiday),
            attachment: uploadAttachments?.attachments,
          };
          if (dataSave[0]?.allow_pat_mat) {
            saveData.dob_child = values.dob_child;
          }
          saveApplyLeave(saveData);
        } else {
          setFormErrors((prev) => ({
            ...prev,
            fromType: values?.from_type === "" ? true : false,
            toType: values?.to_type === "" ? true : false,
          }));
        }
      } else {
        if (checkJoining !== 0) {
          if (curLeaveCode === "MTL") {
            toast.error("You are not eligible to apply selected leave.");
          }else if(curLeaveCode === "PL") {
            toast.error(
              `You cannot take PL in probation period.`
            );
          }
        }
      }
    }
  }

  useEffect(() => {
    if (edit) {
      if (moment(editState?.from_date).isBetween(moment(editBackDate[0]?.period_from), moment(editBackDate[0]?.period_to), undefined, '[]')) {
        setMaxDate(editBackDate[0]?.period_to)
        setMinimumDate(editBackDate[0]?.period_from)
      }
      getLeaveManageReasonList(Number(editState?.leave_type_id));
      if (editState?.attachment !== "") {
        setfilename(editState?.attachment?.split("/").pop());
      }
      setAttachmentsValidate(false);
      setLeaveGap((prev) => ({
        ...prev,
        from: editState?.from_date,
        to: editState?.to_date,
        days: editState?.leave_duration,
      }));
      // if (backDate?.length === 0) {
      // }
      // checkLeaveClubbing(editState?.leave_type_id);
      // getLeaveManageReasonList(editState?.leave_type_id);
      setReasonListData({
        label: editState?.reasons[0]?.reason,
        value: editState?.reasons[0]?.reason_id,
      });
      setApplyLeave((prev) => ({
        ...prev,
        leave_type: Number(editState?.leave_type_id),
        leave_reason: Number(editState?.reasons[0]?.reason_id),
        from_date: editState?.from_date,
        to_date: editState?.to_date,
        dob_child: editState?.dob_child,
        from_type: editState?.from_type,
        to_type: editState?.to_type,
        no_child: editState?.no_child,
        remarks: editState?.remarks,
        option_holiday: editState?.option_holiday,
      }));
    }
  }, [edit]);

  useEffect(() => {
    if (moment(new Date()).date() > 22) {
      setMonthCycle(true);
      setMinimumDate(
        `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
          "MM"
        )}-21`
      );
    }
    getLeaveRuleByUser();
    setDataLeave(leaveList);
    if (backDate?.length > 0) {
      let curData = backDate[0];
      setMinimumDate(curData?.period_from);
      const today = new Date();
      const maxDate = moment(
        new Date(new Date().setDate(today.getDate() - 1))
      ).format("YYYY-MM-DD");
      setMaxDate(curData?.period_to);
      getBackDateWeekOff(curData?.period_from, curData?.period_to)
    }
  }, [leaveList, backDate]);

  const getBackDateWeekOff = (from, to) => {
    leaveDataService.getUserHolidays(from, to).then((res) => {
      if (res.status === 200) {
        setBackDateWeekOff(res?.data?.dataList?.attendanceWeekOff)
      }
    })
  }

  useEffect(() => {
    console.log({ dataSave });
    if (dataSave[0]?.leave_type_name === "Optional Holiday") {
      getHolidayList(false);
    }
  }, [dataSave]);

  const getLeaveRuleByUser = async () => {
    await leaveDataService.getRulesForUser().then((response) => {
      if (response?.status === 200) {
        setUsersRule(response?.data?.dataList?.result);
      }
    });
  };

  const checkLeaveClubbing = (leaveId, obj) => {
    let check = obj?.clubLeaves?.filter((obj) => {
      if (obj?.leave_id === Number(leaveId)) {
        return true;
      }
    });
    if (check?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const userCalculatedLeaves = (event) => {
    if (leaveGap?.from === "") {
      toast.warning("Kindly select from date.");
    } else {
      let start = moment(leaveGap?.from);
      let end = moment(event.target.value);
      let gap = end.diff(start, "days") + 1;
      if (applyLeave.from_type === "Second") {
        gap = gap - 0.5;
      }
      if (curLeaveCode !== "MTL") {
        while (start <= end) {
          if (start.isBefore(moment(new Date()))) {
            if (attendanceWeekOff?.includes(start.format("YYYY-MM-DD"))) {
              gap = gap - 1;
            }
            if (mandatory.includes(start.format("YYYY-MM-DD"))) {
              gap = gap - 1;
            }
          } else {
            if (mandatory.includes(start.format("YYYY-MM-DD"))) {
              gap = gap - 1;
            } else if (
              start.format("dddd").toUpperCase().slice(0, 3) ===
              weekOff?.first_off
            ) {
              gap = gap - 1;
            } else if (
              start.format("dddd").toUpperCase().slice(0, 3) ===
              weekOff?.second_off &&
              weekOff?.alternate_off.includes(
                String(Math.ceil(start.date() / 7))
              )
            ) {
              gap = gap - 1;
            }
          }
          start.add(1, "days");
        }
      }
      if (gap < 0) {
        gap = 0;
      }
      setLeaveGap((prev) => ({
        ...prev,
        days: gap,
      }));
    }
  };
  const changeLeaveGap = (e, type) => {
    let gap = leaveGap?.days;
    if (e.target.value !== "" && leaveGap?.days > 0) {
      if (type === "from_type" && e.target.value === "Second") {
        gap = gap - 0.5;
      }
      if (
        type === "from_type" &&
        e.target.value === "First" &&
        applyLeave[type] !== ""
      ) {
        gap = gap + 0.5;
      }
      if (type === "to_type" && e.target.value === "First") {
        gap = gap - 0.5;
      }
      if (
        type === "to_type" &&
        e.target.value === "Second" &&
        applyLeave[type] !== ""
      ) {
        gap = gap + 0.5;
      }
      setLeaveGap((prev) => ({
        ...prev,
        days: gap,
      }));
    } else if (
      leaveGap?.days === 0 &&
      e.target.value === "Second" &&
      applyLeave?.from_type === "Second"
    ) {
      gap = gap + 0.5;
      setLeaveGap((prev) => ({
        ...prev,
        days: gap,
      }));
    } else if (
      leaveGap?.days === 0 &&
      e.target.value === "First" &&
      applyLeave?.to_type === "First"
    ) {
      gap = gap + 0.5;
      setLeaveGap((prev) => ({
        ...prev,
        days: gap,
      }));
    }
  };

  const checkLeaveExist = async (toDate, toType, fromType) => {
    let obj = {
      from_date: leaveGap?.from,
      to_date: toDate,
      from_type: fromType !== undefined ? fromType : applyLeave?.from_type,
      to_type: toType === null ? applyLeave?.to_type : toType,
      edit: edit,
      l_id: edit ? editState?.id : 0,
      leave_type: applyLeave?.leave_type
    };
    await leaveDataService
      .checkExist(obj)
      .then((response) => {
        if (response?.status === 200) {
          setExist(false);
          // if (isClub === false) {
          //   if (
          //     moment(leaveGap.from) > moment(lastLeave?.to_date) &&
          //     lastLeave?.to_date !== undefined
          //   ) {
          //     let diff = moment(leaveGap?.from).diff(
          //       moment(lastLeave?.to_date),
          //       "days"
          //     );
          //     if (diff < 2 && lastLeave?.from_type === "") {
          //       if (applyLeave?.from_type === "Second") {
          //         setCanApply(false);
          //       } else {
          //         toast.error(`Selected Leave can be applied after 0.5 days.`);
          //         setCanApply(true);
          //       }
          //     } else if (
          //       lastLeave?.to_type === "Second" &&
          //       (applyLeave?.from_type === "First" || curLeaveCode === "PL") &&
          //       diff === 1
          //     ) {
          //       toast.error(`Selected Leave can be applied after 0.5 day.`);
          //       setCanApply(true);
          //     } else {
          //       setCanApply(false);
          //     }
          //   } else if (moment(toDate).isBefore(moment(lastLeave?.from_date))) {
          //     let diff = moment(lastLeave?.from_date).diff(
          //       moment(toDate),
          //       "days"
          //     );
          //     if (diff < 1) {
          //       toast.error(
          //         `Selected Leave can be applied after ${diff} days.`
          //       );
          //       setCanApply(true);
          //     } else if (diff === 1) {
          //       if (toType === "Second" || lastLeave?.from_type === "First") {
          //         toast.error(`Selected Leave can be applied after 0.5 days.`);
          //         setCanApply(true);
          //       }
          //     } else {
          //       setCanApply(false);
          //     }
          //   }
          // }
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setExist(true);
      });
  };

  const checkClubbing = (values) => {
    console.log(values, lastLeave);
    let flag = true;
    let message = "";
    if (lastLeave !== null) {
      for (let index = 0; index < lastLeave.length; index++) {
        console.log(moment(values?.to_date).isBefore(moment(lastLeave[index]?.from_date)), moment(leaveGap?.from).isAfter(moment(lastLeave[index]?.to_date)), lastLeave[index]?.to_date);
        if (
          moment(leaveGap?.from).isAfter(moment(lastLeave[index]?.to_date)) &&
          (moment(leaveGap?.from).diff(
            moment(lastLeave[index]?.to_date),
            "days"
          ) === 1 ||
            moment(leaveGap?.from).diff(
              moment(lastLeave[index]?.to_date),
              "days"
            ) === -1) &&
          checkLeaveClubbing(applyLeave?.leave_type, lastLeave[index]) === false
        ) {
          if (
            (applyLeave?.from_type === "First" ||
              dataSave[0]?.allow_half_day === false) &&
            (lastLeave[index]?.to_type === "Second" ||
              lastLeave[index]?.to_type === "")
          ) {
            flag = false;
            message = `You cannot club ${dataSave[0]?.code} with ${lastLeave[index]?.leave_type__code}`;
            break;
          }
        } else if (
          moment(values?.to_date).isBefore(moment(lastLeave[index]?.from_date)) &&
          (moment(values?.to_date).diff(
            moment(lastLeave[index]?.from_date),
            "days"
          ) === 1 ||
            moment(values?.to_date).diff(
              moment(lastLeave[index]?.from_date),
              "days"
            ) === -1) &&
          checkLeaveClubbing(applyLeave?.leave_type, lastLeave[index]) === false
        ) {
          if (
            (applyLeave?.to_type === "Second" ||
              dataSave[0]?.allow_half_day === false) &&
            (lastLeave[index]?.from_type === "First" ||
              lastLeave[index]?.from_type === "")
          ) {
            flag = false;
            message = `You cannot club ${dataSave[0]?.code} with ${lastLeave[index]?.leave_type__code}`;
            break;
          }
        }
      }
    }
    let returnData = {
      flag: flag,
      message: message,
    };
    return returnData;
  };

  const checkYearValid = (values) => {
    let curYear = moment(new Date()).year()
    let fromDate = moment(values?.from_date).year()
    let toDate = moment(values?.to_date).year()
    if (fromDate > curYear || toDate > curYear) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <Formik
        initialValues={applyLeave}
        validationSchema={
          !edit
            ? dataSave && dataSave[0]?.code !== "OL"
              ? validationSchema
              : optionalSchema
            : ""
        }
        onSubmit={async (values) => {
          // let result = checkClubbing(values);
          // if (result?.flag) {
          if (editState?.id !== undefined) {
            updateApplyLeave(editState?.id, values);
            AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            let checkLeaveYear = checkYearValid(values);
            if (checkLeaveYear) {
              handleData(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              toast.error('Kindly apply leave for current year.')
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          // } else {
          //   toast.error(result?.message);
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div class="content-wrapper-inner">
            <div class="innerheadsec pb-0">
              <div className="row">
                <ToastContainer autoClose={1000} />
                <div class="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <h4 class="inner-page-title">Apply Leave</h4>
                    </div>
                    <div className="col-md-9 text-right">
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        *Transactions before 20th of current month are not
                        allowed after 22nd of current month.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mb-3"></div>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        {/* <div class="col-md-6">
                          <select class="form-control newbgselect">
                            <option>One</option>
                            <option>Two</option>
                            <option>Three</option>
                          </select>
                        </div> */}
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Type Of Leave<span class="text-danger">*</span>
                            </label>
                            <select
                              class="form-control newbgselect"
                              name="leave_type"
                              type="number"
                              onBlur={handleBlur}
                              disabled={edit}
                              onChange={(e) => {
                                setCurLeaveCode(
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("code")
                                );
                                if (
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("code") === "OL"
                                ) {
                                  setLeaveGap((prev) => ({
                                    ...prev,
                                    days: 1,
                                    to: "",
                                  }));
                                } else {
                                  setLeaveGap((prev) => ({
                                    ...prev,
                                    from: moment(new Date()).format(
                                      "YYYY-MM-DD"
                                    ),
                                    days: "",
                                    to: "",
                                  }));
                                }
                                setFieldValue("from_date", "");
                                setFieldValue("to_date", "");
                                handleChange(e);
                                setApplyLeave({
                                  ...applyLeave,
                                  leave_type: e.target.value,
                                  from_type: "",
                                  to_type: "",
                                });
                                // checkLeaveClubbing(e.target.value);
                                getLeaveManageReasonList(e.target.value);
                                handleShowValidate(e.target.value);
                              }}
                              value={Number(applyLeave?.leave_type)}
                            >
                              <option
                                value="0"
                                label="Select Leave type"
                                disabled
                              >
                                Select Leave Type
                              </option>

                              {leaveAllocationListData(dataLeave)}
                            </select>
                            {touched.leave_type && errors.leave_type ? (
                              <div className="small text-danger">
                                {errors.leave_type}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {dataSave && dataSave[0]?.code === "OL" ? (
                          <div class="col-md-6">
                            <div class="form-group innergroup">
                              <label>
                                Holiday Events
                                <span class="text-danger">*</span>
                              </label>
                              <Select
                                name="option_holiday"
                                options={holidayList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleHolidate(e);
                                  handleChange(e);
                                }}
                                value={
                                  edit === false
                                    ? holidateListData
                                    : holidayList?.filter((obj) => {
                                      if (
                                        obj?.value ===
                                        applyLeave?.option_holiday
                                      ) {
                                        return true;
                                      }
                                    })[0]
                                }
                              />
                              {/* {touched.option_holiday &&
                            errors.option_holiday ? (
                              <div className="small text-danger">
                                {errors.option_holiday}
                              </div>
                            ) : null} */}
                            </div>
                          </div>
                        ) : dataSave && dataSave[0]?.code !== "OL" ? (
                          <>
                            <div class="col-md-6">
                              <div class="form-group innergroup">
                                <label>Reason For Taking Leave</label>
                                <Select
                                  name="leave_reason"
                                  options={ReasonList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    // handleChange(e);
                                    handleSetReasonValue(e);
                                  }}
                                  value={reasonListData}
                                />
                                {touched.leave_reason && errors.leave_reason ? (
                                  <div className="small text-danger">
                                    {errors.leave_reason}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            {dataSave &&
                              (dataSave[0]?.code === "MTL" ||
                                dataSave[0]?.code === "PTL") && (
                                <>
                                  <div class="col-md-6">
                                    <div class="form-group innergroup">
                                      <label>
                                        Number of Child
                                        <span class="text-danger">*</span>
                                      </label>
                                      <select
                                        class="form-control"
                                        name="no_child"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setApplyLeave({
                                            ...applyLeave,
                                            no_child: e.target.value,
                                          });
                                        }}
                                        value={applyLeave.no_child}
                                      >
                                        <option value="">Select</option>
                                        <option value="first">First</option>
                                        <option value="second">Second</option>
                                      </select>
                                      {touched.no_child && errors.no_child ? (
                                        <div className="small text-danger">
                                          {errors.no_child}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group innergroup">
                                      <label>
                                        DOB of Child
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        name="dob_child"
                                        type="date"
                                        placeholder="Enter From Date"
                                        class="form-control"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setApplyLeave((prev) => ({
                                            ...prev,
                                            dob_child: e.target.value,
                                          }));
                                        }}
                                        value={
                                          !edit
                                            ? values.dob_child
                                            : moment(
                                              applyLeave?.dob_child
                                            ).format("YYYY-MM-DD")
                                        }
                                      />
                                      {touched.dob_child && errors.dob_child ? (
                                        <div className="small text-danger">
                                          {errors.dob_child}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </>
                              )}
                            <div class="col-md-6">
                              <div className="row">
                                <div class="col-md-6 form-group innergroup">
                                  <label>
                                    From Date<span class="text-danger">*</span>
                                  </label>
                                  <input
                                    id="fromDate"
                                    name="from_date"
                                    type="date"
                                    placeholder="Enter From Date"
                                    class="form-control"
                                    min={
                                      backDate?.length > 0 ||
                                        monthCycle === true
                                        ? minimumDate
                                        : (editBackDate?.length > 0 && edit) ? minimumDate
                                          : moment(new Date()).month() === 0
                                            ? `${moment(new Date()).format(
                                              "YYYY"
                                            )}-01-01`
                                            : `${moment(new Date()).format(
                                              "YYYY"
                                            )}-${moment(new Date())
                                              .subtract(1, "month")
                                              .format("MM")}-20`
                                    }
                                    max={maxDate}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      checkDateIsWeekOff(
                                        e.target.value,
                                        "from"
                                      );
                                      setMinimumDate(e.target.value);
                                      setLeaveGap((prev) => ({
                                        ...prev,
                                        from: e.target.value,
                                        to: "",
                                        days: "",
                                      }));
                                      setFieldValue("to_date", "");
                                      setApplyLeave((prev) => ({
                                        ...prev,
                                        from_type: "",
                                        to_type: "",
                                      }));
                                      if (edit) {
                                        setApplyLeave((prev) => ({
                                          ...prev,
                                          from_date: e.target.value,
                                          to_date: "",
                                        }));
                                      }
                                    }}
                                    value={
                                      !edit
                                        ? values.from_date === ""
                                          ? moment(new Date()).format(
                                            "YYYY-MM-DD"
                                          )
                                          : values.from_date
                                        : applyLeave?.from_date
                                    }
                                  />
                                  {touched.from_date && errors.from_date ? (
                                    <div className="small text-danger">
                                      {errors.from_date}
                                    </div>
                                  ) : formErrors?.isWeekOffFrom ? (
                                    <div className="small text-danger">
                                      From Date cannot be week off.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {!edit
                                  ? dataSave &&
                                  dataSave[0]?.allow_half_day === true && (
                                    <div class="col-md-6">
                                      <div class="form-group innergroup">
                                        <label>
                                          {/* From Type{" "} */}
                                          {/* <span class="text-danger">*</span> */}
                                        </label>
                                        <select
                                          class="form-control"
                                          name="from_type"
                                          onBlur={(event) => {
                                            handleBlur(event);
                                            if (event.target.value === "") {
                                              setFormErrors((prev) => ({
                                                ...prev,
                                                fromType: true,
                                              }));
                                            }
                                          }}
                                          // disabled={values?.from_date === ""}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFormErrors((prev) => ({
                                              ...prev,
                                              fromType: false,
                                            }));
                                            setApplyLeave({
                                              ...applyLeave,
                                              from_type: e.target.value,
                                            });
                                            changeLeaveGap(e, "from_type");
                                            if (applyLeave?.to_type !== "") {
                                              checkLeaveExist(
                                                values?.to_date,
                                                null,
                                                e.target.value
                                              );
                                            }
                                          }}
                                          value={applyLeave.from_type}
                                        >
                                          <option value="">Select</option>
                                          <option value="First">
                                            First Half
                                          </option>
                                          <option value="Second">
                                            Second Half
                                          </option>
                                        </select>
                                        {touched.from_type &&
                                          errors.from_type ? (
                                          <div className="small text-danger">
                                            {errors.from_type}
                                          </div>
                                        ) : formErrors?.fromType ? (
                                          <div className="small text-danger">
                                            From Type is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )
                                  : editState?.from_type !== undefined &&
                                  editState?.from_type !== "" && (
                                    <div class="col-md-6">
                                      <div class="form-group innergroup">
                                        <label>
                                          {/* From Type{" "} */}
                                          {/* <span class="text-danger">*</span> */}
                                        </label>
                                        <select
                                          class="form-control"
                                          name="from_type"
                                          onBlur={handleBlur}
                                          // disabled={values?.from_date === ""}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setApplyLeave({
                                              ...applyLeave,
                                              from_type: e.target.value,
                                            });
                                            changeLeaveGap(e, "from_type");
                                          }}
                                          value={applyLeave.from_type}
                                        >
                                          <option value="">Select</option>
                                          <option value="First">
                                            First Half
                                          </option>
                                          <option value="Second">
                                            Second Half
                                          </option>
                                        </select>
                                        {touched.from_type &&
                                          errors.from_type ? (
                                          <div className="small text-danger">
                                            {errors.from_type}
                                          </div>
                                        ) : formErrors?.fromType ? (
                                          <div className="small text-danger">
                                            From Type is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div className="row">
                                <div class="col-md-6 form-group innergroup">
                                  <label>
                                    To Date<span class="text-danger">*</span>
                                  </label>
                                  <input
                                    name="to_date"
                                    type="date"
                                    placeholder="Enter To Date"
                                    class="form-control"
                                    min={!edit ? minimumDate : (editBackDate?.length > 0 && edit) ? minimumDate : ""}
                                    onBlur={handleBlur}
                                    max={maxDate}
                                    onChange={(e) => {
                                      handleChange(e);
                                      checkDateIsWeekOff(e.target.value, "to");
                                      userCalculatedLeaves(e);
                                      if (
                                        dataSave[0]?.allow_half_day !== true
                                      ) {
                                        checkLeaveExist(e.target.value);
                                      }
                                      if (edit) {
                                        setApplyLeave((prev) => ({
                                          ...prev,
                                          to_date: e.target.value,
                                        }));
                                      }
                                      setApplyLeave((prev) => ({
                                        ...prev,
                                        to_type: "",
                                      }));
                                    }}
                                    value={
                                      !edit
                                        ? values.to_date
                                        : applyLeave?.to_date
                                    }
                                  />
                                  {touched.to_date && errors.to_date ? (
                                    <div className="small text-danger">
                                      {errors.to_date}
                                    </div>
                                  ) : formErrors?.isWeekOffTo ? (
                                    <div className="small text-danger">
                                      To Date cannot be week off.
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {!edit
                                  ? (dataSave && dataSave[0].allow_half_day) ===
                                  true && (
                                    <div class="col-md-6">
                                      <div class="form-group innergroup">
                                        <label>
                                          {/* To Type{" "} */}
                                          {/* <span class="text-danger">*</span> */}
                                        </label>
                                        <select
                                          class="form-control"
                                          name="to_type"
                                          onBlur={(event) => {
                                            handleBlur(event);
                                            if (event.target.value === "") {
                                              setFormErrors((prev) => ({
                                                ...prev,
                                                toType: true,
                                              }));
                                            }
                                          }}
                                          disabled={values?.to_date === ""}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFormErrors((prev) => ({
                                              ...prev,
                                              toType: false,
                                            }));
                                            setApplyLeave({
                                              ...applyLeave,
                                              to_type: e.target.value,
                                            });
                                            changeLeaveGap(e, "to_type");
                                            checkLeaveExist(
                                              values?.to_date,
                                              e.target.value
                                            );
                                          }}
                                          value={applyLeave.to_type}
                                        >
                                          <option value="">Select</option>
                                          <option value="First">
                                            First Half
                                          </option>
                                          <option value="Second">
                                            Second Half
                                          </option>
                                        </select>
                                        {touched.to_type && errors.to_type ? (
                                          <div className="small text-danger">
                                            {errors.to_type}
                                          </div>
                                        ) : formErrors?.toType ? (
                                          <div className="small text-danger">
                                            To Type is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )
                                  : editState?.to_type !== undefined &&
                                  editState?.to_type !== "" && (
                                    <div class="col-md-6">
                                      <div class="form-group innergroup">
                                        <label>
                                          {/* To Type{" "} */}
                                          {/* <span class="text-danger">*</span> */}
                                        </label>
                                        <select
                                          class="form-control"
                                          name="to_type"
                                          onBlur={handleBlur}
                                          // disabled={values?.to_date === ""}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setApplyLeave({
                                              ...applyLeave,
                                              to_type: e.target.value,
                                            });
                                            changeLeaveGap(e, "to_type");
                                          }}
                                          value={applyLeave.to_type}
                                        >
                                          <option value="">Select</option>
                                          <option value="First">
                                            First Half
                                          </option>
                                          <option value="Second">
                                            Second Half
                                          </option>
                                        </select>
                                        {touched.to_type && errors.to_type ? (
                                          <div className="small text-danger">
                                            {errors.to_type}
                                          </div>
                                        ) : formErrors?.toType ? (
                                          <div className="small text-danger">
                                            To Type is required
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {dataSave &&
                          dataSave[0]?.leave_type_name !== "Optional Leave" && (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label>Number of Days</label>
                                <input
                                  name="leave_duration"
                                  type="text"
                                  onChange={(e) => {
                                    if (e.target.value >= 0) {
                                      handleChange(e);
                                    }
                                  }}
                                  disabled
                                  onBlur={handleBlur}
                                  value={leaveGap?.days}
                                  //   value="3"
                                  placeholder="Enter Total No. of Leaves Required"
                                  class="form-control"
                                />
                                {touched.leave_duration &&
                                  errors.leave_duration ? (
                                  <div className="small text-danger">
                                    {errors.leave_duration}
                                  </div>
                                ) : leaveGap?.days === 0 ? (
                                  <div className="small text-danger">
                                    Leave Days should be greater than zero.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        {!edit ? (
                          dataSave &&
                          dataSave[0].is_document_required === true && (
                            <div class="col-md-4">
                              <div class="form-group innergroup position-relative">
                                <label>Attachment</label>
                                {filename != undefined ? (
                                  <input
                                    type="text"
                                    name="attachments"
                                    class="form-control bg-white"
                                    placeholder="(Image, PDF Format)"
                                    disabled
                                    value={filename}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control bg-white"
                                    disabled
                                    placeholder="(Image, PDF Format)"
                                  />
                                )}

                                <div class="upload-btn-wrapper up-loposition">
                                  <button class="uploadBtn">Browse</button>
                                  <input
                                    type="file"
                                    title=""
                                    name="attachments"
                                    id="attachmentsFile"
                                    accept="image/*, application/pdf"
                                    onChange={(e) =>
                                      onProfileChange(e, "attachments")
                                    }
                                  />
                                </div>
                                {imgSize ? (
                                  <div className="small text-danger">
                                    File size should not exceed 1mb
                                  </div>
                                ) : null}
                                {filename != undefined && (
                                  <p>
                                    Selected file
                                    <span> {filename}</span>
                                  </p>
                                )}
                                {link != undefined && (
                                  <p>Selected file {<span> {link}</span>}</p>
                                )}
                              </div>
                            </div>
                          )
                        ) : (
                          <div class="col-md-4">
                            <div class="form-group innergroup position-relative">
                              <label>Attachment</label>
                              {filename != undefined ? (
                                <input
                                  type="text"
                                  name="attachments"
                                  class="form-control bg-white"
                                  placeholder="(Image, PDF Format)"
                                  disabled
                                  value={filename}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control bg-white"
                                  disabled
                                  placeholder="(Image, PDF Format)"
                                />
                              )}
                              {attachmentsValidate ? (
                                <div className="small text-danger">
                                  *Attachment is required
                                </div>
                              ) : null}
                              <div class="upload-btn-wrapper up-loposition">
                                <button class="uploadBtn">Browse</button>
                                <input
                                  type="file"
                                  title=""
                                  name="attachments"
                                  id="attachmentsFile"
                                  accept="image/*, application/pdf"
                                  onChange={(e) =>
                                    onProfileChange(e, "attachments")
                                  }
                                />
                              </div>
                              {imgSize ? (
                                <div className="small text-danger">
                                  File size should not exceed 1mb
                                </div>
                              ) : null}
                              {filename != undefined && (
                                <p>
                                  Selected file
                                  <span> {filename}</span>
                                </p>
                              )}
                              {link != undefined && (
                                <p>Selected file {<span> {link}</span>}</p>
                              )}
                            </div>
                          </div>
                        )}
                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>Remarks</label>
                            <input
                              name="remarks"
                              type="text"
                              placeholder="Enter your Remarks"
                              class="form-control"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setApplyLeave((prev) => ({
                                  ...prev,
                                  remarks: e.target.value,
                                }));
                              }}
                              value={
                                !edit ? values.remarks : applyLeave?.remarks
                              }
                            />
                            {touched.remarks && errors.remarks ? (
                              <div className="small text-danger">
                                {errors.remarks}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <button
                            class="btn btn-outline-secondary mr-3"
                            type="button"
                            onClick={() => onClose()}
                          >
                            <i class="far fa-arrow-alt-circle-left"></i> Back
                          </button>
                          {finalUrlValue?.A && (
                            <button
                              class="btn btn-primary-inner bpi-main"
                              type="submit"
                            >
                              <i class="far fa-check-circle"></i> Submit
                            </button>
                          )}
                          <button
                            class="btn btn-outline-secondary ml-3"
                            type="button"
                            onClick={() => {
                              resetData();
                            }}
                          >
                            <i class="far fa-check-circle"></i> Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <LeaveTypeGrid weekOff={weekOff} />
                  </div>
                  <div class="travel-note mt-12">
                    <h4>NOTES :-</h4>
                    <ul>
                      <li>
                        You can avail Contingency Leave during probation and
                        post confirmation for a minimum of half day at a time.
                      </li>
                      <li>
                        In case an employee avails of the Contingency Leave for
                        3 or more days on medical ground , the company has right
                        to ask for a medical certificate from a company
                        authorised medical practitioner.
                      </li>
                      <li>
                        Any public holiday and / weekly offs preceding;
                        succeeding or falling in between the Contigency Leave
                        period will not be included for the purpose of
                        calculations of leaves.
                      </li>
                      <li>
                        You cannot club Contingency Leave with any other
                        category of leave.
                      </li>
                    </ul>
                  </div>
                </>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default ApplyLeave;
