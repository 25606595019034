import { useEffect, useState } from "react";
import * as MyProfileService from "../../service/profile";
import { setDisplayLoader } from "../redux/piDataStore";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
const SsoRequest = () => {
  const [resErr, setResErr] = useState("");
  const dispatch = useDispatch();
  const [permissionAccess, setPermissionAccess] = useState(false);
  const userData = useSelector(selectUserData);
  const getComplianceManager = (py) => {
    MyProfileService.getComplianceManager(py)
      .then((response) => {
        const res = response?.data?.dataList?.result;
        setPermissionAccess(true);
        setResErr(
          "The SSO login session is invalid. Please log in with a PI session and try again."
          );
          if (res.includes("Error Occured")) {
            setTimeout(() => {
              window.location.href = "/";
            }, 5000);
          } else {
          document.open();
          document.write(res);
          document.close();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const redrictURL = (ssol, url) => {
    const full = window.location.protocol + "//" + window.location.host;
    if (url.includes("SAMLRequest")) {
      const urlParams = new URLSearchParams(url);
      const res = (urlParams + "").replace("SAMLRequest=", "");
      if (ssol[0] === "MDO") {
        const pyl = {
          saml_Request: res,
          issuer_id: full,
          flag: ssol[0],
          c_user: "",
        };

        getComplianceManager(pyl);
      } else if (ssol[0] === "CMP") {
        const pyl = {
          saml_Request: res,
          issuer_id: full,
          flag: "COMPLIANCE",
          c_user: "",
        };
        getComplianceManager(pyl);
      } else if (ssol[0] === "GRC") {
        const pyl = {
          saml_Request: res,
          issuer_id: full,
          flag: ssol[0],
          c_user: ssol.pop(),
        };
        getComplianceManager(pyl);
      }
    } else {
      dispatch(setDisplayLoader("Display"));
      // setIsLoading(true)
      window.location.href = ssol[1];
    }
  };

  useEffect(() => {
    //    dispatch(setDisplayLoader("Display"));
    const ssol = atob(localStorage.getItem("currsso")).split("@@");
    if (ssol === undefined || ssol === null || ssol.length <= 1) {
      setPermissionAccess(true);
      setResErr(
        "The SSO login session is invalid. Please log in with a PI session and try again."
      );
    } else {
      const curl = window.location.search;
      redrictURL(ssol, curl);
    }
  }, [userData]);

  return (
    <>
      {permissionAccess === true ? (
        <>
          <h5>
            <header className="d-none d-md-block">
              <div class="col-md-2 text-center">
                <img src="/images/myPiLogo.png" alt="" height={75} />
              </div>
            </header>
          </h5>
          <h3
            style={{
              textAlign: "center",
              color: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {resErr}
          </h3>
          <div
            class="row text-center align-items-center"
            style={{ height: 400 }}
          >
            <div class="col-md-12">
              <img
                src="/images/errorSso.png"
                alt="/"
                height={300}
                width={300}
              />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SsoRequest;
