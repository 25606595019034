import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Alert } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as vehicleService from "../../service/vehiclelog";
import { confirmAlert } from "react-confirm-alert";

const ForeignExpense = (props) => {
  const {
    checkActiveTab,
    state,
    setCheckActiveTab,
    rateData,
    handleExchnagePopup,
    gridDataNew,
  } = props;
  const userDetails = useSelector(selectUserData);
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyFData, setCurrencyFData] = useState([]);
  const [currencyIncData, setCurrencyIncData] = useState([]);
  const [foreignExp, setForeignExpD] = useState([]);
  const [currencyDaData, setCurrencyDaData] = useState([]);
  const [currencyFlag, setCurrencyFlag] = useState(false);
  const [currencyFoodFlag, setCurrencyFoodFlag] = useState(false);
  const [currencyIncFlag, setCurrencyIncFlag] = useState(false);
  const [currencyEntFlag, setCurrencyEntFlag] = useState(false);
  const [currencyConFlag, setCurrencyConFlag] = useState(false);
  const [travelModeFlag, setTravelModeFlag] = useState(false);
  const [currValue, setCurrValue] = useState([]);
  const [sumTotal, setSumTotal] = useState();
  const [foodSumTotal, setFoodSumTotal] = useState();
  const [conveyanceSumTotal, setConveyanceSumTotal] = useState();
  const [daSumTotal, setDaSumTotal] = useState();
  const [currFValue, setCurrFValue] = useState([]);
  const [currConvValue, setCurrConvValue] = useState([]);
  const [currIncValue, setCurrIncValue] = useState();
  const [currDaValue, setCurrDaValue] = useState();
  const [currEntValue, setCurrEntValue] = useState([]);
  const [travelMValue, setTravelMValue] = useState([]);
  const [contAmount, setContAmount] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [errorAmount, setErrorAmount] = useState(false);
  const [foreignExpenseValue, setForeignExpenseValue] = useState();
  const [currConvData, setCurrConvData] = useState();
  const [flagIndex, setFlagIndex] = useState(false);
  const [flagFoodIndex, setFlagFoodIndex] = useState(false);
  const [flagConvIndex, setFlagConvIndex] = useState(false);
  const [totalValueAmount, setTotalValueAmount] = useState();
  const [daValueData, setDaValueData] = useState();
  const [hotelData, setHotelData] = useState([
    {
      hotel_name: "",
      check_in_date: "",
      check_out_date: "",
      paid_by: "Self",
      vendor_invoice: "",
      invoice_date: "",
      currency_id: null,
      amount_in_forex: 0,
      amount_in_inr: 0,
      selected: false,
      // id: 0,
    },
  ]);
  const [foodData, setFoodData] = useState([
    {
      food_date: "",
      paid_by: "Self",
      vendor_name: "",
      currency_id: null,
      amount_in_forex: 0,
      amount_in_inr: 0,
    },
  ]);
  const [fExp, setFExp] = useState("");
  const [conveyanceData, setConveyanceData] = useState([
    {
      conveyance_date: "",
      from_location: "",
      to_location: "",
      travel_mode_id: "",
      paid_by: "Self",
      currency_id: null,
      amount_in_forex: 0,
      amount_in_inr: 0,
      is_exception: false,
    },
  ]);
  let dda = 20 * rateData?.usd;
  const [daData, setDaData] = useState([
    {
      incidental_date: "",
      ent_currency_id: 5,
      ent_amount_in_forex: 20,
      ent_amount_in_inr: 0,
      currency_id: 0,
      amount_in_forex: 0,
      amount_in_inr: 0,
    },
  ]);
  const [formValidation, setFormValidation] = useState({
    hotel_name: "",
    check_in_date: "",
    check_out_date: "",
    paid_by: "Self",
    vendor_invoice: "",
    invoice_date: "",
    currency_id: "",
    amount_in_forex: "",
    // amount_in_inr: "",
  });
  const [formFoodValidation, setFormFoodValidation] = useState({
    food_date: "",
    paid_by: "Self",
    vendor_name: "",
    currency_id: "",
    amount_in_forex: "",
    // amount_in_inr: "",
  });
  const [formConvValidation, setFormConvValidation] = useState({
    conveyance_date: "",
    from_location: "",
    to_location: "",
    travel_mode_id: "",
    paid_by: "Self",
    currency_id: "",
    amount_in_forex: "",
    amount_in_inr: "",
  });
  const [formDaValidation, setFormDaValidation] = useState({
    amount_in_forex: "",
    amount_in_inr: "",
  });

  const amountRegex = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/);

  // const getForeignExpenseData = (travel_id) => {
  //   vehicleService
  //     .getForeignExpenseData(travel_id)
  //     .then((response) => {
  //       let data = response?.data;
  //       let temp = [];
  //       if (data) {
  //         temp = data.map((date) => {
  //           let dateArr = date.split("/");
  //           return {
  //             incidental_date: moment(
  //               new Date(`${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`)
  //             ).format("YYYY-MM-DD"),
  //             ent_currency_id: null,
  //             ent_amount_in_forex: 20,
  //             ent_amount_in_inr: dda,
  //             currency_id: null,
  //             amount_in_forex: 0,
  //           };
  //         });
  //       }
  //       return temp;
  //       // setForeignExpenseValue(data);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const getForeignExpenseData = async (travel_id) => {
    let temp = [];
    let sumDA = 0;
    await vehicleService
      .getForeignExpenseData(travel_id)
      .then((response) => {
        if (!response?.data) return [];
        let data = response?.data;
        if (data) {
          temp = data.map((date) => {
            let dateArr = date.split("/");
            return {
              incidental_date: moment(
                new Date(`${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`)
              ).format("YYYY-MM-DD"),
              ent_currency_id: null,
              ent_amount_in_forex: 20,
              ent_amount_in_inr: dda,
              currency_id: null,
              amount_in_forex: 0,
            };
          });
          setForeignExpenseValue(data);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
        // reject([]);
      });
    return temp;
    // });
  };

  const changeHandler = (value, name, index, curName) => {
    let value_in;
    let sum = 0;
    if (name === "amount_in_forex") {
      // if (!amountRegex.test(value)) return;
      let fd = [...hotelData];
      if (fd[index]["currency_id"] == 1) {
        value_in = Number(value) * Number(1);
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      } else {
        value_in = Number(value) * Number(currValue);
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      }
      setHotelData(fd);
    } else if (name === "currency_id") {
      // if (!amountRegex.test(value)) return;
      value_in = Number(value) * Number(currValue);
      let fd = [...hotelData];
      fd[index]["amount_in_forex"] = value;
      fd[index]["amount_in_inr"] = value_in;
      fd[index]["amount_in_inr"] =
        fd[index]["amount_in_forex"] * (value?.rate === "" ? 1 : value?.rate);
      fd[index]["currency_id"] = value?.value;
      setHotelData(fd);
    } else {
      if (
        (name === "amount_in_inr" || name === "amount_in_forex") &&
        !amountRegex.test(value)
      )
        return;
      let fd = [...hotelData];
      fd[index][name] = value;

      setHotelData(fd);
    }
    hotelData?.map((data) => {
      sum = data?.amount_in_inr + sum;
    });
    let sumtotal =
      sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
    setSumTotal(sumtotal);
  };
  const changeFoodHandler = (value, name, index, curName) => {
    let value_in;
    let sum = 0;
    if (name === "amount_in_forex") {
      if (!amountRegex.test(value)) return;
      let fd = [...foodData];
      if (fd[index]["currency_id"] == 1) {
        value_in = Number(value) * Number(1);
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      } else {
        value_in = Number(value) * Number(currFValue);
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      }
      setFoodData(fd);
    } else if (name == "currency_id") {
      // if (!amountRegex.test(value)) return;
      value_in = Number(value) * Number(currFValue);
      let fd = [...foodData];
      fd[index]["amount_in_forex"] = value;
      fd[index]["amount_in_inr"] = value_in;
      fd[index]["amount_in_inr"] =
        fd[index]["amount_in_forex"] * (value?.rate === "" ? 1 : value?.rate);
      fd[index]["currency_id"] = value.value;
      setFoodData(fd);
    } else {
      if (
        (name === "amount_in_inr" || name === "amount_in_forex") &&
        !amountRegex.test(value)
      )
        return;
      let fd = [...foodData];
      fd[index][name] = value;
      setFoodData(fd);
    }
    foodData?.forEach((value) => {
      if (value?.amount_in_inr) sum += value?.amount_in_inr;
    });
    let sumtotal =
      sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
    setFoodSumTotal(sumtotal);
  };

  const changeConvHandler = (value, name, index, curName) => {
    let value_in;
    let sum = 0;
    let fd = [...conveyanceData];
    if (name === "currency_id") {
      fd[index]["currency_id"] = value.value;
      fd[index]["amount_in_inr"] = 0;
      fd[index]["amount_in_forex"] = 0;
    } else if (name === "amount_in_forex") {
      // if (!amountRegex.test(value)) return;
      if (fd[index]["currency_id"] == 1) {
        value_in = Number(value) * Number(1);
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      } else {
        value_in = value * currConvValue;
        fd[index]["amount_in_forex"] = value;
        fd[index]["amount_in_inr"] = value_in;
      }
      setConveyanceData(fd);
    } else if (name === "currency_id") {
      // if (!amountRegex.test(value)) return;
      value_in = value * currConvValue;
      fd[index]["amount_in_forex"] = value;
      fd[index]["amount_in_inr"] = value_in;
      fd[index]["amount_in_inr"] =
        fd[index]["amount_in_forex"] * (value?.rate === "" ? 1 : value?.rate);
      fd[index]["currency_id"] = value.value;
      setConveyanceData(fd);
    } else {
      if (
        (name === "amount_in_inr" || name === "amount_in_forex") &&
        !amountRegex.test(value)
      )
        return;
      let fd = [...conveyanceData];
      fd[index][name] = value;

      setConveyanceData(fd);
    }
    conveyanceData?.map((value) => {
      sum = value?.amount_in_inr + sum;
    });
    let sumtotal =
      sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
    setConveyanceSumTotal(sumtotal);
  };

  const changeDaHandler = (value, name, index, e) => {
    let value_in;
    let sum = 0;
    let fd = [...daData];
    fd[index]["ent_currency_id"] = 5;
    if (name === "currency_id") {
      fd[index]["currency_id"] = value;
      fd[index]["amount_in_inr"] = 0;
      fd[index]["amount_in_forex"] = 0;
    } else {
      if (!amountRegex.test(value)) return;
      value_in = value * currDaValue;
      fd[index]["amount_in_inr"] = value_in;
      fd[index][name] = value;
    }
    fd?.map((data, i) => {
      sum = Number(data?.amount_in_inr) + Number(sum);
    });
    if (
      name == "amount_in_forex" &&
      daData[index].ent_amount_in_forex * fExp < daData[index].amount_in_inr
    ) {
      setErrorAmount(true);
    } else {
      setErrorAmount(false);
    }
    let sumtotal =
      sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
    setDaSumTotal(sumtotal);
    setDaData(fd);
  };

  const validateSSCFeature = () => {
    let errorExist = false;
    hotelData.map((item) => {
      if (
        item?.hotel_name === "" ||
        item?.check_in_date === "" ||
        item?.check_out_date === "" ||
        item?.vendor_invoice === "" ||
        item?.invoice_date === "" ||
        item?.currency_id === 0 ||
        item?.amount_in_forex === "" ||
        item?.amount_in_inr === ""
      ) {
        errorExist = true;
      }
    });
    return errorExist;
  };
  const validateFoodFeature = () => {
    let errorExist = false;
    foodData.map((item) => {
      if (
        item?.food_date === "" ||
        // item?.paid_by === "" ||
        item?.vendor_name === "" ||
        item?.currency_id === 0 ||
        item?.amount_in_forex === "" ||
        item?.amount_in_inr === ""
      ) {
        errorExist = true;
      }
    });
    return errorExist;
  };
  const validateConvFeature = () => {
    let errorExist = false;
    conveyanceData.map((item) => {
      if (
        item?.conveyance_date === "" ||
        // item?.paid_by === "" ||
        item?.from_location === "" ||
        item?.to_location === "" ||
        item?.travel_mode_id === 0 ||
        item?.currency_id === 0 ||
        item?.amount_in_forex === 0 ||
        item?.amount_in_inr === 0
      ) {
        errorExist = true;
      }
    });
    return errorExist;
  };
  const validateDaFeature = () => {
    let errorExist = false;
    conveyanceData.map((item) => {
      if (item?.amount_in_forex === 0 || item?.amount_in_inr === 0) {
        errorExist = true;
      }
    });
    return errorExist;
  };
  const addMoreHotelExpense = (ele, i) => {
    // setAlertForAmount(false);
    if (validateSSCFeature()) {
    } else {
      // if (hotelData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(hotelData));
        const ele = {};
        ele.hotel_name = "";
        ele.check_in_date = "";
        ele.check_out_date = "";
        ele.paid_by = "Self";
        ele.vendor_invoice = "";
        ele.invoice_date = "";
        ele.amount_in_forex = 0;
        ele.amount_in_inr = 0;

        copyValue.push(ele);
        setHotelData(copyValue);
      // } else {
      //   toast.error("max");
      // }
    }
  };
  const addMoreFoodExpense = (ele, i) => {
    // setAlertForAmount(false);
    if (validateFoodFeature()) {
    } else {
      // if (foodData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(foodData));
        const ele = {};
        ele.food_date = "";
        ele.paid_by = "Self";
        ele.vendor_name = 0;
        ele.currency_id = 0;
        ele.amount_in_forex = "";
        ele.amount_in_inr = 0;
        copyValue.push(ele);
        setFoodData(copyValue);
      // } else {
      //   toast.error("max");
      // }
    }
  };
  const addMoreConvExpense = (ele, i) => {
    // setAlertForAmount(false);
    if (validateConvFeature()) {
    } else {
      // if (conveyanceData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(conveyanceData));
        const ele = {};
        ele.conveyance_date = "";
        ele.from_location = "";
        ele.to_location = "";
        ele.travel_mode_id = 0;
        ele.paid_by = "Self";
        ele.currency_id = 0;
        ele.amount_in_forex = 0;
        ele.amount_in_inr = 0;

        copyValue.push(ele);
        setConveyanceData(copyValue);
      // } else {
      //   toast.error("max");
      // }
    }
  };
  const getCurrencyList = async (data) => {
    await TravelListingAPI.getCurrencyList(data)
      .then((response) => {
        let currencyVal =
          response?.data?.dataList?.currency_value?.length > 0
            ? response?.data?.dataList?.currency_value[0]
            : [];
        let res = response?.data?.dataList?.result?.map((obj) => {
          let curRate = Object.keys(currencyVal)
            .filter((x) => {
              if (x?.toLowerCase() === obj?.symbol.toLowerCase()) {
                return true;
              }
            })
            .map((key) => {
              return currencyVal[key];
            })[0];
          return {
            value: obj.id,
            label: `${obj?.code}`,
            rate: curRate === undefined ? "" : curRate,
          };
        });
        setCurrencyData(res);
        setCurrencyFData(res);
        setCurrencyDaData(res);
        setCurrencyIncData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const getModeList = async () => {
  //   await TravelListingAPI.getModeList()
  //     .then((response) => {
  //       // let res = response?.data?.dataList?.result?.map((obj) => {
  //       //   return {
  //       //     value: obj.id,
  //       //     label: `${obj?.mode}`,
  //       //   };
  //       // });
  //       let res = response?.data?.dataList?.result?.map((obj) => (
  //         <option value={obj.id}>{obj.mode}</option>
  //       ));
  //       setTravelModeData(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const travelByOption = [
    { value: "Taxi", label: "Taxi" },
    { value: "Air", label: "Air" },
    { value: "Bus", label: "Bus" },
    { value: "Train Pound", label: "Train Pound" },
    // { value: "OLA/Uber sedan/Prime/XL", label: "OLA/Uber sedan/Prime/XL" },
    // { value: "OLA/Uber micro/ Mini/Go", label: "OLA/Uber micro/ Mini/Go" },
    // { value: "Rickshaw(Cycle/Auto)", label: "Rickshaw(Cycle/Auto)" },
    // { value: "Public Transport", label: "Public Transport" },
  ];
  let daTempData = null;
  const getForeignExpData = async (data) => {
    let sum = 0;
    let sumFood = 0;
    let sumConvey = 0;
    let sumDA = 0;
    let TotalValue = 0;
    await TravelListingAPI.getForeignExpData(data)
      .then(async (response) => {
        let daTempData = await getForeignExpenseData(state.id);
        let res = response?.data?.dataList;
        setForeignExpD(res);
        setDaValueData(res?.da_data);
        if (res?.hotel_data?.length !== 0) {
          let data = res?.hotel_data?.map((obj) => {
            sum = obj?.amount_in_inr + sum;
            return obj;
          });
          let sumtotal =
            sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
          setSumTotal(sumtotal);
          setHotelData(data);
        }

        if (res?.food_data?.length !== 0) {
          let data = res?.food_data?.map((obj) => {
            sumFood = obj?.amount_in_inr + sumFood;
            return obj;
          });
          let sumtotal =
            sumFood == undefined || Number.isNaN(sumFood)
              ? Number(0)
              : Number(sumFood);
          setFoodSumTotal(sumtotal);
          setFoodData(data);
        }

        if (res?.da_data?.length !== 0) {
          let daData = res?.da_data?.filter((ele) => {
            return ele;
          });
          let foreignData = foreignExpenseValue?.filter((ele) => {
            return ele;
          });

          const filterBookGenres = daData?.filter((ele) => {
            return foreignData?.some(
              (genre) =>
                moment(ele?.incidental_date).format("DD/MM/YYYY") == genre
            );
          });
          setDaData(filterBookGenres);

          res?.da_data?.map((value, index) => {
            sumDA = value?.amount_in_inr + sumDA;
          });
          let sumtotal =
            sumDA == undefined || Number.isNaN(sumDA)
              ? Number(0)
              : Number(sumDA);
          setDaSumTotal(sumtotal);
        } else {
          setDaData(daTempData);
        }
        if (res?.conveyance_data?.length !== 0) {
          let data = res?.conveyance_data?.map((obj) => {
            sumConvey = obj?.amount_in_inr + sumConvey;
            return obj;
          });
          let sumtotal =
            sumConvey == undefined || Number.isNaN(sumConvey)
              ? Number(0)
              : Number(sumConvey);
          setConveyanceSumTotal(sumtotal);
          setConveyanceData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getContinentEntt = (travel_id) => {
    TravelListingAPI.getContinentEntt(travel_id)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setContAmount(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const setValue = (id, value) => {
    return {
      value: Number(id),
      label: value,
    };
  };

  const pushCurrency = (e, i, name) => {
    changeHandler(e.target, "currency_id", i, name);
  };
  const pushFoodCurrency = (e, i, name) => {
    changeFoodHandler(e.target, "currency_id", i, name);
  };
  const pushTravelMode = (e, i) => {
    changeConvHandler(e.value, "travel_mode_id", i);
  };
  const pushConvCurrency = (e, i, name) => {
    changeConvHandler(e.target, "currency_id", i, name);
  };
  const pushIncCurrency = (e, i) => {
    let valueInt = Number(e.target.value);
    changeDaHandler(valueInt, "currency_id", i);
  };

  const pushDeletedData = (i, flag) => {
    let sendData = JSON.parse(JSON.stringify(hotelData));
    sendData[i].selected = flag;
    setFlagIndex(true);
    setHotelData(sendData);
  };

  const pushDeletedFoodData = (i, flag) => {
    let sendData = JSON.parse(JSON.stringify(foodData));
    sendData[i].selected = flag;
    setFlagFoodIndex(true);
    setFoodData(sendData);
  };
  const pushDeletedConvData = (i, flag) => {
    let sendData = JSON.parse(JSON.stringify(conveyanceData));
    sendData[i].selected = flag;
    setFlagConvIndex(true);
    setConveyanceData(sendData);
  };
  const deleteSelectedData = () => {
    let sum = 0;
    let sendData = JSON.parse(JSON.stringify(hotelData)).filter((obj) => {
      if (obj?.selected != true) {
        return true;
      }
    });
    setFormValidation({
      hotel_name: "",
      check_in_date: "",
      check_out_date: "",
      paid_by: "Self",
      vendor_invoice: "",
      invoice_date: "",
      currency_id: "",
      amount_in_forex: "",
      // amount_in_inr: "",
    });
    if (sendData?.length == 0) {
      setHotelData([
        {
          hotel_name: "",
          check_in_date: "",
          check_out_date: "",
          paid_by: "Self",
          vendor_invoice: "",
          invoice_date: "",
          currency_id: null,
          amount_in_forex: 0,
          amount_in_inr: 0,
        },
      ]);
    } else {
      sendData?.map((value) => {
        sum = value?.amount_in_inr + sum;
      });
      let sumtotal =
        sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
      setSumTotal(sumtotal);
      setHotelData(sendData);
    }
  };
  const deleteSelectedFoodData = () => {
    let sum = 0;
    let sendData = JSON.parse(JSON.stringify(foodData)).filter((obj) => {
      if (obj?.selected != true) {
        return true;
      }
    });
    if (sendData?.length == 0) {
      setFoodData([
        {
          food_date: "",
          paid_by: "Self",
          vendor_name: "",
          currency_id: null,
          amount_in_forex: 0,
          amount_in_inr: 0,
        },
      ]);
    } else {
      sendData?.map((value) => {
        sum = value?.amount_in_inr + sum;
      });
      let sumtotal =
        sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
      setFoodSumTotal(sumtotal);
      setFoodData(sendData);
    }
  };
  const deleteSelectedConvData = () => {
    let sum = 0;
    let sendData = JSON.parse(JSON.stringify(conveyanceData)).filter((obj) => {
      if (obj?.selected != true) {
        return true;
      }
    });
    if (sendData?.length == 0) {
      setConveyanceSumTotal(0);
      setConveyanceData([
        {
          conveyance_date: "",
          from_location: "",
          to_location: "",
          travel_mode_id: "",
          paid_by: "Self",
          currency_id: null,
          amount_in_forex: 0,
          amount_in_inr: 0,
        },
      ]);
    } else {
      sendData?.map((value) => {
        sum = value?.amount_in_inr + sum;
      });
      let sumtotal =
        sum == undefined || Number.isNaN(sum) ? Number(0) : Number(sum);
      setConveyanceSumTotal(sumtotal);
      setConveyanceData(sendData);
    }
  };

  const selectAllCheckbox = (e, type) => {
    if (type == "hotel") {
      let sendData = JSON.parse(JSON.stringify(hotelData)).map((obj) => {
        if (e.target.checked === true) {
          obj.selected = true;
          return obj;
        } else {
          obj.selected = false;
          return obj;
        }
      });
      setHotelData(sendData);
    }
    if (type == "food") {
      let sendData = JSON.parse(JSON.stringify(foodData)).map((obj) => {
        if (e.target.checked === true) {
          obj.selected = true;
          return obj;
        } else {
          obj.selected = false;
          return obj;
        }
      });
      setFoodData(sendData);
    }
    if (type == "conveyance") {
      let sendData = JSON.parse(JSON.stringify(conveyanceData)).map((obj) => {
        if (e.target.checked === true) {
          obj.selected = true;
          return obj;
        } else {
          obj.selected = false;
          return obj;
        }
      });
      setConveyanceData(sendData);
    }
  };
  const handleValidate = () => {
    let isError = false;
    let vendorInvoice =
      "^612s?[1-5]0s?0(?:[0-5]d|6[0-4])s?0000s?(?:[0-5]d|6[0-4])s?00s?d{4}$";
    const formerr = { ...formValidation };

    hotelData?.map((hotelData, i) => {
      if (!hotelData.hotel_name) {
        isError = true;
        formerr.hotel_name = "*Hotel Name is required ";
        setFormValidation(formerr);
      }
      if (!hotelData.check_in_date) {
        isError = true;
        formerr.check_in_date = "*Check in Date is required ";
        setFormValidation(formerr);
      }
      if (!hotelData.check_out_date) {
        isError = true;
        formerr.check_out_date = "*Check Out Date is required ";
        setFormValidation(formerr);
      }
      // if (!hotelData.paid_by) {
      //   isError = true;
      //   formerr.paid_by = "*Paid By is required ";
      //   setFormValidation(formerr);
      // }

      if (!hotelData.vendor_invoice) {
        isError = true;
        formerr.vendor_invoice = "*Vendor Invoice is required ";
        setFormValidation(formerr);
      }
      if (!hotelData.invoice_date) {
        isError = true;
        formerr.invoice_date = "*Invoice Date is required ";
        setFormValidation(formerr);
      }
      if (!hotelData.currency_id) {
        isError = true;
        formerr.currency_id = "*Current Id is required ";
        setFormValidation(formerr);
      }
      if (!hotelData.amount_in_forex) {
        isError = true;
        formerr.amount_in_forex = "*Forex Amount is required ";
        setFormValidation(formerr);
      }
      // if (!hotelData.amount_in_inr) {
      //   isError = true;
      //   formerr.amount_in_inr = "*Inr Amount is required ";
      //   setFormValidation(formerr);
      // }
    });
    return isError;
  };

  const handleValidateFood = () => {
    let isError = false;
    const formerr = { ...formFoodValidation };

    foodData?.map((foodData, i) => {
      if (!foodData.food_date) {
        isError = true;
        formerr.food_date = "*Food Date is required ";
        setFormFoodValidation(formerr);
      }

      if (!foodData.vendor_name) {
        isError = true;
        formerr.vendor_name = "*Vendor Name is required ";
        setFormFoodValidation(formerr);
      }
      if (!foodData.currency_id) {
        isError = true;
        formerr.currency_id = "*Currency is required ";
        setFormFoodValidation(formerr);
      }
      // if (!foodData.amount_in_inr) {
      //   isError = true;
      //   formerr.amount_in_inr = "*Amount in INR is required ";
      //   setFormFoodValidation(formerr);
      // }
      if (!foodData.amount_in_forex) {
        isError = true;
        formerr.amount_in_forex = "*Amount in Forex is required ";
        setFormFoodValidation(formerr);
      }
    });

    return isError;
  };

  const handleValidateConv = () => {
    let isError = false;
    const formerr = { ...formConvValidation };

    conveyanceData?.map((conveyanceData, i) => {
      if (!conveyanceData.conveyance_date) {
        isError = true;
        formerr.conveyance_date = "*Conveyance Date is required ";
        setFormConvValidation(formerr);
      }

      if (!conveyanceData.from_location) {
        isError = true;
        formerr.from_location = "*From Location is required ";
        setFormConvValidation(formerr);
      }
      if (!conveyanceData.to_location) {
        isError = true;
        formerr.to_location = "*To Location is required ";
        setFormConvValidation(formerr);
      }
      if (!conveyanceData.travel_mode_id) {
        isError = true;
        formerr.travel_mode_id = "*Travel Mode is required ";
        setFormConvValidation(formerr);
      }
      if (!conveyanceData.currency_id) {
        isError = true;
        formerr.currency_id = "*Currency Id is required";
        setFormConvValidation(formerr);
      }
      // if (!conveyanceData.amount_in_inr) {
      //   isError = true;
      //   formerr.amount_in_inr = "*Amount in INR is required ";
      //   setFormConvValidation(formerr);
      // }
      if (!conveyanceData.amount_in_forex) {
        isError = true;
        formerr.amount_in_forex = "*Amount in Forex is required ";
        setFormConvValidation(formerr);
      }
    });
    return isError;
  };
  const handleValidateDA = () => {
    let isError = false;
    const formerr = { ...formDaValidation };
    daData?.map((daData, i) => {
      // if (!daData.amount_in_inr) {
      //   isError = true;
      //   formerr.amount_in_inr = "*Amount in INR is required ";
      //   setFormDaValidation(formerr);
      // }
      if (!daData.amount_in_forex) {
        isError = true;
        formerr.amount_in_forex = "*Amount in Forex is required ";
        setFormDaValidation(formerr);
      }
      if (errorAmount) {
        isError = true;
      }
    });
    return isError;
  };

  const conveyanceValues = conveyanceData?.map((data, index) => ({
    amount_in_forex: data?.amount_in_forex,
    amount_in_inr: data?.amount_in_inr,
    conveyance_date: data?.conveyance_date!==null ? data?.conveyance_date : "",
    currency_code: data?.currency_code,
    currency_id: data?.currency_id,
    from_location: data?.from_location,
    id: data?.id,
    modee_name: data?.modee_name == undefined ? undefined : data?.modee_name,
    paid_by: data?.paid_by,
    to_location: data?.to_location,
    travel_id: data?.travel_id,
    travel_mode_id:
      typeof data?.travel_mode_id == "number"
        ? data?.modee_name
        : data?.travel_mode_id,
    is_exception: false,
  }));

  const saveForeignExpData = (data, exp) => {
    let tempConveyanceData = [...conveyanceValues];
    let tempHotelData = [...hotelData];
    let tempFoodData = [...foodData];
    tempConveyanceData.forEach((obj, idx) => {
      tempConveyanceData[idx].conveyance_date =
        obj.conveyance_date !== ""
          ? moment(obj.conveyance_date).format("YYYY-MM-D")
          : null;
      tempConveyanceData[idx].is_exception = obj.is_exception !== "" ? exp : "";
    });
    tempHotelData.forEach((obj, idx) => {
      tempHotelData[idx].check_in_date =
        obj.check_in_date !== ""
          ? moment(obj.check_in_date).format("YYYY-MM-D")
          : "";
      tempHotelData[idx].check_out_date =
        obj.check_out_date !== ""
          ? moment(obj.check_out_date).format("YYYY-MM-D")
          : "";
      tempHotelData[idx].invoice_date =
        obj.invoice_date !== ""
          ? moment(obj.invoice_date).format("YYYY-MM-D")
          : "";
    });
    tempFoodData.forEach(
      (obj, idx) =>
        (tempFoodData[idx].food_date =
          obj.food_date !== "" && obj.food_date !== null
            ? moment(obj.food_date).format("YYYY-MM-D")
            : null)
    );
    let dataSave = {
      // travel_id: state?.id,
      attachment_path: {},
      attachment_delete: [],
      conveyance_data: tempConveyanceData,
      hotel_data: tempHotelData,
      food_data: tempFoodData,
      // is_exception: exp,
      da_data: daData,
    };
    TravelListingAPI.saveForeignExpData(dataSave, data)
      .then((response) => {
        if (response?.data.code == 200) {
          // navigate("/travel-listing");
          setCheckActiveTab();
          toast.success("Foreign Expense Added Successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleValidateForm = () => {
    let total = Number(contAmount * fExp);
    let St = sumTotal == undefined ? 0 : sumTotal;
    let ft = foodSumTotal == undefined ? 0 : foodSumTotal;
    let ct = conveyanceSumTotal == undefined ? 0 : conveyanceSumTotal;
    let dt = daSumTotal == undefined ? 0 : daSumTotal;
    let totalSum = St + ft + ct + dt;
    let exp = "false";
    if (
      handleValidate() &&
      handleValidateFood() &&
      handleValidateConv() &&
      handleValidateDA()
    )
      return false;
    if (totalSum > total) {
      exp = "true";
    }
    saveForeignExpData(state?.id, exp);
    // if (isEdit == "false") {

    //   if (handleValidate()) return false;

    //   saveForeignExpData(foreignExpData);
    // }
    //else {
    //   if (handleValidate()) return false;
    //   hotelExpense();
    // }
  };
  useEffect(() => {
    getCurrencyList(userDetails?.organisation_id);
  }, []);

  useEffect(() => {
    if (
      gridDataNew?.length > 0 &&
      typeof gridDataNew[0]?.currency_data == "string"
    ) {
      let data = gridDataNew[0]?.currency_data
        ? JSON.parse(gridDataNew[0]?.currency_data?.replace(/'/g, '"'))
        : "";
      if (data?.length > 0) {
        let usdData = {};
        let tempCurrencyList = [];
        data?.map((obj) => {
          let temp = obj;
          if (temp?.name === "USD") usdData = obj.amount == "" ? 0 : obj.amount;
          if (temp !== "") tempCurrencyList.push(obj);
        });
        setFExp(usdData);
        setCurrencyList(tempCurrencyList);
      }
    }
  }, [gridDataNew]);

  useEffect(() => {
    getContinentEntt(state?.id);
    getForeignExpData(state?.id);
  }, [state, checkActiveTab]);

  useEffect(() => {
    let sumDA = 0;
    if (
      daValueData?.length !== 0 &&
      daValueData &&
      daValueData[0] &&
      daValueData[0]?.currency_id !== null
    ) {
      let daData = daValueData?.filter((ele) => {
        return ele;
      });
      let foreignData = foreignExpenseValue?.filter((ele) => {
        return ele;
      });

      const filterBookGenres = daData?.filter((ele) => {
        return foreignData?.some(
          (genre) => moment(ele?.incidental_date).format("DD/MM/YYYY") == genre
        );
      });
      setDaData(filterBookGenres);

      daValueData?.map((value, index) => {
        sumDA = value?.amount_in_inr + sumDA;
      });
      let sumtotal =
        sumDA == undefined || Number.isNaN(sumDA) ? Number(0) : Number(sumDA);
      setDaSumTotal(sumtotal);
      // let data = res?.da_data
      //   ?.filter(
      //     (ele) =>
      //       console.log(
      //         moment(ele?.incidental_date).format("YYYY-MM-DD"),
      //         moment(foreignExpenseValue).format("YYYY-MM-DD"),
      //         "formmmmmmddddd11"
      //       )
      //     // moment(ele?.incidental_date).format("YYYY-MM-DD") ==
      //     // moment(foreignExpenseValue).format("YYYY-MM-DD")
      //   )
      //   ?.map((obj) => {
      //     return obj;
      //   });
    }
  }, [foreignExpenseValue]);

  const renderStateOptions = (optionsList) => {
    return optionsList.map((item, index) => (
      <option
        key={index}
        amount={`${item?.amount}`}
        label={`${item?.name}`}
        value={`${item?.id}`}
      />
    ));
  };
  const renderCountryDaOptions = (optionsList) => {
    let idx = optionsList.findIndex((obj) => obj?.name === "USD");
    if (idx === -1) return <></>;
    return (
      <option
        amount={`${optionsList[idx].amount}`}
        label="USD"
        value={`${optionsList[idx].id}`}
      />
    );
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();

  // useEffect(() => {
  //   // try {
  //   let diffInMs;
  //   let diffInDays;
  //   if (state?.travel_detail_data?.length > 0) {
  //     diffInMs =
  //       new Date(
  //         state?.travel_detail_data[
  //           state?.travel_detail_data?.length - 1
  //         ]?.to_date
  //       ) - new Date(state?.travel_detail_data[0]?.from_date);
  //     diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  //   } else {
  //     diffInDays = 1;
  //   }
  //   // } catch (err) {
  //   //   console.log("hi");
  //   // }

  //   let counter = 0;

  //   let fd = new Array(diffInDays + 1)?.fill({
  //     incidental_date: "",
  //     ent_currency_id: null,
  //     ent_amount_in_forex: 20,
  //     ent_amount_in_inr: dda,
  //     currency_id: null,
  //     amount_in_forex: 0,
  //     amount_in_inr: 0,
  //   });
  //   let test = fd?.map((obj, i) => {
  //     let curValue = JSON.parse(JSON.stringify(obj));
  //     curValue.incidental_date = moment(state?.travel_detail_data[0]?.from_date)
  //       .add(counter, "day")
  //       .format("YYYY-MM-DD");
  //     counter += 1;
  //     return curValue;
  //   });
  //   console.log('testData = ', test)
  //   setDaData(test);
  // }, [state]);

  useEffect(() => {
    let TotalValue = 0;
    TotalValue = sumTotal + foodSumTotal + conveyanceSumTotal + daSumTotal;
    setTotalValueAmount(TotalValue);
  }, [sumTotal, foodSumTotal, conveyanceSumTotal, daSumTotal]);

  return (
    <>
      <div class="px-4 pb-2">
        <div>
          <p>
            <strong>Total : </strong> <i className="fas fa-rupee-sign f-14"></i>
            {" " + (totalValueAmount ? totalValueAmount : 0)?.toFixed(2)}
          </p>
        </div>
        <div class="tickethistorbg tickethistorbg1 mt-2">
          <div class="row viewformaftersubmit">
            <div class="col-md-5">
              <div class="innergroup">
                <p>Foreign Expenses</p>
                <label>
                  Boarding & Lodging Entitlement (USD : {contAmount ?? "0"} ,
                  INR : <i className="fas fa-rupee-sign f-12"></i>
                  {Number(contAmount * fExp).toFixed(2)})
                </label>
              </div>
            </div>
            <div className="col-md-7 d-flex flex-wrap justify-content-between">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleExchnagePopup(currencyList)}
              >
                <div class="innergroup">
                  <p>Exchange Rate: USD</p>
                  <label>{fExp}</label>
                </div>
              </div>
              {currencyList
                .filter((ele) => ele?.name !== "USD" && ele?.name !== "INR")
                .map((obj) => (
                  // Object.entries(obj).map(([key, value]) =>
                  <div
                    // class="col-md-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleExchnagePopup(currencyList)}
                  >
                    <div class="innergroup">
                      <p>Exchange Rate: {obj?.name}</p>
                      <label>{obj?.amount}</label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <h5 class="text-theme pb-2 mt-4 mb-2">
          <strong>Hotel Details</strong>
        </h5>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured innergroup leavetableapply vehiclelog">
          <thead>
            <tr>
              <th>
                <label class="logCheck">
                  <input
                    type="checkbox"
                    name="statusupdate"
                    onChange={(e) => selectAllCheckbox(e, "hotel")}
                  />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th>Hotel Name</th>
              <th>Stay From</th>
              <th>Stay Till</th>
              <th>Paid By</th>
              <th>Vendor Invoice</th>
              <th>Invoice Date</th>
              <th>Currency</th>
              <th>Amount (Forex)</th>
              <th>Amount (INR)</th>
            </tr>
          </thead>
          <tbody>
            {hotelData?.map((fexp, i) => (
              <>
                <tr>
                  <th>
                    <label class="logCheck">
                      <input
                        type="checkbox"
                        name="statusupdate"
                        onClick={(e) => {
                          pushDeletedData(i, e.target.checked);
                        }}
                        checked={fexp?.selected}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="hotel_name"
                      maxLength={50}
                      onChange={(e) => {
                        changeHandler(e.target.value, "hotel_name", i);
                        setFormValidation({
                          ...formValidation,
                          hotel_name: "",
                        });
                      }}
                      value={hotelData[i]?.hotel_name}
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.hotel_name}
                    </div>
                  </td>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="check_in_date"
                      value={hotelData[i]?.check_in_date}
                      placeholder="DD-MM-YYYY"
                      min={moment(
                        state?.travel_detail_data[0]?.from_date
                      ).format("YYYY-MM-DD")}
                      max={moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_in_date", i);
                        setFormValidation({
                          ...formValidation,
                          check_in_date: "",
                        });
                      }}
                    /> */}
                    <DatePicker
                      selected={
                        hotelData[i]?.check_in_date !== "" &&
                        hotelData[i]?.check_in_date !== null
                          ? new Date(hotelData[i]?.check_in_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "check_in_date", i);
                        setFormValidation({
                          ...formValidation,
                          check_in_date: "",
                        });
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.check_in_date}
                    </div>
                  </td>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="check_out_date"
                      value={hotelData[i]?.check_out_date}
                      min={moment(
                        state?.travel_detail_data[0]?.from_date
                      ).format("YYYY-MM-DD")}
                      max={moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_out_date", i);
                        setFormValidation({
                          ...formValidation,
                          check_out_date: "",
                        });
                      }}
                    /> */}
                    <DatePicker
                      selected={
                        hotelData[i]?.check_out_date !== "" &&
                        hotelData[i]?.check_out_date !== null
                          ? new Date(hotelData[i]?.check_out_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "check_out_date", i);
                        setFormValidation({
                          ...formValidation,
                          check_out_date: "",
                        });
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.check_out_date}
                    </div>
                  </td>
                  <td>
                    <select
                      class="form-control newbgselect"
                      name="paid_by"
                      onChange={(e) =>
                        changeHandler(e.target.value, "paid_by", i)
                      }
                      value={hotelData[i]?.paid_by}
                    >
                      <option value="">Select</option>
                      <option value="Self" selected>
                        Self
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="vendor_invoice"
                      maxLength={16}
                      value={hotelData[i]?.vendor_invoice}
                      onChange={(e) => {
                        changeHandler(e.target.value, "vendor_invoice", i);
                        setFormValidation({
                          ...formValidation,
                          vendor_invoice: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.vendor_invoice}
                    </div>
                  </td>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="invoice_date"
                      min={moment(
                        state?.travel_detail_data[1]?.from_date
                      ).format("YYYY-MM-DD")}
                      value={hotelData[i]?.invoice_date}
                      onChange={(e) => {
                        changeHandler(e.target.value, "invoice_date", i);
                        setFormValidation({
                          ...formValidation,
                          invoice_date: "",
                        });
                      }}
                    /> */}
                    <DatePicker
                      selected={
                        hotelData[i]?.invoice_date !== ""
                          ? new Date(hotelData[i]?.invoice_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "invoice_date", i);
                        setFormValidation({
                          ...formValidation,
                          invoice_date: "",
                        });
                      }}
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.invoice_date}
                    </div>
                  </td>
                  <td style={{ overflow: "visible" }}>
                    <div style={{ width: "150px" }}>
                      <select
                        name="currency_id"
                        class="form-control newbgselect"
                        id={"currency_id" + i}
                        onChange={(e) => {
                          pushCurrency(
                            e,
                            i,
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("label")
                          );
                          setCurrencyFlag(true);
                          setCurrValue(
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("amount")
                          );
                          setFormValidation({
                            ...formValidation,
                            currency_id: "",
                          });
                        }}
                        value={
                          hotelData && currencyFlag == false
                            ? hotelData[i]?.currency_id
                            : hotelData[i]?.currValue
                        }
                      >
                        <option value="">Select</option>
                        {renderStateOptions(currencyList)}
                      </select>
                      <div className="small text-danger">
                        {i == Number(hotelData.length - 1) &&
                          formValidation.currency_id}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_forex"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      maxLength={15}
                      onChange={(e) => {
                        changeHandler(e.target.value, "amount_in_forex", i);
                        setFormValidation({
                          ...formValidation,
                          amount_in_forex: "",
                        });
                      }}
                      value={hotelData[i]?.amount_in_forex}
                    />
                    <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.amount_in_forex}
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_inr"
                      onWheel={(e) => e.target.blur()}
                      disabled
                      maxLength={15}
                      onChange={(e) => {
                        changeHandler(e.target.value, "amount_in_inr", i);
                        // setFormValidation({
                        //   ...formValidation,
                        //   amount_in_inr: "",
                        // });
                      }}
                      value={parseFloat(hotelData[i]?.amount_in_inr).toFixed(2)}
                    />
                    {/* <div className="small text-danger">
                      {i == Number(hotelData.length - 1) &&
                        formValidation.amount_in_inr}
                    </div> */}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <div class="border-top my-3">
          Total : <i className="fas fa-rupee-sign f-12"></i>
          {Number.isNaN(sumTotal) || sumTotal == undefined
            ? "0"
            : parseFloat(sumTotal).toFixed(2)}
        </div>
      </div>
      <div class="mb-3 float-right">
        <div class="row">
          <div class="col-md-12 my-3 text-center ">
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-danger mr-2"
              disabled={!flagIndex}
              onClick={() => {
                deleteSelectedData();
                setFlagIndex(false);
              }}
            >
              Delete
            </button>
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-primary mr-2"
              onClick={() => {
                addMoreHotelExpense(hotelData);
                handleValidate();
              }}
            >
              Add More
            </button>
          </div>
        </div>
      </div>

      <div class="px-4 pb-2">
        <h5 class="text-theme pb-2 mt-4 mb-2">
          <strong>Food Details</strong>
        </h5>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured innergroup leavetableapply vehiclelog">
          <thead>
            <tr>
              <th>
                <label class="logCheck">
                  <input
                    type="checkbox"
                    name="statusupdate"
                    onChange={(e) => selectAllCheckbox(e, "food")}
                  />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th>Date</th>
              <th>Vendor Name</th>
              <th>Paid By</th>
              <th>Currency</th>
              <th>Amount (Forex)</th>
              <th>Amount (INR)</th>
            </tr>
          </thead>
          <tbody>
            {foodData?.map((fData, i) => (
              <>
                <tr>
                  <td>
                    <label class="logCheck">
                      <input
                        type="checkbox"
                        name="statusupdate"
                        onClick={(e) => {
                          pushDeletedFoodData(i, e.target.checked);
                        }}
                        checked={fData?.selected}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="food_date"
                      min={moment(
                        state?.travel_detail_data[0]?.from_date
                      ).format("YYYY-MM-DD")}
                      max={moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        changeFoodHandler(e.target.value, "food_date", i);
                        setFormFoodValidation({
                          ...formFoodValidation,
                          food_date: "",
                        });
                      }}
                      value={foodData[i]?.food_date}
                    /> */}
                    <DatePicker
                      selected={
                        foodData[i]?.food_date !== "" &&
                        foodData[i]?.food_date !== null
                          ? new Date(foodData[i]?.food_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeFoodHandler(date, "food_date", i);
                        setFormFoodValidation({
                          ...formFoodValidation,
                          food_date: "",
                        });
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(foodData.length - 1) &&
                        formFoodValidation.food_date}
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="vendor_name"
                      onChange={(e) => {
                        changeFoodHandler(e.target.value, "vendor_name", i);
                        setFormFoodValidation({
                          ...formFoodValidation,
                          vendor_name: "",
                        });
                      }}
                      value={foodData[i]?.vendor_name}
                    />
                    <div className="small text-danger">
                      {i == Number(foodData.length - 1) &&
                        formFoodValidation.vendor_name}
                    </div>
                  </td>
                  <td>
                    <select
                      class="form-control newbgselect"
                      name="paid_by"
                      onChange={(e) =>
                        changeFoodHandler(e.target.value, "paid_by", i)
                      }
                      value={hotelData[i]?.paid_by}
                    >
                      <option value="">Select</option>
                      <option value="Self" selected>
                        Self
                      </option>
                    </select>
                  </td>
                  <td style={{ overflow: "visible" }}>
                    <div style={{ width: "150px" }}>
                      <select
                        name="currency_id"
                        class="form-control newbgselect"
                        id={"currency_id" + i}
                        onChange={(e) => {
                          pushFoodCurrency(
                            e,
                            i,
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("label")
                          );
                          setCurrencyFoodFlag(true);
                          setCurrFValue(
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("amount")
                          );
                          setFormFoodValidation({
                            ...formFoodValidation,
                            currency_id: "",
                          });
                        }}
                        value={
                          currencyFoodFlag == false
                            ? foodData[i]?.currency_id
                            : foodData[i]?.currFValue
                        }
                      >
                        <option value="">Select</option>
                        {renderStateOptions(currencyList)}
                      </select>
                      <div className="small text-danger">
                        {i == Number(foodData.length - 1) &&
                          formFoodValidation.currency_id}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_forex"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      value={foodData[i]?.amount_in_forex}
                      onChange={(e) => {
                        changeFoodHandler(e.target.value, "amount_in_forex", i);
                        setFormFoodValidation({
                          ...formFoodValidation,
                          amount_in_forex: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {i == Number(foodData.length - 1) &&
                        formFoodValidation.amount_in_forex}
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_inr"
                      onWheel={(e) => e.target.blur()}
                      disabled
                      onKeyDown={blockInvalidChar}
                      value={parseFloat(foodData[i]?.amount_in_inr).toFixed(2)}
                      onChange={(e) => {
                        changeFoodHandler(e.target.value, "amount_in_inr", i);
                        setFormFoodValidation({
                          ...formFoodValidation,
                          amount_in_inr: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {i == Number(foodData.length - 1) &&
                        formFoodValidation.amount_in_inr}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <div class="border-top my-3">
          Total : <i className="fas fa-rupee-sign f-12"></i>
          {Number.isNaN(foodSumTotal) || foodSumTotal == undefined
            ? 0
            : parseFloat(foodSumTotal).toFixed(2)}
        </div>
      </div>
      <div class="mb-3 float-right">
        <div class="row">
          <div class="col-md-12 my-3 text-center">
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-danger mr-2"
              disabled={!flagFoodIndex}
              onClick={() => {
                deleteSelectedFoodData();
                setFlagFoodIndex(true);
              }}
            >
              Delete
            </button>
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-primary mr-2"
              onClick={() => {
                addMoreFoodExpense(foodData);
                handleValidateFood();
              }}
            >
              Add More
            </button>
          </div>
        </div>
      </div>

      <div class="px-4 pb-2">
        <h5 class="text-theme pb-2 mt-4 mb-2">
          <strong>Incidental DA Details</strong>
        </h5>
      </div>
      {errorAmount == true && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> The Amount (forex)
          should not be greater than the ENT Amount (Forex)
        </Alert>
      )}
      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured innergroup leavetableapply vehiclelog">
          <thead>
            <tr>
              <th>Date</th>
              <th>ENT Currency</th>
              <th>ENT Amount (Forex)</th>
              <th>ENT Amount (INR)</th>
              <th>Currency</th>
              <th>Amount (Forex)</th>
              <th>Amount (INR)</th>
            </tr>
          </thead>
          <tbody>
            {daData?.map((dData, i) => (
              <>
                <tr>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="incidental_date"
                      // onChange={(e) => {
                      //   changeDaHandler(e.target.value, "incidental_date", i);
                      //   setFormDaValidation({
                      //     ...formDaValidation,
                      //     incidental_date: "",
                      //   });
                      // }}
                      disabled
                      value={dData?.incidental_date}
                    /> */}
                    <DatePicker
                      selected={
                        dData?.incidental_date !== "" &&
                        dData?.incidental_date !== null
                          ? new Date(dData?.incidental_date)
                          : ""
                      }
                      // onChange={(date) => {
                      //   changeHandler(date, "incidental_date", i);
                      //   setFormDaValidation({
                      //     ...formDaValidation,
                      //     incidental_date: "",
                      //   });
                      // }}
                      disabled
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                  </td>
                  <td style={{ overflow: "visible" }}>
                    {/* <div style={{ width: "150px" }}>
                      <Select
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        options={currencyIncData.filter((ff) => {
                          if (ff?.label === "USD") {
                            return true;
                          }
                        })}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="ent_currency_id"
                        id={"ent_currency_id" + i}
                        // defaultValue={{ label: "Select Dept", value: 0 }}
                        onChange={(e) => {
                          pushEntCurrency(e, i);
                          setCurrencyEntFlag(true);
                          setCurrEntValue(e);
                        }}
                        value={
                          currencyEntFlag == false
                            ? setValue(5, "USD")
                            : daData[i]?.currEntValue
                        }
                      />
                    </div> */}
                    <select
                      class="form-control newbgselect"
                      name="ent_currency_id"
                      value={
                        currencyEntFlag == false
                          ? setValue(5, "USD")
                          : daData[i]?.currEntValue
                      }
                      onChange={(e) => {
                        changeDaHandler(e.target.value, "ent_currency_id", i);
                        setCurrencyEntFlag(true);
                        setCurrEntValue(e);
                      }}
                    >
                      {/* <option value="0">Select</option> */}
                      {renderCountryDaOptions(currencyList)}
                    </select>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="ent_amount_in_forex"
                      value={daData[i]?.ent_amount_in_forex}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="ent_amount_in_inr"
                      disabled
                      value={(daData[i].ent_amount_in_forex * fExp).toFixed(2)}
                    />
                  </td>
                  <td style={{ overflow: "visible" }}>
                    <div style={{ width: "150px" }}>
                      <select
                        name="currency_id"
                        class="form-control newbgselect"
                        id={"currency_id" + i}
                        onChange={(e) => {
                          pushIncCurrency(e, i);
                          setCurrencyIncFlag(true);
                          setCurrIncValue(e);
                          setCurrDaValue(
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("amount")
                          );
                          // setFormFoodValidation({
                          //   ...formFoodValidation,
                          //   currency_id: "",
                          // });
                        }}
                        value={
                          currencyIncFlag == false
                            ? daData[i]?.currency_id
                            : daData[i]?.currIncValue
                        }
                      >
                        <option value="">Select</option>
                        {/* {renderStateOptions(currencyList)} */}

                        {renderCountryDaOptions(currencyList)}

                        {/* {renderStateOptions(currencyFData)} */}
                      </select>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_forex"
                      value={daData[i]?.amount_in_forex}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        changeDaHandler(e.target.value, "amount_in_forex", i);
                        setFormDaValidation({
                          ...formDaValidation,
                          amount_in_forex: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {i == Number(daData.length - 1) &&
                        formDaValidation.amount_in_forex}
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_inr"
                      disabled
                      value={parseFloat(daData[i]?.amount_in_inr).toFixed(2)}
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        changeDaHandler(e.target.value, "amount_in_inr", i);
                        setFormDaValidation({
                          ...formDaValidation,
                          amount_in_inr: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {i == Number(daData.length - 1) &&
                        formDaValidation.amount_in_inr}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <div class="border-top my-3">
          Total : <i className="fas fa-rupee-sign f-12"></i>
          {Number.isNaN(daSumTotal) || daSumTotal == undefined
            ? 0
            : parseFloat(daSumTotal).toFixed(2)}{" "}
        </div>
      </div>

      <div class="px-4 pb-2">
        <h5 class="text-theme pb-2 mt-4 mb-2">
          <strong>Conveyance Details</strong>
        </h5>
      </div>

      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured innergroup leavetableapply vehiclelog">
          <thead>
            <tr>
              <th>
                <label class="logCheck">
                  <input
                    type="checkbox"
                    name="statusupdate"
                    onChange={(e) => selectAllCheckbox(e, "conveyance")}
                  />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th>Date</th>
              <th>Travel Mode</th>
              <th>From</th>
              <th>To</th>
              <th>Paid By</th>
              <th>Currency</th>
              <th>Amount (Forex)</th>
              <th>Amount (INR)</th>
            </tr>
          </thead>
          <tbody>
            {conveyanceData?.map((convData, i) => (
              <>
                <tr>
                  <td>
                    <label class="logCheck">
                      <input
                        type="checkbox"
                        name="statusupdate"
                        onClick={(e) => {
                          pushDeletedConvData(i, e.target.checked);
                        }}
                        checked={convData?.selected}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="conveyance_date"
                      min={moment(
                        state?.travel_detail_data[0]?.from_date
                      ).format("YYYY-MM-DD")}
                      max={moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        changeConvHandler(e.target.value, "conveyance_date", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          conveyance_date: "",
                        });
                      }}
                      value={conveyanceData[i]?.conveyance_date}
                    /> */}
                    <DatePicker
                      selected={
                        conveyanceData[i]?.conveyance_date !== "" &&
                        conveyanceData[i]?.conveyance_date !== null
                          ? new Date(conveyanceData[i]?.conveyance_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeConvHandler(date, "conveyance_date", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          conveyance_date: "",
                        });
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(conveyanceData.length - 1) &&
                        formConvValidation.conveyance_date}
                    </div>
                  </td>
                  <td style={{ overflow: "visible" }}>
                    <div style={{ width: "150px" }}>
                      {/* <Select
                        options={travelModeData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="travel_mode_id"
                        id={"travel_mode_id" + i}
                        onChange={(e) => {
                          pushTravelMode(e, i);
                          setTravelModeFlag(true);
                          setTravelMValue(e);
                          setFormConvValidation({
                            ...formConvValidation,
                            travel_mode_id: "",
                          });
                        }}
                        value={
                          travelModeFlag == false
                            ? setValue(
                              convData.travel_mode_id,
                              convData.mode_name
                            )
                            : conveyanceData[i]?.travelMValue
                        }
                      /> */}
                      <select
                        class="form-control newbgselect"
                        name="travel_mode_id"
                        value={
                          travelModeFlag == false
                            ? conveyanceData[i].modee_name
                            : conveyanceData[i]?.travelMValue
                        }
                        onChange={(e) => {
                          changeConvHandler(
                            e.target.value,
                            "travel_mode_id",
                            i
                          );
                          setTravelModeFlag(true);
                          setTravelMValue(e);
                          setFormConvValidation({
                            ...formConvValidation,
                            travel_mode_id: "",
                          });
                        }}
                      >
                        <option value="">Select</option>
                        {travelByOption?.map((obj) => (
                          <option value={obj.value}>{obj.label}</option>
                        ))}
                      </select>

                      <div className="small text-danger">
                        {i == Number(conveyanceData.length - 1) &&
                          formConvValidation.travel_mode_id}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="from_location"
                      onChange={(e) => {
                        changeConvHandler(e.target.value, "from_location", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          from_location: "",
                        });
                      }}
                      value={conveyanceData[i]?.from_location}
                    />

                    <div className="small text-danger">
                      {i == Number(conveyanceData.length - 1) &&
                        formConvValidation.from_location}
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      name="to_location"
                      value={conveyanceData[i]?.to_location}
                      onChange={(e) => {
                        changeConvHandler(e.target.value, "to_location", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          to_location: "",
                        });
                      }}
                    />

                    <div className="small text-danger">
                      {i == Number(conveyanceData.length - 1) &&
                        formConvValidation.to_location}
                    </div>
                  </td>
                  <td>
                    <select
                      class="form-control newbgselect"
                      name="paid_by"
                      onChange={(e) =>
                        changeConvHandler(e.target.value, "paid_by", i)
                      }
                      value={conveyanceData[i]?.paid_by}
                    >
                      <option value="">Select</option>
                      <option value="Self" selected>
                        Self
                      </option>
                    </select>
                  </td>
                  <td style={{ overflow: "visible" }}>
                    <div style={{ width: "150px" }}>
                      <select
                        name="currency_id"
                        id={"currency_id" + i}
                        class="form-control newbgselect"
                        onChange={(e) => {
                          pushConvCurrency(
                            e,
                            i,
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("label")
                          );
                          setCurrencyConFlag(true);
                          setCurrConvValue(
                            e.target.options[
                              e.target.selectedIndex
                            ]?.getAttribute("amount")
                          );
                          setFormConvValidation({
                            ...formConvValidation,
                            currency_id: "",
                          });
                        }}
                        value={
                          currencyConFlag == false
                            ? conveyanceData[i]?.currency_id
                            : conveyanceData[i]?.currConvValue
                        }
                      >
                        <option value={null}>Select</option>
                        {renderStateOptions(currencyList)}
                      </select>
                      <div className="small text-danger">
                        {i == Number(conveyanceData.length - 1) &&
                          formConvValidation.currency_id}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_forex"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        changeConvHandler(e.target.value, "amount_in_forex", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          amount_in_forex: "",
                        });
                      }}
                      value={conveyanceData[i]?.amount_in_forex}
                    />
                    <div className="small text-danger">
                      {i == Number(conveyanceData.length - 1) &&
                        formConvValidation.amount_in_forex}
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      name="amount_in_inr"
                      disabled
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      onChange={(e) => {
                        changeConvHandler(e.target.value, "amount_in_inr", i);
                        setFormConvValidation({
                          ...formConvValidation,
                          amount_in_inr: "",
                        });
                      }}
                      value={parseFloat(
                        Number(conveyanceData[i]?.amount_in_inr).toString()
                      ).toFixed(2)}
                    />
                    <div className="small text-danger">
                      {i == Number(conveyanceData.length - 1) &&
                        formConvValidation.amount_in_inr}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <div class="border-top my-3">
          Total : <i className="fas fa-rupee-sign f-12"></i>
          {Number.isNaN(conveyanceSumTotal) || conveyanceSumTotal == undefined
            ? 0
            : parseFloat(conveyanceSumTotal).toFixed(2)}
        </div>
      </div>
      <div class="mb-3 float-right">
        <div class="row">
          <div class="col-md-12 my-3 text-center">
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-danger mr-2"
              disabled={!flagConvIndex}
              onClick={() => {
                deleteSelectedConvData();
                setFlagConvIndex(false);
              }}
            >
              Delete
            </button>
            <button
              style={{ padding: "5px 5px", marginTop: "-35px" }}
              class="btn btn-outline-primary mr-2"
              onClick={() => {
                addMoreConvExpense(conveyanceData);
                handleValidateConv();
              }}
            >
              Add More
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-14 mt-3 ml-5 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mb-2 mt-2"
          onClick={() => {
            setCheckActiveTab("Summary");
          }}
        >
          Back
        </button>
        &nbsp;&nbsp;
        <button
          class="btn btn-primary-inner text-center bpi-main"
          onClick={() => handleValidateForm()}
          style={{ marginRight: "190px" }}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default ForeignExpense;
