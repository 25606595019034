import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import * as surveyService from "../../service/Survey";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import moment from "moment/moment";

function SurveyAsst() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();

  const options = [
    { value: "1", label: "Assessment" },
    { value: "2", label: "Polls" },
    { value: "3", label: "Survey" },
    { value: "4", label: "Feedback" },
  ];

  useEffect(() => {
    if (state?.surveyId) {
      navigate(`/viewmyassignment/${state?.surveyId}/${false}`, {
        state: {
          targetAsmnt: {
            id: state?.surveyId,
            type_id: state?.category,
            user_id: userData.id,
            current: "Assessment",
          },
          flag: "true",
          view: false,
        },
      });
    }
  }, [state]);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [surveyDataFilter, setSurveyDataFilter] = useState({
    status: "",
    assessment_type_id: 1,
  });
  const userData = useSelector(selectUserData);

  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [surveyType, setSurveyType] = useState([]);

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const [apiresp, setApiResp] = useState([]);
  const [subMenuCounts, setSubMenuCounts] = useState(0);

  const handleKey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (
      assessment_type_id !== "" &&
      assessment_type_id !== undefined &&
      assessment_type_id !== null
    ) {
      queryParm = queryParm + "&assessment_type_id=" + assessment_type_id;
    }

    if (
      category_id !== "" &&
      category_id !== undefined &&
      category_id !== null
    ) {
      queryParm = queryParm + "&category_id=" + category_id;
    }

    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }

    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    queryParm = queryParm + "&client=" + true;
    return queryParm;
  };

  const getSurvey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    surveyService
      .getSurvey(
        handleKey(
          search,
          assessment_type_id,
          category_id,
          status,
          start_date,
          end_date,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setApiResp(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    getSurvey(
      e.target.value.toLowerCase(),
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    // setSearchStr(e.target.value);
  };

  const mainDataFilter = (surveyDataFilter) => {
    console.log(surveyDataFilter);
    getSurvey(
      searchStr,
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  useEffect(() => {
    console.log(pageCount);
  }, [pageCount])

  useEffect(() => {
    getSurveyTypes();
  }, []);

  const getSurveyTypes = () => {
    surveyService.getAllCategoryType(false).then((response) => {
      if (response?.status === 200) {
        setSurveyType(response?.data?.dataList?.result);
      }
    });
  };

  const getSurveys = (pageNo) => {
    if (surveyType?.length > 0) {
      dispatch(setDisplayLoader('Display'))
      surveyService
        .getSurvey(`&sort_by=-id&client=${true}&page_no=${pageNo}`)
        .then((response) => {
          // console.log(response?.data?.dataList?.result);
          // getAllCategoryType();
          dispatch(setDisplayLoader('Hide'))
          setApiResp(response?.data?.dataList?.result);
          setSubMenuCounts(response?.data?.dataList?.result.length);
          setPageCount(response?.data?.dataList?.paginated_data?.totalPages)
        })
        .catch((error) => {
          dispatch(setDisplayLoader('Hide'))
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  }


  useEffect(() => {
    getSurveys(1)
  }, [surveyType]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getSurveys(activePage)
  }

  return (
    <>
      <div class="row">
        {/* <SelectedTypeUser current={"Assessment"} /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Poll, Survey and Assessment</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Survey & Polls</Breadcrumb.Item>
                    <Breadcrumb.Item active>Assessment</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Published At</th>
                    <th>Last Date</th>
                    {/* <th>Description</th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apiresp?.map((dataItem, idx) => {
                    return (
                      <tr>
                        <td>{dataItem?.assessment_type_name}</td>
                        <td className="assess-title" dangerouslySetInnerHTML={{
                          __html: dataItem.title.trim()
                        }}></td>
                        <td>{moment(dataItem.start_date).format('DD-MM-YYYY')}</td>
                        <td>{moment(dataItem.end_date).format('DD-MM-YYYY')}</td>
                        {/* <td className="assess-title" dangerouslySetInnerHTML={{
                          __html: dataItem?.description.trim()
                        }}></td> */}
                        {dataItem?.status === "Expired" ? (
                          <td>
                            <span class="badge bad-status badge-danger">
                              Expired
                            </span>
                          </td>
                        ) : dataItem?.status === "Pending" ? (
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                        ) : dataItem?.status === "Submitted" ? (
                          <td>
                            <span class="badge bad-status badge-success">
                              Submitted
                            </span>
                          </td>
                        ) : dataItem?.status === "Skipped" ? (
                          <td>
                            <span class="badge bad-status badge-success">
                              Skipped
                            </span>
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          {dataItem?.status === "Expired" ||
                            (dataItem?.status === "Submitted" &&
                              dataItem?.permission_type === "SO") ? (
                            ""
                          ) : dataItem?.status === "Submitted" &&
                            (dataItem?.permission_type === "SV" ||
                              dataItem?.permission_type === "SE") ? (
                            <span
                              class="cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/viewmyassignment/${dataItem?.id}/${true}`,
                                  {
                                    state: {
                                      targetAsmnt: {
                                        id: dataItem.id,
                                        type_id: dataItem.assessment_type_id,
                                        user_id: userData.id,
                                        current: "Assessment",
                                      },
                                      view: true,
                                      flag: "true",
                                    },
                                  }
                                )
                              }
                            >
                              <img src="images/eye.png" alt="" />
                            </span>
                          ) : (
                            ""
                          )}
                          {(dataItem?.status === "Pending" ||
                            (dataItem?.status === "Pending" &&
                              dataItem?.permission_type === "SE")) && (
                              <span
                                class="cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/viewmyassignment/${dataItem?.id}/${false}`,
                                    {
                                      state: {
                                        targetAsmnt: {
                                          id: dataItem.id,
                                          type_id: dataItem.assessment_type_id,
                                          user_id: userData.id,
                                          current: "Assessment",
                                        },
                                        view: false,
                                        flag: "true",
                                      },
                                    }
                                  )
                                }
                              >
                                <i className="far fa-edit"></i>
                              </span>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                {/* <Pagination>
                  <Pagination.Prev className="previpag" />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item >{2}</Pagination.Item>
                  <Pagination.Ellipsis />
                  <Pagination.Item>{9}</Pagination.Item>
                  <Pagination.Item>{10}</Pagination.Item>
                  <Pagination.Next className="previpag" />
                </Pagination> */}
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Published Date <span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  start_date: `${e.target.value}`,
                });
              }}
              value={surveyDataFilter.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Last Date <span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  end_date: `${e.target.value}`,
                });
              }}
              value={surveyDataFilter.end_date}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Type <span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  assessment_type_id: e.value,
                });
              }}
            />
          </div> */}
          <div class="form-group innergroup">
            <label>
              Status <span class="text-danger">*</span>
            </label>
            <select
              class="form-control"
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  status: e.target.value,
                });
              }}
            >
              <option value="">Select</option>
              <option value="Pending">Pending</option>
              <option value="Submitted">Submitted</option>
              <option value="Expired">Expired</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter(surveyDataFilter)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyAsst;
