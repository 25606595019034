import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { toast } from "react-toastify";
import { uploadImages } from "../../service/leaveService";
import { uploadUserGuide } from "../../service/NpsServices";

function NPSUserGuide() {
  const userData = useSelector(selectUserData);
  const [filename, setfilename] = useState("");
  const [imgSize, setImgSize] = useState(false);
  const [link, setLink] = useState(null);
  const [attachmentsValidate, setAttachmentsValidate] = useState(false);
  const [formData, setFormData] = useState({
    userGuide: "",
  });
  const dispatch = useDispatch();

  const onProfileChange = (e) => {
    const files = e.target.files[0];
    setLink(undefined);
    setImgSize(false);
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    dispatch(setDisplayLoader("Display"));
    uploadImages(fileData).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setFormData((prev) => ({
        ...prev,
        userGuide: response?.data,
      }));
      setfilename(response.data.original_name);
      setAttachmentsValidate(false);
    });
  };

  const submitForm = () => {
    uploadUserGuide(formData).then((response) => {
      if (response?.status === 200) {
        toast.success("User Guide Uploaded Successfully");
      }
    });
  };

  return (
    <div class="col-md-12">
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">NPS User Guide Upload</h4>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="border-top mt-2 mb-3"></div>
        </div>
        <div class="innerheadsec ml-5">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div class="form-group innergroup position-relative">
                <label>User Manual</label>
                {filename != undefined ? (
                  <input
                    type="text"
                    name="attachments"
                    class="form-control bg-white"
                    placeholder="(Image, PDF Format)"
                    disabled
                    value={filename}
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control bg-white"
                    disabled
                    placeholder="(Image, PDF Format)"
                  />
                )}
                {attachmentsValidate ? (
                  <div className="small text-danger">
                    *Attachment is required
                  </div>
                ) : null}
                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input
                    type="file"
                    title=""
                    name="attachments"
                    id="attachmentsFile"
                    accept="application/pdf"
                    onChange={(e) => onProfileChange(e, "attachments")}
                  />
                </div>
                {imgSize ? (
                  <div className="small text-danger">
                    File size should not exceed 1mb
                  </div>
                ) : null}
                {filename != undefined && (
                  <p>
                    Selected file
                    <span> {filename}</span>
                  </p>
                )}
                {link != undefined && (
                  <p>Selected file {<span> {link}</span>}</p>
                )}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
          <br />
          <button class="btn btn-primary-inner bpi-main" onClick={submitForm}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default NPSUserGuide;
