import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const getSkillGroup = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/skill-group-mapping-master?${queryParm}`);
  };
export const getGroup = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/skill-group-master?paginate=${paginate}`);
  };
export const getEmployee = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
  };
  export const saveSkillGroup = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/skill-group-mapping-master`, data);
  };
  
  export const updateSkillGroup = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/skill-group-mapping-master/${id}`, data);
  };

  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/skill-group-mapping-master/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const deleteSkillGroup = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/skill-group-mapping-master/${id}`);
  };
  export const multideleteSkillGroup = async (ids) => {
    return axios.post(`${AUTH_BASE_URL}/skill-group-mapping-master/skill_group/multiSkillGroupDelete`, ids);
  };
  export const isDataExists = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/skill-group-mapping-master/exists`, data);
  };
  

