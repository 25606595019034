import React, { useState, useEffect } from "react";
import AddQuestion from "./AddQuestion";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as surveyService from "../../service/Survey";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import Select from "react-select";
import { getUsersListDataNew } from "../../service/userService";

const QuestionsForm = (props) => {
  const {
    setTabStatus,
    edit,
    saveSurvey,
    isViewing,
    setTabType,
    tabType,
    templateData,
    tabStatus
  } = props;
  const [sectionsData, setSectionsData] = useState([]);
  const [imageLocalPreviewDict, set_imageLocalPreviewDict] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [multiOptQues, setMultiOptQues] = useState([]);
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch()

  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState({
    id: 0,
    assessment_type_id: 0,
    type: "",
    userDetails: false,
    category_id: 0,
    duration: 0,
    title: "",
    description: "",
    user_view_type: "full",
    section: false,
    question_per_section: [],
    section_heading: [],
    // wizard_type: "",
    save_as_template: false,
    template_name: "",
    banner: "",
    imgType: "",
    allowSequence: false,
    buddy: "",
    created_by: '',
    questions: [
      {
        id: 0,
        question: "",
        type: "",
        option_type: "",
        options: [
          {
            id: 0,
            option: "",
            min: 0,
            max: 0,
          },
        ],
      },
    ],
  });
  const [dataSelect, setDataSelect] = useState([]);
  const [bannerName, setBannerName] = useState("");
  const [formValidation, setFormValidation] = useState({
    assessment_type_id: "",
    category_id: "",
    title: "",
    description: "",
    user_view_type: "",
    // wizard_type: "",
  });

  const [questionData, setQuestionData] = useState([
    {
      id: 0,
      question: "",
      type: "",
      option_type: "",
      options: [],
      is_dropdown: false,
      default_option: "",
      is_mandatory: false,
      multiOptionFlag: false,
      allow_feedback: false,
      quesWidth: "",
      selectFeedback: false,
      selectFeedbackOption: ''
    },
  ]);
  const [categoryType, setCategoryType] = useState([]);
  const [surveyType, setSurveyType] = useState([]);

  const getAllCategoryType = (paginate) => {
    surveyService
      .getAllCategoryType(paginate)
      .then((response) => {
        setCategoryType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllSurveyType = () => {
    dispatch(setDisplayLoader('Display'))
    surveyService
      .getAllSurveyType()
      .then((response) => {
        dispatch(setDisplayLoader('Hide'))
        setSurveyType(response?.data?.dataList?.result);
        getAllCategoryType();
      })
      .catch((error) => {
        dispatch(setDisplayLoader('Hide'))
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSurveyData = (id, values) => {
    surveyService
      .updateSurveyData(id, values)
      .then((response) => {
        // setResponseData(response?.data?.dataList);
        setTabStatus("publish");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  function containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  const handleValidate = (value) => {
    let isError = false;

    const formerr = { ...formValidation };
    // if (!surveyData.assessment_type_id) {
    //   isError = true;
    //   formerr.assessment_type_id = "Required Survey Type ";
    //   setFormValidation(formerr);
    // }
    if (!surveyData.category_id) {
      isError = true;
      formerr.category_id = "Required Category ";
      setFormValidation(formerr);
    }
    if (!surveyData.title) {
      isError = true;
      formerr.title = "Required Document Type ";
      setFormValidation(formerr);
    }
    // if (!surveyData.description) {
    //   isError = true;
    //   formerr.description = "Required Description ";
    //   setFormValidation(formerr);
    // }
    // if (!surveyData.user_view_type) {
    //   isError = true;
    //   formerr.user_view_type = "Required User View Type ";
    //   setFormValidation(formerr);
    // }

    if (value.length <= 0) {
      isError = true;
      toast.error("Please add atleast one question");
    }

    // if (!surveyData.type) {
    //   isError = true;
    //   toast.error("Please select users type");
    // }
    value.map((item, i) => {
      if (item?.multiOptionFlag) {
        if (questionData?.length === 1) {
          if (item?.question === "" || multiOptQues[i]?.options?.length <= 0) {
            isError = true;
            return isError;
          }
        } else {
          if (item?.question === "" || item?.options?.length <= 0) {
            isError = true;
            return isError;
          }
        }
      } else {
        if (item?.type !== "") {
          if (
            item.question === "" ||
            item.type === "" ||
            item.option_type === "" ||
            item.options?.length <= 0
          ) {
            isError = true;
            toast.error("Please fill question and options data properly");
            return isError;
          }

          item.options.map((opItem, idx) => {
            if (item.type === "Rating" || item?.type === "Slider") {
              if (opItem.max === 0 || opItem.max === "") {
                isError = true;
                toast.error("Please fill max rating properly");
                return isError;
              }
            } else if (item.type === "Short Answer") {
              if (
                !containsOnlyNumbers(opItem.min) ||
                !containsOnlyNumbers(opItem.max) ||
                Number(opItem.min) > Number(opItem.max)
              ) {
                isError = true;
                toast.error("Please fill short answer range properly");
                return isError;
              }
            } else if (opItem.option === "" && (item.type !== "Long Answer" && item?.type !== "Date" && item?.type !== "Emoji" && item?.type !== "Slider")) {
              isError = true;
              toast.error("Please fill option data properly");
              return isError;
            }
          });
        }
      }
    });

    return isError;
  };

  const allQuestionFormData = () => {
    let sec_heading = [];
    let ques_per_sec = [];
    let sec_desc = [];
    let sec_id = [];
    sectionsData.map((item, idx) => {
      sec_heading.push(item.name);
      ques_per_sec.push(Number(item.num_ques));
      sec_desc.push(item?.desc)
      sec_id.push(item?.id)
    });

    let ques = questionData?.map((each, index) => {
      if (each?.multiOptionFlag) {
        let data = multiOptQues[index]
        data.selectFeedbackOption = each?.selectFeedback === undefined ? "" : each.selectFeedbackOption.toString()
        return multiOptQues[index];
      } else {
        each.selectFeedbackOption = each?.selectFeedback === undefined ? "" : each.selectFeedbackOption.toString()
        return each
      }
    })

    let dataSave = {
      assessment_type_id: Number(surveyData.assessment_type_id),
      category_id: Number(surveyData.category_id),
      title: surveyData.title,
      description: surveyData.description,
      user_view_type: surveyData.user_view_type,
      // wizard_type: surveyData.wizard_type,
      save_as_template: surveyData.save_as_template,
      template_name: surveyData.template_name,
      questions: ques,
      section: surveyData.section,
      section_heading: sec_heading,
      sec_id: sec_id,
      section_desc: sec_desc,
      question_per_section: ques_per_sec,
      banner: surveyData?.banner,
      userDetails: surveyData?.userDetails,
      type: tabType,
      duration: surveyData?.duration,
      random: surveyData?.random,
      max_ques: surveyData?.max_ques,
      alert_duration: surveyData?.alert_duration,
      imgType: surveyData?.imgType,
      allowSequence: surveyData?.allowSequence,
      buddy: surveyData?.buddy?.value,
      created_by: surveyData?.created_by?.value
    };
    console.log(dataSave);
    if (edit?.id) {
      updateSurveyData(edit.id, dataSave);
    } else {
      if (dataSave.questions.length <= 0) {
        toast.error("Please add one or more questions");
        return;
      }
      saveSurvey(dataSave);
    }
  };

  const bannerUpload = (e) => {
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("uploaded_file", files);
    surveyService.uploadfile(formData).then((res) => {
      if (res.status === 200) {
        setSurveyData((prev) => ({
          ...prev,
          banner: res?.data,
        }));
        setBannerName(res?.data?.original_name);
      }
    });
  };

  const handleValidateTab = () => {
    let value = JSON.parse(JSON.stringify(questionData))?.map((each, index) => {
      if (each?.multiOptionFlag) {
        return multiOptQues[index];
      } else {
        return each
      }
    });
    if (handleValidate(value)) return false;
    allQuestionFormData();
  };

  const updateSecInQues = (sId, field, value) => {
    if (field === 'name') {
      let questions = JSON.parse(JSON.stringify(questionData))
      questions.map((each) => {
        if (each.sec === sId) {
          each.section = value
        }
      });
      setQuestionData(questions)
    }
  }

  const updateSectionData = (cdata, action, field, value, idx) => {
    let asectionData = [...sectionsData];
    switch (action) {
      case "create":
        if (value == false) asectionData.splice(0, asectionData.length);
        else asectionData.push(cdata);

        break;
      case "update":
        asectionData[idx][field] = value;
        updateSecInQues(cdata, field, value)
        break;
    }
    setSectionsData(asectionData);
  };

  useEffect(() => {
    getAllSurveyType();
    getUsers();
  }, []);

  const getUsers = () => {
    getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserList(res);
      }
    })
  }

  const dropSurveyList = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`survey${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const categorySurveyList = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`survey${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  function jvDetail(val) {
    if (templateData) {
      const arrayDetail = [];

      setTabType(templateData?.type);

      if (isViewing) console.log("..viewing..");
      else console.log("..template", templateData);

      let optionSelectArray = [];
      for (let i = 0; i < templateData.questions.length; i++) {
        const childArrayDetail = [];

        let imgLocalPreviewDict = { ...imageLocalPreviewDict };

        if (
          templateData.questions[i].option_type == "file" ||
          templateData.questions[i].option_type == "old_file"
        ) {
          imgLocalPreviewDict[i] = [];
        }

        templateData?.questions[i]?.options?.map((value, opidx) => {

          if (
            templateData.questions[i].option_type == "file" ||
            templateData.questions[i].option_type == "old_file"
          ) {
            imgLocalPreviewDict[i].push(value.option);
          }

          childArrayDetail.push({
            id: value?.id,
            option: value.option,
            option_type:
              templateData.questions[i].option_type == "file"
                ? "old_file"
                : templateData.questions[i].option_type,
            min: value.min,
            max: value.max,
            desc: value?.desc,
            type: value?.type === null ? "" : value?.type,
            is_dropdown: value?.is_dropdown,
            allow_feedback: value?.allow_feedback,
            optWidth: value?.optWidth === null ? "" : value?.optWidth,
            optWidthMob: value?.optWidthMob === null ? "" : value?.optWidthMob,
            optWidthTab: value?.optWidthTab === null ? "" : value?.optWidthTab,
            feedbackWidth: value?.feedback_width === null ? "" : value?.feedback_width,
            feedbackWidthMob: value?.feedback_width_mob === null ? "" : value?.feedback_width_mob,
            feedbackWidthTab: value?.feedback_width_tab === null ? "" : value?.feedback_width_tab,
            feedback_mandatory: value?.feedback_mandatory === null ? false : value?.feedback_mandatory
          });
        });

        set_imageLocalPreviewDict(imgLocalPreviewDict);

        let data = {
          value: templateData?.questions[i].type,
          label: (
            <div>
              <i class="fas fa-caret-down"></i>{" "}
              {templateData?.questions[i].type}
            </div>
          ),
        };

        optionSelectArray.push(data);
        // setDataSelect(data);

        arrayDetail.push({
          id: 0,
          question: templateData.questions[i].question,
          type: data.value,
          option_type: templateData.questions[i].option_type,
          options: childArrayDetail,
          is_dropdown: templateData?.questions[i]?.is_dropdown,
          default_option: templateData?.questions[i]?.default_option,
          is_mandatory: templateData?.questions[i]?.is_mandatory,
          multiOptionFlag: templateData?.questions[i]?.multiOption,
          quesWidth: templateData?.questions[i]?.quesWidth,
          quesWidthMob: templateData?.questions[i]?.quesWidthMob,
          quesWidthTab: templateData?.questions[i]?.quesWidthTab,
          selectFeedback: templateData?.questions[i]?.select_feedback,
          selectFeedbackOption: templateData?.questions[i]?.select_feedback_option,
        });
      }
      setDataSelect(optionSelectArray);
      setQuestionData(arrayDetail);
    } else if (edit) {
      const arrayDetail = [];

      setTabType(edit?.type);

      if (isViewing) console.log("..viewing..");
      else console.log("..editi", edit);

      let optionSelectArray = [];
      for (let i = 0; i < edit.questions.length; i++) {
        const childArrayDetail = [];

        let imgLocalPreviewDict = { ...imageLocalPreviewDict };

        if (
          edit.questions[i].option_type == "file" ||
          edit.questions[i].option_type == "old_file"
        ) {
          imgLocalPreviewDict[i] = [];
        }

        edit?.questions[i]?.options?.map((value, opidx) => {
          if (
            edit.questions[i].option_type == "file" ||
            edit.questions[i].option_type == "old_file"
          ) {
            imgLocalPreviewDict[i].push(value.option);
          }

          childArrayDetail.push({
            id: value?.id,
            option: value.option,
            option_type: edit.questions[i].option_type,
            min: value.min,
            max: value.max,
            desc: value?.desc,
            type: value?.type === null ? "" : value?.type,
            is_dropdown: value?.is_dropdown,
            allow_feedback: value?.allow_feedback,
            optWidth: value?.optWidth === null ? "" : value?.optWidth,
            optWidthMob: value?.optWidthMob === null ? "" : value?.optWidthMob,
            optWidthTab: value?.optWidthTab === null ? "" : value?.optWidthTab,
            feedbackWidth: value?.feedback_width === null ? "" : value?.feedback_width,
            feedbackWidthMob: value?.feedback_width_mob === null ? "" : value?.feedback_width_mob,
            feedbackWidthTab: value?.feedback_width_tab === null ? "" : value?.feedback_width_tab,
            feedback_mandatory: value?.feedback_mandatory === null ? false : value?.feedback_mandatory
          });
        });

        set_imageLocalPreviewDict(imgLocalPreviewDict);

        let data = {
          value: edit?.questions[i].type,
          label: (
            <div>
              <i class="fas fa-caret-down"></i> {edit?.questions[i].type}
            </div>
          ),
        };

        optionSelectArray.push(data);
        // setDataSelect(data);
        arrayDetail.push({
          id: edit.questions[i]?.id,
          question: edit.questions[i].question,
          type: data.value,
          option_type: edit.questions[i].option_type,
          options: childArrayDetail,
          is_dropdown: edit.questions[i]?.is_dropdown,
          section: edit.questions[i]?.section,
          sec: edit.questions[i]?.sec_id,
          sequence: edit.questions[i]?.sequence,
          default_option: edit.questions[i]?.default_option,
          is_mandatory: edit.questions[i]?.is_mandatory,
          multiOptionFlag: edit.questions[i]?.multiOption,
          quesWidth: edit?.questions[i]?.quesWidth,
          quesWidthMob: edit?.questions[i]?.quesWidthMob,
          quesWidthTab: edit?.questions[i]?.quesWidthTab,
          selectFeedback: edit?.questions[i]?.select_feedback,
          selectFeedbackOption: edit?.questions[i]?.select_feedback_option === null ? '' : edit?.questions[i]?.select_feedback_option,
        });
      }
      setDataSelect(optionSelectArray);
      setQuestionData(arrayDetail);

      let sectionData = [];
      Object.keys(edit?.section_heading)?.map((each) => {
        let secData = {};
        secData["name"] = edit?.section_heading[each];
        secData["desc"] = edit?.section_desc[each];
        secData["num_ques"] = edit?.question_per_section[each];
        secData['id'] = edit?.sec_id[each]
        secData['created_values'] = {}
        sectionData.push(secData);
      });
      setSectionsData(sectionData);
    }
  }

  useEffect(() => {
    if (templateData) {
      jvDetail();
      setSurveyData(templateData);
      Object.keys(templateData).forEach((item) => {
        surveyData[item] = templateData[item];
      });
    } else if (edit) {
      let surveyDetails = JSON.parse(JSON.stringify(surveyData));
      let newData = JSON.parse(JSON.stringify(edit));
      if (newData?.category_name === "Quiz") {
        setCategoryName("Quiz")
      }
      jvDetail(newData?.category_name);
      setSurveyData(edit);
      Object.keys(newData).forEach((item) => {
        if (item === "is_sectional") {
          surveyDetails["section"] = newData[item];
        }
        if (item === "user_details") {
          surveyDetails["userDetails"] = newData["user_details"];
        }
        if (item === 'banner') {
          setBannerName(newData[item]?.url ? newData[item]?.url.split('/').pop() : "")
        }
        if (item === "buddy_id") {
          surveyDetails['buddy'] = userList?.filter((x) => {
            if (x?.value === newData['buddy_id']) {
              return true
            }
          })[0]
        }
        if (item === "imgType") {
          surveyDetails[item] = newData[item]
        } else {
          surveyDetails[item] = newData[item];
        }
      });
      setSurveyData(surveyDetails);
    }
  }, [edit, templateData, userList]);

  const QuestionDataChange = (e, index) => {
    let copyData = JSON.parse(JSON.stringify(questionData));
    copyData[index].default_option = e;
    setQuestionData(copyData);
  };

  return (
    <div class="row">
      <div class="col-md-4">
        <div class="row position-sticky fixed-top">
          <div class="col-md-6 form-group innergroup">
            <label>
              Users Type<span class="text-danger">*</span>
            </label>

            {/* <label class="logCheck d-block"> */}
            <div className="">
              <label class="logCheck d-inline-block mr-4" for="Internal">
                <input
                  id="Internal"
                  checked={tabType === "Internal"}
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onClick={() => {
                    setSurveyData((prev) => ({ ...prev, type: "Internal" }));
                    setTabType("Internal");
                  }}
                  name="assignment_type"
                />
                Internal
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block" for="External">
                <input
                  id="External"
                  checked={tabType === "External"}
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onClick={() => {
                    setSurveyData((prev) => ({ ...prev, type: "External" }));
                    setTabType("External");
                  }}
                  name="assignment_type"
                />
                External
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="col-md-6 form-group innergroup">
            <label>
              User Details<span class="text-danger">*</span>
            </label>

            {/* <label class="logCheck d-block"> */}
            <div className="">
              <label class="logCheck d-inline-block mr-4" for="allow">
                <input
                  id="allow"
                  // checked={tabType === "Internal"}
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onClick={() => {
                    setSurveyData((prev) => ({ ...prev, userDetails: true }));
                    // setTabType("Internal");
                  }}
                  checked={surveyData?.userDetails}
                  name="user_det"
                />
                Yes
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block" for="disallow">
                <input
                  id="disallow"
                  // checked={tabType === "External"}
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onClick={() => {
                    setSurveyData((prev) => ({ ...prev, userDetails: false }));
                    // setTabType("External");
                  }}
                  name="user_det"
                />
                No
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="col-md-6 form-group innergroup">
            <label>Banner/LOGO</label>
            <input
              type="text"
              class="form-control bg-white"
              disabled
              placeholder="(JPG, PNG Format only - upto 4 MB)"
            />
            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => bannerUpload(e)}
              />
            </div>
            <p>
              Selected files <span>{bannerName}</span>
            </p>
          </div>
          <div class="col-md-6">
            <div class="form-group innergroup">
              <label>
                Image Type
              </label>
              <select
                class="form-control newbgselect"
                name="category_id"
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing
                }
                onChange={(e) => {
                  setSurveyData({
                    ...surveyData,
                    imgType: e.target.value,
                  });
                }}
                value={surveyData?.imgType}
              >
                <option value="0" label="Select Image Type" selected />
                <option value="Logo" label="Logo" />
                <option value="Banner" label="Banner" />
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group innergroup">
              <label>
                Survey Type<span class="text-danger">*</span>
              </label>
              <select
                class="form-control newbgselect"
                name="category_id"
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing
                }
                onChange={(e) => {
                  setSurveyData({
                    ...surveyData,
                    category_id: e.target.value,
                  });
                  setFormValidation({
                    ...formValidation,
                    category_id: "",
                  });
                  setCategoryName(
                    e.target.options[
                      e.target.selectedIndex
                    ].getAttribute("label")
                  )
                }}
                value={surveyData?.category_id}
              >
                <option value="0" label="Select Survey Category" disabled />
                {categorySurveyList(categoryType)}
              </select>
              <div className="small text-danger">
                {formValidation.category_id}
              </div>
            </div>
            {categoryName === "Quiz" && (
              <div class="form-group innergroup">
                <label>
                  Quiz Duration (in min)<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Quiz Duration"
                  name="duration"
                  onChange={(e) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      duration: e.target.value,
                    }));
                    // setFormValidation({
                    //   ...formValidation,
                    //   title: "",
                    // });
                  }}
                  value={surveyData?.duration}
                />

                <label>
                  Alert Duration (in min)<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Alert Duration"
                  name="alert_duration"
                  onChange={(e) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      alert_duration: e.target.value,
                    }));
                    // setFormValidation({
                    //   ...formValidation,
                    //   title: "",
                    // });
                  }}
                  value={surveyData?.alert_duration}
                />
                {/* <div className="small text-danger">{formValidation.title}</div> */}
              </div>
            )}
          </div>
          {/* {surveyData?.type === "Internal" && (
            <div className="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Buddy<span class="text-danger">*</span>
                </label>
                <Select
                  closeMenuOnSelect={false}
                  options={userList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select..."
                  onChange={(event, action) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      buddy: event,
                    }));
                  }}
                  value={surveyData?.buddy}
                />
              </div>
            </div>
          )} */}
          <div class="col-md-12">
            <div class="form-group innergroup">
              <label>
                Survey Title<span class="text-danger">*</span>
              </label>
              <CKEditor
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing
                }
                editor={ClassicEditor}
                placeholder="Enter Title"
                name="title"
                onReady={(editor) => {
                  // console.log("Editor is ready to use!", editor);
                }}
                data={surveyData?.title}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSurveyData((prev) => ({
                    ...prev,
                    title: data,
                  }));
                }}
              />
              <div className="small text-danger">{formValidation.title}</div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group innergroup">
              <label>Description</label>
              <CKEditor
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing
                }
                editor={ClassicEditor}
                name="description"
                onReady={(editor) => {
                  // console.log("Editor is ready to use!", editor);
                }}
                data={surveyData?.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSurveyData((prev) => ({
                    ...prev,
                    description: data,
                  }));
                  setFormValidation({
                    ...formValidation,
                    description: "",
                  });
                }}
              />
              <div className="small text-danger">
                {formValidation.description}
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group innergroup">
            <label>
              Question Sequence<span class="text-danger">*</span>
            </label>
            <div className="">
              <label class="logCheck d-inline-block mr-4" for="allowSeq">
                <input
                  id="allowSeq"
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onChange={() => {
                    setSurveyData((prev) => ({ ...prev, allowSequence: true }));
                  }}
                  checked={surveyData?.allowSequence}
                  name="allow_seq"
                />
                Yes
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block" for="disallowSeq">
                <input
                  id="disallowSeq"
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="radio"
                  onChange={() => {
                    setSurveyData((prev) => ({ ...prev, allowSequence: false }));
                  }}
                  name="allow_seq"
                />
                No
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          {edit && (
            <div className="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Created By<span class="text-danger">*</span>
                </label>
                <Select
                  closeMenuOnSelect={false}
                  options={userList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select..."
                  onChange={(event, action) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      created_by: event,
                    }));
                  }}
                  value={surveyData?.created_by || surveyData?.created_by_id}
                />
              </div>
            </div>
          )}
          {/* <div class="col-md-6">
            <div class="form-group innergroup">
              <label>
                User View Type<span class="text-danger">*</span>
              </label>
              <select
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing || surveyData?.survey_status === "Published"
                }
                class="form-control newbgselect"
                name="user_view_type"
                onChange={(e) => {
                  setSurveyData({
                    ...surveyData,
                    user_view_type: e.target.value,
                  });
                  setFormValidation({
                    ...formValidation,
                    user_view_type: "",
                  });
                }}
                value={surveyData?.user_view_type}
              >
                <option value="">Select</option>
                <option value="wiz">Wizard</option>
                <option value="full">Full</option>
              </select>
              <div className="small text-danger">
                {formValidation.user_view_type}
              </div>
            </div>
          </div> */}
          <div class="col-md-12">
            <div class="form-group innergroup savetemplate">
              <label class="logCheck d-inline-block mr-4">
                Save as Template
                <input
                  disabled={
                    templateData != undefined || templateData != null
                      ? false
                      : isViewing
                  }
                  type="checkbox"
                  name="save_as_template"
                  onChange={(e) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      save_as_template: e.target.checked,
                    }));
                  }}
                  value={surveyData?.save_as_template}
                  checked={surveyData?.save_as_template}
                />
                <span class="checkmark"></span>
              </label>
              <input
                disabled={
                  templateData != undefined || templateData != null
                    ? false
                    : isViewing
                }
                type="text"
                class="form-control mt-2"
                placeholder="Enter Template Name"
                name="template_name"
                onChange={(e) => {
                  setSurveyData((prev) => ({
                    ...prev,
                    template_name: e.target.value,
                  }));
                }}
                value={surveyData?.template_name}
              />
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <div class="form-group innergroup ">
              <label class="logCheck d-inline-block mr-4">
                Section
                <input
                  disabled={isViewing}
                  type="checkbox"
                  name="setsections"
                  onChange={(e) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      section: e.target.checked,
                    }));
                    updateSectionData(
                      {
                        id: 0,
                        name: "",
                        num_ques: 0,
                        desc: "",
                        created_values: {},
                      },
                      "create",
                      null,
                      e.currentTarget.checked,
                      -1
                    );
                  }}
                  value={surveyData?.section}
                  checked={surveyData?.section}
                />
                <span class="checkmark"></span>
              </label>

              {surveyData?.section ? (
                <div id="q_sections">
                  {sectionsData?.map((asection, idx) => {
                    return (
                      <div>
                        <label>Section Name</label>
                        <CKEditor
                          editor={ClassicEditor}
                          name="s_name"
                          placeholder="Section name"
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            updateSectionData(
                              asection?.id,
                              "update",
                              "name",
                              data,
                              idx
                            )
                          }}
                          data={asection?.name}
                        />
                        <br />
                        <label>Section Description</label>
                        <textarea
                          className="form-control"
                          name="s_desc"
                          placeholder="Section Description"
                          onChange={(event) => {
                            updateSectionData(
                              null,
                              "update",
                              "desc",
                              event.target.value,
                              idx
                            )
                          }}
                          value={asection?.desc}
                        />
                        {/* <CKEditor
                          editor={ClassicEditor}
                          name="s_desc"
                          placeholder="Section Description"
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            updateSectionData(
                              null,
                              "update",
                              "desc",
                              data,
                              idx
                            )
                          }}
                          data={asection?.desc}
                        /> */}
                        <br />
                        <label>Question per section</label>
                        <input
                          type="number"
                          class="form-control mt-2"
                          placeholder="Question per section"
                          name="qps"
                          onChange={(e) =>
                            updateSectionData(
                              null,
                              "update",
                              "num_ques",
                              e.target.value,
                              idx
                            )
                          }
                          value={asection?.num_ques}
                        />
                        <hr />
                      </div>
                    );
                  })}

                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    onClick={() =>
                      updateSectionData(
                        {
                          id: 0,
                          name: "",
                          num_ques: "",
                          desc: "",
                          created_values: {},
                        },
                        "create",
                        null,
                        null,
                        -1
                      )
                    }
                  >
                    <i class="fas fa-plus-square mr-1"></i> Add more sections
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {categoryName === "Quiz" && (
            <div class="col-md-12">
              <div class="form-group innergroup savetemplate">
                <label class="logCheck d-inline-block mr-4">
                  Random Question
                  <input
                    type="checkbox"
                    name="save_as_template"
                    onChange={(e) => {
                      setSurveyData((prev) => ({
                        ...prev,
                        random: e.target.checked,
                      }));
                    }}
                    value={surveyData?.random}
                    checked={surveyData?.random}
                  />
                  <span class="checkmark"></span>
                </label>
                {surveyData?.random && (
                  <input
                    type="text"
                    class="form-control mt-2"
                    placeholder="Enter Max. Ques."
                    name="max_ques"
                    onChange={(e) => {
                      setSurveyData((prev) => ({
                        ...prev,
                        max_ques: e.target.value,
                      }));
                    }}
                    value={surveyData?.max_ques}
                  />
                )}
              </div>
            </div>
          )}
          <div class="col-md-12 mt-2">
            <button
              class="btn btn-outline-secondary mr-3"
              type="button"
              onClick={() => navigate("/survey")}
            // onClick={() => setTabStatus("Primary")}
            >
              <i class="far fa-arrow-alt-circle-left"></i> Previous
            </button>
            <button
              hidden={isViewing}
              class="btn btn-primary-inner bpi-main"
              type="button"
              onClick={() => {
                handleValidateTab();
              }}
            // disabled={
            //   templateData != undefined || (templateData != null && false)
            // }
            >
              <i class="far fa-arrow-alt-circle-right mr-1"></i>
              Save & Next
            </button>
          </div>
        </div>
      </div>

      {/* {console.log('arrsending..', questionData)} */}
      <AddQuestion
        sectionsData={sectionsData}
        edit={edit}
        setQuestionData={setQuestionData}
        questionData={questionData}
        dataSelect={dataSelect}
        categoryName={categoryName}
        setDataSelect={setDataSelect}
        isViewingorPublished={isViewing}
        set_imageLocalPreviewDict={set_imageLocalPreviewDict}
        imageLocalPreviewDict={imageLocalPreviewDict}
        QuestionDataChange={QuestionDataChange}
        setMultiOptQues={setMultiOptQues}
        surveyData={surveyData}
      />
    </div>
  );
};

export default QuestionsForm;
