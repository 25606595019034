import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { Modal, Pagination, Tabs, Tab, Badge } from "react-bootstrap";
import * as otvServiceData from "../../service/otvServices";
import Select from "react-select";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

function OTVApproval() {
  const navigate = useNavigate();
  const userData = useSelector(selectUserData)
  const [otvListData, setOtvListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [draftPageCount, setDraftPageCount] = useState(1);
  const [currentTab, setCurrentTab] = useState("approval");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [searchStr, setSearchStr] = useState("");
  const [vendorFilterList, setVendorFilterList] = useState([]);
  const [vendorFilterValue, setVendorFilterValue] = useState([]);
  const [invoiceFilterList, setinvoiceFilterList] = useState([]);
  const [invoiceFilterValue, setinvoiceFilterValue] = useState([]);
  const [reqIdList, setReqIdList] = useState([]);
  const [reqIdValue, setReqIdValue] = useState([]);
  const [reqByNameList, setReqByNameList] = useState([]);
  const [reqByNameValue, setReqByNameValue] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseValue, setExpenseValue] = useState([]);
  const [filterNonOtv, setFilterNonOtv] = useState({
    end_date: "",
    start_date: "",
    id: "",
    type: "",
    requested_by_id: "",
    vendor_id: "",
    invoice_no: "",
    expense_category_id: "",
    status: "",
  });

  const handleKey = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm = "sort_by=" + sort_by;
    if (paginate !== "" && paginate !== undefined && paginate !== null) {
      queryParm = queryParm + "&paginate=" + paginate;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (type !== "" && type !== undefined && type !== null) {
      queryParm = queryParm + "&type=" + type;
    }
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    if (
      requested_by_id !== "" &&
      requested_by_id !== undefined &&
      requested_by_id !== null
    ) {
      queryParm = queryParm + "&requested_by_id=" + requested_by_id;
    }
    if (vendor_id !== "" && vendor_id !== undefined && vendor_id !== null) {
      queryParm = queryParm + "&vendor_id=" + vendor_id;
    }
    if (invoice_no !== "" && invoice_no !== undefined && invoice_no !== null) {
      queryParm = queryParm + "&invoice_no=" + invoice_no;
    }
    if (
      expense_category_id !== "" &&
      expense_category_id !== undefined &&
      expense_category_id !== null
    ) {
      queryParm = queryParm + "&expense_category_id=" + expense_category_id;
    }
    return queryParm;
  };

  const getOtvDataList = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    otvServiceData
      .getOtvDataList(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        let vendorData = [];
        let invoiceNo = [];
        let requestedBy = [];
        let requestedId = [];
        setOtvListData(response?.data?.dataList?.result);
        setDraftPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        response?.data?.dataList?.result?.map((res) => {
          let venData = {
            value: res.vendor_code,
            label: res.vendor_name,
          };
          vendorData.push(venData);
          let invoiceData = {
            value: res.invoice_number,
            label: res.invoice_number,
          };
          invoiceNo.push(invoiceData);
          let reqData = {
            value: res.id,
            label: res.id,
          };
          requestedId.push(reqData);
          let reqByData = {
            value: res.created_by,
            label: res.created_by,
          };
          requestedBy.push(reqByData);
        });
        setVendorFilterList(vendorData);
        setinvoiceFilterList(invoiceNo);
        setReqIdList(requestedId);
        setReqByNameList(requestedBy);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onPushVendorFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, vendor_id: ids });
    setVendorFilterValue(e);
  };

  const onPushInvoiceFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    if (ids !== "") {
      setFilterNonOtv((prev) => ({ ...prev, invoice_no: ids }));
    }
    setinvoiceFilterValue(e);
  };

  const onPushExpensesFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv((prev) => ({ ...prev, expense_category_id: ids }));
    setExpenseValue(e);
  };

  const onRequestId = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, id: ids });
    setReqIdValue(e);
  };

  const onPushRequestBy = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, requested_by_id: ids });
    setReqByNameValue(e);
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterNonOtv));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setReqIdValue([]);
    setinvoiceFilterValue([]);
    setReqByNameValue([]);
    setVendorFilterValue([]);
    setExpenseValue([]);
    setFilterNonOtv(ClearData);
    filterClose();
    if (currentTab === "approval") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        "-id",
        true
      );
    } else if (currentTab === "approved") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "A",
        1,
        pageSizeNo,
        "id",
        true
      );
    } else {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "D",
        1,
        pageSizeNo,
        "id",
        true
      );
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterNonOtv.end_date;
    const start_date = filterNonOtv.start_date;
    const id = filterNonOtv.id;
    const requested_by_id = filterNonOtv.requested_by_id;
    const vendor_id = filterNonOtv.vendor_id;
    const invoice_no = filterNonOtv.invoice_no;
    const expense_category_id = filterNonOtv.expense_category_id;
    const status = filterNonOtv.status;

    getOtvDataList(
      filterNonOtv.type,
      searchStr,
      end_date,
      start_date,
      id,
      requested_by_id,
      vendor_id,
      invoice_no,
      expense_category_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getOtvDataList(
      filterNonOtv.type,
      searchStr,
      filterNonOtv.end_date,
      filterNonOtv.start_date,
      filterNonOtv.id,
      filterNonOtv.requested_by_id,
      filterNonOtv.vendor_id,
      filterNonOtv.invoice_no,
      filterNonOtv.expense_category_id,
      filterNonOtv.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      false
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getOtvDataList(
        "",
        e.target.value.toLowerCase(),
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.id,
        filterNonOtv.requested_by_id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        currentPage,
        pageSizeNo,
        "id",
        true
      );
    }
    setSearchStr(e.target.value);
  };

  const handleViewRedirect = (id) => {
    navigate(`/viewotv/${id}`, {
      state: { from: id, flag: true },
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (currentTab === "approval") {
      getOtvDataList(
        "",
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.id,
        filterNonOtv.requested_by_id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else if (currentTab === "approved") {
      getOtvDataList(
        "",
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.id,
        filterNonOtv.requested_by_id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        "A",
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else {
      getOtvDataList(
        "",
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.id,
        filterNonOtv.requested_by_id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        "D",
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (currentTab === "approval") {
        getOtvDataList(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          1,
          pageSizeNo,
          "-id",
          true
        );
      } else if (currentTab === "approved") {
        getOtvDataList(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "A",
          1,
          pageSizeNo,
          "id",
          true
        );
      } else {
        getOtvDataList(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "D",
          1,
          pageSizeNo,
          "id",
          true
        );
      }
    }
  };

  useEffect(() => {
    closeButtonCallBack();
    if (currentTab === "approval") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        "-id",
        true
      );
    } else if (currentTab === "approved") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "A",
        1,
        pageSizeNo,
        "id",
        true
      );
    } else {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "D",
        1,
        pageSizeNo,
        "id",
        true
      );
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === "approval") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        "-id",
        true
      );
    } else if (currentTab === "approved") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "A",
        1,
        pageSizeNo,
        "id",
        true
      );
    } else {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "D",
        1,
        pageSizeNo,
        "id",
        true
      );
    }
  }, [pageSizeNo]);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    For RM Approval Payemnt Request
                  </h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      RM Approval Payment Request
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Invoice,
                            <br />
                            bank name
                            <br />
                            bank acc
                            <br />
                            bank ifsc
                            <br />
                            status
                            <br />
                            type
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Invoice, bank name, bank ifsc ..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey="approval"
                id="approvaltabs"
                onSelect={(e) => setCurrentTab(e)}
              >
                <Tab
                  eventKey="approval"
                  title={
                    <React.Fragment>
                      For Approval
                      {/* <span class="numberpurchse">11</span> */}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured travelintim newSpace">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            Request ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("code");
                            }}
                            className={
                              sortData["code"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["code"]
                            }
                          >
                            Vendor Code
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("name");
                            }}
                            className={
                              sortData["name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["name"]
                            }
                          >
                            Vendor Name
                          </th>
                          <th>GST</th>
                          <th>Location</th>
                          <th>Invoice No</th>
                          <th>Invoice Date</th>
                          <th>Expense Category</th>
                          <th>Base Amount</th>
                          <th>GL Code</th>
                          <th>Cost Center</th>
                          <th>Company Code</th>
                          <th>Bank Name</th>
                          <th>Bank A/c No</th>
                          <th>IFC Code</th>
                          <th>SAP Document No</th>
                          <th>Type</th>
                          <th>Pending At</th>
                          <th>Status</th>
                          <th>Requested By</th>
                          <th>Requested On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {otvListData
                          ?.filter((value) => value.status === null)
                          ?.map((data) => (
                            <tr>
                              {console.log(data, "data1234")}
                              <td>
                                <button
                                  className="btn btn-blanktd text-primary ml-2"
                                  onClick={() => handleViewRedirect(data.id)}
                                >
                                  <img src="images/eye.png" alt="" />
                                </button>
                              </td>
                              <td class="text-theme fw-bold">{data?.id}</td>
                              <td>{data?.vendor_code}</td>
                              <td>
                                {data?.otv_name !== null
                                  ? data?.otv_name
                                  : data?.vendor_name}
                              </td>
                              <td>{data?.vendor_gst}</td>
                              <td>{data?.vendor_location}</td>
                              <td>{data?.invoice_number}</td>
                              <td>{data?.invoice_date}</td>
                              <td>{data?.expense_name}</td>
                              <td>{data?.gross_amount}</td>
                              <td>{data?.gl_code}</td>
                              <td>{data?.cost_center_name}</td>
                              <td>{data?.organisation_name} - {data?.organisation_code}</td>
                              <td>{data?.bank_name}</td>
                              <td>{data?.bank_account_number}</td>
                              <td>{data?.bank_ifsc_code}</td>
                              <td>{data?.sap_doc_no}</td>
                              <td>
                                {data?.type === "OTV"
                                  ? "OTV Payment Request"
                                  : data?.type === "NONPO"
                                    ? "Non PO Vendor Payment Request"
                                    : data?.type === "NONREIMB"
                                      ? "Vendor Invoice Reimb below 5K"
                                      : ""}
                              </td>
                              <td>
                                {
                                  data?.current_approver_data
                                    ?.current_approver_name
                                }
                              </td>
                              <td>
                                {data?.status === null && (
                                  <Badge bg="secondary">Pending</Badge>
                                )}
                              </td>
                              <td>{data?.created_by}</td>
                              <td>
                                {moment(data?.created_at).format("DD-MMM-YYYY")}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                          <option value={500} label={500}>500</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={draftPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="approved"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured travelintim">
                      <thead>
                        <tr>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            Request ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("code");
                            }}
                            className={
                              sortData["code"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["code"]
                            }
                          >
                            Vendor Code
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("name");
                            }}
                            className={
                              sortData["name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["name"]
                            }
                          >
                            Vendor Name
                          </th>
                          <th>GST</th>
                          <th>Location</th>
                          <th>Invoice No</th>
                          <th>Invoice Date</th>
                          <th>Expense Category</th>
                          <th>Base Amount</th>
                          <th>GL Code</th>
                          <th>Cost Center</th>
                          <th>Company Code</th>
                          <th>Bank Name</th>
                          <th>Bank A/c No</th>
                          <th>IFC Code</th>
                          <th>SAP Document No</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Requested By</th>
                          <th>Requested On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {otvListData?.map((data) => (
                          <tr>
                            <td class="text-theme fw-bold">{data?.id}</td>
                            <td>{data?.vendor_code}</td>
                            <td>{data?.otv_name}</td>
                            <td>{data?.vendor_gst}</td>
                            <td>{data?.vendor_location}</td>
                            <td>{data?.invoice_number}</td>
                            <td>{data?.invoice_date}</td>
                            <td>{data?.expense_name}</td>
                            <td>{data?.gross_amount}</td>
                            <td>{data?.gl_code}</td>
                            <td>{data?.cost_center_name}</td>
                            <td>{data?.organisation_name} - {data?.organisation_code}</td>
                            <td>{data?.bank_name}</td>
                            <td>{data?.bank_account_number}</td>
                            <td>{data?.bank_ifsc_code}</td>
                            <td>{data?.document_no}</td>
                            <td>
                              {data?.type === "OTV"
                                ? "OTV Payment Request"
                                : data?.type === "NONPO"
                                  ? "Non PO Vendor Payment Request"
                                  : data?.type === "NONREIMB"
                                    ? "Vendor Invoice Reimb below 5K"
                                    : ""}
                            </td>
                            <td>
                              {data?.status === "A" && (
                                <Badge bg="success">Approved</Badge>
                              )}
                            </td>
                            <td>{data?.created_by}</td>
                            <td>
                              {moment(data?.created_at).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                          <option value={500} label={500}>500</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={draftPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="rejected"
                  title={<React.Fragment>Rejected</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured travelintim">
                      <thead>
                        <tr>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            Request ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("code");
                            }}
                            className={
                              sortData["code"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["code"]
                            }
                          >
                            Vendor Code
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("name");
                            }}
                            className={
                              sortData["name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["name"]
                            }
                          >
                            Vendor Name
                          </th>
                          <th>GST</th>
                          <th>Location</th>
                          <th>Invoice No</th>
                          <th>Invoice Date</th>
                          <th>Expense Category</th>
                          <th>Base Amount</th>
                          <th>GL Code</th>
                          <th>Cost Center</th>
                          <th>Company Code</th>
                          <th>Bank Name</th>
                          <th>Bank A/c No</th>
                          <th>IFC Code</th>
                          <th>SAP Document No</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Requested By</th>
                          <th>Requested On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {otvListData?.map((data) => (
                          <tr>
                            <td class="text-theme fw-bold">{data?.id}</td>
                            <td>{data?.vendor_code}</td>
                            <td>{data?.otv_name}</td>
                            <td>{data?.vendor_gst}</td>
                            <td>{data?.vendor_location}</td>
                            <td>{data?.invoice_number}</td>
                            <td>{data?.invoice_date}</td>
                            <td>{data?.expense_name}</td>
                            <td>{data?.gross_amount}</td>
                            <td>{data?.gl_code}</td>
                            <td>{data?.cost_center_name}</td>
                            <td>{data?.organisation_name} - {data?.organisation_code}</td>
                            <td>{data?.bank_name}</td>
                            <td>{data?.bank_account_number}</td>
                            <td>{data?.bank_ifsc_code}</td>
                            <td>{data?.sap_doc_no}</td>
                            <td>
                              {data?.type === "OTV"
                                ? "OTV Payment Request"
                                : data?.type === "NONPO"
                                  ? "Non PO Vendor Payment Request"
                                  : data?.type === "NONREIMB"
                                    ? "Vendor Invoice Reimb below 5K"
                                    : ""}
                            </td>
                            <td>
                              {data?.status === "D" && (
                                <Badge bg="danger">Rejected</Badge>
                              )}
                            </td>
                            <td>{data?.created_by}</td>
                            <td>
                              {moment(data?.created_at).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                          <option value={500} label={500}>500</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={draftPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="start_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      start_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.start_date}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="end_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      end_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.end_date}
                />
              </div>
            </div>
          </div>

          <div class="form-group innergroup">
            <label>Select Type</label>
            <select
              class="form-control newbgselect"
              name="type"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  type: e.target.value,
                })
              }
              value={filterNonOtv.type}
            >
              <option value="">Select</option>
              <option value="OTV">OTV Payment Request</option>
              <option value="NONPO">Non PO Vendor Payment Request</option>
              <option value="NONREIMB">Vendor Invoice Reimb below 5K</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Request Id</label>
            <Select
              isMulti
              options={reqIdList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onRequestId(e)}
              value={reqIdValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Request By</label>
            <Select
              isMulti
              options={reqByNameList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushRequestBy(e)}
              value={reqByNameValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Vendor Name</label>
            <Select
              isMulti
              options={vendorFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushVendorFilter(e)}
              value={vendorFilterValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Invoice No.</label>
            <Select
              isMulti
              options={invoiceFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushInvoiceFilter(e)}
              value={invoiceFilterValue}
            />
          </div>
          <div class="form-group innergroup">
            <label>Expenses Category</label>
            <Select
              options={expenseList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushExpensesFilter(e)}
              value={expenseValue}
            />
          </div>

          {/* <div class="form-group innergroup">
                <label>Select Status</label>
                <select
                  class="form-control newbgselect"
                  name="status"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      status: e.target.value,
                    })
                  }
                  value={filterNonOtv.status}
                >
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Approved">Approved</option>
                </select>
              </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OTVApproval;
