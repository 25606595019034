import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const saveLoan = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/loan/loan`, data);
  };

export const saveSalaryAdvance = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/loan/salary_advance`, data);
  };

export const updateLoan = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/loan/loan/${id}`, data);
};

export const updateSalaryAdvance = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/loan/advance_salary/${id}`, data);
};

export const getUserLoan = (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/loan/loan?${queryParm}`);
}

export const getUserLoan_forApproval = (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/loan/loan_for_approval?${queryParm}`);
}

export const getUser = () => {
  return axios.get(`${AUTH_BASE_URL}/loan/get_user`);
}

export const uploadImages = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/upload`, data);
  };

export const deleteLoan = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/loan/${id}`, data);
};

export const deleteSalaryAdvance = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/loan/advance_salary/${id}`, data);
};

export const getBU = () => {
  return axios.get(`${AUTH_BASE_URL}/loan/get_bu`);
}

export const getApprover = () => {
  return axios.get(`${AUTH_BASE_URL}/loan/get_approver`);
}

export const saveApprover = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/loan/save_approver`, data);
};

export const getApproverMaster = (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/loan/get_approver_master?${queryParm}`);
}

export const updateApprover = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/loan/update_approver/${id}`, data);
};

export const isBUExist = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/loan/approver-exists`, data);
};

export const isLoanExist = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/loan/exists`, data);
};

export const isSalaryExist = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/loan/salary_exists`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
      url: `${AUTH_BASE_URL}/loan/loan_for_approval/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
  });
};

export const getExportValue_loan_report = async (queryParm) => {
  return axios({
      url: `${AUTH_BASE_URL}/loan/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
  });
};