import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidemenu from "../../include/sidemenu";
import Header from "../../include/header";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import * as BrandingCategoryService from "../../../service/brandingCategory";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Formik } from "formik";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import AddBrandingCategory from "./AddBrandingCategory";
import ViewBrandingCategory from "./ViewBrandingCategory";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
if (typeof window !== "undefined") {
  injectStyle();
}

function BrandingCategory() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [brandingCategoryList, setBrandingCategoryList] = useState([]);
  const [brandingCategoryData, setBrandingCategoryData] = useState([]);
  const [brandingCategory, setBrandingCategory] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [allBrandingCategory, setAllBrandingCategory] = useState([]);
  const [dropBrandingCategory, setDropBrandingCategory] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [allOrg, setAllOrg] = useState([]);
  const [brandingCategoryDataForExport, setBrandingCategoryDataForExport] =
    useState({
      brand_category_id: "",
      organisation_id: "",
      status: "",
    });
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [showForm, setShowForm] = useState(false);
  const [brandingCategoryDataFilter, setBrandingCategoryDataFilter] = useState({
    brand_category_id: "",
    organisation_id: "",
    status: "",
  });

  const getBrandCategoryList = (
    searchStr,
    pageNo,
    pageSizeNo,
    sort_by = "id",
    paginate
  ) => {
    BrandingCategoryService.getBrandCategoryList(
      searchStr,
      pageNo,
      pageSizeNo,
      sort_by,
      paginate
    )
      .then((response) => {
        setBrandingCategoryList(response?.data?.dataList?.result);
        setBrandingCategoryData(response?.data?.dataList?.paginated_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getOrganisation = (paginate) => {
    BrandingCategoryService.getOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBrandingCategory = (
    pageNo,
    sort_by,
    searchStr,
    paginate,
    org,
    dept,
    stats
  ) => {
    dispatch(setDisplayLoader("Display"));
    BrandingCategoryService.getBrandingCategory(
      pageNo,
      pageSizeNo,
      sort_by,
      searchStr,
      paginate,
      org,
      dept,
      stats
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setBrandingCategoryList(response?.data?.dataList?.result);
        setBrandingCategoryData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    organisation_id,
    brand_category_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    let queryParm =
      "pageNo=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sortBy=" +
      sortBy +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      brand_category_id !== "" &&
      brand_category_id !== undefined &&
      brand_category_id !== null
    ) {
      queryParm = queryParm + "&brand_category_id=" + brand_category_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    organisation_id,
    brand_category_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    BrandingCategoryService.getExportValue(
      handleKey(
        search,
        organisation_id,
        brand_category_id,
        status,
        pageNo,
        page_size,
        sortBy,
        paginate
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BrandingCategory.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteBrandingCategory = (idx) => {
    // let dataId = { id: idx };
    BrandingCategoryService.deleteBrandingCategory(idx)
      .then((res) => {
        if (res.data.status == true) {
          getBrandingCategory(currentPage, "-id", "", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    confirmAlert({
      title: "Delete Branding Category",
      message: `Are you sure to delete ${deptName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBrandingCategory(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addBrandingCategory = () => {
    setShowForm(true);
    setEditMode(false);
    setBrandingCategory(null);
  };
  const editbrandingCategory = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setBrandingCategory(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setBrandingCategory(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getBrandingCategory(currentPage, "-id", "", true);
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getBrandingCategory(1, "-id", e.target.value.toLowerCase(), true);
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getBrandCategoryList(data, 1, pageSizeNo, sortData.sort_by, true);
    }
  };

  // const getNextPrevPage = (action) => {
  //   let activePage = currentPage;
  //   if (action === 1) {
  //     if (activePage <= brandingCategoryData.totalPages - 1) {
  //       activePage = activePage + 1;
  //       setCurrentPage(activePage);
  //       getBrandingCategory(activePage, "-id", searchStr, true);
  //     }
  //   } else {
  //     if (activePage > 1) {
  //       activePage = activePage - 1;
  //       getBrandingCategory(activePage, "-id", searchStr, true);
  //       setCurrentPage(activePage);
  //     }
  //   }
  // };
  // const handlePageClick = (action) => {
  //   let activePage = action.selected + 1;
  //   setCurrentPage(activePage);
  //   getBrandCategoryList(
  //     searchStr,
  //     brandingCategoryDataFilter.organisation_id,
  //     brandingCategoryDataFilter.brand_category_id,
  //     brandingCategoryDataFilter.status,
  //     activePage,
  //     pageSizeNo,
  //     "id",
  //     true
  //   );
  // };
  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBrandingCategory(
      activePage,
      sortData.sort_by,
      searchStr,
      true,
      brandingCategoryDataFilter.organisation_id,
      brandingCategoryDataFilter.brand_category_id,
      brandingCategoryDataFilter.status
    );
  };

  useEffect(() => {
    getBrandingCategory(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      brandingCategoryDataFilter.organisation_id,
      brandingCategoryDataFilter.brand_category_id,
      brandingCategoryDataFilter.status
    );
  }, [pageSizeNo]);

  const getModule = (paginate) => {
    BrandingCategoryService.getModule(paginate)
      .then((response) => {
        setAllBrandingCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllBrandingCategory = (paginate) => {
    BrandingCategoryService.getAllBrandingCategory(paginate)
      .then((response) => {
        setDropBrandingCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    getOrganisation(false);
    getAllBrandingCategory(false);
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const rendePolicyOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const mainDataFilter = (values) => {
    setBrandingCategoryDataForExport({
      ...brandingCategoryDataForExport,
      brand_category_id: values.brand_category_id,
      organisation_id: values.organisation_id,
      status: values.status,
    });
    const org = values.organisation_id;
    const dept = values.brand_category_id;
    const stats = values.status;
    getBrandingCategory(1, "-id", "", true, org, dept, stats);
  };
  const handleShow = (e) => {
    getBrandingCategory(1, "-id", searchStr.toLowerCase(), true);
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getBrandCategoryList(searchStr, 1, pageSizeNo, sdata.sort_by, true);
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Branding Category</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Branding Category</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on <strong> Category Name</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Category Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />{" "}
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              brandingCategoryDataForExport.organisation_id,
                              brandingCategoryDataForExport.brand_category_id,
                              brandingCategoryDataForExport.status,
                              1,
                              pageSizeNo,
                              "-id",
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">
                            <i className="far fa-file-pdf"></i>Pdf
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-copy"></i>Copy
                          </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addBrandingCategory();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Category Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("organisation__name");
                  }}
                  className={
                    sortData["organisation_name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["organisation_name"]
                  }
                >
                  Organisation Name & Code
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {brandingCategoryList?.length ? (
                brandingCategoryList?.map((data, index) => (
                  <>
                    <tr key={`brandingCategory${index}`}>
                      <td className="text-theme fw-bold">{data?.name}</td>

                      <td>
                        {data?.organisation_name} &nbsp;(
                        {data?.organisation_code})
                      </td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {" "}
                        {moment
                          .utc(data?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? (
                        <td>
                          {finalUrlValue?.V ? (
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              <img src="images/eye.png" alt="" />
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.E ? (
                            <button
                              className="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editbrandingCategory(data);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.R ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {brandingCategoryList?.length ? (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={handleShow}
                  style={{ textAlign: "center" }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Formik
        initialValues={brandingCategoryDataFilter}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <label>Select Organization</label>
                  <select
                    className="form-control main-pointer"
                    classNamePrefix="select"
                    style={{ width: "334px", cursor: "pointer" }}
                    name="organisation_id"
                    onChange={(e) => {
                      handleChange(e);
                      setBrandingCategoryDataFilter({
                        ...brandingCategoryDataFilter,
                        organisation_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" label="Select Organization" />

                    {renderOrgOptions(allOrg)}
                  </select>
                </div>
                <div class="form-group innergroup">
                  <label>Select Branding Category</label>
                  <select
                    className="form-control"
                    classNamePrefix="select"
                    style={{ width: "334px", cursor: "pointer" }}
                    name="brand_category_id"
                    onChange={(e) => {
                      handleChange(e);
                      setBrandingCategoryDataFilter({
                        ...brandingCategoryDataFilter,
                        brand_category_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" label="Select Branding Category" />
                    {rendePolicyOptions(brandingCategoryList)}
                  </select>
                </div>
                <div class="form-group innergroup">
                  <label>Select Status</label>
                  <select
                    class="form-control innergroup"
                    style={{ width: "334px", cursor: "pointer" }}
                    name="status"
                    onChange={(e) => {
                      handleChange(e);
                      setBrandingCategoryDataFilter({
                        ...brandingCategoryDataFilter,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={filterclose}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-inner bpi-main"
                  onClick={filterclose}
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>

      {showForm ? (
        <>
          <AddBrandingCategory
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editbrandingCategory={brandingCategory}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewBrandingCategory
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default BrandingCategory;
