import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown
} from "react-bootstrap";
import PrAddUnit from "./pr-add-unit";
import ReactPaginate from "react-paginate";
import * as PrService from "../../service/prService";
import moment from "moment";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

function PRUnitMaster() {
  const [show, filtersetShow] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({ sort_by: 'id' });
  const [searchStr, setSearchStr] = useState("");
  const [addnewshow, addnewsetShow] = useState(false);
  const [filterState, setFilterState] = useState({});

  const [currentPRUnit, setCurrentPRUnit] = useState(null) /* handling current prUnit whenever modal refreshes */


  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const handleSearch = (e) => {
    getUnitMasters(
      e.target.value.toLowerCase(),
      filter,
      1,
      pageSizeNo,
      "id",
      true
    );
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getUnitMasters(
      searchStr,
      filter,
      activePage,
      pageSizeNo,
      "id",
      true
    );
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: 'Confirm to proceed',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await PrService.deletePrUnit(id)
              .then((response) => {
                if (response?.data?.code === 200) {
                  getUnitMasters(
                    "",
                    filter,
                    currentPage,
                    pageSizeNo,
                    filter.sort_by,
                    true
                  );
                  toast.success(response?.data?.message)
                }
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No',
          onClick: () => console.log('bye')
        }
      ]
    });
  };

  const handleKey = (search, filter, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      for (const key in filter) {
        queryParm += `&${key}=${filter[key]}`
      }
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getUnitMasters = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    await PrService.getPrUnits(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditPRUnit = item => {
    setCurrentPRUnit(item);
    addnewopen()
  }

  const handleApplyFilter = () => {
    setFilter(pre => ({ ...pre, ...filterState }));
    getUnitMasters(
      searchStr,
      filterState,
      1,
      pageSizeNo,
      filter.sort_by,
      true
    );
    filterclose();
  }

  const handleClearFilter = () => {
    setFilter({ sort_by: 'id' });
    setFilterState({});
    filterclose();
    getUnitMasters(
      searchStr,
      {},
      1,
      pageSizeNo,
      filter.sort_by,
      true
    );
  }

  const getExportDetails = () => {
    PrService.getExportValueUnitMaster(
      handleKey(searchStr, filter, currentPage, pageSizeNo, filter.sort_by, true)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "UnitMaster.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(error.response &&
          error.response.data &&
          error.response.data.message ||
          error.message);
        console.log('error', error)
      });
  };

  const refreshData = () => {
    getUnitMasters(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true
    );
  }

  useEffect(() => {
    getUnitMasters(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true
    );
  }, [pageSizeNo]);


  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="backleftlist">
              <Link to="/pr-list">
                <span>
                  <i class="fas fa-arrow-alt-circle-left f-12"></i> Back
                </span>
              </Link>
            </li>
            <li class="active">
              <Link to="/pr-unit-master">Unit Master</Link>
            </li>
         
            <li>
              <Link to="/pr-group-master">Purchasing Group Master</Link>
            </li>
            <li>
              <Link to="/pr-org-master">Purchasing Organization Master</Link>
            </li>
            <li>
              <Link to="/pr-type-master">Type Master</Link>
            </li>
            <li>
              <Link to="/pr-user-master">User Authorization Master</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Unit Master</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>Unit Master</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item href="#">
                                                    <i class="fas fa-print"></i>Print
                                                </Dropdown.Item> */}
                        {/* <Dropdown.Item href="#">
                                                    <i class="far fa-file-alt"></i>CSV
                                                </Dropdown.Item> */}
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#">
                                                    <i class="far fa-file-pdf"></i>Pdf
                                                </Dropdown.Item> */}
                        {/* <Dropdown.Item href="#">
                                                    <i class="far fa-copy"></i>Copy
                                                </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>PR Unit Code</th>
                    <th>PR Unit Description</th>
                    <th>Updated On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    list.map(item => {
                      return (
                        <tr key={item.id}>
                          <td class="text-theme fw-bold">{item.unit_code}</td>
                          <td>{item.unit_desc}</td>
                          <td>
                            {moment
                              .utc(item?.updated_at)
                              .format("Do MMM YYYY, h:mm:ss A")}
                          </td>
                          {item?.is_active == true ? (
                            <td>
                              <span class="badge bad-status badge-success">
                                Active
                              </span>
                            </td>
                          ) : (
                            <td>
                              <span class="badge bad-status badge-danger">
                                InActive
                              </span>
                            </td>
                          )}
                          <td>
                            <button onClick={() => handleEditPRUnit({ ...item /* do not remove spread, because useEffect of modal component wont trigger if reference don't change */ })} class="btn btn-blanktd text-primary">
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2" onClick={() => handleDelete(item.id)}>
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

            <div class="row">
              <div className="col-sm-5">
                <div className="sortinglist">
                  Show
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>10</option>
                    <option value={20} label={20}>20</option>
                    <option value={30} label={30}>30</option>
                    <option value={100} label={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              PR Unit Code<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter PR Unit Code"
              value={filterState.unit_code}
              onChange={e => setFilterState(pre => ({ ...pre, unit_code: e.target.value }))}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              PR Unit Description<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter PR Unit Description"
              value={filterState.unit_desc}
              onChange={e => setFilterState(pre => ({ ...pre, unit_desc: e.target.value }))}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger"></span>
            </label>
            <select class="form-control newbgselect"
              value={filterState.is_active}
              onChange={e => setFilterState(pre => ({ ...pre, is_active: e.target.value }))}
            >
              <option >Select</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={handleClearFilter}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={handleApplyFilter}>Apply</button>
        </Modal.Footer>
      </Modal>

      <PrAddUnit refreshData={refreshData} addnewshow={addnewshow} addnewclose={addnewclose} currentPRUnit={currentPRUnit} />
    </>
  );
}

export default PRUnitMaster;
