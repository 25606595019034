import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getLeaveManageList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess?${queryParm}`);
};
export const getLeaveManageListUser = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/ess?${query}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
};
export const getAllBranch = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?paginate=${paginate}`);
};
export const getLeaveManageListData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess?${queryParm}`);
};
export const getLeaveAllocation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess/allocation?${queryParm}`);
};
export const getUserDetails = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-user-data?user_id=${user_id}`);
};
export const getLeaveManageData = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/ess?${query}`);
};
export const getLeaveManageDataList = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/ess/list_leave_type/${id}`);
};
export const getReason = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/ess/list_reason/${id}`);
};

export const getLeaveManageReasonList = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/ess/list_reason/${id}`);
};

export const getHolidayList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/holiday_calendar?paginate=${paginate}`);
};

export const getHolidayListByUser = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/holiday_calendar?${queryParam}`);
};

export const getUserHolidays = async (from, to) => {
  return axios.get(`${AUTH_BASE_URL}/holiday_calendar/user_holiday?fromDate=${from}&toDate=${to}`);
};

export const getBranchFormList = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  org,
  dept,
  stats
) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?search=${search}${org == undefined || org == "" || org == null
      ? ""
      : `&organisation_id=${org}`
    }${dept == undefined || dept == "" || dept == null
      ? ""
      : `&department_id=${dept}`
    }${stats == undefined || stats == "" || stats == null
      ? ""
      : `&status=${stats}`
    }&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};
export const deleteLeaveManage = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/${id}`,
    method: "DELETE",
  });
};
export const deleteLeaveAllocate = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/essallocation/${id}`,
    method: "DELETE",
  });
};

export const deleteApplyLeave = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/ess/leave/${id}`);
};

export const getBusinessList = async (paginate, organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?paginate=${paginate}&organisation_id=${organisation_id}&sort_by=name`
  );
};

export const saveLeaveManage = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess`, data);
};
export const updateLeaveManage = async (id, data) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/${id}`,
    method: "PUT",
    data,
  });
};

export const isBranchExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/ess/exists`, isData);
};

export const saveLeaveAllocation = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/allocation`, data);
};

export const getLeaveRules = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ess/getRules?${queryParam}`);
};

export const updateLeaveRule = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/ess/rule/${data?.id}`, data);
};

export const deleteLeaveRule = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/ruleDel/${id}`,
    method: "DELETE",
  });
  // return axios.delete(`${AUTH_BASE_URL}/ess/rule/${id}`);
};

export const updateLeaveAllocationDays = async (id, data) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/allocation/${id}`,
    method: "PUT",
    data,
  });
};

export const saveApplyLeave = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/apply_leave`, data);
};

export const getLeaveDataList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess/leave?${queryParm}`);
};

export const updateApplyLeave = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/ess/update_leave/${id}`, data);
};
export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getApprovalLeaveDataList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess/approval_leave?${queryParm}`);
};

export const getEmployeeDataList = async (bussiness_unit_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${bussiness_unit_id}`
  );
};

export const approveApplyLeave = async (id, status) => {
  return axios.get(
    `${AUTH_BASE_URL}/ess/approve_decline_leave/${id}?status=${status}`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/leave/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportDetail = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/approval_leave/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getUserLeaveSummary = async (id, year, value) => {
  let userId = ''
  if (id) {
    userId = id
  }
  let url = AUTH_BASE_URL + "/ess/user_leave_summary?user_id=" + userId
  if (value !== "") {
    url = url + "&approval=" + true
  }
  if (year !== "") {
    url = url + "&year=" + year
  }
  return axios.get(url);
};

export const getLeaveById = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/ess/getLeave/${id}`);
};

export const changeLeaveStatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/leaveStatus`, data);
};

export const getRulesForUser = async () => {
  return axios.get(`${AUTH_BASE_URL}/ess/userRule`);
};

export const saveBackDateAlloc = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/backDate`, data);
};

export const getBackDatesEntries = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ess/getBackDates?${queryParam}`);
};

export const getBackDatedExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/getBackDates/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const checkExist = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/checkExist`, data);
};

export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/bulk_import_leave?leave_code=${data?.leave_code}`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const getLeaveImportDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/ess/ess_import_details`);
};

export const getBulkDataList = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/ess/get_bulk_leave_data?${queryParam}`);
};

export const getBulkDataListExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ess/get_bulk_leave_data/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const syncUserLeaveSavior = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/ess/sync_user_leave`, data);
};

export const getPersonalSubArea = async (branch_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/ess/sub-personal-area?branch_id=${branch_id}`
  );
};

export const removeBackDateEntry = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/ess/backDate/${id}`);
};

export const repostLeave = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/ess/repost_leave?id=${id}`);
};