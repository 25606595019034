import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import CompanyDocForm from "./companyDocForm";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getCompanyCode } from "../../service/jv_service";
import { confirmAlert } from "react-confirm-alert";
import { deleteGST, getGST } from "../../service/gst";
import { getStateList } from "../../service/state";
import { ToastContainer } from "react-toastify";
function CompanyDoc() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();

  const options = [
    { value: "Capex", label: "Capex" },
    { value: "Engineering", label: "Engineering" },
    { value: "RM/PM", label: "RM/PM" },
  ];
  const [approvalMast, setApprovalMast] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [edit, setedit] = useState(false);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [editData, setEditData] = useState({});
  const [filterdata, setfilterData] = useState({
    company_id: { value: 0 },
    state_id: { value: 0 },
  });
  const resetFilterData = () =>
    setfilterData({
      company_id: { value: 0 },
      state_id: { value: 0 },
    });

  const getGSTData = (
    company_id,
    state_id,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    getGST(company_id, state_id, page_no, page_size, sort_by, paginate, "doc")
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(response?.data?.dataList?.result);
        setApprovalMast(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getGSTData(
      filterdata?.company_id?.value,
      filterdata?.state_id?.value,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
  };
  const getCompanyCodes = () => {
    getCompanyCode()
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.name + "(" + x?.code + ")" };

          plant.push(data);
        });
        setCompanyCode(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getState = () => {
    getStateList("paginate=false")
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.name };
          plant.push(data);
        });
        setStateList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getGSTData(0, 0, currentPage, pageSizeNo, "-id", true);
    getCompanyCodes();
    getState();
  }, []);

  const handleChange = (event, name) => {
    console.log(event, name);
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "company":
        setfilterData((prev) => ({
          ...prev,
          company_id: event,
        }));
        break;
      case "state":
        setfilterData((prev) => ({
          ...prev,
          state_id: event,
        }));
        break;
    }
  };

  const mainDataFilter = () => {
    getGSTData(
      filterdata?.company_id?.value,
      filterdata?.state_id?.value,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );

    filterclose();
  };
  const resetFilter = () => {
    filterclose();
    resetFilterData();
    getGSTData(0, 0, currentPage, pageSizeNo, "-id", true);
  };
  const DeleteApprover = (approverid) => {
    console.log(approverid);
    let data = { id: approverid };
    deleteGST(approverid)
      .then((response) => {
        getGSTData(
          filterdata?.company_id?.value,
          filterdata?.state_id?.value,
          currentPage,
          pageSizeNo,
          "-id",
          true
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deletapprover = (id) => {
    confirmAlert({
      title: "Delete GST Certificate ",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const design = {
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/gst">Add GST Certificate</Link>
            </li>
            <li class="active">
              <Link to="/companydoc">Add Company Documents</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Add Company Documents</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A ? (
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          setedit(false);
                          addnewopen();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Company Documents
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          setedit(false);
                          addnewopen();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Company Documents
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    {finalUrlValue?.E || finalUrlValue?.R ? (
                      <th>Actions</th>
                    ) : (
                      <th>Actions</th>
                    )}
                    <th>COMPANY CODE</th>
                    <th>STATE</th>
                    <th>DOCUMENTS NO.</th>
                    <th>REMARKS</th>
                  </tr>
                </thead>
                <tbody>
                  {approvalMast.map((x) => (
                    <tr>
                      {finalUrlValue?.E || finalUrlValue?.R ? (
                        <td>
                          {finalUrlValue?.E ? (
                            <button
                              onClick={(e) => {
                                setEditData(x);
                                setedit(true);
                                addnewopen();
                              }}
                              class="btn btn-blanktd text-primary ml-2 f-12"
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.R ? (
                            <button
                              onClick={() => deletapprover(x?.id)}
                              class="btn btn-blanktd text-danger ml-2 f-12"
                              type="button"
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <>
                          <td>
                            <button
                              onClick={(e) => {
                                setEditData(x);
                                setedit(true);
                                addnewopen();
                              }}
                              class="btn btn-blanktd text-primary ml-2 f-12"
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              onClick={() => deletapprover(x?.id)}
                              class="btn btn-blanktd text-danger ml-2 f-12"
                              type="button"
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                        </>
                      )}
                      <td>
                        {x?.company_name}
                        {/* <br />({x?.requester_username}) */}
                      </td>
                      <td>{x?.state_name}</td>
                      <td>{x?.gst_no}</td>
                      <td style={design}>{x?.billing_address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Company Code</label>
            <Select
              options={companyCode}
              className="basic-multi-select"
              classNamePrefix="select"
              name="company"
              value={filterdata?.company_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>State Code</label>
            <Select
              options={stateList}
              className="basic-multi-select"
              classNamePrefix="select"
              name="state"
              value={filterdata?.state_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Clear
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {addnewshow && (
        <CompanyDocForm
          show={addnewshow}
          edit={edit}
          getList={() =>
            getGSTData(
              filterdata?.company_id?.value,
              filterdata?.state_id?.value,
              currentPage,
              pageSizeNo,
              "-id",
              true
            )
          }
          close={addnewclose}
          open={addnewopen}
          editData={editData}
        ></CompanyDocForm>
      )}
    </>
  );
}

export default CompanyDoc;
