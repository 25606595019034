import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab  } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";


function TravelFcmExpReport() { 

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const options = [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' }
    ]
    

    const [show, filtersetShow] = useState(false); 
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" }); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [searchStr, setSearchStr] = useState("");
    const [ticketDetails, setTicketDetails]  = useState([]);
    const [usersDropdown, setUsersDropdown] = useState([]);


    const [filterdata, setfilterData] = useState({
        user_id: {value:undefined},
        trip_from: "",
        trip_to: "",
        from_date: "",
        to_date: "",
        search_by:""
        
      });


      const handleKey = (search, page_no, page_size, sort_by, paginate, status, 
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date,search_by) => {
        let queryParm =
          "page_no=" +
          page_no +
          "&page_size=" +
          page_size +
          "&paginate=" +
          paginate;
          if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
            queryParm = queryParm + "&sort_by=" + sort_by;
          }
          if (search_by !== "" && search_by !== undefined && search_by !== null) {
            queryParm = queryParm + "&search_by=" + search_by;
          }
        if (search !== "" && search !== undefined && search !== null) {
          queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
          queryParm = queryParm + "&status=" + status;
        }
    
    
        if (user_id !== "" && user_id !== undefined && user_id !== null) {
          queryParm = queryParm + "&user_id=" + user_id;
        }
        if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
          queryParm = queryParm + "&trip_from=" + trip_from;
        }
        if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
          queryParm = queryParm + "&trip_to=" + trip_to;
        }
        if (from_date !== "" && from_date !== undefined && from_date !== null) {
          queryParm = queryParm + "&from_date=" + from_date;
        }
        if (to_date !== "" && to_date !== undefined && to_date !== null) {
          queryParm = queryParm + "&to_date=" + to_date;
        }
        
    
        return queryParm;
      };



      
  const handleSearch = (e) => {
    
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      
    getTravelFCM(
        e.target.value,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
            "","","","",""
        )

    
    setSearchStr(e.target.value);
    }
  };

      const mainDataFilter = () => {
        console.log(" filterdata:", filterdata);
        getTravelFCM(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
        filterdata?.user_id?.value,
          filterdata?.trip_from,
          filterdata?.trip_to,
          filterdata?.from_date,
          filterdata?.to_date,
          filterdata?.search_by
            )
        
        // getTravelPickList(
        //   searchStr,
        //   currentPage,
        //   pageSizeNo,
        //   sortData.sort_by,
        //   true,
        //   "",
        //   filterdata?.user_id,
        //   filterdata?.company,
        //   filterdata?.trip_number,
        //   filterdata?.from_date,
        //   filterdata?.to_date,
        //   true
          
        //   )
      
        filterclose();
      };
      
      
      const clearfilter = () => {
        filterclose();
        // getTravelPickList(
        //   searchStr,
        //   currentPage,
        //   pageSizeNo,
        //   sortData.sort_by,
        //   true,
        //   "",
        //    "","","","","",false
        // );
        getTravelFCM(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
        setfilterData({
            user_id: {value:undefined},
          from_date: "",
          to_date: "",
          trip_from:"",
          trip_to:"",
          search_by:""
    
        });
        
      };
    
      const getUsersList = () => {
        UserService.getUsersListDataNew(0).then((res) => {
          // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.id,
                label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
              };
            }),
          ];
          setUsersDropdown(data);
          
        });
      };



      const getTravelFCM = (
        search, page_no, page_size, sort_by, paginate, status, 
                user_id,
                trip_from,
                trip_to,
                from_date,
                to_date,search_by=""
    ) =>{
        dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getTravelFCM(
            handleKey(search, page_no, page_size, sort_by, paginate, status, 
                user_id,
                trip_from,
                trip_to,
                from_date,
                to_date,search_by)
        ).then((response)=>{
            dispatch(setDisplayLoader("Hide"));
            setTicketDetails(response?.data?.dataList?.result)
              setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }).catch((err)=>{
            toast.error("Unable to fetch, "+err?.response?.data?.message)
        })
    }
    
    
    
    
    const getTravelFCMExport = (
        search, page_no, page_size, sort_by, paginate, status, 
                user_id,
                trip_from,
                trip_to,
                from_date,
                to_date
    ) =>{
        dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getTravelFCMExport(
            "is_export=true"
        ).then((response)=>{
            dispatch(setDisplayLoader("Hide"));
    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "FCM_Ticket_Report.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
    
            // setTravelBills(response?.data?.dataList?.result)
            //   setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        })
    }
    

    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);

          getTravelFCM(searchStr,
            activePage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
      };
    
      useEffect(()=>{
        getUsersList()
        getTravelFCM(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
    },[pageSizeNo])
    
    
    return (
        <>
            <div class="container-fluid">
                        <div class="row">
                                {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li class="active"><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

                            <div class="col-md-12">
                                <div class="content-wrapper-inner content-wrapper-inner2">
                                    <div class="innerheadsec">
                                        <div className="row">
                                            <div class="col-md-12">
                                                <h4 class="inner-page-title">FCM Ticket Expense Report</h4>
                                            </div> 
                                            <div class="col-md-12">
                                                <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-lg-4">
                                                <div class="input-group table-search">
                                                    <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                                    <input type="text" class="form-control" placeholder="Search..." 
                                                    onChange={(e) => {
                                                        handleSearch(e);
                                                      }}
                                                    /> 
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="inline-spacing btn-betweenspaing">
                                                    <Dropdown show>
                                                        <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                            <img src="images/export.png" alt="" class="mr-2" /> Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#"
                                                            onClick={() =>
                                                                
                                                                getTravelFCMExport(searchStr,
                                                                    1,pageSizeNo,sortData.sort_by,true,"","","","","","")
                                                            }
                                                            ><i class="far fa-file-excel"></i>Excel</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered tablecured">  
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Employee ID</th>
                                                    <th>Employee Name</th>
                                                    <th>Employee Email</th>
                                                    <th>Emp Vendor Code</th>
                                                    <th>Location</th>
                                                    <th>SAP Trip ID</th>
                                                    <th>BOARDING ON</th>
                                                    <th>TICKET BETWEEN</th>
                                                    <th>Invoice No</th>
                                                    <th>TICKET BY</th>
                                                    <th>BILL AMT</th>
                                                    <th>OTHER CHARGES</th>
                                                    <th>TAX AMT</th>
                                                    <th>TOTAL CLAIM</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                                {
                                                    ticketDetails?.length>0 ?
                                                
                                                    ticketDetails.map((data,idx)=>(<tr> 
                                                    <td>{idx+1}</td>
                                                    <td class="fw-bold text-theme">{data?.user_code}</td>
                                                    <td>{data?.username}</td>
                                                    <td>{data?.user_email}</td>
                                                    <td>{data?.user_vendor_code}</td>
                                                    <td>{data?.Location}</td>
                                                    <td><Link to="/">{data?.sap_trip}</Link></td>
                                                    <td>{data?.travel_date?moment.parseZone(data?.travel_date).format("DD-MM-YYYY"):""}</td>
                                                    <td>
                                                        {/* {data?.from_city_name=="Other"?
                                                        data?.from_city_input:data?.from_city_name    }-
                                                        {data?.to_city_name=="Other"?
                                                        data?.to_city_input:data?.to_city_name   } */}

                                                        {data["TICKET BETWEEN"]}
                                                        
                                                    </td>
                                                    <td>{data?.invoice_no??""}</td>
                                                    <td>{data?.paid_by}</td>
                                                    <td>{data?.tax_bill_amount?.toFixed(2)}</td>
                                                    <td>{data?.other_charges}</td>
                                                    <td>{data?.tax_amount}</td>
                                                    <td>{data["TOTAL CLAIM"]}</td>
                                                    <td>{data?.sap_status}</td> 
                                                </tr>))
                                                :"No records found"}
                                                
                                            </tbody>
                                        </table>

                                    </div> 

                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="sortinglist">
                                                Show
                                                <select 
                                                    class="form-control"
                                                    onChange={(e) => {
                                                        setPageSizeNo(e.target.value);
                                                        setCurrentPage(1);
                                                      }}
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                        <ReactPaginate
                                            key={pageSizeNo}
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={handlePageClick}
                                            disableInitialCallback={true}
                                            containerClassName={"pagination justify-content-left"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                        </div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
            </div>

    

            <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                        
                   
                        
                <div class="form-group innergroup">
                        <label>Employee ID  <span class="text-danger"></span></label>
                        <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                                ...filterdata,
                                user_id: e,
                              }) 
                                  
                        }
                        value={filterdata?.user_id}
                        />
                        
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip Start Date  <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        from_date:e.currentTarget.value
                        })}
                        value={filterdata.from_date}/>
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip End Date <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        to_date:e.currentTarget.value
                        })}
                        value={filterdata.to_date}/>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <button 
                    class="btn btn-outline-danger"
                     onClick={() => clearfilter()}
                    //  onClick={filterclose}
                    >
                        Cancel</button>
                    <button 
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => mainDataFilter()}
                    >Apply</button>
                </Modal.Footer>
            </Modal>

            

        </>
    );
}

export default TravelFcmExpReport;