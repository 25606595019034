import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModularTable from "./../ModularTable";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import styles from "./Table.module.css";
import {
  getFieldWork,
  getAllFields,
  getFieldPlanApprovalLogs,
  getLastFieldPlan,
  getTotalDemos,
  postFieldWork,
  postFieldWorkAction,
  putFieldWork,
} from "../../../../service/AgriPortalService/FieldWorkService";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [fields, setFields] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [updatedTableData, setUpdatedTableData] = useState([[]]);

  const [mode, setMode] = useState();

  const [freezeCTA, setFreezeCTA] = useState(false);
  const [loader, setLoader] = useState(false);
  const [approvalList, setApprovalList] = useState([]);

  const [totalDemos, setTotalDemos] = useState([]);
  const [locationData, setLocationData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cfy, setCfy] = useState("");

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getFieldWork()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => {
    getCurrentFy()
      .then((res) => setCfy(res.data.dataList.fy))
      .catch((err) => console.log(err));

    getAllFields()
      .then((res) => {
        setFields(res?.data?.dataList);
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoader(false);
      });

    getTotalDemos()
      .then((res) => setTotalDemos(res?.data?.dataList))
      .catch((err) => {
        if (err.response.data.code == 409) {
          toast.error(err?.response?.data?.detail);
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/fieldwork";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mode]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    const totals = [{ value: "Total" }];

    for (let i = 0; i < data?.length; i++) {
      let total = 0;
      for (let j = 1; j < data[i].length - 1; j++) {
        if (data[i][j].value[0] == "") {
          total += 0
        } else {
          total += parseInt(data[i][j].value[0]);
        }
      }
      data[i][data[i]?.length - 1] = {
        value: [total],
        meta: {}
      };
    }

    setUpdatedTableData(data);
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;

    let totalDemoData = [];
    totalDemos.map((ele) => {
      totalDemoData.push({
        id: 0,
        month_id: ele.id,
        value: ele.total_demos,
        cost: ele.total_cost,
      });
    });
    ans.push({
      field_work_id: 1,
      month_data: totalDemoData,
    });
    data.forEach((row) => {
      if (row[0].value != "Total No. of Demos") {
        let month = [];

        for (let j = 1; j < row?.length - 1; j++) {
          month.push({
            id: 0,
            month_id: data[0][j]?.meta?.raw?.id,
            value: row[j]?.value[0] == "" ? "0" : row[j]?.value[0],
            cost: 0,
          });
        }

        ans.push({
          field_work_id: row[0]?.meta?.raw?.id,
          month_data: month,
        });
      }
    });
    return ans;
  };

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));
    let query = "";
    if (params.get("mode") != "create") {
      query = "?field_plan_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getFieldPlanApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }

    // if (params.get("mode") != "create")
    dispatch(setDisplayLoader('Display'))
    getLastFieldPlan(query)
      .then((res) => {
        dispatch(setDisplayLoader('Hide'))
        setRawData(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setDisplayLoader('Hide'))
        if (err.response.data.code == 409) {
          toast.error("Field work already exists!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        }
      });
  }, []);

  //Set table data after past data is fetched
  useEffect(() => {
    // if(!rawData.category_data) return;

    setTableHeadData((state) => {
      // if(!rawData) return [];

      let headData;

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Month",
            type: "T",
            isEditable: false,
          },
          {
            name: "Apr",
            type: "N",
            isEditable: true,
          },
          {
            name: "May",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jun",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jul",
            type: "N",
            isEditable: true,
          },
          {
            name: "Aug",
            type: "N",
            isEditable: true,
          },
          {
            name: "Sep",
            type: "N",
            isEditable: true,
          },
          {
            name: "Oct",
            type: "N",
            isEditable: true,
          },
          {
            name: "Nov",
            type: "N",
            isEditable: true,
          },
          {
            name: "Dec",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jan",
            type: "N",
            isEditable: true,
          },
          {
            name: "Feb",
            type: "N",
            isEditable: true,
          },
          {
            name: "Mar",
            type: "N",
            isEditable: true,
          },
          {
            name: "Total",
            type: "N",
            isEditable: false,
          },
        ];

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Month",
            type: "T",
            isEditable: false,
          },
          {
            name: "Apr",
            type: "N",
            isEditable: false,
          },
          {
            name: "May",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jun",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jul",
            type: "N",
            isEditable: false,
          },
          {
            name: "Aug",
            type: "N",
            isEditable: false,
          },
          {
            name: "Sep",
            type: "N",
            isEditable: false,
          },
          {
            name: "Oct",
            type: "N",
            isEditable: false,
          },
          {
            name: "Nov",
            type: "N",
            isEditable: false,
          },
          {
            name: "Dec",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jan",
            type: "N",
            isEditable: false,
          },
          {
            name: "Feb",
            type: "N",
            isEditable: false,
          },
          {
            name: "Mar",
            type: "N",
            isEditable: false,
          },
          {
            name: "Total",
            type: "N",
            isEditable: false,
          },
        ];

      return headData;
    });

    setTableData((state) => {
      let newTableData = [];
      // let val1=0,val2=0,val3=0,val4=0,val5=0;
      for (let i = 0; i < fields?.length; i++) {
        newTableData.push([
          {
            value: [fields[i]?.name],
            meta: {
              raw: fields[i],
            },
          },
        ]);
      }

      const months = [
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
      ];
      for (let i = 0; i < newTableData.length; i++) {
        newTableData[i] = [
          ...newTableData[i],
          ...[
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
          ],
        ];
      }
      const params = new URLSearchParams(window.location.search);
      if (!newTableData || newTableData.length == 0) return;
      if (params.get("mode") == "create") {
        let demoTotal = 0;
        for (let k = 0; k < totalDemos?.length; k++) {
          demoTotal += totalDemos[k]?.total_demos;
          newTableData[0][k + 1].value[0] = totalDemos[k]?.total_demos;
          newTableData[0][k + 1].meta.raw = totalDemos[k];
        }
        newTableData[0][13].value[0] = demoTotal;
      } else if (params.get("mode") == "edit") {
        let demoTotal = 0;
        for (let k = 0; k < totalDemos?.length; k++) {
          demoTotal += totalDemos[k]?.total_demos;
          newTableData[0][k + 1].value[0] = totalDemos[k]?.total_demos;
          newTableData[0][k + 1].meta.raw = totalDemos[k];
        }
        newTableData[0][13].value[0] = demoTotal;
        if (!rawData || rawData?.field_data?.length == 0) return newTableData;

        for (let i = 0; i < rawData?.field_data?.length; i++) {
          if (rawData?.field_data[i]?.field_work_name == "Total No. of Demos")
            continue;
          const idx = newTableData.findIndex(
            (el) => el[0]?.value[0] == rawData?.field_data[i]?.field_work_name
          );
          if (idx == -1 || idx == 0) continue;
          let curTotal = 0;
          for (let j = 0; j < rawData?.field_data[i]?.month_data?.length; j++) {
            newTableData[idx][
              parseInt(rawData?.field_data[i]?.month_data[j]?.month_id)
            ].value[0] = rawData?.field_data[i]?.month_data[j]?.value;
            curTotal += rawData?.field_data[i]?.month_data[j]?.value;
          }
          newTableData[idx][newTableData[idx]?.length - 1].value[0] = curTotal
        }
      } else {
        if (!rawData || rawData?.field_data?.length == 0) return newTableData;

        for (let i = 0; i < rawData?.field_data?.length; i++) {
          const idx = newTableData.findIndex(
            (el) => el[0]?.value[0] == rawData?.field_data[i]?.field_work_name
          );

          if (idx == -1) continue;
          let curTotal = 0;
          for (let j = 0; j < rawData?.field_data[i]?.month_data?.length; j++) {
            newTableData[idx][
              parseInt(rawData?.field_data[i]?.month_data[j]?.month_id)
            ].value[0] = rawData?.field_data[i]?.month_data[j]?.value;
            curTotal += rawData?.field_data[i]?.month_data[j]?.value;
          }
          newTableData[idx][newTableData[idx]?.length - 1].value[0] = curTotal;
        }
      }

      return newTableData;
    });
  }, [rawData, fields, totalDemos]);

  const verifyChecks = (data) => {
    if (!data) return false;
    let totalDemo = 0;
    let totalRows = 0;
    for (let i = 0; i < 4; i++) {
      for (let j = 1; j < 13; j++) {
        if (i == 0) {
          totalDemo += parseInt(data[i][j].value[0]);
        } else {
          if(data[i][0].value == "No. of Demos (FA)" || data[i][0].value == "No. of Demos (TI)-J" || data[i][0].value == "No. of Demos (FA/ ET/MT)-J"){
            totalRows += parseInt(data[i][j].value[0]);
          }         
        }
      }
    }
    if (totalDemo == totalRows) {
      return true;
    } else {
      return false;
    }
  };
  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    // let cfy = rawData?.cfy.split("-")[1];
    // if(cfy.length!==4) return;

    // cfy=rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const field_data = convertCategoryData(updatedTableData);
    if (!field_data) return;
    body.field_data = field_data;
    dispatch(setDisplayLoader('Display'))
    if (mode == "create")
      postFieldWork(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");
      putFieldWork(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        });
    }
  };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (event) => {
    if (freezeCTA) return;
    if (!verifyChecks(updatedTableData))
      return toast.error(
        "No. of demo bifurcation is not correct distribute properly"
      );
    setFreezeCTA(true);
    // let cfy = rawData?.cfy.split("-")[1];
    // if(cfy.length!==4) return;

    // cfy=rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };

    const field_data = convertCategoryData(updatedTableData);

    if (!field_data) return;
    body.field_data = field_data;

    dispatch(setDisplayLoader('Display'))
    if (mode == "create")
      postFieldWork(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = params.get("fy");
      dispatch(setDisplayLoader('Display'))
      putFieldWork(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/fieldwork");
          }, 1000);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/fieldwork");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      field_plan_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postFieldWorkAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate(`/dashboard`);
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  // (function () {
  //   if (loader) {
  //     dispatch(setDisplayLoader("Display"));
  //   } else {
  //     dispatch(setDisplayLoader("Hide"));
  //   }
  // })();

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Field Work Planner
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY IN NO's
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  confirmDialog("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"T",
//         isEditable:false,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2024-25",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     }
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
// ]
