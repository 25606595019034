import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";

import * as RelStrategyServices from "../../service/releaseStrategy";

const validationSchema = Yup.object({
  description: Yup.string().required("*Description is required").nullable(),
});

const AddreleaseStrategy = (props) => {
  const { closeForm, showForm, edit, editGrp, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [allGroups, setAllGroups] = useState([]);
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [grpError, setGrpError] = useState(false);

  const [allStrat, setAllStrat] = useState([]);
  const [allStratData, setAllStratData] = useState([]);
  const [stratError, setStratError] = useState(false);

  const [allCode, setAllCode] = useState([]);
  const [allCodeData, setAllCodeData] = useState([]);
  const [allCodeData2, setAllCodeData2] = useState([]);
  const [allCodeData3, setAllCodeData3] = useState([]);
  const [allCodeData4, setAllCodeData4] = useState([]);
  const [allCodeData5, setAllCodeData5] = useState([]);
  const [allCodeData6, setAllCodeData6] = useState([]);
  const [allCodeData7, setAllCodeData7] = useState([]);
  const [allCodeData8, setAllCodeData8] = useState([]);
  const [codeError, setCodeError] = useState(false);
  const [descError, setDescError] = useState(false);

  const [isExist, setIsExist] = useState(false);

  const [releaseData, setReleaseData] = useState({
    id: 0,
    release_group_id: "",
    release_strat_id: "",
    release_code_1_id: "",
    release_code_2_id: 0,
    release_code_3_id: 0,
    release_code_4_id: 0,
    release_code_5_id: 0,
    release_code_6_id: 0,
    release_code_7_id: 0,
    release_code_8_id: 0,
    description: "",
  });

  const saveRelease = () => {
    setIsLoading(true);
    let data = {};
    data["release_group_id"] = Number(releaseData?.release_group_id);
    data["release_strat_id"] = Number(releaseData?.release_strat_id);
    data["release_code_1_id"] = Number(releaseData?.release_code_1_id);
    data["release_code_2_id"] = releaseData?.release_code_2_id;
    data["release_code_3_id"] = releaseData?.release_code_3_id;
    data["release_code_4_id"] = releaseData?.release_code_4_id;
    data["release_code_5_id"] = releaseData?.release_code_5_id;
    data["release_code_6_id"] = releaseData?.release_code_6_id;
    data["release_code_7_id"] = releaseData?.release_code_7_id;
    data["release_code_8_id"] = releaseData?.release_code_8_id;
    data["description"] = releaseData?.description;
    RelStrategyServices.saveRelease(data)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateRelease = async () => {
    console.log(releaseData);
    setIsLoading(true);
    let data = {};
    data["release_group_id"] = Number(releaseData?.release_group_id);
    data["release_strat_id"] = Number(releaseData?.release_strat_id);
    data["release_code_1_id"] = Number(releaseData?.release_code_1_id);
    data["release_code_2_id"] = releaseData?.release_code_2_id;
    data["release_code_3_id"] = releaseData?.release_code_3_id;
    data["release_code_4_id"] = releaseData?.release_code_4_id;
    data["release_code_5_id"] = releaseData?.release_code_5_id;
    data["release_code_6_id"] = releaseData?.release_code_6_id;
    data["release_code_7_id"] = releaseData?.release_code_7_id;
    data["release_code_8_id"] = releaseData?.release_code_8_id;
    data["description"] = releaseData?.description;
    console.log(releaseData?.id);
    await RelStrategyServices.updateRelease(data, releaseData?.id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllGroups = (paginate) => {
    RelStrategyServices.getAllGroups(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} ${
              x?.desc === null ? "" : "-" + x?.desc
            }`,
          };
          data.push(newData);
        });
        setAllGroups(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllStrat = (paginate) => {
    RelStrategyServices.getAllStrat(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} ${
              x?.desc === null ? "" : "-" + x?.desc
            }`,
          };
          data.push(newData);
        });
        setAllStrat(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllCode = (paginate) => {
    RelStrategyServices.getAllCode(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} ${
              x?.desc === null ? "" : "-" + x?.desc
            }`,
          };
          data.push(newData);
        });
        setAllCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isCodeExists = async (values, id) => {
    let error = false;
    setIsExist(false);
    let data = {};
    data["release_group_id"] = releaseData?.release_group_id;
    data["release_strat_id"] = releaseData?.release_strat_id;
    if (editGrp !== null) {
      Object.keys(editGrp).map((key) => {
        if (
          (key === "release_group_id" || key === "release_strat_id") &&
          editGrp[key] !== releaseData[key]
        ) {
          error = true;
        }
      });
    }
    console.log(error, id);
    if (error === true) {
      await RelStrategyServices.isCodeExists(data)
        .then((response) => {
          setIsExist(response?.data?.dataList?.is_exist);
          if (response?.data?.dataList?.is_exist !== true) {
            if (id !== undefined) {
              updateRelease();
            } else {
              saveRelease();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      if (id !== undefined) {
        updateRelease();
      } else {
        await RelStrategyServices.isCodeExists(data)
          .then((response) => {
            setIsExist(response?.data?.dataList?.is_exist);
            if (response?.data?.dataList?.is_exist !== true) {
              saveRelease();
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };

  useEffect(() => {
    getAllGroups(false);
    getAllStrat(false);
    getAllCode(false);
  }, []);

  useEffect(() => {
    if (editGrp) {
      setReleaseData(editGrp);
      let grpData = {
        value: editGrp?.release_group_id,
        label: `${
          editGrp?.release_group_name === null
            ? ""
            : editGrp?.release_group_name
        } ${
          editGrp?.release_group_desc === null
            ? ""
            : "-" + editGrp?.release_group_desc
        }`,
      };
      setAllGroupsData(grpData);
      let stratData = {
        value: editGrp?.release_strtgy_id,
        label: `${
          editGrp?.release_strat_name === null
            ? ""
            : editGrp?.release_strat_name
        } ${
          editGrp?.release_strat_desc === null
            ? ""
            : "-" + editGrp?.release_strat_desc
        }`,
      };
      setAllStratData(stratData);
      let CodeData = {
        value: editGrp?.release_code_1_id,
        label: `${
          editGrp?.release_code_1_name === null
            ? ""
            : editGrp?.release_code_1_name
        } ${
          editGrp?.release_code_1_desc === null
            ? ""
            : "-" + editGrp?.release_code_1_desc
        }`,
      };
      setAllCodeData(CodeData);
      let codeData2 = {
        value: editGrp?.release_code_2_id,
        label: `${
          editGrp?.release_code_2_name === null
            ? "Select"
            : editGrp?.release_code_2_name
        } ${
          editGrp?.release_code_2_desc === null
            ? ""
            : "-" + editGrp?.release_code_2_desc
        }`,
      };
      setAllCodeData2(codeData2);
      let codeData3 = {
        value: editGrp?.release_code_3_id,
        label: `${
          editGrp?.release_code_3_name === null
            ? "Select"
            : editGrp?.release_code_3_name
        } ${
          editGrp?.release_code_3_desc === null
            ? ""
            : "-" + editGrp?.release_code_3_desc
        }`,
      };
      setAllCodeData3(codeData3);
      let codeData4 = {
        value: editGrp?.release_code_4_id,
        label: `${
          editGrp?.release_code_4_name === null
            ? "Select"
            : editGrp?.release_code_4_name
        } ${
          editGrp?.release_code_4_desc === null
            ? ""
            : "-" + editGrp?.release_code_4_desc
        }`,
      };
      setAllCodeData4(codeData4);
      let codeData5 = {
        value: editGrp?.release_code_5_id,
        label: `${
          editGrp?.release_code_5_name === null
            ? "Select"
            : editGrp?.release_code_5_name
        } ${
          editGrp?.release_code_5_desc === null
            ? ""
            : "-" + editGrp?.release_code_5_desc
        }`,
      };
      setAllCodeData5(codeData5);
      let codeData6 = {
        value: editGrp?.release_code_6_id,
        label: `${
          editGrp?.release_code_6_name === null
            ? "Select"
            : editGrp?.release_code_6_name
        } ${
          editGrp?.release_code_6_desc === null
            ? ""
            : "-" + editGrp?.release_code_6_desc
        }`,
      };
      setAllCodeData6(codeData6);
      let codeData7 = {
        value: editGrp?.release_code_7_id,
        label: `${
          editGrp?.release_code_6_name === null
            ? "Select"
            : editGrp?.release_code_6_name
        } ${
          editGrp?.release_code_7_desc === null
            ? ""
            : "-" + editGrp?.release_code_7_desc
        }`,
      };
      setAllCodeData7(codeData7);
      let codeData8 = {
        value: editGrp?.release_code_8_id,
        label: `${
          editGrp?.release_code_8_name === null
            ? "Select"
            : editGrp?.release_code_8_name
        } ${
          editGrp?.release_code_8_desc === null
            ? ""
            : "-" + editGrp?.release_code_8_desc
        }`,
      };
      setAllCodeData8(codeData8);
      Object.keys(editGrp).forEach((item) => {
        releaseData[item] = editGrp[item];
      });
    }
  }, [editGrp]);

  const AllCheck = () => {
    let error = false;
    if (edit) {
      if (editGrp?.release_group_id !== releaseData?.release_group_id) {
        error = true;
      }
      if (editGrp?.release_strat_id !== releaseData?.release_strat_id) {
        error = true;
      }
    }
    if (error === false && editGrp?.id !== undefined) {
      updateRelease();
    }
    if (error === true && editGrp?.id !== undefined) {
      isCodeExists();
    }
    if (error === false && editGrp?.id === undefined) {
      isCodeExists();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let error = false;
    if (allGroupsData.length === 0) {
      setGrpError(true);
      error = true;
    }
    if (allStratData.length === 0) {
      setStratError(true);
      error = true;
    }
    if (allCodeData.length === 0) {
      setCodeError(true);
      error = true;
    }
    if (releaseData.description.length === 0) {
      setDescError(true);
      error = true;
    }
    if (error === false) {
      AllCheck();
    }
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={closeForm}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit ? "Edit" : "Add"} Release Strategy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Release Group<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="release_group_id"
                      id="release_group_id"
                      options={allGroups}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_group_id: e.value,
                        });
                        setAllGroupsData(e);
                        setGrpError(false);
                      }}
                      value={allGroupsData}
                    />
                    {grpError && (
                      <div className="small text-danger">
                        *Release Group is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Release Strategy
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="release_strat_id"
                      id="release_strat_id"
                      options={allStrat}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_strat_id: e.value,
                        });
                        setAllStratData(e);
                        setStratError(false);
                      }}
                      value={allStratData}
                    />
                    {stratError && (
                      <div className="small text-danger">
                        *Release Group is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Release Code 1<span className="text-danger">*</span>
                    </label>
                    <Select
                      name="release_code_1_id"
                      id="release_code_1_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setCodeError(false);
                        setReleaseData({
                          ...releaseData,
                          release_code_1_id: e.value,
                        });
                        setAllCodeData(e);
                      }}
                      value={allCodeData}
                    />
                    {codeError && (
                      <div className="small text-danger">
                        *Release Code is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 2</label>
                    <Select
                      name="release_code_2_id"
                      id="release_code_2_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_2_id: e.value,
                        });
                        setAllCodeData2(e);
                      }}
                      value={allCodeData2}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 3</label>
                    <Select
                      name="release_code_3_id"
                      id="release_code_3_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_3_id: e.value,
                        });
                        setAllCodeData3(e);
                      }}
                      value={allCodeData3}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 4</label>
                    <Select
                      name="release_code_4_id"
                      id="release_code_4_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_4_id: e.value,
                        });
                        setAllCodeData4(e);
                      }}
                      value={allCodeData4}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 5</label>
                    <Select
                      name="release_code_5_id"
                      id="release_code_5_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_5_id: e.value,
                        });
                        setAllCodeData5(e);
                      }}
                      value={allCodeData5}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 6</label>
                    <Select
                      name="release_code_6_id"
                      id="release_code_6_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_6_id: e.value,
                        });
                        setAllCodeData6(e);
                      }}
                      value={allCodeData6}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 7</label>

                    <Select
                      name="release_code_7_id"
                      id="release_code_7_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_7_id: e.value,
                        });
                        setAllCodeData7(e);
                      }}
                      value={allCodeData7}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Release Code 8</label>

                    <Select
                      name="release_code_8_id"
                      id="release_code_8_id"
                      options={allCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setReleaseData({
                          ...releaseData,
                          release_code_8_id: e.value,
                        });
                        setAllCodeData8(e);
                      }}
                      value={allCodeData8}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="description"
                      maxLength={250}
                      placeholder="Enter Description here..."
                      onChange={(e) => {
                        setDescError(false);
                        setReleaseData({
                          ...releaseData,
                          description: e.target.value,
                        });
                      }}
                      value={releaseData?.description}
                    />
                    {descError && (
                      <div className="small text-danger">
                        *Description is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {isExist && (
          <Alert variant="danger" className="mb-5 mt-2">
            <i className="fa fa-exclamation-triangle"></i> This combination
            already exists for an approver.
          </Alert>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                className="btn btn-outline-danger"
                onClick={closeForm}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary-inner bpi-main"
                type="button"
                onClick={(e) => submitHandler(e)}
              >
                Submit
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddreleaseStrategy;
