import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import { toast, ToastContainer } from "react-toastify";
import * as PolicyService from "../../service/policy";
import * as BrandService from "../../service/brandService";
import * as CmsNewsService from "../../service/Cms-newsService";
import { changeLeaveStatus, getLeaveById } from "../../service/leaveService";
import {
  changeShiftStatus,
  changeLoanRequestStatus,
  changeSalaryAdvRequestStatus,
  changeVPFRequestStatus,
  getShiftChangeById,
  getLoanById,
  getSalaryById,
  getVPFById,
} from "../ShiftMaster/shiftservice";
import "react-toastify/dist/ReactToastify.css";
function ApproveEssModule(props) {
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState();
  const [error, seterror] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
    is_exception: false,
    is_exception_error: false,
  });

  const getPolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    PolicyService.getPolicy(
      handleValuePolicy(
        search,
        organisation_id,
        category,
        policy_id,
        status,
        pageNo,
        page_size,
        sortBy,
        paginate
      )
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValuePolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sortBy +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (policy_id !== "" && policy_id !== undefined && policy_id !== null) {
      queryParm = queryParm + "&policy_id=" + policy_id;
    }
    return queryParm;
  };

  const handleValueBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      branding_id !== "" &&
      branding_id !== undefined &&
      branding_id !== null
    ) {
      queryParm = queryParm + "&branding_id=" + branding_id;
    }
    return queryParm;
  };

  const getBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    BrandService.getBrand(
      handleValueBrand(
        search,
        organisation_id,
        branding_id,
        category,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getData = async (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    data_id
  ) => {
    await CmsNewsService.getNotice(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      data_id
    )
      .then((response) => {
        setData(response?.data?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    switch (location?.state?.type) {
      case "Leave":
        getAppliedLeave(location?.state?.from);
        break;
      case "Shift":
        getAppliedShift(location?.state?.from);
        break;
      case "Loan":
        getAppliedLoan(location?.state?.from);
        break;
      case "Salary Advance":
        getAppliedAdvanceSalary(location?.state?.from);
        break;
      case "VPF":
        getAppliedVPFRequest(location?.state?.from);
        break;
      default:
        break;
    }
  }, []);

  const getAppliedLoan = async (id) => {
    await getLoanById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList?.result[0]);
      }
    });
  };

  const getAppliedVPFRequest = async (id) => {
    await getVPFById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.result[0]);
      }
    });
  };

  const getAppliedAdvanceSalary = async (id) => {
    await getSalaryById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList?.result[0]);
      }
    });
  };

  const getAppliedShift = async (id) => {
    await getShiftChangeById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList?.result[0]);
      }
    });
  };

  const getAppliedLeave = async (id) => {
    await getLeaveById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList?.result[0]);
      }
    });
  };

  const submitAction = (id, stat) => {
    let data = status;
    let idlist = [];
    data.status = stat;
    switch (location?.state?.type) {
      case "Policy":
        data.policy_id = id;
        changePolicyRemarks(data);
        break;
      case "Branding":
        data.branding_id = id;
        changeBrandingRemarks(data);
        break;
      case "News":
        data.news_id = id;
        changeNewsRemarks(data);
        break;
      case "Leave":
        idlist.push(id);
        data.leave_id = idlist;
        changeLeaveRemarks(data);
        break;
      case "Shift":
        idlist.push(id);
        data.shift_id = idlist;
        changeShiftChangeRemarks(data);
        break;
      case "Loan":
        idlist.push(id);
        data.loan_id = idlist;
        changeLoanRequestRemarks(data);
        break;
      case "Salary Advance":
        idlist.push(id);
        data.salary_id = idlist;
        changeSalaryAdvRequestRemarks(data);
        break;
      case "VPF":
        idlist.push(id);
        data.vpf_id = idlist;
        changeVPFRequestRemarks(data);
        break;
    }
  };

  const changePolicyRemarks = (data) => {
    if (data.remarks != "") {
      setIsLoading(true);
      PolicyService.changePolicyStatus(data)
        .then((response) => {
          getPolicy("", "", "", id, true, 1, 10, "id", true);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeBrandingRemarks = (data) => {
    setIsLoading(true);
    if (data.remarks != "") {
      BrandService.changeBrandingStatus(data)
        .then((response) => {
          getBrand("", "", id, "", true, 1, 10, "id", true);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeNewsRemarks = (data) => {
    setIsLoading(true);
    if (data.remarks != "") {
      CmsNewsService.changeNewsStatus(data)
        .then((response) => {
          getData(1, 10, "id", "", true, "", "", "", id);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeLeaveRemarks = (data) => {
    if (data.remarks != "" || data?.status === "A") {
      setIsLoading(true);
      changeLeaveStatus(data)
        .then((response) => {
          getAppliedLeave(location?.state?.from);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeShiftChangeRemarks = (data) => {
    if (data.remarks != "" || data?.status === "A") {
      setIsLoading(true);
      changeShiftStatus(data)
        .then((response) => {
          getAppliedShift(location?.state?.from);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeLoanRequestRemarks = (data) => {
    if (
      (data?.is_exception === false &&
        (data.remarks != "" || data?.status === "A")) ||
      (data?.is_exception === true && data.remarks != "")
    ) {
      setIsLoading(true);
      changeLoanRequestStatus(data)
        .then((response) => {
          getAppliedLoan(location?.state?.from);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeSalaryAdvRequestRemarks = (data) => {
    if (
      (data?.is_exception === false &&
        (data.remarks != "" || data?.status === "A")) ||
      (data?.is_exception === true && data.remarks != "")
    ) {
      setIsLoading(true);
      changeSalaryAdvRequestStatus(data)
        .then((response) => {
          getAppliedAdvanceSalary(location?.state?.from);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeVPFRequestRemarks = (data) => {
    if (data.remarks != "" || data?.status === "A") {
      setIsLoading(true);
      changeVPFRequestStatus(data)
        .then((response) => {
          getAppliedVPFRequest(location?.state?.from);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  useEffect(() => {
    console.log(status);
  }, [status]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                {location?.state?.type === "Policy" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                  
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Category</label>
                        <p>{Data?.category_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organisation.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch.map((obj) => {
                          return <li>{obj?.branch_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Department</label>
                        {Data?.department.map((obj) => {
                          return <li>{obj?.department_name})</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Sub Title</label>
                        <p>{Data?.sub_title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Users</label>
                        {Data?.user.map((obj) => {
                          return <li>{obj?.user_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.desc}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment.map((a) => (
                            <a
                              href={a.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span class="mr-3">
                                {a.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                  <iframe
                                    width="100"
                                    height="100"
                                    src={a.attachment_path}
                                  ></iframe>
                                ) : a.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".mp4") ? (
                                  <video controls>
                                    <source
                                      src={a.attachment_path}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={a.attachment_path}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                )}
                              </span>
                            </a>
                          ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "News" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Display On</label>
                        <p>
                          {Data?.display_on?.length > 0 &&
                            Data?.display_on?.map((x) => x)}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organization.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch.map((obj) => {
                          return <li>{obj?.name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Unit City</label>
                        {Data?.branch_city.map((obj) => {
                          return <li>{obj?.name})</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Start Date</label>
                        <p>
                          {moment
                            .utc(Data?.startDateTime)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>End Date</label>
                        <p>
                          {moment
                            .utc(Data?.endDateTime)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.description}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment.map((a) => (
                            <a
                              href={a.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span class="mr-3">
                                {a.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                  <iframe
                                    width="100"
                                    height="100"
                                    src={a.attachment_path}
                                  ></iframe>
                                ) : a.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".mp4") ? (
                                  <video controls>
                                    <source
                                      src={a.attachment_path}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={a.attachment_path}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                )}
                              </span>
                            </a>
                          ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "Leave" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.requested_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>{moment(Data?.requested_at).format("DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Leave Type</label>
                        <p>{Data?.leave_type__name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>From</label>
                        <p>{moment(Data?.from_date).format("DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>To</label>
                        <p>{moment(Data?.to_date).format("DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>No. Of Days</label>
                        <p>{Data?.leave_duration}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>From Type</label>
                        <p>
                          {(Data?.from_type === "F" ||
                            Data?.from_type === "First") &&
                            "First Half"}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>To Type</label>
                        <p>
                          {(Data?.to_type === "S" ||
                            Data?.to_type === "Second") &&
                            "Second Half"}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Reason</label>
                        <p>{Data?.leave_reason__reason}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Remarks</label>
                        <p>{Data?.remarks}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment !== undefined &&
                            Data?.attachment.map((a) => (
                              <a
                                href={a?.attachment}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span class="mr-3">
                                  {a?.attachment
                                    ?.split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <iframe
                                      width="100"
                                      height="100"
                                      src={a.attachment}
                                    ></iframe>
                                  ) : a?.attachment
                                      ?.split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <video controls>
                                      <source
                                        src={a.attachment}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={a?.attachment}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  )}
                                </span>
                              </a>
                            ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "Shift" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.user}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Shift Date</label>
                        <p>{Data?.shift_date}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Assigned Shift</label>
                        <p>{Data?.assigned_shift}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Reason</label>
                        <p>{Data?.reason__reason}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Change To</label>
                        {Data?.change_to?.length > 0 && (
                          <p>{Data?.change_to[0]?.shift_name}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Changed Timing</label>
                        {Data?.change_to?.length > 0 && (
                          <p>
                            {moment(
                              Data?.change_to[0]?.shift_time_in,
                              "HH:mm:ss"
                            ).format("hh:mm:ss A")}{" "}
                            -{" "}
                            {moment(
                              Data?.change_to[0]?.shift_time_out,
                              "HH:mm:ss"
                            ).format("hh:mm:ss A")}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "Loan" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>
                          {Data?.emp_full_name} ({Data?.emp_code})
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Raised By</label>
                        <p>
                          {Data?.hr_full_name}
                        </p>
                      </div>
                    </div>
                    {/* <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Unit</label>
                        <p>{Data?.shift_date}</p>
                      </div>
                    </div> */}
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Loan Type</label>
                        <p>{Data?.loan_type}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.comments}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Loan Amount</label>
                        <p>{Data?.amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>No. Of Installment</label>
                        <p>{Data?.no_of_installment}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {Data?.created_at === null
                            ? "-- : --"
                            : moment(Data?.created_at).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Recovery Date</label>
                        <p>
                          {moment(Data?.recovery_date).format("DD-MM-YYYY")}
                        </p>
                      </div>
                      <div class="col-md-8">
                        <div class="form-group innergroup">
                          <label>Download Attachments</label>
                          <p>
                            <a
                              href={Data?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {Data?.attachment_path !== undefined && (
                                <span class="mr-3">
                                  {Data?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <iframe
                                      width="100"
                                      height="100"
                                      src={Data?.attachment_path}
                                    ></iframe>
                                  ) : Data?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <video controls>
                                      <source
                                        src={Data?.attachment_path}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : Data?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".jpg") ? (
                                    <img
                                      src={Data?.attachment_path}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : (
                                    <img
                                      src={Data?.attachment_path}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  )}
                                </span>
                              )}
                            </a>
                          </p>
                        </div>
                      </div>
                      {Data?.flag === null && (
                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label className="d-block mb-2">
                              Case<span class="text-danger"></span>
                            </label>
                            <label class="logCheck d-inline-block mr-4">
                              Exception
                              <input
                                type="radio"
                                name="statusupdate1"
                                value="checked"
                                checked={status?.is_exception === true}
                                onChange={(e) => {
                                  setStatus((prev) => ({
                                    ...prev,
                                    is_exception: true,
                                  }));
                                  seterror(false);
                                }}
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="logCheck d-inline-block">
                              No Exception
                              <input
                                type="radio"
                                name="statusupdate1"
                                value="unchecked"
                                checked={status?.is_exception === false}
                                onChange={(e) => {
                                  setStatus((prev) => ({
                                    ...prev,
                                    is_exception: false,
                                  }));
                                  seterror(false);
                                }}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : location?.state?.type === "Salary Advance" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Raised By</label>
                        <p>{Data?.hr_full_name}</p>
                      </div>
                    </div>
                    {/* <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Unit</label>
                        <p>{Data?.shift_date}</p>
                      </div>
                    </div> */}
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Employee</label>
                        <p>{Data?.emp_full_name}({Data?.emp_code})</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <Tooltip title={Data?.comments} position="bottom">
                          <p
                            style={{
                              maxWidth: "170px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {Data?.comments}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested Amount</label>
                        <p>{Data?.amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {Data?.apply_date === null
                            ? "-- : --"
                            : moment(Data?.apply_date).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    {Data?.flag === null && (
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            Case<span class="text-danger"></span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Exception
                            <input
                              type="radio"
                              name="statusupdate1"
                              value="checked"
                              checked={status?.is_exception === true}
                              onChange={(e) => {
                                setStatus((prev) => ({
                                  ...prev,
                                  is_exception: true,
                                }));
                                seterror(false);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No Exception
                            <input
                              type="radio"
                              name="statusupdate1"
                              value="unchecked"
                              checked={status?.is_exception === false}
                              onChange={(e) => {
                                setStatus((prev) => ({
                                  ...prev,
                                  is_exception: false,
                                }));
                                seterror(false);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                ) : location?.state?.type === "VPF" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.emp_full_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Employee</label>
                        <p>{Data?.emp_full_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Employee Code</label>
                        <p>{Data?.emp_code}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Statutory PF (%)</label>
                        <p>{Data?.Statutory_pf}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Volunatry PF (%)</label>
                        <p>{Data?.vpf}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Total PF (%)</label>
                        <p>{Data?.total_pf}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Fiscal Year</label>
                        <p>{Data?.fiscal_year}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested At</label>
                        <p>
                          {Data?.created_at === null
                            ? "-- : --"
                            : moment(Data?.created_at).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Branding Category</label>
                        <p>{Data?.category_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organisation.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch.map((obj) => {
                          return <li>{obj?.branch_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Department</label>
                        {Data?.department.map((obj) => {
                          return <li>{obj?.department_name})</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Branding Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Users</label>
                        {Data?.user.map((obj) => {
                          return <li>{obj?.user_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.desc}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment.map((a) => (
                            <a
                              href={a?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span class="mr-3">
                                {a?.attachment_path
                                  ?.split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                  <iframe
                                    width="100"
                                    height="100"
                                    src={a.attachment_path}
                                  ></iframe>
                                ) : a?.attachment_path
                                    ?.split("/")
                                    .pop()
                                    .includes(".mp4") ? (
                                  <video controls>
                                    <source
                                      src={a.attachment_path}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={a?.attachment_path}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                )}
                              </span>
                            </a>
                          ))}
                        </p>
                      </div>
                    </div>
                    {Data?.flag === null && (
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            Case<span class="text-danger"></span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Exception
                            <input
                              type="radio"
                              name="statusupdate1"
                              onChange={(e) => {
                                setStatus((prev) => ({
                                  ...prev,
                                  is_exception: true,
                                }));
                                seterror(false);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No Exception
                            <input
                              type="radio"
                              name="statusupdate1"
                              checked
                              onChange={(e) => {
                                setStatus((prev) => ({
                                  ...prev,
                                  is_exception: false,
                                }));
                                seterror(false);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

{(location?.state?.type === "Salary Advance" || location?.state?.type === "Loan") && (
            <div class="row ml-5">
              <div class="col-md-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Approver Stage</th>
                        <th>Approver Name</th>
                        <th>Approved Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Data?.approval_details?.length > 0 ?
                      Data?.approval_details?.map((details, index)=>{
                        return (
                      <tr>
                        <td>{index + 1}.</td>
                        <td>{details?.approver_type}</td>
                        <td>{details?.approver_name}</td>
                        {details?.status === "A" || details?.status === "D" ?
                        <td>{moment(details.updated_at).format("DD-MM-YYYY")}</td>
                        :
                        <td>---</td>
                      }
                      {details?.remark !== null ?
                        <td>{details?.remark}</td>
                        :
                        <td>---</td>
                        }
                        <td>
                          {details?.status == null ? (
                            <span class="badge badge-secondary">Pending</span>
                          ) : details?.status == "A" ? (
                            <span class="badge badge-success">Approved</span>
                          ) : details?.status == "D" ? (
                            <span class="badge badge-danger">Rejected</span>
                          ) : null}
                        </td>
                      </tr>
                        )
                      })
                      :
                      <tr>
                        <td colSpan={12}>No Records Found...</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            )}

            {(Data?.status == null ||
              Data?.for_cancel === true ||
              Data?.status_approve === null ||
              Data?.status === "P" ||
              Data?.status_approve === "P") && (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label></label>
                    <textarea
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                          is_exception_error: false,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="remarks..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give Remarks
                      </div>
                    }
                    {/* */}
                  </div>
                </div>

                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          submitAction(Data?.id, "D");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(Data?.id, "A");
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveEssModule;
