import SelectInput from "@mui/material/Select/SelectInput";
import React from "react";
import { Tooltip } from "react-tippy";

const AutoFillSelect = (props) => {
  const {
    suggestions,
    inputValue,
    onSelectSuggestion,
    displaySuggestions,
    selectedSuggestion,
    setInputValue,
    empSelectedSuggestion,
    onSelectSuggestionsEmployee,
    empDisplaySuggestions,
    empfilteredSuggestions,
  } = props;
  if (
    (inputValue && displaySuggestions) ||
    (inputValue && empDisplaySuggestions)
  ) {
    if (suggestions.length > 0) {
      return (
        <ul className="suggestions-list" id="scrollpart">
          {suggestions.map((suggestion, index) => {
            const isSelected = selectedSuggestion === index;
            const classname = `suggestion ${isSelected ? "selected" : ""}`;
            return (
              <li
                key={index}
                className={classname}
                onClick={() => {
                  onSelectSuggestion(
                    suggestion?.link,
                    index,
                    suggestion?.url_type
                  );
                  setInputValue("");
                }}
              >
                {suggestion?.name}
              </li>
            );
          })}
        </ul>
      );
    } else if (empfilteredSuggestions.length > 0) {
      return (
        <ul className="suggestions-list" id="scrollpart">
          {empfilteredSuggestions?.map((suggestion, index) => {
            const isSelected = empSelectedSuggestion === index;
            const classname = `suggestion ${isSelected ? "selected" : ""}`;
            return (
              <li
                key={index}
                className={classname}
                onClick={() => {
                  onSelectSuggestionsEmployee(suggestion?.email, index);
                  setInputValue("");
                }}
              >
                {/* {suggestion?.name} */}

                <Tooltip
                  title={`${suggestion?.email}, ${suggestion?.mobile}, ${suggestion?.Ext}`}
                  position="bottom"
                >
                  {suggestion?.name}
                </Tooltip>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div class="no-sugtxt">No suggestions available...</div>;
    }
  }
  return <></>;
};

export default AutoFillSelect;
