import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewBrand = (props) => {
    const { viewForm, onViewClose, viewFormData } = props;
    return (
        <>
            <Modal
                show={viewForm}
                onHide={onViewClose}
                size="lg"
                className="modaldefaultclose modaldefaultclosecenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Brand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row viewformaftersubmit">


                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Brand Title
                                        </label>
                                        <p>{viewFormData?.title}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Branding Category
                                        </label>
                                        <p>{viewFormData?.category_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Branch Name
                                        </label>
                                        <p>
                                            {viewFormData?.branch.map((b) => (
                                                <h6>{b?.branch_name}</h6>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Branch Description
                                        </label>
                                        <p>
                                            {viewFormData?.desc}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Department Name
                                        </label>
                                        <p>
                                            {viewFormData?.department.map((b) => (
                                                <h6>{b?.department_name}</h6>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Sub Department Name
                                        </label>
                                        <p>
                                            {viewFormData?.sub_department.map((b) => (
                                                <h6>{b?.sub_department_name}</h6>
                                            ))}
                                        </p>
                                    </div>
                                </div>


                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Organisation Name & Code
                                        </label>
                                        <p>
                                            {viewFormData?.organisation.map((b) => (
                                                <h6>{b?.name}</h6>
                                            ))}
                                        </p>
                                        {/* <p>{viewFormData?.organisation}</p> */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Attachment
                                        </label>
                                        <p>
                                            {viewFormData?.attachment.map((b) => (<>
                                                <a
                                                    href={b.attachment_path}
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                >
                                                    {b.attachment_path.includes(".pdf") ? (<img
                                                        src={"images/policy-icon/pdf.png"}
                                                        alt="image"
                                                        width="70px"
                                                        height="70px"
                                                    />) : b.attachment_path.includes(".xlsx") ? (
                                                        <img
                                                            src={"images/policy-icon/xlsx.png"}
                                                            alt="image"
                                                            width="70px"
                                                            height="70px"
                                                        />
                                                    ) : b.attachment_path.includes(".xls") ? (
                                                        <img
                                                            src={"images/policy-icon/xlsx.png"}
                                                            alt="image"
                                                            width="70px"
                                                            height="70px"
                                                        />
                                                    ) : b.attachment_path.includes(".doc") ? (
                                                        <img
                                                            src={"images/policy-icon/docs.png"}
                                                            alt="image"
                                                            width="70px"
                                                            height="70px"
                                                        />
                                                    ) : b.attachment_path.includes(".docs") ? (
                                                        <img
                                                            src={"images/policy-icon/docs.png"}
                                                            alt="image"
                                                            width="70px"
                                                            height="70px"
                                                        />
                                                    ) : b.attachment_path.includes(".pptx") ? (
                                                        <img
                                                            src={"images/policy-icon/ppt.png"}
                                                            alt="image"
                                                            width="70px"
                                                            height="70px"
                                                        />
                                                    ) : b.attachment_path.includes(".mp4") ? (
                                                        <img
                                                        src={"images/policy-icon/video.png"}
                                                        alt="image"
                                                        width="70px"
                                                        height="70px"
                                                    />):<img src={b.attachment_path} target="_blank" style={{ width: "50px" }}></img>}</a></>
                                            ))}
                                        </p>
                                        {/* <p>{viewFormData?.organisation}</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Created By</label>
                                        <p>{viewFormData?.created_by}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Created At</label>
                                        <p>
                                            {moment.utc(viewFormData?.created_at).format(
                                                "Do MMM YYYY, h:mm:ss A"
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Updated By</label>
                                        <p>{viewFormData?.updated_by}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Updated At</label>
                                        <p>
                                            {moment.utc(viewFormData?.updated_at).format(
                                                "Do MMM YYYY, h:mm:ss A"
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label className="d-block">
                                            Status
                                        </label>
                                        <label className="logCheck d-inline-block mr-4" disabled style={{ cursor: "auto" }}>
                                            Active
                                            <input
                                                type="radio"
                                                name="statusupdate1"
                                                checked={viewFormData.is_active === true}
                                                readOnly
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="logCheck d-inline-block" disabled style={{ cursor: "auto" }}>
                                            Inactive
                                            <input
                                                type="radio"
                                                name="is_active"
                                                checked={viewFormData.is_active === false}
                                                readOnly
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ViewBrand;
