const { CLIENT_ID_PI, TENANT_ID_PI, SECRET_PI } = process.env;

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/624e7232-a3b5-4b26-911e-c1886eb5be42`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    //UAT Details
    // clientId: "fd27a60a-48dd-45ba-8a52-5bafe53082d2",
    // redirectUri: `https://uatmypi.piind.in/`,
    // clientSecret: "YDm8Q~olYpX.TrKVziFzG85T00xqYdH-AdMHFaoT",

    //PRD Details
    clientId: "a44489ff-0c37-4eea-b32b-ea4b07c282dc",
    redirectUri: `https://mypi.piind.in/`,
    clientSecret: "Sbj8Q2YHFvfie8A_tMWEMICAefdFXbCi0Wtb6k",

    //Dev Details
    // clientId: "ff524893-006e-4ff0-aff5-7284a6504573",
    // redirectUri: `https://devmypi.piind.in/`,
    // clientSecret: "z4O8Q~pk0gdqxjdpoLcctPTj4lUmwxtoJwwvMdux",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "Calendars.Read", "Tasks.ReadWrite"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0",
};
