import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as LoanDataService from "../../service/loansalary";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";

import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const DEFAULT_SELECT_OPTION = { value: null, label: "--select--" };

const SalaryApprover = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let condition = "true";
  const userData = useSelector(selectUserData)

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    filtersetShow(false);
  };
  const [addnewshow, addnewsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const addnewopen = () => {
    addnewsetShow(true);
    setEditMode(false);
    setEditData(null);
    setSelectedBU([]);
    setApprover1([]);
    setApprover2([]);
    setApprover3([]);
    setApprover4([]);
    setApprover5([]);
    setApprover6([]);
    setApprover7([]);
  };
  const addnewclose = () => {
    addnewsetShow(false);
    setApproverForm({
      ...approverForm,
      type: "",
      business_unit: "",
      reporting_person: "",
      hr_id: "",
      hod: "",
      approver4: "",
      approver5: "",
      approver6: "",
      approver7: "",
    });
    setEditMode(false);
    setSelectedBU([]);
    setApprover1([]);
    setApprover2([]);
    setApprover3([]);
    setApprover4([]);
    setApprover5([]);
    setApprover6([]);
    setApprover7([]);
    setEditData(null);
  };
  const [checkIndex, setCheckIndex] = useState("Loan");
  const [checkCase, setCheckCase] = useState("valid");
  const [businessUnit, setBusinessUnit] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [approverMasterList, setApproverMasterList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedBU, setSelectedBU] = useState([]);
  const [approver1, setApprover1] = useState([]);
  const [approver2, setApprover2] = useState([]);
  const [approver3, setApprover3] = useState([]);
  const [approver4, setApprover4] = useState([]);
  const [approver5, setApprover5] = useState([]);
  const [approver6, setApprover6] = useState([]);
  const [approver7, setApprover7] = useState([]);
  const [approverForm, setApproverForm] = useState({
    business_unit: "",
    reporting_person: "",
    hr_id: "",
    hod: "",
    type: "",
    case: "",
    approver4: "",
    approver5: "",
    approver6: "",
    approver7: "",
  });
  const [salaryApproverFilter, setSalaryApproverFilter] = useState({
    business_unit: "",
  });

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getBusinessUnit();
    getApproverList();
    ApproverMaster(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
    if (editMode) {
      let obj = {
        value: editData?.id,
        label:
          editData?.business_unit_name +
          "(" +
          editData?.business_unit_code +
          ")",
      };
      setSelectedBU(obj);
      let obj1 = {
        value: editData?.id,
        label: editData?.approver1_name + "(" + editData?.approver1_code + ")",
      };
      setApprover1(obj1);
      let obj2 = {
        value: editData?.id,
        label: editData?.approver2_name + "(" + editData?.approver2_code + ")",
      };
      setApprover2(obj2);
      let obj3 = {
        value: editData?.id,
        label: editData?.approver3_name + "(" + editData?.approver3_code + ")",
      };
      if (editData?.approver3_code != null) {
        setApprover3(obj3);
      }
      let obj4 = {
        value: editData?.id,
        label: editData?.approver4_name + "(" + editData?.approver4_code + ")",
      };
      if (editData?.approver4_code != null) {
        setApprover4(obj4);
      }
      let obj5 = {
        value: editData?.id,
        label: editData?.approver5_name + "(" + editData?.approver5_code + ")",
      };
      if (editData?.approver5_code != null) {
        setApprover5(obj5);
      }
      let obj6 = {
        value: editData?.id,
        label: editData?.approver6_name + "(" + editData?.approver6_code + ")",
      };
      if (editData?.approver6_code != null) {
        setApprover6(obj6);
      }
      let obj7 = {
        value: editData?.id,
        label: editData?.approver7_name + "(" + editData?.approver7_code + ")",
      };
      if (editData?.approver7_code != null) {
        setApprover7(obj7);
      }
      setCheckCase(editData?.case);
      setApproverForm({
        ...approverForm,
        type: editData?.type,
        business_unit: editData?.business_unit_id,
        reporting_person: editData?.approver1_id,
        hr_id: editData?.approver2_id,
        hod: editData?.approver3_id,
        approver4: editData?.approver4_id,
        approver5: editData?.approver5_id,
        approver6: editData?.approver6_id,
        approver7: editData?.approver7_id,
        case: editData?.case,
      });
    } else {
      setApproverForm({
        ...approverForm,
        type: "",
        business_unit: "",
        reporting_person: "",
        hr_id: "",
        hod: "",
        approver4: "",
        approver5: "",
        approver6: "",
        approver7: "",
        case: "",
      });
    }
  }, [editMode, pageSizeNo]);

  const getBusinessUnit = () => {
    LoanDataService.getBU().then((response) => {
      setBusinessUnitList(response?.data?.dataList?.result);

      const data = [
        { value: null, label: "--select--" },
        ...response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        }),
      ];
      setBusinessUnit(data);
    });
  };

  const mainDataFilter = () => {
    let data = approverForm;
    let business_unit = data.business_unit;
    setFilterApplied(true);
    ApproverMaster(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      business_unit
    );
    filtersetShow();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(approverForm));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setSalaryApproverFilter(ClearData);
    filterclose();
    ApproverMaster(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const getApproverList = () => {
    LoanDataService.getApprover().then((res) => {
      setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: x.full_name + "(" + x.username + ")",
          };
        }),
      ];
      setApprovers(data);
    });
  };

  const checkBUExist = () => {
    let data = approverForm;
    if (data.business_unit == "") {
      toast.error("Kindly fill the required field");
    } else {
      let obj = {
        business_unit: data.business_unit,
        loan_type: checkIndex,
        case: checkCase,
      };
      LoanDataService.isBUExist(obj).then((response) => {
        if (response?.data?.dataList?.is_exist === true) {
          toast.error("Approver in this Business Unit is already created");
        } else {
          if (condition == "true") {
            condition = "false";
            handleSubmit();
          }
        }
      });
    }
  };

  const handleSubmit = () => {
    let data = approverForm;
    data.type = checkIndex;
    data.case = checkCase;
    // if (data.reporting_person === "") {
    //   toast.error("Select Reporting Person to proceed")
    // }
    // else {
    LoanDataService.saveApprover(data).then((response) => {
      condition = "true";
      toast.success(response?.data?.message);
      setApproverForm({
        ...approverForm,
        business_unit: "",
        reporting_person: "",
        hr_id: "",
        hod: "",
        type: "",
        approver4: "",
        approver5: "",
        approver6: "",
        approver7: "",
      });
      ApproverMaster(
        searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      addnewsetShow(false);
    });
  };
  // }

  const handleUpdate = () => {
    let data = approverForm;
    data.type = checkIndex;
    if (data.business_unit === null) {
      toast.error("Kindly Select a business unit");
    } else {
      let id = editData?.id;
      LoanDataService.updateApprover(id, data).then((response) => {
        toast.success(response?.data?.message);
        addnewsetShow(false);
        ApproverMaster(
          searchStr,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      });
    }
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate, bu) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (bu !== "" && bu !== undefined && bu !== null) {
      queryParm = queryParm + "&bu=" + bu;
    }

    return queryParm;
  };

  const ApproverMaster = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    bu
  ) => {
    LoanDataService.getApproverMaster(
      handleKey(search, page_no, page_size, sort_by, paginate, bu)
    ).then((response) => {
      setApproverMasterList(response?.data?.dataList?.result);
      setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      ApproverMaster(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        approverForm.business_unit
      );
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      ApproverMaster("", currentPage, pageSizeNo, sortData.sort_by, true);
      setSearchStr("");
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    ApproverMaster(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const edit = (data) => {
    addnewsetShow(true);
    setEditMode(true);
    setEditData(data);
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Assign Approver</h4>
                    {/* <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                          <Breadcrumb.Item active>
                            Assign Approver
                          </Breadcrumb.Item>
                        </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Business Unit,
                              <br />
                              Advance Type,
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          id="searchbar"
                          type="text"
                          class="form-control"
                          placeholder="Search on Business Unit, Advance Type..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                      <button
                        class="btn btn-primary-inner"
                        onClick={addnewopen}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Approver
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Status</th>
                    <th>Advance Type</th>
                    <th>Business Unit</th>
                    <th>App. Name & ID 1</th>
                    <th>App. Name & ID 2</th>
                    <th>App. Name & ID 3</th>
                    <th>App. Name & ID 4</th>
                    <th>App. Name & ID 5</th>
                    <th>App. Name & ID 6</th>
                    <th>App. Name & ID 7</th>
                  </tr>
                </thead>
                <tbody>
                  {approverMasterList.length > 0 &&
                    approverMasterList?.map((data) => {
                      return (
                        <tr>
                          <td>
                            <button
                              class="btn btn-blanktd text-primary f-12"
                              type="button"
                              onClick={(e) => {
                                edit(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            {/* <button class="btn btn-blanktd text-danger ml-2 f-12">
                                  <i class="far fa-trash-alt text-danger"></i>
                                </button> */}
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Active
                            </span>
                          </td>
                          {/* <td class="fw-bold text-theme">{data?.type}</td> */}
                          {data?.case === "exception" ? (
                            <td>{data?.type} (Exceptional Case)</td>
                          ) : (
                            <td>{data?.type}</td>
                          )}
                          <td>{data?.business_unit_name}</td>
                          <td>
                            Reporting Manager
                            {/* {data?.approver1_name}
                                <br />
                                ({data?.approver1_code}) */}
                          </td>
                          <td>
                            HRBP
                            {/* {data?.approver2_name}
                                <br />
                                ({data?.approver2_code}) */}
                          </td>
                          <td>
                            {data?.approver3_name}
                            <br />
                            {data?.approver3_code !== null
                              ? data?.approver3_code
                              : "N/A"}
                          </td>
                          <td>
                            {data?.approver4_name}
                            <br />
                            {data?.approver4_code !== null
                              ? data?.approver4_code
                              : "N/A"}
                          </td>
                          <td>
                            {data?.approver5_name}
                            <br />
                            {data?.approver5_code !== null
                              ? data?.approver5_code
                              : "N/A"}
                          </td>
                          <td>
                            {data?.approver6_name}
                            <br />
                            {data?.approver6_code !== null
                              ? data?.approver6_code
                              : "N/A"}
                          </td>
                          <td>
                            {data?.approver7_name}
                            <br />
                            {data?.approver7_code !== null
                              ? data?.approver7_code
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Business Unit<span class="text-danger"></span>
            </label>
            <Select
              options={businessUnit}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setApproverForm({
                  ...salaryApproverFilter,
                  business_unit: e.value,
                });
                setSelectedBU(e);
              }}
              value={selectedBU}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Approver Id 1<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setSalaryApproverFilter({
                  ...salaryApproverFilter,
                  approver_id : e.target.value
                })
              }}
              value={salaryApproverFilter.approver_id}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>

          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              mainDataFilter(salaryApproverFilter);
              setCurrentPage(1);
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode === true ? "Edit" : "Add"} Approver
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup answerboxpollsuy">
                    <label className="d-block mb-2">
                      Assign Approver for<span class="text-danger"></span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Loan
                      <input
                        type="radio"
                        name="AvailableLoan"
                        disabled={editData?.type == "Salary"}
                        onChange={() => {
                          setCheckIndex("Loan");
                          setApproverForm({
                            ...approverForm,
                            type: "loan",
                          });
                        }}
                        checked={checkIndex === "Loan"}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Salary
                      <input
                        type="radio"
                        name="AvailableSalary"
                        disabled={editData?.type == "Loan"}
                        onChange={() => {
                          setCheckIndex("Salary");
                          setApproverForm({
                            ...approverForm,
                            type: "salary",
                          });
                        }}
                        checked={checkIndex === "Salary"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup answerboxpollsuy">
                    <label className="d-block mb-2">
                      Select Approval Case<span class="text-danger"></span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Exceptional
                      <input
                        type="radio"
                        name="exception"
                        disabled={editData?.case == "valid"}
                        onChange={() => {
                          setCheckCase("exception");
                          setApproverForm({
                            ...approverForm,
                            case: "exception",
                          });
                        }}
                        checked={checkCase === "exception"}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Normal
                      <input
                        type="radio"
                        name="valid"
                        disabled={editData?.case == "exception"}
                        onChange={() => {
                          setCheckCase("valid");
                          setApproverForm({
                            ...approverForm,
                            case: "valid",
                          });
                        }}
                        checked={checkCase === "valid"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Business Unit<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={businessUnit}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          business_unit: e.value,
                        });
                        setSelectedBU(e);
                      }}
                      value={selectedBU}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Reporting Manager (Approver 1)
                      <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={true}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Reporting Manager"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          reporting_person: e.value,
                        });
                        setApprover1(e);
                      }}
                    // value={approver1}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      HRBP (Approver 2)<span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={true}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="HRBP"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          hr_id: e.value,
                        });
                        setApprover2(e);
                      }}
                    // value={approver2}
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver 3 <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          hod: e.value,
                        });
                        setApprover3(e);
                      }}
                      value={approver3}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver 4 <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={approver3.length === 0}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          approver4: e.value,
                        });
                        setApprover4(e);
                      }}
                      value={approver4}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver 5 <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={approver4.length === 0}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          approver5: e.value,
                        });
                        setApprover5(e);
                      }}
                      value={approver5}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver 6 <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={approver5.length === 0}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          approver6: e.value,
                        });
                        setApprover6(e);
                      }}
                      value={approver6}
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver 7 <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={approver6.length === 0}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          approver7: e.value,
                        });
                        setApprover7(e);
                      }}
                      value={approver7}
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger"></span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="statusupdate1" checked />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={editMode == false ? checkBUExist : handleUpdate}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SalaryApprover;
