import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSearchPo = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/search_po_list?${queryParm}`
  );
};

export const getApprovedPo = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/get_approval_data?${queryParm}`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/purchase-order/search_po_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getViewOrder = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/purchase-order/view_po?po_id=${id}`);
};
export const getViewOrderCA = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/view_po?po_id=${id}&category_type=CONTRACT`
  );
};

export const saveSearchPo = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order?category_type=PO
    `,
    data
  );
};
export const onDeclinedSubmit = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/edit_po?category_type=PO
    `,
    data
  );
};

export const onDeclinedSubmitCA = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/edit_po?category_type=CONTRACT
    `,
    data
  );
};
export const saveSearchContract = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order?category_type=CONTRACT
    `,
    data
  );
};

export const sendEmail = async (email, type, user_name, po_no, po_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/send_reminder?email=${email}&type=${type}
    &user_name=${user_name}&po_no=${po_no}&category_type=PO&po_id=${po_id}`
  );
};

export const sendEmailCA = async (email, type, user_name, po_no, po_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/send_reminder?email=${email}&type=${type}
    &user_name=${user_name}&po_no=${po_no}&category_type=CONTRACT&po_id=${po_id}`
  );
};

export const getSyncData = async () => {
  return axios.get(`${AUTH_BASE_URL}/purchase-order/sync_po`);
};

export const syncPoFromSap = async (data) =>{
  return axios.post(`${AUTH_BASE_URL}/purchase-order/po_sync`, data);
};

export const get_sap_schedule = async(command_name) => {
  return axios.get(`${AUTH_BASE_URL}/sap_schedule?command_name=${command_name}`);
}