import React, { useEffect, useState } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import AllUrlsCashbook from "./AllUrlsCashbook";
import CustomPagination from "./CustomPagination";
import * as cashbookService from "../../service/cashbookService";
import moment from "moment";
import ViewCashbookDemand from "./ViewCashbookDemand";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";

function CashReports() {
  const dispatch = useDispatch();
  const [show, filtersetShow] = useState(false);
  const initialValues = {
    list: [],
    totalPages: 0,
    query: { page_size: 10, page_no: 1, paginate: true },
  };
  const [data, setData] = useState(initialValues);
  const [viewForm, setViewForm] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [viewFormData, setViewFormData] = useState(null);

  const getData = async (query) => {
    try {
      const response = await cashbookService.getReports(query);
      if (response.data.code === 200) {
        dispatch(setDisplayLoader("Hide"));
        setData({
          list: response?.data?.dataList?.result,
          totalPages: response?.data?.dataList?.paginated_data?.totalPages,
          query: {
            ...query,
            page_no: response?.data?.dataList?.paginated_data?.currentPage,
          },
        });
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      dispatch(setDisplayLoader("Hide"));
      console.log("error", error);
      toast.warn(error.message);
    }
  };
  const getExportDetails = (search, page_no, page_size, sort_by, paginate) => {
    cashbookService
      .getExportValueDemand(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookDemand.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const handlePageClick = (page) => {
    const query = { ...data.query, page_no: page };
    getData(query);
  };

  const handlePageCountChange = (count) => {
    const query = { ...data.query, page_size: count };
    getData(query);
  };

  const handleSearch = (text) => {
    const query = { ...data.query, search: text };
    getData(query);
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData({ ...data.query, sort_by: sortData.sort_by });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getData();
  }, []);

  return (
    <>
      <div class="row">
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Cash Demand List</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Cash Demand List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            disabled={data?.list.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew newSpace">
                <thead>
                  <tr>
                    <th
                      onClick={(e) => {
                        handlerData("id");
                      }}
                      className={
                        sortData["id"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["id"]
                      }
                    >
                      Request No.
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("status");
                      }}
                      className={
                        sortData["status"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["status"]
                      }
                    >
                      Status
                    </th>
                    <th>SAP Doc No</th>
                    <th>SAP Message</th>
                    <th>SAP Demand CRN No.</th>
                    <th>SAP Conf. No</th>
                    <th>SAP Conf. CRN No.</th>
                    <th>Cashbook</th>
                    <th
                      onClick={(e) => {
                        handlerData("amount");
                      }}
                      className={
                        sortData["amount"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["amount"]
                      }
                    >
                      Amount
                    </th>
                    <th>Requester</th>
                    <th
                      onClick={(e) => {
                        handlerData("created_at");
                      }}
                      className={
                        sortData["created_at"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["created_at"]
                      }
                    >
                      Requested On
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("beneficiary_user_id");
                      }}
                      className={
                        sortData["beneficiary_user_id"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["beneficiary_user_id"]
                      }
                    >
                      Beneficiary
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("doc_type");
                      }}
                      className={
                        sortData["doc_type"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["doc_type"]
                      }
                    >
                      ID Doc
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("doc_no");
                      }}
                      className={
                        sortData["doc_no"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["doc_no"]
                      }
                    >
                      ID Doc No.
                    </th>
                    <th>Payble Branch</th>
                    <th>Agency</th>
                    <th>Branch</th>
                    <th>Delivery Code</th>
                    <th>Delivery Address</th>
                  </tr>
                </thead>
                <tbody>
                  {Array?.isArray(data?.list) &&
                    data?.list?.map((item, i) => (
                      <tr key={`report-${i}`}>
                        <td
                          class="fw-bold text-theme"
                          onClick={() => {
                            onView(item);
                          }}
                        >
                          {item?.id}
                        </td>
                        <td>
                          {item?.status == "A" ? (
                            <span class="badge bad-status badge-success">
                              Approved{" "}
                            </span>
                          ) : item?.status == "P" || item?.status == null ? (
                            <span class="badge bad-status badge-warning">
                              Pending{" "}
                            </span>
                          ) : item?.status == "R" ? (
                            <span class="badge bad-status badge-danger">
                              Reject{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>{item?.document_no ?? "-"}</td>
                        <td>{item?.sap_message ?? "-"}</td>
                        <td>-</td>
                        <td>{item?.sap_confirmation_no ?? "-"}</td>
                        <td>-</td>
                        <td>{item?.cashbook_desc}</td>
                        <td>INR {item?.amount}</td>
                        <td>-</td>
                        <td>{moment(item?.created_at).format("DD-MM-YYYY")}</td>
                        <td>{item?.beneficiary_user_id}</td>
                        <td>{item?.doc_type}</td>
                        <td>{item?.doc_no}</td>
                        <td>{item?.payable_branch}</td>
                        <td>{item?.agency_name}</td>
                        <td>{item?.branch_code}</td>
                        <td>{item?.cash_delivery_code}</td>
                        <td>
                          <Tooltip
                            title={item?.delivery_address_code}
                            position="bottom"
                          >
                            {item?.delivery_address_code}
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <CustomPagination
              onChange={(e) => handlePageCountChange(e.target.value)}
              handlePageClick={(page) => handlePageClick(page)}
              pageCount={data.totalPages}
            />
          </div>
        </div>
      </div>

      {viewForm ? (
        <>
          <ViewCashbookDemand
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Cash Demand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="tickethistorbg mt-2">
                    <div class="row viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Cashbook </p>
                          <label>Cash in Hand - Raipur</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Current Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR 0.00
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Provision Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR 0.00
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Cash Limit</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR 15000.00
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Beneficiary<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option value="">Select</option>
                      <option value="105125">RaipurAnoop</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      ID Proof<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option value="">Select</option>
                      <option value="105125">PAN Card</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Doc No.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      value="GLDPS8860L"
                      placeholder="Enter Doc No."
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Amount"
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Remark<span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Remarks"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CashReports;
