import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as deptServices from "../../../service/department";
import { Formik } from "formik";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Spinner,
} from "react-bootstrap";
import AddEmployee from "./AddEmployee";
import * as EmpService from "../../../service/employee";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewEmployee from "./ViewEmployee";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
  injectStyle();
}

function Employee() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [importshow, importsetShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [dropDept, setDropDept] = useState([]);
  const [dropBranch, setDropBranch] = useState([]);
  const [dropDesig, setDropDesig] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [uploadData, setUploadData] = useState({});
  const [excelList, setExcelList] = useState([]);
  const [excelListImport, setExcelListImport] = useState([]);
  const [exDataDown, setExDataDown] = useState({});
  const [pageCount, setpageCount] = useState(1);
  const [business_unit_id1, setbusiness_unit_id1] = useState([]);
  const [businessunitid1, setbusinessunitid1] = useState("");
  const [department1, setDepartment1] = useState("");
  const [department_id1, setdepartment_id1] = useState("");
  const [empdesgid, setempdesgid] = useState("");
  const [empDesg1, setempDesg1] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [emploc, setemploc] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    designation_id: "",
    department_id: "",
    branch_id: "",
  });
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    business_unit_id: "",
    department_id: "",
    designation_id: "",
  });
  const [deptFil, setDeptFil] = useState("");

  const getEmployee = (
    pageNo,
    sort_by,
    search,
    paginate,
    dept,
    branchId,
    desigId,
    locationId
    // designationId,
    // deepartmentId
  ) => {
    dispatch(setDisplayLoader("Display"));

    EmpService.getEmployee(
      pageNo,
      pageSizeNo,
      sort_by,
      search,
      paginate,
      dept,
      branchId,
      desigId,
      emploc?.map((obj) => {
        return obj?.value;
      })
      // designationId,
      // deepartmentId
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        console.log("response", response);
        setempdesgid(desigId);
        setdepartment_id1(dept);
        setEmpList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const exportHandleKey = (
    search,
    designation_id,
    department_id,
    branch_id,
    filetype,
    locationid
  ) => {
    let queryParm = "";

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (filetype !== "" && filetype !== undefined && filetype !== null) {
      queryParm = queryParm + "&filetype=" + filetype;
    }
    if (branch_id !== "" && branch_id !== undefined && branch_id !== null) {
      queryParm = queryParm + "&branch_id=" + branch_id;
    }
    if (
      department_id !== "" &&
      department_id !== undefined &&
      department_id !== null
    ) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    if (
      designation_id !== "" &&
      designation_id !== undefined &&
      designation_id !== null
    ) {
      queryParm = queryParm + "&designation_id=" + designation_id;
    }
    if (empdesgid !== "" && empdesgid !== undefined && empdesgid !== null) {
      queryParm = queryParm + "&empdesgid=" + empdesgid;
    }
    if (
      department_id1 !== "" &&
      department_id1 !== undefined &&
      department_id1 !== null
    ) {
      queryParm = queryParm + "&department_id1=" + department_id1;
    }
    if (
      locationid !== "" &&
      locationid !== undefined &&
      locationid !== null
    ) {
      queryParm = queryParm + "&locationId=" + emploc?.map((obj) => {
        return obj?.value;
      });
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    designation_id,
    department_id,
    branch_id,
    id,
    filetype,
    empdesgid,
    department_id1,
    locationid
  ) => {
    dispatch(setDisplayLoader("Display"));
    // console.log("Filetype",filetype,designation_id,department_id,branch_id)
    // console.log(">>>>>>>>>>>>>>>>>>>>>",business_unit_id1,empdesgid,department_id1)
    EmpService.getExportValue(
      exportHandleKey(
        search,
        designation_id,
        department_id,
        branch_id,
        filetype,
        empdesgid,
        department_id1,
        locationid
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "employee-dir.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteEmployee = (idx) => {
    EmpService.deleteEmployee(idx)
      .then((res) => {
        if (res.data.status == true) {
          getEmployee(
            currentPage,
            sortData.sort_by,
            searchStr,
            true,
            departmentDataFilter.department_id,
            departmentDataFilter.branch_id,
            departmentDataFilter.designation_id,
            ""
          );

          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getEmployee(
        currentPage,
        sortData.sort_by,
        data,
        true,
        departmentDataFilter.department_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.designation_id,
        ""
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getEmployee(
      activePage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      ""
    );
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getEmployee(
        1,
        sortData.sort_by,
        e.target.value.toLowerCase(),
        true,
        departmentDataFilter.department_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.designation_id,
        ""
      );
      setSearchStr(e.target.value);
    }
  };

  const addNewEmp = () => {
    setEditMode(false);
    setShowForm(true);
    setEmployee(null);
  };
  const editEmp = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setEmployee(eData);
  };
  const onClose = () => {
    setShowForm(false);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      ""
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const submit = (id, EmpName) => {
    confirmAlert({
      title: "Delete Employee",
      message: `Are you sure to delete ${EmpName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEmployee(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const personal_subAreafilter = (paginate) => {
    EmpService.getSubPersonalAreaFilter(paginate)
      .then((response) => {
        console.log("getSubPersonalAreafilter", response);
        let obj = response?.data?.dataList?.result?.map((res) => {
          // console.log("REsssss")
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        console.log("Objj", obj);
        setSubPersonalAreaData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllDesignation = (paginate) => {
    EmpService.getAllDesignation(paginate)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDesig(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const DepartmentFilter = (location_id) => {
    EmpService.DepartmentFilter(location_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDept(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const DesignationFilter = (location_ids, department_id) => {
    EmpService.DesignationFilter(location_ids, department_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDesig(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBranch = (paginate) => {
    EmpService.getAllBranch(paginate)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropBranch(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    DepartmentFilter();
    DesignationFilter();
    LocationFilter();
    // getAllDepartment(false);
    // getBranch(false);
    // getAllDesignation(false);
    // personal_subAreafilter(false);
  };

  const onFilterSubmit = () => {
    // console.log("aPPLY")
    if (department_id1.length > 0 && empdesgid != "") {
      filterclose();
    }
  };
  const mainDataFilter = (values) => {
    setDepartmentDataForExport({
      ...departmentDataForExport,
      department_id: values.department_id,
      branch_id: values.branch_id,
      designation_id: values.designation_id,
    });
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      emploc
    );
    filterClose();
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setempdesgid("");
    setdepartment_id1([]);
    setbusinessunitid1("");
    setempDesg1([]);
    setDepartment1("");
    setbusiness_unit_id1([]);
    setemploc(0);
    setDepartmentDataFilter(ClearData);
    getEmployee(currentPage, sortData.sort_by, searchStr, true, "", "", "", "");
    filterClose();
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      console.log("Backdata", backdata);
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getEmployee(
      currentPage,
      sdata.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      ""
    );
    // console.log("<<sdata<<<", sdata);
  };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files", files.type);
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    EmpService.uploadImages(fileData).then((response) => {
      if (flag === "import_user") {
        setUploadData(response.data);
      }
    });
  };

  const getDirectoryDetails = (flag) => {
    EmpService.getDirectoryDetails()
      .then((response) => {
        if (flag === "flg") {
          setExcelList(response?.data?.dataList);
        } else {
          setExcelListImport(response?.data?.dataList);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const importExel = (values) => {
    if (JSON.stringify(uploadData) !== JSON.stringify({})) {
      setShowStatus(true);
      setIsLoading(true);
      EmpService.importExel(values)
        .then((response) => {
          setExDataDown(response.data);
          // console.log(response);
          if (response.status === 200) {
            setIsLoading(false);
            getDirectoryDetails();
            document.getElementById("import_user").value = "";
            setUploadData({});
          }
        })
        .catch((error) => {
          document.getElementById("import_user").value = "";
          setUploadData({});
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
          toast.error("Kindly Check the format");
        });
    } else {
      toast.warning("Kindly select the file to upload");
    }
  };
  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };
  useEffect(() => {
    getEmployee(
      currentPage,
      sortData.sort_by,
      "",
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      ""
    );
    getDirectoryDetails("flg");
  }, [pageSizeNo]);

  const handleShow = (e) => {
    getEmployee(
      currentPage,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter.department_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.designation_id,
      ""
    );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Employee Directory Master</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Employee Directory Master
                </Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Employee Code,
                        <br />
                        Employee Name <br />
                        Email Id <br />
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div className="input-group table-search">
                  <span className="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    id="searchbar"
                    className="form-control"
                    placeholder="Search on Employee Code, Employee Name, Email Id..."
                    onChange={(e) => {
                      handleSearch(e);
                      setCurrentPage(1);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>

            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />{" "}
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              departmentDataFilter?.designation_id,
                              departmentDataFilter?.department_id,
                              departmentDataFilter?.branch_id,
                              "id",
                              "excel",
                              emploc
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              departmentDataForExport.designation_id,
                              departmentDataForExport.department_id,
                              departmentDataForExport.branch_id,
                              "id",
                              "custom"
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Download Emp
                          Directory
                        </button>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.U ? (
                <button className="btn btn-danger" onClick={importuser}>
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Import User
                </button>
                 ) : (
                  ""
                )}
                {finalUrlValue?.A ? (
                  <button
                    type="button"
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addNewEmp(empList);
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add User
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th style={{ width: "20px" }}>S.No.</th>
                <th
                  onClick={(e) => {
                    handlerData("code");
                  }}
                  className={
                    sortData["code"] === undefined
                      ? "unsortcls"
                      : sortData["code"]
                  }
                  width="150"
                >
                  Employee Code
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                  width="150"
                >
                  Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("designation");
                  }}
                  className={
                    sortData["designation"] === undefined
                      ? "unsortcls"
                      : sortData["designation"]
                  }
                  width="150"
                >
                  Designation
                </th>
                <th
                  onClick={(e) => {
                    handlerData("department");
                  }}
                  className={
                    sortData["department"] === undefined
                      ? "unsortcls"
                      : sortData["department"]
                  }
                  width="150"
                >
                  Department
                </th>
                <th
                  onClick={(e) => {
                    handlerData("branch");
                  }}
                  className={
                    sortData["branch"] === undefined
                      ? "unsortcls"
                      : sortData["branch"]
                  }
                  width="150"
                >
                  Location
                </th>
                <th>Mobile No.</th>
                <th>Landline No.</th>
                <th>Office Address</th>
                <th>Geo Location</th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls"
                      : sortData["updated_by"]
                  }
                  width="150"
                >
                  Last updated by
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls"
                      : sortData["updated_at"]
                  }
                  width="150"
                >
                  Last updated on
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                  width="150"
                >
                  Status
                </th>
                {/* {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? ( */}
                <th>Actions</th>
                {/* ) : (
                  ""
                )} */}
              </tr>
            </thead>
            <tbody>
              {empList.length ? (
                empList?.map((data, index) => (
                  <>
                    <tr key={`Empp${index}`}>
                      <td>{pageSizeNo * (currentPage - 1) + (index + 1)}</td>
                      <td className="text-theme fw-bold">{data?.code}</td>
                      <td className="text-theme fw-bold">
                        <div className="d-flex align-items-center">
                          <div className="teamtxt ml-0">
                            <h5 className="mb-0 text-dark">{data?.name}</h5>
                            <p>{data?.email}</p>
                          </div>
                        </div>
                      </td>
                      <td>{data?.designation_name}</td>
                      <td>{data?.department_name}</td>
                      <td>
                        {data?.sub_personal != null
                          ? data?.sub_personal +
                            "(" +
                            data?.sub_personal_name +
                            ")"
                          : " "}
                      </td>
                      <td>
                        {data?.country_code} {data?.mobile}
                      </td>
                      <td>{data?.telephone_ext}</td>
                      <td>
                        <Tooltip title={data?.office_address} position="bottom">
                          {data?.office_address}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={data?.geo_location} position="bottom">
                          {data?.geo_location}
                        </Tooltip>
                      </td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {moment
                          .utc(data?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>

                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {/* 
                      {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? ( */}
                      <td>
                        {/* {finalUrlValue?.V ? ( */}
                        <img
                          src="images/eye.png"
                          alt=""
                          onClick={() => {
                            onView(data);
                          }}
                        />
                        {/* ) : (
                            ""
                          )} */}
                        {finalUrlValue?.E ? (
                          <button
                            className="btn btn-blanktd text-primary ml-2"
                            onClick={() => {
                              editEmp(data);
                            }}
                          >
                            <i className="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        {finalUrlValue?.R ? (
                          <button
                            className="btn btn-blanktd text-danger ml-2"
                            onClick={() => {
                              submit(data?.id, data?.name);
                            }}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                      {/* ) : (
                        ""
                      )} */}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* {empList?.length == 0 ? (
          ""
        ) : ( */}
        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                style={{ textAlign: "center" }}
                onChange={(e) => {
                  handleShow(e);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7 main-pointer">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {/* )} */}
      </div>

      <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            // backdrop="static"
            // keyboard={false}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group innergroup">
                  <label>
                    Select Location
                    <span className="text-danger"></span>
                  </label>
                  <Select
                    name="business_unit_id"
                    isMulti
                    options={subPersonalArea}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      let ValueFilter = e?.map((data) => {
                        return data?.value;
                      });
                      DepartmentFilter(ValueFilter);
                      DesignationFilter(ValueFilter, "");
                      setDeptFil(ValueFilter);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        business_unit_id: e.value,
                      });
                      setCurrentPage(1);
                      setbusiness_unit_id1(e);
                      setemploc(e);
                    }}
                    value={business_unit_id1}
                  />
                </div>

                <div className="form-group innergroup main-pointer">
                  <label>
                    Select Department<span className="text-danger"></span>
                  </label>
                  <Select
                    name="department_id"
                    options={dropDept}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      setdepartment_id1(e.value);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        department_id: e.value,
                      });
                      setCurrentPage(1);
                      setDepartment1(e);
                      DesignationFilter(deptFil, e.value);
                    }}
                    value={department1}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>
                    Select Designation <span className="text-danger"></span>
                  </label>
                  <Select
                    name="designation_id"
                    options={dropDesig}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      setempdesgid(e.value);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        designation_id: e.value,
                      });
                      setCurrentPage(1);
                      setempDesg1(e);
                    }}
                    value={empDesg1}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    setCurrentPage(1);
                  }}
                >
                  CLEAR
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    onFilterSubmit();
                  }}
                  className="btn btn-primary-inner bpi-main"
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i className="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button className="btn btn-primary-inner" type="button">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <button className="editbtn">
                      <i className="far fa-file-excel"></i>
                      <a href="import.xlsx" download>
                        DOWNLOAD TEMPLATE
                      </a>
                    </button>
                  </div>
                  <input
                    type="file"
                    title=""
                    id="import_user"
                    accept=".xlsx, .xls, .csv, .xlsb"
                    className="form-control"
                    onChange={(e) => onProfileChange(e, "import_user")}
                  />
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span className="badge bad-status mb-3 badge-warning">
                    {uploadData?.original_name}{" "}
                    <i className="far fa-times-circle"></i>
                  </span>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => importExel(uploadData)}
                    >
                      Proceed to Upload
                    </button>
                  )}
                </div>

                {/* <div className="drapdroparea upload-btn-wrapper text-center">
             <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
             
                </div>  */}
                {/* <div className="drapdroparea upload-btn-wrapper text-center">
                  <span className="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i className="far fa-times-circle"></i>
                  </span>
                  <button className="btn btn-primary-inner" disabled>
                    Proceed to Upload
                  </button>
                  <div className="w-75 mt-3">
                    <ProgressBar
                      striped
                      variant="success"
                      animated
                      now={45}
                      label="40%"
                    />
                  </div>
                </div> */}

                {showStatus && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>
                      The system was unable to read few records from the
                      uploaded file.
                      <br />
                      Please correct or remove those records from the file and
                      upload again.
                    </p>
                    <span className="badge bad-status mb-3 badge-success">
                      Records Succeded:
                      {excelListImport[0]?.records_uploaded}
                    </span>
                    <span className="badge bad-status mb-3 badge-danger">
                      Records Failed:
                      {excelListImport[0]?.records_failed}
                    </span>

                    <p>Check the uploaded file in case of error.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}

                <table className="table uploadrecordstab">
                  <thead>
                    <tr>
                      <th>Upload Date</th>
                      <th>Uploaded By</th>
                      <th>File Name</th>
                      <th>Records Uploaded</th>
                    </tr>
                  </thead>
                  <tbody>
                    {excelList?.map((data, index) => (
                      <>
                        <tr>
                          <td className="text-theme">
                            {moment
                              .utc(data?.uploaded_date)
                              .format("D-MM-YYYY")}
                          </td>
                          <td>{data?.uploaded_by}</td>
                          <td>{data?.file_name}</td>
                          <td>{data?.records_uploaded}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showForm ? (
        <>
          <AddEmployee
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editEmployee={employee}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewEmployee
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Employee;
