import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import Select from "react-select";
import AddSkillTab from "./AddSkillTab";
import * as SkillTabService from "../../service/skillTab";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { injectStyle } from "react-toastify/dist/inject-style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
  injectStyle();
}

function SkillTab() {
  const dispatch = useDispatch();
  const [skillList, setSkillList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionAccess, setPermissionAccess] = useState({});
  const [showSkillTab, setShowSkillTab] = useState(false)

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSkillTab = (
    search,
    year,
    quarter,
    employee_id,
    skill_id,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    SkillTabService.getSkillTab(
      handleKey(
        search,
        year,
        quarter,
        employee_id,
        skill_id,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addSkills = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillData(null);
  };

  const editSkill = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillData(eData);
  };

  const onClose = () => {
    setShowForm(false);
    setSkillData(null);
  };

  const onAddCallBack = () => {
    setShowForm(false);
    getSkillTab(searchStr, 1, pageSizeNo, "-id", true);
  };

  const deleteSkillTab = (idx) => {
    SkillTabService.deleteSkillTab(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillTab(searchStr, currentPage, pageSizeNo, "-id", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, name) => {
    confirmAlert({
      title: "Delete Skill Tab Access",
      message: `Are you sure to delete ${name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkillTab(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getExportDetails = (search, pageNo, page_size, sort_by, paginate) => {
    SkillTabService.getExportValue(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-quaterly-reports.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleSearch = (e) => {
    getSkillTab(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSkillTab(data, 1, pageSizeNo, "-id", true);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillTab(searchStr, activePage, pageSizeNo, "-id", true);
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err))
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getSkillPermission();
    getSkillTab(searchStr, 1, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  return (
    <>
      {showForm ? (
        <AddSkillTab
          edit={editMode}
          showForm={showForm}
          onClose={onClose}
          editSkills={skillData}
          onAddCallBack={onAddCallBack}
        />
      ) : (
        ""
      )}
      <div>
        <ToastContainer autoClose={1000} />
        <div className="row">
          <div className="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li class="active">
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div className="col-md-9">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">Skill Tab Access</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong>Employee Name</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="input-group table-search">
                        <span className="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e)}
                        />

                        <span className="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              className="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,

                                  1,
                                  pageSizeNo,
                                  "-id",
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        className="btn btn-primary-inner"
                        onClick={() => {
                          addSkills();
                        }}
                      >
                        <img src="images/upload.png" alt="" className="mr-3" />
                        Add New
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Status</th>
                      <th>Employee Code</th>
                      <th>Employee Name</th>
                      <th colspan="2">Skill Master</th>
                      <th colspan="2">Skill Group Master</th>
                      <th colspan="2">Quarterly Report</th>
                      <th colspan="2">Skill Matrix Usage</th>
                      <th colspan="2">User Group Mapping</th>
                      <th colspan="2">Skill Group Mapping</th>
                      <th colspan="2">Skill Department Master</th>
                      <th colspan="2">Skill Plant Master</th>
                      <th colspan="2">Skill Location Master</th>
                      <th colspan="2">Sub Group Master</th>
                      <th colspan="2">Report Master</th>
                    </tr>
                    <tr className="secondthhdata">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                      <th>View</th>
                      <th>Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td>
                              <button
                                className="btn btn-blanktd text-primary f-12"
                                type="button"
                                onClick={() => {
                                  editSkill(data);
                                }}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                              <button className="btn btn-blanktd text-danger ml-2 f-12">
                                <i
                                  className="far fa-trash-alt text-danger"
                                  type="button"
                                  onClick={() => {
                                    submit(data?.id, data?.employee_name);
                                  }}
                                ></i>
                              </button>
                            </td>
                            <td>
                              {data?.is_active ? (
                                <span className="badge bad-status badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bad-status badge-danger">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td className="text-theme fw-bold">
                              {data?.employee_Id}
                            </td>
                            <td>{data?.employee_name}</td>
                            <td>{data?.skill_list_view ? "Yes" : "No"}</td>
                            <td>{data?.skill_list_update ? "Yes" : "No"}</td>
                            <td>{data?.skill_group_view ? "Yes" : "No"}</td>
                            <td>{data?.skill_group_update ? "Yes" : "No"}</td>
                            <td>
                              {data?.quarterly_report_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.quarterly_report_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_matrix_usage_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_matrix_usage_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.user_group_mapping_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.user_group_mapping_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.group_owner_mapping_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.group_owner_mapping_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_department_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_department_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_plant_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_plant_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_location_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_location_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_sub_group_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.skill_sub_group_update ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.report_view ? "Yes" : "No"}
                            </td>
                            <td>
                              {data?.report_update ? "Yes" : "No"}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkillTab;
