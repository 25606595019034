import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";
import { uploadImages } from "../../service/userService";
import {
  getMateriallist,
  getMateriallistDrop,
  getPoOrg,
  getSourcelistPlant,
  getSourcelistVedndor,
  savesourceList,
  updatesourceList,
} from "../../service/source-list";
import { toast } from "react-toastify";

function SourceListForm(props) {
  const {show, close, edit,editMode, getList, editData } = props;
  const [plantlist, setPlantList] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [poOrgList, setPoOrgList] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [flag, setflag] = useState(false);
  const [link, setLink] = useState();
  const [deleteattachment, setdeleteAttachment] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [showRadio, setShowRadio] = useState(false);
  const [errorValidto, setErrorValidto] = useState("");
  const [file, setfiles] = useState([]);
  const options = [
    { value: "Capex", label: "Capex" },
    { value: "Engineering", label: "Engineering" },
    { value: "RM/PM", label: "RM/PM" },
  ];
  const vaendor_category = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
  ];
  const [errors, setErrors] = useState({
    selectPlant_id: false,
    selectPurchase_org_id: false,
    selectMaterial_id: false,
    selectVendor_id: false,
    selectCategory: false,
    selectVendor_category: false,
    selectValid_from: false,
    selectValid_to: false,
    selectAttachment: false,
    selectvalidity: false,
  });
  const [sourceListData, setsourceListData] = useState({
    plant_id: null,
    purchase_org_id: null,
    material_id: null,
    materialName: "",
    vendor_id: null,
    vendorName: "",
    category: "",
    vendor_category: "",
    valid_from: "",
    valid_to: "",
    attachment: [{}],
    validity: "",
  });
  const handleBlur = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "plant_id":
        setErrors((prev) => ({
          ...prev,
          selectPlant_id: true,
        }));
        break;
      case "valid_from":
        setErrors((prev) => ({
          ...prev,
          selectValid_from: false,
        }));
        break;
      case "valid_to":
        setErrors((prev) => ({
          ...prev,
          selectValid_to: false,
        }));
        break;
      case "purchase_org_id":
        setErrors((prev) => ({
          ...prev,
          selectPurchase_org_id: false,
        }));
        break;
      case "material_id":
        setErrors((prev) => ({
          ...prev,
          selectMaterial_id: false,
        }));
        break;
      case "vendor_id":
        setErrors((prev) => ({
          ...prev,
          selectVendor_id: false,
        }));
        break;
      case "category":
        setErrors((prev) => ({
          ...prev,
          selectCategory: false,
        }));
        break;
      case "vendor_category":
        setErrors((prev) => ({
          ...prev,
          selectVendor_category: false,
        }));
      default:
        break;
    }
  };
  // const deleteAttachment = (id) => {
  //   console.log(id);
  //   let obj = {};
  //   Object.keys(file)
  //     .filter((del) => {
  //       if (Number(del) !== Number(id)) {
  //         return true;
  //       }
  //     })
  //     .map((item) => {
  //       obj[item] = file[item];
  //     });

  //   setfiles(obj);
  // };
  // const attachments123 = () => {
  //   let data = [];
  //   for (let i = 0; i < Object.keys(file).length; i++) {
  //     console.log(Object.keys(file)[i]);
  //     let data2 = file[Object.keys(file)[i]];
  //     if (true) {
  //       data.push(
  //         <>
  //           <div class="uploaded-image mr-1">
  //             <a
  //               href={URL.createObjectURL(data2)}
  //               target={"_blank"}
  //               rel="noreferrer"
  //             >
  //               {data2?.name.includes(".pdf") ? (
  //                 <img
  //                   src={"images/policy-icon/pdf.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : data2?.name.includes(".xlsx") ? (
  //                 <img
  //                   src={"images/policy-icon/xlsx.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : data2?.name.includes(".xls") ? (
  //                 <img
  //                   src={"images/policy-icon/xlsx.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : data2?.name.includes(".doc") ? (
  //                 <img
  //                   src={"images/policy-icon/docs.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : data2?.name.includes(".docs") ? (
  //                 <img
  //                   src={"images/policy-icon/docs.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : data2?.name.includes(".pptx") ? (
  //                 <img
  //                   src={"images/policy-icon/ppt.png"}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               ) : (
  //                 <img
  //                   src={URL.createObjectURL(data2)}
  //                   alt="image"
  //                   width="70px"
  //                   height="70px"
  //                 />
  //               )}
  //             </a>
  //             <button
  //               onClick={() => {
  //                 deleteAttachment(Object.keys(file)[i]);
  //               }}
  //               className="btn btn-blanktd text-danger ml-2"
  //             >
  //               <i className="far fa-trash-alt text-danger"></i>
  //             </button>
  //           </div>
  //         </>
  //       );
  //     }
  //   }
  //   return data;
  // };
  // const onProfileChange = async (e, flag) => {
  //   let file = e.target.files;
  //   setfiles(file);

  //   let files = [];
  //   let filename = [];
  //   for (let i = 0; i < file.length; i++) {
  //     let data = e.target.files[i];
  //     const fileData = new FormData();
  //     fileData.append("uploaded_file", data);
  //     await uploadImages(fileData).then((response) => {
  //       let name = { name: data.name };
  //       setErrors((prev) => ({
  //         ...prev,
  //         selectAttachment: false,
  //       }));
  //       filename.push(name);
  //       files.push(response.data);
  //     });
  //   }
  //   setAttachment(filename);
  //   setUploadDataIm(files);
  // };
  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();

      fileData.append("uploaded_file", data);
         await uploadImages(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    }
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const deleteAttachment = (id) => {
    console.log("-------",id);
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (edit == false) {
      let obj = {};
      Object.keys(file)
      .filter((del) => {
        
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("sourceattachment").value = "";

      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("sourceattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {

            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("sourceattachment").value = "";

        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ||
                data2.name.includes(".m4v") ||
                data2.name.includes(".mkv") ||
                data2.name.includes(".mov") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const handleChange = (event, name) => {
    console.log(event, name);
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "plant_id":
        setsourceListData((prev) => ({
          ...prev,
          plant_id: event,
        }));
        setsourceListData((prev) => ({
          ...prev,
          material_id: {},
        }));
        setErrors((prev) => ({
          ...prev,
          selectPlant_id: false,
        }));
        materiallist(event.value);
        break;
      case "valid_from":
        setsourceListData((prev) => ({
          ...prev,
          valid_from: event.target.value,
        }));
        setErrors((prev) => ({
          ...prev,
          selectValid_from: false,
        }));
        break;
      case "valid_to":
        if (
          moment(sourceListData.valid_from).format("YYYY-MM-DD") <
          moment(event.target.value).format("YYYY-MM-DD")
        ) {
          setsourceListData((prev) => ({
            ...prev,
            valid_to: event.target.value,
          }));
          setErrorValidto("");

          setErrors((prev) => ({
            ...prev,
            selectValid_to: false,
          }));
        } else {
          setsourceListData((prev) => ({
            ...prev,
            valid_to: event.target.value,
          }));
          // setErrors((prev) => ({
          //   ...prev,
          //   selectValid_to: true,
          // }))
          setErrorValidto("Same date is not allowed");
        }
        break;
      case "purchase_org_id":
        setsourceListData((prev) => ({
          ...prev,
          purchase_org_id: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectPurchase_org_id: false,
        }));
        setsourceListData((prev) => ({
          ...prev,
          plant_id: {},
          vendor_id: {},
        }));
        plantList(event.value);
        vendorCodeList(event.value);
        break;
      case "material_id":
        setsourceListData((prev) => ({
          ...prev,
          material_id: event,
        }));
        setsourceListData((prev) => ({
          ...prev,
          materialName: event.name,
        }));
        setErrors((prev) => ({
          ...prev,
          selectMaterial_id: false,
        }));
        break;
      case "vendor_id":
        setsourceListData((prev) => ({
          ...prev,
          vendor_id: event,
        }));
        setsourceListData((prev) => ({
          ...prev,
          vendorName: event.name,
        }));

        setErrors((prev) => ({
          ...prev,
          selectVendor_id: false,
        }));
        break;
      case "category":
        setsourceListData((prev) => ({
          ...prev,
          category: event,
        }));
        if (event.value == "RM/PM") {
          setShowRadio(true);
        } else {
          setShowRadio(false);
        }
        setErrors((prev) => ({
          ...prev,
          selectCategory: false,
        }));
        break;
      case "vendor_category":
        setsourceListData((prev) => ({
          ...prev,
          vendor_category: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectVendor_category: false,
        }));
        break;

      case "validity":
        setsourceListData((prev) => ({
          ...prev,
          validity: event.target.value,
        }));
        setErrors((prev) => ({
          ...prev,
          selectvalidity: false,
        }));
        if (event.target.value == "Temporary") {
          setsourceListData((prev) => ({
            ...prev,
            valid_to: moment(new Date()).add(29, "days").format("YYYY-MM-DD"),
          }));
          setsourceListData((prev) => ({
            ...prev,
            valid_from: moment(new Date()).format("YYYY-MM-DD"),
          }));
          setErrors((prev) => ({
            ...prev,
            selectValid_from: false,
          }));
          setErrors((prev) => ({
            ...prev,
            selectValid_to: false,
          }));
        } else {
          setsourceListData((prev) => ({
            ...prev,
            valid_to: "",
          }));
          setsourceListData((prev) => ({
            ...prev,
            valid_from: "",
          }));
        }
        break;
      default:
        break;
    }
  };
  const plantList = (pag_id) => {
    getSourcelistPlant(pag_id)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        // let plant = [];
        // response?.data?.dataList?.result?.map((x) => {
        //   let data = { value: x?.id, label: x?.code+"("+x?.description+")" };
        //   plant.push(data);
        // });
        setPlantList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const materiallist = (plantid,searchData) => {
    let data = { plant_id: plantid,search: searchData};
    getMateriallistDrop(data)
      .then((response) => {
        
        setMaterialList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const vendorCodeList = (pg_id) => {
    getSourcelistVedndor(pg_id)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.label, name:x?.name };
          plant.push(data);
        });
        setvendorList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPoList = () => {
    getPoOrg("-id", false)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code + "(" + x?.desc + ")" };
          plant.push(data);
        });
        setPoOrgList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const ValidateAttachment=()=>{
    if (edit) {
      if (flag == true && file.length === 0) {
        setErrorattachment(true);
        return true
      } else if (flag == false && attachment.length === 0) {
        setErrorattachment(true);
        return true

      }
    } else {
      if (uploadDataIm.length == 0) {
        setErrorattachment(true);
        return true

      }
    }
  }
  const submit = () => {
   
    let valid = true;
    if (sourceListData?.category?.value == "RM/PM") {
      if (sourceListData?.validity === "") {
        valid = false;
      }
    }
    if (
      sourceListData?.plant_id !== null &&
      sourceListData?.purchase_org_id !== null &&
      sourceListData?.material_id !== null &&
      sourceListData?.vendor_id !== null &&
      sourceListData?.category !== "" &&
      sourceListData?.vendor_category !== "" &&
      sourceListData?.valid_from !== "" &&
      sourceListData?.valid_to !== "" &&
      errorValidto == "" &&
      valid &&
      // sourceListData?.validity!== ""&&
      !ValidateAttachment()
    ) {
      sourceListData.attachment = uploadDataIm;
      if(edit==false)
      {
        close();
        savesourceList(sourceListData)
        .then((response) => {
          getList();
          if (response?.data?.dataList?.flag) {
            toast.success(response?.data?.message);
          } else {
            toast.warning(response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });}
        
        else{
          sourceListData.id=editData?.id
          updatesourceList(sourceListData)
        .then((response) => {

          close();
          getList();
          toast.success(response?.data?.message);
          
        })
        .catch((error) => {
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
        }
    } else {
      setErrors((prev) => ({
        ...prev,
        selectPlant_id: sourceListData?.plant_id !== null ? false : true,
        selectPurchase_org_id:
          sourceListData?.purchase_org_id !== null ? false : true,
        selectMaterial_id: sourceListData?.material_id !== null ? false : true,
        // sele:sourceListData?.materialName!== ""?false:true,
        selectVendor_id: sourceListData?.vendor_id !== null ? false : true,
        // :sourceListData?.vendorName!== ""?false:true,
        selectCategory: sourceListData?.category !== "" ? false : true,
        selectVendor_category:
          sourceListData?.vendor_category !== "" ? false : true,
        selectValid_from: sourceListData?.valid_from !== "" ? false : true,
        selectValid_to: sourceListData?.valid_to !== "" ? false : true,
        // selectAttachment: uploadDataIm.length != 0 ? false : true,
        selectvalidity: sourceListData?.validity != "" ? false : true,
      }));
    }
  };
  const getmateriallist=(e)=>{
    let search=e
    if(search!=""){
      materiallist(sourceListData.plant_id.value,e)
    }
  }
  useEffect(() => {
   if (edit==false) {setsourceListData({
      plant_id: null,
      purchase_org_id: null,
      material_id: null,
      materialName: "",
      vendor_id: null,
      vendorName: "",
      category: "",
      vendor_category: "",
      valid_from: "",
      valid_to: "",
      attachment: [{}],
      validity: "",
    });
    setErrors({
      selectPlant_id: false,
      selectPurchase_org_id: false,
      selectMaterial_id: false,
      selectVendor_id: false,
      selectCategory: false,
      selectVendor_category: false,
      selectValid_from: false,
      selectValid_to: false,
      selectAttachment: false,
      selectvalidity: false,
    });}
    else{
      setsourceListData({
        plant_id: {value:editData?.plant_id,label:editData?.plant_code},
        purchase_org_id: {value:editData?.purchase_org,label:editData?.purchase_org_code},
        material_id: {value:editData?.material,label:editData?.material_code+"("+editData?.material_code_name+")"},
        materialName: editData?.material_code_name,
        vendor_id: {value:editData?.vendor_id,label:editData?.vendor_code+"("+editData?.vendor_code_name+")"},
        vendorName: editData?.vendor_code_name,
        category: {value:editData?.category,label:editData?.category},
        vendor_category:{value:editData?.vendor_category,label:editData?.vendor_category},
        valid_from: editData?.valid_from,
        valid_to: editData?.valid_to,
        attachment: [{}],
        validity: "",
      });
      setAttachment(editData?.attachment);

    }
    // plantList();
    // vendorCodeList(0)
    getPoList();
  }, []);
// useEffect(() => {
//   setsourceListData({
//     plant_id: {value:editData?.plant_id,label:editData?.plant_code},
//     purchase_org_id: {value:editData?.purchase_org,label:editData?.purchase_org_code},
//     material_id: {value:editData?.material,label:editData?.material_code+"("+editData?.material_code_name+")"},
//     materialName: editData?.material_code_name,
//     vendor_id: {value:editData?.vendor_id,label:editData?.vendor_code+"("+editData?.vendor_code_name+")"},
//     vendorName: editData?.vendor_code_name,
//     category: {value:editData?.category,label:editData?.category},
//     vendor_category:{value:editData?.vendor_category,label:editData?.vendor_category},
//     valid_from: editData?.valid_from,
//     valid_to: editData?.valid_to,
//     attachment: [{}],
//     validity: "",
//   });
//   console.log(editData);
// }, [editData])

  return (
    <>
      <Modal
        show={show}
        onHide={()=>{close();editMode()}}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Source List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Purchasing Organization<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={poOrgList}
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="purchase_org_id"
                      value={sourceListData.purchase_org_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectPurchase_org_id}
                      className="small text-danger"
                    >
                      Kindly select purchasing organization
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Plant<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={plantlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="plant_id"
                      value={sourceListData.plant_id}
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectPlant_id}
                      className="small text-danger"
                    >
                      Kindly select plant
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Material Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={materialList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      name="material_id"
                      value={sourceListData.material_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                      onInputChange={(e) => {
                        getmateriallist(e);
                      }}
                    />
                    <div
                      hidden={!errors?.selectMaterial_id}
                      className="small text-danger"
                    >
                      Kindly select material code
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Material Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      name="materialName"
                      value={sourceListData.materialName}
                      placeholder="Material Description"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={vendorList}
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      name="vendor_id"
                      value={sourceListData.vendor_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />{" "}
                    <div
                      hidden={!errors?.selectVendor_id}
                      className="small text-danger"
                    >
                      Kindly select vendor code
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      name="vendorName"
                      value={sourceListData.vendorName}
                      placeholder="Vendor Description"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Product Category<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="category"
                      value={sourceListData.category}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    {showRadio && (
                      <div>
                        <label class="mr-2">
                          Validity Type<span class="text-danger">*</span>
                        </label>
                        <label class="logCheck d-inline-block mr-4">
                          Temporary
                          <input
                            name="validity"
                            value="Temporary"
                            type="radio"
                            onChange={(e) => handleChange(e, "validity")}
                            checked={
                              sourceListData?.validity == "Temporary"
                                ? true
                                : false
                            }
                            // checked={glData?.validity == true ? true : false}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          Permanent
                          <input
                            name="validity"
                            value="Permanent"
                            type="radio"
                            onChange={(e) => handleChange(e, "validity")}
                            checked={
                              sourceListData?.validity == "Permanent"
                                ? true
                                : false
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                        <div
                          hidden={!errors?.selectvalidity}
                          className="small text-danger"
                        >
                          Kindly select Validity type
                        </div>
                      </div>
                    )}
                    <div
                      hidden={!errors?.selectCategory}
                      className="small text-danger"
                    >
                      Kindly select product category
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Category<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={vaendor_category}
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="vendor_category"
                      value={sourceListData.vendor_category}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectVendor_category}
                      className="small text-danger"
                    >
                      Kindly select vendor category
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Valid From<span class="text-danger">*</span>
                    </label>
                    <input
                      name="valid_from"
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      value={sourceListData.valid_from}
                      onChange={handleChange}
                      type="date"
                      class="form-control"
                    />
                    <div
                      hidden={!errors?.selectValid_from}
                      className="small text-danger"
                    >
                      Kindly select valid from date
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Valid To<span class="text-danger">*</span>
                    </label>
                    <input
                      name="valid_to"
                      // onBlur={(newValue, action) => {
                      //   handleBlur(newValue, action.name);
                      // }}
                      min={moment(sourceListData.valid_from).format(
                        "YYYY-MM-DD"
                      )}
                      value={sourceListData.valid_to}
                      onChange={handleChange}
                      type="date"
                      class="form-control"
                      disabled={
                        showRadio && sourceListData?.validity == "Temporary"
                      }
                    />
                    <div
                      hidden={!errors?.selectValid_to}
                      className="small text-danger"
                    >
                      Kindly select valid to date
                    </div>

                    <div className="small text-danger">{errorValidto}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Upload File<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      placeholder="(JPG,ELSX,PPT,PPTX,PNG,PDF,TXT Format only)"
                    />
                    <div className="upload-btn-wrapper up-loposition">
                      <button type="button" className="uploadBtn">
                        Browse
                      </button>
                      <input
                        id="sourceattachment"
                        type="file"
                        title=""
                        multiple
                        name="attachment"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={(e) => onProfileChange(e, "attachment")}
                      />
                    </div>
                    {Errorattachment && (
                            <div className="small text-danger">
                              *Attachment is required
                            </div>
                          )}
                    <div>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123()}</div>
                            ) : link ? (
                              <>
                                <div class="d-flex">
                                  <div class="uploaded-image mr-1">
                                    <i class="" style={{ width: "20px" }}>
                                      <a
                                        href={link}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={link}
                                          id="searchbar"
                                          alt=""
                                          style={{ width: "40px" }}
                                        />
                                      </a>
                                    </i>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        deleteAttachment(link?.split("/")[4]);
                                      }}
                                      className="btn btn-blanktd text-danger ml-2"
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : edit === true ? (
                              <>
                                <div class="d-flex">
                                  {attachment?.map((Data) => (
                                    <div class="uploaded-image mr-1">
                                      <i class="" style={{ width: "20px" }}>
                                        <a
                                          href={Data.attachment}
                                          target={"_blank"}
                                          rel="noreferrer"
                                        >
                                          {Data.attachment.includes(
                                            ".pdf"
                                          ) ? (
                                            <img
                                              src={"images/policy-icon/pdf.png"}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".xlsx"
                                            ) ? (
                                            <img
                                              src={
                                                "images/policy-icon/xlsx.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".xls"
                                            ) ? (
                                            <img
                                              src={
                                                "images/policy-icon/xlsx.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".doc"
                                            ) ? (
                                            <img
                                              src={
                                                "images/policy-icon/docs.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".docs"
                                            ) ? (
                                            <img
                                              src={
                                                "images/policy-icon/docs.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".pptx"
                                            ) ? (
                                            <img
                                              src={"images/policy-icon/ppt.png"}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.attachment.includes(
                                              ".mp4"
                                            ) ? (
                                            <img
                                              src={
                                                "images/policy-icon/video.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : (
                                            <img
                                              src={Data.attachment}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          )}
                                        </a>
                                      </i>
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          deleteAttachment(
                                            Data?.attachment?.split("/")[4]
                                          );
                                        }}
                                        className="btn btn-blanktd text-danger ml-2"
                                      >
                                        <i className="far fa-trash-alt text-danger"></i>
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                    <div
                      hidden={!errors?.selectAttachment}
                      className="small text-danger"
                    >
                      Kindly select attachments
                    </div>
                  </div>
                </div>
                {showRadio && (
                  <p style={{ marginLeft: "3%", color: "grey" }}>
                    Note:-Kindly upload the vrf form,RM/PM change Approvals,R&D
                    performance Report.
                  </p>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={submit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SourceListForm;
