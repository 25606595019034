import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillGroupService from "../../service/skillGroupMapping";

const AddSkillGroupMapping = (props) => {
  const nameForm = "Skill Group Master";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [empError, setEmpError] = useState(false);

  const [allGroup, setAllGroup] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [grpError, setGrpError] = useState(false);

  const [levelData, setLevelData] = useState([]);
  const [levelError, setLevelError] = useState(false);

  const [skillData, setSkillData] = useState({
    group_id: [],
    employee_id: 0,
    level: "",
    is_active: true,
  });

  const options = [
    { value: "Creator", label: "Creator" },
    { value: "Reviewer", label: "Approver" },
  ];

  let condition = "false";

  const saveSkillGroup = () => {
    setIsLoading(true);
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
    data["level"] = skillData?.level;
    data["is_active"] = skillData?.is_active;
    
    SkillGroupService.saveSkillGroup(data)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success("Skill Group Mapping Created Successfully");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateSkillGroup = () => {
    setIsLoading(true);
    
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
    data["level"] = skillData?.level;
    data["is_active"] = skillData?.is_active;

   
    SkillGroupService.updateSkillGroup(data, skillData?.id)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success("Skill Group Mapping Updated Successfully");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGroup = (paginate) => {
    SkillGroupService.getGroup(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let groupData = {
            value: x.id,
            label: x.group_name,
          };
          data.push(groupData);
        });
        setAllGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    SkillGroupService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isDataExists = (values) => {
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
    data["level"] = skillData?.level;
    data["is_active"] = skillData?.is_active;
   
    if (condition == "false") {
      condition = "true";
      SkillGroupService.isDataExists(data)
        .then((res) => {
          setIsSkillExist(res?.data?.dataList?.is_exist);
          
          if (res?.data?.dataList?.is_exist === false && editSkills?.id) {
            updateSkillGroup();
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            editSkills?.id === undefined
          ) {
            saveSkillGroup();
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = (values) => {
    let error = false;
    if (edit) {
      if (editSkills?.employee_id !== skillData?.employee_id) {
        error = true;
      }
      if (editSkills?.group_id !== skillData?.group_id) {
        error = true;
      }
      if (editSkills?.level !== skillData?.level) {
        error = true;
      }
    }
    if (error === false && editSkills?.id !== undefined) {
      updateSkillGroup();
    }
    if (error === true && editSkills?.id !== undefined) {
      
      isDataExists();
    }
    if (error === false && editSkills?.id === undefined) {
      isDataExists();
    }
  };

  const DataPush = (event, flag) => {
    if (flag === "employee") {
      setSkillData({
        ...skillData,
        employee_id: event.value,
      });
      setEmployeeData(event);
    }
    if (flag === "group") {
      let obj = event?.map((each)=>{
        return each.value
      })
      
      setSkillData({
        ...skillData,
        group_id: obj,
      });

      setGroupData(event);
    }
    if (flag === "level") {
      setSkillData({
        ...skillData,
        level: event.value,
      });
      setLevelData(event);
    }
    if (flag === "status") {
      setSkillData({
        ...skillData,
        is_active: event.target.value == "true" ? true : false,
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let error = false;

    if (employeeData.length === 0) {
      setEmpError(true);
      error = true;
    }
    if (groupData.length === 0) {
      setGrpError(true);
      error = true;
    }
    if (levelData.length === 0) {
      setLevelError(true);
      error = true;
    }
    if (error === false) {
      AllCheck();
    }
  };

  useEffect(() => {
    getGroup(false);
    getEmployee(false);
  }, []);

  useEffect(() => {
    if (editSkills) {
      let userData = {
        value: editSkills?.employee_id,
        label: editSkills?.owner_name,
      };
      setEmployeeData(userData);
      let groupData = editSkills?.group_details?.map((res)=>{
        return {
          value: res?.group_id,
          label: res?.group_description
        }
      })
      
      setGroupData(groupData);
      let level = {
        value: editSkills?.level,
        label: editSkills?.level === "Creator" ? "Creator" : "Approver",
      };
      setLevelData(level);

      setSkillData(editSkills);
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills]);



  return (
    <>
      <Modal
        show={showForm}
        onHide={onClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <ToastContainer autoClose={1000} />
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {edit === true ? "Edit" : "Add"} SKILL GROUP OWNER
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Group Name<span className="text-danger">*</span>
                      </label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        name="group_id"
                        id="group_id"
                        options={allGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "group");
                          setGrpError(false);
                        }}
                        value={groupData}
                      />
                      {grpError && (
                        <div className="small text-danger">
                          *Group Name is required
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Employee
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="employee_id"
                        id="employee_id"
                        options={allEmployee}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "employee");
                          setEmpError(false);
                        }}
                        value={employeeData}
                      />
                      {empError && (
                        <div className="small text-danger">
                          *Employee Id is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Level
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="level"
                        id="level"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "level");
                          setLevelError(false);
                        }}
                        value={levelData}
                      />
                      {levelError && (
                        <div className="small text-danger">
                          *Level is required
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label className="d-block">
                        Status <span className="text-danger"></span>
                      </label>
                      <label
                        className="logCheck d-inline-block mr-4"
                        htmlFor="active"
                      >
                        Active
                        <input
                          type="radio"
                          name="is_active"
                          id="active"
                          value={true}
                          checked={skillData?.is_active == true ? true : false}
                          onChange={(e) => {
                            DataPush(e, "status");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        className="logCheck d-inline-block"
                        htmlFor="Inactive"
                      >
                        Inactive
                        <input
                          type="radio"
                          name="is_active"
                          id="Inactive"
                          value={false}
                          checked={skillData?.is_active == true ? false : true}
                          onChange={(e) => {
                            DataPush(e, "status");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {isSkillExist && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>
              &nbsp;This Entry Already Exists.
            </Alert>
          )}

          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={(e) => submitHandler(e)}
                  className="btn btn-primary-inner bpi-main"
                >
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddSkillGroupMapping;
