import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import * as SkillUserService from "../../service/skillUser";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { injectStyle } from "react-toastify/dist/inject-style";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import AddSkillUser from "./AddSkillUser";
if (typeof window !== "undefined") {
  injectStyle();
}

function SkillUser() {
  const dispatch = useDispatch();

  const [searchStr, setSearchStr] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const [exDataDown, setExDataDown] = useState({});
  const [downloadButton, setDownloadButton] = useState(false);
  const [permissionAccess, setPermissionAccess] = useState({});
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [showSkillTab, setShowSkillTab] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSkillUser = (search, pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillUserService.getSkillUser(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportDetails = (search, pageNo, page_size, sort_by, paginate) => {
    SkillUserService.getExportValue(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-user.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const addSkills = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillData(null);
  };

  const editSkill = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillData(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setSkillData(null);
  };

  const openFilter = () => {
    importuser();
    setIsLoading(false);
  };

  const onAddCallBack = () => {
    setShowForm(false);
    getSkillUser("", currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const closeModal = () => {
    importsetShow(false);
    setDownloadButton(false);
  };

  const importExel = (values) => {
    setIsLoading(true);
    SkillService.importExelGroup(values)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          setDownloadButton(true);
          setIsLoading(false);
          toast.success("User Group Mapping Bulk Uploaded Successfully");
          getSkillUser("", 1, pageSizeNo, "-id", true);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = (e) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    SkillService.uploadImages(fileData).then((response) => {
      let data = response.data;
      if (response.data.status === true) {
        importExel(data);
      }
    });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SkillUserErr.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };
  const deleteSkillUser = (idx) => {
    SkillUserService.deleteSkillUser(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillUser(
            searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success("User Group Mapping Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deleteMultipleSkillUser = (idx) => {
    
    SkillUserService.multideleteSkillUser(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillUser(
            searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success("User Group Mapping Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const multidelete = () => {
    
    confirmAlert({
      title: "Delete Multiple User Group",
      message: `Are you sure to bulk delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMultipleSkillUser(selectedIds),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete User Group",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkillUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSkillUser(data, 1, pageSizeNo, sortData.sort_by, true);
    }
  };

  const handleSearch = (e) => {
    getSkillUser(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillUser(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(
          (el) => el.name == "Can Add Skill Tab Access"
        );
        if (findIndex != -1) {
          setShowSkillTab(true);
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allItemIds = skillList.map(item => item.employee_id);
      
      setSelectedIds(allItemIds);
    }
    setSelectAll(!selectAll);
  };
  const toggleSelect = (itemId) => {
    const index = selectedIds.indexOf(itemId);
    if (index === -1) {
      setSelectedIds([...selectedIds, itemId]);
    } else {
      const updatedIds = [...selectedIds];
      updatedIds.splice(index, 1);
      setSelectedIds(updatedIds);
    }
  };

  useEffect(() => {
    setSelectedIds([]);
    getSkillPermission();
    getSkillUser(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li class="active">
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {showSkillTab && (
                <li>
                  <Link to="/skill-tab">Skill Tab Access</Link>
                </li>
              )}
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">User Group Mapping</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on
                            <strong>
                              Employee Name
                              <br />
                              Employee Code,
                              <br />
                              Group Name,
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="input-group table-search">
                        <span className="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e)}
                        />

                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {permissionAccess?.user_group_mapping_update === true && (
                        <>
                          <button class="btn btn-danger" onClick={openFilter}>
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Import Data
                          </button>
                          <button
                            className="btn btn-primary-inner"
                            onClick={() => {
                              addSkills();
                            }}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Add New
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-md-3">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                    {permissionAccess?.user_group_mapping_update ===
                              true ?
                              (<th>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectAll &&
                                    selectedIds?.length === skillList?.length
                                  }
                                  onChange={toggleSelectAll}
                                />
                                <button
                                  className="btn btn-blanktd text-danger ml-2"
                                  onClick={multidelete}
                                  disabled={selectedIds.length === 0}
                                >
                                  <i className="far fa-trash-alt text-danger"></i>
                                </button>
                              </th>) : ''
                              }
                      
                      <th>Group Code</th>
                      <th>Sub Group Code</th>
                      <th>Description</th>
                      <th>Department</th>
                      <th>Employee Id</th>
                      <th>Employee Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                          {permissionAccess?.user_group_mapping_update ===
                              true && ( <td>
                                <input
                                  type="checkbox"
                                  checked={selectedIds.includes(data.employee_id)}
                                  onChange={() => toggleSelect(data.employee_id)}
                                />
                              </td>)}
                           
                            <td class="text-theme fw-bold">
                              {data?.group_names?.join("")}
                            </td>
                            <td>{data?.sub_group_name?.join("")}</td>
                            <td>{data?.group_names?.join("")}</td>
                            <td>{data?.group_department}</td>
                            <td>{data?.employee_code}</td>
                            <td>{data?.owner_name}</td>
                            <td>
                              {permissionAccess?.user_group_mapping_update ===
                              true ? (
                                <>
                                  <button
                                    className="btn btn-blanktd text-primary ml-2"
                                    onClick={() => {
                                      editSkill(data);
                                    }}
                                  >
                                    <i className="far fa-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-blanktd text-danger ml-2"
                                    onClick={() => {
                                      submit(data?.employee_id);
                                    }}
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <b>Not Allowed</b>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showForm ? (
        <>
          <AddSkillUser
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editSkills={skillData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      <Modal
        show={importshow}
        onHide={closeModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import User Group Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  {isLoading ? (
                    <div class="loadingss">
                      <p style={{ color: "#3498db" }}>
                        Please wait while uploading the data.
                      </p>
                      <span>
                        <i></i>
                        <i></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <i className="fas fa-file-upload fa-2x"></i>
                      <p>Upload the template file with updated data</p>
                      <button className="btn btn-primary-inner" type="button">
                        DRAG AND DROP HERE OR CHOOSE FILE
                      </button>
                      <div className="mt-3 downloadtemplate">
                        <button className="editbtn">
                          <i className="far fa-file-excel"></i>
                          <a href="skill-user.xlsx" download>
                            DOWNLOAD TEMPLATE
                          </a>
                        </button>
                      </div>
                    </>
                  )}

                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e)}
                  />
                </div>

                {downloadButton && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>Check the uploaded file in case of error.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SkillUser;
