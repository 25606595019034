import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";
export const getPolicyCategoryList = async (
  search,
  pageNo,
  pageSize,
  sortBy,
  paginate
) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_category?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};
export const getPolicyCategory = async (
  search,
  organisation_id,
  policy_category_id,
  status,
  page_no,
  page_size,
  sort_by,
  paginate
) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_category?search=${search}${
      organisation_id == undefined ||
      organisation_id == "" ||
      organisation_id == null
        ? ""
        : `&organisation_id=${organisation_id}`
    }${
      policy_category_id == undefined ||
      policy_category_id == "" ||
      policy_category_id == null
        ? ""
        : `&policy_category_id=${policy_category_id}`
    }${
      status == undefined || status == "" || status == null
        ? ""
        : `&status=${status}`
    }&page_no=${page_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}`
  );
};
export const getPolicyByCategory = async () => {
  return axios.get(`${AUTH_BASE_URL}/policy_client`);
};
export const getAllPolicyCategoryListData = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/policy_category?paginate=${paginate}`);
};

export const savePolicyCategory = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/policy_category`, data);
};

export const updatePolicyCategory = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/policy_category/${id}`, data);
};

export const deletePolicyCategory = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/policy_category/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getModule = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/modules?paginate=${paginate}`);
};

export const isPolicyCategoryExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/policy_category/exists`, isData);
};

export const getAllPolicyCategory = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_category?paginate=${paginate}&sort_by=name`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/policy_category/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

//SUB CATEGORY APIS
export const getPolicySubCategory = async (
  search,
  policy_category_id,
  status,
  page_no,
  page_size,
  sort_by,
  paginate
) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_subcategory?search=${search}${
      policy_category_id == undefined ||
      policy_category_id == "" ||
      policy_category_id == null
        ? ""
        : `&policy_category_id=${policy_category_id}`
    }${
      status == undefined || status == "" || status == null
        ? ""
        : `&status=${status}`
    }&page_no=${page_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}`
  );
};
export const getAllPolicySubCategoryListData = async (
  paginate,
  policy_category_id
) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_subcategory?paginate=${paginate}&policy_category_id=${policy_category_id}`
  );
};

export const savePolicySubCategory = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/policy_subcategory`, data);
};

export const updatePolicySubCategory = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/policy_subcategory/${id}`, data);
};

export const deletePolicySubCategory = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/policy_subcategory/${id}`);
};
export const isPolicySubCategoryExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/policy_subcategory/exists`, isData);
};
