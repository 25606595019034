import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";

const AddEsop = (props) => {
  const { showForm, onClose, esopData, approvalsettings } = props;
  return (
    <Modal
      show={showForm}
      onHide={onClose}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title> {"ESOP"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {esopData.length > 0 ? (
            <>
              <Slider className="clientslider" {...approvalsettings}>
                {esopData?.map((data) => (
                  <>
                    <div>
                      <Link to={data?.URL} target="_blank">
                        <div className="aprlTxt">
                          <p style={{ fontSize: "20px" }}>{data?.Title}</p>
                        </div>
                      </Link>
                    </div>
                  </>
                ))}
              </Slider>
            </>
          ) : (
            <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
              <strong>No Esop Approvals are available.</strong>
            </p>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddEsop;
