import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as BusinessService from "../../../service/businessService";
import { Formik } from "formik";
import * as Yup from "yup";
import AuditLog from "../../audit/auditlog";
import { selectAudit, setOrgData } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field cannot contain only blank spaces")
    // .matches(
    //   /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
    //   "Insert only normal character"
    // )
    .required("*Business Unit Name is required"),
  code: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field cannot contain only blank spaces")
    .max(10, "Max 10 digits")
    .required("*Business Unit Code is required"),
  // organisation_id: Yup.string().required("*Select Organization").nullable(),
  // country_id: Yup.string().required("*Select Country").nullable(),
  // state_id: Yup.string().required("*Select State").nullable(),
  // city_id: Yup.string().required("*Select City").nullable(),
});

const AddBusiness = (props) => {
  const nameForm = "Business Unit Master";
  const { edit, showForm, onClose, editBranch, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [allDept, setAllDept] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isDeptExist, setIsDeptExist] = useState(false);
  const [isDeptCodeExist, setIsDeptCodeExist] = useState(null);
  const [businessUnitHead, setBusinessUnitHead] = useState(null);
  const [payrollHead, setPayrollHead] = useState(null);
  const [ceo, setCeo] = useState(null);
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [branchData, setBranchData] = useState({
    id: 0,
    name: "",
    code: "",
    country_id: "",
    state_id: "",
    city_id: "",
    is_active: true,
    organisation_id: "",
  });
  const [isSelectOrg, setSelectOrg] = useState(false);
  const [isSelectCountry, setSelectCountry] = useState(false);
  const [isSelectState, setSelectState] = useState(false);
  const [isSelectCity, setSelectCity] = useState(false);
  const [chkError, setChkError] = useState({
    orgError: false,
    countryError: false,
    stateError: false,
    cityError: false,
    businessUnitHeadError: false,
    payrollHeadError: false,
    ceoError: false,
  });
  const [dropDownData, setDropDownData] = useState({
    select_organisation: { value: "", label: "" },
    select_country: { value: null, label: null },
    select_state: { value: null, label: null },
    select_city: { value: null, label: null },
  });

  const chkIsActive = () => {
    let flag = false;
    if (dropDownData?.select_organisation.label === "") {
      setChkError((prev) => ({
        ...prev,
        orgError: true,
      }));
      flag = true;
    }
    if(!businessUnitHead){
      setChkError((prev) => ({
        ...prev,
        businessUnitHeadError: true,
      }));
      flag = true;
    }
    if(!payrollHead){
      setChkError((prev) => ({
        ...prev,
        payrollHeadError: true,
      }));
      flag = true;
    }
    if(!ceo){
      setChkError((prev) => ({
        ...prev,
        ceoError: true,
      }));
      flag = true;
    }
    // if (dropDownData?.select_country.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     countryError: true,
    //   }));
    //   flag = true;
    // }
    // if (dropDownData?.select_state.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     stateError: true,
    //   }));
    //   flag = true;
    // }
    // if (dropDownData?.select_city.label === "") {
    //   setChkError((prev) => ({
    //     ...prev,
    //     cityError: true,
    //   }));
    //   flag = true;
    // }
    return flag;
  };
  const saveBranch = async (values) => {
    let flag = chkIsActive();
    if (!flag) {
      setIsLoading(true);
      BusinessService.saveBranch(values)
        .then((response) => {
          if (response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      setSelectOrg(true);
    }
  };

  const updateBranch = (values) => {
    if(chkIsActive()) return;
    setIsLoading(true);
    let datass = values;
    BusinessService.updateBranch(datass)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const dataBlank = () => {
    onClose();
  };
  const getOrganisation = (paginate) => {
    BusinessService.getOrganisation(paginate)
      .then((response) => {
        setAllDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getStateList = (id) => {
    BusinessService.getStateList(id)
      .then((response) => {
        setStateData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getCityList = (id) => {
    BusinessService.getCityList(id)
      .then((response) => {
        setCityData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getCountryList = () => {
    BusinessService.getCountryList()
      .then((response) => {
        setCountryData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const organizationData = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const renderOrgOptions = (optionsList) => {
    let opt = optionsList?.map((item, index) => {
      return {
        key: `org${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };

  const renderCountryOptions = (optionsList) => {
    let opt = optionsList?.map((item, index) => {
      return {
        key: `country${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };

  const renderStateOptions = (optionsList) => {
    console.log(optionsList, "DOOM");
    let opt = optionsList?.map((item, index) => {
      return {
        key: `state${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };
  const renderCityOptions = (optionsList) => {
    let opt = optionsList?.map((item, index) => {
      return {
        key: `city${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };

  const countryListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`country${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const stateListData = (optionsStateList) => {
    console.log(optionsStateList, "optionsStateList");
    return optionsStateList?.map((item, index) => (
      <option
        key={`state${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const cityListData = (optionsCityList) => {
    return optionsCityList?.map((item, index) => (
      <option
        key={`city${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const isBranchExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editBranch?.name == e.target.value) {
      setIsDeptExist(false);
      return;
    } else if (editBranch?.code == e.target.value) {
      setIsDeptCodeExist(false);
      return;
    } else {
      BusinessService.isBranchExists(data)
        .then((res) => {
          if (e.target.name == "name") {
            setIsDeptExist(res?.data?.dataList?.is_exist);
          } else {
            setIsDeptCodeExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const handleDropData = (value, name) => {
    switch (name) {
      case "select_organisation":
        setDropDownData((prev) => ({
          ...prev,
          select_organisation: value,
        }));
        setSelectOrg(false);
        setChkError((prev) => ({
          ...prev,
          orgError: false,
        }));
        break;
      default:
        break;
    }
  };
  const handleDropCountryData = (value, name) => {
    console.log(value, name);
    switch (name) {
      case "select_country":
        setDropDownData((prev) => ({
          ...prev,
          select_country: value,
        }));
        setSelectCountry(false);
        setChkError((prev) => ({
          ...prev,
          countryError: false,
        }));
        break;
      default:
        break;
    }
  };
  const handleDropStateData = (value, name) => {
    console.log(value, name);
    switch (name) {
      case "select_state":
        setDropDownData((prev) => ({
          ...prev,
          select_state: value,
        }));
        setSelectState(false);
        setChkError((prev) => ({
          ...prev,
          stateError: false,
        }));
        break;
      default:
        break;
    }
  };
  const handleDropCityData = (value, name) => {
    console.log(value, name);
    switch (name) {
      case "select_city":
        setDropDownData((prev) => ({
          ...prev,
          select_city: value,
        }));
        setSelectCity(false);
        setChkError((prev) => ({
          ...prev,
          cityError: false,
        }));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getOrganisation(false);
    getCountryList();
    let buh_idx = props.employeeOption.findIndex(obj => obj.id == props.editBranch?.head_id);
    let ceo_idx = props.employeeOption.findIndex(obj => obj.id == props.editBranch?.ceo_id);
    let ph_idx = props.employeeOption.findIndex(obj => obj.id == props.editBranch?.payroll_head_id);
    if(buh_idx > -1) setBusinessUnitHead(props.employeeOption[buh_idx]);
    if(ceo_idx > -1) setCeo(props.employeeOption[ceo_idx]);
    if(ph_idx > -1) setPayrollHead(props.employeeOption[ph_idx]);
  }, []);

  useEffect(() => {
    console.log(editBranch, "ef");
    if (editBranch) {
      setBranchData(editBranch);
      Object.keys(editBranch).forEach((item) => {
        branchData[item] = editBranch[item];
        if (item == "state_id") {
          getCityList(editBranch[item]);
        }
        if (item == "country_id") {
          getStateList(editBranch[item]);
        }
        if (item === "organisation_id") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_organisation: {
              ...prev.select_organisation,
              value: editBranch[item],
            },
          }));
        }
        if (item === "organisation_name") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_organisation: {
              ...prev.select_organisation,
              label: editBranch[item],
            },
          }));
        }
        if (item === "country_id") {
          setDropDownData((prev) => ({
            ...prev,
            select_country: {
              ...prev.select_country,
              value: editBranch[item],
            },
          }));
        }
        if (item === "country_name") {
          setDropDownData((prev) => ({
            ...prev,
            select_country: {
              ...prev.select_country,
              label: editBranch[item],
            },
          }));
        }
        if (item === "state_id") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_state: {
              ...prev.select_state,
              value: editBranch[item],
            },
          }));
        }
        if (item === "state_name") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_state: {
              ...prev.select_state,
              label: editBranch[item],
            },
          }));
        }
        if (item === "city_id") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_city: {
              ...prev.select_city,
              value: editBranch[item],
            },
          }));
        }
        if (item === "city_name") {
          console.log(dropDownData, "heyk");
          setDropDownData((prev) => ({
            ...prev,
            select_city: {
              ...prev.select_city,
              label: editBranch[item],
            },
          }));
        }
      });
    }
  }, [editBranch]);
  console.log(dropDownData, "heykooooo");
  return (
    <>
      <Formik
        initialValues={branchData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!isDeptExist && !isDeptCodeExist) {
            // debugger
            if (values.id) {
              updateBranch({
                ...values,
                organisation_id: dropDownData?.select_organisation.value,
                country_id: dropDownData?.select_country.value,
                state_id: dropDownData?.select_state.value,
                city_id: dropDownData?.select_city.value,
                head_id: businessUnitHead.id,
                ceo_id: ceo.id,
                payroll_head_id: payrollHead.id
              });
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              await saveBranch({
                ...values,
                organisation_id: dropDownData?.select_organisation.value,
                country_id: dropDownData?.select_country.value,
                state_id: dropDownData?.select_state.value,
                city_id: dropDownData?.select_city.value,
                head_id: businessUnitHead.id,
                ceo_id: ceo.id,
                payroll_head_id: payrollHead.id
              });
              AuditLog.addAuditLog(dispatch, nameForm);
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit === true ? "Edit" : "Add"} Business Unit
              </Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div class="row justify-content-center">
                    <ToastContainer autoClose={1000} />
                    <div class="col-md-11">
                      <div class="row">
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Select Organization
                              <span className="text-danger">*</span>
                            </label>
                            {/* <select
                              className="form-control main-pointer"
                              classNamePrefix="select"
                              style={{ width: "334px" }}
                              type="number"
                              name="organisation_id"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setBranchData({
                                  ...branchData,
                                  organisation_id: e.target.value,
                                });
                              }}
                              value={values.organisation_id}
                            >
                              <option
                                value=""
                                label="Select Organization"
                                disabled
                              />

                              {organizationData(allDept)}
                            </select>
                            {touched.organisation_id &&
                            errors.organisation_id ? (
                              <div className="small text-danger">
                                {errors.organisation_id}
                              </div>
                            ) : null} */}
                            <Select
                              options={renderOrgOptions(allDept)}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_organisation"
                              onChange={(newValue, action) => {
                                handleDropData(newValue, action.name);
                              }}
                              value={dropDownData?.select_organisation}
                            />
                            {chkError?.orgError ?
                              <div className="small text-danger">
                                {"*Please select Organisation"}
                              </div> : <></>}
                          </div>
                        </div>

                        {/* </div> */}

                        {/* <div class="row"> */}
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Select Country<span class="text-danger"></span>
                            </label>
                            {/* <select
                              class="form-control newbgselect main-pointer"
                              name="country_id"
                              type="number"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setBranchData({
                                  ...branchData,
                                  country_id: e.target.value,
                                });
                                getStateList(e.target.value);
                                getCityList(0);
                              }}
                              value={Number(values.country_id)}
                            >
                              <option
                                value="0"
                                label="Select Country"
                                disabled
                              />
                              {countryListData(countryData)}
                            </select>
                            {touched.country_id && errors.country_id ? (
                              <div className="small text-danger">
                                {errors.country_id}
                              </div>
                            ) : null} */}

                            <Select
                              options={renderCountryOptions(countryData)}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_country"
                              onChange={(newValue, action, e) => {
                                handleDropCountryData(newValue, action.name);
                                getStateList(newValue.value);
                                getCityList(0);
                              }}
                              value={dropDownData?.select_country}
                            />
                            {chkError.countryError ? (
                              <div className="small text-danger">
                                {"*Please select Country"}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Select State<span class="text-danger"></span>
                            </label>
                            {/* <select
                              class="form-control newbgselect main-pointer"
                              name="state_id"
                              type="number"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setBranchData({
                                  ...branchData,
                                  state_id: e.target.value,
                                });
                                getCityList(e.target.value);
                              }}
                              value={Number(values.state_id)}
                            >
                              <option value="0" label="Select State" />
                              {stateListData(stateData)}
                            </select>
                            {touched.state_id && errors.state_id ? (
                              <div className="small text-danger">
                                {errors.state_id}
                              </div>
                            ) : null} */}

                            <Select
                              options={renderStateOptions(stateData)}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_state"
                              onChange={(newValue, action, e) => {
                                handleDropStateData(newValue, action.name);
                                getCityList(newValue.value);
                              }}
                              value={dropDownData?.select_state}
                            />
                            {chkError.stateError ? (
                              <div className="small text-danger">
                                {"*Please select State"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Select City<span class="text-danger"></span>
                            </label>
                            {/* <select
                              class="form-control newbgselect main-pointer"
                              type="number"
                              name="city_id"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setBranchData({
                                  ...branchData,
                                  city_id: e.target.value,
                                });
                              }}
                              value={Number(values.city_id)}
                            >
                              <option value="0" label="Select City" />

                              {cityListData(cityData)}
                            </select>
                            {touched.city_id && errors.city_id ? (
                              <div className="small text-danger">
                                {errors.city_id}
                              </div>
                            ) : null} */}

                            <Select
                              options={renderCityOptions(cityData)}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_city"
                              onChange={(newValue, action, e) => {
                                handleDropCityData(newValue, action.name);
                                // getCityList(newValue.value);
                              }}
                              value={dropDownData?.select_city}
                            />
                            {chkError.cityError ? (
                              <div className="small text-danger">
                                {"*Please select City"}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Business Unit Code
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Business Unit Code"
                              maxLength={10}
                              name="code"
                              onChange={(e) => {
                                handleChange(e);
                                isBranchExists(e, "code");
                              }}
                              onBlur={handleBlur}
                              value={values.code}
                            />
                            {touched.code && errors.code ? (
                              <div className="small text-danger">
                                {errors.code}
                              </div>
                            ) : isDeptCodeExist === true ? (
                              <div className="small text-danger">
                                Business Unit Code already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Business Unit Name
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Business Unit Name"
                              maxLength={30}
                              name="name"
                              onChange={(e) => {
                                handleChange(e);
                                isBranchExists(e, "name");
                              }}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="small text-danger">
                                {errors.name}
                              </div>
                            ) : isDeptExist === true ? (
                              <div className="small text-danger">
                                Business Unit Name already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Select Business Unit Head
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={props.employeeOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_business_unit"
                              onChange={obj => setBusinessUnitHead(obj)}
                              value={businessUnitHead}
                            />
                            {chkError?.businessUnitHeadError ? (
                              <div className="small text-danger">
                                {"*Please select business unit head"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Select Payroll Head
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={props.employeeOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_payroll"
                              onChange={obj => setPayrollHead(obj)}
                              value={payrollHead}
                            />
                            {chkError?.payrollHeadError ? (
                              <div className="small text-danger">
                                {"*Please select payroll head"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Select CEO
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={props.employeeOption}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_ceo"
                              onChange={obj => setCeo(obj)}
                              value={ceo}
                            />
                            {chkError?.ceoError ? (
                              <div className="small text-danger">
                                {"*Please select CEO"}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Pincode<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              maxLength={6}
                              class="form-control"
                              placeholder="Enter Pincode Name"
                              name="postal_code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postal_code}
                            />
                            {touched.postal_code && errors.postal_code ? (
                              <div className="small text-danger">
                                {errors.postal_code}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Address Line 1<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Address Line 1"
                              maxLength={60}
                              name="address_line_1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line_1}
                            />
                            {touched.address_line_1 && errors.address_line_1 ? (
                              <div className="small text-danger">
                                {errors.address_line_1}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Address Line 2<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Address Line 2"
                              maxLength={60}
                              name="address_line_2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line_2}
                            />
                            {touched.address_line_2 && errors.address_line_2 ? (
                              <div className="small text-danger">
                                {errors.address_line_2}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Address Line 3<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Address Line 3"
                              maxLength={60}
                              name="address_line_3"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line_3}
                            />
                            {touched.address_line_3 && errors.address_line_3 ? (
                              <div className="small text-danger">
                                {errors.address_line_3}
                              </div>
                            ) : null}
                          </div>
                        </div> */}

                        <div className="col-md-12">
                          <div className="form-group innergroup">
                            <label className="d-block">Status</label>
                            <label
                              className="logCheck d-inline-block mr-4"
                              htmlFor="active"
                            >
                              Active
                              <input
                                type="radio"
                                name="is_active"
                                id="active"
                                value={values.is_active}
                                onChange={() => {
                                  setFieldValue("is_active", true);
                                }}
                                checked={values.is_active === true}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block"
                              htmlFor="Inactive"
                            >
                              Inactive
                              <input
                                type="radio"
                                name="is_active"
                                id="Inactive"
                                value={values.is_active}
                                onChange={(e) => {
                                  setFieldValue("is_active", false);
                                }}
                                checked={values.is_active === false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          dataBlank();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddBusiness;
