import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getReleaseCode = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/purchase-org?${queryParm}`);
  };

  export const saveReleaseCode = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/purchase-org`, data);
  };

  export const updateReleaseCode = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/purchase-org/${id}`, data);
  };

  export const deleteReleaseCode = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/purchase-org/${id}`);
  };

  export const getExportValue = async (search,sort_by) => {
    return axios({
      url: `${AUTH_BASE_URL}/purchase-org/export?search=${search}&sort_by=${sort_by}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const isCodeExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/purchase-org/exists`, isData);
  };