/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as MyProfileService from "../../service/profile";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { data } from "jquery";
import { updateUserPassword } from "../../service/delegateService";
import { toast } from "react-toastify";
import { logoutUser } from "../../service/userService";

function Myprofile({ myRightsData }) {
  const dispatch = useDispatch();
  const [barList, setBarList] = useState([]);
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const userData = useSelector(selectUserData);
  const { instance, accounts } = useMsal();
  const [myUrlLink, setMyUrlLink] = useState([]);
  const isAuthenticated = useIsAuthenticated();
  const [passwordType5, setPasswordType5] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const togglePassword2 = () => {
    if (passwordType5 === "password") {
      setPasswordType5("text");
      return;
    }
    setPasswordType5("password");
    return;
  };
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
    return;
  };

  let navigate = useNavigate();
  const logoutHandle = async () => {
    dispatch(setDisplayLoader("Display"));
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        if (localStorage.getItem("fromAd") === "true") {
          handleLogout("redirect");
          // sessionStorage.clear();
          localStorage.clear();
        } else {
          window.location.replace("/custom");
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("delegatedLogin");
          localStorage.removeItem("skipped");
          localStorage.clear();
        }
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };

  // useEffect(() => {
  //   if (localStorage.getItem("fromAd") === "true" && !isAuthenticated) {
  //     localStorage.removeItem("accesstoken");
  //   }
  // }, [isAuthenticated]);

  // const saveSsoList = async (value) => {
  //   let handleValue = {
  //     action: value,
  //   };
  //   MyProfileService.saveSsoList(handleValue)
  //     .then((response) => {
  //       let data = response?.data?.dataList?.result;
  //       const url = data[0]?.url;
  //       window.open(url, "_blank");
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const [changeshow, changesetShow] = useState(false);
  const changeopen = () => changesetShow(true);
  const changeclose = () => changesetShow(false);

  const [impershow, impersetShow] = useState(false);
  const imperopen = () => impersetShow(true);
  const imperclose = () => impersetShow(false);
  const userDetails = useSelector(selectUserData);
  const [vmsData, setVmsData] = useState([]);

  useEffect(() => {
    // console.log("name=====",myRightsData?.result)
    setBarList(myRightsData?.result);
  }, [myRightsData]);
  const OldPassword = (e) => {
    setoldpassword(e.target.value);
  };
  const NewPassword = (e) => {
    setpassword(e.target.value);
  };
  const ConFirmPassword = (e) => {
    setconfirmpassword(e.target.value);
  };

  const UpdateUserPassword = (id) => {
    const regexpassword =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    // setIsLoading(true);
    let payload = {
      oldpassword: oldpassword,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
    };
    if (newpassword == confirmpassword) {
      if (regexpassword.test(newpassword)) {
        updateUserPassword(payload)
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              changeclose();
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      } else {
        toast.error(
          "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        );
      }

      //   setExDataDown(response.data);
      //   console.log(response);
      //   if (response.status === 200) {
      //     setIsLoading(false);
      //     getDirectoryDetails();
      //   }
      // })
      // .catch((error) => {
      //   setIsLoading(false);
      //   console.log(
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //       "failed"
      //   );
      //   toast.error("Kindly Check the format");
      // });
    } else {
      toast.error("New password and confirm password should be same");
    }
  };

  // useEffect(() => {
  //   userData?.is_sf_user &&
  //   saveVmsDataList(userData?.username);
  // }, []);

  const saveVmsDataList = async (value) => {
    MyProfileService.saveVmsDataList(value)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setMyUrlLink(data);
        console.log("myUrlLink", myUrlLink);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="notificationbox">
        <p class="last-login">
          Last login:
          <br />
          <strong>
            {moment
              .utc(userData && userData?.last_login_at)
              .format("Do MMM YYYY, h:mm:ss A")}
          </strong>
        </p>
        <ul>
          {barList?.PROF_M?.map((items, index) => (
            <li key={`my-profile-bar-list-${index}`}>
              {items?.url_type === "INTR" ? (
                <Link
                  to={items.url}
                  state={{ permission: items }}
                  eventKey={items.name}
                  key={`my-profile-links-${index}`}
                >
                  <img src={items.icon_url} alt="" />
                  {items.name}
                </Link>
              ) : (
                <a
                  class="w-100 d-flex justify-content-between align-items-center"
                  href={items.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <img src={items.icon_url} alt="" /> {items.name}
                  </span>
                  {/* <i class="fas fa-chevron-right"></i> */}
                </a>
              )}
            </li>
          ))}
          {/* <li>
            <Link to="/delegate">
              <span>
                <img src="images/delegate.png" alt="" /> Delgate
              </span>
            </Link>
          </li> */}
          {userData?.is_sf_user === false && (
            <li onClick={changeopen}>
              <span>
                <img src="images/change-pass.png" alt="" /> Change Password
              </span>{" "}
              {/* <i class="fas fa-chevron-right"></i>{" "} */}
            </li>
          )}
          {/* <i class="fas fa-chevron-right"></i>{" "} */}
          <li onClick={() => logoutHandle()}>
            <Link>
              <img src="images/icon/logout-hover.svg" alt="" /> Logout
            </Link>
          </li>
        </ul>
      </div>

      <Modal
        show={changeshow}
        onHide={changeclose}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Old Password<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Old Password"
                      onChange={(e) => OldPassword(e)}
                    />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      New Password<span class="text-danger">*</span>
                    </label>
                    <input
                      type={passwordType1}
                      class="form-control"
                      placeholder="Enter New Password"
                      onChange={(e) => NewPassword(e)}
                    />
                    <i
                      className={
                        passwordType1 === "password"
                          ? "far fa-eye-slash eyepassword"
                          : "far fa-eye eyepassword"
                      }
                      style={{ marginTop: "-15px", marginRight: "10px" }}
                      onClick={() => togglePassword1()}
                    ></i>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Confirm Password<span class="text-danger">*</span>
                    </label>
                    <input
                      type={passwordType5}
                      class="form-control"
                      placeholder="Enter Confirm Password"
                      onChange={(e) => ConFirmPassword(e)}
                      value={confirmpassword}
                    />
                    <i
                      className={
                        passwordType5 === "password"
                          ? "far fa-eye-slash eyepassword"
                          : "far fa-eye eyepassword"
                      }
                      style={{ marginTop: "-15px", marginRight: "10px" }}
                      onClick={() => togglePassword2()}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={changeclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={UpdateUserPassword}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={impershow}
        onHide={imperclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter withouthead"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-12">
                  <div class="user-imageemper">
                    <img src="images/4.png" alt="" />
                  </div>
                  <div class="empertitle">
                    <h4>Impersonate</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3 col-6">
                      <div class="img-sectionemper mb-3 mb-md-0">
                        <div class="user-imageemper">
                          <img src="images/5.png" alt="" />
                        </div>
                        <h4>John peter</h4>
                        <p>John@mypi.com</p>

                        <label class="logCheck">
                          <input type="radio" name="permission" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3 col-6">
                      <div class="img-sectionemper mb-3 mb-md-0">
                        <div class="user-imageemper">
                          <img src="images/3.png" alt="" />
                        </div>
                        <h4>John peter</h4>
                        <p>John@mypi.com</p>

                        <label class="logCheck">
                          <input type="radio" name="permission" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3 col-6">
                      <div class="img-sectionemper mb-3 mb-md-0">
                        <div class="user-imageemper">
                          <img src="images/1.png" alt="" />
                        </div>
                        <h4>John peter</h4>
                        <p>John@mypi.com</p>

                        <label class="logCheck">
                          <input type="radio" name="permission" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3 col-6">
                      <div class="img-sectionemper mb-3 mb-md-0">
                        <div class="user-imageemper">
                          <img src="images/2.png" alt="" />
                        </div>
                        <h4>John peter</h4>
                        <p>John@mypi.com</p>

                        <label class="logCheck">
                          <input type="radio" name="permission" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={imperclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Myprofile;
