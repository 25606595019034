import React, { useState, useEffect } from "react";
import * as TravelLists from "../../service/travellist";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function TravelLogAmount() {
  const [pageCount, setpageCount] = useState(1);
  const [travelLogList, setTravelLogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });

  const getTravelLogValueList = (
    page_no,
    page_size,
    sort_by,
    paginate,
    search
  ) => {
    TravelLists.getTravelLogValueList(
      handleKey(page_no, page_size, sort_by, paginate, search)
    )
      .then((response) => {
        setTravelLogList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (page_no, page_size, sort_by, paginate, search) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getTravelLogValueList(
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        e.target.value.toLowerCase()
      );
      setSearchStr(e.target.value);
    }
  };

  useEffect(() => {
    getTravelLogValueList(1, pageSizeNo, sortData.sort_by, true, searchStr);
  }, [pageSizeNo]);

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelLogValueList(1, pageSizeNo, sortData.sort_by, true, data);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelLogValueList(
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      searchStr
    );
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Travel Log</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>City Master </Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              {/* <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on
                      <strong>
                        State Name,
                        <br />
                        City Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on State Name, City Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger> */}
            </div>
            <div class="col-lg-8"></div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Travel Intimation Id</th>
                <th
                //   onClick={(e) => {
                //     handlerData("name");
                //   }}
                //   className={
                //     sortData["name"] === undefined
                //       ? "unsortcls main-pointer"
                //       : sortData["name"]
                //   }
                >
                  Advance
                </th>
                <th>Sap Id</th>
                <th>Sap Message</th>
                <th>Sap Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {travelLogList?.length ? (
                travelLogList?.map((data, index) => (
                  <tr>
                    <td
                      style={{
                        whiteSpace: "pre",
                      }}
                    >
                      {data?.travel_intimation_id}
                    </td>
                    <td
                      style={{
                        whiteSpace: "pre",
                      }}
                      class="text-theme fw-bold"
                    >
                      {data?.advance}
                    </td>
                    <td>{data?.sap_id}</td>
                    <td>{data?.advance_sap_message}</td>
                    <td>{data?.sap_status}</td>
                    <td>
                      {moment.utc(data?.created_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {travelLogList?.length == 0 ? (
          ""
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TravelLogAmount;
