import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getInstrumentType = async () => {
  return axios.get(`${AUTH_BASE_URL}/cre/cre_instrument_list`);
};

export const getInstrumentLocation = async () => {
  return axios.get(`${AUTH_BASE_URL}/cre/cre_location_list`);
};

export const getCreData = async () => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/master-gl-code/list`);
};

export const getMakingList = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/cre/cre_manufacturer/${id}`);
};

export const getBusinessAreaList = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-business-area/list`
  );
};
export const updateCre = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/cre/cre_instrument_request/${id}`, data);
};

export const getCreList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/cre/cre_instrument_request?${queryParm}`);
};
export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const saveCreInstrument = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/cre/cre_instrument_request`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/cre/cre_instrument_request/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
