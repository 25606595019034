import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Breadcrumb, Badge, Tabs, Tab, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { CustomBreadcrumb } from "./custom-breadcrump";
import * as MediclaimService from "../../service/medicalService";
import AddMediclamPremiumModal from "./modals/add-mediclaim-premium";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from 'react-select'
import { getUsersListDataNew } from "../../service/userService";
import { getOrganisation } from "../../service/organisation";
import { getEmpSubGroups } from "../../service/roleRightMgt";

const CRUMB_ITEMS = [
  { to: "/home", title: "Home" },
  { to: "/", title: "Me@PI" },
  { active: true, title: "Medical Premuim" },
];

const noDataFound = (howMuchSpan, loading) => {
  return (
    <tr>
      <td colSpan={howMuchSpan} className="text-center">
        {" "}
        {loading ? "Loading" : "No Record Found"}
      </td>
    </tr>
  );
};

const MediclaimPremium = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [filter, setFilter] = useState({ sort_by: "id" });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [subGroupList, setSubGroupList] = useState([]);
  const [organisationList, setOrganisationList] = useState([]);
  const [userList, setUserList] = useState([])
  const [filterMediclaim, setFilterMediclaim] = useState({
    employee_sub_group: "",
    age: "",
    sum_insured: "",
    user_status: "",
    emp_code: "",
    organisation: "",
    fin_year: ""
  });
  const handleSearch = (e) => {
    getData(
      e.target.value.toLowerCase(),
      filter,
      1,
      pageSizeNo,
      "id",
      true,
      filterMediclaim.employee_sub_group,
      filterMediclaim.age,
      filterMediclaim.sum_insured,
      filterMediclaim?.emp_code,
      filterMediclaim?.user_status
    );
    setSearchStr(e.target.value);
  };

  /* Do not remove this function, Pagination is removed for the time being. Will be added back */
  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(
      searchStr,
      filter,
      activePage,
      pageSizeNo,
      "id",
      true,
      filterMediclaim.employee_sub_group,
      filterMediclaim.age,
      filterMediclaim.sum_insured,
      filterMediclaim?.emp_code,
      filterMediclaim?.user_status
    );
  };

  const handleKey = (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    employee_sub_group,
    age,
    sum_insured,
    user,
    emp_status
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      queryParm = queryParm;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      employee_sub_group !== "" &&
      employee_sub_group !== undefined &&
      employee_sub_group !== null
    ) {
      queryParm = queryParm + "&employee_sub_group=" + employee_sub_group?.map((each) => each?.value).toString();
    }
    if (
      filterMediclaim?.organisation !== "" &&
      filterMediclaim?.organisation !== undefined &&
      filterMediclaim?.organisation !== null
    ) {
      queryParm = queryParm + "&organisation=" + filterMediclaim?.organisation?.map((each) => each?.value).toString();
    }

    if (
      filterMediclaim?.fin_year !== "" &&
      filterMediclaim?.fin_year !== undefined &&
      filterMediclaim?.fin_year !== null
    ) {
      queryParm = queryParm + "&fin_year=" + filterMediclaim?.fin_year;
    }

    if (age !== "" && age !== undefined && age !== null) {
      queryParm = queryParm + "&age=" + age;
    }
    if (
      sum_insured !== "" &&
      sum_insured !== undefined &&
      sum_insured !== null
    ) {
      queryParm = queryParm + "&sum_insured=" + sum_insured;
    }
    if (
      user !== "" &&
      user !== undefined &&
      user !== null
    ) {
      queryParm = queryParm + "&user_id=" + user?.value;
    }
    if (
      emp_status !== "" &&
      emp_status !== undefined &&
      emp_status !== null
    ) {
      queryParm = queryParm + "&emp_status=" + emp_status;
    }
    queryParm = queryParm + '&from_admin=' + true;
    return queryParm;
  };

  const getData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    employee_sub_group,
    age,
    sum_insured,
    user,
    emp_status
  ) => {
    setIsLoading(true);
    await MediclaimService.getMediclaimPremiums(
      handleKey(
        search,
        filter,
        page_no,
        page_size,
        sort_by,
        paginate,
        employee_sub_group,
        age,
        sum_insured,
        user,
        emp_status
      )
    )
      .then((response) => {
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refreshData = () => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      "",
      "",
      "",
      "",
      ""
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(filterMediclaim));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    getData("", filter, 1, pageSizeNo, filter.sort_by, true, "", "", "", "", "");
    setFilterMediclaim(ClearData);
    filterClose();
  };

  const mainDataFilter = () => {
    const employee_sub_group = filterMediclaim.employee_sub_group;
    const age = filterMediclaim.age;
    const sum_insured = filterMediclaim.sum_insured;
    getData(
      "",
      filter,
      1,
      pageSizeNo,
      filter.sort_by,
      true,
      employee_sub_group,
      age,
      sum_insured,
      filterMediclaim?.emp_code,
      filterMediclaim?.user_status
    );
    filterClose();
  };

  const handleModal = () => {
    setAddEditModal((prev) => !prev);
    setEditData(null)
  };

  useEffect(() => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true,
      filterMediclaim.employee_sub_group,
      filterMediclaim.age,
      filterMediclaim.sum_insured,
      filterMediclaim?.emp_code,
      filterMediclaim?.user_status
    );
    // getUserList();
    getOrgList();
    getEmpSubGroup();
  }, [pageSizeNo]);

  const getEmpSubGroup = async () => {
    await getEmpSubGroups().then((response) => {
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res.emp_sub_group,
          label: res.emp_sub_group,
        };
      });
      setSubGroupList(obj);
    });
  };

  const getOrgList = () => {
    getOrganisation(`paginate=${false}`).then((res) => {
      if (res?.status === 200) {
        setOrganisationList(res?.data?.dataList?.result?.map((each) => {
          return ({
            label: `${each?.name} (${each?.code})`,
            value: each?.id
          })
        }))
      }
    })
  }

  const getUserList = async () => {
    await getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserList(res);
      }
    });
  };

  const exportData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate,
    employee_sub_group,
    age,
    sum_insured
  ) => {
    setIsLoading(true);
    const response = await MediclaimService.exportMediclaimPremium(
      handleKey(
        search,
        filter,
        page_no,
        page_size,
        sort_by,
        paginate,
        employee_sub_group,
        age,
        sum_insured
      )
    );
    setIsLoading(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Mediclaim Premium.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const editForm = (data) => {
    setAddEditModal(true);
    setEditData(data)
  }

  return (
    <>
      {/* Modals */}
      <AddMediclamPremiumModal
        isOpen={addEditModal}
        edit={editData}
        handleModalClose={handleModal}
        refreshData={refreshData}
      />
      <div class="row">
        {/* <div class="col-md-3">
                    <Sidebar />
                </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Mediclaim Premium</h4>
                  {/* <CustomBreadcrumb items={CRUMB_ITEMS} /> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <br />
                            <strong>
                              Sum assured, <br />
                              Premium,
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search on Sum Assured, Premium..."
                        onChange={(e) => handleSearch(e)}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              exportData(
                                searchStr,
                                filter,
                                1,
                                pageSizeNo,
                                "id",
                                true,
                                filterMediclaim.employee_sub_group,
                                filterMediclaim.age,
                                filterMediclaim.sum_insured
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary" onClick={handleModal}>
                      Add More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Sum Insured</th>
                      <th>ESG</th>
                      <th>Lower Age Limit</th>
                      <th>Upper Age Limit</th>
                      <th>Premium</th>
                      <th>Organisation Name</th>
                      <th>Updated At</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.length > 0
                      ? list.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item?.sum_insured}</td>
                            <td>{item?.employee_sub_group}</td>
                            <td>{item?.lower_age_limit}</td>
                            <td>{item?.upper_age_limit}</td>
                            <td>{item?.premium}</td>
                            <td>
                              {item?.organisation_name} (
                              {item?.organisation_code})
                            </td>
                            <td>
                              {item?.updated_at &&
                                moment
                                  .utc(item?.updated_at)
                                  .format("Do MMM YYYY, h:mm:ss A")}
                            </td>
                            {item?.is_active == true ? (
                              <td>
                                <span class="badge bad-status badge-success">
                                  Active
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span class="badge bad-status badge-danger">
                                  InActive
                                </span>
                              </td>
                            )}
                            <td>
                              <button
                                class="btn btn-blanktd text-primary ml-2"
                                onClick={() => {
                                  editForm(item)
                                }}
                              >
                                <i class="far fa-edit"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                      : noDataFound(8, isLoading)}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Employee Sub Group</label>
            <Select
              isMulti
              name="employee_sub_group"
              options={subGroupList}
              onChange={(e) =>
                setFilterMediclaim({
                  ...filterMediclaim,
                  employee_sub_group: e,
                })
              }
              value={filterMediclaim.employee_sub_group}
            />
          </div>

          <div class="form-group innergroup">
            <label>Organisation</label>
            <Select
              isMulti
              name="employee_sub_group"
              options={organisationList}
              onChange={(e) =>
                setFilterMediclaim({
                  ...filterMediclaim,
                  organisation: e,
                })
              }
              value={filterMediclaim.organisation}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select Status <span class="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="status"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setFilterMediclaim((prev) => ({
                  ...prev,
                  user_status: e?.target?.value
                }));
              }}
              value={filterMediclaim.user_status}
            >
              <option value="" label="Select" disabled />
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Age</label>
            <input
              type="number"
              class="form-control"
              name="age"
              onChange={(e) =>
                setFilterMediclaim({
                  ...filterMediclaim,
                  age: e.target.value,
                })
              }
              value={filterMediclaim.age}
            />
          </div>

          <div class="form-group innergroup">
            <label>Year</label>
            <input
              type="text"
              class="form-control"
              name="year"
              onChange={(e) =>
                setFilterMediclaim((prev) => ({
                  ...prev,
                  fin_year: e.target.value,
                }))
              }
              value={filterMediclaim.fin_year}
            />
          </div>

          <div class="form-group innergroup">
            <label>Sum-Insured</label>
            <input
              type="number"
              class="form-control"
              name="sum_insured"
              onChange={(e) =>
                setFilterMediclaim({
                  ...filterMediclaim,
                  sum_insured: e.target.value,
                })
              }
              value={filterMediclaim.sum_insured}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MediclaimPremium;
