import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as LeaveService from "../../service/leaveService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";

const EditLeaveAllocation = (props) => {
  const nameForm = " Leave Allocation Master";
  const {
    showEditForm,
    onClose,
    editLeaveManage,
    onAddCallBack,
    userData,
    isDisabled,
  } = props;
  const [leaveAllocationDataUpdate, setleaveAllocationDataUpdate] = useState({
    data: editLeaveManage.allocated,
  });
  const [backUpData, setBackUpData] = useState({
    data: editLeaveManage.allocated,
  });

  useEffect(() => {
    let lType = ["COL", "PL", "OL"];
    editLeaveManage?.allocated.map((obj) => {
      if (obj !== "None") {
        if (isDisabled) {
          obj.disabled = true;
        } else {
          if (lType?.includes(obj?.code)) {
            obj.disabled = true;
          } else {
            obj.disabled = false;
          }
        }
      }
    });
  }, [editLeaveManage]);

  // const getleaveAllocationList = () => {
  //   LeaveService.getLeaveManageDataList()
  //     .then((response) => {
  //       setLeaveTypeData(response?.data?.dataList?.result);
  //       let data = response?.data?.dataList?.result.map((it) => {
  //         return {
  //           id: it.id,
  //           leaves: it.leaves,
  //           leave_type_name: it.leave_type_name,
  //           max_leave: it.max_leave,
  //         };
  //       });
  //       setleaveAllocationDataUpdate(() => ({
  //         data: data,
  //       }));
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const updateLeaveDays = (leaveAllocationDataUpdate) => {
    LeaveService.updateLeaveAllocationDays(
      editLeaveManage.user_id,
      leaveAllocationDataUpdate
    )
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          toast.success("Leave Allocation Updated");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteLeave = (idx) => {
    LeaveService.deleteLeaveAllocate(idx)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id, policyName) => {
    // onClose();
    confirmAlert({
      title: "Delete Leave Type",
      message: `Are you sure to delete ${policyName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteLeave(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleLeave = (id, e, i) => {
    let dataCopy = JSON.parse(JSON.stringify(leaveAllocationDataUpdate.data));
    dataCopy[i].leave_allocated_days = e.target.value + "";
    dataCopy[i].id = id;
    setleaveAllocationDataUpdate(() => ({
      data: dataCopy,
    }));
  };

  return (
    <Formik
      initialValues={leaveAllocationDataUpdate}
      onSubmit={async (values) => {
        updateLeaveDays(leaveAllocationDataUpdate);
        await new Promise((resolve) => setTimeout(resolve, 500));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div>
          <Modal
            show={showEditForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Allocation</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <ToastContainer autoClose={1000} />
                  <div class="row justify-content-center">
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Employee Name<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              value={userData?.first_name}
                              disabled
                              class="form-control"
                              placeholder="Enter Employee Name"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Organization<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              value={`${userData?.organisation_name} (${userData?.organisation_code})`}
                              disabled
                              class="form-control"
                              placeholder="Enter Organization"
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Business Unit<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              value={`${userData?.business_unit_name} (${userData?.business_unit_code})`}
                              disabled
                              class="form-control"
                              placeholder="Enter Organization"
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <table class="table table-striped table-bordered tablecured leavetableapply">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Leave Type</th>
                                <th>Leaves</th>
                                <th>Max. Leave in One Time</th>
                                {/* <th>Actions</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {leaveAllocationDataUpdate?.data.map(
                                (ltdata, i) => {
                                  return (
                                    ltdata !== "None" && (
                                      <>
                                        <tr class="innergroup">
                                          <td>{i + 1}</td>
                                          <td>
                                            <strong>
                                              {ltdata?.leave_type_name}
                                            </strong>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              class="form-control"
                                              value={
                                                ltdata?.leave_allocated_days
                                              }
                                              placeholder="Enter Leaves"
                                              disabled={ltdata?.disabled}
                                              name="leave_allocated_days"
                                              onBlur={handleBlur}
                                              onChange={(e) => {
                                                console.log(
                                                  e.target.value,
                                                  backUpData.data[i]
                                                );
                                                if (
                                                  e.target.value >=
                                                  backUpData.data[i]
                                                    ?.leave_allocated_days
                                                ) {
                                                  ltdata.error = false;
                                                  handleLeave(ltdata.id, e, i);
                                                } else {
                                                  ltdata.error = true;
                                                  handleLeave(ltdata.id, e, i);
                                                }
                                              }}
                                            />
                                            {ltdata?.error ? (
                                              <div className="small text-danger">
                                                {`Value must be less than ${backUpData.data[i]?.leave_allocated_days}`}
                                              </div>
                                            ) : null}
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              class="form-control"
                                              value={ltdata?.leave_max_in_month}
                                              disabled
                                            />
                                          </td>
                                          {/* <td>
                                          {ltdata?.disabled === false && (
                                            <a
                                              class="btn btn-blanktd text-danger"
                                              onClick={() => {
                                                submit(ltdata.id, "Leave Type");
                                              }}
                                            >
                                              <i class="far fa-trash-alt"></i>
                                            </a>
                                          )}
                                        </td> */}
                                        </tr>
                                      </>
                                    )
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    class="btn btn-outline-danger"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button class="btn btn-primary-inner bpi-main" type="submit">
                    Submit
                  </button>
                </Modal.Footer>
              </>
            </form>
          </Modal>
        </div>
      )}
    </Formik>
  );
};

export default EditLeaveAllocation;
