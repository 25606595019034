import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getTransDoc = async () => {
    return axios.get(`${AUTH_BASE_URL}/buss-transactions/document-type/list`);
  };

export const saveTransDoc = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/buss-transactions/document-type/create`, data);
};

export const updateTransDoc = async (values,id) => {
    return axios.post(`${AUTH_BASE_URL}/buss-transactions/document-type/${id}/edit`, values);
  };


export const getallApproveruser = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
}

export const deleteDepartment = async (pk) => {
return axios.delete(`${AUTH_BASE_URL}/buss-transactions/document-type/${pk}/delete`)}