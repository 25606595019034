import React, { useState, useEffect } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
// import * as CountryService from "../../service/country";
import * as TravelListingAPI from "../../service/travelService";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as moment from "moment";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { current } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TravelAdvanceAccess from "./TravelAdvanceAccess";
import { useNavigate } from "react-router-dom";

function TravelAdvanceAccessListing() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [countryDataFilter, setCountryDataFilter] = useState({ status: "" });

  const getTravelAdvList = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    TravelListingAPI.getTravelAdvList(
      handleKey(search, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        console.log("response", response);
        setCountryList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (search, status, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  // const deleteCountry = (id, idx) => {
  //   let dataId = { id: idx };
  //   TravelListingAPI.deleteCountry(id, dataId)
  //     .then((res) => {
  //       if (res.data.status == true) {
  //         getCountryList(
  //           searchStr,
  //           countryDataFilter.status,
  //           currentPage,
  //           pageSizeNo,
  //           sortData.sort_by,
  //           true
  //         );
  //         toast.success(res.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const submit = (id, countryName) => {
    confirmAlert({
      title: "Delete Country ",
      message: `Are you sure to delete ${countryName}?`,
      buttons: [
        {
          label: "Yes",
          // onClick: () => deleteCountry(id, id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getTravelAdvList(
      searchStr,
      "",
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const onClose = () => {
    setShowForm(false);
  };
  {
    console.log(country, "country--");
  }
  const editCountry = (eData) => {
    // setShowForm(true);
    navigate("/travel-advance-access", { state: { data: eData, edit: true } });
    // setEditMode(true);
    // setCountry(eData);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getTravelAdvList(
      searchStr,
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const addNewBranch = () => {
    setCountry(null);
    setEditMode(false);
    setShowForm(true);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getTravelAdvList(
        e.target.value.toLowerCase(),
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  useEffect(() => {
    getTravelAdvList(
      searchStr,
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelAdvList(
        data,
        countryDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelAdvList(
      searchStr,
      countryDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Travel Advance Access List</h4>

              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Country Master </Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <ToastContainer autoClose={1000} />
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Allow Access to,
                        <br />
                        Status
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Allow Access To, Status..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {finalUrlValue?.A ? (
                  <button
                    class="btn btn-primary-inner"
                    onClick={() => {
                      addNewBranch();
                    }}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured ">
            <thead>
              <tr>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Allow Access To
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Updated On
                </th>

                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {countryList?.length ? (
                countryList?.map((data, index) => (
                  <tr>
                    <td
                      class="text-theme fw-bold"
                      style={{
                        whiteSpace: "pre",
                      }}
                    >
                      {data?.user_name} ({data?.user_code})
                    </td>

                    <td>
                      {moment.utc(data?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>
                      {data?.is_active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          Inactive
                        </span>
                      )}
                    </td>

                    <td>
                      <button
                        type="button"
                        class="btn btn-blanktd text-primary"
                        onClick={() => {
                          editCountry(data);
                        }}
                      >
                        <i class="far fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {countryList?.length == 0 ? (
          ""
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TravelAdvanceAccessListing;
