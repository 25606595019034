import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import * as VendorService from "../../service/cashbookService";
import AddCashBookVendor from "./AddCashBookVendor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUrlsCashbook from "./AllUrlsCashbook";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function CashbookVendors() {
  const dispatch = useDispatch();
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModuleData, setEditModuleData] = useState(null);

  const onAddCallBack = (response) => {
    setAddNewShow(false);
    getVendorList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
  };
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, setAddNewShow] = useState(false);
  const [vendorData, setVendorData] = useState([]);

  const getVendorList = (search, page_no, page_size, sort_by, paginate) => {
    VendorService.getVendorList(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setVendorData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getExportDetails = (search, page_no, page_size, sort_by, paginate) => {
    VendorService.getExportValue(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookVendors.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const editVendor = (eData) => {
    setAddNewShow(true);
    setEditMode(true);
    setEditModuleData(eData);
  };
  const addNewVendorCashbook = (eData) => {
    setAddNewShow(true);
    setEditMode(false);
    setEditModuleData(null);
  };

  const onClose = () => {
    setEditModuleData(null);
    setAddNewShow(false);
  };

  const deleteVendor = (idx) => {
    VendorService.deleteVendor(idx)
      .then((res) => {
        if (res.data.status == true) {
          getVendorList(
            searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
        }
        toast.success("Vendor Deleted Successfully");
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error("Unable to delete as mapped data exists.");
        } else {
          error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getVendorList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getVendorList(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getVendorList(data, currentPage, pageSizeNo, sortData.sort_by, true);
    }
  };

  const submit = (id, moduleName) => {
    console.log(id, "kid");
    confirmAlert({
      title: "Delete Module",
      message: `Are you sure to delete Vendor ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteVendor(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getVendorList(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getVendorList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Manage Vendors</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Vendors</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Name,
                            <br />
                            Gst No, <br />
                            Gst Type, <br />
                            Vendor Address,
                            <br />
                            Status,
                            <br />
                            State Name,
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Name, Gst No, Gst Type, Vendor Address,Status, State Name,..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            disabled={vendorData.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => addNewVendorCashbook()}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th
                      onClick={(e) => {
                        handlerData("name");
                      }}
                      className={
                        sortData["name"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["name"]
                      }
                    >
                      Vendor Name
                    </th>
                    <th className={" main-pointer"}>State</th>
                    <th
                      onClick={(e) => {
                        handlerData("gst_type");
                      }}
                      className={
                        sortData["gst_type"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["gst_type"]
                      }
                    >
                      Category
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("gst_no");
                      }}
                      className={
                        sortData["gst_no"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["gst_no"]
                      }
                    >
                      GST NO
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("vendor_address");
                      }}
                      className={
                        sortData["vendor_address"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["vendor_address"]
                      }
                    >
                      Address
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("is_active");
                      }}
                      className={
                        sortData["is_active"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["is_active"]
                      }
                    >
                      Status
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorData?.length ? (
                    vendorData?.map((data, index) => (
                      <tr>
                        <td class="fw-bold text-theme">{data?.name}</td>
                        <td>{data?.state_name}</td>
                        <td>{data?.gst_type}</td>
                        <td>{data?.gst_no}</td>
                        <td>{data?.vendor_address}</td>
                        <td>
                          {data?.is_active ? (
                            <span className="badge bad-status badge-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge bad-status badge-danger">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-blanktd text-primary"
                            onClick={() => {
                              editVendor(data);
                            }}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i
                              class="far fa-trash-alt text-danger"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {vendorData?.length == 0 ? (
              <td colSpan={12}> No Record Found</td>
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {addnewshow && (
        <AddCashBookVendor
          edit={editMode}
          addnewshow={addnewshow}
          onClose={onClose}
          onAddCallBack={onAddCallBack}
          editModuleData={editModuleData}
        />
      )}
    </>
  );
}

export default CashbookVendors;
