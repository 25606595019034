import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";

import {  Tabs, Tab } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";


function Tadmin() {

    return(


<div className="content-wrapper">
<ToastContainer autoClose={1000} />


    <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Admin</h4>
             
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
      
          </div>
     
     



          <div className="row">
       
          <div class="col-lg-4">

            <h6 class="inner-page-title">User-Plant Mapping</h6>
         
              < div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
           
          <thead>
              <tr>
                <th>Transporter Code</th>
                <th>Transporter Name</th>
               
                <th
                //   onClick={(e) => {
                //     handlerData("is_active");
                //   }}
                //   className={
                //     sortData["is_active"] === undefined
                //       ? "unsortcls main-pointer"
                //       : sortData["is_active"]
                //   }
                >
                  Status
                </th>
                {/* {finalUrlValue?.E || finalUrlValue?.R ? <th>Actions</th> : ""} */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {true ? (
               
                  <tr>
                <td>{"34985"}</td>
                <td>{"Jai Travellers"}</td>
                    <td>
                      {true ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    {/* <td>
                          <span class="badge bad-status badge-danger">
                            InActive
                          </span>
                        </td> */}
                    {true ? (
                      <td>
                        {true ? (
                          <button
                            class="btn btn-blanktd text-primary"
                            onClick={() => {
                            //   editBranch(data);
                            }}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        {true ? (
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i
                              class="far fa-trash-alt text-danger"
                              onClick={() => {
                                // submit(data?.id, data?.name);
                              }}
                            ></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
            </table>
            </div>
          </div>
 
          <div class="col-lg-4">
            <h6 class="inner-page-title">Vendor To Sub-vendor Mapping</h6>
         
              < div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">


            </table>
            </div>
          </div>
          </div>
       
      
   
   


</div>

)
      }

      export default Tadmin