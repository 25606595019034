import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillGroupService from "../../service/skillGroup";
import * as SkillDepartmentService from "../../service/skillDepartment";
import Select from "react-select";

const validationSchema = Yup.object({
  group_id: Yup.string()
    .required("*Group Code is required")
    .matches(
      /[^\s*].*[^\s*]/g,
      "* This field cannot contain only blank spaces"
    ),
  group_name: Yup.string()
    .required("*Group Name is required")
    .matches(
      /[^\s*].*[^\s*]/g,
      "* This field cannot contain only blank spaces"
    )
});

const AddSkillGroup = (props) => {
  const nameForm = "Skill Group Master";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);
  const [departmentList, setDepartmentList] = useState([])
  const [departmentOption, setDepartmentOption] = useState({})
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [departmentError, setDepartmentError] = useState(false)

  const [skillData, setSkillData] = useState({
    group_id: "",
    group_name: "",
    group_dept: "",
    is_active: true,
  });

  let condition = "false";

  const saveSkillGroup = (values) => {
    setIsLoading(true);
    SkillGroupService.saveSkillGroup(values)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getDepartment = (search, pageNo, page_size, sort_by, paginate) => {
    SkillDepartmentService.getDepartment(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        let deptList = response?.data?.dataList?.result?.map(el => {
          return ({
            value: `${el.department_code}-${el.department_name}`,
            label: `${el.department_code}-${el.department_name}`
          })
        })
        setDepartmentList(deptList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkillGroup = (values) => {
    values["group_dept"] = departmentOption.value
    setIsLoading(true);
    SkillGroupService.updateSkillGroup(values, values.id)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = (values) => {
    let data = {};
    data["group_id"] = values.group_id;
    data["group_name"] = values.group_name;
    data["group_dept"] = departmentOption.value;
    values["group_dept"] = departmentOption.value;
    if (condition == "false") {
      condition = "true";
      SkillGroupService.isDataExists(data)
        .then((res) => {
          setIsSkillExist(res?.data?.dataList?.is_exist);
          if (res?.data?.dataList?.is_exist === false && values?.id) {
            updateSkillGroup(values);
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            values?.id === undefined
          ) {
            saveSkillGroup(values);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = (values) => {
    let error = false;
    if (Object.keys(departmentOption).length == 0) {
      setDepartmentError(true)
      return
    }
    if (edit) {
      if (editSkills?.group_id !== values.group_id) {
        error = true;
      }
      if (editSkills?.group_name !== values.group_name) {
        error = true;
      }
      if (editSkills?.group_dept !== values.group_dept) {
        error = true;
      }
    }
    if (error === false && values?.id) {
      updateSkillGroup(values);
    }
    if (error === true && values?.id) {
      isDataExists(values);
      return;
    }
    if (error === false && values?.id === undefined) {
      isDataExists(values);
    }
  };

  useEffect(() => {
    if (editSkills) {
      setSkillData(editSkills);
      setDepartmentOption({ label: editSkills.group_dept, value: editSkills.group_dept })
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills]);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  useEffect(() => {
    getDepartment("", 1, 100, sortData.sort_by, false);
  }, [])

  return (
    <>
      <Formik
        initialValues={skillData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          AllCheck(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Skill Group
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Group Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="group_id"
                            className="form-control"
                            placeholder="Enter Group Code"
                            maxLength={30}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.group_id}
                          />
                          {touched.group_id && errors.group_id ? (
                            <div className="small text-danger">
                              {errors.group_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Group Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="group_name"
                            className="form-control"
                            placeholder="Enter Group Name"
                            maxLength={100}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.group_name}
                          />
                          {touched.group_name && errors.group_name ? (
                            <div className="small text-danger">
                              {errors.group_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Group Department
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="department"
                            id="department"
                            options={departmentList}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              setDepartmentOption(e);
                              setDepartmentError(false);
                            }}
                            value={departmentOption}
                          />
                          {departmentError && (
                            <div className="small text-danger">
                              *Department is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">
                            Status <span className="text-danger"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              {isSkillExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;This Entry Already Exists.
                </Alert>
              )}

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSkillGroup;
