import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from "react-select";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as PrServices from '../../../service/prService';
import { Link, useNavigate, useLocation } from "react-router-dom";

const ViewSubItemForm = (props) => {
  const location = useLocation();
  const { handleClose,viewSubitemflag, subItemValues} = props;

  return (
    <div >
      <Modal
        show={viewSubitemflag}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        {/* <form onSubmit={handleSubmit}> */}
          <Modal.Header closeButton>
            <Modal.Title>View Sub Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              {/* Header Table */}
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      {/* <th>Action</th> */}
                      <th>Line No.</th>
                      <th>Service No.</th>
                      <th>Short Text</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Gross Price</th>
                      <th>Currency</th>
                      <th>Net Value</th>
                      <th>GL Account</th>
                      <th>Order No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      subItemValues.map((item, i) => (
                        <tr>
                          {/* <td>
                            <button className="btn btn-blanktd text-primary f-12" onClick={()=>{handleEditSubItem(i)}}>
                              <i className="far fa-edit"></i>
                            </button>
                            {
                              values.length > 1 &&
                              <span className="btn btn-blanktd text-danger ml-2 f-12" onClick={() => handleDeleteSubItem(i)}>
                                <i className="far fa-trash-alt text-danger"></i>
                              </span>
                            }
                          </td> */}
                          <td>
                            <span className="badge badge-secondary">{(i+1)*10}</span>
                          </td>
                          <td>{item.service_no}</td>
                          <td>{item.short_text}</td>
                          <td>{item.quantity}</td>
                          <td>{item.unit_name}</td>
                          <td>{item.gross_price}</td>
                          <td>{item?.currency_desc} ({item?.currency_code})</td>
                          <td>{item.quantity * item.gross_price}</td>
                          <td>{item.gl_acc_short_desc}</td>

                          <td>{item.order_desc} {item.order_name}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              {/* Header Table */}

             
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={()=>{handleClose()}} className="btn btn-primary-inner bpi-main">Back</button>
          </Modal.Footer>
        {/* </form> */}
      </Modal>
    </div >
  )
}

export default ViewSubItemForm