import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Dropdown, Pagination, Tabs, Tab } from "react-bootstrap";
import * as SkillService from "../../service/skill";
import * as SkillReportService from "../../service/skillReport";
import ReactPaginate from "react-paginate";
import ViewTableSkill from "./ViewTableSkill";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function SkillReport() {
  const dispatch = useDispatch();

  const [permissionAccess, setPermissionAccess] = useState({});
  const [currentTab, setCurrentTab] = useState("purchase");
  const [ViewDataSkill, setViewDataSkill] = useState([]);
  const [viewEmployeeData, setViewEmployeeData] = useState([]);
  const [skillwiseData, setSkillwiseData] = useState([]);
  const [locationwiseData, setLocationwiseData] = useState([]);
  const [groupwiseData, setGroupwiseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [skillViewSet, setSkillViewSet] = useState([]);
  const [locationViewSet, setLocationViewSet] = useState([]);
  const [groupViewSet, setGroupViewSet] = useState([]);
  const [empViewSet, setEmpViewSet] = useState([]);

  const [empwiseData, setEmpwiseData] = useState([]);
  const [locwiseData, setLocwiseData] = useState([]);
  const [grpwiseData, setGrpwiseData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageCount1, setpageCount1] = useState(1);
  const [pageCount2, setpageCount2] = useState(1);
  const [pageCount3, setpageCount3] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageSizeNo1, setPageSizeNo1] = useState(10);
  const [pageSizeNo2, setPageSizeNo2] = useState(10);
  const [pageSizeNo3, setPageSizeNo3] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [importshow, importsetShow] = useState(false);
  const [showSkillTab, setShowSkillTab] = useState(false)
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getSkillWise = (search, page_no, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));
    SkillReportService.getSkillWise(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSkillwiseData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getEmployeeWise = (search, page_no, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillReportService.getEmployeeWise(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setEmpwiseData(response?.data?.dataList?.result);
        setpageCount1(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLocationWise = (search, page_no, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillReportService.getLocationWise(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setLocwiseData(response?.data?.dataList?.result);
        setpageCount2(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getGroupWise = (search, page_no, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillReportService.getGroupWise(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setGrpwiseData(response?.data?.dataList?.result);
        setpageCount3(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    currentTab
  ) => {
    if (currentTab === "purchase") {
      SkillReportService.getExportValueSkill(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "skillwise.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else if (currentTab === "approved") {
      SkillReportService.getExportValueEmp(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "employeewise.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else if (currentTab === "locationWise") {
      SkillReportService.getExportValueLocation(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "locationwise.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      SkillReportService.getExportValueGroup(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "groupwise.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const handleSearch = (e, tabName) => {
    if (tabName === "purchase") {
      getSkillWise(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    } else if (tabName == "approved") {
      getEmployeeWise(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo1,
        "-id",
        true
      );
    } else if (currentTab === "locationWise") {
      getLocationWise(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo2,
        "-id",
        true
      );
    } else {
      getGroupWise(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo3,
        "-id",
        true
      );
    }
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = (tabName) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (tabName === "purchase") {
        getSkillWise(data, 1, pageSizeNo, "-id", true);
      } else if (tabName == "approved") {
        getEmployeeWise(data, 1, pageSizeNo1, "-id", true);
      } else if (currentTab === "locationWise") {
        getLocationWise(data, 1, pageSizeNo2, "-id", true);
      } else {
        getGroupWise(data, 1, pageSizeNo3, "-id", true);
      }
    }
  };
  const handleTab = (tabName) => {
    console.log(tabName)
    let closeBar = document.getElementById("searchbar");
    const clearData = (closeBar.value = "");
    setSearchStr(clearData);
    getSkillWise("", 1, pageSizeNo, "-id", true);
    getEmployeeWise("", 1, pageSizeNo1, "-id", true);
    getLocationWise("", 1, pageSizeNo2, "-id", true);
    getGroupWise("", 1, pageSizeNo3, "-id", true);
  };

  const viewSkillData = (data, value, locData, grpData) => {
    importuser();
    setViewDataSkill(data);
    setViewEmployeeData(value);
    setLocationwiseData(locData)
    setGroupwiseData(grpData)
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    if (currentTab === "purchase") {
      getSkillWise(searchStr, activePage, pageSizeNo, "-id", true);
    } else if (currentTab == "approved") {
      getEmployeeWise(searchStr, activePage, pageSizeNo1, "-id", true);
    } else if (currentTab == "locationWise") {
      getLocationWise(searchStr, activePage, pageSizeNo2, "-id", true);
    } else {
      getGroupWise(searchStr, activePage, pageSizeNo3, "-id", true);
    }
    setCurrentPage(activePage);
    setCurrentPage1(activePage);
    setCurrentPage2(activePage);
    setCurrentPage3(activePage);
  };

  useEffect(() => {
    if (currentTab === "purchase") {
      getSkillWise("", 1, pageSizeNo, "-id", true);
    } else if (currentTab == "approved") {
      getEmployeeWise("", 1, pageSizeNo1, "-id", true);
    } else if (currentTab == "locationWise") {
      getLocationWise("", 1, pageSizeNo2, "-id", true);
    } else {
      getGroupWise("", 1, pageSizeNo3, "-id", true);
    }
    getSkillPermission();
  }, [pageSizeNo, pageSizeNo1, pageSizeNo2, pageSizeNo3]);

  return (
    <>
      <div>
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li class="active">
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill Report</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {currentTab == "purchase"
                                ? "Skill Code"
                                : " Employee Code, Employee Name"}
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e, currentTab)}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack(currentTab);
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  {
                    permissionAccess?.report_update == true && (
                      <div class="col-lg-8">
                        <div class="inline-spacing btn-betweenspaing">
                          <Dropdown show>
                            <Dropdown.Toggle
                              variant="primary-inner dropdownbtn"
                              id="dropdown-basic"
                            >
                              <img src="images/export.png" alt="" class="mr-2" />{" "}
                              Export
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#">
                                <button
                                  type="button"
                                  disabled={
                                    currentTab === "purchase"
                                      ? skillwiseData.length !== 0
                                        ? false
                                        : true
                                      : empwiseData.length !== 0
                                        ? false
                                        : true
                                  }
                                  style={{ border: 0, background: "none" }}
                                  onClick={() =>
                                    getExportDetails(
                                      searchStr,
                                      1,
                                      pageSizeNo,
                                      "-id",
                                      true,
                                      currentTab
                                    )
                                  }
                                >
                                  <i className="far fa-file-excel"></i>Excel
                                </button>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs">
                <Tabs
                  defaultActiveKey="purchase"
                  id="approvaltabs"
                  activeKey={currentTab}
                  onSelect={(e) => {
                    setCurrentTab(e);
                    handleTab(currentTab);
                  }}
                >
                  <Tab eventKey="purchase" title={<>Skill Wise Data</>}>
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Skill Code</th>
                            <th>Description</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {skillwiseData?.length ? (
                            skillwiseData?.map((data, index) => (
                              <>
                                <tr key={`desg${index}`}>
                                  <td>
                                    {" "}
                                    {pageSizeNo * (currentPage - 1) +
                                      (index + 1)}
                                  </td>
                                  <td class="text-theme fw-bold">
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() => {
                                        viewSkillData(data?.employee_data, "");
                                        setSkillViewSet(data);
                                      }}
                                    >
                                      {data["Skill Code"]}
                                    </button>
                                  </td>
                                  <td>{data?.Description}</td>
                                  <td>
                                    <span class="badge badge-secondary">
                                      {data?.Count}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {skillwiseData?.length == 0 ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="sortinglist">
                            Show
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>10</option>
                              <option value={20} label={20}>20</option>
                              <option value={30} label={30}>30</option>
                              <option value={100} label={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                  <Tab
                    eventKey="approved"
                    title={<React.Fragment>Employee Wise Data</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Emp. Code</th>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Department</th>
                            <th>Count</th>
                            {/* <th>Job Role</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {empwiseData?.length ? (
                            empwiseData?.map((data, index) => (
                              <>
                                <tr key={`desg${index}`}>
                                  <td>
                                    {pageSizeNo1 * (currentPage1 - 1) +
                                      (index + 1)}
                                  </td>
                                  <td class="text-theme fw-bold">
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() => {
                                        viewSkillData("", data?.skill_data);
                                        setEmpViewSet(data);
                                      }}
                                    >
                                      {data["Emp. Code"]}
                                    </button>
                                  </td>
                                  <td>{data?.Name}</td>
                                  <td>{data?.Location}</td>
                                  <td>{data?.Department}</td>
                                  <td>
                                    <span class="badge badge-secondary">
                                      {data?.Count}
                                    </span>
                                  </td>
                                  {/* <td>React Developer</td> */}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {empwiseData?.length == 0 ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="sortinglist">
                            Show
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setPageSizeNo1(e.target.value);
                                setCurrentPage1(1);
                              }}
                            >
                              <option value={10} label={10}>10</option>
                              <option value={20} label={20}>20</option>
                              <option value={30} label={30}>30</option>
                              <option value={100} label={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount1}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                  <Tab
                    eventKey="locationWise"
                    title={<React.Fragment>Location Wise Data</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Location. Code</th>
                            <th>Name</th>
                            <th>Count</th>
                            {/* <th>Job Role</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {locwiseData?.length ? (
                            locwiseData?.map((data, index) => (
                              <>
                                <tr key={`desg${index}`}>
                                  <td>
                                    {pageSizeNo2 * (currentPage2 - 1) +
                                      (index + 1)}
                                  </td>
                                  <td class="text-theme fw-bold">
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() => {
                                        viewSkillData("", "", data.skill_data);
                                        setLocationViewSet(data);
                                      }}
                                    >
                                      {`${data["Location Code"]}`}
                                    </button>
                                  </td>
                                  <td>{data["Location Name"]}</td>
                                  <td>
                                    <span class="badge badge-secondary">
                                      {data?.Count}
                                    </span>
                                  </td>
                                  {/* <td>React Developer</td> */}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {locwiseData?.length == 0 ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="sortinglist">
                            Show
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setPageSizeNo2(e.target.value);
                                setCurrentPage2(1);
                              }}
                            >
                              <option value={10} label={10}>10</option>
                              <option value={20} label={20}>20</option>
                              <option value={30} label={30}>30</option>
                              <option value={100} label={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount2}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>

                  <Tab
                    eventKey="groupWise"
                    title={<React.Fragment>Group Wise Data</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Group. Code</th>
                            <th>Name</th>
                            <th>Count</th>
                            {/* <th>Job Role</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {grpwiseData?.length ? (
                            grpwiseData?.map((data, index) => (
                              <>
                                <tr key={`desg${index}`}>
                                  <td>
                                    {pageSizeNo2 * (currentPage2 - 1) +
                                      (index + 1)}
                                  </td>
                                  <td class="text-theme fw-bold">
                                    <button
                                      class="btn btn-blanktd text-success"
                                      onClick={() => {
                                        viewSkillData("", "", "", data.skill_data);
                                        setGroupViewSet(data);
                                      }}
                                    >
                                      {`${data["Group Code"]}`}
                                    </button>
                                  </td>
                                  <td>{data["Group Name"]}</td>
                                  <td>
                                    <span class="badge badge-secondary">
                                      {data?.Count}
                                    </span>
                                  </td>
                                  {/* <td>React Developer</td> */}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {locwiseData?.length == 0 ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="sortinglist">
                            Show
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setPageSizeNo3(e.target.value);
                                setCurrentPage3(1);
                              }}
                            >
                              <option value={10} label={10}>10</option>
                              <option value={20} label={20}>20</option>
                              <option value={30} label={30}>30</option>
                              <option value={100} label={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount3}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {importshow && (
        <ViewTableSkill
          importshow={importshow}
          importclose={importclose}
          ViewDataSkill={ViewDataSkill}
          viewEmployeeData={viewEmployeeData}
          locationwiseData={locationwiseData}
          groupwiseData={groupwiseData}
          skillViewSet={skillViewSet}
          empViewSet={empViewSet}
          locationViewSet={locationViewSet}
          groupViewSet={groupViewSet}
        />
      )}
    </>
  );
}

export default SkillReport;
