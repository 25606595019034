import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";




export const getUserList = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/users?${queryParm}`);
  };

export const getUsersListDataByOrg = async (id) => {
    return axios.get(
      `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${0}&organisation_id=${id}&show_ot_users=${true}`
    );
  };

export const submitOtdetails = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/ot_hours`, data);
};
export const getOttdetails = async (paginate) => {
    // console.log("Paginate",paginate)
    return axios.get(`${AUTH_BASE_URL}/ot_hours?${paginate}`)
  }


export const ottuserlistexportfile = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/ot_hours/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
}

export const uploadfile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};