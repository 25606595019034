import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getApprover = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/approver-assign?${queryParm}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/approver-assign/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteApprover = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/approver-assign/${id}`);
};

export const saveApprover = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/approver-assign`, data);
};

export const updateApprover = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/approver-assign/${id}`, data);
};

export const getAllGroups = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/release-group?paginate=${paginate}`);
};
export const getAllStrat = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/release-strat?paginate=${paginate}`);
};
export const getAllCode = async (group_id, strategy_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/release_code_list?release_group_id=${group_id}&release_strategy_id=${strategy_id}`
  );
};
export const getAllCodes = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/release-code?paginate=${paginate}`);
};
export const getPurOrg = async (sort_by, paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-org?sort_by=${sort_by}&paginate=${paginate}`
  );
};
export const getPurGroup = async (sort_by, paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-group?sort_by=${sort_by}&paginate=${paginate}`
  );
};

export const getApproverId = async () => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=false`);
};

export const isDataExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/approver-assign/exists`, isData);
};

export const importExel = async (data, category) => {
  return axios({
    url: `${AUTH_BASE_URL}/approver-assign/po_import?category=${category}`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};
export const importExelCA = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/approver-assign/ca_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const getCADirectoryDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/approver-assign/ca_import_details`);
};

export const getPODirectoryDetails = async (category) => {
  return axios.get(
    `${AUTH_BASE_URL}/approver-assign/po_import_details?category=${category}`
  );
};
