import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import * as CmsNewsService from "../../service/Cms-newsService";
import { toast } from "react-toastify";
import { uploadFile } from "../../service/policy";
import {
  getBranchFormList,
  getBusinessList,
  getOrganisation,
} from "../../service/businessService";
import * as moment from "moment";
import ReactQuill from "react-quill";
import { getPersonalSubArea } from "../../service/leaveService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
const CmsNewsForms = (props) => {
  const [file, setfiles] = useState([]);
  const [Displayon2, setDisplayon2] = useState([
    { value: "Mobile App", label: "Mobile App" },
    { value: "Web on Mobile", label: "Web on Mobile" },
    { value: "Web", label: "Web" },
  ]);
  const [Displayoncopy, setDisplayoncopy] = useState([
    { value: "Mobile App", label: "Mobile App" },
    { value: "Web on Mobile", label: "Web on Mobile" },
    { value: "Web", label: "Web" },
    { value: "All", label: "All" },
  ]);
  const [Displayon, setDisplayon] = useState([
    { value: "Mobile App", label: "Mobile App" },
    { value: "Web on Mobile", label: "Web on Mobile" },
    { value: "Web", label: "Web" },
    { value: "All", label: "All" },
  ]);
  const { editForm, data, branchcopy1 } = props;
  const [Errororganisation, setErrororganisation] = useState(false);
  const [deleteattachment, setdeleteAttachment] = useState([]);
  const [ErrorDisplay, setErrorDisplay] = useState(false);
  const [ErrorDescription, SetErrorDescription] = useState(false);

  const [errorOccurence, setErrorOccurence] = useState(false);
  const [Errorattachment, setErrorattachment] = useState("");
  const [attachmentError, setAttachmentError] = useState(true);
  const [attachmentTypeError, setattachmentTypeError] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [org, setOrg] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [display_on, setdisplay_on] = useState([]);
  const [display_onValue, setdisplay_onValue] = useState([]);
  const [orgOldData, setOldOrgData] = useState([]);
  const [dublicateOrgData, setDubOrgData] = useState([]);
  const [branch, setBranchData] = useState([]);
  const [branchCity, setBranchCityData] = useState([]);
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [branchData, setBranch] = useState([]);
  const [branchCityData, setBranchCity] = useState([]);
  const [oldbranchData, setoldBranch] = useState([]);
  const [oldbranchCityData, setoldBranchCity] = useState([]);
  const [disableOccurrence, setOccurrence] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setSatus] = useState([]);
  const [ExternalLink, setExternalLink] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [external, setExternal] = useState(false);
  const dispatch = useDispatch()
  const validationSchema = Yup.object({
    Type: Yup.string().required("*Type is required").nullable(),
    title: Yup.string()
      .required("*Title is required")
      .matches(
        /[^\s*].*[^\s*]/g,
        "* This field cannot contain only blank spaces"
      )
      .nullable(),
    startDateTime: Yup.string()
      .required("*Start Date & Time is required")
      .nullable(),
    endDateTime: Yup.string()
      .required("*End Date & Time is required")
      .nullable(),
  });
  const [noticeData, setnoticeData] = useState({
    Type: "",
    title: "",
    startDateTime: "",
    endDateTime: "",
  });
  useEffect(() => {
    if (editForm) {
      let data2 = data;
      let branch = [];
      data2.branch != null &&
        data2.branch?.map((x) => {
          let data = { value: x.id, label: x.name };
          branch.push(data);
        });
      let branchCity = [];
      data2.branch_city != null &&
        data2.branch_city?.map((x) => {
          let data = { value: x.id, label: x.name };
          branchCity.push(data);
        });
      let organisation = [];
      data2.organization != null &&
        data2.organization?.map((x) => {
          let data = { value: x.id, label: x.name + " (" + x.code + ")" };
          organisation.push(data);
        });

      let display_on = [];
      let display_on2 = [];
      data2.display_on != null &&
        data2.display_on?.map((x) => {
          let data = { value: x, label: x };
          display_on.push(data);
          display_on2.push(x);
        });
      pushOrg(organisation);
      setdisplay_on(display_on);
      setdisplay_onValue(display_on2);
      setOldOrgData(organisation);
      setOrgData(organisation);
      setBranch(branch);
      setoldBranch(branch);
      setBranchCity(branchCity);
      setoldBranchCity(branchCity);
      setnoticeData(data2);
      Object.keys(data2).forEach((item) => {
        noticeData[item] = data2[item];
      });
      setSatus(data2.status);
      if (data2.external != null) {
        setExternalLink(data2.external);
        setExternal(true);
      } else {
        let filename = [];
        data2?.attachment != null &&
          data2?.attachment?.map((x) => {
            let data1 = {
              name:
                x.attachment_path != null && x.attachment_path?.split("/")[4],
            };
            filename.push(data1);
          });
        setAttachment(data2?.attachment);
      }
      if (data2.Type == "Popup") {
        setOccurrence(true);
      }
    } else {
      setSatus(true);
      setExternal(null);
      setnoticeData({
        id: 0,
        Type: null,
        title: null,
        display_on: null,
        startDateTime: null,
        endDateTime: null,
        description: null,
        attachment: null,
        branch: [],
        branch_city: [],
        organisation: [],
        occurrence: null,
        status: true,
        external: null,
      });
    }
  }, [data]);

  useEffect(() => {
    // if (editForm == false) {
    //   getBranch(false);
    // }
    getOrganisationList();
  }, []);

  useEffect(() => {
    getBranch();
  }, [branchData]);

  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      let organisation = [];
      let organisation2 = [];

      response?.data?.dataList?.result.map((x) => {
        let data = { value: x.id, label: x.name + " (" + x.code + ")" };
        organisation.push(data);
        organisation2.push(data);
      });
      setDubOrgData(organisation2);
      let all = { value: "All", label: "All" };
      organisation.push(all);
      setOrg(organisation);
    });
  };
  const getBranch = () => {
    getPersonalSubArea(branchData?.map((each) => each?.value).toString()).then(
      (response) => {
        console.log(response);
        let branch = [];
        let branchCity = [];
        response?.data?.dataList?.result.map((x) => {
          let data = {
            value: x.id,
            label: x.name + " (" + x.description + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          branchCity.push(data);
        });
        const ids = branchCity.map((o) => o.value);
        const filteredBranchCity = branchCity.filter(
          ({ value }, index) => !ids.includes(value, index + 1)
        );
        setBranchCityData(filteredBranchCity);
      }
    );
  };
  const submitForm = (values) => {
    values.branch = branchData;
    values.attachment_path = uploadDataIm;
    values.branch_city = branchCityData;
    values.organisation = orgData;
    values.display_on = display_onValue;
    values.description = noticeData.description;
    if (values.external != null) {
      values.external = createEmbedLink(values.external);
    }
    values.status = status;
    if (
      orgData.length != 0 &&
      (uploadDataIm != null ||
        file.length != 0 ||
        attachment.length != 0 ||
        external != "") &&
      attachmentTypeError === ""
    ) {
      // && (attachment.length != 0||file.length!=0))
      if (!editForm) {
        setIsLoading(true);
        CmsNewsService.Addnews(values)
          .then((response) => {
            setIsLoading(false);
            setfiles([]);
            setdeleteAttachment([]);
            props.onhide();
            props.getdata();
            toast.success("Record Saved Successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      } else {
        values["oldBranch"] = oldbranchData;
        values["oldBranchCity"] = oldbranchCityData;
        values["orgOldData"] = orgOldData;
        values["deleteAttachment"] = deleteattachment;

        setIsLoading(true);
        CmsNewsService.Updatenews(values)
          .then((response) => {
            setIsLoading(false);
            setfiles([]);
            setdeleteAttachment([]);
            props.onhide();
            props.getdata();
            toast.success("Record Updated Successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      }
    }
  };
  const pushBranch = (e) => {
    setBranch(e);
  };
  const pushDisplayOn = (e) => {
    let check = 0;
    e.map((x) => {
      if (x.label == "All") {
        check++;
      }
    });
    if (check == 0) {
      setDisplayon(Displayoncopy);
      let data = e.map((x) => {
        return x.value;
      });
      setdisplay_onValue(data);
      setdisplay_on(e);
    } else {
      let data = Displayon2.map((x) => {
        return x.value;
      });
      setdisplay_onValue(data);
      setdisplay_on(Displayon2);
      setDisplayon(Displayon2);
    }
    setErrorDisplay(false);
  };
  const pushBranchCity = (e) => {
    setBranchCity(e);
  };
  const pushOrg = (e) => {
    console.log(editForm);
    let branches = editForm == true ? branchcopy1 : branchcopy;
    let newbranch = [];
    let check = 0;
    console.log(branches);
    e.map((x) => {
      branches.map((b) => {
        console.log(x?.label === b?.organisation);
        if (x?.label === b?.organisation) {
          newbranch.push(b);
        }
      });
      if (x.label == "All") {
        check++;
      }
    });
    setBranchData(newbranch);
    newbranch = [];
    if (check == 0) {
      setOrgData(e);
      getOrganisationList();
    } else {
      let all = [{ value: "All", label: "All" }];
      setOrgData(dublicateOrgData);
      setOrg([]);
    }
    setErrororganisation(false);
    // setOrgData(e);
    // if (e?.value === "All") {
    //   setOrg([]);
    // }
  };

  const getBusinessUnits = async () => {
    if (orgData?.length > 0) {
      let orgIds =
        orgData[0]?.label === "All"
          ? []
          : orgData?.map((obj) => obj.value).toString();
      let queryParam = "paginate=" + false + "&organisation_id=" + orgIds;
      await getBusinessList(queryParam).then((response) => {
        if (response?.status === 200) {
          let branchList = response?.data?.dataList?.result.map((obj) => {
            return {
              label: `${obj?.name} (${obj?.code})`,
              value: obj?.id,
            };
          });
          setBranchData(branchList);
        }
      });
    } else {
      setBranchData([]);
    }
  };

  useEffect(() => {
    getBusinessUnits();
  }, [orgData]);

  const onProfileChange = async (e) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    dispatch(setDisplayLoader('Display'))
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      if (
        data.type.split("/")[1] === "jpg" ||
        data.type.split("/")[1] === "jpeg" ||
        data.type.split("/")[1] === "png" ||
        data.type.split("/")[1] === "PNG" ||
        data.type.split("/")[1] === "gif" ||
        data.type.split("/")[1] === "mp4" ||
        data.type.split("/")[1] === "pdf"
      ) {
        setErrorattachment(false);
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await uploadFile(fileData).then((response) => {
          let name = { name: data.name };
          filename.push(name);
          files.push(response.data);
        });
        setattachmentTypeError("");
      } else {
        setattachmentTypeError("kindly select correct file");
        setUploadDataIm(null);
      }
    }
    dispatch(setDisplayLoader('Hide'))
    // setErrorattachment(false);
    // setAttachment([]);
    // setUploadDataIm(files);
    setAttachmentError(true);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const disbableOcc = (e) => {
    if (e.target.value == "Popup") {
      setOccurrence(true);
    } else {
      setOccurrence(false);
    }
  };
  const createEmbedLink = (data) => {
    let link = data.replace("watch?v=", "embed/");
    return link;
  };

  const submitHandler = () => {
    if (orgData.length == 0) {
      setErrororganisation(true);
    }
    if (external == "") {
      setErrorattachment(true);
    }
    if (uploadData == null) {
      setErrorattachment(true);
    }
    if (display_on.length == 0) {
      setErrorDisplay(true);
    }
    console.log(noticeData.description);
    if (
      noticeData.description === null ||
      noticeData.description === "<p><br></p>"
    ) {
      SetErrorDescription(true);
    }
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      console.log(x?.original_name !== id, x?.original_name, id);
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (editForm == false) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          console.log(file[del].name);

          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("newsattachment").value = "";

      setfiles(obj);
    } else {
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          console.log(del.attachment_path?.split("/")[4], del);
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("newsattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            console.log(file[del].name);
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("newsattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  return (
    <>
      <Formik
        initialValues={noticeData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log("hello");
          submitForm(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={props.open}
            onHide={props.onhide}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {editForm ? "Edit" : "Add"} News/Notice/Popup
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div class="row justify-content-center">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Type<span class="text-danger">*</span>
                            </label>
                            <select
                              onChange={(e) => {
                                handleChange(e);
                                disbableOcc(e);
                              }}
                              value={values.Type}
                              name="Type"
                              onBlur={handleBlur}
                              class="form-control newbgselect"
                            >
                              <option value={null}>Select Type</option>
                              <option value="News">News</option>
                              <option value="Notice">Notice</option>
                              <option value="Popup">Popup</option>
                            </select>
                            {touched.Type && errors.Type ? (
                              <div className="small text-danger">
                                {errors.Type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Title<span class="text-danger">*</span>
                            </label>
                            <input
                              maxLength={145}
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              type="text"
                              class="form-control"
                              placeholder="Enter Title here..."
                            />
                            {touched.title && errors.title && (
                              <div className="small text-danger">
                                {errors.title}
                              </div>
                            )}
                          </div>
                        </div>
                        {disableOccurrence && (
                          <div class="col-md-6">
                            <div class="form-group innergroup">
                              <label>
                                Occurrence<span class="text-danger">*</span>
                              </label>
                              <select
                                name="occurrence"
                                id="occurrenceField"
                                onChange={(e) => {
                                  handleChange(e);
                                  setErrorOccurence(false);
                                }}
                                value={values.occurrence}
                                onBlur={handleBlur}
                                class="form-control newbgselect"
                              >
                                <option value="">Select Occurrence</option>
                                <option value="Once">Once</option>
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Yearly">Yearly</option>
                                <option value="Monthly">Monthly</option>
                              </select>
                              {errorOccurence && (
                                <div className="small text-danger">
                                  *Occurrence is required
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Display On<span class="text-danger">*</span>
                            </label>
                            <Select
                              name="display_on"
                              isMulti
                              options={Displayon}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                pushDisplayOn(e);
                              }}
                              value={display_on}
                            />
                            {ErrorDisplay && (
                              <div className="small text-danger">
                                *Display On is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Organization<span class="text-danger">*</span>
                            </label>
                            <Select
                              name="organisation"
                              isMulti
                              options={org}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                pushOrg(e);
                              }}
                              value={orgData}
                            />
                            {Errororganisation && (
                              <div className="small text-danger">
                                *Organizations is required
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Business unit<span class="text-danger"></span>
                            </label>
                            <Select
                              name="branch"
                              isMulti
                              options={branch}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                pushBranch(e);
                              }}
                              value={branchData}
                            />
                            <div className="small text-danger">
                              {errors.branch}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Location <span class="text-danger"></span>
                            </label>
                            <Select
                              name="branch_city"
                              onChange={(e) => {
                                pushBranchCity(e);
                              }}
                              isMulti
                              options={branchCity}
                              value={branchCityData}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                            />
                            <div className="small text-danger">
                              {errors.branch_city}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Start Date & Time
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              name="startDateTime"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.startDateTime}
                              type="datetime-local"
                              min={
                                !editForm
                                  ? moment
                                    .utc(new Date())
                                    .format("YYYY-MM-DDTHH:mm")
                                  : ""
                              }
                              class="form-control"
                              // disabled={editForm}
                              placeholder="Placeholder"
                            />
                            {touched.startDateTime && errors.startDateTime && (
                              <div className="small text-danger">
                                {errors.startDateTime}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              End Date & Time<span class="text-danger">*</span>
                            </label>
                            <input
                              name="endDateTime"
                              value={values.endDateTime}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="datetime-local"
                              class="form-control"
                              min={moment
                                .utc(values.startDateTime)
                                .format("YYYY-MM-DDTHH:mm")}
                              placeholder="Placeholder"
                            />
                            {touched.endDateTime && errors.endDateTime && (
                              <div className="small text-danger">
                                {errors.endDateTime}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>Description</label>
                            {/* <span class="text-danger">*</span> */}
                            {/* <textarea
                              value={values.description}
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              class="form-control"
                              rows={5}
                              placeholder="Enter Descrition"
                            ></textarea> */}
                            <ReactQuill
                              value={noticeData.description}
                              name="description"
                              onChange={(e) => {
                                setnoticeData((prev) => ({
                                  ...prev,
                                  description: e,
                                }));
                                SetErrorDescription(false);
                              }}
                              // onBlur={handleBlur}
                              placeholder="Enter Description"
                            />
                            {/* {ErrorDescription && (
                              <div className="small text-danger">
                                *Description is required
                              </div>
                            )} */}
                          </div>
                        </div>

                        {!external ? (
                          <div class="col-md-6">
                            <div class="form-group innergroup position-relative modaldefaultclose ">
                              <label>
                                Attachment
                                {/*<span class="text-danger">*</span> */}
                              </label>
                              <a
                                style={{
                                  fontSize: "100%",
                                  color: "#0195D4",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  setExternal(true);
                                  setUploadDataIm([]);
                                }}
                              >
                                External
                              </a>
                              <input
                                type="text"
                                class="form-control bg-white"
                                disabled
                                placeholder="(Image, PDF Format)"
                              />
                              <div class="upload-btn-wrapper up-loposition">
                                <button class="uploadBtn">Browse</button>
                                <input
                                  id="newsattachment"
                                  multiple
                                  onChange={(e) => onProfileChange(e)}
                                  type="file"
                                />
                              </div>
                              {/* {Errorattachment && (
                                <div className="small text-danger">
                                  *Attachment is required
                                </div>
                              )} */}
                              {/* {!attachmentError && (
                                <div className="small text-danger">
                                  Attachment is required
                                </div>
                              )} */}
                              <div className="small text-danger">
                                {attachmentTypeError}
                              </div>
                              {/* <p>
                                Selected files{" "}
                                {attachment.map((a) => (
                                  <span>
                                    {" "}
                                    <br />
                                    {a.name}
                                  </span>
                                ))}
                              </p> */}
                              <div>
                                {Object.keys(file).length !== 0 ? (
                                  <div className="d-flex">
                                    {attachments123()}
                                  </div>
                                ) : editForm === true && data ? (
                                  <>
                                    <div class="d-flex">
                                      {attachment?.map((Data) => (
                                        <div class="uploaded-image mr-1">
                                          <i class="" style={{ width: "20px" }}>
                                            <a
                                              href={Data.attachment_path}
                                              target={"_blank"}
                                              rel="noreferrer"
                                            >
                                              {Data.attachment_path.includes(
                                                ".pdf"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/pdf.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".xlsx"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/xlsx.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".xls"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/xlsx.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".doc"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/docs.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".docs"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/docs.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".pptx"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/ppt.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : Data.attachment_path.includes(
                                                ".mp4"
                                              ) ? (
                                                <img
                                                  src={
                                                    "images/policy-icon/video.png"
                                                  }
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              ) : (
                                                <img
                                                  src={Data.attachment_path}
                                                  alt="image"
                                                  width="70px"
                                                  height="70px"
                                                />
                                              )}
                                            </a>
                                          </i>
                                          <button
                                            type="button"
                                            onClick={(e) => {
                                              deleteAttachment(
                                                Data?.attachment_path?.split(
                                                  "/"
                                                )[4]
                                              );
                                            }}
                                            className="btn btn-blanktd text-danger ml-2"
                                          >
                                            <i className="far fa-trash-alt text-danger"></i>
                                          </button>
                                          {/* <i 
                                        onClick={(e) => {
                                          props.editPolicy((prev) => ({
                                            ...prev,
                                            attachment: [],
                                          }));
                                        }}
                                        className="far fa-trash-alt text-danger"></i> */}
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div class="col-md-6">
                            <div class="form-group innergroup">
                              <label>External Youtube Link</label>
                              <a
                                style={{
                                  fontSize: "100%",
                                  color: "#0195D4",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  setExternal(false);
                                  setnoticeData({
                                    ...noticeData,
                                    external: null,
                                  });
                                  setUploadDataIm([]);
                                }}
                              >
                                File
                              </a>

                              <input
                                name="external"
                                onChange={handleChange}
                                value={values.external}
                                type="text"
                                class="form-control"
                                placeholder="Enter Link here..."
                              />
                              <div className="small text-danger">
                                {errors.external}
                              </div>
                              {/* <p>
                                Caption text, description, error notification{" "}
                              </p> */}
                            </div>
                          </div>
                        )}
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label className="d-block mb-2">Status</label>
                            <label class="logCheck d-inline-block mr-4">
                              Active
                              <input
                                name="status"
                                checked={status == true}
                                onChange={(e) => {
                                  setSatus(true);
                                }}
                                type="radio"
                              />
                              <span class="checkmark"></span>
                            </label>
                            <label class="logCheck d-inline-block">
                              Inactive
                              <input
                                checked={status == false}
                                onChange={(e) => {
                                  setSatus(false);
                                }}
                                type="radio"
                                name="status"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <button
                      type="submit"
                      class="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};
export default CmsNewsForms;
