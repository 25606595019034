import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getTravelList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse?${queryParm}`);
};

//---DropDown in Ticket
export const getAirline = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/airline/drop-down`);
};

export const getTaxRate = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/tax-rate/drop-down`);
};

export const getGstState = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/state?paginate=${paginate}`);
};

//-- Ticket Grid Listing

export const getTicketList = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expansetravel_detail`);
};

export const updateTable = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expansetravel_detail/${id}`,
    data
  );
};

export const deleteTable = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/travel_detail/${id}`);
};

export const saveTable = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expansetravel_detail`, data);
};
export const saveTicket = async (sendData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_ticket`,
    sendData
  );
};

export const updateTicket = async (payload, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_ticket/${id}`,
    payload
  );
};

export const getTicketData = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_ticket?travel_id=${travel_id}`
  );
};

export const deleteTicket = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/expense_ticket/${id}`);
};