import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const acceptTerms = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/accept-tnc`, data);
};

export const quaterlyTerms = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-quaterly-terms?${queryParam}`);
};

export const checkQuaterlyTerms = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/quater-terms`, data);
};
