import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as VendorService from "../../service/cashbookService";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";

const ConfirmModal = (props) => {
  const { confirmModal, onConfirmClose, sendIdData, getDemandList, finalData } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [cashBookDemand, setCashBookDemand] = useState({
    sap_posting_status: "true",
    receipt_no: "",
    status: "C",
    amount: finalData?.amount,
    beneficiary_user_id: finalData?.beneficiary_user_id,
    doc_no: finalData?.doc_no,
    doc_type: finalData?.doc_type,
    remarks: finalData?.remarks,
  });

  const [formValidation, setFormValidation] = useState({
    receipt_no: "",
  });
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!cashBookDemand.receipt_no) {
      isError = true;
      formerr.receipt_no = "Id is required";
      setFormValidation(formerr);
    }
    // else if (cashBookDemand.receipt_no.length <= ) {
    //   isError = true;
    //   formerr.receipt_no = "*Atlest 20 characters is Required";
    //   setFormValidation(formerr);
    // }
    return isError;
  };
  console.log(finalData, "finalData");
  const updateDemand = (id, cashBookDemand) => {
    VendorService.updateDemand(id, cashBookDemand)
      .then((res) => {
        if (res.data.status == true) {
          getDemandList("", 1, 10, "-id", true);
          onConfirmClose();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleValidateForm = (sendIdData, cashBookDemand) => {
    if (handleValidate()) return false;
    updateDemand(sendIdData, cashBookDemand);
  };
  return (
    <Modal
      show={confirmModal}
      onHide={onConfirmClose}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label style={{ textAlign: "center" }}>
                      Enter Receipt Id
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control nonumbdrop"
                      placeholder="Enter Id"
                      name="receipt_no "
                      maxLength={15}
                      value={cashBookDemand?.receipt_no}
                      onChange={(e) => {
                        setCashBookDemand({
                          ...cashBookDemand,
                          receipt_no: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          receipt_no: "",
                        });
                      }}
                    />
                    <div className="small text-danger">
                      {formValidation.receipt_no}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  onConfirmClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleValidateForm(sendIdData, cashBookDemand);
                }}
                // onClick={() => {
                //   deleteOnDuty(sendIdData);
                // }}
                className="btn btn-primary-inner bpi-main"
              >
                Submit
              </button>
            </>
          )}
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default ConfirmModal;
