import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import * as LoanDataService from "../../service/loansalary";
import { useState } from "react";
import * as moment from "moment";
import Select from "react-select";

const validationSchema = Yup.object({
  // user_id: Yup.string()
  //   .matches(/[^\s*].*[^\s*]/g, "*This field cannot contain only blank spaces")
  //   .required("*Employee Code is required"),
  amount: Yup.string().required("*Amount is required"),
  loan_type: Yup.string().required("*Loan Type is required"),
  no_of_installment: Yup.string().required(
    "*No. of Installment number is required"
  ),
  // apply_date: Yup.string().required("*Apply Date number is required"),
  recovery_date: Yup.string().required("*Recovery Start Date is required"),
  attachment_path: Yup.mixed().required("*Attachment Type is required"),
  comments: Yup.string().required("*Add Comments is required"),
  // loanForm: Yup.number().test(
  //   "Is positive?",
  //   "ERROR: The number must be greater than 0!",
  //   (value) => value > 0
  // ),
});

const validationSchema1 = Yup.object({
  // user_id: Yup.string()
  //   .matches(/[^\s*].*[^\s*]/g, "*This field cannot contain only blank spaces")
  //   .required("*Employee Code is required"),
  amount: Yup.string().required("*Amount is required"),
  // loan_type: Yup.string().required("*Loan Type is required"),
  // no_of_installment: Yup.string().required("*No. of Installment number is required"),
  // apply_date: Yup.string().required("*Apply Date number is required"),
  // recovery_date: Yup.string().required("*Recovery Start Date number is required"),
  attachment_path: Yup.mixed().required("*Attachment Type is required"),
  comments: Yup.string().required("Add Comments is required"),
});

const AddLoanApply = (props) => {
  const nameForm = "Add ";
  const { edit, showForm, onClose, editModule, loanApply, filterclose } = props;
  const dispatch = useDispatch();
  let condition = "true";
  const [uploadLoanData, setUploadLoanData] = useState({
    attachment_path: {},
  });
  const [imgSize, setImgSize] = useState(false);

  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [LoanUser, setLoanUser] = useState([]);
  const [checkIndex, setCheckIndex] = useState("Loan");
  const [empDetails, setEmpDetails] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [loanType, setLoanType] = useState("");
  const [noOfInstallment, setNoOfInstallment] = useState("");
  const [applyDate, setApplyDate] = useState("");
  const [recoveryDate, setRecoveryDate] = useState("");
  const [attachment, setAttachment] = useState("");
  const [comments, setComments] = useState("");
  const [users, setUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [userValue, setUserValue] = useState([]);
  const [userId, setUserId] = useState("");
  const [minimumYearAlert, setMinimumYearAlert] = useState("");
  const [maxLoanAmt, setMaxLoanAmt] = useState("");
  const [loanForm, setLoanForm] = useState({
    user_id: userId,
    amount: edit == false ? null : editModule?.amount,
    loan_type: edit == false ? "" : editModule?.loan_type,
    no_of_installment: edit == false ? null : editModule?.no_of_installment,
    apply_date: edit == false ? "" : editModule?.apply_date,
    recovery_date: edit == false ? "" : editModule?.recovery_date,
    attachment_path: {},
    comments: edit == false ? "" : editModule?.comments,
  });

  //   const keysRemoveMethod = () => {
  //     let object = {}
  //     Object.keys(loanForm)?.filter((obj) => {
  //      if (loanForm[obj] !== "") {
  //          return true;
  //      }
  //      }).map((x) => {
  //          object.x = loanForm[x];
  //      })
  //   }

  const isLoanRequestExist = (values) => {
    let myData = values;
    let obj = {
      loan_type: myData.loan_type,
      apply_date: myData.apply_date,
      user_id: userId,
    };
    LoanDataService.isLoanExist(obj).then((response) => {
      if (response?.data?.dataList?.is_exist === true) {
        toast.error("You have already applied Loan Request");
      } else {
        if (loanAmount >= 500) {
          if (noOfInstallment > 0) {
            if (condition == "true") {
              condition = "false";
              saveLoan(values);
            }
          } else {
            toast.error("No. of installment can not be 0");
          }
        } else {
          toast.error("minimum amount should be 500");
        }
      }
    });
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const isSalaryRequestExist = (values) => {
    let myData = values;
    let obj = { apply_date: myData.apply_date, user_id: userId };
    LoanDataService.isSalaryExist(obj).then((response) => {
      if (response?.data?.dataList?.is_exist === true) {
        toast.error("You have already applied Salary Advance Request");
      } else {
        if (condition == "true") {
          condition = "false";
          saveSalaryAdvance(values);
        }
        // if(attachment === ""){
        //   toast.error("Kindly Select Attachment")
        // }
        // else{
        // saveSalaryAdvance(values);
        // }
      }
    });
  };

  const saveLoan = (values) => {
    setIsLoading(true);
    let myData = values;
    myData.user_id = userId;
    // console.log('user_id', userId);
    myData.apply_date = moment(new Date()).format("YYYY-MM-DD");
    // if (minimumYearAlert != "") {
    //   myData.flag = "E";
    //   myData.exception_reason = "Joining Date"
    // }
    // else if(myData.amount > maxLoanAmt){
    //   myData.flag = "E";
    //   myData.exception_reason = "Amount"
    // }
    // else if(moment(myData?.recovery_date).month() > moment(new Date()).month()){
    //   myData.flag = "E";
    //   myData.exception_reason = "Recovery Date"
    // }
    // else if(myData?.no_of_installment > 10){
    //   myData.flag = "E";
    //   myData.exception_reason = "NO. Of Installment"
    // }
    myData.attachment_path = uploadLoanData.attachment_path;
    LoanDataService.saveLoan(myData)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          condition = "true";
          //   onAddCallBack(response);
          setIsLoading(false);
          if (minimumYearAlert != "") {
            toast.info(minimumYearAlert);
          }
          if (response.data.message == "Loan Request Added successfully.") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setUploadLoanData({ attachment_path: {} });
          onClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const dataBlank = () => {
    onClose();
  };

  const saveSalaryAdvance = (values) => {
    setIsLoading(true);
    let dataLoanUpload = values;
    dataLoanUpload.user_id = userId;
    dataLoanUpload.apply_date = moment(new Date()).format("YYYY-MM-DD");
    if (dataLoanUpload.amount >= 500) {
      // if (dataLoanUpload.amount > 55000) {
      //   dataLoanUpload.flag = "E";
      // }
      // if (minimumYearAlert != "") {
      //   dataLoanUpload.flag = "E";
      // }
      dataLoanUpload.attachment_path = uploadLoanData.attachment_path;
      LoanDataService.saveSalaryAdvance(values)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            condition = "true";
            //   onAddCallBack(response);
            setIsLoading(false);
            if (
              response.data.message ===
              "Advance Salary request added successfully."
            ) {
              if (minimumYearAlert != "") {
                toast.info(minimumYearAlert);
              }
              toast.success(response.data.message);
              setUploadLoanData({ attachment_path: {} });
              onClose();
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      toast.error("minimum amount should be 500");
      setIsLoading(false);
    }
  };

  const onProfileChange = (e) => {
    const files = e.target.files[0];
    if (files.size < 1033415 || files.size === 1033415) {
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      LoanDataService.uploadImages(fileData).then((response) => {
        setUploadLoanData({
          ...uploadLoanData,
          attachment_path: response.data,
        });
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    } else {
      setImgSize(true);
    }
  };

  const updateLoan = (values) => {
    setIsLoading(true);
    let dataLoanUpload = values;
    dataLoanUpload.user_id = userId;
    dataLoanUpload.flag = editModule?.flag;
    dataLoanUpload.attachment_path = uploadLoanData.attachment_path;
    LoanDataService.updateLoan(values, editModule?.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          //   onAddCallBack(response);
          setIsLoading(false);
          if (response.data.message === "Loan Updated successfully.") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setUploadLoanData({ attachment_path: {} });
          onClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateSalaryAdvance = (values) => {
    setIsLoading(true);
    let dataLoanUpload = values;
    dataLoanUpload.user_id = userId;
    dataLoanUpload.flag = editModule?.flag;
    dataLoanUpload.attachment_path = uploadLoanData.attachment_path;
    LoanDataService.updateSalaryAdvance(values, editModule?.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          //   onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
          setUploadLoanData({ attachment_path: {} });
          onClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUserLoan = (id) => {
    LoanDataService.getUserLoan(id)
      .then((response) => {
        setLoanUser(response?.data?.dataList?.result);
        let obj =
          response?.data?.dataList?.result[0].emp_first_name +
          " " +
          response?.data?.dataList?.result[0].emp_last_name +
          "(" +
          response?.data?.dataList?.result[0].emp_code +
          ")";
        setEmpDetails(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getUserList = () => {
    LoanDataService.getUser().then((res) => {
      setUsersList(res?.data?.dataList?.result);
      let data = res?.data?.dataList?.result?.map((x) => {
        return {
          value: x.id,
          label: x.full_name + "(" + x.username + ")",
        };
      });
      setUsers(data);
      if (data.length === 1) {
        setUserId(data[0].value);
      }
    });
  };

  // const handleRemove = () => {
  //   setLoanForm({
  //     loan_type: "",
  //     no_of_installment: "",
  //     recovery_date: "",
  //   })

  // }

  const handleUser = (e) => {
    setUserValue(e);
    setUserId(e[0].value);
    // console.log(e);
    let data = usersList;
    data.map((res) => {
      if (res.id == e[0].value) {
        let curDate = moment(new Date());
        let difference = curDate.diff(
          moment(res.details__date_of_joining, "YYYY-MM-DD"),
          "years"
        );
        if (difference < 2) {
          setMinimumYearAlert("User has not completed 2 years.");
        }
      }
    });
  };
  const maxLoanAmount = (e) => {
    if (e == "Housing Security Deposit Loan") {
      setMaxLoanAmt("50000");
    }
    if (e == "Marriage Loan") {
      setMaxLoanAmt("60000");
    }
    if (e == "Personal exigency Loan") {
      setMaxLoanAmt("70000");
    }
    if (e == "Medical Exigency Loan") {
      setMaxLoanAmt("80000");
    }
  };

  const ApiHandler = (values) => {
    // saveLoan(values)
    if (attachment !== "") {
      if (checkIndex === "Loan") {
        isLoanRequestExist(values);
      } else {
        isSalaryRequestExist(values);
      }
    } else {
      toast.error("Kindly Choose an attachment");
    }
  };

  const UpdateLoanAndSalary = (values) => {
    if (checkIndex === "Loan") {
      updateLoan(values);
    } else {
      updateSalaryAdvance(values);
    }
  };

  useEffect(() => {
    getUserList();
    getUserLoan(false);
    if (
      editModule?.type == "Loan" ||
      editModule?.type == "Loan(Exceptional Case)"
    ) {
      maxLoanAmount(editModule?.loan_type);
      setLoanForm({
        ...loanForm,
        loan_type: editModule?.loan_type,
      });
      setCheckIndex("Loan");
      setLoanAmount(editModule?.amount);
      setLoanType(editModule?.loan_type);
      setNoOfInstallment(editModule?.no_of_installment);
      setApplyDate(editModule?.apply_date);
      setRecoveryDate(editModule?.recovery_date);
      if (editModule?.attachment !== undefined) {
        setAttachment(editModule?.attachment[0]?.attachment_path);
      }
      setUserId(editModule?.emp_id);
      setComments(editModule?.comments);
      let obj = {
        value: editModule?.emp_id,
        label: editModule?.emp_full_name + "(" + editModule?.emp_code + ")",
      };
      setUserValue(obj);
    }
    if (
      editModule?.type == "Salary" ||
      editModule?.type == "Salary(Exceptional Case)"
    ) {
      setMaxLoanAmt("55000");
      setCheckIndex("Salary");
      setLoanAmount(editModule?.amount);
      setLoanType(editModule?.loan_type);
      setNoOfInstallment(editModule?.no_of_installment);
      setApplyDate(editModule?.apply_date);
      setRecoveryDate(editModule?.recovery_date);
      setAttachment(editModule?.attachment_path);
      setComments(editModule?.comments);
      setUserId(editModule?.emp_id);
      let obj = {
        value: editModule?.emp_id,
        label: editModule?.emp_full_name + "(" + editModule?.emp_code + ")",
      };
      setUserValue(obj);
    }
  }, []);

  const ignoreIntValue = (event) => {
    if (event.key == "-") {
      toast.warning("you cannot enter negative amount.");
      setLoanAmount({
        amount: "",
      });
    }
  };
  const ignoreInstallValue = (event) => {
    if (event.key == "-") {
      toast.warning("you cannot enter negative no_of_installment.");
      setLoanAmount({
        no_of_installment: "",
      });
      let myInstall = document.getElementById("no_of_installments");
      myInstall.value = "";
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={loanForm}
        validationSchema={
          checkIndex === "Loan" ? validationSchema : validationSchema1
        }
        onSubmit={async (values) => {
          {
            edit == true ? UpdateLoanAndSalary(values) : ApiHandler(values);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit === true ? "Edit" : "Add"} Loan and Salary Advance{" "}
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-10"></div>
                  <div className="col-md-2">
                    <a
                      href="https://uat-mypi-public.s3.ap-south-1.amazonaws.com/loan_salary/LoanPolicy.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Loan Policy
                    </a>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group innergroup answerboxpollsuy">
                          <label className="d-block mb-2">
                            Apply Advance for<span class="text-danger">*</span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Loan
                            <input
                              type="radio"
                              name="AvailableLoan"
                              disabled={editModule?.type == "Salary"}
                              onChange={() => {
                                setCheckIndex("Loan");
                              }}
                              checked={checkIndex === "Loan"}
                            />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            Salary
                            <input
                              type="radio"
                              name="AvailableSalary"
                              disabled={editModule?.type == "Loan"}
                              onChange={() => {
                                setCheckIndex("Salary");
                              }}
                              checked={checkIndex === "Salary"}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      {/* {
                      users.length > 1 ? 
                      ( */}
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Employee Code<span class="text-danger">*</span>
                            </label>
                            <Select
                              isMulti
                              options={users}
                              name="users"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                handleUser(e);
                              }}
                              value={userValue}
                            />
                          </div>
                        </div>
                      {/* ) 
                      : (
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Employee Code<span class="text-danger">*</span>
                            </label>
                            <Select
                              isMulti
                              isDisabled={false}
                              options={users}
                              name="users"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                handleUser(e);
                              }}
                              value={users}
                            />
                          </div>
                        </div>
                      )
                    } */}

                      {checkIndex === "Loan" ? (
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Loan Type<span class="text-danger">*</span>
                            </label>
                            <select
                              class="form-control newbgselect"
                              name="loan_type"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setLoanForm({
                                  ...loanForm,
                                  loan_type: e.target.value,
                                });
                                maxLoanAmount(e.target.value);
                                setLoanType(e.target.value);
                              }}
                              value={loanType}
                            >
                              <option
                                value=""
                                label="Select Loan Type"
                                disabled
                              />
                              <option value="Marriage Loan">
                                Marriage Loan
                              </option>
                              <option value="Medical Exigency Loan">
                                Medical Exigency Loan
                              </option>
                              <option value="Personal exigency Loan">
                                Personal exigency Loan
                              </option>
                              <option value="Housing Security Deposit Loan">
                                Housing Security Deposit Loan
                              </option>
                            </select>
                            {touched.loan_type && errors.loan_type ? (
                              <div className="small text-danger">
                                {errors.loan_type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Amount<span class="text-danger">* </span>
                          </label>
                          {checkIndex == "Loan" ? (
                            <span>
                              (Max. Limit - {"3 Months Basic Salary"})
                            </span>
                          ) : (
                            <span>(Max. Limit - {"70% of Gross Salary"})</span>
                          )}
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Enter Amount"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 7))
                            }
                            onKeyUp={(event) => {
                              ignoreIntValue(event, "amount");
                            }}
                            name="amount"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setLoanForm({
                                ...loanForm,
                                amount: e.target.value,
                              });
                              setLoanAmount(e.target.value);
                            }}
                            value={loanAmount}
                          />
                          {checkIndex == "Loan" ? (
                            <div className="small text-secondary">
                              {/* <span style={{color:"black"}}>Note:-</span> Gross Salary definition should be
                          mentioned in apply page as Basic Salary+ H.R.A + Other/Special Allowance+
                          Transport/Conveyance/Car Allowance. */}
                            </div>
                          ) : (
                            <div className="small text-secondary">
                              <span style={{ color: "black" }}>Note:-</span>
                              Gross Salary = Basic Salary+ H.R.A + Other/Special
                              Allowance+ Transport/Conveyance/Car Allowance.
                            </div>
                          )}
                          {touched.amount && errors.amount ? (
                            <div className="small text-danger">
                              *Amount is required
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {checkIndex === "Loan" ? (
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              No. of Installment
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="a"
                              class="form-control"
                              id="no_of_installments"
                              placeholder="Enter No. of Installment"
                              name="no_of_installment"
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 3))
                              }
                              onBlur={handleBlur}
                              onKeyUp={(event) => {
                                ignoreInstallValue(event);
                              }}
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={blockInvalidChar}
                              onChange={(e) => {
                                handleChange(e);
                                setLoanForm({
                                  ...loanForm,
                                  no_of_installment: e.target.value,
                                });
                                setNoOfInstallment(e.target.value);
                              }}
                              value={noOfInstallment}
                            />
                            {touched.no_of_installment &&
                            errors.no_of_installment ? (
                              <div className="small text-danger">
                                *No. of Installment is required
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Apply Date<span class="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            name="apply_date"
                            disabled
                            onChange={(e) => {
                              handleChange(e);
                              setLoanForm({
                                ...loanForm,
                                apply_date: moment(new Date()).format(
                                  "YYYY-MM-DD"
                                ),
                              });
                              setApplyDate(
                                moment(new Date()).format("YYYY-MM-DD")
                              );
                            }}
                            onBlur={handleBlur}
                            min={
                              !editModule
                                ? moment(new Date()).format("YYYY-MM-DD")
                                : ""
                            }
                            value={moment(new Date()).format("YYYY-MM-DD")}
                          />
                          {touched.apply_date && errors.apply_date ? (
                            <div className="small text-danger">
                              {errors.apply_date}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {checkIndex === "Loan" ? (
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Recovery Start Date
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              name="recovery_date"
                              onChange={(e) => {
                                handleChange(e);
                                setLoanForm({
                                  ...loanForm,
                                  recovery_date: e.target.value,
                                });
                                setRecoveryDate(e.target.value);
                              }}
                              onBlur={handleBlur}
                              min={moment(new Date()).format("YYYY-MM-DD")}
                              max={`${moment(new Date()).format(
                                "YYYY"
                              )}-${moment(new Date()).format("MM")}-${moment(
                                new Date()
                              )
                                .endOf("month")
                                .format("DD")}`}
                              value={recoveryDate}
                            />
                            {touched.recovery_date && errors.recovery_date ? (
                              <div className="small text-danger">
                                {errors.recovery_date}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="col-md-4">
                        <div class="form-group innergroup position-relative modaldefaultclose ">
                          <label>
                            Attachment <span class="text-danger">*</span>
                          </label>
                          {uploadLoanData?.attachment_path?.original_name !==
                          undefined ? (
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(Image, PDF Format)"
                              value={
                                (edit === true
                                  ? editModule?.attachment_path
                                  : uploadLoanData?.attachment_path?.original_name?.slice(
                                      0,
                                      28
                                    )) + "..."
                              }
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control bg-white"
                              disabled
                              placeholder="(Image, PDF Format)"
                            />
                          )}
                          <div class="upload-btn-wrapper up-loposition">
                            <button class="uploadBtn">Browse</button>
                            <input
                              type="file"
                              title=""
                              name="attachment_path"
                              accept="image/png, image/jpeg"
                              onChange={(e) => {
                                onProfileChange(e);
                                setLoanForm({
                                  ...loanForm,
                                  attachment_path: e.target.value,
                                });
                                setAttachment(e.target.value);
                              }}
                            />
                            {touched.attachment_path &&
                            errors.attachment_path ? (
                              <div className="small text-danger">
                                *Attachment is required
                              </div>
                            ) : null}
                          </div>
                          {imgSize ? (
                            <div className="small text-danger">
                              File Size should not exceed 1mb
                            </div>
                          ) : null}

                          <p>
                            Selected files&nbsp;
                            {edit === true &&
                            uploadLoanData?.attachment_path?.original_name ===
                              undefined ? (
                              <span>
                                {attachment !== null && attachment !== undefined
                                  ? attachment.split("/").pop()
                                  : ""}
                              </span>
                            ) : (
                              <span>
                                {uploadLoanData?.attachment_path?.original_name}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>
                            Add Comments<span class="text-danger">*</span>
                          </label>
                          <textarea
                            class="form-control"
                            maxLength={50}
                            placeholder="Enter Comments here..."
                            name="comments"
                            onChange={(e) => {
                              handleChange(e);
                              setLoanForm({
                                ...loanForm,
                                comments: e.target.value,
                              });
                              setComments(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={comments}
                          ></textarea>
                          {touched.comments && errors.comments ? (
                            <div className="small text-danger">
                              {errors.comments}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button class="btn btn-outline-danger" onClick={onClose}>
                      Cancel
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main"
                      type="submit"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddLoanApply;
