import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const addses = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/ses`,
      Data
    );
  }
  export const updateses = async (Data) => {
    return axios.put(
      `${AUTH_BASE_URL}/ses`,
      Data
    );
  }

export const deleteses = async (id) => {
    return axios.delete(
      `${AUTH_BASE_URL}/ses?id=${id}`
      
    );
  }

  export const getAllSes = async (search,page_no,page_size,paginate,release_start,plant_code,po_code,approver_1,pg_code,sort_by) => {
    return axios.get(`${AUTH_BASE_URL}/ses?search=${search}&release_start=${release_start}&plant_code=${plant_code}&approver_1=${approver_1}&po_code=${po_code}&pg_code=${pg_code}&page_no=${page_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}`)
  }
  export const getAllSesApproverExport = async (search,page_no,page_size,paginate,release_start,plant_code,po_code,approver_1,pg_code,sort_by) => {
    return axios({
      url: `${AUTH_BASE_URL}/ses/export?search=${search}&release_start=${release_start}&plant_code=${plant_code}&approver_1=${approver_1}&po_code=${po_code}&pg_code=${pg_code}&page_no=${page_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}`,
      method: "GET",
      responseType: "blob",
    });
  }
  export const getAllSesmaster = async (search,page_no,page_size,paginate,sort_by,sheet_no,created_at,created_by,status,approver_1,myses, self_approval_data) => {
    return axios.get(`${AUTH_BASE_URL}/ses/master?page_no=${page_no}&search=${search}&created_at=${created_at}&created_by=${created_by}&approver=${approver_1}&status=${status}&sheet_no=${sheet_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}&myses=${myses}&self_approval_data=${self_approval_data}`)
  }
  export const changesesstatus = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/ses/change_status`,
      Data
    );
  }
  export const SyncSes = async () => {
    return axios.post(
      `${AUTH_BASE_URL}/ses/syncSes`,
    );
  }
  export const getExportPRReport = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/ses/ses_export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const changeBulksesstatus = async (Data) => {
    return axios.post(
      `${AUTH_BASE_URL}/ses/change_bulk_status`,
      Data
    );
  }

  export const syncSesFromSap = async (values) => {
    return axios.post(`${AUTH_BASE_URL}/ses/sync_ses`, values);
  };