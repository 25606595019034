import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getTerritory = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
}
export const getMarketSize = async () => {
    return axios.get(`${AUTH_BASE_URL}/market-size`);
};

export const getLastMarketSize = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/market-size/last-market-size${query}`)
}


export const postMarketSize = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-size`,body);
}

export const putMarketSize = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/market-size`,body);
}

export const getMarketSizeApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/market-size/approver-market-size-logs${query}`);
}

export const postMarketSizeAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-size/action-market-size`,body);
}