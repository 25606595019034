import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuditLog from "../audit/auditlog";
import { selectAudit, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as moduleDataService from "../../service/moduleService";
import Select from "react-select";

const moduleOption = [
  {
    label: "Root Menu",
    value: "ROOT_M",
  },
  {
    label: "Home Menu",
    value: "LEFT_M",
  },
  {
    label: "Ess Menu",
    value: "ESS_M",
  },
  {
    label: "Agri Menu",
    value: "AGRI_M",
  },
  { label: "My Market", value: "AG_MAR" },
  { label: "My Strategy", value: "AG_STA" },
  { label: "My Plan", value: "AG_PLN" },
  {
    label: "Profile Menu",
    value: "PROF_M",
  },
  {
    label: "External App",
    value: "EXT_APP",
  },
  {
    label: "My Pi App",
    value: "MYPI_APP",
  },
  {
    label: "Office365",
    value: "O365",
  },
  {
    label: "Widget",
    value: "WID",
  },
  {
    label: "Business Transaction Menu",
    value: "BT_M",
  },
];

const urlOptions = [
  {
    label: "Internal Application URL",
    value: "INTR",
  },
  {
    label: "External URL",
    value: "EXTR",
  },
];

const REDIRECTOPT = [
  {
    label: "Internal",
    value: "INTR",
  },
  {
    label: "External",
    value: "EXTR",
  },
];

const AddModule = (props) => {
  const nameForm = "Add Module";
  const { edit, showForm, onClose, editModule, onAddCallBack, moduleList } =
    props;
  const [moduleFormData, setModuleFormData] = useState({
    id: 0,
    type: "",
    name: "",
    module_id: {},
    app_name: "",
    app_store_id: "",
    app_store_locale: "",
    play_store_id: "",
    mobile_app: "false",
    url_type: "",
    url: "",
    icon: {},
    hover_icon: {},
    sequence_no: "",
    is_active: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState();
  const [links, setLinks] = useState();
  const [imgSize, setImgSize] = useState(false);
  const [imgSizeNew, setImgSizeNew] = useState(false);
  const [showField, setShowField] = useState(false)
  const [uploadData, setUploadData] = useState({
    icon: {},
  });
  const [formValidation, setFormValidation] = useState({
    name: "",
    moduleType: "",
    urlType: "",
    sequence_no: "",
  });
  const [uploadDataIm, setUploadDataIm] = useState({
    hover_icon: {},
  });
  const [isModuleExist, setIsModuleExist] = useState("");
  const [validParent, setValidParent] = useState(false);
  const [sequenceExist, isSequenceExist] = useState(false);
  const [maxSequenceNum, setMaxSequenceNum] = useState("")
  const [urlExist, isUrlExist] = useState(false);
  const [dropDownData, setDropDownData] = useState({
    moduleType: "",
    urlType: "",
    parent: "",
    redirectType: ""
  });
  const [file, setfile] = useState([]);
  const [filess, setfilesss] = useState([]);
  const dispatch = useDispatch();
  const auditValueLog = useSelector(selectAudit);


  useEffect(() => {
    console.log({ moduleFormData });
  }, [moduleFormData])

  const saveModule = (values) => {
    setIsLoading(true);
    moduleDataService
      .saveModule(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
          setUploadData({ icon: {} });
          setUploadDataIm({ hover_icon: {} });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    if (moduleFormData?.mobile_app === false || dropDownData?.moduleType?.value !== "EXT_APP") {
      setModuleFormData((prev) => ({
        ...prev,
        app_name: '',
        app_store_id: '',
        app_store_locale: '',
        play_store_id: ''
      }))
    }
  }, [moduleFormData?.mobile_app, dropDownData?.moduleType])

  const updateModuleData = (values) => {
    moduleDataService
      .updateModule(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
          setUploadData({ icon: {} });
          setUploadDataIm({ hover_icon: {} });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const ModuleListData = (optionsModuleList) => {
    let opt = optionsModuleList?.map((item, index) => {
      return {
        key: `modules${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };

  const handleValidation = (opt) => {
    if (
      opt?.value === "LEFT_M" ||
      opt?.value === "ESS_M" ||
      opt?.value === "AGRI_M" ||
      opt?.value === "BT_M"
    ) {
      setValidParent(false);
    } else {
      setValidParent(true);
    }
  };

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!dropDownData.moduleType) {
      isError = true;
      formerr.moduleType = "*Required Type";
      setFormValidation(formerr);
    }
    if (!moduleFormData.name) {
      isError = true;
      formerr.name = "*Required Name ";
      setFormValidation(formerr);
    }
    if (!dropDownData.urlType) {
      isError = true;
      formerr.urlType = "*Required Url Type";
      setFormValidation(formerr);
    }
    if (!moduleFormData.sequence_no) {
      isError = true;
      formerr.sequence_no = "*Required Sequence Number ";
      setFormValidation(formerr);
    }
    return isError;
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleValidateModule = () => {
    if (handleValidate()) return false;
    allModuleData();
  };

  const onProfileChange = (e, flag) => {
    const newFile = e.target.files[0];
    if (flag == "icon") {
      setfile(newFile);
      setLink(URL.createObjectURL(e.target.files[0]));
    } else {
      setfilesss(newFile);
      setLinks(URL.createObjectURL(e.target.files[0]));
    }
    if (newFile.size < 1033415 || newFile.size === 1033415) {
      if (
        newFile.type === "image/png" ||
        newFile.type === "image/svg" ||
        newFile.type === "image/jpeg" ||
        newFile.type === "image/jpg"
      ) {
        const fileData = new FormData();
        setImgSize(false);
        setImgSizeNew(false);
        fileData.append("uploaded_file", newFile);
        moduleDataService.uploadImages(fileData).then((response) => {
          if (flag == "icon") {
            setUploadData({ ...uploadData, icon: response.data });
          } else {
            setUploadDataIm({ ...uploadDataIm, hover_icon: response.data });
          }
        });
      } else {
        toast.error("Only jpg, png, jpeg and svg files are supported");
      }
    } else if (flag == "icon") {
      setImgSize(true);
    } else {
      setImgSizeNew(true);
    }
  };

  const dataBlank = () => {
    onClose();
  };

  useEffect(() => {
    if (edit) {
      if (editModule) {
        setModuleFormData(editModule);
        Object.keys(editModule).forEach((item) => {
          if (
            moduleFormData[item] !== undefined &&
            moduleFormData[item] !== null
          ) {
            if (item === "type") {
              let val = moduleOption
                .filter((obj) => {
                  if (obj?.value === editModule["type"]) {
                    return true;
                  }
                })
                .map((x) => x);
              setDropDownData((prev) => ({
                ...prev,
                moduleType: val[0],
              }));
            } else if (item === "url_type") {
              if (editModule['url_type'] === "EXTR") {
                setShowField(true)
                if (editModule['redirect_type'] !== "") {
                  setDropDownData((prev) => ({
                    ...prev,
                    redirectType: REDIRECTOPT?.filter((each) => {
                      if (each?.value === editModule['redirect_type']) {
                        return true
                      }
                    })[0]
                  }))
                }
              }
              let url = urlOptions
                .filter((obj) => {
                  if (obj?.value === editModule["url_type"]) {
                    return true;
                  }
                })
                .map((x) => x);
              setDropDownData((prev) => ({
                ...prev,
                urlType: url[0],
              }));
            } else {
              moduleFormData[item] = editModule[item];
            }
          }
          if (item === "module_id") {
            if (moduleFormData[item] === null) {
              moduleFormData[item] = 0;
            } else {
              let obj = moduleList
                .filter((obj) => {
                  if (obj.id === editModule["module_id"]) {
                    return true;
                  }
                })
                .map((x) => {
                  return {
                    label: x.name,
                    value: x.id,
                  };
                });
              setDropDownData((prev) => ({
                ...prev,
                parent: obj[0],
              }));
            }
          }
          if (item === "mobile_app") {
            setModuleFormData((prev) => ({
              ...prev,
              mobile_app: editModule[item] === true ? 'true' : 'false'
            }))
          }
        });
        moduleFormData["hover_icon"] = {};
        moduleFormData["icon"] = {};
      }
    }
  }, [editModule]);

  const DataPush = (events) => {
    setModuleFormData({
      ...moduleFormData,
      is_active: events.target.value == "true" ? true : false,
    });
  };

  const checkSequenceExist = async (e) => {
    if (edit == true) {
      if (
        e.target.value !== "" &&
        Number(e.target.value) !== editModule?.sequence_no
      ) {
        var obj = {};
        if (moduleFormData?.module_id !== 0) {
          obj = {
            sequence_no: Number(e.target.value),
            parent_id: moduleFormData?.module_id,
          };
        } else {
          obj = {
            sequence_no: e.target.value,
          };
        }
        await moduleDataService.isSequenceExist(obj).then((response) => {
          if (response?.status === 200) {
            if (response?.data?.dataList?.is_exist) {
              isSequenceExist(true);
              setMaxSequenceNum(response?.data?.dataList?.max_sequence_number)
            } else {
              isSequenceExist(false);
            }
          }
        });
      } else {
        isSequenceExist(false);
      }
    } else {
      if (
        e.target.value !== "" &&
        editModule?.filter((res) => res.sequence_no == Number(e.target.value))
          .length > 0
      ) {
        var obj = {};
        if (dropDownData?.parent.value !== 0) {
          obj = {
            sequence_no: Number(e.target.value),
            parent_id: dropDownData?.parent.value,
          };
        } else {
          obj = {
            sequence_no: e.target.value,
          };
        }
        await moduleDataService.isSequenceExist(obj).then((response) => {
          if (response?.status === 200) {
            if (response?.data?.dataList?.is_exist) {
              isSequenceExist(true);
              setMaxSequenceNum(response?.data?.dataList?.max_sequence_number)
            } else {
              isSequenceExist(false);
            }
          }
        });
      } else {
        isSequenceExist(false);
      }
    }
  };

  const checkNameExist = async (value) => {
    let data = {};
    data["name"] = value.name;
    data["type"] = value.type;
    data["module_id"] = value.module_id.value;
    await moduleDataService.isSequenceExist(data).then((response) => {
      setIsModuleExist(response?.data?.dataList?.is_exist);
      if (response?.data?.dataList?.is_exist === false && value?.id) {
        updateModuleData(value);
        AuditLog.addAuditLog(dispatch, nameForm);
      }
      if (
        response?.data?.dataList?.is_exist === false &&
        value?.id === undefined
      ) {
        saveModule(value);
        AuditLog.addAuditLog(dispatch, nameForm);
      }
    });
  };
  // const checkURLExist = async (e) => {
  //   if (edit == false) {
  //     if (e.target.value !== "" && Number(e.target.value) !== editModule?.url) {
  //       var obj = {};
  //       if (moduleFormData?.module_id !== 0) {
  //         obj = {
  //           url: e.target.value,
  //           parent_id: moduleFormData?.module_id,
  //         };
  //       } else {
  //         obj = {
  //           url: e.target.value,
  //         };
  //       }
  //       await moduleDataService.isSequenceExist(obj).then((response) => {
  //         if (response?.status === 200) {
  //           if (response?.data?.dataList?.is_exist) {
  //             isUrlExist(true);
  //           } else {
  //             isUrlExist(false);
  //           }
  //         }
  //       });
  //     } else {
  //       isUrlExist(false);
  //     }
  //   } else {
  //     if (e.target.value !== "" && e.target.value !== editModule?.url) {
  //       var obj = {};
  //       if (moduleFormData?.module_id !== 0) {
  //         obj = {
  //           url: e.target.value,
  //           parent_id: moduleFormData?.module_id,
  //         };
  //       } else {
  //         obj = {
  //           url: e.target.value,
  //         };
  //       }
  //       await moduleDataService.isSequenceExist(obj).then((response) => {
  //         if (response?.status === 200) {
  //           if (response?.data?.dataList?.is_exist) {
  //             isUrlExist(true);
  //           } else {
  //             isUrlExist(false);
  //           }
  //         }
  //       });
  //     } else {
  //       isUrlExist(false);
  //     }
  //   }
  // };
  const isExistsValue = (value) => {
    let error = false;
    if (edit) {
      if (editModule?.type !== value.type) {
        error = true;
      }
      if (editModule?.name === null ? "" : editModule?.name !== value.name) {
        error = true;
      }
      if (
        editModule?.module_id === null
          ? ""
          : editModule?.module_id !== value.module_id.value
      ) {
        error = true;
      }
    }
    if (error === false && value.id) {
      updateModuleData(value);
      AuditLog.addAuditLog(dispatch, nameForm);
    }
    if (error === true && value?.id) {
      checkNameExist(value);
    }
    if (error === false && value?.id === undefined) {
      checkNameExist(value);
    }
  };

  const handleDropData = (value, name) => {
    switch (name) {
      case "moduleType":
        setDropDownData((prev) => ({
          ...prev,
          moduleType: value,
        }));
        break;
      case "urlType":
        setDropDownData((prev) => ({
          ...prev,
          urlType: value,
        }));
        break;
      case "redirect":
        setDropDownData((prev) => ({
          ...prev,
          redirectType: value,
        }));
        break;
      case "parent":
        setDropDownData((prev) => ({
          ...prev,
          parent: value,
        }));
        break;
      default:
        break;
    }
  };

  const attachments123 = () => {
    <div class="uploaded-image mr-1">
      <a href={URL.createObjectURL(file)} target={"_blank"} rel="noreferrer">
        <img
          src={URL.createObjectURL(file)}
          alt="image"
          width="70px"
          height="70px"
        />
      </a>
    </div>;
  };

  const myattachments123 = () => {
    <div class="uploaded-image mr-1">
      <a href={URL.createObjectURL(filess)} target={"_blank"} rel="noreferrer">
        <img
          src={URL.createObjectURL(filess)}
          alt="image"
          width="70px"
          height="70px"
        />
      </a>
    </div>;
  };

  const allModuleData = () => {
    let dataSave = {
      id: editModule.id,
      type: dropDownData?.moduleType?.value,
      redirectType: dropDownData?.redirectType?.value,
      name: moduleFormData?.name,
      mobile_app: moduleFormData?.mobile_app === "true" ? true : false,
      app_name: moduleFormData?.app_name,
      app_store_id: moduleFormData?.app_store_id,
      app_store_locale: moduleFormData?.app_store_locale,
      play_store_id: moduleFormData?.play_store_id,
      module_id: dropDownData?.parent,
      url_type: dropDownData?.urlType?.value,
      url: moduleFormData?.url,
      icon: uploadData?.icon,
      hover_icon: uploadDataIm?.hover_icon,
      sequence_no: moduleFormData?.sequence_no,
      is_active: moduleFormData?.is_active,
    };
    if (sequenceExist !== true) {
      isExistsValue(dataSave);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={showForm}
        onHide={onClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{edit === true ? "Edit" : "Add"} Module</Modal.Title>
        </Modal.Header>
        <>
          <Modal.Body>
            <div className="row justify-content-center">
              <ToastContainer autoClose={1000} />
              <div className="col-md-11">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Select Type<span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={moduleOption}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          name="moduleType"
                          onChange={(newValue, action) => {
                            handleDropData(newValue, action.name);
                            handleValidation(newValue);
                            setFormValidation({
                              ...formValidation,
                              moduleType: "",
                            });
                          }}
                          value={dropDownData?.moduleType}
                        />
                        <div className="small text-danger">
                          {formValidation.moduleType}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Module Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Module name"
                        name="name"
                        onChange={(e) => {
                          setModuleFormData({
                            ...moduleFormData,
                            name: e.target.value,
                          });
                          setFormValidation({
                            ...formValidation,
                            name: "",
                          });
                        }}
                        value={moduleFormData?.name}
                      />
                      <div className="small text-danger">
                        {formValidation.name}
                      </div>
                    </div>
                  </div>
                  {dropDownData?.moduleType?.value !== undefined && dropDownData?.moduleType?.value === 'EXT_APP' && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">Mobile App details</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="app-active"
                          >
                            Yes
                            <input
                              type="radio"
                              name="app_details"
                              id="app-active"
                              value={"true"}
                              checked={
                                moduleFormData?.mobile_app === "true" ? true : false
                              }
                              onChange={(e) => {
                                setModuleFormData((prev) => ({
                                  ...prev,
                                  mobile_app: e.target.value
                                }))
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="app-Inactive"
                          >
                            No
                            <input
                              type="radio"
                              name="app_details"
                              id="app-Inactive"
                              value={"false"}
                              onChange={(e) => {
                                setModuleFormData((prev) => ({
                                  ...prev,
                                  mobile_app: e.target.value
                                }))
                              }}
                              checked={
                                moduleFormData?.mobile_app === "false" ? true : false
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      {moduleFormData?.mobile_app === "true" && (
                        <>
                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label>App Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Page URL"
                                name="app_name"
                                onChange={(e) =>
                                  setModuleFormData((prev) => ({
                                    ...prev,
                                    app_name: e.target.value
                                  }))
                                }
                                value={moduleFormData?.app_name}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label>App Store ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Page URL"
                                name="app_store_id"
                                onChange={(e) =>
                                  setModuleFormData((prev) => ({
                                    ...prev,
                                    app_store_id: e.target.value
                                  }))
                                }
                                value={moduleFormData?.app_store_id}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label>App Store Locale</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Page URL"
                                name="url"
                                onChange={(e) =>
                                  setModuleFormData((prev) => ({
                                    ...prev,
                                    app_store_locale: e.target.value
                                  }))
                                }
                                value={moduleFormData?.app_store_locale}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label>Play Store ID</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Page URL"
                                name="play_store_id"
                                onChange={(e) =>
                                  setModuleFormData((prev) => ({
                                    ...prev,
                                    play_store_id: e.target.value
                                  }))
                                }
                                value={moduleFormData?.play_store_id}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Select Url Type
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={urlOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="urlType"
                        onChange={(newValue, action) => {
                          handleDropData(newValue, action.name);
                          setFormValidation({
                            ...formValidation,
                            urlType: "",
                          });
                          if (newValue?.value === "EXTR") {
                            setShowField(true)
                          }
                        }}
                        value={dropDownData?.urlType}
                      />
                      <div className="small text-danger">
                        {formValidation.urlType}
                      </div>
                    </div>
                  </div>
                  {showField && (
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>
                          Select Redirect Method
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={REDIRECTOPT}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          name="redirect"
                          onChange={(newValue, action) => {
                            handleDropData(newValue, action.name);
                          }}
                          value={dropDownData?.redirectType}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>Select Parent</label>
                      <span
                        className="mt-1"
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => {
                          setDropDownData((prev) => ({
                            ...prev,
                            parent: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                      <Select
                        options={ModuleListData(moduleList)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="parent"
                        onChange={(newValue, action) => {
                          handleDropData(newValue, action.name);
                        }}
                        isDisabled={validParent}
                        value={dropDownData?.parent}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group innergroup">
                      <label>Page URL</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Page URL"
                        name="url"
                        onChange={(e) =>
                          setModuleFormData({
                            ...moduleFormData,
                            url: e.target.value,
                          })
                        }
                        value={moduleFormData?.url}
                      />
                      {urlExist ? (
                        <div className="small text-danger">
                          *URL Already exist.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group innergroup position-relative">
                      <label>Icon Image</label>
                      {uploadData?.icon?.original_name !== undefined ? (
                        <input
                          type="text"
                          className="form-control bg-white"
                          disabled
                          value={
                            (edit === true
                              ? editModule?.icon
                              : uploadData?.icon?.original_name?.slice(0, 28)) +
                            "..."
                          }
                          placeholder="(Image, PDF Format)"
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control bg-white"
                          disabled
                          placeholder="(Image, PDF Format)"
                        />
                      )}

                      <div className="upload-btn-wrapper up-loposition">
                        <button className="uploadBtn">Browse</button>
                        <input
                          type="file"
                          title=""
                          name="icon"
                          accept="image/png, image/jpeg"
                          onChange={(e) => onProfileChange(e, "icon")}
                        />
                      </div>
                      {imgSize ? (
                        <div className="small text-danger">
                          File size should not exceed 1mb
                        </div>
                      ) : null}
                      <p>
                        Selected files
                        {edit === true &&
                          uploadData?.icon?.original_name === undefined ? (
                          <span>{editModule?.icon?.split("/").pop()}</span>
                        ) : (
                          <span>{uploadData?.icon?.original_name}</span>
                        )}
                      </p>
                      <div>
                        {edit === true ? (
                          <>
                            {editModule?.icon !== "" ? (
                              <div class="d-flex">
                                <div class="uploaded-image mr-2">
                                  <i class="">
                                    <a
                                      href={editModule?.icon}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={editModule?.icon}
                                        id="searchbar"
                                        alt=""
                                        style={{ width: "40px" }}
                                      />
                                    </a>
                                  </i>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : links ? (
                          <div class="d-flex">
                            <div class="uploaded-image mr-2">
                              <i class="">
                                <a
                                  href={links}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  <img
                                    src={links}
                                    id="searchbar"
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                </a>
                              </i>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {Object.keys(file).length !== 0 ? (
                          <div className="d-flex">{attachments123()}</div>
                        ) : link ? (
                          <>
                            <div class="d-flex">
                              <div class="uploaded-image mr-1">
                                <i class="" style={{ width: "20px" }}>
                                  <a
                                    href={link}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={link}
                                      id="searchbar"
                                      alt=""
                                      style={{ width: "40px" }}
                                    />
                                  </a>
                                </i>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group innergroup position-relative">
                      <label>Icon Hover Image</label>
                      {uploadDataIm?.hover_icon?.original_name !== undefined ? (
                        <input
                          type="text"
                          className="form-control bg-white"
                          disabled
                          value={
                            (edit === true
                              ? editModule?.hover_icon
                              : uploadDataIm?.hover_icon?.original_name?.slice(
                                0,
                                28
                              )) + "..."
                          }
                          placeholder="(Image, PDF Format)"
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control bg-white"
                          disabled
                          placeholder="(Image, PDF Format)"
                        />
                      )}
                      <div className="upload-btn-wrapper up-loposition">
                        <button className="uploadBtn">Browse</button>
                        <input
                          type="file"
                          title=""
                          name="hover_icon"
                          accept="image/png, image/jpeg"
                          onChange={(e) => onProfileChange(e, "hover_icon")}
                        />
                      </div>
                      {imgSizeNew ? (
                        <div className="small text-danger">
                          File Size should not exceed 1mb
                        </div>
                      ) : null}
                      <p>
                        Selected files{" "}
                        {edit === true &&
                          uploadData?.hover_icon?.original_name === undefined ? (
                          <span>{editModule?.hover_icon.split("/").pop()}</span>
                        ) : (
                          <span>{uploadDataIm?.hover_icon?.original_name}</span>
                        )}
                      </p>
                      <div>
                        {edit === true ? (
                          <>
                            {editModule?.hover_icon !== "" ? (
                              <div class="d-flex">
                                <div class="uploaded-image mr-2">
                                  <i class="">
                                    <a
                                      href={editModule?.hover_icon}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={editModule?.hover_icon}
                                        id="searchbar"
                                        alt=""
                                        style={{ width: "40px" }}
                                      />
                                    </a>
                                  </i>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : links ? (
                          <div class="d-flex">
                            <div class="uploaded-image mr-2">
                              <i class="">
                                <a
                                  href={links}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  <img
                                    src={links}
                                    id="searchbar"
                                    alt=""
                                    style={{ width: "40px" }}
                                  />
                                </a>
                              </i>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {Object.keys(filess).length !== 0 ? (
                          <div className="d-flex">{myattachments123()}</div>
                        ) : links ? (
                          <>
                            <div class="d-flex">
                              <div class="uploaded-image mr-1">
                                <i class="" style={{ width: "20px" }}>
                                  <a
                                    href={links}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={links}
                                      id="searchbar"
                                      alt=""
                                      style={{ width: "40px" }}
                                    />
                                  </a>
                                </i>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Sequence No.
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Sequence No."
                        name="sequence_no"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => {
                          setModuleFormData({
                            ...moduleFormData,
                            sequence_no: e.target.value,
                          });
                          checkSequenceExist(e);
                          setFormValidation({
                            ...formValidation,
                            sequence_no: "",
                          });
                        }}
                        value={moduleFormData?.sequence_no}
                      />
                      <div className="small text-danger">
                        {formValidation.sequence_no}
                      </div>
                      {sequenceExist ? (
                        <div className="small text-danger">
                          *Module with this sequence number exist. <br />
                          (max sequence number - {maxSequenceNum})
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label className="d-block">Status</label>
                      <label
                        className="logCheck d-inline-block mr-4"
                        htmlFor="active"
                      >
                        Active
                        <input
                          type="radio"
                          name="is_active"
                          id="active"
                          value={true}
                          checked={
                            moduleFormData?.is_active == true ? true : false
                          }
                          onChange={(e) => {
                            DataPush(e);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        className="logCheck d-inline-block"
                        htmlFor="Inactive"
                      >
                        Inactive
                        <input
                          type="radio"
                          name="is_active"
                          id="Inactive"
                          value={false}
                          onChange={(e) => {
                            DataPush(e);
                          }}
                          checked={
                            moduleFormData?.is_active == true ? false : true
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {isModuleExist && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>
              &nbsp;This Entry Already Exists.
            </Alert>
          )}
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    dataBlank();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => handleValidateModule()}
                >
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default AddModule;
