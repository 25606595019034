import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import SourceApproverForm from "./sourceApproverForm";
import {
  deleteApprover,
  getApprovalMastList,
  getApprovalMastListExport,
  getPoOrg,
  getSourcelistPlant,
} from "../../service/source-list";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { getPlantList, getUserRmList } from "../../service/jv_service";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function SourceApprover() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();
  const userData = useSelector(selectUserData)

  const options = [
    { value: "Capex", label: "Capex" },
    { value: "Engineering", label: "Engineering" },
    { value: "RM/PM", label: "RM/PM" },
  ];
  const [plantlist, setPlantList] = useState([]);
  const [poOrgList, setPoOrgList] = useState([]);
  const [userlist, setuserlist] = useState([]);

  const [approvalMast, setApprovalMast] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [edit, setedit] = useState(false);
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [editData, setEditData] = useState({});
  const [filterdata, setfilterData] = useState({
    plant_id: { value: 0 },
    po_id: { value: 0 },
    approver1_id: { value: 0 },
    approver2_id: { value: 0 },
    additional_approver_id: { value: 0 },
    requisitioner_id: { value: 0 },
    category: { value: "" },
  });
  const resetFilterData = () =>
    setfilterData({
      plant_id: { value: 0 },
      po_id: { value: 0 },
      approver1_id: { value: 0 },
      approver2_id: { value: 0 },
      additional_approver_id: { value: 0 },
      requisitioner_id: { value: 0 },
      category: { value: "" },
    });

  const getApprovalList = (
    search,
    pageno,
    page_size,
    sort_by,
    plantcode,
    pocode,
    approver1_id,
    approver2_id,
    additional_approver_id,
    requisitioner_id,
    category
  ) => {
    dispatch(setDisplayLoader("Display"));
    getApprovalMastList(
      search,
      pageno,
      page_size,
      sort_by,
      plantcode,
      pocode,
      approver1_id,
      approver2_id,
      additional_approver_id,
      requisitioner_id,
      category
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(response?.data?.dataList?.result);
        setApprovalMast(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getApprovalListExport = (
    search,
    pageno,
    page_size,
    sort_by,
    plantcode,
    pocode,
    approver1_id,
    approver2_id,
    additional_approver_id,
    category
  ) => {
    getApprovalMastListExport(
      search,
      pageno,
      page_size,
      sort_by,
      plantcode,
      pocode,
      approver1_id,
      approver2_id,
      additional_approver_id,
      category
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SourceList.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const plantList = (pag_id) => {
    getSourcelistPlant(pag_id)
      .then((response) => {
        console.log(response?.data?.dataList?.result);

        setPlantList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getPoList = () => {
    getPoOrg("-id", false)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code + "(" + x?.desc + ")" };
          plant.push(data);
        });
        setPoOrgList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getApprovalList(
      search,
      activePage,
      pageSizeNo,
      "-id",
      0,
      0,
      0,
      0,
      0,
      0,
      ""
    );
  };
  useEffect(() => {
    getApprovalList("", currentPage, pageSizeNo, "-id", 0, 0, 0, 0, 0, 0, "");
    getPoList();
    // plantList();
    getUserList();
  }, [pageSizeNo]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getApprovalList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        0,
        0,
        0,
        0,
        0,
        0,
        ""
      );

      setSearch(e.target.value);
    }
  };
  const handleChange = (event, name) => {
    console.log(event, name);
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "plant_id":
        setfilterData((prev) => ({
          ...prev,
          plant_id: event,
        }));
        break;
      case "po_id":
        setfilterData((prev) => ({
          ...prev,
          po_id: event,
        }));
        plantList(event.value);
        break;
      case "approver1_id":
        setfilterData((prev) => ({
          ...prev,
          approver1_id: event,
        }));
        break;
      case "requisitioner_id":
        setfilterData((prev) => ({
          ...prev,
          requisitioner_id: event,
        }));
        break;
      case "approver2_id":
        setfilterData((prev) => ({
          ...prev,
          approver2_id: event,
        }));
        break;
      case "additional_approver_id":
        setfilterData((prev) => ({
          ...prev,
          additional_approver_id: event,
        }));
        break;
      case "category":
        setfilterData((prev) => ({
          ...prev,
          category: event,
        }));
        break;
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    getApprovalList(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      filterdata?.plant_id?.value,
      filterdata?.po_id?.value,
      filterdata?.approver1_id?.value,
      filterdata?.approver2_id?.value,
      filterdata?.additional_approver_id?.value,
      filterdata?.requisitioner_id?.value,
      filterdata?.category?.value
    );
    setPlantList([]);

    filterclose();
  };
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getApprovalList("", currentPage, pageSizeNo, "-id", 0, 0, 0, 0, 0, 0, "");
  };
  const DeleteApprover = (approverid) => {
    console.log(approverid);
    let data = { id: approverid };
    deleteApprover(data)
      .then((response) => {
        getApprovalList(
          "",
          currentPage,
          pageSizeNo,
          "-id",
          0,
          0,
          0,
          0,
          0,
          0,
          ""
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const deletapprover = (id) => {
    confirmAlert({
      title: "Delete Approver ",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const exports = () => {
    getApprovalListExport(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      filterdata?.plant_id?.value,
      filterdata?.po_id?.value,
      filterdata?.approver1_id?.value,
      filterdata?.approver2_id?.value,
      filterdata?.additional_approver_id?.value,
      filterdata?.requisitioner_id?.value,

      filterdata?.category?.value
    );
  };
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/source-list">Source List</Link>
            </li>
            <li class="active">
              <Link to="/source-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Assign Approver</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Assign Approver</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Req. Name & ID,
                            <br />
                            Doc. Number,
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            // disabled={cityList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() => exports()}
                          >
                            <i class="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A ? (
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          setedit(false);
                          addnewopen();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Approver
                      </button>
                    ) : (
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          setedit(false);
                          addnewopen();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Approver
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    {finalUrlValue?.E || finalUrlValue?.R ? (
                      <th>Actions</th>
                    ) : (
                      ""
                    )}
                    <th>Req. Name & ID</th>
                    <th>Category</th>
                    <th>Plant</th>
                    <th>Pur. Org</th>
                    <th>App. Name & ID 1</th>
                    <th>App. Name & ID 2</th>
                    <th>RM/PM Additional Approver</th>
                  </tr>
                </thead>
                <tbody>
                  {approvalMast.length != 0 ? (
                    approvalMast.map((x) => (
                      <tr>
                        {finalUrlValue?.E || finalUrlValue?.R ? (
                          <td>
                            {finalUrlValue?.E ? (
                              <button
                                onClick={(e) => {
                                  setEditData(x);
                                  setedit(true);
                                  addnewopen();
                                }}
                                class="btn btn-blanktd text-primary ml-2 f-12"
                              >
                                <i class="far fa-edit"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {finalUrlValue?.R ? (
                              <button
                                onClick={() => deletapprover(x?.id)}
                                class="btn btn-blanktd text-danger ml-2 f-12"
                                type="button"
                              >
                                <i class="far fa-trash-alt text-danger"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          {x?.requester_name}
                          <br />({x?.requester_username})
                        </td>
                        <td>{x?.category}</td>
                        <td>
                          <Tooltip
                            title={x?.plant_id.map((x) => x.label)}
                            position="bottom"
                          >
                            {x?.plant_id.map((x) => x.label)}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            title={x?.purchase_org_id.map((x) => x.label)}
                            position="bottom"
                          >
                            {x?.purchase_org_id.map((x) => x.label)}
                          </Tooltip>
                        </td>
                        <td>
                          {x?.approver1_name}
                          <br />({x?.approver1_username})
                        </td>
                        <td>
                          {x?.approver2_name}
                          <br />({x?.approver2_username})
                        </td>
                        <td>
                          {x?.additional_approver_username !== null &&
                            x?.category === "RM/PM" ? (
                            <>
                              {x?.additional_approver_name}
                              <br />({x?.additional_approver_username})
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onClick={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1)
                    }}
                  >
                    <option value={10} label={10}>10</option>
                    <option value={20} label={20}>20</option>
                    <option value={30} label={30}>30</option>
                    <option value={100} label={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Purchase Org.</label>
            <Select
              options={poOrgList}
              className="basic-multi-select"
              classNamePrefix="select"
              name="po_id"
              value={filterdata?.po_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Plant</label>
            <Select
              options={plantlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="plant_id"
              value={filterdata?.plant_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Requisitioner</label>
            <Select
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="requisitioner_id"
              value={filterdata?.requisitioner_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Approver Id 1</label>
            <Select
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="approver1_id"
              value={filterdata?.approver1_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Approver Id 2</label>
            <Select
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="approver2_id"
              value={filterdata?.approver2_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>RM/PM Additional Approver</label>
            <Select
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="additional_approver_id"
              value={filterdata?.additional_approver_id}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Category</label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              name="category"
              value={filterdata?.category}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {addnewshow && (
        <SourceApproverForm
          show={addnewshow}
          edit={edit}
          getList={() =>
            getApprovalList(
              "",
              currentPage,
              pageSizeNo,
              "-id",
              0,
              0,
              0,
              0,
              0,
              0,
              ""
            )
          }
          close={addnewclose}
          open={addnewopen}
          editData={editData}
        ></SourceApproverForm>
      )}
    </>
  );
}

export default SourceApprover;
