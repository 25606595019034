const { REACT_APP_API_AUTH_URL } = process.env;
const { REACT_APP_API_AUTH_URL_SURVEY } = process.env;

const BASE_URL = REACT_APP_API_AUTH_URL;
const BASE_URL_SURVEY = REACT_APP_API_AUTH_URL_SURVEY;
const AUTH_URL = `${BASE_URL}`;
const AUTH_URL_SURVEY = `${BASE_URL_SURVEY}`;

console.log("auth: ", AUTH_URL);

function getUrls() {
  const ENV_URLS = {
    dev: {
      CUSTOMER_URL: "http://182.73.254.2/c1",
      AUTH_URL: "http://182.73.254.2/ensurea1",
      // AUTH_URL: 'http://172.16.26.27:6088/ensurea1',
      // EMPLOYEE_URL: 'http://172.16.26.27:6090/ensurea2',  // S Sir
      // EMPLOYEE_URL: 'http://172.16.34.20:6090/ensurea2',  // S Sir
      EMPLOYEE_URL: "http://182.73.254.2/ensurea2",
      CRAWLER_URL: "http://182.73.254.2/w1",
      UPLOAD_URL: "http://182.73.254.2/v101",
      // UPLOAD_URL: 'http://172.16.25.49:5556/v101',   // Y Sir
    },
    stage: {
      CUSTOMER_URL: "http://182.73.254.2/c1",
      AUTH_URL: "http://182.73.254.2/a1",
      EMPLOYEE_URL: "http://182.73.254.2/a2",
      CRAWLER_URL: "http://182.73.254.2/w1",
      UPLOAD_URL: "http://182.73.254.2/v100",
    },
    prod: {
      CUSTOMER_URL: "http://54.251.74.59/a1",
      AUTH_URL: "http://54.251.74.59/a1",
      EMPLOYEE_URL: "http://54.251.74.59/a2",
      CRAWLER_URL: "http://54.251.74.59/w1",
      UPLOAD_URL: "http://54.251.74.59/v100",
    },
  };

  const HOST_TYPE = "dev";
  const ENV = "development";
  let envUrls;
  if (HOST_TYPE === "dev" || HOST_TYPE === "stage") {
    // console.log(process.env);
  }
  if (HOST_TYPE === "dev" || ENV === "development") {
    envUrls = ENV_URLS.dev;
  } else if (HOST_TYPE === "stage") {
    envUrls = ENV_URLS.stage;
  } else if (HOST_TYPE === "prod" || ENV === "production") {
    envUrls = ENV_URLS.prod;
  } else {
    envUrls = ENV_URLS.prod;
  }
  return envUrls;
}

const URLS = getUrls();

export const CUSTOMER_BASE_URL = URLS.CUSTOMER_URL;
export const AUTH_BASE_URL = AUTH_URL;
export const AUTH_BASE_URL_SURVEY = AUTH_URL_SURVEY;
export const EMPLOYEE_BASE_URL = URLS.EMPLOYEE_URL;
export const JOB_ORDER_DESC_BASE_URL = URLS.CUSTOMER_URL;
export const DASHBOARD_BASE_URL = URLS.CUSTOMER_URL;
export const FILE_UPLOAD_BASE_URL = URLS.UPLOAD_URL;
export const CRAWLER_BASE_URL = URLS.CRAWLER_URL;
