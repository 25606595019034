/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import * as BrandingCategoryService from "../../service/brandingCategory";
import * as moment from "moment";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setTracker,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
var id = "";

function Branding() {
  const [category, setCategory] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [show, setshow] = useState(false);
  const [link, setlink] = useState([]);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const [trackerData, setTrackerData] = useState({
    permission_id: 0,
    transaction_id: 0,
    attachment_id: 0,
  });

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));

    BrandingCategoryService.getBrandingByCategory()
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setPolicy(response?.data);
        let categories = [];
        response?.data?.map((x) => {
          categories.push(x?.catgory);
        });
        setCategory(categories);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, []);
  const filterPolicy = (id) => {
    let data = JSON.parse(JSON.stringify(policy));
    let index = "";
    data?.map((x) => {
      if (Number(x?.catgory.id) == Number(id)) {
        document.getElementById(x?.catgory.id).classList.add("active");
        index = data.indexOf(x);
      } else {
        let element = document.getElementById(x?.catgory.id);
        element.className = "";
      }
    });

    if (index > -1) {
      let data2 = data.splice(index, 1);
      data.unshift(data2[0]);
    }
    setPolicy(data);
  };

  useEffect(() => {
    dispatch(setTracker(trackerData));
  }, [trackerData]);

  const showImg = (data, indexes) => {
    console.log(data);
    let link = data;
    setlink(link);
    setshow(true);
    setIndex(indexes);
  };
  const jai = () => {};
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            {category?.map((x) => (
              <li
                className={
                  Number(x.id) == Number(category[0].id) ? "active" : ""
                }
                onClick={() => filterPolicy(x.id)}
                id={x.id}
              >
                {x.name}
              </li>
            ))}
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              {policy?.map((x) => (
                <div className="row mb-3">
                  <div class="col-md-12">
                    <h3 class="policyhead">{x?.catgory.name}</h3>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      {x?.policies.length != 0 &&
                        x?.policies?.map((j) => (
                          <>
                            <div class="col-md-4">
                              <div class="policy-boxhead">
                                <h4>{j?.fields?.title}</h4>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-block d-sm-none">
                                    {j?.fields?.attachment?.map((at) => (
                                      <>
                                        <a
                                          href={at?.attachment_path}
                                          target="_blank"
                                          class="d-block"
                                        >
                                          <img
                                            style={{ cursor: "pointer" }}
                                            // onClick={() => {
                                            //   showImg(j?.fields?.attachment);
                                            //   setTrackerData({
                                            //     ...trackerData,
                                            //     transaction_id: j.pk,
                                            //     attachment_id: at.id,
                                            //     permission_id:
                                            //       finalUrlValue?.V?.id,
                                            //   });
                                            // }}
                                            src={
                                              at?.attachment_path?.includes(
                                                ".jpg"
                                              ) ||
                                              at?.attachment_path?.includes(
                                                ".png"
                                              ) ||
                                              at?.attachment_path?.includes(
                                                ".jpeg"
                                              ) ||
                                              at?.attachment_path?.includes(
                                                ".jfif"
                                              ) ||
                                              at?.attachment_path?.includes(
                                                ".gif"
                                              )
                                                ? "images/policy-icon/img.png"
                                                : at?.attachment_path?.includes(
                                                    ".mp4"
                                                  ) ||
                                                  at?.attachment_path?.includes(
                                                    ".m4v"
                                                  ) ||
                                                  at?.attachment_path?.includes(
                                                    ".mov"
                                                  ) ||
                                                  at?.attachment_path?.includes(
                                                    ".mkv"
                                                  )
                                                ? "images/policy-icon/video.png"
                                                : at?.attachment_path?.includes(
                                                    ".pdf"
                                                  )
                                                ? "images/policy-icon/pdf.png"
                                                : at?.attachment_path?.includes(
                                                    ".pptx"
                                                  )
                                                ? "images/policy-icon/ppt.png"
                                                : at?.attachment_path?.includes(
                                                    ".docx"
                                                  )
                                                ? "images/policy-icon/docs.png"
                                                : at?.attachment_path?.includes(
                                                    ".doc"
                                                  )
                                                ? "images/policy-icon/docs.png"
                                                : at?.attachment_path?.includes(
                                                    ".xls"
                                                  )
                                                ? "images/policy-icon/xlsx.png"
                                                : at?.attachment_path?.includes(
                                                    ".xlsx"
                                                  )
                                                ? "images/policy-icon/xlsx.png"
                                                : null
                                            }
                                          />
                                        </a>
                                      </>
                                    ))}
                                    {/* <img src="images/policy-icon/video.png" />
                                    "images/policy-icon/pdf.png"
                                    <img src="images/policy-icon/img.png" /> */}
                                  </div>
                                  <div class="d-none d-sm-block">
                                    {j?.fields?.attachment?.map((at) => (
                                      <>
                                        <img
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            showImg(j?.fields?.attachment);
                                            setTrackerData({
                                              ...trackerData,
                                              transaction_id: j.pk,
                                              attachment_id: at.id,
                                              permission_id:
                                                finalUrlValue?.V?.id,
                                            });
                                          }}
                                          src={
                                            at?.attachment_path?.includes(
                                              ".jpg"
                                            ) ||
                                            at?.attachment_path?.includes(
                                              ".png"
                                            ) ||
                                            at?.attachment_path?.includes(
                                              ".jpeg"
                                            ) ||
                                            at?.attachment_path?.includes(
                                              ".jfif"
                                            ) ||
                                            at?.attachment_path?.includes(
                                              ".gif"
                                            )
                                              ? "images/policy-icon/img.png"
                                              : at?.attachment_path?.includes(
                                                  ".mp4"
                                                ) ||
                                                at?.attachment_path?.includes(
                                                  ".m4v"
                                                ) ||
                                                at?.attachment_path?.includes(
                                                  ".mov"
                                                ) ||
                                                at?.attachment_path?.includes(
                                                  ".mkv"
                                                )
                                              ? "images/policy-icon/video.png"
                                              : at?.attachment_path?.includes(
                                                  ".pdf"
                                                )
                                              ? "images/policy-icon/pdf.png"
                                              : at?.attachment_path?.includes(
                                                  ".pptx"
                                                )
                                              ? "images/policy-icon/ppt.png"
                                              : at?.attachment_path?.includes(
                                                  ".docx"
                                                )
                                              ? "images/policy-icon/docs.png"
                                              : at?.attachment_path?.includes(
                                                  ".doc"
                                                )
                                              ? "images/policy-icon/docs.png"
                                              : at?.attachment_path?.includes(
                                                  ".xls"
                                                )
                                              ? "images/policy-icon/xlsx.png"
                                              : at?.attachment_path?.includes(
                                                  ".xlsx"
                                                )
                                              ? "images/policy-icon/xlsx.png"
                                              : null
                                          }
                                        />
                                      </>
                                    ))}
                                    {/* <img src="images/policy-icon/video.png" />
                                    "images/policy-icon/pdf.png"
                                    <img src="images/policy-icon/img.png" /> */}
                                  </div>
                                  <h5>
                                    {" "}
                                    {moment(j?.fields?.updated_at).format(
                                      "Do MMM YYYY"
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter withouthead"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center brandingcrousel">
            <div class="col-md-11">
              <div class="row news-detail news-detail-marq text-center">
                <div class="col-md-12">
                  <h2></h2>
                </div>
                <div class="col-md-12 dashNews"></div>
                <div class="col-md-12">
                  <div class="row justify-content-center">
                    <div class="col-11">
                      <Carousel selectedItem={index}>
                        {link?.map(
                          (x) =>
                            // <>
                            x?.attachment_path?.includes(".mp4") ||
                            x?.attachment_path?.includes(".m4v") ||
                            x?.attachment_path?.includes(".mkv") ||
                            x?.attachment_path?.includes(".mov") ? (
                              <video
                                width="100%"
                                height="100%"
                                style={{ marginLeft: "0%" }}
                                controls
                              >
                                <source
                                  src={x?.attachment_path}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            ) : x?.attachment_path?.includes(".jpg") ||
                              x?.attachment_path?.includes(".jpeg") ||
                              x?.attachment_path?.includes(".png") ||
                              x?.attachment_path?.includes(".jfif") ||
                              x?.attachment_path?.includes(".gif") ? (
                              <img
                                style={{ width: "650px", height: "100%" }}
                                src={x?.attachment_path}
                              ></img>
                            ) : x?.attachment_path?.includes(".pdf") ? (
                              <iframe
                                width="800"
                                height="450"
                                src={x?.attachment_path}
                              ></iframe>
                            ) : x?.attachment_path?.includes(".pptx") ? (
                              <iframe
                                width="400"
                                height="400"
                                src={
                                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                  x?.attachment_path.split("?")[0]
                                }
                              ></iframe>
                            ) : x?.attachment_path?.includes(".docx") ? (
                              <iframe
                                width="400"
                                height="400"
                                src={
                                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                  x?.attachment_path.split("?")[0]
                                }
                              ></iframe>
                            ) : x?.attachment_path?.includes(".doc") ? (
                              <iframe
                                width="400"
                                height="400"
                                src={
                                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                  x?.attachment_path.split("?")[0]
                                }
                              ></iframe>
                            ) : x?.attachment_path?.includes(".xls") ? (
                              <iframe
                                width="400"
                                height="400"
                                src={
                                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                  x?.attachment_path.split("?")[0]
                                }
                              ></iframe>
                            ) : x?.attachment_path?.includes(".xlsx") ? (
                              <iframe
                                width="400"
                                height="400"
                                src={
                                  "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                  x?.attachment_path.split("?")[0]
                                }
                              ></iframe>
                            ) : null
                          // </>
                        )}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Branding;
