import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const AlertBox = (props) => {
  const { confirmModal, onConfirmClose } = props;

  return (
    <>
      <Modal
        show={confirmModal}
        // onHide={onConfirmClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
       <Modal.Header closeButton onClick={() => onConfirmClose()}>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label style={{ textAlign: "center" }}>
                        1. Employee should obtain original Hotel invoice &
                        correct company GST number should be mentioned on hotel
                        invoice of that respective state. <br />
                        2. Hotel invoice should obtain in name of Company,
                        separate invoice must be obtained for room charges. No
                        other changes should be clubbed in the invoice of room
                        charges issued by the Hotel.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                onConfirmClose();
              }}
            >
              OK
            </button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
export default AlertBox;
