import React, { useState, useEffect } from "react";
import * as surveyService from "../../service/Survey";
import ReactPaginate from "react-paginate";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";

const SelectTemp = (props) => {
  const { setTabStatus, tabStatus, setTemplateData } = props;
  const [surveyList, setSurveyList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const userData = useSelector(selectUserData);

  const handleKey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let curr_user = userData.id;
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&created_by=" +
      curr_user;

    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (
      assessment_type_id !== "" &&
      assessment_type_id !== undefined &&
      assessment_type_id !== null
    ) {
      queryParm = queryParm + "&assessment_type_id=" + assessment_type_id;
    }

    if (
      category_id !== "" &&
      category_id !== undefined &&
      category_id !== null
    ) {
      queryParm = queryParm + "&category_id=" + category_id;
    }

    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }

    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    return queryParm;
  };

  const getSurvey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    surveyService
      .getSurvey(
        handleKey(
          search,
          assessment_type_id,
          category_id,
          status,
          start_date,
          end_date,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        setSurveyList(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSurvey("", "", "", "", "", "", activePage, pageSizeNo, "-id", true);
  };

  // const editSurveyDetails = (editData) => {
  //   navigate("/addsurvey", {
  //     state: { edit: editData, flag: "true" },
  //   });
  //   setEditMode(true);
  //   setSurveyData(editData);
  // };

  useEffect(() => {
    if (tabStatus === "Personal") {
      getSurvey("", "", "", "", "", "", currentPage, pageSizeNo, "-id", true);
    }
  }, [tabStatus]);

  return (
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="">
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Organization</th>
                  <th>Type</th>
                  {/* <th>Category</th> */}
                  <th>Template Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Responses</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {surveyList?.length ? (
                  surveyList?.map((data, index) => (
                    <>
                      <tr key={`surv${index}`}>
                        <td>{index + 1}</td>
                        <td class="text-theme fw-bold">
                          {data?.organisation_name}
                        </td>
                        <td>{data?.assessment_type_name}</td>
                        {/* <td>{data?.category_name}</td> */}
                        <td>{data?.template_name}</td>
                        <td>{data?.start_date}</td>
                        <td>{data?.end_date}</td>
                        {/* <td>{data?.response}</td> */}
                        <td>
                          <span
                            class="badge badge-secondary cursor-pointer"
                            // onClick={() => navigate("/survey-result")}
                          >
                            {data?.responses}
                          </span>
                        </td>
                        <td>
                          <button
                            class="btn btn-blanktd text-success w-auto p-0"
                            onClick={() => {
                              setTemplateData(data);
                              setTabStatus("Addressess");
                            }}
                          >
                            Copy Template
                          </button>
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={13}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {surveyList?.length === 0 ? (
            ""
          ) : (
            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      pageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectTemp;
