import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";  
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab  } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"

function TravelProcessedTa() { 

    const navigate = useNavigate();
 
    const options = [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' }
    ]
 

    const [show, filtersetShow] = useState(false); 
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" }); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [travelBills, setTravelBills] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [selectedEmployee, set_selectedEmployee] = useState({
        empObj:""
    });
    
    const [usersDropdown, setUsersDropdown] = useState([]);

    const [filterdata, setfilterData] = useState({
        user_id: "",
        trip_from: "",
        trip_to: "",
        from_date: "",
        to_date: "",
        
      });

    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);

          getTravelProcessedTa(searchStr,
            activePage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
      };



     

    const calcMapData=(map_data,dat_type)=>{
        let calculated=0
        switch(dat_type){
            case "Self_ticket":
                map_data?.map((sub_data,idx)=>
                    {
                        if(sub_data?.paid_by == "Self") {
                            calculated+= sub_data?.taxable_amount
                        }
                    }
                )

                break;
            case "Company_ticket":
                map_data?.map((sub_data,idx)=>
                    {
                        if(sub_data?.paid_by == "Company") {
                            calculated+= sub_data?.taxable_amount
                        }
                    }
                )
                break;
            case "hotel":
                map_data?.map((sub_data,idx)=>
                    {
                        
                            calculated+= sub_data?.tax_bill_amount
                        
                    }
                )
                break;
            case "conveyance":
                map_data?.map((sub_data,idx)=>
                    {
                        
                            calculated+= sub_data?.amount
                        
                    }
                )
                break;
            default: console.log("invalid");
        }
        return calculated;

    }



      

    useEffect(()=>{
        getUsersList()
        getTravelProcessedTa(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
    },[pageSizeNo])

    const handleKey = (search, page_no, page_size, sort_by, paginate, status, 
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date) => {
        let queryParm =
          "page_no=" +
          page_no +
          "&page_size=" +
          page_size +
          "&paginate=" +
          paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
          queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
          queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
          queryParm = queryParm + "&status=" + status;
        }
    
    
        if (user_id !== "" && user_id !== undefined && user_id !== null) {
          queryParm = queryParm + "&user_id=" + user_id;
        }
        if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
          queryParm = queryParm + "&trip_from=" + trip_from;
        }
        if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
          queryParm = queryParm + "&trip_to=" + trip_to;
        }
        if (from_date !== "" && from_date !== undefined && from_date !== null) {
          queryParm = queryParm + "&from_date=" + from_date;
        }
        if (to_date !== "" && to_date !== undefined && to_date !== null) {
          queryParm = queryParm + "&to_date=" + to_date;
        }
        
    
        return queryParm;
      };



      
  const handleSearch = (e) => {
    
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      
    getTravelProcessedTa(
        e.target.value,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
            "","","","",""
        )

    
    setSearchStr(e.target.value);
    }
  };


  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };



  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    getTravelProcessedTa(searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
    filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
        )
    
    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   filterdata?.user_id,
    //   filterdata?.company,
    //   filterdata?.trip_number,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   true
      
    //   )
  
    filterclose();
  };
  
  
  const clearfilter = () => {
    filterclose();
    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //    "","","","","",false
    // );
    getTravelProcessedTa(searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
         "","","","",""
        )
    setfilterData({
      user_id: "",
      from_date: "",
      to_date: "",
      trip_from:"",
      trip_to:"",
    });
    
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
      
    });
  };


  const getTravelProcessedTa = (
    search, page_no, page_size, sort_by, paginate, status, 
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
) =>{
    TravelListingAPI.getTravelProcessedTa(
        handleKey(search, page_no, page_size, sort_by, paginate, status, 
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date)
    ).then((response)=>{
        setTravelBills(response?.data?.dataList?.result)
          setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
    })
}




const getTravelProcessedTaExport = (
    search, page_no, page_size, sort_by, paginate, status, 
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
) =>{
    TravelListingAPI.getTravelProcessedTaExport(
        "is_export=true"
    ).then((response)=>{

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Processed_Ta_Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // setTravelBills(response?.data?.dataList?.result)
        //   setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
    })
}

 
 
    return (
        <>
           <div class="container-fluid">
                        <div class="row">
                             {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li class="active"><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

                            <div class="col-md-12">
                                <div class="content-wrapper-inner content-wrapper-inner2">
                                    <div class="innerheadsec">
                                        <div className="row">
                                            <div class="col-md-12">
                                                <h4 class="inner-page-title">Processed TA Report</h4>
                                            </div> 
                                            <div class="col-md-12">
                                                <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-lg-4">
                                                <div class="input-group table-search">
                                                    <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                                    <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Search..." 
                                                    onChange={(e) => {
                                                        handleSearch(e);
                                                      }}
                                                    /> 
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="inline-spacing btn-betweenspaing">
                                                    <Dropdown show>
                                                        <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                            <img src="images/export.png" alt="" class="mr-2" /> Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#">
                                                            <button
                                                            type="button"
                                                            // disabled={surveyList.length !== 0 ? false : true}
                                                            style={{ border: 0, background: "none" }}
                                                            onClick={() =>
                                                                
                                                                getTravelProcessedTaExport(searchStr,
                                                                    1,pageSizeNo,sortData.sort_by,true,"","","","","","")
                                                            }
                                                            >
                                                                <i class="far fa-file-excel"></i>Excel
                                                            </button>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                     
                                        <div class="table-responsive">
                                            <table class="table table-striped table-bordered tablecured"> 
                                                <thead>
                                                    <tr>
                                                        <th>Sr.No</th>
                                                        <th>Employee ID</th>
                                                        <th>Emp Name</th>
                                                        <th>Emp Vendor Code</th>
                                                        <th>Reporting</th>
                                                        	<th>hod_name</th>
                                                        	<th>Zone</th>
                                                        	<th>Trip Number</th>
                                                        	<th>Trip Start Date</th>
                                                        	<th>Trip End Date</th>
                                                        	<th>Applied Date</th>
                                                        	<th>User Delay</th>
                                                        	<th>Claim Amt.</th>
                                                        	<th>Approved Amt</th>
                                                        	<th>Ded./Add.</th>
                                                        	<th>penalty	Doc.</th>
                                                            <th>Rec. On</th>
                                                        	<th>Approved On</th>
                                                        	<th>Status Message</th>
                                                        	<th>Check By</th>
                                                        	<th>Delay Days</th>

                                                    </tr>
                                                </thead>

                                                <tbody> 
                                                    {travelBills?.length > 0 ?
                                                    travelBills?.map((data,idx)=>{
                                                        return(
                                                    <tr style={data?.is_exception 
                                                                ?   {backgroundColor:"orange"}
                                                                :   data?.chat_status
                                                                        ?{backgroundColor:"yellow"}
                                                                        :   {}
                                                                }>
                                                        <td>{idx+1}.</td>
                                                        <td class="">{data?.user_code}</td>
                                                        <td>{data?.username}</td>
                                                        <td>{data?.user_vendor_code}</td>
                                                        <td>{data["reporting_user_name"]}</td>
                                                        <td>{data["hod_name"]}</td>
                                                        <td>{data?.zone}</td> 
                                                        <td>{data?.sap_trip_number}</td> 
                                                        <td>{moment.parseZone(data["Trip Start Date"]).format("DD-MM-YYYY")}</td>
                                                        <td>{moment.parseZone(data["Trip End Date"]).format("DD-MM-YYYY")}</td>
                                                        <td>{moment.parseZone(data["created_at"]).format("DD-MM-YYYY")}</td>

                                                        <td class="">{data["User Delay"]}</td>
                                                        <td>{data["Claim Amt."]}</td>
                                                        <td>{data["Approved Amt"]}</td>
                                                        <td>{data["Ded./Add."]}</td>
                                                        <td>{data["penalty"]}</td>
                                                        <td>-</td> 
                                                        <td>{moment.parseZone(data?.approval_date).format("DD-MM-YYYY")}</td> 
                                                        <td>{data?.current_status}</td>
                                                        <td>-</td>
                                                        <td>{data["Delay Days"]}</td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={12}>No Records Found...</td>
                                                </tr>
                                                }
                                                </tbody>

                                            </table>

                                        </div>

                                        <div class="row">
                                            <div class="col-sm-5">
                                                <div class="sortinglist">
                                                    Show
                                                    <select 
                                                    class="form-control"
                                                    onChange={(e) => {
                                                        setPageSizeNo(e.target.value);
                                                        setCurrentPage(1);
                                                      }}
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-7">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={2}
                                                onPageChange={handlePageClick}
                                                disableInitialCallback={true}
                                                containerClassName={"pagination justify-content-left"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                />
                                            </div>
                                        </div>
                                    

                                </div>
                            </div>
                        </div>
           </div>

 

           <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                       

                    <div class="form-group innergroup">
                        <label>Employee ID  <span class="text-danger"></span></label>
                        <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                                ...filterdata,
                                user_id: e,
                              }) 
                                  
                        }
                        value={filterdata?.user_id}
                        />
                        
                    </div>

                    {/* <div class="form-group innergroup">
                        <label>Trip From <span class="text-danger"></span></label>
                        <input type="text" 
                        placeholder="Enter Start Location" class="form-control" 
                        onChange={e=>setfilterData({
                            ...filterdata,
                            trip_from:e.currentTarget.value
                        })}
                        value={filterdata?.trip_from}

                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip To <span class="text-danger"></span></label>
                        <input type="text" 
                        placeholder="Enter End Location" class="form-control" 
                        onChange={e=>setfilterData({
                            ...filterdata,
                            trip_to:e.currentTarget.value
                        })}
                        value={filterdata?.trip_to}
                        />
                    </div> */}



                    <div class="form-group innergroup">
                        <label>Trip Start Date  <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        from_date:e.currentTarget.value
                        })}
                        value={filterdata.from_date}/>
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip End Date <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        to_date:e.currentTarget.value
                        })}
                        value={filterdata.to_date}/>
                    </div>

                    {/* <div class="form-group innergroup">
                        <label>Search By <span class="text-danger"></span></label>
                        <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>T</option>
                            <option>P</option>
                        </select>
                    </div> */}
                    
                </Modal.Body>
                <Modal.Footer>
                    <button 
                    class="btn btn-outline-danger"
                     onClick={() => clearfilter()}
                    //  onClick={filterclose}
                    >
                        Cancel</button>
                    <button 
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => mainDataFilter()}
                    >Apply</button>
                </Modal.Footer>
            </Modal>

            

        </>
    );
}

export default TravelProcessedTa;