const removeSplCharsForNumbers = (str) => {
    return str.replace(/[^0-9.]/gi, '');
}

const getOnlyNumber = (oldVal, _val, maxVal = 1000000, fixedValue = 2) => {
    let val = _val;

    if (isNaN(val)) {
        return oldVal
    }
    if (val > maxVal) {
        return oldVal;
    }

    let parsedString = removeSplCharsForNumbers(val);

    /* If parsed string is in float */
    if (parsedString.includes('.')) {
        /* splitting parsedString with and slicing last index with fixed value */
        let splited = parsedString.split('.');
        if (splited.length == 2) {
            splited[splited.length - 1] = splited[splited.length - 1].slice(0, fixedValue);
        }

        /* then joining back to parsedString */
        parsedString = splited.join('.')
    }

    return parsedString;
}

const generateQuery = (URL, options) => {
    let keys = Object.keys(options);
    keys.length > 0 && keys.forEach(e => {
        if (options[e]) {
            URL += `&${e}=${options[e]}`
        }
    })
    return URL;
}

const validateFileTypeImages = formate => ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg', 'image/svg+xml'].includes(formate)
const validateFileTypeExcel = formate => ['text/csv', 'application/vnd.ms-excel', 'application/excel', 'application/x-excel', 'application/x-msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(formate)

export {
    getOnlyNumber,
    generateQuery,
    validateFileTypeImages,
    validateFileTypeExcel,
}