import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";



export const getInvoiceReqList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_request_data?${query}`);
};

export const getInvoiceReqListID = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_data_by_request_id${query}`);
};

export const getInvoiceList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_invoice_listing${query}`);
};

export const ewayBillDetails = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_eway_data${query}`);
};

export const getTruckList = async () => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_truck/get_truck_data`);
};

export const postInvoiceRequest = async (query,body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_transport_invoice/${query}`,body);
}

export const putInvoiceRequest = async (query,body) =>{
    return axios.put(`${AUTH_BASE_URL}/lr_gr_transport_invoice/update_data_by_request_id${query}`,body);
}

export const getInvoiceapprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/request_approval_history${query}`)
}

export const postApproverAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_approver_action`, body);
}
export const postMassApproverAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_mass_approver_action`, body);
}
export const postInvoiceRequestAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-player/action-market-player`,body);
}

export const getExportInner = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_transport_invoice/get_data_by_request_id_export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };
  
export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_transport_invoice/export_to_excel?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

