import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login";
import CustomLogin from "./pages/auth/CustomLogin";
import Forget from "./pages/auth/forget";
import ResetPassword from "./pages/auth/reset";
import GetOTP from "./pages/auth/get-otp";
import Home from "./pages/dashboard/home";
import Mandatory from "./pages/dashboard/mandatory-course";
import AgreeTerms from "./pages/dashboard/agree-terms";
import QuaterlyTerms from "./pages/dashboard/QuaterlyTerms";
import NotFound from "./pages/404";
import {
  selectPixelData,
  setProfileStatus,
  setSideMenuStatus,
} from "../src/pages/redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import LoaderContainer from "./modals/loaderContainer";

import { isAndroid, isIOS } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import SsoRequest from "./pages/PiLinkPages/SsoRequest";
import AccessDenied from "./pages/accessDenied";
import SocialPolicy from "./pages/dashboard/SocialPolicy";
import DataCertification from "./pages/DataCertification/DataCertification";
import { ViewExternalassess } from "./pages/index";
import ExternalSurveyResponse from "./pages/company/ExternalSurveyResponse";
import VisitorDetailsForm from "./pages/transaction/visitor-details-form";
import ThankYouPage from "./pages/transaction/thankyoupage";
import { initGA, logPageView } from './analytics';

function App() {
  const data = useSelector(selectPixelData);
  const dispatch = useDispatch();
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  useEffect(() => {
    initGA('G-VZF6MPDSVQ'); // Replace with your Google Analytics tracking ID
  }, []);

  useEffect(() => {
    document.body.style.fontSize = data + "px";
  }, [data]);

  const profileStatus = () => {
    dispatch(setProfileStatus());
    dispatch(setSideMenuStatus());
  };

  useEffect(() => {
    let url = window.location.href
    if (isAndroid && !localStorage.getItem("plateform") && url.includes('is_external') === false) {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.develop.mypi"
      );
    } else if (isIOS && !localStorage.getItem("plateform") && url.includes('is_external') === false) {
      window.location.replace(
        "https://apps.apple.com/in/app/mypi/id6448280260"
      );
    }
    <ToastContainer autoClose={1000} />;
  }, []);

  return (
    <Router>
      <LoaderContainer />
      <ToastContainer autoClose={1000} />
      <Routes>
        <Route path="/custom" exact element={<CustomLogin />} />
        <Route path="/" exact element={<Login />} />
        <Route path="/ssorequest" exact element={<SsoRequest />} />
        <Route
          path="/*"
          exact
          element={<Home profileStatus={() => profileStatus()} />}
        />
        <Route path="/forgot" exact element={<Forget />} />
        <Route path="/resetpassword" exact element={<ResetPassword />} />
        <Route path="/get-otp" exact element={<GetOTP />} />
        <Route path="/mandatory" exact element={<Mandatory />} />
        <Route path="/agree-terms" exact element={<AgreeTerms />} />
        <Route path="/social-policy" exact element={<SocialPolicy />} />
        <Route path="/quater-terms" exact element={<QuaterlyTerms />} />
        <Route path="/404-not-found" exact element={<NotFound />} />
        <Route path="/denied" exact element={<AccessDenied />} />
        <Route path="/viewassignments" exact element={<ViewExternalassess />} />
        <Route path="/visitor-details" exact element={<VisitorDetailsForm />} />
        <Route path="/thankyou-page" exact element={<ThankYouPage />} />
        <Route path='/extSurveyResponse' exact element={<ExternalSurveyResponse />} />
        <Route
          path="/data-certification"
          exact
          element={<DataCertification />}
        />
      </Routes>
    </Router>
  );
}
export default App;
