import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import * as ShiftService from "../../service/ShiftMaster";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const validationSchema = Yup.object({
  shift_name: Yup.string()
    // .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    // .matches(
    //   /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
    //   "Insert only normal character"
    // )
    .required("*Shift Name is required")
    .nullable(),
  shift_time_in: Yup.string().required("*Shift Time In is required").nullable(),
  personal_sub_area_id: Yup.string()
    .required("*Personal Sub Area is required")
    .nullable(),
  shift_time_out: Yup.string()
    .required("*Shift Time Out is required")
    .nullable(),
});

const AddShiftMaster = (props) => {
  const nameForm = "Shift Master";
  const { edit, showForm, onClose, editShift, onAddCallBack, shiftflag } =
    props;
  const [personalSubAreaData, setPersonalSubAreaData] = useState([]);
  const [shiftData, setShiftData] = useState({
    shift_name: "",
    shift_time_in: "",
    shift_time_out: "",
    // reasons: [{ reason: "heollo kanu" }],
    personal_sub_area_id: null,
    is_active: true,
  });
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const dataBlank = () => {
    onClose();
  };
  const getPersonalSubAreaData = (id) => {
    ShiftService.getPersonalSubAreaData(id)
      .then((response) => {
        setPersonalSubAreaData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const saveShift = (values) => {
    setIsLoading(true);
    let datass = JSON.parse(JSON.stringify(values));
    datass.personal_sub_area_id = parseInt(datass.personal_sub_area_id);
    ShiftService.saveShift(datass)
      .then((response) => {
        if (response.data.code == 200) {
          onAddCallBack(response);
          if (response.data.message === "Shift created successfully") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const updateShift = (id, values) => {
    setIsLoading(true);
    let datass = JSON.parse(JSON.stringify(values));
    datass.personal_sub_area_id = parseInt(datass.personal_sub_area_id);
    // value={`${
    //   values.shift_time_in.split(":")[0]
    // }:{${values.shift_time_in.split(":")[1]}`}
    ShiftService.updateShift(id, datass)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const shiftListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`personalArea${index}`}
        value={`${item?.id}`}
        label={`${item?.description} (${item?.name})`}
      />
    ));
  };
  useEffect(() => {
    getPersonalSubAreaData(0);
  }, []);

  useEffect(() => {
    if (editShift) {
      setShiftData(editShift);
      Object.keys(editShift).forEach((item) => {
        if (item === "shift_time_in") {
          let value = editShift[item].split(":");
          shiftData[item] = `${value[0]}:${value[1]}`;
        } else if (item === "shift_time_out") {
          let value = editShift[item].split(":");
          shiftData[item] = `${value[0]}:${value[1]}`;
        } else {
          shiftData[item] = editShift[item];
        }
        // values.shift_time_in.split(":")[0]
      });
    }
  }, [editShift]);

  // const myFunc = (e, name) => {
  // }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={shiftData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // if (!isDeptExist) {
          if (values.id) {
            updateShift(values.id, values);
            AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            saveShift(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Modal
              show={shiftflag}
              onHide={onClose}
              size="lg"
              backdrop="static"
              keyboard={false}
              className="modaldefaultclose modaldefaultclosecenter"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {" "}
                  {edit === true ? "Edit" : "Add"} Shift Module
                </Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmit}>
                <>
                  <Modal.Body>
                    <div class="row justify-content-center">
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Shift Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Shift Name"
                                maxLength={30}
                                name="shift_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.shift_name}
                              />
                              {touched.shift_name && errors.shift_name ? (
                                <div className="small text-danger">
                                  {errors.shift_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Time In<span class="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                class="form-control"
                                name="shift_time_in"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.shift_time_in}
                                // value={`${
                                //   values.shift_time_in.split(":")[0]
                                // }:{${values.shift_time_in.split(":")[1]}`}
                              />
                              {touched.shift_time_in && errors.shift_time_in ? (
                                <div className="small text-danger">
                                  {errors.shift_time_in}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Time Out<span class="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                class="form-control"
                                name="shift_time_out"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.shift_time_out}
                                // min={values.shift_time_in}
                                // min="21:00"
                              />
                              {touched.shift_time_out &&
                              errors.shift_time_out ? (
                                <div className="small text-danger">
                                  {errors.shift_time_out}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Personal Sub Area
                                <span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                name="personal_sub_area_id"
                                type="number"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  setShiftData({
                                    ...shiftData,
                                    personal_sub_area_id: e.target.value,
                                  });
                                }}
                                value={values.personal_sub_area_id}
                              >
                                <option
                                  value="0"
                                  label="Select Personal Sub Area"
                                  hidden
                                />

                                {shiftListData(personalSubAreaData)}
                              </select>
                              {touched.personal_sub_area_id &&
                              errors.personal_sub_area_id ? (
                                <div className="small text-danger">
                                  {errors.personal_sub_area_id}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label className="d-block">Status</label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="active"
                              >
                                Active
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="active"
                                  value={values.is_active}
                                  onChange={() => {
                                    setFieldValue("is_active", true);
                                  }}
                                  checked={values.is_active === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Inactive"
                              >
                                Inactive
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="Inactive"
                                  value={values.is_active}
                                  onChange={(e) => {
                                    setFieldValue("is_active", false);
                                  }}
                                  checked={values.is_active === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      <>
                        <button
                          class="btn btn-outline-danger"
                          onClick={() => {
                            dataBlank();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </Modal.Footer>
                </>
              </form>
            </Modal>
          </div>
        )}
      </Formik>
    </>
  );
};

export default AddShiftMaster;
