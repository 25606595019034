import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";
let baseurl = `http://localhost:8000/api/v1`;

export const Addnews = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/notice`, data);
};
export const Updatenews = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/notice`, data);
};
export const uploadFile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};
export const UpdateNews = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/notice`, data);

  
};

export const getNotices = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  type,
  from_date,
  to_date,
  status,
  data_id
) => {
  let queryParm = `${AUTH_BASE_URL}/notice?page_no=${pageNo}&search=${search}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&Type=${type}&from_date=${from_date}&to_date=${to_date}${
    status == undefined || status == "" || status == null
      ? ""
      : `&status=${status}`
  }`;
  if (data_id !== undefined) {
    queryParm = queryParm + "&data_id=" + data_id;
  }
  return axios.get(queryParm);
};
export const getNotice = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  type,
  from_date,
  to_date,
  data_id
) => {
  let queryParm = `${AUTH_BASE_URL}/notice?page_no=${pageNo}&search=${search}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&Type=${type}&from_date=${from_date}&to_date=${to_date}`;
  if (data_id !== undefined) {
    queryParm = queryParm + "&data_id=" + data_id;
  }
  return axios.get(queryParm);
};
export const getNews = async () => {
  return axios.get(`${AUTH_BASE_URL}/notice/clients/getNews`);
};
export const getPopupData = async () => {
  return axios.get(`${AUTH_BASE_URL}/notice/clients/popup`);
};

export const postPopupData = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/notice/clients/popup`,
    method: "POST",
    data: data,
  });
};
export const deleteNotice = async (id) => {
  // return axios({
  //   url: `${AUTH_BASE_URL}/notice/${id}`,
  //   method: "DELETE",
  //   data: id,
  // });
  return axios.delete(`${AUTH_BASE_URL}/notice/${id}`);
};

export const changeNewsStatus = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/notice/action`,
    method: "POST",
    data: data,
  });
};
