import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import * as leaveDataService from "../../service/leaveService";
import avtar from "../../assets/images/avtar.png";
import femaleAvatr from "../../assets/images/Female-Avatar.png";

const LeaveTypeGrid = ({ weekOff }) => {
  const [userLeaveSummaryData, setuserLeaveSummaryData] = useState([]);
  const userData = useSelector(selectUserData);

  const getUserLeaveSummary = (id) => {
    leaveDataService
      .getUserLeaveSummary(id, "", "")
      .then((response) => {
        setuserLeaveSummaryData(response.data.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getUserLeaveSummary(userData?.id, "", "");
  }, [userData]);

  function getOrdinal(n) {
    let ord = "th";

    if (n % 10 == 1 && n % 100 != 11) {
      ord = "st";
    } else if (n % 10 == 2 && n % 100 != 12) {
      ord = "nd";
    } else if (n % 10 == 3 && n % 100 != 13) {
      ord = "rd";
    }

    return ord;
  }

  return (
    <>
      <div class="col-md-4">
        <div class="card leave-apply-section  mt-3">
          <div class="row">
            <div class="col-5">
              <div class="leave-cc-image position-relative">
                <img
                  src={
                    userData?.image === "" && userData?.gender === "M"
                      ? avtar
                      : userData?.image === "" && userData?.gender === "F"
                        ? femaleAvatr
                        : `data:image/gif;base64,${userData?.image}`
                  }
                />
              </div>
            </div>
            <div class="col-7 ">
              <h5>{userData?.full_name}</h5>
              <h6 class="mb-1">
                <i class="far fa-check-circle"></i>
                RM -{" "}
                {`${userData?.reporting_to_fname} ${userData?.reporting_to_lname} (${userData?.reporting_to_name})`}
              </h6>
            </div>

            <div class="col-md-12">
              <h5 class="text-dark mt-2">My Leaves</h5>
              {/* {weekOff?.first_off !== "" ||
                (weekOff?.second_off !== "" && ( */}
              <h5
                class="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                1st Weekly Off - {weekOff?.first_off} | Freq. - ALL
              </h5>
              <h5
                class="mt-2"
                style={{
                  fontSize: "14px",
                }}
              >
                2nd Weekly Off - {weekOff?.second_off} | Freq. -
                {weekOff?.alternate_off
                  .replaceAll(" ", "")
                  .split("")
                  .map((obj) => {
                    let ord = getOrdinal(Number(obj));
                    return `${obj}${ord}`;
                  })
                  .toString()}
              </h5>
              {/* ))} */}
              <div class="mt-1">
                <div class="dashbed-border-bottom"></div>
              </div>

              {/* <div class="row mt-1 mb-3">
                <div class="col-4">
                  <h4
                    class="dashTitle font-weight-light text-info"
                    href="#"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    {userLeaveSummaryData?.total}
                  </h4>
                  <div class="dot-blue">Total</div>
                </div>
                <div class="col-4">
                  <h4
                    class="dashTitle font-weight-light text-danger"
                    href="#"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    {userLeaveSummaryData?.availed}
                  </h4>
                  <div class="dot-blue">Availed</div>
                </div>
                <div class="col-4">
                  <h4
                    class="dashTitle font-weight-light text-success"
                    href="#"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    {userLeaveSummaryData?.balance}
                  </h4>
                  <div class="dot-blue">Balance</div>
                </div>
              </div> */}

              <h5 class="text-dark ">Leave Detail</h5>

              <div class="mt-1">
                <div class="dashbed-border-bottom"></div>
              </div>

              <div class="table-responsive mt-2 mb-0">
                <table
                  class="table  table-bordered leavestatustab"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <th>#</th>
                      <th>Leave Type</th>
                      <th>Current</th>
                      <th>Availed</th>
                    </tr>
                  </tbody>
                  <tbody>
                    {userLeaveSummaryData?.leave_type_summary?.map(
                      (data, idx) => (
                        <tr key={`userSummary_${idx}`}>
                          <td>{idx + 1}</td>
                          <td>{data?.leave_type_name}</td>
                          <td>
                            <span>{data?.bal}</span>
                          </td>
                          <td>
                            <span>{data?.avail}</span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveTypeGrid;
