import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import * as ShiftService from "../../service/ShiftMaster";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import AddShiftMaster from "./AddShiftMaster";
import ReasonMaster from "./ReasonMaster";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

const ShiftMaster = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [showForm, setShowForm] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const [shiftManageList, setShiftManageList] = useState([]);
  const [shiftManageData, setShiftManageData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageCount1, setPageCount1] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [shiftManage, setShiftManage] = useState(null);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [checkActiveTab, setCheckActiveTab] = useState("approval");
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [dropDept, setDropDept] = useState([]);
  const [personalSubAreaDropdown, setPersonalSubAreaDropdown] = useState([]);
  const [shiftflag, setShiftFlag] = useState(false);
  const [reasonflag, setReasonFlag] = useState(false);
  const [reasonNewData, setReasonNewData] = useState("");
  const [shiftDataForExport, setShiftDataForExport] = useState({
    shift_id: "",
    status: "",
  });
  const [shiftDataFilter, setShiftDataFilter] = useState({
    shift_id: "",
    status: "",
    psa_id: "",
  });

  const getShiftList = (
    search,
    shift_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    psa
  ) => {
    ShiftService.getShiftList(
      handleKey(
        search,
        shift_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        psa
      )
    )
      .then((response) => {
        setShiftManageList(response?.data?.dataList?.result);
        setShiftManageData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getReasonList = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    ShiftService.getReasonList(
      handleKeyNew(search, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setReasonNewData(response?.data?.dataList?.result);
        // setShiftManage(response?.data?.dataList?.result);
        setPageCount1(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    shift_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    psa_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (shift_id !== "" && shift_id !== undefined && shift_id !== null) {
      queryParm = queryParm + "&shift_id=" + shift_id;
    }
    if (psa_id !== "" && psa_id !== undefined && psa_id !== null) {
      queryParm = queryParm + "&psa_id=" + psa_id;
    }

    return queryParm;
  };

  const handleKeyNew = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const editShift = (eData) => {
    if (shiftflag == true) {
      setShowForm(true);
    }
    if (reasonflag == true) {
      setShowForm1(true);
    }
    setEditMode(true);
    setShiftManage(eData);
  };
  const addNewShift = () => {
    setEditMode(false);
    if (shiftflag == true) {
      setShowForm(true);
    }
    if (reasonflag == true) {
      setShowForm1(true);
    }
    setShiftManage(null);
  };
  const handleSearch = (e) => {
    if (checkActiveTab == "approval") {
      getShiftList(
        e.target.value.toLowerCase(),
        shiftDataFilter.shift_id,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        shiftDataFilter.psa_id
      );
    } else {
      getReasonList(
        e.target.value.toLowerCase(),
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }

    setSearchStr(e.target.value);
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.shift_name}`}
        label={`${item?.shift_name}`}
      />
    ));
  };
  const renderPsaOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.personal_sub_area_id}`}
        label={`${item?.personal_sub_area_name}`}
      />
    ));
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (checkActiveTab == "approval") {
        getShiftList(
          data,
          shiftDataFilter.shift_id,
          shiftDataFilter.status,
          1,
          pageSizeNo,
          sortData.sort_by,
          true,
          shiftDataFilter.psa_id
        );
      } else {
        getReasonList(
          data,
          shiftDataFilter.status,
          1,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      }
    }
  };

  const onAddCallBack = (response) => {
    if (response == "editReason") {
      setShowForm1(true);
    } else {
      setShowForm(false);
      getShiftList(
        searchStr,
        shiftDataFilter.holiday_date,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        shiftDataFilter.psa_id
      );
      getReasonList("", "", currentPage, pageSizeNo, "-id", true);
    }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getShiftList(
      searchStr,
      shiftDataFilter.holiday_date,
      shiftDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      shiftDataFilter.psa_id
    );
  };
  const onClose = () => {
    setShowForm(false);
    setShowForm1(false);
  };

  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    setShiftDataForExport({
      ...shiftDataForExport,
      shift_id: shiftDataFilter.shift_id,
      status: shiftDataFilter.status,
    });
    const status = shiftDataFilter.status;
    const shift_id = shiftDataFilter.shift_id;
    const psa_id = shiftDataFilter.psa_id;
    if (checkActiveTab == "approval") {
      getShiftList(
        searchStr,
        shift_id,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        psa_id
      );
    } else {
      getReasonList(
        searchStr,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
    filterClose();
  };
  const getExportDetails = (
    search,
    shift_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    ShiftService.getExportValue(
      handleKey(search, shift_id, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ShiftList.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllShift = (paginate) => {
    ShiftService.getShiftList(
      handleKey("", "", "", 1, 10, sortData.sort_by, true, "")
    )
      .then((response) => {
        let uniqueArray = [];
        let uniqueArray1 = [];
        let obj = response?.data?.dataList?.result_data1?.filter((res) => {
          if (uniqueArray.includes(res.shift_name)) {
            return false;
          } else {
            uniqueArray.push(res?.shift_name);
            return true;
          }
        });
        setDropDept(obj);
        let obj1 = response?.data?.dataList?.result_data1?.filter((res) => {
          if (uniqueArray1.includes(res.personal_sub_area_name)) {
            return false;
          } else if (res?.personal_sub_area_name !== null) {
            uniqueArray1.push(res?.personal_sub_area_name);
            return true;
          }
        });
        setPersonalSubAreaDropdown(obj1);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  // const getReasonList = (paginate) => {
  //   ShiftService.getReasonList(paginate)
  //     .then((response) => {
  //       setReasonNewData(response?.data?.dataList?.result);
  //     })
  //     .catch((error) => {
  //       console.log(

  //     });
  // };
  const filterShow = () => {
    filteropen();
    getAllShift(false);
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(shiftDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setShiftDataFilter(ClearData);
    filterClose();
    if (checkActiveTab == "approval") {
      getShiftList(
        searchStr,
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        ""
      );
    } else {
      getReasonList(
        searchStr,
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    if (checkActiveTab == "approval") {
      getShiftList(
        searchStr,
        shiftDataFilter.shift_id,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        shiftDataFilter.psa_id
      );
    } else {
      getReasonList(
        searchStr,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  const deleteShift = (idx) => {
    ShiftService.deleteShift(idx)
      .then((res) => {
        if (res.data.status == true) {
          if (checkActiveTab == "approval") {
            getShiftList(
              searchStr,
              shiftDataFilter.shift_id,
              shiftDataFilter.status,
              currentPage,
              pageSizeNo,
              sortData.sort_by,
              true,
              shiftDataFilter.psa_id
            );
          } else {
            getReasonList(
              searchStr,
              shiftDataFilter.status,
              currentPage,
              pageSizeNo,
              sortData.sort_by,
              true
            );
          }
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete Shift Manage",
      message: `Are you sure to delete Shift Manage?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteShift(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    if (checkActiveTab == "approval") {
      getShiftList(
        searchStr,
        shiftDataFilter.shift_id,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        shiftDataFilter.psa_id
      );
      setShiftFlag(true);
      setReasonFlag(false);
    } else {
      getReasonList("", "", currentPage, pageSizeNo, "-id", true);
      setReasonFlag(true);
      setShiftFlag(false);
    }
  }, [checkActiveTab]);

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getShiftList(
      searchStr,
      shiftDataFilter.shift_id,
      shiftDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      shiftDataFilter.psa_id
    );
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="content-wrapper-inner">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">Shift Master</h4>
                {/* <Breadcrumb>
                  <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Shift Master</Breadcrumb.Item>
                </Breadcrumb> */}
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                {checkActiveTab == "approval" ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong> Shift Master,</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        id="searchbar"
                        class="form-control"
                        placeholder="Search on Shift Name..."
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong> Reason,</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        id="searchbar"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {/* <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={shiftManageList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              shiftDataForExport.shift_id,
                              shiftDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <button
                    onClick={() => {
                      filterShow();
                    }}
                    class="btn btn-secondary-inner"
                    style={{
                      backgroundColor: filterApplied && "yellow",
                      color: filterApplied && userData?.dark_mode === false && "#000"
                    }}
                  >
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                  <button
                    class="btn btn-primary-inner"
                    onClick={() => {
                      addNewShift();
                    }}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
            <Tabs
              defaultActiveKey="approval"
              id="approvaltabs"
              onSelect={(e) => setCheckActiveTab(e)}
            >
              <Tab
                eventKey="approval"
                title={<React.Fragment>Shift Master</React.Fragment>}
              >
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>Shift Name</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Personal Sub Area</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shiftManageList?.length ? (
                        shiftManageList?.map((data, index) => (
                          <tr>
                            <td class="fw-bold text-theme">
                              {data?.shift_name}
                            </td>
                            {data?.shift_time_in !== null ? (
                              <td>
                                {data?.shift_time_in.split(":")[0]}:
                                {data?.shift_time_in.split(":")[1]}{" "}
                                {data?.shift_time_in.split(":")[0] <= 11 ||
                                  (data?.shift_time_in.split(":")[0] == 12 &&
                                    data?.shift_time_in.split(":")[1] == 0)
                                  ? "hrs"
                                  : "hrs"}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {data?.shift_time_out !== null ? (
                              <td>
                                {data?.shift_time_out.split(":")[0]}:
                                {data?.shift_time_out.split(":")[1]}{" "}
                                {data?.shift_time_out.split(":")[0] <= 11 ||
                                  (data?.shift_time_out.split(":")[0] == 12 &&
                                    data?.shift_time_out.split(":")[1] == 0)
                                  ? "hrs"
                                  : "hrs"}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {/* <td>
                              {data?.reasons?.map((ss) => {
                                return <> {ss.reason} </>;
                              })}
                            </td> */}
                            <td>
                              {data?.personal_sub_area_desc} (
                              {data?.personal_sub_area_name})
                            </td>
                            <td>
                              {data?.is_active ? (
                                <span className="badge bad-status badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bad-status badge-danger">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                onClick={() => {
                                  editShift(data);
                                }}
                              >
                                <i class="far fa-edit"></i>
                              </button>
                              <button
                                class="btn btn-blanktd text-danger ml-2"
                                onClick={() => {
                                  submit(data?.id, data?.name);
                                }}
                              >
                                <i class="far fa-trash-alt text-danger"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        class="form-control main-pointer"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="draft"
                title={<React.Fragment>Reason Master</React.Fragment>}
                onClick={() => setCheckActiveTab(false)}
              >
                {" "}
                <ReasonMaster
                  onAddCallBack1={onAddCallBack}
                  getReasonList={getReasonList}
                  reasonNewData={reasonNewData}
                  reasonflag={reasonflag}
                  setPageCount1={setPageCount1}
                  pageCount1={pageCount1}
                  showForm1={showForm1}
                  setShowForm1={setShowForm1}
                  onClose1={onClose}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkActiveTab == "approval" ? (
            <>
              <div class="form-group innergroup">
                <label>Select Shift</label>
                <select
                  className="form-control main-pointer"
                  classNamePrefix="select"
                  style={{ width: "334px" }}
                  name="shift_id"
                  onChange={(e) => {
                    setShiftDataFilter({
                      ...shiftDataFilter,
                      shift_id: e.target.value,
                    });
                  }}
                  value={shiftDataFilter.shift_id}
                >
                  <option value="" label="Select Shift" />
                  {renderDeptOptions(dropDept)}
                </select>
              </div>
              <div class="form-group innergroup">
                <label>Select Personal Sub Area</label>
                <select
                  className="form-control main-pointer"
                  classNamePrefix="select"
                  style={{ width: "334px" }}
                  name="psa_id"
                  onChange={(e) => {
                    setShiftDataFilter({
                      ...shiftDataFilter,
                      psa_id: e.target.value,
                    });
                  }}
                  value={shiftDataFilter.psa_id}
                >
                  <option value="" label="Select Personal Sub Area" />
                  {renderPsaOptions(personalSubAreaDropdown)}
                </select>
              </div>
              <div class="form-group innergroup">
                <label>Select Status</label>
                <select
                  class="form-control newbgselect main-pointer"
                  name="status"
                  onChange={(e) => {
                    setShiftDataFilter({
                      ...shiftDataFilter,
                      status: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={shiftDataFilter.status}
                >
                  <option value="" label="Select" />
                  <option value={true} label="Active" />
                  <option value={false} label="In-Active" />
                </select>
              </div>
            </>
          ) : (
            <div class="form-group innergroup">
              <label>Select Status</label>
              <select
                class="form-control newbgselect main-pointer"
                name="status"
                onChange={(e) => {
                  setShiftDataFilter({
                    ...shiftDataFilter,
                    status: e.target.value,
                  });
                  setCurrentPage(1);
                }}
                value={shiftDataFilter.status}
              >
                <option value="" label="Select" />
                <option value={true} label="Active" />
                <option value={false} label="In-Active" />
              </select>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter(shiftDataFilter)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {showForm ? (
        <>
          <AddShiftMaster
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editShift={shiftManage}
            onAddCallBack={onAddCallBack}
            shiftflag={shiftflag}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ShiftMaster;
