import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Pagination } from "react-bootstrap";
import * as ClaimTravel from "../../service/claimTravel";
import Select from "react-select";
import ExpenseClaim from "./ExpenseClaim";
import TravelLinks from "./TravelLinks";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";

function ClaimListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [gridData, setGridData] = useState(false);
  const [grideditData, setGrideditData] = useState([]);
  const [allData, setAllData] = useState([]);

  const options = [
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getTravelList = (search, pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));
    ClaimTravel.getTravelList(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setGridData(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const AddForm = (data, datas) => {
    setGrideditData(data);
    setAllData(datas);
    setShowForm(true);
  };

  useEffect(() => {
    getTravelList("", 1, 10, "-id", true);
  }, []);

  return (
    <>
      {showForm ? (
        <ExpenseClaim
          gridData={gridData}
          grideditData={grideditData}
          allData={allData}
          getTravelList={getTravelList}
        />
      ) : (
        <div class="row">
          {/* <div class="col-md-3">
            <TravelLinks />
          </div> */}
          {console.log(gridData, "gridData")}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  {/* <div class="col-md-12">
                    <h4 class="inner-page-title">Claim Listing</h4>
                  </div> */}
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Actions</th>
                      <th>ID</th>
                      <th>Employee Name</th>
                      <th>Status</th>
                      <th>Location</th>
                      <th>Zone</th>
                      <th>Trip No</th>
                      <th>Submit Date</th>
                      <th>Tracking No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData?.length ? (
                      gridData?.map((data, index) => (
                        <>
                          {data?.request_type !== "On Duty" && (
                            <tr>
                              <td>
                                <button
                                  class="btn btn-primary-inner bpi-main px-3 py-1 f-14"
                                  type="button"
                                  onClick={() => {
                                    AddForm(data?.travel_detail_data, data);
                                  }}
                                >
                                  <i class="far fa-check-circle"></i> Claim
                                </button>
                              </td>
                              <td class="text-theme fw-bold">
                                <span class="text-theme">{data?.id}</span>
                              </td>
                              <td>Aniket Singh</td>
                              <td>
                                <span class="badge bad-status badge-success">
                                  {data?.status}
                                </span>
                              </td>
                              <td>Udaipur</td>
                              <td>{data?.request_type}</td>
                              <td>123421</td>
                              <td>09/02/20</td>
                              <td>
                                <strong>4352641285</strong>
                              </td>
                            </tr>
                          )}
                          {/* <tr>
                      <td>
                        <button class="btn btn-primary-inner bpi-main px-3 py-1 f-14">
                          <i class="far fa-check-circle"></i> Claim
                        </button>
                      </td>
                      <td class="text-theme fw-bold">
                        <span class="text-theme">#2353</span>
                      </td>
                      <td>Aniket Singh</td>
                      <td>
                        <span class="badge bad-status badge-danger">
                          InActive
                        </span>
                      </td>
                      <td>Udaipur</td>
                      <td>SZCC42</td>
                      <td>123421</td>
                      <td>09/02/20</td>
                      <td>
                        <strong>4352641285</strong>
                      </td>
                    </tr> */}
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select class="form-control">
                      <option>10</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <Pagination>
                    <Pagination.Prev className="previpag" />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{9}</Pagination.Item>
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Next className="previpag" />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee Id<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Employee Name<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Department<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Travel Type<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Business</option>
              <option>Marketing</option>
              <option>Sales</option>
              <option>Other</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  From Date<span class="text-danger">*</span>
                </label>
                <input type="date" class="form-control" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  To Date<span class="text-danger">*</span>
                </label>
                <input type="date" class="form-control" />
              </div>
            </div>
          </div>

          <div class="form-group innergroup">
            <label>
              Location Form<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Location To<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Pending</option>
              <option>Rejected</option>
              <option>Approved</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClaimListing;
