import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModularTable from "../../../../elements/ModularTable";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import {
  getSalesPlan,
  getLastSalesPlan,
  getSalesPlanApprovalLogs,
  postSalesPlan,
  postSalesPlanAction,
  putSalesPlan,
} from "../../../../service/AgriPortalService/SalesPlanService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch,useSelector } from "react-redux";
import { setDisplayLoader,selectUserData } from "../../../redux/piDataStore";

const Root = (props) => {
  const userData = useSelector(selectUserData);
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);

  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [mode, setMode] = useState();
  const [locationData, setLocationData] = useState({});
  const [freezeCTA, setFreezeCTA] = useState(false);
  const [loader, setLoader] = useState(true);
  const [approvalList, setApprovalList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLoader(false);
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getSalesPlan()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    const totals = [{ value: "Total" }, { value: "-" }];

    for (let i = 2; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (!parseInt(data[j][i].value[0])) total += 0;
        else total += parseInt(data[j][i].value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;

    setUpdatedTableData(data);
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;
    data.forEach((row) => {
      if (row[0].value != "Total") {
        ans.push({
          id: 0,
          pi_product_id: row[1].meta.id,
          mol_market_size: row[3].value[0] == "" ? "0" : row[3].value[0],
          fy: tableHeadData[4].name.split(" ")[1],
          fy_projection: row[4].value[0] == "" ? "0" : row[4].value[0],
          fy_p_1: tableHeadData[5].name.split(" ")[1],
          fy_p_1_projection: row[5].value[0] == "" ? "0" : row[5].value[0],
          // fy_p_2: tableHeadData[6].name.split(" ")[1],
          // fy_p_2_projection: row[6].value[0] == "" ? "0" : row[6].value[0],
        });
      }
    });
    return ans;
  };

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/salesplan";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));
    let query = "";
    if (params.get("mode") != "create") {
      query = "?sale_trans_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getSalesPlanApprovalLogs(query)
        .then((res) => {
          setLoader(false);
          setApprovalList(res.data.dataList);
        })
        .catch((err) => console.log(err));
    }
    dispatch(setDisplayLoader('Display'))
    getLastSalesPlan(query)
      .then((res) => {
        dispatch(setDisplayLoader('Hide'))
        setRawData(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Crop Plan should be Submitted before Sale Plan");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        }
      });
  }, []);

  //Set table data after past data is fetched
  useEffect(() => {
    if (!rawData.sale_plan_data) return;

    setTableHeadData((state) => {
      if (!rawData) return [];

      let headData;

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Product Group",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crop Wise Plan " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Molecule Market Size",
            type: "N",
            isEditable: true,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: true,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_1,
            type: "N",
            isEditable: true,
          },
          // {
          //   name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: true,
          // },
        ];

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Product Group",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crop Wise Plan " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Molecule Market Size",
            type: "N",
            isEditable: false,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          {
            name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_1,
            type: "N",
            isEditable: false,
          },
          // {
          //   name: "Projection " + rawData?.sale_plan_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: false,
          // },
        ];

      return headData;
    });

    setTableData((state) => {
      const newTableData = [];

      let val1 = 0,
        val2 = 0,
        val3 = 0,
        val4 = 0,
        val5 = 0;
      rawData?.sale_plan_data?.forEach((el, idx) => {
        let rowData;

        val1 += parseInt(el.fy_projection);
        val2 += parseInt(el.fy_p_1_projection);
        // val3 += parseInt(el.fy_p_2_projection);
        // val4+=parseInt(el.fy_p_1_value);
        // val5+=parseInt(el.fy_p_1_value);
        let category = "";

        if (el.pi_product_category == "M") category = "Marketing";
        else if (el.pi_product_category == "S") category = "Star";
        else if (el.pi_product_category == "T") category = "Trading";
        else if (el.pi_product_category == "N") category = "New";
        else if (el.pi_product_category == "G") category = "Generic";
        else if (el.pi_product_category == "F") category = "Future";
        else if (el.pi_product_category == "B") category = "BIOLOGICALS";

        rowData = [
          {
            value: [category],
            meta: {
              id: "",
            },
          },
          {
            value: [el.pi_product_name],
            meta: {
              id: el.pi_product_id,
            },
          },
          {
            value: [el.crop_wise_plan],
            meta: {},
          },
          {
            value: [el.mol_market_size],
            meta: {},
          },
          {
            value: [el.fy_projection],
            meta: {},
          },
          {
            value: [el.fy_p_1_projection],
            meta: {},
          },
          // {
          //   value: [el.fy_p_2_projection],
          //   meta: {},
          // },
        ];

        newTableData.push(rowData);
      });

      newTableData.push([
        {
          value: ["Total"],
          meta: {
            id: "",
          },
        },
        {
          value: ["-"],
          meta: {
            id: "",
          },
        },
        {
          value: ["-"],
          meta: {},
        },
        {
          value: ["-"],
          meta: {},
        },
        {
          value: [val1],
          meta: {},
        },
        {
          value: [val2],
          meta: {},
        },
        // {
        //   value: [val3],
        //   meta: {},
        // },
      ]);

      return newTableData;
    });
  }, [rawData]);

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      sale_trans_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postSalesPlanAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };
  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    let cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    cfy = rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const sale_plan_data = convertCategoryData(updatedTableData);

    if (!sale_plan_data) return;
    body.sale_plan_data = sale_plan_data;
    dispatch(setDisplayLoader('Display'))
    if (mode == "create")
      postSalesPlan(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          dispatch(setDisplayLoader('Hide'))
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      dispatch(setDisplayLoader('Display'))
      putSalesPlan(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        });
    }
  };

  const validateData = (type) => {
    if (!updatedTableData) return;
    const errorData = [];
    updatedTableData.map((el) => {
      if (
        el[0].value[0] != "Total" &&
        parseInt(el[2].value[0]) != parseInt(el[4].value[0])
      ) {
        errorData.push(el[1].value[0]);
      }
    });
    if (errorData.length > 0) {
      let text = `Quantity of current fy should be equal to crop wise plan for following product ${errorData.join(
        ", "
      )}`;
      setFreezeCTA(false);
      errorDialog(text);
    } else {
      confirmDialog(type);
    }
  };

  const errorDialog = (data) => {
    confirmAlert({
      title: "Validation Error",
      message: data,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (event) => {
    if (freezeCTA) return;
    setFreezeCTA(true);
    let cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    cfy = rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const sale_plan_data = convertCategoryData(updatedTableData);

    if (!sale_plan_data) return;
    body.sale_plan_data = sale_plan_data;
    dispatch(setDisplayLoader('Display'))
    if (mode == "create")
      postSalesPlan(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail)
          } else {
            toast.error("Submit failed.")
          }
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      dispatch(setDisplayLoader('Display'))
      putSalesPlan(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail)
          } else {
            toast.error("Submit failed.")
          }
          setTimeout(() => {
            navigate("/myplan/salesplan");
          }, 1000);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/salesplan");
  };

  // (function () {
  //   if (loader) {
  //     dispatch(setDisplayLoader("Display"));
  //   } else {
  //     dispatch(setDisplayLoader("Hide"));
  //   }
  // })();

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Sales Plan
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY IN LT/KG
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{rawData?.cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
         {userData.organisation_code === "PIIL" ?
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  setFreezeCTA(true);
                  validateData("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  setFreezeCTA(true);
                  validateData("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
        :
            <div className={styles["ctas_right"]}>
                  <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                    Back
                  </div>
                </div>
            }
      </div>
    </div>
  );
};
export default Root;
