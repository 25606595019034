import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getDelegatedModules = async () => {
  return axios.get(`${AUTH_BASE_URL}/users/delegate_modules`);
};

export const assignModule = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/assign-delegate`, data);
};

export const sendChatText = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/chat-gpt`, data);
};

export const addModule = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/assign-delegated-modules`, data);
};

export const getDelegateList = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/users/assigned-delegate?${queryParam}`);
};

export const updateAssignedModules = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/users/${id}`, data);
};
export const updateUserPassword = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/users/UpdateCurrentPassword`, data);
};
