import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as VendorService from "../../service/cashbookService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

const AddCashBookDemand = (props) => {
  let condition = "true";
  const { addnewshow, onClose, onAddCallBack, editModuleData, edit, showView } =
    props;
  const options = [
    { value: "P", label: "PAN Card" },
    { value: "A", label: "Aadhar Card" },
    { value: "D", label: "Driving Licence" },
  ];
  const userData = useSelector(selectUserData);

  const [makingType, setMakingType] = useState("");
  const [benType, setBenType] = useState([]);
  const [makingValue, setMakingValue] = useState();
  const [benValue, setBenValue] = useState();
  const [idProofValue, setIdProofValue] = useState();
  const [cashbookData, setCashbookData] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [beneficiary, setBeneficiary] = useState([]);
  const [masterData, setMasterData] = useState([]);

  const [cashBookDemand, setCashBookDemand] = useState({
    beneficiary_user_id: "",
    doc_type: "",
    doc_no: "",
    amount: 0,
    remarks: "",
  });

  const [formValidation, setFormValidation] = useState({
    beneficiary_user_id: "",
    doc_type: "",
    doc_no: "",
    amount: "",
    remarks: "",
  });

  const getDocDetails = async (user_id) => {
    await VendorService.getDocDetails(user_id)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.doc_type,
            label: `${
              obj?.doc_type == "A"
                ? "Adhar Card"
                : obj?.doc_type == "P"
                ? "Pan Card"
                : "Driving Lisense"
            }`,
            // id: obj?.id,
            docNo: obj?.doc_no,
          };
        });
        setBenType(res);
        if (response?.data?.dataList?.result.length == 0) {
          setMakingType("");
        } else {
          response?.data?.dataList?.result?.map((obj) => {
            console.log(obj, "final");
            setMakingType(obj?.doc_no);
          });
        }
      })
      .catch((error) => {});
  };
  console.log(benType, "benType");
  const pushBenId = (e) => {
    setBenValue(e);
    setCashBookDemand({
      ...cashBookDemand,
      beneficiary_user_id: e.value,
    });
  };
  const getBeneficiaryData = (data, id, paginate) => {
    VendorService.getBeneficiaryData(data, id, paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.user_id,
            label: `${obj?.beneficiary_code}`,
            id: obj?.id,
            frequency_type: obj?.frequency_type,
            cash_limit: obj?.cash_limit,
            frequency: obj?.frequency,
          };
        });
        setBeneficiaryData(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const pushIdProof = (e) => {
    console.log(e, "hieepiee");
    setIdProofValue(e);
    let dd = cashBookDemand;
    dd.doc_no = e.docNo;
    dd.doc_type = e.value;
    setCashBookDemand(dd);
    // setCashBookDemand({
    //   ...cashBookDemand,
    //   doc_type: e.value,
    // });
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!cashBookDemand.doc_type) {
      isError = true;
      formerr.doc_type = "Document Type is required";
      setFormValidation(formerr);
    }

    if (!cashBookDemand.amount) {
      isError = true;
      formerr.amount = "*Amount is required";
      setFormValidation(formerr);
    } else if (cashBookDemand.amount.length >= 11) {
      isError = true;
      formerr.amount = "*Max Amount 11 digits";
      setFormValidation(formerr);
    }
    if (!cashBookDemand.remarks) {
      isError = true;
      formerr.remarks = "*Remarks is required";
      setFormValidation(formerr);
    }
    if (!cashBookDemand.beneficiary_user_id) {
      isError = true;
      formerr.beneficiary_user_id = "*Beneficiary Id is required";
      setFormValidation(formerr);
    }
    if (!cashBookDemand?.doc_no) {
      isError = true;
      formerr.doc_no = "*Doc No is required";
      setFormValidation(formerr);
    }
    return isError;
  };
  const getAllCashbookList = (paginate) => {
    VendorService.getAllCashbookList(paginate)
      .then((response) => {
        setCashbookData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getMasterData = (paginate) => {
    VendorService.getMasterData(paginate)
      .then((response) => {
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const updateDemand = async (id, data) => {
    await VendorService.updateDemand(id, data)
      .then((response) => {
        if (response.data.code == 200) {
          toast.success("Cahbook Demand Updated Successfully");
          onAddCallBack();
          onClose();
          getAllCashbookList(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  console.log(cashBookDemand, "cashBookDemand");
  const handleCashlimitCheck = async (data, values) => {
    let flag = null;
    await VendorService.handleCashlimitCheck(data)
      .then((response) => {
        if (response?.data.code == 200) {
          console.log(
            response?.data?.dataList?.cash_balance >= Number(values?.amount)
          );
          if (
            response?.data?.dataList?.cash_balance &&
            response?.data?.dataList?.cash_balance >= Number(values?.amount)
          ) {
            flag = true;
          } else {
            flag = false;
          }
          // toast.success("test");
          // getAllCashbookList(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    return flag;
  };
  const saveDemand = async (cashBookDemand) => {
    if ((await handleCashlimitCheck(userData?.id, cashBookDemand)) === false) {
      console.log("SONI");
      toast.error("Cashbook Limit is expired");
    } else {
      let datas = cashBookDemand;
      datas.amount = Number(cashBookDemand.amount);
      await VendorService.saveDemand(datas)
        .then((response) => {
          if (response?.data.code == 200) {
            toast.success("Cashbook Demand Created Successfully");
            onAddCallBack();
            onClose();
            getAllCashbookList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const handleValidateForm = (cashBookDemand) => {
    if (edit == false) {
      if (handleValidate()) return false;
      if (condition == "true") {
        condition = "false";
      saveDemand(cashBookDemand);
      }
    } else {
      updateDemand(cashBookDemand?.id, cashBookDemand);
    }
  };
  const DataPush = (events) => {
    setCashBookDemand({
      ...cashBookDemand,
      is_active: events.target.value == "true" ? true : false,
    });
  };

  // useEffect(() => {
  //   if (editModuleData) {
  //     console.log(editModuleData, "editModuleData");
  //     // setCashBookDemand(editModuleData);
  //     Object.keys(editModuleData).forEach((item) => {
  //       let value = options?.filter((obj) => {
  //         if (obj.value === editModuleData[item]) {
  //           return true;
  //         }
  //       })[0];
  //       setIdProofValue(value);
  //       // console.log(frameElement,'finaldatadone')
  //       if (item === "doc_type") {
  //         let value = options?.filter((obj) => {
  //           if (obj.value === editModuleData[item]) {
  //             return true;
  //           }
  //         })[0];
  //         setIdProofValue(value);
  //       }
  //       if (item === "doc_no") {
  //         let value = makingType?.filter((obj) => {
  //           if (obj.value === Number(editModuleData[item])) {
  //             return true;
  //           }
  //         })[0];
  //         setMakingValue(value);
  //         setCashBookDemand((prev) => ({
  //           ...prev,
  //           doc_no: value?.value,
  //         }));
  //       } else {
  //         cashBookDemand[item] = editModuleData[item];
  //       }
  //     });
  //   }
  // }, [editModuleData]);
  const maxAmount = (e) => {
    let maxAmt = beneficiaryData[0]?.cash_limit;
    if (maxAmt < e.target.value) {
    } else {
      setCashBookDemand({
        ...cashBookDemand,
        amount: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (editModuleData) {
      setCashBookDemand(editModuleData);
      Object.keys(editModuleData).forEach((item) => {
        if (item === "doc_type") {
          let value = options?.filter((obj) => {
            if (obj.value === editModuleData[item]) {
              return true;
            }
          })[0];
          setIdProofValue(value);
        }

        if (item === "beneficiary_user_id") {
          let value = beneficiaryData?.filter((obj) => {
            if (obj.value === Number(editModuleData[item])) {
              return true;
            }
          })[0];
          setBenValue(value);
          setCashBookDemand((prev) => ({
            ...prev,
            beneficiary_user_id: value?.value,
          }));
        } else {
          cashBookDemand[item] = editModuleData[item];
        }
      });
    }
  }, [editModuleData, beneficiaryData]);
  useEffect(() => {
    getMasterData(userData?.id);
  }, []);
  useEffect(() => {
    if (masterData[0]?.id !== undefined) {
      getBeneficiaryData(masterData[0]?.id, userData?.id, "false");
    }
  }, [masterData]);

  // useEffect(() => {
  //   setCashBookDemand({ ...cashBookDemand, doc_no: makingType });
  // }, [makingType]);
  // useEffect(() => {

  //   if (
  //     cashBookDemand.beneficiary_user_id !== "" &&
  //     cashBookDemand.doc_type !== ""
  //   ) {
  //     getDocDetails(
  //       cashBookDemand.beneficiary_user_id,
  //       cashBookDemand.doc_type
  //     );
  //   }
  // }, [cashBookDemand]);
  useEffect(() => {
    getDocDetails(cashBookDemand.beneficiary_user_id);
  }, [cashBookDemand]);

  return (
    <div>
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {edit === true ? "Edit" : "Add"} Cash Demand
          </Modal.Title>
        </Modal.Header>
        <ToastContainer autoClose={1000} />
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="tickethistorbg mt-2">
                    <div class="row viewformaftersubmit">
                      <div class="col-md-4">
                        <div class="innergroup">
                          <p>Cashbook </p>
                          <label>{masterData[0]?.desc}</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="innergroup">
                          <p>Current Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                            {masterData[0]?.current_balance.toFixed(2)}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="innergroup">
                          <p>Provision Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                            {masterData[0]?.provision_balance.toFixed(2)}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Beneficiary<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={beneficiaryData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="beneficiary_user_id"
                      onChange={(e) => {
                        pushBenId(e);
                        setFormValidation({
                          ...formValidation,
                          beneficiary_user_id: "",
                        });
                      }}
                      value={benValue}
                    />
                    <div className="small text-danger">
                      {formValidation.beneficiary_user_id}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      ID Proof<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={benType}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="doc_type"
                      onChange={(e) => {
                        pushIdProof(e);
                        setFormValidation({
                          ...formValidation,
                          doc_type: "",
                        });
                      }}
                      value={idProofValue}
                    />
                    {/* <select class="form-control newbgselect">
                      <option value="">Select</option>
                      <option value="105125">PAN Card</option>
                    </select> */}
                    <div className="small text-danger">
                      {formValidation.doc_type}
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Doc No.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      namr="doc_no"
                      class="form-control"
                      disabled
                      value={cashBookDemand?.doc_no}
                      placeholder="Enter Doc No."
                    />
                    {console.log(makingType, "kanusoni")}
                  </div>
                  <div className="small text-danger">
                    {formValidation.doc_no}
                  </div>
                  {/* 
                  {makingType == undefined ? (
                    <div className="small text-danger">*Doc No is Required</div>
                  ) : (
                    ""
                  )} */}
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Amount"
                      // max={beneficiaryData[0]?.cash_limit}
                      name="amount"
                      maxLength={15}
                      onChange={(e) => {
                        maxAmount(e);
                        setFormValidation({
                          ...formValidation,
                          amount: "",
                        });
                      }}
                      value={cashBookDemand?.amount}
                    />
                    <div className="small text-danger">
                      {formValidation.amount}
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Remark<span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      maxLength={500}
                      placeholder="Enter Remarks"
                      value={cashBookDemand?.remarks}
                      onChange={(e) => {
                        setCashBookDemand({
                          ...cashBookDemand,
                          remarks: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          remarks: "",
                        });
                      }}
                    ></textarea>
                    <div className="small text-danger">
                      {formValidation.remarks}
                    </div>
                  </div>
                </div>
                <div></div>
                <div class="col-md-12">
                  <span
                    style={{
                      float: "right",
                      fontSize: "13px",
                      fontWeight: "700",
                    }}
                  >
                    {console.log(beneficiaryData, "beneficiaryData")}
                    Cashbook Limit :{masterData[0]?.cash_limit} (
                    {masterData[0]?.frequency_type == "W"
                      ? "Weekly"
                      : "Monthly"}
                    , {masterData[0]?.frequency})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => onClose()}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              handleValidateForm(cashBookDemand);
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCashBookDemand;
