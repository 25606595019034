import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import * as PrService from "../../service/prService";
import CustomPagination from "../CashbookProfile/CustomPagination";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
import { Tooltip } from "react-tippy";
import * as searchPoService from "../../service/searchpo";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

function PRlist() {
  const initialValues = {
    list: [],
    totalPages: 0,
    query: { search: "", page_size: 10, page_no: 1, paginate: true, pr_id: 0 },
  };
  const [data, setData] = useState(initialValues);
  const userData = useSelector(selectUserData)
  const getData = async (query) => {
    try {
      const response = await PrService.getPrTransactions(query);
      if (response.data.code === 200) {
        setData({
          list: response?.data?.dataList?.result,
          totalPages: response?.data?.dataList?.paginated_data?.totalPages,
          query: {
            ...query,
            page_no: response?.data?.dataList?.paginated_data?.currentPage,
          },
        });
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    }
  };
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterState, setFilterState] = useState({});
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [prTypeOptions, setPrTypeOptions] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('');
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('');
  const [nextSapApprovalPostingTime, setNextSapApprovalPostingTime] = useState('');
  const [nextELNSyncingTime, setNextELNSyncingTime] = useState('');

  const handlePageClick = (page) => {
    let activePage = page.selected + 1;
    const query = { ...data.query, page_no: activePage };
    getData(query);
  };

  const handlePageCountChange = (count) => {
    const query = { ...data.query, page_size: count };
    getData(query);
  };

  const handleSearch = (text) => {
    if (text.length > 2 || text.length === 0) {
      const query = { ...data.query, search: text };
      getData(query);
    }
  };

  const handleApplyFilter = () => {
    setFilterApplied(true);
    const query = { ...data.query, ...filterState };
    query.page_no = 1;
    getData(query);
    filterclose();
  };

  const handleClearFilter = () => {
    setFilterApplied(false);
    setFilterState({});
    getData({
      search: data.query.search,
      page_size: data.query.page_size,
      page_no: 1,
      paginate: true,
    });
    filterclose();
  };

  const getSelectFeilds = (options, key) => {
    return {
      value: options
        ? prTypeOptions.find((option) => option.value === filterState[key])
        : "",
      onChange: (option) =>
        setFilterState((pre) => ({ ...pre, [key]: option?.value })),
    };
  };

  const getPrTypes = () => {
    PrService.getPrTypes("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.pr_desc}(${item.pr_code})`,
          }));
          setPrTypeOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.message);
      });
  };

  const getExportDetails = () => {
    PrService.getExportValuePrList(data.query)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PrList.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  useEffect(() => {
    getData(data.query);
    getSapSchedule()
  }, []);

  useEffect(() => {
    if (show && prTypeOptions.length === 0) {
      getPrTypes();
    }
  }, [show]);

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_pr_on_sap_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_sap_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_eln_data').then((response) => {
      let next_eln_syncing_time = response?.data?.dataList?.result
      setNextELNSyncingTime(next_eln_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_post_approved_pr_item_in_sap').then((response) => {
      let next_sap_approval_syncing_time = response?.data?.dataList?.result
      setNextSapApprovalPostingTime(next_sap_approval_syncing_time)
    })
  }

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/create-pr">Create PR</Link>
            </li>
            <li class="active">
              <Link to="/pr-list">My PR List</Link>
            </li>
          
            <li>
              <Link to="/pr-unit-master">Masters</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">My PR List</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>My PR List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <Link to="/create-pr" class="btn btn-primary-inner" state={{ createFlag: true }}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </Link>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next ELN Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextELNSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Posting of Approved Items:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapApprovalPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    {/* <th>Item No.</th> */}
                    {/* <th>Action</th> */}
                    <th>Action</th>
                    <th>PR Type</th>
                    <th>Creation Date</th>
                    <th>SAP PR No.</th>
                    <th>Source</th>
                    <th>Status</th>
                    <th>SAP Status</th>
                    <th>SAP Response</th>
                    {/* <th>Pending at</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(data.list) &&
                    data.list.map((item, i) => (
                      <tr key={`pr-list-${i}`}>

                        {/* <td>  {(item?.Draft||item?.status == "D") &&
                        <Link to="/create-pr"  state={{from: item}}>
                        <span
                            class="btn btn-blanktd text-primary f-12"
                          >
                            <i class="far fa-edit"></i>
                          </span>
                    </Link>
                          
                          }
                          </td> */}
                        {console.log("============", item.items)}
                        <td class="fw-bold">
                          <Link
                            state={{ from: item, Itemsflag: false, viewPRFlag: true, isDraft: item?.Draft, header_text: item?.header_text, source: item?.source, currentApprover: item?.items }}
                            to="/create-pr"
                            class="text-theme"
                          >
                            PRNO{item?.id}
                          </Link>
                        </td>
                        {/* <td>{item?.pr_id}</td> */}
                        <td>{item?.type}</td>
                        <td>
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td>{item?.sap_pr_no}</td>
                        {/* <td>0015704032</td> */}
                        {item?.source === "M" ?
                          <td>MYPI</td>
                          : item?.source === "E" ?
                            <td>ELN</td>
                            : item?.source === "S" ?
                              <td>SAP</td>
                              :
                              <td>{item?.source}</td>
                        }
                        {/* <td>SAP</td> */}
                        <td>
                          {item?.Draft == true ?
                            <span class="badge bad-status badge-warning">
                              Draft
                            </span> : item?.status == "A" ? (
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            ) : item?.status == "D" ? (
                              <span class="badge bad-status badge-danger">
                                Rejected
                              </span>
                            ) : item?.status == "P" && item?.level == "User" ? (
                              <span class="badge bad-status badge-warning">
                                Submitted
                              </span>
                            ) : item?.status == "P" &&
                              item?.level == "Reviewer" ? (
                              <span class="badge bad-status badge-warning">
                                Partially Approved
                              </span>
                            ) : (
                              ""
                            )}
                        </td>
                        {/* <td>{item?.currentApprover?.approver_name}</td> */}
                        {item?.sap_status === false ?
                          <td>Pending to Sync</td>
                          :
                          <td>Synced</td>
                        }
                        {item?.log !== null ? (
                          <td>
                            {item?.log?.length > 15 ?
                              <Tooltip title={item?.log} position="bottom">
                                {item?.log?.substr(0, 15)}...
                              </Tooltip>
                              :
                              item?.log
                            }
                          </td>
                        ) :
                          <td></td>
                        }
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <CustomPagination
              onChange={(e) => handlePageCountChange(e.target.value)}
              handlePageClick={(page) => handlePageClick(page)}
              pageCount={data.totalPages}
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              PR ID<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter PR ID"
              value={filterState.prId}
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  prId: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>
              SAPPR NO<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter SAP PR NO"
              value={filterState.sap_pr_no}
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  sap_pr_no: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>Start Date</label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  start_date: e.target.value,
                })
              }
              value={filterState.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>End Date</label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              onChange={(e) =>
                setFilterState({
                  ...filterState,
                  end_date: e.target.value,
                })
              }
              value={filterState.end_date}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>Creation Date</label>
            <input
              type="date"
              class="form-control"
              placeholder="Enter Description"
              value={filterState.created_date}
              onChange={(e) =>
                setFilterState((pre) => ({
                  ...pre,
                  created_date: e.target.value,
                }))
              }
            />
          </div> */}
          <div class="form-group innergroup">
            <label>Select Source</label>
            <select
              class="form-control newbgselect"
              value={filterState.source}
              onChange={(e) =>
                setFilterState((pre) => ({ ...pre, source: e.target.value }))
              }
            >
              <option>Select</option>
              <option value={"M"}>MyPI</option>
              <option value={"E"}>ELN</option>
              <option value={"SAP"}>SAP</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select PR Type</label>
            <Select
              options={prTypeOptions}
              classNamePrefix="select"
              className="basic-multi-select"
              {...getSelectFeilds(prTypeOptions, "pr_type_id")}
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              value={setFilterState.status}
              onChange={(e) =>
                setFilterState((pre) => ({ ...pre, status: e.target.value }))
              }
            >
              <option>Select</option>
              <option value={"A"}>Approved</option>
              <option value={"P"}>Pending</option>
              <option value={"D"}>Rejected</option>
              {/* <option value={"Partially Approved"}>Partially Approved</option> */}
              <option value={"Draft"}>Pending for Submission</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={handleClearFilter}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={handleApplyFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PRlist;
