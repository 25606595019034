import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";



export const getTerritoryCust = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/et_form/get_territory_customer${query}`);
};

export const getTerritoryList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/et_form/get_pending_territory_list${query}`);
};

export const getTerritoryEmp = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/et_form/get_territor_et_list${query}`);
};

export const getETList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/et_form/get_data_for_request_create${query}`);
};


export const postETCreation = async (query,body) =>{
    return axios.post(`${AUTH_BASE_URL}/et_form/create_request${query}`,body);
}

export const postETUpdate = async (query,body) =>{
    return axios.post(`${AUTH_BASE_URL}/et_form/update_request${query}`,body);
}


export const getReqListById = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/et_form/get_data_by_request_id${query}`)
}
export const getReqList = async () =>{
    return axios.get(`${AUTH_BASE_URL}/et_form/get_request_list`)
}


export const getRole = async () =>{
    return axios.get(`${AUTH_BASE_URL}/et_form/get_role`)
}

export const getMonthYear = async () =>{
    return axios.get(`${AUTH_BASE_URL}/et_form/get_current_month_year`)
}