import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as HSNService from "../../service/hsnService";
import { toast } from "react-toastify";

const AddMatHsn = (props) => {
  const {
    addnewclose,
    editFlag,
    editHsnData,
    getHsnListData,
    addnewshow,
    setEditFlag,
  } = props;
  const [plantList, setPlantList] = useState();
  const [plantListValue, setPlantListValue] = useState();
  const [matType, setMatType] = useState();
  const [matTypeValue, setMatTypeValue] = useState();
  const [materialValue, setMaterialValue] = useState();
  const [materialList, setMaterialList] = useState();
  const [showDescHsn, setShowDescHsn] = useState();
  const [hsnList, setHsnList] = useState();
  const [hsnValue, setHsnValue] = useState();
  const [matTypeName, setMatTypeName] = useState();
  const [editableHsn, setEditableHsn] = useState(false);
  const [hsnData, setHsnData] = useState({
    material_id: 0,
    material_type_id: 0,
    hsn_code: 0,
    hsn: 0,
    desc: "",
    plant_id: "",
  });
  const [error, setError] = useState({
    material_id: "",
    material_type_id: "",
    hsn_code: "",
    desc: "",
    plant_id: "",
  });

  const saveHsnDataList = (data) => {
    HSNService.saveHsnDataList(data)
      .then((response) => {
        toast.success("HSN Request Submitted Successfully")
        getHsnListData(1, 10);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateHsnDataList = (id, data) => {
    HSNService.updateHsnDataList(id, data)
      .then((response) => {
        getHsnListData(1, 10);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getMaterialTypePlant = () => {
    HSNService.getMaterialTypePlant()
      .then((response) => {
        let matData = response?.data?.dataList?.result?.mat_type?.map(
          (item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }
        );
        setMatType(matData);
        let data = response?.data?.dataList?.result?.plant?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getMaterialList = (plant_id, material_type, page_no, page_size,search) => {
    HSNService.getMaterialList(plant_id, material_type, page_no, page_size,search)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code,
            descData: res.description,
          };
        });
        data.unshift({ value: "", label: "Select" });
        setMaterialList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getHsnList = () => {
    HSNService.getHsnList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.desc + "(" + res.code + ")",
            code: res.code
          };
        });
        data.unshift({ value: "", label: "Select" });
        setHsnList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleMaterialCode = () => {
    if (matTypeName !== "" && hsnData?.plant_id !== 0) {
      getMaterialList(hsnData?.plant_id, encodeURIComponent(matTypeName), 1, 10,'');
    }
  };

  const handleSaveData = () => {
    if (handleValidate()) return false;
    if (hsnData.id == undefined) {
      saveHsnDataList(hsnData);
      setHsnData();
    } else {
      updateHsnDataList(hsnData.id, hsnData);
    }
    addnewclose();
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "material_id":
        setHsnData((prev) => ({
          ...prev,
          material_id: event.value,
        }));
        setShowDescHsn(event.descData);
        break;
      case "material_type_id":
        setHsnData((prev) => ({
          ...prev,
          material_type_id: event.value,
        }));
        setMatTypeName(event.label);
        break;
      case "hsn_code":
        setHsnData((prev) => ({
          ...prev,
          hsn_code: event.label,
          hsn: event.code
        }));
        break;
      case "new_hsn_code":
          setHsnData((prev) => ({
            ...prev,
            new_hsn_code: event,
          }));
          break;
      case "desc":
        setHsnData((prev) => ({
          ...prev,
          desc: event.target.value,
        }));
        break;
      case "plant_id":
        setHsnData((prev) => ({
          ...prev,
          plant_id: event.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...error };
    if (!hsnData.material_id) {
      isError = true;
      formerr.material_id = "*Material Code is required  ";
      setError(formerr);
    }
    if (!hsnData.hsn_code && !hsnData.new_hsn_code) {
      isError = true;
      formerr.hsn_code = "*HSN Code is required";
      setError(formerr);
    }
    if (!hsnData.desc) {
      isError = true;
      formerr.desc = " *Description is required";
      setError(formerr);
    }
    if (!hsnData.material_type_id) {
      isError = true;
      formerr.material_type_id = " *Material Type is required";
      setError(formerr);
    }
    if (!hsnData.plant_id) {
      isError = true;
      formerr.plant_id = " *Plant is required";
      setError(formerr);
    }
    return isError;
  };
  const onPushMatType = (newValue, name) => {
    handleChange(newValue, name);
    setMatTypeValue(newValue);
  };
  const onPushPlant = (newValue, name) => {
    handleChange(newValue, name);
    setPlantListValue(newValue);
  };

  const onPushMaterial = (newValue, name) => {
    handleChange(newValue, name);
    setMaterialValue(newValue);
  };
  const onPushHSN = (newValue, name) => {
    handleChange(newValue, name);
    setHsnValue(newValue);
  };

  useEffect(() => {
    if (editHsnData) {
      getMaterialList(
        editHsnData?.plant_id,
        encodeURIComponent(editHsnData?.material_type_name),
        1,
        10,
        ''
      );
      let data1 = {
        value: editHsnData.plant_id,
        label:
          editHsnData?.plant_description + "(" + editHsnData?.plant_code + ")",
      };
      let data2 = {
        value: editHsnData.material_type_id,
        label: editHsnData.material_type_name,
      };
      let data3 = {
        value: editHsnData.material_id,
        label: editHsnData?.material_code,
      };

      setMaterialValue(data3);
      setMatTypeValue(data2);
      setPlantListValue(data1);
      Object.keys(editHsnData).forEach((item) => {
        hsnData[item] = editHsnData[item];
      });
    }
  }, [editHsnData]);

  useEffect(() => {
    if (editFlag) {
      let dataValue = materialList?.filter((ele) => {
        if (ele?.label === editHsnData?.material_code) {
          return ele;
        }
      });
      setShowDescHsn(dataValue && dataValue[0]?.descData);

      let hsnDataVVVVVV = hsnList?.filter((item) => {
        if (item.value == Number(editHsnData.hsn_code)) {
          return item;
        }
      });
      setHsnValue(hsnDataVVVVVV);
      if(editHsnData?.new_hsn_code !== null){
        setEditableHsn(true)
        setHsnValue(editHsnData?.new_hsn_code);
      }
    }
  }, [materialList]);

  useEffect(() => {
    handleMaterialCode();
  }, [hsnData]);

  useEffect(() => {
    getMaterialTypePlant();
    getHsnList();
  }, []);
  return (
    <Modal
      show={addnewshow}
      onHide={addnewclose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add HSN Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Material Type<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={matType}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="material_type_id"
                    onChange={(newValue, action) => {
                      onPushMatType(newValue, action?.name);
                      setError({ ...error, material_type_id: "" });
                    }}
                    value={matTypeValue}
                  />
                  <div className="small text-danger">
                    {error?.material_type_id}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Plant<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={plantList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="plant_id"
                    onChange={(newValue, action) => {
                      onPushPlant(newValue, action?.name);
                      setError({ ...error, plant_id: "" });
                    }}
                    value={plantListValue}
                  />
                  <div className="small text-danger">{error?.plant_id}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Material Code<span class="text-danger">*</span>
                  </label>
                  <Select
                    options={materialList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="material_id"
                    onChange={(newValue, action) => {
                      onPushMaterial(newValue, action?.name);
                      setError({ ...error, material_id: "" });
                    }}
                    onInputChange={(e) => {
                      getMaterialList(
                        hsnData?.plant_id, encodeURIComponent(matTypeName), 1, 10,e
                      );
                    }}
                    value={materialValue}
                  />
                  <div className="small text-danger">{error?.material_id}</div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>Material Description</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Material Description"
                    disabled
                    value={showDescHsn}
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    HSN Code<span class="text-danger">*</span>
                  </label>
                  {editableHsn ?
                  <>
                  <input
                  // disabled={rejectFlag}
                    type="number"
                    className="form-control"
                    placeholder="Enter HSN Code"
                    onChange={(e) => {
                      onPushHSN(e.target.value, "new_hsn_code");
                      setError({ ...error, hsn_code: "" });
                    }}
                    value={hsnValue}
                    // {...getFieldProps(`${formIndex}.new_vendor`)}
                  />
                  <button type="button" className="btn btn-link" onClick={()=>{setEditableHsn(false)}}>Exist HSN</button>
                  </>
                  :
                  <>
                  <Select
                    options={hsnList}
                    className="basic-multi-select"
                    name="hsn_code"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      onPushHSN(newValue, action?.name);
                      setError({ ...error, hsn_code: "" });
                    }}
                    value={hsnValue}
                  />
                  <button type="button" className="btn btn-link" onClick={()=>{setEditableHsn(true)}}>New HSN Code</button>
                  </>
                  }
                  <div className="small text-danger">{error?.hsn_code}</div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label>
                    Technical Description<span class="text-danger">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    placeholder="Enter Technical Description"
                    name="desc"
                    onChange={(newValue, action) => {
                      handleChange(newValue, action?.name, "", "");
                      setError({ ...error, desc: "" });
                    }}
                    value={hsnData?.desc}
                  ></textarea>
                  <div className="small text-danger">{error?.desc}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          class="btn btn-outline-danger"
          onClick={() => {
            addnewclose();
            setEditFlag(false);
          }}
        >
          Cancel
        </button>
        <button
          class="btn btn-primary-inner bpi-main"
          onClick={() => handleSaveData()}
        >
          {editFlag === true ? "Update" : "Submit"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMatHsn;
