import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const saveRoleRights = async (savedata) => {
  return axios.post(`${AUTH_BASE_URL}/groups`, savedata);
};

export const getPermissionValue = async (searchstr) => {
  return axios.get(
    `${AUTH_BASE_URL}/permissions/app_permissions?search=${searchstr}`
  );
};

export const isRoleExist = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/groups/exists`, isData);
};

export const saveRightsPermission = async (pData) => {
  return axios.post(
    `${AUTH_BASE_URL}/permissions/add_permission_to_group`,
    pData
  );
};

export const getGroupValue = async (search, pageNo, pageSize, sortBy) => {
  return axios.get(
    `${AUTH_BASE_URL}/groups?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}`
  );
};

export const saveUserDataId = async (uData) => {
  return axios.post(
    `${AUTH_BASE_URL}/permissions/get_group_permissions`,
    uData
  );
};

export const deleteGroupData = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/groups`,
    method: "DELETE",
    data: id,
  });
};

export const removePermissions = async (rData) => {
  return axios.post(
    `${AUTH_BASE_URL}/permissions/remove_group_permissions`,
    rData
  );
};

export const updateGroup = async (savedata) => {
  return axios.put(`${AUTH_BASE_URL}/groups`, savedata);
};

export const autoRuleCreate = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/groups/auto-assign`, data);
};

export const autoRuleUpdate = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/groups/auto-assign`, data);
};

export const autoRuleDelete = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/groups/auto-assign/${id}`);
};

export const getAutoRoleRecords = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/groups/auto-assign?${queryParm}`);
};

export const getExportData = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/groups/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getEmpSubGroups = async (data) => {
  return axios.get(`${AUTH_BASE_URL}/groups/getEmp_subGroup`, data);
};

export const getTableList = async () => {
  return axios.get(`${AUTH_BASE_URL}/groups/get_table_list`);
};

export const getGroupList = async () => {
  return axios.get(`${AUTH_BASE_URL}/groups/get_role_list`);
};

export const submitCustomRole = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/groups/custom_role`, data);
};

export const getCustomRole = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/groups/custom_role?${queryParm}`);
};

export const updateCustomRole = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/groups/custom_role/${id}`, data);
};

export const deleteCustomRole = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/groups/custom_role`,
    method: "DELETE",
    data: id,
  });
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/groups/custom_role/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
