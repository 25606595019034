import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  getTerritory,
} from "../../../../service/AgriPortalService/MarketSizeService";
import styles from "./Root.module.css";
import { getAGCFY, getAGReqList } from "../../../../service/AgriPortalService/AGSales/Agsales";

import {
  getRegionDropDown,
  getTerritoryDropDown,
  getZoneDropDown,
} from "../../../../service/AgriPortalService/CommonService";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader, setUserData } from "../../../redux/piDataStore";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getUserDetails } from "../../../../service/header";

const Root = (props) => {
  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //To contain territory dropdown state
  const [territories, setTerritories] = useState();
  const [territory, setTerritory] = useState();
  //To contain market player data
  const [marketSizeData, setMarketSizeData] = useState([]);
  const [agList, setAgList] = useState([]);
  const [marketSizeSelectedData, setMarketSizeSelectedData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAddNew, setShowAddNew] = useState(true);
  const [selectedZone, setSelectedZone] = useState("ALL");
  const [selectedRegion, setSelectedRegion] = useState("ALL");
  const [selectedTerritory, setSelectedTerritory] = useState("ALL");

  const [cfy, setCfy] = useState("");
  useEffect(() => {
    localStorage.setItem("territory", JSON.stringify(territory));
    getAGCFY()
      .then((res) => {
        setCfy(res.data.fy)
      }
      ).catch((err) => {
        toast.error("No data found!");
        console.log(err)
      })

  }, [territory]);

  //Load territory dropdown data
  useEffect(() => {
    getTerritory()
      .then((res) => {
        if (!res.data.dataList || res.data.dataList.length == 0) return;
        setTerritory(res.data.dataList[0]);
        setTerritories(res.data.dataList);
      })
      .catch((err) => {
        toast.error("No territory associated with your account!");
        console.log(err);
      });

  }, []);

  //Load market player data
  //Store territory to local storage
  useEffect(() => {
    getAGReqList().then((res) => {
      setAgList(res.data);
      getZRT()
        .then((re) => {
          console.log("zrt data", re?.data?.dataList)
          // setMarketSizeData(res?.data?.dataList);
          getAGCFY().then((r) => {
            setLoader(false);
            let flag = true;
            if (re?.data?.dataList != undefined && re.data.dataList.territory_id != null) {
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].cfy == r.data.fy) {
                  flag = false;
                  break;
                }
              }
            } else {
              flag = false;
            }

            setShowAddNew(flag);
          });

        })
        .catch((err) => {
          console.log(err);
        });
      setLoader(false);
    })
      .catch((err) => {
        setLoader(false);
        toast.error("Fetching Sales Data failed!");
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getZoneDropDown(false)
      .then((res) => {
        setZoneData((state) => {
          let newData = [
            {
              name: "All",
              code: "ALL",
              id: "ALL",
            },
          ];
          newData = [...newData, ...res.data.dataList];
          setSelectedZone(newData[0].id);
          return newData;
        });
        regionChangeHandler(-1);
      })
      .catch((err) => { });
  }, [agList, agList.length]);
  // }, [marketSizeData, marketSizeData.length]);

  const zoneChangeHandler = (e) => {
    if (e.target.value == "ALL") {
      setSelectedZone("ALL");
      setSelectedRegion("ALL");
      setSelectedTerritory("ALL");
      setRegionData([
        {
          name: "All",
          id: "ALL",
          code: "ALL",
        },
      ]);
    } else {
      setSelectedZone(e.target.value);
      getRegionDropDown(e.target.value)
        .then((res) => {
          setRegionData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                id: "ALL",
                code: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            setSelectedRegion(newData[0].id);
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const regionChangeHandler = (e) => {
    setSelectedTerritory("ALL");
    if (e == -1) {
      setRegionData([
        {
          name: "All",
          code: "ALL",
          id: "ALL",
        },
      ]);
      setSelectedRegion("ALL");
      setTerritoryData((state) => {
        let newData = [...state];
        newData = [
          {
            name: "All",
            code: "ALL",
            id: "ALL",
          },
        ];
        return newData;
      });
      territoryChangeHandler("ALL");
    } else {
      setSelectedRegion(e.target.value);
      getTerritoryDropDown(e.target.value)
        .then((res) => {
          setTerritoryData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                code: "ALL",
                id: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const territoryChangeHandler = (code) => {
    setSelectedTerritory(code);
    const zone =
      zoneData[zoneData.findIndex((el) => el.id == selectedZone)]?.code;
    const region =
      regionData[regionData.findIndex((el) => el.id == selectedRegion)]?.code;
  };

  function handleLoading() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
      if (agList.length == 0) {
        return (
          <div className={styles["nodata"]}>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              NO DATA YET! PLEASE ADD
            </h4>
          </div>
        );
      }
    }
  }
  const getUserDetail = () => {
    getUserDetails(0)
      .then((response) => {
        // dispatch(setUserData(response.data.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  useEffect(() => {
    if (selectedZone == "ALL") {
      setMarketSizeSelectedData(agList);
    } else if (selectedZone != "ALL" && selectedRegion == "ALL") {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        let code = zoneData.findIndex((el) => el.id == selectedZone);
        newData = agList.filter(
          (el) => el.zone_code == zoneData[code].code
        );
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory == "ALL"
    ) {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        let code = regionData.findIndex((el) => el.id == selectedRegion);
        newData = agList.filter(
          (el) => el.region_code == regionData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          marketSizeData,
          selectedRegion
        );
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory != "ALL"
    ) {
      setMarketSizeSelectedData((state) => {
        let newData = [...state];
        console.log(territoryData, selectedTerritory);
        let code = territoryData.findIndex(
          (el) => el.code == selectedTerritory
        );
        console.log(code);
        if (code == -1) return [];
        newData = agList.filter(
          (el) => el.territory_code == territoryData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          agList,
          selectedRegion
        );
        return newData;
      });
    }
  }, [selectedZone, selectedRegion, selectedTerritory, agList]);

  // useEffect(() => {
  //   console.log("DATA", marketSizeSelectedData);
  // }, [marketSizeSelectedData, marketSizeSelectedData.length]);

  //MARKUP JSX
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["agri-portal-controls"]}
          style={{ paddingBottom: "0" }}
        >
          <div>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              Sales Data
            </h4>
          </div>
          <div>
            <div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>CURRENT FY</b>
                </div>
                <div className={styles["valuebox"]}>{cfy}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div style={{ display: "flex", gap: "10px" }}>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
              }}
              value={selectedZone}
              // classNamePrefix="select"
              onChange={(e) => {
                zoneChangeHandler(e);
              }}
            >
              {zoneData &&
                zoneData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedZone == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedZone == "ALL"}
              // classNamePrefix="select"
              onChange={(e) => {
                regionChangeHandler(e);
              }}
            >
              {regionData &&
                regionData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedRegion == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedRegion == "ALL"}
              value={selectedTerritory}
              // classNamePrefix="select"
              onChange={(e) => {
                territoryChangeHandler(e.target.value);
              }}
            >
              {territoryData &&
                territoryData?.map((el) => {
                  return <option value={el.code} label={el.name} />;
                })}
            </select>
          </div>

          {showAddNew && (
            <div className={styles["right"]}>
              <div>
                <Link
                  to="/agsales/agtable?mode=create"
                  class="btn btn-primary-inner"
                >
                  Add New
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* {console.log("aglist", agList)} */}
        {marketSizeSelectedData != undefined && marketSizeSelectedData.length != 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div class="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Territory Code</th>
                      <th>Territory Name</th>
                      <th>Submited On</th>
                      <th>Submited By</th>
                      <th>Financial Year</th>
                      <th>Status</th>
                      <th>Remarks</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketSizeSelectedData && marketSizeSelectedData.length != 0 &&
                      marketSizeSelectedData.map((el) => {
                        let status;

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P")
                          status = `Pending with ${el.pending_with}(${el.pending_at})`;
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr
                            style={{
                              backgroundColor: el.for_my_approval
                                ? "#92ff40"
                                : "",
                              fontWeight: el.for_my_approval ? "bold" : "",
                            }}
                          >
                            <td>{el.territory_code}</td>
                            <td>{el.territory_name}</td>
                            <td>
                              {moment(el.created_at).format("DD-MM-YYYY")}
                            </td>
                            <td>{el.created_by_name}</td>
                            <td>{el.cfy}</td>
                            <td>{status}</td>
                            <td>-</td>
                            {(el.status == "D" ||
                              (el.status == "R" && el.created_by_me)) &&
                              el.status != "RP" && (
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    navigate(
                                      "/agsales/agtable?mode=edit&id=" +
                                      el.id +
                                      "&fy=" +
                                      el.cfy +
                                      "&territory_name=" +
                                      el.territory_name +
                                      "&region_name=" +
                                      el.region_name +
                                      "&zone_name=" +
                                      el.zone_name

                                    )
                                  }
                                >
                                  Edit
                                </td>
                              )}
                            {(el.for_my_approval != null && el.for_my_approval == true) ? (
                              (el.status == "P" ||
                                el.status == "A" ||
                                el.status == "RP" ||
                                (el.status == "R" && !el.created_by_me)) && (
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    navigate(
                                      "/agsales/agtable?mode=approve&id=" +
                                      el.id +
                                      "&fy=" +
                                      el.cfy +
                                      "&territory_name=" +
                                      el.territory_name +
                                      "&region_name=" +
                                      el.region_name +
                                      "&zone_name=" +
                                      el.zone_name
                                    )
                                  }
                                >
                                  View
                                </td>
                              )
                            ) :
                              (el.status == "P" ||
                                el.status == "A" ||
                                el.status == "RP" ||
                                (el.status == "R" && !el.created_by_me)) && (
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    navigate(
                                      "/agsales/agtable?mode=view&id=" +
                                      el.id +
                                      "&fy=" +
                                      el.cfy +
                                      "&territory_name=" +
                                      el.territory_name +
                                      "&region_name=" +
                                      el.region_name +
                                      "&zone_name=" +
                                      el.zone_name
                                    )
                                  }
                                >
                                  View
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {handleLoading()}
      </div>
    </div>
  );
};
export default Root;
