/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import * as LeaveService from "../../service/leaveService";
import "react-toastify/dist/ReactToastify.css";
import AuditLog from "../audit/auditlog";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getOrganisation } from "../../service/businessService";
import { getBusinessList } from "../../service/leaveService";
import { ToastContainer, toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
if (typeof window !== "undefined") {
  injectStyle();
}
const validationSchema = Yup.object({
  // leave_max_in_month: Yup.number().required(
  //   "*Maximum Leave in Month is required"
  // ),
  // // leave_allocated_days: Yup.number().required("*Leave Day's is required"),
  // leave_max_in_year: Yup.number().required(
  //   "**Maximum Leave in Year is required"
  // ),
  // joining_day: Yup.number().required("*Joining Day is required"),
  // allocate_type: Yup.string().required("*Select Allocate Type").nullable(),
});
const AddLeaveAllocation = (props) => {
  const nameForm = " Leave Allocation Master";
  const { edit, showForm, onClose, editLeaveManage, onAddCallBack, ruleList } =
    props;
  const dispatch = useDispatch();
  const [clubData, setClubData] = useState([]);
  const [clubDataList, setClubDataList] = useState([]);
  const [newAddLeave, setNewAddLeave] = useState([{ reason: "" }]);
  const [org, setOrg] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [orgDataList, setOrgDataList] = useState([]);
  const [dublicateOrgData, setDubOrgData] = useState([]);
  const [business, setBusiness] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [businessDataList, setBusinessDataList] = useState([]);
  const [leaveTypeData, setLeaveTypeData] = useState([]);
  const [approver, setApprover] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [leaveMaxError, setLeaveMaxError] = useState(false);
  const [maxLeavesAllowed, setMaxLeavesAllowed] = useState(0);
  const [psa, setPsa] = useState("");
  const [psaData, setPsaData] = useState("");
  const [psaList, setPsaList] = useState([]);
  const [disablePsa, setDisablePsa] = useState(false);
  const [validationError, setValidationError] = useState({
    month: false,
    year: false,
    leaves: false,
  });
  let condition = "true";

  const [leaveAllocationData, setleaveAllocationData] = useState({
    branch: [],
    psa: [],
    leave_type: 0,
    leave_allocated_days: "",
    leave_max_in_month: "",
    joining_day: "",
    approver_name: [],
    notice_period: false,
    allocate_type: "",
    leave_max_in_year: "",
    is_active: false,
  });

  const [formError, setFormError] = useState({
    leaveType: false,
    allocateType: false,
    org: false,
    businessUnit: false,
    psa: false,
    leaveDays: false,
    yearMax: false,
    oneTimeMax: false,
    joiningDay: false,
    approver: false,
    jDay: false,
    leaveLimit: false,
  });

  const [ruleGender, setRuleGender] = useState("");

  function leaveDetail() {
    const arrayLeave = [];
    if (editLeaveManage) {
      for (let i = 0; i < editLeaveManage.reasons.length; i++) {
        arrayLeave.push({
          reason: editLeaveManage.reasons[i].reason,
        });
      }
      setNewAddLeave(arrayLeave);
    }
  }

  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      let organisation = [];
      let organisation2 = [];
      response?.data?.dataList?.result.map((x) => {
        let data = { value: x.id, label: `${x.name} (${x?.code})` };
        organisation.push(data);
        organisation2.push(data);
      });
      setDubOrgData(organisation2);
      // let all = { value: "All", label: "All" };
      // organisation.push(all);
      setOrgList(organisation);
    });
  };
  const getBusinessUnitList = (paginate, organisation_id) => {
    if (organisation_id?.length > 0) {
      getBusinessList(paginate, organisation_id).then((response) => {
        let business = [
          {
            label: "ALL",
            value: "ALL",
          },
        ];
        response?.data?.dataList?.result.map((x) => {
          let data = { value: x.id, label: `${x.name} (${x?.code})` };
          business.push(data);
        });
        setBusiness(business);
      });
    } else {
      setBusiness([]);
    }
  };
  const getleaveAllocationList = (paginate) => {
    let except = ["LWP", "PLTA"];
    let query = `sort_by=code&paginate=${false}`;
    LeaveService.getLeaveManageListUser(query)
      .then((response) => {
        let res = response?.data?.dataList?.result.filter((obj) => {
          if (except.includes(obj?.code)) {
            return false;
          } else {
            return true;
          }
        });
        setLeaveTypeData(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const pushOrg = (e) => {
    setOrgData([e]);
  };

  const pushBusiness = (e) => {
    setBusinessData([e]);
    if (e?.value === "ALL") {
      setBusinessDataList(
        business
          ?.filter((obj) => {
            if (obj?.value !== "ALL") {
              return true;
            }
          })
          .map((obj) => {
            return obj?.value;
          })
      );
      pushPsa({
        label: "ALL",
        value: "ALL",
      });
      setDisablePsa(true);
    } else {
      setDisablePsa(false);
      setBusinessDataList([e?.value]);
    }
  };

  const pushPsa = (e) => {
    setPsa(e);
    setPsaData(e?.value);
  };

  const saveLeaveAllocation = (values) => {
    if (condition === "true") {
      condition = "false";
      setIsLoading(true);
      dispatch(setDisplayLoader("Display"));
      onAddCallBack();
      let datass = JSON.parse(JSON.stringify(values));
      datass.approver_name = approver;
      datass.branch = businessDataList;
      datass.leave_type = parseInt(datass.leave_type);
      LeaveService.saveLeaveAllocation(datass)
        .then((response) => {
          if (response.data.code == 200) {
            dispatch(setDisplayLoader("Hide"));
            condition = "true";
            onAddCallBack(response);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.warning(error.response.data.message);
          if (error?.response?.status === 404) {
            toast.success("Leave Rule Created.");
          }
          onAddCallBack();
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const leaveAllocationListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`leaveAllocation${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
        leave={`${item?.no_of_days}`}
        gender={
          item?.gender === "M"
            ? "Male"
            : item?.gender === "F"
            ? "Female"
            : "All"
        }
        max={`${item?.no_of_days}`}
      >{`${item?.name}`}</option>
    ));
  };

  useEffect(() => {
    getOrganisationList();
    getleaveAllocationList(false);
  }, []);

  useEffect(() => {
    let ids = orgData?.map((obj) => {
      return obj?.value;
    });
    getBusinessUnitList(false, ids);
  }, [orgData]);

  useEffect(() => {
    if (businessData?.length > 0) {
      let bu = businessData[0]?.value === "ALL" ? 0 : businessData[0]?.value;
      LeaveService.getPersonalSubArea(bu).then((response) => {
        if (response?.status === 200) {
          let res = [
            {
              label: "ALL",
              value: "ALL",
            },
          ];
          response?.data?.dataList?.result.map((obj) => {
            res.push({
              label: obj?.name,
              value: obj?.id,
            });
          });
          setPsaList(res);
        }
      });
    }
  }, [businessData]);

  useEffect(() => {
    if (editLeaveManage && leaveTypeData?.length > 0) {
      // let datas = editLeaveManage;
      // let clubList = [];
      // datas?.club?.map((x) => {
      //   let data1 = { value: x.club_with, label: x.club_with_name };
      //   clubList.push(data1);
      // });
      // if (clubDataList !== null && clubDataList.length > 0) {
      //   setClubDataList([...clubDataList, clubList.value]);
      // }
      // setClubData(clubList);
      // setleaveAllocationData(editLeaveManage);
      // Object.keys(editLeaveManage).forEach((item) => {
      //   leaveAllocationData[item] = editLeaveManage[item];
      // });
      // leaveDetail();
      let selectedLeave = leaveTypeData?.filter((obj) => {
        if (obj?.id === editLeaveManage?.leave_type_id) {
          return true;
        }
      });
      setMaxLeavesAllowed(editLeaveManage?.leave_allocated_days);
      setRuleGender(
        selectedLeave[0]?.gender === "A"
          ? "All"
          : selectedLeave[0]?.gender === "M"
          ? "Male"
          : "Female"
      );
      setleaveAllocationData((prev) => ({
        ...prev,
        id: editLeaveManage?.id,
        leave_type: editLeaveManage?.leave_type_id,
        allocate_type: editLeaveManage?.allocate_type,
        notice_period: editLeaveManage?.notice_period,
        joining_day: editLeaveManage?.joining_day,
        leave_max_in_month: editLeaveManage?.leave_max_in_month,
        leave_allocated_days: editLeaveManage?.leave_allocated_days,
        approver_name: editLeaveManage?.approver_name,
        leave_max_in_year: editLeaveManage?.year_max,
        is_active: editLeaveManage?.is_active,
      }));
      let bu = editLeaveManage?.branch?.map((obj) => {
        return {
          label: obj?.name,
          value: obj?.id,
        };
      });
      let appr = approvers
        .filter((obj) => {
          if (editLeaveManage?.approver_name.includes(obj?.value)) {
            return true;
          }
        })
        .map((obj) => obj);
      setOrgData(
        editLeaveManage?.organization.map((obj) => {
          return {
            label: `${obj?.name} (${obj?.code})`,
            value: obj?.id,
          };
        })
      );
      if (bu?.length > 1) {
        setBusinessData({
          label: "ALL",
          value: "ALL",
        });
        setPsa({
          label: "ALL",
          value: "ALL",
        });
        setBusinessDataList(
          bu
            ?.filter((obj) => {
              if (obj?.value !== "ALL") {
                return true;
              }
            })
            .map((obj) => {
              return obj?.value;
            })
        );
      } else {
        if (
          editLeaveManage?.personalSubArea?.length === 0 ||
          editLeaveManage?.personalSubArea?.length === 0
        ) {
          setPsa({
            label: "ALL",
            value: "ALL",
          });
        } else {
          setPsa({
            label: editLeaveManage?.personalSubArea[0]?.name,
            value: editLeaveManage?.personalSubArea[0]?.id,
          });
        }
        setBusinessData(bu);
        setBusinessDataList([bu[0]?.value]);
      }
      setOrg(appr);
      setApprover([appr[0]?.value]);
    }
  }, [editLeaveManage, leaveTypeData]);

  const pushApprover = (e) => {
    let ids = e.map((data, i) => {
      return data?.value;
    });
    setApprover(ids);
    setOrg(e);
  };

  const approvers = [
    { value: "RM", label: "Reporting Manager" },
    { value: "HOD", label: "HOD" },
    { value: "HR", label: "HR" },
  ];

  const updateRule = async (data) => {
    dispatch(setDisplayLoader("Display"));
    onAddCallBack();
    await LeaveService.updateLeaveRule(data).then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        onAddCallBack(response);
        toast.success("Leave Rule Updated Successfully.");
      }
    });
  };

  function handleData(values) {
    if (!edit) {
      let existCheck = ruleList?.filter((obj) => {
        if (
          obj?.leave_type_id === Number(leaveAllocationData?.leave_type) &&
          (obj?.branch[0]?.id === businessDataList[0] ||
            obj?.branch?.length > 1) &&
          (obj?.organization[0]?.id === orgData[0] ||
            obj?.organization?.length > 1) &&
          obj?.allocate_type === leaveAllocationData?.allocate_type
        ) {
          return true;
        }
      });
      if (existCheck?.length > 0) {
        toast.warning(
          "Leave Rule for selected Leave type and Business Unit exists."
        );
      } else {
        let saveData = {
          organization: orgData,
          branch: businessDataList,
          psa: psaData === "ALL" ? 0 : psaData,
          leave_type: leaveAllocationData.leave_type,
          leave_allocated_days: leaveAllocationData.leave_allocated_days,
          leave_max_in_month: leaveAllocationData.leave_max_in_month,
          joining_day: leaveAllocationData.joining_day,
          approver_name: approver,
          notice_period: leaveAllocationData.notice_period,
          allocate_type: leaveAllocationData.allocate_type,
          year_max: leaveAllocationData.leave_max_in_year,
          gender: ruleGender,
          is_active: leaveAllocationData?.is_active,
        };
        saveLeaveAllocation(saveData);
      }
    } else {
      let saveData = {
        id: leaveAllocationData?.id,
        organization: orgData,
        branch: businessDataList,
        pas: psaData === "ALL" ? 0 : psaData,
        leave_type: leaveAllocationData.leave_type,
        leave_allocated_days: leaveAllocationData.leave_allocated_days,
        leave_max_in_month: leaveAllocationData.leave_max_in_month,
        year_max: leaveAllocationData.leave_max_in_year,
        joining_day: leaveAllocationData.joining_day,
        approver_name: approver,
        notice_period: leaveAllocationData.notice_period,
        allocate_type: leaveAllocationData.allocate_type,
        is_active: leaveAllocationData?.is_active,
        gender:
          ruleGender === "Male" ? "M" : ruleGender === "Female" ? "F" : "A",
      };
      updateRule(saveData);
    }
  }

  const checkValidation = (e, type) => {
    switch (type) {
      case "year":
        if (
          e.target.value !== "" &&
          (Number(e.target.value) >
            Number(leaveAllocationData?.leave_allocated_days) ||
            Number(e.target.value) <
              Number(leaveAllocationData?.leave_max_in_month))
        ) {
          setValidationError((prev) => ({
            ...prev,
            year: "Value must be less than leave days and greater than maximum leaves in one time.",
          }));
        } else {
          setValidationError((prev) => ({
            ...prev,
            year: "",
          }));
        }
        break;
      case "month":
        if (
          e.target.value !== "" &&
          (Number(e.target.value) >
            Number(leaveAllocationData?.leave_allocated_days) ||
            Number(e.target.value) >
              Number(leaveAllocationData?.leave_max_in_year))
        ) {
          setValidationError((prev) => ({
            ...prev,
            month: "Value must be <= leave days and maximum leaves in year.",
          }));
        } else {
          setValidationError((prev) => ({
            ...prev,
            month: "",
          }));
        }
        break;
      case "leave_allocated_days":
        if (e.target.value < maxLeavesAllowed) {
          setValidationError((prev) => ({
            ...prev,
            leaves: true,
          }));
        } else {
          setValidationError((prev) => ({
            ...prev,
            leaves: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleErrors = (e, name) => {
    switch (name) {
      case "leaveType":
        if (e.target.value === "0") {
          setFormError((prev) => ({
            ...prev,
            leaveType: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            leaveType: false,
          }));
        }
        break;
      case "allocate_type":
        if (e.target.value === "") {
          setFormError((prev) => ({
            ...prev,
            allocateType: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            allocateType: false,
          }));
        }
        break;
      case "org":
        if (e.target.value === "" && orgData?.length === 0) {
          setFormError((prev) => ({
            ...prev,
            org: true,
          }));
        }
        break;
      case "branch":
        if (e.target.value === "" && businessData?.length===0) {
          setFormError((prev) => ({
            ...prev,
            businessUnit: true,
          }));
        }
        break;
      case "psa":
        if (e.target.value === "" && psa?.value === undefined) {
          setFormError((prev) => ({
            ...prev,
            psa: true,
          }));
        }
        break;
      case "leave_max_in_year":
        if (e.target.value === "") {
          setFormError((prev) => ({
            ...prev,
            yearMax: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            yearMax: false,
          }));
        }
        break;
      case "leave_allocated_days":
        if (e.target.value === "") {
          setFormError((prev) => ({
            ...prev,
            leaveDays: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            leaveDays: false,
          }));
        }
        break;
      case "leave_max_in_month":
        if (e.target.value === "") {
          setFormError((prev) => ({
            ...prev,
            oneTimeMax: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            oneTimeMax: false,
          }));
        }
        break;
      case "joining_day":
        if (e.target.value === "") {
          setFormError((prev) => ({
            ...prev,
            joiningDay: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            joiningDay: false,
          }));
        }
        break;
      case "approver":
        if (e.target.value === "" && org?.length === 0) {
          setFormError((prev) => ({
            ...prev,
            approver: true,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            approver: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Formik
        initialValues={leaveAllocationData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (
            Object.values(formError).includes(true) ||
            leaveAllocationData?.leave_type === 0
          ) {
            setFormError((prev) => ({
              ...prev,
              leaveType: leaveAllocationData?.leave_type === 0 ? true : false,
              allocateType:
                leaveAllocationData?.allocate_type === "" ? true : false,
              org: orgData?.length === 0 ? true : false,
              businessUnit: businessDataList?.length === 0 ? true : false,
              psa: psaData === "" ? true : false,
              leaveDays:
                leaveAllocationData?.leave_allocated_days === "" ? true : false,
              oneTimeMax:
                leaveAllocationData?.leave_max_in_month === "" ? true : false,
              yearMax:
                leaveAllocationData?.leave_max_in_year === "" ? true : false,
              joiningDay:
                leaveAllocationData?.joining_day === "" ? true : false,
              approver: org.length === 0 ? true : false,
            }));
          } else {
            handleData(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Modal
              show={showForm}
              onHide={onClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {edit ? "Edit Leave Rule" : "Add Leave Rule"}
                </Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmit}>
                <>
                  <Modal.Body>
                    <div class="row justify-content-center">
                      <ToastContainer autoClose={1000} />
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Leave Type<span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                name="leave_type"
                                type="number"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleErrors(e, "leaveType");
                                }}
                                disabled={edit}
                                onChange={(e) => {
                                  handleChange(e);
                                  setleaveAllocationData({
                                    ...leaveAllocationData,
                                    leave_type: e.target.value,
                                    leave_allocated_days:
                                      e.target.options[
                                        e.target.selectedIndex
                                      ].getAttribute("leave"),
                                  });
                                  setRuleGender(
                                    e.target.options[
                                      e.target.selectedIndex
                                    ].getAttribute("gender")
                                  );
                                  setFormError((prev) => ({
                                    ...prev,
                                    leaveType: false,
                                    leaveDays: false,
                                  }));
                                }}
                                value={Number(leaveAllocationData.leave_type)}
                              >
                                <option
                                  value="0"
                                  label="Select Leave type"
                                  disabled
                                >
                                  Select Leave Type
                                </option>

                                {leaveAllocationListData(leaveTypeData)}
                              </select>
                              {touched.country_id && errors.country_id ? (
                                <div className="small text-danger">
                                  {errors.country_id}
                                </div>
                              ) : formError?.leaveType ? (
                                <div className="small text-danger">
                                  Select Leave Type
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Allocate Type<span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control newbgselect main-pointer"
                                type="number"
                                name="allocate_type"
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleErrors(e, "allocate_type");
                                }}
                                disabled={edit}
                                onChange={(e) => {
                                  handleChange(e);
                                  setleaveAllocationData({
                                    ...leaveAllocationData,
                                    allocate_type: e.target.value,
                                  });
                                  setFormError((prev) => ({
                                    ...prev,
                                    allocateType: false,
                                  }));
                                }}
                                value={leaveAllocationData.allocate_type}
                              >
                                <option value="" label="Select" disabled>
                                  Select
                                </option>
                                {/* <option value="A" label="ALL" /> */}
                                <option value="SF" label="SF">
                                  SF
                                </option>
                                <option value="NSF" label="Non-SF">
                                  Non-SF
                                </option>
                              </select>
                              {touched.allocate_type && errors.allocate_type ? (
                                <div className="small text-danger">
                                  {errors.allocate_type}
                                </div>
                              ) : formError?.allocateType ? (
                                <div className="small text-danger">
                                  Select Allocate Type
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Gender<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                disabled
                                placeholder="gender"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={ruleGender}
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Select Organization
                                <span class="text-danger">*</span>
                              </label>
                              <Select
                                name="organisation"
                                // isMulti
                                options={orgList}
                                isDisabled={edit}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                onBlur={(e) => {
                                  handleErrors(e, "org");
                                }}
                                onChange={(e) => {
                                  pushOrg(e);
                                  setFormError((prev) => ({
                                    ...prev,
                                    org: false,
                                  }));
                                }}
                                value={orgData}
                              />
                              {formError?.org && orgData?.length === 0 ? (
                                <div className="small text-danger">
                                  Select Organization
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Select Business Unit
                                <span class="text-danger">*</span>
                              </label>
                              <Select
                                name="branch"
                                // isMulti
                                options={business}
                                isDisabled={edit}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                onBlur={(e) => {
                                  handleErrors(e, "branch");
                                }}
                                onChange={(e) => {
                                  pushBusiness(e);
                                  setFormError((prev) => ({
                                    ...prev,
                                    businessUnit: false,
                                  }));
                                }}
                                value={businessData}
                              />
                              {formError?.businessUnit &&
                              businessData?.length === 0 ? (
                                <div className="small text-danger">
                                  Select Business Unit
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Select Personal Sub Area
                                <span class="text-danger">*</span>
                              </label>
                              <Select
                                name="psa"
                                // isMulti
                                options={psaList}
                                isDisabled={edit || disablePsa}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                onBlur={(e) => {
                                  handleErrors(e, "psa");
                                }}
                                onChange={(e) => {
                                  pushPsa(e);
                                  setFormError((prev) => ({
                                    ...prev,
                                    psa: false,
                                  }));
                                }}
                                value={psa}
                              />
                              {formError?.psa ? (
                                <div className="small text-danger">
                                  Select Personal Sub Area
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Leave Day's<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter Leave Day's"
                                name="leave_allocated_days"
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.value === "") {
                                    setFormError((prev) => ({
                                      ...prev,
                                      leaveDays: true,
                                    }));
                                  } else {
                                    setFormError((prev) => ({
                                      ...prev,
                                      leaveDays: false,
                                    }));
                                  }
                                  if (e.target.value > 365) {
                                    setFormError((prev) => ({
                                      ...prev,
                                      leaveLimit: true,
                                    }));
                                  } else {
                                    setFormError((prev) => ({
                                      ...prev,
                                      leaveLimit: false,
                                    }));
                                  }
                                  setleaveAllocationData({
                                    ...leaveAllocationData,
                                    leave_allocated_days: e.target.value,
                                  });
                                  checkValidation(e, "leave_allocated_days");
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleErrors(e, "leave_allocated_days");
                                }}
                                value={leaveAllocationData.leave_allocated_days}
                              />
                              {touched.leave_allocated_days &&
                              errors.leave_allocated_days ? (
                                <div className="small text-danger">
                                  {errors.leave_allocated_days}
                                </div>
                              ) : validationError?.leaves ? (
                                <div className="small text-danger">
                                  Leave Days should not be less than the value
                                  described in Leave Master.
                                </div>
                              ) : formError?.leaveDays ? (
                                <div className="small text-danger">
                                  Enter Leave Days
                                </div>
                              ) : formError?.leaveLimit ? (
                                <div className="small text-danger">
                                  Value should be less than 366
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Maximum Leave in Year
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Maximum Leave in Year"
                                name="leave_max_in_year"
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setFormError((prev) => ({
                                      ...prev,
                                      yearMax: true,
                                    }));
                                  } else {
                                    setFormError((prev) => ({
                                      ...prev,
                                      yearMax: false,
                                    }));
                                  }
                                  handleChange(e);
                                  setleaveAllocationData({
                                    ...leaveAllocationData,
                                    leave_max_in_year: e.target.value,
                                  });
                                  checkValidation(e, "year");
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleErrors(e, "leave_max_in_year");
                                }}
                                value={leaveAllocationData.leave_max_in_year}
                              />
                              {touched.leave_max_in_year &&
                              errors.leave_max_in_year ? (
                                <div className="small text-danger">
                                  {errors.leave_max_in_year}
                                </div>
                              ) : formError?.yearMax ? (
                                <div className="small text-danger">
                                  Enter Maximum Leave in Year
                                </div>
                              ) : null}
                              {validationError?.year !== "" && (
                                <div className="small text-danger">
                                  {validationError.year}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Maximum Leave in One Time
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Maximum Leave in One Time"
                                name="leave_max_in_month"
                                onChange={(e) => {
                                  handleChange(e);
                                  if (e.target.value === "") {
                                    setFormError((prev) => ({
                                      ...prev,
                                      oneTimeMax: true,
                                    }));
                                  } else {
                                    setFormError((prev) => ({
                                      ...prev,
                                      oneTimeMax: false,
                                    }));
                                  }
                                  setleaveAllocationData({
                                    ...leaveAllocationData,
                                    leave_max_in_month: e.target.value,
                                  });
                                  checkValidation(e, "month");
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleErrors(e, "leave_max_in_month");
                                }}
                                value={leaveAllocationData.leave_max_in_month}
                              />
                              {touched.leave_max_in_month &&
                              errors.leave_max_in_month ? (
                                <div className="small text-danger">
                                  {errors.leave_max_in_month}
                                </div>
                              ) : formError?.oneTimeMax ? (
                                <div className="small text-danger">
                                  Enter Maximum Leave in One Time
                                </div>
                              ) : null}
                              {validationError?.month !== "" && (
                                <div className="small text-danger">
                                  {validationError.month}
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Day's After Joining
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Enter Day's After Joining"
                                name="joining_day"
                                onChange={(e) => {
                                  if (
                                    e.target.value > 0 &&
                                    e.target.value <= 365
                                  ) {
                                    handleChange(e);
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      joining_day: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      jDay: false,
                                    }));
                                  } else {
                                    handleChange(e);
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      joining_day: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      jDay: true,
                                    }));
                                  }
                                  if (e.target.value === "") {
                                    setFormError((prev) => ({
                                      ...prev,
                                      joiningDay: true,
                                    }));
                                  } else {
                                    setFormError((prev) => ({
                                      ...prev,
                                      joiningDay: false,
                                    }));
                                  }
                                }}
                                onBlur={(e) => {
                                  handleErrors(e, "joining_day");
                                }}
                                value={leaveAllocationData.joining_day}
                              />
                              {touched.joining_day && errors.joining_day ? (
                                <div className="small text-danger">
                                  {errors.joining_day}
                                </div>
                              ) : formError?.joiningDay ? (
                                <div className="small text-danger">
                                  Enter Day's After Joining
                                </div>
                              ) : formError?.jDay ? (
                                <div className="small text-danger">
                                  Leave Days should be in between (1 - 365)
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>
                                Approver Allows
                                <span class="text-danger">*</span>
                              </label>
                              <Select
                                name="approver_name"
                                isMulti
                                options={approvers}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                closeMenuOnSelect={true}
                                onChange={(e) => {
                                  pushApprover(e);
                                  setFormError((prev) => ({
                                    ...prev,
                                    approver: false,
                                  }));
                                }}
                                onBlur={(e) => {
                                  handleErrors(e, "approver");
                                }}
                                value={org}
                              />
                              {formError?.approver && org?.length === 0 ? (
                                <div className="small text-danger">
                                  Select Approver Allows
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label className="d-block mb-2">
                                Available for Notice Period
                              </label>
                              <label class="logCheck d-inline-block mr-4">
                                Yes
                                <input
                                  type="radio"
                                  name="notice_period"
                                  id="yes"
                                  value={leaveAllocationData.notice_period}
                                  onChange={() => {
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      notice_period: true,
                                    }));
                                  }}
                                  checked={
                                    leaveAllocationData.notice_period === true
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label class="logCheck d-inline-block">
                                No
                                <input
                                  type="radio"
                                  name="notice_period"
                                  id="No"
                                  value={leaveAllocationData.notice_period}
                                  onChange={(e) => {
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      notice_period: false,
                                    }));
                                  }}
                                  checked={
                                    leaveAllocationData.notice_period === false
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div className="form-group innergroup">
                              <label className="d-block">Status</label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="active"
                              >
                                Active
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="active"
                                  value={leaveAllocationData.is_active}
                                  onChange={() => {
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      is_active: true,
                                    }));
                                  }}
                                  checked={
                                    leaveAllocationData.is_active === true
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Inactive"
                              >
                                Inactive
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="Inactive"
                                  value={leaveAllocationData.is_active}
                                  onChange={(e) => {
                                    setleaveAllocationData((prev) => ({
                                      ...prev,
                                      is_active: false,
                                    }));
                                  }}
                                  checked={
                                    leaveAllocationData.is_active === false
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      <>
                        <button
                          class="btn btn-outline-danger"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </Modal.Footer>
                </>
              </form>
            </Modal>
          </div>
        )}
      </Formik>
    </>
  );
};
export default AddLeaveAllocation;
