import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillPlantService from "../../service/skillPlant";
import * as SkillService from "../../service/skill";
import Select from "react-select";
const validationSchema = Yup.object({
    plant_name: Yup.string()
        .required("*Department Name is required")
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field cannot contain only blank spaces"
        ),
        plant_code: Yup.string()
        .required("*department code is required")
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field cannot contain only blank spaces"
        ),
});
const AddPlant = (props) => {
    const nameForm = "Plant Master";
    const dispatch = useDispatch();
    const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [dataLocation, setDataLocation] = useState([]);
    const [locationError, setLocationError] = useState(false);
    const [skillPlantData, setSkillPlantData] = useState({
        plant_code: "",
        plant_name: "",
        description:"",
        is_active: true,
    });
    const saveDepartment = (values) => {
        let data = dataLocation.map((x) => {
            return x.value;
        });
        values.location = data;
        setIsLoading(true);
        SkillPlantService.postPlant(values)
            .then((response) => {
                if (response.data.status === true && response.data.code === 200) {
                    onAddCallBack(response);
                    setIsLoading(false);
                    toast.success(response.data.message);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.response.data.message);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    const updateSkills = (values) => {
        let data = {...values}
        delete data.id
        delete data.is_active
        console.log(data,values)
        setIsLoading(true);
        SkillPlantService.updatePlant(values, values.id)
            .then((response) => {
                if (response.data.status === true && response.data.code === 200) {
                    onAddCallBack(response);
                    setIsLoading(false);
                    toast.success(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error.response)
                setIsLoading(false);
                toast.error(error.response.data.message);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    const locationPush = (e) => {
        setDataLocation(e);
    };
    useEffect(() => {
        if (editSkills) {
            console.log(editSkills)
            let locationData = editSkills
            setDataLocation(locationData);
            setSkillPlantData(editSkills);
            Object.keys(editSkills).forEach((item) => {
                skillPlantData[item] = editSkills[item];
            });
        }
    }, [editSkills]);
    const submitHandler = () => {
        // if (dataLocation.length === 0) {
        //     setLocationError(true);
        // }
    };
    return (
        <>
            <Formik
                initialValues={skillPlantData}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    console.log(locationError)
                    if (!locationError) {
                        if (values.id) {
                            updateSkills(values);
                        } else {
                            saveDepartment(values);
                        }
                    }
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Modal
                        show={showForm}
                        onHide={onClose}
                        size="lg"
                        backdrop="static"
                        keyboard={false}
                        className="modaldefaultclose modaldefaultclosecenter"
                    >
                        <ToastContainer />
                        <Modal.Header closeButton>
                            <Modal.Title> {edit === true ? "Edit" : "Add"} Plant</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <div className="row justify-content-center">
                                    <div className="col-md-11">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                    Plant Code
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="plant_code"
                                                        className="form-control"
                                                        placeholder="Enter Plant Code"
                                                        maxLength={30}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.plant_code}
                                                    />
                                                    {touched.plant_code && errors.plant_code ? (
                                                        <div className="small text-danger">
                                                            {errors.plant_code}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                        Plant Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="plant_name"
                                                        className="form-control"
                                                        placeholder="Enter Plant Name"
                                                        maxLength={400}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.plant_name}
                                                    />
                                                    {touched.plant_name && errors.plant_name ? (
                                                        <div className="small text-danger">
                                                            {errors.plant_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                        Description
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="description"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        maxLength={400}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.description}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label className="d-block">
                                                        Status <span className="text-danger"></span>
                                                    </label>
                                                    <label
                                                        className="logCheck d-inline-block mr-4"
                                                        htmlFor="active"
                                                    >
                                                        Active
                                                        <input
                                                            type="radio"
                                                            name="is_active"
                                                            id="active"
                                                            value={values.is_active}
                                                            onChange={() => {
                                                                setFieldValue("is_active", true);
                                                            }}
                                                            checked={values.is_active === true}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label
                                                        className="logCheck d-inline-block"
                                                        htmlFor="Inactive"
                                                    >
                                                        Inactive
                                                        <input
                                                            type="radio"
                                                            name="is_active"
                                                            id="Inactive"
                                                            value={values.is_active}
                                                            onChange={(e) => {
                                                                setFieldValue("is_active", false);
                                                            }}
                                                            checked={values.is_active === false}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                {isLoading ? (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        as="span"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary-inner bpi-main"
                                            onClick={() => submitHandler()}
                                        >
                                            Submit
                                        </button>
                                    </>
                                )}
                            </Modal.Footer>
                        </form>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default AddPlant;