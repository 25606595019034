import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getCityList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/city?${queryParm}`);
};

export const saveCity = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/city`, data);
};

export const updateCity = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/city/${id}`, data);
};

export const deleteCity = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/city/${id}`, data);
};

export const getAllStateList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/state?paginate=${paginate}`);
};

export const isCityExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/city/exists`, isData);
};
export const getStateList = async (country_id) => {
  return axios.post(`${AUTH_BASE_URL}/state/list/${country_id}`);
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/city/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
