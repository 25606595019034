import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import * as OrganisationService from "../../service/organisation"
import * as otherExpService from "../../service/otherExpService";
import { Tooltip } from "react-tippy";

import { useDispatch, useSelector } from "react-redux";

import {
    selectFinalUrlPermission,
    setDisplayLoader,
    selectUserData,
} from "../redux/piDataStore";


function ListOtherExpEntryException() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const finalUser = useSelector(selectUserData);
    const [userGroup,setuserGroup] = useState({
        _userGroup:""
    })




    const [show, filtersetShow] = useState(false);
    const [otherExpData, setotherExpData] = useState([]);

    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-created_at" });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecord, setTotalRecord] = useState();
    const [searchStr, setSearchStr] = useState("");
    const [travelList, setTravelList] = useState([]);
    const [usersDropdown, setUsersDropdown] = useState([]);
    const [orgDropDown, setorgDropDown] = useState([]);
    const [postUserData,setpostUserData] = useState({})
    const [organisation_User, setOrganisation_User] = useState({
        company: "",
        user_id: ""
    });

    // const [filterdata, setfilterData] = useState({
    //     user_id: { value: 0 },
    //     id: "",
    //     organisation:{value: ""},
    //     from_date: "",
    //     to_date: "",
    //   });
    

    const ListExceptionMaster = (
      search,
      page_no,
      page_size,
      sort_by,
      paginate,
      status,
    ) => {
        dispatch(setDisplayLoader("Display"));

        otherExpService.getExceptionMaster(
            handleKey(
              search,
              page_no,
              page_size,
              sort_by,
              paginate,
              status,
            )
            +`&check_type=other`

        )
            .then((response) => {
                // console.log(`otherexp...`,response);
                dispatch(setDisplayLoader("Hide"));
                
                setTravelList(response?.data?.dataList?.result);
                setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
                setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);

            })
            .catch((error) => {
                dispatch(setDisplayLoader("Hide"));

                toast.error(error.response.data);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    const typeUserEntry=async(_key="",_val="")=>{

        postUserData[_key]=_val
        postUserData["check_type"]="other"

        console.log(`postype`,postUserData);
        

    }




    const handleSearch = (e) => {
      if (e.target.value.length > 1 || e.target.value.length === 0) {
        ListExceptionMaster(
          e.target.value.toLowerCase(),
          1,
          10,
          sortData.sort_by,
          true,
          "",
        )
        setSearchStr(e.target.value);
      }
    };

    const handlePageClick = (action) => {
      let activePage = action.selected + 1;
      setCurrentPage(activePage);
      
      ListExceptionMaster(
        searchStr,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
      )
    };


 

    useEffect(() => {

        let localGroup=""

        finalUser.groups.every(
            (data) =>
              {
                if(data?.name.toLowerCase() === "ssc user" || data?.name.toLowerCase() === "ssc manager"){
                    localGroup=data?.name.toLowerCase()
                    console.log(`-->`,localGroup);
                    userGroup._userGroup=localGroup
                    return false
                }
                return true
              }
          )
         



        // getUsersList()
        ListExceptionMaster(
          "",
          1,
          10,
          sortData.sort_by,
          true,
          "",
        )
        // getAllOrganisation()
        // getBusinessUnits()
        
        
    }, [])


    useEffect(() => {
      console.log(`being called`);
      dispatch(setDisplayLoader("Display"));
      ListExceptionMaster(
        searchStr,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
      )
    }, [pageSizeNo]);




    const getUsersList = () => {
        UserService.getUsersListDataNew(0).then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.id,
                        label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
                    };
                }),
            ];
            setUsersDropdown(data);





        });
    };

    const getAllOrganisation = () => {
        OrganisationService.getAllOrganisation(false).then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.code,
                        label: `${x?.name} ( ${x.code} )`,
                    };
                }),
            ];
            setorgDropDown(data);





        });
    };


    const otherExpExport = async (
        search,
        from_date,
        to_date,
        sort_by
        
    )=>{

        otherExpService.otherExpExport(
            'search='+search
            +'&from_date='+from_date
            +'&to_date='+to_date
            +'&sort_by='+sort_by
        ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp.data.dataList.result]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "OtherExpenseExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            
            toast.success(`export success`)
            console.log(`export`,resp);
            
        }).catch((err)=>{
            console.log(err);
            toast.error(`Unable to export`)
        })
        

    }

    const getBusinessUnits = () => {
        otherExpService.getBusinessUnits().then((res)=>{  
        // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.code,
                label: `${x?.name}( ${x.code} )`,
              };
            }),
          ];
          setorgDropDown(data);
        });
      };

    const getChcekExpenseList = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        TravelListingAPI.getCheckExpenseList(
            handleKey(
                search,
                page_no,
                page_size,
                sort_by,
                paginate,
                status,
            )
        ).then((response) => {
            setTravelList(response?.data?.dataList?.result);
            setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
            setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        })
    }

    const handleKey = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        let queryParm =
            "page_no=" +
            page_no +
            "&page_size=" +
            page_size +
            "&paginate=" +
            paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
            queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
            queryParm = queryParm + "&status=" + status;
        }
        return queryParm;
    };

    const getExportValue = (search, page_no, page_Size, sort_by, paginate, status) => {
        // dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getCheckExpenseExportValue(
            handleKey(search, page_no, page_Size, sort_by, paginate, status)
        )
            .then((response) => {
                // dispatch(setDisplayLoader("Hide"));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    return (
        <>
            <div class="container-fluid">

                <div class="row">

                  <div className="col-md-12">


                    <div className="content-wrapper-inner content-wrapper-inner2">

                    <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">Other Exception Master Listing</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group table-search">
                      <span className="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <button
                        className="btn btn-primary-inner"
                        onClick={() => {
                          navigate("/otherexception-master");
                        }}
                      >
                        <img
                          src="images/upload.png"
                          alt=""
                          className="mr-3"
                        />
                        Add New
                      </button>
                      {/* <button
                        onClick={filteropen}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>

                    


                            
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured travelintim">
                            <thead>
                              <tr>
                                <th>User ID</th>
                                <th>Bill Start Date</th>
                                <th>Bill End Date</th>
                                <th>Allowed From Date</th>
                                <th>Allowed To Date</th>
                                <th>Created By</th>
                                <th>Created At</th>
                                <th>Status</th>
                              
                              </tr>
                            </thead>
                            <tbody>
                              {travelList?.length ? (
                                travelList?.map((data, index) => (
                                  <tr>
                                    <td>
                                      <Tooltip 
                                        position="bottom" 
                                        title={data?.user_name+ ` (${data?.user_code})`
                                      }>
                                      <span>{data?.user_name+ ` (${data?.user_code}) ` }</span>
                                      </Tooltip>
                                      
                                    </td>
                                    <td>{moment.parseZone(data?.from_date).format("DD-MM-YYYY")}</td>
                                    <td>{moment.parseZone(data?.to_date).format("DD-MM-YYYY")}</td>
                                    <td>{moment.parseZone(data?.opened_from).format("DD-MM-YYYY")}</td>
                                    <td>{moment.parseZone(data?.opened_to).format("DD-MM-YYYY")}</td>
                                    <td>{data?.created_by_user_name+ ` (${data?.created_by_user_code}) ` } </td>
                                    <td>{moment.parseZone(data?.created_at).format("DD-MM-YYYY H:mm A")}</td>
                                    <td>
                                      {/* {!data?.check_type ? <span className="badge rounded-pill bg-secondary">Inactive</span> :<span className="badge rounded-pill bg-success">Active</span>} */}
                                      {!data?.is_active ? <span className="badge rounded-pill bg-secondary">Inactive</span> :<span className="badge rounded-pill bg-success">Active</span>}
                                      
                                      
                                    </td>
                                  </tr>
                                ))
                              ) : 
                                <tr>
                                  <td colSpan={16}> No Record Found</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>

                        {travelList?.length == 0 ? (
                          ""
                        ) : (
                          <div class="row">
                            <div class="col-sm-5">
                              <div class="sortinglist">
                                Show
                                <select
                                  class="form-control main-pointer"
                                  onChange={(e) => {
                                    setPageSizeNo(e.target.value);
                                    setCurrentPage(1);
                                  }}
                                >
                                  <option value={10} label={10}>
                                    10
                                  </option>
                                  <option value={20} label={20}>
                                    20
                                  </option>
                                  <option value={30} label={30}>
                                    30
                                  </option>
                                  <option value={100} label={100}>
                                    100
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-left"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}

                    </div>
                
                </div>


                </div>

            </div>


 



        </>
    );
}

export default ListOtherExpEntryException;