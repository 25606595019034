import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import AddTransporter from "./AddTransporter";
import ViewTransporter from "./ViewTransporter";
// import * as deptServices from "../../../../service/department";
import * as transportService from "../../../../service/LRGRPortal/transporterService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

if (typeof window !== "undefined") {
  injectStyle();
}
function Transporter() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [deptList, setDeptList] = useState([]);
  const [transporterList, setTransporterList] = useState([]);
  const [department, setDepartment] = useState(null);
  const [transporter, setTransporter] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [allDept, setAllDept] = useState([]);
  const [allTransporter, setAllTransporter] = useState([]);
  const [dropDept, setDropDept] = useState([]);
  const [dropTransporter, setDropTransporter] = useState([]);
  const [viewForm, setViewForm] = useState(true);
  const [viewFormData, setViewFormData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  // const [departmentDataForExport, setDepartmentDataForExport] = useState({
  //   department_id: "",
  //   business_unit_id: "",
  //   status: "",
  // });
  const [transporterDataForExport, setTransporterDataForExport] = useState({
   transporter_id: "",
    business_unit_id: "",
    status: "",
  });
  const [showForm, setShowForm] = useState(true);
  // const [departmentDataFilter, setDepartmentDataFilter] = useState({
  //   department_id: "",
  //   business_unit_id: "",
  //   status: "",
  // });
  const [transporterDataFilter, setTransporterDataFilter] = useState({
    transporter_id: "",
    business_unit_id: "",
    status: "",
  });

  const getTransporter = (
    search,
    business_unit_id,
    transporter_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    transportService
      .getTransporter(
        handleKey(
          search,
          business_unit_id,
          transporter_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        setTransporterList(response?.data?.dataList);
        console.log("ransporter data" ,response.data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    business_unit_id,
    transporter_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      business_unit_id !== "" &&
      business_unit_id !== undefined &&
      business_unit_id !== null
    ) {
      queryParm = queryParm + "&business_unit_id=" + business_unit_id;
    }
    if (
      transporter_id !== "" &&
      transporter_id !== undefined &&
      transporter_id !== null
    ) {
      queryParm = queryParm + "&transporter_id=" + transporter_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    business_unit_id,
    transporter_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    transportService
      .getExportValue(
        handleKey(
          search,
          business_unit_id,
          transporter_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Transporter.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteTransporter = (idx) => {
    transportService
      .deleteTransporter(idx)
      .then((res) => {
        if (res.data.status == true) {
          getTransporter(
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    confirmAlert({
      title: "Delete Transporter",
      message: `Are you sure to delete ${deptName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTransporter(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addDepart = () => {
    setShowForm(true);
    setEditMode(false);
    setTransporter(null);
  };
  const editDept = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setTransporter(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setTransporter(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getTransporter(
      searchStr,
      transporterDataFilter.business_unit_id,
      transporterDataFilter.transporter_id,
      transporterDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getTransporter(
        e.target.value.toLowerCase(),
        transporterDataFilter.business_unit_id,
        transporterDataFilter.transporter_id,
        transporterDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(transporterDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setTransporterDataFilter(ClearData);
    getTransporter(searchStr, "", "", "", 1, pageSizeNo, sortData.sort_by, true);
    filterClose();
  };

  useEffect(() => {
    getTransporter(
      searchStr,
      transporterDataFilter.business_unit_id,
      transporterDataFilter.transporter_id,
      transporterDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  const getBranch = (paginate) => {
    transportService
      .getBranch(paginate)
      .then((response) => {
        setAllTransporter(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllTransporter = (paginate) => {
    transportService
      .getAllTransporter(paginate)
      .then((response) => {
        setDropTransporter(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    getBranch(false);
    getAllTransporter(false);
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`dept${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const mainDataFilter = () => {
    setTransporterDataForExport({
      ...transporterDataForExport,
      transporter_id: transporterDataFilter.transporter_id,
      business_unit_id: transporterDataFilter.business_unit_id,
      status: transporterDataFilter.status,
    });
    const business_unit_id = transporterDataFilter.business_unit_id;
    const transporter_id = transporterDataFilter.transporter_id;
    const status = transporterDataFilter.status;
    getTransporter(
      searchStr,
      business_unit_id,
      transporter_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getTransporter(
      searchStr,
      transporterDataFilter.business_unit_id,
      transporterDataFilter.transporter_id,
      transporterDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTransporter(
        data,
        transporterDataFilter.business_unit_id,
        transporterDataFilter.transporter_id,
        transporterDataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTransporter(
      searchStr,
      transporterDataFilter.business_unit_id,
      transporterDataFilter.transporter_id,
      transporterDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Transporter</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Transporter</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Transporter Code,
                        <br />
                        Transporter Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Transporter Code, Transporter Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />{" "}
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              transporterDataForExport.business_unit_id,
                              transporterDataForExport.transporter_id,
                              transporterDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}

                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {/* {finalUrlValue?.A ? ( */}
                {true ? ( 
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addDepart();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured sort-overlap">
            <thead>
              <tr>
                <th>Transporter Code</th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                >
                  Transporter Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("business_unit_id__name");
                  }}
                  className={
                    sortData["business_unit_id__name"] === undefined
                      ? "unsortcls"
                      : sortData["business_unit_id__name"]
                  }
                >
                  City
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {/* {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? ( */}
                { true ?(
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {transporterList.length ? (
                transporterList?.map((data, index) => (
                  <>
                    <tr key={`dept${index}`}>
                      <td>{data?.code}</td>
                      <td className="text-theme fw-bold">{data?.name}</td>

                      <td>
                        {data?.business_unit_name}&nbsp;(
                        {data?.business_unit_code})
                      </td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {moment
                          .utc(data?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                      <td>
                        {/* {data?.is_active ? ( */}
                        {true ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R || true ? (
                        <td>
                          {/* {finalUrlValue?.V ? ( */}
                          {true ?(
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              <img src="images/eye.png" alt="" />
                            </button>
                          ) : (
                            ""
                          )}

                          {/* {finalUrlValue?.E ? ( */}
                          {true ? (
                            <button
                              className="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editDept(data);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            
                              
                            </button>
                          ) : (
                            ""
                          )}

                          {/* {finalUrlValue?.R ? ( */}
                          {true ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {transporterList?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    // setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-group innergroup ">
            <label>
              Select Business Unit <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              name="business_unit_id"
              onChange={(e) => {
                setTransporterDataFilter({
                  ...transporterDataFilter,
                  business_unit_id: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={transporterDataFilter?.business_unit_id}
            >
              <option value="" label="Select Business Unit" disabled />

              {renderOrgOptions(allTransporter)}
            </select>
          </div> */}
          <div className="form-group innergroup">
            <label>
              Select Transporter <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer "
              name="transporter_id"
              onChange={(e) => {
                setTransporterDataFilter({
                  ...transporterDataFilter,
                  transporter_id: e.target.value,
                });
              }}
              value={transporterDataFilter?.transporter_id}
            >
              <option value="" label="Select Transporter" disabled />

              {renderDeptOptions(dropTransporter)}
            </select>
          </div>
          <div className="form-group innergroup">
            <label>
              Select Status <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setTransporterDataFilter({
                  ...transporterDataFilter,
                  status: e.target.value,
                });
              }}
              value={transporterDataFilter?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true} label="Active" />
              <option value={false} label="In-Active" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddTransporter
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editDepartment={transporter}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewTransporter
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Transporter;
