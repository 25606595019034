import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "../../alert/CustomUi.css";
import * as moment from "moment";
import { setDisplayLoader } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import Select from "react-select";
import { getBranchFormList } from "../../service/businessService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { getUserRmList } from "../../service/jv_service";
import {getAllOrganisation} from "../../service/organisation"
import {
  getAssignedBspoc,
  getUserList,
  makeUserBspoc,
  removeUserAsBspoc,
} from "../../service/idea";
import {
  getPersonalArea,
  getUsersListData,
  getUsersListDataByOrg,
  getSubPersonalArea,
  getSubPersonalAreaByOrg,
} from "../../service/userService";

function AssignBspoc() {
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [personalSubAreaList, setPersonalSubAreaList] = useState([]);
  const [personalAreaList, setPersonalAreaList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false);
  const [userList, setUserList] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    organisation_id: "",
    status: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [organizData, setOrganizData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [valueExportData, setValueExportData] = useState({
    id: "",
    status: "",
  });
  const [moduleDataFilter, setModuleDataFilter] = useState({});
  const [formObj, setFormObj] = useState({
    orgValue: [],
    businessUnit: [],
    personalArea: [],
    personalSubArea: [],
    userList: "",
  });
  const [bspocList, setBspocList] = useState([]);
  const [removeUser, setRemoveUser] = useState({
    userList: [],
  });
  const userData = useSelector(selectUserData);

  const handleKey = (
    orgId,
    search,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "orgId=" +
      orgId +
      "&page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      status !== "" &&
      status !== undefined &&
      status !== null &&
      status !== []
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBspoc(
      userData.organisation_id,
      searchStr,
      true,
      activePage,
      pageSizeNo,
      "id",
      true
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
    }
  };

  const onClose = () => {
    setShowForm(false);
  };
  const onView = (data) => {
    setShowForm(true);
    setEditMode(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const getNextPrevPage = (action) => {
    let activePage = currentPage;
    if (action === 1) {
      if (activePage <= organizData.totalPages - 1) {
        activePage = activePage + 1;
        setCurrentPage(activePage);
      }
    } else {
      if (activePage > 1) {
        activePage = activePage - 1;
        setCurrentPage(activePage);
      }
    }
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
  };

  useEffect(() => {
    getBusinessUnits();
    getUserList();
    getOrg();
    if (userData.organisation_id !== undefined) {
      getBspoc(
        userData.organisation_id,
        searchStr,
        true,
        currentPage,
        pageSizeNo,
        "id",
        true
      );
    }
  }, [userData]);

  useEffect(() => {
    if (viewFormData !== null) {
      setFormObj((prev) => ({
        ...prev,
        orgValue: viewFormData.orgDetails,
        personalArea: viewFormData.personalArea,
        personalSubArea: viewFormData.personalSubArea,
        userList: viewFormData.userList,
      }));
    }
  }, [viewFormData]);

  
  const getOrg = () => {
    let paginate = false;
    getAllOrganisation(paginate).then((response)=>{
      let obj = response?.data?.dataList?.result?.map((res)=>{
        return {
          value: res.id,
          label: res.code
        }
      })
      setOrgOptions(obj)
    })
  }

  const getBspoc = (
    orgId,
    search,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    getAssignedBspoc(
      handleKey(orgId, search, status, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        setBspocList(response?.data?.dataList?.result);
        setOrganizData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getUserList = async () => {
    if (userData?.organisation_id != undefined) {
      await getUserRmList(false).then(
        (response) => {
          if (response?.status === 200) {
            let userDropdown = response?.data?.dataList?.result.map((obj) => {
              return {
                label: `${obj.full_name} (${obj.username})`,
                value: obj.id,
              };
            });
            setUserList(userDropdown);
          }
        }
      );
    }
  };

  const submit = (id, userName) => {
    confirmAlert({
      title: `Remove ${userName} as BSPOC`,
      message: `Are you sure to un-assign ${userName}?`,
      buttons: [
        {
          label: "Yes",
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getBusinessUnits = async () => {
    let organization_id = userData.organisation_id;
    setFormObj((prev) => ({
      ...prev,
      orgId: organization_id,
    }));
    await getBranchFormList(
      1,
      100,
      "id",
      "",
      true,
      organization_id,
      "",
      true
    ).then((response) => {
      if (response?.status === 200) {
        let dropDown = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj.name + "(" + obj.code + ")",
            value: obj.id,
          };
        });
        setBusinessUnits(dropDown);
      }
    });
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(moduleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setModuleDataFilter(ClearData);
  };

  const filterShow = () => {
    filteropen();
  };

  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    setValueExportData({
      ...valueExportData,
      id: moduleDataFilter,
      status: moduleDataFilter.status,
    });
    const event = moduleDataFilter;
    const stats = moduleDataFilter.status;
    filterClose();
  };
  const addNew = () => {
    getPersonalSubArea()
    setEditMode(false);
    setShowForm(true);
  };

  const handleChange = (event, name) => {
    switch (name) {
      case "org":
        setFormObj((prev) => ({
          ...prev,
          orgValue: event,
        }));
        getPersonalAreaData(event.value);
        break;
      case "area":
        setFormObj((prev) => ({
          ...prev,
          personalSubArea: event,
        }));
        break;
      case "user":
        setFormObj((prev) => ({
          ...prev,
          userList: event,
        }));
        break;
      case "personal_area":
        setFormObj((prev) => ({
          ...prev,
          personalArea: event,
        }));
        break;
      default:
        break;
    }
  };

  const getPersonalAreaData = (org_id) => {
     getPersonalArea(org_id)
      .then((response) => {
        if (response?.status === 200) {
          let areaList = response?.data?.dataList?.result?.map((obj) => {
            return {
              label: obj.description + "(" + obj.name + ")",
              value: obj.id,
            };
          });
          setPersonalAreaList(areaList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPersonalSubArea = async () => {
    // let totalBu = formObj.businessUnit.map((obj) => obj.value);
    await getSubPersonalArea(0)
      .then((response) => {
        if (response?.status === 200) {
          let areaList = response?.data?.dataList?.result?.map((obj) => {
            return {
              label: obj.description + "(" + obj.name + ")",
              value: obj.id,
            };
          });
          setPersonalSubAreaList(areaList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addRecord = async () => {
    await makeUserBspoc(formObj).then((response) => {
      if (response?.status === 200) {
        setShowForm(false);
        setFormObj({
          ...formObj,
          orgValue: [],
          businessUnit: [],
          personalArea: [],
          personalSubArea: [],
          userList: "",
        })
        toast.success(response?.data?.message);
        getBspoc(
          userData.organisation_id,
          searchStr,
          true,
          currentPage,
          pageSizeNo,
          "id",
          true
        );
      }
    });
  };

  const addSelectedToDelete = (id) => {
    let curObj = viewFormData;
    let list = curObj?.userList.map((obj) => {
      if (obj?.value === id) {
        obj.selected = obj.selected === true ? false : true;
        return obj;
      }
      return obj;
    });
    if (!removeUser.userList.includes(id)) {
      setRemoveUser((prev) => ({
        ...prev,
        userList: [...prev.userList, id],
      }));
    } else {
      let current = removeUser.userList.filter((obj) => obj !== id);
      setRemoveUser((prev) => ({
        ...prev,
        userList: current,
      }));
    }
    setViewFormData((prev) => ({
      ...prev,
      userList: list,
    }));
  };

  const deleteRecord = async (id) => {
    let obj = {
      user_id: id,
    };
    await removeUserAsBspoc(obj).then((response) => {
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setViewFormData(null);
        setViewForm(false);
        getBspoc(
          userData.organisation_id,
          searchStr,
          true,
          currentPage,
          pageSizeNo,
          "id",
          true
        );
      }
    });
  };

  const deleteBspoc = (id) => {
    confirmAlert({
      title: "Delete BSPOC",
      message: `Are you sure to delete BSPOC?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      <div className="content-wrapper-inner" onClick={() => setSearchStr("")}>
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Assign BSPOC</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">System Setting</Breadcrumb.Item>
                <Breadcrumb.Item active>Assign BSPOC</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="input-group table-search">
                <span className="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" className="mr-2" />
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="#">
                            <i className="fas fa-print"></i>Print
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-file-alt"></i>CSV
                          </Dropdown.Item> */}
                    <Dropdown.Item href="#">
                      <button style={{ border: 0, background: "none" }}>
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#">
                            <i className="far fa-file-pdf"></i>Pdf
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-copy"></i>Copy
                          </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                <button
                  className="btn btn-primary-inner"
                  onClick={() => {
                    addNew();
                  }}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Organization</th>
                {/* <th>Business Units</th> */}
                <th>Personal Sub Areas</th>
                <th>User</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {bspocList.map((obj, index) => {
                return (
                  <tr key={index + 1}>
                    <td>{userData?.organisation_name}</td>
                    {/* <td>{obj?.businessUnit?.length}</td>  */}
                    <td>{obj?.personalSubArea?.length}</td>
                    <td>{obj?.userList?.label}</td>
                    <td>{obj?.created_by?.label}</td>
                    <td>{moment(obj?.created_at).format("Do MMM YYYY")}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-blanktd text-primary"
                        onClick={() => {
                          onView(obj);
                        }}
                      >
                        <img src="images/eye.png" alt="" />
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-blanktd text-primary ml-2"
                      >
                        <i className="far fa-edit"></i>
                      </button> */}
                      <button
                        className="btn btn-blanktd text-danger ml-2"
                        type="button"
                        onClick={() => deleteBspoc(obj?.userList?.value)}
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            {/* <Pagination>
              <Pagination.Item onClick={() => getNextPrevPage(0)}>
                <i className="fa fa-angle-left"></i>
              </Pagination.Item>
              <Pagination.Item>
                <span>
                  {currentPage}/ {organizData?.totalPages}
                </span>
              </Pagination.Item>
              <Pagination.Item
                className="active"
                onClick={() => getNextPrevPage(1)}
              >
                <i className="fa fa-angle-right"></i>
              </Pagination.Item>
            </Pagination> */}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Event Name <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="organisation_id"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  event_id: e.target.value,
                });
              }}
              value={moduleDataFilter?.event_id}
            >
              <option value="" label="Select Event" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              clearFilter();
            }}
          >
            <i className="fa fa-undo"></i>Clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
     
      <Modal
        show={showForm}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Organization
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="org"
                      options={orgOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={formObj?.orgValue}
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Personal Area
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="personal_area"
                      // isMulti
                      closeMenuOnSelect={false}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={personalAreaList}
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                      // onMenuClose={() => {
                      //   getPersonalSubAreaByArea();
                      // }}
                      value={formObj.personalArea}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Personal Sub Area
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="area"
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={personalSubAreaList}
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                      value={formObj.personalSubArea}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      User
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={editMode}
                      options={userList}
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                      value={formObj.userList}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4"></div>
                <div className="col-md-6">
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                      onClick={addRecord}
                    >
                      Submit
                    </button>
                  </>
                </div>
                <dic className="col-md-2"></dic>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssignBspoc;
