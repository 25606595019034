import React, { useState, useEffect } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
// import Logo from "../../assets/images/logo.png";
import { getUserDetails } from "../../service/header";
import { Formik } from "formik";
import { getAllDepartment, getAllIdeaDepartment } from "../../service/subdepartment";
import {
  getAreaAndScope,
  getUserList,
  saveMyidea,
  updateMyidea,
  getIdeaObjectiveResult,
  getMyidea,
  postVoteToIdea
} from "../../service/idea";
import { uploadFile } from "../../service/policy";
import { object } from "yup";
import { setDisplayLoader, selectUserData, selectIdeaRole } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { setUserData as setUser } from "../redux/piDataStore";

import * as Yup from "yup";
import moment from "moment/moment";

function ShareIdea() {
  const [myideasLead, setMyideaLead] = useState([]);
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    // idea_area: Yup.string().required("Idea area is required"),
    // idea_scope: Yup.string().required("Idea scope is required"),
    idea_desc: Yup.string().required("Idea desc. is required"),
  });
  const [department_idError, setdepartment_id] = useState(true);
  const [idea_head_idError, setidea_head_id] = useState(true);
  const [disableIdeaDept, setDisableIdeaDept] = useState(false);
  const [disableIdeaScope, setDisableIdeaScope] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [ideaNotes, setIdeaNotes] = useState("");
  const [openVoteModal, setOpenVoteModal] = useState(false);
  const [ideaDetails, setIdeaDetails] = useState([]);
  const [likeFlag, setLikeFlag] = useState(null);
  const [isEdit, setisEdit] = useState(false);
  const [useData, setUserData] = useState({
    full_name: "",
    username: "",
    department_name: "",
    designation_name: "",
    business_unit_name: "",
    reporting_to_fname: "",
    reporting_to_lname: "",
    reporting_to_name:"",
    department_name:"",
    department_code:"",
    location_name:"",
    location_code:"",
  });
  const [attachmentError, setAttachmentError] = useState(true);
  const [attachmentTypeError, setattachmentTypeError] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [dept, setDept] = useState([]);
  const [ideaLead, setIdeaLead] = useState("");
  const dispatch = useDispatch();

  const [olddept, setoldDept] = useState([]);
  const [oldideaLead, setoldIdeaLead] = useState([]);

  const [userListData, setUserListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [ideaLeadId, setIdeaLeadId] = useState(0);
  const [ideaArea, setIdeaArea] = useState([]);
  const [ideaScope, setIdeaScope] = useState([]);
  const [ideaObjective, setIdeaObjective] = useState("");
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [ideaAreaAndScope, setIdeaAreaAndScope] = useState({
    ideaArea: {},
    ideaScope: {},
  });

  const [myidea, setMyidea] = useState({
    idea_area: {},
    idea_scope: {},
    department_id: [],
    idea_head_id: [],
    idea_desc: "",
    attachments: [],
  });
  const userData = useSelector(selectUserData);
  const ideaRole = useSelector(selectIdeaRole);

  const { state } = useLocation();
  const handleKey = (
    search,
    myidea,
    from_date,
    to_date,
    status,
    currentpage,
    for_vote,
    idea_id
  ) => {
    let queryParm =
      "page_no=" +
      currentpage +
      "&page_size=" +
      pageSizeNo +
      "&sort_by=" +
      "-id" +
      "&paginate=" +
      "true" +
      "&my_ideas=" +
      myidea;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (for_vote !== "" && for_vote !== undefined && for_vote !== null) {
      queryParm = queryParm + "&for_vote=" + for_vote;
    }
    if (idea_id !== "" && idea_id !== undefined && idea_id !== null) {
      queryParm = queryParm + "&idea_id=" + idea_id;
    }

    return queryParm;
  };
  const getIdeaLead = (
    search,
    myidea,
    from_date,
    to_date,
    status,
    currentpage
  ) => {
    getMyidea(
      handleKey(search, myidea, from_date, to_date, status, currentpage)
    )
      .then((response) => {
        setMyideaLead(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getUserDetail = () => {
    getUserDetails(0)
      .then((response) => {
        if (response?.data?.dataList?.idea_lead_id !== null) {
          setIdeaLeadId(response?.data?.dataList?.idea_lead_id);
          setIdeaLead(
            // response?.data?.dataList?.idea_lead_name +
            //   "-" +
              response?.data?.dataList?.idea_lead_fname +
              " " +
              response?.data?.dataList?.idea_lead_lname +
              "(" +
              response?.data?.dataList?.idea_lead_name +
              ")"
              // "(" +
              // response?.data?.dataList?.idea_lead_bu_name +
              // ")"
          );
        } else {
          setIdeaLead(
            // response?.data?.dataList?.reporting_to_name +
            //   "-" +
              response?.data?.dataList?.reporting_to_fname +
              " " +
              response?.data?.dataList?.reporting_to_lname +
              "(" +
              response?.data?.dataList?.reporting_to_name +
              ")"
          );
        }
        dispatch(setUser(response?.data?.dataList));
        setUserData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllUsers = () => {
    getUserList(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let value = {
            value: x.id,
            label:
              x.full_name +
              "(" +
              x?.username +
              ")",
          };
          data.push(value);
        });
        setUserListData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getDepartmentDetail = () => {
    getAllIdeaDepartment(false,"my_idea")
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let value = { value: x.id, label: x.name };
          data.push(value);
        });
        setDepartmentData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    if (state != undefined && state.data != undefined) {
      setisEdit(true);
      let data = JSON.parse(JSON.stringify(state.data));
      let temp = []
      data?.attachments?.map((res)=>{
        let obj = {"name":res.attachment, "format":res.attachment.split('.').pop()}
        temp.push(obj)
      })
      setAttachmentUrl(temp)
      let ideahead = [];
      let lead = [];
      setIdeaObjective(data?.objective)
      data?.department.map((x) => {
        let data2 = { value: x?.id, label: x?.name };
        ideahead.push(data2);
      });
      if(ideahead.length > 0){
      setDept(ideahead);
      }
      else{
        if(data['idea_area_name'] !== "Digitization"){
        let obj = {value:data?.created_by_department_id, label:data?.created_by_department + "(" + data?.created_by_department_code + ")"}
        ideahead.push(obj);
        setDept(ideahead)
        }
      }
      setoldDept(ideahead);

      let filename = [];
      let urls=[]
      data?.attachments.map((x) => {
        let data2 = { name: x?.attachment.split("/")[4] };
        filename.push(data2);
        let url = { name: x?.attachment, "format":x.attachment.split('.').pop() };
        urls.push(url)
      });
      setAttachment(filename);
      setAttachmentUrl(urls)
      if (data?.idea_head.length > 0) {
        data?.idea_head.map((x) => {
          let value = {
            value: x.id,
            label:
              x.username +
              "-" +
              x?.first_name +
              " " +
              x.last_name +
              "(" +
              x?.business_unit_name +
              ")",
          };
          lead.push(value);
        });
      }
      if (lead.length === 0) {
        if (userData?.idea_lead_id !== null) {
          setIdeaLeadId(userData?.idea_lead_id);
          setIdeaLead(
            userData?.idea_lead_name +
              "-" +
              userData?.idea_lead_fname +
              " " +
              userData?.idea_lead_lname +
              "(" +
              userData?.idea_lead_bu_name +
              ")"
          );
        } else {
          setIdeaLead(
            userData?.reporting_to_name +
              "-" +
              userData?.reporting_to_fname +
              " " +
              userData?.reporting_to_lname +
              "(" +
              userData?.reporting_to_bu_name +
              ")"
          );
        }
      } else {
        setIdeaLead(lead[0]);
        setoldIdeaLead(lead);
      }

      if (data) {
        Object.keys(data).forEach((item) => {
          myidea[item] = data[item];
        });
        ideaAreaAndScope["ideaArea"] = {
          label: data["idea_area_name"],
          value: data["idea_area__id"],
        };
        if(data['idea_area_name'] === "Digitization"){
          setDisableIdeaScope(true)
          setDisableIdeaDept(true)
          setdepartment_id(true);
          setUserData({
            full_name: `${data?.created_by_first_name} ${data?.created_by_last_name}`,
            username: data?.created_by_code,
            department_name: data?.department.length > 0 ? data?.department[0].name + "(" + data?.department[0].code + ")" : "",
            designation_name: data?.created_by_designation,
            business_unit_name:
              data?.department.length > 0 ? data?.department[0].name : "",
            reporting_to_fname: data?.created_by_reporting_fname,
            reporting_to_lname: data?.created_by_reporting_lname,
            reporting_to_name:data?.reporting_name,
            // department_name:data?.created_by_department,
            // department_code:data?.created_by_department_code,
            personal_sub_area_description:data?.created_by_location,
            personal_sub_area_name:data?.created_by_location_code,
          });
        }
        else{
          setDisableIdeaScope(false)
          setDisableIdeaDept(false)
        
        ideaAreaAndScope["ideaScope"] = {
          label: data["idea_scope_name"],
          value: data["idea_scope__id"],
        };
        setUserData({
          full_name: `${data?.created_by_first_name} ${data?.created_by_last_name}`,
          username: data?.created_by_code,
          department_name: data?.department.length > 0 ? data?.department[0].name + "(" + data?.department[0].code + ")" : "",
          designation_name: data?.created_by_designation,
          business_unit_name:
            data?.department.length > 0 ? data?.department[0].name : "",
          reporting_to_fname: data?.created_by_reporting_fname,
          reporting_to_lname: data?.created_by_reporting_lname,
          reporting_to_name:data?.reporting_name,
          department_name:data?.created_by_department,
          department_code:data?.created_by_department_code,
          personal_sub_area_description:data?.created_by_location,
          personal_sub_area_name:data?.created_by_location_code,
        });
      }
        
      }
    }
  }, [state?.data]);

  const getIdeaAreaAndScope = async () => {
    await getAreaAndScope().then((response) => {
      if (response?.status === 200) {
        let ideaArea = [];
        let ideaScope = [];
        response?.data?.dataList?.result.map((obj) => {
          if (obj.has_type === "area") {
            ideaArea.push({
              label: obj.idea_area,
              value: obj.id,
              notes: obj?.notes,
              idea_lead: obj?.idea_lead,
              idea_lead_name: obj?.idea_lead_name
            });
          } else {
            ideaScope.push({
              label: obj.idea_scope,
              value: obj.id,
            });
          }
        });
        setIdeaArea(ideaArea);
        setIdeaScope(ideaScope);
      }
    });
  };

  useEffect(() => {
    getIdeaLead("", false, "", "", "", currentPage);
    getIdeaAreaAndScope();
    getDepartmentDetail();
    getAllUsers();
    if (state?.data === undefined) {
      getUserDetail();
    }
  }, []);
  const pushDepartment = (e) => {
    setdepartment_id(true);
    setDept(e);
  };
  const pushIdeaLead = (e) => {
    setidea_head_id(true);
    setIdeaLeadId(e.value);
    setIdeaLead(e);
  };
  const onProfileChange = async (e) => {
    let file = e.target.files;
    let previewUrl = URL.createObjectURL(e.target.files[0])
    let files = [];
    let filename = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      if (
        data.type.split("/")[1] === "jpg" ||
        data.type.split("/")[1] === "jpeg" ||
        data.type.split("/")[1] === "png" ||
        data.type.split("/")[1] === "PNG" ||
        data.type.split("/")[1] === "gif" ||
        data.type.split("/")[1] === "mp4" ||
        data.type.split("/")[1] === "pdf" ||
        data.name.split(".")[1] === "xlsx" ||
        data.name.split(".")[1] === "csv" ||
        data.name.split(".")[1] === "doc" ||
        data.name.split(".")[1] === "docx"
      ) {
        let temp=[]
        let obj={"name":previewUrl, "format":data.name.split(".")[1]}
        temp.push(obj)
        setAttachmentUrl(temp)
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await uploadFile(fileData).then((response) => {
          let name = { name: data.name };
          filename.push(name);
          files.push(response.data);
          if(response?.data?.status === false){
            toast.error(response?.data?.message)
          }
        });
        setattachmentTypeError("");
      } else {
        setattachmentTypeError("kindly select correct file");
        setUploadDataIm(null);
      }
    }
    setAttachmentError(true);
    setAttachment(filename);
    setUploadDataIm(files);
  };
  const submitMyIdea = async (values, isDraft) => {
    values.objective = ideaObjective;
    if (dept.length == 0 && !disableIdeaDept) {
      setdepartment_id(false);
    }
    if (ideaLead === "") {
      setidea_head_id(false);
    }
    // if (attachment.length == 0) {
    //   setAttachmentError(false);
    // }
    if (typeof ideaLead === "object") {
      ideaLead.length === 0 && setidea_head_id(false);
      values.idea_head_id = [ideaLead];
    } else {
      ideaLead === "" && setidea_head_id(false);
      values.idea_head_id = [ideaLeadId];
    }
    values.department_id = dept;
    values.attachments = uploadDataIm;
    values.isDraft = isDraft;
    values.idea_area = ideaAreaAndScope.ideaArea;
    values.idea_scope = ideaAreaAndScope.ideaScope;
    let data = document.getElementById("resetForm");
    if ((dept.length != 0 || disableIdeaDept) && ideaLead.length != 0) {
      dispatch(setDisplayLoader("Display"));
      if (!isEdit) {
        await saveMyidea(values)
          .then((response) => {
            dispatch(setDisplayLoader("Hide"));
            data.click();
            getUserDetail();
            if (isDraft == true) {
              toast.success("Idea saved successfully");
            } else {
              toast.success("Saved as Draft successfully");
            }
            navigate("/myidea");
          })
          .catch((error) => {
            data.click();
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else {
        values.olddepartment_id = olddept;
        values.oldidea_head_id = oldideaLead;
        values.is_forwarded = state?.is_forwarded;
        await updateMyidea(values)
          .then((response) => {
            if(state?.from === "RM"){
              navigate("/idealead");
            }
            else{
            navigate("/myidea");
            }
            getUserDetail();
            let jai = {
              idea_area: {},
              idea_scope: {},
              department_id: [],
              idea_head_id: [],
              idea_desc: "",
              attachments: [],
            };
            Object.keys(jai).forEach((item) => {
              myidea[item] = jai[item];
            });
            getUserDetail();
            dispatch(setDisplayLoader("Hide"));
            data.click();
            toast.success("Idea updated successfully");
          })
          .catch((error) => {
            data.click();
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };
  const resetFormSuccess = () => {
    setAttachmentError(true);
    setdepartment_id(true);
    setidea_head_id(true);
    // setIdeaLead([]);
    setDept([]);
    setAttachment([]);
    setUploadDataIm([]);
    setIdeaAreaAndScope({
      ideaArea: {},
      ideaScope: {},
    });
  };

  const handleAreaChange = (e) =>{
    if(e?.label === 'Digitization'){
      setDisableIdeaScope(true)
      setDisableIdeaDept(true)
      setdepartment_id(true);
    }
    else{
      setDisableIdeaScope(false)
      setDisableIdeaDept(false)
      // setdepartment_id(false);
    }
    if(e?.notes !== null){
      setIdeaNotes(e?.notes)
      setShowNotesModal(true)
    }
    else{
      setShowNotesModal(false)
    }
    if(e?.idea_lead !== null){
      setIdeaLead(e?.idea_lead_name)
      setIdeaLeadId(e?.idea_lead)
    }
    else{
      getUserDetail()
    }
  }

  const closeModal = () =>{
    setShowNotesModal(false)
  }

  const handleIdeaObjectiveChange = (e) =>{
    setIdeaObjective(e.target.value)
    getIdeaObjectiveResult(e.target.value.toLowerCase()).then((response)=>{
      if(e.target.value.length !== 0){
      setSearchResults(response?.data?.dataList?.result)
      }
    })
    if(e.target.value.length === 0){
      setSearchResults([])
    }
  }

  const onSelectSuggestion = (index, idea_id, idea_desc) => {
    dispatch(setDisplayLoader('Display'));
    getIdeaDetailsById(idea_id)
    setSearchResults([])
  }

  const closeVoteModal = () =>{
    setOpenVoteModal(false)
    setIdeaDetails([])
  }

  const getIdeaDetailsById = (idea_id) => {
    getMyidea(
      handleKey(
        '',
        false,
        '',
        '',
        '',
        1,
        true,
        idea_id
      )
    ).then((response)=>{
      setIdeaDetails(response?.data?.dataList?.result)
      setOpenVoteModal(true)
      dispatch(setDisplayLoader('Hide'))
    })
    .catch((error) => {
      dispatch(setDisplayLoader('Hide'))
      console.log(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "failed"
      );
    })
  }

  const handleIdeaVote = (id, action) => {
    if (action === 'like'){
    setLikeFlag(true)
    }
    else{
      setLikeFlag(false)
    }
    let obj = {id: id, action: action}
    postVoteToIdea(obj).then((response)=>{
      toast.success('Your Response submitted Successfully')
    })
  }

  return (
    <>
      <ToastContainer></ToastContainer>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/shareidea">Share Your Ideas</Link>
            </li>
            <li>
              <Link to="/myidea">My Ideas</Link>
            </li>
            {userData?.have_team && (
              <li>
                <Link to="/myTeamIdea">My Team</Link>
              </li>
            )}
            {ideaRole?.is_idea_lead === true && (
              <li>
                <Link to="/idealead">Idea Leads</Link>
              </li>
            )}
            {(userData?.is_bspoc === true || useData?.is_bspoc === true) && (
              <li>
                <Link to="/idealead" state={{ component: "BSPOC" }}>
                  BSPOC
                </Link>
              </li>
            )}
            {userData?.is_idea_implementer === true && (
              <li>
                <Link to="/idea-implementation">Under Implementation</Link>
              </li>
            )}
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Share Your Idea</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Me@PI</Breadcrumb.Item>
                    <Breadcrumb.Item active>Share Your Idea</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="bg-light px-3 pt-2 mb-3">
                <div class="row viewformaftersubmit">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Name</label>
                      <p>{useData?.full_name}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Employee Code</label>
                      <p>{useData?.username}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Department</label>
                      <p>{useData?.department_name} ({useData?.department_code})</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Designation</label>
                      <p>{useData?.designation_name}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Location</label>
                      <p>{useData?.personal_sub_area_description} ({useData?.personal_sub_area_name})</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Reporting Manager</label>
                      <p>{`${useData?.reporting_to_fname} ${useData?.reporting_to_lname} (${useData?.reporting_to_name})`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Formik
                initialValues={myidea}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  submitMyIdea(values, false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Idea Area<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="idea_area"
                            placeholder="Enter Idea Area"
                            options={ideaArea}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            // closeMenuOnSelect={false}
                            onChange={(e) => {
                              setIdeaAreaAndScope((prev) => ({
                                ...prev,
                                ideaArea: e,
                              }));
                              handleAreaChange(e)
                            }}
                            isDisabled={state?.from === "RM"}
                            value={ideaAreaAndScope.ideaArea}
                          />
                          {/* {touched.idea_area && errors.idea_area ? (
                            <div className="small text-danger">
                              {errors.idea_area}
                            </div>
                          ) : null} */}
                          {/* */}
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Idea Scope<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="idea_scope"
                            placeholder="Enter Idea Scope"
                            options={ideaScope}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              setIdeaAreaAndScope((prev) => ({
                                ...prev,
                                ideaScope: e,
                              }));
                            }}
                            isDisabled={state?.from === "RM" || disableIdeaScope}
                            value={ideaAreaAndScope?.ideaScope}
                          />
                          {/* {touched.idea_scope && errors.idea_scope ? (
                            <div className="small text-danger">
                              {errors.idea_scope}
                            </div>
                          ) : null} */}
                          {/* */}
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Idea Relevance(Department){" "}
                         <span class="text-danger">*</span>
                          </label>
                          <Select
                            name="department_id"
                            isMulti
                            options={departmentData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              pushDepartment(e);
                            }}
                            value={dept}
                            isDisabled={state?.from === "RM" || disableIdeaDept}
                          />
                          {!department_idError && (
                            <div className="small text-danger">
                              Idea Relevance is required
                            </div>
                          )}
                          {/* */}
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup position-relative modaldefaultclose ">
                          <label>
                            Attachment <span class="text-danger"></span>
                          </label>
                          <input
                            type="text"
                            class="form-control bg-white"
                            disabled
                            placeholder="(Image, PDF Format)"
                          />
                          <div class="upload-btn-wrapper up-loposition">
                            <button class="uploadBtn">Browse</button>
                            <input
                              multiple
                              onChange={(e) => onProfileChange(e)}
                              type="file"
                              disabled={state?.from === "RM"}
                            />
                          </div>
                          {!attachmentError && (
                            <div className="small text-danger">
                              Attachment is required
                            </div>
                          )}
                          <div className="small text-danger">
                            {attachmentTypeError}
                          </div>
                          <p className="mt-2">
                          Selected files{" "} <br/>
                          {attachmentUrl.map((x)=>(
                            x?.format === "png" || x?.format === "jpg" || x?.format === "jpeg" ?
                            <img src={x.name} style={{height:"75px", marginLeft:"10px"}} />
                            : x?.format === "pdf" ?
                            <>
                            <a
                              href={x.name}
                              target={"_blank"}
                              rel="noreferrer"
                          >
                            <i class="fas fa-file-pdf fa-3x" style={{color: "#ca3721",}}></i>
                            </a>
                            </>
                            : x?.format === "csv" ?
                            <>
                            <a
                              href={x.name}
                              target={"_blank"}
                              rel="noreferrer"
                          >
                            <i class="fas fa-file-csv fa-3x" style={{color: "#ca3721",}}></i>
                            </a>
                            </>
                            : x?.format === "xlsx" ?
                            <>
                            <a
                              href={x.name}
                              target={"_blank"}
                              rel="noreferrer"
                          >
                            <img
                            src={"images/policy-icon/xlsx1.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                        />
                            </a>
                            </>
                            : x?.fromat === "doc" || x?.format === "docx" ?
                            <>
                            <a
                              href={x.name}
                              target={"_blank"}
                              rel="noreferrer"
                          >
                            <i class="fas fa-file-word fa-3x" style={{color: "#ca3721",}}></i>
                            </a>
                            </>
                            :""
                          ))}
                          </p>
                          {/* <p>
                            Selected files{" "}
                            {attachment.map((a) => (
                              <span>
                                {" "}
                                <br />
                                {a.name}
                              </span>
                            ))}
                          </p> */}
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Suggested Idea Lead for your idea{" "}
                         <span class="text-danger">*</span>
                          </label>
                          {state?.data?.idea_head.length > 0 ||
                          state?.from !== undefined ||
                          state?.from === "RM" ? (
                            <Select
                              name="idea_head_id"
                              options={userListData}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                pushIdeaLead(e);
                              }}
                              value={ideaLead}
                            />
                          ) : (
                            <input
                              name="idea_head_id"
                              className="form-control"
                              disabled={true}
                              value={ideaLead}
                            />
                          )}
                          {!idea_head_idError && (
                            <div className="small text-danger">
                              Suggested Idea Lead is required
                            </div>
                          )}
                          {/* */}
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div class="form-group innergroup">
                        <label>
                          Idea Objective
                        </label>
                      <input
                        name="idea_objective"
                        className="form-control"
                        placeholder="Enter Idea Objective..."
                        autoComplete="off"
                        value={ideaObjective}
                        onChange={(e)=>{
                          handleIdeaObjectiveChange(e)
                        }}
                      />
                      {searchResults?.length > 0 && (
                      <ul className="suggestions-list" id="scrollpart">
                      {searchResults?.map((suggestion, index) => {
                        // const isSelected = selectedSuggestion === index;
                        // const classname = `suggestion ${isSelected ? "selected" : ""}`;
                        return (
                          <li
                            key={index}
                            className='suggestion'
                            onClick={() => {
                              onSelectSuggestion(
                                index,
                                suggestion?.id,
                                suggestion?.idea_desc
                              );
                              setIdeaObjective("");
                            }}
                          >
                            {suggestion?.objective}
                          </li>
                        );
                      })}
                    </ul>
                    )}
                      </div>
                      </div>
                      
                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>
                            Your Idea<span class="text-danger">*</span>
                          </label>
                          <textarea
                            onBlur={handleBlur}
                            name="idea_desc"
                            value={values.idea_desc}
                            disabled={state?.from === "RM"}
                            onChange={handleChange}
                            class="form-control"
                            onFocus={()=>{
                              setSearchResults([])
                            }}
                            placeholder="Enter Your Idea..."
                          ></textarea>
                          {touched.idea_desc && errors.idea_desc ? (
                            <div className="small text-danger">
                              {errors.idea_desc}
                            </div>
                          ) : null}
                          {/* */}
                        </div>
                      </div>

                      <div class="col-md-12 text-right">
                      {state?.from === undefined && (
                        <button
                          type="button"
                          onClick={(e) => {
                            resetForm(e);
                            resetFormSuccess();
                          }}
                          class="btn btn-outline-danger mr-2"
                        >
                          Clear
                        </button>
                        )}
                        {state?.from === undefined && (
                          <button
                            onClick={() => submitMyIdea(values, true)}
                            type="button"
                            class="btn btn-outline-primary mr-2"
                          >
                            Save as Draft
                          </button>
                        )}
                        <button
                          type="button"
                          id="resetForm"
                          onClick={(e) => {
                            resetForm(e);
                            resetFormSuccess();
                          }}
                          hidden
                        ></button>
                        <button
                          type="submit"
                          class="btn btn-primary-inner bpi-main"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {/*Modal for Idea Notes */}
      <Modal
      show={showNotesModal}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          {ideaNotes}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          class="btn btn-outline-danger"
          onClick={() => {
            closeModal();
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
    {/*End Modal for Idea Notes */}

    {/* Modal for Idea Voting */}
    <Modal
      show={openVoteModal}
      onHide={closeVoteModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Idea</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div class="row justify-content-center"> */}
        {ideaDetails?.map((x, index) => (
          <div class="ideabox">
        <div class="row">
          <div class="col-md-1">
            {/* <div class="team-profile">
              <img
                src={
                  x?.profile_image != ""
                    ? x?.profile_image
                    : avtar
                }
                alt=""
              />
            </div> */}
          </div>
          <div class="col-md-8">
            <h4>
              {x?.created_by_first_name +
                " " +
                x?.created_by_last_name}{" "}
              <i class="fas fa-circle"></i>{" "}
              <span>{x?.created_by_code}</span>{" "}
              <i class="fas fa-circle"></i>{" "}
              {moment.utc(x?.created_at).format(
                "MMMM Do YYYY, h:mm a"
                )}
                {(x?.is_active === true && x?.is_draft == false) && (
                  x?.status !== "I" && x?.status !== "WD" && x?.is_active === true ?
                  <div
                  className="ml-2 mt-2 mt-lg-0 cursor-pointer" 
                  // onClick={() => {
                  //   showComment(index);
                  // }}
                  >
                  <i class="far fa-comment-dots ideacomments"></i>
                  {x?.comments.length === 0
                    ? "No Comments"
                    : `${x?.comments.length} Comments`}
              </div>
              : x?.status !== "I" && (x?.is_active === true && x?.is_draft === true) ?
              <div
              className="ml-2 mt-2 mt-lg-0 cursor-pointer" 
              
              >
                <i class="far fa-comment-dots ideacomments"></i>
                {x?.comments.length === 0
                  ? "No Comments"
                  : `${x?.comments.length} Comments`}
              </div>
              :
              <div
              className="ml-2 mt-2 mt-lg-0 cursor-pointer" 
              
              >
                <i class="far fa-comment-dots ideacomments"></i>
                {x?.comments.length === 0
                  ? "No Comments"
                  : `${x?.comments.length} Comments`}
              </div>
              
                  )}
            </h4>
            <h5>Id-{x?.id}, {x?.idea_area_name}</h5>
          </div>
          <div class="col-md-3 text-sm-right">
          <button type="button" className="btn" onClick={()=>{handleIdeaVote(x?.id, 'like')}} disabled={likeFlag}>
          <i class="fas fa-thumbs-up fa-2x" style={{color: likeFlag ? 'blue' : ""}}></i>
          </button>
          <button type="button" className="btn ml-3" onClick={()=>{handleIdeaVote(x?.id, 'dislike')}} disabled={!likeFlag}>
          <i class="fas fa-thumbs-down fa-2x" style={{color: likeFlag===false ? 'blue' : ""}}></i>
          </button>
            {/* <>
              {x?.is_draft == true ? (
                <h6>Draft</h6>
              ) : x?.status === "N" &&
                x?.bspoc_status === "N" ? (
                <h6>Submitted</h6>
              ) : x?.status == "R" ||
                x?.bspoc_status === "R" ? (
                <h6>Rejected</h6>
              ) : x?.status == "A" ||
                x?.bspoc_status === "A" ? (
                <h6>Approved</h6>
              ) : x?.status == "I" ||
                x?.bspoc_status === "I" ? (
                <h6>Implemented</h6>
              ) : x?.status == "cancel" ||
              x?.bspoc_status === "cancel" ? (
              <h6>Cancelled</h6>
            ) : x?.status == "WD" ||
            x?.bspoc_status === "WD" ? (
            <h6>Withdraw</h6>
          ) : null}
              
            
            </> */}
          </div>
        </div>

        <div class="row">
            <div class="col-md-12">
              <h6>
            {x?.idea_desc}
              </h6>
              {/* <div className="row mb-3">
                {x?.attachments.map((a) =>
                  a?.attachment?.includes(".mp4") ? (
                    <div
                      class="col-sm-1 col-3 pr-0"
                      onClick={() => {
                        setLink(a?.attachment);
                        viewnewopen();
                      }}
                    >
                      <video height="75px" width="100%">
                        <source
                          src={a?.attachment}
                          type="video/mp4"
                        />
                        Your browser does not support the video
                        tag.
                      </video>
                    </div>
                  ) : a?.attachment?.includes(".jpg") ||
                    a?.attachment?.includes(".jpeg") ||
                    a?.attachment?.includes(".png") ||
                    a?.attachment?.includes(".gif") ? (
                    <div
                      onClick={() => {
                        setLink(a?.attachment);
                        viewnewopen();
                      }}
                      class="col-sm-1 col-3 pr-0"
                    >
                      <img width="100%" src={a?.attachment}></img>
                    </div>
                  ) : a?.attachment?.includes(".pdf") ? (
                    <div class="col-sm-1 col-3 pr-0">
                      <iframe
                        onClick={() => {
                          setLink(a?.attachment);
                          viewnewopen();
                        }}
                        width="100%"
                        height="75"
                        src={a?.attachment}
                      ></iframe>
                    </div>
                  ) : null
                )}
              </div> */}
            </div>
            
            {x?.showComments && (
              <>
                {x?.comments.map((c) => (
                  <div class="col-md-12 mt-3">
                    <div class="row justify-content-end">
                      <div class="col-md-11 col-11">
                        <div class="row align-items-center inputideabox">
                          <div class="col-md-1">
                            {/* <div class="team-profile">
                              <img
                                src={
                                  x?.profile_image != ""
                                    ? x?.profile_image
                                    : avtar
                                }
                                alt=""
                              />
                            </div> */}
                          </div>
                          <div class="col-md-8">
                            <h4>
                              {c?.created_by_first_name +
                                " " +
                                c?.created_by_last_name}{" "}
                              <i class="fas fa-circle"></i>{" "}
                              <span>{c?.created_by_code}</span>{" "}
                              <i class="fas fa-circle"></i>{" "}
                              {moment
                                .utc(c?.created_at)
                                .format(
                                  "MMMM Do YYYY, h:mm a"
                                )}
                            </h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <h6>
                              {c?.tagged?.length > 0 && (
                                <span
                                  style={{ color: "blue" }}
                                >{`@${c?.tagged}  `}</span>
                              )}
                              {`${c?.comment}`}
                            </h6>
                          </div>

                          <div class="col-md-12">
                            <div class="border-top my-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        ))}
        {/* </div> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          class="btn btn-outline-danger"
          onClick={() => {
            closeVoteModal();
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
    {/*End Modal for Idea Voting */}
    </>
  );
}

export default ShareIdea;
