import React, { useEffect, useState } from "react";
import * as RoleService from "../../service/roleRightMgt";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import AddRole from "./add-role";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import avatar from "../../assets/images/avatarImg.png";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import UserDetailGrid from "./userDetailGrid";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
} from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const RoleMaster = () => {
  const location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const permission = location?.state?.permission;
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const [groupValueData, setGroupValueData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [editValue, setEditValue] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [roleValue, setRoleValue] = useState(null);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [userDetailModule, setUserDetailModule] = useState();

  const getGroupData = (search, pageNo, pageSize, sortBy) => {
    dispatch(setDisplayLoader("Display"));
    RoleService.getGroupValue(search, pageNo, pageSize, sortBy)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let dataGroup = response.data.dataList.result;
        setGroupValueData(dataGroup);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    group_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (group_id !== "" && group_id !== undefined && group_id !== null) {
      queryParm = queryParm + "&group_id=" + group_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    group_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    RoleService.getExportData(
      handleKey(search, group_id, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Module.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onAddCallBack = (response) => {
    // setShowForm(false);
    getGroupData(searchStr, 1, pageSizeNo, "-id");
  };

  const addRole = () => {
    setEditValue(false);
    setRoleValue({
      id: 0,
      name: "",
      is_active: true,
    });
    setShowForm(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onHandleUserDetail = (data) => {
    // setEditValue(false);
    setShowModal(true);
    setShowForm(false);
    setUserDetailModule(data);
  };

  const onClose = () => {
    setRoleValue(null);
    setShowForm(false);
    setSearchStr("");
    window.scrollTo(0, 0);
    getGroupData("", 1, pageSizeNo, "-id");
  };

  const editRoleMaster = (value) => {
    setShowForm(true);
    setShowModal(false);
    setEditValue(true);
    setRoleValue(value);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getGroupData(e.target.value.toLowerCase(), 1, pageSizeNo, "-id");
      setSearchStr(e.target.value);
    }
  };

  const deleteGroupData = (id) => {
    let dataId = { id: id };
    RoleService.deleteGroupData(dataId)
      .then((response) => {
        getGroupData(searchStr, 1, pageSizeNo, "-id");
        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleDelete = (id, groupName) => {
    confirmAlert({
      title: "Delete Role",
      message: `Are you sure to delete ${groupName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGroupData(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getGroupData(searchStr, activePage, pageSizeNo, "-id");
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getGroupData(data, 1, pageSizeNo, "-id");
    }
  };

  useEffect(() => {
    getGroupData(searchStr, 1, pageSizeNo, "-id");
  }, [pageSizeNo]);

  return (
    <>
      {showForm == false ? (
        <div className="content-wrapper-inner">
          <ToastContainer autoClose={1000} />
          <div className="innerheadsec">
            <div className="row">
              <div className="col-md-12">
                <h4 className="inner-page-title">Roles & Permission</h4>
                {/* <Breadcrumb>
              <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/home">Role Management</Breadcrumb.Item>
              <Breadcrumb.Item active>Roles & Permission</Breadcrumb.Item>
            </Breadcrumb> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      className="bs-tooltip-end tooltip"
                      id="overlay-example"
                    >
                      <div class="tooltip-inner text-left">
                        Search on <strong> Role Name</strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      id="searchbar"
                      type="text"
                      class="form-control"
                      placeholder="Search on Role Name..."
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-8">
                <div className="inline-spacing btn-betweenspaing">
                  <Dropdown show>
                    {/* <Dropdown.Toggle
                  variant="primary-inner dropdownbtn"
                  id="dropdown-basic"
                >
                  <img src="images/export.png" alt="" className="mr-2" />{" "}
                  Export
                </Dropdown.Toggle> */}

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              "",
                              "",
                              1,
                              pageSizeNo,
                              "-id",
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-12">
                <div className="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row adminboxes">
                <div className="col-md-6 col-lg-3">
                  <div className="card userboxes">
                    <div className="d-flex justify-content-between align-items-end mt-3">
                      <img src="images/add-role.svg" alt="" width={72} />
                      <div className="text-right">
                        {finalUrlValue?.A ? (
                          <button
                            className="btn btn-primary-inner px-3"
                            onClick={() => addRole()}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Add Role
                          </button>
                        ) : (
                          ""
                        )}
                        <p className="mt-2">Add New Role</p>
                      </div>
                    </div>
                  </div>
                </div>
                {groupValueData.map((data, index) => (
                  <>
                    <div className="col-md-6 col-lg-3" key={`_group${index}`}>
                      <div className="card userboxes">
                        <div className="d-flex align-items-center justify-content-between timelinecontent-sec">
                          <div className="roletoreluser">
                            <h6>Total {data.user_data.length} User</h6>
                          </div>
                          <div
                            className="d-flex team-multiple"
                            onClick={() => onHandleUserDetail(data)}
                          >
                            <AvatarGroup total={data.user_data.length}>
                              {data?.user_data?.map((item) => (
                                <Avatar
                                  alt="Remy Sharp"
                                  src={
                                    item?.image === "" ? avatar : item?.image
                                  }
                                />
                              ))}
                            </AvatarGroup>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div className="">
                            <h5
                              className="mb-1"
                              style={{
                                maxWidth: "170px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Tooltip title={data?.name} position="bottom">
                                {data.name}
                              </Tooltip>
                            </h5>
                            {finalUrlValue?.E ? (
                              <p>
                                <span
                                  className="text-theme cursor-pointer"
                                  onClick={() => {
                                    editRoleMaster(data);
                                  }}
                                >
                                  <strong>Edit Role</strong>
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="delteroleop">
                          <button
                            className="btn btn-blanktd text-danger"
                            onClick={() => handleDelete(data.id, data.name)}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        </div>
                        <div className="statusroleop">
                          {data.is_active === true ? (
                            <span className="badge bad-status badge-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge bad-status badge-danger">
                              Inactive
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          {groupValueData?.length == 0 ? (
            ""
          ) : (
            <div className="row">
              <div className="col-sm-5">
                <div className="sortinglist">
                  Show
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      //  setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      {showForm ? (
        <>
          <AddRole
            edit={editValue}
            showForm={showForm}
            onClose={onClose}
            editRole={roleValue}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {showModal && (
        <UserDetailGrid
          showModal={showModal}
          onCloseModal={onCloseModal}
          userDetailModule={userDetailModule}
        />
      )}
    </>
  );
};

export default RoleMaster;
