import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const VehicleReq = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle`, data);
};
export const VehicleReqUpdate = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/vehicle`, data);
};
export const VehicleReqUpdateAll = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/vehicle/terminated_by`, data);
};

export const uploadFile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getVehicleReqData = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  status
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle?page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }`;

  return axios.get(queryParm);
};
export const getVehicleReqAll = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/vehicle/all?${queryParm}`);
};

export const getVehicleAllData = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  status
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/all?page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }`;

  return axios.get(queryParm);
};
export const getVehicleRegistrationAllData = async (
  search,
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  vehicle_id,
  user_id,
  status,
  current
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/registration-reimbursement-all?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}${
    from_date === undefined || from_date === "" || from_date === null
      ? ""
      : `&from_date=${from_date}`
  }${
    to_date === undefined || to_date === "" || to_date === null
      ? ""
      : `&to_date=${to_date}`
  }${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }${
    vehicle_id === undefined || vehicle_id === "" || vehicle_id === null
      ? ""
      : `&vehicle_id=${vehicle_id}`
  }${
    user_id === undefined || user_id === "" || user_id === null
      ? ""
      : `&user_id=${user_id}`
  }${
    current === undefined || current === "" || current === null
      ? ""
      : `&current=${current}`
  }`;

  return axios.get(queryParm);
};

export const getVehicleReqDataByid = async (vehicle_id) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle?vehicle_id=${vehicle_id}&paginate=${true}`;

  return axios.get(queryParm);
};

export const receivePickList = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle/receive_pick_list`, data);
};
export const getPickList = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  emp_code,
  search
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/ssc-user?search=${search}&created_by=${emp_code}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}`;

  return axios.get(queryParm);
};

export const declinePickList = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle/decline_pick_list`, data);
};
export const getCheckExpenseList = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  emp_code,
  search
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/get_check_expense_list?search=${search}&created_by=${emp_code}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}`;

  return axios.get(queryParm);
};

export const action_on_vehicle_req = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle/action`, data);
};

export const VehicleInsurance = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle/insurance`, data);
};
export const VehicleInsuranceUpdate = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/vehicle/insurance`, data);
};
export const VehicleInsuranceUpdateAll = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/vehicle/terminated-by-insurance`, data);
};

export const getVehicleInsuranceData = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  status
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance?page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }`;

  return axios.get(queryParm);
};

export const getVehicleInsuranceAll = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/vehicle/insurance-all?${queryParm}`);
};

export const getVehicleInsuranceAllData = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  vehicle_id,
  user_id,
  status
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance-all?page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }`;

  if (vehicle_id && vehicle_id !== null)
    queryParm += `&vehicle_id=${vehicle_id}`;
  if (user_id && user_id !== null) queryParm += `&user_id=${user_id}`;

  return axios.get(queryParm);
};

export const getVehicleProjectedInsuranceAllData = async (
  search,
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  vehicle_id,
  user_id,
  status,
  current
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance-reimbursement-all?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}${
    from_date === undefined || from_date === "" || from_date === null
      ? ""
      : `&from_date=${from_date}`
  }${
    to_date === undefined || to_date === "" || to_date === null
      ? ""
      : `&to_date=${to_date}`
  }${
    status === undefined || status === "" || status === null
      ? ""
      : `&status=${status}`
  }${
    vehicle_id === undefined || vehicle_id === "" || vehicle_id === null
      ? ""
      : `&vehicle_id=${vehicle_id}`
  }${
    user_id === undefined || user_id === "" || user_id === null
      ? ""
      : `&user_id=${user_id}`
  }${
    current === undefined || current === "" || current === null
      ? ""
      : `&current=${current}`
  }`;

  if (vehicle_id && vehicle_id !== null)
    queryParm += `&vehicle_id=${vehicle_id}`;
  if (user_id && user_id !== null) queryParm += `&user_id=${user_id}`;

  return axios.get(queryParm);
};

export const getVehicleInsuranceDataByid = async (vehicle_id) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance?vehicle_id=${vehicle_id}&paginate=${true}`;

  return axios.get(queryParm);
};
export const receivePickListInsurance = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/vehicle/insurance/receive_pick_list`,
    data
  );
};
export const getPickListInsurance = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  emp_code,
  search
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance/ssc-user?search=${search}&created_by=${emp_code}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}`;

  return axios.get(queryParm);
};

export const declinePickListInsurance = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/vehicle/insurance/decline_pick_list`,
    data
  );
};
export const getCheckExpenseListInsurance = async (
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  emp_code,
  search
) => {
  let queryParm = `${AUTH_BASE_URL}/vehicle/insurance/get_check_expense_list?search=${search}&created_by=${emp_code}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}&from_date=${from_date}&to_date=${to_date}`;

  return axios.get(queryParm);
};

export const action_on_vehicle_Insurance = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/vehicle/insurance/action`, data);
};

export const exportInsuranceExcel = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/vehicle/insurance/export?sort_by=id&${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const exportRegistrationExcel = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/vehicle/export?sort_by=id&${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const expotr_getVehicleInsuranceAll = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/vehicle/insurance-all?sort_by=id&${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const exportInsuranceMaintenanceExcel = async (search,
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  status,
  vehicle_id,
  user_id,
  current
) => {
  return axios({
    url: `${AUTH_BASE_URL}/vehicle/insurance-maintenance/export?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}${
      from_date === undefined || from_date === "" || from_date === null
        ? ""
        : `&from_date=${from_date}`
    }${
      to_date === undefined || to_date === "" || to_date === null
        ? ""
        : `&to_date=${to_date}`
    }${
      status === undefined || status === "" || status === null
        ? ""
        : `&status=${status}`
    }${
      vehicle_id === undefined || vehicle_id === "" || vehicle_id === null
        ? ""
        : `&vehicle_id=${vehicle_id}`
    }${
      user_id === undefined || user_id === "" || user_id === null
        ? ""
        : `&user_id=${user_id}`
    }${
      current === undefined || current === "" || current === null
        ? ""
        : `&current=${current}`
    }`,
    method: "GET",
    responseType: "blob",
  });
};

export const exportRegistrationMaintenanceExcel = async (search,
  pageNo,
  pageSize,
  sortBy,
  paginate,
  from_date,
  to_date,
  status,
  vehicle_id,
  user_id,
  current
) => {
  return axios({
    url: `${AUTH_BASE_URL}/vehicle/maintenance/export?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}${
      from_date === undefined || from_date === "" || from_date === null
        ? ""
        : `&from_date=${from_date}`
    }${
      to_date === undefined || to_date === "" || to_date === null
        ? ""
        : `&to_date=${to_date}`
    }${
      status === undefined || status === "" || status === null
        ? ""
        : `&status=${status}`
    }${
      vehicle_id === undefined || vehicle_id === "" || vehicle_id === null
        ? ""
        : `&vehicle_id=${vehicle_id}`
    }${
      user_id === undefined || user_id === "" || user_id === null
        ? ""
        : `&user_id=${user_id}`
    }${
      current === undefined || current === "" || current === null
        ? ""
        : `&current=${current}`
    }`,
    method: "GET",
    responseType: "blob",
  });
};
