import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "./../ModularTable";
import {
  getCropScenario,
  fetchCropScenario,
  getCompanyProduct,
  getCropPlanDropdownCrops,
  getCropScenarioApprovalLogs,
  getCropScenarioDropDown,
  getCropStagesDropDown,
  getCurrentFy,
  getGenricProduct,
  getPestData,
  getProductCategory,
  getStagesDates,
  postCropScenario,
  postCropScenarioAction,
  putCropScenario,
} from "../../../../service/AgriPortalService/CropScenarioService";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";

const Root = (props) => {
  //ALL USESTATES
  const [cfy, setCfy] = useState("");
  const [cropDropdown, setCropDropdown] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState("");
  const [selectedSeason, setSelectedSeason] = useState({});
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [cropView, setCropView] = useState({});
  const [finalCropView, setFinalCropView] = useState({});
  const [approvalList, setApprovalList] = useState([]);
  const [freezeCTA, setFreezeCTA] = useState(false);
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [existingData, setExistingData] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoader(true);
      getCropScenario()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLoader(false);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => {
    invokeLoader();
  }, [loader]);

  useEffect(() => {
    if (!mode) return;
    let query = "/mymarket/cropscenario";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //ALL USEEFFECTS
  //TO LOAD CURRENT FY
  useEffect(() => {
    getCurrentFy()
      .then((res) => {
        setCfy(res.data.dataList.fy);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, []);

  //TO SET SELECTED CROP AT 0 OF THE ARRAY WHEN DROPDOWN CHANGE
  //TO SET THE SEASON AT 0 OF SELECTED CROP WHEN DROPDOWN CHANGE
  useEffect(() => {
    if (!cropDropdown || cropDropdown.length == 0) return;

    setSelectedCrop(cropDropdown[0]);
    setSelectedSeason(cropDropdown[0]?.season_data[0]);
  }, [cropDropdown]);

  //TO SET THE SEASON AT 0 WHEN SELECTED CROP CHANGE
  useEffect(() => {
    if (!selectedCrop || !selectedCrop?.season_data) return;
    if (!selectedSeason) {
      if (
        selectedCrop?.season_data[0] == "R" ||
        selectedCrop?.season_data[0] == "K" ||
        selectedCrop?.season_data[0] == "N"
      )
        setSelectedSeason(selectedCrop?.season_data[0]);
      else setSelectedSeason(selectedCrop?.season_data[0]);
    }
  }, [selectedCrop]);

  //FETCH DATA FOR THE TABLE WHEN SELECTED CROP OR SEASON CHANGES
  useEffect(() => {
    if (
      !selectedCrop ||
      !selectedSeason ||
      !cropView ||
      (cropView[selectedCrop?.crop_id] &&
        cropView[selectedCrop?.crop_id][selectedSeason?.season])
    )
      return;

    fetchCrop(selectedCrop?.crop_id, selectedSeason?.season)
      .then((res) => {
        setLoader(false);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [selectedCrop, selectedSeason, cropView, locationData]);

  useEffect(() => {
    if (Object.keys(locationData).length != 0) {
      if (mode == "create" || mode == "edit") {
        getCropScenarioDropDown(locationData?.territory_code)
          .then(() => { })
          .catch((err) => {
            {
              toast.error(
                err?.response?.data?.detail
              );
              setTimeout(() => {
                navigate("/mymarket/croppattern");
              }, 1000);
            }
          });
      }
    }
  }, [mode, locationData]);

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit. Any Row having "None" in any column will not be saved in the system`
          : `This is a draft, You can edit it anytime. Any Row having "None" in any column will not be saved in the system`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  //TO CONVERT DATA FROM INTERNAL STATE TO API SENDABLE FORMAT
  const convertCategoryData = (data) => {
    const ans = [];
    if (!data) return;
    let objectKeys = Object.keys(data);
    objectKeys = objectKeys.filter((ele) => ele != "undefined");
    let patternObj = [];
    for (let i = 0; i < objectKeys.length; i++) {
      let plan = data[objectKeys[i]];
      Object.keys(plan)?.map((ele, index) => {
        let plan_data = { season: Object.keys(plan)[index] };
        let crop_id = parseInt(objectKeys[i]);
        plan_data["crop_id"] = parseInt(objectKeys[i]);
        plan_data["starting_date"] = selectedSeason.starting_date;
        let stageData = [];
        const existing_id = existingData.findIndex(
          (el) =>
            el.crop_id == crop_id && el?.season == Object.keys(plan)[index]
        );
        if (existing_id == -1) {
          plan_data["id"] = 0;
        } else {
          plan_data["id"] = existingData[existing_id].id;
        }
        Object.values(plan)[index]?.map((ele, i) => {
          let temp_data = ele;
          // if (i == 0) {
          //     if (temp_data[0].meta?.fulldetails?.id) {
          //         plan_data["id"] = temp_data[0].meta.fulldetails.id
          //     } else {
          //         plan_data["id"] = 0
          //     }
          // }
          let obj = {
            id: temp_data[0].meta?.details?.id
              ? temp_data[0].meta.details.id
              : 0,
            stage_id: temp_data[0].meta.raw.id,
            week_from: temp_data[1].value[0],
            week_to: temp_data[2].value[0],
            category_id: temp_data[3].meta?.raw?.find(
              (el) => el?.name == temp_data[3]?.selected
            )?.id === undefined ? null : temp_data[3].meta?.raw?.find(
              (el) => el?.name == temp_data[3]?.selected
            )?.id,
            pest_id: temp_data[4].meta?.raw?.find(
              (el) => el?.name == temp_data[4]?.selected
            )?.id === undefined ? null : temp_data[4].meta?.raw?.find(
              (el) => el?.name == temp_data[4]?.selected
            )?.id,
            company_product_1_id: temp_data[5].meta?.raw?.find(
              (el) => el?.name == temp_data[5]?.selected
            )?.id === undefined ? null : temp_data[5].meta?.raw?.find(
              (el) => el?.name == temp_data[5]?.selected
            )?.id,
            company_product_2_id: temp_data[6].meta?.raw?.find(
              (el) => el?.name == temp_data[6]?.selected
            )?.id === undefined ? null : temp_data[6].meta?.raw?.find(
              (el) => el?.name == temp_data[6]?.selected
            )?.id,
            molecule_1_id: temp_data[7].meta?.raw?.find(
              (el) => el?.name == temp_data[7]?.selected
            )?.id == undefined ? null : temp_data[7].meta?.raw?.find(
              (el) => el?.name == temp_data[7]?.selected
            )?.id,
            molecule_2_id: temp_data[8].meta?.raw?.find(
              (el) => el?.name == temp_data[8]?.selected
            )?.id === undefined ? null : temp_data[8].meta?.raw?.find(
              (el) => el?.name == temp_data[8]?.selected
            )?.id,
            new_molecule_id: temp_data[9].meta?.raw?.find(
              (el) => el?.name == temp_data[9]?.selected
            )?.id === undefined ? null : temp_data[9].meta?.raw?.find(
              (el) => el?.name == temp_data[9]?.selected
            )?.id,
          };
          stageData.push(obj);
        });
        plan_data["stage_data"] = stageData;
        patternObj.push(plan_data);
      });

    }
    for (let i = 0; i < patternObj.length; i++) {
      patternObj[i].stage_data = patternObj[i].stage_data.filter(
        (el) =>
          !(
            el.category_id == undefined && el?.id === 0
            // el.company_product_1_id == undefined ||
            // el.company_product_2_id == undefined ||
            // el.molecule_1_id == undefined ||
            // el.molecule_2_id == undefined ||
            // el.new_molecule_id == undefined ||
            // el.pest_id == undefined
          )
      );
    }
    // for(let i=0;i<Object.keys(data).length,i++)
    // for(let i=0;i<Object.keys(data).length;i++){
    //     for(let j=0;j<Object.keys(Object.keys(data)[i]).length;j++){
    //         let patternObj =  {};
    //         if(!data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]] || data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]]?.length == 0 || data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]][0]?.length == 0 || !data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]][0][0]?.meta?.fulldetails)
    //         patternObj.id=0;
    //         else
    //         patternObj.id = data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]][0][0]?.meta?.fulldetails?.id;
    //         patternObj.crop_id=Object.keys(data)[i];
    //         patternObj.season = Object.keys(data[Object.keys(data)[i]])[j];
    //         const crop = cropDropdown?.find(el=>el?.crop_id==Object.keys(data)[i]);
    //         patternObj.starting_date = crop?.season_data?.find(el=>el?.season==Object.keys(data[Object.keys(data)[i]])[j])?.starting_date;
    //         patternObj.stage_data =[];

    //         for(let k=0;k<data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]]?.length;k++){
    //             let stageObj = {};

    //             const d = data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]][k];
    //             if(!d[0]?.meta?.details)
    //             stageObj.id = 0;
    //             else
    //             stageObj.id = d[0]?.meta?.details?.id;

    //             stageObj.stage_id= d[0]?.meta?.raw?.id;
    //             stageObj.week_from= d[1]?.value[0];
    //             stageObj.week_to =d[2]?.value[0];
    //             const catId = d[3]?.meta?.raw?.find(el=>el?.name==d[3]?.selected)?.id;
    //             stageObj.category_id=catId;
    //             stageObj.pest_id = d[4]?.meta?.raw?.find(el=>el?.name==d[4]?.selected)?.id;
    //             stageObj.company_product_1_id = d[5]?.meta?.raw?.find(el=>el?.name==d[5]?.selected)?.id;
    //             stageObj.company_product_2_id = d[6]?.meta?.raw?.find(el=>el?.name==d[6]?.selected)?.id;
    //             stageObj.molecule_1_id = d[7]?.meta?.raw?.find(el=>el?.name==d[7]?.selected)?.id;
    //             stageObj.molecule_2_id = d[8]?.meta?.raw?.find(el=>el?.name==d[8]?.selected)?.id;
    //             stageObj.new_molecule_id = d[9]?.meta?.raw?.find(el=>el?.name==d[9]?.selected)?.id;

    //             let undefFound = false;
    //             for(let h = 0;h<Object.keys(stageObj).length;h++){
    //                 if(stageObj[Object.keys(stageObj)[h]]==undefined){
    //                     undefFound = true;
    //                     break;
    //                 }
    //             }

    //             if(!undefFound)
    //             patternObj.stage_data.push(stageObj);
    //         }
    //         console.log("CROPSCENARIO=>",patternObj)

    //         if(!patternObj.stage_data || patternObj.stage_data.length ==0) continue;

    //         let undefFound = false;
    //         for(let h = 0;h<Object.keys(patternObj).length;h++){
    //             if(patternObj[Object.keys(patternObj)[h]]==undefined){
    //                     undefFound = true;
    //                     break;
    //             }
    //         }
    //         if(!undefFound)
    //         ans.push(patternObj);
    //     }
    // }
    return patternObj;
  };

  //CHANGE SELECTED SEASON IN STATE WHEN USER CHANGES IT ON UI
  const changeSelectedSeason = (season) => {
    // console.log("test123456789",selectedSeason,selectedCrop)
    let index = selectedCrop?.season_data?.findIndex(
      (el) => el?.season == season
    );
    if (index == -1) return;
    setSelectedSeason(selectedCrop?.season_data[index]);
  };

  const changeSelectedCropSeason = (crop, season) => {
    let index = crop?.season_data?.findIndex((el) => el?.season == season);
    if (index == -1) return;
    setSelectedSeason(crop?.season_data[index]);
  };

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    setFinalCropView((state) => {
      let newState = { ...state };
      if (!newState[selectedCrop.crop_id]) newState[selectedCrop.crop_id] = {};

      newState[selectedCrop.crop_id][selectedSeason?.season] = data;
      return newState;
    });
  };

  //CALLED EVERYTIME IN USEEFFECT WHEN EITHER SELECTED CROP OR SEASON CHANGES
  const fetchCrop = async (crop_id, season) => {
    if (Object.keys(locationData).length == 0) return;
    let data = [[[]]];

    let cropViewState;

    try {
      setLoader(true);
      //FECTH ALL THE CROPS FROM DROPDOWN
      const crops = await getCropScenarioDropDown(locationData?.territory_code);

      if (!crops.data.dataList || crops.data.dataList.length == 0)
        return setLoader(false);

      //GET THE INDEX OF DROPDOWN OBJ REPRESENTING CURRENT CROP
      const idx = crops?.data?.dataList?.findIndex(
        (el) => el.crop_id == crop_id
      );

      if (idx == -1) return setLoader(false);

      const query = `?crop_id=` + crops.data.dataList[idx].crop_id;

      //FETCH STAGES OF CURRENT CROP
      let cropStages;
      try {
        cropStages = await getCropStagesDropDown(query);
      } catch (err) {
        toast.error(
          "Failed to fetch crop stages for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!cropStages.data.dataList) {
        toast.error(
          "Crop stages unfetched for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }

      //FETCH PESTS FOR CURRENT CROP
      let pestData;
      try {
        pestData = await getPestData(query);
      } catch (err) {
        toast.error(
          "Failed to fetch pests for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!pestData.data.dataList) {
        toast.error(
          "Pest data unfetched for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }

      //FETCH CATEGORY
      let productCat;
      try {
        productCat = await getProductCategory();
      } catch (err) {
        toast.error(
          "Failed to product category for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!productCat.data.dataList || productCat.data.dataList.length == 0)
        return setLoader(false);

      //FETCH INDEX OF SEASON OBJECT IN CROP OBJECT USED FROM DROPDOWN DATA
      const sidx = crops?.data?.dataList[idx]?.season_data?.findIndex(
        (el) => el?.season == season
      );
      if (sidx == -1) return setLoader(false);
      //LOOPING OVER ALL THE STAGES OF CURRENT CROP
      setLoader(true);
      let cropview = [];
      for (let j = 0; j < cropStages.data.dataList.length; j++) {
        let stageQuery =
          "?crop_id=" +
          crops.data.dataList[idx].crop_id +
          "&season=" +
          crops.data.dataList[idx]?.season_data[sidx]?.season +
          "&stage_id=" +
          cropStages.data.dataList[j].id +
          "&pattern_trans_id=" +
          crops.data.dataList[idx].pattern_trans_id;

        //FETCHING WEEK FROM AND WEEK TO OF THE CURRENT STAGE OF SELELECTED CROP
        let durations;
        try {
          durations = await getStagesDates(stageQuery);
        } catch (err) {
          toast.error(
            "Failed to fetch Durations for stage_id=" +
            cropStages.data.dataList[j].id
          );
          continue;
        }
        if (!durations.data.dataList) {
          continue;
        }
        let week_from = durations.data.dataList.week_from;
        let week_to = durations.data.dataList.week_to;

        //PREPARE ROW DATA FOR CURRENT STAGE OF CURRENT CROP USING ALL THE DATA FETCHED ABOVE
        let row = [
          {
            value: [cropStages.data.dataList[j].name],
            meta: {
              raw: cropStages.data.dataList[j],
            },
          },
          {
            value: [week_from],
            meta: {
              id: "",
            },
          },
          {
            value: [week_to],
            meta: {
              id: "",
            },
          },
          {
            value: productCat.data.dataList.map((el) => el.name),
            meta: {
              raw: productCat?.data?.dataList,
            },
          },
          {
            value: pestData.data.dataList.map((el) => el.name),
            meta: {
              raw: pestData.data.dataList,
            },
            selected: pestData.data.dataList[0].name,
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
        ];

        //PUSH CREATED ROW TO CROPVIEW OBJECT
        cropview.push(row);
      }

      //IF MODE IS EQUAL TO CREATE SET THE CREATED 2D ARRAY REPRESENTING TABLE FOR CURRENT CROP AND CURRENT SEASON
      if (mode == "create")
        setCropView((state) => {
          let newState = { ...state };

          if (!newState[crops.data.dataList[idx].crop_id])
            newState[crops.data.dataList[idx].crop_id] = {};

          newState[crops.data.dataList[idx].crop_id][
            crops.data.dataList[idx]?.season_data[sidx]?.season
          ] = cropview;

          cropViewState = newState;
          return newState;
        });
      //ELSE JUST ASSIGN IT TO CROPVIEWSTATE FOR FURTHER USE
      else {
        let newState = JSON.parse(JSON.stringify({ ...cropView }));

        if (!newState[crops.data.dataList[idx].crop_id])
          newState[crops.data.dataList[idx].crop_id] = {};

        newState[crops.data.dataList[idx].crop_id][
          crops.data.dataList[idx]?.season_data[sidx]?.season
        ] = cropview;

        cropViewState = newState;
      }
      setLoader(false);
    } catch (err) {
      toast.error("Crop Pattern should be Submitted before Crop Scenario");
      setTimeout(() => {
        navigate("/mymarket/croppattern");
      }, 1000);
    }

    //UPDATE THE CREATED DATA IF THERE IS ANY ENTRY FOR THE SAME
    const params = new URLSearchParams(window.location.search);
    if (params.get("mode") != "create")
      try {
        let q = "?territory_scenario_id=" + params.get("id");

        //FETCH DATA OF LAST SAVE
        setLoader(true);
        let cropScene = await fetchCropScenario(q);
        setExistingData(cropScene.data.dataList.crops_data);
        if (cropScene.data.dataList) {
          cropScene = cropScene.data.dataList;

          //GET INDEX OF CURRENT SELECTED CROP OBJECT
          let idx = cropScene?.crops_data?.findIndex(
            (el) => el.crop_id == crop_id && el?.season == season
          );

          //IF WE GET INDEX MOVE FURTHER
          if (idx != -1) {
            let crop = cropScene?.crops_data[idx];

            //IF THERE IS ENTRY UPDATE
            if (
              cropViewState[crop.crop_id] &&
              cropViewState[crop.crop_id][crop.season]
            ) {
              setLoader(true);
              //LOOP OVER ALL THE STAGE DATA WE GOT FROM LAST SAVE TO UPDATE THE SAME ON CURRENT STATE
              for (let j = 0; j < crop?.stage_data?.length; j++) {
                const stageEdit = crop?.stage_data[j];
                if (stageEdit?.category_id) {

                  //FIND ROW INDEX OF CURRENT STAGE ROW IN OUR INTERNAL STATE SO WE CAN UPDATE IT WITH STAGE DATA FETCHED
                  const stageIdx = cropViewState[crop.crop_id][
                    crop.season
                  ].findIndex((el) => el[0]?.meta?.raw?.id == stageEdit.stage_id);
                  if (stageIdx == -1) continue;

                  //UPDATING INPUT STATE BASED ON FETCHED DATA
                  cropViewState[crop.crop_id][crop.season][stageIdx][3].selected =
                    stageEdit?.category_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][4].selected =
                    stageEdit?.pest_name;

                  //FETCH COMPANY PRODUCT, GENRIC PRODUCT (PRODUCT) AND NEW PRODUCT DROPDOWN DATA BASED ON CATEGORY IN FETCHED DATA
                  const query =
                    "?category_id=" +
                    stageEdit?.category_id +
                    "&crop_id=" +
                    crop?.crop_id;
                  const query2 =
                    "?category_id=" + stageEdit?.category_id + "&is_new=false";
                  const query3 =
                    "?category_id=" + stageEdit?.category_id + "&is_new=true";

                  let cp = await getCompanyProduct(query);
                  if (!cp.data || !cp.data.dataList) continue;
                  cp = cp.data.dataList;

                  let gp1 = await getGenricProduct(query2);
                  let gp2 = await getGenricProduct(query3);
                  if (
                    !gp1.data ||
                    !gp1.data.dataList ||
                    !gp2.data ||
                    !gp2.data.dataList
                  )
                    continue;

                  gp1 = gp1.data.dataList;
                  gp2 = gp2.data.dataList;

                  //SET ALL THE FETCHED DATA IN OUR CROPVIEWSTATE VARIABLE
                  cropViewState[crop.crop_id][crop.season][
                    stageIdx
                  ][0].meta.details = stageEdit;
                  cropViewState[crop.crop_id][crop.season][
                    stageIdx
                  ][0].meta.fulldetails = crop;
                  cropViewState[crop.crop_id][crop.season][stageIdx][5].selected =
                    stageEdit?.company_product_1_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][5].value =
                    cp?.map((el) => el.name);
                  cropViewState[crop.crop_id][crop.season][stageIdx][5].meta.raw =
                    cp;

                  cropViewState[crop.crop_id][crop.season][stageIdx][6].selected =
                    stageEdit?.company_product_2_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][6].value =
                    cp?.map((el) => el.name);
                  cropViewState[crop.crop_id][crop.season][stageIdx][6].meta.raw =
                    cp;

                  cropViewState[crop.crop_id][crop.season][stageIdx][7].selected =
                    stageEdit?.molecule_1_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][7].value =
                    gp1?.map((el) => el.name);
                  cropViewState[crop.crop_id][crop.season][stageIdx][7].meta.raw =
                    gp1;

                  cropViewState[crop.crop_id][crop.season][stageIdx][8].selected =
                    stageEdit?.molecule_2_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][8].value =
                    gp1?.map((el) => el.name);
                  cropViewState[crop.crop_id][crop.season][stageIdx][8].meta.raw =
                    gp1;

                  cropViewState[crop.crop_id][crop.season][stageIdx][9].selected =
                    stageEdit?.new_molecule_name;
                  cropViewState[crop.crop_id][crop.season][stageIdx][9].value =
                    gp2?.map((el) => el.name);
                  cropViewState[crop.crop_id][crop.season][stageIdx][9].meta.raw =
                    gp2;
                }
              }
              setLoader(false);
            }
          }

          //FINALLY SET THE CROPVIEW STATE WITH UPDATED DATA
          setCropView(cropViewState);
        }
      } catch (err) {
        console.log(err);
        toast.error("Ops! Something went wrong!");
      }

    //TO REMOVE ALL THE STAGES ROW'S WHICH DON'T HAVE ALL DATA PREFILLED
    if (params.get("mode") == "view")
      try {
        for (let i = 0; i < Object.keys(cropViewState)?.length; i++) {
          for (
            let j = 0;
            j <
            Object.keys(cropViewState[Object.keys(cropViewState)[i]])?.length;
            j++
          ) {
            const crop =
              cropViewState[Object.keys(cropViewState)[i]][
              Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
              ];

            let arr = [];
            for (let k = 0; k < crop?.length; k++) {
              const stage = crop[k];

              //ONLY PUSH ROW'S WHICH HAVE ALL THE DATA FILLED
              if (
                !stage[3].selected ||
                !stage[4].selected ||
                !stage[5].selected ||
                !stage[6].selected ||
                !stage[7].selected ||
                !stage[8].selected ||
                !stage[9].selected
              ) {
              } else
                arr.push(
                  cropViewState[Object.keys(cropViewState)[i]][
                  Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
                  ][k]
                );
            }

            cropViewState[Object.keys(cropViewState)[i]][
              Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
            ] = arr;
          }
        }

        //SET ALL THE FILTERED DATA
        setCropView(cropViewState);
      } catch (err) {
        console.log(err);
        toast.error("Ops! Something went wrong!");
      }
    //SET ISLOADING TO FALSE SO TABLE CAN RENDER
    setLoader(false);
  };

  //CALLED INITIALLY TO SET CROP DROPDOWN STATE
  const setCropViewHelper = async () => {
    if (Object.keys(locationData).length == 0) return;
    try {
      //FETCH CROP DROPDOWN
      let crops = await getCropScenarioDropDown(locationData?.territory_code);
      if (!crops.data.dataList || crops.data.dataList.length == 0) return;
      crops = crops.data.dataList;

      //FETCHING CROPS FROM CROP-PLAN DROPDOWN AS IT GIVES CROPS WHO'S DATA HAS BEEN SUBMITED IN CROP SCENARIO
      //ANYWAYS REQUIRE STARTING DATE IN BODY FROM API WITH SEASON TO RENDER CORRESPONDING STARTING DATE
      const params = new URLSearchParams(window.location.search);
      if (params.get("mode") == "view") {
        // let q = "?territory_scenario_id="+params.get("id");
        // let cropScene = await fetchCropScenario(q);

        // if(cropScene&&cropScene?.data?.dataList)
        // crops=crops?.filter(el=>{
        //     for(let i=0;i<cropScene?.data?.dataList?.crops_data?.length;i++){
        //         if(el.crop_id == cropScene?.data?.dataList?.crops_data[i]?.crop_id) return true;
        //     }
        //     return false;
        // });

        crops = await getCropPlanDropdownCrops(locationData?.territory_code);
        if (
          !crops ||
          !crops?.data?.dataList ||
          crops?.data?.dataList?.length == 0
        )
          return;
        crops = crops.data.dataList;
      }

      //SET DROPDOWN CROPS AND SET FIRST CROP AS SELECTED
      setCropDropdown(crops);
      setSelectedCrop(crops[0]);
    } catch (err) {
      toast.error(err?.response?.data?.detail);
      setTimeout(() => {
        navigate("/mymarket/croppattern");
      }, 1000);
    }
  };

  //CALLED INITIALLY TO LOAD AND SET DROPDOWN STATE AND CROP SCENARIO APPROVAL TABLE IN CASE OF EDIT
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setCropViewHelper()
      .then((res) => { })
      .catch((err) => console.log(err));
    setMode(params.get("mode"));

    let query = "";
    setPageId(params.get("id"));
    if (params.get("mode") != "create") {
      query = "?scenario_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getCropScenarioApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }
  }, [locationData]);

  //SETTING COLUMN SETTINGS BASED ON CONDITIONS
  let columnSettings;
  if (mode != "view" && mode != "approve")
    columnSettings = [
      {
        name: "Stages",
        type: "T",
        isEditable: false,
      },
      {
        name: "Week from",
        type: "T",
        isEditable: false,
      },
      {
        name: "Week to",
        type: "T",
        isEditable: false,
      },
      {
        name: "Category",
        type: "D",
        isEditable: true,
      },
      {
        name: "Pest",
        type: "D",
        isEditable: true,
      },
      {
        name: "Company Product",
        type: "D",
        isEditable: true,
      },
      {
        name: "Company Product",
        type: "D",
        isEditable: true,
      },
      {
        name: "Product",
        type: "D",
        isEditable: true,
      },
      {
        name: "Product",
        type: "D",
        isEditable: true,
      },
      {
        name: "New Product",
        type: "D",
        isEditable: true,
      },
    ];
  else
    columnSettings = [
      {
        name: "Stages",
        type: "T",
        isEditable: false,
      },
      {
        name: "Week from",
        type: "T",
        isEditable: false,
      },
      {
        name: "Week to",
        type: "T",
        isEditable: false,
      },
      {
        name: "Category",
        type: "D",
        isEditable: false,
      },
      {
        name: "Pest",
        type: "D",
        isEditable: false,
      },
      {
        name: "Company Product",
        type: "D",
        isEditable: false,
      },
      {
        name: "Company Product",
        type: "D",
        isEditable: false,
      },
      {
        name: "Product",
        type: "D",
        isEditable: false,
      },
      {
        name: "Product",
        type: "D",
        isEditable: false,
      },
      {
        name: "New Product",
        type: "D",
        isEditable: false,
      },
    ];

  //CALLED ON SUBMIT
  const onSubmitHandler = async (event) => {
    if (freezeCTA) return;

    // setFreezeCTA(true);
    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot submit as cfy could not be fetched!");
    }

    cfy = cfy.data.dataList.fy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const pattern_data = convertCategoryData(finalCropView);

    if (!pattern_data) return;
    body.pattern_data = pattern_data;

    if (mode == "create") {
      body.id = 0;
      postCropScenario(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 409) {
            toast.error("Crop Pattern should be submitted first.");
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropScenario(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.code === 409) {
            toast.error("Crop Pattern should be submitted first.");
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        });
    }
  };

  //CALLED ON SAVE
  const onSaveHandler = async (e) => {
    // if (freezeCTA) return;

    // setFreezeCTA(true);
    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot save as cfy could not be fetched!");
    }

    cfy = cfy.data.dataList.fy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const pattern_data = convertCategoryData(finalCropView);
    if (!pattern_data) return;
    body.pattern_data = pattern_data;
    if (mode == "create") {
      body.id = 0;
      postCropScenario(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropScenario(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/cropscenario");
          }, 1000);
        });
    }
  };

  //CALLED ON CANCEL
  const onCancelHandler = () => {
    return navigate("/mymarket/cropscenario");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      scenario_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postCropScenarioAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };
  function invokeLoader() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  }

  //JSX
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <div className={styles["heading"]}>
                <h4 class="inner-page-title">Crop Scenario</h4> */}
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crop Scenario</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}
        {/* </div> */}
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Crop Scenario
          </h4>
          {/* <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            RS IN LAKHS
          </p> */}
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div
            className={styles["left"]}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedCrop?selectedCrop.crop_name:"Crop"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    cropDropdown && cropDropdown.map(el=>{
                                        return <Dropdown.Item href="#" onClick={e=>setSelectedCrop(el)}>
                                           {el.crop_name}
                                        </Dropdown.Item>
                                    })  
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                value={selectedCrop.crop_name}
                onChange={(e) => {
                  setSelectedCrop(
                    cropDropdown?.find((el) => el?.crop_name == e.target.value)
                  );
                  setSelectedSeason(
                    cropDropdown.find((el) => el?.crop_name == e.target.value)
                      ?.season_data[0]
                  );
                }}
              >
                {cropDropdown &&
                  cropDropdown?.map((el) => {
                    return <option value={el.crop_name} label={el.crop_name} />;
                  })}
              </select>
            </div>
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedSeason.season=="R"?"Rabi":""}
                                {selectedSeason.season=="K"?"Kharif":""}
                                {selectedSeason.season=="N"?"None":""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    selectedCrop?.season_data && selectedCrop.season_data?.map((el,idx)=>{
                                        return <Dropdown.Item href="#" onClick={()=>changeSelectedSeason(idx)}>
                                        {el.season=="R"?"Rabi":""}
                                        {el.season=="K"?"Kharif":""}
                                        {el.season=="N"?"None":""}
                                    </Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                // classNamePrefix="select"
                value={selectedSeason.season}
                onChange={(e) => {
                  changeSelectedSeason(e.target.value);
                }}
              >
                {selectedCrop?.season_data &&
                  selectedCrop?.season_data.map((el, idx) => {
                    let s;
                    if (el?.season == "R") s = "Rabi";
                    if (el?.season == "K") s = "Kharif";
                    if (el?.season == "N") s = "None";
                    return <option value={el.season} label={s} />;
                  })}
              </select>
            </div>
            <div>
              <p
                style={{
                  margin: "0",
                  color: "#0195D4",
                  padding: "4px 8px",
                  border: "1px solid #0195D4",
                  borderRadius: "4px",
                }}
              >
                {selectedSeason.starting_date}
              </p>
            </div>
          </div>
          <div className={styles["right"]}></div>
        </div>

        {existingData.length > 0 &&
          (mode == "edit" || mode == "view" || mode == "approve") && (
            <div className={styles["table_contain"]}>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Data Entered For</b>
                </div>
              </div>
              <div className={styles["agri-portal-table"]}>
                <div
                  class="table-responsive"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <table
                    class="table table-striped table-bordered tablecured"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ textAlign: "center" }}>
                        <th>Crop Name</th>
                        <th>Crop Season</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingData.map((ele) => {
                        let season = "";
                        switch (ele.season) {
                          case "K":
                            season = "Kharif";
                            break;
                          case "R":
                            season = "Rabi";
                            break;
                          case "N":
                            season = "None";
                        }
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{ele?.crop_name}</td>
                            <td>{season}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                console.log(
                                  "SEASONNNNN_>",
                                  selectedCrop,
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  ),
                                  selectedSeason,
                                  ele?.season
                                );
                                setSelectedCrop(
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  )
                                );
                                if (
                                  selectedCrop ==
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  ) &&
                                  selectedSeason != ele?.season
                                ) {
                                  changeSelectedSeason(ele?.season);
                                }
                                if (
                                  selectedCrop !=
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  ) &&
                                  selectedSeason != ele?.season
                                ) {
                                  changeSelectedCropSeason(
                                    cropDropdown?.find(
                                      (el) => el?.crop_name == ele?.crop_name
                                    ),
                                    ele?.season
                                  );
                                }
                              }}
                            >
                              {mode == "view" ? "View" : "Edit"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

        {!isLoading && (
          <>
            <div className={styles["table_contain"]}>
              {cropView[selectedCrop?.crop_id] &&
                cropView[selectedCrop?.crop_id][selectedSeason?.season]?.length !=
                0 ? (
                <div className={styles["agri-portal-table"]}>
                  <ModularTable
                    onTableDataChange={onTableDataChange}
                    crop_id={selectedCrop.crop_id}
                    columns={columnSettings}
                    data={
                      cropView[selectedCrop.crop_id]
                        ? cropView[selectedCrop.crop_id][selectedSeason.season]
                        : undefined
                    }
                  />
                </div>
              ) : (
                <div className={styles["nodata"]}>
                  <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
                    No Stages Available
                  </h4>
                </div>
              )}
            </div>
            {approvalList?.approvals && approvalList?.approvals.length > 0 && (
              <div className={styles["table_contain"]}>
                <div className={styles["agri-portal-table"]}>
                  <div
                    class="table-responsive"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <table
                      class="table table-striped table-bordered tablecured"
                      style={{ maxHeight: "200px" }}
                    >
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr>
                          <th>Action At</th>
                          <th>Action By</th>
                          <th>Action User</th>
                          <th>Action Status</th>
                          <th>Action Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvalList?.approvals &&
                          approvalList?.approvals.map((el, i) => {
                            let status;

                            const findPendingIndex =
                              approvalList.approvals.findIndex(
                                (ele) =>
                                  ele.status == "P" && ele.discarded == false
                              );

                            if (el.status == "D") status = "Saved";
                            else if (el.status == "P" && el.discarded == true)
                              status = "Auto Discarded";
                            else if (el.status == "P") status = "Pending";
                            else if (el.status == "A") status = "Approved";
                            else if (el.status == "R") status = "Rejected";
                            else if (el.status == "RP")
                              status = "Auto Rejected";

                            return (
                              <tr>
                                <td>
                                  {status == "Pending"
                                    ? "-"
                                    : moment(el.action_at)
                                      .utc()
                                      .format("DD-MM-YYYY HH:mm:ss")}
                                </td>
                                <td>{el.action_by_id}</td>
                                <td>{el.action_user_name}</td>
                                <td>
                                  {findPendingIndex == -1
                                    ? status
                                    : i > findPendingIndex
                                      ? "-"
                                      : status}
                                </td>
                                <td>{el.remarks}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            <div className={styles["ctas"]}>
              {mode != "view" && mode != "approve" && (
                <div className={styles["ctas_left"]}>
                  <div
                    class="btn btn-success"
                    onClick={() => {
                      confirmDialog("P");
                    }}
                  >
                    Submit
                  </div>
                  <div
                    class="btn btn-primary-inner"
                    onClick={() => {
                      confirmDialog("D");
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
              {mode == "approve" || approvalList?.for_approval ? (
                <div>
                  <div className="col-md-12">
                    <div className="form-group innergroup">
                      <label>
                        Add Comments <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="additional_comments"
                        placeholder="Enter Comments here..."
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-right pb-3">
                    <button
                      className="btn btn-primary-inner bpi-main"
                      type="submit"
                      onClick={() => validate("approve")}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-outline-danger mr-3 ml-3"
                      type="button"
                      onClick={() => {
                        onApprovalCancelHandler();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary-inner bpi-main"
                      type="submit"
                      onClick={() => validate("reject")}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ) : (
                <div className={styles["ctas_right"]}>
                  <div class="btn btn-danger" onClick={onCancelHandler}>
                    Cancel
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {isLoading && (
          <div className={styles["nodata"]}>LOADING DATA ... PLEASE WAIT </div>
        )}
      </div>
    </div>
  );
};
export default Root;

// const data = [
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//         [
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['1-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['31-Jan'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//             {
//                 value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//                 meta:{
//                     id:'abc'
//                 }
//             },
//         ],
//     ]
