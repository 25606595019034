import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import * as ShiftService from "../../service/ShiftMaster";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const validationSchema = Yup.object({
  reason: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    )
    .required("*Reason is required")
    .nullable(),
});

const AddReasonMaster = (props) => {
  const nameForm = "Shift Master";
  const { edit, showForm, onClose, editShift, onAddCallBack } = props;
  const [personalSubAreaData, setPersonalSubAreaData] = useState([]);
  const [reasonData, setReasonData] = useState({
    reason: "",
    is_active: true,
  });
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();

  const dataBlank = () => {
    onClose();
  };
  const [isLoading, setIsLoading] = useState(false);

  const saveReason = (values) => {
    setIsLoading(true);
    ShiftService.saveReason(values)
      .then((response) => {
        if (response.data.code == 200) {
          onAddCallBack(response);
          if (response.data.message == "Shift Reason created successfully") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const updateReason = (id, values) => {
    setIsLoading(true);
    ShiftService.updateReason(id, values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          toast.success(response.data.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (editShift) {
      setReasonData(editShift);
      Object.keys(editShift).forEach((item) => {
        reasonData[item] = editShift[item];
      });
    }
  }, [editShift]);

  const myfunc = (e) => {
    console.log("hello", e.target.value);
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={reasonData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          //   console.log(values, "Submit");
          // if (!isDeptExist) {
          if (values.id) {
            updateReason(values.id, values);
            AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            saveReason(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Modal
              show={showForm}
              onHide={onClose}
              size="lg"
              backdrop="static"
              keyboard={false}
              className="modaldefaultclose modaldefaultclosecenter"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {" "}
                  {edit === true ? "Edit" : "Add"} Reason Module
                </Modal.Title>
              </Modal.Header>
              <form onSubmit={handleSubmit}>
                <>
                  <Modal.Body>
                    <div class="row justify-content-center">
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group innergroup">
                              <label>
                                Reason<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                name="reason"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reason}
                                maxLength={100}
                              />
                              {touched.reason && errors.reason ? (
                                <div className="small text-danger">
                                  {errors.reason}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group innergroup">
                              <label className="d-block">Status</label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="active"
                              >
                                Active
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="active"
                                  value={values.is_active}
                                  onChange={() => {
                                    setFieldValue("is_active", true);
                                  }}
                                  checked={values.is_active === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Inactive"
                              >
                                Inactive
                                <input
                                  type="radio"
                                  name="is_active"
                                  id="Inactive"
                                  value={values.is_active}
                                  onChange={(e) => {
                                    setFieldValue("is_active", false);
                                  }}
                                  checked={values.is_active === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      <>
                        <button
                          class="btn btn-outline-danger"
                          onClick={() => {
                            dataBlank();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </Modal.Footer>
                </>
              </form>
            </Modal>
          </div>
        )}
      </Formik>
    </>
  );
};

export default AddReasonMaster;
