import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import * as TravelListingAPI from "../../service/travelService";
import AddTravelIntimation from "./AddTravelIntimation";
import TravelIntimation from "./TravelIntimation";
import TravelLinks from "./TravelLinks";
import { Tooltip } from "react-tippy";

import { ToastContainer, toast } from "react-toastify";
function ViewTravelInt(props) {
  const { viewForm, onViewClose, viewFormData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [addnewshow, addnewsetShow] = useState(false);

  console.log(viewForm, viewFormData, "okkanu");
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const [outstandshow, outstandsetShow] = useState(false);
  const outstanding = () => outstandsetShow(true);
  const [editMode, setEditMode] = useState(false);
  const [idUpdate, setIdUpdate] = useState("");
  const outstandclose = () => outstandsetShow(false);
  const onView = (data) => {
    setIdUpdate(data);
    console.log(data, "dataa");
    setViewFormNew(true);
    setViewFormDataNew(data?.travel_detail_data);
    setEditMode(true);
  };
  const [viewFormNew, setViewFormNew] = useState(false);
  const todaysD = new Date();
  const fDate = moment(todaysD).format("DDMMYYYY");
  const [viewFormDataNew, setViewFormDataNew] = useState(null);
  const onViewCloseNew = () => {
    setViewFormNew(false);
  };
  const deleteOnDuty = (id) => {
    TravelListingAPI.deleteOnDuty(id)
      .then((res) => {
        if (res.data.status == true) {
          // getCountryList(
          //   searchStr,
          //   countryDataFilter.status,
          //   currentPage,
          //   pageSizeNo,
          //   sortData.sort_by,
          //   true
          // );
          // navigate("/travel-listing");
          onViewClose();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete Country ",
      message: `Are you sure to delete On-Duty?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOnDuty(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {viewFormNew ? (
        <>
          <AddTravelIntimation
            viewForm={viewForm}
            onViewCloseNew={onViewCloseNew}
            onViewClose={onViewClose}
            viewFormDataNew={viewFormDataNew}
            idUpdate={idUpdate}
            edit={editMode}
          />
        </>
      ) : (
        <>
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec pb-0">
                  <div className="row">
                    <div class="col-md-12">
                      <h3 class="policyhead">
                        View Travel Intimation:
                        {/* PITI{fDate}
                        {userData?.username}
                        {viewFormData?.id} */}
                        {viewFormData?.travel_intimation_number}
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="defaultchatgptquest text-left">
                        <Link
                          onClick={onViewClose}
                          className="badge badge-secondary"
                        >
                          <i className="fas fa-arrow-circle-left"></i> Back
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" style={{ textAlign: "right" }}>
                    <h6 class="f-14 mt-2">
                      <span class="text-muted">
                        Reporting Manager - {userData?.reporting_to_fname}{" "}
                        {userData?.reporting_to_lname} (
                        {userData?.reporting_to_name})
                      </span>
                    </h6>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
                  <Tabs defaultActiveKey="Domestic" id="approvaltabs">
                    <Tab
                      eventKey="Domestic"
                      title={
                        <React.Fragment>
                          {viewFormData?.request_type == "On Duty"
                            ? "In Station"
                            : viewFormData?.request_type}
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured viewpo">
                          <thead>
                            <tr>
                              {/* <th>Action</th> */}
                              <th>From</th>
                              <th>To</th>
                              <th>Purpose of Travel</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>

                          <tbody>
                            {viewFormData?.travel_detail_data?.map(
                              (data, index) => (
                                <tr>
                                  {/* <td>
                                <button
                                  class="btn btn-blanktd text-primary"
                                  onClick={() => {
                                    onView(viewFormData);
                                  }}
                                >
                                  <i class="far fa-edit"></i>
                                </button>
                                <button class="btn btn-blanktd text-danger ml-2">
                                  <i
                                    class="far fa-trash-alt text-danger"
                                    onClick={() => {
                                      submit(viewFormData?.id);
                                    }}
                                  ></i>
                                </button>
                              </td> */}
                                  <td>
                                    <div class="text-dark fw-bold f-14">
                                      {data?.from_city_name === "Other"
                                        ? data?.from_city_input
                                        : data?.from_city_name}
                                      -{data?.from_city_category}
                                    </div>
                                    {moment(data?.from_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                    :{" "}
                                    {moment(data?.from_time, "h:mm A").format(
                                      "HH:mm"
                                    )}
                                  </td>
                                  <td>
                                    <div class="text-dark fw-bold f-14">
                                      {data?.to_city_name === "Other"
                                        ? data?.to_city_input
                                        : data?.to_city_name}
                                      -{data?.to_city_category}
                                    </div>
                                    {moment(data?.to_date).format("DD-MM-YYYY")}
                                    :{" "}
                                    {moment(data?.to_time, "h:mm A").format(
                                      "HH:mm"
                                    )}
                                  </td>
                                  <td>{data?.purpose}</td>
                                  <td>
                                    {data?.reason?.length > 20 ? (
                                      <Tooltip
                                        title={data?.reason}
                                        position="bottom"
                                      >
                                        {`${data?.reason.substring(0, 30)}...`}
                                      </Tooltip>
                                    ) : (
                                      data?.reason
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <p
                            style={{
                              color: "#0195d4",
                              fontWeight: "bold",
                            }}
                          >
                            Approve Details
                          </p>
                          <div class="table-responsive ">
                            <table class="table table-striped table-bordered tablecured viewpo">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Approver Name</th>
                                  <th>Approver Level</th>
                                  <th>Status</th>
                                  <th>Approved Date</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {viewFormData?.approver_remark_data?.map(
                                  (data, index) => (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          {`${data?.approver_name} (${data?.approver_username})`}
                                        </td>
                                        <td>
                                          <span>{data?.approver_type}</span>
                                        </td>
                                        <td>
                                          {!data?.status
                                            ? "Pending"
                                            : data?.status == "A"
                                            ? "Approved"
                                            : data?.status == "R"
                                            ? "Rejected"
                                            : ""}
                                        </td>
                                        <td>
                                          {data?.status &&
                                            data?.status != "P" &&
                                            data?.updated_at &&
                                            moment
                                              .parseZone(data?.updated_at)
                                              .format("DD-MM-YYYY")}
                                          &nbsp;
                                          {moment(
                                            data?.updated_at.split("T")[1],
                                            "h:mm A"
                                          ).format("HH:mm A")}
                                        </td>
                                        <td>
                                          {data?.remark?.length > 20 ? (
                                            <Tooltip
                                              title={data?.remark}
                                              position="bottom"
                                            >
                                              {`${data?.remark.substring(
                                                0,
                                                30
                                              )}...`}
                                            </Tooltip>
                                          ) : (
                                            data?.remark
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div class="px-4 pb-2">
                        {/* <div class="row align-items-center">
                          <div class="col-md-6">
                            <h6 class="f-14 mt-2">
                              <span class="text-muted">
                                RM - {userData?.reporting_to_fname}{" "}
                                {userData?.reporting_to_lname} (
                                {userData?.reporting_to_name})
                              </span>
                            </h6>
                          </div>
                        </div> */}

                        <div class="mb-3">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="border-top my-3"></div>
                            </div>

                            <div class="col-md-12 mt-3 text-center">
                              <button
                                class="btn btn-outline-secondary mr-3"
                                type="button"
                                onClick={onViewClose}
                              >
                                <i class="far fa-arrow-alt-circle-left"></i>{" "}
                                Back
                              </button>

                              {/* <button
                                class="btn btn-outline-secondary mr-2"
                                onClick={onViewClose}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={addnewshow}
            onHide={addnewclose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Advance Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-11">
                  <div class="row">
                    <table class="table table-advance">
                      <thead>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>13-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>15-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>17-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 700
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>24-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 1220
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={outstandshow}
            onHide={outstandclose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Outstanding</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-11">
                  <div class="row">
                    <table class="table table-advance">
                      <thead>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>13-01-2023</td>
                          <td>Description will come here</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>15-01-2023</td>
                          <td>Description will come here</td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>17-01-2023</td>
                          <td>Description will come here</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 700
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>24-01-2023</td>
                          <td>Description will come here</td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 1220
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default ViewTravelInt;
