import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillUserService from "../../service/skillUser";
import * as SkillGroupService from "../../service/skillGroup";
import Select from "react-select";

const AddSkillUser = (props) => {
  const nameForm = "User Group Mapping";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);

  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [empError, setEmpError] = useState(false);

  const [allGroup, setAllGroup] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [subGroupData, setSubGroupData] = useState([]);
  const [grpError, setGrpError] = useState(false);
  const [subGroupList, setSubGroupList] = useState([])

  const [skillData, setSkillData] = useState({
    group_id: [],
    employee_id: "",
    sub_group_id: ""
  });
  let condition = "false";

  const saveSkillGroup = () => {
    setIsLoading(true);
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
   
    if (skillData.sub_group_id) {
      data["sub_group_id"] = skillData?.sub_group_id;
    }
   
    SkillUserService.saveSkillUser(data)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success("User Group Mapping Added Successfully");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkillGroup = () => {
    setIsLoading(true);
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
    if (skillData.sub_group_id) {
      data["sub_group_id"] = skillData?.sub_group_id;
    }
    
    SkillUserService.updateSkillUser(data, skillData.id)
      .then((response) => {
        condition = "false";
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success("User Group Mapping Updated Successfully");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = () => {
    let data = {};
    data["group_id"] = skillData?.group_id;
    data["employee_id"] = skillData?.employee_id;
    if (condition == "false") {
      condition = "true";
     
      SkillUserService.isDataExists(data)
        .then((res) => {
          // setIsSkillExist(res?.data?.dataList?.is_exist);
          if (skillData?.id) {
            updateSkillGroup();
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            skillData?.id === undefined
          ) {
            saveSkillGroup();
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = () => {
    let error = false;
    if (edit) {
      if (editSkills?.group_id !== skillData?.group_id) {
        error = true;
      }
      if (editSkills?.employee_id !== skillData?.employee_id) {
        error = true;
      }
    }
    if (error === false && skillData?.id) {
      updateSkillGroup();
    }
    if (error === true && skillData?.id) {
      isDataExists();
    }
    if (error === false && skillData?.id === undefined) {
      isDataExists();
    }
  };

  const getGroup = (paginate) => {
    SkillUserService.getGroup(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let groupData = {
            value: x.id,
            label: x.group_name,
          };
          data.push(groupData);
        });
        setAllGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    SkillUserService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSubGroup = (e, isEdit) => {
    
    let ids = []
    
    const stringData =  e?.map(({value}) => ids.push(value));
    
    setSubGroupList([])
    setSubGroupData([])
    SkillGroupService.getSkillSubGroupByParent(ids)
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.id,
            label: element.group_name
          })
        })
        setSubGroupList(plList);
        if (isEdit) {

          const findIndex = plList.findIndex(el => el.value == editSkills?.sub_group_id)
          setSubGroupData(plList[findIndex])
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const DataPush = (event, flag) => {
    if (flag === "employee") {
      setSkillData({
        ...skillData,
        employee_id: event.value,
      });
      setEmployeeData(event);
    }
    if (flag === "group") {
      // setSkillData({
      //   ...skillData,
      //   group_id: event.value,
      // });
      // setGroupData(event);
      let obj = event?.map((each)=>{
        return each.value
      })
      setSkillData({
        ...skillData,
        group_id: obj,
      });
      setGroupData(event);
    }
    if (flag === "subGroup") {
      setSkillData({
        ...skillData,
        sub_group_id: event.value,
      });
      setSubGroupData(event);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let error = false;

    if (employeeData.length === 0) {
      setEmpError(true);
      error = true;
    }
    if (groupData.length === 0) {
      setGrpError(true);
      error = true;
    }
    if (error === false) {
      AllCheck();
    }
  };

  useEffect(() => {
    getGroup(false);
    getEmployee(false);
  }, []);

  useEffect(() => {
    if (editSkills) {
      
      let userData = {
        value: editSkills?.employee_id,
        label:
          editSkills?.owner_name + " " + "(" + editSkills?.employee_id + ")",
      };
      setEmployeeData(userData);
      let groupData = editSkills?.group_details?.map((res)=>{
        return {
          value: res?.group_id,
          label: res?.group_description
        }
      })
      let subgroupData = editSkills?.group_details?.map((res)=>{
        return {
          value: res?.subgroup_id,
          label: res?.subgroups_name
        }
      })
      // let groupData = {
      //   value: editSkills?.group_id,
      //   label: editSkills?.group_description,
      // };
     
     // getSubGroup({ value: editSkills?.group_id }, true)
      setGroupData(groupData);
      setSubGroupData(subgroupData)
      
      setSkillData(editSkills);
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills]);

  useEffect(() => {
   
  }, [auditValueLog]);

  return (
    <>
      <Modal
        show={showForm}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit === true ? "Edit" : "Add"} Skill User Group
          </Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        Employee ID<span class="text-danger">*</span>
                      </label>
                      <Select
                        name="employee_id"
                        id="employee_id"
                        options={allEmployee}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "employee");
                          setEmpError(false);
                        }}
                        value={employeeData}
                      />
                      {empError && (
                        <div className="small text-danger">
                          *Employee ID is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        Group ID<span class="text-danger">*</span>
                      </label>
                      {/* <Select
                        isMulti
                        closeMenuOnSelect={false}
                        name="group_id"
                        id="group_id"
                        options={allGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "group");
                          setGrpError(false);
                        }}
                        value={groupData}
                      /> */}
                      
                      <Select
                        isMulti
                        name="group_id"
                        id="group_id"
                        options={allGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "group");
                          setGrpError(false);
                          getSubGroup(e)
                        }}
                        value={groupData}
                      />
                      {grpError && (
                        <div className="small text-danger">
                          *Group ID is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group innergroup">
                      <label>
                        Sub Group ID
                      </label>
                      <Select
                        // isMulti
                        name="sub_group_id"
                        id="sub_group_id"
                        options={subGroupList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          DataPush(e, "subGroup");
                        }}
                        value={subGroupData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {isSkillExist && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>
              &nbsp;This Entry Already Exists.
            </Alert>
          )}
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  class="btn btn-outline-danger"
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </button>

                <button
                  class="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={(e) => submitHandler(e)}
                >
                  Submit
                </button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddSkillUser;
