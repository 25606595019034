import React from "react";
import { Link } from "react-router-dom";

const AllUrlsCashbook = () => {
  const isActiveRoute = (activeRoute) => {
    return activeRoute === window.location.pathname ? { class: "active" } : {};
  };
  return (
    <div class="col-md-3">
      <ul class="policylistnames">
        <li {...isActiveRoute("/cashbook-expenses")}>
          <Link to="/cashbook-expenses">Manage Expenses</Link>
        </li>
        <li {...isActiveRoute("/cashbook-receipts")}>
          <Link to="/cashbook-receipts">Manage Receipts</Link>
        </li>
        <li {...isActiveRoute("/cashbook-beneficial")}>
          <Link to="/cashbook-beneficial">Manage Beneficiary</Link>
        </li>

        <li {...isActiveRoute("/cashbook-vendors")}>
          <Link to="/cashbook-vendors">Manage Vendors</Link>
        </li>
        <li {...isActiveRoute("/cashbook-demand")}>
          <Link to="/cashbook-demand">Cash Demand</Link>
        </li>
        <li {...isActiveRoute("/cashbook-approval")}>
          <Link to="/cashbook-approval">For Approval</Link>
        </li>
        <li {...isActiveRoute("/cashbook-reports")}>
          <Link to="/cashbook-reports">Reports</Link>
        </li>
        {/* <li {...isActiveRoute("/manage-expense")}>
          <Link to="/manage-expense">New Test</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default AllUrlsCashbook;
