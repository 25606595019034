import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getCompanyCode = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-company-code/list`
  );
};

export const getDocumentType = async (organisation_id) => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/document-type/list?organisation_id=${organisation_id}
  `);
};

export const getGlCodeList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-gl-code/list?organisation_id=${organisation_id}`
  );
};

export const getCostCenter = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-cost-center/list?organisation_id=${organisation_id}`
  );
};

export const getProfitCenterList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-profit-center/list?organisation_id=${organisation_id}`
  );
};

export const getBusinessAreaList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-business-area/list?organisation_id=${organisation_id}`
  );
};

export const getPlantList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-plant/list?organisation_id=${organisation_id}`
  );
};

export const getPlantListByUser = async () => {
  return axios.get(`${AUTH_BASE_URL}/reservation/plantsByUser`);
};

export const getCurrencyList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-currency/list?organisation_id=${organisation_id}`
  );
};

export const getBusinessPlaceList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-business-place/list?organisation_id=${organisation_id}`
  );
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const saveJvDetails = async (Jvdata) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/create`,
    Jvdata
  );
};

export const editJvDetails = async (id, eData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/${id}/edit`,
    eData
  );
};

export const getDraftList = async (page_no, page_size, search) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/my-saved?page_no=${page_no}&page_size=${page_size}${
      search == undefined || search == "" || search == null
        ? ""
        : `&search=${search}`
    }`
  );
};

export const getAllList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/my-all?${queryParm}`
  );
};
export const getReportAllList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/all-user?${queryParm}`
  );
};

export const getApprovalList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/approval?${queryParm}`
  );
};

export const saveMultiApprove = async (checkData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/multi-save`,
    checkData
  );
};

export const getViewData = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/jv-transaction/${id}`);
};

export const saveRemarksData = async (id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/approval/${id}`,
    data
  );
};

export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/buss-transactions/jv-transaction/import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const importExelHoliday = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/holiday_calendar/import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const addJvRmApproval = async (approvalData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-rm-approval/create`,
    approvalData
  );
};

export const updateJvRmApproval = async (id, updateData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-rm-approval/${id}/edit`,
    updateData
  );
};

export const getAllRmApprovalList = async (search, page_no, page_size) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-rm-approval/list?search=${search}&page_no=${page_no}&page_size=${page_size}`
  );
};

export const getUserRmList = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/users?paginate=${paginate}&status=${true}`
  );
};

export const getUserJvRmList = async (paginate, sort_by) => {
  return axios.get(
    `${AUTH_BASE_URL}/users?paginate=${paginate}&sort_by=${sort_by}`
  );
};

export const deleteMultiApprove = async (checkData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/delete`,
    checkData
  );
};

export const deleteUserRmList = async (id) => {
  return axios.delete(
    `${AUTH_BASE_URL}/buss-transactions/jv-rm-approval/${id}/delete`
  );
};

export const getExportDetails = async (status, jv_id) => {
  return axios({
    url: `${AUTH_BASE_URL}/buss-transactions/jv-transaction/export?status=${status}${
      jv_id == undefined || jv_id == "" || jv_id == null
        ? ""
        : `&jv_id=${jv_id}`
    }`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteAttachment = async (id) => {
  return axios.delete(
    `${AUTH_BASE_URL}/buss-transactions/jv-attachment/${id}/delete`
  );
};

export const editAttachments = async (id, aData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/${id}/add`,
    aData
  );
};

export const getDirectoryDetails = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/import_details`
  );
};

export const getHolidayDirectoryDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/holiday_calendar/import_details`);
};

export const getExportSummaryDetail = async () => {
  return axios({
    url: `${AUTH_BASE_URL}/buss-transactions/jv-transaction/summary-report`,
    method: "GET",
    responseType: "blob",
  });
};

export const addAttachmentData = async (id, is_ssc, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-attachment/${id}/add?is_rm=${is_ssc}`,
    data
  );
};

export const getExportDetailsReport = async (
  status,
  start_date,
  end_date,
  search,
  sap_doc_no,
  user_id
) => {
  return axios({
    url: `${AUTH_BASE_URL}/buss-transactions/jv-transaction/all-user-jv-export?${
      status == undefined || status == "" || status == null
        ? ""
        : `&status=${status}`
    }${
      start_date == undefined || start_date == "" || start_date == null
        ? ""
        : `&start_date=${start_date}`
    }${
      end_date == undefined || end_date == "" || end_date == null
        ? ""
        : `&end_date=${end_date}`
    }${
      search == undefined || search == "" || search == null
        ? ""
        : `&search=${search}`
    }
    ${
      sap_doc_no == undefined || sap_doc_no == "" || sap_doc_no == null
        ? ""
        : `&sap_doc_no=${sap_doc_no}`
    }
    ${
      user_id == undefined || user_id == "" || user_id == null
        ? ""
        : `&user_id=${user_id}`
    }`,
    method: "GET",
    responseType: "blob",
  });
};

export const getViewExportDetails = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/buss-transactions/jv-transaction/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const saveMultiApproveList = async (checkData) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/multi-approve`,
    checkData
  );
};

export const saveInDraft = async (id) => {
  return axios.post(
    `${AUTH_BASE_URL}/buss-transactions/jv-transaction/rejected-to-draft/${id}`
  );
};
export const getYtdValue = async (user_id, year) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_user_ytd?user_id=${user_id}&year=${year}`
  );
};
