import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getVendorInvoiceList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/bt-invoice/list?${queryParm}
  `);
};

export const saveVendorInvoice = async (vdata) => {
  return axios.post(`${AUTH_BASE_URL}/bt-invoice/create`, vdata);
};

export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/bt-invoice/import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const getSscMembeList = async (page_no, page_size) => {
  return axios.get(
    `${AUTH_BASE_URL}/bt-invoice/ssc_membe/list?page_no=${page_no}&page_size=${page_size}`
  );
};

export const saveApprovedData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/bt-invoice/approval`, data);
};

export const getDirectoryDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/bt-invoice/import_details`);
};
export const getAllVendorList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/country?paginate=${paginate}`);
};

export const getExportData = async (choice, status) => {
  return axios({
    url: `${AUTH_BASE_URL}/bt-invoice/export?${
      choice == undefined || choice == "" || choice == null
        ? ""
        : `&choice=${choice}`
    }${
      status == undefined || status == "" || status == null
        ? ""
        : `&status=${status}`
    }`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportDetailsReport = async (report,choice, start_date, end_date, status, created_by_id, grn_number, courier_to, invoice_type,courier_no,self_approval_data) => {
  return axios({
    url: `${AUTH_BASE_URL}/bt-invoice/report?report=${report}&choice=${choice}&start_date=${start_date}&end_date=${end_date}&status=${status}&created_by_id=${created_by_id}&grn_number=${grn_number}&courier_to=${courier_to}&invoice_type=${invoice_type}&courier_no=${courier_no}&self_approval_data=${self_approval_data}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteVendor = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/bt-invoice/delete/${id}`);
};