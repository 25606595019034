import React, {useEffect, useState} from "react";
import { Dropdown, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import * as CashbookService from '../../service/cashbookService'
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  setDisplayLoader,
} from "../redux/piDataStore";

function CashbookWiseExpenseReport() {

    const dispatch = useDispatch();

    const [show, filtersetShow] = useState(false);
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [pageCount, setpageCount] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [searchStr, setSearchStr] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [cashbookList, setCashbookList] = useState([]);
    const [cashbookValue, setCashbookValue] = useState([]);
    const [expenseData, setExpenseData] = useState([]);
    const [cashbookData, setCashbookData] = useState({
      cashbook_id: "",
      from_date: "",
      to_date: ""
    })

    const filterShow = () => {
        filteropen();
        };
    
    const handleSearch = () => {
        
    }
    
    const closeButtonCallBack = () => {
        
    }

    const handlePageClick = (action) => {
      dispatch(setDisplayLoader('Display'))
      let activePage = action.selected + 1;
      CashbookService.getCashbookExpenseData(
        handleKey(cashbookData?.cashbook_id, activePage, pageSizeNo)
        ).then((response)=>{
        setExpenseData(response?.data?.dataList?.result)
        dispatch(setDisplayLoader('Hide'))
      })
      setCurrentPage(activePage);
    }

    useEffect(()=>{
      getCashbookMaster()
    },[])

    const getCashbookMaster = () => {
      CashbookService.getAllCashbookMasterData().then((response)=>{
        let obj = response?.data?.dataList?.result?.map((each)=>{
          return {
            label: each?.desc,
            value: each?.id
          }
        })
        setCashbookList(obj)
      })
    }

    const handleKey = (cashbook_id, page_no, page_size, from_date, to_date) => {
      let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size;
      if (from_date !== "" && from_date !== undefined && from_date !== null) {
        queryParm = queryParm + "&from_date=" + from_date;
      }
      if (to_date !== "" && to_date !== undefined && to_date !== null) {
        queryParm = queryParm + "&to_date=" + to_date;
      }
      if (cashbook_id !== "" && cashbook_id !== undefined && cashbook_id !== null) {
        queryParm = queryParm + "&cashbook_id=" + cashbook_id;
      }
      return queryParm;
    }

    const getCashExpenseByCashbook = (e) => {
      
        let cashbook_id = e
          .map((obj) => {
            return obj.value;
          })
          .toString()
          dispatch(setDisplayLoader('Display'))
          CashbookService.getCashbookExpenseData(handleKey(
            cashbook_id, currentPage, pageSizeNo, '', ''
          )).then((response)=>{
            setExpenseData(response?.data?.dataList?.result)
            setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
            dispatch(setDisplayLoader('Hide'))
          })
          setCashbookData({
            ...cashbookData,
            cashbook_id: e
              .map((obj) => {
                return obj.value;
              })
              .toString(),
          });
      
    }

    const getExportDetails = () => {
      dispatch(setDisplayLoader('Display'))
      CashbookService.getExportCashExpense(handleKey(
        cashbookData?.cashbook_id, currentPage, pageSizeNo, cashbookData?.from_date, cashbookData?.to_date
      )).then((response)=>{
        dispatch(setDisplayLoader('Hide'))
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookExpenseReport.xlsx");
        document.body.appendChild(link);
        link.click();
      })
    }

    const applyFilter = () => {
      dispatch(setDisplayLoader('Display'))
      filterclose();
      CashbookService.getCashbookExpenseData(
        handleKey(cashbookData?.cashbook_id, currentPage, pageSizeNo, cashbookData?.from_date, cashbookData?.to_date)
        ).then((response)=>{
        setExpenseData(response?.data?.dataList?.result)
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
        dispatch(setDisplayLoader('Hide'))
      })
    };
    const resetFilter = () => {
      filterclose();
      CashbookService.getCashbookExpenseData(handleKey(
        cashbookData?.cashbook_id, currentPage, pageSizeNo, '', ''
      )).then((response)=>{
        setExpenseData(response?.data?.dataList?.result)
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
      })
    };

    return (
        <div>

        <div class="content-wrapper-inner">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">
                  {/* {bhr !== undefined ? "BHR Attendance Report" : "Attendance"} */}
                  Cashbook Expense Report
                </h4>
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
              <div class="form-group innergroup">
            <label>
              Select Cashbook<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={cashbookList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Cashbook"
              value={cashbookValue}
              onChange={(e) => {
                setCashbookValue(e);
                getCashExpenseByCashbook(e)
              }}
            />
          </div>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={expenseData.length === 0}
                          style={{ border: 0, background: "none" }}
                          onClick={()=>{
                            getExportDetails()
                          }}
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                 
                  <button
                    onClick={() => {
                      filterShow();
                    }}
                    class="btn btn-secondary-inner"
                    // style={{
                    //   backgroundColor: filterApplied && "yellow",
                    // }}
                  >
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                    <th>Total Amount</th>
                    <th>Expense GL</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Cost Center</th>
                    <th>Amount</th>
                    <th>Internal Order</th>
                    <th>Tax Rate</th>
                    <th>Status</th>
                    <th>SAP Doc.</th>
                    <th>SAP Message</th>
                    <th>Date</th>
                    <th>Narration</th>
                </tr>
              </thead>
              <tbody>
                    <>
                    {expenseData?.length > 0 ?
                    expenseData?.map((data)=>(
                      <tr>
                        {data?.type === 'Debit' ?
                        <td>{data?.total_amount}</td>
                        :
                        <td>{data?.amount}</td>
                        }
                        <td>{data?.gl_code}</td>
                        <td>{data?.type}</td>
                        <td>
                          <Tooltip title={data?.gl_desc} position="bottom">
                          {data?.gl_desc}
                          </Tooltip>
                        </td>
                        <td>{data?.cost_center_code}</td>
                        {data?.type === 'Debit' ?
                        <td>{data?.base_amount}</td>
                        :
                        <td>{data?.amount}</td>
                        }
                        <td>{data?.au_fnr}</td>
                        <td>{data?.tax_rate}</td>
                        <td>
                          {data?.sap_posting_status === true ?
                          'Posted'
                          :
                          'Pending'
                          }
                        </td>
                        <td>{data?.document_no}</td>
                        <td>
                          <Tooltip title={data?.sap_message} position="bottom">
                          {data?.sap_message}
                          </Tooltip>
                        </td>
                        <td>{moment(data?.updated_at).format('DD-MM-YYYY')}</td>
                        <td>
                          {data?.bill_description?.length > 22 ?
                          <Tooltip title={data?.bill_description} position="bottom">
                          {data?.bill_description?.substr(0,22)}...
                          </Tooltip>
                          :
                          data?.bill_description
                          }
                        </td>
                      </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={12}>No Record Found...</td>
                      </tr>
                    }
                    </>
              </tbody>
            </table>
          </div>
            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control main-pointer"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          
        </div>
        <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger"></span>
            </label>
            <input
              onChange={(e) => {
                setCashbookData({
                  ...cashbookData,
                  from_date: e.target.value
                })
              }}
              value={cashbookData?.from_date}
              // max={moment(data.query.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input
              onChange={(e) => {
                setCashbookData({
                  ...cashbookData,
                  to_date: e.target.value
                })
              }}
              value={cashbookData?.to_date}
              // max={moment(data.query.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    );
}
export default CashbookWiseExpenseReport;