import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getOtherExp = async (filters) => {
  return axios.get( `${AUTH_BASE_URL}/travel-n-expanseother_expense?${filters}`)
  };
export const getDiscardedOtherExp = async (filters) => {
  return axios.get( `${AUTH_BASE_URL}/travel-n-expanseother_expense_discarded?${filters}`)
  };

  export const receivePickList = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_expense_2`, data);
  };

  export const getAssignedGLDropDown=async (params) =>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanseother_expense_assigned_gl?${params}`)    
  }

  export const getBusinessUnits=async ()=>{
    return axios.get(`${AUTH_BASE_URL}/usersuser-business-unit-filter`)    
  }
  
  
  export const otherExpSSCAppr = async (ssc,data) => {
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_expense_ssc_approval?approved_by=${ssc}`, data);
  };

  export const updateOtherExpense = async (id,data) => {
    return axios.put(`${AUTH_BASE_URL}/travel-n-expanse/other_expense/${id}`, data);
  };


  export const postOtherExpChat = async (id, data) => {
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/otherexpchat/${id}`, data);
  };
  
  export const getOtherExpChat = async (id, queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/otherexpchat/${id}?${queryParm}`);
  };


  export const reassignSSC=async (id,username,action)=>{
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_expense_reassign_ssc?id=${id}&username=${username}&action=${action}`,{})
  }

  export const otherdiscardToPickList=async (id,username,action)=>{
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_expense_discard_topicklist?id=${id}&username=${username}&action=${action}`,{})
  }

  export const otherExpExport = async (params)=>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanseother_expense_export?${params}`)
  }

  export const otherExpExport2 = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/travel-n-expanseother_expense_export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const addExceptionMaster=async (data)=>{
    return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/travel_back_date_insert`,data)
  }

  export const getExceptionMaster=async (params)=>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/travel_back_date?${params}`)
  }


  export const otherExpHeaderExport = async (params)=>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanseother_expense_header_export?${params}`)
  }

  export const otherExpHeaderExport2 = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/travel-n-expanseother_expense_header_export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };


  export const getHeaderAllReq=async(params)=>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanseother_expense_header_export?${params}`)
  }

  export const getUserDetail=async(params)=>{
    return axios.get(`${AUTH_BASE_URL}/users?${params}`)
  }

  
  export const otherExpBHRExport = async (params)=>{
    return axios.get(`${AUTH_BASE_URL}/travel-n-expanseother_expense_bhr?${params}`)
  }

  export const otherExpBHRExport2 = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/travel-n-expanseother_expense_bhr?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const getOtherExpExportPick = async (filters) => {
    return axios({
      url:`${AUTH_BASE_URL}/travel-n-expanseother_expense?${filters}`,
      method: "GET",
      responseType: "blob",
    });
    };