import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import * as TravelListingAPI from "../../service/travelService";
import * as ClaimTravel from "../../service/claimTravel";
import moment from "moment/moment";

import { uploadfile } from "../../service/Survey";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { updateVehicleLogssc } from "../../service/vehiclelog";
import * as vehicleService from "../../service/vehiclelog";
import * as userGl from "../../service/userGl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function TravelCheckExpens() {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const [ex, set_ee] = useState({
    aa: 220,
    bb: 230,
  });
  const [addnewshow, addnewsetShow] = useState(false);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const [vehicleData, setVehicalData] = useState({});

  const [traveluserDetails, setTraveluserDetails] = useState([]);

  const [inputReason, set_inputReason] = useState("");
  const [reasonshows, setReasonShow] = useState(false);
  const [forwardreasonshows, setForwardReasonShow] = useState(false);

  const [travelDetails, setTravelDetails] = useState(undefined);
  const [newticketDetails, set_newTicketDetails] = useState([]);
  const [orignewticketDetails, set_orignewTicketDetails] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [hotelDetails, setHotelDetails] = useState([]);
  const [daDetails, setDADetails] = useState([]);
  const [conveyanceDetails, setConveyanceDetails] = useState([]);
  const [foreignDetails, setForeignDetails] = useState({
    hotelDetail: [],
    foodDetail: [],
    inciDaDetail: [],
    conveyDetail: [],
  });
  const [contAmount, setContAmount] = useState([]);

  // const [conveyanceDetails, setConveyanceDetails] = useState([]);
  // const [conveyanceDetails, setConveyanceDetails] = useState([]);
  // const [conveyanceDetails, setConveyanceDetails] = useState([]);
  const [fExp, setFExp] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [totalHotelValue, setTotalHotelValue] = useState(0.0);
  const [totalHotelValue2, setTotalHotelValue2] = useState(0.0);
  const [totalTicketValue, setTotalTicketValue] = useState(0.0);
  const [totalTicketValue2, setTotalTicketValue2] = useState(0.0);
  const [totalDaValue, setTotalDaValue] = useState(0.0);
  const [totalDaValue2, setTotalDaValue2] = useState(0.0);
  const [totalVehLogValue, setTotalVehLogValue] = useState(0.0);
  const [totalVehLogValue2, setTotalVehLogValue2] = useState(0.0);
  const [totalConveValue, setTotalConveValue] = useState(0.0);
  const [totalConveValue2, setTotalConveValue2] = useState(0.0);
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [appErrs, setappErrs] = useState({});

  const [pick_item_id, set_pickItemId] = useState(null);

  const [taxRatesMenu, set_taxRatesMenu] = useState([]);
  const [airlinesMenu, set_AirlinesMenu] = useState([]);
  const [gstState, setGstState] = useState([]);

  const [post_reasons, set_post_reasons] = useState({});

  const [delete_tickets_ids, set_delete_tickets_ids] = useState([]);
  // const [new_added_tickets,set_new_added_tickets] = useState([])
  const [updated_tickets, set_updated_tickets] = useState([]);
  const [updated_hotels, set_updated_hotels] = useState([]);
  const [updated_da, set_updated_da] = useState([]);
  const [updated_vehLogs, set_updated_vehLogs] = useState([]);
  const [updated_conveyance, set_updated_conveyance] = useState([]);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [finalDetails, set_finalDetails] = useState({
    final_userclaimself: 0,
    final_userclaimcompany: 0,
    final_claim: 0,
    final_approvedCompExp: 0,
    final_approvedSelfExp: 0,
    final_approvedTotal: 0,
    final_netAmt: 0,
  });

  const [delayAmt, setdelayAmt] = useState(0);

  const [allApproved, set_allApproved] = useState({
    totalHotelValue: 0.0,
    totalHotelValue2: 0.0,

    totalTicketValue: 0.0,
    totalTicketValueByUser: 0.0,
    changedtotalTicketValueBySSC: 0.0,
    totalTicketValue2: 0.0,

    totalDaValue: 0.0,
    totalDaValue2: 0.0,

    totalVehLogValue: 0.0,
    totalVehLogValue2: 0.0,

    totalConveValue: 0.0,
    totalConveValue2: 0.0,

    totaltikbyself: 0.0,
    totaltikbycompany: 0.0,

    total_da_byself: 0.0,
    total_da_bycompany: 0.0,
    total_hotel_byself: 0.0,
    total_conve_byself: 0.0,

    totalForegHotel: 0.0,
    totalForegHotel2: 0.0,
    totalForegHotelself: 0.0,
    totalForegHotelcomp: 0.0,

    totalForegFood: 0.0,
    totalForegFood2: 0.0,
    totalForegFoodself: 0.0,
    totalForegFoodcomp: 0.0,

    totalForegDaValue: 0.0,
    totalForegDaValue2: 0.0,
    totalForegDaValueself: 0.0,
    totalForegDaValuecomp: 0.0,

    totalForegConveValue: 0.0,
    totalForegConveValue2: 0.0,
    totalForegConveValueself: 0.0,
    totalForegConveValuecomp: 0.0,

    totaltikuserbyself: 0.0,
    totaltikuserbycompany: 0.0,
  });

  // const [vehicleLogSums,set_vehicleLogSums] = useState({
  //   total_kms:0,
  //   total_fuelqty:0,
  //   total_fuelamt:0,
  //   total_apprfuelamt:0,
  //   total_dailywage:0,
  //   total_apprdailywage:0,
  //   total_daval:0,
  //   total_apprdaval:0,
  //   total_other:0,
  //   total_approther:0,
  //   total_washing:0,
  //   total_apprwashing:0,
  //   total_total:0,
  //   total_apprtotal:0,
  // })

  const [vehicleLogSums, set_vehicleLogSums] = useState({
    id: 381,
    travel_id: 8253,
    travel_date: "2023-08-23",
    start_kms: 0,
    is_exception: null,
    mode_id: 4,
    close_kms: 0,
    total_kms: 0,
    fuel_quantity: 0,
    fuel_amount: 0,
    driver_da_value: 0,
    other_expenses: 0,
    total_amount: 0,
    driver_daily_wages: 0,
    user_id: 5590,
    washing_amount: 0,
    appr_driver_daily_wages: 0,
    appr_fuel_amount: 0,
    appr_other_expenses: 0,
    appr_washing_amount: 0,
    appr_driver_da_value: null,
    approved_amount: 0,
    vehicle_log_id: null,
    user_name: "Ravi Kumar",
    modee_name: "Car",
  });

  const [ticketSums, setticketSums] = useState({
    taxamt: 0.0,
    other: 0.0,
    billamt: 0.0,
    claimed: 0.0,
    apprtax: 0.0,
    approther: 0.0,
    apprtaxable: 0.0,
    appramt: 0.0,
  });

  const [daDataList, setDaDataList] = useState([]);
  const [approvedSendBack_payload, set_approvedSendBack_payload] = useState({
    pick_list_id: [],
    status: "",
    remarks: "",
    delay_deduction: 0.0,
    penalty: 0.0,
    reason: [
      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "TICKET",
        reason: "",
      },
      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "HOTEL",
        reason: "",
      },
      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "DA",
        reason: "",
      },
      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "VEHICLE_LOG",
        reason: "",
      },
      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "LOCAL_CONVEYANCE",
        reason: "",
      },

      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "FOREIGN_HOTEL",
        reason: "",
      },

      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "FOREIGN_FOOD",
        reason: "",
      },

      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "FOREIGN_DA",
        reason: "",
      },

      {
        applied_amount: 0.0,
        approved_amount: 0.0,
        expense_type: "FOREIGN_CONVEYANCE",
        reason: "",
      },
    ],
  });

  const [docsModel, set_docsModel] = useState(false);
  const todayDate = new Date();

  const [travelchats, setTravelChats] = useState([]);
  const [uploadData, setUploadData] = useState({});

  const finalUser = useSelector(selectUserData);
  const [vehicleAssignValues, setVehicleAssignValues] = useState();

  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    message: "",
    attachment_path: null,
    attachment_list: [],
  });

  const [approveData, setApproveData] = useState({
    travel_id: null,
    amount: null,
  });

  const [editDaDetailModal, setEditDaDetailModal] = useState({
    flag: false,
    id: null,
    isView: false,
    daIdx: null,
    travel_id: null,
  });
  const [daBillDetail, setDaBillDetail] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const userDetails = useSelector(selectUserData);
  const [focusedSelect, setFocusedSelect] = useState(null);

  const daType = {
    0: "Local",
    100: "RHQ",
    155: "NH",
  };

  const amountRegex = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/);

  const readOnlyColumnStyle = {
    css: {
      backgroundColor: "#d8d8d8",
      color: "#000",
      textAlign: "center",
      minHeight: 28,
    },
  };

  const inputStyle = {
    class: "d-flex  p-1 border border-dark rounded",
    css: { textAlign: "center", width: 60 },
  };

  const numberInputStyle = {
    taxCss: { width: 40, textAlign: "center" },
    amountCss: { width: 60, textAlign: "center" },
  };

  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files", files.type);

    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    console.log(`...`, fileData);

    uploadfile(fileData).then((response) => {
      // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      setUploadData(response.data);
      console.log(`response`, response.data);
      set_travelPostData({
        ...travelPostData,
        attachment_path: response.data,
      });
    });
  };

  const removeImageByIdx = (idx) => {
    console.log(`images bef`, imageLocalPreviewList);

    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    // console.log(`images aft`,newIList);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  function getFileSize_KB(size_bytes = 0) {
    return Math.ceil((size_bytes * 10) / 1024) / 10;
  }

  const onProfileChange2 = (e, flag) => {
    const MAX_FILE_SIZE = 4 * 1024; // 4MB
    const SUPPORTED_FILE_TYPES = ["application/pdf"];

    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];
      console.log(`file`, afile.type);

      if (getFileSize_KB(afile.size) > MAX_FILE_SIZE) {
        toast.error("File size can't exceed 4MB", {
          autoClose: 2000,
        });
        continue;
      }
      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      console.log("files", getFileSize_KB(afile.size));

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);

      // console.log(`...`,fileData);

      // uploadfile(fileData).then((response) => {
      //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      //   setUploadData(response.data)
      //   console.log(`response`,response.data);
      //   setPostData({
      //     ...postData,
      //     attachment_path:response.data
      //   })

      // })
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);

    console.log(`files...`, filesToUpload);
  };
  
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  async function approveCheckExpense() {
    // let _foreignPayload= {
    //   "attachment_path": {},
    //   "attachment_delete": [

    //   ],
    //   "conveyance_data": foreignDetails.conveyDetail,
    //   "hotel_data": foreignDetails.hotelDetail,
    //   "food_data": foreignDetails.foodDetail,
    //   "da_data": foreignDetails.inciDaDetail
    // }
    // console.log(`=>_foreignPayload`,_foreignPayload);
    // return
    console.log("updated_tickets..", updated_tickets);
    console.log("updated_da..", daDetails);
    console.log("updated_da..", updated_da);
    console.log("hotelDetails..", hotelDetails);
    // alert()
    // return
    approvedSendBack_payload["status"] = "APPROVED";

    let errExist = false;

    if (
      allApproved.totalTicketValue - allApproved.changedtotalTicketValueBySSC !=
      0
    ) {
      toast.error("Ticket amounts do not match");
      return;
    }

    // return

    if (
      Number.parseFloat(allApproved.changedtotalTicketValueBySSC?.toFixed(2)) <
      (Number.parseFloat(allApproved.totalTicketValue2?.toFixed(2))) ||
      Number.parseFloat(allApproved.totalTicketValue?.toFixed(2)) < (Number.parseFloat(allApproved.totalTicketValue2?.toFixed(2)))
    ) {
      console.log(`Ticket amount cant be increased....`,allApproved.changedtotalTicketValueBySSC,allApproved.totalTicketValue2);
      toast.error("Ticket amount cant be increased");
      return;
    }
    if (allApproved.totalHotelValue < allApproved.totalHotelValue2) {
      toast.error("Hotel amount cant be increased");
      return;
    }
    if (allApproved.totalDaValue < allApproved.totalDaValue2) {
      toast.error("DA amount cant be increased");
      return;
    }
    if (allApproved.totalVehLogValue < allApproved.totalVehLogValue2) {
      toast.error("Vehicle Log amount cant be increased");
      return;
    }
    if (allApproved.totalConveValue < allApproved.totalConveValue2) {
      toast.error("Conveyance amount cant be increased");
      return;
    }

    if (travelDetails.request_type == "International") {
      if (allApproved.totalForegHotel < allApproved.totalForegHotel2) {
        toast.error("Foreign Hotel amount cant be increased");
        return;
      }

      if (allApproved.totalForegFood < allApproved.totalForegFood2) {
        toast.error("Foreign Food amount cant be increased");
        return;
      }

      if (allApproved.totalForegDaValue < allApproved.totalForegDaValue2) {
        toast.error("Foreign Incident amount cant be increased");
        return;
      }

      if (
        allApproved.totalForegConveValue < allApproved.totalForegConveValue2
      ) {
        toast.error("Foreign Conveyance  amount cant be increased");
        return;
      }
    }

    newticketDetails?.every((_newtidata, iidx) => {
      if (
        // (_newtidata?.other_charges != _newtidata?.appr_other_charges ||
        // _newtidata?.tax_amount != _newtidata?.appr_tax_amount ||
        // _newtidata?.taxable_amount != _newtidata?.appr_taxable_amount)

        allApproved.totalTicketValue > allApproved.totalTicketValue2 &&
        !approvedSendBack_payload.reason[0]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Ticket", {
          autoClose: 2000,
        });

        errExist = true;
        return false;
      }
      return true;
    });

    hotelDetails?.every((_newtidata, iidx) => {
      console.log(
        `log`,
        _newtidata?.tax_amount,
        _newtidata?.appr_tax_amount,
        _newtidata?.taxable_amount,
        _newtidata?.appr_bill_amount
      );

      if (
        allApproved.totalHotelValue > allApproved.totalHotelValue2 &&
        !approvedSendBack_payload.reason[1]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Hotel", {
          autoClose: 2000,
        });

        errExist = true;
        return false;
      }

      return true;
    });

    conveyanceDetails?.every((_newtidata, iidx) => {
      if (_newtidata?.amount - _newtidata?.approved_amount < 0) {
        toast.error(" Conveyance amount can't be increased", {
          autoClose: 2000,
        });

        errExist = true;
      }

      if (
        _newtidata?.amount > _newtidata?.approved_amount &&
        !approvedSendBack_payload.reason[4]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Conveyance", {
          autoClose: 2000,
        });

        errExist = true;
      }

      if (errExist) {
        return false;
      }
      return true;
    });

    vehicleDetails?.every((_newtidata, iidx) => {
      if (_newtidata?.total_amount - _newtidata?.approved_amount < 0) {
        toast.error("Amount can't be increased in vehicle", {
          autoClose: 2000,
        });

        errExist = true;
      }

      if (
        _newtidata?.total_amount > _newtidata?.approved_amount &&
        !approvedSendBack_payload.reason[3]?.reason &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Vehicle", {
          autoClose: 2000,
        });

        errExist = true;
      }

      if (errExist) {
        return false;
      }
      return true;
    });

    if (
      updated_da?.length > 0 &&
      !approvedSendBack_payload.reason[2]?.reason?.length
    ) {
      toast.error("Reason is mandatory in DA", {
        autoClose: 2000,
      });
      errExist = true;
    }

    if (travelDetails.request_type == "International") {
      if (
        allApproved.totalForegHotel > allApproved.totalForegHotel2 &&
        !approvedSendBack_payload.reason[5]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory Foreign Hotel");
        // return
        errExist = true;
      }

      if (
        allApproved.totalForegFood > allApproved.totalForegFood2 &&
        !approvedSendBack_payload.reason[6]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Foreign Food");
        errExist = true;
      }

      if (
        allApproved.totalForegDaValue > allApproved.totalForegDaValue2 &&
        !approvedSendBack_payload.reason[7]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Foreign Incident");
        errExist = true;
      }

      if (
        allApproved.totalForegConveValue > allApproved.totalForegConveValue2 &&
        !approvedSendBack_payload.reason[8]?.reason?.length &&
        userGroup?._userGroup == "ssc user"
      ) {
        toast.error("Reason is mandatory in Foreign Conveyance ");
        errExist = true;
      }
    }

    if (errExist) {
      return;
    }

    // if(updated_tickets?.length>0 && !approvedSendBack_payload.reason[0]?.reason?.length){
    //   toast.error("Reason is mandatory in Ticket")
    //   errExist=true
    // }

    // if(updated_hotels?.length>0 && !approvedSendBack_payload.reason[1]?.reason?.length){
    //   toast.error("Reason is mandatory in Hotel")
    //   errExist=true
    // }

    // if(updated_conveyance?.length>0 && !approvedSendBack_payload.reason[4]?.reason?.length){
    //   toast.error("Reason is mandatory in Conveyance")
    //   errExist=true
    // }

    console.log(`line 2....`, approvedSendBack_payload);

    console.log("updated_hotels..", updated_hotels);
    console.log("updated_tickets..", updated_tickets);
    console.log("updated_da..", updated_da);
    console.log("updated_conveyance..", updated_conveyance);
    console.log("updated_vehLogs..", updated_vehLogs);

    let _dapayload = {
      total_amount: allApproved.totalDaValue,
      attachment_path: [],
      attachment_delete: [],
      da_data: [],
    };

    daDetails.forEach((itemm) => {
      // delete itemm["bill_data"]
      itemm["updated_by_ssc_user_id"] = finalUser?.id;

      // if(!itemm.id){

      // console.log(`new...`,itemm);
      _dapayload["da_data"].push(itemm);
      console.log(`ok`, itemm);

      // }
    });
    console.log(`updating...`, _dapayload);

    // return;
    // updateHotelExpense(travelDetails?.travel_id,)

    console.log(`updating for approval...`);

    await delay(2000)

      
      try{
        dispatch(setDisplayLoader("Display"));
        await  userGl.glCostCenterVerify(
          `id=${traveluserDetails[0]?.id}&exptype=travel`
        )
        .then((response) => {
          console.log(`otherexp...`,response.data);
          dispatch(setDisplayLoader("Hide"));
          if(response.data==null){
  
            // toast.success(`response.data`)
            
            // return
  
          }
          else{
            toast.error(response.data)
            throw new Error("Some error")
          }
        
        })
        .catch((error) => {
          toast.error(error)
          console.log("error response=>",error);
  
          throw error
          
        });
      }catch(error){
        // toast.error(error.message)
        console.log(`inside catch error response`);

        return null;
      }
    // return

    for (let i = 0; i < finalUser.groups.length; i++) {
      // const payloa={
      //   "pick_list_id": [
      //     `${pick_item_id}`
      //   ],
      //   "status": "APPROVED",
      //   "reason": [
      //     {
      //       "applied_amount":"",
      //       "approved_amount":"",
      //       "expense_type":"",
      //       "reason":""

      //     }

      //   ]
      // }

      

      if (finalUser.groups[i].name.toLowerCase() == "ssc user") {
        dispatch(setDisplayLoader("Display"));

        //delete records
        delete_tickets_ids.forEach((itemm_id) => {
          if (itemm_id) {
            console.log(`deleting..`, itemm_id);
            deleteTicket(itemm_id);
          }
        });

        let _ticketpayload = {
          attachment_path: [],
          attachment_delete: [],
          ticket_data: [],
        };

        //update modified tickets
        updated_tickets.forEach((itemm) => {
          itemm["updated_by_ssc_user_id"] = finalUser?.id;
        if(itemm["invoice_date"] && typeof itemm["invoice_date"]=="object"){
          itemm["invoice_date"]=itemm?.invoice_date?.toISOString()?.split('T')[0]
        }
          // delete itemm["tax_bill_amount"]
          // if(!itemm.id){

          // console.log(`new...`,itemm);

          let _payload = {
            travel_id: travelDetails?.travel_id,
            attachment_path: {},
            ticket_data: [
              {
                travel_date: "2023-09-18",
                // "from_location_id": 0,
                // "to_location_id": 0,
                mode_id: 0,
                paid_by: "",
                vendor_name: "",
                vendor_gst_no: "",
                airline_id: 0,
                vendor_category: "",
                vendor_gst_state: "",
                invoice_no: "",
                invoice_date: "2023-09-18",
                ticket_no: "",
                tax_rate_id: 0,
                taxable_amount: 0,
                tax_amount: 0,
                other_charges: 0,
                tax_bill_amount: 0,
                comments: "",
                // "from_city_input": "",
                // "to_city_input": "",
                // "pnr_no": ""
              },
            ],
          };

          _ticketpayload["ticket_data"].push(itemm);
          // console.log(`updating...`, itemm);
          console.log(`updating invo...`, typeof itemm["invoice_date"]);

          // }
        });
        // return;

        // newly added objects
        newticketDetails.forEach((itemm) => {
          if (!itemm.id) {
            itemm["updated_by_ssc_user_id"] = finalUser?.id;
            if(itemm["invoice_date"] && typeof itemm["invoice_date"]=="object"){
              itemm["invoice_date"]=itemm?.invoice_date?.toISOString()?.split('T')[0]
            }

            console.log(`new...`, itemm);

            let _payload = {
              travel_id: travelDetails?.travel_id,
              attachment_path: [],
              ticket_data: [
                {
                  travel_date: "2023-09-18",
                  // "from_location_id": 0,
                  // "to_location_id": 0,
                  mode_id: 0,
                  paid_by: "",
                  vendor_name: "",
                  vendor_gst_no: "",
                  airline_id: 0,
                  vendor_category: "",
                  vendor_gst_state: "",
                  invoice_no: "",
                  invoice_date: "2023-09-18",
                  ticket_no: "",
                  tax_rate_id: 0,
                  taxable_amount: 0,
                  tax_amount: 0,
                  other_charges: 0,
                  tax_bill_amount: 0,
                  comments: "",
                  // "from_city_input": "",
                  // "to_city_input": "",
                  // "pnr_no": ""
                },
              ],
            };

            // let payload = {
            //   "updated_by_ssc_user_id": finalUser?.id,
            //   "travel_id": travelDetails?.travel_id,
            //   "attachment_path": [],
            //   "ticket_data": [
            //     itemm
            //   ]
            // }

            // console.log(`saving...`, itemm);

            // saveTicket(payload)
            delete itemm["user_id"];
            delete itemm["id"];
            // delete itemm['user_id']

            _ticketpayload["ticket_data"].push(itemm);
          }
        });

        console.log(`ticket payload=>`, _ticketpayload);

        // return

        let successAll = 0;

        try {
          await updateVehiLog(travelDetails?.travel_id, updated_vehLogs);

          await updateTicket(_ticketpayload, travelDetails?.travel_id);
          // return

          await updateDAExpense(travelDetails?.travel_id, _dapayload);
          await updateHotelExpense(travelDetails?.travel_id, updated_hotels);
          await updateConveyanceExpense(
            travelDetails?.travel_id,
            updated_conveyance
          );
          successAll = 1;
        } catch (error) {
          dispatch(setDisplayLoader("Hide"));

          toast.error("Could not approve.", {
            autoClose: 4000,
          });

          console.log(
            `approval updating error`,
            error.message || "There was a problem with the API call"
          );
          return null;
        }

        let xx = await (async function () {
          if (travelDetails.request_type == "International") {
            let _foreignPayload = {
              attachment_path: {},
              attachment_delete: [],
              conveyance_data: foreignDetails.conveyDetail,
              hotel_data: foreignDetails.hotelDetail,
              food_data: foreignDetails.foodDetail,
              da_data: foreignDetails.inciDaDetail,
            };
            console.log(`=>_foreignPayload`, _foreignPayload);
            // return

            await updateForeignExpData(
              _foreignPayload,
              travelDetails?.travel_id
            );
            return 1;
          }
          return 0;
        })();

        console.log("...finished");
        // return

        approvedSendBack_payload["penalty"] = delayAmt;
        approvedSendBack_payload["delay_deduction"] = (
          (delayAmt * finalDetails?.final_approvedTotal) /
          100
        )?.toFixed(2);

        approvedSendBack_payload["total_claim_amount"] =
          finalDetails?.final_claim;
        approvedSendBack_payload["approve_expense_self"] =
          finalDetails?.final_approvedSelfExp;
        approvedSendBack_payload["approve_expense_company"] =
          finalDetails?.final_approvedCompExp;
        approvedSendBack_payload["total_approved_amount"] =
          finalDetails?.final_approvedTotal?.toFixed(2);
        approvedSendBack_payload["net_amount"] = finalDetails?.final_netAmt;

        approvedSendBack_payload["reason"][0]["applied_amount"] =
          allApproved.totalTicketValue;
        approvedSendBack_payload["reason"][0]["approved_amount"] =
          allApproved.totalTicketValue2;

        approvedSendBack_payload["reason"][1]["applied_amount"] =
          allApproved.totalHotelValue;
        approvedSendBack_payload["reason"][1]["approved_amount"] =
          allApproved.totalHotelValue2;

        approvedSendBack_payload["reason"][2]["applied_amount"] =
          allApproved.totalDaValue;
        approvedSendBack_payload["reason"][2]["approved_amount"] =
          allApproved.totalDaValue2;

        approvedSendBack_payload["reason"][3]["applied_amount"] =
          allApproved?.totalVehLogValue;
        approvedSendBack_payload["reason"][3]["approved_amount"] =
          allApproved?.totalVehLogValue2;

        approvedSendBack_payload["reason"][4]["applied_amount"] =
          allApproved.totalConveValue;
        approvedSendBack_payload["reason"][4]["approved_amount"] =
          allApproved.totalConveValue2;

        if (travelDetails.request_type == "International") {
          approvedSendBack_payload["reason"][5]["applied_amount"] =
            allApproved.totalForegHotel;
          approvedSendBack_payload["reason"][5]["approved_amount"] =
            allApproved.totalForegHotel2;

          approvedSendBack_payload["reason"][6]["applied_amount"] =
            allApproved.totalForegFood;
          approvedSendBack_payload["reason"][6]["approved_amount"] =
            allApproved.totalForegFood2;

          approvedSendBack_payload["reason"][7]["applied_amount"] =
            allApproved.totalForegDaValue;
          approvedSendBack_payload["reason"][7]["approved_amount"] =
            allApproved.totalForegDaValue2;

          approvedSendBack_payload["reason"][8]["applied_amount"] =
            allApproved.totalForegConveValue;
          approvedSendBack_payload["reason"][8]["approved_amount"] =
            allApproved.totalForegConveValue2;
        }

        console.log(`approvedSendBack_payload`, approvedSendBack_payload);

        await TravelListingAPI.approvePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));
            toast.success("Expense approved successfully!", {
              autoClose: 2000,
            });
            navigate(`/travel-expense`);
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc manager") {
        dispatch(setDisplayLoader("Display"));

        await TravelListingAPI.managerapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense approved successfully!", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc head") {
        dispatch(setDisplayLoader("Display"));
        await TravelListingAPI.headapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense approved successfully!", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      }
    }

    // })
    // .catch((error)=>{
    //   console.log(`error approve check!`,error);
    // })
  }

  const handleShow = () => setReasonShow(true);
  const handleForwardShow = () => setForwardReasonShow(true);
  const handleClose = () => {
    set_inputReason("");
    setReasonShow(false);
    setForwardReasonShow(false);
  };

  const handleReasonView = (typee) => {
    if (typee == "send_back") {
      handleShow();
    } else if (typee == "send_forward") {
      handleForwardShow();
    }
  };

  function do1() {
    alert();
  }

  async function sendBackToUser() {
    approvedSendBack_payload["status"] = "SEND BACK TO USER";
    approvedSendBack_payload["remarks"] = inputReason;

    console.log(`back..`, approvedSendBack_payload);
    // return

    for (let i = 0; i < finalUser.groups.length; i++) {
      // const payloa={
      //   "pick_list_id": [
      //     `${pick_item_id}`
      //   ],
      //   "status": "SEND BACK TO USER"
      // }

      if (finalUser.groups[i].name.toLowerCase() == "ssc user") {
        dispatch(setDisplayLoader("Display"));

        await TravelListingAPI.approvePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense sent back to user successfully", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-expense`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc manager") {
        dispatch(setDisplayLoader("Display"));

        await TravelListingAPI.managerapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense sent back to SSC executive successfully", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      } else if (finalUser.groups[i].name.toLowerCase() == "ssc head") {
        dispatch(setDisplayLoader("Display"));
        await TravelListingAPI.headapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense send back to SSC Manager successfully", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail!", {
              autoClose: 2000,
            });
            console.log(`error approve check!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      }
    }
  }

  async function forwardToHead() {
    approvedSendBack_payload["status"] = "FORWARD";
    approvedSendBack_payload["remarks"] = inputReason;
    approvedSendBack_payload["penalty"] = 0;
    approvedSendBack_payload["delay_deduction"] = 0;

        
    approvedSendBack_payload["net_amount"] = finalDetails?.final_apptotal;

    // console.log(`back..`,approvedSendBack_payload);
    // return

    for (let i = 0; i < finalUser.groups.length; i++) {
      // const payloa={
      //   "pick_list_id": [
      //     `${pick_item_id}`
      //   ],
      //   "status": "SEND BACK TO USER"
      // }

      if (finalUser.groups[i].name.toLowerCase() == "ssc manager") {
        dispatch(setDisplayLoader("Display"));

        await TravelListingAPI.managerapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            dispatch(setDisplayLoader("Hide"));

            toast.success("Expense Approved Successfully!", {
              autoClose: 2000,
            });
          })
          .catch((error) => {
            toast.error("fail while forward!", {
              autoClose: 2000,
            });
            console.log(`error approve forward!`, error);
          });

        navigate(`/travel-approve-expense`);
        break;
      }
    }
  }

  const getGstState = (paginate) => {
    ClaimTravel.getGstState(paginate)
      .then((response) => {
        setGstState(response?.data?.dataList?.result);
        let data = [];
        // response?.data?.dataList?.result.map((item) => {
        //   let data1 = {
        //     value: item?.id,
        //     label: `${item?.gst_state_code}-${item?.name}`,
        //   };
        //   data.push(data1);
        // });
        // setGstState(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // async function viewAttachments(){

  //   await TravelListingAPI.viewCheckExpenseAttachments(travelPostData.travel_id).then((response)=>{

  //     console.log(`docs resp=>`,response?.data);

  //   })
  //   .catch((error)=>{
  //     console.log(`error viewing docs!`,error);
  //   })

  // }

  const handleKey1 = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_free !== "" && is_free !== undefined && is_free !== null) {
      queryParm = queryParm + "&is_free=" + is_free;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const getVehicleAssignData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    vehicleService
      .getVehicleAssignData(
        handleKey1(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          is_free,
          user_id
        )
      )
      .then((response) => {
        setVehicleAssignValues(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getChcekExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    TravelListingAPI.getCheckExpenseList(
      handleKey(search, page_no, page_size, sort_by, paginate, status)
    ).then((response) => {
      // setTravelList(response?.data?.dataList?.result);
      // setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      // setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    travel_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&travel_id=" +
      travel_id;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };

  let abortTravelExpenseVehicleSummary = null;

  const getTravelExpenseVehicleSummary = (userId, travelId) => {
    abortTravelExpenseVehicleSummary = new AbortController();
    TravelListingAPI.getTravelExpenseVehicleSummary(
      userId,
      travelId,
      abortTravelExpenseVehicleSummary.signal
    ).then((res) => {
      if (res.status) setVehicalData(res.data.dataList);
    });
  };

  useEffect(() => {
    let qry = new URLSearchParams(window.location.search);

    let transaction_id = qry.get("id");
    let travel_id = transaction_id;

    // getChcekExpenseList(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "P")

    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager" ||
        data?.name.toLowerCase() === "ssc head"
      ) {
        localGroup = data?.name.toLowerCase();
        setInputsDisabled(
          localGroup == "ssc manager" || localGroup == "ssc head"
        );
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    set_travelPostData({
      ...travelPostData,
      travel_id: travel_id,
    });

    TravelListingAPI.getCheckExpenseList(
      handleKey("", 1, 10, "id", true, "P", travel_id)
    ).then((response) => {
      setApproveData({
        ...approveData,
        travel_id: travel_id,
        travelexp_approval_id:
          response?.data?.dataList?.result[0]?.travelexp_approval_id,
      });
      // setTravelList(response?.data?.dataList?.result);
      // setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      // setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });

    // let user_id = location?.state?.user_id;
    // let travelexp_approval_id = location?.state?.travelexp_approval_id

    TravelListingAPI.getTaxRate()
      .then((resp) => {
        set_taxRatesMenu(resp.data.dataList.tax_rate_data);
      })
      .catch((error) => {
        console.log(`tax rates..error`);
      });

    TravelListingAPI.getAirlines()
      .then((resp) => {
        set_AirlinesMenu(resp.data.dataList.airline_data);
      })
      .catch((error) => {
        console.log(`tax rates..error`);
      });

    getGstState(false);

    getTravelChat(travel_id);

    // getConveyenceList(travel_id);

    getTraveluserDetailsById(travel_id);
    getTravelDetailsById(travel_id);
    getContinentEntt(travel_id);

    // getTicketExpense(travel_id, 1578);

    // getVehicleExpense(travel_id, user_id);

    // getHotelExpense(travel_id, user_id);
    // getDAExpense(travel_id, user_id);
  }, []);

  const getContinentEntt = (travel_id) => {
    TravelListingAPI.getContinentEntt(travel_id)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setContAmount(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let delayamt = 0;

    if (travelDetails) {
      delayamt = (userGroup._userGroup == "ssc head"?0:calculateDelayDeductionPercent(
        moment(travelDetails?.dispatch_date).diff(
          moment(
            travelDetails?.travel_detail[
              travelDetails?.travel_detail.length - 1
            ]?.to_date,
            "YYYY-MM-DD"
          ),
          "days"
        )
      ));
      setdelayAmt(delayamt);
    }

    let finalClaim =
      allApproved.totalTicketValue +
      allApproved.totalConveValue +
      allApproved.totalDaValue +
      allApproved.totalHotelValue +
      allApproved.totalVehLogValue +
      allApproved.totalForegHotel +
      allApproved.totalForegFood +
      allApproved.totalForegDaValue +
      allApproved.totalForegConveValue 
    let finalSelfsum =
      allApproved.totaltikbyself +
      allApproved.totalDaValue2 +
      allApproved.total_hotel_byself +
      allApproved.total_conve_byself +
      allApproved?.totalVehLogValue2 +
      allApproved.totalForegHotelself +
      allApproved.totalForegFoodself +
      allApproved.totalForegDaValueself +
      allApproved.totalForegConveValueself ;
    let finalcomp =
      allApproved.totaltikbycompany +
      allApproved.totalForegHotelcomp +
      allApproved.totalForegFoodcomp +
      allApproved.totalForegDaValuecomp +
      allApproved.totalForegConveValuecomp;
    let final_apptotal =
      allApproved.totalTicketValue2 +
      allApproved.totalConveValue2 +
      allApproved.totalDaValue2 +
      allApproved.totalHotelValue2 +
      allApproved.totalVehLogValue2 +
      allApproved.totalForegHotel2 +
      allApproved.totalForegFood2 +
      allApproved.totalForegDaValue2 +
      allApproved.totalForegConveValue2;

    let final_claimself =
      allApproved.totaltikuserbyself +
      allApproved.totalDaValue +
      allApproved.totalHotelValue +
      allApproved?.totalVehLogValue +
      allApproved.totalConveValue +
      allApproved.totalForegHotelself +
      allApproved.totalForegFoodself +
      allApproved.totalForegDaValueself +
      allApproved.totalForegConveValueself ;

    let final_claimcompany =
      allApproved.totaltikuserbycompany +
      allApproved.totalForegHotelcomp +
      allApproved.totalForegFoodcomp +
      allApproved.totalForegDaValuecomp +
      allApproved.totalForegConveValuecomp;
    console.log(`eee`, allApproved);

    set_finalDetails((pre) => ({
      ...pre,
      final_userclaimself: final_claimself?.toFixed(2),
      final_userclaimcompany: final_claimcompany?.toFixed(2),
      final_approvedCompExp: finalcomp?.toFixed(2),
      final_approvedSelfExp: finalSelfsum?.toFixed(2),
      final_claim: finalClaim?.toFixed(2),

      final_approvedTotal: final_apptotal,

      final_netAmt: (
        final_apptotal -
        (delayamt * final_apptotal) / 100
      )?.toFixed(2),
    }));

    // finalDetails.final_approvedCompExp=allApproved.totaltikbycompany
    // finalDetails.final_approvedSelfExp=allApproved.totaltikbyself + allApproved.total_da_byself + allApproved.total_hotel_byself
    // finalDetails.final_claim=finalDetails.final_approvedCompExp+finalDetails.final_approvedSelfExp

    // finalDetails.final_approvedTotal=allApproved.totalTicketValue2+allApproved.totalConveValue2+allApproved.totalDaValue2+allApproved.totalHotelValue2+allApproved.totalVehLogValue2

    // finalDetails.final_netAmt= finalDetails.final_approvedTotal -delayamt

    console.log(`finalDetails`, finalDetails);
  }, [allApproved]);

  const getTravelChat = (id) => {
    TravelListingAPI.getTravelChat(id).then((response) => {
      console.log("chat->", response?.data?.dataList?.result);
      setTravelChats(response?.data?.dataList?.result);
    });
  };

  // const saveTicket = (payload) => {

  //   ClaimTravel.saveTicket(payload)
  //     .then((response) => {
  //       if (response.data.status === true && response.data.code === 200) {
  //         toast.success("created!",{
  //           autoClose: 2000
  //         });

  //       }
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         "failed"
  //       );
  //     });
  // };

  const updateForeignExpData = async (payload, id) => {
    TravelListingAPI.saveForeignExpData(payload, id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          // toast.success("Updated Foreign expenses",{
          //   autoClose: 3000
          // });
          // getTicketData(state?.id);
          // setCheckActiveTab();
          // navigate("/expense-listing");
        }
      })
      .catch((error) => {
        // toast.success("Fail Updated Foreign expenses",{
        //   autoClose: 3000
        // });
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateTicket = async (payload, id) => {
    ClaimTravel.updateTicket(payload, id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          // toast.success("updated",{
          //   autoClose: 2000
          // });
          // getTicketData(state?.id);
          // setCheckActiveTab();
          // navigate("/expense-listing");
        }
      })
      .catch((error) => {
        toast.error("Error in updating ticket");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
        throw error;
      });
  };

  const deleteTicket = (del_id) => {
    // let payload = {
    //   travel_id: state?.id,
    //   attachment_path: uploadAttachments.attachment,
    //   ticket_data: removeNullVlaue(newData),
    // };
    ClaimTravel.deleteTicket(del_id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          // toast.success("deleted..",{
          //   autoClose: 2000
          // });
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const postTravelChat = (id) => {
  //   TravelListingAPI.postTravelChat(id).then((response) => {
  //     console.log("posted chat->",response?.data?.dataList?.result);
  //   });
  // };

  const postTravelChat = async () => {
    dispatch(setDisplayLoader("Display"));
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.", {
        autoClose: 2000,
      });
      return;
    }
    console.log("posted chat->", travelPostData);
    let payl = { ...travelPostData };

    let attachments2 = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          console.log(`uploaded for index`, afilee);

          attachments2.push(response?.data);
          // console.log(`arrres..`,attachments2);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `, {
            autoClose: 2000,
          });
          return;
        });
    }

    payl.attachment_list = [...attachments2];

    TravelListingAPI.postTravelChat(travelPostData.travel_id, payl)
      .then((response) => {
        console.log("posted chat->", travelPostData);
        getTravelChat(travelPostData.travel_id);
        set_travelPostData({
          ...travelPostData,
          message: "",
          attachment_path: null,
        });
        document.getElementById("querytext").value = "";

        dispatch(setDisplayLoader("Hide"));
        setselectedFiles([]);
        setFilesToUpload([]);
        set_imageLocalPreviewList([]);
      })
      .catch((err) => {
        toast.error("Unable to post data.", {
          autoClose: 2000,
        });
      });
  };

  const deleteTicketData = (_idx) => {
    let _travelDetails = [];
    console.log(`del...`, _idx);
    console.log(`deta`, travelDetails);

    newticketDetails.forEach((itemm, idx) => {
      if (_idx != idx) {
        _travelDetails.push(itemm);
      } else {
        if (itemm.id) {
          delete_tickets_ids.push(itemm?.id);
        }
      }
    });

    console.log(`_trav...`, delete_tickets_ids);

    set_newTicketDetails(_travelDetails);
    calcApprovedTicket(_travelDetails);
  };

  const updateReasons = (_idx, _reason) => {
    console.log("resaon = ", _idx, _reason);
    let _approvedSendBack_payload = { ...approvedSendBack_payload };
    if (_idx == "remarks") {
      _approvedSendBack_payload.remarks = _reason;
    } else {
      console.log(" = ", _reason);
      _approvedSendBack_payload.reason[_idx]["reason"] = _reason;
    }
    set_approvedSendBack_payload(_approvedSendBack_payload);
  };

  const updateHotelExpense = async (trav_id, data) => {
    console.log(`updating hotel expense..`);

    let payload = {
      attachment_path: [],
      attachment_delete: [],
      hotel_data: data,
    };

    await TravelListingAPI.updateHotelExpense(trav_id, payload)
      .then((resp) => {
        // toast.success("hotel updated",{
        //   autoClose: 2000
        // })
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        console.log(`error approve check!`, error.res);
        throw error;
      });
  };

  const updateDAExpense = async (trav_id, data) => {
    console.log(`updating da expense..`);

    // let payload = {
    //   // "maximum_entitlement": 0,
    //   // "total_amount": 0,
    //   "attachment_path": [

    //   ],
    //   "attachment_delete": [],

    //   "da_data": data
    // }
    await TravelListingAPI.updateDa(data, trav_id)
      .then((resp) => {
        // toast.success("da updated",{
        //   autoClose: 2000
        // })
      })
      .catch((error) => {
        toast.error("Error in updating DA", {
          autoClose: 2000,
        });
        console.log(`error approve check!`, error);
        throw error;
      });
  };

  const updateVehiLog = async (trav_id, data) => {
    console.log(`updating vehicle expense..`);

    let payload = {
      attachment_path: [],
      attachment_delete: [],
      vehicle_data: data,
    };

    await updateVehicleLogssc(trav_id, payload)
      .then((resp) => {
        // toast.success("vehicle log updated",{
        //   autoClose: 2000
        // })
      })
      .catch((error) => {
        toast.error("Error in updating vehicle.", {
          autoClose: 2000,
        });
        console.log(`error approve check!`, error);
        throw error;
      });
  };

  const updateConveyanceExpense = async (trav_id, data) => {
    console.log(`updating conveyance expense..`);

    let payload = {
      attachment_path: [],
      attachment_delete: [],
      conveyance_data: data,
    };

    await TravelListingAPI.updateConveyanceExpense(trav_id, payload)
      .then((resp) => {
        // toast.success("Locaonv updated",{
        //   autoClose: 2000
        // })
      })
      .catch((error) => {
        toast.error("Error in updating Conveyance", {
          autoClose: 2000,
        });
        console.log(`error approve check!`, error);
        throw error;
      });
  };

  const modifyFinalSumDetails = (_Lkey = "", _Lval = "", _Rkey = "") => {
    switch (_Lkey) {
      case "totalTicketValue":
        finalDetails[_Rkey] += _Lval;

        finalDetails.final_approvedTotal = finalDetails.final_claim;
        finalDetails.final_netAmt = finalDetails.final_approvedTotal;

        break;

      case "totalTicketValue2":
        break;

      default:
        break;
    }
  };

  function calcApprovedTicket(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    let _ticketSums = {
      taxamt: 0.0,
      other: 0.0,
      billamt: 0.0,
      claimed: 0.0,
      apprtax: 0.0,
      approther: 0.0,
      apprtaxable: 0.0,
      appramt: 0.0,
    };

    _ndata?.forEach((data) => {
      _ticketSums.taxamt += data?.tax_amount;
      _ticketSums.other += data?.other_charges;
      _ticketSums.billamt += data?.taxable_amount;
      _ticketSums.claimed +=
        data?.taxable_amount + data?.tax_amount + data?.other_charges;
      _ticketSums.apprtax += data?.appr_tax_amount;
      _ticketSums.approther += data?.appr_other_charges;
      _ticketSums.apprtaxable += data?.appr_taxable_amount;
      _ticketSums.appramt +=
        data?.appr_taxable_amount +
        data?.appr_tax_amount +
        data?.appr_other_charges;

      data.appr_total_bill_amount =
        data?.appr_tax_amount +
        Number(data?.appr_other_charges) +
        data?.appr_taxable_amount;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_total_bill_amount;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_total_bill_amount;

          break;
      }
      console.log(`tikk`, data?.paid_by, totalticketComp, totalticketself);

      if (is_applied) {
        totalticketvalue += data?.tax_bill_amount;
      } else {
        totalticketvalue +=
          data?.tax_amount + data?.taxable_amount + data?.other_charges;
      }

      totalticketvalue2 += data?.appr_total_bill_amount;
    });

    setticketSums(_ticketSums);

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);
    console.log(`tik..value by user`, allApproved.totalTicketValue);
    console.log(`tik..value by ssc`, totalticketvalue);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalTicketValue: is_applied ? totalticketvalue : prev.totalTicketValue,
      totalTicketValueByUser: is_applied
        ? totalticketvalue
        : prev.totalTicketValue,
      changedtotalTicketValueBySSC: totalticketvalue,
      totalTicketValue2: totalticketvalue2,
      totaltikbyself: totalticketself,
      totaltikuserbyself: is_applied
        ? totalticketself
        : prev.totaltikuserbyself,
      totaltikbycompany: totalticketComp,
      totaltikuserbycompany: is_applied
        ? totalticketComp
        : prev.totaltikuserbycompany,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  const calcApprovedConveyance = (_ndata, modif = "") => {
    let totalConveValue = 0,
      totalConveValue2 = 0;
    // console.log(`convey`,_ndata);

    _ndata?.forEach((data) => {
      totalConveValue += data?.amount;
      totalConveValue2 += data?.approved_amount;
    });

    // setTotalConveValue(totalConveValue)
    // setTotalConveValue2(totalConveValue2)

    // allApproved.totalConveValue=totalConveValue
    // allApproved.totalConveValue2=totalConveValue2

    //   if(modif){

    //     set_allApproved((prev) => ({
    //     ...prev,
    //     // totalDaValue: da,
    //     totalDaValue2: da,
    //     // total_da_byself: da,

    //   }))
    // }
    // else{

    set_allApproved((pre) => {
      return {
        ...pre,
        totalConveValue: totalConveValue,
        totalConveValue2: totalConveValue2,
        total_conve_byself: totalConveValue2,
      };
    });

    // }

    // finalDetails.final_approvedTotal=totalTicketValue2+totalConveValue2
    // finalDetails.final_approvedSelfExp+=totalConveValue
  };

  function calcApprovedDA(_ndata, modif = "") {
    let da = 0,
      da2 = 0;
    let totalticketComp = 0,
      totalticketself = 0;

    console.log(`calcda..`, _ndata);

    _ndata?.forEach((data) => {
      // console.log(`da..`, data?.SelectedKey?.amt ? data?.SelectedKey?.amt : data?.appr_da_amount);
      da = da + data?.gross_total;
      da2 =
        da2 +
        (data?.SelectedKey[0]?.amt >= 0
          ? data?.SelectedKey[0]?.amt
          : data?.appr_da_amount);
      // if (data?.SelectedKey[0]?.label?.toLowerCase()?.includes("company")){
      //   totalticketComp += ( (data?.SelectedKey[0]?.amt >= 0 ? data?.SelectedKey[0]?.amt : data?.appr_da_amount))
      // }else{
      //   totalticketself += ( (data?.SelectedKey[0]?.amt >= 0 ? data?.SelectedKey[0]?.amt : data?.appr_da_amount))
      // }
      // totalConveValue2+=data?.approved_amount
    });

    // setTotalDaValue(da)
    // setTotalDaValue2(da)
    // allApproved.totalDaValue=da
    // allApproved.totalDaValue2=da

    if (modif) {
      set_allApproved((prev) => ({
        ...prev,
        // totalDaValue: da,
        totalDaValue2: da2,
        // total_da_byself: totalticketself,
        // total_da_bycompany: totalticketComp,
      }));
    } else {
      set_allApproved((prev) => ({
        ...prev,
        totalDaValue: da,
        totalDaValue2: da2,
        // total_da_byself: totalticketself,
        // total_da_bycompany: totalticketComp,
      }));
    }

    // finalDetails.final_approvedTotal=totalTicketValue2+totalConveValue2
  }

  function calcApprovedHotel(_ndata) {
    let totalhotval = 0,
      totalhotval2 = 0;

    _ndata?.map((data) => {
      totalhotval += data?.total_claim_amount;
      totalhotval2 += data?.appr_amount;
    });

    // setTotalHotelValue(totalhotval)
    // setTotalHotelValue2(totalhotval2)

    // allApproved.totalHotelValue=totalhotval
    // allApproved.totalHotelValue2=totalhotval2

    console.log(`on change hotel ...`, totalhotval2);

    set_allApproved((prev) => ({
      ...prev,
      totalHotelValue: totalhotval,
      totalHotelValue2: totalhotval2,
      total_hotel_byself: totalhotval2,
    }));
  }

  function calcApprovedVehicle(_ndata, modif = "") {
    let totalVehval = 0,
      totalVehval2 = 0;

    // set_vehicleLogSums

    for (const [key, value] of Object.entries(vehicleLogSums)) {
      console.log(`${key}: ${value}`);
      if (typeof value == "number") {
        vehicleLogSums[key] = 0;
      }

      if (typeof value == "string") {
        vehicleLogSums[key] = "";
      }
    }

    _ndata?.map((data) => {
      for (const [key, value] of Object.entries(data)) {
        console.log(`${key}: ${value}`);
        if (typeof value == "number") {
          vehicleLogSums[key] += value;
        }
      }
    });

    _ndata?.map((data) => {
      totalVehval += data?.total_amount;
      totalVehval2 += data?.approved_amount;
    });

    if (modif) {
      set_allApproved((prev) => ({
        ...prev,
        // totalDaValue: da,
        totalVehLogValue2: totalVehval2,
        // total_da_byself: da,
      }));
    } else {
      set_allApproved((prev) => ({
        ...prev,
        totalVehLogValue: totalVehval,
        totalVehLogValue2: totalVehval2,
        // total_da_byself: da,
      }));
    }

    console.log(`on change vehicle ...`);

    set_allApproved((prev) => ({
      ...prev,
      totalVehLogValue: totalVehval,
      totalVehLogValue2: totalVehval2,
      // total_hotel_byself : totalhotval
    }));
  }

  function calcApprovedForegHotel(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegHotel: totalticketvalue,
      totalForegHotel2: totalticketvalue2,
      totalForegHotelself: totalticketself,
      totalForegHotelcomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegFood(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegFood: totalticketvalue,
      totalForegFood2: totalticketvalue2,
      totalForegFoodself: totalticketself,
      totalForegFoodcomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegDA(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegDaValue: totalticketvalue,
      totalForegDaValue2: totalticketvalue2,
      totalForegDaValueself: totalticketself,
      totalForegDaValuecomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  function calcApprovedForegConvey(_ndata, is_applied = false) {
    let totalticketvalue = 0,
      totalticketvalue2 = 0.0;
    let totalticketComp = 0,
      totalticketself = 0;

    _ndata?.forEach((data) => {
      data.appr_amount_inr = data?.appr_amount * data?.appr_exchange_rate;

      switch (data?.paid_by?.toLowerCase()) {
        case "company":
          totalticketComp += data?.appr_amount_inr;
          break;
        // case "self":
        //   totalticketself+=data?.tax_bill_amount
        //   break;
        default:
          totalticketself += data?.appr_amount_inr;

          break;
      }

      totalticketvalue += data?.amount_in_inr;

      totalticketvalue2 += data?.appr_amount_inr;
    });

    // allApproved.totalTicketValue=totalticketvalue
    // allApproved.totalTicketValue2=totalticketvalue2

    console.log(`tik..`, totalticketvalue, totalticketvalue2);

    // let oldDat={...allApproved}
    // oldDat.     totalTicketValue= totalticketvalue
    //  oldDat. totalTicketValue2 = totalticketvalue2
    //   // totaltikbyself : totalticketself,
    //   // totaltikbycompany : totalticketComp

    //   console.log(oldDat);

    set_allApproved((prev) => ({
      ...prev,
      totalForegConveValue: totalticketvalue,
      totalForegConveValue2: totalticketvalue2,
      totalForegConveValueself: totalticketself,
      totalForegConveValuecomp: totalticketComp,
    }));

    // setTotalTicketValue(totalticketvalue)
    // setTotalTicketValue2(totalticketvalue2)
  }

  const modifyForegExp = (expense_name, idx, _key, val) => {
    let vall = val != "" ? parseFloat(val) : 0;

    switch (expense_name) {
      case "hotel":
        let foregHotel = [...foreignDetails.hotelDetail];

        if (_key.includes("appr") && val != "") {
          // if((_key == "appr_amount" || _key == "appr_exchange_rate") && !amountRegex.test(vall)) return;

          console.log(`hotelforeg`, val, _key);
          foregHotel[idx][_key] =
            _key == "appr_amount" || _key == "appr_exchange_rate"
              ? vall
              : parseInt(val);
        } else {
          foregHotel[idx][_key] = val;
        }

        setForeignDetails({
          ...foreignDetails,
          hotelDetail: foregHotel,
          // foodDetail:foregFood,
          // inciDaDetail:foregDA,
          // conveyDetail:foregConv,
        });

        calcApprovedForegHotel(foregHotel);

        break;
      case "food":
        let foregFood = [...foreignDetails.foodDetail];

        if (_key.includes("appr")) {
          // if((_key == "appr_amount" || _key == "appr_exchange_rate") && !amountRegex.test(vall)) return;

          foregFood[idx][_key] =
            _key == "appr_amount" || _key == "appr_exchange_rate"
              ? vall
              : Number(val);
        } else {
          foregFood[idx][_key] = val;
        }

        setForeignDetails({
          ...foreignDetails,
          // hotelDetail:foregHotel,
          foodDetail: foregFood,
          // inciDaDetail:foregDA,
          // conveyDetail:foregConv,
        });

        calcApprovedForegFood(foregFood);

        break;
      case "da":
        let foregDA = [...foreignDetails.inciDaDetail];
        if (_key.includes("date")) {
          foregDA[idx][_key] = val;
        } else {
          // if((_key == "appr_amount" || _key == "appr_exchange_rate") && !amountRegex.test(vall)) return;
          foregDA[idx][_key] =
            _key == "appr_amount" || _key == "appr_exchange_rate"
              ? vall
              : Number(val);
        }
        setForeignDetails({
          ...foreignDetails,
          // hotelDetail:foregHotel,
          // foodDetail:foregFood,
          inciDaDetail: foregDA,
          // conveyDetail:foregConv,
        });
        calcApprovedForegDA(foregDA);

        break;
      case "convey":
        let foregConv = [...foreignDetails.conveyDetail];

        if (_key.includes("appr")) {
          // if((_key == "appr_amount" || _key == "appr_exchange_rate") && !amountRegex.test(vall)) return;

          foregConv[idx][_key] =
            _key == "appr_amount" || _key == "appr_exchange_rate"
              ? vall
              : Number(val);
        } else {
          foregConv[idx][_key] = val;
        }
        setForeignDetails({
          ...foreignDetails,
          // hotelDetail:foregHotel,
          // foodDetail:foregFood,
          // inciDaDetail:foregDA,
          conveyDetail: foregConv,
        });
        calcApprovedForegConvey(foregConv);

        break;

      default:
        break;
    }
  };

  const modifyTicket = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = val != "" ? parseFloat(val) : 0;
    // let vall = val != "" ? parseFloat(val) : 0;
    console.log(`typing..`, iidx, vall,'it was',val);
    let _travelDetails = [...newticketDetails];

    if (iidx >= 0 && _k) {
      switch (_k) {
        case "appr_tax_rate":
          taxRatesMenu?.every((data) => {
            if (data?.pi_tax_id == vall) {
              _travelDetails[iidx]["appr_tax_rate"] = data?.pi_tax_id;
              _travelDetails[iidx]["appr_tax_amount"] =
                _travelDetails[iidx]?.appr_taxable_amount *
                0.01 *
                parseFloat(data?.name);

              console.log(
                `onchang`,
                _travelDetails[iidx]?.appr_tax_amount,
                _travelDetails[iidx]?.appr_taxable_amount,
                _travelDetails[iidx]?.appr_other_charges
              );

              _travelDetails[iidx]["appr_total_bill_amount"] =
                _travelDetails[iidx]?.appr_tax_amount +
                _travelDetails[iidx]?.appr_taxable_amount +
                _travelDetails[iidx]?.appr_other_charges;
              // modifyFinalSumDetails()

              // console.log(`onchang`, _travelDetails[iidx]["appr_total_bill_amount"]);
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        case "appr_other_charges":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            // _travelDetails[iidx]["appr_other_charges"]=0
          } else {
            let tmp = parseFloat(_travelDetails[iidx]["other_charges"]);
            if (vall > tmp) vall = tmp;
            let temp =
              _travelDetails[iidx]["appr_total_bill_amount"] -
              newticketDetails[iidx][_k];
            _travelDetails[iidx]["appr_other_charges"] = vall;

            _travelDetails[iidx]["appr_total_bill_amount"] = temp + vall;
          }
          break;
        case "appr_taxable_amount":
          // if(!amountRegex.test(vall)) return;
          let tmp = parseFloat(_travelDetails[iidx]["taxable_amount"]);
          if (vall > tmp) vall = tmp;

          _travelDetails[iidx][_k] = vall;

          taxRatesMenu?.every((data) => {
            if (data?.pi_tax_id == _travelDetails[iidx]["appr_tax_rate"]) {
              _travelDetails[iidx]["appr_tax_amount"] =
                _travelDetails[iidx]?.appr_taxable_amount *
                0.01 *
                parseFloat(data?.name);

              console.log(
                `onchang`,
                _travelDetails[iidx]?.appr_tax_amount,
                _travelDetails[iidx]?.appr_taxable_amount,
                _travelDetails[iidx]?.appr_other_charges
              );

              _travelDetails[iidx]["appr_total_bill_amount"] =
                _travelDetails[iidx]?.appr_tax_amount +
                _travelDetails[iidx]?.appr_taxable_amount +
                _travelDetails[iidx]?.appr_other_charges;
              // modifyFinalSumDetails()

              // console.log(`onchang`, _travelDetails[iidx]["appr_total_bill_amount"]);
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        case "other_charges":
          // if(!amountRegex.test(vall)) return;
          if (!Number.isNaN(vall)) {
            // let tmp = parseFloat(_travelDetails[iidx]["other_charges"]);
            // if(vall > tmp) vall=tmp
            console.log(`entering `, vall);
            _travelDetails[iidx][_k] = parseFloat(vall);
            _travelDetails[iidx]["appr_other_charges"] = vall;
            _travelDetails[iidx]["tax_bill_amount"] =
              _travelDetails[iidx]["taxable_amount"] +
              _travelDetails[iidx]["other_charges"] +
              _travelDetails[iidx]["tax_amount"];
          }
          break;

        case "taxable_amount":
          // if(!amountRegex.test(vall)) return;
          if (!Number.isNaN(vall)) {
            // let tmp = parseFloat(_travelDetails[iidx]["other_charges"]);
            // if(vall > tmp) vall=tmp
            console.log(`entering taxable_amount`, vall);
            _travelDetails[iidx][_k] = parseFloat(vall);
            _travelDetails[iidx]["appr_taxable_amount"] = vall;
            _travelDetails[iidx]["tax_bill_amount"] =
              _travelDetails[iidx]["taxable_amount"] +
              _travelDetails[iidx]["other_charges"] +
              _travelDetails[iidx]["tax_amount"];
          }
          break;

        default:
          // if(( _k === "taxable_amount") && !amountRegex.test(vall)) return;
          _travelDetails[iidx][_k] = val;
          break;
      }

      set_newTicketDetails(_travelDetails);

      calcApprovedTicket(_travelDetails);
    }

    if (_dataObj?.id) {
      for (let _i = 0; _i < updated_tickets.length; _i++) {
        if (updated_tickets[_i]["id"] == _dataObj?.id) {
          updated_tickets[_i] = _dataObj;
          return;
        }
      }

      updated_tickets.push(_dataObj);
    }
  };

  const modifex = (e, _k) => {
    console.log(_k, e.target.value);
    var raw = e.target.value;
    var v = parseFloat(e.target.value);
    // if(raw.length>0 && raw=="."){
    //   v=raw+"."
    // }
    // if (isNaN(v)) {
    //     v=0;
    // }
    // else {
    //     v = v.toFixed(2);
    // }

    set_ee({
      ...ex,
      bb: v,
    });
  };

  const modifyHotel = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = val != "" ? parseFloat(val) : 0;
    console.log(`typing..`, iidx, vall, _dataObj?.id);
    let _hotelDetails = [...hotelDetails];

    if (iidx >= 0 && _k) {
      // _hotelDetails[iidx][_k]=vall
      let temp = 0;
      switch (_k) {
        case "appr_tax_rate":
          console.log(`sent`, vall);
          taxRatesMenu?.every((data) => {
            if (data?.id == vall) {
              console.log(`got tax `, _hotelDetails);
              _hotelDetails[iidx]["appr_tax_rate_id"] = vall;
              _hotelDetails[iidx]["appr_tax_amount"] =
                _hotelDetails[iidx]?.appr_bill_amount *
                0.01 *
                parseFloat(data?.name);
              _hotelDetails[iidx]["appr_amount"] =
                _hotelDetails[iidx]?.appr_claim_amount +
                _hotelDetails[iidx]?.appr_tax_amount;
              // modifyFinalSumDetails()
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        // case "appr_other_charges":
        //   if(vall > _hotelDetails[iidx]["other_charges"]) vall = _hotelDetails[iidx]["other_charges"];
        //   temp = _hotelDetails[iidx]["appr_amount"] - (_hotelDetails[iidx][_k] ? _hotelDetails[iidx][_k] : 0)
        //   _hotelDetails[iidx][_k] = vall != NaN ? vall : 0
        //   _hotelDetails[iidx]["appr_amount"] = temp + _hotelDetails[iidx][_k]
        //   break;

        case "hotel_gst_no":
          // let temp2=_hotelDetails[iidx]["appr_amount"]-hotelDetails[iidx][_k]
          if (!_hotelDetails[iidx]?.orig_hotel_gst_no) {
            _hotelDetails[iidx]["orig_hotel_gst_no"] =
              _hotelDetails[iidx]["hotel_gst_no"];
          }
          _hotelDetails[iidx]["hotel_gst_no"] = val;
          break;

        case "invoice_no":
          // let temp2=_hotelDetails[iidx]["appr_amount"]-hotelDetails[iidx][_k]
          if (!_hotelDetails[iidx]?.orig_invoice_no) {
            _hotelDetails[iidx]["orig_invoice_no"] =
              _hotelDetails[iidx]["invoice_no"];
          }
          _hotelDetails[iidx]["invoice_no"] = val;
          break;
        case "appr_claim_amount":
          // if(!amountRegex.test(vall)) return;
          if (vall > _hotelDetails[iidx]["claim_amount"])
            vall = _hotelDetails[iidx]["claim_amount"];
          _hotelDetails[iidx][_k] = vall != NaN ? vall : 0;
          taxRatesMenu?.every((data) => {
            if (data?.id == _hotelDetails[iidx]["appr_tax_rate_id"]) {
              // _hotelDetails[iidx]["appr_tax_amount"] =
              //   _hotelDetails[iidx]?.appr_claim_amount *
              //   0.01 *
              //   parseFloat(data?.name);
              _hotelDetails[iidx]["appr_amount"] =
                _hotelDetails[iidx]?.appr_claim_amount +
                _hotelDetails[iidx]?.appr_tax_amount;
              // modifyFinalSumDetails()
              // setTotalTicketValue2()
              return false;
            }
            return true;
          });
          break;

        case "appr_bill_amount":
          // if(!amountRegex.test(vall)) return;
          if (vall > _hotelDetails[iidx]["taxable_amount"])
            vall = _hotelDetails[iidx]["taxable_amount"];
          _hotelDetails[iidx][_k] = vall;

          taxRatesMenu?.every((data) => {
            if (data?.id == _hotelDetails[iidx]["appr_tax_rate_id"]) {
              _hotelDetails[iidx]["appr_tax_amount"] =
                _hotelDetails[iidx]?.appr_bill_amount *
                0.01 *
                parseFloat(data?.name);
              _hotelDetails[iidx]["appr_amount"] =
                _hotelDetails[iidx]?.appr_claim_amount +
                _hotelDetails[iidx]?.appr_tax_amount;

              return false;
            }
            return true;
          });

          break;

        default:
          _hotelDetails[iidx][_k] = val;
          break;
      }
      let sum = 0;
      _hotelDetails.forEach((obj) => {
        sum += obj.appr_amount;
      });
      set_allApproved({
        ...allApproved,
        totalHotelValue2: sum,
        total_hotel_byself: sum,
      });
      _hotelDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setHotelDetails(_hotelDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_hotels.length; _i++) {
      if (updated_hotels[_i]["id"] == _dataObj?.id) {
        updated_hotels[_i] = _dataObj;
        return;
      }
    }

    updated_hotels.push(_hotelDetails[iidx]);
    calcApprovedHotel(_hotelDetails);
    // }
  };

  const calculateTotalAmt = (e, i) => {
    if (e !== undefined) {
      let ttData = JSON.parse(JSON.stringify(daDataList));
      console.log(e, daDataList, "jai8");

      ttData[i].amt = (Number(ttData[i]?.entitlement) * Number(e.value)) / 100;
      ttData[i].SelectedKey = e;
      setDaDataList(ttData);
    }
  };

  const calculateTotalAmt2 = (e, entitlement) => {
    console.log("jai7", e, entitlement);
    if (e !== undefined) {
      let ttData = JSON.parse(JSON.stringify(daDataList));
      return (Number(entitlement) * Number(e.value)) / 100;
    }
  };

  const modifyDA = (_dataObj, iidx = -1, _k = "", vall) => {
    console.log(`typing..`, iidx, vall);
    let _daDetails = [...daDetails];

    if (iidx >= 0 && _k) {
      if (_k == "appr_da_remarks") {
        vall = JSON.parse(vall);
        let calcamt =
          (Number(_daDetails[iidx]?.entitlement) * Number(vall.value)) / 100;
        console.log("setting.", calcamt);
        _daDetails[iidx]["appr_da_amount"] = calcamt;
        _daDetails[iidx]["appr_da_remarks"] = vall?.label;
        _daDetails[iidx]["appr_da_percent"] = vall?.value;

        _dataObj.SelectedKey = [
          {
            amt: calcamt,
            value: vall?.value,
            label: vall?.label,
          },
        ];
      }

      _daDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      setDADetails(_daDetails);
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_da.length; _i++) {
      if (updated_da[_i]["id"] == _dataObj?.id) {
        updated_da[_i][_k] = _dataObj.remarks;
        // return
      }
    }

    updated_da.push(_daDetails[iidx]);

    calcApprovedDA(_daDetails, "y");
  };

  const modifyConveyance = (_dataObj, iidx = -1, _k = "", val = "") => {
    let vall = val != "" ? parseFloat(val) : 0;
    let _conveyanceDetails = [...conveyanceDetails];

    let temp = 0;

    if (_k === "approved_amount") {
      // if(!amountRegex.test(vall)) return;
      if (vall > _dataObj.amount) vall = _dataObj.amount;
    }

    if (iidx >= 0 && _k) {
      _conveyanceDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;
      // setTotalConveValue2(totalConveValue2-_conveyanceDetails[iidx][_k]+vall)
      _conveyanceDetails[iidx][_k] = vall;

      setConveyanceDetails(_conveyanceDetails);
    }

    // if(_dataObj?.id){
    // console.log(`sending conve 1..`);
    for (let _i = 0; _i < updated_conveyance.length; _i++) {
      if (updated_conveyance[_i]["id"] == _dataObj?.id) {
        updated_conveyance[_i]["approved_amount"] = _dataObj.approved_amount;
        break;
      }
    }
    updated_conveyance.push(_conveyanceDetails[iidx]);
    // }
    // console.log(`sending conve 2..`);
    calcApprovedConveyance(_conveyanceDetails, "y");
  };

  const modifyVehicle = (_dataObj, iidx = -1, _k = "", vall = "") => {
    console.log(`typing..`, iidx, vall);
    vall = vall != "" ? parseFloat(vall) : 0;
    let _vehicleDetails = [...vehicleDetails];

    if (iidx >= 0 && _k) {
      switch (_k) {
        case "appr_driver_daily_wages":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            _vehicleDetails[iidx]["appr_driver_daily_wages"] = 0;
          } else {
            console.log(
              `remm`,
              typeof vall,
              typeof _vehicleDetails[iidx]["approved_amount"]
            );
            let temp =
              _vehicleDetails[iidx]["approved_amount"] -
              vehicleDetails[iidx][_k];
            _vehicleDetails[iidx]["appr_driver_daily_wages"] = vall;
            _vehicleDetails[iidx]["approved_amount"] = temp + vall;
          }

          break;

        case "appr_washing_amount":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            _vehicleDetails[iidx]["appr_washing_amount"] = 0;
          } else {
            console.log(
              `remm`,
              typeof vall,
              typeof _vehicleDetails[iidx]["approved_amount"]
            );
            let temp =
              _vehicleDetails[iidx]["approved_amount"] -
              vehicleDetails[iidx][_k];
            _vehicleDetails[iidx]["appr_washing_amount"] = vall;
            _vehicleDetails[iidx]["approved_amount"] = temp + vall;
          }

          break;

        case "appr_other_expenses":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            _vehicleDetails[iidx]["appr_other_expenses"] = 0;
          } else {
            console.log(
              `remm`,
              typeof vall,
              typeof _vehicleDetails[iidx]["approved_amount"]
            );
            let temp =
              _vehicleDetails[iidx]["approved_amount"] -
              vehicleDetails[iidx][_k];
            _vehicleDetails[iidx]["appr_other_expenses"] = vall;
            _vehicleDetails[iidx]["approved_amount"] = temp + vall;
          }

          break;

        case "appr_fuel_amount":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            _vehicleDetails[iidx]["appr_fuel_amount"] = 0;
          } else {
            console.log(
              `remm`,
              typeof vall,
              typeof _vehicleDetails[iidx]["approved_amount"]
            );
            let temp =
              _vehicleDetails[iidx]["approved_amount"] -
              vehicleDetails[iidx][_k];
            _vehicleDetails[iidx]["appr_fuel_amount"] = vall;
            _vehicleDetails[iidx]["approved_amount"] = temp + vall;
          }

          break;

        case "appr_driver_da_value":
          // if(!amountRegex.test(vall)) return;
          if (Number.isNaN(vall)) {
            _vehicleDetails[iidx]["appr_driver_da_value"] = 0;
          } else {
            console.log(
              `remm`,
              typeof vall,
              typeof _vehicleDetails[iidx]["approved_amount"]
            );
            let temp =
              _vehicleDetails[iidx]["approved_amount"] -
              vehicleDetails[iidx][_k];
            _vehicleDetails[iidx]["appr_driver_da_value"] = vall;
            _vehicleDetails[iidx]["approved_amount"] = temp + vall;
          }

          break;

        default:
          _vehicleDetails[iidx][_k] = vall;
          break;
      }

      _vehicleDetails[iidx]["updated_by_ssc_user_id"] = `${finalUser?.id}`;

      setVehicleDetails(_vehicleDetails);
      calcApprovedVehicle(_vehicleDetails, "y");
    }

    // if(_dataObj?.id){

    for (let _i = 0; _i < updated_vehLogs.length; _i++) {
      if (updated_vehLogs[_i]["id"] == _dataObj?.id) {
        updated_vehLogs[_i] = _dataObj;

        return;
      }
    }

    updated_vehLogs.push(_vehicleDetails[iidx]);
  };

  const addNewTickets = (_idx) => {
    let _travelDetails = [...newticketDetails];

    let itemm = {
      ...newticketDetails[_idx],
    };

    itemm["id"] = "";
    _travelDetails.push(itemm);

    // console.log(`_trav...`,_travelDetails);

    set_newTicketDetails(_travelDetails);
    calcApprovedTicket(_travelDetails);
  };

  const getTraveluserDetailsById = (id) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelById(id).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setTraveluserDetails(response?.data?.dataList?.result);

      getTravelExpenseVehicleSummary(
        response?.data?.dataList?.result[0]?.created_by_id,
        id
      );
      // getVehicleAssignData("", 1, 10, "-id", false, "", response?.data?.dataList?.result[0]?.created_by_id);
    });
  };

  const todaysD = new Date();

  const fDate = moment(todaysD).format("DDMMYYYY");

  const calcApprDa = (daObj) => {
    let da_appr = 0;

    // if (daObj?.bill_data?.length>0){
    //   da_appr= daObj?.gross_total
    // }
    // else{

    //   da_appr = (Number(daObj?.entitlement) * Number(daObj?.da_percent)) / 100;

    // }
    da_appr = daObj?.gross_total;

    return da_appr;
  };

  const getTravelDetailsById = (id) => {
    TravelListingAPI.trav_detail(id)
      .then((resp) => {
        resp?.data?.dataList?.ssc_data.forEach((itemm, idx) => {
          if (itemm.current_status == "Documents Received")
            set_pickItemId(itemm.id);
          approvedSendBack_payload["pick_list_id"].push(`${itemm.id}`);
        });

        let dadata = resp?.data?.dataList?.da_expense.map((data) => {
          data.appr_da_percent =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_da_percent == null
              ? data?.da_percent
                ? parseInt(data?.da_percent)
                : 0
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_da_percent
              : data?.da_percent
              ? parseInt(data?.da_percent)
              : 0;

          data.appr_da_amount =
          resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_da_amount == null ? data?.gross_total
            :
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_da_amount
              : calcApprDa(data);

          data.appr_da_remarks =
          resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_da_remarks == null ?data?.remarks
            :
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_da_remarks
              : data?.remarks;
          return data;
        });
        let timeCalcN = dadata?.map((dd, i) => {
          let tt = dd?.hours;

          dd.dropDownKey = [
            { value: 0, label: "No DA Claim", isDisable: false },
          ];

          dd.SelectedKey = [
            {
              value: dd?.appr_da_percent,
              label: dd?.remarks,
              isDisable: false,
            },
          ];

          if (tt > 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 20,
                label: "All Food Provided by the company",
                isDisable: false,
              },
              {
                value: 40,
                label: "Lunch and Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 50,
                label: "Breakfast and Lunch/Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 70,
                label: "Lunch/Dinner provided by the company",
                isDisable: false,
              },
              {
                value: 80,
                label: "Breakfast provided by the company",
                isDisable: false,
              },
              {
                value: 100,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          } else if (tt > 8 && tt <= 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 50,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          } else if (tt > 4 && tt <= 8) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim", isDisable: false },
              {
                value: 30,
                label: "All food expenses paid by the employee",
                isDisable: false,
              },
            ];
          }
          let foundSelectedEle = false;

          if (!dd.da_percent) {
            dd.dropDownKey.forEach((_item, index) => {
              console.log(
                `detail..comparing id ${dd?.id} ${dd?.da_date} ${dd?.remarks} with ${_item.label}`
              );
              if (foundSelectedEle) _item.isDisable = true;
              if (
                _item.label.toLowerCase() == dd?.appr_da_remarks?.toLowerCase()
              ) {
                foundSelectedEle = true;
                dd.da_percent = _item.value;
                dd.appr_da_percent = _item.value;
                dd.appr_da_amount =
                  resp?.data?.dataList?.approval_reasons?.length > 0
                    ? dd.appr_da_amount
                    : calcApprDa(dd);
                dd.SelectedKey = [
                  {
                    value: _item.value,
                    label: _item.label,
                    isDisable: false,
                  },
                ];

                // return false
              }
            });
          }

          // else if (tt > 4) {
          //   dd.dropDownKey = [
          //     { value: 0, label: "No DA Claim" },
          //     {
          //       value: 30,
          //       label: "All food expenses paid by the employee",
          //     },
          //   ];
          // }
          return dd;
        });

        // if (dadata.length != 0) {
        //   timeCalcN.map((x, index) => {
        //     let data = {
        //       value: giveRemarkValue(x?.dropDownKey, x?.remarks),
        //       label:
        //         x?.remarks == null
        //           ? "All food expenses paid by the employee"
        //           : x?.remarks,
        //     };
        //     x.SelectedKey = data;
        //     // x.entitlement =
        //     //   x.gross_total == 0 && x?.remarks !== "No DA Claim"
        //     //     ? x.entitlement
        //     //     : x.gross_total;
        //     x.amt = calculateTotalAmt2(data, x.entitlement);
        //     console.log("jai7", calculateTotalAmt2(data, index));
        //   });
        //   setDaDataList(timeCalcN);
        // }

        // let totalticketvalue=0,totalticketvalue2=0
        let totalHotelValue = 0,
          totalHotelValue2 = 0;
        let totalDaValue = 0,
          totalDaValue2 = 0;
        let totalVehLogValue = 0,
          totalVehLogValue2 = 0;
        // let totalConveValue=0,totalConveValue2=0

        setTravelDetails(resp?.data?.dataList);

        // modifyFinalSumDetails("",totalTicketValue,"final_claim")

        let newtiks = resp?.data?.dataList?.ticket_expense.map((data) => {
          if (data.tax_bill_amount == null) {
            data.tax_bill_amount =
              data?.taxable_amount + data?.other_charges + data?.tax_amount;
          }
          data.appr_other_charges =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_other_charges == null
              ? data.other_charges
                ? data.other_charges
                : 0
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_other_charges
              : data.other_charges
              ? data.other_charges
              : 0;
          data.appr_tax_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_tax_amount == null
              ? data.tax_amount
                ? data.tax_amount
                : 0
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_tax_amount
              : data.tax_amount
              ? data.tax_amount
              : 0;
          data.appr_tax_rate =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_tax_rate == null
              ? data.tax_rate_id
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_tax_rate
              : data.tax_rate_id;
          data.appr_taxable_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            data.appr_taxable_amount == null
              ? data.taxable_amount
              : resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_taxable_amount
              : data.taxable_amount;
          data.appr_total_bill_amount = data.appr_total_bill_amount
            ? data.appr_total_bill_amount
            : data.tax_bill_amount;
          updated_tickets.push(data);
          return data;
        });

        let _reason = [...approvedSendBack_payload.reason];

        resp?.data?.dataList?.approval_reasons?.map((data) => {
          // console.log(`check..`);

          for (let i = 0; i < approvedSendBack_payload.reason.length; i++) {
            if (
              approvedSendBack_payload.reason[i].expense_type ==
              data.expense_type
            ) {
              _reason[i].reason = data.reason;
              // console.log(`check..found`);
            }
          }
        });

        // console.log(`after..`,_reason);
        // console.log(`after2..`,_reason);
        set_approvedSendBack_payload((prev) => ({ ...prev, reason: _reason }));

        set_newTicketDetails(newtiks);
        set_orignewTicketDetails(newtiks);

        calcApprovedTicket(newtiks, true);
        let usdData = [{ id: "", name: "", amount: 0 }];

        if (resp?.data?.dataList?.request_type != "International") {
          approvedSendBack_payload["reason"].splice(5);
        } else {
          let data = resp?.data?.dataList?.currency_data;
          // ? JSON.parse(resp?.data?.dataList?.currency_data.replace(/'/g, '"'))
          // : "";
          if (data?.length > 0) {
            let tempCurrencyList = [];
            data.forEach((obj) => {
              // let temp = Object.entries(obj)[0];
              // if (temp[0] === "USD") usdData = obj.USD == "" ? 0 : parseInt(obj.USD);
              // if (temp[1] !== "") tempCurrencyList.push(obj);

              obj.amount = parseFloat(obj?.amount ? obj?.amount : obj?.name==='INR' ?  1 : 0);
            });
            // setFExp(usdData);
            usdData = data;
            setCurrencyList(data);
            console.log(`setfexp`, usdData, fExp);
          }
        }

        // resp?.data?.dataList?.hotel_expense?.map((data)=>{
        //   totalHotelValue+=data?.total_claim_amount
        //   totalHotelValue2+=data?.total_claim_amount

        // })

        // setTotalHotelValue(totalHotelValue)
        // setTotalHotelValue2(totalHotelValue2)
        let hotelData = resp?.data?.dataList?.hotel_expense.map((data) => {
          if (!data?.tax_amount) {
            data.tax_amount = 0.0;
          }
          data.appr_tax_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_tax_amount
              : data.tax_amount;

          data.appr_bill_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data.appr_bill_amount
              : data.taxable_amount;

          data.appr_claim_amount =
            resp?.data?.dataList?.approval_reasons?.length == 0
              ? data?.claim_amount
              : data?.appr_claim_amount;

          // data.appr_amount =
          // resp?.data?.dataList?.approval_reasons?.length==0
          //     ? data.total_claim_amount
          //     : data.appr_claim_amount + data.appr_tax_amount;

          data.appr_amount =
            // resp?.data?.dataList?.approval_reasons?.length>0 && !data.appr_amount ? data.total_claim_amount :
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data?.appr_amount === null
                ? data.total_claim_amount
                : data.appr_amount
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data.total_claim_amount
              : data.appr_claim_amount + data.appr_tax_amount;

          data.appr_tax_rate_id =
            resp?.data?.dataList?.approval_reasons?.length > 0 &&
            !data.appr_tax_rate_id
              ? data.tax_rate_id
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data?.tax_rate_id
              : data?.appr_tax_rate_id;

          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          // data.appr_amount = data.appr_amount !== null ? data.total_claim_amount : data.appr_amount;
          updated_hotels.push(data);
          return data;
        });
        setHotelDetails(hotelData);
        calcApprovedHotel(hotelData);

        let conveyanc = resp?.data?.dataList?.conveyance_expense.map((data) => {
          data.approved_amount =
            resp?.data?.dataList?.approval_reasons?.length > 0
              ? data?.approved_amount === null
                ? data.amount
                : data.approved_amount
              : resp?.data?.dataList?.approval_reasons?.length == 0
              ? data.amount
              : data?.approved_amount;
          updated_conveyance.push(data);
          return data;
        });

        setConveyanceDetails(conveyanc);
        calcApprovedConveyance(conveyanc);

        let vehicledetaills = resp?.data?.dataList?.vehicle_expense.map(
          (data) => {
            data.washing_amount =
              data.washing_amount != null ? data.washing_amount : 0;

            data.appr_fuel_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_fuel_amount == null
                ? data.fuel_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_fuel_amount
                : data.fuel_amount;
            data.appr_driver_daily_wages =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_driver_daily_wages == null
                ? data.driver_daily_wages
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_driver_daily_wages
                : data.driver_daily_wages;
            data.appr_driver_da_value =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_driver_da_value == null
                ? data.driver_da_value
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_driver_da_value
                : data.driver_da_value;
            data.appr_other_expenses =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_other_expenses == null
                ? data.other_expenses
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_other_expenses
                : data.other_expenses;
            data.appr_washing_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.appr_washing_amount == null
                ? data.washing_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.appr_washing_amount
                : data.washing_amount;
            data.approved_amount =
              resp?.data?.dataList?.approval_reasons?.length > 0 &&
              data.approved_amount == null
                ? data.total_amount
                : resp?.data?.dataList?.approval_reasons?.length > 0
                ? data.approved_amount
                : data.total_amount;

            updated_vehLogs.push(data);
            return data;
          }
        );
        setVehicleDetails(vehicledetaills);
        calcApprovedVehicle(vehicledetaills);
        // setDADetails(resp?.data?.dataList?.da_expense)

        setDADetails(timeCalcN);
        calcApprovedDA(timeCalcN);
        // console.log(`...=>`,timeCalcN);

        let foregHotel = resp?.data?.dataList?.foreign_expense_hotel.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              : 0.0;
            data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;

            return data;
          }
        );
        let foregFood = resp?.data?.dataList?.foreign_food_expense.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              : 0.0;
            data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;

            return data;
          }
        );
        let foregDA = resp?.data?.dataList?.foreign_expense_da.map((data) => {
          data.currency_name = !data?.currency_name
            ? data.ent_currency_name
            : data.currency_name;
          data.appr_amount =
            data?.appr_amount == null ? data.amount_in_forex : data.appr_amount;
          data.appr_exchange_rate = usdData[
            usdData?.findIndex((obj) => obj?.name === data?.currency_name)
          ]?.amount
            ? usdData[
                usdData?.findIndex((obj) => obj?.name === data?.currency_name)
              ]?.amount
            : 0.0;
          data.amount_in_inr = data.appr_exchange_rate * data.amount_in_forex;
          data.ent_amount_in_inr =
            data?.ent_amount_in_forex * data.appr_exchange_rate;

          return data;
        });
        let foregConv = resp?.data?.dataList?.foreign_expense_conveyance.map(
          (data) => {
            data.appr_amount =
              data?.appr_amount == null
                ? data.amount_in_forex
                : data.appr_amount;
            data.appr_exchange_rate = usdData[
              usdData?.findIndex((obj) => obj?.name === data?.currency_name)
            ]?.amount
              ? usdData[
                  usdData?.findIndex((obj) => obj?.name === data?.currency_name)
                ]?.amount
              :  0.0;
            data.amount_in_inr = data?.currency_name==='INR' ? 1 * data.amount_in_forex : data.appr_exchange_rate * data.amount_in_forex;

            return data;
          }
        );

        setForeignDetails({
          ...foreignDetails,
          hotelDetail: foregHotel,
          foodDetail: foregFood,
          inciDaDetail: foregDA,
          conveyDetail: foregConv,
        });

        calcApprovedForegHotel(foregHotel, true);
        calcApprovedForegFood(foregFood, true);
        calcApprovedForegDA(foregDA, true);
        calcApprovedForegConvey(foregConv, true);
      })
      .catch((error) => {
        console.log(`detial`, error);
        toast.error("Error!", {
          autoClose: 2000,
        });
      });
  };

  const getConveyenceList = (id) => {
    TravelListingAPI.getConveyenceList(id).then((response) => {
      setTotalConveValue(response?.data?.dataList?.final_amount);

      setConveyanceDetails(response?.data?.dataList?.result);
    });
  };

  const getTicketExpense = (id, user_id) => {
    TravelListingAPI.getTicketExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.tax_bill_amount;
      });
      setTotalTicketValue(value);
      setTicketDetails(response?.data?.dataList?.result);
    });
  };

  const getVehicleExpense = (id, user_id) => {
    TravelListingAPI.getVehicleExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.total_amount;
      });
      setTotalVehLogValue(value);
      setVehicleDetails(response?.data?.dataList?.result);
    });
  };

  const getHotelExpense = (id, user_id) => {
    TravelListingAPI.getHotelExpense(id, user_id).then((response) => {
      let value = 0;
      response?.data?.dataList?.result.map((obj) => {
        value += obj?.total_claim_amount;
      });
      setTotalHotelValue(value);
      setHotelDetails(response?.data?.dataList?.result);
    });
  };

  const getDAExpense = (id, user_id) => {
    TravelListingAPI.getDAExpense(id, user_id).then((response) => {
      let value = 0;
      // response?.data?.dataList?.result.map((obj) => {
      //   value += obj?.gross_total;
      // });
      setTotalDaValue(response?.data?.dataList?.final_gross_total);
      setDADetails(response?.data?.dataList?.result);
    });
  };

  // useEffect(() => {
  //   console.log(allApproved, "MADHAV")
  // }, [allApproved])

  const calculateDelayDeductionPercent = (calcDaya) => {
    let userr = traveluserDetails[0]?.other_details;

    let claimDaysFromJoining = moment(travelDetails?.dispatch_date).diff(
      moment(userr?.date_of_joining, "YYYY-MM-DD"),
      "days"
    );
    let days = calcDaya + " Days";
    let deductedPercent = 100;
    console.log(`....`, days, claimDaysFromJoining);

    if (claimDaysFromJoining < 61) {
      days = "0 Days";
    }
    if (
      ["D1", "D2", "C1"].includes(userr?.emp_sub_group) ||
      userr?.sales_dist == "CONS"
    ) {
      days = "0 Days";
    }
    days = days.split(" ");
    if (days[0] <= 7) {
      deductedPercent = 0;
    } else if (days[0] >= 8 && days[0] <= 20) {
      deductedPercent = 5;
    }
    console.log(`ded per`, deductedPercent);
    return deductedPercent;
  };

  const editDaBillModalClose = () => {
    setDaBillDetail([]);
    setEditDaDetailModal({
      flag: false,
      id: null,
      isView: false,
      daIdx: null,
      travel_id: null,
    });
  };

  const addDaBill = () => {
    let temp = JSON.parse(JSON.stringify(daBillDetail));
    temp.push({
      id: temp.length ? temp[temp.length - 1].id + 1 : 1,
      bill_date: "",
      invoice_number: "",
      bill_amount: "",
      created_at: null,
    });
    setDaBillDetail(temp);
  };

  const removeDaBill = (id) => {
    let temp = daBillDetail.filter((obj) => obj.id !== id);
    setDaBillDetail(temp);
  };

  const daBillHandleChange = (value, name, index) => {
    let temp = JSON.parse(JSON.stringify(daBillDetail));
    switch (name) {
      case "bill_date":
        temp[index].bill_date = moment(value).format("YYYY-MM-DD");
        break;
      case "bill_amount":
        if (index >= daDetails[editDaDetailModal.daIdx].bill_data.length) {
          temp[index][name] = value;
        } else
          temp[index][name] =
            Number(value) <=
            daDetails[editDaDetailModal.daIdx].bill_data[index].bill_amount
              ? value
              : daDetails[editDaDetailModal.daIdx].bill_data[index].bill_amount;
        break;
      default:
        temp[index][name] = value;
    }
    setDaBillDetail(temp);
  };

  const updateDaBillDetail = () => {
    dispatch(setDisplayLoader("Display"));
    let temp = JSON.parse(JSON.stringify(daDetails));
    let total_amt = 0;
    daBillDetail.forEach((obj) => {
      total_amt += Number(obj.bill_amount);
    });
    temp[editDaDetailModal.daIdx].bill_data = daBillDetail;
    temp[editDaDetailModal.daIdx].appr_da_amount = total_amt;
    // temp[editDaDetailModal.daIdx].gross_total = total_amt;
    let total_da_amt = 0;
    temp.forEach((obj) => (total_da_amt += obj.gross_total));

    // console.log(total_amt,total_da_amt);return

    const payload = {
      id: editDaDetailModal.id,
      bill_data: daBillDetail,
      gross_total: total_amt,
    };
    TravelListingAPI.updateDaBillDetail(
      payload,
      editDaDetailModal.travel_id
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      temp[editDaDetailModal.daIdx].bill_data.forEach((obj) => {
        if (obj.created_at == null) {
          obj.created_at = new Date();
          obj.da_id = 1;
        }
      });
      // set_allApproved({
      //   ...allApproved,
      //   // totalDaValue: total_da_amt,
      //   totalDaValue2: total_da_amt,
      // });
      setDADetails(temp);
      calcApprovedDA(temp, "y");
      editDaBillModalClose();
    });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <div class="defaultchatgptquest text-right">
                      <Link
                        to={
                          userGroup._userGroup == "ssc user"
                            ? "/travel-expense"
                            : "/travel-approve-expense"
                        }
                        class="badge badge-secondary"
                      >
                        <i class="fas fa-arrow-circle-left"></i> Back
                      </Link>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h3 class="policyhead">Check Expenses</h3>
                  </div>
                </div>
                {traveluserDetails?.map((data) => {
                  return (
                    <div class="row viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Emp No</label>
                          <p>{data?.emp_code}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Name</label>
                          <p>{data?.user_name}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Grade</label>
                          <p>{data?.other_details?.emp_sub_group}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Head Quarters</label>
                          <p>{data?.other_details?.location}</p>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group innergroup">
                          <label>Intimation Number</label>
                          <p>
                            {data?.travel_intimation_number
                              ? data?.travel_intimation_number
                              : `PITI${fDate}${data?.emp_code}${data?.created_by_id}`}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Deputation</label>
                          <p className="text-danger">
                            {" "}
                            {traveluserDetails[0]?.action_code === "10"
                              ? "Yes"
                              : "No" ?? "-"}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Vendor Code</label>
                          <p>{data?.vendor_code}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>SAP Trip No.</label>
                          <p>{data?.sap_trip_number}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Tour Applied on</label>
                          <p>
                            {moment
                              .parseZone(data?.created_at)
                              .format("DD-MM-YYYY HH:mm ")}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Tour Edit on</label>
                          <p>
                            {data?.tour_edit_on
                              ? moment
                                  .parseZone(data?.tour_edit_on)
                                  .format("DD-MM-YYYY HH:mm ")
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Approved on</label>
                          <p>
                            {data?.approval_date &&
                              moment
                                .parseZone(data?.approval_date)
                                .format("DD-MM-YYYY HH:mm ")}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Zone</label>
                          <p>{data?.other_details?.sales_dist}</p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>From Location</label>
                          <p>
                            {travelDetails?.travel_detail?.length > 0 &&
                              (travelDetails?.travel_detail[0]
                                ?.from_city_name === "Other"
                                ? travelDetails?.travel_detail[0]
                                    ?.from_city_input
                                : travelDetails?.travel_detail[0]
                                    ?.from_city_name)}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Departure Date</label>
                          <p>
                            {/* {moment(
                              data?.travel_detail_data[0]?.from_date
                            ).format("DD-MM-YYYY")} */}
                            {travelDetails?.travel_detail.length > 0 &&
                              moment(
                                travelDetails?.travel_detail[0]?.from_date +
                                  " " +
                                  travelDetails?.travel_detail[0]?.from_time
                              ).format("DD-MM-YYYY hh:mm ")}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>To Location</label>
                          <p>
                            {data?.travel_detail_data[0]?.to_city_name ===
                            "Other"
                              ? data?.travel_detail_data[0]?.to_city_input
                              : data?.travel_detail_data[0]?.to_city_name}
                            {/* {data?.travel_detail_data[0]?.to_city_name} */}
                            {/* {travelDetails?.travel_detail?.length > 0 &&
                              (travelDetails?.travel_detail[
                                travelDetails?.travel_detail?.length - 1
                              ]?.from_city_name === "Other"
                                ? travelDetails?.travel_detail[
                                    travelDetails?.travel_detail?.length - 1
                                  ]?.from_city_input
                                : travelDetails?.travel_detail[
                                    travelDetails?.travel_detail?.length - 1
                                  ]?.from_city_name)} */}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Arrival Date</label>
                          <p>
                            {/* {moment(
                              data?.travel_detail_data[data?.travel_detail_data.length-1].to_date
                            ).format("DD-MM-YYYY")} */}

                            {travelDetails?.travel_detail.length > 0 &&
                              moment(
                                travelDetails?.travel_detail[
                                  travelDetails?.travel_detail.length - 1
                                ]?.to_date +
                                  " " +
                                  travelDetails?.travel_detail[
                                    travelDetails?.travel_detail.length - 1
                                  ]?.to_time
                              ).format("DD-MM-YYYY HH:mm")}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Days</label>
                          <p>
                            {
                              // parseInt(
                              //   ((new Date(data?.travel_detail_data[1]?.to_date))
                              //     - (new Date(data?.travel_detail_data[0]?.from_date)))
                              //   / (1000 * 60 * 60 * 24), 10
                              // ) + 1

                              travelDetails?.travel_detail.length > 0 &&
                                moment(
                                  travelDetails?.travel_detail[
                                    travelDetails?.travel_detail.length - 1
                                  ]?.to_date
                                ).diff(
                                  moment(
                                    travelDetails?.travel_detail[0]?.from_date,
                                    "YYYY-MM-DD"
                                  ),
                                  "days"
                                ) + 1
                            }
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Territory</label>
                          <p class="text-danger">
                            {data?.territory_name ?? "No"}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Date of Joining</label>
                          <p>
                            {moment(
                              data?.other_details?.date_of_joining
                            ).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Employee Status</label>
                          <p>Active</p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Reporting Person</label>
                          <p>{data?.reporting_person}</p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Exception Approved By</label>
                          <p>
                            {data?.expense_exception
                              ? `${
                                  data?.expense_approver_remark_data[
                                    data?.expense_approver_remark_data?.length -
                                      1
                                  ]?.approver_name
                                } (${
                                  data?.expense_approver_remark_data[
                                    data?.expense_approver_remark_data?.length -
                                      1
                                  ]?.approver_username
                                })`
                              : "-"}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Delay Deduction -{" "}
                            {travelDetails?.travel_detail?.length > 0
                              ? moment(travelDetails?.dispatch_date).diff(
                                  moment(
                                    travelDetails?.travel_detail[
                                      travelDetails?.travel_detail.length - 1
                                    ]?.to_date,
                                    "YYYY-MM-DD"
                                  ),
                                  "days"
                                ) + " days"
                              : ""}
                            {/* {
                            travelDetails ? moment(travelDetails?.dispatch_date).diff(moment(travelDetails?.travel_detail[travelDetails?.travel_detail.length - 1]?.to_date, "YYYY-MM-DD"), "days") : ""

                          } Days */}
                          </label>
                          {/* <p class="text-danger">
                            {console.log(`delayy`,travelDetails?.travel_detail)}
                            { travelDetails?.travel_detail?.length > 0 ?  
                                moment(travelDetails?.dispatch_date).diff(moment(travelDetails?.travel_detail[travelDetails?.travel_detail.length-1]?.to_date, "YYYY-MM-DD"), "days") > 7? 300 : 100
                              : 100
                              }
                            </p> */}
                          <p>{`${delayAmt}%`}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Expense Claimed on</label>
                          <p>
                            {moment
                              .parseZone(data?.claim_date)
                              .utc()
                              .format("DD-MM-YYYY HH:mm ")}
                          </p>
                        </div>
                      </div>
                      {/* <div class="col-md-3 viewformaftersubmit">
                        <div class="form-group innergroup">
                          <label>Download Attachments</label>
                          <p>
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i> file.pdf
                            </span>{" "}
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i> file-01.pdf
                            </span>
                          </p>
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </div>

              <div class="mb-2 ml-3">
                <h6 class="text-theme mb-0">
                  <i class="far fa-arrow-alt-circle-right"></i>{" "}
                  <strong>Travel Intimation</strong>
                </h6>
              </div>

              <div class="table-responsive mb-4">
                <table class="table table-striped table-bordered tablecured viewpo">
                  {console.log(`Ticket amount cant be increased....`,allApproved.totalTicketValue,allApproved.changedtotalTicketValueBySSC,(Number.parseFloat(allApproved.totalTicketValue2?.toFixed(2)))  )}
                  <thead>
                    <tr>
                      <th>From</th>
                      <th>Departure Date</th>
                      <th>Departure Time</th>
                      <th>Places Visited</th>
                      <th>To</th>
                      <th>Reaching Date</th>
                      <th>Reaching Time</th>
                      {/* {traveluserDetails[0]?.other_details?.sales_dist && <th>HQ</th>} */}
                      <th>HQ</th>
                      <th>Purpose</th>
                      <th>Mode</th>
                      <th>Colleague Empcode</th>
                      <th>Territory</th>
                      <th>Hotel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {travelDetails?.travel_detail?.length > 0 ? (
                      travelDetails?.travel_detail?.map((data, tr_idx) => {
                        // return res?.travel_detail_data?.map((data, index) => {
                        return (
                          <tr>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.request_type == "International"
                                ? data?.from_continent_name +
                                  `-${data?.from_country_name}-${
                                    data?.from_city_name == "Other"
                                      ? data?.from_city_input
                                      : data?.from_city_name
                                  }`
                                : data?.from_city_name == "Other"
                                ? data?.from_city_input
                                : data?.from_city_name}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {moment(data?.from_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {moment("2023-1-1 " + data?.from_time).format(
                                "HH:mm"
                              )}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.additional_information
                                ? data?.additional_information
                                : "-"}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.request_type == "International"
                                ? data?.to_continent_name +
                                  `-${data?.to_country_name}-${
                                    data?.to_city_name == "Other"
                                      ? data?.to_city_input
                                      : data?.to_city_name
                                  }`
                                : data?.to_city_name == "Other"
                                ? data?.to_city_input
                                : data?.to_city_name}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {moment(data?.to_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {moment("2023-1-1 " + data?.to_time).format(
                                "HH:mm"
                              )}
                            </td>
                            {/* {traveluserDetails[0]?.other_details?.sales_dist && <td>{data?.hq === true ?`YES` : `NO`}</td>} */}
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.hq === true ? `YES` : `NO`}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.purpose}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.mode_name}
                            </td>
                            <td
                              class={
                                data?.reason_for_exception_name
                                  ? `text-danger`
                                  : ""
                              }
                            >
                              {data?.colleague_user_code
                                ? data?.colleague_user_code
                                : "-"}
                            </td>
                            {data?.territory ? (
                              <td
                                class={
                                  data?.reason_for_exception_name
                                    ? `text-danger`
                                    : ""
                                }
                              >
                                Yes
                              </td>
                            ) : (
                              <td
                                class={
                                  data?.reason_for_exception_name
                                    ? `text-danger`
                                    : ""
                                }
                              >
                                No
                              </td>
                            )}
                            {data?.stay_needed ? (
                              <td
                                class={
                                  data?.reason_for_exception_name
                                    ? `text-danger`
                                    : ""
                                }
                              >
                                Yes
                              </td>
                            ) : (
                              <td
                                class={
                                  data?.reason_for_exception_name
                                    ? `text-danger`
                                    : ""
                                }
                              >
                                No
                              </td>
                            )}
                          </tr>
                        );
                        //  });
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>No Records Found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {newticketDetails?.length ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Ticket Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Boarding On</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Paid By</th>
                          <th>Mode</th>
                          <th>Vendor Name</th>
                          <th>Invoice No</th>
                          <th>Invoice Date</th>
                          <th>PNR/Ticket No</th>
                          <th>Airline</th>
                          <th>Ven Type</th>
                          <th>Ven. State</th>
                          <th>Ven. GSTNo</th>
                          <th>Tax %</th>
                          <th>Tax Amt.</th>
                          <th>Base Amt.</th>
                          <th>Other</th>
                          <th>Total Bill Amt.</th>
                          <th>Appr. Base Amt.</th>
                          <th>Appr. Tax %</th>
                          <th>Appr. Tax Amt.</th>
                          <th>Appr. Other Amt.</th>
                          <th>Appr. Amount</th>
                          {!inputsDisabled && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {newticketDetails?.map((data, tidx) => {
                          return (
                            <tr>
                              <td>
                                {data?.travel_date &&
                                  moment
                                    .parseZone(data?.travel_date)
                                    .format("DD-MM-YYYY")}
                              </td>
                              <td>
                                {data?.from_location_name == "Other"
                                  ? data?.from_city_input
                                  : data?.from_location_name}
                              </td>
                              <td>
                                {data?.to_location_name == "Other"
                                  ? data?.to_city_input
                                  : data?.to_location_name}
                              </td>
                              <td>
                                <select
                                  disabled={inputsDisabled}
                                  className={"form-control " + inputStyle.class}
                                  style={{ width: 70 }}
                                  onChange={(e) =>
                                    modifyTicket(
                                      data,
                                      tidx,
                                      "paid_by",
                                      e.target.value
                                    )
                                  }
                                  type="text"
                                >
                                  <option
                                    value="self"
                                    selected={
                                      data?.paid_by?.toLowerCase() == "self"
                                    }
                                  >
                                    Self
                                  </option>
                                  <option
                                    value="company"
                                    selected={
                                      data?.paid_by?.toLowerCase() == "company"
                                    }
                                  >
                                    Company
                                  </option>
                                </select>
                              </td>
                              <td>{data?.modee_name}</td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.vendor_name}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "vendor_name",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                    value={data?.vendor_name}
                                  />
                                )}
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.invoice_no}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "invoice_no",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                    value={data?.invoice_no}
                                  />
                                )}
                              </td>
                              <td>
                                {/* <input disabled={inputsDisabled} onChange={(e) => modifyTicket(data, tidx, "invoice_date", e.target.value)} type="date"
                              //  min={traveluserDetails[0]?.travel_detail_data[0]?.from_date} 
                              max={moment.parseZone(todayDate).format("YYYY-MM-DD")}
                              value={data?.invoice_date} /> */}
                                <div style={{ width: 53 }}>
                                  <DatePicker
                                    // showIcon={true}
                                    disabled={inputsDisabled}
                                    selected={
                                      data?.invoice_date
                                        ? new Date(
                                            moment
                                              .parseZone(data?.invoice_date)
                                              .format("YYYY-MM-DD")
                                          )
                                        : null
                                    }
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "invoice_date",
                                        e
                                      )
                                    }
                                    maxDate={new Date()}
                                    // minDate={
                                    //   new Date(state?.travel_detail_data[0]?.from_date)
                                    // }
                                    placeholderText="DD-MM-YYYY"
                                    class={"form-control " + inputStyle.class}
                                    style={inputStyle.css}
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </div>
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.modee_name === "Air" ||
                                    data?.modee_name === "Train"
                                      ? data?.pnr_no != ""
                                        ? data?.pnr_no
                                        : "-"
                                      : data?.ticket_no != ""
                                      ? data?.ticket_no
                                      : "-"}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={inputStyle.css}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        data?.modee_name === "Air" ||
                                          data?.modee_name === "Train"
                                          ? "pnr_no"
                                          : "ticket_no",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                    value={
                                      data?.modee_name === "Air" ||
                                      data?.modee_name === "Train"
                                        ? data?.pnr_no
                                        : data?.ticket_no
                                    }
                                  />
                                )}
                              </td>
                              <td>
                                {/* {data?.airline_name} */}
                                <select
                                  disabled={
                                    inputsDisabled || data?.modee_name !== "Air"
                                  }
                                  className={"form-control " + inputStyle.class}
                                  style={
                                    inputsDisabled || data?.modee_name !== "Air"
                                      ? {
                                          ...readOnlyColumnStyle.css,
                                          width: 80,
                                        }
                                      : {
                                          ...readOnlyColumnStyle.Inss,
                                          width: 80,
                                        }
                                  }
                                  // onChange={(e)=>modifyTicket(data,tidx,"",e.target.value)}
                                >
                                  <option>select</option>
                                  {airlinesMenu?.map((adata, _item) => (
                                    <option
                                      value={adata?.pi_airline_id}
                                      selected={
                                        adata?.pi_airline_id == data?.airline_id
                                      }
                                    >
                                      {adata?.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  disabled={inputsDisabled}
                                  class={"form-control " + inputStyle.class}
                                  style={{ width: 100 }}
                                  onChange={(e) =>
                                    modifyTicket(
                                      data,
                                      tidx,
                                      "vendor_category",
                                      e.target.value
                                    )
                                  }
                                >
                                  {/* <option value={data?.vendor_category}>
                                {data?.vendor_category}
                              </option> */}
                                  {/* <option value="NR">Non-Registered</option> */}

                                  <option value="">Select</option>
                                  <option
                                    value="Registered"
                                    selected={
                                      data?.vendor_category == "Registered"
                                    }
                                  >
                                    R
                                  </option>
                                  <option
                                    value="Not-Registered"
                                    selected={
                                      data?.vendor_category == "Not-Registered"
                                    }
                                  >
                                    NR
                                  </option>
                                </select>
                              </td>
                              <td>
                                {/* {data?.vendor_gst_state} */}
                                <select
                                  disabled={inputsDisabled}
                                  class={"form-control " + inputStyle.class}
                                  style={{ width: 80 }}
                                  // onChange={(e)=>modifyTicket(data,tidx,"",e.target.value)}
                                  onChange={(e) =>
                                    modifyTicket(
                                      data,
                                      tidx,
                                      "vendor_gst_state",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  {gstState?.map((gdata, _item) => (
                                    <option
                                      value={gdata?.gst_state_code}
                                      selected={
                                        parseInt(data?.vendor_gst_state) ===
                                        parseInt(gdata?.gst_state_code)
                                      }
                                    >
                                      {gdata?.gst_state_code} {gdata?.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    <Tooltip
                                      title={`${
                                        data?.vendor_gst_no
                                          ? data?.vendor_gst_no
                                          : "-"
                                      }`}
                                      position="bottom"
                                    >
                                      {data?.vendor_gst_no
                                        ? data?.vendor_gst_no
                                        : "-"}
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={inputStyle.css}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "vendor_gst_no",
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                    value={data?.vendor_gst_no}
                                  />
                                )}
                              </td>
                              <td>
                                {/* {inputsDisabled ? */}
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {data?.tax_rate_name
                                    ? data?.tax_rate_name
                                    : 0.0}
                                </div>
                                {/* // :                            <input className={inputStyle.class} style={numberInputStyle.taxCss} onChange={(e) => (e.target.value >= 0 && e.target.value <= 100) ? modifyTicket(data, tidx, "tax_rate_name", e.target.value) : {}} type="number" onWheel={e => e.target.blur()} value={data?.tax_rate_name} />} */}
                              </td>
                              <td>{data?.tax_amount?.toFixed(2)}</td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.taxable_amount?.toFixed(2)}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "taxable_amount",
                                        Number(e.target.value)
                                      )
                                    }
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={Number(
                                      data?.taxable_amount
                                    ).toString()}
                                  />
                                )}
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.other_charges
                                      ? data?.other_charges?.toFixed(2)
                                      : "0"}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "other_charges",
                                        e.target.value
                                      )
                                    }
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={Number(
                                      data?.other_charges
                                    ).toString()}
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {data?.tax_bill_amount?.toFixed(2)}
                                </div>

                                {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{data?.tax_bill_amount?.toFixed(2)}</div> :
                            <input className={inputStyle.class} style={numberInputStyle.amountCss} 
                            // onChange={(e) => modifyTicket(data, tidx, "tax_bill_amount", e.target.value)} 
                            type="number" onWheel={e => e.target.blur()} value={data?.tax_bill_amount} />} */}
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.appr_taxable_amount
                                      ? data?.appr_taxable_amount?.toFixed(2)
                                      : 0}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "appr_taxable_amount",
                                        e.target.value
                                      )
                                    }
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={Number(
                                      data?.appr_taxable_amount
                                    ).toString()}
                                  />
                                )}
                              </td>
                              <td>
                                {/* <input  disabled={inputsDisabled} 
                            onChange={(e)=>modifyTicket(data,tidx,"appr_tax_rate",e.target.value)} 
                            type="text" value={data?.appr_tax_rate} /> */}

                                <select
                                  disabled={inputsDisabled}
                                  className={"form-control " + inputStyle.class}
                                  onChange={(e) =>
                                    modifyTicket(
                                      data,
                                      tidx,
                                      "appr_tax_rate",
                                      e.target.value
                                    )
                                  }
                                >
                                  {taxRatesMenu?.map((tadata, _item) => (
                                    <option
                                      value={tadata?.pi_tax_id}
                                      selected={
                                        data?.appr_tax_rate == tadata?.pi_tax_id
                                      }
                                    >
                                      {tadata?.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                {/* <input disabled={inputsDisabled} onChange={(e) => modifyTicket(data, tidx, "appr_tax_amount", e.target.value)} type="text" value={data?.appr_tax_amount} /> */}
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {Number(data?.appr_tax_amount)?.toFixed(2)}
                                </div>
                              </td>
                              <td>
                                {inputsDisabled ? (
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.appr_other_charges
                                      ? data?.appr_other_charges?.toFixed(2)
                                      : "0"}
                                  </div>
                                ) : (
                                  <input
                                    className={inputStyle.class}
                                    style={numberInputStyle.amountCss}
                                    onChange={(e) =>
                                      modifyTicket(
                                        data,
                                        tidx,
                                        "appr_other_charges",
                                        e.target.value
                                      )
                                    }
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    value={Number(
                                      data?.appr_other_charges
                                    ).toString()}
                                  />
                                )}
                              </td>
                              <td>
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {data?.appr_total_bill_amount?.toFixed(2)}
                                </div>
                              </td>
                              {!inputsDisabled && (
                                <td className="d-flex p-2">
                                  <span
                                    onClick={() => addNewTickets(tidx)}
                                    style={{
                                      color: "#0195d4",
                                      cursor: "pointer",
                                      fontSize: "large",
                                    }}
                                  >
                                    <i class="fas fa-plus-square"></i>
                                  </span>
                                  <span
                                    onClick={() => deleteTicketData(tidx)}
                                    className="ml-2"
                                    style={{
                                      color: "#f00",
                                      cursor: "pointer",
                                      fontSize: "large",
                                    }}
                                  >
                                    <i class="fas fa-minus-square"></i>
                                  </span>
                                </td>
                              )}
                            </tr>
                          );
                        })}

                        <tr className="font-weight-bold">
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{ticketSums.taxamt?.toFixed(2)}</td>
                          <td>{ticketSums.billamt?.toFixed(2)}</td>
                          <td>{ticketSums.other?.toFixed(2)}</td>
                          <td>{ticketSums.claimed?.toFixed(2)}</td>
                          <td>{ticketSums.apprtaxable?.toFixed(2)}</td>

                          <td></td>
                          <td>{ticketSums.apprtax?.toFixed(2)}</td>
                          <td>{ticketSums.approther?.toFixed(2)}</td>
                          <td>{ticketSums.appramt?.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          {/* {totalTicketValue} */}
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Applied Amount"
                            value={allApproved.totalTicketValue.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Approved Amount"
                            value={allApproved.totalTicketValue2.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(0, e.target.value)}
                            value={
                              // travelDetails?.approval_reasons?.length>0?

                              //     travelDetails?.approval_reasons[travelDetails?.approval_reasons.findIndex(obj => obj.expense_type === "TICKET")].reason
                              // :
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) => obj.expense_type === "TICKET"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[0]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                          {/* <div>reason is mandatory...</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {hotelDetails?.length ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Hotel Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Check In Date</th>
                          <th>Check Out Date</th>
                          <th>City / Entitlement (Days)</th>
                          <th>Stay At</th>
                          {/* <th>Paid By</th> */}
                          <th>Hotel Name</th>
                          <th>Invoice</th>
                          <th>Bill Date</th>
                          <th>Sharing</th>
                          <th>Ven Type</th>
                          <th>Ven. State</th>
                          <th>Ven. GSTNo</th>
                          <th>Bill Basic Amt.</th>
                          <th>Claim Amt.</th>
                          <th>Tax %</th>
                          <th>Tax Amt.</th>
                          {/* <th>Other Charges</th> */}

                          <th>Total Claim Amt.</th>
                          <th>Appr. Basic Bill Amt.</th>
                          <th>Appr. Claim Amt.</th>
                          <th>Appr. Tax Rate</th>
                          <th>Appr. Tax Amt.</th>
                          {/* <th>Appr. Other Charges</th> */}
                          <th>Approved Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {hotelDetails?.length > 0 ? (
                          hotelDetails?.map((data, hidx) => {
                            return (
                              <tr>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {moment(data?.check_in_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {moment(data?.check_out_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.city_name !== "Other"
                                    ? data?.city_name
                                    : data?.city_input}
                                  -{data?.city_category}-
                                  {data?.city_entitlement}({data?.days})
                                  {/* {data?.city_name}-{data?.city_category} -
                                  {data?.city_entitlement}({data?.days}) */}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.stay_at}
                                </td>
                                {/* <td class={data?.is_exception ? `text-danger` : ""}>{data?.expense_by}</td> */}
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.hotel_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.invoice_no}
                                    </div>
                                  ) : (
                                    <input
                                      disabled={inputsDisabled}
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="text"
                                      value={data?.invoice_no}
                                      onChange={(e) =>
                                        modifyHotel(
                                          data,
                                          hidx,
                                          "invoice_no",
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.stay_at
                                    ?.toLowerCase()
                                    ?.includes("friend")
                                    ? "-"
                                    : data?.invoice_date
                                    ? moment(data?.invoice_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : ""}
                                </td>
                                {data?.sharing === false ? (
                                  <td
                                    class={
                                      data?.is_exception ? `text-danger` : ""
                                    }
                                  >
                                    No
                                  </td>
                                ) : (
                                  <td
                                    class={
                                      data?.is_exception ? `text-danger` : ""
                                    }
                                  >
                                    {data?.person_one_username}
                                    {data?.person_two_username
                                      ? `, ${data?.person_two_username}`
                                      : ""}
                                  </td>
                                )}
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.hotel_vendor_category?.toLowerCase() ==
                                  "registered"
                                    ? "R"
                                    : data?.hotel_vendor_category?.toLowerCase() ==
                                      "not-registered"
                                    ? "NR"
                                    : ""}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.hotel_state_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      <Tooltip
                                        title={`${
                                          data?.hotel_gst_no
                                            ? data?.hotel_gst_no
                                            : "-"
                                        }`}
                                        position="bottom"
                                      >
                                        {data?.hotel_gst_no
                                          ? data?.hotel_gst_no
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <input
                                      disabled={inputsDisabled}
                                      className={inputStyle.class}
                                      style={inputStyle.css}
                                      type="text"
                                      value={data?.hotel_gst_no}
                                      onChange={(e) =>
                                        modifyHotel(
                                          data,
                                          hidx,
                                          "hotel_gst_no",
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.taxable_amount?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.claim_amount?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.tax_rate_value}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.tax_amount?.toFixed(2)}
                                </td>
                                {/* <td class={data?.is_exception ? `text-danger`:""}>{data?.other_charges}</td> */}
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.total_claim_amount?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_bill_amount?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      disabled={inputsDisabled}
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={Number(
                                        data?.appr_bill_amount
                                      ).toString()}
                                      onChange={(e) =>
                                        modifyHotel(
                                          data,
                                          hidx,
                                          "appr_bill_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_claim_amount?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      disabled={inputsDisabled}
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      value={Number(
                                        data?.appr_claim_amount
                                      ).toString()}
                                      onChange={(e) =>
                                        modifyHotel(
                                          data,
                                          hidx,
                                          "appr_claim_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {/* <input  disabled={inputsDisabled}
                                type="text"
                                value={data?.appr_tax_rate}
                                onChange={(e)=>modifyHotel(data,hidx,"appr_tax_rate",e.target.value)}
                              /> */}
                                  <select
                                    disabled={inputsDisabled}
                                    className={
                                      "form-control " + inputStyle.class
                                    }
                                    onChange={(e) =>
                                      modifyHotel(
                                        data,
                                        hidx,
                                        "appr_tax_rate",
                                        e.target.value
                                      )
                                    }
                                  >
                                    {taxRatesMenu?.map((tadata, _item) => (
                                      <option
                                        value={tadata?.id}
                                        selected={
                                          data?.appr_tax_rate_id == tadata?.id
                                        }
                                      >
                                        {tadata?.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.appr_tax_amount
                                      ? data?.appr_tax_amount?.toFixed(2)
                                      : "0"}
                                  </div>
                                </td>
                                {/* <td class={data?.is_exception ? `text-danger`:""}>
                              {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{data?.appr_other_charges?.toFixed(2)}</div> : 
                              <input  disabled={inputsDisabled}
                                className={inputStyle.class} style={numberInputStyle.amountCss} 
                                type="text"
                                value={data?.appr_other_charges}
                                onChange={(e)=>modifyHotel(data,hidx,"appr_other_charges",e.target.value)}
                              />}
                            </td> */}
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  <div
                                    className={inputStyle.class}
                                    style={readOnlyColumnStyle.css}
                                  >
                                    {data?.appr_amount?.toFixed(2)}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            {/* <td colSpan={21}>No Records Found...</td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Applied Amount"
                            value={allApproved.totalHotelValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Approved Amount"
                            value={allApproved.totalHotelValue2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(1, e.target.value)}
                            value={
                              // travelDetails?.approval_reasons?.length>0?

                              //     travelDetails?.approval_reasons[travelDetails?.approval_reasons.findIndex(obj => obj.expense_type === "HOTEL")].reason

                              // :
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) => obj.expense_type === "HOTEL"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[1]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {daDetails?.length ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>DA Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Hours-Claim</th>
                          <th>City/Entitlement</th>
                          <th>Total Claim Amt</th>
                          <th>DA</th>
                          <th>DA %</th>
                          <th>Places</th>
                          <th>Day/Km</th>
                          <th>Day/Leave</th>
                          {/* <th>Appr DA %</th> */}

                          <th>Approved DA</th>
                          <th>Total Appr. Amt.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {daDetails?.length > 0 ? (
                          daDetails?.map((data, daidx) => {
                            return (
                              <tr>
                                {console.log(`dadetails`, data)}
                                <td>
                                  {moment(data?.da_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{data?.hours?.toFixed(2)}</td>
                                <td>
                                  {data?.city_name !== "Other"
                                    ? data?.city_name
                                    : data?.city_input}
                                  -{data?.category}-
                                  {data?.entitlement?.toFixed(2)}
                                  {/* {data?.city_name}-{data?.category}-
                                  {data?.entitlement?.toFixed(2)} */}
                                </td>
                                <td>{data?.gross_total?.toFixed(2)}</td>
                                <td class="mx-100">
                                  {traveluserDetails[0]?.other_details?.emp_sub_group.startsWith(
                                    "C1"
                                  ) ||
                                  traveluserDetails[0]?.other_details?.emp_sub_group.startsWith(
                                    "D"
                                  ) ? (
                                    <button
                                      class="btn btn-secondary  btn-block bpi-main px-3 py-1 f-14"
                                      style={{ marginLeft: "3px" }}
                                      onClick={() => {
                                        setDaBillDetail(data.bill_data);
                                        setEditDaDetailModal({
                                          flag: true,
                                          id: data.id,
                                          isView: true,
                                          daIdx: daidx,
                                          travel_id: data.travel_id,
                                        });
                                      }}
                                      data-toggle="modal"
                                      data-target="#exampleModal"
                                    >
                                      View Bill
                                    </button>
                                  ) : (
                                    <Tooltip
                                      title={`${data?.remarks}`}
                                      position="bottom"
                                    >
                                      {data?.remarks}
                                    </Tooltip>
                                  )}
                                </td>
                                <td>
                                  {data?.remarks == "Headquarter DA"
                                    ? ""
                                    : data?.da_percent}
                                </td>
                                <td>{data?.places}</td>
                                <td>
                                  {data?.daykm ? data?.daykm?.toFixed(2) : ""}
                                </td>
                                <td className="text-danger">
                                  {data?.purpose
                                    ?.toLowerCase()
                                    ?.includes("leave")
                                    ? "Leave"
                                    : data?.purpose
                                        ?.toLowerCase()
                                        ?.includes("holiday")
                                    ? weekdays[
                                        new Date(data?.da_date).getDay()
                                      ] == "Sunday"
                                      ? "Sunday"
                                      : "Holiday"
                                    : ""}
                                </td>

                                <td
                                  class="mw-100"
                                  style={{ overflow: "visible" }}
                                >
                                  {/* <select disabled={inputsDisabled} class="form-control" onChange={(e)=>modifyDA(data,daidx,"remarks",e.target.value)}>

                                <option value="No DA Claim" selected={data?.remarks?.toLowerCase()=="No DA Claim".toLowerCase()}>
                                  No DA Claim (0%)
                                </option>
                                <option value="All food expenses paid by the employee" selected={data?.remarks?.toLowerCase()=="All food expenses paid by the employee".toLowerCase()}>
                                  All food expenses paid by the employee (30%)
                                </option>
                                
                              </select> */}

                                  {traveluserDetails[0]?.other_details?.emp_sub_group.startsWith(
                                    "C1"
                                  ) ||
                                  traveluserDetails[0]?.other_details?.emp_sub_group.startsWith(
                                    "D"
                                  ) ? (
                                    userGroup._userGroup == "ssc manager" ||
                                    userGroup._userGroup == "ssc head" ? (
                                      "Change Bill"
                                    ) : (
                                      <button
                                        class="btn btn-primary btn-block bpi-main px-3 py-1 f-14"
                                        style={{ marginLeft: "3px" }}
                                        onClick={() => {
                                          setDaBillDetail(data.bill_data);
                                          setEditDaDetailModal({
                                            flag: true,
                                            id: data.id,
                                            isView: false,
                                            daIdx: daidx,
                                            travel_id: data.travel_id,
                                          });
                                        }}
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                      >
                                        CHANGE BILL
                                      </button>
                                    )
                                  ) : (
                                    <div
                                      style={{
                                        width: "230px",
                                        overflow: "visible",
                                      }}
                                    >
                                      {/* <Select isDisabled={inputsDisabled}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 999999 }) }}


                                    options={data?.dropDownKey}
                                    // className="basic-multi-select"
                                    // name="remarks"
                                    // onChange={(e) => {
                                    //   // calculateTotalAmt(e, i);
                                    // }}
                                    // id={"stay_at" + i}
                                    onChange={(e) => modifyDA(data, daidx, "appr_da_remarks", e)}
                                    isOptionDisabled={(option)=> option.isDisable}
                                    value={data?.SelectedKey}
                                  /> */}

                                      {data?.remarks == "Headquarter DA" ? (
                                        data?.appr_da_remarks
                                      ) : (
                                        <select
                                          disabled={inputsDisabled}
                                          id={`select` + daidx}
                                          className={
                                            focusedSelect === `select` + daidx
                                              ? "form-control d-flex  p-1 border border-danger rounded"
                                              : "form-control " +
                                                inputStyle.class
                                          }
                                          // id={`select`+daidx}
                                          // // style={focusedSelect === `select`+daidx ? focusStyle : {}}
                                          onFocus={() =>
                                            setFocusedSelect(`select` + daidx)
                                          }
                                          onBlur={() => setFocusedSelect(null)}
                                          onChange={(e) =>
                                            modifyDA(
                                              data,
                                              daidx,
                                              "appr_da_remarks",
                                              e.target.value
                                            )
                                          }
                                        >
                                          {data?.dropDownKey?.map((opt) => (
                                            <option
                                              value={JSON.stringify(opt)}
                                              selected={
                                                opt?.value ==
                                                data?.SelectedKey[0].value
                                              }
                                              disabled={opt.isDisable?"disabled":""}
                                            >
                                              {`${opt?.label}(${opt?.value})`}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {data?.SelectedKey[0]?.amt >= 0
                                    ? data?.SelectedKey[0]?.amt?.toFixed(2)
                                    : data?.appr_da_amount?.toFixed(2)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={12}>No Records Found...</td>
                          </tr>
                        )}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>
                              {allApproved.totalDaValue?.toFixed(2)}
                            </strong>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <strong>
                              {allApproved.totalDaValue2?.toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Applied Amount"
                            value={allApproved.totalDaValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            placeholder="Enter Approved Amount"
                            value={allApproved.totalDaValue2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(2, e.target.value)}
                            value={
                              // travelDetails?.approval_reasons?.length>0?

                              //     travelDetails?.approval_reasons[travelDetails?.approval_reasons.findIndex(obj => obj.expense_type === "DA")].reason

                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) => obj.expense_type === "DA"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[2]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {vehicleDetails?.length ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>
                        Vehicle Log Details Vehicle No:-{" "}
                        {traveluserDetails[0]?.vehicle_no
                          ? traveluserDetails[0].vehicle_no
                          : ""}
                      </strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Starting Kms</th>
                          <th>Closing Kms</th>
                          <th>Total Kms</th>
                          <th>Fuel Qty</th>
                          <th>Fuel Amount</th>
                          <th>Appr. Fuel Amt.</th>
                          <th>Driver Daily Wages</th>
                          <th>Appr. Wages</th>
                          <th>Driver DA</th>
                          <th>Driver DA Val</th>
                          <th>Appr. Drv. DA Val</th>
                          <th>Other</th>
                          <th>Appr. Other</th>
                          <th>Washing</th>
                          <th>Appr. Washing</th>
                          <th>Travel With</th>
                          <th>Partner Name</th>
                          <th>Vehicle Type</th>
                          <th>Total</th>
                          <th>Total Appr. Amt.</th>
                          <th>Exception Reason</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <tr>
                      <td>01-11-2022</td>
                      <td>500</td>
                      <td>550</td>
                      <td>50</td>
                      <td>0.00</td>
                      <td>175.00</td>
                      <td>
                        <input  disabled={inputsDisabled}
                          class="form-control"
                          type="number" onWheel={e => e.target.blur()}
                          value="175.00"
                          placeholder="Enter Amt"
                        />
                      </td>
                      <td>0.00</td>
                      <td>
                        <input  disabled={inputsDisabled}
                          class="form-control"
                          type="number" onWheel={e => e.target.blur()}
                          value="0.00"
                          placeholder="Enter Amt"
                        />
                      </td>
                      <td>Localzero</td>
                      <td>0.00</td>
                      <td>
                        <input  disabled={inputsDisabled}
                          class="form-control"
                          type="number" onWheel={e => e.target.blur()}
                          value="0.00"
                          placeholder="Enter Amt"
                        />
                      </td>
                      <td>0.00</td>
                      <td>
                        <input  disabled={inputsDisabled}
                          class="form-control"
                          type="number" onWheel={e => e.target.blur()}
                          value="0.00"
                          placeholder="Enter Amt"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td>Personal Bike</td>
                      <td>175.00</td>
                      <td>175.00</td>
                    </tr> */}

                        {vehicleDetails?.length > 0
                          ? vehicleDetails?.map((data, vidx) => (
                              <tr>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {moment
                                    .parseZone(data?.travel_date)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.start_kms?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.close_kms?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.total_kms?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.fuel_quantity?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.fuel_amount.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_fuel_amount?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      disabled={inputsDisabled}
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyVehicle(
                                          data,
                                          vidx,
                                          "appr_fuel_amount",
                                          e.target.value
                                        )
                                      }
                                      value={Number(
                                        data?.appr_fuel_amount
                                      ).toString()}
                                      placeholder="Enter Amt"
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.driver_daily_wages?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_driver_daily_wages?.toFixed(
                                        2
                                      )}
                                    </div>
                                  ) : (
                                    <input
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyVehicle(
                                          data,
                                          vidx,
                                          "appr_driver_daily_wages",
                                          e.target.value
                                        )
                                      }
                                      value={Number(
                                        data?.appr_driver_daily_wages
                                      ).toString()}
                                      placeholder="Enter Amt"
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.driver_da_type == ""
                                    ? "Local"
                                    : daType[data?.driver_da_type]}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.driver_da_value?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_driver_da_value?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyVehicle(
                                          data,
                                          vidx,
                                          "appr_driver_da_value",
                                          e.target.value
                                        )
                                      }
                                      value={Number(
                                        data?.appr_driver_da_value
                                      ).toString()}
                                      placeholder="Enter Amt"
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.other_expenses?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_other_expenses?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyVehicle(
                                          data,
                                          vidx,
                                          "appr_other_expenses",
                                          e.target.value
                                        )
                                      }
                                      value={Number(
                                        data?.appr_other_expenses
                                      ).toString()}
                                      placeholder="Enter Amt"
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.washing_amount.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.appr_washing_amount?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyVehicle(
                                          data,
                                          vidx,
                                          "appr_washing_amount",
                                          e.target.value
                                        )
                                      }
                                      value={Number(
                                        data?.appr_washing_amount
                                      ).toString()}
                                      placeholder="Enter Amt"
                                    />
                                  )}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.travel_with}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.partner_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.modee_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.total_amount.toFixed(2)}
                                </td>
                                {/* <td class={data?.is_exception ? `text-danger` : ""}>
                            <input 
                            disabled
                              type="number" onWheel={e => e.target.blur()}
                              // onChange={(e) => modifyVehicle(data, vidx, "approved_amount", e.target.value)}
                              value={data?.approved_amount}
                              class="form-control"
                            /></td> */}
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  <strong>
                                    {data?.approved_amount.toFixed(2)}
                                  </strong>
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                ></td>
                              </tr>
                            ))
                          : ""}

                        {vehicleDetails?.length > 0 && (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <strong>
                                {vehicleLogSums?.total_kms?.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>{vehicleLogSums?.fuel_quantity}</strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.fuel_amount.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.appr_fuel_amount.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.driver_daily_wages.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.appr_driver_daily_wages.toFixed(
                                  2
                                )}
                              </strong>
                            </td>
                            <td></td>
                            <td>
                              <strong>
                                {vehicleLogSums?.driver_da_value.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.appr_driver_da_value.toFixed(
                                  2
                                )}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.other_expenses.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.appr_other_expenses.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.washing_amount.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              {vehicleLogSums?.appr_washing_amount.toFixed(2)}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <strong>
                                {vehicleLogSums?.total_amount.toFixed(2)}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {vehicleLogSums?.approved_amount.toFixed(2)}
                              </strong>
                            </td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved?.totalVehLogValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved?.totalVehLogValue2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(3, e.target.value)}
                            value={
                              // travelDetails?.approval_reasons?.length>0?

                              //     travelDetails?.approval_reasons[travelDetails?.approval_reasons.findIndex(obj => obj.expense_type === "VEHICLE_LOG")].reason

                              // :
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) =>
                                        obj.expense_type === "VEHICLE_LOG"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[3]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* <div class="mb-2 ml-3">
                <h6 class="text-theme mb-0">
                  <i class="far fa-arrow-alt-circle-right"></i>{" "}
                  <strong>VEHICLE SUMMARY</strong>
                </h6>
              </div>

              <div class="table-responsive mb-4">
                <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                  <thead>
                    <tr>
                      <th>Monthly KM limit</th>
                      <th>KM Travelled in current Month</th>
                      <th>KM Travelled - in current travel claim</th>
                      <th>Travelled KM MTD</th>
                      <th>Excess KM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2000</td>
                      <td>0</td>
                      <td>100 (100 current month km)</td>
                      <td>100</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {vehicleData["monthly limit"] ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Vehicle Summary</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-4">
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Monthly KM limit</th>
                          <th>KM Travelled in current Month</th>
                          <th>KM Travelled - in current travel claim</th>
                          <th>Travelled KM MTD</th>
                          <th>Excess KM</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{vehicleData["monthly limit"]}</td>
                          <td>{vehicleData["km traveled"]}</td>
                          <td>{vehicleData["current travel km travelled"]}</td>
                          <td>{vehicleData["traveled km MTD"]}</td>
                          <td>{vehicleData["Excess KM"]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}

              {conveyanceDetails?.length ? (
                <>
                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Local Conveyance Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                          <th>Exception</th>
                        </tr>
                      </thead>

                      <tbody>
                        {conveyanceDetails?.length > 0
                          ? conveyanceDetails?.map((data, cidx) => (
                              <tr>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {moment
                                    .parseZone(data?.conveyance_date)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.from_location_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.to_location_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.mode_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.vendor_state_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.vendor_name}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.vendor_invoice}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.mode_name
                                    ?.toLowerCase()
                                    ?.includes("rickshaw") ||
                                  data?.mode_name
                                    ?.toLowerCase()
                                    ?.includes("riks") ||
                                  data?.mode_name
                                    ?.toLowerCase()
                                    ?.includes("public transport")
                                    ? "-"
                                    : moment
                                        .parseZone(data?.invoice_date)
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {data?.amount?.toFixed(2)}
                                </td>
                                <td
                                  class={
                                    data?.is_exception ? `text-danger` : ""
                                  }
                                >
                                  {inputsDisabled ? (
                                    <div
                                      className={inputStyle.class}
                                      style={readOnlyColumnStyle.css}
                                    >
                                      {data?.approved_amount?.toFixed(2)}
                                    </div>
                                  ) : (
                                    <input
                                      className={inputStyle.class}
                                      style={numberInputStyle.amountCss}
                                      value={Number(
                                        data?.approved_amount
                                      ).toString()}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        modifyConveyance(
                                          data,
                                          cidx,
                                          "approved_amount",
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter Approved Amount"
                                    />
                                  )}
                                </td>
                                <td>
                                  {data?.is_exception ? (
                                    <i class="fa fa-flag text-danger"> </i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved.totalConveValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved.totalConveValue2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(4, e.target.value)}
                            value={
                              // travelDetails?.approval_reasons?.length>0?

                              //     travelDetails?.approval_reasons[travelDetails?.approval_reasons.findIndex(obj => obj.expense_type === "LOCAL_CONVEYANCE")].reason

                              // :
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) =>
                                        obj.expense_type === "LOCAL_CONVEYANCE"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[4]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {travelDetails?.request_type == "International" && (
                <>
                  <div>
                    <h6>
                      Foreign Expenses ( Boarding & Lodging Entitlement (USD :{" "}
                      {contAmount ?? "0"} , INR :{" "}
                      {(
                        contAmount *
                          currencyList[
                            currencyList.findIndex((obj) => obj?.name == "USD")
                          ]?.amount ?? 1
                      )
                        //  fExp
                        ?.toFixed(2)}
                      ))
                    </h6>
                  </div>
                  <h6>
                    Exchange Rates [{" "}
                    {currencyList?.map((cdata) =>
                      cdata?.amount ? (
                        <span>
                          {cdata?.name}:{cdata?.amount?.toFixed(2)}{" "}
                        </span>
                      ) : (
                        " "
                      )
                    )}
                    ]
                  </h6>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Hotel Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Hotel Name</th>
                          <th>Stay From</th>
                          <th>Stay Till</th>
                          <th>Paid By</th>
                          <th>Vendor Invoice</th>
                          <th>Invoice Date</th>
                          <th>Currency</th>
                          <th>Amt(in Forex)</th>
                          <th>Amount (in INR)</th>
                          <th>Approved Amt (in Forex)</th>
                          <th>Exchange Rate</th>
                          <th>Approved Amt (in INR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                      <td>Annex - B</td>
                      <td> 09-09-2023 </td>
                      <td>17-09-2023 </td>
                      <td>Self</td>
                      <td>As per annex-B</td>
                      <td>17-09-2023 </td>
                      <td>Indian Ruppee</td>
                      <td>77400.00</td>
                      <td>77400.00</td>
                      <td>77400.00</td>
                      <td>90</td>
                      <td>77400.00</td>
                    </tr> */}

                        {foreignDetails.hotelDetail?.map((fordata, idx) => (
                          <tr>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.hotel_name}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="text"
                                  value={fordata.hotel_name}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "hotel",
                                      idx,
                                      "hotel_name",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.check_in_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.check_in_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp(
                                    "hotel",
                                    idx,
                                    "check_in_date",
                                    e
                                  )
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />
                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.check_in_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.check_in_date} 
                              onChange={(e) => modifyForegExp("hotel", idx, "check_in_date", e.target.value)}
                              />} */}
                            </td>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.check_out_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.check_out_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp(
                                    "hotel",
                                    idx,
                                    "check_out_date",
                                    e
                                  )
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />
                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.check_out_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.check_out_date} 
                              onChange={(e) => modifyForegExp("hotel", idx, "check_out_date", e.target.value)}
                              />} */}
                            </td>
                            <td>
                              <select
                                disabled={inputsDisabled}
                                className={"form-control " + inputStyle.class}
                                style={{ width: 80 }}
                                onChange={(e) =>
                                  modifyForegExp(
                                    "hotel",
                                    idx,
                                    "paid_by",
                                    e.target.value
                                  )
                                }
                              >
                                <option
                                  value="self"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "self"
                                  }
                                >
                                  Self
                                </option>
                                <option
                                  value="company"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "company"
                                  }
                                >
                                  Company
                                </option>
                              </select>
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.vendor_invoice}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="text"
                                  value={fordata.vendor_invoice}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "hotel",
                                      idx,
                                      "vendor_invoice",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.invoice_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.invoice_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp(
                                    "hotel",
                                    idx,
                                    "invoice_date",
                                    e
                                  )
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />

                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.invoice_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.invoice_date} 
                              onChange={(e) => modifyForegExp("hotel", idx, "invoice_date", e.target.value)}
                              />} */}
                            </td>
                            <td>{fordata.currency_name}</td>
                            <td>{fordata.amount_in_forex?.toFixed(2)}</td>
                            <td>{fordata.amount_in_inr?.toFixed(2)}</td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_amount}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(fordata.appr_amount).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "hotel",
                                      idx,
                                      "appr_amount",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_exchange_rate}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(
                                    fordata.appr_exchange_rate
                                  ).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "hotel",
                                      idx,
                                      "appr_exchange_rate",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>{fordata.appr_amount_inr?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved.totalForegHotel?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved.totalForegHotel2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(5, e.target.value)}
                            value={
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) =>
                                        obj.expense_type === "FOREIGN_HOTEL"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[5]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Food Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}
                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Vendor Name</th>
                          <th>Paid By</th>
                          <th>Currency</th>
                          <th>Amount(in Forex)</th>
                          <th>Amount (in INR)</th>
                          <th>Approved Amt (in Forex)</th>
                          <th>Exchange Rate</th>
                          <th>Approved Amt (in INR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                      <td> 16-09-2023 </td>
                      <td>As per Annex - C</td>
                      <td>SELF</td>
                      <td>INR</td>
                      <td>17325.00</td>
                      <td>17325.00</td>
                      <td>17325.00</td>
                      <td>1</td>
                      <td>17325.00</td>
                    </tr> */}

                        {foreignDetails.foodDetail?.map((fordata, idx) => (
                          <tr>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.food_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.food_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp("food", idx, "food_date", e)
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />
                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.food_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.food_date} 
                              onChange={(e) => modifyForegExp("food", idx, "food_date", e.target.value)}
                              />} */}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.vendor_name}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="text"
                                  value={fordata.vendor_name}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "food",
                                      idx,
                                      "vendor_name",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <select
                                disabled={inputsDisabled}
                                className={"form-control " + inputStyle.class}
                                style={{ width: 80 }}
                                onChange={(e) =>
                                  modifyForegExp(
                                    "food",
                                    idx,
                                    "paid_by",
                                    e.target.value
                                  )
                                }
                              >
                                <option
                                  value="self"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "self"
                                  }
                                >
                                  Self
                                </option>
                                <option
                                  value="company"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "company"
                                  }
                                >
                                  Company
                                </option>
                              </select>
                            </td>
                            <td>{fordata.currency_name}</td>
                            <td>{fordata.amount_in_forex?.toFixed(2)}</td>
                            <td>{fordata.amount_in_inr?.toFixed(2)}</td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_amount?.toFixed(2)}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(fordata.appr_amount).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "food",
                                      idx,
                                      "appr_amount",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_exchange_rate?.toFixed(2)}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(
                                    fordata.appr_exchange_rate
                                  ).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "food",
                                      idx,
                                      "appr_exchange_rate",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>

                            <td>{fordata.appr_amount_inr?.toFixed(2)}</td>
                            {/* <td>{fordata.appr_amount}</td>                          
                          <td>{fordata.appr_exchange_rate}</td>                          
                          <td>{fordata.appr_amount_inr}</td>                           */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved.totalForegFood?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved.totalForegFood2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(6, e.target.value)}
                            // value={approvedSendBack_payload.reason[3]["reason"]}
                            value={
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) =>
                                        obj.expense_type === "FOREIGN_FOOD"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[6]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Incidental Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Ent Currency</th>
                          <th>Ent Amount(In Forex)</th>
                          <th>Ent Amount(In INR)</th>
                          <th>Currency</th>
                          <th>Amount(in Forex)</th>
                          <th>Amount (in INR)</th>
                          <th>Approved Amt (in Forex)</th>
                          <th>Exchange Rate</th>
                          <th>Approved Amt (in INR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                      <td> 09-09-2023 </td>
                      <td>USD</td>
                      <td>20.00</td>
                      <td>1662.60</td>
                      <td>USD</td>
                      <td>20.00</td>
                      <td>1662.60</td>
                      <td>20.00</td>
                      <td>83.13</td>
                      <td>1662.60</td>
                    </tr> */}
                        {foreignDetails.inciDaDetail?.map((fordata, idx) => (
                          <tr>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.incidental_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.incidental_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp(
                                    "da",
                                    idx,
                                    "incidental_date",
                                    e
                                  )
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />

                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.incidental_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.incidental_date} 
                              onChange={(e) => modifyForegExp("da", idx, "incidental_date", e.target.value)}
                              />} */}
                            </td>
                            <td>{fordata.ent_currency_name}</td>
                            <td>{fordata.ent_amount_in_forex?.toFixed(2)}</td>
                            <td>{fordata.ent_amount_in_inr?.toFixed(2)}</td>
                            <td>{fordata.currency_name}</td>
                            <td>{fordata.amount_in_forex?.toFixed(2)}</td>
                            <td>{fordata.amount_in_inr?.toFixed(2)}</td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_amount?.toFixed(2)}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(fordata.appr_amount).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "da",
                                      idx,
                                      "appr_amount",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_exchange_rate}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(
                                    fordata.appr_exchange_rate
                                  ).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "da",
                                      idx,
                                      "appr_exchange_rate",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>{fordata.appr_amount_inr?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved.totalForegDaValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved.totalForegDaValue2?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(7, e.target.value)}
                            // value={approvedSendBack_payload.reason[3]["reason"]}
                            value={
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) => obj.expense_type === "FOREIGN_DA"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[7]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Conveyance Details</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-3">
                    {/* <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Travel By</th>
                          <th>Vendor State</th>
                          <th>Vendor Name</th>
                          <th>Invoice Number</th>
                          <th>Invoice Date</th>
                          <th>Amount</th>
                          <th>Approved Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {travelDetails?.conveyance_expense?.length > 0
                          ? travelDetails?.conveyance_expense?.map((data) => (
                              <tr>
                                <td>{data?.conveyance_date}</td>
                                <td>{data?.from_location_name}</td>
                                <td>{data?.to_location_name}</td>
                                <td>{data?.mode_name}</td>
                                <td>{data?.vendor_state_name}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.vendor_invoice}</td>
                                <td>{data?.invoice_date}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.amount}</td>
                              </tr>
                            ))
                          : "No Records Found..."}
                      </tbody>
                    </table> */}

                    <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Travel Mode</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Paid By</th>
                          <th>Currency</th>
                          <th>Amount(in Forex)</th>
                          <th>Amount (in INR)</th>
                          <th>Approved Amt (in Forex)</th>
                          <th>Exchange Rate</th>
                          <th>Approved Amt (in INR)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                      <td> 10-09-2023 </td>
                      <td>TAXI</td>
                      <td>ABC</td>
                      <td>abc</td>
                      <td>SELF</td>
                      <td></td>
                      <td>0.00</td>
                      <td>0.00</td>
                      <td>0.00</td>
                      <td>90</td>
                      <td>0.00</td>
                    </tr> */}

                        {foreignDetails.conveyDetail?.map((fordata, idx) => (
                          <tr>
                            <td>
                              <DatePicker
                                disabled={inputsDisabled}
                                selected={
                                  fordata.conveyance_date
                                    ? new Date(
                                        moment
                                          .parseZone(fordata.conveyance_date)
                                          .format("YYYY-MM-DD")
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  modifyForegExp(
                                    "convey",
                                    idx,
                                    "conveyance_date",
                                    e
                                  )
                                }
                                placeholderText="DD-MM-YYYY"
                                class={"form-control " + inputStyle.class}
                                style={{
                                  fontSize: 14,
                                  color: "#616a71",
                                  height: 30,
                                }}
                                dateFormat="dd-MM-yyyy"
                              />

                              {/* {inputsDisabled ? <div className={inputStyle.class} style={readOnlyColumnStyle.css}>{fordata.conveyance_date}</div> : 
                              <input disabled={inputsDisabled} className={inputStyle.class} style={inputStyle.css} type="date" value={fordata.conveyance_date} 
                              onChange={(e) => modifyForegExp("convey", idx, "conveyance_date", e.target.value)}
                              />} */}
                            </td>
                            <td>
                              <select
                                disabled={inputsDisabled}
                                className={"form-control " + inputStyle.class}
                                style={{ width: 80 }}
                                // onChange={(e) => modifyForegExp("convey", idx, "conveyance_date", e.target.value)}
                              >
                                <option
                                  value="air"
                                  selected={
                                    fordata.modee_name?.toLowerCase() == "air"
                                  }
                                >
                                  Air
                                </option>
                                <option
                                  value="train"
                                  selected={
                                    fordata.modee_name?.toLowerCase() == "train"
                                  }
                                >
                                  Train Pound
                                </option>
                                <option
                                  value="bus"
                                  selected={
                                    fordata.modee_name?.toLowerCase() == "bus"
                                  }
                                >
                                  Bus
                                </option>
                                <option
                                  value="taxi"
                                  selected={
                                    fordata.modee_name?.toLowerCase() == "taxi"
                                  }
                                >
                                  Taxi
                                </option>
                              </select>
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.from_location}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="text"
                                  value={fordata.from_location}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "convey",
                                      idx,
                                      "from_location",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.to_location}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="text"
                                  value={fordata.to_location}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "convey",
                                      idx,
                                      "to_location",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <select
                                disabled={inputsDisabled}
                                className={"form-control " + inputStyle.class}
                                style={{ width: 80 }}
                                onChange={(e) =>
                                  modifyForegExp(
                                    "convey",
                                    idx,
                                    "paid_by",
                                    e.target.value
                                  )
                                }
                              >
                                <option
                                  value="self"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "self"
                                  }
                                >
                                  Self
                                </option>
                                <option
                                  value="company"
                                  selected={
                                    fordata.paid_by?.toLowerCase() == "company"
                                  }
                                >
                                  Company
                                </option>
                              </select>
                            </td>
                            <td>{fordata.currency_name}</td>
                            <td>{fordata.amount_in_forex?.toFixed(2)}</td>
                            <td>{fordata.amount_in_inr?.toFixed(2)}</td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_amount?.toFixed(2)}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(fordata.appr_amount).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "convey",
                                      idx,
                                      "appr_amount",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {inputsDisabled ? (
                                <div
                                  className={inputStyle.class}
                                  style={readOnlyColumnStyle.css}
                                >
                                  {fordata.appr_exchange_rate?.toFixed(2)}
                                </div>
                              ) : (
                                <input
                                  disabled={inputsDisabled}
                                  className={inputStyle.class}
                                  style={inputStyle.css}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={Number(
                                    fordata.appr_exchange_rate
                                  ).toString()}
                                  onChange={(e) =>
                                    modifyForegExp(
                                      "convey",
                                      idx,
                                      "appr_exchange_rate",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>{fordata.appr_amount_inr?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="pl-3 mb-3 border-bottom">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>
                            Applied Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Applied Amount"
                            value={allApproved.totalForegConveValue?.toFixed(2)}
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup ml-3">
                          <label>
                            Approved Amount <span class="text-danger">*</span>
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            class="form-control"
                            // placeholder="Enter Approved Amount"
                            value={allApproved.totalForegConveValue2?.toFixed(
                              2
                            )}
                          />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Reason
                            {/* <span class="text-danger">*</span> */}
                          </label>
                          <input
                            disabled={inputsDisabled}
                            type="text"
                            onChange={(e) => updateReasons(8, e.target.value)}
                            // value={approvedSendBack_payload.reason[3]["reason"]}
                            value={
                              inputsDisabled
                                ? travelDetails?.approval_reasons[
                                    travelDetails?.approval_reasons.findIndex(
                                      (obj) =>
                                        obj.expense_type ===
                                        "FOREIGN_CONVEYANCE"
                                    )
                                  ].reason
                                : approvedSendBack_payload.reason[8]["reason"]
                            }
                            class="form-control"
                            placeholder="Enter Reason"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div class="row viewformaftersubmit m-0">
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Expense By Self</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_userclaimself}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Expense By Company</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_userclaimcompany}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Total Claim Amt.</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_claim}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Appr. Exp. By Company</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_approvedCompExp}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Appr. Exp. By Self</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_approvedSelfExp}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Total Appr. Amt.</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {finalDetails?.final_approvedTotal?.toFixed(2)}
                    </p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Deducted Amt.</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {(
                        finalDetails?.final_claim -
                        finalDetails?.final_approvedTotal
                      )?.toFixed(2)}
                    </p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Delay:{" "}
                      <span class="text-danger">
                        {travelDetails
                          ? moment(travelDetails?.dispatch_date).diff(
                              moment(
                                travelDetails?.travel_detail[
                                  travelDetails?.travel_detail.length - 1
                                ]?.to_date,
                                "YYYY-MM-DD"
                              ),
                              "days"
                            ) + " days"
                          : ""}{" "}
                      </span>
                      Deduction Percent: (
                      <span class="text-danger">
                        {/* <i class="fas fa-rupee-sign f-12"></i> */}
                        {/* { 
                               travelDetails ?  moment(travelDetails?.dispatch_date).diff(moment(travelDetails?.travel_detail[travelDetails?.travel_detail.length-1]?.to_date, "YYYY-MM-DD"), "days") > 7 ? (finalDetails.final_approvedTotal*.05).toFixed(2) : 0 : 0
                              }
                       */}

                        {travelDetails?.ssc_manager_status=='F'?0.0:`${delayAmt}%`}
                      </span>
                      )
                    </label>

                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {travelDetails?.ssc_manager_status=='F'?0.0:(delayAmt
                        ? (
                            (delayAmt * finalDetails?.final_approvedTotal) /
                            100
                          )?.toFixed(2)
                        : 0.0)}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Net Amount</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i>{" "}
                      {travelDetails?.ssc_manager_status=='F'?finalDetails?.final_approvedTotal?.toFixed(2):finalDetails?.final_netAmt}
                    </p>
                  </div>
                </div>
              </div>

              <div class="mb-2 ml-3">
                <h6 class="text-theme mb-0">
                  <i class="far fa-arrow-alt-circle-right"></i>{" "}
                  <strong>SSC Approver History</strong>
                </h6>
              </div>

              <div class="table-responsive mb-3">
                <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                  <thead>
                    <tr>
                      <th>Remarks by</th>
                      <th>Remarks</th>
                      <th>Remarks at</th>
                    </tr>
                  </thead>

                  <tbody>
                    {travelDetails?.ssc_user_send_back_to_requestor_remarks ? (
                      <tr>
                        <td>SSC User sent back</td>
                        <td>
                          {
                            travelDetails?.ssc_user_send_back_to_requestor_remarks
                          }
                        </td>
                        <td>
                          {travelDetails?.ssc_user_send_back_to_requestor_remarks_at
                            ? moment
                                .parseZone(
                                  travelDetails?.ssc_user_send_back_to_requestor_remarks_at
                                )
                                .format("DD-MM-YYYY hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {travelDetails?.ssc_user_remarks ? (
                      <tr>
                        <td>SSC Executive</td>
                        <td>{travelDetails?.ssc_user_remarks}</td>
                        <td>
                          {travelDetails?.ssc_user_remarks_at
                            ? moment
                                .parseZone(travelDetails?.ssc_user_remarks_at)
                                .format("DD-MM-YYYY hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {travelDetails?.ssc_manager_send_back_to_sscuser_remarks ? (
                      <tr>
                        <td>SSC Manager sent back</td>
                        <td>
                          {
                            travelDetails?.ssc_manager_send_back_to_sscuser_remarks
                          }
                        </td>
                        <td>
                          {travelDetails?.ssc_manager_send_back_to_sscuser_remarks_at
                            ? moment
                                .parseZone(
                                  travelDetails?.ssc_manager_send_back_to_sscuser_remarks_at
                                )
                                .format("DD-MM-YYYY hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {travelDetails?.ssc_manager_forward_to_head_remarks ? (
                      <tr>
                        <td>SSC Manager forwarded</td>
                        <td>
                          {travelDetails?.ssc_manager_forward_to_head_remarks}
                        </td>
                        <td>
                          {travelDetails?.ssc_manager_forward_to_head_remarks_at
                            ? moment
                                .parseZone(
                                  travelDetails?.ssc_manager_forward_to_head_remarks_at
                                )
                                .format("DD-MM-YYYY hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {travelDetails?.ssc_head_send_back_to_manager_remarks ? (
                      <tr>
                        <td>SSC Head</td>
                        <td>
                          {travelDetails?.ssc_head_send_back_to_manager_remarks}
                        </td>
                        <td>
                          {travelDetails?.ssc_head_send_back_to_manager_remarks_at
                            ? moment
                                .parseZone(
                                  travelDetails?.ssc_head_send_back_to_manager_remarks_at
                                )
                                .format("DD-MM-YYYY hh:mm A")
                            : ""}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {!travelDetails?.ssc_user_send_back_to_requestor_remarks &&
                    !travelDetails?.ssc_user_remarks &&
                    !travelDetails?.ssc_manager_send_back_to_sscuser_remarks &&
                    !travelDetails?.ssc_manager_forward_to_head_remarks &&
                    !travelDetails?.ssc_head_send_back_to_manager_remarks ? (
                      <td colSpan={3}>No records found.</td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>

              {travelchats?.length > 0 ? (
                <div class="row m-0">
                  <div
                    className="col-md-12 text-white"
                    style={{ backgroundColor: "#0195d4" }}
                  >
                    Query Chain
                  </div>
                  {travelchats?.length
                    ? travelchats.map((cdata, idx) =>
                        cdata?.created_by_id != cdata?.travel_created_by_id ? (
                          <div className="col-md-12">
                            <b>SSC</b>

                            <p>
                              {cdata?.message}
                              {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                              {cdata?.attachment_list?.map((attdata, aidx) => (
                                <Link
                                  target="_blank"
                                  class="ml-2"
                                  to={attdata?.attachment_path}
                                >
                                  <i class="fas fa-paperclip text-theme"></i>
                                </Link>
                              ))}

                              <span className="ml-3">
                                {moment
                                  .parseZone(cdata?.created_at)
                                  .format("DD-MM-YYYY HH:mm:ss")}
                              </span>
                            </p>
                          </div>
                        ) : (
                          <div className="col-md-12">
                            <b>User</b>

                            <p>
                              {cdata?.message}
                              {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                              {cdata?.attachment_list?.map((attdata, aidx) => (
                                <Link
                                  target="_blank"
                                  class="ml-2"
                                  to={attdata?.attachment_path}
                                >
                                  <i class="fas fa-paperclip text-theme"></i>
                                </Link>
                              ))}
                              <span className="ml-3">
                                {moment
                                  .parseZone(cdata?.created_at)
                                  .format("DD-MM-YYYY HH:mm:ss")}
                              </span>
                            </p>
                          </div>
                        )
                      )
                    : ""}

                  {/* <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Total Claim Amt.</label>
                    <p>
                      <i class="fas fa-rupee-sign f-12"></i> {finalDetails?.final_claim}
                    </p>
                  </div>
                </div> */}
                </div>
              ) : (
                ""
              )}

              <div class="row m-0">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>Remarks</label>
                    <textarea
                      onChange={(e) => updateReasons("remarks", e.target.value)}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12 py-3 ">
                  <div className="float-right p-3">
                    <button
                      class="btn btn-primary-inner bpi-main mr-2"
                      onClick={() => approveCheckExpense()}
                    >
                      APPROVE
                    </button>

                    <button
                      class="btn btn-outline-primary  mr-2"
                      onClick={() => handleReasonView("send_back")}
                    >
                      {userGroup._userGroup == "ssc manager"
                        ? "SEND BACK TO SSC USER"
                        : userGroup._userGroup == "ssc head"
                        ? "SEND BACK TO SSC MANAGER"
                        : "SEND BACK TO USER"}
                    </button>
                    {userGroup._userGroup == "ssc manager" && (
                      <button
                        class="btn btn-outline-primary  mr-2"
                        // onClick={()=>forwardToHead()}
                        onClick={() => handleReasonView("send_forward")}
                      >
                        FORWARD TO SSC HEAD
                      </button>
                    )}
                    <button
                      class="btn btn-primary-inner mr-2"
                      onClick={() => set_docsModel(true)}
                      // onClick={()=>viewAttachments()}
                    >
                      VIEW ATTACHMENT
                    </button>
                    <button
                      class="btn btn-primary-inner mr-2"
                      onClick={addnewopen}
                    >
                      QUERY MANAGER
                    </button>

                    {/* <button class="btn btn-outline-danger mr-2">Reject</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Query to Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row chtgptbox agent">
                {travelchats?.length ? (
                  travelchats.map((cdata, idx) =>
                    // <div class={ cdata?.created_by_id != userDetails?.id ? "col-md-12" : "col-md-12 text-right"}>
                    //   <div class="ticketreplytxt rounded text-break">
                    //   {cdata?.attachment && <a href={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></a>}
                    //    {cdata?.message}

                    //   </div>
                    //   <div class="mt-2">
                    //     <h6>{moment.parseZone(cdata?.created_at).format("DD-MM-YYYY hh:mm A")}</h6>
                    //   </div>
                    // </div>

                    cdata?.created_by_id != cdata?.travel_created_by_id ? (
                      <div
                        style={{ borderRight: "solid 2px #2e6da4" }}
                        class={"mb-3 col-md-12 text-right"}
                      >
                        <b>SSC</b>

                        <p>
                          {cdata?.message}
                          <br />
                          {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                          {cdata?.attachment_list?.map((attdata, aidx) => (
                            <Link
                              target="_blank"
                              class="ml-2"
                              to={attdata?.attachment_path}
                            >
                              <i class="fas fa-paperclip text-theme"></i>
                            </Link>
                          ))}

                          <small className="ml-2">
                            {moment
                              .parseZone(cdata?.created_at)
                              .format("DD-MM-YYYY hh:mm A")}
                          </small>
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{ borderLeft: "solid 2px #eea236" }}
                        class={"mb-3 col-md-12 text-left "}
                      >
                        <b>User</b>

                        <p>
                          {cdata?.message}
                          <br />
                          {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                          {cdata?.attachment_list?.map((attdata, aidx) => (
                            <Link
                              target="_blank"
                              class="ml-2"
                              to={attdata?.attachment_path}
                            >
                              <i class="fas fa-paperclip text-theme"></i>
                            </Link>
                          ))}

                          <small className="ml-2">
                            {moment
                              .parseZone(cdata?.created_at)
                              .format("DD-MM-YYYY hh:mm A")}
                          </small>
                        </p>
                      </div>
                    )
                  )
                ) : (
                  <div class="col-md-12">
                    <div class="text-center">No queries to show.</div>
                  </div>
                )}

                {userGroup._userGroup == "ssc user" && (
                  <>
                    <div class="col-md-12">
                      <div class="border my-3"></div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group innergroup position-relative modaldefaultclose ">
                        <label>Upload files</label>
                        <input
                          disabled={inputsDisabled}
                          type="text"
                          class="form-control bg-white"
                          placeholder="(JPG, PNG, PDF Format only)"
                        />
                        {/* <div class="upload-btn-wrapper up-loposition">
                      <button class="uploadBtn">Browse</button>
                      <input disabled={inputsDisabled} type="file"
                        onChange={(e) => onProfileChange(e, "import_user")}
                      />
                    </div> */}

                        <div class="upload-btn-wrapper up-loposition">
                          <button class="uploadBtn">Browse</button>
                          <input
                            type="file"
                            multiple
                            onChange={(e) => onProfileChange2(e, "import_user")}
                          />
                        </div>

                        {selectedFiles.map((dfile) => (
                          <small class="mr-2">{dfile.name}</small>
                        ))}
                      </div>

                      {imageLocalPreviewList.length ? (
                        <div className="row">
                          {imageLocalPreviewList.map((idata, idx) => (
                            <>
                              {filesToUpload[idx]
                                .get("uploaded_file")
                                .name.includes(".pdf") ? (
                                <div class="col-md-4 text-center">
                                  <img
                                    src={"images/policy-icon/pdf.png"}
                                    width="70px"
                                    height="70px"
                                  />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              ) : filesToUpload[idx]
                                  .get("uploaded_file")
                                  .name.includes(".xls") ? (
                                <div class="col-md-4 text-center">
                                  <img
                                    src={"images/policy-icon/xlsx.png"}
                                    width="70px"
                                    height="70px"
                                  />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              ) : (
                                <div class="col-md-4 text-center">
                                  <img src={idata} class="h-100" />
                                  <i
                                    role="button"
                                    onClick={() => removeImageByIdx(idx)}
                                    class=" fas fa-trash-alt text-danger"
                                  />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="col-md-12">
                      <div class="form-group innergroup">
                        <label>
                          Comment <span class="text-danger">*</span>
                        </label>
                        <textarea
                          id="querytext"
                          class="form-control"
                          placeholder="Enter Comment"
                          onChange={(e) =>
                            set_travelPostData({
                              ...travelPostData,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        {userGroup._userGroup == "ssc user" && (
          <Modal.Footer>
            <button class="btn btn-outline-danger" onClick={addnewclose}>
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              onClick={postTravelChat}
            >
              Submit
            </button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={reasonshows} onHide={handleClose}>
        <Modal.Header
          style={{ "background-color": "#0195d4" }}
          className="text-white"
          closeButton
        >
          <Modal.Title>Send Back To User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-sm-12">
              <p> Enter reason to send back</p>
              <input
                onChange={(e) => set_inputReason(e.target.value)}
                class="form-control"
                type="text"
                value={inputReason}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn "
            disabled={inputReason?.length < 1}
            onClick={sendBackToUser}
          >
            Ok
          </Button>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={forwardreasonshows} onHide={handleClose}>
        <Modal.Header
          style={{ "background-color": "#0195d4" }}
          className="text-white"
          closeButton
        >
          <Modal.Title>Forward To SSC Head</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-sm-12">
              <p> Enter reason to forward to SSC Head</p>
              <input
                onChange={(e) => set_inputReason(e.target.value)}
                class="form-control"
                type="text"
                value={inputReason}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn "
            disabled={inputReason?.length < 1}
            onClick={forwardToHead}
          >
            Ok
          </Button>
          <Button
            style={{ "background-color": "#0195d4" }}
            class="btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={docsModel}
        onHide={() => set_docsModel(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Attachments</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row chtgptbox agent">
                {travelDetails?.attachments?.length ? (
                  <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                    <thead>
                      <tr>
                        <th>Expense Type</th>
                        <th>FILE NAME </th>
                        <th>VIEW</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travelDetails?.attachments?.map((adata, idx) => (
                        <tr>
                          <td>{adata?.expense_type}</td>
                          <td>
                            {adata?.attachment_path?.substring(
                              adata?.attachment_path?.lastIndexOf("/") + 1
                            )}
                          </td>
                          <td>
                            <Link target="_Blank" to={adata?.attachment_path}>
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  "No attachment yet."
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={editDaDetailModal.flag} onHide={editDaBillModalClose}>
        <Modal.Header
          style={{ backgroundColor: "#0068b4", color: "#fff" }}
          className="d-flex justify-content-between"
        >
          <span style={{ fontSize: "bold" }}>
            {!editDaDetailModal.isView ? "Add" : ""} Bill Details
          </span>
          <i
            class="fas fa-times"
            onClick={editDaBillModalClose}
            style={{ cursor: "pointer" }}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">Bill Date</div>
            <div className="col-md-4">Invoice Number</div>
            <div className="col-md-4">Bill Amount</div>
          </div>
          {daBillDetail.length ? (
            daBillDetail.map((obj, idx) => (
              <div className="row" key={obj.id}>
                <div className="col-md-4 px-1 mb-2">
                  {/* <input type="date" className="form-control" style={{ fontSize: 14, color: "#616a71", height: 30 }} disabled={editDaDetailModal.isView || obj?.da_id} onChange={(e) => daBillHandleChange(e.target.value, 'bill_date', idx)} value={obj.bill_date}/> */}
                  <DatePicker
                    // showIcon={true}
                    disabled={editDaDetailModal.isView || obj?.da_id}
                    selected={
                      obj.bill_date
                        ? new Date(
                            moment.parseZone(obj.bill_date).format("YYYY-MM-DD")
                          )
                        : null
                    }
                    onChange={(e) => daBillHandleChange(e, "bill_date", idx)}
                    // maxDate={new Date()}
                    // minDate={
                    //   new Date(state?.travel_detail_data[0]?.from_date)
                    // }
                    placeholderText="DD-MM-YYYY"
                    class={"form-control " + inputStyle.class}
                    style={{ fontSize: 14, color: "#616a71", height: 30 }}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="col-md-4 px-1 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    style={{ fontSize: 14, color: "#616a71", height: 30 }}
                    disabled={editDaDetailModal.isView || obj?.da_id}
                    onChange={(e) =>
                      daBillHandleChange(e.target.value, "invoice_number", idx)
                    }
                    value={obj.invoice_number}
                  />
                </div>
                <div className="col-md-4 px-1 mb-2 d-flex align-items-center">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-control"
                    style={{ fontSize: 14, color: "#616a71", height: 30 }}
                    disabled={editDaDetailModal.isView}
                    onChange={(e) =>
                      daBillHandleChange(e.target.value, "bill_amount", idx)
                    }
                    value={Number(obj.bill_amount).toString()}
                  />
                  {!editDaDetailModal.isView && obj.created_at == null ? (
                    <i
                      className="far fa-times-circle ml-1"
                      style={{ color: "#f00", cursor: "pointer" }}
                      onClick={() => removeDaBill(obj.id)}
                    ></i>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))
          ) : (
            <span style={{ fontSize: 14, color: "#616a71" }}>
              No bill added
            </span>
          )}
          {!editDaDetailModal.isView ? (
            <div className="d-flex justify-content-end align-items-center mt-1">
              <span
                style={{ color: "#0195d4", cursor: "pointer" }}
                onClick={addDaBill}
              >
                <i className="fas fa-plus-circle mr-1"></i>Add
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex justify-content-end mt-3">
            {!editDaDetailModal.isView ? (
              <button
                class="btn btn-primary bpi-main px-3 py-1 f-14"
                onClick={updateDaBillDetail}
              >
                Save
              </button>
            ) : (
              <></>
            )}
            <button
              class="btn btn-outline-secondary bpi-main px-3 py-1 f-14"
              onClick={editDaBillModalClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TravelCheckExpens;
