import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getMaterialList = async (
  plant_id,
  material_type,
  page_no,
  page_size,
  search
) => {
  return axios.get(
    `${AUTH_BASE_URL}/material_creation/material-list?plant_id=${plant_id}&material_type=${material_type}&page_no=${page_no}&page_size=${page_size}&search=${search}`
  );
};

export const getMaterialTypePlant = async () => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/material-type/list`);
};

export const getMaterialTypePlantFullList = async () => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/material-type-full-list`);
};

export const getHsnList = async () => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/hsn/list`);
};

export const getHsnListData = async (page_no, page_size, report_flag, search) => {
  return axios.get(
    `${AUTH_BASE_URL}/material_creation/hsn-request/list?page_no=${page_no}&page_size=${page_size}&report_flag=${report_flag}&search=${search}`
  );
};

export const saveHsnDataList = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/material_creation/hsn-request/create`,
    data
  );
};
export const updateHsnDataList = async (id, data) => {
  return axios.post(
    `${AUTH_BASE_URL}/material_creation/hsn-request/${id}/update`,
    data
  );
};

export const getHsnApprover = () => {
  return axios.get(`${AUTH_BASE_URL}/loan/get_approver`);
};

export const getHsnDetailsById = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/hsn-request/${id}/detail`);
}

export const saveHsnApprover = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/material_creation/hsn-request/power-user-list`,
    data
  );
};

export const getHsnApproverListing = (page_no, page_size) => {
  return axios.get(
    `${AUTH_BASE_URL}/material_creation/hsn-request/power-user-list?page_no=${page_no}&page_size=${page_size}`
  );
};

export const saveHsn_AuthUser = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/material_creation/hsn-request/authorize-user-list`,
    data
  );
};

export const getHsnAuthUserListing = (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/material_creation/hsn-request/authorize-user-list?${queryParm}`
  );
};
export const deleteHsnAuthUser = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/material_creation/hsn-request/delete_hsn_auth_user/${id}`);
}

export const deleteHsnPowerUser = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/material_creation/hsn-request/delete_hsn_power_user/${id}`);
}

export const changeHsnStatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/hsn-request/${data?.hsn_id}/approval`, data);
}

export const checkIsExist = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/hsn-request/is_exist`, data)
}

export const changeBulkHsnstatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/material_creation/hsn-request/bulk/approval`, data);
}
