import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSkillUser = async (queryData) => {
  return axios.get(`${AUTH_BASE_URL}/user-group-mapping-master?${queryData}`);
};

export const saveSkillUser = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/user-group-mapping-master`, data);
};

export const updateSkillUser = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/user-group-mapping-master/${id}`, data);
};

export const deleteSkillUser = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/user-group-mapping-master/${id}`);
};

export const multideleteSkillUser = async (ids) => {
  return axios.post(`${AUTH_BASE_URL}/user-group-mapping-master/multi_usrgroup/multiUserGroupDelete`, ids);
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/user-group-mapping-master/exists`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/user-group-mapping-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getEmployee = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};

export const getGroup = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/skill-group-master?paginate=${paginate}`);
};
