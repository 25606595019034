import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import AddLoanApply from "./AddLoanApply";
import * as LoanDataService from "../../service/loansalary";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { get_sap_schedule } from "../../service/searchpo";
//import Tooltip from 'react-bootstrap/Tooltip';

function LoanApply() {
  const dispatch = useDispatch();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    filtersetShow(false);
    setFilter("");
    setFilterEmp("");
    getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true, "");
  };
  const [availEmp, setAvailEmp] = useState([]);
  const [availEmp1, setAvailEmp1] = useState([]);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [currentTab, setCurrentTab] = useState("P");
  const [currentTabType, setCurrentTabType] = useState("Loan");
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loanApply, setLoanApply] = useState([]);
  const [editModuleData, setEditModuleData] = useState(null);
  const [userLoanData, setUserLoanData] = useState([]);
  const [userSalaryData, setUserSalaryData] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [pageCount, setpageCount] = useState(1);
  const [filter, setFilter] = useState("");
  const [filterEmp, setFilterEmp] = useState("");
  const [countPend, setCountPend] = useState([]);
  const [countAppr, setCountAppr] = useState([]);
  const [countRej, setCountRej] = useState([]);
  const [salaryCountPend, setSalaryCountPend] = useState([]);
  const [salaryCountAppr, setSalaryCountAppr] = useState([]);
  const [salaryCountRej, setSalaryCountRej] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const addNewModule = () => {
    setEditMode(false);
    setEditModuleData(null);
    setShowForm(true);
  };

  const onClose = () => {
    setEditModuleData(null);
    setShowForm(false);
    getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const editModuleValue = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setEditModuleData(eData);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getUserLoan(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filter,
      currentTab
    );
    getSapSchedule();
  }, [pageSizeNo, currentTab]);

  const getSapSchedule = () => {
    get_sap_schedule('sync_loan_advance').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const editLoanAndSalary = (data) => {
    setEditMode(true);
    setEditModuleData(data);
    setShowForm(true);
  };
  const deleteLoanRecord = (id, idx) => {
    let dataId = { id: idx };
    LoanDataService.deleteLoan(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
          if (res.data.message === "Loan Deleted Successfully.") {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const deleteSalaryAdvanceRecord = (id, idx) => {
    let dataId = { id: idx };
    LoanDataService.deleteSalaryAdvance(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const deleteLoanAndSalary = (data) => {
    confirmAlert({
      title: "Delete Loan ",
      message: `Are you sure to delete ${data?.loan_type}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            data?.type == "Loan" || data?.type === "Loan(Exceptional Case)"
              ? deleteLoanRecord(data?.id, data?.id)
              : deleteSalaryAdvanceRecord(data?.id, data?.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getUserLoan = (
    page_no,
    page_size,
    sort_by,
    paginate,
    filter,
    status
  ) => {
    LoanDataService.getUserLoan(
      handleKey(page_no, page_size, sort_by, paginate, filter, status)
    )
      .then((response) => {
        setUserLoanData(response?.data?.dataList?.result);
        setUserSalaryData(response?.data?.dataList?.result1);
        dispatch(setDisplayLoader("Hide"));
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        let obj1 = response?.data?.dataList?.result1?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        let jsonObject = obj.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setAvailEmp(uniqueArray);
        setAvailEmp1(obj1);

        response?.data?.dataList?.result.filter((item) => {
          if (item.status === "P") {
            setCountPend([item]);
          } else if (item?.status === "A") {
            setCountAppr([item]);
          } else {
            setCountRej([item]);
          }
        });

        response?.data?.dataList?.result1.filter((item) => {
          if (item.status === "P") {
            setSalaryCountPend([item]);
          } else if (item?.status === "A") {
            setSalaryCountAppr([item]);
          } else {
            setSalaryCountRej([item]);
          }
        });
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (page_no, page_size, sort_by, paginate, filter, status) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (filter !== "" && filter !== undefined && filter !== null) {
      queryParm = queryParm + "&filter=" + filter;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      let data = userLoanData;
      let data1 = [];
      data.map((res) => {
        if (
          res.type.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.loan_type.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.apply_date.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.emp_code.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.updated_at.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.comments.toLowerCase().includes(e.target.value.toLowerCase()) ||
          res.emp_full_name.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          data1.push(res);
        }
      });
      setUserLoanData(data1);
    }
    if (e.target.value.length === 0) {
      getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
      setSearchStr("");
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getUserLoan(activePage, pageSizeNo, sortData.sort_by, true);
  };

  const myShowHandle = (e) => {
    getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true);
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };

  const handleFilter = () => {
    getUserLoan(currentPage, pageSizeNo, sortData.sort_by, true, filter);
    filtersetShow(false);
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />

        <div class="row">
          {/* <div class="col-md-3">
                        <ul class="policylistnames">
                            <li class="active"><Link to="/loan-apply">Loan & Salary Advance</Link></li>
                            <li><Link to="/loan-report">Loan & Salary Advance Report</Link></li>
                            <li><Link to="/salary-approver">Approver Assignment</Link></li>
                            <li><Link to="/loan-approval">For Approval</Link> 
                            </li>
                        </ul>
                    </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Loan & Salary Advance</h4>
                    {/* <Breadcrumb>
                                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/">
                                            Me@PI
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>Loan & Salary Advance</Breadcrumb.Item>
                                    </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Loan Type,
                              <br />
                              Employee Code,
                              <br />
                              Employee Name
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          id="searchbar"
                          type="text"
                          class="form-control"
                          placeholder="Search on Loan Type, Employee Code..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* <Dropdown show>
                                                <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                    <img src="images/export.png" alt="" class="mr-2" /> Export
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#"><i class="fas fa-print"></i>Print</Dropdown.Item>
                                                    <Dropdown.Item href="#"><i class="far fa-file-alt"></i>CSV</Dropdown.Item>
                                                    <Dropdown.Item href="#"><i class="far fa-file-excel"></i>Excel</Dropdown.Item>
                                                    <Dropdown.Item href="#"><i class="far fa-file-pdf"></i>Pdf</Dropdown.Item>
                                                    <Dropdown.Item href="#"><i class="far fa-copy"></i>Copy</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                      {/* <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button> */}
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          addNewModule();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Apply Loan/Advance
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs">
                <Tabs
                  defaultActiveKey="Loan"
                  id="loansalary"
                  onSelect={(e) => setCurrentTabType(e)}
                >
                  <Tab
                    eventKey="Loan"
                    title={
                      <React.Fragment>
                        Loan
                        {/* <span class="numberpurchse">{userLoanData?.length}</span> */}
                      </React.Fragment>
                    }
                  >
                    <Tabs
                      defaultActiveKey="P"
                      id="approvaltabs"
                      onSelect={(e) => setCurrentTab(e)}
                    >
                      <Tab
                        eventKey="P"
                        title={
                          <React.Fragment>
                            Pending
                            {/* <span class="numberpurchse">
                              {countPend?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                <th>Action</th>
                                {/* <th>Status</th> */}
                                {/* <th>Advance Type</th> */}
                                <th>Loan Type</th>
                                <th>Applied Date</th>
                                {/* <th>Advance No.</th> */}
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                {/* <th>Document No.</th> */}
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>Exception Reason</th>
                                <th>Pending At (Approver)</th>
                                {/* <th>SAP Error</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {userLoanData?.length > 0 ? (
                                userLoanData
                                  ?.filter((res) => {
                                    if (res.status === "P") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        <td>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              editLoanAndSalary(data);
                                            }}
                                            class="btn btn-blanktd text-primary f-12"
                                          >
                                            <i class="far fa-edit"></i>
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              deleteLoanAndSalary(data);
                                            }}
                                            class="btn btn-blanktd text-danger ml-2 f-12"
                                          >
                                            <i class="far fa-trash-alt text-danger"></i>
                                          </button>
                                        </td>
                                        {/* <td><span class="badge bad-status badge-warning">Pending</span></td> */}
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        <td>
                                          {data?.loan_type}
                                          {data?.flag === "E" && " (Exception)"}
                                        </td>
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        {/* <td></td> */}
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        {/* <td></td> */}
                                        {/* <td></td> */}
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data?.type == "Loan" ||
                                                  data?.type ==
                                                  "Loan(Exceptional Case)"
                                                  ? data?.attachment[0]
                                                    ?.attachment_path
                                                  : data?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            <Tooltip
                                              title={data?.comments}
                                              position="bottom"
                                            >
                                              {data?.comments}
                                            </Tooltip>
                                          </div>
                                        </td>
                                        <td>{data?.exception_reason}</td>
                                        {data?.currentApprover[0]?.approver_name
                                          .length > 16 ? (
                                          <>
                                            <Tooltip
                                              title={
                                                data?.currentApprover[0]
                                                  ?.approver_name +
                                                "(" +
                                                data?.currentApprover[0]
                                                  ?.emp_code +
                                                ")"
                                              }
                                              position="bottom"
                                            >
                                              <td>
                                                {data?.currentApprover[0]?.approver_name?.slice(
                                                  0,
                                                  16
                                                )}{" "}
                                                ...
                                                {/* ({data?.currentApprover[0]?.emp_code}) */}
                                              </td>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          <td>
                                            {
                                              data?.currentApprover[0]
                                                ?.approver_name
                                            }{" "}
                                            (
                                            {data?.currentApprover[0]?.emp_code}
                                            )
                                          </td>
                                        )}
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {userLoanData?.filter((res) => {
                          if (res.status === "P") {
                            return true;
                          }
                        }).length == 0 ? (
                          ""
                        ) : (
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="sortinglist">
                                Show
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    myShowHandle(e);
                                  }}
                                >
                                  <option value={10} label={10}>
                                    10
                                  </option>
                                  <option value={20} label={20}>
                                    20
                                  </option>
                                  <option value={30} label={30}>
                                    30
                                  </option>
                                  <option value={100} label={100}>
                                    100
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-left"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        eventKey="A"
                        title={
                          <React.Fragment>
                            Approved{" "}
                            {/* <span class="numberpurchse">
                              {countAppr?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                <th>Status</th>
                                {/* <th>Advance Type</th> */}
                                <th>Loan Type</th>
                                <th>Applied Date</th>
                                {/* <th>Advance No.</th> */}
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                <th>Document No.</th>
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>Exception Reason</th>
                                <th>SAP Response</th>
                                <th>SAP Status</th>
                                <th>Approved By</th>
                                <th>Approved On</th>
                                <th>SAP Last Sync</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userLoanData?.length > 0 ? (
                                userLoanData
                                  ?.filter((res) => {
                                    if (res.status === "A") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        <td>
                                          <span class="badge bad-status badge-success">
                                            Approved
                                          </span>
                                        </td>
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        <td>{data?.loan_type}</td>
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        {/* <td></td> */}
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        <td>{data?.document_no}</td>
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data.attachment[0]
                                                  ?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            {data?.comments}
                                          </div>
                                        </td>
                                        <td>{data?.exception_reason}</td>
                                        <td>
                                          {data?.sap_error !== null && (
                                            <Tooltip
                                              title={data?.sap_error}
                                              position="bottom"
                                            >
                                              {data?.sap_error?.substr(0, 12)}
                                              ...
                                            </Tooltip>
                                          )}
                                        </td>
                                        {data?.sap_status === true ? (
                                          <td>Synced</td>
                                        ) : (
                                          <td>Pending to Sync</td>
                                        )}
                                        {data?.status == "P" ? (
                                          <td></td>
                                        ) : data?.status == "A" ? (
                                          <td>
                                            {
                                              data?.currentApprover[0]
                                                ?.approver_name
                                            }
                                          </td>
                                        ) : (
                                          <td>
                                            {
                                              data?.currentApprover[0]
                                                ?.approver_name
                                            }
                                          </td>
                                        )}
                                        <td>
                                          {moment(data?.approved_date).format(
                                            "DD-MM-YYYY HH:mm"
                                          )}
                                        </td>
                                        <td>
                                          {data?.sync_date !== null
                                            ? moment(data?.sync_date).format(
                                              "DD-MM-YYYY HH:mm"
                                            )
                                            : "-- : --"}
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {userLoanData.filter((res) => {
                          if (res.status == "A") {
                            return true;
                          }
                        }).length == 0
                          ? ""
                          : userLoanData.filter((res) => {
                            if (res.status == "A") {
                              return true;
                            }
                          }) && (
                            <div className="row">
                              <div className="col-sm-5">
                                <div className="sortinglist">
                                  Show
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      myShowHandle(e);
                                    }}
                                  >
                                    <option value={10} label={10}>
                                      10
                                    </option>
                                    <option value={20} label={20}>
                                      20
                                    </option>
                                    <option value={30} label={30}>
                                      30
                                    </option>
                                    <option value={100} label={100}>
                                      100
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-7">
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={2}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-left"
                                  }
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          )}
                      </Tab>

                      <Tab
                        eventKey="D"
                        title={
                          <React.Fragment>
                            Rejected{" "}
                            {/* <span class="numberpurchse">
                              {countRej?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                <th>Status</th>
                                {/* <th>Advance Type</th> */}
                                <th>Loan Type</th>
                                <th>Applied Date</th>
                                {/* <th>Advance No.</th> */}
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                {/* <th>Document No.</th> */}
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>Exception Reason</th>
                                {/* <th>SAP Response</th> */}
                                {/* <th>SAP Status</th> */}
                                <th>Rejected At</th>
                                {/* <th>SAP Last Sync</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {userLoanData?.length > 0 ? (
                                userLoanData
                                  ?.filter((res) => {
                                    if (res.status === "D") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        <td>
                                          <span class="badge bad-status badge-danger">
                                            Rejected
                                          </span>
                                        </td>
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        <td>{data?.loan_type}</td>
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        {/* <td></td> */}
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data.attachment[0]
                                                  ?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            {data?.comments}
                                          </div>
                                        </td>
                                        <td>{data?.exception_reason}</td>
                                        {/* <td>
                                          {data?.sap_error !== null && (
                                            <Tooltip
                                              title={data?.sap_error}
                                              position="bottom"
                                            >
                                              {data?.sap_error?.substr(0, 12)}
                                              ...
                                            </Tooltip>
                                          )}
                                        </td> */}
                                        {/* {data?.sap_status === true ? (
                                          <td>Synced</td>
                                        ) : (
                                          <td>Pending to Sync</td>
                                        )} */}
                                        <td>
                                          {moment(data?.approved_date).format(
                                            "DD-MM-YYYY HH:mm"
                                          )}
                                        </td>
                                        {/* <td>{data?.sync_date}</td> */}
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {userLoanData?.filter((res) => {
                          if (res.status == "D") {
                            return true;
                          }
                        }).length == 0 ? (
                          ""
                        ) : (
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="sortinglist">
                                Show
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    myShowHandle(e);
                                  }}
                                >
                                  <option value={10} label={10}>
                                    10
                                  </option>
                                  <option value={20} label={20}>
                                    20
                                  </option>
                                  <option value={30} label={30}>
                                    30
                                  </option>
                                  <option value={100} label={100}>
                                    100
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-left"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>
                    </Tabs>
                  </Tab>
                  <Tab
                    eventKey="Salary"
                    title={
                      <React.Fragment>
                        Salary Advance
                        {/* <span class="numberpurchse">{userLoanData?.length}</span> */}
                      </React.Fragment>
                    }
                  >
                    <Tabs
                      defaultActiveKey="P"
                      id="approvaltabs"
                      onSelect={(e) => setCurrentTab(e)}
                    >
                      <Tab
                        eventKey="P"
                        title={
                          <React.Fragment>
                            Pending
                            {/* <span class="numberpurchse">
                              {salaryCountPend?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                <th>Action</th>
                                {/* <th>Status</th> */}
                                {/* <th>Advance Type</th> */}
                                {/* <th>Loan Type</th> */}
                                <th>Applied Date</th>
                                <th>Advance No</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                {/* <th>Document No</th> */}
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>Pending At(Approver)</th>
                                {/* <th>SAP Error</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {userSalaryData?.length > 0 ? (
                                userSalaryData
                                  ?.filter((res) => {
                                    if (res.status === "P") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        <td>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              editLoanAndSalary(data);
                                            }}
                                            class="btn btn-blanktd text-primary f-12"
                                          >
                                            <i class="far fa-edit"></i>
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              deleteLoanAndSalary(data);
                                            }}
                                            class="btn btn-blanktd text-danger ml-2 f-12"
                                          >
                                            <i class="far fa-trash-alt text-danger"></i>
                                          </button>
                                        </td>
                                        {/* <td><span class="badge bad-status badge-warning">Pending</span></td> */}
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        {/* <td>{data?.loan_type}</td> */}
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td></td>
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        {/* <td></td> */}
                                        {/* <td></td> */}
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data?.type == "Salary" ||
                                                  data?.type ==
                                                  "Salary(Exceptional Case)"
                                                  ? data?.attachment[0]
                                                    ?.attachment_path
                                                  : data?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            {data?.comments}
                                          </div>
                                        </td>
                                        <td>
                                          {
                                            data?.currentApprover[0]
                                              ?.approver_name
                                          }{" "}
                                          ({data?.currentApprover[0]?.emp_code})
                                        </td>
                                        {/* <td></td> */}
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {userSalaryData?.length == 0 ? (
                          ""
                        ) : (
                          <div className="row">
                            <div className="col-sm-5">
                              <div className="sortinglist">
                                Show
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    myShowHandle(e);
                                  }}
                                >
                                  <option value={10} label={10}>
                                    10
                                  </option>
                                  <option value={20} label={20}>
                                    20
                                  </option>
                                  <option value={30} label={30}>
                                    30
                                  </option>
                                  <option value={100} label={100}>
                                    100
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-left"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        eventKey="A"
                        title={
                          <React.Fragment>
                            Approved{" "}
                            {/* <span class="numberpurchse">
                              {salaryCountAppr?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                {/* <th>Status</th> */}
                                {/* <th>Advance Type</th> */}
                                {/* <th>Loan Type</th> */}
                                <th>Applied Date</th>
                                <th>Advance No</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                <th>Document No</th>
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>SAP Response</th>
                                <th>SAP Status</th>
                                <th>Approved By</th>
                                <th>Approved On</th>
                                <th>SAP Last Sync</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userSalaryData?.length > 0 ? (
                                userSalaryData
                                  ?.filter((res) => {
                                    if (res.status === "A") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        {/* <td><span class="badge bad-status badge-success">Approved</span></td> */}
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        {/* <td>{data?.loan_type}</td> */}
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td></td>
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        <td>{data?.sap_no}</td>
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data?.type == "Salary" ||
                                                  data?.type ==
                                                  "Salary(Exceptional Case)"
                                                  ? data?.attachment[0]
                                                    ?.attachment_path
                                                  : data?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            {data?.comments}
                                          </div>
                                        </td>
                                        <td></td>
                                        {data?.sap_status === true ? (
                                          <td>Synced</td>
                                        ) : (
                                          <td>Pending to Sync</td>
                                        )}
                                        {data?.status == "P" ? (
                                          <td></td>
                                        ) : data?.status == "A" ? (
                                          <td>
                                            {
                                              data?.currentApprover[0]
                                                ?.approver_name
                                            }
                                          </td>
                                        ) : (
                                          <td>
                                            {
                                              data?.currentApprover[0]
                                                ?.approver_name
                                            }
                                          </td>
                                        )}
                                        <td>
                                          {moment(data?.approved_date).format(
                                            "DD-MM-YYYY HH:mm"
                                          )}
                                        </td>
                                        <td>
                                          {data?.sync_date !== null
                                            ? moment(data?.sync_date).format(
                                              "DD-MM-YYYY HH:mm"
                                            )
                                            : "-- : --"}
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {userSalaryData?.length == 0 ? (
                          ""
                        ) : (
                          <div class="row">
                            <div class="col-sm-5">
                              <div class="sortinglist">
                                Show
                                <select class="form-control">
                                  <option>10</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-left"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        eventKey="D"
                        title={
                          <React.Fragment>
                            Rejected{" "}
                            {/* <span class="numberpurchse">
                              {salaryCountRej?.length}
                            </span> */}
                          </React.Fragment>
                        }
                      >
                        <div class="table-responsive">
                          <table class="table table-striped table-bordered tablecured">
                            <thead>
                              <tr>
                                {/* <th>Status</th> */}
                                {/* <th>Advance Type</th> */}
                                {/* <th>Loan Type</th> */}
                                <th>Applied Date</th>
                                <th>Advance No</th>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Amount</th>
                                {/* <th>Document No</th> */}
                                <th>Attachment</th>
                                <th>Last Modified</th>
                                <th>Remarks</th>
                                <th>SAP Response</th>
                                <th>SAP Status</th>
                                <th>Rejected At</th>
                                <th>SAP Last Sync</th>
                              </tr>
                            </thead>
                            <tbody>
                              {userSalaryData?.length > 0 ? (
                                userSalaryData
                                  ?.filter((res) => {
                                    if (res.status === "D") {
                                      return true;
                                    }
                                  })
                                  .map((data) => {
                                    return (
                                      <tr>
                                        {/* <td><span class="badge bad-status badge-success">Rejected</span></td> */}
                                        {/* <td class="text-theme fw-bold">{data?.type}</td> */}
                                        {/* <td>{data?.loan_type}</td> */}
                                        <td>
                                          {moment(data?.apply_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>{data?.emp_code}</td>
                                        <td>{data?.emp_full_name}</td>
                                        <td>{data?.amount}</td>
                                        {/* <td></td> */}
                                        {data?.attachment !== undefined ? (
                                          <td>
                                            <a
                                              href={
                                                data?.type == "Salary" ||
                                                  data?.type ==
                                                  "Salary(Exceptional Case)"
                                                  ? data?.attachment[0]
                                                    ?.attachment_path
                                                  : data?.attachment_path
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i class="fas fa-paperclip"></i>{" "}
                                              View Doc
                                            </a>
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {moment(data?.updated_at).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>
                                          <div class="remarks_text">
                                            {data?.comments}
                                          </div>
                                        </td>
                                        <td></td>
                                        {data?.sap_status === true ? (
                                          <td>Synced</td>
                                        ) : (
                                          <td>Pending to Sync</td>
                                        )}
                                        <td>{data?.approved_date}</td>
                                        <td></td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td colSpan={12}>No Record Found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {userSalaryData?.length == 0 ? (
                          ""
                        ) : (
                          <div class="row">
                            <div class="col-sm-5">
                              <div class="sortinglist">
                                Show
                                <select class="form-control">
                                  <option>10</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-sm-7">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-left"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        )}
                      </Tab>
                    </Tabs>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee Name & Code<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={currentTabType === "Loan" ? availEmp : availEmp1}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilter(e[0].value);
                setFilterEmp(e);
              }}
              value={filterEmp}
            />
          </div>

          {/* <div class="form-group innergroup">
                        <label>From Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" />
                    </div>

                    <div class="form-group innergroup">
                        <label>To Date<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" />
                    </div> */}

          {/* <div class="form-group innergroup">
                        <label>Advance Type<span class="text-danger">*</span></label>
                        <select class="form-control newbgselect"
                        onChange={(e)=>{setFilter(e.target.value)}}
                        value={filter}
                        >
                            <option value="">Select</option>
                            <option value="Loan">Loan</option>
                            <option value="Salary">Salary</option>
                        </select>
                    </div> */}

          {/* <div class="form-group innergroup">
                        <label>Select Status<span class="text-danger">*</span></label>
                        <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>Pending</option>
                            <option>Approved</option>
                            <option>Rejected</option>
                        </select>
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={handleFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddLoanApply
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editModule={editModuleData}
            // onAddCallBack={onAddCallBack}
            loanApply={loanApply}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default LoanApply;
