import React, { useState, useEffect } from "react";
import OfficeApp from "../include/officeapp";
import Slider from "react-slick";
import { Modal, Tabs, Tab, Spinner } from "react-bootstrap";
import * as DashboardService from "../../service/dashboard";
import femaleAvatr from "../../assets/images/Female-Avatar.png";
import {
  selectDashboardData,
  selectUserData,
  selectCoursesValueData,
  selectLinkAccess,
  setIdeaRolePermission,
  selectSortedModule,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as CmsNewsService from "../../service/Cms-newsService";
import * as moment from "moment";
import ReviewModal from "../../modals/ReviewModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import AnniversaryModal from "../../modals/AnniversaryModal";
import { getUserDetails } from "../../service/header";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import interactionPlugin from "@fullcalendar/interaction";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ToastContainer } from "react-toastify";
import avtar from "../../assets/images/avtar.png";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import * as MyProfileService from "../../service/profile";
import SuccessFactor from "../PiLinkPages/SuccessFactor";
import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";
import AddEsop from "../PiLinkPages/AddEsop";
import AddGrc from "../PiLinkPages/AddGrc";
import Marquee from "react-fast-marquee";
import { Tooltip } from "react-tippy";
import { MD5 } from "crypto-js";
import { getRolePermission } from "../../service/idea";
import * as surveyService from "../../service/Survey";

var newssettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 6000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
};

var approvalsettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplay: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

var applicasettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 10,
  slidesToScroll: 2,
  autoplay: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

function Dashboard() {
  const dispatch = useDispatch();
  const [viewnewshow, viewnewsetShow] = useState(false);
  const [tabStatus, setTabStatus] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showForm1, setShowForm1] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);
  const [arrayDataValue, setArrayDataValue] = useState();
  const [show, setShow] = useState(false);
  const [showExternal, setShowExternal] = useState(false);
  const handleShowExternal = () => setShowExternal(true);
  const handleCloseExternal = () => setShowExternal(false);
  const [originalTime, setOriginalTime] = useState("");
  const [urlValue, setUrlValue] = useState();
  const [errorMandatory, setErrorMandatory] = useState(false);
  const [filtershow, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showAnniversaryForm, setShowAnniversaryForm] = useState(false);
  const formopen = () => setShowAnniversaryForm(true);
  const formclose = () => setShowAnniversaryForm(false);
  let navigate = useNavigate();
  const handleClose = () => setShow(false);
  const [News, setNews] = useState([]);
  const [Notice, setNotice] = useState([]);
  const [OneNotice, setOneNotice] = useState({});
  const [esopData, setESopData] = useState([]);
  const [video, setVideo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [grcData, setGrcData] = useState([]);
  const handleShow = () => setShow(true);
  const [attendance, setAttendance] = useState([]);
  const extDashboardData = useSelector(selectDashboardData);
  const sortedModuleData = useSelector(selectSortedModule);
  const getCourses = useSelector(selectCoursesValueData);
  const getAccessLinks = useSelector(selectLinkAccess);
  const [assignment, setAssignment] = useState([]);
  const [countFlag, setCountFlag] = useState(false);
  const [RoundCount, setRoundCount] = useState(false);
  const [birthdayData, setBirthdayData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [birthdayReviewData, setBirthdayReviewData] = useState();
  const [eventsData, setEventsData] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [anniversaryData, setAnniversaryData] = useState([]);
  const [anniversaryReviewData, setAnniversaryReviewData] = useState();
  const [dateTimeValue, setDateTimeValue] = useState(null);
  const [initailCase, setInitialCase] = useState(false);
  const [rejectedPlan, setRejectedPlan] = useState([]);
  const [servicesFact, setServicesFact] = useState(false);
  const [todoComFact, setTodoComfact] = useState(false);
  const [todoCapexFact, setTodoCapexfact] = useState(false);
  const [todoGRCFact, setTodoGRCfact] = useState(false);
  const [successfact, setSuccessfact] = useState(false);
  const [stopNotify, setStopNotify] = useState(true);
  const [coursesDataValue, setCoursesDataValue] = useState([]);
  const [courseValueMandt, setCourseValueMandt] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const userData = useSelector(selectUserData);
  let emp_code = userData?.username
  let encodedString = btoa(emp_code)
  let url = `${process.env.REACT_APP_PROD_URL}${encodedString}`;
  const allowCerfication = process.env.REACT_APP_DATA_CERTIFICATION;

  useEffect(() => {
    if (allowCerfication === "true") {
      if (
        userData?.certified === false &&
        localStorage.getItem("fromAd") === "true"
      ) {
        let lastSkipped = JSON.parse(localStorage?.getItem("skipped"));
        if (
          moment(new Date()).isAfter(moment(lastSkipped?.skip_date), "date") ||
          localStorage?.getItem("skipped") === null
        ) {
          navigate("/data-certification");
        }
      }
    }
    getNewsData();
    getPopup();
    getSurveys();
  }, []);

  const getSurveys = () => {
    surveyService.getSurvey(`&sort_by=-id&client=${true}`).then((response) => {
      if (response?.status === 200) {
        setSurveyData(
          response?.data?.dataList?.result.filter((each) => {
            if (each?.status === "Pending") {
              return true;
            }
          })
        );
      }
    });
  };

  const pathname = window.location.pathname;
  const insertNameInArray = [
    { name: "SERVICES" },
    { name: "COMPLIANCE_TODO" },
    { name: "GRC_TODO" },
  ];

  const getPlan = async () => {
    DashboardService.getRejectedPlan()
      .then((res) => {
        setRejectedPlan(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getIdeaRolePermission = () => {
    getRolePermission()
      .then((response) => {
        dispatch(setIdeaRolePermission(response?.data?.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  //   const scroll = (e) => {
  //     if (slider === null)
  //         return 0;

  //     e.wheelDelta > 0 ? (
  //         slider.current.slickNext()
  //     ) : (
  //         slider.current.slickPrev()
  //     );

  // };

  // useEffect(() => {
  //   window.addEventListener("wheel", scroll,true);

  //   return () => {
  //       window.removeEventListener("wheel", scroll, true);
  //   };
  // }, [scroll]);
  useEffect(() => {
    let courseArray = [];
    if (getCourses.length !== 0) {
      getCourses?.map((item) => {
        item?.todos?.results?.map((value) => {
          courseArray.push(value);
        });
      });
    }

    let data = courseArray?.filter((item) => {
      if (
        item.categoryId == "15" &&
        (item.name.includes("Code of Conduct") ||
          item.name.includes("PoSH") ||
          item.name.includes("Cyber Security"))
      ) {
        return true;
      }
    });
    let data1 = courseArray?.filter((item) => {
      if (
        item.categoryId == "15" &&
        !item.name.includes("Code of Conduct") &&
        !item.name.includes("PoSH") &&
        !item.name.includes("Cyber Security")
      ) {
        return true;
      }
    });
    setCourseValueMandt(data1);
    setCoursesDataValue(data);
  }, [getCourses]);

  const getNewsData = () => {
    CmsNewsService.getNews()
      .then((response) => {
        let data = response?.data?.dataList?.result;
        let news = [];
        let notice = [];
        let popup = [];
        data.map((x) => {
          if (x.Type == "News") {
            news.push(x);
          } else if (x.Type == "Notice") {
            notice.push(x);
          }
        });
        if (notice?.length > 0) {
          let latestNotice = moment(notice[0]?.startDateTime);
          let curDate = moment(new Date());
          if (Number(curDate.diff(latestNotice, "days")) > 3) {
            setStopNotify(true);
          } else {
            setStopNotify(false);
          }
        } else {
          setStopNotify(true);
        }
        setNotice(notice);
        setNews(news);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getPopup = () => {
    CmsNewsService.getPopupData()
      .then((response) => {
        let data = response?.data?.dataList?.result;
        let popup = [];
        data.map((x) => {
          if (x.Type == "Popup") {
            popup.push(x);
          }
        });

        if (popup.length != 0) {
          setOneNotice(popup[0]);
          viewnewopen();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const postPopup = (popupid) => {
    let data = { id: popupid };
    CmsNewsService.postPopupData(data)
      .then((response) => {
        let data = response?.data?.dataList?.result;

        if (data.length != 0) {
          setOneNotice(data[0]);
          viewnewopen();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getAttendance = async (from_date, to_date) => {
    DashboardService.getAttendance(from_date, to_date, true)
      .then((response) => {
        setAttendance(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getApproval = async () => {
    let deletegatedLogin = localStorage?.getItem('delegatedLogin') === null ? false : true;
    DashboardService.getApproval(deletegatedLogin)
      .then((response) => {
        response?.data?.dataList?.result?.forEach((item, i) => {
          item.sub_type = item?.sub_type?.filter(
            (data) => data?.approvals_count != 0
          );
        });

        let grc_app_data = response?.data?.dataList?.result
        
      MyProfileService.getLinksAccess(userData?.username)
      .then((response) => {
        let data1 = response?.data?.dataList;
        // if (data1?.grc === "Y") {
          getGRCTodoList(userData?.username, grc_app_data);
        // }
        // if (data1?.capex === "Y") {
        //   getCapexTodo(userData?.username, false, grc_app_data);
        // }
      })
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getGRCTodoList = async (user_id, app_data) => {
    await MyProfileService.getGRCTodoList(user_id)
      .then((response) => {
        let data1 = response?.data?.dataList;
        if (data1?.grc_todo_count !== "" && data1?.grc_username !== "") {
          data1.sub_type_name = "GRC";
          // setGrcTodoList(data1);
          
        }
        // let grc = JSON.parse(JSON.stringify(data1))
        if (app_data?.length > 0) {
          let hasBusinessTransaction = false;
        
          // Iterate over the app_data array
          app_data.forEach((val) => {
            if (val?.type_name === "Business Transaction") {
              hasBusinessTransaction = true;
        
              let data = {
                sub_type_name: "GRC Approval",
                grc_todo_count: Number(data1.grc_todo_count),
                grc_username: data1?.grc_username
              };
        
              let grcCount = data1?.grc_todo_count === undefined ? 0 : Number(data1.grc_todo_count);
              let calc = val.total === undefined ? 0 : Number(val.total);
              let sum = calc + grcCount;
        
              val.total = sum;
              val.sub_type.push(data);
            }
          });
        
          // If no "Business Transaction" was found, create a new one
          if (!hasBusinessTransaction) {
            let new_data = {
              type_name: "Business Transaction",
              sub_type: [],
              total: 0
            };
        
            let data = {
              sub_type_name: "GRC Approval",
              grc_todo_count: Number(data1.grc_todo_count),
              grc_username: data1?.grc_username
            };
        
            let grcCount = data1?.grc_todo_count === undefined ? 0 : Number(data1.grc_todo_count);
            new_data.total = grcCount;
            new_data.sub_type.push(data);
        
            if(grcCount !== 0){
            app_data.push(new_data);
            }
          }
        
          getCapexTodo(userData?.username, false, app_data);
          // setAssignment(app_data)
        }
        
      else{
        let new_data=[
          {
          type_name: "Business Transaction",
          sub_type:[],
          total:0
          }
        ]
        let data = {
          sub_type_name: "GRC Approval",
          grc_todo_count: Number(data1.grc_todo_count),
          grc_username: data1?.grc_username
        };
        let grcCount = data1.grc_todo_count == undefined ? 0 : Number(data1.grc_todo_count);
        // let calc = val.total === undefined ? 0 : Number(val.total);
        
        let sum = Number(grcCount)
        if(sum !== 0){
        new_data[0].total=sum
        new_data[0].sub_type.push(data)
        getCapexTodo(userData?.username, false, new_data);
        }
        else{
          getCapexTodo(userData?.username, false, app_data);
        }
        // setAssignment(new_data)
      }

        // setAssignment(app_data);
        // setCountFlag(true);
      })
      .catch((error) => {
        setAssignment(app_data)
        setCountFlag(true);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCapexTodo = async (user_id, flg, app_data) => {
    await MyProfileService.getCapexTodo(user_id)
      .then((response) => {
        if (!flg) {
          let data1 = response?.data?.dataList;
          data1.type = "Capex";
          // setCapexTodoList(data1);
          if (app_data?.length > 0) {
            let hasBusinessTransaction = false;
          
            app_data &&
            app_data.map((val) => {
              if (val?.type_name === "Business Transaction") {
                hasBusinessTransaction = true;
                let data = {
                  sub_type_name: "Cigma Approval",
                  count: Number(data1.count),
                  status_code: 200
                };
                let capexCount = data1.count === undefined ? 0 : Number(data1.count);
                let calc = val.total === undefined ? 0 : Number(val.total);
                let sum = calc + capexCount;
                val.total = sum;
                val.sub_type.push(data);
              }
            });
          
            if (!hasBusinessTransaction) {
              let new_data = [
                {
                  type_name: "Business Transaction",
                  sub_type: [],
                  total: 0
                }
              ];
              let data = {
                sub_type_name: "Cigma Approval",
                count: Number(data1.count),
                status_code: 200
              };
              let capexCount = data1.count === undefined ? 0 : Number(data1.count);
              let sum = capexCount;
              new_data[0].total = sum;
              new_data[0].sub_type.push(data);
              app_data.push(new_data[0]);
            }
          
            setAssignment(app_data);
          }
          
      else{

        let new_data=[
          {
          type_name: "Business Transaction",
          sub_type:[],
          total:0
          }
        ]
        let data = {
          sub_type_name: "Cigma Approval",
          count: Number(data1.count),
          status_code: 200
        };
        let capexCount = data1.count == undefined ? 0 : Number(data1.count);
        // let calc = val.total === undefined ? 0 : Number(val.total);
        
        let sum = Number(capexCount)
        new_data[0].total=sum
        new_data[0].sub_type.push(data)
        
        setAssignment(new_data)
      }
        
        setCountFlag(true);

        } else {
          setTimeout(() => {
            let data2 = response?.data?.dataList;
            window.open(data2.url, "_blank");
          }, 100);
        }
      })
      .catch((error) => {
        setAssignment(app_data)
        setCountFlag(true);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BK3rIWCqfIhV2d0MVYAJWjJBymqYi4PN0U5WVirt7HyfZAanidXoaYybmu9Uq5z7UbHDcyh2AHrzdEdj6JyRLLw",
      });
      let data = { token: token };
      DashboardService.saveNotificationToken(data).then((response) => {
        // console.log("response", response);
      });
    }
  }

  useEffect(() => {
    requestPermission();
    // getIdeaRolePermission();
  }, []);

  const getBirthdayList = async () => {
    DashboardService.getBirthdayList()
      .then((response) => {
        setBirthdayData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getHolidaysList = async (paginate, month, year) => {
    DashboardService.getHolidaysList(paginate, month, year)
      .then((response) => {
        let data = [];
        let holiData = response?.data?.dataList?.result;
        let optHoliData = response?.data?.dataList?.result1;
        if (holiData !== undefined) {
          data.push(...response?.data?.dataList?.result);
        }
        if (optHoliData !== undefined) {
          data.push(...response?.data?.dataList?.result1);
        }
        // console.log("=====================",response?.data?.dataList?.result);
        // holiData
        //   .filter((obj) => {
        //     if (
        //       moment(obj.day_date).format("MMM") === moment(date).format("MMM")
        //     ) {
        //       return true;
        //     }
        //   })
        //   .map((item) => {
        //     data.push(item);
        //   });
        setHolidayData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getMeetings = async (value, date) => {
    setIsLoading(true);
    DashboardService.getMeetings(value)
      .then((response) => {
        let data = [];
        let todoData = [];
        let meetData = response?.data?.dataList?.result?.value;
        let meetValue = meetData
          .filter((obj) => {
            if (
              moment(obj.start.dateTime).format("DD") ===
              moment(date).format("DD")
            ) {
              return true;
            }
          })
          .map((item) => {
            data.push(item);
          });
        setMeetingData(data);
        setTodoData(todoData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getCourseList = async () => {
    DashboardService.getCourseList()
      .then((response) => {
        setCourseData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getActivityList = async (page_no, page_size) => {
    DashboardService.getActivityList(page_no, page_size)
      .then((response) => {
        setActivityData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getWorkAnniversaryList = async () => {
    DashboardService.getWorkAnniversaryList()
      .then((response) => {
        setAnniversaryData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const HandleEvents = () => {
    let eventValue = [];
    [meetingData]?.map((item, idx) =>
      eventValue.push({
        title: item.title,
        start: item.transaction_date + "T" + item.transaction_from_time,
        end: item.transaction_date + "T" + item.transaction_to_time,
      })
    );
    setEventsData(eventValue);
  };

  useEffect(() => {
    let handleDate = "";
    activityData?.map(
      (data) => (handleDate = data?.created_at_datetime.split(":"))
    );
    setDateValue(`${handleDate[0]} : ${handleDate[1]}`);
  }, [activityData]);

  useEffect(() => {
    HandleEvents();
  }, [meetingData]);

  // const md5 = require("blueimp-md5");
  // const buildUrl = (id, url) => {
  //   const userid = encodeURIComponent(btoa(id));
  //   var date = new Date();
  //   var dateString =
  //     date.getFullYear() +
  //     ("0" + (date.getMonth() + 1)).slice(-2) +
  //     ("0" + date.getDate()).slice(-2);
  //   const expireDate = `${dateString}T${
  //     new Date().toTimeString().split(" ")[0]
  //   }`;
  //   const callerHash = md5(
  //     encodeURIComponent(btoa(parseInt(userid).toString() + dateString))
  //   );
  //   const timeZone = "Asia/Kolkata";

  //   return `${url}&T=${userid}&tklogin_key=mypi&expire=${expireDate}&callerhash=${callerHash}&tz=Asia/Kolkata`;
  //   // return `https://mypiqas1.piind.in/piilqas/webservices?company=PI&username=${userid}&tklogin_key=mypiportal&expire=${expireDate}&callerhash=${callerHash}&tz=Asia/Kolkata&method=ITSM`;
  // };

  useEffect(() => {
    getAttendance(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD")
    );
    getBirthdayList();
    if (localStorage.getItem("fromAd") === "true") {
      getCourseList();
    }
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getApproval();
    }, 1000 * 60 * 1);
    return () => {
      interval && clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getActivityList(1, 10);
    getWorkAnniversaryList();
    getApproval();
  }, []);

  const handleDateClick = (e) => {
    let date = new Date(e);
    let fromDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    let dataValue1 = moment(fromDate).format("DD-MM-YYYY").split("-");

    const meetingData = {
      from_year: dataValue1[2],
      from_month: dataValue1[1],
      from_day: dataValue1[0],
      to_year: dataValue1[2],
      to_month: dataValue1[1],
      to_day: dataValue1[0],
    };
    extDashboardData?.WID?.map((value, index) => {
      if (
        localStorage.getItem("fromAd") === "true" &&
        value?.name === "Meetings" &&
        meetingData?.from_year !== "1970"
      ) {
        getMeetings(meetingData, e);
      }
    });
  };

  useEffect(() => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    setDateTimeValue(currentDate);
    getHolidaysList(true, month, year);
    // getUserDetails(0);
  }, []);

  useEffect(() => {
    let intervalDate = setInterval(() => {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let currentDate = `${year}-${month}-${day}`;
      setDateTimeValue(currentDate);
    }, 1000 * 60 * 3);
    return () => {
      intervalDate && clearInterval(intervalDate);
    };
  }, []);

  const dateString =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  useEffect(() => {
    if (dateTimeValue !== undefined) {
      handleDateClick(dateTimeValue);
    }
  }, [dateTimeValue]);

  const setVideoLink = (link) => {
    setVideo(link);
  };
  const viewNotice = (data) => {
    setOneNotice(data);
    viewnewopen();
  };

  const dynamicUrlCall = (caseurl, furl) => {
    switch (caseurl) {
      case "VMS":
        saveVmsDataList(userData?.username);
        break;

      case "MDO":
        localStorage.setItem("currsso", btoa("MDO@@" + furl));
        window.open("/ssorequest", "_blank");
        break;

      case "GRC":
        localStorage.setItem("currsso", btoa("GRC@@" + furl));
        setShowForm1(true);
        break;

      case "GRC_TODO":
        localStorage.setItem("currsso", btoa("GRC_TODO@@" + furl));
        setShowForm1(true);
        break;

      case "CMP":
        localStorage.setItem("currsso", btoa("CMP@@" + furl));
        window.open("/ssorequest", "_blank");
        break;

      case "ESOP":
        setShowForm(true);
        break;

      case "Capex":
        saveCapexAccess(userData?.username);
        break;
      case "ClickIPR":
        getClickIPR();
        break;

      default:
    }
  };

  const dataArrayInList = () => {
    let insertValue = extDashboardData?.EXT_APP?.map((value) => value);
    insertValue.splice.apply(insertValue, [4, 0].concat(insertNameInArray));
    let dataArrayValue = extDashboardData?.MYPI_APP?.map((value) => value);
    dataArrayValue.splice.apply(dataArrayValue, [4, 0].concat(insertValue));

    let priority_data = [];
    let priority_data2 = [];
    let check = [];
    if (sortedModuleData?.length > 0) {
      sortedModuleData?.map((res) => {
        dataArrayValue?.map((obj) => {
          if (obj?.id === res) {
            priority_data.push(obj);
          } else {
            if (check.includes(obj?.id) === false) {
              check.push(obj.id);
              priority_data2.push(obj);
            }
          }
        });
      });
    } else {
      dataArrayValue?.map((res) => {
        priority_data2.push(res);
      });
    }

    let finalArr = [...priority_data, ...priority_data2];
    let jsonObject = finalArr.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    uniqueArray?.map((data, idx) => {
      if (data.name === "GRC_TODO") {
        setTodoGRCfact(true);
      } else if (data.name === "COMPLIANCE_TODO") {
        setTodoComfact(true);
      } else if (data.name === "SERVICES") {
        setServicesFact(true);
      } else if (data.name === "SUCCESSFACTOR") {
        setSuccessfact(true);
      }
    });

    let notAllowed = [
      "SUCCESSFACTOR",
      "GRC_TODO",
      "SERVICES",
      "COMPLIANCE_TODO",
    ];
    let newData = uniqueArray?.filter((obj) => {
      if (notAllowed.includes(obj?.name) === false) {
        if (
          (obj?.name === "MDO" && getAccessLinks?.mdo === "N") ||
          (obj?.name === "GRC" && getAccessLinks?.grc === "N") ||
          (obj?.name === "SAP Password Reset" && getAccessLinks?.grc === "N") ||
          (obj?.name === "ESOP" && getAccessLinks?.esop === "N")
        ) {
          return false;
        } else {
          return true;
        }
      }
    });
    newData.push({ name: "ClickIPR" });
    setArrayDataValue(newData);
  };

  const saveModuleLog = (data) => {
    MyProfileService.save_module_log(data?.id, data).then((response) => { });
  };

  const grcRedirection = (user, url) => {
    let testUrl = url === undefined ? urlValue : url;
    localStorage.setItem(
      "currsso",
      btoa(atob(btoa("GRC@@" + testUrl)) + "@@" + user)
    );
    window.open("/ssorequest", "_blank");
  };

  const CreateUrl = ({ item }) => {
    let furl = "";
    let utype = "L";
    let temp = false;
    switch (item.name) {
      case "Ticket":
        utype = "L";
        furl = item?.url; //buildUrl(userData?.username, item?.url);
        break;

      case "VMS":
        utype = "B";
        furl = ""; // myUrlData;
        break;

      case "MDO":
        utype = "L";
        furl = item?.url;
        break;

      case "CMP":
        utype = "B";
        furl = item?.url;
        break;

      case "GRC":
        utype = "B";
        furl = item?.url;
        setUrlValue(item?.url);
        break;

      case "SAP Password Reset":
        utype = "L";
        furl = item?.url;
        temp = true;
        setUrlValue(item?.url);
        break;

      case "ESOP":
        utype = "B";
        furl = "";
        break;

      case "Capex":
        utype = "B";
        furl = "";
        break;

      case "ClickIPR":
        utype = "B";
        furl = "";
        break;
      default:
        utype = "L";
        furl = item?.url
          .replace("{userid}", btoa(userData?.username))
          .replace("{citz}", btoa(dateString));
        break;
    }
    return (
      <>
        {utype === "N" ? (
          ""
        ) : utype === "L" ? (
          <a
            target={item?.redirect_type === "EXTR" ? "_blank" : ""}
            href={temp === false ? furl : ""}
            class="d-block"
            rel="noreferrer"
          >
            <div>
              <div className="applicationTxt">
                {item?.url_type === "INTR" ? (
                  <Tooltip
                    title={item?.name}
                    position="bottom"
                    className="quicklinktoop"
                  >
                    <a
                      href={item?.url}
                      class="d-block"
                      onClick={() => {
                        saveModuleLog(item);
                      }}
                    >
                      <div className="apppplinner">
                        {furl !== "" && <img src={item?.icon_url} alt="" />}
                        <p>{item?.name}</p>
                      </div>
                    </a>
                  </Tooltip>
                ) : (
                  <a
                    href={temp === false ? item?.url : ""}
                    target={item?.redirect_type === "EXTR" ? "_blank" : ""}
                    class="d-block"
                    rel="noreferrer"
                    onClick={() => {
                      if (temp) {
                        let grc =
                          grcData?.length > 0 ? grcData[0]?.grc_username : "";
                        grcRedirection(grc, item?.url);
                      } else {
                        saveModuleLog(item);
                      }
                    }}
                  >
                    <Tooltip
                      title={item?.name}
                      position="bottom"
                      className="quicklinktoop"
                    >
                      <div className="apppplinner">
                        {furl !== "" && <img src={item?.icon_url} alt="" />}
                        <p>{item?.name}</p>
                      </div>
                    </Tooltip>
                  </a>
                )}
              </div>
            </div>

            {/* <div className="extapptxt">
              {furl !== "" && (
                <div className="extapptxtinner">
                  <span>
                    <img src={item?.icon_url} alt="" />
                  </span>
                </div>
              )}
              <p>{item?.name}</p>
            </div> */}
          </a>
        ) : (
          <a
            href="javascript:void(0)"
            onClick={() => {
              saveModuleLog(item);
              dynamicUrlCall(item.name, furl);
            }}
          >
            {/* <div className="extapptxt">
              <div className="extapptxtinner">
                <span>
                  <img src={item?.icon_url} alt="" />
                </span>
              </div>
              <p>{item?.name}</p>
            </div> */}
            <div>
              <div className="applicationTxt">
                <div className="apppplinner">
                  <img
                    src={
                      item?.name == "ClickIPR"
                        ? "/images/ClickIPR-white (1).png"
                        : item?.icon_url
                    }
                    alt=""
                  />
                  <p>
                    <Tooltip
                      title={item?.name === "Capex" ? "CIGMA" : item?.name}
                      position="bottom"
                      className="quicklinktoop"
                    >
                      {item?.name === "Capex" ? "CIGMA" : item?.name}
                    </Tooltip>
                  </p>
                </div>
              </div>
            </div>
          </a>
        )}
      </>
    );
  };

  const saveVmsDataList = (value) => {
    MyProfileService.saveVmsDataList(value)
      .then((response) => {
        window.open(response?.data?.dataList?.result, "_blank");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const saveCapexAccess = (value) => {
    MyProfileService.saveCapexAccess(value)
      .then((response) => {
        window.open(response?.data?.dataList?.result, "_blank");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getClickIPR = () => {
    MyProfileService.getClickIPR()
      .then((response) => {
        window.open(response?.data?.dataList?.url, "_blank");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEsopList = (value) => {
    MyProfileService.getEsopList(value)
      .then((response) => {
        setESopData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleMonthChange = (payload) => {
    let month = payload.endStr.slice(5, 7);
    let year = payload.endStr.slice(0, 4);
    let currMonth = Number(month) - 1;
    let currYear = Number(year);
    if (currMonth == 0) {
      currMonth = 12;
    }
    getHolidaysList(true, currMonth, currYear);
  };

  const getGrcUsername = (value) => {
    MyProfileService.getGrcUsername(value)
      .then((response) => {
        setGrcData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onClose = () => {
    setShowForm(false);
    setShowForm1(false);
  };

  useEffect(() => {
    // localStorage.getItem("fromAd") === "true" &&
    //   getAccessLinks &&
    //   getAccessLinks.esop === "Y" &&
    getEsopList(userData?.username);

    // localStorage.getItem("fromAd") === "true" &&
    getGrcUsername(userData?.username);
    if (
      userData["Region Manager"] ||
      userData["Zone Manger"] ||
      userData["Territory Manager"]
    ) {
      getPlan();
    }
  }, [userData]);

  const [isoffShown, offsetActive] = useState("false");
  const [quizData, setQuizData] = useState([]);
  const [showQuiz, setShowQuiz] = useState(false);
  const office365Open = () => {
    offsetActive(!isoffShown);
  };

  useEffect(() => {
    dataArrayInList();
  }, [getAccessLinks]);

  useEffect(() => {
    getIdeaRolePermission();
  }, []);

  const openQuiz = (data) => {
    let data1 = data;
    let time = data1?.duration;
    setOriginalTime(time);
    let min = Math.floor(time / 60),
      sec = Math.round(time % 60);
    data1.duration = min + "hr :" + sec + " min ";
    setQuizData(data1);
    setShowQuiz(true);
  };
  const closeQuiz = () => {
    let data = quizData;
    data.duration = originalTime;
    setQuizData(data);
    setShowQuiz(false);
    setOriginalTime("");
  };
  return (
    <>
      <div className="content-wrapper">
        <ToastContainer autoClose={1000} />
        <iframe
          src={url}
          allowFullScreen
          style={{
            width: "100%",
            height: "100%",
          }}
          hidden
        ></iframe>
        {userData["Region Manager"] ||
          userData["Zone Manger"] ||
          (userData["Territory Manager"] && (
            <>
              {extDashboardData?.WID?.findIndex(
                (el) => el.name == "Rejection"
              ) == -1 ? null : (
                <div className="col-lg-6">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mainpage-head">Rejections</h2>
                    </div>
                  </div>
                  <div
                    className="approval-bg apphomeheadscroll"
                    style={{ height: "200px" }}
                  >
                    <Tabs defaultActiveKey="Agri Portal" id="approvaltabs">
                      <Tab
                        eventKey="Agri Portal"
                        title={
                          <React.Fragment>
                            Agri Portal
                            <span className="text-danger">
                              {rejectedPlan.length}
                              {/* {value?.total == 0 ? "" : <>{value.total}</>} */}
                            </span>
                          </React.Fragment>
                        }
                      >
                        <Slider className="clientslider" {...approvalsettings}>
                          {rejectedPlan?.map((item) => {
                            let url = "";
                            switch (item.module) {
                              case "Major Product":
                                url = "/mymarket/majorproducts";
                                break;
                              case "Market Size":
                                url = "/mymarket/marketsize";
                                break;
                              case "Channel Size":
                                url = "/mymarket/channelsize";
                                break;
                              case "Market Player":
                                url = "/mymarket/marketplayer";
                                break;
                              case "Crop Pattern":
                                url = "/mymarket/croppattern";
                                break;
                              case "Crop Scenario":
                                url = "/mymarket/cropscenario";
                                break;
                              case "Competing Brand":
                                url = "/mymarket/competitionbrandmapping";
                                break;
                              case "Competing Molecule":
                                url = "/mymarket/competitionmoleculemapping";
                                break;
                              case "Overall Strategy":
                                url = "/mystrategy/overallstrategy";
                                break;
                              case "Product Strategy":
                                url = "/mystrategy/cropproductstrategy";
                                break;
                              case "Crop Plan":
                                url = "/myplan/cropplan";
                                break;
                              case "Sale Plan":
                                url = "/myplan/salesplan";
                                break;
                              case "Detailed Sale Plan":
                                url = "/myplan/detailedsalesplan";
                                break;
                              case "Field Work Plan":
                                url = "/myplan/fieldwork";
                                break;
                              case "Expense Plan":
                                url = "/myplan/expenseplan";
                                break;
                              case "Pop Item":
                                url = "/myplan/popplanner";
                                break;
                              default:
                                url = "/myplan/popplanner";
                            }
                            return (
                              <div>
                                <Link to={url}>
                                  <div className="aprlTxt">
                                    <h5>{item?.module?.slice(0, 1)}</h5>
                                    <p>{item?.module}</p>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </Slider>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </>
          ))}

        <div className="row">
          {extDashboardData?.WID?.map((value, index) => (
            <>
              {value?.name === "News" ? (
                <div class="col-lg-6">
                  <h2 class="mainpage-head">News/Notice</h2>
                  <div class="homenewsdesign-bg homenesnew-bg">
                    <div class="row">
                      <div
                        class="col-sm-1 pr-0 mb-2 mb-sm-0 text-center"
                        style={{ position: "sticky" }}
                      >
                        <img src="images/new-icon.png" alt="" />
                        <p class="newsnewheaidng">News</p>
                      </div>
                      <div
                        class="col-sm-11 position-relative"
                      // style={{ maxHeight: "12vh" }}
                      >
                        <Slider className="clientslider" {...newssettings}>
                          {News.length !== 0 ? (
                            News?.map((x) => (
                              <>
                                <div>
                                  <div className="row">
                                    {x?.external !== null ? (
                                      <div
                                        className="col-sm-6 pr-3 pr-lg-0"
                                        onClick={() => {
                                          viewNotice(x);
                                          setVideoLink(x?.external);
                                        }}
                                      >
                                        <div
                                          className="position-relative"
                                          onClick={viewnewopen}
                                          style={{ zIndex: -1 }}
                                        >
                                          <iframe
                                            className="home-newsimg"
                                            src={x?.external}
                                          ></iframe>
                                        </div>
                                      </div>
                                    ) : x?.attachment.length != 0 ? (
                                      <div
                                        className="col-lg-5 pr-3 pr-lg-0"
                                        onClick={() => {
                                          viewNotice(x);
                                          setVideoLink(
                                            x?.attachment[0]?.attachment_path
                                          );
                                        }}
                                      >
                                        <div
                                          className="position-relative"
                                          style={{ zIndex: -1 }}
                                        >
                                          {x?.attachment[0]?.attachment_path?.includes(
                                            ".jpg"
                                          ) ||
                                            x?.attachment[0]?.attachment_path?.includes(
                                              ".jpeg"
                                            ) ||
                                            x?.attachment[0]?.attachment_path?.includes(
                                              ".png"
                                            ) ||
                                            x?.attachment[0]?.attachment_path?.includes(
                                              ".PNG"
                                            ) ||
                                            x?.attachment[0]?.attachment_path?.includes(
                                              ".gif"
                                            ) ? (
                                            <img
                                              className="home-newsimg"
                                              src={
                                                x?.attachment[0]
                                                  ?.attachment_path
                                              }
                                            ></img>
                                          ) : x?.attachment[0]?.attachment_path?.includes(
                                            ".pdf"
                                          ) ? (
                                            <div class="col-sm-1 col-3 pr-0">
                                              <iframe
                                                width="100px"
                                                height="75"
                                                src={
                                                  x?.attachment[0]
                                                    ?.attachment_path
                                                }
                                              ></iframe>
                                            </div>
                                          ) : (
                                            <video
                                              onClick={() =>
                                                setVideoLink(
                                                  x?.attachment[0]
                                                    ?.attachment_path
                                                )
                                              }
                                              className="home-newsimg"
                                              src={
                                                x?.attachment[0]
                                                  ?.attachment_path
                                              }
                                            ></video>
                                          )}
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="col-lg-7 homenewstxt d-flex flex-column">
                                      <h4 onClick={() => viewNotice(x)}>
                                        <ReactQuill
                                          value={x?.title}
                                          readOnly
                                          theme="bubble"
                                        />
                                      </h4>
                                      <p onClick={() => viewNotice(x)}>
                                        <ReactQuill
                                          value={x?.description}
                                          readOnly
                                          theme="bubble"
                                        />
                                      </p>
                                      <div className="d-flex mt-auto">
                                        <h6>
                                          {moment
                                            .utc(x?.created_at)
                                            .format("Do MMM YYYY, h:mm A")}
                                        </h6>
                                        {/* <span className="badge-purple">Fox News</span> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <div class="text-center">
                              <img
                                src="images/no-found.png"
                                alt=""
                                style={{ height: "84px", margin: "0 auto" }}
                              />
                              <p class="mt-2 f-15 text-muted mb-2">
                                <strong>No news available.</strong>
                              </p>
                            </div>
                          )}
                        </Slider>
                      </div>
                    </div>

                    <div
                      class="row mt-1 pt-1"
                    // style={{ position: "relative", top: "20px" }}
                    >
                      <div
                        class="col-sm-1 pr-0 mb-2 mb-sm-0 text-center"
                        style={{ position: "sticky" }}
                      >
                        <img src="images/notice-icon.png" alt="" />
                        <p class="newsnewheaidng">Notice</p>
                      </div>
                      <div class="col-sm-11">
                        {Notice.length !== 0 ? (
                          Notice?.map((x) => (
                            <>
                              <Marquee pauseOnHover delay={2.5}>
                                {x?.external !== null ? (
                                  <div
                                    className="col-lg-5 pr-3 pr-lg-0"
                                    onClick={() => {
                                      viewNotice(x);
                                      setVideoLink(x?.external);
                                    }}
                                  >
                                    <div
                                      className="position-relative"
                                      onClick={viewnewopen}
                                      style={{ zIndex: -1 }}
                                    >
                                      <iframe
                                        className="home-newsimg"
                                        src={x?.external}
                                      ></iframe>
                                    </div>
                                  </div>
                                ) : x?.attachment.length != 0 ? (
                                  <div
                                    className="col-lg-5 pr-3 pr-lg-0"
                                    onClick={() => {
                                      viewNotice(x);
                                      setVideoLink(
                                        x?.attachment[0]?.attachment_path
                                      );
                                    }}
                                  >
                                    <div
                                      className="position-relative"
                                      style={{ zIndex: -1 }}
                                    >
                                      {x?.attachment[0]?.attachment_path?.includes(
                                        ".jpg"
                                      ) ||
                                        x?.attachment[0]?.attachment_path?.includes(
                                          ".jpeg"
                                        ) ||
                                        x?.attachment[0]?.attachment_path?.includes(
                                          ".png"
                                        ) ||
                                        x?.attachment[0]?.attachment_path?.includes(
                                          ".PNG"
                                        ) ||
                                        x?.attachment[0]?.attachment_path?.includes(
                                          ".gif"
                                        ) ? (
                                        <></>
                                      ) : // <img
                                        //   // className="home-newsimg"
                                        //   width="80px"
                                        //   height="80px"
                                        //   src={
                                        //     x?.attachment[0]?.attachment_path
                                        //   }
                                        // ></img>
                                        x?.attachment[0]?.attachment_path?.includes(
                                          ".pdf"
                                        ) ? (
                                          <></>
                                        ) : (
                                          // <div class="col-sm-1 col-3 pr-0">
                                          //   <iframe
                                          //     width="100px"
                                          //     height="100px"
                                          //     src={
                                          //       x?.attachment[0]?.attachment_path
                                          //     }
                                          //   ></iframe>
                                          // </div>
                                          <></>
                                          // <video
                                          //   onClick={() =>
                                          //     setVideoLink(
                                          //       x?.attachment[0]?.attachment_path
                                          //     )
                                          //   }
                                          //   className="home-newsimg"
                                          //   src={
                                          //     x?.attachment[0]?.attachment_path
                                          //   }
                                          // ></video>
                                        )}
                                    </div>
                                  </div>
                                ) : null}
                                {x.description !== null ? (
                                  <p
                                    onClick={() => viewNotice(x)}
                                    class="noticetxtnew"
                                  >
                                    {x?.description
                                      .replaceAll("<p>", "")
                                      .replaceAll("</p>", "")}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </Marquee>
                            </>
                          ))
                        ) : (
                          <div
                            class="text-center"
                            style={{
                              borderTop: "#4A9BD4 1px solid",
                              position: "sticky",
                            }}
                          >
                            <p class="pt-2 mb-0 f-15 text-muted">
                              <strong>No notice available to read.</strong>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {value.name === "Approvals & Assignments" ? (
                <div className="col-lg-6">
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <h2 className="mainpage-head">Approvals & Assignments</h2>
                    </div>
                  </div>
                  <>
                    <div
                      className="approval-bg apphomeheadscroll"
                      style={{ height: "193px" }}
                    >
                      {countFlag && (
                        <SuccessFactor
                          assignment={assignment}
                          approvalsettings={approvalsettings}
                          setAssignment={setAssignment}
                          setTabStatus={setTabStatus}
                          tabStatus={tabStatus}
                          setShowForm={setShowForm}
                          showForm={showForm}
                          grcData={grcData}
                          servicesFact={servicesFact}
                          successfact={successfact}
                          todoComFact={todoComFact}
                          todoCapexFact={todoCapexFact}
                          todoGRCFact={todoGRCFact}
                          urlValue={urlValue}
                          setRoundCount={setRoundCount}
                        />
                      )}
                      {/* ) : (
                        <div class="text-center">
                          <img
                            src="images/no-found.png"
                            alt=""
                            style={{ height: "145px", margin: "0 auto" }}
                          />
                          <p class="mt-2 f-15 text-muted">
                            <strong>
                              No approvals & assignments are pending.
                            </strong>
                          </p>
                        </div>
                      )} */}
                    </div>
                  </>
                </div>
              ) : (
                ""
              )}

              {/* {value.name === "MyPi Applications" ? (
                <div className="col-lg-6">
                  <div className="d-flex justify-content-between align-items-end">
                    <h2 className="mainpage-head">MyPi Applications</h2>
                  </div>
                  <div className="application-bg">
                    {extDashboardData?.MYPI_APP?.length !== 0 ? (
                      <Slider className="clientslider" {...applicasettings}>
                        {extDashboardData?.MYPI_APP?.map((item, idx) => (
                          <div key={`myPi_${idx}`}>
                            <div className="applicationTxt">
                              <a href={item?.url} class="d-block">
                                <div className="apppplinner">
                                  <img src={item?.icon_url} alt="" />
                                  <p>{item?.name}</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <div class="text-center">
                        <img
                          src="images/no-found.png"
                          alt=""
                          style={{ height: "75px", margin: "0 auto" }}
                        />
                        <p class="mt-2 f-15 text-muted">
                          <strong>No Mypi application available.</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )} */}

              {value.name === "External Applications" ? (
                <div className="col-lg-11">
                  <div className="d-flex justify-content-between align-items-end">
                    <h2 className="mainpage-head">My Quick Links</h2>
                  </div>
                  <div className="application-bg">
                    {extDashboardData?.EXT_APP?.length !== 0 ? (
                      <Slider className="clientslider" {...applicasettings}>
                        {arrayDataValue &&
                          arrayDataValue
                            ?.filter((each) => {
                              if (each?.name === "ClickIPR") {
                                return true;
                              }
                              let permission = each?.permissions?.filter(
                                (each) => {
                                  if (each?.code === "V") {
                                    return true;
                                  }
                                }
                              );
                              if (permission?.length > 0) {
                                return true;
                              }
                            })
                            .map((item, idx) => (
                              <CreateUrl item={item} idx={idx} />
                            ))}
                      </Slider>
                    ) : (
                      <div class="text-center">
                        <img
                          src="images/no-found.png"
                          alt=""
                          style={{ height: "75px", margin: "0 auto" }}
                        />
                        <p class="mt-2 f-15 text-muted">
                          <strong>No applications available.</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {value.name === "External Applications" ? (
                <div class="col-lg-1 mt-4">
                  <div
                    className={
                      isoffShown
                        ? "d-none d-md-block"
                        : "d-none d-md-block officeboxapphover"
                    }
                    onClick={office365Open}
                  >
                    <div class="application-bg officebox">
                      <img src="images/office-icon.png" alt="" />
                      <span>
                        Office
                        <br />
                        365
                      </span>
                    </div>
                    <div class="blackoverlay" onClick={office365Open}>
                      <OfficeApp></OfficeApp>
                    </div>
                  </div>
                  {/* <div class="d-flex justify-content-between align-items-end">
                    <h2 class="mainpage-head">Microsoft 365</h2>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="application-bg officesliders">
                        <Slider className="clientslider" {...approvalsettings}>
                          {extDashboardData?.O365?.map((item, idx) =>
                            item.url_type === "EXTR" ? (
                              <div>
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src={item?.icon_url} alt="" />
                                </a>
                              </div>
                            ) : (
                              <div>
                                <a href={item?.url}>
                                  <img src={item?.icon_url} alt="" />
                                </a>
                              </div>
                            )
                          )}
                        </Slider>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}

              {value?.name === "Meetings" ? (
                <>
                  <div class="col-lg-6">
                    <h2 class="mainpage-head">My Calendar</h2>
                    <div class="calendar-bg teambg-height">
                      <div class="row h-100">
                        <div class="col-md-6 calendorbox-border-right">
                          <FullCalendar
                            headerToolbar={{
                              start: "title prev next",
                              center: "",
                              end: "",
                            }}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            events={eventsData}
                            selectable={true}
                            datesSet={(e) => {
                              handleMonthChange(e);
                            }}
                            dateClick={(e) => {
                              setDateTimeValue(e.dateStr);
                              setInterval(() => {
                                if (pathname == "/dashboard") {
                                  handleDateClick(e.dateStr);
                                }
                              }, 1000 * 60 * 3);
                            }}
                          />

                          <h2 class="mainpage-head mt-3 text-dark">Holidays</h2>
                          {holidayData?.length > 0 ? (
                            <div class="holidaylistscroll" id="scrollpart">
                              {holidayData
                                ?.filter((obj) => {
                                  if (
                                    moment(obj?.day_date).isAfter(
                                      moment(new Date())
                                    ) ||
                                    moment(obj?.day_date).isSame(
                                      moment(new Date())
                                    )
                                  ) {
                                    return true;
                                  }
                                })
                                .map((item) => (
                                  <div class="row align-items-center">
                                    <div class="col-2 holiday-time">
                                      <h4>
                                        {moment(item?.day_date).format("DD")}
                                      </h4>
                                      <p>
                                        {moment(item?.day_date).format("MMMM")}
                                      </p>
                                    </div>
                                    <div class="col-10 pl-0">
                                      <div
                                        class={
                                          item?.type === "mandatory"
                                            ? "holiday-bg"
                                            : "holiday-bg-opt"
                                        }
                                      >
                                        <h4>{item?.name}</h4>
                                        <p>
                                          <i class="fas fa-map-marker-alt mr-1"></i>
                                          {item?.type}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <div
                              class="text-center"
                              style={{
                                marginTop: "40px",
                              }}
                            >
                              <p class="mt-2 f-15 text-muted">
                                <strong>No holidays in this month.</strong>
                              </p>
                            </div>
                          )}
                        </div>
                        <div class="col-md-6">
                          <div class="calhomtime">
                            <h4>{moment(dateTimeValue).format("DD")}</h4>
                            <p>{moment(dateTimeValue).format("dddd")}</p>
                          </div>
                          <div class="event-sectionhome" id="scrollpartmeeting">
                            {isLoading ? (
                              <Spinner
                                animation="border"
                                variant="primary"
                                as="span"
                                role="status"
                                aria-hidden="true"
                              ></Spinner>
                            ) : meetingData.length !== 0 ? (
                              meetingData?.map((items) => (
                                <div class="calhomeevent">
                                  <div class="fc-eventbox">
                                    <a
                                      href={items?.onlineMeeting?.joinUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <h4
                                        class="text-theme fw-bold"
                                        style={{ fontSize: "15px" }}
                                      >
                                        {items?.subject}
                                      </h4>
                                      <h6>
                                        {
                                          items?.organizer?.emailAddress
                                            ?.address
                                        }
                                      </h6>
                                      <div class="meetingnewdesbox">
                                        <div class="roletoreluser">
                                          <h5>
                                            {moment(
                                              items?.start?.dateTime
                                            ).format("hh:mm A")}{" "}
                                            to{" "}
                                            {moment(
                                              items?.end?.dateTime
                                            ).format("hh:mm A")}
                                          </h5>
                                        </div>
                                        <AvatarGroup
                                          total={items?.attendees.length}
                                          max={3}
                                          class="meetingavtarimge d-flex team-multiple"
                                        >
                                          {items?.attendees?.map((value) => (
                                            <Avatar
                                              alt="Remy Sharp"
                                              src={value?.image === "" && avtar}
                                              class="team-profile"
                                            />
                                          ))}
                                        </AvatarGroup>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div
                                class="text-center"
                                style={{
                                  marginTop: "100px",
                                }}
                              >
                                <img
                                  src="images/no-found.png"
                                  alt=""
                                  style={{ height: "130px", margin: "0 auto" }}
                                />
                                <p class="mt-2 f-15 text-muted">
                                  <strong>No meetings are available.</strong>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {value.name === "My Team & Attendance" ? (
                <div className="col-lg-6">
                  <h2 class="mainpage-head">My Team & Attendance</h2>
                  <div className="teambg teambg-height d-flex flex-column">
                    <div className="teamtab-labels">
                      <div>
                        <span class="present"></span> Present
                      </div>
                      <div>
                        <span class="absent"></span>
                        Leave
                      </div>
                      <div>
                        <span class="no-punch"></span> Absent
                      </div>
                      <div>
                        <span class="holidayweek"></span> Holiday / Week off
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-end teamheadsection">
                      {/* <h2 className="mainpage-head mt-0 mb-3 text-dark">
                        <Link to="/attendance" style={{ color: "inherit" }}>
                          {" "}
                          My Team & Attendance
                        </Link>
                      </h2> */}
                    </div>

                    <div class="teamscroll" id="scrollpartmeeting">
                      {attendance?.length > 0 ? (
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="table myteam-table"
                        >
                          <tr>
                            <th>Member</th>
                            <th width="700px">Punch In</th>
                            <th width="20px">-</th>
                            <th width="100px">Punch Out</th>
                            {/* <th width="70px">Leave</th> */}
                          </tr>
                          {attendance?.map(
                            (lists, index) =>
                              lists?.full_name !== null && (
                                <tr
                                  class={
                                    lists?.attendances?.length > 0
                                      ? lists?.attendances?.map((items) => {
                                        let statusLaeve =
                                          items.status.slice(-1);
                                        return items?.status === "P"
                                          ? "present"
                                          : items?.status === "A"
                                            ? "unknwn"
                                            : items?.status === "T"
                                              ? "present"
                                              : statusLaeve === "L"
                                                ? "absent"
                                                : statusLaeve === "WO"
                                                  ? "WeekOff"
                                                  : "unknwn";
                                      })
                                      : "hld"
                                  }
                                >
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <div>
                                        <div class="team-profile">
                                          <img
                                            src={
                                              lists?.image === "" &&
                                                lists?.gender === "M"
                                                ? avtar
                                                : lists?.image === "" &&
                                                  lists?.gender === "F"
                                                  ? femaleAvatr
                                                  : lists?.image === "" &&
                                                    lists?.gender === null
                                                    ? avtar
                                                    : `data:image/gif;base64,${lists?.image}`
                                            }
                                            alt=""
                                          />
                                          <i class="fas fa-circle"></i>
                                        </div>
                                      </div>
                                      <div class="teamtxt">
                                        <Link
                                          to={`/attendance/${btoa(
                                            lists?.userId
                                          )}`}
                                          state={{
                                            from: "dashboard",
                                            user_id:
                                              lists?.attendances?.length > 0 &&
                                              lists?.userId,
                                          }}
                                          class="text-theme"
                                        >
                                          <h5>{lists?.full_name}</h5>
                                          {lists?.leave_dates !== "" &&
                                            lists?.leave_dates !== null && (
                                              <p>
                                                <span>Upcoming Leave :</span>
                                                {lists?.leave_dates}
                                              </p>
                                            )}
                                            {lists?.travel_dates !== "" &&
                                            lists?.travel_dates !== null && (
                                              <p>
                                                <span>Upcoming Travel :</span>
                                                {lists?.travel_dates}
                                              </p>
                                            )}
                                          {/* <p>
                                            <span>Upcoming Travel :</span> 23,
                                            24, 25, 27
                                          </p> */}
                                        </Link>
                                        {/* <p>{lists?.email}</p> */}
                                      </div>
                                    </div>
                                  </td>
                                  {lists?.attendances.length > 0 ? (
                                    lists?.attendances?.map((values, index) => (
                                      <>
                                        <td>
                                          <p class="teamtime">
                                            {" "}
                                            {values?.in_time === null ||
                                              values?.in_time === undefined
                                              ? ""
                                              : values?.in_time}
                                          </p>
                                        </td>
                                        <td>-</td>
                                        <td>
                                          <p class="teamtime">
                                            {" "}
                                            {values?.out_time === null ||
                                              values?.out_time === undefined
                                              ? ""
                                              : values?.out_time}
                                          </p>
                                        </td>
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      <td>
                                        <p class="teamtime"></p>
                                      </td>
                                      <td>-</td>
                                      <td>
                                        <p class="teamtime"></p>
                                      </td>
                                      <td>
                                        <p class="teamtime f-12">
                                          {/* <strong>{lists?.leave_dates}</strong> */}
                                        </p>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              )
                          )}
                        </table>
                      ) : (
                        <div class="text-center mt-4">
                          <img src="images\team&Attendance.png" alt="" />
                          <p class="mt-2 f-15 text-muted">
                            <strong>No team & attendance.</strong>
                          </p>
                        </div>
                      )}
                    </div>
                    <div class="morebtn mt-auto text-right mr-2">
                      <Link
                        class="text-theme fw-bold"
                        to={`/attendance/${btoa(userData?.id)}`}
                      >
                        {" "}
                        See More <i class="far fa-arrow-alt-circle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {value.name === "Mandatory Courses & Trainings" ? (
                <div className="col-lg-4">
                  <h2 className="mainpage-head text-dark">
                    Mandatory Courses & Trainings
                  </h2>

                  <div className="bottomboxbg py-3 px-2">
                    <div className="traitabs">
                      <Tabs defaultActiveKey="course" id="coursetabs">
                        <Tab eventKey="course" title="Mandatory">
                          <div
                            className="homcousesecnew"
                            id="scrollpartmeeting"
                          >
                            <>
                              {coursesDataValue.length > 0 ? (
                                coursesDataValue.map((value) => (
                                  <div className="homecoussec">
                                    <a
                                      href={value?.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div className="row">
                                        <div className="col-md-4 col-5">
                                          <img
                                            src={
                                              value?.name?.includes(
                                                "Code of Conduct"
                                              )
                                                ? "images/coc_course.png"
                                                : value?.name?.includes(
                                                  "Cyber Security"
                                                )
                                                  ? "images/cyber_course.png"
                                                  : value?.name?.includes("PoSH")
                                                    ? "images/posh.png"
                                                    : ""
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="col-md-8 col-7 pl-0 d-flex flex-column">
                                          <h4>{value?.name}</h4>
                                          <h5>{value?.statusLabel}</h5>
                                          <div className="d-flex mt-auto justify-content-between align-items-end">
                                            <div></div>
                                            <div>
                                              <span>
                                                {/* {item?.days_left} days Left */}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <div class="text-center">
                                  <img src="images/no-found.png" alt="" />
                                  <p class="mt-2 f-15 text-muted">
                                    <strong>No mandatory Courses found.</strong>
                                  </p>
                                </div>
                              )}
                            </>
                          </div>
                        </Tab>

                        <Tab eventKey="training" title="Optional">
                          <div
                            className="homcousesecnew"
                            id="scrollpartmeeting"
                          >
                            <>
                              {courseValueMandt.length > 0 ? (
                                courseValueMandt?.map((item) => (
                                  <div className="homecoussec">
                                    <a
                                      href={item?.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <div className="row">
                                        <div className="col-md-4 col-5">
                                          <img
                                            src="images/coc_course.png"
                                            alt=""
                                          />
                                        </div>
                                        <div className="col-md-8 col-7 pl-0 d-flex flex-column">
                                          <h4>{item?.name}</h4>
                                          <h5>{item?.statusLabel}</h5>
                                          <div className="d-flex mt-auto justify-content-between align-items-end">
                                            <div></div>
                                            <div>
                                              <span>
                                                {/* {item?.days_left} days Left */}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <div class="text-center">
                                  <img src="images/no-found.png" alt="" />
                                  <p class="mt-2 f-15 text-muted">
                                    <strong>No Courses found.</strong>
                                  </p>
                                </div>
                              )}
                            </>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {value.name === "Activity History" ? (
                <div className="col-lg-8">
                  <h2 className="mainpage-head text-dark">Activity History</h2>

                  <div className="bottomboxbg p-3">
                    <div className="timescrollsec" id="scrollpartmeeting">
                      {activityData?.map((data) => (
                        <div className="timelines">
                          <h4>
                            {data?.transaction?.message}
                            <span>
                              {moment(data?.created_at_date).format(
                                "DD MMM YYYY"
                              )}{" "}
                              &nbsp;
                              {moment(
                                data?.created_at_datetime,
                                "hh:mm:ss"
                              ).format("HH:mm")}
                            </span>
                          </h4>
                          <div className="timelinecontent-sec">
                            <div className=" ">
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="team-profile">
                                    <img
                                      src={
                                        data?.profile_pic === "" &&
                                          data?.gender === "M"
                                          ? avtar
                                          : data?.profile_pic === "" &&
                                            data?.gender === "M"
                                            ? femaleAvatr
                                            : `data:image/gif;base64,${data?.profile_pic}`
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="teamtxt">
                                  <h5 className="mb-0 text-dark mw-100">
                                    {data?.created_by}
                                  </h5>
                                  <p>
                                    {moment(data?.created_at_date).format(
                                      "DD MMM"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {value.name === "Anniversary" ? (
                <div class="col-lg-4">
                  <h2 class="mainpage-head text-dark">&nbsp;</h2>
                  <div class="birthday-bg anniversry-bg">
                    <div class="d-flex birhead">
                      <div>
                        <img
                          src="images/workicon.png"
                          alt=""
                          style={{ height: "25px" }}
                        />
                      </div>
                      <div>
                        <h4>Work Anniversary</h4>
                        <h5>{moment(currentDate).format("MMM YYYY")}</h5>
                      </div>
                      <img
                        src="images/work-ani.png"
                        alt=""
                        class="brthbgimage"
                        style={{
                          height: "93px",
                          top: "-30px",
                          marginRight: "14px",
                        }}
                      />
                    </div>

                    <div class="birgday-listing" id="scrollpartmeeting">
                      {anniversaryData.length == 0 ? (
                        <>
                          <div class="text-center">
                            <img src="images/no-found.png" alt="" />
                            <p class="mt-2 f-15 text-muted">
                              <strong>No work anniversary for today.</strong>
                            </p>
                          </div>
                        </>
                      ) : (
                        <table class="table">
                          {anniversaryData?.map((items) =>
                            items?.name === "" ? (
                              ""
                            ) : (
                              <tr>
                                <td>
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <div class="team-profile">
                                        <img
                                          src={
                                            items?.image === "" &&
                                              items?.user_gender === "M"
                                              ? avtar
                                              : items?.image === "" &&
                                                items?.user_gender === "F"
                                                ? femaleAvatr
                                                : `data:image/gif;base64,${items?.image}`
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div class="teamtxt">
                                      <h5>{items?.name}</h5>
                                      <p>{items?.department_name}</p>
                                      <p>{items?.location_name}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h6>{moment(items?.dob).format("Do MMM")}</h6>
                                </td>
                                {items.wished === false ? (
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        setShowAnniversaryForm(true);
                                        setAnniversaryReviewData(items);
                                      }}
                                    >
                                      wish
                                    </button>
                                  </td>
                                ) : (
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      disabled
                                    >
                                      wished
                                    </button>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {value.name === "Birthday" ? (
                <>
                  <div className="col-lg-4">
                    <h2 className="mainpage-head text-dark">&nbsp;</h2>
                    <div className="birthday-bg">
                      <div className="d-flex birhead">
                        <div>
                          <img src="images/bthicon.png" alt="" />
                        </div>
                        <div>
                          <h4>Birthday</h4>
                          <h5>{moment(currentDate).format("MMM YYYY")}</h5>
                        </div>
                        <img
                          src="images/brthdaycake.png"
                          alt=""
                          className="brthbgimage"
                        />
                      </div>

                      <div className="birgday-listing" id="scrollpartmeeting">
                        {birthdayData.length == 0 ? (
                          <>
                            <div class="text-center">
                              <img src="images/no-found.png" alt="" />
                              <p class="mt-2 f-15 text-muted">
                                <strong>No birthday for today.</strong>
                              </p>
                            </div>
                          </>
                        ) : (
                          <table className="table ">
                            {birthdayData?.map((items) => (
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div className="team-profile">
                                        <img
                                          src={
                                            items?.image === "" &&
                                              items?.user_gender === "M"
                                              ? avtar
                                              : items?.image === "" &&
                                                items?.user_gender === "F"
                                                ? femaleAvatr
                                                : `data:image/gif;base64,${items?.image}`
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="teamtxt">
                                      <h5>{items?.name}</h5>
                                      <p>{items?.department_name}</p>
                                      <p>{items?.location_name}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h6>{moment(items?.dob).format("Do MMM")}</h6>
                                </td>
                                {items.wished === false ? (
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        filtersetShow(true);
                                        setBirthdayReviewData(items);
                                      }}
                                    >
                                      wish
                                    </button>
                                  </td>
                                ) : (
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      disabled
                                    >
                                      wished
                                    </button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : value.name === "Surveys" ? (
                <div class="col-lg-4">
                  <h2 class="mainpage-head text-dark">Surveys & Feedback</h2>
                  <div class="bottomboxbg py-3 px-2">
                    <div class="traitabs">
                      <div
                        class="homcousesecnew newsurveyheight"
                        id="scrollpart"
                      >
                        {surveyData?.length > 0 ? (
                          surveyData?.map((each) => (
                            <>
                              <h6 class="text-theme text-left fw-bold f-1">
                                {each.category_name}
                              </h6>
                              <div
                                class="homecoussec"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  each.category_name === "Quiz"
                                    ? openQuiz(each)
                                    : navigate("/survey-assessment", {
                                      state: {
                                        surveyId: each?.id,
                                        category: each?.assessment_type_id,
                                      },
                                    });
                                }}
                              >
                                <div class="row">
                                  <div class="col-md-4 col-5">
                                    <img
                                      src="images/quarterly report-white.png"
                                      style={{
                                        height: "63px",
                                        width: "57px",
                                      }}
                                      alt=""
                                    />
                                  </div>

                                  <div class="col-md-8 col-7 pl-0 d-flex flex-column">
                                    <h4 className="fw-bold">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: each?.title,
                                        }}
                                      ></div>
                                    </h4>
                                    <h5>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: each?.description,
                                        }}
                                        className="editor-style"
                                      ></div>
                                    </h5>

                                    <div class="d-flex mt-auto justify-content-between align-items-end">
                                      <div>
                                        <h6>&nbsp;</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <div class="text-center">
                            <img src="images/no-found.png" alt="" />
                            <p class="mt-2 f-15 text-muted">
                              <strong>No Surveys & Feedback found.</strong>
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="morebtn mt-auto text-right mr-2">
                        <Link
                          class="text-theme fw-bold"
                          to="/survey-assessment"
                        >
                          See More <i class="far fa-arrow-alt-circle-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </div>

      <Modal
        show={initailCase}
        onHide={() => {
          localStorage?.setItem("initial", true);
          setInitialCase(false);
        }}
        size="sm"
        centered
        className="videopopup modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          {/* <iframe
            src={url}
            allowFullScreen
            style={{
              width: "100%",
              height: "100%",
            }}
            hidden
          ></iframe> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={showExternal}
        onHide={handleCloseExternal}
        size="lg"
        centered
        className="videopopup modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <iframe width="800" height="650" src={video}></iframe>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered
        className="videopopup modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          {video?.includes(".mp4") ? (
            <video controls>
              <source width="100%" height="100%" src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : video?.includes(".jpg") ||
            video?.includes(".jpeg") ||
            video?.includes(".png") ||
            video?.includes(".PNG") ||
            video?.includes(".gif") ? (
            <img width="100%" height="100%" src={video}></img>
          ) : video?.includes("youtube") ? (
            <iframe width="800" height="450" src={video}></iframe>
          ) : video?.includes(".pdf") ? (
            <div class="col-sm-1 col-3 pr-0">
              <iframe
                // width="100%"
                // height="75"
                src={video}
              ></iframe>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal
        show={viewnewshow}
        onHide={() => {
          postPopup(OneNotice?.id);
          viewnewclose();
        }}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter withouthead"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center brandingcrousel">
            <div class="col-md-11">
              <div class="row news-detail news-detail-marq text-center">
                <div class="col-md-12">
                  <h2>{OneNotice?.title}</h2>
                </div>
                <div class="col-md-12 dashNews">
                  <ReactQuill
                    value={OneNotice?.description}
                    readOnly
                    theme="bubble"
                  />
                </div>
                <div class="col-md-12">
                  <div class="row justify-content-center">
                    <div class="col-11">
                      {/* <Slider className="clientslider" {...approvalsettings}> */}
                      {OneNotice.external !== null ? (
                        <Carousel>
                          <div>
                            {OneNotice.external !== null && (
                              <iframe
                                style={{ marginLeft: "0%" }}
                                width="900"
                                height="300"
                                src={OneNotice.external}
                              ></iframe>
                            )}
                          </div>

                          {OneNotice.attachment?.map((x) => (
                            <a
                              href={x?.attachment_path}
                              target="_blank"
                              id="jai"
                              style={{}}
                              rel="noreferrer"
                            >
                              {x?.attachment_path?.includes(".mp4") ? (
                                <div>
                                  <video
                                    width="100%"
                                    height="100%"
                                    style={{ marginLeft: "0%" }}
                                    controls
                                  >
                                    <source
                                      src={x?.attachment_path}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              ) : x?.attachment_path?.includes(".jpg") ||
                                x?.attachment_path?.includes(".jpeg") ||
                                x?.attachment_path?.includes(".png") ||
                                x?.attachment_path?.includes(".gif") ? (
                                <img
                                  src={x?.attachment_path}
                                  style={{ width: "650px", height: "100%" }}
                                ></img>
                              ) : x?.attachment_path?.includes(".pdf") ? (
                                <div class="col-sm-1 col-3 pr-0">
                                  <iframe
                                    width="400"
                                    height="400"
                                    src={x?.attachment_path}
                                  ></iframe>
                                </div>
                              ) : (
                                <iframe
                                  style={{ marginLeft: "-76%" }}
                                  width="350"
                                  height="300"
                                  src={OneNotice.external}
                                ></iframe>
                              )}
                            </a>
                          ))}
                        </Carousel>
                      ) : (
                        <Carousel>
                          {OneNotice.attachment?.map((x) => (
                            <a
                              href={x?.attachment_path}
                              target="_blank"
                              id="jai"
                              style={{}}
                              rel="noreferrer"
                            >
                              {x?.attachment_path?.includes(".mp4") ? (
                                <div>
                                  <video
                                    width="100%"
                                    height="100%"
                                    style={{ marginLeft: "0%" }}
                                    controls
                                  >
                                    <source
                                      src={x?.attachment_path}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              ) : x?.attachment_path?.includes(".jpg") ||
                                x?.attachment_path?.includes(".jpeg") ||
                                x?.attachment_path?.includes(".png") ||
                                x?.attachment_path?.includes(".gif") ? (
                                <img
                                  src={x?.attachment_path}
                                  style={{ width: "650px", height: "100%" }}
                                  alt=""
                                ></img>
                              ) : x?.attachment_path?.includes(".pdf") ? (
                                <div class="">
                                  <iframe
                                    width="400"
                                    height="400"
                                    src={x?.attachment_path}
                                  ></iframe>
                                </div>
                              ) : (
                                <iframe
                                  style={{ marginLeft: "-76%" }}
                                  width="350"
                                  height="300"
                                  src={OneNotice.external}
                                ></iframe>
                              )}
                            </a>
                          ))}
                        </Carousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showQuiz}
        onHide={closeQuiz}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This Quiz will be auto submitted in {quizData?.duration}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={closeQuiz}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              navigate("/survey-assessment", {
                state: {
                  surveyId: quizData?.id,
                  category: quizData?.assessment_type_id,
                },
              });
            }}
          >
            Proceed
          </button>
        </Modal.Footer>
      </Modal>
      {filtershow && (
        <ReviewModal
          birthdayReviewData={birthdayReviewData}
          filteropen={filteropen}
          filtersetShow={filtersetShow}
          filterclose={filterclose}
          getBirthdayList={getBirthdayList}
        />
      )}

      {showAnniversaryForm && (
        <AnniversaryModal
          anniversaryReviewData={anniversaryReviewData}
          formopen={formopen}
          setShowAnniversaryForm={setShowAnniversaryForm}
          formclose={formclose}
          getWorkAnniversaryList={getWorkAnniversaryList}
        />
      )}
      <AddEsop
        showForm={showForm}
        onClose={onClose}
        esopData={esopData}
        approvalsettings={approvalsettings}
      />
      <AddGrc
        showForm1={showForm1}
        onClose={onClose}
        grcData={grcData}
        approvalsettings={approvalsettings}
      />
    </>
  );
}

export default Dashboard;
