import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import MaterialRecord from "./MaterialRecord";
import { getCostCenter, getPlantListByUser } from "../../service/jv_service";
import {
  addMaterialReservation,
  getMaterialReservations,
  deleteMaterialReservation,
  updateMaterialReservation,
  getExportValue,
} from "./reservationService";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission, selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import { useNavigate } from "react-router-dom";
import { getMateriallistDrop } from "../../service/source-list";


function ReservationReport(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [addNew, showAddNew] = useState(false);
  const openAddNew = () => showAddNew(true);
  const closeAddNew = () => showAddNew(false);
  const [index, setIndex] = useState(1);
  const [recordArr, setRecordArr] = useState([]);
  const [view, setView] = useState(false);
  const [errors, setErrors] = useState({
    selectMaterial: false,
    enterQuantity: false,
    enterDesc: false,
    enterDate: false,
    selectPlant: false,
    selectCostCenter: false,
    addMaterial: false,
    quantityLen: false,
  });
  const [plantOptions, setPlantOptions] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [reservationDataFilter, setreservationDataFilter] = useState({
    status: true,
    from: "",
    to: "",
    type: "",
    sap_status: "",
  });
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [reservationData, setReservationData] = useState([]);
  const [reservationList, setReservationList] = useState([]);
  const [currentReservation, setCurrentReservation] = useState({});
  const [editRecord, setEditRecord] = useState(false);
  const userData = useSelector(selectUserData);
  let condition = "true";

  const handleKey = (search, filter, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      queryParm = queryParm + "&status=" + filter.status;
    }
    if (filter?.to !== "" && filter?.to !== undefined && filter?.to !== null) {
      queryParm = queryParm + "&end_date=" + filter?.to;
    }
    if (
      filter?.from !== "" &&
      filter?.from !== undefined &&
      filter?.from !== null
    ) {
      queryParm = queryParm + "&start_date=" + filter?.from;
    }
    if (
      filter?.type !== "" &&
      filter?.type !== undefined &&
      filter?.type !== null
    ) {
      queryParm = queryParm + "&type_is=" + filter?.type;
    }
    if (
      filter?.sap_status !== "" &&
      filter?.sap_status !== undefined &&
      filter?.sap_status !== null
    ) {
      queryParm = queryParm + "&sap_status=" + filter?.sap_status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    queryParm = queryParm + "&report=" + true;
    return queryParm;
  };

  useEffect(() => {
    if (userData?.id !== undefined) {
      getData();
      getReservations(
        searchStr,
        reservationDataFilter,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  }, [userData]);

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getReservations(
      searchStr,
      reservationDataFilter,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  const getReservations = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    await getMaterialReservations(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response.data.code === 200) {
          setReservationList(response?.data?.dataList?.result);
          setReservationData(response?.data?.dataList?.paginated_data);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = async () => {
    await getPlant();
    await getCostCenterList();
  };

  const getPlant = async () => {
    await getPlantListByUser()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.description} (${obj?.code})`,
          };
        });
        setPlantOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCostCenterList = async () => {
    await getCostCenter(userData?.organisation_id)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj.name} (${obj.code})`,
          };
        });
        setCostCenterOptions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMaterialList = async (plantId, isUpdate, curObj, search) => {
    setMaterialList([]);
    let object = {
      plant_id: plantId,
      search: search === undefined ? "" : search,
    };
    await getMateriallistDrop(object)
      .then((response) => {
        let res = response?.data?.dataList?.result
          .filter((x) => {
            if (isUpdate) {
              if (
                curObj?.materials
                  ?.map((obj) => {
                    return obj?.material_id;
                  })
                  .includes(x?.value)
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          })
          .map((obj) => {
            return {
              key: obj?.value,
              value: obj?.value,
              label: obj?.label,
              description: obj?.name,
            };
          });
        setMaterialList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [formObj, setFormObj] = useState({
    plant: "",
    costCenter: "",
    reservationDate: null,
    materialList: [],
  });

  const [materialObj, setMaterialObj] = useState({
    materialName: "",
    quantity: "",
    description: "",
  });

  const addRecord = (count) => {
    if (
      errors.enterDesc !== true &&
      errors.enterQuantity !== true &&
      errors.selectMaterial !== true &&
      errors.quantityLen !== true &&
      materialObj.materialName &&
      materialObj.quantity !== "" &&
      materialObj.description !== ""
    ) {
      const rowData = {
        ...materialObj,
        item_no: count,
        count: index + 1,
      };
      setRecordArr([...recordArr, rowData]);
      setErrors((prev) => ({
        ...prev,
        addMaterial: false,
      }));
      setFormObj((prev) => ({
        ...prev,
        materialList: [...recordArr, rowData],
      }));
      setIndex(count + 1);
      setMaterialObj({
        materialName: "",
        quantity: "",
        description: "",
      });
      setErrors({
        selectMaterial: false,
        enterQuantity: false,
        enterDesc: false,
      });
    } else {
      setErrors((prev) => ({
        ...prev,
        selectMaterial: materialObj.materialName !== "" ? false : true,
        enterQuantity: materialObj.quantity === "" ? true : false,
        enterDesc: materialObj.description === "" ? true : false,
      }));
    }
  };

  const removeRecord = (index) => {
    let resultArr = recordArr
      .filter((obj) => {
        if (!editRecord && obj.count !== index) {
          return obj;
        } else if (editRecord && obj.count !== index - 1) {
          return obj;
        }
      })
      .map((obj) => obj);
    resultArr?.map((x, i) => {
      x.count = i + 2;
    });
    setRecordArr(resultArr);
    setFormObj((prev) => ({
      ...prev,
      materialList: resultArr,
    }));
    setIndex(index - 1);
  };

  const handleChange = (event, name, isEdit) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "material":
        let check = recordArr?.filter((obj) => {
          if (obj?.materialName?.value === event?.value) {
            return true;
          }
        });
        if (check.length > 0) {
          toast.warning("Material already added.");
        } else {
          setMaterialObj((prev) => ({
            ...prev,
            materialName: event,
            description: event.description,
          }));
          setErrors((prev) => ({
            ...prev,
            selectMaterial: false,
            enterDesc: false,
          }));
          setErrors((prev) => ({
            ...prev,
            selectMaterial: false,
          }));
        }
        break;
      case "quantity":
        if (
          event?.target?.value.length < 6 &&
          (event?.target?.value > 0 || event?.target?.value === "")
        ) {
          if (isEdit !== undefined) {
            handleUpdate(event, event.target.name, isEdit);
          } else {
            setMaterialObj((prev) => ({
              ...prev,
              quantity:
                event.target.value !== "" ? Number(event.target.value) : "",
            }));
            setErrors((prev) => ({
              ...prev,
              enterQuantity: false,
            }));
          }
          setErrors((prev) => ({
            ...prev,
            quantityLen: false,
          }));
          break;
        } else {
          setErrors((prev) => ({
            ...prev,
            quantityLen: true,
          }));
          break;
        }
      case "reservationDate":
        setFormObj((prev) => ({
          ...prev,
          reservationDate: event.target.value,
        }));
        setErrors((prev) => ({
          ...prev,
          enterDate: false,
        }));
        break;
      case "plant":
        setMaterialObj((prev) => ({
          ...prev,
          materialName: "",
          quantity: "",
          description: "",
        }));
        setFormObj((prev) => ({
          ...prev,
          plant: event,
        }));
        getMaterialList(event.value);
        setErrors((prev) => ({
          ...prev,
          selectPlant: false,
        }));
        break;
      case "cost-center":
        setFormObj((prev) => ({
          ...prev,
          costCenter: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectCostCenter: false,
        }));
      default:
        break;
    }
  };

  const handleUpdate = (event, name, count) => {
    let updatedData = [];
    switch (name) {
      case "desc":
        updatedData = recordArr.map((obj) => {
          if (obj.item_no === count - 1) {
            obj.description = event.target.value;
            return obj;
          } else {
            return obj;
          }
        });
        setRecordArr(updatedData);
        break;
      case "quantity":
        updatedData = recordArr.map((obj) => {
          if (obj.item_no === count - 1) {
            obj.quantity = Number(event.target.value);
            return obj;
          } else {
            return obj;
          }
        });
        setRecordArr(updatedData);
        break;
      default:
        break;
    }
  };

  const handleBlur = (event) => {
    let type = event.target.name;
    switch (type) {
      case "quantity":
        if (event.target.value === "") {
          setErrors((prev) => ({
            ...prev,
            enterQuantity: true,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            enterQuantity: false,
          }));
        }
        break;
      case "desc":
        if (event.target.value === "") {
          setErrors((prev) => ({
            ...prev,
            enterDesc: true,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            enterDesc: false,
          }));
        }
        break;
      case "reservationDate":
        if (event.target.value === "") {
          setErrors((prev) => ({
            ...prev,
            enterDate: true,
          }));
        }
      default:
        break;
    }
  };

  const resetForm = () => {
    setFormObj({
      plant: "",
      costCenter: "",
      reservationDate: null,
      materialList: [],
    });
    setMaterialObj({
      materialName: "",
      quantity: "",
      description: "",
    });
    setRecordArr([]);
    setErrors({
      selectMaterial: false,
      enterQuantity: false,
      enterDesc: false,
      enterDate: false,
      selectPlant: false,
      selectCostCenter: false,
    });
    setCurrentReservation({});
    setIndex(1);
  };

  const saveReservation = async () => {
    if (
      formObj.plant !== "" &&
      formObj.costCenter !== "" &&
      formObj.reservationDate !== null &&
      editRecord === false
    ) {
      if (materialObj?.materialName !== "" && materialObj?.quantity !== "") {
        if (condition === "true") {
          condition = "false";
          let obj = formObj;
          if (formObj?.materialList?.length === 0) {
            let curMaterial = materialObj;
            curMaterial.item_no = 1;
            curMaterial.count = index + 1;
            obj.materialList = [curMaterial];
          } else {
            let data = materialObj;
            data.item_no = obj.materialList.length + 1;
            data.count = index + 1;
            obj.materialList.push(data);
          }
          await addMaterialReservation(obj)
            .then((response) => {
              if (response?.data?.code === 200) {
                condition = "true";
                getReservations(
                  "",
                  reservationDataFilter,
                  currentPage,
                  pageSizeNo,
                  sortData.sort_by,
                  true
                );
                closeAddNew();
                resetForm();
                toast.success(response?.data?.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          enterQuantity: materialObj?.quantity === "" && true,
          selectMaterial: materialObj?.description === "" && true,
          addMaterial:
            materialObj?.quantity === "" &&
            materialObj?.description === "" &&
            true,
        }));
      }
    } else if (editRecord === true) {
      if (formObj?.materialList?.length > 0) {
        if (condition === "true") {
          condition = "false";
          await updateMaterialReservation(formObj, formObj.id)
            .then((response) => {
              if (response?.data?.code === 200) {
                condition = "true";
                getReservations(
                  "",
                  reservationDataFilter,
                  currentPage,
                  pageSizeNo,
                  sortData.sort_by,
                  true
                );
                closeAddNew();
                resetForm();
                toast.success(response?.data?.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          addMaterial: true,
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        selectPlant: formObj.plant === "" ? true : false,
        selectCostCenter: formObj.costCenter === "" ? true : false,
        enterDate: formObj.reservationDate === null ? true : false,
      }));
    }
  };

  const updateReservation = (object) => {
    setView(true);
    setEditRecord(true);
    setCurrentReservation(object);
    showAddNew(true);
  };

  const updateReservationData = (object) => {
    getMaterialList(object?.plant__id, true, object, "");
    setEditRecord(true);
    setView(false);
    setCurrentReservation(object);
    showAddNew(true);
  };

  useEffect(() => {
    let currentObj = JSON.parse(JSON.stringify(currentReservation));
    let items = currentObj?.materials?.map((obj, index) => {
      obj.count = index + 1;
      return obj;
    });
    if (items !== undefined) {
      setIndex(items?.length + 1);
      setFormObj((prev) => ({
        ...prev,
        id: currentObj.id,
        plant: {
          label: `${currentObj.plant__desc === null ? "" : currentObj.plant__desc
            } (${currentObj.plant__code})`,
          value: currentObj.plant__id,
        },
        costCenter: {
          label: `${currentObj.plant__desc === null ? "" : currentObj.plant__desc
            } (${currentObj.cost_center__code})`,
          value: currentObj.cost_center__id,
        },
        reservationDate: currentObj.reservation_date,
        materialList: items,
      }));
      setRecordArr(items);
    } else {
      setRecordArr([]);
    }
  }, [currentReservation]);

  const deleteRecord = (id, reservationNum) => {
    confirmAlert({
      title: "Delete Material Reservation",
      message: `Are you sure to delete the reservation ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteReservation(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteReservation = async (id) => {
    await deleteMaterialReservation(id)
      .then((response) => {
        if (response?.data?.code === 200) {
          closeAddNew();
          toast.success("Reservation Deleted Successfully");
          getReservations(
            "",
            reservationDataFilter,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterReservationData = () => {
    setFilterApplied(true);
    getReservations(
      searchStr,
      reservationDataFilter,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  const getExportDetails = (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    getExportValue(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "reservation.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    getReservations(
      e.target.value.toLowerCase(),
      reservationDataFilter,
      1,
      pageSizeNo,
      "id",
      true
    );
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReservations(
      searchStr,
      reservationDataFilter,
      activePage,
      pageSizeNo,
      "id",
      true
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getReservations(
      searchStr,
      reservationDataFilter,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="row">
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-9">
                  <h4 className="inner-page-title">Material Reservations</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Reservations</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    {/* {finalUrlValue?.D ? ( */}
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" className="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                {
                                  status: true,
                                  from: reservationDataFilter?.from,
                                  to: reservationDataFilter?.to,
                                },
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* ) : (
                      "" */}
                    {/* )} */}
                    <button
                      onClick={filteropen}
                      className="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                    {/* {finalUrlValue?.A ? ( */}
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="purchaseinnertabs">
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th
                        onClick={(e) => {
                          handlerData("plant__code");
                        }}
                        className={
                          sortData["plant__code"] === undefined
                            ? "unsortcls"
                            : sortData["plant__code"]
                        }
                      >
                        Plant
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("reservation_number");
                        }}
                        className={
                          sortData["reservation_number"] === undefined
                            ? "unsortcls"
                            : sortData["reservation_number"]
                        }
                      >
                        Reservation No.
                      </th>
                      <th>Requested On</th>
                      <th>Requested By</th>
                      <th
                        onClick={(e) => {
                          handlerData("reservation_date");
                        }}
                        className={
                          sortData["reservation_date"] === undefined
                            ? "unsortcls"
                            : sortData["reservation_date"]
                        }
                      >
                        Reservation Date
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("pr_no");
                        }}
                        className={
                          sortData["pr_no"] === undefined
                            ? "unsortcls"
                            : sortData["pr_no"]
                        }
                      >
                        PR No.
                      </th>
                      <th>Type</th>
                      <th>SAP Status</th>
                      <th>SAP Log</th>
                      {/* {finalUrlValue?.V ?  */}
                      <th>Actions</th>
                      {/* : ""} */}
                    </tr>
                  </thead>
                  <tbody>
                    {reservationList.map((obj, i) => (
                      <tr key={i + 1}>
                        <td>{i + 1}</td>
                        <td>{obj?.id}</td>
                        <td>{`${obj?.plant__desc === null ? "" : obj?.plant__desc
                          } (${obj?.plant__code})`}</td>
                        <td>{obj?.reservation_number}</td>
                        <td>
                          {moment.utc(obj?.created_at).format("Do MMM YYYY")}
                        </td>
                        <td>{obj?.created_by}</td>
                        <td>
                          {moment(obj?.reservation_date).format("Do MMM YYYY")}
                        </td>
                        <td>{obj?.pr_no}</td>
                        <td>
                          {obj?.reservation_type === "auto"
                            ? "Auto Request"
                            : "Manual Request"}
                        </td>
                        <td>{obj?.sap_status ? "Synced" : "Not Synced"}</td>
                        <td>
                          <Tooltip title={obj?.sap_error} position="bottom">
                            {obj?.sap_error?.substr(0, 12)}...
                          </Tooltip>
                        </td>
                        {/* {finalUrlValue?.V ? ( */}
                        <td>
                          <button
                            onClick={() => updateReservation(obj)}
                            className="btn btn-blanktd text-primary"
                          >
                            <img src="images/eye.png" alt="" />
                          </button>
                        </td>
                        {/* ) : (
                          ""
                        )} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reservationList?.length == 0 ? (
        ""
      ) : (
        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              From Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => {
                setreservationDataFilter((prev) => ({
                  ...prev,
                  from: event.target.value,
                }));
              }}
              value={reservationDataFilter.from}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              To Date<span className="text-danger">*</span>
            </label>
            <input
              type="date"
              className="form-control"
              onChange={(event) => {
                setreservationDataFilter((prev) => ({
                  ...prev,
                  to: event.target.value,
                }));
              }}
              value={reservationDataFilter.to}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              Type<span className="text-danger">*</span>
            </label>
            <select
              class="form-control newbgselect main-pointer"
              type="text"
              name="type"
              onChange={(e) => {
                setreservationDataFilter({
                  ...reservationDataFilter,
                  type: e.target.value,
                });
              }}
              value={reservationDataFilter?.type}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="">Select</option>
              <option value="manual">Manual Request</option>
              <option value="auto">Auto Request</option>
            </select>
          </div>
          <div className="form-group innergroup">
            <label>
              SAP Status<span className="text-danger">*</span>
            </label>
            <select
              class="form-control newbgselect main-pointer"
              type="text"
              name="type"
              onChange={(e) => {
                setreservationDataFilter({
                  ...reservationDataFilter,
                  sap_status: e.target.value,
                });
              }}
              value={reservationDataFilter?.sap_status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="">Select</option>
              <option value="true">Synced</option>
              <option value="false">Not Synced</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              setFilterApplied(false);
              getReservations(
                searchStr,
                {
                  status: true,
                  from: "",
                  to: "",
                  type: "",
                  sap_status: "",
                },
                currentPage,
                pageSizeNo,
                sortData.sort_by,
                true
              );
              setreservationDataFilter((prev) => ({
                ...prev,
                status: true,
                from: "",
                to: "",
                type: "",
                sap_status: "",
              }));
              filterclose();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={filterReservationData}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={addNew}
        onHide={() => {
          closeAddNew();
          setEditRecord(false);
          resetForm();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editRecord ? "View Reservations" : "Add/Edit Reservations"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Plant<span className="text-danger">*</span>
                    </label>
                    <Select
                      options={plantOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="plant"
                      isDisabled={view}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                      value={formObj.plant}
                    />
                    <div
                      className="text-center pt-2 text-danger"
                      hidden={!errors.selectPlant}
                    >
                      Kindly Select Plant
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Cost Center<span className="text-danger">*</span>
                    </label>
                    <Select
                      options={costCenterOptions}
                      name="cost-center"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={view}
                      placeholder="Press enter to Search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                      value={formObj.costCenter}
                    />
                    <div
                      className="text-center pt-2 text-danger"
                      hidden={!errors.selectCostCenter}
                    >
                      Kindly Select Cost Center
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Reservation Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="reservationDate"
                      value={formObj.reservationDate}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event);
                      }}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      disabled={view}
                      onBlur={handleBlur}
                    />
                    <div
                      className="text-center pt-2 text-danger"
                      hidden={!errors.enterDate}
                    >
                      Kindly Provide Reservation Date
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="table table-striped table-bordered tablecured leavetableapply tableremovetdoverflow">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th
                          style={{
                            width: "35%",
                          }}
                        >
                          Material
                        </th>
                        <th>Quantity</th>
                        <th>Material Description</th>
                        {!view && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {recordArr !== undefined && (
                        <MaterialRecord
                          edit={editRecord}
                          view={view}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          recordArr={recordArr}
                          removeRecord={removeRecord}
                          materialOpt={materialList}
                        />
                      )}
                      {!view && (
                        <tr className="innergroup">
                          <td>{index}</td>
                          <td
                            style={{
                              overflow: "visible",
                            }}
                          >
                            <Select
                              options={materialList}
                              name="material"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Press enter to Search"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action.name);
                              }}
                              value={materialObj.materialName}
                              onInputChange={(e) => {
                                getMaterialList(
                                  formObj?.plant?.value,
                                  editRecord,
                                  currentReservation,
                                  e
                                );
                              }}
                            />
                            <div
                              className="text-center pt-2 text-danger"
                              hidden={!errors.selectMaterial}
                            >
                              Kindly Select Material
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              name="quantity"
                              placeholder="Enter Quantity"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={materialObj.quantity}
                            />{" "}
                            <div
                              className="text-center pt-2 text-danger"
                              hidden={!errors.enterQuantity}
                            >
                              Kindly Provide Quantity
                            </div>
                            <div
                              className="text-center pt-2 text-danger"
                              hidden={!errors.quantityLen}
                            >
                              Quantity Limit Reached.
                            </div>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="desc"
                              placeholder="Enter Description"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled
                              value={materialObj.description}
                            />{" "}
                            {/* <div
                              className="text-center pt-2 text-danger"
                              hidden={!errors.enterDesc}
                            >
                              Kindly Provide Description
                            </div> */}
                          </td>
                          {!view && (
                            <td>
                              <button
                                className="btn btn-blanktd text-theme"
                                onClick={() => addRecord(index)}
                              >
                                <i className="far fa-plus-square"></i>{" "}
                              </button>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div
                    className="text-center pt-2 text-danger"
                    hidden={!errors.addMaterial}
                  >
                    Kindly add atleast one material with all details.
                  </div>
                  <br />
                  {view && (
                    <span
                      className="text-left"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <span className="text-danger">*</span>
                      {currentReservation?.sap_error}
                    </span>
                  )}
                  <span
                    className="text-left"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <span className="text-danger">*</span>
                    This transaction will get synced based on cron time
                  </span>
                  {/* <span>
                   <span className="text-danger">*</span>This transaction will
                    get synced based on cron time
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {!view && (
          <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={closeAddNew}>
              Cancel
            </button>
            <button
              className="btn btn-primary-inner bpi-main"
              onClick={saveReservation}
            >
              Submit
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ReservationReport;
