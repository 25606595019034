import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as surveyService from "../../service/Survey";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { selectFinalUrlPermission, selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

function SurveyResult() {
  const finalUrlValue = useSelector(selectFinalUrlPermission);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { targetAsmnt, flag } = state;
  console.log(targetAsmnt);
  const dispatch = useDispatch()

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPageFrom, setSelectedPageFrom] = useState(0);

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [surveyGridResponse, setSurveyGridResponse] = useState([]);

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [selectPages, setSelectPages] = useState(false);
  const [pageOptions, setPageOptions] = useState([])

  const handleKey = (
    search,
    survey_id,
    pendingRes,
    survey_type,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      `&survey_id=${survey_id}&survey_type=${survey_type}`;
    // if (status !== "" && status !== undefined && status !== null) {
    //   queryParm = queryParm + "&status=" + status;
    // }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (pendingRes !== "" && pendingRes !== undefined && pendingRes !== null) {
      queryParm = queryParm + "&pendingRes=" + pendingRes;
    }
    // if (
    //   organisation_id !== "" &&
    //   organisation_id !== undefined &&
    //   organisation_id !== null
    // ) {
    //   queryParm = queryParm + "&organisation_id=" + organisation_id;
    // }

    return queryParm;
  };

  const [filterdata, setfilterData] = useState({
    created_at: "",
  });

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      sdata.sort_by,
      true,
      filterdata.Type,
      
    );
  };

  const handleKeyExp = (
    search,
    survey_id,
    survey_type,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      `&survey_id=${survey_id}&survey_type=${survey_type}`;
    // if (status !== "" && status !== undefined && status !== null) {
    //   queryParm = queryParm + "&status=" + status;
    // }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    // if (
    //   organisation_id !== "" &&
    //   organisation_id !== undefined &&
    //   organisation_id !== null
    // ) {
    //   queryParm = queryParm + "&organisation_id=" + organisation_id;
    // }
    if (targetAsmnt?.pendingRes === true) {
      queryParm = queryParm + "&pendingRes=" + targetAsmnt?.pendingRes
    }

    if (selectedPage !== 0) {
      queryParm = queryParm + "&selectedPage=" + selectedPage?.value
    }
    if (selectedPageFrom !== 0) {
      queryParm = queryParm + "&selectedPageFrom=" + selectedPageFrom?.value
    }

    return queryParm;
  };

  const getSurveyResponses = (
    search,
    survey_id,
    pendingRes,
    survey_type,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    surveyService
      .getSurveyUserDetails(
        handleKey(
          search,
          survey_id,
          pendingRes,
          survey_type,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        console.log("viewing.,", response?.data?.dataList?.result);
        // getAllCategoryType();
        setSurveyGridResponse(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleSearch = (e) => {
    getSurveyResponses(
      e.target.value.toLowerCase(),
      targetAsmnt.id,
      targetAsmnt?.pendingRes,
      targetAsmnt.type_internal_external,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const getExportSurveyUser = (
    search,
    survey_id,
    survey_type,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"))
    setSelectPages(false)
    surveyService
      .getExportSurveyUser(
        handleKeyExp(
          search,
          survey_id,
          survey_type,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"))
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SurveyResponsesExports.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        setSelectPages(false);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"))
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getData = () => {
    surveyService
      .getSurveyUserDetails(
        `survey_id=${targetAsmnt.id}&pendingRes=${targetAsmnt?.pendingRes}&survey_type=${targetAsmnt.type_internal_external}&page_size=${pageSizeNo}&sort_by=${sortData.sort_by}`
      )
      .then((response) => {
        console.log("viewing.,", response?.data?.dataList?.result);
        setSurveyGridResponse(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        let pages = []
        let count = 0;
        for (let index = 0; index < response?.data?.dataList?.paginated_data?.totalPages; index++) {
          count += 1
          pages.push({
            label: count,
            value: count
          })
        }
        setPageOptions(pages)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }

  useEffect(() => {
    getData(
      sortData.sort_by,
      true,
      filterdata.created_at
    )
  }, [pageSizeNo]);

  const modifySurveyUser = (type, email) => {
    confirmAlert({
      title: type === "delete" ? "Delete Survey User" : "Resend Email to User",
      message: type === "delete" ? `Are you sure to remove user.` : `Are you sure to resend mail to user`,
      buttons: [
        {
          label: "Yes",
          onClick: () => changeUserSetting(type, email),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const changeUserSetting = (type, email) => {
    let payload = {
      actionType: type,
      email: email,
      surveyId: targetAsmnt?.id
    }
    surveyService?.modifyUserSetting(payload).then((res) => {
      if (res?.status === 200) {
        if (type == 'email') {
          toast.success('Email Send Successfully');
        } else {
          toast.success('User Deleted Removed from Survey');
          getData()
        }
      }
    })
  }

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    surveyService
      .getSurveyUserDetails(
        `survey_id=${targetAsmnt.id}&pendingRes=${targetAsmnt?.pendingRes}&survey_type=${targetAsmnt.type_internal_external}&page_no=${activePage}&page_size=${pageSizeNo}&sort_by=${sortData.sort_by}`
      )
      .then((response) => {
        setSurveyGridResponse(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
  };

  return (
    <>
      <Modal
        show={selectPages}
        onHide={() => setSelectPages(false)}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header>
          <Modal.Title>Select Pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="form-group innergroup">
                <label>
                  Select Page Number From
                  <span class="text-danger">*</span>
                </label>
                <Select
                  name="option_holiday"
                  options={pageOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedPageFrom(e)
                  }}
                />
              </div>
              <div class="form-group innergroup">
                <label>
                  Select Page Number To
                  <span class="text-danger">*</span>
                </label>
                <Select
                  name="option_holiday"
                  options={pageOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedPage(e)
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setSelectPages(false);
            }}
          >
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={() => {
            getExportSurveyUser(
              searchStr,
              targetAsmnt.id,
              targetAsmnt.type_internal_external,
              1,
              pageSizeNo,
              sortData.sort_by,
              true
            )
          }}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Survey</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* {finalUrlValue?.D ? ( */}
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={
                              surveyGridResponse.length !== 0 ? false : true
                            }
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              setSelectPages(true)
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  {targetAsmnt.type_internal_external == "Internal" ? (
                    <tr>
                      <th>#</th>
                      <th>Organization</th>
                      <th>Name</th>
                      <th>Employee Code</th>
                      <th>PSA</th>
                      <th>Department</th>
                      <th>Email-ID</th>
                      <th>Mobile Number</th>
                      <th>Survey Name</th>
                      {targetAsmnt?.type_name === "Quiz" && (
                        <>
                          <th>Total Question</th>
                          <th>Correct Answer</th>
                          <th>Incorrect Answer</th>
                          <th>Completion Time</th>
                        </>
                      )}
                      {targetAsmnt?.pendingRes === false && (
                        <>
                          <th 
                            onClick={(e) => {
                            handlerData("created_at");
                            }}
                            className={
                              sortData["created_at"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["created_at"]}>Submission Time</th>
                          <th>Action</th>
                        </>
                      )}
                    </tr>
                  ) : targetAsmnt.type_internal_external == "External" ? (
                    <tr>
                      <th>#</th>

                      <th>Email-ID</th>
                      <th>Type</th>
                      <th>Survey Name</th>
                      <th>First Opened On</th>
                      <th>Opened Count</th>
                      {targetAsmnt?.pendingRes === false && (
                        <>
                          <th 
                              className={
                              sortData["created_at"] === undefined
                              ? "unsortcls main-pointer"
                              : sortData["created_at"]
                            }>Submission Time</th>
                        </>
                      )}
                      <th>Action</th>
                    </tr>
                  ) : (
                    ""
                  )}
                </thead>
                <tbody>

                  {surveyGridResponse?.map((gridItem, idx) =>
                    targetAsmnt.type_internal_external == "Internal" ? (
                      <tr>
                        <td>{idx + 1}</td>
                        <td class="text-theme fw-bold">
                          {gridItem.org_name[0]}
                        </td>
                        <td>{gridItem.user_name}</td>
                        <td>{gridItem?.emp_code}</td>
                        <td>{gridItem?.psa}</td>
                        <td><Tooltip title={gridItem?.user_department}>{gridItem?.user_department}</Tooltip></td>
                        <td>{gridItem.business_email}</td>
                        <td>{gridItem.mobile_no}</td>
                        <td className="assess-title" dangerouslySetInnerHTML={{
                          __html: gridItem.survey_name.trim()
                        }}></td>
                        {
                          gridItem?.survey_type === "Quiz" && (
                            <>
                              <td>{gridItem?.total_ques}</td>
                              <td>{gridItem?.correct_answers}</td>
                              <td>{gridItem?.incorrect_answers}</td>
                              <td>{gridItem?.completion_time}</td>
                            </>
                          )
                        }
                        {targetAsmnt?.pendingRes === false && (
                          <>
                            <td>
                              {gridItem.submission_date} {gridItem.submission_time}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-success"
                                onClick={() =>
                                  navigate("/viewsurvey_userresponse", {
                                    state: {
                                      targetAsmnt: {
                                        id: targetAsmnt.id,
                                        type_id: "",
                                        user_id: gridItem.id,
                                        type_internal_external:
                                          targetAsmnt.type_internal_external,
                                      },
                                      view: "true",
                                    },
                                  })
                                }
                              >
                                <img src="images/eye.png" alt="" />{" "}
                              </button>
                            </td>
                          </>
                        )}
                      </tr>
                    ) : targetAsmnt.type_internal_external == "External" ? (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{gridItem.email}</td>
                        <td>{targetAsmnt.type_internal_external}</td>
                        <td className="assess-title" dangerouslySetInnerHTML={{
                          __html: gridItem.survey_name.trim()
                        }}></td>
                        <td>{gridItem?.first_opened}</td>
                        <td>{gridItem?.open_count}</td>
                        {targetAsmnt?.pendingRes === false ? (
                          <>
                            <td>
                              {gridItem.submission_date} {gridItem.submission_time}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-success"
                                onClick={() =>
                                  navigate("/viewsurvey_userresponse", {
                                    state: {
                                      targetAsmnt: {
                                        id: targetAsmnt.id,
                                        type_id: "",
                                        user_id:
                                          targetAsmnt.type_internal_external ==
                                            "External"
                                            ? gridItem.email
                                            : gridItem.id,
                                        type_internal_external:
                                          targetAsmnt.type_internal_external,
                                      },
                                      view: "true",
                                      flag: true,
                                      pdf: false
                                    },
                                  })
                                }
                              >
                                <img src="images/eye.png" alt="" />{" "}
                              </button>
                              <button
                                class="btn btn-primary bpi-main px-3 py-1 f-14"
                                style={{ marginLeft: "3px" }}
                                onClick={() =>
                                  navigate("/viewsurvey_userresponse", {
                                    state: {
                                      targetAsmnt: {
                                        id: targetAsmnt.id,
                                        type_id: "",
                                        user_id:
                                          targetAsmnt.type_internal_external ==
                                            "External"
                                            ? gridItem.email
                                            : gridItem.id,
                                        type_internal_external:
                                          targetAsmnt.type_internal_external,
                                      },
                                      view: "true",
                                      pdf: true,
                                    },
                                  })
                                }
                              >
                                <i class="fas fa-print"></i>
                              </button>
                            </td>
                          </>
                        ) : (
                          <td>
                            <button
                              className="btn btn-blanktd text-danger "
                              onClick={() => {
                                modifySurveyUser("delete", gridItem.email)
                              }}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                            <button
                              className="btn btn-blanktd"
                              onClick={() => {
                                modifySurveyUser("email", gridItem.email)
                              }}
                            >
                              <i className="fas fa-mail-bulk"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    ) : (
                      ""
                    )
                  )}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>10</option>
                    <option value={20} label={20}>20</option>
                    <option value={30} label={30}>30</option>
                    <option value={100} label={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date <span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date <span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyResult;
