import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as LeaveService from "../../service/leaveService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as HeaderService from "../../service/jv_service";

const AddBulkUpload = (props) => {
  const { importclose, importshow, onCallback } = props;
  const [uploadData, setUploadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [excelListImport, setExcelListImport] = useState([]);
  const [link, setLink] = useState(null);
  const [imgSize, setImgSize] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [filename, setfilename] = useState("");
  const [gatePass, setGatePass] = useState({});
  const [attachmentsValidate, setAttachmentsValidate] = useState(true);
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });
  const [leaveTypeError, setLeaveTypeError] = useState(false);
  const [leaveType, setLeaveType] = useState(0);

  const onProfileChange = (e, flag) => {
    // setShowImport({
    //   ...showImport,
    //   downloadFlag: false,
    //   importFlag: showImport?.importFlag === false ? false : true,
    // });
    const files = e.target.files[0];
    if (
      flag === "attachments" ||
      files.type === "text/csv" ||
      files.type === "image/xls" ||
      files.type === "image/xlsx" ||
      files.type === "image/XLSX" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      HeaderService.uploadImages(fileData).then((response) => {
        if (flag === "import_Jv_data") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
        if (flag === "attachments") {
          setGatePass(response?.data);
          setfilename(response.data.original_name);
          setAttachmentsValidate(false);
        }
      });
    } else {
      toast.error("Only csv, xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const importExel = (values) => {
    if (filename !== "" && leaveType !== 0) {
      setShowImport({ ...showImport, importFlag: false });
      setIsLoading(true);
      let payload = {
        leave_code: leaveType,
        import_file: values,
        gate_pass: gatePass,
      };
      LeaveService.importExel(payload)
        .then((response) => {
          setExDataDown(response.data);
          if (response.status === 200) {
            setShowImport({ ...showImport, importFlag: true });
            setIsLoading(false);
            getDirectoryDetails();
          }
        })
        .catch((error) => {
          toast.error(error.message.data.detail);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      setLeaveTypeError(leaveType === 0 ? true : false);
      if (filename === "") {
        toast.error("Kindly Select Gate Pass");
      }
    }
  };

  const getDirectoryDetails = () => {
    LeaveService.getLeaveImportDetails()
      .then((response) => {
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} record sucessfully`
          );
        }
        if (
          response?.data?.dataList[0]?.records_uploaded === 0 &&
          response?.data?.dataList[0]?.records_failed === 0
        ) {
          toast.warn("No Record Found");
        }
        if (response?.data?.dataList[0]?.records_failed > 0) {
          toast.error(
            `${response?.data?.dataList[0]?.records_failed} record unsucessfull`
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    getLeaveList();
    setShowImport({
      ...showImport,
      importFlag: false,
      downloadFlag: false,
    });
  }, [importclose]);

  const getLeaveList = () => {
    let queryParam = `&paginate=${false}`;
    LeaveService.getLeaveManageList(queryParam).then((response) => {
      if (response?.status === 200) {
        setLeaveList(
          response?.data?.dataList?.result.filter((obj) => {
            if (obj?.allow_bulk) {
              return true;
            }
          })
        );
      }
    });
  };

  const leaveAllocationListData = (optionsCountryList) => {
    return optionsCountryList?.map((item, index) => (
      <option
        key={`leaveAllocation${index}`}
        value={`${item?.id}`}
        label={`${item?.code} (${item?.name})`}
        leave={`${item?.no_of_days}`}
        gender={
          item?.gender === "M"
            ? "Male"
            : item?.gender === "F"
            ? "Female"
            : "All"
        }
        max={`${item?.no_of_days}`}
      >{`${item?.code} (${item?.name})`}</option>
    ));
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={importshow}
        onHide={() => {
          onCallback();
          importclose();
        }}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div className="col-md-5">
              <div className="drapdroparea upload-btn-wrapper text-center">
                <div class="form-group innergroup">
                  <label>
                    Leave Type<span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control newbgselect main-pointer"
                    name="leave_type"
                    type="number"
                    onChange={(event) => {
                      setLeaveType(event.target.value);
                      setLeaveTypeError(false);
                    }}
                    value={Number(leaveType)}
                  >
                    <option value="0" label="Select Leave type" disabled>
                      Select Leave Type
                    </option>

                    {leaveAllocationListData(leaveList)}
                  </select>
                  {leaveTypeError ? (
                    <div className="small text-danger">Select Leave Type</div>
                  ) : null}
                </div>
                <div class="form-group innergroup">
                  <label>
                    Gate Pass<span class="text-danger">*</span>
                  </label>
                  {filename != undefined ? (
                    <input
                      type="text"
                      name="attachments"
                      class="form-control bg-white"
                      placeholder="(Image, PDF Format)"
                      disabled
                      style={{
                        width: "250px",
                      }}
                      value={filename}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      placeholder="(Image, PDF Format)"
                    />
                  )}
                  <div class="upload-btn-wrapper">
                    <button class="uploadBtn">Browse</button>
                    <input
                      type="file"
                      title=""
                      name="attachments"
                      id="attachmentsFile"
                      accept="image/*, application/pdf"
                      onChange={(e) => onProfileChange(e, "attachments")}
                    />
                  </div>
                  {imgSize ? (
                    <div className="small text-danger">
                      File size should not exceed 1mb
                    </div>
                  ) : null}
                  {filename != undefined && (
                    <p>
                      Selected file
                      <span> {filename}</span>
                    </p>
                  )}
                  {link != undefined && (
                    <p>Selected file {<span> {link}</span>}</p>
                  )}
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button class="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="far fa-file-excel"></i>
                      <a href="\bulk_leave_format.xlsx" download>
                        DOWNLOAD TEMPLATE
                      </a>
                    </button>
                  </div>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e, "import_Jv_data")}
                  />
                </div>
                {showImport.downloadFlag === true && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <span class="badge bad-status mb-3 badge-warning">
                      {uploadData?.original_name}
                      <i
                        class="far fa-times-circle"
                        onClick={() => {
                          setUploadData({});
                          setShowImport({
                            ...showImport,
                            importFlag: false,
                            downloadFlag: false,
                          });
                        }}
                      ></i>
                    </span>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      showImport.importFlag === false && (
                        <button
                          className="btn btn-primary-inner"
                          type="button"
                          onClick={() => importExel(uploadData)}
                        >
                          Proceed to Upload
                        </button>
                      )
                    )}
                  </div>
                )}
                {showImport.importFlag === true && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>
                      The system was unable to read few records from the
                      uploaded file.
                      <br />
                      Please correct or remove those records from the file and
                      upload again.
                    </p>
                    <span className="badge bad-status mb-3 badge-success">
                      Records Succeded:
                      {excelListImport[0]?.records_uploaded}
                    </span>
                    <span className="badge bad-status mb-3 badge-danger">
                      Records Failed:
                      {excelListImport[0]?.records_failed}
                    </span>
                    <div className="mt-3 downloadtemplate">
                      <p>Check the uploaded file in case of error.</p>
                      <button
                        className="btn btn-primary-inner"
                        type="button"
                        onClick={() => {
                          downloadError();
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBulkUpload;
