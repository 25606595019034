import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";
import * as surveyService from "../../service/Survey";
// import SelectedTypeUser from "./SelectedTypeUser";
import * as HigherEduService from "../../service/educationService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import moment from "moment";
import { PdfIcon } from "./Icon";
import DataTable from "./DataTable";
import { Tooltip } from "react-tippy";

function HigherEduApproval() {
  const navigate = useNavigate();
  const location = useLocation(selectUserData);

  const [asmntData, setAsmntData] = useState({});
  const [submittedNotifier, setSubmitNotifier] = useState(false);
  const finalUser = useSelector(selectUserData);

  const [postApprData, set_postApprData] = useState({
    id_list: [],
    status: "",
    remarks: "",
  });

  const [userInput, setUserInput] = useState({
    id: 0,
    is_draft: false,
    // survey_id:  0,
    questions: [],
  });

  const [isApproval, setIsApproval] = useState(false);

  let currentCheckedRating = {};
  let abortApplicationRequestDetail = null;

  const column = [
    {
      datafield: '',
      text: 'S.No.',
      formatter: (value, row, idx) => idx+1
    },
    {
      datafield: 'approval_type',
      text: 'Approver Stage'
    },
    {
      datafield: '',
      text: 'Approver Name',
      formatter: (value, row) => `${row?.full_name} (${row?.username})`
    },
    {
      datafield: 'updated_at',
      text: 'Approved Date',
      formatter: value => moment(value).format("DD/MM/YYYY")
    },
    {
      datafield: 'status',
      text: 'Status',
      formatter: (value) => <span
        className={
          value.toLowerCase() == "approved"
            ? "badge badge-success"
            : value.toLowerCase() == "pending"
              ? "badge badge-secondary"
              : "badge badge-danger"
        }
      >
        {value}
      </span>
    },
    {
      datafield: 'remarks',
      text: 'Remark',
      formatter: value => value?.length > 30 ? <Tooltip title={value} position="top">{value.substring(0,30)+"..."}</Tooltip> : value
    },
  ];

  const amountFormatter = number => new Intl.NumberFormat(asmntData?.currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: asmntData?.currency ? asmntData?.currency : 'INR' }).format(number)

  const getApplicationRequestDetail = (id) => {
    abortApplicationRequestDetail = new AbortController();
    HigherEduService.getApplicationRequestDetail(id, abortApplicationRequestDetail.signal)
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        abortApplicationRequestDetail = null;
        setAsmntData(response.data?.dataList);
        // toast.success(`fetch success ${response.data}`)

        response.data?.dataList.approval_list.every((ele) => {
          if (ele.status.toLowerCase() == "pending") {
            postApprData.id_list.push(ele["id"]);
            return false;
          }
          return true;
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
        toast.error(error);
      });
  };

  // useEffect(()=>{

  // },[postApprData])

  function SubmitApproved(markedStatus) {
    let submitState = Object.assign({}, postApprData);
    submitState["status"] = markedStatus;

    let setbit = true;

    for (const [key, value] of Object.entries(submitState)) {
      if (!value) {
        setbit &= false;
        toast.error(`${key} is mandatory`);
      }
    }
    if (setbit) {
      // return

      // toast.success(`called`)

      HigherEduService.postMultiApplicationApprove(submitState)
        .then((response) => {
          toast.success(`submitted`);
          navigate("/dashboard");
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }

    // console.log(`logg`,submitState);
  }

  useEffect(() => {
    let id = location?.state?.from ? location?.state?.from : parseInt(location.search.slice(1));
    getApplicationRequestDetail(id);
    setIsApproval(location?.state?.isApproval ? location?.state?.isApproval : false);

    return () => {
      if (abortApplicationRequestDetail) abortApplicationRequestDetail.abort();
    }
  }, []);

  const QuestionEditor = (question) => {
    let ques = question.split("<p>")[1].split("</p>")[0];
    return ques;
  };

  function goNext() {
    // if(userInput?.questions.length <= 0 || !userMailToken.userMail || !userMailToken.userToken  ){
    //   return
    // }
    // else
    // {
    //   let queryBody={...userInput}
    //   queryBody.is_draft=false
    //   // queryBody.survey_id=asmntData.id
    //   console.log(`sending `,queryBody);
    //   surveyService.postExternalSurveyResponses(userMailToken,queryBody)
    //   .then((response) => {
    //     console.log('viewing.,',response?.data?.dataList?.result);
    //     setSubmitNotifier(true)
    //     })
    //     .catch((error) => {
    //       console.log(
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //           "failed"
    //           );
    //         });
    // }
  }

  return (
    <>
      <div className="row">
        {/* <SelectedTypeUser current={targetAsmnt.current}/> */}
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="policyhead">View Request: {asmntData?.id}</h3>
                </div>
              </div>

              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Name</label>
                    <p>{asmntData?.user_full_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Code</label>
                    <p>{asmntData?.username}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Designation/Grade</label>
                    <p>{asmntData?.designation_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <p>{asmntData?.department_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Location</label>
                    <p>
                      {`${asmntData?.personal_area_description} (${asmntData?.personal_area_name})`}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Unit</label>
                    <p>{asmntData?.branch_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of joining</label>
                    <p>
                      {moment
                        .parseZone(asmntData?.date_of_joining)
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Performance Rating</label>
                    <p>{asmntData?.user_rating}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Area of Development</label>
                    <p>{asmntData?.development_status}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Course applying for</label>
                    <p>{asmntData?.course}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Name of Institute or Unversity</label>
                    <p>{asmntData?.university}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Course Start Date</label>
                    <p>
                      {moment
                        .parseZone(asmntData?.start_date)
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Course End Date</label>
                    <p>
                      {moment
                        .parseZone(asmntData?.end_date)
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Duration of the course (in days)</label>
                    <p>{asmntData?.course_duration}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Course fee </label>
                    <p>{amountFormatter(asmntData?.course_fee ? asmntData?.course_fee : 0)}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Course contents</label>
                    <p>{asmntData?.course_content}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Justification for applying of the course</label>
                    <p>{asmntData?.justification}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="content-wrapper-inner content-wrapper-inner2">
                    <DataTable column={column} list={asmntData?.approval_list}/>
                  </div>
                </div>

                <div className="col-md-12">
                  <label>
                    Attachments
                    <span className="text-danger">*</span>
                  </label>
                  {asmntData?.attachment_list?.length ? (
                    <div className="row">
                      {asmntData?.attachment_list.map((idata, idx) => (
                        <a
                          href={idata.url}
                          target="_blank"
                          className="col-md-2"
                          rel="noreferrer"
                        >
                          {idata.url.split(".")[
                            idata.url.split(".").length - 1
                          ] == "pdf" ? (
                            <PdfIcon width={120} height={85} />
                          ) : (
                            <img
                              src={idata.url}
                              style={{ aspectRatio: "16/9" }}
                            />
                          )}
                        </a>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* {finalUser.id != asmntData?.created_by_id && finalUser.id != asmntData?.user_id && <div className="col-md-12 mt-3"> */}
                {isApproval && (<>
                  <div className="col-md-12 mt-3">
                    <label>
                      Remarks
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      placeholder="Enter remarks"
                      type="text"
                      onChange={(e) =>
                        set_postApprData({
                          ...postApprData,
                          remarks: e.target.value,
                        })
                      }
                      className="form-control"
                    ></textarea>
                  </div>
                  <div style={{border: '2px solid #ffb2b2', width: '100%', borderRadius: 10}} className="m-2 mt-3">
                    <div style={{backgroundColor: '#ffb2b2', color: '#fff', borderTopRightRadius: 7, borderTopLeftRadius: 7}} className="p-2">
                      <i className="fas fa-exclamation-triangle"></i>&nbsp;&nbsp;
                      <span style={{fontWeight: 'bold', fontSize: 18}}>Caution</span>
                    </div>
                      <p className="m-3" style={{color: '#ff4c4c', fontSize: 17, fontWeight: 'bold'}}>
                      Listed below are few guidelines to be considered by approver before providing approval to an employee:<br/></p>
                      <p className="m-3 mt-1" style={{color: '#ff4c4c'}}>
                      1) Budget allocation<br/>
                      2) Relevance of the course with respect to his/her current job<br/>
                      3) Succession/Role movement<br/>
                      4) Rating of Employee<br/>
                      5) Impact on Return on investment (ROI)<br/>
                      </p>
                  </div>
                  </>
                )}
              </div>
            </div>

            {/* {finalUser.id != asmntData?.created_by_id && finalUser.id != asmntData?.user_id ? <div className="col-md-12 text-right pb-3"> */}
            {isApproval ? (
              <div className="col-md-12 text-right pb-3">
                <div className="col-md-12 text-right pb-3">
                  {/* <Link to="/common-approve" className="btn btn-outline-danger mr-3 ml-3">Cancel</Link> */}

                  <button
                    className="btn btn-primary-inner mr-3 ml-3"
                    type="button"
                    onClick={() => {
                      navigate(`/common-approve/${location?.state?.subId}`, {
                        state: {
                          subtypeid: location?.state?.subId,
                          subTypeName: location?.state?.type,
                        },
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-outline-danger bpi-main mr-3"
                    type="button"
                    onClick={() => SubmitApproved("REJECTED")}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main mr-3"
                    type="button"
                    onClick={() => SubmitApproved("RESUBMIT")}
                  >
                    Send Back
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main mr-3"
                    type="button"
                    onClick={() => SubmitApproved("APPROVED")}
                  >
                    Approve
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="btn btn-primary-inner mr-3 ml-3"
                type="button"
                onClick={() => {
                  navigate("/higher-edu-listing");
                }}
              >
                Back
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HigherEduApproval;
