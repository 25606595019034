import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Asidemenu from "./asidemenu";

function Sidemenu({ myRights }) {
  const [myRightsData, setMyRightsData] = useState([]);
  useEffect(() => {
    console.log(myRights);
    setMyRightsData(myRights);
  }, [myRights]);

  return (
    <>
      <div className="col-md-2 col-lg-1 d-none d-md-block" id="tabSide">
        <Asidemenu myRightsData={myRightsData}></Asidemenu>
      </div>
    </>
  );
}

export default Sidemenu;
