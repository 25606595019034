import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import * as VehicalService from "../../service/VehicalService";
function VehicleCheck() {
  let location = useLocation();
  let navigate = useNavigate();
  const finalUser = useSelector(selectUserData);
  const [data, setData] = useState({});
  const [ForwardFlag, setFlag] = useState(false);
  const [msg, setMsg] = useState("");
  const status_choice = [
    ("A", "Final Approved"),
    ("ARM", "Approved By Reporting Manager"),
    ("ASU", "Approved By SCC User"),
    ("ASM", "Approved By SCC Manager"),
    ("FASM", "Forward & Approved By SCC Manager"),
    ("ASH", "Approved By SCC Head"),
    ("RRM", "Declined By Reporting Manager"),
    ("RSU", "Declined By SCC User"),
    ("RSM", "Declined By SCC Manager"),
    ("RSH", "Declined By SCC Head"),
    ("P", "Pending"),
  ];
  const userDetails = useSelector(selectUserData);

  async function approveCheckExpense(status) {
    if (msg === "") {
      toast.error("Remarks is required");
      return;
    }
    let object = {
      vehicle_id: location?.state?.from,
      // vehicle_id: 4,
      status: checkStatus(status),
      remarks: msg,
    };
    await VehicalService.action_on_vehicle_Insurance(object)
      .then((response) => {
        let idx = finalUser.groups.findIndex(
          (obj) => obj.name.toLowerCase() == "ssc user"
        );
        if (idx > -1) {
          navigate(`/vehicle-expense-insurance`);
        } else if (location?.state?.dataCount > 1) {
          window.history.go(-1);
        } else {
          navigate(`/dashboard`);
        }
      })
      .catch((error) => {
        console.log(`error approve check!`, error);
      });
  }
  const checkStatus = (status) => {
    // ('A', 'Final Approved'),
    // ('ARM', 'Approved By Reporting Manager'),
    // ('ASU', 'Approved By SCC User'),
    // ('ASM', 'Approved By SCC Manager'),
    // ('ASH', 'Approved By SCC Head'),

    // ('RRM', 'Declined By Reporting Manager'),
    // ('RSU', 'Declined By SCC User'),
    // ('RSM', 'Declined By SCC Manager'),
    // ('RSH', 'Declined By SCC Head'),

    // ('P', 'Pending'),
    let statusFlag = "";
    finalUser.groups.map((data, idx) => {
      switch (data.name.toLowerCase()) {
        case "ssc manager":
          {
            if (status == "A") {
              statusFlag = "ASM";
            } else if (status == "R") {
              statusFlag = "RSM";
            } else if (status == "F") {
              statusFlag = "FASM";
            }
          }
          break;

        case "ssc user":
          {
            if (status == "A") {
              statusFlag = "ASU";
            } else if (status == "R") {
              statusFlag = "RSU";
            }
          }
          break;

        case "reporting manager":
          {
            if (status == "A") {
              statusFlag = "ARM";
            } else if (status == "R") {
              statusFlag = "RRM";
            }
          }
          break;

        case "ssc head":
          {
            if (status == "A") {
              statusFlag = "ASH";
            } else if (status == "R") {
              statusFlag = "RSH";
            }
          }
          break;
      }
    });
    if (finalUser.is_superuser === true) {
      statusFlag = status;
    }
    return statusFlag;
  };

  const getData = (vehicle_id) => {
    // dispatch(setDisplayLoader("Display"));
    VehicalService.getVehicleInsuranceDataByid(vehicle_id)
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.dataList?.result[0];
        setData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    finalUser.groups.map((data, idx) => {
      if (
        data.name.toLowerCase() == "ssc manager" ||
        data.name.toLowerCase() == "ssc user"
      ) {
        setFlag(true);
      }
    });

    let vehicle_id = location?.state?.from;
    getData(vehicle_id);
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <div class="defaultchatgptquest text-right">
                      <Link to="/travel-expense" class="badge badge-secondary">
                        <i class="fas fa-arrow-circle-left"></i> Back
                      </Link>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <h3 class="policyhead">Vehicle Insurance Request</h3>
                  </div>
                </div>
                <div class="row viewformaftersubmit">
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Emp Code</label>
                      <p>{data?.created_by_username}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Req Id</label>
                      <p>{`PIJAVR${data?.id}`}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Name</label>
                      <p>
                        {data?._state?.fields_cache?.created_by?.first_name +
                          " " +
                          data?._state?.fields_cache?.created_by?.last_name}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Vendor Code</label>
                      <p>
                        {
                          data?._state?.fields_cache?.created_by
                            ?.employee_vendor_code
                        }
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Grade</label>
                      <p>{data?._state?.fields_cache.created_by.employee_vendor_code}</p>
                    </div>
                  </div> */}
                  {/* <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>UniqueId</label>
                      <p>{data?.created_by_username}</p>
                    </div>
                  </div> */}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Date of Joining</label>
                      <p>
                        {moment(
                          data?._state?.fields_cache?.created_by?.date_joined
                        ).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Reporting Person</label>
                      <p>{data?.relation_type}</p>
                    </div>
                  </div> */}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Applied on</label>
                      <p>{moment.utc(data?.created_at).format("DD-MM-YYYY")}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Approved on</label>
                      <p>
                        {data?.status && data?.status[0] == "A"
                          ? moment(data?.updated_at).format("DD-MM-YYYY")
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Relation</label>
                      <p>{data?.relation_type}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Vehicle Owner Name</label>
                      <p>{data?.VehicleOwnerName}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Vehicle Number</label>
                      <p>{data?.vehicleNumber}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Policy No.</label>
                      <p>{data?.policyNumber}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Valid From</label>
                      <p>
                        {moment.utc(data?.validFromDate).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Valid Till</label>
                      <p>
                        {moment.utc(data?.validTillDate).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Premium</label>
                      <p>
                        <i className="fas fa-rupee-sign mr-1"></i>
                        {Number(data?.premium)?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Attachment</label>
                      <p>
                        {" "}
                        {data?.attachment_path?.includes("https") && (
                          <a
                            target="_blank"
                            href={data?.attachment_path}
                            rel="noreferrer"
                          >
                            <i class="fas fa-paperclip"></i>&nbsp;View
                          </a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-0">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Remarks <span class="text-danger">*</span>
                    </label>
                    <textarea
                      onChange={(e) => setMsg(e.target.value)}
                      class="form-control"
                      value={msg}
                      placeholder="Enter Comments here..."
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12 py-3 ">
                  <div className="float-right p-3">
                    <button
                      className="btn bpi-main mr-2"
                      onClick={() =>
                        navigate(`/common-approve/${location?.state?.subId}`, {
                          state: {
                            subtypeid: location?.state?.subId,
                            subTypeName: location?.state?.type,
                          },
                        })
                      }
                      style={{ backgroundColor: "#b5babe" }}
                    >
                      BACK
                    </button>
                    <button
                      className={
                        "btn mr-2 btn-outline-" +
                        (ForwardFlag ? "primary" : "danger")
                      }
                      type="button"
                      onClick={() => approveCheckExpense("R")}
                    >
                      {ForwardFlag ? "SEND BACK TO USER" : "REJECT"}
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main mr-2"
                      onClick={() => approveCheckExpense("A")}
                    >
                      APPROVE
                    </button>

                    {/* {ForwardFlag && (
                      <button
                        class="btn btn-outline-primary  mr-2"
                        onClick={() => approveCheckExpense("F")}
                      >
                        FORWARD TO SSC HEAD
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VehicleCheck;
