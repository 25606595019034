import React from "react";
import { Modal } from "react-bootstrap";

const ViewTableSkill = (props) => {
  const {
    importshow,
    importclose,
    ViewDataSkill,
    viewEmployeeData,
    skillViewSet,
    empViewSet,
    locationViewSet,
    locationwiseData,
    groupViewSet,
    groupwiseData
  } = props;
  console.log(viewEmployeeData, ViewDataSkill, "viewEmployeeData");
  return (
    <Modal
      show={importshow}
      onHide={importclose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          View {ViewDataSkill !== "" ? "Skill Wise" : viewEmployeeData ? "Employee" : locationwiseData ? "Location" : "Group"} Data (
          {ViewDataSkill !== ""
            ? skillViewSet?.["Skill Code"]
            : viewEmployeeData ?
              empViewSet?.["Emp. Code"]
              :
              locationwiseData ?
                locationViewSet?.["Location Code"]
                :
                groupViewSet?.["Group Code"]
          }
          )
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="col-md-12">
              {ViewDataSkill !== "" && (
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Employe Code</th>
                        <th>Employee Name</th>
                        <th>Location</th>
                        <th>Department</th>
                        <th>Current Skill</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ViewDataSkill?.map((data, i) => (
                        <tr>
                          <td>{i + 1}.</td>
                          <td>{data.username}</td>
                          <td>
                            {data.first_name} {data.last_name}
                          </td>
                          <td>{data.location_name}</td>
                          <td>{data.department_name}</td>
                          <td>{data.current_level}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {viewEmployeeData !== "" && (
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Skill Id</th>
                        <th>Description</th>
                        <th>Current Level</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewEmployeeData?.map((data, i) => (
                        <tr>
                          <td>{i + 1}.</td>
                          <td>{data.skill_id}</td>
                          <td>{data.desc}</td>
                          <td>{data.current_level}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {locationwiseData && locationwiseData.length > 0 !== "" && (
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Skill Id</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationwiseData?.map((data, i) => (
                        <tr>
                          <td>{i + 1}.</td>
                          <td>{data.skill_id}</td>
                          <td>{data.desc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {groupwiseData && groupwiseData.length > 0 !== "" && (
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Skill Id</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupwiseData?.map((data, i) => (
                        <tr>
                          <td>{i + 1}.</td>
                          <td>{data.skill_id}</td>
                          <td>{data.desc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button class="btn btn-primary-inner bpi-main">Close</button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ViewTableSkill;
