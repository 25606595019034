import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as HeaderService from "../../service/jv_service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HolidayBulkImport = (props) => {
  const { importclose, importshow } = props;
  const [uploadData, setUploadData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [excelListImport, setExcelListImport] = useState([]);
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });

  const onProfileChange = (e, flag) => {
    setShowImport({ ...showImport, downloadFlag: false });
    const files = e.target.files[0];
    if (
      files.type === "text/csv" ||
      files.type === "image/xls" ||
      files.type === "image/xlsx" ||
      files.type === "image/XLSX" ||
      files.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const fileData = new FormData();
      console.log(files, "profile changed");
      fileData.append("uploaded_file", files);
      HeaderService.uploadImages(fileData).then((response) => {
        if (flag === "import_Jv_data") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
      });
    } else {
      toast.error("Only csv, xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const importExel = (values) => {
    setShowImport({ ...showImport, importFlag: false });
    setIsLoading(true);
    HeaderService.importExelHoliday(values)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          setShowImport({ ...showImport, importFlag: true });
          setIsLoading(false);
          getDirectoryDetails();
          toast.error(response?.message?.data?.detail);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message?.data?.detail);
        setIsLoading(false);
        console.log(
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
            "failed"
        );
      });
  };

  const getDirectoryDetails = () => {
    HeaderService.getHolidayDirectoryDetails()
      .then((response) => {
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} record sucessfully`
          );
        }
        if (
          response?.data?.dataList[0]?.records_uploaded === 0 &&
          response?.data?.dataList[0]?.records_failed === 0
        ) {
          toast.warn("No Record Found");
        }
        if (response?.data?.dataList[0]?.records_failed > 0) {
          toast.error(
            `${response?.data?.dataList[0]?.records_failed} record unsucessfull`
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
    importclose();
  };

  useEffect(() => {
    setShowImport({
      ...showImport,
      importFlag: false,
      downloadFlag: false,
    });
  }, [importclose]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Upload (Optional Holiday)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button class="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="far fa-file-excel"></i>
                      <a href="\holiday_bulk_upload_format.csv" download>
                        DOWNLOAD TEMPLATE
                      </a>
                    </button>
                  </div>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e, "import_Jv_data")}
                  />
                </div>
                {showImport.downloadFlag === true && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <span class="badge bad-status mb-3 badge-warning">
                      {uploadData?.original_name}
                      <i
                        class="far fa-times-circle"
                        onClick={() => {
                          setUploadData({});
                          setShowImport({
                            ...showImport,
                            importFlag: false,
                            downloadFlag: false,
                          });
                        }}
                      ></i>
                    </span>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        variant="primary"
                        as="span"
                        role="status"
                        aria-hidden="true"
                      ></Spinner>
                    ) : (
                      showImport.importFlag === false && (
                        <button
                          className="btn btn-primary-inner"
                          type="button"
                          onClick={() => importExel(uploadData)}
                        >
                          Proceed to Upload
                        </button>
                      )
                    )}
                  </div>
                )}
                {showImport.importFlag === true && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>
                      The system was unable to read few records from the
                      uploaded file.
                      <br />
                      Please correct or remove those records from the file and
                      upload again.
                    </p>
                    <span className="badge bad-status mb-3 badge-success">
                      Records Succeded:
                      {excelListImport[0]?.records_uploaded}
                    </span>
                    <span className="badge bad-status mb-3 badge-danger">
                      Records Failed:
                      {excelListImport[0]?.records_failed}
                    </span>
                    <div className="mt-3 downloadtemplate">
                      <p>Check the uploaded file in case of error.</p>
                      <button
                        className="btn btn-primary-inner"
                        type="button"
                        onClick={() => {
                          downloadError();
                        }}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HolidayBulkImport;
