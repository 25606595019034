import { AUTH_BASE_URL } from "../../constants/URL";
import axios from "../../utils/axios";

export const getReimbursementData = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/reimbursement/details?${queryParam}`);
};

export const saveMobileReimbursement = async (payload) => {
  return axios.post(`${AUTH_BASE_URL}/reimbursement/mobile`, payload);
};

export const uploadfile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getReimbEntitlement = (subGroup, reimb_type) => {
  return axios.get(
    `${AUTH_BASE_URL}/reimbursement/getEntitlement/${subGroup}/${reimb_type}`
  );
};

export const deleteReimbursementData = (id, reimb_type) => {
  return axios.delete(
    `${AUTH_BASE_URL}/reimbursement/mobile/${id}/${reimb_type}`
  );
};

export const updateReimbursementData = (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/reimbursement/mobile/${id}`, data);
};

export const updateMarriageReimbursement = (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/reimbursement/marriage/${id}`, data);
};

export const saveMarriageReimbursement = (data) => {
  return axios.post(`${AUTH_BASE_URL}/reimbursement/marriage`, data);
};

export const getExportData = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/reimbursement/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getSelectedData = (type, id) => {
  return axios.get(`${AUTH_BASE_URL}/reimbursement/getData/${type}/${id}`);
};

export const changeReimbursementStatus = (data) => {
  return axios.post(`${AUTH_BASE_URL}/reimbursement/changeStatus`, data);
};

export const getReimbRecovery = (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/reimbursement/recovery?${queryParam}`);
};

export const getExportValueRecovery = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/reimbursement/recovery/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const submitReimburseDeclaration = (data) => {
  return axios.post(`${AUTH_BASE_URL}/reimbursement/decl`, data);
};

export const getReimbDec = (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/reimbursement/decl?${queryParm}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/reimbursement/decl/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
