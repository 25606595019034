import { useEffect } from "react";
import { useState } from "react";
import styles from "./ModularTable.module.css";
import { MAX_NUMBER_LENGTH, MAX_TEXT_LENGTH } from '../../utils/constant';
import { confirmAlert } from "react-confirm-alert";

function restrictToWords(word, limit) {
    if (!word) return "";

    if (word.length >= limit) {
        return word.slice(0, limit) + "...";
    }
    return word;
}

const ModularTable = ({ showTotal, columns, data, onTableDataChange, initialData }) => {
    const [internalData, setInternalData] = useState(data);

    const [blacklistObj, setBlacklistObj] = useState({});

    const [selectedCrop, setSelectedCrop] = useState({});

    const [allowChange, setAllowChange] = useState(false);

    const [seasonChangeData, setSeasonChangeData] = useState({
        data: null,
        col: null,
        row: null
    })


    useEffect(() => {


        if (!columns) return;
        let BLCols = {};

        for (let i = 0; i < columns.length; i++) {
            if (!columns[i]) continue;
            if (columns[i].nonrepeat) {
                BLCols[i] = [];
            }
        }

        setBlacklistObj(BLCols);

    }, [columns]);

    useEffect(() => {
        if (onTableDataChange)
            onTableDataChange(internalData);

        setBlacklistObj(state => {
            const BLObj = {};
            for (let i = 0; i < Object.keys(state).length; i++) {

                BLObj[Object.keys(state)[i]] = [];
                for (let j = 0; j < internalData.length; j++) {
                    BLObj[Object.keys(state)[i]].push(internalData[j][Object.keys(state)[i]].selected);
                }
            }



            return BLObj;
        })


        setSelectedCrop(state => {
            const selectedCrop = {};

            for (let i = 0; i < internalData.length; i++) {
                if (!selectedCrop[internalData[i][1].selected])
                    selectedCrop[internalData[i][1].selected] = {};

                if (!selectedCrop[internalData[i][1].selected][internalData[i][2].selected])
                    selectedCrop[internalData[i][1].selected][internalData[i][2].selected] = i;
            }



            return selectedCrop;
        })


    }, [internalData]);

    useEffect(() => { setInternalData(data) }, [data]);

    const onChangeHandler = (e, col, row, type) => {
        if (e.target.value.length >= (MAX_NUMBER_LENGTH + 1) && e.key != "Backspace" && type == "N") { e.target.value = 99999 }
        if (e.target.value.length >= (MAX_TEXT_LENGTH + 1) && e.key != "Backspace" && type == "T") { e.preventDefault() }
        setInternalData(state => {
            const newData = [...state];
            newData[col][row].value[0] = e.target.value;
            return newData;
        })
    }

    const dropdownClickedHandler = (val, col, row) => {
        if (columns[row]?.name === "Season" && (initialData[col][row]?.selected !== val && initialData[col][row]?.selected !== "None")) {
            confirmDialog(val, col, row)
        } else {
            setInternalData(state => {
                const newData = [...state];
                newData[col][row].selected = val;
                return newData;
            })
        }
    }

    const confirmDialog = (data, col, row) => {
        confirmAlert({
            title: "Change crop season.",
            message: 'Are you sure you want to change the crop season. Previous data will be removed.',
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        setInternalData(state => {
                            const newData = [...state];
                            newData[col][row].selected = data;
                            return newData;
                        })
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    return (
        <div>
            <div class="table-responsive" style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }}>
                <table class="table table-striped table-bordered tablecured">
                    <thead>
                        <tr style={{ minWidth: "150px" }}>
                            {
                                columns && columns.map(el => {
                                    return <th className={styles["agri-portal-table"]} style={{ position: "sticky", top: "0", zIndex: "2", textAlign: "center", textOverflow: "clip", whiteSpace: "normal" }}>{el.name}</th>
                                })
                            }
                            {/* <th>Territory Code</th>
                        <th>Territory Name</th>
                        <th>Submitted On</th>
                        <th>Submitted By</th>
                        <th>Financial Year</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data && data.map((el, i) => {
                                const ele = el && el.map((e, idx) => {
                                    if (!columns) return <></>;
                                    if (!internalData) return <></>;

                                    if (!columns[idx]) return <></>;
                                    if (!internalData[i]) return <></>;
                                    if (showTotal && i == data.length - 1) return <td className={styles["agri-portal-table"]} style={{ textAlign: internalData[i][idx]?.value == "Total" ? "left" : "right", position: "sticky", bottom: "0", zIndex: "2", textOverflow: "clip", whiteSpace: "normal", backgroundColor: "#0195D4", color: "#FFF" }}><b>{internalData[i][idx]?.value}</b></td>;
                                    //FOR EDITABLE TEXT INPUTS
                                    if (columns[idx].type == "T" && columns[idx].isEditable)
                                        return <td className={styles["agri-portal-table"]} style={{ maxWidth: "100px", minWidth: "auto", textOverflow: "clip", whiteSpace: "normal" }}>
                                            <input type="text" value={internalData[i][idx]?.value} disabled={e?.meta?.isDisabled} style={{ width: "100%" }} onChange={(e) => onChangeHandler(e, i, idx, "T")} onKeyDown={(event) => { if (event.target.value.length >= MAX_TEXT_LENGTH && event.key != "Backspace") { event.preventDefault() } }} />
                                        </td>;

                                    //FOR EDITABLE NUMBER INPUTS
                                    else if (columns[idx].type == "N" && columns[idx].isEditable)
                                        return <td className={styles["agri-portal-table"]} style={{ textAlign: "right", maxWidth: "100px", minWidth: "60px", textOverflow: "clip", whiteSpace: "normal" }}>
                                            <input type="number" onWheel={(e) => e.target.blur()} value={internalData[i][idx]?.value} disabled={e?.meta?.isDisabled} style={{ width: "100%" }} onChange={(e) => onChangeHandler(e, i, idx, "N")} onKeyDown={(event) => { if (event.target.value.length >= MAX_NUMBER_LENGTH && event.key != "Backspace") { event.preventDefault() } else if (event.key == "ArrowUp" || event.key == "ArrowDown") { event.preventDefault() } else if ((!columns[idx].allowDecimalUpto && event.key === '.') || event.key === "-") { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) > 100 && event.key != "Backspace") && columns[idx].isPercentage) { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) >= 6 && event.key != "Backspace") && columns[idx].isRating) { event.preventDefault(); } }} onInput={(event) => { if (!columns[idx].allowDecimalUpto) event.target.value = event.target.value.replace(/[^0-9]*/g, ''); else if (event?.target?.value?.split(".")[1]?.length > 2) { event.target.value = event.target.value.split(".")[0] + "." + event.target.value.split(".")[1].slice(0, 2) } }} />
                                        </td>;

                                    // FOR EDITABLE DROPDOWNS
                                    // else if(columns[idx].type=="D"&&columns[idx].isEditable)
                                    // return <td style={{overflow:"visible",width:"110%"}}>
                                    // <div class="dropdown" >
                                    //     <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{position:"relative",zIndex:1000, width:"170px", display:"flex", justifyContent:"space-between"}}>
                                    //         {
                                    //             internalData[i][idx].selected?restrictToWords(internalData[i][idx].selected,20):restrictToWords("Select "+columns[idx].name,20)
                                    //         }
                                    //     </button>
                                    //     <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{zIndex:1001,width:"170px"}}>
                                    //         {
                                    //             data && data[i][idx].value.map(e=>{
                                    //                 return <div className="dropdown-item"  onClick={(event)=>dropdownClickedHandler(e,i,idx)}>{restrictToWords(e,17)}</div>
                                    //             })
                                    //         }
                                    //     </div>
                                    //     </div>
                                    // </td>;



                                    else if (columns[idx].type == "D" && columns[idx].isEditable) {

                                        return <td className={styles["agri-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "100px", textOverflow: "clip", whiteSpace: "normal" }}><select
                                            className="form-control newbgselect"
                                            name="business_unit_id"
                                            style={{ cursor: "pointer" }}
                                            disabled={e?.meta?.isDisabled}
                                            // classNamePrefix="select"
                                            onChange={(e) => {
                                                dropdownClickedHandler(e.target.value, i, idx);
                                            }}
                                            value={data[i][idx].selected}
                                        >
                                            {/* <option value="" label="Select Business Unit" /> */}
                                            {
                                                data && data[i][idx]?.value?.map(e => {

                                                    if (blacklistObj[idx]) {
                                                        if (blacklistObj[idx].find(ell => ell == e) != undefined && e !== data[i][idx].selected) return <></>;
                                                    }


                                                    if (internalData[i][idx].selected == e) {

                                                        return <option value={e} label={e} selected />
                                                    }
                                                    else
                                                        return <option value={e} label={e} />
                                                })


                                            }
                                        </select></td>;
                                    }

                                    //FOR UNEDITABLE VALUES 
                                    else if (columns[idx].type == "D" && !columns[idx].isEditable)
                                        return <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                            {e.selected}
                                        </td>

                                    else if (columns[idx].type == "N" && !columns[idx].isEditable)
                                        return <td className={styles["agri-portal-table"]} style={{ textAlign: "right", textOverflow: "clip", whiteSpace: "normal" }}>
                                            {e.value}
                                        </td>

                                    else
                                        return <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                            {e.value}
                                        </td>
                                });
                                return <tr>{ele}</tr>;
                            })
                        }
                        {/* <td>T1</td>
                        <td>T1</td>
                        <td>1-March-2022</td>
                        <td>Raj</td>
                        <td>FY 22-23</td>
                        <td>Approved</td>
                        <td>All good</td>
                        <td>View</td>                              */}
                        {/* </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ModularTable;