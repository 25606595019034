import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import * as VehicleService from "../../service/VehicalService";
import moment from "moment/moment";
import { Tooltip } from "react-tippy";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import * as VehicalService from "../../service/VehicalService";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

function VehicleProjectedReport() {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);
  const [vehicleData, setVehicleData] = useState([]);
  const [otherExpData, setotherExpData] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [travelList, setTravelList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [filterdata, setfilterData] = useState({
    Type: "",
    from_date: "",
    to_date: "",
    vehicle_id: "",
    user_id: "",
    status: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.vehicle_id,
      searchStr,
      filterdata.status,
      false
    );
  };

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    };
  };

  const getData = (
    search,
    currentpage,
    pagesize,
    sort_by,
    paginate,
    from_date,
    to_date,
    vehicle_id,
    user_id,
    status,
    current
  ) => {
    dispatch(setDisplayLoader("Display"));

    VehicalService.getVehicleProjectedInsuranceAllData(search,
      currentpage,
      pagesize,
      sort_by,
      paginate,
      from_date,
      to_date,
      vehicle_id,
      user_id,
      status,
      current
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setVehicleData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    getData(e.target.value,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.vehicle_id,
      filterdata?.user_id?.value,
      filterdata.status,
      false
    );
    setSearchStr(e.target.value);
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.username,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };
 

  const handleSearchWithDebounce = debounce(handleSearch, 500);

  useEffect(() => {
    getUsersList()
    getData(searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      filterdata.vehicle_id,
      filterdata?.user_id?.value,
      filterdata.status,
      false
    );
  }, [pageSizeNo]);

  const exportExcel = (search,
    currentpage,
    pagesize,
    sort_by,
    paginate,
    from_date,
    to_date,status,
    vehicle_id,
    user_id,
    
    current
  ) => {
    dispatch(setDisplayLoader("Display"));
    VehicalService.exportInsuranceMaintenanceExcel(search,
      currentpage,
      pagesize,
      sort_by,
      paginate,
      from_date,
      to_date,  status,
      vehicle_id,
      user_id,
    
      current
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vehicle_insurance.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const mainDataFilter = () => {
    getData(searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.vehicle_id,
      filterdata?.user_id?.value,
      filterdata.status,
      false
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getData(searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      false
    );
    setfilterData({
      from_date: "",
      to_date: "",
      user_id: { value: undefined },
    });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Vehicle Report</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">

                  <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong> Emp ID</strong>
                          </div>
                        </Tooltip>
                      }
                    >

                  <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearchWithDebounce(e)}
                      />
                    </div>

                      </OverlayTrigger>
                    
                    
                    


                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc user" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onReceive()}
                            >
                              <i class="far fa-check-circle"></i> Receive
                            </button>
                          )
                      )} */}

                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                exportExcel(searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  filterdata.from_date,
                                  filterdata.to_date,
                                  filterdata.status,
                                  filterdata.vehicle_id,
                                  filterdata?.user_id?.value,
                                  
                                  false
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>S. NO.</th>
                        <th>EMPLOYEE ID</th>
                        <th>EMP NAME</th>
                        <th>EMP VENDOR CODE</th>
                        <th>RELATION</th>
                        {/* <th>VEHICLE OWNER.</th> */}
                        <th>VEHICLE NO.</th>
                        <th>POLICY NO.</th>
                        <th>REQUEST ID</th>
                        <th>VALID FROM</th>
                        <th>VALID TILL</th>
                        <th>PREMIUM</th>
                        <th>DATE OF JOINING</th>
                        <th>Scheduler Start Date</th>
                        <th>Scheduler End Date</th>
                        <th>APROVED AMOUNT</th>
                        <th>REQUEST ID STATUS</th>
                        <th>DOCUMENT NO.</th>
                        <th>Terminate Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicleData.map((obj, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{obj?.Employee_ID}</td>
                          <td>
                            <Tooltip title={obj?.Employee_Name}>
                              {obj?.Employee_Name}
                            </Tooltip>
                          </td>
                          <td>{obj?.Employee_Vendor_Code}</td>
                          <td>{obj?.Relation}</td>
                          {/* <td>{obj?.VehicleOwnerName}</td> */}
                          <td>{obj?.Vehicle_Number}</td>
                          <td>{obj?.Policy_Number}</td>
                          <td>{obj?.Request_ID}</td>
                          <td>{obj?.Valid_from_Date}</td>
                          <td>{obj?.Valid_till_Date}</td>
                          <td>{obj?.Premium}</td>
                          <td>{obj?.Date_of_Joining}</td>
                          <td>{obj?.Scheduler_Start_Date}</td>
                          <td>{obj?.Scheduler_End_Date}</td>
                          <td>{obj?.Approved_Amount}</td>
                          <td>
                            <Tooltip title={obj?.Request_ID_Status}>
                              {obj?.Request_ID_Status}
                            </Tooltip>
                          </td>
                          <td>{obj?.Document_No}</td>
                          <td>
                            {obj?.Validity_Terminated_On != "" &&
                              obj?.Validity_Terminated_On}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      key={pageSizeNo}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata?.user_id}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VehicleProjectedReport;
