import React, { useState, useEffect } from "react";
import * as LoanDataService from "../../service/loansalary";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Modal, Badge } from "react-bootstrap";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as otvServiceData from "../../service/otvServices";
import { getOrganisation } from "../../service/businessService";
import { Tooltip } from "react-tippy";
import * as userService from "../../service/userService";
import * as jvService from "../../service/jv_service"

const DEFAULT_SELECT_OPTION = { value: null, label: "--select--" };

const OTVApprover = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)
  const [show, filtersetShow] = useState(false);
  const userDetails = useSelector(selectUserData);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    filtersetShow(false);
  };
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => {
    addnewsetShow(true);
    setEditMode(false);
    setEditData(null);
    setSelectedBU([]);
    setApprover1([]);
    setApprover2([]);
    setApprover3([]);
    setApprover4([]);
    setApprover5([]);
    setApprover6([]);
    setApproverForm({
      ...approverForm,
      organisation_id: 0,
      approver1_id: undefined,
      approver2_id: undefined,
      approver3_id: undefined,
      approver4_id: undefined,
      approver5_id: undefined,
      type: "OTV",
      case: "",
      value_limit_1: false,
      value_limit_2: false,
      value_limit_3: false,
      value_limit_4: false,
      value_limit_5: false,
      pa_id: [],
      psa_id: [],
      profit_center_id: [],
      expense_category_id: 0,
      add_apr_1_id: undefined,
      add_apr_2_id: undefined,
      add_apr_3_id: undefined,
      add_apr_4_id: undefined,
      add_apr_5_id: undefined,
      add_apr_1_from_amount: '',
      add_apr_2_from_amount: '',
      add_apr_3_from_amount: '',
      add_apr_4_from_amount: '',
      add_apr_5_from_amount: '',
      add_apr_1_to_amount: '',
      add_apr_2_to_amount: '',
      add_apr_3_to_amount: '',
      add_apr_4_to_amount: '',
      add_apr_5_to_amount: '',
      rm_approval: false,
      fh_approval: false
    })
    setCheckIndex('OTV')
    setCheckCase('E')
    setExpenseCatValue([])
    setAddApr1Value([])
    setAddApr2Value([])
    setAddApr3Value([])
    setAddApr4Value([])
    setAddApr5Value([])
    setPaValue([])
    setProfitCenterValue([])
    setPsaValue([])
  };
  const addnewclose = () => {
    addnewsetShow(false);
    setApproverForm({
      ...approverForm,
      organisation_id: 0,
      approver1_id: undefined,
      approver2_id: undefined,
      approver3_id: undefined,
      approver4_id: undefined,
      ssc_ap_processor_id: [],
      approver5_id: undefined,
      type: "",
      case: "",
      value_limit_1: false,
      value_limit_2: false,
      value_limit_3: false,
      value_limit_4: false,
      value_limit_5: false,
      pa_id: [],
      psa_id: [],
      profit_center_id: [],
      expense_category_id: 0,
      add_apr_1_id: undefined,
      add_apr_2_id: undefined,
      add_apr_3_id: undefined,
      add_apr_4_id: undefined,
      add_apr_5_id: undefined,
      add_apr_1_from_amount: '',
      add_apr_2_from_amount: '',
      add_apr_3_from_amount: '',
      add_apr_4_from_amount: '',
      add_apr_5_from_amount: '',
      add_apr_1_to_amount: '',
      add_apr_2_to_amount: '',
      add_apr_3_to_amount: '',
      add_apr_4_to_amount: '',
      add_apr_5_to_amount: '',
    });
    setEditMode(false);
    setSelectedBU([]);
    setApprover1([]);
    setApprover2([]);
    setApprover3([]);
    setApprover4([]);
    setApprover5([]);
    setApprover6([]);
    setEditData(null);
    setCWApprData()
    setCheckIndex('OTV')
    setCheckCase('E')
    setExpenseCatValue([])
    setAddApr1Value([])
    setAddApr2Value([])
    setAddApr3Value([])
    setAddApr4Value([])
    setAddApr5Value([])
    setPaValue([])
    setProfitCenterValue([])
    setPsaValue([])
  };
  const [checkIndex, setCheckIndex] = useState("OTV");
  const [businessUnit, setBusinessUnit] = useState([]);
  const [approverList, setApproverList] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [approverMasterList, setApproverMasterList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedBU, setSelectedBU] = useState([]);
  const [approver1, setApprover1] = useState([]);
  const [approver2, setApprover2] = useState([]);
  const [approver3, setApprover3] = useState([]);
  const [approver4, setApprover4] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [psaList, setPsaList] = useState([]);
  const [paList, setPaList] = useState([]);
  const [openCWApprModal, setOpenCWApprModal] = useState(false);
  const [cWApprData, setCWApprData] = useState();
  const [approver5, setApprover5] = useState([]);
  const [approver6, setApprover6] = useState([]);
  const [checkCase, setCheckCase] = useState("E");
  const [expenseCatValue, setExpenseCatValue] = useState([]);
  const [filterValueOrgId, setFilterValueOrgId] = useState();
  const [filterValueExpensId, setFilterValueExpensId] = useState();
  const [paValue, setPaValue] = useState([]);
  const [psaValue, setPsaValue] = useState([]);
  const [profitCenterValue, setProfitCenterValue] = useState([]);
  const [profitCenterList, setProfitCenterList] = useState([]);
  const [addApr1Value, setAddApr1Value] = useState([]);
  const [addApr2Value, setAddApr2Value] = useState([]);
  const [addApr3Value, setAddApr3Value] = useState([]);
  const [addApr4Value, setAddApr4Value] = useState([]);
  const [addApr5Value, setAddApr5Value] = useState([]);
  const [approverForm, setApproverForm] = useState({
    organisation_id: 0,
    approver1_id: undefined,
    approver2_id: undefined,
    approver3_id: undefined,
    approver4_id: undefined,
    approver5_id: undefined,
    ssc_ap_processor_id: [],
    type: "OTV",
    case: "",
    value_limit_1: false,
    value_limit_2: false,
    value_limit_3: false,
    value_limit_4: false,
    value_limit_5: false,
    pa_id: [],
    psa_id: [],
    profit_center_id: [],
    expense_category_id: 0,
    add_apr_1_id: undefined,
    add_apr_2_id: undefined,
    add_apr_3_id: undefined,
    add_apr_4_id: undefined,
    add_apr_5_id: undefined,
    add_apr_1_from_amount: '',
    add_apr_2_from_amount: '',
    add_apr_3_from_amount: '',
    add_apr_4_from_amount: '',
    add_apr_5_from_amount: '',
    add_apr_1_to_amount: '',
    add_apr_2_to_amount: '',
    add_apr_3_to_amount: '',
    add_apr_4_to_amount: '',
    add_apr_5_to_amount: '',
    rm_approval: false,
    fh_approval: false
  });
  const [filterNonOtv, setFilterNonOtv] = useState({
    type: "",
    case: "",
    organisation_id: "",
    expense_category_id:""
  });

  useEffect(() => {
    getOrganisationList();
    getApproverList();
    ApproverMaster(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterNonOtv.type,
      filterNonOtv.case_type,
      filterNonOtv.organisation_id,
      filterNonOtv.expense_category_id
    );
    getExpenseList()
    if (editMode) {
      let obj = {
        value: editData?.id,
        label:
          editData?.organisation_name + "(" + editData?.organisation_code + ")",
      };
      setSelectedBU(obj);
      let obj1 = {
        value: editData?.expense_category_id,
        label: editData?.expense_category_name,
      };
      setExpenseCatValue(obj1);
      let obj2 = editData?.pa_details?.map((res) => {
        return ({
          value: res?.id,
          label: res?.description,
        }
        )
      });
      setPaValue(obj2);
      let obj_pc = editData?.profit_center_details?.map((res) => {
        return ({
          value: res?.id,
          label: res?.code,
        }
        )
      });
      setProfitCenterValue(obj_pc);
      let obj_psa = editData?.psa_details?.map((res) => {
        return ({
          value: res?.id,
          label: res?.description,
        }
        )
      });
      setPsaValue(obj_psa);
      let obj_appr1 = {
        value: editData?.add_apr_1,
        label: editData?.add_approver1_name,
      };
      setAddApr1Value(obj_appr1);
      let obj_appr2 = {
        value: editData?.add_apr_2,
        label: editData?.add_approver2_name,
      };
      setAddApr2Value(obj_appr2);
      let obj_appr3 = {
        value: editData?.add_apr_3,
        label: editData?.add_approver3_name,
      };
      setAddApr3Value(obj_appr3);
      let obj_appr4 = {
        value: editData?.add_apr_4,
        label: editData?.add_approver4_name,
      };
      setAddApr4Value(obj_appr4);
      let obj_appr5 = {
        value: editData?.add_apr_5,
        label: editData?.add_approver5_name,
      };
      setAddApr5Value(obj_appr5);
      let obj3 = {
        value:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver3_id
            : "",
        label:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver3_name
            : "",
      };
      if (editData?.approver3_id != null) {
        setApprover3(obj3);
      }
      let obj4 = {
        value:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver4_id
            : editData?.type == "NONPO" && editData?.case == "NV"
              ? editData?.approver2_id
              : editData?.approver3_id,
        label:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver4_name
            : editData?.type == "NONPO" && editData?.case == "NV"
              ? editData?.approver2_name
              : editData?.approver3_name,
      };
      setApprover4(editData?.ssc_ap_details);
      let obj5 = {
        value:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver5_id
            : editData?.type == "NONPO" && editData?.case == "NV"
              ? editData?.approver3_id
              : editData?.approver4_id,
        label:
          ((editData?.type === "OTV" || editData.type === "NONPO") &&
            (editData?.case == "E" || editData?.case == 'CW'))
            ? editData?.approver5_name
            : editData?.type == "NONPO" && editData?.case == "NV"
              ? editData?.approver3_name
              : editData?.approver4_name,
      };
      setApprover5(obj5);
      setCheckCase(editData?.case);
      setCheckIndex(editData?.type);
      setApproverForm({
        ...approverForm,
        type: editData?.type,
        organisation_id: editData?.organisation_id,
        // approver1_id: editData?.approver1_id,
        // approver2_id: editData?.approver2_id,
        approver3_id: editData?.approver3_id,
        approver4_id: editData?.approver4_id,
        ssc_ap_processor_id: editData?.ssc_ap_ids,
        approver5_id: editData?.approver5_id,
        case: editData?.case,
        value_limit_1: editData?.value_limit_1,
        value_limit_2: editData?.value_limit_2,
        value_limit_3: editData?.value_limit_3,
        value_limit_4: editData?.value_limit_4,
        value_limit_5: editData?.value_limit_5,
        pa_id: editData?.pa_ids,
        psa_id: editData?.psa_ids,
        profit_center_id: editData?.profit_center_ids,
        expense_category_id: editData?.expense_category_id,
        add_apr_1_id: editData?.add_apr_1,
        add_apr_2_id: editData?.add_apr_2,
        add_apr_3_id: editData?.add_apr_3,
        add_apr_4_id: editData?.add_apr_4,
        add_apr_5_id: editData?.add_apr_5,
        add_apr_1_from_amount: editData?.add_apr_1_from_amount,
        add_apr_2_from_amount: editData?.add_apr_2_from_amount,
        add_apr_3_from_amount: editData?.add_apr_3_from_amount,
        add_apr_4_from_amount: editData?.add_apr_4_from_amount,
        add_apr_5_from_amount: editData?.add_apr_5_from_amount,
        add_apr_1_to_amount: editData?.add_apr_1_to_amount,
        add_apr_2_to_amount: editData?.add_apr_2_to_amount,
        add_apr_3_to_amount: editData?.add_apr_3_to_amount,
        add_apr_4_to_amount: editData?.add_apr_4_to_amount,
        add_apr_5_to_amount: editData?.add_apr_5_to_amount,
        rm_approval: editData?.rm_approval,
        fh_approval: editData?.fh_approval
      });
    } else {
      setApproverForm({
        ...approverForm,
        organisation_id: 0,
        approver1_id: undefined,
        approver2_id: undefined,
        approver3_id: undefined,
        approver4_id: undefined,
        ssc_ap_processor_id: [],
        approver5_id: undefined,
        type: "",
        case: "",
      });
    }
  }, [editMode]);

  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      const data = [
        { value: null, label: "--select--" },
        ...response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        }),
      ];
      setBusinessUnit(data);
    });
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const type = filterNonOtv.type;
    const case_type = filterNonOtv.case_type;
    const organisation_id = filterNonOtv.organisation_id;
    const expense_category_id = filterNonOtv.expense_category_id;
    ApproverMaster(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      type,
      case_type,
      organisation_id,
      expense_category_id
    );
    filtersetShow();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterNonOtv));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterValueExpensId()
    setFilterValueOrgId()
    setFilterNonOtv(ClearData);
    filterclose();
    ApproverMaster(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      ""
    );
  };

  const getApproverList = () => {
    LoanDataService.getApprover().then((res) => {
      setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: x.full_name + "(" + x.username + ")",
          };
        }),
      ];
      setApprovers(data);
    });
  };

  const handleSubmit = () => {
    let data = approverForm;
    if(data.approver5_id === undefined){
      toast.error("Please select SSC AP Lead and Processor")
    }
    else{
      dispatch(setDisplayLoader("Display"));
    data.type = checkIndex;
    data.case = checkCase;
    otvServiceData
      .saveApprover(data)
      .then((response) => {
        toast.success(response?.data?.message);
        dispatch(setDisplayLoader("Hide"));
        setApproverForm({
          ...approverForm,
          organisation_id: 0,
          approver1_id: undefined,
          approver2_id: undefined,
          approver3_id: undefined,
          approver4_id: undefined,
          ssc_ap_processor_id: [],
          approver5_id: undefined,
          type: "",
          case: "",
        });
        ApproverMaster(
          searchStr,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          filterNonOtv.type,
          filterNonOtv.case_type,
          filterNonOtv.organisation_id
        );
        addnewsetShow(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
    }
  };

  const handleUpdate = () => {
    let data = approverForm;
    data.type = checkIndex;
    let id = editData?.id;
    otvServiceData
      .updateApprover(id, data)
      .then((response) => {
        toast.success(response?.data?.message);
        addnewsetShow(false);
        ApproverMaster(
          searchStr,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          filterNonOtv.type,
          filterNonOtv.case_type,
          filterNonOtv.organisation_id
        );
      })
      .catch((error) => {
        toast.warning(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    type,
    case_type,
    organisation_id,
    expense_category_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (type !== "" && type !== undefined && type !== null) {
      queryParm = queryParm + "&type=" + type;
    }
    if (case_type !== "" && case_type !== undefined && case_type !== null) {
      queryParm = queryParm + "&case_type=" + case_type;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      expense_category_id !== "" &&
      expense_category_id !== undefined &&
      expense_category_id !== null
    ) {
      queryParm = queryParm + "&expense_category_id=" + expense_category_id;
    }

    return queryParm;
  };

  const ApproverMaster = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    type,
    case_type,
    organisation_id,
    expense_category_id
  ) => {
    otvServiceData
      .getApproverMaster(
        handleKey(
          search,
          page_no,
          page_size,
          sort_by,
          paginate,
          type,
          case_type,
          organisation_id,
          expense_category_id
        )
      )
      .then((response) => {
        setApproverMasterList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // let data = approverMasterList;
      // let data1 = [];
      // data.map((res) => {
      //   if (
      //     res?.type?.toLowerCase().includes(e.target.value) ||
      //     res?.approver1_name?.toLowerCase().includes(e.target.value) ||
      //     res?.approver1_code?.toLowerCase().includes(e.target.value) ||
      //     res?.business_unit_name?.toLowerCase().includes(e.target.value) ||
      //     res?.approver1_name?.toLowerCase().includes(e.target.value) ||
      //     res?.approver2_name?.toLowerCase().includes(e.target.value) ||
      //     res?.approver3_name?.toLowerCase().includes(e.target.value)
      //   ) {
      //     data1.push(res);
      //   }
      // });
      // setApproverMasterList(data1);
    }
    ApproverMaster(
      e.target.value.toLowerCase(),
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterNonOtv.type,
      filterNonOtv.case_type,
      filterNonOtv.organisation_id,
      filterNonOtv.expense_category_id
    );
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      ApproverMaster(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        filterNonOtv.type,
        filterNonOtv.case_type,
        filterNonOtv.organisation_id
      );
      setSearchStr("");
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    ApproverMaster(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterNonOtv.type,
      filterNonOtv.case_type,
      filterNonOtv.organisation_id
    );
  };

  const onEdit = (data) => {
    addnewsetShow(true);
    setEditMode(true);
    let data2 = JSON.parse(JSON.stringify(data));
    setEditData(data2);
  };

  const onPushOrgId = (e) => {
    setFilterValueOrgId(e);
    setFilterNonOtv({ ...filterNonOtv, organisation_id: e.value });
  };
  const onPushExpenseID = (e) => {
    console.log(e.value);
    setFilterValueExpensId(e);
    setFilterNonOtv({ ...filterNonOtv, expense_category_id: e.value });
    console.log(filterNonOtv);
  };

  useEffect(() => {
    ApproverMaster(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterNonOtv.type,
      filterNonOtv.case_type,
      filterNonOtv.organisation_id
    );
  }, [pageSizeNo]);

  useEffect(() => {
    let BuData = {
      value: userDetails?.organisation_id,
      label:
        userDetails?.organisation_name +
        "(" +
        userDetails?.organisation_id +
        ")",
    };
    setApproverForm({
      ...approverForm,
      organisation_id: userDetails?.branch_id !== null ? userDetails?.branch_id : 0,
    });
    setSelectedBU(BuData);
    // ApproverMaster("", 1, pageSizeNo, sortData.sort_by, true, "", "", "");
  }, []);

  const getExpenseList = () => {
    otvServiceData
      .getExpenseList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.category_name,
          };
        });
        setExpenseList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    // getPaByBranch()
    getProfitCenterByOrg()
  }, [approverForm?.organisation_id])

  // useEffect(()=>{
  //   getPsaByBranch()
  // },[approverForm?.pa_id])

  const getPsaByBranch = () => {
    let areas = approverForm.pa_id?.map((obj) => obj).toString();
    let queryParam = "personal_area_id=" + areas;
    userService.getPersonalAreaByArea(queryParam).then((response) => {
      if (response?.status === 200) {
        setPsaList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: `${each?.description} (${each?.name})`,
              value: each?.id,
            };
          })
        );
      }
    });
  };

  const getPaByBranch = () => {
    userService.getPersonalArea(approverForm?.organisation_id).then((response) => {
      if (response?.status === 200) {
        setPaList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: `${each?.description} (${each?.name})`,
              value: each?.id,
            };
          })
        );
      }
    });
  };

  const getProfitCenterByOrg = () => {
    jvService.getProfitCenterList(approverForm?.organisation_id).then((response) => {
      if (response?.status === 200) {
        setProfitCenterList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: `${each?.code}`,
              value: `${each?.id}`
            }
          })
        )
      }
    })
  }

  const closeApprModal = () => {
    setCWApprData([])
    setOpenCWApprModal(false)
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <ToastContainer autoClose={1000} />
                <div class="col-md-12">
                  <h4 class="inner-page-title">Assign Approver</h4>
                  {/* <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                          <Breadcrumb.Item active>
                            Assign Approver
                          </Breadcrumb.Item>
                        </Breadcrumb> */}
                </div>
                <div className="col-md-12">
                  <div className="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>

                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={() => {
                        filteropen();
                      }}
                      className="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Approver
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Status</th>
                    <th>Advance Type</th>
                    <th>Approval Case</th>
                    <th>Organisation Unit</th>
                    <th>Expense Category</th>
                    {/* <th>Personal Area</th>
                    <th>Sub Personal Area</th> */}
                    <th>Plant</th>
                    <th>Finance Controller</th>
                    <th>ssc AP Processor</th>
                    <th>SSC AP Lead</th>
                  </tr>
                </thead>
                <tbody>
                  {approverMasterList.length > 0 &&
                    approverMasterList?.map((data) => {
                      return (
                        <tr>
                          <td>
                            <button
                              class="btn btn-blanktd text-primary f-12"
                              type="button"
                              onClick={(e) => {
                                onEdit(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            {data?.case === "CW" && (
                              <button
                                class="btn btn-blanktd text-primary f-12 ml-2"
                                onClick={() => {
                                  setCWApprData(data)
                                  setOpenCWApprModal(true)
                                }
                                }
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            )}
                          </td>
                          <td>
                            <span class="badge bad-status badge-success">
                              Active
                            </span>
                          </td>
                          <td>{data?.type}</td>
                          <td>
                            {data?.case === "E" ? "Expectional" : data?.case === "CW" ? "Category Wise" : "Normal"}
                          </td>
                          <td>{`${data?.organisation_name} (${data?.organisation_code})`}</td>
                          <td>{data?.expense_category_name}</td>
                          {/* <td>{data?.personal_area_name}</td>
                          <td>{data?.sub_personal_area_name}</td> */}
                          <td>{data?.profit_center_names}</td>
                          <td>
                            {((data?.type === "OTV" || data.type === "NONPO") &&
                              (data?.case == "E" || data?.case == "CW"))
                              ? data?.approver3_name
                              : ""}
                          </td>
                          <td>
                            <Tooltip position="bottom" title={data?.ssc_ap_names}>
                              {data?.ssc_ap_names}
                            </Tooltip>
                          </td>
                          <td>
                            {((data?.type == "OTV" || data?.type == "NONPO") &&
                              (data?.case == "E" || data?.case == 'CW'))
                              ? data?.approver5_name
                              : data?.type == "NONPO" && data?.case == "NV"
                                ? data?.approver3_name
                                : data?.approver4_name}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode == false ? "Add Assign Approver" : "Edit Assign Approver"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup answerboxpollsuy">
                    <label className="d-block mb-2">
                      Assign Approver for<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-2">
                      OTV
                      <input
                        type="radio"
                        name="Availableotv"
                        disabled={editData?.type == "OTV"}
                        onChange={() => {
                          setCheckIndex("OTV");
                          setApproverForm({
                            ...approverForm,
                            type: "OTV",
                          });
                        }}
                        checked={checkIndex === "OTV"}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block mr-2">
                      NON PO
                      <input
                        type="radio"
                        name="AvailableNonPo"
                        disabled={editData?.type == "NONPO"}
                        onChange={() => {
                          setCheckIndex("NONPO");
                          setApproverForm({
                            ...approverForm,
                            type: "NONPO",
                          });
                        }}
                        checked={checkIndex === "NONPO"}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block mr-2">
                      NONREIMB
                      <input
                        type="radio"
                        name="AvailableNonRemb"
                        disabled={editData?.type == "NONREIMB"}
                        onChange={() => {
                          setCheckIndex("NONREIMB");
                          setApproverForm({
                            ...approverForm,
                            type: "NONREIMB",
                          });
                        }}
                        checked={checkIndex === "NONREIMB"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                {approverForm.type === "NONREIMB" ? (
                  ""
                ) : (
                  <div class="col-md-6">
                    <div class="form-group innergroup answerboxpollsuy">
                      <label className="d-block mb-2">
                        Select Approval Case<span class="text-danger">*</span>
                      </label>
                      <label class="logCheck d-inline-block mr-4">
                        Exceptional
                        <input
                          type="radio"
                          name="exception"
                          disabled={editData?.case == "valid"}
                          onChange={() => {
                            setCheckCase("E");
                            setApproverForm({
                              ...approverForm,
                              case: "E",
                            });
                          }}
                          checked={checkCase === "E"}
                        />
                        <span class="checkmark"></span>
                      </label>
                      {checkIndex === "OTV" ? (
                        <label class="logCheck d-inline-block">
                          Normal
                          <input
                            type="radio"
                            name="valid"
                            disabled={editData?.case == "exception"}
                            onChange={() => {
                              setCheckCase("V");
                              setApproverForm({
                                ...approverForm,
                                case: "V",
                              });
                            }}
                            checked={checkCase === "V"}
                          />
                          <span class="checkmark"></span>
                        </label>
                      ) : (
                        <label class="logCheck d-inline-block">
                          Normal
                          <input
                            type="radio"
                            name="valid"
                            disabled={editData?.case == "exception"}
                            onChange={() => {
                              setCheckCase("NV");
                              setApproverForm({
                                ...approverForm,
                                case: "NV",
                              });
                            }}
                            checked={checkCase === "NV"}
                          />
                          <span class="checkmark"></span>
                        </label>
                      )}
                      <label class="logCheck d-inline-block ml-4">
                        Category Wise
                        <input
                          type="radio"
                          name="category"
                          // disabled={editData?.case == "valid"}
                          onChange={() => {
                            setCheckCase("CW");
                            setApproverForm({
                              ...approverForm,
                              case: "CW",
                            });
                          }}
                          checked={checkCase === "CW"}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                )}
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Organisation<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={businessUnit}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setApproverForm({
                          ...approverForm,
                          organisation_id: e.value,
                        });
                        setSelectedBU(e);
                      }}
                      value={selectedBU}
                    />
                  </div>
                </div>
                {checkCase === "CW" && (
                  <>
                    <div class="col-md-3 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Reporting Manager Approval:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.rm_approval === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                rm_approval: true,
                              });
                            }}
                            name="rm_approval"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.rm_approval === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                rm_approval: false,
                              })
                            }
                            name="rm_approval"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Functional Head Approval:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.fh_approval === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                fh_approval: true,
                              });
                            }}
                            name="fh_approval"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.fh_approval === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                fh_approval: false,
                              })
                            }
                            name="fh_approval"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Reporting Manager (Approver 1){" "}
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Reporting Manager"
                      name="approver1_id"
                      disabled={true}
                    // value={userDetails?.reporting_to_name}
                    />
                  </div>
                </div>
                {checkIndex === "NONPO" && checkCase === "NV" ? (
                  ""
                ) : (
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Functional Head (Approver 2)
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Functional Head"
                        name="approver2_id"
                        disabled={true}
                      // value={`${
                      //   userDetails?.functional_head_fname === null
                      //     ? ""
                      //     : userDetails?.functional_head_fname
                      // } ${
                      //   userDetails?.functional_head_lname === null
                      //     ? ""
                      //     : userDetails?.functional_head_lname
                      // } ${
                      //   userDetails?.functional_head_name === null
                      //     ? ""
                      //     : userDetails?.functional_head_name
                      // }`}
                      />
                    </div>
                  </div>
                )}
                {checkCase === "CW" && (
                  <>
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Expense Category
                          <span class="text-danger">*</span>
                        </label>
                        <Select
                          options={expenseList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          name="expense_category_id"
                          onChange={(event) => {
                            setExpenseCatValue(event)
                            setApproverForm({
                              ...approverForm,
                              expense_category_id: event.value
                            })
                          }}
                          value={expenseCatValue}
                        />
                      </div>
                    </div>
                    {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Personal Area
                    </label>
                    <Select
                      name="pa"
                      isMulti
                      options={paList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        let ValueFilter = e?.map((data) => {
                          return data?.value;
                        });
                        setApproverForm({
                          ...approverForm,
                          pa_id: ValueFilter
                        })
                        setPaValue(e)
                      }}
                      value={paValue}
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Personal Sub Area
                    </label>
                    <Select
                      name="psa"
                      isMulti
                      options={psaList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        let ValueFilter = e?.map((data) => {
                          return data?.value;
                        });
                        setApproverForm({
                          ...approverForm,
                          psa_id: ValueFilter
                        })
                        setPsaValue(e)
                      }}
                      value={psaValue}
                    />
                  </div>
                </div> */}
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Plant
                        </label>
                        <Select
                          name="profit_center"
                          isMulti
                          options={profitCenterList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                          onChange={(e) => {
                            let ValueFilter = e?.map((data) => {
                              return data?.value;
                            });
                            setApproverForm({
                              ...approverForm,
                              profit_center_id: ValueFilter
                            })
                            setProfitCenterValue(e)
                          }}
                          value={profitCenterValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Additional Approver 1
                          <span class="text-danger">*</span>
                        </label>
                        <Select
                          options={approvers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Press enter to search"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_1_id: e.value,
                            });
                            setAddApr1Value(e);
                          }}
                          value={addApr1Value}
                        />
                      </div>
                    </div>
                    <div class="col-md-2 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Value Limit:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_1 === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                value_limit_1: true,
                              });
                            }}
                            name="value_limit_1"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_1 === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                value_limit_1: false,
                              })
                            }
                            name="value_limit_1"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          From Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter from amount"
                          name="add_apr_1_from_amount"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_1_from_amount: e.target.value
                            })
                          }}
                          disabled={approverForm.value_limit_1 === false}
                          value={approverForm?.add_apr_1_from_amount}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          To Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter to amount"
                          name="add_apr_1_to_amount"
                          disabled={approverForm.value_limit_1 === false}
                          value={approverForm?.add_apr_1_to_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_1_to_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Additional Approver 2
                          {/* <span class="text-danger">*</span> */}
                        </label>
                        <Select
                          options={approvers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Press enter to search"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_2_id: e.value,
                            });
                            setAddApr2Value(e);
                          }}
                          value={addApr2Value}
                        />
                      </div>
                    </div>
                    <div class="col-md-2 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Value Limit:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_2 === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                value_limit_2: true,
                              });
                            }}
                            name="value_limit_2"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_2 === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                value_limit_2: false,
                              })
                            }
                            name="value_limit_2"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          From Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter from amount"
                          name="add_apr_2_from_amount"
                          disabled={approverForm.value_limit_2 === false}
                          value={approverForm?.add_apr_2_from_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_2_from_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          To Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter to amount"
                          name="add_apr_2_to_amount"
                          disabled={approverForm.value_limit_2 === false}
                          value={approverForm?.add_apr_2_to_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_2_to_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Additional Approver 3
                          {/* <span class="text-danger">*</span> */}
                        </label>
                        <Select
                          options={approvers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Press enter to search"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_3_id: e.value,
                            });
                            setAddApr3Value(e);
                          }}
                          value={addApr3Value}
                        />
                      </div>
                    </div>
                    <div class="col-md-2 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Value Limit:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_3 === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                value_limit_3: true,
                              });
                            }}
                            name="value_limit_3"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_3 === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                value_limit_3: false,
                              })
                            }
                            name="value_limit_3"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          From Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter from amount"
                          name="add_apr_3_from_amount"
                          disabled={approverForm.value_limit_3 === false}
                          value={approverForm?.add_apr_3_from_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_3_from_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          To Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter to amount"
                          name="add_apr_3_to_amount"
                          disabled={approverForm.value_limit_3 === false}
                          value={approverForm?.add_apr_3_to_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_3_to_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Additional Approver 4
                          {/* <span class="text-danger">*</span> */}
                        </label>
                        <Select
                          options={approvers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Press enter to search"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_4_id: e.value,
                            });
                            setAddApr4Value(e);
                          }}
                          value={addApr4Value}
                        />
                      </div>
                    </div>
                    <div class="col-md-2 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Value Limit:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_4 === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                value_limit_4: true,
                              });
                            }}
                            name="value_limit_4"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_4 === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                value_limit_4: false,
                              })
                            }
                            name="value_limit_4"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          From Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter from amount"
                          name="add_apr_4_from_amount"
                          disabled={approverForm.value_limit_4 === false}
                          value={approverForm?.add_apr_4_from_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_4_from_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          To Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter to amount"
                          name="add_apr_4_to_amount"
                          disabled={approverForm.value_limit_4 === false}
                          value={approverForm?.add_apr_4_to_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_4_to_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                          Additional Approver 5
                          {/* <span class="text-danger">*</span> */}
                        </label>
                        <Select
                          options={approvers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Press enter to search"
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_5_id: e.value,
                            });
                            setAddApr5Value(e);
                          }}
                          value={addApr5Value}
                        />
                      </div>
                    </div>
                    <div class="col-md-2 innergroup">
                      <div class="form-group">
                        <label class="fw-bold mb-1">Value Limit:</label>
                        <label class="logCheck d-inline-block mr-4">
                          Yes:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_5 === true}
                            onClick={() => {
                              setApproverForm({
                                ...approverForm,
                                value_limit_5: true,
                              });
                            }}
                            name="value_limit_5"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          No:
                          <input
                            type="radio"
                            checked={approverForm.value_limit_5 === false}
                            onClick={() =>
                              setApproverForm({
                                ...approverForm,
                                value_limit_5: false,
                              })
                            }
                            name="value_limit_5"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          From Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter from amount"
                          name="add_apr_5_from_amount"
                          disabled={approverForm.value_limit_5 === false}
                          value={approverForm?.add_apr_5_from_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_5_from_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>
                          To Amount
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter to amount"
                          name="add_apr_5_to_amount"
                          disabled={approverForm.value_limit_5 === false}
                          value={approverForm?.add_apr_5_to_amount}
                          onChange={(e) => {
                            setApproverForm({
                              ...approverForm,
                              add_apr_5_to_amount: e.target.value
                            })
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {((checkIndex === "OTV" || checkIndex === "NONPO") &&
                  (checkCase === "V" || checkCase === "NV")) ||
                  checkIndex === "NONREIMB" ? (
                  ""
                ) : (
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Finance Controller<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={approvers}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Press enter to search"
                        onChange={(e) => {
                          setApproverForm({
                            ...approverForm,
                            approver3_id: e.value,
                          });
                          setApprover3(e);
                        }}
                        value={approver3}
                      />
                    </div>
                  </div>
                )}
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      SSC AP Processor <span class="text-danger"></span>
                    </label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={approvers}
                      // isDisabled={
                      //   checkIndex === "OTV" && checkCase === "E"
                      //     ? approver3.length === 0
                      //     : ""
                      // }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        let ids = e?.map((data) => {
                          return data?.value
                        })
                        setApproverForm({
                          ...approverForm,
                          ssc_ap_processor_id: ids,
                        });
                        // if (
                        //   (checkIndex === "OTV" || checkIndex === "NONPO") &&
                        //   checkCase === "E"
                        // ) {
                        //   setApproverForm({
                        //     ...approverForm,
                        //     approver4_id: ids,
                        //   });
                        // } else if (
                        //   checkIndex === "NONPO" &&
                        //   checkCase === "NV"
                        // ) {
                        //   setApproverForm({
                        //     ...approverForm,
                        //     approver2_id: ids,
                        //   });
                        // } else {
                        //   setApproverForm({
                        //     ...approverForm,
                        //     approver3_id: ids,
                        //   });
                        // }
                        setApprover4(e);
                      }}
                      value={approver4}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      SSC AP Lead <span class="text-danger"></span>
                    </label>
                    <Select
                      options={approvers}
                      isDisabled={approver4?.length === 0}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(e) => {
                        // if (
                        //   (checkIndex === "OTV" || checkIndex === "NONPO") &&
                        //   checkCase === "E"
                        // ) {
                        setApproverForm({
                          ...approverForm,
                          approver5_id: e.value,
                        });
                        // } else if (
                        //   checkIndex === "NONPO" &&
                        //   checkCase === "NV"
                        // ) {
                        //   setApproverForm({
                        //     ...approverForm,
                        //     approver3_id: e.value,
                        //   });
                        // } else {
                        //   setApproverForm({
                        //     ...approverForm,
                        //     approver4_id: e.value,
                        //   });
                        // }
                        setApprover5(e);
                      }}
                      value={approver5}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={editMode == false ? handleSubmit : handleUpdate}
          >
            {editMode == false ? "Submit" : "Update"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Type</label>
            <select
              class="form-control newbgselect"
              name="type"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  type: e.target.value,
                })
              }
              value={filterNonOtv.type}
            >
              <option value="">Select</option>
              <option value="OTV">OTV Payment Request</option>
              <option value="NONPO">Non PO Vendor Payment Request</option>
              <option value="NONREIMB">Vendor Invoice Reimb below 5K</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select Case</label>
            <select
              class="form-control newbgselect"
              name="case"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  case_type: e.target.value,
                })
              }
              value={filterNonOtv.case_type}
            >
              <option value="">Select</option>
              <option value="E">Exceptional</option>
              {filterNonOtv.type === "OTV" ? (
                <option value="V">Normal</option>
              ) : (
                <option value="NV">Normal</option>
              )}
              <option value="CW">Category Wise</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Organisation Name</label>
            <Select
              options={businessUnit}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushOrgId(e)}
              value={filterValueOrgId}
            />
          </div>
          <div class="form-group innergroup">
            <label>Expense Category</label>
            <Select
              options={expenseList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushExpenseID(e)}
              value={filterValueExpensId}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openCWApprModal}
        onHide={closeApprModal}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Category Wise Approver Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div class="col-md-4">
              <div class="form-group innergroup answerboxpollsuy">
                <label className="d-block mb-2">
                  Expense Category
                </label>
                <label class="logCheck d-inline-block mr-2">
                  {cWApprData?.expense_category_name}
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup answerboxpollsuy">
                <label className="d-block mb-2">
                  Plant
                </label>
                <label class="logCheck d-inline-block mr-2">
                  {cWApprData?.profit_center_names}
                </label>
              </div>
            </div>
            {/* <div class="col-md-4">
              <div class="form-group innergroup answerboxpollsuy">
                    <label className="d-block mb-2">
                     Personal Sub Area
                    </label>
                    <label class="logCheck d-inline-block mr-2">
                      {cWApprData?.sub_personal_area_name}
                    </label>
              </div>
          </div> */}
            <div class="col-md-4">
              <div class="form-group innergroup answerboxpollsuy">
                <label className="">
                  RM Approval :
                </label>
                <label class="logCheck d-inline-block ml-5">
                  {cWApprData?.rm_approval === true ?
                    <Badge bg="success" style={{ color: "white" }}>Yes</Badge>
                    :
                    <Badge bg="danger" style={{ color: "white" }}>No</Badge>
                  }
                </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup answerboxpollsuy">
                <label className="">
                  FH Approval :
                </label>
                <label class="logCheck d-inline-block ml-5">
                  {cWApprData?.fh_approval === true ?
                    <Badge bg="success" style={{ color: "white" }}>Yes</Badge>
                    :
                    <Badge bg="danger" style={{ color: "white" }}>No</Badge>
                  }
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>Approver Type</th>
                  <th>Approver Name</th>
                  <th>Value Limit</th>
                  <th>From Amount</th>
                  <th>To Amount</th>
                  {/* <th>Organisation Unit</th>
                    <th>Finance Controller</th>
                    <th>ssc AP Processor</th>
                    <th>SSC AP Lead</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Additional Approver 1
                  </td>
                  <td>
                    {cWApprData?.add_approver1_name}
                  </td>
                  {cWApprData?.value_limit_1 === true ?
                    <td>
                      Yes
                    </td>
                    :
                    <td>No</td>
                  }
                  <td>
                    {cWApprData?.add_apr_1_from_amount}
                  </td>
                  <td>
                    {cWApprData?.add_apr_1_to_amount}
                  </td>
                </tr>
                {cWApprData?.add_apr_2 !== null && (
                  <tr>
                    <td>
                      Additional Approver 2
                    </td>
                    <td>
                      {cWApprData?.add_approver2_name}
                    </td>
                    {cWApprData?.value_limit_2 === true ?
                      <td>
                        Yes
                      </td>
                      :
                      <td>No</td>
                    }
                    <td>
                      {cWApprData?.add_apr_2_from_amount}
                    </td>
                    <td>
                      {cWApprData?.add_apr_2_to_amount}
                    </td>
                  </tr>
                )}
                {cWApprData?.add_apr_3 !== null && (
                  <tr>
                    <td>
                      Additional Approver 3
                    </td>
                    <td>
                      {cWApprData?.add_approver3_name}
                    </td>
                    {cWApprData?.value_limit_3 === true ?
                      <td>
                        Yes
                      </td>
                      :
                      <td>No</td>
                    }
                    <td>
                      {cWApprData?.add_apr_3_from_amount}
                    </td>
                    <td>
                      {cWApprData?.add_apr_3_to_amount}
                    </td>
                  </tr>
                )}
                {cWApprData?.add_apr_4 !== null && (
                  <tr>
                    <td>
                      Additional Approver 4
                    </td>
                    <td>
                      {cWApprData?.add_approver4_name}
                    </td>
                    {cWApprData?.value_limit_4 === true ?
                      <td>
                        Yes
                      </td>
                      :
                      <td>No</td>
                    }
                    <td>
                      {cWApprData?.add_apr_4_from_amount}
                    </td>
                    <td>
                      {cWApprData?.add_apr_4_to_amount}
                    </td>
                  </tr>
                )}
                {cWApprData?.add_apr_5 !== null && (
                  <tr>
                    <td>
                      Additional Approver 5
                    </td>
                    <td>
                      {cWApprData?.add_approver5_name}
                    </td>
                    {cWApprData?.value_limit_5 === true ?
                      <td>
                        Yes
                      </td>
                      :
                      <td>No</td>
                    }
                    <td>
                      {cWApprData?.add_apr_5_from_amount}
                    </td>
                    <td>
                      {cWApprData?.add_apr_5_to_amount}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => closeApprModal()}
          >
            Close
          </button>
          {/* <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OTVApprover;
