import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getCropProductStrategy = async () => {
    return axios.get(`${AUTH_BASE_URL}/product-strategy`);
};

export const getProductStrategyCropDropdown = async (code) =>{
    return axios.get(`${AUTH_BASE_URL}/product-strategy/crop/drop-down?territory_code=${code}`)
}

export const getCropPlanData = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-plan/crop-plan-data${query}`)
}


export const getProductStrategyApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/product-strategy/approver-product-strategy-logs${query}`);
}

export const getAllStrategy = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/product-strategy/drop-down`);
}

export const postProductStrategy = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/product-strategy`,body);
}

export const putProductStrategy = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/product-strategy`,body);
}
export const getLastProductStrategy = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/product-strategy/last-product-strategy${query}`);
}

export const postProductStrategyAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/product-strategy/action-product-strategy`,body);
}