import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import AddBusiness from "./AddBusiness";
import * as BusinessService from "../../../service/businessService";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function Business() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [branch, setBranch] = useState(null);
  const [branchList, setbranchList] = useState([]);
  const [branchData, setbranchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [allDept, setAllDept] = useState([]);
  const [dropDept, setDropDept] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    branch_id: "",
    organisation_id: "",
    status: "",
  });
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    branch_id: "",
    organisation_id: "",
    status: "",
  });
  const [employeeOption, setEmployeeOption] = useState([]);

  let employeeListAbortController = null;
  
  const getEmployeeList = () => {
    employeeListAbortController = new AbortController();
    BusinessService.getEmployeeList(employeeListAbortController.signal)
      .then((response) => {
        employeeListAbortController = null;
        let empOpt = [];
        response.data.dataList.result.forEach(obj => {
          if(obj.is_active) empOpt.push({label: `${obj.first_name} ${obj.last_name} (${obj.username})`, value: obj.username, id: obj.id})
        })
        setEmployeeOption(empOpt);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getBusinessList = (
    search,
    organisation_id,
    branch_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    BusinessService.getBusinessList(
      handleKey(
        search,
        organisation_id,
        branch_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setbranchList(response?.data?.dataList?.result);
        setbranchData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    organisation_id,
    branch_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (branch_id !== "" && branch_id !== undefined && branch_id !== null) {
      queryParm = queryParm + "&branch_id=" + branch_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    organisation_id,
    branch_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    BusinessService.getExportValue(
      handleKey(
        search,
        organisation_id,
        branch_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BusinessUnit.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteBranch = (idx) => {
    let dataId = { id: idx };
    BusinessService.deleteBranch(dataId)
      .then((res) => {
        if (res.data.status == true) {
          getBusinessList(
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          // getBusinessList("", "", currentPage, pageSizeNo, "-id", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id, branchName) => {
    confirmAlert({
      title: "Delete Business Unit",
      message: `Are you sure to delete ${branchName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBranch(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const getOrganisation = (paginate) => {
    BusinessService.getOrganisation(paginate)
      .then((response) => {
        setAllDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllBranch = (paginate) => {
    BusinessService.getAllBranch(paginate)
      .then((response) => {
        setDropDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const filterShow = () => {
    filteropen();
    getOrganisation(false);
    getAllBranch(false);
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    setDepartmentDataFilter({
      branch_id: "",
      organisation_id: "",
      status: "",
    });
    filterClose();
    getBusinessList(
      searchStr,
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const filterClose = () => {
    filterclose();
  };

  const onClose = () => {
    setShowForm(false);
  };

  const editBranch = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setBranch(eData);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getBusinessList(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
  };
  const addNewBranch = () => {
    setEditMode(false);
    setShowForm(true);
    setBranch(null);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getBusinessList(
        e.target.value.toLowerCase(),
        departmentDataFilter.organisation_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.status,
        currentPage,
        pageSizeNo,
        "-id",
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBusinessList(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const mainDataFilter = (values) => {
    console.log("values.status", values.status);
    setDepartmentDataForExport({
      ...departmentDataForExport,
      branch_id: values.branch_id,
      organisation_id: values.organisation_id,
      status: values.status,
    });
    const organisation_id = values.organisation_id;
    const branch_id = values.branch_id;
    const status = values.status;
    getBusinessList(
      searchStr,
      organisation_id,
      branch_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getBusinessList(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  useEffect(() => {
    getBusinessList(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branch_id,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  useEffect(() =>{
    getEmployeeList();
    return () => {
      if(employeeListAbortController) employeeListAbortController.abort();
    }
  }, [])

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getBusinessList(
        data,
        departmentDataFilter.organisation_id,
        departmentDataFilter.branch_id,
        departmentDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Business Unit</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Business Unit</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Business Unit Code,
                        <br />
                        Business Unit Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Business Unit Code, Business Unit Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={branchList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              departmentDataForExport.organisation_id,
                              departmentDataForExport.branch_id,
                              departmentDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  class="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    class="btn btn-primary-inner"
                    onClick={() => {
                      addNewBranch();
                    }}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Business Unit Code</th>

                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Business Unit Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Organization Name & Code
                </th>
                <th
                  onClick={(e) => {
                    handlerData("created_by");
                  }}
                  className={
                    sortData["created_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["created_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.E || finalUrlValue?.R ? <th>Actions</th> : ""}
              </tr>
            </thead>
            <tbody>
              {branchList?.length ? (
                branchList?.map((data, index) => (
                  <tr>
                    <td>{data?.code}</td>
                    <td class="text-theme fw-bold">{data?.name}</td>

                    <td>
                      {data?.organisation_name} &nbsp;({data?.organisation_code}
                      )
                    </td>
                    <td>{data?.created_by} </td>

                    <td>
                      {moment.utc(data?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>
                      {data?.is_active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    {/* <td>
                          <span class="badge bad-status badge-danger">
                            InActive
                          </span>
                        </td> */}
                    {finalUrlValue?.E || finalUrlValue?.R ? (
                      <td>
                        {finalUrlValue?.E ? (
                          <button
                            class="btn btn-blanktd text-primary"
                            onClick={() => {
                              editBranch(data);
                            }}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        {finalUrlValue?.R ? (
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i
                              class="far fa-trash-alt text-danger"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            ></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {branchList?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Formik
        initialValues={departmentDataFilter}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
          filterclose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <label>Select Organization</label>
                  <select
                    className="form-control main-pointer "
                    classNamePrefix="select"
                    style={{ width: "334px" }}
                    name="organisation_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        organisation_id: e.target.value,
                      });
                    }}
                    value={departmentDataFilter.organisation_id}
                  >
                    <option value="" label="Select Organisation" disabled />
                    {renderOrgOptions(allDept)}
                  </select>
                </div>
                <div class="form-group innergroup">
                  <label>Select Business Unit</label>
                  <select
                    className="form-control main-pointer"
                    classNamePrefix="select"
                    style={{ width: "334px" }}
                    name="branch_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        branch_id: e.target.value,
                      });
                    }}
                    value={departmentDataFilter.branch_id}
                  >
                    <option value="" label="Select Business Unit" disabled />

                    {renderDeptOptions(dropDept)}
                  </select>
                </div>
                <div class="form-group innergroup">
                  <label>Select Status</label>
                  <select
                    class="form-control newbgselect main-pointer"
                    name="status"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        status: e.target.value,
                      });
                      setCurrentPage(1);
                    }}
                    value={departmentDataFilter.status}
                  >
                    <option value="" label="Select" disabled />
                    <option value={true} label="Active" />
                    <option value={false} label="Inactive" />
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    setCurrentPage(1);
                  }}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn btn-primary-inner bpi-main"
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
      {showForm ? (
        <>
          <AddBusiness
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editBranch={branch}
            onAddCallBack={onAddCallBack}
            employeeOption={employeeOption}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Business;
