import React, { useState, useEffect } from "react";
import { Tabs, Tab, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/piDataStore";
import TravelLinks from "../TravelLinks";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import * as DomesticService from "../../../service/domestic";
import * as TravelSrv from "../../../service/travellist";
import { Link } from "react-router-dom";
import { Alert, Modal } from "react-bootstrap";
import { PdfIcon } from "../../HigherEducation/Icon";
import * as TravelListingAPI from "../../../service/travelService";
import * as ClaimTravel from "../../../service/claimTravel";

function ViewTravelAll(props) {
  const {
    viewFormData,
    onViewClose,
    flagValue,
    userDetails,
    managerEditing,
    dynamicUserData,
  } = props;
  const todaysD = new Date();
  const userDetail = useSelector(selectUserData);
  const fDate = moment.parseZone(todaysD).format("DDMMYYYY");
  const [outstandingAmt, setOutstandingAmt] = useState([]);
  const [checkAdvanceValidation, setCheckAdvanceValidation] = useState();
  const [advanceAmountShow, setAdvanceAmountShow] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [outstandingValues, setOutstandingValues] = useState();
  let tstart = 1;
  let sscMangerFlagValue = 3;
  const [sscManaViewFor, set_sscManaViewFor] = useState("");
  const userData = useSelector(selectUserData);

  const [imageLocalPreviewList, setAttachment] = useState({
    daPics: [],
    conveyancePics: [],
    ticketPics: [],
    hotelPics: [],
    vehiclePics: [],
  });

  const getOutstandingAmount = (employee_code, travel_id) => {
    DomesticService.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);

        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const TravelAdvanceCheck = (data) => {
    TravelSrv.TravelAdvanceCheck(data)
      .then((response) => {
        const data = response?.data?.dataList?.is_exist;
        setCheckAdvanceValidation(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDa = (travel_id) => {
    TravelListingAPI.getDaDataList(travel_id)
      .then((response) => {
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        // let temp=[...imageLocalPreviewList]
        // temp.push(response?.data?.dataList?.attachment_path)
        // setAttachment(temp)
        setAttachment((prev) => ({
          ...prev,
          daPics: response?.data?.dataList?.attachment_path,
        }));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getTicketData = (ticket_id) => {
    ClaimTravel.getTicketData(ticket_id)
      .then((response) => {
        // setAttachment(response?.data?.dataList?.attachment_path);
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        setAttachment((prev) => ({
          ...prev,
          ticketPics: response?.data?.dataList?.attachment_path,
        }));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getConveyenceList = async (data) => {
    await TravelListingAPI.getConveyenceList(data)
      .then((response) => {
        // let res = response?.data?.dataList?.result;
        // setConveyanceList(res);
        // setConveyanceAmount(response?.data?.dataList);
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        setAttachment((prev) => ({
          ...prev,
          conveyancePics: response?.data?.dataList?.attachment_path,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getHotelList = async (data) => {
    await TravelListingAPI.getHotelList(data)
      .then((response) => {
        // let res = response?.data?.dataList?.result;
        // setConveyanceList(res);
        // setConveyanceAmount(response?.data?.dataList);
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        setAttachment((prev) => ({
          ...prev,
          hotelPics: response?.data?.dataList?.attachment_path,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVehicleList = async (data) => {
    await TravelListingAPI.getHotelList(data)
      .then((response) => {
        // let res = response?.data?.dataList?.result;
        // setConveyanceList(res);
        // setConveyanceAmount(response?.data?.dataList);
        // setAttachment([...imageLocalPreviewList, ...response?.data?.dataList?.attachment_path]);
        // setAttachment(prev => ({...prev, vehiclePics: response?.data?.dataList?.attachment}));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // console.log(`managerEditing`,managerEditing,dynamicUserData);
    if (flagValue == 3) {
      let params = new URL(document.location).searchParams;
      let idquery = params.get("id");
      set_sscManaViewFor(idquery);
      getOutstandingAmount(viewFormData?.created_by_id, "");
    } else {
      getOutstandingAmount(userDetails?.id, "");

      if (viewFormData?.request_type == "International") {
      }

      getDa(viewFormData?.id);
      getTicketData(viewFormData?.id);
      getConveyenceList(viewFormData?.id);
      getHotelList(viewFormData?.id);
      getVehicleList(viewFormData?.id);
    }
  }, []);

  function DocumentPreview(props) {
    return (
      <div className="col-md-1 mb-2">
        <a
          href={props.docData.attachment_path}
          target="_blank"
          rel="noreferrer"
        >
          {props.docData?.type ? (
            props.docData.type == "pdf" ? (
              <div>
                <PdfIcon width={120} height={85} />
              </div>
            ) : (
              <img
                width="70px"
                height="70px"
                src={props.docData.attachment_path}
                // style={{ aspectRatio: "16/9" }}
              />
            )
          ) : props.docData.attachment_path.split(".")[
              props.docData.attachment_path.split(".").length - 1
            ] == "pdf" ? (
            <div>
              <PdfIcon width={120} height={85} />
            </div>
          ) : (
            <img
              width="70px"
              height="70px"
              src={props.docData.attachment_path}
              // style={{ aspectRatio: "16/9" }}
            />
          )}
        </a>
      </div>
    );
  }

  useEffect(() => {
    if (userDetails?.id !== undefined) {
      TravelAdvanceCheck(userDetails?.id);
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      ((!["1003", "1007", "1017"].includes(userDetails?.personal_area_name) ||
        (["1003", "1007", "1017"].includes(userDetails?.personal_area_name) &&
          userDetails?.sub_personal_area_name === "GG01")) &&
        userDetails?.is_sf_user === true) ||
      checkAdvanceValidation == true
    ) {
      setAdvanceAmountShow(true);
    }
  }, [checkAdvanceValidation]);

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <TravelLinks />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                {flagValue === 1 ? (
                  <div class="col-md-12">
                    <h3 class="policyhead">
                      View Travel Intimation:
                      {/* PITI{fDate}
                      {userDetails?.username}
                      {viewFormData?.id} */}
                      {viewFormData?.travel_intimation_number}
                    </h3>
                  </div>
                ) : (
                  <div class="col-md-12">
                    <h3 class="policyhead">
                      View Travel Intimation:
                      {
                        flagValue == sscMangerFlagValue
                          ? ` ${sscManaViewFor}`
                          : `${viewFormData?.travel_intimation_number}`
                        // PITI${fDate}${userDetails?.username}${viewFormData?.id}
                      }
                    </h3>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="defaultchatgptquest text-left">
                    {flagValue != sscMangerFlagValue && (
                      <Link
                        onClick={onViewClose}
                        className="badge badge-secondary"
                      >
                        <i className="fas fa-arrow-circle-left"></i> Back
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {flagValue === 1 ? (
                ""
              ) : (
                <div class="col-md-12" style={{ textAlign: "right" }}>
                  <h6 class="f-14 mt-2">
                    <span class="text-muted">
                      Reporting Manager -
                      {managerEditing && dynamicUserData
                        ? dynamicUserData?.reporting_to_fname
                        : userData?.reporting_to_fname}{" "}
                      {managerEditing && dynamicUserData
                        ? dynamicUserData?.reporting_to_lname
                        : userData?.reporting_to_lname}{" "}
                      (
                      {managerEditing && dynamicUserData
                        ? dynamicUserData?.reporting_to_name
                        : userData?.reporting_to_name}
                      )
                    </span>
                  </h6>
                </div>
              )}
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs defaultActiveKey="Domestic" id="approvaltabs">
                <Tab
                  eventKey="Domestic"
                  title={
                    <React.Fragment>
                      {viewFormData?.request_type}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th
                            style={{
                              maxWidth: "55px",
                            }}
                          >
                            Departure Location
                          </th>
                          <th
                            style={{
                              maxWidth: "55px",
                            }}
                          >
                            Reaching Location
                          </th>
                          <th
                            style={{
                              maxWidth: "20px",
                            }}
                          >
                            Purpose
                          </th>
                          <th
                            style={{
                              maxWidth: "30px",
                            }}
                          >
                            Mode
                          </th>
                          <th>HQ</th>
                          <th
                            style={{
                              maxWidth: "20px",
                            }}
                          >
                            Stay
                          </th>
                          <th
                            style={{
                              maxWidth: "60px",
                            }}
                          >
                            Colleague <br />
                            Empcode
                          </th>
                          <th
                            style={{
                              maxWidth: "50px",
                            }}
                          >
                            <Tooltip title={"Exception"} position="left">
                              Exception
                            </Tooltip>
                          </th>
                          <th
                            style={{
                              maxWidth: "50px",
                            }}
                          >
                            <Tooltip title={"Reason"} position="left">
                              Reason
                            </Tooltip>
                          </th>
                          <th>Additional Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewFormData?.travel_detail_data?.map(
                          (data, index) => (
                            <>
                              <tr>
                                <td>
                                  <div class="text-dark fw-bold f-14">
                                    {viewFormData?.request_type ===
                                    "International"
                                      ? `${data?.from_continent_name} (${
                                          data?.from_country_name
                                        } ||  ${
                                          data?.from_city_name === "Other"
                                            ? data?.from_city_input
                                            : data?.from_city_name
                                        })`
                                      : `${
                                          data?.from_city_name === "Other"
                                            ? data?.from_city_input
                                            : data?.from_city_name
                                        }`}
                                    {/* {data?.from_city_name === "Other"
                                      ? data?.from_city_input
                                      : data?.from_city_name}
                                    -{data?.from_city_category} */}
                                  </div>{" "}
                                  {moment
                                    .parseZone(data?.from_date)
                                    .format("DD-MM-YYYY")}
                                  &nbsp;
                                  {/* {data?.from_time.substring(0, 5)} */}
                                  {moment
                                    .parseZone(data?.from_time, "h:mm A")
                                    .format("HH:mm A")}
                                </td>
                                <td>
                                  <div class="text-dark fw-bold f-14">
                                    {viewFormData?.request_type ===
                                    "International"
                                      ? `${data?.to_continent_name} (${
                                          data?.to_country_name
                                        } ||  ${
                                          data?.to_city_name === "Other"
                                            ? data?.to_city_input
                                            : data?.to_city_name
                                        })`
                                      : `${
                                          data?.to_city_name === "Other"
                                            ? data?.to_city_input
                                            : data?.to_city_name
                                        }`}
                                    {/* {data?.to_city_name === "Other"
                                      ? data?.to_city_input
                                      : data?.to_city_name}
                                    -{data?.to_city_category} */}
                                  </div>
                                  {moment
                                    .parseZone(data?.to_date)
                                    .format("DD-MM-YYYY")}
                                  &nbsp;
                                  {/* {data?.to_time.substring(0, 5)} */}
                                  {moment
                                    .parseZone(data?.to_time, "h:mm A")
                                    .format("HH:mm A")}
                                </td>
                                <td> {data?.purpose}</td>
                                <td> {data?.mode_name}</td>
                                <td>{data?.hq ? "Yes" : "No"}</td>
                                <td> {data?.stay_needed ? "Yes" : "No"}</td>
                                <td
                                  style={{
                                    maxWidth: "40px",
                                  }}
                                >
                                  {data?.colleague_user_code}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "40px",
                                  }}
                                >
                                  {data?.reason_for_exception_name ? (
                                    <i
                                      class="fa fa-flag"
                                      aria-hidden="true"
                                      style={{ color: "red" }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "40px",
                                  }}
                                >
                                  <Tooltip
                                    title={data?.reason_for_exception_name}
                                    position="left"
                                  >
                                    {data?.reason_for_exception_name
                                      ? data?.reason_for_exception_name
                                      : ""}
                                  </Tooltip>
                                </td>
                                {/* <td>{data?.hq ? "Yes" : "No"}</td> */}

                                <td
                                  style={{
                                    maxWidth: "130px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Tooltip
                                    title={data?.additional_information}
                                    position="left"
                                  >
                                    {data?.additional_information}
                                  </Tooltip>
                                </td>
                              </tr>
                            </>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  {!flagValue ? (
                    <div class="row">
                      <div class="col-md-12">
                        <p
                          style={{
                            color: "#0195d4",
                            fontWeight: "bold",
                          }}
                        >
                          Travel Approve History
                        </p>
                        <div class="table-responsive ">
                          <table class="table table-striped table-bordered tablecured viewpo">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Approver Name</th>
                                <th>Approver Level</th>
                                <th>Status</th>
                                <th>Approved Date</th>
                                <th>Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {viewFormData?.approver_remark_log?.map(
                                (data, idx) => (
                                  <tr>
                                    <td>{idx + 1}</td>

                                    <td>{data?.approver_name}</td>

                                    <td>{data?.approver_position}</td>

                                    <td>
                                      {data?.status == "A" ? (
                                        <span class="badge bad-status badge-success">
                                          Approved
                                        </span>
                                      ) : data?.status == "R" ? (
                                        <span class="badge bad-status badge-danger">
                                          Rejected
                                        </span>
                                      ) : !data?.status ? (
                                        <span class="badge bad-status badge-warning">
                                          Pending
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>

                                    <td>
                                      {data?.status &&
                                        data?.status != "P" &&
                                        data?.created_at &&
                                        moment
                                          .parseZone(data?.created_at)
                                          .format("DD-MM-YYYY")}
                                      &nbsp;
                                      {moment(
                                        data?.created_at.split("T")[1],
                                        "h:mm A"
                                      ).format("HH:mm A")}
                                    </td>
                                    <td>{data?.remark}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : flagValue === 1 ? (
                    <>
                      <div class="row">
                        <div class="col-md-12">
                          <p
                            style={{
                              color: "#0195d4",
                              fontWeight: "bold",
                            }}
                          >
                            Expense Approval History
                          </p>
                          <div class="table-responsive ">
                            <table class="table table-striped table-bordered tablecured viewpo">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Type</th>
                                  <th>Approver Name</th>
                                  <th>Approver Level</th>
                                  <th>Status</th>
                                  <th>Approved Date</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {viewFormData?.approver_remark_log?.map(
                                  (data, index) => (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>Intimation</td>
                                        <td>
                                          {`${data?.approver_name} (${data?.approver_username})`}
                                        </td>
                                        <td>
                                          {/* <span>{data?.approver_type}</span> */}
                                          <span>{`Approver ${index + 1}`}</span>
                                        </td>
                                        <td>
                                          {!data?.status
                                            ? "Pending"
                                            : data?.status == "A"
                                            ? "Approved"
                                            : data?.status == "R"
                                            ? "Rejected"
                                            : ""}
                                        </td>
                                        <td>
                                          {/* {data?.status &&
                                            data?.status != "P" &&
                                            data?.updated_at &&
                                            moment
                                              .parseZone(data?.updated_at)
                                              .format("DD-MM-YYYY hh:mm A")} */}
                                          {data?.status &&
                                            data?.status != "P" &&
                                            data?.created_at &&
                                            moment
                                              .parseZone(data?.created_at)
                                              .format("DD-MM-YYYY hh:mm A")}
                                        </td>
                                        <td>{data?.remark}</td>
                                      </tr>
                                    </>
                                  )
                                )}

                                {viewFormData?.expense_exception &&
                                  viewFormData?.expense_approver_remark_data?.map(
                                    (data, index) => (
                                      <>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>Expense </td>
                                          <td>
                                            {`${data?.approver_name} (${data?.approver_username})`}
                                          </td>
                                          <td>
                                            <span>{data?.approver_type}</span>
                                          </td>
                                          <td>
                                            {!data?.status
                                              ? "Pending"
                                              : data?.status == "A"
                                              ? "Approved"
                                              : data?.status == "R"
                                              ? "Rejected"
                                              : ""}
                                          </td>
                                          <td>
                                            {data?.updated_at ?
                                              moment
                                                .parseZone(data?.updated_at)
                                                .format("DD-MM-YYYY hh:mm A"):""}
                                          </td>
                                          <td>{data?.remark}</td>
                                        </tr>
                                      </>
                                    )
                                  )}

                                {/* <tr>
                                <td>{tstart}</td>

                                <td>
                                  {viewFormData?.claim_flag
                                    ? viewFormData?.expense_approval_status ||
                                      viewFormData?.is_edit
                                      ? viewFormData?.is_edit
                                        ? "Requestor"
                                        : viewFormData?.expense_approval_status ==
                                          "A"
                                        ? !viewFormData?.is_picked
                                          ? "SSC Executive"
                                          : viewFormData?.is_picked &&
                                            !viewFormData?.ssc_user_status
                                          ? `SSC Executive (${viewFormData?.is_picked_by_user_name} (${viewFormData?.is_picked_by_user_code}))`
                                          : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            !viewFormData?.ssc_manager_status
                                          ? `SSC Manager `
                                          : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            viewFormData?.ssc_manager_status ==
                                              "F" &&
                                            !viewFormData?.ssc_head_status
                                          ? `SSC Head `
                                          : // : data?.is_picked &&
                                            //   data?.ssc_user_status &&
                                            //   data?.ssc_manager_status
                                            // ? "Approved"
                                            ""
                                        : viewFormData?.expense_approval_status ==
                                          "R"
                                        ? // ? data?.approver_remark_data?.map(
                                          //     (rdata) => {
                                          //       return (
                                          //         rdata?.status == "R" &&
                                          //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                          //       );
                                          //     }
                                          //   )
                                          // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                          viewFormData?.expense_approver_remark_data?.map(
                                            (rdata) => {
                                              return (
                                                rdata?.status == "R" &&
                                                rdata?.approver_username ==
                                                  viewFormData
                                                    ?.current_expense_exception_approver_data
                                                    ?.current_approver_code &&
                                                `${rdata?.approver_name} (${rdata?.approver_username})`
                                              );
                                            }
                                          )
                                        : ""
                                      : viewFormData
                                          ?.current_expense_exception_approver_data
                                          ?.current_approver_name &&
                                        `${viewFormData?.current_expense_exception_approver_data?.current_approver_name} (${viewFormData?.current_expense_exception_approver_data?.current_approver_code})`
                                    : ""}
                                </td>

                                <td>
                                  {viewFormData?.claim_flag
                                    ? viewFormData?.expense_approval_status ||
                                      viewFormData?.is_edit
                                      ? viewFormData?.is_edit
                                        ? "Requestor"
                                        : viewFormData?.expense_approval_status ==
                                          "A"
                                        ? !viewFormData?.is_picked
                                          ? "In Pick List"
                                          : viewFormData?.is_picked &&
                                            !viewFormData?.ssc_user_status
                                          ? `SSC Executive (${viewFormData?.is_picked_by_user_name} (${viewFormData?.is_picked_by_user_code}))`
                                          : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            !viewFormData?.ssc_manager_status
                                          ? `SSC Manager `
                                          : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            viewFormData?.ssc_manager_status ==
                                              "F" &&
                                            !viewFormData?.ssc_head_status
                                          ? `SSC Head `
                                          : // : data?.is_picked &&
                                            //   data?.ssc_user_status &&
                                            //   data?.ssc_manager_status
                                            // ? "Approved"
                                            ""
                                        : viewFormData?.expense_approval_status ==
                                          "R"
                                        ? // ? data?.approver_remark_data?.map(
                                          //     (rdata) => {
                                          //       return (
                                          //         rdata?.status == "R" &&
                                          //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                          //       );
                                          //     }
                                          //   )
                                          // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                          viewFormData?.expense_approver_remark_data?.map(
                                            (rdata) => {
                                              return (
                                                rdata?.status == "R" &&
                                                rdata?.approver_username ==
                                                  viewFormData
                                                    ?.current_expense_exception_approver_data
                                                    ?.current_approver_code &&
                                                `${rdata?.approver_position}`
                                              );
                                            }
                                          )
                                        : ""
                                      : viewFormData
                                          ?.current_expense_exception_approver_data
                                          ?.current_approver_name &&
                                        `${viewFormData?.current_expense_exception_approver_data?.current_approver_name} (${viewFormData?.current_expense_exception_approver_data?.current_approver_code})`
                                    : ""}
                                </td>

                                <td>
                                  {viewFormData?.is_picked &&
                                  viewFormData?.ssc_user_status &&
                                  (viewFormData?.ssc_manager_status == "A" ||
                                    viewFormData?.ssc_head_status == "A") ? (
                                    <span class="badge bad-status badge-success">
                                      Approved
                                    </span>
                                  ) : viewFormData?.expense_approval_status ==
                                    "R" ? (
                                    `Rejected`
                                  ) : viewFormData?.claim_flag ? (
                                    "Pending"
                                  ) : (
                                    ""
                                  )}
                                </td>

                                <td>
                                  {viewFormData?.is_picked &&
                                  viewFormData?.ssc_user_status &&
                                  (viewFormData?.ssc_manager_status == "A" ||
                                    viewFormData?.ssc_head_status == "A") ? (
                                    <span class="badge bad-status badge-success"></span>
                                  ) : viewFormData?.expense_approval_status ==
                                    "R" ? (
                                    viewFormData?.expense_approver_remark_data?.map(
                                      (rdata) => {
                                        return (
                                          rdata?.status == "R" &&
                                          rdata?.approver_username ==
                                            viewFormData
                                              ?.current_expense_exception_approver_data
                                              ?.current_approver_code &&
                                          rdata?.updated_at &&
                                          moment
                                            .parseZone(rdata?.updated_at)
                                            .format("DD-MM-YYYY")
                                        );
                                      }
                                    )
                                  ) : (
                                    ""
                                  )}
                                </td>

                                <td>
                                  {viewFormData?.expense_approval_status == "R"
                                    ? viewFormData?.expense_approver_remark_data?.map(
                                        (rdata) => {
                                          return (
                                            rdata?.status == "R" &&
                                            rdata?.approver_username ==
                                              viewFormData
                                                ?.current_expense_exception_approver_data
                                                ?.current_approver_code &&
                                            `${rdata?.remark}`
                                          );
                                        }
                                      )
                                    : ""}
                                </td>
                              </tr> */}

                                {viewFormData?.ssc_expense_approver_remark_data?.map(
                                  (data, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {data?.approver_type == "SSC User"
                                          ? "SSC Executive"
                                          : data?.approver_type}
                                      </td>
                                      <td>
                                        {`${data?.approver_name} (${data?.approver_username})`}
                                        {/* {data?.approver_type=="SSC User"?"SSC Executive":data?.approver_type} */}
                                      </td>
                                      <td>
                                        {/* <span>{data?.approver_type}</span> */}
                                        <span>
                                          {data?.approver_type == "SSC User"
                                            ? "Approver 1"
                                            : data?.approver_type ==
                                              "SSC Manager"
                                            ? "Approver 2"
                                            : data?.approver_type}
                                        </span>
                                      </td>
                                      <td>
                                        {!data?.status
                                          ? "Pending"
                                          : data?.status == "APPROVED"
                                          ? "Approved"
                                          : data?.status == "R"
                                          ? "Rejected"
                                          : data?.status}
                                      </td>
                                      <td>
                                        {/* {data?.status &&
                                            data?.status != "P" &&
                                            data?.updated_at &&
                                            moment
                                              .parseZone(data?.updated_at)
                                              .format("DD-MM-YYYY hh:mm A")} */}
                                        {data?.approved_at
                                          ? moment
                                              .parseZone(data?.approved_at)
                                              .format("DD-MM-YYYY hh:mm A")
                                          : ""}
                                      </td>
                                      <td>{data?.remark}</td>
                                    </tr>
                                  )
                                )}

                                {/* <tr>
                                  <td>
                                    {viewFormData?.approver_remark_data
                                      ?.length +
                                      viewFormData?.expense_approver_remark_data
                                        ?.length +
                                      1}
                                  </td>
                                  <td>SSC Executive</td>
                                  <td>
                                    SSC Executive{" "}
                                    {viewFormData.is_picked_by_user_name ??
                                      `(${viewFormData.is_picked_by_user_name} (${viewFormData.is_picked_by_user_code}))`}
                                  </td>
                                  <td>SSC Executive</td>
                                  <td>
                                    {viewFormData.expense_approval_status == "A"
                                      ? viewFormData?.ssc_user_status == "A"
                                        ? "Approved"
                                        : "Pending"
                                      : "-"}
                                  </td>
                                  <td>
                                    {viewFormData.ssc_user_approved_at &&
                                      moment
                                        .parseZone(
                                          viewFormData?.ssc_user_approved_at
                                        )
                                        .format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    {viewFormData?.approver_remark_data
                                      ?.length +
                                      viewFormData?.expense_approver_remark_data
                                        ?.length +
                                      2}
                                  </td>
                                  <td>SSC Manager</td>
                                  <td>SSC Manager</td>
                                  <td>SSC Manager</td>
                                  <td>
                                    {viewFormData.expense_approval_status == "D"
                                      ? "Discarded"
                                      : viewFormData.expense_approval_status ==
                                        "A"
                                      ? viewFormData?.ssc_manager_status ==
                                          "A" ||
                                        viewFormData?.ssc_manager_status == "F"
                                        ? "Approved"
                                        : "Pending"
                                      : "-"}
                                  </td>
                                  <td>
                                    {viewFormData.ssc_manager_approved_at &&
                                      moment
                                        .parseZone(
                                          viewFormData?.ssc_manager_approved_at
                                        )
                                        .format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td></td>
                                </tr>
                                {viewFormData?.ssc_user_status == "A" &&
                                  (viewFormData?.ssc_manager_status == "F" ||
                                    !viewFormData?.ssc_manager_status) && (
                                    <tr>
                                      <td>
                                        {viewFormData?.approver_remark_data
                                          ?.length +
                                          viewFormData
                                            ?.expense_approver_remark_data
                                            ?.length +
                                          3}
                                      </td>
                                      <td>SSC Head</td>
                                      <td>SSC Head</td>
                                      <td>SSC Head</td>
                                      <td>
                                        {viewFormData.expense_approval_status ==
                                        "A"
                                          ? viewFormData?.ssc_head_status == "A"
                                            ? "Approved"
                                            : "Pending"
                                          : "-"}
                                      </td>
                                      <td>
                                        {viewFormData.ssc_head_approved_at &&
                                          moment
                                            .parseZone(
                                              viewFormData?.ssc_head_approved_at
                                            )
                                            .format("DD-MM-YYYY hh:mm A")}
                                      </td>
                                      <td></td>
                                    </tr>
                                  )} */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <p
                            style={{
                              color: "#0195d4",
                              fontWeight: "bold",
                            }}
                          >
                            Attachments
                          </p>
                          <div class="d-flex flex-wrap">
                            {[
                              ...imageLocalPreviewList.daPics,
                              ...imageLocalPreviewList.ticketPics,
                              ...imageLocalPreviewList.conveyancePics,
                              ...imageLocalPreviewList.hotelPics,
                              ...imageLocalPreviewList.vehiclePics,
                            ].map((idata, idx) => (
                              <DocumentPreview
                                docData={idata}
                                // removeDocumentByIdx={removeDocumentByIdx}
                                index={idx}
                                key={idata.id}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    flagValue === 3 && (
                      <div class="row">
                        <div class="col-md-12">
                          <p
                            style={{
                              color: "#0195d4",
                              fontWeight: "bold",
                            }}
                          >
                            {/* console.log(`MANAGER VIEWING`); */}
                            Approver history
                          </p>
                          <div class="table-responsive ">
                            <table class="table table-striped table-bordered tablecured viewpo">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Approver Name</th>
                                  <th>Approver Level</th>
                                  <th>Status</th>
                                  <th>Approved Date</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* {viewFormData?.expense_approval_status=="R" && viewFormData?.expense_approver_remark_data?.map(
                              (data, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {`${data?.approver_name} (${data?.approver_username})`}
                                    </td>
                                    <td>
                                      <span>{data?.approver_type}</span>
                                    </td>
                                    <td>
                                      {!data?.status
                                        ? "Pending"
                                        : data?.status == "A"
                                        ? "Approved"
                                        : data?.status == "R"
                                        ? "Rejected"
                                        : ""}
                                    </td>
                                    <td>
                                      {data?.remark !== null &&
                                        moment.parseZone(data?.updated_at).format(
                                          "DD-MM-YYYY"
                                        )}
                                    </td>
                                    <td>{data?.remark}</td>
                                  </tr>
                                </>
                              )
                            )} */}
                                <tr>
                                  <td>{tstart}1</td>

                                  <td>
                                    {viewFormData?.claim_flag ? (
                                      viewFormData?.expense_approval_status ||
                                      viewFormData?.is_edit ? (
                                        viewFormData?.is_edit ? (
                                          "Requestor"
                                        ) : viewFormData?.expense_approval_status ==
                                          "A" ? (
                                          !viewFormData?.is_picked ? (
                                            <Tooltip title="Document sent but not received by SSC">
                                              Document sent but not received by
                                              SSC
                                            </Tooltip>
                                          ) : viewFormData?.is_picked &&
                                            !viewFormData?.ssc_user_status ? (
                                            `SSC Executive (${viewFormData?.is_picked_by_user_name} (${viewFormData?.is_picked_by_user_code}))`
                                          ) : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            !viewFormData?.ssc_manager_status ? (
                                            `SSC Manager `
                                          ) : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            viewFormData?.ssc_manager_status ==
                                              "F" &&
                                            !viewFormData?.ssc_head_status ? (
                                            `SSC Head `
                                          ) : (
                                            // : data?.is_picked &&
                                            //   data?.ssc_user_status &&
                                            //   data?.ssc_manager_status
                                            // ? "Approved"
                                            ""
                                          )
                                        ) : viewFormData?.expense_approval_status ==
                                          "R" ? (
                                          // ? data?.approver_remark_data?.map(
                                          //     (rdata) => {
                                          //       return (
                                          //         rdata?.status == "R" &&
                                          //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                          //       );
                                          //     }
                                          //   )
                                          // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                          viewFormData?.expense_approver_remark_data?.map(
                                            (rdata) => {
                                              return (
                                                rdata?.status == "R" &&
                                                rdata?.approver_username ==
                                                  viewFormData
                                                    ?.current_expense_exception_approver_data
                                                    ?.current_approver_code &&
                                                `${rdata?.approver_name} (${rdata?.approver_username})`
                                              );
                                            }
                                          )
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        viewFormData
                                          ?.current_expense_exception_approver_data
                                          ?.current_approver_name &&
                                        `${viewFormData?.current_expense_exception_approver_data?.current_approver_name} (${viewFormData?.current_expense_exception_approver_data?.current_approver_code})`
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    {viewFormData?.claim_flag ? (
                                      viewFormData?.expense_approval_status ||
                                      viewFormData?.is_edit ? (
                                        viewFormData?.is_edit ? (
                                          "Requestor"
                                        ) : viewFormData?.expense_approval_status ==
                                          "A" ? (
                                          !viewFormData?.is_picked ? (
                                            <Tooltip title="Document sent but not received by SSC">
                                              Document sent but not received by
                                              SSC
                                            </Tooltip>
                                          ) : viewFormData?.is_picked &&
                                            !viewFormData?.ssc_user_status ? (
                                            `SSC Executive (${viewFormData?.is_picked_by_user_name} (${viewFormData?.is_picked_by_user_code}))`
                                          ) : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            !viewFormData?.ssc_manager_status ? (
                                            `SSC Manager `
                                          ) : viewFormData?.is_picked &&
                                            viewFormData?.ssc_user_status &&
                                            viewFormData?.ssc_manager_status ==
                                              "F" &&
                                            !viewFormData?.ssc_head_status ? (
                                            `SSC Head `
                                          ) : (
                                            // : data?.is_picked &&
                                            //   data?.ssc_user_status &&
                                            //   data?.ssc_manager_status
                                            // ? "Approved"
                                            ""
                                          )
                                        ) : viewFormData?.expense_approval_status ==
                                          "R" ? (
                                          // ? data?.approver_remark_data?.map(
                                          //     (rdata) => {
                                          //       return (
                                          //         rdata?.status == "R" &&
                                          //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                          //       );
                                          //     }
                                          //   )
                                          // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                          viewFormData?.expense_approver_remark_data?.map(
                                            (rdata) => {
                                              return (
                                                rdata?.status == "R" &&
                                                rdata?.approver_username ==
                                                  viewFormData
                                                    ?.current_expense_exception_approver_data
                                                    ?.current_approver_code &&
                                                `${rdata?.approver_position}`
                                              );
                                            }
                                          )
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        viewFormData
                                          ?.current_expense_exception_approver_data
                                          ?.current_approver_name &&
                                        `${viewFormData?.current_expense_exception_approver_data?.current_approver_name} (${viewFormData?.current_expense_exception_approver_data?.current_approver_code})`
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    {viewFormData?.is_picked &&
                                    viewFormData?.ssc_user_status &&
                                    (viewFormData?.ssc_manager_status == "A" ||
                                      viewFormData?.ssc_head_status == "A") ? (
                                      <span class="badge bad-status badge-success">
                                        Approved
                                      </span>
                                    ) : viewFormData?.expense_approval_status ==
                                      "R" ? (
                                      `Rejected`
                                    ) : viewFormData?.claim_flag ? (
                                      "Pending"
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    {viewFormData?.is_picked &&
                                    viewFormData?.ssc_user_status &&
                                    (viewFormData?.ssc_manager_status == "A" ||
                                      viewFormData?.ssc_head_status == "A") ? (
                                      <span class="badge bad-status badge-success"></span>
                                    ) : viewFormData?.expense_approval_status ==
                                      "R" ? (
                                      viewFormData?.expense_approver_remark_data?.map(
                                        (rdata) => {
                                          return (
                                            rdata?.status == "R" &&
                                            rdata?.approver_username ==
                                              viewFormData
                                                ?.current_expense_exception_approver_data
                                                ?.current_approver_code &&
                                            rdata?.updated_at &&
                                            moment
                                              .parseZone(rdata?.updated_at)
                                              .format("DD-MM-YYYY")
                                          );
                                        }
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  <td>
                                    {viewFormData?.expense_approval_status ==
                                    "R"
                                      ? viewFormData?.expense_approver_remark_data?.map(
                                          (rdata) => {
                                            return (
                                              rdata?.status == "R" &&
                                              rdata?.approver_username ==
                                                viewFormData
                                                  ?.current_expense_exception_approver_data
                                                  ?.current_approver_code &&
                                              `${rdata?.remark}`
                                            );
                                          }
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <div class="px-4 pb-2 mt-3">
                    {viewFormData?.request_type !== "On Duty" ? (
                      <div class="mb-3">
                        <div class="row">
                          <div class="col-md-12 mt-3">
                            <h5 class="text-theme border-bottom pb-2 mb-3">
                              {/* <strong>{advanceAmountShow ? "Advance" : "Outstanding"} Details</strong> */}
                              <strong>Outstanding Details</strong>
                            </h5>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label class="d-flex w-100 align-items-center">
                                Outstanding
                                <i class="fas fa-rupee-sign ml-1 mr-1"></i>
                              </label>
                              <input
                                type="text"
                                disabled
                                value={` Rs.${
                                  viewFormData?.outstanding === null ||
                                  viewFormData?.outstanding === undefined
                                    ? outstandingAmt?.balance
                                    : viewFormData?.outstanding
                                } `}
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-14">
                              <Link onClick={addnewopen}>
                                Click here to view (Outstanding Details)
                              </Link>
                            </div>
                          </div>
                          {0 > 1 && advanceAmountShow && (
                            <div class="col-md-4">
                              <div class="form-group innergroup">
                                <label class="d-flex w-100 align-items-center">
                                  Advance Amount{" "}
                                  <i class="fas fa-rupee-sign ml-1 mr-1"></i>{" "}
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder="Enter Advance Amount"
                                  value={viewFormData?.advance_amount}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {flagValue != sscMangerFlagValue && (
                          <div class="col-md-12 mt-3 text-center">
                            <button
                              type="button"
                              class="btn btn-outline-secondary mr-2"
                              onClick={onViewClose}
                            >
                              Back
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div className="col-md-12">
              <div className="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Bill Date</th>
                      <th>Text</th>
                      <th>Assignment number</th>
                      <th>Document Number</th>
                      <th>Posting Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outstandingValues?.length > 0 ? (
                      outstandingValues?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value?.__values__?.DMBTR}</td>
                          <td>{value?.__values__?.WAERS}</td>
                          <td>
                            {moment
                              .parseZone(value?.__values__?.BLDAT)
                              .format("DD-MM-YYYY")}
                          </td>
                          <td
                            style={{
                              maxWidth: "80vh",
                            }}
                          >
                            {value?.__values__?.SGTXT}
                          </td>
                          <td>{value?.__values__?.ZUONR}</td>
                          <td>{value?.__values__?.BELNR}</td>
                          <td>
                            {moment
                              .parseZone(value?.__values__?.BUDAT)
                              .format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewTravelAll;
