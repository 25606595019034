 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"; 
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab  } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

import * as UserService from "../../service/userService"
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";

function TravelConveyanceExpReport() { 

    const navigate = useNavigate();
    
    const options = [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' }
    ]
    

    const [show, filtersetShow] = useState(false); 
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" }); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [searchStr, setSearchStr] = useState("");
    const [hotelDetails, setHotelDetails]  = useState([]);
    const [usersDropdown, setUsersDropdown] = useState([]);
    const finalUser = useSelector(selectUserData);

    const [totalValue, setTotalValue] = useState(0.0);

    const [filterdata, setfilterData] = useState({
        user_id: {value:undefined},
        trip_from: "",
        trip_to: "",
        from_date: "",
        to_date: "",
        search_by:""
        
      });


    const handleKey = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status
      ) => {
        let queryParm =
          "page_no=" +
          page_no +
          "&page_size=" +
          page_size +
          "&paginate=" +
          paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
          queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
          queryParm = queryParm + "&search=" + search;
        }
        if (from_date !== "" && from_date !== undefined && from_date !== null) {
          queryParm = queryParm + "&from_date=" + from_date;
        }
        if (to_date !== "" && to_date !== undefined && to_date !== null) {
          queryParm = queryParm + "&to_date=" + to_date;
        }
        if (user_id !== "" && user_id !== undefined && user_id !== null) {
          queryParm = queryParm + "&user_id=" + user_id;
        }
        if (status !== "" && status !== undefined && status !== null) {
          queryParm = queryParm + "&status=" + status;
        }
    
        return queryParm;
      };
    
      
      
  const handleSearch = (e) => {
    
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      
        getHotelExpenseReport(
        e.target.value,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        filterdata?.user_id?.value,
      
      filterdata?.from_date,
      filterdata?.to_date,"","",""
        )

    
    setSearchStr(e.target.value);
    }
  };




      
  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    getHotelExpenseReport(searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
      
    filterdata?.user_id?.value,
      
      filterdata?.from_date,
      filterdata?.to_date,
      ""
        )
    
  
    filterclose();
  };
  
  
  const clearfilter = () => {
    filterclose();
   
    getHotelExpenseReport(searchStr,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
         "","","","",""
        )
    setfilterData({
        user_id: {value:undefined},
      from_date: "",
      to_date: "",
      trip_from:"",
      trip_to:"",
      search_by:""

    });
    
  };

    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        
        setCurrentPage(activePage);

        getHotelExpenseReport(searchStr,
            activePage,
            pageSizeNo,
            sortData.sort_by,
            true,
            filterdata?.user_id?.value,
      
      filterdata?.from_date,
      filterdata?.to_date,"","",""
            )
            

      };

      const getUsersList = () => {
        UserService.getUsersListDataNew(0).then((res) => {
          // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.id,
                label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
              };
            }),
          ];
          setUsersDropdown(data);
          
        });
      };
    
    useEffect(()=>{
        if(usersDropdown?.length<1){
        getUsersList()}
        
        getHotelExpenseReport(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            filterdata?.user_id?.value,
      
      filterdata?.from_date,
      filterdata?.to_date,"","",""
            )
    },[pageSizeNo])

    const getHotelExpenseReport = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status
    ) =>{
        TravelListingAPI.getLocalConveyanceReport(
            handleKey(
                search,
                page_no,
                page_size,
                sort_by,
                paginate,
                user_id,
                from_date,
                to_date,
                status
              ) +`&hr_id=`+finalUser?.id

        ).then((response)=>{
            let value = 0;
            response?.data?.dataList?.result.map((obj) => {
                value += obj?.total_claim_amount
            });
            setTotalValue(value)
            setHotelDetails(response?.data?.dataList?.result)
            setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        })
    }


    const getHotelExpenseReportExport = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status
    ) =>{
        TravelListingAPI.getLocalConveyanceReportExport(
            handleKey(
                search,
                page_no,
                page_size,
                sort_by,
                paginate,
                user_id,
                from_date,
                to_date,
                status
              )+
            "&is_export=true"+`&hr_id=`+finalUser?.id).then((response)=>{

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Local_Conveyance_Report.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();

        })
    }
    
    return (
        <>
            <div class="container-fluid">
                        <div class="row">
                                {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li class="active"><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

                            <div class="col-md-12">
                                <div class="content-wrapper-inner content-wrapper-inner2">
                                    <div class="innerheadsec">
                                        <div className="row">
                                            <div class="col-md-12">
                                                <h4 class="inner-page-title">Local Conveyance Report</h4>
                                            </div> 
                                            <div class="col-md-12">
                                                <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-lg-4">

                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip
                                                        className="bs-tooltip-end tooltip"
                                                        id="overlay-example"
                                                        >
                                                        <div class="tooltip-inner text-left">
                                                            Search on <strong> Name, Emp ID, trip no.</strong>
                                                        </div>
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <div class="input-group table-search">
                                                        <span class="input-group-text">
                                                        <img src="images/search.png" alt="" />
                                                        </span>
                                                        <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            handleSearch(e);
                                                        }}
                                                        />
                                                    </div>
                                                    </OverlayTrigger>
                                               
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="inline-spacing btn-betweenspaing">
                                                    <Dropdown show>
                                                        <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                            <img src="images/export.png" alt="" class="mr-2" /> Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#"
                                                            onClick={() =>
                                                                
                                                                getHotelExpenseReportExport(searchStr,
                                                                    1,pageSizeNo,sortData.sort_by,true,filterdata?.user_id?.value,
      
                                                                    filterdata?.from_date,
                                                                    filterdata?.to_date,"","","")
                                                            }
                                                            ><i class="far fa-file-excel"></i>Excel</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered tablecured">  
                                            <thead> 
                                                <tr>
                                                
                                                <td>Sr.No 	</td>
                                                <td>Employee ID 	</td>
                                                <td>Name 	</td>
                                                <td>Trip No 	</td>
                                                <td>Date 	</td>
                                                <td>Conveyance 	</td>
                                                <td>Mode 	</td>
                                                <td>Ven State 	</td>
                                                <td>Bill Amount 	</td>
                                                <td>Claim Tax 	</td>
                                                <td>Total Claim Amount 	</td>
                                                <td>Base Appr. Amount 	</td>
                                                <td>Approved Tax 	</td>
                                                <td>Total Appr. Amt. 	</td>
                                                <td>Vendor Code 	</td>
                                                <td>Grade 	</td>
                                                <td>Reporting Head 	</td>
                                                <td>Location 	</td>
                                                <td>Zone 	</td>
                                                <td>Accounting doc no.</td>
                                                </tr> 
                                            </thead>
                                            <tbody> 
                                            {hotelDetails?.length > 0 ?
                                                hotelDetails?.map((data, index)=>{
                                                    return(
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.user_code}</td>
                                                    <td>{data?.user_name}</td>
                                                    <td>
                                                        
                                                        <Link
                                                        to={`/travel-view-list?${data?.travel_id}`}
                                                        target="_blank"
                                                    >
                                                        {data?.sap_trip ?? "-"}
                                                    </Link>
                                                    </td>
                                                    <td>{data?.conveyance_date?moment.parseZone(data?.conveyance_date).format("DD-MM-YYYY"):""}</td>
                                                    <td>{data?.from_location_name}-{data?.to_location_name}</td>
                                                    <td>{data?.mode_name}</td>
                                                    {/* <td>{data?.check_in_date && data?.check_out_date? moment.parseZone(data?.check_out_date).diff(moment(data?.check_in_date, "YYYY-MM-DD"), "days"):""}</td> */}
                                                    {/* <td>{data?.stay_at}</td>
                                                    <td>{data?.hotel_name}</td>
                                                    <td>{data?.hotel_vendor_category}</td>
                                                    <td>{data?.hotel_gst_no}</td>
                                                    <td>{data?.invoice_no}</td> */}
                                                    {/* <td>{data?.invoice_date? moment.parseZone(data?.invoice_date).format("DD-MM-YYYY"):""}</td> 
                                                    <td>{data?.expense_by}</td> */}

                                                    <td>{data?.vendor_state__name}</td>
                                                    <td>{data?.amount}</td>
                                                    {/* <td>{data?.tax}</td> */}
                                                    <td>{data?.tax_amount}</td>
                                                    {/* <td>{data?.total_bill_amount}</td> */}
                                                    {/* <td>{data?.claim_amount}</td> */}
                                                    <td>{data?.total_claim_amount}</td>
                                                    <td>{data?.appr_bill_amount}</td>
                                                    {/* <td>{data?.appr_tax}</td> */}
                                                    <td>{data?.appr_tax_amount}</td>
                                                    <td>{data?.approved_amount}</td>
                                                    <td>{data?.user_vendor_code}</td>
                                                    <td>{data?.grade}</td>
                                                    <td>{data?.reporting_user_name}</td>
                                                    <td>{data?.location}</td>
                                                    <td>{data?.zone}</td>
                                                    <td>{data?.accounting_doc_no}</td>
                                                </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={12}>No Records Found...</td>
                                            </tr>
                                            }
                                            </tbody>
                                        </table>

                                    </div> 

                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="sortinglist">
                                                Show
                                                <select
                                                    class="form-control main-pointer"
                                                    onChange={(e) => {
                                                        setPageSizeNo(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                    >
                                                    <option value={10} label={10}>
                                                        10
                                                    </option>
                                                    <option value={20} label={20}>
                                                        20
                                                    </option>
                                                    <option value={30} label={30}>
                                                        30
                                                    </option>
                                                    <option value={100} label={100}>
                                                        100
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                        <ReactPaginate
                                            key={pageSizeNo}
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={handlePageClick}
                                            disableInitialCallback={true}
                                            containerClassName={"pagination justify-content-left"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                        </div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
            </div>

    

            <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                        
                    <div class="form-group innergroup">
                        <label>Employee ID  <span class="text-danger">*</span></label>
                        <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                                ...filterdata,
                                user_id: e,
                              }) 
                                  
                        }
                        value={filterdata?.user_id}
                        />
                    </div> 

                    <div class="form-group innergroup">
                        <label>Trip Start Date  <span class="text-danger">*</span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        from_date:e.currentTarget.value
                        })}
                        value={filterdata.from_date}/>
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip End Date  <span class="text-danger">*</span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        to_date:e.currentTarget.value
                        })}
                        value={filterdata.to_date}/>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <button 
                    class="btn btn-outline-danger"
                     onClick={() => clearfilter()}
                    //  onClick={filterclose}
                    >
                        Cancel</button>
                    <button 
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => mainDataFilter()}
                    >Apply</button>
                </Modal.Footer>
            </Modal>

            

        </>
    );
}

export default TravelConveyanceExpReport;