import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { toast, ToastContainer } from "react-toastify";
import { getAnalytics } from "../../service/analytics";
import Chart from "react-apexcharts";

import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";

function Analytics() {
  // const options = [
  //     { value: 'Option1', label: 'Option1' },
  //     { value: 'Option2', label: 'Option2' },
  //     { value: 'Option3', label: 'Option3' },
  //     { value: 'Option4', label: 'Option4' },
  //     { value: 'Option5', label: 'Option5' }
  // ]

  // const selrole = [
  //     { value: 'Admin', label: 'Admin' },
  //     { value: 'Manager', label: 'Manager' },
  //     { value: 'Accountant', label: 'Accountant' },
  //     { value: 'Engineer', label: 'Engineer' },
  //     { value: 'Team Lead', label: 'Team Lead' }
  // ]

  // const organiz = [
  //     { value: 'Admin', label: 'Sheelafoam (SH001)' },
  //     { value: 'Manager', label: 'Sheelafoam (SH001)' },
  //     { value: 'Accountant', label: 'Sheelafoam (SH001)' },
  //     { value: 'Engineer', label: 'Sheelafoam (SH001)' },
  //     { value: 'Team Lead', label: 'Sheelafoam (SH001)' }
  // ]
  const [options, setOptions] = useState({
    series: [75, 56],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      labels: ["Web", "Mobile"],
      colors: ["#4A9BD4", "#F8E822"],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
      },
      title: {
        text: "",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [options1, setOptions1] = useState({
    series: [42, 47, 52, 58, 65],
    options: {
      chart: {
        width: 380,
        type: "polarArea",
      },
      labels: ["Modules 1", "Modules 2", "Modules 3", "Modules 4", "Modules 5"],
      colors: ["#F8E822", "#4A9BD4", "#4ABBD4", "#80B9E1", "#C9E1F2"],
      fill: {
        opacity: 1,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      yaxis: {
        show: false,
      },
      legend: {
        position: "right",
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
          spokes: {
            strokeWidth: 0,
          },
        },
      },
    },
  });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const [viewnewshow, viewnewsetShow] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);
  const [data, setData] = useState();
  const getData = () => {
    getAnalytics()
      .then((res) => {
        console.log(res?.data?.dataList?.result);
        setData(res?.data?.dataList?.result);
        let data = res?.data?.dataList?.result;
        // data.system_adoption.module_uses
        let module = [];
        let percentage = [];
        data.system_adoption.module_uses.map((x) => {
          module.push(x.module_name);
          percentage.push(x.percentage.toFixed(2));
        });
        setOptions1((prev) => ({
          ...prev,
          options: {
            ...prev.options,

            labels: module,
          },
          series: percentage,
        }));
        setOptions((prev) => ({
          ...prev,
          // options: {
          //     ...prev.options,
          //     labels: ["jai", "harsh"]
          // },
          series: [
            data.system_adoption.traffic?.web_traffic?.percentage,
            data.system_adoption.traffic?.mobile_traffic?.percentage,
          ],
        }));
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec px-4 pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">User</h4>
            </div>
            <div className="col-md-12">
              <div class="analytic-boxes">
                <div class="analytic-content">
                  <h3>Total Active Users</h3>
                  <div class="d-flex justify-content-between mt-3 align-items-end">
                    <div>
                      <img src="images/analy/1.png" />
                    </div>
                    <h4>{data?.user?.active_users}</h4>
                  </div>
                </div>
                <div class="analytic-content analytic-content1">
                  <h3>Frequent Users</h3>
                  <div class="d-flex justify-content-between mt-3 align-items-end">
                    <div>
                      <img src="images/analy/2.png" />
                    </div>
                    <h4>{data?.user?.frequent_users}</h4>
                  </div>
                </div>
                <div class="analytic-content analytic-content2">
                  <h3>Rare Users</h3>
                  <div class="d-flex justify-content-between mt-3 align-items-end">
                    <div>
                      <img src="images/analy/3.png" />
                    </div>
                    <h4>{data?.user?.rare_users}</h4>
                  </div>
                </div>
                <div class="analytic-content analytic-content3">
                  <h3>Single Sign On</h3>
                  <div class="d-flex justify-content-between mt-3 align-items-end">
                    <div>
                      <img src="images/analy/4.png" />
                    </div>
                    <h4>{data?.user?.single_sign_on}</h4>
                  </div>
                </div>
                <div class="analytic-content analytic-content4">
                  <h3>Using Password</h3>
                  <div class="d-flex justify-content-between mt-3 align-items-end">
                    <div>
                      <img src="images/analy/5.png" />
                    </div>
                    <h4>{data?.user?.using_password}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrapper-inner">
        <div class="innerheadsec px-4 pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">System Adoption</h4>
            </div>
            <div class="col-md-6">
              <div class="chart-boxes">
                <div class="d-flex justify-content-between poll-sna-head">
                  <h3>Traffic - Web & Mobile</h3>
                  <select class="newbgselect">
                    <option>Monthly</option>
                  </select>
                </div>
                <Chart
                  options={options?.options}
                  series={options?.series}
                  type="donut"
                  width={380}
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="chart-boxes">
                <div class="d-flex justify-content-between poll-sna-head">
                  <h3>Module Uses</h3>
                  <select class="newbgselect">
                    <option>Weekly</option>
                  </select>
                </div>
                <Chart
                  options={options1?.options}
                  series={options1?.series}
                  type="polarArea"
                  width={380}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="poll-sna-box">
                    <div class="d-flex justify-content-between poll-sna-head">
                      <h3>Polls Status</h3>
                      <select class="newbgselect">
                        <option>Monthly</option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s1.png" />
                          </div>
                          <h4>
                            Uploaded
                            <span>
                              {data?.system_adoption?.polls_status?.uploaded}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s2.png" />
                          </div>
                          <h4>
                            Engagement
                            <span>
                              {data?.system_adoption?.polls_status?.engagement}
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="poll-sna-box policy-ana-box">
                    <div class="d-flex justify-content-between poll-sna-head">
                      <h3>Policies Status</h3>
                      <select class="newbgselect">
                        <option>Monthly</option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s2.png" />
                          </div>
                          <h4>
                            Total Uploaded Policies
                            <span>
                              {data?.system_adoption?.policies_status?.uploaded}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s3.png" />
                          </div>
                          <h4>
                            Number Of Views
                            <span>
                              {data?.system_adoption?.policies_status
                                ?.engagement / 1000}
                              K
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="poll-sna-box news-ana-box">
                    <div class="d-flex justify-content-between poll-sna-head">
                      <h3>News Status</h3>
                      <select class="newbgselect">
                        <option>Monthly</option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s2.png" />
                          </div>
                          <h4>
                            Uploaded
                            <span>
                              {data?.system_adoption?.news_status?.uploaded}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div class="col-6 pl-0">
                        <div class="d-flex justify-content-start mt-3 align-items-end">
                          <div class="pol-icon">
                            <img src="images/analy/s3.png" />
                          </div>
                          <h4>
                            Views
                            <span>
                              {data?.system_adoption?.news_status?.engagement /
                                1000}
                              K
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrapper-inner">
        <div class="innerheadsec px-4 pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Ticket</h4>
            </div>
            <div className="col-md-12">
              <div class="row">
                <div class="col-md-2 col-6">
                  <div class="ticketbg">
                    <h3>Unresolved</h3>
                    <h4>{data?.tickets?.unresolved}</h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="ticketbg ticketbg1">
                    <h3>Overdue</h3>
                    <h4>{data?.tickets?.overdue}</h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="ticketbg ticketbg2">
                    <h3>Due Today</h3>
                    <h4>{data?.tickets?.due_today}</h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="ticketbg ticketbg3">
                    <h3>Open</h3>
                    <h4>{data?.tickets?.open}</h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="ticketbg ticketbg4">
                    <h3>On Hold</h3>
                    <h4>{data?.tickets?.on_hold}</h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="ticketbg ticketbg5">
                    <h3>Unassigned</h3>
                    <h4>{data?.tickets?.unassigned}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Analytics;
