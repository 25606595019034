import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import CreateNewSurvey from "./CreateNewSurvey";
import SelectTemp from "./SelectTemp";
import QuestionsForm from "./QuestionsForm";
import PublishSetting from "./PublishSetting";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as surveyService from "../../service/Survey";

function AddSurvey() {
  const [tabStatus, setTabStatus] = useState("Primary");
  const [tabType, setTabType] = useState("");
  const [templateData, setTemplateData] = useState(null);
  const { state } = useLocation();
  const { edit, flag } = state;
  const [responseData, setResponseData] = useState([]);
  // const [isViewingForTemplate,set_isViewingForTemplate] = useState(false)

  useEffect(() => {
    if (flag === "true") {
      setTabStatus("Addressess");
    }
  }, [flag]);

  const saveSurvey = (values) => {
    surveyService
      .saveSurvey(values)
      .then((response) => {
        setResponseData(response?.data?.dataList);
        setTabStatus("publish");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Add New Survey</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">Company</Breadcrumb.Item>
                <Breadcrumb.Item href="/survey">Survey</Breadcrumb.Item>
                <Breadcrumb.Item active>Add New</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div class="addusertabs">
          <Tabs
            activeKey={tabStatus}
            id="adduserinfo"
            onSelect={(e) => setTabStatus(e)}
          >
            <Tab
              eventKey="Primary"
              title={
                <React.Fragment>
                  <span>1</span> Create New
                </React.Fragment>
              }
            >
              <CreateNewSurvey setTabStatus={setTabStatus} />
            </Tab>
            <Tab
              eventKey="Personal"
              title={
                <React.Fragment>
                  <span>2</span> Select Templates
                </React.Fragment>
              }
            >
              <SelectTemp
                setTabStatus={setTabStatus}
                tabStatus={tabStatus}
                setTemplateData={setTemplateData}
              />
            </Tab>
            <Tab
              eventKey="Addressess"
              title={
                <React.Fragment>
                  <span>3</span> Add/Edit Questions
                </React.Fragment>
              }
            >
              <QuestionsForm
                setTabStatus={setTabStatus}
                edit={edit}
                tabStatus={tabStatus}
                saveSurvey={saveSurvey}
                setTabType={setTabType}
                tabType={tabType}
                templateData={templateData}
                isViewingForTemplate={true}
              />
            </Tab>
            <Tab
              eventKey="publish"
              title={
                <React.Fragment>
                  <span>4</span> Publish Setting
                </React.Fragment>
              }
            >
              <PublishSetting
                tabType={tabType}
                setTabstatus={setTabStatus}
                responseData={responseData}
                tabStatus={tabStatus}
                edit={edit}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default AddSurvey;
