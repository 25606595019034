import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { Tabs, Tab, Alert } from "react-bootstrap";
import Select from "react-select";
import * as UserService from "../../service/userService";
import * as HeaderService from "../../service/jv_service";
import * as ActivePoServices from "../../service/activePo";
import * as otvServiceData from "../../service/otvServices";
import OtvBulkImport from "./otvBulkImport";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import SuggestionsList from "./AutoSuggest";
import { setDisplayLoader, selectFinalUrlPermission, setTracker, selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";

function OTVRequest() {
  let condition = "false";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trackerData, setTrackerData] = useState({
    permission_id: 0,
    transaction_id: 0,
    attachment_id: 0,
  });
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryValue, setCountryValue] = useState([]);
  const [stateValue, setStateValue] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [glCodeList, setGlCodeList] = useState([]);
  const [vendorDataList, setVendorDataList] = useState([]);
  const [vendorValue, setVendorValue] = useState([]);
  const [glCodeValue, setGlCodeValue] = useState([]);
  const [costCenterDisable, setCostCenterDisable] = useState(false);
  const [costCenterValue, setcostCenterValue] = useState([]);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [errorAttachment1, setErrorAttachment1] = useState(false);
  const [businessPlaceList, setBusinessPlaceList] = useState([]);
  const [businessAreaList, setBusinessAreaList] = useState();
  const [file, setfiles] = useState({});
  const [imgSize, setImgSize] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseValue, setExpenseValue] = useState([]);
  const [businessPlaceValue, setBusinessPlaceValue] = useState([]);
  const [businessAreaValue, setBusinessAreaValue] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [plantValue, setPlantValue] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [suggestError, setSuggestError] = useState(false);
  const [isBankNumberExists, setIsBankNumberExists] = useState(false);
  const [isIfscCodeExists, setIsIfscCodeExists] = useState(false);
  const [editOtvData, setEditOtvData] = useState();
  const [orgData, setOrgData] = useState();
  const [ifscNumberCode, setIfscNumberCode] = useState(false);
  const [valueCountryCode, setValueCountryCode] = useState();
  const [attachment, setAttachment] = useState([]);
  const [existsValueData, setExistsValueData] = useState({
    invoiceNumber: "",
    invoiceDate: "",
  });
  const { state } = useLocation();
  const [vendorInvoiceExists, setVendorInvoiceExists] = useState(false);
  const [gstReminder, setGstReminder] = useState(false);
  const [error, setError] = useState({
    otv_name: "",
    street: "",
    state_id: "",
    country_id: "",
    otv_city_name: "",
    postal_code: "",
    invoice_number: "",
    invoice_date: "",
    gross_amount: "",
    expense_category_id: "",
    business_place_id: "",
    business_area_id: "",
    plant_id: "",
    gl_id: "",
    service_desc: "",
    cost_center_id: "",
    bank_name: "",
    bank_account_number: "",
    bank_ifsc_code: "",
  });
  const [otvData, setOtvData] = useState({
    street: "",
    otv_name: "",
    state_id: 0,
    country_id: 0,
    otv_city_name: "",
    type: "OTV",
    postal_code: "",
    invoice_number: "",
    invoice_date: "",
    gross_amount: 0,
    expense_category_id: 0,
    business_place_id: undefined,
    business_area_id: undefined,
    plant_id: undefined,
    gl_id: 0,
    cost_center_id: 0,
    organisation_id: undefined,
    service_desc: "",
    bank_name: "",
    bank_account_number: "",
    bank_ifsc_code: "",
    attachments: [],
    company_code: "",
    pan_no: "",
    gst_no: "",
  });

  const getCountryList = (paginate) => {
    UserService.getCountryList(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result?.map((val) => {
          let dataSet = {
            value: val.id,
            label: val.name,
            code: val.code,
          };
          data.push(dataSet);
        });
        setCountryList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getStateList = (code) => {
    otvServiceData.getOtvStateList(code)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.map((val) => {
          let dataSet = {
            value: val.id,
            label: val.state_name,
          };
          data.push(dataSet);
        });
        setStateList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCityList = (id) => {
    UserService.getCityList(id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.map((val) => {
          let dataSet = {
            value: val.id,
            label: val.name,
          };
          data.push(dataSet);
        });
        setCityList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCostCenter = (id) => {
    HeaderService.getCostCenter(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
            org_id: res?.organisation_id
          };
        });
        setCostCenterList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGlCodeList = (type) => {
    otvServiceData
      .getGlCodeList(type)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.desc + ")",
            code: res.code,
          };
        });
        setGlCodeList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExpenseList = () => {
    otvServiceData
      .getExpenseList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.category_name,
          };
        });
        setExpenseList(data);
        getVendorId(0);
        getBusinessPlaceList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessPlaceList = (id) => {
    HeaderService.getBusinessPlaceList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.description + ")",
          };
        });
        setBusinessPlaceList(data);
        getBusinessAreaList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessAreaList = (id) => {
    HeaderService.getBusinessAreaList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
            organisation_name: res.organisation_name,
          };
        });
        setBusinessAreaList(data);
        getPlantList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPlantList = (id) => {
    HeaderService.getPlantList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVendorId = (id) => {
    ActivePoServices.getVendorId(id)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          // let data = {
          //   value: x?.id,
          //   // label: `${x?.name} (${x?.code})`,
          //   name: x?.name,
          // };
          plant.push(`${x?.name} (${x?.code})`);
        });
        setVendorDataList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const suggestions = vendorDataList;

  const onChange = (event) => {
    const value = event.target.value;
    handleChange(value, "otv_name");
    setInputValue(value);
    setSuggestError(false);

    const filteredSuggestions = suggestions?.filter((suggestion) =>
      suggestion?.toLowerCase()?.includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };

  const onSelectSuggestion = (index) => {
    setSuggestError(true);
    // setSelectedSuggestion(index);
    // setInputValue(filteredSuggestions[index]);
    // setFilteredSuggestions([]);
    // setDisplaySuggestions(false);
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "otv_name":
        setOtvData((prev) => ({
          ...prev,
          otv_name: event,
        }));
        break;
      case "street":
        setOtvData((prev) => ({
          ...prev,
          street: event.target.value,
        }));
        break;
      case "state_id":
        setOtvData((prev) => ({
          ...prev,
          state_id: event.value,
        }));
        setOtvData((prev) => ({
          ...prev,
          otv_city_name: "",
        }));
        break;
      case "country_id":
        setOtvData((prev) => ({
          ...prev,
          country_id: event.value,
        }));
        setOtvData((prev) => ({
          ...prev,
          state_id: "",
        }));
        break;
      case "otv_city_name":
        setOtvData((prev) => ({
          ...prev,
          otv_city_name: event.target.value,
        }));
        break;
      case "postal_code":
        if (event.target.value.length < length) {
          setOtvData((prev) => ({
            ...prev,
            postal_code: event.target.value,
          }));
        }
        break;
      case "invoice_number":
        if (event.target.value.length < length) {
          setOtvData((prev) => ({
            ...prev,
            invoice_number: event.target.value,
          }));
          setExistsValueData({
            ...existsValueData,
            invoiceNumber: event.target.value,
          });
        }
        break;
      case "invoice_date":
        setOtvData((prev) => ({
          ...prev,
          invoice_date: event.target.value,
        }));
        setExistsValueData({
          ...existsValueData,
          invoiceDate: event.target.value,
        });
        break;
      case "gross_amount":
        setOtvData((prev) => ({
          ...prev,
          gross_amount: event.target.value,
        }));
        break;
      case "gl_id":
        setOtvData((prev) => ({
          ...prev,
          gl_id: event.value,
        }));
        break;
      case "expense_category_id":
        setOtvData((prev) => ({
          ...prev,
          expense_category_id: event.value,
        }));
        break;
      case "cost_center_id":
        setOtvData((prev) => ({
          ...prev,
          cost_center_id: event.value,
          organisation_id: event.org_id
        }));

        break;
      case "bank_name":
        if (!event.target.value.match(regex)) {
          setOtvData((prev) => ({
            ...prev,
            bank_name: event.target.value,
          }));
        }
        break;
      case "bank_account_number":
        if (event.target.value.length < length) {
          setOtvData((prev) => ({
            ...prev,
            bank_account_number: event.target.value.trim(),
          }));
          isBankAccNumber(event.target.value);
        }
        break;
      case "bank_ifsc_code":
        if (event.target.value.length < length) {
          setOtvData((prev) => ({
            ...prev,
            bank_ifsc_code: event.target.value,
          }));
        }
        break;
      case "service_desc":
        setOtvData((prev) => ({
          ...prev,
          service_desc: event.target.value,
        }));
        break;
      case "business_place_id":
        setOtvData((prev) => ({
          ...prev,
          business_place_id: event.value,
        }));
        break;
      case "business_area_id":
        setOtvData((prev) => ({
          ...prev,
          business_area_id: event.value,
        }));
        let string = event?.label?.split("(")[1];
        let code = string.substring(0, string.length - 1);
        let company_code = "";
        if (code === "PIAG") {
          company_code = "PIIL";
        } else if (code === "PIJA") {
          company_code = "PIJA";
        } else if (code === "PKAG") {
          company_code = "PIKM";
        }
        setOtvData((prev) => ({
          ...prev,
          company_code: company_code,
        }));
        break;
      case "plant_id":
        setOtvData((prev) => ({
          ...prev,
          plant_id: event.value,
        }));
        break;
      case "pan_no":
        setOtvData((prev) => ({
          ...prev,
          pan_no: event.target.value,
        }));
        break;
      case "gst_no":
        setOtvData((prev) => ({
          ...prev,
          gst_no: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleVerifiedIfsc = () => {
    if (
      otvData.bank_account_number !== "" &&
      otvData.bank_ifsc_code !== "" &&
      otvData.country_id !== ""
    ) {
      isIfscCode(
        valueCountryCode,
        otvData.bank_ifsc_code,
        otvData.bank_account_number
      );
    }
  };

  useEffect(() => {
    if (
      otvData.bank_account_number.length >= 10 &&
      otvData.bank_ifsc_code.length == 11 &&
      !editOtvData
    ) {
      handleVerifiedIfsc();
    }
  }, [otvData]);

  const handleValidate = () => {
    let isError = false;
    let regexIfsc = "^[A-Z]{4}0[A-Z0-9]{6}$";
    const formerr = { ...error };
    if (!otvData?.otv_name) {
      isError = true;
      formerr.otv_name = "*Vendor Name is required  ";
      setError(formerr);
    }
    if (!otvData?.street) {
      isError = true;
      formerr.street = "*House Number & Street is required";
      setError(formerr);
    }
    if (!otvData?.state_id) {
      isError = true;
      formerr.state_id = " *State is required";
      setError(formerr);
    }
    if (!otvData?.country_id) {
      isError = true;
      formerr.country_id = " *Country is required ";
      setError(formerr);
    }
    if (!otvData?.service_desc) {
      isError = true;
      formerr.service_desc = " *Description is required ";
      setError(formerr);
    }
    if (!otvData?.otv_city_name) {
      isError = true;
      formerr.otv_city_name = " *City is required ";
      setError(formerr);
    }
    if (!otvData?.postal_code) {
      isError = true;
      formerr.postal_code = "*Postal Code is required ";
      setError(formerr);
    }
    if (!otvData?.invoice_number) {
      isError = true;
      formerr.invoice_number = " *Invoice Number is required ";
      setError(formerr);
    } 
    // else if (vendorInvoiceExists === true) {
    //   isError = true;
    //   formerr.invoice_number =
    //     "*Vendor Invoice Number is already exists for this Financial Year ";
    //   setError(formerr);
    // }
    if (!otvData?.invoice_date) {
      isError = true;
      formerr.invoice_date = " *Invoice Date is required ";
      setError(formerr);
    }
    if (!otvData?.gross_amount) {
      isError = true;
      formerr.gross_amount = " *Base Amount is required ";
      setError(formerr);
    }
    if (!otvData?.expense_category_id) {
      isError = true;
      formerr.expense_category_id = "*Expenses Category is required ";
      setError(formerr);
    }
    if (!otvData?.gl_id) {
      isError = true;
      formerr.gl_id = "*Gl Code is required ";
      setError(formerr);
    }
    if (!otvData?.cost_center_id && costCenterDisable === false) {
      isError = true;
      formerr.cost_center_id = "*Cost Center is required ";
      setError(formerr);
    }
    if (!otvData?.bank_name) {
      isError = true;
      formerr.bank_name = "*Bank Name is required ";
      setError(formerr);
    }
    if (
      !otvData?.bank_account_number ||
      otvData?.bank_account_number?.length < 10
    ) {
      isError = true;
      formerr.bank_account_number = "*Bank Account number is required ";
      setError(formerr);
    } else if (isBankNumberExists === true) {
      isError = true;
      formerr.bank_account_number = "*Bank Account number is already exists ";
      setError(formerr);
    }
    if (!otvData?.bank_ifsc_code || otvData?.bank_ifsc_code?.length < 10) {
      isError = true;
      formerr.bank_ifsc_code = "*Ifsc Code is required";
      setError(formerr);
    } else if (
      isIfscCodeExists.sap_flag === false &&
      isIfscCodeExists.db_flag === false
    ) {
      isError = true;
      formerr.bank_ifsc_code = "*Kindly contact to your SSC Team";
      setError(formerr);
    } else if (
      isIfscCodeExists.sap_flag === false &&
      isIfscCodeExists.db_flag === false
    ) {
      isError = true;
      formerr.bank_ifsc_code = "*Kindly contact to your SSC Team";
      setError(formerr);
    } else if (!otvData?.bank_ifsc_code?.match(regexIfsc)) {
      formerr.bank_ifsc_code = "Invalid IFSC format";
    }
    if (
      !otvData?.business_area_id &&
      (glCodeValue?.code === "772006" ||
        glCodeValue?.code === "771006" ||
        glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.business_area_id = "*Business Area is required ";
      setError(formerr);
    }
    if (
      !otvData?.business_place_id &&
      (glCodeValue?.code === "772006" ||
        glCodeValue?.code === "771006" ||
        glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.business_place_id = "*Business Place is required ";
      setError(formerr);
    }
    if (
      !otvData?.plant_id &&
      (glCodeValue?.code === "772006" ||
        glCodeValue?.code === "771006" ||
        glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.plant_id = "*Plant is required ";
      setError(formerr);
    }
    return isError;
  };

  useEffect(() => {
    if (isIfscCodeExists?.sap_flag === false && isIfscCodeExists.db_flag === false) {
      setError((prev) => ({
        ...prev,
        bank_ifsc_code: "Kindly contact to your SSC Team",
      }));
    }
    if (
      isIfscCodeExists.db_flag === true &&
      isIfscCodeExists.sap_flag === false &&
      state.flag === undefined
    ) {
      setIfscNumberCode(true);
    } else {
      setIfscNumberCode(false);
    }
  }, [isIfscCodeExists]);

  const saveOtvPayment = (data) => {
    if (condition == "false") {
      condition = "true";
      let datass = data;
      datass.attachments = Object.values(uploadDataIm);
      if (Object.keys(uploadDataIm).length !== 0) {
        setTrackerData({
          ...trackerData,
          permission_id: finalUrlValue?.A?.id,
        });
        otvServiceData
          .saveOtvPayment(data)
          .then((response) => {
            if(response.data.message !== "OTV added successfully.") {
              toast.error(response.data.message)
              condition = "false";
            }
            else{
            toast.success(response.data.message);
            setTimeout(() => {
              condition = "false";
              navigate("/otv-vendor-request");
            }, 300);
          }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else {
        setErrorAttachment(true);
      }
    }
  };

  const updateOtvPayment = (id, data) => {
    let datass = data;
    datass.attachments = Object.values(uploadDataIm);
    if (Object.keys(uploadDataIm).length !== 0) {
      setTrackerData({
        ...trackerData,
        permission_id: finalUrlValue?.E?.id
      })
    otvServiceData
      .updateOtvPayment(id, data)
      .then((response) => {
        toast.success(response?.data?.message);
        setTimeout(() => {
          condition = "false";
          navigate("/otv-vendor-request");
        }, 300);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    }
    else {
      setErrorAttachment(true);
    }
  };

  const onProfileChange = async (e, index) => {
    dispatch(setDisplayLoader("Display"));
    let file = e.target.files;
    setfiles((prev) => ({ ...prev, [index]: e.target.files[0] }));
    let files1 = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await HeaderService.uploadImages(fileData).then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let name = { name: data.name };
        filename.push(name);
        files1.push(response.data);
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    }
    setAttachment([]);
    if (index === 0) {
      setErrorAttachment(false);
    } else {
      setErrorAttachment1(false);
    }
    uploadDataIm[index] = files1[0];
  };

  const isBankAccNumber = (data) => {
    if (data.length === 16) {
      let value = JSON.stringify(data);
      otvServiceData
        .isBankAccNumber(value)
        .then((res) => {
          setIsBankNumberExists(res?.data?.dataList?.is_exist);
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const isIfscCode = (country, ifsc_no, bank_ac_no) => {
    otvServiceData
      .isIfscCode(country, ifsc_no, bank_ac_no)
      .then((res) => {
        setIsIfscCodeExists(res.data.dataList);
      })
      .catch((err) => console.log("Error=>", err));
  };

  const onPushCountry = (newValue, name) => {
    getStateList(newValue.code);
    setCountryValue(newValue);
    handleChange(newValue, name);
    setValueCountryCode(newValue.code);
  };

  const onPushState = (newValue, name) => {
    getCityList(newValue.value);
    setStateValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataVendor = (newValue, name) => {
    setVendorValue(newValue);
    handleChange(newValue, name);
  };
  const pushDataGlCode = (newValue, name) => {
    setGlCodeValue(newValue);
    handleChange(newValue, name);
    let gl_code = newValue?.label?.split("(")[0];
    checkGLValidation(gl_code);
  };

  const checkGLValidation = (gl_code) => {
    dispatch(setDisplayLoader("Display"));
    otvServiceData.checkValidationByGL(gl_code).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      let obj = response?.data?.dataList?.result;
      if (obj.length > 0) {
        if (obj[0]?.is_exception === true) {
          setCostCenterDisable(true);
        } else {
          setCostCenterDisable(false);
        }
      }
    });
  };

  const pushDataCost = (newValue, name) => {
    setcostCenterValue(newValue);
    handleChange(newValue, name);
  };

  const onPushExpense = (newValue, name) => {
    setExpenseValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataBusinessPlace = (newValue, name) => {
    setBusinessPlaceValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataBusinessArea = (newValue, name) => {
    // setOrgData(newValue?.organisation_name);
    setBusinessAreaValue(newValue);
    handleChange(newValue, name);
    let string = newValue?.label?.split("(")[1];
    let code = string.substring(0, string.length - 1);
    let company_code = "";
    if (code === "PIAG") {
      company_code = "PIIL";
    } else if (code === "PIJA") {
      company_code = "PIJA";
    } else if (code === "PKAG") {
      company_code = "PIKM";
    }
    setOrgData(company_code);
  };

  const pushDataPlant = (newValue, name) => {
    setPlantValue(newValue);
    handleChange(newValue, name);
  };

  useEffect(() => {
    if (
      existsValueData.invoiceNumber !== "" &&
      existsValueData.invoiceDate != ""
    ) {
      checkInvoiceNumber(existsValueData);
    }
  }, [existsValueData]);

  useEffect(() => {
    setEditOtvData(state?.from);
  }, [state]);

  useEffect(() => {
    if (editOtvData) {
      let data = editOtvData;
      let expenseItem = [];
      let exData = {
        value: data.expense_category_id,
        label: data.expense_name,
      };
      expenseItem.push(exData);
      let glItem = [];
      let glData = {
        value: data.gl_id,
        label: data.gl_code,
      };
      glItem.push(glData);

      let costItem = [];
      let costData = {
        value: data.cost_center_id,
        label: data.cost_center_name,
      };
      costItem.push(costData);

      let countryItem = [];
      let countryData = {
        value: data.country_id,
        label: data.country_name,
      };
      countryItem.push(countryData);

      let stateItem = [];
      let stateData = {
        value: data.state_id,
        label: data.state_name,
      };
      stateItem.push(stateData);
      setCountryValue(countryItem);
      setStateValue(stateItem);
      setInputValue(data.otv_name);
      setcostCenterValue(costItem);
      setGlCodeValue(glItem);
      setExpenseValue(expenseItem);
      setAttachment(data?.attachments);

      Object.keys(editOtvData).forEach((item) => {
        otvData[item] = editOtvData[item];
      });
    }
  }, [editOtvData]);

  const checkInvoiceNumber = (data) => {
    otvServiceData
      .checkInvoiceNumber(data)
      .then((res) => {
        setVendorInvoiceExists(res?.data?.dataList?.is_exist);
      })
      .catch((err) => console.log("Error=>", err));
  };

  const handleSubmit = () => {
    const keys = Object.keys(uploadDataIm || {});
    if (state?.flag === undefined) {
      if (keys?.length !== 0) {
        setErrorAttachment(false);
      } else {
        setErrorAttachment(true);
      }
      if (keys?.length === 2 || keys?.length === 3) {
        setErrorAttachment1(false);
      } else {
        setErrorAttachment1(true);
      }
    } else {
      if (attachment?.length === 0) {
        setErrorAttachment1(true);
      } else {
        setErrorAttachment1(false);
      }
    }
    if (handleValidate()) return false;
    if (otvData.id === undefined) {
      if (
        isBankNumberExists !== true &&
        isIfscCodeExists.sap_flag === true &&
        // vendorInvoiceExists !== true &&
        errorAttachment === false &&
        errorAttachment1 === false
      ) {
        saveOtvPayment(otvData);
      }
    } else {
      updateOtvPayment(otvData.id, otvData);
    }
  };

  const deleteAttachment = (id) => {
    let data = { ...file };
    Object.entries(data).map((key, value) => {
      let val = key[0];
      if (val == id) {
        delete data[val];
      }
    });
    setfiles(data);
  };

  useEffect(() => {
    getCountryList(false);
    getGlCodeList("OTV");
    getCostCenter();
    getExpenseList();
  }, []);

  const attachments123 = (index) => {
    let data = [];
    let data2 = file[index] != undefined ? file[index] : "";
    data =
      file[index] != undefined ? (
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={(e) => {
                deleteAttachment(index);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      ) : (
        ""
      );
    return data;
  };

  const blockInvalidChar = (e) =>
    [
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "+",
      "|",
      ":",
      "<",
      ">",
      "!",
    ].includes(e.key) && e.preventDefault();

    useEffect(() => {
      dispatch(setTracker(trackerData));
    }, [trackerData]);

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    One Time Vendor Payment Request
                  </h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      OTV Payment Request
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs defaultActiveKey="onduty" id="approvaltabs">
                <Tab
                  eventKey="onduty"
                  title={<React.Fragment>OTV Payment Request</React.Fragment>}
                >
                  <div class="px-4 pb-2">
                    <div class="defaultchatgptquest text-right">
                      <a
                        class="badge badge-secondary"
                        href="\Policy_OTV_Transactions.pdf"
                        target="_blank"
                      >
                        <i class="far fa-file-pdf"></i> OTV Policy
                      </a>
                      <a
                        class="badge badge-secondary"
                        href="\User Manual.pdf"
                        target="_blank"
                      >
                        <i class="far fa-file-pdf"></i> User Manual
                      </a>
                    </div>

                    {userData?.reporting_to_id === null || userData?.functional_head_id === null ?
                    <Alert variant="danger" className="mb-5 mt-2 text-center">
                      <i className="fa fa-exclamation-triangle"></i> 
                      Contact Your BHR as {userData?.reporting_to_id === null ? "Reporting Manager" : "Functional Head"} is not Mapped
                    </Alert>
                    :
                    ""
                    }

                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Vendor Details</strong>
                    </h5>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Vendor Name<span class="text-danger">*</span>
                              {suggestError && (
                                <Alert variant="danger" className="mb-5 mt-2">
                                  <i className="fa fa-exclamation-triangle"></i>
                                  "In case of Existing vendor, Please select NON
                                  PO vendor payment"
                                </Alert>
                              )}
                            </label>
                            <div class="modulesearchbox">
                              <input
                                className="innergroup form-control"
                                type="text"
                                onChange={onChange}
                                value={inputValue}
                                placeholder="Search Vendor"
                              />
                              <SuggestionsList
                                inputValue={inputValue}
                                selectedSuggestion={selectedSuggestion}
                                onSelectSuggestion={onSelectSuggestion}
                                displaySuggestions={displaySuggestions}
                                suggestions={filteredSuggestions}
                              />
                            </div>
                            <div className="small text-danger">
                              {error.otv_name}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              House Number & Street
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="street"
                              placeholder="Enter House Number & Street"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, street: "" });
                              }}
                              value={otvData.street}
                            />
                            <div className="small text-danger">
                              {error?.street}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Postal Code<span class="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              name="postal_code"
                              placeholder="Enter Postal Code"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "7");
                                setError({ ...error, postal_code: "" });
                              }}
                              value={otvData.postal_code}
                            />
                            <div className="small text-danger">
                              {error?.postal_code}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Country<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={countryList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="country_id"
                              onChange={(newValue, action) => {
                                onPushCountry(newValue, action?.name);
                                setError({ ...error, country_id: "" });
                              }}
                              value={countryValue}
                            />
                            <div className="small text-danger">
                              {error?.country_id}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              State<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={stateList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="state_id"
                              onChange={(newValue, action) => {
                                onPushState(newValue, action?.name);
                                setError({ ...error, state_id: "" });
                              }}
                              value={stateValue}
                            />
                            <div className="small text-danger">
                              {error?.state_id}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              City<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="otv_city_name"
                              placeholder="Enter City Name"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, otv_city_name: "" });
                              }}
                              value={otvData.otv_city_name}
                            />
                            <div className="small text-danger">
                              {error?.otv_city_name}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              PAN No.<span class="text-danger"></span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="pan_no"
                              placeholder="Enter PAN No."
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name);
                              }}
                              value={otvData.pan_no}
                            />
                           
                          </div>
                        </div>

                        <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">
                            GST Registered<span class="text-danger"></span>
                          </label>
                          <label class="logCheck d-inline-block mr-4">
                            Yes
                            <input type="radio" name="statusupdate1" onClick={()=>{setGstReminder(true)}} checked={gstReminder} />
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            No
                            <input type="radio" name="statusupdate1" onClick={()=>{setGstReminder(false)}} checked={gstReminder===false} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              GST No.
                              {gstReminder && (
                              <span class="text-danger">*</span>
                              )}
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="gst_no"
                              placeholder="Enter GST No."
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name);
                              }}
                              value={otvData.gst_no}
                            />
                           
                          </div>
                        </div>

                      </div>
                    </div>

                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Invoice Details</strong>
                    </h5>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Invoice No"
                              name="invoice_number"
                              onKeyDown={blockInvalidChar}
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "17");
                                setError({ ...error, invoice_number: "" });
                              }}
                              value={otvData.invoice_number}
                            />
                            <div className="small text-danger">
                              {error?.invoice_number}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice Date<span class="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter Invoice Date"
                              name="invoice_date"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, invoice_date: "" });
                              }}
                              value={otvData.invoice_date}
                              max={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <div className="small text-danger">
                              {error?.invoice_date}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Base Amount (Excluding Taxes){" "}
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Enter Base Amount"
                              name="gross_amount"
                              step="0.001"
                              min="0.001"
                              max="10"
                              precision={3}
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, gross_amount: "" });
                              }}
                              value={parseFloat(
                                otvData.gross_amount
                              ).toFixed(2)}
                            />
                            <div className="small text-danger">
                              {error?.gross_amount}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Expense Category
                              <span class="text-danger">*</span>
                            </label>
                            <Select
                              options={expenseList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="expense_category_id"
                              onChange={(newValue, action) => {
                                onPushExpense(newValue, action?.name);
                                setError({ ...error, expense_category_id: "" });
                              }}
                              value={expenseValue}
                            />
                            <div className="small text-danger">
                              {error?.expense_category_id}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              GL Code<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={glCodeList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="gl_id"
                              onChange={(newValue, action) => {
                                pushDataGlCode(newValue, action?.name);
                                setError({ ...error, gl_id: "" });
                              }}
                              value={glCodeValue}
                            />
                            <div className="small text-danger">
                              {error?.gl_id}
                            </div>
                          </div>
                        </div>
                        {
                          // (glCodeValue?.code === "772006" ||
                          //   glCodeValue?.code === "771006" ||
                          //   glCodeValue?.code === "771004")
                          costCenterDisable && (
                            <>
                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    BUSINESS PLACE{" "}
                                    <span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={businessPlaceList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="business_place_id"
                                    onChange={(newValue, action) => {
                                      pushDataBusinessPlace(
                                        newValue,
                                        action?.name
                                      );
                                      setError({
                                        ...error,
                                        business_place_id: "",
                                      });
                                    }}
                                    value={businessPlaceValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.business_place_id}
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    BUSINESS AREA{" "}
                                    <span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={businessAreaList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="business_area_id"
                                    onChange={(newValue, action) => {
                                      pushDataBusinessArea(
                                        newValue,
                                        action?.name
                                      );
                                      setError({
                                        ...error,
                                        business_area_id: "",
                                      });
                                    }}
                                    value={businessAreaValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.business_area_id}
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>COMPANY CODE</label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    placeholder="Please search COMPANY CODE"
                                    value={orgData}
                                  />
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    Plant<span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={plantList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="plant_id"
                                    onChange={(newValue, action) => {
                                      pushDataPlant(newValue, action?.name);
                                      setError({ ...error, plant_id: "" });
                                    }}
                                    value={plantValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.plant_id}
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Description of Service/Material</label>
                            <span class="text-danger">*</span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Description of Service/Material"
                              name="service_desc"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, service_desc: "" });
                              }}
                              value={otvData.service_desc}
                            />
                            <div className="small text-danger">
                              {error?.service_desc}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Cost Center<span class="text-danger">*</span>
                            </label>
                            <Select
                              isDisabled={costCenterDisable}
                              options={costCenterList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="cost_center_id"
                              onChange={(newValue, action) => {
                                pushDataCost(newValue, action?.name);
                                setError({ ...error, cost_center_id: "" });
                              }}
                              value={costCenterValue}
                            />
                            <div className="small text-danger">
                              {error?.cost_center_id}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Bank Details</strong>
                    </h5>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Bank Name<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Bank Name"
                              name="bank_name"
                              onChange={(newValue, action) => {
                                handleChange(
                                  newValue,
                                  action?.name,
                                  "[^A-Z a-z]",
                                  ""
                                );
                                setError({ ...error, bank_name: "" });
                              }}
                              value={otvData.bank_name}
                            />
                            <div className="small text-danger">
                              {error?.bank_name}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Bank A/c No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Bank A/c No"
                              name="bank_account_number"
                              onChange={(newValue, action) => {
                                handleChange(
                                  newValue,
                                  action?.name,
                                  "[0-9]{9,18}",
                                  "17"
                                );
                                setError({ ...error, bank_account_number: "" });
                              }}
                              value={otvData.bank_account_number}
                            />
                            <div className="small text-danger">
                              {error?.bank_account_number}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              IFSC Code<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter IFS Code "
                              name="bank_ifsc_code"
                              id="ssn-input"
                              onChange={(newValue, action) => {
                                handleChange(
                                  newValue,
                                  action?.name,
                                  "[A-Z]{4}0[A-Z0-9]{6}",
                                  "12"
                                );
                                setError({ ...error, bank_ifsc_code: "" });
                              }}
                              value={otvData.bank_ifsc_code}
                            />
                            <div className="small text-danger">
                              {error?.bank_ifsc_code}
                              {ifscNumberCode && (
                                <span style={{ color: "orange" }}>
                                  *In case of Existing IFSC Code, Please select
                                  NONPO vendor payment
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Attachments</strong>
                    </h5>

                    <div class="bg-light px-3 pt-3 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup position-relative modaldefaultclose ">
                            <label>
                              Invoice Attachment
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                            />
                            {errorAttachment ? (
                              <div className="small text-danger">
                                *Attachment is required
                              </div>
                            ) : null}
                            <div class="upload-btn-wrapper up-loposition">
                              <button class="uploadBtn">Browse</button>
                              <input
                                type="file"
                                id="attachmentsFile"
                                accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                                onChange={(e) => onProfileChange(e, 0)}
                              />
                            </div>
                            {imgSize ? (
                              <div className="small text-danger">
                                *File size should not exceed 1mb
                              </div>
                            ) : null}
                            <p>
                              Selected files
                              {[file].map((a) => (
                                <span>
                                  <br />
                                  {a.name}
                                </span>
                              ))}
                            </p>
                            {Object.keys(file)?.length !== 0 ? (
                              <div className="d-flex">{attachments123(0)}</div>
                            ) : attachment[0] ? (
                              <>
                                {attachment !== [] && (
                                  <div class="uploaded-image mr-1">
                                    <a
                                      href={attachment[0].attachment_path}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {attachment &&
                                      attachment[0]?.attachment_path.includes(
                                        ".pdf"
                                      ) ? (
                                        <img
                                          src={"images/policy-icon/pdf.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".xlsx"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/xlsx.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".xls"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/xlsx.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".doc"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/docs.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".docs"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/docs.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".pptx"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/ppt.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".mp4"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/video.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : (
                                        <img
                                          src={attachment[0]?.attachment_path}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      )}
                                    </a>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        deleteAttachment(0);
                                      }}
                                      className="btn btn-blanktd text-danger ml-2"
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                  </div>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup position-relative modaldefaultclose ">
                            <label>Cheque Attachment</label>
                            <span class="text-danger">*</span>
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                            />
                            {errorAttachment1 ? (
                              <div className="small text-danger">
                                *Attachment is required
                              </div>
                            ) : null}
                            <div class="upload-btn-wrapper up-loposition">
                              <button class="uploadBtn">Browse</button>
                              <input
                                type="file"
                                onChange={(e) => onProfileChange(e, 1)}
                              />
                            </div>
                            {imgSize ? (
                              <div className="small text-danger">
                                *File size should not exceed 1mb
                              </div>
                            ) : null}
                            <p>
                              Selected files
                              {[file].map((a) => (
                                <span>
                                  <br />
                                  {a.name}
                                </span>
                              ))}
                            </p>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123(1)}</div>
                            ) : attachment[1] ? (
                              <>
                                <div class="uploaded-image mr-1">
                                  <a
                                    href={attachment[1].attachment_path}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {attachment &&
                                    attachment[1].attachment_path.includes(
                                      ".pdf"
                                    ) ? (
                                      <img
                                        src={"images/policy-icon/pdf.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".xlsx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".xls"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".doc"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".docs"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".pptx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/ppt.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".mp4"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/video.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : (
                                      <img
                                        src={attachment[1]?.attachment_path}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    )}
                                  </a>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      deleteAttachment(1);
                                    }}
                                    className="btn btn-blanktd text-danger ml-2"
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup position-relative modaldefaultclose ">
                            <label>Other Attachment</label>
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                            />
                            <div class="upload-btn-wrapper up-loposition">
                              <button class="uploadBtn">Browse</button>
                              <input
                                type="file"
                                onChange={(e) => onProfileChange(e, 2)}
                              />
                            </div>
                            <p>
                              Selected files
                              {[file].map((a) => (
                                <span>
                                  <br />
                                  {a.name}
                                </span>
                              ))}
                            </p>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123(2)}</div>
                            ) : attachment[2] ? (
                              <>
                                <div class="uploaded-image mr-1">
                                  <a
                                    href={attachment[2].attachment_path}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {attachment &&
                                    attachment[2].attachment_path.includes(
                                      ".pdf"
                                    ) ? (
                                      <img
                                        src={"images/policy-icon/pdf.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".xlsx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".xls"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".doc"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".docs"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".pptx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/ppt.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[2].attachment_path.includes(
                                        ".mp4"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/video.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : (
                                      <img
                                        src={attachment[2]?.attachment_path}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    )}
                                  </a>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      deleteAttachment(2);
                                    }}
                                    className="btn btn-blanktd text-danger ml-2"
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-12 mt-3 text-center">
                          <button
                            class="btn btn-outline-secondary mr-2"
                            onClick={() => navigate("/otv-vendor-request")}
                          >
                            Cancel
                          </button>
                          {userData?.reporting_to_id !== null && userData?.functional_head_id !== null ?
                          <button
                            class="btn btn-primary-inner bpi-main"
                            type="submit"
                            disabled={isIfscCodeExists.sap_flag === false && isIfscCodeExists.db_flag === false}
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                          :
                          <>
                          <span class="text-danger">* Contact Your BHR as {userData?.reporting_to_id === null ? "Reporting Manager" : "Functional Head"} is not Mapped</span>
                          </>
                          }
                        </div>
                      </div>
                    </div>

                    <div class="travel-note">
                      <h4>Notes :-</h4>
                      <ul>
                        <li>
                          Payment to vendor which is not registered in PIIL SAP
                          records can be made in this category.
                        </li>
                        <li>
                          Only one transaction can be processed through OTV in
                          one Financial Year.
                        </li>
                        <li>
                          Make sure that vendor is unregistered in SAP & in case
                          of ambiguity contact SSC Vendor Master team.
                        </li>
                        <li>
                          Attach vendor invoice or payment request letter. In
                          case of GST invoice, make sure Address & GSTN of PIIL
                          is correctly mentioned on invoice.
                        </li>
                        <li>
                          Attached cancelled cheque or bank mandate form of
                          vendor.
                        </li>
                        <li>
                          Allowed formats are pdf,png,jpeg,jpg,xlsx with size
                          upto 4MB.
                        </li>
                        <li>Allowed max 3 no of attachments.</li>
                      </ul>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Domestic"
                  title={<React.Fragment>Bulk Upload</React.Fragment>}
                >
                  <OtvBulkImport />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTVRequest;
