import React, { useEffect } from 'react'

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal, Spinner } from 'react-bootstrap';
import * as PrGroupOrgService from '../../../service/purchaseGroup';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
    pgcode: Yup.string()
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field should contain minimum 2 alphabet"
        )
        // .matches(
        //     /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
        //     "Insert only character"
        // )
        .required("*Purchasing Group Organization Code is required")
        .nullable(),
    pgdesc: Yup.string()
        .min(2)
        .required("*Purchasing Group Organization Description is required")
        .nullable(),
});

const initialValues = {
    pgcode: "",
    pgdesc: "",
}

const PrAddOrgGroupModal = ({ isOpen, handleModalClose, currentItem, refreshData }) => {

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let response = null;
                if (currentItem) {
                    response = await PrGroupOrgService.updateReleaseCode(values, currentItem.id)
                } else {
                    response = await PrGroupOrgService.saveReleaseCode(values)
                }
                if (response.data?.status) {
                    toast.success(response.data.message)
                    refreshData()
                    _handleModalClose()
                } else {
                    toast.error(response.message)
                }
            } catch (error) {
                console.error("🚀 ~ file: pr-add-org-master.js:39 ~ onSubmit: ~ error:", error)
            } finally { setSubmitting(false) }
        }
    })
    const { handleSubmit, getFieldProps, errors, setFieldValue, touched, isSubmitting, resetForm } = formik;


    const _handleModalClose = () => {
        resetForm();
        handleModalClose()
    }

    useEffect(() => {
        if (currentItem) {
            setFieldValue('pgcode', currentItem.pgcode)
            setFieldValue('pgdesc', currentItem.pgdesc)
        }
    }, [currentItem])
    return (
        <Modal
            show={isOpen}
            onHide={_handleModalClose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Purchasing Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group innergroup">
                                        <label>
                                            Purchasing Group Code<span class="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            {...getFieldProps('pgcode')}
                                            placeholder="Enter Purchasing Group Code"
                                        />
                                        {touched.pgcode && errors.pgcode ? (
                                            <div className="small text-danger">
                                                {errors.pgcode}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group innergroup">
                                        <label>
                                            Purchasing Group Description{" "}
                                         <span class="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            {...getFieldProps('pgdesc')}
                                            placeholder="Enter Purchasing Group Description"
                                        />
                                        {touched.pgdesc && errors.pgdesc ? (
                                            <div className="small text-danger">
                                                {errors.pgdesc}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div class="col-md-12">
                                <div class="form-group innergroup">
                                    <label className="d-block mb-2">
                                        Status<span class="text-danger">*</span>
                                    </label>
                                    <label class="logCheck d-inline-block mr-4">
                                        Active
                                        <input type="radio" name="statusupdate1" checked />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="logCheck d-inline-block">
                                        Inactive
                                        <input type="radio" name="statusupdate1" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmitting
                            ? <Spinner animation="border" variant="primary" as="span" role="status" aria-hidden="true" />
                            : (
                                <>
                                    <div class="btn btn-outline-danger" onClick={_handleModalClose}>
                                        Cancel
                                    </div>
                                    <button type='submit' class="btn btn-primary-inner bpi-main">Submit</button>
                                </>
                            )
                    }
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default PrAddOrgGroupModal;