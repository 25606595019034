import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getBrand = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/branding?${queryParm}`);
};

export const saveBrand = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/branding`, data);
};

export const updateBrand = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/branding/${id}`, data);
};

export const deleteBrand = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/branding/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getBranch = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?paginate=${paginate}`);
};
export const getBrandingCategory = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/branding_category?paginate=${paginate}`);
};
export const getDepartment = async (branch_id) => {
  return axios.post(`${AUTH_BASE_URL}/department/list?branch_id=${branch_id}`);
};
export const getSubDepartment = async (department_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/sub_department_router/list?department_id=${department_id}`
  );
};
export const getModule = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/modules?paginate=${paginate}&sort_by=name`
  );
};
export const getBrandCategory = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/branding_category?paginate=${paginate}`);
};

export const isBrandExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/branding/exists`, isData);
};

export const getAllBrand = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/branding?paginate=${paginate}&sort_by=name`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/branding/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const uploadFile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const changeBrandingStatus = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/branding/action`,
    method: "POST",
    data: data,
  });
};
