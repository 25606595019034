import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import ViewTable from "./ViewTable";
import { toast, ToastContainer } from "react-toastify";
import * as TransportServices from "../../../../service/LRGRPortal/transportinvoiceservices";
import { getTransporter } from "../../../../service/LRGRPortal/transporterService";
import { getSubtransporter } from "../../../../service/LRGRPortal/subtransporterService";
import { getPlant } from "../../../../service/LRGRPortal/plantService";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { useDispatch } from "react-redux";
import AddIcon from "../../../../assets/images/plus.png";
import { StyledEngineProvider } from "@mui/material";


const Table = (props) => {
  const [rawData, setRawData] = useState({});
  const [newData, setNewData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [invoicedate, setInvoicedate] = useState({ on_date: "" });
  const [invoiceList, setInvoiceList] = useState([]);
  const [subtransEdit, setSubtransEdit] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [transportData, setTransportData] = useState([]);
  const [transporterList, setTransporterList] = useState([]);
  const [seletectedTransporter, setSeletedTransporter] = useState();

  const [subtransporterList, setSubtransporterList] = useState([]);
  const [newsubtransporterList, setNewsubtransporterList] = useState([]);
  const [newhitindex, setNewhitindex] = useState(0);
  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [marketSize, setMarketSizeData] = useState({});
  const [mode, setMode] = useState();
  const [requestid, setRequestid] = useState();
  const [truckList, setTruckList] = useState([]);
  //const [ewayList, setEwayList] = useState([]);
  const [ewayList, setEwayList] = useState([])
  const [epodList, setEpodList] = useState([])
  const [approvalid, setApprovalid] = useState();
  const [plantid, setPlantid] = useState();
  let involist = [];
  let unique_id = [];
  const [shipdelay, setShipdelay] = useState();

  // const [loader, setLoader] = useState(true);
  const [selectedPlant, setSelectedPlant] = useState();
 
  const [freezeCTA, setFreezeCTA] = useState(false);

  const approvals = [{ id: 1, status: "P" }, { id: 2, status: "A" }, { id: 3, status: "D" }, { id: 4, status: "R" }]
  const aprvllist = [approvals];

  // modular table prop
  const [blacklistObj, setBlacklistObj] = useState({});
  const [isrequest, setIsrequest] = useState(false);
  const [shipmode, setShipmode] = useState("FTL")
  const [plant, setPlant] = useState([]);
  const plant_obj = {
    plant_id: "",
    plant_name: "",
    plant_code: ""
  };
  const [transporterOptions, setTransporterOptions] = useState([]);
  const [subtransportersData, setSubtransportersData] = useState({});
  const initialData =
    { transporter: '', subtransporter: '', subtransporters: [] }
  const [ddata, setDdata] = useState([]);


  const shippingMode =[
    "FTL",
    "PTL",
    ]
  const [approvalList, setApprovalList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLoader = (show = true) =>{
      dispatch(setDisplayLoader( show ? "Display" : "Hide"));
    }
  var today = new Date();

  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  today = yyyy + '-' + mm + '-' + dd;
  const [date, setDate] = useState(today);

  // show Plant and Date 
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setRequestid(params.get("requestID"));
    setApprovalid(params.get("approvalID"))
    setPlantid(params.get("plantID"))
    handleLoader();
    TransportServices.getTruckList()
    .then((res) => {
    
      setTruckList(res?.data?.dataList);

    })
    .catch((err) => {
   
    }).finally(()=>{
      handleLoader(false);
    });

    if (!params.get("mode")) return;
    if (params.get("mode") == "create") {
      handleLoader();
      getPlant()
        .then((res) => {
          setPlant(res?.data?.dataList);

        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          handleLoader(false);
        });;
        // setLoader(false);
    } else if ((params.get("mode") == "edit") || ((params.get("mode") == "view") && (params.get("c_a") == undefined))) {
      handleLoader();
      getPlant()
        .then((res) => {
    
          let index = res.data.dataList.find((el) => el.plant_id == params.get("plantID"));
          setPlant([index])
          setInvoicedate({
            ...invoicedate,
            on_date: params.get("date"),
          })
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          handleLoader(false);
        });;
      if (params.get("plantID") != undefined && params.get("plantID") != null) {
        let queryParm = "plant_id=" + params.get("plantID")
        handleLoader();
        getTransporter(queryParm)
          .then((response) => {
            setTransporterList(response?.data?.dataList);
          })

          .catch((error) => {

            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });
      }
      // setLoader(false);
    } else if ((params.get("mode") == "approve") && (params.get("c_a") == "True")) {
      plant_obj.plant_id = params.get("plantID")
      plant_obj.plant_code = params.get("plantCode")
      plant_obj.plant_name = params.get("plantName")
  
      setPlant([plant_obj])
      setInvoicedate({
        ...invoicedate,
        on_date: params.get("date"),
      })
      // setLoader(false);
    }

  }, [mode]);



  // plant change Handler

  const plantChangeHandler = (code) => {
    setSelectedPlant(code)

  }
  
  const getExportDetails = () => {
    let query = "";
    query = "request_id=" + requestid
 
    if (mode == "view") {
      handleLoader();
      TransportServices.getExportInner(query)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Invoicedata.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        }).finally(()=>{
          handleLoader(false);
        });
    }
  }



  // search Invoice Data

  const searchInvoiceList = () => {
    if (selectedPlant != undefined && selectedPlant != "select plant") {
      if (moment(invoicedate.on_date).format("YYYY-MM-DD") != "Invalid date") {
        handleLoader();
        TransportServices
          .getInvoiceList(
            `?date_of_invoice=${moment(invoicedate.on_date).format("YYYY-MM-DD")}&plant_id=${selectedPlant}`
          )
          .then((response) => {

              
            let data = response.data;

            if (response.data.message == "No invoices found for the given criteria.") {
              toast.info("No invoices found for the given Date")
              setInvoiceList([]);
              // setLoader(false);
            } else if (response.data.message == "All Invoice for this date are tagged") {
              toast.info("All Invoice for this date are tagged")
              setInvoiceList([]);
              // setLoader(false);
            }
            else if (response.data.message != undefined &&
              response.data.message == "You have already created a request for this date. Please edit that request.") {
              //alert("message", response.data.message)
              setIsrequest(true);
              setInvoiceList([]);

              if (freezeCTA) return;
              confirmAlert({
                title: "Request already exist!!!",
                message:
                  "You have already created a request for this date. Please edit that request.",
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {
                      setFreezeCTA(false);
                    },
                  },
                ],
                onClickOutside: () => {
                  setFreezeCTA(false);
                },
                onKeypressEscape: () => {
                  setFreezeCTA(false);
                },
              });
              // setLoader(false);


            } else {
              const datalen = data.length;
              const invoicecreatelist = data.map((el) => {
                const dupRow = { ...el }

                dupRow.transporter_id = "";

                dupRow.sub_transporter_id = "";

                dupRow.lr_gr_number = null;

                dupRow.vehicle_number = null;

                dupRow.shipping_delay = null;

                dupRow.lr_gr_date = null;

                dupRow.shipping_mode = "FTL";
                dupRow.other_charges = 0;
                dupRow.truck_id = "";
                dupRow.freight = 0;
                dupRow.cartage = 0;
                dupRow.hamali = 0;
                return dupRow;

              })
              setInvoiceList(invoicecreatelist)
              if (datalen > 0) {
                for (let i = 0; i < datalen; i++) {
                  setDdata(prevState => ([
                    ...prevState,
                    initialData
                  ]))
                }

              }
              // setLoader(false);
            }

          })
          .catch((error) => {

            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
            // setLoader(false);
          }).finally(()=>{
            handleLoader(false);
          })
        fetchtransporter(selectedPlant)
        // setLoader(false);
      } else {
        // setLoader(false);
        toast.error("Please select valid Date");
      }
      // setLoader(false);
    } else {
      // setLoader(false);
      toast.error("Please select valid Plant");
    }
  }

  const fetchtransporter = (selectedPlant) => {
    let queryParm = "plant_id=" + selectedPlant;
    handleLoader();
    const transporterData = getTransporter(queryParm)
      .then((response) => {
        setTransporterList(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      }).finally(()=>{
        handleLoader(false);
      });;
  }


  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));

    setRequestid(params.get("requestID"));
    let query = "";
    query = "?request_id=" + params.get("requestID");
    if (params.get("mode") != "create" ) {
      handleLoader();
      TransportServices.
        getInvoiceapprovalLogs(query)
        .then((res) => {
          setApprovalList(res.data);

        })
        .catch((err) => console.log(err))
        .finally(()=>{
          handleLoader(false);
        });
    }

    if (params.get("mode") == "edit" || params.get("mode") == "view" || params.get("mode") == "approve") {
      handleLoader();
      TransportServices.getInvoiceReqListID(query)
        .then((res) => {
          if (res.status == 200) {
            setInvoiceList(res.data);

            if (res.data.length > 0) {
             
              setInvoiceList(res.data.map((elem, i) => {  
                if(elem.shipping_mode == undefined)

                elem.shipping_mode = "FTL";
                return elem;
                     
              }))
        
            }

          }
        })
        .catch((err) => console.log(err))
        .finally(()=>{
          handleLoader(false);
        });
    }



  }, [mode]);


  // useEffect(() => {
  //   let end_point = "/mymarket/marketsize";
  //   getPermissions(end_point)
  //     .then((res) => {
  //       setNewData(res.data.dataList);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.data.code == 409) {
  //         toast.error("Save failed!");
  //       }
  //     });
  // }, []);

  // useEffect(() => {
  //   if (!mode) return;
  //   let query = "/mymarket/marketsize";
  //   getPermissions(query)
  //     .then((res) => {
  //       let modeType = "";
  //       if (mode == "approve") modeType = "V";
  //       if (mode == "create") modeType = "A";
  //       if (mode == "view") modeType = "V";
  //       if (mode == "edit") modeType = "E";
  //       const index = res.data.dataList.findIndex((e) => e.code == modeType);
  //       if (index == -1) {
  //         toast.error(`You are not authorized to ${mode} this page`);
  //         setTimeout(() => {
  //           navigate("/mymarket/marketsize");
  //         }, 1000);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [mode]);





  //EVENT HANDLERS
  const onSaveHandler = () => {
    handleLoader();
    setInvoiceList(invoiceList.map((elem) => {
      if (elem.sub_transporter_list != undefined) {
        delete elem.sub_transporter_list
        return elem;
      } else {
        return elem;
      }
    }))
    if (mode == "create") {
 
      let query = "";
      query = "?plant_id=" + selectedPlant
        + "&status=" + "D"
        + "&date_of_invoice=" + moment(invoicedate.on_date).format("YYYY-MM-DD");

        
      TransportServices.
        postInvoiceRequest(query, invoiceList)
        .then((res) => {
          
          toast.success("Save succesfull!");
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        })
        .catch((err) => {
        
          toast.error("Save failed!");
       
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }



    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      let query = "";
      query = "?request_id=" + params.get("requestID")
        + "&status=" + "D";

       
      handleLoader();
      TransportServices.
        putInvoiceRequest(query, invoiceList)
        .then((res) => {
        
          toast.success("save succesfull!");
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        })
        .catch((err) => {
        
          toast.error("save failed!");
      
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }


  };


  const onValidation = (type) => {
    if (invoiceList.length != 0) {
      let flag_data = false;
      let flag_partial = false;
      let dupRow = invoiceList
      involist = [];
      unique_id = [];
      dupRow.map((el, i) => {
        flag_data = false;
        if (el.transporter_id == null || el.transporter_id == "") {
          flag_data = true;
          flag_partial = true;
        }
        else if (el.sub_transporter_id == null || el.sub_transporter_id == "") {
          flag_data = true;
          flag_partial = true;
        }
        else if (el.shipping_mode == null || el.shipping_mode == "") {
          flag_data = true;
          flag_partial = true;
        }
        else if (el.lr_gr_number == null || el.lr_gr_number == "") {
          flag_data = true;
          flag_partial = true;
        }
        else if (el.vehicle_number == null || el.vehicle_number == "" ) {
          flag_data = true;
          flag_partial = true;
        }
        else if (el.lr_gr_date == null || el.lr_gr_date == "") {
          flag_data = true;
          flag_partial = true;
        }

        if (flag_data) {
          involist.push(el.invoice_number)
          unique_id.push(el.invoice_id)
        }

      })


      if (flag_partial) {
        if (type == "P") {
          if (freezeCTA) return;
          if (invoiceList.length == unique_id.length) {
            confirmAlert({
              title: "Creteria Not matched?",
              message: `Request can't be created as no invoice is fully filled.`
              ,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    setFreezeCTA(false);
                  },
                },
              ],
              onClickOutside: () => {
                setFreezeCTA(false);
              },
              onKeypressEscape: () => {
                setFreezeCTA(false);
              },
            });
          } else {
            confirmAlert({
              title: "Are you sure?",
              message: `Out of Total Number of Invoice ${invoiceList.length}; system has detected ${involist.length} invoice(s) is/are Partially or Not Filled.
              Please fill such invoices completely else system will be discard these invoices.`
              ,
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    submitDiscard(unique_id)
                  },
                },
                {
                  label: "No",
                  onClick: () => {
                    setFreezeCTA(false);
                  },
                },
              ],
              onClickOutside: () => {
                setFreezeCTA(false);
              },
              onKeypressEscape: () => {
                setFreezeCTA(false);
              },
            });
          }
        } else {
          confirmDialog(type)
        }
      } else {
        confirmDialog(type)
      }
    } else {
      toast.error("No Invoice added");
    }

  }


  const submitDiscard = (unique_id) => {

    if (mode == "create") {
      const myArray = invoiceList.filter(ar => !unique_id.find(rm => (rm === ar.invoice_id)))
      setInvoiceList(myArray);
      onSubmitHandler(myArray);

    } else {
     
      unique_id.find((el) => {
        setInvoiceList(invoiceList.map((elem) => {
          if (el == elem.invoice_id) {
            elem["is_active"] = false;
            return elem;
          } else {
            return elem;
          }
        }))
      })

     
      onSubmitHandler(invoiceList);

    }
    setInvoiceList(invoiceList.map((elem) => {
      if (elem.sub_transporter_list != undefined) {
        delete elem.sub_transporter_list
        return elem;
      } else {
        return elem;
      }
    }))


  };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler(invoiceList) : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };


  const onView = (data) => {
    setViewForm(true);
    let queryParm = "?invoice_number=" + data;
    TransportServices.ewayBillDetails(queryParm)
    .then((res) => {

     setEwayList(res?.data?.ewaybill_data[0])
     setEpodList(res?.data?.epod_data[0])

    })
    .catch((err) => {
      console.log(err);
    });
    
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const onChangeDateHandler = (tdate, index, mode, idate) => {

    const valid_days = moment(tdate)
    const days = moment(tdate).diff(moment(idate, "YYYY-MM-DD"), "days")

    setShipdelay(days);

    const dupRow = invoiceList
    dupRow[index].shipping_delay = days;
    dupRow[index].lr_gr_date = tdate;
    setInvoiceList(dupRow)

  }


  //onChangeVehicleHandler
  const onChangeVehicleHandler = (vnumber, index, type, mode) => {

  
    if (vnumber.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "vehicle_number") {
            elem["vehicle_number"] = vnumber;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "vehicle_number") {
            elem["vehicle_number"] = null;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }


  }

  //dropdownShippingHandler
  const dropdownShippingHandler = (shipmode, index, type, mode) => {

    if (shipmode != undefined) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "shipping_mode") {
            elem["shipping_mode"] = shipmode;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "shipping_mode") {
            elem["shipping_mode"] = null;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }

  const onChangeLRGRnumberHandler = (lrnumber, index, type, mode) => {
    if (lrnumber.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "lr_gr_number") {
            elem["lr_gr_number"] = lrnumber;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "lr_gr_number") {
            elem["lr_gr_number"] = null;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }


  }


  const onChangelcHandler = (loadingcharges, index, type, mode) => {
    if (loadingcharges.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "hamali") {
            elem["hamali"] = loadingcharges;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "hamali") {
            elem["hamali"] = 0;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }


  const onChangefcHandler = (freightcharges, index, type, mode) => {
    if (freightcharges.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "freight") {
            elem["freight"] = freightcharges;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "freight") {
            elem["freight"] = 0;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }



  const onChangeccHandler = (cartagecharges, index, type, mode) => {
    if (cartagecharges.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "cartage") {
            elem["cartage"] = cartagecharges;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "cartage") {
            elem["cartage"] = 0;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }



  const onChangeocHandler = (othercharges, index, type, mode) => {
    if (othercharges.length > 0) {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "other_charges") {
            elem["other_charges"] = othercharges;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "other_charges") {
            elem["other_charges"] = 0;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }



    
    const dropdownTruckHandler = (truck_id, index, type, invoicenum, id) => {
      setSubtransEdit(true)
      if (truck_id != "") {
        // const tranportname = transporterList.find((elem) => elem.transporter_id == truck_id)
        setInvoiceList(invoiceList.map((elem, i) => {
          if (index == i) {
            if (type == "truck_id") {
              elem["truck_id"] = Number(truck_id);
              // elem["truck_id"] = tranportname.transporter_name;
  
            }
            return elem;
          } else {
            return elem;
          }
        }))
  
      } else {
        setInvoiceList(invoiceList.map((elem, i) => {
          if (index == i) {
            if (type == "truck_id") {
              elem["truck_id"] = "";
  
            }
            return elem;
          } else {
            return elem;
          }
        }))
      }
  
  
    }
  
  const dropdownTransporterHandler = (transporterid, index, type, invoicenum, id) => {
    setSubtransEdit(true);
    if (transporterid != "") {
      const tranportname = transporterList.find((elem) => elem.transporter_id == transporterid)
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "transporter_id") {
            elem["transporter_id"] = Number(transporterid);
            elem["transporter_id__name"] = tranportname.transporter_name;

          }
          return elem;
        } else {
          return elem;
        }
      }))
      fetchsubtransport(transporterid, index, invoicenum, id);

    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "transporter_id") {
            elem["transporter_id"] = "";
            elem["transporter_id__name"] = "";
            elem["sub_transporter_id"] = "";
            elem["sub_transporter_id__name"] = "";
            elem["sub_transporter_list"] = [];

          }
          return elem;
        } else {
          return elem;
        }
      }))
      fetchsubtransport(transporterid, index, invoicenum);
      setTransporterList(transporterList);
    }


  }



  const fetchsubtransport = (transporterid, index, invoicenum, id) => {
    let queryParm = "?transporter_id=" + transporterid;
      
    if (id != "" && id != undefined) {
      if (transporterid != "" && transporterid != undefined) {
        handleLoader();
        getSubtransporter(queryParm)
          .then((response) => {
            

            const parentRow = invoiceList.find((row) => row.invoice_id == id);
            const ind = invoiceList.findIndex((row) => row.invoice_id == id);
        
            const index = ind;
            const dupRow = { ...parentRow }
            dupRow.sub_transporter_list = response?.data?.dataList;
            dupRow.sub_transporter_id = "";
            dupRow.sub_transporter_id__name = "";

            const full_data = invoiceList;

            full_data[index] = dupRow;
      
            setInvoiceList(full_data);
          
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });;

     

      }
    } else {
      if (transporterid != "" && transporterid != undefined) {
        handleLoader();
        getSubtransporter(queryParm)
          .then((response) => {
           
         

            const parentRow = invoiceList.find((row) => row.invoice_id == id);
            const ind = invoiceList.findIndex((row) => row.invoice_id == id);
         
            const index = ind;
            const dupRow = { ...parentRow }
            dupRow.sub_transporter_list = response?.data?.dataList;
            dupRow.sub_transporter_id = "";
            dupRow.sub_transporter_id__name = "";
     
            const full_data = invoiceList;

            full_data[index] = dupRow;
      
            setInvoiceList(full_data);
           

          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          }).finally(()=>{
            handleLoader(false);
          });

      

      }
    }
  }


  const dropdownSubtHandlerr = (subtransporterid, index, type) => {

    if (subtransporterid != "") {
      const subtranporter = invoiceList[index].sub_transporter_list.find((elem) => elem.sub_transporter_id == subtransporterid);
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "sub_transporter_id") {
            elem["sub_transporter_id"] = Number(subtransporterid);
            elem["sub_transporter_id__name"] = subtranporter.sub_transporter_name;

          }
          return elem;
        } else {
          return elem;
        }
      }))



    } else {
      setInvoiceList(invoiceList.map((elem, i) => {
        if (index == i) {
          if (type == "sub_transporter_id") {
            elem["sub_transporter_id"] = "";
            elem["sub_transporter_id__name"] = "";

          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
  }





  const onSubmitHandler = (invo) => {
    // setLoader(true);
    setInvoiceList(invoiceList.map((elem) => {
      if (elem.sub_transporter_list != undefined) {
        delete elem.sub_transporter_list
        return elem;
      } else {
        return elem;
      }
    }))

    if (mode == "create") {
      let query = "";
      query = "?plant_id=" + selectedPlant
        + "&status=" + "P"
        + "&date_of_invoice=" + moment(invoicedate.on_date).format("YYYY-MM-DD");
      handleLoader();
      TransportServices.
        postInvoiceRequest(query, invo)
        .then((res) => {
        
          toast.success("Submit succesfull!");
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        })
        .catch((err) => {
         
          toast.error("Submit failed!");
      
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }


    if (mode == "edit") {

      const params = new URLSearchParams(window.location.search);
      let query = "";
      query = "?request_id=" + params.get("requestID")
        + "&status=" + "P";

 
      handleLoader();
      TransportServices.
        putInvoiceRequest(query, invoiceList)
        .then((res) => {
      
          toast.success("Submit succesfull!");
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        })
        .catch((err) => {
        
          //toast.error("Submit failed!");
          toast.success("Submit succesfull!");
          setTimeout(() => {
            navigate("/transport");
          }, 1000);
        }).finally(()=>{
          handleLoader(false);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/transport");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/transport");
  };

  // approval/reject validate
  const validate = (value) => {
    let data = {
      request_id: requestid,
      plant_id: plantid,
      approval_id: approvalid,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    let queryParm = "?request_id=" + requestid + "&status=" + `${value === "approve" ? "A" : "R"}` + "&approval_id=" + approvalid + "&plant_id=" + plantid + "&remarks=" + remark
    handleLoader();
    TransportServices.
      postApproverAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/transport");
        }, 2000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      }).finally(()=>{
        handleLoader(false);
      });
   
  };

  // (function () {
  //   if (loader) {
  //     dispatch(setDisplayLoader("Display"));
  //   } else {
  //     dispatch(setDisplayLoader("Hide"));
  //   }
  // })();


  //JSX markup language
  return (
    <>
      
      <div className={styles["transport-portal-container"]}>
        <ToastContainer autoClose={1000} />
        <div className={styles["transport-portal-content"]}>
          <div
            className={styles["heading"]}
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <h4 class="inner-page-title" style={{ margin: "0" }}>
              Transport Data
            </h4>


          </div>
          <div className="col-md-12 my-2">
            <div className="border-top md-3"> </div>
          </div>
          <div
            className={styles["heading"]}
            style={{
              paddingTop: "0",
              paddingBottom: "15px",
              // justifyContent: "start",
            }}
          >
            <div
              className={styles["transport-portal-label"]}
              style={{ display: "flex" }}
            >
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["transport-portal-label"]}>
                  <b>Plant Name : </b>
                </div>
                <div className={styles["valueboxone"]}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      style={{
                        cursor: "pointer",
                        border: "1px solid #0195D4",
                        color: "#0195DA",
                        minWidth: "120px",
                      }}
                      disabled={mode == "edit" || mode == "view"}

                      value={selectedPlant}
                      classNamePrefix="select"
                      onChange={(e) => {

                        plantChangeHandler(e.target.value);
                      }}
                    >

                      {mode == "create" ?
                        <option value={"select plant"} label={"Plant Name"} /> :
                        ""
                      }
                      {mode == "create" && plant.length >= 0 &&
                        plant?.map((el, index) => {
                          return <option key={index} value={el.plant_id} label={el.plant_name + "(" + el.plant_code + ")"} />;
                        })}

                      {mode !== "create" && plant.length >= 0 &&
                        plant?.map((el, index) => {
                          return <option key={index} value={el.plant_id} label={el.plant_name + "(" + el.plant_code + ")"} />;
                        })}

                    </select>


                  </div>
                </div>
              </div>


              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["transport-portal-label"]}>

                  <b>Date of Invoice : </b>
                </div>
                <div className={styles["valueboxone"]}>
                  {mode == "create" && mode != undefined ? (
                    <input
                      type="date"
                      class="form-control"
                      name="from_date"
                      onChange={(e) => {
                        setInvoicedate({
                          ...invoicedate,
                          on_date: e.target.value,
                        })
                      }}

                      value={invoicedate?.on_date}
                      label={invoicedate.on_date}
                    />
                  ) : (
                    <input
                      type="date"
                      class="form-control"
                      name="from_date"
                      value={invoicedate?.on_date}
                      label={invoicedate.on_date}
                      disabled
                    />
                  )
                  }


                </div>
              </div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["transport-portal-label"]}>

                </div>
                <div className={styles["valueboxone"]}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {
                      mode == "create" && mode != undefined ? (
                        <button type="button" class="btn btn-success btn-sm" onClick={searchInvoiceList}>Search</button>
                      ) : (
                        ""
                      )
                    }

                  </div>
                </div>
              </div>
              { mode !=undefined && mode == "view" &&
                    <div style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "100px",
              }}>
                <div>
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                      style={{ fontSize: "14px" }}
                    >
                      <img
                        src="images/export.png"
                        alt=""
                        className="mr-2"
                      />
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          // disabled={userList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails()
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
              </div>
                 }
              <div >
              <h6 style={{marginLeft:"60px", alignItems: "right", justifyContent: "center", color: "red" }} >* Mandatory fields</h6>
            </div>
            </div>
          </div>
          <div className={styles["table_contain"]}>
            <div className={styles["table"]}>

              <div>
                {/* style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }} */}
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                    <thead>
                      <tr style={{ minWidth: "150px" }}>
                        <th>Invoice/<br></br>STF No.</th>
                        <th>Date of Invoice</th>
                        <th>Customer Name</th>
                        <th>Customer<br></br> Code</th>
                        <th>City</th>
                        <th>Qty in Case/<br></br>Drum/Pack</th>
                        <th>Qty in<br></br> Kg/Ltr</th>
                        <th>Transporter Name <span style={{color:"red"}}>*</span></th>
                        <th>Sub Transporter Name</th>
                        <th>LR/RR Number <span style={{color:"red"}}>*</span></th>
                        <th>LR/RR Date<span style={{color:"red"}}>*</span></th>
                        <th>Vehicle<br></br> Number <span style={{color:"red"}}>*</span></th>
                        <th>Shipping Delay</th>
                        <th>Loading <br></br> Charges</th>
                        <th>Freight</th>
                        <th>Cartage</th>
                        <th>Other Charges</th>
                        <th>Shipping<br></br> Mode</th>
                        <th>Truck Type</th>
                        <th>EWAY/EPOD Detail</th>
                        
                        {/* <th>Split Invoice</th> */}
                      </tr>
                    </thead>
                    <tbody>

               


                      {((mode != undefined && mode == "view") || (mode != undefined && mode == "approve")) &&
                        (invoiceList != undefined && (invoiceList.map((el, i) => {
                          return <tr>
                  
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.invoice_number}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}> {moment(el?.invoice_date).format("DD-MM-YYYY")} </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_name}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_code}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_city}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.qty_case_box}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right"}}>{el.qty_kg_ltr}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.transporter_id__name}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.sub_transporter_id__name}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.lr_gr_number}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{moment(el?.lr_gr_date).format("DD-MM-YYYY")}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.vehicle_number}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.shipping_delay}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.hamali}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.freight}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.cartage}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.other_charges}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.shipping_mode}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.truck_type}  </td>
                           
                            <td> 
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(el.invoice_number);
                              }}
                            >
                              <img src="../images/eye.png" alt="" />
                            </button>
                        </td>
                      
                          </tr>
                        }))
                        )}
                      {invoiceList.length ? (
                        (mode != undefined) && (mode == "create" || mode == "edit") &&
                        (invoiceList != undefined && (invoiceList.map((el, i) => {
                          return <tr>
                          
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.invoice_number}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{moment(el?.invoice_date).format("DD-MM-YYYY")}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_name}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_code}  </td>

                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.customer_city}  </td>

                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal",  textAlign: "right"}}>{el.qty_case_box}  </td>
                            <td className={styles["transport-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.qty_kg_ltr}  </td>

                            <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "160px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                value={el.transporter_id}
                                //  label= {el.transporter_id__name}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  dropdownTransporterHandler(e.target.value, i, "transporter_id", el.invoice_number, el.invoice_id)
                                }} >
                                <option value="" >
                                  Transporter Name
                                </option>


                                {transporterList != undefined && transporterList.length >= 0 &&
                                  transporterList.map((el, Index) => {

                                    return <option key={Index} value={el.transporter_id}>
                                      {el.transporter_name}
                                    </option>

                                  })}
                              
                              </select></td>



                            <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "160px", textOverflow: "clip", whiteSpace: "normal" }}>


                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                style={{ cursor: "pointer" }}
                                value={el.sub_transporter_id}
                                onChange={(e) => {
                                  dropdownSubtHandlerr(e.target.value, i, "sub_transporter_id")
                                }} >
                                <option value="">
                                  SubTransporter Name
                                </option>

                                {el.sub_transporter_list != undefined && el.sub_transporter_list.length != undefined && el.sub_transporter_list.length != 0
                                  && el.sub_transporter_list.map((tel, optionIndex) => (
                                    <option key={optionIndex} value={tel.sub_transporter_id}
                                    >

                                      {tel.sub_transporter_name}
                                    </option>
                                  ))}
                                        

                              </select>


                
                            </td>

                            {/* //FOR EDITABLE TEXT INPUTS */}

                            <td className={styles["transport-portal-table"]}
                              style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="text" maxlength="20" value={el.lr_gr_number} style={{ width: "100%", padding: "2px" }}
                                onChange={(e) => onChangeLRGRnumberHandler(e.target.value, i, "lr_gr_number", mode)} />         </td>

                            <td className={styles["transport-portal-table"]} style={{ maxWidth: "160px", minWidth: "120px", padding: "2px", textOverflow: "clip", whiteSpace: "normal", position: "relative", marginRight: "5px" }}>

                              <input className="inputhtStyle" type="date" min={invoicedate.on_date} max={date} value={el.lr_gr_date} style={{ width: "100%", marginRight: "5px" }} onChange={(e) => onChangeDateHandler(e.target.value, i, mode, el.invoice_date)} />        </td>

                            <td className={styles["transport-portal-table"]} style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="text" maxlength="15" value={el.vehicle_number} style={{ width: "100%", padding: "2px" }} onChange={(e) => onChangeVehicleHandler(e.target.value, i, "vehicle_number", mode)} />
                            </td>
                            <td className={styles["transport-portal-table"]} style={{ textAlign: "left", maxWidth: "160px", minWidth: "40px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="number" value={el.shipping_delay} style={{ textAlign: "right", padding: "2px", width: "100%" }}
                                disabled />
                            </td>
                            <td className={styles["transport-portal-table"]}
                              style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="number" maxlength="12" value={el.hamali} style={{ width: "100%", padding: "2px" }}
                                onChange={(e) => onChangelcHandler(e.target.value, i, "hamali", mode)} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');"/>         </td>
                            <td className={styles["transport-portal-table"]}
                              style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="number" maxlength="12" value={el.freight} style={{ width: "100%", padding: "2px" }}
                                onChange={(e) => onChangefcHandler(e.target.value, i, "freight", mode)} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');"/>         </td>
                            <td className={styles["transport-portal-table"]}
                              style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="number" maxlength="12" value={el.cartage} style={{ width: "100%", padding: "2px" }}
                                onChange={(e) => onChangeccHandler(e.target.value, i, "cartage", mode)} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');"/>         </td>
                            <td className={styles["transport-portal-table"]}
                              style={{ maxWidth: "160px", minWidth: "120px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" type="number" maxlength="12"  value={el.other_charges} style={{ width: "100%", padding: "2px" }}
                                onChange={(e) => onChangeocHandler(e.target.value, i, "other_charges", mode)} onKeyDown={(event) => { if (event.target.value.length >= 12 && event.key != "Backspace") { event.preventDefault() }}} oninput="validity.valid||(value='');"/>         </td>
                            <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "100px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                style={{ cursor: "pointer" }}
                                value={el.shipping_mode}
                                onChange={(e) => {
                                  dropdownShippingHandler(e.target.value, i, "shipping_mode", mode)
                                }} >
                                {shippingMode.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                              </select></td>

                              <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "160px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                value={el.truck_id}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  dropdownTruckHandler(e.target.value, i, "truck_id", el.invoice_number, el.id)
                                }} >
                                <option value="" >
                                  Truck Type
                                </option>


                                {truckList != undefined && truckList.length >= 0 &&
                                  truckList.map((el, Index) => {

                                    return <option key={Index} value={el.truck_id}>
                                      {el.truck_type}
                                    </option>
                                  })}
                              </select></td>

                              <td> 
                          <button
                            type="button"
                            className="btn btn-blanktd text-primary"
                            onClick={() => {
                              onView(el.invoice_number);
                            }}
                          >
                            <img src="../images/eye.png" alt="" />
                          </button>
                      </td>
                     

                          </tr>
                        }))
                        )) : (
                        <tr>
                          <td colSpan={21}> No Record Found</td>
                        </tr>)}

                    </tbody>

                  </table>
                </div>
              </div>











            </div>
          </div>
  
          {approvalList?.length > 0 &&
            mode != undefined && (mode != "create") && (
              <div className={styles["table_contain"]}>
                <div className={styles["transport-portal-table"]}>
                  <div
                    class="table-responsive"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <table
                      class="table table-striped table-bordered tablecured tablcuredlastnew"
                      style={{ maxHeight: "200px" }}
                    >
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr style={{ textAlign: "center" }}>
                          <th>Action At</th>
                          <th>Action By</th>

                          <th>Action Status</th>
                          <th>Action Remark</th>
                        </tr>
                      </thead>
                      <tbody>

                    
                        {approvalList &&
                          approvalList.map((el, i) => {
                            let status;

                            // const findPendingIndex =
                            //   approvalList.findIndex(
                            //     (ele) => ele.status == "P"
                            //   );

                            if (el.status == "D") status = "Saved";
                            else if (el.status == "P") status = "Pending";
                            else if (el.status == "A") status = "Approved";
                            else if (el.status == "R") status = "Rejected";
                            else if (el.status == "RP") status = "Auto Rejected";


                          
                            return (
                              <tr>
                                <td>
                                  {status == "Pending"
                                    ? "-"
                                    : el.action_at == "None"
                                      ? "-"
                                      : moment(el.action_at)
                                        .utc()
                                        .format("DD-MM-YYYY")}
                                </td>
                                <td>{el.pending_at_first_name}</td>
                                <td>
                                  {status}
                                </td>
                                <td>
                                  {el.remarks == "None"
                                    ? "-"
                                    : el.remarks
                                  }
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

          <div className={styles["ctas"]}>
            {mode != "view" && mode != "approve" && (
              <div className={styles["ctas_left"]}>
                <div
                  class="btn btn-success"
                  onClick={() => {
                    onValidation("P");
                  }}
                >
                  Submit
                </div>
                <div
                  class="btn btn-primary-inner"
                  onClick={() => {
                    onValidation("D");
                  }}
                >
                  Save
                </div>
              </div>
            )}
            {mode == "approve" ? (
              <div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Add Comments <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-right pb-3">
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("approve")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-outline-danger mr-3 ml-3"
                    type="button"
                    onClick={() => {
                      onApprovalCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("reject")}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles["ctas_right"]}>
                <div class="btn btn-danger" onClick={onCancelHandler}>
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {viewForm ? (
        <>
          <ViewTable
            viewForm={viewForm}
            onViewClose={onViewClose}
            epodList = {epodList}
            ewayList = {ewayList}

          />
        </>
      ) : (
        ""
      )}
    </>
  )

}



export default Table;