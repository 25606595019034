import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import * as CreateSkillService from "../../service/createSkill";
if (typeof window !== "undefined") {
    injectStyle();
}

const MySkill = (props) => {
    const { currentTab, isDisplay } = props;
    const [skillList, setSkillList] = useState([]);

    const getSkillStatusData = () => {
        CreateSkillService.getUserSkill()
            .then((response) => {
                setSkillList(response?.data?.dataList?.result?.result);
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };

    useEffect(() => {
        getSkillStatusData();
    }, [currentTab, isDisplay]);

    return (
        <>
            <div className="px-4 pb-2">
                <ToastContainer autoClose={1000} />
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Skill ID</th>
                            <th>Location</th>
                            <th>Current Level</th>
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>Planned Level</th>
                            <th>Actual Level</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {skillList?.length ? (
                            skillList?.map((data, index) => (
                                <>
                                    <tr key={`desg${index}`}>
                                        <td className="text-theme fw-bold">{data?.year}</td>
                                        <td>{data?.quarter}</td>
                                        <td>{data?.skill_Id}</td>
                                        <td>{data?.location}</td>
                                        <td>{data?.current_level}</td>
                                        <td>{data?.employee_id}</td>
                                        <td>{data?.employee_name}</td>
                                        <td>{data?.planned_level}</td>
                                        <td>{data?.actual_level}</td>
                                        <td>
                                            {data?.status}
                                        </td>
                                    </tr>
                                </>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={16}> No Record Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default MySkill;
