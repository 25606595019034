import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSkills = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/skill-master?${queryParm}`);
};
export const getSubPersonal = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/personal-sub-area/drop-down/${id}`);
};
export const saveSkills = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-master`, data);
};

export const updateSkills = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-master/${id}`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/skill-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteSkill = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-master/${id}`);
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-master/exists`, data);
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/skill-master/bulk_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};
export const importExelGroup = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/user-group-mapping-master/bulk_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const getSkillPermission = async () => {
  return axios.get(`${AUTH_BASE_URL}/skill-tab-access-master/access_list`);
};

export const getPermissions = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/permissions/get-my-url-permission?module_end_point=${query}`);
}
