import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as HSNService from "../../service/hsnService";

function ViewHSNApproval(props) {
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState();
  const [errorForRemarks, setErrorForRemarks] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
    is_exception: false,
    is_exception_error: false,
  });

  useEffect(() => {
    getHSNCodeDetails(location?.state?.from);
  }, []);

  const getHSNCodeDetails = async (id) => {
    await HSNService.getHsnDetailsById(id).then((response) => {
      if (response?.status === 200) {
        setData(response?.data?.dataList?.result[0]);
      }
    });
  };

  const submitAction = (id, stat) => {
    let data = status;
    let idlist = [];
    data.status = stat;
        data.hsn_id = id;
        changeHsnStatus(data);
  };
  const changeHsnStatus = (data) => {
    if (data.remarks != "") {
    //   setIsLoading(true);
      HSNService.changeHsnStatus(data)
        .then((response) => {
        //   getAppliedVPFRequest(location?.state?.from);
        //   setData({
        //     status: "",
        //     remarks: "",
        //   });
        //   setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      setErrorForRemarks(true);
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.requestor}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Material Description</label>
                        <p>{Data?.material_description}</p>
                      </div>
                    </div>
                   
                  
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>HSN Code</label>
                        {Data?.hsn_code !== 0 && Data?.hsn_code !== '0' ?
                        <p>
                          {Data?.hsn_code}
                        </p>
                        :
                        <p>
                          {Data?.new_hsn_code}
                        </p>
                        }
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Plant</label>
                        <p>{Data?.plant_description} - {Data?.plant_code}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Technical Description</label>
                        <p>{Data?.desc}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Material Type</label>
                        <p>{Data?.material_type_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Material Code</label>
                        <p>{Data?.material_code}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requested at</label>
                        <p>{moment(Data?.created_at).format("DD-MM-YYYY")}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label></label>
                    <textarea
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }));
                        setErrorForRemarks(false);
                      }}
                      class="form-control"
                      placeholder="remarks..."
                    ></textarea>
                    <div className="small text-danger">
                      {errorForRemarks &&
                        "* Kindly give remarks."}
                    </div>
                  </div>
                </div>
                    <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                    <>
                      <button
                        onClick={() => {
                          submitAction(Data?.id, "REJECTED");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(Data?.id, "APPROVED");
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </>
                  );
}

export default ViewHSNApproval;
