import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as PolicyService from "../../service/policy";
import * as BrandService from "../../service/brandService";
import * as CmsNewsService from "../../service/Cms-newsService";
import { replace } from "formik";

function ApproveCmsModule(props) {
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState(null);
  const [error, seterror] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
  });

  const getPolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    PolicyService.getPolicy(
      handleValuePolicy(
        search,
        organisation_id,
        category,
        policy_id,
        status,
        pageNo,
        page_size,
        sortBy,
        paginate
      )
    )
      .then((response) => {
        // console.log(response?.data?.dataList?.result);
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValuePolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sortBy +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (policy_id !== "" && policy_id !== undefined && policy_id !== null) {
      queryParm = queryParm + "&policy_id=" + policy_id;
    }
    return queryParm;
  };

  const handleValueBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      branding_id !== "" &&
      branding_id !== undefined &&
      branding_id !== null
    ) {
      queryParm = queryParm + "&branding_id=" + branding_id;
    }
    return queryParm;
  };

  const getBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    BrandService.getBrand(
      handleValueBrand(
        search,
        organisation_id,
        branding_id,
        category,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getData = async (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    data_id
  ) => {
    await CmsNewsService.getNotice(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      data_id
    )
      .then((response) => {
        setData(response?.data?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    switch (location?.state?.type) {
      case "Policy":
        getPolicy("", "", "", id, true, 1, 10, "id", true);
        break;
      case "Branding":
        getBrand("", "", id, "", true, 1, 10, "id", true);
        break;
      case "News, Notice & Popup":
        getData(1, 10, "id", "", true, "", "", "", id);
        break;
      default:
        break;
    }
  }, []);
  const submitAction = (id, stat) => {
    let data = status;
    let idList = [];
    data.status = stat;
    switch (location?.state?.type) {
      case "Policy":
        idList.push(id);
        data.policy_id = idList;
        changePolicyRemarks(data);
        break;
      case "Branding":
        idList.push(id);
        data.branding_id = idList;
        changeBrandingRemarks(data);
        break;
      case "News, Notice & Popup":
        idList.push(id);
        data.news_id = idList;
        changeNewsRemarks(data);
        break;
    }
  };

  const changePolicyRemarks = (data) => {
    if (data.status == "A" || data.remarks != "") {
      setIsLoading(true);
      PolicyService.changePolicyStatus(data)
        .then((response) => {
          getPolicy("", "", "", id, true, 1, 10, "id", true);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeBrandingRemarks = (data) => {
    if (data.status == "A" || data.remarks != "") {
      setIsLoading(true);
      BrandService.changeBrandingStatus(data)
        .then((response) => {
          getBrand("", "", id, "", true, 1, 10, "id", true);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeNewsRemarks = (data) => {
    if (data.status == "A" || data.remarks != "") {
      setIsLoading(true);
      CmsNewsService.changeNewsStatus(data)
        .then((response) => {
          // getData(1, 10, "id", "", true, "", "", "", id);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                {location?.state?.type === "Policy" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Category</label>
                        <p>{Data?.category_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organisation.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch.map((obj) => {
                          return <li>{obj?.branch_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Department</label>
                        {Data?.department.map((obj) => {
                          return <li>{obj?.department_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Policy Sub Title</label>
                        <p>{Data?.sub_title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Users</label>
                        {Data?.user.map((obj) => {
                          return <li>{obj?.user_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.desc}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment.map((a) => (
                            <>
                              <a
                                href={a?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                                zIndex="100"
                              >
                                <span class="mr-3">
                                  {a?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <img
                                      src={"images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : a?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <img
                                      src={"images/policy-icon/video.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : (
                                    <img
                                      src={"images/policy-icon/img.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  )}
                                </span>
                              </a>
                              <br />
                            </>
                          ))}
                        </p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "News, Notice & Popup" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by?.username}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Type</label>
                        <p>{Data?.Type}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Display On</label>
                        <p>
                          {Data?.display_on?.length > 0 &&
                            Data?.display_on?.map((x) => x)}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organization?.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch?.map((obj) => {
                          return <li>{obj?.name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Unit City</label>
                        {Data?.branch_city?.map((obj) => {
                          return <li>{obj?.name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Start Date</label>
                        <p>
                          {moment
                            .utc(Data?.startDateTime)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>End Date</label>
                        <p>
                          {moment
                            .utc(Data?.endDateTime)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>
                          {Data?.description
                            ?.replace("<p>", "")
                            ?.replace("</p>", "")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment?.length !== 0 ? (
                            Data?.attachment?.map((a) => (
                              <a
                                href={a?.attachment_path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span class="mr-3">
                                  {a?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".pdf") ? (
                                    <img
                                      src={"images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : a?.attachment_path
                                      .split("/")
                                      .pop()
                                      .includes(".mp4") ? (
                                    <img
                                      src={"images/policy-icon/video.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  ) : (
                                    <img
                                      src={"images/policy-icon/img.png"}
                                      alt="image"
                                      width="40px"
                                      height="40px"
                                    />
                                  )}
                                </span>
                              </a>
                            ))
                          ) : Data?.attachment?.length === 0 &&
                            Data?.external !== "" ? (
                            <iframe
                              src={Data?.external}
                              frameborder="0"
                            ></iframe>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Branding Category</label>
                        <p>{Data?.category_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Organization</label>
                        <ul>
                          {Data?.organisation.map((obj) => {
                            return (
                              <li>
                                {obj?.name} ({obj?.code})
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Business Units</label>
                        {Data?.branch.map((obj) => {
                          return <li>{obj?.branch_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Department</label>
                        {Data?.department.map((obj) => {
                          return <li>{obj?.department_name})</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Branding Title</label>
                        <p>{Data?.title}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Users</label>
                        {Data?.user.map((obj) => {
                          return <li>{obj?.user_name}</li>;
                        })}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Description</label>
                        <p>{Data?.desc}</p>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group innergroup">
                        <label>Download Attachments</label>
                        <p>
                          {Data?.attachment.map((a) => (
                            <a
                              href={a?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span class="mr-3">
                                {a?.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                  <img
                                    src={"images/policy-icon/pdf.png"}
                                    alt="image"
                                    width="40px"
                                    height="40px"
                                  />
                                ) : a?.attachment_path
                                    .split("/")
                                    .pop()
                                    .includes(".mp4") ? (
                                  <img
                                    src={"images/policy-icon/video.png"}
                                    alt="image"
                                    width="40px"
                                    height="40px"
                                  />
                                ) : (
                                  <img
                                    src={"images/policy-icon/img.png"}
                                    alt="image"
                                    width="40px"
                                    height="40px"
                                  />
                                )}
                              </span>
                            </a>
                          ))}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {(Data?.status == null || Data?.status_approve === null) && (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label></label>
                    <textarea
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give comment
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          submitAction(Data?.id, "D");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(Data?.id, "A");
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveCmsModule;
