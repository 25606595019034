import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
// import { Spinner } from "react-bootstrap";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import moment from "moment";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import AddChangeShift from "./AddChangeShift";
import * as ShiftDataService from "../../service/changeShift";
import { useEffect } from "react";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { get_sap_schedule } from "../../service/searchpo";
// import Tooltip from 'react-bootstrap/Tooltip';

const ChangeShiftIndex = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)
  const [showForm, setShowForm] = useState(false);

  const onClose = () => {
    setShowForm(false);
  };

  const addNewChangeShift = () => {
    setEditValue(false);
    setShowForm(true);
  };
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [changeShiftData, setChangeShiftData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [changeShiftPermission, setChangeShiftPermission] = useState(false);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-shift_date",
  });
  const [shiftDataFilter, setShiftDataFilter] = useState({
    from_date: "",
    to_date: "",
    status: "",
  });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  const handleKey = (
    search,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getChangeShiftData(
      searchStr,
      shiftDataFilter.from_date,
      shiftDataFilter.to_date,
      shiftDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const onAddCallBack = (response) => {
    getChangeShiftData(
      searchStr,
      shiftDataFilter.from_date,
      shiftDataFilter.to_date,
      shiftDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    if (response?.data?.message == "Shift change requested successfully") {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
  };

  const getChangeShiftData = (
    search,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    ShiftDataService.getChangeShiftData(
      handleKey(
        search,
        from_date,
        to_date,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setChangeShiftData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setIsLoading(false)
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  // useEffect(() => {
  //   getChangeShiftData("","","","",1,10,"-id",true)
  // }, [])

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getChangeShiftData(
      searchStr,
      shiftDataFilter.from_date,
      shiftDataFilter.to_date,
      shiftDataFilter.status,
      activePage,
      pageSizeNo,
      "-id",
      true
    );
  };

  const deleteChangeShiftData = (idx) => {
    ShiftDataService.deleteChangeShiftData(idx)
      .then((res) => {
        if (res.data.status === true) {
          getChangeShiftData(
            "",
            "",
            "",
            "",
            1,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    confirmAlert({
      title: "Delete ChangeShift",
      message: `Are you sure to delete ${deptName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteChangeShiftData(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const editShiftChange = (data) => {
    setShowForm(true);
    setEditValue(true);
    setChangeShiftData(data);
    setEditId(data.id);
  };

  const handleExport = (search, from_date, to_date, status, sort_by) => {
    let queryParm = "sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const getExportValue = (search, from_date, to_date, status, sort_by) => {
    dispatch(setDisplayLoader("Display"));
    ShiftDataService.getExportValue(
      handleExport(search, from_date, to_date, status, sort_by)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shiftChange.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const mainDataFilter = (shiftDataFilter) => {
    setFilterApplied(true);
    const frDate = shiftDataFilter.from_date;
    const toDate = shiftDataFilter.to_date;
    const stats = shiftDataFilter.status;
    getChangeShiftData(
      searchStr,
      frDate,
      toDate,
      stats,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(shiftDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setShiftDataFilter(ClearData);
    getChangeShiftData(
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
      // true,
      // search, organisation_id, status, pageNo, page_size, sort_by, paginate
    );
    filterclose();
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getChangeShiftData(
        data,
        shiftDataFilter.from_date,
        shiftDataFilter.to_date,
        shiftDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
        // search, organisation_id, status, pageNo, page_size, sort_by, paginate
      );
    }
  };

  const handleSearch = (e) => {
    getChangeShiftData(
      e.target.value.toLowerCase(),
      shiftDataFilter.from_date,
      shiftDataFilter.to_date,
      shiftDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
      // true
    );
    setSearchStr(e.target.value);
  };

  const getRightForChangeShift = () => {
    ShiftDataService.getRightForChangeShift().then((res) => {
      setChangeShiftPermission(!res?.data?.dataList?.shiftChangePermission);
    });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    // setIsLoading(true)
    getChangeShiftData(
      searchStr,
      shiftDataFilter.from_date,
      shiftDataFilter.to_date,
      shiftDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    // getRightForChangeShift();
    getSapSchedule();
  }, [pageSizeNo, showForm]);


  const getSapSchedule = () => {
    get_sap_schedule('sync_change_shift').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }


  return (
    <>
      <div class="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Change Shift</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Profile</Breadcrumb.Item>
                <Breadcrumb.Item active>Change Shift</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="overlay-example">
                    Search on{" "}
                    <strong> Assigned Shift, Requested Shift Name</strong>.
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    id="searchbar"
                    class="form-control"
                    placeholder="Search on Assigned Shift..."
                    onChange={(e) => handleSearch(e)}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        type="button"
                        disabled={changeShiftData.length !== 0 ? false : true}
                        style={{ border: 0, background: "none" }}
                        onClick={() =>
                          getExportValue(
                            searchStr,
                            shiftDataFilter.from_date,
                            shiftDataFilter.to_date,
                            shiftDataFilter.status,
                            sortData.sort_by
                          )
                        }
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button onClick={filteropen} class="btn btn-secondary-inner" style={{
                  backgroundColor: filterApplied && "yellow",
                  color: filterApplied && userData?.dark_mode === false && "#000"
                }}>
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    class="btn btn-primary-inner"
                    onClick={() => addNewChangeShift()}
                  // disabled={changeShiftPermission}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                ) : (
                  <Tooltip
                    title="You have not permission to request for shift change."
                    position="bottom"
                  >
                    <button
                      class="btn btn-primary-inner"
                      // onClick={() => addNewChangeShift()}
                      disabled
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </Tooltip>

                )}
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <b style={{ fontSize: "13px" }}>Next Shift Change Sync:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>S.No</th>
                <th
                  onClick={(e) => {
                    handlerData("shift_date");
                  }}
                  className={
                    sortData["shift_date"] === undefined
                      ? "unsortcls"
                      : sortData["shift_date"]
                  }
                >
                  Date
                </th>
                <th>Requested Shift Name </th>
                <th
                  onClick={(e) => {
                    handlerData("assigned_shift");
                  }}
                  className={
                    sortData["assigned_shift"] === undefined
                      ? "unsortcls"
                      : sortData["assigned_shift"]
                  }
                >
                  Assigned Shift{" "}
                </th>
                <th
                  onClick={(e) => {
                    handlerData("reasons");
                  }}
                  className={
                    sortData["reasons"] === undefined
                      ? "unsortcls"
                      : sortData["reasons"]
                  }
                >
                  Reason
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                >
                  Approval Status
                </th>
                <th>Savior Response</th>
                <th>Savior Status</th>
                <th>Approved On</th>
                <th>Savior Last Sync</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {changeShiftData.length ? (
                changeShiftData.map((data, index) => (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{moment(data.shift_date).format("DD-MM-YYYY")}</td>
                      <td>
                        {data.shift_name} (
                        {data?.shift_time_in != null
                          ? data?.shift_time_in.slice(0, 5) + " hrs to"
                          : ""}{" "}
                        {data?.shift_time_out != null
                          ? data?.shift_time_out.slice(0, 5) + " hrs"
                          : ""}
                        )
                      </td>
                      <td>
                        {data.assign_shift} (
                        {data?.assigned_shift_time_in != null
                          ? data?.assigned_shift_time_in.slice(0, 5)
                          : ""}{" "}
                        hrs to{" "}
                        {data?.assigned_shift_time_out != null
                          ? data?.assigned_shift_time_out.slice(0, 5)
                          : ""}{" "}
                        hrs)
                      </td>
                      <td>{data.reasons}</td>
                      <td>
                        {data.status == "P" ? (
                          <span class="badge bad-status badge-warning">
                            Pending
                          </span>
                        ) : data.status == "A" ? (
                          <span class="badge bad-status badge-success">
                            Approved
                          </span>
                        ) : data.status == "D" ? (
                          <span class="badge bad-status badge-danger">
                            Rejected
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {data?.sap_error !== null && (
                          <Tooltip title={data?.sap_error} position="bottom">
                            {data?.sap_error?.substr(0, 12)}...
                          </Tooltip>
                        )}
                      </td>
                      {data?.sap_status === true ? (
                        <td>Synced</td>
                      ) : (
                        <td>Pending to Sync</td>
                      )}
                      {data?.approved_date !== null ? (
                        <td>
                          {moment
                            .utc(data?.approved_date)
                            .format("DD-MM-YYYY HH:mm")}{" "}
                          hrs
                        </td>
                      ) : (
                        <td></td>
                      )}
                      {data?.sync_date !== null ? (
                        <td>
                          {moment
                            .utc(data?.sync_date)
                            .format("DD-MM-YYYY HH:mm")}{" "}
                          hrs
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td>
                        {data.status == "P" ? (
                          <>
                            <button
                              class="btn btn-blanktd text-primary"
                              type="button"
                              onClick={() => {
                                editShiftChange(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data.id, data.shift_name);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </>
                        ) : (
                          <>
                            <Tooltip
                              title="You are not permitted."
                              position="bottom"
                            >
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </button>
                            </Tooltip>
                            <Tooltip
                              title="You are not permitted."
                              position="bottom"
                            >
                              <button
                                type="button"
                                class="btn btn-blanktd text-danger ml-2"
                                disabled
                              >
                                <i class="far fa-trash-alt text-danger"></i>
                              </button>
                            </Tooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {changeShiftData?.length === 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                disableInitialCallback={true}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Shift Date</label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  from_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={shiftDataFilter.from_date}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              From To<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              name="to_date"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  to_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={shiftDataFilter.to_date}
            />
          </div> */}
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={shiftDataFilter.status}
            >
              <option>Select</option>
              <option value="P">Pending</option>
              <option value="A">Approved</option>
              <option value="D">Rejected</option>
              {/* <option>Pending</option> */}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter(shiftDataFilter)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddChangeShift
            appliedShift={changeShiftData}
            onClose={onClose}
            showForm={showForm}
            edit={editValue}
            changeShiftData={changeShiftData}
            onAddCallBack={onAddCallBack}
            editId={editId}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ChangeShiftIndex;
