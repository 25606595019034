import React, { useState, useEffect, useMemo } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";
import * as SkillQuarterService from "../../service/skillquaterly";
import * as SkillTabService from "../../service/skillTab";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const AddSkillTab = (props) => {
  const nameForm = "Skill Tab Access";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
  const [dataEmp, setDataEmp] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeError, setEmployeeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);
  const [isSkillExistMsg, setIsSkillExistMsg] = useState("");
  const [skillData, setSkillData] = useState({
    employee_id: "",
    all_access: false,
    skill_list_view: false,
    skill_list_update: false,
    skill_group_view: false,
    skill_group_update: false,
    quarterly_report_view: false,
    quarterly_report_update: false,
    skill_matrix_usage_view: false,
    skill_matrix_usage_update: false,
    user_group_mapping_view: false,
    user_group_mapping_update: false,
    group_owner_mapping_view: false,
    group_owner_mapping_update: false,
    skill_department_view: false,
    skill_department_update: false,
    skill_plant_view: false,
    skill_plant_update: false,
    skill_location_view: false,
    skill_location_update: false,
    skill_sub_group_view: false,
    skill_sub_group_update: false,
    report_view: false,
    report_update: false,
    is_active: true,
  });
  let condition = "false";

  const saveSkills = (values) => {
    let emp = dataEmp?.value;
    values.employee_id = emp;
    values.all_access = skillData.all_access;
    values.skill_list_view = skillData.skill_list_view;
    values.skill_list_update = skillData.skill_list_update;
    values.skill_group_view = skillData.skill_group_view;
    values.skill_group_update = skillData.skill_group_update;
    values.quarterly_report_view = skillData.quarterly_report_view;
    values.quarterly_report_update = skillData.quarterly_report_update;
    values.skill_matrix_usage_view = skillData.skill_matrix_usage_view;
    values.skill_matrix_usage_update = skillData.skill_matrix_usage_update;
    values.user_group_mapping_view = skillData.user_group_mapping_view;
    values.user_group_mapping_update = skillData.user_group_mapping_update;
    values.group_owner_mapping_view = skillData.group_owner_mapping_view;
    values.group_owner_mapping_update = skillData.group_owner_mapping_update;
    values.skill_department_view = skillData.skill_department_view
    values.skill_department_update = skillData.skill_department_update
    values.skill_plant_view = skillData.skill_plant_view
    values.skill_plant_update = skillData.skill_plant_update
    values.skill_location_view = skillData.skill_location_view
    values.skill_location_update = skillData.skill_location_update
    values.skill_sub_group_view = skillData.skill_sub_group_view
    values.skill_sub_group_update = skillData.skill_sub_group_update
    values.report_view = skillData.report_view
    values.report_update = skillData.report_update
    setIsLoading(true);
    SkillTabService.saveSkillTab(values)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkills = (values) => {
    let emp = dataEmp?.value;
    values.employee_id = emp;
    values.all_access = skillData.all_access;
    values.skill_list_view = skillData.skill_list_view;
    values.skill_list_update = skillData.skill_list_update;
    values.skill_group_view = skillData.skill_group_view;
    values.skill_group_update = skillData.skill_group_update;
    values.quarterly_report_view = skillData.quarterly_report_view;
    values.quarterly_report_update = skillData.quarterly_report_update;
    values.skill_matrix_usage_view = skillData.skill_matrix_usage_view;
    values.skill_matrix_usage_update = skillData.skill_matrix_usage_update;
    values.user_group_mapping_view = skillData.user_group_mapping_view;
    values.user_group_mapping_update = skillData.user_group_mapping_update;
    values.group_owner_mapping_view = skillData.group_owner_mapping_view;
    values.group_owner_mapping_update = skillData.group_owner_mapping_update;
    values.skill_department_view = skillData.skill_department_view
    values.skill_department_update = skillData.skill_department_update
    values.skill_plant_view = skillData.skill_plant_view
    values.skill_plant_update = skillData.skill_plant_update
    values.skill_location_view = skillData.skill_location_view
    values.skill_location_update = skillData.skill_location_update
    values.skill_sub_group_view = skillData.skill_sub_group_view
    values.skill_sub_group_update = skillData.skill_sub_group_update
    values.report_view = skillData.report_view
    values.report_update = skillData.report_update
    setIsLoading(true);
    SkillTabService.updateSkillTab(values, values.id)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    SkillQuarterService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(data1);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = (values) => {
    let emp = dataEmp?.value;
    let data = {};
    data["employee_id"] = emp;
    data["all_access"] = skillData.all_access;
    data["skill_list_view"] = skillData.skill_list_view;
    data["skill_list_update"] = skillData.skill_list_update;
    data["skill_group_view"] = skillData.skill_group_view;
    data["skill_group_update"] = skillData.skill_group_update;
    data["quarterly_report_view"] = skillData.quarterly_report_view;
    data["quarterly_report_update"] = skillData.quarterly_report_update;
    data["skill_matrix_usage_view"] = skillData.skill_matrix_usage_view;
    data["skill_matrix_usage_update"] = skillData.skill_matrix_usage_update;
    data["user_group_mapping_view"] = skillData.user_group_mapping_view;
    data["user_group_mapping_update"] = skillData.user_group_mapping_update;
    data["group_owner_mapping_view"] = skillData.group_owner_mapping_view;
    data["group_owner_mapping_update"] = skillData.group_owner_mapping_update;
    data["skill_department_view"] = skillData.skill_department_view;
    data["skill_department_update"] = skillData.skill_department_update;
    data["skill_plant_view"] = skillData.skill_plant_view;
    data["skill_plant_update"] = skillData.skill_plant_update;
    data["skill_location_view"] = skillData.skill_location_view
    data["skill_location_update"] = skillData.skill_location_update
    data["skill_sub_group_view"] = skillData.skill_sub_group_view
    data["skill_sub_group_update"] = skillData.skill_sub_group_update
    data["report_view"] = skillData.report_view
    data["report_update"] = skillData.report_update

    data["is_active"] = values.is_active;
    if (condition == "false") {
      condition = "true";

      SkillTabService.isDataExists(data)
        .then((res) => {
          setIsSkillExist(res?.data?.dataList?.is_exist);
          setIsSkillExistMsg(res?.data?.message);
          if (
            res?.data?.dataList?.is_exist === false &&
            values?.id &&
            !employeeError
          ) {
            updateSkills(values);
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            values?.id === undefined &&
            !employeeError
          ) {
            saveSkills(values);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = (values) => {
    let emp = dataEmp?.value;
    let error = false;
    if (edit) {
      if (editSkills?.employee_id !== emp) {
        error = true;
      }
      if (editSkills?.skill_list_view !== values.skill_list_view) {
        error = true;
      }
      if (editSkills?.skill_list_update !== values.skill_list_update) {
        error = true;
      }
      if (editSkills?.skill_group_view !== values.skill_group_view) {
        error = true;
      }
      if (editSkills?.skill_group_update !== values.skill_group_update) {
        error = true;
      }
      if (editSkills?.quarterly_report_view !== values.quarterly_report_view) {
        error = true;
      }
      if (
        editSkills?.quarterly_report_update !== values.quarterly_report_update
      ) {
        error = true;
      }
      if (
        editSkills?.skill_matrix_usage_view !== values.skill_matrix_usage_view
      ) {
        error = true;
      }
      if (
        editSkills?.skill_matrix_usage_update !==
        values.skill_matrix_usage_update
      ) {
        error = true;
      }
      if (
        editSkills?.user_group_mapping_view !== values.user_group_mapping_view
      ) {
        error = true;
      }
      if (
        editSkills?.user_group_mapping_update !==
        values.user_group_mapping_update
      ) {
        error = true;
      }
      if (
        editSkills?.group_owner_mapping_view !== values.group_owner_mapping_view
      ) {
        error = true;
      }
      if (
        editSkills?.group_owner_mapping_update !==
        values.group_owner_mapping_update
      ) {
        error = true;
      }
      if (
        editSkills?.skill_department_view !==
        values.skill_department_view
      ) {
        error = true;
      }
      if (
        editSkills?.skill_department_update !==
        values.skill_department_update
      ) {
        error = true;
      }
      if (
        editSkills?.skill_plant_view !==
        values.skill_plant_view
      ) {
        error = true;
      }
      if (
        editSkills?.skill_plant_update !==
        values.skill_plant_update
      ) {
        error = true;
      }
      if (editSkills?.is_active !== values.is_active) {
        error = true;
      }
    }
    if (error === false && values?.id && !employeeError) {
      updateSkills(values);
    }
    if (error === true && values?.id && !employeeError) {
      isDataExists(values);
    }
    if (error === false && values?.id === undefined && !employeeError) {
      isDataExists(values);
    }
  };

  const UserPush = (e) => {
    setDataEmp(e);
  };

  useEffect(() => {
    getEmployee(false);
  }, []);

  const submitHandler = () => {
    if (dataEmp.length === 0) {
      setEmployeeError(true);
    }
  };

  useEffect(() => {
    if (editSkills) {
      let dataEm = {
        value: editSkills?.employee_id,
        label: editSkills?.employee_name,
      };
      setDataEmp(dataEm);
      setSkillData(editSkills);
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills]);

  const checkAll = (e) => {
    if (e.target.checked === true) {
      Object.keys(skillData).forEach((item) => {
        if (item !== "is_active" && item !== "employee_id") {
          skillData[item] = true;
        }
      });
    } else {
      Object.keys(skillData).forEach((item) => {
        if (item !== "is_active" && item !== "employee_id") {
          skillData[item] = false;
        }
      });
    }
  };

  const unCheckedAccess = useMemo(() => {
    const temp = { ...skillData };
    delete temp.employee_id;
    delete temp.is_active;
    delete temp.all_access;
    return Object.values(temp).filter((e) => !e);
  }, [skillData]);

  return (
    <>
      <Formik
        initialValues={skillData}
        onSubmit={(values) => {
          AllCheck(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit === true ? "Edit" : "Add"} Skill Tab Access
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Employee Id<span className="text-danger">*</span>
                          </label>
                          <Select
                            name="employee_id"
                            id="employee_ids"
                            options={allEmployee}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              UserPush(e);
                              setEmployeeError(false);
                            }}
                            value={dataEmp}
                          />
                          {employeeError && (
                            <div className="small text-danger">
                              *Employee Id is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block mb-2">
                            Status<span className="text-danger">*</span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="Active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="Active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="logCheck d-inline-block">
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="perhead">
                      <h4>Tab Access</h4>
                      <p>Select Desired Module</p>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-bordered permission-box">
                        <tr>
                          <td width={300}>
                            <h4>Administrator Access</h4>
                          </td>
                          <td colspan="2">
                            <label className="logCheck d-inline-block">
                              Provide All Access
                              <input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={(e) => {
                                  checkAll(e);
                                  setSkillData({
                                    ...skillData,
                                    all_access: e.target.checked,
                                  });
                                }}
                                checked={
                                  skillData?.all_access &&
                                  unCheckedAccess.length === 0
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_list_view"
                                id="skill_list_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_list_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_list_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_list_update"
                                id="skill_list_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_list_update: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_list_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Group Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_group_view"
                                id="skill_group_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_group_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_group_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_group_update"
                                id="skill_group_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_group_update: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_group_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Quarterly Reports
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="quarterly_report_view"
                                id="quarterly_report_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    quarterly_report_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.quarterly_report_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="quarterly_report_update"
                                id="quarterly_report_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    quarterly_report_update: e.target.checked,
                                  });
                                }}
                                checked={skillData?.quarterly_report_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Matrix Usage
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_matrix_usage_view"
                                id="skill_matrix_usage_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_matrix_usage_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_matrix_usage_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          {/* <td>
                              <label className="logCheck d-inline-block">
                                {" "}
                                Update
                                <input
                                  type="checkbox"
                                  name="skill_matrix_usage_update"
                                  id="skill_matrix_usage_update"
                                  onChange={(e) => {
                                    setSkillData({
                                      ...skillData,
                                      skill_matrix_usage_update: e.target.checked,
                                    });
                                  }}
                                  checked={skillData?.skill_matrix_usage_update}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </td> */}
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              User Group Mapping
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="user_group_mapping_view"
                                id="user_group_mapping_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    user_group_mapping_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.user_group_mapping_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="user_group_mapping_update"
                                id="user_group_mapping_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    user_group_mapping_update: e.target.checked,
                                  });
                                }}
                                checked={skillData?.user_group_mapping_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Group Mapping
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="group_owner_mapping_view"
                                id="group_owner_mapping_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    group_owner_mapping_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.group_owner_mapping_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="group_owner_mapping_update"
                                id="group_owner_mapping_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    group_owner_mapping_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.group_owner_mapping_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Department Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_department_view"
                                id="skill_department_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_department_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_department_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_department_update"
                                id="skill_department_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_department_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_department_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Plant Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_plant_view"
                                id="skill_plant_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_plant_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_plant_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_plant_update"
                                id="skill_plant_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_plant_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_plant_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Skill Location Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_location_view"
                                id="skill_location_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_location_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_location_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_location_update"
                                id="skill_location_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_location_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_location_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Sub Group Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="skill_sub_group_view"
                                id="skill_sub_group_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_sub_group_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_sub_group_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="skill_sub_group_update"
                                id="skill_sub_group_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    skill_sub_group_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.skill_sub_group_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h5>
                              <i className="far fa-arrow-alt-circle-right"></i>{" "}
                              Report Master
                            </h5>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              View
                              <input
                                type="checkbox"
                                name="report_view"
                                id="report_view"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    report_view: e.target.checked,
                                  });
                                }}
                                checked={skillData?.report_view}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                          <td>
                            <label className="logCheck d-inline-block">
                              {" "}
                              Update
                              <input
                                type="checkbox"
                                name="report_update"
                                id="report_update"
                                onChange={(e) => {
                                  setSkillData({
                                    ...skillData,
                                    report_update:
                                      e.target.checked,
                                  });
                                }}
                                checked={skillData?.report_update}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              {isSkillExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;{isSkillExistMsg}
                </Alert>
              )}

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary-inner bpi-main"
                      type="submit"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSkillTab;
