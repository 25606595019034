import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getCompanyProduct = async ()=>{    
    return axios.get(`${AUTH_BASE_URL}/company-product/drop-down?is_molecule=true`);
}

export const getMarketPlayerDropdown = async () =>{    
    return axios.get(`${AUTH_BASE_URL}/market-player/drop-down`)
}

export const getCompetitiveMolecules = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/competitive-molecules/drop-down${query}`)
}

export const getCurrentFy = async () =>{    
    return axios.get(`${AUTH_BASE_URL}/current-fy`)
}

export const getLastCompititionMolecule = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/competing-molecule/last-competing-molecule${query}`)
}

export const postCompititionMolecule = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/competing-molecule`,body);
}

export const getCompetingMolecule = async () =>{    
    return axios.get(`${AUTH_BASE_URL}/competing-molecule`)
}

export const putCompititionMolecule = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/competing-molecule`,body);
}





export const getCompetitionMoleculesApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/competing-molecule/approver-competing-molecule-logs${query}`);
}


export const postCompetitionMoleculeAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/competing-molecule/action-competing-molecule`,body);
}