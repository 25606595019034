import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModularTable from "../ModularTable";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import styles from "./Table.module.css";
import {
  getExpensePlan,
  fetchGlData,
  getExpensePlanApprovalLogs,
  getLastExpensePlan,
  postExpensePlan,
  postExpensePlanAction,
  putExpensePlan,
  getExpensePlanLowerGrid,
} from "../../../../service/AgriPortalService/ExpensePlanService";

import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [updatedTableData, setUpdatedTableData] = useState([[]]);

  const [tableData2, setTableData2] = useState([]);
  const [tableHeadData2, setTableHeadData2] = useState([]);

  const [mode, setMode] = useState();
  const [locationData, setLocationData] = useState({});

  const [freezeCTA, setFreezeCTA] = useState(false);
  const [loader, setLoader] = useState(true);
  const [approvalList, setApprovalList] = useState([]);
  const [expenseLowerGrid, setExpenseLowerGrid] = useState([]);
  const [gl, setGl] = useState([]);

  useEffect(() => {
    getTableHead();
    getTableRow();
  }, []);

  const getTableHead = () => {
    const data = [
      {
        type: "T",
        isEditable: false,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
      {
        type: "N",
        isEditable: true,
      },
    ];
    setTableHeadData(data);
  };

  const getTableRow = () => {
    const data = [
      [
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
      ],
      [
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
      ],
      [
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
      ],
    ];
    data.push([
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: ["Total Quantity"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: ["Total price (INR)"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ]);
    data.push([
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: [],
        meta: {},
      },
      {
        value: ["Total price (USD)"],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ]);
    setTableData(data);
  };

  const onTableDataChange = (data) => {
    let quatity1 = 0;
    let quatity2 = 0;
    let quatity3 = 0;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    for (let i = 0; i < data.length - 2; i++) {
      quatity1 += parseInt(data[i][4].value[0]);
      quatity2 += parseInt(data[i][5].value[0]);
      quatity3 += parseInt(data[i][6].value[0]);
      total1 += parseInt(data[i][13].value[0]);
      total2 += parseInt(data[i][14].value[0]);
      total3 += parseInt(data[i][15].value[0]);
    }
    if (data[data.length - 2]) {
      data[data.length - 2][4].value[0] = quatity1;
      data[data.length - 2][5].value[0] = quatity2;
      data[data.length - 2][6].value[0] = quatity3;
      data[data.length - 2][13].value[0] = total1;
      data[data.length - 2][14].value[0] = total2;
      data[data.length - 2][15].value[0] = total3;
      data[data.length - 1][13].value[0] = total1 * 80;
      data[data.length - 1][14].value[0] = total2 * 80;
      data[data.length - 1][15].value[0] = total3 * 80;
    }
    setUpdatedTableData([...data]);
    console.log(data);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Rolling Plan
          </h4>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Plant Code</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Plant Name</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Business Unit</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Version</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Year</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>Month</b>
              </div>
              <div className={styles["valuebox"]}>XYZ</div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <div
              class="table-responsive"
              style={{ maxHeight: "200px", overflowY: "auto" }}
            >
              <table
                class="table table-striped table-bordered tablecured"
                style={{ maxHeight: "200px" }}
              >
                <thead style={{ position: "sticky", top: 0 }}>
                  <tr style={{ textAlign: "center" }}>
                    <th>Action At</th>
                    <th>Action By</th>
                    <th>Action User</th>
                    <th>Action Status</th>
                    <th>Action Remark</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {moment(new Date()).utc().format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td>123</td>
                    <td>XYZ</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={styles["ctas"]}>
          <div className={styles["ctas_left"]}>
            <div class="btn btn-success" onClick={() => {}}>
              Submit
            </div>
            <div class="btn btn-primary-inner" onClick={() => {}}>
              Save
            </div>
          </div>
          {/* {
                    mode == "approve" || approvalList?.for_approval ?
                        <div>
                            <div className="col-md-12">
                                <div className="form-group innergroup">
                                    <label>Add Comments <span className="text-danger">*</span></label>
                                    <textarea
                                        className="form-control"
                                        name="additional_comments"
                                        placeholder="Enter Comments here..."
                                        onChange={(e) =>
                                            setRemark(e.target.value)
                                        }
                                        value={remark}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 text-right pb-3">
                                <button className="btn btn-primary-inner bpi-main" type="submit"
                                    onClick={() => validate("approve")}
                                >Approve</button>
                                <button className="btn btn-outline-danger mr-3 ml-3" type="button"
                                    onClick={() => { onApprovalCancelHandler() }}
                                >Cancel</button>
                                <button className="btn btn-primary-inner bpi-main" type="submit"
                                    onClick={() => validate("reject")}
                                >Decline</button>
                            </div>
                        </div>
                        :
                        <div className={styles["ctas_right"]}>
                            <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                                Back
                            </div>
                        </div>
                } */}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"N",
//         isEditable:false,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2024-25",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     }
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
// ]
