import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getMarketPlayer = async () => {
    return axios.get(`${AUTH_BASE_URL}/market-player`);
};

export const getMarketPlayerDropdown = async () =>{
    return axios.get(`${AUTH_BASE_URL}/market-player/drop-down`)
}

export const getLastMarketPlayer = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/market-player/last-market_player${query}`)
}

export const postMarketPlayer = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-player`,body);
}

export const putMarketPlayer = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/market-player`,body);
}

export const getMarketPlayerApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/market-player/approver-market-player-logs${query}`)
}

export const postMarketPlayerAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-player/action-market-player`,body);
}
