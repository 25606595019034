import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getPlant = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/skill-plant-master?${queryParm}`);
};
export const postPlant = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-plant-master`,data);
};
export const updatePlant = async (data,id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-plant-master/${id}`,data);
};
export const deletePlant = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-plant-master/${id}`);
};
