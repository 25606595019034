import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getPolicy = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/policy?${queryParm}`);
};

export const savePolicy = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/policy`, data);
};

export const updatePolicy = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/policy/${id}`, data);
};

export const deletePolicy = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/policy/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getBranch = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?paginate=${paginate}`);
};
export const getPolicyCategory = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_category?paginate=${paginate}&sort_by=name`
  );
};

export const getPolicySubCategory = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy_subcategory?paginate=${paginate}&sort_by=name&policy_category_id=0`
  );
};
// export const getDepartment = async (paginate) => {
//     return axios.post(`${AUTH_BASE_URL}/department?paginate=${paginate}`);
// };
export const getDepartment = async (branch_id) => {
  return axios.post(`${AUTH_BASE_URL}/department/list?branch_id=${branch_id}`);
};
export const getSubDepartment = async (department_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/sub_department_router/list?department_id=${department_id}`
  );
};
export const getModule = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/modules?paginate=${paginate}`);
};

export const isPolicyExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/policy/exists`, isData);
};

export const getAllPolicy = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/policy?paginate=${paginate}&sort_by=title`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/policy/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const uploadFile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const changePolicyStatus = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/policy/action`,
    method: "POST",
    data: data,
  });
};
export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/gl_approver/gl_import_balancesheet`,
    method: "POST",
    responseType: "blob",
    data: data
  });
};
export const getBalancesheet = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/gl_approver/getBalancesheet`,
    method: "GET",
    responseType: "blob",
  });
};
export const save_CanteenData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/policy/save_canteen_menu`, data);
};
export const view_CanteenData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/policy/view_canteen_menu?${queryParm}`);
};
export const update_canteenData = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/policy/update_canteen_menu/${id}`, data);
};
