import React, { useState, useEffect } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import * as TravelListingAPI from "../../service/travelService";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";

function ViewOtherAdvance() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [userDataNew, setUserDataNew] = useState({});
    const [pageSizeNo, setPageSizeNo] = useState(10);

    const getAdvanceDetails = (page_no, page_size, paginate, obj_id) => {
        dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getAdvanceDetails(
            handleKey(page_no, page_size, paginate, obj_id)
        )
            .then((response) => {
                dispatch(setDisplayLoader("Hide"));
                setUserDataNew(response?.data?.dataList?.result[0]);
            })
            .catch((error) => {
                dispatch(setDisplayLoader("Hide"));
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };

    const handleKey = (page_no, page_size, paginate, obj_id) => {
        let queryParm =
            "page_no=" +
            page_no +
            "&page_size=" +
            page_size +
            "&paginate=" +
            paginate;

        if (obj_id !== "" && obj_id !== undefined && obj_id !== null) {
            queryParm = queryParm + "&obj_id=" + obj_id;
        }
        return queryParm;
    };

    useEffect(() => {
        let id = parseInt(location.search.slice(1));
        getAdvanceDetails(1, pageSizeNo, true, id);
    }, [])
    return (<>
        <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="policyhead">
                            Other Advance
                        </h3>
                    </div>
                </div>
                <div className="row viewformaftersubmit">
                    <div className="col-md-4">
                        <div className="form-group innergroup">
                            <label>Requester</label>
                            <p>{userDataNew?.created_by_id}</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group innergroup">
                            <label>Requested At</label>
                            <p>
                                {moment(userDataNew?.created_at).format("DD-MM-YYYY")}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group innergroup">
                            <label>Advance Amount</label>
                            <p>{userDataNew?.advance_amount}</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group innergroup">
                            <label>Settlement Date</label>
                            <p>
                                {moment(userDataNew?.advance_amount_date).format("DD-MM-YYYY")}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group innergroup">
                            <label>Reason</label>
                            <p>{userDataNew?.reason_advance_amount}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured travelintim">
                    <thead>
                        <tr>
                            <th> Date </th>
                            <th> Approved By </th>
                            <th>Status</th>
                            <th> Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userDataNew?.approval_list?.length > 0 ? userDataNew?.approval_list?.map(data => (data.status === "A" || data.status === 'R') ? (
                            <tr key={data.id}>
                                <td>
                                    {moment(data?.updated_at)
                                        .utc()
                                        .format("DD-MM-YYYY h:m:s")}
                                </td>
                                <td>
                                    {data?.full_name} ({data?.username})
                                </td>
                                <td>
                                    <div className={
                                        data?.status == "A"
                                            ? "badge badge-success"
                                            : data?.status == "P"
                                                ? "badge badge-secondary"
                                                : "badge badge-danger"
                                    }>
                                        {data?.status === "A"
                                            ? "Approved"
                                            : data?.status === "P"
                                                ? "Pending"
                                                : data?.status === "R"
                                                    ? "Rejected"
                                                    : data?.status === "D"
                                                        ? "Rejected"
                                                        : ""}
                                    </div>
                                </td>
                                <td>{data?.remark}</td>
                            </tr>
                        ) : <></>) : <tr><td colSpan={4} className="d-flex justify-content-center">No record found</td></tr>}
                    </tbody>
                </table>
            </div>
            <button
                className="btn btn-outline-secondary mt-3"
                type="button"
                onClick={() => window.history.go(-1)}
              >
                Back
              </button>
        </div>
    </>);
}
export default ViewOtherAdvance;