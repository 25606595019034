import React, { useState } from "react";
import {
  Modal,
  Dropdown
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import CustomBreadcrumb from "./CustomBreadcrumb";

function RefMat() {
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Reference Material' }
  ]

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Reference Material</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Assign New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Ref. ID</th>
                    <th>Type</th>
                    <th>Plant</th>
                    <th>Val. Class</th>
                    <th>QM. View</th>
                    <th>Val. Type Check</th>
                    <th>Val. Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>76000000</td>
                    <td>ZSRC</td>
                    <td>UD01</td>
                    <td>8700</td>
                    <td>No</td>
                    <td>No</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>76000000</td>
                    <td>ZSRC</td>
                    <td>UD01</td>
                    <td>8700</td>
                    <td>No</td>
                    <td>No</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>76000000</td>
                    <td>ZSRC</td>
                    <td>UD01</td>
                    <td>8700</td>
                    <td>No</td>
                    <td>No</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>76000000</td>
                    <td>ZSRC</td>
                    <td>UD01</td>
                    <td>8700</td>
                    <td>No</td>
                    <td>No</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>76000000</td>
                    <td>ZSRC</td>
                    <td>UD01</td>
                    <td>8700</td>
                    <td>No</td>
                    <td>No</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <CustomPagination />

          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Assign Reference Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Reference Material No.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Reference Material No."
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Material Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option value="ERSA">ERSA - SPARE PARTS</option>
                      <option value="GEN">GEN - GENERAL STORES</option>
                      <option value="POP">POP - Promotional Items</option>
                      <option value="ROH">ROH - RAW MATERIALS</option>
                      <option value="VERP">VERP - PACKAGING</option>
                      <option value="ZCRE">
                        ZCRE - EXTERNAL &amp; TEST COMPOUNDS
                      </option>
                      <option value="ZLAB">ZLAB - LAB MATERIALS</option>
                      <option value="ZR&amp;D">
                        ZR&amp;D - R&amp;D MATERIALS
                      </option>
                      <option value="ZSRC">ZSRC - CRE RESEARCH CHEMICAL</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Plant<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Valuation Class<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option value="8100">
                        8100 - Inventory RM- Domestic
                      </option>
                      <option value="8101">8101 - RM Imported- WO Duty</option>
                      <option value="8102">
                        8102 - RM Imported- Duty Paid
                      </option>
                      <option value="8200">
                        8200 - INVENTORY GENERAL STORES
                      </option>
                      <option value="8300">
                        8300 - INV. STORE/SPARE-ELECTRICAL
                      </option>
                      <option value="8301">
                        8301 - INV. STORE/SPARE-MECHANICAL
                      </option>
                      <option value="8302">
                        8302 - INV. STORE/SPARE-INSTRUMENT
                      </option>
                      <option value="8400">8400 - Inv Pkg. M- Domestic</option>
                      <option value="8401">
                        8401 - Pkg. M Imported- WO Duty
                      </option>
                      <option value="8402">
                        8402 - Pkg M Imported- Duty Paid
                      </option>
                      <option value="8600">
                        8600 - INVENTORY LAB MATERIAL
                      </option>
                      <option value="8700">
                        8700 - INV. R&amp;D MATERIAL-DOMESTIC
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Batch Management<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Batch Management"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Include Variation Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option selected>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Variation Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option selected>Damaged</option>
                      <option>Good</option>
                      <option>Repaired</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      QM View<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Yes
                      <input type="radio" name="statusupdate1" checked />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      No
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RefMat;
