import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as purchaseOrderService from "../../service/purchase-order";
import ViewPO from "./viewpo";
import * as moment from "moment";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function Transaction() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [purchaseData, setPurchaseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [purPageData, setPurPageData] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [myDate, setMydate] = useState("");

  const [dataView, setDataView] = useState([]);

  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getPurchaseOrder = (search, page_no, page_size, sort_by, paginate) => {
    // dispatch(setDisplayLoader("Display"));
    purchaseOrderService
      .getPurchaseOrder(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        setPurchaseData(response?.data?.dataList?.result);
        setPurPageData(response?.data?.dataList?.paginated_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getViewPurchaseOrder = (id) => {
    purchaseOrderService
      .getViewPurchaseOrder(id)
      .then((response) => {
        setDataView(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const acceptForm = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const onCallBack = () => {};

  useEffect(() => {
    getPurchaseOrder("", 1, 10, "-id", true);
  }, []);

  //  let myDated = new Date(myDate);
  //  console.log("myDated",myDate);
  // let date_2 = new Date();

  // const days = (myDated, date_2) =>{
  //     let difference = myDated.getTime() - date_2.getTime();
  //     let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  //     return TotalDays;
  // }
  // console.log(days(myDated, date_2) +" days to world cup");

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };

  return (
    <>
      {viewForm ? (
        <>
          <ViewPO
            dataView={dataView}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
            onCallBack={onCallBack}
          />
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-3">
              <ul className="policylistnames">
                <li class="active">
                  <Link to="/purchase-order">Purchase Order</Link>
                </li>
                <li>
                  <Link to="/search-po">Search Order</Link>
                </li>
                <li>
                  <Link to="/active-po">Active PO Status</Link>
                </li>
                <li>
                  <Link to="/approver-assignment">Approver Assignment</Link>
                </li>
                <li>
                  <Link to="/releasegroup">Release Group</Link>
                </li>
                <li>
                  <Link to="/releasecode">Release Code</Link>
                </li>
                <li>
                  <Link to="/releasestrat">Release Strategy</Link>
                </li>
                <li>
                  <Link to="/releasestrategy">Approval Mapping</Link>
                </li>
              </ul>
            </div>
            {/* ----------------------------------------------------------------------------- */}
            <div class="col-md-9">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <h4 class="inner-page-title">Purchase Order</h4>
                      {/* <Breadcrumb>
                                                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                                                <Breadcrumb.Item href="#">
                                                    Transactions
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>Purchase Order</Breadcrumb.Item>
                                            </Breadcrumb> */}
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <i class="fas fa-print"></i>Print
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <i class="far fa-file-alt"></i>CSV
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <i class="far fa-file-excel"></i>Excel
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <i class="far fa-file-pdf"></i>Pdf
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <i class="far fa-copy"></i>Copy
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button>  */}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs">
                  <Tabs defaultActiveKey="purchase" id="approvaltabs">
                    <Tab
                      eventKey="purchase"
                      title={
                        <React.Fragment>
                          Purchase Order
                          <span class="numberpurchse">
                            {purPageData?.totalRecords}
                          </span>
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>PO NO</th>
                              <th>Creation Date</th>
                              <th>Vendor Name</th>
                              <th>Total Price</th>
                              <th>Currency</th>
                              <th>Created by</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseData.length > 0
                              ? purchaseData?.map((data, index) => (
                                  <>
                                    <tr key={`po${index}`}>
                                      <td class="text-theme fw-bold">
                                        <Link
                                          to="/viewpurchase/:id"
                                          class="text-theme"
                                        >
                                          {data?.po_no}
                                        </Link>
                                      </td>
                                      <td>
                                        {" "}
                                        {moment(data?.created_at).format(
                                          "Do MMM YYYY"
                                        )}
                                      </td>
                                      <td>{data?.vendor_name}</td>
                                      <td> {changeData(data?.total_price)}</td>

                                      <td>{data?.currency}</td>
                                      <td>{data?.created_by_user}</td>
                                      <td>{data?.currency}</td>
                                      <td>
                                        <button
                                          className="btn btn-blanktd text-success"
                                          onClick={() => acceptForm(data?.id)}
                                        >
                                          <img src="images/eye.png" alt="" />
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : "No Record Found"}
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select class="form-control">
                              <option>10</option>
                              <option>20</option>
                              <option>30</option>
                              <option>50</option>
                              <option>100</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <Pagination>
                            <Pagination.Prev classNameName="previpag" />
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item active>{2}</Pagination.Item>
                            <Pagination.Ellipsis />
                            <Pagination.Item>{9}</Pagination.Item>
                            <Pagination.Item>{10}</Pagination.Item>
                            <Pagination.Next classNameName="previpag" />
                          </Pagination>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="approved"
                      title={<React.Fragment>Approved PO</React.Fragment>}
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>PO NO</th>
                              <th>Creation Date</th>
                              <th>Vendor Name</th>
                              <th>Total Price</th>
                              <th>Currency</th>
                              <th>Created by</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-theme fw-bold">
                                <Link to="/viewpurchase/:id" class="text-theme">
                                  5465797654
                                </Link>
                              </td>
                              <td>Nov 25, 2022</td>
                              <td>Aarti Industries Ltd</td>
                              <td>14,16,000.00</td>
                              <td>INR</td>
                              <td>Aniket Singh</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select class="form-control">
                              <option>10</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <Pagination>
                            <Pagination.Prev className="previpag" />
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Item active>{2}</Pagination.Item>
                            <Pagination.Ellipsis />
                            <Pagination.Item>{9}</Pagination.Item>
                            <Pagination.Item>{10}</Pagination.Item>
                            <Pagination.Next className="previpag" />
                          </Pagination>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            classNameName="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group innergroup">
                <label>
                  Select PO<span className="text-danger">*</span>
                </label>
                <Select
                  isMulti
                  options={options}
                  classNameName="basic-multi-select"
                  classNameNamePrefix="select"
                />
              </div>
              <div className="form-group innergroup">
                <label>
                  Select Vendor<span className="text-danger">*</span>
                </label>
                <Select
                  isMulti
                  options={options}
                  classNameName="basic-multi-select"
                  classNameNamePrefix="select"
                />
              </div>
              <div className="form-group innergroup">
                <label>
                  Created Date<span className="text-danger">*</span>
                </label>
                <input type="date" className="form-control" />
              </div>
              <div className="form-group innergroup">
                <label>
                  Select Status<span className="text-danger">*</span>
                </label>
                <select className="form-control newbgselect">
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Approved</option>
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-outline-danger" onClick={filterclose}>
                Cancel
              </button>
              <button className="btn btn-primary-inner bpi-main">Apply</button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default Transaction;
