import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";

function OtherAdvanceApproval(props) {
  const { backDateData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [travelList, setTravelList] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const userDetails = useSelector(selectUserData);

  const getBackDateList = async (data) => {
    await TravelListingAPI.getBackDateList(data)
      .then((response) => {
        setTravelList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserDetails = async () => {
    await TravelListingAPI.getUserDetails()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.username,
            label: `${obj?.first_name}`,
          };
        });
        setUserDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getBackDateList(userDetails?.id);
    getUserDetails();
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <div class="col-md-6" style={{ float: "left" }}>
                    {" "}
                    <h5 class="inner-page-title">Other Advance Approvals</h5>
                  </div>
                  <div
                    class="col-md-6"
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      float: "right",
                    }}
                  ></div>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured travelintim">
                <thead>
                  <tr>
                    <th>Requested ID</th>
                    <th> Requested On</th>
                    <th> Emp Code</th>
                    <th> Emp Name</th>
                    <th> Advance Reason</th>
                    <th> Advance Amount</th>
                    <th> Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {backDateData?.length ? (
                    backDateData?.map((data, index) => (
                      <tr>
                        <td>{data?.emp_id ?? "-"}</td>
                        <td> {data?.user_name ?? "-"}</td>
                        <td> {"-"}</td>
                        <td> {"-"}</td>
                        <td>
                          {moment(data?.opened_from).format("DD-MM-YYYY")}
                        </td>
                        <td>{moment(data?.from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(data?.to_date).format("DD-MM-YYYY")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 text-center">
                  <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                  <p class="fa-20 text-muted mb-4">
                    You are generating SAP Trip No, After this you will not be
                    able to change tour
                  </p>

                  <div class="mb-3">
                    <Link to="/claim-listing" class="mr-2">
                      <button class="btn btn-primary-inner bpi-main f-14 py-1">
                        Yes
                      </button>
                    </Link>
                    <button
                      class="btn btn-outline-primary f-14 py-1"
                      onClick={addnewclose}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OtherAdvanceApproval;
