import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";
export const getDepartment = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/designation?${queryParm}`);
};

export const getDesignation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/designation?${queryParm}`);
};
// export const getDepartment = async (
//   pageNo,
//   pageSize,
//   sortBy,
//   search,
//   paginate,
//   desigId,
//   busiId,
//   stats
// ) => {
//   return axios.get(
//     `${AUTH_BASE_URL}/designation?search=${search}${
//       desigId == undefined || desigId == "" || desigId == null
//         ? ""
//         : `&designation_id=${desigId}`
//     }${
//       busiId == undefined || busiId == "" || busiId == null
//         ? ""
//         : `&business_units_id=${busiId}`
//     }${
//       stats == undefined || stats == "" || stats == null
//         ? ""
//         : `&status=${stats}`
//     }&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
//   );
// };

export const saveDepartment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/designation`, data);
};

export const updateDepartment = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/designation/${id}`, data);
};

export const deleteDepartment = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/designation/${id}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/designation/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getAllBusinessUnit = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
  );
};

export const getAllDesignation = async (id) => {
  return axios.post(`${AUTH_BASE_URL}/designation/list?department_id=${id}`);
};
export const isDeptExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/designation/exists`, isData);
};
