import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { PdfIcon } from "./Icon";
import * as HigherEduService from "../../service/educationService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as moment from "moment";
import DataTable from "./DataTable";

function HigherEduClaimApproval() {
    const [allData, setAllData] = useState({});
    const [isApproval, setIsApproval] = useState(false);
    const [remark, setRemark] = useState('');

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reimbursementData, setReimbursementData] = useState([]);
    const [approvalData, setApprovalData] = useState({});
    const [projectDescription, setProjectDescription] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [requestedAmount, setRequestedAmount] = useState(0);
    const [previewDocModalFlag, setPreviewDocModalFlag] = useState({ flag: false, data: [] });

    // const [isReachMaxAmt, setIsReachMaxAmt] = useState({ flag: false, maxAmt: 0 });
    const claimData = location?.state;

    let abortAllData = null;
    let abortReimbursementData = null;

    const amountFormatter = number => new Intl.NumberFormat(allData?.currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: allData?.currency ? allData?.currency : 'INR' }).format(number)

    useEffect(() => {
        getAllData();
        setIsApproval(location?.state?.isApproval);
        // if(claimData.isPreview){
        //     let reimData = [];
        //     if(claimData?.reimData){
        //         claimData.reimData.forEach(obj => reimData.push({
        //             type: obj.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '),
        //             total_amt: obj.total_amount,
        //             requested_amt: obj.requested_amount,
        //             documents: [],
        //             documentPreview: obj.attachments,
        //             key: obj.id
        //         }))
        //         setProjectDescription(claimData.projectDesc);
        //         setTotalAmount(claimData.totalAmount);
        //         setRequestedAmount(claimData.requestedAmount);
        //         setReimbursementData(reimData);
        //     }else{
        //         getReimbursementData();
        //     }
        // }
        getReimbursementData();
    }, [])

    const getAllData = () => {
        abortAllData = new AbortController();
        dispatch(setDisplayLoader("Display"));
        HigherEduService.getApplicationRequestDetail(claimData?.id, abortAllData.signal)
            .then((response) => {
                abortAllData = null;
                dispatch(setDisplayLoader("Hide"));
                setAllData(response.data.dataList)
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    const getReimbursementData = () => {
        abortReimbursementData = new AbortController();
        dispatch(setDisplayLoader("Display"));
        HigherEduService.getReimbursementData(claimData.id, abortReimbursementData.signal)
            .then((response) => {
                let reimData = [];
                let data = response?.data?.dataList?.reimbursement_items.filter(obj => obj.id == claimData.claimId)[0];
                setProjectDescription(data.description);
                data.reimbursement_items.forEach(obj => {
                    reimData.push({
                        type: obj.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '),
                        total_amt: obj.total_amount,
                        requested_amt: obj.requested_amount,
                        documents: [],
                        documentPreview: obj.attachments,
                        key: obj.id
                    })
                })
                setTotalAmount(data.total_amount);
                setRequestedAmount(data.requested_amount);
                setReimbursementData(reimData)
                setApprovalData(data.approval_list)
                abortReimbursementData = null;
                dispatch(setDisplayLoader("Hide"));
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    const submitApproval = (status) => {
        if(remark == ''){
            toast.error('Remark is mandatory');
            return;
        }
        let id = 0;
        approvalData.forEach(obj => {if(obj.is_current) id = obj.id})
        const payload = {
            id_list: [id],
            status: status,
            remarks: remark
        }
        HigherEduService.postMultiReimbursementApprove(payload)
            .then((response) => {
                toast.success(`submitted`);
                navigate("/dashboard");
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    }

    const column = [
        {
            datafield: '',
            text: 'S.No.',
            formatter: (value, row, idx) => idx + 1
        },
        {
            datafield: 'approval_type',
            text: 'Approver Stage'
        },
        {
            datafield: '',
            text: 'Approver Name',
            formatter: (value, row) => `${row?.full_name} (${row?.username})`
        },
        {
            datafield: 'date',
            text: 'Approved Date'
        },
        {
            datafield: 'remarks',
            text: 'Remark'
        },
        {
            datafield: 'status',
            text: 'Status',
            formatter: (value) => <span
                className={
                    value.toLowerCase() == "approved"
                        ? "badge badge-success"
                        : value.toLowerCase() == "pending"
                            ? "badge badge-secondary"
                            : ""
                }
            >
                {value}
            </span>
        },
    ];
    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">Reimbursement for Higher Education</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Employee Name & Code<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control"> {`${allData?.user_full_name} (${allData?.username})`}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Designation/Grade<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.designation_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Department<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.department_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Location<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{`${allData?.personal_area_description} (${allData?.personal_area_name})`}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Unit<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.branch_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Date of Joining<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{moment.parseZone(allData?.date_of_joining).format("DD-MM-YYYY")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h5 className="text-theme border-bottom pb-2 mb-3">
                                        <strong>Course Info</strong>
                                    </h5>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Duration of the course (in Days)
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.course_duration}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Total Amount Sanctioned
                                            <span className="text-danger">*</span>
                                        </label>
                                        {/* <div className="form-control">{amountFormatter(allData?.sanctioned_amount ? allData?.sanctioned_amount : 0)}</div> */}
                                        <div className="form-control">{amountFormatter(allData?.sanctioned_amount)}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Claimed Amount
                                            <span className="text-danger">*</span>
                                        </label>
                                        {/* <div className="form-control">{amountFormatter(allData?.claimed_amount ? allData?.claimed_amount : 0)}</div> */}
                                        <div className="form-control">{amountFormatter(allData?.claimed_amount)}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Balance Amount
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{amountFormatter(allData?.balance_amount)}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Started Date
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.start_date}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Completion date
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.end_date}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group innergroup">
                                        <label>
                                            Project work completed (if any) (Enclose brief description of project work and separated pages for additional details of the project work)
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control" style={{minHeight: 100}}>{projectDescription}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-between mb-3 border-bottom">
                                    <h5 className="text-theme pb-2 mb-3">
                                        <strong>Reimbursements</strong>
                                    </h5>
                                </div>
                                <div className="table-responsive" style={{ overflow: 'visible' }}>
                                    <table className="table table-striped table-bordered tablecured">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Reimbursements</th>
                                                <th>Total Amount</th>
                                                <th>
                                                    Request Amount
                                                </th>
                                                <th>Documents</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reimbursementData.map((obj, index) =>
                                                <tr key={obj.key}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ overflow: 'visible' }}> {obj.type} </td>
                                                    <td>{amountFormatter(obj.total_amt)}</td>
                                                    <td>{amountFormatter(obj.requested_amt)}</td>
                                                    <td>
                                                        <div className="upload-btn-wrapper">
                                                            <button
                                                                className="btn btn-primary-inner"
                                                                style={{ padding: '5px 10px' }}
                                                                onClick={() => setPreviewDocModalFlag({ flag: true, data: obj.documentPreview })}
                                                            >
                                                                <i className="far fa-eye"></i>&nbsp;
                                                                <span style={{ fontSize: 14 }}>Preview</span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>{reimbursementData.length + 1}</td>
                                                <td>Total</td>
                                                <td>{amountFormatter(totalAmount)}</td>
                                                <td>{amountFormatter(requestedAmount)}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content-wrapper-inner content-wrapper-inner2">
                                        <DataTable column={column} list={approvalData} />
                                    </div>
                                </div>
                                {isApproval && (
                                    <div className="col-md-12 mt-3">
                                        <label>
                                            Remarks
                                            <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            placeholder="Enter remarks"
                                            type="text"
                                            onChange={(e) => setRemark(e.target.value)}
                                            value={remark}
                                            className="form-control"
                                        ></textarea>
                                    </div>
                                )}
                            </div>

                            <div className="row mt-3">
                                {isApproval ? (
                                    <div className="col-md-12 text-right pb-3">
                                        <div className="col-md-12 text-right pb-3">
                                            {/* <Link to="/common-approve" className="btn btn-outline-danger mr-3 ml-3">Cancel</Link> */}

                                            <button
                                                className="btn btn-primary-inner mr-3 ml-3"
                                                type="button"
                                                onClick={() => {
                                                    navigate(`/common-approve/${location?.state?.subId}`, {
                                                        state: {
                                                          subtypeid: location?.state?.subId,
                                                          subTypeName: location?.state?.type,
                                                        },
                                                      });
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-outline-danger bpi-main mr-3"
                                                type="button"
                                                onClick={() => submitApproval("REJECTED")}
                                            >
                                                Reject
                                            </button>
                                            <button
                                                className="btn btn-primary-inner bpi-main mr-3"
                                                type="button"
                                                onClick={() => submitApproval("APPROVED")}
                                            >
                                                Approve
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        className="btn btn-primary-inner mr-3 ml-3"
                                        type="button"
                                        onClick={() => {
                                            navigate("/higher-edu-listing");
                                        }}
                                    >
                                        Back
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={previewDocModalFlag.flag}
            onHide={() => setPreviewDocModalFlag({ flag: false, data: [] })}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <Modal.Header closeButton>
                <Modal.Title> Documents </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {previewDocModalFlag.data.length > 0 ? previewDocModalFlag.data.map(doc => <DocumentPreviewRow id={doc.id} url={doc.url} />) :
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                        <span style={{ color: '#bfbfbf' }} className="small">
                            <em>No document uploaded</em>
                        </span>
                    </div>}
            </Modal.Body>
        </Modal>
    </>);
}

function DocumentPreviewRow(props) {
    const urlStrArr = props.url.split('/');
    const type = urlStrArr[urlStrArr.length - 1].split('.')[1];
    return (
        <li key={props.id} className="row d-flex align-items-center mb-4">
            <div className="col-md-3">
                {type == 'pdf' ?
                    <div style={{ aspectRatio: '16/9' }} >
                        <PdfIcon width={95} height={65} />
                    </div> : <img src={props.url} style={{ aspectRatio: '16/9' }} />}
            </div>
            <div className="col-md-6">{urlStrArr[urlStrArr.length - 1]}</div>
            <div className="col-md-3 d-flex justify-content-center">
                <a href={props.url} target="_blank">
                    <i className="fas fa-eye"></i>
                </a>
            </div>
        </li>
    )
}

export default HigherEduClaimApproval