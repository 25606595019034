import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as PolicyCategoryService from "../../../service/policyCategory";

const validationSchema = Yup.object({
  name: Yup.string().required("*Policy Category Name is required"),
  organisation_id: Yup.string().required("*Select Organisation").nullable(),
  sequence: Yup.number().required("*Enter Sequence"),
});

const AddPolicyCategory = (props) => {
  const nameForm = "Policy Category Master";
  const { edit, showForm, onClose, editPolicyCategory, onAddCallBack } = props;
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allPolicyCategory, setAllPolicyCategory] = useState([]);
  const [isPolicyCategoryExist, setIsPolicyCategoryExist] = useState(false);
  const [policyCategoryData, setPolicyCategoryData] = useState({
    name: "",
    organisation_id: "",
    is_active: true,
    sequence: 0
  });

  const savePolicyCategory = (values) => {
    setIsLoading(true);
    PolicyCategoryService.savePolicyCategory(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updatePolicyCategory = (values) => {
    setIsLoading(true);
    PolicyCategoryService.updatePolicyCategory(values, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getOrganisation = (paginate) => {
    PolicyCategoryService.getOrganisation(paginate)
      .then((response) => {
        setAllPolicyCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isPolicyCategoryExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editPolicyCategory?.name === e.target.value) {
      setIsPolicyCategoryExist(false);
      return;
    } else {
      PolicyCategoryService.isPolicyCategoryExists(data)
        .then((res) => {
          console.log("res", res);
          if (e.target.name === "name") {
            setIsPolicyCategoryExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  // const isPolicyExists = (e, fieldName) => {
  //   let data = {};
  //   data[fieldName] = e.target.value;
  //   if (editPolicy?.name == e.target.value) {
  //     setIsPolicyExist(false);
  //     setIsPolicyExists(false);
  //     return;
  //   } else {
  //     PolicyService.isPolicyExists(data)
  //       .then((res) => {
  //         if (e.target.name == "title") {
  //           setIsPolicyExist(res?.data?.dataList?.is_exist);
  //         }
  //         if (e.target.name == "sub_title") {
  //           setIsPolicyExists(res?.data?.dataList?.is_exist);
  //         }
  //       })
  //       .catch((err) => console.log("Error=>", err));
  //   }
  // };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  useEffect(() => {
    console.log(editPolicyCategory, "editBrandingCategory");
    if (editPolicyCategory) {
      setPolicyCategoryData(editPolicyCategory);
      Object.keys(editPolicyCategory).forEach((item) => {
        policyCategoryData[item] = editPolicyCategory[item];
      });
    }
  }, [editPolicyCategory]);
  useEffect(() => {
    getOrganisation(false);
  }, []);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  return (
    <>
      <Formik
        initialValues={policyCategoryData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isPolicyCategoryExist !== true) {
            if (values.id) {
              updatePolicyCategory(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              savePolicyCategory(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Policy Category
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Policy Category Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter Policy Category Name"
                            maxLength={100}
                            onChange={handleChange}
                            onBlur={(e) => {
                              isPolicyCategoryExists(e, "name");
                              handleBlur(e);
                            }}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : isPolicyCategoryExist === true ? (
                            <div className="small text-danger">
                              *Policy Category Name already exists
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Organization
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            classnameprefix="select"
                            style={{ width: "334px" }}
                            name="organisation_id"
                            // options={options}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setPolicyCategoryData({
                                ...policyCategoryData,
                                organisation_id: e.target.value,
                              });
                            }}
                            value={values.organisation_id}
                          >
                            <option
                              value=""
                              label="Select Organisation"
                              hidden
                            />

                            {renderOrgOptions(allPolicyCategory)}
                          </select>
                          {touched.organisation_id && errors.organisation_id ? (
                            <div className="small text-danger">
                              {errors.organisation_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">Status</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Sequence
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            name="sequence"
                            className="form-control"
                            placeholder="Enter Sequence"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sequence}
                          />
                          {touched.sequence && errors.sequence ? (
                            <div className="small text-danger">
                              {errors.sequence}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddPolicyCategory;
