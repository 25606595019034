import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import ViewTravelAll from "./Domestic/ViewTravelAll";
import TravelIntimation from "./TravelIntimation";
import SapStatusModal from "./sapStatusModal";
import * as headerService from "../../service/header";
import * as userGl from "../../service/userGl";

function TravelIntimDetailreport() {
  const dispatch = useDispatch();

  const [viewFormData, setViewFormData] = useState(null);
  const [viewForm, setViewForm] = useState(false);
  const [viewAllForm, setViewAllForm] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [managerEditing, set_managerEditing] = useState(false);
  const [companyCode, setCompanyCode] = useState([]);

  const finalUser = useSelector(selectUserData);
  const [dynamicUserData, set_dynamicUserData] = useState(null);

  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [migrate, setMigrate] = useState(false);
  const [allGridData, setAllGridData] = useState([]);
  const [condition, setCondition] = useState("");

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [travelList, setTravelList] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    trip_from: "",
    trip_to: "",
    from_date: "",
    to_date: "",
    search_by: "",
    request_type: "",
    status: "",
    organisation_id: { value: undefined },
  });

  const getCompany = (paginate) => {
    userGl
      .getCompany(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataCompany = {
            value: x.id,
            label: `${x?.name}`,
          };
          data.push(dataCompany);
        });
        setCompanyCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onViewClose = () => {
    setViewForm(false);
    // setViewInternationalForm(false);
    setMigrate(false);
    setViewAllForm(false);
    // clearFilter();
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "userDetails?.id",
      "False"
    );
  };

  const onCloseForm = () => {
    setMigrate(false);
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "userDetails?.id",
      "False",
      "",
      "",
      "",
      "",
      "",
      "from_date"
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    // getTravelList(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
    getTravelList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.status,
      "",
      filterdata?.user_id?.value,
      filterdata?.request_type,
      filterdata?.from_date,
      filterdata?.to_date,
      "",
      "",
      filterdata?.organisation_id.value
    );
  };

  const mainDataFilter = () => {
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.status,
      "",

      filterdata?.user_id?.value,
      filterdata?.request_type,
      filterdata?.from_date,
      filterdata?.to_date,
      "",
      "",
      filterdata?.organisation_id.value
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getTravelList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
    setfilterData({
      user_id: { value: undefined },
      from_date: "",
      to_date: "",
      trip_from: "",
      trip_to: "",
      search_by: "",
      request_type: "",
      status: "",
      organisation_id: { value: undefined },
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "Select" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    };
  };


  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      getSearchApplicationRequestsWithDebounce(
        e.target.value,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        filterdata?.status,
        "",
        filterdata?.user_id?.value,
        filterdata?.request_type,
        filterdata?.from_date,
        filterdata?.to_date,
        "",
        "",
        filterdata?.organisation_id.value
      );

      setSearchStr(e.target.value);
    }
  };

  const onView = (data) => {
    setViewFormData(data);
    // if (data.request_type === "Domestic") {
    setViewAllForm(true);
    // }
    //  else if (data.request_type === "On Duty") {
    //   setViewForm(true);
    // } else if (data.request_type === "International") {
    //   setViewInternationalForm(true);
    // }
  };

  const onCallGrid = () => {
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "userDetails?.id",
      "False"
    );
  };

  const submit = (data) => {
    confirmAlert({
      title: "Confirm",
      message: `Do you want to change this?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            window.open(
              `/travel-intimat-report?isedit=${true}&id=${
                data["TI Reference ID"]
              }&tid=${data?.id}`
            );
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getTravelListFk = (travel_id) => {
    TravelListingAPI.getTravelListFk(travel_id)
      .then((response) => {
        if (response?.data?.dataList?.result.length == 0) {
          onCallGrid();
          setMigrate(false);
        }
        setMainData(response?.data?.dataList?.result[0].travel_detail_data);
        // dispatch(setDisplayLoader("Hide"));
        // setTravelList(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        // dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onCallBackTravel = (travel_id) => {
    getTravelListFk(travel_id);
  };

  const OnEditGrid = (data) => {
    if (data?.request_type == "International") {
      setCondition("International");
    } else if (data?.request_type == "Domestic") {
      setCondition("Domestic");
    }
    setMigrate(true);
    setAllData(data);
    set_managerEditing(true);
    setAllGridData(data?.travel_detail_data);
  };

  const getIntimUserDetails = (uid, sapnum) => {
    headerService.getUserDetails(uid).then((response) => {
      set_dynamicUserData(response?.data?.dataList);
    });
  };

  const [viewSapData, setViewSapData] = useState();
  const [confirmSapModal, setConfirmSapModal] = useState(false);

  const onViewData = (data) => {
    setViewSapData(data);
    setConfirmSapModal(true);
  };
  
  const onClose = () => {
    setConfirmSapModal(false);
  };

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let idquery = params.get("tid");
    let isedit = params.get("isedit");
    if (!isedit && idquery) {
      TravelListingAPI.getTravelList(`travel_id=${idquery}`)
        .then((response) => {
          // dispatch(setDisplayLoader("Hide"));
          // setTravelList(response?.data?.dataList?.result);
          // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
          // setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
          // dispatch(setMessage(response?.data?.dataList?.result));

          onView(response?.data?.dataList?.result[0]);
          set_managerEditing(true);
          getIntimUserDetails(
            response?.data?.dataList?.result[0]?.created_by_id,
            response?.data?.dataList?.result[0].sap_trip_number
          );
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else if (isedit) {
      TravelListingAPI.getTravelList(`travel_id=${idquery}`)
        .then((response) => {
          // dispatch(setDisplayLoader("Hide"));
          // setTravelList(response?.data?.dataList?.result);
          // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
          // setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
          // dispatch(setMessage(response?.data?.dataList?.result));

          OnEditGrid(response?.data?.dataList?.result[0]);
          getIntimUserDetails(
            response?.data?.dataList?.result[0]?.created_by_id,
            response?.data?.dataList?.result[0].sap_trip_number
          );
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }

    getUsersList();
    getCompany(false);

    finalUser.groups.every((data) => {
      if (data?.name.toLowerCase()?.includes("ssc manager") || data?.name?.includes("IT Admin") || data?.name?.includes("MA Payroll")) {
        userGroup._userGroup = data?.name.toLowerCase();
        return false;
      }
      return true;
    });

    // getTravelList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.status,
      "",
      filterdata?.user_id?.value,
      filterdata?.request_type,
      filterdata?.from_date,
      filterdata?.to_date,
      "",
      "",
      filterdata?.organisation_id.value
    );
  }, [pageSizeNo]);

  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    travel_id,
    user_id,
    request_type,
    from_date,
    to_date,
    from_city,
    to_city,
    org_id
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getintim_detailreport(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        travel_id,
        user_id,
        request_type,
        from_date,
        to_date,
        from_city,
        to_city,
        org_id
      )//+`&hr_id=`+(userGroup._userGroup?.includes("ssc")||userGroup._userGroup?.includes("ma payroll") || userGroup._userGroup?.includes("it admin")?'':finalUser?.id)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setTravelList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getSearchApplicationRequestsWithDebounce = debounce(
    getTravelList,
    2000
  );


  const getTravelListExport = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    travel_id,
    user_id,
    request_type,
    from_date,
    to_date,
    from_city,
    to_city,
    org_id
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getExportTravelDetail(
      `is_export=true` +
        `&search=` +
        search +
        `&sort_by=` +
        sort_by +
        (user_id ? `&user_id=` + user_id : ``) +
        `&from_date=` +
        from_date +
        `&to_date=` +
        to_date +
        `&organisation_id=` +
        (org_id ? org_id : ``)
        +`&hr_id=`+(userGroup._userGroup?.includes("ssc")?'':finalUser?.id)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Travel_intimation_Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    travel_id,
    user_id,
    request_type,
    from_date,
    to_date,
    from_city,
    to_city,
    organisation_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (from_city !== "" && from_city !== undefined && from_city !== null) {
      queryParm = queryParm + "&from_city=" + from_city;
    }
    if (to_city !== "" && to_city !== undefined && to_city !== null) {
      queryParm = queryParm + "&to_city=" + to_city;
    }
    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }

    return queryParm;
  };

  return (
    <>
      {viewAllForm ? (
        <ViewTravelAll
          key={JSON.stringify(dynamicUserData)}
          viewFormData={viewFormData}
          flagValue={3}
          // userDetails={userDetails}
          onViewClose={onViewClose}
          managerEditing={managerEditing}
          dynamicUserData={dynamicUserData}
        />
      ) : migrate ? (
        <>
          <TravelIntimation
            key={JSON.stringify(dynamicUserData)}
            condition={condition}
            setCondition={setCondition}
            allGridDatas={allGridData}
            allData={allData}
            onViewClose={onViewClose}
            migrate={migrate}
            onCallBackTravel={onCallBackTravel}
            onCloseForm={onCloseForm}
            mainData={mainData}
            managerEditing={managerEditing}
            dynamicUserData={dynamicUserData}
          />
        </>
      ) : (
        <>
          <div class="container-fluid">
            <div class="row">
              {/* <div class="col-md-3">
                                  <ul class="policylistnames">
                                      <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                      <li><Link to="/travel-expense">Check Expense</Link></li>
                                      <li><Link to="/travel-bills">All Bills</Link></li>
                                      <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                      <li><Link to="/travel-km-report">KM Report</Link></li>
                                      <li class="active"><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                      <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                      <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                      <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                      <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                      <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                  </ul>
                              </div> */}

              <div class="col-md-12">
                <div class="content-wrapper-inner content-wrapper-inner2">
                  <div class="innerheadsec">
                    <div className="row">
                      <div class="col-md-12">
                        <h4 class="inner-page-title">
                          Travel Intimation Detail Report
                        </h4>
                      </div>
                      <div class="col-md-12">
                        <div class="border-top mt-2 mt-md-4 mb-3"></div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-lg-4">
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              className="bs-tooltip-end tooltip"
                              id="overlay-example"
                            >
                              <div class="tooltip-inner text-left">
                                Search on <strong> Name, Employee ID,Trip No.</strong>
                              </div>
                            </Tooltip>
                          }
                        >
                          <div class="input-group table-search">
                            <span class="input-group-text">
                              <img src="images/search.png" alt="" />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search..."
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div class="col-lg-8">
                        <div class="inline-spacing btn-betweenspaing">
                          <Dropdown show>
                            <Dropdown.Toggle
                              variant="primary-inner dropdownbtn"
                              id="dropdown-basic"
                            >
                              <img
                                src="images/export.png"
                                alt=""
                                class="mr-2"
                              />{" "}
                              Export
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() =>
                                  getTravelListExport(
                                    searchStr,
                                    1,
                                    pageSizeNo,
                                    sortData.sort_by,
                                    false,
                                    filterdata?.status,
                                    "",
                                    filterdata?.user_id?.value,
                                    "",
                                    filterdata?.from_date,
                                    filterdata?.to_date,
                                    "",
                                    "",
                                    filterdata?.organisation_id.value
                                  )
                                }
                              >
                                <i class="far fa-file-excel"></i>Excel
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <button
                            onClick={filteropen}
                            class="btn btn-secondary-inner"
                          >
                            <img src="images/filter.png" alt="" class="mr-3" />
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>TI Reference ID</th>
                          <th>Employee ID</th>
                          <th>Name</th>
                          <th>RM</th>
                          <th>Vendor code</th>
                          <th>Grade</th>
                          <th>Zone</th>
                          <th>HQ</th>
                          <th>Type</th>
                          <th>SAP TRIP No</th>
                          {/* {userGroup._userGroup?.includes("ssc")||finalUser?.username=="100000"&& */}
                          <th>Saviour Status</th>
                          {/* {userGroup._userGroup?.includes("ssc")||finalUser?.username=="100000"&&<th>Saviour Status</th>}                           */}
                          <th>From Date</th>
                          <th>From Location</th>
                          <th>To Date</th>
                          <th>To Location</th>
                          <th>Places Visited</th>
                          <th>TI Applied On</th>
                          <th>TI Approved On</th>
                          <th>Status</th>
                          <th>TI Approved By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {travelList?.length ? (
                          travelList?.map((data, index) => (
                            <tr>
                              <td>
                                {pageSizeNo * (currentPage - 1) + index + 1}
                              </td>
                              <td class="fw-bold">
                                {userGroup._userGroup == "ssc manager" &&
                                  (data?.travel__request_type?.toLowerCase() ==
                                    "domestic" ||
                                    data?.travel__request_type?.toLowerCase() ==
                                      "international") &&
                                  (data?.travel__is_edit ||
                                  
                                    data?.travel__expense_approval_status == "R" ? (
                                    <button
                                      className="btn btn-blanktd text-primary mr-1"
                                      onClick={() => {
                                        submit(data);
                                      }}
                                    >
                                      <i className="far fa-edit"></i>
                                    </button>
                                  ) : // <Link
                                  //   target="_blank"
                                  //   to={`/travel-intimat-report?isedit=${true}&id=${
                                  //     data["TI Reference ID"]
                                  //   }&tid=${data?.travel__id}`}
                                  //   class="text-theme"
                                  //   // onClick={()=>{
                                  //   //   onView(data)
                                  //   // }}
                                  // >
                                  //   <i
                                  //     className="far fa-edit"
                                  //     style={{ color: "#034694" }}
                                  //   ></i>
                                  // </Link>
                                  !data?.travel__claim_flag ? (
                                    !data?.travel__sap_trip_number ? (
                                      data?.travel__is_exception ? (
                                        ""
                                      ) : (
                                        ``
                                      )
                                    ) : (
                                      <button
                                        className="btn btn-blanktd text-primary mr-1"
                                        onClick={() => {
                                          submit(data);
                                        }}
                                      >
                                        <i className="far fa-edit"></i>
                                      </button>
                                      // <Link
                                      //   target="_blank"
                                      //   to={`/travel-intimat-report?isedit=${true}&id=${
                                      //     data["TI Reference ID"]
                                      //   }&tid=${data?.travel__id}`}
                                      //   class="text-theme"
                                      //   // onClick={()=>{
                                      //   //   onView(data)
                                      //   // }}
                                      // >
                                      //   <i
                                      //     className="far fa-edit"
                                      //     style={{ color: "#034694" }}
                                      //   ></i>
                                      // </Link>
                                    )
                                  ) : (
                                    ""
                                  ))}
                                <Tooltip title={data?.travel__travel_intimation_number}>
                                  <Link
                                    target="_blank"
                                    to={`/travel-intimat-report?id=${data?.travel__travel_intimation_number}&tid=${data?.travel__id}`}
                                    class="text-theme"
                                    // onClick={()=>{
                                    //   onView(data)
                                    // }}
                                  >
                                    {data?.travel__travel_intimation_number}
                                  </Link>{" "}
                                </Tooltip>
                              </td>
                              <td>{data?.user_code}</td>
                              <td>{data?.username}</td>
                              <td>
                                {data?.reporting_user_name
                                  ? data?.reporting_user_name
                                  : "-"}
                              </td>
                              <td>
                                {data?.user_vendor_code
                                  ? "0000" + data?.user_vendor_code
                                  : ""}
                              </td>
                              <td>{data?.Grade ?? "-"}</td>
                              <td>{data?.travel__sales_dist ?? "-"}</td>
                              <td>{data?.HQ ?? "-"}</td>
                              <td>{data?.travel__request_type ?? "-"}</td>
                              <td>
                                {/* <Link onClick={() => onView(data)}> */}
                                <Link
                                  to={`/travel-view-list?${data.travel_id}`}
                                  target="_blank"
                                >
                                  {data?.travel__sap_trip_number ?? "-"}
                                </Link>
                                {/* </Link> */}
                              </td>
                              {/* {userGroup._userGroup?.includes("ssc")||finalUser?.username=="100000"&& */}
                              <td
                              className="text-center">
                                <button
                                  class="btn btn-blanktd text-primary"
                                  type="button"
                                  onClick={() => onViewData(data)}
                                >
                                  <img src="images/eye.png" alt="" />
                                </button>
                              </td>
                              
                              <td>
                                {data?.from_date === null ||
                                data?.from_date === undefined
                                  ? ""
                                  : moment(data?.from_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>{data?.from_city_name ?? "-"}</td>
                              <td>
                                {data?.to_date === null ||
                                data?.to_date === undefined
                                  ? ""
                                  : moment(data?.to_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>{data?.to_city_name ?? "-"}</td>
                              <td>{data?.additional_information ?? "-"}</td>
                              <td>
                                {data?.travel__created_at === null ||
                                data?.travel__created_at === undefined
                                  ? ""
                                  : moment(data?.travel__created_at).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>
                                {data?.travel__approval_date === null
                                  ? "-"
                                  : moment(data?.travel__approval_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              </td>
                              <td>
                                {/* {data?.travel__current_status} */}
                                {data.current_status ? (
                                  ((data.current_status=="Discarded"||data.current_status=="Correction Required")?<span class="badge bad-status badge-warning">
                                  {data.current_status}
                                  </span>:<span class="badge bad-status badge-success">
                                    {data.current_status}
                                  </span>)
                                ) : data?.travel__status == "C" ? (
                                  <span class="badge bad-status badge-danger">
                                    Cancelled
                                  </span>
                                ) : data?.travel__status == "R" ? (
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                ) : data?.travel__status == "P" ? (
                                  "TI Pending At " +
                                  data?.travel__current_approver_data
                                    ?.current_approver_name +
                                  `(${data?.travel__current_approver_data?.travel__current_approver_code})`
                                ) : data?.travel__status == "D" ? (
                                  <span class="badge bad-status badge-danger">
                                    {" "}
                                    Pending for Submission{" "}
                                  </span>
                                ) : data?.travel__is_edit ? (
                                  <span class="badge bad-status badge-warning">
                                    Correction Required
                                  </span>
                                ) : !data?.travel__claim_flag ? (
                                  !data?.travel__sap_trip_number ? (
                                    // (data?.travel__is_exception?
                                    //   "":
                                    //   `TI Approved`
                                    // )
                                    <span class="badge bad-status badge-success">
                                      {" "}
                                      TI Approved{" "}
                                    </span>
                                  ) : (
                                    <span class="badge bad-status badge-success">
                                      {" "}
                                      Expenses Saved
                                    </span>
                                  )
                                ) : !data?.travel__expense_approval_status ? (
                                  // data?.travel__expense_exception ?
                                  // (
                                  //   `${data.current_expense_exception_approver_data?.travel__current_approver_name} (${data.current_expense_exception_approver_data?.travel__current_approver_code})`
                                  // )
                                  // :""
                                  `Expense Pending At ${data.expense_approver_data?.travel__current_approver_name} (${data.expense_approver_data?.travel__current_approver_code})`
                                ) : (
                                  data?.travel__expense_approval_status == "A" &&
                                  (!data?.travel__is_picked ? (
                                    <Tooltip title="Document sent but not received by SSC">
                                      Document sent but not received by SSC
                                    </Tooltip>
                                  ) : data?.travel__is_picked &&
                                    !data?.travel__ssc_user_status ? (
                                    `SSC Executive`
                                  ) : data?.travel__is_picked &&
                                    data?.travel__ssc_user_status &&
                                    !data?.travel__ssc_manager_status ? (
                                    `SSC Manager `
                                  ) : data?.travel__is_picked &&
                                    data?.travel__ssc_user_status &&
                                    data?.travel__ssc_manager_status == "F" &&
                                    !data?.travel__ssc_head_status ? (
                                    `SSC Head `
                                  ) : data?.travel__is_picked &&
                                    data?.travel__ssc_user_status &&
                                    (data?.travel__ssc_manager_status == "A" ||
                                      data?.travel__ssc_head_status == "A") ? (
                                    <span class="badge bad-status badge-success">
                                      Approved
                                    </span>
                                  ) : data?.travel__expense_approval_status == "R" ? (
                                    <Badge pill bg="danger">
                                      {" "}
                                      Rejected
                                    </Badge>
                                  ) : (
                                    "Pending..."
                                  ))
                                )}
                                {/* {data?.travel__current_status == "A" ? (
                                                      <span class="badge bad-status badge-success">
                                                      Approved{" "}
                                                      </span>
                                                  ) : data?.travel__status == "P" ||
                                                      data?.travel__status == null ||
                                                      data?.travel__status == "pending" ? (
                                                      <span class="badge bad-status badge-warning">
                                                      Pending{" "}
                                                      </span>
                                                  ) : data?.travel__status == "R" ? (
                                                      <span class="badge bad-status badge-danger">
                                                      Reject{" "}
                                                      </span>
                                                  ) : data?.travel__status == "draft" ? (
                                                      <span class="badge bad-status badge-danger">
                                                      Draft{" "}
                                                      </span>
                                                  ) : data?.travel__status == "Cancelled" ? (
                                                      <span class="badge bad-status badge-danger">
                                                      Cancelled{" "}
                                                      </span>
                                                  ) : (
                                                      ""
                                                  )} */}
                              </td>
                              <td>
                                {data?.travel__approved_by_username === " " ||
                                data?.travel__approved_by_username === ""
                                  ? "-"
                                  : data?.travel__approved_by_username}
                              </td>

                              
                            
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={17} className="text-center">
                              {" "}
                              No Records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value="10" selected={pageSizeNo==10}>10</option>
                      <option value="20" selected={pageSizeNo==20}>20</option>
                      <option value="30" selected={pageSizeNo==30}>30</option>
                      <option value="50" selected={pageSizeNo==50}>50</option>
                      <option value="100" selected={pageSizeNo==100}>100</option>
                      <option value="200" selected={pageSizeNo==200}>200</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        key={pageSizeNo}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        disableInitialCallback={true}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {confirmSapModal && (
        <SapStatusModal
          viewSapData={viewSapData}
          confirmSapModal={confirmSapModal}
          onClose={onClose}
        />
      )}

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group innergroup">
                <label>
                  Company Code<span className="text-danger">*</span>
                </label>
                <Select
                  name="organisation_id"
                  options={companyCode}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e, action) => {
                    setfilterData({
                      ...filterdata,
                      organisation_id: e,
                    });
                    // setCompanyCodeData(e);
                  }}
                  value={filterdata?.organisation_id}
                />
              </div>

              <div class="form-group innergroup">
                <label>
                  Employee ID <span class="text-danger">*</span>
                </label>
                <Select
                  options={usersDropdown}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) =>
                    setfilterData({
                      ...filterdata,
                      user_id: e,
                    })
                  }
                  value={filterdata?.user_id}
                />
              </div>
              <div class="form-group innergroup">
                <label>
                  Travel Type <span class="text-danger">*</span>
                </label>
                <select
                  name="request_type"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setfilterData({
                      ...filterdata,
                      request_type: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterdata.request_type}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {/* <option value="">All</option> */}
                  <option value="On Duty">On Duty</option>
                  <option value="Domestic">Domestic</option>
                  <option value="International">International</option>
                </select>
              </div>

              <div class="form-group innergroup">
                <label>Status</label>
                <select
                  name="status"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setfilterData({
                      ...filterdata,
                      status: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterdata.status}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="R">Rejected</option>
                  <option value="C">Cancelled</option>
                </select>
              </div>

              <div class="form-group innergroup">
                <label>
                  Start Date <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  onChange={(e) =>
                    setfilterData({
                      ...filterdata,
                      from_date: e.currentTarget.value,
                    })
                  }
                  value={filterdata.from_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>
                  End Date <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  onChange={(e) =>
                    setfilterData({
                      ...filterdata,
                      to_date: e.currentTarget.value,
                    })
                  }
                  value={filterdata.to_date}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => clearfilter()}
                //  onClick={filterclose}
              >
                Clear
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default TravelIntimDetailreport;
