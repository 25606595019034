import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillGroupService from "../../service/skillGroup";
import * as SkillService from "../../service/skill";
const validationSchema = Yup.object({
    group_id: Yup.string()
        .required("*Sub Group Code is required")
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field cannot contain only blank spaces"
        ),
    group_name: Yup.string()
        .required("*Group Name is required")
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field cannot contain only blank spaces"
        ),
});
const AddSubGroup = (props) => {
    const nameForm = "Sub Group Master";
    const dispatch = useDispatch();
    const { edit, showForm, onClose, editSkills, onAddCallBack, masterGroup, masterGroupOption } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [dataLocation, setDataLocation] = useState([]);
    const [locationError, setLocationError] = useState(false);
    const [parentGroupError, setParentGroupError] = useState(false);
    const [parentGroup, setParentGroup] = useState({});
    const [isSkillExist, setIsSkillExist] = useState(false);
    const [skillGroupData, setSkillGroupData] = useState({
        group_id: "",
        group_name: "",
        group_dept: "",
        is_active: true,
        parent_group: {
            id: "",
            group_name: ""
        }
    });
    const saveSubGroup = (values) => {
        setIsLoading(true);
        SkillGroupService.isSubGroupExists(values).then((res) => {
            setIsSkillExist(res?.data?.dataList?.is_exist);
            if (!res?.data?.dataList?.is_exist) {
                SkillGroupService.createSkillSubGroup(values)
                    .then((response) => {
                        if (response.data.status === true && response.data.code === 200) {
                            onAddCallBack(response);
                            setIsLoading(false);
                            toast.success(response.data.message);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.error(error.response.data.message);
                        console.log(
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            "failed"
                        );
                    });
            } else {
                setIsLoading(false)
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.message);
            console.log(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                "failed"
            );
        });
    };
    const updateSkills = (values) => {
        let data = { ...values }
        delete data.id
        delete data.is_active
        console.log(data, values)
        setIsLoading(true);
        SkillGroupService.updateSkillSubGroup(values, values.id)
            .then((response) => {
                if (response.data.status === true && response.data.code === 200) {
                    onAddCallBack(response);
                    setIsLoading(false);
                    toast.success(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error.response)
                setIsLoading(false);
                toast.error(error.response.data.message);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };
    const DataPush = (event) => {
        let findIndex = masterGroup.findIndex(el => el.id == event.value)
        let tempData = { ...skillGroupData }
        tempData.group_dept = masterGroup[findIndex].group_dept
        tempData.parent_group.id = masterGroup[findIndex].id
        tempData.parent_group.group_name = masterGroup[findIndex].group_name
        console.log("group_dept", tempData)
        setSkillGroupData(tempData)
        setParentGroup(event)
    };
    const locationPush = (e) => {
        setDataLocation(e);
    };
    useEffect(() => {
        if (editSkills) {
            let locationData = editSkills
            setDataLocation(locationData);
            let findIndex = masterGroup.findIndex(el => el.id == editSkills.parent_group.id)
            setParentGroup({
                value: editSkills.parent_group.id,
                label: `${masterGroup[findIndex].group_id}-${masterGroup[findIndex].group_name}`
            })
            setSkillGroupData(editSkills);
            Object.keys(editSkills).forEach((item) => {
                skillGroupData[item] = editSkills[item];
            });
        }
    }, [editSkills]);
    const submitHandler = () => {
        if (!parentGroup.value) {
            setParentGroupError(true)
        }
    };
    return (
        <>
            <Formik
                initialValues={skillGroupData}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    console.log(parentGroup)
                    if (!parentGroupError) {
                        if (values.id) {
                            updateSkills(values);
                        } else {
                            saveSubGroup(values);
                        }
                    }
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Modal
                        show={showForm}
                        onHide={onClose}
                        size="lg"
                        backdrop="static"
                        keyboard={false}
                        className="modaldefaultclose modaldefaultclosecenter"
                    >
                        <ToastContainer />
                        <Modal.Header closeButton>
                            <Modal.Title> {edit === true ? "Edit" : "Add"} Sub Group</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <div className="row justify-content-center">
                                    <div className="col-md-11">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                        Sub Group Code
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="group_id"
                                                        className="form-control"
                                                        placeholder="Enter Sub Group Code"
                                                        maxLength={30}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.group_id}
                                                    />
                                                    {touched.group_id && errors.group_id ? (
                                                        <div className="small text-danger">
                                                            {errors.group_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                        Sub Group Name
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="group_name"
                                                        className="form-control"
                                                        placeholder="Enter Sub Group Name"
                                                        maxLength={400}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.group_name}
                                                    />
                                                    {touched.group_name && errors.group_name ? (
                                                        <div className="small text-danger">
                                                            {errors.group_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label>
                                                        Parent Group
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        name="parent_group"
                                                        id="parent_group"
                                                        options={masterGroupOption}
                                                        className="basic-select"
                                                        classNamePrefix="select"
                                                        onChange={(e) => {
                                                            DataPush(e, "group");
                                                            setParentGroupError(false);
                                                        }}
                                                        value={parentGroup}
                                                    />
                                                    {
                                                        parentGroupError ?
                                                            <div className="small text-danger">
                                                                *Parent Group is required
                                                            </div> :
                                                            <></>
                                                    }
                                                    {/* <input
                                                        type="text"
                                                        name="description"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        maxLength={400}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.description}
                                                    /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group innergroup">
                                                    <label className="d-block">
                                                        Status <span className="text-danger"></span>
                                                    </label>
                                                    <label
                                                        className="logCheck d-inline-block mr-4"
                                                        htmlFor="active"
                                                    >
                                                        Active
                                                        <input
                                                            type="radio"
                                                            name="is_active"
                                                            id="active"
                                                            value={values.is_active}
                                                            onChange={() => {
                                                                setFieldValue("is_active", true);
                                                            }}
                                                            checked={values.is_active === true}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label
                                                        className="logCheck d-inline-block"
                                                        htmlFor="Inactive"
                                                    >
                                                        Inactive
                                                        <input
                                                            type="radio"
                                                            name="is_active"
                                                            id="Inactive"
                                                            value={values.is_active}
                                                            onChange={(e) => {
                                                                setFieldValue("is_active", false);
                                                            }}
                                                            checked={values.is_active === false}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            {isSkillExist && (
                                <Alert variant="danger" className="mb-5 mt-2 text-center">
                                    <i className="fa fa-exclamation-triangle"></i>
                                    &nbsp;This Entry Already Exists.
                                </Alert>
                            )}
                            <Modal.Footer>
                                {isLoading ? (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        as="span"
                                        role="status"
                                        aria-hidden="true"
                                    ></Spinner>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary-inner bpi-main"
                                            onClick={() => submitHandler()}
                                        >
                                            Submit
                                        </button>
                                    </>
                                )}
                            </Modal.Footer>
                        </form>
                    </Modal>
                )}
            </Formik>
        </>
    );
};
export default AddSubGroup;