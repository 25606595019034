import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as HigherEduService from "../../service/educationService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import DataTable from "./DataTable";

function HigherEduClaim(props) {
    const [reimbursementData, setReimbursementData] = useState({});

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const claimData = location?.state;

    let abortReimbursementData = null;

    const amountFormatter = number => new Intl.NumberFormat(claimData.currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: claimData.currency }).format(number)
    const nextApproval = (value, row) => {
        let obj = value.filter(obj => obj.is_current)[0]
        const full_name = `${obj.full_name} (${obj.username})`
        if(row.status == 'PENDING') return <span style={{whiteSpace: full_name.length > 25 ? 'wrap' : 'nowrap'}}>{full_name}</span>
    }
    const column = [
        {
            datafield: 'reimbursement_items',
            text: 'Action',
            formatter: (value, row) =>  <div className="d-flex justify-content-center">
                <i className="fas fa-eye" onClick={() => navigate("/higher-edu-add-claim", {
                    state: {
                        id: claimData.id,
                        currency: claimData.currency,
                        projectDesc: row.description,
                        reimData: row.reimbursement_items,
                        totalAmount: row.total_amount,
                        requestedAmount: row.requested_amount,
                        isPreview: true
                    }}) } style={{ color: '#0195d4', cursor: 'pointer' }}></i>
                </div>
        },
        {
            datafield: 'status',
            text: 'Status',
            formatter: value => <span className={"badge bad-status badge-" + (value == 'PENDING' ? 'warning' : value == 'REJECTED' ? 'danger' : 'success')}>{value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase()}</span>
        },
        {
            datafield: 'id',
            text: 'ID',
        },
        {
            datafield: 'total_amount',
            text: 'Total Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'requested_amount',
            text: 'Requested Amount',
            formatter: value => amountFormatter(value)
        },
        {
            datafield: 'description',
            text: 'Description',
            formatter: value => <div title={value}>{value.length > 25 ? value.substring(0, 25) + '...' : value}</div>
        },
        {
            datafield: 'created_at',
            text: 'Created at',
            formatter: value => moment(value).format("DD-MM-YYYY")
        },
        {
            datafield: 'approval_list',
            text: 'Pending at',
            formatter: (value, row) => nextApproval(value, row)
        },
        {
            datafield: 'sap_no',
            text: 'SAP Document No.'
        },
    ];

    // const debounce = (func, delay) => {
    //     let timeout = 0;
    //     return (...args) => {
    //         timeout = setTimeout(() => func(...args), delay);
    //         clearTimeout(timeout - 1);
    //     }
    // }

    const getReimbursementData = () => {
        abortReimbursementData = new AbortController();
        dispatch(setDisplayLoader("Display"));
        HigherEduService.getReimbursementData(claimData.id, abortReimbursementData.signal)
            .then((response) => {
                abortReimbursementData = null;
                dispatch(setDisplayLoader("Hide"));
                setReimbursementData(response.data.dataList)
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    useEffect(() => {
        getReimbursementData();

        return () => {
            if (abortReimbursementData) abortReimbursementData.abort();
        }
    }, [])

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">{claimData?.course}</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="input-group table-search">
                                        <span className="input-group-text">
                                            <img src="images/search.png" alt="" />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                        // onChange={(e) => {
                                        //     handleSearch(e);
                                        // }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="inline-spacing btn-betweenspaing">
                                        <button
                                            className="btn btn-primary-inner"
                                            onClick={() => {
                                                navigate("/higher-edu-add-claim", {
                                                    state: {
                                                        id: claimData.id,
                                                        currency: claimData.currency,
                                                        isPreview: false
                                                    }
                                                });
                                            }}
                                        >
                                            <img
                                                src="images/upload.png"
                                                alt=""
                                                className="mr-3"
                                            />
                                            Add New
                                        </button>
                                        <button
                                            // onClick={filteropen}
                                            className="btn btn-secondary-inner"
                                        >
                                            <img src="images/filter.png" alt="" className="mr-3" />
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            column={column}
                            list={reimbursementData?.reimbursement_items}
                            // option={{
                            //   pagination: {
                            //     show: true,
                            //     // currentPaginatedData: paginatedData,
                            //     // dataCount: 300,
                            //     // getNextData: getMyListing,
                            //     // payload: [sortData.sort_by, "", true, currentTab],
                            //   }
                            // }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default HigherEduClaim