import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getCropPlan = async () => {
    return axios.get(`${AUTH_BASE_URL}/crop-plan`);
};

export const postCropPlan = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-plan`,body);
}
export const putCropPlan = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/crop-plan`,body);
}

export const fetchCropPlan = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-plan/territory-crops-plan${query}`);
}
export const getCropPlanApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-plan/approver-crop-plan-logs${query}`);
}

export const postCropPlanAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/crop-plan/action-crop-plan`,body);
}

export const getCropPlanDropdown = async (code)=>{
    return axios.get(`${AUTH_BASE_URL}/crop-plan/crop/drop-down?territory_code=${code}`);
}