import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const TravelSapNumber = async (travel_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_sap_trip_no?travel_id=${travel_id}`
  );
};
export const TravelTimeCheck = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_time_check`,
    data
  );
};
export const TravelCityCheck = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/city-exists`, data);
};
export const TravelAdvanceCheck = async (user_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_advance_access_exists?user_id=${user_id}`
  );
};

export const sapStatusData = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/user_attendance_log?travel_id=${travel_id}`
  );
};

export const getTravelLogValueList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_advance_amount_log?${queryParm}`
  );
};

export const managerDeletingUserDA = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/expense_da/${id}`);
};

export const managerCreatingUserDA = async (travel_id, user_id, is_ssc) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da_2?travel_id=${travel_id}&user_id=${user_id}&is_ssc=${true}`
  );
};
