import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getReimbEntitlement,
  getReimbursementData,
  saveMobileReimbursement,
  updateReimbursementData,
  uploadfile,
} from "./claimReimbServices";
import Select from "react-select";
import * as moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { toast } from "react-toastify";

function MobileReimbursement(props) {
  const { onClose, edit, editRecord, onAddOrUpdate } = props;
  const exceptionUsers = ["100044", "100134", "100967"];
  const manufacturerOptions = [
    {
      value: "Apple",
      label: "Apple",
    },
    {
      value: "Asus",
      label: "Asus",
    },
    {
      value: "Blackberry",
      label: "Blackberry",
    },
    {
      value: "Coolpad",
      label: "Coolpad",
    },
    {
      value: "Gionne",
      label: "Gionne",
    },
    {
      value: "HTC",
      label: "HTC",
    },
    {
      value: "Huwaei",
      label: "Huwaei",
    },
    {
      value: "Intex",
      label: "Intex",
    },
    {
      value: "LG",
      label: "LG",
    },
    {
      value: "Lenovo",
      label: "Lenovo",
    },
    {
      value: "Micromax",
      label: "Micromax",
    },
    {
      value: "Nokia",
      label: "Nokia",
    },
    {
      value: "Oppo",
      label: "Oppo",
    },
    {
      value: "Panasonic",
      label: "Panasonic",
    },
    {
      value: "Samsung",
      label: "Samsung",
    },
    {
      value: "Ureka",
      label: "Ureka",
    },
    {
      value: "Vivo",
      label: "Vivo",
    },
    {
      value: "Xaiomi",
      label: "Xaiomi",
    },
    {
      value: "OnePlus",
      label: "OnePlus",
    },
    {
      value: "Realme",
      label: "Realme",
    },
    {
      value: "Motorola",
      label: "Motorola",
    },
    {
      value: "Google",
      label: "Google",
    },
  ];
  let condition = "true";
  const osOptions = [
    {
      value: "iOS8",
      label: "iOS8",
    },
    {
      value: "iOS9",
      label: "iOS9",
    },
    {
      value: "iOS10",
      label: "iOS10",
    },
    {
      value: "Androvalue4.x",
      label: "Androvalue4.x",
    },
    {
      value: "Androvalue5.x",
      label: "Androvalue5.x",
    },
    {
      value: "Androvalue6.x",
      label: "Androvalue6.x",
    },
    {
      value: "Androvalue7.x",
      label: "Androvalue7.x",
    },
    {
      value: "Windows8.x",
      label: "Windows8.x",
    },
    {
      value: "Windows10",
      label: "Windows10",
    },
    {
      value: "iOS11",
      label: "iOS11",
    },
    {
      value: "iOS12",
      label: "iOS12",
    },
    {
      value: "iOS13",
      label: "iOS13",
    },
    {
      value: "iOS14",
      label: "iOS14",
    },
  ];
  const [formObj, setFormObj] = useState({
    reimburseType: "1",
    lastClaimed: "init",
    mobEntitlement: "",
    companyMob: "",
    imeiNo: "",
    manufacturer: "",
    osVer: "",
    billNo: "",
    billDate: "",
    particulars: "",
    amount: "",
    attachment: null,
  });
  const [formError, setFormError] = useState({
    companyMob: false,
    imeiNo: false,
    manufacturer: false,
    osVer: false,
    billNo: false,
    billDate: false,
    particulars: false,
    amount: false,
    attachment: false,
  });
  const userData = useSelector(selectUserData);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filename, setFilename] = useState("");
  const [fileurl, setfileurl] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [lastReimburse, setLastReimburse] = useState(null);
  const dispatch = useDispatch();

  const fileUploadHandler = (e) => {
    const fileData = new FormData();
    let files = [];
    let urls = [];
    let fileNames = [];
    let file = e.target.files;
    dispatch(setDisplayLoader("Display"));
    for (let i = 0; i < file.length; i++) {
      let myUrl = URL.createObjectURL(e.target.files[i]);
      urls.push(myUrl);
      let data = e.target.files[i];
      fileData.append("uploaded_file", data);
      let name = { name: data.name };
      fileNames.push(name);
      uploadfile(fileData).then((response) => {
        files.push(response.data);
      });
    }
    dispatch(setDisplayLoader("Hide"));
    setFormObj((prev) => ({
      ...prev,
      attachment: files,
    }));
    setFilename(fileNames);
    setfileurl(urls);
  };

  const submitReimbursement = () => {
    if (checkErrors()) {
      if (edit) {
        if (condition === "true") {
          condition = "false";
          updateReimbursementData(editRecord?.id, formObj).then((response) => {
            if (response?.status === 200) {
              condition = "true";
              toast.success(response?.data?.message);
              onAddOrUpdate();
            }
          });
        } else {
          toast.error("Kindly provide attachment.");
        }
      } else {
        if (
          (userData?.emp_sub_group.startsWith("F") ||
            userData?.emp_sub_group.startsWith("W") ||
            userData?.emp_sub_group.startsWith("O")) &&
          exceptionUsers.includes(userData?.username) === false
        ) {
          toast.error("You are not eligible for Mobile Reimbursement.");
        } else if (formObj.attachment === null) {
          toast.error("Kindly select attachment.");
        } else {
          if (condition === "true") {
            condition = "false";
            saveMobileReimbursement(formObj).then((res) => {
              if (res?.status === 200) {
                condition = "true";
                toast.success(res?.data?.message);
                onAddOrUpdate();
              }
            });
          }
        }
      }
    }
  };

  const checkErrors = () => {
    let flag = Object.values(formObj)
      .filter((obj) => {
        if (obj !== "1" || obj !== null || obj !== "init") {
          return true;
        }
      })
      .includes("");
    if (flag) {
      setFormError((prev) => ({
        ...prev,
        amount: formObj?.amount === "" ? true : false,
        billDate: formObj?.billDate === "" ? true : false,
        billNo: formObj?.billNo === "" ? true : false,
        imeiNo: formObj?.imeiNo === "" ? true : false,
        companyMob: formObj?.companyMob === "" ? true : false,
        manufacturer: formObj?.amount === "" ? true : false,
        osVer: formObj?.amount === "" ? true : false,
        particulars: formObj?.particulars === "" ? true : false,
      }));
      return false;
    } else {
      return true;
    }
  };

  const getEntitlement = (empSubGroup) => {
    getReimbEntitlement(empSubGroup, "1").then((response) => {
      if (response?.status === 200) {
        setFormObj((prev) => ({
          ...prev,
          mobEntitlement: response?.data?.dataList?.amount,
        }));
      }
    });
  };

  const changeHandler = (type, value) => {
    switch (type) {
      case "companyMob":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            companyMob: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            companyMob: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          companyMob: value,
        }));
        break;
      case "imeiNo":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            imeiNo: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            imeiNo: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          imeiNo: value,
        }));
        break;
      case "manufacturer":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            manufacturer: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            manufacturer: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          manufacturer: value,
        }));
        break;
      case "osVer":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            osVer: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            osVer: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          osVer: value,
        }));
        break;
      case "billNo":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            billNo: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            billNo: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          billNo: value,
        }));
        break;
      case "billDate":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            billDate: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            billDate: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          billDate: value,
        }));
        break;
      case "particulars":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            particulars: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            particulars: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          particulars: value,
        }));
        break;
      case "amount":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            amount: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            amount: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          amount: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleBlur = (type, value) => {
    switch (type) {
      case "companyMob":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            companyMob: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            companyMob: true,
          }));
        }
        break;
      case "imeiNo":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            imeiNo: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            imeiNo: true,
          }));
        }
        break;
      case "manufacturer":
        if (value !== "" && formObj?.manufacturer !== "") {
          setFormError((prev) => ({
            ...prev,
            manufacturer: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            manufacturer: true,
          }));
        }
        break;
      case "osVer":
        if (value !== "" || formObj?.osVer === "") {
          setFormError((prev) => ({
            ...prev,
            osVer: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            osVer: true,
          }));
        }
        break;
      case "billNo":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            billNo: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            billNo: true,
          }));
        }
        break;
      case "billDate":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            billDate: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            billDate: true,
          }));
        }
        break;
      case "particulars":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            particulars: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            particulars: true,
          }));
        }
        break;
      case "amount":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            amount: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            amount: true,
          }));
        }
        break;
      default:
        break;
    }
  };

  const getLastReimbursement = (paginate) => {
    getReimbursementData(`reimburse_type=1&paginate=${paginate}`).then(
      (response) => {
        if (response?.status === 200) {
          if (response?.data?.dataList?.result?.last_claimed !== undefined) {
            if (
              moment(new Date()).diff(
                moment(response?.data?.dataList?.result?.last_claimed),
                "days"
              ) < 730
            ) {
              setIsDisabled(true);
            }
            setFormObj((prev) => ({
              ...prev,
              lastClaimed: moment(
                response?.data?.dataList?.result?.last_claimed
              ).format("YYYY-MM-DD"),
            }));
          } else {
            setFormObj((prev) => ({
              ...prev,
              lastClaimed: "init",
            }));
          }
          setLastReimburse(response?.data?.dataList?.result);
        }
      }
    );
  };

  useEffect(() => {
    if (userData?.emp_sub_group) {
      getLastReimbursement(false);
      getEntitlement(userData?.emp_sub_group);
    }
  }, [userData]);

  useEffect(() => {
    if (editRecord) {
      setFormObj((prev) => ({
        ...prev,
        amount: editRecord?.amount,
        lastClaimed: moment(editRecord?.last_claimed).format("DD-MM-YYYY"),
        billDate: moment(editRecord?.bill_date).format("YYYY-MM-DD"),
        billNo: editRecord?.bill_no,
        mobEntitlement: editRecord?.mobile_entitlement,
        companyMob: editRecord?.company_mobile,
        imeiNo: editRecord?.imei_no,
        osVer: osOptions?.filter((obj) => {
          if (obj?.value === editRecord?.os_ver) {
            return true;
          }
        })[0],
        manufacturer: manufacturerOptions?.filter((obj) => {
          if (obj?.value === editRecord?.manufacturer) {
            return true;
          }
        })[0],
        particulars: editRecord?.particulars,
      }));
      setFilename(editRecord?.attachment);
    }
  }, [edit]);

  return (
    <>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Last Claimed On</label>
            <input
              type="text"
              class="form-control"
              value={
                formObj?.lastClaimed === "init" || formObj?.lastClaimed === null
                  ? ""
                  : formObj?.lastClaimed
              }
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Mobile Entitlement (INR)</label>
            <input
              type="number"
              class="form-control"
              value={formObj?.mobEntitlement}
              placeholder="Enter Amount"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Company Mobile No. <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              value={formObj?.companyMob}
              onChange={(e) => {
                if (e.target.value?.length <= 10) {
                  changeHandler("companyMob", e.target.value);
                }
              }}
              onBlur={(e) => {
                handleBlur("companyMob", e.target.value);
              }}
              placeholder="Enter Company Mobile No."
            />
            {formError?.companyMob ? (
              <div className="small text-danger">Select Company Mobile No.</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              IMEI No. <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              value={formObj?.imeiNo}
              onChange={(e) => {
                if (e.target.value?.length <= 16) {
                  changeHandler("imeiNo", e.target.value);
                }
              }}
              onBlur={(e) => {
                handleBlur("imeiNo", e.target.value);
              }}
              placeholder="Enter IMEI No."
            />
            {formError?.imeiNo ? (
              <div className="small text-danger">Select IMEI No.</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Manufacturer <span class="text-danger">*</span>
            </label>
            <Select
              options={manufacturerOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler("manufacturer", e);
              }}
              onBlur={(e) => {
                handleBlur("manufacturer", e);
              }}
              value={formObj?.manufacturer}
            />
            {formError?.manufacturer ? (
              <div className="small text-danger">Select Manufacturer</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              OS Version <span class="text-danger">*</span>
            </label>

            <Select
              options={osOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler("osVer", e);
              }}
              onBlur={(e) => {
                handleBlur("osVer", e);
              }}
              value={formObj?.osVer}
            />
            {formError?.osVer ? (
              <div className="small text-danger">Select OS Version</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Bill No. <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              value={formObj?.billNo}
              onChange={(e) => {
                if (e.target.value.length <= 50) {
                  changeHandler("billNo", e.target.value);
                }
              }}
              onBlur={(e) => {
                handleBlur("billNo", e.target.value);
              }}
              class="form-control"
              placeholder="Enter Bill No."
            />
            {formError?.billNo ? (
              <div className="small text-danger">Select Bill No.</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Bill Date <span class="text-danger">*</span>
            </label>
            <input
              type="date"
              onChange={(e) => {
                changeHandler("billDate", e.target.value);
              }}
              onBlur={(e) => {
                handleBlur("billDate", e.target.value);
              }}
              max={moment(new Date()).format("YYYY-MM-DD")}
              class="form-control"
              value={formObj?.billDate}
            />
            {formError?.billDate ? (
              <div className="small text-danger">Select Bill Date</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Particulars <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              value={formObj?.particulars}
              onChange={(e) => {
                changeHandler("particulars", e.target.value);
              }}
              onBlur={(e) => {
                handleBlur("particulars", e.target.value);
              }}
              class="form-control"
              placeholder="Enter Particulars"
            />
            {formError?.particulars ? (
              <div className="small text-danger">Select Particulars</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Amount <span class="text-danger">*</span>
            </label>
            <input
              type="number"
              value={formObj?.amount}
              onChange={(e) => {
                changeHandler("amount", e.target.value);
              }}
              onBlur={(e) => {
                handleBlur("amount", e.target.value);
              }}
              class="form-control"
              placeholder="Enter Amount"
            />
            {formError?.amount ? (
              <div className="small text-danger">Select Amount</div>
            ) : null}
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup position-relative modaldefaultclose ">
            <label>
              Attachment <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control bg-white"
              disabled
              placeholder={
                filename?.length > 0 ? "" : "JPG,PNG,PDF Format Only"
              }
            />
            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input
                type="file"
                accept="image/*, application/pdf"
                multiple
                onChange={fileUploadHandler}
              />
            </div>
            <p>
              Selected files{" "}
              <span>
                {filename?.length > 0
                  ? filename?.map((obj, i) => (
                      <>
                        <a href={fileurl[i]} target="_blank" rel="noreferrer">
                          {edit ? obj?.name.split("/").pop() : obj?.name}
                        </a>
                        <br />
                      </>
                    ))
                  : ""}
              </span>
            </p>
          </div>
        </div>
      </div>
      <Modal.Footer>
        <button class="btn btn-outline-danger" onClick={onClose}>
          Cancel
        </button>
        <button
          class="btn btn-primary-inner bpi-main"
          disabled={isDisabled && edit === false}
          onClick={submitReimbursement}
        >
          Submit
        </button>
      </Modal.Footer>
    </>
  );
}

export default MobileReimbursement;
