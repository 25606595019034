import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import avtar from "../../assets/images/avtar.png";
import femaleAvatr from "../../assets/images/Female-Avatar.png";
import Notification from "./notification";
import { Modal } from "react-bootstrap";
import Asidemenu from "./asidemenu";
import * as HeaderService from "../../service/header";
import Select from "react-select";
import Myprofile from "./myprofile";
import * as LoginService from "../../service/login";
import voice from "../../assets/images/voice-listen.jpg";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import { Tooltip as toolTip } from "react-tippy";
import html2canvas from "html2canvas";
import * as DashboardService from "../../service/dashboard";
import { isAndroid, isIOS } from "react-device-detect";
import {
  selectPixelData,
  selectHeaderData,
  setOrgData,
  selectProfileStatus,
  setProfileStatus,
  selectTrackerData,
  selectUserData,
  setDisplayLoader,
  setUserData,
} from "../redux/piDataStore";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../../store/actions";
import AutoFillSelect from "./AutoFillSelect";
import { Accordion } from "react-bootstrap";

function Header({ myRights }) {
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [attendance, setAttendance] = useState([]);
  const [attendanceUp, setAttendanceUp] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [counterData, setCounterData] = useState(100);
  const [inTime, setInTime] = useState("");
  const [outTime, setOutTime] = useState("");
  const [items, setItems] = useState([]);
  const [empItems, setEmpItems] = useState([]);
  const [isShowncal, setActivecal] = useState("false");
  const [fullMonthData, setFullMonthData] = useState([]);
  const [fullMonthData1, setFullMonthData1] = useState([]);
  const incrementCounter = () => setCounterData(counterData + 20);
  const decrementCounter = () => setCounterData(counterData - 20);
  const dispatch = useDispatch();
  const data = useSelector(selectPixelData);
  const showProfile = useSelector(selectHeaderData);
  const trackValue = useSelector(selectTrackerData);
  const [userProfile, setUserProfile] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [empfilteredSuggestions, setEmpFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [empSelectedSuggestion, setEmpSelectedSuggestion] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [empDisplaySuggestions, setEmpDisplaySuggestions] = useState(false);
  const [valueSpeech, setValueSpeech] = useState();
  const [isShown, setIsShown] = useState("false");
  const [writetext, setWriteText] = useState("");
  const [listenshow, setlistenShow] = useState(false);
  const listenclose = () => setlistenShow(false);
  const [delegatedUser, setDelegatedUser] = useState([]);
  const [error, setError] = useState(false);
  const [reviewData, setReviewData] = useState({
    id: 0,
    description: "",
    attachment: {},
  });
  const [myRightsData, setMyRightsData] = useState([]);
  const profileStatus = useSelector(selectProfileStatus);
  const [imageUrl, setImageUrl] = useState();
  const [isShowncaldel, setActivecaldelg] = useState("false");

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const navigate = useNavigate();

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const getUserDetails = async (id) => {
    HeaderService.getUserDetails(id)
      .then((response) => {
        setProfileData(response?.data?.dataList);
        dispatch(setUserData(response?.data?.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const setAttendanceMain = async (from_date, to_date) => {
    let allData = {};
    allData["from_date"] = from_date;
    allData["to_date"] = to_date;
    HeaderService.setAttendanceMain(allData)
      .then((response) => {
        setAttendance(response?.data?.dataList);
        setAttendanceUp(response?.data?.dataList?.attendances);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveActivityTracker = async (data) => {
    HeaderService.saveActivityTracker(data)
      .then((response) => {
        // console.log(response, "resssssss");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (trackValue !== undefined && Object.keys(trackValue)?.length !== 0) {
      saveActivityTracker(trackValue);
    }
  }, [trackValue]);

  const delgateDropopen = () => {
    setActivecaldelg(!isShowncaldel);
  };

  const georg = (id) => {
    LoginService.getOrgbyId(userData?.organisation_code)
      .then((res) => {
        dispatch(setOrgData(res?.data?.dataList?.result[0]));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (profileStatus == true) {
      setIsShown(true);
    }
  }, [profileStatus]);

  useEffect(() => {
    var full = window.location.host;
    var parts = full.split(".");
    var sub = parts[0];
    var domain = parts[1];
    var type = parts[2];
    georg(sub);
  }, []);

  useEffect(() => {
    let month = moment(new Date()).format("MM");
    // let currMonth = month+1
    GetFullMonthAttendance(month);
  }, []);

  const handlekey = (month) => {
    let queryParm = "";
    if (month !== "" && month !== undefined && month !== null) {
      queryParm = queryParm + "&month=" + month;
    }
    return queryParm;
  };

  const GetFullMonthAttendance = (month) => {
    let currMonth = Number(month);
    HeaderService.getFullMonthAttendance(handlekey(currMonth)).then(
      (response) => {
        setFullMonthData1(response?.data?.dataList?.result);
        let obj = response?.data?.dataList?.result?.map((res) => {
          let statusLaeve = res.status.slice(-1);
          if (res.status === "P") {
            var status = "present";
          }
          if (statusLaeve === "L") {
            var status = "leave";
          }
          if (res.status === "A" || res?.status === "HLF") {
            var status = "absent";
          }
          if (res.status === "T") {
            var status = "travel";
          }
          if (res.status === "WO" || res.status === "HLD") {
            var status = "";
          }
          if (
            res?.attendance_date === moment(new Date()).format("YYYY-MM-DD")
          ) {
            if (res?.status == "HLF") {
              status = "present";
            }
            setInTime(res?.in_time);
            setOutTime(res?.out_time);
          }
          return {
            title: "",
            date: res.attendance_date,
            className:
              status == "travel"
                ? "dotcheckincircle present"
                : status == "present"
                ? "dotcheckincircle present"
                : status == "absent"
                ? "dotcheckincircle today"
                : status == "leave"
                ? "dotcheckincircle absent"
                : status == ""
                ? "dotcheckincircle"
                : "dotcheckincircle present",
          };
        });
        setFullMonthData(obj);
      }
    );
  };

  const setSearchData = async (search) => {
    await HeaderService.setSearchData(search)
      .then((response) => {
        let data = [];
        let empData = [];
        response?.data?.dataList?.result?.authentication_authmodules?.map(
          (item, index) => {
            let link = {
              link: item?.url,
              name: item.name,
              url_type: item.url_type,
            };
            data.push(link);
          }
        );
        setItems(data);
        response?.data?.dataList?.result?.master_employeedirectory?.map(
          (item, index) => {
            let link = {
              email: item?.email,
              name: item.name,
              mobile: item.mobile,
              Ext: item.telephone_ext,
            };
            empData.push(link);
          }
        );
        setEmpItems(empData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveDarkModeData = async (data) => {
    await HeaderService.saveDarkModeData(data)
      .then((response) => {
        getUserDetails(0);
        dispatch(setUserData(response?.data?.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    if (data === true) {
      document.getElementById("parent_id").classList.remove("dark-theme");
    } else {
      document.getElementById("parent_id").classList.add("dark-theme");
    }
  };

  const saveReportIssue = async (data) => {
    data.description = reviewData?.description;
    data.attachment = imageUrl;
    dispatch(setDisplayLoader("Display"));
    HeaderService.saveReportIssue(data)
      .then((response) => {
        toast.success('Ticket Raised Successfully')
        dispatch(setDisplayLoader("Hide"));
        reportissueclose();
        setReviewData();
      })
      .catch((error) => {
        toast.error('Error While Ticket Raising')
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (data > 18) {
      incrementCounter();
    }
    if (data <= 18) {
      decrementCounter();
    }
  }, [data]);

  useEffect(() => {
    if (imageUrl !== undefined) {
      saveReportIssue(reviewData);
    }
  }, [imageUrl]);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const userData = useSelector(selectUserData);
  const addnewclose = () => addnewsetShow(false);

  const [reportissueshow, reportissueShow] = useState(false);
  const reportissueopen = () => reportissueShow(true);
  const reportissueclose = () => reportissueShow(false);

  useEffect(() => {
    getProfilePic();
    // getLeaveBalanceSync();
    setAttendanceMain(currentDate, currentDate);
    let interval = setInterval(() => {
      setAttendanceMain(currentDate, currentDate);
    }, 12000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getProfilePic = () => {
    DashboardService.getProfilePicSync()
      .then((res) => {
        if (res?.status === 200) {
          console.log(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getLeaveBalanceSync = () => {
  //   DashboardService.getLeaveBalanceSync()
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         console.log(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const profileDropopen = () => {
    setIsShown(!isShown);
    dispatch(setProfileStatus(isShown));
  };

  useEffect(() => {
    setMyRightsData(myRights);
  }, [myRights]);

  useEffect(() => {
    if (showProfile === "true") {
      setIsShown("true");
    }
  }, [showProfile]);

  useEffect(() => {
    if (userData == undefined) return;
    let jsonData = {};
    userData?.groups?.map((item) => {
      jsonData[item?.name] = item;
    });
    setProfileData(userData);
    setUserProfile(jsonData);
    if (userData?.dark_mode === true) {
      document.getElementById("parent_id").classList.remove("dark-theme");
    } else {
      document.getElementById("parent_id").classList.add("dark-theme");
    }
  }, [userData]);

  useEffect(() => {
    if (profileStatus === true) {
      setIsShown(true);
    }
  }, [profileStatus]);

  const onChange = (event) => {
    const value = event;
    setSearchData(value.trim());
    setInputValue(value);
  };

  useEffect(() => {
    if (items !== []) {
      const filteredSuggestions = items.filter((suggestion) =>
        suggestion?.name.toLowerCase().includes(valueSpeech.toLowerCase())
      );
      setFilteredSuggestions(filteredSuggestions);
      setDisplaySuggestions(true);
    }
  }, [items]);

  useEffect(() => {
    if (empItems !== []) {
      const filteredSuggestions = empItems.filter((suggestion) =>
        suggestion?.name.toLowerCase().includes(valueSpeech.toLowerCase())
      );
      setEmpFilteredSuggestions(filteredSuggestions);
      setEmpDisplaySuggestions(true);
    }
  }, [empItems]);

  useEffect(() => {
    if (localStorage?.getItem("delegatedLogin") !== null) {
      getUserDelegated(Number(localStorage?.getItem("initialUser")), false);
    } else {
      if (userData?.has_delegated) {
        getUserDelegated(userData?.id, true);
      } else {
        getUserDelegated(userData?.id, false);
      }
    }
  }, []);

  const getUserDelegated = (id, from) => {
    HeaderService.getUserDelegates(id, from).then((response) => {
      if (response?.status === 200) {
        setDelegatedUser(
          response?.data?.dataList?.result.filter((obj) => {
            if (obj?.from_user_id !== userData?.id) {
              return true;
            }
          })
        );
      }
    });
  };

  const onSelectSuggestion = (link, index, urlType) => {
    if (urlType === "EXTR") {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
    setSelectedSuggestion(index);
    setInputValue(filteredSuggestions[index]);
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };

  const onSelectSuggestionsEmployee = (email, index) => {
    setEmpSelectedSuggestion(index);
    setInputValue(empfilteredSuggestions[index]);
    setEmpFilteredSuggestions([]);
    setEmpDisplaySuggestions(false);
    navigate("/employee-dir");
  };

  function runSpeechRecognition() {
    // get output div reference
    //  var output = document.getElementById("findqueanswerId");
    // get action element reference
    //  var action = document.getElementById("listeningId");
    // new speech recognition object
    var SpeechRecognition = SpeechRecognition || window.webkitSpeechRecognition;
    var recognition = new SpeechRecognition();

    // This runs when the speech recognition service starts
    recognition.onstart = function () {
      setlistenShow(true);

      //        action.innerHTML = "<small>listening, please speak...</small>";
      // $("#listeningId").show();
      // $("#findqueanswerId").val("");
      // $("#findqueanswerId").attr("disabled", "disabled");
    };

    recognition.onspeechend = function () {
      setlistenShow(false);
      recognition.stop();
    };

    // This runs when the speech recognition service returns result
    recognition.onresult = function (event) {
      var transcript = event.results[0][0].transcript;
      setWriteText(transcript);
      onChange(transcript.toLowerCase());
      setValueSpeech(transcript.toLowerCase());
      // document.getElementById("text123").value = transcript
      var confidence = event.results[0][0].confidence;
    };

    // start recognition
    recognition.start();
  }

  function report() {
    if(!reviewData?.description){
      setError(true)
    }
    if(reviewData?.description){         
    reportissueclose()
    dispatch(setDisplayLoader("Display"));
    let region = document.querySelector("body");
    html2canvas(region).then((canvas) => {
      let pngUrl = canvas.toDataURL("image/png");
      fetch(pngUrl)
        .then((res) => res.blob())

        .then((blob) => {
          const fileData = new FormData();
          const file = new File([blob], "filename.jpeg");
          fileData.append("uploaded_file", file);
          HeaderService.uploadImages(fileData).then((response) => {
            setImageUrl(response.data);
            setError(false)
          });
        });
    });
    }
  }

  const handleDateClick = (e) => {
    let data = fullMonthData1;
    let flag = false;
    let isAbsent = false;
    data.map((res) => {
      if (res.attendance_date == e.dateStr) {
        flag = true;
        setInTime(res.in_time);
        setOutTime(res.out_time);
        if (res?.status === "A") {
          isAbsent = true;
        }
      } else if (moment(new Date()).format("YYYY-MM-DD") < e.dateStr) {
        setInTime("");
        setOutTime("");
      }
      if (isAbsent) {
        navigate("/my-leave", {
          state: {
            fromCalender: true,
          },
        });
        checkInCalen();
      }
    });
    if (flag === false) {
      setInTime("");
      setOutTime("");
    }
  };

  const handleMonthChange = (payload) => {
    let month = moment(payload?.view?.currentStart).format("MM");
    GetFullMonthAttendance(month);
  };

  const checkInCalen = () => {
    setActivecal(!isShowncal);
  };

  const switchUser = (obj) => {
    if (
      (userData?.has_delegated === true &&
        localStorage?.getItem("delegatedLogin") !== null) ||
      userData?.has_delegated === false
    ) {
      dispatch(setDisplayLoader("Display"));
      let payload = {
        curToken: localStorage?.getItem("accesstoken"),
        curUser: userData?.id,
        switchUser: obj?.from_user_id,
        initailUser:
          localStorage?.getItem("initialUser") === null
            ? userData?.id
            : Number(localStorage?.getItem("initialUser")),
      };
      LoginService.switchSelectedUser(payload).then((response) => {
        if (response?.status === 200) {
          dispatch(setDisplayLoader("Hide"));
          localStorage?.setItem(
            "accesstoken",
            response?.data?.dataList?.switchToken
          );
          localStorage?.setItem("delegatedLogin", true);
          localStorage?.setItem(
            "initialUser",
            response?.data?.dataList?.initailUser
          );
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <header className="d-none d-md-block" id="tabHead">
        <div className="row align-items-center">
          <div className="col-md-4 col-lg-3 d-flex align-items-center">
            {userProfile["Region Manager"] ||
            userProfile["Zone Manger"] ||
            userProfile["Territory Manager"] ? (
              ""
            ) : (
              <>
                {profileData?.dark_mode === true ? (
                  <div
                    className="darkbtn"
                    onClick={() => saveDarkModeData(false)}
                  >
                    <img src="images/dark-mode.svg" alt="" />
                  </div>
                ) : (
                  <div
                    className="darkbtn"
                    onClick={() => saveDarkModeData(true)}
                  >
                    <img src="images/light-mode.svg" alt="" />
                  </div>
                )}
              </>
            )}

            {userProfile["Region Manager"] ||
            userProfile["Zone Manger"] ||
            userProfile["Territory Manager"] ? (
              ""
            ) : (
              <div className="input-group ml-4">
                <div className="modulesearchbox">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Type here to search..."
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValueSpeech(e.target.value);
                    }}
                    value={inputValue}
                  />
                  <AutoFillSelect
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    selectedSuggestion={selectedSuggestion}
                    onSelectSuggestion={onSelectSuggestion}
                    displaySuggestions={displaySuggestions}
                    suggestions={filteredSuggestions}
                    empDisplaySuggestions={empDisplaySuggestions}
                    empSelectedSuggestion={empSelectedSuggestion}
                    onSelectSuggestionsEmployee={onSelectSuggestionsEmployee}
                    empfilteredSuggestions={empfilteredSuggestions}
                  />
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  className="input-group-text"
                  onClick={runSpeechRecognition}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Search by voice</Tooltip>}
                  >
                    <img src="images/sound.png" alt="" />
                  </OverlayTrigger>
                </span>
              </div>
            )}
          </div>

          <div class="col-md-4 text-center">
            {userProfile["Region Manager"] ||
            userProfile["Zone Manger"] ||
            userProfile["Territory Manager"] ? (
              ""
            ) : (
              <img
                src="\images\MyPI Logo_644 x 297px-01 (2).png"
                alt=""
                height={50}
              />
            )}
          </div>
          <div className="col-md-4 col-lg-2 px-lg-0 mb-3 mb-lg-0 d-flex justify-content-end align-items-end">
            {userProfile["Region Manager"] ||
            userProfile["Zone Manger"] ||
            userProfile["Territory Manager"]
              ? ""
              : attendanceUp?.map((val, ind) => (
                  <div
                    className={
                      isShowncal
                        ? "checkinbox"
                        : "usermyprofilesettingshow checkinbox"
                    }
                  >
                    <div key={`header${ind}`}>
                      <p>
                        {" "}
                        {`${moment(val.attendance_date, false).format(
                          "DD - MM - YYYY"
                        )}`}
                      </p>
                      <div
                        className="d-flex justify-content-between cursor-pointer"
                        onClick={checkInCalen}
                      >
                        <div>
                          <label>Punch In</label>
                          <h5>
                            {val?.in_time === "" || val?.in_time === null
                              ? "- -  -"
                              : val?.in_time}
                          </h5>
                        </div>
                        <div>
                          <label>Punch Out</label>
                          <h5>
                            {val?.out_time === "" || val?.out_time === null
                              ? "- - -"
                              : val?.out_time}
                          </h5>
                        </div>
                        <i className="fas fa-chevron-down"></i>
                      </div>
                      <div class="blackoverlay" onClick={checkInCalen}></div>
                      <div class="notificationbox checkinCalbox">
                        <FullCalendar
                          headerToolbar={{
                            start: "title",
                            center: "",
                            end: "prev next",
                          }}
                          plugins={[dayGridPlugin, interactionPlugin]}
                          dateClick={(e) => {
                            handleDateClick(e);
                          }}
                          datesSet={(e) => {
                            handleMonthChange(e);
                          }}
                          events={fullMonthData}
                          selectable={true}
                        />
                        <div class="morebtn mt-auto text-right mr-2">
                          <Link
                            class="text-theme fw-bold"
                            to={`/attendance/${btoa(userData?.id)}`}
                            onClick={checkInCalen}
                          >
                            {" "}
                            See More{" "}
                            <i class="far fa-arrow-alt-circle-right"></i>
                          </Link>
                        </div>
                        <div class="popupcheckintimebox">
                          <div class="popupcheckintime">
                            <div>
                              <img src="images/check-in-img.png" />
                            </div>
                            <div>
                              {inTime === "" || inTime === null ? (
                                <h2>---</h2>
                              ) : (
                                <h2>{inTime}</h2>
                              )}
                              <p>Punch In</p>
                            </div>
                          </div>
                          <div class="popupcheckintime">
                            <div>
                              <img src="images/check-out-img.png" />
                            </div>
                            <div>
                              {outTime === "" || outTime === null ? (
                                <h2>---</h2>
                              ) : (
                                <h2>{outTime}</h2>
                              )}
                              <p>Punch Out</p>
                            </div>
                          </div>
                        </div>
                        <div class="leave-indicator">
                          <div class="chkpresent">
                            <span></span> Present
                          </div>
                          <div class="chkabsent">
                            <span></span> Leave
                          </div>
                          <div class="chkpucnhout">
                            <span></span> Absent
                          </div>
                          <div class="chkholiday">
                            <span></span> Holiday/Week off
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          <div class="col-md-12 col-lg-3">
            <ul className="topheadusernot">
              {/* <li class="mr-sm-4">
                <div class="input-group addminushtml">
                  <button class="input-group-text" 
                  // onClick={() => Minimize()}
                  >
                    -
                  </button>
                  <input
                    type="text"
                    class="form-control"
                    value="100%"
                    disabled
                  />
                  <button class="input-group-text" 
                  // onClick={() => Maximize()}
                  >
                    +
                  </button>
                </div>
              </li> */}
              {profileData?.is_delegated !== undefined &&
              profileData?.is_delegated === true ? (
                // <li class="mr-sm-4">
                //   <img src="images/delegate-icon.png" alt="" />

                // </li>
                <li
                  className={
                    isShowncaldel
                      ? "usermyprofile delegatedropdwon"
                      : "usermyprofilesettingshow usermyprofile delegatedropdwon"
                  }
                >
                  <img
                    src="https://prod-mypi-public.s3.ap-south-1.amazonaws.com/media/delegate-icon.png"
                    width="45px"
                    alt=""
                    onClick={delgateDropopen}
                  />
                  {/* <span class="text-danger ml-1 blink">
                    <i class="fas fa-comment-dots"></i>
                  </span> */}
                  <div class="notificationbox">
                    <h4>Delegate</h4>
                    <div class="mandtop-user topheaduser">
                      <img
                        src={
                          profileData?.image === "" && userData?.gender === "M"
                            ? avtar
                            : profileData?.image === "" &&
                              userData?.gender === "F"
                            ? femaleAvatr
                            : `data:image/gif;base64,${profileData?.image}`
                        }
                        alt=""
                      />
                      <div>
                        <h2>{`${userData?.full_name} (${userData?.username})`}</h2>
                      </div>
                    </div>

                    <Accordion defaultActiveKey="2" className="delegateusertop">
                      {delegatedUser.map((obj, i) => (
                        <Accordion.Item
                          eventKey={i}
                          onClick={() => switchUser(obj)}
                        >
                          <Accordion.Header>
                            <div class="mandtop-user topheaduser">
                              <img
                                src={
                                  obj?.from_profile === ""
                                    ? avtar
                                    : `data:image/gif;base64,${obj?.from_profile}`
                                }
                                alt=""
                              />
                              <div>
                                <h2>{`${obj?.from_name} (${obj?.from_username})`}</h2>
                                <p>{obj?.from_email}</p>
                              </div>
                            </div>
                          </Accordion.Header>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </li>
              ) : (
                ""
              )}
              {profileData?.has_delegated !== undefined &&
              profileData?.has_delegated === true ? (
                <li
                  className={
                    isShowncaldel
                      ? "usermyprofile delegatedropdwon"
                      : "usermyprofilesettingshow usermyprofile delegatedropdwon"
                  }
                >
                  <img
                    src="https://prod-mypi-public.s3.ap-south-1.amazonaws.com/media/delegate-icon-green.png"
                    width="45px"
                    alt=""
                    onClick={delgateDropopen}
                  />
                  <div class="notificationbox">
                    <h4>Delegate</h4>
                    <div class="mandtop-user topheaduser">
                      <img
                        src={
                          profileData?.image === "" && userData?.gender === "M"
                            ? avtar
                            : profileData?.image === "" &&
                              userData?.gender === "F"
                            ? femaleAvatr
                            : `data:image/gif;base64,${profileData?.image}`
                        }
                        alt=""
                      />
                      <div>
                        <h2>{`${userData?.full_name} (${userData?.username})`}</h2>
                      </div>
                    </div>

                    <Accordion defaultActiveKey="2" className="delegateusertop">
                      {delegatedUser.map((obj, i) => (
                        <toolTip
                          title={`${obj?.from_name} (${obj?.from_username})`}
                          position="bottom"
                        >
                          <Accordion.Item
                            eventKey={i}
                            onClick={() => switchUser(obj)}
                          >
                            <Accordion.Header>
                              <div class="mandtop-user topheaduser">
                                <img
                                  src={
                                    obj?.from_profile === ""
                                      ? avtar
                                      : `data:image/gif;base64,${obj?.from_profile}`
                                  }
                                  alt=""
                                />
                                <div>
                                  <h2>{`${obj?.from_name} (${obj?.from_username})`}</h2>
                                  <p>{obj?.from_email}</p>
                                </div>
                              </div>
                            </Accordion.Header>
                          </Accordion.Item>
                        </toolTip>
                      ))}
                    </Accordion>
                  </div>
                </li>
              ) : (
                // <li class="mr-sm-4">
                //   <img src="images/delegate-icon-green.png" alt="" />
                // </li>
                ""
              )}
              {/* {userProfile["Region Manager"] ||
              userProfile["Zone Manger"] ||
              userProfile["Territory Manager"] ? (
                ""
              ) : ( */}{" "}
              <Notification></Notification>
              {/* )} */}
              <li
                className={
                  isShown
                    ? "usermyprofile"
                    : "usermyprofilesettingshow usermyprofile"
                }
                onClick={() => profileDropopen()}
              >
                <div className="mandtop-user topheaduser">
                  <div>
                    <h2>{profileData && profileData.full_name}</h2>
                    {/* <h2>{attendance?.email}</h2> */}
                    {/* <p>
                      Last login:
                      {moment.utc(profileData && profileData.last_login_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p> */}
                  </div>
                  <img
                    src={
                      profileData?.image === "" && userData?.gender === "M"
                        ? avtar
                        : profileData?.image === "" && userData?.gender === "F"
                        ? femaleAvatr
                        : `data:image/gif;base64,${profileData?.image}`
                    }
                    alt=""
                  />
                </div>
                <div class="blackoverlay" onClick={profileDropopen}></div>
                <Myprofile myRightsData={myRightsData}></Myprofile>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <header className="d-md-none d-block" id="mobHead">
        <div className="row">
          <div className="col-5 mob-head-left-sec">
            <img src={Logo} alt="" className="mob-lgo" />
            <img
              src="images/menubar.png"
              alt=""
              className="menuicon"
              onClick={filteropen}
            />
          </div>
          <div className="col-7">
            <ul className="topheadusernot">
              <li>
                <Link to="/">
                  <img src="images/search.png" alt="" />
                </Link>
              </li>
              <Notification></Notification>
              <li className="notificonhoverbox usermyprofile">
                <div className="mandtop-user topheaduser">
                  <img
                    src={profileData?.image === "" ? avtar : profileData?.image}
                    alt=""
                  />
                  <i className="fas fa-circle activeiconuser"></i>
                </div>
                <Myprofile></Myprofile>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {!isAndroid && !isIOS && (
        <toolTip title="Raise a Ticket" position="top-start">
          <div class="report-issue" onClick={reportissueopen}>
            <img src="images/report-issue.png" alt="" />
            <p class='raise-text'>Raise a Ticket</p>
          </div>
        </toolTip>)
      }

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose mobilmenupoup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="mobilmenwrapper">
            <Asidemenu></Asidemenu>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delegate Access Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      User Name<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="statusupdate2" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate2" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="perhead">
                <h4>Modules</h4>
                <p>Select Desired Module</p>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered permission-box">
                  <tr>
                    <td width={300}>
                      <h4>Administrator Access</h4>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        Select All
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>
                        <i class="far fa-arrow-alt-circle-right"></i> Me@PI
                      </h5>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Attendance &
                        Holiday
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Shift Changes
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Travel & Tour
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Travel & Other
                        Expense
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Reimbursement
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>
                        <i class="far fa-arrow-alt-circle-right"></i> CMS
                      </h5>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> News
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Notice
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={listenshow}
        onHide={listenclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose listenpopup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-6">
              <div class="preloader">
                <div class="loadingBox">
                  <p>Listening</p>
                  <div class="loadingbar">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src={voice} alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={reportissueshow}
        onHide={reportissueclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter reportanissuepoup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="images/alert-icon.png" alt="" /> Report an Issue
          </Modal.Title>
          <h5>Screenshot will be attached automatically</h5>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            {error && (<div className="text-danger text-md">Please Write your Issue</div>) }
            <div class="col-md-12">
              <textarea
                class="form-control"
                rows={6}
                onChange={(e) => {
                  setReviewData({ ...reviewData, description: e.target.value })
                  setError(false)
                }
                }
                value={reviewData?.description}
              ></textarea>
              <button class="btn btn-primary" onClick={() => report()}>
                <img src="images/submit-btn.png" alt="" />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  userLogin: (token, istemp) =>
    dispatch({ type: ActionTypes.LOGIN_USER, token, istemp }),
});
export default connect(null, mapDispatchToProps)(Header);
