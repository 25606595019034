import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getReleaseStrategy = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/master-release-strategy?${queryParm}`);
  };
export const getAllGroups = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/release-group?paginate=${paginate}`);
  };
export const getAllStrat = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/release-strat?paginate=${paginate}`);
  };
export const getAllCode = async (paginate) => {
    return axios.get(`${AUTH_BASE_URL}/release-code?paginate=${paginate}`);
  };

  export const saveRelease = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/master-release-strategy`, data);
  };

  export const updateRelease = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/master-release-strategy/${id}`, data);
  };

  export const deleteRelease = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/master-release-strategy/${id}`);
  };

  export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/master-release-strategy/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const isCodeExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/master-release-strategy/exists`, isData);
  };