import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import AddModule from "./addModule";
import * as moduleDataService from "../../service/moduleService";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { Formik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import SearchLoader from "../../common/SearchLoader";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const moduleOption = [
  {
    label: "Root Menu",
    value: "ROOT_M",
  },
  {
    label: "Left Menu",
    value: "LEFT_M",
  },
  {
    label: "Ess Menu",
    value: "ESS_M",
  },
  {
    label: "Agri Menu",
    value: "AGRI_M",
  },
  {
    label: "Profile Menu",
    value: "PROF_M",
  },
  {
    label: "External App",
    value: "EXT_APP",
  },
  {
    label: "My Pi App",
    value: "MYPI_APP",
  },
  {
    label: "Office365",
    value: "O365",
  },
  {
    label: "Widget",
    value: "WID",
  },
  {
    label: "Business Transaction Menu",
    value: "BT_M",
  },
];

const ModuleMaster = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userDetails = useSelector(selectUserData);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [editModuleData, setEditModuleData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [moduleDataFilter, setModuleDataFilter] = useState({ status: "" });
  const [childFilterData, setChildFilterData] = useState([]);
  const [loaderActive, isLoaderActive] = useState(false);
  const [valueExportData, setValueExportData] = useState({
    moduleId: "",
    parentModuleId: "",
    type: "",
    module_name: "",
    status: "",
  });
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const handleKey = (
    search,
    module_id,
    module_parent_id,
    module_type,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (module_id !== "" && module_id !== undefined && module_id !== null) {
      queryParm = queryParm + "&module_id=" + module_id;
    }
    if (
      module_parent_id !== "" &&
      module_parent_id !== undefined &&
      module_parent_id !== null
    ) {
      queryParm = queryParm + "&module_parent_id=" + module_parent_id;
    }
    if (
      module_type !== "" &&
      module_type !== undefined &&
      module_type !== null
    ) {
      queryParm = queryParm + "&module_type=" + module_type;
    }
    return queryParm;
  };

  const getDataModule = (
    search,
    module_id,
    module_parent_id,
    module_type,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    dispatch(setDisplayLoader("Display"));
    moduleDataService
      .getModule(
        handleKey(
          search,
          module_id,
          module_parent_id,
          module_type,
          status,
          page_no,
          page_size,
          sort_by
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        getModuleList();
        setModuleData([]);
        setModuleData([]);
        setModuleData([]);
        setModuleData([]);
        setModuleData(response?.data?.dataList);

        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        isLoaderActive(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    module_id,
    module_parent_id,
    module_type,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    moduleDataService
      .getExportData(
        handleKey(
          search,
          module_id,
          module_parent_id,
          module_type,
          status,
          page_no,
          page_size,
          sort_by
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Module.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getModuleList = () => {
    moduleDataService
      .getParentModuleList()
      .then((response) => {
        setModuleList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteModule = (idx) => {
    const data = { id: idx };
    moduleDataService
      .deleteModule(data)
      .then((res) => {
        if (res.data.status == true) {
          getDataModule(
            searchStr,
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            "-id"
          );
          toast.success("Module Deleted Successfully");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error("Unable to delete as mapped data exists.");
        } else {
          error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        }
      });
  };

  const getChildModuleList = (idx) => {
    moduleDataService
      .getChildModuleList(idx)
      .then((res) => {
        setChildFilterData(res?.data?.dataList?.result);
      })
      .catch((error) => {
        error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, moduleName) => {
    confirmAlert({
      title: "Delete Module",
      message: `Are you sure to delete ${moduleName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteModule(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      isLoaderActive(true);
      getDataModule(
        e.target.value.toLowerCase(),
        moduleDataFilter.module_id,
        moduleDataFilter.module_parent_id,
        moduleDataFilter.module_type,
        moduleDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by
      );
      setSearchStr(e.target.value);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    console.log("action", action);
    setCurrentPage(activePage);
    getDataModule(
      searchStr,
      moduleDataFilter.module_id,
      moduleDataFilter.module_parent_id,
      moduleDataFilter.module_type,
      moduleDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by
    );
  };

  useEffect(() => {
    getDataModule(
      searchStr,
      moduleDataFilter.module_id,
      moduleDataFilter.module_parent_id,
      moduleDataFilter.module_type,
      moduleDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by
    );

    getChildModuleList(0);
  }, [pageSizeNo, moduleDataFilter?.module_parent_id]);

  const addNewModule = () => {
    setEditMode(false);
    setEditModuleData(moduleData.result);
    setShowForm(true);
  };

  const onClose = () => {
    setEditModuleData(null);
    setShowForm(false);
  };

  const editModuleValue = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setEditModuleData(eData);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getDataModule(
      searchStr,
      moduleDataFilter.module_id,
      moduleDataFilter.module_parent_id,
      moduleDataFilter.module_type,
      moduleDataFilter.status,
      1,
      pageSizeNo,
      "-id"
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getDataModule(
      searchStr,
      moduleDataFilter.module_id,
      moduleDataFilter.module_parent_id,
      moduleDataFilter.module_type,
      moduleDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getDataModule(
        data,
        moduleDataFilter.module_id,
        moduleDataFilter.module_parent_id,
        moduleDataFilter.module_type,
        moduleDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const ModuleListData = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`modules${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const childModuleListData = (optionsModuleList) => {
    return optionsModuleList?.map((item, index) => (
      <option
        key={`modules${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const filterShow = () => {
    filteropen();
    getModuleList();
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    setValueExportData({
      ...valueExportData,
      moduleId: moduleDataFilter.module_id,
      parentModuleId: moduleDataFilter.module_parent_id,
      type: moduleDataFilter.module_type,
      status: moduleDataFilter.status,
    });
    const moduleId = moduleDataFilter.module_id;
    const parentModuleId = moduleDataFilter.module_parent_id;
    const type = moduleDataFilter.module_type;
    const status = moduleDataFilter.status;
    getDataModule(
      searchStr,
      moduleId,
      parentModuleId,
      type,
      status,
      1,
      pageSizeNo,
      sortData.sort_by
    );
    filterClose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(moduleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setModuleDataFilter(ClearData);
    getDataModule(searchStr, "", "", "", "", 1, pageSizeNo, sortData.sort_by);
    filterClose();
  };

  const filterClose = () => {
    filterclose();
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Module</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Module</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <ToastContainer autoClose={1000} />
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Parent Module,
                        <br />
                        Module name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Parent Module,Module name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D || userDetails?.is_superuser == true ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />{" "}
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              valueExportData.moduleId,
                              valueExportData.parentModuleId,
                              valueExportData.type,
                              valueExportData.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A || userDetails?.is_superuser == true ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addNewModule();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addNewModule();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured sort-overlap">
            <thead>
              <tr>
                <th>Icon</th>
                <th
                  onClick={(e) => {
                    handlerData("type");
                  }}
                  className={
                    sortData["type"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["type"]
                  }
                >
                  Type
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer "
                      : sortData["name"]
                  }
                >
                  Parent Module
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  Module name
                </th>
                <th>Page URL</th>
                <th
                  onClick={(e) => {
                    handlerData("sequence_no");
                  }}
                  className={
                    sortData["sequence_no"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["sequence_no"]
                  }
                >
                  Sequence No.
                </th>

                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Last updated by
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last updated on
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.E ||
                  finalUrlValue?.R ||
                  userDetails?.is_superuser == true ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {moduleData?.result?.length ? (
                moduleData?.result?.map((value, index) => (
                  <>
                    <tr key={`module${index}`}>
                      <td>
                        <a href={value?.icon} target="_blank" rel="noreferrer">
                          <img src={value?.icon} alt="" height={18} />
                        </a>
                      </td>
                      <td className="text-theme fw-bold">
                        {
                          moduleOption
                            .filter((x) => {
                              if (x.value === value?.type) {
                                return true;
                              }
                            })
                            .map((x) => x.label)[0]
                        }
                      </td>

                      <td>
                        {value?.module_name === null ? "" : value?.module_name}
                      </td>
                      <td>{value?.name}</td>
                      <td className="text-theme">{value?.url}</td>
                      <td>{value?.sequence_no}</td>

                      <td>{value?.updated_by}</td>
                      <td>
                        {moment
                          .utc(value?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                      <td>
                        {value?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.E ||
                        finalUrlValue?.R ||
                        userDetails?.is_superuser == true ? (
                        <td>
                          {finalUrlValue?.E ||
                            userDetails?.is_superuser == true ? (
                            <button
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                editModuleValue(value);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.R ||
                            userDetails?.is_superuser == true ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              onClick={() => submit(value.id, value.name)}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {moduleData?.result?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  style={{ textAlign: "center" }}
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option className="main-pointer" value={10} label={10} />
                  <option className="main-pointer" value={20} label={20} />
                  <option className="main-pointer" value={30} label={30} />
                  <option className="main-pointer" value={100} label={100} />
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                renderOnZeroPageCount={0}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Parent
              <select
                className="form-control newbgselect main-pointer"
                name="module_parent_id"
                type="text"
                onChange={(e) => {
                  setModuleDataFilter({
                    ...moduleDataFilter,
                    module_parent_id: e.target.value,
                  });
                }}
                value={moduleDataFilter?.module_parent_id}
              >
                <option value="" label="Select Module" />
                {ModuleListData(childFilterData)}
              </select>
            </label>
          </div>
          <div class="form-group innergroup">
            <label>Select Module</label>
            <select
              className="form-control newbgselect main-pointer"
              name="module_id"
              type="text"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  module_id: e.target.value,
                });
              }}
              value={moduleDataFilter?.module_id}
            >
              <option value="" label="Select Module" />
              {childModuleListData(childFilterData)}
            </select>
          </div>
          <div className="form-group innergroup">
            <label>Select Type</label>
            <select
              className="form-control newbgselect main-pointer"
              name="module_type"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  module_type: e.target.value,
                });
              }}
              value={moduleDataFilter?.module_type}
            >
              <option value="">Select</option>
              <option value="ROOT_M">Root Menu</option>
              <option value="LEFT_M">Home Menu</option>
              <option value="ESS_M">Ess Menu</option>
              <option value="PROF_M">Profile Menu</option>
              <option value="EXT_APP">External App</option>
              <option value="MYPI_APP">My Pi App</option>
              <option value="O365">Office365</option>
              <option value="WID">Widget</option>
              <option value="BT_M">Business Transaction Menu</option>
            </select>
          </div>
          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  status: e.target.value,
                });
              }}
              value={moduleDataFilter?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="true" label="Active" />
              <option value="false" label="In-Active" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddModule
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editModule={editModuleData}
            onAddCallBack={onAddCallBack}
            moduleList={moduleList}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ModuleMaster;
