import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSkillWise = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/report-master/skill-wise?${queryParm}`);
};

export const getEmployeeWise = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/report-master/employee-wise?${queryParm}`);
};

export const getLocationWise = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/report-master/location-wise?${queryParm}`);
};

export const getGroupWise = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/report-master/group-wise?${queryParm}`);
};

export const getExportValueSkill = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/report-master/skill-wise-data-export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueEmp = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/report-master/employee-wise-data-export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueLocation = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/report-master/location-wise-data-export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueGroup = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/report-master/group-wise-data-export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
