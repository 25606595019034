import React from "react";
import { useState,useEffect } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { Link, useNavigate } from "react-router-dom";
import {getPlant} from "../../../service/LRGRPortal/plantService";
import { Calendar } from '@fullcalendar/core';
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import {getsummaryList} from "../../../service/LRGRPortal/summaryService";
import { setDisplayLoader, selectLoader } from "../../../pages/redux/piDataStore";
import styles from './Summary.css'




            function Summary(){
                const [fullMonthData, setFullMonthData] = useState([]);
                const [fullMonthData1, setFullMonthData1] = useState([]);
                const [selectedPlant,setSelectedPlant] = useState("All");
                const dispatch = useDispatch();
                const [month, setMonth] = useState();
                const [year, setYear] = useState();
                const [plant, setPlant] = useState([]);
                const navigate = useNavigate();
                let flag = false;

                const handleLoader = (show = true) =>{
                  dispatch(setDisplayLoader( show ? "Display" : "Hide"));
                }

                function renderEventContent(eventInfo) {
                    return (
                      <>
                    
                        {/* <h6>{eventInfo.event.title}</h6> */}
                        <p style={{fontSize: "14px", color:"black",margin:0 }}>{eventInfo.event.extendedProps.Total_Invoice}</p>
                        <p style={{ fontSize: "14px",color:"black",margin:0 }}>{eventInfo.event.extendedProps.Tagged_Invoice}</p>
                        <p style={{fontSize: "14px",color:"black",margin:0 }}>{eventInfo.event.extendedProps.Untagged_Invoice}</p>
                        <p style={{fontSize: "14px",color:"black",margin:0 }}>{eventInfo.event.extendedProps.freight_Invoice}</p>
                        

                      </>
                    )
                  }
                  
                  useEffect(() => {
                    const d = new Date();
                    let month = d.getMonth()+1;
                    let year = d.getFullYear();
                    handleLoader();
                    getPlant()
                    .then((res) => {
                      setPlant(res?.data?.dataList);
                    })
                    .catch((err) => {
                      console.log(err);
                    }).finally(()=>{
                      handleLoader(false);
                    });
                   
                     
                    GetFullMonthList(month, year, selectedPlant);
                  
                  }, []);
                
              // const handleDateClick = (e) => {
              // //  console.log("e value", e.dateStr)

              //   navigate(
              //     `/transport?start_date=${e.dateStr}&end_date=${e.dateStr}`
              // )
              // };
            
              const handlekey = (month,year,plant) => {
                let queryParm = "";
                if (month !== "" && month !== undefined && month !== null && year !== "" && year !== undefined && year !== null && plant =="All"){
                  queryParm = queryParm + "&month=" + month+ "&year=" + year ;
           
                }else{
                  queryParm = queryParm + "&month=" + month+ "&year=" + year + "&plant_id=" + plant;
               
                }
                return queryParm;
              };

              const GetFullMonthList = (month,year,plant) => {
          
           
                getsummaryList(handlekey(month,year,plant)).then(
                  (response) => {
                  // setFullMonthData1(response?.data?.dataList?.result);
                 
                     if(response.data.message =="No Data found"){
                      toast.info("No Data found for the given Month")
                      setFullMonthData([]);
                     }else{
                    let obj = response?.data?.dataList?.map((res) => {
              
                      let total_invoice = res.total_invoice;
                      let untag_invoice = res.untagged_invoice;
                      let freight_invoice = res.freight_invoice_tagged;
                      let tag_invoice = res.tagged_invoice;


                      if (res.total_invoice == res.tagged_invoice) {
                        var status = "green";
                      }
                      else if (res.total_invoice == res.untagged_invoice) {
                        var status = "red";
                      }
                      else{
                        var status = "orange";

                      }
                   
        
                  return {
                    title: "User Information",
                    Total_Invoice : `Total Invoice =${total_invoice} `,
                    Tagged_Invoice : `Tagged Invoice = ${tag_invoice}`,
                    Untagged_Invoice : `Untagged Invoice = ${untag_invoice}`,
                    freight_Invoice : `Freight Invoice = ${freight_invoice}`,
                    date: res.invoice_date,
                    className:
                    status == "green"
                    ? "dotcheckincircle green"
                    : status == "red"
                    ? "dotcheckincircle red"
                    : status == "orange"
                    ? "dotcheckincircle orange"
                    : status == ""
                    ? "dotcheckincircle"
                    : "dotcheckincircle present",
                      
                        
                  };
                })
                setFullMonthData(obj);
                
               // console.log("setfullmonth",fullMonthData) 
               }

              })
              .catch((err) => {
              console.log(err);
            }).finally(()=>{
              handleLoader(false);
            });
          };




          const plantChangeHandler = (code) => {
            setSelectedPlant(code)
     
            // let currMonth = month+1
            
            GetFullMonthList(month, year, code);

              }

            const handleMonthChange = (payload) => {

              let smonth = Number(payload.startStr.slice(5, 7)) ;
              let syear = Number(payload.startStr.slice(0,4))
              let emonth = Number(payload.endStr.slice(5, 7)) ;
              let eyear = Number(payload.endStr.slice(0,4))
             let monthmh;
             let yearmh;
              if((smonth == 12 && emonth == 1) || (smonth == 12 && emonth == 2 )){
                  monthmh = 1
                  yearmh = eyear
                      setMonth(1)
                      setYear(eyear)
              }else if((smonth == 11 && emonth == 1) || (smonth ==11 && emonth == 12)){
                monthmh = 12
                yearmh = syear
                        setMonth(12)
                        setYear(syear)
              }else {
                monthmh = Math.ceil((smonth+emonth)/2)
                yearmh = syear
                setMonth(Math.ceil((smonth+emonth)/2))
                setYear(syear)
              }
              
           
             
              GetFullMonthList(monthmh, yearmh, selectedPlant);
            
            };
            // (function () {
            //   selectLoader ? handleLoader(false) : handleLoader();
            // })();
          return(
              <div className="container" style={{width: "auto", height:"90%"}}>
          
            
          {/* <div className="col-md-4 col-lg-2 px-lg-0 mb-3 mb-lg-0 d-flex justify-content-end align-items-end"> */}
                  
                              <div >
                    
       <div  class="col-sm-3 classplant">                
    <div 
    className={styles["classplant"]}
            >
              <div className={styles["summary-portal-label"]}>
                <b>Plant :   </b>
              </div>
              <div className={styles["valueboxone"]}>
              <div style={{ display: "flex", gap: "2px" }}>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
              }}
             
               
              value={selectedPlant}
               classNamePrefix="select"
              onChange={(e) => {
                
                 plantChangeHandler(e.target.value);
              }}
            >
           
             
             <option value={"All"} label={"ALL"}/>:
            
              {plant.length >=0  &&
                plant?.map((el, index) => {
                  return <option  key={index} value={el.plant_id} label={el.plant_name +"(" + el.plant_code +")"} />;
                })}
     
            </select>
           
           
          </div>
              </div>
             
            </div>
            </div>
            <div class="invoice-indicator">
                          <div class="chkgreen">
                            <span></span> Fully Tagged
                          </div>
                          <div class="chkorange">
                            <span></span> Partially Tagged
                          </div>
                          <div class="chkred">
                            <span></span> Fully Untagged
                          </div>
                        </div>
                    <div class="checkinCalboxx">
                        <FullCalendar
                          headerToolbar={{
                            start: "title",
                            center: "",
                            end: "prev next",
                          }}
                          plugins={[dayGridPlugin, interactionPlugin]}
                          eventContent={renderEventContent}
                          dateClick={(e) => {
                         //   handleDateClick(e);
                          }}
                          
                          datesSet={(e) => {
                            handleMonthChange(e);
                          }}
                          events={fullMonthData}
                          selectable={true}
                         
                        />
                          <div class="invoice-indicator">
                          <div class="chkgreen">
                            <span></span> Fully Tagged
                          </div>
                          <div class="chkorange">
                            <span></span> Partially Tagged
                          </div>
                          <div class="chkred">
                            <span></span> Fully Untagged
                          </div>
                        </div>
                    
                      </div>
                    </div>
                  </div>
            
        
)
    
}


export default Summary;