import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getAttendance = async (from_date, to_date, onDash) => {
  let flag = onDash !== undefined ? true : false;
  return axios.get(
    `${AUTH_BASE_URL}/attendance/get_my_subordinates_attendance_v2?from_date=${from_date}&to_date=${to_date}&onDash=${flag}`
  );
};

export const getApproval = async (delegate) => {
  return axios.get(`${AUTH_BASE_URL}/approval_assignment?delegate=${delegate}`);
};

export const getTravelList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse?${queryParm}`);
};
export const getCommonApproval = async (
  sub_type_id,
  page_no,
  page_size,
  sortby,
  paginate,
  search,
  is_exception
) => {
  return axios.get(
    `${AUTH_BASE_URL}/approval_assignment?sub_type_id=${sub_type_id}&page_no=${page_no}&page_size=${page_size}&sort_by=${sortby}&paginate=${paginate}&search=${search}${is_exception == undefined || is_exception == "" || is_exception == null
      ? ""
      : `&is_exception=${is_exception}`
    }`
  );
};

export const getBirthdayList = async () => {
  return axios.get(`${AUTH_BASE_URL}/birthday`);
};

export const saveBirthdayRemarks = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/birthday`, data);
};

export const saveWorkAnniversaryRemarks = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/work-anniversary`, data);
};

export const getHolidaysList = async (paginate, month, year) => {
  return axios.get(
    `${AUTH_BASE_URL}/holiday_calendar/state_id?paginate=${paginate}&month=${month}&year=${year}&sort_by=day_date`
  );
};

export const getMeetings = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/meetings`, data);
};

export const getTodoList = async () => {
  return axios.get(`${AUTH_BASE_URL}/todo`);
};

export const getCourseList = async () => {
  return axios.get(`${AUTH_BASE_URL}/course`);
};

export const changeSkipStatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/course/skipCourse`, data);
};

export const getActivityList = async (page_no, page_size) => {
  return axios.get(
    `${AUTH_BASE_URL}/my-team-activity?page_no=${page_no}&page_size=${page_size}`
  );
};

export const getWorkAnniversaryList = async () => {
  return axios.get(`${AUTH_BASE_URL}/work-anniversary`);
};

export const getDocumentTypeList = async () => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/document-type/list`);
};

export const getRejectedPlan = async () => {
  return axios.get(`${AUTH_BASE_URL}/rejected-plans`);
};

export const saveNotificationToken = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift/save_token`, data);
};

export const getProfilePicSync = async () => {
  return axios.get(`${AUTH_BASE_URL}/users/sync_sf_profile_pic`);
};

// export const getLeaveBalanceSync = async (data) => {
//   return axios.get(`${AUTH_BASE_URL}/ess/getselfleavebalance`);
// };
