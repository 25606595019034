import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { toast } from "react-toastify";
import * as ApiService from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import * as PolicyService from "../../service/policy";
import * as BrandService from "../../service/brandService";

function UserCertificationReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dept, setdeptData] = useState([]);
  const [branch, setBranchData] = useState([]);
  const [currentTabType, setCurrentTabType] = useState("P");
  const [userOptions, setUserOptions] = useState([]);
  const [show, filtersetShow] = useState(false);
  const [deptVal, setDeptVal] = useState([]);
  const [branchVal, setBranchVal] = useState([]);
  const [filterReport, setFilterReport] = useState({
    department: "",
    certified: "",
    business_unit: "",
  });
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);

  const filteropen = () => {
    getFilterData();
    filtersetShow(true);
  };
  const filterclose = () => filtersetShow(false);

  const handleKey = (
    search,
    page_no,
    page_size,
    paginate,
    department,
    certified,
    business_unit
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (department !== "" && department !== undefined && department !== null) {
      queryParm = queryParm + "&department=" + department;
    }
    if (certified !== "" && certified !== undefined && certified !== null) {
      queryParm = queryParm + "&certified=" + certified;
    }
    if (
      business_unit !== "" &&
      business_unit !== undefined &&
      business_unit !== null
    ) {
      queryParm = queryParm + "&business_unit=" + business_unit;
    }
    return queryParm;
  };

  const getUserData = (
    search,
    page_no,
    page_size,
    paginate,
    department,
    certified,
    business_unit
  ) => {
    // dispatch(setDisplayLoader("Display"));

    ApiService.getUserCertificationData(
      handleKey(
        search,
        page_no,
        page_size,
        paginate,
        department,
        certified,
        business_unit
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getUserData(
      "",
      1,
      pageSizeNo,
      true,
      filterReport.department,
      filterReport.certified,
      filterReport.business_unit
    );
  }, [pageSizeNo]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getUserData(
      searchStr,
      activePage,
      pageSizeNo,
      true,
      filterReport.department,
      filterReport.certified,
      filterReport.business_unit
    );
  };
  const handleSearch = (text) => {
    if (text.length > 2 || text.length === 0) {
      setSearchStr(text);
      getUserData(
        text,
        1,
        pageSizeNo,
        true,
        filterReport.department,
        filterReport.certified,
        filterReport.business_unit
      );
    }
  };

  const mainDataFilter = () => {
    const department = filterReport.department;
    const certified = filterReport.certified;
    const business_unit = filterReport.business_unit;
    getUserData(
      "",
      currentPage,
      pageSizeNo,
      true,
      department,
      certified,
      business_unit
    );
    filterclose();
  };

  const resetFilter = () => {
    filterclose();
    setFilterReport({
      ...filterReport,
      department: "",
      certified: "",
      business_unit: "",
    });
    getUserData("", currentPage, pageSizeNo, true, "", "", "");
  };

  const getExportDetails = () => {
    dispatch(setDisplayLoader("Display"));
    ApiService.UserCertificationDataExport(
      handleKey(
        searchStr,
        currentPage,
        pageSizeNo,
        true,
        filterReport?.department,
        filterReport?.certified,
        filterReport?.business_unit
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "UserCertificationReport.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message
        );
        console.log("error", error);
      });
  };

  const getFilterData = () => {
    getDepartment();
    getBranch();
  };

  const getDepartment = () => {
    PolicyService.getDepartment("")
      .then((response) => {
        let data = [];
        response?.data?.dataList?.map((x) => {
          let data2 = { value: x.id, label: x.name + " (" + x.code + ")" };
          data.push(data2);
        });
        setdeptData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getBranch = async () => {
    await BrandService.getBranch(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">User Certification Report</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th>Designation</th>
                      <th>Business Unit</th>
                      <th>Certified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((res) => {
                        return (
                          <tr>
                            <td>{res?.username}</td>
                            <td>
                              {res?.first_name} {res?.last_name}
                            </td>
                            <td>{res?.department_name}</td>
                            <td>{res?.designation_name}</td>
                            <td>{res?.branch_name}</td>
                            {res?.certified === true ? (
                              <td>Yes</td>
                            ) : (
                              <td>No</td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>No Records Found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onClick={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Business Unit<span class="text-danger"></span>
            </label>
            <Select
              // isMulti
              options={branch}
              className="basic-multi-select"
              classNamePrefix="select"
              value={branchVal}
              onChange={(e) => {
                setFilterReport({
                  ...filterReport,
                  business_unit: e.value,
                });
                setBranchVal(e);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Department<span class="text-danger"></span>
            </label>
            <Select
              // isMulti
              options={dept}
              className="basic-multi-select"
              classNamePrefix="select"
              value={deptVal}
              onChange={(e) => {
                setFilterReport({
                  ...filterReport,
                  department: e.value,
                });
                setDeptVal(e);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Certified<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              value={filterReport.certified}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  certified: e.target.value,
                })
              }
            >
              <option>Select</option>
              <option value={"Y"}>Yes</option>
              <option value={"N"}>No</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserCertificationReport;
