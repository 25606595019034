import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getCommonApproval } from "../../service/dashboard";
import { Spinner } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import * as DomesticService from "../../service/domestic";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { ApproveOtherAdv } from "..";

function ApplyOtherAdv() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const userData = useSelector(selectUserData);
  const [userDataNew, setUserDataNew] = useState(false);
  const [remError, setRemError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [id, setid] = useState(location?.state?.from);
  const [udata, setUdata] = useState();
  const [travelDetails, setTravelDetails] = useState([]);
  const [dataNew, setDataNew] = useState([]);
  const [Data, setData] = useState();
  const [error, seterror] = useState(false);
  const [newOtherData, setNewOtherData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [chkLength, setChkLength] = useState(false);
  const [remarksData, setRemarksData] = useState({
    id: "",
    status: "",
    remark: "",
  });
  const userDetails = useSelector(selectUserData);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
    is_exception: false,
    is_exception_error: false,
  });
  const [formValidation, setFormValidation] = useState({
    remark: "",
  });

  //   if (data.remarks != "" || data?.status === "A") {
  //     setIsLoading(true);
  //     changeVPFRequestStatus(data)
  //       .then((response) => {
  //         getAppliedVPFRequest(location?.state?.from);
  //         setData({
  //           status: "",
  //           remarks: "",
  //         });
  //         setIsLoading(false);
  //         toast.success(response.data.message);
  //         window.history.go(-1);
  //         return false;
  //       })
  //       .catch((error) => {
  //         toast.success(error.response.data.detail);
  //         setIsLoading(false);
  //         toast.error(
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //             "failed"
  //         );
  //       });
  //   } else {
  //     seterror(true);
  //   }
  // };
  const submitAction = (id, stat, datas) => {
    if (datas?.remarks !== "") {
      setRemError(false);
      let fdd = [
        {
          id: id,
          status: stat,
          remark: status?.remarks,
        },
      ];
      let ndd = {
        approval_data: fdd,
      };
      TravelListingAPI.approveOtherExpFinal(ndd)
        .then((response) => {
          // condition = "false";
          if (response?.data.code == 200) {
            toast.success("Other Advance Submittted successfully");
            if(location?.state?.dataCount === 1) window.history.go(-2);
            window.history.go(-1);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      setRemError(true);
    }
  };
  const submitActionRej = (id, stat, datas) => {
    if (datas?.remarks !== "") {
      setRemError(false);
      let fdd = [
        {
          id: id,
          status: stat,
          remark: status?.remarks,
        },
      ];
      let ndd = {
        approval_data: fdd,
      };
      TravelListingAPI.approveOtherExpFinal(ndd)
        .then((response) => {
          // condition = "false";
          if (response?.data.code == 200) {
            toast.error("Other Advance Rejected");
            window.history.go(-1);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      setRemError(true);
    }
  };

  useEffect(() => {
    console.log({ userDataNew });
  }, [userDataNew]);
  const saveExpenseApproveData = (value) => {
    setIsLoading(true);
    let arrOtherExpense = { approval_data: [] };
    let data = remarksData;
    data.id = id;
    data.status = value;
    data.remark = remarksData.remark;
    arrOtherExpense?.approval_data?.push(data);
    TravelListingAPI.saveExpenseApproveData(arrOtherExpense)
      .then((response) => {
        toast.success(response.data.message);
        setIsLoading(false);
        navigate(`/common-approve/${location?.state?.subId}`, {
          state: {
            subtypeid: location?.state?.subId,
            subTypeName: "Travel Expense",
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getApproval = (
    sub_type_id,
    page_no,
    page_size,
    sortby,
    paginate,
    search,
    is_exception
  ) => {
    getCommonApproval(
      sub_type_id,
      page_no,
      page_size,
      sortby,
      paginate,
      search,
      is_exception
    )
      .then((response) => {
        setTravelDetails(
          response?.data?.dataList?.result?.approvals?.result[0]?.details[0]
        );
        setUdata(response?.data?.dataList?.result?.approvals?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!remarksData.remark) {
      isError = true;
      formerr.remark = "Remarks Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const handleSaveValue = (value) => {
    if (handleValidate()) return false;
    saveExpenseApproveData(value);
  };
  const getAdvanceDetails = (page_no, page_size, paginate, obj_id) => {
    console.log(obj_id, "omg");
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getAdvanceDetails(
      handleKey(page_no, page_size, paginate, obj_id)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setUserDataNew(response?.data?.dataList?.result);

        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        // dispatch(setMessage(response?.data?.dataList?.result));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    page_no,
    page_size,

    paginate,

    obj_id
  ) => {
    console.log(obj_id, "hiobj");

    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;

    if (obj_id !== "" && obj_id !== undefined && obj_id !== null) {
      queryParm = queryParm + "&obj_id=" + obj_id;
    }

    return queryParm;
  };

  useEffect(() => {
    getApproval(state?.subId, 1, 10, "-id", true, "", "");
  }, []);
  {
    console.log(state, "plat");
  }
  useEffect(() => {
    if (state?.otherExpData !== "") {
      getAdvanceDetails(
        1,
        pageSizeNo,
        true,
        state?.otherExpData?.transaction_id
      );
      setNewOtherData(state?.otherExpData);
    }
  }, [state]);
  // useEffect(() => {
  //   {
  //     userDataNew[0]?.approval_list
  //       ?.filter((ele) => ele.status === "A")
  //       ?.map((data, index) => (
  //         <>
  //           {console.log(data, "mynew")}
  //           {data !== undefined || data !== null
  //             ? setChkLength(false)
  //             : setChkLength(true)}
  //         </>
  //       ));
  //   }
  // }, [userDataNew]);

  useEffect(() => {
    getUsersDetails(userData?.username);
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Requester</label>
                      <p>{newOtherData?.created_by_name}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Requested At</label>
                      <p>
                        {moment(newOtherData?.created_at).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Leave Type</label>
                      <p>{Data?.leave_type__name}</p>
                    </div>
                  </div> */}
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Advance Amount</label>
                      <p>{newOtherData?.details[0]?.advance_amount}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Settlement Date</label>
                      <p>
                        {moment(
                          newOtherData?.details[0]?.advance_amount_date
                        ).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>No. Of Days</label>
                      <p>{Data?.leave_duration}</p>
                    </div>
                  </div> */}
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>From Type</label>
                      <p>
                        {(Data?.from_type === "F" ||
                          Data?.from_type === "First") &&
                          "First Half"}
                      </p>
                    </div>
                  </div> */}
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>To Type</label>
                      <p>
                        {(Data?.to_type === "S" ||
                          Data?.to_type === "Second") &&
                          "Second Half"}
                      </p>
                    </div>
                  </div> */}
                  {/* {console.log(user)} */}
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Reason</label>
                      <p>{newOtherData?.details[0]?.reason_advance_amount}</p>
                    </div>
                  </div>
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Remarks</label>
                      <p>{Data?.remarks}</p>
                    </div>
                  </div> */}
                  {/* <div class="col-md-8">
                    <div class="form-group innergroup">
                      <label>Download Attachments</label>
                      <p>
                        {Data?.attachment !== undefined &&
                          Data?.attachment.map((a) => (
                            <a
                              href={a?.attachment}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span class="mr-3">
                                {a?.attachment
                                  ?.split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                  <iframe
                                    width="100"
                                    height="100"
                                    src={a.attachment}
                                  ></iframe>
                                ) : a?.attachment
                                    ?.split("/")
                                    .pop()
                                    .includes(".mp4") ? (
                                  <video controls>
                                    <source
                                      src={a.attachment}
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={a?.attachment}
                                    alt="image"
                                    width="70px"
                                    height="70px"
                                  />
                                )}
                              </span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div> */}
                </>
              </div>
            </div>
            {console.log(chkLength, "okbyelength")}

            {chkLength === true ? (
              ""
            ) : (
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th> Date </th>
                      <th> Approved By </th>
                      <th>Status</th>
                      <th> Remarks</th>
                    </tr>

                    {userDataNew[0]?.approval_list?.length ? userDataNew[0]?.approval_list
                      ?.filter((ele) => ele.status === "A" || ele.status === 'R')
                      ?.map((data, index) => (
                        <tr>
                          <td>
                            {moment(data?.updated_at)
                              .utc()
                              .format("DD-MM-YYYY h:m:s")}
                          </td>
                          <td>
                            {data?.full_name} ({data?.username})
                          </td>
                          <td>
                            {data?.status === "A"
                              ? "Approved"
                              : data?.status === "P"
                                ? "Pending"
                                : data?.status === "R"
                                  ? "Rejected"
                                  : data?.status === "D"
                                    ? "Rejected"
                                    : ""}
                          </td>
                          <td>{data?.remark}</td>
                        </tr>
                      )): <tr><td colSpan={4} className="d-flex justify-content-center">No record found</td></tr>}
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            )}

            <>
              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label></label>
                  <textarea
                    onChange={(e) => {
                      setStatus((prev) => ({
                        ...prev,
                        remarks: e.target.value,
                        is_exception_error: false,
                      }));
                      seterror(false);
                      setRemError(false);
                    }}
                    class="form-control"
                    placeholder="Remarks..."
                  ></textarea>
                  {console.log(remError, "plank")}
                  {remError === true ? (
                    <div className="small text-danger">Kindly give Remarks</div>
                  ) : (
                    ""
                  )}
                  {/* */}
                </div>
              </div>

              <div class="col-md-12 pb-3">
                <button
                  class="btn btn-outline-secondary mr-2"
                  onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                >
                  <i class="fas fa-arrow-circle-left"></i> Cancel
                </button>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        submitActionRej(
                          newOtherData?.details[0]?.id,
                          "R",
                          status
                        );
                      }}
                      class="btn btn-outline-danger mr-2"
                    >
                      <i class="far fa-times-circle"></i> Reject
                    </button>

                    <button
                      onClick={() => {
                        submitAction(newOtherData?.details[0]?.id, "A", status);
                      }}
                      class="btn btn-primary-inner bpi-main"
                    >
                      <i class="far fa-check-square"></i> Approve
                    </button>
                  </>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyOtherAdv;
