import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import * as otherExpService from "../../service/otherExpService";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import { toast } from "react-toastify";

function OtherExpUnderProcess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const finalUser = useSelector(selectUserData);
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [show, filtersetShow] = useState(false);
  const [otherExpData, setotherExpData] = useState([]);
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(100);
  const [pageCount, setPageCount] = useState(1);
  const [totalRecord, setTotalRecord] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [travelList, setTravelList] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [orgDropDown, setorgDropDown] = useState([]);
  const [newSSCUser, setnewSSCUser] = useState({
    id: "",
    username: "",
  });
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: "",
  });

  const [filterdata, setfilterData] = useState({
    user_id: { value: 0 },
    id: "",
    organisation: { value: "" },
    from_date: "",
    to_date: "",
  });

  const getOtherExp = (
    status,
    is_picked,
    pageNo,
    pageSize,
    sort_by,
    paginate,
    emp_code,
    org,
    reqid,
    search
  ) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getOtherExp(
        `status=` +
          status +
          `&page_no=` +
          pageNo +
          `&paginate=` +
          paginate +
          `&page_size=` +
          pageSize +
          `&sort_by=` +
          sort_by +
          `&is_picked=` +
          is_picked  +`&is_discarded=false` +
          `&user_id=` +
          (emp_code > 0 ? emp_code : "") +
          `&organisation=` +
          (org ? org : "") +
          `&id=` +
          reqid +
          `&search=` +
          search
      )
      .then((response) => {
        // console.log(`otherexp...`,response);
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        let ress = null;
        // console.log(`-->`,userGroup);
        switch (userGroup._userGroup) {
          case "ssc user":
            // ress=response?.data?.dataList?.result.filter((data)=>{
            //     return !data.ssc_user_status
            // })
            break;
          case "ssc manager":
            // ress = response?.data?.dataList?.result.filter((data) => {
            //   return !data.ssc_user_status && !data.is_discarded;
            // });
            break;
          default:
            break;
        }
        console.log(`log..`, ress);
        setotherExpData(response?.data?.dataList?.result);
        setCurrentPage(response?.data?.dataList?.paginated_data?.currentPage);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getOtherExp("A", true, activePage, pageSizeNo, "-id", true, "", "", "", "");
  };

  const reassignSSC = async (action="") => {
    otherExpService
      .reassignSSC(newSSCUser.id, newSSCUser.username,action)
      .then((res) => {

        getOtherExp(
          "A",
          true,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          filterdata?.user_id.value,
          filterdata?.organisation.value,
          filterdata?.id,
          searchStr
        );
        
        toast.success(`Assigned successfully.`);
        setnewSSCUser({
          id: "",
          username: "",
        });

        handleClose();
      })
      .catch((err) => {
        toast.error(`Unable to reassign`);
      });
  };

  useEffect(() => {
    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager"
      ) {
        localGroup = data?.name.toLowerCase();
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });
    getOtherExp(
      "A",
      true,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      "",
      ""
    );

    getUsersList();
    // getAllOrganisation()
    getBusinessUnits();
  }, [pageSizeNo]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getOtherExp(
        "A",
        true,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        "",
        e.target.value
      );

      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);

    getOtherExp(
      "A",
      true,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      filterdata?.user_id.value,
      filterdata?.organisation.value,
      filterdata?.id,
      searchStr
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getOtherExp(
      "A",
      true,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      "",
      searchStr
    );

    setfilterData({
      organisation: { value: "" },
      id: "",
      user_id: { value: 0 },
      from_date: "",
      to_date: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setorgDropDown(data);
    });
  };

  const getBusinessUnits = () => {
    otherExpService.getBusinessUnits().then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name}( ${x.code} )`,
          };
        }),
      ];
      setorgDropDown(data);
    });
  };

  const getChcekExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    TravelListingAPI.getCheckExpenseList(
      handleKey(search, page_no, page_size, sort_by, paginate, status)
    ).then((response) => {
      setTravelList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate, status) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };

  const getExportValue = (
    search,
    page_no,
    page_Size,
    sort_by,
    paginate,
    status
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCheckExpenseExportValue(
      handleKey(search, page_no, page_Size, sort_by, paginate, status)
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleFileView = () => {
    // setFileObj(data)
    handleShow();
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                    <ul class="policylistnames">
                                        <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                        <li class="active"><Link to="/travel-expense">Check Expense</Link></li>
                                        <li><Link to="/travel-bills">All Bills</Link></li>
                                        <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                        <li><Link to="/travel-km-report">KM Report</Link></li>
                                        <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                        <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                        <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                        <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                        <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                        <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                    </ul>
                                </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Others Under Process List</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* <Dropdown show>
                                                            <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                                <img src="images/export.png" alt="" class="mr-2" /> Export
                                                            </Dropdown.Toggle>
    
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">
                                                                <button
                                                                type="button"
                                                                disabled={travelList.length !== 0 ? false : true}
                                                                style={{ border: 0, background: "none" }}
                                                                onClick={() =>
                                                                    getExportValue(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "pending", )
                                                                }
                                                                >
                                                                <i className="far fa-file-excel"></i>Excel
                                                                </button>
                                                                    </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown> */}
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="draft" id="approvaltabs">
                  <Tab
                    eventKey="draft"
                    title={
                      <React.Fragment>
                        In Process
                        <span class="numberpurchse">{totalRecord}</span>
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>SR. NO.</th>
                            <th>REQ ID</th>
                            <th>EMPLOYEE ID</th>
                            <th>REQUESTOR</th>
                            <th>ESG</th>
                            <th>REQUESTED ON</th>
                            <th>LOCATION</th>
                            <th> AMOUNT</th>
                            <th>DOC. RECEIVED ON</th>
                            <th>PROCESSED BY</th>
                            <th>ACTION</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {otherExpData?.length ? (
                            otherExpData?.map((data, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{data?.request_id}</td>
                                  <td>{data?.user_code}</td>
                                  <td>{data?.user_name}</td>
                                  <td>{data?.user_grade}</td>
                                  <td>
                                    {moment
                                      .parseZone(data?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>{data?.user_location} </td>
                                  <td>{data?.total_bill_amount}</td>
                                  <td>
                                    {moment
                                      .parseZone(data?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {data?.is_picked_by_user_name} (
                                    {data?.is_picked_by_user_code})
                                  </td>
                                  <td>
                                    <Link
                                      to="/otherexp-printview"
                                      state={{
                                        travel_id: data?.id,
                                        backpath: "/otherexp-check",
                                        predata: data,
                                        showPrintData: false,
                                      }}
                                    >
                                      <button
                                        style={{
                                          "background-color": "#0195d4",
                                        }}
                                        class="btn  m-2   text-white"
                                      >
                                        <i class=" p-0.5 fas fa-print"></i>
                                      </button>
                                    </Link>
                                    <button
                                      onClick={() => {
                                        setnewSSCUser({
                                          ...newSSCUser,
                                          id: data?.id,
                                        })
                                        handleFileView();
                                      }}
                                      class="btn border-dark btn-light "
                                    >
                                      Reassign
                                    </button>
                                  </td>

                                  <Modal show={shows} onHide={handleClose}>
                                    <Modal.Header
                                      style={{ "background-color": "#0195d4" }}
                                      className="text-white"
                                      closeButton
                                    >
                                      <Modal.Title>Prompt</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <p>
                                            {" "}
                                            Enter employee code of new SSC User
                                          </p>
                                          <input
                                            onChange={(e) =>
                                              setnewSSCUser({
                                                id: data?.id,
                                                username: e.target.value,
                                              })
                                            }
                                            class="form-control"
                                            type="text"
                                            value={newSSCUser.username}
                                          />
                                        </div>
                                      </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button
                                        style={{
                                          "background-color": "#0195d4",
                                        }}
                                        class="btn "
                                        onClick={()=>reassignSSC("revert_to_pick_list")}
                                      >
                                        Revert to Pick List
                                      </Button>
                                      <Button
                                        style={{
                                          "background-color": "#0195d4",
                                        }}
                                        class="btn "
                                        onClick={()=>reassignSSC("")}
                                      >
                                        Ok
                                      </Button>
                                      <Button
                                        style={{
                                          "background-color": "#0195d4",
                                        }}
                                        class="btn"
                                        onClick={handleClose}
                                      >
                                        Cancel
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Records Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            value={pageSizeNo}
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10 }>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50 }>50</option>
                        <option value={100} >100</option>
                        <option value={200} >200</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Company <span class="text-danger"></span>
            </label>
            <Select
              options={orgDropDown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  organisation: e,
                })
              }
              value={filterdata.organisation}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Req ID <span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  id: e.currentTarget.value,
                })
              }
              value={filterdata.id}
            />
          </div>

          {/* <div class="form-group innergroup">
                            <label>Trip Number <span class="text-danger"></span></label>
                            <Select  
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select" 
                            />
                        </div> */}

          {/* <div class="form-group innergroup">
                        <label>
                            Start Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                from_date: e.currentTarget.value
                            })}
                            value={filterdata.from_date}
                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>
                            End Date <span class="text-danger"></span>
                        </label>
                        <input type="date" class="form-control"
                            onChange={(e) => setfilterData({
                                ...filterdata,
                                to_date: e.currentTarget.value

                            })}
                            value={filterdata.to_date}
                        />
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtherExpUnderProcess;
