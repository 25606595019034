import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import * as leaveDataService from "../../service/leaveService";
import Select from "react-select";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { getUserByReporting } from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { get_sap_schedule } from "../../service/searchpo";

if (typeof window !== "undefined") {
  injectStyle();
}
function MyLeaveApp() {
  const dispatch = useDispatch();
  const [leaveMgmtData, setLeaveMgmtData] = useState([]);
  const [currentTab, setCurrentTab] = useState("P");
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataMList, setDataMList] = useState([]);
  const [dataEmployeeList, setDataEmployeeList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [managementLeaveList, setManagementLeaveList] = useState([]);
  const [userLeaveSummaryData, setuserLeaveSummaryData] = useState([]);
  const [leaveCounts, setLeaveCounts] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    cancelled: 0
  })
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-from_date",
  });
  const [viewDetails, setViewDetails] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [filterValue, setFilterValue] = useState({
    leave_type_id: "",
    approver_name: "",
    employee_id: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [leaveFilterValue, setLeaveFilterValue] = useState({
    leave_type_id: "",
    approver_name: "",
    employee_id: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const userData = useSelector(selectUserData);

  const getLeaveManageDataList = (id) => {
    leaveDataService
      .getLeaveManageDataList(id)
      .then((response) => {
        getEmployeeDataList(0);
        let leaveM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = {
            value: value.leave_type_id,
            label: value.leave_type_name,
          };
          leaveM.push(data);
        });
        setManagementLeaveList(leaveM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEmployeeDataList = (data) => {
    getUserByReporting(userData?.id, false, "id", true, true)
      .then((response) => {
        let employeeM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = {
            value: value.id,
            label: `${value?.first_name} ${value?.last_name} (${value.username})`,
          };
          employeeM.push(data);
        });
        setEmployeeDetails(employeeM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetail = (
    search,
    leave_type_id,
    approver_name,
    employee_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    leaveDataService
      .getExportDetail(
        handleKey(
          search,
          leave_type_id,
          approver_name,
          employee_id,
          from_date,
          to_date,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Leave.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    leave_type_id,
    approver_name,
    from_date,
    to_date,
    employee_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&for_approval=" +
      true;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      leave_type_id !== "" &&
      leave_type_id !== undefined &&
      leave_type_id !== null
    ) {
      queryParm = queryParm + "&leave_type_id=" + leave_type_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      employee_id !== "" &&
      employee_id !== undefined &&
      employee_id !== null
    ) {
      queryParm = queryParm + "&employee_id=" + employee_id;
    }
    console.log(queryParm);
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getLeaveDataList(
        e.target.value.toLowerCase(),
        leaveFilterValue.leave_type_id,
        leaveFilterValue?.approver_name,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.employee_id,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getLeaveDataList(
        data,
        leaveFilterValue.leave_type_id,
        leaveFilterValue?.approver_name,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.employee_id,
        currentTab,
        1,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
    }
  };

  const handlePushData = (e) => {
    setLeaveFilterValue({
      ...leaveFilterValue,
      leave_type_id: e.value,
    });
    setDataMList(e);
  };

  const pushEmployee = (e) => {
    setLeaveFilterValue({
      ...leaveFilterValue,
      employee_id: e.value,
    });
    setDataEmployeeList(e);
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue?.approver_name,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.employee_id,
      currentTab,
      1,
      pageSizeNo,
      sdata?.sort_by,
      true
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue?.approver_name,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.employee_id,
      currentTab,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const mainDataFilter = () => {
    if (
      leaveFilterValue?.leave_type_id !== "" ||
      leaveFilterValue?.employee_id !== "" ||
      leaveFilterValue?.from_date !== "" ||
      leaveFilterValue?.to_date !== ""
    ) {
      setFilterApplied(true);
    }
    setFilterValue({
      ...filterValue,
      status: leaveFilterValue.status,
      leave_type_id: leaveFilterValue.leave_type_id,
      approver_name: leaveFilterValue.approver_name,
      employee_id: leaveFilterValue.employee_id,
      from_date: leaveFilterValue.from_date,
      to_date: leaveFilterValue.to_date,
    });
    const status = leaveFilterValue.status;
    const leaveType = leaveFilterValue.leave_type_id;
    const approverName = leaveFilterValue.approver_name;
    const employee = leaveFilterValue.employee_id;
    const fromDate = leaveFilterValue.from_date;
    const toDate = leaveFilterValue.to_date;
    if (leaveFilterValue?.employee_id !== "") {
      getUserLeaveSummary(leaveFilterValue?.employee_id, "", "")
    }
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue?.approver_name,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.employee_id,
      currentTab,
      1,
      pageSizeNo,
      "-id",
      true
    );
    filterClose();
  };

  const getUserLeaveSummary = (id) => {
    leaveDataService
      .getUserLeaveSummary(id, "", true)
      .then((response) => {
        let data = response?.data?.dataList?.leave_type_summary?.map((each) => {
          return {
            leave_name: each.leave_type_name,
            balance: each?.bal,
            availed: each?.avail
          }
        })
        setuserLeaveSummaryData(data);
        if (id === undefined) {
          setLeaveCounts((prev) => ({
            ...prev,
            pending: response?.data?.dataList?.leave_status?.pending,
            approved: response?.data?.dataList?.leave_status?.approved,
            rejected: response?.data?.dataList?.leave_status?.rejected,
            cancelled: response?.data?.dataList?.leave_status?.cancelled
          }))
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue?.approver_name,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.employee_id,
      currentTab,
      1,
      pageSizeNo,
      "-id",
      true
    );
  }, [currentTab]);

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(leaveFilterValue));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setLeaveFilterValue(ClearData);
    filterClose();
    setDataMList([]);
    setDataEmployeeList([]);
    getLeaveDataList(
      searchStr,
      "",
      "",
      "",
      "",
      "",
      currentTab,
      1,
      pageSizeNo,
      "-id",
      true
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const approveApplyLeave = (idx, status) => {
    leaveDataService
      .approveApplyLeave(idx, status)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleApprove = (id) => {
    confirmAlert({
      title: "Approve Apply Leave",
      message: `Are you sure to Approve ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => approveApplyLeave(id, "A"),
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    getUserLeaveSummary();
    getSapSchedule();
  }, []);

  const getSapSchedule = () => {
    get_sap_schedule('sync_apply_leave_balance_from_savior').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  useEffect(() => {
    if (userData?.id !== undefined) {
      dispatch(setDisplayLoader("Display"));
      getLeaveDataList(
        searchStr,
        leaveFilterValue.leave_type_id,
        leaveFilterValue?.approver_name,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue?.employee_id,
        currentTab,
        1,
        pageSizeNo,
        "-id",
        true
      );
    }
  }, [pageSizeNo, userData]);

  const getLeaveDataList = (
    search,
    leave_type_id,
    approver_name,
    from_date,
    to_date,
    employee_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    leaveDataService
      .getLeaveDataList(
        handleKey(
          search,
          leave_type_id,
          approver_name,
          from_date,
          to_date,
          employee_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setLeaveMgmtData(response?.data?.dataList);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        getLeaveManageDataList(userData?.id);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const displayLogs = (data) => {
    let dates = [];
    let finalData = data?.filter((each) => {
      if (
        dates.includes(each?.leave_date) === false &&
        each?.sync_date !== null
      ) {
        dates.push(each?.leave_date);
        return true;
      }
    });
    setDetailsData(finalData);
  };

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/manage-leave">Leave Master</Link>
            </li>
            <li>
              <Link to="/leave-rule">Leave Rule</Link>
            </li>
            <li>
              <Link to="/allocation-leave">Leave Allocation</Link>
            </li>
            <li>
              <Link to="/my-leave">My Leaves</Link>
            </li>
            <li>
              <Link to="/bulk-leave">Leave Bulk Upload</Link>
            </li>
            <li class="active">
              <Link to="/leave-approval">For Approval Leaves</Link>{" "}
            </li>
            <li>
              <Link to="/leave-backdate">Back Date Entry Exception</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-7">
                  <h4 class="inner-page-title">My Team Leave Report</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                    <Breadcrumb.Item active>My Leaves</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div className="col-md-5">
                  <div className="d-flex" style={{
                    fontSize: '14px',
                    justifyContent: 'space-between'
                  }}>
                    {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                      <>
                        <div>
                          <span className="fw-bold text-theme ml-2">{each?.leave_name}</span>
                          <ul className="ml-4">
                            <li><span>Balance - {each?.balance}</span></li>
                            <li><span>Availed - {each?.availed}</span></li>
                          </ul>
                        </div>
                        <br />
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong> Leave Type,</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="searchbar"
                        placeholder="Search on Leave Type"
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetail(
                                searchStr,
                                leaveFilterValue.leave_type_id,
                                leaveFilterValue.approver_name,
                                leaveFilterValue.employee_id,
                                leaveFilterValue.from_date,
                                leaveFilterValue.to_date,
                                currentTab,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next Savior Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs
                defaultActiveKey="P"
                id="approvaltabs"
                onSelect={(e) => {
                  setCurrentTab(e);
                  setPageSizeNo(10);
                  setCurrentPage(1);
                }}
              >
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      Pending
                      <span class="numberpurchse">
                        {leaveCounts?.pending !== undefined
                          && leaveCounts?.pending}
                      </span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>Pending From</th>
                          <th
                            onClick={(e) => {
                              handlerData("requested_by_id");
                            }}
                            className={
                              sortData["requested_by_id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["requested_by_id"]
                            }
                          >
                            Requested By
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_type__name");
                            }}
                            className={
                              sortData["leave_type__name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_type__name"]
                            }
                          >
                            Leave Type
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("from_date");
                            }}
                            className={
                              sortData["from_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["from_date"]
                            }
                          >
                            From
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("to_date");
                            }}
                            className={
                              sortData["to_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["to_date"]
                            }
                          >
                            To
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_duration");
                            }}
                            className={
                              sortData["leave_duration"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_duration"]
                            }
                          >
                            Total Leaves
                          </th>
                          <th>Reason</th>
                          <th
                            onClick={(e) => {
                              handlerData("status");
                            }}
                            className={
                              sortData["status"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["status"]
                            }
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveMgmtData?.pending?.length > 0 &&
                          leaveMgmtData?.pending?.map((data, index) => (
                            <tr>
                              {/* <td>
                              <button
                                class="btn btn-blanktd text-success"
                                // onClick={() => handleApprove(data.id)}
                              >
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td> */}
                              <td>
                                {userData?.first_name +
                                  " " +
                                  userData?.last_name +
                                  " (" +
                                  userData?.username +
                                  ")"}
                              </td>
                              <td>{data?.requested_by}</td>
                              <td>{data?.leave_type_name}</td>
                              <td className="text-center">
                                {`${moment(data?.from_date).format(
                                  "DD-MM-YYYY"
                                )}
                                  ${data?.from_type !== ""
                                    ? data?.from_type === "First" ||
                                      data?.from_type === "F"
                                      ? " | F"
                                      : data?.from_type === "Second" ||
                                        data?.from_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td className="text-center">
                                {`${moment(data?.to_date).format("DD-MM-YYYY")}
                              ${data?.to_type !== ""
                                    ? data?.to_type === "First" ||
                                      data?.to_type === "F"
                                      ? " | F"
                                      : data?.to_type === "Second" ||
                                        data?.to_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td>{data?.leave_duration} Days</td>
                              <td>
                                {data?.reasons
                                  ?.map((obj) => {
                                    return obj?.reason;
                                  })
                                  .toString()}
                              </td>
                              <td>
                                {data?.status === "D"
                                  ? "Rejected"
                                  : data?.status === "A"
                                    ? "Approved"
                                    : "Pending"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {leaveMgmtData?.pending?.length == 0 ? (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="A"
                  title={
                    <React.Fragment>
                      Approved
                      <span class="numberpurchse">
                        {leaveCounts?.approved !== undefined
                          && leaveCounts?.approved}
                      </span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Approved By</th>
                          <th
                            onClick={(e) => {
                              handlerData("requested_by_id");
                            }}
                            className={
                              sortData["requested_by_id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["requested_by_id"]
                            }
                          >
                            Requested By
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_type__name");
                            }}
                            className={
                              sortData["leave_type__name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_type__name"]
                            }
                          >
                            Leave Type
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("from_date");
                            }}
                            className={
                              sortData["from_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["from_date"]
                            }
                          >
                            From
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("to_date");
                            }}
                            className={
                              sortData["to_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["to_date"]
                            }
                          >
                            To
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_duration");
                            }}
                            className={
                              sortData["leave_duration"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_duration"]
                            }
                          >
                            Total Leaves
                          </th>
                          <th>Reason</th>
                          <th>Status</th>
                          <th>Savior Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveMgmtData?.approved?.length > 0 &&
                          leaveMgmtData?.approved?.map((data, index) => (
                            <tr>
                              <td>
                                {userData?.first_name +
                                  " " +
                                  userData?.last_name +
                                  " (" +
                                  userData?.username +
                                  ")"}
                              </td>
                              <td>{data?.requested_by}</td>
                              <td>{data?.leave_type_name}</td>
                              <td className="text-center">
                                {`${moment(data?.from_date).format(
                                  "DD-MM-YYYY"
                                )}
                                  ${data?.from_type !== ""
                                    ? data?.from_type === "First" ||
                                      data?.from_type === "F"
                                      ? " | F"
                                      : data?.from_type === "Second" ||
                                        data?.from_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td className="text-center">
                                {`${moment(data?.to_date).format("DD-MM-YYYY")}
                              ${data?.to_type !== ""
                                    ? data?.to_type === "First" ||
                                      data?.to_type === "F"
                                      ? " | F"
                                      : data?.to_type === "Second" ||
                                        data?.to_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td>{data?.leave_duration} Days</td>
                              <td>
                                {data?.reasons
                                  ?.map((obj) => {
                                    return obj?.reason;
                                  })
                                  .toString()}
                              </td>
                              <td>
                                {data?.status === "D"
                                  ? "Rejected"
                                  : data?.status === "A"
                                    ? "Approved"
                                    : "Pending"}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-blanktd text-primary ml-4"
                                  onClick={() => {
                                    setViewDetails(true);
                                    displayLogs(data?.leave_detail_status);
                                  }}
                                >
                                  <img src="images/eye.png" alt="" />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {leaveMgmtData?.approved?.length == 0 ? (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>

                <Tab
                  eventKey="D"
                  title={
                    <React.Fragment>
                      Rejected{" "}
                      <span class="numberpurchse">
                        {leaveCounts?.rejected !== undefined
                          && leaveCounts?.rejected}
                      </span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Rejected By</th>
                          <th
                            onClick={(e) => {
                              handlerData("requested_by_id");
                            }}
                            className={
                              sortData["requested_by_id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["requested_by_id"]
                            }
                          >
                            Requested By
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_type__name");
                            }}
                            className={
                              sortData["leave_type__name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_type__name"]
                            }
                          >
                            Leave Type
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("from_date");
                            }}
                            className={
                              sortData["from_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["from_date"]
                            }
                          >
                            From
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("to_date");
                            }}
                            className={
                              sortData["to_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["to_date"]
                            }
                          >
                            To
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_duration");
                            }}
                            className={
                              sortData["leave_duration"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_duration"]
                            }
                          >
                            Total Leaves
                          </th>
                          <th>Reason</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveMgmtData?.rejected?.length > 0 &&
                          leaveMgmtData?.rejected?.map((data, index) => (
                            <tr>
                              <td>
                                {userData?.first_name +
                                  " " +
                                  userData?.last_name +
                                  " (" +
                                  userData?.username +
                                  ")"}
                              </td>
                              <td>{data?.requested_by}</td>
                              <td>{data?.leave_type_name}</td>
                              <td className="text-center">
                                {`${moment(data?.from_date).format(
                                  "DD-MM-YYYY"
                                )}
                                  ${data?.from_type !== ""
                                    ? data?.from_type === "First" ||
                                      data?.from_type === "F"
                                      ? " | F"
                                      : data?.from_type === "Second" ||
                                        data?.from_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td className="text-center">
                                {`${moment(data?.to_date).format("DD-MM-YYYY")}
                              ${data?.to_type !== ""
                                    ? data?.to_type === "First" ||
                                      data?.to_type === "F"
                                      ? " | F"
                                      : data?.to_type === "Second" ||
                                        data?.to_type === "S"
                                        ? " | S"
                                        : ""
                                    : ""
                                  }`}
                              </td>
                              <td>{data?.leave_duration} Days</td>
                              <td>
                                {data?.reasons
                                  ?.map((obj) => {
                                    return obj?.reason;
                                  })
                                  .toString()}
                              </td>
                              <td>
                                {data?.status === "D"
                                  ? "Rejected"
                                  : data?.status === "A"
                                    ? "Approved"
                                    : "Pending"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {leaveMgmtData?.rejected?.length == 0 ? (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Leave Type</label>
            <Select
              options={managementLeaveList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => handlePushData(e)}
              value={dataMList}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>For Approval</label>
            <select
              class="form-control newbgselect"
              name="approver_name"
              onChange={(e) =>
                setLeaveFilterValue({
                  ...leaveFilterValue,
                  approver_name: e.target.value,
                })
              }
              value={leaveFilterValue.approver_name}
            >
              <option value="">Select</option>
              <option value="HOD">HOD</option>
              <option value="RM">Reporting Manager</option>
              <option value="HR">HR</option>
            </select>
          </div> */}

          <div class="form-group innergroup">
            <label>Employee Name</label>
            <Select
              options={employeeDetails}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                pushEmployee(e);
              }}
              value={dataEmployeeList}
            />
          </div>

          <div class="form-group innergroup">
            <label>From Date</label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) =>
                setLeaveFilterValue({
                  ...leaveFilterValue,
                  from_date: e.target.value,
                  to_date: "",
                })
              }
              value={leaveFilterValue.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              type="date"
              name="to_date"
              class="form-control"
              min={moment(leaveFilterValue?.from_date).format("YYYY-MM-DD")}
              onChange={(e) =>
                setLeaveFilterValue({
                  ...leaveFilterValue,
                  to_date: e.target.value,
                })
              }
              value={leaveFilterValue.to_date}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={(e) =>
                setLeaveFilterValue({
                  ...leaveFilterValue,
                  status: e.target.value,
                })
              }
              value={leaveFilterValue.status}
            >
              <option value="">Select</option>
              <option value="P">Pending</option>
              <option value="A">Approved</option>
              <option value="D">Rejected</option>
            </select>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setuserLeaveSummaryData(null)
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewDetails}
        onHide={() => {
          setViewDetails(false);
          setDetailsData(null);
        }}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Leave Status Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>Sno.</th>
                  <th>Leave Date</th>
                  <th>Savior Response</th>
                  <th>Synced At</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {detailsData?.length > 0 ? (
                  detailsData?.map((data, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{moment(data?.leave_date).format("DD-MM-YYYY")}</td>
                      <td>{data?.savior_response}</td>
                      <td>
                        {moment.utc(data?.sync_date).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td>
                        {data?.status
                          ? `Posted ${data?.is_cancelled ? "(Cancelled)" : ""}`
                          : "Not Posted"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center">
                      {" "}
                      Pending For Posting
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setViewDetails(false);
              setDetailsData(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyLeaveApp;
