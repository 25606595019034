import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as surveyService from "../../service/Survey";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Dropdown } from "react-bootstrap";
import ViewSurvey from "./ViewSurvey";
import { selectFinalUrlPermission, selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import moment from "moment/moment";
import copy from "copy-to-clipboard";
import * as surveyMaster from "../../service/Survey";

function Survey() {
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const [emailsubject, setEmailSubject] = useState('');
  const [emailbody, setEmailBody] = useState('');
  const [emailsignature, setEmailSignature] = useState('');
  const addnewclose = () => {
    addnewsetShow(false);
    setEmailSubject('');
    setEmailBody('');
    setEmailSignature('');
  };
  const [erroremailsignature, setErrorEmailSignature] = useState(false);
  const [erroremailbody, setErrorEmailBody] = useState(false);
  const [erroremailsubject, setErrorEmailSubject] = useState(false);

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [surveyData, setSurveyData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [showForm, setShowForm] = useState(false);

  const [surveyDataFilter, setSurveyDataFilter] = useState({
    status: "",
  });
  const [surveyThankyouEmail, setSurveyThankyouEmail] = useState({
    subject: "",
    body: "",
    signature: "",
  });

const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [categoryType, setCategoryType] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [thankyouApplied, setThankyouApplied] = useState(false);
  const [surveyType, setSurveyType] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);

  const handleKey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let curr_user = userData.id;

    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&created_by=" +
      curr_user;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (
      assessment_type_id !== "" &&
      assessment_type_id !== undefined &&
      assessment_type_id !== null
    ) {
      queryParm = queryParm + "&assessment_type_id=" + assessment_type_id;
    }

    if (
      category_id !== "" &&
      category_id !== undefined &&
      category_id !== null
    ) {
      queryParm = queryParm + "&category_id=" + category_id;
    }

    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }

    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    return queryParm;
  };

  const getSurvey = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    surveyService
      .getSurvey(
        handleKey(
          search,
          assessment_type_id,
          category_id,
          status,
          start_date,
          end_date,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSurveyList(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getThankuEmail = (
    subject, 
    body, 
    signature 
    ) => {
    surveyMaster.postSurveyResponses(
      handleKey(subject, body, signature)
    ).then((response) => {
      console.log({ response });
      setThankyouApplied(response?.data?.dataList?.result);
      // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
    });
  };

  const deleteSurvey = (id, idx) => {
    let dataId = { id: idx };
    surveyService
      .deleteSurvey(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getSurvey(
            "",
            "",
            "",
            "",
            "",
            "",
            1,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, surveyName) => {
    let name = `Are you sure to delete ${surveyName}`
    confirmAlert({
      title: "Delete Survey ",
      message: <div dangerouslySetInnerHTML={{
        __html: name
      }}></div>,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSurvey(id, id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getAllCategoryType = (paginate) => {
    surveyService
      .getAllCategoryType(paginate)
      .then((response) => {
        setCategoryType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const mapAllOptionSurvey = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const getAllSurveyType = () => {
    surveyService
      .getAllSurveyType()
      .then((response) => {
        setSurveyType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getAllCategoryType();
    getAllSurveyType();
  }, []);

  const mainDataFilter = () => {
    setFilterApplied(true);
    getSurvey(
      searchStr,
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(surveyDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setSurveyDataFilter(ClearData);
    filterClose();
    getSurvey(
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
      // search, organisation_id, status, pageNo, page_size, sort_by, paginate
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const viewSurveyDetails = (editData) => {
    navigate("/viewsurvey", {
      state: { edit: editData, flag: "true", isViewing: true },
    });
    setEditMode(false);
    setSurveyData(editData);
  };

  const editSurveyDetails = (editData) => {
    navigate("/addsurvey", {
      state: { edit: editData, flag: "true" },
    });
    setEditMode(true);
    setSurveyData(editData);
  };

  const addSurveyDetails = () => {
    navigate("/addsurvey", {
      state: { edit: null, flag: "false", isViewing: false },
    });
    setSurveyData(null);
    setEditMode(false);
  };

  const onView = (viewData) => {
    setViewForm(true);
    setViewFormData(viewData);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const onClose = () => {
    setSurveyData(null);
  };

  const handleSearch = (e) => {
    getSurvey(
      e.target.value.toLowerCase(),
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      console.log(searchStr);
      getSurvey(
        data,
        surveyDataFilter.assessment_type_id,
        surveyDataFilter.category_id,
        surveyDataFilter.status,
        surveyDataFilter.start_date,
        surveyDataFilter.end_date,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  // const mainDataFilter = (surveyDataFilter) => {
  //   console.log(">>>pick", dataFilter);
  //   //     setSurveyDataForExport({
  //   //   ...surveyDataForExport,
  //   //   organisation_id: values.organisation_id,
  //   //   status: values.status,
  //   // });
  //   const orgId = surveyDataFilter.organisation_id;
  //   const stats = surveyDataFilter.status;
  //   getSurvey(searchStr, orgId, stats, 1, pageSizeNo, sortData.sort_by, true);
  //   filterclose();
  // };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSurvey(
      searchStr,
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  // const handlerData = (backdata) => {
  //   let sdata = {};
  //   if (sortData["colName"] === backdata) {
  //     if (sortData["sort_by"].includes("-")) {
  //       sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
  //     } else {
  //       sdata = {
  //         colName: backdata,
  //         sort_by: "-" + backdata,
  //         [backdata]: "desccls",
  //       };
  //     }
  //   } else {
  //     sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
  //   }
  //   setSortData(sdata);
  //   getSurvey(
  //     searchStr,
  //     surveyDataFilter.organisation_id,
  //     surveyDataFilter.status,
  //     currentPage,
  //     pageSizeNo,
  //     sdata.sort_by,
  //     true,
  //   );
  // };

  const getExportDetails = (
    search,
    assessment_type_id,
    category_id,
    status,
    start_date,
    end_date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    surveyService
      .getExportValue(
        handleKey(
          search,
          assessment_type_id,
          category_id,
          status,
          start_date,
          end_date,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Survey.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getSurvey(
      "",
      surveyDataFilter.assessment_type_id,
      surveyDataFilter.category_id,
      surveyDataFilter.status,
      surveyDataFilter.start_date,
      surveyDataFilter.end_date,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    console.log(`survey..`, userData);
  }, [pageSizeNo]);

  const saveThankuEmailData = () =>{
    if(
      emailsubject !== '' && emailbody !== '' && emailsignature !== ''
    ){
      const frmObj = {
        emailSubject: emailsubject,
        emailBody: emailbody,
        emailSignature: emailsignature
      }
      surveyMaster.postSurveyResponses(frmObj).then((response)=>{
        if(response.data.message === 'Email Sended successfully !!'){
          addnewclose()
          toast.success(response.data.message)
          getSurvey(
            true,
            currentPage,
            pageSizeNo,
            searchStr
          );
        }
        else{
          toast.error(response.data.message)
        }
        
      })
    }
    else{
      if(emailsignature === ""){
        setErrorEmailSignature(true)
      }
      else if(emailsubject === ""){
        setErrorEmailSubject(true)
      }
      else if(emailbody === ""){
        setErrorEmailBody(true)
      }
    }
  }
  
  const updateThankuEmailData = () =>{
    const frmObj = {
      emailSubject: emailsubject,
      emailBody: emailbody,
      emailSignature: emailsignature
    }
    
  }
  
  const onAddCallBack = (response) => {
    setShowForm(false);
    getSurvey("", "", "", "", "", "", 1, pageSizeNo, sortData.sort_by, true);
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Survey</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/home">Company</Breadcrumb.Item>
                    <Breadcrumb.Item active>Survey</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <ToastContainer autoClose={1000} />
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong>Title</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Title"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {finalUrlValue?.D ? (
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={surveyList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  surveyDataFilter.assessment_type_id,
                                  surveyDataFilter.category_id,
                                  surveyDataFilter.status,
                                  surveyDataFilter.start_date,
                                  surveyDataFilter.end_date,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                    <button class="btn btn-primary-inner" 
                        onClick={addnewopen}
                        >
                          <img src="images/upload.png" alt="" class="mr-3" />
                          Thankyou Email
                          </button>  
                    <Modal
                       show={addnewshow}
                       onHide={addnewclose}
                       backdrop="static"
                       keyboard={false}
                       size="lg"
                       className="modaldefaultclose modaldefaultclosecenter"
                      >
        
                         <Modal.Header closeButton>
                           <Modal.Title>Thankyou Email</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                         <div class="row justify-content-center">
                           <div class="col-md-11">
                             <div class="row">
                               <div class="col-md-6">
                                 <div class="form-group innergroup">
                                   <label>
                                     Email Subject<span class="text-danger">*</span>
                                   </label>
                                   <input
                                     type="text"
                                     className="form-control"
                                     name="category"
                                     placeholder="Enter Email Subject"
                                     value={emailsubject}
                                     onChange={(e) => {setEmailSubject(e.target.value)}}
                                    />
                                    {erroremailsubject && (
                                <div className="small text-danger">
                                  {" "}
                                  *email subject is required
                                </div>
                              )}
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group innergroup">
                                    <label>
                                      Email Body<span class="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="email_body"
                                      placeholder="Enter Email Body"
                                      value={emailbody}
                                      onChange={(e) => {setEmailBody(e.target.value)}}
                                      />
                                      {erroremailbody && (
                                <div className="small text-danger">
                                  {" "}
                                  *email body is required
                                </div>
                              )}
                                    </div>
                                  </div>

                                  <div class="col-md-6">
                                    <div class="form-group innergroup">
                                      <label>
                                        Email Signature<span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="email_signature"
                                        placeholder="Enter Email Signature"
                                        value={emailsignature}
                                        onChange={(e) => {setEmailSignature(e.target.value)}}
                                      />
                                      {erroremailsignature && (
                                <div className="small text-danger">
                                  {" "}
                                  *email signature is required
                                </div>
                              )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Modal.Footer>
                        <button
                          class="btn btn-outline-danger"
                          onClick={() => {
                          addnewclose();
                          //   resetForm();
                          }}
                        >
                          Cancel
                          </button>
                        <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                          onClick={editMode ? updateThankuEmailData : saveThankuEmailData}
                          // disabled={isSubmitting}
                        >
                          Submit
                        </button>
                        </Modal.Footer>
                      </Modal.Body>
                        </Modal>
                      

                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {/* {finalUrlValue?.A ? ( */}
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => addSurveyDetails()}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Organization</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Responses</th>
                    <th>Pending Responses</th>
                    <th>Survey Link</th>
                    <th>Survey Status</th>
                    <th>Created by</th>
                    <th>Created at</th>
                    <th>Pending At</th>
                    {/* {finalUrlValue?.V ||
                    finalUrlValue?.E ||
                    finalUrlValue?.R ? ( */}
                    <th>Actions</th>
                    {/* ) : (
                      ""
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {surveyList?.length ? (
                    surveyList?.map((data, index) => (
                      <>
                        <tr key={`surv${index}`}>
                          <td>{index + 1}</td>
                          <td class="text-theme fw-bold">
                            {data?.organisation_name?.length > 0
                              ? data?.organisation_name[0]
                              : ""}
                          </td>
                          <td>{data?.category_name}</td>
                          <td className="assess-title-list" dangerouslySetInnerHTML={{
                            __html: data?.title.trim()
                          }}></td>
                          <td>{data?.start_date}</td>
                          <td>{data?.end_date}</td>
                          {/* <td>{data?.response}</td> */}
                          <td>
                            <span
                              class="badge badge-secondary cursor-pointer"
                              onClick={() =>
                                navigate("/survey-result", {
                                  state: {
                                    targetAsmnt: {
                                      id: data.id,
                                      type_name: data.assessment_type_name,
                                      type_id: data.assessment_type_id,
                                      type_internal_external: data.type,
                                      pendingRes: false
                                    },
                                    view: true,
                                  },
                                })
                              }
                            >
                              {data?.responses}
                            </span>
                          </td>
                          <td>
                            <span
                              class="badge badge-secondary cursor-pointer"
                              onClick={() =>
                                navigate("/survey-result", {
                                  state: {
                                    targetAsmnt: {
                                      id: data.id,
                                      type_id: data.assessment_type_id,
                                      type_internal_external: data.type,
                                      pendingRes: true
                                    },
                                    view: true,
                                  },
                                })
                              }
                            >
                              {data?.pendingResponse}
                            </span>
                          </td>
                          <td>
                            {(data?.survey_status === "Pending" ||
                              data?.survey_status === "Published") &&
                              data?.type === "Internal" && (
                                <i
                                  class="fas fa-paperclip"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let url = window.location.href.split("/");
                                    copy(
                                      `${url[0]}${url[1]}${url[2]
                                      }/viewmyassignment/${data?.id}/${false}`
                                    );
                                    toast.success("Survey link copied.");
                                  }}
                                ></i>
                              )}
                          </td>
                          <td>
                            {data?.survey_status}
                            {/* {data.is_active ? (
                              <span class="badge bad-status badge-success">
                                Active
                              </span>
                            ) : (
                              <span class="badge bad-status badge-success">
                                InActive
                              </span>
                            )} */}
                          </td>
                          <td>{data?.created_by}</td>
                          <td>
                            {moment
                              .utc(data?.created_at)
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td>{data?.pending_at}</td>
                          {/* {finalUrlValue?.V ||
                          finalUrlValue?.E ||
                          finalUrlValue?.R ? ( */}
                          <td>
                            {/* {finalUrlValue?.V ? ( */}
                            <button
                              class="btn btn-blanktd text-success"
                              onClick={() => viewSurveyDetails(data)}
                            >
                              <img src="images/eye.png" alt="" />{" "}
                            </button>
                            {/* ) : (
                                ""
                              )} */}
                            {/* {finalUrlValue?.E ? ( */}

                            {data?.survey_status == "Draft Saved" ||
                              data?.survey_status == "Rejected" ||
                              data?.survey_status == "Pending" ||
                              data?.survey_status == "Published" ? (
                              <button
                                class="btn btn-blanktd text-primary ml-2"
                                onClick={() => editSurveyDetails(data)}
                              >
                                <i class="far fa-edit"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {/* ) : (
                                ""
                              )} */}
                            {/* {finalUrlValue?.R ? ( */}

                            {data?.survey_status == "Draft Saved" ||
                              data?.survey_status == "Rejected" ||
                              data?.survey_status == "Pending" ||
                              data?.survey_status == "Inactive" ? (
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i
                                  class="far fa-trash-alt text-danger"
                                  onClick={() => {
                                    submit(data?.id, data?.title);
                                  }}
                                ></i>
                              </button>
                            ) : (
                              ""
                            )}

                            {/* ) : (
                                ""
                              )} */}
                          </td>
                          {/* // ) : (
                          //   ""
                          // )} */}
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {surveyList?.length === 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onChange={(e) => {
                        pageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  start_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={surveyDataFilter.start_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              disabled={!surveyDataFilter.start_date}
              min={surveyDataFilter.start_date}
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  end_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={surveyDataFilter.end_date}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Select Type <span class="text-danger"></span>
            </label>

            <select
              class="form-control newbgselect"
              classNamePrefix="select"
              name="assessment_type_id"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  assessment_type_id: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={surveyDataFilter.assessment_type_id}
            >
              <option value="" label="Select Type" disabled />
              {mapAllOptionSurvey(surveyType)}
            </select>
          </div> */}
          <div class="form-group innergroup">
            <label>
              Select Category <span class="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              classNamePrefix="select"
              name="category_id"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  category_id: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={surveyDataFilter.category_id}
            >
              <option value="" label="Select Organization" disabled />
              {mapAllOptionSurvey(categoryType)}
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Select Status <span class="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="status"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setSurveyDataFilter({
                  ...surveyDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={surveyDataFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter(surveyDataFilter)}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {viewForm ? (
        <>
          <ViewSurvey
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Survey;
