import React, { useEffect, useState } from "react";
import VehicalForm from "./JivagroVehicalForm";
import * as VehicalService from "../../service/VehicalService";
import * as moment from "moment";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function VehicalReqHistory() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);

  const [filterdata, setfilterData] = useState({
    Type: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [editData, setEditVehicleData] = useState({});
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [viewnewshow, viewnewsetShow] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const viewnewclose = () => viewnewsetShow(false);
  const getData = (
    currentpage,
    pagesize,
    sort_by,
    paginate,
    from_date,
    to_date,
    status
  ) => {
    dispatch(setDisplayLoader("Display"));

    VehicalService.getVehicleReqData(
      currentpage,
      pagesize,
      sort_by,
      paginate,
      from_date,
      to_date,
      ""
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setVehicleData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const editNotice = (data) => {
    setEditVehicleData(data);
    setEditForm(true);
    addnewopen();
  };
  const addnewopenForm = () => {
    addnewsetShow(true);
    setEditForm(false);
  };

  useEffect(() => {
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  }, [pageSizeNo]);

  const handleShow = (e) => {
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   searchStr,
    //   true,
    //   filterdata.Type,
    //   filterdata.from_date,
    //   filterdata.to_date
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  // const submitDelete = (id, type) => {
  //   confirmAlert({
  //     title: "Delete " + type,
  //     message: `Are you sure to delete ` + type + "?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deleteNotice(id),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getData(
      activePage,
      pageSizeNo,
      "-id",
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.status
    );
    setCurrentPage(activePage);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getData(1, pageSizeNo, sortData.sort_by, true, "", "", "");
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true,
      filterdata.from_date,
      filterdata.to_date,
      filterdata.status
    );
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Vehicle Registration History</h4>
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Emp Code</th>
                <th>Relation</th>
                <th>Vehicle Type</th>
                <th>Vehicle Number</th>
                <th>Valid from</th>
                <th>Valid till</th>
                <th>Attachment</th>
                <th>License Number</th>
                <th>DL Valid from</th>
                <th>DL Valid till</th>
                <th>DL Attachment</th>
                <th>Applied on</th>
                <th>Status</th>
                {/* <th>Approved on</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vehicleData?.length ? (
                vehicleData?.map((x) => (
                  <tr>
                    <td class="text-theme fw-bold">{x.created_by_id}</td>
                    <td>{x?.relation_type}</td>
                    <td>{x?.vehicleType}</td>

                    <td>{x?.vehicleNumber}</td>
                    {x?.validFromDate !== null ? (
                      <td>
                        {moment.utc(x?.validFromDate).format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {x?.validTillDate !== null ? (
                      <td>
                        {moment.utc(x?.validTillDate).format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      <a
                        target="_blank"
                        href={x?.attachment_path}
                        rel="noreferrer"
                      >
                        <i class="fas fa-paperclip"></i>
                      </a>
                    </td>

                    <td>{x?.licenseNumber}</td>
                    {x?.licenseValidFromDate !== null ? (
                      <td>
                        {moment
                          .utc(x?.licenseValidFromDate)
                          .format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    {x?.licenseValidTillDate !== null ? (
                      <td>
                        {moment
                          .utc(x?.licenseValidTillDate)
                          .format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td>
                      <a
                        target="_blank"
                        href={x?.dl_attachment_path}
                        rel="noreferrer"
                      >
                        <i class="fas fa-paperclip"></i>
                      </a>
                    </td>
                    <td>{moment.utc(x?.created_at).format("DD-MM-YYYY")}</td>
                    <td>
                      {x?.status == "A" ? (
                        <span class="badge bad-status badge-success">
                          Approved{" "}
                        </span>
                      ) : x?.status == "P" ? (
                        <span class="badge bad-status badge-warning">
                          Pending{" "}
                        </span>
                      ) : x?.status == "ARM" ||
                        x?.status == "ASU" ||
                        x?.status == "ASM" ||
                        x?.status == "FASM" ||
                        x?.status == "ASH" ? (
                        <span class="badge bad-status badge-warning">
                          Partially Pending{" "}
                        </span>
                      ) : x?.status == "RRM" ||
                        x?.status == "RSU" ||
                        x?.status == "RSM" ||
                        x?.status == "RSH" ? (
                        <span class="badge bad-status badge-danger">
                          Declined{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td>{moment.utc(x?.created_at).format("DD-MM-YYYY")}</td> */}
                    <td>
                      {x?.is_editable && (
                        <button
                          className="btn btn-blanktd text-primary"
                          onClick={() => {
                            editNotice(x);
                          }}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14}>NO Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {vehicleData?.length ? (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    handleShow(e);
                  }}
                  style={{ textAlign: "center" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {addnewshow && (
        <VehicalForm
          open={addnewshow}
          onhide={addnewclose}
          getdata={() =>
            getData(currentPage, pageSizeNo, "-id", true, "", "", "")
          }
          data={editData}
          editForm={editForm}
        />
      )}
    </>
  );
}

export default VehicalReqHistory;
