import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewOrganisation = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Organization Code 
                    </label>
                    <p>{viewFormData?.code}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Organization Name 
                    </label>
                    <p>{viewFormData?.name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Sub-Domain 
                    </label>
                    <p>{viewFormData?.sub_domain}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Title Color 
                    </label>

                    <input
                      className="form-control"
                      type="color"
                      name="title_color"
                      value={viewFormData?.title_color}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Heading Color 
                    </label>

                    <input
                      className="form-control"
                      type="color"
                      name="heading_color"
                      value={viewFormData?.heading_color}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Text Link Color 
                    </label>

                    <input
                      className="form-control"
                      type="color"
                      name="text_link_color"
                      value={viewFormData?.text_link_color}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Menu Background 
                    </label>

                    <input
                      className="form-control"
                      type="color"
                      name="menu_background"
                      value={viewFormData?.menu_background}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Menu Text Color 
                    </label>

                    <input
                      className="form-control"
                      type="color"
                      name="menu_text_color"
                      value={viewFormData?.menu_text_color}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Uploaded Logo 
                    </label>
                    <div className="uploaded-image">
                      <img src={viewFormData?.logo} alt="" height={50} />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Uploaded Login Image{" "}
                      
                    </label>
                    <div className="uploaded-image">
                      <img src={viewFormData?.bg_image} alt="" height={50} />
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Application Background{" "}
                      
                    </label>
                    <br />
                    <div
                      className={`img-sectionemper ${viewFormData?.application_background_color} mb-3 mb-md-0`}
                      style={{ width: "46px", height: "42px" }}
                    ></div>
                    <div className="clearfix"></div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block">
                      Status 
                    </label>
                    <label className="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="statusupdate1"
                        disabled
                        checked={viewFormData.is_active === true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        disabled
                        checked={viewFormData.is_active === false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment.utc(viewFormData?.created_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated By</label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <p>
                      {moment.utc(viewFormData?.updated_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewOrganisation;
