import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "../ModularTable";
import {
  getAllDates,
  getCropPattern,
  getCategoryDropdown,
  getCropPatternApprovalLogs,
  getCropsByCatId,
  getLastCropPattern,
  postChannelSize,
  postCropPatternAction,
  postCropPatternSize,
  putCropPatternSize,
} from "../../../../service/AgriPortalService/CropPatternService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS
  const [dropwdownCategories, setDropdownCategories] = useState([]);
  const [dropdownProducts, setDropdownProducts] = useState({});

  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);

  const [updatedTableData, setUpdatedTableData] = useState([[]]);

  const [dates, setDates] = useState([]);

  const [freezeCTA, setFreezeCTA] = useState(false);
  const [mode, setMode] = useState();
  const [locationData, setLocationData] = useState({});
  const [approvalList, setApprovalList] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // show zone territory and region
  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getCropPattern()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => console.log("THD", tableHeadData), [tableHeadData]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    const totals = [
      { value: ["Total"] },
      { value: ["-"] },
      { value: ["-"] },
      { value: ["-"] },
      { value: ["-"] },
    ];

    for (let i = 5; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (!parseInt(data[j][i]?.value[0])) total += 0;
        else total += parseInt(data[j][i]?.value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;
    let selectedData = {};
    for (let i = 0; i < data.length - 1; i++) {
      if (data[i][1].selected != "None") {
        const length = data[i].length;
        for (let j = 2; j < length; j++) {
          data[i][j].meta.isDisabled = false;
        }
        continue;
      } else {
        const length = data[i].length;
        for (let j = 2; j < length; j++) {
          data[i][j].meta.isDisabled = true;
        }
        data[i][2].selected = "None";
        data[i][3].selected = "None";
        data[i][4].value = [0];
        data[i][5].value = [0];
        data[i][6].value = [0];
        data[i][7].value = [0];
        data[i][8].value = [0];
        data[i][9].value = [0];
        continue;
      }
    }
    setUpdatedTableData([...data]);
  };

  //To preload dropdown player values
  useEffect(() => {
    getCategoryDropdown()
      .then((res) => {
        setDropdownCategories(res.data.dataList);
      })
      .catch((err) => console.log(err));

    getAllDates()
      .then((res) => {
        setDates(res.data.dataList);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!dropwdownCategories) return;

    fetchAllCropsDropdownByCategory(dropwdownCategories)
      .then((res) => {
        setDropdownProducts(res);
      })
      .catch((err) => console.log(err));
  }, [dropwdownCategories]);

  async function fetchAllCropsDropdownByCategory(category) {
    const obj = {};

    for (let i = 0; i < category.length; i++) {
      const res = await getCropsByCatId("?crop_category_id=" + category[i].id);
      obj[category[i].name] = res.data.dataList;
    }

    return obj;
  }

  useEffect(() => {
    if (!mode) return;
    let query = "/mymarket/croppattern";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));

    let query = "",
      query2 = "";
    if (params.get("mode") != "create") {
      query = "?crop_pattern_id=" + params.get("id");
      setPageId(params.get("id"));
      query2 = "?pattern_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getCropPatternApprovalLogs(query2)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }

    getLastCropPattern(query)
      .then((res) => {
        setRawData(res.data.dataList);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Crop pattern already exists!");
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        }
      });
  }, []);

  //Set table data after past data is fetched
  useEffect(() => {
    if (!rawData.pattern_data) return;

    if (
      Object.keys(dropwdownCategories).length == 0 ||
      Object.keys(dropdownProducts).length == 0
    )
      return;

    setTableHeadData((state) => {
      if (!rawData) return [];

      // let newYear = rawData?.category_data[0]?.fy_p_2.split("-")[1];
      // newYear=newYear+"-"+newYear[0]+newYear[1]+newYear[2]+(parseInt(newYear[3])+1);

      let headData;

      // if(mode=="create")
      // headData=[
      //     {
      //         name:"Category",
      //         type:"T",
      //         isEditable:false
      //     },
      //     {
      //         name:rawData?.category_data[0]?.fy_m_1,
      //         type:"N",
      //         isEditable:true,
      //     },
      //     {
      //         name:rawData?.category_data[0]?.fy,
      //         type:"N",
      //         isEditable:true,
      //     },
      //     {
      //         name:rawData?.category_data[0]?.fy_p_1,
      //         type:"N",
      //         isEditable:true,
      //     },
      //     {
      //         name:rawData?.category_data[0]?.fy_p_2,
      //         type:"N",
      //         isEditable:true,
      //     },
      //     {
      //         name:newYear,
      //         type:"N",
      //         isEditable:true,
      //     }
      // ];

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crops",
            type: "D",
            isEditable: true,
          },
          {
            name: "Season",
            type: "D",
            isEditable: true,
          },
          {
            name: "Crop Start Date",
            type: "D",
            isEditable: true,
          },
          {
            name: "Estimated Irrigated % " + rawData?.pattern_data[0]?.fy,
            type: "N",
            isEditable: true,
            isPercentage: true,
          },
          // {
          //   name: rawData?.pattern_data[0]?.fy_m_2,
          //   type: "N",
          //   isEditable: true,
          // },
          // {
          //   name: rawData?.pattern_data[0]?.fy_m_1,
          //   type: "N",
          //   isEditable: true,
          // },
          {
            name: rawData?.pattern_data[0]?.fy,
            type: "N",
            isEditable: true,
          },
          // {
          //   name: rawData?.pattern_data[0]?.fy_p_1,
          //   type: "N",
          //   isEditable: true,
          // },
          // {
          //   name: rawData?.pattern_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: true,
          // },
        ];

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Category",
            type: "T",
            isEditable: false,
          },
          {
            name: "Crops",
            type: "D",
            isEditable: false,
          },
          {
            name: "Season",
            type: "D",
            isEditable: false,
          },
          {
            name: "Crop Start Date",
            type: "D",
            isEditable: false,
          },
          {
            name: "Estimated Irrigated % " + rawData?.pattern_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          // {
          //   name: rawData?.pattern_data[0]?.fy_m_2,
          //   type: "N",
          //   isEditable: false,
          // },
          // {
          //   name: rawData?.pattern_data[0]?.fy_m_1,
          //   type: "N",
          //   isEditable: false,
          // },
          {
            name: rawData?.pattern_data[0]?.fy,
            type: "N",
            isEditable: false,
          },
          // {
          //   name: rawData?.pattern_data[0]?.fy_p_1,
          //   type: "N",
          //   isEditable: false,
          // },
          // {
          //   name: rawData?.pattern_data[0]?.fy_p_2,
          //   type: "N",
          //   isEditable: false,
          // },
        ];

      return headData;
    });

    setTableData((state) => {
      const newTableData = [];

      // const categoryDropdown = dropwdownCategories.map(el=>el.name);
      let val1 = 0,
        val2 = 0,
        val3 = 0,
        val4 = 0,
        val5 = 0;
      if (mode == "create" || mode == "edit") {
        for (let i = 0; i < dropwdownCategories.length; i++) {
          let filteredRawData = rawData.pattern_data.filter(
            (el) => el.category_name == dropwdownCategories[i]?.name
          );

          for (
            let j = 0;
            j <
            (dropwdownCategories[i]?.line_items
              ? dropwdownCategories[i]?.line_items
              : 0);
            j++
          ) {
            let rowData;

            if (filteredRawData.length > j) {
              let season = "Kharif";

              if (filteredRawData[j].season == "R") season = "Rabi";
              if (filteredRawData[j].season == "N") season = "None";

              val1 += parseInt(filteredRawData[j].fy_m_2_value);
              val2 += parseInt(filteredRawData[j].fy_m_1_value);
              val3 += parseInt(filteredRawData[j].fy_value);
              val4 += parseInt(filteredRawData[j].fy_p_1_value);
              val5 += parseInt(filteredRawData[j].fy_p_1_value);
              rowData = [
                {
                  value: dropwdownCategories[i]?.name,
                  meta: {
                    id: dropwdownCategories[i]?.id,
                    pid: filteredRawData[j]?.id
                  },
                },
                {
                  value: [
                    ...dropdownProducts[dropwdownCategories[i]?.name]?.map(
                      (el) => el.name
                    ),
                    "None",
                  ],
                  meta: {
                    raw: [
                      ...dropdownProducts[dropwdownCategories[i]?.name],
                      "None",
                    ],
                  },
                  selected: filteredRawData[j].crop_name,
                },
                {
                  value: ["Kharif", "Rabi", "None"],
                  meta: {
                    raw: [
                      { name: "Kharif", id: "K" },
                      { name: "Rabi", id: "R" },
                      { name: "None", id: "N" },
                    ],
                    isDisabled: true,
                  },
                  selected: season,
                },
                {
                  value: [...dates?.map((el) => el.name), "None"],
                  meta: {
                    raw: [...dates, "None"],
                    isDisabled: true,
                  },
                  selected: filteredRawData[j].crop_start_date_value,
                },
                {
                  value: [filteredRawData[j].est_value],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [filteredRawData[j].fy_value],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [filteredRawData[j].fy_m_1_value],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [filteredRawData[j].fy_value],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [filteredRawData[j].fy_p_1_value],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [filteredRawData[j].fy_p_2_value],
                  meta: {
                    isDisabled: true,
                  },
                },
              ];
            } else
              rowData = [
                {
                  value: dropwdownCategories[i]?.name,
                  meta: {
                    id: dropwdownCategories[i]?.id,
                  },
                },
                {
                  value: [
                    ...dropdownProducts[dropwdownCategories[i]?.name]?.map(
                      (el) => el.name
                    ),
                    "None",
                  ],
                  meta: {
                    raw: [
                      ...dropdownProducts[dropwdownCategories[i]?.name],
                      "None",
                    ],
                  },
                  selected: "None",
                },
                {
                  value: ["Kharif", "Rabi", "None"],
                  meta: {
                    raw: [
                      { name: "Kharif", id: "K" },
                      { name: "Rabi", id: "R" },
                      { name: "None", id: "N" },
                    ],
                    isDisabled: true,
                  },
                  selected: "None",
                },
                {
                  value: [...dates?.map((el) => el.name), "None"],
                  meta: {
                    raw: [...dates, "None"],
                    isDisabled: true,
                  },
                  selected: "None",
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
                {
                  value: [0],
                  meta: {
                    isDisabled: true,
                  },
                },
              ];

            newTableData.push(rowData);
          }
        }
      } else
        rawData.pattern_data.forEach((el, idx) => {
          let season = "Kharif";

          if (el.season == "R") season = "Rabi";
          if (el.season == "N") season = "None";

          val1 += parseInt(el.fy_m_2_value);
          val2 += parseInt(el.fy_m_1_value);
          val3 += parseInt(el.fy_value);
          val4 += parseInt(el.fy_p_1_value);
          val5 += parseInt(el.fy_p_1_value);
          let rowData;
          rowData = [
            {
              value: el.category_name,
              meta: {
                id: el.category_id,
              },
            },
            {
              value: dropdownProducts[el.category_name]?.map((el) => el.name),
              meta: {
                raw: dropdownProducts[el.category_name],
              },
              selected: el.crop_name,
            },
            {
              value: ["Kharif", "Rabi", "None"],
              meta: {
                raw: [
                  { name: "Kharif", id: "K" },
                  { name: "Rabi", id: "R" },
                  { name: "None", id: "N" },
                ],
              },
              selected: season,
            },
            {
              value: dates?.map((el) => el.name),
              meta: {
                raw: dates,
              },
              selected: el.crop_start_date_value,
            },
            {
              value: [el.est_value],
              meta: {},
            },
            {
              value: [el.fy_value],
              meta: {},
            },
            {
              value: [el.fy_m_1_value],
              meta: {},
            },
            {
              value: [el.fy_value],
              meta: {},
            },
            {
              value: [el.fy_p_1_value],
              meta: {},
            },
            {
              value: [el.fy_p_2_value],
              meta: {},
            },
          ];

          newTableData.push(rowData);
        });

      newTableData.push([
        {
          value: ["Total"],
          meta: {},
        },
        { value: ["-"] },
        { value: ["-"] },
        { value: ["-"] },
        {
          value: [val1],
          meta: {},
        },
        {
          value: [val2],
          meta: {},
        },
        {
          value: [val3],
          meta: {},
        },
        {
          value: [val4],
          meta: {},
        },
        {
          value: [val5],
          meta: {},
        },
      ]);
      // newTableData.forEach((ele)=>{
      //     console.log("TABLE DATA=>",ele)
      //     ele[1].selected=""
      //     ele[2].selected=""
      // })
      return newTableData;
    });
  }, [rawData, dropwdownCategories, dropdownProducts]);

  useEffect(() => {
    if (initialData?.length === 0) {
      let obj = JSON.parse(JSON.stringify(tableData))
      setInitialData(obj)
    }
  }, [tableData])

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;
    data.forEach((row) => {
      if (row[0].value != "Total" && (row[1].selected != "None" || row[0]?.meta?.pid !== undefined)) {
        let crop_id = -1;
        crop_id = row[1].meta.raw.find((el) => el.name == row[1].selected);
        crop_id = crop_id?.id;

        let crop_start_date_id = -1;
        crop_start_date_id = row[3].meta.raw.find(
          (el) => el.name == row[3].selected
        );
        crop_start_date_id = crop_start_date_id?.id;

        let season = "N";
        season = row[2].meta.raw.find((el) => el.name == row[2].selected);
        season = season?.id;
        console.log({tableHeadData});
        ans.push({
          id: row[0]?.meta?.pid !== undefined ? row[0]?.meta?.pid : 0,
          category_id: row[0].meta.id,
          crop_id: crop_id,
          crop_start_date_id: crop_start_date_id,
          season: season,
          est_value: row[4].value[0] == "" ? "0" : row[4].value[0],
          // fy_m_2: tableHeadData[5].name,
          // fy_m_1: tableHeadData[6].name,
          fy: tableHeadData[5].name,
          // fy_p_1: tableHeadData[6].name,
          // fy_p_2: tableHeadData[9].name,
          // fy_m_2_value: row[5].value[0] == "" ? "0" : row[5].value[0],
          // fy_m_1_value: row[6].value[0] == "" ? "0" : row[6].value[0],
          fy_value: row[5].value[0] == "" ? "0" : row[5].value[0],
          // fy_p_1_value: row[6].value[0] == "" ? "0" : row[6].value[0],
          // fy_p_2_value: row[9].value[0] == "" ? "0" : row[9].value[0],
        });
      }
    });
    let selectedData = {};
    let seasonError = [];
    let dateError = [];
    data.map((ele) => {
      if (ele[0].value != "Total" && ele[2].selected != "None" && (ele[1].selected != "None" || ele[0]?.meta?.pid !== undefined)) {
        if (selectedData[ele[1].selected]) {
          selectedData[ele[1].selected].push(ele[2].selected);
        } else {
          selectedData[ele[1].selected] = [ele[2].selected];
        }
      }
      if (ele[1].selected != "None" && ele[2].selected == "None") {
        seasonError.push(ele[1].selected);
      }
      if (ele[1].selected != "None" && ele[3].selected == "None") {
        dateError.push(ele[1].selected);
      }
    });
    let selectedSeason = Object.values(selectedData);
    let duplicateEntries = [];
    selectedSeason.map((ele, i) => {
      if (!checkIfArrayIsUnique(ele)) {
        duplicateEntries.push(Object.keys(selectedData)[i]);
      }
    });

    if (duplicateEntries.length == 0 && seasonError.length == 0 && dateError.length == 0) {
      return { error: false, data: ans };
    } else {
      return { error: true, data: { duplicateEntries,seasonError,dateError } };
    }

  };

  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);
    // if(mode=="edit")
  };

  const checkData = (type) => {
    console.log({updatedTableData});
    const pattern_data = convertCategoryData(updatedTableData);
    if (pattern_data.error == false && pattern_data.data.length == 0) return;
    if (pattern_data.error == true) {
      let duplicateData = pattern_data.data.duplicateEntries.join(", ");
      let dateData = Array.isArray(pattern_data.data.dateError) ? pattern_data.data.dateError.join(", ") : pattern_data.data.dateError;
      let seasonData = pattern_data.data.seasonError.join(", ");
      setFreezeCTA(false);
      let text = "";
      console.log(duplicateData, dateData);
      if (pattern_data.data.seasonError.length != 0) {
        text += `Please select season for following crop: ${seasonData}`;
        setFreezeCTA(false);
        errorDialog(text);
        return;
      }
      if (pattern_data.data.duplicateEntries.length != 0) {
        text += `Please remove duplicate crop-season entries of following crop: ${duplicateData}`;
      }
      if (
        pattern_data.data.duplicateEntries.length != 0 &&
        pattern_data.data.dateError.length != 0
      ) {
        text += ` and `;
      }
      if (pattern_data.data.dateError.length != 0) {
        text += `Please select date for following crop: ${dateData}`;
      }
      setFreezeCTA(false);
      errorDialog(text);
      return;
    }
    if (freezeCTA) return;
    setFreezeCTA(true);
    let cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    cfy = rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: type,
    };
    body.pattern_data = pattern_data.data;
    confirmDialog(type, body);
  };

  const postData = (type, body) => {
    if (mode == "create")
      postCropPatternSize(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropPatternSize(body)
        .then((res) => {
          toast.success(
            type == "P" ? "Submit Succesfull!" : "Save Succesfull!"
          );
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        })
        .catch((err) => {
          toast.error(type == "P" ? "Submit Failed!" : "Save Failed!");
          console.log(err);
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        });
    }
  };

  function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
  }

  const errorDialog = (data) => {
    confirmAlert({
      title: "Validation Error!!",
      message: data,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  const confirmDialog = (type, body) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            postData(type, body);
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    let cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);

    // if(mode=="edit")
    cfy = rawData?.cfy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const pattern_data = convertCategoryData(updatedTableData);

    if (!pattern_data) return;
    body.pattern_data = pattern_data;

    if (mode == "create")
      postCropPatternSize(body)
        .then((res) => {
          setFreezeCTA(false);
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        })
        .catch((err) => {
          setFreezeCTA(false);
          toast.error("Submit Failed!");
          console.log(err);
        });

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropPatternSize(body)
        .then((res) => {
          setFreezeCTA(false);
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/mymarket/croppattern");
          }, 1000);
        })
        .catch((err) => {
          setFreezeCTA(false);
          toast.error("Submit Failed!");
          console.log(err);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/mymarket/croppattern");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      pattern_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postCropPatternAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };
  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();

  // to do later add location  in cropscenario table
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crop Pattern</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}

        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Crop Pattern
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            IN 000 ACRE
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{rawData?.cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {/*backend error check if data is being populated or not*/}
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={tableHeadData}
              initialData={initialData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals?.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  checkData("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  checkData("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Crops",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Season",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Crop Start Date",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2025-26",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2026-27",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2026-27",
//         type:"N",
//         isEditable:true,
//     },
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ]
// ]
