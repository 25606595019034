import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Sort from "../assets/images/sort.png";
// import Sidemenu from "./include/sidemenu";
// import Header from "./include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as ShiftDataService from "../../service/changeShift";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";

function CompAllMast() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [shiftDataFilter, setShiftDataFilter] = useState({
    user_id: "",
    department_id: "",
    personal_sub_area: "",
    date: "",
  });
  const [compMasterData, setCompMasterData] = useState([]);
  const [PSA, setPSA] = useState([]);
  const [recordCount, setRecordCount] = useState("");
  const [allEmp, setAllEmp] = useState([]);
  const [postingData, setPostingData] = useState([]);
  const [dept, setDept] = useState([]);
  const [compDates, setCompDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [empFilter, setEmpFilter] = useState([]);
  const [deptFilter, setDeptFilter] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    setFilterApplied(false);
    filtersetShow(false);
    setShiftDataFilter({
      ...shiftDataFilter,
      personal_sub_area: "",
      user_id: "",
      department_id: "",
    });
    setEmpFilter([]);
    setDeptFilter([]);
    getCompensatoryEmp(
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getCompensatoryEmp(
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      shiftDataFilter.date,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    getFilterData();
  }, [pageSizeNo]);

  const getFilterData = () => {
    ShiftDataService.getFilterData().then((response) => {
      let emp_obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.id,
          label: res?.fullname + "(" + res?.username + ")",
        };
      });
      setAllEmp(emp_obj);
      let dept_obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.department_id,
          label: res?.department_name,
        };
      });
      setDept(dept_obj);
      let unique = [];
      let psa_obj = response?.data?.dataList?.result
        ?.filter((obj) => {
          if (!unique.includes(obj?.personal_sub_area_id)) {
            unique.push(obj?.personal_sub_area_id);
            return true;
          }
        })
        .map((res) => {
          return {
            value: res?.psa_name,
            label: res?.psa_name + "-" + res?.psa_desc,
          };
        });
      setPSA(psa_obj);
    });
  };

  const handleKey = (
    user_id,
    department_id,
    personal_sub_area,
    date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (
      department_id !== "" &&
      department_id !== undefined &&
      department_id !== null
    ) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    if (
      personal_sub_area !== "" &&
      personal_sub_area !== undefined &&
      personal_sub_area !== null
    ) {
      queryParm = queryParm + "&personal_sub_area=" + personal_sub_area;
    }
    if (date !== "" && date !== undefined && date !== null) {
      queryParm = queryParm + "&date=" + date;
    }

    return queryParm;
  };

  const getCompensatoryEmp = (
    user_id,
    department_id,
    personal_sub_area,
    date,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    ShiftDataService.getCompEmp(
      handleKey(
        user_id,
        department_id,
        personal_sub_area,
        date,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setCompMasterData(response?.data?.dataList?.result);
      setCompDates(response?.data?.dataList?.compDates);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setRecordCount(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getCompensatoryEmp(
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      shiftDataFilter.date,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    getCompensatoryEmp(
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      shiftDataFilter.date,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filtersetShow(false);
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(compMasterData));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setCompMasterData(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.filter((res) => {
        if (res?.id != data?.id) {
          return true;
        }
      });
      console.log({ obj });
      setPostingData(obj);
      setCompMasterData(copyData1);
    }
  };

  const postAllData = () => {
    if (postingData.length > 0 && !postingData.includes(undefined)) {
      if (shiftDataFilter.date !== "") {
        let obj = { data: postingData };
        ShiftDataService.postData(obj).then((res) => {
          toast.success("Data Added Successfully");
          getCompensatoryEmp(
            shiftDataFilter.user_id,
            shiftDataFilter.department_id,
            shiftDataFilter.personal_sub_area,
            shiftDataFilter.date,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          setPostingData([]);
        });
      } else {
        toast.error("Kindly select compensatory date");
      }
    } else {
      toast.error("Please Select Records to Post");
    }
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(compMasterData));
    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        postingData.push(res);
      });
      setCompMasterData(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setCompMasterData(data);
      let obj = postingData?.map((x) => {
        if (x?.id != data?.id) {
          return x;
        }
      });
      setPostingData([]);
    }
  };
  const handleDateChange = (e) => {
    let date = e.target.value.split(" ").pop();
    setShiftDataFilter({
      ...shiftDataFilter,
      date: date,
    });
    getCompensatoryEmp(
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      date,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3">
                        <ul class="policylistnames">
                            <li class="active"><Link to="/comp-off">Compensatory Allowance Master</Link></li> 
                            <li><Link to="/comp-allowance">Compensatory Allowance</Link></li>
                        </ul>
                    </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">
                      Compensatory Allowance Master
                    </h4>
                    {/* <Breadcrumb>
                                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/">
                                                Me@Pi
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>Compensatory Allowance Master</Breadcrumb.Item>
                                        </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top  mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="innergroup">
                      <select
                        class="form-control newbgselect"
                        onChange={(e) => {
                          handleDateChange(e);
                        }}
                      >
                        <option>Select Compensatory Date</option>
                        <option value={compDates?.second_sat}>
                          {compDates?.second_sat}
                        </option>
                        <option value={compDates?.fourth_sat}>
                          {compDates?.fourth_sat}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="approval" id="approvaltabs">
                  <Tab
                    eventKey="approval"
                    title={
                      <React.Fragment>
                        Pending
                        <span class="numberpurchse">{recordCount}</span>
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    handleAllData(e);
                                  }}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </th>
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>PSA</th>
                            <th>Designation</th>
                            <th>Department</th>
                            {/* <th>Date</th> */}
                            {/* <th>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {compMasterData?.length > 0 ? (
                            compMasterData?.map((data) => {
                              return (
                                <tr>
                                  <td>
                                    <label class="logCheck d-inline-block">
                                      <input
                                        type="checkbox"
                                        name="permission"
                                        onClick={(e) => {
                                          selectDataToPost(e, data);
                                        }}
                                        checked={data?.checked === "Y"}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </td>
                                  <td class="">{data?.emp_code}</td>
                                  <td>{data?.fullname}</td>
                                  <td>{data?.psa}</td>
                                  <td>{data?.emp_designation}</td>
                                  <td>{data?.department}</td>
                                  {/* <td>{data?.attendance_date}</td> */}
                                  {/* <td>{data?.status}</td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>NO Records Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={500} label={500}>
                              500
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                    <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          <button
                            class="btn btn-success"
                            type="button"
                            onClick={postAllData}
                          >
                            <i class="far fa-check-circle"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee Name<span class="text-danger"></span>
            </label>
            <Select
              options={allEmp}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  user_id: e.value,
                });
                setCurrentPage(1);
                setEmpFilter(e);
              }}
              value={empFilter}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Department<span class="text-danger"></span>
            </label>
            <Select
              options={dept}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  department_id: e.value,
                });
                setCurrentPage(1);
                setDeptFilter(e);
              }}
              value={deptFilter}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Personal Sub-Area<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  personal_sub_area: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={shiftDataFilter?.personal_sub_area}
            >
              <option>Select</option>
              {PSA?.length > 0 &&
                PSA?.map((obj) => {
                  return (
                    <option value={obj?.value} label={obj?.label}></option>
                  );
                })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={mainDataFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompAllMast;
