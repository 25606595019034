import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AddDomestic from "./AddDomestic";
import { Alert, Modal } from "react-bootstrap";
import * as TravelListingAPI from "../../../service/travelService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectUserData, setDisplayLoader } from "../../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as DomesticService from "../../../service/domestic";
import * as TravelSrv from "../../../service/travellist";
import * as moment from "moment";
import { Tooltip } from "react-tippy";

if (typeof window !== "undefined") {
  injectStyle();
}

const Domestic = forwardRef((props, ref) => {
  const {
    checkActiveTab,
    condition,
    allGridData,
    allData,
    onViewClose,
    migrate,
    onCallBackTravel,
    setMigrate,
    onCallBackDomData,
    dataNew,
    mainData,
    userBackDate,
    flagSet,
    managerEditing,
    dynamicUserData,
  } = props;
  let location = useLocation();
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [outstandingAmt, setOutstandingAmt] = useState([]);
  const [domData, setDomData] = useState([]);
  const [callGrid, setCallGrid] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editDomestic, setEditDomestic] = useState(null);
  const [dataError, setDataError] = useState(false);
  const [validLastData, setValidLastData] = useState(false);
  const [detailArr, setDetailArr] = useState([]);
  const [changes, setChanges] = useState(false);
  const [currentData, setcurrentData] = useState([]);
  const [countDate, setCountDate] = useState(0);
  const [daysDiffNo, setDaysDiffNo] = useState();
  const [indexValue, setIndexValue] = useState(false);
  const [changeValueData, setChangeValueData] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [finalData, setFinalData] = useState({
    advance_amount: "",
  });
  const [checkAdvanceValidation, setCheckAdvanceValidation] = useState();
  const [advanceAmountShow, setAdvanceAmountShow] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [showButton, setShowButton] = useState(true);
  const [formView, setFormView] = useState(false);
  const [outstandingValues, setOutstandingValues] = useState();

  useImperativeHandle(ref, () => ({
    isDataPresent() {
      return domData.length > 0 ? true : false;
    },
  }));

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();
  const dispatch = useDispatch();

  const toggleButton = () => {
    setShowButton(!showButton);
  };

  useEffect(() => {
    setFinalData({ ...finalData, advance_amount: allData?.advance_amount });
  }, [migrate]);

  const getDomesticTemp = () => {
    TravelListingAPI.getDomesticTemp()
      .then((response) => {
        setDomData(response?.data?.dataList?.result);
        setCallGrid(false);
        if (response?.data?.dataList?.result?.length !== 0) {
          onCallBackDomData(true);
        } else {
          onCallBackDomData(false);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveTravelInfo = async (type) => {
    dispatch(setDisplayLoader("Display"));
    let mainArray = domData;
    let first = mainArray[0];
    let last = mainArray[mainArray.length - 1];
    let firstValue =
      first?.from_city_input == "" ? null : first.from_city_input;
    let toValue = last?.to_city_input == "" ? null : last.to_city_input;
    if (domData?.length >= 2 || currentData?.length >= 2) {
      if (
        first?.from_city_name !== last?.to_city_name ||
        firstValue !== toValue
      ) {
        setValidLastData(true);
        dispatch(setDisplayLoader("Hide"));
      } else {
        setValidLastData(false);
        let data = {};
        data["status"] = type;
        data["request_type"] = "Domestic";
        data["days_no"] = daysDiffNo;
        data["outstanding"] =
          allData?.outstanding === undefined
            ? outstandingAmt?.balance === ""
              ? 0
              : outstandingAmt?.balance
            : allData?.outstanding;
        data["travel_detail"] = domData;
        data["is_exception"] = check;
        if (finalData?.advance_amount == "") {
          data["advance_amount"] = 0;
        } else {
          data["advance_amount"] = finalData?.advance_amount;
        }
        if (managerEditing) {
          data["edit_by_manager_at"] = new Date().toISOString().split("T")[0];
          data["status"] = "A";
        }
        if (condition == "Domestic") {
          await TravelListingAPI.updateTravelInfo(data, allData?.id)
            .then((response) => {
              if (response.data.code == 200) {
                getOutstandingAmount(
                  managerEditing && dynamicUserData
                    ? dynamicUserData?.id
                    : userData?.id,
                  allData?.id
                );
                onViewClose();
                setCountDate(0);
                if (managerEditing) {
                  navigate("/travel-intimat-report");
                } else {
                  navigate("/travel-listing");
                }
                toast.success(response.data.message);
                setDataError(false);
                setFinalData({
                  advance_amount: "",
                });
              }
              dispatch(setDisplayLoader("Hide"));
            })
            .catch((error) => {
              dispatch(setDisplayLoader("Hide"));
              toast.warning(error?.response?.data?.message);
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
            });
        } else {
          await TravelListingAPI.saveTravelInfo(data)
            .then((response) => {
              if (response.data.code == 200) {
                let dataRes = response?.data?.dataList;
                getOutstandingAmount(
                  managerEditing && dynamicUserData
                    ? dynamicUserData?.id
                    : userData?.id,
                  dataRes?.id
                );

                if (managerEditing) {
                  navigate("/travel-intimat-report");
                } else {
                  navigate("/travel-listing");
                }
                toast.success(response.data.message);
                setDataError(false);
                deleteData(0);
                setFinalData({
                  advance_amount: "",
                });
                dispatch(setDisplayLoader("Hide"));
              }
            })
            .catch((error) => {
              dispatch(setDisplayLoader("Hide"));
              toast.warning(error.response.data.message);
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
            });
        }
      }
    } else {
      dispatch(setDisplayLoader("Hide"));
      setDataError(true);
    }
  };
  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setUserDetails(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getOutstandingAmount = (employee_code, travel_id) => {
    DomesticService.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);
        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteData = (id) => {
    TravelListingAPI.deleteData(id)
      .then((res) => {
        if (res.data.status === true) {
          getDomesticTemp();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deleteDataFromMain = (id) => {
    DomesticService.deleteDataFromMain(id)
      .then((res) => {
        if (res.data.status === true) {
          onCallBackTravel(allData?.id);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleDelete = (id) => {
    let deleteValue = domData?.filter((ele) => {
      if (ele.id !== id) {
        return true;
      }
    });
    setDomData(deleteValue);
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Data",
      message: `Are you sure to delete this record?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            migrate ? handleDelete(id) : deleteData(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const submitAll = (type) => {
    let save = type === "P" ? "submit" : "draft";
    let data = type;
    confirmAlert({
      title: `Want to ${save}`,
      message: `Are you sure, you want to ${save} tour?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => saveTravelInfo(data),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addShowRow = () => {
    setDataError(false);
    setFormView(true);
    setEditDomestic(null);
    setEdit(false);
  };

  const editDataMain = (data, index) => {
    setIndexValue(true);
    setEditDomestic(data);
    setEdit(true);
    setFormView(true);
    setShowButton(false);
  };

  const onClose = () => {
    setFormView(false);
    setEditDomestic(null);
    setShowButton(true);
  };

  const onAddCallBack = () => {
    getDomesticTemp();
  };

  const validCountYtd = () => {
    let fromYtdDate = domData[0]?.from_date;
    let last = domData[domData.length - 1];
    let toYtdDate = last?.to_date;
    calculateDateDifference(fromYtdDate, toYtdDate);
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysDiffNo(diffDays);
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (domData?.length > 0) {
      validCountYtd();
    }
  }, [domData]);

  useEffect(() => {
    if (migrate == false) {
      getDomesticTemp();
    }
  }, [callGrid]);

  useEffect(() => {
    if (checkActiveTab !== "Domestic") {
      setCallGrid(false);
    } else if (condition == undefined) {
      deleteData(0);
    }
  }, [checkActiveTab]);

  const update = (data) => {
    let dataData = domData;
    let newDetail = dataData?.map((obj) => {
      if (obj?.id === data?.id) {
        return data;
      } else {
        return obj;
      }
    });
    if (managerEditing) {
      data["added_by_manager"] = true;
    }
    // setDetailArr(newDetail);
    saveEditTravel(newDetail);
    setcurrentData(newDetail);
  };
  // const updateCurrent = (data) => {
  //   let x = domData.travel_detail_data;

  //   let newData = x?.map((obj) => {
  //     if (obj?.id === data?.id) {
  //       return data;
  //     } else {
  //       return obj;
  //     }
  //   });
  //   let newDetail = detailArr?.map((obj) => {
  //     if (obj?.id === data?.id) {
  //       return data;
  //     } else {
  //       return obj;
  //     }
  //   });
  //   setDetailArr(newDetail);
  //   setDomData((prev) => ({
  //     ...prev,
  //     travel_detail_data: newData,
  //   }));
  // };

  // useEffect(() => {
  //   if (migrate === false) {
  //     setDomData(allGridData);
  //   }
  // }, [migrate]);

  // useEffect(() => {
  //   if (migrate) {
  //     console.log("allGridData 1", allGridData);
  //     setDomData(allGridData);
  //     setDetailArr(allGridData);
  //   }
  // }, [allData]);

  const saveEditTravel = async (values) => {
    setChanges(false);
    let data = {};
    if (allData?.status == "R") {
      data["status"] = "R";
    } else if (allData?.status == "A") {
      data["status"] = "P";
    } else if (allData?.status == "P") {
      data["status"] = "P";
    } else {
      data["status"] = "D";
    }
    data["request_type"] = "Domestic";
    data["travel_detail"] = values;
    data["is_exception"] = check;
    data["outstanding"] =
      outstandingAmt?.balance === "" ? 0 : outstandingAmt?.balance;

    if (finalData?.advance_amount !== "") {
      data["advance_amount"] = finalData?.advance_amount;
    }
    if (managerEditing) {
      data["edit_by_manager_at"] = new Date().toISOString().split("T")[0];
      data["status"] = "A";
    }
    await TravelListingAPI.updateTravelInfo(data, allData?.id)
      .then((response) => {
        if (response.data.code === 200) {
          setDomData(values);
          setChanges(true);
          onCallBackTravel(allData?.id);
          setCallGrid(true);
          setValidLastData(false);
          onClose();
          // onViewClose();
          // toast.success("Successfully Updated");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const AddFunction = (data) => {
    let xx = {};
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      if (data[key] !== "") {
        xx[key] = value;
      }
    });
    let dataData = [...domData];
    if (managerEditing) {
      xx["added_by_manager"] = true;
    }
    console.log(`adding record..`, xx);
    dataData.push(xx);
    setCallGrid(true);
    saveAddTravel(dataData);
  };

  const saveAddTravel = async (values) => {
    let data = {};
    data["status"] = "D";
    data["request_type"] = "Domestic";
    data["travel_detail"] = values;
    data["is_exception"] = check;
    data["outstanding"] =
      outstandingAmt?.balance === "" ? 0 : outstandingAmt?.balance;

    if (finalData?.advance_amount !== "") {
      data["advance_amount"] = finalData?.advance_amount;
    }
    if (managerEditing) {
      data["edit_by_manager_at"] = new Date().toISOString().split("T")[0];
      data["status"] = "A";
    }
    await TravelListingAPI.updateTravelInfo(data, allData?.id)
      .then((response) => {
        if (response.data.code === 200) {
          // navigate("/travel-listing");
          setDomData(values);
          setChanges(true);
          onCallBackTravel(allData?.id);
          setCallGrid(true);
          onClose();
          // onViewClose();
          toast.success("Successfully Added");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (migrate && allData?.request_type == "Domestic") {
      setDomData(allGridData);
      setDetailArr(allGridData);
    }
  }, [allGridData]);

  useEffect(() => {
    if (migrate && mainData.length !== 0) {
      setDomData(mainData);
    }
  }, [mainData]);

  useEffect(() => {
    getUsersDetails(
      managerEditing && dynamicUserData
        ? dynamicUserData?.username
        : userData?.username
    );
    getOutstandingAmount(
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id,
      ""
    );
  }, []);

  const checkSave = (type) => {
    if (domData?.length >= 2 || currentData?.length >= 2) {
      submitAll(type);
    } else {
      saveTravelInfo(type);
    }
  };

  var check = false;
  const checkIsException = () => {
    const hasReasonForException = domData.some(
      (obj) => obj.reason_for_exception_id !== null
    );
    if (hasReasonForException) {
      check = true;
    } else {
      check = false;
    }
  };

  const [timeCheck, setTimeCheck] = useState([]);

  // const TravelTimeCheck = (data, type) => {
  //   TravelSrv.TravelTimeCheck(data)
  //     .then((response) => {
  //       const data = response?.data?.dataList?.result;
  //       setTimeCheck(response?.data?.dataList?.result);
  //       if (data.length === 0) {
  //         checkSave(type);
  //         checkIsException();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const TravelCityCheck = (data, type) => {
    TravelSrv.TravelCityCheck(data)
      .then((response) => {
        const data = response?.data?.message;
        setTimeCheck(response?.data?.message);
        if (data.length === 0) {
          checkSave(type);
          checkIsException();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const TravelAdvanceCheck = (data) => {
    TravelSrv.TravelAdvanceCheck(data)
      .then((response) => {
        const data = response?.data?.dataList?.is_exist;
        setCheckAdvanceValidation(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (userDetails[0]?.id !== undefined) {
      TravelAdvanceCheck(userDetails[0]?.id);
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      checkAdvanceValidation == true ||
      ((!["1003", "1007", "1017"].includes(
        userDetails[0]?.personal_area_name
      ) ||
        (["1003", "1007", "1017"].includes(
          userDetails[0]?.personal_area_name
        ) &&
          userDetails[0]?.sub_personal_area_name === "GG01")) &&
        userDetails[0]?.is_sf_user == true)
    ) {
      setAdvanceAmountShow(true);
    }
  }, [checkAdvanceValidation]);

  // const createTravelDatePayload = () => {
  //   const travelDates = Object.values(domData).map((entry) => {
  //     const fromDateTime = `${entry.from_date} ${entry.from_time.substring(
  //       0,
  //       5
  //     )}`;
  //     const toDateTime = `${entry.to_date} ${entry.to_time.substring(0, 5)}`;
  //     return [fromDateTime, toDateTime];
  //   });

  //   return { travel_date: travelDates.flat() };
  // };

  // const [newPayload, setNewPayload] = useState([]);
  // const createTravelCityPayload = () => {
  //   // const travelDates = Object.values(domData).map((entry) => {
  //   //   const fromDateTime = `${entry.from_date} ${entry.from_time.substring(
  //   //     0,
  //   //     5
  //   //   )}`;
  //   //   const toDateTime = `${entry.to_date} ${entry.to_time.substring(0, 5)}`;
  //   //   return [fromDateTime, toDateTime];
  //   // });

  //   // return { travel_date: travelDates.flat() };
  //   const newPayloadData = domData.map((detail) => {
  //     const {
  //       from_city_name,
  //       to_city_name,
  //       from_city_input,
  //       to_city_input,
  //       from_city_id,
  //       to_city_id,
  //     } = detail;

  //      const fromCity =
  //        from_city_name === "Other" ? { from_city_input } : { from_city_id };

  //      const toCity =
  //        to_city_name === "Other" ? { to_city_input } : { to_city_id };

  //      return { ...fromCity, ...toCity };
  //   });

  //   setNewPayload(newPayloadData);
  // };

  const newPayload = domData.map((travelDetail) => {
    const {
      from_city_name,
      from_city_input,
      from_city_id,
      to_city_name,
      to_city_input,
      to_city_id,
    } = travelDetail;

    const fromCity =
      from_city_name === "Other" ? { from_city_input } : { from_city_id };

    const toCity =
      to_city_name === "Other" ? { to_city_input } : { to_city_id };

    return { ...fromCity, ...toCity };
  });

  const timeCheckValidator = (type) => {
    // let dataCheckUserDetails = userDetails[0];
    // if (!dataCheckUserDetails?.business_unit_code) {
    //   toast.error(
    //     "You can not submit the request as business area is not available"
    //   );
    //   return;
    // } else if (!dataCheckUserDetails?.employee_vendor_code) {
    //   toast.error(
    //     "You can not submit the request as vendor code is not available"
    //   );
    //   return;
    // } else if (!dataCheckUserDetails?.cost_center_code) {
    //   toast.error(
    //     "You can not submit the request as cost center is not available"
    //   );
    //   return;
    // } else {
    const payload = newPayload;
    TravelCityCheck(payload, type);
    // }
  };

  useEffect(() => {
    let dataCheckUserDetails = userDetails[0];
    if (!dataCheckUserDetails?.reporting_person_id) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.business_unit_code) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.employee_vendor_code) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.cost_center_code) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [userDetails]);

  const checkToOrg = userDetails[0]?.organisation_code;

  const managerDeletingUserDA = (id, travelId, userId) => {
    TravelSrv.managerDeletingUserDA(id)
      .then((response) => {
        managerCreatingUserDA(travelId, userId);
      })
      .catch((error) => {
        if (error.response.data.message == "DA Data  Doesn't Exist.") {
          managerCreatingUserDA(travelId, userId);
        } else {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }
      });
  };

  const managerCreatingUserDA = (travelId, userId, is_ssc) => {
    TravelSrv.managerCreatingUserDA(travelId, userId, is_ssc)
      .then((response) => {
        // window.close();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSubmitSSC = () => {
    handleDateChange("P");
  };

  const handleUserDAChange = async () => {
    dispatch(setDisplayLoader("Display"));

    await managerDeletingUserDA(
      allData?.id,
      allData?.id,
      allData?.created_by_id
    );
    dispatch(setDisplayLoader("Hide"));
  };

  const handleDateChange = (type) => {
    let data = [];
    let citySameValue = false;
    domData?.map((value, i) => {
      if (
        value?.from_date == value?.to_date &&
        (value?.to_city_input || value?.to_city_name == dataNew[0]?.location) &&
        (value?.from_city_input ||
          value?.from_city_name == dataNew[0]?.location) &&
        value?.hq == true
      ) {
        data?.push(value);
        citySameValue = true;
      }
    });
    let tt = moment(data[data?.length - 1]?.to_time, "HH:mm").diff(
      moment(data[0]?.from_time, "HH:mm"),
      "hours"
    );
    if (
      (checkToOrg == "PIIL" || checkToOrg == "PIJA") &&
      tt > 17 &&
      changeValueData == true
    ) {
      let val = data?.map((ele) => {
        ele.reason_for_exception_id = "5";
        ele.reason_for_exception_name = "Other";
        ele.reason = "Traveled more than 17hr on same day";
        return ele;
      });

      let mergeArray =
        domData && domData.map((t) => (t.id == val.id ? val : t));
      setDomData(mergeArray);
      timeCheckValidator(type);
    } else {
      timeCheckValidator(type);
    }
  };

  const handleLength = (value) => {
    if (Number(value) <= 100000) {
      setFinalData((prev) => ({
        ...prev,
        advance_amount: value,
      }));
    }
  };

  useEffect(() => {
    if (allData?.status === "A") {
      setFinalData({ ...finalData, advance_amount: "" });
    }
  }, [allData]);

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-bordered tablecured viewpo">
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "30px",
                }}
              >
                S.No.
              </th>
              <th
                style={{
                  maxWidth: "40px",
                }}
              >
                Action
              </th>
              <th
                style={{
                  maxWidth: "65px",
                }}
              >
                Departure Location
              </th>
              <th
                style={{
                  maxWidth: "60px",
                }}
              >
                Reaching Location
              </th>
              <th
                style={{
                  maxWidth: "45px",
                }}
              >
                Purpose
              </th>
              <th
                style={{
                  maxWidth: "40px",
                }}
              >
                Mode
              </th>
              <th>HQ</th>
              <th
                style={{
                  maxWidth: "30px",
                }}
              >
                Stay
              </th>
              <th
                style={{
                  maxWidth: "80px",
                }}
              >
                Colleague <br />
                Empcode
              </th>
              <th
                style={{
                  maxWidth: "40px",
                }}
              >
                EX
              </th>
              <th
                style={{
                  maxWidth: "40px",
                }}
              >
                Reason
              </th>
              <th>Additional Information</th>
            </tr>
          </thead>
          <tbody>
            {domData?.length ? (
              domData?.map((data, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td
                      style={{
                        maxWidth: "45px",
                      }}
                    >
                      <button
                        className="btn btn-blanktd text-primary"
                        onClick={() => {
                          editDataMain(data, index);
                        }}
                      >
                        <i className="far fa-edit"></i>
                      </button>
                      {(managerEditing || flagSet != true) && (
                        <button
                          class="btn btn-blanktd text-danger ml-2"
                          type="button"
                          onClick={() => {
                            submit(data?.id);
                          }}
                        >
                          <i class="far fa-trash-alt text-danger"></i>
                        </button>
                      )}
                    </td>
                    <td
                      style={{
                        maxWidth: "80px",
                      }}
                    >
                      <div className="text-dark fw-bold f-14">
                        {data?.from_city_name === "Other"
                          ? data?.from_city_input
                          : data?.from_city_name}{" "}
                        - {data?.from_city_category}
                      </div>
                      <Tooltip
                        title={`${moment(data?.from_date).format(
                          "DD-MM-YYYY"
                        )} ${moment(data?.from_time, "h:mm A").format(
                          "hh:mm A"
                        )}`}
                      >
                        {moment(data?.from_date).format("DD-MM-YYYY")}&nbsp;
                        {moment(data?.from_time, "h:mm A").format("hh:mm A")}
                      </Tooltip>
                    </td>
                    <td
                      style={{
                        maxWidth: "80px",
                      }}
                    >
                      <div className="text-dark fw-bold f-14">
                        {data?.to_city_name === "Other"
                          ? data?.to_city_input
                          : data?.to_city_name}{" "}
                        - {data?.to_city_category}
                      </div>
                      <Tooltip
                        title={`${moment(data?.to_date).format(
                          "DD-MM-YYYY"
                        )} ${moment(data?.to_time, "h:mm A").format(
                          "hh:mm A"
                        )}`}
                      >
                        {moment(data?.to_date).format("DD-MM-YYYY")}&nbsp;
                        {moment(data?.to_time, "h:mm A").format("hh:mm A")}
                      </Tooltip>
                    </td>
                    <td
                      style={{
                        maxWidth: "70px",
                      }}
                    >
                      {data?.purpose}
                    </td>
                    <td
                      style={{
                        maxWidth: "40px",
                      }}
                    >
                      {data?.mode_name === undefined
                        ? data?.modee_name
                        : data?.mode_name}
                    </td>
                    <td>{data?.hq === true ? "Yes" : "No"}</td>
                    <td
                      style={{
                        maxWidth: "20px",
                      }}
                    >
                      {data?.stay_needed ? "Yes" : "No"}
                    </td>
                    <td
                      style={{
                        maxWidth: "120px",
                      }}
                    >
                      {data?.colleague_user_name !== "" ||
                      data?.colleague_user_name !== null ? (
                        <Tooltip
                          title={data?.colleague_user_code}
                          position="left"
                        >
                          {data?.colleague_user_name !== undefined &&
                            data?.colleague_user_code !== null &&
                            `${data?.colleague_user_name} (${data?.colleague_user_code})`}
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td
                      style={{
                        maxWidth: "40px",
                      }}
                    >
                      {" "}
                      {data?.reason_for_exception_name ? (
                        <i
                          class="fa fa-flag"
                          aria-hidden="true"
                          style={{ color: "red" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </td>
                    <td
                      style={{
                        maxWidth: "80px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip
                        title={data?.reason_for_exception_name}
                        position="left"
                      >
                        {data?.reason_for_exception_name
                          ? data?.reason_for_exception_name
                          : ""}
                      </Tooltip>
                    </td>
                    {/* <td>{data?.hq ? "Yes" : "No"}</td> */}

                    <td
                      style={{
                        maxWidth: "130px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip
                        title={data?.additional_information}
                        position="left"
                      >
                        {data?.additional_information}
                      </Tooltip>
                    </td>

                    {/* <td
                      style={{
                        maxWidth: "130px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {data?.additional_information}
                    </td> */}
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={12}> No Record</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row align-items-center">
        {showButton ? (
          <div className="col-md-12 text-sm-right">
            <button
              className="btn btnblanktd text-primary"
              onClick={() => {
                addShowRow();
                toggleButton();
              }}
              style={{ marginRight: "10vh" }}
            >
              <i className="far fa-plus-square mr-2"></i> Add New Row
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {timeCheck.length > 0 && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;{timeCheck}
        </Alert>
      )}
      {validLastData && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;First from location should be same last to location
        </Alert>
      )}
      {/* {dataError && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;Please enter atleast two rows with return journey details.
        </Alert>
      )} */}
      {formView && (
        <AddDomestic
          setCallGrid={setCallGrid}
          checkActiveTab={checkActiveTab}
          condition={condition}
          allGridData={allGridData}
          allData={allData}
          setTimeCheck={setTimeCheck}
          domData={domData}
          dataNew={dataNew}
          edit={edit}
          setChangeValueData={setChangeValueData}
          setEdit={setEdit}
          userBackDate={userBackDate}
          setValidLastData={setValidLastData}
          editDomestic={editDomestic}
          formView={formView}
          onClose={onClose}
          onAddCallBack={onAddCallBack}
          dataError={dataError}
          migrate={migrate}
          onCallBackTravel={onCallBackTravel}
          saveEditTravel={saveEditTravel}
          update={update}
          AddFunction={AddFunction}
          userDetailsLatest={userDetails}
          indexValue={indexValue}
          flagSet={flagSet}
          managerEditing={managerEditing}
          dynamicUserData={dynamicUserData}
        />
      )}

      <div className="mb-3">
        <div className="row">
          <div className="col-md-12 mt-3">
            <h5 className="text-theme border-bottom pb-2 mb-3 ml-2">
              <strong>
                {advanceAmountShow ? "Advance" : "Outstanding"} Details
              </strong>{" "}
            </h5>
          </div>
          <div className="col-md-4 ml-2">
            <div className="form-group innergroup">
              <label>
                Outstanding <i className="fas fa-rupee-sign"></i>
              </label>
              <input
                type="text"
                disabled
                value={`${outstandingAmt?.remark} : Rs.${
                  outstandingAmt?.balance == null
                    ? 0
                    : Math.round(outstandingAmt?.balance)
                } `}
                className="form-control"
              />
            </div>
            <div className="col-md-14">
              <Link onClick={addnewopen}>
                Click here to view (Outstanding Details)
              </Link>
            </div>
          </div>
          {advanceAmountShow && (
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Advance Amount <i className="fas fa-rupee-sign"></i>{" "}
                  <span className="text-danger"></span>
                </label>
                <input
                  type="number"
                  name="advance_amount"
                  disabled={
                    userDetails[0]?.personal_area_desc === "Marketing"
                      ? false
                      : userDetails[0]?.sub_personal_area_name === "GG01"
                      ? false
                      : userDetails[0]?.is_sf_user === true
                      ? false
                      : true
                  }
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  onKeyDown={blockInvalidChar}
                  placeholder="Enter Advance Amount"
                  onChange={(e) => {
                    handleLength(e.target.value);
                  }}
                  value={finalData?.advance_amount}
                />
              </div>
            </div>
          )}
        </div>
        {dataError && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>
            &nbsp;Please enter atleast two rows with return journey details.
          </Alert>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="border-top my-3"></div>
          </div>
          <div className="col-md-12 mt-3 text-center">
            {allData?.status === "P" ||
            allData?.status === "A" ||
            allData?.status === "R" ||
            allData?.status == "D" ? (
              ""
            ) : (
              <button
                type="button"
                className="btn btn-outline-primary mr-2"
                disabled={disableButton}
                onClick={() => {
                  // checkSave("P");
                  // checkIsException();
                  // timeCheckValidator("D");
                  handleDateChange("D");
                }}
                // onClick={() => {
                //   checkSave("D");
                //   checkIsException();
                // }}
              >
                <i class="far fa-check-circle"></i> Save As Draft
              </button>
            )}
            {managerEditing ? (
              <button
                type="button"
                class="btn btn-primary-inner mr-2"
                disabled={disableButton}
                onClick={() => {
                  handleSubmitSSC();
                }}
              >
                <i class="far fa-check-circle"></i> Done
              </button>
            ) : (
              <Tooltip
                title={
                  disableButton == true && !userDetails[0]?.business_unit_code
                    ? "You can not submit the request as business unit is not available"
                    : disableButton == true &&
                      !userDetails[0]?.employee_vendor_code
                    ? "You can not submit the request as vendor code is not available"
                    : disableButton == true && !userDetails[0]?.cost_center_code
                    ? "You can not submit the request as cost center is not available"
                    : ""
                }
                position="bottom"
              >
                <button
                  type="button"
                  class="btn btn-primary-inner mr-2"
                  disabled={disableButton}
                  onClick={() => {
                    handleDateChange("P");
                  }}
                >
                  <i class="far fa-check-circle"></i> Submit
                </button>
              </Tooltip>
            )}
            {/* {migrate ? ( */}
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={() => {
                if (allData == undefined) {
                  navigate("/travel-listing");
                } else {
                  onViewClose();
                }
              }}
            >
              <i class="far fa-arrow-alt-circle-left"></i> Back
            </button>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
      <div className="travel-note">
        <h4>Notes :-</h4>
        <ul>
          <li>
            Attendance and Travel claim will be marked/submit on the basis of
            approved tour intimation.
          </li>
          <li>Time to be filled as per 24 Hr clock.</li>
          <li>
            Select city form drop down history, in case it does not exit please
            enter by typing in the column.
          </li>
          <li>
            Ideally one line item to be created for a single day if there is no
            change in Travel Mode/purpose of travel by mentioning place visited
            in Additional info/Place Visited tab.
          </li>
          <li>
            We recommend for creation of travel intimation at interval of 10
            days for Sales & Marketing Staff.
          </li>
          <li>
            While selecting Travel mode/Stay employee needs to be more cautious,
            if employee select Hotel Stay YES then there will be a tab for hotel
            claim/Selection of any travel mode enable for claim under ticket
            column at time of submission of expense.
          </li>
          <li>
            In case employee choose Mode of transport as Personal Car then there
            will by option for claiming via VLB per KM rate
          </li>
        </ul>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div className="col-md-12">
              <div className="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Bill Date</th>
                      <th>Text</th>
                      <th>Assignment number</th>
                      <th>Document Number</th>
                      <th>Posting Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outstandingValues?.length > 0 ? (
                      outstandingValues?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value?.__values__?.DMBTR}</td>
                          <td>{value?.__values__?.WAERS}</td>
                          <td>
                            {moment(value?.__values__?.BLDAT).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td
                            style={{
                              maxWidth: "80vh",
                            }}
                          >
                            {value?.__values__?.SGTXT}
                          </td>
                          <td>{value?.__values__?.ZUONR}</td>
                          <td>{value?.__values__?.BELNR}</td>
                          <td>{value?.__values__?.BUDAT}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default Domestic;
