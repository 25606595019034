import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function TravelBills() {
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-conv_trip_from_date" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(100);
  const [pageCount, setPageCount] = useState(1);
  const [travelBills, setTravelBills] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [usersDropdown, setUsersDropdown] = useState([]);

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    trip_from: "",
    trip_to: "",
    from_date: "",
    to_date: "",
    search_by: "T",
    request_type: "",
    expense_status: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getTravelBills(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );
  };

  const calcMapData = (map_data, dat_type) => {
    let calculated = 0;
    switch (dat_type) {
      case "Self_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Self") {
            calculated += sub_data?.taxable_amount;
          }
        });

        break;
      case "Company_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Company") {
            calculated += sub_data?.taxable_amount;
          }
        });
        break;
      case "hotel":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.tax_bill_amount;
        });
        break;
      case "conveyance":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.amount;
        });
        break;
      default:
        console.log("invalid");
    }
    return calculated;
  };

  const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileView = (data) => {
    setFileObj(data);
    handleShow();
  };

  useEffect(() => {
    getUsersList();
    getTravelBills(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );
  }, [pageSizeNo]);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by,
    request_type,
    expense_status
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search_by !== "" && search_by !== undefined && search_by !== null) {
      queryParm = queryParm + "&search_by=" + search_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
      queryParm = queryParm + "&trip_from=" + trip_from;
    }
    if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
      queryParm = queryParm + "&trip_to=" + trip_to;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (
      expense_status !== "" &&
      expense_status !== undefined &&
      expense_status !== null
    ) {
      queryParm = queryParm + "&expense_status=" + expense_status;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      getTravelBillsWithDebounce(
        e.target.value,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        filterdata?.user_id?.value,
        filterdata?.trip_from,
        filterdata?.trip_to,
        filterdata?.from_date,
        filterdata?.to_date,
        filterdata?.search_by,
        filterdata?.request_type,
        filterdata?.expense_status
      );

      setSearchStr(e.target.value);
    }
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    getTravelBills(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );

    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   filterdata?.user_id,
    //   filterdata?.company,
    //   filterdata?.trip_number,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   true

    //   )

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //    "","","","","",false
    // );
    getTravelBills(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    setfilterData({
      user_id: { value: undefined },
      from_date: "",
      to_date: "",
      trip_from: "",
      trip_to: "",
      search_by: "T",
      request_type: "",
      expense_status: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getTravelBills = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by = "",
    request_type = "",
    expense_status = ""
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelBills(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date,
        search_by,
        request_type,
        expense_status
      )
    ).then((response) => {
      setTravelBills(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    };
  };

  const getTravelBillsWithDebounce = debounce(
    getTravelBills,
    2000
  );

  const getTravelBillsExport = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by,
    request_type, expense_status
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelBillsExport(
      "is_export=true" +
        `&search=` +
        search +
        `&sort_by=` +
        sort_by +
        (user_id ? `&user_id=` + user_id : ``) +
        `&trip_from=` +
        trip_from +
        `&trip_to=` +
        trip_to +
        `&from_date=` +
        from_date +
        `&to_date=` +
        to_date +
        `&search_by=` +
        search_by+
        `&request_type=` +
        request_type+
        `&expense_status=` +
        expense_status
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "All_Bills_Report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(setDisplayLoader("Hide"));

      // setTravelBills(response?.data?.dataList?.result)
      //   setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
    });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li class="active"><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-7">
                    <h4 class="inner-page-title">All Bills</h4>
                  </div>

                  <div className="col-md-5">
                      <div className="d-flex" style={{
                        fontSize: '14px',
                        justifyContent: 'space-between'
                      }}>
                        {/* {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                          <> */}
                            <div>
                              {/* <span className="fw-bold text-theme ml-2">{each?.leave_name}</span> */}
                              {/* <ul className=""> */}
                              
                                <span ><i style={{color:"yellow"}} class='fas fa-square'></i> Query raised</span>
                                <span className="ml-4"><i style={{color:"#FFBF00"}} class='fas fa-square'></i> Send back to User</span>
                                <span className="ml-4"><i style={{color:"#FFA895"}} class='fas fa-square'></i> Expense Exception</span>
                              {/* </ul> */}
                            </div>
                            <br />
                          {/* </>
                        ))} */}
                      </div>
                    </div>

                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong> Name, Employee ID, SAP num</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={surveyList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getTravelBillsExport(
                                  searchStr,
                                  "",
                                  "",
                                  sortData.sort_by,
                                  false,
                                  "",
                                  filterdata?.user_id?.value,
                                  filterdata?.trip_from,
                                  filterdata?.trip_to,
                                  filterdata?.from_date,
                                  filterdata?.to_date,
                                  filterdata?.search_by,
                                  filterdata?.request_type,
                                  filterdata?.expense_status

                                  
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Employee ID</th>
                      <th>Emp Name</th>
                      <th>Emp Vendor Code</th>
                      <th>Emp Grade</th>
                      <th>Location (HQ)</th>
                      <th>Zone</th>
                      <th>SAP TRIP No</th>
                      
                      <th>Trip Start Date</th>
                      <th>Trip End Date</th>
                      <th>TI Apply Date</th>
                      <th>TI Approve Date</th>
                      <th>TI User Delay Day</th>
                      <th>TI Boss Delay Days</th>
                      <th>Expense Applied Date</th>
                      <th>User Delay</th>
                      <th>Claim Amt.</th>
                      <th>Approved Amt</th>
                      <th>Ded./Add.</th>
                      <th>Penalty</th>
                      <th>Doc. Rec. On</th>
                      <th>App. By Exec.</th>
                      <th>App. By Head On</th>
                      <th>Status Message</th>
                      <th>Discard Reason</th>
                      <th>Check By</th>
                      <th>Del. By Exec.</th>
                      <th>Approved By</th>
                      <th>Del. By Head</th>
                      <th>Courier Name</th>
                      <th>Courier Number</th>
                      <th>Penalty Waive off</th>
                      <th>Exception in TI</th>
                      <th>Deviation in Expenses</th>
                      <th>Exp Deviation Approved BY</th>
                      <th>Exp Deviation Approved on Date</th>
                      <th>Reporting Head</th>
                      <th>HOD</th>
                      <th>HR</th>
                      <th>Travel From</th>
                      <th>Vehicle No.</th>
                      <th>KM Travelled</th>
                      <th>Fuel Qty</th>
                      <th>Fuel Claim Amt</th>
                      <th>Ticket Paid By Self Claim Amt</th>
                      <th>Ticket Paid By Company Claim Amt</th>
                      <th>Hotel Claim</th>
                      <th>DA Claim</th>
                      <th>Driver Wages Claim</th>
                      <th>Driver DA Claim</th>
                      <th>Toll / Puncturen Exp Claim</th>
                      <th>Washing Claim</th>
                      <th>Conveyance Claim</th>
                      <th>Fuel Approved Amt</th>
                      <th>Ticket Paid By Self Approved Amt</th>
                      <th>Ticket Paid By Copmany Approved Amt</th>
                      <th>Hotel Approved Amount</th>
                      <th>DA Approved</th>
                      <th>Driver Wages Approved</th>
                      <th>Driver DA Approved</th>
                      <th>Toll / Puncturen Exp Approved</th>
                      <th>Washing Approved</th>
                      <th>Conveyance Approved</th>
                      <th>Date of Joining</th>
                      {/* <th>Zone</th> */}
                      <th>Cost Center</th>
                      <th>Business Area</th>
                      <th>Profit Centre</th>
                      <th>E mail ID</th>
                      <th>Contact No</th>
                      <th>Approvers</th>
                      <th> Deviation in KM</th>
                      <th> Query Raised On</th>
                      <th> Query</th>
                      <th> Query Type(Hotel/DA/Ticket)</th>
                      <th>Request Type</th>
                      <th>Date on which claim fall in pick list</th>
                    </tr>
                  </thead>

                  <tbody>
                    {travelBills?.length > 0 ? (
                      travelBills?.map((data, idx) => {
                        return (
                          <tr
                          
                            style={{
                              backgroundColor: data["First Query Raised"]
                                ? "yellow"
                                :data?.ssc_user_send_back_to_requestor_remarks?"#FFBF00"
                                : data?.expense_exception
                                ? "#FFA895"
                                : "",
                            }}
                          >
                            <td>{pageSizeNo * (currentPage - 1) + idx + 1}</td>
                            <td class="">
                              {
                                <Link
                                  onClick={() =>
                                    handleFileView(data.attachments)
                                  }
                                >
                                  <button class="btn btn-blanktd text-primary">
                                    {data?.user_code}
                                  </button>
                                </Link>
                              }
                            </td>
                            <td>{data?.username}</td>
                            <td>
                              {data?.user_vendor_code
                                ? "0000" + data?.user_vendor_code
                                : ""}
                            </td>
                            <td>
                              {data["Emp Grade"] ? data["Emp Grade"] : "-"}
                            </td>
                            <td>{data["Location (HQ)"]}</td>
                            <td>{data?.sales_dist ? data?.sales_dist : "-"}</td>
                            <td>
                              <Link
                                to={`/travel-view-list?${data.id}`}
                                target="_blank"
                              >
                                {data?.sap_trip_number ?? "-"}
                              </Link>
                            </td>
                            
                            <td>
                              {data["Trip Start Date"] &&
                                moment
                                  .parseZone(data["Trip Start Date"])
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data["Trip End Date"] &&
                                moment
                                  .parseZone(data["Trip End Date"])
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data?.created_at &&
                                moment
                                  .parseZone(data?.created_at)
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data?.approval_date &&
                                moment
                                  .parseZone(data?.approval_date)
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data["TI User Delay Day"]}
                              {/* {
                                                            calculateDateDifference(
                                                                data?.travel_detail_data[0]?.created_at,
                                                                data?.travel_detail_data[
                                                                  data?.travel_detail_data?.length - 1
                                                                ]?.from_date
                                                              )
                                                              } */}
                            </td>
                            <td>{data["TI Boss Delay Days"]}</td>
                            <td>
                              {/* {moment(data?.travel_detail_data[0]?.created_at).format("DD-MM-YYYY")} */}
                              {data?.claim_date &&
                                moment
                                  .parseZone(data?.claim_date)
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>{data["User Delay"]}</td>
                            <td>
                              {data["Claim Amt."] == null
                                ? "-"
                                : Number(data["Claim Amt."]).toFixed(2)}
                            </td>
                            <td>
                              {data["Approved Amt"] == null ? ""
                                : Number(data["Approved Amt"])?.toFixed(2)
                                }
                            </td>
                            <td>
                              {data["Ded.Add."]}
                            </td>
                            <td>{data?.penalty}</td>
                            <td>
                              {data?.is_picked_at &&
                                moment
                                  .parseZone(data?.is_picked_at)
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data?.ssc_user_approved_at &&
                                moment
                                  .parseZone(data?.ssc_user_approved_at)
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {data["App. By Head On"] &&
                                moment
                                  .parseZone(data["App. By Head On"])
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td> 
                              
                              <Tooltip
                                title={data?.current_status}
                                position="bottom"
                              >
                                <div>{data?.current_status}</div>
                              </Tooltip>
                              </td>
                            <td>
                              {data?.discard_reason} 
                              {/* {data?.apprsec_remark} */}
                            </td>
                            <td>{data?.ssc_username}</td>
                            <td>{data["Del. By Exec."]}</td>
                            <td>{data["Approved By"]}</td>
                            <td>{data["Del. By Head"]}</td>
                            <td>{data["courier_company"]}</td>
                            <td>{data?.tracking_number}</td>
                            <td>{data["Penalty Waive off"]}</td>
                            <td>{data["Exception in TI"]}</td>
                            <td>{data["Deviation in Expenses"]}</td>
                            <td>
                              <Tooltip
                                title={data["Exp Deviation Approved BY"]}
                                position="bottom"
                              >
                                <div>{data["Exp Deviation Approved BY"]?.replaceAll("<br/>"," ")}</div>
                              </Tooltip>
                            </td>
                            <td>
                              {data["Exp Deviation Approved on Date"] &&
                                moment
                                  .parseZone(
                                    data["Exp Deviation Approved on Date"]
                                  )
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>{data?.reporting_user_name}</td>
                            <td>{data?.hod_name}</td>
                            <td>{data?.hr_username}</td>
                            <td>{data["Travel From"]}</td>
                            <td>{data["Vehicle No."]}</td>
                            <td>{data["KM Travelled"]}</td>
                            <td>{data["Fuel Qty"]}</td>
                            <td>{data["Fuel Claim Amt"]}</td>
                            <td>
                              {data["Ticket Paid By Self Claim Amt"]}
                              {/* {calcMapData(data?.tickett,"Self_ticket")}  */}
                            </td>
                            <td>
                              {data["Ticket Paid By Company Claim Amt"]}
                              {/* {calcMapData(data?.tickett,"Company_ticket")} */}
                            </td>
                            <td>
                              {data["Hotel Claim"]}
                              {/* {calcMapData(data?.hotell,"hotel")} */}
                            </td>
                            <td>{data["DA Claim"]}</td>
                            <td>{data["Driver Wages Claim"]}</td>
                            <td>{data["Driver DA Claim"]}</td>
                            <td>{data["Toll / Puncturen Exp Claim"]}</td>
                            <td>{data["Washing Claim"]}</td>
                            <td>{data["Conveyance Claim"]}</td>
                            <td>{data["Fuel Approved Amt"]}</td>
                            <td>{data["Ticket Paid By Self Approved Amt"]}</td>
                            <td>
                              {data["Ticket Paid By Company Approved Amt"]}
                            </td>
                            <td>{data["Hotel Approved Amount"]}</td>
                            <td>{data["DA Approved"]}</td>
                            <td>{data["Driver Wages Approved"]}</td>
                            <td>{data["Driver DA Approved"]}</td>
                            <td>{data["Toll / Puncturen Exp Approved"]}</td>
                            <td>{data["Washing Approved"]}</td>
                            <td>{data["Conveyance Approved"]}</td>
                            <td>
                              {data["Date of Joining"] &&
                                moment
                                  .parseZone(data["Date of Joining"])
                                  .format("DD-MM-YYYY")}
                            </td>

                            <td>{data?.cost_center_code}</td>
                            <td>{data["Business Area"]}</td>
                            <td>{data["Profit Centre"]}</td>
                            <td>{data?.user_email}</td>
                            <td>{data?.user_mobile_no}</td>
                            <td>
                              <Tooltip
                                title={data["Approvers"]}
                                position="bottom"
                              >
                                <div>{data["Approvers"]}</div>
                              </Tooltip>
                            </td>
                            <td>{data["Deviation in KM"]}</td>
                            <td>
                              {data["First Query Raised"] &&
                                moment
                                  .parseZone(data["First Query Raised"])
                                  .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <Tooltip
                                title={data["First Query Message"]}
                                position="bottom"
                              >
                                {data["First Query Message"]}
                              </Tooltip>
                            </td>
                            <td>-</td>
                            <td>{data?.request_type}</td>
                            <td>{data?.date_expense_fall_in_pick_list}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>No Records Found...</td>
                      </tr>
                    )}

                    <Modal
                      backdrop="static"
                      keyboard={false}
                      size="md"
                      className="modaldefaultclose modaldefaultclosecenter"
                      show={shows}
                      onHide={handleClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>View attachments</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div class="table-responsive mb-3">
                          <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                            <thead>
                              <tr>
                                <th>Expense Type</th>
                                <th>FILE NAME </th>
                                <th>VIEW</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fileObj.length > 0
                                ? fileObj?.map((adata, idx) => (
                                    <tr>
                                      <td>{adata?.expense_type}</td>
                                      <td>
                                        {adata?.attachment_path?.substring(
                                          adata?.attachment_path?.lastIndexOf(
                                            "/"
                                          ) + 1
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          target="_Blank"
                                          to={adata?.attachment_path}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                : "No records found"}
                            </tbody>
                          </table>
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer>
                                                                                                    <Button variant="secondary" onClick={handleClose}>
                                                                                                        Close
                                                                                                    </Button>
                                                                                                    </Modal.Footer> */}
                    </Modal>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="10" selected={pageSizeNo==10}>10</option>
                      <option value="20" selected={pageSizeNo==20}>20</option>
                      <option value="30" selected={pageSizeNo==30}>30</option>
                      <option value="50" selected={pageSizeNo==50}>50</option>
                      <option value="100" selected={pageSizeNo==100}>100</option>
                      <option value="200" selected={pageSizeNo==200}>200</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Request Type <span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  request_type: e.target.value,
                })
              }
              value={filterdata?.request_type}
            >
              <option value="">Select</option>
              <option value="Domestic">Domestic</option>
              <option value="International">International</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Expense Status <span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  expense_status: e.target.value,
                })
              }
              value={filterdata?.expense_status}
            >
              <option value="">Select</option>
              <option value="P">Pending</option>
              <option value="D">Discarded</option>
              <option value="A">Posted</option>
              <option value="R">Rejected</option>
              <option value="pick_list">In Pick List</option>
              <option value="executive">SSC Executive</option>
              <option value="manager">SSC Manager</option>
              <option value="head">SSC Head</option>
              
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata?.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip From <span class="text-danger"></span>
            </label>
            <input
              type="text"
              placeholder="Enter Start Location"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  trip_from: e.currentTarget.value,
                })
              }
              value={filterdata?.trip_from}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip To <span class="text-danger"></span>
            </label>
            <input
              type="text"
              placeholder="Enter End Location"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  trip_to: e.currentTarget.value,
                })
              }
              value={filterdata?.trip_to}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Search By <span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  search_by: e.target.value,
                })
              }
              value={filterdata?.search_by}
            >
              {/* <option value="">Select</option> */}
              <option value="T" selected={filterdata?.search_by == "T"}>
                T (Travel Intimation Date)
              </option>
              <option value="P" selected={filterdata?.search_by == "P"}>
                P (SSC Processed date + Pick List)
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TravelBills;
