import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as VehicalService from "../../service/VehicalService";
import moment from "moment/moment";
import { Tooltip } from "react-tippy";
import * as UserService from "../../service/userService";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import VehicalForm from "./JivagroVehicalForm";

function VehicleRequest() {
  const finalUser = useSelector(selectUserData);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const [vehicleData, setVehicleData] = useState([]);
  const filterclose = () => filtersetShow(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [usersDropdown, setUsersDropdown] = useState([]);

  const [filterdata, setfilterData] = useState({
    user_id: { value: 0 },
    from_date: "",
    to_date: "",
  });

  const [viewFlag, setviewFlag] = useState(false);
  const [editData, setEditVehicleData] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const editNotice = (data, ViewFlag) => {
    setEditVehicleData(data);
    setEditForm(true);
    addnewopen();
    setviewFlag(ViewFlag);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getChcekExpenseList(
      activePage,
      pageSizeNo,
      "-id",
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id.value,
      searchStr
    );
  };

  useEffect(() => {
    getUsersList();
    getChcekExpenseList(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id.value,
      searchStr
    );
  }, []);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getChcekExpenseList(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        filterdata?.from_date,
        filterdata?.to_date,
        filterdata?.user_id.value,
        e.target.value
      );

      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    getChcekExpenseList(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id.value,
      searchStr
    );

    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   filterdata?.user_id,
    //   filterdata?.company,
    //   filterdata?.trip_number,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   true

    //   )

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getChcekExpenseList(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      0,
      searchStr
    );
    setfilterData({
      user_id: { value: 0 },
      from_date: "",
      to_date: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  // const getExportValue = (search, page_no, page_Size, sort_by, paginate, status) => {
  //   // dispatch(setDisplayLoader("Display"));
  //   TravelListingAPI.getCheckExpenseExportValue(
  //     handleKey(search, page_no, page_Size, sort_by, paginate, status)
  //   )
  //     .then((response) => {
  //       // dispatch(setDisplayLoader("Hide"));
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const getChcekExpenseList = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    from_date,
    to_date,
    emp_code,
    search
  ) => {
    // dispatch(setDisplayLoader("Display"));

    VehicalService.getCheckExpenseList(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      from_date,
      to_date,
      emp_code,
      search
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setVehicleData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Check Expense</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              // onClick={() =>
                              //     getExportValue(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "pending", )
                              // }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="draft" id="approvaltabs">
                  <Tab
                    eventKey="draft"
                    title={
                      <React.Fragment>
                        In Process
                        <span class="numberpurchse">{pageCount}</span>
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Sno.</th>
                            <th>Action</th>
                            <th>Transaction Id</th>
                            <th>Emp Code</th>
                            <th>Relation</th>
                            <th>Vehicle Type</th>
                            <th>Vehicle Number</th>
                            <th>Valid from</th>
                            <th>Valid till</th>
                            <th>Attachment</th>
                            <th>License Number</th>
                            <th>DL Valid from</th>
                            <th>DL Valid till</th>
                            <th>DL Attachment</th>
                            <th>Applied on</th>
                            <th>Pending at</th>
                            <th>Status</th>

                            {/* <th>Approved on</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {vehicleData?.length > 0 ? (
                            vehicleData?.map((x, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div
                                      className="d-flex justify-content-around"
                                      style={{ width: "max-content" }}
                                    >
                                      {finalUser.groups.map(
                                        (data, idx) =>
                                          (data.name.toLowerCase() ==
                                            "ssc user" ||
                                            data.name.toLowerCase() ==
                                              "ssc manager") && (
                                            <>
                                              <Link
                                                to="/vehicle-check"
                                                state={{
                                                  from: x.id,
                                                }}
                                              >
                                                <button class="btn btn-primary-inner bpi-main px-2 py-1 f-14">
                                                  <i class="far fa-check-circle"></i>
                                                </button>
                                              </Link>
                                              <Link
                                                to="/print-vehicle-check"
                                                state={{
                                                  from: x.id,
                                                }}
                                              >
                                                <button class="btn btn-primary-inner bpi-main px-2 py-1 f-14 ml-2">
                                                  <i class="fas fa-print"></i>
                                                </button>
                                              </Link>
                                            </>
                                          )
                                      )}
                                      <button
                                        className="btn btn-blanktd text-primary ml-2"
                                        onClick={() => {
                                          editNotice(x, true);
                                        }}
                                      >
                                        <img src="images/eye.png" alt="" />
                                      </button>
                                    </div>
                                  </td>
                                  <td class="text-theme fw-bold">
                                    {`PIJAVR298${x.id}`}
                                  </td>
                                  <td class="text-theme fw-bold">
                                    {x.created_by_username}
                                  </td>
                                  <td>{x?.relation_type}</td>
                                  <td>{x?.vehicleType}</td>

                                  <td>{x?.vehicleNumber}</td>
                                  {x?.validFromDate !== null ||
                                  x?.validFromDate !== undefined ? (
                                    <td>
                                      {moment
                                        .utc(x?.validFromDate)
                                        .format("DD-MM-YYYY")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {x?.validTillDate !== null ||
                                  x?.validTillDate !== undefined ? (
                                    <td>
                                      {moment
                                        .utc(x?.validTillDate)
                                        .format("DD-MM-YYYY")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  <td>
                                    <a
                                      target="_blank"
                                      href={
                                        x?.attachment_path?.includes("https")
                                          ? x?.attachment_path
                                          : ""
                                      }
                                      rel="noreferrer"
                                    >
                                      <i class="fas fa-paperclip"></i>&nbsp;View
                                    </a>
                                  </td>
                                  <td>{x?.licenseNumber}</td>
                                  {x?.licenseValidFromDate !== null ||
                                  x?.licenseValidFromDate !== undefined ? (
                                    <td>
                                      {moment
                                        .utc(x?.licenseValidFromDate)
                                        .format("DD-MM-YYYY")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}

                                  {x?.licenseValidTillDate !== null ||
                                  x?.licenseValidTillDate !== undefined ? (
                                    <td>
                                      {moment
                                        .utc(x?.licenseValidTillDate)
                                        .format("DD-MM-YYYY")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  <td>
                                    <a
                                      target="_blank"
                                      href={
                                        (x?.dl_attachment_path).includes(
                                          "https"
                                        )
                                          ? x?.dl_attachment_path
                                          : ""
                                      }
                                      rel="noreferrer"
                                    >
                                      <i class="fas fa-paperclip"></i>&nbsp;View
                                    </a>
                                  </td>
                                  <td>
                                    {moment
                                      .utc(x?.created_at)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  {x?.status == "P" ? (
                                    <td>
                                      <Tooltip title={x?.pending_at}>
                                        {x?.pending_at}
                                      </Tooltip>
                                    </td>
                                  ) : x?.status == "ARM" ? (
                                    <td>SSC User</td>
                                  ) : (
                                    ""
                                  )}

                                  <td>
                                    {x?.status == "A" ? (
                                      <span class="badge bad-status badge-success">
                                        Approved{" "}
                                      </span>
                                    ) : x?.status == "P" ? (
                                      <span class="badge bad-status badge-warning">
                                        Pending{" "}
                                      </span>
                                    ) : x?.status == "ARM" ? (
                                      <span class="badge bad-status badge-warning">
                                        Partially Approved{" "}
                                      </span>
                                    ) : x?.status.includes("R") ? (
                                      <span class="badge bad-status badge-danger">
                                        Declined{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={19}>No Records Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select class="form-control">
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata?.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {addnewshow && (
        <VehicalForm
          open={addnewshow}
          onhide={addnewclose}
          data={editData}
          editForm={editForm}
          viewFlag={viewFlag}
        />
      )}
    </>
  );
}

export default VehicleRequest;
