import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import * as BrandService from "../../../service/brandService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewBrand from "./ViewBrand";
import AddBrand from "./AddBrand";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
if (typeof window !== "undefined") {
  injectStyle();
}

function Brand() {
  const dispatch = useDispatch();
  let location = useLocation();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [brandCategory, setbrandCategory] = useState([]);
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [policyList, setPolicyList] = useState([]);
  const [policy, setPolicy] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [branchcopy, setBranchDatacopy] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [allPolicy, setAllpolicy] = useState([]);
  const [dropPolicy, setDropPolicy] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [allOrg, setAllOrg] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [filterApplied, setFilterApplied] = useState(false);
  const [policyDataForExport, setPolicyDataForExport] = useState({
    branding_id: "",
    organisation_id: "",
    status: "",
    category: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    branding_id: "",
    organisation_id: "",
    status: "",
    category: "",
  });

  const getBranch = async () => {
    await BrandService.getBranch(false)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchDatacopy(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getBrandingCategory = (paginate) => {
    BrandService.getBrandingCategory(paginate)
      .then((response) => {
        setbrandCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleValue = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      branding_id !== "" &&
      branding_id !== undefined &&
      branding_id !== null
    ) {
      queryParm = queryParm + "&branding_id=" + branding_id;
    }
    return queryParm;
  };

  const getBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    BrandService.getBrand(
      handleValue(
        search,
        organisation_id,
        branding_id,
        category,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setPolicyList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      branding_id !== "" &&
      branding_id !== undefined &&
      branding_id !== null
    ) {
      queryParm = queryParm + "&branding_id=" + branding_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    BrandService.getExportValue(
      handleKey(
        search,
        organisation_id,
        branding_id,
        category,
        status,
        page_no,
        page_size,
        sort_by
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Brand.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getOrganisation = (paginate) => {
    BrandService.getOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const deleteBrand = (idx) => {
    BrandService.deleteBrand(idx)
      .then((res) => {
        if (res.data.status == true) {
          getBrand(
            "",
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          if (res.data.message.includes("can't")) {
            toast.warning(res.data.message);
          } else {
            toast.success(res.data.message);
          }
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, policyName) => {
    confirmAlert({
      title: "Delete Brand",
      message: `Are you sure to delete ${policyName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBrand(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addPolicy = () => {
    setShowForm(true);
    setEditMode(false);
    setPolicy(null);
  };
  const editPolicy = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setPolicy(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setPolicy(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getBrand(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branding_id,
      departmentDataFilter.category,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getBrand(
        e.target.value.toLowerCase(),
        departmentDataFilter.organisation_id,
        departmentDataFilter.branding_id,
        departmentDataFilter.category,
        departmentDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getBrand("", "", "", "", "", 1, pageSizeNo, sortData.sort_by, true);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;

    getBrand(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branding_id,
      departmentDataFilter.category,
      departmentDataFilter.status,
      activePage,
      10,
      "-id",
      true
    );
    setCurrentPage(activePage);
  };

  useEffect(() => {
    getBranch();
    getBrand(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branding_id,
      departmentDataFilter.category,
      departmentDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    getBrandingCategory(false);
  }, [pageSizeNo]);

  const getModule = (paginate) => {
    BrandService.getModule(paginate)
      .then((response) => {
        setAllpolicy(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllBrand = (paginate) => {
    BrandService.getAllBrand(paginate)
      .then((response) => {
        setDropPolicy(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    getModule(false);
    // getBrand(searchStr, "", "", "", "", 1, pageSizeNo, sortData.sort_by, true);
    getOrganisation(false);
  };

  const renderBrandingOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.title}`}
      />
    ));
  };
  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    setPolicyDataForExport({
      ...policyDataForExport,
      branding_id: departmentDataFilter.branding_id,
      organisation_id: departmentDataFilter.organisation_id,
      status: departmentDataFilter.status,
      category: departmentDataFilter.category,
    });
    const org = departmentDataFilter.organisation_id;
    const dept = departmentDataFilter.branding_id;
    const stats = departmentDataFilter.status;
    const category = departmentDataFilter.category;
    getBrand(
      searchStr,
      org,
      dept,
      category,
      stats,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getBrand(
      searchStr,
      departmentDataFilter.organisation_id,
      departmentDataFilter.branding_id,
      departmentDataFilter.category,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const handleShow = (e) => {
    // getBrand(
    //   searchStr.toLowerCase(),
    //   departmentDataFilter.organisation_id,
    //   departmentDataFilter.branding_id,
    //   departmentDataFilter.category,
    //   departmentDataFilter.status,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    setFilterApplied(false);
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    filterClose();
    getBrand(searchStr, "", "", "", "", 1, pageSizeNo, sortData.sort_by, true);
  };

  const filterClose = () => {
    filterclose();
  };

  return (
    <>
      {console.log(branchcopy)}
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Branding </h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Brand</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Brand Title,
                        <br />
                        Category Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Brand Title,Category Name On..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              policyDataForExport.organisation_id,
                              policyDataForExport.branding_id,
                              policyDataForExport.category,
                              policyDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addPolicy();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th
                  onClick={(e) => {
                    handlerData("title");
                  }}
                  className={
                    sortData["title"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["title"]
                  }
                >
                  Brand Title
                </th>
                <th
                  onClick={(e) => {
                    handlerData("category_id__name");
                  }}
                  className={
                    sortData["category_name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["category_name"]
                  }
                >
                  Category Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("organisation");
                  }}
                  className={
                    sortData["organisation"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["organisation"]
                  }
                >
                  Organization Name & Code
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Last updated by
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last updated on
                </th>
                <th>Approver</th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                <th>Approval Status</th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>

            <tbody>
              {policyList?.map((data, index) => (
                <>
                  <tr>
                    <td class="fw-bold text-theme">{data?.title}</td>
                    <td class="fw-bold text-theme">{data?.category_name}</td>
                    <td>
                      {data?.organisation.map(
                        (o) => o?.name + "(" + o?.code + "),"
                      )}
                    </td>

                    <td>{data?.updated_by}</td>
                    <td>
                      {" "}
                      {moment.utc(data?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>{data?.approved_at}</td>
                    <td>
                      {data?.is_active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          InActive
                        </span>
                      )}
                    </td>
                    <td>
                      {data?.status == "Approved" ? (
                        <span class="badge bad-status badge-success">
                          Approved{" "}
                        </span>
                      ) : data?.status == "Pending" || data?.status == null ? (
                        <span class="badge bad-status badge-warning">
                          Pending{" "}
                        </span>
                      ) : data?.status == "Declined" || data?.status == "R" ? (
                        <span class="badge bad-status badge-danger">
                          Declined{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>

                    {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? (
                      <td>
                        {finalUrlValue?.V ? (
                          <button
                            type="button"
                            className="btn btn-blanktd text-primary"
                            onClick={() => {
                              onView(data);
                            }}
                          >
                            <img src="images/eye.png" alt="" />
                          </button>
                        ) : (
                          ""
                        )}

                        {finalUrlValue?.E ? (
                          <button
                            className="btn btn-blanktd text-primary ml-2"
                            // disabled={data?.status === "Approved"}
                            onClick={() => {
                              editPolicy(data);
                            }}
                          >
                            <i className="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}

                        {finalUrlValue?.R ? (
                          <button
                            className="btn btn-blanktd text-danger ml-2"
                            onClick={() => {
                              submit(data?.id, data?.title);
                            }}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select className="form-control" onChange={handleShow}>
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Catgory</label>
            <select
              className="form-control main-pointer"
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="category"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  category: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={departmentDataFilter.category}
            >
              <option value="" label="Select Category" disabled />

              {renderOrgOptions(brandCategory)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select Organization</label>
            <select
              className="form-control main-pointer"
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="organisation_id"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  organisation_id: e.target.value,
                });
              }}
              value={departmentDataFilter.organisation_id}
            >
              <option value="" label="Select Organization" disabled />

              {renderOrgOptions(allOrg)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select Brand</label>
            <select
              className="form-control main-pointer"
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="branding_id"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  branding_id: e.target.value,
                });
              }}
              value={departmentDataFilter.branding_id}
            >
              <option value="" label="Select Brand" disabled />

              {renderBrandingOptions(policyList)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  status: e.target.value,
                });
              }}
              value={departmentDataFilter.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddBrand
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            branchcopy1={branchcopy}
            editPolicy={policy}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewBrand
            viewForm={viewForm}
            branchcopy1={branchcopy}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Brand;
