import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";
import { data } from "jquery";

export const savePrUnit = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/pr/pr_unit`, data);
};


export const addPrUnit = async (values) => axios.post(`${AUTH_BASE_URL}/pr/pr_unit`, values)
export const updatePrUnit = async (id, values) => axios.put(`${AUTH_BASE_URL}/pr/pr_unit/${id}`, values)

export const addPrType = async (values) => axios.post(`${AUTH_BASE_URL}/pr/pr_type`, values)
export const updatePrType = async (id, values) => axios.put(`${AUTH_BASE_URL}/pr/pr_type/${id}`, values)


export const getPrUnits = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_unit?${queryParm}`);
}

export const deletePrUnit = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/pr/pr_unit/${id}`);
}

export const getPrTypes = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_type?${queryParm}`);
}

export const deletePrTyoe = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/pr/pr_type/${id}`);
}

export const getPrUserAuthorizations = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_user_authorization?${queryParm}`);
}

export const addPrUserAuthorization = async (values) => {
  return axios.post(`${AUTH_BASE_URL}/pr/pr_user_authorization`, values);
}

export const updatePrUserAuthorization = async (id, values) => {
  return axios.put(`${AUTH_BASE_URL}/pr/pr_user_authorization/${id}`, values);
}

export const getPlantsList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/master-plant/list`, { params: queryParm });
}

export const deletePrUserAuthorization = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/pr/pr_user_authorization/${id}`);
}

export const getCurrencyList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-currency/list?organisation_id=${organisation_id}`
  );
};

export const getGlCodeList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-gl-code/list?organisation_id=${organisation_id}`
  );
};

export const getVendorsList = async (search) => {
  return axios.get(
    `${AUTH_BASE_URL}/vendor-code/drop-down/undefined?search=${search}`
  );
};

export const addPrTransaction = async (values) => {
  return axios.post(`${AUTH_BASE_URL}/pr/pr_transaction_add_pr`, values);
};
export const updatePrTransaction = async (values) => {
  return axios.put(`${AUTH_BASE_URL}/pr/pr_transaction_item`, values);
};

export const getMaterialListByPlant = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/buss-transactions/material-list`, data);
};
export const getMaterialGroup= async () => {
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/material-group`);
};

export const getPrTransactions = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_transaction_item`, { params });
}
export const getPrApprovalData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/get_approval_data?${queryParm}`);
}
export const getPrReports = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_transaction_item?${queryParm}`);
}
export const getSapPrReports = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/pr/get_status_report?${queryParm}`);
}
export const getPrTransactionsApproval = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_transaction_item/approval_data`, { params });
}
export const changeprstatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/pr/pr_transaction_submit`, data);
}

export const getExportValueTypeMaster = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_type_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueUnitMaster = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_unit_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValueUserMaster = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_user_authorization_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValuePrList = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_transaction_item_export`,
    method: "GET",
    responseType: "blob",
    params
  });
};

export const getExportPRReport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_itemwise_report_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportSAPPRReport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/pr/pr_sap_report_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getAssetCodes = async (search, params) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_asset_code?search=${search}`, { params });
}

export const getOrders = async (plant_id) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_order_no?plant_id=${plant_id}`);
}

export const getWbsElements = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_wbs_element`, { params });
}

export const getNetworks = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/pr/pr_network_no`, { params });
}

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const pullPrFromSap = async (values) => {
  return axios.post(`${AUTH_BASE_URL}/pr/sync_pr`, values);
};

export const getMaterialPrice = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/pr/get_material_price?material_id=${id}`);
}

export const getMaterialStock = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/pr/get_material_stock?material_id=${id}`);
}

export const deletePrItem = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/pr/pr_delete_item/${id}`);
}

export const getServiceNo = async (service_no) => {
  return axios.get(`${AUTH_BASE_URL}/pr/get_service_no?service_no=${service_no}`);
}

export const deletePR = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/pr/pr_delete/${id}`);
}

export const reSubmitPR = async (id) => {
  return axios.put(`${AUTH_BASE_URL}/pr/pr_re_submit/${id}`);
};

export const getMaterialDataByDesc = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/material_creation/similar_material?${queryParm}`);
}