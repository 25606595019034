import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import Select from "react-select";
import {
  getPersonalAreaByArea,
  getPersonalAreaByOrg,
  getRolesList,
} from "../../service/userService";
import { getOrganisation } from "../../service/brandingCategory";
import { getBusinessList } from "../../service/businessService";
import { getDepartment } from "../../service/department";
import { getDesignation } from "../../service/designation";
import {
  autoRuleCreate,
  autoRuleDelete,
  autoRuleUpdate,
  getAutoRoleRecords,
  getEmpSubGroups,
} from "../../service/roleRightMgt";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const AutoRoleAssign = () => {
  let condition = "true";
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [searchStr, setSearchStr] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [dept, setDept] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [personalArea, setPersonalArea] = useState([]);
  const [personalSubArea, setPersonalSubArea] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [view, isView] = useState(false);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [empSubGroup, setEmpSubGroup] = useState([]);
  const userTypes = [
    {
      label: "SF",
      value: "SF",
    },
    {
      label: "Non SF",
      value: "NSF",
    },
  ];

  const [formObj, setFormObj] = useState({
    role: {},
    organization: [],
    businessUnit: [],
    department: [],
    designation: [],
    empSubGroup: "",
    personalArea: [],
    personalSubArea: [],
    isRM: "N",
    isHR: "N",
    isHOD: "N",
    isSF: {},
  });

  const [errors, setErrors] = useState({
    errRole: false,
    errOrganization: false,
    errBusinessUnit: false,
    errDepartment: false,
    errDesignation: false,
    errEmpSubGroup: false,
    errPersonalArea: false,
    errPersonalSubArea: false,
    errIsRM: false,
    errIsHR: false,
    errIsHOD: false,
    errIsSF: false,
  });

  useEffect(() => {
    getRecords(searchStr, 1, pageSizeNo, sortData.sort_by);
    getData();
    getEmpSubGroup();
  }, []);

  useEffect(() => {
    getRecords(searchStr, 1, pageSizeNo, sortData.sort_by);
  }, [pageSizeNo]);

  const getEmpSubGroup = async () => {
    await getEmpSubGroups().then((response) => {
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res.id,
          label: res.emp_sub_group,
        };
      });
      setEmpSubGroup(obj);
    });
  };

  const getRecords = async (search, page_no, page_size, sort_by) => {
    await getAutoRoleRecords(
      handleKey(search, page_no, page_size, sort_by)
    ).then((response) => {
      if (response?.status === 200) {
        setTotalRecords(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  const getData = async () => {
    await getRoleList();
    await getOrgList();
  };

  const getBranchData = () => {
    getBranchDepartments();
    getBranchDesignation();
  };

  const getOrganizationData = () => {
    getBusinessUnits();
    getPersonalArea();
  };

  const getPersonalSubArea = async () => {
    let areas = personalArea?.map((obj) => obj.value).toString();
    let queryParam = "personal_area_id=" + areas;
    await getPersonalAreaByArea(queryParam).then((response) => {
      if (response?.status === 200) {
        let subArea = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj?.name,
            value: obj?.id,
          };
        });
        setPersonalSubArea(subArea);
      }
    });
  };

  const getPersonalArea = async () => {
    let orgIds = orgList?.map((obj) => obj.value).toString();
    let queryParam = "orgIds=" + orgIds;
    await getPersonalAreaByOrg(queryParam).then((response) => {
      if (response?.status === 200) {
        let personalAreas = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.name}`,
            value: obj?.id,
          };
        });
        setPersonalArea(personalAreas);
      }
    });
  };

  const getBranchDesignation = async () => {
    let branchIds = businessUnits?.map((obj) => obj?.id);
    let queryParam = "paginate=" + false + "&business_unit_id=" + branchIds;
    await getDesignation(queryParam).then((response) => {
      if (response?.status === 200) {
        let designations = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.name} (${obj?.code})`,
            value: obj?.id,
          };
        });
        setDesignationList(designations);
      }
    });
  };

  const getBranchDepartments = async () => {
    let branchIds = businessUnits?.map((obj) => obj?.id);
    let queryParam = "paginate=" + false + "&business_unit_id=" + branchIds;
    await getDepartment(queryParam).then((response) => {
      if (response?.status === 200) {
        let departmentList = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.name} (${obj?.code})`,
            value: obj?.id,
          };
        });
        setDept(departmentList);
      }
    });
  };

  const getBusinessUnits = async () => {
    console.log("orgList", orgList);
    let orgIds = orgList?.map((obj) => obj.value).toString();
    let queryParam = "paginate=" + false + "&organisation_id=" + orgIds;
    await getBusinessList(queryParam).then((response) => {
      if (response?.status === 200) {
        let branchList = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.name} (${obj?.code})`,
            value: obj?.id,
          };
        });
        setBusinessUnits(branchList);
      }
    });
  };

  const getOrgList = async () => {
    await getOrganisation(false).then((response) => {
      if (response?.status === 200) {
        let orgs = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.name} (${obj?.code})`,
            value: obj?.id,
          };
        });
        setOrgList(orgs);
      }
    });
  };

  const getRoleList = async () => {
    await getRolesList(false).then((response) => {
      if (response?.status === 200) {
        let roles = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj.name,
            value: obj.id,
          };
        });
        setRoleList(roles);
      }
    });
  };

  const onClose = () => {
    setFormObj({
      role: {},
      organization: [],
      businessUnit: [],
      department: [],
      designation: [],
      empSubGroup: "",
      personalArea: [],
      personalSubArea: [],
      isRM: "N",
      isHR: "N",
      isHOD: "N",
      isSF: {},
    });
    setShowForm(false);
    setEdit(false);
  };

  const handleChange = (value, name) => {
    console.log("Vahandlechange", value, name);
    switch (name) {
      case "role":
        setFormObj((prev) => ({
          ...prev,
          role: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errRole: false,
        }));
        break;
      case "org":
        setFormObj((prev) => ({
          ...prev,
          organization: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errOrganization: false,
        }));
        break;
      case "bu":
        setFormObj((prev) => ({
          ...prev,
          businessUnit: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errBusinessUnit: false,
        }));
        break;
      case "dept":
        setFormObj((prev) => ({
          ...prev,
          department: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errDepartment: false,
        }));
        break;
      case "designation":
        setFormObj((prev) => ({
          ...prev,
          designation: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errDesignation: false,
        }));
        break;
      case "group":
        setFormObj((prev) => ({
          ...prev,
          empSubGroup: value.label,
        }));

        break;
      case "area":
        setFormObj((prev) => ({
          ...prev,
          personalArea: value,
        }));
        break;
      case "subArea":
        setFormObj((prev) => ({
          ...prev,
          personalSubArea: value,
        }));
        break;
      case "user":
        setFormObj((prev) => ({
          ...prev,
          isSF: value,
        }));
        setErrors((prev) => ({
          ...prev,
          errIsSF: false,
        }));
        break;
      case "isRM":
        if (value.target.id === "true") {
          setFormObj((prev) => ({
            ...prev,
            isRM: "Y",
          }));
          break;
        } else {
          setFormObj((prev) => ({
            ...prev,
            isRM: "N",
          }));
          break;
        }
      case "isHR":
        if (value.target.id === "hrTrue") {
          setFormObj((prev) => ({
            ...prev,
            isHR: "Y",
          }));
          break;
        } else {
          setFormObj((prev) => ({
            ...prev,
            isHR: "N",
          }));
          break;
        }
      case "isHOD":
        if (value.target.id === "hodTrue") {
          setFormObj((prev) => ({
            ...prev,
            isHOD: "Y",
          }));
          break;
        } else {
          setFormObj((prev) => ({
            ...prev,
            isHOD: "N",
          }));
          break;
        }
      default:
        break;
    }
  };

  const saveRule = async () => {
    if (
      formObj?.isSF != undefined &&
      formObj?.organization?.length > 0 &&
      formObj?.role != undefined
    ) {
      if (!edit) {
        if (condition === "true") {
          condition = "false";
          await autoRuleCreate(formObj)
            .then((response) => {
              if (response?.status === 200) {
                condition = "true";
                toast.success(response?.data?.message);
                getRecords("", 1, pageSizeNo, sortData.sort_by);
                setFormObj({
                  role: undefined,
                  organization: undefined,
                  businessUnit: undefined,
                  department: undefined,
                  designation: undefined,
                  empSubGroup: undefined,
                  personalArea: undefined,
                  personalSubArea: undefined,
                  isRM: "N",
                  isHR: "N",
                  isHOD: "N",
                  isSF: undefined,
                });
                setShowForm(false);
              }
            })
            .catch((error) => {
              toast.error(error?.response?.data?.detail);
            });
        }
      } else {
        await autoRuleUpdate(formObj).then((response) => {
          console.log(response);
        });
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        errIsSF: formObj?.isSF == undefined ? true : false,
        errOrganization: formObj?.organization?.length == 0 ? true : false,
        errRole: formObj?.role == undefined ? true : false,
      }));
      console.log("hello");
    }
  };

  const viewData = (data) => {
    isView(true);
    setSelectedData(data);
  };

  useEffect(() => {
    setFormObj((prev) => ({
      ...prev,
      id: selectedData?.id,
      role: selectedData?.role,
      organization: selectedData?.organization.map((obj) => {
        return {
          label: `${obj?.name} (${obj?.code})`,
          value: obj?.id,
        };
      }),
      businessUnit: selectedData?.businessUnit.map((obj) => {
        return {
          label: `${obj?.name} (${obj?.code})`,
          value: obj?.id,
        };
      }),
      department: selectedData?.department.map((obj) => {
        return {
          label: `${obj?.name} (${obj?.code})`,
          value: obj?.id,
        };
      }),
      designation: selectedData?.designation.map((obj) => {
        return {
          label: `${obj?.name} (${obj?.code})`,
          value: obj?.id,
        };
      }),
      empSubGroup: selectedData?.empSubGroup,
      personalArea: selectedData?.personalArea.map((obj) => {
        return {
          label: `${obj?.name}`,
          value: obj?.id,
        };
      }),
      personalSubArea: selectedData?.personalSubArea.map((obj) => {
        return {
          label: `${obj?.name}`,
          value: obj?.id,
        };
      }),
      isRM: selectedData?.isRM === true ? "Y" : "N",
      isHR: selectedData?.isHR === true ? "Y" : "N",
      isHOD: selectedData?.isHOD === true ? "Y" : "N",
      isSF: selectedData?.isSF,
    }));
  }, [edit]);

  const editData = (obj) => {
    setSelectedData(obj);
    setEdit(true);
    setShowForm(true);
  };

  const handleKey = (search, page_no, page_size, sort_by) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Rule",
      message: `Are you sure to delete the rule.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecord = async (id) => {
    await autoRuleDelete(id)
      .then((response) => {
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          getRecords("", 1, pageSizeNo, sortData.sort_by);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getRecords(searchStr, activePage, pageSizeNo, sortData.sort_by);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      // isLoaderActive(true);
      getRecords(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getRecords(data, 1, pageSizeNo, sortData.sort_by);
    }
  };

  return (
    <div className="content-wrapper-inner">
      <ToastContainer autoClose={1000} />
      <div className="innerheadsec">
        <div className="row">
          <div className="col-md-12">
            <h4 className="inner-page-title">Auto Role Assign</h4>
          </div>
          <div className="col-md-12">
            <div className="border-top mt-2 mt-md-4 mb-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip
                  className="bs-tooltip-end tooltip"
                  id="overlay-example"
                >
                  <div class="tooltip-inner text-left">
                    Search on <strong> Role</strong>
                  </div>
                </Tooltip>
              }
            >
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  id="searchbar"
                  type="text"
                  class="form-control"
                  placeholder="Search on Role..."
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </OverlayTrigger>
          </div>
          <div className="col-lg-8">
            <div className="inline-spacing btn-betweenspaing">
              {/* <Dropdown show>
                <Dropdown.Toggle
                  variant="primary-inner dropdownbtn"
                  id="dropdown-basic"
                >
                  <img src="images/export.png" alt="" className="mr-2" />
                  Export
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#">
                    <button
                      style={{ border: 0, background: "none" }}
                        onClick={() =>
                          getExportDetails(
                            searchStr,
                            moduleDataFilter.organisation_id,
                            moduleDataFilter.status,
                            1,
                            pageSizeNo,
                            sortData.sort_by,
                            true
                          )
                        }
                    >
                      <i className="far fa-file-excel"></i>Excel
                    </button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}

              {/* <button
                onClick={() => {
                  filterShow();
                }}
                className="btn btn-secondary-inner"
              >
                <img src="images/filter.png" alt="" className="mr-3" />
                Filter
              </button> */}
              {/* {finalUrlValue?.A ? ( */}
              <button
                className="btn btn-primary-inner"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <img src="images/upload.png" alt="" className="mr-3" />
                Add New
              </button>
              {/* ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered tablecured">
          <thead>
            <tr>
              <th>Role</th>
              <th>Organization</th>
              <th>Business Units</th>
              <th>Department</th>
              <th>Designation</th>
              <th>Personal Area</th>
              <th>Personal Sub Area</th>
              <th>Employee Sub Group</th>
              <th>Is RM</th>
              <th>Is HR</th>
              <th>Is HOD</th>
              <th>User Type</th>
              <th>Last Updated By</th>
              <th>Last Updated On</th>
              {/* <th>Status</th> */}
              {finalUrlValue?.V || finalUrlValue?.R ? <th>Actions</th> : ""}
            </tr>
          </thead>
          <tbody>
            {totalRecords?.length > 0 ? (
              totalRecords.map((obj) => {
                return (
                  <tr>
                    <td>{obj?.role?.label}</td>
                    <td>{obj?.organization?.length}</td>
                    <td>{obj?.businessUnit?.length}</td>
                    <td>{obj?.department?.length}</td>
                    <td>{obj?.designation?.length}</td>
                    <td>{obj?.personalArea?.length}</td>
                    <td>{obj?.personalSubArea?.length}</td>
                    <td>{obj?.empSubGroup}</td>
                    <td>{String(obj?.isRM)}</td>
                    <td>{String(obj?.isHR)}</td>
                    <td>{String(obj?.isHOD)}</td>
                    <td>{obj?.isSF?.label}</td>
                    <td>{obj?.created_by}</td>
                    <td>
                      {moment.utc(obj?.created_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    {/* <td>True</td> */}
                    {finalUrlValue?.V || finalUrlValue?.R ? (
                      <td>
                        {finalUrlValue?.V ? (
                          <button
                            type="button"
                            className="btn btn-blanktd text-primary"
                            onClick={() => viewData(obj)}
                          >
                            <img src="images/eye.png" alt="" />
                          </button>
                        ) : (
                          ""
                        )}
                        {/* <button
                          className="btn btn-blanktd text-primary ml-2"
                          onClick={() => {
                            editData(obj);
                          }}
                        >
                          <i className="far fa-edit"></i>
                        </button> */}
                        {finalUrlValue?.R ? (
                          <button
                            className="btn btn-blanktd text-danger ml-2"
                            onClick={() => {
                              submit(obj?.id);
                            }}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={12}>No records found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-sm-5">
          <div className="sortinglist">
            Show
            <select
              style={{ textAlign: "center" }}
              className="form-control"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option className="main-pointer" value={10} label={10} />
              <option className="main-pointer" value={20} label={20} />
              <option className="main-pointer" value={30} label={30} />
              <option className="main-pointer" value={100} label={100} />
            </select>
          </div>
        </div>
        <div className="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
      <Modal
        show={showForm}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title> {edit === true ? "Edit" : "Add"} Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Role
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="role"
                      options={roleList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={formObj?.role}
                    />
                    <div
                      hidden={!errors?.errRole}
                      className="small text-danger"
                    >
                      Kindly select Role
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Organization
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      name="org"
                      options={orgList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      onBlur={getOrganizationData}
                      value={formObj?.organization}
                    />
                    <div
                      hidden={!errors?.errOrganization}
                      className="small text-danger"
                    >
                      Kindly select Organization
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Business Unit</label>
                    <Select
                      isMulti
                      name="bu"
                      options={businessUnits}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      onBlur={getBranchData}
                      value={formObj?.businessUnit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <Select
                      isMulti
                      name="dept"
                      options={dept}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={formObj?.department}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Designation</label>
                    <Select
                      isMulti
                      name="designation"
                      options={designationList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={formObj?.designation}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Employee Sub Group</label>
                    <Select
                      // isMulti
                      name="group"
                      options={empSubGroup}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      // value={formObj?.empSubGroup}
                    />
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Employee Sub Group"
                      name="group"
                      onChange={(e) => handleChange(e.target.value, "group")}
                      value={formObj?.empSubGroup}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <Select
                      isMulti
                      name="area"
                      options={personalArea}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      onBlur={getPersonalSubArea}
                      value={formObj?.personalArea}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Personal Sub Area</label>
                    <Select
                      isMulti
                      name="subArea"
                      options={personalSubArea}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={formObj?.personalSubArea}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">
                      Is RM
                      <span className="text-danger">*</span>
                    </label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="true"
                    >
                      Yes
                      <input
                        type="radio"
                        name="is_true"
                        id="true"
                        onChange={(e) => {
                          handleChange(e, "isRM");
                        }}
                        checked={formObj?.isRM === "Y"}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="logCheck d-inline-block" htmlFor="false">
                      No
                      <input
                        type="radio"
                        name="is_false"
                        id="false"
                        onChange={(e) => {
                          handleChange(e, "isRM");
                        }}
                        checked={formObj?.isRM === "N"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">
                      Is HR
                      <span className="text-danger">*</span>
                    </label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="hrTrue"
                    >
                      Yes
                      <input
                        type="radio"
                        name="is_hrTrue"
                        id="hrTrue"
                        onChange={(e) => {
                          handleChange(e, "isHR");
                        }}
                        checked={formObj?.isHR === "Y"}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="hrFalse"
                    >
                      No
                      <input
                        type="radio"
                        name="is_hrFalse"
                        id="hrFalse"
                        onChange={(e) => {
                          handleChange(e, "isHR");
                        }}
                        checked={formObj?.isHR === "N"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">
                      Is HOD
                      <span className="text-danger">*</span>
                    </label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="hodTrue"
                    >
                      Yes
                      <input
                        type="radio"
                        name="is_hodTrue"
                        id="hodTrue"
                        onChange={(e) => {
                          handleChange(e, "isHOD");
                        }}
                        checked={formObj?.isHOD === "Y"}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="hodFalse"
                    >
                      No
                      <input
                        type="radio"
                        name="is_hodFalse"
                        id="hodFalse"
                        onChange={(e) => {
                          handleChange(e, "isHOD");
                        }}
                        checked={formObj?.isHOD === "N"}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      User Type
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      options={userTypes}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action?.name);
                      }}
                      value={formObj?.isSF}
                    />
                    <div
                      hidden={!errors?.errIsSF}
                      className="small text-danger"
                    >
                      Kindly select User Type
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary-inner bpi-main" onClick={saveRule}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        keyboard={false}
        show={view}
        onHide={() => {
          isView(false);
          setSelectedData(null);
          onClose();
        }}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Role</label>
                    <p>{selectedData?.role?.label}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Organisation</label>
                    <ul>
                      {selectedData?.organization.map((b) => (
                        <li>
                          {b?.name} ({b?.code})
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Unit</label>
                    <ul>
                      {selectedData?.businessUnit.map((b) => (
                        <li>
                          {b?.name} ({b?.code})
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <ul>
                      {selectedData?.department.map((b) => (
                        <li>
                          {b?.name} ({b?.code})
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Designation</label>
                    <ul>
                      {selectedData?.designation.map((b) => (
                        <li>
                          {b?.name} ({b?.code})
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <ul>
                      {selectedData?.personalArea.map((b) => (
                        <li>{b?.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Sub Area</label>
                    <ul>
                      {selectedData?.personalSubArea.map((b) => (
                        <li>{b?.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Emp Sub Group</label>
                    <p>{selectedData?.empSubGroup}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{selectedData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment
                        .utc(selectedData?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>IS RM</label>
                    <p>{String(selectedData?.isRM)}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>IS HOD</label>
                    <p>{String(selectedData?.isHOD)}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>IS HR</label>
                    <p>{String(selectedData?.isHR)}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>User Type</label>
                    <p>{selectedData?.isSF?.label}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AutoRoleAssign;
