import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as VehicleService from "../../service/VehicalService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import * as VehicalService from "../../service/VehicalService";
import * as otherExpService from "../../service/otherExpService";


import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

function OtherExpAllReq() {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);
  
  const [vehicleData, setVehicleData] = useState([]);
  const [otherExpData, setotherExpData] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [travelList, setTravelList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [filterdata, setfilterData] = useState({
    user_id: { value: 0 },
        id: "",
        // organisation:{value: ""},
        from_date: "",
        to_date: "",
        from_id:"",
        to_id:"",
        _gl:{value: 0},
        search_by:"T"
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    
    getHeaderAllReq(
      "A",
      "",
      activePage,
      pageSizeNo,
      sortData?.sort_by,
     
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id?.value,
      searchStr,
      filterdata?.from_id,
      filterdata?.to_id,
      filterdata?.search_by,
      )
    

  };
  const getData = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    from_date,
    to_date,
    emp_code,
    search
  ) => {
    // dispatch(setDisplayLoader("Display"));

    VehicalService.getPickList(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      from_date,
      to_date,
      emp_code,
      search
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setVehicleData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getHeaderAllReq = (
    status,
    is_picked,
    pageNo,
    pageSize,
    sortBy,
    paginate,
    from_date,
    to_date,
    user_id,
    search,
    from_id,
    to_id,
    search_by
  ) => {
    dispatch(setDisplayLoader("Display"));

    let query = "sort_by=-id&search="+search
    + `&page_no=` + pageNo
    + `&paginate=` + paginate
    + `&page_size=` + pageSize
    + `&from_date=` + from_date
    + `&to_date=` + to_date
    +`&user_id=`+(user_id>0?user_id : "")
    + `&from_id=` + from_id
    + `&to_id=` + to_id
    + `&search_by=` + search_by



    otherExpService.getHeaderAllReq(  query  )
      .then((response) => {
        console.log(`otherexp...`,response.data);
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        setotherExpData(response?.data?.dataList?.result);

        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));

        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };


  useEffect(() => {
    // getUsersList();
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   "-id",
    //   true,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   filterdata?.user_id.value,
    //   searchStr
    // );

    getHeaderAllReq(
      "A",
      "",
      currentPage,
      pageSizeNo,
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      )
  }, []);

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
     
      setSearchStr(e.target.value);
    getHeaderAllReq(
      "A",
      "",
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
     
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id?.value,
      e.target.value,
      filterdata?.from_id,
      filterdata?.to_id,
      filterdata?.search_by,
      )

    }
  };

  const mainDataFilter = () => {
    setCurrentPage(1)
    getHeaderAllReq(
      "A",
      "",
      1,
      pageSizeNo,
      "",
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id?.value,
      searchStr,
      filterdata?.from_id,
      filterdata?.to_id,
      filterdata?.search_by,
      )

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getHeaderAllReq(
      "A",
      "",
      currentPage,
      pageSizeNo,
      "",
      true,
      "",
      "",
      "",
          searchStr,
      "",
      "",
      "",
      )
      
    setfilterData({
      user_id: { value: 0 },
        id: "",
        // organisation:{value: ""},
        from_date: "",
        to_date: "",
        from_id:"",
        to_id:"",
        _gl:{value: 0},
        search_by:""
    });
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(vehicleData));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setVehicleData(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.map((res) => {
        if (res?.id != data?.id) {
          return res;
        }
      });
      setPostingData(obj);
      setVehicleData(copyData1);
    }
  };

  const onReceive = () => {
    confirmAlert({
      title: "Receive Vehicle Pick List",
      message: `Are you sure to Receive Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => receivePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onDecline = () => {
    confirmAlert({
      title: "Decline Travel Pick List",
      message: `Are you sure to Decline Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => declinePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(vehicleData));
    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        postingData.push(res);
      });
      setVehicleData(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setVehicleData(data);
      let obj = postingData?.map((x) => {
        if (x?.id != data?.id) {
          return x;
        }
      });
      setPostingData(obj);
    }
  };

  const getHeaderAllReqExport = (

    status,
    is_picked,
    pageNo,
    pageSize,
    sortBy,
    paginate,
    from_date,
    to_date,
    user_id,
    search,
    from_id,
    to_id,
    search_by
    
  ) => {
    // alert()
    dispatch(setDisplayLoader("Display"));

    let query = `is_export=true`+
    "&search="+search+
    `&sort_by=`+sortBy+
    // + `&page_no=` + pageNo
    // + `&paginate=` + paginate
    // + `&page_size=` + pageSize
     `&from_date=` + from_date
    + `&to_date=` + to_date
    +`&user_id=`+(user_id>0?user_id : "")
    + `&from_id=` + from_id
    + `&to_id=` + to_id
    + `&search_by=` + search_by


    otherExpService.otherExpHeaderExport2(

      query
      
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "AllOtherExpenseRequest.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error)
        // console.log(error.response.data)
        // console.log( error.response.data.message)
      });
  };

  const receivePickList = () => {
    let data = postingData;
    let idList = [];
    data.map((res) => {
      idList.push(res.id);
    });
    let obj = { data: postingData };
    VehicleService.receivePickList(obj)
      .then((response) => {
        console.log(response, "response");
        getData(
          currentPage,
          pageSizeNo,
          "-id",
          true,
          filterdata?.from_date,
          filterdata?.to_date,
          filterdata?.user_id.value,
          searchStr
        );
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const declinePickList = () => {
    let data = postingData;
    let idList = [];
    data.map((res) => {
      idList.push(res.id);
    });
    let obj = { data: postingData };
    VehicleService.declinePickList(obj).then((response) => {
      console.log(response, "response");
      getData(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        filterdata?.from_date,
        filterdata?.to_date,
        filterdata?.user_id.value,
        searchStr
      );
    });
  };

  const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileView = (data) =>{
    setFileObj(data)
    handleShow()
}

  useEffect(() => {

    if(usersDropdown?.length<1)
    getUsersList()

    getHeaderAllReq(
      "A",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.user_id?.value,
      searchStr,
      filterdata?.from_id,
      filterdata?.to_id,
      filterdata?.search_by,
      )
      
    
  }, [pageSizeNo]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">All Other Expense Request List</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">

                  <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on{" "}
                              <strong> Name,  Employee ID</strong>
                            </div>
                          </Tooltip>
                        }
                      >

                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                        {/* <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            id="searchbar"
                            type="text"
                            class="form-control"
                            placeholder="Search on Status..."
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack();
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div> */}
                      </OverlayTrigger>

                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc user" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onReceive()}
                            >
                              <i class="far fa-check-circle"></i> Receive
                            </button>
                          )
                      )} */}

                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={otherExpData.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getHeaderAllReqExport(
                                  "A",
                                  "",
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  filterdata?.from_date,
                                  filterdata?.to_date,
                                  filterdata?.user_id?.value,
                                  searchStr,
                                  filterdata?.from_id,
                                  filterdata?.to_id,
                                  filterdata?.search_by,
                                   )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        
                      
                      <th>S. NO.</th>
                      <th>	EMPLOYEE ID</th>
                      <th>	EMP NAME</th>
                      <th>	EMP VENDOR CODE</th>
                      <th>	EMP GRADE</th>
                      <th>	LOCATION (HQ)</th>
                      <th>	ZONE</th>
                      <th>	REQUEST ID</th>
                      <th>	REPORTING HEAD</th>
                      <th>	HOD</th>
                      <th>	BLANK 3</th>
                      <th>	BLANK 4</th>
                      <th>	BLANK 5</th>
                      <th>	BLANK 6</th>
                      <th>	EXPENSES APPLIED DATE</th>
                      <th>	BOSS APPROVED ON</th>
                      <th>	CLAIM AMT.</th>
                      <th>	APPROVED AMT</th>
                      <th>	DEDUCTION</th>
                      <th>	NET AMT</th>
                      <th>	DOC. REC. ON</th>
                      <th>	APP. BY EXEC.</th>
                      <th>	APP.BY HEAD ON</th>
                      <th>	SAP DOC NO</th>
                      <th>	CHECK BY</th>
                      <th>	DEL. BY EXEC.</th>
                      <th>	DEL. BY HEAD</th>
                      <th>	APPROVED BY HEAD NAME</th>
                      <th>	STATUS MESSAGE</th>
                      <th>	DISCARD REASON</th>
                      <th>	1ST LEVEL</th>
                      {/* <th> APPROVAR (REPORTING MGR)</th> */}
                      <th>	2ND LEVEL APPROVAR (ZM)</th>
                      <th>	3RD LEVEL APPROVAR (BH/FH)</th>
                      <th>	4TH LEVEL APPROVAR (MD)</th>
                      <th>	DATE OF JOINING</th>
                      {/* <th>	ZONE</th> */}
                      <th>	COST CENTER</th>
                      <th>	BUSINESS AREA</th>
                      <th>	PROFIT CENTRE</th>
                      <th>	E MAIL</th>
                      <th> ID	CONTACT NO</th>
                      {/* <th>	ACTION</th> */}


                      </tr>
                    </thead>
                    <tbody>
                      {otherExpData?.length ? (
                        otherExpData?.map((x,idx) => (
                          <tr

                          style={{
                            backgroundColor:
                              x?.chat_status > 0
                                ? "yellow"
                                : (x?.send_back_remark||x?.send_back_ssc_remark)
                                ? "#FFBF00"
                                : x?.is_exception
                                ? "#FFA895"
                                : "",
                          }}  
                          
                          >
                            <td>{idx+1}</td>                            
                      <td>
                        	
                          {x?.attachment?.length >0 ?
                                    <Link  onClick={()=>{handleFileView(x.attachment)}}>
                                      <button class="btn btn-blanktd text-primary">
                                      {x["user_code"]}
                                      </button>
                                    </Link>
                                    :x["user_code"]}
                      </td>
                      <td>	{x["username"]}</td>
                      <td>{x?.user_vendor_code?x?.user_vendor_code:""}</td>
                      <td>	{x["Emp Grade"]}</td>
                      <td>	{x["Location (HQ)"]}</td>
                      <td>	{x.sales_dist}</td>
                      <td>	
                       
                        <Link
                                to={`/print-other-listing?id=${x?.id}&user_id=${x?.user_id}&from_allrequest=1`}
                                target="_blank"
                              >
                                {x["Request ID"]}
                            </Link>
                      </td>
                      <td>	{x["reporting_user_name"]}</td>
                      <td>	{x["hod_name"]}</td>
                      <td>	-</td>
                      <td>	- </td>
                      <td>	- </td>
                      <td>	- </td>
                      <td>	{x?.created_at && moment.parseZone(x["created_at"]).format("DD-MM-YYYY")}</td>
                      <td>	{x?.approved_at && moment.parseZone(x["approved_at"]).format("DD-MM-YYYY")}</td>
                      <td>	{x["total_bill_amount"]}</td>
                      <td>	{x?.total_approved_amount ?? ""}</td>
                      <td>	{x["Deduction"]}</td>
                      <td>	{x?.total_approved_amount ?? ""}</td>
                      <td>	{x?.is_picked_at && moment.parseZone(x["is_picked_at"]).format("DD-MM-YYYY")}</td>
                      <td>	{x?.ssc_user_approved_at &&  moment.parseZone(x["ssc_user_approved_at"]).format("DD-MM-YYYY")}</td>
                      <td>	{x?.ssc_manager_approved_at &&  moment.parseZone(x["ssc_manager_approved_at"]).format("DD-MM-YYYY")}</td>
                      <td>	{x["sap_doc_no"]}</td>
                      <td>	{x["ssc_username"]}</td>
                      <td>	{x["Del. By Exec."]}</td>
                      <td>	{x["Del. By Head"]}</td>
                      <td>	{x["ssc_manager_username"]}</td>
                      <td>	{x["status"]}</td>
                      <td>	{x["discard_reason"]}</td>
                      <td>	{x["1st level Approvar (Reporting Mgr)"]}</td>
                      {/* <td> APPROVAR (REPORTING MGR)</td> */}
                      <td>	{x["2nd level approvar (ZM)"]}</td>
                      <td>	{x["3rd Level Approvar (bh/fh)"]}</td>
                      <td>	{x["4th Level Approvar (MD)"]}</td>
                      <td>	{x["Date of Joining"] && moment.parseZone(x["Date of Joining"]).format("DD-MM-YYYY")}</td>
                      {/* <td>	ZONE</td> */}
                      <td>	{x?.cost_center_code}</td>
                      <td>	{x["Business Area"]}</td>
                      <td>	{x["Profit Centre"]}</td>
                      <td>	{x["user_email"]}</td>
                      <td> {x["user_mobile_no"]}</td>
                      {/* <td>	...</td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={41}>No Records Found</td>
                        </tr>
                      )}
                       <Modal 
                           className="modaldefaultclose modaldefaultclosecenter"
                          backdrop="static"  show={shows} onHide={handleClose}>
                                        <Modal.Header 
                                        // className="bg-primary text-white" 
                                        closeButton>
                                          <Modal.Title  >View attachments</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div class="table-responsive mb-3">
                                                    
                                                            <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                                                            <thead>
                                                                <tr>
                                                              
                                                                <th>EXPENSE TYPE</th>
                                                                <th>FILE NAME	</th>
                                                                <th>VIEW</th>
                                                                
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                          {fileObj?.map((adata,idx)=>(
                                                              <tr>   
                                                                <td>{adata?.expense_type}</td>        
                                                              <td>{adata?.attachment_path?.substring(adata?.attachment_path?.lastIndexOf("/")+1)}</td>
                                                              <td>
                                                                  <Link target="_Blank" to={adata?.attachment_path}>View</Link>
                                                              </td>
                                                              </tr>
                                                              
                                                          ))}
                                                          </tbody>
                                                          </table>
                                                    
                                                      
                                                  
                                                  </div>    
                                        </Modal.Body>
                                        {/* <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                          </Button>
                                        </Modal.Footer> */}
                                      </Modal>
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={50} label={50}>
                            50
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                          <option value={200} label={200}>
                            200
                          </option>
                        </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      key={pageSizeNo}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3"></div>
                  </div>
                </div>
                {/* </Tab>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log("filterdata", filterdata)}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Exp. ID From <span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_id: e.currentTarget.value,
                })
              }
              value={filterdata.from_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
            Exp. ID To <span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_id: e.currentTarget.value,
                })
              }
              value={filterdata.to_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>

          <div class="form-group innergroup">
                        <label>
                        Search By <span class="text-danger"></span>
                        </label>

                        <select
                        class="form-control"                        
                        onChange={(e) =>
                            setfilterData({
                            ...filterdata,
                            search_by: e.target.value,
                            })
                        }
                        >
                            <option  
                            selected={filterdata?.search_by==="T"}
                            value="T">
                                T (Bill Date)
                            </option>
                            <option 
                            selected={filterdata?.search_by==="P"}
                            value="P">
                                P (SSC Processed date + Pick List)
                            </option>
                        </select>
                         
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtherExpAllReq;
