import React from 'react';

const ThankYouPage = () => {
    return (
        <div className="thank-you-page" style={{textAlign: "center", marginTop: "50px"}}>
            <h1 style={{color: "#333", fontSize: "36px", marginBottom: "20px"}}>Thank You for Your Response!</h1>
            <p style={{color: "#666", fontSize: "18px"}}>We appreciate your feedback.</p>
        </div>
    );
};

export default ThankYouPage;
