import React, { useState } from "react";
import { Link } from "react-router-dom";

const PermissionDenied = () => {
  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div class="row justify-content-center">
            <div class="col-md-5 text-center">
              <h1 class="fw-bold text-theme">Permission Denied</h1>
              <img src="images/404.jpg" />
              <p>
                Page not found your Internet not working properly.
                <br />
                Please go back to home page
              </p>
              <Link to="/dashboard" class="btn btn-outline-danger">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PermissionDenied;
