import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const getSkillsQuarter = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/quarter-report-master?${queryParm}`);
};
export const getAllYear = async () => {
  return axios.get(`${AUTH_BASE_URL}/skill-location-master/year-list`);
};

export const saveSkillsQuarter = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/quarter-report-master`, data);
};

export const updateSkillsQuarter = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/quarter-report-master/${id}`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/quarter-report-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteSkillQuarter = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/quarter-report-master/${id}`);
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/quarter-report-master/exists`, data);
};


export const getAllSkills = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/skill-master?paginate=${paginate}`);
};


export const getEmployee = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};

export const getEmployeeByCreator = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/skill-plan-master/group-users?paginate=${paginate}`);
};
//   export const uploadImages = async (data) => {
//     return axios.post(`${AUTH_BASE_URL}/upload`, data);
//   };


export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/quarter-report-master/bulk_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};