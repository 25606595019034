import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewDesignation = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Designation Code <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.code}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Designation Name <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Business Unit Name & Code<span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.business_units_name} ({viewFormData?.business_units_code})</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment.utc(viewFormData?.created_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated By</label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <p>
                      {moment.utc(viewFormData?.updated_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block">
                      Status <span className="text-danger"></span>
                    </label>
                    <label className="logCheck d-inline-block mr-4" disabled style={{cursor:"auto"}}>
                      Active
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData.is_active === true}
                        readOnly
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="logCheck d-inline-block" disabled style={{cursor:"auto"}}>
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        checked={viewFormData.is_active === false}
                        readOnly
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDesignation;
