import React, { useState, useEffect } from "react";
import AddUserGl from "./AddUserGl";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as userGl from "../../service/userGl";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";


import { injectStyle } from "react-toastify/dist/inject-style";
import BulkGLUserImport from "./BulkGLUserImport";

if (typeof window !== "undefined") {
  injectStyle();
}

function UserGlMapping(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [userGlData, setUserGlData] = useState(null);
  const [userGlMap, setUserGlMap] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [fshow, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [companyCode, setCompanyCode] = useState([]);
  const [multiUser, setMultiUser] = useState([]);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [multiUserData, setMultiUserData] = useState([]);
  const [glCodeOptions, setGlCodeOptions] = useState([]);

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => {
    importsetShow(false);
    getUserGl("", currentPage, pageSizeNo, "-id", true);
  }
  const pathname = window.location.pathname;

  const [filterData, setFilterData] = useState({
    gl_type: "",
    gl_id: { value: undefined },
    organisation_id: { value: undefined },
    user_id: { value: undefined },
    status: "",
  });
  const rerender = () => {
    forceUpdate();
  };
 const forceUpdate = () => {
  getUserGl(
    searchStr,
    currentPage,
    pageSizeNo,
    "-id",
    true,
    "",
    "",
    "",
    "",
    ""
  );
  };
  const handleKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    gl_type,
    organisation_id,
    user_id,
    gl_id,
    is_active
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (gl_type !== "" && gl_type !== undefined && gl_type !== null) {
      queryParm = queryParm + "&gl_type=" + gl_type;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (gl_id !== "" && gl_id !== undefined && gl_id !== null) {
      queryParm = queryParm + "&gl_id=" + gl_id;
    }
    return queryParm;
  };
  const getUserGl = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    gl_type,
    organisation_id,
    user_id,
    gl_id,
    is_active
  ) => {
    dispatch(setDisplayLoader("Display"));

    userGl
      .getUserGl(
        handleKey(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          gl_type,
          organisation_id,
          user_id,
          gl_id,
          is_active
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setUserGlMap(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onAddButtonClick = () => {
    setShow(true);
    setEditMode(false);
    setUserGlData(null);
  };
  const onEdit = (data) => {
    setShow(true);
    setEditMode(true);
    setUserGlData(data);
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(filterData));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array" ||
        type[1].constructor.name.toLowerCase() == "number"
      ) {
        if (type[1].length > 0 || type[1].length == undefined) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setMultiUserData([]);
    setGlCodeOptions([]);

    setCompanyCodeData([]);
    setFilterData((ClearData) => ({
      ...ClearData,
      gl_id: { value: undefined },
      user_id: { value: undefined },
      organisation_id: { value: undefined },
    }));

    filterClose();
    getUserGl(
      searchStr,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      "",
      "",
      ""
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const onCloseButtonClick = () => {
    setShow(false);
  };
 
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    // getUserGl(searchStr, activePage, pageSizeNo, "-id", true);
    getUserGl(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      true,
      filterData?.gl_type,
      filterData?.organisation_id.value,
      filterData?.user_id.value,
      filterData?.gl_id.value,
      filterData?.status
    );
  };

  const onAddCallBack = () => {
    setShow(false);
    getUserGl("", currentPage, pageSizeNo, "-id", true);
  };

  const getGlCode2 = () => {
    userGl
      .getUserGl("wantdropdown=y&paginate=true")
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((x) => {
          return {
            value: x?.gl_id,
            label: `${x?.gl_desc} (${x?.gl_account})`,
          };
        });

        let jsonObject = data?.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

        setGlCodeOptions(uniqueArray);
        // // getPersonalArea();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const getGlCode = (organisation_id, value) => {
  //   userGl
  //     .getGlCode(organisation_id, value)
  //     .then((response) => {
  //       let data = [];

  //       response?.data?.dataList?.result.map((x) => {
  //         let dataGl = {
  //           value: x.id,
  //           label: `${x?.short_desc} (${x?.gl_account})`,
  //         };
  //         data.push(dataGl);
  //       });

  //       let pp = data.filter(
  //         (ele, ind) =>
  //           ind === data.findIndex((elem) => elem.label === ele.label)
  //       );

  //       setGlCodeOptions(data);
  //       // getPersonalArea();
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const mainDataFilter = () => {
    let activePage = 1;
    setCurrentPage(activePage);
    getUserGl(
      searchStr,
      1,
      pageSizeNo,
      "-id",
      true,
      filterData?.gl_type,
      filterData?.organisation_id.value,
      filterData?.user_id.value,
      filterData?.gl_id.value,
      filterData?.status
    );
    filterClose();
  };

  const filterShow = () => {
    filteropen();
  };

  const handleSearch = (e) => {
    // getUserGl(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    getUserGl(
      e.target.value.toLowerCase(),
      1,
      pageSizeNo,
      "-id",
      true,
      filterData?.gl_type,
      filterData?.organisation_id.value,
      filterData?.user_id.value,
      filterData?.gl_id.value,
      filterData?.status
    );
    setSearchStr(e.target.value);
  };

  const deleteData = (id) => {
    userGl
      .deleteData(id)
      .then((res) => {
        if (res.data.status === true) {
          getUserGl("", currentPage, pageSizeNo, "-id", true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCompany = (paginate) => {
    userGl
      .getCompany(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataCompany = {
            value: x.id,
            label: `${x?.name}`,
          };
          data.push(dataCompany);
        });
        setCompanyCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getMultiUser = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})(${x?.business_email})`,
            record: x?.username,
          };
          data.push(empData);
        });
        setMultiUser(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getUserGl(data, 1, pageSizeNo, "-id", true);
    }
  };

  const submit = (id, name) => {
    confirmAlert({
      title: "Delete Skill",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteData(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    getUserGl("", currentPage, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  function getglTooltip(glData) {
    let finalRow = "";
    glData?.forEach((value, idx) => {
      finalRow +=
        `${value?.gl_desc}-(${value?.gl_account})` +
        (glData?.length == idx + 1 ? "" : ", ");
    });

    return finalRow;
  }

  function getPATooltip(glData) {
    let finalRow = "";
    glData?.forEach((value, idx) => {
      finalRow +=
        `${value?.personal_area_description} (${value?.personal_area_name})` +
        (glData?.length == idx + 1 ? "" : ", ");
    });

    return finalRow;
  }

  function getCSTooltip(glData) {
    let finalRow = "";
    glData?.forEach((value, idx) => {
      finalRow +=
        `${value?.cost_center_name} (${value?.cost_center_code})` +
        (glData?.length == idx + 1 ? "" : ", ");
    });

    return finalRow;
  }

  useEffect(() => {
    // getGlCode("","")

    getGlCode2();

    getCompany(false);
    getMultiUser(false);
  }, [fshow]);

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">GL Mapping</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on <strong>User Code Or Employee name</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div className="input-group table-search">
                  <span className="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    id="searchbar"
                    className="form-control"
                    placeholder="Search usercode..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {pathname === "/user-gl" ? (
                      <button class="btn btn-danger" onClick={importuser}>
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Import Data
                      </button>
                    ) : (
                      ""
                    )}
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={onAddButtonClick}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Company Code</th>
                <th>Type</th>
                <th>GlCode</th>
                <th>Emp Sub Group</th>
                <th>Personal Area</th>
                <th>Cost Center</th>
                <th>User Code</th>
                <th>Updated At</th>
                <th>Updated By</th>
                <th>Created By</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userGlMap?.length ? (
                userGlMap?.map((data, index) => (
                  <>
                    <tr>
                      <td className="text-theme fw-bold">
                        {data?.organisation_name}
                      </td>

                      <td> {data?.gl_type}</td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip
                              className="bs-tooltip-bottom tooltip"
                              id="overlay-example"
                            >
                              <div class="tooltip-inner text-left">
                                {getglTooltip(data?.gl_id)}
                              </div>
                            </Tooltip>
                          }
                        >
                          <div>
                            {data?.gl_id?.map((value, i) => (
                              <span>
                                {`${value?.gl_desc}-(${value?.gl_account})`}
                                {data?.gl_id?.length != i + 1 ? ", " : ""}
                              </span>
                            ))}
                          </div>
                        </OverlayTrigger>

                        {/* <p
                            // title={getglTooltip(data?.gl_id)}
                            position="bottom"
                            // content={data?.gl_id}
                            title={
                              getglTooltip(data?.gl_id)
                            }

                            

                            
                          >

                        {data?.gl_id?.map((value, i) => (

                            <span>
                              {`${value?.gl_desc}-(${value?.gl_account})`}
                              {data?.gl_id?.length != i + 1 ? ", " : ""}
                            </span>
                        ))}

                          </p> */}
                      </td>
                      <td>
                        {data?.emp_sub_group?.length !== 0
                          ? data?.emp_sub_group?.map(
                              (x, _idx) =>
                                x.emp_sub_group +
                                (data?.emp_sub_group.length != _idx + 1
                                  ? ", "
                                  : "")
                            )
                          : ""}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip
                              className="bs-tooltip-bottom tooltip"
                              id="overlay-example"
                            >
                              <div class="tooltip-inner text-left">
                                {getPATooltip(data?.personal_area_id)}
                              </div>
                            </Tooltip>
                          }
                        >
                          <div>
                            {data?.personal_area_id?.map((value, pidx) => (
                              <span>
                                {value?.personal_area_description +
                                  `(${value?.personal_area_name})`}
                                {data?.personal_area_id?.length != pidx + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </div>
                        </OverlayTrigger>

                        {/* {data?.personal_area_id?.length !== 0
                          ? data?.personal_area_id?.map(
                              
                              (x,pidx) =>  
                                <Tooltip
                                title={x.personal_area_description+`(${x.personal_area_name})`}
                                position="bottom"
                                >
                                  <span>
                                    {x.personal_area_description+`(${x.personal_area_name})`}
                                    {data?.personal_area_id?.length != pidx + 1 ? ", " : ""}
                                  </span>
                                </Tooltip>
                              
                            )
                          : ""} */}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip
                              className="bs-tooltip-bottom tooltip"
                              id="overlay-example"
                            >
                              <div class="tooltip-inner text-left">
                                {getCSTooltip(data?.cost_center_id)}
                              </div>
                            </Tooltip>
                          }
                        >
                          <div>
                            {data?.cost_center_id?.map((value, i) => (
                              <span>
                                {value?.cost_center_name +
                                  " (" +
                                  value?.cost_center_code +
                                  ") "}
                                {data?.cost_center_id?.length != i + 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))}
                          </div>
                        </OverlayTrigger>

                        {/* 
                        {data?.cost_center_id?.map((value, i) => (
                          <Tooltip
                            title={value?.cost_center_name+' ('+value?.cost_center_code+') '}
                            position="bottom"
                          >
                            <span>
                              {value?.cost_center_name+' ('+value?.cost_center_code+') '}
                              {data?.cost_center_id?.length != i + 1 ? ", " : ""}
                            </span>
                          </Tooltip>
                        ))} */}
                      </td>
                      <td>
                        <Tooltip title={data.user_code} position="bottom">
                          {data?.user_id !== null &&
                            `${data.user_name} - (${data.user_code})`}
                        </Tooltip>
                      </td>
                      <td>
                        {" "}
                        {moment
                          .utc(data?.updated_at)
                          .format("Do MMM YYYY H:mm:ss ")}
                      </td>
                      <td>{data?.updated_by}</td>

                      <td>
                        <Tooltip
                          title={data.created_by_user_code}
                          position="bottom"
                        >
                          {data?.created_by_user_name} - (
                          {data?.created_by_user_code})
                        </Tooltip>
                      </td>
                      <td>
                        {moment
                          .utc(data?.created_at)
                          .format("Do MMM YYYY H:mm:ss ")}
                      </td>
                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-blanktd text-primary"
                          type="button"
                          onClick={() => onEdit(data)}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-blanktd text-danger ml-2"
                          type="button"
                          onClick={() => {
                            submit(data?.id);
                          }}
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {userGlMap?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                key={pageSizeNo}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={fshow}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>GL Code</label>

            <Select
              name="gl_id"
              options={glCodeOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                // console.log(`glid`,e);
                setFilterData({
                  ...filterData,
                  gl_id: e,
                });
                // setGlCodeOptions(e);
              }}
              value={filterData?.gl_id}
            />
          </div>

          <div className="form-group innergroup">
            <label>GL Type</label>
            <select
              className="form-control newbgselect main-pointer"
              id="selectFilter"
              name="gl_type"
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  gl_type: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={filterData.gl_type}
            >
              <option value="" label="Select" />
              <option value="Petty" label="Petty" />
              <option value="Other" label="Other" />
              <option value="Gl Exception" label="Gl Exception" />
            </select>
          </div>

          <div className="form-group innergroup">
            <label>UserName</label>
            <Select
              name="user_id"
              options={multiUser}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  user_id: e,
                });
                // setMultiUserData(e);
              }}
              value={filterData?.user_id}
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Company Code<span className="text-danger">*</span>
            </label>
            <Select
              name="organisation_id"
              options={companyCode}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e, action) => {
                setFilterData({
                  ...filterData,
                  organisation_id: e,
                });
                // setCompanyCodeData(e);
              }}
              value={filterData?.organisation_id}
            />
          </div>

          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              id="selectFilter"
              name="status"
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={filterData.status}
            >
              <option value="" label="Select" />
              <option value={true} label="Active" />
              <option value={false} label="InActive" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {show && (
        <AddUserGl
          show={show}
          onCloseButtonClick={onCloseButtonClick}
          edit={editMode}
          editGlData={userGlData}
          onAddCallBack={onAddCallBack}
        />
      )}

      {importsetShow && (
        <BulkGLUserImport importshow={importshow} renderer = {rerender} importclose={importclose} />
      )}
    </>
  );
}

export default UserGlMapping;
