import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../../service/travelService";
import * as TravelLists from "../../../service/travellist";
import { Modal, Badge } from "react-bootstrap";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../../redux/piDataStore";
import ViewTravelAll from "./../Domestic/ViewTravelAll";
import { toast } from "react-toastify";
import ConfirmModal from "../../CommonComp/ConfirmModal";
import { Tooltip } from "react-tippy";
import { confirmAlert } from "react-confirm-alert";
import { uploadfile } from "../../../service/Survey";
import SapStatusModal from "./../sapStatusModal";
import { PdfIcon } from "../../HigherEducation/Icon";

function ExpenseViewSap() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [travelLogModal, setTravelLogModal] = useState(false);
  const [travelLogList, setTravelLogList] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [flagValue, setFlagValue] = useState();
  const [viewFormData, setViewFormData] = useState(null);
  const [viewAllForm, setViewAllForm] = useState(false);
  const [viewInternationalForm, setViewInternationalForm] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [show, filtersetShow] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [travelList, setTravelList] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [addnewOutstandingShow, addnewsetOutstandingShow] = useState(false);
  const addnewopenOut = () => addnewsetOutstandingShow(true);
  const addnewcloseOut = () => addnewsetOutstandingShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [cityDataTo, setCityDataTo] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendIdData, setSendIdData] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [visible, setVisible] = useState({ flag: false, id: "", data: "" });
  const [uploadData, setUploadData] = useState({});
  const userData = useSelector(selectUserData);
  const [modalState, setModalState] = useState(null);
  const [travelchats, setTravelChats] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const finalUser = useSelector(selectUserData);

  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    message: "",
    attachment_path: null,
  });
  const [viewSapData, setViewSapData] = useState();
  const [confirmSapModal, setConfirmSapModal] = useState(false);
  const [outstandingValues, setOutstandingValues] = useState();
  const [outstandingAmt, setOutstandingAmt] = useState([]);

  function handleCLOSE2() {
    setModalState("close");
  }
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [filterTravel, setFilterTravel] = useState({
    user_id: "",
    from_date: "",
    request_type: "",
    status: "A",
    request_type: "",
  });
  const userDetails = useSelector(selectUserData);

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];

    const fileData = new FormData();
    fileData.append("uploaded_file", files);

    uploadfile(fileData).then((response) => {
      // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      setUploadData(response.data);
      console.log(`response`, response.data);
      set_travelPostData({
        ...travelPostData,
        attachment_path: response.data,
      });
    });
  };

  const removeImageByIdx = (idx) => {
    console.log(`images bef`, imageLocalPreviewList);

    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    // console.log(`images aft`,newIList);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  const onProfileChange2 = (e, flag) => {
    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];

      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      // console.log("files", afile.type);

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);

      // console.log(`...`,fileData);

      // uploadfile(fileData).then((response) => {
      //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      //   setUploadData(response.data)
      //   console.log(`response`,response.data);
      //   setPostData({
      //     ...postData,
      //     attachment_path:response.data
      //   })

      // })
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);

    console.log(`files...`, filesToUpload);
  };

  const postTravelChat = async () => {
    dispatch(setDisplayLoader("Display"));
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.");
      return;
    }
    console.log("posted chat->", travelPostData);
    let payl = { ...travelPostData };

    let attachments2 = [];

    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          console.log(`uploaded for index`, afilee);

          attachments2.push(response?.data);
          // console.log(`arrres..`,attachments2);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `);
          return;
        });
    }

    payl.attachment_list = [...attachments2];

    // TravelListingAPI.postTravelChat(
    //   travelPostData.travel_id,
    //   travelPostData
    // ).then((response) => {
    //   console.log("posted chat->", travelPostData);
    //   getTravelChat(travelPostData.travel_id);
    //   set_travelPostData({
    //     ...travelPostData,
    //     message: "",
    //     attachment_path: null,
    //   });
    //   document.getElementById("querytext").value = "";
    // });

    TravelListingAPI.postTravelChat(travelPostData.travel_id, payl)
      .then((response) => {
        console.log("posted chat->", travelPostData);
        getTravelChat(travelPostData.travel_id);
        set_travelPostData({
          ...travelPostData,
          message: "",
          attachment_path: null,
        });
        document.getElementById("querytext").value = "";

        dispatch(setDisplayLoader("Hide"));
        setselectedFiles([]);
        setFilesToUpload([]);
        set_imageLocalPreviewList([]);
      })
      .catch((err) => {
        toast.error("Unable to post data.");
      });
  };

  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        // sap_trip_flag,
        status,
        travel_id,
        request_type,
        from_date,
        to_date
      ) + "&exclude_type=On Duty"
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        // response?.data?.dataList?.result?.map((data,idx) => {
        //   getTravelChat(data?.id)
        // })

        setTravelList(
          response?.data?.dataList?.result.map((obj) => {
            let lastestDate = null;
            let latestRemark = "";
            obj.expense_approver_remark_data.forEach((ele) => {
              if (
                lastestDate === null ||
                moment(ele.updated_at).isAfter(lastestDate)
              ) {
                lastestDate = ele.updated_at;
                latestRemark = ele.remark;
              }
            });
            obj.latestRemark = latestRemark;
            return obj;
          })
          // ?.filter(
          //   (data) => {
          //   if (!data.request_type.includes("On Duty")) {
          //     return true;
          //   }
          // }
          // )
        );
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };
  const getUserDetails = async () => {
    await TravelListingAPI.getUserDetails()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.username,
            label: `${obj?.first_name}`,
          };
        });
        setUserDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onConfirmClose = () => {
    setConfirmModal(false);
  };
  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityData(res);
        setCityDataTo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var todayD = new Date();
  var timeD =
    todayD.getHours() + ":" + todayD.getMinutes() + ":" + todayD.getSeconds();
  console.log(timeD, "timeD");

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getTravelList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "A"
      );
      // "False"
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "A"
        // "False"
      );
    }
  };

  const onView = (data) => {
    setViewAllForm(true);
    setViewFormData(data);
    setFlagValue(1);
  };

  const onViewClose = () => {
    setViewForm(false);
    setViewInternationalForm(false);
    setMigrate(false);
    setViewAllForm(false);
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };

  const filterClose = () => {
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterTravel));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterTravel(ClearData);
    filterClose();
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      // "False",

      "A",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterTravel.from_date;
    const user_id = filterTravel.user_id;
    const request_type = filterTravel.request_type;
    const to_date = filterTravel.to_date;
    const status = "A";

    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      // "False",
      status,
      "",
      request_type,
      from_date,
      to_date
    );
    filterClose();
  };

  const onCallGrid = () => {
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };

  const deleteTravel = (id) => {
    TravelListingAPI.deleteTravel(id)
      .then((res) => {
        if (res.data.status == true) {
          onCallGrid();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  function getTravelChat(id) {
    TravelListingAPI.getTravelChat(id).then((response) => {
      console.log("get chat for id=>", response);

      console.log("chat->", response?.data?.dataList?.result?.length);
      setTravelChats(response?.data?.dataList?.result);
      set_travelPostData({
        ...travelPostData,
        travel_id: id,
      });
      // return response?.data?.dataList?.result?.length

      // setChatStatus(response?.data?.dataList?.result?.length)

      // setChatStatus({
      //   ...chatStatus,
      //   id:response?.data?.dataList?.result?.length==0
      // })
    });
  }

  const handleShowModalTwo = (id) => {
    setModalState(`query-modal-${id}`);
  };

  const getOutstandingAmount = (employee_code, travel_id) => {
    TravelListingAPI.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);
        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };
  const handleClose = () => {
    setVisible({ ...visible, flag: false });
    // callBackCancel();
  };

  useEffect(() => {
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  }, [pageSizeNo]);
  const sapTripPopup = (id, data) => {
    confirmAlert({
      title: "",
      message: `You are Generating SAP trip No, After this you will not able to change tour.`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            setVisible({ ...visible, flag: true, id: id, data: data }),

          // onClick: () => saveInDraft(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const TravelSapNumber = (id, data) => {
    TravelLists.TravelSapNumber(id)
      .then((res) => {
        if (res.data.code === 200) {
          let dataV = JSON.parse(JSON.stringify(data));
          dataV.sap_trip_number = res?.data?.message;
          if (res?.data?.message?.includes("already exists")) {
            setVisible({ ...visible, flag: false });
            toast.error(res?.data?.message);
          } else {
            toast.success(res?.data?.message);

            navigate("/expense-claim", {
              state: dataV,
            });
          }
        } else if (res?.data?.code == 400) {
          setVisible({ ...visible, flag: false });
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager"
      ) {
        localGroup = data?.name.toLowerCase();
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    getCityList(false);
    getUserDetails();
    getOutstandingAmount(userData?.id, "");
  }, []);

  const onViewData = (data) => {
    setViewSapData(data);
    setConfirmSapModal(true);
  };

  const onClose = () => {
    setConfirmSapModal(false);
  };

  const getTravelLogValueList = (id) => {
    dispatch(setDisplayLoader("Display"));
    TravelLists.getTravelLogValueList(
      `sort_by=id&paginate=false&travel_id=${id}`
    )
      .then((response) => {
        setTravelLogList(response?.data?.dataList?.result);
        setTravelLogModal(true);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      {viewAllForm ? (
        <>
          <ViewTravelAll
            viewFormData={viewFormData}
            onViewClose={onViewClose}
            flagValue={flagValue}
            userDetails={userData}
          />
        </>
      ) : (""
      )}
      
    </>
  );
}

export default ExpenseViewSap;
