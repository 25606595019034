import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { getAllSesmaster } from "../../service/sesService";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { getUserRmList } from "../../service/jv_service";
import { Tooltip } from "react-tippy";
import {
  selectUserData,
  selectFinalUrlPermission,
  setDisplayLoader
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
function ServiceEntrySearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterdata, setfilterData] = useState({
    sheetno: { value: 0 },
    Created_Date: "",
    Created_by: { value: "" },
    approver: { value: "" },
    status: { value: "" },
  });
  const resetFilterData = () =>
    setfilterData({
      sheetno: { value: 0 },
      Created_Date: "",
      Created_by: { value: "" },
      approver: { value: "" },
      status: { value: "" },
    });
  const [userlist, setuserlist] = useState([]);

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [sesData, setSesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const getSes = (
    search,
    page_no,
    page_size,
    paginate,
    sort_by,
    sheet_no,
    created_at,
    created_by,
    status,
    approver_1,
    myses
  ) => {
    dispatch(setDisplayLoader("Display"));
    
    getAllSesmaster(
      search,
      page_no,
      page_size,
      paginate,
      sort_by,
      sheet_no,
      created_at,
      created_by,
      status,
      approver_1,
      myses
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSesData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    // getSes("", currentPage, pageSizeNo, true,"-id", 0, "", "", "", "");
    getUserList();
  }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSes("", activePage, pageSizeNo, true, "-id", 0, "", "", "", "", false);
  };
  const resetFilter = () => {
    filterclose();
    resetFilterData();
    getSes("", currentPage, pageSizeNo, true, "-id", 0, "", "", "", "", false);
  };
  const mainDataFilter = () => {
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      false
    );
    filterclose();
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "sheetno":
        setfilterData((prev) => ({
          ...prev,
          sheetno: event,
        }));
        break;
      case "Created_Date":
        setfilterData((prev) => ({
          ...prev,
          Created_Date: event.target.value,
        }));
        break;
      case "Create_by":
        setfilterData((prev) => ({
          ...prev,
          Created_by: event,
        }));
        break;
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "status":
        setfilterData((prev) => ({
          ...prev,
          status: event,
        }));
        break;
    }
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label:x?.first_name+" "+x?.last_name+"("+x?.username+")" };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getSes(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        true,
        "-id",
        0,
        "",
        "",
        "",
        "",
        false
      );
      setSearch(e.target.value);
    }
  };
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/ses-search">Search</Link>
            </li>
            <li>
              <Link to="/service-entry-sheet">Service Entry Sheet</Link>
            </li>
            <li>
              <Link to="/service-entry-all">All Pending</Link>
            </li>
            <li>
              <Link to="/service-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Pending Service Entries</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                {/* <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner">
                      <i class="fas fa-sync-alt mr-2 f-14"></i>SES Sync
                    </button>
                  </div>
                </div> */}
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Sheet No.</th>
                    <th>Date</th>
                    <th>PO Number</th>
                    <th>Item</th>
                    <th>Posting Date</th>
                    <th>Created By</th>
                    <th>User Name</th>
                    <th>Amount</th>
                    <th>Rel. Strat</th>
                    {/* <th>Pending At</th> */}
                    <th>Sap status</th>
                    <th>SAP Log</th>
                  </tr>
                </thead>
                <tbody>
                  {sesData.map((x) => (
                    <tr>
                      <td class="text-theme">
                        <Link to="/approve-service" class="fw-bold text-theme">
                          {x?.sesno}
                        </Link>
                      </td>
                      <td>
                      {moment
                            .utc(x?.created_at)
                            .format("DD-MM-YYYY,HH:mm")}</td>
                      <td>{x?.pono}</td>
                      <td>10</td>
                      <td>
                      {moment
                            .utc(x?.postingdate)
                            .format("DD-MM-YYYY,HH:mm")}</td>
                      <td>{x?.created_by_name}</td>
                      <td>{x?.createdBy}</td>
                      <td>{x?.totalvalue}</td>
                      <td>{x?.rel_strat}</td>
                      <td>
                        {x?.sap_status === true
                          ? "Synced"
                          : "Pending for synced"}
                      </td>
                      <td>
                        <Tooltip title={x?.log} position="bottom">
                          {x?.log?.slice(0,12)}...
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Search Sheet No.
            </label>
            <Select
              name="sheetno"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.sheetno}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Created Date
            </label>
            <input
              value={filterdata?.Created_Date}
              name="Created_Date"
              onChange={handleChange}
              type="date"
              class="form-control"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Created by
            </label>
            <Select
              //   isMulti
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="Create_by"
              value={filterdata?.Created_by}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select Approver
            </label>
            <Select
              //   isMulti
              name="approver"
              value={filterdata?.approver}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status
            </label>
            <Select
              //   isMulti
              name="status"
              value={filterdata?.status}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceEntrySearch;
