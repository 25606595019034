import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
// import Select from "react-select";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
//import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as deptServices from "../../../../service/department";
import Select from "react-select";
const latinCharsRegEx =
  /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\'\-]*)$/gi;
const validationSchema = Yup.object({
  name: Yup.string()
    .required("*Plant Name is required")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "*Insert only character"
    )
    .nullable(),
  code: Yup.string().required("*Plant Code is required").nullable(),
  city: Yup.string().required("*Plant City is required").nullable(),
  // business_unit_id: Yup.string().required("*Select Business Unit").nullable(),
});

const AddPlant = (props) => {
  const nameForm = "Department Master";
  const { edit, showForm, onClose, editDepartment, onAddCallBack } = props;
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isbusinessunit, setbusinessunit] = useState(false);
  const [isplant, setplant] = useState(false);
  const [allplant, setAllplant] = useState([]);
  const [isPlantExist, setIsPlantExist] = useState(false);
  const [isPlantCodeExist , setIsPlantCodeExist] = useState(null)
  const [allDept, setAllDept] = useState([]);
  const [isDeptExist, setIsDeptExist] = useState(false);
  const [isDeptCodeExist, setIsDeptCodeExist] = useState(null);
  const [departmentData, setDepartmentData] = useState({
    id: 0,
    name: "",
    code: "",
    business_unit_id: "",
    is_active: true,
  });
  const [plantData, setPlantData] = useState({
    id: 0,
    name: "",
    code: "",
    is_active: true,
  });

  const [dropDownData, setDropDownData] = useState({
    select_business_unit: { value: "", label: "" },
  });

  const nonalphabet = /^[A-Za-z]+$/;

  const saveDepartment = (values) => {
    if (dropDownData?.select_business_unit.label !== "") {
      setIsLoading(true);
      deptServices
        .saveDepartment(values)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      setbusinessunit(true);
    }
  };

  const options = [
    { value: 'C++', label: 'Jaipur-Vi' },
    { value: 'JAVA', label: 'Laxmi' },
    { value: 'Javascript', label: 'User1' },
    { value: 'Python', label: 'Python' },
    { value: 'Swift', label: 'Swift' }
];
  const updateDepartment = (values) => {
    setIsLoading(true);
    deptServices
      .updateDepartment(values, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBranch = (paginate) => {
    deptServices
      .getBranch(paginate)
      .then((response) => { 
        setAllDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isDeptExists = (values, type) => {
    let data = {};
    data["name"] = values.name;
    data["business_unit_id"] = values.business_unit_id;
    deptServices
      .isDeptExists(data)
      .then((res) => {
        setIsDeptExist(res?.data?.dataList?.is_exist);
        if (res?.data?.dataList?.is_exist === false && values?.id) {
          updateDepartment(values);
       //   AuditLog.addAuditLog(dispatch, nameForm);
        }
        if (res?.data?.dataList?.is_exist === false && values?.id === 0) {
          saveDepartment(values);
        //  AuditLog.addAuditLog(dispatch, nameForm);
        }
      })
      .catch((err) => console.log("Error=>", err));
  };

  const isDepartmentCodeExist = (e, type) => {
    let data = {};
    data[type] = e.target.value;
    if (editDepartment?.code == e.target.value) {
      setIsDeptCodeExist(false);
      return;
    } else {
      deptServices
        .isDeptExists(data)
        .then((res) => {
          if (res?.data?.dataList?.is_exist === true) {
            setIsDeptCodeExist(true);
          } else {
            setIsDeptCodeExist(false);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };
  const renderOrgOptions = (optionsList) => {
    // return optionsList?.map((item, index) => (
    //   <option
    //     key={`org${index}`}
    //     value={`${item?.id}`}
    //     label={`${item?.name}`}
    //   />
    // ));
    let opt = optionsList?.map((item, index) => {
      return {
        key: `org${index}`,
        value: item?.id,
        label: item?.name + " (" + item?.code + ")",
      };
    });
    return opt;
  };

  useEffect(() => {
    if (editDepartment) {
      setDepartmentData(editDepartment);
      Object.keys(editDepartment).forEach((item) => {
        departmentData[item] = editDepartment[item];
        if (item === "business_unit_id") {
          setDropDownData((prev) => ({
            ...prev,
            select_business_unit: {
              ...prev.select_business_unit,
              value: editDepartment[item],
            },
          }));
        }
        if (item === "business_unit_name") {
          setDropDownData((prev) => ({
            ...prev,
            select_business_unit: {
              ...prev.select_business_unit,
              label:
                editDepartment[item] +
                " (" +
                editDepartment["business_units_code"] +
                ")",
            },
          }));
        }
      });
    }
  }, [editDepartment]);
  useEffect(() => {
    getBranch(false);
  }, []);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  const handleDropData = (value, name) => {
    switch (name) {
      case "select_business_unit":
        setDropDownData((prev) => ({
          ...prev,
          select_business_unit: value,
        }));
        setbusinessunit(false);
        break;
      default:
        break;
    }
  };

  const isExistsValue = (value) => {
    Object.keys(value).forEach((item) => {
      if (item === "business_unit_id") {
        value[item] = Number(dropDownData?.select_business_unit.value);
      }
    });
    let error = false;
    if (edit) {
      if (editDepartment?.name !== value.name) {
        error = true;
      }
      if (editDepartment?.business_unit_id !== value.business_unit_id) {
        error = true;
      }
    }
    if (error === false && value?.id) {
      updateDepartment(value);
    //  AuditLog.addAuditLog(dispatch, nameForm);
    }
    if (error === true && value?.id) {
      isDeptExists(value);
    }
    console.log(error, value?.id);
    if (error === false && value?.id === 0) {
      isDeptExists(value);
    }
  };

  return (
    <>
      <Formik
        initialValues={departmentData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isDeptCodeExist !== true) {
            isExistsValue(values);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Plant
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant Code
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="code"
                            className="form-control"
                            placeholder="Plant Code"
                            maxLength={20}
                            onChange={handleChange}
                            onBlur={(e) => {
                              isDepartmentCodeExist(e, "code");
                              handleBlur(e);
                            }}
                            value={values.code}
                          />
                          {touched.code && errors.code ? (
                            <div className="small text-danger">
                              {errors.code}
                            </div>
                          ) : isDeptCodeExist === true ? (
                            <div className="small text-danger">
                              Plant Code already exists
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Plant Name"
                            maxLength={20}
                            onChange={handleChange}
                            onBlur={(e) => {
                              isDepartmentCodeExist(e, "name");
                              handleBlur(e);
                            }}
                            value={values.name}
                          />
                          {touched.code && errors.code ? (
                            <div className="small text-danger">
                              {errors.code}
                            </div>
                          ) : isDeptCodeExist === true ? (
                            <div className="small text-danger">
                              Plant Name already exists
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant City
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            placeholder="Plant City"
                            maxLength={20}
                            onChange={handleChange}
                            onBlur={(e) => {
                              isDepartmentCodeExist(e, "city");
                              handleBlur(e);
                            }}
                            value={values.city}
                          />
                          {touched.code && errors.code ? (
                            <div className="small text-danger">
                              {errors.code}
                            </div>
                          ) : isDeptCodeExist === true ? (
                            <div className="small text-danger">
                              Plant City already exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                          //  onInput={nonalphabet}
                            className="form-control"
                            placeholder="Enter Plant Name"
                            maxLength={30}
                            onChange={(e) => {
                              handleChange(e);
                              // isDeptExist(e, "name");
                            }}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant City
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                         //   onInput={nonalphabet}
                            className="form-control"
                            placeholder="Enter Plant City"
                            maxLength={30}
                            onChange={(e) => {
                              // handleChange(e);
                              // isDeptExist(e, "name");
                            }}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Select Plant Type
                            <span className="text-danger">*</span>
                          </label>
                         
                          <Select
                             options={options}
                            
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="select_business_unit"
                            onBlur={handleBlur}
                            onChange={(newValue, action) => {
                              // handleChange(newValue)
                              handleDropData(newValue, action.name);
                            }}
                            value={dropDownData?.select_business_unit}
                          />
                          {isbusinessunit ? (
                            <div className="small text-danger">
                              {"*Please select business unit"}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Plant-Transporter mapping
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      name="org"
                       options={options}
                     
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        // handleChange(newValue, action?.name);
                      }}
                      // onBlur={getOrganizationData}
                      // value={formObj?.organization}
                    />
                    <div
                      // hidden={!errors?.errOrganization}
                      className="small text-danger"
                    >
                      {/* Kindly select Transporter */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                    Plant-User mapping
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      name="org"
                       options={options}
                    
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        // handleChange(newValue, action?.name);
                      }}
                      // onBlur={getOrganizationData}
                      // value={formObj?.organization}
                    />
                    <div
                      // hidden={!errors?.errOrganization}
                      className="small text-danger"
                    >
                      {/* Kindly select Users */}
                    </div>
                  </div>
                </div>
                      {/* <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Plant-Vendor mapping
                            <span className="text-danger">*</span>
                          </label>
                         
                          <Select
                            options={renderOrgOptions(allDept)}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="select_business_unit"
                            onBlur={handleBlur}
                            onChange={(newValue, action) => {
                              // handleChange(newValue)
                              handleDropData(newValue, action.name);
                            }}
                            value={dropDownData?.select_business_unit}
                          />
                          {isbusinessunit ? (
                            <div className="small text-danger">
                              {"*Please select business unit"}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">
                            Status <span className="text-danger"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              {isDeptExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;This Entry Already Exists.
                </Alert>
              )}
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddPlant;
