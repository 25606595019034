import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewSkillGroup = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  const design = {
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Skill Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Group Code<span className="text-danger"></span>
                    </label>
                    <p style={design}>{viewFormData?.group_id}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Group Name<span className="text-danger"></span>
                    </label>
                    <p style={design}>{viewFormData?.group_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Group Department<span className="text-danger"></span>
                    </label>
                    <p style={design}>{viewFormData?.group_dept}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment
                        .utc(viewFormData?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated By</label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <p>
                      {moment
                        .utc(viewFormData?.updated_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block">
                      Status <span className="text-danger"></span>
                    </label>
                    <p>{viewFormData?.is_active ? "Active" : "Inactive"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewSkillGroup;
