import React, { useState, useEffect } from "react";
import { Tabs, Tab, Alert } from "react-bootstrap";
import Summary from "./Summary";
import Ticket from "./Ticket";
import Hotel from "./Hotel";
import DA from "./DA";
import Conveyance from "./Conveyance";
import ForeignExpense from "./ForeignExpense";
import VehicleLog from "./VehicleLog";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import TravelLinks from "./TravelLinks";
import * as TravelListingAPI from "../../service/travelService";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import * as DomesticService from "../../service/domestic";
import * as ClaimTravel from "../../service/claimTravel";
import {
  selectMesaageData,
  selectUserData,
  setDisplayLoader,
  setMessage,
} from "../redux/piDataStore";
import moment from "moment";
import * as vehicleService from "../../service/vehiclelog";
import ConfirmModal from "../CommonComp/ConfirmModal";
import { confirmAlert } from "react-confirm-alert";
import AlertConfirm from "react-alert-confirm";
import ExchangeModal from "./exchangeModal";

function ExpenseClaim(props) {
  const userData = useSelector(selectUserData);
  const { gridData, grideditData, allData } = props;
  const { state, newState } = useLocation();
  const [rateData, setRateData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleHotel, setVisibleHotel] = useState(false);
  const userDetails = useSelector(selectUserData);
  const [checkActiveTab, setCheckActiveTab] = useState("DA");
  const [att, setAtt] = useState([]);
  const [visibleExchange, setVisibleExchange] = useState(true);
  const [exchangeDataUsd, setExchangeDataUsd] = useState([]);
  const messageValue = useSelector(selectMesaageData);
  const [messageInt, setMessageInt] = useState();
  const [dataNew, setDataNew] = useState([]);
  const [gridDataNew, setGridDataNew] = useState([]);
  const [visibleVehicle, setVisibleVehicle] = useState(false);
  const [vehicleAssignValues, setVehicleAssignValues] = useState();
  const [vehicleManagement, setVehicleManagement] = useState(false);

  // const getHotelList = async (data) => {
  //   await TravelListingAPI.getHotelList(data)
  //     .then((response) => {
  //       let res = response?.data?.dataList?.result;
  //       console.log(res, "response123");
  //       setHotelList(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const moveToDraftData = (id) => {
  //   confirmAlert({
  //     title: "Note",
  //     message: `1. Amount entered should be in complete rupees
  //     2. In case of room sharing, hotel bill should be claimed by one person by mentioning another employee ID in sharing tab
  //     3. State code & hotel situated state should always match
  //     4. Message Alert – Hotel under GST defaulter list is just only for warning, we can further process claim.`,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         // onClick: () => saveInDraft(id),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  // const moveToDraftDataNew = (id) => {
  //   confirmAlert({
  //     title: "Note",
  //     message: `1. Ensure correctness of invoice number of FCM travel Solution pvt ltd starting with alphabet U or Y. ${"/n"}
  //     2. Service Charge+GST to be mentioned in other charges tab.
  //     ${"/n"}
  //     3. Taxation amount is ((Total Tax invoice value – Service Charges)
  //     4. If travel sector is fully cancelled (To & Fro) then there is no need to claim cancellation charges.
  //     5. In case travel sector is partially cancelled then claim full amount of FCM invoice, SSC will do accounting separately of credit note issued by FCM Travel.
  //     6. Send boarding passes & FCM tax invoices with claim.`,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         // onClick: () => saveInDraft(id),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleClose = () => {
    setVisible(false);
    // reply();
  };
  const handleCloseHotel = () => {
    setVisibleHotel(false);
    // reply();
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    return queryParm;
  };
  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    // dispatch(setDisplayLoader("Display"));
    ClaimTravel.getTravelList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        // sap_trip_flag,
        status,
        travel_id,
        request_type,
        from_date,
        to_date
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        setGridDataNew(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey1 = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_free !== "" && is_free !== undefined && is_free !== null) {
      queryParm = queryParm + "&is_free=" + is_free;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const getVehicleAssignData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    user_id
  ) => {
    vehicleService
      .getVehicleAssignData(
        handleKey1(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          is_free,
          user_id
        )
      )
      .then((response) => {
        response?.data?.dataList?.result
          ?.filter((ele) => ele?.is_free == false)
          ?.map((data) => {
            setVehicleAssignValues(data);
          });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (checkActiveTab == "Hotel") {
      setVisibleHotel(true);
    } else if (checkActiveTab == "Ticket") {
      setVisible(true);
    } else if (
      checkActiveTab == "Foreign Expense" &&
      (gridDataNew?.currency_data == "" ||
        gridDataNew?.currency_data == undefined ||
        gridDataNew?.currency_data == null)
    ) {
      setVisibleExchange(true);
    } else {
      setVisibleExchange(false);
    }
  }, [checkActiveTab]);

  useEffect(() => {
    let data = [messageValue]?.filter((ele) => {
      if (Number(ele?.id) == Number(state?.id)) {
        return true;
      }
    });
    setMessageInt(...data);
  }, [messageValue]);
  useEffect(() => {
    // getTravelList("", 1, 10, "-id", true);
    getTravelList(
      "",
      1,
      10,
      "-id",
      true,
      userDetails?.id,
      "A",
      state?.id
      // "False"
    );
  }, [checkActiveTab]);

  const handleBackData = () => {
    getTravelList(
      "",
      1,
      10,
      "-id",
      true,
      userDetails?.id,
      "A",
      state?.id
      // "False"
    );
  };

  useEffect(() => {
    getUsersDetails(userDetails?.username);
  }, [checkActiveTab]);

  useEffect(() => {
    gridDataNew[0]?.travel_detail_data?.map((data, index) => {
      if (data?.mode_name == "Personal Car" || data?.mode_name == "Bike") {
        setVehicleManagement(true);
      }
    });
  }, [gridDataNew]);

  useEffect(() => {
    if (
      ["1003", "1007", "1017"]?.includes(dataNew[0]?.personal_area_name) &&
      !["GG01"]?.includes(dataNew[0]?.sub_personal_area_name) &&
      userData[0]?.emp_sub_group !== "F2"
    ) {
      setVisibleVehicle(true);
    }
    // else if (
    //   ["108068", "108184", "109591", "110242", "110257"]?.includes(
    //     dataNew[0]?.username
    //   )
    // ) {
    //   setVisibleVehicle(true);
    // }
    else if (vehicleManagement == true) {
      setVisibleVehicle(true);
    }
  }, [dataNew]);

  const updateCurrency = (data) => {
    let tempGridData = [...gridDataNew];
    tempGridData[0].currency_data = data;
    state.currency_data = JSON.stringify(data);
    const currentState = window.history.state;
    const updatedState = { ...currentState, usr: state };
    window.history.replaceState(updatedState, "");
    setGridDataNew(tempGridData);
  };

  const getRateList = async () => {
    await TravelListingAPI.getRateList()
      .then((response) => {
        let res = response?.data?.dataList?.currency_converter[0];
        setRateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCloseButtonClick = () => {
    setVisibleExchange(false);
  };

  useEffect(() => {
    getVehicleAssignData("", 1, 10, "-id", false, "", userData.id);
    getRateList();
  }, []);

  useEffect(() => {
    if (
      state?.request_type === "International" &&
      (gridDataNew?.currency_data == "" ||
        gridDataNew?.currency_data == undefined ||
        gridDataNew?.currency_data == null)
    ) {
      setVisibleExchange(true);
    } else {
      setVisibleExchange(false);
    }
  }, [state]);

  const getConveyenceList = async (data) => {
    await TravelListingAPI.getConveyenceList(data)
      .then((response) => {
        let res = response?.data?.dataList?.result;

        setAtt(response?.data?.dataList?.attachment_path);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // getTravelList("", 1, 10, "-id", true);
    getConveyenceList(state?.id);
  }, [checkActiveTab === "Conveyance"]);

  const handleExchnagePopup = (data) => {
    setVisibleExchange(true);
    setExchangeDataUsd(data);
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">Expense Claim {allData?.id}</h3>
                </div>
              </div>
            </div>

            <div class="tickethistorbg mt-3 mx-4">
              <div class="row viewformaftersubmit">
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Emp No</p>
                    <label>{userDetails?.username}</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Name</p>
                    <label>{userDetails?.full_name}</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Grade</p>
                    <label>{userDetails?.emp_sub_group}</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Head Quarters</p>
                    <label>{dataNew[0]?.location}</label>
                  </div>
                </div>
              </div>
              <div class="row viewformaftersubmit mt-3">
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Deputation</p>
                    <label>
                      {dataNew[0]?.action_code === "10" ? "Yes" : "No" ?? "-"}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Vendor Code</p>
                    <label>{userDetails?.employee_vendor_code}</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>SAP Trip No</p>
                    <label>
                      {state?.sap_trip_number?.split(" ", 1)}
                      {/* {messageInt == undefined || messageInt == ""
                        ? state?.sap_trip_number
                        : messageInt && messageInt?.sap_trip_number} */}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Zone </p>
                    <label>
                      {dataNew[0]?.sales_dist ?? "-"}
                      {/* {messageInt == undefined || messageInt == ""
                        ? state?.sap_trip_number
                        : messageInt && messageInt?.sap_trip_number} */}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row viewformaftersubmit mt-3">
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Tour Applied On</p>
                    <label>
                      {" "}
                      {moment.utc(state?.created_at).format("DD-MM-YYYY") ??
                        "-"}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Tour Approved On</p>
                    <label>
                      {" "}
                      {moment(state?.approval_date).format("DD-MM-YYYY") ?? "-"}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>From Location</p>
                    <label>
                      {state?.travel_detail_data[0]?.from_city_name === "Other"
                        ? state?.travel_detail_data[0]?.from_city_input
                        : ` ${state?.travel_detail_data[0]?.from_city_name}
                      -${state?.travel_detail_data[0]?.from_city_category}`}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Departure Date</p>
                    <label>
                      {" "}
                      {moment(state?.travel_detail_data[0]?.from_date).format(
                        "DD-MM-YYYY"
                      ) ?? "-"}
                      {/* {moment(state?.travel_detail_data[0]?.from_time).format(
                        "hh mm"
                      ) ?? "-"} */}{" "}
                      {moment(
                        state?.travel_detail_data[0]?.from_time,
                        "h:mm A"
                      ).format("HH:mm A")}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row viewformaftersubmit mt-3">
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>To Location</p>
                    <label>
                      {state?.travel_detail_data[0]?.to_city_name === "Other"
                        ? state?.travel_detail_data[0]?.to_city_input
                        : ` ${state?.travel_detail_data[0]?.to_city_name}
                      -${state?.travel_detail_data[0]?.to_city_category}`}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="innergroup">
                    <p>Arrival Date</p>
                    <label>
                      {moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      ).format("DD-MM-YYYY") ?? "-"}{" "}
                      {moment(
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_time,
                        "h:mm A"
                      ).format("HH:mm A")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs
                defaultActiveKey="DA"
                id="approvaltabs"
                activeKey={checkActiveTab}
                onSelect={(e) => setCheckActiveTab(e)}
              >
                <Tab
                  eventKey="Summary"
                  title={<>Summary</>}
                  onSelect={(e) => setCheckActiveTab("Summary")}
                >
                  <Summary
                    checkActiveTab={checkActiveTab}
                    dataNew={dataNew}
                    getTravelList={getTravelList}
                    gridDataNew={gridDataNew}
                    setGridDataNew={setGridDataNew}
                  />
                </Tab>
                <Tab
                  eventKey="Ticket"
                  title={<>Ticket</>}
                  onSelect={(e) => setCheckActiveTab("Ticket")}
                >
                  <Ticket
                    checkActiveTab={checkActiveTab}
                    grideditData={grideditData}
                    gridData={gridData}
                    allData={allData}
                    orgCode={
                      ["PIJA", "PIHS", "PILS"].includes(
                        dataNew[0]?.organisation_code
                      )
                        ? dataNew[0]?.organisation_code
                        : "Other"
                    }
                    setCheckActiveTab={() => setCheckActiveTab("Summary")}
                  />
                </Tab>
                <Tab
                  eventKey="Hotel"
                  title={<>Hotel</>}
                  onSelect={(e) => {
                    setCheckActiveTab("Hotel");
                  }}
                >
                  <Hotel
                    checkActiveTab={checkActiveTab}
                    flag={true}
                    dataNew={dataNew}
                    setCheckActiveTab={() => setCheckActiveTab("Summary")}
                  />
                </Tab>
                <Tab
                  eventKey="DA"
                  title={<>DA</>}
                  onSelect={(e) => setCheckActiveTab("DA")}
                >
                  <DA
                    checkActiveTab={checkActiveTab}
                    setCheckActiveTab={() => setCheckActiveTab("Summary")}
                    dataNew={dataNew}
                  />
                </Tab>
                <Tab
                  eventKey="Conveyance"
                  title={<>Conveyance</>}
                  onSelect={(e) => setCheckActiveTab("Conveyance")}
                >
                  <Conveyance
                    checkActiveTab={checkActiveTab}
                    setCheckActiveTab={() => setCheckActiveTab("Summary")}
                    att={att}
                  />
                </Tab>
                {state?.request_type === "International" ? (
                  <Tab
                    eventKey="Foreign Expense"
                    title={<>Foreign Expense</>}
                    onSelect={(e) => setCheckActiveTab("Foreign Expense")}
                  >
                    <ForeignExpense
                      checkActiveTab={checkActiveTab}
                      state={state}
                      rateData={rateData}
                      handleExchnagePopup={handleExchnagePopup}
                      getRateList={getRateList}
                      gridDataNew={gridDataNew}
                      setCheckActiveTab={() => setCheckActiveTab("Summary")}
                    />
                  </Tab>
                ) : (
                  ""
                )}
                {(visibleVehicle || (vehicleAssignValues !== undefined && vehicleAssignValues?.length !== 0)) && (
                  <Tab
                    eventKey="Vehical"
                    title={<>Vehicle</>}
                    onSelect={(e) => {
                      setCheckActiveTab("Vehical");
                    }}
                  >
                    <VehicleLog
                      dataNew={dataNew}
                      vehicleAssignValues={vehicleAssignValues}
                      checkActiveTab={checkActiveTab}
                      gridDataNew={gridDataNew}
                      setCheckActiveTab={() => setCheckActiveTab("Summary")}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
            {/* {checkActiveTab !== "Summary" ? (
              <div class="col-md-12 mt-1 mb-8 text-center">
                <button
                  type="button"
                  class="btn btn-outline-secondary mb-4 mt-2"
                  onClick={() => {
                    setCheckActiveTab("Summary");
                  }}
                >
                  Back
                </button>
              </div>
            ) : (
              <div class="col-md-12 mt-1 mb-8 text-center">
                <button
                  type="button"
                  class="btn btn-outline-secondary mb-4 mt-2"
                  onClick={() => {
                    navigate("/expense-listing");
                  }}
                >
                  Back
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <Modal show={visible} size="lg">
        <Modal.Body>
          <p style={{ fontSize: "18px", color: "#000", fontWeight: "bold" }}>
            Note:-
          </p>
          <table>
            <tr>
              <td class="pr-2 d-flex justify-content-start">1.</td>
              <td>
                Ensure correctness of invoice number of FCM travel Solution pvt
                ltd starting with alphabet U or Y..
              </td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">2.</td>
              <td>Service Charge+GST to be mentioned in other charges tab.</td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">3. </td>
              <td>
                Taxation amount is (Total Tax invoice value – Service Charges)
              </td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">4. </td>
              <td>
                If travel sector is fully cancelled (To & Fro) then there is no
                need to claim cancellation charges.
              </td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">5. </td>
              <td>
                In case travel sector is partially cancelled then claim full
                amount of FCM invoice, SSC will do accounting separately of
                credit note issued by FCM Travel.
              </td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">6. </td>
              <td>Send boarding passes & FCM tax invoices with claim.</td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-primary-inner bpi-main"
            variant="primary"
            onClick={() => handleClose(true)}
          >
            OK
          </button>
          {/* <button
            class="btn btn-primary-inner bpi-main"
            variant="primary"
            onClick={() => handleClose(true)}
          >
            No
          </button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={visibleHotel}
        //  onHide={() => handleCloseHotel(true)}
      >
        <Modal.Body>
          <p style={{ fontSize: "18px", color: "#000", fontWeight: "bold" }}>
            Note:-
          </p>
          <table>
            <tr>
              <td class="pr-2 d-flex justify-content-start">1.</td>
              <td>Amount entered should be in complete rupees.</td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">2.</td>
              <td>
                In case of room sharing, hotel bill should be claimed by one
                person by mentioning another employee ID in sharing tab
              </td>
            </tr>
            <tr>
              <td class="pr-2 d-flex justify-content-start">3. </td>
              <td>State code & hotel situated state should always match</td>
            </tr>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-primary-inner bpi-main"
            variant="primary"
            onClick={() => handleCloseHotel(true)}
          >
            OK
          </button>
          {/* <button
            class="btn btn-primary-inner bpi-main"
            variant="primary"
            onClick={() => handleCloseHotel(true)}
          >
            No
          </button> */}
        </Modal.Footer>
      </Modal>
      {checkActiveTab === "Foreign Expense" && (
        <ExchangeModal
          visibleExchange={visibleExchange}
          onCloseButtonClick={onCloseButtonClick}
          rateData={rateData}
          state={state}
          getTravelList={getTravelList}
          exchangeDataUsd={exchangeDataUsd}
          getRateList={getRateList}
          checkActiveTab={checkActiveTab}
          gridDataNew={gridDataNew}
          handleBackData={handleBackData}
          updateCurrency={updateCurrency}
        />
      )}
    </>
  );
}

export default ExpenseClaim;
