import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import moment from "moment/moment";
import * as VehicleLogApi from "../../service/vehiclelog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Summary = (props) => {
  const { checkActiveTab, dataNew, gridDataNew, setGridDataNew } = props;
  let condition = "true";
  const userDetails = useSelector(selectUserData);
  const { state } = useLocation();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [hotelList, setHotelList] = useState([]);
  const [daList, setDaList] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const [totalVehicleAmount, setTotalVehicleAmount] = useState();
  const [exceptionData, setExceptionData] = useState({
    courier_company: "",
    document_no: "",
    courier_date: null,
  });
  const [formValidation, setFormValidation] = useState({
    courier_company: "",
    document_no: "",
    courier_date: "",
  });
  const [courierFlag, setCourierFlag] = useState(false);
  const [exceptionValueData, setExceptionValueData] = useState();

  const validateRecords = () => {
    let isError = false;
    const fromData = { ...formValidation };
    if (!exceptionData.courier_company) {
      isError = true;
      fromData.courier_company = "*Courier Company is required";
      setFormValidation(fromData);
    }
    if (!exceptionData.document_no) {
      isError = true;
      fromData.document_no = "*Document No is required";
      setFormValidation(fromData);
    }
    if (!exceptionData.courier_date) {
      isError = true;
      fromData.courier_date = "*courier date is required";
      setFormValidation(fromData);
    }

    return isError;
  };
  var datePev = new Date();
  datePev.setDate(datePev.getDate() - 1);
  let doubleClick = "false";
  const updateTravelInfo = async (values) => {
    if (doubleClick === "false") {
      doubleClick = "true";
      let datas = values;
      datas.courier_date =
        moment(exceptionData?.courier_date).format("YYYY-MM-DD") !=
        "Invalid date"
          ? moment(exceptionData?.courier_date).format("YYYY-MM-DD")
          : null;
      datas.courier_company = exceptionData?.courier_company;
      datas.tracking_number = exceptionData?.document_no;
      datas.document_no = exceptionData?.document_no;
      datas["travel_detail"] = datas["travel_detail_data"];
      delete datas["travel_detail_data"];
      // datas.claim_flag = true;
      datas.claim_flag = true;
      datas.id = datas?.id;
      await TravelListingAPI.updateTravelInfo(datas, datas?.id)
        .then((response) => {
          doubleClick = "false";
          if (response?.data.code == 200) {
            toast.success("Travel Summary Updated Successfully");
            navigate("/expense-listing");
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getHotelList = async (data) => {
    await TravelListingAPI.getHotelList(data)
      .then((response) => {
        let res = response?.data?.dataList;
        setHotelList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDaList = async (data) => {
    await TravelListingAPI.getDaList(data)
      .then((response) => {
        let res = response?.data?.dataList;
        setDaList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTicketExpense = (id, user_id) => {
    TravelListingAPI.getTicketExpense(id, user_id).then((response) => {
      let res = response?.data?.dataList;

      setTicketDetails(res);
    });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays - 1;
    } else {
      return 0;
    }
  };
  const calculateDateOfJoining = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getHotelList(state?.id);
    getDaList(state?.id);
    getTicketExpense(state?.id, userDetails?.id);
  }, []);

  useEffect(() => {
    if (
      (dataNew[0]?.personal_area_name === "1003" ||
        dataNew[0]?.personal_area_name === "1007" ||
        dataNew[0]?.personal_area_name === "1017") &&
      dataNew[0]?.sub_personal_area_name != "GG01" &&
      dataNew[0]?.sub_personal_area_name != "null"
    ) {
      setCourierFlag(true);
    } else {
      setCourierFlag(false);
    }
  }, [dataNew]);

  useEffect(() => {
    let sum = 0;
    gridDataNew[0]?.vehicle_data?.map((value) => {
      sum = value?.total_amount + sum;
    });
    setTotalVehicleAmount(sum);

    setExceptionData({
      courier_company: gridDataNew[0]?.courier_company,
      courier_date: moment(gridDataNew[0]?.courier_date).format("YYYY-MM-DD"),
      document_no: gridDataNew[0]?.document_no,
    });
  }, [gridDataNew]);

  useEffect(() => {
    if (gridDataNew?.length > 0) {
      let ddd = gridDataNew?.filter((x) => {
        if (x.id === state?.id) {
          return true;
        }
      });
      setGridDataNew(ddd);
    }
  }, [checkActiveTab === "Summary"]);

  const saveExceptionData = (
    id,
    expense_exception,
    vehicle_exception,
    data
  ) => {
    let value = data;
    VehicleLogApi.saveExceptionData(
      id,
      expense_exception,
      vehicle_exception,
      value
    )
      .then((response) => {
        // setDriverExists(response?.data?.dataList?.is_exist);
        delete gridDataNew[0]["expense_approval_status"];
        updateTravelInfo(gridDataNew[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleSubmit = () => {
    if (condition == "true") {
      condition = "false";
    if (courierFlag == true) {
      if (validateRecords()) return false;
      let gridValueData = gridDataNew[0];
      gridValueData["expense_approval_status"] = "A";
      if (dataNew[0]?.emp_sub_group == "F2" && dataNew[0]?.sales_grp !== null) {
        saveExceptionData(state.id, "true", "false", []);
      } else if (
        (exceptionValueData?.hotel === true ||
          exceptionValueData?.conveyanceValue === true ||
          exceptionValueData?.foreign_excep === true ||
          exceptionValueData?.vehicle === true) &&
        exceptionValueData?.personalCarExcep === false
      ) {
        saveExceptionData(state.id, "true", "false", []);
      } else if (
        exceptionValueData?.personalCarExcep === true &&
        exceptionValueData?.vehicle === false &&
        exceptionValueData?.hotel === false &&
        exceptionValueData?.conveyanceValue === false &&
        exceptionValueData?.foreign_excep === false
      ) {
        saveExceptionData(state.id, "false", "true", []);
      } else if (
        exceptionValueData?.personalCarExcep === true &&
        (exceptionValueData?.vehicle === true ||
          exceptionValueData?.hotel === true ||
          exceptionValueData?.conveyanceValue === true ||
          exceptionValueData?.foreign_excep === true)
      ) {
        saveExceptionData(state.id, "true", "true", []);
      } else if (exceptionValueData?.personalCarExcep === true) {
        saveExceptionData(state.id, "false", "true", []);
      } else {
        updateTravelInfo(gridValueData);
      }
    } else {
      let gridValueData = gridDataNew[0];
      gridValueData["expense_approval_status"] = "A";
      if (dataNew[0]?.emp_sub_group == "F2" && dataNew[0]?.sales_grp !== null) {
        saveExceptionData(state.id, "true", "false", []);
      } else if (
        exceptionValueData?.hotel === true ||
        exceptionValueData?.conveyanceValue === true ||
        exceptionValueData?.vehicle === true ||
        exceptionValueData?.foreign_excep === true
      ) {
        saveExceptionData(state.id, "true", "false", []);
        // updateTravelInfo(gridDataNew[0]);
      } else if (
        exceptionValueData?.personalCarExcep === true &&
        exceptionValueData?.vehicle === false &&
        exceptionValueData?.hotel === false &&
        exceptionValueData?.conveyanceValue === false &&
        exceptionValueData?.foreign_excep === false
      ) {
        saveExceptionData(state.id, "false", "true", []);
      } else {
        updateTravelInfo(gridValueData);
      }
    }
  }
  };

  useEffect(() => {
    let data = {
      conveyanceValue: false,
      hotel: false,
      vehicle: false,
      foreign_excep: false,
      personalCarExcep: false,
    };
    gridDataNew[0]?.conveyance_data?.map((value) => {
      if (value?.is_exception == true) {
        data.conveyanceValue = true;
      }
    });
    gridDataNew[0]?.hotel_data?.map((value) => {
      if (value?.is_exception == true) {
        data.hotel = true;
      }
    });
    gridDataNew[0]?.vehicle_data?.map((ele) => {
      if (ele?.is_exception === true) {
        data.vehicle = true;
      } else {
        data.vehicle = false;
      }
      if (ele?.is_personal_car_exception == true) {
        data.personalCarExcep = true;
      }
    });
    gridDataNew[0]?.foreign_conveyance_data?.map((ele) => {
      if (ele?.is_exception == true) {
        data.foreign_excep = true;
      }
    });
    setExceptionValueData(data);
  }, [gridDataNew]);

  let newDate = new Date();

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formattedDate = formatDate(newDate);

  return (
    <>
      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured">
          <thead>
            <tr>
              <th>Type</th>
              <th>By Self</th>
              <th>By Company</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {/* {state?.map((data, i) => { */}
            <>
              {gridDataNew[0]?.ticket_data.length > 0 && (
                <tr>
                  <td
                    className={
                      userData?.dark_mode == false
                        ? "fw-bold text f-15"
                        : "fw-bold text-dark f-15"
                    }
                  >
                    Ticket{" "}
                  </td>
                  <td>{Number(gridDataNew[0]?.ticket_self).toFixed(2)}</td>
                  <td>
                    {gridDataNew[0]?.ticket_company == null
                      ? "0.00"
                      : Number(gridDataNew[0]?.ticket_company).toFixed(2)}
                    {/* {kanu(state?.ticket_data, "Ticket")} */}
                  </td>
                  <td>
                    {Number(gridDataNew[0]?.final_ticket_tax_bill).toFixed(2)}
                  </td>
                </tr>
              )}
              {gridDataNew[0]?.foreign_conveyance_data?.length > 0 && (
                <tr>
                  <td
                    className={
                      userData?.dark_mode == false
                        ? "fw-bold text f-15"
                        : "fw-bold text-dark f-15"
                    }
                  >
                    Foreign Expense{" "}
                  </td>
                  <td>
                    {Number(gridDataNew[0]?.foreign_total_sum?.total).toFixed(
                      2
                    )}
                  </td>
                  <td>
                    {/* {
                      Number(gridDataNew[0]?.foreign_conveyance_company) * 100
                    ) / 100} */}
                  </td>
                  <td>
                    {Number(gridDataNew[0]?.foreign_total_sum?.total).toFixed(
                      2
                    )}
                  </td>
                </tr>
              )}
              {gridDataNew[0]?.hotel_data?.length > 0 && (
                <tr>
                  <td
                    className={
                      userData?.dark_mode == false
                        ? "fw-bold text f-15"
                        : "fw-bold text-dark f-15"
                    }
                  >
                    Hotel{" "}
                  </td>
                  <td>{gridDataNew[0]?.hotel_self.toFixed(2)}</td>
                  <td>
                    {gridDataNew[0]?.hotel_company == null
                      ? "0.00"
                      : gridDataNew[0]?.hotel_company.toFixed(2)}
                  </td>
                  <td style={{ fontSize: "15px" }}>
                    {(
                      gridDataNew[0]?.hotel_company + gridDataNew[0]?.hotel_self
                    ).toFixed(2)}
                  </td>
                </tr>
              )}

              {gridDataNew[0]?.da_data?.length > 0 && (
                <tr>
                  <td
                    className={
                      userData?.dark_mode == false
                        ? "fw-bold text f-15"
                        : "fw-bold text-dark f-15"
                    }
                  >
                    DA{" "}
                  </td>
                  <td>
                    {Number(gridDataNew[0]?.total_da_amount)?.toFixed(2)}
                    {/* {kanu(state?.conveyance_data, "DA")} */}
                  </td>
                  <td>
                    {Number(gridDataNew[0]?.da_company).toFixed(2)}
                    {/* {gridDataNew[0]?.da_company == null
                      ? "0"
                      : gridDataNew[0]?.da_company} */}
                  </td>

                  <td>
                    {(
                      gridDataNew[0]?.total_da_amount +
                      Number(gridDataNew[0]?.da_company)
                    ).toFixed(2)}
                  </td>
                </tr>
              )}

              {gridDataNew[0]?.conveyance_data?.length > 0 && (
                <tr>
                  <td
                    className={
                      userData?.dark_mode == false
                        ? "fw-bold text f-15"
                        : "fw-bold text-dark f-15"
                    }
                  >
                    Conveyance{" "}
                  </td>
                  <td>{gridDataNew[0]?.conveyance_self.toFixed(2)}</td>
                  <td>
                    {gridDataNew[0]?.conveyance_company == null
                      ? "0.00"
                      : Number(gridDataNew[0]?.conveyance_company).toFixed(2)}
                  </td>
                  <td>
                    {(
                      gridDataNew[0]?.conveyance_company +
                      gridDataNew[0]?.conveyance_self
                    ).toFixed(2)}
                  </td>
                </tr>
              )}
              {gridDataNew[0]?.vehicle_data?.length > 0 &&
                totalVehicleAmount !== 0 && (
                  <tr>
                    <td
                      className={
                        userData?.dark_mode == false
                          ? "fw-bold text f-15"
                          : "fw-bold text-dark f-15"
                      }
                    >
                      Vehicle{" "}
                    </td>
                    <td>{totalVehicleAmount.toFixed(2)}</td>

                    <td>
                      0.00
                      {/* {" "}
                    {gridDataNew[0]?.conveyance_company == null
                      ? "0"
                      : gridDataNew[0]?.conveyance_company} */}
                    </td>
                    <td>{totalVehicleAmount.toFixed(2)}</td>
                  </tr>
                )}

              <tr>
                <td class="fw-bold text-theme fa-20">Total</td>
                <td>
                  {(
                    gridDataNew[0]?.ticket_self +
                    gridDataNew[0]?.foreign_total_sum?.total +
                    gridDataNew[0]?.hotel_self +
                    gridDataNew[0]?.total_da_amount +
                    gridDataNew[0]?.conveyance_self +
                    totalVehicleAmount
                  ).toFixed(2)}
                </td>
                <td>
                  {(
                    gridDataNew[0]?.ticket_company +
                    // gridDataNew[0]?.foreign_conveyance_company +
                    gridDataNew[0]?.hotel_company +
                    gridDataNew[0]?.da_company +
                    gridDataNew[0]?.conveyance_company
                  ).toFixed(2)}
                </td>

                <td>
                  {(
                    gridDataNew[0]?.ticket_self +
                    gridDataNew[0]?.foreign_total_sum?.total +
                    gridDataNew[0]?.hotel_self +
                    gridDataNew[0]?.total_da_amount +
                    gridDataNew[0]?.conveyance_self +
                    gridDataNew[0]?.ticket_company +
                    // gridDataNew[0]?.foreign_conveyance_company +
                    gridDataNew[0]?.hotel_company +
                    gridDataNew[0]?.da_company +
                    gridDataNew[0]?.conveyance_company +
                    totalVehicleAmount
                  ).toFixed(2)}
                </td>
              </tr>
            </>
            {/* })} */}
          </tbody>
        </table>
      </div>
      {courierFlag ? (
        <div>
          <div className="row ">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Courier Company<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="text"
                  maxLength={100}
                  class="form-control"
                  name="courier_company"
                  onChange={(e) => {
                    setExceptionData({
                      ...exceptionData,
                      courier_company: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      courier_company: "",
                    });
                  }}
                  value={exceptionData?.courier_company}
                />
                <div className="small text-danger">
                  {formValidation.courier_company}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Docket No.<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="text"
                  maxLength={20}
                  class="form-control"
                  name="document_no"
                  onChange={(e) => {
                    setExceptionData({
                      ...exceptionData,
                      document_no: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      document_no: "",
                    });
                  }}
                  value={exceptionData?.document_no}
                />
                <div className="small text-danger">
                  {formValidation?.document_no}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup d-flex flex-column">
                <label>
                  Courier Date<span class="text-danger">*</span>
                </label>
                <DatePicker
                  selected={
                    exceptionData?.courier_date
                      ? new Date(exceptionData?.courier_date) !=
                          "Invalid Date" &&
                        new Date(exceptionData?.courier_date)
                      : ""
                  }
                  onChange={(date) => {
                    setExceptionData({
                      ...exceptionData,
                      courier_date: date,
                    });
                    setFormValidation({
                      ...formValidation,
                      courier_date: "",
                    });
                  }}
                  minDate={newDate.setDate(newDate.getDate() - 1)}
                  maxDate={new Date()}
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />

                <div className="small text-danger">
                  {formValidation.courier_date}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div class="px-4 pb-2">
        <h6 class="f-14 mt-2">
          <strong>
            <span class="text-muted">Delay Deduction:</span>
            <span class="text-danger">
              {calculateDateOfJoining(
                new Date(userDetails?.joiningDate?.date_of_joining),
                new Date()
              ) < 61
                ? 0
                : userDetails?.emp_sub_group?.includes(["D1", "D2", "C1"])
                ? 0
                : state?.travel_detail_data?.length > 0 &&
                  state?.claim_date !== null
                ? calculateDateDifference(
                    moment(state?.claim_date).format("YYYY-MM-DD"),
                    state?.travel_detail_data[
                      state?.travel_detail_data?.length - 1
                    ]?.to_date
                  ) + " Days"
                : calculateDateDifference(
                    moment(formattedDate).format("YYYY-MM-DD"),
                    state?.travel_detail_data[
                      state?.travel_detail_data?.length - 1
                    ]?.to_date
                  ) + " Days"}
            </span>
          </strong>
          &nbsp;&nbsp;
          <strong>
            <>
              {/* <span class="text-muted">Deduction Percent :</span> */}
              <span class="text-danger">
                {calculateDateOfJoining(
                  new Date(userDetails?.joiningDate?.date_of_joining),
                  moment(state?.claim_date).format("YYYY-MM-DD")
                ) < 61 ||
                userDetails?.emp_sub_group?.includes(["D1", "D2", "C1"])
                  ? "0%"
                  : state?.claim_date !== null &&
                    calculateDateDifference(
                      moment(state?.claim_date).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) <= Number(7)
                  ? "0%"
                  : calculateDateDifference(
                      moment(formattedDate).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) <= Number(7)
                  ? "0%"
                  : state?.claim_date !== null &&
                    calculateDateDifference(
                      moment(state?.claim_date).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) >= Number(8) &&
                    calculateDateDifference(
                      moment(state?.claim_date).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) <= Number(20)
                  ? "(5%)"
                  : calculateDateDifference(
                      moment(formattedDate).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) >= Number(8) &&
                    calculateDateDifference(
                      moment(formattedDate).format("YYYY-MM-DD"),
                      state?.travel_detail_data[
                        state?.travel_detail_data?.length - 1
                      ]?.to_date
                    ) <= Number(20)
                  ? "(5%)"
                  : "(100%)"}
              </span>
            </>
          </strong>
        </h6>

        <div class="form-group innergroup">
          <label class="logCheck d-inline-block acceptchkbox">
            I hereby confirmed that the expense claimed by me are in accordance
            with company's travel policy and has been incurred during the course
            of discharging my office work only. All bills are pertaining to DA
            claim are retained at my end, will be submitted as and when
            required.
            <input
              type="checkbox"
              name="statusupdate1"
              onChange={() => {
                setCheckData((prev) => !prev);
              }}
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="mb-3">
        <div class="row">
          <div class="col-md-12">
            <div class="border-top my-3"></div>
          </div>
          <div class="col-md-12 text-right ">
            <button
              type="button"
              class="btn btn-outline-secondary mb-2 mt-2"
              onClick={() => {
                navigate("/expense-listing");
              }}
            >
              Back
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-primary-inner bpi-main"
              onClick={() =>
                navigate("/expensesPrint", {
                  state: state,
                })
              }
            >
              Print
            </button>
            &nbsp;&nbsp;
            <button
              disabled={!checkData}
              class="btn btn-primary-inner bpi-main"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
