import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import * as PrService from "../../service/prService";
import * as PrOrgService from "../../service/purchaseOrg";
import * as PrPurchaseGroup from "../../service/purchaseGroup";
import * as MaterialService from "../../service/materialService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { selectUserData, selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { getUserRmList } from "../../service/jv_service";
import { Tooltip } from "react-tippy";

let initialValues = {
  user_id: "",
  pr_type_id: "",
  purchase_org_id: "",
  plant_id: "",
  purchase_group_id: "",
  purchaser_id: "",
  rel_strategy: "Z1",
  // "s1": "",
  c2: "",
  c3: "",
  status: true,
};
const createFlag = false;
function PRUserMaster() {
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);
  const [currentItem, setCurrentItem] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalFlag, setModalFlag] = useState(false);
  const [coloumnName, setColoumnName] = useState("");

  /* refs for react-selects */
  const plantRef = useRef(null);
  const prTypeRef = useRef(null);
  const purchasingOrg = useRef(null);
  const purchasingGroup = useRef(null);
  const userID = useRef(null);
  const PurchaserID = useRef(null);
  const C2Release = useRef(null);
  const C3Release = useRef(null);
  /* refs for react-selects */

  const releaseStrategyOptions = [
    { value: "Z1", label: "Z1" },
    // { value: 'Z2', label: 'Z2' }
  ];

  const [purchaseOrgOptions, setPurchaseOrgOptions] = useState([]);
  const [purchaseGroupsOptions, setPurchaseGroupsOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [prTypeOptions, setprTypeOptions] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [show, filtersetShow] = useState(false);
  const [filterState, setFilterState] = useState({});
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: "-id",
  });
  const [searchStr, setSearchStr] = useState("");
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => {
    addnewsetShow(false);
    setCurrentItem(null);
  };

  const getSelectFeilds = (options, key) => {
    return {
      value: options
        ? options.find((option) => option.value === values[key])
        : "",
      onChange: (option) => {
        if (Array.isArray(option)) {
          return setFieldValue(
            key,
            option.map((item) => item.value)
          );
        }
        setFieldValue(key, option?.value);
      },
    };
  };

  const removeC2 = () => {
    setFieldValue("c2", "");
    currentItem.c2 = null
    C2Release.current?.setValue({
      value: '',
      label: "Select...",
    });
  };

  const getSelectFeildsFilter = (options, key) => {
    return {
      value: options
        ? options.find((option) => option.value === filterState[key])
        : "",
      onChange: (option) => {
        if (Array.isArray(option)) {
          return setFilterState((pre) => ({
            ...pre,
            [key]: option.map((item) => item.value),
          }));
        }
        setFilterState((pre) => ({ ...pre, [key]: option.value }));
      },
    };
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getData(e.target.value.toLowerCase(), filter, 1, pageSizeNo, "id", true);
      setSearchStr(e.target.value);
    }
  };

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(searchStr, filter, activePage, pageSizeNo, "id", true);
  };

  const handleKey = (search, filter, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      for (const key in filter) {
        queryParm += `&${key}=${filter[key]}`;
      }
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    await PrService.getPrUserAuthorizations(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getPurchaseOrgs = () => {
    PrOrgService.getReleaseCode("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.code + "(" + item?.desc + ")",
          }));
          setPurchaseOrgOptions(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getPurchaseGroups = () => {
    PrPurchaseGroup.getReleaseCode("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.pgcode + "(" + item?.pgdesc + ")",
          }));
          setPurchaseGroupsOptions(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  // const getUsers = () => {
  //   MaterialService.getUserData(0)
  //     .then((response) => {
  //       if (response.data.code === 200) {
  //         const list = response?.data?.dataList?.result;
  //         const options = list.map(user => ({ value: user.id, label: `${user.first_name+" "+user.last_name}(${user.username})` }));
  //         setUsersOptions(options);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.warn(error.message);
  //       console.log(error);
  //     });
  // };
  const getUsers = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setUsersOptions(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getPlants = () => {
    PrService.getPlantsList({ organisation_id: userData?.organisation_id })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.code + "(" + item?.description + ")",
          }));
          setPlantOptions(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const getPrTypes = () => {
    PrService.getPrTypes("paginate=false&all=true")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: item.pr_desc,
          }));
          setprTypeOptions(options);
        }
      })
      .catch((error) => {
        toast.warn(error.message);
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to proceed",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await PrService.deletePrUserAuthorization(id)
              .then((response) => {
                if (response?.data?.code === 200) {
                  getData(
                    searchStr,
                    filter,
                    currentPage,
                    pageSizeNo,
                    filter.sort_by,
                    true
                  );
                  toast.success(response?.data?.message);
                }
              })
              .catch((error) => {
                toast.error(error?.response?.data?.message ?? error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("bye"),
        },
      ],
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      currentItem == null &&
      Yup.object({
        user_id: Yup.string().required("* Required"),
        pr_type_id: Yup.array().of(Yup.string()).required("* Required"),
        purchase_org_id: Yup.array().of(Yup.string()).required("* Required"),
        plant_id: Yup.array().of(Yup.string()).required("* Required"),
        purchase_group_id: Yup.array().of(Yup.string()).required("* Required"),
        purchaser_id: Yup.string().required("* Required"),
        rel_strategy: Yup.string().required("* Required"),
        // "s1": Yup.string().required('* Required'),
        // "c2": Yup.string().required('* Required'),
        c3: Yup.string().required("* Required"),
      }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(userID);
      try {
        let response = null;
        if (currentItem) {
          values.id = currentItem.id;
          values.user_id =
            values.user_id != "" ? values.user_id : currentItem.user_id;
          values.c2 = values.c2 != "" ? values.c2 : currentItem.c2;
          values.c3 = values.c3 != "" ? values.c3 : currentItem.c3;
          values.pr_type =
            values.pr_type_id != ""
              ? values.pr_type_id
              : currentItem.pr_type.map((x) => {
                return x.pr_type_id;
              });
          values.purchase_organisation =
            values.purchase_org_id != ""
              ? values.purchase_org_id
              : currentItem.purchase_organisation.map((x) => {
                return x.purchase_org_id;
              });
          values.plant =
            values.plant_id != ""
              ? values.plant_id
              : currentItem.plant.map((x) => {
                return x.plant_id;
              });
          values.purchase_group =
            values.purchase_group_id != ""
              ? values.purchase_group_id
              : currentItem.purchase_group.map((x) => {
                return x.purchase_group_id;
              });
          values.purchaser_id =
            values.purchaser_id != ""
              ? values.purchaser_id
              : currentItem.purchaser_id;

          response = await PrService.updatePrUserAuthorization(
            values.id,
            values
          );
        } else {
          response = await PrService.addPrUserAuthorization(values);
        }
        if (response.data.code === 200) {
          setCurrentPage(1);
          getData(
            searchStr,
            filter,
            currentPage,
            pageSizeNo,
            filter.sort_by,
            true
          );
          resetForm();
          toast.success(response.data.message);
          addnewclose();
        } else {
          toast.warn(response.data.message);
        }
      } catch (error) {
        toast.warn(error.message);
        console.error("error", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleEdit = (item) => {
    setCurrentItem({ ...item });
    addnewopen();
  };

  const handleApplyFilter = () => {
    setFilterApplied(true);
    setFilter((pre) => ({ ...pre, ...filterState }));
    getData(searchStr, filterState, 1, pageSizeNo, filter.sort_by, true);
    filterclose();
  };

  const handleClearFilter = () => {
    setFilterApplied(false);
    setFilter({ sort_by: "id" });
    setFilterState({});
    filterclose();
    getData(searchStr, {}, 1, pageSizeNo, filter.sort_by, true);
  };

  const getExportDetails = () => {
    PrService.getExportValueUserMaster(
      handleKey(
        searchStr,
        filter,
        currentPage,
        pageSizeNo,
        filter.sort_by,
        true
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "UserMaster.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  useEffect(() => {
    console.log(currentItem);
    if (currentItem) {
      plantRef.current?.setValue([
        ...currentItem?.plant?.map((item) => ({
          value: item?.plant_id,
          label: item?.code + "(" + item?.description + ")",
        })),
      ]);
      purchasingOrg.current?.setValue([
        ...currentItem?.purchase_organisation?.map((item) => ({
          value: item?.purchase_org_id,
          label: item?.code + "(" + item?.desc + ")",
        })),
      ]);
      purchasingGroup.current?.setValue([
        ...currentItem?.purchase_group?.map((item) => ({
          value: item?.purchase_group_id,
          label: item?.pgcode + "(" + item?.pgdesc + ")",
        })),
      ]);
      prTypeRef.current?.setValue([
        ...currentItem?.pr_type?.map((item) => ({
          value: item?.pr_type_id,
          label: item?.pr_desc,
        })),
      ]);
      userID.current?.setValue({
        value: currentItem?.user_id,
        label: currentItem?.user_name + "(" + currentItem?.user_code + ")",
      });
      C2Release.current?.setValue({
        value: currentItem?.c2,
        label: currentItem?.c2_name + "(" + currentItem?.c2_code + ")",
      });
      C3Release.current?.setValue({
        value: currentItem?.c3,
        label: currentItem?.c3_name + "(" + currentItem?.c3_code + ")",
      });
      PurchaserID.current?.setValue({
        value: currentItem?.purchaser_id,
        label:
          currentItem?.purchaser_name + "(" + currentItem?.purchaser_code + ")",
      });
      let obj = values
      obj.status = currentItem?.is_active
      obj.user_id = currentItem?.user_id
      obj.c2 = currentItem?.c2
      obj.c3 = currentItem?.c3
      obj.pr_type_id = currentItem.pr_type.map((x) => {
        return x.pr_type_id;
      });
      obj.purchase_org_id = currentItem.purchase_organisation.map((x) => {
        return x.purchase_org_id;
      });
      obj.plant_id = currentItem.plant.map((x) => {
        return x.plant_id;
      });
      obj.purchase_group_id = currentItem.purchase_group.map((x) => {
        return x.purchase_group_id;
      });
      obj.purchaser_id = currentItem?.purchaser_id
      obj.rel_strategy = currentItem?.rel_strategy
      setValues(obj)
    }
  }, [currentItem]);

  const {
    setValues,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    values,
    isSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    getData(searchStr, filter, currentPage, pageSizeNo, filter.sort_by, true);
  }, [pageSizeNo]);

  useEffect(() => {
    if (addnewshow || show) {
      /* only fetching data when modal is open */
      getPurchaseGroups();
      getPurchaseOrgs();
      getPlants();
      getUsers();
      getPrTypes();
      resetForm();
    }
  }, [addnewshow, show]);

  const viewAllDetails = (data, name) => {
    setModalData(data)
    setColoumnName(name)
    setModalFlag(true)
  }
  const onSelectStatus = (newValue, name) => {
    setValues({
      ...values,
      status: newValue
    })
  };

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="backleftlist">
              <Link to="/pr-list">
                <span>
                  <i class="fas fa-arrow-alt-circle-left f-12"></i> Back
                </span>
              </Link>
            </li>
            <li>
              <Link to="/pr-unit-master">Unit Master</Link>
            </li>
         
            <li>
              <Link to="/pr-group-master">Purchasing Group Master</Link>
            </li>
            <li>
              <Link to="/pr-org-master">Purchasing Organization Master</Link>
            </li>
            <li>
              <Link to="/pr-type-master">Type Master</Link>
            </li>
            <li class="active">
              <Link to="/pr-user-master">User Authorization Master</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">User Authorization Master</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      User Authorization Master
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item> */}
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A ?
                      <button class="btn btn-primary-inner" onClick={addnewopen}>
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                      :
                      ""
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Status</th>
                    <th>User ID</th>
                    <th>PR Type</th>
                    <th>Purch. Org.</th>
                    <th>Plant</th>
                    <th>Purch. Group</th>
                    <th>Release Strategy</th>
                    <th>Purch. ID</th>
                    {/* <th>S1 Release</th> */}
                    <th>C2 Release</th>
                    <th>c3 Release</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <span
                            class="btn btn-blanktd text-primary f-12"
                            onClick={() => handleEdit(item)}
                          >
                            <i class="far fa-edit"></i>
                          </span>
                          <span
                            class="btn btn-blanktd text-danger ml-2 f-12"
                            onClick={() => handleDelete(item.id)}
                          >
                            <i class="far fa-trash-alt text-danger"></i>
                          </span>
                        </td>
                        <td>
                          {item?.is_active ? (
                            <span class="badge bad-status badge-success">
                              Active
                            </span>
                          ) : (
                            <span class="badge bad-status badge-danger">
                              InActive
                            </span>
                          )}
                        </td>
                        <td class="">
                          <Tooltip title={item?.user_code} position="bottom">
                            {item.user_code
                              ? `${item.user_name || ""}(${item.user_code || ""})`
                              : "--"}
                          </Tooltip>
                        </td>
                        <td>
                          <span onClick={() => { viewAllDetails(item?.pr_type, "PR Type") }}>
                            {item?.pr_type
                              .map((obj) => `${obj.pr_desc}(${obj.pr_code})`)
                              .join(",")}
                          </span>
                        </td>
                        <td>
                          <span onClick={() => { viewAllDetails(item?.purchase_organisation, "Purchase Org.") }}>
                            {item?.purchase_organisation
                              .map((obj) => `${obj.desc}(${obj.code})`)
                              .join(",")}
                          </span>
                        </td>
                        <td>
                          <span onClick={() => { viewAllDetails(item?.plant, "Plant") }}>
                            {item?.plant
                              .map((obj) => `${obj.description}(${obj.code})`)
                              .join(",")}
                          </span>
                        </td>
                        <td>
                          <span onClick={() => { viewAllDetails(item?.purchase_group, "Purchase Group") }}>
                            {item?.purchase_group
                              .map((obj) => `${obj.pgdesc}(${obj.pgcode})`)
                              .join(",")}
                          </span>
                        </td>
                        <td>{item.rel_strategy}</td>
                        <td>
                          <Tooltip title={item?.purchaser_code} position="bottom">
                            {item.purchaser_code
                              ? `${item.purchaser_name || ""}(${item.purchaser_code || ""
                              })`
                              : "--"}
                          </Tooltip>
                        </td>
                        {/* <td>
                          {item.s1_code
                            ? `${item.s1_name || ""}(${item.s1_code || ""})`
                            : "--"}
                        </td> */}
                        <td>
                          {item.c2_code
                            ? `${item.c2_name || ""}(${item.c2_code || ""})`
                            : "--"}
                        </td>
                        <td>
                          {item.c3_code
                            ? `${item.c3_name || ""}(${item.c3_code || ""})`
                            : "--"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div className="col-sm-5">
                <div className="sortinglist">
                  Show
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>10</option>
                    <option value={20} label={20}>20</option>
                    <option value={30} label={30}>30</option>
                    <option value={100} label={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filter modal */}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              User ID<span class="text-danger"></span>
            </label>
            <Select
              options={usersOptions}
              {...getSelectFeildsFilter(usersOptions, "user_id")}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Purchasing Organization<span class="text-danger"></span>
            </label>
            <Select
              options={purchaseOrgOptions}
              {...getSelectFeildsFilter(purchaseOrgOptions, "purchase_org_id")}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Plant<span class="text-danger"></span>
            </label>
            <Select
              options={plantOptions}
              {...getSelectFeildsFilter(plantOptions, "plant_id")}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Purchasing Group<span class="text-danger"></span>
            </label>
            <Select
              options={purchaseGroupsOptions}
              {...getSelectFeildsFilter(
                purchaseGroupsOptions,
                "purchase_group_id"
              )}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              value={filterState.is_active}
              onChange={(e) =>
                setFilterState((pre) => ({ ...pre, is_active: e.target.value }))
              }
            >
              <option>Select</option>
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={handleClearFilter}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={handleApplyFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit modal */}
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add/Edit User Authorization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        User ID<span class="text-danger">*</span>
                      </label>
                      <Select
                        ref={userID}
                        options={usersOptions}
                        {...getSelectFeilds(usersOptions, "user_id")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {formik.touched?.user_id && formik.errors?.user_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        PR Type<span class="text-danger">*</span>
                      </label>
                      <Select
                        isMulti
                        ref={prTypeRef}
                        options={prTypeOptions}
                        {...getSelectFeilds(prTypeOptions, "pr_type_id")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.pr_type_id && errors?.pr_type_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Purchasing Organization
                        <span class="text-danger">*</span>
                      </label>
                      <Select
                        isMulti
                        ref={purchasingOrg}
                        options={purchaseOrgOptions}
                        {...getSelectFeilds(
                          purchaseOrgOptions,
                          "purchase_org_id"
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.purchase_org_id && errors?.purchase_org_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Plant<span class="text-danger">*</span>
                      </label>
                      <Select
                        isMulti
                        ref={plantRef}
                        options={plantOptions}
                        {...getSelectFeilds(plantOptions, "plant_id")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.plant_id && errors?.plant_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Purchasing Group<span class="text-danger">*</span>
                      </label>
                      <Select
                        isMulti
                        ref={purchasingGroup}
                        options={purchaseGroupsOptions}
                        {...getSelectFeilds(
                          purchaseGroupsOptions,
                          "purchase_group_id"
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.purchase_group_id &&
                          errors?.purchase_group_id}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Release Strategy<span class="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled
                        options={releaseStrategyOptions}
                        {...getSelectFeilds(
                          releaseStrategyOptions,
                          "rel_strategy"
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.rel_strategy && errors?.rel_strategy}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        Purchaser ID<span class="text-danger">*</span>
                      </label>
                      <Select
                        ref={PurchaserID}
                        {...getSelectFeilds(usersOptions, "purchaser_id")}
                        options={usersOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.purchaser_id && errors?.purchaser_id}
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        S1 Release<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={usersOptions}
                        {...getSelectFeilds(usersOptions, "s1")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.s1 && errors?.s1}
                      </div>
                    </div>
                  </div> */}
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        C2 Release
                        <span class="text-danger ml-5" style={{ cursor: "pointer" }} onClick={removeC2}>
                          Remove
                        </span>
                      </label>
                      <Select
                        ref={C2Release}
                        options={usersOptions}
                        {...getSelectFeilds(usersOptions, "c2")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.c2 && errors?.c2}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label>
                        C3 Release<span class="text-danger">*</span>
                      </label>
                      <Select
                        ref={C3Release}
                        options={usersOptions}
                        {...getSelectFeilds(usersOptions, "c3")}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      <div className="small text-danger">
                        {touched?.c3 && errors?.c3}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label className="d-block mb-2">
                        Status<span class="text-danger">*</span>
                      </label>
                      <label class="logCheck d-inline-block mr-4">
                        Active
                        <input type="radio" name="status"
                          {...getSelectFeilds(usersOptions, "c3")}
                          onClick={() => {
                            onSelectStatus(true, "status");
                          }}
                          checked={values?.status === true}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="logCheck d-inline-block">
                        Inactive
                        <input type="radio" name="status"
                          onClick={() => {
                            onSelectStatus(false, "status");
                          }}
                          checked={values?.status === false}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              class="btn btn-outline-danger"
              onClick={() => {
                addnewclose();
                resetForm();
              }}
            >
              Cancel
            </div>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={modalFlag}
        onHide={() => setModalFlag(false)}
        size="lg"
        centered
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>{coloumnName}</Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>{coloumnName} :- </label><span class="text-danger"></span>
                    {coloumnName === "PR Type" && (
                      modalData?.map((obj) => `${obj.pr_desc}(${obj.pr_code})`)
                        .join(",")
                    )}
                    {coloumnName === "Purchase Org." && (
                      modalData
                        .map((obj) => `${obj.desc}(${obj.code})`)
                        .join(",")
                    )}
                    {coloumnName === "Plant" && (
                      modalData
                        .map((obj) => `${obj.description}(${obj.code})`)
                        .join(",")
                    )}
                    {coloumnName === "Purchase Group" && (
                      modalData
                        .map((obj) => `${obj.pgdesc}(${obj.pgcode})`)
                        .join(",")
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              setModalData([])
              setModalFlag(false)
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default PRUserMaster;
