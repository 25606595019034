import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as searchPoService from "../../service/searchpo";
import ReactPaginate from "react-paginate";
import ViewContractSearch from "./ViewContractSearch";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import CaUrls from "./CaUrls";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
  injectStyle();
}

function ContractApproval() {
  const dispatch = useDispatch();

  const [pageCount, setpageCount] = useState(1);
  const [searchPoData, setSearchPoData] = useState([]);
  const [perPageData, setPerPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [viewForm, setViewForm] = useState(false);
  const [dataView, setDataView] = useState([]);
  const [condition, setCondition] = useState([]);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&category_type=" +
      category_type;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSearchPo = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    dispatch(setDisplayLoader("Display"));

    searchPoService
      .getSearchPo(
        handleKey(search, page_no, page_size, sort_by, paginate, category_type)
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSearchPoData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setPerPageData(response?.data?.dataList?.paginated_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  console.log("searchPoData", searchPoData);

  const getViewOrder = (id) => {
    searchPoService
      .getViewOrder(id)
      .then((response) => {
        setDataView(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getSearchPo(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "CONTRACT"
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSearchPo(data, 1, pageSizeNo, "-id", true, "CONTRACT");
    }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSearchPo(searchStr, activePage, pageSizeNo, "-id", true, "CONTRACT");
  };

  const getExportDetails = (search, sort_by, paginate, contract_type) => {
    searchPoService
      .getExportValue(search, sort_by, paginate, contract_type)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "searchContractApproval.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const openViewForm = (data) => {
    setViewForm(true);
    getViewOrder(data?.id);
    setCondition(data);
  };

  const closeViewForm = (data) => {
    setViewForm(false);
  };

  const onCallBack = () => {
    setViewForm(false);
    getSearchPo("", 1, pageSizeNo, "-id", true, "CONTRACT");
  };

  const sendEmail = (email, type, user_name, po_no, po_id) => {
    searchPoService
      .sendEmailCA(email, type, user_name, po_no, po_id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getSearchPo("", 1, pageSizeNo, "-id", true, "CONTRACT");
  }, [pageSizeNo]);

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };

  return (
    <>
      {viewForm ? (
        <>
          <ViewContractSearch
            dataView={dataView}
            closeViewForm={closeViewForm}
            onCallBack={onCallBack}
            condition={condition}
          />
        </>
      ) : (
        <>
          <div class="row">
            <ToastContainer autoClose={1000} />
            {/* <div class="col-md-3">
              <CaUrls />
            </div> */}

            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner/2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <h4 class="inner-page-title">Search Contract</h4>
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>

                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search Contract Number..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <button
                                disabled={
                                  searchPoData.length !== 0 ? false : true
                                }
                                style={{ border: 0, background: "none" }}
                                onClick={() =>
                                  getExportDetails(
                                    searchStr,
                                    "-id",
                                    true,
                                    "CONTRACT"
                                  )
                                }
                              >
                                <i className="far fa-file-excel"></i>Excel
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>PO NO</th>
                        <th>Creation Date</th>
                        <th>Vendor Name</th>
                        <th>Total Price</th>
                        <th>Currency</th>
                        <th>Created by</th>
                        <th>Approved By</th>
                        <th>Pending</th>
                        <th>Declined</th>
                        {/* <th>Send Reminder</th> */}
                        <th>Sap Log</th>
                        <th>Sap Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchPoData?.length ? (
                        searchPoData?.map((data, index) => (
                          <>
                            <tr key={`po${index}`}>
                              <td
                                class="text-theme fw-bold"
                                onClick={() => {
                                  openViewForm(data);
                                }}
                              >
                                {data?.po_no}
                              </td>
                              <td>
                                {" "}
                                {moment(data?.created_at).format("Do MMM YYYY")}
                              </td>
                              <td> {data?.vendor_name}</td>
                              <td> {changeData(data?.total_price)}</td>

                              <td> {data?.currency_code}</td>
                              <td> {data?.created_by_user}</td>

                              <td>{data?.approved_by}</td>
                              <td> {data?.pending}</td>
                              <td>{data?.declined}</td>
                              {/* <td>
                                {" "}
                                {data?.send_reminder && (
                                  <>
                                    <button
                                      class="btn btn-blanktd"
                                      type="button"
                                      onClick={() =>
                                        sendEmail(
                                          data?.pending_email,
                                          "Pending",
                                          data?.user_name,
                                          data?.po_no,
                                          data?.id
                                        )
                                      }
                                    >
                                      <span class="badge badge-secondary">
                                        <i class="far fa-bell"></i> Send
                                        Reminder
                                      </span>
                                    </button>
                                  </>
                                )}
                              </td> */}
                              <td>
                                <Tooltip title={data?.log} position="bottom">
                                  {data?.log?.substr(0, 12)}...
                                </Tooltip>
                              </td>
                              <td>
                                {data?.sap_status ? "Synced" : "Not Synced"}
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {searchPoData?.length == 0 ? (
                  ""
                ) : (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      {/* <Pagination>
                <Pagination.Item onClick={() => getNextPrevPage(0)}>
                  <i className="fa fa-angle-left"></i>
                </Pagination.Item>
                <Pagination.Item className="main-pointer-remove">
                  <span>
                    {currentPage}/ {countryData?.totalPages}
                  </span>
                </Pagination.Item>
                <Pagination.Item onClick={() => getNextPrevPage(1)}>
                  <i className="fa fa-angle-right"></i>
                </Pagination.Item>
              </Pagination> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ContractApproval;
