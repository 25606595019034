import {React, useEffect, useState} from "react";
import { Modal, Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { getUserRmList } from "../../service/jv_service";
import * as VisitorService from './visitor_service';
import { toast } from "react-toastify";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tippy";

function VisitingSiteMaster() {

    const [editMode, setEditMode] = useState(false);
    const [addnewshow, addnewsetShow] = useState(false);
    const addnewopen = () => addnewsetShow(true);
    const addnewclose = () => {
      addnewsetShow(false);
      setStatus(true);
      setSiteOwnerValue('');
      setNearestAirport('');
      setDistanceFromMumbai('');
      setDistanceFromDelhi('');
      setNearestCityStay('');
      setDistanceOfSite('');
      setSiteOperatingHour('');
      setLocalCityAttraction('');
      setEditMode(false);
    };
    const [siteOptions, setSiteOptions] = useState([]);
    
    const [siteOwner, setSiteOwner] = useState('');
    const [siteOwnerValue, setSiteOwnerValue] = useState('');
    const [nearestAirport, setNearestAirport] = useState('');
    const [distanceFromMumbai, setDistanceFromMumbai] = useState('');
    const [distanceFromDelhi, setDistanceFromDelhi] = useState('');
    const [nearestCityStay, setNearestCityStay] = useState('');
    const [distanceOfSite, setDistanceOfSite] = useState('');
    const [siteOperatingHour, setSiteOperatingHour] = useState('');
    const [localCityAttraction, setLocalCityAttraction] = useState('');

    const [status, setStatus] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [searchStr, setSearchStr] = useState('');
    const [pageCount, setpageCount] = useState(1);
    const [visitingSiteMasterData, setVisitingSiteMasterData] = useState([]);
    const [editSiteId, setEditSiteId] = useState(null);
    const [emailUserValue, setEmailUserValue] = useState([]);
    const [emailUser, setEmailUser] = useState('');

    useEffect(()=>{
        getVisitingSiteMasterData(
          true,
          currentPage,
          pageSizeNo,
          searchStr
        );
        getUsers();
    },[pageSizeNo])

    const getUsers = () => {
        VisitorService.getPlantList()
          .then((response) => {
            console.log(response);
            let users = [];
            response?.data?.dataList?.result?.map((x) => {
              let data = {
                value: x?.id,
                label: x?.description + "(" + x?.code + ")",
              };
              users.push(data);
            });
            setSiteOptions(users);
          })
          .catch((error) => {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      };
   
      const saveSiteMasterData = () => {
        // if(siteOwner == '') 
        let obj = {
          visiting_site_id: siteOwner,
          nearest_airport: nearestAirport,
          distance_from_mumbai: distanceFromMumbai,
          distance_from_delhi: distanceFromDelhi,
          nearest_city: nearestCityStay,
          site_distance_from_city: distanceOfSite,
          site_operating_hours: siteOperatingHour,
          local_city_attraction: localCityAttraction,
          
        }
        
        VisitorService.save_visiting_site_master(obj).then((response)=>{
          if(response.data.message === 'Data added successfully'){
            addnewclose()
            toast.success(response.data.message)
            getVisitingSiteMasterData(
              true,
              currentPage,
              pageSizeNo,
              searchStr
            );
          }
          else{
            toast.error(response.data.message)
          }
        })
      }

      const handleKey = (paginate, page_no, page_size, search) => {
        let queryParm =
          "page_no=" +
          page_no +
          "&page_size=" +
          page_size +
          "&paginate=" +
          paginate;

        if (search !== "" && search !== undefined && search !== null) {
          queryParm = queryParm + "&search=" + search;
        }

        return queryParm;
      }

      const getVisitingSiteMasterData  = (paginate, page_no, page_size, search) => {
        VisitorService.get_visiting_site_master_response(handleKey(
          paginate,
          page_no,
          page_size,
          search
        )).then((response)=>{
          console.log({response});
          setVisitingSiteMasterData(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
        })
      }

      const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        getVisitingSiteMasterData(
          true,
          activePage,
          pageSizeNo,
          searchStr
        );
      }

      const editSite = (data) => {
        console.log(data);
        setEditSiteId(data?.id);
        setEditMode(true);
        
        
         
        let obj = {value: data?.visiting_site_id, label: data?.site_id }
        setSiteOwnerValue(obj);
        setSiteOwner(data?.visiting_site_id);    
        setNearestAirport(data?.nearest_airport);
        setDistanceFromMumbai(data?.distance_from_mumbai);
        setDistanceFromDelhi(data?.distance_from_delhi);
        setNearestCityStay(data?.nearest_city);
        setDistanceOfSite(data?.site_distance_from_city);
        setSiteOperatingHour(data?.site_operating_hours);
        setLocalCityAttraction(data?.local_city_attraction);
        addnewopen();
      }

      const updateCategoryMasterData = () => {
        let obj = {
          visiting_site_id: siteOwner,
          nearest_airport: nearestAirport,
          distance_from_mumbai: distanceFromMumbai,
          distance_from_delhi: distanceFromDelhi,
          nearest_city: nearestCityStay,
          site_distance_from_city: distanceOfSite,
          site_operating_hours: siteOperatingHour,
          local_city_attraction: localCityAttraction,
          
        }
        console.log(obj);
        VisitorService.update_visiting_site_master(editSiteId, obj).then((response)=>{
          toast.success('Category Master Updated Successfully')
          addnewclose();
          getVisitingSiteMasterData(
            true,
            currentPage,
            pageSizeNo,
            searchStr
          );
        })
      }

      const confirmDelete = (id) =>{
        console.log(id);
        confirmAlert({
          title: "Delete Site ",
          message: `Are you sure to delete Site Master ?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => deleteSiteMaster(id),
            },
            {
              label: "No",
            },
          ],
        });
      };

      const deleteSiteMaster = (id) => {
        VisitorService.delete_site_master(id).then((response)=>{
          toast.success('Site deleted Successfully')
          getVisitingSiteMasterData(
            true,
            currentPage,
            pageSizeNo,
            searchStr
          );
        })
      }


    return (
        <>
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Visiting Site Master</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        // onChange={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      
                      <button
                        // onClick={filteropen}
                        class="btn btn-secondary-inner"
                        // style={{
                        //   backgroundColor: filterApplied && "yellow",
                        //   color: filterApplied && userData?.dark_mode === false && "#000"
                        // }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                        <button class="btn btn-primary-inner" 
                        onClick={addnewopen}
                        >
                          <img src="images/upload.png" alt="" class="mr-3" />
                          Add New
                        </button>
                   
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Visiting Site Name</th>
                      <th>Nearest Airport</th>
                      <th>Distance from Mumbai</th>
                      <th>Distance from Delhi</th>
                      <th>Nearest City for Stay</th>
                      <th>Distance of site from city</th>
                      <th>Mfg. Site Operating hours</th>
                      <th>Local City attractions</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitingSiteMasterData?.length > 0 ?
                    visitingSiteMasterData?.map((data)=>(
                        <tr>
                            <td>
                            <button
                              className="btn btn-blanktd text-primary"
                              onClick={()=>{editSite(data)}}
                              >
                              <i className="far fa-edit"></i>
                            </button>

                              <button
                                type="button"
                                className="btn btn-blanktd text-danger ml-2"
                                onClick={()=>{confirmDelete(data?.id)}}
                              >
                                <i className="far fa-trash-alt text-danger"></i>
                              </button>
                            </td>
                            <td>{data?.site_id}</td>
                            <td>{data?.nearest_airport}</td>
                            <td>{data?.distance_from_mumbai}</td>
                            <td>{data?.distance_from_delhi}</td>
                            <td>{data?.nearest_city}</td>
                            <td>{data?.site_distance_from_city}</td>
                            <td>{data?.site_operating_hours}</td>
                            <td>{data?.local_city_attraction}</td>
                           
                            <td>{moment(data?.updated_at).format('DD-MM-YYYY')}</td>
                        </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={12}>No Records Found...</td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
  
              <div class="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>10</option>
                      <option value={20} label={20}>20</option>
                      <option value={30} label={30}>30</option>
                      <option value={100} label={100}>100</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Filter modal */}
        <Modal
        //   show={show}
        //   onHide={filterclose}
          className="filter-popup modaldefaultclose"
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="form-group innergroup">
              <label>
                User ID<span class="text-danger"></span>
              </label>
              <Select
                // options={usersOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Purchasing Organization<span class="text-danger"></span>
              </label>
              <Select
                // options={purchaseOrgOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Plant<span class="text-danger"></span>
              </label>
              <Select
                // options={plantOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Purchasing Group<span class="text-danger"></span>
              </label>
              <Select
                // options={purchaseGroupsOptions}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
  
            <div class="form-group innergroup">
              <label>
                Select Status<span class="text-danger"></span>
              </label>
              <select
                class="form-control newbgselect"
                // value={filterState.is_active}
                // onChange={(e) =>
                //   setFilterState((pre) => ({ ...pre, is_active: e.target.value }))
                // }
              >
                <option>Select</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button class="btn btn-outline-danger"
            //  onClick={handleClearFilter}
             >
              Cancel
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
            //   onClick={handleApplyFilter}
            >
              Apply
            </button>
          </Modal.Footer>
        </Modal>
  
        {/* Add/Edit modal */}
        <Modal
          show={addnewshow}
          onHide={addnewclose}
          backdrop="static"
          keyboard={false}
          size="lg"
          className="modaldefaultclose modaldefaultclosecenter"
        >
            <Modal.Header closeButton>
              <Modal.Title>Add/Edit Visiting Site Master</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="row">
                  <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Visiting Site Name<span class="text-danger">*</span>
                        </label>
                        <Select
                          options={siteOptions}
                          className="basic-multi-select"
                          classNamePrefix="Select Site"
                          value={siteOwnerValue}
                          onChange={(e)=>{
                            setSiteOwnerValue(e);
                            setSiteOwner(e.value);
                          }}
                        />
                      </div>
                    </div>
                  
                  
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Nearest Airport<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="nearest_airport"
                            placeholder="Enter Nearest International / national Airport"
                            value={nearestAirport}
                            onChange={(e) => {setNearestAirport(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Distance from Mumbai<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="distance_from_mumbai"
                            placeholder="Enter Distance from Mumbai"
                            value={distanceFromMumbai}
                            onChange={(e) => {setDistanceFromMumbai(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Distance from Delhi<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="distance_from_delhi"
                            placeholder="Enter Distance from Delhi"
                            value={distanceFromDelhi}
                            onChange={(e) => {setDistanceFromDelhi(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Nearest City for Stay<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="nearest_city_stay"
                            placeholder="Enter Nearest City for Stay"
                            value={nearestCityStay}
                            onChange={(e) => {setNearestCityStay(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Distance of site from city<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="distance_of_site"
                            placeholder="Enter Distance of site from city"
                            value={distanceOfSite}
                            onChange={(e) => {setDistanceOfSite(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Mfg. Site Operating hours<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="site_operating_hour"
                            placeholder="Enter Mfg. Site Operating hours"
                            value={siteOperatingHour}
                            onChange={(e) => {setSiteOperatingHour(e.target.value)}}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>
                        Local City attractions<span class="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="local_city_attraction"
                            placeholder="Enter Local City attractions"
                            value={localCityAttraction}
                            onChange={(e) => {setLocalCityAttraction(e.target.value)}}
                        />
                      </div>
                    </div>
                   

                  
                    
                    {/* <div class="col-md-6">
                      <div class="form-group innergroup">
                        <label className="d-block mb-2">
                          Status<span class="text-danger">*</span>
                        </label>
                        <label class="logCheck d-inline-block mr-4">
                          Active
                          <input type="radio" name="status"
                            onClick={() => {
                              setStatus(true);
                            }}
                            checked={status === true}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          Inactive
                          <input type="radio" name="status"
                            onClick={() => {
                                setStatus(false);
                            }}
                            checked={status === false}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div
                class="btn btn-outline-danger"
                onClick={() => {
                  addnewclose();
                //   resetForm();
                }}
              >
                Cancel
              </div>
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={editMode ? updateCategoryMasterData : saveSiteMasterData}
                // disabled={isSubmitting}
              >
                Submit
              </button>
            </Modal.Footer>
        </Modal>
  
      </>
    );

}

export default VisitingSiteMaster;