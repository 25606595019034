import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Sidemenu from "./include/sidemenu";
// import Header from "./include/header";
import { Breadcrumb, Pagination, Modal, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import { sendChatText } from "../../service/delegateService";
import moment from "moment/moment";
import { getFaqs } from "../../service/faq";
import { getHolidayList } from "../../service/leaveService";
import * as leaveDataService from "../../service/leaveService";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import voice from "../../assets/images/voice-listen.jpg";

// import Logo from "../assets/images/logo.png";

function ChatGpt() {
  const [writetext, setWriteText] = useState("");
  const [showwritetext, setShowWriteText] = useState("");
  const [responsefortext, setResponseforText] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(false);
  const [faqData, setFaqData] = useState(true);
  const [faqList, setFaqList] = useState([]);
  const [managementLeaveList, setManagementLeaveList] = useState([]);
  const [leaveList, setleaveList] = useState([]);
  const [userLeaveSummaryData, setuserLeaveSummaryData] = useState([]);
  const [listenshow, setlistenShow] = useState(false);
  const listenclose = () => setlistenShow(false);

  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  // useEffect(() => {
  //     getFaqData("", "", true, 1, 10, "id", true);
  // }, []);

  const handleKey = (
    search,
    faq_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      status !== "" &&
      status !== undefined &&
      status !== null &&
      status !== []
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (faq_id !== "" && faq_id !== undefined && faq_id !== null) {
      queryParm = queryParm + "&faq_id=" + faq_id;
    }
    return queryParm;
  };

  const handletextchange = (event) => {
    console.log(event.target.value);
    setWriteText(event.target.value);
  };
  const handlechangemypi = (event) => {
    setFaqData(true);
    getFaqData("", "", true, 1, 10, "id", true);
  };

  const handleleavebalance = (event) => {
    setFaqData(false);
    getLeaveManageDataList(userData.id);
    getUserLeaveSummary(userData.id);
  };
  const getLeaveManageDataList = (id) => {
    leaveDataService
      .getLeaveManageDataList(id)
      .then((response) => {
        console.log("response", response);
        setleaveList(response?.data?.dataList?.result);
        let leaveM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = { value: value.id, label: value.leave_type_name };
          leaveM.push(data);
        });
        setManagementLeaveList(leaveM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUserLeaveSummary = (id) => {
    leaveDataService
      .getUserLeaveSummary(id)
      .then((response) => {
        setuserLeaveSummaryData(response.data.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getFaqData = async (
    search,
    faq_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    await getFaqs(
      handleKey(search, faq_id, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        if (response?.status === 200) {
          console.log(
            "response?.data?.dataList?.result",
            response?.data?.dataList?.result
          );
          setFaqList(response?.data?.dataList?.result);
          //   setOrganizData(response?.data?.dataList?.paginated_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendtext = (event) => {
    // document.getElementById("text123").value = ""
    setLoading(true);
    setShowWriteText(writetext);
    setResponseforText("");
    let obj = {
      usertext: writetext,
    };
    setWriteText("");
    sendChatText(obj)
      .then((response) => {
        if (response?.status === 200) {
          console.log("response", response);
          setResponseforText(response.data.dataList.text);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function runSpeechRecognition() {
    // get output div reference
    //  var output = document.getElementById("findqueanswerId");
    // get action element reference
    //  var action = document.getElementById("listeningId");
    // new speech recognition object
    var SpeechRecognition = SpeechRecognition || window.webkitSpeechRecognition;
    var recognition = new SpeechRecognition();

    // This runs when the speech recognition service starts
    recognition.onstart = function () {
      setlistenShow(true);

      //        action.innerHTML = "<small>listening, please speak...</small>";
      // $("#listeningId").show();
      // $("#findqueanswerId").val("");
      // $("#findqueanswerId").attr("disabled", "disabled");
    };

    recognition.onspeechend = function () {
      recognition.stop();
      setlistenShow(false);
    };

    // This runs when the speech recognition service returns result
    recognition.onresult = function (event) {
      var transcript = event.results[0][0].transcript;
      console.log("transcript", transcript);
      // console.log("confidence",confidence)
      setWriteText(transcript);
      // sendtext();
      // document.getElementById("text123").value = transcript
      // var confidence = event.results[0][0].confidence;
    };

    // start recognition
    recognition.start();
  }

  return (
    <>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2 ginniechatgpt">
              <div class="innerheadsec">
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div className="row">
                      <div class="col-md-12">
                        <img src="images/ginnelogo.png" alt="" />
                      </div>
                    </div>
                    <div class="answerboxpollsuy mt-0 bg-white p-0">
                      <div class="row">
                        <div class="col-md-12">
                          <label class="logCheck d-inline-block mr-3">
                            <input
                              type="radio"
                              name="glovalseafh"
                              checked={formData === true}
                              onClick={() => {
                                setFormData(true);
                                setShowWriteText("");
                                setResponseforText("");
                                setFaqData(true);
                              }}
                            />
                            <div>
                              <strong>MyPI</strong>
                            </div>
                            <span class="checkmark"></span>
                          </label>
                          <label class="logCheck d-inline-block">
                            <input
                              type="radio"
                              name="glovalseafh"
                              checked={formData === false}
                              onClick={() => {
                                setFormData(false);
                                setAnswer("");
                              }}
                            />
                            <div>
                              <strong>PI Genie</strong>
                            </div>
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="chat-box mb-4 border rounded chtgptbox">
                      <div class="mw-100 m-0 agreescroll raise-scroll px-4 pt-4">
                        {showwritetext ? (
                          <div class="row agent">
                            <div class="col-md-8">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="ticketreplytxt rounded">
                                    {showwritetext}
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  {/* <h6>20/07/22 | 05:30 PM</h6> */}
                                  <h6>
                                    {moment(new Date()).format(
                                      "Do MMM YYYY, h:mm:ss A"
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {responsefortext ? (
                          <div class="row agent justify-content-end text-right">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="ticketreplytxt bg-white rounded">
                                    {responsefortext}
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <h6>
                                    <i class="fas fa-check-double text-theme mr-1"></i>
                                    {moment(new Date()).format(
                                      "Do MMM YYYY, h:mm:ss A"
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* <div class="row agent justify-content-end text-right">
                                                  <div class="col-md-8">
                                                      <div class="row">
                                                          <div class="col-md-12">
                                                              <div class="ticketreplytxt bg-white rounded">
                                                              Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                                              </div>
                                                          </div>
                                                          <div class="col-md-12 mt-2"> 
                                                              <h6><i class="fas fa-check-double text-muted"></i> 20/07/22 | 05:30 PM</h6>
                                                          </div> 
                                                      </div>
                                                  </div>
                                              </div> */}

                        {/* <div class="row">
                                                  {formData ? faqList?.map((data, index) => (
                                                          <div class="col-md-2">
                                                              <div class="defaultchatgptquest">
                                                                  <button class="badge badge-secondary"
                                                                      onClick={() => {
                                                                          setAnswer(data.answer);
                                                                      }}>{data.question}</button>
                                                              </div>
                                                          </div>
                                                  )) : ("")}
                                                  </div> */}
                        {formData && (
                          <div class="row">
                            <div class="col-md-12">
                              <div class="defaultchatgptquest">
                                <button
                                  class="badge badge-secondary"
                                  onClick={handlechangemypi}
                                >
                                  Faq
                                </button>
                                <button
                                  class="badge badge-secondary"
                                  onClick={handleleavebalance}
                                >
                                  Leave Balance
                                </button>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="border-top mb-3"></div>
                            </div>
                            <div class="col-md-12 defaultchatgptquest">
                              {faqData ? (
                                faqList?.map((data, index) => (
                                  <button
                                    class="badge badge-secondary"
                                    onClick={() => {
                                      setAnswer(data.answer);
                                    }}
                                  >
                                    {data.question
                                      .replace("<p>", "")
                                      .replace("</p>", "")}
                                  </button>
                                ))
                              ) : (
                                <div class="row">
                                  <div class="col-md-7">
                                    <div class="card leave-apply-section my-4">
                                      <div class="row">
                                        <div class="col-5">
                                          <div class="leave-cc-image position-relative">
                                            <img src={userData?.logo} />
                                          </div>
                                        </div>
                                        <div class="col-7 ">
                                          <h5>{userData?.full_name}</h5>
                                          <h6 class="mb-1">
                                            <i class="far fa-check-circle"></i>
                                            {userData?.reporting_to_name}
                                          </h6>
                                          <div class="leave-grid-status">
                                            <span class="badge bad-status badge-warning">
                                              Pending
                                            </span>
                                          </div>
                                        </div>

                                        <div class="col-md-12">
                                          <h5 class="text-dark mt-2">
                                            My Leaves
                                          </h5>

                                          <div class="mt-1">
                                            <div class="dashbed-border-bottom"></div>
                                          </div>

                                          {/* <div class="row mt-1 mb-3">
                                              <div class="col-4">
                                                <h4
                                                  class="dashTitle font-weight-light text-info"
                                                  href="#"
                                                >
                                                  {
                                                    userLeaveSummaryData?.total_leaves
                                                  }
                                                </h4>
                                                <div class="dot-blue">Total</div>
                                              </div>
                                              <div class="col-4">
                                                <h4
                                                  class="dashTitle font-weight-light text-danger"
                                                  href="#"
                                                >
                                                  {
                                                    userLeaveSummaryData?.balance_leaves
                                                  }
                                                </h4>
                                                <div class="dot-blue">
                                                  Balance
                                                </div>
                                              </div>
                                              <div class="col-4">
                                                <h4
                                                  class="dashTitle font-weight-light text-success"
                                                  href="#"
                                                >
                                                  {
                                                    userLeaveSummaryData?.availed_leaves
                                                  }
                                                </h4>
                                                <div class="dot-blue">
                                                  Availed
                                                </div>
                                              </div>
                                            </div> */}

                                          <h5 class="text-dark ">
                                            Leave Detail
                                          </h5>

                                          <div class="mt-1">
                                            <div class="dashbed-border-bottom"></div>
                                          </div>

                                          <div class="table-responsive mt-2 mb-0">
                                            <table
                                              class="table  table-bordered leavestatustab"
                                              cellspacing="0"
                                              cellpadding="0"
                                            >
                                              <tbody>
                                                <tr>
                                                  <th>#</th>
                                                  <th>Leave Type</th>
                                                  <th>Current</th>
                                                  <th>Bal.</th>
                                                </tr>
                                              </tbody>
                                              <tbody>
                                                {userLeaveSummaryData?.leave_type_summary?.map(
                                                  (data, idx) => (
                                                    <tr
                                                      key={`userSummary_${idx}`}
                                                    >
                                                      <td>{idx + 1}</td>
                                                      <td>
                                                        {data?.leave_type_name}
                                                      </td>
                                                      <td>
                                                        <span>{data?.bal}</span>
                                                      </td>
                                                      <td>
                                                        <span>
                                                          {data?.avail}
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {answer ? (
                          <div class="row agent justify-content-end text-right">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="ticketreplytxt bg-white rounded">
                                    {answer
                                      .replace("<p>", "")
                                      .replace("</p>", "")}
                                  </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                  <h6>
                                    <i class="fas fa-check-double text-theme"></i>
                                    {moment(new Date()).format(
                                      "Do MMM YYYY, h:mm:ss A"
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {loading && (
                          <div class="row agent">
                            <div class="col-md-8">
                              <div class="preloader">
                                <div class="loadingBox">
                                  <p>Fetching</p>
                                  <div class="loadingbar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {!formData && (
                      <div class="mb-3 raisereplybox">
                        <button
                          class="btn btn-blanktd btn-sound"
                          onClick={runSpeechRecognition}
                        >
                          <img src="images/sound-icon-chat.png" />
                        </button>
                        <textarea
                          id="text123"
                          value={writetext}
                          class="form-control text-black "
                          placeholder="write a reply..."
                          onChange={handletextchange}
                          onKeyUp={(e) => {
                            if (e.key == "Enter") {
                              sendtext();
                            }
                          }}
                        ></textarea>
                        <button class="btn btn-blank" onClick={sendtext}>
                          <i class="fas fa-paper-plane"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={listenshow}
          onHide={listenclose}
          backdrop="static"
          keyboard={false}
          size="lg"
          className="modaldefaultclose listenpopup"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div class="row h-100 justify-content-center align-items-center">
              <div class="col-md-6">
                <div class="preloader">
                  <div class="loadingBox">
                    <p>Listening</p>
                    <div class="loadingbar">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <img src={voice} alt="" />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ChatGpt;
