import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import DocumentsItems from "./DocumentsItems";
import { ToastContainer, toast } from "react-toastify";
import Attachments from "./Attachments";
import DocumentHeader from "./DocumentHeader";
import * as HeaderService from "../../service/jv_service";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function Addjv() {
  const dispatch = useDispatch();
  const [tabStatus, setTabStatus] = useState("Primary");
  const [validateField, setValidateField] = useState(false);
  const [editJvData, setEditJvData] = useState();
  const { state } = useLocation();
  const date = new Date();
  let dd = date.getDate();
  let mm = date.getMonth() + 1; 
  let year = date.getFullYear();

  const todayDate = () => {
    mm = (mm + "").length < 2 ? "0" + mm : mm;
    dd = (dd + "").length < 2 ? "0" + dd : dd;
    return `${year}-${mm}-${dd}`;
  };
  const [companyCodeValue, setCompanyCodeValue] = useState();
  const [companyCode, setCompanyCode] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [editFlag3, setEditFlag3] = useState(false);
  const [jvData, setJvData] = useState({
    doc_no: "",
    company_code: "",
    document_type: "",
    document_date: todayDate(),
    posting_date: todayDate(),
    document_header: "",
    reference: "",
    status: "DRAFT",
    items: [
      {
        posting_key: "",
        gl_code: "",
        cost_center: 0,
        profit_center: 0,
        business_area: 0,
        internal_order: "",
        amount: "",
        assignment: "",
        plant: 0,
        item_text: "",
        currency: "",
        rate: "",
        tax_code: "",
        business_place: "",
      },
    ],
    remarks: "",
    attachments: [],
  });

  function jvDetail() {
    const arrayGST = [];
    if (editJvData) {
      for (let i = 0; i < editJvData.items.length; i++) {
        arrayGST.push({
          posting_key: editJvData?.items[i].posting_key,
          gl_code: editJvData?.items[i].gl_code.id,
          gl_desc: editJvData?.items[i].gl_code.short_desc,
          cost_center:
            editJvData?.items[i]?.cost_center == null ||
            editJvData.items[i].cost_center === undefined
              ? "0"
              : editJvData.items[i].cost_center.id,
          cost_center_name:
            editJvData?.items[i]?.cost_center == null ||
            editJvData.items[i].cost_center === undefined
              ? ""
              : editJvData.items[i].cost_center.name,
          profit_center:
            editJvData.items[i].profit_center === null ||
            editJvData.items[i].profit_center === undefined
              ? "0"
              : editJvData.items[i].profit_center.id,
          profit_center_name:
            editJvData.items[i].profit_center === null ||
            editJvData.items[i].profit_center === undefined
              ? "0"
              : editJvData.items[i].profit_center.description,
          business_area:
            editJvData.items[i].business_area === null ||
            editJvData.items[i].business_area === undefined
              ? "0"
              : editJvData.items[i].business_area.id,
          business_area_name:
            editJvData.items[i].business_area === null ||
            editJvData.items[i].business_area === undefined
              ? "0"
              : editJvData.items[i].business_area.description,
          internal_order: editJvData.items[i].internal_order,
          amount: editJvData.items[i].amount,
          assignment: editJvData.items[i].assignment,
          plant:
            editJvData.items[i].plant === null ||
            editJvData.items[i].plant === undefined
              ? "0"
              : editJvData.items[i].plant.id,
          plant_name:
            editJvData.items[i].plant === null ||
            editJvData.items[i].plant === undefined
              ? "0"
              : editJvData.items[i].plant.description,
          item_text: editJvData?.items[i].item_text,
          currency: editJvData?.items[i]?.currency?.id,
          currency_name: editJvData?.items[i]?.currency?.code,
          rate: editJvData?.items[i].rate,
          tax_code: editJvData?.items[i].tax_code,
          business_place:
            editJvData.items[i].business_place === null ||
            editJvData.items[i].business_place === undefined
              ? "0"
              : editJvData?.items[i].business_place.id,
          business_place_name:
            editJvData.items[i].business_place === null ||
            editJvData.items[i].business_place === undefined
              ? "0"
              : editJvData.items[i].business_place.description,
        });
      }
      setJvDocDetails(arrayGST);
    }
  }
  const [jvDocDetails, setJvDocDetails] = useState([
    {
      posting_key: "",
      gl_code: 0,
      gl_desc: "",
      cost_center: 0,
      cost_center_name: "",
      profit_center: 0,
      profit_center_name: "",
      business_area: 0,
      business_area_name: "",
      internal_order: "",
      amount: "",
      assignment: "",
      plant: 0,
      plant_name: "",
      item_text: "",
      currency: 0,
      currency_name: "",
      rate: "",
      tax_code: "",
      business_place: "",
      business_place_name: "",
    },
  ]);

  const removeJvDetailRow = (idx) => {
    const newJvDetails = jvDocDetails.filter((item, i) => i !== idx);
    setJvDocDetails(newJvDetails);
  };

  const getViewData = (id) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getViewData(id)
      .then((response) => {
        setEditJvData(response?.data?.dataList);
        dispatch(setDisplayLoader("Hide"));
        setEditFlag3(true);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onAddCallback = (data) => {
    setCompanyCodeValue(data.value);
  };

  useEffect(() => {
    if (state?.id !== undefined) {
      getViewData(state?.id);
    }
  }, [state?.id]);

  useEffect(() => {
    if (editJvData) {
      let datass = editJvData;
      let comCode = {
        value: datass?.company_code?.id,
        label:
          datass?.company_code?.name + "(" + datass?.company_code?.code + ")",
      };
      let docTypeCode = {
        value: datass?.document_type?.id,
        label:
          datass?.document_type?.document_desc +
          "(" +
          datass?.document_type?.document_type +
          ")",
      };
      setDocumentType(docTypeCode);
      setCompanyCode(comCode);
      Object.keys(editJvData).forEach((item) => {
        jvData[item] = editJvData[item];

        if (item === "company_code" || item === "document_type") {
          jvData[item] = editJvData[item]?.id;

          jvData["document_type_desc"] = editJvData[item]?.document_desc;
        }
        if (item === "company_code") {
          jvData["company_code_name"] = editJvData[item]?.name;
        }
      });
      jvData["attachments"] = [];
      jvDetail();
    }
  }, [editJvData]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Add New JV</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">Transactions</Breadcrumb.Item>
                <Breadcrumb.Item href="/user">JV</Breadcrumb.Item>
                <Breadcrumb.Item active>Add New</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div class="addusertabs">
          <Tabs
            activeKey={tabStatus}
            id="adduserinfo"
            onSelect={(e) => setTabStatus(e)}
          >
            <Tab
              eventKey="Primary"
              disabled
              title={
                <React.Fragment>
                  <span>1</span> Document Header
                </React.Fragment>
              }
            >
              <DocumentHeader
                jvData={jvData}
                setJvData={setJvData}
                setTabStatus={setTabStatus}
                validateField={validateField}
                companyCode={companyCode}
                setCompanyCode={setCompanyCode}
                setValidateField={setValidateField}
                onAddCallback={onAddCallback}
                setDocumentType={setDocumentType}
                documentType={documentType}
              />
            </Tab>
            <Tab
              eventKey="Personal"
              disabled
              title={
                <React.Fragment>
                  <span>2</span> Document Line Items
                </React.Fragment>
              }
            >
              <DocumentsItems
                jvDocDetails={jvDocDetails}
                editFlag3={editFlag3}
                removeJvDetailRow={removeJvDetailRow}
                setJvDocDetails={setJvDocDetails}
                setTabStatus={setTabStatus}
                companyCodeValue={companyCodeValue}
              />
            </Tab>
            <Tab
              eventKey="Addressess"
              disabled
              title={
                <React.Fragment>
                  <span>3</span> Attachments
                </React.Fragment>
              }
            >
              <Attachments
                jvData={jvData}
                setJvData={setJvData}
                attachments={editJvData}
                jvDocDetails={jvDocDetails}
                setTabStatus={setTabStatus}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Addjv;
