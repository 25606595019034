import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthorizeUserForm from "./authrize-userForm";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import CustomMenu from "./CustomMenu";
import { ToastContainer } from "react-toastify";
import { getmaterialApprovals } from "../../service/materialService";
function AuthorizeUser() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();
  const [approvalMast, setApprovalMast] = useState([]);
  const [edit, setedit] = useState(false);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [editData, setEditData] = useState({});

  const getmaterialApprover = () => {
    dispatch(setDisplayLoader("Display"));

    getmaterialApprovals()
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(response?.data?.dataList?.result);
        setApprovalMast(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getmaterialApprover();
  }, []);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Add Authorize User</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing"></div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    {finalUrlValue?.E || finalUrlValue?.R ? (
                      <th>Actions</th>
                    ) : (
                      <th>Actions</th>
                    )}
                    <th>Tech Approver </th>
                    <th>Fin Approver</th>
                    <th>Master Approver</th>
                  </tr>
                </thead>
                <tbody>
                  {approvalMast.map((x) => (
                    <tr>
                      <td>
                        <button
                          onClick={(e) => {
                            setEditData(x);
                            setedit(true);
                            addnewopen();
                          }}
                          class="btn btn-blanktd text-primary ml-2 f-12"
                        >
                          <i class="far fa-edit"></i>
                        </button>
                      </td>

                      <td>{x?.tech_approver_name}</td>
                      <td>{x?.fin_approver_name}</td>
                      <td>{x?.master_approver_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              {/* <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {addnewshow && (
        <AuthorizeUserForm
          show={addnewshow}
          edit={edit}
          getList={() => getmaterialApprover()}
          close={addnewclose}
          open={addnewopen}
          editData={editData}
        ></AuthorizeUserForm>
      )}
    </>
  );
}

export default AuthorizeUser;
