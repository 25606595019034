import React,{useEffect,useState} from 'react'
import { useLocation,Navigate } from "react-router-dom"
import { bool } from 'yup';
import ResetPassword from '../include/changePassword';

function Index({ children }) {
    // const [isLogin, setisLogin] = useState(false);
    // useEffect(() => {
    //     setisLogin(localStorage.getItem('token')?true:false);

    // }, [])

    // const isLogin=localStorage.getItem('token')?true:false;
    // const isLogin = true;
    // console.log("sdf");
    const location=useLocation();
 
    return localStorage.getItem('accesstoken') ?  localStorage.getItem('isTemporary')=="false"?children:<ResetPassword></ResetPassword>:(<Navigate to="/" state={{from:location}}></Navigate>)
}

export default Index