import axios from "../utils/axios";
import {
  AUTH_BASE_URL,
  EMPLOYEE_BASE_URL,
  CUSTOMER_BASE_URL,
  CRAWLER_BASE_URL,
} from "../constants/URL";

export const login = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/login`, data);
};
export const getOrgbyId = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations/Orgdata?organisation_sub_name=${id}&paginate=true`
  );
};

export const setOtp = async (otp) => {
  return axios.get(`${CRAWLER_BASE_URL}/chatborresp/setotp/${otp}`);
};
export const ResetOTP = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/verify_details`, data);
};
export const ChangePassword = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/change_password`, data);
};
export const SendOTP = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/auth/verify_otp`, data);
};

// export const getRights = async () => {
//   return axios.get(`${AUTH_BASE_URL}/rtmc/userright`, {});
// };

export const getProfile = async (customertype) => {
  if (customertype === 1) {
    return axios.get(`${CUSTOMER_BASE_URL}/customers/getprofile`);
  }
  return axios.get(`${EMPLOYEE_BASE_URL}/employees/getprofile`);
};

// ResetPassword API

export const resetPassword = async (payload) => {
  //alert(">>>>>>>>>>"+cPassword+"hhh"+nPassword+"");
  return axios.post(`${EMPLOYEE_BASE_URL}/employees/resetpassword`, payload);
};

export const switchSelectedUser = async (payload) => {
  return axios.post(`${AUTH_BASE_URL}/auth/switchUser`, payload);
};
