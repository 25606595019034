import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getCountryList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/country?${queryParm}`);
};

export const saveCountry = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/country`, data);
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/country/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteCountry = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/country/${id}`, data);
};

export const updateCountry = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/country/${id}`, data);
};

export const isCountryExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/country/exists`, isData);
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};
