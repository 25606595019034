import React, { useState, useEffect } from "react";
import * as VisitorService from "./visitor_service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

function Visitor() {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [fromVisitingDate, setFromVisitingDate] = useState("");
  const [toVisitingDate, setToVisitingDate] = useState("");
  const [visitingError, setVisitingError] = useState(false);
  const [contactPerson, setContactPerson] = useState("");
  const [contactPersonError, setContactPersonError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [visitingSiteMasterData, setVisitingSiteMasterData] = useState([]);
  const [visitingSiteValue, setVisitingSiteValue] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [visitingSiteDetailsData, setVisitingSiteDetailsData] = useState([]);
  const [visitingSiteData, setVisitingSiteData] = useState([
    {
      visiting_site_id: 0,
      visiting_site_name:[],
      from_visiting_date: "",
      to_visiting_date: "",
      nearest_airport: "",
      distance_from_mumbai: "",
      distance_from_delhi: "",
      nearest_city: "",
      site_distance_from_city: "",
      site_climatic_condition: "",
      site_operating_hours: "",
      local_city_attraction: "",
    },
  ]);
  useEffect(() => {
    getVisitingSiteMasterData();
  }, []);

  const getVisitingSiteMasterData = (paginate, page_no, page_size, search) => {
    VisitorService.get_visiting_site_master_response(
      handleKey(paginate, page_no, page_size, search)
    ).then((response) => {
      let obj = response?.data?.dataList?.result?.map((each) => {
        return {
          value: each?.id,
          label: each?.site_id,
        };
      });
      setVisitingSiteMasterData(obj);
      // setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
    });
  };

  const handleKey = (paginate, page_no, page_size, search) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const handleSiteChange = (event, index) => {
    let id = event.value;
    let queryParm = "id=" + id;
    VisitorService.get_visiting_site_master_response(queryParm).then(
      (response) => {
        let data = response?.data?.dataList?.result;
        if (data?.length > 0) {
          handleChange(data[0], index, id, event);
        }
      }
    );
  };

  const handleChange = (data, index, id, event) => {
    let onChangeValue = [...visitingSiteData];
    // let { name, value } = event.target;
    onChangeValue[index]["visiting_site_name"] = event;
    onChangeValue[index]["nearest_airport"] = data.nearest_airport;
    onChangeValue[index]["distance_from_mumbai"] = data.distance_from_mumbai;
    onChangeValue[index]["distance_from_delhi"] = data.distance_from_delhi;
    onChangeValue[index]["nearest_city"] = data.nearest_city;
    onChangeValue[index]["site_distance_from_city"] = data.site_distance_from_city;
    onChangeValue[index]["site_operating_hours"] = data.site_operating_hours;
    onChangeValue[index]["local_city_attraction"] = data.local_city_attraction;
    onChangeValue[index]["visiting_site_id"] = id;
    console.log(onChangeValue);
    setVisitingSiteData(onChangeValue);
    // setErrors({ ...errors, [name]: "" });
  };
  const handleDeleteInput = (index) => {
    const newArray = [...visitingSiteData];
    newArray.splice(index, 1);
    setVisitingSiteData(newArray);
  };
  const handleAddInput = () => {
    setVisitingSiteData([
      ...visitingSiteData,
      {
        visiting_site_id: 0,
        from_visiting_date: "",
        to_visiting_date: "",
        nearest_airport: "",
        distance_from_mumbai: "",
        distance_from_delhi: "",
        nearest_city: "",
        site_distance_from_city: "",
        site_climatic_condition: "",
        site_operating_hours: "",
        local_city_attraction: "",
      },
    ]);
  };
  const saveVisitorInfo = () => {
    let obj = {
      company_name: companyName,
      visiting_date: fromVisitingDate,
      contact_person: contactPerson,
      email: emailId,
      visiting_site_data: visitingSiteData,
    };
    // console.log(obj);
    VisitorService.save_visitor_info(obj).then((response) => {
      toast.success("Data saved successfully !!");
      navigate("/visitor-master-management");
    });
  };

  return (
    <div class="row">
      <div class="col-md-12">
        <div class="content-wrapper-inner content-wrapper-inner2">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">Visitor Management</h4>
              </div>
              <div class="col-md-12">
                <div class="border-top mb-3"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group innergroup">
                  <label>
                    Name of the Company<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the company name"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setCompanyError(false);
                    }}
                    value={companyName}
                  />
                  <div className="small text-danger">
                    {companyError && "*Company Name Required"}
                  </div>
                </div>
              </div>

           <div class="col-md-4">
                <div class="form-group innergroup">
                  <label>
                    Contact Person<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter contact person"
                    onChange={(e) => {
                      setContactPerson(e.target.value);
                      setContactPersonError(false);
                    }}
                    value={contactPerson}
                  />
                  <div className="small text-danger">
                    {contactPersonError && "*Description Required"}
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group innergroup">
                  <label>
                    Email Id<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email Id"
                    onChange={(e) => {
                      setEmailId(e.target.value);
                      setEmailError(false);
                    }}
                    value={emailId}
                  />
                  <div className="small text-danger">
                    {emailError && "*Description Required"}
                  </div>
                </div>
              </div>
              <div class="col-md-12">
              <h4 class="inner-page-title mt-4">Add Visiting Sites </h4>
                <div class="border-top mb-3"></div>
              </div>
              {visitingSiteData.map((item, index) => (
                <div className="mx-sm-3 mt-sm-3 mx-2 mt-2">
                  <div class="row viewformaftersubmit">
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>Name of the visiting Site</label>
                        <Select
                          options={visitingSiteMasterData}
                          className="basic-multi-select"
                          classNamePrefix="Select"
                          value={item?.visiting_site_name}
                          onChange={(e) => {
                            
                            handleSiteChange(e, index);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>From Site Visiting Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="from_visiting_date"
                          placeholder="Enter name of the Airport"
                          value={item?.from_visiting_date}
                          onChange={(event) => {
                            let onChangeValue = [...visitingSiteData];
                            onChangeValue[index]["from_visiting_date"] =
                              event.target.value;
                            setVisitingSiteData(onChangeValue);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>To Site Visiting Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="to_visiting_date"
                          placeholder="Enter name of the Airport"
                          value={item?.to_visiting_date}
                          onChange={(event) => {
                            let onChangeValue = [...visitingSiteData];
                            onChangeValue[index]["to_visiting_date"] =
                              event.target.value;
                            setVisitingSiteData(onChangeValue);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>Nearest International / national Airport</label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="nearest_airport"
                          placeholder="Enter name of the Airport"
                          value={item?.nearest_airport}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>Distance from Mumbai</label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="distance_from_mumbai"
                          placeholder="Enter Distance in KM"
                          value={item?.distance_from_mumbai}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>Distance from Delhi</label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="distance_from_delhi"
                          placeholder="Enter Distance in KM"
                          value={item?.distance_from_delhi}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group innergroup">
                        <label>Nearest City for Stay</label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="nearest_city"
                          placeholder="Enter city Name"
                          value={item?.nearest_city}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label className="d-block mb-2">
                          Distance of site from city
                          <span class="text-danger"></span>
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="site_distance_from_city"
                          placeholder="Enter Distance in KM"
                          value={item?.site_distance_from_city}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label className="d-block mb-2">
                          Climatic conditions of the visiting sites
                          <span class="text-danger"></span>
                        </label>
                        <select
                                class="form-control newbgselect"
                                name="site_climatic_condition"
                                value={item?.site_climatic_condition}
                                onChange={(event) => {
                                  let onChangeValue = [...visitingSiteData];
                                  onChangeValue[index]["site_climatic_condition"] = event.target.value;
                                  setVisitingSiteData(onChangeValue);
                                }}
                              >
                                <option value={''}>Select</option>
                                <option value={"Fair"}>Fair</option>
                                <option value={"Cloudy"}>Cloudy</option>
                                <option value={"Rainy"}>Rainy</option>
                              </select>

                        
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label className="d-block mb-2">
                          Mfg. Site Operating hours
                          <span class="text-danger"></span>
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="site_operating_hours"
                          placeholder="Enter Operating hours"
                          value={item?.site_operating_hours}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label className="d-block mb-2">
                          Local City attractions
                          <span class="text-danger"></span>
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          name="local_city_attraction"
                          placeholder="Enter Local City attractions"
                          value={item?.local_city_attraction}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </div>
                    </div>

                    {visitingSiteData.length > 1 && index !== 0 && (
                      <div className="col-md-4">
                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteInput(index);
                        }}
                        className="btn btn-blanktd text-danger mr-2 float-right mt-4"
                      >
                        <i className="far fa-trash-alt text-danger"></i>
                      </button>
                      </div>
                    )}
                  </div>
                 
                  <div class="border-top mb-3"></div>
                  <div className="col-md-12">
                    {index === visitingSiteData.length - 1 && (
                      // <div className="col-md-3">
                      <button
                        onClick={() => handleAddInput()}
                        className="btn btn-primary-inner bpi-main float-right mt-4"
                        type="button"
                      >
                        Add Location <i class="fas fa-user-plus"></i>
                      </button>
                      // </div>
                    )}
                  
                  </div>
                </div>
              ))}
              <div class="col-md-12 mt-3">
                <button
                  class="btn btn-outline-danger mr-3"
                  type="button"
                  onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary-inner bpi-main"
                  type="button"
                  onClick={saveVisitorInfo}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Visitor;
