import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { getUserRmList } from "../../service/jv_service";
import { toast, ToastContainer } from "react-toastify";
import { updateGST } from "../../service/gst";
import { materialApprovals } from "../../service/materialService";
function AuthorizeUserForm(props) {
  let flag = true;
  const { show, close, edit, getList, editData } = props;
  const [userlist, setuserlist] = useState([]);
  const navigate = useNavigate();
  const [errors,setErrors]=useState({
    fin_approver:false,
    tech_approver:false,
    master_approver:false
  })
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let user = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label:x?.first_name+" "+x?.last_name+"("+x?.username+")" };
          user.push(data);
        });
       setuserlist(user)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  
  
  const [approver, setApprover] = useState({
    fin_approver:null,
    tech_approver:null,
    master_approver:null
  });

  const handleChange = (event, name) => {
    // fin_approver:null,
    // tech_approver:null,
    // master_approver:null
    console.log(name, event);
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "fin_approver":
        setApprover((prev) => ({
          ...prev,
          fin_approver: event,
        }));
        setErrors((prev) => ({
          ...prev,
          fin_approver: false,
        }));
        break;
      case "tech_approver":
        setApprover((prev) => ({
          ...prev,
          tech_approver: event,
        }));
        setErrors((prev) => ({
          ...prev,
          tech_approver: false,
        }));
        break;
      case "master_approver":
        setApprover((prev) => ({
          ...prev,
          master_approver: event,
        }));
        setErrors((prev) => ({
          ...prev,
          master_approver: false,
        }));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getUserList()
  }, []);

  useEffect(() => {
    if (edit == true) {
  
      Object.keys(editData).forEach((item) => {
        approver[item] = editData[item];
      });
      setApprover((prev) => ({
        ...prev,
        tech_approver: {
          value: editData?.tech_approver,
          label: editData?.tech_approver_name,
        },
        fin_approver: {
          value: editData?.fin_approver,
          label: editData?.fin_approver_name,
        },
        master_approver: {
          value: editData?.master_approver,
          label: editData?.master_approver_name,
        },
      }));
    } else if (edit == false) {
    }
  }, [editData]);
  const submit = () => {
          materialApprovals(approver)
            .then((response) => {
              flag = true;
              close();
              getList();
              toast.success("Aprover updated successfully");
            })
            .catch((error) => {
              flag = true;
              toast.error(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
            });
       
    //   }
    // } else {
    //   setErrors((prev) => ({
    //     ...prev,
    //     company_id: gst?.company_id == null ? true : false,
    //     state_id: gst?.state_id == null ? true : false,
    //     gst_no: gst?.gst_no == null ? true : false,
    //     billing_address: gst?.billing_address == null ? true : false,
    //   }));
    // }
  };

 
  return (
    <>
      <ToastContainer></ToastContainer>

      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Authorize User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                    <div class="col-md-6">
                      <div class="form-group innergroup">
                        <label>
                          Tech approver<span class="text-danger">*</span>
                        </label>
                        <Select
                          options={userlist}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={approver?.tech_approver}
                          name="tech_approver"
                          onChange={(newValue, action) => {
                            handleChange(newValue, action.name);
                          }}
                        //   onChange={(option) => setFieldValue('tech_approver_id', option.value)}
                        />
                        <div className="small text-danger">
                          {/* {touched.tech_approver_id && errors.tech_approver_id} */}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group innergroup">
                        <label>
                          Fin approver<span class="text-danger">*</span>
                        </label>
                        <Select
                          options={userlist}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={approver?.fin_approver}
                          name="fin_approver"
                          onChange={(newValue, action) => {
                            handleChange(newValue, action.name);
                          }}

                        //   value={options ? options.find(option => option.value === values.fin_approver_id) : ''}
                        //   onChange={(option) => setFieldValue('fin_approver_id', option.value)}
                        />
                        <div className="small text-danger">
                          {/* {touched.fin_approver_id && errors.fin_approver_id} */}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group innergroup">
                        <label>
                          Master approver<span class="text-danger">*</span>
                        </label>
                        <Select
                          options={userlist}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={approver?.master_approver}
                          name="master_approver"
                          onChange={(newValue, action) => {
                            handleChange(newValue, action.name);
                          }}

                        //   value={options ? options.find(option => option.value === values.master_approver_id) : ''}
                        //   onChange={(option) => setFieldValue('master_approver_id', option.value)}
                        />
                        <div className="small text-danger">
                          {/* {touched.master_approver_id && errors.master_approver_id} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button
            disabled={!flag}
            class="btn btn-primary-inner bpi-main"
            onClick={submit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AuthorizeUserForm;
