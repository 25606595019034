import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const getSkillGroup = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/skill-group-master?${queryParm}`);
};
export const saveSkillGroup = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-group-master`, data);
};

export const updateSkillGroup = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-group-master/${id}`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/skill-group-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const deleteSkillGroup = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-group-master/${id}`);
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-group-master/exists`, data);
};

export const getSkillSubGroup = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/skill-sub-group-master?${queryParm}`);
};

export const getSkillSubGroupByParent = async (parent_id) => {
  return axios.get(`${AUTH_BASE_URL}/skill-sub-group-master/parent_id/${parent_id}`);
};

export const createSkillSubGroup = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-sub-group-master`, data);
};
export const isSubGroupExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-sub-group-master/exists`, data);
};

export const updateSkillSubGroup = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-sub-group-master/${id}`, data);
};

export const deleteSkillSubGroup = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-sub-group-master/${id}`);
};