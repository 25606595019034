import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getTerritory = async () => {
  return axios.get(`${AUTH_BASE_URL}/territory/drop-down`);
};
export const getExpensePlan = async () => {
  return axios.get(`${AUTH_BASE_URL}/expense-plan`);
};

export const getLastExpensePlan = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/expense-plan/last-expense-plan${query}`);
};

export const fetchGlData = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/expense-plan/gl-data`);
};

export const postExpensePlan = async (body) => {
  return axios.post(`${AUTH_BASE_URL}/expense-plan`, body);
};

export const putExpensePlan = async (body) => {
  return axios.put(`${AUTH_BASE_URL}/expense-plan`, body);
};

export const getExpensePlanApprovalLogs = async (query) => {
  return axios.get(
    `${AUTH_BASE_URL}/expense-plan/approver-expense-plan-logs${query}`
  );
};

export const postExpensePlanAction = async (body) => {
  return axios.post(`${AUTH_BASE_URL}/expense-plan/action-expense-plan`, body);
};

export const getExpensePlanLowerGrid = async (query) => {
  return axios.get(
    `${AUTH_BASE_URL}/expense-plan/get-lower-grid?territory_code=${query}`
  );
};
