import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getMajorProduct = async () => {
    return axios.get(`${AUTH_BASE_URL}/major-product`);
};

export const getCategoryDropdown = async ()=>{
    return axios.get(`${AUTH_BASE_URL}/product-category/drop-down`);
}

export const getLastMajorProduct = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/major-product/last-major-product${query}`)
}

export const getMoleculesByCatId = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/molecules/drop-down${query}`)
}


export const postMajorProduct = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/major-product`,body);
}

export const putMajorProduct = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/major-product`,body);
}

export const getMajorProductsApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/major-product/approver-major-product-logs${query}`);
}

export const postMajorProductAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/major-product/action-major-product`,body);
}