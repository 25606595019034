import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import * as OrganisationService from "../../service/organisation";

function EmpListReport() {
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [travelBills, setTravelBills] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    company:{ value: ""},
    trip_from: "",
    trip_to: "",
    from_date: "",
    to_date: "",
    search_by: "T",
    request_type: "",
    expense_status: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getTravelBills(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );
  };

  const calcMapData = (map_data, dat_type) => {
    let calculated = 0;
    switch (dat_type) {
      case "Self_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Self") {
            calculated += sub_data?.taxable_amount;
          }
        });

        break;
      case "Company_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Company") {
            calculated += sub_data?.taxable_amount;
          }
        });
        break;
      case "hotel":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.tax_bill_amount;
        });
        break;
      case "conveyance":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.amount;
        });
        break;
      default:
        console.log("invalid");
    }
    return calculated;
  };

  const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileView = (data) => {
    setFileObj(data);
    handleShow();
  };


  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
      

      


    });
  };

  useEffect(() => {
    getUsersList();
    getAllOrganisation()
    getTravelBills(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );
  }, [pageSizeNo]);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by,
    request_type,
    expense_status
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search_by !== "" && search_by !== undefined && search_by !== null) {
      queryParm = queryParm + "&search_by=" + search_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
      queryParm = queryParm + "&trip_from=" + trip_from;
    }
    if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
      queryParm = queryParm + "&trip_to=" + trip_to;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (
      expense_status !== "" &&
      expense_status !== undefined &&
      expense_status !== null
    ) {
      queryParm = queryParm + "&status=" + expense_status;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getTravelBills(
        e.target.value,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        filterdata?.user_id?.value,
        filterdata?.trip_from,
        filterdata?.trip_to,
        filterdata?.from_date,
        filterdata?.to_date,
        filterdata?.search_by,
        filterdata?.request_type,
        filterdata?.expense_status
      );

      setSearchStr(e.target.value);
    }
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    getTravelBills(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.trip_from,
      filterdata?.trip_to,
      filterdata?.from_date,
      filterdata?.to_date,
      filterdata?.search_by,
      filterdata?.request_type,
      filterdata?.expense_status
    );

    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   filterdata?.user_id,
    //   filterdata?.company,
    //   filterdata?.trip_number,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   true

    //   )

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //    "","","","","",false
    // );
    getTravelBills(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    setfilterData({
      user_id: { value: undefined },
      company: { value: "" },
      from_date: "",
      to_date: "",
      trip_from: "",
      trip_to: "",
      search_by: "T",
      request_type: "",
      expense_status: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getTravelBills = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by = "",
    request_type = "",
    expense_status = ""
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getEmpListReport(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date,
        search_by,
        request_type,
        expense_status
      )+`&company=`+filterdata.company.value
    ).then((response) => {
      setTravelBills(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  const getTravelBillsExport = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    search_by
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getEmpListReportExport(
      "is_export=true" +
        `&search=` +
        search +
        `&sort_by=` +
        sort_by +
        (user_id ? `&user_id=` + user_id : ``) +
        `&trip_from=` +
        trip_from +
        `&trip_to=` +
        trip_to +
        `&from_date=` +
        from_date +
        `&to_date=` +
        to_date +
        `&search_by=` +
        search_by
        +`&company=`+filterdata.company.value
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Employee List Report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      dispatch(setDisplayLoader("Hide"));

      // setTravelBills(response?.data?.dataList?.result)
      //   setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
    });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li class="active"><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Employee List Report</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong> Name, Employee ID</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={surveyList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getTravelBillsExport(
                                  searchStr,
                                  "",
                                  "",
                                  sortData.sort_by,
                                  false,
                                  "",
                                  filterdata?.user_id?.value,
                                  filterdata?.trip_from,
                                  filterdata?.trip_to,
                                  filterdata?.from_date,
                                  filterdata?.to_date,
                                  filterdata?.search_by
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Employee Code</th> 	
                      <th>Employee Vendor Code </th>
                      <th>	Employee Name </th>
                      <th>	Employee Grade </th>
                      <th>	Head Quarter </th>
                      <th>	Zone </th>
                      <th>	Email </th>
                      <th>	Mobile No. </th>
                      <th>	PA 	</th>
                      <th>PSA </th>
                      <th>	Pan No. </th>
                      <th>	Company Code </th>
                      <th>	Reporting Person </th>
                      <th>	Reporting Person Name </th>
                      <th>	Functional Head </th>
                      <th>	Functional Head Name </th>
                      <th>	BHR </th>
                      <th>	Cost Center </th>
                      <th>	Profit Center </th>
                      <th>	Business Area </th>
                      <th>	Status </th>
                      <th>	DOJ </th>
                      <th>	DOR 	</th>
                      <th>Job Description</th>
                    </tr>
                  </thead>

                  <tbody>
                    {travelBills?.length > 0 ? (
                      travelBills?.map((data, idx) => {
                        return (
                          <tr
                          
                            // style={{
                            //   backgroundColor: data["First Query Raised"]
                            //     ? "yellow"
                            //     :data?.ssc_user_send_back_to_requestor_remarks?"#FFBF00"
                            //     : data?.expense_exception
                            //     ? "#FFA895"
                            //     : "",
                            // }}
                          >
                            <td>{pageSizeNo * (currentPage - 1) + idx + 1}</td>
                           <td>
                            {data?.emp_code}
                           </td>
                           <td>
                            {data?.emp_vendor_code}
                           </td>
                           <td>{data?.user_name  }

                           </td>
                           <td>{data?.emp_sub_group}

                           </td>
                           <td>{data?.location}

                           </td>
                           <td>{data?.sales_dist}

                           </td>
                           <td>{data?.bemail}

                           </td>
                           <td>{data?.bmobile}

                           </td>
                           <td>{data?.pa}

                           </td>
                           <td>{data?.psa}

                           </td>
                           <td>{data?.pan_no}

                           </td>
                           <td>{data?.org_code}

                           </td>
                           <td>{data?.reporting_user_code}

                           </td>
                           <td>{data?.reporting_user_name}

                           </td>
                           <td>{data?.hod_code}

                           </td>
                           <td>{data?.hod_name}</td>
                           <td>{data?.bhr_username} {data?.bhr_code}</td>
                           <td>{data?.cost_center_code}</td>
                           <td>{data?.profit_center}</td>
                           <td>{data?.business_area}</td>
                           <td>{data?.status}</td>
                           <td>{data?.date_of_joining?moment.parseZone(data?.date_of_joining).format("DD-MM-YYYY"):""}</td>
                           <td>{data?.date_of_leaving?moment.parseZone(data?.date_of_leaving).format("DD-MM-YYYY"):""}</td>
                           <td>{data.job_desc_code} {data?.job_desc_name}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>No Records Found...</td>
                      </tr>
                    )}

                  
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="20">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                  key={pageSizeNo}
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    disableInitialCallback={true}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>


            <Select
              options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  company: e,
                })
              }
              value={filterdata.company}
            />
            
            {/* <select class="form-control newbgselect"
              onChange={(e)=>setfilterData({
              ...filterdata,
              company:e.currentTarget.value
            })}
            >
              <option>Select</option>
              <option>Jivagro Limited</option>
              <option>PI Consultant</option>
              <option>PI Industries Ltd.</option>
            </select> */}
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata?.user_id}
            />
          </div>


          <div class="form-group innergroup">
            <label>
              Status <span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  expense_status: e.target.value,
                })
              }
              value={filterdata?.expense_status}
            >
              {/* <option value="">Select</option> */}
              <option value="" selected>
                All
              </option>
              <option value="true" selected={filterdata?.expense_status == "true"}>
                Active
              </option>
              <option value="false" selected={filterdata?.expense_status == "false"}>
                Inactive
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmpListReport;
