import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import * as eventService from "../../service/eventService";
import "../../alert/CustomUi.css";
import * as moment from "moment";
import AddEvent from "./AddEvent";
import ViewEvent from "./ViewEvent";
import SearchLoader from "../../common/SearchLoader";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

const eventNameList = [
  { label: "Create User", value: 1 },
  { label: "PO Request", value: 2 },
  { label: "Update Password", value: 3 },
  { label: "Block User", value: 4 },
];

function Event() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [loaderActive, isLoaderActive] = useState(false);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    organisation_id: "",
    status: "",
  });
  const [eventList, setEventList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [event, setEvent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [organizData, setOrganizData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [valueExportData, setValueExportData] = useState({
    id: "",
    status: "",
  });
  const [moduleDataFilter, setModuleDataFilter] = useState({});
  const getEvent = (
    search,
    event_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    eventService
      .getEvents(
        handleKey(
          search,
          event_id,
          status,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setEventList(response?.data?.dataList?.result);
        setOrganizData(response?.data?.dataList?.paginated_data);
        isLoaderActive(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    event_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      status !== "" &&
      status !== undefined &&
      status !== null &&
      status !== []
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (event_id !== "" && event_id !== undefined && event_id !== null) {
      queryParm = queryParm + "&event_id=" + event_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    event_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    eventService
      .getExportValue(
        handleKey(
          search,
          event_id,
          status,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "event.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      isLoaderActive(true);
      getEvent(e.target.value.toLowerCase(), "", "", 1, pageSizeNo, "id", true);
      setSearchStr(e.target.value);
    }
  };

  const addNewEvent = () => {
    setEditMode(false);
    setEvent(null);
    setShowForm(true);
  };

  const onClose = () => {
    setEvent(null);
    setShowForm(false);
  };

  const editEvent = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setEvent(eData);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getEvent(searchStr, "", "", currentPage, pageSizeNo, "-id", true);
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const getNextPrevPage = (action) => {
    let activePage = currentPage;
    if (action === 1) {
      if (activePage <= organizData.totalPages - 1) {
        activePage = activePage + 1;
        setCurrentPage(activePage);
        getEvent(searchStr, "", "", activePage, pageSizeNo, "id", true);
      }
    } else {
      if (activePage > 1) {
        activePage = activePage - 1;
        getEvent(searchStr, "", "", activePage, pageSizeNo, "id", true);
        setCurrentPage(activePage);
      }
    }
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getEvent(searchStr, "", "", currentPage, pageSizeNo, sdata.sort_by, true);
  };

  useEffect(() => {
    getEvent(searchStr, "", "", 1, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  const getAllEvent = (paginate) => {
    eventService
      .getEvents(paginate)
      .then((response) => {
        setEventList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(moduleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setModuleDataFilter(ClearData);
  };

  const filterShow = () => {
    filteropen();
    getAllEvent(false);
  };

  const renderEventNameOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`event${index}`}
        value={`${item?.value}`}
        label={`${item?.label}`}
      />
    ));
  };
  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setValueExportData({
      ...valueExportData,
      id: moduleDataFilter.event_id,
      status: moduleDataFilter.status,
    });
    const event = moduleDataFilter.event_id;
    const stats = moduleDataFilter.status;
    getEvent(searchStr, event, stats, 1, pageSizeNo, "id", true);
    filterClose();
  };

  return (
    <>
      <div className="content-wrapper-inner" onClick={() => setSearchStr("")}>
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Event</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Event</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-10">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  {loaderActive ? <SearchLoader /> : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#">
                            <i className="fas fa-print"></i>Print
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-file-alt"></i>CSV
                          </Dropdown.Item> */}
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              valueExportData.id,
                              valueExportData.status,
                              1,
                              pageSizeNo,
                              "id",
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">
                            <i className="far fa-file-pdf"></i>Pdf
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-copy"></i>Copy
                          </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}

                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addNewEvent();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th
                  onClick={(e) => {
                    handlerData("event_name");
                  }}
                  className={
                    sortData["event_name"] === undefined
                      ? "unsortcls"
                      : sortData["event_name"]
                  }
                >
                  Event Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("view_name");
                  }}
                  className={
                    sortData["view_name"] === undefined
                      ? "unsortcls"
                      : sortData["view_name"]
                  }
                >
                  View Name
                </th>
                <th>Template</th>
                <th>Subject</th>
                <th>Body</th>
                <th>Created By</th>
                <th>Created At</th>
                {finalUrlValue?.V || finalUrlValue?.E ? <th>Actions</th> : ""}
              </tr>
            </thead>
            <tbody>
              {eventList.length
                ? eventList?.map((data, index) => (
                    <>
                      <tr key={`org${index}`}>
                        {/* <td>
                      <a href={data?.logo} target="_blank" rel="noreferrer">
                        <img src={data?.logo} alt="" height={50} />
                      </a>
                    </td> */}
                        <td>{data?.event_name_label}</td>
                        <td className="text-theme fw-bold">
                          {data?.view_name_label}
                        </td>

                        <td>{data?.template_id}</td>
                        <td>{data?.subject}</td>
                        <td>
                          {data?.body.replace("<p>", "").replace("</p>", "")}
                        </td>
                        {/* <td>{data?.updated_at}</td> */}
                        {/* <td>
                      {" "}
                      {moment(data?.updated_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </td> */}
                        {/* <td>
                      {data?.is_active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          InActive
                        </span>
                      )}
                    </td> */}
                        <td>{data?.created_by}</td>
                        <td>
                          {moment
                            .utc(data?.updated_at)
                            .format("Do MMM YYYY, h:mm:ss A")}
                        </td>
                        {finalUrlValue?.V || finalUrlValue?.E ? (
                          <td>
                            {finalUrlValue?.V ? (
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary"
                                onClick={() => {
                                  onView(data);
                                }}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            ) : (
                              ""
                            )}

                            {finalUrlValue?.E ? (
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary ml-2"
                                onClick={() => {
                                  editEvent(data);
                                }}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                            ) : (
                              ""
                            )}
                            {/* <button
                        className="btn btn-blanktd text-danger ml-2"
                        type="button"
                      >
                        <i
                          className="far fa-trash-alt text-danger"
                          onClick={() => {
                            submit(data?.id, data?.name);
                          }}
                        ></i>
                      </button> */}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    </>
                  ))
                : "No Record Found"}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <Pagination>
              <Pagination.Item onClick={() => getNextPrevPage(0)}>
                <i className="fa fa-angle-left"></i>
              </Pagination.Item>
              <Pagination.Item>
                <span>
                  {currentPage}/ {organizData?.totalPages}
                </span>
              </Pagination.Item>
              <Pagination.Item
                className="active"
                onClick={() => getNextPrevPage(1)}
              >
                <i className="fa fa-angle-right"></i>
              </Pagination.Item>
            </Pagination>
          </div>
        </div>
      </div>

      {/* <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => ( */}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Event Name <span className="text-danger"></span>
            </label>
            {/* <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}

            <select
              className="form-control newbgselect"
              name="organisation_id"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  event_id: e.target.value,
                });
              }}
              value={moduleDataFilter?.event_id}
            >
              <option value="" label="Select Event" />
              {renderEventNameOptions(eventNameList)}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              clearFilter();
            }}
          >
            <i className="fa fa-undo"></i>Clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddEvent
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editEvent={event}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
      {viewForm ? (
        <>
          <ViewEvent
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Event;
