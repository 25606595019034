import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sort from "../../assets/images/sort.png";
import { Formik } from "formik";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";
import { getMyidea, deleteComment, saveComment, getImpHistory, updateMyidea, getRolePermission } from "../../service/idea";
import ReactPaginate from "react-paginate";
import { getUserDetails } from "../../service/header";
import * as moment from "moment";
import { selectUserData, selectIdeaRole, setIdeaRolePermission } from "../redux/piDataStore";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import idea_img from '../../assets/images/Businessman_sitting_thinking.png'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from 'react-bootstrap/Tooltip';
import {Tooltip} from "react-tippy";
import avtar from "../../assets/images/avtar.png";

function Myidea() {
  const [myideaDataFilter, setMyideaDataFilter] = useState({
    from_date: "",
    to_date: "",
    subject: [],
    status: "",
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const ideaRole = useSelector(selectIdeaRole);
  const [myideas, setMyidea] = useState([]);
  const [myideasLead, setMyideaLead] = useState([]);
  const [totalcount, setTotalcount] = useState(0);
  const [taggedUser, setTaggedUser] = useState([]);
  const [comment, setcomment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const addnewclose = () => addnewsetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [impHistory, setImpHistory] = useState([]);
  const [bspocData, setBspocData] = useState([]);
  const [rmRemarks, setRmRemarks] = useState('');
  const [leadRemarks, setLeadRemarks] = useState('');
  const [impRemarks, setImpRemarks] = useState('');


  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [video, setLinkUrl] = useState("");
  const [viewnewshow, viewnewsetShow] = useState(false);
  const viewnewopen = () => viewnewsetShow(true);
  const [currentComment, setcurrentComment] = useState("");
  const viewnewclose = () => viewnewsetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [useData, setUserData] = useState({});
  const [reportingManager, setReportingManager] = useState("");
  const [ideaLead, setIdeaLead] = useState("");
  const [implementor, setImplementor] = useState("")
  const [outstandshow, outstandsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [creator, setCreator] = useState("");
  const outstandclose = () => outstandsetShow(false);

  const handleKey = (
    search,
    isBspoc,
    myidea,
    from_date,
    to_date,
    status,
    currentpage
  ) => {
    let queryParm =
      "page_no=" +
      currentpage +
      "&page_size=" +
      pageSizeNo +
      "&sort_by=" +
      "-id" +
      "&paginate=" +
      "true" +
      "&my_ideas=" +
      myidea +
      "&is_bspoc=" +
      isBspoc;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    return queryParm;
  };

  const getIdeas = (
    search,
    isBspoc,
    myidea,
    from_date,
    to_date,
    status,
    currentpage
  ) => {
    getMyidea(
      handleKey(
        search,
        isBspoc,
        myidea,
        from_date,
        to_date,
        status,
        currentpage
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setMyidea(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setTotalcount(response?.data?.dataList?.paginated_data?.totalRecords)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getIdeaLead = (
    search,
    isBspoc,
    myidea,
    from_date,
    to_date,
    status,
    currentpage
  ) => {
    getMyidea(
      handleKey(
        search,
        isBspoc,
        myidea,
        from_date,
        to_date,
        status,
        currentpage
      )
    )
      .then((response) => {
        setMyideaLead(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    if (userData?.is_bspoc !== undefined) {
      dispatch(setDisplayLoader("Display"));
      getIdeas("", userData.is_bspoc, true, "", "", "", currentPage);
      // getIdeaLead("", userData.is_bspoc, true, "", "", "", currentPage);
    }
  }, [userData]);

  const setLink = (link) => {
    setLinkUrl(link);
  };
  const showMore = (id3) => {
    if (myideas[id3].showMore == false) {
      myideas[id3].showMore = true;
    } else {
      myideas[id3].showMore = false;
    }
    setMyidea([...myideas]);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getIdeas(e.target.value, userData.is_bspoc, true, "", "", "", currentPage);

      setSearchStr(e.target.value);
    }
  };
  const redirectToUpdate = (data) => {
    navigate("/shareidea", {
      state: { data, is_forwarded: false },
    });
  };
  const mainDataFilter = (values) => {
    setFilterApplied(true);
    getIdeas(
      "",
      userData.is_bspoc,
      true,
      values.from_date,
      values.to_date,
      values.status,
      currentPage
    );
    filterclose();
  };
  const cancelFilter = () => {
    setFilterApplied(false);
    getIdeas("", userData.is_bspoc, true, "", "", "", currentPage);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getIdeas("", userData.is_bspoc, true, "", "", "", activePage);
  };

  const saveComments = (idea_id, index) => {
    dispatch(setDisplayLoader("Display"));
    if (comment !== "" && comment !== null && comment !== undefined) {
      let data = { idea_id: idea_id, comment: comment, taggedUser: taggedUser };
      saveComment(data)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          setcomment("");
          // getIdeas("", false, false, "", "", "", currentPage, true);
          getIdeas("", userData.is_bspoc, true, "", "", "", currentPage);
          toast.success("Commented Successfully.");
          document.getElementById(idea_id).value = "";
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      dispatch(setDisplayLoader("Hide"));
      setCommentError(true);
    }
  };

  const clearComment = (id) => {
    document.getElementById(id).value = "";
  };

  const showComment = (id3) => {
    setcurrentComment(id3);
    if (myideas[id3].showComments == false) {
      myideas[id3].showComments = true;
    } else {
      myideas[id3].showComments = false;
    }
    setMyidea([...myideas]);
  };

  const submitDelete = (id) => {
    confirmAlert({
      title: "Delete Comment",
      message: `Are you sure to delete your comment?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteComments(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteComments = (commentId) => {
    let data = { id: commentId };
    deleteComment(data)
      .then((response) => {
        getIdeas("", userData.is_bspoc, true, "", "", "", currentPage, true);
        toast.success("Comment Deleted Successfully");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const outstanding = (data) => {
    getImplementationHistory(data)
    let creator = data?.created_by_first_name + data?.created_by_last_name + "(" + data?.created_by_code + ")"

    let rm = data?.created_by_reporting_fname + data?.created_by_reporting_lname + "(" + data?.reporting_name + ")"
    let implementor = ""
    let idea_lead = ""
    if (data?.idea_head[0]?.username === undefined) {
      idea_lead = "Not Assign"
    }
    else {
      idea_lead = data?.idea_head[0]?.first_name + data?.idea_head[0]?.last_name + "(" + data?.idea_head[0]?.username + ")"
    }
    if (data?.toImplement === false) {
      implementor = "Not Assign"
    }
    else {
      implementor = ""
    }
    setImplementor(implementor)
    setCreator(creator)
    setReportingManager(rm)
    setIdeaLead(idea_lead)
    outstandsetShow(true);
    setRmRemarks(data?.rm_remarks);
    setLeadRemarks(data?.idea_lead_remarks);
    setImpRemarks(data?.idea_implementer_remarks);
  }

  const addnewopen = (data) => {
    getImplementationHistory(data)
    addnewsetShow(true);
  }

  const getImplementationHistory = (obj) => {
    getImpHistory(handleKey2(obj.id)).then((response) => {
      setImpHistory(response?.data?.dataList?.result)
      setBspocData(response?.data?.dataList?.bspoc_data)
    })
  }
  const handleKey2 = (
    id,
  ) => {
    let queryParm =
      "idea_id=" +
      id
    return queryParm;
  };

  const withdrawIdea = (idea_data) => {
    let data = {
      id: idea_data?.id,
      idea_desc: "",
      idea_area: "",
      idea_scope: "",
      department_id: [],
      attachments: [],
      idea_head_id: [],
      onlyStatus: true,
      status: "WD",
      isBspoc: userData.is_bspoc,
      // remark: underImpForm.remark
    };
    updateMyidea(data)
      .then((response) => {
        getIdeas(
          "", userData.is_bspoc, true, "", "", "", currentPage
        );
        toast.success("Idea Withdrawl")
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }

  useEffect(() => {
    getIdeaRolePermission();
  }, []);

  const getIdeaRolePermission = () => {
    getRolePermission()
      .then((response) => {
        dispatch(setIdeaRolePermission(response?.data?.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/shareidea">Share Your Ideas</Link>
            </li>
            <li class="active">
              <Link to="/myidea">My Ideas</Link>
            </li>
            {userData?.have_team && (
              <li>
                <Link to="/myTeamIdea">My Team</Link>
              </li>
            )}
            {ideaRole?.is_idea_lead === true && (
              <li>
                <Link to="/idealead">Idea Leads</Link>
              </li>
            )}
            {userData?.is_bspoc === true && (
              <li>
                <Link to="/idealead" state={{ component: "BSPOC" }}>
                  BSPOC
                </Link>
              </li>
            )}
            {userData?.is_idea_implementer === true && (
              <li>
                <Link to="/idea-implementation" state={{ component: "implement" }}>Under Implementation</Link>
              </li>
            )}
          </ul>

          <div class="ideaboxleft totalidea">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{totalcount}</h4>
              <div>
                <img src="images/ideaicon.png" />
              </div>
            </div>
            <p>Ideas Created by you</p>
          </div>

          {/* <div class="ideaboxleft totalidea">
            <p class="mt-0 mb-3">Tags</p>
            <div class="mb-1">
              <Link to="/" class="text-theme font-italic">
                #Design
              </Link>{" "}
            </div>
            <div class="mb-1">
              <Link to="/" class="text-theme font-italic">
                #Projectmanagement
              </Link>{" "}
            </div>
            <div class="mb-1">
              <Link to="/" class="text-theme font-italic">
                #website
              </Link>{" "}
            </div>
            <div class="mb-1">
              <Link to="/" class="text-theme font-italic">
                #business
              </Link>{" "}
            </div>
          </div> */}
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">My Ideas</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Me@PI</Breadcrumb.Item>
                    <Breadcrumb.Item active>My Ideas</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="overlay-example" className="bs-tooltip-end tooltip">
                        Search on{" "}
                        <strong> ID, Name, Employee Code</strong>.
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        onChange={(e) => handleSearch(e)}
                        type="text"
                        class="form-control"
                        placeholder="Search On ID..."
                      />
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  {myideas?.length > 0 ?
                    myideas?.map((x, index) => (
                      <>
                        <div class="ideabox">
                          <div class="row">
                            <div class="col-md-1">
                              <div class="team-profile">
                                <img
                                  src={
                                    x?.profile_image != ""
                                      ? x?.profile_image
                                      : avtar
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div class="col-md-8">
                              <h4>
                                {x?.created_by_first_name +
                                  " " +
                                  x?.created_by_last_name}{" "}
                                <i class="fas fa-circle"></i>{" "}
                                <span>{x?.created_by_code}</span>{" "}
                                <i class="fas fa-circle"></i>{" "}
                                {moment.utc(x?.created_at).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}
                                {(x?.is_active === true && x?.is_draft == false) && (
                                  x?.status !== "I" && x?.status !== "WD" && x?.is_active === true ?
                                    <div
                                      className="ml-2 mt-2 mt-lg-0 cursor-pointer"
                                      onClick={() => {
                                        showComment(index);
                                      }}
                                    >
                                      <i class="far fa-comment-dots ideacomments"></i>
                                      {x?.comments.length === 0
                                        ? "No Comments"
                                        : `${x?.comments.length} Comments`}
                                    </div>
                                    : x?.status !== "I" && (x?.is_active === true && x?.is_draft === true) ?
                                      <div
                                        className="ml-2 mt-2 mt-lg-0 cursor-pointer"

                                      >
                                        <i class="far fa-comment-dots ideacomments"></i>
                                        {x?.comments.length === 0
                                          ? "No Comments"
                                          : `${x?.comments.length} Comments`}
                                      </div>
                                      :
                                      <div
                                        className="ml-2 mt-2 mt-lg-0 cursor-pointer"

                                      >
                                        <i class="far fa-comment-dots ideacomments"></i>
                                        {x?.comments.length === 0
                                          ? "No Comments"
                                          : `${x?.comments.length} Comments`}
                                      </div>

                                )}
                              </h4>
                              <h5>Id-{x?.id}, {x?.idea_area_name}</h5>
                            </div>
                            <div class="col-md-3 text-sm-right inline-spacing justify-content-end align-items-start">
                              <>
                                {x?.is_draft == true ? (
                                  <h6>Draft</h6>
                                ) : x?.status === "N" &&
                                  x?.bspoc_status === "N" ? (
                                  <h6>Submitted</h6>
                                ) : x?.status == "R" ||
                                  x?.bspoc_status === "R" ? (
                                  <h6>Rejected</h6>
                                ) : x?.status == "A" ||
                                  x?.bspoc_status === "A" ? (
                                  <h6>Approved</h6>
                                ) : x?.status == "I" ||
                                  x?.bspoc_status === "I" ? (
                                  <h6>Implemented</h6>
                                ) : x?.status == "cancel" ||
                                  x?.bspoc_status === "cancel" ? (
                                  <h6>Cancelled</h6>
                                ) : x?.status == "WD" ||
                                  x?.bspoc_status === "WD" ? (
                                  <h6>Withdraw</h6>
                                ) : x?.status == 'U' ? "Under Implementation" :
                                null}

                                {x?.is_draft === true && (
                                  <button
                                    onClick={() => redirectToUpdate(x)}
                                    class="btn btn-blanktd editidea"
                                  >
                                    <i class="far fa-edit"></i>
                                  </button>
                                )}

                                <div class="tableaction mt-2">
                                  <Dropdown show>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img
                                        src="images/viewmore.png"
                                        alt=""
                                        class="ml-2"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#" onClick={(e) => { outstanding(x) }}><i class="far fa-user-circle"></i>Idea Hierarchy</Dropdown.Item>
                                      {x?.status === "N" && (
                                        <Dropdown.Item href="#" onClick={(e) => { withdrawIdea(x) }}><i class="far fa-user-circle"></i>Withdraw</Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              {x?.idea_desc.length > 300 ?
                                <h6>
                                  {!x?.showMore && x?.idea_desc.slice(0, 300)}...
                                </h6>
                                :
                                <h6>
                                  {!x?.showMore && x?.idea_desc}
                                </h6>
                              }
                              {x?.attachments.length != 0 && !x?.showMore && x?.idea_desc.length > 300 && (
                                <button
                                  onClick={() => showMore(index)}
                                  class="btn btn-blanktd"
                                >
                                  Read more{" "}
                                  <i class="fas fa-angle-double-right"></i>
                                </button>
                              )}
                              {x?.showMore && (
                                <div>
                                  <h6>{x?.idea_desc}</h6>

                                  {x?.attachments.length != 0 && (
                                    <button
                                      onClick={() => showMore(index)}
                                      class="btn btn-blanktd"
                                    >
                                      Show less{" "}
                                      <i class="fas fa-angle-double-right"></i>
                                    </button>
                                  )}
                                </div>
                              )}
                              <div className="row mb-3">
                                {x?.attachments.map((a) =>
                                  a?.attachment?.includes(".mp4") ? (
                                    <div
                                      class="col-sm-1 col-3 pr-0"
                                      onClick={() => {
                                        setLink(a?.attachment);
                                        viewnewopen();
                                      }}
                                    >
                                      <video height="75px" width="100%">
                                        <source
                                          src={a?.attachment}
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  ) : a?.attachment?.includes(".jpg") ||
                                    a?.attachment?.includes(".jpeg") ||
                                    a?.attachment?.includes(".png") ||
                                    a?.attachment?.includes(".gif") ? (
                                    <div
                                      onClick={() => {
                                        setLink(a?.attachment);
                                        viewnewopen();
                                      }}
                                      class="col-sm-1 col-3 pr-0"
                                    >
                                      <img width="100%" src={a?.attachment}></img>
                                    </div>
                                  ) : a?.attachment?.includes(".pdf") ? (
                                    <div class="col-sm-1 col-3 pr-0">
                                      <iframe
                                        onClick={() => {
                                          setLink(a?.attachment);
                                          viewnewopen();
                                        }}
                                        width="100%"
                                        height="75"
                                        src={a?.attachment}
                                      ></iframe>
                                    </div>
                                  ) : null
                                )}
                              </div>
                            </div>
                            {(x?.is_active == true && x?.is_draft == false) && (
                              // <div className="row">
                              <div class="col-md-12">
                                <div class="bg-light rounded px-3 pt-0">
                                  <div class="btn-blanktd attachemntbox pt-3">
                                    Status
                                  </div>
                                  <ul class="ideastatusbar">
                                    <li class="completed" onClick={(e) => { outstanding(x) }} style={{cursor:"pointer"}}>
                                      <div class="ideadefstat">
                                        <span>1</span>
                                      </div>
                                      <h4>Submitted</h4>
                                      {/* <p>20/07/23 4:23 PM</p> */}
                                    </li>
                                    <li
                                      class={`${x.status === "A" ||
                                          x.status === "I" ||
                                          x?.status === "U" ||
                                          x?.bspoc_status === "U" ||
                                          x.bspoc_status === "A" ||
                                          x.bspoc_status === "I"
                                          ? "completed"
                                          : ""
                                        }`}
                                        onClick={(e) => { outstanding(x) }} style={{cursor:"pointer"}}
                                    >
                                      <div class="ideadefstat">
                                        <span>2</span>
                                      </div>
                                      <h4>
                                        {x.status === "A" ||
                                          x.status === "I" ||
                                          x?.status === "U" ||
                                          x?.bspoc_status === "U" ||
                                          x.bspoc_status === "A" ||
                                          x.bspoc_status === "I"
                                          ? "Approved"
                                          : x.status === "R" ||
                                            x.bspoc_status === "R"
                                            ?
                                            "Rejected"
                                            : x?.status == "cancel" ||
                                              x?.bspoc_status === "cancel" ?
                                              "Cancelled"
                                              : "No Action Taken"}
                                        <br />
                                      </h4>
                                    </li>
                                    <li
                                      class={`${x.status === "U" ||
                                          x.bspoc_status === "U" ||
                                          x.status === "I" ||
                                          x.bspoc_status === "I"
                                          ? "completed"
                                          : ""
                                        }`}
                                      // onClick={(e) => { addnewopen(x) }}
                                      onClick={(e) => { outstanding(x) }} style={{cursor:"pointer"}}
                                    >
                                      <div class="ideadefstat">
                                        <span>3</span>
                                      </div>
                                      <h4>Under Implementation</h4>

                                      {/* <p>
                                        {x?.implemented_data?.length > 0 &&
                                          `(${x?.implemented_data[0]?.from_date}) - (${x?.implemented_data[0]?.to_date})`}
                                      </p> */}
                                    </li>
                                    {x?.status !== "cancel" ||
                                      x?.bspoc_status !== "cancel" ?
                                      <li
                                        class={`${x.bspoc_status === "I" ||
                                            x.status === "I"
                                            ? "completed"
                                            : ""
                                          }`}
                                          onClick={(e) => { outstanding(x) }} style={{cursor:"pointer"}}
                                      >
                                        <div class="ideadefstat">
                                          <span>?</span>
                                        </div>
                                        <h4>Implemented</h4>
                                      </li>
                                      :
                                      <li
                                        class={`${x.bspoc_status === "cancel" ||
                                            x.status === "cancel"
                                            ? "completed"
                                            : ""
                                          }`}
                                          onClick={(e) => { outstanding(x) }} style={{cursor:"pointer"}}
                                      >
                                        <div class="ideadefstat">
                                          <span>X</span>
                                        </div>
                                        <h4>Cancelled</h4>
                                      </li>
                                    }
                                  </ul>
                                </div>
                                {/* </div> */}
                              </div>
                            )}
                            {x?.showComments && (
                              <>
                                {x?.comments.map((c) => (
                                  <div class="col-md-12 mt-3">
                                    <div class="row justify-content-end">
                                      <div class="col-md-11 col-11">
                                        <div class="row align-items-center inputideabox">
                                          <div class="col-md-1">
                                            <div class="team-profile">
                                              <img
                                                src={
                                                  x?.profile_image != ""
                                                    ? x?.profile_image
                                                    : avtar
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <div class="col-md-8">
                                            <h4>
                                              {c?.created_by_first_name +
                                                " " +
                                                c?.created_by_last_name}{" "}
                                              <i class="fas fa-circle"></i>{" "}
                                              <span>{c?.created_by_code}</span>{" "}
                                              <i class="fas fa-circle"></i>{" "}
                                              {moment
                                                .utc(c?.created_at)
                                                .format(
                                                  "MMMM Do YYYY, h:mm a"
                                                )}
                                            </h4>
                                          </div>
                                          {x?.created_by_code === c?.created_by_code && (
                                            <div class="col-md-2 text-sm-right">
                                              <button
                                                onClick={() => submitDelete(c?.id)}
                                                class="btn btn-blanktd mt-0 mb-2 mr-1"
                                              >
                                                <i class="far fa-trash-alt text-danger f-14"></i>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        <div class="row">
                                          <div class="col-md-12">
                                            <h6>
                                              {c?.tagged?.length > 0 && (
                                                <span
                                                  style={{ color: "blue" }}
                                                >{`@${c?.tagged}  `}</span>
                                              )}
                                              {`${c?.comment}`}
                                            </h6>
                                          </div>

                                          <div class="col-md-12">
                                            <div class="border-top my-2"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {x?.status !== "I" && x?.status !== "R" && x?.status !== "cancel" && (
                                  <div style={{ marginLeft: "80px" }} class="row">
                                    <div class="col-md-12">
                                      <Select
                                        name="suggest"
                                        placeholder="Select to tag a person..."
                                        options={x?.commenters?.filter(
                                          (obj) => {
                                            if (obj.type !== "CR") {
                                              return true;
                                            }
                                          }
                                        )}
                                        isMulti={true}
                                        onChange={(e) => setTaggedUser(e)}
                                        className="basic-multi-select ml-auto"
                                        classNamePrefix="select"
                                      />
                                      <div class="form-group innergroup">
                                        <label style={{ display: "" }}>
                                          Leave your Comments{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <textarea
                                          id={x?.id}
                                          onChange={(e) => {
                                            setcomment(e.target.value);
                                            setCommentError(false);
                                          }}
                                          class="form-control"
                                          placeholder="Write your Comments..."
                                        ></textarea>
                                        {commentError && (
                                          <div className="small text-danger">
                                            Comment is required
                                          </div>
                                        )}
                                        {/* <p>
                                      Caption text, description, error
                                      notification
                                    </p> */}

                                      </div>
                                    </div>

                                    <div class="col-md-12 mb-3 text-right">
                                      <button
                                        onClick={() => {
                                          clearComment(x?.id);
                                          setCommentError(false);
                                        }}
                                        class="btn btn-outline-danger mr-2 f-14"
                                      >
                                        Clear
                                      </button>
                                      <button
                                        onClick={() => saveComments(x?.id, index)}
                                        class="btn btn-primary-inner bpi-main f-14"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                        </div>
                      </>
                    ))
                    :
                    <div className="row ml-5 text-center">
                      <div className="col-md-12">
                        <img src={idea_img} style={{ width: "40%", marginLeft: "-20%" }} />
                        <div className="row" style={{ justifyContent: "center" }}>
                          <h5 className="mt-3 text-secondary">Waiting for your ideas!</h5>
                          <Link to='/shareidea'>
                            <button type="button" class="btn btn-primary-inner bpi-main ml-3 mt-2">Share Your Ideas</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 idealistpagination">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Formik
        initialValues={myideaDataFilter}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <label>
                    From Date <span class="text-danger">*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    name="from_date"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group innergroup">
                  <label>
                    To Date <span class="text-danger">*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    name="to_date"
                    type="date"
                    class="form-control"
                  />
                </div>
                {/* <div class="form-group innergroup">
            <label>
              Select Subject <span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              
            />
          </div> */}
                <div class="form-group innergroup">
                  <label>
                    Select Status <span class="text-danger">*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      handleChange(e);
                      setMyideaDataFilter({
                        ...myideaDataFilter,
                        status: e.target.value,
                      });
                    }}
                    name="status"
                    class="form-control newbgselect"
                  >
                    <option>Select</option>
                    <option value="">Select All</option>
                    <option value="N">Submitted</option>
                    <option value="A">Approved</option>
                    <option value="R">Rejected</option>
                    <option value="cancel">Cancelled</option>
                    <option value="U">Under Implementation</option>
                    <option value="I">Implemented</option>
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  onClick={() => {
                    filterclose();
                    cancelFilter();
                  }}
                >
                  Clear
                </button>
                <button type="submit" class="btn btn-primary-inner bpi-main">
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>

      <Modal show={addnewshow} onHide={addnewclose} backdrop="static" keyboard={false} size="lg" className="modaldefaultclose modaldefaultclosecenter">
        <Modal.Header closeButton>
          <Modal.Title>Implementation History</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">

                <table class="table table-advance">
                  <thead>
                    <th>S.No</th>
                    <th>Name & Code</th>
                    <th>Implementation Period</th>
                    <th>Assigned Date</th>
                    <th>Remark</th>
                  </thead>
                  <tbody>
                    {impHistory.length > 0 && (
                      impHistory?.map((data, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{data?.full_name} ({data?.emp_code})</td>
                            <td>{moment(data?.from_date).format("Do MMM YYYY")} - {moment(data?.to_date).format("Do MMM YYYY")}</td>
                            <td class="text-muted font-weight-normal">{moment(data?.created_at).format("Do MMM YYYY")}</td>
                            <td class="text-muted font-weight-normal">
                              {data?.remark}
                              </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>

                </table>

              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={viewnewshow}
        onHide={viewnewclose}
        size="md"
        centered
        className="videopopup modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          {video?.includes(".mp4") ? (
            <video controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : video?.includes(".jpg") ||
            video?.includes(".jpeg") ||
            video?.includes(".png") ||
            video?.includes(".gif") ? (
            <img src={video}></img>
          ) : video?.includes("pdf") ? (
            <iframe width="800" height="450" src={video}></iframe>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal show={outstandshow} onHide={outstandclose} backdrop="static" keyboard={false} size="xl" className="modaldefaultclose modaldefaultclosecenter">
        <Modal.Header closeButton>
          <Modal.Title>Idea Hierarchy</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">

                <table class="table">
                  <thead>
                    <th>S.No</th>
                    <th>User Type</th>
                    <th>Name & Code</th>
                    <th>Remarks</th>
                    {/* <th>Submitted</th> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td class="text-dark fw-bold">Creator</td>
                      <td>{creator}</td>
                      <td></td>
                      {/* <td><span class="badge bad-status badge-success">Submitted</span></td> */}
                    </tr>
                    <tr>
                      <td>2</td>
                      <td class="text-dark fw-bold">Reporting Manager</td>
                      <td>{reportingManager}</td>
                      <td>
                        {rmRemarks} {rmRemarks !== null && <sapn>(Duration: From {moment(impHistory[0]?.from_date).format('DD-MM-YYYY')} to {moment(impHistory[0]?.to_date).format('DD-MM-YYYY')})</sapn>}
                      </td>
                      {/* <td><span class="badge bad-status badge-success">Approved</span></td> */}
                    </tr>
                    <tr>
                      <td>3</td>
                      <td class="text-dark fw-bold">Idea Lead</td>
                      <td>{ideaLead}</td>
                      <td style={{fontSize:"14px"}}>
                        {leadRemarks} {leadRemarks !== null && <sapn>(Duration: From {moment(impHistory[0]?.from_date).format('DD-MM-YYYY')} to {moment(impHistory[0]?.to_date).format('DD-MM-YYYY')})</sapn>}
                      </td>
                      {/* <td><span class="badge bad-status badge-warning">Pending</span></td> */}
                    </tr>
                    <tr>
                      <td>4</td>
                      <td class="text-dark fw-bold">Implementor</td>
                      {impHistory.length > 0 ?
                      <td>{impHistory[0]?.full_name} ({impHistory[0]?.emp_code})</td>
                      :
                      <td>Not Assigned</td>
                      }
                      <td>{impRemarks}</td>
                      {/* <td>{implementor}</td>  */}
                      {/* <td><span class="badge bad-status badge-warning">Pending</span></td> */}
                    </tr>
                    <tr>
                      <td>5</td>
                      <td class="text-dark fw-bold">BSPOC</td>
                      {bspocData?.length > 0 ?
                        <td>{bspocData[0]?.bspoc_name}</td>
                        :
                        <td></td>
                      }
                      <td></td>
                      {/* <td><span class="badge bad-status badge-warning">Pending</span></td> */}
                    </tr>
                  </tbody>

                </table>

              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Myidea;
