import { useEffect, useState } from "react";
import Select from 'react-select';
import { Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function MultiOption(props) {
    let { isViewingorPublished, edit, aquestionData, selectquestion, dataSelect, index, categoryName, setCurMultiOptQues, setMultiOptQues, setQuestionData } = props;
    const [questions, setQuestions] = useState([]);
    const [selectedType, setSelectedType] = useState("");

    const [quesType, setQuesType] = useState([]);

    useEffect(() => {
        setQuestions(JSON.parse(JSON.stringify(aquestionData)))
        setCurMultiOptQues(JSON.parse(JSON.stringify(aquestionData)))
        setMultiOptQues(JSON.parse(JSON.stringify(aquestionData)))
    }, [aquestionData]);

    let inputEle = "";

    const updateValue = (type, index, e) => {
        let question = JSON.parse(JSON.stringify(questions))
        if (type === "type" && (e?.value === "Rating" || e?.value === "Slider" || e?.value === "Short Answer" || e?.value === "Long Answer" || e?.value === "Emoji" || e?.value === "Date")) {
            question[index][type] = e?.value;
            question[index]["option_type"] = "txt";
            question[index]["options"].push({
                id: 0,
                option: "",
                option_type: question[index]["option_type"],
                type: e?.value,
                min: 0,
                max: 0,
                is_dropdown: false,
                allow_feedback: false,
                feedback_mandatory: false
            });
            let val = JSON.parse(JSON.stringify(quesType))
            val.push({
                type: e?.value
            })
            setQuesType(val)
        } else if (type === "type") {
            question[index][type] = e?.value;
            question[index]["option_type"] = "";
        } else if (type === "is_dropdown") {
            question[index]["is_dropdown"] = e
        } else {
            question[index][type] = e;
        }
        setQuestionData(question)
        setMultiOptQues(question)
        setCurMultiOptQues(question)
    }

    const HandleDataRow = (index) => {
        let copyValue = [...questions];
        if (copyValue.option_type == "file" || copyValue.option_type == "old_file") {
            copyValue[index]["options"].push({
                id: 0,
                option_type: "file",
                option: "",
                min: 0,
                max: 0,
            });
        } else if (inputEle) {
            if (copyValue[index]["options"].length == 16) return;
            copyValue[index]["options"].push({
                id: 0,
                option: inputEle?.target?.value,
                option_type: "txt",
                type: selectedType?.value,
                min: 0,
                max: 0,
            });
            inputEle.target.value = "";
            let val = JSON.parse(JSON.stringify(quesType))
            val.push({
                type: selectedType?.value
            })
            setQuesType(val)
        }
        setQuestionData(copyValue);
        setMultiOptQues(copyValue);
        setCurMultiOptQues(copyValue)
    };

    const handleDelete = (item, pi, idx) => {
        const copyValue = [...questions];
        let Items = item;
        Items = Items.splice(idx, 1);
        copyValue[pi] = { ...copyValue[pi], options: item };
        setQuestionData(copyValue);
        setMultiOptQues(copyValue);
        setCurMultiOptQues(copyValue)
    };

    const setDefaultOption = (e, index, value) => {
        let copyData = questions
        copyData[index].default_option = value
        setQuestionData(copyData);
        setMultiOptQues(copyData);
        setCurMultiOptQues(copyData)
    }

    const minMax = (data, i, min_max, e) => {
        let copyValue = JSON.parse(JSON.stringify(questions));
        if (edit) {
            switch (data[i]?.type) {
                case "Short Answer":
                case "Slider":
                case "Emoji":
                case "Long Answer":
                case "Rating":
                case "Date":
                    if (min_max === "is_dropdown" || min_max === "allow_feedback" || min_max === "feedback_mandatory") {
                        copyValue[index]["options"][i][min_max] = e
                    } else {
                        copyValue[index]["options"][i][min_max] = e?.target?.value
                    }
                    break;
            }
        } else {
            switch (quesType[i]?.type) {
                case "Short Answer":
                case "Slider":
                case "Emoji":
                case "Rating":
                    if (min_max === "is_dropdown" || min_max === "allow_feedback" || min_max === "feedback_mandatory") {
                        copyValue[index]["options"][i][min_max] = e
                    } else {
                        copyValue[index]["options"][i][min_max] = e?.target?.value
                    }
                    break;
            }
        }
        // setQuestions(copyValue);
        setQuestionData(copyValue)
        setMultiOptQues(copyValue);
        setCurMultiOptQues(copyValue)
    };

    const RadioBox = (value, item, pi, idx) => {
        return (
            <>
                <Col md={12} className="mb-3">
                    <label class="logCheck d-inline-block mr-4">
                        {item.option}
                        <input disabled type="radio" name="dropdquestion" />
                        <span class="checkmark"></span>
                    </label>
                    {!isViewingorPublished ? (<div class="mt-2 mt-sm-0 d-sm-inline-block">
                        <span
                            class="text-danger cursor-pointer text-sm-right"
                            onClick={() => handleDelete(value, pi, idx)}
                        >
                            <i class="far fa-times-circle"></i> Remove
                        </span>
                        {(questions[index]?.is_dropdown || categoryName === "Quiz") && (
                            <>
                                <input
                                    type="radio"
                                    name="default_opt"
                                    className="ml-4"
                                    checked={questions[index]?.default_option == value[idx].option}
                                    onChange={(e) => {
                                        setDefaultOption(e, idx, value[idx].option)
                                    }}
                                />
                                <label className="ml-2">{categoryName === "Quiz" ? "Set as answer" : "Set as default"}</label>
                            </>
                        )}
                    </div>) : ("")}
                </Col>
            </>
        );
    };

    const InputBoxLong = (value, index, idx) => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <table width='100%' className="table table-striped">
                            <thead className="text-center">
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Web
                                    </th>
                                    <th>
                                        Mobile
                                    </th>
                                    <th>
                                        Tablet
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Option Width
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidth", e);
                                            }}
                                            value={value[idx]?.optWidth}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthMob", e);
                                            }}
                                            value={value[idx]?.optWidthMob}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthTab", e);
                                            }}
                                            value={value[idx]?.optWidthTab}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }

    const InputBoxShort = (value, index, idx) => {
        return (
            <>
                <div className="row">
                    <div class="col-md-4">
                        <div class="form-group innergroup">
                            <label>Min Character</label>
                            <input
                                disabled={isViewingorPublished}
                                type="number"
                                class="form-control"
                                name="min"
                                placeholder="Enter Min Character"
                                onChange={(e) => {
                                    minMax(value, idx, "min", e);
                                }}
                                value={value[idx]?.min}
                            />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group innergroup">
                            <label>Max Character</label>
                            <input
                                disabled={isViewingorPublished}
                                type="number"
                                class="form-control"
                                name="max"
                                placeholder="Enter Max Character"
                                onChange={(e) => {
                                    minMax(value, idx, "max", e);
                                }}
                                value={value[idx]?.max}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table width='100%' className="table table-striped">
                            <thead className="text-center">
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Web
                                    </th>
                                    <th>
                                        Mobile
                                    </th>
                                    <th>
                                        Tablet
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Option Width
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidth", e);
                                            }}
                                            value={value[idx]?.optWidth}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthMob", e);
                                            }}
                                            value={value[idx]?.optWidthMob}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthTab", e);
                                            }}
                                            value={value[idx]?.optWidthTab}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                </tr>
                                {value[idx]?.allow_feedback && (
                                    <tr>
                                        <td>Feedback Width</td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidth", e);
                                                }}
                                                value={value[idx]?.feedbackWidth}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidthMob", e);
                                                }}
                                                value={value[idx]?.feedbackWidthMob}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidthTab", e);
                                                }}
                                                value={value[idx]?.feedbackWidthTab}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    };

    const SelectBox = (value, item, pi, idx) => {
        return (
            <Col md={12} className="mb-3">
                <label class="logCheck d-inline-block mr-4">
                    {item.option}
                    <input disabled type="checkbox" name="statusupdate3" />
                    <span class="checkmark"></span>
                </label>

                {!isViewingorPublished ? (
                    <div class="mt-2 mt-sm-0 d-sm-inline-block">
                        <span
                            class="text-danger cursor-pointer text-sm-right"
                            onClick={() => { handleDelete(value, pi, idx) }}
                        >
                            <i class="far fa-times-circle"></i> Remove
                        </span>
                    </div>) : ("")}

            </Col>
        );
    };

    const RatingBox = (value, i, idx) => {
        return (
            <>
                <div className="row">
                    <div class="col-md-3">
                        <div class="form-group innergroup">
                            {(value[idx]?.type === "Rating" || value[idx]?.type === "Slider") && (
                                <>
                                    <label>Max. Rating</label>
                                    <select
                                        disabled={isViewingorPublished}
                                        class="form-control newbgselect"
                                        name="max"
                                        onChange={(e) => {
                                            minMax(value, idx, "max", e);
                                        }}
                                        value={value[idx]?.max}
                                    >
                                        <option value="">Select</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </>
                            )}
                            {(value[idx]?.type === "Rating" || value[idx]?.type === "Slider") && (
                                <>
                                    <label>
                                        Options as Dropdown<span class="text-danger"></span>
                                    </label>
                                    <div className="">
                                        <label
                                            class="logCheck d-inline-block mr-4"
                                            for={`dropYes${idx}q${i}`}
                                        >
                                            <input
                                                id={`dropYes${idx}q${i}`}
                                                type="radio"
                                                name={`dropdown${idx}q${i}`}
                                                onClick={(e) => {
                                                    minMax(value, idx, "is_dropdown", true);
                                                }}
                                                checked={
                                                    dataSelect[idx]?.is_dropdown ||
                                                    value[idx]?.is_dropdown === true
                                                }
                                            />
                                            Yes
                                            <span class="checkmark"></span>
                                        </label>
                                        <label
                                            class="logCheck d-inline-block"
                                            for={`dropNo${idx}q${i}`}
                                        >
                                            <input
                                                id={`dropNo${idx}q${i}`}
                                                type="radio"
                                                name={`dropdown${idx}q${i}`}
                                                onClick={(e) => {
                                                    minMax(value, idx, "is_dropdown", false);
                                                }}
                                                checked={
                                                    dataSelect[idx]?.is_dropdown ||
                                                    value[idx]?.is_dropdown === false
                                                }
                                            />
                                            No
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </>
                            )}
                            {/* <label>Option Width</label>
                            <select
                                disabled={isViewingorPublished}
                                class="form-control newbgselect"
                                name="max"
                                onChange={(e) => {
                                    minMax(value, idx, "optWidth", e);
                                }}
                                value={value[idx]?.optWidth}
                            >
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="form-group innergroup">
                            <label>Rating Description</label>
                            <textarea className='form-control' disabled={isViewingorPublished} name='desc' onChange={(e) => {
                                minMax(value, idx, 'desc', e)
                            }} value={value[idx]?.desc} />
                        </div>
                    </div>
                    {(value[idx]?.type === "Rating" || value[idx]?.type === "Slider" || value[idx]?.type === "Date") && (
                        <div className="col-md-3 form-group innergroup">
                            <br />
                            <div className="row" style={{
                                marginTop: '-14px'
                            }}>
                                <div className="col-md-6">
                                    <label>
                                        User Feedback<span class="text-danger"></span>
                                    </label>
                                    <div className="">
                                        <label
                                            class="logCheck d-inline-block mr-4"
                                            for={`feedYes${idx}q${i}`}
                                        >
                                            <input
                                                id={`feedYes${idx}q${i}`}
                                                type="radio"
                                                name={`feedback${idx}q${i}`}
                                                onClick={(e) => {
                                                    minMax(value, idx, "allow_feedback", true);
                                                }}
                                                checked={
                                                    dataSelect[idx]?.allow_feedback ||
                                                    value[idx]?.allow_feedback === true
                                                }
                                            />
                                            Yes
                                            <span class="checkmark"></span>
                                        </label>
                                        <label
                                            class="logCheck d-inline-block"
                                            for={`feedNo${idx}q${i}`}
                                        >
                                            <input
                                                id={`feedNo${idx}q${i}`}
                                                type="radio"
                                                name={`feedback${idx}q${i}`}
                                                onClick={(e) => {
                                                    minMax(value, idx, "allow_feedback", false);
                                                }}
                                                checked={
                                                    dataSelect[idx]?.allow_feedback ||
                                                    value[idx]?.allow_feedback === false
                                                }
                                            />
                                            No
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {
                                        value[idx]?.allow_feedback && (
                                            <>
                                                <label>Feedback Mandatory</label>
                                                <label
                                                    class="logCheck d-inline-block mr-4"
                                                    for={`feedMandatYes${idx}q${i}`}
                                                >
                                                    <input
                                                        id={`feedMandatYes${idx}q${i}`}
                                                        type="radio"
                                                        name={`feedbackMandat${idx}q${i}`}
                                                        onClick={(e) => {
                                                            minMax(value, idx, "feedback_mandatory", true);
                                                        }}
                                                        checked={
                                                            dataSelect[idx]?.feedback_mandatory ||
                                                            value[idx]?.feedback_mandatory === true
                                                        }
                                                    />
                                                    Yes
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label
                                                    class="logCheck d-inline-block"
                                                    for={`feedMandatNo${idx}q${i}`}
                                                >
                                                    <input
                                                        id={`feedMandatNo${idx}q${i}`}
                                                        type="radio"
                                                        name={`feedbackMandat${idx}q${i}`}
                                                        onClick={(e) => {
                                                            minMax(value, idx, "feedback_mandatory", false);
                                                        }}
                                                        checked={
                                                            dataSelect[idx]?.feedback_mandatory ||
                                                            value[idx]?.feedback_mandatory === false
                                                        }
                                                    />
                                                    No
                                                    <span class="checkmark"></span>
                                                </label>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-md-12">
                        <table width='100%' className="table table-striped">
                            <thead className="text-center">
                                <tr>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Web
                                    </th>
                                    <th>
                                        Mobile
                                    </th>
                                    <th>
                                        Tablet
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Option Width
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidth", e);
                                            }}
                                            value={value[idx]?.optWidth}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthMob", e);
                                            }}
                                            value={value[idx]?.optWidthMob}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            disabled={isViewingorPublished}
                                            class="form-control newbgselect"
                                            name="max"
                                            onChange={(e) => {
                                                minMax(value, idx, "optWidthTab", e);
                                            }}
                                            value={value[idx]?.optWidthTab}
                                        >
                                            <option value="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </td>
                                </tr>
                                {value[idx]?.allow_feedback && (
                                    <tr>
                                        <td>Feedback Width</td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidth", e);
                                                }}
                                                value={value[idx]?.feedbackWidth}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidthMob", e);
                                                }}
                                                value={value[idx]?.feedbackWidthMob}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                disabled={isViewingorPublished}
                                                class="form-control newbgselect"
                                                name="max"
                                                onChange={(e) => {
                                                    minMax(value, idx, "feedbackWidthTab", e);
                                                }}
                                                value={value[idx]?.feedbackWidthTab}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {/* <div class="col-md-6">
                <div class="form-group innergroup">
                    <Select
                        isDisabled={(isViewingorPublished || selectedType !== "")}
                        options={selectquestion}
                        className="basic-multi-select"
                        classNamePrefix="select mr-3"
                        placeholder="Select answer style"
                        onChange={(e) => {
                            setSelectedType(e)
                            updateValue("type", index, e);
                        }}
                        value={selectedType}
                    />
                </div>
            </div> */}
            {(selectedType?.value === "Single Select" || selectedType?.value === "Multiple Choice") &&
                <div
                    class={
                        questions[index]?.type === "Single Select"
                            ? "col-md-3"
                            : "col-md-6"
                    }
                >
                    <div class="form-group innergroup">
                        <select
                            class="form-control newbgselect"
                            name="option_type"
                            value={
                                questions ? questions[index]?.option_type : ""
                            }
                            onChange={(e) => {
                                updateValue("option_type", index, e.target.value);
                            }}
                        >
                            <option value="">Select Answer Type</option>
                            <option value="txt">Text</option>
                            <option value="file">Image</option>
                            {/* <option value="video">Video</option> */}
                        </select>
                    </div>
                </div>}
            {(selectedType?.value === "Single Select") && (
                <div className="col-md-3 form-group innergroup">
                    <label>
                        Options as Dropdown<span class="text-danger"></span>
                    </label>
                    <div className="">
                        <label
                            class="logCheck d-inline-block mr-4"
                            for={`dropYes${index}`}
                        >
                            <input
                                id={`dropYes${index}`}
                                type="radio"
                                name={`dropdown${index}`}
                                onClick={(e) => {
                                    updateValue("is_dropdown", index, true);
                                }}
                                checked={
                                    dataSelect[index]?.is_dropdown ||
                                    questions[index]?.is_dropdown === true
                                }
                            />
                            Yes
                            <span class="checkmark"></span>
                        </label>
                        <label
                            class="logCheck d-inline-block"
                            for={`dropNo${index}`}
                        >
                            <input
                                id={`dropNo${index}`}
                                type="radio"
                                name={`dropdown${index}`}
                                onClick={(e) => {
                                    updateValue("is_dropdown", index, false);
                                }}
                                checked={
                                    dataSelect[index]?.is_dropdown ||
                                    questions[index]?.is_dropdown === false
                                }
                            />
                            No
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            )}
            <div className="col-md-12">
                {aquestionData[index]?.options?.map((each, i) => {
                    if ((quesType[i]?.type === "Single Select" || each?.type === "Single Select") && each?.option_type === "txt") {
                        return RadioBox(aquestionData[index]?.options, each, index, i)
                    } else if (quesType[i]?.type === "Short Answer" || each?.type === "Short Answer") {
                        return InputBoxShort(aquestionData[index]?.options, index, i)
                    } else if ((quesType[i]?.type === "Multiple Choice" || each?.type === "Short Answer") && each?.option_type === "txt") {
                        return SelectBox(aquestionData[index]?.options, each, index, i)
                    } else if (quesType[i]?.type === "Rating" || each?.type === "Rating" || quesType[i]?.type === "Slider" || each?.type === "Slider" || quesType[i]?.type === "Emoji" || each?.type === "Emoji" || quesType[i]?.type === "Date" || each?.type === "Date") {
                        return RatingBox(aquestionData[index]?.options, index, i)
                    } else if (quesType[i]?.type === "Long Answer" || each?.type === "Long Answer") {
                        return InputBoxLong(aquestionData[index]?.options, index, i)
                    }
                })}
                <div class="innergroup">
                    {(selectedType?.value === "Multiple Choice" ||
                        selectedType?.value === "Single Select") ? (
                        <div class="row">
                            {questions[index].option_type === "txt" ? (
                                <div class="col-md-10 pr-3 pr-sm-0">
                                    <input
                                        type="text"
                                        name="option"
                                        class="form-control"
                                        placeholder="Enter Answer"
                                        onChange={(e) => {
                                            inputEle = e;
                                        }}
                                    />
                                </div>
                            ) : (
                                ""
                            )}

                            <div
                                class="col-md-2 mt-2 mt-sm-0"
                                hidden={isViewingorPublished}
                            >
                                <button
                                    type="button"
                                    class="btn btn-blanktd"
                                    onClick={() => {
                                        HandleDataRow(index);
                                    }}
                                >
                                    <span class="text-theme cursor-pointer">
                                        <i class="fas fa-plus-circle"></i> Add Option
                                    </span>
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group innergroup">
                    <Select
                        isDisabled={(isViewingorPublished || selectedType !== "")}
                        options={selectquestion}
                        className="basic-multi-select"
                        classNamePrefix="select mr-3"
                        placeholder="Select answer style"
                        onChange={(e) => {
                            setSelectedType(e)
                            updateValue("type", index, e);
                        }}
                        value={selectedType || dataSelect[index]}
                    />
                </div>
            </div>
            <div className="col-md-12"
                style={{
                    top: '30px',
                    zIndex: '999'
                }}
            >
                <div class="innergroup" onClick={() => {
                    setSelectedType("")
                }}>
                    <span class="text-theme cursor-pointer float-right">
                        <i class="fas fa-plus-circle"></i> Add Option Type
                    </span>
                </div>
            </div>
        </>
    )
}


export default MultiOption;