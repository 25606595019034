import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import AddSurveyCategory from "./AddSurveyCategory";
import * as moment from "moment";
import ViewCategorySurvey from "./ViewCategorySurvey";
import * as surveyService from "../../service/surveyCategory";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

if (typeof window !== "undefined") {
  injectStyle();
}

function SurveyCategory() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  // const [surveyData, setSurveyData] = useState([null])
  const [pageCount, setPageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [Mysurvey, setMysurvey] = useState(null);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [surveyList, setSurveyList] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [filterApplied, setFilterApplied] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    organisation_id: "",
    status: "",
  });

  const [surveyDataForExport, setSurveyDataForExport] = useState({
    organisation_id: "",
    status: "",
  });

  const getAllOrganisation = (paginate) => {
    surveyService
      .getAllOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter(ClearData);
    getSurveyCategory(
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
      // search, organisation_id, status, pageNo, page_size, sort_by, paginate
    );
    filterClose();
  };

  useEffect(() => {
    getSurveyCategory(
      searchStr,
      dataFilter.organisation_id,
      dataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
      // searchStr, "", "", 1, pageSizeNo, "-id", true
    );
  }, [pageSizeNo]);

  const filterClose = () => {
    filterclose();
  };

  const handleKey = (
    search,
    organisation_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }

    return queryParm;
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getSurveyCategory(
      searchStr,
      dataFilter.organisation_id,
      dataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const getSurveyCategory = (
    search,
    organisation_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    surveyService
      .getSurveyCategory(
        handleKey(
          search,
          organisation_id,
          status,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        setSurveyList(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    organisation_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    surveyService
      .getExportValue(
        handleKey(
          search,
          organisation_id,
          status,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SurveyCategory.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getAllOrganisation(true);
  }, []);

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSurveyCategory(
        data,
        dataFilter.organisation_id,
        dataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
        // search, organisation_id, status, pageNo, page_size, sort_by, paginate
      );
    }
  };

  const deleteSurveyCategory = (idx) => {
    surveyService
      .deleteSurveyCategory(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSurveyCategory("", "", "", 1, pageSizeNo, sortData.sort_by, true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    console.log(id, "<<<<<");
    confirmAlert({
      title: "Delete Designation",
      message: `Are you sure to delete ${deptName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSurveyCategory(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSurveyCategory(
      searchStr,
      dataFilter.organisation_id,
      dataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
      // search, organisation_id, status, pageNo, page_size, sort_by, paginate
    );
  };

  const mainDataFilter = (dataFilter) => {
    setFilterApplied(true);
    //     setSurveyDataForExport({
    //   ...surveyDataForExport,
    //   organisation_id: values.organisation_id,
    //   status: values.status,
    // });
    const orgId = dataFilter.organisation_id;
    const stats = dataFilter.status;
    getSurveyCategory(
      searchStr,
      orgId,
      stats,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  const handleSearch = (e) => {
    getSurveyCategory(
      e.target.value.toLowerCase(),
      dataFilter.organisation_id,
      dataFilter.status,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
    setSearchStr(e.target.value);
  };

  const addSurveyOrg = () => {
    setShowForm(true);
    setEditMode(false);
    setSurvey(null);
  };

  const editSurvey = (eData) => {
    console.log("eeeee", eData);
    setShowForm(true);
    setEditMode(true);
    setSurvey(eData);
  };

  const onClose = () => {
    setShowForm(false);
    setSurvey(null);
  };

  const filterShow = () => {
    filteropen();
    // getAllOrganisation(false);
  };

  const onView = (data) => {
    console.log(">>>>");
    setViewForm(true);
    setViewFormData(data);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getSurveyCategory("", "", "", 1, 10, "-id", true);
  };

  useEffect(() => {
    getSurveyCategory("", "", "", 1, 10, "-id", true);
  }, [pageSizeNo]);

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Survey Type</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/">
                  Company
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Survey Category</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <ToastContainer autoClose={1000} />
          <div className="row">
            <div class="col-lg-4">
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  type="text"
                  id="searchbar"
                  class="form-control"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e)}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          disabled={surveyList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              dataFilter.organisation_id,
                              dataFilter.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  class="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}
                >
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>

                {/* {finalUrlValue?.A ? ( */}
                <button
                  class="btn btn-primary-inner"
                  onClick={() => {
                    addSurveyOrg();
                  }}
                >
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>#</th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                >
                  Type Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                >
                  Organization
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls"
                      : sortData["updated_by"]
                  }
                >
                  Last updated by
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls"
                      : sortData["updated_at"]
                  }
                >
                  Last updated on
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {surveyList?.length ? (
                surveyList?.map((data, index) => (
                  <>
                    <tr key={`desg${index}`}>
                      <td>{index + 1}</td>
                      <td class="fw-bold text-theme">{data?.name}</td>
                      <td>{data?.organisation_name}</td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {moment
                          .utc(data?.updated_at)
                          .format("Do MMM YYYY, H:mm:ss A")}
                      </td>
                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            InActive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.V ||
                        finalUrlValue?.E ||
                        finalUrlValue?.R ? (
                        <td>
                          {finalUrlValue?.V ? (
                            <img
                              src="images/eye.png"
                              alt=""
                              onClick={() => {
                                onView(data);
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.E ? (
                            <button
                              class="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editSurvey(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.R ? (
                            <button
                              class="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {surveyList?.length === 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Organization <span className="text-danger"></span>
            </label>

            <select
              className="form-control newbgselect"
              classNamePrefix="select"
              name="organisation_id"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setDataFilter({
                  ...dataFilter,
                  organisation_id: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={dataFilter.organisation_id}
            >
              <option value="" label="Select Organization" disabled />

              {renderOrgOptions(allOrg)}
            </select>
          </div>

          <div className="form-group innergroup">
            <label>
              Select Status <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect"
              name="status"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                setDataFilter({
                  ...dataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={dataFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter(dataFilter)}
            className="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddSurveyCategory
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editCategory={survey}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewCategorySurvey
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}

      {/* <Modal show={addnewshow} onHide={addnewclose} size="lg" backdrop="static" keyboard={false} className="modaldefaultclose modaldefaultclosecenter">
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Survey Catgeory</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group innergroup">
                                        <label>Survey Category Name<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Policy Category Name" />
                                       
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group innergroup">
                                        <label>Organization<span class="text-danger">*</span></label>
                                        <Select
                                            options={options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                       
                                    </div>
                                </div>





                                <div class="col-md-12">
                                    <div class="form-group innergroup">
                                        <label className="d-block">Status<span class="text-danger">*</span></label>
                                        <label class="logCheck d-inline-block mr-4">
                                            Active
                                            <input type="radio" name="statusupdate1" />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="logCheck d-inline-block">
                                            Inactive
                                            <input type="radio" name="statusupdate1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-outline-danger" onClick={addnewclose}>Cancel</button>
                    <button class="btn btn-primary-inner bpi-main">Submit</button>
                </Modal.Footer>

            </Modal> */}
    </>
  );
}

export default SurveyCategory;
