import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
const ViewDepartment = (props) => {
    const { viewForm, onViewClose, viewFormData } = props;
    const design = {
        maxWidth: "180px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    };
    return (
        <>
            <Modal
                show={viewForm}
                onHide={onViewClose}
                size="lg"
                className="modaldefaultclose modaldefaultclosecenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <div className="row viewformaftersubmit">
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Department Code<span className="text-danger"></span>
                                        </label>
                                        <p style={design}>{viewFormData?.department_code}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Department Name</label>
                                        <p style={design}>{viewFormData?.department_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>Description</label>
                                        <p style={design}>{viewFormData?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ViewDepartment;