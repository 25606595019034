import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { uploadImages } from "../../service/jv_service";
import { toast, ToastContainer } from "react-toastify";
import { getCompanyCode } from "../../service/jv_service";
import { getStateList } from "../../service/state";
import { saveGST, updateGST, getCompanyOrg } from "../../service/gst";

const gstInregex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

function GSTForm(props) {
  let flag = true;
  const { show, close, edit, getList, editData } = props;
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [attachmentError, setAttachmentError] = useState(false);

  const [companyCode, setCompanyCode] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [curStateCode, setCurStateCode] = useState("");

  const [file, setfiles] = useState([]);

  const navigate = useNavigate();

  const getCompanyCodes = (paginate) => {
    getCompanyOrg(paginate)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.name + " " + "(" + x?.code + ")",
          };
          plant.push(data);
        });
        setCompanyCode(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getState = () => {
    getStateList("paginate=false")
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.name, code: x?.gst_state_code };
          plant.push(data);
        });
        setStateList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const [errors, setErrors] = useState({
    company_id: false,
    state_id: false,
    state_code: false,
    gst_no: false,
    billing_address: false,
  });
  const [gst, setGST] = useState({
    company_id: null,
    state_id: null,
    gst_no: null,
    billing_address: null,
    is_active: true
  });
  const [gstError, setGstError] = useState(false);

  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "company_id":
        setGST((prev) => ({
          ...prev,
          company_id: event,
        }));
        setErrors((prev) => ({
          ...prev,
          company_id: false,
        }));
        break;
      case "state":
        setGST((prev) => ({
          ...prev,
          state_id: event,
        }));
        setCurStateCode(event?.code);
        setErrors((prev) => ({
          ...prev,
          state_id: false,
        }));
        break;
      case "gst":
        if (event.target.value.includes(curStateCode) === false) {
          setErrors((prev) => ({
            ...prev,
            state_code: true,
          }));
        } else if (event.target.value.includes(curStateCode) === true) {
          setErrors((prev) => ({
            ...prev,
            state_code: false,
          }));
        }
        setGST((prev) => ({
          ...prev,
          gst_no: event.target.value.toUpperCase(),
        }));
        setErrors((prev) => ({
          ...prev,
          gst_no: false,
        }));
        break;
      case "billingaddress":
        setGST((prev) => ({
          ...prev,
          billing_address: event.target.value,
        }));
        setErrors((prev) => ({
          ...prev,
          billing_address: false,
        }));
        break;
      case "status":
        setGST((prev) => ({
          ...prev,
          is_active: event,
        }));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getCompanyCodes(false);
    getState();
  }, []);

  useEffect(() => {
    if (edit == true) {
      let attachments = [];
      attachments.push(editData?.certificate);
      setAttachment(attachments);
      Object.keys(editData).forEach((item) => {
        gst[item] = editData[item];
      });
      setGST((prev) => ({
        ...prev,
        company_id: {
          value: editData?.company_id,
          label: editData?.company_name,
        },
        state_id: { value: editData?.state_id, label: editData?.state_name },
        is_active: editData?.is_active
      }));
    } else if (edit == false) {
    }
  }, [editData]);
  const submit = () => {
    gst.certificate = uploadDataIm;
    let attach = false;
    if (edit == false) {
      if (gst?.certificate?.length > 0) {
        attach = true;
      } else {
        setAttachmentError(true);
      }
    } else {
      if (attachment?.length > 0 || gst?.certificate?.length > 0) {
        attach = true;
      } else {
        setAttachmentError(true);
      }
    }
    gst.type = "gst";
    console.log(attachment?.length > 0, gst?.certificate?.length > 0);
    if (
      gst?.company_id !== null &&
      gst?.state_id !== null &&
      gst?.gst_no !== null &&
      gst?.billing_address !== null &&
      attach
    ) {
      flag = false;
      if (edit == false && !gstError) {
        saveGST(gst)
          .then((response) => {
            flag = true;
            getList();
            console.log(response?.data?.message);
            if (response?.data?.message.includes("already")) {
              toast.warning(response?.data?.message);
            } else {
              toast.success(response?.data?.message);
              close();
            }
          })
          .catch((error) => {
            flag = true;
            toast.error(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else if (edit == true && !gstError) {
        updateGST(gst)
          .then((response) => {
            flag = true;
            getList();
            if (response?.data?.message.includes("already")) {
              toast.warning(response?.data?.message);
            } else {
              close();
              toast.success(response?.data?.message);
            }
          })
          .catch((error) => {
            flag = true;
            toast.error(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        company_id: gst?.company_id == null ? true : false,
        state_id: gst?.state_id == null ? true : false,
        gst_no: gst?.gst_no == null ? true : false,
        billing_address: gst?.billing_address == null ? true : false,
      }));
    }
  };
  const deleteAttachment = (id) => {
    console.log(id);
    let obj = {};
    Object.keys(file)
      .filter((del) => {
        if (Number(del) !== Number(id)) {
          return true;
        }
      })
      .map((item) => {
        obj[item] = file[item];
      });

    setfiles(obj);
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      console.log(Object.keys(file)[i]);
      let data2 = file[Object.keys(file)[i]];
      if (true) {
        data.push(
          <>
            <div class="uploaded-image mr-1">
              <a
                href={URL.createObjectURL(data2)}
                target={"_blank"}
                rel="noreferrer"
              >
                {data2?.name.includes(".pdf") ? (
                  <img
                    src={"images/policy-icon/pdf.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : data2?.name.includes(".xlsx") ? (
                  <img
                    src={"images/policy-icon/xlsx.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : data2?.name.includes(".xls") ? (
                  <img
                    src={"images/policy-icon/xlsx.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : data2?.name.includes(".doc") ? (
                  <img
                    src={"images/policy-icon/docs.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : data2?.name.includes(".docs") ? (
                  <img
                    src={"images/policy-icon/docs.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : data2?.name.includes(".pptx") ? (
                  <img
                    src={"images/policy-icon/ppt.png"}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(data2)}
                    alt="image"
                    width="70px"
                    height="70px"
                  />
                )}
              </a>
              {/* <button
                onClick={() => {
                  deleteAttachment(Object.keys(file)[i]);
                }}
                className="btn btn-blanktd text-danger ml-2"
              >
                <i className="far fa-trash-alt text-danger"></i>
              </button> */}
            </div>
          </>
        );
      }
    }
    return data;
  };
  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    setfiles(file);

    let files = [];
    let filename = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await uploadImages(fileData).then((response) => {
        let name = { name: data.name };
        setErrors((prev) => ({
          ...prev,
          selectAttachment: false,
        }));
        filename.push(name);
        files.push(response.data);
      });
    }
    setAttachmentError(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  return (
    <>
      <ToastContainer></ToastContainer>

      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {edit == true ? "Edit" : "Add"} GST Certificate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Company Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="company_id"
                      value={gst?.company_id}
                      options={companyCode}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.company_id}
                      className="small text-danger"
                    >
                      Kindly select Company code
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      State<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={stateList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="state"
                      value={gst?.state_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.state_id}
                      className="small text-danger"
                    >
                      Kindly select State
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      GST NO.<span class="text-danger">*</span>
                    </label>
                    <input
                      value={gst?.gst_no}
                      type="text"
                      class="form-control"
                      placeholder="Enter GST NO."
                      maxLength={15}
                      onChange={(newValue, action) => {
                        handleChange(newValue, "gst");
                        if (!newValue.target.value.match(gstInregex)) {
                          setGstError(true);
                        } else {
                          setGstError(false);
                        }
                      }}
                    />
                    <div hidden={!errors?.gst_no} className="small text-danger">
                      Kindly enter GST NO.
                    </div>
                    <div hidden={!gstError} className="small text-danger">
                      Kindly enter Correct GST NO.
                    </div>
                    <div
                      hidden={!errors?.state_code}
                      className="small text-danger"
                    >
                      {`Kindly Enter GST number starting with ${curStateCode}`}
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Principal Billing Address
                   <span class="text-danger">*</span>
                    </label>
                    <input
                      value={gst?.billing_address}
                      type="text"
                      class="form-control"
                      placeholder="ENTER PRINCIPLE BILLING ADDRESS"
                      maxLength={500}
                      onChange={(newValue, action) => {
                        handleChange(newValue, "billingaddress");
                      }}
                    />
                    <div
                      hidden={!errors?.billing_address}
                      className="small text-danger"
                    >
                      Kindly enter BILLING ADDRESS
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group innergroup position-relative">
                    <label>
                      Upload File<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      // value={
                      //   edit === true
                      //     ? editPolicy?.logo
                      //     : uploadDataIm?.logo?.original_name
                      // }
                      placeholder="(JPG,XLSX,PPT,PPTX,PNG,PDF,TXT Format only)"
                    />
                    <div className="upload-btn-wrapper up-loposition">
                      <button type="button" className="uploadBtn">
                        Browse
                      </button>
                      <input
                        type="file"
                        title=""
                        name="attachment"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={(e) => onProfileChange(e, "attachment_path")}
                      />
                    </div>

                    <div>
                      {Object.keys(file).length !== 0 ? (
                        <div className="d-flex">{attachments123()}</div>
                      ) : edit === true ? (
                        <>
                          <div class="d-flex">
                            <div class="uploaded-image mr-1">
                              <i class="" style={{ width: "20px" }}>
                                <a
                                  href={attachment}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {attachment[0]?.includes(".pdf") ? (
                                    <img
                                      src={"/images/policy-icon/pdf.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".xlsx") ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".xls") ? (
                                    <img
                                      src={"/images/policy-icon/xlsx.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".doc") ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".docs") ? (
                                    <img
                                      src={"/images/policy-icon/docs.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".pptx") ? (
                                    <img
                                      src={"/images/policy-icon/ppt.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : attachment[0]?.includes(".mp4") ? (
                                    <img
                                      src={"/images/policy-icon/video.png"}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  ) : (
                                    <img
                                      src={attachment}
                                      alt="image"
                                      width="70px"
                                      height="70px"
                                    />
                                  )}
                                </a>
                              </i>
                              {/* <button
                                        type="button"
                                        onClick={(e) => {
                                          deleteAttachment(
                                            attachment?.split("/")[5]
                                          );
                                        }}
                                        className="btn btn-blanktd text-danger ml-2"
                                      >
                                        <i className="far fa-trash-alt text-danger"></i>
                                      </button> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      hidden={!attachmentError}
                      className="small text-danger"
                    >
                      Kindly select attachments
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Principal Billing Address
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      maxLength={500}
                      value={gst?.billing_address}
                      placeholder="ENTER PRINCIPLE BILLING ADDRESS"
                      onChange={(newValue, action) => {
                        handleChange(newValue, "billingaddress");
                      }}
                    ></textarea>
                    <div
                      hidden={!errors?.billing_address}
                      className="small text-danger"
                    >
                      Kindly enter Billing Address
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group innergroup">
                      <label className="d-block mb-2">
                        Status<span class="text-danger">*</span>
                      </label>
                      <label class="logCheck d-inline-block mr-4">
                        Active
                        <input type="radio" name="status"
                          onClick={(e) => {
                            handleChange(true, "status");
                          }}
                          checked={gst?.is_active === true}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="logCheck d-inline-block">
                        Inactive
                        <input type="radio" name="status"
                        onClick={(e) => {
                          handleChange(false, "status");
                        }}
                        checked={gst?.is_active === false}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button
            disabled={!flag}
            class="btn btn-primary-inner bpi-main"
            onClick={submit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GSTForm;
