import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import { toast } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

function ApproveTravelExpense() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const dispatch = useDispatch();

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);

  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    message: "",
    attachment_path: null,
  });

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(200);
  const [pageCount, setPageCount] = useState(1);
  const [expenseList, setExpenseList] = useState([]);
  // const [travelList, setTravelList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [modalState, setModalState] = useState(null);
  const [totalRecord, setTotalRecord] = useState();
  const [travelchats, setTravelChats] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: "",
  });

  const [pinCode, setPinCode] = React.useState("");

  const [approvedSendBack_payload, set_approvedSendBack_payload] = useState({
    pick_list_id: [],
    status: "",
    remarks: "",
    reason: [
      {
        applied_amount: 234.2,
        approved_amount: 123.3,
        expense_type: "TICKET",
        reason: "",
      },
      {
        applied_amount: 111.4,
        approved_amount: 222.5,
        expense_type: "HOTEL",
        reason: "",
      },
      {
        applied_amount: 333.6,
        approved_amount: 444.7,
        expense_type: "DA",
        reason: "",
      },
      {
        applied_amount: 555.8,
        approved_amount: 666.9,
        expense_type: "VEHICLE_LOG",
        reason: "",
      },
      {
        applied_amount: 777.9,
        approved_amount: 888.78,
        expense_type: "LOCAL_CONVEYANCE",
        reason: "",
      },
      // {
      //   "applied_amount":777.9,
      //   "approved_amount":888.78,
      //   "expense_type":"TRAVEL",
      //   "reason":""

      // },
    ],
  });

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    company: { value: undefined },
    trip_number: "",
    from_date: "",
    to_date: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    get_approved_expenselist(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );
  };

  const get_approved_expenselist = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.get_approved_expenselist(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        company,
        trip_number,
        from_date,
        to_date,
        isFiltering
      )
    ).then((response) => {
      // dispatch(setDisplayLoader("Hide"));
      setExpenseList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  useEffect(() => {
    getUsersList();
    getAllOrganisation();

    console.log(`....${finalUser}`);
    console.log(`....${finalUser.designation_name}`);

    get_approved_expenselist(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );
  }, []);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&isFiltering=" +
      isFiltering;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&company=" + company;
    }
    if (
      trip_number !== "" &&
      trip_number !== undefined &&
      trip_number !== null
    ) {
      queryParm = queryParm + "&trip_number=" + trip_number;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      get_approved_expenselist(
        searchStr,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        filterdata?.user_id?.value,
        filterdata?.company?.value,
        filterdata?.trip_number,
        filterdata?.from_date,
        filterdata?.to_date,
        false
      );
    }, 2000);

    return () => clearTimeout(getData);
  }, [searchStr]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      get_approved_expenselist(
        e.target.value,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        filterdata?.user_id?.value,
        filterdata?.company?.value,
        filterdata?.trip_number,
        filterdata?.from_date,
        filterdata?.to_date,
        false
      );

      setSearchStr(e.target.value);
    }
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
    });
  };

  const setCompantAndUser = (comp, uid) => {
    console.log(`setting`, comp, uid);

    if (comp) {
      // let mdoUser=companyDropdown.filter((x) => {
      //   return x.value == comp

      // })
      // // console.log('comp..',mdoUser);

      // setOrganisation_User({
      //   ...organisation_User,
      //   company:mdoUser
      // })

      setfilterData({
        ...filterdata,
        company: comp,
      });
    }

    if (uid) {
      // let mdoUser=usersDropdown.filter((x) => {
      //   return x.value == uid

      // })
      // console.log('uid..',mdoUser);

      // setOrganisation_User({
      //   ...organisation_User,
      //   user_id:mdoUser
      // })
      setfilterData({
        ...filterdata,
        user_id: uid,
      });
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);

    get_approved_expenselist(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.company?.value,
      filterdata?.trip_number,
      filterdata?.from_date,
      filterdata?.to_date,
      true
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    get_approved_expenselist(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );
    setfilterData({
      from_date: "",
      to_date: "",
      user_id: { value: undefined },
      company: { value: undefined },
      trip_number: "",
    });
    // setOrganisation_User({
    //   user_id:"",
    //   company:""
    // })
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(expenseList));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setExpenseList(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = [];
      copyData?.map((res) => {
        if (res?.id != data?.id) {
          obj.push(res);
          // return res;
        }
      });
      setPostingData(obj);
      setExpenseList(copyData1);
    }
  };

  const receivePickList = async () => {
    let data = [];

    postingData.map((res) => {
      if (res) {
        data.push(`${res?.id}`);
      }
    });

    if (data?.length < 1) {
      toast.error("Please select an expense to approve");
      return;
    }

    // let obj = { data: data };
    // console.log(`toast`,obj);
    // approvedSendBack_payload["pick_list_id"]=data
    approvedSendBack_payload["status"] = "APPROVED";

    for (let kk = 0; kk < data?.length; kk++) {
      await TravelListingAPI.trav_detail(data[kk])
        .then((resp) => {
          resp?.data?.dataList?.ssc_data.forEach((itemm, idx) => {
            if (itemm.current_status == "Documents Received")
              // set_pickItemId(itemm.id)
              console.log(`got piuck list id `, `${itemm.id}`);
            approvedSendBack_payload["pick_list_id"].push(`${itemm.id}`);
          });
        })
        .catch((error) => {
          console.log(`detial`, error);
          toast.error("Error!");
        });
    }

    console.log(`payl`, approvedSendBack_payload);

    for (let i = 0; i < finalUser.groups.length; i++) {
      if (finalUser.groups[i].name.toLowerCase() == "ssc head") {
        // dispatch(setDisplayLoader("Display"));
        TravelListingAPI.headapprovePickList(approvedSendBack_payload)
          .then((resp) => {
            // dispatch(setDisplayLoader("Hide"));

            toast.success("Expenses approved successfully!");
            setPostingData([]);

            get_approved_expenselist(
              searchStr,
              1,
              pageSizeNo,
              sortData.sort_by,
              true,
              "",
              filterdata?.user_id?.value,
              filterdata?.company?.value,
              filterdata?.trip_number,
              filterdata?.from_date,
              filterdata?.to_date,
              false
            );
          })
          .catch((error) => {
            // toast.error("fail!")
            toast.error(`Problem approving expenses!`);
          });

        break;
      }
    }
  };

  const onReceive = async () => {
    // console.log(`pre toast`,postingData);
    if (postingData?.length < 1) {
      toast.error("Please select an expense to approve");
      return;
    }

    confirmAlert({
      title: "Approve Travel Expense List",
      message: `Are you sure to approve expense?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => receivePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(expenseList));
    if (!data?.length) {
      return;
    }
    let copyposData = JSON.parse(JSON.stringify(postingData));

    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        // postingData.push(res);
        let payl = {
          id: res?.id,
        };

        if (copyposData?.length > 0) {
          copyposData?.map((pdata) => {
            if (pdata?.id != payl?.id) {
              console.log(`check  ${payl?.id} with ${pdata?.id}`);
              postingData.push(payl);
            }
          });
        } else {
          postingData.push(payl);
        }
      });
      setExpenseList(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setExpenseList(data);
      // let obj = postingData?.map((x) => {
      //   if (x?.id != data?.id) {
      //     return x;
      //   }
      // });
      setPostingData([]);
    }
  };

  const getExportValue = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.export_approved_expenselist(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        company,
        trip_number,
        from_date,
        to_date,
        isFiltering
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TravelPick.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleShowModalTwo = (id) => {
    setModalState(`query-modal-${id}`);
  };

  function handleCLOSE2() {
    setModalState("close");
  }

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files", files.type);

    // const fileData = new FormData();
    // fileData.append("uploaded_file", files);
    // console.log(`...`, fileData);

    // uploadfile(fileData).then((response) => {
    //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
    //   setUploadData(response.data);
    //   console.log(`response`, response.data);
    //   set_travelPostData({
    //     ...travelPostData,
    //     attachment_path: response.data,
    //   });
    // });
  };

  const postTravelChat = () => {
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.");
      return;
    }
    console.log("posted chat->", travelPostData);

    TravelListingAPI.postTravelChat(
      travelPostData.travel_id,
      travelPostData
    ).then((response) => {
      console.log("posted chat->", travelPostData);
      getTravelChat(travelPostData.travel_id);
      set_travelPostData({
        ...travelPostData,
        message: "",
        attachment_path: null,
      });
      document.getElementById("querytext").value = "";
    });
  };

  function getTravelChat(id) {
    TravelListingAPI.getTravelChat(id).then((response) => {
      console.log("get chat for id=>", response);

      console.log("chat->", response?.data?.dataList?.result?.length);
      setTravelChats(response?.data?.dataList?.result);
      set_travelPostData({
        ...travelPostData,
        travel_id: id,
      });
      // return response?.data?.dataList?.result?.length

      // setChatStatus(response?.data?.dataList?.result?.length)

      // setChatStatus({
      //   ...chatStatus,
      //   id:response?.data?.dataList?.result?.length==0
      // })
    });
  }

  useEffect(() => {
    get_approved_expenselist(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      filterdata?.company?.value,
      filterdata?.trip_number,
      filterdata?.from_date,
      filterdata?.to_date,
      false
    );
  }, [pageSizeNo]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li >                <Link to="/travel-pick">Travel Expense Pick List</Link>              </li>
              <li>                <Link to="/travel-expense">Check Expense</Link>              </li>
              <li class="active">                <Link to="/travel-approve-expense">Approve Travel Expense</Link>              </li>
              <li>                <Link to="/travel-bills">All Bills</Link>              </li>
              <li>                <Link to="/travel-processed-ta">Processed TA</Link>              </li>
              <li>                <Link to="/travel-km-report">KM Report</Link>              </li>
              <li>                <Link to="/travel-intimat-report">                  Travel Intimation Report </Link>   </li>
              <li>                <Link to="/travel-prov-report">Provision Report</Link>              </li>
              <li>                <Link to="/travel-deviat-report">Deviation Report</Link>              </li>
              <li>                <Link to="/travel-hotel-exp-report">Hotel Expense Report</Link>              </li>
              <li>                <Link to="/travel-fcm-exp-report">                  FCM Ticket Expense Report      </Link>       </li>
              <li>                <Link to="/travel-employ-report">Employee List Report</Link>              </li>
            </ul>
          </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-7">
                    <h4 class="inner-page-title">Approve Travel Expense A/C</h4>
                  </div>
                  <div className="col-md-5">
                      <div className="d-flex" style={{
                        fontSize: '14px',
                        justifyContent: 'space-between'
                      }}>
                        {/* {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                          <> */}
                            <div>
                              {/* <span className="fw-bold text-theme ml-2">{each?.leave_name}</span> */}
                              {/* <ul className=""> */}
                              
                                <span ><i style={{color:"yellow"}} class='fas fa-square'></i> Query raised</span>
                                <span className="ml-4"><i style={{color:"#FFBF00"}} class='fas fa-square'></i> Send back to User</span>
                                <span className="ml-4"><i style={{color:"#FFA895"}} class='fas fa-square'></i> Expense Exception</span>
                              {/* </ul> */}
                            </div>
                            <br />
                          {/* </>
                        ))} */}
                      </div>
                    </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong> Name, Employee ID, SAP No.</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            // handleSearch(e);
                            setSearchStr(e.target.value);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc head" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onReceive()}
                            >
                              <i class="far fa-check-circle"></i> Approve
                            </button>
                          )
                      )}

                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={
                                expenseList?.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  false,
                                  "",
                                  filterdata?.user_id?.value,
                                  filterdata?.company?.value,
                                  filterdata?.trip_number,
                                  filterdata?.from_date,
                                  filterdata?.to_date,
                                  false
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>SR NO.</th>
                        {finalUser.groups.map(
                          (data, idx) =>
                            data.name.toLowerCase() == "ssc head" && (
                              <th>
                                <label class="logCheck d-inline-block">
                                  <input
                                    type="checkbox"
                                    name="permission"
                                    onClick={(e) => {
                                      handleAllData(e);
                                    }}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </th>
                            )
                        )}
                        <th>ACTION</th>
                        <th>EMPLOYEE ID</th>
                        <th>NAME</th>
                        <th>TRIP NO</th>
                        <th>QUERY</th>
                        <th>COURIER/SUBMIT DATE</th>
                        <th>RECEIVED ON</th>
                        <th>PROCESSED ON</th>
                        <th>APPROVED AMT.</th>
                        <th>DELAY DAYS</th>
                        <th>SENT BY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expenseList?.length > 0 ? (
                        expenseList?.map((data, index) => {
                          return (
                            <tr
                              style={{
                                backgroundColor:
                                  data?.chat_status > 0
                                    ? "yellow"
                                    : data?.ssc_user_send_back_to_requestor_remarks
                                    ? "#FFBF00"
                                    : data?.expense_exception
                                    ? "#FFA895"
                                    : "",
                              }}
                            >
                              <td>{index + 1}</td>
                              {finalUser.groups.map(
                                (gdata, idx) =>
                                  gdata.name.toLowerCase() == "ssc head" && (
                                    <td>
                                      <label class="logCheck d-inline-block">
                                        <input
                                          type="checkbox"
                                          name="permission"
                                          onClick={(e) => {
                                            selectDataToPost(e, data);
                                          }}
                                          checked={data?.checked === "Y"}
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                  )
                              )}
                              <td>
                                <Link
                                  to={"/travel-check-expense?id=" + data?.id}
                                >
                                  <button class="btn btn-primary-inner bpi-main px-2 mr-2 py-1 f-14">
                                    <i class="far fa-check-circle"></i> Check
                                  </button>
                                </Link>
                                <Link
                                  to={`/travel-view-list?${data.id}`}
                                  target="_blank"
                                >
                                  <button class="btn btn-blanktd text-primary">
                                    <i class="fas fa-print"></i>
                                  </button>
                                </Link>
                              </td>

                              <td>{data?.emp_code}</td>
                              <td>{data?.emp_name}<br/>{data?.bmobile}</td>
                              <td>
                                {/* <Link to={""}> */}
                                {data?.sap_trip_number}
                                {/* </Link> */}
                              </td>
                              <td>
                                {data?.chat_status > 0 && (
                                  <button
                                    class="btn btn-primary bpi-main px-3 py-1 f-14"
                                    style={{ marginLeft: "3px" }}
                                    onClick={() =>
                                      handleShowModalTwo(`${data?.id}`)
                                    }
                                  >
                                    Query
                                  </button>
                                )}
                              </td>

                              {data?.chat_status > 0 && (
                                <Modal
                                  show={
                                    modalState === `query-modal-${data?.id}`
                                  }
                                  // onHide={addnewclose}
                                  onHide={handleCLOSE2}
                                  onShow={() => getTravelChat(data?.id)}
                                  backdrop="static"
                                  keyboard={false}
                                  size="lg"
                                  className="modaldefaultclose modaldefaultclosecenter"
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Query to Manager</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body
                                    style={{
                                      maxHeight: "calc(100vh - 210px)",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div class="row justify-content-center">
                                      <div class="col-md-11">
                                        <div class="row chtgptbox agent">
                                          {/* {travelchats?.length
                                          ? travelchats.map((cdata, idx) => (
                                              <div
                                                class={
                                                  cdata?.created_by_id !=
                                                  userDetails?.id
                                                    ? "col-md-12"
                                                    : "col-md-12 text-right"
                                                }
                                              >
                                                <div class="ticketreplytxt rounded text-break">
                                                  {cdata?.attachment && (
                                                    <a href={cdata?.attachment}>
                                                      <i class="far fa-file-pdf text-theme"></i>
                                                    </a>
                                                  )}
                                                  {cdata?.message}
                                                </div>
                                                <div class="mt-2">
                                                  <h6>
                                                    {moment
                                                      .parseZone(
                                                        cdata?.created_at
                                                      )
                                                      .format(
                                                        "DD-MM-YYYY hh:mm A"
                                                      )}
                                                  </h6>
                                                </div>
                                              </div>
                                            ))
                                          : ""} */}

                                          {travelchats?.length
                                            ? travelchats.map((cdata, idx) =>
                                                // <div class={ cdata?.created_by_id != userDetails?.id ? "col-md-12" : "col-md-12 text-right"}>
                                                //   <div class="ticketreplytxt rounded text-break">
                                                //   {cdata?.attachment && <a href={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></a>}
                                                //    {cdata?.message}

                                                //   </div>
                                                //   <div class="mt-2">
                                                //     <h6>{moment.parseZone(cdata?.created_at).format("DD-MM-YYYY hh:mm A")}</h6>
                                                //   </div>
                                                // </div>

                                                cdata?.created_by_id !=
                                                cdata?.travel_created_by_id ? (
                                                  <div
                                                    style={{
                                                      borderRight:
                                                        "solid 2px #2e6da4",
                                                    }}
                                                    class={
                                                      "mb-3 col-md-12 text-right"
                                                    }
                                                  >
                                                    <b>SSC</b>

                                                    <p>
                                                      {cdata?.message}
                                                      <br />
                                                      {cdata?.attachment && (
                                                        <Link
                                                          target="_blank"
                                                          to={cdata?.attachment}
                                                        >
                                                          <i class="far fa-file-pdf text-theme"></i>
                                                        </Link>
                                                      )}
                                                      {cdata?.attachment_list?.map(
                                                        (attdata, aidx) => (
                                                          <Link
                                                            target="_blank"
                                                            class="ml-2"
                                                            to={
                                                              attdata?.attachment_path
                                                            }
                                                          >
                                                            <i class="fas fa-paperclip text-theme"></i>
                                                          </Link>
                                                        )
                                                      )}

                                                      <small className="ml-2">
                                                        {moment
                                                          .parseZone(
                                                            cdata?.created_at
                                                          )
                                                          .format(
                                                            "DD-MM-YYYY hh:mm A"
                                                          )}
                                                      </small>
                                                    </p>
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      borderLeft:
                                                        "solid 2px #eea236",
                                                    }}
                                                    class={
                                                      "mb-3 col-md-12 text-left "
                                                    }
                                                  >
                                                    <b>User</b>

                                                    <p>
                                                      {cdata?.message}
                                                      <br />
                                                      {cdata?.attachment && (
                                                        <Link
                                                          target="_blank"
                                                          to={cdata?.attachment}
                                                        >
                                                          <i class="far fa-file-pdf text-theme"></i>
                                                        </Link>
                                                      )}
                                                      {cdata?.attachment_list?.map(
                                                        (attdata, aidx) => (
                                                          <Link
                                                            target="_blank"
                                                            class="ml-2"
                                                            to={
                                                              attdata?.attachment_path
                                                            }
                                                          >
                                                            <i class="fas fa-paperclip text-theme"></i>
                                                          </Link>
                                                        )
                                                      )}

                                                      <small className="ml-2">
                                                        {moment
                                                          .parseZone(
                                                            cdata?.created_at
                                                          )
                                                          .format(
                                                            "DD-MM-YYYY hh:mm A"
                                                          )}
                                                      </small>
                                                    </p>
                                                  </div>
                                                )
                                              )
                                            : ""}

                                          {finalUser.groups.map(
                                            (data, idx) =>
                                              data.name.toLowerCase() ==
                                                "ssc user" && (
                                                <>
                                                  <div class="col-md-12">
                                                    <div class="border my-3"></div>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <div class="form-group innergroup position-relative modaldefaultclose ">
                                                      <label>
                                                        Upload files
                                                      </label>
                                                      <input
                                                        type="text"
                                                        class="form-control bg-white"
                                                        disabled
                                                        placeholder="(JPG, PNG Format only)"
                                                      />
                                                      <div class="upload-btn-wrapper up-loposition">
                                                        <button class="uploadBtn">
                                                          Browse
                                                        </button>
                                                        <input
                                                          type="file"
                                                          onChange={(e) =>
                                                            onProfileChange(
                                                              e,
                                                              "import_user"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div class="col-md-12">
                                                    <div class="form-group innergroup">
                                                      <label>
                                                        Comment{" "}
                                                        <span class="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                      <textarea
                                                        id="querytext"
                                                        class="form-control"
                                                        placeholder="Enter Comment"
                                                        onChange={(e) =>
                                                          set_travelPostData({
                                                            ...travelPostData,
                                                            message:
                                                              e.target.value,
                                                          })
                                                        }
                                                      ></textarea>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  {finalUser.groups.map(
                                    (data, idx) =>
                                      data.name.toLowerCase() == "ssc user" && (
                                        <Modal.Footer>
                                          <button
                                            class="btn btn-outline-danger"
                                            // onClick={addnewclose}
                                            onClick={handleCLOSE2}
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            class="btn btn-primary-inner bpi-main"
                                            onClick={postTravelChat}
                                          >
                                            Submit
                                          </button>
                                        </Modal.Footer>
                                      )
                                  )}
                                </Modal>
                              )}
                              <td>
                                {data?.claim_date &&
                                  moment
                                    .parseZone(data?.claim_date)
                                    .format("DD-MM-YYYY HH:mm")}
                              </td>
                              <td>
                                {moment
                                  .parseZone(data?.is_picked_at)
                                  .format("DD-MM-YYYY HH:mm")}
                              </td>
                              <td>
                                {finalUser.groups.map((data1, idx) =>
                                  data1.name.toLowerCase() == "ssc manager"
                                    ? moment
                                        .parseZone(data?.ssc_user_approved_at)
                                        .format("DD-MM-YYYY")
                                    : data1.name.toLowerCase() == "ssc head"
                                    ? moment
                                        .parseZone(data?.courier_date)
                                        .format("DD-MM-YYYY")
                                    : ""
                                )}
                              </td>
                              <td>{data?.approver_total_amount?.toFixed(2)}</td>
                              {/* <td>{data?.delay_days}</td> */}
                              <td>
                                {/* 106398 Shubham Chheepa 2000021342  disp 20-12-2023 12:00  recon 29-12-2023 11:47 */}
                                {/* {data?.exp_appr_details.slice(-1)[0].processed_on} */}
                                {/* { moment(data?.exp_appr_details.slice(-1)[0].processed_on).diff(moment(data?.ssc_pick_receive_details[0]?.received_on, "YYYY-MM-DD"), "days")+" Days"} */}
                                {finalUser.groups.map((data1, idx) =>
                                  data1.name.toLowerCase() == "ssc manager"
                                    ? moment((data?.ssc_user_approved_at)?.split("T")[0]).diff(
                                        moment(
                                          (data?.is_picked_at)?.split("T")[0]
                                         
                                        ),
                                        "days"
                                      ) + " Days"
                                    : data1.name.toLowerCase() == "ssc head"
                                    ? moment(data?.courier_date).diff(
                                        moment(
                                          data?.is_picked_at,
                                          "YYYY-MM-DD"
                                        ),
                                        "days"
                                      ) + " Days"
                                    : ""
                                )}
                              </td>
                              <td>
                                {finalUser.groups.map((data1, idx) =>
                                  data1.name.toLowerCase() == "ssc manager"
                                    ? data?.picked_by_ssc_exec_fname +
                                      " " +
                                      data?.picked_by_ssc_exec_lname
                                    : data1.name.toLowerCase() == "ssc head"
                                    ? "Sanjay" + " " + "Saini"
                                    : ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>No Records Found...</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                        value={pageSizeNo}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={50} label={50}>
                          50
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                        <option value={200} label={200}>
                          200
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      key={pageSizeNo}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                {/* <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          
                          
                          { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc manager" &&<button
                            class="btn btn-primary-inner"
                            type="button"
                            // onClick={() => onDecline()}
                          >
                            <i class="far fa-check-circle"></i> Decline
                          </button>
                          )) }


                          { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc user" &&<button
                            class="btn btn-primary-inner"
                            type="button"
                            onClick={() => onReceive()}
                          >
                            <i class="far fa-check-circle"></i> Receive
                          </button>
                          )) }


                          
                        </div>
                      </div>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>

            <Select
              options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser(e, "")}
              value={filterdata?.company}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Employee Code <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser("", e)}
              value={filterdata?.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip Number <span class="text-danger"></span>
            </label>
            <Select
              // options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(e)=>setfilterData({
              //   ...filterdata,
              //   trip_number:e.value
              // })}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input type="date" class="form-control"
            onChange={(e)=>setfilterData({
              ...filterdata,
              from_date:e.currentTarget.value
            })}
            value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input type="date" class="form-control"
            onChange={(e)=>setfilterData({
              ...filterdata,
              to_date:e.currentTarget.value

            })}
            value={filterdata.to_date}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApproveTravelExpense;
