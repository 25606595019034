import React, { useState } from "react";
import Select from "react-select";
import * as MaterialService from "../../service/materialService";
import { toast } from "react-toastify";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import * as PrPurchaseGroup from "../../service/purchaseGroup";
import { useEffect } from "react";

const options = [
  { value: "Option1", label: "Option1" },
  { value: "Option2", label: "Option2" },
  { value: "Option3", label: "Option3" },
  { value: "Option4", label: "Option4" },
  { value: "Option5", label: "Option5" },
];

function SyncMaterial() {

  const dispatch = useDispatch();

  const [matCode, setMatCode] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [plantCode, setPlantCode] = useState(null)
  const [plantOptions, setPlantOptions] = useState([]);

  useEffect(()=>{
    getPlants()
  },[])

  const handleChange = (e, field_name) =>{
    if(field_name === "mat_code"){
        setMatCode(e.target.value)
    }
    if(field_name === "from_date"){
        setFromDate(e.target.value)
      }
    if(field_name === "to_date"){
        setToDate(e.target.value)
      }
      if(field_name === "plant_code"){
          let obj = e?.map((res)=>{
              return res?.code
            })
        setPlantCode(obj)
      }
  }

  const pullMatCode = () =>{
    dispatch(setDisplayLoader("Display"));
    let data = {"mat_code" : matCode, "plant_code" : plantCode, "from_date" : fromDate, "to_date" : toDate}
    MaterialService.pullMatCodeFromSap(data).then((response)=>{
      if(response?.data?.dataList?.response?.error === "Material Code Synced Successfully."){
      toast.success("Material Code Synced Successfully.")
    }
    else{
      toast.error(response?.data?.dataList?.response?.error)
    }
      dispatch(setDisplayLoader("Hide"));
    })
  }

  const getPlants = () => {
    PrPurchaseGroup.getPlants({ organisation_id: 0 })
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.map((item) => ({
            value: item.id,
            label: `${item.description}(${item.code})`,
            code: `${item.code}`,
          }));
          setPlantOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Sync Material</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      From Date<span class="text-danger"></span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      onChange={(e)=>{handleChange(e, "from_date")}}
                    />
                   
                  </div>
                </div>

                <div class="col-md-4">
                <div class="form-group innergroup">
                    <label>
                      To Date<span class="text-danger"></span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      onChange={(e)=>{handleChange(e, "to_date")}}
                    />
                   
                  </div>
                </div>
              </div>

              <div class="row">
                {/* <div class="col-md-12 fw-bold mb-3">OR</div> */}

                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>
                      Material Code<span class="text-danger"></span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Material Code"
                      onChange={(e)=>{handleChange(e, "mat_code")}}
                    />
                   
                  </div>
                </div>

                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>
                      Plant Code<span class="text-danger"></span>
                    </label>
                    <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={plantOptions}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    onChange={(e)=>{handleChange(e, "plant_code")}}
                  />
                   
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <button
                   class="btn btn-outline-danger mr-3"
                   type="button"
                   onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                   >
                    Cancel
                   </button>
                  <button class="btn btn-primary-inner bpi-main" type="button" onClick={pullMatCode}>
                    Pull Material
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SyncMaterial;
