import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function TravelExpense() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(200);
  const [pageCount, setPageCount] = useState(1);
  const [totalRecord, setTotalRecord] = useState();
  const [searchStr, setSearchStr] = useState("");
  const [travelList, setTravelList] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: "",
  });

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    company: { value: undefined },
    trip_number: "",
    from_date: "",
    to_date: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getChcekExpenseList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "P",
      filterdata?.user_id.value,
      filterdata?.company?.value,
      filterdata?.from_date,
      filterdata?.to_date
    );
  };

  const calculateDelayDeductionPercent = (calcDaya, traveluserDetails) => {
    if (traveluserDetails?.other_details?.date_of_joining) {
      let userr = traveluserDetails?.other_details;

      let claimDaysFromJoining = moment(traveluserDetails?.dispatch_date).diff(
        moment(userr?.date_of_joining, "YYYY-MM-DD"),
        "days"
      );
      let days = calcDaya + " Days";
      let deductedPercent = 100;
      console.log(`....`, days, claimDaysFromJoining);

      if (claimDaysFromJoining < 61) {
        days = "0 Days";
      }
      if (
        ["D1", "D2", "C1"].includes(userr?.emp_sub_group) ||
        userr?.sales_dist == "CONS"
      ) {
        days = "0 Days";
      }
      days = days.split(" ");
      if (days[0] <= 7) {
        deductedPercent = 0;
      } else if (days[0] >= 8 && days[0] <= 20) {
        deductedPercent = 5;
      }
      console.log(`ded per`, deductedPercent);
      return deductedPercent;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getUsersList();
    getAllOrganisation();
    getChcekExpenseList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "P",
      "",
      "",
      "",
      ""
    );
  }, []);

  useEffect(() => {
    getChcekExpenseList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "P",
      filterdata?.user_id.value,
      filterdata?.company?.value,
      filterdata?.from_date,
      filterdata?.to_date
    );
  }, [pageSizeNo]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setCurrentPage(1);

      getChcekExpenseList(
        e.target.value,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "P",
        filterdata?.user_id.value,
        filterdata?.company?.value,
        filterdata?.from_date,
        filterdata?.to_date
      );

      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    setCurrentPage(1);

    getChcekExpenseList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "P",
      filterdata?.user_id.value,
      filterdata?.company?.value,
      filterdata?.from_date,
      filterdata?.to_date
    );

    // getTravelPickList(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   filterdata?.user_id,
    //   filterdata?.company,
    //   filterdata?.trip_number,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   true

    //   )

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    setCurrentPage(1);

    getChcekExpenseList(searchStr, 1, pageSizeNo, sortData.sort_by, true, "P");
    setfilterData({
      user_id: { value: undefined },
      company: { value: undefined },
      from_date: "",
      to_date: "",

      trip_number: "",
    });
    setOrganisation_User({
      user_id: "",
      company: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
    });
  };

  const setCompantAndUser = (comp, uid) => {
    console.log(`setting`, comp, uid);

    if (comp) {
      // let mdoUser=companyDropdown.filter((x) => {
      //   return x.value == comp

      // })
      // console.log('comp..',mdoUser);

      // setOrganisation_User({
      //   ...organisation_User,
      //   company:mdoUser
      // })

      setfilterData({
        ...filterdata,
        company: comp,
      });
    }

    if (uid) {
      // let mdoUser=usersDropdown.filter((x) => {
      //   return x.value == uid

      // })
      // // console.log('uid..',mdoUser);

      // setOrganisation_User({
      //   ...organisation_User,
      //   user_id:mdoUser
      // })
      setfilterData({
        ...filterdata,
        user_id: uid,
      });
    }
  };

  const getChcekExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,

    from_date = "",
    to_date = ""
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCheckExpenseList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        company,
        from_date,
        to_date
      )
    ).then((response) => {
      setTravelList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&company=" + company;
    }
    return queryParm;
  };

  const getExportValue = (
    search,
    page_no,
    page_Size,
    sort_by,
    paginate,
    status,
    user_id,
    company,

    from_date,
    to_date
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCheckExpenseExportValue(
      handleKey(
        search,
        page_no,
        page_Size,
        sort_by,
        paginate,
        status,
        user_id,
        company,

        from_date,
        to_date
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li class="active"><Link to="/travel-expense">Check Expense</Link></li>
                                    <li><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-7">
                    <h4 class="inner-page-title">Check Expense</h4>
                  </div>
                  <div className="col-md-5">
                      <div className="d-flex" style={{
                        fontSize: '14px',
                        justifyContent: 'space-between'
                      }}>
                        {/* {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                          <> */}
                            <div>
                              {/* <span className="fw-bold text-theme ml-2">{each?.leave_name}</span> */}
                              {/* <ul className=""> */}
                              
                                <span ><i style={{color:"yellow"}} class='fas fa-square'></i> Query raised</span>
                                <span className="ml-4"><i style={{color:"#FFBF00"}} class='fas fa-square'></i> Send back to User</span>
                                <span className="ml-4"><i style={{color:"#FFA895"}} class='fas fa-square'></i> Expense Exception</span>
                              {/* </ul> */}
                            </div>
                            <br />
                          {/* </>
                        ))} */}
                      </div>
                    </div>

                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong> Name, SAP No.</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  false,
                                  "P",
                                  filterdata?.user_id.value,
                                  filterdata?.company?.value,
                                  filterdata?.from_date,
                                  filterdata?.to_date
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs defaultActiveKey="draft" id="approvaltabs">
                  <Tab
                    eventKey="draft"
                    title={
                      <React.Fragment>
                        In Process
                        <span class="numberpurchse">{totalRecord}</span>
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Sno.</th>
                            <th>Action</th>
                            <th>Employee ID</th>
                            <th>Name</th>
                            <th>Trip Number</th>
                            <th>Trip Start Date</th>
                            <th>Trip End Date</th>
                            <th>Document Dispatch date</th>
                            <th>Received On</th>
                            <th>Docket Number</th>
                            <th>Delay Days</th>
                          </tr>
                        </thead>
                        <tbody>
                          {travelList?.length > 0 ? (
                            travelList?.map((data, index) => {
                              return (
                                <tr
                                  style={{
                                    backgroundColor:
                                      data?.chat_status > 0
                                        ? "yellow"
                                        : data?.ssc_user_send_back_to_requestor_remarks
                                        ? "#FFBF00"
                                        : data?.expense_exception
                                        ? "#FFA895"
                                        : "",
                                  }}
                                >
                                  <td>{index + 1}</td>
                                  <td>
                                    <Link
                                      to={
                                        "/travel-check-expense?id=" +
                                        data?.travel_intimation_id
                                      }
                                    >
                                      <button class="btn btn-primary-inner bpi-main px-2 mr-2 py-1 f-14">
                                        <i class="far fa-check-circle"></i>{" "}
                                        Check
                                      </button>
                                    </Link>
                                    <Link
                                      to={`/travel-view-list?${data.travel_intimation_id}`}
                                      target="_blank"
                                    >
                                      <button class="btn btn-blanktd text-primary">
                                        <i class="fas fa-print"></i>
                                      </button>
                                    </Link>
                                  </td>
                                  <td>{data?.emp_code}</td>
                                  <td>{data?.emp_name}<br/>{data?.bmobile}</td>
                                  <td>{data?.sap_trip_numberr}</td>
                                  <td>
                                    {moment
                                      .parseZone(data?.from_date)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {moment
                                      .parseZone(data?.to_date)
                                      .format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {data?.courier_date
                                      ? moment
                                          .parseZone(data?.courier_date)
                                          .format("DD-MM-YYYY HH:mm")
                                      : data?.document_dispatch_date &&
                                        moment
                                          .parseZone(
                                            data?.document_dispatch_date
                                          )
                                          .format("DD-MM-YYYY HH:mm")}
                                  </td>
                                  <td>
                                    {moment
                                      .parseZone(data?.created_at)
                                      .format("DD-MM-YYYY HH:mm")}
                                  </td>
                                  <td>{data?.docket_number}</td>
                                  <td>
                                    {data?.document_dispatch_date &&
                                      moment(
                                        moment(
                                          data?.document_dispatch_date,
                                          "YYYY-MM-DD"
                                        )
                                      ).diff(
                                        moment(data?.to_date, "YYYY-MM-DD"),
                                        "days"
                                      ) +
                                        " Days " +
                                        " (" +
                                        calculateDelayDeductionPercent(
                                          moment(
                                            data?.document_dispatch_date
                                          ).diff(
                                            moment(
                                              moment(
                                                data?.to_date,
                                                "YYYY-MM-DD"
                                              ),
                                              "YYYY-MM-DD"
                                            ),
                                            "days"
                                          ),
                                          data
                                        ) +
                                        "%)"}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Records Found...</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            value={pageSizeNo}
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"20"}>20</option>
                            <option value={"30"}>30</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                            <option value={"200"}>200</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          key={pageSizeNo}
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>
            {/* <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>Jivagro Limited</option>
                            <option>PI Consultant</option>
                            <option>PI Industries Ltd.</option>
                        </select> */}

            <Select
              options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser(e, "")}
              value={filterdata?.company}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser("", e)}
              value={organisation_User?.user_id}
            />
          </div>

          {/* <div class="form-group innergroup">
                        <label>Trip Number <span class="text-danger"></span></label>
                        <Select  
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select" 
                        />
                    </div> */}

          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TravelExpense;
