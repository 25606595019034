import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as HeaderService from "../../service/jv_service";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DocumentHeader = (props) => {
  const {
    jvData,
    setJvData,
    setTabStatus,
    onAddCallback,
    companyCode,
    setCompanyCode,
    documentType,
    setDocumentType,
  } = props;

  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [companyFlag, setCompanyFlag] = useState(false);
  const [documentFlag, setDocumentFlag] = useState(false);
  const [formValidation, setFormValidation] = useState({
    doc_no: "",
    company_code: "",
    document_type: "",
    document_date: "",
    posting_date: "",
    document_header: "",
    reference: "",
  });

  const getCompanyCode = () => {
    HeaderService.getCompanyCode()
      .then((response) => {
        let data = response?.data?.dataList?.result.map((res, index) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        });
        setCompanyCodeData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDocumentType = (id) => {
    HeaderService.getDocumentType(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.document_desc + "(" + res.document_type + ")",
          };
        });
        setDocumentTypeData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const companyCodeList = (optionsCountryList) => {
  //   return optionsCountryList?.map((item, index) => (
  //     <option
  //       key={`company${index}`}
  //       value={`${item?.id}`}
  //       label={`${item?.name}  (${item?.code})`}
  //     />
  //   ));
  // };

  // const documentTypeList = (optionsCountryList) => {
  //   return optionsCountryList?.map((item, index) => (
  //     <option
  //       key={`document${index}`}
  //       value={`${item?.id}`}
  //       label={`${item?.document_desc} (${item?.document_type})`}
  //     />
  //   ));
  // };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!jvData.doc_no) {
      isError = true;
      formerr.doc_no = "*Required Document No. ";
      setFormValidation(formerr);
    }
    if (!jvData.company_code) {
      isError = true;
      formerr.company_code = "*Required Company Code ";
      setFormValidation(formerr);
    }
    if (!jvData.document_type) {
      isError = true;
      formerr.document_type = "*Required Document Type ";
      setFormValidation(formerr);
    }
    if (!jvData.document_date) {
      isError = true;
      formerr.document_date = "*Required Document Date ";
      setFormValidation(formerr);
    }
    if (!jvData.posting_date) {
      isError = true;
      formerr.posting_date = "*Required Posting Date ";
      setFormValidation(formerr);
    }
    if (!jvData.document_header) {
      isError = true;
      formerr.document_header = "*Required Document Header Text ";
      setFormValidation(formerr);
    }
    if (!jvData.reference) {
      isError = true;
      formerr.reference = "*Required Reference ";
      setFormValidation(formerr);
    }
    return isError;
  };

  const handleValidateTab = () => {
    if (handleValidate()) return false;
    setTabStatus("Personal");
  };

  useEffect(() => {
    getCompanyCode();
    window.scrollTo(0, 0);
  }, []);

  const handleCompanyCode = (e) => {
    setJvData({
      ...jvData,
      company_code: e.value,
    });
    setDocumentType("");
    if (e.value !== undefined) {
      getDocumentType(e.value);
    }
    setCompanyCode(e);
    onAddCallback(e);
    setFormValidation({
      ...formValidation,
      company_code: "",
    });
  };

  const handleDocumentType = (e) => {
    setJvData({
      ...jvData,
      document_type: e.value,
    });
    setDocumentType(e);
    setFormValidation({
      ...formValidation,
      document_type: "",
    });
  };

  return (
    <>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Document No<span class="text-danger">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter Document No."
              name="doc_no"
              maxLength={20}
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  doc_no: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  doc_no: "",
                });
              }}
              value={jvData.doc_no}
            />
            <div className="small text-danger">{formValidation.doc_no}</div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Company Code<span class="text-danger">*</span>
            </label>
            <Select
              options={companyCodeData}
              className="basic-multi-select"
              name="company_code"
              id="company_code"
              classNamePrefix="select"
              onChange={(e) => {
                handleCompanyCode(e);
                setCompanyFlag(true);
              }}
              value={companyCode}
            />
            <div className="small text-danger">
              {formValidation.company_code}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Document Type<span class="text-danger">*</span>
            </label>

            <Select
              options={documentTypeData}
              name="document_type"
              id="document_type"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                handleDocumentType(e);
                setDocumentFlag(true);
              }}
              value={documentType}
            />

            {/* <select
              className="form-control newbgselect main-pointer"
              classNamePrefix="select"
              name="document_type"
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  document_type: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  document_type: "",
                });
              }}
              value={jvData.document_type}
            >
              <option value="" label="Select Code" disabled />
              {documentTypeList(documentTypeData)}
            </select> */}
            <div className="small text-danger">
              {formValidation.document_type}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Document Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              placeholder="Enter Document Date"
              name="document_date"
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  document_date: e.target.value,
                  posting_date: "",
                });
                setFormValidation({
                  ...formValidation,
                  document_date: "",
                });
              }}
              value={jvData.document_date}
            />
            <div className="small text-danger">
              {formValidation.document_date}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Posting Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              placeholder="Enter Doc No"
              min={jvData.document_date}
              disabled={!jvData.document_date}
              name="posting_date"
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  posting_date: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  posting_date: "",
                });
              }}
              value={jvData.posting_date}
            />
            <div className="small text-danger">
              {formValidation.posting_date}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Document Header Text<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              maxLength={25}
              placeholder="Enter Document Header Text"
              name="document_header"
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  document_header: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  document_header: "",
                });
              }}
              value={jvData.document_header}
            />
            <div className="small text-danger">
              {formValidation.document_header}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Reference<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              maxLength={16}
              placeholder="Enter Reference"
              name="reference"
              onChange={(e) => {
                setJvData({
                  ...jvData,
                  reference: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  reference: "",
                });
              }}
              value={jvData.reference}
            />
            <div className="small text-danger">{formValidation.reference}</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-2">
          <Link to="/jv" class="btn btn-outline-secondary mr-3">
            <i class="far fa-arrow-alt-circle-left"></i> Back
          </Link>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => {
              handleValidateTab();
            }}
          >
            <i class="far fa-arrow-alt-circle-right"></i> Save & Next
          </button>
        </div>
      </div>
    </>
  );
};

export default DocumentHeader;
