import React, { useState, useEffect } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import * as TransDocService from "../../service/DocSurvice";
import AddDocType from "./AddDocumentType";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as moment from "moment";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";

function DocumentTypeIndex() {
  const [pageCount, setPageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [document, setDocument] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [countryDataFilter, setCountryDataFilter] = useState({ status: "" });
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    status: "",
  });

  const getTransDoc = () => {
    TransDocService.getTransDoc()
      .then((response) => {
        setData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getTransDoc();
  }, []);

  const addDoc = () => {
    setShowForm(true);
    setEditMode(false);
    setDocument(null);
  };

  const editDoc = (data) => {
    setShowForm(true);
    setEditMode(true);
    setDocument(data);
  };

  const onClose = () => {
    setShowForm(false);
    setDocument(null);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getTransDoc();
  };

  const deleteDepartment = (idx) => {
    TransDocService.deleteDepartment(idx)
      .then((res) => {
        if (res.data.code === 200) {
          getTransDoc();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, docName) => {
    confirmAlert({
      title: "Delete Data",
      message: `Are you sure to delete ${docName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDepartment(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Document Type Master</h4>

              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Document Type Master</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>

          <div className="row">
            <div class="col-lg-4">
              <div class="input-group table-search"></div>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <button
                  class="btn btn-primary-inner"
                  onClick={() => {
                    addDoc();
                  }}
                >
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured ">
            <thead>
              <tr>
                <th>#</th>
                <th>Document Type</th>
                <th>Document Desc.</th>
                <th>Approved Name</th>
                <th>Organization Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data?.map((data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data?.document_type}</td>
                    <td>{data?.document_desc}</td>
                    <td>{data?.approver_id} </td>

                    <td>{data?.organisation_id}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-blanktd text-primary"
                        onClick={() => {
                          editDoc(data);
                        }}
                      >
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                        <i
                          class="far fa-trash-alt text-danger"
                          onClick={() => {
                            submit(data?.id, data?.document_type);
                          }}
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* {countryList?.length == 0 ? (
          ""
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>10</option>
                  <option value={20} label={20}>20</option>
                  <option value={30} label={30}>30</option>
                  <option value={100} label={100}>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )} */}
      </div>

      {/* <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setCountryDataFilter({
                  ...countryDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={countryDataFilter.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true} label="Active" />
              <option value={false} label="In-Active" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal> */}

      {showForm ? (
        <>
          <AddDocType
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editDocument={document}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default DocumentTypeIndex;
