import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import * as moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewTravelApproval from "./ViewTravelApproval";
import TravelLinks from "./TravelLinks";

if (typeof window !== "undefined") {
  injectStyle();
}

function TravelListingApproval() {
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [draftListData, setDraftListData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentTab, setCurrentTab] = useState("DRAFT");
  const [allListData, setAllListData] = useState([]);
  const [findTravelId, setFindTravelId] = useState([]);
  const [travelListNew, setTravelListNew] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [statusData, setStatusData] = useState("");
  const [chkCheckedData, setChkCheckedData] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [checkData, setCheckData] = useState({
    id: [0],
    status: "",
  });

  const userDetails = useSelector(selectUserData);
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }

    return queryParm;
  };

  const saveMultiApprove = (data) => {
    let finaldata = [];
    if (data !== null && checkedId.length > 0) {
      if (checkedId?.length > 0) {
        console.log(checkedId, "okokok");
        finaldata = checkedId.map((obj) => {
          return {
            status: data,
            id: obj,
            remark: "Ok",
          };
        });
      }
      let payload = {
        approval_data: finaldata,
      };
      TravelListingAPI.saveMultiApprove(payload).then((response) => {
        setCheckedId([]);
        setCheckData({
          id: [0],
        });
        setCurrentTab("DRAFT");
        getTravelList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
        toast.success(response.data.message);
      });
    }
  };

  // const getTravelDataByIds = async (travel_id) => {
  //   let queryParam = {
  //     travel_id: travel_id,
  //   };
  //   await TravelListingAPIgetTravelDataById("travel_id=" + travel_id).then((response) => {
  //     //   console.log(response?.data?.dataList?.result[0]);
  //     setData(response?.data?.dataList?.result[0]);
  //   });
  // };
  const getTravelList = async (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id
  ) => {
    await TravelListingAPI.getTravelList(
      handleKey(search, page_no, page_size, sort_by, paginate, user_id)
    )
      .then((response) => {
        setAllListData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateCheckBoxValue = (e, value) => {
    let data = draftListData;
    data?.map((obj) => {
      if (Number(obj.id) === Number(value.id)) {
        obj.checkData = e.target.checked;
      }
    });
    setDraftListData(data);
    if (e.target.checked) {
      setCheckedId([...checkedId, value.transaction_id]);
    } else {
      const index = checkedId.indexOf(value.transaction_id);
      if (index > -1) {
        let data = checkedId;
        data.splice(index, 1);
        setCheckedId(data);
      }
    }
  };

  useEffect(() => {
    console.log(checkedId);
    if (checkedId?.length > 1) {
      setChkCheckedData(true);
    } else {
      setChkCheckedData(false);
    }
  }, [checkedId]);

  const deleteMultiApprove = (data) => {
    if (data !== null && checkedId?.length > 0) {
      checkData.id_list = checkedId;
      TravelListingAPI.deleteMultiApprove(checkData)
        .then((response) => {
          setCheckedId([]);
          setCheckData({
            id_list: [0],
          });
          // setCurrentTab("PENDING");
        })
        .catch((error) => {
          toast.error(error.response.data.detail);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  console.log(findTravelId, "findTravelId");
  const handleAllData = (e) => {
    if (e.target.checked === true) {
      let data = [];
      let finalData = allListData;
      finalData.map((fd, i) => {
        fd["checkData"] = e.target.checked;
        data.push(fd.id);
      });
      setCheckedId(data);
    } else {
      let finalData = allListData;
      finalData.map((fd, i) => {
        fd["checkData"] = e.target.checked;
      });
      setCheckedId([]);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getTravelList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const submit = () => {
    confirmAlert({
      title: "Delete Travel ",
      message: `Are you sure to delete Travel ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMultiApprove(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const submitPosting = (data) => {
    confirmAlert({
      message: `Are you sure to Submit Travel Approval" ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => saveMultiApprove(data),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const getCommonApproval = async () => {
    await TravelListingAPI.getCommonApproval(
      findTravelId,
      1,
      10,
      "-created_at",
      "true",
      ""
    )
      .then((response) => {
        setTravelListNew(response?.data?.dataList?.result?.approvals?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCommonApprovalTravel = async () => {
    await TravelListingAPI.getCommonApprovalTravel(1, 10, "-created_at", "true")
      .then((response) => {
        setFindTravelId(
          response?.data?.dataList?.result
            ?.filter((obj) => {
              if (obj?.type_name === "Travel And Expense") {
                return true;
              }
            })[0]
            ?.sub_type?.filter((x) => {
              if (x?.sub_type_name == "Travel Intimation") {
                return true;
              }
            })[0]?.sub_type_id
        );
        // setCityDataTo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  }, [pageSizeNo]);
  useEffect(() => {
    getCommonApprovalTravel();
  }, []);
  useEffect(() => {
    getCommonApproval();
  }, [findTravelId]);

  // function checkReasonForException(data) {
  //   console.log(data, "checkReasonForException1");
  //   for (let i = 0; i < data?.length; i++) {
  //     const obj = data[i];
  //     const travelDetails = obj.details?.travel_detail_data;

  //     // Check if travelDetails exists and is an array
  //     if (Array.isArray(travelDetails)) {
  //       // Check if any element has reason_for_exception_name as null
  //       const hasNullReason = travelDetails.some(
  //         (detail) => detail.reason_for_exception_name === null
  //       );
  //       console.log(hasNullReason, "checkReasonForException2");
  //       if (hasNullReason) {
  //         // If any element has reason_for_exception_name as null, return an empty array
  //         return [];
  //       }
  //     } else {
  //       // If travelDetails is not an array, return an empty array
  //       return [];
  //     }
  //   }

  //   // If all elements have reason_for_exception_name as non-null, return the main array
  //   return data;
  // }

  // console.log(checkReasonForException, "checkReasonForException");

  // useEffect(() => {
  //   checkReasonForException(travelListNew);
  // }, [travelListNew]);

  const filterClose = () => {
    filterclose();
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      {viewForm ? (
        <>
          <ViewTravelApproval
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        <>
          <ToastContainer autoClose={1000} />
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-9">
                      <h4 class="inner-page-title">Travel Approval</h4>
                    </div>

                    <div class="col-md-12">
                      <div class="border-top mt-2 mt-md-4 mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                  <Tabs
                    unmountOnExit
                    id="approvaltabs"
                    onSelect={(e) => {
                      setCurrentTab(e);
                    }}
                    activeKey={currentTab}
                  >
                    <Tab
                      eventKey="DRAFT"
                      title={
                        <React.Fragment>
                          Travel
                          {currentTab === "DRAFT" && (
                            <span class="numberpurchse">
                              {/* allListData.filter((obj) => {
                                  if (obj?.status === "P") {
                                    return true;
                                  }
                                }
                                
                                ) */}
                              {travelListNew?.length}
                            </span>
                          )}
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>
                                <label class="logCheck d-inline-block">
                                  <input
                                    type="checkbox"
                                    name="permission"
                                    onClick={(e) => {
                                      handleAllData(e);
                                    }}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </th>
                              <th>Action</th>
                              <th>Name</th>
                              <th>From</th>
                              <th>Till</th>
                              <th>Advance</th>
                              <th>Location</th>
                              <th>Days</th>
                              <th>Deviation</th>
                              <th>Status</th>
                              <th>Approver Name</th>
                              <th>Approver Remark</th>
                              <th>Request Type</th>
                              <th>Requested On</th>

                              {/* <th>Advance</th>
                              <th>Location</th> */}

                              {/* <th>Approval Remarks</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {travelListNew?.length ? (
                              travelListNew
                                ?.filter(
                                  (ele) =>
                                    ele.reason_for_exception_name !== null
                                )
                                ?.map((item, idx) => (
                                  <tr>
                                    {/* {item?.travel_detail_data[0]?.user_name ===
                                    userDetails?.full_name
                                      ? "iugfvb"
                                      : "+++++"} */}
                                    <td>
                                      <label class="logCheck d-inline-block">
                                        <input
                                          type="checkbox"
                                          name="permission"
                                          id={"permission" + idx}
                                          onClick={(e) => {
                                            updateCheckBoxValue(e, item);
                                          }}
                                          defaultChecked={item.checkData}
                                        />
                                        <span class="checkmark"></span>
                                      </label>
                                    </td>
                                    <td>
                                      <button
                                        class="btn btn-dangerNew"
                                        style={{
                                          color: chkCheckedData && "gray",
                                        }}
                                        disabled={chkCheckedData === true}
                                        onClick={(e) => {
                                          submitPosting("D");
                                        }}
                                      >
                                        <i class="far fa-times-circle"></i>
                                      </button>
                                      <button
                                        class="btn btn-successNew"
                                        style={{
                                          color: chkCheckedData && "gray",
                                        }}
                                        disabled={chkCheckedData === true}
                                        onClick={() => {
                                          submitPosting("A");
                                        }}
                                      >
                                        <i class="far fa-check-circle"></i>
                                      </button>
                                    </td>
                                    <td>{item?.created_by_name}</td>
                                    <td>
                                      {item?.details?.travel_detail_data
                                        ?.length > 0 &&
                                        moment(
                                          item?.details?.travel_detail_data[
                                            item?.details?.travel_detail_data
                                              ?.length - 1
                                          ]?.from_date
                                        ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {item?.details?.travel_detail_data
                                        ?.length > 0 &&
                                        moment(
                                          item?.details?.travel_detail_data[0]
                                            ?.to_date
                                        ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>{item?.details?.advance_amount}</td>
                                    <td>
                                      {item?.request_type == "On Duty"
                                        ? item?.details?.travel_detail_data[0]
                                            ?.to_city_name
                                        : item?.details?.travel_detail_data[0]
                                            ?.to_city_name}
                                    </td>
                                    <td>
                                      {item?.details?.travel_detail_data
                                        ?.length > 0 &&
                                        calculateDateDifference(
                                          item?.details?.travel_detail_data[0]
                                            ?.to_date,
                                          item?.details?.travel_detail_data[
                                            item?.details?.travel_detail_data
                                              ?.length - 1
                                          ]?.from_date
                                        )}
                                    </td>
                                    <td>{"-"}</td>

                                    <td>
                                      {item?.details?.status == "A" ? (
                                        <span class="badge bad-status badge-success">
                                          Approved{" "}
                                        </span>
                                      ) : item?.details?.status == "P" ||
                                        item?.details?.status == "PENDING" ||
                                        item?.details?.status == null ? (
                                        <span class="badge bad-status badge-warning">
                                          Pending{" "}
                                        </span>
                                      ) : item?.details?.status ==
                                        "Cancelled" ? (
                                        <span class="badge bad-status badge-danger">
                                          Cancelled{" "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>{item?.request_at}</td>
                                    <td>{"-"}</td>
                                    <td
                                      class="text-theme fw-bold"
                                      onClick={() => {
                                        onView(item?.details);
                                      }}
                                    >
                                      {item?.details?.request_type}
                                    </td>
                                    <td>
                                      {moment(item?.created_at).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan={18}> No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select
                              class="form-control"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>
                                10
                              </option>
                              <option value={20} label={20}>
                                20
                              </option>
                              <option value={30} label={30}>
                                30
                              </option>
                              <option value={100} label={100}>
                                100
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>

                      <div class="row jvactionsbutton">
                        <div class="col-md-12">
                          <div class="p-3">
                            <button
                              class="btn btn-success"
                              onClick={() => {
                                if (checkedId.length === 0) {
                                  toast.error("Please select atleast one Data");
                                } else {
                                  submitPosting("A");
                                }
                              }}
                            >
                              <i class="far fa-check-circle"></i>
                              Approve
                            </button>
                            {/* <button
                              class="btn btn-danger"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                if (checkedId.length === 0) {
                                  toast.error("Please select atleast one Data");
                                } else {
                                  submitPosting("R");
                                }
                              }}
                            >
                              <i class="far fa-check-circle"></i> Reject
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TravelListingApproval;
