import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as PolicyCategoryService from "../../../service/policyCategory";

function SustainPolicy() {
  const [category, setCategory] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [policy1, setPolicy1] = useState([]);

  useEffect(() => {
    PolicyCategoryService.getPolicyByCategory()
      .then((response) => {
        setPolicy(response?.data);
        setPolicy1(response?.data);
        let categories = [];
        response?.data?.map((x) => {
          let data = { category: x?.catgory, subcategory: x?.subcategoryList };
          categories.push(data);
        });
        setCategory(categories);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, []);

  const filterPolicy = (id, flag, catgoery_id) => {
    if (flag == "cat") {
      let data = JSON.parse(JSON.stringify(policy1));

      console.log(id);
      let index = "";
      data?.map((x) => {
        if (Number(x?.catgory?.id) == Number(id)) {
          let tab = document.getElementById(x?.catgory.id);
          if (tab != null) {
            tab.classList.add("active");
          }
          index = data.indexOf(x);
        } else {
          let element = document.getElementById(x?.catgory.id);
          if (element != null) {
            element.className = "";
          }
        }
        x?.subcategoryList?.map((y) => {
          console.log(y);
          let element = document.getElementById(y.id + "sub");
          if (element != null) {
            element.className = "";
          }
        });
      });

      if (index > -1) {
        let data2 = data.splice(index, 1);
        data.unshift(data2[0]);
      }
      setPolicy(data);
    } else {
      let data2 = JSON.parse(JSON.stringify(policy1));
      let index = "";
      data2?.map((x) => {
        if (Number(x?.catgory?.id) == Number(catgoery_id)) {
          x?.policies.map((y) => {
            let tab = document.getElementById(id + "sub");
            if (tab != null) {
              tab.classList.add("active");
            }

            if (Number(y?.fields?.subcategory) != Number(id)) {
              index = x?.policies?.indexOf(y);
              x?.policies?.splice(index, 1);
              let element = document.getElementById(
                y?.fields?.subcategory + "sub"
              );
              if (element != null) {
                element.className = "";
              }
            }
          });
        }
      });
      setPolicy(data2);
    }
  };
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            {category?.map((x) => (
              <>
                <Link
                  to="/userpolicy"
                  state={{
                    id: Number(x?.category?.id),
                  }}
                >
                  <li id={x?.category?.id}>{x?.category?.name}</li>
                </Link>
              </>
            ))}
            <li className="active">
              <Link to="/sus-policy">Sustainability Policy</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row mb-3">
                <div class="col-md-12">
                  <h3 class="policyhead">Sustainability Policy</h3>
                </div>
                <div class="col-md-12">
                  <div class="admin-guide-data pt-0 custom-policy-txt">
                    <h5>
                      <strong>BACKGROUND</strong>
                    </h5>
                    <p>
                      Our Company's corporate philosophy as embedded in us by
                      our Founder, requires that our business activities and
                      objectives must be served on the tenets of good corporate
                      citizenship. We serve the needs of our business based on
                      transparency, integrity, sustainability, innovation and on
                      our commitment to our stake holders and society. This
                      entails our commitment to the principles of sound
                      corporate governance and setting of highest standards of
                      ethical performance for ourselves. We recognise that the
                      concept of good corporate citizenship is an evolving one,
                      and we accordingly evolve our business practices to keep
                      pace with the changing requirements: yet it is equally
                      true that the basic tenets of good corporate citizenship
                      do not change. This policy statement has been developed to
                      articulate clear guidelines pertaining to our obligations
                      and expectations and this statement is applicable to all
                      our Company divisions, our employees and to our
                      relationship with our stake holders.
                    </p>

                    <p>
                      We recognise that the concept of good corporate
                      citizenship is an evolving one, and we accordingly evolve
                      our business practices to keep pace with the changing
                      requirements: yet it is equally true that the basic tenets
                      of good corporate citizenship do not change.
                    </p>

                    <p>
                      This policy statement has been developed to articulate
                      clear guidelines pertaining to our obligations and
                      expectations and this statement is applicable to all our
                      Company divisions, our employees and to our relationship
                      with our stake holders.
                    </p>

                    <h5>
                      <strong>POLICY STATEMENT</strong>
                    </h5>

                    <ul>
                      <li>
                        We are an ethical company dedicated to ensuring that we
                        use only those resources required to achieve our
                        legitimate business objectives, while doing what we can
                        to conserve existing natural resources such as to ensure
                        that there will be sufficient resources for future
                        generations, while also contributing to our communities
                        and the communities in which we and our suppliers and
                        customers live.
                      </li>
                      <li>
                        We shall conduct our business responsibly, with
                        integrity and with respect for the interest of all our
                        stakeholders who are directly or indirectly related to
                        our operations.
                      </li>
                      <li>
                        We shall uphold the values of trust, honesty and just
                        dealings in our relationship with all stakeholders.
                      </li>
                      <li>
                        While pursuing our business objectives, we shall always
                        endeavour to strike the right balance amongst economic
                        progress, environmental protection and social
                        responsibilities.
                      </li>
                      <li>
                        We shall adopt principles of Responsible Care and
                        encourage and support our partners to embrace these
                        principles.
                      </li>
                      <li>
                        Create conducive environment for the employees and
                        community in and around our business operations for
                        improvement in quality of life through enhanced
                        economic, educational and healthcare opportunities.
                      </li>
                      <li>
                        Continually improve and enhance our capabilities through
                        innovative approach to deploy sustainable technology
                        solutions and efficient use of scarce and natural
                        resources.
                      </li>
                      <li>
                        We shall always comply with, and exceed, where
                        practicable, all applicable legislation, regulations and
                        codes of practice.
                      </li>
                      <li>
                        It is the responsibility of each employee of the company
                        to ensure that these principles are upheld across our
                        business operations and commercial relationships, and
                        that each employee serves as an ambassador for the
                        company in all our communities.
                      </li>
                    </ul>

                    <h5>
                      <strong>OUR COMMITMENT</strong>
                    </h5>

                    <p>
                      The company stands committed to its responsibility towards
                      society and our goal is to ensure that our economic growth
                      is socially and environmentally sustainable. Our business
                      efforts would be towards inclusive development and we aim
                      that the "people grow with us and we grow with people". We
                      attract, nurture and help people grow.
                    </p>

                    <h5>
                      <strong>
                        OUR MODALITIES OF EXECUTION OF SUCH PROJECTS ENVIRONMENT
                        AND REGULATORY COMPLIANCE
                      </strong>
                    </h5>

                    <ul>
                      <li>
                        We are committed to provide total environment safety &
                        industrial hygiene measures, excellent housekeeping,
                        congenial working environment and regulatory compliance.
                        Our factory, plant.
                      </li>
                      <li>
                        Facilities fully compliant with regulatory approvals
                        backed with strong environment management
                      </li>
                      <li>
                        Best possible recycling & recovery of by-products and
                        solvents
                      </li>
                      <li>
                        Excellent housekeeping and congenial working environment
                      </li>
                      <li>
                        Innovate processes to minimize waste, in order to
                        achieve high standards of environmental safety
                      </li>
                      <li>Continuous efforts for Energy Saving</li>
                      <li>Conserve and manage water efficiently</li>
                      <li>Soothing landscaping and greenery</li>
                    </ul>

                    <h5>
                      <strong>HEALTH & SAFETY COMPLIANCE</strong>
                    </h5>
                    <h6>PI Industries Limited is committed:</h6>

                    <ul>
                      <li>
                        To adopt sound manufacturing practices to minimize
                        pollutants, occupational health and safety risks
                      </li>
                      <li>
                        To continuously work for cost effective technological
                        improvements to minimize risks to human beings, property
                        and reduce adverse environmental impact
                      </li>
                      <li>
                        To continually improve health, safety and environmental
                        performance
                      </li>
                      <li>
                        To inculcate safety culture in its people and processes
                        on a continuous basis
                      </li>
                      <li>
                        To provide extensive training to farmers on safe &amp;
                        judicious handling and use of pesticides
                      </li>
                    </ul>

                    <h5>
                      <strong>
                        Social Responsibility and Community Development
                      </strong>
                    </h5>
                    <h6>
                      Our continuous and active participation in various social
                      upliftment and rural development programes are targeted
                      t07
                    </h6>
                    <ul>
                      <li>
                        Increase agricultural productivity, and enable national
                        food security
                      </li>
                      <li>Raise the income of the farmers</li>
                      <li>
                        Conserve and manage water efficiently for irrigational
                        purposes
                      </li>
                      <li>
                        Capacity building, farm extension services, and
                        empowerment of farmers.
                      </li>
                      <li>
                        Provide support,including financial assistance to
                        various NGOs and Voluntary Organisations to aid
                        community development programmes
                      </li>
                      <li>
                        Support and provide resources for activities that
                        promote sports, art, culture, education, and health.
                      </li>
                      <li>
                        Take up projects and support vocational and skills
                        development for youth to enable their employability.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SustainPolicy;
