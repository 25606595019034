import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from "react-select";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as PrServices from '../../../service/prService';
import { Link, useNavigate, useLocation } from "react-router-dom";

const SubItemForm = (props) => {
  const location = useLocation();
  const { handleClose, open, formikProps, formIndex, SUB_ITEM_INITIAL,
    glOptions, unitOptions, orderOptions, costCenterOptions, subitemflag, subItemIndex, createSubItemFlag, networkOptions,itemNO, sapPrNo } = props;
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const SUB_ITEM_VALIDATION = Yup.array().of(Yup.object().shape({
    // line: Yup.number().typeError('Must be a valid number').required('This field is required'),
    // service_no: Yup.number().typeError('Must be a valid number').required('This field is required'),
    short_text: Yup.string().required('This field is required'),
    quantity: Yup.number().typeError('Must be a valid number').required('This field is required'),
    unit: Yup.string().required('This field is required'),
    gross_price: Yup.number().typeError('Must be a valid number').required('This field is required'),
    currency: Yup.string().required('This field is required'),
    // net_value: Yup.number().typeError('Must be a valid number').required('This field is required'),
    gl_account_id: Yup.string().required('This field is required')
  })).required();


  const getData = async () => {
    try {
      /* fetch gl codes */
      props.getGlCodes();

      /* fetch cost centers */
      props.getCostCenters();

      /* fetch currencies */
      const currencyRes = await PrServices.getCurrencyList();
      if (currencyRes.data.code === 200) {
        const list = currencyRes?.data?.dataList?.result;
        const options = list.map(item => ({ value: item.id, label: `${item.description}(${item.code})` }));
        setCurrencyOptions(options);
      } else {
        toast.warn(currencyRes.data.message);
      }

      /* fetching units */
      props.getPrUnits();

    } catch (error) {
      console.log('error', error)
      toast.warn(error.message);
    }
  }

  console.log("jhgfghjuhgfvcdxcfghj",formikProps,formIndex)
  const formik = useFormik({
    initialValues: formikProps.values[formIndex]?.sub_transaction_data ?? SUB_ITEM_INITIAL,
    validationSchema: SUB_ITEM_VALIDATION,
    onSubmit: (dataValues, { setFieldError }) => {
      try {
        const { values, setValues } = formikProps;
        if (values[formIndex].account_assignment == "Order") {
          // for (const i in dataValues) {
          //   if (!dataValues[i].order_no)
          //     return setFieldError(i, { order_no: 'This field is required' });
          // }
        }
        const temp = [...values];
        if(subitemflag === false){
        temp[formIndex].sub_transaction_data = [...dataValues];
        }
        else{
          temp[subItemIndex].sub_transaction_data = [...dataValues];
        }
        console.log("saving subitem",temp);
        setValues(temp);
        handleClose();
      } catch (error) {
        console.log('error in sub item form save ==>>>>', error);
      }
    }
  });

  const { handleSubmit, getFieldProps, setValues, setFieldValue, values, errors, touched } = formik;
  console.log('errors==>>', errors)

  const handleAddSubItem = () => {
    // if (Object.keys(errors).length > 0) {
    //   console.log("=======>>>>>>>>>>>>>>",Object.keys(errors));
      // showToast && toast.warn("Please complete current form before add new");
    //   return false;
    // }
    console.log("subitem values",values,SUB_ITEM_INITIAL);
    setEditMode(false)
    setValues(prev => [...prev, { ...SUB_ITEM_INITIAL[0] }], false);
    console.log("subitem values1212121",values,SUB_ITEM_INITIAL);
  }

  const handleDeleteSubItem = (index) => {
    // values.splice(index, 1);
    // const temp = [...formikProps.values];
    // temp[formIndex].sub_transaction_data = [...values]
    // setValues([...temp]);
    const temp = [...values];
    temp.splice(index, 1);
    setValues(temp, false);
  }
  const handleEditSubItem = (index) =>{
    console.log("errors sub item", errors);
    // setViewPrFlag(true)
    if(location?.state?.createSubItemFlag !== true){
    if (Object.keys(errors).length!=0){
      values.pop()
    }
  }
    setEditIndex(index)
    setEditMode(true)
  }

  const getSelectFeilds = (options, index, key) => {
    return {
      value: options ? options.find(option => option.value === values[index][key]) : '',
      onChange: (option) => setFieldValue(`${index}.${key}`, option?.value)
    }
  }

  const handleNetValue = (e,index,key) =>{
    setFieldValue(`${index}.${"gross_price"}`, e.target.value)
    setFieldValue(`${index}.${key}`, values[index]["gross_price"] * values[index]["quantity"])
  }

  useEffect(() => {
    if (open || subitemflag)
      getData();
      console.log("werfdyhgsvbhxcgbvdbsxhdgb",open,subitemflag)
  }, [open]);

  const handleServiceNoChange = (e,index) =>{
    let service_no = e.target.value
    if(e.key === "Enter"){
      PrServices.getServiceNo(service_no).then((response)=>{
        let obj = response?.data?.dataList?.result
        setFieldValue(`${index}.${'short_text'}`, obj[0]?.asktx)
      })
    }
  }

  return (
    <div >
      <Modal
        show={open || subitemflag}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        {/* <form onSubmit={handleSubmit}> */}
          <Modal.Header closeButton>
            <Modal.Title>Add/Edit Sub Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-4'></div>
          <div className="col-md-2">
            <span className="fw-bold" style={{color:"#0195d4"}}>SAPPR No- {sapPrNo}</span>
            </div>
            <div className="col-md-4">
            <span className="fw-bold" style={{color:"#0195d4"}}>Item No- {itemNO}</span>
            </div>
            </div>
            <div className="row justify-content-center mt-3">
              {/* Header Table */}
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Line No.</th>
                      <th>Service No.</th>
                      <th>Short Text</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Gross Price</th>
                      <th>Currency</th>
                      <th>Net Value</th>
                      <th>GL Account</th>
                      <th>Order No.</th>
                      <th>Cost Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      values.map((item, i) => (
                        <tr>
                          <td>
                            {sapPrNo === undefined || sapPrNo === null || sapPrNo === '' && (
                            <>
                            <button className="btn btn-blanktd text-primary f-12" onClick={()=>{handleEditSubItem(i)}}>
                              <i className="far fa-edit"></i>
                            </button>
                            {
                              values.length > 1 &&
                              <span className="btn btn-blanktd text-danger ml-2 f-12" onClick={() => handleDeleteSubItem(i)}>
                                <i className="far fa-trash-alt text-danger"></i>
                              </span>
                            }
                            </>
                          )}
                          </td>
                          <td>
                            <span className="badge badge-secondary">{(i+1)*10}</span>
                          </td>
                          <td>{item.service_no}</td>
                          <td>{item.short_text}</td>
                          <td>{item.quantity}</td>
                          <td>{item.unit_name}</td>
                          <td>{item.gross_price}</td>
                          <td>{item?.currency_desc} ({item?.currency_code})</td>
                          <td>{item.quantity * item.gross_price}</td>
                          <td>{item.gl_acc_short_desc}</td>

                          <td>{item.order_desc} {item.order_name}</td>
                          <td>{item.cost_center_desc} - {item.cost_center_code}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              {/* Header Table */}

              <div className="row align-items-center w-100">
                <div className="col-md-12 text-sm-right">
                {sapPrNo === undefined || sapPrNo === null || sapPrNo === '' && (
                  <div
                    className="btn btnblanktd text-primary"
                    onClick={handleAddSubItem}
                  >
                    <i className="far fa-plus-square mr-2"></i>
                    Add Sub Item More
                  </div>
                  )}
                </div>
              </div>
              {/* Looping through dynamic form */}
              { (open ||
                (subitemflag === true && (editMode === true || createSubItemFlag === true))) &&
                values.map(
                  (item, index) => (
                    ((editMode!== true && index == values.length-1) || (editMode === true && index == editIndex)) &&(
                    // return (
                      <div className="col-md-12" key={`sub_item_${index + 1 * values.length}`}>
                        <div>
                          <div className="bg-light px-3 pt-3 mb-3">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Line<span className="text-danger"></span>
                                  </label>
                                  <input
                                    {...getFieldProps(`${index}.line`)}
                                    disabled
                                    value={(index+1)*10}
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Line"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.line && errors[index]?.line}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Service No.<span className="text-danger"></span>
                                  </label>
                                  <input
                                    {...getFieldProps(`${index}.service_no`)}
                                    type="number"
                                    className="form-control"
                                    placeholder="Service No."
                                    onKeyUp={(e)=>{handleServiceNoChange(e,index)}}
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.service_no && errors[index]?.service_no}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Short Text<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    {...getFieldProps(`${index}.short_text`)}
                                    type="text"
                                    // disabled
                                    className="form-control"
                                    placeholder="Enter Short Text"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.short_text && errors[index]?.short_text}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Quantity<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    {...getFieldProps(`${index}.quantity`)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Quantity"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.quantity && errors[index]?.quantity}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Unit<span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={unitOptions}
                                    {...getSelectFeilds(unitOptions, index, 'unit')}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.unit && errors[index]?.unit}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Gross Price<span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    {...getFieldProps(`${index}.gross_price`)}
                                    className="form-control"
                                    placeholder="Enter Gross Price"
                                    // onChange={(e)=>{handleNetValue(e,index,"net_value")}}
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.gross_price && errors[index]?.gross_price}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Currency<span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={currencyOptions}
                                    {...getSelectFeilds(currencyOptions, index, 'currency')}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.currency && errors[index]?.currency}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>Net Value<span className="text-danger"></span></label>
                                  <input
                                    type="text"
                                    disabled
                                    value={values[index]?.gross_price * values[index]?.quantity}
                                    className="form-control"
                                    placeholder="Enter Net Value"
                                    // {...getFieldProps(`${index}.net_value`)}
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.net_value && errors[index]?.net_value}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    GL Account<span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={glOptions}
                                    classNamePrefix="select"
                                    className="basic-multi-select"
                                    {...getSelectFeilds(glOptions, index, 'gl_account_id')}
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.gl_account_id && errors[index]?.gl_account_id}
                                  </div>
                                </div>
                              </div>

                              {
                                (formikProps?.values[formIndex]?.account_assignment == 'K' || formikProps?.values[formIndex]?.account_assignment == 'F' || formikProps?.values[formIndex]?.account_assignment == 'N') &&
                              <div className="col-md-4">
                                <div className="form-group innergroup">
                                  <label>
                                    Cost Center{" "}
                                   <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={costCenterOptions}
                                    {...getSelectFeilds(costCenterOptions, index, 'cost_center_id')}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                  <div className="small text-danger">
                                    {touched[index]?.cost_center_id && errors[index]?.cost_center_id}
                                  </div>
                                </div>
                              </div>
                              }
                              {
                                formikProps?.values[formIndex]?.account_assignment == 'F' &&
                                <div className="col-md-4">
                                  <div className="form-group innergroup">
                                    <label>Order no.<span className="text-danger"></span></label>
                                    <Select
                                      options={orderOptions}
                                      {...getSelectFeilds(orderOptions, index, 'order_no')}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                    <div className="small text-danger">
                                      {touched[index]?.order_no && errors[index]?.order_no}
                                    </div>
                                  </div>
                                </div>
                              }
                               {
                                formikProps?.values[formIndex]?.account_assignment == 'N' &&
                                <div className="col-md-4">
                                  <div className="form-group innergroup">
                                    <label>Network no.<span className="text-danger"></span></label>
                                    <Select
                                      options={orderOptions}
                                      {...getSelectFeilds(orderOptions, index, 'network_no_id')}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                                    <div className="small text-danger">
                                      {touched[index]?.network_no_id && errors[index]?.network_no_id}
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        {
                          values.length > 1 &&
                          <center>
                            <span class="btn btn-outline-danger mr-2 mb-3" onClick={() => handleDeleteSubItem(index)}>
                              Remove
                            </span>
                          </center>
                        }
                      </div>
                    )
                )
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={handleSubmit} className="btn btn-primary-inner bpi-main">Save</button>
          </Modal.Footer>
        {/* </form> */}
      </Modal>
    </div >
  )
}

export default SubItemForm