import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";
import { getbl, getExportGLData } from "../../service/glService";
import ReactPaginate from "react-paginate";
import { getGlCodeList, getUserRmList } from "../../service/jv_service";
import { useLocation } from "react-router-dom";
import {
  selectUserData,
  selectFinalUrlPermission,
  setDisplayLoader
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";


function GIApprovral() {
  const dispatch = useDispatch();
  let location = useLocation();
  const [userid, setUserid] = useState(0);
  const [reviewerid, setReviewerid] = useState(0);
  const [level, setLevel] = useState("User");

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [filterdata, setfilterData] = useState({
    approver: { value: 0 },
    // requester: { value: 0 },
    glcode: { value: 0 },
  });
  const resetFilterData = () =>
    setfilterData({
      approver: { value: 0 },
      // requester: { value: 0 },
      glcode: { value: 0 },
    });
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [search, setSearch] = useState("");
  const [userlist, setuserlist] = useState([]);
  const [glcode, setGlcode] = useState([]);
  const [BLData, setGLData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [filterApplied, setFilterApplied] = useState(false);
  const [currentTab, setCurrentTab] = useState("P");
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const getBL = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    search,
    status,
    glcode,
    level,
    user_id,
    blcode,
    month,
    year,
    approvers
  ) => {
    dispatch(setDisplayLoader("Display"));

    getbl(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      search,
      status,
      glcode,
      level,
      user_id,
      blcode,
      month,
      year,
      approvers
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setGLData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setDataCount(response?.data?.dataList?.paginated_data?.approval_count);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      currentTab,
      0,
      "User",
      userData?.id,
      0,
      "",
      "",
      0
    );
    getUserList();
    Glcode();
  }, []);

  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const Glcode = () => {
    getGlCodeList()
      .then((response) => {
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x.id };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      currentTab,
      filterdata?.glcode?.value,
      level,
      userid,
      0,
      "",
      "",
      filterdata?.approver.value
    );
  };
  const changeCurrentTab = (action) => {
    // setUserid(userData?.id)
    // setReviewerid(userData?.id)
    // setLevel(level)
    let level = "";
    let userid = 0
    let reviewerid = 0
    if (action == "M") {
      setReviewerid(userData?.id)
      reviewerid = userData?.id
      action = ""
    }
    else {
      userid = userData?.id
      setUserid(userData?.id)

    }
    if (action == "P") {
      level = "User";
      // setLevel(level)

    }
    if (action == "c") {
      level = "Reviewer";
      setLevel(level)
      action = "P";
    }
    setUserid(userid)
    setReviewerid(reviewerid)
    setLevel(level)
    setCurrentTab(action);

    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      action,
      0,
      level,
      userid,
      0,
      "",
      "",
      reviewerid
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getBL(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        e.target.value.toLowerCase(),
        currentTab,
        filterdata?.glcode.value,
        level,
        userid,
        0,
        "",
        "",
        filterdata?.approver.value
      );
      // getBL(
      //   currentPage,
      //   pageSizeNo,
      //   "-id",
      //   true,
      //   e.target.value.toLowerCase(),
      //   currentTab,
      //   0,
      //   "",
      //   userData?.id,
      //   "",
      //   "",
      //   0
      // );
      setSearch(e.target.value);
    }
  };
  const resetFilter = () => {
    setFilterApplied(false);
    console.log(level);
    filterclose();
    resetFilterData();
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      currentTab,
      filterdata?.glcode.value,
      level,
      userid,
      0,
      "",
      "",
      filterdata?.approver.value
    );
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      currentTab,
      filterdata?.glcode.value,
      level,
      userid,
      0,
      "",
      "",
      filterdata?.approver.value
    );
    // getGlapprover(currentPage, pageSizeNo, "-id", true, "", true,filterdata?.glcode.value,filterdata?.approver.value,filterdata?.requester.value);
    filterclose();
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "glcode":
        setfilterData((prev) => ({
          ...prev,
          glcode: event,
        }));
        break;
    }
  };

  const getExportDetails = () => {
    getExportGLData(
      handleKey(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        currentTab,
        filterdata?.glcode.value,
        level,
        userid,
        0,
        "",
        "",
        filterdata?.approver.value
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GLApprovalBalanceSheet.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  const handleKey = (
    page_no,
    page_size,
    sort_by,
    paginate,
    search,
    status,
    glcode,
    level,
    user_id,
    blcode,
    month,
    year,
    approvers
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (
      glcode !== "" &&
      glcode !== undefined &&
      glcode !== null
    ) {
      queryParm = queryParm + "&glcode=" + glcode;
    }
    if (level !== "" && level !== undefined && level !== null) {
      queryParm = queryParm + "&level=" + level;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (blcode !== "" && blcode !== undefined && blcode !== null) {
      queryParm = queryParm + "&blcode=" + blcode;
    }
    if (month !== "" && month !== undefined && month !== null) {
      queryParm = queryParm + "&month=" + month;
    }
    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (approvers !== "" && approvers !== undefined && approvers !== null) {
      queryParm = queryParm + "&approvers=" + approvers;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/GlMapping">Mapped GL</Link>
            </li>
            <li>
              <Link to="/gi-balancesheet">GL Balancesheet</Link>
            </li>
            <li class="active">
              <Link to="/gi-approval">GL Balancesheet Approval</Link>{" "}
              <span class="numberpurchse active float-right mr-2">
                {dataCount}
              </span>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">GL Balancesheet Approval</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                onSelect={(e) => changeCurrentTab(e)}
                defaultActiveKey="P"
                id="approvaltabs"
              >
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      For My Approval
                      <span class="numberpurchse">{dataCount}</span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>BL Code</th>
                          <th>GL Code</th>
                          <th>GL Desc</th>
                          <th>Sap Amount</th>
                          <th>Balance</th>
                          <th>Variance</th>
                          <th>Currency</th>
                          <th>FC Amount</th>
                          <th>Schedules</th>
                          <th>User Id</th>
                          <th>Reviewer Id</th>
                          <th>Status</th>
                          <th>Level</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BLData?.map((x) => (
                          <tr>

                            <td class="text-theme">
                              <Link
                                to="/approve-gi"
                                state={{ from: x?.id }}
                                class="fw-bold text-theme"
                              >
                                {x?.id}
                              </Link>
                            </td>
                            <td>{x?.gl_code}</td>
                            <td>{x?.Gl_desc}</td>
                            <td>{x?.sap_amount}</td>
                            <td>{x?.balance}</td>
                            <td>{x?.sap_amount - x?.balance}</td>
                            <td>{x?.currency}</td>
                            <td>{x?.fc_amount}</td>
                            <td>No</td>
                            <td>
                              <Tooltip title={x?.user_name + "(" + x?.user_username + ")"} position="bottom" >
                                {x?.user_name + "(" + x?.user_username + ")"}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title={x?.Reviewer_name + "(" + x?.Reviewer + ")"} position="bottom" >
                                {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                              </Tooltip></td>
                            <td>
                              {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{x?.level}</td>
                            <td>
                              {moment
                                .utc(x?.created_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="A"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>BL Code</th>
                          <th>GL Code</th>
                          <th>GL Desc</th>
                          <th>Sap Amount</th>
                          <th>Balance</th>
                          <th>Variance</th>
                          <th>Currency</th>
                          <th>FC Amount</th>
                          <th>Schedules</th>
                          <th>User Id</th>
                          <th>Reviewer Id</th>
                          <th>Status</th>
                          <th>Level</th>
                          <th>Created On</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BLData?.map((x) => (
                          <tr>
                            <td class="text-theme">
                              <Link
                                to="/approve-gi"
                                state={{ from: x?.id }}
                                class="fw-bold text-theme"
                              >
                                {x?.id}
                              </Link>
                            </td>
                            <td>{x?.gl_code}</td>
                            <td>{x?.Gl_desc}</td>
                            <td>{x?.sap_amount}</td>
                            <td>{x?.balance}</td>
                            <td>{x?.sap_amount - x?.balance}</td>
                            <td>{x?.currency}</td>
                            <td>{x?.fc_amount}</td>
                            <td>No</td>
                            <td>
                              <Tooltip title={x?.user_name + "(" + x?.user_username + ")"} position="bottom" >
                                {x?.user_name + "(" + x?.user_username + ")"}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title={x?.Reviewer_name + "(" + x?.Reviewer + ")"} position="bottom" >
                                {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                              </Tooltip></td>
                            <td>
                              {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{x?.level}</td>
                            <td>
                              {moment
                                .utc(x?.created_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                            <td>{x?.remarks}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="R"
                  title={<React.Fragment>Rejected</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>BL Code</th>
                          <th>GL Code</th>
                          <th>GL Desc</th>
                          <th>Sap Amount</th>
                          <th>Balance</th>
                          <th>Variance</th>
                          <th>Currency</th>
                          <th>FC Amount</th>
                          <th>Schedules</th>
                          <th>User Id</th>
                          <th>Reviewer Id</th>
                          <th>Status</th>
                          <th>Level</th>
                          <th>Created On</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BLData?.map((x) => (
                          <tr>
                            <td class="text-theme">
                              <Link
                                to="/approve-gi"
                                state={{ from: x?.id }}
                                class="fw-bold text-theme"
                              >
                                {x?.id}
                              </Link>
                            </td>
                            <td>{x?.gl_code}</td>
                            <td>{x?.Gl_desc}</td>
                            <td>{x?.sap_amount}</td>
                            <td>{x?.balance}</td>
                            <td>{x?.sap_amount - x?.balance}</td>
                            <td>{x?.currency}</td>
                            <td>{x?.fc_amount}</td>
                            <td>No</td>
                            <td>
                              <Tooltip title={x?.user_name + "(" + x?.user_username + ")"} position="bottom" >
                                {x?.user_name + "(" + x?.user_username + ")"}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title={x?.Reviewer_name + "(" + x?.Reviewer + ")"} position="bottom" >
                                {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                              </Tooltip></td>
                            <td>
                              {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{x?.level}</td>
                            <td>
                              {moment
                                .utc(x?.created_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                            <td>{x?.remarks}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="c"
                  title={<React.Fragment>Pending</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>BL Code</th>
                          <th>GL Code</th>
                          <th>GL Desc</th>
                          <th>Sap Amount</th>
                          <th>Balance</th>
                          <th>Variance</th>
                          <th>Currency</th>
                          <th>FC Amount</th>
                          <th>Schedules</th>
                          <th>User Id</th>
                          <th>Reviewer Id</th>
                          <th>Status</th>
                          <th>Level</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BLData?.map((x) => (
                          <tr>
                            <td class="text-theme">
                              <Link
                                to="/approve-gi"
                                state={{ from: x?.id }}
                                class="fw-bold text-theme"
                              >
                                {x?.id}
                              </Link>
                            </td>
                            <td>{x?.gl_code}</td>
                            <td>{x?.Gl_desc}</td>
                            <td>{x?.sap_amount}</td>
                            <td>{x?.balance}</td>
                            <td>{x?.sap_amount - x?.balance}</td>
                            <td>{x?.currency}</td>
                            <td>{x?.fc_amount}</td>
                            <td>No</td>
                            <td>
                              <Tooltip title={x?.user_name + "(" + x?.user_username + ")"} position="bottom" >
                                {x?.user_name + "(" + x?.user_username + ")"}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title={x?.Reviewer_name + "(" + x?.Reviewer + ")"} position="bottom" >
                                {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                              </Tooltip></td>
                            <td>
                              {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{x?.level}</td>
                            <td>
                              {moment
                                .utc(x?.created_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="M"
                  title={<React.Fragment>My GL</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>BL Code</th>
                          <th>GL Code</th>
                          <th>GL Desc</th>
                          <th>Sap Amount</th>
                          <th>Balance</th>
                          <th>Variance</th>
                          <th>Currency</th>
                          <th>FC Amount</th>
                          <th>Schedules</th>
                          <th>User Id</th>
                          <th>Reviewer Id</th>
                          <th>Status</th>
                          <th>Level</th>
                          <th>Created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BLData?.map((x) => (
                          <tr>

                            <td class="text-theme">
                              <Link
                                to="/approve-gi"
                                state={{ from: x?.id }}
                                class="fw-bold text-theme"
                              >
                                {x?.id}
                              </Link>
                            </td>
                            <td>{x?.gl_code}</td>
                            <td>{x?.Gl_desc}</td>
                            <td>{x?.sap_amount}</td>
                            <td>{x?.balance}</td>
                            <td>{x?.sap_amount - x?.balance}</td>
                            <td>{x?.currency}</td>
                            <td>{x?.fc_amount}</td>
                            <td>No</td>
                            <td>
                              <Tooltip title={x?.user_name + "(" + x?.user_username + ")"} position="bottom" >
                                {x?.user_name + "(" + x?.user_username + ")"}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title={x?.Reviewer_name + "(" + x?.Reviewer + ")"} position="bottom" >
                                {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                              </Tooltip></td>
                            <td>
                              {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending
                                </span>
                              ) : (
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              )}
                            </td>
                            <td>{x?.level}</td>
                            <td>
                              {moment
                                .utc(x?.created_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control">
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              GL Code<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select User<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Reviewer<span class="text-danger">*</span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Gl Code<span class="text-danger">*</span>
            </label>
            <Select
              name="glcode"
              options={glcode}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.glcode}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Requester<span class="text-danger">*</span>
            </label>
            <Select
              name="requester"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.requester}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div> */}
          <div class="form-group innergroup">
            <label>
              Approver Id<span class="text-danger">*</span>
            </label>
            <Select
              name="approver"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.approver}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GIApprovral;
