import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import * as SkillUsageService from "../../service/skillusage";
import * as SkillQuarterService from "../../service/skillquaterly";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import * as SkillService from "../../service/skill";
import ReactPaginate from "react-paginate";
import Select from "react-select";

function SkillUsage() {
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [permissionAccess, setPermissionAccess] = useState({});
  const [skillList, setSkillList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [allYear, setAllYear] = useState([]);
  const [allDept, setAllDept] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [showSkillTab, setShowSkillTab] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    year: "",
    quarter: "",
    department_id: "",
  });

  const handleKey = (pageNo, page_size, sort_by, paginate, year, quarter, department_id) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (quarter !== "" && quarter !== undefined && quarter !== null) {
      queryParm = queryParm + "&quarter=" + quarter;
    }
    if (department_id !== "" && department_id !== undefined && department_id !== null) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    return queryParm;
  };

  const getSkillTab = (pageNo, page_size, sort_by, paginate, year, quarter, department_id) => {
    SkillUsageService.getSkillTab(
      handleKey(pageNo, page_size, sort_by, paginate, year, quarter, department_id)
    )
      .then((response) => {
        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSkillTabForDept = (pageNo, page_size, sort_by, paginate, year, quarter) => {
    SkillUsageService.getSkillTab(
      handleKey(pageNo, page_size, sort_by, paginate, year, quarter)
    )
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.department_id,
            label: res?.department_name
          }
        })
        let jsonObject = obj.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setAllDept(uniqueArray)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    pageNo,
    page_size,
    sort_by,
    paginate,
    year,
    quarter,
    department_id
  ) => {
    SkillUsageService.getExportValue(
      handleKey(pageNo, page_size, sort_by, paginate, year, quarter, department_id)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-usage.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillTab(activePage, pageSizeNo, "-id", true);
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllYear = () => {
    SkillQuarterService.getAllYear()
      .then((response) => {
        setAllYear(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option key={`opt${index}`} value={`${item}`} label={`${item}`} />
    ));
  };

  const filterShow = () => {
    filteropen();
    getAllYear();
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter(ClearData);
    filterClose();
    getSkillTab(1, pageSizeNo, "-id", true);
  };

  const mainDataFilter = () => {
    const year = dataFilter?.year;
    const quarter = dataFilter?.quarter;
    const department_id = dataFilter?.department_id
    getSkillTab(1, pageSizeNo, "-id", true, year, quarter, department_id);
    filterclose();
  };

  const data = ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"];

  useEffect(() => {
    getSkillPermission();
    getSkillTab(1, pageSizeNo, "-id", true);
    getSkillTabForDept(1, pageSizeNo, "-id", false);
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li class="active">
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}

              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill Matrix Usage</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-12">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  1,
                                  pageSizeNo,
                                  "-id",
                                  true,
                                  dataFilter?.year,
                                  dataFilter?.quarter,
                                  dataFilter?.department_id
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Quarter</th>
                      <th>Sup ID</th>
                      <th>Sup Description</th>
                      <th>Employee ID</th>
                      <th>Employee Name</th>
                      <th>Department Name</th>
                      <th>Previous Level</th>
                      <th>Actual Level</th>
                      <th>Skill Code</th>
                      <th>Skill Level</th>
                      <th>Status</th>
                      <th>Reason</th>
                      <th>Created On</th>
                      <th>Created By</th>
                      <th>Sup Master Status</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td class="text-theme fw-bold">{data?.year}</td>
                            <td>{data?.quarter}</td>
                            <td>{data?.sup_id}</td>
                            <td>{data?.sup_desc}</td>
                            <td>{data?.employee_code}</td>
                            <td>{data?.employee_name}</td>
                            <td>{data?.department_name}</td>
                            <td>{data?.planned_level}</td>
                            <td>{data?.actual_level}</td>
                            <td>{data?.skill_Id}</td>
                            <td>{data?.current_level}</td>
                            <td>{data?.status}</td>
                            <td>{data?.reason}</td>
                            <td>{data?.created_at}</td>
                            <td>{data?.created_by}</td>
                            <td>{data?.sup_master_status}</td>
                            <td>{data?.remarks}</td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>10</option>
                        <option value={20} label={20}>20</option>
                        <option value={30} label={30}>30</option>
                        <option value={100} label={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Year</label>
            <select
              name="year"
              className="form-control newbgselect"
              onChange={(e) => {
                setDataFilter({
                  ...dataFilter,
                  year: e.target.value,
                });
              }}
              value={dataFilter?.year}
            >
              <option value="">Select</option>
              {renderOptions(allYear)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Quarter</label>
            <select
              className="form-control newbgselect"
              name="quarter"
              onChange={(e) => {
                setDataFilter({
                  ...dataFilter,
                  quarter: e.target.value,
                });
              }}
              value={dataFilter?.quarter}
            >
              <option value="">Select</option>
              <option value="Jan-Mar">January-March</option>
              <option value="Apr-Jun">April-June</option>
              <option value="Jul-Sep">July-September</option>
              <option value="Oct-Dec">October-December</option>
              <option value={data}>All</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>
              Department<span class="text-danger"></span>
            </label>
            <Select
              options={allDept}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select"
              value={selectedDept}
              onChange={(e) => {
                setSelectedDept(e)
                setDataFilter({
                  ...dataFilter,
                  department_id: e.value
                });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter()}
            className="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SkillUsage;
