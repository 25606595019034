import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { setCloseHeader, selectProfileStatus } from "../redux/piDataStore";
import {
  selectOrgData,
  selectUserData,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { logoutUser } from "../../service/userService";

function Asidemenu({ myRightsData }) {
  const orgData = useSelector(selectOrgData);
  const userData = useSelector(selectUserData);
  const sideBarData = useSelector(selectProfileStatus);
  const [submenuexpand, setActive] = useState();
  const [mepiul, mepiulsetActive] = useState();
  const [cmsul, cmsulsetActive] = useState();
  const [roleul, roleulsetActive] = useState();
  const { instance, accounts } = useMsal();
  const [addactiveclass, addactiveActive] = useState();
  const [addactiveclasscms, addactivecmsActive] = useState();
  const [addactiveclassrole, addactiveroleActive] = useState();
  const [sideData, setSideData] = useState({});
  const [barList, setBarList] = useState([]);
  const [leftmenuopen, setleftmenuActive] = useState();
  const [essleftmenuopen, setEssleftmenuActive] = useState();
  const [businessLeftmenuActive, setBusinessleftmenuActive] = useState();

  const [agrileftmenuActive, setAgrileftmenuActive] = useState();
  const [myMarketleftmenuActive, setMyMarketleftmenuActive] = useState();
  const [myStrategyleftmenuActive, setMyStrategyleftmenuActive] = useState();
  const [planleftmenuActive, setPlanleftmenuActive] = useState();
  const [activeMenu, setActiveMenu] = useState({});
  const [userProfile, setUserProfile] = useState([]);
  const [rootMenuIconChange, setRootMenuIconChange] = useState();
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const logoutHandle = async () => {
    dispatch(setDisplayLoader("Display"));
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        if (localStorage.getItem("fromAd") === "true") {
          handleLogout("redirect");
          localStorage.clear();
        } else {
          window.location.replace("/custom");
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("delegatedLogin");
        }
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };
  const opensidesubmenu = () => {
    setActive(true);
    mepiulsetActive(true);
    addactiveActive(true);
    cmsulsetActive(false);
    addactivecmsActive(false);
    roleulsetActive(false);
    addactiveroleActive(false);
  };

  const opensidesubmenucms = () => {
    setActive(false);
    mepiulsetActive(false);
    addactiveActive(false);
    cmsulsetActive(true);
    addactivecmsActive(true);
    roleulsetActive(false);
    addactiveroleActive(false);
  };

  const opensidesubmenurole = () => {
    setActive(true);
    mepiulsetActive(false);
    addactiveActive(false);
    cmsulsetActive(false);
    addactivecmsActive(false);
    roleulsetActive(true);
    addactiveroleActive(true);
  };

  const closesidesubmenu = () => {
    setActive(false);
    mepiulsetActive(false);
    addactiveActive(false);
    cmsulsetActive(false);
    addactivecmsActive(false);
    roleulsetActive(false);
    addactiveroleActive(false);
    setleftmenuActive(false);
    setEssleftmenuActive(false);
    setAgrileftmenuActive(false);
    setMyMarketleftmenuActive(false);
    setMyStrategyleftmenuActive(false);
    setPlanleftmenuActive(false);
    setBusinessleftmenuActive(false);
  };

  const leftmentClickopen = () => {
    setleftmenuActive(true);
  };
  const leftmenuEssClickopen = () => {
    setEssleftmenuActive(true);
    setBusinessleftmenuActive(false);
  };
  const leftmenuAgriClickopen = () => {
    setAgrileftmenuActive(true);
  };
  const leftmenuMyMarketClickopen = () => {
    setMyMarketleftmenuActive(true);
    setMyStrategyleftmenuActive(false);
  };
  const leftmenuMyStrategyClickopen = () => {
    setMyStrategyleftmenuActive(true);
  };
  const leftmenuPlanClickopen = () => {
    setPlanleftmenuActive(true);
  };

  const leftmenuBusinessClickopen = () => {
    setBusinessleftmenuActive(true);
    setEssleftmenuActive(false);
  };

  const onHandleRootMenu = () => {
    setBusinessleftmenuActive(false);
    setMyStrategyleftmenuActive(false);
    setEssleftmenuActive(false);
    setleftmenuActive(false);
  };

  useEffect(() => {
    let dataValue = [];
    barList?.LEFT_M?.map((items) => dataValue.push({ items }));
    if (activeMenu.name === dataValue.name) {
      opensidesubmenucms();
    }
  }, [activeMenu]);

  useEffect(() => {
    if (userData == undefined) return;
    let jsonData = {};
    userData?.groups?.map((item) => {
      jsonData[item?.name] = item;
    });
    setUserProfile(jsonData);
    setRootMenuIconChange(userData);
  }, [userData]);
  useEffect(() => {
    setBarList(myRightsData?.result);
  }, [myRightsData]);

  useEffect(() => {
    if (sideBarData === false) {
      // closesidesubmenu();
    }
  }, [sideBarData]);

  return (
    <>
      <aside>
        <div className="sidelogo">
          {/* <Link to=""> */}
          <img src={rootMenuIconChange?.logo} alt="" />
          {/* </Link> */}
        </div>
        <ul>
          {userProfile["Region Manager"] ||
          userProfile["Zone Manger"] ||
          userProfile["Territory Manager"] ? (
            <>
              {/* <li className={agrileftmenuActive ? "leftmenuclickopen" : ""}>
                <div onClick={leftmenuAgriClickopen}>
                  <img src={process.env.PUBLIC_URL+"/images/agri_photo.png"} alt="" />
                  <p>
                    Agri
                    <br />
                    Portal
                  </p>
                </div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img src={process.env.PUBLIC_URL+"/images/backbtn.png"} alt="" />
                    </div>
                    <>
                      <ul>
                        <li>
                          <img
                            src={process.env.PUBLIC_URL+"/images/agri_photo.png"}
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src={process.env.PUBLIC_URL+"/images/hover_agri.png"}
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Agri Business Transaction
                        </li>
                        <li className="divider">...</li>
                        {barList?.AGRI_M?.map((items) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setAgrileftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setAgrileftmenuActive(false);
                              }
                            }}
                          >
                            <img
                              src={items?.icon_url}
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src={items?.hover_icon_url}
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            {items.name}
                          </li>
                        ))}
                      </ul>
                      {barList?.AGRI_M?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            {items?.child?.map((values, idx) => (
                              <li>
                                {values?.url_type === "INTR" ? (
                                  <Link
                                    to={values.url}
                                    state={{ permission: values }}
                                    eventKey={values.name}
                                    onClick={() => setAgrileftmenuActive(false)}
                                    key={idx}
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </Link>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={values.url}
                                    rel="noreferrer"
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      ))}
                    </>
                  </div>
                </div>
              </li> */}

              <li className={myMarketleftmenuActive ? "leftmenuclickopen" : ""}>
                <div onClick={leftmenuMyMarketClickopen}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/my market-blue.png"}
                    alt=""
                  />
                  <p>
                    My
                    <br />
                    Market
                  </p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <img
                            src="images\agri_photo.png"
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src="images\hover_agri.png"
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Agri Business Transaction
                        </li>
                        <li className="divider">...</li> */}
                        {barList?.AG_MAR?.map((items, index) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setMyMarketleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setMyMarketleftmenuActive(false);
                              }
                            }}
                          >
                            {items?.url_type === "INTR" ? (
                              <Link
                                to={items.url}
                                state={{ permission: items }}
                                eventKey={items.name}
                                onClick={() => setMyMarketleftmenuActive(false)}
                                key={index}
                              >
                                <img src={items.icon_url} alt="" />
                                {items.name}
                              </Link>
                            ) : (
                              <a
                                target="_blank"
                                href={items.url}
                                rel="noreferrer"
                                // rel="noreferrer"
                              >
                                <img
                                  src={items?.icon_url}
                                  alt=""
                                  className="leftmenuimgwith"
                                />
                                <img
                                  src={items?.hover_icon_url}
                                  alt=""
                                  className="leftmenuimgwithout"
                                />
                                {items.name}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                      {/* {barList?.AG_MAR?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            <li>
                              {items?.url_type === "INTR" ? (
                                <Link
                                  to={items.url}
                                  state={{ permission: items }}
                                  eventKey={items.name}
                                  onClick={() =>
                                    setMyMarketleftmenuActive(false)
                                  }
                                  key={index}
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </Link>
                              ) : (
                                <a
                                  target="_blank"
                                  href={items.url}
                                  rel="noreferrer"
                                  // rel="noreferrer"
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </a>
                              )}
                            </li>
                          </ul>
                        </>
                      ))} */}

                      {/* <ul
                  className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/cms-news">
                      <img src="images/icon/cms.svg" alt="" />
                      News
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="images/icon/cms.svg" alt="" />
                      Notice
                    </Link>
                  </li>
                </ul> */}
                      {/* <ul
                  className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/role">
                      <img src="images/icon/role.svg" alt="" />
                      Role & Permission
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <img src="images/icon/role.svg" alt="" />
                      User Management
                    </Link>
                  </li>
                </ul> */}
                    </>
                  </div>
                </div>
              </li>

              <li
                className={myStrategyleftmenuActive ? "leftmenuclickopen" : ""}
              >
                <div onClick={leftmenuMyStrategyClickopen}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/my strategy-blue.png"
                    }
                    alt=""
                  />
                  <p>
                    My
                    <br />
                    Strategy
                  </p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <img
                            src="images\agri_photo.png"
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src="images\hover_agri.png"
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Agri Business Transaction
                        </li>
                        <li className="divider">...</li> */}
                        {barList?.AG_STA?.map((items, index) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setMyStrategyleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setMyStrategyleftmenuActive(false);
                              }
                            }}
                          >
                            {items?.url_type === "INTR" ? (
                              <Link
                                to={items.url}
                                state={{ permission: items }}
                                eventKey={items.name}
                                onClick={() =>
                                  setMyStrategyleftmenuActive(false)
                                }
                                key={index}
                              >
                                <img src={items.icon_url} alt="" />
                                {items.name}
                              </Link>
                            ) : (
                              <a
                                target="_blank"
                                href={items.url}
                                rel="noreferrer"
                                // rel="noreferrer"
                              >
                                <img
                                  src={items?.icon_url}
                                  alt=""
                                  className="leftmenuimgwith"
                                />
                                <img
                                  src={items?.hover_icon_url}
                                  alt=""
                                  className="leftmenuimgwithout"
                                />
                                {items.name}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                      {/* {barList?.AG_STA?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            {console.log(items.url, "items")}
                            <li>
                              {items?.url_type === "INTR" ? (
                                <Link
                                  to={items.url}
                                  state={{ permission: items }}
                                  eventKey={items.name}
                                  onClick={() =>
                                    setMyStrategyleftmenuActive(false)
                                  }
                                  key={index}
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </Link>
                              ) : (
                                <a
                                  target="_blank"
                                  href={items.url}
                                  rel="noreferrer"
                                  // rel="noreferrer"
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </a>
                              )}
                            </li>
                          </ul>
                        </>
                      ))} */}

                      {/* <ul
                  className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/cms-news">
                      <img src="images/icon/cms.svg" alt="" />
                      News
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="images/icon/cms.svg" alt="" />
                      Notice
                    </Link>
                  </li>
                </ul> */}
                      {/* <ul
                  className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/role">
                      <img src="images/icon/role.svg" alt="" />
                      Role & Permission
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <img src="images/icon/role.svg" alt="" />
                      User Management
                    </Link>
                  </li>
                </ul> */}
                    </>
                  </div>
                </div>
              </li>

              <li className={planleftmenuActive ? "leftmenuclickopen" : ""}>
                <div onClick={leftmenuPlanClickopen}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/my plan-blue.png"}
                    alt=""
                  />
                  <p>
                    My
                    <br />
                    Plan
                  </p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <img
                            src="images\agri_photo.png"
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src="images\hover_agri.png"
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Agri Business Transaction
                        </li>
                        <li className="divider">...</li> */}
                        {barList?.AG_PLN?.map((items, index) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setPlanleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setPlanleftmenuActive(false);
                              }
                            }}
                          >
                            {items?.url_type === "INTR" ? (
                              <Link
                                to={items.url}
                                state={{ permission: items }}
                                eventKey={items.name}
                                onClick={() => setPlanleftmenuActive(false)}
                                key={index}
                              >
                                <img src={items.icon_url} alt="" />
                                {items.name}
                              </Link>
                            ) : (
                              <a
                                target="_blank"
                                href={items.url}
                                rel="noreferrer"
                                // rel="noreferrer"
                              >
                                <img
                                  src={items?.icon_url}
                                  alt=""
                                  className="leftmenuimgwith"
                                />
                                <img
                                  src={items?.hover_icon_url}
                                  alt=""
                                  className="leftmenuimgwithout"
                                />
                                {items.name}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                      {/* {barList?.AG_PLN?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            <li>
                              {items?.url_type === "INTR" ? (
                                <Link
                                  to={items.url}
                                  state={{ permission: items }}
                                  eventKey={items.name}
                                  onClick={() => setPlanleftmenuActive(false)}
                                  key={index}
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </Link>
                              ) : (
                                <a
                                  target="_blank"
                                  href={items.url}
                                  rel="noreferrer"
                                  // rel="noreferrer"
                                >
                                  <img src={items.icon_url} alt="" />
                                  {items.name}
                                </a>
                              )}
                            </li>
                          </ul>
                        </>
                      ))} */}

                      {/* <ul
                  className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/cms-news">
                      <img src="images/icon/cms.svg" alt="" />
                      News
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="images/icon/cms.svg" alt="" />
                      Notice
                    </Link>
                  </li>
                </ul> */}
                      {/* <ul
                  className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
                >
                  <li className="text-right" onClick={closesidesubmenu}>
                    <img src="images/backbtn.png" />
                  </li>
                  <li>
                    <Link to="/role">
                      <img src="images/icon/role.svg" alt="" />
                      Role & Permission
                    </Link>
                  </li>
                  <li>
                    <Link to="/user">
                      <img src="images/icon/role.svg" alt="" />
                      User Management
                    </Link>
                  </li>
                </ul> */}
                    </>
                  </div>
                </div>
              </li>
            </>
          ) : (
            " "
          )}

          {userProfile["Region Manager"] ||
          userProfile["Zone Manger"] ||
          userProfile["Territory Manager"] ||
          userProfile["Marketing Manager"] ? (
            " "
          ) : (
            <>
              <li>
                <div>
                  <Link to="/dashboard" className="d-block">
                    <img
                      src={process.env.PUBLIC_URL + "/images/Home-1.png"}
                      alt=""
                    />
                    <p>Home</p>
                  </Link>
                </div>
              </li>
              <li className={leftmenuopen ? "leftmenuclickopen" : ""}>
                <div onClick={leftmentClickopen}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/MENU-BLUE.png"}
                    alt=""
                  />
                  <p>About Company</p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <Link
                            to="/dashboard"
                            onClick={() => setleftmenuActive(false)}
                          >
                            <img
                              src="images/icon/home.svg"
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src="images/icon/home-hover.svg"
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            Home
                          </Link>
                        </li>
                        <li className="divider">...</li> */}

                        {barList?.LEFT_M?.map((items) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                dispatch(setCloseHeader("true"));
                                setActive(true);
                                setleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setleftmenuActive(false);
                              }
                            }}
                          >
                            <img
                              src={items.icon_url}
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src={items.hover_icon_url}
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            {items.name}
                          </li>
                        ))}
                        {/* <li onClick={() => logoutHandle()}>
                          <Link>
                            <img
                              src="images/icon/logout.svg"
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src="images/icon/logout-hover.svg"
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            Logout
                          </Link>
                        </li> */}
                      </ul>
                      {barList?.LEFT_M?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            {/* <li className="text-right" onClick={closesidesubmenu}>
                        <img src="images/backbtn.png" />
                      </li> */}
                            {items?.child?.map((values, idx) => (
                              <li>
                                {values?.url_type === "INTR" ? (
                                  <Link
                                    to={values.url}
                                    state={{ permission: values }}
                                    eventKey={values.name}
                                    onClick={() => {
                                      setleftmenuActive(false);
                                      closesidesubmenu();
                                    }}
                                    key={idx}
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </Link>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={values.url}
                                    rel="noreferrer"
                                    // rel="noreferrer"
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      ))}
                    </>
                  </div>
                </div>
              </li>

              <li className={essleftmenuopen ? "leftmenuclickopen" : ""}>
                <div onClick={leftmenuEssClickopen}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/ESS.png"}
                    alt=""
                  />
                  <p>
                    Employee
                    <br />
                    Self Service
                  </p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <img
                            src="/images/ess -white.png"
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src="/images/ess -blue.png"
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Employee Self Service
                        </li>
                        <li className="divider">...</li> */}
                        {barList?.ESS_M?.map((items) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setEssleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setEssleftmenuActive(false);
                              }
                            }}
                          >
                            <img
                              src={items?.icon_url}
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src={items?.hover_icon_url}
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            {items.name}
                          </li>
                        ))}
                      </ul>
                      {barList?.ESS_M?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            {items?.child?.map((values, idx) => (
                              <li>
                                {values?.url_type === "INTR" ? (
                                  <Link
                                    to={values.url}
                                    state={{ permission: values }}
                                    eventKey={values.name}
                                    onClick={() => setEssleftmenuActive(false)}
                                    key={idx}
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </Link>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={values.url}
                                    rel="noreferrer"
                                    // rel="noreferrer"
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      ))}

                      {/* <ul
                className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/cms-news">
                    <img src="images/icon/cms.svg" alt="" />
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src="images/icon/cms.svg" alt="" />
                    Notice
                  </Link>
                </li>
              </ul> */}
                      {/* <ul
                className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/role">
                    <img src="images/icon/role.svg" alt="" />
                    Role & Permission
                  </Link>
                </li>
                <li>
                  <Link to="/user">
                    <img src="images/icon/role.svg" alt="" />
                    User Management
                  </Link>
                </li>
              </ul> */}
                    </>
                  </div>
                </div>
              </li>

              <li className={businessLeftmenuActive ? "leftmenuclickopen" : ""}>
                <div onClick={leftmenuBusinessClickopen}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/Business Transaction.png"
                    }
                    alt=""
                  />
                  <p>
                    Business
                    <br />
                    Transactions
                  </p>
                </div>
                <div class="blackoverlay" onClick={closesidesubmenu}></div>
                <div
                  className={
                    submenuexpand ? "left-menu left-submenu" : "left-menu"
                  }
                >
                  <div className="leftmenubox">
                    <div
                      class="text-right closenewbtnmenu"
                      onClick={closesidesubmenu}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                        alt=""
                      />
                    </div>
                    <>
                      <ul>
                        {/* <li>
                          <img
                            src="/images/transaction-white.png"
                            alt=""
                            className="leftmenuimgwith"
                          />
                          <img
                            src="/images/transaction-blue.png"
                            alt=""
                            className="leftmenuimgwithout"
                          />
                          Business Transactions
                        </li>
                        <li className="divider">...</li> */}
                        {barList?.BT_M?.map((items) => (
                          <li
                            className={
                              items.name === activeMenu.name ? "active" : ""
                            }
                            onClick={() => {
                              if (items?.child?.length !== 0) {
                                setActiveMenu({ name: items.name });
                                setActive(true);
                                setBusinessleftmenuActive(true);
                              } else {
                                setActiveMenu({ name: items.name });
                                setActive(false);
                                setBusinessleftmenuActive(false);
                              }
                            }}
                          >
                            <img
                              src={items?.icon_url}
                              alt=""
                              className="leftmenuimgwith"
                            />
                            <img
                              src={items?.hover_icon_url}
                              alt=""
                              className="leftmenuimgwithout"
                            />
                            {items.name}
                          </li>
                        ))}
                      </ul>
                      {barList?.BT_M?.map((items, index) => (
                        <>
                          <ul
                            className={
                              items.name === activeMenu.name
                                ? "sub-menutxt"
                                : "sub-menutxt sub-menutxtnone"
                            }
                          >
                            {items?.child?.map((values, idx) => (
                              <li>
                                {values?.url_type === "INTR" ? (
                                  <Link
                                    to={values.url + "?createFlag=true"}
                                    state={{
                                      permission: values,
                                      createFlag: true,
                                    }}
                                    eventKey={values.name}
                                    onClick={() =>
                                      setBusinessleftmenuActive(false)
                                    }
                                    key={idx}
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </Link>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={values.url}
                                    rel="noreferrer"
                                    // rel="noreferrer"
                                  >
                                    <img src={values.icon_url} alt="" />
                                    {values.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      ))}

                      {/* <ul
                className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/cms-news">
                    <img src="images/icon/cms.svg" alt="" />
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src="images/icon/cms.svg" alt="" />
                    Notice
                  </Link>
                </li>
              </ul> */}
                      {/* <ul
                className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/role">
                    <img src="images/icon/role.svg" alt="" />
                    Role & Permission
                  </Link>
                </li>
                <li>
                  <Link to="/user">
                    <img src="images/icon/role.svg" alt="" />
                    User Management
                  </Link>
                </li>
              </ul> */}
                    </>
                  </div>
                </div>
              </li>
            </>
          )}
          {userProfile["Marketing Manager"] && (
            <li className={planleftmenuActive ? "leftmenuclickopen" : ""}>
              <div onClick={leftmenuPlanClickopen}>
                <img
                  src={process.env.PUBLIC_URL + "/images/my plan-blue.png"}
                  alt=""
                />
                <p>
                  My
                  <br />
                  Plan
                </p>
              </div>
              <div class="blackoverlay" onClick={closesidesubmenu}></div>
              <div
                className={
                  submenuexpand ? "left-menu left-submenu" : "left-menu"
                }
              >
                <div className="leftmenubox">
                  <div
                    class="text-right closenewbtnmenu"
                    onClick={closesidesubmenu}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/images/backbtn.png"}
                      alt=""
                    />
                  </div>
                  <>
                    <ul>
                      {/* <li>
                        <img
                          src="images\agri_photo.png"
                          alt=""
                          className="leftmenuimgwith"
                        />
                        <img
                          src="images\hover_agri.png"
                          alt=""
                          className="leftmenuimgwithout"
                        />
                        Agri Business Transaction
                      </li>
                      <li className="divider">...</li> */}
                      {barList?.AG_PLN?.map((items, index) => (
                        <li
                          className={
                            items.name === activeMenu.name ? "active" : ""
                          }
                          onClick={() => {
                            if (items?.child?.length !== 0) {
                              setActiveMenu({ name: items.name });
                              setActive(true);
                              setPlanleftmenuActive(true);
                            } else {
                              setActiveMenu({ name: items.name });
                              setActive(false);
                              setPlanleftmenuActive(false);
                            }
                          }}
                        >
                          {items?.url_type === "INTR" ? (
                            <Link
                              to={items.url}
                              state={{ permission: items }}
                              eventKey={items.name}
                              onClick={() => setPlanleftmenuActive(false)}
                              key={index}
                            >
                              <img src={items.icon_url} alt="" />
                              {items.name}
                            </Link>
                          ) : (
                            <a
                              target="_blank"
                              href={items.url}
                              rel="noreferrer"
                              // rel="noreferrer"
                            >
                              <img
                                src={items?.icon_url}
                                alt=""
                                className="leftmenuimgwith"
                              />
                              <img
                                src={items?.hover_icon_url}
                                alt=""
                                className="leftmenuimgwithout"
                              />
                              {items.name}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                    {/* {barList?.AG_PLN?.map((items, index) => (
                      <>
                        <ul
                          className={
                            items.name === activeMenu.name
                              ? "sub-menutxt"
                              : "sub-menutxt sub-menutxtnone"
                          }
                        >
                          <li>
                            {items?.url_type === "INTR" ? (
                              <Link
                                to={items.url}
                                state={{ permission: items }}
                                eventKey={items.name}
                                onClick={() => setPlanleftmenuActive(false)}
                                key={index}
                              >
                                <img src={items.icon_url} alt="" />
                                {items.name}
                              </Link>
                            ) : (
                              <a
                                target="_blank"
                                href={items.url}
                                rel="noreferrer"
                                // rel="noreferrer"
                              >
                                <img src={items.icon_url} alt="" />
                                {items.name}
                              </a>
                            )}
                          </li>
                        </ul>
                      </>
                    ))} */}

                    {/* <ul
                className={cmsul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/cms-news">
                    <img src="images/icon/cms.svg" alt="" />
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img src="images/icon/cms.svg" alt="" />
                    Notice
                  </Link>
                </li>
              </ul> */}
                    {/* <ul
                className={roleul ? "sub-menutxt" : "sub-menutxt sub-menutxtnone"}
              >
                <li className="text-right" onClick={closesidesubmenu}>
                  <img src="images/backbtn.png" />
                </li>
                <li>
                  <Link to="/role">
                    <img src="images/icon/role.svg" alt="" />
                    Role & Permission
                  </Link>
                </li>
                <li>
                  <Link to="/user">
                    <img src="images/icon/role.svg" alt="" />
                    User Management
                  </Link>
                </li>
              </ul> */}
                  </>
                </div>
              </div>
            </li>
          )}
          {barList?.ROOT_M?.map((value, index) => (
            <li onClick={() => onHandleRootMenu()}>
              {value?.url_type === "INTR" ? (
                <Link to={value?.url} className="d-block">
                  <img src={value?.icon_url} alt="" />
                  <p>{value?.name}</p>
                </Link>
              ) : (
                <a target="_blank" rel="noreferrer" href={value?.url}>
                  <img src={value?.icon_url} alt="" />
                  <p>{value?.name}</p>
                </a>
              )}
            </li>
          ))}
          {/* <li>
            <p>Poll</p>

            <div className="left-menu pollbox">
              <div className="leftmenubox">
                <div className="row">
                  <div className="col-7">
                    <h5>Poll of the week</h5>
                  </div>
                  <div className="col-5 text-center">
                    <button className="btn btn-primary btn-poll">Poll</button>
                  </div>
                </div>
                <h4>
                  What programming do you use during the coding interview ?
                </h4>
                <label className="logCheck">
                  Python
                  <input type="radio" name="statusupdate" />
                  <span className="checkmark"></span>
                </label>
                <label className="logCheck">
                  Javascript
                  <input type="radio" name="statusupdate" />
                  <span className="checkmark"></span>
                </label>
                <label className="logCheck">
                  Go
                  <input type="radio" name="statusupdate" />
                  <span className="checkmark"></span>
                </label>
                <div className="d-sm-flex align-items-center timelinecontent-sec mt-4">
                  <div className="d-flex team-multiple">
                    <div className="team-profile">
                      <img src="images/1.png" alt="" />
                    </div>
                    <div className="team-profile">
                      <img src="images/2.png" alt="" />
                    </div>
                    <div className="team-profile">
                      <img src="images/3.png" alt="" />
                    </div>
                  </div>
                  <div className="teamtxt">
                    <h5 className="mb-0 text-dark mw-100 d-flex align-items-center">
                      Total vote 34 <i className="fas mx-1 fa-circle f-11"></i> 4
                      days left
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <img src="images/help.png" alt="" />
            <p>Help</p>

            <div className="left-menu helpbox">
              <div className="leftmenubox">
                <div className="helpboxTxt">
                  <h4>Instructions</h4>
                  <ul>
                    <li>
                      <Link to="/">
                        <img src="images/pdf-icon.png" alt="" />{" "}
                        <span>Invoice 1</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src="images/pdf-icon.png" alt="" />{" "}
                        <span>Invoice 2</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src="images/pdf-icon.png" alt="" />{" "}
                        <span>Invoice 3</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src="images/pdf-icon.png" alt="" />{" "}
                        <span>Invoice 4</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <img src="images/pdf-icon.png" alt="" />{" "}
                        <span>Invoice 5</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li> */}
          {userProfile["Region Manager"] ||
          userProfile["Zone Manger"] ||
          userProfile["Territory Manager"] ||
          userProfile["Marketing Manager"] ? (
            ""
          ) : (
            <li onClick={() => onHandleRootMenu()}>
              <Link to="/faqs" className="d-block">
                <img
                  src={process.env.PUBLIC_URL + "/images/Help-2.png"}
                  alt=""
                />
                <p>Help</p>
              </Link>
            </li>
          )}
        </ul>
      </aside>
    </>
  );
}

export default Asidemenu;
