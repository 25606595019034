import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { getOrganisation } from '../../../src/service/policy';
import * as MediclaimService from '../../../src/service/medicalService';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getOnlyNumber } from '../../../src/utils/helpers';


const initialValues = []

const validationSchema = Yup.array().of(Yup.object().shape({
    org: Yup.object(),
    dateTo: Yup.string().optional('This field is required').nullable(),
    dateFrom: Yup.string().optional('This field is required').nullable(),
    employeeIdTo: Yup.number().optional('This field is required').nullable(),
    employeeIdFrom: Yup.number().optional('This field is required').nullable(),
}))

const VPFOpenDeclaration = () => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const _values = values.map(
                    item => {
                        if (Object.values(item).filter(_item => !_item).length === 0)
                            return {
                                organisation_id: parseInt(item?.org?.id),
                                to_date: (item?.dateTo),
                                from_date: (item?.dateFrom),
                                employee_to_id: parseInt(item?.employeeIdTo),
                                employee_from_id: parseInt(item?.employeeIdFrom)
                            }
                    }
                ).filter(Boolean)
                // return;
                const { data } = await MediclaimService.vpfOpenDecl({ detail_data: _values });
                toast.success(data?.message || 'Uploaded successfully')
            } catch (error) {
                console.error("🚀 ~ file: open-declaration.js:39 ~ onSubmit: ~ error:", error)
                toast.error(error?.data?.message || 'Some error occured')
            }
        }
    })
    const { values, errors, touched, isSubmitting, setValues, getFieldProps, handleSubmit, handleChange } = formik;


    const fetchOrgs = async () => {
        const { data } = await getOrganisation(false)
        const response = await MediclaimService.getvpfOpenDecl();
        if (
            Array.isArray(data?.dataList?.result)
            && data?.dataList?.result.length > 0
        ) {

            const oldData = response?.data?.dataList?.result;
            const array = data?.dataList?.result.map(
                item => {
                    const old = oldData?.find ? oldData.find(_item => item.id == _item.organisation_id) : []
                    return {
                        org: item,
                        dateTo: old?.to_date || '',
                        dateFrom: old?.from_date || '',
                        employeeIdTo: old?.employee_to_id || '',
                        employeeIdFrom: old?.employee_from_id || '',
                    }
                }
            )
            setValues(array)
        }
    }

    useState(() => {
        fetchOrgs()
    }, [])
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="px-3 pb-2">
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered tablecured leavetableapply vehiclelog innergroup">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Company Code</th>
                                                <th>Employee Id From</th>
                                                <th>Employee Id To</th>
                                                <th>Date From</th>
                                                <th>Date To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                values.map(
                                                    (item, index) => {
                                                        return (
                                                            <tr key={`org-listing-${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td class="fw-bold text-theme">{item?.org?.name || 'N/A'}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        {...getFieldProps(`${index}.employeeIdFrom`)}
                                                                        onChange={e => {
                                                                            e.target.value = (getOnlyNumber(values?.[index]?.employeeIdFrom, e.target.value, 999999))
                                                                            handleChange(e)
                                                                        }}
                                                                    />
                                                                    <div className="small text-danger">
                                                                        {touched?.[index]?.employeeIdFrom && errors?.[index]?.employeeIdFrom}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={6}
                                                                        class="form-control"
                                                                        {...getFieldProps(`${index}.employeeIdTo`)}
                                                                        onChange={e => {
                                                                            e.target.value = (getOnlyNumber(values?.[index]?.employeeIdTo, e.target.value, 999999))
                                                                            handleChange(e)
                                                                        }}
                                                                    />
                                                                    <div className="small text-danger">
                                                                        {touched?.[index]?.employeeIdTo && errors?.[index]?.employeeIdTo}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="date"
                                                                        class="form-control"
                                                                        min={moment().format('YYYY-MM-DD')}
                                                                        {...getFieldProps(`${index}.dateFrom`)}
                                                                    />
                                                                    <div className="small text-danger">
                                                                        {touched?.[index]?.dateFrom && errors?.[index]?.dateFrom}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="date"
                                                                        class="form-control"
                                                                        min={moment(values?.[index]?.dateFrom).format('YYYY-MM-DD')}
                                                                        {...getFieldProps(`${index}.dateTo`)}
                                                                    />
                                                                    <div className="small text-danger">
                                                                        {touched?.[index]?.dateTo && errors?.[index]?.dateTo}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <div class="btn btn-outline-secondary mr-2">Cancel</div>
                                <button disabled={isSubmitting} type='submit' class="btn btn-primary-inner bpi-main">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default VPFOpenDeclaration