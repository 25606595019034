import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getReimbEntitlement,
  getReimbursementData,
  saveMarriageReimbursement,
  saveMobileReimbursement,
  updateMarriageReimbursement,
  updateReimbursementData,
  uploadfile,
} from "./claimReimbServices";
import Select from "react-select";
import * as moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { toast } from "react-toastify";

function MarriageGiftVoucher(props) {
  const { onClose, edit, editRecord, onAddOrUpdate } = props;
  const marriageOptions = [
    { value: "Select", label: "Select" },
    { value: "Self", label: "Self" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
  ];
  let condition = "true";

  const [formObj, setFormObj] = useState({
    reimburseType: "2",
    attachment: null,
    marriageEntitlement: "5000",
    marriageFor: "",
    marriageDate: "",
    firstName: "",
    lastName: "",
  });
  const [formError, setFormError] = useState({
    attachment: false,
    marriageFor: "",
    marriageDate: "",
    firstName: "",
    lastName: "",
  });
  const userData = useSelector(selectUserData);

  const [filename, setFilename] = useState("");
  const [fileurl, setfileurl] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [lastReimburse, setLastReimburse] = useState(null);
  const dispatch = useDispatch();

  const fileUploadHandler = (e) => {
    const fileData = new FormData();
    let files = [];
    let urls = [];
    let fileNames = [];
    let file = e.target.files;
    dispatch(setDisplayLoader("Display"));
    for (let i = 0; i < file.length; i++) {
      let myUrl = URL.createObjectURL(e.target.files[i]);
      urls.push(myUrl);
      let data = e.target.files[i];
      fileData.append("uploaded_file", data);
      let name = { name: data.name };
      fileNames.push(name);
      uploadfile(fileData).then((response) => {
        files.push(response.data);
      });
    }
    dispatch(setDisplayLoader("Hide"));
    setFormObj((prev) => ({
      ...prev,
      attachment: files,
    }));
    setFilename(fileNames);
    setfileurl(urls);
  };

  const resetData = () => {
    setFormObj({
      reimburseType: "2",
      attachment: null,
      marriageEntitlement: "",
      marriageFor: "",
      marriageDate: "",
      firstName: "",
      lastName: "",
    });
    setFormError({
      attachment: false,
      marriageFor: "",
      marriageDate: "",
      firstName: "",
      lastName: "",
    });
  };

  const submitReimbursement = () => {
    if (checkErrors()) {
      if (edit) {
        if (condition === "true") {
          condition = "false";
          updateMarriageReimbursement(editRecord?.id, formObj).then(
            (response) => {
              if (response?.status === 200) {
                condition = "true";
                toast.success(response?.data?.message);
                resetData();
                onAddOrUpdate();
              }
            }
          );
        } else {
          toast.error("Kindly provide attachment.");
        }
      } else {
        if (
          !isDisabled &&
          showPopup === false &&
          formObj?.attachment !== null
        ) {
          if (condition == "true") {
            condition = "false";
            saveMarriageReimbursement(formObj).then((res) => {
              if (res?.status === 200) {
                condition = "true";
                toast.success(res?.data?.message);
                resetData();
                onAddOrUpdate();
              }
            });
          }
        } else if (formObj.attachment === null) {
          toast.error("Kindly provide attachment.");
        } else if (showPopup) {
          toast.error("Maritial status should be Single.");
        } else {
          toast.error("Marriage Reimbursement already applied.");
        }
      }
    }
  };

  const getLastReimbursement = (paginate) => {
    getReimbursementData(`reimburse_type=2&paginate=${paginate}&marriage_for=${formObj?.marriageFor?.value}&first_name=${formObj?.firstName}&last_name=${formObj?.lastName}`).then(
      (response) => {
        if (response?.status === 200) {
          if (response?.data?.dataList?.result?.id !== undefined) {
            setIsDisabled(true);
          }
          else{
            setIsDisabled(false);
          }
          setLastReimburse(response?.data?.dataList?.result);
        }
      }
    );
  };

  // const getEntitlement = (empSubGroup) => {
  //   getReimbEntitlement(empSubGroup, "2").then((response) => {
  //     if (response?.status === 200) {
  //       setFormObj((prev) => ({
  //         ...prev,
  //         marriageEntitlement: 5000,
  //       }));
  //     }
  //   });
  // };

  const changeHandler = (type, value) => {
    switch (type) {
      case "marriageFor":
        if (value !== "") {
          if (
            value?.value === "Self" &&
            userData?.joiningDate?.marital_status === "Marr."
          ) {
            setShowPopup(true);
          } else {
            setShowPopup(false);
          }
          setFormError((prev) => ({
            ...prev,
            marriageFor: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            marriageFor: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          marriageFor: value,
        }));
        break;
      case "marriageDate":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            marriageDate: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            marriageDate: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          marriageDate: value,
        }));
        break;
      case "firstName":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            firstName: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            firstName: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          firstName: value,
        }));
        break;
      case "lastName":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            lastName: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            lastName: true,
          }));
        }
        setFormObj((prev) => ({
          ...prev,
          lastName: value,
        }));
        break;
      default:
        break;
    }
  };

  const handleBlur = (type, value) => {
    switch (type) {
      case "marriageDate":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            marriageDate: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            marriageDate: true,
          }));
        }
        break;
      case "firstName":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            firstName: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            firstName: true,
          }));
        }
        break;
      case "marriageFor":
        if (value !== "" && formObj?.marriageFor !== "") {
          setFormError((prev) => ({
            ...prev,
            marriageFor: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            marriageFor: true,
          }));
        }
        break;
      case "lastName":
        if (value !== "") {
          setFormError((prev) => ({
            ...prev,
            lastName: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            lastName: true,
          }));
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (userData?.emp_sub_group) {
      // getEntitlement(userData?.emp_sub_group);
      getLastReimbursement(false);
    }
  }, [userData,formObj?.firstName, formObj?.marriageFor, formObj?.lastName]);

  useEffect(() => {
    if (editRecord) {
      console.log(editRecord);
      setFormObj((prev) => ({
        ...prev,
        firstName: editRecord?.first_name,
        lastName: editRecord?.last_name,
        marriageDate: editRecord?.marriage_date,
        marriageEntitlement: editRecord?.entitlement_amount,
        marriageFor: marriageOptions?.filter((obj) => {
          if (obj?.value === editRecord?.marriage_for) {
            return true;
          }
        })[0],
      }));
      setFilename(editRecord?.attachment);
    }
  }, [edit]);

  const checkErrors = () => {
    let flag = Object.values(formObj)
      .filter((obj) => {
        if (obj !== "2" || obj !== null) {
          return true;
        }
      })
      .includes("");
    if (flag) {
      setFormError((prev) => ({
        ...prev,
        firstName: formObj?.firstName === "" ? true : false,
        lastName: formObj?.lastName === "" ? true : false,
        marriageDate: formObj?.marriageDate === "" ? true : false,
        marriageFor: formObj?.marriageFor === "" ? true : false,
      }));
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>MARRIAGE GIFT ENTITLEMENT (INR)</label>
            <input
              type="text"
              class="form-control"
              value={formObj?.marriageEntitlement}
              disabled
            />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              FOR MARRIAGE OF <span class="text-danger">*</span>
            </label>
            <Select
              options={marriageOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler("marriageFor", e);
              }}
              onBlur={(e) => {
                handleBlur("marriageFor", e);
              }}
              value={formObj?.marriageFor}
            />
            {formError?.marriageFor ? (
              <div className="small text-danger">Select For Marriage Of</div>
            ) : null}
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              MARRIAGE DATE <span class="text-danger">*</span>
            </label>
            <input
              type="date"
              onChange={(e) => {
                changeHandler("marriageDate", e.target.value);
              }}
              onBlur={(e) => {
                handleBlur("marriageDate", e.target.value);
              }}
              max={moment(new Date()).format("YYYY-MM-DD")}
              class="form-control"
              value={formObj?.marriageDate}
            />
            {formError?.marriageDate ? (
              <div className="small text-danger">Select Marriage Date</div>
            ) : null}
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>First Name</label> <span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              value={formObj?.firstName}
              placeholder="Enter Spouse First Name"
              onChange={(e) => {
                if (e.target.value.length < 25) {
                  changeHandler("firstName", e.target.value);
                }
              }}
              onBlur={(e) => {
                handleBlur("firstName", e.target.value);
              }}
            />
            {formError?.firstName ? (
              <div className="small text-danger">Enter First Name</div>
            ) : null}
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Last Name</label> <span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              value={formObj?.lastName}
              placeholder="Enter Spouse Last Name"
              onChange={(e) => {
                if (e.target.value.length < 25) {
                  changeHandler("lastName", e.target.value);
                }
              }}
              onBlur={(e) => {
                handleBlur("lastName", e.target.value);
              }}
            />
            {formError?.lastName ? (
              <div className="small text-danger">Enter Last Name</div>
            ) : null}
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group innergroup position-relative modaldefaultclose ">
            <label>
              Attachment <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control bg-white"
              disabled
              placeholder={
                filename?.length > 0 ? "" : "JPG,PNG,PDF Format Only"
              }
            />
            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input type="file" onChange={fileUploadHandler} />
            </div>
            <p>
              Selected files{" "}
              <span>
                {filename?.length > 0
                  ? filename?.map((obj, i) => (
                      <>
                        <a href={fileurl[i]} target="_blank" rel="noreferrer">
                          {edit ? obj?.name.split("/").pop() : obj?.name}
                        </a>
                        <br />
                      </>
                    ))
                  : ""}
              </span>
            </p>
          </div>
        </div>
      </div>
      <Modal.Footer>
        <button
          class="btn btn-outline-danger"
          onClick={() => {
            resetData();
            onClose();
          }}
        >
          Cancel
        </button>
        <button
          class="btn btn-primary-inner bpi-main"
          onClick={submitReimbursement}
        >
          Submit
        </button>
        {showPopup && (
          <div>
            <p>
              <span class="text-danger">*</span>
              In case of claiming for self, make sure your marital status shows
              'unmarried' in SuccessFactors before initiating this
              reimbursement. You can change the status to married once the
              reimbursement is processed.
            </p>
          </div>
        )}
      </Modal.Footer>
    </>
  );
}

export default MarriageGiftVoucher;
