import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const saveEvent = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/event`, data)
}

export const getEvents = async (queryParam) => {
    return axios.get(`${AUTH_BASE_URL}/event?${queryParam}`);
}

export const updateEvent = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/event/${id}`, data);
  };

export const getExportValue = async (queryParm) => {
    return axios({
        url: `${AUTH_BASE_URL}/event/export?${queryParm}`,
        method: "GET",
        responseType: "blob",
    });
};
  