import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getOrganisation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?${queryParm}`);
};

export const saveOrganisation = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/organisations`, data);
};
export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const updateOrganisation = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/organisations/${id}`, data);
};

export const deleteOrg = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/organisations/${id}`);
};

export const getAllOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};

export const isOrgExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/organisations/exists`, isData);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/organisations/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getGLmaster=async()=>{
  return axios.get(`${AUTH_BASE_URL}/buss-transactions/master-gl-code/list`)
}