import { Tooltip } from "react-tippy";
import { OverlayTrigger } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Button, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as VehicleService from "../../service/VehicalService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";
import * as VehicalService from "../../service/VehicalService";
import * as otherExpService from "../../service/otherExpService";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import { toast } from "react-toastify";

function OtherExpPick() {
  const dispatch = useDispatch();
  const [shows, setShow] = useState(false);
  const [showsDiscard, setShowDiscard] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleCloseDiscard = () => setShowDiscard(false);
  const handleShowDiscard = () => setShowDiscard(true);

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);
  const [vehicleData, setVehicleData] = useState([]);
  const [fileObj, setFileObj] = useState();
  const [otherExpData, setotherExpData] = useState([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-created_at" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(500);
  const [pageCount, setPageCount] = useState(1);
  const [travelList, setTravelList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [discardReason, setDiscardReason] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [orgDropDown, setorgDropDown] = useState([]);
  const [companyDropDown, setcompanyDropDown] = useState([]);
  const [filterdata, setfilterData] = useState({
    user_id: { value: "" },
    id: "",
    organisation: { value: "" },
    company: { value: "" },
    from_date: "",
    to_date: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getOtherExp(
      "A",
      false,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      searchStr
    );
  };
  const getData = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    from_date,
    to_date,
    emp_code,
    search
  ) => {
    // dispatch(setDisplayLoader("Display"));

    VehicalService.getPickList(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      from_date,
      to_date,
      emp_code,
      search
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setVehicleData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getOtherExp = (
    status,
    is_picked,
    pageNo,
    pageSize,
    sort_by,
    paginate,
    emp_code,
    org,
    compid,
    reqid,
    search
  ) => {
    dispatch(setDisplayLoader("Display"));

    otherExpService
      .getOtherExp(
        `status=` +
          status +
          `&page_no=` +
          pageNo +
          `&paginate=` +
          paginate +
          `&page_size=` +
          pageSize +
          `&sort_by=` +
          sort_by +
          `&is_picked=` +
          is_picked +
          `&user_id=` +
          (emp_code > 0 ? emp_code : "") +
          `&organisation=` +
          (org ? org : "") +
          `&company=` +
          (compid ? compid : "") +
          `&id=` +
          reqid +
          `&search=` +
          search
      )
      .then((response) => {
        // console.log(`otherexp...`,response);
        dispatch(setDisplayLoader("Hide"));
        // let data = response?.data?.result;
        // setVehicleData(response?.data?.dataList?.result);
        setotherExpData(response?.data?.dataList?.result);

        let ress = response?.data?.dataList?.result.filter((data) => {
          postingData?.map((piked)=>{
            if (piked?.id == data?.id) {
                data.checked = "Y";
            }
          })
          return !data.is_discarded;
        });
        console.log(`log..`, ress);
        setotherExpData(ress);
        setCurrentPage(response?.data?.dataList?.paginated_data?.currentPage);
        console.log(
          "pages..",
          response?.data?.dataList?.paginated_data?.totalPages
        );
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getUsersList();
    getAllOrganisation();
    getBusinessUnits();

    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   filterdata?.from_date,
    //   filterdata?.to_date,
    //   filterdata?.user_id.value,
    //   searchStr
    // );

    getOtherExp(
      "A",
      false,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      searchStr
    );
  }, []);

  useEffect(() => {
    setCurrentPage(1);

    getOtherExp(
      "A",
      false,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.user_id.value,
      filterdata?.organisation.value,
      filterdata?.company.value,
      filterdata?.id,
      searchStr
    );
  }, [pageSizeNo]);

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name}( ${x.code} )`,
          };
        }),
      ];
      setcompanyDropDown(data);
    });
  };

  const getBusinessUnits = () => {
    otherExpService.getBusinessUnits().then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name}( ${x.code} )`,
          };
        }),
      ];
      setorgDropDown(data);
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getOtherExp(
        "A",
        false,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        filterdata?.user_id.value,
        filterdata?.organisation.value,
        filterdata?.company.value,
        filterdata?.id,
        e.target.value
      );

      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    getOtherExp(
      "A",
      false,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterdata?.user_id.value,
      filterdata?.organisation.value,
      filterdata?.company.value,
      filterdata?.id,
      searchStr
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    getOtherExp(
      "A",
      false,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      searchStr
    );
    setfilterData({
      organisation: { value: "" },
      id: "",
      user_id: { value: "" },
      company: { value: "" },
      from_date: "",
      to_date: "",
    });
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(otherExpData));
    // let copyposData = JSON.parse(JSON.stringify(postingData));
    if (e.target.checked === true) {
      //# data.is_picked=true
      // let payl = { id: data?.id };

      // if (copyposData?.length) {
      //   copyposData?.map((pdata) => {
      //     if (pdata?.id != data?.id) {
      //       postingData.push(payl);
      //     }
      //   });
      // } else {
      //   postingData.push(payl);
      // }

      let obj = [];
      obj.push(data)
      postingData?.map((res) => {
        if (res?.id != data?.id) {
          return obj.push(res);
        }
      });




      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });

      setPostingData(obj);
      setotherExpData(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });

      // let copyData = postingData;
      let obj = [];
      postingData?.map((res) => {
        if (res?.id != data?.id) {
          return obj.push(res);
        }
      });
      setPostingData(obj);

      setotherExpData(copyData1);
    }
  };

  const onReceive = () => {
    // console.log(`onrec`,postingData);
    if (postingData?.length < 1) {
      toast.error("Please select an expense to receive");
      return;
    }
    // return
    confirmAlert({
      title: "Receive Other Expenses Pick List",
      message: `Are you sure to Receive Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => receivePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onDecline = () => {
    if (postingData?.length < 1) {
      toast.error("Please select an expense to Discard");
      return;
    }
    handleShowDiscard();
    // confirmAlert({
    //   title: "Discard Other Expenses Pick List",
    //   message: `Are you sure to Discard Data?`,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => declinePickList(),
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(otherExpData));
    if (!data?.length) {
      return;
    }
    let copyposData = JSON.parse(JSON.stringify(postingData));
    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        res.is_picked = true;

        let payl = {
          id: res?.id,
        };

        if (copyposData?.length > 0) {
          copyposData?.map((pdata) => {
            if (pdata?.id != payl?.id) {
              postingData.push(payl);
            }
          });
        } else {
          postingData.push(payl);
        }
      });
      setotherExpData(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setotherExpData(data);
      // let obj = postingData?.map((x) => {
      //   if (x?.id != data?.id) {
      //     return x;
      //   }
      // });
      // setPostingData(obj);
      setPostingData([]);
    }
  };

  const getExportValue = (
    status,
    is_picked,
    pageNo,
    pageSize,
    sort_by,
    paginate,
    emp_code,
    org,
    compid,
    reqid,
    search
  ) => {
    dispatch(setDisplayLoader("Display"));
    otherExpService.getOtherExpExportPick(
      `status=` +
          status +
          `&page_no=` +
          pageNo +
          `&paginate=` +
          paginate +
          `&page_size=` +
          pageSize +
          `&sort_by=` +
          sort_by +
          `&is_picked=` +
          is_picked +
          `&user_id=` +
          (emp_code > 0 ? emp_code : "") +
          `&organisation=` +
          (org ? org : "") +
          `&company=` +
          (compid ? compid : "") +
          `&id=` +
          reqid +
          `&search=` +
          search+`&is_export=true`
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Other Pick List.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const receivePickList = () => {
    let data = [];
    console.log(`recev`, postingData);

    // return
    postingData.map((res) => {
      if (res) {
        data.push({
          id: res?.id,
          is_picked: true,
        });
      }
    });

    // console.log(`sending....`,data);

    // toast.success(`sending...${Object.entries(data)}`)

    // return

    if (data?.length < 1) {
      toast.error("Please select an expense to receive");
      return;
    }
    let obj = { data: data };
    console.log(`rec..`, obj);

    otherExpService
      .receivePickList(obj)
      .then((response) => {
        console.log(response, "response");
        navigate("/otherexp-check");
        getOtherExp(
          "A",
          false,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          "",
          "",
          ""
        );
      })
      .catch((error) => {
        console.log(error.response);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const declinePickList = () => {
    
    if (!discardReason) {
      toast.error("Please select reason to discard");
      return;
    }
    let data = [];
    // if (data?.length < 1) {
    //   toast.error("Please select an expense to Discard");
    //   return;
    // }
    postingData.map((res) => {
      if (res) {
        data.push({
          id: res?.id,
          is_discarded: true,
          discard_reason: discardReason,
        });
      }
    });

    let obj = { data: data };
    console.log(`dec..`, obj);
    // return

    otherExpService
      .receivePickList(obj)
      .then((response) => {
        setPostingData([]);
        console.log(response, "response");
        getOtherExp(
          "A",
          false,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
      filterdata?.user_id.value,
      filterdata?.organisation.value,
      filterdata?.company.value,
      filterdata?.id,
      searchStr
        );
        
        handleCloseDiscard();
        
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // useEffect(() => {
  //   // getData(
  //   //   currentPage,
  //   //   pageSizeNo,
  //   //   sortData.sort_by,
  //   //   true,
  //   //   filterdata?.from_date,
  //   //   filterdata?.to_date,
  //   //   filterdata?.user_id.value,
  //   //   searchStr
  //   // );
  // }, [pageSizeNo]);

  const handleFileView = (data) => {
    setFileObj(data);
    handleShow();
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">
                      Other Expense Request Pick List
                    </h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong> Name, Employee ID, Expense ID</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc manager" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onDecline()}
                            >
                              <i class="far fa-check-circle"></i> Discard
                            </button>
                          )
                      )}

                      {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc user" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onReceive()}
                            >
                              <i class="far fa-check-circle"></i> Receive
                            </button>
                          )
                      )}

                       <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  "A",
                                false,
                                currentPage,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                filterdata?.user_id.value,
                                filterdata?.organisation.value,
                                filterdata?.company.value,
                                filterdata?.id,
                                searchStr
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> 
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>SNO.</th>
                        <th>
                          <label class="logCheck d-inline-block">
                            <input
                              type="checkbox"
                              name="permission"
                              id={`mainperm`}
                              onClick={(e) => {
                                handleAllData(e);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </th>
                        <th> PRINT</th>

                        <th> REQ ID</th>
                        <th> EMPLOYEE ID</th>
                        <th> NAME</th>
                        <th> STATUS</th>
                        <th> LOCATION</th>
                        <th> ESG</th>
                        <th> ZONE</th>
                        <th> REQUESTED ON</th>
                        <th> BOSS APPROVED ON</th>
                        <th> AMOUNT</th>
                        <th> EXPENSE TYPE</th>
                        <th> FOR MONTH OF</th>

                        {/* <th>Approved on</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {otherExpData?.length ? (
                        otherExpData?.map((x, idx) => (
                          <tr
                          style={{
                            backgroundColor:
                              x?.chat_status > 0
                                ? "yellow"
                                : (x?.send_back_remark||x?.send_back_ssc_remark)
                                ? "#FFBF00"
                                : x?.is_exception
                                ? "#FFA895"
                                : "",
                          }}  
                          >
                            <td>{idx + 1}</td>
                            <td>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  id={`expdata${idx}`}
                                  onClick={(e) => {
                                    selectDataToPost(e, x);
                                  }}
                                  checked={x?.checked === "Y"}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </td>
                            <td>
                              <Link
                                to="/otherexp-printview"
                                state={{
                                  travel_id: x?.id,
                                  backpath: "/otherexp-pick",
                                  predata: x,
                                  showPrintData: false,
                                }}
                              >
                                <button class="btn btn-blanktd text-primary">
                                  <i class="fas fa-print"></i>
                                </button>
                              </Link>

                              {x?.attachment?.length > 0 && (
                                <Link
                                  onClick={() => {
                                    handleFileView(x.attachment);
                                  }}
                                >
                                  <button class="btn btn-blanktd text-primary">
                                    <i class="fas fa-paperclip"></i>
                                  </button>
                                </Link>
                              )}
                            </td>
                            <td>
                              {x?.request_id
                                ? x?.request_id
                                : x?.expense_type == "petty"
                                ? `${x?.organisation_code}PE`
                                : `${x?.user_organisation_code}OE` + x?.id}
                            </td>
                            <td>{x?.user_code}</td>
                            <td>{x?.user_name}</td>
                            <td>{x?.user_status}ACTIVE</td>
                            <td>{x?.user_location}</td>

                            <td>{x?.user_grade}</td>
                            <td>{x?.zone}-</td>
                            <td>
                              {moment
                                .parseZone(x?.created_at)
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {moment
                                .parseZone(
                                  x?.approver_remark_data[0]?.updated_at
                                )
                                .format("DD-MM-YYYY")}
                            </td>
                            <td>{x?.total_bill_amount?.toFixed(2)}</td>

                            {/*{x?.validFromDate !== null ||
                            x?.validFromDate !== undefined ? (
                              <td>
                                {moment
                                  .utc(x?.validFromDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {x?.validTillDate !== null ||
                            x?.validTillDate !== undefined ? (
                              <td>
                                {moment
                                  .utc(x?.validTillDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            <td>
                              <a
                                target="_blank"
                                href={
                                  (x?.attachment_path).includes("https")
                                    ? x?.attachment_path
                                    : ""
                                }
                                rel="noreferrer"
                              >
                                <i class="fas fa-paperclip"></i>
                              </a>
                            </td>
                            <td>{x?.licenseNumber}</td>
                            {x?.licenseValidFromDate !== null ||
                            x?.licenseValidFromDate !== undefined ? (
                              <td>
                                {moment
                                  .utc(x?.licenseValidFromDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            {x?.licenseValidTillDate !== null ||
                            x?.licenseValidTillDate !== undefined ? (
                              <td>
                                {moment
                                  .utc(x?.licenseValidTillDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td>
                              <a
                                target="_blank"
                                href={
                                  (x?.dl_attachment_path).includes("https")
                                    ? x?.dl_attachment_path
                                    : ""
                                }
                                rel="noreferrer"
                              >
                                <i class="fas fa-paperclip"></i>
                              </a>
                            </td>
                            <td>
                              {moment.utc(x?.created_at).format("DD-MM-YYYY")}
                            </td> */}
                            <td>
                              {x?.expense_type[0]?.toUpperCase() +
                                x?.expense_type.slice(1)}
                              {/* {x?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Approved{" "}
                                </span>
                              ) : x?.status == "P" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending{" "}
                                </span>
                              ) : x?.status == "ARM" ? (
                                <span class="badge bad-status badge-warning">
                                  Partially Approved{" "}
                                </span>
                              ) : x?.status.includes("R") ? (
                                <span class="badge bad-status badge-danger">
                                  Declined{" "}
                                </span>
                              ) : (
                                ""
                              )} */}
                            </td>
                            <td>
                              {x?.expense_type?.toLowerCase()?.includes("petty")
                                ? moment
                                    .parseZone(x?.expense_data[0].for_month_of)
                                    .format("MMMM")
                                : "N/A"}
                            </td>
                            {/* <td>
                              {moment.utc(x?.created_at).format("DD-MM-YYYY")}
                            </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}>NO Record Found</td>
                        </tr>
                      )}

                      <Modal show={shows} onHide={handleClose}>
                        <Modal.Header
                          className="bg-primary text-white"
                          closeButton
                        >
                          <Modal.Title>View attachment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div class="table-responsive mb-3">
                            <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>FILE NAME </th>
                                  <th>VIEW</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fileObj?.map((adata, idx) => (
                                  <tr>
                                    <td>
                                      {adata?.attachment_path?.substring(
                                        adata?.attachment_path?.lastIndexOf(
                                          "/"
                                        ) + 1
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        target="_Blank"
                                        to={adata?.attachment_path}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        size="lg"
                        show={showsDiscard}
                        onHide={handleCloseDiscard}
                      >
                        <Modal.Header
                          style={{ "background-color": "#0195d4" }}
                          className="text-white"
                        >
                          <Modal.Title>
                            KINDLY PROVIDE REASON TO DISCARD EXPENSE?
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div class="row">
                            <div class="col-sm-12">
                              <select
                                class="form-control"
                                onChange={(e) =>
                                  setDiscardReason(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="docs_not_rec_90_days">
                                  Documents not recieved in 90 days
                                </option>
                                <option value="duplicate_form">
                                  Duplicate Form Submission
                                </option>
                              </select>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            style={{ "background-color": "#0195d4" }}
                            class="btn"
                            onClick={handleCloseDiscard}
                          >
                            Cancel
                          </Button>
                          <Button
                            style={{ "background-color": "#0195d4" }}
                            class="btn "
                            onClick={declinePickList}
                          >
                            Submit
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        className="form-control"
                        value={pageSizeNo}
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={100} label={100}>
                          100
                        </option>
                        <option value={200} label={200}>
                          200
                        </option>
                        <option value={500} label={500}>
                          500
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      key={pageSizeNo}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3"></div>
                  </div>
                </div>
                {/* </Tab>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log("filterdata", filterdata)}
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div class="form-group innergroup">
            <label>
              Status <span class="text-danger"></span>
            </label>
            <select 
                class="form-control"

            // style={{width:"100%"}}
            
            onChange={(e) =>
              setfilterData({
                ...filterdata,
                status: e.target.value,
              })
            }
            >
              <option value="T">T</option>
              <option value="P">P</option>
            </select>
          </div> */}

          <div class="form-group innergroup">
            <label>
              Company <span class="text-danger"></span>
            </label>
            <Select
              options={companyDropDown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  company: e,
                })
              }
              value={filterdata.company}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Business Unit <span class="text-danger"></span>
            </label>
            <Select
              options={orgDropDown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  organisation: e,
                })
              }
              value={filterdata.organisation}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Req ID <span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  id: e.currentTarget.value,
                })
              }
              value={filterdata.id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OtherExpPick;
