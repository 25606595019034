import React, { useState, useEffect } from "react";
import * as moment from "moment";
import {
  Modal,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import Select from "react-select";
import { getUserByReporting } from "../../service/userService";
import { uploadFile, view_CanteenData } from "../../service/policy";
import ReactPaginate from "react-paginate";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
  selectUserData,
} from "../redux/piDataStore";
import { toast } from "react-toastify";

function CanteenMaster() {

  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userDetails = useSelector(selectUserData);
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [moduleList, setModuleList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [status, setStatus] = useState([]);

  const [filterdata, setfilterData] = useState({
    from_date: moment(new Date()).format("YYYY-MM-DD"),
    to_date: moment(new Date()).format("YYYY-MM-DD"),
    user_id: 0,
    status: []
  });
  const [attendanceDataForExport, setAttendanceDataForExport] = useState({
    from_date: "",
    to_date: "",
    user_id: "",
  });

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [importshow, importsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [abscondingData, setAbscondingData] = useState([]);
  const [isAbsconding, setIsAbsconding] = useState('');
  

  const getCanteenData = (
    id,
    self_data,
    page_no,
    page_size,
    paginate,
    search
  ) => {
    view_CanteenData(
        handleKey(id, self_data, page_no, page_size, paginate, search)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setAbscondingData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    id, self_data, page_no, page_size, paginate, search
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    if (self_data !== "" && self_data !== undefined && self_data !== null) {
        queryParm = queryParm + "&self_data=" + self_data;
      }
    if (search !== "" && search !== undefined && search !== null) {
    queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
        if(pathname === '/canteen-menu-master'){
            getCanteenData(0, false, 1, pageSizeNo, true, e.target.value.toLowerCase())
            }
            else{
                getCanteenData(0, true, 1, pageSizeNo, true, e.target.value.toLowerCase())
            }
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = (e) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if(pathname === '/canteen-menu-master'){
        getCanteenData(0, false, 1, pageSizeNo, true, '')
        }
        else{
            getCanteenData(0, true, 1, pageSizeNo, true, '')
        }
      
    }
  };
 
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if(pathname === '/canteen-menu-master'){
        getCanteenData(0, false, activePage, pageSizeNo, true, searchStr)
        }
        else{
            getCanteenData(0, true, activePage, pageSizeNo, true, searchStr)
        }
   
  };
  

  
  useEffect(() => {
    if(pathname === '/canteen-menu-master'){
    getCanteenData(0, false, currentPage, pageSizeNo, true, '')
    }
    else{
        getCanteenData(0, true, currentPage, pageSizeNo, true, '')
    }
    
  }, [pageSizeNo]);

  return (
    <>
      <div>

        <div class="content-wrapper-inner">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">
                  Canteen Menu Management
                </h4>
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      className="bs-tooltip-end tooltip"
                      id="overlay-example"
                    >
                      <div class="tooltip-inner text-left">
                        Search on{" "}
                        <strong>
                          {" "}
                          Location
                        </strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search on Location..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={(e) => {
                            closeButtonCallBack(e);
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {finalUrlValue?.A ? (
                    <Link to='/canteen-menu-management'>
                  <button
                      class="btn btn-primary-inner bpi-main"
                      type="button"
                    >
                      Add Menu
                    </button>
                    </Link>
                  ):""}
                  {/* <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={abscondingData?.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                        //   onClick={() =>
                        //     getExportDetails(
                        //       searchStr,
                        //       filterdata.from_date,
                        //       filterdata.to_date,
                        //       filterdata.user_id,
                        //       "",
                        //       1,
                        //       pageSizeNo,
                        //       sortData.sort_by,
                        //       true,
                        //       true
                        //     )
                        //   }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {/* ) : (
                    ""
                  )} */}
                  {/* <button
                    onClick={() => {
                      filterShow();
                    }}
                    class="btn btn-secondary-inner"
                    style={{
                      backgroundColor: filterApplied && "yellow",
                    }}
                  >
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                    {finalUrlValue?.R || finalUrlValue?.E ?
                    <th>Action</th>
                    : ""}
                    <th>Location</th>
                    <th>Description</th>
                    <th>Attachment</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {abscondingData?.length ? (
                  abscondingData?.map((data, parent_index) => (
                    <>
                      <tr>
                      {finalUrlValue?.R || finalUrlValue?.E ?
                        <td>
                            <Link
                                to={'/canteen-menu-management' + `?id=${data?.id}`}>
                            <button
                            type="button"
                            class="btn btn-blanktd text-primary f-12"
                            >
                            <i class="far fa-edit"></i>
                            </button>
                            </Link>
                            <button
                            type="button"
                            // onClick={() => {
                            //     deleteLoanAndSalary(data);
                            // }}
                            class="btn btn-blanktd text-danger ml-2 f-12"
                            >
                            <i class="far fa-trash-alt text-danger"></i>
                            </button>
                        </td>
                        : ""}
                        <td>{data?.location_details}</td>
                        <td>{data?.description}</td>
                        <td>
                            <a href={data?.attachment[0]?.attachment_path} target="__blank">
                            <i class="fas fa-paperclip"></i>{" "}
                                View Menu
                            </a>
                        </td>
                        <td>{moment(data?.created_at).format('DD-MM-YYYY')}</td>
                        <td>{moment(data?.updated_at).format('DD-MM-YYYY')}</td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {abscondingData?.length == 0 ? (
            ""
          ) : (
            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control main-pointer"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        backdrop="static"
        keyboard={false}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger"></span>
            </label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  from_date: e.target.value,
                }))
              }
              value={filterdata?.from_date}
              max={moment(filterdata.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger"></span>
            </label>
            <input
              onChange={(e) =>
                setfilterData((prev) => ({
                  ...prev,
                  to_date: e.target.value,
                }))
              }
              value={filterdata?.to_date}
              min={moment(filterdata.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>
              Absent Since<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>1 Day</option>
              <option>2 Day</option>
              <option>3 Day</option>
              <option>4 Day</option>
              <option>5 Day</option>
            </select>
          </div> */}
          <div class="form-group innergroup">
            <label>
              Select Employee<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={moduleList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Employee"
              value={userList}
              onChange={(e) => {
                setUserList(e);
                setfilterData({
                  ...filterdata,
                  user_id: e
                    .map((obj) => {
                      return obj.value;
                    })
                    .toString(),
                });
              }}
            />
            {/* <select
              className="form-control newbgselect main-pointer"
              name="module_parent_id"
              type="text"
              onChange={(e) => {
                setfilterData({
                  ...filterdata,
                  user_id: e.target.value,
                });
              }}
              value={filterdata?.user_id}
            >
              <option value="" label="Select Employee" />
              <option value={0} label="Self Attendance" />
              {ModuleListData(moduleList)}
            </select> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
            //   clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            // onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Employee Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Employee Code"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Email Id<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email Id"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Department<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Designation<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Branch<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Telephone Ext.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Telephone Ext."
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Sequence<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Sequence"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Masking Mobile<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Masking Email<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label className="d-block">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4 mt-2">
                      Active
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button class="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="far fa-file-excel"></i> DOWNLOAD TEMPLATE
                    </button>
                  </div>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                  />
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i class="far fa-times-circle"></i>
                  </span>
                  <button class="btn btn-primary-inner">
                    Proceed to Upload
                  </button>
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i class="far fa-times-circle"></i>
                  </span>
                  <button class="btn btn-primary-inner" disabled>
                    Proceed to Upload
                  </button>
                  <div class="w-75 mt-3">
                    <ProgressBar
                      striped
                      variant="success"
                      animated
                      now={45}
                      label="40%"
                    />
                  </div>
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                  <p>
                    The system was unable to read few records from the uploaded
                    file.
                    <br />
                    Please correct or remove those records from the file and
                    upload again.
                  </p>
                  <span class="badge bad-status mb-3 badge-success">
                    Records Succeded: 230
                  </span>
                  <span class="badge bad-status mb-3 badge-danger">
                    Records Failed: 50
                  </span>
                  <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="fas fa-chevron-circle-left"></i> Go Back
                    </button>
                  </div>
                </div>

                <table class="table uploadrecordstab">
                  <thead>
                    <tr>
                      <th>Upload Date</th>
                      <th>Uploaded By</th>
                      <th>File Name</th>
                      <th>Records Uploaded</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CanteenMaster;
