import { AUTH_BASE_URL } from "../../constants/URL";
import axios from "../../utils/axios";

export const get_curruser_data = async () => {
    return axios.get(`${AUTH_BASE_URL}/reimbursement/get_curruser_data`);
  };

export const get_all_recruiters = async () => {
return axios.get(`${AUTH_BASE_URL}/reimbursement/all_recruiters`);
};

export const submit_notice_form = async (payload) => {
  return axios.post(`${AUTH_BASE_URL}/reimbursement/submit_notice_form`, payload);
};

export const updateNoticeReimbursementData = (id, data) => {
    return axios.put(`${AUTH_BASE_URL}/reimbursement/notice_reimb/${id}`, data);
  };
  
  export const submit_notice_approval_form = async (payload) => {
    return axios.post(`${AUTH_BASE_URL}/reimbursement/submit_notice_approval_form`, payload);
  };


  export const get_noticeapproval_data = (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/reimbursement/get_notice_approval_data?${queryParm}`);
  }

  export const updateNoticeApprovalData = (id, data) => {
    return axios.put(`${AUTH_BASE_URL}/reimbursement/update_notice_approval_form/${id}`, data);
  };