import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as HolidayService from "../../service/HolidayService";
import * as Yup from "yup";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";

const validationSchema = Yup.object({
  // year: Yup.string().required("*Select Year is required").nullable(),
  name: Yup.string()
    // .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    // .matches(
    //   /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
    //   "Insert only normal character"
    // )
    .required("*Holiday Name is required")
    .nullable(),
  day_date: Yup.string().required("*Date is required").nullable(),
});

const AddHolidayMaster = (props) => {
  const nameForm = "Holiday Master";
  const { edit, showForm, onClose, editHoliday, onAddCallBack } = props;
  const [holidayData, setHolidayData] = useState({
    name: "",
    day_date: "",
    day: "",
    is_active: true,
    state: "",
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [stateData, setStateData] = useState([]);
  const [maxDate, setMaxDate] = useState("");
  const saveHoliday = (values) => {
    setIsLoading(true);
    values.day = holidayData.day;
    values.state_id = stateName;
    HolidayService.saveHoliday(values)
      .then((response) => {
        if (response.data.code == 200) {
          setIsLoading(false);
          onAddCallBack(response);
          if (response.data.message == "Holiday created successfully") {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const date = new Date();
  let Year = date.getFullYear();
  let fdata = `${Year}-01-01`;

  const updateHoliday = (id, values) => {
    setIsLoading(true);
    // let data = values;
    //  values.start_date = moment(values.start_date).format("YYYY-MM-DDTHH:mm"),
    values.day = holidayData.day;
    HolidayService.updateHoliday(id, values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          setIsLoading(false);
          onAddCallBack(response);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const dataBlank = () => {
    onClose();
  };
  useEffect(() => {
    if (editHoliday) {
      setHolidayData(editHoliday);
      Object.keys(editHoliday).forEach((item) => {
        holidayData[item] = editHoliday[item];
      });
      let obj = {
        value: editHoliday?.id,
        label: editHoliday?.state,
      };
      setStateData(obj);
    }

    getStateList();
    let year = moment(new Date()).format("YYYY-MM-DD");
    let max = moment(year).endOf("year").format("YYYY-MM-DD");
    setMaxDate(max);
  }, [editHoliday]);

  const getStateList = () => {
    HolidayService.getStateList(false).then((response) => {
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res.id,
          label: res.name + "(" + res.alias + ")",
        };
      });
      setStateList(obj);
    });
  };

  const handleStateChange = (value) => {
    setStateName(value.value);
    setHolidayData({
      ...holidayData,
      state: value.value,
    });
    setStateData(value);
  };

  return (
    <div>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={holidayData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (values.id) {
            updateHoliday(values.id, values);
            AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            saveHoliday(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit " : "Add "} Optional Holiday
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      {/* <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Select Year<span class="text-danger">*</span>
                          </label>
                          <select
                            class="form-control newbgselect main-pointer"
                            type="number"
                            name="year"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setHolidayData({
                                ...holidayData,
                                year: e.target.value,
                              });
                            }}
                            value={values.year}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                          </select>
                          {touched.year && errors.year ? (
                            <div className="small text-danger">
                              {errors.year}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Holiday Name<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Holiday's Name"
                            maxLength={30}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Date<span class="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            class="form-control"
                            name="day_date"
                            placeholder="mm/dd/yyyy"
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleChange(e);
                              setHolidayData({
                                ...holidayData,
                                day_date: e.target.value,
                                day: moment(e.target.value).format("dddd"),
                              });
                            }}
                            // min={fdata}
                            // min={moment(new Date()).format("YYYY-MM-DD")}
                            max={maxDate}
                            onBlur={handleBlur}
                            value={holidayData.day_date}
                            // value={moment(holidayData.day_date).format(
                            //   "YYYY-MM-DDTHH:mm"
                            // )}
                          />
                          {touched.day_date && errors.day_date ? (
                            <div className="small text-danger">
                              {errors.day_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Day<span class="text-danger"></span>
                          </label>
                          <input
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Select Date"
                            maxLength={30}
                            name="day"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={holidayData.day}
                          />
                        </div>
                      </div>

                      {/* <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Select State<span class="text-danger">*</span>
                          </label>
                          <Select
                            options={stateList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="state"
                            // isDisabled={editRecord}
                            onChange={(newValue, action) => {
                              handleStateChange(newValue, action.name);
                            }}
                            value={stateData}
                          />
                        </div>
                      </div> */}

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Holiday Type <span class="text-danger"></span>
                          </label>
                          <input
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Optional"
                            maxLength={30}
                            name="type"
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            // value={holidayData.day}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label className="d-block">Status</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      class="btn btn-outline-danger"
                      onClick={() => {
                        dataBlank();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="btn btn-primary-inner bpi-main"
                      type="submit"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </div>
  );
};

export default AddHolidayMaster;
