// import firebase from 'firebase'
// import firebase from "firebase"
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDofAanWPt1Pg4748uYsI2SBQRZ2GJ2bsk",
  authDomain: "mypi-d7401.firebaseapp.com",
  projectId: "mypi-d7401",
  storageBucket: "mypi-d7401.appspot.com",
  messagingSenderId: "832755131922",
  appId: "1:832755131922:web:ec183acc8a3409f42f5acb",
  measurementId: "G-H3WXC1T6HG"
};

  // export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('./firebase-messaging-sw.js')
        .then(function (registration) {
          return registration.scope;
      })
      });
    }
  // }
  export let messaging
  export let app
try {
   app = initializeApp(firebaseConfig);
   messaging = getMessaging(app);
} catch (error) {
  alert("Browser not supported")
}

// const analytics = getAnalytics(app);

// export default firebase;