import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Pagination,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as PrService from "../../service/prService";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";

function PrReportSap() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [reportData, setReportData] = useState([]);
  const [filterReport, setFilterReport] = useState({
    end_date: "",
    start_date: "",
    source: "",
    user: "",
    sap_pr_no: "",
    mat_code: "",
    purchase_org: "",
    plant: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getData(
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
      "",
      filterReport.mat_code,
      filterReport.purchase_org,
      filterReport.plant
    );
    setCurrentPage(activePage);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
      "",
      filterReport.mat_code,
      filterReport.purchase_org,
      filterReport.plant
    );
  }, [pageSizeNo]);

  const getData = async (page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, mat_code, purchase_org, plant) => {
    try {
      const response = await PrService.getSapPrReports(handleKey(page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, mat_code, purchase_org, plant));
      dispatch(setDisplayLoader("Hide"));
      if (response.data.code === 200) {
        setReportData(response?.data?.dataList?.result);
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.user_username,
            label: res?.user_name
          }
        })
        let jsonObject = obj.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setUserOptions(uniqueArray)
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
      } else {
        dispatch(setDisplayLoader("Hide"));
        toast.warn(response.data.message);
      }
    } catch (error) {
      dispatch(setDisplayLoader("Hide"));
      console.log("error", error);
      toast.warn(error.message);
    }
  };

  const handleKey = (page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, mat_code, purchase_org, plant) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (
      report_flag !== "" &&
      report_flag !== undefined &&
      report_flag !== null
    ) {
      queryParm = queryParm + "&report_flag=" + report_flag;
    }
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (sap_pr_no !== "" && sap_pr_no !== undefined && sap_pr_no !== null) {
      queryParm = queryParm + "&sap_pr_no=" + sap_pr_no;
    }
    if (source !== "" && source !== undefined && source !== null) {
      queryParm = queryParm + "&source=" + source;
    }
    if (user !== "" && user !== undefined && user !== null) {
      queryParm = queryParm + "&user=" + user;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (mat_code !== "" && mat_code !== undefined && mat_code !== null) {
      queryParm = queryParm + "&mat_code=" + mat_code;
    }
    if (purchase_org !== "" && purchase_org !== undefined && purchase_org !== null) {
      queryParm = queryParm + "&purchase_org=" + purchase_org;
    }
    if (plant !== "" && plant !== undefined && plant !== null) {
      queryParm = queryParm + "&plant=" + plant;
    }

    return queryParm;
  };

  const getExportDetails = () => {
    dispatch(setDisplayLoader("Display"));
    PrService.getExportSAPPRReport(
      handleKey(
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true,
        filterReport.end_date,
        filterReport.start_date,
        filterReport.sap_pr_no,
        filterReport.source,
        filterReport.user,
        "",
        filterReport.mat_code,
        filterReport.purchase_org,
        filterReport.plant
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SAPPrReport.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterReport.end_date;
    const start_date = filterReport.start_date;
    const user = filterReport.user;
    const source = filterReport.source;
    const sap_pr_no = filterReport.sap_pr_no;
    const mat_code = filterReport.mat_code;
    const purchase_org = filterReport.purchase_org;
    const plant = filterReport.plant;

    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true,
      end_date,
      start_date,
      sap_pr_no,
      source,
      user,
      "",
      mat_code,
      purchase_org,
      plant
    );
    filterclose();
  };

  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    // resetFilterData();
    setFilterReport({
      ...filterReport,
      end_date: "",
      start_date: "",
      source: "",
      user: "",
      sap_pr_no: ""
    })
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      getData(
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true,
        filterReport.end_date,
        filterReport.start_date,
        filterReport.sap_pr_no,
        filterReport.source,
        filterReport.user,
        search,
        filterReport.mat_code,
        filterReport.purchase_org,
        filterReport.plant
      );
    }
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Approved SAP PR Report</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-2"></div> */}
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>


                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="approved" id="approvaltabs">
                <Tab
                  eventKey="approved"
                  title={<React.Fragment>Approved SAP PR</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>REQUESTOR</th>
                          <th>PR No.</th>
                          <th>PR ITEM NO.</th>
                          <th>SOURCE</th>
                          <th>PR Date</th>
                          <th>RELEASE DATE</th>
                          <th>MATERIAL CODE</th>
                          <th>Material Description</th>
                          <th>PR Qty.</th>
                          <th>Unit</th>
                          <th>PURCHASING GROUP</th>
                          <th>Plant Code</th>
                          <th>PO NO.</th>
                          <th>PO ITEM NO.</th>
                          <th>PO QTY</th>
                          <th>PO DEL DATE.</th>
                          <th>PO REL DATE</th>
                          <th>VENDOR CODE</th>
                          <th>VENDOR DESC.</th>
                          <th>GRN NO.</th>
                          <th>GRN POSTING DATE</th>
                          <th>SERVICE ENTRY</th>
                          <th>GATE ENTRY NO</th>
                          <th>INVOICE NO.</th>
                          <th>INVOICE YEAR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.length > 0 ?
                          reportData?.map((data) => {
                            return (
                              <tr>
                                <td>
                                  <Tooltip position="bottom" title={data?.userfullname}>
                                    {data?.userfullname}
                                  </Tooltip>
                                </td>
                                {data?.is_pr_exist ?
                                  <td>
                                    <Link
                                      to="/view-pr-approval"
                                      state={{
                                        from: data?.item_id,
                                        Itemsflag: true,
                                        view_level: "PR",
                                        master_flag: true,
                                        pr_mast_id: data?.pr_id,
                                      }}
                                      class="fw-bold text-theme"
                                    >
                                      {data?.BANFN}
                                    </Link>
                                  </td>
                                  :
                                  <td>
                                    {data?.BANFN}
                                  </td>
                                }
                                <td>{data?.BNFPO}</td>

                                {data?.source === "M" ?
                                  <td>MYPI</td>
                                  : data?.source === "E" ?
                                    <td>ELN</td>
                                    : data?.source === "S" ?
                                      <td>SAP</td>
                                      :
                                      <td>{data?.source}</td>
                                }
                                <td>{data?.BADAT}</td>
                                <td>{data?.FRGDT}</td>
                                <td>{data?.PR_MATNR}</td>
                                <td>{data?.TXZ01}</td>
                                <td>{data?.PR_QTY}</td>
                                <td>{data?.PR_MEINS}</td>
                                <td>{data?.EKGRP}</td>
                                <td>{data?.PR_WERKS}</td>
                                <td>{data?.PO_NO}</td>
                                <td>{data?.EBELP}</td>
                                <td>{data?.PO_QTY}</td>
                                <td>{data?.EINDT}</td>
                                <td>{data?.UDATE}</td>
                                <td>{data?.LIFNR}</td>
                                <td>{data?.NAME1}</td>
                                <td>{data?.GRN_BELNR}</td>
                                <td>{data?.GRN_BUDAT}</td>
                                <td>{data?.SERVICE_ENTRY}</td>
                                {/* <td>{data?.BANFN}</td> */}
                                <td>{data?.GATPSNO}</td>
                                <td>{data?.MIRO_BELNR}</td>
                                <td>{data?.MIRO_GJAHR}</td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={12}>No Records Found...</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control" onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1)
                        }}>
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                          <option value={500} label={500}>500</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              SAPPR NO<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter SAP PR NO"
              value={filterReport.sap_pr_no}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  sap_pr_no: e.target.value,
                })
              }
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Material Code<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Material Code"
              value={filterReport.mat_code}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  mat_code: e.target.value,
                })
              }
            />
            {/* <Select
              isMulti
              options={userOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterReport.user}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  user: e,
                })
              }
            /> */}
          </div>
          <div class="form-group innergroup">
            <label>
              Purchase Org<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Purchase Org"
              value={filterReport.purchase_org}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  purchase_org: e.target.value,
                })
              }
            />
            {/* <select
              class="form-control newbgselect"
              value={filterReport.source}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  source: e.target.value,
                })
              }
            >
              <option>Select</option>
              <option value={"M"}>MyPI</option>
              <option value={"E"}>ELN</option>
              <option value={"S"}>SAP</option>
            </select> */}
          </div>
          <div class="form-group innergroup">
            <label>Plant</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Plant"
              value={filterReport.plant}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  plant: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>From Date</label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  start_date: e.target.value,
                })
              }
              value={filterReport.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  end_date: e.target.value,
                })
              }
              value={filterReport.end_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => {
            resetFilter();
          }}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={() => mainDataFilter()}>Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrReportSap;
