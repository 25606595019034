import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { getUserRmList } from "../../service/jv_service";
import { getAllSesmaster, SyncSes } from "../../service/sesService";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as CommonService from "../../service/searchpo";
import { Tooltip } from "react-tippy";
import {
  selectUserData,
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
function ServiceEntryList() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const navigate = useNavigate();
  const options = [
    { value: "A", label: "Aproved" },
    { value: "D", label: "Rejected" },
    { value: "P", label: "Pending" },
  ];
  const [filterdata, setfilterData] = useState({
    sheetno: { value: 0 },
    Created_Date: "",
    Created_by: { value: "" },
    approver: { value: "" },
    status: { value: "P" },
  });
  const [search, setSearch] = useState("");
  const [sesno, setsesno] = useState([]);
  const [sesData, setSesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [userlist, setuserlist] = useState([]);
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const filteropen = () => filtersetShow(true);
  const [currentTab, setcurrentTab] = useState("P");
  const filterclose = () => filtersetShow(false);
  const resetFilterData = () =>
    setfilterData({
      sheetno: { value: 0 },
      Created_Date: "",
      Created_by: { value: "" },
      approver: { value: "" },
      status: { value: "P" },
    });
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getSes("", currentPage, pageSizeNo, true, "-id", 0, "", "", "P", "", true);
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.username };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getSes = (
    search,
    page_no,
    page_size,
    paginate,
    sort_by,
    sheet_no,
    created_at,
    created_by,
    status,
    approver_1,
    myses,
  ) => {
    dispatch(setDisplayLoader("Display"));

    getAllSesmaster(
      search,
      page_no,
      page_size,
      paginate,
      sort_by,
      sheet_no,
      created_at,
      created_by,
      status,
      approver_1,
      myses,
      false
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSesData(response?.data?.dataList?.result);
        let ses = [];
        response?.data?.dataList?.result.map((x) => {
          let data = { value: x.sesno, label: x?.sesno };
          ses.push(data);
        });
        setsesno(ses);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const SyncData = () => {
    SyncSes()
      .then((response) => {
        toast.success("Data Synced Successfully");
        getSes(
          "",
          currentPage,
          pageSizeNo,
          true,
          "-id",
          0,
          "",
          "",
          currentTab,
          "",
          true
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      0,
      "",
      "",
      currentTab,
      "",
      true
    );
    getUserList();
    getSapSchedule()
  }, []);
  const setCurrentTab = (e) => {
    setcurrentTab(e);
    getSes("", currentPage, pageSizeNo, true, "-id", 0, "", "", e, "", true);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSes(
      "",
      activePage,
      pageSizeNo,
      true,
      "-id",
      0,
      "",
      "",
      currentTab,
      "",
      true
    );
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    setcurrentTab(filterdata?.status.value);
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      true
    );
    filterclose();
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "sheetno":
        setfilterData((prev) => ({
          ...prev,
          sheetno: event,
        }));
        break;
      case "Created_Date":
        setfilterData((prev) => ({
          ...prev,
          Created_Date: event.target.value,
        }));
        break;
      case "Create_by":
        setfilterData((prev) => ({
          ...prev,
          Created_by: event,
        }));
        break;
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "status":
        setfilterData((prev) => ({
          ...prev,
          status: event,
        }));
        break;
    }
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getSes(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        true,
        "-id",
        0,
        "",
        "",
        currentTab,
        "",
        true
      );
      setSearch(e.target.value);
    }
  };

  useEffect(() => {
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      true
    );
  }, [pageSizeNo]);

  const getSapSchedule = () => {
    CommonService.get_sap_schedule('sync_post_ses_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    CommonService.get_sap_schedule('sync_get_ses_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />

      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/ses-search">Search</Link>
            </li>
            <li class="active">
              <Link to="/service-entry-sheet">Service Entry Sheet</Link>
            </li>
            <li>
              <Link to="/service-entry-all">All Pending</Link>
            </li>
            <li>
              <Link to="/service-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Pending Service Entry Sheet</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {/* <button
                      onClick={() => SyncData()}
                      class="btn btn-primary-inner"
                    >
                      <i class="fas fa-sync-alt mr-2 f-14"></i>SES Sync
                    </button> */}
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>
            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              {currentTab == "P" && (
                <>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Sheet No.</th>
                          <th>Date</th>
                          <th>Pending At</th>
                          <th>PO Number</th>
                          <th>Item</th>
                          <th>Posting Date</th>
                          <th>Amount</th>
                          <th>Rel. Strat</th>
                          <th>Sap status</th>
                          <th>SAP Log</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sesData.length != 0 ? (
                          sesData.map((x) => (
                            <tr>
                              <td class="text-theme">
                                <Link
                                  to="/approve-service"
                                  class="fw-bold text-theme"
                                  state={{ from: x?.sesno }}
                                >
                                  {x?.sesno}
                                </Link>
                              </td>
                              <td>{moment(x?.docdate).format("DD-MM-YYYY")}</td>
                              <td>{x?.requestedAt}</td>
                              <td>{x?.pono}</td>
                              <td>{x?.items.length}</td>
                              <td>
                                {moment(x?.postingdate).format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.totalvalue}</td>
                              <td>{x?.rel_strat}</td>
                              <td>
                                {x?.sap_status === true
                                  ? "Synced"
                                  : "Pending for synced"}
                              </td>
                              <td>
                                <Tooltip title={x?.log} position="bottom">
                                  {x?.log?.slice(0, 12)}...
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => setPageSizeNo(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </>
              )}

              {currentTab == "A" && (
                <>
                  {" "}
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Sheet No.</th>
                          <th>Date</th>
                          <th>PO Number</th>
                          <th>Item</th>
                          <th>Posting Date</th>
                          <th>Created By</th>
                          <th>User Name</th>
                          <th>Amount</th>
                          <th>Reference</th>
                          <th>Doc. Text</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sesData.length != 0 ? (
                          sesData.map((x) => (
                            <tr>
                              <td class="text-theme">
                                <Link
                                  to="/approve-service"
                                  class="fw-bold text-theme"
                                  state={{ from: x?.sesno }}
                                >
                                  {x?.sesno}
                                </Link>
                              </td>
                              <td>
                                {moment.utc(x?.docdate).format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.pono}</td>
                              <td>{x?.items.length}</td>
                              <td>
                                {moment
                                  .utc(x?.postingdatet)
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.created_by_name}</td>
                              <td>{x?.createdBy}</td>
                              <td>{x?.totalvalue}</td>
                              <td>{x?.reference}</td>
                              <td title="DETOX_Waste Disposal Charge_PN01">
                                {x?.doctext}
                              </td>
                              <td>
                                {x?.status === "A"
                                  ? "Approved"
                                  : x?.status === "D"
                                    ? "Rejected"
                                    : "Pending"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => setPageSizeNo(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </>
              )}

              {currentTab == "D" && (
                <>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Sheet No.</th>
                          <th>Date</th>
                          <th>Rejected By</th>
                          <th>PO Number</th>
                          <th>Item</th>
                          <th>Posting Date</th>
                          <th>Amount</th>
                          <th>Rel. Strat</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sesData.length != 0 ? (
                          sesData.map((x) => (
                            <tr>
                              <td class="text-theme">
                                <Link
                                  to="/approve-service"
                                  class="fw-bold text-theme"
                                  state={{ from: x?.sesno }}
                                >
                                  {x?.sesno}
                                </Link>
                              </td>
                              <td>
                                {moment.utc(x?.docdate).format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.requestedAt}</td>
                              <td>{x?.pono}</td>
                              <td>{x?.items.length}</td>
                              <td>
                                {moment
                                  .utc(x?.postingdate)
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td>{x?.totalvalue}</td>
                              <td>{x?.rel_strat}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onChange={(e) => setPageSizeNo(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={30}>30</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Search Sheet No.</label>
            <Select
              name="sheetno"
              options={sesno}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.sheetno}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created Date</label>
            <input
              value={filterdata?.Created_Date}
              name="Created_Date"
              onChange={handleChange}
              type="date"
              class="form-control"
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Created by</label>
            <Select
              //   isMulti
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="Create_by"
              value={filterdata?.Created_by}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>Select Approver</label>
            <Select
              //   isMulti
              name="approver"
              value={filterdata?.approver}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <Select
              //   isMulti
              name="status"
              value={filterdata?.status}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceEntryList;
