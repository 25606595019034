import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Accordion, Tabs, Tab } from "react-bootstrap";
import { getFaqCategory, getFaqs } from "../../service/faq";
import { useEffect } from "react";
import ReactQuill from "react-quill";

function FaqDashboard() {
  const [faqCategory, setFaqCategory] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    getFaqCategories();
  }, []);

  const getFaqList = async (allCategories, search) => {
    const queryParam = "paginate=" + false + "&search=" + search;
    await getFaqs(queryParam).then((response) => {
      if (response?.status === 200) {
        let data = allCategories?.map((cat) => {
          let curData = [];
          response?.data?.dataList?.result.map((obj) => {
            if (cat?.value === obj?.category__id) {
              curData.push(obj);
            }
          });
          return {
            category: cat?.label,
            faq: curData,
          };
        });
        console.log(data);
        setFaqData(data);
      }
    });
  };

  const getFaqCategories = async () => {
    await getFaqCategory().then((response) => {
      if (response?.status === 200) {
        let faqCat = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj.category,
            value: obj.id,
          };
        });
        setFaqCategory(faqCat);
        getFaqList(faqCat, "");
      }
    });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getFaqList(faqCategory, e.target.value.toLowerCase());
      setSearchStr(e.target.value);
    }
  };
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link to="/faqs">FAQs</Link>
            </li>
            {/* <li>
              <Link to="/admin-guide">Administrater Guide</Link>
            </li> */}
            <li>
              {/* <Link to="/raise-ticket">Raise Ticket</Link> */}
              <a
                target="blank"
                href="https://piindustries.symphonysummit.com/Summit_ssologin.aspx"
              >
                Raise Ticket
              </a>
            </li>
            <li>
              <Link to="/help-desk">IT Help Desk</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">FAQs</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Help</Breadcrumb.Item>
                    <Breadcrumb.Item active>Faqs</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-12">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs biginnertabs">
                <Tabs defaultActiveKey={`faq${1}`} id="approvaltabs">
                  {faqData
                    ?.filter((obj) => {
                      if (obj?.faq?.length !== 0) {
                        return true;
                      }
                    })
                    .map((data, index) => {
                      return (
                        <Tab
                          eventKey={`faq${index + 1}`}
                          title={
                            <React.Fragment>{data?.category}</React.Fragment>
                          }
                        >
                          <Accordion defaultActiveKey="1">
                            {data?.faq?.map((obj, i) => {
                              return (
                                <Accordion.Item eventKey={`${i + 1}`}>
                                  <Accordion.Header>
                                    <ReactQuill
                                      readOnly
                                      value={obj?.question}
                                      theme="bubble"
                                    />
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ReactQuill
                                      readOnly
                                      value={obj?.answer}
                                      theme="bubble"
                                    />
                                    {obj?.attachment?.map((x) => {
                                      return (
                                        <img
                                          src={x?.attachment}
                                          width="250px"
                                          class="border"
                                        />
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                        </Tab>
                      );
                    })}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqDashboard;
