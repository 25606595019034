import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  getOrganisation,
  getPolicy,
  submiteditinsurancematrix,
  uploadfile,
  getinsurancedetails,
  mediclaiminsurenceexportfile,
  deletemediclaim,
} from "../../service/mediclaim_brokerservice";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import * as moment from "moment";

const EditInsurance = (props) => {
  // console.log("Props",props)
  const {
    edit,
    editdata,
    org,
    policyname,
    editclose,
    editdataclose,
    getdata,
    fileUploadHandler,
    filename,
    file,
    fileurl,
  } = props;
  const [editshow, editsetShow] = useState(false);
  const editopen = () => editsetShow(true);
  // const editclose = () => editsetShow(false);
  const [comp_name, setComp_name] = useState("");
  const [policy_num, setpolicy_num] = useState("");
  const [amount, setAmount] = useState(0);
  const [startdate, setStartDate] = useState("");
  const [enddate, setenddate] = useState("");
  const [brokername, setBrokerName] = useState("");
  const [broker_email, setBroker_email] = useState("");
  const [brokercontact, setBrokerContact] = useState("");
  const [hremail, setHRemail] = useState("");
  const [remarks, setRemarks] = useState("");
  // const [filename, setfilename] = useState("")
  const [policyType, setpolicyType] = useState("");
  const [pol, setpol] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [SearchStr, setSearchStr] = useState("");
  // const [file, setfile] = useState({})
  const [company, setcompany] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [empNumber, setEmpNumber] = useState("");
  const [reminderDays, setReminderDays] = useState(0);
  const [sumInsured, setSumInsured] = useState("");
  // const [fileurl, setfileurl] = useState("")
  const [insurencedetails, setinsurancedetails] = useState(
    props.insurencedetails
  );

  const handleKey = (search, status, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  useEffect(() => {
    if (edit) {
      if (editdata) {
        editsetShow(true);
        // console.log("AllData",org)
        // console.log("editdata",editdata)
        let obj = {
          value: editdata?.organisation_id,
          label: editdata?.organisation_name,
        };
        setComp_name(obj);
        let obj1 = {
          value: editdata?.policy_master_id,
          label: editdata?.policy_master_name,
        };
        setpol(obj1);
      }
    }
  }, [editdata]);

  //   useEffect(()=>{

  //   },[insurencedetails])

  const submiteditinsurance = () => {
    // console.log("Fileedit",file)
    let data = {
      organisation_id: company === "" ? editdata.organisation_id : company,
      policy_master_id:
        policyType === "" ? editdata.policy_master_id : policyType,
      policy_number: policy_num === "" ? editdata.policy_number : policy_num,
      total_amount: amount === 0 ? editdata.total_amount : amount,
      policy_start_date:
        startdate === "" ? editdata.policy_start_date : startdate,
      policy_end_date: enddate === "" ? editdata.policy_end_date : enddate,
      broker_name: brokername === "" ? editdata.broker_name : brokername,
      broker_email: broker_email === "" ? editdata.broker_email : broker_email,
      broker_contacts:
        brokercontact === "" ? editdata.broker_contacts : brokercontact,
      insurance_company:
        insuranceCompany === "" ? editdata?.insuranceCompany : insuranceCompany,
      emp_number: empNumber === "" ? editdata?.empNumber : empNumber,
      sum_insured: sumInsured === "" ? editdata?.sumInsured : sumInsured,
      reminder_days:
        reminderDays === "" ? editdata?.reminderDays : reminderDays,
      hr_mails: hremail === "" ? editdata.hr_mails : hremail,
      remarks: remarks === "" ? editdata.remarks : remarks,
      attachment: file === "" ? editdata.attachment[0] : file,
    };
    submiteditinsurancematrix(data, editdata.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          // console.log("submitinsurancematrix", response)
          setcompany("");
          setComp_name("");
          setpolicy_num("");
          setAmount(0);
          setStartDate("");
          setenddate("");
          setBrokerName("");
          setHRemail("");
          setRemarks("");
          // setfile({})
          // setfilename("")
          // setfileurl("")
          editsetShow(false);
          editdataclose();
          editclose();
          getdata();
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // const getmediclaininsurancelist = (
  //     search,
  //     activePage,
  //     pageSizeNo,
  //     paginate,
  //     pageNo,
  //     page_size,
  //     sort_by,

  // ) => {
  //     getinsurancedetails(
  //         handleKey(
  //             search,
  //             activePage,
  //             pageSizeNo,
  //             paginate,
  //             pageNo,
  //             page_size,
  //             sort_by,

  //         )
  //     ).then(
  //         (response) => {

  //             if (response?.status === 200) {
  //                 console.log("Editpage>>>>>>>>>>>>>>>>>>>>>>>>>", response)
  //                 setinsurancedetails(response?.data?.dataList?.result)
  //                 setpageCount(response?.data?.dataList?.paginated_data?.totalPages);

  //             }
  //         }
  //     );

  // };

  // const fileUploadHandler = (e) => {
  //     // console.log("GIleeea",e.target.files)

  //     // setfile(e.target.files)
  //     let file = e.target.files;
  //     // setfile(file);
  //     let files = [];
  //     let filename = [];
  //     let myfile = [];
  //     for (let i = 0; i < file.length; i++) {
  //         let data = e.target.files[i];
  //         //   console.log("Datat",data)
  //         let myUrl = URL.createObjectURL(e.target.files[i]);
  //         //   console.log("URs",myUrl)
  //         setfileurl(myUrl)
  //         myfile.push(myUrl);
  //         const fileData = new FormData();
  //         fileData.append("uploaded_file", data);
  //         uploadfile(fileData).then((response) => {
  //             console.log("Fileresponseedit",response.data)
  //             if (response.data.status == true) {
  //                 setfilename(e.target.files[0].name)
  //                 setfile(response.data)
  //             }

  //         });
  //     }
  // }

  return (
    <>
      <Modal
        show={editshow}
        onHide={editclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Insurance Matrix Broker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Comapny Name<span class="text-danger">*</span>
                    </label>

                    <Select
                      options={org}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setcompany(e.value);
                        setComp_name(e);
                      }}
                      value={comp_name}
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Policy Type<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={policyname}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        setpolicyType(e.value);
                        setpol(e);
                      }}
                      value={pol}
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Policy Number<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Policy Number"
                      onChange={(e) => {
                        setpolicy_num(e.target.value);
                      }}
                      defaultValue={editdata?.policy_number}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      defaultValue={editdata?.total_amount}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Sum Insured<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Sum Insured"
                      onChange={(e) => {
                        setSumInsured(e.target.value);
                      }}
                      defaultValue={editdata?.sumInsured}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Number of Employees<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Number of Employees"
                      onChange={(e) => {
                        setEmpNumber(e.target.value);
                      }}
                      defaultValue={editdata?.empNumber}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Start Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Enter Start Date"
                      min={moment(new Date())
                        .subtract(1, "years")
                        .format("YYYY-MM-DD")}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      defaultValue={editdata?.policy_start_date}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      End Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Enter End Date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      max={moment(new Date())
                        .add(5, "years")
                        .format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setenddate(e.target.value);
                      }}
                      defaultValue={editdata?.policy_end_date}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Reminder From (days)<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Reminder Days"
                      onChange={(e) => {
                        setReminderDays(e.target.value);
                      }}
                      defaultValue={editdata?.reminderDays}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Broker Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Broker Name"
                      onChange={(e) => {
                        setBrokerName(e.target.value);
                      }}
                      defaultValue={editdata?.broker_name}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Broker Email-Id<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Broker Email-Id"
                      onChange={(e) => {
                        setBroker_email(e.target.value);
                      }}
                      defaultValue={editdata?.broker_email}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Insurance Company<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Broker Name"
                      onChange={(e) => {
                        setInsuranceCompany(e.target.value);
                      }}
                      defaultValue={editdata?.insuranceCompany}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Broker Contacts<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Broker Contacts"
                      onChange={(e) => {
                        setBrokerContact(e.target.value);
                      }}
                      defaultValue={editdata?.policy_end_date}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      HR Email<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter HR Email"
                      onChange={(e) => {
                        setHRemail(e.target.value);
                      }}
                      defaultValue={editdata?.hr_mails}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup position-relative">
                    <label>
                      Attachment<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control bg-white"
                      disabled
                      placeholder={
                        filename?.length > 0
                          ? filename
                          : "JPG,PNG,PDF Format Only"
                      }
                    />
                    <div class="upload-btn-wrapper up-loposition">
                      <button class="uploadBtn">Browse</button>
                      <input
                        type="file"
                        accept="application/pdf,image/png,image/jpeg"
                        onChange={(e) => fileUploadHandler(e)}
                      />
                    </div>
                    {/* <p>Selected files <span>{fileurl ==""?(editdata?.attachment[0]?.attachment.split('/').pop()):{filename}}</span></p> */}
                    <p>
                      Selected files{" "}
                      <span>
                        {fileurl != "" ? (
                          <a href={fileurl} target="_blank" rel="noreferrer">
                            {filename}
                          </a>
                        ) : (
                          editdata?.attachment[0]?.attachment.split("/").pop()
                        )}
                      </span>
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Remarks<span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      placeholder="Enter  Remarks"
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      defaultValue={editdata.remarks}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={editclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={submiteditinsurance}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default EditInsurance;
