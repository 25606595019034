import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as TravelLists from "../../service/travellist";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserData,
  setDisplayLoader,
  setMessage,
} from "../redux/piDataStore";
import { confirmAlert } from "react-confirm-alert";
import ViewTravelInt from "./ViewTravelInt";
import TravelIntimation from "./TravelIntimation";
import ViewTravelAll from "./Domestic/ViewTravelAll";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from "../CommonComp/ConfirmModal";
import ViewTravelInter from "./International/ViewTravelInter";
import { Tooltip } from "react-tippy";
import SapStatusModal from "./sapStatusModal";
import * as DomesticService from "../../service/domestic";

function TravelListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [travelLogModal, setTravelLogModal] = useState(false);
  const [travelLogList, setTravelLogList] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [viewAllForm, setViewAllForm] = useState(false);
  const [viewInternationalForm, setViewInternationalForm] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [show, filtersetShow] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-from_date",
  });
  const [travelList, setTravelList] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [condition, setCondition] = useState("");
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [cityDataTo, setCityDataTo] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendIdData, setSendIdData] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [viewSapData, setViewSapData] = useState();
  const [dateMoment, setDateMoment] = useState();
  const [filterTravel, setFilterTravel] = useState({
    user_id: "",
    from_date: "",
    request_type: "",
    status: "",
  });
  const [userBackDate, setUserBackDate] = useState([]);
  const userDetails = useSelector(selectUserData);
  const [allGridData, setAllGridData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [visible, setVisible] = useState({ flag: false, id: "", data: "" });
  const [confirmSapModal, setConfirmSapModal] = useState(false);
  let date = new Date();
  let valueDate = moment(date).format("YYYY-MM-DD");

  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,

    user_id,
    sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date,
    sort_by2
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        sap_trip_flag,
        status,
        travel_id,
        request_type,
        from_date,
        to_date,
        sort_by2
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setTravelList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        dispatch(setMessage(response?.data?.dataList?.result));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date,
    sort_by2
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (sort_by2 !== "" && sort_by2 !== undefined && sort_by2 !== null) {
      queryParm = queryParm + "&sort_by_2=" + sort_by2;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      sap_trip_flag !== "" &&
      sap_trip_flag !== undefined &&
      sap_trip_flag !== null
    ) {
      queryParm = queryParm + "&sap_trip_flag=" + sap_trip_flag;
    }

    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  var todayD = new Date();
  var timeD =
    todayD.getHours() + ":" + todayD.getMinutes() + ":" + todayD.getSeconds();
  const getUserDetails = async () => {
    await TravelListingAPI.getUserDetails()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.username,
            label: `${obj?.first_name}`,
          };
        });
        setUserDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onConfirmClose = () => {
    setConfirmModal(false);
  };
  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityData(res);
        setCityDataTo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const TravelSapNumber = (id, data) => {
    TravelLists.TravelSapNumber(id)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success(res?.data?.message);

          let dataV = JSON.parse(JSON.stringify(data));
          dataV.sap_trip_number = res?.data?.message;
          navigate("/expense-claim", {
            state: dataV,
          });
          onCallGrid();
        } else if (res?.data?.code == 400) {
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBackDateData = async () => {
    let queryParam = `user_id=${userDetails?.id}`;
    await DomesticService.getBackDateData(queryParam).then((response) => {
      if (response?.status === 200) {
        setUserBackDate(response?.data?.dataList?.result);
        if (response?.data?.dataList?.result?.length > 0) {
          let userBackDate = response?.data?.dataList?.result[0];
          let fromDate = moment(userBackDate?.from_date);
          let toDate = moment(userBackDate?.to_date);
          let today = moment(new Date());
          if (today.isBetween(fromDate, toDate, "date", "[]")) {
            // setAllowBackDate(true);
          }
        }
      }
    });
  };

  const onViewData = (data) => {
    setViewSapData(data);
    setConfirmSapModal(true);
  };

  const onClose = () => {
    setConfirmSapModal(false);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False"
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getTravelList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "False"
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "False"
      );
    }
  };

  const onView = (data) => {
    setViewFormData(data);
    if (data.request_type === "Domestic") {
      setViewAllForm(true);
    } else if (data.request_type === "On Duty") {
      setViewForm(true);
    } else if (data.request_type === "International") {
      setViewInternationalForm(true);
    }
  };

  const OnEditGrid = (data) => {
    if (data?.request_type == "International") {
      setCondition("International");
    } else if (data?.request_type == "Domestic") {
      setCondition("Domestic");
    }
    setMigrate(true);
    setAllData(data);
    setAllGridData(data?.travel_detail_data);
  };

  const onCloseForm = () => {
    setMigrate(false);
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False",
      "",
      "",
      "",
      "",
      "",
      "from_date"
    );
  };

  const onViewClose = () => {
    setViewForm(false);
    setViewInternationalForm(false);
    setMigrate(false);
    setViewAllForm(false);
    clearFilter();
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False"
    );
  };

  const filterClose = () => {
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterTravel));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterTravel(ClearData);
    filterClose();
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterTravel.from_date;
    const user_id = filterTravel.user_id;
    const request_type = filterTravel.request_type;
    const to_date = filterTravel.to_date;
    const status = filterTravel.status;

    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False",
      status,
      "",
      request_type,
      from_date,
      to_date
    );
    filterClose();
  };

  const onCallGrid = () => {
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False"
    );
  };

  const deleteTravel = (id) => {
    TravelListingAPI.deleteTravel(id)
      .then((res) => {
        if (res.data.status == true) {
          onCallGrid();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete Travel",
      message: `Are you sure to delete travel?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteTravel(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const submitEdit = (data) => {
    confirmAlert({
      title: "Confirm",
      message: `Do you want to change this?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => OnEditGrid(data),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const sendId = (id) => {
    setSendIdData(id);
    setConfirmModal(true);
  };

  const [mainData, setMainData] = useState([]);

  const getTravelListFk = (travel_id) => {
    TravelListingAPI.getTravelListFk(travel_id)
      .then((response) => {
        if (response?.data?.dataList?.result.length == 0) {
          onCallGrid();
          setMigrate(false);
        }
        setMainData(response?.data?.dataList?.result[0].travel_detail_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onCallBackTravel = (travel_id) => {
    getTravelListFk(travel_id);
  };
  const handleClose = () => {
    setVisible({ ...visible, flag: false });
    // callBackCancel();
  };
  useEffect(() => {
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "False",
      "",
      "",
      "",
      "",
      "",
      "from_date"
    );
  }, [pageSizeNo]);

  const sapTripPopup = (id, data) => {
    confirmAlert({
      title: "",
      message: `You are Generating SAP trip No, After this you will not able to change tour.`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            setVisible({ ...visible, flag: true, id: id, data: data }),
        },
        {
          label: "No",
        },
      ],
    });
  };
  // 'Notre- Travel Expense should be matched with travel intimation created by employee'
  useEffect(() => {
    getCityList(false);
    getUserDetails();
    getBackDateData();
  }, []);
  // const td = new Date();
  // const tdd = moment(tdd).format("DD-MM-YYYY");
  // const fdd = moment(travelList?.travel_detail_data[0]?.to_date).format(
  //   "DD-MM-YYYY"
  // );
  // const ChkReqClaim = () => {
  //   if (tdd > fdd) {
  //     return true;
  //   }
  // };
  // ChkReqClaim();

  const checkAttendenceCircle = (intimation_to_date) => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    let intimateDate = new Date(
      new Date(intimation_to_date).getFullYear(),
      new Date(intimation_to_date).getMonth(),
      new Date(intimation_to_date).getDate()
    );
    let monthCurrent = new Date(`${year}-${month + 1}-22`);
    if (
      new Date(`${year}-${month + 1}-${day}`) <=
      new Date(`${year}-${month + 1}-22`)
    ) {
      if (
        new Date(intimation_to_date) >=
        new Date(
          `${month == 0 ? year - 1 : year}-${month == 0 ? 12 : month}-22`
        )
      )
        return true;
    } else if (
      new Date(`${year}-${month + 1}-${day}`) >
      new Date(`${year}-${month + 1}-22`)
    ) {
      console.log(
        new Date(intimation_to_date),
        new Date(`${year}-${month + 1}-21`),
        new Date(intimation_to_date),
        new Date(`${year}-${month == 11 ? 1 : month + 2}-22`),
        "checkAttendenceCircle22"
      );
      if (
        new Date(intimation_to_date) > new Date(`${year}-${month + 1}-21`)
        // &&
        // new Date(intimation_to_date) <=
        //   new Date(`${year}-${month == 11 ? 1 : month + 2}-22`)
      )
        return true;
    }
    return false;
    // if (
    //   new Date() <
    //   new Date(`${new Date().getFullYear()}-${new Date().getMonth()}-21`)
    // ) {
    //   if (
    //     new Date(
    //       moment(new Date()).subtract(1, "months").format("YYYY-MM-DD")
    //     ) < new Date(intimation_to_date) &&
    //     new Date(intimation_to_date) <
    //       new Date(`${new Date().getFullYear()}-${new Date().getMonth()}-21`)
    //   ) {
    //     return true;
    //   } else return false;
    // } else {
    //   if (
    //     new Date(`${new Date().getFullYear()}-${new Date().getMonth()}-21`) <=
    //       new Date(intimation_to_date) &&
    //     new Date(intimation_to_date) <=
    //       new Date(moment(new Date()).add(1, "months").format("YYYY-MM-DD"))
    //   ) {
    //     return true;
    //   } else return false;
    // }
  };

  console.log(checkAttendenceCircle, "checkAttendenceCircle");

  const getTravelLogValueList = (id) => {
    dispatch(setDisplayLoader("Display"));
    TravelLists.getTravelLogValueList(
      `sort_by=id&paginate=false&travel_id=${id}`
    )
      .then((response) => {
        setTravelLogList(response?.data?.dataList?.result);
        setTravelLogModal(true);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let tdDate = "";
    travelList?.map((value, index) => {
      tdDate = moment(
        value?.travel_detail_data[value?.travel_detail_data.length - 1]?.to_date
      ).format("YYYY-MM-DD");
    });
    setDateMoment(tdDate);
  }, [travelList]);

  useEffect(() => {
    dispatch(setMessage(visible?.data));
  }, [visible]);

  return (
    <>
      {viewForm ? (
        <>
          <ViewTravelInt
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : viewAllForm ? (
        <>
          <ViewTravelAll
            viewFormData={viewFormData}
            userDetails={userDetails}
            onViewClose={onViewClose}
          />
        </>
      ) : viewInternationalForm ? (
        <>
          <ViewTravelInter
            viewFormData={viewFormData}
            onViewClose={onViewClose}
          />
        </>
      ) : migrate ? (
        <>
          <TravelIntimation
            condition={condition}
            setCondition={setCondition}
            allGridDatas={allGridData}
            allData={allData}
            onViewClose={onViewClose}
            migrate={migrate}
            onCallBackTravel={onCallBackTravel}
            onCloseForm={onCloseForm}
            mainData={mainData}
          />
        </>
      ) : (
        <>
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <div class="col-md-5" style={{ float: "left" }}>
                        {" "}
                        <h4 class="inner-page-title">
                          My Travel Intimations - {pageRecord}
                        </h4>
                      </div>
                      <div
                        class="col-md-7"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          float: "right",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          color: "#0195d4",
                        }}
                      >
                        <p>
                          Attendance Cycle is closed by 22nd of every month, if
                          you want to edit travel intimation please contact
                          your's BHR
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search on Travel Type..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <button
                          className="btn btn-primary-inner"
                          onClick={() => {
                            navigate("/travel-intimation");
                          }}
                        >
                          <img
                            src="images/upload.png"
                            alt=""
                            className="mr-3"
                          />
                          Add Travel
                        </button>
                        <button
                          onClick={filteropen}
                          class="btn btn-secondary-inner"
                          style={{
                            backgroundColor: filterApplied && "yellow",
                            color:
                              filterApplied &&
                              userDetails?.dark_mode === false &&
                              "#000",
                          }}
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured travelintim">
                    <thead>
                      <tr>
                        <th>Actions</th>
                        <th>Req. To Claim</th>
                        <th>Travel Type</th>
                        <th>Departure Date</th>
                        <th>Arrival Date</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Exception</th>
                        <th>Approver Name</th>
                        <th>Approved/Rejected On</th>
                        <th>Savior Status</th>
                        <th>Request Advance Amount</th>
                        <th>Total Advance Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travelList?.length ? (
                        travelList?.map((data, index) => (
                          <tr>
                            <td
                              className="d-flex justify-content-around"
                              style={{ width: 100 }}
                            >
                              {
                                // ((data?.request_type == "On Duty" &&
                                //     data?.status != "R") ||
                                //     data?.status == "A") &&
                                //   `${userBackDate?.length} > 0
                                //     ? ${moment(
                                //       data?.travel_detail_data[
                                //         data?.travel_detail_data.length - 1
                                //       ]?.to_date
                                //     ).format("YYYY-MM-DD")}
                                //     : ${moment(
                                //       userBackDate[0]?.opened_from
                                //     ).format("YYYY-MM-DD")}` >=
                                //     `${moment(new Date()).format(
                                //       "YYYY"
                                //     )}-${moment(new Date()).format("MM")}-21` &&
                                //   data?.status != "CA" &&
                                //   data?.status != "C" ? (
                                //   <button
                                //     class="btn btn-dangerNew"
                                //     onClick={() => {
                                //       sendId(data?.id);
                                //     }}
                                //   >
                                //     <i class="far fa-times-circle"></i>
                                //   </button>
                                // )
                                data?.status == "P" || data?.status == "A" ? (
                                  //   moment(
                                  //   userBackDate[userBackDate?.length - 1]
                                  //     ?.to_date
                                  // ).isBefore(valueDate) ||
                                  ((moment(
                                    userBackDate[userBackDate?.length - 1]
                                      ?.to_date
                                  ).isSame(valueDate) ||
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ).isAfter(valueDate) ||
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ).isSame(valueDate)) &&
                                    userBackDate?.length > 0 &&
                                    moment(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ).isBefore(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ) &&
                                    moment(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ).isAfter(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.opened_from
                                    )) ||
                                  moment(
                                    data?.travel_detail_data[
                                      data?.travel_detail_data.length - 1
                                    ]?.to_date
                                  ).isSame(
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.opened_from
                                    )
                                  ) ? (
                                    <button
                                      class="btn btn-blanktd btn-dangerNew"
                                      onClick={() => {
                                        sendId(data?.id);
                                      }}
                                    >
                                      <i class="far fa-times-circle"></i>
                                    </button>
                                  ) : (
                                    checkAttendenceCircle(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ) && (
                                      <button
                                        class="btn btn-blanktd btn-dangerNew"
                                        onClick={() => {
                                          sendId(data?.id);
                                        }}
                                      >
                                        {console.log(
                                          "checkAttendenceCircle111"
                                        )}
                                        <i class="far fa-times-circle"></i>
                                      </button>
                                    )
                                  )
                                ) : (
                                  ""
                                )

                                // :

                                // (data?.status == "Cancelled" ||
                                //       data?.status == "C" ||
                                //       data?.status == "R" ||
                                //       data?.status == "A") &&
                                //     `${userBackDate?.length} > 0
                                //       ? ${moment(
                                //         data?.travel_detail_data[
                                //           data?.travel_detail_data.length - 1
                                //         ]?.to_date
                                //       ).format("YYYY-MM-DD")}
                                //       : ${moment(userBackDate[0]?.opened_from).format(
                                //         "YYYY-MM-DD"
                                //       )}` <
                                //       `${moment(new Date()).format("YYYY")}-${moment(
                                //         new Date()
                                //       ).format("MM")}-21` ?
                                //       (
                                //       ""
                                //     )

                                //     : (
                                //       ""
                                //     )
                              }
                              {
                                // (data?.sap_trip_number === null &&
                                //   data?.request_type != "On Duty" &&
                                //   data?.status != "C" &&
                                //   `${userBackDate?.length} > 0
                                //   ? ${moment(
                                //     data?.travel_detail_data[
                                //       data?.travel_detail_data.length - 1
                                //     ]?.to_date
                                //   ).format("YYYY-MM-DD")}
                                //   : ${moment(userBackDate[0]?.opened_from).format(
                                //     "YYYY-MM-DD"
                                //   )}` >=
                                //     `${moment(new Date()).format(
                                //       "YYYY"
                                //     )}-${moment(new Date()).format("MM")}-21`) || data?.status === 'D'

                                data?.status !== "C" &&
                                data?.request_type != "On Duty" ? (
                                  //   moment(
                                  //   userBackDate[userBackDate?.length - 1]
                                  //     ?.to_date
                                  // ).isBefore(valueDate) ||
                                  ((moment(
                                    userBackDate[userBackDate?.length - 1]
                                      ?.to_date
                                  ).isSame(valueDate) ||
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ).isAfter(valueDate) ||
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ).isSame(valueDate)) &&
                                    userBackDate?.length > 0 &&
                                    moment(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ).isBefore(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    ) &&
                                    moment(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ).isAfter(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.opened_from
                                    )) ||
                                  moment(
                                    data?.travel_detail_data[
                                      data?.travel_detail_data.length - 1
                                    ]?.to_date
                                  ).isSame(
                                    moment(
                                      userBackDate[userBackDate?.length - 1]
                                        ?.to_date
                                    )
                                  ) ? (
                                    <button
                                      class="btn btn-blanktd text-primary"
                                      type="button"
                                      onClick={() => {
                                        submitEdit(data);
                                      }}
                                    >
                                      <i class="far fa-edit"></i>
                                    </button>
                                  ) : (
                                    checkAttendenceCircle(
                                      data?.travel_detail_data[
                                        data?.travel_detail_data.length - 1
                                      ]?.to_date
                                    ) && (
                                      <button
                                        class="btn btn-blanktd text-primary"
                                        type="button"
                                        onClick={() => {
                                          submitEdit(data);
                                        }}
                                      >
                                        <i class="far fa-edit"></i>
                                      </button>
                                    )
                                  )
                                ) : (
                                  ""
                                )
                              }
                              {/* {data?.request_type != "On Duty" ? (
                                <button
                                  class="btn btn-dangerNew"
                                  onClick={() => {
                                    sendId(data?.id);
                                  }}
                                >
                                  <i class="far fa-times-circle"></i>
                                </button>
                              ) : (
                                ""
                              )} */}
                            </td>

                            <td>
                              {/* <Link to="/expense-claim" class="mr-2"> */}
                              {data?.request_type !== "On Duty" &&
                              data?.status == "A" &&
                              (data?.sap_trip_number === null ||
                                data?.sap_trip_number === "") &&
                              moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ).format("YYYY-MM-DD") <
                                moment(new Date()).format("YYYY-MM-DD") ===
                                true ? (
                                <button
                                  class="btn btn-primary-inner bpi-main f-14 py-1"
                                  onClick={() => sapTripPopup(data?.id, data)}
                                >
                                  <i class="far fa-check-circle"></i>
                                  Claim
                                </button>
                              ) : moment(
                                  data?.travel_detail_data[
                                    data?.travel_detail_data?.length - 1
                                  ]?.to_date
                                ).format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD") &&
                                data?.request_type !== "On Duty" &&
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_time < timeD &&
                                data?.status == "A" ? (
                                <button
                                  class="btn btn-primary-inner bpi-main f-14 py-1"
                                  onClick={() => sapTripPopup(data?.id, data)}
                                >
                                  <i class="far fa-check-circle"></i>
                                  Claim
                                </button>
                              ) : (
                                ""
                              )}
                              {/* </Link> */}
                            </td>
                            <td
                              class="fw-bold text-theme cursor-pointer"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              {data?.request_type == "On Duty"
                                ? "In Station"
                                : data?.request_type == "International"
                                ? "Foreign"
                                : data?.request_type}
                            </td>

                            {/* moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ) < moment(new Date()) */}

                            {/* moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ).isAfter(moment(new Date())) === "false"  */}

                            <td>
                              {moment(
                                data?.travel_detail_data[0]?.from_date
                              ).format("DD-MM-YYYY")}{" "}
                              {moment(
                                data?.travel_detail_data[0]?.from_time,
                                "hh:mm "
                              ).format("hh:mm A")}
                            </td>
                            <td>
                              {moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ).format("DD-MM-YYYY")}{" "}
                              {moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_time,
                                "hh:mm "
                              ).format("hh:mm A")}
                            </td>
                            <td>
                              {data?.request_type == "On Duty"
                                ? data?.travel_detail_data[0]?.to_city_input
                                : data?.travel_detail_data[0]?.to_city_name ===
                                  "Other"
                                ? data?.travel_detail_data[0]?.to_city_input
                                : data?.travel_detail_data[0]?.to_city_name +
                                  " - " +
                                  data?.travel_detail_data[0]?.to_city_category}
                            </td>
                            <td>
                              {data?.status == "A" ? (
                                <span class="badge bad-status badge-success">
                                  Travel Intimation Approved
                                </span>
                              ) : data?.status == "P" ||
                                data?.status == null ||
                                data?.status == "pending" ? (
                                <span class="badge bad-status badge-warning">
                                  Pending for Approval
                                </span>
                              ) : data?.status == "R" ? (
                                <span class="badge bad-status badge-danger">
                                  Travel Intimation Reject
                                </span>
                              ) : data?.status == "D" ||
                                data?.status == "draft" ? (
                                <span class="badge bad-status badge-danger">
                                  Pending for Submission
                                </span>
                              ) : data?.status == "C" ? (
                                <span class="badge bad-status badge-danger">
                                  Travel Intimation Cancelled
                                </span>
                              ) : data?.status == "CA" ? (
                                <span class="badge bad-status badge-danger">
                                  Cancellation Approval Pending
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              style={{
                                maxWidth: "40px",
                              }}
                            >
                              {" "}
                              {data?.is_exception ? (
                                <i
                                  class="fa fa-flag"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                ></i>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Tooltip
                                title={`${data?.current_approver_data?.current_approver_name} (${data?.current_approver_data?.current_approver_code})`}
                                position="bottom"
                              >
                                {data?.current_approver_data
                                  ?.current_approver_name && data?.status != "C"
                                  ? `${data?.current_approver_data?.current_approver_name} (${data?.current_approver_data?.current_approver_code})`
                                  : ""}{" "}
                              </Tooltip>
                            </td>
                            <td>
                              {data?.status == "P" ||
                              data?.status == "Pending" ||
                              data?.status == "pending" ||
                              data?.status == "D" ||
                              data?.status == "draft" ||
                              data?.status == "C"
                                ? ""
                                : moment(data?.updated_at)
                                    .utc()
                                    .format("DD-MM-YYYY hh:mm A")}{" "}
                              {/* {data?.status == "P" ||
                              data?.status == "Pending" ||
                              data?.status == "pending" ||
                              data?.status == "D" ||
                              data?.status == "draft" ||
                              data?.status == "C"
                                ? ""
                                : data?.updated_at?.slice(11, 16)} */}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                onClick={() => onViewData(data)}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            </td>
                            <td>
                              {data?.advance_amount === 0
                                ? ""
                                : data?.advance_amount === null
                                ? ""
                                : data?.advance_amount}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                onClick={() => getTravelLogValueList(data.id)}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            </td>

                            {/* <td>{data?.is_exception}</td> */}

                            {/*                           
                            <td>
                              {calculateDateDifference(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date,
                                data?.travel_detail_data[0]?.from_date
                              )}
                            </td>
                            */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={16}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {travelList?.length == 0 ? (
                  ""
                ) : (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="form-group innergroup">
                <label>
                  From Date<span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  onChange={(e) =>
                    setFilterTravel({
                      ...filterTravel,
                      from_date: e.target.value,
                    })
                  }
                  value={filterTravel.from_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>Travel Type</label>
                <select
                  name="request_type"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      request_type: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.request_type}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {/* <option value="">All</option> */}
                  <option value="On Duty">In Station</option>
                  <option value="Domestic">Domestic</option>
                  <option value="International">Foreign</option>
                </select>
              </div>

              <div class="form-group innergroup">
                <label>Status</label>
                <select
                  name="status"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      status: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.status}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="R">Reject</option>
                  <option value="C">Cancelled</option>
                </select>
              </div>

              {/* <div class="form-group innergroup">
                <label>
                  Select Status<span class="text-danger">*</span>
                </label>
                <select class="form-control newbgselect">
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Rejected</option>
                  <option>Approved</option>
                </select>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => clearFilter()}
              >
                Clear
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={visible.flag}
            onHide={() => handleClose}
            style={{
              fontSize: "14px",
              marginTop: "200px",
              padding: "20px",
              width: "400px",
              marginLeft: "440px",
            }}
          >
            <Modal.Body style={{}}>
              Travel Expense should be matched with travel intimation created by
              employee
            </Modal.Body>
            <Modal.Footer>
              {/* {showCancel && ( */}
              <button
                type="button"
                className="btn btn-outline-secondary"
                variant="primary"
                style={{
                  background: "#0195d4",
                  borderColor: "#0195d4",
                  color: "#fff",
                  fontSize: "12px",
                }}
                onClick={() => TravelSapNumber(visible?.id, visible?.data)}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={() => handleClose(false)}
                style={{
                  background: "#0195d4",
                  borderColor: "#0195d4",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                No
              </button>
              {/* )} */}
            </Modal.Footer>
          </Modal>
          <Modal
            show={addnewshow}
            onHide={addnewclose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                      <p class="fa-20 text-muted mb-4">
                        You are generating SAP Trip No, After this you will not
                        be able to change tour
                      </p>

                      <div class="mb-3">
                        <Link to="/claim-listing" class="mr-2">
                          <button class="btn btn-primary-inner bpi-main f-14 py-1">
                            Yes
                          </button>
                        </Link>
                        <button
                          class="btn btn-outline-primary f-14 py-1"
                          onClick={addnewclose}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={travelLogModal}
            // onHide={() => setTravelLogModal(false)}
            size="lg"
          >
            <Modal.Header className="d-flex justify-content-between">
              <h5 className="fw-bold text-theme">Advance Amount Logs</h5>
              <i
                className="fas fa-times"
                style={{ cursor: "pointer" }}
                onClick={() => setTravelLogModal(false)}
              ></i>
            </Modal.Header>
            <Modal.Body>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Advance</th>
                      <th>Sap Id</th>
                      <th>Sap Message</th>
                      <th>Sap Status</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {travelLogList?.length ? (
                      travelLogList?.map((data, index) => (
                        <tr>
                          <td
                            style={{
                              whiteSpace: "pre",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              whiteSpace: "pre",
                            }}
                            class="text-theme fw-bold"
                          >
                            {data?.advance}
                          </td>
                          <td>{data?.sap_id}</td>
                          <td title={data?.advance_sap_message}>
                            {data?.advance_sap_message}
                          </td>
                          <td>
                            {data?.sap_status == "S"
                              ? "Posted"
                              : data?.sap_status}
                          </td>
                          <td>
                            {moment
                              .utc(data?.created_at)
                              .format("DD-MM-YYYY,HH:mm")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      {confirmModal && (
        <ConfirmModal
          confirmModal={confirmModal}
          onConfirmClose={onConfirmClose}
          sendIdData={sendIdData}
          getTravelList={getTravelList}
        />
      )}

      {confirmSapModal && (
        <SapStatusModal
          viewSapData={viewSapData}
          confirmSapModal={confirmSapModal}
          onClose={onClose}
        />
      )}
    </>
  );
}

export default TravelListing;
