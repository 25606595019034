import React, { useEffect, useState } from "react";
import VehicalForm from "./JivagroVehicalForm";
import * as VehicalService from "../../service/VehicalService";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Modal, Tabs, Tab, Dropdown, OverlayTrigger } from "react-bootstrap";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function JivagroAddVehicalAll() {
  const dispatch = useDispatch();
  const [viewFlag, setviewFlag] = useState(false);
  const [editData, setEditVehicleData] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [addnewshow, addnewsetShow] = useState(false);
  const [viewTerminateFlag, setViewTerminateFlag] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterVehicle, setFilterVehicle] = useState({
    user_id: {value:undefined},
    from_date: "",
    to_date: "",
    status: "",
    vehicle_id: "",
    terminated_on:"",
  });
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    from_date,
    to_date,
    status,
    user_id,
    vehicle_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (vehicle_id !== "" && vehicle_id !== undefined && vehicle_id !== null) {
      queryParm = queryParm + "&vehicle_id=" + vehicle_id;
    }

    return queryParm;
  };

  const getData = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    terminated_on,
    from_date,
    to_date,
    status,
    user_id,
    vehicle_id
  ) => {
    dispatch(setDisplayLoader("Display"));

    VehicalService.getVehicleReqAll(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        from_date,
        to_date,
        status,
        user_id,
        vehicle_id
      )+`&terminated_on=${terminated_on}`
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.result;
        setVehicleData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const editNotice = (data, ViewFlag) => {
    setEditVehicleData(data);
    setEditForm(true);
    addnewopen();
    setviewFlag(ViewFlag);
    setViewTerminateFlag(true);
  };
  const addnewopenForm = () => {
    addnewsetShow(true);
    setEditForm(false);
    setviewFlag(false);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getData(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        filterVehicle.terminated_on,
                                  filterVehicle.from_date,
                                  filterVehicle.to_date,
                                  filterVehicle?.status,
                                  filterVehicle?.user_id?.value,
                                  filterVehicle?.vehicle_id
        
      );
      setSearchStr(e.target.value);
    }
  };

  

  const exportExcel = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    terminated_on,
    from_date,
    to_date,
    status,
    user_id,
    vehicle_id
  ) => {
    dispatch(setDisplayLoader("Display"));
    VehicalService.exportRegistrationExcel(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        from_date,
        to_date,
        status,
        user_id,
        vehicle_id
      )+`&terminated_on=${terminated_on}`
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vehicle_insurance.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getData(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterVehicle.terminated_on,
                                  filterVehicle.from_date,
                                  filterVehicle.to_date,
                                  filterVehicle?.status,
                                  filterVehicle?.user_id?.value,
                                  filterVehicle?.vehicle_id
    );
  }, [pageSizeNo]);

  const handleShow = (e) => {
    // getData(
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   searchStr,
    //   true,
    //   filterdata.Type,
    //   filterdata.from_date,
    //   filterdata.to_date
    // );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };
  // const submitDelete = (id, type) => {
  //   confirmAlert({
  //     title: "Delete " + type,
  //     message: `Are you sure to delete ` + type + "?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deleteNotice(id),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getData(
      "",
      activePage,
      pageSizeNo,
      "-id",
      true,
      filterVehicle.terminated_on,
                                  filterVehicle.from_date,
                                  filterVehicle.to_date,
                                  filterVehicle?.status,
                                  filterVehicle?.user_id?.value,
                                  filterVehicle?.vehicle_id
    );
    setCurrentPage(activePage);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getData("", 1, pageSizeNo, sortData.sort_by, true, filterVehicle.terminated_on,
        filterVehicle.from_date,
        filterVehicle.to_date,
        filterVehicle?.status,
        filterVehicle?.user_id?.value,
        filterVehicle?.vehicle_id);
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData(
      "",
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true,
      filterVehicle.from_date,
      filterVehicle.to_date,
      filterVehicle.status
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const clearFilter = () => {
    // setFilterApplied(false);
    // let ClearData = JSON.parse(JSON.stringify(filterVehicle));
    // Object.entries(ClearData).map((type) => {
    //   if (
    //     type[1].constructor.name.toLowerCase() === "string" ||
    //     type[1].constructor.name.toLowerCase() === "array"
    //   ) {
    //     if (type[1].length > 0) {
    //       ClearData[type[0]] = "";
    //     }
    //   } else {
    //     if (type[1].length > 0) {
    //       ClearData[type[0]] = "";
    //     }
    //   }
    // });
    setFilterVehicle({
      user_id: "",
    from_date: "",
    to_date: "",
    status: "",
    vehicle_id: "",
    terminated_on:"",
    });
    filterClose();
    getData(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterVehicle.from_date;
    const user_id = filterVehicle?.user_id?.value;
    const to_date = filterVehicle.to_date;
    const status = filterVehicle.status;
    const vehicle_id = filterVehicle.vehicle_id;

    getData(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      filterVehicle?.terminated_on,
      from_date,
      to_date,
      status,
      user_id,
      vehicle_id
    );
    filterClose();
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Vehicle Registration</h4>
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  type="text"
                  id="searchbar"
                  class="form-control"
                  placeholder="Search on Emp ID..."
                  onChange={(e) => {
                    handleSearch(e);
                    setCurrentPage(1);
                  }}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {/* {finalUrlValue?.A &&
                  Number(finalUser?.personal_area_name) === 1007 &&
                  vehicleData?.length == 0 && (
                    <button
                      class="btn btn-primary-inner"
                      onClick={addnewopenForm}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  )} */}

<Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                exportExcel(
                                  searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  filterVehicle.terminated_on,
                                  filterVehicle.from_date,
                                  filterVehicle.to_date,
                                  filterVehicle?.status,
                                  filterVehicle?.user_id?.value,
                                  filterVehicle?.vehicle_id
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                <button
                  onClick={filteropen}
                  class="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                  }}
                >
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Transaction Id</th>
                <th>Emp Code</th>
                <th>Emp Name</th>
                <th>Relation</th>
                <th>Vehicle Type</th>
                <th>Vehicle Number</th>
                <th>Valid from</th>
                <th>Valid till</th>
                <th>Attachment</th>
                <th>License Number</th>
                <th>DL Valid from</th>
                <th>DL Valid till</th>
                <th>Terminate Date</th>
                <th>DL Attachment</th>
                <th>Applied on</th>
                <th>Pending at</th>
                <th>Status</th>
                {/* <th>Approved on</th> */}
              </tr>
            </thead>
            <tbody>
              {vehicleData?.length ? (
                vehicleData?.map((x) => (
                  <tr>
                    <td>
                      <button
                        className="btn btn-blanktd text-primary"
                        onClick={() => {
                          editNotice(x, false);
                        }}
                      >
                        <i className="far fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-blanktd text-primary"
                        onClick={() => {
                          editNotice(x, true);
                        }}
                      >
                        <img src="images/eye.png" alt="" />
                      </button>
                    </td>
                    <td class="fw-bold">{`PIJAVR298${x.id}`}</td>
                    <td class="fw-bold">{x.created_by_username}</td>
                    <td class="fw-bold">{x.created_by_name}</td>
                    <td>{x?.relation_type}</td>
                    <td>{x?.vehicleType}</td>

                    <td>{x?.vehicleNumber}</td>
                    {x?.validFromDate !== null ||
                    x?.validFromDate !== undefined ? (
                      <td>
                        {moment.utc(x?.validFromDate).format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {x?.validTillDate !== null ||
                    x?.validTillDate !== undefined ? (
                      <td>
                        {moment.utc(x?.validTillDate).format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      {x?.attachment_path?.includes("https") && (
                        <a
                          target="_blank"
                          href={x?.attachment_path}
                          rel="noreferrer"
                        >
                          <i class="fas fa-paperclip"></i>&nbsp;View
                        </a>
                      )}
                    </td>

                    <td>{x?.licenseNumber}</td>
                    {x?.licenseValidFromDate !== null ||
                    x?.licenseValidFromDate !== undefined ? (
                      <td>
                        {moment
                          .utc(x?.licenseValidFromDate)
                          .format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}

                    {x?.licenseValidTillDate !== null ||
                    x?.licenseValidTillDate !== undefined ? (
                      <td>
                        {moment
                          .utc(x?.licenseValidTillDate)
                          .format("DD-MM-YYYY")}
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td>
                      {x?.validity_terminated_on != null ||
                      x?.validity_terminated_on != undefined
                        ? moment
                            .utc(x?.validity_terminated_on)
                            .format("DD-MM-YYYY")
                        : ""}
                    </td>
                    <td>
                      {x?.dl_attachment_path?.includes("https") && (
                        <a
                          target="_blank"
                          href={x?.dl_attachment_path}
                          rel="noreferrer"
                        >
                          <i class="fas fa-paperclip"></i>&nbsp;View
                        </a>
                      )}
                    </td>
                    <td>{moment.utc(x?.created_at).format("DD-MM-YYYY")}</td>
                    {x?.status == "P" ? (
                      <td>
                        <Tooltip title={x?.pending_at}>{x?.pending_at}</Tooltip>
                      </td>
                    ) : x?.status == "ARM" ? (
                      <td>
                        <Tooltip title=" SSC User">SSC User</Tooltip>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      {x?.status == "A" ? (
                        <span class="badge bad-status badge-success">
                          Approved{" "}
                        </span>
                      ) : x?.status == "P" ? (
                        <span class="badge bad-status badge-warning">
                          Pending{" "}
                        </span>
                      ) : x?.status == "ARM" ? (
                        <span class="badge bad-status badge-warning">
                          <Tooltip title="Document sent but not received by SSC">
                            Document sent but not...
                          </Tooltip>
                        </span>
                      ) : x?.status.includes("R") ? (
                        <span class="badge bad-status badge-danger">
                          Declined{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td>{moment.utc(x?.created_at).format("DD-MM-YYYY")}</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14}>No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {vehicleData?.length ? (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    handleShow(e);
                  }}
                  style={{ textAlign: "center" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>100</option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div class="form-group innergroup">
            <label>
              Terminated On<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) =>
                setFilterVehicle({
                  ...filterVehicle,
                  terminated_on: e.target.value,
                })
              }
              value={filterVehicle.terminated_on}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) =>
                setFilterVehicle({
                  ...filterVehicle,
                  from_date: e.target.value,
                })
              }
              value={filterVehicle.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              name="to_date"
              onChange={(e) =>
                setFilterVehicle({
                  ...filterVehicle,
                  to_date: e.target.value,
                })
              }
              value={filterVehicle.to_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>Status</label>
            <select
              name="status"
              class="form-control newbgselect"
              onChange={(e) => {
                setFilterVehicle({
                  ...filterVehicle,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={filterVehicle.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="P">Pending</option>
              <option value="A">Approved</option>
              <option value="R">Reject</option>
              <option value="C">Cancelled</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearFilter()}>
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <VehicalForm
          open={addnewshow}
          onhide={addnewclose}
          getdata={() =>
            getData(
              "",
              currentPage,
              pageSizeNo,
              sortData.sort_by,
              true,
              "",
              "",
              "",
              "",
              "",
              ""
            )
          }
          data={editData}
          editForm={editForm}
          // viewFlag={viewFlag}
          viewTerminateFlag={viewTerminateFlag}
        />
      )}
    </>
  );
}

export default JivagroAddVehicalAll;
