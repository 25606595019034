import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select'
import { Slider } from "@mui/material";
import Box from '@mui/material/Box';
import { isAndroid, isIOS } from "react-device-detect";

let android = true

function MultiOptionSurveyView(props) {
    const { question, view, updateLocalResponse, curAssess, index, qItem, idx, prevQues, userInput, valid, secDesc } = props;
    let curQuesData = userInput?.questions?.filter((each) => {
        if (each?.question_id === question?.id) {
            return true
        }
    })[0]
    var emojis = ['😠', '☹️', '🙂', '😀', '🤩️'];
    return (
        <>
            {/* <div className="row" >
                <div className={`col-${question?.quesWidth}`}>
                    {index === 0 && (
                        <div className="ml-2">
                            <div dangerouslySetInnerHTML={{
                                __html: secDesc[idx]
                            }}></div>
                        </div>
                    )}
                </div>
                {
                    (!isAndroid && !isIOS) && question?.options?.map((optItem, iddx) => {
                        return (
                            <div
                                class={
                                    [
                                        "file",
                                        "Rating",
                                        // "Short Answer",
                                        "Emoji"
                                    ].includes(optItem.type) === false
                                        ? `col-${optItem?.optWidthMob} col-md-${optItem?.optWidthTab} col-lg-${optItem?.optWidth}`
                                        : `col-${optItem?.optWidthMob} col-md-${optItem?.optWidthTab} col-lg-${optItem?.optWidth}`
                                    // : (isAndroid || isIOS) ? `col-md-${optItem?.optWidth} mb-3` : `col-md-${optItem?.optWidth} ques-padding text-center`
                                }
                            // style={{
                            //     marginBottom: (!isAndroid && !isIOS && optItem?.type !== 'slider') && '50px'
                            // }}
                            >
                                {((isAndroid || isIOS) || (prevQues?.options[iddx]?.desc !== question?.options[iddx]?.desc)) && (
                                    <div class="text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: question?.options[iddx]?.desc
                                        }}>
                                    </div>
                                )}
                            </div>
                        )
                    })
                }
            </div> */}
            <div className="row questheadrowdisplay">
                <div className={`col-sm-${question?.quesWidthMob} col-md-${question?.quesWidthTab} col-lg-${question?.quesWidth} text-left`} >
                    <div class="row">
                        {index === 0 && (
                            <div class="col-md-12 questfirstheadtxt" dangerouslySetInnerHTML={{
                                __html: secDesc[idx]
                            }}></div>
                        )}
                        <div className="col-md-12 d-flex mt-3">
                            {curAssess?.allowSequence && `(${index + 1}.) `}&nbsp;&nbsp; {question?.is_mandatory && (<span className="text-danger">*</span>)}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: question?.question,
                                }}
                            ></div>
                        </div>
                        {(view !== "true" && curAssess?.is_sectional === false)
                            ? ((qItem === "questions" && valid[index]?.mandatory) && <span class="text-danger ml-2" style={{
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }}>*Kindly attempt this question</span>) : (view !== "true" && curAssess?.is_sectional) ? (
                                ((qItem !== "questions") && valid[qItem][index]?.mandatory) && <span class="text-danger ml-2" style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                }}>*Kindly attempt this question</span>
                            ) : ""}
                    </div>
                </div>
                {question?.options.map((optItem, iddx) => (
                    <>
                        <div
                            class={
                                [
                                    "file",
                                    "Rating",
                                    // "Short Answer",
                                    "Emoji"
                                ].includes(optItem.type) === false
                                    ? `col-sm-${optItem?.optWidthMob} col-md-${optItem?.optWidthTab} col-lg-${optItem?.optWidth}`
                                    : `col-sm-${optItem?.optWidthMob} col-md-${optItem?.optWidthTab} col-lg-${optItem?.optWidth} text-center nextrowasset-mar`
                            }
                        >
                            {optItem.type === "Rating" ? (
                                <>
                                    {optItem?.is_dropdown ? (
                                        <>
                                            <div class="questfirstheadtxt" dangerouslySetInnerHTML={{
                                                __html: question?.options[iddx]?.desc
                                            }}>
                                            </div>
                                            {/* <div className={(isAndroid || isIOS) ? "row mb-5" : "row"}> */}
                                            {/* <div class={`col-md-${(optItem?.optWidth)} mt-3`}> */}
                                            <Select
                                                isDisabled={
                                                    (curAssess.permission_type === "SV" &&
                                                        curAssess?.status === "Submitted") ||
                                                    view === "true"
                                                }
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        // marginTop: index === 0 && '30px',
                                                        border: (view !== "true" && ((qItem === "questions" && valid[index]?.mandatory) || (qItem !== "questions") && valid[qItem][index]?.mandatory))
                                                            && '0.5px solid red'
                                                    }),
                                                    indicatorsContainer: (style) => ({
                                                        ...style,
                                                        display: 'none'
                                                    })

                                                }}
                                                placeholder={<i className="fas fa-angle-down text-center" />}
                                                defaultValue={
                                                    question?.options
                                                        ?.filter((x) => {
                                                            if (
                                                                x?.option === question?.default_option
                                                            ) {
                                                                return true;
                                                            }
                                                        })
                                                        .map((each, index) => {
                                                            return {
                                                                label: each?.option,
                                                                value: each?.id,
                                                                key: index,
                                                            };
                                                        })[0]
                                                }
                                                options={
                                                    optItem?.type === "Rating" ? Array.from({ length: question?.options[iddx]?.max }, (_, i) => i + 1).map((x) => {
                                                        return {
                                                            label: x,
                                                            value: x,
                                                            key: x
                                                        }
                                                    }) :
                                                        question?.options?.map(
                                                            (each, index) => {
                                                                return {
                                                                    label: each?.option,
                                                                    value: each?.id,
                                                                    key: index,
                                                                };
                                                            }
                                                        )}
                                                onChange={(e) => {
                                                    updateLocalResponse(
                                                        {
                                                            target: {
                                                                value: e?.value,
                                                            },
                                                        },
                                                        iddx,
                                                        question.id,
                                                        e.value,
                                                        optItem.type,
                                                        qItem
                                                    );
                                                    // setDropDownVal(e);
                                                }}
                                                value={
                                                    (curAssess.permission_type === "SV" &&
                                                        curAssess?.status === "Submitted") ||
                                                        view === "true"
                                                        ? (optItem?.type === "Rating" ? Array.from({ length: question?.options[iddx]?.max }, (_, i) => i + 1)
                                                            ?.filter((x) => {
                                                                if (
                                                                    x.toString() ===
                                                                    question?.answers[iddx]
                                                                        ?.remark_text
                                                                ) {
                                                                    return true;
                                                                }
                                                            })
                                                            .map((each, index) => {
                                                                return {
                                                                    label: each,
                                                                    value: each,
                                                                    key: index,
                                                                };
                                                            })[0]
                                                            :
                                                            question?.options
                                                                ?.filter((x) => {
                                                                    if (
                                                                        x?.option ===
                                                                        question?.answers[iddx]
                                                                            ?.answer_options
                                                                    ) {
                                                                        return true;
                                                                    }
                                                                })
                                                                .map((each, index) => {
                                                                    return {
                                                                        label: each?.option,
                                                                        value: each?.id,
                                                                        key: index,
                                                                    };
                                                                })[0]
                                                        ) : question?.options
                                                            ?.filter((x) => {
                                                                if (
                                                                    x?.option ===
                                                                    question?.answers[iddx]
                                                                        ?.answer_options
                                                                ) {
                                                                    return true;
                                                                }
                                                            })
                                                            .map((each, index) => {
                                                                return {
                                                                    label: each?.option,
                                                                    value: each?.id,
                                                                    key: index,
                                                                };
                                                            })[0]
                                                }
                                            />
                                            {/* </div> */}
                                            {/* <div className={optItem?.feedback_width !== "" ? `col-md-${12 - (12 / Number(optItem?.feedback_width))}` : "col-md-12"}>
                                                {optItem?.type === "Rating" && optItem?.allow_feedback && (optItem?.showFeedback || view === "true") && (
                                                    <>
                                                        <div class="mt-3 innergroup">
                                                            <textarea style={{ height: "40px", border: '1px solid darkgrey', }} name='RatingFeedback' className="form-control" placeholder="Remarks" maxLength={150} onChange={(e) => {
                                                                updateLocalResponse(
                                                                    e,
                                                                    iddx,
                                                                    question.id,
                                                                    "",
                                                                    "Rating",
                                                                    qItem
                                                                )
                                                            }}
                                                                value={question?.answers[iddx]?.feedback}
                                                            />
                                                        </div>
                                                        {(view !== "true" && qItem !== "" && valid[qItem][index]?.feedback) && (<span class="text-danger ml-1">*Kindly Fill Remarks</span>)}
                                                    </>
                                                )}
                                            </div> */}
                                            {/* </div> */}
                                        </>
                                    ) : (
                                        <>
                                            <div class="innergroup mb-2 text-left mt-5">
                                                {question?.options[0]?.desc}
                                            </div>
                                            <div className="row">
                                                <div class="col-md-7 mt-3">
                                                    <div class="ratingpoll d-inline-block">
                                                        {Array.apply(null, {
                                                            length: optItem.max,
                                                        }).map((e, index) => (
                                                            <label class="logCheck d-inline-block">
                                                                {question?.answers.length > 0 ? (
                                                                    question?.answers?.map(
                                                                        (anitem, anidx) =>
                                                                            Number(`${index + 1}`) <=
                                                                                Number(
                                                                                    anitem?.remark_text
                                                                                ) ? (
                                                                                <input
                                                                                    checked
                                                                                    className="checkboxstart"
                                                                                    ques={question?.id}
                                                                                    disabled={
                                                                                        (curAssess.permission_type ===
                                                                                            "SV" &&
                                                                                            curAssess?.status ===
                                                                                            "Submitted") ||
                                                                                        view === "true"
                                                                                    }
                                                                                    type="checkbox"
                                                                                    value={`${index + 1}`}
                                                                                    onChange={(e) =>
                                                                                        updateLocalResponse(
                                                                                            e,
                                                                                            index,
                                                                                            question.id,
                                                                                            optItem.id,
                                                                                            optItem.type,
                                                                                            qItem
                                                                                        )
                                                                                    }
                                                                                    name="surveyanswer1"
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    className="checkboxstart"
                                                                                    value={`${index + 1}`}
                                                                                    ques={question?.id}
                                                                                    disabled={
                                                                                        (curAssess.permission_type ===
                                                                                            "SV" &&
                                                                                            curAssess?.status ===
                                                                                            "Submitted") ||
                                                                                        view === "true"
                                                                                    }
                                                                                    type="checkbox"
                                                                                    onChange={(e) =>
                                                                                        updateLocalResponse(
                                                                                            e,
                                                                                            index,
                                                                                            question.id,
                                                                                            optItem.id,
                                                                                            optItem.type,
                                                                                            qItem
                                                                                        )
                                                                                    }
                                                                                    name="surveyanswer1"
                                                                                />
                                                                            )
                                                                    )
                                                                ) : (
                                                                    <input
                                                                        value={`${index + 1}`}
                                                                        className="checkboxstart"
                                                                        type="checkbox"
                                                                        name="surveyanswer1"
                                                                        ques={question?.id}
                                                                        onChange={(e) =>
                                                                            updateLocalResponse(
                                                                                e,
                                                                                index,
                                                                                question.id,
                                                                                optItem.id,
                                                                                optItem.type,
                                                                                qItem
                                                                            )
                                                                        }
                                                                    />
                                                                )}

                                                                <span class="checkmark"></span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div class="innergroup mt-3">
                                                        <textarea name='RatingFeedback' className="form-control" placeholder="Your Feeback" onChange={(e) => {
                                                            updateLocalResponse(
                                                                e,
                                                                index,
                                                                question.id,
                                                                optItem.id,
                                                                "Rating"
                                                            )
                                                        }}
                                                            value={question?.answers[0]?.feedback}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                </>
                            ) : (optItem?.type === "Emoji" || question?.type === "Emoji") ? (
                                <>
                                    <div className="row text-center">
                                        <div className={optItem?.feedback_width !== "" ? `col-md-${12 - (12 / Number(optItem?.feedback_width))}` : "col-md-12"}>
                                            {(prevQues?.options[iddx]?.desc !== question?.options[iddx]?.desc) && (question?.options[iddx]?.desc !== "") && (
                                                <div class="mb-2 text-center" dangerouslySetInnerHTML={{
                                                    __html: question?.options[iddx]?.desc
                                                }}>
                                                </div>
                                            )}
                                            <div className={(isAndroid || isIOS) ? "row mb-2" : question?.options[iddx]?.desc === "" ? "row smiley-iconsbox" : "row smiley-iconsbox"}>
                                                {/* <div className="col-2"></div> */}
                                                {emojis?.map((each, i) => {
                                                    return (
                                                        <>
                                                            <div className={'col-2 text-sm-center'} style={{
                                                                marginLeft: i === 1 && '10px'
                                                            }}>
                                                                <div><i className="fas fa-check" style={{
                                                                    color: 'green',
                                                                    visibility: (Number(curQuesData?.option_id[iddx]?.remark_text) === (i + 1) || Number(curQuesData?.remark_text) === (i + 1)) ? 'visible' : 'hidden'
                                                                }} /></div>
                                                                <label class="smiley-icons"
                                                                    onClick={() => {
                                                                        updateLocalResponse(
                                                                            {
                                                                                target: {
                                                                                    value: (i + 1)
                                                                                }
                                                                            },
                                                                            iddx,
                                                                            question.id,
                                                                            optItem.id,
                                                                            "Rating",
                                                                            qItem
                                                                        )
                                                                    }}
                                                                >{each}
                                                                    <div class="smilytxtsmall">{i === 0 ? "Very Dissatisfied" : i === 4 ? "Very Satisfied" : ""}</div></label>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : optItem.type === "Slider" ? (
                                <>
                                    {(prevQues?.options[iddx]?.desc !== question?.options[iddx]?.desc) && (question?.options[iddx]?.desc !== "") && (
                                        <div class="mb-2 text-center" dangerouslySetInnerHTML={{
                                            __html: question?.options[iddx]?.desc
                                        }}>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className={"col-md-11 mt-3"}>
                                            <div class="ratingpoll d-inline-block" style={{
                                                width: '100%'
                                            }}>
                                                <Box sx={{
                                                    width: '100%'
                                                }}>
                                                    {optItem?.max !== undefined && (
                                                        <Slider
                                                            aria-label="Question-Slider"
                                                            defaultValue={0}
                                                            marks={
                                                                Array.from({ length: (optItem?.max) + 1 }, (_, i) => i + 1).map((each) => {
                                                                    if (each === (optItem?.max) + 1) {
                                                                        return {
                                                                            label: 0,
                                                                            value: 0
                                                                        }
                                                                    } else {
                                                                        return {
                                                                            label: each,
                                                                            value: each
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                            // getAriaValueText={(e) => {
                                                            //     return e.toString()
                                                            // }}
                                                            onChange={(event) => {
                                                                updateLocalResponse(
                                                                    event,
                                                                    iddx,
                                                                    question.id,
                                                                    optItem.id,
                                                                    "Rating",
                                                                    qItem
                                                                )
                                                            }}
                                                            isDisabled={
                                                                (curAssess.permission_type === "SV" &&
                                                                    curAssess?.status === "Submitted") ||
                                                                view === "true"
                                                            }
                                                            value={Number(curQuesData?.remark_text) || Number(curQuesData?.option_id[iddx]?.remark_text) || ""}
                                                            valueLabelDisplay="auto"
                                                            step={1}
                                                            min={0}
                                                            max={optItem?.max}
                                                        />
                                                    )}
                                                </Box>
                                            </div>
                                        </div>
                                        <div className={"col-md-11"}>
                                            {(optItem?.type === "Rating" || optItem?.type === "Slider") && optItem?.allow_feedback && (optItem?.showFeedback || (view === "true" && question?.answers[iddx]?.feedback !== "")) && (
                                                <>
                                                    <div class="mt-3 innergroup">
                                                        <textarea name='RatingFeedback' className="form-control" placeholder="Remarks" maxLength={500} style={{
                                                            height: view === "true" ? "200px" : '50px',
                                                            border: (view !== "true" && ((qItem === "questions" && valid[index]?.feedback) || (qItem !== "questions") && valid[qItem][index]?.feedback))
                                                                && '0.5px solid red'
                                                        }} onChange={(e) => {
                                                            updateLocalResponse(
                                                                e,
                                                                iddx,
                                                                question.id,
                                                                optItem,
                                                                "Rating",
                                                                qItem
                                                            )
                                                        }}
                                                            value={question?.answers[iddx]?.feedback}
                                                        />
                                                        {(view !== "true" && qItem !== "" && valid[qItem][index]?.feedback) && (<span class="text-danger ml-1">*Kindly Fill Remarks</span>)}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : optItem.type === "Short Answer" ? (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div class="innergroup">
                                                <input
                                                    type="text"
                                                    name='RatingFeedback'
                                                    className="form-control"
                                                    placeholder="Please give your short answer"
                                                    value={
                                                        question?.answers[0]?.remark_text
                                                    }
                                                    onChange={(e) =>
                                                        updateLocalResponse(
                                                            e,
                                                            idx,
                                                            question.id,
                                                            optItem.id,
                                                            optItem.type
                                                        )
                                                    }
                                                    disabled={
                                                        (curAssess.permission_type ===
                                                            "SV" &&
                                                            curAssess?.status ===
                                                            "Submitted") ||
                                                        view === "true"
                                                    }
                                                    minLength={optItem.min}
                                                    maxLength={optItem.max}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </>
                            ) : optItem?.type === "Long Answer" ? (
                                iddx === 0 ? (
                                    <div class="answerboxpollsuy innergroup">
                                        {(curAssess.permission_type ===
                                            "SV" &&
                                            curAssess?.status ===
                                            "Submitted") ||
                                            view === "true" ? (
                                            <div
                                                class="col-md-12"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        question?.answers[0]
                                                            ?.remark_text,
                                                }}
                                            ></div>
                                        ) : (
                                            <CKEditor
                                                editor={ClassicEditor}
                                                name="question"
                                                onReady={(editor) => {
                                                    console.log(
                                                        "Editor is ready to use!",
                                                        editor
                                                    );
                                                }}
                                                data={
                                                    question?.answers[0]?.remark_text
                                                }
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    updateLocalResponse(
                                                        {
                                                            currentTarget: {
                                                                value: data,
                                                            },
                                                        },
                                                        idx,
                                                        question.id,
                                                        optItem.id,
                                                        optItem.type
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )
                            ) : optItem?.type === "Date" ? (
                                <div className="row">
                                    <div className="col-12">
                                        <div class="innergroup">
                                            <input type="date" className="form-control"
                                                onChange={(e) => {
                                                    updateLocalResponse(
                                                        e,
                                                        idx,
                                                        question.id,
                                                        optItem.id,
                                                        "Rating",
                                                        qItem
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div class="answerboxpollsuy innergroup">
                                    <span>
                                        {String.fromCharCode(65 + iddx)}.
                                    </span>

                                    <label class="logCheck d-inline-block">
                                        {optItem.option}

                                        {question?.answers?.length > 0 ? (
                                            question?.answers?.map(
                                                (anitem, anidx) =>
                                                    optItem.option ===
                                                        anitem?.answer_options ? (
                                                        <input
                                                            disabled={
                                                                (curAssess.permission_type ===
                                                                    "SV" &&
                                                                    curAssess?.status ===
                                                                    "Submitted") ||
                                                                view === "true"
                                                            }
                                                            checked
                                                            value={"demo"}
                                                            onChange={(e) =>
                                                                updateLocalResponse(
                                                                    e,
                                                                    idx,
                                                                    question.id,
                                                                    optItem.id,
                                                                    optItem.type
                                                                )
                                                            }
                                                            type={optItem?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                            name={
                                                                optItem.type ===
                                                                    "Multiple Choice"
                                                                    ? `surveyanswer${question.id}${optItem.id}`
                                                                    : `surveyanswer${question.id}`
                                                            }
                                                        />
                                                    ) : (
                                                        <input
                                                            disabled={
                                                                (curAssess.permission_type ===
                                                                    "SV" &&
                                                                    curAssess?.status ===
                                                                    "Submitted") ||
                                                                view === "true"
                                                            }
                                                            value={"demo"}
                                                            onChange={(e) =>
                                                                updateLocalResponse(
                                                                    e,
                                                                    idx,
                                                                    question.id,
                                                                    optItem.id,
                                                                    optItem.type
                                                                )
                                                            }
                                                            type={optItem?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                            name={
                                                                optItem.type ===
                                                                    "Multiple Choice"
                                                                    ? `surveyanswer${question.id}${optItem.id}`
                                                                    : `surveyanswer${question.id}`
                                                            }
                                                        />
                                                    )
                                            )
                                        ) : (
                                            <input
                                                value={"demo"}
                                                disabled={
                                                    (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                        "Submitted") ||
                                                    view === "true"
                                                }
                                                onChange={(e) =>
                                                    updateLocalResponse(
                                                        e,
                                                        idx,
                                                        question.id,
                                                        optItem.id,
                                                        optItem.type
                                                    )
                                                }
                                                type={optItem?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                name={
                                                    optItem.type ===
                                                        "Multiple Choice"
                                                        ? `surveyanswer${question.id}${optItem.id}`
                                                        : `surveyanswer${question.id}`
                                                }
                                            />
                                        )}

                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            )}
                        </div>
                        {optItem?.feedback_width !== "" && (
                            <div className={`col-sm-${optItem?.feedback_width_mob} col-md-${optItem?.feedback_width_tab} col-lg-${optItem?.feedback_width}`}>
                                {optItem?.type === "Rating" && optItem?.allow_feedback && (optItem?.showFeedback || (view === "true" && question?.answers[iddx]?.feedback !== "")) && (
                                    <>
                                        <div class="innergroup">
                                            <textarea id='feedbackRemark' disabled={view === "true"}
                                                name='RatingFeedback' className={view === 'true' ? 'form-control feedbackform-satisfact-view' : "form-control feedbackform-satisfact"}
                                                placeholder="Remarks" maxLength={500}
                                                style={{
                                                    border: (view !== "true" && ((qItem === "questions" && valid[index]?.feedback) || (qItem !== "questions") && valid[qItem][index]?.feedback))
                                                        && '0.5px solid red',
                                                }}
                                                onChange={(e) => {
                                                    updateLocalResponse(
                                                        e,
                                                        iddx,
                                                        question.id,
                                                        "",
                                                        "Rating",
                                                        qItem
                                                    )
                                                }}
                                                value={question?.answers[iddx]?.feedback}
                                            />
                                            {(view !== "true" && qItem !== "" && valid[qItem][index]?.feedback) && (<span class="text-danger ml-1" >*Kindly Fill Remarks</span>)}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                ))
                }
            </div>
        </>
    )
}

export default MultiOptionSurveyView;