import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { object, string, mixed } from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import * as UserService from "../../../service/userService";
import {
    getEmpSubGroups
} from "../../../service/roleRightMgt";
const panNoRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object({
  first_name: Yup.string().required("*First Name is required").nullable(),
  last_name: Yup.string().required("*Last Name is required").nullable(),
  username: Yup.string().required("*Employee Id is required").nullable(),
  date_of_joining: Yup.string()
    .required("*Date of Joining is required")
    .nullable(),
  business_mobile: Yup.string()
    .matches(phoneRegExp, "*Mobile Number is not Valid")
    .min(8, "*Mobile Number is too short")
    .max(13, "*Mobile Number is too long")
    .nullable(),

  gender: Yup.string().required("*Gender is required").nullable(),
  pan_no: Yup.string()
    .matches(panNoRegex, "*Pan number is not valid")
    .nullable(),

  course_days: Yup.string()
    .max(3, "*Days too long")
    .required("*Course Days is required")
    .nullable(),
  business_email: Yup.string().email("*Invalid Email ID").nullable(),
  business_unit_id: Yup.string()
    .required("*Business unit is required")
    .nullable(),

  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .when("is_sf_user", {
      is: (is_sf_user) => is_sf_user === true,
      then: Yup.string()
        .oneOf([Yup.ref("is_sf_user"), null], "")
        .min(10, "*Must be a minimum of 10 characters")
        .max(16, "*Must be 16 characters or less")
        .required("*Please enter a password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )

        .nullable(),
    }),

  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "*Password & Confirm Password does not match"
    )
    .required("*Please enter a confirm password")
    .nullable(),

  hr_id: Yup.string().required("*Select HR Name").nullable(),
  functional_head_id: Yup.string()
    .required("*Select Functional Head")
    .nullable(),
  reporting_person_id: Yup.string()
    .required("*Select Reporting Manager")
    .nullable(),
  department_id: Yup.string().required("*Select Department").nullable(),
  designation_id: Yup.string().required("*Select Designation").nullable(),
  personal_area_id: Yup.string().required("*Select Personal Area").nullable(),
  sub_personal_area_id: Yup.string()
    .required("*Select Sub-Personal Area")
    .nullable(),
});

const validationSchema5 = Yup.object({
  // first_name: Yup.string().required("*First Name is required").nullable(),
  last_name: Yup.string().required("*Last Name is required").nullable(),
  username: Yup.string().required("*Employee Id is required").nullable(),
  date_of_joining: Yup.string()
    .required("*Date of Joining is required")
    .nullable(),
  business_mobile: Yup.string()
    .matches(phoneRegExp, "*Mobile Number is not Valid")
    .min(8, "*Mobile Number is too short")
    .max(13, "*Mobile Number is too long")
    .nullable(),
  personal_mobile: Yup.string()
    .matches(phoneRegExp, "*Mobile number is not valid")
    .required("*Mobile Number is required")
    .min(10, "*Mobile Number is too short")
    .max(10, "*Mobile Number is too long")
    .nullable(),

  gender: Yup.string().required("*Gender is required").nullable(),
  pan_no: Yup.string()
    .matches(panNoRegex, "*Pan number is not valid")
    .nullable(),
  business_email: Yup.string().email("*Invalid Email ID").nullable(),
  business_unit_id: Yup.string()
    .required("*Business unit is required")
    .nullable(),

  password: Yup.string()
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // )
    .when("is_sf_user", {
      is: (is_sf_user) => is_sf_user === true,
      then: Yup.string()
        .oneOf([Yup.ref("is_sf_user"), null], "")
        .min(10, "*Must be a minimum of 10 characters")
        .max(16, "*Must be 16 characters or less")
        .required("*Please enter a password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )

        .nullable(),
    }),

  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "*Password & Confirm Password does not match"
    )
    .required("*Please enter a confirm password")
    .nullable(),

  hr_id: Yup.string().required("*Select HR Name").nullable(),
  functional_head_id: Yup.string()
    .required("*Select Functional Head")
    .nullable(),
  reporting_person_id: Yup.string()
    .required("*Select Reporting Manager")
    .nullable(),
  department_id: Yup.string().required("*Select Department").nullable(),
  designation_id: Yup.string().required("*Select Designation").nullable(),
});

const validationSchema1 = Yup.object({
  date_of_birth: Yup.string().required("*Date of Birth is required").nullable(),
  personal_mobile: Yup.string()
    .matches(phoneRegExp, "*Mobile number is not valid")
    .required("*Mobile Number is required")
    .min(10, "*Mobile Number is too short")
    .max(10, "*Mobile Number is too long")
    .nullable(),
  personal_email: Yup.string()
    .required("*Personal Email is required")
    .email("*Must be a valid email")
    .nullable(),
});

const validationSchema2 = Yup.object({
  personal_area_id: Yup.string().required("*Select Personal Area").nullable(),
  sub_personal_area_id: Yup.string()
    .required("*Select Sub-Personal Area")
    .nullable(),
  pincode: Yup.string().max(6, "*pincode must be 6 digits only").nullable(),
});
const validationSchema3 = Yup.object({});


function AddUser(props) {
  const { edit, showForm, onClose, editUser, onAddCallBack } = props;

  const [currentTab, setCurrentTab] = useState("primary");
  const [valid, setValid] = useState("");
  const [selectedValue, setSelectedValue] = useState({});
  const [imgSize, setImgSize] = useState(false);
  const [Errororganisation, setErrororganisation] = useState(false);
  const [selectedValue1, setSelectedValue1] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeCon, setPasswordTypeCon] = useState("password");
  const [allDept, setAllDept] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [userHrListData, setUserHrListData] = useState([]);
  const [costCenterData, setCostCenterData] = useState([]);
  const [vendorCodeData, setVendorCodeData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [countryDataB, setCountryDataB] = useState([]);
  const [countryDataP, setCountryDataP] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [personalAreaData, setPersonalAreaData] = useState([]);
  const [subPersonalAreaData, setSubPersonalAreaData] = useState([]);
  const [selectImage, setSelectImage] = useState(false);
  const [empSubGroup, setEmpSubGroup] = useState([]);
  const [isDataExist, setIsDataExist] = useState({
    userNameFlag: false,
    businessEmailFlag: false,
    personalEmailFlag: false,
    personalMobileFlag: false,
    businessMobileFlag: false,
  });

  const [roles, setRoles] = useState({
    group_ids: [],
    user_id: "",
  });
  const [checkDetails, setcheckDetails] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [rolesDataSelected, setRolesDataSelected] = useState([]);
  const [groupDataSelected, setGroupDataSelected] = useState([]);
  const [roleDrop, setRoleDrop] = useState([]);
  const [hRValue, setHRValue] = useState([]);
  const [rMValue, setRMValue] = useState([]);
  const [fHValue, setFHValue] = useState([]);
  const [CCValue, setCCValue] = useState([]);
  const [desigValue, setDesigValue] = useState([]);
  const [uploadData, setUploadData] = useState({
    profile_picture: {},
  });
  const [userData, setUserData] = useState({
    id: 0,
    business_unit_id: "",
    username: "",
    first_name: "",
    last_name: "",
    pan_no: "",
    vendor_code_id: "",
    business_email: "",
    hr_id: "",
    business_mobile: "",
    functional_head_id: "",
    reporting_person_id: "",
    idea_lead_id: "",
    cost_center_id: 0,
    department_id: "",
    designation_id: "",
    gender: "",
    course_days: 7,
    business__country_code: 0,
    date_of_joining: "",
    date_of_leaving: "",
    password: `${edit === true ? "string" : ""}`,
    confirm_password: `${edit === true ? "string" : ""}`,
    is_sf_user: false,
    profile_picture: {},
    is_blocked: false,
    is_active: true,
    date_of_birth: "",
    birthday_visible: true,
    anniversary_visible: true,
    personal_email: "",
    country__code: "",
    personal_mobile: "",
    country_id: 0,
    state_id: 0,
    city_id: 0,
    district_id: 0,
    personal_area_id: "",
    sub_personal_area_id: "",
    emp_sub_group: "",
    emp_subgroup_cons: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    landmark: "",
    pincode: "",
    employee_vendor_code: 0,
  });

  let currentDate = new Date();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
    return;
  };
  const togglePasswordCon = () => {
    if (passwordTypeCon === "password") {
      setPasswordTypeCon("text");
      return;
    }
    setPasswordTypeCon("password");
    return;
  };
  const getEmpSubGroup = async () => {
    await getEmpSubGroups().then((response) => {
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res.id,
          label: res.emp_sub_group,
        };
      });
      setEmpSubGroup(obj);
    });
  };
  const handleChangee = (e) => {
    console.log("Vahandlechange", e);
    // let collectData = e?.map((value) => {
    //   if (value !== []) {
    //     return value.value;
    //   }
    // });
    // setEmpSubGroup(collectData)
    
    setGroupDataSelected(e.label);  
    
  };
  const saveUser = (values) => {
    values.business__country_code = selectedValue.value;
    values.country__code = selectedValue1.value;
    UserService.saveUser(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setUploadData({ profile_picture: {} });
          onClose();
          toast.success(response.data.message);
          groupsRoles(response?.data?.dataList?.id);
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateUser = (values) => {
    values.business__country_code = selectedValue.value;
    values.country__code = selectedValue1.value;
    values.role = rolesDataSelected;
    let datass = values;
    datass.employee_vendor_code =
      userData?.employee_vendor_code !== ""
        ? userData?.employee_vendor_code
        : values?.employee_vendor_code;
    datass.profile_picture = uploadData.profile_picture;
    if (datass?.course_days === "") {
      datass.course_days = 0;
    }
    // else if(rolesDataSelected.length === 0) {
    //   setErrororganisation(true);
    // }
    console.log("datass", datass);
    UserService.updateUser(datass)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setUploadData({ profile_picture: {} });
          groupsRoles(response?.data?.dataList?.id);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const groupsRoles = (data) => {
    roles.user_id = data;
    roles.group_ids = rolesData;
    UserService.groupsRoles(roles)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.detail) ||
            "failed"
        );
      });
  };

  const getRolesList = (paginate) => {
    UserService.getRolesList(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result?.map((x) => {
          let data2 = { value: x.id, label: x.name };
          data.push(data2);
        });
        setRoleDrop(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  //   const onProfileChange = (e, flag) => {
  //     const files = e.target.files[0];
  //     if (files.size < 1033415 || files.size === 1033415){
  //       const fileData = new FormData();
  //       setImgSize(false);
  //       fileData.append("uploaded_file", files);
  //       UserService.uploadImages(fileData).then((response) => {
  //         if (flag == "profile_picture") {
  //           setUploadData({ ...uploadData, profile_picture: response.data });
  //         }
  //       });

  //     }
  //     elseif (flag == "profile_picture"){
  //         setImgSize(true)
  //  }
  //  setErrorattachment(false)
  //   };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    setSelectImage(true);
    if (files.size < 1033415 || files.size === 1033415) {
      const fileData = new FormData();
      setImgSize(false);
      fileData.append("uploaded_file", files);
      UserService.uploadImages(fileData).then((response) => {
        if (flag == "profile_picture") {
          setUploadData({ ...uploadData, profile_picture: response.data });
        }
      });
    } else if (flag == "profile_picture") {
      setImgSize(true);
    }
  };
  // const onProfileChange = (e, flag) => {
  //   const files = e.target.files[0];
  //   console.log(files.size);
  //   if (files.size < 1033415 || files.size === 1033415) {
  //     const fileData = new FormData();
  //     setImgSize(false);
  //     setImgSizeNew(false);
  //     fileData.append("uploaded_file", files);
  //     moduleDataService.uploadImages(fileData).then((response) => {
  //       if (flag == "icon") {
  //         setUploadData({ ...uploadData, icon: response.data });
  //       } else {
  //         setUploadDataIm({ ...uploadDataIm, hover_icon: response.data });
  //       }
  //     });
  //   } else if (flag == "icon") {
  //     setImgSize(true);
  //   } else {
  //     setImgSizeNew(true);
  //   }
  // };
  //   setErrorattachment(false);
  //   setAttachment(filename);
  //   setUploadDataIm(files);
  // };

  const getBranch = (paginate) => {
    UserService.getBranch(paginate)
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} ${
          item?.code != null ? "(" + item?.code + ")" : ""
        }`}
      />
    ));
  };

  const getDepartment = (id) => {
    UserService.getDepartment("")
      .then((response) => {
        setDeptData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };
  const getUsersListData = (id) => {
    UserService.getUsersListDataNew(0)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label:
              res?.first_name +
              " " +
              res?.last_name +
              " (" +
              res?.username +
              ")",
          };
        });
        setUserListData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getUsersHrListData = () => {
    UserService.getUsersHrListData()
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label:
              res?.first_name +
              " " +
              res?.last_name +
              " (" +
              res?.username +
              ")",
          };
        });
        setUserHrListData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderUserHROptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.first_name} ${item?.last_name} (${item?.username})`}
      />
    ));
  };

  const renderUserOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.first_name} ${item?.last_name} (${item?.username})`}
      />
    ));
  };
  const getVendorCode = (id) => {
    UserService.getVendorCode(id)
      .then((response) => {
        setVendorCodeData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderVendorCodeOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name != null ? item?.name : ""} ${
          item?.code != null ? "(" + item?.code + ")" : ""
        }`}
      />
    ));
  };
  const getCostCenter = (id) => {
    UserService.getCostCenter(id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + " (" + res?.code + ")",
          };
        });
        setCostCenterData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderCostCenterOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name != null ? item?.name : ""} (${
          item?.code != null ? item?.code : ""
        })`}
      />
    ));
  };
  const getCountryList = (paginate) => {
    UserService.getCountryList(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result?.map((val) => {
          let dataSet = {
            value: val.id,
            label: (
              <div>
                <img src={val.flag} className="countryimageph" />{" "}
                {val.dial_code}
              </div>
            ),
          };
          data.push(dataSet);
        });
        setCountryDataB(data);

        response?.data?.dataList?.result
          ?.filter((each) => {
            if (each?.dial_code === "91") {
              return true;
            }
          })
          .map((val) => {
            let dataSet = {
              value: val.id,
              label: (
                <div>
                  <img src={val.flag} className="countryimageph" />{" "}
                  {val.dial_code}
                </div>
              ),
            };
            setSelectedValue(dataSet);
            let data = userData;
            data.business__country_code = `${val.id}`;
          });

        let data1 = [];
        response?.data?.dataList?.result?.map((val) => {
          let dataSet1 = {
            value: val.id,
            label: (
              <div>
                <img src={val.flag} className="countryimageph" />{" "}
                {val.dial_code}
              </div>
            ),
          };
          data1.push(dataSet1);
        });
        setCountryDataP(data1);
        setCountryData(response?.data?.dataList?.result);

        response?.data?.dataList?.result
          ?.filter((each) => {
            if (each?.dial_code === "91") {
              return true;
            }
          })
          .map((val) => {
            let dataSet1 = {
              value: val.id,
              label: (
                <div>
                  <img src={val.flag} className="countryimageph" />{" "}
                  {val.dial_code}
                </div>
              ),
            };
            setSelectedValue1(dataSet1);
            let data = userData;
            data.country__code = `${val.id}`;
          });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderCountryNameOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}  (${item?.dial_code})`}
      />
    ));
  };

  const getStateList = (id) => {
    UserService.getStateList(id)
      .then((response) => {
        setStateData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderStateOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const getCityList = (id) => {
    UserService.getCityList(id)
      .then((response) => {
        setCityData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderCityOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const getDistrictList = (id) => {
    UserService.getDistrictList(id)
      .then((response) => {
        setDistrictData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderDistrictOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const getPersonalArea = (id) => {
    UserService.getPersonalArea(id)
      .then((response) => {
        setPersonalAreaData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderPersonalAreaOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.description} (${item?.name})`}
      />
    ));
  };
  const getSubPersonalArea = (id) => {
    UserService.getSubPersonalArea(id)
      .then((response) => {
        setSubPersonalAreaData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const renderSubPersonalAreaOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.description} (${item?.name})`}
      />
    ));
  };
  const renderSubGroupOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.label}`}
        label={`${item?.label}`}
      />
    ));
  };

  const renderConsGroupOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.label}`}
        label={`${item?.label}`}
      />
    ));
  };

  const getAllDesignation = (id) => {
    UserService.getAllDesignation("")
      .then((response) => {
        let obj = response?.data?.dataList?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + " (" + res?.code + ")",
          };
        });
        setAllDept(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const renderDesigOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };

  const pushDept = (e) => {
    // let check = 0;
    let collectData = e?.map((value) => {
      if (value !== []) {
        return value.value;
      }
    });
    setRolesData(collectData);
    setRolesDataSelected(e);
    setErrororganisation(false);
  };

  const isUserDataExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editUser?.username == e.target.value) {
      setIsDataExist({ ...isDataExist, userNameFlag: false });
      return;
    } else if (editUser?.business_email == e.target.value) {
      setIsDataExist({ ...isDataExist, businessEmailFlag: false });
      return;
    } else if (editUser?.business_mobile == e.target.value) {
      setIsDataExist({ ...isDataExist, businessMobileFlag: false });
      return;
    } else if (editUser?.personal_email == e.target.value) {
      setIsDataExist({ ...isDataExist, personalEmailFlag: false });
      return;
    } else if (editUser?.personal_mobile == e.target.value) {
      setIsDataExist({ ...isDataExist, personalMobileFlag: false });
      return;
    } else {
      UserService.isUserDataExists(data)
        .then((res) => {
          if (e.target.name == "username") {
            setIsDataExist({
              ...isDataExist,
              userNameFlag: res?.data?.dataList?.is_exist,
            });
          } else if (e.target.name == "business_email") {
            setIsDataExist({
              ...isDataExist,
              businessEmailFlag: res?.data?.dataList?.is_exist,
            });
          } else if (e.target.name == "business_mobile") {
            setIsDataExist({
              ...isDataExist,
              businessMobileFlag: res?.data?.dataList?.is_exist,
            });
          } else if (e.target.name == "personal_email") {
            setIsDataExist({
              ...isDataExist,
              personalEmailFlag: res?.data?.dataList?.is_exist,
            });
          } else if (e.target.name == "personal_mobile") {
            setIsDataExist({
              ...isDataExist,
              personalMobileFlag: res?.data?.dataList?.is_exist,
            });
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const handleChangeFlag = (e, flag) => {
    if (flag === "busMob") {
      setSelectedValue(e);
      let data = userData;
      data.business__country_code = `${e.value}`;
      setUserData(data);
    } else if (flag === "perMob") {
      setSelectedValue1(e);
      let data = userData;
      data.country__code = `${e.value}`;
      setUserData(data);
    }
  };

  useEffect(() => {
    
    if (editUser) {
      let editValue = editUser;
      let bData = [];
      let bData2 = [];
      editValue?.groups?.map((x) => {
        let data = { value: x.id, label: x.name };
        bData?.push(data);
        bData2?.push(x.id);
      });
      if (editValue?.hr_id !== null) {
        let hr_val = {
          value: editValue?.hr_id,
          label:
            editValue?.hr_first_name +
            " " +
            editValue?.hr_last_name +
            " (" +
            editValue?.hr_username +
            ")",
        };
        setHRValue(hr_val);
      }
      if (editValue?.reporting_person_id !== null) {
        let rm_val = {
          value: editValue?.reporting_person_id,
          label:
            editValue?.reporting_person_firstname +
            " " +
            editValue?.reporting_person_lastname +
            " (" +
            editValue?.reporting_person_username +
            ")",
        };
        setRMValue(rm_val);
      }
      if (editValue?.functional_head_id !== null) {
        let fh_val = {
          value: editValue?.functional_head_id,
          label:
            editValue?.functional_head_first_name +
            " " +
            editValue?.functional_head_last_name +
            " (" +
            editValue?.functional_head_username +
            ")",
        };
        setFHValue(fh_val);
      }
      if (editValue?.cost_center_id !== null) {
        let cc_val = {
          value: editValue?.cost_center_id,
          label:
            editValue?.cost_center_name +
            " (" +
            editValue?.cost_center_code +
            " )",
        };
        setCCValue(cc_val);
      }
      if (editValue?.designation_id !== null) {
        let desig_val = {
          value: editValue?.designation_id,
          label:
            editValue?.designation_name +
            " (" +
            editValue?.designation_code +
            ")",
        };
        setDesigValue(desig_val);
      }

      setRolesData(bData2);
      setRolesDataSelected(bData);
      let data = [];
      let dataSet = {
        value: editUser.business__country_code,
        label: (
          <div>
            <img
              src={editUser.business_country_code_flag}
              className="countryimageph"
            />{" "}
            {editUser.business_country_dial_code}
          </div>
        ),
      };
      data.push(dataSet);
      setSelectedValue(data);
      let data1 = [];
      let dataSet1 = {
        value: editUser.country__code,
        label: (
          <div>
            <img src={editUser.country_code_flag} className="countryimageph" />{" "}
            {editUser.country_dial_code}
          </div>
        ),
      };
      data1.push(dataSet1);
      setSelectedValue1(data1);
      setUserData(editUser);
      Object.keys(editUser).forEach((item) => {
        userData[item] = editUser[item];
        if (item === "state_id") {
          getCityList(editUser[item]);
          getDistrictList(editUser[item]);
        }
        if (item === "country_id") {
          getStateList(editUser[item]);
        }
        if (item === "business_unit_id") {
          getUsersListData(editUser[item]);
          getCostCenter(editUser[item]);
          getVendorCode(editUser[item]);
          getPersonalArea(editUser[item]);
          getAllDesignation(editUser[item]);
          getDepartment(editUser[item]);
        }
        if (item === "personal_area_id") {
          getSubPersonalArea(editUser[item]);
        }
        if (
          item === "vendor_code_id" ||
          item === "state_id" ||
          item === "city_id" ||
          item === "country_id" ||
          item === "district_id" ||
          item === "business_unit_id" ||
          item === "functional_head_id" ||
          item === "reporting_person_id" ||
          item === "cost_center_id" ||
          item === "department_id" ||
          item === "designation_id" ||
          item === "personal_area_id" ||
          item === "sub_personal_area_id" ||
          item === "idea_lead_id"
        ) {
          if (userData[item] === null) {
            userData[item] = 0;
          } else {
            userData[item] = editUser[item];
          }
        }
      });
    }
  }, [editUser]);

  const allData = (values) => {
    let dataSave = {
      id: userData.id,
      business_unit_id: Number(userData.business_unit_id),
      username: values.username,
      first_name: values.first_name,
      last_name: values.last_name,
      pan_no: values.pan_no,
      vendor_code_id: Number(userData.vendor_code_id),
      business_email: values.business_email,
      hr_id: Number(userData.hr_id),
      business_mobile: values.business_mobile,
      functional_head_id: Number(userData.functional_head_id),
      reporting_person_id: Number(userData.reporting_person_id),
      idea_lead_id: Number(userData.idea_lead_id),
      cost_center_id: Number(userData.cost_center_id),
      department_id: Number(userData.department_id),
      designation_id: Number(userData.designation_id),
      gender: values.gender,
      course_days: values.course_days,
      business__country_code: Number(userData.business__country_code),
      date_of_joining: values.date_of_joining,
      date_of_leaving: values.date_of_leaving,
      password: values.password,
      confirm_password: values.confirm_password,
      is_sf_user: values.is_sf_user,
      profile_picture: uploadData.profile_picture,
      is_blocked: values.is_blocked,
      is_active: values.is_active,
      date_of_birth: values.date_of_birth,
      birthday_visible: values.birthday_visible,
      anniversary_visible: values.anniversary_visible,
      personal_email: values.personal_email,
      country__code: Number(userData.country__code),
      personal_mobile: values.personal_mobile,
      country_id: Number(userData.country_id),
      state_id: Number(userData.state_id),
      city_id: Number(userData.city_id),
      district_id: Number(userData.district_id),
      personal_area_id: Number(userData.personal_area_id),
      sub_personal_area_id: Number(userData.sub_personal_area_id),
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3,
      landmark: values.landmark,
      pincode: values.pincode,
      employee_vendor_code: userData.employee_vendor_code,
      blood_group: values.blood_group,
      marital_status: values.marital_status,
    };
    saveUser(dataSave);
  };

  useEffect(() => {
    getBranch(false);
    getRolesList(false);
    getCountryList(false);
    getUsersHrListData();
    getEmpSubGroup();
  }, []);

  const today = new Date();

  const checkMultifield = (e) => {
    if (rolesDataSelected.length === 0) {
      setErrororganisation(true);
    }
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec pb-0">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">
                {edit ? "Edit User" : "Add New User"}
              </h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">User Master</Breadcrumb.Item>
                <Breadcrumb.Item active>Add New</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
        </div>
        <Formik
          initialValues={userData}
          validationSchema={
            edit == false
              ? currentTab == "primary"
                ? validationSchema
                : currentTab == "personal"
                ? validationSchema1
                : validationSchema2
              : edit == true &&
                (editUser?.is_sf_user == false ||
                  editUser?.is_sf_user === undefined)
              ? validationSchema5
              : validationSchema3
          }
          onSubmit={async (values) => {
            console.log("valid", valid);
            if (
              isDataExist.userNameFlag !== true &&
              isDataExist.businessEmailFlag !== true &&
              isDataExist.personalEmailFlag !== true &&
              isDataExist.personalMobileFlag !== true &&
              isDataExist.businessMobileFlag !== true
            ) {
              if (valid == "submitForm") {
                if (values.id) {
                  updateUser(values);
                  console.log(values);
                } else {
                  allData(values);
                }
              }
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValidating,
            handleReset,
            setErrors,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="addusertabs">
                <Tabs
                  activeKey={currentTab}
                  id="adduserinfo"
                  onSelect={(e) => setCurrentTab(e)}
                >
                  <Tab
                    eventKey="primary"
                    title={
                      <>
                        <span>1</span> Primary Info
                      </>
                    }
                    disabled={!edit}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Business Unit<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="business_unit_id"
                            style={{ cursor: "pointer" }}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                business_unit_id: e.target.value,
                              });
                              getAllDesignation(e.target.value);
                              getDepartment(e.target.value);
                              getUsersListData(e.target.value);
                              getVendorCode(e.target.value);
                              getCostCenter(e.target.value);
                              getPersonalArea(e.target.value);
                            }}
                            value={values.business_unit_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Business Unit" />
                            {renderBranchOptions(branchData)}
                          </select>
                          {touched.business_unit_id &&
                          errors.business_unit_id ? (
                            <div className="small text-danger">
                              {errors.business_unit_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Employee Id<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength={16}
                            placeholder="Enter Employee Id"
                            name="username"
                            onChange={handleChange}
                            onBlur={(e) => {
                              isUserDataExists(e, "username");
                              handleBlur(e);
                            }}
                            value={values.username}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.username && errors.username ? (
                            <div className="small text-danger">
                              {errors.username}
                            </div>
                          ) : isDataExist.userNameFlag === true ? (
                            <div className="small text-danger">
                              Employee Id already exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            First Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            maxLength={20}
                            className="form-control"
                            placeholder="Enter First Name"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.first_name && errors.first_name ? (
                            <div className="small text-danger">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Last Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            maxLength={20}
                            className="form-control"
                            placeholder="Enter Last Name"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.last_name && errors.last_name ? (
                            <div className="small text-danger">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>PAN No.</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter PAN No."
                            name="pan_no"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pan_no}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.pan_no && errors.pan_no ? (
                            <div className="small text-danger">
                              {errors.pan_no}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Employee Vendor Code</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Vendor Code"
                            name="employee_vendor_code"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 20))
                            }
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                employee_vendor_code: Number(e.target.value),
                              });
                            }}
                            onBlur={handleBlur}
                            onKeyDown={(evt) =>
                              evt.key === "e" && evt.preventDefault()
                            }
                            value={values.employee_vendor_code}
                            // disabled={editUser?.is_sf_user}
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Vendor Code</label>
                          <select
                            className="form-control newbgselect"
                            name="vendor_code_id"
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                vendor_code_id: e.target.value,
                              });
                            }}
                            value={values.vendor_code_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option
                              value=""
                              label="Select Vendor Code"
                              style={{ cursor: "pointer" }}
                            />
                            {renderVendorCodeOptions(vendorCodeData)}
                          </select>
                        </div>
                      </div> */}

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Business Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Business Email"
                            name="business_email"
                            onChange={handleChange}
                            onBlur={(e) => {
                              isUserDataExists(e, "business_email");
                              handleBlur(e);
                            }}
                            value={values.business_email}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.business_email && errors.business_email ? (
                            <div className="small text-danger">
                              {errors.business_email}
                            </div>
                          ) : isDataExist.businessEmailFlag === true ? (
                            <div className="small text-danger">
                              Business Email Already Exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            HR Name<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={userHrListData}
                            className="basic-multi-select"
                            name="hr_id"
                            style={{ cursor: "pointer" }}
                            classNamePrefix="select"
                            // closeMenuOnSelect={false}
                            disabled={editUser?.is_sf_user}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("hr_id", e.value);
                              // handleChange("hr_id")
                              setUserData({
                                ...userData,
                                hr_id: e.value,
                              });
                              setHRValue(e);
                              // console.log(e.value);
                            }}
                            value={hRValue}
                          />
                          {/* <select
                            className="form-control newbgselect"
                            name="hr_id"
                            style={{ cursor: "pointer" }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                hr_id: e.target.value,
                              });
                            }}
                            value={values.hr_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select HR Name" />
                            {renderUserHROptions(userHrListData)}
                          </select> */}
                          {console.log({ errors }, touched)}
                          {touched.hr_id && errors.hr_id ? (
                            <div className="small text-danger">
                              {errors.hr_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Business Mobile No.</label>
                          <div className="mobile-country-drop">
                            <div className="row">
                              <div className="col-4 pr-0">
                                <Select
                                  name="business__country_code"
                                  options={countryDataB}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  placeholder="select"
                                  onChange={(e) => {
                                    handleChangeFlag(e, "busMob");
                                    handleChange();
                                  }}
                                  onBlur={handleBlur}
                                  value={selectedValue}
                                  isDisabled={editUser?.is_sf_user}
                                />
                              </div>
                              <div className="col-8 pl-0">
                                <input
                                  type="number"
                                  className="form-control nonumbdrop"
                                  placeholder="Enter Business Mobile No."
                                  name="business_mobile"
                                  onChange={handleChange}
                                  onBlur={(e) => {
                                    isUserDataExists(e, "business_mobile");
                                    handleBlur(e);
                                  }}
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  value={values.business_mobile}
                                  disabled={editUser?.is_sf_user}
                                />
                              </div>
                            </div>
                          </div>
                          {touched.business_mobile && errors.business_mobile ? (
                            <div className="small text-danger">
                              {errors.business_mobile}
                            </div>
                          ) : isDataExist.businessMobileFlag === true ? (
                            <div className="small text-danger">
                              Business Mobile Number Already Exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Reporting Manager
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="reporting_person_id"
                            options={userListData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              setFieldValue("reporting_person_id", e.value);
                              setUserData({
                                ...userData,
                                reporting_person_id: e.value,
                              });
                              setRMValue(e);
                            }}
                            value={rMValue}
                            disabled={editUser?.is_sf_user}
                          />
                          {/* <select
                            className="form-control newbgselect"
                            name="reporting_person_id"
                            style={{ cursor: "pointer" }}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                reporting_person_id: e.target.value,
                              });
                            }}
                            value={values.reporting_person_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Reporting Manager" />
                            {renderUserOptions(userListData)}
                          </select> */}
                          {touched.reporting_person_id &&
                          errors.reporting_person_id ? (
                            <div className="small text-danger">
                              {errors.reporting_person_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Functional Head
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="functional_head_id"
                            options={userListData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            style={{ cursor: "pointer" }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("functional_head_id", e.value);
                              setUserData({
                                ...userData,
                                functional_head_id: e.value,
                              });
                              setFHValue(e);
                            }}
                            value={fHValue}
                            disabled={editUser?.is_sf_user}
                          />
                          {/* <select
                            className="form-control newbgselect"
                            name="functional_head_id"
                            style={{ cursor: "pointer" }}
                            onBlur={handleBlur}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                functional_head_id: e.target.value,
                              });
                            }}
                            value={values.functional_head_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Functional Head" />
                            {renderUserOptions(userListData)}
                          </select> */}
                          {touched.functional_head_id &&
                          errors.functional_head_id ? (
                            <div className="small text-danger">
                              {errors.functional_head_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Cost Center</label>
                          <Select
                            name="cost_center_id"
                            options={costCenterData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            style={{ cursor: "pointer" }}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("cost_center_id", e.value);
                              setUserData({
                                ...userData,
                                cost_center_id: e.value,
                              });
                              setCCValue(e);
                            }}
                            value={CCValue}
                            disabled={editUser?.is_sf_user}
                          />
                          {/* <select
                            className="form-control newbgselect"
                            name="cost_center_id"
                            style={{ cursor: "pointer" }}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                cost_center_id: e.target.value,
                              });
                            }}
                            value={values.cost_center_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Cost Center" />
                            {renderCostCenterOptions(costCenterData)}
                          </select> */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Department<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="department_id"
                            style={{ cursor: "pointer" }}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                department_id: e.target.value,
                              });
                            }}
                            value={values.department_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Department" />
                            {renderDeptOptions(deptData)}
                          </select>
                          {touched.department_id && errors.department_id ? (
                            <div className="small text-danger">
                              {errors.department_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Designation<span className="text-danger">*</span>
                          </label>
                          <Select
                            name="designation_id"
                            options={allDept}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={true}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              setFieldValue("designation_id", e.value);
                              setUserData({
                                ...userData,
                                designation_id: e.value,
                              });
                              setDesigValue(e);
                            }}
                            value={desigValue}
                            disabled={editUser?.is_sf_user}
                          />
                          {/* <select
                            className="form-control newbgselect"
                            name="designation_id"
                            style={{ cursor: "pointer" }}
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                designation_id: e.target.value,
                              });
                            }}
                            value={values.designation_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Designation" />
                            {renderDesigOptions(allDept)}
                          </select> */}
                          {touched.designation_id && errors.designation_id ? (
                            <div className="small text-danger">
                              {errors.designation_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            MyPI Role<span className="text-danger">*</span>
                          </label>
                          <Select
                            name="role"
                            id="role"
                            isMulti
                            options={roleDrop}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              pushDept(e);
                            }}
                            value={rolesDataSelected}
                          />
                          {Errororganisation && (
                            <div className="small text-danger">
                              *Role is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Gender<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="gender"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{ cursor: "pointer" }}
                            value={values.gender}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {touched.gender && errors.gender ? (
                            <div className="small text-danger">
                              {errors.gender}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Mandatory course days
                           <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control nonumbdrop"
                            placeholder="Enter Mandatory course days"
                            name="course_days"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(evt) =>
                              evt.key === "e" && evt.preventDefault()
                            }
                            value={values.course_days}
                          />
                          {touched.course_days && errors.course_days ? (
                            <div className="small text-danger">
                              {errors.course_days}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Date of Joining
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_joining"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // min={
                            //   !editUser
                            //     ? moment(new Date()).format("YYYY-MM-DD")
                            //     : ""
                            // }
                            value={values.date_of_joining}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.date_of_joining && errors.date_of_joining ? (
                            <div className="small text-danger">
                              {errors.date_of_joining}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Date of Leaving</label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_leaving"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.date_of_leaving}
                            disabled={editUser?.is_sf_user}
                            min={moment(values?.date_of_joining).format(
                              "YYYY-MM-DD"
                            )}
                          />
                          {touched.date_of_leaving && errors.date_of_leaving ? (
                            <div className="small text-danger">
                              {errors.date_of_leaving}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup position-relative inneereye">
                          <label>
                            Password<span className="text-danger">*</span>
                          </label>
                          <input
                            type={passwordType}
                            className="form-control"
                            placeholder="Enter Password"
                            name="password"
                            onChange={handleChange}
                            // onBlur={!edit && handleBlur}
                            value={
                              values.password == "string" ? "" : values.password
                            }
                            disabled={editUser?.is_sf_user}
                            maxLength="16"
                          />
                          <i
                            className={
                              passwordType === "password"
                                ? "far fa-eye-slash eyepassword"
                                : "far fa-eye eyepassword"
                            }
                            onClick={() => togglePassword()}
                          ></i>
                          {touched.password && errors.password ? (
                            <div className="small text-danger">
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group innergroup position-relative inneereye">
                          <label>
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type={passwordTypeCon}
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            name="confirm_password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.confirm_password == "string"
                                ? ""
                                : values.confirm_password
                            }
                            disabled={editUser?.is_sf_user}
                            maxLength="16"
                          />
                          <i
                            className={
                              passwordTypeCon === "password"
                                ? "far fa-eye-slash eyepassword"
                                : "far fa-eye eyepassword"
                            }
                            onClick={() => togglePasswordCon()}
                          ></i>
                          {touched.confirm_password &&
                          errors.confirm_password ? (
                            <div className="small text-danger">
                              {errors.confirm_password}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Is SF user</label>
                          <select
                            className="form-control newbgselect"
                            name="is_sf_user"
                            onChange={handleChange}
                            value={values.is_sf_user}
                            disabled
                          >
                            <option value="">Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Personal Area<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="personal_area_id"
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                personal_area_id: e.target.value,
                              });
                              getSubPersonalArea(e.target.value);
                            }}
                            value={values.personal_area_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Personal Area" />
                            {renderPersonalAreaOptions(personalAreaData)}
                          </select>
                          {touched.personal_area_id &&
                          errors.personal_area_id ? (
                            <div className="small text-danger">
                              {errors.personal_area_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Sub-Personal Area
                            <span className="text-danger">*</span>
                          </label>

                          <select
                            className="form-control newbgselect"
                            name="sub_personal_area_id"
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                sub_personal_area_id: e.target.value,
                              });
                            }}
                            value={values.sub_personal_area_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Personal Area" />
                            {renderSubPersonalAreaOptions(subPersonalAreaData)}
                          </select>
                          {touched.sub_personal_area_id &&
                          errors.sub_personal_area_id ? (
                            <div className="small text-danger">
                              {errors.sub_personal_area_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup position-relative">
                          <label>
                            Profile Picture
                            {/*<span className="text-danger">*</span> */}
                          </label>
                          {uploadData?.icon?.original_name != undefined ? (
                            <input
                              type="file"
                              className="form-control bg-white"
                              disabled
                              placeholder="(Image, PDF Format)"
                              accept="iamge/*"
                              value={
                                (edit === true
                                  ? editUser?.image
                                  : uploadData?.profile_picture?.original_name(
                                      0,
                                      28
                                    )) + "..."
                              }
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control bg-white"
                              disabled
                              placeholder="(Image, PDF Format)"
                            />
                          )}
                          <div className="upload-btn-wrapper up-loposition">
                            <button className="uploadBtn">Browse</button>
                            <input
                              type="file"
                              title=""
                              name="profile_picture"
                              accept="image/png, image/jpeg"
                              // onBlur={handleBlur}
                              onChange={(e) =>
                                onProfileChange(e, "profile_picture")
                              }
                              disabled={editUser?.is_sf_user}
                            />
                          </div>
                          {imgSize ? (
                            <div className="small text-danger">
                              File size should not exceed 1mb
                            </div>
                          ) : null}
                          <p>
                            Selected files{" "}
                            {edit === true && selectImage == false ? (
                              <span> {editUser?.image.split("/").pop()}</span>
                            ) : (
                              <span>
                                {" "}
                                {uploadData?.profile_picture?.original_name}
                              </span>
                            )}
                          </p>
                          {/* {touched.profile_picture && errors.profile_picture ? (
                            <div className="small text-danger">
                              {errors.profile_picture}
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Idea Lead
                          
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="idea_lead_id"
                            style={{ cursor: "pointer" }}
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                idea_lead_id: e.target.value,
                              });
                            }}
                            value={values.idea_lead_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Idea Lead" />
                            {renderUserOptions(userListData)}
                          </select>
                          {touched.idea_lead_id && errors.idea_lead_id ? (
                            <div className="small text-danger">
                              {errors.idea_lead_id}
                            </div>
                          ) : null}
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Employee Sub Group</label>
                          <select
                            className="form-control newbgselect"
                            name="emp_sub_group"
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                emp_sub_group: e.target.value,
                              });
                            }}
                            value={values.emp_sub_group}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Sub group" />
                            {renderSubGroupOptions(empSubGroup)}
                          </select>
                          
                        </div>
                      </div>
                      { values.emp_sub_group ==='C1' ?
                      <div className="col-md-4">
                        {console.log(values)}
                        <div className="form-group innergroup">
                          <label>Consultant Sub Group</label>
                          <select
                            className="form-control newbgselect"
                            name="emp_subgroup_cons"
                            // classNamePrefix="select"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                emp_subgroup_cons: e.target.value,
                              });
                            }}
                            value={values.emp_subgroup_cons}
                            disabled={false}
                          >
                            <option value="" label="Select Cons Sub group" />
                            {renderConsGroupOptions(empSubGroup)}
                          </select>
                          
                        </div>
                      </div>
                      :
                      <div></div>
                      }
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Location<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Location"
                            name="location"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            disabled={editUser?.is_sf_user}
                          />
                          {/* {touched.first_name && errors.first_name ? (
                            <div className="small text-danger">
                              {errors.first_name}
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label className="d-block mb-2">Block User</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="block"
                          >
                            Block
                            <input
                              type="radio"
                              name="is_blocked"
                              id="block"
                              value={values.is_blocked}
                              onChange={() => {
                                setFieldValue("is_blocked", true);
                              }}
                              checked={values.is_blocked === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="unblock"
                          >
                            Unblock
                            <input
                              type="radio"
                              name="is_blocked"
                              id="unblock"
                              value={values.is_blocked}
                              onChange={() => {
                                setFieldValue("is_blocked", false);
                              }}
                              checked={values.is_blocked === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label className="d-block mb-2">Status</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              disabled={editUser?.is_sf_user}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              disabled={editUser?.is_sf_user}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-12 mt-4">
                        <button
                          type="button"
                          className="btn btn-outline-secondary mr-3"
                          onClick={() => {
                            onClose();
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-left"></i> Back
                        </button>
                        <button
                          className="btn btn-primary-inner bpi-main"
                          type="submit"
                          onClick={(e) => {
                            edit
                              ? setCurrentTab(isValid ? "personal" : "primary")
                              : setCurrentTab(
                                  isValid && dirty ? "personal" : "primary"
                                );
                            checkMultifield(e);
                            console.log("isValid", isValid, dirty);
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-right"></i> Next
                        </button>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="personal"
                    id="perso"
                    title={
                      <>
                        <span>2</span> Personal Details
                      </>
                    }
                    disabled={!edit}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Date of Birth<span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="date_of_birth"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.date_of_birth}
                            // max={currentDate}
                            max={
                              !editUser
                                ? moment(new Date()).format("YYYY-MM-DD")
                                : ""
                            }
                            // min={moment(values.startDateTime).format("YYYY-MM-DDTHH:mm")}
                            // min={currentDate}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.date_of_birth && errors.date_of_birth ? (
                            <div className="small text-danger">
                              {errors.date_of_birth}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Birthday visibility</label>
                          <select
                            className="form-control newbgselect"
                            name="birthday_visible"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.birthday_visible}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                          {touched.birthday_visible &&
                          errors.birthday_visible ? (
                            <div className="small text-danger">
                              {errors.birthday_visible}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Work Anniversary visibility</label>
                          <select
                            className="form-control newbgselect"
                            name="anniversary_visible"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.anniversary_visible}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                          {touched.anniversary_visible &&
                          errors.anniversary_visible ? (
                            <div className="small text-danger">
                              {errors.anniversary_visible}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Personal Email<span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            maxLength={30}
                            placeholder="Enter Personal Email"
                            name="personal_email"
                            onChange={handleChange}
                            onBlur={(e) => {
                              isUserDataExists(e, "personal_email");
                              handleBlur(e);
                            }}
                            value={values.personal_email}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.personal_email && errors.personal_email ? (
                            <div className="small text-danger">
                              {errors.personal_email}
                            </div>
                          ) : isDataExist.personalEmailFlag === true ? (
                            <div className="small text-danger">
                              Personal Email Already Exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Personal Mobile No.
                            <span className="text-danger">*</span>
                          </label>
                          <div className="mobile-country-drop">
                            <div className="row">
                              <div className="col-4 pr-0">
                                {/* <select
                                  className="form-control newbgselect"
                                  name="country__code"
                                  // classNamePrefix="select"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setUserData({
                                      ...userData,
                                      country__code: e.target.value,
                                    });
                                  }}
                                  value={values.country__code}
                                  disabled={editUser?.is_sf_user}
                                >
                                  <option value="" label="Select" />
                                  {renderCountryOptionsPer(countryDataP)}
                                </select> */}
                                <Select
                                  name="country__code"
                                  options={countryDataP}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  placeholder="+91"
                                  onChange={(e) => {
                                    handleChangeFlag(e, "perMob");
                                    handleChange();
                                  }}
                                  onBlur={handleBlur}
                                  value={selectedValue1}
                                  isDisabled={editUser?.is_sf_user}
                                />
                              </div>
                              <div className="col-8 pl-0">
                                <input
                                  type="number"
                                  className="form-control nonumbdrop"
                                  placeholder="Enter Mobile No."
                                  name="personal_mobile"
                                  onChange={handleChange}
                                  maxLength={30}
                                  onBlur={(e) => {
                                    isUserDataExists(e, "personal_mobile");
                                    handleBlur(e);
                                  }}
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      10
                                    ))
                                  }
                                  value={values.personal_mobile}
                                  disabled={editUser?.is_sf_user}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {touched.personal_mobile && errors.personal_mobile ? (
                          <div
                            className="small text-danger"
                            style={{ marginLeft: "132px", marginTop: "-20px" }}
                          >
                            {errors.personal_mobile}
                          </div>
                        ) : isDataExist.personalMobileFlag === true ? (
                          <div className="small text-danger">
                            Personal Mobile No. Already Exists
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Blood Group</label>
                          <input
                            type="text"
                            name="blood_group"
                            className="form-control"
                            maxLength={4}
                            placeholder="Enter Blood Group"
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setUserData({
                            //     ...userData,
                            //     blood_group: e.target.value,
                            //   });
                            // }}
                            // onBlur={handleBlur}
                            value={values.blood_group}
                            disabled={editUser?.is_sf_user}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Marital Status</label>
                          <input
                            type="text"
                            name="marital_status"
                            className="form-control"
                            maxLength={20}
                            placeholder="Enter Marital Status"
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setUserData({
                            //     ...userData,
                            //     marital_status: e.target.value,
                            //   });
                            // }}
                            // onBlur={handleBlur}
                            value={values.marital_status}
                            disabled={editUser?.is_sf_user}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 mt-4">
                        <button
                          className="btn btn-outline-secondary mr-3"
                          type="button"
                          onClick={() => {
                            setCurrentTab("primary");
                            setErrors({});
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-left"></i>{" "}
                          Previous
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary-inner bpi-main"
                          onClick={() => {
                            setValid("submitForm");
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-right"></i>
                          &nbsp;Submit
                        </button>
                      </div>
                    </div>
                  </Tab>
                  {/* <Tab
                    eventKey="address"
                    title={
                      <>
                        <span>3</span> Addresses
                      </>
                    }
                    disabled={!edit}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Country</label>
                          <select
                            className="form-control newbgselect"
                            name="country_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                country_id: e.target.value,
                              });
                              getStateList(e.target.value);
                            }}
                            value={values.country_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Country" />
                            {renderCountryNameOptions(countryData)}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>State</label>
                          <select
                            className="form-control newbgselect"
                            name="state_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                state_id: e.target.value,
                              });
                              getDistrictList(e.target.value);
                              getCityList(e.target.value);
                            }}
                            value={values.state_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select State" />
                            {renderStateOptions(stateData)}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>City</label>
                          <select
                            className="form-control newbgselect"
                            name="city_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                city_id: e.target.value,
                              });
                            }}
                            value={values.city_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select City" />
                            {renderCityOptions(cityData)}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>District</label>
                          <select
                            className="form-control newbgselect"
                            name="district_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                district_id: e.target.value,
                              });
                            }}
                            value={values.district_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select District" />
                            {renderDistrictOptions(districtData)}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Personal Area<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="personal_area_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                personal_area_id: e.target.value,
                              });
                              getSubPersonalArea(e.target.value);
                            }}
                            value={values.personal_area_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Personal Area" />
                            {renderPersonalAreaOptions(personalAreaData)}
                          </select>
                          {touched.personal_area_id &&
                          errors.personal_area_id ? (
                            <div className="small text-danger">
                              {errors.personal_area_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Sub-Personal Area
                            <span className="text-danger">*</span>
                          </label>

                          <select
                            className="form-control newbgselect"
                            name="sub_personal_area_id"
                            onChange={(e) => {
                              handleChange(e);
                              setUserData({
                                ...userData,
                                sub_personal_area_id: e.target.value,
                              });
                            }}
                            value={values.sub_personal_area_id}
                            disabled={editUser?.is_sf_user}
                          >
                            <option value="" label="Select Personal Area" />
                            {renderSubPersonalAreaOptions(subPersonalAreaData)}
                          </select>
                          {touched.sub_personal_area_id &&
                          errors.sub_personal_area_id ? (
                            <div className="small text-danger">
                              {errors.sub_personal_area_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address"
                            name="address_line_1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address_line_1}
                            disabled={editUser?.is_sf_user}
                            maxLength="50"
                          />
                          {touched.address_line_1 && errors.address_line_1 ? (
                            <div className="small text-danger">
                              {errors.address_line_1}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Address Line 1</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address Line 1"
                            name="address_line_2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address_line_2}
                            disabled={editUser?.is_sf_user}
                            maxLength="50"
                          />
                          {touched.address_line_2 && errors.address_line_2 ? (
                            <div className="small text-danger">
                              {errors.address_line_2}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Address Line 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address Line 2"
                            name="address_line_3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address_line_3}
                            disabled={editUser?.is_sf_user}
                            maxLength="50"
                          />
                          {touched.address_line_3 && errors.address_line_3 ? (
                            <div className="small text-danger">
                              {errors.address_line_3}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Landmark</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Landmark"
                            name="landmark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.landmark}
                            disabled={editUser?.is_sf_user}
                            maxLength="50"
                          />
                          {touched.landmark && errors.landmark ? (
                            <div className="small text-danger">
                              {errors.landmark}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Pincode</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Pincode"
                            name="pincode"
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 6))
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pincode}
                            disabled={editUser?.is_sf_user}
                          />
                          {touched.pincode && errors.pincode ? (
                            <div className="small text-danger">
                              {errors.pincode}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-12 mt-4">
                        <button
                          className="btn btn-outline-secondary mr-3"
                          type="button"
                          onClick={() => {
                            setCurrentTab("personal");
                            setErrors({});
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-left"></i>{" "}
                          Previous
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary-inner bpi-main"
                          onClick={() => {
                            setValid("submitForm");
                          }}
                        >
                          <i className="far fa-arrow-alt-circle-right"></i>
                          &nbsp;Submit
                        </button>
                      </div>
                    </div>
                  </Tab> */}
                </Tabs>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddUser;
