import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as BeneficiaryService from "../../service/cashbookService";
import AllUrlsCashbook from "./AllUrlsCashbook";
import ReactPaginate from "react-paginate";
import moment from "moment";
import AddCashbookBeneficial from "./AddCashbookBeneficial";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import ViewBeneficiary from "./ViewBeneficiary";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function CashbookBeneficial() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [masterData, setMasterData] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [editMode, setEditMode] = useState(false);
  const [editModuleData, setEditModuleData] = useState(null);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [addnewshow, setAddNewShow] = useState(false);
  const userData = useSelector(selectUserData);
  const getBeneficiaryList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate
    // cashbook_id
  ) => {
    BeneficiaryService.getBeneficiaryList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate
        // cashbook_id
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setBeneficiaryList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getExportDetails = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate
    // cashbook_id
  ) => {
    BeneficiaryService.getExportBeneficiary(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate
        //  cashbook_id
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookBeneficial.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getMasterData = (paginate) => {
    BeneficiaryService.getMasterData(paginate)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const addNewVendorCashbook = (eData) => {
    setAddNewShow(true);
    setEditMode(false);
    setEditModuleData(null);
  };
  const editVendor = (eData) => {
    setAddNewShow(true);
    setEditMode(true);
    setEditModuleData(eData);
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate
    // cashbook_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    // +
    // "&cashbook_id=" +
    // cashbook_id;

    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBeneficiaryList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      masterData[0]?.id
    );
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getBeneficiaryList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      masterData[0]?.id
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getBeneficiaryList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        masterData[0]?.id
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getBeneficiaryList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        masterData[0]?.id
      );
    }
  };
  const onClose = () => {
    setEditModuleData(null);
    setAddNewShow(false);
  };
  const onAddCallBack = (response) => {
    setAddNewShow(false);
    getBeneficiaryList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      masterData[0]?.id
    );
  };

  const deleteBeneficiary = (idx) => {
    BeneficiaryService.deleteBeneficiary(idx)
      .then((res) => {
        if (res.data.status == true) {
          getBeneficiaryList(
            searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            masterData[0]?.id
          );
        }
        toast.success("Cahbook Beneficiary Deleted Successfully");
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error("Unable to delete as mapped data exists.");
        } else {
          error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        }
      });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete Module",
      message: `Are you sure to delete Cashbook Beneficial ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBeneficiary(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getMasterData(userData?.id);
  }, [pageSizeNo]);
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getBeneficiaryList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
      // masterData[0]?.id
    );
  }, [masterData]);

  return (
    <>
      <div>
        <div class="row">
          {/* <AllUrlsCashbook /> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Manage Beneficiary</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Beneficiary Code,
                              <br />
                              Delivery Address, <br />
                              Payable Branch, <br />
                              Agency Code,
                              <br />
                              Branch Code, <br />
                              Location Code, <br />
                              Cash Delivery Code, <br />
                              User First Name, <br />
                              User Last name
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          id="searchbar"
                          type="text"
                          class="form-control"
                          placeholder="Search on  Beneficiary Code, Delivery Address,  Payable Branch, Agency Code,Branch Code, Location Code, Cash Delivery Code..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={
                                beneficiaryList.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  ""
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => addNewVendorCashbook()}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add Beneficiary
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured newSpace">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Cashbook</th>
                      <th
                        onClick={(e) => {
                          handlerData("user_id");
                        }}
                        className={
                          sortData["user_id"] === undefined
                            ? "unsortcls main-pointer "
                            : sortData["user_id"]
                        }
                      >
                        User
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("beneficiary_code");
                        }}
                        className={
                          sortData["beneficiary_code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["beneficiary_code"]
                        }
                      >
                        Beneficiary Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("delivery_address");
                        }}
                        className={
                          sortData["delivery_address"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["delivery_address"]
                        }
                      >
                        Delivery Address
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("payable_branch");
                        }}
                        className={
                          sortData["payable_branch"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["payable_branch"]
                        }
                      >
                        Payble Branch
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("agency_code");
                        }}
                        className={
                          sortData["agency_code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["agency_code"]
                        }
                      >
                        Agency Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("agency_name");
                        }}
                        className={
                          sortData["agency_name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["agency_name"]
                        }
                      >
                        Agency Name
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("branch_code");
                        }}
                        className={
                          sortData["branch_code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["branch_code"]
                        }
                      >
                        Branch Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("location_code");
                        }}
                        className={
                          sortData["location_code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["location_code"]
                        }
                      >
                        Location Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("cash_delivery_code");
                        }}
                        className={
                          sortData["cash_delivery_code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["cash_delivery_code"]
                        }
                      >
                        Cash Delivery Code
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("created_at");
                        }}
                        className={
                          sortData["created_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["created_at"]
                        }
                      >
                        Created On
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_at");
                        }}
                        className={
                          sortData["updated_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_at"]
                        }
                      >
                        Last Modified
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {beneficiaryList?.length ? (
                      beneficiaryList?.map((data, index) => (
                        <tr>
                          <td>
                            <button
                              class="btn btn-blanktd text-primary f-12"
                              onClick={() => {
                                editVendor(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2 f-12">
                              <i
                                class="far fa-trash-alt text-danger"
                                onClick={() => {
                                  submit(data?.id, data?.name);
                                }}
                              ></i>
                            </button>
                          </td>
                          {/* <td>
                            <span class="badge bad-status badge-success">
                              {data?.status ?? "-"}
                            </span>
                          </td> */}
                          <td> {data?.cashbook_desc ?? "-"}</td>
                          <td>{data?.user_id ?? "-"}</td>
                          <td
                            class="fw-bold text-theme cursor-pointer"
                            onClick={() => {
                              onView(data);
                            }}
                          >
                            {data?.beneficiary_code ?? "-"}
                          </td>
                          <td>{data?.delivery_address ?? "-"}</td>
                          <td> {data?.payable_branch ?? "-"}</td>
                          <td> {data?.agency_code ?? "-"}</td>
                          <td>{data?.agency_name ?? "-"}</td>
                          <td>{data?.branch_code ?? "-"}</td>
                          <td>{data?.location_code ?? "-"}</td>
                          <td>{data?.cash_delivery_code ?? "-"}</td>
                          <td>
                            {moment(data?.created_at).format("Do MMM YYYY")}
                          </td>
                          <td>
                            {moment(data?.updated_at).format("Do MMM YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {beneficiaryList?.length == 0 ? (
                <td colSpan={12}> No Record Found</td>
              ) : (
                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        class="form-control main-pointer"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {addnewshow && (
        <AddCashbookBeneficial
          edit={editMode}
          addnewshow={addnewshow}
          onClose={onClose}
          onAddCallBack={onAddCallBack}
          editModuleData={editModuleData}
        />
      )}

      {viewForm ? (
        <>
          <ViewBeneficiary
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CashbookBeneficial;
