import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "./../ModularTable";
import ModularTableInput from "./../ModularTableInput";
import {
  fetchCropScenario,
  getCompanyProduct,
  getCropPlanDropdownCrops,
  getCropScenario,
  getCropScenarioApprovalLogs,
  getCropScenarioDropDown,
  getCropStagesDropDown,
  getCurrentFy,
  getGenricProduct,
  getPestData,
  getProductCategory,
  getStagesDates,
  postCropScenario,
  putCropScenario,
} from "../../../../service/AgriPortalService/CropScenarioService";
import styles from "./Table.module.css";
import {
  fetchCropPlan,
  getCropPlanApprovalLogs,
  postCropPlan,
  postCropPlanAction,
  putCropPlan,
  getCropPlan,
  getCropPlanDropdown,
} from "../../../../service/AgriPortalService/CropPlanService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";

const Root = (props) => {
  const [cropDropdown, setCropDropdown] = useState([
    {
      crop_name: "Bengal Gram",
      crop_id: "1",
      season_data: [
        { season: "R", starting_date: "1-Jan" },
        { season: "K", starting_date: "1-Dec" },
      ],
    },
    {
      crop_name: "Tomato",
      crop_id: "2",
      season_data: [{ season: "R", starting_date: "1-Mar" }],
    },
  ]);
  const [selectedCrop, setSelectedCrop] = useState({
    crop_name: "Bengal Gram",
    crop_id: "1",
  });
  const [selectedSeason, setSelectedSeason] = useState({
    season: "R",
    starting_date: "1-Jan",
  });
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");

  // useEffect(() => {
  //   console.log("selectedcrop", selectedCrop);
  //   console.log("selectedseason", selectedSeason);
  // }, [selectedCrop, selectedSeason]);
  const [stagesData, setStagesData] = useState([]);

  const [productCategory, setProductCategory] = useState([]);
  const [pestData, setPestData] = useState([]);
  const [companyProduct, setCompanyProduct] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [finalInputData, setFinalInputData] = useState({});
  const [inputData, setInputData] = useState({});
  const [finalCropView, setFinalCropView] = useState({});
  const [cropView, setCropView] = useState({});
  const [approvalList, setApprovalList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [existingData, setExistingData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [freezeCTA, setFreezeCTA] = useState(false);

  const [mode, setMode] = useState();
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cfy, setCfy] = useState("");

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getCropPlan()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => {
    getCurrentFy()
      .then((res) => {
        setCfy(res.data.dataList.fy);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   console.log("inputData", inputData);
  // }, [inputData]);

  // useEffect(() => {
  //   console.log("cropView", cropView);
  // }, [cropView]);

  // useEffect(() => {
  //   console.log("cropview", cropView);
  // }, [cropView]);

  useEffect(() => {
    if (!cropDropdown || cropDropdown.length == 0) return;

    setSelectedCrop(cropDropdown[0]);
    setSelectedSeason(cropDropdown[0]?.season_data[0]);
  }, [cropDropdown]);

  const confirmDialog = (type) => {
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const convertCategoryData = async (data) => {
    const ans = [];
    let scenario_detail_id = await getCropPlanDropdown(
      locationData?.territory_code
    );
    scenario_detail_id = scenario_detail_id.data.dataList;
    if (!data) return;

    for (let i = 0; i < Object.keys(data).length; i++) {
      for (
        let j = 0;
        j < Object.keys(data[Object.keys(data)[i]])?.length;
        j++
      ) {
        for (
          let k = 0;
          k <
          data[Object.keys(data)[i]][Object.keys(data[Object.keys(data)[i]])[j]]
            ?.length;
          k++
        ) {
          let row =
            data[Object.keys(data)[i]][
            Object.keys(data[Object.keys(data)[i]])[j]
            ][k];
          let patternObj = {};

          if (row[1]?.meta?.lastId) patternObj.id = row[1]?.meta?.lastId;
          else patternObj.id = 0;

          // let comp1= row[0]?.meta?.raw?.find(el=>el?.name==row[0].value[0]);
          // if(!comp1) return;

          // let comp2= row[3]?.meta?.raw?.find(el=>el?.name==row[3].value[0]);
          // if(!comp2) return;

          // let gen1= row[6]?.meta?.raw?.find(el=>el?.name==row[6].value[0]);
          // if(!gen1) return;

          let scenarioId =
            scenario_detail_id[
              scenario_detail_id.findIndex(
                (el) => el.crop_id == Object.keys(data)[i]
              )
            ].scenario_id;

          patternObj.crop_id = parseInt(Object.keys(data)[i]);
          patternObj.season = Object.keys(data[Object.keys(data)[i]])[j];
          patternObj.scenario_id = scenarioId;
          if (row[0].meta.details) {
            patternObj.stage_id = row[0].meta.details.stage_id;
          } else {
            // patternObj.stage_id = null;
          }
          patternObj.company_product_1_qty =
            row[1].value[0] == "" ? "0" : row[1].value[0];
          patternObj.company_product_1_demo =
            row[2].value[0] == "" ? "0" : row[2].value[0];
          patternObj.company_product_2_qty =
            row[4].value[0] == "" ? "0" : row[4].value[0];
          patternObj.company_product_2_demo =
            row[5].value[0] == "" ? "0" : row[5].value[0];

          patternObj.molecule_1_qty =
            row[7].value[0] == "" ? "0" : row[7].value[0];
          patternObj.molecule_2_qty =
            row[9].value[0] == "" ? "0" : row[9].value[0];

          patternObj.new_molecule_qty =
            row[11].value[0] == "" ? "0" : row[11].value[0];

          ans.push(patternObj);
        }
      }
    }
    return ans;
  };

  // useEffect(() => {
  //   console.log("finalCropView", finalCropView);
  // }, [finalCropView]);

  useEffect(() => {
    invokeLoader();
  }, [loader]);

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/cropplan";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  useEffect(() => {
    if (!selectedCrop || !selectedCrop.season_data) return;
    if (!selectedSeason) {
      if (
        selectedCrop.season_data[0] == "R" ||
        selectedCrop.season_data[0] == "K" ||
        selectedCrop.season_data[0] == "N"
      )
        setSelectedSeason({ season: selectedCrop.season_data[0] });
      else setSelectedSeason(selectedCrop.season_data[0]);
    }
  }, [selectedCrop]);

  const changeSelectedSeason = (season) => {
    let index = selectedCrop.season_data.findIndex((el) => el.season == season);
    if (index == -1) setSelectedSeason(selectedCrop?.season_data[0]);
    setSelectedSeason(selectedCrop.season_data[index]);
  };
  const changeSelectedCropSeason = (crop, season) => {
    let index = crop?.season_data?.findIndex((el) => el?.season == season);
    if (index == -1) setSelectedSeason(crop?.season_data[0]);
    setSelectedSeason(crop?.season_data[index]);
  };

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    setFinalInputData((state) => {
      let newState = { ...state };
      if (!newState[selectedCrop.crop_id]) newState[selectedCrop.crop_id] = {};

      newState[selectedCrop.crop_id][selectedSeason.season] = data;
      return newState;
    });
  };

  // useEffect(() => {
  //   console.log("finalinputdata", finalInputData);
  // }, [finalInputData]);

  const formatDec = (num) => {
    if (typeof num == "number") return num;
    return num.split(".")[0];
  };
  useEffect(() => {
    if (isLoading) return;
    if (!cropView) return;
    let newObj = {};
    for (let i = 0; i < Object.keys(cropView).length; i++) {
      let internalObj = {};
      for (
        let j = 0;
        j < Object.keys(cropView[Object.keys(cropView)[i]]).length;
        j++
      ) {
        let arr = [];
        let cropData =
          cropView[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ];

        for (let k = 0; k < cropData.length; k++) {
          const stageData = cropData[k];

          arr.push([
            {
              value: [stageData[5].selected],
              meta: {
                raw: stageData[5].meta.raw,
                details: stageData[0]?.meta?.details,
              },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[6].selected],
              meta: { raw: stageData[6].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[7].selected],
              meta: { raw: stageData[7].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[8].selected],
              meta: { raw: stageData[8].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[9].selected],
              meta: { raw: stageData[9].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
          ]);
        }
        if (
          inputData[Object.keys(cropView)[i]] &&
          inputData[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ]
        )
          internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] =
            inputData[Object.keys(cropView)[i]][
            Object.keys(cropView[Object.keys(cropView)[i]])[j]
            ];
        else
          internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] = arr;
      }
      newObj[Object.keys(cropView)[i]] = internalObj;
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get("mode") != "create") {
      editObj();
    }

    if (params.get("mode") == "create") {
      createObj();
    }
  }, [isLoading, cropView]);

  const editObj = () => {
    const params = new URLSearchParams(window.location.search);
    let q = "?territory_plan_id=" + params.get("id");
    setPageId(params.get("id"));
    let newObj = {};
    for (let i = 0; i < Object.keys(cropView).length; i++) {
      let internalObj = {};
      for (
        let j = 0;
        j < Object.keys(cropView[Object.keys(cropView)[i]]).length;
        j++
      ) {
        let arr = [];
        let cropData =
          cropView[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ];
        for (let k = 0; k < cropData.length; k++) {
          const stageData = cropData[k];
          arr.push([
            {
              value: [stageData[5].selected],
              meta: {
                raw: stageData[5].meta.raw,
                details: stageData[0]?.meta?.details,
              },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[6].selected],
              meta: { raw: stageData[6].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[7].selected],
              meta: { raw: stageData[7].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[8].selected],
              meta: { raw: stageData[8].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[9].selected],
              meta: { raw: stageData[9].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
          ]);
        }
        if (
          inputData[Object.keys(cropView)[i]] &&
          inputData[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ]
        ) {
          internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] =
            inputData[Object.keys(cropView)[i]][
            Object.keys(cropView[Object.keys(cropView)[i]])[j]
            ];
        } else {
          let newarray = arr?.map((each)=>{
            const filtereach = each?.filter((res)=>{
              return res?.meta?.raw !== undefined;
            })
            if(filtereach.length > 0){
              return each
            }
            return null
          }).filter((item)=> item !== null)
          internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] = newarray;
        }
      }
      newObj[Object.keys(cropView)[i]] = internalObj;
      setInputData(newObj);
    }
    fetchCropPlan(q)
      .then((res) => {
        const filteredData = res.data.dataList.crops_data.filter(
          (el) => el.stage_data.length > 0
        );
        setExistingData(filteredData);
        if (
          !res.data ||
          !res.data.dataList ||
          res.data.dataList.length == 0 ||
          Object.keys(newObj).length >= 0
        )
          for (let i = 0; i < res?.data?.dataList?.crops_data?.length; i++) {
            let crop = res?.data?.dataList?.crops_data[i];
            if (newObj[crop.crop_id]) {
              if (newObj[crop.crop_id][crop.season]) {
                for (let j = 0; j < crop?.stage_data?.length; j++) {
                  let stage = crop?.stage_data[j];
                  if (
                    !newObj ||
                    !newObj[crop.crop_id] ||
                    !newObj[crop.crop_id][crop.season]
                  )
                    continue;
                  let idx = newObj[crop.crop_id][crop.season].findIndex(
                    (el) =>
                      el[0]?.meta?.details?.id == stage?.scenario_detail_id
                  );
                  // if (idx != -1) {
                    newObj[crop.crop_id][crop.season][j][1].meta.lastId =
                      stage?.id;
                    newObj[crop.crop_id][crop.season][j][1].value[0] =
                      formatDec(stage?.company_product_1_qty);
                    newObj[crop.crop_id][crop.season][j][2].value[0] =
                      formatDec(stage?.company_product_1_demo);
                    newObj[crop.crop_id][crop.season][j][4].value[0] =
                      formatDec(stage?.company_product_2_qty);
                    newObj[crop.crop_id][crop.season][j][5].value[0] =
                      formatDec(stage?.company_product_2_demo);
                    newObj[crop.crop_id][crop.season][j][7].value[0] =
                      formatDec(stage?.molecule_1_qty);
                    newObj[crop.crop_id][crop.season][j][9].value[0] =
                      formatDec(stage?.molecule_2_qty);
                    newObj[crop.crop_id][crop.season][j][11].value[0] =
                      formatDec(stage?.new_molecule_qty);
                    // }
                    setInputData(newObj);
                  // }
                }
              }
            }
          }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const createObj = () => {
    let newObj = {};
    for (let i = 0; i < Object.keys(cropView).length; i++) {
      let internalObj = {};
      for (
        let j = 0;
        j < Object.keys(cropView[Object.keys(cropView)[i]]).length;
        j++
      ) {
        let arr = [];
        let cropData =
          cropView[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ];

        for (let k = 0; k < cropData.length; k++) {
          const stageData = cropData[k];

          arr.push([
            {
              value: [stageData[5].selected],
              meta: {
                raw: stageData[5].meta.raw,
                details: stageData[0]?.meta?.details,
              },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[6].selected],
              meta: { raw: stageData[6].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[7].selected],
              meta: { raw: stageData[7].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[8].selected],
              meta: { raw: stageData[8].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
            {
              value: [stageData[9].selected],
              meta: { raw: stageData[9].meta.raw },
            },
            {
              value: [0],
              meta: {},
            },
          ]);
        }
        if (
          inputData[Object.keys(cropView)[i]] &&
          inputData[Object.keys(cropView)[i]][
          Object.keys(cropView[Object.keys(cropView)[i]])[j]
          ]
        )
          internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] =
            inputData[Object.keys(cropView)[i]][
            Object.keys(cropView[Object.keys(cropView)[i]])[j]
            ];
        else{
        let newarray = arr?.map((each)=>{
          const filtereach = each?.filter((res)=>{
            return res?.meta?.raw !== undefined;
          })
          if(filtereach.length > 0){
            return each
          }
          return null
        }).filter((item)=> item !== null)
        internalObj[Object.keys(cropView[Object.keys(cropView)[i]])[j]] = newarray;
      }
    }
    newObj[Object.keys(cropView)[i]] = internalObj;
  }
      // mergeCreateRows(newObj)

    setInputData(newObj);
  };

  //CALLED EVERYTIME IN USEEFFECT WHEN EITHER SELECTED CROP OR SEASON CHANGES
  const fetchCrop = async (crop_id, season) => {
    if (Object.keys(locationData).length == 0) return;
    let data = [[[]]];

    var cropViewState;

    try {
      setLoader(true);

      //FECTH ALL THE CROPS FROM DROPDOWN
      const crops = await getCropScenarioDropDown(locationData?.territory_code);
      if (!crops.data.dataList || crops.data.dataList.length == 0)
        return setLoader(false);

      //GET THE INDEX OF DROPDOWN OBJ REPRESENTING CURRENT CROP
      const idx = crops?.data?.dataList?.findIndex(
        (el) => el.crop_id == crop_id
      );

      if (idx == -1) return setLoader(false);

      const query = `?crop_id=` + crops.data.dataList[idx].crop_id;
      //FETCH STAGES OF CURRENT CROP
      let cropStages;
      try {
        cropStages = await getCropStagesDropDown(query);
      } catch (err) {
        toast.error(
          "Failed to fetch crop stages for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!cropStages.data.dataList) {
        toast.error(
          "Crop stages unfetched for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }

      //FETCH PESTS FOR CURRENT CROP
      let pestData;
      try {
        pestData = await getPestData(query);
      } catch (err) {
        toast.error(
          "Failed to fetch pests for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!pestData.data.dataList) {
        toast.error(
          "Pest data unfetched for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }

      //FETCH CATEGORY
      let productCat;
      try {
        productCat = await getProductCategory();
      } catch (err) {
        toast.error(
          "Failed to product category for " + crops.data.dataList[idx].crop_id
        );
        return setLoader(false);
      }
      if (!productCat.data.dataList || productCat.data.dataList.length == 0)
        return setLoader(false);

      //FETCH INDEX OF SEASON OBJECT IN CROP OBJECT USED FROM DROPDOWN DATA
      const sidx = crops?.data?.dataList[idx]?.season_data?.findIndex(
        (el) => el?.season == season
      );
      if (sidx == -1) return setLoader(false);

      setLoader(true);
      //LOOPING OVER ALL THE STAGES OF CURRENT CROP
      let tempCropView = [];
      for (let j = 0; j < cropStages.data.dataList.length; j++) {
        let stageQuery =
          "?crop_id=" +
          crops.data.dataList[idx].crop_id +
          "&season=" +
          crops.data.dataList[idx].season_data[sidx].season +
          "&stage_id=" +
          cropStages.data.dataList[j].id +
          "&pattern_trans_id=" +
          crops.data.dataList[idx].pattern_trans_id;

        //FETCHING WEEK FROM AND WEEK TO OF THE CURRENT STAGE OF SELELECTED CROP
        let durations;
        try {
          durations = await getStagesDates(stageQuery);
        } catch (err) {
          toast.error(
            "Failed to fetch Durations for stage_id=" +
            cropStages.data.dataList[j].id
          );
          continue;
        }
        if (!durations.data.dataList) {
          continue;
        }
        let week_from = durations.data.dataList.week_from;
        let week_to = durations.data.dataList.week_to;

        //PREPARE ROW DATA FOR CURRENT STAGE OF CURRENT CROP USING ALL THE DATA FETCHED ABOVE
        let row = [
          {
            value: [cropStages.data.dataList[j].name],
            meta: {
              raw: cropStages.data.dataList[j],
            },
          },
          {
            value: [week_from],
            meta: {
              id: "",
            },
          },
          {
            value: [week_to],
            meta: {
              id: "",
            },
          },
          {
            value: productCat.data.dataList.map((el) => el.name),
            meta: {
              raw: productCat?.data?.dataList,
            },
          },
          {
            value: pestData.data.dataList.map((el) => el.name),
            meta: {
              raw: pestData.data.dataList,
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
          {
            value: [],
            meta: {
              id: "",
            },
          },
        ];

        //PUSH CREATED ROW TO CROPVIEW OBJECT
        tempCropView.push(row);
      }

      let newState = JSON.parse(JSON.stringify({ ...cropView }));
      if (!newState[crops.data.dataList[idx].crop_id])
        newState[crops.data.dataList[idx]?.crop_id] = {};
      newState[crops.data.dataList[idx]?.crop_id][
        crops.data.dataList[idx].season_data[sidx]?.season
      ] = tempCropView;
      cropViewState = newState;
      setLoader(false);
    } catch (err) {
      toast.error("Crop Pattern should be Submitted before Crop Plan");
      setTimeout(() => {
        navigate("/mymarket/croppattern");
      }, 1000);
    }
    //UPDATE THE CREATED DATA IF THERE IS ANY ENTRY FOR THE SAME
    const params = new URLSearchParams(window.location.search);
    // if(params.get("mode")!="create")
    try {
      let crop_scenarios = await getCropScenario();

      if (!crop_scenarios || !crop_scenarios.data.dataList)
        crop_scenarios = crop_scenarios.data.dataList;

      let cfy = await getCurrentFy();
      let territory_scenario_id = crop_scenarios.data.dataList.findIndex(
        (el) => el.territory_code == locationData?.territory_code
      );
      console.log(
        "cropScenecropScenecropScenecropScene",
        territory_scenario_id,
        crop_scenarios
      );
      territory_scenario_id =
        crop_scenarios.data.dataList[territory_scenario_id].id;

      let q = "?territory_scenario_id=" + territory_scenario_id;

      //FETCH DATA OF LAST SAVE
      let cropScene = await fetchCropScenario(q);
      if (cropScene.data.dataList) {
        cropScene = cropScene.data.dataList;

        //GET INDEX OF CURRENT SELECTED CROP OBJECT
        let idx = cropScene?.crops_data?.findIndex(
          (el) => el.crop_id == crop_id && el.season == season
        );
        //IF WE GET INDEX MOVE FURTHER
        if (idx != -1) {
          let crop = cropScene?.crops_data[idx];
          setLoader(true);
          //IF THERE IS ENTRY UPDATE
          if (
            cropViewState[crop.crop_id] &&
            cropViewState[crop.crop_id][crop.season]
          ) {
            //LOOP OVER ALL THE STAGE DATA WE GOT FROM LAST SAVE TO UPDATE THE SAME ON CURRENT STATE
            for (let j = 0; j < crop?.stage_data?.length; j++) {
              const stageEdit = crop?.stage_data[j];

              //FIND ROW INDEX OF CURRENT STAGE ROW IN OUR INTERNAL STATE SO WE CAN UPDATE IT WITH STAGE DATA FETCHED
              const stageIdx = cropViewState[crop.crop_id][
                crop.season
              ].findIndex((el) => el[0]?.meta?.raw?.id == stageEdit.stage_id);
              if (stageIdx == -1) continue;
              //UPDATING INPUT STATE BASED ON FETCHED DATA
              cropViewState[crop.crop_id][crop.season][stageIdx][3].selected =
                stageEdit?.category_name;
              cropViewState[crop.crop_id][crop.season][stageIdx][4].selected =
                stageEdit?.pest_name;

              //FETCH COMPANY PRODUCT, GENRIC PRODUCT (PRODUCT) AND NEW PRODUCT DROPDOWN DATA BASED ON CATEGORY IN FETCHED DATA
              const query =
                "?category_id=" +
                stageEdit?.category_id +
                "&crop_id=" +
                crop?.crop_id;
              const query2 =
                "?category_id=" + stageEdit?.category_id + "&is_new=false";
              const query3 =
                "?category_id=" + stageEdit?.category_id + "&is_new=true";

              if (stageEdit?.category_id) {
                let cp = await getCompanyProduct(query);
                if (!cp.data || !cp.data.dataList) continue;
                cp = cp.data.dataList;

                let gp1 = await getGenricProduct(query2);
                let gp2 = await getGenricProduct(query3);
                if (
                  !gp1.data ||
                  !gp1.data.dataList ||
                  !gp2.data ||
                  !gp2.data.dataList
                )
                  continue;

                gp1 = gp1.data.dataList;
                gp2 = gp2.data.dataList;

                //SET ALL THE FETCHED DATA IN OUR CROPVIEWSTATE VARIABLE
                cropViewState[crop.crop_id][crop.season][
                  stageIdx
                ][0].meta.details = stageEdit;
                cropViewState[crop.crop_id][crop.season][
                  stageIdx
                ][0].meta.fulldetails = crop;
                cropViewState[crop.crop_id][crop.season][stageIdx][5].selected =
                  stageEdit?.company_product_1_name;
                cropViewState[crop.crop_id][crop.season][stageIdx][5].value =
                  cp?.map((el) => el.name);
                cropViewState[crop.crop_id][crop.season][stageIdx][5].meta.raw =
                  cp;

                cropViewState[crop.crop_id][crop.season][stageIdx][6].selected =
                  stageEdit?.company_product_2_name;
                cropViewState[crop.crop_id][crop.season][stageIdx][6].value =
                  cp?.map((el) => el.name);
                cropViewState[crop.crop_id][crop.season][stageIdx][6].meta.raw =
                  cp;

                cropViewState[crop.crop_id][crop.season][stageIdx][7].selected =
                  stageEdit?.molecule_1_name;
                cropViewState[crop.crop_id][crop.season][stageIdx][7].value =
                  gp1?.map((el) => el.name);
                cropViewState[crop.crop_id][crop.season][stageIdx][7].meta.raw =
                  gp1;

                cropViewState[crop.crop_id][crop.season][stageIdx][8].selected =
                  stageEdit?.molecule_2_name;
                cropViewState[crop.crop_id][crop.season][stageIdx][8].value =
                  gp1?.map((el) => el.name);
                cropViewState[crop.crop_id][crop.season][stageIdx][8].meta.raw =
                  gp1;

                cropViewState[crop.crop_id][crop.season][stageIdx][9].selected =
                  stageEdit?.new_molecule_name;
                cropViewState[crop.crop_id][crop.season][stageIdx][9].value =
                  gp2?.map((el) => el.name);
                cropViewState[crop.crop_id][crop.season][stageIdx][9].meta.raw =
                  gp2;
              }
            }
          }
        }
        setLoader(false);

        //FINALLY SET THE CROPVIEW STATE WITH UPDATED DATA
        setCropView(cropViewState);
      }
    } catch (err) {
      console.log(err);
    }

    //TO REMOVE ALL THE STAGES ROW'S WHICH DON'T HAVE ALL DATA PREFILLED
    // if(params.get("mode")=="view")
    // try {
    //   for (let i = 0; i < Object.keys(cropViewState)?.length; i++) {
    //     for (
    //       let j = 0;
    //       j < Object.keys(cropViewState[Object.keys(cropViewState)[i]])?.length;
    //       j++
    //     ) {
    //       const crop =
    //         cropViewState[Object.keys(cropViewState)[i]][
    //         Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
    //         ];

    //       let arr = [];
    //       for (let k = 0; k < crop?.length; k++) {
    //         const stage = crop[k];

    //         //ONLY PUSH ROW'S WHICH HAVE ALL THE DATA FILLED
    //         if (
    //           !stage[3].selected ||
    //           !stage[4].selected ||
    //           !stage[5].selected ||
    //           !stage[6].selected ||
    //           !stage[7].selected ||
    //           !stage[8].selected ||
    //           !stage[9].selected
    //         ) {
    //         } else
    //           arr.push(
    //             cropViewState[Object.keys(cropViewState)[i]][
    //             Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
    //             ][k]
    //           );
    //       }

    //       cropViewState[Object.keys(cropViewState)[i]][
    //         Object.keys(cropViewState[Object.keys(cropViewState)[i]])[j]
    //       ] = arr;
    //     }
    //   }
    //   //SET ALL THE FILTERED DATA
    //   setCropView(cropViewState);
    // } catch (err) {
    //   console.log("CHECKKKkkk", err);
    //   console.log(err);
    // }
    //SET ISLOADING TO FALSE SO TABLE CAN RENDER
    setLoader(false);
  };

  // useEffect(() => {
  //   console.log({ cropView });
  // }, [cropView])

  //FETCH DATA FOR THE TABLE WHEN SELECTED CROP OR SEASON CHANGES
  useEffect(() => {
    if (
      !selectedCrop ||
      !selectedSeason ||
      !cropView ||
      (cropView[selectedCrop?.crop_id] &&
        cropView[selectedCrop?.crop_id][selectedSeason?.season])
    )
      return;
    fetchCrop(selectedCrop?.crop_id, selectedSeason?.season)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [selectedCrop, selectedSeason, locationData]);

  const setCropViewHelper = async () => {
    if (Object.keys(locationData).length == 0) return;
    try {
      //FETCH CROP DROPDOWN
      let crops = await getCropPlanDropdownCrops(locationData?.territory_code);
      if (!crops.data.dataList || crops.data.dataList.length == 0) return;
      crops = crops.data.dataList;

      //SET DROPDOWN CROPS AND SET FIRST CROP AS SELECTED
      setCropDropdown(crops);
      setSelectedCrop(crops[0]);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.detail);
      setTimeout(() => {
        navigate("/mymarket/cropscenario");
      }, 1000);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setCropViewHelper()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    setMode(params.get("mode"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "?plan_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getCropPlanApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }
  }, [mode, locationData]);

  useEffect(() => { }, [selectedCrop]);

  useEffect(() => {
    let tData = [];

    for (let i = 0; i < stagesData.length; i++) {
      let row = [
        {
          value: [stagesData[i].name],
          meta: {
            raw: stagesData[i],
          },
        },
        {
          value: ["1-Jan"],
          meta: {
            id: "abc",
          },
        },
        {
          value: ["31-Jan"],
          meta: {
            id: "abc",
          },
        },
        {
          value: productCategory.map((el) => el.name),
          meta: {
            raw: productCategory,
          },
        },
        {
          value: pestData.map((el) => el.name),
          meta: {
            raw: pestData,
          },
        },
        {
          value: ["Crop 1", "Crop 2", "Crop 3", "Crop 4"],
          meta: {
            id: "abc",
          },
        },
        {
          value: ["Crop 1", "Crop 2", "Crop 3", "Crop 4"],
          meta: {
            id: "abc",
          },
        },
        {
          value: ["Crop 1", "Crop 2", "Crop 3", "Crop 4"],
          meta: {
            id: "abc",
          },
        },
        {
          value: ["Crop 1", "Crop 2", "Crop 3", "Crop 4"],
          meta: {
            id: "abc",
          },
        },
        {
          value: ["Crop 1", "Crop 2", "Crop 3", "Crop 4"],
          meta: {
            id: "abc",
          },
        },
      ];
      tData.push(row);
    }

    setTableData(tData);
  }, [stagesData]);

  let columnSettings, columnSettings2;

  columnSettings = [
    {
      name: "Stages",
      type: "T",
      isEditable: false,
    },
    {
      name: "Week from",
      type: "T",
      isEditable: false,
    },
    {
      name: "Week to",
      type: "T",
      isEditable: false,
    },
    {
      name: "Category",
      type: "D",
      isEditable: false,
    },
    {
      name: "Pest",
      type: "D",
      isEditable: false,
    },
    {
      name: "Company Product",
      type: "D",
      isEditable: false,
    },
    {
      name: "Company Product",
      type: "D",
      isEditable: false,
    },
    {
      name: "Product",
      type: "D",
      isEditable: false,
    },
    {
      name: "Product",
      type: "D",
      isEditable: false,
    },
    {
      name: "New Product",
      type: "D",
      isEditable: false,
    },
  ];

  const par = new URLSearchParams(window.location.search);
  if (par.get("mode") != "view" && par.get("mode") != "approve")
    columnSettings2 = [
      {
        name: "Company Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: true,
      },
      {
        name: "Demo",
        type: "N",
        isEditable: true,
      },
      {
        name: "Company Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: true,
      },
      {
        name: "Demo",
        type: "N",
        isEditable: true,
      },
      {
        name: "Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: true,
      },
      {
        name: "Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: true,
      },
      {
        name: "New Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: true,
      },
    ];
  else
    columnSettings2 = [
      {
        name: "Company Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: false,
      },
      {
        name: "Demo",
        type: "N",
        isEditable: false,
      },
      {
        name: "Company Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: false,
      },
      {
        name: "Demo",
        type: "N",
        isEditable: false,
      },
      {
        name: "Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: false,
      },
      {
        name: "Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: false,
      },
      {
        name: "New Product",
        type: "T",
        isEditable: false,
      },
      {
        name: "Qty",
        type: "N",
        isEditable: false,
      },
    ];

  const onSubmitHandler = async (e) => {
    if (freezeCTA) return;

    // setFreezeCTA(true);
    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot save as cfy could not be fetched!");
    }

    cfy = cfy.data.dataList.fy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const plan_data = await convertCategoryData(finalInputData);

    if (!plan_data) return;
    body.plan_data = plan_data;

    if (mode == "create") {
      body.id = 0;
      postCropPlan(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 409) {
            toast.error("Crop Scenario should be submitted first");
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropPlan(body)
        .then((res) => {
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === 409) {
            toast.error("Crop Scenario should be submitted first");
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        });
    }
  };

  const onSaveHandler = async (e) => {
    if (freezeCTA) return;

    // setFreezeCTA(true);
    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot save as cfy could not be fetched!");
    }

    cfy = cfy.data.dataList.fy;

    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;

    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    const plan_data = await convertCategoryData(finalInputData);
    if (!plan_data) return;
    body.plan_data = plan_data;
    if (mode == "create") {
      body.id = 0;
      postCropPlan(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putCropPlan(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/cropplan");
          }, 1000);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/cropplan");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      plan_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postCropPlanAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };
  function invokeLoader() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  }

  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crop Scenario</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}

        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Crop Plan
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY IN LT/KG
          </p>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            DEMO IN NO's
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 " style={{ margin: "10px" }}>
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>

        <div className={styles["agri-portal-controls"]}>
          <div className={styles["left"]}>
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedCrop?selectedCrop.crop_name:"Crop"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    cropDropdown && cropDropdown.map(el=>{
                                        return <Dropdown.Item href="#" onClick={e=>setSelectedCrop(el)}>
                                           {el.crop_name}
                                        </Dropdown.Item>
                                    })  
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                value={selectedCrop.crop_name}
                // classNamePrefix="select"
                onChange={(e) => {
                  setSelectedSeason(
                    cropDropdown.find((el) => el?.crop_name == e.target.value)
                      ?.season_data[0]
                  );
                  setSelectedCrop(
                    cropDropdown.find((el) => el?.crop_name == e.target.value)
                  );
                }}
              >
                {cropDropdown &&
                  cropDropdown.map((el) => {
                    return <option value={el.crop_name} label={el.crop_name} />;
                  })}
              </select>
            </div>
            {/* <div>
                        <div style={{zIndex:1010,position:"relative"}}>
                            <Dropdown show>
                            <Dropdown.Toggle
                                variant="primary-inner dropdownbtn"
                                id="dropdown-basic"
                            >
                                {selectedSeason.season=="R"?"Rabi":""}
                                {selectedSeason.season=="K"?"Kharif":""}
                                {selectedSeason.season=="N"?"None":""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    selectedCrop?.season_data && selectedCrop.season_data?.map((el,idx)=>{
                                        return <Dropdown.Item href="#" onClick={()=>changeSelectedSeason(idx)}>
                                        {el.season=="R"?"Rabi":""}
                                        {el.season=="K"?"Kharif":""}
                                        {el.season=="N"?"None":""}
                                    </Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div> */}
            <div>
              <select
                className="form-control newbgselect"
                name="business_unit_id"
                style={{
                  cursor: "pointer",
                  border: "1px solid #0195D4",
                  color: "#0195DA",
                  minWidth: "120px",
                }}
                value={selectedSeason.season}
                // classNamePrefix="select"
                onChange={(e) => {
                  changeSelectedSeason(e.target.value);
                }}
              >
                {selectedCrop?.season_data &&
                  selectedCrop?.season_data.map((el, idx) => {
                    let s;
                    if (el?.season == "R") s = "Rabi";
                    if (el?.season == "K") s = "Kharif";
                    if (el?.season == "N") s = "None";
                    return <option value={el.season} label={s} />;
                  })}
              </select>
            </div>
            <div>
              <p
                style={{
                  color: "#0195D4",
                  padding: "4px 8px",
                  border: "1px solid #0195D4",
                  borderRadius: "4px",
                }}
              >
                {selectedSeason.starting_date}
              </p>
            </div>
          </div>
          <div className={styles["right"]}></div>
        </div>
        {existingData.length > 0 &&
          (mode == "edit" || mode == "view" || mode == "approve") && (
            <div className={styles["table_contain"]}>
              <div style={{ zIndex: 1010, position: "relative" }}>
                <div className={styles["agri-portal-label"]}>
                  <b>Data Entered For</b>
                </div>
              </div>
              <div className={styles["agri-portal-table"]}>
                <div
                  class="table-responsive"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <table
                    class="table table-striped table-bordered tablecured"
                    style={{ maxHeight: "200px" }}
                  >
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ textAlign: "center" }}>
                        <th>Crop Name</th>
                        <th>Crop Season</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingData.map((ele) => {
                        let season = "";
                        switch (ele.season) {
                          case "K":
                            season = "Kharif";
                            break;
                          case "R":
                            season = "Rabi";
                            break;
                          case "N":
                            season = "None";
                        }
                        return (
                          <tr style={{ textAlign: "center" }}>
                            <td>{ele?.crop_name}</td>
                            <td>{season}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedCrop(
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  )
                                );
                                if (
                                  selectedCrop ==
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  ) &&
                                  selectedSeason.season != ele?.season
                                ) {
                                  changeSelectedSeason(ele?.season);
                                }
                                if (
                                  selectedCrop !=
                                  cropDropdown?.find(
                                    (el) => el?.crop_name == ele?.crop_name
                                  ) &&
                                  selectedSeason.season != ele?.season
                                ) {
                                  changeSelectedCropSeason(
                                    cropDropdown?.find(
                                      (el) => el?.crop_name == ele?.crop_name
                                    ),
                                    ele?.season
                                  );
                                }
                              }}
                            >
                              {mode == "view" ? "View" : "Edit"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        {!isLoading && cropView && (
          <>
            {cropView[selectedCrop?.crop_id] &&
              cropView[selectedCrop?.crop_id][selectedSeason?.season]?.length !=
              0 ? (
              <>
                <div className={styles["table_contain"]}>
                  <div className={styles["agri-portal-table"]}>
                    <ModularTable
                      crop_id={selectedCrop.crop_id}
                      columns={columnSettings}
                      data={
                        cropView[selectedCrop.crop_id]
                          ? cropView[selectedCrop.crop_id][
                          selectedSeason.season
                          ]
                          : undefined
                      }
                    />
                  </div>
                </div>
                <div className={styles["table_contain"]}>
                  <div style={{
                    display: 'flex'
                  }}>
                    <div style={{ zIndex: 1010, position: "relative" }}>
                      <div className={styles["agri-portal-label"]}>
                        <b>CROP WISE PRODUCT AND DEMO PLANNING</b>
                      </div>
                    </div>
                  </div>
                  {console.log("CROP PLAN =>", inputData)}
                  <div className={styles["agri-portal-table"]}>
                    <ModularTableInput
                      onTableDataChange={onTableDataChange}
                      crop_id={selectedCrop.crop_id}
                      columns={columnSettings2}
                      data={
                        inputData[selectedCrop.crop_id]
                          ? inputData[selectedCrop.crop_id][
                          selectedSeason.season
                          ]
                          : undefined
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className={styles["nodata"]}>
                <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
                  No Stages Available
                </h4>
              </div>
            )}
            {/* {approvalList && approvalList.length > 0 &&<div className={styles["table_contain"]}>
                <div className={styles["table"]}>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                            <thead>
                            <tr>
                                <th>Action At</th>
                                <th>Action By</th>
                                <th>Action User</th>
                                <th>Action Status</th>
                                <th>Action Remark</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    approvalList && approvalList.map(el=>{
                                        let status;

                                        if(el.status=="D") status="Saved";
                                        else if(el.status=="P") status="Pending";
                                        else if(el.status=="A") status="Approved";
                                        else if(el.status=="R") status="Rejected";

                                        return <tr>
                                            <td>{el.action_at?.split("T")[0]}</td>
                                            <td>{el.action_by_id}</td>
                                            <td>{el.action_user_name}</td>
                                            <td>{status}</td>
                                            <td>{el.remarks}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>} */}
            {approvalList?.approvals && approvalList?.approvals.length > 0 && (
              <div className={styles["table_contain"]}>
                <div className={styles["agri-portal-table"]}>
                  <div
                    class="table-responsive"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <table
                      class="table table-striped table-bordered tablecured"
                      style={{ maxHeight: "200px" }}
                    >
                      <thead style={{ position: "sticky", top: 0 }}>
                        <tr style={{ textAlign: "center" }}>
                          <th>Action At</th>
                          <th>Action By</th>
                          <th>Action User</th>
                          <th>Action Status</th>
                          <th>Action Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvalList?.approvals &&
                          approvalList?.approvals.map((el, i) => {
                            let status;

                            const findPendingIndex =
                              approvalList.approvals.findIndex(
                                (ele) =>
                                  ele.status == "P" && ele.discarded == false
                              );

                            if (el.status == "D") status = "Saved";
                            else if (el.status == "P" && el.discarded == true)
                              status = "Auto Discarded";
                            else if (el.status == "P") status = "Pending";
                            else if (el.status == "A") status = "Approved";
                            else if (el.status == "R") status = "Rejected";
                            else if (el.status == "RP")
                              status = "Auto Rejected";

                            return (
                              <tr>
                                <td>
                                  {status == "Pending"
                                    ? "-"
                                    : moment(el.action_at)
                                      .utc()
                                      .format("DD-MM-YYYY HH:mm:ss")}
                                </td>
                                <td>{el.action_by_id}</td>
                                <td>{el.action_user_name}</td>
                                <td>
                                  {findPendingIndex == -1
                                    ? status
                                    : i > findPendingIndex
                                      ? "-"
                                      : status}
                                </td>
                                <td>{el.remarks}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            <div className={styles["ctas"]}>
              {mode != "view" && mode != "approve" && (
                <div className={styles["ctas_left"]}>
                  <div
                    class="btn btn-success"
                    onClick={() => {
                      confirmDialog("P");
                    }}
                  >
                    Submit
                  </div>
                  <div
                    class="btn btn-primary-inner"
                    onClick={() => {
                      confirmDialog("D");
                    }}
                  >
                    Save
                  </div>
                </div>
              )}
              {mode == "approve" || approvalList?.for_approval ? (
                <div>
                  <div className="col-md-12">
                    <div className="form-group innergroup">
                      <label>
                        Add Comments <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="additional_comments"
                        placeholder="Enter Comments here..."
                        onChange={(e) => setRemark(e.target.value)}
                        value={remark}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-right pb-3">
                    <button
                      className="btn btn-primary-inner bpi-main"
                      type="submit"
                      onClick={() => validate("approve")}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-outline-danger mr-3 ml-3"
                      type="button"
                      onClick={() => {
                        onApprovalCancelHandler();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary-inner bpi-main"
                      type="submit"
                      onClick={() => validate("reject")}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              ) : (
                <div className={styles["ctas_right"]}>
                  <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                    Back
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {/* {isLoading && (
          <div className={styles["nodata"]}>LOADING DATA ... PLEASE WAIT </div>
        )} */}
      </div>
    </div>
  );
};
export default Root;
