import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as OrgService from "../../service/organisation";
import * as eventService from "../../service/eventService";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const channel_types = [
  { label: "Email", value: 1 },
  { label: "SMS", value: 2 },
  { label: "Whatsapp", value: 3 },
  { label: "Push Notification", value: 4 },
];

const validationSchema = Yup.object({
  event_name: Yup.number().required("*Event name is required").nullable(),
  view_name: Yup.number().required("*View name is required").nullable(),
  // subject: Yup.string()
  //   .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
  //   .matches(
  //     /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
  //     "*Insert only normal character"
  //   )
  //   .required("*Event subject is required").nullable(),
  body: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    .required("*Event body is required")
    .nullable(),
  // template_id: Yup.number().required("Template id is required").nullable(),
});

const AddEvent = (props) => {
  const nameForm = "Event Master";
  const { edit, showForm, onClose, editEvent, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isEventExist, setIsEventExist] = useState(false);
  const [notificationChannel, setNotificationChannel] = useState([]);
  const [disabledField, setDisabledField] = useState({
    templateDisabled: true,
    subjectDisabled: true,
  });
  const [eventBody, setEventBody] = useState("");

  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState({
    id: 0,
    event_name: "",
    view_name: "",
    subject: "",
    body: "",
    template_id: 0,
    notification_id: [],
  });

  const saveEvent = (values) => {
    setIsLoading(true);
    eventService
      .saveEvent(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const addNotificationChannel = (e) => {
    let updatedData = e;
    setNotificationChannel(updatedData);
  };

  const updateEvent = (values) => {
    setIsLoading(true);
    let datass = values;
    eventService
      .updateEvent(datass, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  //   const isOrgExists = (e, fieldName) => {
  //     let data = {};
  //     data[fieldName] = e.target.value;
  //     if (editOrganisation?.name == e.target.value) {
  //       setIsOrgExist(false);
  //       return;
  //     } else if (editOrganisation?.code == e.target.value) {
  //       setIsOrgCodeExist(false);
  //       return;
  //     } else {
  //       OrgService.isOrgExists(data)
  //         .then((res) => {
  //           if (e.target.name == "name") {
  //             setIsOrgExist(res?.data?.dataList?.is_exist);
  //           } else {
  //             setIsOrgCodeExist(res?.data?.dataList?.is_exist);
  //           }
  //         })
  //         .catch((err) => console.log("Error=>", err));
  //     }
  //   };

  //   const onProfileChange = (e, flag) => {
  //     const files = e.target.files[0];
  //     if (files.size <= 1000000) {
  //       setImgSize(false);
  //       setImg2Size(false);
  //       setLink(undefined)
  //       const fileData = new FormData();
  //       fileData.append("uploaded_file", files);
  //       OrgService.uploadImages(fileData).then((response) => {
  //         if (flag == "logo") {
  //           setUploadData({ ...uploadData, logo: response.data });
  //           setIsLogo(false)
  //         } else {
  //           setUploadDataIm({ ...uploadDataIm, bg_image: response.data });
  //           setBeImageLogo(false)
  //         }
  //       });
  //     } else {
  //       if(flag === "logo"){
  //         setImgSize(true);
  //       }else{
  //         setImg2Size(true);
  //       }
  //     }
  //   };

  const dataBlank = () => {
    onClose();
    setNotificationChannel([]);
  };

  useEffect(() => {
    if (editEvent) {
      console.log(editEvent);
      setEventData(editEvent);
      Object.keys(editEvent).forEach((item) => {
        if (eventData[item] !== undefined && eventData[item] !== null) {
          if (item === "notification_id") {
            let channels = channel_types
              .filter((obj) => {
                if (editEvent[item].includes(obj.value)) {
                  return obj;
                }
              })
              .map((obj) => obj);
            setNotificationChannel(channels);
          } else {
            console.log(item);
            eventData[item] = editEvent[item];
          }
        }
      });
    }
  }, [editEvent]);

  useEffect(() => {
    checkIfDisabled();
  }, [notificationChannel]);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  const checkIfDisabled = () => {
    let selected = notificationChannel.map((obj) => obj.value);
    if (selected.length === 0) {
      setDisabledField((prev) => ({
        ...prev,
        templateDisabled: true,
        subjectDisabled: true,
      }));
    }
    if (selected.indexOf(2) !== -1) {
      setDisabledField((prev) => ({
        ...prev,
        templateDisabled: false,
        subjectDisabled: true,
      }));
    }
    if (selected.indexOf(3) !== -1) {
      setDisabledField((prev) => ({
        ...prev,
        templateDisabled: true,
        subjectDisabled: true,
      }));
    }
    if (selected.indexOf(1) !== -1) {
      setDisabledField((prev) => ({
        ...prev,
        templateDisabled: true,
        subjectDisabled: false,
      }));
    }
    if (selected.indexOf(2) !== -1 && selected.indexOf(1) !== -1) {
      setDisabledField((prev) => ({
        ...prev,
        templateDisabled: false,
        subjectDisabled: false,
      }));
    }
  };

  return (
    <>
      <Formik
        initialValues={eventData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (notificationChannel.length > 0) {
            values.notification_id = notificationChannel.map(
              (obj) => obj.value
            );
          }
          if (values.id) {
            updateEvent(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          } else {
            console.log(values);
            saveEvent(values);
            AuditLog.addAuditLog(dispatch, nameForm);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title> {edit === true ? "Edit" : "Add"} Event</Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Event Name
                             <span className="text-danger">*</span>
                            </label>
                            <select
                              class="form-control newbgselect main-pointer "
                              name="event_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={Number(values.event_name)}
                            >
                              <option
                                value="0"
                                label="Select Event Name"
                                disabled
                              />
                              <option
                                key="event1"
                                value="1"
                                label="Create User"
                              />
                              <option
                                key="event2"
                                value="2"
                                label="PO Request"
                              />
                              <option
                                key="event3"
                                value="3"
                                label="Update Password"
                              />
                              <option
                                key="event4"
                                value="4"
                                label="Block User"
                              />
                            </select>
                            {touched.event_name && errors.event_name ? (
                              <div className="small text-danger">
                                {errors.event_name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              View Name
                             <span className="text-danger">*</span>
                            </label>
                            <select
                              class="form-control newbgselect main-pointer "
                              name="view_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={Number(values.view_name)}
                            >
                              <option
                                value="0"
                                label="Select View Name"
                                disabled
                              />
                              <option key="view1" value="1" label="User view" />
                            </select>
                            {touched.view_name && errors.view_name ? (
                              <div className="small text-danger">
                                {errors.view_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Notification Channel
                             <span className="text-danger">*</span>
                            </label>
                            <Select
                              name="notification_id"
                              isMulti
                              options={channel_types}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(e) => {
                                addNotificationChannel(e);
                              }}
                              onBlur={handleBlur}
                              value={notificationChannel}
                            />
                            {/* {errors.notification_id && touched["react-select-15-input"]? (
                              <div className="small text-danger">
                                {errors.notification_id}
                              </div>
                            ) : null} */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Subject (Email)
                             <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="subject"
                              className="form-control bg-white"
                              value={values.subject}
                              defaultValue=""
                              placeholder="Enter event subject"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disabledField.subjectDisabled}
                            />
                            {touched.subject && errors.subject ? (
                              <div className="small text-danger">
                                {errors.subject}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Template ID (SMS)
                             <span className="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              name="template_id"
                              className="form-control bg-white"
                              value={
                                values.template_id === 0
                                  ? ""
                                  : values.template_id
                              }
                              placeholder="Enter Template Id"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={disabledField.templateDisabled}
                            />
                            {touched.template_id && errors.template_id ? (
                              <div className="small text-danger">
                                {errors.template_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group innergroup">
                            <label>
                              Event Body<span className="text-danger">*</span>
                            </label>
                            <Field name="body">
                              {({ field }) => (
                                <ReactQuill
                                  value={field.value}
                                  onChange={field.onChange(field.name)}
                                />
                              )}
                            </Field>
                            {/* <ReactQuill 
                            theme="snow" 
                            name='body' 
                            value={eventBody} 
                            onChange={(e) => setEventBody(e)} 
                            onBlur={handleBlur}
                            /> */}
                            {/* <input
                              type="text"
                              name="body"
                              className="form-control"
                              placeholder="Enter Event Body"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.body}
                            /> */}
                            {touched.body && errors.body ? (
                              <div className="small text-danger">
                                {errors.body}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          dataBlank();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddEvent;
