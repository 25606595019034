import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import total_idea_img from '../../assets/images/total-idea.png'
import under_imp_img from '../../assets/images/under-imp.png'
import idea_img from '../../assets/images/Businessman_sitting_thinking.png'
import imp_img from '../../assets/images/imp.png'

function IdeaApproval() {
    
    const location = useLocation()
    console.log(location.state.role);
    const total_idea = location.state.role.IdeaLead + location.state.role.IdeaImplementer + location.state.role.RM
    const idea_lead = location.state.role.IdeaLead
    const IdeaImplementer = location.state.role.IdeaImplementer
    const RM = location.state.role.RM

  return (
    <>

    <div className="row mt-5">
                <div class="col-md-12">
                  <h3 class="policyhead">My Idea Approval</h3>
                </div>
              </div>

    <div class="row mt-5">
                <div class="col-md-3">
                    <div class="bspoc-box">
                        <div class="row align-items-center">
                            <div class="col-4 pr-0">
                                <img src={total_idea_img} />
                            </div>
                            <div class="col-8"> 
                                <h2>{total_idea}</h2>
                                <h3>Total Idea for Approval</h3> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <Link to="/idea-implementation">
                    <div class="bspoc-box">
                        <div class="row align-items-center">
                            <div class="col-4 pr-0">
                                <img src={under_imp_img} />
                            </div>
                            <div class="col-8">  
                                <h2>{IdeaImplementer}</h2>
                                <h3>Under Implementation</h3>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                <div class="col-md-3">
                    <Link to="/idealead">
                    <div class="bspoc-box">
                        <div class="row align-items-center">
                            <div class="col-4 pr-0">
                                <img src={idea_img} />
                            </div>
                            <div class="col-8"> 
                                <h2>{idea_lead}</h2>
                                <h3>Idea Lead</h3> 
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                <div class="col-md-3">
                    <Link to="/myTeamIdea">
                    <div class="bspoc-box">
                        <div class="row align-items-center">
                            <div class="col-4 pr-0">
                                <img src={imp_img} />
                            </div>
                            <div class="col-8"> 
                                <h2>{RM}</h2>
                                <h3>My Idea Approval (RM)</h3> 
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                </div>
                </>
  );
}

export default IdeaApproval;
