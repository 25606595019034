import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import Sort from "../assets/images/sort.png"; 
import Sidemenu from '../include/sidemenu';
import Header from "../include/header";
import { Modal, Breadcrumb, Dropdown, Pagination, Badge, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { getAllOrganisation, getPolicy, submitinsurancematrix, uploadfile, getinsurancedetails, mediclaiminsurenceexportfile, deletemediclaim, getEssPolicy } from "../../service/mediclaim_brokerservice";
import { useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import EditInsurance from './editMediclaim-broker';
import * as moment from "moment";

function MediBroker() {

    const navigate = useNavigate();

    // const options = [
    //     { value: 'Option1', label: 'Option1' },
    //     { value: 'Option2', label: 'Option2' },
    //     { value: 'Option3', label: 'Option3' },
    //     { value: 'Option4', label: 'Option4' },
    //     { value: 'Option5', label: 'Option5' }
    // ]


    const [show, filtersetShow] = useState(false);
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);

    const [addnewshow, addnewsetShow] = useState(false);
    const addnewopen = () => addnewsetShow(true);
    const addnewclose = () => addnewsetShow(false);
    const [org, setOrg] = useState([])
    const [policyname, setPolicyname] = useState([])
    const [policyType, setpolicyType] = useState("")
    const [pol, setpol] = useState("")
    const [company, setcompany] = useState("")
    const [comp_name, setComp_name] = useState("")
    const [policy_num, setpolicy_num] = useState("")
    const [amount, setAmount] = useState(0)
    const [startdate, setStartDate] = useState("")
    const [enddate, setenddate] = useState("")
    const [brokername, setBrokerName] = useState("")
    const [insuranceCompany, setInsuranceCompany] = useState("");
    const [broker_email, setBroker_email] = useState("")
    const [brokercontact, setBrokerContact] = useState("")
    const [hremail, setHRemail] = useState("")
    const [remarks, setRemarks] = useState("")
    const [file, setfile] = useState({})
    const [fileurl, setfileurl] = useState("")
    const [filename, setfilename] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setpageCount] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [SearchStr, setSearchStr] = useState("")
    const [insurencedetails, setinsurancedetails] = useState([])
    const [editmode, setEditmode] = useState(false)
    const editclose = () => setEditmode(false)
    const [editdata, setEditData] = useState([])
    const editdataclose = () => setEditData([])
    const [validmail, setValidmail] = useState("")
    const [hrvalidmail, setHrValidmail] = useState("");
    const [sumInsured, setSumInsured] = useState("");
    const [empNumber, setEmpNumber] = useState(0);
    const [reminderDays, setReminderDays] = useState(0);


    useEffect(() => {
        getOrganisationList();
        getmediclaininsurancelist("", "", currentPage, pageSizeNo, "id", true);
    }, []);

    const getPolicyList = (org) => {
        getEssPolicy(`paginate=${false}&org=${org}`).then((response) => {
            if (response?.status === 200) {
                let obj = response?.data?.dataList?.result?.map((each) => {
                    return ({
                        label: each?.name,
                        value: each?.id
                    })
                })
                setPolicyname(obj)
            }
        })
    }

    useEffect(() => {
        if (company !== "") {
            getPolicyList(company)
        }
    }, [company])



    const handleKey = (
        search,
        status,
        pageNo,
        page_size,
        sort_by,
        paginate,


    ) => {
        let queryParm =
            "page_no=" +
            pageNo +
            "&page_size=" +
            page_size +
            "&sort_by=" +
            sort_by +
            "&paginate=" +
            paginate;
        if (status !== "" && status !== undefined && status !== null) {
            queryParm = queryParm + "&status=" + status;
        }
        if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
        }
        // if (user_id !== "" && user_id !== undefined && user_id !== null) {
        //     queryParm = queryParm + "&user_id=" + user_id;
        // }
        // if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
        //     queryParm = queryParm + "&sap_status=" + sap_status;
        // }

        return queryParm;
    };


    const exportHandleKey = (
        searchStr,

        sort_by,

    ) => {
        let queryParm = "";

        if (searchStr !== "" && searchStr !== undefined && searchStr !== null) {
            queryParm = queryParm + "&SearchStr=" + searchStr;
        }

        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
            queryParm = queryParm + "&sort_by=" + sort_by;
        }

        return queryParm;
    };


    const getOrganisationList = () => {
        getAllOrganisation(false).then((response) => {
            // console.log(">>>>getOrganisationList", response?.data?.dataList?.result)
            let organisation = [];
            let organisation2 = [];

            response?.data?.dataList?.result.map((x) => {
                let data = { value: x.id, label: x.name + " (" + x.code + ")" };
                organisation.push(data);
                // organisation2.push(data);
            });

            setOrg(organisation)
            //   let all = { value: "All", label: "All" };
            //   organisation.push(all);
            //   setOrg(organisation);
        });
    };


    const getpolicyType = () => {
        getPolicy().then((response) => {
            let policy_type = []
            // console.log(">>>>>>>>>>>>>>>>>>",response?.data?.dataList?.result)
            response?.data?.dataList?.result.map((x) => {
                let data = { value: x.id, label: x.name };
                policy_type.push(data)
            });

            setPolicyname(policy_type)
        });
    };



    const submitinsurance = () => {
        if (company != "" && policyType != "" && policy_num != "" && amount != 0 && startdate != "" && enddate != "" && brokername != "" && broker_email != ""
            && brokercontact != "" && hremail != "" && remarks != "" && file != {}) {
            let data = {
                "organisation_id": company,
                "policy_master_id": policyType,
                "policy_number": policy_num,
                "total_amount": amount,
                "policy_start_date": startdate,
                "policy_end_date": enddate,
                "broker_name": brokername,
                "broker_email": broker_email,
                "broker_contacts": brokercontact,
                "insurance_company": insuranceCompany,
                "emp_number": empNumber,
                "sum_insured": sumInsured,
                "reminder_days": reminderDays,
                "hr_mails": hremail,
                "remarks": remarks,
                "attachment": file
            }
            submitinsurancematrix(data)
                .then((response) => {
                    if (response.data.status == true && response.data.code == 200) {
                        // console.log("submitinsurancematrix", response)
                        setcompany("")
                        setComp_name("")
                        setpolicy_num("")
                        setAmount(0)
                        setStartDate("")
                        setenddate("")
                        setBrokerName("")
                        setHRemail("")
                        setRemarks("")
                        setInsuranceCompany("")
                        setfile({})
                        setfilename("")
                        setfileurl("")
                        setpol("")
                        setpolicyType("")
                        addnewsetShow(false)
                        setValidmail("")
                        setHrValidmail("")
                        getmediclaininsurancelist("", "", currentPage, pageSizeNo, "id", true);
                        toast.success(response.data.message);
                    }
                })
                .catch((error) => {
                    // setIsLoading(false);
                    toast.error(error.response.data.message);
                    console.log(
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        "failed"
                    );
                });
        } else {
            toast.error("Please fill all the fields");
        }

    };



    const getmediclaininsurancelist = (
        search,
        activePage,
        pageSizeNo,
        paginate,
        pageNo,
        page_size,
        sort_by,

    ) => {
        getinsurancedetails(
            handleKey(
                search,
                activePage,
                pageSizeNo,
                paginate,
                pageNo,
                page_size,
                sort_by,

            )
        ).then(
            (response) => {

                if (response?.status === 200) {
                    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", response)              
                    setinsurancedetails(response?.data?.dataList?.result)
                    setpageCount(response?.data?.dataList?.paginated_data?.totalPages);



                }
            }
        );

    };



    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);
        getmediclaininsurancelist("", "", activePage, pageSizeNo, "id", true);

    };


    const submit = (id, Policyname) => {
        confirmAlert({
            title: "Delete Employee",
            message: `Are you sure to delete ${Policyname} ?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deletePolicy(id),
                },
                {
                    label: "No",
                },
            ],
        });
    };


    const deletePolicy = (idx) => {
        deletemediclaim(idx)
            .then((res) => {
                if (res.data.status == true) {
                    toast.success(res.data.message);
                    getmediclaininsurancelist("", "", currentPage, pageSizeNo, "id", true);
                }
            })
            .catch((error) => {
                toast.error(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    const fileUploadHandler = (e) => {
        // console.log("GIleeea",e.target.files)

        // setfile(e.target.files)
        let file = e.target.files;
        // setfile(file);
        let files = [];
        let filename = [];
        let myfile = [];
        for (let i = 0; i < file.length; i++) {
            let data = e.target.files[i];
            //   console.log("Datat",data)
            let myUrl = URL.createObjectURL(e.target.files[i]);
            //   console.log("URs",myUrl)
            setfileurl(myUrl)
            myfile.push(myUrl);
            const fileData = new FormData();
            fileData.append("uploaded_file", data);
            uploadfile(fileData).then((response) => {
                // console.log("Fileresponse",response.data)
                if (response.data.status == true) {
                    setfilename(e.target.files[0].name)
                    setfile(response.data)
                }

            });
        }
    }

    const handleSearch = (e) => {
        // console.log("E value ",e.target.value)
        if (e.target.value.length > 2 || e.target.value.length === 0) {
            // console.log("eeee>>>>>",e.target.value)
            setSearchStr(e.target.value);
            getmediclaininsurancelist(e.target.value.toLowerCase(), "", currentPage, pageSizeNo, "id", true);


        }
    };

    const medclaimlistexport = (
        searchStr,
        sort_by,
    ) => {
        mediclaiminsurenceexportfile(
            exportHandleKey(
                searchStr,
                sort_by,
            )
        ).then(
            (response) => {
                // console.log("getottuserlistexporst", response)
                if (response?.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "mediclaim.xlsx"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }
            }).catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });


    }

    const editinsurance = (data) => {
        setEditmode(true);
        setEditData(data)

    }
    const Alldata = () => {
        getmediclaininsurancelist("", "", currentPage, pageSizeNo, "id", true)
    }

    const closeModal = () => {
        addnewclose()
        setcompany("")
        setComp_name("")
        setpolicy_num("")
        setAmount(0)
        setStartDate("")
        setenddate("")
        setBrokerName("")
        setHRemail("")
        setRemarks("")
        setfile({})
        setfilename("")
        setfileurl("")
        setpol("")
        setpolicyType("")
        addnewsetShow(false)
        setValidmail("")
        setHrValidmail("")
        getmediclaininsurancelist("", "", currentPage, pageSizeNo, "id", true);

    }
    const emailhandler = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (e.target.value.match(mailformat)) {
            setBroker_email(e.target.value)
            setValidmail("This is a valid email address")
            // console.log("This is a valid email address");
            return false;
        } else {
            setValidmail("This is not a valid email address")
            // console.log(" Not a Valid email")

        }

    }
    const hremailhandler = (e) => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (e.target.value.match(mailformat)) {
            setHRemail(e.target.value)
            setHrValidmail("This is a valid email address")
            // console.log("This is a valid email address");
            return false;
        } else {
            setHrValidmail("This is not a valid email address")
            // console.log(" Not a Valid email")

        }
    }

    return (
        <>


            <div class="row">
                <div class="col-md-12">
                    <div class="content-wrapper-inner content-wrapper-inner2">
                        <div class="innerheadsec">
                            <div className="row">
                                <div class="col-md-12">
                                    <h4 class="inner-page-title">Insurance Matrix Broker</h4>
                                    {/* <Breadcrumb>
                                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/">
                                            Transactions
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>Insurance Matrix Broker</Breadcrumb.Item>
                                    </Breadcrumb> */}
                                </div>
                                <div class="col-md-12">
                                    <div class="border-top mb-3"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="col-lg-4">
                                    <div class="input-group table-search">
                                        <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                        <input type="text" class="form-control" placeholder="Search..."
                                            onChange={(e) => handleSearch(e)}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="inline-spacing btn-betweenspaing">
                                        <Dropdown show>
                                            <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                <img src="images/export.png" alt="" class="mr-2"
                                                /> Export
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#">
                                                    <button
                                                        type="button"
                                                        style={{ border: 0, background: "none" }}
                                                        onClick={() =>
                                                            medclaimlistexport(SearchStr, "", "", "id")
                                                        }
                                                    >
                                                        <i className="far fa-file-excel"></i>Excel
                                                    </button>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <button class="btn btn-primary-inner" onClick={addnewopen}><img src="images/upload.png" alt="" class="mr-3" />Add Broker</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-striped table-bordered tablecured">
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Action</th>
                                        <th>Company Name</th>
                                        <th>Policy Type</th>
                                        <th>Policy Number</th>
                                        <th>Amount</th>
                                        <th>Sum Insured</th>
                                        <th>Number of Employees</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Reminder From</th>
                                        <th>Broker Name</th>
                                        <th>Broker Email-Id</th>
                                        <th>Broker Contacts</th>
                                        <th>Insurance Company</th>
                                        <th>HR Email-ID</th>
                                        <th>Attachment</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {insurencedetails.map((val, idx) => {

                                        return (
                                            <tr>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    <button class="btn btn-blanktd text-primary f-12"
                                                        onClick={() => {
                                                            editinsurance(val)
                                                        }}
                                                    ><i class="far fa-edit"></i></button>
                                                    <button class="btn btn-blanktd text-danger ml-2 f-12" onClick={() => {
                                                        submit(val?.id, val?.policy_master_name
                                                        );
                                                    }}><i class="far fa-trash-alt text-danger"></i></button>
                                                </td>
                                                <td>{val.organisation_name}</td>
                                                <td>{val.policy_master_name}</td>
                                                <td>{val.policy_number}</td>
                                                <td>{val.total_amount}</td>
                                                <td>{val.sumInsured}</td>
                                                <td>{val.empNumber}</td>
                                                <td>{val.policy_start_date}</td>
                                                <td>{val.policy_end_date}</td>
                                                <td>{val.reminderDays}</td>
                                                <td>{val.broker_name}</td>
                                                <td>{val.broker_email}</td>
                                                <td>{val.broker_contacts}</td>
                                                <td>{val?.insuranceCompany}</td>
                                                <td>{val.hr_mails}</td>
                                                <td><a href={val.attachment[0]?.attachment} target="_blank"><i class="fas fa-paperclip"></i> View Doc</a></td>
                                                <td>{val.remarks}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-sm-5">
                                <div class="sortinglist">
                                    Show
                                    <select class="form-control">
                                        <option>10</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-left"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <Modal show={addnewshow} onHide={closeModal} backdrop="static" keyboard={false} size="lg" className="modaldefaultclose maxwidth90 modaldefaultclosecenter">
                <Modal.Header closeButton>
                    <Modal.Title>Add Insurance Matrix Broker</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Comapny Name<span class="text-danger">*</span></label>
                                        <Select
                                            options={org}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => {
                                                setcompany(e.value)
                                                setComp_name(e)
                                            }}
                                            value={comp_name}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Policy Type<span class="text-danger">*</span></label>
                                        <Select
                                            options={policyname}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => {
                                                setpolicyType(e.value)
                                                setpol(e)
                                            }}
                                            value={pol}
                                        />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Policy Number<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Policy Number"

                                            onChange={(e) => {
                                                setpolicy_num(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Amount<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Amount"
                                            onChange={(e) => {
                                                setAmount(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Sum Insured<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Sum Insured"
                                            onChange={(e) => {
                                                setSumInsured(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Number of Employees<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Number of Employees"
                                            onChange={(e) => {
                                                setEmpNumber(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Start Date<span class="text-danger">*</span></label>
                                        <input type="date" class="form-control" placeholder="Enter Start Date"
                                            min={moment(new Date()).subtract(1, 'years').format("YYYY-MM-DD")}
                                            max={moment(new Date()).format("YYYY-MM-DD")}
                                            onChange={(e) => {
                                                setStartDate(e.target.value)
                                            }}

                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>End Date<span class="text-danger">*</span></label>
                                        <input type="date" class="form-control" placeholder="Enter End Date"
                                            min={moment(new Date()).format("YYYY-MM-DD")}
                                            max={moment(new Date()).add(5, 'years').format("YYYY-MM-DD")}
                                            onChange={(e) => {
                                                setenddate(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Reminder From (days)<span class="text-danger">*</span></label>
                                        <input type="number" class="form-control" placeholder="Enter Reminder Days"
                                            onChange={(e) => {
                                                setReminderDays(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Broker Name<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Broker Name"
                                            onChange={(e) => {
                                                setBrokerName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Insurance Company<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Broker Name"
                                            onChange={(e) => {
                                                setInsuranceCompany(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Broker Email-Id<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Broker Email-Id"
                                            onChange={(e) => {
                                                emailhandler(e)
                                            }}
                                        />
                                        <span>{validmail}</span>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Broker Contacts<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter Broker Contacts"
                                            onChange={(e) => {
                                                setBrokerContact(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>HR Email<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Enter HR Email"
                                            onChange={(e) => {
                                                hremailhandler(e)

                                            }}
                                        />
                                        <span>{hrvalidmail}</span>

                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup position-relative">
                                        <label>Attachment<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control bg-white" disabled
                                            placeholder={filename?.length > 0 ? filename : ("JPG,PNG,PDF Format Only")} />
                                        <div class="upload-btn-wrapper up-loposition">
                                            <button class="uploadBtn">Browse</button>
                                            <input type="file"
                                                accept="application/pdf,image/png,image/jpeg"
                                                onChange={(e) => fileUploadHandler(e)}
                                            />
                                        </div>
                                        <p>Selected files <span>{fileurl != "" ? (<a href={fileurl} target="_blank">{filename}</a>) : ""}</span></p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group innergroup">
                                        <label>Remarks<span class="text-danger">*</span></label>
                                        <textarea class="form-control" placeholder="Enter  Remarks"
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                            }}
                                        ></textarea>
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-outline-danger" onClick={addnewclose}>Cancel</button>
                    <button class="btn btn-primary-inner bpi-main" onClick={submitinsurance}>Submit</button>
                </Modal.Footer>

            </Modal>

            {editmode ? (
                <>
                    <EditInsurance
                        edit={editmode}
                        editdata={editdata}
                        org={org}
                        policyname={policyname}
                        insurencedetails={insurencedetails}
                        editclose={editclose}
                        editdataclose={editdataclose}
                        getdata={Alldata}
                        fileUploadHandler={fileUploadHandler}
                        filename={filename}
                        fileurl={fileurl}
                        file={file}
                    />
                </>
            ) : (
                ""
            )}






        </>
    );
}

export default MediBroker;