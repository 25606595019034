import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getGST = async (
  company_id,
  state_id,
  page_no,
  page_size,
  sort_by,
  paginate,
  type,
  status
) => {
  return axios.get(
    `${AUTH_BASE_URL}/gst_certificates?company_id=${company_id}&state_id=${state_id}&page_no=${page_no}&page_size=${page_size}&sort_by=${sort_by}&paginate=${paginate}&type=${type}&status=${status}`
  );
};

export const saveGST = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/gst_certificates`, data);
};
export const getCompanyOrg = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
};

export const updateGST = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/gst_certificates/${data.id}`, data);
};
export const deleteGST = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/gst_certificates/${id}`);
};
