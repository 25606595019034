import axios from "axios";
import store from "../store/index";
import * as ActionTypes from "../store/actions";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";

const instance = axios.create({
  baseURL: `${process.env.CUSTOMER_URL}`,
});

instance.interceptors.request.use(
  (request) => {
    const { myrights } = store.getState().auth;
    if (
      window.location.pathname != "/" &&
      myrights !== null &&
      myrights[window.location.pathname] === undefined &&
      window.location.pathname !== "/dashboard"
      // window.location.pathname !== '/befikradmin/customerjos'
    ) {
      store.dispatch({ type: ActionTypes.LOGOUT_USER });
    } else if (myrights === null) {
      request.headers.myrkey = 0;
    } else {
      request.headers.myrkey = myrights[window.location.pathname];
    }
    const { token } = store.getState().auth;
    if (token && typeof token === "string") {
      request.headers.Authorization = `Bearer ${token}`;
    } else if (token && token.data && token.data.accessToken) {
      request.headers.Authorization = `Bearer ${token.data.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      if (
        localStorage?.getItem("fromAd") === null ||
        localStorage?.getItem("fromAd") === undefined
      ) {
        store.dispatch({ type: ActionTypes.LOGOUT_USER });
      } else {
        localStorage?.removeItem("accesstoken");
        window.location.replace("/");
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
