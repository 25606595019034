import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";

function TravelDeviation() {
  const navigate = useNavigate();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [exc_travel, set_exc_travel] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [travelBills, setTravelBills] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [selectedEmployee, set_selectedEmployee] = useState({
    empObj: "",
  });

  const [conveyExcp, set_conveyExcp] = useState(0);
  const [hotelExcp, set_hotelExcp] = useState(0);

  const [filterdata, setfilterData] = useState({
    user_id: { value: undefined },
    trip_from: "",
    trip_to: "",
    from_date: "",
    to_date: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getTravelBills(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const getExportDetails = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date
  ) => {
    TravelListingAPI.get_travel_deviationExport(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date
      )+
      `&is_export=true`)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Travel_deviation_report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportTravelException = () =>
    // search, page_no, page_size, sort_by, paginate, status,
    //         user_id,
    //         trip_from,
    //         trip_to,
    //         from_date,
    //         to_date
    {
      TravelListingAPI
        .getExportTravel
        // handleKey(search, page_no, page_size, sort_by, paginate, status,
        //     user_id,
        //     trip_from,
        //     trip_to,
        //     from_date,
        //     to_date)
        ()
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exported_travel_excep.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    };

  const getExportLocalException = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    exporting = ""
  ) => {
    TravelListingAPI.getTravelConveyDeviat(
      
        handleKey(
            search,
            page_no,
            page_size,
            sort_by,
            paginate,
            status,
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
          )+(exporting
          ? `&is_export=true`:"")
    )
      .then((response) => {
        if (exporting) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exported_conveyance_excep.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          set_conveyExcp(
            response?.data?.dataList?.paginated_data?.totalRecords
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportLocalException2 = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    exporting = ""
  ) => {
    TravelListingAPI.getTravelConveyDeviatExport(
      
        handleKey(
            search,
            page_no,
            page_size,
            sort_by,
            paginate,
            status,
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
          )+(exporting
          ? `&is_export=true`:"")
    )
      .then((response) => {
        if (exporting) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exported_conveyance_excep.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          set_conveyExcp(
            response?.data?.dataList?.paginated_data?.totalRecords
          );
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportHotelException = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    exporting = ""
  ) => {
    TravelListingAPI.getTravelHotelDeviat(
      exporting
        ? `is_export=true`
        : handleKey(
            search,
            page_no,
            page_size,
            sort_by,
            paginate,
            status,
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
          )
    )
      .then((response) => {
        if (exporting) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exported_hotel_excep.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          set_hotelExcp(response?.data?.dataList?.paginated_data?.totalRecords);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportHotelException2 = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date,
    exporting = ""
  ) => {
    TravelListingAPI.getTravelHotelDeviatExport(
      
         handleKey(
            search,
            page_no,
            page_size,
            sort_by,
            paginate,
            status,
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
          )+`&is_export=true`
    )
      .then((response) => {
        if (exporting) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exported_hotel_excep.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
          set_hotelExcp(response?.data?.dataList?.paginated_data?.totalRecords);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const calcMapData = (map_data, dat_type) => {
    let calculated = 0;
    switch (dat_type) {
      case "Self_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Self") {
            calculated += sub_data?.taxable_amount;
          }
        });

        break;
      case "Company_ticket":
        map_data?.map((sub_data, idx) => {
          if (sub_data?.paid_by == "Company") {
            calculated += sub_data?.taxable_amount;
          }
        });
        break;
      case "hotel":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.tax_bill_amount;
        });
        break;
      case "conveyance":
        map_data?.map((sub_data, idx) => {
          calculated += sub_data?.amount;
        });
        break;
      default:
        console.log("invalid");
    }
    return calculated;
  };

  const expAlert = () => {
    alert();
  };

  useEffect(() => {
    getUsersList();
    // get_travel_deviation(
    //   searchStr,
    //   currentPage,
    //   pageSizeNo,
    //   sortData.sort_by,
    //   true,
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   ""
    // );

    // getExportLocalException(
    //   "",
    //   1,
    //   pageSizeNo,
    //   "",
    //   true,
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   ""
    // );

    // getExportHotelException(
    //   "",
    //   1,
    //   pageSizeNo,
    //   "",
    //   true,
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   "",
    //   ""
    // );
  }, []);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
      queryParm = queryParm + "&trip_from=" + trip_from;
    }
    if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
      queryParm = queryParm + "&trip_to=" + trip_to;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getTravelBills(
        e.target.value,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        "",
        ""
      );

      setSearchStr(e.target.value);
    }
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);

    get_travel_deviation(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      "",
      "",
      filterdata?.from_date,
      filterdata?.to_date
    );

    getExportLocalException(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      "",
      "",
      filterdata?.from_date,
      filterdata?.to_date,

      ""
    );

    getExportHotelException(
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id?.value,
      "",
      "",
      filterdata?.from_date,
      filterdata?.to_date,

      ""
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();

    get_travel_deviation(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      ""
    );

    getExportLocalException(
      "",
      1,
      pageSizeNo,
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    getExportHotelException(
      "",
      1,
      pageSizeNo,
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );

    setfilterData({
      user_id: { value: undefined },
      from_date: "",
      to_date: "",
      trip_from: "",
      trip_to: "",
    });
  };

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const getTravelBills = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelBills(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date
      )
    ).then((response) => {
      setTravelBills(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      dispatch(setDisplayLoader("Hide"));
    });
  };

  const get_travel_deviation = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    trip_from,
    trip_to,
    from_date,
    to_date
  ) => {
    TravelListingAPI.get_travel_deviation(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date
      )
    ).then((response) => {
      set_exc_travel(response?.data?.dataList?.paginated_data?.totalRecords);
      //   setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
    });
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li ><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li class="active"><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Deviation Report</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  {/* <div class="col-lg-4">
                                                <div class="input-group table-search">
                                                    <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                                    <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    placeholder="Search..." 
                                                    onChange={(e) => {
                                                        handleSearch(e);
                                                      }}
                                                    /> 
                                                </div>
                                            </div> */}
                  <div class="col-lg-12 float-right">
                    <div class="inline-spacing btn-betweenspaing">
                      {/* <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              // disabled={surveyList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  "",
                                  "",
                                  "",
                                  "",
                                  "",
                                  ""
                                )
                              }
                            >
                              <i class="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12">
                  Trip Start Date: {filterdata?.from_date? moment.parseZone(filterdata?.from_date).format(`DD-MM-YYYY`):""}
                      {` - `} Trip End Date: {filterdata?.to_date? moment.parseZone(filterdata?.to_date).format("DD-MM-YYYY"):""}

                     {/* {filterdata?.from_date ? `Trip Start Date: ${moment.parseZone(filterdata?.from_date).format(`DD-MM-YYYY`)}`:""}
                     {filterdata?.to_date ? ` `+` Trip End Date: ${moment.parseZone(filterdata?.to_date).format("DD-MM-YYYY")}`:""} */}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card">
                      <div
                        class="card-header text-white "
                        style={{
                          backgroundColor: "#4a9bd4",
                        }}
                      >
                        <h5>
                          Exception in Travel Intimation
                          <Link
                            class="text-white float-right"
                            onClick={()=>
                              getExportDetails(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                "",
                                filterdata?.user_id?.value,
                                "",
                                "",
                                filterdata?.from_date,
                                filterdata?.to_date
                              )}
                            to="#"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </Link>
                          {/* <a className="text-white" id=""><i class="fa fa-download" aria-hidden="true"></i></a> */}
                        </h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text text-center h3">
                          {exc_travel}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card">
                      <div
                        class="card-header text-white "
                        style={{
                          backgroundColor: "#4a9bd4",
                        }}
                      >
                        <h5>
                          Exception in Local conveyance
                          <Link
                            class="text-white float-right"
                            onClick={() =>
                              getExportLocalException2(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                "",
                                filterdata?.user_id?.value,
                                "",
                                "",
                                filterdata?.from_date,
                                filterdata?.to_date,
                                "y"
                              )
                            }
                            to="#"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </Link>
                          {/* <a class="floatr text-white download-btn" id="exporttideviationssc"><i class="fa fa-download" aria-hidden="true"></i></a> */}
                        </h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text text-center h3"> {conveyExcp}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card">
                      <div
                        class="card-header text-white "
                        style={{
                          backgroundColor: "#4a9bd4",
                        }}
                      >
                        <h5>
                          Exception in Hotel Expense
                          <Link
                            class="text-white float-right"
                            onClick={() =>

                              // getExportLocalException2(
                              //   searchStr,
                              //   1,
                              //   pageSizeNo,
                              //   sortData.sort_by,
                              //   true,
                              //   "",
                              //   filterdata?.user_id?.value,
                              //   "",
                              //   "",
                              //   filterdata?.from_date,
                              //   filterdata?.to_date,
                              //   "y"
                              // )
                              getExportHotelException2(
                                "",
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                "",
                                filterdata?.user_id?.value,
                                "",
                                "",
                                filterdata?.from_date,
                                filterdata?.to_date,
                                "y"
                              )

                            }
                            to="#"
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </Link>
                          {/* <a class="floatr text-white download-btn" id="exporttideviationssc"><i class="fa fa-download" aria-hidden="true"></i></a> */}
                        </h5>
                      </div>
                      <div class="card-body">
                        <p class="card-text text-center h3"> {hotelExcp}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  user_id: e,
                })
              }
              value={filterdata?.user_id}
            />
          </div>

          {/* <div class="form-group innergroup">
                        <label>Trip From <span class="text-danger"></span></label>
                        <input type="text" 
                        placeholder="Enter Start Location" class="form-control" 
                        onChange={e=>setfilterData({
                            ...filterdata,
                            trip_from:e.currentTarget.value
                        })}
                        value={filterdata?.trip_from}

                        />
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip To <span class="text-danger"></span></label>
                        <input type="text" 
                        placeholder="Enter End Location" class="form-control" 
                        onChange={e=>setfilterData({
                            ...filterdata,
                            trip_to:e.currentTarget.value
                        })}
                        value={filterdata?.trip_to}
                        />
                    </div> */}

          <div class="form-group innergroup">
            <label>
              Trip Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>

          {/* <div class="form-group innergroup">
                        <label>Search By <span class="text-danger"></span></label>
                        <select class="form-control newbgselect">
                            <option>Select</option>
                            <option>T</option>
                            <option>P</option>
                        </select>
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearfilter()}>
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TravelDeviation;
