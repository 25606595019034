 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"; 
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab  } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";


function TravelProvisReport() { 

    const navigate = useNavigate();
    
    const options = [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' }
    ]
    const dispatch = useDispatch();
    

    const [show, filtersetShow] = useState(false); 
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" }); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [searchStr, setSearchStr] = useState("");
    const [hotelDetails, setHotelDetails]  = useState([]);
    const [totalValue, setTotalValue] = useState(0.0);

     
    const [usersDropdown, setUsersDropdown] = useState([]);

    const [filterdata, setfilterData] = useState({
        user_id: {value:undefined},
        trip_from: "",
        trip_to: "",
        from_date: "",
        to_date: "",
        
      });

    const handleKey = (search, page_no, page_size, sort_by, paginate, status, 
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date) => {
        let queryParm =
          "page_no=" +
          page_no +
          "&page_size=" +
          page_size +
          "&paginate=" +
          paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
          queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
          queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
          queryParm = queryParm + "&status=" + status;
        }
    
    
        if (user_id !== "" && user_id !== undefined && user_id !== null) {
          queryParm = queryParm + "&user_id=" + user_id;
        }
        if (trip_from !== "" && trip_from !== undefined && trip_from !== null) {
          queryParm = queryParm + "&trip_from=" + trip_from;
        }
        if (trip_to !== "" && trip_to !== undefined && trip_to !== null) {
          queryParm = queryParm + "&trip_to=" + trip_to;
        }
        if (from_date !== "" && from_date !== undefined && from_date !== null) {
          queryParm = queryParm + "&from_date=" + from_date;
        }
        if (to_date !== "" && to_date !== undefined && to_date !== null) {
          queryParm = queryParm + "&to_date=" + to_date;
        }
        
    
        return queryParm;
      };

    const handlePageClick = (action) => {
        let activePage = action.selected + 1;
        setCurrentPage(activePage);
        getProvisExpenseReport(searchStr,
            activePage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","",""
            )
      };

      const mainDataFilter = () => {
        console.log(" filterdata:", filterdata);
        // getTravelList(searchStr, 1, pageSizeNo, sortData.sort_by, true,"","",filterdata?.user_id?.value,"",filterdata?.from_date,filterdata?.to_date,"","");
        getProvisExpenseReport(searchStr,
            1,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             filterdata?.user_id?.value,"","",filterdata?.from_date,filterdata?.to_date)

        filterclose();
      };
      
      
      const clearfilter = () => {
        filterclose();
        // getTravelList(searchStr, 1, pageSizeNo, sortData.sort_by, true);
        getProvisExpenseReport(searchStr,
            1,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","","")

        setfilterData({
            user_id: {value:undefined},
          from_date: "",
          to_date: "",
          trip_from:"",
          trip_to:"",
          search_by:""
    
        });
        
      };

      const getUsersList = () => {
        UserService.getUsersListDataNew(0).then((res) => {
          // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.id,
                label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
              };
            }),
          ];
          setUsersDropdown(data);
          
        });
      };

      const handleSearch = (e) => {
    
        if (e.target.value.length > 2 || e.target.value.length === 0) {
          
          
            getProvisExpenseReport(e.target.value,
                1,
                pageSizeNo,
                sortData.sort_by,
                true,
                "",
                 filterdata?.user_id?.value,"","",filterdata?.from_date,filterdata?.to_date)
    
        
        setSearchStr(e.target.value);
        }
      };
    
    
    useEffect(()=>{

        getUsersList()
        getProvisExpenseReport(searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "",
             "","","","","")
    },[pageSizeNo])

    const getProvisExpenseReport = (
        search, page_no, page_size, sort_by, paginate, status, 
            user_id,
            trip_from,
            trip_to,
            from_date,
            to_date
    ) =>{
        dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getProvisionReport(
            handleKey(search, page_no, page_size, sort_by, paginate, status, 
                user_id,
                trip_from,
                trip_to,
                from_date,
                to_date)
        ).then((response)=>{
            let value = 0;
            response?.data?.dataList?.result.map((obj) => {
                value += obj?.total_claim_amount
            });
            setTotalValue(value)
            setHotelDetails(response?.data?.dataList?.result)
          setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
          dispatch(setDisplayLoader("Hide"));

        })
    }

    const [shows, setShow] = useState(false);
    const [fileObj, setFileObj] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleFileView = (data) => {
      setFileObj(data);
      handleShow();
    };


    const getProvisExpenseReportExport = (
        search, page_no, page_size, sort_by, paginate, status, 
        user_id,
        trip_from,
        trip_to,
        from_date,
        to_date
        
    ) =>{
        dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getProvisionReportExport(
            `is_export=true`+//&sort_by=${sortData.sort_by}`+(filterdata?.user_id?.value?`&user_id=${filterdata?.user_id?.value}`:``)+`&from_date=${filterdata?.from_date}&to_date=${filterdata?.to_date}`
            `&search=`+search+
            `&sort_by=`+sort_by+
            (user_id?`&user_id=`+user_id:``)+
            `&from_date=`+from_date+
            `&to_date=`+to_date
        
        ).then((response)=>{

            dispatch(setDisplayLoader("Hide"));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Provision_Report.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();

        })
    }
    
    return (
        <>
            <div class="container-fluid">
                        <div class="row">
                                {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li class="active"><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

                            <div class="col-md-12">
                                <div class="content-wrapper-inner content-wrapper-inner2">
                                    <div class="innerheadsec">
                                        <div className="row">
                                            <div class="col-md-12">
                                                <h4 class="inner-page-title">Provision Report</h4>
                                            </div> 
                                            <div class="col-md-12">
                                                <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-lg-4">
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                    <Tooltip
                                                        className="bs-tooltip-end tooltip"
                                                        id="overlay-example"
                                                    >
                                                        <div class="tooltip-inner text-left">
                                                        Search on{" "}
                                                        <strong> Name,  Employee ID</strong>
                                                        </div>
                                                    </Tooltip>
                                                    }
                                                >
                                                    <div class="input-group table-search">
                                                        <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                                        <input type="text" class="form-control" placeholder="Search..." 
                                                        onChange={(e) => {
                                                            handleSearch(e);
                                                        }}
                                                        /> 
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="inline-spacing btn-betweenspaing">
                                                    <Dropdown show>
                                                        <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                            <img src="images/export.png" alt="" class="mr-2" /> Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#"
                                                            onClick={() =>
                                                                
                                                                // getProvisExpenseReportExport(searchStr,
                                                                //     1,pageSizeNo,sortData.sort_by,true,"","","","","",""
                                                                //     )
                                                                getProvisExpenseReportExport(
                                                                    searchStr,"",
                                                                    "",
                                                                    sortData.sort_by,
                                                                    false,
                                                                    "",
                                                                    filterdata?.user_id?.value,"","",filterdata?.from_date,filterdata?.to_date
                                                                )
                                                            }
                                                            ><i class="far fa-file-excel"></i>Excel</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered tablecured">  
                                            <thead> 
                                                <tr>
                                                <th>Sr. No.</th> 	<th>Employee ID</th> 	<th>Name</th> 	<th>Grade</th> 	<th>Vendor Code</th> 	<th>Reporting Head</th> 	<th>Location</th> 	<th>Zone</th> 	<th>Cost Center</th> 	<th>Business Area</th> 	<th>Profit Centre</th> 	<th>Trip Number</th> 	<th>Trip Start Date</th> 	<th>Trip End Date</th> 	<th>TI Applied Date</th> 	<th>TI Approve Date</th> 	<th>TI User Delay Day</th> 	<th>Boss Delay Days</th> 	<th>Expense Applied Date</th> 		<th>Document Received date</th> 	<th>Status Message</th> 	<th>Travel From</th> 	<th>Ticket Paid By Self Claim Amt</th> 	<th>Ticket Paid By Company Claim Amt</th> 	<th>Hotel Claim</th> 	<th>DA Claim</th> 	<th>Conveyance Claim</th> 	<th>Fuel Claim Amt</th> 	<th>Driver Wages Claim</th> 	<th>Driver DA Claim</th> 	<th>Toll / Puncturen Exp Claim</th>
                                                <th>Washing Charges</th>
                                                <th>Claim Amount</th> 
                                                </tr> 
                                            </thead>
                                            <tbody> 
                                            {hotelDetails?.length > 0 ?
                                                hotelDetails?.map((data, index)=>{
                                                    return(
                                                <tr style={data?.is_exception 
                                                    ?   {backgroundColor:"orange"}
                                                    :   data?.chat_status
                                                            ?{backgroundColor:"yellow"}
                                                            :   {}
                                                    }>
                                                    <td>{(pageSizeNo * (currentPage-1)) + index+1}.</td>
                                                    <td >
                                                    {
                                                            <Link
                                                            onClick={() =>
                                                                handleFileView(data.attachments)
                                                            }
                                                            >
                                                            <button class="btn btn-blanktd text-primary">
                                                                {data?.user_code}
                                                            </button>
                                                            </Link>
                                                        }
                                                        </td>
                                                    <td>{data?.username}</td>
                                                    <td>{data?.Grade}</td>
                                                    <td>{data?.user_vendor_code}</td>
                                                    <td>{data?.reporting_user_name}</td>
                                                    <td>{data?.Location}</td>
                                                    <td>{data?.zone}</td>
                                                    <td>{data?.cost_center_code}</td>
                                                    <td>{data["Business Area"]}</td>
                                                    <td>{data["Profit Centre"]}</td>
                                                    <td> 
                                                        
                                                    <Link
                                                        to={`/travel-view-list?${data.id}`}
                                                        target="_blank"
                                                    >
                                                        {data?.sap_trip_number ?? "-"}
                                                    </Link>
                                                    </td>
                                                    <td>{data["Trip Start Date"]&&moment.parseZone(data["Trip Start Date"]).format("DD-MM-YYYY")}</td>
                                                    <td>{data["Trip End Date"]&&moment.parseZone(data["Trip End Date"]).format("DD-MM-YYYY")}</td> 
                                                    <td>{data?.created_at&&moment.parseZone(data?.created_at).format("DD-MM-YYYY")}</td>
                                                    <td>{data?.approval_date&&moment.parseZone(data?.approval_date).format("DD-MM-YYYY")}</td>
                                                    <td>{data["TI User Delay Day"]}</td>
                                                    <td>{data["Boss Delay Days"]}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>{data?.current_status}</td>
                                                    <td>{data["Travel From"]}</td>
                                                    <td>{data["Ticket Paid By Self Claim Amt"]?.toFixed(2)}</td>
                                                    <td>{data["Ticket Paid By Company Claim Amt"]?.toFixed(2)}</td>
                                                    <td>{data["Hotel Claim"]?.toFixed(2)}</td>
                                                    <td>{data["DA Claim"]?.toFixed(2)}</td>
                                                    <td>{data["Conveyance Claim"]?.toFixed(2)}</td>
                                                    <td>{data["Fuel Claim Amt"]?.toFixed(2)}</td>
                                                    <td>{data["Driver Wages Claim"]?.toFixed(2)}</td>
                                                    <td>{data["Driver DA Claim"]?.toFixed(2)}</td>
                                                    <td>{data["Toll / Puncturen Exp Claim"]?.toFixed(2)}</td>
                                                    <td>{data["Washing Charges"]?.toFixed(2)}</td>
                                                    <td>{data["Claim Amt."]?.toFixed(2)}</td>


                                                </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={12}>No Records Found...</td>
                                            </tr>
                                            }
                                            <Modal
                      backdrop="static"
                      keyboard={false}
                      size="md"
                      className="modaldefaultclose modaldefaultclosecenter"
                      show={shows}
                      onHide={handleClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>View attachments</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div class="table-responsive mb-3">
                          <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                            <thead>
                              <tr>
                                <th>Expense Type</th>
                                <th>FILE NAME </th>
                                <th>VIEW</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fileObj.length > 0
                                ? fileObj?.map((adata, idx) => (
                                    <tr>
                                      <td>{adata?.expense_type}</td>
                                      <td>
                                        {adata?.attachment_path?.substring(
                                          adata?.attachment_path?.lastIndexOf(
                                            "/"
                                          ) + 1
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          target="_Blank"
                                          to={adata?.attachment_path}
                                        >
                                          View
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                : "No records found"}
                            </tbody>
                          </table>
                        </div>
                      </Modal.Body>
                      {/* <Modal.Footer>
                                                                                                    <Button variant="secondary" onClick={handleClose}>
                                                                                                        Close
                                                                                                    </Button>
                                                                                                    </Modal.Footer> */}
                    </Modal>
                                            </tbody>
                                        </table>

                                    </div> 

                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="sortinglist">
                                                Show
                                                <select 
                                                    class="form-control"
                                                    onChange={(e) => {
                                                        setPageSizeNo(e.target.value);
                                                        setCurrentPage(1);
                                                      }}
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={handlePageClick}
                                            disableInitialCallback={true}
                                            containerClassName={"pagination justify-content-left"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                        </div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
            </div>

    

            <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                        
                <div class="form-group innergroup">
                        <label>Employee ID  <span class="text-danger"></span></label>
                        <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) =>
                            setfilterData({
                                ...filterdata,
                                user_id: e,
                              }) 
                                  
                        }
                        value={filterdata?.user_id}
                        />
                        
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip Start Date  <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        from_date:e.currentTarget.value
                        })}
                        value={filterdata.from_date}/>
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip End Date <span class="text-danger"></span></label>
                        <input type="date" class="form-control" 
                        onChange={(e)=>setfilterData({
                        ...filterdata,
                        to_date:e.currentTarget.value
                        })}
                        value={filterdata.to_date}/>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <button 
                    class="btn btn-outline-danger"
                     onClick={() => clearfilter()}
                    //  onClick={filterclose}
                    >
                        Clear</button>
                    <button 
                    class="btn btn-primary-inner bpi-main"
                    onClick={() => mainDataFilter()}
                    >Apply</button>
                </Modal.Footer>
            </Modal>

            

        </>
    );
}

export default TravelProvisReport;
