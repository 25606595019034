import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSkillTab = async (queryData) => {
  return axios.get(`${AUTH_BASE_URL}/skill-tab-access-master?${queryData}`);
};

export const saveSkillTab = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-tab-access-master`, data);
};

export const updateSkillTab = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-tab-access-master/${id}`, data);
};

export const deleteSkillTab = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-tab-access-master/${id}`);
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-tab-access-master/exists`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/skill-tab-access-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getEmployee = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};
