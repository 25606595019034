import React, { useState, useEffect } from "react";
import Sort from "../../assets/images/sort.png";
import { ProgressBar, Tabs, Tab, Alert } from "react-bootstrap";
import Select from "react-select";
import * as ActivePoServices from "../../service/activePo";
import Logo from "../../assets/images/logo.png";
import * as HeaderService from "../../service/jv_service";
import * as otvServiceData from "../../service/otvServices";
import NonPoBulkImport from "./non-po-bulkImport";
import { useNavigate, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { selectFinalUrlPermission, setTracker, selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";

function NonPORequest() {
  let condition = "false";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [trackerData, setTrackerData] = useState({
    permission_id: 0,
    transaction_id: 0,
    attachment_id: 0,
  });
  const [vendorDataList, setVendorDataList] = useState([]);
  const [vendorValue, setVendorValue] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [glCodeList, setGlCodeList] = useState([]);
  const [glCodeValue, setGlCodeValue] = useState([]);
  const [companyCode, setCompanyCode] = useState("");
  const [costCenterValue, setcostCenterValue] = useState([]);
  const [costCenterDisable, setCostCenterDisable] = useState(false);
  const [importValue, setImportValue] = useState("");
  const [currentTab, setCurrentTab] = useState();
  const [expenseList, setExpenseList] = useState([]);
  const [expenseValue, setExpenseValue] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState({});
  const [attachment, setAttachment] = useState([]);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [businessPlaceValue, setBusinessPlaceValue] = useState([]);
  const [businessAreaValue, setBusinessAreaValue] = useState([]);
  const [businessAreaList, setBusinessAreaList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [plantValue, setPlantValue] = useState([]);
  const [businessPlaceList, setBusinessPlaceList] = useState([]);
  const [file, setfiles] = useState({});
  const [imgSize, setImgSize] = useState(false);
  const [editNonpoData, setEditNonpoData] = useState();
  const { state } = useLocation();
  const [approveList, setApproveList] = useState();

  const [vendorDataValue, setVendorDataValue] = useState({
    vendorName: "",
    VendorCode: "",
    VendorGst: "",
    VendorLocation: "",
  });
  const [nonOtvData, setNonOtvData] = useState({
    vendor_id: 0,
    invoice_number: "",
    invoice_date: "",
    gross_amount: 0,
    type: "NONPO",
    expense_category_id: 0,
    business_place_id: undefined,
    business_area_id: undefined,
    plant_id: undefined,
    organisation_id: undefined,
    gl_id: 0,
    cost_center_id: 0,
    service_desc: "",
    attachments: [],
    company_code:""
  });
  const [error, setError] = useState({
    vendor_id: "",
    invoice_number: "",
    invoice_date: "",
    gross_amount: "",
    expense_category_id: "",
    gl_id: "",
    service_desc: "",
    cost_center_id: "",
  });

  const getVendorId = (id) => {
    ActivePoServices.getVendorId(id)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: `${x?.name} (${x?.code})`,
            name: x?.name,
            location: x?.location_name,
            gst: x?.gst,
          };
          plant.push(data);
        });
        setVendorDataList(plant);
        getGlCodeList("NONPO");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGlCodeList = (type) => {
    otvServiceData
      .getGlCodeList(type)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.desc + ")",
          };
        });
        setGlCodeList(data);
        getCostCenter();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCostCenter = (id) => {
    HeaderService.getCostCenter(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
            org_id: res?.organisation_id
          };
        });
        setCostCenterList(data);
        getExpenseList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExpenseList = () => {
    otvServiceData
      .getExpenseList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.category_name,
          };
        });
        setExpenseList(data);
        getBusinessPlaceList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessPlaceList = (id) => {
    HeaderService.getBusinessPlaceList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.description + ")",
          };
        });
        setBusinessPlaceList(data);
        getBusinessAreaList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessAreaList = (id) => {
    HeaderService.getBusinessAreaList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        setBusinessAreaList(data);
        getPlantList();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPlantList = (id) => {
    HeaderService.getPlantList(0)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveOtvPayment = (data) => {
    if (condition == "false") {
      condition = "true";
      let datass = data;
      datass.attachments = Object.values(uploadDataIm);
      if (Object.keys(uploadDataIm).length !== 0) {
        setTrackerData({
          ...trackerData,
          permission_id: finalUrlValue?.A?.id
        })
        otvServiceData
          .saveOtvPayment(data)
          .then((response) => {
            if(response.data.message === "Invoice no. already exist.") {
              toast.error(response.data.message)
              condition = "false";
            }
            else{
            toast.success(response.data.message);
            setTimeout(() => {
              condition = "false";
              navigate("/otv-vendor-request");
            }, 300);
          }
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else {
        setErrorAttachment(true);
      }
    }
  };

  const updateOtvPayment = (id, data) => {
    let datass = data;
    datass.attachments = Object.values(uploadDataIm);
    if (Object.keys(uploadDataIm).length !== 0) {
      setTrackerData({
        ...trackerData,
        permission_id: finalUrlValue?.E?.id
      })
    otvServiceData
      .updateOtvPayment(id, data)
      .then((response) => {
        condition = "false";
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/otv-vendor-request");
        }, 300);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
    }
    else {
      setErrorAttachment(true);
    }
  };

  const handleChange = (event, name, regex, length) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "vendor_id":
        setNonOtvData((prev) => ({
          ...prev,
          vendor_id: event.value,
        }));
        break;
      case "invoice_number":
        if (event.target.value.length < length) {
          // if (!event.target.value.match(regex)) {
          setNonOtvData((prev) => ({
            ...prev,
            invoice_number: event.target.value,
          }));
          // }
        }
        break;
      case "invoice_date":
        setNonOtvData((prev) => ({
          ...prev,
          invoice_date: event.target.value,
        }));
        break;
      case "gross_amount":
        if (event.target.value.length < length) {
          setNonOtvData((prev) => ({
            ...prev,
            gross_amount: event.target.value,
          }));
        }
        break;
      case "gl_id":
        setNonOtvData((prev) => ({
          ...prev,
          gl_id: event.value,
        }));
        break;
      case "expense_category_id":
        setNonOtvData((prev) => ({
          ...prev,
          expense_category_id: event.value,
        }));
        break;
      case "cost_center_id":
        setNonOtvData((prev) => ({
          ...prev,
          cost_center_id: event.value,
          organisation_id: event.org_id
        }));

        break;
      case "service_desc":
        setNonOtvData((prev) => ({
          ...prev,
          service_desc: event.target.value,
        }));
        break;
      case "business_place_id":
        setNonOtvData((prev) => ({
          ...prev,
          business_place_id: event.value,
        }));
        break;
      case "business_area_id":
        setNonOtvData((prev) => ({
          ...prev,
          business_area_id: event.value,
        }));
        let string = event?.label?.split('(')[1];
        let code = string.substring(0, string.length-1)
        let company_code = ""
        if(code === "PIAG"){
          company_code = 'PIIL'
        }
        else if(code === "PIJA"){
          company_code = 'PIJA'
        }
        else if(code === "PKAG"){
          company_code = 'PIKM'
        }
        setNonOtvData((prev) => ({
          ...prev,
          company_code: company_code
        }));
        break;
      case "plant_id":
        setNonOtvData((prev) => ({
          ...prev,
          plant_id: event.value,
        }));
        break;
      default:
        break;
    }
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...error };
    if (!nonOtvData.vendor_id) {
      isError = true;
      formerr.vendor_id = "*Vendor Name is required  ";
      setError(formerr);
    }
    if (!nonOtvData.invoice_number) {
      isError = true;
      formerr.invoice_number = " *Invoice Number is required ";
      setError(formerr);
    }
    if (!nonOtvData.invoice_date) {
      isError = true;
      formerr.invoice_date = " *Invoice Date is required ";
      setError(formerr);
    }
    if (!nonOtvData.service_desc) {
      isError = true;
      formerr.service_desc = " *Description is required ";
      setError(formerr);
    }
    if (!nonOtvData.gross_amount) {
      isError = true;
      formerr.gross_amount = " *Base Amount is required ";
      setError(formerr);
    }
    if (!nonOtvData.expense_category_id) {
      isError = true;
      formerr.expense_category_id = "*Expenses Category is required ";
      setError(formerr);
    }
    if (!nonOtvData.gl_id) {
      isError = true;
      formerr.gl_id = "*Gl Code is required ";
      setError(formerr);
    }
    if (!nonOtvData.cost_center_id && costCenterDisable == false) {
      isError = true;
      formerr.cost_center_id = "*Cost Center is required ";
      setError(formerr);
    }

    if (
      !nonOtvData.business_area_id && costCenterDisable === true
      // (glCodeValue?.code === "772006" ||
      //   glCodeValue?.code === "771006" ||
      //   glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.business_area_id = "*Business Area is required ";
      setError(formerr);
    }
    if (
      !nonOtvData.business_place_id && costCenterDisable === true
      // (glCodeValue?.code === "772006" ||
      //   glCodeValue?.code === "771006" ||
      //   glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.business_place_id = "*Business Place is required ";
      setError(formerr);
    }
    if (
      !nonOtvData.plant_id && costCenterDisable === true
      // (glCodeValue?.code === "772006" ||
      //   glCodeValue?.code === "771006" ||
      //   glCodeValue?.code === "771004")
    ) {
      isError = true;
      formerr.plant_id = "*Plant is required ";
      setError(formerr);
    }
    return isError;
  };

  const onProfileChange = async (e, index) => {
    let file = e.target.files;
    setfiles((prev) => ({ ...prev, [index]: e.target.files[0] }));
    let files1 = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await HeaderService.uploadImages(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files1.push(response.data);
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    }
    setAttachment([]);
    if (index === 0) {
      setErrorAttachment(false);
    }
    uploadDataIm[index] = files1[0];
  };

  const pushDataVendor = (newValue, name) => {
    setVendorDataValue({
      ...vendorDataValue,
      vendorName: newValue?.name,
      VendorCode: newValue?.label,
      VendorGst: newValue?.gst === null ? "" : newValue?.gst,
      VendorLocation: newValue?.location === null ? "" : newValue?.location,
    });
    setVendorValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataGlCode = (newValue, name) => {
    setGlCodeValue(newValue);
    handleChange(newValue, name);
    let gl_code = newValue?.label?.split('(')[0]
    // if(['772006', '771006', '771004'].includes(gl_code)){
    //   setCostCenterDisable(true)
    // }
    // else{
    //   setCostCenterDisable(false)
    // }
    checkGLValidation(gl_code)
  };

  const checkGLValidation = (gl_code) =>{
      otvServiceData.checkValidationByGL(gl_code).then((response)=>{
        let obj = response?.data?.dataList?.result
        if(obj.length > 0){
          if(obj[0]?.is_exception === true){
            setcostCenterValue([])
            setNonOtvData((prev) => ({
              ...prev,
              cost_center_id: 0,
            }));
            setCostCenterDisable(true)
          }
          else{
            setCostCenterDisable(false)
          }
        }
      })
  }

  const pushDataCost = (newValue, name) => {
    setcostCenterValue(newValue);
    handleChange(newValue, name);
  };

  const onPushExpense = (newValue, name) => {
    setExpenseValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataBusinessPlace = (newValue, name) => {
    setBusinessPlaceValue(newValue);
    handleChange(newValue, name);
  };

  const pushDataBusinessArea = (newValue, name) => {
    setBusinessAreaValue(newValue);
    handleChange(newValue, name);
    let string = newValue?.label?.split('(')[1];
    let code = string.substring(0, string.length-1)
    let company_code = ""
        if(code === "PIAG"){
          company_code = 'PIIL'
        }
        else if(code === "PIJA"){
          company_code = 'PIJA'
        }
        else if(code === "PKAG"){
          company_code = 'PIKM'
        }
    setCompanyCode(company_code)
  };

  const pushDataPlant = (newValue, name) => {
    setPlantValue(newValue);
    handleChange(newValue, name);
  };

  const handleSubmit = () => {
    if (state?.flag === undefined) {
      if (Object.keys(uploadDataIm).length !== 0) {
        setErrorAttachment(false);
      } else {
        setErrorAttachment(true);
      }
    } else {
      if (attachment.length === 0) {
        setErrorAttachment(true);
      } else {
        setErrorAttachment(false);
      }
    }
    if (handleValidate()) return false;
    if (nonOtvData.id === undefined) {
      saveOtvPayment(nonOtvData);
    } else {
      updateOtvPayment(nonOtvData.id, nonOtvData);
    }
  };

  const attachments123 = (index) => {
    let data = [];
    let data2 = file[index] != undefined ? file[index] : "";
    data =
      file[index] != undefined ? (
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="pic"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={(e) => {
                deleteAttachment(index);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      ) : (
        ""
      );
    return data;
  };

  const deleteAttachment = (id) => {
    let data = { ...file };
    Object.entries(data).map((key, value) => {
      let val = key[0];
      if (val == id) {
        delete data[val];
      }
    });
    setfiles(data);
  };

  useEffect(() => {
    getVendorId();
  }, []);

  useEffect(() => {
    setEditNonpoData(state?.from);
  }, [state]);

  useEffect(() => {
    if (editNonpoData) {
      setErrorAttachment(false);
      let data = editNonpoData;
      setVendorDataValue({
        ...vendorDataValue,
        VendorCode: editNonpoData.vendor_code,
        vendorName: editNonpoData.vendor_name,
        VendorGst: editNonpoData.vendor_gst,
        VendorLocation: editNonpoData.vendor_location,
      });
      let expenseItem = [];
      let exData = {
        value: data.expense_category_id,
        label: data.expense_name,
      };
      expenseItem.push(exData);
      let glItem = [];
      let glData = {
        value: data.gl_id,
        label: data.gl_code,
      };
      glItem.push(glData);
      let businessItem = [];
      let businessData = {
        value: data.business_area_id,
        label: data.business_area_code,
      };
      businessItem.push(businessData);

      let businessPlaceItem = [];
      let businessPlaceData = {
        value: data.business_place_id,
        label: data.business_place_code,
      };
      businessPlaceItem.push(businessPlaceData);

      let plantItem = [];
      let plantData = {
        value: data.plant_id,
        label: data.plant_code,
      };
      plantItem.push(plantData);

      let costItem = [];
      let costData = {
        value: data.cost_center_id,
        label: data.cost_center_name,
      };
      costItem.push(costData);

      let vendorItem = [];
      let vendorData = {
        value: data.vendor_id,
        label: data.vendor_name,
      };
      vendorItem.push(vendorData);
      setVendorValue(vendorItem);
      setcostCenterValue(costItem);
      setPlantValue(plantItem);
      setBusinessPlaceValue(businessPlaceItem);
      setBusinessAreaValue(businessItem);
      setGlCodeValue(glItem);
      setExpenseValue(expenseItem);
      setAttachment(data?.attachments);
      if(data?.gl_is_exception === true){
        setCostCenterDisable(true)
      }

      Object.keys(editNonpoData).forEach((item) => {
        nonOtvData[item] = editNonpoData[item];
      });

      let jsonData = {};
      editNonpoData?.approver_listing?.map((obj, i) => {
        jsonData[obj.approver_position] = obj;
      });
      setApproveList(jsonData);
    }
  }, [editNonpoData]);

  const blockInvalidChar = (e) =>
    [
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "+",
      "|",
      ":",
      "<",
      ">",
      "!",
    ].includes(e.key) && e.preventDefault();

  useEffect(() => {
    if (currentTab === "Domestic")
      setImportValue("Non PO Vendor Payment Request");
  }, [currentTab]);

  useEffect(()=>{
    dispatch(setTracker(trackerData))
  },[trackerData])

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    Non PO Vendor Payment Request
                  </h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      Non PO Vendor Payment Request
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs
                defaultActiveKey="onduty"
                id="approvaltabs"
                onSelect={(e) => setCurrentTab(e)}
              >
                <Tab
                  eventKey="onduty"
                  title={
                    <React.Fragment>
                      Non PO Vendor Payment Request
                    </React.Fragment>
                  }
                >
                  <div class="px-4 pb-2">
                    <div class="defaultchatgptquest text-right">
                      <a
                        class="badge badge-secondary"
                        href="\Policy_Non_PO_Transactions.pdf"
                        target="_blank"
                      >
                        <i class="far fa-file-pdf"></i> Non PO Policy
                      </a>
                      <a
                        class="badge badge-secondary"
                        href="\User Manual.pdf"
                        target="_blank"
                      >
                        <i class="far fa-file-pdf"></i> User Manual
                      </a>
                    </div>

                    {userData?.reporting_to_id === null || userData?.functional_head_id === null ?
                    <Alert variant="danger" className="mb-5 mt-2 text-center">
                      <i className="fa fa-exclamation-triangle"></i> 
                      Contact Your BHR as {userData?.reporting_to_id === null ? "Reporting Manager" : "Functional Head"} is not Mapped
                    </Alert>
                    :
                    ""
                    }

                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Vendor Name<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={vendorDataList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="vendor_id"
                              onInputChange={(e) => {
                                getVendorId(e);
                              }}
                              onChange={(newValue, action) => {
                                pushDataVendor(newValue, action?.name);
                              }}
                              value={vendorValue}
                            />
                            <div className="small text-danger">
                              {error.vendor_id}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Vendor Code</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={vendorDataValue.VendorCode}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Vendor Name</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={vendorDataValue.vendorName}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>GST</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={vendorDataValue.VendorGst}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Location</label>
                            <input
                              type="text"
                              disabled
                              class="form-control"
                              placeholder="Please search Vendor name"
                              value={vendorDataValue.VendorLocation}
                            />
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice No<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Invoice No"
                              name="invoice_number"
                              onKeyDown={blockInvalidChar}
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "17");
                              }}
                              value={nonOtvData.invoice_number}
                            />
                            <div className="small text-danger">
                              {error.invoice_number}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Invoice Date<span class="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              class="form-control"
                              placeholder="Enter Invoice Date"
                              name="invoice_date"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "11");
                              }}
                              value={nonOtvData.invoice_date}
                              max={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <div className="small text-danger">
                              {error.invoice_date}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Base Amount (Excluding Taxes){" "}
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Enter Base Amount"
                              name="gross_amount"
                              step="0.001"
                              min="0.001"
                              max="10"
                              precision={3}
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "11");
                              }}
                              value={nonOtvData.gross_amount}
                            />
                            <div className="small text-danger">
                              {error.gross_amount}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Expense Category
                              <span class="text-danger">*</span>
                            </label>
                            <Select
                              options={expenseList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="expense_category_id"
                              onChange={(newValue, action) =>
                                onPushExpense(newValue, action?.name)
                              }
                              value={expenseValue}
                            />
                            <div className="small text-danger">
                              {error?.expense_category_id}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>Description of Service</label>
                            <span class="text-danger">*</span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Description of Service/Material"
                              name="service_desc"
                              onChange={(newValue, action) => {
                                handleChange(newValue, action?.name, "", "");
                                setError({ ...error, service_desc: "" });
                              }}
                              value={nonOtvData.service_desc}
                            />
                            <div className="small text-danger">
                              {error?.service_desc}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              GL Code<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={glCodeList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="gl_id"
                              onChange={(newValue, action) => {
                                pushDataGlCode(newValue, action?.name);
                              }}
                              value={glCodeValue}
                            />
                            <div className="small text-danger">
                              {error?.gl_id}
                            </div>
                          </div>
                        </div>
                        {
                        // glCodeValue?.code === "772006" ||
                          // glCodeValue?.code === "771006" ||
                          // glCodeValue?.code === "771004"
                          ( costCenterDisable && (
                            <>
                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    BUSINESS PLACE{" "}
                                    <span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={businessPlaceList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="business_place_id"
                                    onChange={(newValue, action) => {
                                      pushDataBusinessPlace(
                                        newValue,
                                        action?.name
                                      );
                                      setError({
                                        ...error,
                                        business_place_id: "",
                                      });
                                    }}
                                    value={businessPlaceValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.business_place_id}
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    BUSINESS AREA{" "}
                                    <span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={businessAreaList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="business_area_id"
                                    onChange={(newValue, action) => {
                                      pushDataBusinessArea(
                                        newValue,
                                        action?.name
                                      );
                                      setError({
                                        ...error,
                                        business_area_id: "",
                                      });
                                    }}
                                    value={businessAreaValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.business_area_id}
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>COMPANY CODE</label>
                                  <input
                                    type="text"
                                    disabled
                                    class="form-control"
                                    placeholder="Please search COMPANY CODE"
                                    value={companyCode}
                                  />
                                </div>
                              </div>

                              <div class="col-md-4">
                                <div class="form-group innergroup">
                                  <label>
                                    Plant<span class="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={plantList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    name="plant_id"
                                    onChange={(newValue, action) => {
                                      pushDataPlant(newValue, action?.name);
                                      setError({ ...error, plant_id: "" });
                                    }}
                                    value={plantValue}
                                  />
                                  <div className="small text-danger">
                                    {error?.plant_id}
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Cost Center<span class="text-danger">*</span>
                            </label>
                            <Select
                              isDisabled={costCenterDisable}
                              options={costCenterList}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="cost_center_id"
                              onChange={(newValue, action) => {
                                pushDataCost(newValue, action?.name);
                              }}
                              value={costCenterValue}
                            />
                            <div className="small text-danger">
                              {error?.cost_center_id}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 class="text-theme border-bottom pb-2 mb-3">
                      <strong>Attachments</strong>
                    </h5>

                    <div class=" ">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group innergroup position-relative modaldefaultclose ">
                            <label>
                              Invoice Attachment
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                            />
                            {errorAttachment ? (
                              <div className="small text-danger">
                                *Attachment is required
                              </div>
                            ) : null}
                            <div class="upload-btn-wrapper up-loposition">
                              <button class="uploadBtn">Browse</button>
                              <input
                                type="file"
                                id="attachmentsFile"
                                accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                                onChange={(e) => onProfileChange(e, 0)}
                              />
                            </div>
                            {imgSize ? (
                              <div className="small text-danger">
                                *File size should not exceed 1mb
                              </div>
                            ) : null}
                            <p>
                              Selected files
                              {[file].map((a) => (
                                <span>
                                  <br />
                                  {a.name}
                                </span>
                              ))}
                            </p>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123(0)}</div>
                            ) : attachment[0] ? (
                              <>
                                {attachment !== [] && (
                                  <div class="uploaded-image mr-1">
                                    <a
                                      href={attachment[0].attachment_path}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {attachment &&
                                      attachment[0]?.attachment_path.includes(
                                        ".pdf"
                                      ) ? (
                                        <img
                                          src={"images/policy-icon/pdf.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".xlsx"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/xlsx.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".xls"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/xlsx.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".doc"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/docs.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".docs"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/docs.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".pptx"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/ppt.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : attachment &&
                                        attachment[0]?.attachment_path.includes(
                                          ".mp4"
                                        ) ? (
                                        <img
                                          src={"images/policy-icon/video.png"}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      ) : (
                                        <img
                                          src={attachment[0]?.attachment_path}
                                          alt="pic"
                                          width="70px"
                                          height="70px"
                                        />
                                      )}
                                    </a>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        deleteAttachment(0);
                                      }}
                                      className="btn btn-blanktd text-danger ml-2"
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                  </div>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group innergroup position-relative modaldefaultclose ">
                            <label>Other Attachment</label>
                            <input
                              type="text"
                              class="form-control bg-white"
                              disabled
                              placeholder="(PDF, PNG, JPEG, JPG, xlsx Format only)"
                            />
                            <div class="upload-btn-wrapper up-loposition">
                              <button class="uploadBtn">Browse</button>
                              <input
                                type="file"
                                onChange={(e) => onProfileChange(e, 1)}
                              />
                            </div>
                            <p>
                              Selected files
                              {[file].map((a) => (
                                <span>
                                  <br />
                                  {a.name}
                                </span>
                              ))}
                            </p>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123(1)}</div>
                            ) : attachment[1] ? (
                              <>
                                <div class="uploaded-image mr-1">
                                  <a
                                    href={attachment[1].attachment_path}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {attachment &&
                                    attachment[1].attachment_path.includes(
                                      ".pdf"
                                    ) ? (
                                      <img
                                        src={"images/policy-icon/pdf.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".xlsx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".xls"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/xlsx.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".doc"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".docs"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/docs.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".pptx"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/ppt.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : attachment &&
                                      attachment[1].attachment_path.includes(
                                        ".mp4"
                                      ) ? (
                                      <img
                                        src={"images/policy-icon/video.png"}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    ) : (
                                      <img
                                        src={attachment[1]?.attachment_path}
                                        alt="pic"
                                        width="70px"
                                        height="70px"
                                      />
                                    )}
                                  </a>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      deleteAttachment(1);
                                    }}
                                    className="btn btn-blanktd text-danger ml-2"
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="border-top my-3"></div>
                        </div>
                        <div class="col-md-12 mt-3 text-center">
                          <button
                            class="btn btn-outline-secondary mr-2"
                            onClick={() => navigate("/otv-vendor-request")}
                          >
                            Cancel
                          </button>
                          {userData?.reporting_to_id !== null && userData?.functional_head_id !== null ?
                          <button
                          class="btn btn-primary-inner bpi-main"
                          type="submit"
                          onClick={() => handleSubmit()}
                        >
                          Submit
                        </button>
                          :
                          <>
                          <span class="text-danger">* Contact Your BHR as {userData?.reporting_to_id === null ? "Reporting Manager" : "Functional Head"} is not Mapped</span>
                          </>
                          }
                          
                        </div>
                      </div>
                    </div>

                    <div class="travel-note">
                      <h4>Notes :-</h4>
                      <ul>
                        <li>
                          Payment to vendor which is registered in PIIL SAP
                          records but PO is not created can be made in this
                          category.
                        </li>
                        <li>
                          As per PO Policy, for all vendor payments, PO must be
                          created & only exceptions should be routed through
                          this category.
                        </li>
                        <li>
                          In case of repeat payment to vendor which has been
                          paid earlier through OTV in same Financial Year, then
                          create vendor code with the help of Vendor Master team
                          & submit request under this category.
                        </li>
                        <li>
                          Make sure to select correct vendor from "Search" icon
                          & in case of ambiguity contact SSC Vendor Master team.
                        </li>
                        <li>
                          Attach vendor invoice or payment request letter. In
                          case of GST invoice, make sure Address & GSTN of PIIL
                          is correctly mentioned on invoice.
                        </li>
                        <li>
                          Attached cancelled cheque or bank mandate form of
                          vendor.
                        </li>
                        <li>
                          Allowed formats are pdf,png,jpeg,jpg,xlsx with size
                          upto 4MB.
                        </li>
                        <li>Allowed max 2 no of attachments.</li>
                      </ul>
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="Domestic"
                  title={<React.Fragment>Bulk Upload</React.Fragment>}
                >
                  <NonPoBulkImport importValue={importValue} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NonPORequest;
