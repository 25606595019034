import { toast } from "react-toastify";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import * as OrganisationService from "../../service/organisation"
import * as otherExpService from "../../service/otherExpService";

import { useDispatch, useSelector } from "react-redux";

import {
    selectFinalUrlPermission,
    setDisplayLoader,
    selectUserData,
} from "../redux/piDataStore";


function AddOtherExpEntryException() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const finalUser = useSelector(selectUserData);
    const [userGroup,setuserGroup] = useState({
        _userGroup:""
    })




    const [show, filtersetShow] = useState(false);
    const [otherExpData, setotherExpData] = useState([]);

    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
    const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSizeNo, setPageSizeNo] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [totalRecord, setTotalRecord] = useState();
    const [searchStr, setSearchStr] = useState("");
    const [travelList, setTravelList] = useState([]);
    const [usersDropdown, setUsersDropdown] = useState([]);
    const [orgDropDown, setorgDropDown] = useState([]);
    const [postUserData,setpostUserData] = useState({})
    const [organisation_User, setOrganisation_User] = useState({
        company: "",
        user_id: ""
    });

    // const [filterdata, setfilterData] = useState({
    //     user_id: { value: 0 },
    //     id: "",
    //     organisation:{value: ""},
    //     from_date: "",
    //     to_date: "",
    //   });
    

    const addExceptionMaster = (
        status,
        is_picked,
        pageNo,
        pageSize,
        sort_by,
        paginate,
        emp_code,
        org,
        reqid,
        search
    ) => {

        let keys={"user_id":"User ID","from_date":"Bill start date","to_date":"Bill end date","opened_from":"Allowed from date","opened_to":"Allowed to date",}
        for (let [kee,vall] of Object.entries(keys)) {
            console.log(`..`,kee);
            try{

                if(kee in postUserData){

                }
                else{
                   toast.error(`${vall} can not be empty`); 
                   return 
                }
                
            }catch(e){
                
                toast.error("An error in key ",kee)
                return
            }
        }

        if(
            !moment(moment(postUserData.opened_to)).isSameOrAfter(moment(postUserData?.opened_from))
        
        ){
            toast.error("Allowed to date cant be less than Allowed from date",{
                autoClose:5000
            })

            return 
            
        }
        // console.log(`upload..`);
        // return
        dispatch(setDisplayLoader("Display"));

        

        // return

        otherExpService.addExceptionMaster(
            postUserData

        )
            .then((ress) => {
                // console.log(`otherexp...`,response);
                dispatch(setDisplayLoader("Hide"));
                // let data = response?.data?.result;
                // setVehicleData(response?.data?.dataList?.result);
                // let ress=null
                // console.log(`-->`,userGroup);
                // switch (userGroup._userGroup){
                //     case "ssc user":
                //         ress=response?.data?.dataList?.result.filter((data)=>{
                //             return !data.ssc_user_status
                //         })
                //         break
                //     case "ssc manager":
                //         ress=response?.data?.dataList?.result.filter((data)=>{
                //             return data.ssc_user_status==="A" && !data.ssc_manager_status
                //         })
                //         break
                //     default:
                //         break

                // }
                postUserData["user_id"]=0
                postUserData["opened_from"]=""
                postUserData["opened_to"]=""
                postUserData["from_date"]=""
                postUserData["to_date"]=""

                console.log(`log..`,ress);
                toast.success("Added successfully")

                navigate("/otherexception-list")
                // setotherExpData(ress?.data?.dataList?.result);
                // setCurrentPage(ress?.data?.dataList?.paginated_data?.currentPage)                
                // setPageCount(ress?.data?.dataList?.paginated_data?.totalPages);
            })
            .catch((error) => {
                dispatch(setDisplayLoader("Hide"));

                toast.error(error.response.data);
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    const typeUserEntry=async(_key="",_val="")=>{

        
        
        postUserData[_key]=_val
        postUserData["check_type"]="other"
        postUserData["is_active"]=true
        
        // console.log(`postype`,postUserData);
        if(
            (_key=="opened_to" && postUserData?.opened_from  &&   !moment(moment(_val)).isSameOrAfter(moment(postUserData?.opened_from)))||
        (_key=="opened_from" && postUserData?.opened_to && !moment(moment(postUserData?.opened_to)).isSameOrAfter(moment(_val)))
        ){
            toast.error("Allowed to date cant be less than Allowed from date",{
                autoClose:5000
            })
            
        }
        

    }


 

    useEffect(() => {

        let localGroup=""

        finalUser.groups.every(
            (data) =>
              {
                if(data?.name.toLowerCase() === "ssc user" || data?.name.toLowerCase() === "ssc manager"){
                    localGroup=data?.name.toLowerCase()
                    console.log(`-->`,localGroup);
                    userGroup._userGroup=localGroup
                    return false
                }
                return true
              }
          )
         



        getUsersList()
        // getAllOrganisation()
        // getBusinessUnits()
        
        
    }, [])




    const getUsersList = () => {
        UserService.getUserList("paginate=false").then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            

            let data = [];
            res?.data?.dataList?.result.map((x) => {

            if(x?.is_active){

                let empData = {
                value: x.id,
                label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
                record: x?.username,
                };
                data.push(empData);
            }

            });
        
            setUsersDropdown(data);





        });
    };

    const getAllOrganisation = () => {
        OrganisationService.getAllOrganisation(false).then((res) => {
            // setApproverList(res?.data?.dataList?.result);
            const data = [
                { value: null, label: "--select--" },
                ...res?.data?.dataList?.result?.map((x) => {
                    return {
                        value: x.code,
                        label: `${x?.name} ( ${x.code} )`,
                    };
                }),
            ];
            setorgDropDown(data);





        });
    };


    const otherExpExport = async (
        search,
        from_date,
        to_date,
        sort_by
        
    )=>{

        otherExpService.otherExpExport(
            'search='+search
            +'&from_date='+from_date
            +'&to_date='+to_date
            +'&sort_by='+sort_by
        ).then((resp)=>{

            const url = window.URL.createObjectURL(new Blob([resp.data.dataList.result]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "OtherExpenseExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            
            toast.success(`export success`)
            console.log(`export`,resp);
            
        }).catch((err)=>{
            console.log(err);
            toast.error(`Unable to export`)
        })
        

    }

    const getBusinessUnits = () => {
        otherExpService.getBusinessUnits().then((res)=>{  
        // setApproverList(res?.data?.dataList?.result);
          const data = [
            { value: null, label: "--select--" },
            ...res?.data?.dataList?.result?.map((x) => {
              return {
                value: x.code,
                label: `${x?.name}( ${x.code} )`,
              };
            }),
          ];
          setorgDropDown(data);
        });
      };

    const getChcekExpenseList = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        TravelListingAPI.getCheckExpenseList(
            handleKey(
                search,
                page_no,
                page_size,
                sort_by,
                paginate,
                status,
            )
        ).then((response) => {
            setTravelList(response?.data?.dataList?.result);
            setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
            setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        })
    }

    const handleKey = (
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
    ) => {
        let queryParm =
            "page_no=" +
            page_no +
            "&page_size=" +
            page_size +
            "&paginate=" +
            paginate;
        if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
            queryParm = queryParm + "&sort_by=" + sort_by;
        }
        if (search !== "" && search !== undefined && search !== null) {
            queryParm = queryParm + "&search=" + search;
        }
        if (status !== "" && status !== undefined && status !== null) {
            queryParm = queryParm + "&status=" + status;
        }
        return queryParm;
    };

    const getExportValue = (search, page_no, page_Size, sort_by, paginate, status) => {
        // dispatch(setDisplayLoader("Display"));
        TravelListingAPI.getCheckExpenseExportValue(
            handleKey(search, page_no, page_Size, sort_by, paginate, status)
        )
            .then((response) => {
                // dispatch(setDisplayLoader("Hide"));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "CheckExpense.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "failed"
                );
            });
    };


    return (
        <>
            <div class="container-fluid">

                <div class="row">
                    {/* <div class="col-md-3">
                                    <ul class="policylistnames">
                                        <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                        <li class="active"><Link to="/travel-expense">Check Expense</Link></li>
                                        <li><Link to="/travel-bills">All Bills</Link></li>
                                        <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                        <li><Link to="/travel-km-report">KM Report</Link></li>
                                        <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                        <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                        <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                        <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                        <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                        <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                    </ul>
                                </div> */}

                    <div class="col-md-12">
                        <div class="content-wrapper-inner content-wrapper-inner2">
                            <div class="innerheadsec">
                                <div className="row">
                                    <div class="col-md-12">
                                        <h4 class="inner-page-title"> Other Expense Entry Exception</h4>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-lg-6">
                                        <div class="form-group innergroup">
                                            <label>USER ID</label>
                                                {/* <span class="input-group-text"><img src="images/search.png" alt="" /></span> */}
                                                {/* <input type="text"
                                                    class="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        typeUserEntry("user_id",e.target.value)
                                                    }}
                                                /> */}
                                                <Select
                                                options={usersDropdown}
                                                onChange={(e) => {
                                                    typeUserEntry("user_id",e.value)
                                                }}
                                                // value={}

                                                />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group innergroup">
                                            <label>BILL START DATE</label>
                                                {/* <span class="input-group-text"><img src="images/search.png" alt="" /></span> */}
                                                <input type="date"
                                                    class="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        typeUserEntry("from_date",e.target.value)
                                                    }}
                                                    value={postUserData?.from_date}
                                                />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group innergroup">
                                            <label>BILL END DATE</label>
                                                <input type="date"
                                                    class="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        typeUserEntry("to_date",e.target.value)
                                                    }}
                                                    value={postUserData?.to_date}

                                                />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group innergroup">
                                            <label>ALLOWED FROM DATE</label>
                                                <input type="date"
                                                    class="form-control"
                                                    min={moment.parseZone(new Date()).format("YYYY-MM-DD")}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        typeUserEntry("opened_from",e.target.value)
                                                    }}
                                                    value={postUserData?.opened_from}

                                                />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group innergroup">
                                            <label>ALLOWED TO DATE</label>
                                                {/* <span class="input-group-text"><img src="images/search.png" alt="" /></span> */}
                                                <input type="date"
                                                    class="form-control"
                                                    min={moment.parseZone(new Date()).format("YYYY-MM-DD")}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        typeUserEntry("opened_to",e.target.value)
                                                    }}
                                                    value={postUserData?.opened_to}

                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="text-right mr-5">
                            <button class="btn btn-primary-inner bpi-main"
                            onClick={(e)=>{
                                addExceptionMaster()
                            }}
                            >
                                SUBMIT
                            </button>
                            </div>

                            


                        </div>
                    </div>
                </div>

            </div>


 



        </>
    );
}

export default AddOtherExpEntryException;