import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewTable = (props) => {
  const { viewForm, onViewClose, epodList, ewayList } = props;


  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>EPOD & EWAY Bill Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>   
          <div className="row justify-content-center">
            <h6 style={{color:"#0195d4"}}>EPOD Details</h6>
            <div className="col-md-11">

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Image Hyper Link</label>
                    <p>{epodList?.image_hyper_link}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Received on</label>
                    <p>
                      {epodList?.received_on}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h6 style={{color:"#0195d4"}}>EWAY Bill Details</h6>
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Invoice No.</label>
                    <p> {ewayList.invoice_number}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Transporter Name</label>
                    <p> {ewayList.transporter_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Vehicle #</label>
                    <p> {ewayList.vehicle_number}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bill #</label>
                    <p> {ewayList.bill_number}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bill Date</label>
                    <p> {ewayList.bill_date}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bill Time</label>
                    <p> {ewayList.bill_time}</p>
                  </div>
                </div>
            
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>From GST # </label>
                    <p> {ewayList.from_gstin_number}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>To GST #</label>
                    <p> {ewayList.to_gstin_number}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Distance</label>
                    <p> {ewayList.distance}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>From State Name</label>
                    <p> {ewayList.from_state_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>To State Name</label>
                    <p> {ewayList.to_state_name}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Distance Unit</label>
                    <p> {ewayList.distance_unit}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>From State Code</label>
                    <p> {ewayList.from_state_code}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>To State Code</label>
                    <p> {ewayList.to_state_code}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>From Pin Code</label>
                    <p> {ewayList.from_pin_code}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>To Pin Code</label>
                    <p> {ewayList.to_pin_code}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewTable;
