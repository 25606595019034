import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import * as DashboardService from "../service/dashboard";
import * as moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import avtar from "../assets/images/avtar.png";

const reviewValidationSchema = Yup.object({
  wish_remarks: Yup.string().required("Remarks is required"),
});

const ReviewModal = (props) => {
  const { birthdayReviewData, filteropen, filterclose, getBirthdayList } =
    props;
  const [reviewData, setReviewData] = useState({
    user_id: birthdayReviewData.user_id,
    wish_remarks: "",
  });

  const saveBirthdayRemarks = async (values) => {
    DashboardService.saveBirthdayRemarks(values)
      .then((response) => {
        getBirthdayList();
        filterclose();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <Modal
      show={filteropen}
      onHide={filterclose}
      backdrop="static"
      keyboard={false}
      className="filter-popup modaldefaultclose"
    >
      <Modal.Header closeButton>
        <Modal.Title>Birthday Wish</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={reviewData}
        validationSchema={reviewValidationSchema}
        onSubmit={async (values) => {
          try {
            const response = await DashboardService.saveBirthdayRemarks(values);
            getBirthdayList();
            filterclose();
          } catch (error) {
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          }
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <div class="d-flex align-items-center pb-3 border-bottom">
                    <div>
                      <div class="team-profile">
                        <img
                          src={
                            birthdayReviewData?.image === ""
                              ? avtar
                              : `data:image/gif;base64,${birthdayReviewData?.image}`
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="teamtxt">
                      <h5 class="mb-0 wishname">
                        <strong>{birthdayReviewData?.name}</strong>
                      </h5>
                      {/* <p class="text-dark">
                        <strong>DOB:</strong>
                        {moment(birthdayReviewData?.dob).format("Do MMM YYYY")}
                      </p> */}
                      <p class="text-dark"><strong>Dept.:</strong> {birthdayReviewData?.department_name}</p>
                      <p class="text-dark"><strong>Desig.:</strong> {birthdayReviewData?.designation_name}</p>
                      <p class="text-dark"><strong>Location:</strong> {birthdayReviewData?.location_name}</p>
                      {birthdayReviewData?.mobile !== null ?
                      <p class="text-dark"><strong>Mobile:</strong> {birthdayReviewData?.mobile}</p>
                      :
                      <p class="text-dark"><strong>Mobile:</strong> N/A</p>
                      }
                    </div>
                  </div>
                </div>
                <div class="form-group innergroup mb-4">
                  <label>
                    Your Message<span class="text-danger">*</span>
                  </label>
                  <textarea
                    type="text"
                    name="wish_remarks"
                    class="form-control"
                    placeholder="Enter Message here..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    values={reviewData}
                  ></textarea>
                  {touched.wish_remarks && errors.wish_remarks ? (
                    <div className="small text-danger">
                      {errors.wish_remarks}
                    </div>
                  ) : null}
                </div>

                <button
                  class="btn btn-outline-danger btn-xs"
                  onClick={filterclose}
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  class="btn btn-primary-inner bpi-main btn-xs"
                  type="submit"
                >
                  Submit
                </button>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ReviewModal;
