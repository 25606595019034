import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getSalesPlan = async () => {
    return axios.get(`${AUTH_BASE_URL}/sale-plan`);
};

export const getLastSalesPlan = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/sale-plan/last-sale-plan${query}`)
}


export const postSalesPlan = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/sale-plan`,body);
}

export const putSalesPlan = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/sale-plan`,body);
}

export const getSalesPlanApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/sale-plan/approver-sale-plan-logs${query}`);
}

export const postSalesPlanAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/sale-plan/action-sale-plan`,body);
}