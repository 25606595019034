import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom"; 
import { Modal, Breadcrumb, Dropdown, Pagination, ProgressBar, Tabs, Tab  } from 'react-bootstrap';
import Select from 'react-select';

function TravelKmreport() { 

    const navigate = useNavigate();
 
    const options = [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' }
    ]
 

    const [show, filtersetShow] = useState(false); 
    const filteropen = () => filtersetShow(true);
    const filterclose = () => filtersetShow(false);
 
 
    return (
        <>
           <div class="container-fluid">
                        <div class="row">
                             {/* <div class="col-md-3">
                                <ul class="policylistnames">
                                    <li><Link to="/travel-pick">Travel Expense Pick List</Link></li>
                                    <li><Link to="/travel-expense">Check Expense</Link></li>
                                    <li><Link to="/travel-bills">All Bills</Link></li>
                                    <li><Link to="/travel-processed-ta">Processed TA</Link></li>
                                    <li class="active"><Link to="/travel-km-report">KM Report</Link></li>
                                    <li><Link to="/travel-intimat-report">Travel Intimation Report</Link></li>
                                    <li><Link to="/travel-prov-report">Provision Report</Link></li>
                                    <li><Link to="/travel-deviat-report">Deviation Report</Link></li>
                                    <li><Link to="/travel-hotel-exp-report">Hotel Expense Report</Link></li>
                                    <li><Link to="/travel-fcm-exp-report">FCM Ticket Expense Report</Link></li>
                                    <li><Link to="/travel-employ-report">Employee List Report</Link></li>
                                </ul>
                            </div> */}

                            <div class="col-md-12">
                                <div class="content-wrapper-inner content-wrapper-inner2">
                                    <div class="innerheadsec">
                                        <div className="row">
                                            <div class="col-md-12">
                                                <h4 class="inner-page-title">KM Report</h4>
                                            </div> 
                                            <div class="col-md-12">
                                                <div class="border-top mt-2 mt-md-4 mb-3"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-lg-4">
                                                <div class="input-group table-search">
                                                    <span class="input-group-text"><img src="images/search.png" alt="" /></span>
                                                    <input type="text" class="form-control" placeholder="Search..." /> 
                                                </div>
                                            </div>
                                            <div class="col-lg-8">
                                                <div class="inline-spacing btn-betweenspaing">
                                                    <Dropdown show>
                                                        <Dropdown.Toggle variant="primary-inner dropdownbtn" id="dropdown-basic">
                                                            <img src="images/export.png" alt="" class="mr-2" /> Export
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#"><i class="far fa-file-excel"></i>Excel</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <button onClick={filteropen} class="btn btn-secondary-inner"><img src="images/filter.png" alt="" class="mr-3" />Filter</button> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-striped table-bordered tablecured">  
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Employee ID</th>
                                                    <th>Name</th>
                                                    <th>Vendor Code</th>
                                                    <th>Reporting Head</th>
                                                    <th>Grade</th>
                                                    <th>Location</th>
                                                    <th>Zone</th>
                                                    <th>Vehicle No.</th>
                                                    <th>Make</th>
                                                    <th>Model</th>
                                                    <th>Vehicle Type</th>
                                                    <th>Driver Wages</th>                                    
                                                    <th>Monthly Limit</th>
                                                    <th>Travelled Kms</th>
                                                    <th>Excess Km</th>                                    
                                                    <th>Fuel Charges</th>
                                                    <th>Fuel Qty</th>
                                                    <th>Rs/Km.</th>
                                                    <th>Km./Ltr.</th>
                                                    <th>Fuel Avg. Rates/Ltr.</th>
                                                    <th>Till Date</th>
                                                    <th>Cost Centre</th>
                                                    <th>Profit Centre</th>
                                                    <th>Business Area</th> 
                                                    <th>Reporting Manager</th>
                                                    <th>Functional Head</th>
                                                    <th>E- mail Id</th> 
                                                </tr>
                                            </thead>
                                             <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td> 
                                                </tr>
                                             </tbody>
                                        </table>

                                    </div> 

                                    <div class="row">
                                        <div class="col-sm-5">
                                            <div class="sortinglist">
                                                Show
                                                <select class="form-control">
                                                    <option>10</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                            <Pagination> 
                                                <Pagination.Prev className="previpag"/>
                                                <Pagination.Item>{1}</Pagination.Item> 
                                                <Pagination.Item active>{2}</Pagination.Item>  
                                                <Pagination.Ellipsis />
                                                <Pagination.Item>{9}</Pagination.Item>
                                                <Pagination.Item>{10}</Pagination.Item> 
                                                <Pagination.Next className="previpag"/> 
                                            </Pagination>
                                        </div>
                                    </div>
                                

                                </div>
                            </div>
                        </div>
           </div>

 

           <Modal show={show} onHide={filterclose} className="filter-popup modaldefaultclose">
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                   
                    <div class="form-group innergroup">
                        <label>Employee ID  <span class="text-danger">*</span></label>
                        <Select  
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select" 
                        />
                    </div> 

                    <div class="form-group innergroup">
                        <label>Trip Start Date  <span class="text-danger">*</span></label>
                        <input type="date" class="form-control" />
                    </div>

                    <div class="form-group innergroup">
                        <label>Trip End Date  <span class="text-danger">*</span></label>
                        <input type="date" class="form-control" />
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-outline-danger" onClick={filterclose}>Cancel</button>
                    <button class="btn btn-primary-inner bpi-main">Apply</button>
                </Modal.Footer>
            </Modal>

            

        </>
    );
}

export default TravelKmreport;