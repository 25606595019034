import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getSubPersonalArea } from "../../service/userService";
import { uploadFile, save_CanteenData, view_CanteenData, update_canteenData } from "../../service/policy";
import { redirect } from "react-router";

function CanteenManagement() {

    let qry = new URLSearchParams(window.location.search)
    const id = qry.get("id")
     
    const [locationOptions, setLocationOptions] = useState([]);
    const [locationValue, setLocationValue] = useState([]);
    const [locationIds, setLocationIds] = useState([]);
    const [description, setDescription] = useState('');
    const [imgSize, setImgSize] = useState(false);
    const [attachment, setAttachment] = useState({});
    const [image, setImage] = useState([]);
    const [descriptionError, setDescriptionError] = useState(false);
    const [attachmentError, setAttachmentError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [editMode, setEditMode] = useState(false);

    useEffect(()=>{
        getAllLocations();
        if(id !== undefined && id !== null){
            setEditMode(true)
            getCanteenData(id)
        }
    },[])

    const getAllLocations = () => {
        getSubPersonalArea(0).then((response)=>{
            let obj = response?.data?.dataList?.result?.map((each)=>{
                return {
                    value: each?.id,
                    label: `${each?.description} (${each?.name})`
                }
            })
            setLocationOptions(obj);
        })
    }

    const onProfileChange = (e) => {
        setAttachmentError(false);
        Object.values(e.target.files)?.map((res) => {
          const files = res;
          const img_format = files?.name?.split("/").pop().includes(".pdf")
          if (res && img_format) {
            image.push(URL.createObjectURL(res));
          }
          if ((files.size < 2066830 || files.size === 2066830) && img_format) {
            setImgSize(false);
            const fileData = new FormData();
            fileData.append("uploaded_file", files);
            uploadFile(fileData).then((response) => {
              setAttachment(response.data)
              if(response?.data?.status === false){
                toast.error(response?.data?.message)
              }
            });
          } else {
            setImgSize(true);
          }
        })
      };

    const handleRemoveImage = (index) => {
        let imageData = JSON.parse(JSON.stringify(image));
        imageData.splice(index, 1);
        setImage([...imageData]);
    }

    const handleLocationChange = (e) => {
        setLocationValue(e);
        setLocationError(false);
        let location = e?.map((each)=>{
            return each?.value
        })
        setLocationIds(location)
    }

    const saveCanteenData = () => {
        if(locationIds?.length ===0){
            setLocationError(true);
        }
        else if(Object.keys(attachment).length === 0){
            setAttachmentError(true);
        }
        else if(description.length === 0){
            setDescriptionError(true)
        }
        else{
        let data = {
            location: locationIds,
            attachment: attachment,
            description: description
        }
        save_CanteenData(data).then((response)=>{
            toast.success('Data Submitted Successfully')
        })
    }
    }

    const getCanteenData = (id) => {
        view_CanteenData(id).then((response)=>{
            let data = response?.data?.dataList?.result
            if(data?.length > 0){
                setDescription(data[0]?.description)
                let obj = {
                    value: data[0]?.location,
                    label: data[0]?.location_details
                }
                setLocationValue(obj)
                locationIds.push(data[0]?.location)
                let attach = data[0]?.attachment
                if(attach?.length > 0){
                    image.push(attach[0]?.attachment_path)
                }
            }
        })
    }

    const updateCanteenData = () => {
        let data = {
            location: locationIds,
            // attachment: attachment,
            description: description
        }
        if(Object.keys(attachment).length !== 0){
            data.attachment = attachment
        }
        update_canteenData(id, data).then((response)=>{
            toast.success('Data Updated Successfully')
            window.location.replace('/canteen-menu-master')
        })
    }

    
    return (
        <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Canteen Menu Management</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Location<span class="text-danger">*</span>
                    </label>
                    <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={locationOptions}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    onChange={(e)=>{handleLocationChange(e)}}
                    value={locationValue}
                    /> 
                    <div className="small text-danger">
                        {locationError && "*Location Required"}
                      </div> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Attachment<span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control bg-white"
                        disabled
                        placeholder="(PDF Format)"
                    />
                    <div class="upload-btn-wrapper up-loposition">
                        <button class="uploadBtn">Browse</button>
                        <input
                        type="file"
                        title=""
                        name="attachment_path"
                        accept="application/pdf"
                        multiple
                        onChange={(e) => {
                            onProfileChange(e);
                        }}
                        />
                        <div className="small text-danger">
                        {attachmentError && "*Attachment Required"}
                      </div> 
                        </div>
                    {imgSize ?
                    <span className="text-danger">
                    (Check File format should be pdf or size upto 2MB)
                    </span>
                    :
                    <span>
                        (Allowed only pdf with size: upto 2MB)
                    </span>
                    }
                    <div className="col-md-6 mt-3">
                        <p>
                            <span>
                            {image?.length > 0 && image !== null && image !== undefined
                                ?
                                <>
                                Selected files&nbsp;
                                {image?.map((url, index) => {
                                    return (
                                    <>
                                        <a href={url} target="_blank">
                                        {(url.split("/").pop().includes(".pdf") || (url.split("/")?.length > 0 && url.split("/")[0].includes("blob"))) ? (
                                            <i class="fas fa-file-pdf fa-2x" style={{ color: "red", marginLeft: "40px" }}></i>
                                        ) : ""
                                        }
                                        </a>
                                        <span
                                            class="btn btn-blanktd text-danger ml-2 f-12"
                                            onClick={() => handleRemoveImage(index)}
                                        >
                                            <i class="far fa-trash-alt text-danger"></i>
                                        </span>
                                    </>
                                    )
                                })}
                                </>
                                : ""}
                            </span>
                        </p>
                        </div>
                  </div>
                </div>
                
              </div>

              <div class="row">
                {/* <div class="col-md-12 fw-bold mb-3">OR</div> */}

                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>
                      Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Menu Description"
                      onChange={(e)=>{setDescription(e.target.value); setDescriptionError(false)}}
                      value={description}
                    />
                   <div className="small text-danger">
                        {descriptionError && "*Description Required"}
                      </div> 
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <button
                   class="btn btn-outline-danger mr-3"
                   type="button"
                   onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                   >
                    Cancel
                   </button>
                  <button class="btn btn-primary-inner bpi-main" type="button" onClick={editMode ? updateCanteenData : saveCanteenData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CanteenManagement;