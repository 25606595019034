import React from "react";
import { Modal } from "react-bootstrap";

const UserDetailGrid = (props) => {
  const { showModal, onCloseModal, userDetailModule } = props;
  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title> User Detail</Modal.Title>
      </Modal.Header>
      <>
        <Modal.Body>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured ">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Employee Code</th>
                  <th>Email Id</th>
                </tr>
              </thead>
              <tbody>
                {userDetailModule?.user_data?.length ? (
                  userDetailModule?.user_data?.map((data, index) => (
                    <tr>
                      <td>{data?.name}</td>
                      <td>{data?.last_name}</td>
                      <td>{data?.username}</td>
                      <td>{data?.email}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </>
    </Modal>
  );
};

export default UserDetailGrid;
