import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { changeprstatus, getPrTransactions, getPrTransactionsApproval } from "../../service/prService";
import { toast } from "react-toastify";
import { selectUserData, selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import SubItemForm from "./modals/sub-item-from";
import ViewSubItemForm from "./modals/view-sub-item-details";
import copy from "copy-to-clipboard";
import ViewItemForm from "./modals/view-item-form";

function ViewPR() {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentuserdata = useSelector(selectUserData);
  const finalUrlValue = useSelector(selectFinalUrlPermission)
  const [checkedId, setCheckedId] = useState([]);
  const [image, setImage] = useState([])
  const [errorForRemarks, setErrorForRemarks] = useState(false);
  const [subItemValues, setSubItemValues] = useState([]);
  const [itemValues, setItemValues] = useState([]);
  const [itemDetailsFlag, setItemDetailsFlag] = useState(false);
  const [viewSubitemflag, setViewSubitemflag] = useState(false);
  const [id, setid] = useState(location?.state?.from);
  const navigate = useNavigate();
  const [Status, setstatus] = useState({
    id: location?.state?.from,
    status: "",
    remarks: "",
  });
  const initialValues = {
    list: {},
    query: { search: "", page_size: 10, page_no: 1, paginate: true,pr_id:location?.state?.from,Itemsflag:location?.state?.Itemsflag, pr_mast_id:location?.state?.pr_mast_id, master_flag:location?.state?.master_flag },
  };
  const [data, setData] = useState(initialValues);
  const getData = async (query) => {
    try {
      const response = await getPrTransactionsApproval(query);
      if (response.data.code === 200) {
        console.log("111111",response?.data?.dataList?.result[0]);
      
        setData({
          list: response?.data?.dataList?.result[0],
          query: {
            ...query,
            pr_id:id,
          },
        });
        setImage(response?.data?.dataList?.result[0]?.attachment)
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    }
  };

  const changestatus = async (status) => {
    if(Status.remarks !== "" || data?.list?.currentApprover?.approver_username=== data?.list?.currentApproverList[0]?.approver_username){
    Status.status=status
   Status.itemId=[location?.state?.from]
    try {
      let obj={
        permission_id: finalUrlValue?.A_P?.id,
        transaction_id: location?.state?.from
      }
      if(finalUrlValue?.A_P?.id !== null && finalUrlValue?.A_P?.id !== undefined){
        dispatch(setTracker(obj))
      }
      const response = await changeprstatus(Status);
      if (response.data.code === 200) {
      
        if (status=="A"){
          toast.success("PR Approved")
        }else if(status=="D"){
          toast.warning("PR Rejected")

        }
        window.history.go(-1);
        return false;
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    }
  }
  else{
    setErrorForRemarks(true)
  }
  };
  
  useEffect(() => {
    getData(data.query);
  }, [])
  const handleAllData=(e,id)=>{
    if (e.target.checked) {
      setCheckedId([...checkedId, id]);
    } else {
      const index = checkedId.indexOf(id);
      if (index > -1) {
        let data = checkedId;
        data.splice(index, 1);
        setCheckedId(data);
      }
    }
      }

  const handleClose = () =>{
    setViewSubitemflag(false)
    setItemDetailsFlag(false)
  }

  const copyToClipboard = (text) => {
    copy(text);
    alert(`You have copied "${text}"`);
}

const showItemDetails = (items) =>{
  setItemDetailsFlag(true)
  let query= { search : "", page_size: 10, page_no: 1, paginate: true,pr_id:items?.id,Itemsflag:true, pr_mast_id:items?.pr_id, master_flag:false}
  // getData(query)
  setData({
    list: items,
    query: {
      ...query,
      pr_id:items?.pr_id,
    },
  });
}

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
           
            <li class="active">
              <Link  >PR</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View PR: {data?.list?.id}</h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>PR No.</label>
                    <p>{data?.list?.id}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Created by</label>
                    <p>{data?.list?.user_name}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>PR Type</label>
                    <p>{data?.list?.type}</p>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Purchasing Group</label>
                    {data?.list?.items?.length > 0 ?
                    <p>{data?.list?.items[0]?.purchase_group_desc} - {data?.list?.items[0]?.purchase_group_code}</p>
                    :
                    <p></p>
                    }
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>SAP PR No.</label>
                    <p>{data?.list?.sap_pr_no}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Source</label>
                    {data?.list?.source === "E" ?
                    <p>ELN</p>
                    : data?.list?.source === "M" ?
                    <p>MYPI</p>
                    : data?.list?.source === "S" ?
                    <p>SAP</p>
                    :
                    <p>{data?.list?.source}</p>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-2 ml-3">
              <h6 class="text-theme mb-0">
                <i class="far fa-arrow-alt-circle-right"></i>{" "}
                <strong>Items</strong>
              </h6>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    {/* <th>Select</th> */}
                    <th>Item</th>
                    <th>Sub-Item</th>
                    {/* <th>I</th> */}
                    {/* <th>A</th> */}
                    <th>Plant</th>
                    <th>Material</th>
                    <th>Mat Desc</th>
                    {/* <th>Unit</th> */}
                    <th>Qty.</th>
                    <th>Price/ Unit</th>
                    <th>Total Price</th>
                    <th>Del.Date</th>
                    <th>Val.Type</th>
                    <th>Pur.Grp.</th>
                    <th>Track. No.</th>
                    <th>Item Text</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(data?.list?.items)}
                 {data?.list?.items?.map((items)=>( 
                   currentuserdata?.username==data?.list?.user_username?(<tr>
                  {/* <td>
                  <label class="logCheck d-inline-block">
                           
                            {items?.user_status!="P" ?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                              checked={items?.user_status!="P"}
                              // onClick={(e) => {
                              //   handleAllData(e,items?.id);
                              // }}
                            />: <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                              // checked={items?.user_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />}
                            <span class="checkmark"></span>
                          </label>
                    </td> */}
                    <td>
                      <span class="badge badge-secondary"  style={{cursor:"pointer"}} onClick={()=>{setItemValues(items); setItemDetailsFlag(true)}}>{items?.item_no}</span>
                    </td>
                    <td>
                      <span class="badge badge-primary"  style={{cursor:"pointer"}} onClick={()=>{setSubItemValues(items?.sub_transaction_data); setViewSubitemflag(true)}}>{items?.sub_transaction_data?.length}</span>
                    </td>
                    <td>{items?.plant_code}</td>
                    <td>{items?.material_code}</td>
                    <td onClick={()=>{copyToClipboard(items?.material_desc)}}>{items?.material_desc}</td>
                    {/* <td>{items?.price_per_unit}</td> */}
                    {data?.list?.type === "Service PR" ?
                    <>
                    <td>1</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    </>
                    :
                    <>
                    <td>{items?.requisition_quantity}</td>
                    <td>{items?.valuation}</td>
                    <td>{items?.valuation * items?.requisition_quantity}</td>
                    </>
                    }
                    <td>{items?.delete_date}</td>
                    <td>{items?.valuation_type}</td>
                    <td>{items?.purchase_group_code}</td>
                    <td>{items?.tracking_no}</td>
                    <td onClick={()=>{copyToClipboard(items?.remarks)}}>{items?.remarks}</td>
                    
                    {  items?.user_status=="A" && items?.delete_indicator != 'X' ?
                      <td>
                          <span class="badge badge-success">Approved</span>
                        </td>
                        : items?.user_status=="D" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>
                        : items?.user_status=="P" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>
                        : items?.delete_indicator === 'X' ?
                        <td>Deleted</td>
                        :
                        <td></td>
                        }
                  </tr>)
                  : currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?
                  items?.user_status!="P" &&
                  <tr>
                  {/* <td>
                  <label class="logCheck d-inline-block">
                           { items?.c2_status=="P" ?
                           <input
                              className="selectid"
                              type="checkbox"
                              name="permission"
                              // checked={items?.c2_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:
                            <input
                            className="selectid"
                            type="checkbox"
                            name="permission"
                            checked={items?.c2_status!="P"}
                            onClick={(e) => {
                              handleAllData(e,items?.id);
                            }}
                          />
                            }


                            <span class="checkmark"></span>
                          </label>
                    </td> */}
                    <td>
                      <span class="badge badge-secondary"  style={{cursor:"pointer"}} onClick={()=>{setItemValues(items); setItemDetailsFlag(true)}}>{items?.item_no}</span>
                    </td>
                    <td>
                      <span class="badge badge-primary" style={{cursor:"pointer"}} onClick={()=>{setSubItemValues(items?.sub_transaction_data); setViewSubitemflag(true)}}>{items?.sub_transaction_data?.length}</span>
                    </td>
                    <td>{items?.plant_code}</td>
                    <td>{items?.material_code}</td>
                    <td onClick={()=>{copyToClipboard(items?.material_desc)}}>{items?.material_desc}</td>
                    {/* <td>{items?.price_per_unit}</td> */}
                    {data?.list?.type === "Service PR" ?
                    <>
                    <td>1</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    </>
                    :
                    <>
                    <td>{items?.requisition_quantity}</td>
                    <td>{items?.valuation}</td>
                    <td>{items?.valuation * items?.requisition_quantity}</td>
                    </>
                    }
                    <td>{items?.delete_date}</td>
                    <td>{items?.valuation_type}</td>
                    <td>{items?.purchase_group_code}</td>
                    <td>{items?.tracking_no}</td>
                    <td onClick={()=>{copyToClipboard(items?.remarks)}}>{items?.remarks}</td>

                    {items?.c2_status=="A" && items?.delete_indicator != 'X' ?
                      <td>
                          <span class="badge badge-success">Approved</span>
                        </td>
                        : items?.c2_status=="D" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>
                        : items?.c2_status=="P" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>
                        : items?.delete_indicator === 'X' ?
                        <td>Deleted</td>
                        :
                        <td></td>
                        }
                  </tr>
                  :currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?
                  // items?.c2_status =="P" &&
                  <tr>
                  {/* <td>
                  <label class="logCheck d-inline-block">
                            {currentuserdata?.username==data?.list?.user_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                              checked={items?.user_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:
                            currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                               checked={items?.c2_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:
                            currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                               checked={items?.c3_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:""
                          }
                            <span class="checkmark"></span>
                          </label>
                    </td> */}
                    <td>
                      <span class="badge badge-secondary"  style={{cursor:"pointer"}} onClick={()=>{setItemValues(items); setItemDetailsFlag(true)}}>{items?.item_no}</span>
                    </td>
                    <td>
                      <span class="badge badge-primary" style={{cursor:"pointer"}} onClick={()=>{setSubItemValues(items?.sub_transaction_data); setViewSubitemflag(true)}}>{items?.sub_transaction_data?.length}</span>
                    </td>
                    <td>{items?.plant_code}</td>
                    <td>{items?.material_code}</td>
                    <td onClick={()=>{copyToClipboard(items?.material_desc)}}>{items?.material_desc}</td>
                    {/* <td>{items?.price_per_unit}</td> */}
                    {data?.list?.type === "Service PR" ?
                    <>
                    <td>1</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    </>
                    :
                    <>
                    <td>{items?.requisition_quantity}</td>
                    <td>{items?.valuation}</td>
                    <td>{items?.valuation * items?.requisition_quantity}</td>
                    </>
                    }
                    <td>{items?.delete_date}</td>
                    <td>{items?.valuation_type}</td>
                    <td>{items?.purchase_group_code}</td>
                    <td>{items?.tracking_no}</td>
                    <td onClick={()=>{copyToClipboard(items?.remarks)}}>{items?.remarks}</td>
                    {currentuserdata?.username==data?.list?.user_username?(
                    items?.user_status=="A" && items?.delete_indicator != 'X' ?
                    <td>
                        <span class="badge badge-success">Approved</span>
                      </td>
                      : items?.user_status=="D" && items?.delete_indicator != 'X' ?
                      <td>
                        <span class="badge badge-danger">Rejected</span>
                      </td>
                      : items?.user_status=="P" && items?.delete_indicator != 'X' ?
                      <td>
                        <span class="badge badge-secondary">Pending</span>
                      </td>
                      : items?.delete_indicator === 'X' ?
                      <td>Deleted</td>
                      :
                      <td></td>):
                    currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?(
                      items?.c2_status=="A" && items?.delete_indicator != 'X' ?
                    <td>
                        <span class="badge badge-success">Approved</span>
                      </td>
                      : items?.c2_status=="D" && items?.delete_indicator != 'X' ?
                      <td>
                        <span class="badge badge-danger">Rejected</span>
                      </td>
                      : items?.c2_status=="P" && items?.delete_indicator != 'X' ?
                      <td>
                        <span class="badge badge-secondary">Pending</span>
                      </td>
                      : items?.delete_indicator === 'X' ?
                      <td>Deleted</td>
                      :
                      <td></td>):
                    currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?(
                      items?.c3_status=="A" && items?.delete_indicator != 'X' ?
                      <td>
                          <span class="badge badge-success">Approved</span>
                        </td>
                        : items?.c3_status=="D" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>
                        : items?.c3_status=="P" && items?.delete_indicator != 'X' ?
                        <td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>
                        : items?.delete_indicator === 'X' ?
                        <td>Deleted</td>
                        :
                        <td></td>):""}
                  </tr>:
                  <tr>
                  {/* <td>
                  <label class="logCheck d-inline-block">
                            {currentuserdata?.username==data?.list?.user_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                              checked={items?.user_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:
                            currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                               checked={items?.c2_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:
                            currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?
                            <input
                            className="selectid"
                              type="checkbox"
                              name="permission"
                               checked={items?.c3_status!="P"}
                              onClick={(e) => {
                                handleAllData(e,items?.id);
                              }}
                            />:""
                          }
                            <span class="checkmark"></span>
                          </label>
                    </td> */}
                    <td>
                      <span class="badge badge-secondary"  style={{cursor:"pointer"}} onClick={()=>{setItemValues(items); setItemDetailsFlag(true)}}>{items?.item_no}</span>
                    </td>
                    <td>
                      <span style={{cursor:"pointer"}} onClick={()=>{setSubItemValues(items?.sub_transaction_data); setViewSubitemflag(true)}} class="badge badge-primary">{items?.sub_transaction_data?.length}</span>
                    </td>
                    <td>{items?.plant_code}</td>
                    <td>{items?.material_code}</td>
                    <td onClick={()=>{copyToClipboard(items?.material_desc)}}>{items?.material_desc}</td>
                    {/* <td>{items?.price_per_unit}</td> */}
                    {data?.list?.type === "Service PR" ?
                    <>
                    <td>1</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    <td>{Number(items?.sum_price).toFixed(2)}</td>
                    </>
                    :
                    <>
                    <td>{items?.requisition_quantity}</td>
                    <td>{Number(items?.valuation).toFixed(2)}</td>
                    <td>{Number(items?.valuation * items?.requisition_quantity).toFixed(2)}</td>
                    </>
                    }
                    <td>{items?.delete_date}</td>
                    <td>{items?.valuation_type}</td>
                    <td>{items?.purchase_group_code}</td>
                    <td>{items?.tracking_no}</td>
                    <td onClick={()=>{copyToClipboard(items?.remarks)}}>{items?.remarks}</td>
                    {currentuserdata?.username==data?.list?.user_username?(
                      items?.user_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:items?.user_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:items?.user_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):
                    currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?(
                      items?.c2_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:items?.c2_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:items?.c2_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):
                    currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?(
                      items?.c3_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:items?.c3_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:items?.c3_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):<td></td>}
                  </tr>))}
                </tbody>
              </table>
            </div>

            {/* <div class="mt-4 mb-2 ml-3">
              <h6 class="text-theme mb-0">
                <i class="far fa-arrow-alt-circle-right"></i>{" "}
                <strong>Sub-Items</strong>
              </h6>
            </div> */}

            {/* <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>Line No.</th>
                    <th>Service No.</th>
                    <th>Short Text</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Gross Price</th>
                    <th>Currency</th>
                    <th>Net Value</th>
                    <th>GL Account</th>
                    <th>Order No.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="badge badge-secondary">10</span>
                    </td>
                    <td>234</td>
                    <td>defcwefc</td>
                    <td>3</td>
                    <td>MG</td>
                    <td>3.00</td>
                    <td>CHF</td>
                    <td>0.00</td>
                    <td>333</td>
                    <td>345676</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
{(location?.state?.view_level !== "PR" || itemDetailsFlag) &&(
            <div class="row">
              <div class="col-md-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        <th>Item No</th>
                        <th>Approver Stage</th>
                        <th>Approver Name</th>
                        <th>Approved/Rejected Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.currentApproverList?.map((a)=>(<tr>
                        {/* <td>1</td> */}
                        <td>{a?.item_no}</td>

                        <td>{a?.approver_type}</td>
                        <td class="text-theme fw-bold">{a?.approver_name}</td>
                        {a?.status === "A" || a?.status === "D" ?
                        <td>{moment(a?.updated_at).format("DD-MM-YYYY")}</td>
                        :
                        <td></td>
                      }
                        <td>{a?.remark}</td>
                        {a?.status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:a?.status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:(a?.status==null||a?.status=="P")?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""}
                      </tr>))}
                    
                       
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
)}
            {/* <div class="row">
              <div class="col-md-8">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>File Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td class="text-theme fw-bold">
                          Emp_handbook_16762.pdf
                        </td>
                        <td>
                          <img src="images/eye.png" />
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i class="far fa-trash-alt text-danger"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td class="text-theme fw-bold">
                          Emp_dashboard_98765.pdf
                        </td>
                        <td>
                          <img src="images/eye.png" />
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i class="far fa-trash-alt text-danger"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}

            {/* <div class="col-md-6">
              <div class="form-group innergroup position-relative modaldefaultclose ">
                <label>
                  Upload files<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control bg-white"
                  disabled
                  placeholder="(Image, PDF Format)"
                />
                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input type="file" />
                </div>
                <p>
                  Selected files <span>file-1.png</span>
                </p>
                <div class="d-flex">
                  <div class="uploaded-image mr-2">
                    <i class="far fa-file-pdf fa-2x mt-2 text-theme"></i>
                    <button class="btn-close"></button>
                  </div>
                  <div class="uploaded-image mr-2">
                    <img src={Logo} alt="" height={50} />
                    <button class="btn-close"></button>
                  </div>
                </div>
              </div>
            </div> */}
          {/* {data?.list?.map((res)=>{return( */}
          <div className="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  View PR Header Text<span class="text-danger">*</span>
                </label>
                <textarea
                  disabled
                  class="form-control"
                  value={data?.list?.header_text}
                  // placeholder="Enter PR Header Text"
                  // value={res?.header_text}
                  // onChange={(e)=>     
                  //   setstatus((prev) => ({
                  //   ...prev,
                  //   remarks: e.target.value,
                  // }))}
                ></textarea>
               
              </div>
            </div>
            {(location?.state?.view_level !== "item" && location?.state?.view_level !== "PR") || location?.state?.master_flag === false &&(
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Remarks<span class="text-danger">*</span>
                </label>
                <textarea
                  class="form-control"
                  // value={data?.list?.header_text}
                  placeholder="Enter Remarks..."
                  // value={res?.header_text}
                  onChange={(e)=>{     
                    setstatus((prev) => ({
                    ...prev,
                    remarks: e.target.value,
                  }));
                  setErrorForRemarks(false);
                }}
                ></textarea>
               <div className="small text-danger">
                      {errorForRemarks &&
                        "* Kindly give remarks."}
                    </div>
              </div>
            </div>
)}
            </div>
            {(location?.state?.view_level === "PR" || location?.state?.approval_flag)  && (
              <div className="row">
              <div className="col-md-6">
                     <div class="form-group innergroup position-relative modaldefaultclose ">
                       <label>
                         Attachments <span class="text-danger"></span>
                       </label>
                       <div className="row">
                       <span>
                       {/* <i class="fas fa-file-pdf"></i> */}
                             {image !== null && image !== undefined && image !== []
                               ? 
                               <>
                               {image?.map((url,index)=>{return(
                                 <>
                               <a href={url?.attachment_path} target="_blank">
                               {/* <img src={url?.attachment_path} style={{width:"50px", height:"40px", marginLeft:"40px"}}/> */}
                              {url.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".pdf") ? (
                                    <i class="fas fa-file-pdf fa-2x"></i>
                                  ) : (
                                    url.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".JPG") ||  
                                  url.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".jpg")  ||
                                  url.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".jpeg") ||
                                  url.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".JPEG") 
                                  ) ? (
                                  <img
                                    className="ml-5"
                                    width="70px"
                                    height="70px"
                                    src={url.attachment_path}
                                  />
                                ) : ""
                                  }
                               </a>
                               </>
                               )})}
                               </>
                               : ""}
                           </span>
                       </div>
                       </div>
                     </div>
              </div>
            )}
{/* )})} */}
           {
           <div class="col-md-12 pb-3">
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() => {
                  window.history.go(-1);
                  return false;
                }}
              >
                <i class="fas fa-arrow-circle-left"></i> Back
              </button>
             {location?.state?.Itemsflag && data?.list?.sap_pr_no!==null && location?.state?.master_flag === false &&
              <> {currentuserdata?.username== data?.list?.currentApprover?.approver_username && data?.list?.items[0].status=="P" && data?.list?.items[0].level=="Reviewer" && <button onClick={()=>changestatus("D")} class="btn btn-outline-danger mr-2">
                <i class="far fa-times-circle"></i> Reject
              </button>}
             {((currentuserdata?.username== data?.list?.currentApprover?.approver_username &&  data?.list?.items[0]?.level=="Reviewer" && data?.list?.items[0]?.status=="P")||(currentuserdata?.username== data?.list?.user_username &&  data?.list?.items[0]?.level=="User"&& (data?.list?.items[0]?.status=="P"||data?.list?.items[0]?.status=="D")))  &&<button onClick={()=>changestatus("A")} class="btn btn-primary-inner bpi-main">
                <i class="far fa-check-square"></i> Approve
              </button>}
              </>
              }
            </div>}
          </div>
        </div>
      </div>
      {viewSubitemflag && (
      <ViewSubItemForm
      viewSubitemflag={viewSubitemflag}
      handleClose={handleClose}
      subItemValues={subItemValues}
      />
      )}
      {itemDetailsFlag && (
      <ViewItemForm
      itemDetailsFlag={itemDetailsFlag}
      handleClose={handleClose}
      itemValues={itemValues}
      data = {data}
      />
      )}
    </>
  );
}

export default ViewPR;
