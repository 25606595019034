import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "../ModularTable";
import { getCurrentFY } from "../../../../service/AgriPortalService/OverallStrategyService";
import styles from "./Table.module.css";
import {
  getPopPlan,
  getCropDropdown,
  getLanguageDropdown,
  getLastPopPlanner,
  getMonthDropdown,
  getPopItemApproval,
  getPopItemDropdown,
  getProductItemDropdown,
  postPopItem,
  postPopItemAction,
  putCropPopPlanner,
} from "../../../../service/AgriPortalService/PopPlanner";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS

  const [dropdownProducts, setDropdownProducts] = useState({});

  //tobe removed
  const [objective, setObjective] = useState([]);
  const [months, setMonths] = useState([]);

  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");

  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [currentfy, setCurrentfy] = useState({});
  const [cropData, setCropData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [popData, setPopData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [tableRowReRender, setTableRowReRender] = useState(false);
  const [mode, setMode] = useState();
  const [approvalList, setApprovalList] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showLoader, setShowLoader] = useState(false);

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getPopPlan()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  // useEffect(() => {
  //   if (showLoader) {
  //     dispatch(setDisplayLoader('Display'))
  //   } else {
  //     dispatch(setDisplayLoader('Hide'))
  //   }
  // }, [showLoader])

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/popplanner";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/popplanner");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  // useEffect(() => console.log("THD", tableHeadData), [tableHeadData]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    data.map((element, i) => {
      let total = 0;
      element.map((ele, index) => {
        if (index > 3 && index < element.length - 1) {
          let val = ele.value[0];
          if (!val) val = 0;
          total += parseInt(val);
        }
        data[i][element.length - 1].value[0] = total;
      });
    });
    setUpdatedTableData(data);
  };

  //To preload dropdown player values
  useEffect(() => {
    setShowLoader(true)
    getCropDropdown()
      .then((res) => {
        setShowLoader(false)
        let data = [];
        res.data.dataList.map((ele) => {
          data.push({
            name: ele.crop_name,
            id: ele.crop_id,
            code: ele.crop_code,
          });
        });
        setCropData(data);
      })
      .catch((err) => {
        setShowLoader(false)
        console.log(err)
      });

    // getLanguageDropdown()
    // .then(res=>{setLanguageData(res.data.dataList)})
    // .catch(err=>console.log(err));

    // getPopItemDropdown()
    // .then(res=>{setPopData(res.data.dataList)})
    // .catch(err=>console.log(err));

    // getProductItemDropdown()
    // .then(res=>{setProductData(res.data.dataList)})
    // .catch(err=>console.log(err));
  }, []);

  // useEffect(() => {
  //   if (tableRowReRender === true) {
  //     dispatch(setDisplayLoader('Hide'))
  //   } else {
  //     dispatch(setDisplayLoader('Display'))
  //   }
  // }, [tableRowReRender])

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create")
      query = "?pop_trans_id=" + params.get("id");
    dispatch(setDisplayLoader('Display'))
    getLastPopPlanner(query)
      .then((res) => {
        setShowLoader(false)
        setRawData(res.data.dataList);
        dispatch(setDisplayLoader('Hide'))
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoader(false);
        dispatch(setDisplayLoader('Hide'))
        setShowLoader(false)
        if (err.response.data.code == 409) {
          toast.error("Pop Plan already exists!");
          setTimeout(() => {
            navigate("/myplan/popplanner");
          }, 1000);
        }
      });
    if (params.get("mode") !== "create") {
      setShowLoader(true)
      getPopItemApproval(params.get("id"))
        .then((res) => {
          setShowLoader(false)
          setApprovalList(res.data.dataList)
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err)
        });
    }
    setShowLoader(true)
    getMonthDropdown()
      .then((res) => {
        setShowLoader(false)
        setMonths(res.data.dataList);
      })
      .catch((err) => {
        setShowLoader(false)
        console.log(err);
      });
  }, []);

  const generateHeaderData = (isEditable) => {
    let headData = [
      {
        name: "Crop / POP",
        type: "D",
        isEditable: isEditable,
      },
      {
        name: "Language",
        type: "D",
        isEditable: isEditable,
      },
      {
        name: "POP Item",
        type: "D",
        isEditable: isEditable,
      },
      {
        name: "Product",
        type: "D",
        isEditable: isEditable,
      },
    ];
    months?.map((ele) => {
      headData.push({
        name: ele.name,
        type: "N",
        isEditable: isEditable,
      });
    });
    headData.push({
      name: "Total",
      type: "N",
      isEditable: isEditable,
    });
    return headData;
  };

  const generateHeaderViewData = (isEditable) => {
    let headData = [
      {
        name: "Crop / POP",
        type: "T",
        isEditable: isEditable,
      },
      {
        name: "Language",
        type: "T",
        isEditable: isEditable,
      },
      {
        name: "POP Item",
        type: "T",
        isEditable: isEditable,
      },
      {
        name: "Product",
        type: "T",
        isEditable: isEditable,
      },
    ];
    months?.map((ele) => {
      headData.push({
        name: ele.name,
        type: "N",
        isEditable: isEditable,
      });
    });
    headData.push({
      name: "Total",
      type: "N",
      isEditable: isEditable,
    });
    return headData;
  };

  useEffect(() => {
    if (!cropData) return;
    if (mode == "create") {
      let headData = generateHeaderData(true);
      setTableHeadData(headData);
      addRow();
    }
  }, [mode, cropData]);
  //Set table data after past data is fetched
  useEffect(() => {
    if (!mode || mode == "create") return;

    setTableHeadData((state) => {
      if (!rawData) return [];
      let headData;

      if (mode != "view") headData = generateHeaderData(true);

      if (mode == "view" || mode == "approve")
        headData = generateHeaderViewData(false);

      return headData;
    });
    let rawStrategyData = rawData?.pop_data;
    console.log("RAW", rawStrategyData);
    const newTableData = [];
    if (mode == "edit") {
      for (let i = 0; i < rawStrategyData?.length; i++) {
        getLanguageDropdown(rawStrategyData[i].crop_id)
          .then((res) => {
            let languageData = [];
            res.data.dataList.map((ele) => {
              languageData.push({
                name: ele.language_name,
                id: ele.language_id,
                code: ele.language_code,
              });
            });
            getPopItemDropdown(
              rawStrategyData[i].crop_id,
              rawStrategyData[i]?.language_id
            )
              .then((popResponse) => {
                let popData = [];
                popResponse.data.dataList.map((ele) => {
                  popData.push({
                    name: ele.pop_item_name,
                    id: ele.pop_item_id,
                    code: ele.pop_item_code,
                  });
                });
                getProductItemDropdown(
                  rawStrategyData[i].crop_id,
                  rawStrategyData[i]?.language_id,
                  rawStrategyData[i]?.item_id
                )
                  .then((productRes) => {
                    let productData = [];
                    productRes.data.dataList.map((ele) => {
                      productData.push({
                        name: ele.company_product_name,
                        id: ele.company_product_id,
                        code: ele.company_product_code,
                      });
                    });
                    let rowData = [];
                    rowData.push(
                      {
                        value:
                          cropData.length > 0
                            ? cropData?.map((el) => el.name)
                            : [],
                        meta: {
                          raw: cropData,
                        },
                        selected: rawStrategyData[i].crop_name,
                      },
                      {
                        value:
                          languageData.length > 0
                            ? languageData?.map((el) => el.name)
                            : [],
                        meta: {
                          raw: languageData,
                        },
                        selected: rawStrategyData[i].language_name,
                      },
                      {
                        value:
                          popData.length > 0
                            ? popData?.map((el) => el.name)
                            : [],
                        meta: {
                          raw: popData,
                        },
                        selected: rawStrategyData[i].item_name,
                      },
                      {
                        value:
                          productData.length > 0
                            ? productData?.map((el) => el.name)
                            : [],
                        meta: {
                          raw: productData,
                        },
                        selected: rawStrategyData[i].product_name,
                      }
                    );
                    let total = 0;
                    months?.map((ele, monthIndex) => {
                      total += parseInt(
                        rawStrategyData[i].month_data[monthIndex].value
                      );
                      rowData.push({
                        value: [
                          rawStrategyData[i].month_data[monthIndex].value,
                        ],
                        meta: {},
                      });
                    });
                    rowData.push({
                      value: [total],
                      meta: {},
                    });
                    newTableData.push(rowData);
                    if (i == rawStrategyData?.length - 1) {
                      setTableData(newTableData);
                      setTableRowReRender((prev) => !prev);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
        // newTableData.push(
        //     [{
        //         value: objective.length > 0 ? objective?.map(el => el.name) : [],
        //         meta: {
        //             raw: objective
        //         },
        //         selected: rawStrategyData[i]?.objective_name
        //     },
        //     {
        //         value: [rawStrategyData[i]?.strategy],
        //         meta: {}
        //     },
        //     {
        //         value: [rawStrategyData[i]?.milestone],
        //         meta: {}
        //     },
        //     {
        //         value: months.length > 0 ? months?.map(el => el.name) : [],
        //         meta: {
        //             raw: months
        //         },
        //         selected: rawStrategyData[i]?.month_name
        //     }])
      }
    } else if (mode == "view" || mode == "approve") {
      for (let i = 0; i < rawStrategyData?.length; i++) {
        let rowData = [];
        rowData.push(
          {
            value: [rawStrategyData[i].crop_name],
            meta: {
              raw: [rawStrategyData[i].crop_name],
            },
            selected: rawStrategyData[i].crop_name,
          },
          {
            value: [rawStrategyData[i].language_name],
            meta: {
              raw: [rawStrategyData[i].language_name],
            },
            selected: rawStrategyData[i].language_name,
          },
          {
            value: [rawStrategyData[i].item_name],
            meta: {
              raw: [rawStrategyData[i].item_name],
            },
            selected: rawStrategyData[i].item_name,
          },
          {
            value: [rawStrategyData[i].product_name],
            meta: {
              raw: [rawStrategyData[i].product_name],
            },
            selected: rawStrategyData[i].product_name,
          }
        );
        let total = 0;
        months?.map((ele, monthIndex) => {
          total += parseInt(rawStrategyData[i].month_data[monthIndex].value);
          rowData.push({
            value: [rawStrategyData[i].month_data[monthIndex].value],
            meta: {},
          });
        });
        rowData.push({
          value: [total],
          meta: {},
        });
        newTableData.push(rowData);
        if (i == rawStrategyData?.length - 1) {
          setTableData(newTableData);
          setTableRowReRender((prev) => !prev);
        }
      }
    }
  }, [rawData, objective, mode, cropData]);

  const addRow = () => {
    if (cropData.length == 0) return;
    let rowData = tableData;
    rowData.push([
      {
        value:
          cropData.length > 0
            ? [...cropData?.map((el) => el.name), "None"]
            : [],
        meta: {
          raw: cropData,
        },
        selected: "None",
      },
      {
        value: [],
        meta: {
          raw: {},
        },
        selected: "",
      },
      {
        value: [],
        meta: {
          raw: {},
        },
        selected: "",
      },
      {
        value: [],
        meta: {
          raw: {},
        },
        selected: "",
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
      {
        value: [0],
        meta: {},
      },
    ]);

    setTableData(rowData);
    setTableRowReRender((prev) => !prev);
  };

  const deleteRow = (index) => {
    const tableRowData = tableData;
    tableRowData.splice(index, 1);
    setTableData(tableRowData);
    setTableRowReRender((prev) => !prev);
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;
    let filteredData = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i][0].selected != "None") {
        filteredData.push(data[i]);
      }
    }
    let combinationId = [];
    filteredData.forEach((row) => {
      let monthData = [];
      months.map((ele, i) => {
        monthData.push({
          month_id: ele.id,
          value: row[4 + i].value[0] == "" ? "0" : row[4 + i].value[0],
        });
      });
      ans.push({
        crop_id: row[0].meta.raw.find((el) => el.name == row[0].selected).id,
        language_id: row[1].meta.raw.find((el) => el.name == row[1].selected)
          .id,
        item_id: row[2].meta.raw.find((el) => el.name == row[2].selected).id,
        product_id: row[3].meta.raw.find((el) => el.name == row[3].selected).id,
        month_data: monthData,
      });
      combinationId.push(
        `${row[0].meta.raw.find((el) => el.name == row[0].selected).id}${row[1].meta.raw.find((el) => el.name == row[1].selected).id
        }${row[2].meta.raw.find((el) => el.name == row[2].selected).id}${row[3].meta.raw.find((el) => el.name == row[3].selected).id
        }`
      );
    });

    console.log("POP", ans);

    if (checkIfArrayIsUnique(combinationId)) {
      return ans;
    } else {
      return [];
    }
  };

  function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
  }

  useEffect(() => {
    getCurrentFY()
      .then((res) => {
        setCurrentfy(res.data.dataList);
        // console.log(res,"Result")
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    var cfy = -1;
    let territory_id = 0;
    if (mode == "create") {
      dispatch(setDisplayLoader('Display'))
      getCurrentFY()
        .then((res) => {
          let body = {
            cfy: res.data.dataList.fy,
            territory_id,
            status: "D",
          };
          let pop_data = convertCategoryData(updatedTableData);

          if (pop_data.length === 0) {
            toast.error("Please remove same sequence");
            return;
          }
          body.pop_data = pop_data;

          postPopItem(body)
            .then((res) => {
              dispatch(setDisplayLoader('Hide'))
              toast.success("Save Succesfull!");
              setTimeout(() => {
                navigate("/myplan/popplanner");
              }, 1000);
            })
            .catch((err) => {
              dispatch(setDisplayLoader('Hide'))
              toast.error("Save Failed!");
              console.log(err);
            });
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err);
        });
      return;
    }

    cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);

    // if(mode=="edit")
    cfy = rawData?.cfy;
    const pop_data = convertCategoryData(updatedTableData);
    if (pop_data.length === 0) {
      toast.error("Please remove same sequence");
      return;
    }
    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    if (!pop_data) return;
    body.pop_data = pop_data;

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      dispatch(setDisplayLoader('Display'))
      putCropPopPlanner(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/popplanner");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          toast.error("Save Failed!");
          console.log(err);
        });
    }
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      pop_trans_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postPopItemAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  const confirmDialog = (type) => {
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onSubmitHandler = (event) => {
    var cfy = -1;
    let territory_id = 0;
    if (mode == "create") {
      dispatch(setDisplayLoader('Display'))
      getCurrentFY()
        .then((res) => {
          let body = {
            cfy: res.data.dataList.fy,
            territory_id,
            status: "P",
          };
          let pop_data = convertCategoryData(updatedTableData);
          if (pop_data.length === 0) {
            toast.error("Please remove same sequence");
            return;
          }
          body.pop_data = pop_data;

          postPopItem(body)
            .then((res) => {
              dispatch(setDisplayLoader('Hide'))
              toast.success("Submit Succesfull!");
              setTimeout(() => {
                navigate("/myplan/popplanner");
              }, 1000);
            })
            .catch((err) => {
              dispatch(setDisplayLoader('Hide'))
              toast.error("Submit Failed!");
              console.log(err);
            });
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err);
        });
      return;
    }

    cfy = rawData?.cfy.split("-")[1];
    if (cfy.length !== 4) return;

    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);

    // if(mode=="edit")
    cfy = rawData?.cfy;
    const pop_data = convertCategoryData(updatedTableData);
    if (pop_data.length === 0) {
      toast.error("Please remove same sequence");
      return;
    }
    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    if (!pop_data) return;
    body.pop_data = pop_data;

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      dispatch(setDisplayLoader('Display'))
      putCropPopPlanner(body)
        .then((res) => {
          dispatch(setDisplayLoader('Hide'))
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/popplanner");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader('Hide'))
          toast.error("Save Failed!");
          console.log(err);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/popplanner");
  };
  // (function () {
  //   if (loader) {
  //     dispatch(setDisplayLoader("Display"));
  //   } else {
  //     dispatch(setDisplayLoader("Hide"));
  //   }
  // })();
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crop Pattern</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}

        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Pop Plan
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            QTY IN NO's
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{currentfy?.fy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            {locationData?.region_name && (
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>REGION</b>
                </div>
                <div className={styles["valuebox"]}>
                  {locationData?.region_name}
                </div>
              </div>
            )}
            {locationData?.territory_name && (
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>TERRITORY</b>
                </div>
                <div className={styles["valuebox"]}>
                  {locationData?.territory_name}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
              addRow={addRow}
              deleteRow={deleteRow}
              tableRowReRender={tableRowReRender}
              mode={mode}
              showTotal={true}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  confirmDialog("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Crops",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Season",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Crop Start Date",
//         type:"D",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2025-26",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"Estimated Irrigated % 2026-27",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2026-27",
//         type:"N",
//         isEditable:true,
//     },
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             },
//         },
//         {
//             value:['Crop 1','Crop 2','Crop 3','Crop 4'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:['Season 1','Season 2','Season 3','Season 4'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:['16-Jul','23-Jul','1-Aug','8-Aug'],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ]
// ]
