import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { Breadcrumb, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import * as VendorService from "../../service/cashbookService";
import AddCashBookVendor from "./AddCashBookVendor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUrlsCashbook from "./AllUrlsCashbook";
import * as moment from "moment";
import AddCashBookDemand from "./AddCashbookDemand.";

const ViewCashbookDemand = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  {
    console.log(viewFormData, "viewFormData");
  }
  return (
    <div>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cash Demand Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row viewformaftersubmit">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Request No</label>
                <p>PICASHDE{viewFormData?.id}</p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook</label>
                <p>
                  {viewFormData?.cashbook_desc} - {viewFormData?.state_name}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Request Date</label>
                <p> {moment(viewFormData?.created_at).format("DD-MM-YYYY")}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Transfer Request</label>
                <p>INR 14999</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Name</label>
                <p>{viewFormData?.created_by}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Beneficiary Code</label>
                <p>{viewFormData?.beneficiary_user_name}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Payable Branch</label>
                <p>RAIPU</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Indentification Doc. Type</label>
                <p>
                  {viewFormData?.doc_type == "P"
                    ? "Pancard"
                    : viewFormData?.doc_type == "A"
                    ? "Adhar Card"
                    : "Driving Licence"}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Indentification Doc. No</label>
                <p>{viewFormData?.doc_no}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Remarks</label>
                <p>{viewFormData?.remarks}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>SAP Remarks</label>
                <p>{viewFormData?.sap_message}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-secondary" onClick={onViewClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewCashbookDemand;
