import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import TravelBackDateEntry from "./TravelBackdateEntry";

function BackDateListing(props) {
  const { backDateData, bhr } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [editValue, setEditValue] = useState(false);
  const userDetails = useSelector(selectUserData);
  const [backDateDataNew, setBackDateDataNew] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [editValueData, setEditValueData] = useState();
  const [filterTravel, setFilterTravel] = useState({
    from_date: "",
    to_date: "",
    opened_from: "",
  });

  // const getBackDateList = async (data) => {
  //   await TravelListingAPI.getBackDateList(data)
  //     .then((response) => {
  //       setTravelList(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const getUserDetails = async () => {
    await TravelListingAPI.getUserDetails()
      .then((response) => {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getBackDateList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        "",
        userDetails?.id
      );
      setSearchStr(e.target.value);
    }
  };
  const getBackDateList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date,
    opened_from,
    created_by_id
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getBackDateList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        from_date,
        to_date,
        opened_from,
        created_by_id
      ) + `&check_type=travel`
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setBackDateDataNew(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBackDateList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      userDetails?.id
    );
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date,
    opened_from,
    created_by_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (
      opened_from !== "" &&
      opened_from !== undefined &&
      opened_from !== null
    ) {
      queryParm = queryParm + "&opened_from=" + opened_from;
    }

    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (
      created_by_id !== "" &&
      created_by_id !== undefined &&
      created_by_id !== null
    ) {
      queryParm = queryParm + "&created_by_id=" + created_by_id;
    }

    return queryParm;
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getBackDateList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        "",
        userDetails?.id
      );
    }
  };
  const filterClose = () => {
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterTravel));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterTravel(ClearData);
    filterClose();
    getBackDateList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      userDetails?.id
    );
  };
  function scrollWin() {
    window.scrollTo(0, 100);
  }
  const editData = (data) => {
    setEditValueData(data);
    setEditValue(true);
    scrollWin();
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterTravel.from_date;
    const to_date = filterTravel.to_date;
    const opened_from = filterTravel.opened_from;

    getBackDateList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      from_date,
      to_date,
      opened_from,
      userDetails?.id
    );
    filterClose();
  };
  const handleCallBack = () => {
    getBackDateList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      userDetails?.id
    );
  };
  useEffect(() => {
    getUserDetails();
    getBackDateList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      userDetails?.id
    );
  }, [pageSizeNo]);

  return (
    <>
      <TravelBackDateEntry
        editValueData={editValueData}
        editValue={editValue}
        setEditValue={setEditValue}
        handleCallBack={handleCallBack}
        bhr={bhr}
      />
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <div class="col-md-6" style={{ float: "left" }}>
                    {" "}
                    <h5 class="inner-page-title">
                      Back Date Entry Exception List
                    </h5>
                  </div>
                  <div
                    class="col-md-6"
                    style={{
                      fontSize: "10px",
                      color: "grey",
                      float: "right",
                    }}
                  ></div>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color:
                          filterApplied &&
                          userDetails?.dark_mode === false &&
                          "#000",
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured travelintim">
                <thead>
                  <tr>
                    <th>Emp Code</th>
                    <th>Emp Name</th>
                    <th>Period Open From</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Created At</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {backDateDataNew?.length ? (
                    backDateDataNew?.map((data, index) => (
                      <tr>
                        <td>{data?.user_code ?? "-"}</td>
                        <td> {data?.user_name ?? "-"}</td>
                        <td>
                          {moment(data?.opened_from).format("DD-MM-YYYY")}
                        </td>
                        <td>{moment(data?.from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(data?.to_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                        <td>{data?.created_by_user_name}</td>
                        <td>
                          {data?.is_active === true ? (
                            <button
                              class="btn btn-blanktd text-primary"
                              onClick={() => {
                                editData(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={14}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {backDateDataNew?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    key={pageSizeNo}
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div class="form-group innergroup">
                <label>
                  Employee Name<span class="text-danger">*</span>
                </label>
                <Select
                  options={userDataNew}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="user_id"
                  onChange={(e) => {
                    pushEmployee(e);
                  }}
                  value={userValue}
                />
              </div> */}

          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) =>
                setFilterTravel({
                  ...filterTravel,
                  from_date: e.target.value,
                })
              }
              value={filterTravel.from_date}
            />
          </div>
          {/* 
              <div class="form-group innergroup">
                <label>
                  Travel Type<span class="text-danger">*</span>
                </label>
                <select
                  class="form-control newbgselect"
                  placeholder="Select Travel Type"
                >
                  <option>Select</option>
                  <option>On Duty</option>
                  <option>Domestic</option>
                  <option>International</option>
                </select>
              </div> */}

          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              type="date"
              class="form-control"
              name="to_date"
              onChange={(e) =>
                setFilterTravel({
                  ...filterTravel,
                  to_date: e.target.value,
                })
              }
              value={filterTravel.to_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>Open From</label>
            <input
              type="date"
              class="form-control"
              name="opened_from"
              onChange={(e) =>
                setFilterTravel({
                  ...filterTravel,
                  opened_from: e.target.value,
                })
              }
              value={filterTravel.opened_from}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => clearFilter()}>
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 text-center">
                  <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                  <p class="fa-20 text-muted mb-4">
                    You are generating SAP Trip No, After this you will not be
                    able to change tour
                  </p>

                  <div class="mb-3">
                    <Link to="/claim-listing" class="mr-2">
                      <button class="btn btn-primary-inner bpi-main f-14 py-1">
                        Yes
                      </button>
                    </Link>
                    <button
                      class="btn btn-outline-primary f-14 py-1"
                      onClick={addnewclose}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BackDateListing;
