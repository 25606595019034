import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../../assets/images/logo.png";
import {
  Changeuserstaus,
  deleteattachment,
  getbl,
  uploadGlFile,
} from "../../service/glService";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { uploadFile } from "../../service/brandService";
import { confirmAlert } from "react-confirm-alert";
import { getCurrencyList, uploadImages } from "../../service/jv_service";
function ApproveGI() {
  const userData = useSelector(selectUserData);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);

  const [userEditData, setUserEditData] = useState({
    balance: 0,
    fc_amount: 0,
    currency: { value: "", label: "" },
  });
  const [attachmentid, setAttachmentid] = useState([]);

  const [error, seterror] = useState({
    balance: false,
    fc_amount: false,
    currency: false,
  });
  const [BLData, setGLData] = useState([]);
  const navigate = useNavigate();
  const [attachment, setAttachment] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [errorremarks, setErrorRemarks] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [Errorattachment, setErrorattachment] = useState(false);
  const getCurrencyListss = () => {
    getCurrencyList()
      .then((response) => {
        // setCurrencyList(response?.data?.dataList?.result);
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.description + "(" + x?.code + ")",
          };
          glcode.push(data);
        });
        setCurrencyList(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getBL = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    search,
    status,
    glcode,
    level,
    user_id,
    blcode,
    month,
    year,
    approvers
  ) => {
    getbl(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      search,
      status,
      glcode,
      level,
      user_id,
      blcode,
      month,
      year,
      approvers
    )
      .then((response) => {
        let x = response?.data?.dataList?.result[0];
        x.currency = {
          value: x?.currency,
          label: x?.cureency_desc + "(" + x?.cureency_code + ")",
        };
        setGLData(x);
        setDataCount(response?.data?.dataList?.paginated_data?.approval_count);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    console.log(type, event);
    switch (type) {
      case "balance":
        setUserEditData((prev) => ({
          ...prev,
          balance: event.target.value,
        }));
        seterror((prev) => ({
          ...prev,
          balance: false,
        }));
        break;
      case "fc_amount":
        setUserEditData((prev) => ({
          ...prev,
          fc_amount: event.target.value,
        }));
        seterror((prev) => ({
          ...prev,
          fc_amount: false,
        }));
        break;
      case "currency":
        setUserEditData((prev) => ({
          ...prev,
          currency: event,
        }));
        seterror((prev) => ({
          ...prev,
          currency: false,
        }));
        break;
      default:
    }
  };
  useEffect(() => {
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      0,
      "",
      0,
      id,
      "",
      "",
      0
    );
    getCurrencyListss();
  }, []);
  const uploadGl = () => {
    let data = { attachment: uploadDataIm, id: BLData?.id };
    console.log(uploadDataIm.length);
    if (uploadDataIm.length != 0) {
      uploadGlFile(data)
        .then((response) => {
          setUploadDataIm([]);
          setAttachment([]);
          setErrorattachment(false);
          document.getElementById("approverattachment").value = "";
          getBL(
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            "",
            0,
            "",
            0,
            id,
            "",
            "",
            0
          );
          console.log(response);
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      toast.warning("Kindly select a file to upload");
    }
  };
  const submit = (status) => {
    console.log(BLData.level);
    userEditData.By = BLData.level;
    userEditData.status = status;
    userEditData.remarks = remarks;
    userEditData.attachmentid = attachmentid;
    
    let flag = false;
    if (BLData.level == "User") {
      console.log(flag, userEditData);
      userEditData.id = id;
      if (
        userEditData.balance !== 0 &&
        // userEditData.fc_amount !== 0 &&
        userEditData.currency.value !== "" &&
        attachmentid.length != 0
      ) {
        flag = true;
      }
    }
    if (BLData.level == "Reviewer") {
      userEditData.idList = [id];
      if (remarks !== "") {
        flag = true;
      }
    }
    if (flag) {
      Changeuserstaus(userEditData)
        .then((response) => {
          getBL(
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            "",
            0,
            "",
            0,
            id,
            "",
            "",
            0
          );
          if (status == "A") {
            toast.success("GL Approved");
          } else {
            toast.warning("GL Rejected");
          }
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      if (BLData.level == "User") {
        seterror((prev) => ({
          ...prev,
          balance: userEditData.balance === 0 ? true : false,
          // fc_amount: userEditData.fc_amount === 0 ? true : false,
          currency: userEditData.currency.value === "" ? true : false,
        }));
        setErrorattachment(attachmentid.length == 0 ? true : false);
      }
      if (BLData.level == "Reviewer") {
        setErrorRemarks(remarks == "" ? true : false);
      }
    }
  };
  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }
    setErrorattachment(false);
    setAttachment(filename);
    setUploadDataIm(files);
  };
  const deleteAttachment = (attachmentid) => {
    deleteattachment(attachmentid)
      .then((response) => {
        getBL(
          currentPage,
          pageSizeNo,
          "-id",
          true,
          "",
          "",
          0,
          "",
          0,
          id,
          "",
          "",
          0
        );
        toast.success("Attachment deleted successfully");
      })
      .catch((error) => {
        toast.error("Some error occoured try gain later");

        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: "Delete Attachment",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteAttachment(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleAllData = (e, id) => {
    if (e.target.checked) {
      setAttachmentid([...attachmentid, id]);
    } else {
      const index = attachmentid.indexOf(id);
      if (index > -1) {
        let data = attachmentid;
        data.splice(index, 1);
        setAttachmentid(data);
      }
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>GL Balancesheet Approval</Link>{" "}
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View GL: {BLData?.id}</h3>
                </div>
              </div>
              <div class="bg-light px-3 pt-2 mb-3">
                <div class="row viewformaftersubmit">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>GL Code</label>
                      <p>
                        {BLData?.gl_code} - {BLData?.Gl_desc}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Sap Location</label>
                      <p>{BLData?.saplocation}</p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Type</label>
                      <p>{BLData?.type}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Name of Group</label>
                      <p>{BLData?.name_of_group}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Sap Amount</label>
                      <p>{BLData?.sap_amount}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Remarks</label>
                      <p>{BLData?.remarks}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Schedule Balance<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      value={
                        BLData?.level == "User"
                          ? userEditData?.balance
                          : BLData?.balance
                      }
                      class="form-control"
                      // value="0.00"
                      disabled={BLData?.level == "User" ? false : true}
                      name="balance"
                      placeholder="Enter Schedule Balance"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <div hidden={!error?.balance} className="small text-danger">
                      Kindly enter balance
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Currency<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={currencyList}
                      className="basic-multi-select"
                      value={
                        BLData?.level == "User"
                          ? userEditData?.currency
                          : BLData?.currency
                      }
                      isDisabled={BLData?.level == "User" ? false : true}
                      name="currency"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!error?.currency}
                      className="small text-danger"
                    >
                      Kindly select currency
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Foreign Currency Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      disabled={BLData?.level == "User" ? false : true}
                      type="number"
                      class="form-control"
                      value={
                        BLData?.level == "User"
                          ? userEditData?.fc_amount
                          : BLData?.fc_amount
                      }
                      placeholder="Enter Foreign Currency Amount "
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="fc_amount"
                    />
                    <div
                      hidden={!error?.fc_amount}
                      className="small text-danger"
                    >
                      Kindly enter foreign currency amount
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        {BLData?.level == "User" && BLData?.status == "P" && (
                          <th>Select</th>
                        )}

                        <th>S.No</th>
                        <th>File Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {BLData?.attachment?.map((a) => (
                        <tr>
                          {BLData?.level == "User" && BLData?.status == "P" && (
                            <td>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    handleAllData(e, a?.id);
                                  }}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </td>
                          )}
                          <td>{a?.id}</td>
                          <td class="text-theme fw-bold">
                            {a?.attachment_path?.split("/")[5]?.split("?")[0]}
                          </td>
                          <td>
                            <a
                              href={a?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src="images/eye.png" />
                            </a>
                            {BLData?.user_username == userData.username &&
                              BLData?.level == "User" &&
                              BLData?.id == a?.GlSignoff_id && (
                                <button
                                  onClick={() => handleDelete(a?.id)}
                                  class="btn btn-blanktd text-danger ml-2"
                                >
                                  <i class="far fa-trash-alt text-danger"></i>
                                </button>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {Errorattachment && (
                    <div className="small text-danger">
                      *Attachment is required
                    </div>
                  )}
                </div>
              </div>
            </div>

            {BLData?.user_username == userData.username &&
              BLData?.level == "User" && (
                <div class="col-md-6">
                  <div class="form-group innergroup position-relative modaldefaultclose ">
                    <label>Upload files</label>
                    <input
                      type="text"
                      class="form-control bg-white"
                      disabled
                      placeholder="(Image, PDF, Excel Format)"
                    />
                    <div class="upload-btn-wrapper up-loposition">
                      <button class="uploadBtn">Browse</button>
                      <input
                        id="approverattachment"
                        onChange={(e) => onProfileChange(e, "attachment_path")}
                        multiple
                        name="attachment_path"
                        accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                        type="file"
                      />
                    </div>

                    <p>
                      Selected files{" "}
                      {attachment.map((a) => (
                        <span>
                          <br />
                          {a.name}
                        </span>
                      ))}
                    </p>
                    <div class="d-flex">
                      <button
                        onClick={() => uploadGl()}
                        class="btn btn-primary-inner bpi-main"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              )}

            {BLData?.level !== "User" &&
              BLData?.Reviewer == userData.username && (
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Add Comments<span class="text-danger">*</span>
                    </label>
                    <textarea
                      value={remarks}
                      onChange={(e) => {
                        setErrorRemarks(false);
                        setRemarks(e.target.value);
                      }}
                      disabled={BLData.status !== "P" ? true : false}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    <div hidden={!errorremarks} className="small text-danger">
                      Kindly enter comments
                    </div>
                  </div>
                </div>
              )}

            <div class="col-md-12 pb-3">
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() => {
                  window.history.go(-1);
                  return false;
                }}
              >
                <i class="fas fa-arrow-circle-left"></i> Cancel
              </button>
              {BLData?.status == "P" && (
                <>
                  {BLData?.level !== "User" &&
                    BLData?.Reviewer == userData.username && (
                      <button
                        onClick={() => {
                          submit("R");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>
                    )}
                  {((BLData?.Reviewer == userData.username &&
                    BLData?.level !== "User") ||
                    (BLData?.level == "User" &&
                      BLData?.user_username == userData.username)) && (
                    <button
                      onClick={() => {
                        submit("A");
                      }}
                      class="btn btn-primary-inner bpi-main"
                    >
                      <i class="far fa-check-square"></i> Approve
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveGI;
