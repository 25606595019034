import React, { useState, useEffect } from "react";
import Select from "react-select";
import * as TravelListingAPI from "../../service/travelService";
import ExpenseListing from "./ExpenseListing";
import { selectUserData } from "../redux/piDataStore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Spinner, Alert, NavItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { setDisplayLoader } from "../redux/piDataStore";
import { Modal } from "react-bootstrap";
import { isEmptyArray } from "formik";

const DA = (props) => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [indexs, setIndexs] = useState(0);
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const { checkActiveTab, setCheckActiveTab, dataNew } = props;
  const [daData, setDaData] = useState();
  const [totalData, setTotalData] = useState([]);
  const [daDataList, setDaDataList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [findData, setFindData] = useState([]);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [flag, setflag] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [amt, setAmt] = useState(false);
  const [gradeAndLocate, setGradeAndLocate] = useState(false);
  const [billData, setBillData] = useState([
    {
      bill_date: "",
      invoice_number: "",
      bill_amount: "",
    },
  ]);
  const [billDetail, setBillDetail] = useState([
    [
      {
        bill_date: "",
        invoice_number: "",
        bill_amount: "",
      },
    ],
  ]);
  const [formValidation, setFormValidation] = useState({
    invoice_number: "",
    bill_amount: "",
  });
  const [gradeAmt, setGradeAmt] = useState(0);
  const [visible, setVisible] = useState(false);
  const option = [
    { value: "No DA Claim", label: "No DA Claim", per: 0 },
    {
      value: "Guest House",
      label: "All Food Provided by the company",
      per: 20,
    },
    {
      value: "Lunch and Dinner provided by the company",
      label: "Lunch and Dinner provided by the company",
      per: 40,
    },
    {
      value: "Breakfast and Lunch/Dinner provided by the company",
      label: "Breakfast and Lunch/Dinner provided by the company",
      per: 50,
    },
    {
      value: "Lunch/Dinner provided by the company",
      label: "Lunch/Dinner provided by the company",
      per: 70,
    },
    {
      value: "Breakfast provided by the company",
      label: "Breakfast provided by the company",
      per: 80,
    },
    {
      value: "All food expenses paid by the employee",
      label: "All food expenses paid by the employee",
      per: 100,
    },
  ];
  const [daList, setDaList] = useState([]);
  const [shouldDeleteAttachment, setShouldDleteAttachment] = useState(true);

  const giveRemarkValue = (dropData, remark) => {
    let per = 0;
    if (remark != null) {
      dropData.map((x) => {
        if (x?.label == remark) {
          per = x?.value;
        }
      });
    } else {
      dropData.map((x) => {
        if (x?.label == "All food expenses paid by the employee") {
          per = x?.value;
        }
      });
    }
    return per;
  };
  function handleLoading() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
      if (daDataList?.length == 0) {
        return <div></div>;
      }
    }
  }
  const getDaDataEdit = (travel_id) => {
    TravelListingAPI.getDaDataList(travel_id)
      .then((response) => {
        setLoader(false);
        let data = response?.data?.dataList;
        let res = response?.data?.dataList?.result;
        setDaList(res);
        // if (response?.data?.dataList?.result?.length === 0) {
        //   getDaDataNew(state?.id);
        // }
        setAttachment(response?.data?.dataList?.attachment_path);
        setUploadDataIm([]);
        setfiles([]);
        let timeCalcN = data?.result?.map((dd, i) => {
          let tt = dd?.hours;
          dd.SelectedKey = [
            {
              value: 50,
              label: "All food expenses paid by the employee",
            },
          ];
          dd.dropDownKey = [{ value: 0, label: "No DA Claim" }];

          if (tt > 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim" },
              {
                value: 20,
                label: "All Food Provided by the company",
              },
              {
                value: 40,
                label: "Lunch and Dinner provided by the company",
              },
              {
                value: 50,
                label: "Breakfast and Lunch/Dinner provided by the company",
              },
              {
                value: 70,
                label: "Lunch/Dinner provided by the company",
              },
              {
                value: 80,
                label: "Breakfast provided by the company",
              },
              {
                value: 100,
                label: "All food expenses paid by the employee",
              },
            ];
          } else if (tt > 8 && tt <= 16) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim" },
              {
                value: 50,
                label: "All food expenses paid by the employee",
              },
            ];
          } else if (tt > 4 && tt <= 8) {
            dd.dropDownKey = [
              { value: 0, label: "No DA Claim" },
              {
                value: 30,
                label: "All food expenses paid by the employee",
              },
            ];
          } else if (tt < 4) {
            dd.SelectedKey = [{ value: 0, label: "No DA Claim" }];
          }
          return dd;
        });
        setFindData(data?.result);
        // setDaDataListEdit(daDataListEdit);
        if (data.result.length != 0) {
          timeCalcN.map((x, index) => {
            let data_new = {
              value: giveRemarkValue(x?.dropDownKey, x?.remarks),
              label:
                x?.remarks === null
                  ? "All food expenses paid by the employee"
                  : x?.remarks,
            };
            x.SelectedKey = data_new;
            // x.entitlement =
            //   x.gross_total == 0 && x?.remarks !== "No DA Claim"
            //     ? x.entitlement
            //     : x.gross_total;
            x.amt = calculateTotalAmt2(data_new, x.entitlement);
          });
          setDaDataList(timeCalcN);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const calculateTotalAmt2 = (e, entitlement) => {
    // return (Number(entitlement) * Number(e.value)) / 100;
    if (e !== undefined) {
      if (e.label === "Headquarter DA") {
        return entitlement;
      } else {
        return (Number(entitlement) * Number(e.value)) / 100;
      }
    }
    // else if(e !== undefined && e?.label === 'Headquarter DA')
    // {
    //   let ttData = JSON.parse(JSON.stringify(daDataList));
    //   return (Number(entitlement) * Number(e.value)) / 100;
    // }
  };
  // const getDaDataNew = async (data) => {
  //   await TravelListingAPI.getDaData(data)
  //     .then((response) => {
  //       let res = response?.data?.dataList?.result;

  //       getDaDataEdit(state?.id); ///-------------------------
  //       setLoader(false);
  //       setDaList(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // const getDaData = (travel_id) => {
  //   TravelListingAPI.getDaList(travel_id)
  //     .then((response) => {
  //       let data = response?.data?.dataList;
  //       let timeCalc = data?.result?.map((dd, i) => {
  //         let tt = dd?.hours;
  //         dd.SelectedKey = [
  //           {
  //             value: 50,
  //             label: "All food expenses paid by the employee",
  //           },
  //         ];
  //         dd.dropDownKey = [{ value: 0, label: "No DA Claim" }];

  //         if (tt > 16) {
  //           dd.dropDownKey = [
  //             { value: 0, label: "No DA Claim" },
  //             {
  //               value: 20,
  //               label: "All Food Provided by the company",
  //             },
  //             {
  //               value: 40,
  //               label: "Lunch and Dinner provided by the company",
  //             },
  //             {
  //               value: 50,
  //               label: "Breakfast and Lunch/Dinner provided by the company",
  //             },
  //             {
  //               value: 70,
  //               label: "Lunch/Dinner provided by the company",
  //             },
  //             {
  //               value: 80,
  //               label: "Breakfast provided by the company",
  //             },
  //             {
  //               value: 100,
  //               label: "All food expenses paid by the employee",
  //             },
  //           ];
  //         } else if (tt > 8 && tt <= 16) {
  //           dd.dropDownKey = [
  //             { value: 0, label: "No DA Claim" },
  //             {
  //               value: 50,
  //               label: "All food expenses paid by the employee",
  //             },
  //           ];
  //         } else if (tt > 4 && tt <= 8) {
  //           dd.dropDownKey = [
  //             { value: 0, label: "No DA Claim" },
  //             {
  //               value: 30,
  //               label: "All food expenses paid by the employee",
  //             },
  //           ];
  //         }

  //         // else if (tt > 4) {
  //         //   dd.dropDownKey = [
  //         //     { value: 0, label: "No DA Claim" },
  //         //     {
  //         //       value: 30,
  //         //       label: "All food expenses paid by the employee",
  //         //     },
  //         //   ];
  //         // }
  //         return dd;
  //       });

  //       setDaDataList(timeCalc);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const dropDownData = () => {
    let time = [];
    {
      daDataList?.map((dd, i) => {
        time.push(dd?.hours);
      });
    }
    let optionType = time == 0 ? option[0] : "";
  };
  const calcMaxEntitelment = () => {
    let data = JSON.parse(JSON.stringify(daDataList));
    let sum = 0;
    data?.map((dd, i) => {
      sum = dd?.calculated_entitlement + sum;
    });
    setDaData(sum);
  };

  const calculateTotalAmt = (e, i) => {
    if (e !== undefined) {
      let ttData = JSON.parse(JSON.stringify(daDataList));
      // ttData.forEach((fe, i) => {
      //   if (fe.SelectedKey.label === "Headquarter DA") {
      //     fe.amt = fe.entitlement;
      //   } else {
      //     fe.amt = (Number(fe[i]?.entitlement) * Number(e.value)) / 100;
      //   }
      // });

      ttData[i].amt = (Number(ttData[i]?.entitlement) * Number(e.value)) / 100;

      ttData[i].SelectedKey = e;
      setDaDataList(ttData);
    }
  };
  const handleClose = () => {
    setVisible(false);
    setGstError(false);
    setFormValidation({
      invoice_number: "",
      bill_amount: "",
    });
  };
  const onProfileChange = async (e, flag) => {
    if (shouldDeleteAttachment) {
      let delAtt = [...deleteattachment];
      attachment.forEach((obj) => delAtt.push(obj.id));
      setDeleteAttachment(delAtt);
      setShouldDleteAttachment(false);
    }
    let file = e.target.files;
    let tmp_filesToUpload = [...filesToUpload];
    setfiles(file);
    let files = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      tmp_filesToUpload.push(fileData);

      // await TravelListingAPI.uploadFile(fileData).then((response) => {
      //   let name = { name: data.name };
      //   filename.push(name);
      //   files.push(response.data);
      // });
    }
    setFilesToUpload(tmp_filesToUpload);
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1 col-sm-6">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
              <br></br>
              <span>{data2?.name}</span>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    billData?.map((formData, i) => {
      if (!formData.bill_amount) {
        isError = true;
        formerr.bill_amount = "*Bill Amount is Required";
      }

      // else if (formData.bill_amount == 0) {
      //   isError = true;
      //   formerr.bill_amount = "*Bill Amount Cannot be zero";
      // }
      if (!formData.invoice_number) {
        isError = true;
        formerr.invoice_number = "*Invoice No is required";
        setFormValidation(formerr);
      }
    });
    return isError;
  };
  useEffect(() => {
    if (daDataList?.length > 0) {
      if (
        // dataNew[0]?.emp_sub_group === "T1" ||
        dataNew[0]?.emp_sub_group === "C1" ||
        dataNew[0]?.emp_sub_group === "D1" ||
        dataNew[0]?.emp_sub_group === "D2" ||
        dataNew[0]?.is_sf_user === "false"
      ) {
        let value = 0;
        let data = JSON.parse(JSON.stringify(daDataList));
        data.map((tdd, i) => {
          value = value + (tdd?.amt === undefined && tdd?.amt);
          if (tdd?.hours < 16) {
            calculateTotalAmt(tdd?.SelectedKey[0], i);
          }
        });
        setGradeAmt(value);
        // if(billDetail === [])
        // {       setGradeAmt(value);}
        // else{

        // }
      } else {
        let value = 0;
        let data = JSON.parse(JSON.stringify(daDataList));
        data.map((tdd, i) => {
          value =
            value + (tdd?.amt === undefined ? tdd?.entitlement : tdd?.amt);
          if (tdd?.hours < 16) {
            calculateTotalAmt(tdd?.SelectedKey[0], i);
          }
        });
        setGradeAmt(value);
      }
    }
  }, [daDataList]);

  const getDropDown = (opt) => {
    let finalValue = Object.keys(opt).map((obj) => {
      return {
        label: opt[obj],
        value: obj,
      };
    });
    return finalValue;
  };
  const RemoveEmptyobj = () => {
    let fd = JSON.parse(JSON.stringify(billDetail));

    fd.map((x) => {
      x.map((k, index) => {
        if (k?.bill_amount == "") {
          delete x[index];
        }
      });
      x.map((y, index) => {
        if (y?.bill_amount == undefined) {
          x.push([]);
        }
      });
    });
    return fd;
  };
  const saveDa = async (data) => {
    let bill_data = RemoveEmptyobj();

    let files = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      await TravelListingAPI.uploadFile(filesToUpload[afilee])
        .then((response) => {
          // let name = { name: data.name };
          // filename.push(name);
          files.push(response.data);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `, {
            autoClose: 2000,
          });
          return;
        });
    }
    let dataSave = {
      travel_id: state?.id,
      maximum_entitlement: daData,
      total_amount: gradeAmt,
      attachment_path: files,
      attachment_delete: deleteattachment,
      // attachment_path: {},
      da_data: daDataList.map((obj, i) => {
        return {
          da_date: obj?.da_date,
          city_id: obj?.city_id,
          city_input:
            obj?.city_name === "Other" ? obj?.city_input : obj?.city_name,
          hours: obj?.hours,
          remarks:
            obj?.SelectedKey[0]?.label != undefined
              ? obj?.SelectedKey[0]?.label
              : "All Food Provided by the company",
          gross_total:
            dataNew[0]?.emp_sub_group === "C1" ||
            // dataNew[0]?.emp_sub_group === "T1" ||
            // dataNew[0]?.emp_sub_group === "T2" ||
            dataNew[0]?.emp_sub_group === "D1" ||
            dataNew[0]?.emp_sub_group === "D2" ||
            dataNew[0]?.is_sf_user === "false"
              ? bill_data[i] === undefined
                ? obj?.entitlement
                : billAmountSum(i, obj?.entitlement)
              : obj?.amt === undefined
              ? obj?.entitlement
              : obj?.amt,

          // obj?.amt === undefined ? obj?.entitlement : obj?.amt,
          annual_meet: obj?.annual_meet === "" ? "" : obj?.annual_meet,
          territory: obj?.territory,
          hq: obj?.hq,
          entitlement: obj?.entitlement,
          calculated_entitlement: obj?.calculated_entitlement,
          category: obj?.category,
          // bill_data:
          //   dataNew[0]?.emp_sub_group !== "T1" ? billDetail[i] : billDetail[i],
          bill_data:
            dataNew[0]?.emp_sub_group === "C1" ||
            // dataNew[0]?.emp_sub_group === "T1" ||
            // dataNew[0]?.emp_sub_group === "T2" ||
            dataNew[0]?.emp_sub_group === "D1" ||
            dataNew[0]?.emp_sub_group === "D2" ||
            dataNew[0]?.is_sf_user === "false"
              ? bill_data[i] != undefined
                ? check(bill_data[i])
                : []
              : [],
        };
      }),
    };
    TravelListingAPI.updateDa(dataSave, state?.id)
      .then((response) => {
        if (response?.data.code == 200) {
          setCheckActiveTab();
          setDeleteAttachment([]);
          setShouldDleteAttachment(true);
          setFilesToUpload([]);
          getDaDataEdit(state?.id);
          toast.success("DA Added Successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        console.log(
          (error?.response &&
            error?.response?.data &&
            error?.response?.data?.message) ||
            "failed"
        );
      });
  };

  const check = (data) => {
    if (data[0]?.bill_amount == undefined) {
      return [];
    }
    return data;
  };
  const updateDa = async (data) => {
    let datab = billData;
    let bill_data = RemoveEmptyobj();
    let files = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      await TravelListingAPI.uploadFile(filesToUpload[afilee])
        .then((response) => {
          // let name = { name: data.name };
          // filename.push(name);
          files.push(response.data);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `, {
            autoClose: 2000,
          });
          return;
        });
    }

    let dataSave = {
      travel_id: state?.id,
      maximum_entitlement: daData,
      total_amount: gradeAmt,
      attachment_path: files,
      attachment_delete: deleteattachment,
      da_data: daDataList.map((obj, i) => {
        return {
          da_date: obj?.da_date,
          city_id: obj?.city_id,
          city_input:
            obj?.city_name === "Other" ? obj?.city_input : obj?.city_name,
          hours: obj?.hours,
          remarks: obj?.SelectedKey?.label,
          gross_total:
            dataNew[0]?.emp_sub_group === "C1" ||
            // dataNew[0]?.emp_sub_group === "T1" ||
            // dataNew[0]?.emp_sub_group === "T2" ||
            dataNew[0]?.emp_sub_group === "D1" ||
            dataNew[0]?.emp_sub_group === "D2" ||
            dataNew[0]?.is_sf_user === "false"
              ? bill_data[i] === undefined
                ? obj?.entitlement
                : billAmountSum(i, obj?.entitlement)
              : obj?.amt === undefined
              ? obj?.entitlement
              : obj?.amt,

          // obj?.amt === undefined ? obj?.entitlement : obj?.amt,
          annual_meet: obj?.annual_meet === "" ? "" : obj?.annual_meet,
          territory: obj?.territory,
          hq: obj?.hq,
          entitlement: obj?.entitlement,
          calculated_entitlement: obj?.calculated_entitlement,
          category: obj?.category,
          id: obj?.id,
          bill_data:
            dataNew[0]?.emp_sub_group === "C1" ||
            // dataNew[0]?.emp_sub_group === "T1" ||
            // dataNew[0]?.emp_sub_group === "T2" ||
            dataNew[0]?.emp_sub_group === "D1" ||
            dataNew[0]?.emp_sub_group === "D2" ||
            dataNew[0]?.is_sf_user === "false"
              ? bill_data[i] != undefined
                ? check(bill_data[i])
                : []
              : [],
        };
      }),
    };
    TravelListingAPI.updateDa(dataSave, state?.id)
      .then((response) => {
        if (response?.data.code == 200) {
          setCheckActiveTab();
          setDeleteAttachment([]);
          setShouldDleteAttachment(true);
          setFilesToUpload([]);
          getDaDataEdit(state?.id);
          toast.success("DA Updated Successfully");
        }
      })
      .catch((error) => {
        if (error?.response) {
          toast.error(error);
        }
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const changeHandler = (value, name, index) => {
    let fd = JSON.parse(JSON.stringify(billData));
    fd[index][name] = value;
    // billDetail[indexs] = fd;
    setBillData(fd);
  };
  const addMoreBill = (ele, i) => {
    // setAlertForAmount(false);
    const copyValue = JSON.parse(JSON.stringify(billData));

    if (gstError === true) {
    } else {
      if (billData.length < 20) {
        const ele = {};
        ele.bill_date = "";
        ele.invoice_number = "";
        ele.bill_amount = "";
        copyValue.push(ele);
        setBillData(copyValue);
      } else {
        toast.error("max");
      }
    }
  };

  const addBill = (index, data) => {
    setVisible(true);
    setIndexs(index);
    if (billDetail[index] != undefined) {
      setBillData(billDetail[index]);
    } else {
      let data = [
        {
          bill_date: "",
          invoice_number: "",
          bill_amount: "",
        },
      ];
      setBillData(data);
    }
    let element = document.getElementById("setBillDate");
    element.click();
  };

  // useEffect(() => {
  //   getDaDataNew(state?.id);
  // }, []);
  useEffect(() => {
    // getDaList(state?.id);
    dropDownData();
    getDaDataEdit(state?.id);
    // getDaData(state?.id);
  }, []);

  useEffect(() => {
    calcMaxEntitelment();
  }, [daDataList]);

  useEffect(() => {
    let dd = [];
    daList?.map((data, i) => {
      if (data?.bill_data?.length != 0) dd.push(data?.bill_data);
      else dd?.push(billData);
    });
    setBillDetail(dd);
  }, [daList]);
  const setData = () => {
    if (handleValidate()) return false;

    {
      billDetail[indexs] = billData;
      setBillData([
        {
          bill_date: "",
          invoice_number: "",
          bill_amount: "",
        },
      ]);
      billAmountSum2();
      setVisible(false);
    }
  };
  const handleValidateInvoice = (value, index) => {
    let data = value;
    let flag = true;
    billData?.map((item, i) => {
      if (item.invoice_number === data) {
        if (index != i) {
          setGstError(true);
          flag = false;
          return "";
        } //++++++++++++++++
      }
    });
    if (flag) {
      billData?.map((item, i) => {
        if (item.invoice_number !== data) {
          if (index != i) {
            setGstError(false);
            return "";
          } //++++++++++++++++
        }
      });
    }
  };

  const chkAmount = () => {
    let flag = 0;
    let sum = 0;
    billData.map((x, index) => {
      sum = sum + Number(x?.bill_amount);
    });
    if (sum > daDataList[indexs]?.calculated_entitlement) {
      flag = flag + 1;
    }
    if (flag != 0) {
      return true;
    } else {
      return false;
    }
  };
  const billAmountSum = (index, entitlement, entitlement2) => {
    let sum = 0;
    billDetail[index].map((x) => {
      sum = sum + Number(x?.bill_amount);
    });
    //   if (sum > entitlement2) {
    //   return   setAmt(true);
    //  } else {
    //    return  setAmt(false);
    //  }

    // if (sum != 0) {
    //   return sum;
    // } else {
    //   return entitlement;
    // }
    return sum;
  };
  const billAmountSum2 = () => {
    let grandTotal = 0;
    billDetail.map((x) => {
      let sum = 0;
      x.map((j) => {
        sum = sum + Number(j?.bill_amount);
      });
      grandTotal = grandTotal + sum;
    });
    let value = 0;
    let data = JSON.parse(JSON.stringify(daDataList));
    data.map((tdd, i) => {
      value = value + (tdd?.amt === undefined ? 0 : tdd?.amt);
    });
    // setGradeAmt(grandTotal + value);
    setGradeAmt(grandTotal);
  };

  const removeHotel = (idx) => {
    const newExpDetails = billData.filter((item, i) => i !== idx);
    setBillData(newExpDetails);
    setFormValidation({
      invoice_number: "",
      bill_amount: "",
    });
  };
  const removeHotelLast = (idx) => {
    setBillData([
      {
        bill_date: "",
        invoice_number: "",
        bill_amount: "",
      },
    ]);
    setFormValidation({
      invoice_number: "",
      bill_amount: "",
    });
  };
  const setBillDate = (data, index) => {
    billDetail.map((x, k) => {
      x.map((y) => {
        if (k == index) y.bill_date = data;
      });
    });
    return "";
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  useEffect(() => {
    if (checkActiveTab == "DA") {
      getDaDataEdit(state?.id);
    }
  }, [checkActiveTab]);

  useEffect(() => {
    if (
      dataNew[0]?.emp_sub_group == null ||
      dataNew[0]?.emp_sub_group == "" ||
      dataNew[0]?.location == null ||
      dataNew[0]?.location == ""
    ) {
      setGradeAndLocate(true);
    } else {
      setGradeAndLocate(false);
    }
  }, [dataNew]);

  return (
    <>
      {/* {dataNew[0]?.emp_sub_group === "T1" || */}
      {dataNew[0]?.emp_sub_group === "C1" ||
      dataNew[0]?.emp_sub_group === "D1" ||
      dataNew[0]?.emp_sub_group === "D2" ||
      dataNew[0]?.is_sf_user === "false" ? (
        <div class="px-4 pb-2">
          <div>
            <table class="table table-striped table-bordered tablecured innergroup leavetableapply">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Hours </th>
                  <th>City / Entitlement</th>
                  <th>Bill</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {daDataList?.map((data, i) => (
                  <>
                    {setBillDate(data?.da_date, i)}

                    <a
                      id="setBillDate"
                      onClick={() => setBillDate(data?.da_date, i)}
                    ></a>
                    <tr>
                      <td class="fw-bold text-theme ">
                        {moment(data?.da_date).format("DD-MM-YYYY")}
                      </td>
                      <td>{data?.hours.toFixed(2)}</td>
                      <td>
                        {data?.city_name === "Other"
                          ? data?.city_input
                          : data?.city_name}
                        -{data?.category}-
                        {data?.calculated_entitlement?.toFixed(2)}
                      </td>
                      <td style={{ overflow: "visible" }}>
                        <button
                          class="btn btn-primary-inner"
                          style={{ padding: "3px 18px", fontSize: "15px" }}
                          onClick={() => addBill(i, data)}
                        >
                          Add Bill
                        </button>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="gross_total"
                          value={
                            billDetail[i] === undefined
                              ? "0"
                              : billAmountSum(
                                  i,
                                  0,
                                  data?.calculated_entitlement
                                )
                          }
                          // value={
                          //   billDetail[i] === undefined
                          //     ? data?.entitlement
                          //     : billAmountSum(i, data?.entitlement)
                          // }
                          class="form-control"
                          disabled
                          style={{ maxWidth: "100px" }}
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            <Modal
              show={visible}
              onHide={() => handleClose}
              backdrop="static"
              keyboard={false}
              size=""
              className="modaldefaultclose modaldefaultclosecenter"
            >
              <Modal.Header
                closeButton
                onClick={() => handleClose(false)}
                style={{
                  backgroundColor: "#0195d4",
                  color: "white",
                  height: "50px",
                }}
              >
                <Modal.Title
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  <i className="cis-warning "></i>
                  {/* {modalData.title} */}Add Bill Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {billData?.map((billdata, i) => (
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Bill Date</label>
                        <input
                          type="date"
                          class="form-control"
                          name="bill_date"
                          disabled
                          style={{ fontSize: "13px" }}
                          onChange={(e) => {
                            changeHandler(
                              daDataList[indexs]?.da_date,
                              "bill_date",
                              i
                            );
                          }}
                          value={daDataList[indexs]?.da_date}
                        />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="form-group innergroup">
                        <label>Invoice Number</label>
                        <input
                          type="text"
                          class="form-control"
                          style={{ textTransform: "uppercase" }}
                          name="invoice_number"
                          autocomplete="off"
                          onChange={(e) => {
                            changeHandler(e.target.value, "invoice_number", i);
                            handleValidateInvoice(e.target.value, i);
                            setFormValidation({
                              ...formValidation,
                              invoice_number: "",
                            });
                          }}
                          value={billdata?.invoice_number}
                        />
                        <div className="small text-danger">
                          {i == Number(billData.length - 1) &&
                            formValidation.invoice_number}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Bill Amount</label>
                        <input
                          type="number"
                          class="form-control"
                          name="bill_amount"
                          min="0"
                          onChange={(e) => {
                            let arr = ("" + e.target.value).split(".");
                            let num = billData[i].bill_amount;
                            if (arr.length) {
                              if (arr.length == 1) {
                                num = arr[0];
                              } else {
                                if (Number(arr[1]) <= 99) {
                                  num = e.target.value;
                                }
                              }
                            }
                            changeHandler(num, "bill_amount", i);
                            // changeHandler(e.target.value, "bill_amount", i);
                            setFormValidation({
                              ...formValidation,
                              bill_amount: "",
                            });
                          }}
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={blockInvalidChar}
                          value={
                            billdata?.bill_amount
                            // &&
                            // Math.max(1, billdata?.bill_amount)
                          }
                        />
                        <div className="small text-danger">
                          {i == Number(billData.length - 1) &&
                            formValidation.bill_amount}
                        </div>
                      </div>
                    </div>
                    {billData?.length > 1 ? (
                      <button
                        class="btn btnblankth text-danger p-0"
                        style={{
                          float: "right",
                          marginTop: "10px",
                          marginRight: "20px",
                        }}
                        onClick={() => {
                          removeHotel(i);
                        }}
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    ) : (
                      <button
                        class="btn btnblankth text-danger p-0"
                        style={{
                          float: "right",
                          marginTop: "10px",
                          marginRight: "20px",
                          color: "red",
                        }}
                        onClick={() => {
                          removeHotelLast(i);
                        }}
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    )}
                  </div>
                ))}
                <div class="col-md-1" style={{ float: "right" }}>
                  <div class="form-group innergroup">
                    <button
                      className="btn btn-primary"
                      style={{
                        float: "right",
                      }}
                      onClick={() => {
                        addMoreBill(billData);
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </Modal.Body>
              {gstError && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i> Invoice no is
                  Same
                </Alert>
              )}

              {gradeAndLocate == true && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;Your grade & location is not mapped in user master,
                  please contact your BHR.
                </Alert>
              )}

              {chkAmount() && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i> Amount cannot
                  be greater then max entitlement
                </Alert>
              )}
              {/* {handleValidate() && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  ++++++++++++++
                </Alert>
              )} */}
              <Modal.Footer>
                <button
                  type="button"
                  disabled={gstError || chkAmount()}
                  className="btn btn-outline-primary"
                  variant="primary"
                  style={{ padding: "2px 12px", fontSize: "15px" }}
                  onClick={() => {
                    setData();
                  }}
                >
                  {" "}
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  variant="secondary"
                  style={{ padding: "2px 12px", fontSize: "15px" }}
                  onClick={() => handleClose(true)}
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Modal>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Maximum Entitlement</label>
                <input
                  type="text"
                  disabled
                  value={daData}
                  class="form-control"
                  placeholder="Enter Maximum Entitlement"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Total</label>
                <input
                  type="text"
                  disabled
                  value={gradeAmt.toFixed(2)}
                  class="form-control"
                  placeholder="Enter Grade Lavel "
                />
              </div>
            </div>
          </div>

          <div class="mb-4">
            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>

              {findData?.length !== 0 ? (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    onClick={updateDa}
                    style={{ marginRight: "202px" }}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    onClick={saveDa}
                    style={{ marginRight: "202px" }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div class="px-4 pb-2">
          <div>
            <table class="table table-striped table-bordered tablecured innergroup leavetableapply">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Hours </th>
                  <th>City / Entitlement</th>
                  <th>Remarks</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {daDataList?.map((data, i) => (
                  <>
                    <tr>
                      <td class="fw-bold text-theme">
                        {moment(data?.da_date).format("DD-MM-YYYY")}
                      </td>
                      <td>{data?.hours.toFixed(2)}</td>
                      <td>
                        {data?.city_name === "Other"
                          ? data?.city_input
                          : data?.city_name}
                        -{data?.category}-{data?.calculated_entitlement}
                      </td>
                      <td style={{ overflow: "visible" }}>
                        <div style={{ width: "390px" }}>
                          <Select
                            options={data?.dropDownKey}
                            className="basic-multi-select"
                            isDisabled={
                              data?.SelectedKey?.label == "Headquarter DA"
                            }
                            name="remarks"
                            onChange={(e) => {
                              calculateTotalAmt(e, i);
                            }}
                            // id={"stay_at" + i}
                            // onChange={(e) => {
                            //   pushState(e, i);
                            //   setStayAtValue(e);
                            //   setStayAtFlag(true);
                            //   setFormValidation({
                            //     ...formValidation,
                            //     stay_at: "",
                            //   });
                            // }}
                            value={data?.SelectedKey}
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="gross_total"
                          value={data?.amt}
                          class="form-control"
                          disabled
                          style={{ maxWidth: "100px" }}
                        />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Maximum Entitlement</label>
                <input
                  type="text"
                  disabled
                  value={daData}
                  class="form-control"
                  placeholder="Enter Maximum Entitlement"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Total</label>
                <input
                  type="text"
                  disabled
                  value={gradeAmt}
                  class="form-control"
                  placeholder="Enter Grade Lavel "
                />
              </div>
            </div>
          </div>

          <div class="mb-3">
            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>
              <div class="col-md-4 d-flex flex-column mt-3">
                <h5 class="text-theme pb-2 mb-3">
                  <strong>
                    Attachments<span class="text-danger">*</span>
                  </strong>
                </h5>

                <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
                  <label>
                    Attach File<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control bg-white"
                    disabled
                    value={
                      // edit === true
                      // ? editPolicy?.logo
                      uploadDataIm?.logo?.original_name
                    }
                    placeholder="(JPG, PNG , Word, Pdf, ppt, Video)"
                  />
                  <div class="upload-btn-wrapper up-loposition">
                    <button class="uploadBtn">Browse</button>
                    <input
                      type="file"
                      title=""
                      id="policyattachment"
                      multiple
                      name="attachment_path"
                      accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                      onChange={(e) => onProfileChange(e, "attachment_path")}
                    />
                  </div>
                  <p>
                    Selected files{" "}
                    {/* <span> {uploadDataIm[0]?.original_name}</span> */}
                  </p>
                </div>
                {Object.keys(file).length !== 0 ? (
                  <div className="d-flex">{attachments123()}</div>
                ) : attachment != undefined && attachment.length != 0 ? (
                  <>
                    <div class="d-flex">
                      {attachment?.map((Data) => (
                        <div class="uploaded-image mr-1">
                          <i class="" style={{ width: "20px" }}>
                            <a
                              href={Data?.attachment_path}
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {Data?.attachment_path?.includes(".pdf") ? (
                                <img
                                  src={"/images/policy-icon/pdf.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".xlsx") ? (
                                <img
                                  src={"/images/policy-icon/xlsx.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".xls") ? (
                                <img
                                  src={"/images/policy-icon/xlsx.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".doc") ? (
                                <img
                                  src={"/images/policy-icon/docs.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".docs") ||
                                Data?.attachment_path.includes(".csv") ? (
                                <img
                                  src={"/images/policy-icon/docs.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".pptx") ? (
                                <img
                                  src={"/images/policy-icon/ppt.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data.attachment_path.includes(".mp4") ? (
                                <img
                                  src={"/images/policy-icon/video.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : Data?.attachment_path.includes(".msg") ? (
                                <img
                                  src={"/images/policy-icon/msg.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : (
                                <img
                                  src={Data.attachment_path}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              )}
                            </a>
                          </i>
                          <button
                            type="button"
                            onClick={(e) => {
                              deleteAttachment(
                                Data?.attachment_path?.split("/")[4]
                              );
                            }}
                            className="btn btn-blanktd text-danger ml-2"
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>

              {findData?.length !== 0 ? (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary mb-2 mt-2"
                    onClick={() => {
                      setCheckActiveTab("Summary");
                    }}
                  >
                    Back
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    onClick={updateDa}
                    style={{ marginRight: "202px" }}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <div class="col-md-12 mt-3 text-center">
                  <button
                    type="button"
                    class="btn btn-outline-secondary mb-2 mt-2"
                    onClick={() => {
                      setCheckActiveTab("Summary");
                    }}
                  >
                    Back
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    onClick={saveDa}
                    style={{ marginRight: "202px" }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {handleLoading()}
    </>
  );
};

export default DA;
