import * as ActionTypes from "../actions";
// import { FILE_UPLOAD_BASE_URL } from "../../constants/URL";

const getProfilePhoto = (token) => {
  let t;
  if (!token) {
    t = localStorage.getItem("accesstoken");
  } else {
    t = token;
  }
  const r = Math.random();
  const pic = t
    ? `FILE_UPLOAD_BASE_URL/fileupload-service/doc/profile/${t}?r=${r}`
    : "/img/default-profile.png";
  return pic;
};

const initialState = {
  isLoggedIn: !!localStorage.getItem("accesstoken"),
  token: localStorage.getItem("accesstoken"),
  currentUser: {
    email: "mail@example.com",
    picture: null,
  },
  cartCount: 0,
  profilePhoto: getProfilePhoto(),
  myrights: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
      localStorage.setItem("accesstoken", action.token);
      localStorage.setItem("isTemporary", action.istemp);
      return {
        ...state,
        isLoggedIn: true,
        token: action.token,
        profilePhoto: getProfilePhoto(action.token),
      };
    case ActionTypes.LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        currentUser: {
          email: "mail@example.com",
          picture: null,
        },
        cartCount: 0,
        profilePhoto: null,
        selectedDirectCustomer: 0,
        myrights: null,
      };
    case ActionTypes.LOAD_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case ActionTypes.UPDATE_RIGHTS:
      return {
        ...state,
        myrights: action.myrights,
      };
    case ActionTypes.UPDATE_CART_INFO:
      return {
        ...state,
        cartCount: action.cartCount,
      };
    case ActionTypes.UPDATE_PROFILE_PHOTO:
      return {
        ...state,
        profilePhoto: getProfilePhoto(),
      };
    case ActionTypes.CHANGE_DIRECT_CUSTOMER:
      return {
        ...state,
        selectedDirectCustomer: action.selectedDirectCustomer,
      };
    case ActionTypes.CHANGE_DIRECT_CUSTOMER_CONTACT:
      return {
        ...state,
        selectedDirectCustomerContact: action.selectedCustCont,
      };
    default:
      return { ...state };
  }
};

export default reducer;
