import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getModule = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/modules?${queryParm}`);
};

export const saveModule = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/modules`, data);
};

export const updateModule = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/modules`, data);
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getParentModuleList = async () => {
  return axios.get(`${AUTH_BASE_URL}/modules/drop-down`);
};

export const deleteModule = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/modules`,
    method: "DELETE",
    data: id,
  });
};

export const getChildModuleList = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/modules/child-modules/${id}`);
};

export const getExportData = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/modules/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const isSequenceExist = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/modules/exists`, isData);
};
