import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import * as OrgService from "../../../service/organisation";
import { confirmAlert } from "react-confirm-alert";
import "../../../alert/CustomUi.css";
import AddOrganisation from "./AddOrganisation";
import ViewOrganisation from "./ViewOrganisation";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as BusinessService from "../../../service/businessService";

function Organization() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [allDept, setAllDept] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [organisation, setOrganisation] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [valueExportData, setValueExportData] = useState({
    id: "",
    status: "",
  });

  const [moduleDataFilter, setModuleDataFilter] = useState({
    organisation_id: "",
    status: "",
  });
  const [employeeOption, setEmployeeOption] = useState([]);

  let employeeListAbortController = null;

  const getEmployeeList = () => {
    employeeListAbortController = new AbortController();
    BusinessService.getEmployeeList(employeeListAbortController.signal)
      .then((response) => {
        employeeListAbortController = null;
        let empOpt = [];
        response.data.dataList.result.forEach(obj => {
          if(obj.is_active) empOpt.push({label: `${obj.first_name} ${obj.last_name} (${obj.username})`, value: obj.username, id: obj.id})
        })
        setEmployeeOption(empOpt);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getOrganisation = (
    search,
    organisation_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    OrgService.getOrganisation(
      handleKey(
        search,
        organisation_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setOrgList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    organisation_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      status !== "" &&
      status !== undefined &&
      status !== null
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    organisation_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    OrgService.getExportValue(
      handleKey(
        search,
        organisation_id,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "organization.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteOrg = (idx) => {
    OrgService.deleteOrg(idx)
      .then((res) => {
        if (res.data.status == true) {
          getOrganisation(
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getOrganisation(
        e.target.value.toLowerCase(),
        moduleDataFilter.organisation_id,
        moduleDataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const submit = (id, orgName) => {
    confirmAlert({
      title: "Delete Organization",
      message: `Are you sure to delete ${orgName} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOrg(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addNewOrg = () => {
    setEditMode(false);
    setOrganisation(null);
    setShowForm(true);
  };

  const onClose = () => {
    setOrganisation(null);
    setShowForm(false);
  };

  const editOrg = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setOrganisation(eData);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getOrganisation(
      searchStr,
      moduleDataFilter.organisation_id,
      moduleDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getOrganisation(
      searchStr,
      moduleDataFilter.organisation_id,
      moduleDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  useEffect(() => {
    getOrganisation(
      searchStr,
      moduleDataFilter.organisation_id,
      moduleDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  useEffect(() =>{
    getEmployeeList();
    return () => {
      if(employeeListAbortController) employeeListAbortController.abort();
    }
  }, [])

  const getAllOrganisation = (paginate) => {
    OrgService.getAllOrganisation(paginate)
      .then((response) => {
        setAllDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(moduleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setModuleDataFilter(ClearData);
    getOrganisation(searchStr, "", "", 1, pageSizeNo, sortData.sort_by, true);
    filterClose();
  };

  const filterShow = () => {
    filteropen();
    getAllOrganisation(false);
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setValueExportData({
      ...valueExportData,
      id: moduleDataFilter.organisation_id,
      status: moduleDataFilter.status,
    });
    const org = moduleDataFilter.organisation_id;
    const stats = moduleDataFilter.status;
    getOrganisation(
      searchStr,
      org,
      stats,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getOrganisation(
        data,
        moduleDataFilter.organisation_id,
        moduleDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getOrganisation(
      searchStr,
      moduleDataFilter.organisation_id,
      moduleDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const myShowHandle = (e) => {
    getOrganisation(
      searchStr,
      moduleDataFilter.organisation_id,
      moduleDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Organization</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Organization</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Organization Code,
                        <br />
                        Organization Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Organization Code, Organization Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              moduleDataFilter.organisation_id,
                              moduleDataFilter.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}

                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addNewOrg();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Logo</th>
                <th>Organization Code</th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                >
                  Organization Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("created_by");
                  }}
                  className={
                    sortData["created_by"] === undefined
                      ? "unsortcls"
                      : sortData["created_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {orgList.length
                ? orgList?.map((data, index) => (
                    <>
                      <tr key={`org${index}`}>
                        <td>
                          <a href={data?.logo} target="_blank" rel="noreferrer">
                            <img src={data?.logo} alt="" height={50} />
                          </a>
                        </td>
                        <td>{data?.code}</td>
                        <td className="text-theme fw-bold">{data?.name}</td>

                        <td>{data?.updated_by}</td>
                        {/* <td>{data?.updated_at}</td> */}
                        <td>
                          {moment
                            .utc(data?.updated_at)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td>
                        <td>
                          {data?.is_active ? (
                            <span className="badge bad-status badge-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge bad-status badge-danger">
                              InActive
                            </span>
                          )}
                        </td>

                        {finalUrlValue?.V ||
                        finalUrlValue?.E ||
                        finalUrlValue?.R ? (
                          <td>
                            {finalUrlValue?.V ? (
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary"
                                onClick={() => {
                                  onView(data);
                                }}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            ) : (
                              ""
                            )}

                            {finalUrlValue?.E ? (
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary ml-2"
                                onClick={() => {
                                  editOrg(data);
                                }}
                              >
                                <i className="far fa-edit"></i>
                              </button>
                            ) : (
                              ""
                            )}

                            {finalUrlValue?.R ? (
                              <button
                                className="btn btn-blanktd text-danger ml-2"
                                type="button"
                              >
                                <i
                                  className="far fa-trash-alt text-danger"
                                  onClick={() => {
                                    submit(data?.id, data?.name);
                                  }}
                                ></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    </>
                  ))
                : "No Record Found"}
            </tbody>
          </table>
        </div>
        {orgList?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    myShowHandle(e);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Organization <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              name="organisation_id"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  organisation_id: e.target.value,
                });
              }}
              value={moduleDataFilter?.organisation_id}
            >
              <option value="" label="Select Organization" disabled />
              {renderOrgOptions(allDept)}
            </select>
          </div>
          <div className="form-group innergroup ">
            <label>
              Select Status <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  status: e.target.value,
                });
              }}
              value={moduleDataFilter?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true} label="Active" />
              <option value={false} label="In-Active" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddOrganisation
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editOrganisation={organisation}
            onAddCallBack={onAddCallBack}
            employeeOption={employeeOption}
          />
        </>
      ) : (
        ""
      )}
      {viewForm ? (
        <>
          <ViewOrganisation
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Organization;
