import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Jivasmall from "../../assets/images/jivasmall.png";
import Sidemenu from "./../include/sidemenu";
import Header from "./../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import * as NPSServices from "../../service/NpsServices";
import * as VPSService from "./vpfService.js";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore.js";
import { get_sap_schedule } from "../../service/searchpo.js";

function PFVoluntary() {
  const [show, filtersetShow] = useState(false);
  const filteropen = () => {
    filtersetShow(true);
  };
  const filterclose = () => {
    filtersetShow(false);
  };
  const userData = useSelector(selectUserData)
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => {
    getCurrentPF();
    addnewsetShow(true);
    setEditMOde(false);
    setVpfEditData(null);
  };
  const addnewclose = () => addnewsetShow(false);
  const [npsUser, setNpsUser] = useState();
  const [vpf, setVpf] = useState("");
  const [vpfDetails, setVpfDetails] = useState([]);
  const [currentVpfDetails, setCurrentVpfDetails] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [emp_id, setEmployeeId] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [SelfDeclFlag, setSelfDeclFlag] = useState(false);
  const [selectId, setSelectId] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [editMode, setEditMOde] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [options, setOptions] = useState([]);
  const [vpfEditData, setVpfEditData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [vpfDataFilter, setVpfDataFilter] = useState({
    year: "",
    status: "",
  });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  useEffect(() => {
    getSapSchedule();
    getNpsUser(0);
    getVpfDetails(
      searchStr,
      vpfDataFilter.year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      vpfDataFilter.status
    );
    getAllEmployee();
    if (editMode == true) {
      setVpf(vpfEditData?.vpf);
    }
  }, [addnewshow]);

  const editVpf = (data) => {
    addnewsetShow(true);
    setEditMOde(true);
    setVpfEditData(data);
  };

  const getSapSchedule = () => {
    get_sap_schedule('sync_post_vpf').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }


  const getAllEmployee = () => {
    NPSServices.getAllEmployeIdName(false).then((response) => {
      let obj = response?.data?.dataList?.result?.map((data) => {
        return {
          value: data?.id,
          label:
            data?.first_name +
            " " +
            data?.last_name +
            "(" +
            data?.username +
            ")",
        };
      });
      setAllEmployee(obj);
    });
  };

  const getNpsUser = (id) => {
    NPSServices.getUserNps(id)
      .then((response) => {
        setNpsUser(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    year,
    currentPage,
    pageSizeNo,
    sort_by,
    paginate,
    status
  ) => {
    let queryParm =
      "page_no=" +
      currentPage +
      "&page_size=" +
      pageSizeNo +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const getVpfDetails = (
    search,
    year,
    currentPage,
    pageSizeNo,
    sort_by,
    paginate,
    status
  ) => {
    VPSService.getVPFDetails(
      handleKey(
        search,
        year,
        currentPage,
        pageSizeNo,
        sort_by,
        paginate,
        status
      )
    ).then((response) => {
      setVpfDetails(response?.data?.dataList?.result);
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.fiscal_year,
          label: res?.fiscal_year,
        };
      });
      let jsonObject = obj.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      setOptions(uniqueArray);
    });
  };

  const handleVpfChange = (e) => {
    setVpf(e.target.value);
  };
  const saveVpf = () => {
    if (vpf === "") {
      toast.error("Kindly fill the required data");
    } else {
      let obj = {};
      if (emp_id != "") {
        obj = { vpf: vpf, emp_id: emp_id };
      } else {
        obj = { vpf: vpf };
      }
      VPSService.saveVPF(obj).then((res) => {
        setVpf("");
        if (res.data.message === "VPF Submitted successfully") {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getVpfDetails(
          "",
          vpfDataFilter.year,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          vpfDataFilter.status
        );
        setEmployeeId("");
        setVpf("");
        addnewclose();
        setSelfDeclFlag(false);
      });
    }
  };
  const updateVpf = () => {
    let obj = { vpf: vpf };
    VPSService.editVpf(vpfEditData?.id, obj).then((response) => {
      toast.success(response.data.message);
      getVpfDetails(
        searchStr,
        vpfDataFilter.year,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        vpfDataFilter.status
      );
      setEmployeeId("");
      addnewclose();
    });
  };

  const handleEmployee = (value) => {
    let emp_id = value.value;
    setEmployeeId(value.value);
    getCurrentPF(emp_id);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      getVpfDetails(
        e.target.value.toLowerCase(),
        vpfDataFilter.year,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        vpfDataFilter.status
      );
      // let data = vpfDetails;
      // let data1 = [];
      // data.map((x) => {
      //   if (
      //     x.user_name.toLowerCase().includes(e.target.value) ||
      //     x.created_by_name.toLowerCase().includes(e.target.value) ||
      //     x.emp_code.toLowerCase().includes(e.target.value)
      //   ) {
      //     data1.push(x);
      //   }
      // });
      // let data2 = [];
      // setVpfDetails(data1);
    }
    if (e.target.value.length === 0) {
      getVpfDetails(
        "",
        vpfDataFilter.year,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        vpfDataFilter.status
      );
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      getVpfDetails(
        "",
        vpfDataFilter.year,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        vpfDataFilter.status
      );
      setSearchStr("");
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getVpfDetails(
      "",
      vpfDataFilter.year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      vpfDataFilter.status
    );
  };
  const acceptSelfDecl = (e) => {
    setSelfDeclFlag(e.target.checked);
  };

  const handleFilter = (vpfDataFilter) => {
    setFilterApplied(true);
    const year = vpfDataFilter?.year[0]?.label;
    const status = vpfDataFilter.status;
    //         let data = vpfDetails
    //         let data1=[]
    //         if(statusFilter != "" && yearFilter == ""){
    //          data.map((x)=>{
    //       if(x.status.toLowerCase().includes(statusFilter)){
    //         data1.push(x)
    //       }
    //   })
    // }
    // if(statusFilter == "" && yearFilter != ""){
    //     data.map((x)=>{
    //         if(x.fiscal_year.toLowerCase().includes(yearFilter)){
    //           data1.push(x)
    //         }
    //     })
    // }
    // if(statusFilter != "" && yearFilter != ""){
    //     data.map((x)=>{
    //         if(x.fiscal_year.toLowerCase().includes(yearFilter) && x.status.toLowerCase().includes(statusFilter)){
    //           data1.push(x)
    //         }
    //     })
    // }
    //   setVpfDetails(data1)
    getVpfDetails(
      searchStr,
      year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      status
    );
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(vpfDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setVpfDataFilter(ClearData);
    getVpfDetails(
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      ""
      // true,
      // search, organisation_id, status, pageNo, page_size, sort_by, paginate
    );
    filterclose();
  };

  const reviseVpf = () => {
    if (selectId.length > 0) {
      let data = { vpf_id: selectId, status: "revised" };
      VPSService.updateVpf(data).then((response) => {
        toast.success(response?.data?.message);
        getVpfDetails(
          "",
          vpfDataFilter.year,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          vpfDataFilter.status
        );
      });
    } else {
      toast.error("Please Select VPF First");
    }
  };

  const approveVpf = () => {
    if (selectId.length > 0) {
      let data = { id: selectId, status: "approved" };
      VPSService.approveVPF(data).then((response) => {
        toast.success(response?.data?.message);
        setSelectId([]);
        getVpfDetails(
          searchStr,
          vpfDataFilter.year,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          vpfDataFilter.status
        );
      });
    } else {
      toast.error("Please Select VPF First");
    }
  };

  const selectedVpf = (e, data) => {
    // let obj = []
    let copyData = JSON.parse(JSON.stringify(vpfDetails));
    if (e.target.checked == true) {
      selectId.push(data.id);
      copyData?.map((res) => {
        if (res.id === data.id) {
          res.checked = "Y";
        }
      });
      setVpfDetails(copyData);
    } else {
      copyData?.map((res) => {
        if (res.id === data.id) {
          res.checked = "N";
        }
      });
      let obj = selectId?.map((x) => {
        if (x != data.id) {
          return x;
        }
      });
      setSelectId(obj);
      setVpfDetails(copyData);
    }
  };

  const handleExport = (search, year, status, sort_by) => {
    let queryParm = "sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }

    return queryParm;
  };

  const getExportValue = (search, year, status, sort_by) => {
    VPSService.getExportValue(handleExport(search, year, status, sort_by))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vpfdata.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(vpfDetails));
    if (e.target.checked === true) {
      data?.map((res) => {
        if (res.status.toLowerCase() === "submitted") {
          res.checked = "Y";
          selectId.push(res?.id);
        }
      });
      setVpfDetails(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setVpfDetails(data);
      let obj = selectId?.map((x) => {
        if (x != data.id) {
          return x;
        }
      });
      setSelectId(obj);
    }
  };

  const getCurrentPF = (id) => {
    VPSService.getCurrentvpf(handleKey2(id)).then((response) => {
      setCurrentVpfDetails(response?.data?.result);
    });
  };
  const handleKey2 = (id) => {
    let queryParm = "";
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }

    return queryParm;
  };

  return (
    <>
      <div class="">
        <ToastContainer autoClose={1000} />
        <div class="content-wrapper-inner content-wrapper-inner2">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">Voluntary PF</h4>
                {/* <Breadcrumb>
                                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/">
                                    Me@PI
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>PF Voluntary</Breadcrumb.Item>
                            </Breadcrumb> */}
              </div>
              <div class="col-md-12">
                <div class="border-top mt-2 mt-md-4 mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="searchbar"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    placeholder="Search..."
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  {npsUser?.is_superuser == true ? (
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item href="#"><i class="fas fa-print"></i>Print</Dropdown.Item>
                                        <Dropdown.Item href="#"><i class="far fa-file-alt"></i>CSV</Dropdown.Item> */}
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={vpfDetails.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportValue(
                                searchStr,
                                vpfDataFilter.year,
                                vpfDataFilter.status,
                                sortData.sort_by
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#"><i class="far fa-file-pdf"></i>Pdf</Dropdown.Item>
                                        <Dropdown.Item href="#"><i class="far fa-copy"></i>Copy</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    ""
                  )}
                  <button onClick={filteropen} class="btn btn-secondary-inner" style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}>
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                  {npsUser?.vpf_flag === true ? (
                    <button
                      class="btn btn-primary-inner"
                      disabled={!npsUser?.vpf_flag}
                      onClick={addnewopen}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
              </div>
            </div>
          </div>

          <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
            {/* <Tabs defaultActiveKey="approval" id="approvaltabs">
                        
                        <Tab eventKey="approval"
                            title={
                                <React.Fragment>
                                    For Approval
                                    <span class="numberpurchse">11</span>
                                </React.Fragment>
                                }
                        > */}
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    {npsUser?.is_superuser ? (
                      <th>
                        <label class="logCheck d-inline-block">
                          <input
                            type="checkbox"
                            name="permission"
                            onClick={(e) => {
                              handleAllData(e);
                            }}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </th>
                    ) : (
                      ""
                    )}
                    <th>Employee Code</th>
                    <th>Employee Name</th>
                    <th>New PF (%)</th>
                    <th>Year</th>
                    <th>Submitted On</th>
                    <th>Submitted By</th>
                    <th>Status</th>
                    {npsUser?.is_admin === true && (
                      <>
                        <th>SAP Log</th>
                        <th>SAP Status</th>
                      </>
                    )}
                    <th>Actions</th>
                    {/* {npsUser?.is_superuser == false ?
                                            <th>Actions</th> 
                                            :""} */}
                  </tr>
                </thead>
                <tbody>
                  {vpfDetails?.length > 0 &&
                    vpfDetails?.map((data) => {
                      return (
                        <tr>
                          {npsUser?.is_superuser ? (
                            <td>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    selectedVpf(e, data);
                                  }}
                                  checked={data?.checked === "Y"}
                                  disabled={
                                    data?.status.toLowerCase() !== "submitted"
                                  }
                                />
                                <span class="checkmark"></span>
                              </label>
                            </td>
                          ) : (
                            ""
                          )}
                          <td class="">{data?.emp_code}</td>
                          <td>{data?.user_name}</td>
                          <td>{data?.total_pf}</td>
                          <td>{data?.fiscal_year}</td>
                          <td>
                            {moment(data?.created_at).format("Do MMM YYYY")}
                          </td>
                          <td>{data?.created_by_name}</td>
                          {data?.status == "revised" &&
                            npsUser?.is_superuser == false ? (
                            <>
                              <td>
                                <span
                                  style={{ textTransform: "capitalize" }}
                                  class="badge bad-status badge-warning"
                                >
                                  Pending to Revise
                                </span>
                              </td>
                            </>
                          ) : data?.status == "revised" &&
                            npsUser?.is_superuser == true ? (
                            <td>
                              <span
                                style={{ textTransform: "capitalize" }}
                                class="badge bad-status badge-warning"
                              >
                                Requested to Revise
                              </span>
                            </td>
                          ) : data?.status == "A" ? (
                            <>
                              <td>
                                <span
                                  style={{ textTransform: "capitalize" }}
                                  class="badge bad-status badge-success"
                                >
                                  Approved
                                </span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <span
                                  style={{ textTransform: "capitalize" }}
                                  class="badge bad-status badge-success"
                                >
                                  {data?.status}
                                </span>
                              </td>
                            </>
                          )}
                          {npsUser?.is_admin === true && (
                            <>
                              <td>
                                {data?.sap_error !== null && (
                                  <Tooltip
                                    title={data?.sap_error}
                                    position="bottom"
                                  >
                                    {data?.sap_error?.substr(0, 12)}...
                                  </Tooltip>
                                )}
                              </td>
                              {data?.sap_status === true ? (
                                <td>Synced</td>
                              ) : (
                                <td>Pending to sync</td>
                              )}
                            </>
                          )}
                          <td>
                            <button
                              class="btn btn-blanktd text-primary"
                              disabled={
                                data?.status === "A" ||
                                data?.status === "approved"
                              }
                              type="button"
                              onClick={(e) => {
                                editVpf(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                          </td>
                          {/* {npsUser?.is_superuser == false  && (data?.status == "submitted" || data?.status == "revised") ?(
                                            <td>
                                            <button class="btn btn-blanktd text-primary" type="button" onClick={(e)=>{editVpf(data)}}>
                                            <i class="far fa-edit"></i>
                                            </button>
                                            </td>

                                            ):(
                                                ""
                                            )}  */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
            {
              npsUser?.is_superuser ? (
                <div
                  class="row jvactionsbutton"
                  style={{ marginTop: "-110px" }}
                >
                  <div class="col-md-12">
                    <div class="p-3">
                      <button
                        class="btn btn-danger mr-2"
                        type="button"
                        onClick={reviseVpf}
                      >
                        <i class="far fa-times-circle"></i> Revise
                      </button>
                      <button class="btn btn-success" onClick={approveVpf}>
                        <i class="far fa-check-circle"></i> Approve
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
              // <div class="row jvactionsbutton" style={{marginTop:"-60px"}}>
              //     <div class="col-md-12">
              //         <div class="p-3">
              //             <button class="btn btn-danger mr-2"><i class="far fa-times-circle"></i> Delete</button>
              //             <button class="btn btn-success"><i class="far fa-check-circle"></i> Submit for Posting</button>
              //         </div>
              //     </div>
              // </div>
            }

            {/* </Tab> */}

            {/* <Tab eventKey="draft"
                            title={
                                <React.Fragment>
                                    My Request
                                    <span class="numberpurchse">05</span>
                                </React.Fragment>
                                }
                        > 
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered tablecured">
                                    <thead>
                                        <tr> 
                                            <th>Employee Code</th>
                                            <th>Employee Name</th>
                                            <th>New PF</th>
                                            <th>Year</th>
                                            <th>Submitted On</th>
                                            <th>Submitted By</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        {vpfDetails?.length > 0 && 
                                        vpfDetails?.map((data)=>{
                                            return(                            
                                        <tr> 
                                            <td class="fw-bold text-theme">10015</td>
                                            <td>{data?.user_name}</td>
                                            <td>{data?.total_pf}</td>
                                            <td>{data?.fiscal_year}</td>
                                            <td>{moment(data?.created_at).format("Do MMM YYYY")}</td>
                                            <td>{data?.user_name}</td>
                                            <td><span class="badge bad-status badge-success">{data?.status}</span></td> 
                                            <td>
                                            </td>
                                        </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>

                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="sortinglist">
                                        Show
                                        <select class="form-control">
                                            <option>10</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <Pagination> 
                                        <Pagination.Prev className="previpag"/>
                                        <Pagination.Item>{1}</Pagination.Item> 
                                        <Pagination.Item active>{2}</Pagination.Item>  
                                        <Pagination.Ellipsis />
                                        <Pagination.Item>{9}</Pagination.Item>
                                        <Pagination.Item>{10}</Pagination.Item> 
                                        <Pagination.Next className="previpag"/> 
                                    </Pagination>
                                </div>
                            </div>

                            <div class="row jvactionsbutton">
                                <div class="col-md-12">
                                    <div class="p-3"> 
                                        <button class="btn btn-danger mr-2"><i class="far fa-times-circle"></i> Delete</button>
                                        <button class="btn btn-success"><i class="far fa-check-circle"></i> Submit for Posting</button>
                                    </div>
                                </div> 
                            </div>
                        </Tab> */}

            {/* </Tabs> */}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div class="form-group innergroup">
                    <label>From Date<span class="text-danger">*</span></label>
                    <input type="date" class="form-control" />
                 </div>
                 <div class="form-group innergroup">
                    <label>From To<span class="text-danger">*</span></label>
                    <input type="date" class="form-control" />
                 </div> */}
          <div class="form-group innergroup">
            <label>
              Fiscal Year<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setVpfDataFilter({
                  ...vpfDataFilter,
                  year: e,
                });
                setCurrentPage(1);
              }}
              value={vpfDataFilter.year}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setVpfDataFilter({
                  ...vpfDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={vpfDataFilter.status}
            >
              <option>Select</option>
              <option value="approved">Approved</option>
              <option value="revised">Revised</option>
              <option value="Submitted">Submitted</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={(e) => {
              handleFilter(vpfDataFilter);
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode === true ? "Edit" : "Add"} PF Voluntary Request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Year<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      disabled
                      value="2023-2024"
                      class="form-control"
                      placeholder="Enter Year"
                    />
                  </div>
                </div> */}

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Select Employee<span class="text-danger">*</span>
                    </label>
                    {npsUser?.is_superuser ? (
                      <Select
                        options={allEmployee}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          handleEmployee(e);
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        name="usernames"
                        value={
                          npsUser?.full_name + "(" + npsUser?.username + ")"
                        }
                        disabled
                        placeholder="Enter Name"
                        class="form-control"
                      />
                    )}
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>Particulars</th>
                          <th>Statutory %</th>
                          <th>VPF %</th>
                          <th>Total %</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="fw-bold text-theme">
                            Current Employee PF contribution
                          </td>
                          {currentVpfDetails?.map((data) => {
                            return (
                              <>
                                <td>{data?.Statutory_pf}</td>
                                <td>{data?.vpf}</td>
                                <td>{data?.total_pf}</td>
                              </>
                            );
                          })}
                        </tr>
                        <tr>
                          <td class="fw-bold text-theme">
                            Proposed Employee PF contribution
                          </td>
                          <td>12</td>
                          <td>
                            <select
                              class="form-control"
                              value={vpf}
                              onChange={(e) => {
                                handleVpfChange(e);
                              }}
                              style={{ minWidth: "75px" }}
                            >
                              <option>Select</option>
                              <option>0</option>
                              <option>8</option>
                              <option>18</option>
                              <option>28</option>
                              <option>38</option>
                            </select>
                          </td>
                          <td>{12 + Number(vpf)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h6 class="text-danger mb-2">
                    <strong>Self declaration: </strong>
                  </h6>
                  <div class="form-group innergroup">
                    <label class="logCheck d-inline-block acceptchkbox">
                      Please deduct VPF from my salary as per percentage
                      selected by me during the contribution period starting
                      from March-2024 to February-2025
                      <input
                        type="checkbox"
                        name="statusupdate1"
                        onClick={(e) => {
                          acceptSelfDecl(e);
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            disabled={!SelfDeclFlag}
            type="button"
            onClick={editMode ? updateVpf : saveVpf}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PFVoluntary;
