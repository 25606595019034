import React from "react";
import PropTypes from "prop-types";

const Preview = (props) => {
  const { clearFile,file, showAction } = props;
  const formatType = ["pdf", "xlsx", "xls", "doc", "docx", "pptx", "mp4", "png","jpg"];
  const hasType = formatType.indexOf(file.type);
  const getUrl = () => {
    try {
      return URL.createObjectURL(file);
    } catch (error) {
      
      return file?.path ?  'https://uat-mypi-public.s3.ap-south-1.amazonaws.com/' + file?.path : file?.name;
    }
  }
  const getPreviewImg= () => {
    const url = getUrl();
    console.log("url", url)
    if(url.includes('blob:'))
    {
      const filters = file.path.split('.');
      console.log("filter path ", filters)
      const index = formatType.indexOf(filters[filters.length - 1]);
      console.log("index", index)
      if(index > -1){
        return "/images/policy-icon/"+formatType[index]+".png";
      }
      else{
       // console.log(props);
        return file?.name ?  'https://uat-mypi-public.s3.ap-south-1.amazonaws.com/' + file?.name : '';
      }
    }
    return url;
  }
  let imgSrc = (hasType > -1) ? "/images/policy-icon/"+formatType[hasType]+".png" :  getPreviewImg();
  console.log("has type", hasType)
  return (
    <>
      <p>Selected files<span> <br />{file.name}</span></p>
      <div class="uploaded-image mr-1">
        <a href={getUrl()} target={"_blank"} rel="noreferrer">
            <img
                src={imgSrc}
                alt="pic"
                width="50px"
                height="50px"
            />
        </a>
        {showAction && <button
          type="button"
          onClick={clearFile}
          className="btn btn-blanktd text-danger ml-2"
        >
          <i className="far fa-trash-alt text-danger"></i>
        </button>}
      </div>
    </>
  );
};

Preview.defaultProps = {
  clearFile:()=>{},
  file:{},
  showAction:true,
}

Preview.propTypes = {
  clearFile:PropTypes.func,
  file:PropTypes.object,
  showAction:PropTypes.bool
}


export { Preview };
