import { useEffect, useState } from "react";
import { Modal, Breadcrumb, Dropdown, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ModularTable from "../../../../elements/ModularTable";
import {
  getAGSList,
  postAGSList,
  getAGCFY,
  getAGSListID,
  putAGSList,
  getaprvhisList,
  getaproveraction,
  getExportValue,
  uploadImages,
  importExelAg,
  getDirectoryDetail
} from "../../../../service/AgriPortalService/AGSales/Agsales";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { param } from "jquery";
import { paginationItemClasses } from "@mui/material";
const Root = (props) => {
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [newData, setNewData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [requestID, setRequestID] = useState();
  const [remark, setRemark] = useState("");
  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [marketSize, setMarketSizeData] = useState({});
  const [aGSalesList, setAGSalesList] = useState([]);
  const [mode, setMode] = useState();
  const [excelListImport, setExcelListImport] = useState([]);
  const [loader, setLoader] = useState(true);
  const [freezeCTA, setFreezeCTA] = useState(false);
  const [uploadData, setUploadData] = useState({});
  const [excelList, setExcelList] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clfy, setClfy] = useState();
  const [lfy, setLfy] = useState();
  const [month, setMonth] = useState("Mar");
  const [monthN, setMonthN] = useState("");
  const [cfy, setCfy] = useState("");
  const [reqid, setreqId] = useState("");
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    getAGCFY()
      .then((res) => {
        setCfy(res.data.fy)
        if (res.data.fy != null && res.data.fy != undefined) {
          setClfy(parseInt((res.data.fy).slice(0, 4)))
          setLfy(parseInt((res.data.fy).slice(5, 9)))

        }
      }

      ).catch((err) => console.log(err));
  }, [])

  const getExportDetails = () => {
    let query = "";
    query = "request_id=" + requestID
    console.log("requestid and mode", requestID, mode)
    if (mode != "create") {
      getExportValue(query)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "SalesData.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      getExportValue()
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "SalesData.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  }
  // show zone territory and region
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setRequestID(parseInt(params.get("id")))

    // setZone(params.get("zone_name"))
    // setTerritory(params.get("territory_name"))
    // setRegion(param.get("region_name"))
    if (params.get("mode") != "create") {


      const params_region = {
        zone_name: params.get("zone_name"),
        territory_name: params.get("territory_name"),
        region_name: params.get("region_name")
      }
      console.log("params_region", params_region)
      setMarketSizeData(params_region)
    }
    //setPageId(params.get("id"));
    // getCurrentFy()
    //   .then((res) => setCfy(res.data.dataList.fy))
    //   .catch((err) => console.log(err));
    let query = "";
    if (params.get("mode") == "edit" || params.get("mode") == "view" || params.get("mode") == "approve") {
      // query = "request_id=" + params.get("id")
      // console.log("queryy", query)
      getaglistidfun();
    }

    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setMarketSizeData(res?.data?.dataList);

        })
        .catch((err) => {
          console.log(err);
        });
    }



  }, [mode]);



  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    // setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "request_id=" + params.get("id");
    }
    if (params.get("mode") != "create") {
      getaprvhisList(query)
        .then((res) => {
          setApprovalList(res.data);

        })
        .catch((err) => console.log(err));
    }
    getaglistfun();

  }, []);

  useEffect(() => {
    let end_point = "/mymarket/marketsize";
    getPermissions(end_point)
      .then((res) => {
        setNewData(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Save failed!");
        }
      });
  }, []);

  const getaglistidfun = () => {
    const params = new URLSearchParams(window.location.search);
    let query;
    query = "request_id=" + params.get("id")
    console.log("queryy", query)

    getAGSListID(query)
      .then((res) => {
        setLoader(false);
        setAGSalesList(res.data);
        if (res.status == 200) {
          res.data.map((ele, i) => {
            setMonthN(ele.month_upto)
            if (ele.month_upto <= 9) {
              setFlag(true)
            } else {
              setFlag(false)
            }
            if (ele.month_upto == "1") {
              setMonth("Apr")
            } else if (ele.month_upto == "2") {
              setMonth("May")
            } else if (ele.month_upto == "3") {
              setMonth("Jun")
            } else if (ele.month_upto == "4") {
              setMonth("Jul")
            } else if (ele.month_upto == "5") {
              setMonth("Aug")
            } else if (ele.month_upto == "6") {
              setMonth("Sep")
            } else if (ele.month_upto == "7") {
              setMonth("Oct")
            } else if (ele.month_upto == "8") {
              setMonth("Nov")
            } else if (ele.month_upto == "9") {
              setMonth("Dec")
            } else if (ele.month_upto == "10") {
              setMonth("Jan")
            } else if (ele.month_upto == "11") {
              setMonth("Feb")
            } else if (ele.month_upto == "12") {
              setMonth("Mar")
            }
          })
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

  }

  const getaglistfun = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("mode") == "create") {
      getAGSList()
        .then((res) => {
          setLoader(false);
          console.log("res ag id list", res)
          if (res.data[0].message != undefined && res?.data[0]?.message.length > 0) {
            toast.error("Sales Data already exists!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          } else {
            setAGSalesList(res.data);
            if (res.status == 200) {
              res.data.map((ele, i) => {
                setMonthN(ele.month_upto)
                if (ele.month_upto <= 9) {
                  setFlag(true)
                } else {
                  setFlag(false)
                }
                if (ele.month_upto == "1") {
                  setMonth("Apr")
                } else if (ele.month_upto == "2") {
                  setMonth("May")
                } else if (ele.month_upto == "3") {
                  setMonth("Jun")
                } else if (ele.month_upto == "4") {
                  setMonth("Jul")
                } else if (ele.month_upto == "5") {
                  setMonth("Aug")
                } else if (ele.month_upto == "6") {
                  setMonth("Sep")
                } else if (ele.month_upto == "7") {
                  setMonth("Oct")
                } else if (ele.month_upto == "8") {
                  setMonth("Nov")
                } else if (ele.month_upto == "9") {
                  setMonth("Dec")
                } else if (ele.month_upto == "10") {
                  setMonth("Jan")
                } else if (ele.month_upto == "11") {
                  setMonth("Feb")
                } else if (ele.month_upto == "12") {
                  setMonth("Mar")
                }
              })
            }

          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err)
        });
    }
  }

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files types", files.type);
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    console.log("file data", fileData)
    uploadImages(fileData).then((response) => {
      if (flag === "import_user") {
        setUploadData(response.data);
      }
    });
  };

  const getDirectoryDetails = (flag) => {
    let queryParm
    queryParm = "file_name=" + uploadData.original_name
    console.log("queryparam", queryParm)
    getDirectoryDetail(queryParm)
      .then((response) => {
        console.log("excel pass fail data", response.data)
        setExcelListImport(response?.data);
        if (mode == "create") {
          getaglistfun();
        } else {
          getaglistidfun();
        }


      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        )
        console.log("error get ectory", error)
      });
  }

  const importExel = (values) => {
    if (JSON.stringify(uploadData) !== JSON.stringify({})) {
      setShowStatus(true);
      setIsLoading(true);

      importExelAg(values)
        .then((response) => {
          setExDataDown(response.data);
          console.log("importexcel response", response);
          if (response.status === 200) {
            setIsLoading(false);
            getDirectoryDetails();
            document.getElementById("import_user").value = "";
            setUploadData({});
            toast.success("uploaded successfully");
            //  importclose();
          }
        })
        .catch((error) => {
          document.getElementById("import_user").value = "";
          setUploadData({});
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          )

          console.log("error", error)
          toast.error("Kindly Check the format");
          //  importclose();
        });
    } else {
      toast.warning("Kindly select the file to upload");
      //  importclose();
    }
  };
  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  const onChangeBQty = (fyqty, index, type, mode) => {

    if (fyqty.length > 0) {
      console.log("fyqy", fyqty, "index", index, "type", type)
      setAGSalesList(aGSalesList.map((elem, i) => {
        if (index == i) {
          if (type == "fy_budget_qty") {
            elem["fy_budget_qty"] = fyqty;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    } else {
      setAGSalesList(aGSalesList.map((elem, i) => {
        if (index == i) {
          if (type == "fy_budget_qty") {
            elem["fy_budget_qty"] = null;
          }
          return elem;
        } else {
          return elem;
        }
      }))
    }
    console.log(aGSalesList)

  }

  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    if (aGSalesList.length != 0 || aGSalesList != undefined) {
      setLoader(true);
      let queryParm
      if (freezeCTA) return;

      if (mode == "create") {
        queryParm = "cfy=" + cfy + "&status=" + "D"
        // posting the market size data
        console.log("queryparams", queryParm, "aGSalesList", aGSalesList)
        postAGSList(aGSalesList, queryParm)
          .then((res) => {
            setLoader(false);
            toast.success("Save succesfull!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          })
          .catch((err) => {
            console.log("error", err)
            setLoader(false);
            toast.error("Save failed!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          });
      }
      if (mode == "edit") {
        // const params = new URLSearchParams(window.location.search);
        // body.id = params.get("id");
        queryParm = "request_id=" + requestID + "&status=" + "D"
        console.log("query param edit mode", queryParm)
        putAGSList(aGSalesList, queryParm)
          .then((res) => {
            setLoader(false);
            toast.success("Save succesfull!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          })
          .catch((err) => {
            console.log("error", err)
            setLoader(false);
            toast.error("Save failed!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          });
      }
    } else {
      toast.success("No Data Available!");
    }
  };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = (event) => {
    if (aGSalesList.length != 0 || aGSalesList != undefined) {
      setLoader(true);
      if (freezeCTA) return;
      let queryParm

      if (mode == "create") {
        queryParm = "cfy=" + cfy + "&status=" + "P"
        postAGSList(aGSalesList, queryParm)
          .then((res) => {
            setLoader(false);
            console.log("it success", mode)
            toast.success("Submit succesfull!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          })
          .catch((err) => {
            console.log("catch err", mode, err)
            setLoader(false);
            toast.error("Submit failed!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          });
      }
      if (mode == "edit") {
        queryParm = "request_id=" + requestID + "&status=" + "P"
        console.log("query param edit mode", reqid)
        putAGSList(aGSalesList, queryParm)
          .then((res) => {
            setLoader(false);
            console.log("it success", mode)
            toast.success("Submit succesfull!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          })
          .catch((err) => {
            setLoader(false);
            console.log("catch err", mode, err)
            toast.error("Submit failed!");
            setTimeout(() => {
              navigate("/agsales/agroot");
            }, 1000);
          });
      }
    } else {
      toast.success("No Data Available!");
    }
  };

  const onCancelHandler = () => {
    return navigate("/agsales/agroot");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/agsales/agroot");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      request_id: requestID,
      remarks: remark,
      status: `${value === "approve" ? "A" : "R"}`
    }
    console.log("data", data)

    getaproveraction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/agsales/agroot");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  const onPermission = () => {
    return navigate("/mymarket/marketsize");
  };

  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();
  //JSX MARKUP
  return (
    <>
      <div className={styles["agri-portal-container"]}>
        <ToastContainer autoClose={1000} />
        <div className={styles["agri-portal-content"]}>
          <div
            className={styles["heading"]}
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <h4 class="inner-page-title" style={{ margin: "0" }}>
              Sales Data
            </h4>
            <h6
              style={{
                color: "#0195D4",
                padding: "4px 8px",
                border: "1px solid #0195D4",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              Quantity In Lt/KG
            </h6>
            <div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>CURRENT FY</b>
                </div>
                <div className={styles["valuebox"]}>{cfy}</div>
              </div>
            </div>
          </div>
          <div className="col-md-12 my-2">
            <div className="border-top md-3"> </div>
          </div>
          <div
            className={styles["heading"]}
            style={{
              paddingTop: "0",
              marginTop: "10px",
              paddingBottom: "15px",
              // justifyContent: "start",
            }}
          >
            <div
              className={styles["agri-portal-label"]}
              style={{ display: "flex" }}
            >
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>ZONE</b>
                </div>
                <div className={styles["valuebox"]}>{marketSize?.zone_name}</div>
              </div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>REGION</b>
                </div>
                <div className={styles["valuebox"]}>
                  {marketSize?.region_name}
                </div>
              </div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>TERRITORY</b>
                </div>
                <div className={styles["valuebox"]}>
                  {marketSize?.territory_name}
                </div>

              </div>
              {/* <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing"> */}
              {/* {finalUrlValue?.D ? ( */}
              <div style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "100px",
              }}>
                <div>
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                      style={{ fontSize: "14px" }}
                    >
                      <img
                        src="images/export.png"
                        alt=""
                        className="mr-2"
                      />
                      Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          // disabled={userList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails()
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>
              </div>
              {((mode != undefined && mode == "create") || (mode != undefined && mode == "edit")) && (

                <div style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}>
                  <button className="btn btn-danger" onClick={importuser}>
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Import Data
                  </button>
                </div>
              )}

            </div>
            <div >
              <h6 style={{ alignItems: "right", justifyContent: "center", color: "#0195D4" }} >*Actual Sales data upto the month {month}</h6>
            </div>
          </div>

          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div>
                <div class="table-responsive" className={styles["table_css"]} style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }} >
                  <table class="table table-striped table-bordered tablecured ">
                    <thead>
                      <tr style={{ minWidth: "150px" }}>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>Product Code</th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}> Product Name  </th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>Budget Annual Plan<br></br> {" ( APR " + (clfy - 1) + " - Mar " + (lfy - 1) + ")"}</th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>*Actual Sales<br></br> {" ( APR " + (clfy - 1) + " - " + month + " " + (flag ? (lfy - 2) : (lfy - 1)) + ")"}</th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>Quantity <br></br>{" ( APR " + clfy + " - MAR " + lfy + ")"}</th>
                      </tr>
                    </thead>
                    <tbody>


                      {((mode != undefined && mode == "view") || (mode != undefined && mode == "approve")) &&
                        (aGSalesList != undefined && (aGSalesList.map((el, i) => {
                          return <tr>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.product_group}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.product_group_name}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.ap_qty}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.ytm_sales}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.fy_budget_qty}  </td>

                          </tr>
                        })))}

                      {aGSalesList.length ? (
                        (mode != undefined) && (mode == "create" || mode == "edit") &&
                        (aGSalesList != undefined && (aGSalesList.map((el, i) => {
                          return <tr>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.product_group}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.product_group_name}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textAlign: "right", textOverflow: "clip", whiteSpace: "normal" }}>{el.ap_qty}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textAlign: "right", textOverflow: "clip", whiteSpace: "normal" }}>{el.ytm_sales}  </td>
                            <td className={styles["agri-portal-table"]}
                              style={{ maxWidth: "80px", minWidth: "80px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <input className="inputhtStyle" style={{ textAlign: "right", width: "100%", padding: "2px" }} type="number" value={el.fy_budget_qty} onWheel={(e) => e.target.blur()} onChange={(e) => onChangeBQty(e.target.value, i, "fy_budget_qty", mode)} onKeyDown={(event) => { if (event.target.value.length >= 10 && event.key != "Backspace") { event.preventDefault() } else if (event.key == "ArrowUp" || event.key == "ArrowDown") { event.preventDefault() } }}

                              />
                            </td>




                          </tr>
                        })))) : (
                        <tr>
                          <td colSpan={17}> No Record Found</td>
                        </tr>)}
                    </tbody>

                  </table>
                </div>
              </div>

            </div>
          </div>

          {approvalList?.approvals && approvalList?.approvals.length > 0 && (
            <div className={styles["table_contain"]}>
              <div className={styles["agri-portal-table"]}>
                <div
                  class="table-responsive"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <table
                    class="table table-striped table-bordered tablecured"
                    style={{ maxHeight: "200px", marginTop: "10px" }}
                  >
                    <thead style={{ position: "sticky", top: 0 }}>
                      <tr style={{ textAlign: "center" }}>
                        <th>Action At</th>
                        <th>Action By</th>
                        <th>Action User</th>
                        <th>Action Status</th>
                        <th>Action Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvalList?.approvals &&
                        approvalList?.approvals.map((el, i) => {
                          let status;

                          const findPendingIndex =
                            approvalList.approvals.findIndex(
                              (ele) => ele.status == "P" && ele.discarded == false
                            );

                          if (el.status == "D") status = "Saved";
                          else if (el.status == "P" && el.discarded == true)
                            status = "Auto Discarded";
                          else if (el.status == "P") status = "Pending";
                          else if (el.status == "A") status = "Approved";
                          else if (el.status == "R") status = "Rejected";
                          else if (el.status == "RP") status = "Auto Rejected";

                          return (
                            <tr>
                              <td>

                                {status == "Pending"
                                  ? "-"
                                  : status == "Auto Discarded" ? "-"
                                    : moment(el.action_at)
                                      .utc()
                                      .format("DD-MM-YYYY HH:mm:ss")}
                              </td>
                              <td>{el.action_user_code}</td>
                              <td>{el.action_user_name}</td>
                              <td>
                                {findPendingIndex == -1
                                  ? status
                                  : i > findPendingIndex
                                    ? "-"
                                    : status}
                              </td>
                              <td>{el.remarks}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}


          <div className={styles["ctas"]}>
            {mode != "view" && mode != "approve" && (
              <div className={styles["ctas_left"]}>
                <div
                  class="btn btn-success"
                  onClick={() => {
                    confirmDialog("P");
                  }}
                >
                  Submit
                </div>
                <div
                  class="btn btn-primary-inner"
                  onClick={() => {
                    confirmDialog("D");
                  }}
                >
                  Save
                </div>
              </div>
            )}

            {mode == "approve" || approvalList?.for_approval ? (
              <div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Add Comments <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-right pb-3">
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("approve")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-outline-danger mr-3 ml-3"
                    type="button"
                    onClick={() => {
                      onApprovalCancelHandler();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("reject")}
                  >
                    Decline
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles["ctas_right"]}>
                <div class="btn btn-danger" onClick={onCancelHandler}>
                  Cancel
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i className="fas fa-file-upload fa-2x"></i>
                  <p>Upload exported file with updated {cfy} data</p>
                  <button className="btn btn-primary-inner" type="button">
                    CHOOSE FILE
                  </button>
                  {/* <div className="mt-3 downloadtemplate">
                    <button className="editbtn">
                      <i className="far fa-file-excel"></i>
                      <a href="import.xlsx" download>
                        DOWNLOAD TEMPLATE
                      </a>
                    </button>
                  </div> */}
                  <input
                    type="file"
                    title=""
                    id="import_user"
                    accept=".xlsx, .xls"
                    className="form-control"
                    onChange={(e) => onProfileChange(e, "import_user")}
                  />
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span className="badge bad-status mb-3 badge-warning">
                    {uploadData?.original_name}{" "}
                    <i className="far fa-times-circle"></i>
                  </span>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => importExel(uploadData)}
                    >
                      Proceed to Upload
                    </button>
                  )}
                </div>


                {showStatus && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                 
                    <span className="badge bad-status mb-3 badge-success">
                      Records Succeded:
                      {excelListImport?.records_uploaded}
                    </span>
                    <span className="badge bad-status mb-3 badge-danger">
                      Records Failed:
                      {excelListImport?.records_failed}
                    </span>

                    <p>In case of failed records, click on download button to get the error details.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}


              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Root;

