import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Pagination,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import * as PrService from "../../service/prService";
import { toast } from "react-toastify";
import moment from "moment/moment";
import * as AttendanceService from "../../service/AttendanceService";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import * as searchPoService from "../../service/searchpo";

function PRreports() {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [filterApplied, setFilterApplied] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [reportData, setReportData] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('');
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('');
  const [nextSapApprovalPostingTime, setNextSapApprovalPostingTime] = useState('');
  const [nextELNSyncingTime, setNextELNSyncingTime] = useState('');
  const [filterReport, setFilterReport] = useState({
    end_date: "",
    start_date: "",
    source: "",
    user: "",
    sap_pr_no: "",
    status: "",
    prId: "",
    cas_no: "",
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getData(
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      pathname === "/pr-reports" ? true : false,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
      "",
      filterReport.status
    );
    setCurrentPage(activePage);
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      pathname === "/pr-reports" ? true : false,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
      "",
      filterReport.status
    );
    getUserData(0)
    getSapSchedule()
  }, [pageSizeNo]);

  const getData = async (page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, status, prId, cas_no) => {
    try {
      const response = await PrService.getPrReports(handleKey(page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, status, prId, cas_no));
      dispatch(setDisplayLoader("Hide"));
      if (response.data.code === 200) {
        setReportData(response?.data?.dataList?.result);
        // let obj = response?.data?.dataList?.result?.map((res)=>{
        //   return {
        //     value: res?.user_username,
        //     label: res?.user_name
        //   }
        // })
        // let jsonObject = obj.map(JSON.stringify);
        // let uniqueSet = new Set(jsonObject);
        // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        // setUserOptions(uniqueArray)
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warn(error.message);
    }
  };

  const getUserData = (value) => {
    AttendanceService.getUserDataNew(value)
      .then((response) => {
        let obj = response?.data?.dataList?.result
          ?.map((res) => {
            return {
              value: res?.id,
              label: res?.first_name + res?.last_name + "(" + res?.username + ")"
            };
          });
        setUserOptions(obj);
        // setModuleList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (page_no, page_size, sort_by, paginate, report_flag, end_date, start_date, sap_pr_no, source, user, search, status, prId, cas_no) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (
      report_flag !== "" &&
      report_flag !== undefined &&
      report_flag !== null
    ) {
      queryParm = queryParm + "&report_flag=" + report_flag;
    }
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (sap_pr_no !== "" && sap_pr_no !== undefined && sap_pr_no !== null) {
      queryParm = queryParm + "&sap_pr_no=" + sap_pr_no;
    }
    if (source !== "" && source !== undefined && source !== null) {
      queryParm = queryParm + "&source=" + source;
    }
    if (user !== "" && user !== undefined && user !== null) {
      queryParm = queryParm + "&user=" + user;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (prId !== "" && prId !== undefined && prId !== null) {
      queryParm = queryParm + "&prId=" + prId;
    }
    if (cas_no !== "" && cas_no !== undefined && cas_no !== null) {
      queryParm = queryParm + "&cas_no=" + cas_no;
    }

    return queryParm;
  };

  const getExportDetails = () => {
    dispatch(setDisplayLoader("Display"));
    PrService.getExportPRReport(
      handleKey(currentPage, pageSizeNo, sortData.sort_by, true, pathname === "/pr-reports" ? true : false)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PrReportItemWise.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterReport.end_date;
    const start_date = filterReport.start_date;
    let user = ""
    if (filterReport.user?.length > 0) {
      user = filterReport.user[0]?.value;
    }
    else {
      user = ""
    }
    const source = filterReport.source;
    const sap_pr_no = filterReport.sap_pr_no;
    const cas_no = filterReport.cas_no;
    const status = filterReport.status
    const prId = filterReport?.prId
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      pathname === "/pr-reports" ? true : false,
      end_date,
      start_date,
      sap_pr_no,
      source,
      user,
      "",
      status,
      prId,
      cas_no
    );
    filterclose();
  };

  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    // resetFilterData();
    setFilterReport({
      ...filterReport,
      end_date: "",
      start_date: "",
      source: "",
      user: "",
      sap_pr_no: "",
      status: "",
      prId: "",
      cas_no: ""
    })
    getData(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      pathname === "/pr-reports" ? true : false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      getData(
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        pathname === "/pr-reports" ? true : false,
        filterReport.end_date,
        filterReport.start_date,
        filterReport.sap_pr_no,
        filterReport.source,
        filterReport.user,
        search,
        filterReport.status
      );
    }
  };

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_pr_on_sap_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_sap_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_eln_data').then((response) => {
      let next_eln_syncing_time = response?.data?.dataList?.result
      setNextELNSyncingTime(next_eln_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_post_approved_pr_item_in_sap').then((response) => {
      let next_sap_approval_syncing_time = response?.data?.dataList?.result
      setNextSapApprovalPostingTime(next_sap_approval_syncing_time)
    })
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">PR Reports</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-2"></div> */}
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* {pathname === "/pr-reports" && ( */}
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* )} */}


                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next ELN Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextELNSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Posting of Approved Items:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapApprovalPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="approved" id="approvaltabs">
                <Tab
                  eventKey="approved"
                  title={<React.Fragment>Itemwise PR</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>View SAPPR</th>
                          <th>PR NO</th>
                          <th>View Item</th>
                          <th>Creation Date</th>
                          <th>PR Type</th>
                          <th>Plant</th>
                          <th>Purchasing Grp</th>
                          <th>Source</th>
                          <th>Initiator</th>
                          <th>Material Code</th>
                          <th>Material Desc</th>
                          <th>CAS No</th>
                          <th>Status</th>
                          <th>Pending At</th>
                          <th>SAP Release Status</th>
                          <th>SAP Log</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData?.length > 0 ?
                          reportData?.map((data) => {
                            return (
                              data?.items?.map((itemData) => {
                                return (
                                  <tr>
                                    <td class="text-theme">
                                      <Link
                                        to="/view-pr-approval"
                                        state={{
                                          from: itemData?.id,
                                          Itemsflag: true,
                                          view_level: "PR",
                                          master_flag: true,
                                          pr_mast_id: data?.id,
                                        }}
                                        class="fw-bold text-theme"
                                      >
                                        {data?.sap_pr_no}
                                      </Link>
                                    </td>
                                    <td class="text-theme">
                                      <Link
                                        to="/view-pr-approval"
                                        state={{
                                          from: itemData?.id,
                                          Itemsflag: true,
                                          view_level: "PR",
                                          master_flag: true,
                                          pr_mast_id: data?.id,
                                        }}
                                        class="fw-bold text-theme"
                                      >
                                        PRNO{data?.id}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to="/view-pr-approval"
                                        state={{
                                          from: itemData?.id,
                                          Itemsflag: true,
                                          view_level: "item",
                                          master_flag: false,
                                          pr_mast_id: data?.id,
                                        }}
                                      >
                                        <span class="badge badge-secondary">
                                          {itemData?.item_no}
                                        </span>
                                      </Link>
                                    </td>
                                    <td class="date-start">{moment.utc(data?.created_at).format("DD-MM-YYYY")}</td>
                                    <td class="category">{data?.type}</td>
                                    <td>
                                      <Tooltip title={itemData?.plant_name} position="bottom">
                                        {itemData?.plant_code}
                                      </Tooltip>
                                    </td>
                                    <td>{itemData?.purchase_group_name} ({itemData?.purchase_group_code})</td>
                                    {data?.source === "M" ?
                                      <td>MYPI</td>
                                      : data?.source === "E" ?
                                        <td>ELN</td>
                                        : data?.source === "S" ?
                                          <td>SAP</td>
                                          :
                                          <td>{data?.source}</td>
                                    }
                                    <td>
                                      <Tooltip title={data?.user_name} position="bottom">
                                        {data?.user_name}
                                      </Tooltip>
                                    </td>
                                    <td>{itemData?.material_code}</td>
                                    {itemData?.material_id === null ?
                                      <td>{itemData?.material_desc}</td>
                                      :
                                      <td>{itemData?.mat_desc2}</td>
                                    }
                                    <td>{itemData?.cas_no}</td>
                                    <td>
                                      {data?.Draft == true ?
                                        <span class="badge bad-status badge-warning">
                                          Draft
                                        </span> : data?.status == "A" && itemData?.delete_indicator !== 'X' ? (
                                          <span class="badge bad-status badge-success">
                                            Approved
                                          </span>
                                        ) : data?.status == "D" && itemData?.delete_indicator !== 'X' ? (
                                          <span class="badge bad-status badge-danger">
                                            Rejected
                                          </span>
                                        ) : data?.status == "P" && data?.level == "User" && itemData?.delete_indicator !== 'X' ? (
                                          <span class="badge bad-status badge-warning">
                                            Submitted
                                          </span>
                                        ) : data?.status == "P" &&
                                          data?.level == "Reviewer" && itemData?.delete_indicator !== 'X' ? (
                                          <span class="badge bad-status badge-warning">
                                            Partially Approved
                                          </span>
                                        ) : itemData?.delete_indicator === 'X' ? (
                                          <span class="badge bad-status badge-secondary">
                                            Deleted
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                    </td>
                                    {itemData?.currentApprover?.length > 0 ?
                                      itemData?.currentApprover[0]?.approver_type === "Approver 2" ?
                                        <td>
                                          <Tooltip title={itemData?.currentApprover[0]?.approver_name + " - C2"} position="bottom">
                                            {itemData?.currentApprover[0]?.approver_name} - C2
                                          </Tooltip>
                                        </td>
                                        : itemData?.currentApprover[0]?.approver_type === "Approver 3" ?
                                          <td>
                                            <Tooltip title={itemData?.currentApprover[0]?.approver_name + " - C3"} position="bottom">
                                              {itemData?.currentApprover[0]?.approver_name} - C3
                                            </Tooltip>
                                          </td>
                                          :
                                          <td>
                                            <Tooltip title={itemData?.currentApprover[0]?.approver_name + " - C1"} position="bottom">
                                              {itemData?.currentApprover[0]?.approver_name} - C1
                                            </Tooltip>
                                          </td>
                                      :
                                      <td></td>
                                    }
                                    {itemData?.status == "A" && itemData?.currentApprover?.length == 0 ?
                                      <td>Synced</td>
                                      :
                                      <td>Pending to Sync</td>
                                    }
                                    <td>
                                      <Tooltip title={data?.log} position="bottom">
                                        {data?.log}
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              })
                            )
                          })
                          :
                          <tr>
                            <td colSpan={12}>No Records Found...</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select class="form-control" onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1)
                        }}>
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              PR ID<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter PR ID"
              value={filterReport.prId}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  prId: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>
              SAPPR NO<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter SAP PR NO"
              value={filterReport.sap_pr_no}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  sap_pr_no: e.target.value,
                })
              }
            />
            {/* <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}
          </div>

          <div class="form-group innergroup">
            <label>
              User<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={userOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterReport.user}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  user: e,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Source<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              value={filterReport.source}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  source: e.target.value,
                })
              }
            >
              <option>Select</option>
              <option value={"M"}>MyPI</option>
              <option value={"E"}>ELN</option>
              <option value={"S"}>SAP</option>
            </select>
            {/* <Select
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}
          </div>
          <div class="form-group innergroup">
            <label>
              CAS No.<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter CAS No"
              value={filterReport.cas_no}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  cas_no: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              value={filterReport.status}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  status: e.target.value,
                })
              }
            >
              <option>Select</option>
              <option value={"A"}>Approved</option>
              <option value={"P"}>Submitted</option>
              <option value={"D"}>Rejected</option>
              <option value={"PA"}>Partially Approved</option>
              <option value={"Draft"}>Draft</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Start Date</label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  start_date: e.target.value,
                })
              }
              value={filterReport.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>End Date</label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  end_date: e.target.value,
                })
              }
              value={filterReport.end_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => {
            resetFilter();
          }}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={() => mainDataFilter()}>Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PRreports;
