import React, { Suspense, useState, useEffect } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";

import Sidebar from "./sidebar";
import { CustomBreadcrumb } from "./custom-breadcrump";
import * as MedicalService from "../../service/medicalService";
import {
  selectUserData
} from "../redux/piDataStore";
import {
  Link,
  matchPath,
  NavLink as RouterNavLink,
  Outlet,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const CRUMB_ITEMS = [
  { to: "/home", title: "Home" },
  { to: "/", title: "Me@PI" },
  { active: true, title: "Employee Insurance" },
];

function MediclaimDecl() {
  const { pathname } = useLocation();
  const userData = useSelector(selectUserData);
  const match = (path) =>
    path ? !!matchPath({ path, end: true }, pathname) : false;

  const [addnewshow, addnewsetShow] = useState(false);
  const [outstandshow, outstandsetShow] = useState(false);
  const [mediclaimData,setMediclaimData] = useState(false);
  const [mediclaimSetting,setMediclaimSetting] = useState([]);
  // const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  // const outstanding = () => outstandsetShow(true);
  const outstandclose = () => outstandsetShow(false);

  // useEffect(() => {
  //   MedicalService.mediclaminDetails({
  //     user_id: userData?.id,
  //   }).then((response)=>{
  //     let data = response?.data?.dataList?.result
  //     if(data.length > 0){
  //       setMediclaimData(data[0])
  //       console.log(data[0]);
  //     }
      
  //   });
  // }, []);

  useEffect(() => {
    MedicalService.mediclaminDeclaration(
      userData?.organisation_id
    ).then((response)=>{
      let data = response?.data?.dataList?.result
      if(data.length > 0){
        console.log(data[0])
        setMediclaimSetting(data[0])
      }
      
    })
  },[]);
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <Sidebar />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    Declaration for Dependant - Company Group
                    {(!mediclaimSetting?.topup && mediclaimSetting?.mediclaim) ? ' Mediclaim Policy' : (mediclaimSetting?.topup && !mediclaimSetting?.mediclaim) ? ' Voluntary Top-up Policy' : ' Mediclaim Policy/ Voluntary Top-up Policy'}
                    {/* {(mediclaimData.voluntary_top_up) ? ' Voluntary Top-up ': ' Mediclaim '} Policy */}
                  </h4>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              {/* <Tabs id="approvaltabs" style={{ overflow: 'auto' }}>
                <Tab active={match('/mediclaim-decl')} eventKey="mediclaimDeclrationAdd" title={<RouterNavLink className={match('/mediclaim-decl') ? 'text-primary' : 'text-dark'} to={'/mediclaim-decl'}>Add Dependant/Topup</RouterNavLink>} />
                <Tab active={match('/mediclaim-decl/edit')} eventKey="mediclaimDeclrationEdit" title={<RouterNavLink className={match('/mediclaim-decl/edit') ? 'text-primary' : 'text-dark'} to={'/mediclaim-decl/edit'}>Edit Dependant Declaration</RouterNavLink>} />
                <Tab active={match('/mediclaim-decl/open')} eventKey="mediclaimDeclrationOpen" title={<RouterNavLink className={match('/mediclaim-decl/open') ? 'text-primary' : 'text-dark'} to={'/mediclaim-decl/open'}>Open Dependant Declaration</RouterNavLink>} />
              </Tabs> */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Advance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <table class="table table-advance">
                  <thead>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Amount</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>13-01-2023</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>15-01-2023</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>17-01-2023</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 700
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>24-01-2023</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 1220
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={outstandshow}
        onHide={outstandclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Outstanding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <table class="table table-advance">
                  <thead>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Amount</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>13-01-2023</td>
                      <td>Description will come here</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 100
                      </td>
                      <td>-</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>15-01-2023</td>
                      <td>Description will come here</td>
                      <td>-</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 100
                      </td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>17-01-2023</td>
                      <td>Description will come here</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 100
                      </td>
                      <td>-</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 700
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>24-01-2023</td>
                      <td>Description will come here</td>
                      <td>-</td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 100
                      </td>
                      <td>
                        <i class="fas fa-rupee-sign"></i> 1220
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MediclaimDecl;
