import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as surveyService from "../../service/Survey";
import { toast, ToastContainer } from "react-toastify";

const SelectedTypeUser = (props) => {
  const { current } = props;
  const [dataType, setDataType] = useState();
  const [valueDataType, setValueDataType] = useState();
  const [counts, setCounts] = useState({
    Assessment: 0,
    Polls: 0,
    Survey: 0,
    Feedback: 0,
  });

  const getAllSurveyType = () => {
    surveyService
      .getAllCategoryType()
      .then((response) => {
        setDataType(response.data.dataList.result);
        console.log(`alltips`, response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getAllSurveyType();
  }, []);

  useEffect(() => {
    if (dataType !== undefined) {
      surveyService
        .getSurvey(`client=${true}`)
        .then((response) => {
          console.log(` responn`, response.data);
          let countss = { ...counts };
          response?.data?.dataList?.result.map((item, idx) => {
            switch (item.assessment_type_name) {
              case "Assessment":
                countss.Assessment = countss.Assessment + 1;
                break;
              case "Survey":
                countss.Survey = countss.Survey + 1;
                break;
              case "Polls":
                countss.Polls = countss.Polls + 1;
                break;
              case "Feedback":
                countss.Feedback = countss.Feedback + 1;
                break;
            }
          });
          setCounts(countss);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  }, [dataType]);

  return (
    <div class="col-md-3">
      <ul class="policylistnames">
        {dataType?.map((item, index) => (
          <li class={item?.name == current ? "active" : ""}>
            <Link
              to={
                item?.name === "Assessment"
                  ? "/survey-assessment"
                  : item?.name === "Polls"
                    ? "/survey-polls"
                    : item?.name === "Survey"
                      ? "/survey-surveys"
                      : item?.name === "Declaration"
                        ? "/survey-feedback"
                        : "/survey-assessment"
              }
            >
              {item?.name}
            </Link>
            <span class="numberpurchse active float-right mr-2">
              {item?.name === "Assessment"
                ? counts.Assessment
                : item?.name === "Polls"
                  ? counts.Polls
                  : item?.name === "Survey"
                    ? counts.Survey
                    : counts.Feedback}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedTypeUser;
