import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { getOrganisation } from "../../../service/policy";
import * as MediclaimService from "../../../service/medicalService";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { getOnlyNumber } from "../../../utils/helpers";

const UserDeclaration = () => {
  const [records, setRecords] = useState([]);
  const [formobj, setFormobj] = useState({
    empId: "",
    orgId: "",
  });

  useEffect(() => {
    fetchOrgs();
  }, []);

  const fetchOrgs = async () => {
    const { data } = await getOrganisation(false);
    const response = await MediclaimService.getOpenMediclaimDecl();
    if (
      Array.isArray(data?.dataList?.result) &&
      data?.dataList?.result.length > 0
    ) {
      const oldData = response?.data?.dataList?.result;
      const array = data?.dataList?.result.map((item) => {
        const old = oldData?.find
          ? oldData.find((_item) => item.id == _item.organisation_id)
          : [];
        return {
          org: item,
        };
      });
      setRecords(array);
    }
  };

  const mediclaimOpenStatus = (index) => {
    let obj = records[index];
    MediclaimService.changeMediclaimStatusForUser(obj).then((response) => {
      if (response?.status === 200) {
        toast.success(response?.data?.message);
      }
    });
  };

  const changeHandler = (value, name, index) => {
    switch (name) {
      case "empId":
        let curData = records;
        curData[index].empId = value;
        setRecords(curData);
        break;
    }
  };

  return (
    <>
      <div class="px-3 pb-2">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec pb-0">
                  <div className="row">
                    <div class="col-md-12">
                      <h4 class="inner-page-title">
                        User Mediclaim Declaration
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured leavetableapply vehiclelog innergroup">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Company Code</th>
                      <th>Employee Code</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item, index) => {
                      return (
                        <tr key={`org-listing-${index}`}>
                          <td>{index + 1}</td>
                          <td class="fw-bold text-theme">
                            {item?.org?.name || "N/A"}
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              onChange={(e) => {
                                changeHandler(e.target.value, "empId", index);
                              }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-primary-inner bpi-main"
                              onClick={() => {
                                mediclaimOpenStatus(index);
                              }}
                            >
                              Open
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDeclaration;
