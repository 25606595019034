import React from "react";
import { Link } from "react-router-dom";

const CreateNewSurvey = (props) => {
  const { setTabStatus } = props;
  return (
    <div class="row justify-content-center">
      <div class="col-md-3">
        <div
          class="surveyselectbox h-100"
          onClick={(e) => setTabStatus("Addressess")}
        >
          <img src="images/createsurvey.png" height={100} />
          <h4 class="text-theme">Start your Survey from Scratch</h4>
          <p>Build your survey from Scratch</p>
        </div>
      </div>
      <div class="col-md-2">
        <center >OR</center>
      </div>
      <div class="col-md-3">
          <div class="surveyselectbox" onClick={() => setTabStatus("Personal")}>
            <img src="images/selectsurvey.png" height={100} />
            <h4 class="text-success">
              Start your Survey from our quick templates
            </h4>
            <p>
              Use the template to see the question types in action, edit the
              survey and questions to meet your needs.
            </p>
          </div>
      </div>
    </div>
  );
};  

export default CreateNewSurvey;
