import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Paginatio,
  Spinner,
} from "react-bootstrap";
import AddSkillGroup from "./AddSkillGroup";
import ViewSkillGroup from "./ViewSkillGroup";
import { Link } from "react-router-dom";
import * as SkillGroupService from "../../service/skillGroup";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
if (typeof window !== "undefined") {
  injectStyle();
}

const SkillGroup = () => {
  const dispatch = useDispatch();

  const [searchStr, setSearchStr] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [viewForm, setViewForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewFormData, setViewFormData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [permissionAccess, setPermissionAccess] = useState({});
  const [showSkillTab, setShowSkillTab] = useState(false)

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSkillGroup = (search, pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillGroupService.getSkillGroup(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (search, pageNo, page_size, sort_by, paginate) => {
    SkillGroupService.getExportValue(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-group-master.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addSkills = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillData(null);
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };

  const editSkill = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillData(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setSkillData(null);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const onAddCallBack = () => {
    setShowForm(false);
    getSkillGroup("", currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const deleteSkillGroup = (idx) => {
    SkillGroupService.deleteSkillGroup(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillGroup("", currentPage, pageSizeNo, sortData.sort_by, true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, name) => {
    confirmAlert({
      title: "Delete Skill Group",
      message: `Are you sure to delete ${name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkillGroup(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillGroup(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSkillGroup(data, 1, pageSizeNo, sortData.sort_by, true);
    }
  };

  const handleSearch = (e) => {
    getSkillGroup(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    setSearchStr(e.target.value);
  };
  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getSkillPermission();
    getSkillGroup(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <div class="row">
          <ToastContainer autoClose={1000} />
          <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li class="active">
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill-Group</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Group Code,
                              <br />
                              Group Name,
                              <br />
                              Group Department
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="input-group table-search">
                        <span className="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e)}
                        />

                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {permissionAccess?.skill_group_update === true && (
                        <>
                          <button
                            className="btn btn-primary-inner"
                            onClick={() => {
                              addSkills();
                            }}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Add New
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table className="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Group Code</th>
                      <th>Group Name</th>
                      <th>Group Department</th>
                      <th>Last Updated By</th>
                      <th> Last Updated On</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td>{data?.group_id}</td>
                            <td>{data?.group_name}</td>
                            <td>{data?.group_dept}</td>

                            <td>{data?.updated_by}</td>
                            <td>
                              {moment
                                .utc(data?.updated_at)
                                .format("Do MMM YYYY, h:mm:ss a")}
                            </td>
                            <td>
                              {data?.is_active ? (
                                <span className="badge bad-status badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bad-status badge-danger">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary"
                                onClick={() => {
                                  onView(data);
                                }}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                              {permissionAccess?.skill_group_update ===
                                true && (
                                  <>
                                    <button
                                      className="btn btn-blanktd text-primary ml-2"
                                      onClick={() => {
                                        editSkill(data);
                                      }}
                                    >
                                      <i className="far fa-edit"></i>
                                    </button>
                                    <button
                                      className="btn btn-blanktd text-danger ml-2"
                                      onClick={() => {
                                        submit(data?.id, data?.group_id);
                                      }}
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                  </>
                                )}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showForm ? (
        <>
          <AddSkillGroup
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editSkills={skillData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
      {viewForm ? (
        <>
          <ViewSkillGroup
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SkillGroup;
