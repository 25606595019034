import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as BrandingCategoryService from "../../../service/brandingCategory";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    )
    .required("*Branding Category name is required"),
  organisation_id: Yup.string().required("*Select Organisation").nullable(),
});

const AddBrandingCategory = (props) => {
  const nameForm = "Branding Category Master";
  const { edit, showForm, onClose, editbrandingCategory, onAddCallBack } =
    props;
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allBrandingCategory, setAllBrandingCategory] = useState([]);
  const [isBrandingCategoryExist, setIsBrandingCategoryExist] = useState(false);
  const [brandingCategoryData, setBrandingCategoryData] = useState({
    name: "",
    organisation_id: "",
    is_active: true,
  });

  const saveBrandingCategory = (values) => {
    setIsLoading(true);
    BrandingCategoryService.saveBrandingCategory(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateBrandingCategory = (values) => {
    setIsLoading(true);
    BrandingCategoryService.updateBrandingCategory(values, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getOrganisation = (paginate) => {
    BrandingCategoryService.getOrganisation(paginate)
      .then((response) => {
        setAllBrandingCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isBrandingCategoryExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editbrandingCategory?.name == e.target.value) {
      setIsBrandingCategoryExist(false);
      return;
    } else {
      BrandingCategoryService.isBrandingCategoryExists(data)
        .then((res) => {
          if (e.target.name == "name") {
            setIsBrandingCategoryExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  useEffect(() => {
    if (editbrandingCategory) {
      console.log(editbrandingCategory, "editbrandingCategory");
      setBrandingCategoryData(editbrandingCategory);
      Object.keys(editbrandingCategory).forEach((item) => {
        brandingCategoryData[item] = editbrandingCategory[item];
      });
    }
  }, [editbrandingCategory]);
  useEffect(() => {
    getOrganisation(false);
  }, []);

  useEffect(() => {}, [auditValueLog]);

  return (
    <>
      <Formik
        initialValues={brandingCategoryData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isBrandingCategoryExist !== true) {
            if (values.id) {
              updateBrandingCategory(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              saveBrandingCategory(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Branding Category
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Branding Category Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter Branding Category Name"
                            maxLength={30}
                            onChange={handleChange}
                            onBlur={(e) => {
                              isBrandingCategoryExists(e, "name");
                              handleBlur(e);
                            }}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : isBrandingCategoryExist === true ? (
                            <div className="small text-danger">
                              Branding Category Name already exists
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Organisation<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect main-pointer"
                            classnameprefix="select"
                            style={{ width: "334px" }}
                            name="organisation_id"
                            // options={options}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setBrandingCategoryData({
                                ...brandingCategoryData,
                                organisation_id: e.target.value,
                              });
                            }}
                            value={values.organisation_id}
                          >
                            <option value="" label="Select Organisation" />

                            {renderOrgOptions(allBrandingCategory)}
                          </select>
                          {touched.organisation_id && errors.organisation_id ? (
                            <div className="small text-danger">
                              {errors.organisation_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">Status</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddBrandingCategory;
