import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown, Button } from "react-bootstrap";
import Select from "react-select";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService";
import * as OrganisationService from "../../service/organisation";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";

function TravelPickup() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);

  const dispatch = useDispatch();

  const [show, filtersetShow] = useState(false);
  const [showsDiscard, setShowDiscard] = useState(false);
  const handleCloseDiscard = () => setShowDiscard(false);
  const handleShowDiscard = () => setShowDiscard(true);
  const [discardReason, setDiscardReason] = useState("");

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-claim_date",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(1000);
  const [pageCount, setPageCount] = useState(1);
  const [travelList, setTravelList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [organisation_User, setOrganisation_User] = useState({
    company: "",
    user_id: "",
  });

  const [filterdata, setfilterData] = useState({
    user_id: "",
    company: "",
    trip_number: "",
    from_date: "",
    to_date: "",
  });

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    };
  };

  

  const navigate = useNavigate();

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelPickList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id,
      filterdata?.company,
      filterdata?.trip_number,
      filterdata?.from_date,
      filterdata?.to_date,
      false
    );
  };

  useEffect(() => {
    getUsersList();
    getAllOrganisation();

    console.log(`....${finalUser}`);
    console.log(`....${finalUser.designation_name}`);

    getTravelPickList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );
  }, []);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&isFiltering=" +
      isFiltering;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&company=" + company;
    }
    if (
      trip_number !== "" &&
      trip_number !== undefined &&
      trip_number !== null
    ) {
      queryParm = queryParm + "&trip_number=" + trip_number;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    console.log(`queryParm`, queryParm);
    return queryParm;
  };

  const getTravelPickList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {

    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelPickList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        user_id,
        company,
        trip_number,
        from_date,
        to_date,
        isFiltering
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setTravelList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };


  const getTravelPickListWithDebounce = debounce(
    getTravelPickList,
    2000
  );

  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length == 0) {
      setCurrentPage(1);
      getTravelPickListWithDebounce(
        e.target.value,
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        filterdata?.user_id,
        filterdata?.company,
        filterdata?.trip_number,
        filterdata?.from_date,
        filterdata?.to_date,
        false
      );

      setSearchStr(e.target.value);
    }
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
    });
  };

  const setCompantAndUser = (comp, uid) => {
    console.log(`setting`, comp, uid);

    if (comp) {
      let mdoUser = companyDropdown.filter((x) => {
        return x.value == comp;
      });
      // console.log('comp..',mdoUser);

      setOrganisation_User({
        ...organisation_User,
        company: mdoUser,
      });

      setfilterData({
        ...filterdata,
        company: comp,
      });
    }

    if (uid) {
      let mdoUser = usersDropdown.filter((x) => {
        return x.value == uid;
      });
      // console.log('uid..',mdoUser);

      setOrganisation_User({
        ...organisation_User,
        user_id: mdoUser,
      });
      setfilterData({
        ...filterdata,
        user_id: uid,
      });
    }
  };

  const mainDataFilter = () => {
    console.log(" filterdata:", filterdata);
    setCurrentPage(1);
    getTravelPickList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id,
      filterdata?.company,
      filterdata?.trip_number,
      filterdata?.from_date,
      filterdata?.to_date,
      true
    );

    filterclose();
  };

  const clearfilter = () => {
    filterclose();
    setCurrentPage(1);
    getTravelPickList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      false
    );
    setfilterData({
      user_id: "",
      from_date: "",
      to_date: "",
      company: "",
      trip_number: "",
    });
    setOrganisation_User({
      user_id: "",
      company: "",
    });
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(travelList));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setTravelList(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.map((res) => {
        if (res?.id != data?.id) {
          return res;
        }
      });
      setPostingData(obj);
      setTravelList(copyData1);
    }
  };

  const onReceive = () => {
    console.log(`pre toast`, postingData);
    if (postingData?.length < 1) {
      toast.error("Please select an expense to receive");
      return;
    }

    confirmAlert({
      title: "Receive Travel Pick List",
      message: `Are you sure to Receive Data?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => receivePickList(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onDecline = () => {
    if (postingData?.length < 1) {
      toast.error("Please select an expense to Discard");
      return;
    }
    handleShowDiscard();

    // confirmAlert({
    //   title: "Discard Travel Pick List",
    //   message: `Are you sure to Discard Data?`,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => declinePickList(),
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(travelList));
    if (!data?.length) {
      return;
    }
    let copyposData = JSON.parse(JSON.stringify(postingData));

    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        // postingData.push(res);
        let payl = {
          id: res?.id,
        };

        if (copyposData?.length > 0) {
          copyposData?.map((pdata) => {
            if (pdata?.id != payl?.id) {
              postingData.push(payl);
            }
          });
        } else {
          postingData.push(payl);
        }
      });
      setTravelList(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setTravelList(data);
      // let obj = postingData?.map((x) => {
      //   if (x?.id != data?.id) {
      //     return x;
      //   }
      // });
      setPostingData([]);
    }
  };

  const getExportValue = (
    search,
    page_no,
    page_Size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getExportValue(
      handleKey(
        search,
        page_no,
        page_Size,
        sort_by,
        paginate,
        status,
        user_id,
        company,
        trip_number,
        from_date,
        to_date,
        isFiltering
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TravelPick.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const receivePickList = () => {
    let data = [];

    postingData.map((res) => {
      if (res) {
        data.push({
          id: res?.id,
        });
      }
    });
    

    if (data?.length < 1) {
      toast.error("Please select an expense to receive");
      return;
    }

    let obj = { data: data };
    console.log(`toast`, obj);

    TravelListingAPI.receivePickList(obj).then((response) => {
      navigate("/travel-expense");
      console.log(response, "response");
      getTravelPickList(
        searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        "",
        "",
        false
      );

      setPostingData([]);
    });
  };

  const declinePickList = () => {
    if (!discardReason) {
      toast.error("Please select reason to discard");
      return;
    }
    // let data = postingData;
    let idList = [];
    postingData.map((res) => {
      if (res) {
        idList.push({
          id: res?.id,

          discard_reason: discardReason,
        });
      }
    });

    let obj = { data: idList };
    TravelListingAPI.declinePickList(obj).then((response) => {
      console.log(response, "response");
      getTravelPickList(
        searchStr,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        "",
        "",
        "",
        "",
        "",
        "",
        false
      );
      handleCloseDiscard();
      setPostingData([]);
      setDiscardReason("");
      toast.success("Expenses discarded successfully.");
    });
  };
  const [shows, setShow] = useState(false);
  const [fileObj, setFileObj] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileView = (data) => {
    setFileObj(data);
    handleShow();
  };

  useEffect(() => {
    getTravelPickList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
      filterdata?.user_id,
      filterdata?.company,
      filterdata?.trip_number,
      filterdata?.from_date,
      filterdata?.to_date,
      false
    );
  }, [pageSizeNo]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li class="active">                <Link to="/travel-pick">Travel Expense Pick List</Link>              </li>
              <li>                <Link to="/travel-expense">Check Expense</Link>              </li>
              <li>                <Link to="/travel-bills">All Bills</Link>              </li>
              <li>                <Link to="/travel-processed-ta">Processed TA</Link>              </li>
              <li>                <Link to="/travel-km-report">KM Report</Link>              </li>
              <li>                <Link to="/travel-intimat-report">                  Travel Intimation Report </Link>   </li>
              <li>                <Link to="/travel-prov-report">Provision Report</Link>              </li>
              <li>                <Link to="/travel-deviat-report">Deviation Report</Link>              </li>
              <li>                <Link to="/travel-hotel-exp-report">Hotel Expense Report</Link>              </li>
              <li>                <Link to="/travel-fcm-exp-report">                  FCM Ticket Expense Report      </Link>       </li>
              <li>                <Link to="/travel-employ-report">Employee List Report</Link>              </li>
            </ul>
          </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-7">
                    <h4 class="inner-page-title">Travel Expense Pick List</h4>
                  </div>
                  <div className="col-md-5">
                      <div className="d-flex" style={{
                        fontSize: '14px',
                        justifyContent: 'space-between'
                      }}>
                        {/* {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                          <> */}
                            <div>
                              {/* <span className="fw-bold text-theme ml-2">{each?.leave_name}</span> */}
                              {/* <ul className=""> */}
                              
                                <span ><i style={{color:"yellow"}} class='fas fa-square'></i> Query raised</span>
                                <span className="ml-4"><i style={{color:"#FFBF00"}} class='fas fa-square'></i> Send back to User</span>
                                <span className="ml-4"><i style={{color:"#FFA895"}} class='fas fa-square'></i> Expense Exception</span>
                              {/* </ul> */}
                            </div>
                            <br />
                          {/* </>
                        ))} */}
                      </div>
                    </div>
                    
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Name, Employee ID, Request Type, SAP No.
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc manager" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onDecline()}
                            >
                              <i class="far fa-check-circle"></i> Discard
                            </button>
                          )
                      )}

                      {finalUser.groups.map(
                        (data, idx) =>
                          data.name.toLowerCase() == "ssc user" && (
                            <button
                              class="btn btn-primary-inner"
                              type="button"
                              onClick={() => onReceive()}
                            >
                              <i class="far fa-check-circle"></i> Receive
                            </button>
                          )
                      )}

                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  "",
                                  filterdata?.user_id,
                                  filterdata?.company,
                                  filterdata?.trip_number,
                                  filterdata?.from_date,
                                  filterdata?.to_date
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                {/* <Tabs defaultActiveKey="approval" id="approvaltabs">
                  <Tab
                    eventKey="approval"
                    title={
                      <React.Fragment>
                        Pickup List
                        <span class="numberpurchse">{totalRecord}</span>
                      </React.Fragment>
                    }
                  > */}
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>Sno.</th>
                        <th>
                          <label class="logCheck d-inline-block">
                            <input
                              type="checkbox"
                              name="permission"
                              onClick={(e) => {
                                handleAllData(e);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </th>
                        <th>Print</th>
                        <th>Employee Id</th>
                        <th>Name</th>
                        {/* <th>Status</th> */}
                        <th>Location</th>
                        <th>Zone</th>
                        <th>Req Type</th>
                        <th>Trip Number</th>
                        <th>Courier/Submit Date</th>
                        <th>Tracking No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travelList?.length > 0 ? (
                        travelList?.map((data, index) => {
                          return (
                            <tr
                              style={{
                                backgroundColor:
                                  data?.chat_status > 0
                                    ? "yellow"
                                    : data?.ssc_user_send_back_to_requestor_remarks
                                    ? "#FFBF00"
                                    : data?.expense_exception
                                    ? "#FFA895"
                                    : "",
                              }}
                            >
                              <td>{index + 1}</td>
                              <td>
                                <label class="logCheck d-inline-block">
                                  <input
                                    type="checkbox"
                                    name="permission"
                                    onClick={(e) => {
                                      selectDataToPost(e, data);
                                    }}
                                    checked={data?.checked === "Y"}
                                  />
                                  <span class="checkmark"></span>
                                </label>
                              </td>
                              <td>
                                <Link
                                  to={`/travel-view-list?${data.id}`}
                                  target="_blank"
                                >
                                  <button class="btn btn-blanktd text-primary">
                                    <i class="fas fa-print"></i>
                                  </button>
                                </Link>

                                {data?.attachments?.length > 0 && (
                                  <Link
                                    onClick={() => {
                                      handleFileView(data.attachments);
                                    }}
                                  >
                                    <button class="btn btn-blanktd text-primary">
                                      <i class="fas fa-paperclip"></i>
                                    </button>
                                  </Link>
                                )}
                              </td>
                              <td>{data?.emp_code}</td>
                              <td>{data?.emp_name}</td>
                              {/* <td>
                                    <span class="badge bad-status badge-success">
                                      {data?.status}
                                    </span>
                                  </td> */}
                              <td>
                                {/* {data?.location?.to_city_name === "Other"
                                          ? data?.location?.to_city_input
                                          : data?.location?.to_city_name} */}
                                {data?.location}
                              </td>
                              <td>{data?.zone}</td>
                              <td>{data?.request_type}</td>
                              <td>{data?.sap_trip_number}</td>
                              <td>
                                {data?.claim_date &&
                                  moment
                                    .parseZone(data?.claim_date)
                                    .format("DD-MM-YYYY hh:mm A")}
                              </td>
                              <td>{data?.tracking_number}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>No Records Found...</td>
                        </tr>
                      )}
                      <Modal
                        className="modaldefaultclose modaldefaultclosecenter"
                        backdrop="static"
                        show={shows}
                        onHide={handleClose}
                      >
                        <Modal.Header
                          // className="bg-primary text-white"
                          closeButton
                        >
                          <Modal.Title>View attachments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div class="table-responsive mb-3">
                            <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                              <thead>
                                <tr>
                                  <th>EXPENSE TYPE</th>
                                  <th>FILE NAME </th>
                                  <th>VIEW</th>
                                </tr>
                              </thead>
                              <tbody>
                                {fileObj?.map((adata, idx) => (
                                  <tr>
                                    <td>{adata?.expense_type}</td>
                                    <td>
                                      {adata?.attachment_path?.substring(
                                        adata?.attachment_path?.lastIndexOf(
                                          "/"
                                        ) + 1
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        target="_Blank"
                                        to={adata?.attachment_path}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                          </Button>
                                        </Modal.Footer> */}
                      </Modal>

                      <Modal
                        size="md"
                        show={showsDiscard}
                        onHide={handleCloseDiscard}
                      >
                        <Modal.Header
                          style={{ "background-color": "#0195d4" }}
                          className="text-white"
                        >
                          <Modal.Title>
                            KINDLY PROVIDE REASON TO DISCARD EXPENSE?
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div class="row">
                            <div class="col-sm-12">
                              <select
                                class="form-control"
                                onChange={(e) =>
                                  setDiscardReason(e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="docs_not_rec_90_days">
                                  Documents not recieved in 90 days
                                </option>
                                {/* <option value="duplicate_form">Duplicate Form Submission</option> */}
                              </select>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            style={{ "background-color": "#0195d4" }}
                            class="btn"
                            onClick={handleCloseDiscard}
                          >
                            Cancel
                          </Button>
                          <Button
                            style={{ "background-color": "#0195d4" }}
                            class="btn "
                            onClick={declinePickList}
                          >
                            Submit
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </tbody>
                  </table>
                </div>

                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        className="form-control"
                        value={pageSizeNo}
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={50} label={50}>
                          50
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                        <option value={200} label={200}>
                          200
                        </option>
                        <option value={500} label={500}>
                          500
                        </option>
                        <option value={1000} label={1000}>
                          1000
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      key={pageSizeNo + "" + travelList.length}
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      disableInitialCallback={true}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>

                <div class="row jvactionsbutton">
                  <div class="col-md-12">
                    <div class="p-3"></div>
                  </div>
                </div>
                {/* </Tab>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>

            <Select
              options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser(e.value, "")}
              value={organisation_User.company}
            />

            {/* <select class="form-control newbgselect"
              onChange={(e)=>setfilterData({
              ...filterdata,
              company:e.currentTarget.value
            })}
            >
              <option>Select</option>
              <option>Jivagro Limited</option>
              <option>PI Consultant</option>
              <option>PI Industries Ltd.</option>
            </select> */}
          </div>

          <div class="form-group innergroup">
            <label>
              Employee ID <span class="text-danger"></span>
            </label>
            <Select
              options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setCompantAndUser("", e.value)}
              value={organisation_User?.user_id}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Trip Number <span class="text-danger"></span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e)=>setfilterData({
                ...filterdata,
                trip_number:e.value
              })}
            />
          </div> */}

          <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  from_date: e.currentTarget.value,
                })
              }
              value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  to_date: e.currentTarget.value,
                })
              }
              value={filterdata.to_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => clearfilter()}
            //  onClick={filterclose}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TravelPickup;
