import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import {
  submitUserCertification,
  userCertficationData,
} from "../../service/userService";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setUserData } from "../redux/piDataStore";
import moment from "moment/moment";
import avtar from "../../assets/images/avtar.png";
import femaleAvatr from "../../assets/images/Female-Avatar.png";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { logoutUser } from "../../service/userService";
import { setDisplayLoader } from "../redux/piDataStore";
import * as HeaderService from "../../service/header";
import { Tooltip } from "react-tippy";

function DataCertification() {
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [certData, setCertData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [curCategory, setCurCategory] = useState("");
  const [userProgress, setUserProgress] = useState(0);
  const userData = useSelector(selectUserData);
  const [canSkip, setCanSkip] = useState(false);
  const { instance, accounts } = useMsal();
  const [isCertified, setIsCertfied] = useState(false);
  const [certified, setCertified] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails(0);
    getUserCertificationData();
  }, []);

  const getUserDetails = async (id) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getUserDetails(id)
      .then((response) => {
        dispatch(setUserData(response?.data?.dataList));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const logoutHandle = async () => {
    dispatch(setDisplayLoader("Display"));
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        handleLogout("redirect");
        localStorage.clear();
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
    }
  };

  const openCertificationModal = () => {
    setCertified(true);
  };

  const submitCertification = () => {
    let obj = {
      user_id: userData?.id,
    };
    submitUserCertification(obj).then((response) => {
      if (response?.status === 200) {
        window.location.replace("/dashboard");
      }
    });
  };

  const getUserCertificationData = () => {
    dispatch(setDisplayLoader("Display"));
    userCertficationData().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        let total = 0;
        let certified = 0;
        if (Object.keys(response?.data?.dataList?.result)?.length !== 0) {
          Object.keys(response?.data?.dataList?.result).map((obj) => {
            if (
              response?.data?.dataList?.result[obj]?.total_count ===
              response?.data?.dataList?.result[obj]?.data?.length
            ) {
              certified += 1;
            }
            total +=
              response?.data?.dataList?.result[obj]?.total_count /
              response?.data?.dataList?.result[obj]?.data?.length;
          });
          if (
            certified === Object.keys(response?.data?.dataList?.result).length
          ) {
            setIsCertfied(false);
          } else {
            setIsCertfied(true);
          }
          total =
            (total / Object.keys(response?.data?.dataList?.result)?.length) *
            100;
          setUserProgress(total?.toFixed(2));
          setCertData(response?.data?.dataList?.result);
          if (
            response?.data?.dataList?.from_date !== null &&
            response?.data?.dataList?.to_date !== null
          ) {
            if (
              moment(new Date()).isBetween(
                moment(response?.data?.dataList?.from_date),
                moment(response?.data?.dataList?.to_date),
                "date",
                "[]"
              )
            ) {
              setCanSkip(false);
            } else {
              setCanSkip(true);
            }
          }
        } else {
          window.location.replace("/dashboard");
          localStorage.setItem("skipped", true);
        }
      }
    });
  };

  if (Object.keys(certData)?.length > 0) {
    return (
      <>
        <iframe
          src="https://performancemanager10.successfactors.com/sf/liveprofile?company=PI"
          frameborder="0"
          hidden
        ></iframe>
        <div className="mandatory-bg data-certibg">
          <div className="container-fluid">
            <div class="row align-items-center">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <img src="images/mypilogo.png" alt="" height={29} />
              </div>
              <div
                class="col-4"
                onClick={() => {
                  logoutHandle();
                }}
              >
                <ul class="mand-tophead">
                  <li>
                    <div className="mandtop-user mandatory-newlogout">
                      <div>
                        <h2 class="m-0">{userData?.full_name}</h2>
                      </div>
                      <img
                        src={
                          userData?.image === "" && userData?.gender === "M"
                            ? avtar
                            : userData?.image === "" && userData?.gender === "F"
                              ? femaleAvatr
                              : userData?.image
                        }
                        alt=""
                      />
                      <img
                        src="images/logout-mand.png"
                        class="madlogout"
                        alt=""
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-4 mt-sm-3">
              <div class="col-md-12">
                <div class="px-md-4">
                  <div class="row">
                    <div class="col-md-12">
                      <h2 class="mand-page-head">
                        Employee Data Certification
                      </h2>
                    </div>
                    {Object.keys(certData).map((each) => (
                      <div class="col-md-4">
                        <div
                          class="data-coreectafterwh"
                          onClick={() => {
                            addnewopen();
                            setCurCategory(each);
                            setCategoryData(certData[each]);
                          }}
                        >
                          <div className="data-correctionbox">
                            <div>
                              <div class="icondata">
                                {certData[each]?.total_count === 0 ? (
                                  <i class="fas fa-times text-danger"></i>
                                ) : certData[each]?.total_count ===
                                  certData[each]?.data?.length ? (
                                  <i class="fas fa-check text-success"></i>
                                ) : (
                                  <i class="fas fa-check text-warning"></i>
                                )}
                              </div>
                            </div>
                            <h2>
                              {each}{" "}
                              <i
                                class="fas fa-info"
                                style={{
                                  fontSize: "20px",
                                }}
                              ></i>
                            </h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="row">
                    <div className="col-md-3">
                      <div class="data-cert-rightwrng">
                        <i class="fas fa-info-circle"></i>
                        Upon updating your details and workflow approval by your HRBP, the revised information will reflect after a periodic data sync. Please come back after an hour to certify your personal data
                      </div>
                    </div>
                    <div class="col-md-5 data-certiprogreess">
                      <ProgressBar now={userProgress} /> {userProgress}%
                    </div>
                    <div class="col-md-2 mt-4">
                      <div className="mt-auto">
                        {canSkip === true ? (
                          <>
                            <Tooltip
                              title={
                                "Fill in the complete information to skip the page."
                              }
                              position="bottom"
                            >
                              <button
                                class="btn btn-primary-inner btn-block py-2"
                                disabled={canSkip}
                                onClick={() => {
                                  localStorage.setItem(
                                    "skipped",
                                    JSON.stringify({
                                      skipped: true,
                                      skip_date: moment(new Date()).format(
                                        "YYYY-MM-DD"
                                      ),
                                    })
                                  );
                                  window.location.replace("/dashboard");
                                }}
                              >
                                Skip
                              </button>
                            </Tooltip>
                          </>
                        ) : (
                          <button
                            class="btn btn-primary-inner btn-block py-2"
                            disabled={canSkip}
                            onClick={() => {
                              localStorage.setItem(
                                "skipped",
                                JSON.stringify({
                                  skipped: true,
                                  skip_date: moment(new Date()).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              );
                              window.location.replace("/dashboard");
                            }}
                          >
                            Skip
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="col-md-2 mt-4">
                      <div className="mt-auto">
                        <button
                          class="btn btn-primary-inner btn-block py-2"
                          disabled={isCertified}
                          onClick={openCertificationModal}
                        >
                          I, Certify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={certified}
          onHide={() => setCertified(false)}
          backdrop="static"
          keyboard={false}
          size=""
          className="modaldefaultclose modaldefaultclosecenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Certify Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div class="col-md-12">
                <div class="data-cert-rightwrng">
                  <i class="fas fa-info-circle"></i>
                  “I affirm that the information I have provided is both
                  accurate and truthful and any discrepancies may result in
                  appropriate action”
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary-inner bpi-main"
              onClick={submitCertification}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-primary-inner bpi-main"
              onClick={() => setCertified(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={addnewshow}
          onHide={addnewclose}
          backdrop="static"
          keyboard={false}
          size=""
          className="modaldefaultclose modaldefaultclosecenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{curCategory}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <ul className="data-certipopupinfo">
                  {categoryData?.description
                    ?.split(/(\d{1}\. )/)
                    .filter((each) => {
                      if (each !== "") {
                        return true;
                      }
                    })
                    .filter((each, i) => {
                      if (i % 2 !== 0) {
                        return true;
                      }
                    })
                    .map((each) => (
                      <li>{each}</li>
                    ))}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* {categoryData?.data?.length !== categoryData?.total_count && ( */}
            <a
              href={categoryData?.url?.replace("user", userData?.username)}
              target="_blank"
              rel="noreferrer"
            >
              <button type="submit" className="btn btn-primary-inner bpi-main">
                Add Details
              </button>
            </a>
            {/* )} */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
export default DataCertification;
