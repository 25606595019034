import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import { selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import * as purchaseOrderService from "../../service/purchase-order";
import * as ActivePoServices from "../../service/activePo";
import * as ApproverServices from "../../service/approver";
import ReactPaginate from "react-paginate";
import { Formik } from "formik";
import ViewActiveContract from "./ViewActiveContract";
import CaUrls from "./CaUrls";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import * as moment from "moment";

function ActiveContract() {
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  //---------------------------------------------------------
  const [pendingPo, setPendingPo] = useState([]);
  const [currentTab, setCurrentTab] = useState("purchase");
  const [approvedCA, setApprovedCA] = useState([]);
  const [declinedPo, setDeclinedPo] = useState([]);
  const [pageCountPending, setpageCountPending] = useState(1);
  const [pageCountDeclined, setpageCountDeclined] = useState(1);
  const [pageCountApprove, setpageCountApprove] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [pageSizeNo2, setPageSizeNo2] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageSizeNo1, setPageSizeNo1] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [allPurOrg, setAllPurOrg] = useState([]);
  const [allPurGroup, setAllPurGroup] = useState([]);
  const [vendorId, setVendorId] = useState([]);
  const [dataView, setDataView] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [condition, setCondition] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [allPurGrData, setAllPurGrData] = useState([]);
  const [allPurOrgData, setAllPurOrgData] = useState([]);

  const [dataFilter, setDataFilter] = useState({
    vendor_id: "",
    purchase_group_id: "",
    purchase_org_id: "",
    created_at: "",
  });

  const handleKey = (
    search,
    vendor_id,
    purchase_group_id,
    purchase_org_id,
    created_at,
    pageNo,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&category_type=CONTRACT";

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (vendor_id !== "" && vendor_id !== undefined && vendor_id !== null) {
      queryParm = queryParm + "&vendor_id=" + vendor_id;
    }
    if (
      purchase_group_id !== "" &&
      purchase_group_id !== undefined &&
      purchase_group_id !== null
    ) {
      queryParm = queryParm + "&purchase_group_id=" + purchase_group_id;
    }
    if (
      purchase_org_id !== "" &&
      purchase_org_id !== undefined &&
      purchase_org_id !== null
    ) {
      queryParm = queryParm + "&purchase_org_id=" + purchase_org_id;
    }
    if (created_at !== "" && created_at !== undefined && created_at !== null) {
      queryParm = queryParm + "&created_at=" + created_at;
    }
    return queryParm;
  };

  const getPendingPoDetails = (
    search,
    vendor_id,
    purchase_group_id,
    purchase_org_id,
    created_at,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    ActivePoServices.getPendingPoDetails(
      handleKey(
        search,
        vendor_id,
        purchase_group_id,
        purchase_org_id,
        created_at,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setPendingPo(response?.data?.dataList?.result);
        setpageCountPending(
          response?.data?.dataList?.paginated_data?.totalPages
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDeclinedPoDetails = (
    search,
    vendor_id,
    purchase_group_id,
    purchase_org_id,
    created_at,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    ActivePoServices.getDeclinedPoDetails(
      handleKey(
        search,
        vendor_id,
        purchase_group_id,
        purchase_org_id,
        created_at,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setDeclinedPo(response?.data?.dataList?.result);
        setpageCountDeclined(
          response?.data?.dataList?.paginated_data?.totalPages
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    vendor_id,
    purchase_group_id,
    purchase_org_id,
    created_at,
    pageNo,
    page_size,
    sort_by,
    paginate,
    currentTab
  ) => {
    if (currentTab === "purchase") {
      ActivePoServices.getExportValue(
        handleKey(
          search,
          vendor_id,
          purchase_group_id,
          purchase_org_id,
          created_at,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pendingCA.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      ActivePoServices.getExportDeclined(
        handleKey(
          search,
          vendor_id,
          purchase_group_id,
          purchase_org_id,
          created_at,
          pageNo,
          page_size,
          sort_by,
          paginate
        )
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "DeclinedPo.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getApprovedPoDetails = (
    search,
    vendor_id,
    purchase_group_id,
    purchase_org_id,
    created_at,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    ActivePoServices.getApprovedPoDetails(
      handleKey(
        search,
        vendor_id,
        purchase_group_id,
        purchase_org_id,
        created_at,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setApprovedCA(response?.data?.dataList?.result);
        setpageCountApprove(
          response?.data?.dataList?.paginated_data?.totalPages
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e, tabName) => {
    if (tabName === "purchase") {
      getPendingPoDetails(
        e.target.value.toLowerCase(),
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        currentPage,
        pageSizeNo,
        "-id",
        true
      );
    } else if (tabName === "declined") {
      getDeclinedPoDetails(
        e.target.value.toLowerCase(),
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        1,
        pageSizeNo1,
        "-id",
        true
      );
    } else if (tabName === "approved") {
      getApprovedPoDetails(
        e.target.value.toLowerCase(),
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        1,
        pageSizeNo2,
        "-id",
        true
      );
    }
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = (tabName) => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (tabName === "purchase") {
        getPendingPoDetails(
          data,
          dataFilter.vendor_id,
          dataFilter.purchase_group_id,
          dataFilter.purchase_org_id,
          dataFilter.created_at,
          1,
          pageSizeNo,
          "-id",
          true
        );
      } else if (currentTab === "declined") {
        getDeclinedPoDetails(
          data,
          dataFilter.vendor_id,
          dataFilter.purchase_group_id,
          dataFilter.purchase_org_id,
          dataFilter.created_at,
          1,
          pageSizeNo1,
          "-id",
          true
        );
      } else if (currentTab === "approved") {
        getApprovedPoDetails(
          searchStr,
          dataFilter.vendor_id,
          dataFilter.purchase_group_id,
          dataFilter.purchase_org_id,
          dataFilter.created_at,
          1,
          pageSizeNo2,
          "-id",
          true
        );
      }
    }
  };

  const handleTab = (currentTab) => {
    let closeBar = document.getElementById("searchbar");
    const clearData = (closeBar.value = "");
    setSearchStr(clearData);
    if (currentTab === "purchase") {
      getDeclinedPoDetails("", "", "", "", "", 1, pageSizeNo1, "-id", true);
    } else if (currentTab === "declined") {
      getPendingPoDetails("", "", "", "", "", 1, pageSizeNo, "-id", true);
    } else if (currentTab === "approved") {
      getApprovedPoDetails("", "", "", "", "", 1, pageSizeNo2, "-id", true);
    }
  };

  const mainDataFilter = (values, currentTab) => {
    const vendorId = dataFilter.vendor_id;
    const PurchaseGrpId = dataFilter.purchase_group_id;
    const PurchaseOrgId = dataFilter.purchase_org_id;
    const dateData = values.created_at;
    if (currentTab === "purchase") {
      getPendingPoDetails(
        searchStr,
        vendorId,
        PurchaseGrpId,
        PurchaseOrgId,
        dateData,
        1,
        pageSizeNo,
        "-id",
        true
      );
    } else if (currentTab === "declined") {
      getDeclinedPoDetails(
        searchStr,
        vendorId,
        PurchaseGrpId,
        PurchaseOrgId,
        dateData,
        1,
        pageSizeNo1,
        "-id",
        true
      );
    } else if (currentTab === "approved") {
      getApprovedPoDetails(
        searchStr,
        vendorId,
        PurchaseGrpId,
        PurchaseOrgId,
        dateData,
        1,
        pageSizeNo2,
        "-id",
        true
      );
    }
    filterclose();
  };

  const clearFilter = (tabName) => {
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter({
      vendor_id: "",
      purchase_group_id: "",
      purchase_org_id: "",
      created_at: "",
    });
    setVendorData([]);
    setAllPurGrData([]);
    setAllPurOrgData([]);

    filterclose();
    if (tabName === "purchase") {
      getPendingPoDetails("", "", "", "", "", 1, pageSizeNo, "-id", true);
    } else if (tabName === "declined") {
      getDeclinedPoDetails("", "", "", "", "", 1, pageSizeNo1, "-id", true);
    } else if (tabName === "approved") {
      getApprovedPoDetails("", "", "", "", "", 1, pageSizeNo2, "-id", true);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    setCurrentPage1(activePage);
    if (currentTab === "purchase") {
      getPendingPoDetails(
        searchStr,
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        activePage,
        pageSizeNo,
        "-id",
        true
      );
    } else if (currentTab === "declined") {
      getDeclinedPoDetails(
        searchStr,
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        activePage,
        pageSizeNo1,
        "-id",
        true
      );
    } else if (currentTab === "approved") {
      getApprovedPoDetails(
        searchStr,
        dataFilter.vendor_id,
        dataFilter.purchase_group_id,
        dataFilter.purchase_org_id,
        dataFilter.created_at,
        activePage,
        pageSizeNo2,
        "-id",
        true
      );
    }
  };

  const sendEmail = (email, type, user_name, po_no, poid) => {
    ActivePoServices.sendEmailCA(email, type, user_name, po_no, poid)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          if (type == "Declined") {
            getDeclinedPoDetails(
              "",
              "",
              "",
              "",
              "",
              1,
              pageSizeNo1,
              "-id",
              true
            );
          } else {
            getPendingPoDetails("", "", "", "", "", 1, pageSizeNo, "-id", true);
          }
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPurOrg = (sort_by, paginate) => {
    ApproverServices.getPurOrg(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code}`,
          };
          data.push(newData);
        });
        setAllPurOrg(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPurGroup = (sort_by, paginate) => {
    ApproverServices.getPurGroup(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let grpData = {
            value: x.id,
            label: `${x?.pgcode}`,
          };
          data.push(grpData);
        });
        setAllPurGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVendorId = (id) => {
    ActivePoServices.getVendorId(id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.name}`,
          };
          data.push(empData);
        });
        setVendorId(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const openFilter = () => {
    getPurOrg("id", true);
    getPurGroup("id", true);
    getVendorId(0);
    filteropen();
  };

  const getViewPurchaseOrder = (id) => {
    purchaseOrderService
      .getViewPurchaseOrder(id)
      .then((response) => {
        setDataView(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const onViewOpen = (data) => {
    setViewForm(true);
    getViewPurchaseOrder(data.id);
    setCondition(data);
  };

  const onCallBack = () => {
    setViewForm(false);
  };

  useEffect(() => {
    getPendingPoDetails("", "", "", "", "", 1, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  useEffect(() => {
    getDeclinedPoDetails("", "", "", "", "", 1, pageSizeNo1, "-id", true);
  }, [pageSizeNo1]);

  useEffect(() => {
    getApprovedPoDetails("", "", "", "", "", 1, pageSizeNo2, "-id", true);
  }, [pageSizeNo2]);

  useEffect(() => {
    handleTab(currentTab);
  }, [currentTab]);

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };

  return (
    <>
      {viewForm ? (
        <>
          <ViewActiveContract
            dataView={dataView}
            onViewClose={onViewClose}
            onCallBack={onCallBack}
            condition={condition}
          />
        </>
      ) : (
        <>
          <div class="row">
            <ToastContainer autoClose={1000} />
            {/* <div class="col-md-3">
              <CaUrls />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <h4 class="inner-page-title">Contract Approval</h4>
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on <strong>CA Number</strong>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            type="text"
                            id="searchbar"
                            className="form-control"
                            placeholder="Search..."
                            onChange={(e) => handleSearch(e, currentTab)}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack(currentTab);
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <button
                                type="button"
                                disabled={
                                  currentTab === "purchase"
                                    ? pendingPo.length !== 0
                                      ? false
                                      : true
                                    : currentTab === "declined"
                                    ? declinedPo.length !== 0
                                      ? false
                                      : true
                                    : currentTab === "approved"
                                    ? approvedCA.length !== 0
                                      ? false
                                      : true
                                    : ""
                                }
                                style={{ border: 0, background: "none" }}
                                onClick={() =>
                                  getExportDetails(
                                    searchStr,
                                    dataFilter.vendor_id,
                                    dataFilter.purchase_group_id,
                                    dataFilter.purchase_org_id,
                                    dataFilter.created_at,
                                    1,
                                    pageSizeNo,
                                    "-id",
                                    true,
                                    currentTab,
                                    "CONTRACT"
                                  )
                                }
                              >
                                <i className="far fa-file-excel"></i>Excel
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          onClick={() => openFilter()}
                          class="btn btn-secondary-inner"
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs">
                  <Tabs
                    defaultActiveKey="purchase"
                    id="approvaltabs"
                    activeKey={currentTab}
                    onSelect={(e) => {
                      setCurrentTab(e);
                    }}
                  >
                    <Tab eventKey="purchase" title={<>Pending Contract</>}>
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Contract NO</th>
                              <th>Creation Date</th>
                              <th>Vendor Name</th>
                              <th>Total Price</th>
                              <th>Currency</th>
                              <th>Pending with</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pendingPo?.length ? (
                              pendingPo?.map((data, index) => (
                                <>
                                  <tr key={`desg${index}`}>
                                    <td
                                      class="text-theme fw-bold"
                                      onClick={() => onViewOpen(data)}
                                    >
                                      {data?.po_no}
                                    </td>

                                    <td>
                                      {" "}
                                      {moment
                                        .utc(data?.created_at)
                                        .format("Do MMM YYYY")}
                                    </td>
                                    <td>{data?.vendor_name}</td>
                                    <td>{changeData(data?.total_price)}</td>
                                    <td>{data?.currency_code}</td>
                                    <td>{data?.pending}</td>

                                    <td>
                                      <button
                                        class="btn btn-blanktd"
                                        type="button"
                                        onClick={() =>
                                          sendEmail(
                                            data?.pending_email,
                                            "Pending",
                                            data?.user_name,
                                            data?.po_no,
                                            data?.id
                                          )
                                        }
                                      >
                                        <span class="badge badge-secondary">
                                          <i class="far fa-bell"></i> Send
                                          Reminder
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={12}> No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {pendingPo?.length == 0 ? (
                        ""
                      ) : (
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="sortinglist">
                              Show
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setPageSizeNo(e.target.value);
                                  setCurrentPage(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCountPending}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>
                    <Tab
                      eventKey="declined"
                      title={<React.Fragment>Declined Contract</React.Fragment>}
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Contract NO</th>
                              <th>Actions</th>
                              <th>Declined by</th>
                              <th>Creation Date</th>
                              <th>Vendor Name</th>
                              <th>Total Price</th>
                              <th>Reason</th>
                              <th>Contract. Group</th>
                              <th>Contract. Org.</th>
                              <th>Rel. Group</th>
                              <th>Rel. Strategy</th>
                              <th>Next Approver</th>
                            </tr>
                          </thead>
                          <tbody>
                            {declinedPo?.length ? (
                              declinedPo?.map((data, index) => (
                                <>
                                  <tr key={`desg${index}`}>
                                    <td
                                      class="text-theme fw-bold"
                                      onClick={() => onViewOpen(data)}
                                    >
                                      {data?.po_no}
                                    </td>
                                    <td>
                                      <button
                                        class="btn btn-blanktd"
                                        type="button"
                                        onClick={() =>
                                          sendEmail(
                                            data?.declined_email,
                                            "Declined",
                                            data?.user_name,
                                            data?.po_no,
                                            data?.id
                                          )
                                        }
                                      >
                                        <span class="badge badge-secondary">
                                          <i class="far fa-paper-plane"></i>{" "}
                                          Resend CA
                                        </span>
                                      </button>
                                    </td>
                                    <td> {data?.declined}</td>
                                    <td>
                                      {" "}
                                      {moment
                                        .utc(data?.created_at)
                                        .format("Do MMM YYYY")}
                                    </td>
                                    <td> {data?.vendor_name}</td>
                                    <td> {changeData(data?.total_price)}</td>

                                    <td> {data?.reason}</td>
                                    <td> {data?.purchase_group_code}</td>
                                    <td> {data?.purchase_org_code}</td>
                                    <td> {data?.release_group_code}</td>
                                    <td> {data?.release_stat_code}</td>
                                    <td> {data?.next_approver}</td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={12}> No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {declinedPo?.length == 0 ? (
                        ""
                      ) : (
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="sortinglist">
                              Show
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setPageSizeNo1(e.target.value);
                                  setCurrentPage1(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCountDeclined}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab eventKey="approved" title={<>Approved Contract</>}>
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>PO NO</th>
                              <th>Creation Date</th>
                              <th>Vendor Name</th>
                              <th>Total Price</th>
                              <th>Currency</th>
                              <th>Approved By</th>
                              <th>Sap Log</th>
                              <th>Sap Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {approvedCA?.length ? (
                              approvedCA?.map((data, index) => (
                                <>
                                  <tr key={`desg${index}`}>
                                    <td
                                      class="text-theme fw-bold"
                                      onClick={() => onViewOpen(data)}
                                    >
                                      {data?.po_no}
                                    </td>

                                    <td>
                                      {" "}
                                      {moment
                                        .utc(data?.created_at)
                                        .format("Do MMM YYYY")}
                                    </td>
                                    <td>{data?.vendor_name}</td>
                                    <td> {changeData(data?.total_price)}</td>

                                    <td>{data?.currency_code}</td>
                                    <td>{data?.approved_by}</td>
                                    <td>
                                      <Tooltip
                                        title={data?.log}
                                        position="bottom"
                                      >
                                        {data?.log?.substr(0, 12)}...
                                      </Tooltip>
                                    </td>
                                    <td>
                                      {data?.sap_status
                                        ? "Synced"
                                        : "Not Synced"}
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={12}> No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {approvedCA?.length == 0 ? (
                        ""
                      ) : (
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="sortinglist">
                              Show
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  setPageSizeNo2(e.target.value);
                                  setCurrentPage2(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCountApprove}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={dataFilter}
            onSubmit={async (values) => {
              mainDataFilter(values, currentTab);
              await new Promise((resolve) => setTimeout(resolve, 500));
            }}
          >
            {({ handleChange, handleBlur, handleSubmit }) => (
              <Modal
                show={show}
                onHide={filterclose}
                className="filter-popup modaldefaultclose"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                  <Modal.Body>
                    <div class="form-group innergroup">
                      <label>Vendor Code</label>

                      <Select
                        name="vendor_id"
                        id="vendor_id"
                        options={vendorId}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          console.log("ee", e);
                          setVendorData(e);
                          setDataFilter({
                            ...dataFilter,
                            vendor_id: e.value,
                          });
                        }}
                        value={vendorData}
                      />
                    </div>
                    <div class="form-group innergroup">
                      <label>Select Purchase Group</label>

                      <Select
                        name="purchase_group_id"
                        id="purchase_group_id"
                        options={allPurGroup}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          setAllPurGrData(e);
                          setDataFilter({
                            ...dataFilter,
                            purchase_group_id: e.value,
                          });
                        }}
                        value={allPurGrData}
                      />
                    </div>
                    <div class="form-group innergroup">
                      <label>Select Purchase Org</label>

                      <Select
                        name="purchase_org_id"
                        id="purchase_org_id"
                        options={allPurOrg}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          setAllPurOrgData(e);
                          setDataFilter({
                            ...dataFilter,
                            purchase_org_id: e.value,
                          });
                        }}
                        value={allPurOrgData}
                      />
                    </div>
                    <div class="form-group innergroup">
                      <label>Created Date</label>
                      <input
                        type="date"
                        name="created_at"
                        class="form-control"
                        style={{ cursor: "pointer" }}
                        onChange={(e) => {
                          handleChange(e);
                          setDataFilter({
                            ...dataFilter,
                            created_at: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={dataFilter?.created_at}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => {
                        clearFilter(currentTab);
                        setCurrentPage(1);
                        setCurrentPage1(1);
                      }}
                    >
                      Clear
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                    >
                      Apply
                    </button>
                  </Modal.Footer>
                </form>
              </Modal>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default ActiveContract;
