import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import {
  getAllSesmaster,
  SyncSes,
  getExportPRReport,
} from "../../service/sesService";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { getUserRmList } from "../../service/jv_service";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "react-tippy";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as CommonService from "../../service/searchpo";

function ServiceEntryListAll() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const pathname = window.location.pathname

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterdata, setfilterData] = useState({
    sheetno: { value: 0 },
    Created_Date: "",
    Created_by: { value: "" },
    approver: { value: "" },
    status: { value: "" },
  });
  const resetFilterData = () =>
    setfilterData({
      sheetno: { value: 0 },
      Created_Date: "",
      Created_by: { value: "" },
      approver: { value: "" },
      status: { value: "" },
    });
  const [userlist, setuserlist] = useState([]);

  const options = [
    { value: "A", label: "Aproved" },
    { value: "D", label: "Rejected" },
    { value: "P", label: "Pending" },
  ];
  const [sesno, setsesno] = useState([]);
  const [sesData, setSesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const getSes = (
    search,
    page_no,
    page_size,
    paginate,
    sort_by,
    sheet_no,
    created_at,
    created_by,
    status,
    approver_1,
    myses,
    self_approval_data
  ) => {
    dispatch(setDisplayLoader("Display"));

    getAllSesmaster(
      search,
      page_no,
      page_size,
      paginate,
      sort_by,
      sheet_no,
      created_at,
      created_by,
      status,
      approver_1,
      myses,
      self_approval_data
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSesData(response?.data?.dataList?.result);
        let ses = [];
        response?.data?.dataList?.result.map((x) => {
          let data = { value: x.sesno, label: x?.sesno };
          ses.push(data);
        });
        setsesno(ses);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const SyncData = () => {
    SyncSes()
      .then((response) => {
        toast.success("Data Synced Successfully");
        getSes(
          "",
          currentPage,
          pageSizeNo,
          true,
          "-id",
          filterdata?.sheetno.value,
          filterdata?.Created_Date,
          filterdata?.Created_by.value,
          filterdata?.status.value,
          filterdata?.approver.value,
          false,
          pathname === "/service-entry-all" ? false : true
        );
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  // useEffect(() => {
  //   getSes(
  //     "",
  //     currentPage,
  //     pageSizeNo,
  //     true,
  //     "-id",
  //     filterdata?.sheetno.value,
  //     filterdata?.Created_Date,
  //     filterdata?.Created_by.value,
  //     filterdata?.status.value,
  //     filterdata?.approver.value,
  //     false
  //   );
  //   getUserList();
  // }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSes(
      "",
      activePage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      false,
      pathname === "/service-entry-all" ? false : true
    );
  };
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      0,
      '',
      '',
      "P",
      '',
      false,
      pathname === "/service-entry-all" ? false : true
    );
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      false,
      pathname === "/service-entry-all" ? false : true
    );
    filterclose();
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "sheetno":
        setfilterData((prev) => ({
          ...prev,
          sheetno: event,
        }));
        break;
      case "Created_Date":
        setfilterData((prev) => ({
          ...prev,
          Created_Date: event.target.value,
        }));
        break;
      case "Create_by":
        setfilterData((prev) => ({
          ...prev,
          Created_by: event,
        }));
        break;
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "status":
        setfilterData((prev) => ({
          ...prev,
          status: event,
        }));
        break;
    }
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getSes(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        true,
        "-id",
        filterdata?.sheetno.value,
        filterdata?.Created_Date,
        filterdata?.Created_by.value,
        filterdata?.status.value,
        filterdata?.approver.value,
        false,
        pathname === "/service-entry-all" ? false : true
      );
      setSearch(e.target.value);
    }
  };

  useEffect(() => {
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      filterdata?.sheetno.value,
      filterdata?.Created_Date,
      filterdata?.Created_by.value,
      filterdata?.status.value,
      filterdata?.approver.value,
      false,
      pathname === "/service-entry-all" ? false : true
    );
    getSapSchedule()
  }, [pageSizeNo]);

  const handleKey = (
    search,
    page_no,
    page_size,
    paginate,
    sort_by,
    sheet_no,
    created_at,
    created_by,
    status,
    approver_1,
    myses
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (sheet_no !== "" && sheet_no !== undefined && sheet_no !== null) {
      queryParm = queryParm + "&sheet_no=" + sheet_no;
    }
    if (created_at !== "" && created_at !== undefined && created_at !== null) {
      queryParm = queryParm + "&created_at=" + created_at;
    }
    if (created_by !== "" && created_by !== undefined && created_by !== null) {
      queryParm = queryParm + "&created_by=" + created_by;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (approver_1 !== "" && approver_1 !== undefined && approver_1 !== null) {
      queryParm = queryParm + "&approver_1=" + approver_1;
    }
    if (myses !== "" && myses !== undefined && myses !== null) {
      queryParm = queryParm + "&myses=" + myses;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  const getExportDetails = () => {
    getExportPRReport(
      handleKey(
        "",
        currentPage,
        pageSizeNo,
        true,
        "-id",
        filterdata?.sheetno.value,
        filterdata?.Created_Date,
        filterdata?.Created_by.value,
        filterdata?.status.value,
        filterdata?.approver.value,
        false
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SesData.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  const getSapSchedule = () => {
    CommonService.get_sap_schedule('sync_post_ses_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    CommonService.get_sap_schedule('sync_get_ses_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/ses-search">Search</Link>
            </li>
            <li>
              <Link to="/service-entry-sheet">Service Entry Sheet</Link>
            </li>
            <li class="active">
              <Link to="/service-entry-all">All Pending</Link>
            </li>
            <li>
              <Link to="/service-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Service Entries</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button
                      onClick={() => SyncData()}
                      class="btn btn-primary-inner"
                    >
                      <i class="fas fa-sync-alt mr-2 f-14"></i>SES Sync
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Sheet No.</th>
                    <th>Date</th>
                    <th>PO Number</th>
                    <th>Item</th>
                    <th>Posting Date</th>
                    <th>Created By</th>
                    <th>Amount</th>
                    <th>Rel. Strat</th>
                    <th>Pending At</th>
                    <th>Status</th>
                    <th>Approved/Rejected By</th>
                    <th>Sap status</th>
                    <th>SAP Log</th>
                  </tr>
                </thead>
                <tbody>
                  {sesData != 0 ? (
                    sesData.map((x) => (
                      <tr>
                        <td class="text-theme">
                          <Link
                            to="/approve-service"
                            state={{ from: x?.sesno }}
                            class="fw-bold text-theme"
                          >
                            {x?.sesno}
                          </Link>
                        </td>
                        <td>
                          {moment.utc(x?.created_at).format("DD-MM-YYYY,HH:mm")}{" "}
                        </td>
                        <td>{x?.pono}</td>
                        <td>{x?.items.length}</td>
                        <td>
                          {moment
                            .utc(x?.postingdate)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td>
                        <td>
                          <Tooltip title={x?.created_by_name + "(" + x?.createdBy + ")"} position="bottom">
                            {x?.created_by_name}({x?.createdBy})
                          </Tooltip>
                        </td>
                        <td>{x?.totalvalue}</td>
                        <td>{x?.rel_strat}</td>
                        {x?.action_performed === false ?
                          <td>
                            <Tooltip title={x?.requestedAt2} position="bottom">
                              {x?.requestedAt2}
                            </Tooltip>
                          </td>
                          :
                          <td></td>
                        }
                        {x?.status === "P" ?
                          <td>
                            <span className="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                          : x?.status === "A" ?
                            <td>
                              <span className="badge bad-status badge-success">
                                Approved
                              </span>
                            </td>
                            : x?.status === "D" ?
                              <td>
                                <span className="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              </td>
                              :
                              <td>{x?.status}</td>
                        }
                        {x?.action_performed === true ?
                          <td>
                            <Tooltip title={x?.requestedAt2} position="bottom">
                              {x?.requestedAt2}
                            </Tooltip>
                          </td>
                          :
                          <td></td>
                        }
                        <td>
                          {x?.sap_status === true
                            ? "Synced"
                            : "Pending for synced"}
                        </td>
                        <td>
                          <Tooltip title={x?.log} position="bottom">
                            {x?.log?.slice(0, 12)}...
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => setPageSizeNo(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Search Sheet No.</label>
            <Select
              name="sheetno"
              options={sesno}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.sheetno}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created Date</label>
            <input
              value={filterdata?.Created_Date}
              name="Created_Date"
              onChange={handleChange}
              type="date"
              class="form-control"
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Created by</label>
            <Select
              //   isMulti
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              name="Create_by"
              value={filterdata?.Created_by}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>Select Approver</label>
            <Select
              //   isMulti
              name="approver"
              value={filterdata?.approver}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>Select Status</label>
            <Select
              //   isMulti
              name="status"
              value={filterdata?.status}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceEntryListAll;
