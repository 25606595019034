import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import * as agreement from "../../service/agree-terms";
import moment from "moment";
import quaterly from "../../assets/images/quaterly-image.jpg";

function QuaterlyTerms(props) {
  const Location = useLocation();
  const Navigate = useNavigate();
  const [isChecked, setisChecked] = useState(false);

  const acceptTerms = (event) => {
    event.preventDefault();
    let payload = {
      accepted: true,
      curMonth: moment(new Date()).month() + 1,
    };

    agreement
      .checkQuaterlyTerms(payload)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          if (Location?.state?.component == "dashboard") {
            Navigate("/dashboard");
          }
          if (Location?.state?.component == "mandatory") {
            Navigate("/mandatory");
          }
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div className="login-bg">
        <div className="container">
          <div className="row justify-content-between my-3">
            <div className="col-md-2 d-flex flex-column text-center text-sm-left">
              <div>
                <img src={Logo} alt="" height="55px" />
              </div>
            </div>
            <div className="col-md-10 col-lg-10 mt-3 mt-sm-0">
              <div className="loginTabs">
                <div className="tabfliping agreebox">
                  <h3>Security Disclaimer</h3>
                  <form onSubmit={(e) => acceptTerms(e)}>
                    <div className="agreecontent">
                      <div>
                        <div
                          style={{
                            width: "100%",
                            height: "60vh",
                            position: "relative",
                          }}
                        >
                          <img
                            src={quaterly}
                            width="100%"
                            height="100%"
                            alt=""
                            style={{
                              objectFit: "cover",
                              objectPosition: "top right",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              left: "15px",
                            }}
                          >
                            <label className="logCheck d-inline-block mr-4">
                              I understand and acknowledge that I am not <br />
                              supposed to send official information to my
                              personal account or <br />
                              to anyone unauthorised.
                              <input
                                type="checkbox"
                                name="termscondition"
                                onChange={() => {
                                  setisChecked((prev) => !prev);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary agreebtn"
                      type="submit"
                      disabled={!isChecked}
                    >
                      Proceed
                    </button>
                    {/* <Link to="/mandatory" className="btn btn-primary agreebtn"
                                        
                                        >Proceed</Link>  */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuaterlyTerms;
