import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getReleaseStrat = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/release-strat?${queryParm}`);
  };

  export const saveReleaseStrat = async (data) => {
    return axios.post(`${AUTH_BASE_URL}/release-strat`, data);
  };

  export const updateReleaseStrat = async (data, id) => {
    return axios.put(`${AUTH_BASE_URL}/release-strat/${id}`, data);
  };

  export const deleteReleaseStrat = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/release-strat/${id}`);
  };

  export const getExportValue = async (search,sort_by) => {
    return axios({
      url: `${AUTH_BASE_URL}/release-strat/export?search=${search}&sort_by=${sort_by}`,
      method: "GET",
      responseType: "blob",
    });
  };

  export const isCodeExists = async (isData) => {
    return axios.post(`${AUTH_BASE_URL}/release-strat/exists`, isData);
  };