import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import * as PrPurchaseGroup from "../../service/purchaseGroup";

function PRplantMaster() {
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: 'id'
  });
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => {
    addnewsetShow(false)
  };
  const [searchStr, setSearchStr] = useState("");
  const handleSearch = (e) => {
    getData(
      e.target.value.toLowerCase(),
      filter,
      1,
      pageSizeNo,
      "id",
      true
    );
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(
      searchStr,
      filter,
      activePage,
      pageSizeNo,
      "id",
      true
    );
  };

  const handleKey = (search, filter, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      queryParm =
        queryParm
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    await PrPurchaseGroup.getPlants(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true
    );
  }, [pageSizeNo]);
  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="backleftlist">
              <Link to="/pr-list">
                <span>
                  <i class="fas fa-arrow-alt-circle-left f-12"></i> Back
                </span>
              </Link>
            </li>
            <li>
              <Link to="/pr-unit-master">Unit Master</Link>
            </li>
      
            <li>
              <Link to="/pr-group-master">Purchasing Group Master</Link>
            </li>
            <li>
              <Link to="/pr-org-master">Purchasing Organization Master</Link>
            </li>
            <li>
              <Link to="/pr-type-master">Type Master</Link>
            </li>
            <li>
              <Link to="/pr-user-master">User Authorization Master</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Plant Master</h4>
                 
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={e => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Plant Code</th>
                    <th>Plant Description</th>
                    <th>Plant Type</th>
                    <th>Updated On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    list.map(item => {
                      return (
                        <tr>
                          <td class="text-theme fw-bold">JA44</td>
                          <td>JV</td>
                          <td>P</td>
                          <td>2021-12-24 21:40:49</td>
                          <td>
                            <span class="badge bad-status badge-danger">
                              InActive
                            </span>
                          </td>
                          <td>
                            <button class="btn btn-blanktd text-primary">
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2">
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  <tr>
                    <td class="text-theme fw-bold">JA44</td>
                    <td>JIVAGRO PLANT</td>
                    <td>P</td>
                    <td>2021-12-24 21:40:49</td>
                    <td>
                      <span class="badge bad-status badge-success">Active</span>
                    </td>
                    <td>
                      <button class="btn btn-blanktd text-primary">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-theme fw-bold">WZ04</td>
                    <td>PI INDUSTRIES LTD. - JABALPUR</td>
                    <td>D</td>
                    <td>2021-12-24 21:40:49</td>
                    <td>
                      <span class="badge bad-status badge-success">Active</span>
                    </td>
                    <td>
                      <button class="btn btn-blanktd text-primary">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-theme fw-bold">WZ02</td>
                    <td>PI INDUSTRIES LTD. - AKOLA</td>
                    <td>D</td>
                    <td>2021-12-24 21:40:49</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                    </td>
                    <td>
                      <button class="btn btn-blanktd text-primary">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-theme fw-bold">VD01</td>
                    <td>PI INDUSTRIES LTD. VADODARA</td>
                    <td>P</td>
                    <td>2021-12-24 21:40:49</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                    </td>
                    <td>
                      <button class="btn btn-blanktd text-primary">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row">
              <div className="col-sm-5">
                <div className="sortinglist">
                  Show
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>10</option>
                    <option value={20} label={20}>20</option>
                    <option value={30} label={30}>30</option>
                    <option value={100} label={100}>100</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Plant Code<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Plant Code"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Plant Description<span class="text-danger">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Plant Description"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Plant Type<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>D</option>
              <option>P</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Active</option>
              <option>Inactive</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Plant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Plant Code<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Plant Code"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Plant Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Plant Description"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Select Plant Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option>D</option>
                      <option>P</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="statusupdate1" checked />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PRplantMaster;
