import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import AddDepartment from "./AddSubDept";
import ViewDepartment from "./ViewSubDepartment";
import * as deptServices from "../../../service/department";
import * as subDeptServices from "../../../service/subdepartment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

if (typeof window !== "undefined") {
  injectStyle();
}

function SubDeptGrid() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [deptList, setDeptList] = useState([]);
  const [department, setDepartment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [allDept, setAllDept] = useState([]);
  const [dropDept, setDropDept] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    sub_department_id: "",
    department_id: "",
    status: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    sub_department_id: "",
    department_id: "",
    status: "",
  });

  const getDepartment = (
    search,
    sub_department_id,
    department_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    subDeptServices
      .getDepartment(
        handleKey(
          search,
          sub_department_id,
          department_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        setDeptList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    sub_department_id,
    department_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      sub_department_id !== "" &&
      sub_department_id !== undefined &&
      sub_department_id !== null
    ) {
      queryParm = queryParm + "&sub_department_id=" + sub_department_id;
    }
    if (
      department_id !== "" &&
      department_id !== undefined &&
      department_id !== null
    ) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    sub_department_id,
    department_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    subDeptServices
      .getExportValue(
        handleKey(
          search,
          sub_department_id,
          department_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sub-department.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteDepartment = (idx) => {
    subDeptServices
      .deleteDepartment(idx)
      .then((res) => {
        if (res.data.status == true) {
          getDepartment(
            "",
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, deptName) => {
    confirmAlert({
      title: "Delete Sub Department",
      message: `Are you sure to delete ${deptName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDepartment(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addDepart = () => {
    setShowForm(true);
    setEditMode(false);
    setDepartment(null);
  };
  const editDept = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setDepartment(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setDepartment(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getDepartment(
      searchStr,
      departmentDataFilter.sub_department_id,
      departmentDataFilter.department_id,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    filterClose();
    getDepartment(searchStr, "", "", "", 1, pageSizeNo, sortData.sort_by, true);
  };

  const filterClose = () => {
    filterclose();
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getDepartment(
        e.target.value.toLowerCase(),
        departmentDataFilter.sub_department_id,
        departmentDataFilter.department_id,
        departmentDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };
  useEffect(() => {
    getDepartment(
      searchStr,
      departmentDataFilter.sub_department_id,
      departmentDataFilter.department_id,
      departmentDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  const getAllSubDept = (id) => {
    subDeptServices
      .getAllSubDept(id)
      .then((response) => {
        setAllDept(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getAllDepartment = (paginate) => {
    deptServices
      .getAllDepartment(paginate)
      .then((response) => {
        setDropDept(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const filterShow = () => {
    filteropen();
    getAllDepartment(false);
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getDepartment(
      searchStr,
      departmentDataFilter.sub_department_id,
      departmentDataFilter.department_id,
      departmentDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const mainDataFilter = () => {
    setDepartmentDataForExport({
      ...departmentDataForExport,
      sub_department_id: departmentDataFilter.sub_department_id,
      department_id: departmentDataFilter.department_id,
      status: departmentDataFilter.status,
    });
    const sub_department_id = departmentDataFilter.sub_department_id;
    const department_id = departmentDataFilter.department_id;
    const status = departmentDataFilter.status;
    getDepartment(
      searchStr,
      sub_department_id,
      department_id,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getDepartment(
        data,
        departmentDataFilter.sub_department_id,
        departmentDataFilter.department_id,
        departmentDataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getDepartment(
      searchStr,
      departmentDataFilter.sub_department_id,
      departmentDataFilter.department_id,
      departmentDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Sub Department</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Sub Department</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Sub Department Code,
                        <br />
                        Sub Department Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Sub Department Code, Sub Department Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />{" "}
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          type="button"
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              departmentDataForExport.sub_department_id,
                              departmentDataForExport.department_id,
                              departmentDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      addDepart();
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured sort-overlap">
            <thead>
              <tr>
                <th>Sub Department Code</th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls"
                      : sortData["name"]
                  }
                >
                  Sub Department Name
                </th>
                <th
                // onClick={(e) => {
                //   handlerData("department_code");
                // }}
                // className={
                //   sortData["department_code"] === undefined
                //     ? "unsortcls"
                //     : sortData["department_code"]
                // }
                >
                  Department Name & Code
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {deptList?.length ? (
                deptList?.map((data, index) => (
                  <>
                    <tr key={`dept${index}`}>
                      <td>{data?.code}</td>
                      <td className="text-theme fw-bold">{data?.name}</td>

                      <td>
                        {data?.department_name}&nbsp;(
                        {data?.department_code})
                      </td>
                      <td>{data?.updated_by}</td>
                      <td>
                        {moment
                          .utc(data?.updated_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                      <td>
                        {data?.is_active ? (
                          <span className="badge bad-status badge-success">
                            Active
                          </span>
                        ) : (
                          <span className="badge bad-status badge-danger">
                            Inactive
                          </span>
                        )}
                      </td>
                      {finalUrlValue?.V ||
                      finalUrlValue?.E ||
                      finalUrlValue?.R ? (
                        <td>
                          {finalUrlValue?.V ? (
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              <img src="images/eye.png" alt="" />
                            </button>
                          ) : (
                            ""
                          )}

                          {finalUrlValue?.E ? (
                            <button
                              className="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editDept(data);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}

                          {finalUrlValue?.R ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i className="far fa-trash-alt text-danger"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {deptList?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Select Department</label>

            <select
              className="form-control newbgselect main-pointer"
              classNamePrefix="select"
              name="department_id"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  department_id: e.target.value,
                });

                getAllSubDept(e.target.value);
              }}
              value={departmentDataFilter.department_id}
            >
              <option value="" label="Select Department" disabled />

              {renderDeptOptions(dropDept)}
            </select>
          </div>
          <div className="form-group innergroup">
            <label>Select Sub-Department</label>

            <select
              className="form-control newbgselect main-pointer"
              classNamePrefix="select"
              name="sub_department_id"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  sub_department_id: e.target.value,
                });
              }}
              value={departmentDataFilter.sub_department_id}
            >
              <option value="" label="Select Sub-Department" disabled />

              {renderOrgOptions(allDept)}
            </select>
          </div>

          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setDepartmentDataFilter({
                  ...departmentDataFilter,
                  status: e.target.value,
                });
              }}
              value={departmentDataFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value={true} label="Active" />
              <option value={false} label="Inactive" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            Clear
          </button>
          <button
            type="submit"
            onClick={() => mainDataFilter()}
            className="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddDepartment
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editDepartment={department}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {viewForm ? (
        <>
          <ViewDepartment
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default SubDeptGrid;
