import React, { useEffect, useState } from "react";
import * as SingUp from "../../service/singup";
import { useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Badge,
  Form,
  Card,
  Button,
} from "react-bootstrap";
import Clientlogo from "../../assets/img/logo.png";
import Sidemenu from "../include/sidemenu";
import Header from "../include/header";

function UserManagementGrid(props) {
  const { viewRaw, editUser } = props;

  //   const location = useLocation();
  //   const permission = location?.state?.permission;

  //   console.log('viewRaw', viewRaw);
  //   const [userData, setUserData] = useState({
  //     employeename: "",
  //     contactno: "",
  //     emailid: "",
  //     employeestatus: "A",
  //     employeeid: 0
  //   });

  //   const [checkValue, setCheckValue] = useState({});
  //   const [moduleRaw, setModuleRaw] = useState([]);

  function backHandler() {
    props.onCloseView();
  }

  function editHandler() {
    props.editHandle(viewRaw);
  }
  //   function moduleObj(input_data) {
  //     const enteredValues = {
  //       "employeeid": userData.employeeid,
  //       "name": input_data.employeename,
  //       "email": input_data.emailid,
  //       "phone": `${input_data.contactno}`,
  //       "status": userData.employeestatus,
  //       "userRights": moduleRaw
  //     }
  //     singUp(enteredValues);
  //   }

  //   const setCheckedData = (e, mRow, keyN) => {
  //     moduleRaw.filter((someobject) => someobject.rightid === mRow).forEach(v => { v[keyN] = e });

  //     checkValue[mRow] = (e === 1 ? true : false);
  //     setCheckValue({ mRow: e })

  //     console.log(checkValue);
  //   }

  //   const singUp = (rawData) => {
  //     SingUp.updateSingUp(rawData).then((response) => {
  //       if (response.success) {
  //         // props.onAddUser(response);

  //       }
  // toast.success("Update Successfull !")
  // setTimeout(() => {
  //   onClose();
  // }, 4000);

  //     });
  //   }

  //   useEffect(() => {
  //     SingUp.getRightSperm().then((response) => {
  //       const ritem = response.data;
  //       if (viewRaw["rightperm"] !== undefined) {
  //         const myitem = viewRaw["rightperm"];

  //         myitem.forEach((citem) => {
  //           ritem.filter((someobject) => someobject.rightid == citem.rightid).forEach(v => {
  //             v["addp"] = citem.add;
  //             v["editp"] = citem.edit;
  //             v["viewp"] = citem.view;
  //             v["downloadp"] = citem.download;
  //             checkValue[citem.rightid] = (citem.add == 1 ? true : citem.edit == 1 ? true : citem.view == 1 ? true : citem.download == 1 ? true : false);

  //           });

  //         })
  //       }
  //       setModuleRaw(ritem)
  //     });

  //   }, []);

  //   useEffect(() => {
  //     if (editUser) {
  //       Object.keys(editUser).forEach((item) => {
  //         userData[item] = editUser[item];

  //       });

  //     }
  //   }, [editUser]);

  return (
    <>
      <div className="content-wrapper">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={12}>
                  <span
                    className="backbtn"
                    //    onClick={backHandler}
                  >
                    <i className="fa fa-angle-left"></i> Back
                  </span>
                </Col>
                <Col md={4} xs={6}>
                  <p className="pageheadsmall">User Details</p>
                </Col>
                <Col md={8} xs={6} className="text-end">
                  {/* {permission?.editp === 1 ? (<Button variant="editbtn" onClick={editHandler} style={{ backgroundColor: "transparent" }}><i className="fa fa-pencil"></i> Edit</Button>) : null}   */}
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <Card>
                    <Card.Body className="p-1">
                      <div className="clientlogo addclientlogo">
                        <Image src={Clientlogo} alt="" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={10}>
                  <Card className="reviewdata userdetailmanagemargin">
                    <Card.Body>
                      <Row>
                        <Col md={2} xs={6}>
                          <div className="company-info">
                            <label>First Name</label>
                            {/* <p>{viewRaw.employeename}</p> */}
                          </div>
                        </Col>
                        <Col md={5} xs={6}>
                          <div className="company-info">
                            <label>Email</label>
                            {/* <p>{viewRaw.emailid}</p> */}
                          </div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="company-info">
                            <label>Mobile Number</label>
                            {/* <p>{viewRaw.contactno}</p> */}
                          </div>
                        </Col>
                        <Col md={1} xs={6}>
                          <div className="company-info">
                            <label>Status</label>
                            {/* {viewRaw.employeestatus === 1 ? <Badge bg="success">'Active'</Badge> : <Badge bg="danger">'Inactive'</Badge>} */}
                          </div>
                        </Col>
                        {/* <Col md={2} xs={6}>
                          <div className="company-info">
                            <label>Employee ID</label>
                            <p></p>
                          </div>
                        </Col>
                        <Col md={2} xs={6}>
                          <div className="company-info">
                            <label>Department</label>
                            <p></p>
                          </div>
                        </Col>
                        <Col md={3} xs={6}>
                          <div className="company-info">
                            <label>Reporting to</label>
                            <p></p>
                          </div>
                        </Col>
                        <Col md={3} xs={6}>
                          <div className="company-info">
                            <label>PAN Number</label>
                            <p></p>
                          </div>
                        </Col> */}
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="company-info mb-2">
                            <label className="f-14">Modules & Rights</label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-2 mb-sm-4 pt-0 pt-sm-3">
                        <Col md={2}>
                          <Form.Label>Modules</Form.Label>
                        </Col>
                        {/* <Col md={12}>
                          {console.log('moduleRaw', moduleRaw)}
                          {moduleRaw.map((moduleData, index) => (
                            (moduleData.addp == 1 || moduleData.viewp == 1 || moduleData.editp == 1 || moduleData.downloadp == 1 ? (
                              <div className="modulebg" key={index}>
                                <Row>

                                  {
                                    <Col md={3} className="border-end mobmodulebg-bor">

                                      <Form.Label htmlFor={moduleData.rightname}>{checkValue[moduleData.rightid]}{moduleData.rightname}</Form.Label>
                                    </Col>
                                  }


                                  {moduleData.add === 1 &&
                                    <Col md={2} xs={3} className="border-end mobmoduleview">

                                      <Form.Label htmlFor={moduleData.rightname + "add"}>{moduleData.addp == 1 ? "Add" : null}</Form.Label>
                                    </Col>
                                  }

                                  {moduleData.edit === 1 &&
                                    <Col md={2} xs={3} className="border-end mobmoduleview">

                                      <Form.Label htmlFor={moduleData.rightname + "edit"}>{moduleData.editp == 1 ? "Edit" : null}</Form.Label>
                                    </Col>
                                  }
                                  {moduleData.view === 1 &&
                                    <Col md={2} xs={3} className="border-end mobmoduleview">

                                      <Form.Label htmlFor={moduleData.rightname + "view"}>{moduleData.viewp == 1 ? "View" : null}</Form.Label>
                                    </Col>
                                  }
                                  {moduleData.download === 1 &&
                                    <Col md={2} xs={3} className="mobmoduleview">

                                      <Form.Label htmlFor={moduleData.rightname + "download"}>{moduleData.downloadp == 1 ? "Download" : null}</Form.Label>
                                    </Col>
                                  }
                                </Row>
                              </div>
                            ) : null)
                          ))}


                        </Col> */}
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default UserManagementGrid;
