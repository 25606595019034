import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { selectDashboardData } from "../redux/piDataStore";
import { useSelector } from "react-redux";

function OfficeApp() {
  const extDashboardData = useSelector(selectDashboardData);

  const handleUrl = (url) => {
    window.open(url);
  };
  return (
    <>
      <div className="officeappbox">
        <ul>
          {extDashboardData?.O365?.map((item, idx) => (
            <>
              <li key={`ext${idx}`}>
                <Link onClick={() => handleUrl(item?.url)}>
                  <img src={item?.icon_url} />
                </Link>
              </li>
              {/* <li>
                <Link to="/">
                  <img src="images/office/2.png" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="images/office/3.png" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="images/office/4.png" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="images/office/5.png" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="images/office/6.png" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="images/office/7.png" />
                </Link>
              </li> */}
            </>
          ))}
        </ul>
        <div class="modaldefaultclosecenter modaldefaultclose uploaded-image p-0 border-0 position-static"> 
            <button class="btn-close"></button>
        </div>
      </div>
    </>
  );
}

export default OfficeApp;
