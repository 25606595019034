import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import * as ReceiptService from "../../service/cashbookService";
import { ToastContainer, toast } from "react-toastify";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const AddReceipt = (props) => {
  let condition = "false";
  const {
    showForm,
    onClose,
    addnewshow,
    onAddCallBack,
    editModuleData,
    edit,
    showView,
  } = props;
  const [masterData, setMasterData] = useState([]);
  const [cashbookReciept, setCashBookReciept] = useState({
    receipt_date: "",
    receipt_gl_id: "",
    check_no: "",
    amount: 0,
  });

  const [formValidation, setFormValidation] = useState({
    receipt_date: "",
    receipt_gl_id: "",
    check_no: "",
    amount: "",
  });

  const [benValue, setBenValue] = useState();
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const userDetails = useSelector(selectUserData);

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: (values) => {
  //     console.log("values==>", values);
  //   },
  // });

  // const {
  //   values,
  //   handleSubmit,
  //   setValues,
  //   errors,
  //   getFieldProps,
  //   setFieldValue,
  //   touched,
  // } = formik;

  // const getSelectFeildProps = (options, key) => {
  //   return {
  //     value: options
  //       ? options.find((option) => option.value === values[key])
  //       : "",
  //     onChange: (option) => setFieldValue(key, option?.value),
  //   };
  // };
  const getMasterData = (data) => {
    ReceiptService.getMasterData(data)
      .then((response) => {
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getReceiptDataList = () => {
    ReceiptService.getReceiptDataList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj?.id,
            label: `${obj?.desc} (${obj?.code})`,
            id: obj?.id,
          };
        });
        setBeneficiaryData(res);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const saveReceipt = async (cashbookReciept) => {
    if (condition == "false") {
      condition = "true";
      let datas = cashbookReciept;
      datas.amount = Number(cashbookReciept.amount);

      await ReceiptService.saveReceipt(datas)
        .then((response) => {
          condition = "false";
          if (response?.data.code == 200) {
            toast.success("Cashbook Receipt Created Successfully");
            onAddCallBack();
            onClose();
            getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateReceipt = async (id, data) => {
    if (condition == "false") {
      condition = "true";
      await ReceiptService.updateReceipt(id, data)
        .then((response) => {
          condition = "false";
          if (response.data.code == 200) {
            toast.success("Cahbook Receipt Updated Successfully");
            onAddCallBack();
            onClose();
            getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    let regexBlank = "^[0-9]*$";
    if (!cashbookReciept.receipt_date) {
      isError = true;
      formerr.receipt_date = "Receipt Date is required";
      setFormValidation(formerr);
    }
    if (!cashbookReciept.receipt_gl_id) {
      isError = true;
      formerr.receipt_gl_id = "*Receipt Id is required";
      setFormValidation(formerr);
    }
    if (!cashbookReciept.amount) {
      isError = true;
      formerr.amount = "*Amount is required";
      setFormValidation(formerr);
    } else if (cashbookReciept.amount.length > 9) {
      isError = true;
      formerr.amount = "*Amount should be 9 digit or less ";
      setFormValidation(formerr);
    }
    if (!cashbookReciept.check_no) {
      isError = true;
      formerr.check_no = "*Transaction No is required";
      setFormValidation(formerr);
    } else if (cashbookReciept.check_no.length > 15) {
      isError = true;
      formerr.check_no = "*Check No should be 15 digit or less ";
      setFormValidation(formerr);
    }
    return isError;
  };

  const pushBenId = (e) => {
    setBenValue(e);
    setCashBookReciept({
      ...cashbookReciept,
      receipt_gl_id: e.value,
    });
  };
  const handleValidateForm = (cashbookReciept) => {
    if (edit == false) {
      if (handleValidate()) return false;
      saveReceipt(cashbookReciept);
    } else {
      if (handleValidate()) return false;
      updateReceipt(cashbookReciept?.id, cashbookReciept);
    }
  };

  useEffect(() => {
    if (editModuleData) {
      setCashBookReciept(editModuleData);
      Object.keys(editModuleData).forEach((item) => {
        if (item === "receipt_gl_id") {
          let value = beneficiaryData?.filter((obj) => {
            console.log(obj, "woo");
            if (obj.value === Number(editModuleData[item])) {
              return true;
            }
          })[0];
          console.log(beneficiaryData, "friday");
          setBenValue(value);
          setCashBookReciept((prev) => ({
            ...prev,
            receipt_gl_id: value?.value,
          }));
        } else {
          cashbookReciept[item] = editModuleData[item];
        }
      });
    }
  }, [editModuleData, beneficiaryData]);
  useEffect(() => {
    getMasterData(userDetails?.id);
    getReceiptDataList();
  }, []);

  return (
    <>
      {/* <button class="btn btn-primary-inner" onClick={addnewopen}>
        <img src="images/upload.png" alt="" class="mr-3" />
        {edit === true ? "Edit" : "Add"} Receipt
      </button> */}
      <Modal
        show={addnewshow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title> {edit === true ? "Edit" : "Add"} Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="tickethistorbg mt-2">
                    <div class="row viewformaftersubmit">
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Cashbook </p>
                          <label>{masterData[0]?.desc}</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Current Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                            {masterData[0]?.current_balance.toFixed(2)}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Provision Balance</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                            {masterData[0]?.provision_balance.toFixed(2)}
                          </label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="innergroup">
                          <p>Cash Limit</p>
                          <label>
                            <i class="fas fa-rupee-sign mr-1"></i> INR{" "}
                            {masterData[0]?.cash_limit}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Receipt Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="receipt_date"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setCashBookReciept({
                          ...cashbookReciept,
                          receipt_date: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          receipt_date: "",
                        });
                      }}
                      value={cashbookReciept?.receipt_date}
                    />
                    <div className="small text-danger">
                      {formValidation.receipt_date}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Receipt From<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={beneficiaryData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="receipt_gl_id"
                      onChange={(e) => {
                        pushBenId(e);
                        setFormValidation({
                          ...formValidation,
                          receipt_gl_id: "",
                        });
                      }}
                      value={benValue}
                    />
                    <div className="small text-danger">
                      {formValidation.receipt_gl_id}
                    </div>
                    {/* <Select
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        {...getSelectFeildProps(options, "receipt_gl_id")}
                      /> */}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Cheque/ Transaction No<span class="text-danger">*</span>
                    </label>
                    {/* <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Cheque/ Transaction No"
                        {...getFieldProps("transaction_no")}
                      /> */}
                    <input
                      // type="number"
                      class="form-control"
                      placeholder="Enter Cheque/ Transaction No"
                      name="check_no"
                      type="text"
                      pattern="\d*"
                      maxlength="15"
                      onChange={(e) => {
                        setCashBookReciept({
                          ...cashbookReciept,
                          check_no: e.target.value.trim(),
                        });
                        setFormValidation({
                          ...formValidation,
                          check_no: "",
                        });
                      }}
                      value={cashbookReciept?.check_no}
                    />
                    <div className="small text-danger">
                      {formValidation.check_no}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      class="form-control"
                      placeholder="Enter Amount"
                      name="amount"
                      maxlength="10"
                      type="number"
                      // maxLength={15}
                      onChange={(e) => {
                        setCashBookReciept({
                          ...cashbookReciept,
                          amount: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          amount: "",
                        });
                      }}
                      value={cashbookReciept?.amount}
                    />
                    <div className="small text-danger">
                      {formValidation.amount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => {
              handleValidateForm(cashbookReciept);
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddReceipt;
