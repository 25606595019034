import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";


export const getZoneDropDown = async (all) => {
    return axios.get(`${AUTH_BASE_URL}/zone/drop-down?all=${all}`);
};

export const getRegionDropDown = async (zoneId) => {
    return axios.get(`${AUTH_BASE_URL}/region/drop-down?zone=${zoneId}&all=false`);
};

export const getTerritoryDropDown = async (regionId) => {
    return axios.get(`${AUTH_BASE_URL}/territory/drop-down?region=${regionId}&all=false`);
};

export const getZRT = async (regionId) => {
    return axios.get(`${AUTH_BASE_URL}/territory/my-zrt`);
};