import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as SkillService from "../../service/skill";
import * as CreateSkillService from "../../service/createSkill";
import { Tabs, Tab } from "react-bootstrap";
import CreateSkill from "./CreateSkill";
import ReviewSkill from "./ReviewSkill";
import StatusSkill from "./StatusSkill";
import ApproveSkill from "./ApproveSkill";
import MySkill from "./MySkill";

function Skill() {
  const [permissionAccess, setPermissionAccess] = useState({});
  const [isDisplay, setIsDisplay] = useState({});
  const [userAccess, setUserAccess] = useState({});
  const [showSkillTab, setShowSkillTab] = useState(false)

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const [currentTab, setCurrentTab] = useState("MySkills");

  const [formValidation, setFormValidation] = useState({
    skill_id: "",
    employee_id: "",
    current_level: "",
  });

  const onAddCallBack = (data) => {
    setCurrentTab(data);
  };
  useEffect(() => {
    getSkillPermission();
    displaySkillPlan();
  }, []);

  useEffect(() => {
    setFormValidation({ skill_id: "", employee_id: "", current_level: "" });
  }, [currentTab]);

  const displaySkillPlan = () => {
    CreateSkillService.displaySkillPlan()
      .then((response) => {
        setIsDisplay(response?.data?.dataList);
        CreateSkillService.userAccess({ plan_id: response?.data?.dataList?.plan }).then(response => {
          setUserAccess(response?.data?.dataList)
        }).catch(err => {
          console.log(err)
        })
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div>
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              <li class="active">
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
              <li>
                <Link to="/skill-sub-group">Sub Group Master</Link>
              </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
              <li>
                <Link to="/skill-location">Skill Location Access</Link>
              </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill Matrix</h4>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs">
                <Tabs
                  defaultActiveKey="Status"
                  id="approvaltabs"
                  onSelect={(e) => setCurrentTab(e)}
                  activeKey={currentTab}
                >
                  {
                    isDisplay?.is_create && userAccess?.is_creator &&
                    <Tab
                      eventKey="Create"
                      title={<React.Fragment>Create Skill Plan</React.Fragment>}
                    >
                      <CreateSkill
                        setFormValidation={setFormValidation}
                        formValidation={formValidation}
                        isDisplay={isDisplay}
                      />
                    </Tab>
                  }
                  {
                    isDisplay?.is_review && userAccess?.is_creator &&
                    <Tab
                      eventKey="Review"
                      title={<React.Fragment>Review Skill Plan</React.Fragment>}
                    >
                      <ReviewSkill
                        currentTab={currentTab}
                        onAddCallBack={onAddCallBack}
                        isDisplay={isDisplay}
                      />
                    </Tab>
                  }
                  {
                    isDisplay?.is_review && userAccess?.is_reviewer &&
                    <Tab
                      eventKey="Approve"
                      title={<React.Fragment>Approve Skill Plan</React.Fragment>}
                    >
                      <ApproveSkill
                        currentTab={currentTab}
                        onAddCallBack={onAddCallBack}
                        isDisplay={isDisplay}
                      />
                    </Tab>
                  }
                  {
                    userAccess?.is_creator &&
                    <Tab
                      eventKey="ReviewStatus"
                      title={<React.Fragment>Review Status</React.Fragment>}
                    >
                      <StatusSkill currentTab={currentTab} isDisplay={isDisplay}
                        type="review"
                      />
                    </Tab>
                  }
                  {
                    userAccess?.is_reviewer &&
                    <Tab
                      eventKey="ApproveStatus"
                      title={<React.Fragment>Approve Status</React.Fragment>}
                    >
                      <StatusSkill currentTab={currentTab} isDisplay={isDisplay}
                        type="approve"
                      />
                    </Tab>
                  }
                  <Tab
                    eventKey="MySkills"
                    title={<React.Fragment>My Skills</React.Fragment>}
                  >
                    <MySkill currentTab={currentTab} isDisplay={isDisplay}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skill;
