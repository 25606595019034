import React, { useState, useEffect } from "react";
import AssignGiMapping from "./assignGiMapping";
import * as userGl from "../../service/userGl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const AssignGiIndex = () => {
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editUserGlData, setEditUserGlData] = useState(null);
  const [userGlMapData, setUserGlMapData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");


  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {


      // getOtherExpenseList(
      //   e.target.value.toLowerCase(),
      //   currentPage,
      //   pageSizeNo,
      //   sortData.sort_by,
      //   true,
      //   userDetails?.id,
      //     filterTravel.from_date,
      //   filterTravel.to_date,
      //   filterTravel.status,
      // filterTravel.expense_type,
      // filterTravel.organisation.code
      // );
      getGiUserTable(
        e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true,
        );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getGiUserTable(
        "", 1, pageSizeNo, "-id", true,
        );
      
    }

    
  };
  
  const handleKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    gl_type,
    organisation_id,
    user_id,
    gl_id,
    is_active
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    

    

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (gl_type !== "" && gl_type !== undefined && gl_type !== null) {
      queryParm = queryParm + "&gl_type=" + gl_type;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (gl_id !== "" && gl_id !== undefined && gl_id !== null) {
      queryParm = queryParm + "&gl_id=" + gl_id;
    }
    return queryParm;
  };

  const getGiUserTable = (

    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    gl_type,
    organisation_id,
    user_id,
    gl_id,
    is_active

  ) => {
    
    userGl
      .getGiUserTable(

        handleKey(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          gl_type,
          organisation_id,
          user_id,
          gl_id,
          is_active
        )

        

      )
      .then((response) => {
        setUserGlMapData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getGiUserTable(
      searchStr, activePage, pageSizeNo, "-id", true);
  };

  const onAddButtonClick = () => {
    setShow(true);
    setEditMode(false);
    setEditUserGlData(null);
  };
  const onEdit = (data) => {
    setShow(true);
    setEditMode(true);
    setEditUserGlData(data);
  };

  const onAddCallBack = () => {
    setShow(false);
    
    getGiUserTable(
      "", currentPage, pageSizeNo, "-id", true);
  };
  const onCloseButtonClick = () => {
    setShow(false);
  };

  useEffect(() => {
    setCurrentPage(1)
    getGiUserTable(
      searchStr, 1, pageSizeNo, "-id", true,
      );

  }, [pageSizeNo]);

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">GI TI Mapping</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
          <div class="col-lg-4">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on{" "}
                              <strong> Username, GI Username</strong>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            id="searchbar"
                            type="text"
                            class="form-control"
                            placeholder="Search on Username..."
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack();
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={onAddButtonClick}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Username</th>
                <th>GI Username</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userGlMapData?.length ? (
                userGlMapData?.map((data, index) => (
                  <>
                    <tr>
                      <td>{index+1}.</td>
                      <td>
                        {data?.user_name}-({data?.user_code})
                      </td>
                      <td>
                        {data?.gi_user_name}-({data?.gi_user_code})
                      </td>

                      <td>{data?.createdby_username+` (${data?.createdby_usercode})`}</td>
                      <td>{moment.parseZone(data?.created_by)?.format("DD-MM-YYYY hh:mm A")}</td>
                      <td>{data?.is_active?"Active":"Inactive"}</td>

                      <td>
                        <button
                          className="btn btn-blanktd text-primary"
                          type="button"
                          onClick={() => onEdit(data)}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {userGlMapData?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                key={pageSizeNo}
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      {show && (
        <AssignGiMapping
          show={show}
          onCloseButtonClick={onCloseButtonClick}
          edit={editMode}
          editUserGlData={editUserGlData}
          onAddCallBack={onAddCallBack}
        />
      )}
    </>
  );
};

export default AssignGiIndex;
