import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import * as agreement from "../../service/agree-terms";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";

function AgreeTerms(props) {
  const Location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isChecked, setisChecked] = useState(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const logoutHandle = async () => {
    localStorage.clear();
    await logoutHandle().then((response) => {
      if (response?.status === 200) {
        if (localStorage.getItem("fromAd") === "true") {
          localStorage.clear();
          sessionStorage.clear();
          handleLogout("redirect");
        } else {
          window.location.replace("/custom");
        }
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };

  const acceptTerms = (event) => {
    event.preventDefault();
    let payload = {
      accepted: true,
    };

    agreement
      .acceptTerms(payload)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          if (Location?.state?.component == "quater-terms") {
            Navigate("/quater-terms", {
              state: {
                component: "dashboard",
              },
            });
          }
          if (Location?.state?.component == "social-policy") {
            Navigate("/social-policy", {
              state: {
                component: "quater-terms",
              },
            });
          }
          if (Location?.state?.component == "mandatory") {
            Navigate("/mandatory");
          }
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div className="login-bg">
        <div className="container">
          <div className="row justify-content-between my-3">
            <div className="col-md-2 d-flex flex-column text-center text-sm-left">
              <div>
                <img src={Logo} alt="" height="55px" />
              </div>
            </div>
            <div className="col-md-10 col-lg-10 mt-3 mt-sm-0">
              <div className="loginTabs">
                <div className="tabfliping agreebox">
                  <h3>Terms & Conditions</h3>
                  <form onSubmit={(e) => acceptTerms(e)}>
                    <div className="agreecontent">
                      <div>
                        <div
                          style={{
                            width: "100%",
                            height: "80vh",
                          }}
                        >
                          <iframe
                            src="coc.pdf"
                            frameborder="0"
                            width="100%"
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                      <div>
                        <label className="logCheck d-inline-block mr-4">
                          Please accept our *T&C
                          <input
                            type="checkbox"
                            name="termscondition"
                            onChange={() => {
                              setisChecked((prev) => !prev);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary agreebtn"
                      type="submit"
                      disabled={!isChecked}
                    >
                      Proceed
                    </button>
                    {/* <Link to="/mandatory" className="btn btn-primary agreebtn"
                                        
                                        >Proceed</Link>  */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgreeTerms;
