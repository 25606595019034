import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getTravelList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse?${queryParm}`);
};

export const repostTravel = async (id,date) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/repost_travel?id=${id}&date=${date}`);
};

export const getintimreport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_intimation_report2?${queryParm}`
  );
};
export const getintim_detailreport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_intimation_detailreport?${queryParm}`
  );
};

export const getTravelListFk = async (travelId) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse?travel_id=${travelId}&sort_by_2=from_date`
  );
};

export const getBackDateList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_back_date?${queryParm}`
  );
};
export const getPurposeList = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/purpose/drop-down`);
};
export const getDomesticTemp = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expansetravel_detail?sort_by=from_date`
  );
};

export const updateTable = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expansetravel_detail/${id}`,
    data
  );
};
export const deleteData = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/travel_detail/${id}`);
};
export const getModeList = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/mode/drop-down`);
};
export const getRateList = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/currency_to_inr`);
};
export const updateRateList = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/currency_to_inr/${id}`,
    data
  );
};
export const updateRateData = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/update_2/${id}?expense_exception=false&vehicle_exception=false`,
    data
  );
};

export const getReasonList = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/reason/drop-down`);
};
export const getCityList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/city?paginate=${paginate}`);
};
export const getLeaveManageListUser = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/ess?paginate=${paginate}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
};
export const getAllBranch = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?paginate=${paginate}`);
};
export const getLeaveManageListData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess?${queryParm}`);
};
export const getLeaveAllocation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/ess/allocation?${queryParm}`);
};
export const saveTravelInfo = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse`, data);
};
export const saveTravelBackDateInfo = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_back_date_insert`,
    data
  );
};
export const updateTravelBackDateInfo = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_back_date_update/${id}`,
    data
  );
};
export const saveTravelAdvanceAccess = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/advance_access`, data);
};
export const updateTravelAdvanceAccess = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/advance_access/${id}`,
    data
  );
};
export const saveTravelAdvDateInfo = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_advance`, data);
};

export const saveConveyenceInfo = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_conveyance`,
    data
  );
};

export const updateSurveyCategory = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/survey-category/${id}`, data);
};
export const updateTravelInfo = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/travel-n-expanse/${id}`, data);
};

export const getCity = async (countryId) => {
  return axios.get(
    `${AUTH_BASE_URL}/city?country_id=${countryId}&paginate=false`
  );
};
export const getCitys = async () => {
  return axios.get(`${AUTH_BASE_URL}/city?paginate=false`);
};
export const getContinent = async () => {
  return axios.get(`${AUTH_BASE_URL}/continent/drop-down`);
};

export const saveTable = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expansetravel_detail`, data);
};

export const getCountry = async (continentId) => {
  return axios.get(
    `${AUTH_BASE_URL}/country?continent=${continentId}&paginate=false&sort_by=name`
  );
};
export const deleteOnDuty = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/${id}`, data);
};
export const cancelOnDuty = async (id, cancel_reason) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/cancel/${id}?cancel_reason=${cancel_reason}`
  );
};
export const getAllList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse?${queryParm}`);
};
export const saveMultiApprove = async (checkData) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/approval`, checkData);
};
export const deleteMultiApprove = async (checkData) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/delete`, checkData);
};
export const getUserDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=false`);
};
export const getAdvanceDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_advance?${queryParm}`
  );
};
export const getAdvanceDetailsNew = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_advance/all?${queryParm}`
  );
};

export const updateAdvDetails = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/other_advance/${id}`,
    data
  );
};
export const getGlList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-gl-code/list?organisation_id=${organisation_id}`
  );
};
export const getGlListOtherExpNew = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanseother_expense_gl_mapping?${queryParm}`
  );
};
export const getGlListOtherExp = async (gl_type, organisation_id, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanseother_expense_assigned_gl?${
      gl_type == undefined || gl_type == "" || gl_type == null
        ? ""
        : `&gl_type=${gl_type}`
    }${
      organisation_id == undefined ||
      organisation_id == "" ||
      organisation_id == null
        ? ""
        : `&organisation_id=${organisation_id}`
    }&user_id=${user_id}`
  );
};
export const getCostList = async (organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-cost-center/list?organisation_id=${organisation_id}`
  );
};
export const saveOtherExpense = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/other_expense`, data);
};
export const saveHotelExpenseData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/expense_hotel`, data);
};
export const saveForeignExpData = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/foreign_expense_conveyance/${id}`,
    data
  );
};

export const uploadFile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getOtherExpenseList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanseother_expense?${queryParm}`
  );
};
export const importExel = async (expense_type, data) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/other_expense_import?expense_type=${expense_type}`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

// export const importExel = async (expense_type, data) => {
//   return axios.post(
//     `${AUTH_BASE_URL}/travel-n-expanse/other_expense_import?expense_type=${expense_type}`,
//     data
//   );
// };

export const updateOtherExpense = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense/${id}`,
    data
  );
};
export const getStateList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/state?paginate=${paginate}`);
};
export const getCurrencyList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/buss-transactions/master-currency/list?organisation_id=${queryParm}`
  );
};
export const getHotelList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel?travel_id=${queryParm}`
  );
};
export const getHotelListNew = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel_2?travel_id=${queryParm}`
  );
};
export const getTravelPickList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_pick_list?${queryParm}`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/export_pick_list?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const export_approved_expenselist = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/export_approved_expenselist?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

// export const getExportBills = async (queryParm) => {
//   return axios({
//     url: `${AUTH_BASE_URL}/travel-n-expanse/trv_bill_export?${queryParm}`,
//     method: "GET",
//     responseType: "blob",
//   });
// };

// export const getExportTravel = async (queryParm) => {
//   return axios({
//     url: `${AUTH_BASE_URL}/travel-n-expanse/export_travel_excp?${queryParm}`,
//     method: "GET",
//     responseType: "blob",
//   });
// };

export const getExportTravel = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_intimation_report2?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getExportTravelDetail = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_intimation_detailreport?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportConvey = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/export_convey_excp?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

// export const getExportHotel = async (queryParm) => {
//   return axios({
//     url: `${AUTH_BASE_URL}/travel-n-expanse/export_hotel_excp?${queryParm}`,
//     method: "GET",
//     responseType: "blob",
//   });
// };

export const getExportHotel = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/hotel_expense_report?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelHotelDeviat = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_hotel_deviation_report?${queryParm}`
  );
};

export const receivePickList = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/receive_pick_list`,
    data
  );
};

export const declinePickList = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/decline_pick_list`,
    data
  );
};

export const get_travel_deviation = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_t1_deviation_report?${queryParm}`
  );
};

export const get_travel_deviationExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_t1_deviation_report?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelConveyDeviat = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_conveyance_deviation_report?${queryParm}`
  );
};

export const getTravelConveyDeviatExport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_conveyance_deviation_report?${queryParm}`,
    { responseType: "blob" }
  );
};

export const getCheckExpenseList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_check_expense_list?${queryParm}`
  );
};

export const getCheckExpenseExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/export_check_list?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelById = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_travel_by_id?&id=${id}`
  );
};

export const getTaxRate = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/tax-rate/drop-down`);
};

export const getAirlines = async () => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/airline/drop-down`);
};

export const deleteHotel = async (id, data) => {
  return axios.delete(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel/${id}`,
    data
  );
};

//Conveyence
export const getConveyenceList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_conveyance?travel_id=${queryParm}`
  );
};
export const deleteConveyence = async (id, data) => {
  return axios.delete(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_conveyance/${id}`,
    data
  );
};

export const updateConveyence = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_conveyance/${id}`,
    data
  );
};
export const updateHotelExpense = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel/${id}`,
    data
  );
};
//DA
export const getDaList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da?travel_id=${queryParm}&is_visible=${true}`
  );
};
export const getTicketExpense = async (id, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_ticket?&travel_id=${id}&user_id=${user_id}`
  );
};

export const getVehicleExpense = async (id, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle?&travel_id=${id}&user_id=${user_id}`
  );
};

export const getHotelExpense = async (id, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel?&travel_id=${id}&user_id=${user_id}`
  );
};

export const getHotelExpenseReport = async (params) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/hotel_expense_report?${params}`
  );
};

export const getProvisionReport = async (params) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_provision_report?${params}`
  );
};

export const getProvisionReportExport = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_provision_report?${params}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getHotelExpenseReportExport = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/hotel_expense_report?${params}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelBills = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_all_bill_report?${queryParm}`
  );
};

export const getTravelBillsExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_all_bill_report2?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelProcessedTa = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_processed_ta_report?${queryParm}`
  );
};

export const getTravelProcessedTaExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/travel_processed_ta_report?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getTravelFCM = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/ticket_expense_report?${queryParm}`
  );
};

export const getTravelFCMExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/ticket_expense_report?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getDAExpense = async (id, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da?&travel_id=${id}&user_id=${user_id}`
  );
};

export const deleteTravel = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/travel-n-expanse/${id}`);
};

export const updateTravel = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/travel-n-expanse/${id}`, data);
};
export const updateConveyanceExpense = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_conveyance/${id}`,
    data
  );
};
// DA
export const saveDa = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/expense_da`, data);
};
// export const updateDa = async (data) => {
//   return axios.put(`${AUTH_BASE_URL}/travel-n-expanse/expense_da`, data);
// };
export const updateDa = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/travel-n-expanse/expense_da/${id}`, data);
};
export const getForeignExpData = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/foreign_expense_conveyance?&travel_id=${id}`
  );
};

//travel-n-expanse/petty_user_exists
export const getPettyUser = async (id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/petty_user_exists?&user_id=${id}`
  );
};
export const getOrgList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?organisation_id=${queryParm}`
  );
};
export const getTravelDataById = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse?${queryParm}`);
};
export const getCommonApproval = async (
  sub_type_id,
  page_no,
  page_size,
  sortby,
  paginate,
  search
) => {
  return axios.get(
    `${AUTH_BASE_URL}/approval_assignment?sub_type_id=${sub_type_id}&page_no=${page_no}&page_size=${page_size}&sort_by=${sortby}&paginate=${paginate}&search=${search}`
  );
};
export const getCommonApprovalTravel = async (
  page_no,
  page_size,
  sortby,
  paginate
) => {
  return axios.get(
    `${AUTH_BASE_URL}/approval_assignment?&page_no=${page_no}&page_size=${page_size}&sort_by=${sortby}&paginate=${paginate}`
  );
};
export const updateStatus = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/approval`, data);
};
// export const isDataExists = async (isData) => {
//   return axios.post(
//     `${AUTH_BASE_URL}/travel-n-expanse/travel_back_date`,
//     isData
//   );
// };

export const isDataExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/exists`, isData);
};
export const getEmployee = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/users?paginate=${paginate}`);
};
export const getEmployee2 = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/users?${params}`);
};
export const TravelCityCheck = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/city-exists`, data);
};
export const TravelTerritoryExists = async (user_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_territory_exists?user_id=${user_id}`
  );
};

export const postTravelChat = async (id, data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/chat/${id}`, data);
};

export const getTravelChat = async (id, queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/chat/${id}?${queryParm}`);
};
export const getFcmList = async (gst_no) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_fcm_vendor?${
      gst_no == undefined || gst_no == "" || gst_no == null
        ? ""
        : `&gst_no=${gst_no}`
    }`
  );
};
export const getEntitelmentPrice = async (user_id, city_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/user_entitlement?user_id=${user_id}&city_id=${city_id}`
  );
};

export const viewCheckExpenseAttachments = async (id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/view_checkexpense_attach/${id}`
  );
};

export const approveCheckExpense = async (pk, id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/approve_check_expense/${pk}?idd=${id}`
  );
};

export const get_approved_expenselist = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_approved_expenselist?${queryParm}`
  );
};

export const getDaData = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da_2?travel_id=${travel_id}`
  );
};
export const getDaDataList = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da?travel_id=${travel_id}&is_visible=${true}`
  );
};

export const under_process_list = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/under_process_list?${queryParm}`
  );
};
export const getDiscardedTravelExp = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_expnse_discarded?${queryParm}`
  );
};

export const trav_detail = async (tid) => {
  return axios.get(`${AUTH_BASE_URL}/travel-n-expanse/travel-detail/${tid}`);
};

export const saveRemarksData = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_approval`,
    data
  );
};

export const approvePickList = async (isData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/approve_pick_list`,
    isData
  );
};

export const managerapprovePickList = async (isData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/ssc_manager_approve_pick_list`,
    isData
  );
};

export const headapprovePickList = async (isData) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/ssc_head_approve_pick_list`,
    isData
  );
};

export const saveExpenseApproveData = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_expense_approval`,
    data
  );
};
export const getHotelListTwo = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_hotel_2?travel_id=${travel_id}`
  );
};

export const otherExpSendBack = async (params) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_send_back_to_user?${params}`
  );
};

export const getTravelAdvList = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/advance_access?${queryParm}`
  );
};

export const getOutstandingAmount = async (employee_code, travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/get_outstanding_amount?user_id=${employee_code}&travel_id=${travel_id}`
  );
};

export const chkGst = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/gst_certificates/exists`, data);
};

export const getContinentEntt = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/continent_entitlement_2?travel_id=${travel_id}`
  );
};

export const isExistsValue = async (user_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_expense_exist?user_id=${user_id}`
  );
};

export const getUserDetailsBackDated = async () => {
  return axios.get(`${AUTH_BASE_URL}/users?status=true&paginate=false`);
};

export const approveOtherExpFinal = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/other_advance_approval`,
    data
  );
};

export const otherAdvanceReminder = async (data) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/other_advance/send-reminder/${data}`
  );
};

export const getUserList = async (abortSignal) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details-new?bussiness_unit_id=0`,
    { signal: abortSignal }
  );
};

export const exportAllOtherAdvance = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/other_advance/export${data}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getCurrentUser = async (data, abortSignal) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-user-data?user_id=0`, {
    signal: abortSignal,
  });
};

export const updateDaBillDetail = async (data, id) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_da_ssc_user/${id}`,
    data
  );
};

export const getTravelExpenseVehicleSummary = async (
  user_id,
  travel_id,
  abortSignal
) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_summary?user_id=${user_id}&travel_id=${travel_id}`,
    { signal: abortSignal }
  );
};

export const getEmpListReport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/emp_list_report?${queryParm}`
  );
};
export const getEmpListReportExport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/emp_list_report?${queryParm}`,
    {
      responseType: "blob",
    }
  );
};

export const getTravelHotelDeviatExport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/travel_hotel_deviation_report?${queryParm}`,
    {
      responseType: "blob",
    }
  );
};

export const getLocalConveyanceReport = async (params) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/local_conveyance_report?${params}`
  );
};

export const getLocalConveyanceReportExport = async (params) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/local_conveyance_report?${params}`,
    method: "GET",
    responseType: "blob",
  });
};

export const reassignSSC=async (id,username,action)=>{
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/travel_expense_reassign_ssc?id=${id}&username=${username}&action=${action}`,{})
}
export const traveldiscardToPickList=async (id,username,action)=>{
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/travel_expense_discard_topicklist?id=${id}&username=${username}&action=${action}`,{})
}
