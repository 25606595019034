import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import AddBulkUpload from "./AddBulkUpload";
import { getBulkDataList } from "../../service/leaveService";
import ReactPaginate from "react-paginate";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import moment from "moment";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as leaveDataService from "../../service/leaveService";
import { getUsersListDataNew } from "../../service/userService";
import { get_sap_schedule } from "../../service/searchpo";

if (typeof window !== "undefined") {
  injectStyle();
}
function BulkUploadLeave() {
  const dispatch = useDispatch();
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-leave_date",
  });
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterValue, setFilterValue] = useState({
    leave_type_id: "",
    from_date: "",
    to_date: "",
    created_at: "",
    created_by:"",
    is_posted: "",
    user_id: "",
  });
  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [records, setRecords] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  const userData = useSelector(selectUserData);

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getLeaveManageDataList();
    getUserList();
    getTotalRecords(
      searchStr,
      true,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      filterValue?.leave_type_id === ""
        ? ""
        : filterValue?.leave_type_id?.value,
      filterValue?.from_date,
      filterValue?.to_date,
      filterValue?.created_at,
      filterValue?.created_by === "" ? "" : filterValue?.created_by?.value,
      filterValue?.is_posted,
      filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
      true
    );
    getSapSchedule();
  }, []);

  const getSapSchedule = () => {
    get_sap_schedule('bulk_leave_to_savior_sender').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }


  const getUserList = () => {
    getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserDataList(res);
      }
    });
  };

  const getLeaveManageDataList = () => {
    leaveDataService
      .getLeaveManageData(`$paginate=${false}&bulk=${true}`)
      .then((response) => {
        let leaveM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = {
            value: value.id,
            label: `${value.name} (${value?.code})`,
          };
          leaveM.push(data);
        });
        setLeaveList(leaveM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    leave_id,
    from_date,
    to_date,
    created_at,
    created_by,
    is_posted,
    user_id,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (leave_id !== "" && leave_id !== undefined && leave_id !== null) {
      queryParm = queryParm + "&leave_id=" + leave_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (created_at !== "" && created_at !== undefined && created_at !== null) {
      queryParm = queryParm + "&created_at=" + created_at;
    }
    if (created_by !== "" && created_by !== undefined && created_by !== null) {
      queryParm = queryParm + "&created_by=" + created_by;
    }
    if (is_posted !== "" && is_posted !== undefined && is_posted !== null) {
      queryParm = queryParm + "&is_posted=" + is_posted;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const getTotalRecords = async (
    search,
    status,
    currentPage,
    pageSizeNo,
    sortBy,
    leave_id,
    from_date,
    to_date,
    created_at,
    created_by,
    is_posted,
    user_id,
    paginate
  ) => {
    await getBulkDataList(
      handleKey(
        search,
        status,
        currentPage,
        pageSizeNo,
        sortBy,
        leave_id,
        from_date,
        to_date,
        created_at,
        created_by,
        is_posted,
        user_id,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        setRecords(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  const getTotalRecordsExport = async (
    search,
    status,
    currentPage,
    pageSizeNo,
    sortBy,
    leave_id,
    from_date,
    to_date,
    created_at,
    created_by,
    is_posted,
    user_id,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    await leaveDataService.getBulkDataListExport(
      handleKey(
        search,
        status,
        currentPage,
        pageSizeNo,
        sortBy,
        leave_id,
        from_date,
        to_date,
        created_at,
        created_by,
        is_posted,
        user_id,
        paginate
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      if (response?.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Bulk Leave.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTotalRecords(
      searchStr,
      true,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      filterValue?.leave_type_id === ""
        ? ""
        : filterValue?.leave_type_id?.value,
      filterValue?.from_date,
      filterValue?.to_date,
      filterValue?.created_at,
      filterValue?.created_by === "" ? "" : filterValue?.created_by?.value,
      filterValue?.is_posted,      
      filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
      true
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getTotalRecords(
      searchStr,
      true,
      currentPage,
      pageSizeNo,
      sdata?.sort_by,
      filterValue?.leave_type_id === ""
        ? ""
        : filterValue?.leave_type_id?.value,
      filterValue?.from_date,
      filterValue?.to_date,
      filterValue?.created_at,
      filterValue?.created_by === "" ? "" : filterValue?.created_by?.value,
      filterValue?.is_posted,
      filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
      true
    );
  };

  const handleFilterSubmit = () => {
    if (
      filterValue?.from_date !== "" ||
      filterValue?.to_date !== "" ||
      filterValue?.created_at !== "" ||
      filterValue?.created_by !== "" ||
      filterValue?.is_posted !== "" ||
      filterValue?.leave_type_id !== "" ||
      filterValue?.user_id !== ""
    ) {
      setFilterApplied(true);
    }
    getTotalRecords(
      searchStr,
      true,
      1,
      pageSizeNo,
      sortData?.sort_by,
      filterValue?.leave_type_id === ""
        ? ""
        : filterValue?.leave_type_id?.value,
      filterValue?.from_date,
      filterValue?.to_date,
      filterValue?.created_at,
      filterValue?.created_by === "" ? "" : filterValue?.created_by?.value,
      filterValue?.is_posted,
      filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
      true
    );
    filterclose();
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getTotalRecords(
        e.target.value.toLowerCase(),
        true,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        filterValue?.leave_type_id === ""
          ? ""
          : filterValue?.leave_type_id?.value,
        filterValue?.from_date,
        filterValue?.to_date,
        filterValue?.created_at,
        filterValue?.created_by === "" ? "" : filterValue?.created_by?.value, 
        filterValue?.is_posted,       
        filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/manage-leave">Leave Master</Link>
            </li>
            <li>
              <Link to="/allocation-leave">Leave Rule</Link>
            </li>
            <li>
              <Link to="/allocation-leave">Leave Allocation</Link>
            </li>
            <li>
              <Link to="/my-leave">My Leaves</Link>
            </li>
            <li class="active">
              <Link to="/bulk-leave">Leave Bulk Upload</Link>
            </li>
            <li>
              <Link to="/leave-approval">For Approval Leaves</Link>{" "}
            </li>
            <li>
              <Link to="/leave-backdate">Back Date Entry Exception</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Bulk Upload Leave</h4>
                  {/* <Breadcrumb>
                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                        <Breadcrumb.Item active>My Leaves</Breadcrumb.Item>
                      </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong> First Name, Last Name, Leave Code...</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="searchbar"
                        placeholder="First Name, Last Name, Leave Code"
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              // closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img
                          src="images/export.png"
                          alt=""
                          class="mr-2"
                        />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() => {
                              getTotalRecordsExport(
                                searchStr,
                                true,
                                currentPage,
                                pageSizeNo,
                                sortData?.sort_by,
                                filterValue?.leave_type_id === ""
                                  ? ""
                                  : filterValue?.leave_type_id?.value,
                                filterValue?.from_date,
                                filterValue?.to_date,
                                filterValue?.created_at,
                                filterValue?.created_by === "" ? "" : filterValue?.created_by?.value,
                                filterValue?.is_posted,
                                filterValue?.user_id === "" ? "" : filterValue?.user_id?.value,
                                true
                              );
                            }}
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {/* {finalUrlValue?.A ? ( */}
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-danger" onClick={importuser}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Bulk Upload
                    </button>
                    {/* ) : (
                          ""
                        )} */}
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next Savior Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
                <div className="col-md-10 mt-2">
                  <b style={{ fontSize: "13px" }}>Leave Half: N = Full Day, F = First Half, S = Second Half</b>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th
                      onClick={(e) => {
                        handlerData("user__full_name");
                      }}
                      className={
                        sortData["user__full_name"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["user__full_name"]
                      }
                    >
                      User
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("leave_code");
                      }}
                      className={
                        sortData["leave_code"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["leave_code"]
                      }
                    >
                      Leave Code
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("leave_date");
                      }}
                      className={
                        sortData["leave_date"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["leave_date"]
                      }
                    >
                      Leave Date
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("leave_half");
                      }}
                      className={
                        sortData["leave_half"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["leave_half"]
                      }
                    >
                      Leave Half
                    </th>
                    <th>Is Posted</th>
                    <th
                      onClick={(e) => {
                        handlerData("svs_status");
                      }}
                      className={
                        sortData["svs_status"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["svs_status"]
                      }
                    >
                      Savior Status
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("posted_date");
                      }}
                      className={
                        sortData["posted_date"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["posted_date"]
                      }
                    >
                      Posted Date
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("created_at");
                      }}
                      className={
                        sortData["created_at"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["created_at"]
                      }
                    >
                      Created At
                    </th>
                    <th
                      onClick={(e) => {
                          handlerData("created_by__full_name");
                        }}
                        className={
                          sortData["created_by__full_name"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["created_by__full_name"]
                      }
                    >
                      Created By
                    </th>
                  </tr>
                  
                </thead>
                <tbody>
                  {records?.length ? (
                    records?.map((data, index) => (
                      <tr>
                        <td>
                          {data?.status === "H"
                            ? "Pending"
                            : data?.status === "R"
                              ? "Rejected"
                              : "Approved"}
                        </td>
                        <td>{data?.full_name}</td>
                        <td>{data?.leave_code}</td>
                        <td>{moment(data?.leave_date).format("DD-MM-YYYY")}</td>
                        <td>{data?.leave_half}</td>
                        <td>{data?.is_posted === true ? "YES" : "NO"}</td>
                        <td>{data?.svs_status}</td>
                        <td>{data?.posted_date}</td>
                        <td>
                          {moment(data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </td>
                        <td>{data?.created_by_name}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {records?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option
                        class=" main-pointer"
                        value={10}
                        label={10}
                      ></option>
                      <option
                        class=" main-pointer"
                        value={20}
                        label={20}
                      ></option>
                      <option
                        class=" main-pointer"
                        value={30}
                        label={30}
                      ></option>
                      <option
                        class="main-pointer"
                        value={100}
                        label={100}
                      ></option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Leave Type</label>
            <Select
              options={leaveList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  leave_type_id: e,
                }));
              }}
              value={filterValue?.leave_type_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>From Date</label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  from_date: e?.target?.value,
                }));
              }}
              value={filterValue?.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>To Date</label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  to_date: e?.target?.value,
                }));
              }}
              value={filterValue?.to_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created_at</label>
            <input
              type="date"
              class="form-control"
              name="created_at"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  created_at: e?.target?.value,
                }));
              }}
              value={filterValue?.created_at}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created By</label>
            <Select
              options={userDataList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  created_by: e,
                }));
              }}
              value={filterValue?.created_by}
            />
          </div>
          <div class="form-group innergroup">
            <label>Select User</label>
            <Select
              options={userDataList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  user_id: e,
                }));
              }}
              value={filterValue?.user_id}
            />
          </div>
          <div className="form-group innergroup">
          <label>Is Posted</label>
            <Select
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterValue((prev) => ({
                  ...prev,
                  is_posted: e?.value,
                }));
              }}
              value={
                filterValue?.is_posted !== ""
                  ? { value: filterValue.is_posted, label: filterValue.is_posted ? "Yes" : "No" }
                  : ""
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setCurrentPage(1);
              setFilterApplied(false);
              setFilterValue((prev) => ({
                ...prev,
                from_date: "",
                leave_type_id: "",
                to_date: "",
                created_at:"",
                created_by:"",
                is_posted:"",
                user_id: "",
              }));
              getTotalRecords(
                searchStr,
                true,
                1,
                pageSizeNo,
                sortData?.sort_by,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                true
              );
              filterclose();
            }}
          >
            CLEAR
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={handleFilterSubmit}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {importsetShow && (
        <AddBulkUpload
          importshow={importshow}
          importclose={importclose}
          onCallback={() => {
            getTotalRecords("", true, 1, 10, "id", "", "", "", "","","","", true);
          }}
        />
      )}
    </>
  );
}

export default BulkUploadLeave;
