import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

const getEmployee = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  dept,
  branchId,
  desigId,
  locationId
) => {
  return axios.get(
    `${AUTH_BASE_URL}/employee_directory?search=${search}&desigId=${desigId}&locationId=${locationId}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}${
      dept == undefined || dept == "" || dept == null
        ? ""
        : `&department_id=${dept}`
    }${
      branchId == undefined || branchId == "" || branchId == null
        ? ""
        : `&branch_id=${branchId}`
    }${
      desigId == undefined || desigId == "" || desigId == null
        ? ""
        : `&designation_id=${desigId}`
    }`
  );
};

const getSubPersonalArea = async () => {
  return axios.get(`${AUTH_BASE_URL}/employee_directory/sub_personal_area`);
};
const getSubPersonalAreaFilter = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/employee_directory/sub_personal_areafilter`
  );
};

const saveEmployee = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/employee_directory`, data);
};
const updateEmployee = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/employee_directory/${id}`, data);
};
const getAllDepartment = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/department?paginate=${paginate}`);
};

const getAllBranch = async () => {
  return axios.get(`${AUTH_BASE_URL}/employee_directory/business_unit_list`);
};
const getAllCountry = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/country?paginate=${paginate}`);
};

const deleteEmployee = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/employee_directory/${id}`);
};

const getAllDesignation = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/designation?paginate=${paginate}`);
};

const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/employee_directory/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
const getExportPrint = async (location_name) => {
  return axios({
    url: `${AUTH_BASE_URL}/employee_directory/location_export?location_name=${location_name}`,
    method: "GET",
    // responseType: "blob",
  });
};

const isDeptExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/employee_directory/exists`, isData);
};

const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/employee_directory/import_user`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};
const getDirectoryDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/employee_directory/directory_details`);
};

// Filter APIs

const LocationFilter = async () => {
  return axios.get(`${AUTH_BASE_URL}/employee_directory/location_filter`);
};

const DepartmentFilter = async (location_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/employee_directory/department_filter?${
      location_id == undefined || location_id == "" || location_id == null
        ? ""
        : `&location_id=${location_id}`
    }`
  );
};
const DesignationFilter = async (location_id, department_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/employee_directory/designation_filter?${
      location_id == undefined || location_id == "" || location_id == null
        ? ""
        : `&location_id=${location_id}`
    }${
      department_id == undefined || department_id == "" || department_id == null
        ? ""
        : `&department_id=${department_id}`
    }`
  );
};

export {
  getEmployee,
  getSubPersonalArea,
  getSubPersonalAreaFilter,
  saveEmployee,
  updateEmployee,
  getAllDepartment,
  getAllBranch,
  getAllCountry,
  deleteEmployee,
  getAllDesignation,
  getExportValue,
  isDeptExists,
  uploadImages,
  importExel,
  getDirectoryDetails,
  LocationFilter,
  DepartmentFilter,
  DesignationFilter,
  getExportPrint,
};
