import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import CreateNewSurvey from "./CreateNewSurvey";
import SelectTemp from "./SelectTemp";
import QuestionsForm from "./QuestionsForm";
import PublishSetting from "./PublishSetting";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as surveyService from "../../service/Survey";

function ViewSurvey() {
  const [tabStatus, setTabStatus] = useState("Addressess");
  const [tabType, setTabType] = useState("Internal");
  const { state } = useLocation();
  const { edit, flag, isViewing } = state;
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    if (flag === "true") {
      setTabStatus("Addressess");
    }
  }, [flag]);

  const saveSurvey = (values) => {
    surveyService
      .saveSurvey(values)
      .then((response) => {
        setResponseData(response?.data?.dataList);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec pb-0">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Add New Survey</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/home">Company</Breadcrumb.Item>
                <Breadcrumb.Item href="/survey">Survey</Breadcrumb.Item>
                <Breadcrumb.Item active>Add New</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div class="addusertabs">
          <Tabs
            activeKey={tabStatus}
            id="adduserinfo"
            onSelect={(e) => setTabStatus(e)}
          >
            {/* <Tab
              eventKey="Primary"
              title={
                <React.Fragment>
                  <span>1</span> Create New
                </React.Fragment>
              }
            >
              <CreateNewSurvey setTabStatus={setTabStatus} />
            </Tab> */}
            {/* <Tab
              eventKey="Personal"
              title={
                <React.Fragment>
                  <span>2</span> Select Templates
                </React.Fragment>
              }
            >
              <SelectTemp setTabStatus={setTabStatus} tabStatus={tabStatus} />
            </Tab> */}
            <Tab
              eventKey="Addressess"
              title={
                <React.Fragment>
                  <span>1</span> Add/Edit Questions
                </React.Fragment>
              }
            >
              <QuestionsForm
                setTabStatus={setTabStatus}
                edit={edit}
                saveSurvey={saveSurvey}
                isViewing = {isViewing}
                setTabType={setTabType}  
                tabType={tabType}   
              />
            </Tab>
            <Tab
              eventKey="publish"
              title={
                <React.Fragment>
                  <span>2</span> Publish Setting
                </React.Fragment>
              }
            >
              <PublishSetting
                tabType={edit?.type}   
                setTabstatus={setTabStatus}
                responseData={responseData}
                tabStatus={tabStatus}
                edit={edit}
                isViewing={isViewing}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default ViewSurvey;
