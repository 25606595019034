import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
// import * as deptServices from "../../../service/department";
import { Formik } from "formik";
import {
  Tabs, Tab,
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Spinner,
} from "react-bootstrap";
import AddEmployee from "../roleRightMaster/EmployeeMaster/AddEmployee";
import * as EmpService from "../../service/employee";
import { uploadfile } from "../../service/Survey";

import * as HigherEduService from "../../service/educationService";
import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
  setCoursesData
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { injectStyle } from "react-toastify/dist/inject-style";
// import ViewEmployee from "./ViewEmployee";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { PdfIcon } from "./Icon";
import DataTable from "./DataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const HigherEduListing = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [requestList, setReqList] = useState([]);
  const [currentTab, setCurrentTab] = useState('pending');

  const [certificateData, setCertificateData] = useState([]);
  const [certificatePreviewList, setCertificatePreviewList] = useState([]);
  const [deleteCertificateList, setDeleteCertificateList] = useState([]);
  const [uploadCertificateModalFlag, setUploadCertificateModalFlag] = useState({ flag: false, id: null, data: [] });
  const [paginatedData, setPaginatedData] = useState(null);
  const [courseOption, setCourseOption] = useState([]);
  const [universityOption, setUniversityOption] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);

  const [filterdata, setfilterData] = useState({
    applied_from: new Date(),
    applied_to: new Date(),
    emp_name: "",
    course_name: "",
    instt_name: "",
    status: ""
  });
  

  const currentUser = useSelector(selectUserData)

  const statusOption = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Re-Submit', value: 'RESUBMIT' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Approved', value: 'ACCEPTED' }
  ];

  let abortListingData = null;

  const amountFormatter = (number, currency) => new Intl.NumberFormat(currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: currency }).format(number)

  const pendingActionColumn = (value, row) => {
    return (<div key={row.id}>
      <button
        className="btn btn-blanktd text-primary"
        type="button"
        onClick={() => {
          editApplication(row);
        }}
        disabled={row.is_resubmitted ? true : (row.status == 'PENDING' && row.is_any_approved ? true : false)}
      >
        <i className="far fa-edit" style={{ color: row.is_resubmitted ? 'grey' : (row.status == 'PENDING' && row.is_any_approved ? 'grey' : '#58a9fe') }}></i>
      </button>

      <button
        className="btn btn-dangerNew"
        onClick={() => deleteApplication(row?.id)}
        disabled={row.is_resubmitted ? true : (row.status == 'PENDING' && row.is_any_approved ? true : false)}
      >
        <i className="far fa-times-circle" style={{ color: row.is_resubmitted ? 'grey' : (row.status == 'PENDING' && row.is_any_approved ? 'grey' : '#ff000') }}></i>
      </button>
    </div>)
  }

  const nameFormatter = (name, id) => {
    const full_name = `${name} (${id})`;
    if(name){
      return <span style={{whiteSpace: full_name.length > 25 ? 'pre-wrap' : 'nowrap'}}>{full_name}</span>
    }
  }
  const pendingTableColumn = [
    {
      datafield: '',
      text: 'Action',
      formatter: pendingActionColumn
    },
    {
      datafield: 'status',
      text: 'Status',
      formatter: (value, row) => <span className="badge bad-status badge-warning">{row.is_resubmitted ? 'Re-Submitted' : (value == 'PENDING' ? 'Pending' : 'Re- Submit')}</span>
    },
    {
      datafield: 'id',
      text: 'Request ID',
      formatter: value => < Link to="/higher-edu-approval" state={{ from: value, isApproval: false }}> HEP{value} </Link>
    },
    {
      datafield: 'created_at',
      text: 'Date',
      formatter: value => moment.parseZone(value).format("DD-MM-YYYY")
    },
    {
      datafield: '',
      text: 'Name',
      formatter: (value, row) => nameFormatter(row?.user_full_name, row?.username)
    },
    {
      datafield: '',
      text: 'Location',
      formatter: (value, row) => nameFormatter(row?.personal_area_description, row?.personal_area_name)
    },
    {
      datafield: 'course',
      text: 'Course Name',
    },
    {
      datafield: 'university',
      text: 'Name of Institute'
    },
    {
      datafield: 'start_date',
      text: 'Start Date',
    },
    {
      datafield: 'end_date',
      text: 'End Date',
    },
    {
      datafield: 'course_duration',
      text: 'Duration'
    },
    {
      datafield: 'course_fee',
      text: 'Fees',
      formatter: (value, row) => amountFormatter(value, row.currency)
    },
    {
      datafield: 'justification',
      text: 'Justification'
    },
    {
      datafield: '',
      text: 'Created By',
      formatter: (value, row) => nameFormatter(row?.created_by_full_name, row?.created_by_username)
    },
    {
      datafield: '',
      text: 'Pending At',
      formatter: (value, row) => nameFormatter(row?.approve, row?.approve_username)
    },
  ];

  const rejectedTableColumn = [
    {
      datafield: 'status',
      text: 'Status',
      formatter: (value) => <span className="badge bad-status badge-danger">Rejected</span>
    },
    {
      datafield: 'id',
      text: 'Request ID',
      formatter: value => < Link to="/higher-edu-approval" state={{ from: value, isApproval: false }}> HEP{value} </Link>
    },
    {
      datafield: 'created_at',
      text: 'Date',
      formatter: value => moment.parseZone(value).format("DD-MM-YYYY")
    },
    {
      datafield: '',
      text: 'Name',
      formatter: (value, row) => nameFormatter(row?.user_full_name, row?.username)
    },
    {
      datafield: '',
      text: 'Location',
      formatter: (value, row) => nameFormatter(row?.personal_area_description, row?.personal_area_name)
    },
    {
      datafield: 'course',
      text: 'Course Name'
    },
    {
      datafield: 'university',
      text: 'Name of Institute'
    },
    {
      datafield: 'course_duration',
      text: 'Duration'
    },
    {
      datafield: 'course_fee',
      text: 'Fees',
      formatter: (value, row) => amountFormatter(value, row.currency)
    },
    {
      datafield: 'justification',
      text: 'Justification'
    },
    {
      datafield: '',
      text: 'Created By',
      formatter: (value, row) => nameFormatter(row?.created_by_full_name, row?.created_by_username)
    },
  ];


  const approvedActionColumn = (value, row) => {
    return (
      currentUser.username == row.username ? <button className="btn btn-primary-inner bpi-main f-14 py-1" style={{ cursor: 'pointer', padding: 7 }} onClick={() => {
        navigate("/higher-edu-claim-listing", {
          state: {
            claimData: row
          }
        })
      }}>
        <i className="far fa-check-circle"></i>&nbsp;
        Claim
      </button> : <></>
    )
  }
  const certificateColumn = (value, row) => {
    return (
      currentUser.username == row.username ? <button className="btn btn-primary-inner bpi-main f-14 py-1" style={{ cursor: 'pointer', padding: 7 }} onClick={() => setUploadCertificateModalFlag({ flag: true, id: row?.id, data: row.certificate_list })}>
        <i className={row.certificate_list.length == 0 ? "fa fa-upload" : "fas fa-eye"}></i>&nbsp;
        {row.certificate_list.length == 0 ? "Upload" : "View"}
      </button> : <></>
    )
  }
  const approvedTableColumn = [
    {
      datafield: '',
      text: 'Action',
      formatter: approvedActionColumn
    },
    {
      datafield: '',
      text: 'Certificate',
      formatter: certificateColumn
    },
    {
      datafield: 'status',
      text: 'Status',
      formatter: (value) => <span className="badge bad-status badge-success">Approved</span>
    },
    {
      datafield: 'id',
      text: 'Request ID',
      formatter: value => < Link to="/higher-edu-approval" state={{ from: value, isApproval: false }}> HEP{value} </Link>
      // formatter: value => <a href={`/higher-edu-approval?${value}`}> HEP{value} </a>
    },
    {
      datafield: 'created_at',
      text: 'Date',
      formatter: value => moment.parseZone(value).format("DD-MM-YYYY")
    },
    {
      datafield: '',
      text: 'Name',
      formatter: (value, row) => nameFormatter(row?.user_full_name, row?.username)
    },
    {
      datafield: '',
      text: 'Location',
      formatter: (value, row) => nameFormatter(row?.personal_area_description, row?.personal_area_name)
    },
    {
      datafield: 'course',
      text: 'Course Name'
    },
    {
      datafield: 'university',
      text: 'Name of Institute'
    },
    {
      datafield: 'course_duration',
      text: 'Duration'
    },
    {
      datafield: 'course_fee',
      text: 'Fees',
      formatter: (value, row) => amountFormatter(value, row.currency)
    },
    {
      datafield: 'justification',
      text: 'Justification'
    },
    {
      datafield: '',
      text: 'Created By',
      formatter: (value, row) => nameFormatter(row?.created_by_full_name, row?.created_by_username)
    },
  ];

  const getMyListing = (
    pageNo,
    pageSizeNo,
    sort_by,
    search,
    paginate,
    status
    // dept,
    // branchId,
    // desigId,
    // locationId
    // // designationId,
    // // deepartmentId
  ) => {

    // dispatch(setDisplayLoader("Display"));
    if (abortListingData) abortListingData.abort();
    if (status) {
      status = status.toUpperCase()
    }

    dispatch(setDisplayLoader("Display"));
    setReqList([])
    let payload = `pageNo=${pageNo}&page_size=${pageSizeNo}&sort_by=${sort_by}&search=sort_by${search}&paginate=${paginate}`;
    if (status != 'PENDING') {
      payload += `&status=${status}`
    }

    abortListingData = new AbortController()
    HigherEduService.getMyListing(payload, abortListingData.signal)
      .then((response) => {
        abortListingData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result)
        setPaginatedData(response.data?.dataList.paginated_data)
        let courseList = [];
        let courseNameList = [];
        let universityList = [];
        let universityNameList = [];
        let employeeList = [];
        let employeeNameList = [];

        response.data?.dataList.result.forEach(obj => {
          if(!courseNameList.includes(obj.course)){
            courseList.push({label: obj.course, value: obj.course})
            courseNameList.push(obj.course)
          }
          if(!universityNameList.includes(obj.university)){
            universityList.push({label: obj.university, value: obj.university})
            universityNameList.push(obj.university)
          }
          if(!employeeNameList.includes(obj.username)){
            employeeList.push({label: `${obj.user_full_name} (${obj.username})`, value: obj.user_full_name})
            employeeNameList.push(obj.username)
          }
        })

        setCourseOption(courseList);
        setUniversityOption(universityList);
        setEmployeeOption(employeeList);

        // setUserReviewsList(response?.data?.dataList?.result)
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setempdesgid(desigId);
        // setdepartment_id1(dept);
        // setEmpList(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const filterMyListing = () => {
    dispatch(setDisplayLoader("Display"));
    const payload = `applied_from=${filterdata.applied_from}&applied_to=${filterdata.applied_to}&emp_name=${filterdata.emp_name}&course=${filterdata.course_name}&university=${filterdata.instt_name}&status=${filterdata.status}`;
    abortListingData = new AbortController()
    HigherEduService.getMyListing(payload, abortListingData.signal)
      .then((response) => {
        abortListingData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result)
        setPaginatedData(response.data?.dataList.paginated_data)
        filterclose();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }


  const editApplication = (rdata) => {
    navigate("/higher-edu-application", {
      state: {
        editData: rdata
      }
    })
  };

  const deleteApplication = (app_reqid) => {
    dispatch(setDisplayLoader("Display"));

    HigherEduService.deleteApplicationRequest(app_reqid).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      toast.success(`Deleted successfully`)
      getMyListing(
        1,
        300,
        sortData.sort_by,
        "",
        true,
        "pending"
      )

    }).catch((error) => {
      dispatch(setDisplayLoader("Display"));
      toast.error(error?.response?.data?.message)
    })
  };

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    }
  }

  const getSearchApplicationRequests = (
    pageNo,
    pageSizeNo,
    sort_by,
    search,
    paginate,
    startdate,
    enddate
    // dept,
    // branchId,
    // desigId,
    // locationId
    // // designationId,
    // // deepartmentId
  ) => {
    dispatch(setDisplayLoader("Display"));
    let payload = `pageNo=${pageNo}&page_size=${pageSizeNo}&search=${search}&paginate=${paginate}`;
    if (currentTab != 'pending') {
      payload += `&status=${currentTab.toUpperCase()}`
    }
    abortListingData = new AbortController();
    HigherEduService.getMyListing(payload, abortListingData.signal)
      .then((response) => {
        abortListingData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result)
        setPaginatedData(response.data?.dataList.paginated_data)
        // setUserReviewsList(response?.data?.dataList?.result)
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setempdesgid(desigId);
        // setdepartment_id1(dept);
        // setEmpList(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSearchApplicationRequestsWithDebounce = debounce(getSearchApplicationRequests, 500);

  const handleSearch = (e) => {
    getSearchApplicationRequestsWithDebounce(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      e.target.value,
      true,
      "",
      ""
    )
    setSearchStr(e.target.value);
    // if (e.target.value.length > 2 || e.target.value.length === 0) {
    // }
  };


  const handleSelect = (status) => {
    setCurrentTab(status)
    getMyListing(
      1,
      300,
      sortData.sort_by,
      "",
      true,
      status
    )
  }

  const onUploadCertificateModalClose = () => {
    setCertificateData([])
    setCertificatePreviewList([])
    setUploadCertificateModalFlag({ flag: false, id: null, data: [] })
  }

  const removeDocumentByIdx = (idx, id) => {
    let newCertificatePreviewList = certificatePreviewList.filter((data, idxx) => {
      return idxx != idx
    })

    if (id > 0) {
      setDeleteCertificateList([...deleteCertificateList, id])
    } else {
      let newCertificateList = certificateData.filter((data, idxx) => {
        return idxx != idx
      })
      setCertificateData(newCertificateList)
    }
    setCertificatePreviewList(newCertificatePreviewList)
  }

  const onSelectFile = (e) => {
    let fileList = e.target?.files;
    let urls = [...certificatePreviewList]
    let tmp_filesToUpload = [...certificateData]

    for (let i = 0; i < fileList.length; i++) {
      const file = e.target.files[i];

      let myUrl = URL.createObjectURL(file);
      let urlSplitArr = myUrl.split('/');
      let typeArr = file.type.split('/');
      urls.push({ url: myUrl, key: urlSplitArr[urlSplitArr.length - 1], type: typeArr[1] });

      const fileData = new FormData();
      fileData.append("uploaded_file", file);
      tmp_filesToUpload.push(fileData)
    }

    setCertificatePreviewList(urls)
    setCertificateData(tmp_filesToUpload)
  };

  const uploadCertificate = async () => {
    dispatch(setDisplayLoader("Display"));

    let payload = {
      attachments: [],
      del_attachments: deleteCertificateList
    }

    for (let i = 0; i < certificateData.length; i++) {
      await uploadfile(certificateData[i]).then((response) => {
        payload.attachments.push(response?.data)
      }).catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        toast.error(`${error} `)
        return
      })
    }

    HigherEduService.uploadCertificate(payload, uploadCertificateModalFlag.id)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        toast.success("Certificate Uploaded.")
        onUploadCertificateModalClose()
        getMyListing(1, 300, '-id', "", true, 'approved')
      })
      .catch((error) => {
        toast.error("Some Error occured.")
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }

  useEffect(() => {
    getMyListing(
      1,
      300,
      sortData.sort_by,
      "",
      true,
      currentTab
    )

    // toast.success("loaded")

    // getEmployee(
    //   currentPage,
    //   sortData.sort_by,
    //   "",
    //   true,
    //   departmentDataFilter.department_id,
    //   departmentDataFilter.branch_id,
    //   departmentDataFilter.designation_id,
    //   ""
    // );

    // getDirectoryDetails("flg");
    return () => {
      if (abortListingData) abortListingData.abort();
    }
  }, [pageSizeNo]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">My Listing</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group table-search">
                      <span className="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <button
                        className="btn btn-primary-inner"
                        onClick={() => {
                          navigate("/higher-edu-application");
                        }}
                      >
                        <img
                          src="images/upload.png"
                          alt=""
                          className="mr-3"
                        />
                        Create Request
                      </button>
                      <button
                        onClick={filteropen}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs onSelect={handleSelect} defaultActiveKey="pending" id="listingtabs">
                  <Tab
                    eventKey="pending"
                    title={
                      <>
                        Pending
                        {/* <span className="numberpurchse">{totalRecord}</span> */}
                      </>
                    }
                  >
                    <DataTable
                      column={pendingTableColumn}
                      list={requestList}
                      option={{
                        pagination: {
                          show: true,
                          currentPaginatedData: paginatedData,
                          dataCount: 300,
                          getNextData: getMyListing,
                          payload: [sortData.sort_by, "", true, currentTab],
                        }
                      }}
                    />
                  </Tab>

                  <Tab
                    eventKey="rejected"
                    title={
                      <>
                        Rejected
                        {/* <span className="numberpurchse">{totalRecord}</span> */}
                      </>
                    }
                  >
                    <DataTable
                      column={rejectedTableColumn}
                      list={requestList}
                      option={{
                        pagination: {
                          show: true,
                          currentPaginatedData: paginatedData,
                          dataCount: 300,
                          getNextData: getMyListing,
                          payload: [sortData.sort_by, "", true, currentTab],
                        }
                      }}
                    />
                  </Tab>

                  <Tab
                    eventKey="approved"
                    title={
                      <>
                        Approved
                        {/* <span className="numberpurchse">{totalRecord}</span> */}
                      </>
                    }
                  >
                    <DataTable
                      column={approvedTableColumn}
                      list={requestList}
                      option={{
                        pagination: {
                          show: true,
                          currentPaginatedData: paginatedData,
                          dataCount: 300,
                          getNextData: getMyListing,
                          payload: [sortData.sort_by, "", true, currentTab],
                        }
                      }}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="form-group innergroup d-flex flex-column">
            <label>
              Applied from <span className="text-danger">*</span>
            </label>
            <DatePicker
              selected={filterdata.applied_from !== "" ? new Date(filterdata.applied_from) : new Date()}
              onChange={(date) => setfilterData({
                ...filterdata,
                applied_from: date
              })}
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>


          <div className="form-group innergroup d-flex flex-column">
            <label>
              Applied to <span className="text-danger">*</span>
            </label>
            <DatePicker
              selected={filterdata.applied_to !== "" ? new Date(filterdata.applied_to) : new Date()}
              onChange={(date) => setfilterData({
                ...filterdata,
                applied_to: date
              })}
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Name of Employee <span className="text-danger">*</span>
            </label>


            <Select
              options={employeeOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setfilterData({
                ...filterdata,
                emp_name: e.value
              })}
            // value={organisation_User.company}
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Course Name <span className="text-danger">*</span>
            </label>
            <Select
              options={courseOption}
              className="basic-multi-select"
              classNamePrefix="select"
              ononChange={(e) => setfilterData({
                ...filterdata,
                course_name: e.value
              })}
            // value={organisation_User?.user_id}
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Name of Institute <span className="text-danger">*</span>
            </label>
            <Select
              options={universityOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setfilterData({
                ...filterdata,
                instt_name: e.value
              })}
            />
          </div>


          <div className="form-group innergroup">
            <label>
              Status <span className="text-danger">*</span>
            </label>
            <Select
              options={statusOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => setfilterData({
                ...filterdata,
                status: e.value
              })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger"
            //  onClick={() => clearfilter()}
            onClick={filterclose}
          >
            Cancel
          </button>
          <button className="btn btn-primary-inner bpi-main"
          onClick={filterMyListing}
          >Apply</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={uploadCertificateModalFlag.flag}
        onHide={onUploadCertificateModalClose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title> Upload Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i className="fas fa-file-upload fa-2x"></i>
                  <p>Upload the certificate of your course</p>
                  <button className="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <input
                    type="file"
                    accept=".pdf, .jpeg, .jpg, .png"
                    multiple
                    className="form-control"
                    onChange={(e) => onSelectFile(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-11">
              <h6 className="text-theme border-bottom"><strong>New Document</strong></h6>
              {certificatePreviewList.length ?
                (<div className="d-flex flex-wrap col-md-12" style={{ width: '100%' }}>
                  {certificatePreviewList.map((idata, idx) => (
                    <DocumentPreview docData={idata} removeDocumentByIdx={removeDocumentByIdx} index={idx} key={idata.key} />
                  ))}
                </div>)
                : <div className="d-flex justify-content-center" style={{ width: '100%' }}><span style={{ color: '#bfbfbf' }} className="small"><em>No file data</em></span></div>}
            </div>
            <br /> <br />
            {uploadCertificateModalFlag?.data?.length ?
              <div className="col-md-11">
                <h6 className="text-theme border-bottom"><strong>Uploaded Documents</strong></h6>
                <div className="d-flex flex-wrap col-md-12" style={{ width: '100%' }}>
                  {uploadCertificateModalFlag?.data?.map((idata, idx) => (
                    <DocumentPreview docData={idata} removeDocumentByIdx={removeDocumentByIdx} index={idx} key={idata.id} />
                  ))}
                </div>
              </div> : <></>}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button className="btn btn-outline-danger mr-3"
            onClick={onUploadCertificateModalClose}
          >
            Cancel
          </button>
          <button className="btn btn-primary-inner bpi-main"
            onClick={() => uploadCertificate()}
          >Upload</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function DocumentPreview(props) {
  const urlStrArr = props.docData.url.split('/');
  const type = urlStrArr[urlStrArr.length - 1].split('.')[1];
  return (
    <div className="col-md-3 mb-4">
      <a href={props.docData.url} target="_blank" rel="noreferrer">
        {props.docData?.type ? props.docData.type == 'pdf' ?
          <div style={{ aspectRatio: '16/9' }} >
            <PdfIcon width={120} height={85} />
          </div> : <img src={props.docData.url} style={{ aspectRatio: '16/9' }} /> :
          type == 'pdf' ?
            <div style={{ aspectRatio: '16/9' }} >
              <PdfIcon width={120} height={85} />
            </div> : <img src={props.docData.url} style={{ aspectRatio: '16/9' }} />
        }
      </a>
      {props.docData?.type ? <div
        onClick={() => props.removeDocumentByIdx(props.index, props.docData?.id ? props.docData?.id : 0)}
        style={{
          position: 'absolute',
          top: 3,
          right: 20,
          borderRadius: '50%',
          backgroundColor: '#ff0000',
          width: 18,
          height: 18
        }}
        className="d-flex align-items-center justify-content-center p-1"
      >
        <i className="fas fa-times" style={{ color: '#fff' }}></i>
      </div> : <a
        href="#"
        download
        rel="noopener noreferrer"
        style={{
          position: 'absolute',
          top: 3,
          right: 20,
          borderRadius: '50%',
          backgroundColor: '#0195d4',
          width: 25,
          height: 25
        }}
        className="d-flex align-items-center justify-content-center p-1"
      >
        <i className="fas fa-download" style={{ color: '#fff' }}></i>
      </a>}
    </div>
  );
}

export default HigherEduListing;