import React, { useState } from "react";
import { ProgressBar, Pagination, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import TravelLinks from "./TravelLinks";
import AddExpenseTravel from "./AddExpenseTravel";
import BulkUploadOtherExpense from "./BulkUploadOtherExpense";
import GlDetails from "./GlDetails";

function AddOtherExpense(props) {
  const [openTab, setOpenTab] = useState("onduty")
  // const [viewLeaveData, setDataAgain] = useState(null);
  const {
    showLeaveForm,
    onViewCloseEdit,
    viewLeaveData,
    isEdit,
    handleClose,
    onAddCallBack,
  } = props;

  const backDatafromUpload=()=>{
    
  }


  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <TravelLinks />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Add Other Expense</h4>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs defaultActiveKey="onduty" activeKey={openTab} id="approvaltabs" key={openTab} onSelect={(e) => setOpenTab(e)}>
                <Tab eventKey="onduty" title={<>Other Expense</>}>
                  <AddExpenseTravel
                    onAddCallBack={onAddCallBack}
                    isEdit={isEdit}
                    handleClose={handleClose}
                    showLeaveForm={showLeaveForm}
                    onViewCloseEdit={onViewCloseEdit}
                    viewLeaveData={viewLeaveData}
                  />
                </Tab>
                <Tab eventKey="Domestic" title={<>Bulk Upload</>}>
                  <BulkUploadOtherExpense openOnDuty={() => {setOpenTab("onduty"); backDatafromUpload()}}/>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddOtherExpense;
