
import axios from "./../../utils/axios";
 import { AUTH_BASE_URL } from "../../constants/URL";



export const getInvofrReqList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/get_invoice_listing${query}`);
};

export const UpdateInvofrRequest = async (query,body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/update_freight_request${query}`,body);
}
export const postInvofrRequest = async (query,body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/create_freight_request${query}`,body);
}

export const getInvoiceReqListID = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/get_data_by_request_id${query}`);
};


export const getInvoiceList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_transport_invoice/get_invoice_listing${query}`);


}



export const getInvofrList = async (query) => {
    return axios.get(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/get_request_data${query}`);

};




export const getInvoiceapprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/freight_request_approval_history${query}`)
}


export const postApproverAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/lr_gr_trns_invoice_freight/get_freight_approver_action`,body);
}

export const postInvoiceRequestAction = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/market-player/action-market-player`,body);
}


export const getExportValue = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/lr_gr_transport_invoice/export_to_excel?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };

