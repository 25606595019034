import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddHotelChild = (props) => {
  const {
    hotelDataValue,
    isEdit,
    hotelList,
    deleteHotelData,
    isDelete,
    getHotelList,
    setIsDelete,
    getTotalAmt,
    dataNew,
    setCheckActiveTab,
  } = props;
  const { state } = useLocation();
  const [doubleClick, setDoubleClick] = useState("false");
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [cityDataNew, setCityDataNew] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [taxRate, setTaxRate] = useState();
  const [stayAtValue, setStayAtValue] = useState();
  const [vendorValue, setVendorValue] = useState();
  const [taxRateValue, setTaxRateValue] = useState();
  const [codeFlagVendor, setCodeFlagVendor] = useState(false);
  const [codeFlagTaxRate, setCodeFlagTaxRate] = useState(false);
  const [codeFlagHotelState, setCodeFlagHotelState] = useState(false);
  const [codeFlagpOne, setFlagpOne] = useState(false);
  const [codeFlagTwo, setFlagTwo] = useState(false);
  const [stayAtFlag, setStayAtFlag] = useState(false);
  const [hotelListData, setHotelListData] = useState();
  const [hotelTaxCalculation, setHotelTaxCalculation] = useState();
  const [allEmployee, setAllEmployee] = useState([]);
  const [allEmployeeTwo, setAllEmployeeTwo] = useState([]);
  const [chkFilterOpt, setFilterDataOpt] = useState([]);
  const [fcmData, setFcmData] = useState([]);
  const [calDateValue, setCalDateValue] = useState();
  const [entPrice, setEntPrice] = useState();
  const [entPriceNew, setEntPriceNew] = useState("");
  const [billPrice, setBillPrice] = useState("");
  const [hotelListDataTwo, setHotelListDataTwo] = useState([]);

  //   const diffInMs = (fromDate, tillDate) =>{
  //     let calDate =  new Date(
  //       state?.travel_detail_data[state?.travel_detail_data?.length - 1]?.to_date
  //     ) - new Date(state?.travel_detail_data[0]?.from_date);
  //     console.log(calDate, "diffInMs1")
  //   }
  // const diffInDays = diffInMs / (1000 * 60 * 60 * 24) + 1;

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);
    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setCalDateValue(diffDays);
      return diffDays + 1;
    } else {
      return 0;
    }
  };
  var date = new Date();
  date.setDate(date.getDate() - 1);

  // var chkoutdate =
  //   state?.travel_detail_data[state?.travel_detail_data?.length - 1]?.to_date;
  // var ns = chkoutdate.setDate(chkoutdate.getDate() + 1);
  // console.log(ns, "chkoutdate");
  const [expenseData, setExpenseData] = useState([
    {
      check_in_date: state?.travel_detail_data[0]?.from_date,
      check_out_date:
        state?.travel_detail_data[state?.travel_detail_data?.length - 1]
          ?.to_date,
      city_id: undefined,
      person_one_id: undefined,
      person_two_id: undefined,
      days: calDateValue,
      stay_at: undefined,
      hotel_state_id: undefined,
      hotel_name: undefined,
      hotel_vendor_category: undefined,
      hotel_gst_no: undefined,
      invoice_no: undefined,
      invoice_date: undefined,
      sharing: false,
      taxable_amount: undefined,
      tax_rate_id: undefined,
      tax_amount: undefined,
      other_charges: undefined,
      tax_bill_amount: undefined,
      claim_amount: undefined,
      total_claim_amount: undefined,
      bill_amount: undefined,
      reason_for_exception: undefined,
    },
  ]);
  const [formValidation, setFormValidation] = useState({
    // check_in_date: "",
    // check_out_date: "",
    city_id: "",
    // days: "",
    stay_at: "",
    hotel_state_id: "",
    hotel_name: "",
    hotel_vendor_category: "",
    // hotel_gst_no: undefined,
    invoice_no: "",
    invoice_date: "",
    sharing: "",
    taxable_amount: "",
    tax_rate_id: "",
    other_charges: "",
    tax_bill_amount: "",
    claim_amount: "",
    bill_amount: "",
  });
  const taxAmountFinalFun = (tax, i) => {
    let amt = (tax * expenseData[i]?.taxable_amount) / 100;
    let expD = expenseData;
    expD[i].tax_amount = amt;
    setExpenseData(expD);
  };
  const totalBillAmountFinal = (e, i) => {
    let clAmt =
      Number(expenseData[i]?.taxable_amount) +
      Number(expenseData[i]?.tax_amount);
    let expD = expenseData;
    expD[i].tax_bill_amount = clAmt;
    setExpenseData(expD);
  };

  // const taxAmountFinal =

  //   (hotelTaxCalculation * expenseData[0]?.taxable_amount) / 100;

  const billAmountFinal =
    Number(expenseData[0]?.taxable_amount) +
    Number(expenseData[0]?.tax_rate_id);
  const totalClaimAmountFinal = (e, i) => {
    let clAmt = Number(e) + Number(expenseData[i]?.tax_amount);
    let total = expenseData[i]?.taxable_amount;
    let expD = expenseData;
    if (Number(total) < Number(e)) {
      expD[i].claim_amount = total;
    } else {
      expD[i].claim_amount = e;
    }
    expD[i].total_claim_amount = clAmt;
    setExpenseData(expD);
  };
  const option = [
    { value: "Hotel", label: "Hotel" },
    { value: "Guest House", label: "Guest House" },
    { value: "Family/Friends", label: "Family/Friends" },
  ];
  const vendorOption = [
    { value: "", label: "Select" },
    { value: "Registered", label: "Registered" },
    { value: "Non-Registered", label: "Non-Registered" },
  ];
  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getFcmList = async () => {
    await TravelListingAPI.getFcmList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setFcmData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEmployee = (paginate) => {
    TravelListingAPI.getEmployee(paginate)
      .then((response) => {
        let data = [];
        const filteredUsers = response?.data?.dataList?.result.filter(
          (user) => user.is_active
        );
        filteredUsers.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
        setAllEmployeeTwo(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getTaxRate = async () => {
    await TravelListingAPI.getTaxRate()
      .then((response) => {
        let res = response?.data?.dataList?.tax_rate_data?.map((obj) => {
          return {
            value: obj.id,
            name: `${obj?.name}`,
            label: `${obj?.name}`,
          };
        });
        setTaxRate(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkDaysCount = () => {};
  const getStateList = async (paginate) => {
    await TravelListingAPI.getStateList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.gst_state_code} -${obj?.name} `,
          };
        });
        setStateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pushStayAt = (e, i) => {
    changeHandler(e.value, "hotel_state_id", i);
  };
  const pushPersonOne = (e, i) => {
    changeHandler(e.value, "person_one_id", i);
    getEntitelmentPrice(e.value, state?.travel_detail_data[0]?.to_city_id);
  };
  const pushPersonTwo = (e, i) => {
    changeHandler(e.value, "person_two_id", i);
    getEntitelmentPriceNew(e.value, state?.travel_detail_data[0]?.to_city_id);
  };
  const pushState = (e, i) => {
    changeHandler(e.value, "stay_at", i);
    setVendorValue("");
  };

  const pushVendor = (e, i) => {
    changeHandler(e.value, "hotel_vendor_category", i);
  };
  const pushTaxRate = (e, i) => {
    setHotelTaxCalculation(e.label);
    changeHandler(e.value, "tax_rate_id", i);
    // changeHandler("", "hotel_gst_no", i);
  };

  const getHotelListTwo = async (travel_id) => {
    await TravelListingAPI.getHotelListTwo(travel_id)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        setHotelListDataTwo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const itemData = expenseData.map((value) => ({
    check_in_date: state?.travel_detail_data[0]?.from_date,
    check_out_date: state?.travel_detail_data[1]?.to_date,
    city_id: state?.travel_detail_data[0]?.from_city_id,
    person_one_id: value?.person_one_id,
    person_one_name: value?.person_one_name,
    person_two_id: value?.person_two_id,
    person_two_name: value?.person_two_name,
    days: calDateValue,
    stay_at: value.stay_at,
    hotel_state_id: value.hotel_state_id,
    hotel_name: value.hotel_name,
    hotel_vendor_category: value.hotel_vendor_category,
    hotel_gst_no: value.hotel_gst_no,
    invoice_no: value.invoice_no,
    invoice_date: value.invoice_date,
    sharing: value.sharing,
    taxable_amount: value.tax_amount,
    tax_rate_id: value?.tax_rate_id,
    tax_amount: value.tax_amount,
    other_charges: value.other_charges,
    tax_bill_amount: value.tax_bill_amount,
    claim_amount: value.claim_amount,
    total_claim_amount: value.total_claim_amount,
    // reason_for_exception: value?.reason_for_exception,
  }));

  function expenseHotelEdit() {
    const arrayGST = [];
    if (hotelDataValue) {
      for (let i = 0; i < hotelDataValue.length; i++) {
        arrayGST.push({
          id: hotelDataValue[i].id,
          appr_amount: hotelDataValue[i].appr_amount,
          appr_bill_amount: hotelDataValue[i].appr_bill_amount,
          appr_claim_amount: hotelDataValue[i].appr_claim_amount,
          appr_other_charges: hotelDataValue[i].appr_other_charges,
          appr_tax_amount: hotelDataValue[i].appr_tax_amount,
          appr_tax_rate: hotelDataValue[i].appr_tax_rate,
          check_in_date: hotelDataValue[i].check_in_date,
          check_out_date: hotelDataValue[i].check_out_date,
          city_id: hotelDataValue[i].city_id,
          city_name: hotelDataValue[i].city_name,
          claim_amount: hotelDataValue[i].claim_amount,
          comments: hotelDataValue[i].comments,
          days: hotelDataValue[i].days,
          expense_by: hotelDataValue[i].expense_by,
          stay_at: hotelDataValue[i].stay_at,
          hotel_state_id: hotelDataValue[i].hotel_state_id,
          hotel_state_name: hotelDataValue[i].hotel_state_name,
          person_one_name: hotelDataValue[i].person_one_name,
          person_two_name: hotelDataValue[i].person_two_name,
          hotel_name: hotelDataValue[i].hotel_name,
          hotel_vendor_category: hotelDataValue[i].hotel_vendor_category,
          hotel_gst_no: hotelDataValue[i].hotel_gst_no,
          invoice_no: hotelDataValue[i].invoice_no,
          invoice_date: hotelDataValue[i].invoice_date,
          sharing: hotelDataValue[i].sharing,
          taxable_amount: hotelDataValue[i].taxable_amount,
          tax_rate_id: hotelDataValue[i].tax_rate_id,
          tax_amount: hotelDataValue[i].tax_amount,
          other_charges: hotelDataValue[i].other_charges,
          person_one_id: hotelDataValue[i].person_one_id,
          person_two_id: hotelDataValue[i].person_two_id,
          tax_bill_amount: hotelDataValue[i].tax_bill_amount,
          tax_name: hotelDataValue[i].tax_name,
          tax_rate_value: hotelDataValue[i].tax_rate_value,
          total_bill_amount: hotelDataValue[i]?.total_bill_amount,
          user_id: hotelDataValue[i].user_id,
          user_name: hotelDataValue[i].user_name,
          total_claim_amount: hotelDataValue[i].total_claim_amount,
          // reason_for_exception: hotelDataValue[i].reason_for_exception,
        });
      }
      setExpenseData(arrayGST);
    }
  }
  const saveHotelExpenseData = (data) => {
    if (doubleClick === "false") {
      setDoubleClick("true");
      let tempDate = { ...expenseData };
      if (tempDate?.check_in_date)
        tempDate.check_in_date = moment(tempDate.check_in_date).format(
          "YYYY-MM-DD"
        );
      if (tempDate?.check_out_date)
        tempDate.check_out_date = moment(tempDate.check_out_date).format(
          "YYYY-MM-DD"
        );
      if (tempDate?.invoice_date)
        tempDate.invoice_date = moment(tempDate.invoice_date).format(
          "YYYY-MM-DD"
        );
      let dataSave = {
        travel_id: state?.id,
        //   attachment_path: uploadDataIm[0],
        hotel_data: tempDate,
      };
      TravelListingAPI.saveHotelExpenseData(dataSave)
        .then((response) => {
          // condition = "false";
          if (response?.data.code == 200) {
            // resetState();
            // navigate("/expense-listing");
            // setCheckActiveTab("Summary");
            getHotelList(state?.id);
            setDoubleClick("false");
            setCheckActiveTab();
            toast.success("Hotel Expenses Added Successfully");
            // getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateHotelExpense = async (id, data) => {
    if (doubleClick === "false") {
      setDoubleClick("true");
      let dataSave = {
        travel_id: state?.id,
        // attachment_path: uploadDataIm,
        hotel_data: data,
      };
      await TravelListingAPI.updateHotelExpense(id, dataSave)
        .then((response) => {
          if (response.data.code == 200) {
            setCheckActiveTab();
            setDoubleClick("false");
            // toast.success("Hotel Expense Updated Successfully");
            getHotelList(state?.id);
            setIsDelete(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const setValue = (id, value) => {
    return {
      value: id,
      label: value,
    };
  };

  const changeHandler = (value, name, index) => {
    let fd = JSON.parse(JSON.stringify(expenseData));
    if (name === "stay_at") {
      if (value == "Guest House") {
        fd[index].hotel_vendor_category = "";
      }

      if (value == "Family/Friends") {
        fd[index].taxable_amount = "1000";
        fd[index].tax_amount = "0";
        fd[index].tax_bill_amount = "1000";
        fd[index].hotel_vendor_category = "Non-Registered";
      }
    }
    fd[index][name] = value;
    setExpenseData(fd);
  };

  const handleValidateForm = (expenseData) => {
    if (isEdit == "false") {
      if (handleValidate()) return false;

      saveHotelExpenseData(expenseData);
    } else {
      if (handleValidate()) return false;
      hotelExpense();
    }
  };
  // {console.log(finalData,'  ')}

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    expenseData?.map((expenseData, i) => {
      if (!expenseData.stay_at) {
        isError = true;
        formerr.stay_at = "*Stay At is required ";
        setFormValidation(formerr);
      }
      // if (!expenseData.hotel_name) {
      //   formerr.hotel_name = "Hotel Name is required";
      //   setFormValidation(formerr);
      // }
      if (
        !expenseData.hotel_vendor_category &&
        expenseData.stay_at != "Guest House"
      ) {
        isError = true;
        formerr.hotel_vendor_category = "Vendor Category is required";
        setFormValidation(formerr);
      }
      if (!expenseData.hotel_state_id) {
        isError = true;
        formerr.hotel_state_id = "*Stay at State is Required";
      }
      //   expenseData.stay_at != "Guest House" &&
      //   expenseData.stay_at != "Family/Friends") ||
      // expenseData.hotel_vendor_category != "Not Registered"
      // if (
      //   !expenseData.hotel_gst_no &&
      //   expenseData.stay_at != "Guest House" &&
      //   expenseData.hotel_vendor_category == "Non-Registered"
      // ) {
      //   isError = true;
      //   formerr.hotel_gst_no = "*GST No is required";
      //   setFormValidation(formerr);
      // }
      if (
        !expenseData.invoice_no &&
        expenseData.stay_at != "Guest House" &&
        expenseData.stay_at != "Family/Friends"
      ) {
        isError = true;
        formerr.invoice_no = "*Invoice No is required";
        setFormValidation(formerr);
      }
      // if (!expenseData.invoice_date && expenseData.stay_at !== "Guest House") {
      //   isError = true;
      //   formerr.invoice_date = "*Invoice Date is required";
      //   setFormValidation(formerr);
      // }

      if (!expenseData.taxable_amount && expenseData.stay_at != "Guest House") {
        isError = true;
        formerr.taxable_amount = "*Bill Amount is required";
        setFormValidation(formerr);
      }
      // if (
      //   !expenseData.tax_rate_id &&
      //   expenseData.stay_at != "Guest House" &&
      //   expenseData.hotel_vendor_category == "Non-Registered"
      // ) {
      //   isError = true;
      //   formerr.tax_rate_id = "*Tax Rate is required";
      //   setFormValidation(formerr);
      // }
      // if (!expenseData.tax_amount) {
      //   isError = true;
      //   formerr.tax_amount = "*Tax Amount is required";
      //   setFormValidation(formerr);
      // }
      if (!expenseData.claim_amount && expenseData.stay_at != "Guest House") {
        isError = true;
        formerr.claim_amount = "*Claim Amount is required";
        setFormValidation(formerr);
      }
      if (!expenseData.taxable_amount && expenseData.stay_at != "Guest House") {
        isError = true;
        formerr.taxable_amount = "*Bill Amount is required";
        setFormValidation(formerr);
      }
      // if (!expenseData.total_claim_amount) {
      //   isError = true;
      //   formerr.total_claim_amount = "*Total Claim Amount is required";
      //   setFormValidation(formerr);
      // }
    });
    return isError;
  };

  const validateSSCFeature = () => {
    let errorExist = false;
    expenseData.map((item) => {
      if (
        // item?.bill_number === "" ||
        // item?.bill_date === "" ||
        // item?.taxable_amount?.trim().length === 0 ||
        // item?.remarks === ""
        item &&
        item?.stay_at === undefined &&
        // item?.hotel_state_id === 0 ||
        item &&
        item?.hotel_vendor_category === undefined
        // item?.invoice_no === "" ||
        // item?.invoice_date === "" ||
        // item?.taxable_amount?.trim().length === 0 ||
        // item?.tax_rate_id === 0 ||
        // item?.other_charges === 0 ||
        // item?.tax_bill_amount?.trim().length === 0 ||
        // item?.claim_amount?.trim().length === 0 ||
        // item?.taxable_amount?.trim().length === 0
      ) {
        errorExist = true;
      }
      return item;
    });
    return errorExist;
  };

  const addMoreExpense = (ele, i) => {
    // setAlertForAmount(false);
    if (validateSSCFeature()) {
    } else {
      if (expenseData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(expenseData));
        const ele = {};
        ele.check_in_date = "";
        ele.check_out_date = "";
        ele.city_id = 0;
        ele.days = 0;
        ele.stay_at = "";
        ele.hotel_state_id = 0;
        ele.hotel_name = "";
        ele.hotel_vendor_category = "";
        ele.hotel_gst_no = "";
        ele.invoice_no = "";
        ele.invoice_date = "";
        ele.sharing = "";
        ele.taxable_amount = 0;
        ele.tax_rate_id = 0;
        ele.tax_amount = 0;
        ele.other_charges = 0;
        ele.tax_bill_amount = 0;
        ele.claim_amount = 0;
        ele.taxable_amount = 0;
        ele.total_claim_amount = 0;
        ele.taxable_amount = 0;
        copyValue.push(ele);
        setExpenseData(copyValue);
      } else {
        toast.error("max");
      }
    }
  };

  const removeHotelExpenses = () => {
    const minVal = hotelList;
    const index = minVal?.findIndex((v) => v.id == deleteHotelData.id);
    if (index > -1) {
      minVal.splice(index, 1);
    }
    updateHotelExpense(state.id, minVal);
  };
  const removeHotel = (idx) => {
    const newExpDetails = expenseData.filter((item, i) => i !== idx);
    setExpenseData(newExpDetails);
  };
  const removeHotelData = () => {
    confirmAlert({
      title: "Delete Hotel Listing",
      message: `Are you sure to delete Hotel Listing?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => removeHotelExpenses(),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const setCheckedValue = (e, i) => {
    let value = JSON.parse(JSON.stringify(expenseData));
    value[i].sharing = value[i]?.sharing === true ? false : true;
    setExpenseData(value);
  };
  const dateChkOut =
    state?.travel_detail_data[state?.travel_detail_data?.length - 1]?.to_date;
  let test = moment(dateChkOut).add(1, "day").format("YYYY-MM-DD");
  // const ddd = dateChkOut + 1;

  // date.setDate(date.getDate() + 1);
  const getEntitelmentPrice = (user_id, city_id) => {
    TravelListingAPI.getEntitelmentPrice(user_id, city_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setEntPrice(data?.hotel);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getEntitelmentPriceNew = (user_id, city_id) => {
    TravelListingAPI.getEntitelmentPrice(user_id, city_id)
      .then((response) => {
        let data = response?.data?.dataList;

        setEntPriceNew(data?.hotel);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getEntitelmentPriceBill = (user_id, city_id) => {
    TravelListingAPI.getEntitelmentPrice(user_id, city_id)
      .then((response) => {
        let data = response?.data?.dataList;

        setBillPrice(data?.hotel);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getCityList(false);
    getStateList(false);
    getTaxRate();
    getEmployee(false);
    getFcmList();
    getHotelListTwo(state?.id);
  }, []);

  useEffect(() => {
    let data1 = [];
    hotelList?.map((data, i) => {
      data1.push(data);
    });
    setHotelListData();
  }, [hotelList]);

  function hotelExpense() {
    const minVal = hotelList;
    const index = minVal?.findIndex((v) => v.id == hotelDataValue.id);
    minVal.splice(index, 1, expenseData[0]);
    updateHotelExpense(state.id, minVal);
  }

  useEffect(() => {
    if (isEdit == true) {
      expenseHotelEdit();
    }
  }, [isEdit]);
  useEffect(() => {
    getEntitelmentPriceBill(
      userData?.id,
      state?.travel_detail_data[state?.travel_detail_data?.length - 1]
        ?.to_city_id
    );
  }, []);
  useEffect(() => {
    if (isDelete === true) {
      removeHotelData();
    }
  }, [isDelete]);
  useEffect(() => {
    if (dataNew[0]?.personal_area_name != undefined) {
      if (
        dataNew[0]?.personal_area_name === "1003" ||
        dataNew[0]?.personal_area_name === "1007" ||
        dataNew[0]?.personal_area_name === "1017"
      ) {
        let data = option?.filter((ele) => {
          if (ele?.label !== "Guest House") {
            return true;
          }
        });
        setFilterDataOpt(data);
      } else {
        setFilterDataOpt(option);
      }
    }
  }, [dataNew]);

  useEffect(() => {
    calculateDateDifference(
      expenseData[0]?.check_in_date,
      expenseData[0]?.check_out_date
    );
  }, [expenseData]);

  //   useEffect(() => {
  //     if (hotelListDataTwo) {
  //       let total_data = Array.from(hotelListDataTwo).map((obj) => {
  //         return {
  //           check_in_date: state?.travel_detail_data[0]?.from_date,
  //           check_out_date:
  //             state?.travel_detail_data[state?.travel_detail_data?.length - 1]
  //               ?.to_date,
  //           city_id: undefined,
  //           person_one_id: undefined,
  //           person_two_id: undefined,
  //           days: calDateValue,
  //           stay_at: undefined,
  //           hotel_state_id: undefined,
  //           hotel_name: undefined,
  //           hotel_vendor_category: undefined,
  //           hotel_gst_no: undefined,
  //           invoice_no: undefined,
  //           invoice_date: undefined,
  //           sharing: false,
  //           taxable_amount: undefined,
  //           tax_rate_id: undefined,
  //           tax_amount: undefined,
  //           other_charges: undefined,
  //           tax_bill_amount: undefined,
  //           claim_amount: undefined,
  //           total_claim_amount: undefined,
  //           bill_amount: undefined,
  //           reason_for_exception: undefined,
  //         };
  //       });
  //       setExpenseData(total_data);
  //       console.log(total_data, hotelListDataTwo, "total_data");
  //     }
  //   }, [state, hotelListDataTwo]);

  return (
    <div>
      <div class="px-4 pb-2">
        {expenseData.map((expData, i) => (
          <>
            <div class="bg-light px-3 pt-3 mb-3">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup d-flex flex-column">
                    <label>
                      Check in Date<span class="text-danger">*</span>
                    </label>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="check_in_date"
                      // disabled
                      // value={state?.travel_detail_data[0]?.from_date}
                      min={state?.travel_detail_data[0]?.from_date}
                      max={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      }
                      value={
                        isEdit
                          ? expData?.check_in_date
                          : expenseData[i]?.check_in_date
                      }
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_in_date", i);
                      }}
                    /> */}
                    <DatePicker
                      selected={
                        isEdit
                          ? expData?.check_in_date &&
                            expData?.check_in_date !== ""
                            ? new Date(expData?.check_in_date)
                            : ""
                          : expenseData[i]?.check_in_date !== ""
                          ? new Date(expenseData[i]?.check_in_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "check_in_date", i);
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      City/Entitlement<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="city_id"
                      disabled
                      value={`${state?.travel_detail_data[0]?.from_city_name} - ${state?.travel_detail_data[0]?.from_city_category} - ${state?.travel_detail_data[0]?.from_city_hotel} `}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup d-flex flex-column">
                    <label>
                      Checkout Date<span class="text-danger">*</span>
                    </label>
                    {/* <input
                      type="date"
                      class="form-control"
                      name="check_out_date"
                      max={test}
                      min={state?.travel_detail_data[0]?.to_date}
                      value={
                        isEdit
                          ? expData?.check_out_date
                          : expenseData[i]?.check_out_date
                      }
                      onChange={(e) => {
                        changeHandler(e.target.value, "check_out_date", i);
                      }}
                    /> */}
                    <DatePicker
                      selected={
                        isEdit
                          ? expData?.check_out_date &&
                            expData?.check_out_date !== ""
                            ? new Date(expData?.check_out_date)
                            : ""
                          : expenseData[i]?.check_out_date !== ""
                          ? new Date(expenseData[i]?.check_out_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "check_out_date", i);
                      }}
                      minDate={
                        state?.travel_detail_data[0]?.to_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.to_date)
                      }
                      maxDate={new Date(test)}
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Days<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      name="days"
                      placeholder="Enter Days"
                      // max="9"
                      disabled
                      // value={diffInDays}
                      value={calDateValue}
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Stay At<span class="text-danger">*</span>
                    </label>

                    <Select
                      options={chkFilterOpt}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="stay_at"
                      id={"stay_at" + i}
                      onChange={(e) => {
                        pushState(e, i);
                        setStayAtValue(e);
                        setStayAtFlag(true);
                        setFormValidation({
                          ...formValidation,
                          stay_at: "",
                        });
                      }}
                      value={
                        isEdit == true && stayAtFlag == false
                          ? setValue(expData?.stay_at, expData?.stay_at)
                          : expenseData[i]?.stayAtValue
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.stay_at}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Hotel Name<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="hotel_name"
                      maxLength={30}
                      disabled={expenseData[i]?.stay_at === "Family/Friends"}
                      placeholder="Enter Hotel Name"
                      onChange={(e) => {
                        changeHandler(e.target.value, "hotel_name", i);
                      }}
                      value={
                        isEdit
                          ? expData?.hotel_name
                          : expenseData[i]?.hotel_name
                      }
                      // value={expenseData[i]?.hotel_name}
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.hotel_name}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Vendor Catagory<span class="text-danger">*</span>
                    </label>

                    <Select
                      options={vendorOption}
                      className="basic-multi-select"
                      isDisabled={expenseData[i]?.stay_at === "Guest House"}
                      classNamePrefix="select"
                      name="hotel_vendor_category"
                      id={"hotel_vendor_category" + i}
                      onChange={(e) => {
                        pushVendor(e, i);
                        setVendorValue(e);
                        setCodeFlagVendor(true);
                        setFormValidation({
                          ...formValidation,
                          hotel_vendor_category: "",
                        });
                      }}
                      value={
                        isEdit == true && codeFlagVendor == false
                          ? setValue(
                              expData.hotel_vendor_category,
                              expData.hotel_vendor_category
                            )
                          : vendorOption?.filter((obj) => {
                              if (
                                obj.value ===
                                expenseData[i]?.hotel_vendor_category
                              ) {
                                return true;
                              }
                            })[0]
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.hotel_vendor_category}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Stay At - State<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={stateData}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="hotel_state_id"
                      id={"hotel_state_id" + i}
                      onChange={(e) => {
                        pushStayAt(e, i);
                        setStateValue(e);
                        setCodeFlagHotelState(true);
                        setFormValidation({
                          ...formValidation,
                          hotel_state_id: "",
                        });
                      }}
                      value={
                        isEdit == true && codeFlagHotelState == false
                          ? setValue(
                              expData?.hotel_state_id,
                              expData?.hotel_state_name
                            )
                          : stateData !== undefined &&
                            stateData?.filter((obj) => {
                              if (
                                obj?.value === expenseData[i].hotel_state_id
                              ) {
                                return obj;
                              }
                            })[0]
                        // stateValue
                      }
                    />

                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.hotel_state_id}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Hotel GST No</label>
                    <input
                      type="text"
                      class="form-control"
                      name="hotel_gst_no"
                      maxLength={15}
                      disabled={
                        // expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends" ||
                        expenseData[i]?.hotel_vendor_category ===
                          "Non-Registered"
                      }
                      placeholder="Enter Hotel GST No"
                      onChange={(e) => {
                        changeHandler(e.target.value.trim(), "hotel_gst_no", i);
                        setFormValidation({
                          ...formValidation,
                          hotel_gst_no: "",
                        });
                      }}
                      value={
                        isEdit
                          ? expData?.hotel_gst_no
                          : expenseData[i]?.hotel_gst_no
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.hotel_gst_no}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Invoice No<span class="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      maxLength={16}
                      class="form-control"
                      name="invoice_no"
                      disabled={expenseData[i]?.stay_at === "Family/Friends"}
                      placeholder="Enter Invoice GST No"
                      onChange={(e) => {
                        changeHandler(e.target.value.trim(), "invoice_no", i);
                        setFormValidation({
                          ...formValidation,
                          invoice_no: "",
                        });
                      }}
                      value={
                        isEdit
                          ? expData?.invoice_no
                          : expenseData[i]?.invoice_no
                      }
                      // value={expenseData[i]?.invoice_no}
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.invoice_no}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup d-flex flex-column">
                    <label>
                      Invoice Date<span class="text-danger">*</span>
                    </label>
                    {/* <input
                      type="date"
                      maxLength={40}
                      class="form-control"
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                      }
                      name="invoice_date"
                      onChange={(e) => {
                        changeHandler(e.target.value.trim(), "invoice_date", i);
                        setFormValidation({
                          ...formValidation,
                          invoice_date: "",
                        });
                      }}
                      min={state?.travel_detail_data[0]?.from_date}
                      max={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date
                      }
                      value={
                        isEdit
                          ? expData?.invoice_date
                          : expenseData[i]?.invoice_date
                      }
                    /> */}
                    <DatePicker
                      selected={
                        isEdit
                          ? expData?.invoice_date &&
                            expData?.invoice_date !== ""
                            ? new Date(expData?.invoice_date)
                            : ""
                          : expenseData[i]?.invoice_date !== ""
                          ? new Date(expenseData[i]?.invoice_date)
                          : ""
                      }
                      onChange={(date) => {
                        changeHandler(date, "invoice_date", i);
                        setFormValidation({
                          ...formValidation,
                          invoice_date: "",
                        });
                      }}
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                      }
                      minDate={
                        state?.travel_detail_data[0]?.from_date !== "" &&
                        new Date(state?.travel_detail_data[0]?.from_date)
                      }
                      maxDate={
                        state?.travel_detail_data[
                          state?.travel_detail_data?.length - 1
                        ]?.to_date !== "" &&
                        new Date(
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        )
                      }
                      placeholderText="DD-MM-YYYY"
                      className="form-control"
                      dateFormat="dd-MM-yyyy"
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.invoice_date}
                    </div>
                  </div>
                </div>
                {expenseData[i].stay_at == "Hotel" ? (
                  <div class="form-group innergroup ml-5">
                    <div>
                      <label className="logCheck d-inline-block mr-4 mt-4">
                        Sharing
                        <input
                          type="checkbox"
                          name="sharing"
                          checked={expenseData[i]?.sharing === true}
                          onChange={(e) => {
                            setCheckedValue(e, i);
                          }}
                          value={expenseData[i]?.sharing}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {expenseData[i].sharing === true ? (
                  <div className="col-md-12">
                    <p style={{ fontSize: "12px" }}>
                      Note: Only one person should claim total bill amount in
                      case of room sharing
                    </p>{" "}
                  </div>
                ) : (
                  ""
                )}
                {expenseData[i].sharing === true ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          2nd Person
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          name="person_one_id"
                          id="person_one_id"
                          options={allEmployee?.filter((obj) => {
                            if (obj.value !== userData?.id) {
                              return true;
                            }
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushPersonOne(e, i);
                            // setStateValue(e);
                            // setCodeFlagHotelState(true);
                            setFlagpOne(true);
                            setFormValidation({
                              ...formValidation,
                              person_one_id: "",
                            });
                          }}
                          value={
                            isEdit == true && codeFlagpOne == false
                              ? setValue(
                                  expData.person_one_id,
                                  expData.person_one_name
                                )
                              : allEmployee !== undefined &&
                                allEmployee?.filter((obj) => {
                                  if (
                                    obj?.value === expenseData[i].person_one_id
                                  ) {
                                    return obj;
                                  }
                                })[0]
                          }
                        />
                        <p
                          style={{
                            color: "black",
                            padding: "10px",
                            border: "5px",
                          }}
                        >
                          {entPrice}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          3rd Person
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          name="person_two_id"
                          id="person_two_id"
                          options={allEmployeeTwo?.filter((obj) => {
                            if (
                              obj.value !== userData?.id &&
                              obj.value !== expenseData[i].person_one_id
                            ) {
                              return true;
                            }
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushPersonTwo(e, i);
                            // setStateValue(e);
                            // setCodeFlagHotelState(true);
                            setFlagTwo(true);
                            setFormValidation({
                              ...formValidation,
                              person_two_id: "",
                            });
                          }}
                          value={
                            isEdit == true && codeFlagTwo == false
                              ? setValue(
                                  expData.person_two_id,
                                  expData.person_two_name
                                )
                              : allEmployeeTwo !== undefined &&
                                allEmployeeTwo?.filter((obj) => {
                                  if (
                                    obj?.value === expenseData[i].person_two_id
                                  ) {
                                    return obj;
                                  }
                                })[0]
                          }
                        />
                        <p
                          style={{
                            color: "black",
                            padding: "10px",
                            border: "5px",
                          }}
                        >
                          {entPriceNew}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Bill Amount (Excluding GST){" "}
                      <span class="text-danger">*</span>
                    </label>

                    <input
                      type="number"
                      maxLength={14}
                      class="form-control"
                      disabled={expenseData[i]?.stay_at === "Family/Friends"}
                      name="taxable_amount"
                      placeholder={billPrice}
                      onChange={(e) => {
                        changeHandler(
                          e.target.value.trim(),
                          "taxable_amount",
                          i
                        );
                        setFormValidation({
                          ...formValidation,
                          taxable_amount: "",
                        });
                      }}
                      // value={expenseData[i]?.taxable_amount}
                      value={
                        isEdit
                          ? expData?.taxable_amount
                          : expenseData[i]?.taxable_amount
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.taxable_amount}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Tax Rate (%)<span class="text-danger">*</span>
                    </label>

                    <Select
                      options={taxRate}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.hotel_vendor_category ===
                          "Non-Registered"
                      }
                      name="tax_rate_id"
                      id={"tax_rate_id" + i}
                      onChange={(e) => {
                        pushTaxRate(e, i);
                        setTaxRateValue(e);
                        setCodeFlagTaxRate(true);
                        setFormValidation({
                          ...formValidation,
                          tax_rate_id: "",
                        });
                        taxAmountFinalFun(e.label, i);
                        totalBillAmountFinal(e.label, i);
                      }}
                      // value={
                      //   expenseData && codeFlagTaxRate == false
                      //     ? setValue(
                      //         expenseData[i].tax_rate_id
                      //         // ,
                      //         // expenseData[i].gl_id_name
                      //       )
                      //     : expenseData[i]?.taxRateValue
                      // }
                      value={
                        isEdit == true && codeFlagTaxRate == false
                          ? setValue(
                              expData.tax_rate_id,
                              expData.tax_rate_value
                            )
                          : taxRate !== undefined &&
                            taxRate?.filter((obj) => {
                              if (obj?.value === expenseData[i].tax_rate_id) {
                                return obj;
                              }
                            })[0]
                        //  taxRateValue
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.tax_rate_id}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Tax Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      maxLength={40}
                      class="form-control"
                      name="tax_amount"
                      disabled
                      value={expenseData[i]?.tax_amount}
                      // value={
                      //   isEdit
                      //     ? expenseData?.tax_amount
                      //     : expenseData[i]?.tax_amount
                      // }
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Total Bill Amount<span class="text-danger">*</span>
                    </label>

                    <input
                      type="number"
                      maxLength={20}
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.hotel_vendor_category ===
                          "Non-Registered"
                      }
                      class="form-control"
                      name="tax_bill_amount"
                      // placeholder="Enter Total Bill Amount"
                      onChange={(e) => {
                        changeHandler(
                          e.target.value.trim(),
                          "tax_bill_amount",
                          i
                        );

                        setFormValidation({
                          ...formValidation,
                          tax_bill_amount: "",
                        });
                      }}
                      value={
                        isEdit
                          ? expData?.tax_bill_amount
                          : expenseData[i]?.tax_bill_amount
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.tax_bill_amount}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Claim Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      // max={expenseData[i]?.taxable_amount}
                      maxLength={40}
                      class="form-control"
                      disabled={
                        expenseData[i]?.stay_at === "Guest House" ||
                        expenseData[i]?.stay_at === "Family/Friends"
                      }
                      name="claim_amount"
                      placeholder="Enter Total Claim Amount"
                      onChange={(e) => {
                        changeHandler(e.target.value.trim(), "claim_amount", i);
                        totalClaimAmountFinal(e.target.value, i);
                        setFormValidation({
                          ...formValidation,
                          claim_amount: "",
                        });
                      }}
                      // value={expenseData[i]?.claim_amount}
                      value={
                        isEdit
                          ? expData?.claim_amount
                          : expenseData[i]?.claim_amount
                      }
                    />
                    <div className="small text-danger">
                      {i == Number(expenseData.length - 1) &&
                        formValidation.claim_amount}
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Total Claimed Amount
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      disabled
                      maxLength={20}
                      name="total_claim_amount"
                      placeholder="Enter Total Claimed Amount"
                      onChange={(e) => {
                        setExpenseData({
                          ...expenseData,
                          total_claim_amount: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          total_claim_amount: "",
                        });
                      }}
                      value={expenseData[i]?.total_claim_amount}
                      // value={
                      //   isEdit
                      //     ? expenseData?.total_claim_amount
                      //     : expenseData[i]?.total_claim_amount
                      // }
                      // value={
                      //   isEdit
                      //     ? expenseData?.total_claim_amount
                      //     : totalClaimAmountFinal
                      // }
                    />
                  </div>
                </div>
              </div>
            </div>
            {expenseData[i]?.claim_amount > billPrice ? (
              <div class="col-md-4">
                <div class="form-group innergroup">
                  <label>
                    Reason For Exception
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    maxLength={40}
                    name="reason_for_exception"
                    placeholder="Enter Total Claimed Amount"
                    onChange={(e) => {
                      changeHandler(e.target.value, "reason_for_exception", i);
                      // setExpenseData({
                      //   ...expenseData,
                      //   reason_for_exception: e.target.value,
                      // });
                      // setFormValidation({
                      //   ...formValidation,
                      //   reason_for_exception: "",
                      // });
                    }}
                    value={expenseData[i]?.reason_for_exception}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div class="col-md-11 text-sm-right">
              {/* <button
                class="btn btnblanktd text-primary"
                onClick={() => {
                  addMoreExpense(expenseData);
                  handleValidate();
                }}
              >
                <i class="far fa-plus-square mr-2"></i> Add More
              </button> */}
              {/* {expenseData?.length > 1 && ( */}
              <button
                style={{ marginLeft: "10px" }}
                class="btn btnblankth text-danger p-0"
                onClick={() => {
                  // removeHotel(i);
                  removeHotel(i);
                  setFormValidation({
                    check_in_date: "",
                    check_out_date: "",
                    city_id: "",
                    days: "",
                    stay_at: "",
                    hotel_state_id: "",
                    hotel_name: "",
                    hotel_vendor_category: "",
                    hotel_gst_no: "",
                    invoice_no: "",
                    invoice_date: "",
                    sharing: "",
                    taxable_amount: "",
                    tax_rate_id: "",
                    other_charges: "",
                    tax_bill_amount: "",
                    claim_amount: "",
                    taxable_amount: "",
                  });
                }}
              >
                <i class="far fa-trash-alt"></i>
              </button>
              {/* )} */}
            </div>

            <div class="mb-3">
              <div class="row">
                <div class="col-md-11 mt-3"></div>
              </div>
            </div>
          </>
        ))}
        <div class="row">
          <div class="col-md-12">
            <div class="border-top my-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHotelChild;
