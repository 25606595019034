import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { setAuditLog, selectAudit } from "../redux/piDataStore";

const AuditLog = {
  addAuditLog: (dispatch, name) => {
    let auditDate = new Date();
    let data = { FormName: name, actionValue: "Add", date: auditDate };
    dispatch(setAuditLog(data));
  },
  deleteAuditLog: (dispatch, name) => {
    let auditDate = new Date();
    let data = { FormName: name, actionValue: "Delete", date: auditDate };
    dispatch(setAuditLog(data));
  },
  viewAuditLog: (dispatch, name) => {
    let auditDate = new Date();
    let data = { FormName: name, actionValue: "View", date: auditDate };
    dispatch(setAuditLog(data));
  },
  updateAuditLog: (dispatch, name) => {
    let auditDate = new Date();
    let data = { FormName: name, actionValue: "Update", date: auditDate };
    dispatch(setAuditLog(data));
  },
};

export default AuditLog;
