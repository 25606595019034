import React, { useState, useEffect } from "react";
import * as TravelListingAPI from "../../../service/travelService";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisplayLoader,
  selectVehicleAssignData,
  selectUserData,
} from "../../redux/piDataStore";
import * as DomesticService from "../../../service/domestic";
import AlertBox from "../../CommonComp/AlertBox";
import SuggestionsList from "../TravelCommon/SuggestionList";
import { injectStyle } from "react-toastify/dist/inject-style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (typeof window !== "undefined") {
  injectStyle();
}

const AddInternational = (props) => {
  const navigate = useNavigate();

  const {
    setCallGrid,
    setChangeValueData,
    interData,
    setChangeFunc,
    changeFunc,
    allData,
    edit,
    setEditInterData,
    editInterData,
    setFormView,
    setTimeCheck,
    formView,
    onAddCallBack,
    onClose,
    migrate,
    update,
    AddFunction,
    setValidLastData,
    userBackDate,
    indexValue,
    dataNew,
    managerEditing,
    dynamicUserData,
  } = props;

  const userData = useSelector(selectUserData);
  const vehicleAssignValues = useSelector(selectVehicleAssignData);
  const [citiesRecord, setCitiesRecord] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [stayNeededValue, setStayNeededValue] = useState(false);
  const [purpose, setPurpose] = useState([]);
  const [purposeData, setPurposeData] = useState([]);
  const [mode, setMode] = useState([]);
  const [modeData, setModeData] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [reasonData, setReasonData] = useState([]);
  const [continents, setContinents] = useState([]);
  const [continentsData, setContinentData] = useState([]);
  const [continentsDataReach, setContinentDataReach] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesCon, setCitiesCon] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [addIsExist, setAddIsExist] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState(false);
  const [dateCalcData, setDateCalcData] = useState();
  const [validDateJoin, setValidDateJoin] = useState(false);
  const [validTimeZone, setValidTimeZone] = useState(false);
  const [isLeaveExistValue, setIsLeaveExistValue] = useState(false);

  const [countrys, setCountrys] = useState([]);
  const [countryConData, setCountryConData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [countryDataReach, setCountryDataReach] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [errorTime, setErrorTime] = useState("");
  const [hqCondition, setHqCondition] = useState(false);

  const [taxi, setTaxi] = useState(false);
  const [air, setAir] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [leaveCondition, setLeaveCondition] = useState(false);
  const [validJoinDate, setValidJoinDate] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [isLeaveExist, setIsLeaveExist] = useState();
  const [timeSetValue, setTimeSetValue] = useState([]);
  const [fromTimeDated, setFromTimeDated] = useState();
  const [toTimeDated, setToTimeDated] = useState();
  const [minBackDateValue, setMinBackDateValue] = useState("");
  const [maxBackDateValue, setMaxBackDateValue] = useState("");
  const [backDatedError, setBackDatedError] = useState(false);
  const [colleagueSelectFlag, setColleagueSelectFlag] = useState(false);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [inputFieldExp, setInputFieldExp] = useState("false");
  const [interFormData, setInterFormData] = useState({
    from_city_id: "",
    to_city_id: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    purpose_of_travel_id: "",
    mode_id: "",
    hotel: "",
    reason_for_exception_id: "",
    additional_information: "",
    travel_class: "",
    from_continent_id: "",
    from_country_id: "",
    to_continent_id: "",
    to_country_id: "",
    from_city_input: "",
    to_city_input: "",
    stay_needed: "",
    colleague_user_id: null,
    colleague_user_name: "",
  });

  const [formValidation, setFormValidation] = useState({
    from_city_id: "",
    to_city_id: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    purpose_of_travel_id: "",
    additional_information: "",
    mode_id: "",
    hotel: "",
    reason_for_exception_id: "",
    travel_class: "",
    from_continent_id: "",
    from_country_id: "",
    to_continent_id: "",
    to_country_id: "",
    stay_needed: "",
    colleague_user_id: "",
  });

  const dispatch = useDispatch();

  const [timeOption, setTimeOption] = useState([]);
  const [minDateValue, setMinDateValue] = useState("");
  let date = new Date();
  let valueDate = moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    if (moment(new Date()).date() >= 23) {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
          "MM"
        )}-21`
      );
    } else if (
      `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
        "MM"
      )}-${moment(new Date()).format("DD")}` <= "2024-01-23"
    ) {
      setMinDateValue(
        `${moment(new Date()).subtract(1, "years").format("YYYY")}-${moment(
          new Date()
        )
          .subtract(1, "month")
          .format("MM")}-21`
      );
    } else {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date())
          .subtract(1, "month")
          .format("MM")}-21`
      );
    }
  }, []);

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setUserDetails(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const validateRecords = () => {
    let selectElement = document.getElementById("hotelselect");
    let selectedIndex = selectElement?.selectedIndex;
    let isError = false;
    const fromData = { ...formValidation };
    if (!inputValue) {
      isError = true;
      fromData.from_city_id = "*Departure City is required ";
      setFormValidation(fromData);
    }
    if (countryDataReach?.label == "India") {
      if (!inputValueNext) {
        isError = true;
        fromData.to_city_id = "*Reaching City is required";
        setFormValidation(fromData);
      }
    } else {
      if (!interFormData.to_city_input) {
        isError = true;
        fromData.to_city_id = "*Reaching City is required";
        setFormValidation(fromData);
      }
    }
    if (!interFormData.from_date) {
      isError = true;
      fromData.from_date = "Departed On is required";
      setFormValidation(fromData);
    }
    if (!interFormData.from_time) {
      isError = true;
      fromData.from_time = "*Departed Time is required";
      setFormValidation(fromData);
    }
    if (!interFormData.to_time) {
      isError = true;
      fromData.to_time = "*Reaching Time is required";
      setFormValidation(fromData);
    }
    if (!interFormData.to_date) {
      isError = true;
      fromData.to_date = "*Reaching On is required";
      setFormValidation(fromData);
    }
    if (
      userDetails[0]?.personal_area_desc === "Marketing" ||
      userDetails[0]?.personal_area_desc === "PIJIV Marketing"
    ) {
      if (!interFormData.additional_information) {
        isError = true;
        fromData.additional_information = "*Additional Information is required";
        setFormValidation(fromData);
      }
    }
    if (!leaveCondition) {
      if (!interFormData.purpose_of_travel_id) {
        isError = true;
        fromData.purpose_of_travel_id = "*Purpose of Travel is required";
        setFormValidation(fromData);
      }
    }

    if (leaveCondition === false && editInterData?.purpose !== "Leave") {
      if (!interFormData.mode_id) {
        isError = true;
        fromData.mode_id = "*Travel Mode is required";
        setFormValidation(fromData);
      }
    }
    if (colleagueSelectFlag) {
      if (employeeData.length === 0) {
        isError = true;
        fromData.colleague_user_id = "*Collegue Employee Code is required";
        setFormValidation(fromData);
      }
    }

    // if (
    //   leaveCondition == false &&
    //   !interFormData.stay_needed &&
    //   interFormData.from_country_id != 1 &&
    //   interFormData.to_country_id != 1
    // ) {
    //   if (selectedIndex == 0) {
    //     isError = true;
    //     fromData.stay_needed = "*Stay needed is required";
    //     setFormValidation(fromData);
    //   }
    // }
    if (air) {
      if (!interFormData.travel_class) {
        isError = true;
        fromData.travel_class = "*Travel Class is required";
        setFormValidation(fromData);
      }
    }

    if (taxi) {
      if (!interFormData.reason_for_exception_id) {
        isError = true;
        fromData.reason_for_exception_id = "*Reason for exception is required";
        setFormValidation(fromData);
      }
    }
    if (!interFormData.from_continent_id) {
      isError = true;
      fromData.from_continent_id = "*Departure Continent is required";
      setFormValidation(fromData);
    }
    if (!interFormData.from_country_id) {
      isError = true;
      fromData.from_country_id = "*Departure Country is required";
      setFormValidation(fromData);
    }
    if (!interFormData.to_continent_id) {
      isError = true;
      fromData.to_continent_id = "*Reaching Continent is required";
      setFormValidation(fromData);
    }
    if (!interFormData.to_country_id) {
      isError = true;
      fromData.to_country_id = "*Reaching Country is required";
      setFormValidation(fromData);
    }
    if (
      typeof interFormData.stay_needed !== "boolean" &&
      interFormData?.purpose != "Leave"
    ) {
      if (!interFormData.stay_needed) {
        isError = true;
        fromData.stay_needed = "*Stay Needed is required";
        setFormValidation(fromData);
      }
    }
    // else {
    //   if (Boolean(interFormData.stay_needed) == "") {
    //     isError = true;
    //     fromData.stay_needed = "*Stay Needed is required";
    //     setFormValidation(fromData);
    //   }
    // }

    if (error) {
      isError = true;
    }
    if (errorTime) {
      isError = true;
    }

    if (isExist) {
      isError = true;
    }
    if (addIsExist) {
      isError = true;
    }
    // if (
    //   !isLeaveExist &&
    //   isLeaveExist != undefined &&
    //   interFormData?.purpose != undefined &&
    //   interFormData?.purpose == "Leave"
    // ) {
    //   isError = true;
    // }
    if (
      isLeaveExist &&
      isLeaveExist != undefined &&
      interFormData?.purpose != undefined &&
      interFormData?.purpose != "Leave"
    ) {
      isError = true;
    }
    if (validJoinDate) {
      isError = true;
    }
    if (isLeaveExistValue) {
      isError = true;
    }
    return isError;
  };

  const handleFromDateChange = (date) => {
    // const { value } = event.target;
    setFromDate(date);
    validateDateOfJoin(date);
    validateDates(
      moment(date).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
    if (interData.length == 0) {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        date,
        fromTime,
        toDate,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        toDate,
        toTime
      );
    } else {
      isDataExists(
        date,
        toDate,
        fromTime,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_date,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_time
      );
    }

    isAddDataExists(date, toDate, fromTime, toTime);
    // if (leaveCondition) {
    isLeaveExists(date, toDate);
    // }
  };

  const handleToDateChange = (date) => {
    // const { value } = event.target;
    setToDate(date);
    validateDates(
      moment(fromDate).format("YYYY-MM-DD"),
      moment(date).format("YYYY-MM-DD")
    );

    if (interData.length == 0) {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        fromDate,
        fromTime,
        date,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        date,
        toTime
      );
    } else {
      isDataExists(
        fromDate,
        date,
        fromTime,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_date,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, date, fromTime, toTime);
    // if (leaveCondition) {
    isLeaveExists(fromDate, date);
    // }
  };

  const validateDates = (from, to) => {
    if (from && to && moment(from).isAfter(moment(to))) {
      setError("Departed On date should be before the Reaching On date.");
    } else {
      setError("");
    }
  };

  const isDataExists = (
    fromDate,
    toDate,
    fromTime,
    toTime,
    fromDateEnd,
    fromTimeEnd,
    toDateEnd,
    toTimeEnd
  ) => {
    let addTime = ":00";
    let data = {};
    let totime = toTime?.split(":").length < 3 ? toTime + addTime : toTime;
    let travelId = allData !== undefined ? allData?.id : "";
    let fromValuetime =
      fromTime?.split(":").length < 3 ? fromTime + addTime : fromTime;
    let fromEndValuetime =
      fromTimeEnd?.split(":").length < 3 ? fromTimeEnd + addTime : fromTimeEnd;
    let toEndValuetime =
      toTimeEnd?.split(":").length < 3 ? toTimeEnd + addTime : toTimeEnd;
    let fromEndDatevalue = fromDateEnd == undefined ? "" : fromDateEnd;
    let toEndDatevalue = toDateEnd == undefined ? "" : toDateEnd;
    if (editInterData == null) {
      data["user_id"] =
        managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
      data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
      data["to_date"] = moment(toDate).format("YYYY-MM-DD");
      data["to_time"] = totime;
      data["from_time"] = fromValuetime;
      data["trip_start_date"] = moment(fromEndDatevalue).format("YYYY-MM-DD");
      data["trip_start_time"] = fromEndValuetime;
      data["trip_end_date"] = moment(toEndDatevalue).format("YYYY-MM-DD");
      data["trip_end_time"] = toEndValuetime;
    } else {
      data["id"] = editInterData ? editInterData.id : 0;
      data["user_id"] =
        managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
      data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
      data["to_date"] = moment(toDate).format("YYYY-MM-DD");
      data["to_time"] = totime;
      data["from_time"] = fromValuetime;
      data["trip_start_date"] = moment(fromEndDatevalue).format("YYYY-MM-DD");
      data["trip_start_time"] = fromEndValuetime;
      data["trip_end_date"] = moment(toEndDatevalue).format("YYYY-MM-DD");
      data["trip_end_time"] = toEndValuetime;
    }
    // if (interData?.length == 0) {
    //   data["from_time"] = fromValuetime;
    // } else {
    //   data["from_time"] = fromTime;
    // }
    DomesticService.isDataExists(travelId, data)
      .then((res) => {
        setIsExist(res?.data?.dataList?.is_exist);
      })
      .catch((err) => console.log("Error=>", err));
  };

  const isAddDataExists = (fromDate, toDate, fromTime, toTime) => {
    let addTime = ":00";
    let data = {};
    let totime = toTime.split(":").length < 3 ? toTime + addTime : toTime;
    let fromValuetime =
      fromTime.split(":").length < 3 ? fromTime + addTime : fromTime;
    data["user_id"] =
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
    data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
    data["to_date"] = moment(toDate).format("YYYY-MM-DD");
    data["from_time"] = fromValuetime;
    data["to_time"] = totime;
    data["id"] = editInterData?.id;

    if (fromDate !== "" && toDate !== "" && fromTime !== "" && toTime !== "") {
      DomesticService.isAddDataExists(data)
        .then((res) => {
          setAddIsExist(res?.data?.dataList?.is_exist);
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const isLeaveExists = (fromDate, toDate, flag) => {
    let data = {};
    data["user_id"] =
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id;
    data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
    data["to_date"] = moment(toDate).format("YYYY-MM-DD");
    if (fromDate !== "" && toDate !== "") {
      DomesticService.isLeaveExists(data)
        .then((res) => {
          setIsLeaveExist(res?.data?.dataList?.is_exist);
          if (res?.data?.dataList?.is_exist == true && flag == "tag") {
            if (migrate == undefined) {
              migrate && edit ? editUpdate() : addMoreTravelInfo();
            }
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const handleFromTimeChange = (event) => {
    setFromTimeDated(event);
    const { value } = event;
    setFromTime(value);
    validateTime(value, toTime);

    if (interData.length == 0) {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        fromDate,
        value,
        toDate,
        toTime
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        toDate,
        toTime
      );
    } else {
      isDataExists(
        fromDate,
        toDate,
        value,
        toTime,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_date,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, toDate, value, toTime);
  };

  const handleToTimeChange = (event) => {
    setToTimeDated(event);
    const { value } = event;
    setToTime(value);
    validateTime(fromTime, value);
    if (interData.length == 0) {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        fromDate,
        fromTime,
        toDate,
        value
      );
    } else if (indexValue == true) {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        toDate,
        value
      );
    } else {
      isDataExists(
        fromDate,
        toDate,
        fromTime,
        value,
        interData && interData[0] && interData[0].from_date,
        interData && interData[0] && interData[0].from_time,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_date,
        interData &&
          interData[interData.length - 1] &&
          interData[interData.length - 1].to_time
      );
    }
    isAddDataExists(fromDate, toDate, fromTime, value);

    // if (interData.length == 0 || indexValue == true) {

    // } else {
    //   isDataExists(
    //     interData[0].from_date,
    //     toDate,
    //     interData[0].from_time,
    //     value
    //   );
    // }
    isAddDataExists(fromDate, toDate, fromTime, value);
  };

  const validateTime = (from, to) => {
    if (from && to && fromDate === toDate) {
      if (from === to) {
        setErrorTime("Departed time should not be same to reaching time.");
      } else if (from > to) {
        setErrorTime("Departed time should be before the to reaching time.");
      } else {
        setErrorTime("");
      }
    } else {
      setErrorTime("");
    }
  };

  const validateDateOfJoin = (date) => {
    if (userDetails[0]?.date_of_joining > date) {
      setValidJoinDate(true);
    } else {
      setValidJoinDate(false);
    }
  };

  useEffect(() => {
    if (userBackDate?.length > 0) {
      let curData = userBackDate[userBackDate?.length - 1];
      setMinBackDateValue(curData?.opened_from);
      setMaxBackDateValue(curData?.to_date);
    }
  }, [userBackDate]);

  const handleChange = (e, name) => {
    setChangeValueData(true);
    switch (name) {
      case "from_city_input":
        setInterFormData((prev) => ({
          ...prev,
          from_city_input: e?.split("-")[0],
        }));
        break;
      case "to_city_input":
        setInterFormData((prev) => ({
          ...prev,
          to_city_input: e?.target?.value?.split("-")[0],
        }));
        break;
      case "from_city_id":
        setInterFormData((prev) => ({
          ...prev,
          from_city_id: e.value,
          from_city_name: e.label,
        }));
        setCitiesData(e);
        break;
      case "to_city_id":
        setInterFormData((prev) => ({
          ...prev,
          to_city_id: e.value,
          to_city_name: e.label,
        }));
        setCitiesRecord(e);
        break;
      case "from_date":
        setInterFormData((prev) => ({
          ...prev,
          from_date: e ? moment(e).format("YYYY-MM-DD") : "",
        }));
        setInterFormData((prev) => ({ ...prev, from_time: "" }));
        setFromTimeDated("");
        break;
      case "hotel":
        setInterFormData((prev) => ({ ...prev, hotel: e.target.value }));
        break;
      case "from_time":
        setInterFormData((prev) => ({ ...prev, from_time: e.value }));
        break;
      case "to_time":
        setInterFormData((prev) => ({ ...prev, to_time: e.value }));
        break;
      case "to_date":
        setInterFormData((prev) => ({
          ...prev,
          to_date: e ? moment(e).format("YYYY-MM-DD") : "",
        }));
        setInterFormData((prev) => ({ ...prev, to_time: "" }));
        setToTimeDated("");
        break;
      case "travel_class":
        setInterFormData((prev) => ({ ...prev, travel_class: e.target.value }));
        break;
      case "purpose_of_travel_id":
        setInterFormData((prev) => ({
          ...prev,
          purpose_of_travel_id: e.value,
          purpose: e.label,
        }));
        setPurposeData(e);
        break;
      case "mode_id":
        setInterFormData((prev) => ({
          ...prev,
          mode_id: e.value,
          mode_name: e.label,
        }));
        setModeData(e);
        break;
      case "colleague_user_id":
        setInterFormData((prev) => ({
          ...prev,
          colleague_user_id: e.value,
          colleague_user_name: e.label,
        }));
        setEmployeeData(e);
        break;
      case "reason_for_exception_id":
        setInterFormData((prev) => ({
          ...prev,
          reason_for_exception_id: e.value,
          reason_for_exception_name: e.label,
        }));
        setReasonData(e);
        break;
      case "additional_information":
        setInterFormData((prev) => ({
          ...prev,
          additional_information: e.target.value,
        }));
        break;
      case "from_continent_id":
        setInterFormData((prev) => ({
          ...prev,
          from_continent_id: e.value,
          from_continent_name: e.label,
        }));
        setContinentData(e);
        break;
      case "from_country_id":
        setInterFormData((prev) => ({
          ...prev,
          from_country_id: e.value,
          from_country_name: e.label,
        }));
        setCountryData(e);

        break;
      case "to_continent_id":
        setInterFormData((prev) => ({
          ...prev,
          to_continent_id: e.value,
          to_continent_name: e.label,
        }));
        setContinentDataReach(e);

        break;
      case "to_country_id":
        setInterFormData((prev) => ({
          ...prev,
          to_country_id: e.value,
          to_country_name: e.label,
        }));
        setCountryDataReach(e);
        break;
      case "stay_needed":
        setInterFormData((prev) => ({
          ...prev,
          stay_needed: e.target.value,
        }));
        break;
      default:
    }
  };

  const addMoreTravelInfo = () => {
    if (validateRecords()) return false;
    if (validDateJoin == false) {
      if (
        moment(interFormData?.from_date).isSame(
          moment(interFormData?.to_date)
        ) &&
        (interFormData?.from_time == interFormData?.to_time ||
          interFormData?.from_time > interFormData?.to_time)
      ) {
        setValidTimeZone(true);
      } else {
        validateAdditional();
      }
    }
  };

  const AllClear = () => {
    setCitiesData([]);
    setPurposeData([]);
    setModeData([]);
    setReasonData([]);
    setCitiesRecord([]);
    setContinentData([]);
    setCountryDataReach([]);
    setContinentDataReach([]);
    setInputValue("");
    setInputValueNext("");
    setCountryData([]);
    setFromTimeDated("");
    setToTimeDated("");
    setInterFormData({
      from_date: "",
      from_time: "",
      to_time: "",
      to_date: "",
      from_city_id: "",
      hotel: "",
      additional_information: "",
      travel_class: "",
      stay_needed: "",
    });
    // setInterFormData((prev) => ({
    //   ...prev,
    //   from_city_input: "",
    // }));
    // setInterFormData((prev) => ({
    //   ...prev,
    //   to_city_input: "",
    // }));
  };

  const saveTable = (values) => {
    dispatch(setDisplayLoader("Display"));
    let data = {};
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      if (values[key] !== "") {
        if (key === "from_date" || key === "to_date") {
          data[key] = moment(value).format("YYYY-MM-DD");
        } else {
          data[key] = value;
        }
      }
    });
    if (values?.id === undefined) {
      TravelListingAPI.saveTable(data)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            setCallGrid(true);
            AllClear();
            setChangeFunc(true);
            // setFormView(false);
            onClose();
            toast.success(response?.data?.message);
            onAddCallBack();
            setTaxi(false);
            setAir(false);
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      TravelListingAPI.updateTable(data, values?.id)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            setCallGrid(true);
            AllClear();
            setTimeCheck("");
            setChangeFunc(true);
            // setFormView(false);
            // toast.success(response?.data?.message);
            onAddCallBack();
            onClose();
            setTaxi(false);
            setAir(false);
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getContinent = () => {
    TravelListingAPI.getContinent()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.name,
          };
          data.push(data1);
        });
        setContinents(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getCountry = (continentId, name) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCountry(continentId)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.name,
          };
          data.push(data1);
        });
        if (name === "continent") {
          setCountrys(data);
        } else {
          setCountryConData(data);
        }
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCity = (countryId, name) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getCity(countryId)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: `${item?.name} - ${item?.city_category}`,
            city_name: item?.name,
          };
          data.push(data1);
        });
        if (name === "countryid") {
          setCities(data);
        } else {
          setCitiesCon(data);
        }
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getModeList = () => {
    TravelListingAPI.getModeList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.mode,
          };
          data.push(data1);
          if (
            userData?.organisation_code == "PIJA" &&
            (vehicleAssignValues == undefined || vehicleAssignValues == false)
          ) {
            let dataValue = data?.filter((ele) => {
              if (ele?.label != "Car") {
                return true;
              }
            });
            setMode(dataValue);
            // } else if (
            //   userData?.organisation_code == "PIJA" &&
            //   vehicleAssignValues !== undefined &&
            //   vehicleAssignValues == true
            // ) {
            //   let dataValue = data?.filter((ele) => {
            //     if (ele?.label != "Personal Car") {
            //       return true;
            //     }
            //   });
            //   setMode(dataValue);
          } else {
            setMode(data);
          }
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getReasonList = () => {
    TravelListingAPI.getReasonList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.reason,
          };
          data.push(data1);
        });
        setReasons(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPurposeList = () => {
    TravelListingAPI.getPurposeList()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.purpose,
          };
          data.push(data1);
        });
        setPurpose(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getEmployee = (paginate) => {
    TravelListingAPI.getEmployee(paginate)
      .then((response) => {
        let data = [];

        const filteredUsers = response?.data?.dataList?.result.filter(
          (user) => user.is_active
        );
        filteredUsers.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getPurposeList();
    getModeList();
    getReasonList();
    getContinent();
    getUsersDetails(
      managerEditing && dynamicUserData
        ? dynamicUserData?.username
        : userData?.username
    );
    fetchData();
    timeOptionFunc();
    getCity(3, "countryid");
    getEmployee(false);
  }, []);

  useEffect(() => {
    if (editInterData) {
      setInterFormData(editInterData);
      // setCheckCountry(true);
      if (editInterData?.from_country_name == "India") {
        setCheckCountry(true);
      }
      if (editInterData?.to_country_name == "India") {
        setCheckCountryOther(true);
      }
      if (editInterData.colleague_user_id) {
        setColleagueSelectFlag(true);
        setEmployeeData({
          label: editInterData.colleague_user_name,
          value: editInterData.colleague_user_id,
        });
      }

      let fromLocate =
        editInterData?.from_city_name == "Other" &&
        editInterData?.from_country_name !== "India"
          ? editInterData?.from_city_input
          : editInterData?.from_city_input?.split("-")[0];
      if (
        editInterData?.from_city_name == "Other" &&
        editInterData?.from_country_name == "India"
      ) {
        setInterFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      } else {
        setInterFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      }
      let toLocate =
        editInterData?.to_city_name == "Other" &&
        editInterData?.to_country_name !== "India"
          ? editInterData?.to_city_input
          : editInterData?.to_city_input?.split("-")[0];
      if (
        editInterData?.to_city_name == "Other" &&
        editInterData?.to_country_name == "India"
      ) {
        setInterFormData((prev) => ({
          ...prev,
          to_city_input: toLocate,
        }));
      } else {
        setInterFormData((prev) => ({
          ...prev,
          to_city_input: toLocate,
        }));
      }
      setInterFormData((prev) => ({
        ...prev,
        travel_class: editInterData?.travel_class,
      }));

      setInterFormData((prev) => ({
        ...prev,
        reason_for_exception_id: editInterData?.reason_for_exception_id,
        reason_for_exception_name: editInterData?.reason_for_exception_name,
      }));

      if (editInterData?.from_country_name) {
        getCountry(editInterData?.from_continent_id, "continent");
      }
      if (editInterData?.to_country_name) {
        getCountry(editInterData?.to_continent_id, "continentReach");
      }
      if (editInterData?.from_city_id) {
        getCity(editInterData?.from_country_id, "countryid");
      }
      if (editInterData?.to_city_name) {
        getCity(editInterData?.to_country_id, "countryidReach");
      }
      let dateFrom = {
        value: editInterData?.from_time.substring(0, 5),
        label: editInterData?.from_time.substring(0, 5),
      };
      setFromTimeDated(dateFrom);
      let dateto = {
        value: editInterData?.to_time.substring(0, 5),
        label: editInterData?.to_time.substring(0, 5),
      };
      setToTimeDated(dateto);
      // let mainArrayCont = interData;
      // var currentIndex = mainArrayCont.findIndex(function (item) {
      //   return item?.id === editInterData?.id;
      // });
      // let lastCont = mainArrayCont[currentIndex - 1];
      // if (lastCont !== undefined) {
      //   let fromContinent = {
      //     value: lastCont?.to_continent_id,
      //     label: lastCont?.to_continent_name,
      //   };
      //   setContinentData(fromContinent);
      // } else {
      let fromContinent = {
        value: editInterData?.from_continent_id,
        label: editInterData?.from_continent_name,
      };
      setContinentData(fromContinent);
      // }
      let toContinent = {
        value: editInterData?.to_continent_id,
        label: editInterData?.to_continent_name,
      };
      setContinentDataReach(toContinent);

      // let mainArrayCountry = interData;
      // var currentIndexCountry = mainArrayCountry.findIndex(function (item) {
      //   return item?.id === editInterData?.id;
      // });
      // let lastCountry = mainArrayCountry[currentIndexCountry - 1];
      // if (lastCountry !== undefined) {
      //   let fromCountry = {
      //     value: lastCountry?.to_country_id,
      //     label: lastCountry?.to_country_name,
      //   };
      //   setCountryData(fromCountry);
      // } else {
      let fromCountry = {
        value: editInterData?.from_country_id,
        label: editInterData?.from_country_name,
      };
      setCountryData(fromCountry);
      // }
      let toCountry = {
        value: editInterData?.to_country_id,
        label: editInterData?.to_country_name,
      };
      setCountryDataReach(toCountry);
      let fromCity = {
        value: editInterData?.from_city_id,
        label: editInterData?.from_city_name,
      };
      setCitiesData(fromCity);

      let fromLocation = `${
        editInterData?.from_city_name == "Other" &&
        editInterData?.from_country_name == "India"
          ? editInterData?.from_city_input +
            "-" +
            editInterData?.from_city_category
          : editInterData?.from_city_name !== "Other" &&
            editInterData?.from_country_name == "India"
          ? editInterData?.from_city_name +
            "-" +
            editInterData?.from_city_category
          : editInterData?.from_city_name == "Other" &&
            editInterData?.from_country_name !== "India"
          ? editInterData?.from_city_input
          : editInterData?.from_city_name
      }`;
      setInputValue(fromLocation);

      // let mainArray = interData;
      // var currentIndex = mainArray.findIndex(function (item) {
      //   return item?.id === editInterData?.id;
      // });
      // let last = mainArray[currentIndex - 1];
      // let newCity = last?.to_city_input;
      // let fromLocationInter =
      //   last?.to_city_name !== "Other"
      //     ? `${last?.to_city_name + "-" + last?.to_city_category}`
      //     : `${last?.to_city_input + "-" + last?.to_city_category}`;
      // let fromLocationId = { value: last?.to_city_id };
      // let fromLocateInter = newCity?.split("-")[0];
      // if (last !== undefined) {
      //   setInputValue(fromLocationInter);
      //   setStateSelection(fromLocationId);
      //   setInterFormData((prev) => ({
      //     ...prev,
      //     from_city_input: fromLocateInter,
      //   }));
      // } else {
      //   setInputValue(fromLocation);
      //   // setStateSelection(startfromLocationId);
      //   setInterFormData((prev) => ({
      //     ...prev,
      //     from_city_input: fromLocateInter,
      //   }));
      // }

      let toLocation = `${
        editInterData?.to_city_name == "Other" &&
        editInterData?.to_country_name == "India"
          ? editInterData?.to_city_input + "-" + editInterData?.to_city_category
          : editInterData?.to_city_name !== "Other" &&
            editInterData?.to_country_name == "India"
          ? editInterData?.to_city_name + "-" + editInterData?.to_city_category
          : editInterData?.to_city_name == "Other" &&
            editInterData?.to_country_name !== "India"
          ? editInterData?.to_city_input
          : editInterData?.to_city_name
      }`;
      setInputValueNext(toLocation);
      let toCity = {
        value: editInterData?.to_city_id,
        label: editInterData?.to_city_name,
      };
      setCitiesRecord(toCity);
      let prpose = {
        value: editInterData?.purpose_of_travel_id,
        label: editInterData?.purpose,
      };
      setPurposeData(prpose);
      let mode = {
        value: editInterData?.mode_id,
        label:
          editInterData?.modee_name !== undefined
            ? editInterData?.modee_name
            : editInterData?.mode_name,
      };
      setModeData(mode);
      let reason = {
        value: editInterData?.reason_for_exception_id,
        label: editInterData?.reason_for_exception_name,
      };
      setReasonData(reason);
      setFromDate(editInterData?.from_date);
      setFromTime(editInterData?.from_time);
      setToDate(editInterData?.to_date);
      setToTime(editInterData?.to_time);

      Object.keys(editInterData).forEach((item) => {
        interFormData[item] = editInterData[item];
      });
    }
  }, [editInterData]);

  const onConfirmClose = () => {
    setConfirmModal(false);
  };

  const editUpdate = () => {
    if (validateRecords()) return false;
    AllClear();
    let payload = { ...interFormData };
    payload.from_date = moment(interFormData?.from_date).format("YYYY-MM-DD");
    payload.to_date = moment(interFormData?.to_date).format("YYYY-MM-DD");
    update(interFormData);
  };

  const AddNewRecord = () => {
    if (validateRecords()) return false;
    if (validDateJoin == false) {
      AllClear();
      AddFunction(interFormData);
    }
  };

  useEffect(() => {
    if (editInterData) {
      if (editInterData?.reason_for_exception_id !== null) {
        setTaxi(true);
      }
      if (editInterData?.travel_class == "Business") {
        setAir(true);
      }
      setFormValidation({
        from_city_id: "",
        to_city_id: "",
        from_date: "",
        from_time: "",
        to_time: "",
        to_date: "",
        purpose_of_travel_id: "",
        additional_information: "",
        mode_id: "",
        hotel: "",
        reason_for_exception_id: "",
        travel_class: "",
        from_continent_id: "",
        from_country_id: "",
        to_continent_id: "",
        to_country_id: "",
      });
    }
  }, [editInterData]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color:
        state.data.label === "Leave" || state.data.label === "Holiday/WeeklyOff"
          ? "red"
          : "black",
    }),
  };

  const checkTravelMode = (newValue) => {
    setReasonData([]);
    setFormValidation({
      ...formValidation,
      mode_id: "",
    });
    setInterFormData((prev) => ({
      ...prev,
      reason_for_exception_id: null,
      reason_for_exception_name: null,
    }));

    if (newValue.label === "Travel with Colleague") {
      setColleagueSelectFlag(true);
    } else {
      setColleagueSelectFlag(false);
      setInterFormData((prev) => ({
        ...prev,
        colleague_user_id: null,
        colleague_user_name: "",
      }));
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [inputValueNext, setInputValueNext] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [displaySuggestionsNext, setDisplaySuggestionsNext] = useState(false);
  const [stateSelection, setStateSelection] = useState(null);
  const [stateSelectionNext, setStateSelectionNext] = useState(null);

  const [checkCountry, setCheckCountry] = useState(false);
  const [checkCountryOther, setCheckCountryOther] = useState(false);

  const validCountry = (data) => {
    if (data == "India") {
      setCheckCountry(true);
    } else {
      setCheckCountry(false);
    }
  };

  const validCountryOther = (data) => {
    if (data == "India") {
      setCheckCountryOther(true);
    } else {
      setCheckCountryOther(false);
    }
  };
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);

  let suggestions = cities.filter((object) => {
    if (object.label !== "Other - C") {
      return true;
    }
  });

  const onChange = (event, flag) => {
    if (flag === "from_city") {
      const Actualvalue =
        event.target.value?.charAt(0)?.toUpperCase() +
        event.target.value?.slice(1);
      const filteredSuggestionsAll = suggestions?.filter((suggestion) =>
        suggestion?.label?.toLowerCase()?.includes(Actualvalue.toLowerCase())
      );
      if (filteredSuggestionsAll?.length === 0) {
        setInterFormData((prev) => ({
          ...prev,
          from_city_id: "",
        }));
      }
      handleChange(Actualvalue, "from_city_input");
      setInputValue(Actualvalue);
      setFilteredSuggestions(filteredSuggestionsAll);
      setDisplaySuggestions(true);
    }
    if (flag === "to_city") {
      const Actualvalue =
        event.target.value?.charAt(0)?.toUpperCase() +
        event.target.value?.slice(1);
      const filteredSuggestionsAll = suggestions?.filter((suggestion) =>
        suggestion.label?.toLowerCase()?.includes(Actualvalue.toLowerCase())
      );
      if (filteredSuggestionsAll?.length === 0) {
        setInterFormData((prev) => ({
          ...prev,
          to_city_id: "",
        }));
        handleChange(event, "to_city_input");
      } else if (filteredSuggestionsAll?.length === 1) {
        setInterFormData((prev) => ({
          ...prev,
          to_city_id: filteredSuggestionsAll[0]?.value,
        }));
      }
      setInputValueNext(Actualvalue);
      setFilteredSuggestions(filteredSuggestionsAll);
      setDisplaySuggestionsNext(true);
    }
  };

  const onSelectSuggestion = (values, flag) => {
    if (flag === "from_city") {
      let dd = values;
      setInputValue(values?.label);
      setStateSelection(dd);
      setDisplaySuggestions(false);
    }
    if (flag === "to_city") {
      let xx = values;
      setInputValueNext(values?.label);
      setStateSelectionNext(xx);
      setDisplaySuggestionsNext(false);
    }
  };

  useEffect(() => {
    if (stateSelection !== null && stateSelection?.value !== 1) {
      setInterFormData((prev) => ({
        ...prev,
        from_city_id: stateSelection.value,
      }));
      setInterFormData((prev) => ({
        ...prev,
        from_city_input: "",
      }));
    }
  }, [stateSelection]);

  useEffect(() => {
    if (stateSelectionNext !== null) {
      setInterFormData((prev) => ({
        ...prev,
        to_city_id: stateSelectionNext.value,
      }));
      setInterFormData((prev) => ({
        ...prev,
        to_city_input: "",
      }));
    }
  }, [stateSelectionNext]);

  const fetchData = () => {
    const filteredOptions = continents.filter((obj) => obj.label === "Asia");
    const formattedOptions = filteredOptions.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    setContinentData(formattedOptions);
    if (formattedOptions.length > 0) {
      getCountry(3, "continent");
      let dd = {
        value: 1,
        label: "India",
      };
      let ff = { value: 3, label: "Asia" };

      setCountryData(dd);
      setCheckCountry(true);
      handleChange(ff, "from_continent_id");
      handleChange(dd, "from_country_id");
    }
  };

  useEffect(() => {
    if (interData.length === 0) {
      fetchData();
    }
  }, [continents]);

  const checkLeave = (label) => {
    // if (label === "Leave") {
    isLeaveExists(fromDate, toDate, label);
    // }
  };
  useEffect(() => {
    if (
      userDetails?.length > 0 &&
      cities?.length > 0 &&
      interData.length === 0
    ) {
      let curUser = userDetails[0];

      if (curUser?.location_id !== null) {
        let match = cities?.filter((obj) => {
          if (obj?.value === curUser?.location_id) {
            return true;
          }
        });
        setInputValue(match[0].label);
        // setStateSelection(match[0].value);
        setInterFormData((prev) => ({
          ...prev,
          from_city_id: match[0].value,
        }));
      } else {
        setInputValue(curUser?.location);
        setInterFormData((prev) => ({
          ...prev,
          from_city_input: curUser?.location,
        }));
      }
    }
  }, [userDetails, cities]);

  useEffect(() => {
    let empSubGrp = userDetails[0]?.emp_sub_group?.charAt(0);
    let empSubGrpCons = userDetails[0]?.emp_subgroup_cons?.charAt(0);
    let name =
      interFormData?.mode_name == undefined
        ? interFormData?.modee_name
        : interFormData?.mode_name;
    if (
      (interFormData?.purpose !== undefined && edit !== true) ||
      edit == true
    ) {
      if (interFormData?.purpose !== "Annual Meet" && name === "Air") {
        setAir(true);
      } else {
        setAir(false);
      }
      switch (name) {
        case "Air":
          if (empSubGrp !== undefined) {
            if (
              (!["D", "T", "S", "M"]?.includes(empSubGrp) &&
                interFormData?.purpose !== "Annual Meet") ||
              (!["D", "T", "S", "M"]?.includes(empSubGrpCons) &&
                empSubGrpCons != null)
            ) {
              setTaxi(true);
            } else if (
              (interFormData?.mode_name === "Air" ||
                interFormData?.modee_name === "Air") &&
              interFormData?.purpose !== "Annual Meet" &&
              interFormData?.travel_class === "Business" &&
              !["D", "T"]?.includes(empSubGrp)
            ) {
              setTaxi(true);
            } else {
              setTaxi(false);
            }
          }
          break;
        // case "Car":
        //   if (
        //     !["D", "T", "S"]?.includes(empSubGrp) ||
        //     (!userDetails[0]?.emp_subgroup_cons?.includes("S") &&
        //       userDetails[0]?.emp_subgroup_cons !== null) ||
        //     (!["1003", "1007", "1017"]?.includes(
        //       userDetails[0]?.personal_area_name
        //     ) &&
        //       ["CO01", "GG01"]?.includes(
        //         !userDetails[0]?.sub_personal_area_name
        //       ))
        //   ) {
        //     setTaxi(true);
        //   } else {
        //     setTaxi(false);
        //   }
        //   break;

        case "Car":
          if (empSubGrp !== undefined) {
            if (["D", "T", "S"]?.includes(empSubGrp)) {
              setTaxi(false);
            } else if (
              !["D", "T", "S"]?.includes(empSubGrpCons) &&
              empSubGrpCons != null &&
              empSubGrpCons !== undefined
            ) {
              setTaxi(true);
            } else if (
              !["1003", "1007", "1017"]?.includes(
                userDetails[0]?.personal_area_name
              ) &&
              ["CO01", "GG01"]?.includes(userDetails[0]?.sub_personal_area_name)
            ) {
              setTaxi(true);
            } else {
              setTaxi(false);
            }
          }
          break;
        case "Taxi":
          if (empSubGrp !== undefined) {
            if (
              !["D", "T", "S"]?.includes(empSubGrp) ||
              (!["D", "T", "S"]?.includes(empSubGrpCons) &&
                empSubGrpCons != null)
            ) {
              setTaxi(true);
            } else {
              setTaxi(false);
            }
          }
          break;
        case "Personal Car":
          if (empSubGrp !== undefined) {
            if (
              !["D", "T", "S"]?.includes(empSubGrp) ||
              (!["D", "T", "S"]?.includes(empSubGrpCons) &&
                empSubGrpCons != null) ||
              (!userDetails[0]?.personal_area_name === "1007" &&
                vehicleAssignValues !== undefined &&
                vehicleAssignValues == false)
            ) {
              setTaxi(true);
            } else {
              setTaxi(false);
            }
          }
          break;
        default:
          setTaxi(false);
          break;
      }
    }

    if (
      moment(interFormData?.from_date).format("DD-MM-yyyy") ===
      moment(interFormData?.to_date).format("DD-MM-yyyy")
    ) {
      let tt = moment(interFormData?.to_time, "HH:mm").diff(
        moment(interFormData?.from_time, "HH:mm"),
        "hours"
      );
      setDateCalcData(tt);
    }
    if (
      interFormData?.from_city_id &&
      interFormData?.to_city_id == dataNew[0].location_id
    ) {
      setHqCondition(true);
    } else if (
      interFormData?.from_city_input &&
      interFormData?.to_city_input == dataNew[0].location
    ) {
      setHqCondition(false);
    } else {
      setHqCondition(false);
    }

    if (
      isLeaveExist &&
      interFormData?.purpose != undefined &&
      interFormData?.purpose != "Leave"
    ) {
      setIsLeaveExistValue(true);
    } else {
      setIsLeaveExistValue(false);
    }
  }, [interFormData]);

  useEffect(() => {
    if (editInterData == null && taxi == false) {
      setReasonData([]);
      setInterFormData({ ...interFormData, reason_for_exception_id: null });
    }
  }, [taxi]);

  useEffect(() => {
    if (editInterData == null && air == false) {
      setInterFormData({ ...interFormData, travel_class: "" });
    }
  }, [air]);

  useEffect(() => {
    const checkToOrg = userDetails[0]?.organisation_code;
    if (
      (checkToOrg === "PIIL" || checkToOrg == "PIJA") &&
      dateCalcData > 17 &&
      hqCondition === true &&
      inputValue.split("-")[0] === inputValueNext?.split("-")[0]
    ) {
      setInterFormData((prev) => ({
        ...prev,
        reason_for_exception_id: "5",
        reason_for_exception_name: "Other",
      }));
      setInterFormData((prev) => ({
        ...prev,
        reason: "Traveled more than 17hr on same day",
      }));
    }
  }, [dateCalcData, userDetails]);

  useEffect(() => {
    if (interData.length !== 0 && edit !== true) {
      let mainArray = interData;
      let last = mainArray[mainArray.length - 1];

      let fromLocation =
        last?.to_city_name == "Other" && last?.to_country_name == "India"
          ? last?.to_city_input + " - " + last?.to_city_category
          : last?.to_city_name !== "Other" && last?.to_country_name == "India"
          ? last?.to_city_name + " - " + last?.to_city_category
          : last?.to_city_name == "Other" && last?.to_country_name !== "India"
          ? last?.to_city_input
          : last?.to_city_name;
      let fromLocationId = { value: last?.to_city_id };
      let fromLocate =
        last?.to_city_name == "Other" && last?.to_country_name !== "India"
          ? fromLocation
          : fromLocation?.split("-")[0];

      setInterFormData((prev) => ({
        ...prev,
        from_city_id: last?.to_city_id,
        from_city_name: last?.to_city_name,
      }));

      let conti = {
        value: last?.to_continent_id,
        label: last?.to_continent_name,
      };

      setInterFormData((prev) => ({
        ...prev,
        from_continent_id: last?.to_continent_id,
        from_continent_name: last?.to_continent_name,
      }));
      setContinentData(conti);
      getCountry(last?.to_continent_id, "continent");
      let nextCountry = {
        value: last?.to_country_id,
        label: last?.to_country_name,
      };

      setInterFormData((prev) => ({
        ...prev,
        from_country_id: last?.to_country_id,
        from_country_name: last?.to_country_name,
      }));
      setCountryData(nextCountry);
      validCountry(last?.from_country_name);
      if (checkCountry == false) {
        setInterFormData((prev) => ({
          ...prev,
          from_city_input: fromLocate,
        }));
      }
      setInputValue(fromLocation);
      setStateSelection(fromLocationId);
    }
  }, [interData]);

  const timeOptionFunc = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const timeValue = `${formattedHour}:${formattedMinute}`;
        timeOptions.push({ label: timeValue, value: timeValue });
      }
    }
    setTimeOption(timeOptions);
  };

  const customSelectStyle = {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "17px",
    backgroundColor: "#fff",
    color: "#333",
    cursor: "pointer",
    outline: "none",
    width: "150px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    backgroundImage: "url('path/to/your/custom-arrow.png')",
    backgroundPosition: "right 8px center",
    backgroundRepeat: "no-repeat",
  };

  const handleBackDated = (value) => {
    let date = value;
    if (
      moment(date).isAfter(moment(maxBackDateValue)) ||
      moment(date).isBefore(moment(minBackDateValue))
    ) {
      setBackDatedError(true);
    } else {
      setBackDatedError(false);
    }
  };

  const validateAdditional = () => {
    if (
      userDetails[0]?.personal_area_name == 1003 &&
      (interFormData?.additional_information == "" ||
        interFormData?.additional_information == null)
    ) {
      setMessageDisplay(true);
    } else {
      saveTable(interFormData);
    }
  };

  const handleDOJValid = (value) => {
    let DOJ = userDetails[0]?.date_of_joining;
    let date = moment(value).format("YYYY-MM-DD");
    if (moment(DOJ).isAfter(moment(date))) {
      setValidDateJoin(true);
    } else {
      setValidDateJoin(false);
    }
  };

  useEffect(() => {
    let data = timeOption?.map((option) => {
      return {
        value: option.value,
        label: option.label,
      };
    });
    setTimeSetValue(data);
  }, [timeOption]);

  return (
    <>
      <div className="px-4 pb-2">
        {validJoinDate && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>Departed On date
            should be after Date of joining
          </Alert>
        )}

        {!isLeaveExist &&
          isLeaveExist != undefined &&
          interFormData?.purpose != undefined &&
          interFormData?.purpose == "Leave" && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;Please apply
              leave first.
            </Alert>
          )}
        {isLeaveExist &&
          isLeaveExist != undefined &&
          interFormData?.purpose != undefined &&
          interFormData?.purpose != "Leave" && (
            <Alert variant="danger" className="mb-5 mt-2 text-center">
              <i className="fa fa-exclamation-triangle"></i>&nbsp;Please select
              appropriate value for Travel Mode in case of leave applied.
            </Alert>
          )}
        {(isExist || addIsExist) && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> This date is already
            reserved for another journey.
          </Alert>
        )}
        {validTimeZone && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Reaching time cannot
            be less than or same as departure time for same date
          </Alert>
        )}

        {messageDisplay && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> Additional Info can
            not be Blank
          </Alert>
        )}

        {isLeaveExistValue && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>&nbsp;Please select
            appropriate value for Travel Mode in case of leave applied.
          </Alert>
        )}

        {validDateJoin && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i> You can not travel
            before Date of joining
          </Alert>
        )}

        <div className="bg-light px-3 pt-3 mb-3">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Departure Continent<span className="text-danger">*</span>
                </label>
                <Select
                  name="from_continent_id"
                  options={continents}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    getCountry(newValue.value, "continent");
                    setCitiesData([]);
                    setCountryData([]);
                    setFormValidation({
                      ...formValidation,
                      from_continent_id: "",
                    });
                  }}
                  value={continentsData}
                />
                <div className="small text-danger">
                  {formValidation.from_continent_id}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Departure Country<span className="text-danger">*</span>
                </label>
                <Select
                  name="from_country_id"
                  options={countrys}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    getCity(newValue.value, "countryid");
                    validCountry(newValue.label);
                    setCitiesData([]);
                    setInterFormData((prev) => ({
                      ...prev,
                      from_city_input: "",
                    }));
                    setFormValidation({
                      ...formValidation,
                      from_country_id: "",
                    });
                  }}
                  value={countryData}
                />
                <div className="small text-danger">
                  {formValidation.from_country_id}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Departure City<span className="text-danger">*</span>
                </label>
                {checkCountry ? (
                  <div class="modulesearchbox">
                    <input
                      className="innergroup form-control"
                      type="text"
                      name="from_city_id"
                      onChange={(e) => {
                        onChange(e, "from_city");
                        setDisplaySuggestionsNext(false);
                        setTimeCheck("");
                        setFormValidation({
                          ...formValidation,
                          from_city_id: "",
                        });
                      }}
                      value={
                        inputValue?.charAt(0)?.toUpperCase() +
                        inputValue?.slice(1)
                      }
                      placeholder="Departure City..."
                      maxLength={30}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                    />
                    <SuggestionsList
                      inputValue={inputValue}
                      selectedSuggestion={selectedSuggestion}
                      onSelectSuggestion={onSelectSuggestion}
                      displaySuggestions={displaySuggestions}
                      suggestions={filteredSuggestions}
                      flag={"from_city"}
                    />
                  </div>
                ) : (
                  <input
                    name="from_city_input"
                    className="form-control"
                    maxLength={30}
                    placeholder="Enter City..."
                    value={interFormData?.from_city_input}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        from_city_id: "",
                      });
                    }}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />
                )}

                <div className="small text-danger">
                  {formValidation.from_city_id}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group innergroup d-flex flex-column">
                <label>
                  Departed On<span className="text-danger">*</span>
                </label>
                {/* <input
                  type="date"
                  className="form-control dateIconClick"
                  name="from_date"
                  maxlength="4"
                  min={
                    userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? minBackDateValue
                      : minDateValue
                  }
                  max={
                    (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate))) &&
                    maxBackDateValue
                  }
                  onBlur={(e) => {
                    if (
                      userBackDate?.length > 0 &&
                      moment(maxBackDateValue).isAfter(moment(valueDate))
                    ) {
                      handleBackDated(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    handleFromDateChange(e);
                    handleDOJValid(e.target.value);
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  value={interFormData.from_date}
                /> */}
                <DatePicker
                  selected={
                    interFormData?.from_date && interFormData?.from_date !== ""
                      ? new Date(interFormData?.from_date)
                      : null
                  }
                  // onBlur={(e) => {
                  //   if (
                  //     userBackDate?.length > 0 &&
                  //     moment(maxBackDateValue).isAfter(moment(valueDate))
                  //   ) {
                  //     handleBackDated(e.target.value);
                  //   }
                  // }}
                  onChange={(date) => {
                    handleFromDateChange(date);
                    handleDOJValid(date);
                    handleChange(date, "from_date");
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  minDate={
                    userBackDate?.length > 0 &&
                    (moment(maxBackDateValue).isSame(valueDate) ||
                      moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? new Date(minBackDateValue)
                      : new Date(minDateValue)
                  }
                  maxDate={new Date().setDate(new Date().getDate() + 90)}
                  // maxDate={
                  //   (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                  //     moment(maxBackDateValue).isSame(moment(valueDate))) &&
                  //   new Date(maxBackDateValue)
                  // }
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <div className="small text-danger">
                  {formValidation.from_date}
                </div>
                {backDatedError && (
                  <div className="small text-danger">
                    You cannot enter BackDate
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group innergroup">
                <label>
                  Departed Time<span className="text-danger">*</span>
                </label>
                <Select
                  name="from_time"
                  style={customSelectStyle}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={timeSetValue}
                  onChange={(e) => {
                    handleChange(e, "from_time");
                    handleFromTimeChange(e);
                    setFormValidation({
                      ...formValidation,
                      from_time: "",
                    });
                  }}
                  value={fromTimeDated}
                ></Select>
                {/* <input
                  type="time"
                  className="form-control"
                  name="from_time"
                  onChange={(e) => {
                    handleFromTimeChange(e);
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      from_time: "",
                    });
                  }}
                  value={interFormData.from_time}
                /> */}
                <div className="small text-danger">
                  {formValidation.from_time}
                </div>
              </div>
            </div>

            {/* <div className="col-md-2"></div> */}

            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Reaching Continent<span className="text-danger">*</span>
                </label>
                <Select
                  name="to_continent_id"
                  options={continents}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    getCountry(newValue.value, "continentReach");
                    setCitiesRecord([]);
                    setCountryDataReach([]);
                    setFormValidation({
                      ...formValidation,
                      to_continent_id: "",
                    });
                  }}
                  value={continentsDataReach}
                />
                <div className="small text-danger">
                  {formValidation.to_continent_id}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Reaching Country<span className="text-danger">*</span>
                </label>
                <Select
                  name="to_country_id"
                  options={countryConData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    getCity(newValue.value, "countryidReach");
                    setCitiesRecord([]);
                    setInterFormData((prev) => ({
                      ...prev,
                      to_city_input: "",
                    }));
                    validCountryOther(newValue.label);
                    setFormValidation({
                      ...formValidation,
                      to_country_id: "",
                    });
                  }}
                  value={countryDataReach}
                />
                <div className="small text-danger">
                  {formValidation.to_country_id}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Reaching City<span className="text-danger">*</span>
                </label>
                {checkCountryOther ? (
                  <div class="modulesearchbox">
                    <input
                      className="innergroup form-control"
                      type="text"
                      name="to_city_id"
                      onChange={(e) => {
                        onChange(e, "to_city");
                        setDisplaySuggestions(false);
                        setFormValidation({
                          ...formValidation,
                          to_city_id: "",
                        });
                        setInputFieldExp("true");
                      }}
                      value={
                        inputValueNext?.charAt(0)?.toUpperCase() +
                        inputValueNext?.slice(1)
                      }
                      placeholder="Reaching City..."
                      maxLength={30}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                    />

                    <SuggestionsList
                      inputValueNext={inputValueNext}
                      selectedSuggestion={selectedSuggestion}
                      onSelectSuggestion={onSelectSuggestion}
                      displaySuggestionsNext={displaySuggestionsNext}
                      suggestions={filteredSuggestions}
                      flag={"to_city"}
                    />
                  </div>
                ) : (
                  <input
                    name="to_city_input"
                    className="form-control"
                    maxLength={30}
                    placeholder="Enter City..."
                    onChange={(e) => {
                      handleChange(e, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        to_city_id: "",
                      });
                      setInputFieldExp("0");
                    }}
                    value={interFormData.to_city_input}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                  />
                )}
                {/* <Select
                  name="to_city_id"
                  id="to_city_ids"
                  // options={citiesCon}
                  options={citiesCon?.filter((obj) => {
                    console.log(obj.value, interFormData?.from_city_id);
                    if (obj.value !== interFormData?.from_city_id) {
                      return true;
                    }
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    setFormValidation({
                      ...formValidation,
                      to_city_id: "",
                    });
                  }}
                  value={citiesRecord}
                /> */}

                <div className="small text-danger">
                  {formValidation.to_city_id}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group innergroup d-flex flex-column">
                <label>
                  Reaching On<span className="text-danger">*</span>
                </label>
                {/* <input
                  type="date"
                  className="form-control dateIconClick"
                  name="to_date"
                  maxlength="4"
                  min={
                    userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? minBackDateValue
                      : moment(interFormData?.from_date).format("YYYY-MM-DD")
                  }
                  max={
                    (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate))) &&
                    maxBackDateValue
                  }
                  onChange={(e) => {
                    handleToDateChange(e);
                    setTimeCheck("");
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      to_date: "",
                    });
                  }}
                  value={interFormData.to_date}
                /> */}
                <DatePicker
                  selected={
                    interFormData?.to_date && interFormData?.to_date !== ""
                      ? new Date(interFormData?.to_date)
                      : null
                  }
                  onChange={(date) => {
                    handleToDateChange(date);
                    setTimeCheck("");
                    handleChange(date, "to_date");
                    setFormValidation({
                      ...formValidation,
                      to_date: "",
                    });
                  }}
                  minDate={
                    userBackDate?.length > 0 &&
                    (moment(maxBackDateValue).isSame(valueDate) ||
                      moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? new Date(minBackDateValue)
                      : new Date(interFormData?.from_date)
                  }
                  maxDate={
                    interFormData.from_date != ""
                      ? new Date(interFormData.from_date).setDate(
                          new Date(interFormData.from_date).getDate() + 90
                        )
                      : new Date().setDate(new Date().getDate() + 90)
                  }
                  // maxDate={
                  //   (moment(maxBackDateValue).isBefore(moment(valueDate)) ||
                  //     moment(maxBackDateValue).isSame(moment(valueDate))) &&
                  //   new Date(maxBackDateValue)
                  // }
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <div className="small text-danger">
                  {formValidation.to_date}
                </div>
                {error && <div className="small text-danger">{error}</div>}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group innergroup">
                <label>
                  Reaching Time<span className="text-danger">*</span>
                </label>
                <Select
                  name="to_time"
                  style={customSelectStyle}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={timeSetValue}
                  onChange={(e) => {
                    handleChange(e, "to_time");
                    handleToTimeChange(e);
                    setFormValidation({
                      ...formValidation,
                      to_time: "",
                    });
                  }}
                  value={toTimeDated}
                ></Select>
                {/* <input
                  type="time"
                  className="form-control"
                  name="to_time"
                  onChange={(e) => {
                    handleToTimeChange(e);
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      to_time: "",
                    });
                  }}
                  value={interFormData.to_time}
                /> */}
                <div className="small text-danger">
                  {formValidation.to_time}
                </div>
                {errorTime && (
                  <div className="small text-danger">{errorTime}</div>
                )}
              </div>
            </div>
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Purpose of Travel<span className="text-danger">*</span>
                </label>
                <Select
                  name="purpose_of_travel_id"
                  id="purpose_of_travel_ids"
                  options={purpose}
                  styles={customStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    checkLeave(newValue.label);
                    setFormValidation({
                      ...formValidation,
                      purpose_of_travel_id: "",
                    });

                    if (newValue.label === "Leave") {
                      setLeaveCondition(true);
                      setIsLeaveExistValue(false);
                      setTaxi(false);
                      setModeData([]);
                      setReasonData([]);
                      setInterFormData((prev) => ({
                        ...prev,
                        mode_id: "",
                        mode_name: "",
                      }));
                      setInterFormData((prev) => ({
                        ...prev,
                        stay_needed: "",
                      }));
                      setAir(false);
                    }
                    if (newValue.label !== "Leave") {
                      // setIsLeaveExist(true);
                      setLeaveCondition(false);
                    }
                  }}
                  value={purposeData}
                />

                <div className="small text-danger">
                  {formValidation.purpose_of_travel_id}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Travel Mode<span className="text-danger">*</span>
                </label>
                <Select
                  name="mode_id"
                  id="mode_ids"
                  isDisabled={
                    leaveCondition || interFormData?.purpose == "Leave"
                      ? true
                      : false
                  }
                  options={mode}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(newValue, action) => {
                    handleChange(newValue, action.name);
                    checkTravelMode(newValue);
                    // if (
                    //   newValue.label === "car" ||
                    //   newValue.label === "Taxi" ||
                    //   newValue.label === "Personal Car"
                    // ) {
                    //   setTaxi(true);
                    //   setAir(false);
                    // } else if (newValue.label === "Air") {
                    //   setAir(true);
                    //   setTaxi(false);
                    // } else {
                    //   setTaxi(false);
                    //   setAir(false);
                    // }
                  }}
                  value={modeData}
                />
                <div className="small text-danger">
                  {formValidation.mode_id}
                </div>
              </div>
            </div>

            {colleagueSelectFlag ? (
              <div className="col-md-4">
                <div className="form-group innergroup">
                  <label>
                    Collegue Employee Code
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    name="colleague_user_id"
                    id="colleague_user_ids"
                    options={allEmployee?.filter((obj) => {
                      if (
                        obj.value !==
                        (managerEditing && dynamicUserData
                          ? dynamicUserData?.id
                          : userData?.id)
                      ) {
                        return true;
                      }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      handleChange(newValue, action.name);
                      setFormValidation({
                        ...formValidation,
                        colleague_user_id: "",
                      });
                    }}
                    value={employeeData}
                  />
                  <div className="small text-danger">
                    {formValidation.colleague_user_id}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* {interFormData.from_country_id != 1 &&
              interFormData.to_country_id != 1 && ( */}
            <div className="col-md-2">
              <div className="form-group innergroup">
                <label>
                  Stay Needed<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control newbgselect"
                  name="stay_needed"
                  id={
                    interFormData.from_country_id != 1 &&
                    interFormData.to_country_id != 1 &&
                    "hotelselect"
                  }
                  disabled={
                    leaveCondition || interFormData?.purpose == "Leave"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (
                      e.target.value === "true" &&
                      interFormData.to_country_id === 1
                    ) {
                      setConfirmModal(true);
                    }
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      stay_needed: "",
                    });
                    setStayNeededValue(false);
                  }}
                  value={interFormData?.stay_needed}
                >
                  <option value="">Select</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="small text-danger">
                  {formValidation.stay_needed}
                </div>
              </div>
            </div>
            {/* )} */}
            {air ? (
              <div className="col-md-2">
                <div className="form-group innergroup">
                  <label>
                    Travel Class<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control newbgselect"
                    name="travel_class"
                    onChange={(e) => {
                      handleChange(e, e.target.name);
                      setFormValidation({
                        ...formValidation,
                        travel_class: "",
                      });
                      setStayNeededValue(true);
                    }}
                    value={interFormData?.travel_class}
                  >
                    <option value="">Select</option>
                    <option value="Business">Business</option>
                    <option value="Economy">Economy</option>
                  </select>
                  <div className="small text-danger">
                    {formValidation.travel_class}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {taxi ? (
              <div className="col-md-2">
                <div className="form-group innergroup">
                  <label>
                    Exception Reason<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="reason_for_exception_id"
                    id="reason_for_exception_ids"
                    options={reasons}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(newValue, action) => {
                      handleChange(newValue, action.name);
                      setFormValidation({
                        ...formValidation,
                        reason_for_exception_id: "",
                      });
                      setStayNeededValue(true);
                    }}
                    value={reasonData}
                  />
                  <div className="small text-danger">
                    {formValidation.reason_for_exception_id}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
              <div className="form-group innergroup">
                <label>Additional Information</label>
                {(userDetails[0]?.personal_area_desc === "Marketing" ||
                  userDetails[0]?.personal_area_desc === "PIJIV Marketing") && (
                  <span className="text-danger">*</span>
                )}
                <textarea
                  name="additional_information"
                  className="form-control"
                  maxLength={200}
                  style={{ width: "100%", height: "auto" }}
                  rows="2"
                  placeholder="Enter Additional Infirmation..."
                  value={interFormData.additional_information}
                  onChange={(e) => {
                    handleChange(e, e.target.name);
                    setFormValidation({
                      ...formValidation,
                      additional_information: "",
                    });
                  }}
                ></textarea>
                <div className="small text-danger">
                  {formValidation.additional_information}
                </div>
              </div>
            </div>
            <div className="col md-12 pb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="border-top my-3"></div>
                </div>

                <div className="col-md-12 mt-2 text-center">
                  {migrate === undefined && (
                    <button
                      className="btn btn-outline-primary mr-2"
                      type="button"
                      onClick={() => {
                        if (
                          isLeaveExist == false &&
                          interFormData?.purpose != undefined &&
                          interFormData?.purpose == "Leave"
                        ) {
                          checkLeave("tag");
                        } else {
                          migrate && edit ? editUpdate() : addMoreTravelInfo();
                        }
                      }}
                    >
                      {edit ? "Update Record" : "Add Record"}
                    </button>
                  )}
                  {migrate && edit ? (
                    <button
                      className="btn btn-outline-primary mr-2"
                      type="button"
                      onClick={() => {
                        if (
                          isLeaveExist == false &&
                          interFormData?.purpose != undefined &&
                          interFormData?.purpose == "Leave"
                        ) {
                          checkLeave("tag");
                        } else {
                          migrate && edit ? editUpdate() : addMoreTravelInfo();
                        }
                      }}
                    >
                      {edit ? "Update Record" : "Add Record"}
                    </button>
                  ) : (
                    ""
                  )}
                  {migrate && !edit ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary mr-2"
                      onClick={() => AddNewRecord()}
                    >
                      Add Records
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    className="btn btn-primary-inner bpi-main mr-2"
                    onClick={() => AllClear()}
                  >
                    Reset Record
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger bpi-main"
                    onClick={() => onClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmModal && (
        <AlertBox confirmModal={confirmModal} onConfirmClose={onConfirmClose} />
      )}
    </>
  );
};

export default AddInternational;
