import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { uploadFile } from "../../service/policy";
import * as moment from "moment";
import { toast } from "react-toastify";
import * as VehicleService from "../../service/VehicalService";

const VehicalInsuranceForm = (props) => {
  const { editForm, data, viewFlag, viewTerminateFlag } = props;
  const [file, setfiles] = useState([]);
  const [deleteattachment, setdeleteAttachment] = useState([]);
  const [Errorattachment, setErrorattachment] = useState("");
  const [attachmentError, setAttachmentError] = useState(true);
  const [attachmentTypeError, setattachmentTypeError] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    relation_type: Yup.string()
      .required("*Relation Type is required")
      .nullable(),
    vehicleNumber: Yup.string()
      .required("*Vehicle Number is required")
      .nullable(),
    VehicleOwnerName: Yup.string().when(["relation_type"], {
      is: (relation_type) => relation_type !== "Self" && relation_type !== "",
      then: Yup.string().required("*Vehicle Owner Name is required").nullable(),
    }),
    validFromDate: Yup.string()
      .required("*Valid From Date is required")
      .nullable(),
    validTillDate: Yup.string()
      .required("*Valid Till Date is required")
      .nullable(),
    policyNumber: Yup.string()
      .required("*Policy Number is required")
      .nullable(),
    premium: Yup.number().required("*Premium is required").nullable(),
    DeclareFlag: Yup.string().required("*Declaration is required").nullable(),
  });

  const validationSchemaInsu = Yup.object({
    relation_type: Yup.string()
      .required("*Relation Type is required")
      .nullable(),
    vehicleNumber: Yup.string()
      .required("*Vehicle Number is required")
      .nullable(),
    VehicleOwnerName: Yup.string().when(["relation_type"], {
      is: (relation_type) => relation_type !== "Self" && relation_type !== "",
      then: Yup.string().required("*Vehicle Owner Name is required").nullable(),
    }),
    validFromDate: Yup.string()
      .required("*Valid From Date is required")
      .nullable(),
    validTillDate: Yup.string()
      .required("*Valid Till Date is required")
      .nullable(),
    validity_terminated_on: Yup.string()
      .required("*Valid Terminated Date is required")
      .nullable(),
    policyNumber: Yup.string()
      .required("*Policy Number is required")
      .nullable(),
    premium: Yup.number().required("*Premium is required").nullable(),
  });

  const [VehicleData, setVehicleData] = useState({
    relation_type: "Self",
    vehicleNumber: "",
    policyNumber: "",
    premium: "",
    validFromDate: "",
    validTillDate: "",
    DeclareFlag: "",
    VehicleOwnerName: "",
    validity_terminated_on: undefined,
  });

  useEffect(() => {
    //edit data set
    if (editForm) {
      let data2 = data;
      Object.keys(data2).forEach((item) => {
        VehicleData[item] = data2[item];
      });
      setAttachment([data2?.attachment_path]);
    } else {
      setVehicleData({
        relation_type: "",
        vehicleNumber: "",
        policyNumber: "",
        premium: "",
        validFromDate: "",
        validTillDate: "",
        validity_terminated_on: undefined,
      });
    }
  }, [editForm]);

  const submitForm = (values) => {
    if (attachmentTypeError === "") {
      if (!editForm) {
        values.attachment_path = uploadDataIm;
        setIsLoading(true);
        VehicleService.VehicleInsurance(values)
          .then((response) => {
            setIsLoading(false);
            setfiles([]);
            setdeleteAttachment([]);
            props.onhide();
            props.getdata();
            toast.success("Record Saved Successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else if (viewTerminateFlag == true) {
        values.attachment_path = uploadDataIm;
        setIsLoading(true);
        VehicleService.VehicleInsuranceUpdateAll(values)
          .then((response) => {
            setIsLoading(false);
            setfiles([]);
            setdeleteAttachment([]);
            props.onhide();
            props.getdata();
            toast.success("Record Saved Successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else {
        values.attachment_path = uploadDataIm;
        setIsLoading(true);
        VehicleService.VehicleInsuranceUpdate(values)
          .then((response) => {
            setIsLoading(false);
            setfiles([]);
            setdeleteAttachment([]);
            props.onhide();
            props.getdata();
            toast.success("Record Saved Successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            setIsLoading(false);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };

  const onProfileChange = async (e) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      if (
        (data.type.split("/")[1] === "jpg" ||
          data.type.split("/")[1] === "jpeg" ||
          data.type.split("/")[1] === "png" ||
          data.type.split("/")[1] === "PNG" ||
          data.type.split("/")[1] === "gif" ||
          data.type.split("/")[1] === "pdf") &&
        data.size <= 2000000
      ) {
        setErrorattachment(false);
        const fileData = new FormData();
        fileData.append("uploaded_file", data);
        await uploadFile(fileData).then((response) => {
          let name = { name: data.name };
          filename.push(name);
          files.push(response.data);
        });
        setattachmentTypeError("");
      } else {
        setattachmentTypeError("kindly select correct file");
        setUploadDataIm(null);
      }
    }
    setAttachmentError(true);
    setAttachment([]);
    setUploadDataIm(files);
  };

  const deleteAttachment = (id) => {
    setattachmentTypeError("");

    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (editForm == false) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("attachment").value = "";

      setfiles(obj);
    } else {
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("attachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("attachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const ValidateAttachment = () => {
    if (!editForm) {
      if (uploadDataIm.length == 0) {
        setattachmentTypeError("*Attachment is required");
        return false;
      } else {
        if (attachment.length == 0) {
          setattachmentTypeError(
            attachment.length == 0 && uploadDataIm.length == 0
              ? "*Attachment is required"
              : ""
          );

          return false;
        }
      }
      return true;
    }
  };
  return (
    <>
      <Formik
        initialValues={VehicleData}
        validationSchema={
          viewTerminateFlag == true ? validationSchemaInsu : validationSchema
        }
        onSubmit={async (values) => {
          submitForm(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={props.open}
            onHide={props.onhide}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {editForm ? (viewFlag ? "View" : "Edit") : "Add"} Vehicle
                Insurance
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div class="row justify-content-center">
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              RELATION<span class="text-danger">*</span>
                            </label>
                            <select
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={values.relation_type}
                              name="relation_type"
                              onBlur={handleBlur}
                              class="form-control newbgselect"
                            >
                              <option value="Self">Self</option>
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Brother">Brother</option>
                              <option value="Sister">Sister</option>
                              <option value="Spouse">Spouse</option>
                            </select>
                            {touched.relation_type && errors.relation_type ? (
                              <div className="small text-danger">
                                {errors.relation_type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {values?.relation_type !== "" &&
                          values?.relation_type !== "Self" && (
                            <div class="col-md-6">
                              <div class="form-group innergroup">
                                <label>
                                  Vehicle Owner Name
                                  <span class="text-danger">*</span>
                                </label>
                                <input
                                  disabled={
                                    viewFlag == undefined
                                      ? viewTerminateFlag
                                      : viewFlag
                                  }
                                  type="text"
                                  class="form-control"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="VehicleOwnerName"
                                  placeholder="Enter Vehicle Owner Name"
                                  value={values.VehicleOwnerName}
                                />
                                {touched.VehicleOwnerName &&
                                errors.VehicleOwnerName ? (
                                  <div className="small text-danger">
                                    {errors.VehicleOwnerName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              VEHICLE NUMBER<span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              type="text"
                              class="form-control"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="vehicleNumber"
                              placeholder="Enter Vehicle Number"
                              value={values.vehicleNumber}
                            />
                            {touched.vehicleNumber && errors.vehicleNumber ? (
                              <div className="small text-danger">
                                {errors.vehicleNumber}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              POILICY NUMBER<span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              type="text"
                              class="form-control"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="policyNumber"
                              placeholder="Enter Policy Number"
                              value={values.policyNumber}
                            />
                            {touched.policyNumber && errors.policyNumber ? (
                              <div className="small text-danger">
                                {errors.policyNumber}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              PREMIUM<span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              type="number"
                              class="form-control"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              name="premium"
                              placeholder="Enter Premium"
                              value={values.premium}
                            />
                            {touched.premium && errors.premium ? (
                              <div className="small text-danger">
                                {errors.premium}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              VALID FROM DATE
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              name="validFromDate"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={moment(values.validFromDate).format(
                                "YYYY-MM-DD"
                              )}
                              type="date"
                              class="form-control"
                              placeholder="Placeholder"
                            />
                            {touched.validFromDate && errors.validFromDate && (
                              <div className="small text-danger">
                                {errors.validFromDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              VALID TILL DATE
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              name="validTillDate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="date"
                              class="form-control"
                              min={moment
                                .utc(values.validFromDate)
                                .days(moment(values.validFromDate).days() + 361)

                                .format("YYYY-MM-DD")}
                              placeholder="Placeholder"
                              value={moment(values.validTillDate).format(
                                "YYYY-MM-DD"
                              )}
                            />
                            {touched.validTillDate && errors.validTillDate && (
                              <div className="small text-danger">
                                {errors.validTillDate}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              ATTACHMENT
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              disabled={
                                viewFlag == undefined
                                  ? viewTerminateFlag
                                  : viewFlag
                              }
                              type="file"
                              id="attachment"
                              name="attachment"
                              accept="image/png, image/jpeg,image/gif,image/jpg"
                              onChange={(e) => onProfileChange(e, "attachment")}
                            />
                            {attachmentTypeError != "" && (
                              <div className="small text-danger">
                                {attachmentTypeError}
                              </div>
                            )}
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123()}</div>
                            ) : editForm ? (
                              <>
                                <div class="d-flex">
                                  {attachment?.map((Data) => (
                                    <div class="uploaded-image mr-1">
                                      <i class="" style={{ width: "20px" }}>
                                        <a
                                          href={Data}
                                          target={"_blank"}
                                          rel="noreferrer"
                                        >
                                          {Data.includes(".pdf") ? (
                                            <img
                                              src={"images/policy-icon/pdf.png"}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".xlsx") ? (
                                            <img
                                              src={
                                                "images/policy-icon/xlsx.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".xls") ? (
                                            <img
                                              src={
                                                "images/policy-icon/xlsx.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".doc") ? (
                                            <img
                                              src={
                                                "images/policy-icon/docs.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".docs") ? (
                                            <img
                                              src={
                                                "images/policy-icon/docs.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".pptx") ? (
                                            <img
                                              src={"images/policy-icon/ppt.png"}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : Data.includes(".mp4") ? (
                                            <img
                                              src={
                                                "images/policy-icon/video.png"
                                              }
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          ) : (
                                            <img
                                              src={Data}
                                              alt="image"
                                              width="70px"
                                              height="70px"
                                            />
                                          )}
                                        </a>
                                      </i>
                                      {viewFlag == undefined
                                        ? viewTerminateFlag
                                        : !viewFlag && (
                                            <button
                                              type="button"
                                              onClick={(e) => {
                                                deleteAttachment(
                                                  Data?.split("/")[4]
                                                );
                                              }}
                                              className="btn btn-blanktd text-danger ml-2"
                                            >
                                              <i className="far fa-trash-alt text-danger"></i>
                                            </button>
                                          )}
                                    </div>
                                  ))}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {viewTerminateFlag == true && (
                          <div class="col-md-6">
                            <div class="form-group innergroup">
                              <label>
                                Termination Date
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                name="validity_terminated_on"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={moment(
                                  values.validity_terminated_on
                                ).format("YYYY-MM-DD")}
                                type="date"
                                class="form-control"
                                placeholder="Placeholder"
                                min={values?.validFromDate}
                                max={values?.validTillDate}
                              />
                              {touched.validity_terminated_on &&
                                errors.validity_terminated_on && (
                                  <div className="small text-danger">
                                    {errors.validity_terminated_on}
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "65px" }} class="col-md-14">
                    <div class="form-group innergroup">
                      <label class="logCheck d-inline-block">
                        Declaration:-I do hereby solemnly affirm that the above
                        facts are true & this is for office/business purpose.
                        <input
                          disabled={
                            viewFlag == undefined ? viewTerminateFlag : viewFlag
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          class="form-control"
                          type="checkbox"
                          name="DeclareFlag"
                          checked={
                            viewFlag == undefined || viewFlag
                              ? true
                              : values?.DeclareFlag
                          }
                          value={values?.DeclareFlag}
                        />
                        {touched.DeclareFlag && errors.DeclareFlag && (
                          <div className="small text-danger">
                            {errors.DeclareFlag}
                          </div>
                        )}
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div className="small text-primary">
                      *Only .jpeg, .gif,.jpg, .png, and .pdf are allowed and
                      maximum upload size permitted is 2MB
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      {!viewFlag && (
                        <button
                          type="submit"
                          class="btn btn-primary-inner bpi-main"
                          onClick={() => ValidateAttachment()}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};
export default VehicalInsuranceForm;
