import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import ReactQuill from "react-quill";

const modules = {
  toolbar: false,
};

const ViewReimbursement = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Reimbursement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                {viewFormData?.reimburse_type === "1" && (
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Last Claimed<span className="text-danger">*</span>
                      </label>
                      <p>
                        {moment(viewFormData?.last_claimed).format(
                          "DD-MM-YYYY"
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Requested On<span className="text-danger">*</span>
                    </label>
                    <p>
                      {moment(viewFormData?.created_at).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Requested By<span className="text-danger">*</span>
                    </label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                {viewFormData?.reimburse_type === "1" ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Bill Date<span className="text-danger">*</span>
                        </label>
                        <p>
                          {moment(viewFormData?.bill_date).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Bill No.<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.bill_no}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Company Mobile<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.company_mobile}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Manufacturer<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.manufacturer}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          IMEI No.<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.imei_no}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          OS Ver.<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.os_ver}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Particulars<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.particulars}</p>
                      </div>
                    </div>
                  </>
                ) : viewFormData?.reimburse_type === "2" ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Marriage Date<span className="text-danger">*</span>
                        </label>
                        <p>
                          {moment(viewFormData?.marriage_date).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Marriage For<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.marriage_for}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          First Name<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.first_name}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.last_name}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          Entitlement Amount
                          <span className="text-danger">*</span>
                        </label>
                        <p>{viewFormData?.entitlement_amount}</p>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {viewFormData?.status === "A" ||
                  (viewFormData?.status === "D" && (
                    <>
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Action By<span className="text-danger">*</span>
                          </label>
                          <p>{viewFormData?.action_by}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Action At<span className="text-danger">*</span>
                          </label>
                          <p>
                            {moment(viewFormData?.action_at).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Remarks<span className="text-danger">*</span>
                          </label>
                          <p>{viewFormData?.remarks}</p>
                        </div>
                      </div>
                    </>
                  ))}
                {viewFormData?.status === "A" && (
                  <>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          SAP No.<span className="text-danger">*</span>
                        </label>
                        <p>
                          {viewFormData?.sap_no !== null
                            ? viewFormData?.sap_no
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          SAP Status<span className="text-danger">*</span>
                        </label>
                        <p>
                          {viewFormData?.sap_status !== null
                            ? viewFormData?.sap_status
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group innergroup">
                        <label>
                          SAP Error<span className="text-danger">*</span>
                        </label>
                        <p>
                          {viewFormData?.sap_error !== null
                            ? viewFormData?.sap_error
                            : ""}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Download Attachments</label>
                    <p>
                      {viewFormData?.attachment !== undefined &&
                        viewFormData?.attachment.map((a) => (
                          <a
                            href={a?.attachment_path}
                            target="_blank"
                            rel="noreferrer"
                            zIndex="100"
                          >
                            <span class="mr-3">
                              {a?.attachment_path
                                .split("/")
                                .pop()
                                .includes(".pdf") ? (
                                <img
                                  src={"/images/policy-icon/pdf.png"}
                                  alt="image"
                                  width="40px"
                                  height="40px"
                                />
                              ) : a?.attachment_path
                                  .split("/")
                                  .pop()
                                  .includes(".mp4") ? (
                                <img
                                  src={"/images/policy-icon/video.png"}
                                  alt="image"
                                  width="40px"
                                  height="40px"
                                />
                              ) : (
                                <img
                                  src={"/images/policy-icon/img.png"}
                                  alt="image"
                                  width="40px"
                                  height="40px"
                                />
                              )}
                            </span>
                          </a>
                        ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewReimbursement;
