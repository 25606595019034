import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Modal, Tabs, Tab, Dropdown,Button } from "react-bootstrap";
import Select from "react-select";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment/moment";
import * as UserService from "../../service/userService"
import * as OrganisationService from "../../service/organisation"
import { toast } from "react-toastify";
import { useDispatch,useSelector } from "react-redux";

import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData
} from "../redux/piDataStore";

function Traveldiscardtopicklist() {
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const finalUser = useSelector(selectUserData);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [expenseList, setExpenseList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [totalRecord, setTotalRecord] = useState();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [organisation_User, setOrganisation_User] = useState({
    company:"",
    user_id:""
  });

  const [filterdata, setfilterData] = useState({
    user_id: "",
    company: "",
    trip_number: "",
    from_date: "",
    to_date: "",
    
    
  });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1
    setCurrentPage(activePage)
    under_process_list(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
       "","","","","",false
    )
  };


  

  const under_process_list = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering
  ) => {
    TravelListingAPI.getDiscardedTravelExp(
      handleKey(search, page_no, page_size, sort_by, paginate, status, 
        user_id,
        company,
        trip_number,
        from_date,
        to_date,
        isFiltering)
    ).then((response) => {
      // dispatch(setDisplayLoader("Hide"));
      setExpenseList(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
    });
  };

  const [newSSCUser,setnewSSCUser]=useState({
    id:"",
    username:""
})

  const traveldiscardToPickList=async (action="")=>{

      
    TravelListingAPI
      .traveldiscardToPickList(newSSCUser.id, newSSCUser.username,action)
      .then((res) => {
        under_process_list(
          searchStr,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          "",
          filterdata?.user_id,
          filterdata?.company,
          filterdata?.trip_number,
          filterdata?.from_date,
          filterdata?.to_date,
          true
          
          )
          
        toast.success(`Assigned successfully.`);
        setnewSSCUser({
          id: "",
          username: "",
        });
        

        handleClose();
      })
      .catch((err) => {
        toast.error(`Unable to reassign`);
      });


}

function getTravelChat(id) {
  TravelListingAPI.getTravelChat(id).then((response) => {
    console.log("get chat for id=>", response);

    console.log("chat->", response?.data?.dataList?.result?.length);
    setTravelChats(response?.data?.dataList?.result);
    // set_travelPostData({
    //   ...travelPostData,
    //   travel_id: id,
    // });
    // return response?.data?.dataList?.result?.length

    // setChatStatus(response?.data?.dataList?.result?.length)

    // setChatStatus({
    //   ...chatStatus,
    //   id:response?.data?.dataList?.result?.length==0
    // })
  });
}

const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalState, setModalState] = useState(null);
  const [travelchats, setTravelChats] = useState([]);

const handleFileView = (data) =>{
  // setFileObj(data)
  setnewSSCUser({
    ...newSSCUser,
    id:data?.travel_intimation_id
})
  handleShow()
}

const handleShowModalTwo = (id) => {
  setModalState(`query-modal-${id}`);
};

function handleCLOSE2() {
  setModalState("close");
}

const calculateDelayDeductionPercent = (calcDaya,traveluserDetails) => {

  if(traveluserDetails?.other_details?.date_of_joining){

  let userr = traveluserDetails?.other_details

  let claimDaysFromJoining = moment(traveluserDetails?.dispatch_date).diff(moment(userr?.date_of_joining, "YYYY-MM-DD"), "days")
  let days = calcDaya + " Days";
  let deductedPercent = 100;
  console.log(`....`, days, claimDaysFromJoining);

  if (claimDaysFromJoining < 61) {
    days = '0 Days';
  }
  if (['D1', 'D2', 'C1'].includes(userr?.emp_sub_group) || userr?.sales_dist == 'CONS') {
    days = '0 Days';
  }
  days = days.split(" ");
  if (days[0] <= 7) {
    deductedPercent = 0;
  } else if (days[0] >= 8 && days[0] <= 20) {
    deductedPercent = 5;
  }
  console.log(`ded per`,deductedPercent);
  return deductedPercent;}
  else{
    return 0;
  }

}



  useEffect(() => {
    getUsersList()
    getAllOrganisation()

    console.log(`....${finalUser}`)
    console.log(`....${finalUser.designation_name}`)
    
    under_process_list(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
       "","","","","",false
    );
  }, []);

  const handleKey = (search, page_no, page_size, sort_by, paginate, status, 
    user_id,
    company,
    trip_number,
    from_date,
    to_date,
    isFiltering) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate+
      "&isFiltering=" +
      isFiltering;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }


    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&company=" + company;
    }
    if (trip_number !== "" && trip_number !== undefined && trip_number !== null) {
      queryParm = queryParm + "&trip_number=" + trip_number;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    

    return queryParm;
  };

  



  const getUsersList = () => {
    UserService.getUsersListDataNew(0).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} ( ${x.username} )`,
          };
        }),
      ];
      setUsersDropdown(data);
      

      


    });
  };



  const handleSearch = (e) => {
    
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      
      
  under_process_list(
    e.target.value,
    currentPage,
    pageSizeNo,
    sortData.sort_by,
    true,
    "",
    filterdata?.user_id,
    filterdata?.company,
    filterdata?.trip_number,
    filterdata?.from_date,
    filterdata?.to_date,
    false 
    
    )

    
    setSearchStr(e.target.value);
    }
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
      

      


    });
  };


  const setCompantAndUser=(comp,uid)=>{
    console.log(`setting`,comp,uid);

    if (comp){
      let mdoUser=companyDropdown.filter((x) => {
        return x.value == comp
        
      }) 
      // console.log('comp..',mdoUser);

      setOrganisation_User({
        ...organisation_User,
        company:mdoUser
      })

      setfilterData({
        ...filterdata,
        company: comp,
      })
      
    }

    if (uid){
      let mdoUser=usersDropdown.filter((x) => {
        return x.value == uid
        
      }) 
      // console.log('uid..',mdoUser);

      setOrganisation_User({
        ...organisation_User,
        user_id:mdoUser
      })
      setfilterData({
        ...filterdata,
        user_id: uid,
      })
    }

  }



  
 const mainDataFilter = () => {
  console.log(" filterdata:", filterdata);
  
  under_process_list(
    searchStr,
    currentPage,
    pageSizeNo,
    sortData.sort_by,
    true,
    "",
    filterdata?.user_id,
    filterdata?.company,
    filterdata?.trip_number,
    filterdata?.from_date,
    filterdata?.to_date,
    true
    
    )

  filterclose();
};


const clearfilter = () => {
  filterclose();
  under_process_list(
    searchStr,
    currentPage,
    pageSizeNo,
    sortData.sort_by,
    true,
    "",
     "","","","","",false
  );
  setfilterData({
    user_id: "",
    from_date: "",
    to_date: "",
    company: "",
    trip_number:""
  });
  setOrganisation_User({
    user_id:"",
    company:""
  })
};





  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(expenseList));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setExpenseList(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.map((res) => {
        if (res?.id != data?.id) {
          return res;
        }
      });
      setPostingData(obj);
      setExpenseList(copyData1);
    }
  };

 


  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(expenseList));
    if (e.target.checked === true) {
      data?.map((res) => {
        res.checked = "Y";
        postingData.push(res);
      });
      setExpenseList(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setExpenseList(data);
      let obj = postingData?.map((x) => {
        if (x?.id != data?.id) {
          return x;
        }
      });
      setPostingData(obj);
    }
  };

  const getExportValue = (
    search,
    page_no,
    page_Size,
    sort_by,
    paginate,
    status
  ) => {
    // dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getExportValue(
      handleKey(search, page_no, page_Size, sort_by, paginate, status)
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TravelPick.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };




  useEffect(() => {
    under_process_list(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "",
       "","","","","",false
    );
  }, [pageSizeNo]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li >                <Link to="/travel-pick">Travel Expense Pick List</Link>              </li>
              <li class="active">                <Link to="/under_process_list">Under process List</Link>              </li>
              <li>                <Link to="/travel-expense">Check Expense</Link>              </li>
              <li >                <Link to="/travel-approve-expense">Approve Travel Expense</Link>              </li>
              <li>                <Link to="/travel-bills">All Bills</Link>              </li>
              <li>                <Link to="/travel-processed-ta">Processed TA</Link>              </li>
              <li>                <Link to="/travel-km-report">KM Report</Link>              </li>
              <li>                <Link to="/travel-intimat-report">                  Travel Intimation Report </Link>   </li>
              <li>                <Link to="/travel-prov-report">Provision Report</Link>              </li>
              <li>                <Link to="/travel-deviat-report">Deviation Report</Link>              </li>
              <li>                <Link to="/travel-hotel-exp-report">Hotel Expense Report</Link>              </li>
              <li>                <Link to="/travel-fcm-exp-report">                  FCM Ticket Expense Report      </Link>       </li>
              <li>                <Link to="/travel-employ-report">Employee List Report</Link>              </li>
            </ul>
          </div> */}

          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Travel discard to pick list</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={expenseList?.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportValue(
                                  searchStr,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  "P"
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>SR NO.</th>
                            {/* <th>
                              ACTION
                            </th> */}
                            <th>EMPLOYEE ID</th>
                            <th>NAME</th>
                            <th>TRIP NO</th>
                            <th>QUERY</th>
                            <th>TRIP START DATE</th>
                            <th>TRIP END DATE</th>
                            <th>DOC DISP. DATE</th>
                            <th>DOC REC. DATE</th>
                            {/* <th>DOCKET NO</th> */}
                            {/* <th>DED DAYS</th>
                            <th>DELAY DAYS</th> */}
                            {/* <th>PENDING AT</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {expenseList?.length > 0 ? (
                            expenseList?.map((data, index) => {
                              return (
                                <tr>
                                  { (
                                <Modal
                                  show={modalState === `query-modal-${data?.id}`}
                                  // onHide={addnewclose}
                                  onHide={handleCLOSE2}
                                  onShow={() => getTravelChat(data?.id)}
                                  backdrop="static"
                                  keyboard={false}
                                  size="lg"
                                  className="modaldefaultclose modaldefaultclosecenter"
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Query to Manager</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body
                                    style={{
                                      maxHeight: "calc(100vh - 210px)",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <div class="row justify-content-center">
                                      <div class="col-md-11">
                                        <div class="row chtgptbox agent">
                                          {/* {travelchats?.length
                                          ? travelchats.map((cdata, idx) => (
                                              <div
                                                class={
                                                  cdata?.created_by_id !=
                                                  userDetails?.id
                                                    ? "col-md-12"
                                                    : "col-md-12 text-right"
                                                }
                                              >
                                                <div class="ticketreplytxt rounded text-break">
                                                  {cdata?.attachment && (
                                                    <a href={cdata?.attachment}>
                                                      <i class="far fa-file-pdf text-theme"></i>
                                                    </a>
                                                  )}
                                                  {cdata?.message}
                                                </div>
                                                <div class="mt-2">
                                                  <h6>
                                                    {moment
                                                      .parseZone(
                                                        cdata?.created_at
                                                      )
                                                      .format(
                                                        "DD-MM-YYYY hh:mm A"
                                                      )}
                                                  </h6>
                                                </div>
                                              </div>
                                            ))
                                          : ""} */}

                                          {travelchats?.length
                                            ? travelchats.map((cdata, idx) =>
                                              // <div class={ cdata?.created_by_id != userDetails?.id ? "col-md-12" : "col-md-12 text-right"}>
                                              //   <div class="ticketreplytxt rounded text-break">
                                              //   {cdata?.attachment && <a href={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></a>}
                                              //    {cdata?.message}

                                              //   </div>
                                              //   <div class="mt-2">
                                              //     <h6>{moment.parseZone(cdata?.created_at).format("DD-MM-YYYY hh:mm A")}</h6>
                                              //   </div>
                                              // </div>

                                              cdata?.created_by_id !=
                                                cdata?.travel_created_by_id ? (
                                                <div
                                                  style={{
                                                    borderRight:
                                                      "solid 2px #2e6da4",
                                                  }}
                                                  class={
                                                    "mb-3 col-md-12 text-right"
                                                  }
                                                >
                                                  <b>SSC</b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {cdata?.attachment && (
                                                      <Link
                                                        target="_blank"
                                                        to={cdata?.attachment}
                                                      >
                                                        <i class="far fa-file-pdf text-theme"></i>
                                                      </Link>
                                                    )}
                                                    {cdata?.attachment_list?.map((attdata, aidx) => (
                                                      <Link target="_blank" class="ml-2" to={attdata?.attachment_path}><i class="fas fa-paperclip text-theme"></i></Link>
                                                    ))}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "solid 2px #eea236",
                                                  }}
                                                  class={
                                                    "mb-3 col-md-12 text-left "
                                                  }
                                                >
                                                  <b>User</b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {cdata?.attachment && (
                                                      <Link
                                                        target="_blank"
                                                        to={cdata?.attachment}
                                                      >
                                                        <i class="far fa-file-pdf text-theme"></i>
                                                      </Link>
                                                    )}
                                                    {cdata?.attachment_list?.map((attdata, aidx) => (
                                                      <Link target="_blank" class="ml-2" to={attdata?.attachment_path}><i class="fas fa-paperclip text-theme"></i></Link>
                                                    ))}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              )
                                            )
                                            : ""}

                                          {finalUser.groups.map((data, idx) => (
                                            data.name.toLowerCase() == "ssc user" && (<><div class="col-md-12">
                                              <div class="border my-3"></div>
                                            </div>
                                              <div class="col-md-6">
                                                <div class="form-group innergroup position-relative modaldefaultclose ">
                                                  <label>Upload files</label>
                                                  <input
                                                    type="text"
                                                    class="form-control bg-white"
                                                    disabled
                                                    placeholder="(JPG, PNG Format only)"
                                                  />
                                                  <div class="upload-btn-wrapper up-loposition">
                                                    <button class="uploadBtn">
                                                      Browse
                                                    </button>
                                                    <input
                                                      type="file"
                                                      // onChange={(e) =>
                                                      //   onProfileChange(
                                                      //     e,
                                                      //     "import_user"
                                                      //   )
                                                      // }
                                                    />
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="col-md-12">
                                                <div class="form-group innergroup">
                                                  <label>
                                                    Comment{" "}
                                                    <span class="text-danger">*</span>
                                                  </label>
                                                  <textarea
                                                    id="querytext"
                                                    class="form-control"
                                                    placeholder="Enter Comment"
                                                    // onChange={(e) =>
                                                    //   set_travelPostData({
                                                    //     ...travelPostData,
                                                    //     message: e.target.value,
                                                    //   })
                                                    // }
                                                  ></textarea>
                                                </div>
                                              </div></>)))}
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  {finalUser.groups.map((data, idx) => (
                                    data.name.toLowerCase() == "ssc user" && (<Modal.Footer>
                                      <button
                                        class="btn btn-outline-danger"
                                        // onClick={addnewclose}
                                        onClick={handleCLOSE2}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        class="btn btn-primary-inner bpi-main"
                                        // onClick={postTravelChat}
                                      >
                                        Submit
                                      </button>
                                    </Modal.Footer>)))}
                                </Modal>
                              )}
                                
                                  <td>{index + 1}</td>
                                  {/* <td>
                                                                    <Link to="/travel-check-expense" state={{travel_id:data?.id, user_id:data?.picked_ssc_id}}><button class="btn btn-primary-inner bpi-main px-2 mr-2 py-1 f-14"><i class="far fa-check-circle"></i> Check</button></Link>
                                                                    <Link to="/travel-view-list"><button class="btn btn-blanktd text-primary"><i class="fas fa-print"></i></button></Link>
                                  </td> */}
                                
                                  <td>{data?.requestor_code}</td>
                                  <td>{data?.requestor_name}</td>
                                  <td><button onClick={()=>{handleFileView(data)}} class="btn border-dark btn-light ">Revert</button>{data?.sap_trip_numberr}</td>
                                  <td>
                                    {data?.chat_status > 0 && (
                                    <button
                                      class="btn btn-primary bpi-main px-3 py-1 f-14"
                                      style={{ marginLeft: "3px" }}
                                      onClick={() =>
                                        handleShowModalTwo(`${data?.id}`)
                                      }
                                    >
                                      Query
                                    </button>
                                  )}
                                  </td>
                                  <td>{moment.parseZone(data?.from_date).format("DD-MM-YYYY")}</td>
                                  <td>{moment.parseZone(data?.to_date).format("DD-MM-YYYY")}</td>
                                  <td>{data?.document_dispatch_date ? moment.parseZone(data?.document_dispatch_date).format("DD-MM-YYYY"):""}</td>
                                  <td>{data?.received_on ?moment.parseZone(data?.received_on).format("DD-MM-YYYY"):""}</td>
                                  {/* <td>
                                    {data?.docket_number}
                                  </td>
                                  <td>
                                  {data?.document_dispatch_date && moment(data?.document_dispatch_date).diff(moment(data?.to_date, "YYYY-MM-DD"), "days")+" Days "+ " (" +calculateDelayDeductionPercent(moment(data?.document_dispatch_date).diff(moment(moment(data?.to_date, "YYYY-MM-DD"), "YYYY-MM-DD"), "days"),data)+"%)"}
                                    </td>
                                  <td>

                                  {moment(data?.received_on).diff(moment(data?.document_dispatch_date, "YYYY-MM-DD"), "days")+" Days "}
                                  
                                  </td>
                                  <td>{data?.emp_name}</td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>No Records Found...</td>
                            </tr>
                          )}
                            <Modal  show={shows} onHide={handleClose}>
                                                            <Modal.Header
                                                            style={{"background-color":"#0195d4"}}
                                                            className="text-white" closeButton>
                                                            <Modal.Title  >Prompt</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                            <div class="row">

                                                                <div class="col-sm-12">
                                                                    <p>Are you sure to revert this expense to pick list ?</p>
                                                                   
                                                                </div>        
                                                                    
                                                            </div>    
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                            
                                                            <Button
                                                            style={{"background-color":"#0195d4"}}
                                                            class="btn "
                                                            onClick={()=>traveldiscardToPickList()}>
                                                                Ok
                                                            </Button>
                                                            <Button 
                                                            style={{"background-color":"#0195d4"}}
                                                            class="btn"
                                                            onClick={handleClose}>
                                                                Cancel
                                                            </Button>
                                                            </Modal.Footer>
                                                        </Modal>

                              
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            className="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                    {/* <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          
                          
                          { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc manager" &&<button
                            class="btn btn-primary-inner"
                            type="button"
                            // onClick={() => onDecline()}
                          >
                            <i class="far fa-check-circle"></i> Decline
                          </button>
                          )) }


                          { finalUser.groups.map((data,idx)=>(
                            data.name.toLowerCase()=="ssc user" &&<button
                            class="btn btn-primary-inner"
                            type="button"
                            onClick={() => onReceive()}
                          >
                            <i class="far fa-check-circle"></i> Receive
                          </button>
                          )) }


                          
                        </div>
                      </div>
                    </div> */}
                  
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>


            <Select
              // options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(e) =>

              //  setCompantAndUser(e.value,"")
              // }
              // value={organisation_User.company}
            />
           
          </div>

          <div class="form-group innergroup">
            <label>
              Employee Code <span class="text-danger"></span>
            </label>
            <Select
              // options={usersDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(e) =>

              //   setCompantAndUser("",e.value)

                
              // }
              // value={organisation_User?.user_id}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Trip Number <span class="text-danger"></span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(e)=>setfilterData({
              //   ...filterdata,
              //   trip_number:e.value
              // })}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              Start Date <span class="text-danger"></span>
            </label>
            <input type="date" class="form-control"
            onChange={(e)=>setfilterData({
              ...filterdata,
              from_date:e.currentTarget.value
            })}
            value={filterdata.from_date}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              End Date <span class="text-danger"></span>
            </label>
            <input type="date" class="form-control"
            onChange={(e)=>setfilterData({
              ...filterdata,
              to_date:e.currentTarget.value

            })}
            value={filterdata.to_date}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" 
           onClick={() => clearfilter()}
          //  onClick={filterclose}
           >
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main"
          onClick={() => mainDataFilter()}
          >Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Traveldiscardtopicklist;
