import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Root.module.css";
import {
  getRegionDropDown,
  getTerritoryDropDown,
  getZoneDropDown,
} from "../../../../service/AgriPortalService/CommonService";
import { getTerritory } from "../../../../service/AgriPortalService/MarketSizeService";
import { getMajorProduct } from "../../../../service/AgriPortalService/MajorProductService";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

const Root = (props) => {
  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //To contain territory dropdown state
  const [territory, setTerritory] = useState();
  const [territories, setTerritories] = useState();

  //To contain market player data
  const [marketSizeData, setMarketSizeData] = useState([]);
  const [majorProductSelectedData, setMajorProductSelectedData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showAddNew, setShowAddNew] = useState(true);
  const [selectedZone, setSelectedZone] = useState("ALL");
  const [selectedRegion, setSelectedRegion] = useState("ALL");
  const [selectedTerritory, setSelectedTerritory] = useState("ALL");
  const [cfy, setCfy] = useState("");
  useEffect(() => {
    localStorage.setItem("territory", JSON.stringify(territory));
    getCurrentFy()
      .then((res) => setCfy(res.data.dataList.fy))
      .catch((err) => console.log(err));
  }, [territory]);

  //Load territory dropdown data
  useEffect(() => {
    getTerritory()
      .then((res) => {
        if (!res.data.dataList || res.data.dataList.length == 0) return;

        setTerritory(res.data.dataList[0]);
        setTerritories(res.data.dataList);
      })
      .catch((err) => {
        toast.error("Fetching territory failed!");
        console.log(err);
      });
  }, []);

  //Load market player data
  //Store territory to local storage
  useEffect(() => {
    getMajorProduct()
      .then((res) => {
        setMarketSizeData(res.data.dataList);
        getCurrentFy().then((r) => {
          setLoader(false);
          let flag = true;

          for (let i = 0; i < res.data.dataList.length; i++) {
            if (res.data.dataList[i].cfy == r.data.dataList.fy) {
              flag = false;
              break;
            }
          }

          setShowAddNew(flag);
        });
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Fetching Major Product failed!");
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getZoneDropDown(false)
      .then((res) => {
        setZoneData((state) => {
          let newData = [
            {
              name: "All",
              code: "ALL",
              id: "ALL",
            },
          ];
          newData = [...newData, ...res.data.dataList];
          setSelectedZone(newData[0].id);
          return newData;
        });
        regionChangeHandler(-1);
      })
      .catch((err) => {});
  }, [marketSizeData, marketSizeData.length]);

  const zoneChangeHandler = (e) => {
    if (e.target.value == "ALL") {
      setSelectedZone("ALL");
      setSelectedRegion("ALL");
      setSelectedTerritory("ALL");
      setRegionData([
        {
          name: "All",
          id: "ALL",
          code: "ALL",
        },
      ]);
    } else {
      setSelectedZone(e.target.value);
      getRegionDropDown(e.target.value)
        .then((res) => {
          setRegionData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                id: "ALL",
                code: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            setSelectedRegion(newData[0].id);
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const regionChangeHandler = (e) => {
    setSelectedTerritory("ALL");
    if (e == -1) {
      setRegionData([
        {
          name: "All",
          code: "ALL",
          id: "ALL",
        },
      ]);
      setSelectedRegion("ALL");
      setTerritoryData((state) => {
        let newData = [...state];
        newData = [
          {
            name: "All",
            code: "ALL",
            id: "ALL",
          },
        ];
        return newData;
      });
      territoryChangeHandler("ALL");
    } else {
      setSelectedRegion(e.target.value);
      getTerritoryDropDown(e.target.value)
        .then((res) => {
          console.log("territory", res.data.dataList, e.target.id);
          setTerritoryData((state) => {
            let newData = [...state];
            newData = [
              {
                name: "All",
                code: "ALL",
                id: "ALL",
              },
            ];
            newData = [...newData, ...res.data.dataList];
            return newData;
          });
          territoryChangeHandler("ALL");
        })
        .catch((err) => {
          console.log("ZONE", err);
        });
    }
  };

  const territoryChangeHandler = (code) => {
    setSelectedTerritory(code);
    const zone =
      zoneData[zoneData.findIndex((el) => el.id == selectedZone)]?.code;
    const region =
      regionData[regionData.findIndex((el) => el.id == selectedRegion)]?.code;
    console.log("TERRITORY", code, zone, region);
  };

  useEffect(() => {
    if (selectedZone == "ALL") {
      console.log("selectedZone", marketSizeData);
      setMajorProductSelectedData(marketSizeData);
    } else if (selectedZone != "ALL" && selectedRegion == "ALL") {
      setMajorProductSelectedData((state) => {
        let newData = [...state];
        let code = zoneData.findIndex((el) => el.id == selectedZone);
        newData = marketSizeData.filter(
          (el) => el.zone_code == zoneData[code].code
        );
        console.log("selectedRegion", newData, marketSizeData, selectedZone);
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory == "ALL"
    ) {
      setMajorProductSelectedData((state) => {
        let newData = [...state];
        let code = regionData.findIndex((el) => el.id == selectedRegion);
        newData = marketSizeData.filter(
          (el) => el.region_code == regionData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          marketSizeData,
          selectedRegion
        );
        return newData;
      });
    } else if (
      selectedZone != "ALL" &&
      selectedRegion != "ALL" &&
      selectedTerritory != "ALL"
    ) {
      setMajorProductSelectedData((state) => {
        let newData = [...state];
        console.log(territoryData, selectedTerritory);
        let code = territoryData.findIndex(
          (el) => el.code == selectedTerritory
        );
        console.log(code);
        if (code == -1) return [];
        newData = marketSizeData.filter(
          (el) => el.territory_code == territoryData[code].code
        );
        console.log(
          "selectedTerritory",
          newData,
          marketSizeData,
          selectedRegion
        );
        return newData;
      });
    }
  }, [selectedZone, selectedRegion, selectedTerritory, marketSizeData]);

  useEffect(() => {
    console.log("DATA", majorProductSelectedData);
  }, [majorProductSelectedData, majorProductSelectedData.length]);

  function handleLoading() {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
      if (majorProductSelectedData.length == 0) {
        return (
          <div className={styles["nodata"]}>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              NO DATA YET! PLEASE ADD
            </h4>
          </div>
        );
      }
    }
  }

  //MARKUP JSX
  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["agri-portal-controls"]}
          style={{ paddingBottom: "0" }}
        >
          <div>
            <h4 class="inner-page-title m-0 " style={{ margin: "0" }}>
              Major Products
            </h4>
          </div>
          <div>
            <div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>CURRENT FY</b>
                </div>
                <div className={styles["valuebox"]}>{cfy}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div className={styles["agri-portal-controls"]}>
          <div style={{ display: "flex", gap: "10px" }}>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
              }}
              value={selectedZone}
              // classNamePrefix="select"
              onChange={(e) => {
                zoneChangeHandler(e);
              }}
            >
              {zoneData &&
                zoneData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedZone == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedZone == "ALL"}
              // classNamePrefix="select"
              onChange={(e) => {
                regionChangeHandler(e);
              }}
            >
              {regionData &&
                regionData?.map((el) => {
                  return <option value={el.id} label={el.name} />;
                })}
            </select>
            <select
              className="form-control newbgselect"
              name="business_unit_id"
              style={{
                cursor: "pointer",
                border: "1px solid #0195D4",
                color: "#0195DA",
                minWidth: "120px",
                opacity: selectedRegion == "ALL" ? 0.5 : 1,
              }}
              disabled={selectedRegion == "ALL"}
              value={selectedTerritory}
              // classNamePrefix="select"
              onChange={(e) => {
                territoryChangeHandler(e.target.value);
              }}
            >
              {territoryData &&
                territoryData?.map((el) => {
                  return <option value={el.code} label={el.name} />;
                })}
            </select>
          </div>

          {showAddNew && (
            <div className={styles["right"]}>
              <div>
                <Link
                  to="/mymarket/majorproducts/table?mode=create"
                  class="btn btn-primary-inner"
                >
                  Add New
                </Link>
              </div>
            </div>
          )}
        </div>

        {majorProductSelectedData.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Territory Code</th>
                      <th>Territory Name</th>
                      <th>Submitted On</th>
                      <th>Submitted By</th>
                      <th>Financial Year</th>
                      <th>Status</th>
                      <th>Remarks</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {majorProductSelectedData &&
                      majorProductSelectedData.map((el) => {
                        let status;

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P")
                          status = `Pending with ${el.pending_with}(${el.pending_at})`;
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr
                            style={{
                              backgroundColor: el.for_my_approval
                                ? "#92ff40"
                                : "",
                              fontWeight: el.for_my_approval ? "bold" : "",
                            }}
                          >
                            <td>{el.territory_code}</td>
                            <td>{el.territory_name}</td>
                            <td>
                              {moment(el.created_at).format("DD-MM-YYYY")}
                            </td>
                            <td>{el.created_by_name}</td>
                            <td>{el.cfy}</td>
                            <td>{status}</td>
                            <td>-</td>
                            {(el.status == "D" || el.status == "RP" || el.status == "R") && (
                                <td
                                  className={styles["bold"]}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    navigate(
                                      "/mymarket/majorproducts/table?mode=edit&id=" +
                                        el.id +
                                        "&fy=" +
                                        el.cfy
                                    )
                                  }
                                >
                                  Edit
                                </td>
                              )}
                            {(el.status == "P" ||
                              el.status == "A") && (
                              <td
                                className={styles["bold"]}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  navigate(
                                    "/mymarket/majorproducts/table?mode=view&id=" +
                                      el.id +
                                      "&fy=" +
                                      el.cfy
                                  )
                                }
                              >
                                View
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    {/* <tr>
                                    <td>T1</td>
                                    <td>T1</td>
                                    <td>1-March-2022</td>
                                    <td>Raj</td>
                                    <td>FY 22-23</td>
                                    <td>Approved</td>
                                    <td>All good</td>
                                    <td>View</td>
                                </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {handleLoading()}
      </div>
    </div>
  );
};
export default Root;
