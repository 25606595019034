import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import * as moment from "moment";
import ApplyLeave from "./ApplyLeave";
import * as leaveDataService from "../../service/leaveService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectUserData } from "../redux/piDataStore";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getAllUserDataNew } from "../../service/AttendanceService";
import { Badge } from "react-bootstrap";
import * as EmpService from "../../service/employee";
import * as UserService from "../../service/userService";
import { get_sap_schedule } from "../../service/searchpo";

function LeaveReport({ bhr }) {
  const dispatch = useDispatch();
  const leaveStatus = [
    {
      label: "Pending",
      value: "P",
    },
    {
      label: "Approved",
      value: "A",
    },
    {
      label: "Rejected",
      value: "D",
    },
    {
      label: "Cancelled",
      value: "C",
    },
  ];

  let month_start = `${moment(new Date()).format("YYYY")}-${moment(new Date())
    .subtract(1, "month")
    .format("MM")}-20`;
  let month_end = `${moment(new Date()).format("YYYY")}-${moment(
    new Date()
  ).format("MM")}-22`;

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [leaveMgmtData, setLeaveMgmtData] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [leave, setLeave] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaveList, setleaveList] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [dataMList, setDataMList] = useState([]);
  const [filterStatus, setfilterStatus] = useState("");
  const [managementLeaveList, setManagementLeaveList] = useState([]);
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [psaVal, setPsaVal] = useState([]);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-from_date",
  });
  const [lastAppliedLeave, setLastAppliedLeave] = useState(null);
  const [mandatoryHolidays, setMandatoryHolidays] = useState([]);
  const [userWeekOff, setUserWeekOff] = useState(null);
  const [userBackDate, setUserBackDate] = useState([]);
  const [backDateForm, setBackDateForm] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [allowBackDate, setAllowBackDate] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [filterApprover, setFilterApprover] = useState("");
  const [filterRequester, setFilterRequester] = useState("");
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  const [filterValue, setFilterValue] = useState({
    leave_type_id: "",
    from_date: "",
    to_date: "",
    status: "",
    approver: "",
    requester: "",
  });
  const [leaveFilterValue, setLeaveFilterValue] = useState({
    leave_type_id: "",
    from_date: new Date().getDate() > 21
    ? moment(new Date()).month() === 0 ? `${moment(new Date()).format('YYYY')}-${moment(new Date()).format('MM')}-01`
      : `${moment(new Date()).format("YYYY")}-${moment(new Date())
        .subtract(0, "month")
        .format("MM")}-21`
    : moment(new Date()).month() === 0 ? `${moment(new Date()).format('YYYY')}-${moment(new Date()).format('MM')}-01`
      : `${moment(new Date()).format("YYYY")}-${moment(new Date())
        .subtract(1, "month")
        .format("MM")}-21`,
    to_date: `${moment(new Date()).format("YYYY-MM-DD")}`,
    status: "",
    approver: "",
    requester: "",
    psa_id: [],
    business_units_id: "",
  });

  const userData = useSelector(selectUserData);
  const getLeaveDataList = (
    search,
    leave_type_id,
    from_date,
    to_date,
    approver,
    requester,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    psa_id,
    business_units_id
  ) => {
    leaveDataService
      .getLeaveDataList(
        handleKey(
          search,
          leave_type_id,
          from_date,
          to_date,
          approver,
          requester,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          psa_id,
          business_units_id
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let totalLeaves = [];
        totalLeaves.push(...response?.data?.dataList?.pending);
        totalLeaves.push(...response?.data?.dataList?.approved);
        totalLeaves.push(...response?.data?.dataList?.rejected);
        totalLeaves.push(...response?.data?.dataList?.cancelled);
        setLeaveMgmtData(totalLeaves);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        getLeaveManageDataList(userData?.id);
      })
      .catch((error) => {
        console.log(error);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLeaveManageDataList = (id) => {
    leaveDataService
      .getLeaveManageData(`$paginate=${false}`)
      .then((response) => {
        setleaveList(response?.data?.dataList?.result);
        let leaveM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = {
            value: value.id,
            label: value.name,
          };
          leaveM.push(data);
        });
        setManagementLeaveList(leaveM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportValue = (
    search,
    leave_type_id,
    from_date,
    to_date,
    approver,
    requester,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    
    dispatch(setDisplayLoader("Display"));
    leaveDataService
      .getExportValue(
        handleKey(
          search,
          leave_type_id,
          from_date,
          to_date,
          approver,
          requester,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Leave.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    leave_type_id,
    from_date,
    to_date,
    approver,
    requester,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    psa_id,
    business_units_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      leave_type_id !== "" &&
      leave_type_id !== undefined &&
      leave_type_id !== null
    ) {
      queryParm = queryParm + "&leave_type_id=" + leave_type_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (approver !== "" && approver !== undefined && approver !== null) {
      queryParm = queryParm + "&approver=" + approver;
    }
    if (requester !== "" && requester !== undefined && requester !== null) {
      queryParm = queryParm + "&requester=" + requester;
    }
    if (bhr !== undefined) {
      queryParm = queryParm + "&bhr=" + true;
    } else {
      queryParm = queryParm + "&report=" + true;
    }
    if (psa_id !== "" && psa_id !== undefined && psa_id !== null && psa_id.length !== 0) {
      queryParm = queryParm + "&psa_id=" + psa_id;
    }
    if (business_units_id !== "" && business_units_id !== undefined && business_units_id !== null) {
      queryParm = queryParm + "&business_units_id=" + business_units_id;
    }
    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2) {
      getLeaveDataList(
        e.target.value.toLowerCase(),
        leaveFilterValue.leave_type_id,
        "",
        "",
        leaveFilterValue.approver,
        leaveFilterValue.requester,
        currentTab,
        currentPage,
        pageSizeNo,
        "-from_date",
        true
      );
      setSearchStr(e.target.value);
    }
    if (e.target.value.length === 0) {
      getLeaveDataList(
        e.target.value.toLowerCase(),
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.approver,
        leaveFilterValue.requester,
        currentTab,
        currentPage,
        pageSizeNo,
        "-from_date",
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getLeaveDataList(
        data,
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.approver,
        leaveFilterValue.requester,
        leaveFilterValue.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const onClose = () => {
    setShowForm(false);
    setBackDateForm(false);
  };

  const editState = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setLeave(eData);
  };

  const addLeaveApply = () => {
    setLeave(null);
    setEditMode(false);
    setShowForm(true);
  };

  const addBackLeaveApply = () => {
    setLeave(null);
    setEditMode(false);
    setBackDateForm(true);
  };

  const handlePushData = (e) => {
    setLeaveFilterValue({
      ...leaveFilterValue,
      leave_type_id: e.value,
    });
    setDataMList(e);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    setBackDateForm(false);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue.approver,
      leaveFilterValue.requester,
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue.approver,
      leaveFilterValue.requester,
      leaveFilterValue.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getLeaveDataList(
      "",
      "",
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      "",
      "",
      currentTab,
      currentPage,
      pageSizeNo,
      "-from_date",
      true
    );
  };

  const mainDataFilter = () => {
    if (
      leaveFilterValue?.from_date !== "" ||
      leaveFilterValue?.leave_type_id !== "" ||
      leaveFilterValue?.status !== "" ||
      leaveFilterValue?.to_date !== "" ||
      leaveFilterValue?.approver !== "" ||
      leaveFilterValue?.requester !== "" ||
      leaveFilterValue?.psa_id?.length !== 0 ||
      leaveFilterValue?.business_units_id !== ''
    ) {
      setFilterApplied(true);
    }
    setFilterValue({
      ...filterValue,
      status: leaveFilterValue.status,
      leave_type_id: leaveFilterValue.leave_type_id,
      from_date: leaveFilterValue.from_date,
      to_date: leaveFilterValue.to_date,
      approver: leaveFilterValue.approver,
      requester: leaveFilterValue.requester,
      psa_id: leaveFilterValue.psa_id,
      business_units_id: leaveFilterValue.business_units_id
    });
    const status = leaveFilterValue.status;
    const leaveType = leaveFilterValue.leave_type_id;
    const fromDate = leaveFilterValue.from_date;
    const toDate = leaveFilterValue.to_date;
    const approver = leaveFilterValue.approver;
    const requester = leaveFilterValue.requester;
    const psa_id = leaveFilterValue.psa_id;
    const business_units_id = leaveFilterValue.business_units_id;
    getLeaveDataList(
      searchStr,
      leaveType,
      fromDate,
      toDate,
      approver,
      requester,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      psa_id,
      business_units_id
    );
    filterClose();
  };

  useEffect(() => {
    getSapSchedule();
  }, []);

  const getSapSchedule = () => {
    get_sap_schedule('sync_apply_leave_balance_from_savior').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(leaveFilterValue));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    ClearData.approver = "";
    ClearData.requester = "";
    setLeaveFilterValue(ClearData);
    setPsaVal([]);
    setDataMList([]);
    setfilterStatus("");
    setFilterApplied("");
    setFilterRequester("");
    setFilterApprover("");
    filterClose();

    let from_date =
        new Date().getDate() > 21
          ? moment(new Date()).month() === 0 ? `${moment(new Date()).format('YYYY')}-${moment(new Date()).format('MM')}-01`
            : `${moment(new Date()).format("YYYY")}-${moment(new Date())
              .subtract(0, "month")
              .format("MM")}-21`
          : moment(new Date()).month() === 0 ? `${moment(new Date()).format('YYYY')}-${moment(new Date()).format('MM')}-01`
            : `${moment(new Date()).format("YYYY")}-${moment(new Date())
              .subtract(1, "month")
              .format("MM")}-21`

    let to_date = moment(new Date()).format("YYYY-MM-DD");

    getLeaveDataList(
      searchStr,
      "",
      from_date,
      to_date,
      "",
      "",
      currentTab,
      1,
      pageSizeNo,
      "-id",
      true
    );
  };

  const displayLogs = (data) => {
    let dates = [];
    let finalData = data?.filter((each) => {
      if (
        dates.includes(each?.leave_date) === false &&
        each?.sync_date !== null
      ) {
        dates.push(each?.leave_date);
        return true;
      }
    });
    setDetailsData(finalData);
  };

  const deleteApplyLeave = (idx, flag) => {
    leaveDataService
      .deleteApplyLeave(idx)
      .then((res) => {
        if (flag !== undefined) {
          toast.success("Cancel Request Successfully.");
        } else {
          toast.success("Revoked Successfully");
        }
        getLeaveDataList("", "", "", "", "", 1, 10, "-id", true);
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleDelete = (id, flag) => {
    confirmAlert({
      message: `Are you sure to ${flag !== undefined ? "cancel" : "revoke"
        } this leave ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApplyLeave(id, flag),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const filterClose = () => {
    filterclose();
  };

  const rePost = (id) => {
    leaveDataService.repostLeave(id).then((response)=>{
      toast.success('Leave repost successfully')
      getLeaveDataList(
        searchStr,
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.approver,
        leaveFilterValue.requester,
        "",
        1,
        pageSizeNo,
        "-from_date",
        true
      );
      let obj = JSON.parse(JSON.stringify(detailsData))
      obj?.map((each)=>{
        if(each?.id === id){
        each.savior_response = response?.data?.response
        }
      })
      setDetailsData(obj)
    })
    .catch((error) => {
      toast.error(
        (error.response &&
          error.response.data.message) ||
          "failed"
      );
    });
  }

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const filterShow = () => {
    filteropen();
    LocationFilter();
    getBranchFilter();
  };

  const date = new Date();
  let day = date.getDate();
  let days = 22;
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  let beforeDate = `${days}-${month}-${year}`;

  useEffect(() => {
    if (userData?.id !== undefined) {

      dispatch(setDisplayLoader("Display"));
      getUserList();
      getLeaveDataList(
        searchStr,
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.approver,
        leaveFilterValue.requester,
        "",
        1,
        pageSizeNo,
        "-from_date",
        true
      );
      getUserSaviorData();
      getBackDateData();
    }
  }, [pageSizeNo, userData]);

  const getUserList = () => {
    let flag = bhr === undefined ? false : true;
    getAllUserDataNew(0, flag).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserDataList(res);
      }
    });
  };

  const getBackDateData = async () => {
    let queryParam = `user_id=${userData?.id}`;
    await leaveDataService.getBackDatesEntries(queryParam).then((response) => {
      if (response?.status === 200) {
        setUserBackDate(response?.data?.dataList?.result);
        if (response?.data?.dataList?.result?.length > 0) {
          let userBackDate = response?.data?.dataList?.result[0];
          let fromDate = new Date(userBackDate?.from_date).toDateString();
          let toDate = new Date(userBackDate?.to_date).toDateString();
          let today = new Date().toDateString();
          if (today >= fromDate || today <= toDate) {
            setAllowBackDate(true);
          }
        }
      }
    });
  };

  const getUserSaviorData = async () => {
    await leaveDataService.getUserHolidays('', '').then((response) => {
      if (response?.status === 200) {
        let holidays = response?.data?.dataList?.userHoliday?.map((obj) => {
          return obj?.day_date;
        });
        setMandatoryHolidays(holidays);
        setUserWeekOff(response?.data?.dataList?.week_off);
      }
    });
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };

  const getBranchFilter = () => {
    UserService.getBranchFilter()
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };


  // useEffect(() => {
  //   getLeaveDataList("", "", "", "", "", 1, 10, "-id", true);
  // }, [currentTab]);

  return (
    <>
      {showForm ? (
        <>
          <ApplyLeave
            edit={editMode}
            leaveList={leaveList}
            lastLeave={lastAppliedLeave}
            mandatory={mandatoryHolidays}
            weekOff={userWeekOff}
            onClose={onClose}
            editState={leave}
            backDate={[]}
            userLeaves={leaveMgmtData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : backDateForm ? (
        <>
          <ApplyLeave
            edit={editMode}
            leaveList={leaveList}
            lastLeave={lastAppliedLeave}
            mandatory={mandatoryHolidays}
            weekOff={userWeekOff}
            onClose={onClose}
            editState={leave}
            backDate={userBackDate}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        <>
          <div class="row">
            <ToastContainer autoClose={1000} />
            {/* <div class="col-md-3">
              <ul class="policylistnames">
                <li>
                  <Link to="/manage-leave">Leave Master</Link>
                </li>
                <li>
                  <Link to="/allocation-leave">Leave Rule</Link>
                </li>
                <li>
                  <Link to="/allocation-leave">Leave Allocation</Link>
                </li>
                <li class="active">
                  <Link to="/my-leave">My Leaves</Link>
                </li>
                <li>
                  <Link to="/bulk-leave">Leave Bulk Upload</Link>
                </li>
                <li>
                  <Link to="/leave-approval">For Approval Leaves</Link>{" "}
                </li>
                <li>
                  <Link to="/leave-backdate">Back Date Entry Exception</Link>
                </li>
              </ul>
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <h4 class="inner-page-title">
                        {bhr === undefined
                          ? "Leave Report"
                          : "BHR Leave Report"}
                      </h4>
                      {/* <Breadcrumb>
                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                        <Breadcrumb.Item active>My Leaves</Breadcrumb.Item>
                      </Breadcrumb> */}
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on <strong> Leave Type</strong>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            id="searchbar"
                            placeholder="Search on Leave Type"
                            onChange={(e) => {
                              handleSearch(e);
                              setCurrentPage(1);
                            }}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack();
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        {/* {finalUrlValue?.D ? ( */}
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <button
                                style={{ border: 0, background: "none" }}
                                onClick={() =>
                                  getExportValue(
                                    searchStr,
                                    leaveFilterValue.leave_type_id,
                                    leaveFilterValue.from_date,
                                    leaveFilterValue.to_date,
                                    leaveFilterValue.approver,
                                    leaveFilterValue.requester,
                                    leaveFilterValue?.status,
                                    1,
                                    pageSizeNo,
                                    "-from_date",
                                    true
                                  )
                                }
                              >
                                <i className="far fa-file-excel"></i>Excel
                              </button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* ) : (
                          ""
                        )} */}
                        <button
                          onClick={filterShow}
                          class="btn btn-secondary-inner"
                          style={{
                            backgroundColor: filterApplied && "yellow",
                            color: filterApplied && userData?.dark_mode === false && "#000"
                          }}
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <b style={{ fontSize: "13px" }}>Next Savior Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Approver</th>
                          <th>Requested By</th>
                          <th
                            onClick={(e) => {
                              handlerData("from_date");
                            }}
                            className={
                              sortData["from_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["from_date"]
                            }
                          >
                            From
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("to_date");
                            }}
                            className={
                              sortData["to_date"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["to_date"]
                            }
                          >
                            To
                          </th>
                          {/* <th
                            onClick={(e) => {
                              handlerData("from_type");
                            }}
                            className={
                              sortData["from_type"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["from_type"]
                            }
                          >
                            From Type
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("to_type");
                            }}
                            className={
                              sortData["to_type"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["to_type"]
                            }
                          >
                            To Type
                          </th> */}
                          <th
                            onClick={(e) => {
                              handlerData("leave_duration");
                            }}
                            className={
                              sortData["leave_duration"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_duration"]
                            }
                          >
                            Total Leaves
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("leave_type__name");
                            }}
                            className={
                              sortData["leave_type__name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["leave_type__name"]
                            }
                          >
                            Leave Type
                          </th>
                          <th>Reason</th>
                          <th>Attachment</th>
                          <th>Approved On</th>
                          <th>Savior Status</th>
                          <th
                            onClick={(e) => {
                              handlerData("status");
                            }}
                            className={
                              sortData["status"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["status"]
                            }
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveMgmtData?.map((data, index) => (
                          <tr>
                            <td>{data?.user_rm}</td>
                            <td>{data?.requested_by}</td>
                            <td className="text-center">
                              {`${moment(data?.from_date).format("DD-MM-YYYY")}
                                  ${data?.from_type !== ""
                                  ? data?.from_type === "First" ||
                                    data?.from_type === "F"
                                    ? " | F"
                                    : data?.from_type === "Second" ||
                                      data?.from_type === "S"
                                      ? " | S"
                                      : ""
                                  : ""
                                }`}
                            </td>
                            <td className="text-center">
                              {`${moment(data?.to_date).format("DD-MM-YYYY")}
                              ${data?.to_type !== ""
                                  ? data?.to_type === "First" ||
                                    data?.to_type === "F"
                                    ? " | F"
                                    : data?.to_type === "Second" ||
                                      data?.to_type === "S"
                                      ? " | S"
                                      : ""
                                  : ""
                                }`}
                            </td>
                            <td>{data?.leave_duration}</td>
                            <td>{data?.leave_type_name}</td>
                            <td>
                              {data?.reasons
                                ?.map((obj) => {
                                  return obj?.reason;
                                })
                                .toString()}
                            </td>
                            <td>
                              {data?.attachments.map((a) => (
                                <a target="_blank" href={a} rel="noreferrer">
                                  <i class="fas fa-paperclip"></i>
                                </a>
                              ))}
                            </td>
                            {data?.action_at !== null ? (
                              <td>
                                {data?.action_at !== undefined &&
                                  moment.utc(data?.action_at).format(
                                    "DD-MM-YYYY hh:mm A"
                                  )}
                              </td>
                            ) : (
                              <td></td>
                            )}
                            <td>
                              {data?.status !== "D" && (
                                <button
                                  type="button"
                                  className="btn btn-blanktd text-primary ml-4"
                                  onClick={() => {
                                    setViewDetails(true);
                                    displayLogs(data?.leave_detail_status);
                                  }}
                                >
                                  <img src="images/eye.png" alt="" />
                                </button>
                              )}
                            </td>
                            <td>
                              {data?.status === "D" ? (
                                <Badge bg="danger">Rejected</Badge>
                              ) : data?.status === "A" ? (
                                <Badge bg="success">Approved</Badge>
                              ) : data?.status === "C" ||
                                data?.status === "Revoked" ? (
                                <Badge bg="danger">Cancelled</Badge>
                              ) : (
                                <Badge bg="secondary">Pending</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {leaveMgmtData?.length == 0 ? (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  ) : (
                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control main-pointer"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {bhr === undefined && (
                <div class="form-group innergroup">
                  <label>Select Approver</label>
                  <Select
                    options={userDataList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      setFilterApplied(e);
                      setFilterApprover(e);
                      setLeaveFilterValue((prev) => ({
                        ...prev,
                        approver: e?.value,
                      }));
                    }}
                    value={filterApprover}
                  />
                </div>
              )}
              <div class="form-group innergroup">
                <label>Select Requester</label>
                <Select
                  options={userDataList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFilterApplied(e);
                    setFilterRequester(e);
                    setLeaveFilterValue((prev) => ({
                      ...prev,
                      requester: e?.value,
                    }));
                  }}
                  value={filterRequester}
                />
              </div>
              <div class="form-group innergroup">
                <label>Select Leave Type</label>
                <Select
                  options={managementLeaveList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => handlePushData(e)}
                  value={dataMList}
                />
              </div>
              <div class="form-group innergroup">
                <label>Select Status</label>
                <Select
                  options={leaveStatus}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setfilterStatus(e);
                    setLeaveFilterValue((prev) => ({
                      ...prev,
                      status: e?.value,
                    }));
                  }}
                  value={filterStatus}
                />
              </div>

              <div className="form-group innergroup">
                <label>
                  Select Location<span class="text-danger"></span>
                </label>
                <Select
                  isMulti
                  name="sub_personal_area"
                  options={subPersonalArea}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    setPsaVal(e);
                    let psa_data = e?.map((value) => {
                      return value.value;
                    });
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      psa_id: psa_data,
                    })

                  }}
                  value={psaVal}
                />
              </div>
              <div className="form-group innergroup">
                <label>Business Unit</label>
                <select
                  className="form-control newbgselect"
                  name="business_units_id"
                  onChange={(e) => {
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      business_units_id: e.target.value,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                  value={leaveFilterValue?.business_units_id}
                >
                  <option value="" label="Select Business Unit" disabled />
                  {renderBranchOptions(branchData)}
                </select>
              </div>

              <div class="form-group innergroup">
                <label>From Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      from_date: e.target.value,
                      to_date: "",
                    })
                  }
                  value={leaveFilterValue.from_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>To Date</label>
                <input
                  type="date"
                  name="to_date"
                  class="form-control"
                  min={moment(leaveFilterValue?.from_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      to_date: e.target.value,
                    })
                  }
                  value={leaveFilterValue.to_date}
                  max={moment(leaveFilterValue.from_date).add(6, 'months').format("YYYY-MM-DD")}
                />
              </div>

              {/* <div class="form-group innergroup">
                <label>Select Status</label>
                <select
                  class="form-control newbgselect"
                  name="status"
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      status: e.target.value,
                    })
                  }
                  value={leaveFilterValue.status}
                >
                  <option value="">Select</option>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="D">Rejected</option>
                </select>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  clearFilter();
                  setCurrentPage(1);
                }}
              >
                CLEAR
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={viewDetails}
            onHide={() => {
              setViewDetails(false);
              setDetailsData(null);
            }}
            backdrop="static"
            size="lg"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Leave Status Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Sno.</th>
                      <th>Leave Date</th>
                      <th>Savior Response</th>
                      <th>Synced At</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsData?.length > 0 ? (
                      detailsData?.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {moment(data?.leave_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{data?.savior_response}</td>
                          <td>
                            {moment
                              .utc(data?.sync_date)
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td>
                            {data?.status
                              ? `Posted ${data?.is_cancelled ? "(Cancelled)" : ""
                              }`
                              : "Not Posted"}
                          </td>
                          {data?.savior_response !== 'OK' ?
                          <td><button class="btn btn-primary-inner bpi-main" onClick={()=>{rePost(data.id)}}>Repost</button></td>
                          :
                          <td></td>
                              }
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          {" "}
                          Pending For Posting
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  setViewDetails(false);
                  setDetailsData(null);
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default LeaveReport;
