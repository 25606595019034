import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import * as CreateSkillService from "../../service/createSkill";
import moment from "moment";
if (typeof window !== "undefined") {
  injectStyle();
}

const StatusSkill = (props) => {
  const { currentTab, isDisplay, type } = props;
  const [skillStatusValue, setSkillStatusValue] = useState([]);

  const getSkillStatusData = (id, plan) => {
    CreateSkillService.getSkillStatusDatas(id, plan, type)
      .then((response) => {
        setSkillStatusValue(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getSkillStatusData("id", isDisplay?.plan);
  }, [currentTab, isDisplay]);

  return (
    <>
      <div className="px-4 pb-2">
        <ToastContainer autoClose={1000} />
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered tablecured viewpo innergroup leavetableapply vehiclelog">
          <thead>
            <tr>
              <th>Skill Id</th>
              <th>Employee Code</th>
              <th>Employee Name</th>
              <th>Current Level</th>
              <th>Planned Level</th>
              <th>Actual Level</th>
              <th>Status</th>
              <th>Reason</th>
              <th>Updated At</th>
              <th>Other</th>
            </tr>
          </thead>
          <tbody>
            {skillStatusValue?.map((data, index) => (
              <>
                <tr key={`plan${index}`}>
                  <td>{data?.skill_Id}</td>
                  <td>{data?.employee_code}</td>
                  <td>{data?.employee_name}</td>
                  <td> {data?.current_level}</td>
                  <td>{data?.planned_level}</td>
                  <td>{data?.actual_level}</td>
                  <td>{data?.status}</td>

                  <td>{data?.reason}</td>
                  <td> {moment
                    .utc(data?.timestamp)
                    .format("Do MMM YYYY, H:mm:ss A")}</td>
                  <td>{data?.other}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StatusSkill;
