import React, { useState, useEffect } from "react";
import {
  Modal,
  Spinner,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import AuditLog from "../audit/auditlog";
import * as surveyService from "../../service/surveyCategory";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

const validationSchema = Yup.object({
  name: Yup.string().required("*name is required"),
  // organisation_id: Yup.string().required("*Organization is required"),6
});

const AddSurveyCategory = (props) => {
  const nameForm = "Survey Category Master";
  const { edit, showForm, onClose, editCategory, onAddCallBack } = props;
  const [surveyData, setsurveyData] = useState({
    id: 0,
    name: "",
    organisation_id: "",
    organisation_name: "",
    is_active: true,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allOrg, setAllOrg] = useState([]);
  const [dataMine, setDataMine] = useState([]);
  const [isSurveycategExist, setIsSurveycategExist] = useState(false);
  const [isDeptCodeExist, setIsDeptCodeExist] = useState(null);
  const [data1, setData1] = useState([]);
  const [msgError, setMsgError] = useState(false);

  const updateSurveyCategory = (values) => {
    setIsLoading(true);
    surveyService
      .updateSurveyCategory(values, values.id)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const saveSurveyCategory = (values) => {
    setIsLoading(true);
    surveyService
      .saveSurveyCategory(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllOrganisation = (paginate) => {
    surveyService
      .getAllOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
        let dd = [];
        response?.data?.dataList?.result.map((data) => {
          let data2 = { value: data.id, label: data.name };
          dd.push(data2);
        });
        setDataMine(dd);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };

  const isSurveyExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editCategory?.name === e.target.value) {
      setIsSurveycategExist(false);
      return;
    } else {
      surveyService
        .isSurveyCategoryExists(data)
        .then((res) => {
          if (e.target.name === "name") {
            setIsSurveycategExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  useEffect(() => {
    getAllOrganisation(true);
  }, []);

  const pushData = (e) => {
    // console.log(e, "dd");
    if (edit === true) {
      setData1();
    } else {
      setData1(e);
      setsurveyData({
        ...surveyData,
        organisation_name: e.label,
        organisation_id: e.value,
      });
    }
  };

  function handleSave(value) {
    let data = {
      id: 0,
      name: value.name,
      organisation_id: surveyData.organisation_id,
      organisation_name: surveyData.organisation_name,
      is_active: value.is_active,
    };
    saveSurveyCategory(data);
  }

  const submitHandler = () => {
    if (data1.length === 0) {
      setMsgError(true);
    }
  };

  useEffect(() => {
    if (editCategory) {
      let data = {
        value: editCategory?.organisation_id,
        label: editCategory?.organisation_name,
      };
      setData1(data);
      setsurveyData(editCategory);
      Object.keys(editCategory).forEach((item) => {
        surveyData[item] = editCategory[item];
      });
    }
  }, [editCategory]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={surveyData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isSurveycategExist !== true) {
            if (values.id) {
              updateSurveyCategory(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              handleSave(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Survey Type
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {console.log(values, "editCategory")}
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      {/* {console.log(allOrg, 'allOrg')} */}

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Survey Type Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Enter Survey Type Name"
                            maxLength={30}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {touched.name && errors.name ? (
                            <div className="small text-danger">
                              {errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Organization<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="organisation_id"
                            id="select-box"
                            options={dataMine}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              pushData(e);
                              setMsgError(false);
                            }}
                            value={data1}
                          />
                          {msgError && (
                            <div className="small text-danger">
                              *Select Organization
                            </div>
                          )}
                          {/* {touched.organisation_id &&
                            errors.organisation_id ? (
                            <div className="small text-danger">
                              {errors.organisation_id}
                            </div>
                          ) : null} */}
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div className="form-group innergroup">
                          <label className="d-block">
                            Status <span className="text-danger"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values?.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values?.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values?.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                {isLoading ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSurveyCategory;
