import React from 'react'
import { Link } from 'react-router-dom'

const CustomMenu = ({approvalCount}) => {
  const isActiveRoute = (activeRoute) => {
    return activeRoute === window.location.pathname ? { class: 'active' } : {}
  }
  return (
    <>
      <ul class="policylistnames">
        {/* <li {...isActiveRoute("/material-req")}>
          <Link to="/material-req">Material Request</Link>
        </li> */}
        {/* <li {...isActiveRoute("/mat-ext")}>
          <Link to="/mat-ext">Material Extension</Link>
        </li>
        <li {...isActiveRoute("/mat-cas")}>
          <Link to="/mat-cas">Update CAS</Link>
        </li>
        <li {...isActiveRoute("/mat-hsn")}>
          <Link to="/mat-hsn">HSN Code</Link>
        </li>
        <li {...isActiveRoute("/power-user")}>
          <Link to="/power-user">Power User</Link>
        </li> */}
        {console.log(approvalCount)}
        <li {...isActiveRoute("/mat-approval")}>
          <Link to="/mat-approval">Approval Material Request</Link>{" "}
          <span class="numberpurchse float-right mr-2">{approvalCount!=undefined?approvalCount:0}</span>
        </li>
        <li {...isActiveRoute("/authrize-user")}>
          <Link to="/authrize-user">Authorize User</Link>
        </li>
        {/* <li {...isActiveRoute("/mat-descp")}>
          <Link to="/mat-descp">Material Type Description</Link>
        </li>
        <li {...isActiveRoute("/ref-mat")}>
          <Link to="/ref-mat">Reference Material</Link>
        </li>
        <li {...isActiveRoute("/mat-ext-approval")}>
          <Link to="/mat-ext-approval">Approval Material Extension</Link>{" "}
          <span class="numberpurchse float-right mr-2">03</span>
        </li>
        <li {...isActiveRoute("/hsn-approval")}>
          <Link to="/hsn-approval">Approval HSN Code Request</Link>{" "}
          <span class="numberpurchse float-right mr-2">07</span>
        </li> */}
      </ul>
    </>
  )
}

export default CustomMenu