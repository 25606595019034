import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as surveyService from "../../service/Survey";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MultiOptionSurveyView from "./MultiOptionSurveyView";
import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";

var surveyDuration = 0;
function Viewassess() {
  const [asmntData, setAsmntData] = useState({});
  const [curAssess, setCurAssess] = useState({});
  const [dropDownVal, setDropDownVal] = useState({});
  const [buddy, setBuddy] = useState("");
  const [userInput, setUserInput] = useState({
    id: 0,
    is_draft: false,
    survey_id: 0,
    questions: [],
  });
  const userData = useSelector(selectUserData);
  const [surveyDetails, setSurveyDetails] = useState(false);
  const [payload, setPayload] = useState({});
  const [validation, setValidation] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [duration, setDuration] = useState(0);
  const [alertDuration, setAlertDuration] = useState(0);
  const [completionTime, setCompletionTime] = useState(0);
  const [timeOverFlag, setTimeOverFlag] = useState(false);
  const [isFeedback, setIsFeedback] = useState([]);
  const [secDesc, setSecDesc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const myRef = useRef(null);
  var emojis = ["😠", "☹️", "🙂", "😀", "🤩️"];

  const { surveyId, view } = useParams();
  let targetAsmnt = {};

  if (surveyId && surveyDetails === false) {
    targetAsmnt = {
      id: surveyId,
      user_id: userData?.id,
    };
    setPayload(targetAsmnt);
    setSurveyDetails(true);
  }

  useEffect(() => {
    setInterval(() => {
      surveyDuration += 1;
    }, [1000]);
  }, []);

  // const { targetAsmnt, flag, view } = state;

  const navigate = useNavigate();
  var time = Number(duration) * 60;

  useEffect(() => {
    setQuizTimer();
  }, [duration]);

  useEffect(() => {
    if (timeOverFlag) {
      goNext();
    }
  }, [timeOverFlag]);

  useEffect(() => {
    surveyService
      .getSurvey(`survey_id=${payload.id}&response_user_id=${payload.user_id}`)
      .then((response) => {
        // getAllCategoryType();
        setIsLoading(true);
        response?.data?.dataList?.result[0]?.questions?.map((ques) => {
          if (ques?.type === "Slider") {
            ques?.options?.map((opt) => {
              opt.showFeedback = true;
            });
          }
        });
        setCurAssess(response?.data?.dataList?.result[0]);
        let questionsArr = [];
        let finalRes = {};
        let secValid = {};
        let valid = response?.data?.dataList?.result[0]?.questions?.map(
          (each) => {
            return {
              mandatory: false,
            };
          }
        );
        setValidation(valid);

        setDuration(response?.data?.dataList?.result[0]?.duration);
        let alert_dur =
          response?.data?.dataList?.result[0]?.duration -
          response?.data?.dataList?.result[0]?.alert_duration;
        setAlertDuration(alert_dur);
        response?.data?.dataList?.result[0]?.questions
          ?.filter((each) => {
            if (
              curAssess?.category_name === "Quiz" &&
              view === "true" &&
              response?.data?.dataList?.result[0]?.random === true &&
              each?.answers?.length !== 0
            ) {
              return true;
            } else if (
              curAssess?.category_name === "Quiz" &&
              response?.data?.dataList?.result[0]?.random === true &&
              view === "false"
            ) {
              return true;
            } else if (curAssess?.category_name !== "Quiz") {
              return true;
            }
          })
          .map((qItem, idx) => {
            let quesRespData = {
              question_id: qItem.id,
              option_id: [],
              multiOption: qItem?.multiOption,
              remark_text: "",
              feedback: "",
              is_mandatory: qItem?.is_mandatory,
            };
            if (qItem?.answers?.length > 0) {
              qItem?.answers.map((sub_ansOp, sub_ansOpIdx) => {
                if (sub_ansOp?.remark_text?.length > 0) {
                  quesRespData.remark_text = sub_ansOp?.remark_text;
                }

                qItem?.options.map((ansOp, ansOpIdx) => {
                  if (sub_ansOp.answer_options === ansOp.option) {
                    quesRespData.option_id.push(ansOp.id);
                  }
                });
              });
            }
            if (Object.keys(finalRes).includes(qItem?.section)) {
              secValid[qItem?.section].push({
                mandatory: false,
                id: qItem?.id,
                feedback: false,
              });
              finalRes[qItem?.section].push(qItem);
            } else {
              if (qItem?.section !== null) {
                finalRes[qItem?.section] = [qItem];
                secValid[qItem?.section] = [];
                secValid[qItem?.section].push({
                  mandatory: false,
                  id: qItem?.id,
                  feedback: false,
                });
                setSecDesc((prev) => [...prev, qItem?.section_desc]);
              } else {
                valid.push({
                  mandatory: false,
                });
                if ("questions" in finalRes) {
                  finalRes["questions"].push(qItem);
                } else {
                  finalRes["questions"] = [qItem];
                }
              }
            }
            questionsArr.push(quesRespData);
          });
        setValidation(Object.keys(secValid)?.length > 0 ? secValid : valid);
        setUserInput({
          ...userInput,
          questions: questionsArr,
        });
        if (response?.data?.dataList?.result[0]?.is_sectional) {
          let newRes = {};
          response?.data?.dataList?.result[0]?.section_heading.forEach(
            (element) => {
              newRes[element] = finalRes[element];
            }
          );
          setAsmntData(newRes);
        } else {
          setAsmntData(finalRes);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, [payload]);

  function goPrevious() {
    if (userInput?.questions.length > 0) {
      let queryBody = { ...userInput };
      queryBody.is_draft = true;
      queryBody.survey_id = payload.id;

      surveyService
        .postResponse(queryBody)
        .then((response) => {
          // getAllCategoryType();
          // setAsmntData(response?.data?.dataList?.result.filter((data)=>{
          //   if (data.id==targetAsmnt.id) return data
          // })[0])
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
    navigate("/survey-assessment");
  }

  const openConfirmModel = () => {
    setConfirm(true);
  };

  let currentCheckedRating = {};

  function updateLocalResponse(e, local_idx, qid, opid, qtype, qItem) {
    let data = document.getElementsByClassName("checkboxstart");
    for (let i = 0; i < data?.length; i++) {
      if (
        Number(data[i].getAttribute("ques")) === qid &&
        Number(data[i].getAttribute("value")) < Number(e?.target.value)
      ) {
        data[i].checked = data[i].checked ? false : true;
      }
    }
    let assessData = JSON.parse(JSON.stringify(asmntData));
    assessData[qItem]?.map((each) => {
      if (each?.id === qid && e.target.name !== "RatingFeedback") {
        if (!each?.multiOption) {
          if (
            each?.options[0]?.allow_feedback &&
            each?.select_feedback &&
            (each?.select_feedback_option.includes(e.target.value) ||
              e.target.value === 0)
          ) {
            each.options[0].showFeedback = true;
          } else {
            each.options[0].showFeedback = false;
          }
        } else {
          if (
            each?.options[local_idx]?.allow_feedback &&
            each?.select_feedback &&
            each?.select_feedback_option.includes(e.target.value)
          ) {
            each.options[local_idx].showFeedback = true;
          } else {
            each.options[local_idx].showFeedback = false;
          }
        }
      }
      return each;
    });
    setAsmntData(assessData);

    let questionsArr = [...userInput.questions];
    let found = false;
    let curQues = JSON.parse(JSON.stringify(curAssess))?.questions.filter(
      (each) => {
        if (each?.id === qid) {
          return true;
        }
      }
    )[0];
    if (questionsArr?.length > 0) {
      questionsArr?.map(function (qitem, idx) {
        if (qitem.question_id === qid) {
          found = true;
          if (qtype === "Multiple Choice") {
            qitem.option_id.push(opid);
          } else {
            if (
              qtype === "Rating" ||
              qtype === "Short Answer" ||
              qtype === "Long Answer"
            ) {
              if (curQues?.multiOption) {
                if (qtype === "Rating") {
                  if (qitem.option_id[local_idx]) {
                    if (e.target.name === "RatingFeedback") {
                      qitem.option_id[local_idx].feedback = e.target.value;
                    } else {
                      qitem.option_id[local_idx].remark_text =
                        e?.currentTarget?.value === undefined
                          ? e.target.value.toString()
                          : e.currentTarget.value;
                    }
                  } else {
                    if (e.target.name === "RatingFeedback") {
                      qitem.option_id.splice(local_idx, 0, {
                        feedback: e.target.value,
                        opid: 0,
                      });
                    } else {
                      qitem.option_id.splice(local_idx, 0, {
                        remark_text:
                          e?.currentTarget?.value === undefined
                            ? e.target.value.toString()
                            : e.currentTarget.value,
                        opid: 0,
                      });
                    }
                  }
                } else {
                  if (qitem.option_id[local_idx]) {
                    qitem.option_id[local_idx].remark_text =
                      e?.currentTarget?.value === undefined
                        ? e.target.value.toString()
                        : e.currentTarget.value;
                  } else {
                    qitem.option_id.splice(local_idx, 0, {
                      remark_text:
                        e?.currentTarget?.value === undefined
                          ? e.target.value.toString()
                          : e.currentTarget.value,
                      opid: 0,
                    });
                  }
                }
              } else {
                if (qtype === "Rating") {
                  currentCheckedRating[qitem.id] = e;
                  if (e.target.name === "RatingFeedback") {
                    qitem.feedback = e.target.value;
                  } else {
                    qitem.remark_text =
                      e?.currentTarget?.value === undefined
                        ? e.target.value.toString()
                        : e.currentTarget.value;
                  }
                } else {
                  qitem.remark_text = e.currentTarget.value;
                }
              }
            } else {
              if (qtype === "Single") {
                qitem.remark_text = e.target.value;
              } else {
                let curQuesOpt = curAssess?.questions
                  ?.filter((each) => {
                    if (each?.id === qid) {
                      return true;
                    }
                  })[0]
                  .options?.map((each) => each?.id);
                let curFeedbackList = isFeedback?.filter((x) => {
                  if (curQuesOpt.includes(x) === false) {
                    return true;
                  }
                });
                curFeedbackList.push(opid);
                qitem.option_id.pop();
                qitem.option_id.push(opid);
                setIsFeedback(curFeedbackList);
              }
            }
          }
        }

        return qitem;
      });
    }

    if (!found) {
      let quesRespData = {};
      if (qtype === "Rating" || qtype === "Short Answer") {
        quesRespData = {
          question_id: qid,
          option_id: [],
          remark_text: e.target.value,
          feedback: "",
        };
      } else {
        quesRespData = {
          question_id: qid,
          option_id: [opid],
          remark_text: "",
          feedback: "",
        };
      }
      questionsArr.push(quesRespData);
    }
    setUserInput({
      ...userInput,
      questions: questionsArr,
    });
  }

  function handleValidation() {
    let remarkFilled = true;
    let validate = false;
    let valid = JSON.parse(JSON.stringify(validation));
    let userSubmitRes = JSON.parse(JSON.stringify(userInput));

    let questions = [];
    Object.values(asmntData).map((each) => {
      return questions.push(...each);
    });
    let sliders = questions
      ?.filter((each) => {
        if (each?.multiOption) {
          let res = each?.options.filter((opt) => {
            if (opt?.type === "Slider") {
              return true;
            }
          });
          if (res.length > 0) {
            return true;
          }
        }
      })
      ?.map((x) => {
        return x?.id;
      });
    let check = 0;
    userInput?.questions?.map((each, index) => {
      if (sliders.includes(each?.question_id) && each.option_id?.length === 0) {
        validate = true;
        check += 1;
      }
    });
    let is_feed = false;
    function getAnswerStat(each, index) {
      let idx = null;
      let val = userInput?.questions
        ?.filter((x, ind) => {
          if (x?.question_id === each?.id) {
            idx = ind;
            return true;
          }
        })
        .map((x) => {
          let isExist = [];
          if (questions[index]?.multiOption && x?.option_id?.length > 0) {
            isExist = x?.option_id?.filter((val, i) => {
              if (
                questions[index]?.options[i]?.showFeedback &&
                questions[index]?.options[i]?.feedback_mandatory &&
                (val?.feedback === undefined || val?.feedback === "")
              ) {
                valid[each?.section]?.map((y) => {
                  if (y?.id === each?.id) {
                    y.feedback = true;
                  }
                });
                validate = true;
                remarkFilled = false;
              } else {
                valid[each?.section]?.map((y) => {
                  if (y?.id === each?.id) {
                    y.feedback = false;
                    if (!questions[index]?.options[i]?.showFeedback) {
                      userSubmitRes.questions[idx].option_id[i].feedback = "";
                    }
                  }
                });
              }
              if (val?.remark_text === "") {
                return true;
              }
            });
          } else {
            if (
              questions[index]?.options[0]?.showFeedback &&
              questions[index]?.options[0]?.feedback_mandatory &&
              (x?.feedback === undefined || x?.feedback === "")
            ) {
              valid[each?.section]?.map((y) => {
                if (y?.id === each?.id) {
                  y.feedback = true;
                }
              });
              validate = true;
              remarkFilled = false;
            } else {
              valid[each?.section]?.map((y) => {
                if (y?.id === each?.id) {
                  y.feedback = false;
                  if (!questions[index]?.options[0]?.showFeedback) {
                    userSubmitRes.questions[idx].feedback = "";
                  }
                }
              });
            }
            if (x?.remark_text === "" && questions[index]?.type !== "Slider") {
              isExist.push(x);
            }
          }
          if (isExist?.length > 0) {
            return true;
          } else {
            return false;
          }
        })[0];
      return val;
    }
    questions?.map((each, index) => {
      if (each?.is_mandatory && getAnswerStat(each, index)) {
        if (each?.section !== "") {
          valid[each?.section]?.map((x) => {
            if (each?.id === x?.id) {
              validate = true;
              x.mandatory = true;
              return x;
            }
          });
        } else {
          validate = true;
          valid[index].mandatory = true;
        }
      }
    });

    if (curAssess?.compulsory) {
      let notSubmitted = userInput?.questions?.filter((obj, index) => {
        let attempt = obj?.option_id?.filter((val) => {
          if (val?.remark_text === "") {
            return true;
          }
        });
        if (attempt?.length > 0 && questions[index]?.options?.length > 0) {
          return true;
        }
      });
      if (
        (curAssess?.compulsory && notSubmitted?.length > 0) ||
        !remarkFilled
      ) {
        toast.error("Kindly attempt all questions.");
        validate = true;
      }
    } else {
      if (check > 0) {
        toast.error("Kindly attempt Slider Question.");
      }
    }
    setValidation(valid);
    setUserInput(userSubmitRes);
    myRef.current.scrollIntoView();
    return {
      valid: validate,
      data: userSubmitRes,
    };
  }

  function goNext(skipped) {
    let { valid, data } = handleValidation();
    if (valid) {
      return;
    } else {
      let queryBody = data;
      queryBody.is_draft = false;
      queryBody.survey_id = payload.id;
      queryBody.skipped = skipped === undefined ? false : true;
      queryBody.completion_time = moment
        .duration(surveyDuration, "seconds")
        .asMinutes()
        .toFixed(2);
      queryBody.buddy = buddy;
      surveyService
        .postResponse(queryBody)
        .then((response) => {
          // getAllCategoryType();
          // setAsmntData(response?.data?.dataList?.result.filter((data)=>{
          //   if (data.id==targetAsmnt.id) return data
          // })[0])
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
    navigate("/survey-assessment");
  }

  const getSliderValue = (id) => {
    const ques = userInput?.questions?.filter((each) => {
      if (each?.question_id === id) {
        return true;
      }
    });
    if (ques?.length > 0) {
      if (
        ques[0]?.remark_text != "" ||
        ques[0]?.remark_text != null ||
        ques[0]?.remark_text != undefined
      ) {
        return Number(ques[0]?.remark_text);
      } else {
        return Number(ques[0]?.option_id[0]?.remark_text);
      }
    }
  };

  const setQuizTimer = () => {
    setTimeout(() => {
      if (time) {
        let completion_time = Number(duration) * 60 - time;
        time = time - 1;
        if (time === alertDuration) {
          var left_min = Math.floor(alertDuration / 60),
            left_sec = Math.round(alertDuration % 60);
          toast.warn(
            "You have only " + left_min + ":" + left_sec + " min left"
          );
        }
        if (time === 1) {
          setTimeOverFlag(true);
        }
        var min = Math.floor(time / 60),
          sec = Math.round(time % 60);
        document.getElementById("info2").innerHTML =
          min + ":" + sec + " min left";
        setCompletionTime(completion_time);
        setQuizTimer();
      }
    }, 1000);
  };

  return (
    <div className="container">
      <div class="row justify-content-center">
        <Modal
          show={confirm}
          onHide={() => setConfirm(false)}
          backdrop="static"
          keyboard={false}
          size="md"
          className="modaldefaultclose modaldefaultclosecenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Survey Skip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                If you exercise <b>SKIP</b> option you won't be able to attempt
                this again, to go back please click on <b>BACK</b> button
              </div>
              <div className="col-md-2"></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn btn-outline-danger"
              onClick={() => {
                setConfirm(false);
              }}
            >
              Back
            </button>
            {view === "false" && (
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => goNext(true)}
              >
                Skip
              </button>
            )}
          </Modal.Footer>
        </Modal>
        {/* <SelectedTypeUser current={targetAsmnt.current}/> */}
        <div class="surveyviewquestions mt-4 mr-3">
          <div
            class="text-sm-right"
            style={{
              position: "sticky",
              top: "100px",
            }}
          >
            {view === "false" && curAssess?.category_name === "Quiz" && (
              <>
                <h2 className="text-center">Time</h2>
                <h3 id="info2"></h3>
              </>
            )}
          </div>
        </div>
        <div class="col-md-12">
          <div class="content-wrapper-inner pt-0 border">
            <div class="innerheadsec p-0">
              <div className="row">
                {curAssess?.banner?.url !== "" &&
                curAssess?.imgType === "Banner" ? (
                  <div class="col-md-12">
                    <img src={curAssess?.banner?.url} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="row surveyviewquestions">
                <div class="col-12" ref={myRef}>
                  <h3 className="policyhead policyheadasssubhead text-center">
                    <div className="row align-item-center">
                      {curAssess?.banner?.url !== "" &&
                        curAssess?.imgType === "Logo" && (
                          <div className="col-sm-2 text-center text-sm-center">
                            <img
                              src={curAssess?.banner?.url}
                              class="mob-logoass"
                            />
                          </div>
                        )}
                      <div
                        className={
                          curAssess?.banner?.url === "" ||
                          curAssess?.imgType === "Banner"
                            ? "col-sm-12 survey-title-web"
                            : "col-sm-10 survey-title-web"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: curAssess?.title,
                          }}
                        ></span>
                      </div>
                    </div>
                  </h3>
                  <div
                    className="m-2"
                    dangerouslySetInnerHTML={{ __html: curAssess?.description }}
                  ></div>
                  {curAssess?.user_details === true ? (
                    <>
                      <h3
                        className="policyhead policyheadasssubhead"
                        style={{
                          color: !userData?.dark_mode && "black",
                        }}
                      >
                        Personal Details
                      </h3>
                      <div className="mx-sm-3 mt-sm-3 mx-2 mt-2">
                        <div class="row viewformaftersubmit">
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Name of the Employee</label>
                              <p>{userData?.full_name}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Employee Code</label>
                              <p>{userData?.username}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Designation</label>
                              <p>{userData?.designation_name}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Business Email</label>
                              <p>{userData?.business_email}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Department / Function</label>
                              <p>{userData?.department_name}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Reporting Manager</label>
                              <p>
                                {userData?.reporting_to_fname +
                                  " " +
                                  userData?.reporting_to_lname}
                              </p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              {curAssess?.title ===
                                "<p>New Employee Week 1 Survey</p>" ||
                                curAssess?.title ===
                                  "<p>New Employee Day 45 Survey</p>" ||
                                (curAssess?.title ===
                                  "<p>New Employee Day 90 Survey</p>" && (
                                  <>
                                    <label>Buddy</label>
                                    {(curAssess.permission_type === "SV" &&
                                      curAssess?.status === "Submitted") ||
                                    view === "true" ? (
                                      <p>{curAssess?.buddy}</p>
                                    ) : (
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Kindly enter buddy"
                                        onChange={(event) => {
                                          setBuddy(event.target.value);
                                        }}
                                        value={buddy}
                                      />
                                    )}
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div class="border-top mb-3"></div>
            <div class="p-sm-3 p-2">
              {Object.keys(asmntData)?.length > 0 &&
                Object.keys(asmntData)?.map((qItem, idx) => {
                  return (
                    <div class="quesionBoxbig">
                      {qItem !== "questions" && (
                        <>
                          <h4 class="assess-title">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: qItem,
                              }}
                            ></div>
                          </h4>
                        </>
                      )}
                      {asmntData[qItem]?.map((each, i) => {
                        if (each?.multiOption) {
                          return (
                            <MultiOptionSurveyView
                              question={each}
                              prevQues={
                                i === 0 ? null : asmntData[qItem][i - 1]
                              }
                              curAssess={curAssess}
                              view={view}
                              index={i}
                              idx={idx}
                              qItem={qItem}
                              updateLocalResponse={updateLocalResponse}
                              userInput={userInput}
                              secDesc={secDesc}
                              valid={validation}
                            />
                          );
                        } else {
                          return (
                            <>
                              {each?.option_type === "" ? (
                                <div className="row quesionBoxbignewrow">
                                  {i !== 0 && (
                                    <div className="col-md-12 mt-4">
                                      <hr />
                                    </div>
                                  )}
                                  <div className="col-md-12 d-flex mt-3">
                                    {each?.allowSequence && `${i + 1}. `}{" "}
                                    {each?.is_mandatory && (
                                      <span className="text-danger">*</span>
                                    )}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: each?.question,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row g-0" style={{}}>
                                  {i === 0 && (
                                    <div
                                      class="col-md-12 fw-bold"
                                      dangerouslySetInnerHTML={{
                                        __html: secDesc[idx],
                                      }}
                                    ></div>
                                  )}
                                  <div
                                    class={`col-md-${each?.quesWidth} question-boxpollsurvey`}
                                    style={{
                                      marginTop:
                                        each?.type === "Single Select" &&
                                        i === 0 &&
                                        "10px",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-md-12 d-flex">
                                        {curAssess?.allowSequence && (
                                          <span className="mr-1">
                                            ({i + 1}).
                                          </span>
                                        )}{" "}
                                        {each?.is_mandatory && (
                                          <span className="text-danger">*</span>
                                        )}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: each?.question,
                                          }}
                                        ></div>
                                      </div>
                                      {view !== "true" &&
                                      curAssess?.is_sectional === false
                                        ? qItem === "questions" &&
                                          validation[i]?.mandatory && (
                                            <span
                                              class="text-danger ml-2"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              *Kindly attempt this question
                                            </span>
                                          )
                                        : view !== "true" &&
                                          curAssess?.is_sectional
                                        ? qItem !== "questions" &&
                                          validation[qItem][i]?.mandatory && (
                                            <span
                                              class="text-danger ml-2"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              *Kindly attempt this question
                                            </span>
                                          )
                                        : ""}
                                    </div>
                                  </div>
                                  {each?.option_type === "txt" ? (
                                    each?.is_dropdown ? (
                                      <>
                                        <div
                                          class="col-md-2 text-center"
                                          style={{
                                            marginTop:
                                              (i === 0 ||
                                                asmntData[qItem][i - 1]
                                                  ?.options[0]?.desc !==
                                                  each?.options[0]?.desc) &&
                                              "-1.5rem",
                                          }}
                                        >
                                          {(i === 0 ||
                                            asmntData[qItem][i - 1]?.options[0]
                                              ?.desc !==
                                              each?.options[0]?.desc) && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: each?.options[0]?.desc,
                                              }}
                                              style={{
                                                marginBottom: "1rem",
                                                fontWeight: "bold",
                                              }}
                                            ></div>
                                          )}
                                          <Select
                                            isDisabled={
                                              (curAssess.permission_type ===
                                                "SV" &&
                                                curAssess?.status ===
                                                  "Submitted") ||
                                              view === "true"
                                            }
                                            placeholder={
                                              <i className="fas fa-angle-down text-center" />
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                marginTop: "30px",
                                                border:
                                                  view !== "true" &&
                                                  ((qItem === "questions" &&
                                                    validation[i]?.mandatory) ||
                                                    (qItem !== "questions" &&
                                                      validation[qItem][i]
                                                        ?.mandatory)) &&
                                                  "0.5px solid red",
                                              }),
                                              option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color:
                                                  !userData?.dark_mode &&
                                                  "black",
                                              }),
                                              indicatorsContainer: (style) => ({
                                                ...style,
                                                display: "none",
                                              }),
                                            }}
                                            defaultValue={
                                              each?.options
                                                ?.filter((x) => {
                                                  if (
                                                    x?.option ===
                                                    each?.default_option
                                                  ) {
                                                    return true;
                                                  }
                                                })
                                                .map((each, index) => {
                                                  return {
                                                    label: each?.option,
                                                    value: each?.id,
                                                    key: index,
                                                  };
                                                })[0]
                                            }
                                            options={
                                              each?.type === "Rating"
                                                ? Array.from(
                                                    {
                                                      length:
                                                        each?.options[0]?.max,
                                                    },
                                                    (_, i) => i + 1
                                                  ).map((x) => {
                                                    return {
                                                      label: x,
                                                      value: x,
                                                      key: x,
                                                    };
                                                  })
                                                : each?.options?.map(
                                                    (each, index) => {
                                                      return {
                                                        label: each?.option,
                                                        value: each?.id,
                                                        key: index,
                                                      };
                                                    }
                                                  )
                                            }
                                            onChange={(e) => {
                                              updateLocalResponse(
                                                {
                                                  target: {
                                                    value: e?.value,
                                                  },
                                                },
                                                e?.index,
                                                each.id,
                                                e.value,
                                                each.type,
                                                qItem
                                              );
                                              setDropDownVal(e);
                                            }}
                                            value={
                                              (curAssess.permission_type ===
                                                "SV" &&
                                                curAssess?.status ===
                                                  "Submitted") ||
                                              view === "true"
                                                ? each?.type === "Rating"
                                                  ? Array.from(
                                                      {
                                                        length:
                                                          each?.options[0]?.max,
                                                      },
                                                      (_, i) => i + 1
                                                    )
                                                      ?.filter((x) => {
                                                        if (
                                                          x.toString() ===
                                                          each?.answers[0]
                                                            ?.remark_text
                                                        ) {
                                                          return true;
                                                        }
                                                      })
                                                      .map((each, index) => {
                                                        return {
                                                          label: each,
                                                          value: each,
                                                          key: index,
                                                        };
                                                      })[0]
                                                  : each?.options
                                                      ?.filter((x) => {
                                                        if (
                                                          x?.option ===
                                                          each?.answers[0]
                                                            ?.answer_options
                                                        ) {
                                                          return true;
                                                        }
                                                      })
                                                      .map((each, index) => {
                                                        return {
                                                          label: each?.option,
                                                          value: each?.id,
                                                          key: index,
                                                        };
                                                      })[0]
                                                : each?.options
                                                    ?.filter((x) => {
                                                      if (
                                                        x?.option ===
                                                        each?.answers[0]
                                                          ?.answer_options
                                                      ) {
                                                        return true;
                                                      }
                                                    })
                                                    .map((each, index) => {
                                                      return {
                                                        label: each?.option,
                                                        value: each?.id,
                                                        key: index,
                                                      };
                                                    })[0]
                                            }
                                          />
                                        </div>
                                        {each?.type === "Rating" &&
                                          each?.options[0]?.allow_feedback &&
                                          (each?.options[0]?.showFeedback ||
                                            (view === "true" &&
                                              each?.answers[0]?.feedback !==
                                                "")) && (
                                            <div
                                              class="innergroup col-md-3"
                                              style={{
                                                marginTop: "30px",
                                              }}
                                            >
                                              <textarea
                                                name="RatingFeedback"
                                                placeholder="Remarks"
                                                className="form-control"
                                                id="rateRemarks"
                                                onChange={(e) => {
                                                  updateLocalResponse(
                                                    e,
                                                    i,
                                                    each.id,
                                                    "",
                                                    "Rating"
                                                  );
                                                }}
                                                style={{
                                                  height:
                                                    view === "true"
                                                      ? "200px"
                                                      : "38px",
                                                  borderRadius: "5px",
                                                  border:
                                                    view !== "true" &&
                                                    ((qItem === "questions" &&
                                                      validation[i]
                                                        ?.feedback) ||
                                                      (qItem !== "questions" &&
                                                        validation[qItem][i]
                                                          ?.feedback)) &&
                                                    "0.5px solid red",
                                                }}
                                                value={
                                                  each?.answers[0]?.feedback
                                                }
                                              />
                                              {view !== "true" &&
                                              qItem !== "" &&
                                              validation[qItem][i]?.feedback ? (
                                                <span class="text-danger ml-1">
                                                  *Kindly Fill Remarks
                                                </span>
                                              ) : (
                                                view !== "true" &&
                                                qItem === "questions" &&
                                                validation[i]?.feedback && (
                                                  <span class="text-danger ml-1">
                                                    *Kindly Fill Remarks
                                                  </span>
                                                )
                                              )}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      each?.options.map((optItem, iddx) => (
                                        <div
                                          class={
                                            [
                                              "file",
                                              "Rating",
                                              // "Short Answer",
                                              // 'Long Answer',
                                              "Slider",
                                              "Emoji",
                                            ].includes(each.type) === false
                                              ? `col-md-${optItem?.optWidth}`
                                              : each?.type === "Slider"
                                              ? `col-md-${each.quesWidth}`
                                              : "col-md-12"
                                          }
                                        >
                                          {each.type === "Rating" ? (
                                            <div>
                                              <div class="ratingpoll d-inline-block">
                                                {Array.apply(null, {
                                                  length: optItem.max,
                                                }).map((e, i) => (
                                                  <label class="logCheck d-inline-block">
                                                    {each?.answers.length >
                                                    0 ? (
                                                      each?.answers?.map(
                                                        (anitem, anidx) =>
                                                          Number(`${i + 1}`) <=
                                                          Number(
                                                            anitem?.remark_text
                                                          ) ? (
                                                            <input
                                                              checked
                                                              className="checkboxstart"
                                                              ques={each?.id}
                                                              disabled={
                                                                (curAssess.permission_type ===
                                                                  "SV" &&
                                                                  curAssess?.status ===
                                                                    "Submitted") ||
                                                                view === "true"
                                                              }
                                                              type="checkbox"
                                                              value={`${i + 1}`}
                                                              onChange={(e) =>
                                                                updateLocalResponse(
                                                                  e,
                                                                  i,
                                                                  each.id,
                                                                  optItem.id,
                                                                  each.type
                                                                )
                                                              }
                                                              name="surveyanswer1"
                                                            />
                                                          ) : (
                                                            <input
                                                              className="checkboxstart"
                                                              value={`${i + 1}`}
                                                              ques={each?.id}
                                                              disabled={
                                                                (curAssess.permission_type ===
                                                                  "SV" &&
                                                                  curAssess?.status ===
                                                                    "Submitted") ||
                                                                view === "true"
                                                              }
                                                              type="checkbox"
                                                              onChange={(e) =>
                                                                updateLocalResponse(
                                                                  e,
                                                                  i,
                                                                  each.id,
                                                                  optItem.id,
                                                                  each.type
                                                                )
                                                              }
                                                              name="surveyanswer1"
                                                            />
                                                          )
                                                      )
                                                    ) : (
                                                      <input
                                                        value={`${i + 1}`}
                                                        className="checkboxstart"
                                                        type="checkbox"
                                                        name="surveyanswer1"
                                                        ques={each?.id}
                                                        onChange={(e) =>
                                                          updateLocalResponse(
                                                            e,
                                                            i,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          )
                                                        }
                                                      />
                                                    )}

                                                    <span class="checkmark"></span>
                                                  </label>
                                                ))}

                                                <div class="d-flex justify-content-between mt-2">
                                                  <span>
                                                    1<br />
                                                    Poor
                                                  </span>
                                                  <span class="mr-2 pr-1 text-right">
                                                    {optItem.max}
                                                    <br />
                                                    Excellent
                                                  </span>
                                                </div>
                                              </div>
                                              <div class="answerboxpollsuy innergroup">
                                                <textarea
                                                  name="RatingFeedback"
                                                  className="form-control"
                                                  placeholder="Your Feeback"
                                                  onChange={(e) => {
                                                    updateLocalResponse(
                                                      e,
                                                      i,
                                                      each.id,
                                                      optItem.id,
                                                      "Rating",
                                                      qItem
                                                    );
                                                  }}
                                                  value={
                                                    each?.answers[0]?.feedback
                                                  }
                                                />
                                              </div>
                                              <div class="answerboxpollsuy innergroup">
                                                {each?.options[0]?.desc}
                                              </div>
                                            </div>
                                          ) : each?.type === "Emoji" ? (
                                            <>
                                              <div className="row text-center">
                                                <div className={"col-md-12"}>
                                                  <div
                                                    className={
                                                      "row smiley-iconsbox"
                                                    }
                                                  >
                                                    {emojis?.map((x, ind) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className={
                                                              "col-2 text-sm-center"
                                                            }
                                                            style={{
                                                              marginLeft:
                                                                ind === 1 &&
                                                                "10px",
                                                            }}
                                                          >
                                                            <div>
                                                              <i
                                                                className="fas fa-check"
                                                                style={{
                                                                  color:
                                                                    "green",
                                                                  visibility:
                                                                    Number(
                                                                      userInput
                                                                        ?.questions[
                                                                        i
                                                                      ]
                                                                        ?.option_id[0]
                                                                        ?.remark_text
                                                                    ) ===
                                                                      ind + 1 ||
                                                                    Number(
                                                                      userInput
                                                                        ?.questions[
                                                                        i
                                                                      ]
                                                                        ?.remark_text
                                                                    ) ===
                                                                      ind + 1
                                                                      ? "visible"
                                                                      : "hidden",
                                                                }}
                                                              />
                                                            </div>
                                                            <label
                                                              class="smiley-icons"
                                                              onClick={() => {
                                                                updateLocalResponse(
                                                                  {
                                                                    target: {
                                                                      value:
                                                                        ind + 1,
                                                                    },
                                                                  },
                                                                  i,
                                                                  each.id,
                                                                  optItem.id,
                                                                  "Rating",
                                                                  qItem
                                                                );
                                                              }}
                                                            >
                                                              {x}
                                                              <div class="smilytxtsmall">
                                                                {ind === 0
                                                                  ? "Very Dissatisfied"
                                                                  : ind === 4
                                                                  ? "Very Satisfied"
                                                                  : ""}
                                                              </div>
                                                            </label>
                                                          </div>
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : each.type === "Slider" ? (
                                            <>
                                              <div class="ratingpoll d-inline-block" style={{
                                                width: '100%'
                                              }}>
                                                <Box sx={{
                                                  width: '100%',
                                                  border: '0.5px solid black',
                                                  borderRadius: '20px',
                                                  padding: '25px'
                                                }}>
                                                  <Slider
                                                    aria-label="Question-Slider"
                                                    defaultValue={0}
                                                    value={
                                                      getSliderValue(each?.id)
                                                    }
                                                    marks={
                                                      Array.from({ length: (optItem?.max) + 1 }, (_, i) => i + 1).map((each) => {
                                                        if (each === (optItem?.max) + 1) {
                                                          return {
                                                            label: 0,
                                                            value: 0
                                                          }
                                                        } else {
                                                          return {
                                                            label: each,
                                                            value: each
                                                          }
                                                        }
                                                      })
                                                    }
                                                    getAriaValueText={(e) => {
                                                      return e.toString();
                                                    }}
                                                    onChange={(event) => {
                                                      updateLocalResponse(
                                                        event,
                                                        i,
                                                        each.id,
                                                        optItem.id,
                                                        "Rating",
                                                        qItem
                                                      );
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    step={1}
                                                    min={0}
                                                    max={optItem?.max}
                                                  />
                                                </Box>
                                                {each?.type === "Slider" &&
                                                  optItem?.allow_feedback &&
                                                  (optItem?.showFeedback ||
                                                    (view === "true" &&
                                                      each?.answers[0]
                                                        ?.feedback !== "")) && (
                                                    <>
                                                      <div class="mt-3 innergroup">
                                                        <textarea
                                                          name="RatingFeedback"
                                                          className="form-control"
                                                          placeholder="Remarks"
                                                          id="sliderRemarks"
                                                          maxLength={500}
                                                          style={{
                                                            height:
                                                              view === "true"
                                                                ? "200px"
                                                                : "50px",
                                                            border:
                                                              view !== "true" &&
                                                              ((qItem ===
                                                                "questions" &&
                                                                validation[i]
                                                                  ?.feedback) ||
                                                                (qItem !==
                                                                  "questions" &&
                                                                  validation[
                                                                    qItem
                                                                  ][i]
                                                                    ?.feedback)) &&
                                                              "0.5px solid red",
                                                          }}
                                                          onChange={(e) => {
                                                            updateLocalResponse(
                                                              e,
                                                              i,
                                                              each.id,
                                                              optItem.id,
                                                              "Rating",
                                                              qItem
                                                            );
                                                          }}
                                                          value={
                                                            each?.answers[iddx]
                                                              ?.feedback
                                                          }
                                                        />
                                                      </div>
                                                      {view !== "true" &&
                                                      qItem !== "" &&
                                                      validation[qItem][i]
                                                        ?.feedback ? (
                                                        <span class="text-danger ml-1">
                                                          *Kindly Fill Remarks
                                                        </span>
                                                      ) : (
                                                        view !== "true" &&
                                                        qItem === "questions" &&
                                                        validation[i]
                                                          ?.feedback && (
                                                          <span class="text-danger ml-1">
                                                            *Kindly Fill Remarks
                                                          </span>
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                              </div>
                                            </>
                                          ) : each.type === "Short Answer" ? (
                                            <div class="innergroup mt-4">
                                              <input
                                                disabled={
                                                  (curAssess.permission_type ===
                                                    "SV" &&
                                                    curAssess?.status ===
                                                      "Submitted") ||
                                                  view === "true"
                                                }
                                                type="text"
                                                class="form-control"
                                                placeholder="Please give your short Answer"
                                                minLength={optItem.min}
                                                maxLength={optItem.max}
                                                value={
                                                  each?.answers[0]?.remark_text
                                                }
                                                onChange={(e) =>
                                                  updateLocalResponse(
                                                    e,
                                                    idx,
                                                    each.id,
                                                    optItem.id,
                                                    each.type
                                                  )
                                                }
                                              />

                                              {/* {
                                            each?.answers.length>0?(
                                              each?.answers?.map((anitem,anidx)=>{
                                                
                                                  
                                                <input
                                                  disabled={curAssess.permission_type ==="SV"&& curAssess?.status==="Submitted"}
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Please give your short Answer"
                                                  minLength={optItem.min}
                                                  maxLength={optItem.max}
                                                  // value={anitem?.remark_text}
                                                  
                                                  onChange={(e)=>updateLocalResponse(e,idx,each.id,optItem.id,each.type)} 
                                                />
                                              
                                                
                                                })
                                              ):(<input
                                                
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Please give your short Answer"
                                                  minLength={optItem.min}
                                                  maxLength={optItem.max}
                                                  
                                                  
                                                  onChange={(e)=>updateLocalResponse(e,idx,each.id,optItem.id,each.type)} 
                                                />)
                                          } */}
                                            </div>
                                          ) : each?.type === "Long Answer" ? (
                                            iddx === 0 ? (
                                              <div class="answerboxpollsuy innergroup">
                                                {(curAssess.permission_type ===
                                                  "SV" &&
                                                  curAssess?.status ===
                                                    "Submitted") ||
                                                view === "true" ? (
                                                  <div
                                                    class="col-md-12"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        each?.answers[0]
                                                          ?.remark_text,
                                                    }}
                                                  ></div>
                                                ) : (
                                                  <CKEditor
                                                    editor={ClassicEditor}
                                                    name="question"
                                                    onReady={(editor) => {
                                                      console.log(
                                                        "Editor is ready to use!",
                                                        editor
                                                      );
                                                    }}
                                                    data={
                                                      each?.answers[0]
                                                        ?.remark_text
                                                    }
                                                    onChange={(
                                                      event,
                                                      editor
                                                    ) => {
                                                      const data =
                                                        editor.getData();
                                                      updateLocalResponse(
                                                        {
                                                          currentTarget: {
                                                            value: data,
                                                          },
                                                        },
                                                        idx,
                                                        each.id,
                                                        optItem.id,
                                                        each.type
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : each?.type === "Date" ? (
                                            <div class="innergroup mb-2">
                                              <input
                                                disabled={
                                                  (curAssess.permission_type ===
                                                    "SV" &&
                                                    curAssess?.status ===
                                                      "Submitted") ||
                                                  view === "true"
                                                }
                                                type="date"
                                                class="form-control"
                                                value={moment(
                                                  each?.answers[0]?.remark_text
                                                ).format("YYYY-MM-DD")}
                                                onChange={(e) =>
                                                  updateLocalResponse(
                                                    e,
                                                    idx,
                                                    each.id,
                                                    optItem.id,
                                                    "Rating",
                                                    qItem
                                                  )
                                                }
                                              />
                                              <br />
                                            </div>
                                          ) : (
                                            <div
                                              class="answerboxpollsuy innergroup text-center"
                                              style={{
                                                background: "none",
                                              }}
                                            >
                                              {/* <span>
                                              {String.fromCharCode(65 + iddx)}.
                                            </span> */}
                                              {(i === 0 ||
                                                asmntData[qItem][i - 1]
                                                  ?.options[iddx]?.option !==
                                                  optItem?.option) && (
                                                <div
                                                  className="text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    height: "40px",
                                                  }}
                                                >
                                                  {optItem.option}
                                                </div>
                                              )}
                                              <label class="logCheck d-inline-block">
                                                {each?.answers?.length > 0 ? (
                                                  each?.answers?.map(
                                                    (anitem, anidx) =>
                                                      optItem.option ===
                                                      anitem?.answer_options ? (
                                                        <input
                                                          disabled={
                                                            (curAssess.permission_type ===
                                                              "SV" &&
                                                              curAssess?.status ===
                                                                "Submitted") ||
                                                            view === "true"
                                                          }
                                                          checked
                                                          value={"demo"}
                                                          onChange={(e) =>
                                                            updateLocalResponse(
                                                              e,
                                                              idx,
                                                              each.id,
                                                              optItem.id,
                                                              each.type
                                                            )
                                                          }
                                                          type={
                                                            each?.type ===
                                                            "Multiple Choice"
                                                              ? "checkbox"
                                                              : "radio"
                                                          }
                                                          name={
                                                            each.type ===
                                                            "Multiple Choice"
                                                              ? `surveyanswer${each.id}${optItem.id}`
                                                              : `surveyanswer${each.id}`
                                                          }
                                                        />
                                                      ) : (
                                                        <input
                                                          disabled={
                                                            (curAssess.permission_type ===
                                                              "SV" &&
                                                              curAssess?.status ===
                                                                "Submitted") ||
                                                            view === "true"
                                                          }
                                                          value={"demo"}
                                                          onChange={(e) =>
                                                            updateLocalResponse(
                                                              e,
                                                              idx,
                                                              each.id,
                                                              optItem.id,
                                                              each.type
                                                            )
                                                          }
                                                          type={
                                                            each?.type ===
                                                            "Multiple Choice"
                                                              ? "checkbox"
                                                              : "radio"
                                                          }
                                                          name={
                                                            each.type ===
                                                            "Multiple Choice"
                                                              ? `surveyanswer${each.id}${optItem.id}`
                                                              : `surveyanswer${each.id}`
                                                          }
                                                        />
                                                      )
                                                  )
                                                ) : (
                                                  <input
                                                    value={"demo"}
                                                    disabled={
                                                      (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                          "Submitted") ||
                                                      view === "true"
                                                    }
                                                    onChange={(e) =>
                                                      updateLocalResponse(
                                                        e,
                                                        idx,
                                                        each.id,
                                                        optItem.id,
                                                        each.type
                                                      )
                                                    }
                                                    type={
                                                      each?.type ===
                                                      "Multiple Choice"
                                                        ? "checkbox"
                                                        : "radio"
                                                    }
                                                    name={
                                                      each.type ===
                                                      "Multiple Choice"
                                                        ? `surveyanswer${each.id}${optItem.id}`
                                                        : `surveyanswer${each.id}`
                                                    }
                                                  />
                                                )}

                                                <span class="checkmark"></span>
                                              </label>
                                              {each?.select_feedback &&
                                                each?.select_feedback_option ===
                                                  optItem?.option &&
                                                isFeedback.includes(
                                                  optItem?.id
                                                ) && (
                                                  <div
                                                    class="mt-4 innergroup"
                                                    style={{
                                                      border:
                                                        "0.5px solid grey",
                                                      borderRadius: "10px",
                                                    }}
                                                  >
                                                    <textarea
                                                      style={{
                                                        height: "40px",
                                                        fontWeight: "normal",
                                                      }}
                                                      name="RatingFeedback"
                                                      className="form-control"
                                                      placeholder="Remark"
                                                      onChange={(e) => {
                                                        updateLocalResponse(
                                                          e,
                                                          iddx,
                                                          each.id,
                                                          optItem?.id,
                                                          "Single"
                                                        );
                                                      }}
                                                      //   value={question?.answers[iddx]?.feedback}
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    )
                                  ) : each?.option_type === "file" ? (
                                    each?.options.map((optItem, iddx) => (
                                      // <div class="col-md-12">
                                      //   <div class="answerboxpollsuy innergroup">
                                      //     <span>A.</span>
                                      //     <label class="logCheck d-inline-block">
                                      //       {optItem.option}
                                      //       <input type="radio" name="surveyanswer" />
                                      //       <span class="checkmark"></span>
                                      //     </label>
                                      //   </div>
                                      // </div>

                                      // <div class="col-md-3">
                                      // <label class="logCheck d-inline-block">
                                      //   {/* <div class="mb-1">Lorem Ipsum is simply</div> */}
                                      //   <img src= {optItem.option} class="border" />
                                      //   <input disabled={curAssess.permission_type ==="SV"&& curAssess?.status==="Submitted"} type="radio" name="surveyanswer1" />
                                      //   <span class="checkmark"></span>
                                      // </label>
                                      // </div>

                                      <div class="col-md-3 mb-2">
                                        <div class="innergroup">
                                          <span>
                                            {String.fromCharCode(65 + iddx)}.
                                          </span>

                                          <label class="logCheck d-inline-block">
                                            <img
                                              src={optItem.option}
                                              // class="border"
                                            />

                                            {each?.answers?.length > 0 ? (
                                              each?.answers?.map(
                                                (anitem, anidx) =>
                                                  optItem.option ===
                                                  anitem?.answer_options ? (
                                                    <input
                                                      disabled={
                                                        (curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status ===
                                                            "Submitted") ||
                                                        view === "true"
                                                      }
                                                      checked
                                                      value={"demo"}
                                                      onChange={(e) =>
                                                        updateLocalResponse(
                                                          e,
                                                          idx,
                                                          each.id,
                                                          optItem.id,
                                                          each.type
                                                        )
                                                      }
                                                      type="radio"
                                                      name={
                                                        each.type ===
                                                        "Multiple Choice"
                                                          ? `surveyanswer${each.id}${optItem.id}`
                                                          : `surveyanswer${each.id}`
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      disabled={
                                                        (curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status ===
                                                            "Submitted") ||
                                                        view === "true"
                                                      }
                                                      value={"demo"}
                                                      onChange={(e) =>
                                                        updateLocalResponse(
                                                          e,
                                                          idx,
                                                          each.id,
                                                          optItem.id,
                                                          each.type
                                                        )
                                                      }
                                                      type="radio"
                                                      name={
                                                        each.type ===
                                                        "Multiple Choice"
                                                          ? `surveyanswer${each.id}${optItem.id}`
                                                          : `surveyanswer${each.id}`
                                                      }
                                                    />
                                                  )
                                              )
                                            ) : (
                                              <input
                                                value={"demo"}
                                                disabled={
                                                  (curAssess.permission_type ===
                                                    "SV" &&
                                                    curAssess?.status ===
                                                      "Submitted") ||
                                                  view === "true"
                                                }
                                                onChange={(e) =>
                                                  updateLocalResponse(
                                                    e,
                                                    idx,
                                                    each.id,
                                                    optItem.id,
                                                    each.type
                                                  )
                                                }
                                                type="radio"
                                                name={
                                                  each.type ===
                                                  "Multiple Choice"
                                                    ? `surveyanswer${each.id}${optItem.id}`
                                                    : `surveyanswer${each.id}`
                                                }
                                              />
                                            )}

                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    ""
                                  )}
                                  {validation[i]?.mandatory ? (
                                    <span class="text-danger ml-1">
                                      *Kindly attemp this question
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {asmntData[qItem]?.length > 1 &&
                                    i !== asmntData[qItem]?.length - 1 && (
                                      <hr />
                                    )}
                                </div>
                              )}
                            </>
                          );
                        }
                      })}
                      {Object.keys(asmntData)?.length > 1 &&
                        idx !== Object.keys(asmntData)?.length - 1 && <hr />}
                    </div>
                  );
                })}
            </div>

            <div class="border-top mb-3 mt-4 mt-sm-5"></div>

            {isLoading ? (
              <div class="innerheadsec">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button
                      onClick={() => navigate("/survey-assessment")}
                      class="btn btn-outline-danger mr-2"
                    >
                      Back
                    </button>
                    {curAssess?.status !== "Submitted" &&
                    curAssess?.user_view_type !== "full" &&
                    view === "false" ? (
                      <button
                        onClick={() => goPrevious()}
                        class="btn btn-outline-secondary mr-2"
                      >
                        <i class="far fa-arrow-alt-circle-left"></i> Previous
                      </button>
                    ) : (
                      ""
                    )}
                    {view === "false" && (
                      <button
                        onClick={() => goNext()}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-arrow-alt-circle-right"></i>{" "}
                        {curAssess?.user_view_type === "full"
                          ? "Submit"
                          : "Next/Submit"}
                      </button>
                    )}

                    {curAssess?.compulsory === false && view === "false" && (
                      <button
                        onClick={() => openConfirmModel()}
                        class="ml-2 btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-arrow-alt-circle-right"></i> Skip
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewassess;
