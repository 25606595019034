import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import * as NPSServices from "../../service/NpsServices";
import { useEffect } from "react";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  nps_contribution: Yup.string()
    .required("*Select Voluntary NPS Contribution%")
    .nullable(),
  effective_date: Yup.string().required("*Select Effective Date").nullable(),
  pran_number: Yup.string()
    .required("*PRAN Number is required")
    .max(12, "*pran_number Must be 12 characters")
    .min(12, "*pran_number Must be 12 characters"),
  // .optional()
  // effective_date: Yup.date().required("*Select Effective Date").min(Yup.ref('startDate')),
});

const NpsDeclaration = () => {
  const dispatch = useDispatch();
  let condition = "true";
  const [npsData, setNpsData] = useState({
    pran_number: "",
    nps_contribution: "",
    effective_date: "",
    mobile_no: "",
  });
  const [showData, setShowData] = useState(true);
  const [secShowData, setSecShowData] = useState(false);
  const [npsUser, setNpsUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAlertFlag, setIsAlertFlag] = useState(false);
  const [currentTab, setCurrentTab] = useState("P");
  const [npsReportPendingList, setNpsReportPendingList] = useState([]);
  const [allNpsData, setAllNpsData] = useState([]);
  const userData = useSelector(selectUserData);

  const SaveNps = (values) => {
    if (condition == "true") {
      dispatch(setDisplayLoader("Display"));
      if (isAlertFlag == true) {
        setIsAlert(true);
      } else {
        setIsLoading(true);
        setIsAlert(false);
        condition = "false";
        NPSServices.saveNps(values)
          .then((response) => {
            dispatch(setDisplayLoader("Hide"));
            if (response.data.status == true && response.data.code == 200) {
              setIsLoading(false);
              if (response.data.message == "NPS created successfully") {
                toast.success(response.data.message);
                condition = "true";
              } else {
                toast.error(response.data.message);
                condition = "true";
              }
            }
          })
          .catch((error) => {
            dispatch(setDisplayLoader("Hide"));
            setIsLoading(false);
            toast.error(error.response.data.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };

  const getNpsUser = (id) => {
    NPSServices.getUserNps(id)
      .then((response) => {
        setNpsUser(response?.data?.dataList);
        setNpsData({
          ...npsData,
          mobile_no: response?.data?.dataList?.business_mobile,
        });
        setShowFlag(true);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const showGridData = () => {
    let myCheckedData = npsData;
    if (myCheckedData.checked === true) {
      setNpsData(myCheckedData);
    } else {
      setNpsData(null);
    }
  };

  useEffect(() => {
    getNpsUser(0);
    getNpsData();
  }, [currentTab]);

  const getNpsData = () => {
    NPSServices.getNpsData()
      .then((response) => {
        let pendingData = response?.data?.dataList?.pending.map((pd, i) => {
          pd.status = "N";
          return pd;
        });
        setNpsReportPendingList({
          pending: pendingData,
        });
        setAllNpsData(response?.data?.dataList?.result);
        if (response?.data?.dataList?.result?.length > 0) {
          setNpsData({
            ...npsData,
            pran_number: response?.data?.dataList?.result[0]?.pran_number,
          });
        }
        // setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
        // setRejectList(response?.data?.dataList?.rejected);
        // setSendToSap(response?.data?.dataList?.send_to_sap);
        // getAllEmployeIdName(false);
        // getAllEmployeIdName(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const forAlertComming = (e, namecon) => {
    if (namecon == "Available") {
      setIsAlertFlag(true);
    } else {
      setIsAlert(false);
      setIsAlertFlag(false);
    }
  };

  const clearAllField = () => {
    window.location.replace("/nps-declaration");
    setNpsData({
      ...npsData,
      pran_number: "",
      nps_contribution: "",
      effective_date: "",
    });
    let clearBar1 = document.getElementById("pran_number");
    let clearBar2 = document.getElementById("nps_contribution");
    let clearBar3 = document.getElementById("effective_date");
    if (clearBar1?.value != "") {
      clearBar1.value = "";
    }
    if (clearBar2?.value != "") {
      clearBar2.value = "";
    }
    if (clearBar3?.value != "") {
      clearBar3.value = "";
    }
  };

  const myFuncNum = (e) => {
    console.log(e.target.value, "Hhhdhhdj");
    if (e.target.value > 12) {
      e.target.value.slice(0, 12);
    }
  };

  if (showFlag === true) {
    return (
      <>
        {npsUser?.nps_flag === true ? (
          <div>
            <ToastContainer autoClose={1000} />

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative mt-5">
              <Tabs
                defaultActiveKey="P"
                id="approvaltabs"
                onSelect={(e) => setCurrentTab(e)}
              >
                <Tab
                  eventKey="P"
                  title={<React.Fragment>NPS Declaration</React.Fragment>}
                >
                  <div class="content-wrapper-inner">
                    <div class="innerheadsec pb-0">
                      <div className="row">
                        <div class="col-md-12">
                          <h4 class="inner-page-title">NPS Declaration</h4>
                          {/* <Breadcrumb>
                                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/">
                                        Me@PI
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>NPS Declaration</Breadcrumb.Item>
                                </Breadcrumb> */}
                        </div>
                        <div class="col-md-12">
                          <div class="border-top mt-2 mb-3"></div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9">
                          <Formik
                            initialValues={npsData}
                            validationSchema={
                              isAlertFlag == false && showData == true
                                ? validationSchema
                                : ""
                            }
                            onSubmit={async (values) => {
                              if (values) {
                                //   updatePolicy(values);
                                SaveNps(values);
                                //   AuditLog.addAuditLog(dispatch, nameForm);
                              }

                              await new Promise((resolve) =>
                                setTimeout(resolve, 500)
                              );
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div class="row">
                                  <>
                                    {/* {showData ? ( */}
                                    <div class="col-md-12">
                                      <div class="form-group innergroup answerboxpollsuy">
                                        {/* {showData ? ( */}
                                        <label className="d-block mb-2">
                                          Do you have Existing NPS Account{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        {/* ) : ( */}
                                        {/* <label className="d-block mb-2">
                                          Do you wish to enroll for Corporate
                                          Nps <span class="text-danger">*</span>
                                        </label> */}
                                        {/* )} */}
                                        <label class="logCheck d-inline-block mr-4">
                                          Yes
                                          <input
                                            type="radio"
                                            name="Available"
                                            onClick={() => {
                                              setShowData(true);
                                            }}
                                            value={values.showData}
                                            checked={
                                              showData == true ? true : false
                                            }
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="logCheck d-inline-block">
                                          No
                                          <input
                                            type="radio"
                                            name="Available"
                                            onClick={(e) => {
                                              setShowData(false);
                                            }}
                                            value={values.showData}
                                            checked={
                                              showData == false ? true : false
                                            }
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    {/* ) : ( */}
                                    {showData == false && (
                                      <div className="ml-3">
                                        Please Open NPS Account <br />
                                        <a
                                          href="https://prod-mypi-public.s3.ap-south-1.amazonaws.com/NPS/CorporatePRANcreationJourneyKCRANew.pdf"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          User Guide for NPS account opening
                                        </a>
                                      </div>
                                      // <div class="col-md-12">
                                      //   <div class="form-group innergroup answerboxpollsuy">
                                      //     <label className="d-block mb-2">
                                      //       Do you wish to enroll for Corporate Nps{" "}
                                      //       <span class="text-danger"></span>
                                      //     </label>
                                      //     <label class="logCheck d-inline-block mr-4">
                                      //       Yes
                                      //       <input
                                      //         type="radio"
                                      //         name="Available1"
                                      //         checked={secShowData}
                                      //         onClick={(e) => {
                                      //           setSecShowData(true);
                                      //           forAlertComming(e, "not_Available");
                                      //         }}
                                      //       />
                                      //       <span class="checkmark"></span>
                                      //     </label>
                                      //     <label class="logCheck d-inline-block">
                                      //       No
                                      //       <input
                                      //         type="radio"
                                      //         name="Available1"
                                      //         onClick={(e) => {
                                      //           setSecShowData(false);
                                      //           setIsChecked(true);
                                      //           forAlertComming(e, "Available");
                                      //         }}
                                      //         checked={isAlertFlag}
                                      //       />
                                      //       <span class="checkmark"></span>
                                      //     </label>
                                      //   </div>
                                      //   {isAlert ? (
                                      //     <div
                                      //       class="alert alert-success"
                                      //       role="alert"
                                      //     >
                                      //       Response Saved Succesfully!
                                      //     </div>
                                      //   ) : (
                                      //     ""
                                      //   )}
                                      // </div>
                                    )}
                                    {/* )} */}
                                  </>
                                  {showData ? (
                                    <>
                                      <div class="col-md-4">
                                        <div class="form-group innergroup">
                                          <label>
                                            Name
                                            <span class="text-danger">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            name="username"
                                            value={
                                              npsUser?.full_name +
                                              "(" +
                                              npsUser?.username +
                                              ")"
                                            }
                                            disabled
                                            placeholder="Enter Name"
                                            class="form-control"
                                          />
                                        </div>
                                      </div>

                                      <div class="col-md-4">
                                        <div class="form-group innergroup">
                                          <label>
                                            Mobile Number{" "}
                                            <span class="text-danger"></span>
                                          </label>
                                          {npsUser?.business_mobile !== null ? (
                                            <input
                                              type="text"
                                              // disabled
                                              name="mobile_no"
                                              onChange={(e) => {
                                                handleChange(e);
                                                setNpsData({
                                                  ...npsData,
                                                  mobile_no: e.target.value,
                                                });
                                              }}
                                              placeholder="Enter Mobile Number"
                                              value={values.mobile_no}
                                              class="form-control"
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              name="mobile_no"
                                              // disabled
                                              placeholder="Enter Mobile Number"
                                              onChange={(e) => {
                                                handleChange(e);
                                                setNpsData({
                                                  ...npsData,
                                                  mobile_no: e.target.value,
                                                });
                                              }}
                                              value={values.mobile_no}
                                              class="form-control"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <div class="col-md-4">
                                        <div class="form-group innergroup">
                                          <label>
                                            PRAN Number{" "}
                                            <span class="text-danger">*</span>
                                          </label>
                                          <input
                                            type="number"
                                            placeholder="Enter PRAN Number"
                                            name="pran_number"
                                            id="pran_number"
                                            class="form-control"
                                            onBlur={handleBlur}
                                            //   onInput={(e) =>{
                                            //     myFuncNum(e)
                                            //   }
                                            // }
                                            onInput={(e) =>
                                              (e.target.value =
                                                e.target.value.slice(0, 12))
                                            }
                                            onChange={(e) => {
                                              handleChange(e);
                                              setNpsData({
                                                ...npsData,
                                                pran_number: e.target.value,
                                              });
                                            }}
                                            value={values.pran_number}
                                          />
                                          <p>
                                            {touched.pran_number &&
                                            errors.pran_number ? (
                                              <div className="small text-danger">
                                                {errors.pran_number}
                                              </div>
                                            ) : null}
                                          </p>
                                        </div>
                                      </div>

                                      <div class="col-md-4">
                                        <div class="form-group innergroup">
                                          <label>
                                            Voluntary NPS Contribution %{" "}
                                            <span class="text-danger">*</span>
                                          </label>
                                          <select
                                            name="nps_contribution"
                                            id="nps_contribution"
                                            class="form-control newbgselect"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setNpsData({
                                                ...npsData,
                                                nps_contribution:
                                                  e.target.value,
                                              });
                                            }}
                                            value={values.nps_contribution}
                                          >
                                            {/* <option>Select</option> */}
                                            <option
                                              value=""
                                              label="Select"
                                              disabled
                                            />
                                            <option value="5%">5%</option>
                                            <option value="10%">10%</option>
                                          </select>
                                          {touched.nps_contribution &&
                                          errors.nps_contribution ? (
                                            <div className="small text-danger">
                                              {errors.nps_contribution}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>

                                      <div class="col-md-4">
                                        <div class="form-group innergroup">
                                          <label>
                                            Effective Date{" "}
                                            <span class="text-danger">*</span>
                                          </label>
                                          {npsUser?.joiningDate
                                            ?.date_of_joining < "2024-04-01" ? (
                                            <input
                                              type="date"
                                              class="form-control"
                                              name="effective_date"
                                              id="effective_date"
                                              onBlur={handleBlur}
                                              min={"2024-04-01"}
                                              max={"2025-03-31"}
                                              onChange={(e) => {
                                                handleChange(e);
                                                setNpsData({
                                                  ...npsData,
                                                  effective_date:
                                                    e.target.value,
                                                });
                                              }}
                                              value={values.effective_date}
                                            />
                                          ) : (
                                            <input
                                              type="date"
                                              class="form-control"
                                              name="effective_date"
                                              id="effective_date"
                                              onBlur={handleBlur}
                                              min={
                                                new Date(
                                                  npsUser?.joiningDate?.date_of_joining
                                                ).getMonth() + 1
                                              }
                                              max={"2025-03-31"}
                                              onChange={(e) => {
                                                handleChange(e);
                                                setNpsData({
                                                  ...npsData,
                                                  effective_date:
                                                    e.target.value,
                                                });
                                              }}
                                              value={values.effective_date}
                                            />
                                          )}
                                          {/* */}
                                          {touched.effective_date &&
                                          errors.effective_date ? (
                                            <div className="small text-danger">
                                              {errors.effective_date}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {secShowData ? (
                                        <>
                                          <div class="col-md-4">
                                            <div class="form-group innergroup">
                                              <label>
                                                Voluntary NPS Contribution %{" "}
                                                <span class="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <select
                                                name="nps_contribution"
                                                class="form-control newbgselect"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setNpsData({
                                                    ...npsData,
                                                    nps_contribution:
                                                      e.target.value,
                                                  });
                                                }}
                                                value={values.nps_contribution}
                                              >
                                                {/* <option>Select</option> */}
                                                <option
                                                  value=""
                                                  label="Select"
                                                  disabled
                                                />
                                                <option value="5%">5%</option>
                                                <option value="10%">10%</option>
                                              </select>
                                              {touched.nps_contribution &&
                                              errors.nps_contribution ? (
                                                <div className="small text-danger">
                                                  {errors.nps_contribution}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div class="col-md-4">
                                            <div class="form-group innergroup">
                                              <label>
                                                Effective Date{" "}
                                                <span class="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              {npsUser?.joiningDate
                                                ?.date_of_joining <
                                              "2023-04-01" ? (
                                                <input
                                                  type="date"
                                                  class="form-control"
                                                  name="effective_date"
                                                  id="effective_date"
                                                  onBlur={handleBlur}
                                                  min={"2024-04-01"}
                                                  max={"2025-03-31"}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    setNpsData({
                                                      ...npsData,
                                                      effective_date:
                                                        e.target.value,
                                                    });
                                                  }}
                                                  value={values.effective_date}
                                                />
                                              ) : (
                                                <input
                                                  type="date"
                                                  class="form-control"
                                                  name="effective_date"
                                                  id="effective_date"
                                                  onBlur={handleBlur}
                                                  min={
                                                    new Date(
                                                      npsUser?.joiningDate?.date_of_joining
                                                    ).getMonth() + 2
                                                  }
                                                  max={"2024-03-31"}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    setNpsData({
                                                      ...npsData,
                                                      effective_date:
                                                        e.target.value,
                                                    });
                                                  }}
                                                  value={values.effective_date}
                                                />
                                              )}
                                              {/* */}
                                              {touched.effective_date &&
                                              errors.effective_date ? (
                                                <div className="small text-danger">
                                                  {errors.effective_date}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                  {showData && (
                                    <div class="col-md-12 mt-2">
                                      <button
                                        class="btn btn-outline-secondary mr-3"
                                        type="button"
                                        onClick={clearAllField}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        class="btn btn-primary-inner bpi-main"
                                        type="sumbit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>

                        <div class="col-md-3">
                          <div class="card leave-apply-section  mt-3">
                            <div class="row">
                              <div class="col-md-12">
                                <h5 class="text-dark mt-2">
                                  For More Information
                                </h5>

                                <div class="mt-1">
                                  <div class="dashbed-border-bottom"></div>
                                </div>

                                <div class="table-responsive mt-2 mb-0">
                                  <table
                                    class="table  table-bordered leavestatustab npsinfosec"
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tbody>
                                      <tr>
                                        <td>1</td>
                                        <td>
                                          <a
                                            href="https://mypi-public.s3.ap-south-1.amazonaws.com/NPS/ICICI%20Pru%20Annuity%20details.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            ICICI Pru Annuity details
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>2</td>
                                        <td>
                                          <a
                                            href="https://mypi-public.s3.ap-south-1.amazonaws.com/NPS/Inter%20CRA%20Subscriber%20Shifting%20Form.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Inter CRA Subscriber Shifting Form
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>
                                          <a
                                            href="https://mypi-public.s3.ap-south-1.amazonaws.com/NPS/Intersector_Shifting-Form_ISS%20KCRA.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Intersector Shifting-Form ISS KCRA
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>4</td>
                                        <td>
                                          <a
                                            href={userData?.nps_user_guide}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            User Guide for NPS account opening
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>5</td>
                                        <td>
                                          <a
                                            href="https://mypi-public.s3.ap-south-1.amazonaws.com/NPS/Presentation%20on%20NPS%20for%20PI.pdf"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Presentation on NPS for PI
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>6</td>
                                        <td>
                                          <a
                                            href="https://www.iciciprupensionfund.com/NPS/#/"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            NPS Portal
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="A"
                  title={<React.Fragment>View Declaration List</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Mobile No</th>
                          <th>PRAN</th>
                          <th>Contribution</th>
                          <th>Effective From</th>
                          <th>Submitted Date</th>
                          <th>Status</th>
                          {/* <th>Sent to SAP</th> */}
                          {/* <th>SAP Response</th>
                      <th>SAP Status</th> */}
                          {/* <th>SAP Response</th>
                                                    <th>Created on</th> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {allNpsData?.length ? (
                          allNpsData?.map((data, index) => (
                            <>
                              <tr>
                                <td>
                                  {/* <Link to="/viewjv" class="text-theme"> */}
                                  {data.user_name} ({data?.emp_code})
                                  {/* </Link> */}
                                </td>
                                {data?.mobile_no !== null ? (
                                  <td>{data.mobile_no}</td>
                                ) : (
                                  <td>Number Unavailable</td>
                                )}
                                <td>{data.pran_number}</td>
                                <td>{data.nps_contribution}</td>
                                <td>
                                  {data.effective_date === null
                                    ? ""
                                    : moment(data.effective_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                </td>
                                {/* <td>{data.sent_to_sap}</td> */}
                                {/* <td>
                              {data?.sap_error !== null && (
                                <Tooltip
                                  title={data?.sap_error}
                                  position="bottom"
                                >
                                  {data?.sap_error?.substr(0, 12)}...
                                </Tooltip>
                              )}
                            </td>
                            {data?.sap_status === true ? (
                              <td>Synced</td>
                            ) : (
                              <td>Pending to Sync</td>
                            )} */}
                                <td>
                                  {moment(data?.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                {data?.sent_to_sap === "Y" ? (
                                  <td>
                                    <span class="badge bad-status badge-success">
                                      Sent to SAP
                                    </span>
                                  </td>
                                ) : data?.sent_to_sap === "N" ? (
                                  <td>
                                    <span class="badge bad-status badge-danger">
                                      Discarded
                                    </span>
                                  </td>
                                ) : (
                                  <td>
                                    <span class="badge bad-status badge-primary">
                                      Submitted
                                    </span>
                                  </td>
                                )}
                              </tr>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        ) : (
          <div className="row mt-5 ml-5">
            <div class="card w-50">
              <div class="card-body">
                <h5 class="card-title">Permission Denied</h5>
                <p class="card-text">
                  You have not permission to use this service, please contact to
                  your BHR.
                </p>
                <Link to="/dashboard">
                  <a href="#" class="btn btn-primary">
                    Return to Home Page
                  </a>
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default NpsDeclaration;
