import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from "react-select";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import * as PrServices from '../../../service/prService';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { selectUserData } from "../../redux/piDataStore";
import { useSelector } from "react-redux";
import * as moment from "moment";

const ViewItemForm = (props) => {
    const currentuserdata = useSelector(selectUserData);
  const location = useLocation();
  const { handleClose,itemDetailsFlag, itemValues, data} = props;

  return (
    <div >
      <Modal
        show={itemDetailsFlag}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        {/* <form onSubmit={handleSubmit}> */}
          <Modal.Header closeButton>
            <Modal.Title>View Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              {/* Header Table */}
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      {/* <th>Action</th> */}
                      <th>Item</th>
                    <th>Sub-Item</th>
                    {/* <th>I</th> */}
                    {/* <th>A</th> */}
                    <th>Plant</th>
                    <th>Material</th>
                    <th>Mat Desc</th>
                    {/* <th>Unit</th> */}
                    <th>Qty.</th>
                    <th>Price/ Unit</th>
                    <th>Total Price</th>
                    <th>Del.Date</th>
                    <th>Val.Type</th>
                    <th>Pur.Grp.</th>
                    <th>Track. No.</th>
                    <th>Item Text</th>
                    <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {
                      itemValues.map((items, i) => ( */}
                        <tr>
                          {/* <td>
                            <button className="btn btn-blanktd text-primary f-12" onClick={()=>{handleEditSubItem(i)}}>
                              <i className="far fa-edit"></i>
                            </button>
                            {
                              values.length > 1 &&
                              <span className="btn btn-blanktd text-danger ml-2 f-12" onClick={() => handleDeleteSubItem(i)}>
                                <i className="far fa-trash-alt text-danger"></i>
                              </span>
                            }
                          </td> */}
                          <td>
                      <span class="badge badge-secondary">{itemValues?.item_no}</span>
                    </td>
                    <td>
                      <span style={{cursor:"pointer"}} class="badge badge-primary">{itemValues?.sub_transaction_data?.length}</span>
                    </td>
                    <td>{itemValues?.plant_code}</td>
                    <td>{itemValues?.material_code}</td>
                    <td>{itemValues?.material_desc}</td>
                    {/* <td>{itemValues?.price_per_unit}</td> */}
                    {/* {data?.list?.type === "Service PR" ?
                    <>
                    <td>1</td>
                    <td>{Number(itemValues?.sum_price).toFixed(2)}</td>
                    <td>{Number(itemValues?.sum_price).toFixed(2)}</td>
                    </>
                    : */}
                    <>
                    <td>{itemValues?.requisition_quantity}</td>
                    <td>{Number(itemValues?.valuation).toFixed(2)}</td>
                    <td>{Number(itemValues?.valuation * itemValues?.requisition_quantity).toFixed(2)}</td>
                    </>
                    {/* } */}
                    <td>{itemValues?.delete_date}</td>
                    <td>{itemValues?.valuation_type}</td>
                    <td>{itemValues?.purchase_group_code}</td>
                    <td>{itemValues?.tracking_no}</td>
                    <td>{itemValues?.remarks}</td>
                    {currentuserdata?.username==data?.list?.user_username?(
                      itemValues?.user_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:itemValues?.user_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:itemValues?.user_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):
                    currentuserdata?.username==data?.list?.currentApproverList[0]?.approver_username?(
                      itemValues?.c2_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:itemValues?.c2_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:itemValues?.c2_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):
                    currentuserdata?.username==data?.list?.currentApproverList[1]?.approver_username?(
                      itemValues?.c3_status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:itemValues?.c3_status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:itemValues?.c3_status=="P"?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""):<td></td>}
                        </tr>
                  </tbody>
                </table>
              </div>
              </div>
              {/* Header Table */}
              <div class="row">
              <div class="col-md-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        {/* <th>S.No</th> */}
                        <th>Item No</th>
                        <th>Approver Stage</th>
                        <th>Approver Name</th>
                        <th>Approved/Rejected Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.currentApproverList?.map((a)=>(
                        itemValues?.item_no === a?.item_no &&
                      <tr>
                        {/* <td>1</td> */}
                        <td>{a?.item_no}</td>

                        <td>{a?.approver_type}</td>
                        <td class="text-theme fw-bold">{a?.approver_name}</td>
                        {a?.status === "A" || a?.status === "D" ?
                        <td>{moment(a?.updated_at).format("DD-MM-YYYY")}</td>
                        :
                        <td></td>
                      }
                        <td>{a?.remark}</td>
                        {a?.status=="A"?<td>
                          <span class="badge badge-success">Approved</span>
                        </td>:a?.status=="D"?<td>
                          <span class="badge badge-danger">Rejected</span>
                        </td>:(a?.status==null||a?.status=="P")?<td>
                          <span class="badge badge-secondary">Pending</span>
                        </td>:""}
                      </tr>))}
                    
                       
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

             
            
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={()=>{handleClose()}} className="btn btn-primary-inner bpi-main">Back</button>
          </Modal.Footer>
        {/* </form> */}
      </Modal>
    </div >
  )
}

export default ViewItemForm