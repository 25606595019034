import React from "react";
import { Breadcrumb, Dropdown, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";

const ViewBeneficiary = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <div>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cash Beneficiary Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row viewformaftersubmit">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>User Name</label>
                <p> {viewFormData?.user_name}</p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook</label>
                <p>{viewFormData?.cashbook_desc}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Beneficiary Code</label>
                <p>{viewFormData?.beneficiary_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Delivery Address</label>
                <p>{viewFormData?.delivery_address}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Payable Branch</label>
                <p>{viewFormData?.payable_branch}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Agency Code</label>
                <p>{viewFormData?.agency_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Agency Name </label>
                <p>{viewFormData?.agency_name}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Branch Code</label>
                <p>{viewFormData?.branch_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Location Code</label>
                <p>{viewFormData?.location_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook Delivery Code</label>
                <p>{viewFormData?.cash_delivery_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Created On</label>
                <p> {moment(viewFormData?.created_at).format("Do MMM YYYY")}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Last Modified On</label>
                <p> {moment(viewFormData?.updated_at).format("Do MMM YYYY")}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-secondary" onClick={onViewClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewBeneficiary;
