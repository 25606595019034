import axios from "./../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getChannelSize = async () => {
    return axios.get(`${AUTH_BASE_URL}/channel-size`);
};

export const getLastChannelSize = async (query) =>{
    return axios.get(`${AUTH_BASE_URL}/channel-size/last-channel-size${query}`)
}

export const postChannelSize = async (body) =>{
    return axios.post(`${AUTH_BASE_URL}/channel-size`,body);
}

export const putChannelSize = async (body) =>{
    return axios.put(`${AUTH_BASE_URL}/channel-size`,body);
}

export const getChannelSizeApprovalLogs = async (query)=>{
    return axios.get(`${AUTH_BASE_URL}/channel-size/approver-channel-size-logs${query}`)
}


export const postChannelSizeAction = async (body)=>{
    return axios.post(`${AUTH_BASE_URL}/channel-size/action-channel-size`,body)
}