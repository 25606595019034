import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal
} from "react-bootstrap";
import Select from "react-select";
import { getPlantList, getUserRmList } from "../../service/jv_service";
import {
  editApprover,
  getPoOrg,
  getSourcelistPlant,
  saveApprover,
} from "../../service/source-list";
import { toast, ToastContainer } from "react-toastify";


function SourceApproverForm(props) {
  let flag=true
  const { show, close, edit, getList, editData } = props;
  const [userlist, setuserlist] = useState([]);
  const navigate = useNavigate();
  const [poOrgList, setPoOrgList] = useState([]);
  const [plantlist, setPlantList] = useState([]);
  const[approver1_id,setapprover1_id]=useState("")
  const[approver2_id,setapprover2_id]=useState("")
  const[additional_approver_id,setadditional_approver_id]=useState("")
  const[requester_id,setrequester_id]=useState("")
  const [additionalFlag,setAdditionalFlag]=useState(false)
  const options = [
    { value: "Capex", label: "Capex" },
    { value: "Engineering", label: "Engineering" },
    { value: "RM/PM", label: "RM/PM" },
  ];
  const plantList = (pag_id) => {
    getSourcelistPlant(pag_id)
      .then((response) => {
        setPlantList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPoList = () => {
    getPoOrg("-id", false)
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code+"("+x?.desc+")" };
          plant.push(data);
        });
        setPoOrgList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const userlistedit=(data2)=>{
    let data=data2.filter((obj) => {
      if(obj.label !==editData?.additional_approver_username&&obj.label !==editData?.approver2_username&&
        obj.label !==editData?.approver1_username&&obj.label !==editData?.requester_username) {
          return obj;
      }
     });
     console.log(data);
     setuserlist(data)
  }
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label:x?.first_name+" "+x?.last_name+"("+x?.username+")" };
          plant.push(data);
        });
        if(edit != true){
        setuserlist(plant);}else{
          userlistedit(plant)
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const [errors, setErrors] = useState({
    selectrequester_id: false,
    selectpurchase_org_id: false,
    selectplant_id: false,
    selectapprover1_id: false,
    selectapprover2_id: false,
    selectadditional_approver_id: false,
    selectcategory: false,
  });
  const [approver, setApprover] = useState({
    requester_id: null,
    purchase_org_id: null,
    plant_id: null,
    approver1_id: null,
    approver2_id: null,
    additional_approver_id:null,
    category: null,
  });
       

  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "plant_id":
        setApprover((prev) => ({
          ...prev,
          plant_id: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectplant_id: false,
        }));
        break;
      case "purchase_org_id":
        setApprover((prev) => ({
          ...prev,
          purchase_org_id: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectpurchase_org_id: false,
        }));
        setApprover((prev) => ({
          ...prev,
          plant_id: [],
        }));
        console.log(event);
        let plant_ids=[]
        event.map((x)=>{
          plant_ids.push(x.value);
        })
        plantList((plant_ids.toString()));
        break;
      case "category":
        setApprover((prev) => ({
          ...prev,
          category: event,
        }));
        setErrors((prev) => ({
          ...prev,
          selectcategory: false,
          selectadditional_approver_id: false
        }));

        // { value: "Capex", label: "Capex" },
        // { value: "Engineering", label: "Engineering" },
        // { value: "RM/PM", label: "RM/PM" },
        if(event.value=="RM/PM"){
          setAdditionalFlag(true)
        }
        else{
          setAdditionalFlag(false)

        }

        break;
      case "requester_id":
       if(requester_id==""){
         setrequester_id(
           event
         );
         setErrors((prev) => ({
           ...prev,
           selectrequester_id: false,
         }));
         let data=userlist.filter((obj) => {
          if(obj.label !== event.label) {
            return obj;
          }
         });
         setuserlist(data);
         console.log(data);
       }else{
        setrequester_id(
          event
        );
        let currentData=requester_id
        setErrors((prev) => ({
          ...prev,
          selectrequester_id: false,
        }));

        let data=userlist.filter((obj) => {
          if(obj.label !== event.label) {
            return obj;
          }
         });
         data.push(currentData)
   
         setuserlist(data);
       }
        break;

      case "approver2_id":

      if(approver2_id==""){

        setapprover2_id(event);
        setErrors((prev) => ({
          ...prev,
          selectapprover2_id: false,
        }));
        let data=userlist.filter((obj) => {
         if(obj.label !== event.label) {
           return obj;
         }
        });
        setuserlist(data);
        console.log(data);
      }else{
        let currentData=approver2_id

        setapprover2_id(event);
        setErrors((prev) => ({
          ...prev,
          selectapprover2_id: false,
        }));

       let data=userlist.filter((obj) => {
         if(obj.label !== event.label) {
           return obj;
         }
        });
        data.push(currentData)
        setuserlist(data);
      }
        break;

      case "approver1_id":
        if(approver2_id==""){

          setapprover1_id(event);
          setErrors((prev) => ({
            ...prev,
            selectapprover1_id: false,
          }))
          let data=userlist.filter((obj) => {
           if(obj.label !== event.label) {
             return obj;
           }
          });
          setuserlist(data);
          console.log(data);
        }else{
          let currentData=approver1_id
  
          setapprover1_id(event);
          setErrors((prev) => ({
            ...prev,
            selectapprover1_id: false,
          }))
  
         let data=userlist.filter((obj) => {
           if(obj.label !== event.label) {
             return obj;
           }
          });
          data.push(currentData)
          setuserlist(data);
        }
        break;

      case "additional_approver_id":
        if(approver2_id==""){

          setadditional_approver_id(event)
          setErrors((prev) => ({
            ...prev,
            selectadditional_approver_id: false,
          }));
          let data=userlist.filter((obj) => {
           if(obj.label !== event.label) {
             return obj;
           }
          });
          setuserlist(data);
          console.log(data);
        }else{
          let currentData=approver1_id
  
          setadditional_approver_id(event)
          setErrors((prev) => ({
            ...prev,
            selectadditional_approver_id: false,
          }));
  
         let data=userlist.filter((obj) => {
           if(obj.label !== event.label) {
             return obj;
           }
          });
          data.push(currentData)
    
          setuserlist(data);
        }
       
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getPoList();
    // plantList();
    getUserList();
  }, []);

  useEffect(() => {
    if (edit == true) {
      setrequester_id({
        value: editData?.requester_id,
        label: editData?.requester_username+"("+editData?.requester_name+")",
      })
      setapprover1_id({
        value: editData?.approver1_id,
        label: editData?.approver1_username+"("+editData?.approver1_name+")",
      })
      setapprover2_id({
        value: editData?.approver2_id,
        label: editData?.approver2_username+"("+editData?.approver2_name+")",
      })
      setadditional_approver_id({
        value: editData?.additional_approver_id,
        label: editData?.additional_approver_username+"("+editData?.additional_approver_name+")",
      })
      if(editData?.category=="RM/PM"){
        setAdditionalFlag(true)
      }
      setApprover({
        id: editData?.id,
        purchase_org_id: editData?.purchase_org_id,
        plant_id:editData?.plant_id,
        category: { value: editData?.category, label: editData?.category },
      });
      let plant_ids=[]
      editData?.purchase_org_id.map((x)=>{
        plant_ids.push(x.value)
      })
      plantList((plant_ids).toString());

    } else if (edit == false) {
      setapprover1_id("")
      setapprover2_id("")
      setadditional_approver_id("")
      setrequester_id("")

      setApprover({
        purchase_org_id: null,
        plant_id: null,
        category: null,
      });
    }
  }, [editData]);
  const submit = () => {
    let additionalFlag=true
      console.log(approver?.category?.value,approver?.additional_approver_id);
      if (approver?.category !== null){
        if(approver?.category?.value=="RM/PM"){
          if(additional_approver_id==""){
            additionalFlag=false
          }
        }
      }
    if (
      requester_id !== null &&
      approver?.purchase_org_id !== null &&
      approver?.plant_id !== null &&
      approver1_id !== null &&
      approver2_id !== null &&
      additionalFlag  &&
      additional_approver_id !== null &&
      approver?.category !== null
    ) {
      flag=false
      approver.requester_id=requester_id
      approver.approver1_id=approver1_id
      approver.approver2_id=approver2_id
      approver.additional_approver_id=additional_approver_id
      if (edit == false) {
        saveApprover(approver)
          .then((response) => {
            flag=true
            close();
            getList();
        toast.success(response.data.message);

          })
          .catch((error) => {
            flag=true
            toast.error(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else if (edit == true) {
        editApprover(approver)
          .then((response) => {
            flag=true
            close();
            getList();
        toast.success(response.data.message);

          })
          .catch((error) => {
            flag=true
            toast.error(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        selectrequester_id: requester_id ? false : true,
        selectpurchase_org_id:approver?.purchase_org_id ? false : true,
        selectplant_id: approver?.plant_id ? false : true,
        selectapprover1_id: approver1_id ? false : true,
        selectapprover2_id: approver2_id ? false : true,
        selectadditional_approver_id: approver?.category?.value === "RM/PM" && !additional_approver_id ? true : false,
        selectcategory: approver?.category ? false : true,
      }));
    }
  };

  return (
    <>
      <ToastContainer></ToastContainer>

      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Assign Approver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
              <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Category<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="category"
                      value={approver?.category}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectcategory}
                      className="small text-danger"
                    >
                      Kindly select Category
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Purchasing Organization<span class="text-danger">*</span>
                    </label>
                    <Select
                    isMulti
                      options={poOrgList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="purchase_org_id"
                      value={approver?.purchase_org_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectpurchase_org_id}
                      className="small text-danger"
                    >
                      Kindly select purchasing organization
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Plant<span class="text-danger">*</span>
                    </label>
                    <Select
                    isMulti
                      options={plantlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="plant_id"
                      value={approver?.plant_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectplant_id}
                      className="small text-danger"
                    >
                      Kindly select plant
                    </div>
                  </div>
                </div>
          
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Requisitioner ID<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="requester_id"
                      options={userlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      value={requester_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectrequester_id}
                      className="small text-danger"
                    >
                      Kindly select Requisitioner ID
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver ID 1<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="approver1_id"
                      options={userlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      value={approver1_id}
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectapprover1_id}
                      className="small text-danger"
                    >
                      Kindly select Approver ID 1
                    </div>
                  </div>
                </div>
              
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver ID 2<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="approver2_id"
                      options={userlist}
                      value={approver2_id}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectapprover2_id}
                      className="small text-danger"
                    >
                      Kindly select Approver ID 2
                    </div>
                  </div>
                </div>
              {additionalFlag &&  <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      RM/PM Additional Approver{" "}
                   <span class="text-danger">*</span>
                    </label>
                    <Select
                      name="additional_approver_id"
                      options={userlist}
                      value={additional_approver_id}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!errors?.selectadditional_approver_id}
                      className="small text-danger"
                    >
                      Kindly select RM/PM Additional Approver
                    </div>
                  </div>
                </div>}
             
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button disabled={!flag} class="btn btn-primary-inner bpi-main" onClick={submit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SourceApproverForm;
