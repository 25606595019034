import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getStateList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/state?${queryParm}`);
};

export const getAllCountryList = async (sortBy, paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/country?sort_by=${sortBy}&paginate=${paginate}`
  );
};
export const getCountryList = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/country?paginate=${paginate}`);
};

export const saveState = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/state`, data);
};

export const updateState = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/state/${id}`, data);
};

// export const deleteState = async (id) => {
//   return axios({
//     url: `${AUTH_BASE_URL}/state`,
//     method: "DELETE",
//     data: id,
//   });
// };
export const deleteState = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/state/${id}`, data);
};

export const isStateExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/state/exists`, isData);
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/state/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
