import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { PdfIcon } from "./Icon";
import { uploadfile } from "../../service/Survey";
import * as HigherEduService from "../../service/educationService";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as moment from "moment";
import { Tooltip } from "react-tippy";

function HigherEduAddClaim() {
    const [allData, setAllData] = useState({});

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [reimbursementData, setReimbursementData] = useState([
        {
            type: 'Admission Fees',
            total_amt: 0,
            requested_amt: 0,
            documents: [],
            documentPreview: [],
            key: 1
        },
    ]);
    const [projectDescription, setProjectDescription] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [requestedAmount, setRequestedAmount] = useState(0);
    const [documentPreviewList, setDocumentPreviewList] = useState([]);
    const [previewDocModalFlag, setPreviewDocModalFlag] = useState({ flag: false, data: [] });
    const [uploadDocumentModalFlag, setUploadDocumentModalFlag] = useState({ flag: false, key: 0 });
    const [reqAmtErr, setReqAmtErr] = useState({});

    // const [isReachMaxAmt, setIsReachMaxAmt] = useState({ flag: false, maxAmt: 0 });
    const claimData = location?.state;
    // const maxReimbursementLimit = claimData.currency == "INR" ? 500000 : 25000;
    const reimbursementTypeOption = [
        { label: 'Admission Fees', value: 'Admission Fees' },
        { label: 'Tuition Fees', value: 'Tuition Fees' },
        { label: 'Course Material', value: 'Course Material' },
        { label: 'Examination Fees', value: 'Examination Fees' },
    ];

    let abortAllData = null;
    let abortReimbursementData = null;

    const amountFormatter = number => new Intl.NumberFormat(claimData?.currency == 'INR' ? 'en-In' : 'en-Us', { style: 'currency', currency: claimData.currency }).format(number)

    useEffect(() => {
        getAllData();
        if (claimData.isPreview) {
            let reimData = [];
            if (claimData?.reimData) {
                claimData.reimData.forEach(obj => reimData.push({
                    type: obj.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '),
                    total_amt: obj.total_amount,
                    requested_amt: obj.requested_amount,
                    documents: [],
                    documentPreview: obj.attachments,
                    key: obj.id
                }))
                setProjectDescription(claimData.projectDesc);
                setTotalAmount(claimData.totalAmount);
                setRequestedAmount(claimData.requestedAmount);
                setReimbursementData(reimData);
            } else {
                getReimbursementData();
            }
        }
    }, [])

    const getAllData = () => {
        abortAllData = new AbortController();
        dispatch(setDisplayLoader("Display"));
        HigherEduService.getApplicationRequestDetail(claimData?.id, abortAllData.signal)
            .then((response) => {
                abortAllData = null;
                dispatch(setDisplayLoader("Hide"));
                setAllData(response.data.dataList)
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    const getReimbursementData = () => {
        abortReimbursementData = new AbortController();
        dispatch(setDisplayLoader("Display"));
        HigherEduService.getReimbursementData(claimData?.id, abortReimbursementData.signal)
            .then((response) => {
                let reimData = [];
                let total = 0;
                let requested = 0;
                response?.data?.dataList?.reimbursement_items.forEach(reim => {
                    total += reim.total_amount;
                    requested += reim.requested_amount;
                    reim.reimbursement_items.forEach(obj => {
                        reimData.push({
                            type: obj.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '),
                            total_amt: obj.total_amount,
                            requested_amt: obj.requested_amount,
                            documents: [],
                            documentPreview: obj.attachments,
                            key: obj.id
                        })
                    })
                })
                setTotalAmount(total);
                setRequestedAmount(requested);
                setReimbursementData(reimData)
                dispatch(setDisplayLoader("Hide"));
                abortReimbursementData = null;
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    const addNewReimbursement = () => {
        let reimData = [...reimbursementData];
        reimData.push({
            type: 'Admission Fees',
            total_amt: 0,
            requested_amt: 0,
            documents: [],
            documentPreview: [],
            key: reimbursementData[reimbursementData.length - 1].key + 1
        })
        setReimbursementData(reimData);
    }
    const deleteReimbursementRow = (idx) => {
        let reimData = reimbursementData.filter((obj, index) => idx != index);
        setTotalAmount(prev => prev - reimbursementData[idx].total_amt)
        setRequestedAmount(prev => prev - reimbursementData[idx].requested_amt)
        setReimbursementData(reimData);
    }

    const onReimbursementTypeSelect = (value, idx) => {
        let reimData = [...reimbursementData];
        reimData[idx].type = value;
        setReimbursementData(reimData);
    }

    const onChangeHandle = (e, key, field) => {
        let tempData = [...reimbursementData];
        let total = 0;
        let idx = 0;

        tempData.forEach((obj, index) => {
            if (obj.key == key) {
                tempData[index][field] = e.target.value;
                idx = index;
            }
            if (obj[field] !== '') total += Number.parseFloat(obj[field])
        })

        let msg = null;
        switch (field) {
            case 'total_amt':
                if (Number.parseFloat(e.target.value) < Number.parseFloat(tempData[idx].requested_amt)) {
                    msg = "Can't be greater than total amount";
                } else if (reqAmtErr[key]) {
                    let newReqAmtErr = { ...reqAmtErr };
                    delete newReqAmtErr[key]
                    setReqAmtErr(newReqAmtErr)
                }
                setTotalAmount(total);
                break;
            case 'requested_amt':
                if (Number.parseFloat(tempData[idx].total_amt) < Number.parseFloat(e.target.value)) {
                    msg = "Can't be greater than total amount";
                } else if (total > allData?.balance_amount) {
                    msg = "Total amount exceeds balance amount";
                } else if (reqAmtErr[key]) {
                    let newReqAmtErr = { ...reqAmtErr };
                    delete newReqAmtErr[key]
                    setReqAmtErr(newReqAmtErr)
                }
                setRequestedAmount(total);
                break;
            default:
        }
        if (!reqAmtErr[key] && msg) {
            let newReqAmtErr = { ...reqAmtErr };
            newReqAmtErr[key] = msg
            setReqAmtErr(newReqAmtErr)
        }
        setReimbursementData(tempData);
    }

    const onUploadDocumentModalOpen = (key, docData) => {
        setUploadDocumentModalFlag({ flag: true, key: key });
        setDocumentPreviewList(docData);
    }

    const onUploadDocumentModalClose = () => {
        setUploadDocumentModalFlag({ flag: false, key: 0 });
        setDocumentPreviewList([])
    }

    const removeDocumentByIdx = (idx) => {
        let newDocumentPreviewList = documentPreviewList.filter((data, index) => index != idx);
        const index = reimbursementData.findIndex(obj => obj.key == uploadDocumentModalFlag.key);

        let newReimbursementData = [...reimbursementData]
        let tempList = reimbursementData[index].documents.filter((data, index) => index != idx)
        newReimbursementData[index].documents = tempList;
        newReimbursementData[index].documentPreview = newDocumentPreviewList;

        setReimbursementData(newReimbursementData)
        setDocumentPreviewList(newDocumentPreviewList)
    }

    const onSelectFile = (e) => {
        let fileList = e.target?.files;
        let newDocumentPreviewList = [...documentPreviewList]
        let newReimbursementData = [...reimbursementData]
        const index = reimbursementData.findIndex(obj => obj.key == uploadDocumentModalFlag.key);

        for (let i = 0; i < fileList.length; i++) {
            const file = e.target.files[i];

            let myUrl = URL.createObjectURL(file);
            let urlSplitArr = myUrl.split('/');
            let typeArr = file.type.split('/');
            let previewDocObj = { url: myUrl, key: urlSplitArr[urlSplitArr.length - 1], type: typeArr[1] }
            newDocumentPreviewList.push(previewDocObj);
            newReimbursementData[index].documentPreview.push(previewDocObj);

            const fileData = new FormData();
            fileData.append("uploaded_file", file);
            newReimbursementData[index].documents.push(fileData)
        }

        setDocumentPreviewList(newDocumentPreviewList)
        setReimbursementData(newReimbursementData)
    };

    const postReimbursementRequest = async () => {
        dispatch(setDisplayLoader("Display"));
        let payloadData = [];
        for (let fileIdx = 0; fileIdx < reimbursementData.length; fileIdx++) {
            let fileArr = []
            for (let i = 0; i < reimbursementData[fileIdx].documents.length; i++) {
                await uploadfile(reimbursementData[fileIdx].documents[i]).then((response) => {
                    fileArr.push(response?.data)
                }).catch((error) => {
                    dispatch(setDisplayLoader("Hide"));
                    toast.error(`${error} `)
                    return
                })
            }
            let reimType = '';
            switch (reimbursementData[fileIdx].type) {
                case 'Admission Fees':
                    reimType = 'ADMISSION_FEES';
                    break;
                case 'Tuition Fees':
                    reimType = 'TUITION_FEES';
                    break;
                case 'Course Material':
                    reimType = 'COURSE_MATERIAL';
                    break;
                case 'Examination Fees':
                    reimType = 'EXAMINATION_FEES';
                    break;
                default:
            }
            payloadData.push({
                type: reimType,
                total_amount: reimbursementData[fileIdx].total_amt,
                requested_amount: reimbursementData[fileIdx].requested_amt,
                attachments: fileArr
            });
        }

        dispatch(setDisplayLoader("Display"));
        const payload = {
            reimbursement_items: payloadData,
            total_amount: totalAmount,
            requested_amount: requestedAmount,
            description: projectDescription
        }
        HigherEduService.postReimbursementRequest(payload, allData?.id)
            .then((response) => {
                dispatch(setDisplayLoader("Hide"));
                toast.success("Request created successfully.")
                navigate("/higher-edu-claim", {
                    state: {
                        id: allData.id,
                        currency: claimData.currency,
                        course: allData.course,
                    }
                })
            })
            .catch((error) => {
                console.log(
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    "api failed"
                );

                dispatch(setDisplayLoader("Hide"));
                toast.error((error.response &&
                    error.response.data && error.response.data.detail) ||
                    "api failed"
                )
            });
    }

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">Reimbursement for Higher Education</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Employee Name & Code<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control"> {`${allData?.user_full_name} (${allData?.username})`}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Designation/Grade<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.designation_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Department<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.department_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Location<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{`${allData?.personal_area_description} (${allData?.personal_area_name})`}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Unit<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.branch_name}</div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group innergroup">
                                        <label>
                                            Date of Joining<span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{moment.parseZone(allData?.date_of_joining).format("DD-MM-YYYY")}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <h5 className="text-theme border-bottom pb-2 mb-3">
                                        <strong>Course Info</strong>
                                    </h5>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Duration of the course (in Days)
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.course_duration}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Total Amount Sanctioned
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{amountFormatter(allData?.sanctioned_amount ? allData?.sanctioned_amount : 0)}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Claimed Amount
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{amountFormatter(allData?.claimed_amount ? allData?.claimed_amount : 0)}</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Balance Amount
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{amountFormatter(allData?.balance_amount ? allData?.balance_amount : 0)}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Started Date
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.start_date}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group innergroup">
                                        <label>
                                            Completion date
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className="form-control">{allData?.end_date}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group innergroup">
                                        <label>
                                            Project work completed (if any) (Enclose brief description of project work and separated pages for additional details of the project work)
                                            <span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            type="text"
                                            onChange={e => setProjectDescription(e.target.value)}
                                            value={projectDescription}
                                            className="form-control"
                                            disabled={claimData.isPreview}
                                        />
                                        {/* <div className="form-control"></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-between mb-3 border-bottom">
                                    <h5 className="text-theme pb-2 mb-3">
                                        <strong>Reimbursements</strong>
                                    </h5>
                                    {!claimData.isPreview ? <button className="btn btn-primary-inner bpi-main" onClick={addNewReimbursement} style={{ height: 38 }}>+ Add</button> : <></>}
                                </div>
                                <div className="table-responsive" style={{ overflow: 'visible' }}>
                                    <table className="table table-striped table-bordered tablecured">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Reimbursements</th>
                                                <th>Total Amount</th>
                                                <th>
                                                    Request Amount&nbsp;&nbsp;
                                                    {/* <Tooltip title={`Maximum reimbursement amount is minimum of 70% of total amount and ${new Intl.NumberFormat(allData.currency == "INR" ? 'en-In' : 'en-US', { style: 'currency', currency: allData.currency }).format(maxReimbursementLimit)}`} position="top">
                                                        <i className="fas fa-info-circle"></i>
                                                    </Tooltip> */}
                                                </th>
                                                <th>Documents</th>
                                                {!claimData.isPreview ? <th>Action</th> : <></>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reimbursementData.map((obj, index) =>
                                                <tr key={obj.key}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ overflow: 'visible' }}>
                                                        <Select
                                                            options={reimbursementTypeOption}
                                                            onChange={(e) => onReimbursementTypeSelect(e.value, index)}
                                                            value={{ label: obj.type, value: obj.type }}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            isDisabled={claimData.isPreview}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={obj.total_amt}
                                                            onChange={(e) => onChangeHandle(e, obj.key, 'total_amt')}
                                                            min={0}
                                                            className="form-control"
                                                            disabled={claimData.isPreview}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={obj.requested_amt}
                                                            onChange={(e) => onChangeHandle(e, obj.key, 'requested_amt')}
                                                            min={0}
                                                            className="form-control"
                                                            style={{ borderColor: reqAmtErr[obj.key] ? '#ff0000' : '#cccccc' }}
                                                            disabled={claimData.isPreview}
                                                        />
                                                        {reqAmtErr[obj.key] ? <span className="small" style={{ color: '#ff0000' }}>{reqAmtErr[obj.key]}</span> : <></>}
                                                    </td>
                                                    <td>
                                                        <div className="upload-btn-wrapper">
                                                            {claimData.isPreview ? <button
                                                                className="btn btn-primary-inner"
                                                                style={{ padding: '5px 10px' }}
                                                                onClick={() => setPreviewDocModalFlag({ flag: true, data: obj.documentPreview })}
                                                            >
                                                                <i className="far fa-eye"></i>&nbsp;
                                                                <span style={{ fontSize: 14 }}>Preview</span>
                                                            </button> :
                                                                <button
                                                                    className="btn btn-primary-inner"
                                                                    style={{ padding: '5px 10px' }}
                                                                    onClick={() => onUploadDocumentModalOpen(obj.key, obj.documentPreview)}
                                                                >
                                                                    {obj.documentPreview.length == 0 ? <i className="fa fa-upload"></i> : <i className="far fa-edit"></i>}&nbsp;
                                                                    <span style={{ fontSize: 14 }}>{obj.documentPreview.length == 0 ? 'Upload Certificate' : 'Edit & Preview'}</span>
                                                                </button>}
                                                        </div>
                                                    </td>
                                                    {!claimData.isPreview ? <td>
                                                        <button className="btn btn-outline-danger mr-3" onClick={() => deleteReimbursementRow(index)} disabled={reimbursementData.length == 1}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </td> : <></>}
                                                </tr>
                                            )}
                                            <tr>
                                                <td>{reimbursementData.length + 1}</td>
                                                <td>Total</td>
                                                <td>
                                                    <div className="form-control">{totalAmount}</div>
                                                </td>
                                                <td>
                                                    <div className="form-control">{requestedAmount}</div>
                                                    {/* <div className="form-control" style={{borderColor: isReachMaxAmt.flag ? '#ff0000' : '#cccccc'}}>{requestedAmount}</div> */}
                                                    {/* {isReachMaxAmt.flag ? <span className="small" style={{color: '#ff0000'}}>Maximum amount is {new Intl.NumberFormat(claimData.currency == "INR" ? 'en-In' : 'en-US', { style: 'currency', currency: claimData.currency }).format(isReachMaxAmt.maxAmt)}</span> : <></>} */}
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex col-md-12 pt-4" >
                                    <button
                                        className="btn bpi-main"
                                        onClick={() => navigate("/higher-edu-claim", { state: { id: allData.id, course: allData.course, currency: allData.currency } })}
                                        style={{ backgroundColor: "#b5babe" }}
                                    >
                                        Cancel
                                    </button>

                                    {!claimData.isPreview ? <button
                                        className="btn btn-primary-inner bpi-main ml-3"
                                        onClick={postReimbursementRequest}
                                        disabled={Object.keys(reqAmtErr).length}
                                    // disabled={isReachMaxAmt.flag}
                                    >
                                        Submit
                                    </button> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={previewDocModalFlag.flag}
            onHide={() => setPreviewDocModalFlag({ flag: false, data: [] })}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <Modal.Header closeButton>
                <Modal.Title> Documents </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {previewDocModalFlag.data.length > 0 ? previewDocModalFlag.data.map(doc => <DocumentPreviewRow id={doc.id} url={doc.url} />) :
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                        <span style={{ color: '#bfbfbf' }} className="small">
                            <em>No document uploaded</em>
                        </span>
                    </div>}
            </Modal.Body>
        </Modal>
        <Modal
            show={uploadDocumentModalFlag.flag}
            onHide={onUploadDocumentModalClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <Modal.Header>
                <Modal.Title> Upload Documents</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center">
                    <div className="col-md-11">
                        <div className="row">
                            <div className="drapdroparea upload-btn-wrapper text-center">
                                <i className="fas fa-file-upload fa-2x"></i>
                                <p>Upload the certificate of your course</p>
                                <button className="btn btn-primary-inner">
                                    DRAG AND DROP HERE OR CHOOSE FILE
                                </button>
                                <input
                                    type="file"
                                    accept=".pdf, .jpeg, .jpg, .png"
                                    multiple
                                    className="form-control"
                                    onChange={(e) => onSelectFile(e)}
                                />
                            </div>
                        </div>
                    </div>
                    {documentPreviewList.length ?
                        (<div className="d-flex flex-wrap col-md-11" style={{ width: '100%' }}>
                            {documentPreviewList.map((idata, idx) => (
                                <DocumentPreview docData={idata} removeDocumentByIdx={removeDocumentByIdx} index={idx} key={idata?.key} />
                            ))}
                        </div>)
                        : <></>}
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <button className="btn btn-primary-inner bpi-main" onClick={onUploadDocumentModalClose}>Done</button>
            </Modal.Footer>
        </Modal>
    </>);
}

function DocumentPreview(props) {
    return (
        <div className="col-md-3 mb-4">
            <a href={props.docData.url} target="_blank">
                {props.docData.type == 'pdf' ? <div style={{ aspectRatio: '16/9' }} ><PdfIcon width={120} height={85} /></div> : <img src={props.docData.url} style={{ aspectRatio: '16/9' }} />}
            </a>
            <div
                onClick={() => props.removeDocumentByIdx(props.index, props.docData?.id ? props.docData?.id : 0)}
                style={{
                    position: 'absolute',
                    top: 3,
                    right: 20,
                    borderRadius: '50%',
                    backgroundColor: '#ff0000',
                    width: 18,
                    height: 18,
                    cursor: 'pointer'
                }}
                className="d-flex align-items-center justify-content-center p-1"
            >
                <i className="fas fa-times" style={{ color: '#fff' }}></i>
            </div>
        </div>
    );
}

function DocumentPreviewRow(props) {
    const urlStrArr = props.url.split('/');
    const type = urlStrArr[urlStrArr.length - 1].split('.')[1];
    return (
        <li key={props.id} className="row d-flex align-items-center mb-4">
            <div className="col-md-3">
                {type == 'pdf' ?
                    <div style={{ aspectRatio: '16/9' }} >
                        <PdfIcon width={95} height={65} />
                    </div> : <img src={props.url} style={{ aspectRatio: '16/9' }} />}
            </div>
            <div className="col-md-6">{urlStrArr[urlStrArr.length - 1]}</div>
            <div className="col-md-3 d-flex justify-content-center">
                <a href={props.url} target="_blank">
                    <i className="fas fa-eye"></i>
                </a>
            </div>
        </li>
    )
}

export default HigherEduAddClaim