import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const importExel = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/import-review`,
      method: "POST",
      responseType: "blob",
      data: data,
    });
  };

  export const getUserReviews = async (queryParm, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/review/list?${queryParm}`, {signal: abortSignal});
  };

  
  export const getApplicationUserList = async (queryParm, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application/user/list?${queryParm}`, {signal: abortSignal});
  };

  export const getMyListing = async (queryParm, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application-request/list?${queryParm}`, {signal: abortSignal});
  };

  export const getAllHigherEduRequest = async (queryParm, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application-request/full-list?${queryParm}`, {signal: abortSignal});
  };

  export const exportAllHigherEduRequest = async () => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/export`,
      method: "GET",
      responseType: "blob",
    });
  };
  
  export const postApplicationRequest = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/create`,
      method: "POST",
      data: data,
    });
  };

  export const getApplicationRequestDetail = async (id, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application-request/${id}`, {signal: abortSignal});
  };

 

  export const updateApplication = async (id,data) => {
    return axios.put(`${AUTH_BASE_URL}/higher-edu/application-request/${id}`, data);
  };


  export const deleteApplicationRequest = async (id) => {
    return axios.delete(`${AUTH_BASE_URL}/higher-edu/application-request/${id}`);
  };

  export const postMultiApplicationApprove = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/multi-approve`,
      method: "POST",
      data: data,
    });
  };

  export const uploadCertificate = async (data, id) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/certificate/${id}`,
      method: "POST",
      data: data,
    });
  };

  export const postReimbursementRequest = async (data, id) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/reimbursement/${id}`,
      method: "POST",
      data: data,
    });
  };

  export const getReimbursementData = async (id, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application-request/reimbursement/${id}`, {signal: abortSignal});
  };

  export const postMultiReimbursementApprove = async (data) => {
    return axios({
      url: `${AUTH_BASE_URL}/higher-edu/application-request/reimbursement/multi-approve`,
      method: "POST",
      data: data,
    });
  };

  export const re_submitApplication = async (id,data) => {
    return axios.put(`${AUTH_BASE_URL}/higher-edu/application-request/re-submit/${id}`, data);
  };
  


  export const getSearchApplicationRequests = async (queryParm, abortSignal) => {
    return axios.get(`${AUTH_BASE_URL}/higher-edu/application-request/approval?${queryParm}`, {signal: abortSignal});
  };
