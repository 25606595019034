import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSideMenu = async (delegate, initialUser) => {
  return axios.get(`${AUTH_BASE_URL}/permissions/my-modules-and-permissions?delegate=${delegate}&initialUser=${initialUser}`);
};
// export const getProfile = async () => {
//   return axios.get(`${EMPLOYEE_BASE_URL}/employees/getprofile`);
// };

// export const saveNotifications = async (savedata) => {
//   return axios.post(`${EMPLOYEE_BASE_URL}/message/notification`, savedata);
// };

// export const getNotifications = async (pageNo, pageSize) => {
//   return axios.get(
//     `${EMPLOYEE_BASE_URL}/message/notification?pageNo=${pageNo}&pageSize=${pageSize}`
//   );
// };
