import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddLeaveAllocation from "./AddLeaveAllocation";
import * as LeaveService from "../../service/leaveService";
import EditLeaveAllocation from "./EditLeaveAllocation";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import Select from "react-select";
import { getUserList } from "../../service/idea";
import moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { get_sap_schedule } from "../../service/searchpo";

if (typeof window !== "undefined") {
  injectStyle();
}
const UserLeaveAllocation = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [leavemanage, setLeaveManage] = useState(null);
  const [leaveManageList, setLeaveManageList] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [tableData, setTableData] = useState([]);
  const [allLeaveType, setAllLeaveType] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [allBusiness, setAllBusiness] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [showSync, setShowSync] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [leaveAllocationDataForExport, setLeaveAllocationDataForExport] =
    useState({
      leave_type_id: "",
      branch_id: "",
      organisation_id: "",
      allocate_to_id: "",
    });
  const [leaveAllocationDataFilter, setLeaveAllocationDataFilter] = useState({
    leave_type_id: "",
    branch_id: "",
    organisation_id: "",
    allocate_to_id: "",
    year: "",
  });
  const [syncData, setSyncData] = useState({
    userId: "",
  });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  const onClose = () => {
    setShowForm(false);
    setShowEditForm(false);
  };
  const addNewBranch = () => {
    setEditMode(false);
    setShowForm(true);
    setLeaveManage(null);
  };
  const editNewBranch = (id, item) => {
    setEditMode(true);
    setShowEditForm(true);
    setLeaveManage(item);
    getUserDataList(id);
  };

  const getLeaveAllocationList = async (
    search,
    leave_type_id,
    organisation_id,
    allocate_to_id,
    branch_id,
    year,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    await LeaveService.getLeaveAllocation(
      handleKey(
        search,
        leave_type_id,
        organisation_id,
        allocate_to_id,
        branch_id,
        year,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let res = response?.data?.dataList?.result.map((obj) => {
          let leaveTypes = allLeaveType;
          leaveTypes?.map((x, index) => {
            let found = obj?.allocated.filter((val) => {
              if (val?.code === x?.code) {
                return true;
              }
            });
            if (found?.length === 0) {
              obj?.allocated.splice(index, 0, "None");
            }
          });
          return obj;
        });
        setLeaveManageList(res);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setTotalRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    leave_type_id,
    organisation_id,
    allocate_to_id,
    branch_id,
    year,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (branch_id !== "" && branch_id !== undefined && branch_id !== null) {
      queryParm = queryParm + "&branch_id=" + branch_id;
    }
    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (
      leave_type_id !== "" &&
      leave_type_id !== undefined &&
      leave_type_id !== null
    ) {
      queryParm = queryParm + "&leave_type_id=" + leave_type_id;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      allocate_to_id !== "" &&
      allocate_to_id !== undefined &&
      allocate_to_id !== null
    ) {
      queryParm = queryParm + "&allocate_to_id=" + allocate_to_id;
    }

    return queryParm;
  };
  const getOrganisation = (paginate) => {
    LeaveService.getOrganisation(paginate)
      .then((response) => {
        setAllOrg(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllBranch = (paginate) => {
    LeaveService.getAllBranch(paginate)
      .then((response) => {
        setAllBusiness(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLeaveManageListData = async () => {
    let query = `sort_by=code&paginate=${false}&allocated=${true}`;
    await LeaveService.getLeaveManageListData(query)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setAllLeaveType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getLeaveAllocationList(
      searchStr,
      leaveAllocationDataFilter.leave_type_id,
      leaveAllocationDataFilter.organisation_id,
      leaveAllocationDataFilter.allocate_to_id,
      leaveAllocationDataFilter.branch_id,
      leaveAllocationDataFilter?.year,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    setShowEditForm(false);
    getLeaveAllocationList(
      searchStr,
      leaveAllocationDataFilter.leave_type_id,
      leaveAllocationDataFilter.organisation_id,
      leaveAllocationDataFilter.allocate_to_id,
      leaveAllocationDataFilter.branch_id,
      leaveAllocationDataFilter?.year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const deleteLeave = (idx) => {
    LeaveService.deleteLeaveAllocate(idx)
      .then((res) => {
        if (res.data.status == true) {
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const submit = (id, policyName) => {
    confirmAlert({
      title: "Delete Leave Allocate",
      message: `Are you sure to delete ${policyName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteLeave(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const getUserDataList = (id) => {
    LeaveService.getUserDetails(id)
      .then((response) => {
        setUserData(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderOrgOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      >{`${item?.name} (${item?.code})`}</option>
    ));
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`business${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      >{`${item?.name} (${item?.code})`}</option>
    ));
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getLeaveAllocationList(
        e.target.value.toLowerCase(),
        leaveAllocationDataFilter.leave_type_id,
        leaveAllocationDataFilter.organisation_id,
        leaveAllocationDataFilter.allocate_to_id,
        leaveAllocationDataFilter.branch_id,
        leaveAllocationDataFilter?.year,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getLeaveAllocationList(
        data,
        leaveAllocationDataFilter.leave_type_id,
        leaveAllocationDataFilter.organisation_id,
        leaveAllocationDataFilter.allocate_to_id,
        leaveAllocationDataFilter.branch_id,
        leaveAllocationDataFilter?.year,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  const mainDataFilter = () => {
    if (
      leaveAllocationDataFilter?.allocate_to_id !== "" ||
      leaveAllocationDataFilter?.branch_id !== "" ||
      leaveAllocationDataFilter?.leave_type_id !== "" ||
      leaveAllocationDataFilter?.organisation_id !== "" ||
      leaveAllocationDataFilter?.year !== ""
    ) {
      setFilterApplied(true);
    }
    setLeaveAllocationDataForExport({
      ...leaveAllocationDataForExport,
      leave_type_id: leaveAllocationDataFilter.leave_type_id,
      organisation_id: leaveAllocationDataFilter.organisation_id,
      allocate_to_id: leaveAllocationDataFilter.allocate_to_id,
      branch_id: leaveAllocationDataFilter.branch_id,
      year: leaveAllocationDataFilter?.year,
    });
    const leave_type_id = leaveAllocationDataFilter.leave_type_id;
    const organisation_id = leaveAllocationDataFilter.organisation_id;
    const allocate_to_id = leaveAllocationDataFilter.allocate_to_id;
    const branch_id = leaveAllocationDataFilter.branch_id;
    const year = leaveAllocationDataFilter?.year;
    getLeaveAllocationList(
      searchStr,
      leave_type_id,
      organisation_id,
      allocate_to_id,
      branch_id,
      year,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };
  const filterClose = () => {
    filterclose();
  };
  const filterShow = () => {
    getOrganisation(false);
    filteropen();
    getAllBranch(false);
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(leaveAllocationDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setLeaveAllocationDataFilter(ClearData);
    getLeaveAllocationList(
      searchStr,
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };
  const renderOrgLeaveTypeOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`leaveType${index}`}
        value={`${item?.id}`}
        label={`${item?.code} (${item?.name})`}
      >{`${item?.code} (${item?.name})`}</option>
    ));
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getLeaveAllocationList(
      searchStr,
      leaveAllocationDataFilter.leave_type_id,
      leaveAllocationDataFilter.organisation_id,
      leaveAllocationDataFilter.allocate_to_id,
      leaveAllocationDataFilter.branch_id,
      leaveAllocationDataFilter?.year,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getLeaveManageListData();
    getSapSchedule();
  }, [pageSizeNo]);

  const getSapSchedule = () => {
    get_sap_schedule('sync_leave_balance_from_savior').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const getUserListData = () => {
    getUserList(false).then((response) => {
      if (response.status === 200) {
        let res = response?.data?.dataList?.result.map((obj) => {
          return {
            label: `${obj?.full_name} (${obj?.username})`,
            value: obj?.id,
          };
        });
        setUserList(res);
      }
    });
  };

  useEffect(() => {
    if (allLeaveType?.length > 0) {
      dispatch(setDisplayLoader("Display"));
      getLeaveAllocationList(
        searchStr,
        leaveAllocationDataFilter.leave_type_id,
        leaveAllocationDataFilter.organisation_id,
        leaveAllocationDataFilter.allocate_to_id,
        leaveAllocationDataFilter.branch_id,
        leaveAllocationDataFilter?.year,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  }, [allLeaveType]);

  const syncUserLeaves = () => {
    dispatch(setDisplayLoader("Display"));
    let payload = {
      userId: syncData?.userId?.value,
    };
    LeaveService.syncUserLeaveSavior(payload).then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        toast.success("User Leave Synced.");
        getLeaveManageListData();
        setShowSync(false);
      }
    });
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/manage-leave">Leave Master</Link>
              </li>
              <li>
                <Link to="/leave-rule">Leave Rule</Link>
              </li>
              <li class="active">
                <Link to="/allocation-leave">Leave Allocation</Link>
              </li>
              <li>
                <Link to="/my-leave">My Leaves</Link>
              </li>
              <li>
                <Link to="/bulk-leave">Leave Bulk Upload</Link>
              </li>
              <li>
                <Link to="/leave-approval">For Approval Leaves</Link>{" "}
              </li>
              <li>
                <Link to="/leave-backdate">Back Date Entry Exception</Link>
              </li>
            </ul>
          </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-9">
                    <h4 class="inner-page-title">Leave Allocation</h4>
                    {/* <Breadcrumb>
                      <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                      <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                      <Breadcrumb.Item active>Leave Allocation</Breadcrumb.Item>
                    </Breadcrumb> */}
                  </div>
                  <div className="col-md-3">
                    <h5 class="inner-page-title">
                      Synced Users - {totalRecord}
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              First Name, Last Name , Full Name, Employee
                              Code....
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="First Name, Last Name , Full Name, Employee Code"
                          onChange={(e) => handleSearch(e)}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        className="btn btn-primary-inner"
                        onClick={() => {
                          getUserListData();
                          setShowSync(true);
                        }}
                      >
                        <i className="fas fa-sync-alt mr-2 f-14"></i>Leave Sync
                      </button>
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                      {/* <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          addNewBranch();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button> */}
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <b style={{ fontSize: "13px" }}>Next Savior Sync:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      {/* {finalUrlValue?.E || finalUrlValue?.R ? ( */}
                      <th>Actions</th>
                      {/* ) : (
                        ""
                      )} */}
                      <th
                        onClick={(e) => {
                          handlerData("full_name");
                        }}
                        className={
                          sortData["full_name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["full_name"]
                        }
                      >
                        Allocate Name
                      </th>
                      <th>Allocate To</th>
                      <th>Business Unit</th>
                      <th>Year</th>
                      <th>Synced At</th>
                      {allLeaveType.map((allData, i) => {
                        return <th> {allData?.code}</th>;
                      })}
                    </tr>
                  </thead>
                  {leaveManageList.map((items) => (
                    <tbody>
                      <tr>
                        {/* {finalUrlValue?.V ||
                        finalUrlValue?.E ||
                        finalUrlValue?.R ? ( */}
                        <td>
                          {/* {finalUrlValue?.E ? ( */}
                          <button
                            onClick={() => editNewBranch(items.user_id, items)}
                            class="btn btn-blanktd text-primary"
                          >
                            <i class="far fa-edit"></i>{" "}
                          </button>
                          {/* ) : (
                            ""
                          )} */}
                          {/* {finalUrlValue?.R ? ( */}
                          {/* <button
                            onClick={() => {
                              submit(items.id, "Leave Allocation");
                            }}
                            class="btn btn-blanktd text-danger ml-2"
                          >
                            <i class="far fa-trash-alt text-danger"></i>
                          </button> */}
                          {/* ) : (
                            ""
                          )} */}
                        </td>
                        {/* ) : (
                          ""
                        )} */}
                        <td>
                          <span class="text-dark fw-bold">
                            {items?.username}
                          </span>
                        </td>
                        <td>{items?.allocated_to}</td>
                        <td>{items?.bu}</td>
                        <td>{items?.year}</td>
                        <td>{items?.allocated?.length > 0 ? moment.utc(items?.allocated[0]?.updated_at).format('DD-MM-YYYY HH:mm:ss') : ""}</td>
                        {items?.allocated.map((allData, i) => {
                          return (
                            <td>
                              {allData === "None"
                                ? "-"
                                : allData?.total_allocated_days}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              {leaveManageList?.length == 0 ? (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              ) : (
                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option class=" main-pointer" value={10} label={10} />
                        <option class=" main-pointer" value={20} label={20} />
                        <option class=" main-pointer" value={30} label={30} />
                        <option class="main-pointer" value={100} label={100} />
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Leave Type</label>
            <select
              className="form-control newbgselect main-pointer"
              name="leave_type_id"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  leave_type_id: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter?.leave_type_id}
            >
              <option value="" label="Select Leave Type" hidden>
                Select Leave Type
              </option>

              {renderOrgLeaveTypeOptions(allLeaveType)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Allocate to</label>
            <select
              class="form-control newbgselect main-pointer"
              type="number"
              name="allocate_to_id"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  allocate_to_id: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter.allocate_to_id}
            >
              <option value="" label="Select" disabled>
                Select
              </option>
              <option value="true" label="SF">
                SF
              </option>
              <option value="false" label="Non-SF">
                Non-SF
              </option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select Organization</label>
            <select
              className="form-control main-pointer "
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="organisation_id"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  organisation_id: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter.organisation_id}
            >
              <option value="" label="Select Organisation" disabled>
                Select Organisation
              </option>
              {renderOrgOptions(allOrg)}
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select Business Unit</label>
            <select
              className="form-control main-pointer"
              classNamePrefix="select"
              style={{ width: "334px" }}
              name="branch_id"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  branch_id: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter.branch_id}
            >
              <option value="" label="Select Business Unit" disabled>
                Select Business Unit
              </option>
              {renderDeptOptions(allBusiness)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Select Year</label>
            <select
              className="form-control newbgselect main-pointer"
              name="year"
              onChange={(e) => {
                setLeaveAllocationDataFilter({
                  ...leaveAllocationDataFilter,
                  year: e.target.value,
                });
              }}
              value={leaveAllocationDataFilter?.year}
            >
              <option value="" label="Select Year" hidden />
              <option
                value={moment(new Date()).subtract(1, "year").format("YYYY")}
                label={moment(new Date()).subtract(1, "year").format("YYYY")}
              >
                {moment(new Date()).subtract(1, "year").format("YYYY")}
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSync}
        onHide={() => {
          setShowSync(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose maxwidth90 modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sync User Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Select User
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      options={userList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(event) => {
                        setSyncData((prev) => ({
                          ...prev,
                          userId: event,
                        }));
                      }}
                      value={syncData?.userId}
                    />
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger">Clear</button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={syncUserLeaves}
          >
            Sync
          </button>
        </Modal.Footer>
      </Modal>

      {showEditForm ? (
        <>
          <EditLeaveAllocation
            edit={editMode}
            showEditForm={showEditForm}
            userData={userData}
            leaveManageList={leaveManageList}
            onClose={onClose}
            editLeaveManage={leavemanage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {showForm ? (
        <>
          <AddLeaveAllocation
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editLeaveManage={leavemanage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default UserLeaveAllocation;
