import React from 'react';
import ReactPaginate from "react-paginate";

const CustomPagination = ({ handlePageClick, pageCount, onChange,pageSizeNo = 10,currentPage = 1 }) => {
  return (
    <>
      <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select class="form-control" onChange={onChange}>
         
              <option selected={ 10=== pageSizeNo ? 'selected' : '' }  value={10} label={10}>10</option>
              <option selected={ 20=== pageSizeNo ? 'selected' : '' }  value={20} label={20}>20</option>
              <option selected={ 30=== pageSizeNo ? 'selected' : '' }  value={30} label={30}>30</option>
              <option selected={ 100=== pageSizeNo ? 'selected' : '' }  value={100} label={100}>100</option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            forcePage={currentPage-1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  )
}

export default CustomPagination

CustomPagination.defaultProps = {
  onChange: () => { },
  handlePageClick: () => { },
  pageCount: () => { }
}