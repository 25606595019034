import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Dropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import AddVendorInvoicing from "./addVendorInvoicing";
import * as vendorInvoiceService from "../../service/vendorInvoiceService";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import BulkVendorImport from "./BulkVendorImport";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import Select from "react-select";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import * as searchPoService from "../../service/searchpo";

function VendorInvoice() {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData)
  const [editMode, setEditMode] = useState(false);
  const [vendorFilter, setVendorFilter] = useState({
    start_date: "",
    end_date: "",
    grn_number: "",
    courier_to: "",
    invoice_type: "",
    courier_no: "",
    status: "",
    created_by_id: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorInvoicingList, setVendorInvoicingList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentTab, setCurrentTab] = useState("approval");
  const [checkedId, setCheckedId] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  // const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const [checkData, setCheckData] = useState({
    status: "",
    id_list: [0],
  });
  const [createdByData, setCreatedByData] = useState([]);
  const [createdValueBy, setCreatedValueBy] = useState([]);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [dataValue, setDataValue] = useState(null);
  const [sscList, setSscList] = useState([]);

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const location = useLocation();

  const onClose = () => {
    addnewclose();
  };
  const locate = location.state;
  const pathname = window.location.pathname;

  const handleKey = (
    page_no,
    page_size,
    start_date,
    end_date,
    choice,
    status,
    search,
    created_by_id,
    paginate,
    grn_number,
    courier_to,
    invoice_type,
    courier_no,
    self_approval_data
  ) => {
    let queryParm = "page_no=" + page_no + "&page_size=" + page_size;
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (choice !== "" && choice !== undefined && choice !== null) {
      queryParm = queryParm + "&choice=" + choice;
    }
    if (
      created_by_id !== "" &&
      created_by_id !== undefined &&
      created_by_id !== null
    ) {
      queryParm = queryParm + "&created_by_id=" + created_by_id;
    }
    if (paginate !== "" && paginate !== undefined && paginate !== null) {
      queryParm = queryParm + "&paginate=" + paginate;
    }
    if (grn_number !== "" && grn_number !== undefined && grn_number !== null) {
      queryParm = queryParm + "&grn_number=" + grn_number;
    }
    if (courier_to !== "" && courier_to !== undefined && courier_to !== null) {
      queryParm = queryParm + "&courier_to=" + courier_to;
    }
    if (
      invoice_type !== "" &&
      invoice_type !== undefined &&
      invoice_type !== null
    ) {
      queryParm = queryParm + "&invoice_type=" + invoice_type;
    }
    if (courier_no !== "" && courier_no !== undefined && courier_no !== null) {
      queryParm = queryParm + "&courier_no=" + courier_no;
    }
    if (window.location.pathname === "/vendor-invoice-report" || window.location.pathname === "/vendor-invoice-approval") {
      queryParm = queryParm + "&report=" + true;
    }
    if (self_approval_data !== "" && self_approval_data !== undefined && self_approval_data !== null) {
      queryParm = queryParm + "&self_approval_data=" + self_approval_data;
    }
    return queryParm;
  };

  const getVendorInvoiceList = (
    page_no,
    page_size,
    start_date,
    end_date,
    choice,
    status,
    search,
    created_by_id,
    paginate,
    grn_number,
    courier_to,
    invoice_type,
    courier_no,
    self_approval_data
  ) => {
    vendorInvoiceService
      .getVendorInvoiceList(
        handleKey(
          page_no,
          page_size,
          start_date,
          end_date,
          choice,
          status,
          search,
          created_by_id,
          paginate,
          grn_number,
          courier_to,
          invoice_type,
          courier_no,
          self_approval_data
        )
      )
      .then((response) => {
        // setCreatedByData(response?.data?.dataList?.data);
        setVendorInvoicingList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getFilterVendorInvoiceList = (
    page_no,
    page_size,
    start_date,
    end_date,
    choice,
    status,
    search,
    created_by_id,
    paginate
  ) => {
    vendorInvoiceService
      .getVendorInvoiceList(
        handleKey(
          page_no,
          page_size,
          start_date,
          end_date,
          choice,
          status,
          search,
          created_by_id,
          paginate
        )
      )
      .then((response) => {
        setCreatedByData(response?.data?.dataList?.data);
        // setVendorInvoicingList(response?.data?.dataList?.result);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const saveApprovedData = (value) => {
    if (checkedId.length > 0) {
      checkData.id_list = checkedId;
      checkData.status = value;
      vendorInvoiceService
        .saveApprovedData(checkData)
        .then((response) => {
          console.log(vendorInvoicingList, "successssss");
          setVendorFilter({ ...vendorFilter, status: "" });
          getVendorInvoiceList(
            currentPage,
            pageSizeNo,
            vendorFilter.start_date,
            vendorFilter.end_date,
            "myApproval",
            "SUBMITTED",
            searchStr,
            vendorFilter.created_by_id,
            "",
            vendorFilter.grn_number,
            vendorFilter.courier_to,
            vendorFilter.invoice_type,
            vendorFilter.courier_no
          );
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const submitPosting = (data) => {
    confirmAlert({
      title:
        data === "REJECTED"
          ? "Rejected Vendor Invoice"
          : "Approved Vendor Invoice",
      message:
        data === "REJECTED"
          ? "Are you sure to Reject Invoice ?"
          : "Are you sure to Approved Invoice ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => saveApprovedData(data),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addNewInvoice = () => {
    setEditMode(false);
    addnewopen();
    setDataValue(null);
  };

  const onAddCallBack = (response) => {
    getVendorInvoiceList(
      currentPage,
      pageSizeNo,
      vendorFilter.start_date,
      vendorFilter.end_date,
      "",
      "SUBMITTED"
    );
    setCurrentTab("approval");
    addnewclose();
  };

  const filterClose = () => {
    filterclose();
  };

  const getSscMembeList = (page_no, page_size) => {
    vendorInvoiceService
      .getSscMembeList(page_no, page_size)
      .then((response) => {
        setSscList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateCheckBoxValue = (e, value) => {
    console.log(e, value, "vendorInvoicingList", vendorInvoicingList);
    let data = vendorInvoicingList;
    data?.map((obj) => {
      if (Number(obj.id) === Number(value.id)) {
        obj.checkData = e.target.checked;
      }
    });
    if (e.target.checked) {
      setCheckedId([...checkedId, value.id]);
    } else {
      const index = checkedId.indexOf(value.id);
      if (index > -1) {
        let data = checkedId;
        data.splice(index, 1);
        setCheckedId(data);
      }
    }
  };

  const handleAllData = (e) => {
    if (e.target.checked === true) {
      let data = [];
      let finalData = vendorInvoicingList;
      finalData?.map((fd, i) => {
        fd["checkData"] = e.target.checked;
        data.push(fd.id);
      });
      setCheckedId(data);
    } else {
      let finalData = vendorInvoicingList;
      finalData.map((fd, i) => {
        fd["checkData"] = e.target.checked;
      });
      setCheckedId([]);
    }
  };

  const handleSearch = (e) => {
    if (currentTab === "approval") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        e.target.value.toLowerCase(),
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "Received") {
      console.log("REceived");
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "myApproval",
        vendorFilter.status === "" ? "SUBMITTED" : vendorFilter.status,
        e.target.value.toLowerCase(),
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "report" && pathname === "vendor-invoice-report") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        e.target.value.toLowerCase(),
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "report" && pathname === "vendor-invoice-approval") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        e.target.value.toLowerCase(),
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        true
      );
    }
    setSearchStr(e.target.value);
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const start_date = vendorFilter.start_date;
    const end_date = vendorFilter.end_date;
    const status = vendorFilter.status;
    const created_by_id = vendorFilter.created_by_id;
    const grn_number = vendorFilter.grn_number;
    const courier_to = vendorFilter.courier_to;
    const invoice_type = vendorFilter.invoice_type;
    const courier_no = vendorFilter.courier_no;
    if (currentTab === "Received") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        start_date,
        end_date,
        "myApproval",
        status === "" ? "SUBMITTED" : status,
        searchStr,
        created_by_id,
        true,
        grn_number,
        courier_to,
        invoice_type,
        courier_no,
        false
      );
    } else if (pathname === "vendor-invoice-approval") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        start_date,
        end_date,
        "",
        status,
        searchStr,
        created_by_id,
        true,
        grn_number,
        courier_to,
        invoice_type,
        courier_no,
        true
      )
    }
    else {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        start_date,
        end_date,
        "",
        status,
        searchStr,
        created_by_id,
        true,
        grn_number,
        courier_to,
        invoice_type,
        courier_no,
        false
      );
    }
    filterClose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(vendorFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setVendorFilter(ClearData);
    filterClose();
    setCreatedValueBy([]);
    setVendorFilter({ ...vendorFilter, created_by_id: "" });
    if (currentTab === "Received") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        "",
        "",
        "myApproval",
        "SUBMITTED",
        searchStr,
        "",
        "",
        "",
        "",
        "",
        false
      );
    } else if (currentTab === "approval") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        "",
        "",
        "",
        "SUBMITTED",
        searchStr,
        "",
        "",
        "",
        "",
        "",
        false
      );
    } else if (pathname === "vendor-invoice-approval") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        "",
        "",
        "",
        "SUBMITTED",
        searchStr,
        "",
        "",
        "",
        "",
        "",
        true
      );
    }
    else {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        "",
        "",
        "",
        "",
        searchStr,
        "",
        "",
        "",
        "",
        "",
        false
      );
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (currentTab === "approval") {
        getVendorInvoiceList(
          currentPage,
          pageSizeNo,
          vendorFilter.start_date,
          vendorFilter.end_date,
          "",
          vendorFilter.status,
          data,
          vendorFilter.created_by_id,
          true,
          vendorFilter.grn_number,
          vendorFilter.courier_to,
          vendorFilter.invoice_type,
          vendorFilter.courier_no,
          false
        );
      } else if (currentTab === "Received") {
        getVendorInvoiceList(
          currentPage,
          pageSizeNo,
          vendorFilter.start_date,
          vendorFilter.end_date,
          "myApproval",
          vendorFilter.status === "" ? "SUBMITTED" : vendorFilter.status,
          data,
          vendorFilter.created_by_id,
          true,
          vendorFilter.grn_number,
          vendorFilter.courier_to,
          vendorFilter.invoice_type,
          vendorFilter.courier_no,
          false
        );
      } else if (currentTab === "report" && pathname === "vendor-invoice-report") {
        getVendorInvoiceList(
          currentPage,
          pageSizeNo,
          vendorFilter.start_date,
          vendorFilter.end_date,
          "",
          vendorFilter.status,
          data,
          vendorFilter.created_by_id,
          true,
          vendorFilter.grn_number,
          vendorFilter.courier_to,
          vendorFilter.invoice_type,
          vendorFilter.courier_no,
          false
        );
      } else if (currentTab === "report" && pathname === "vendor-invoice-approval") {
        getVendorInvoiceList(
          currentPage,
          pageSizeNo,
          vendorFilter.start_date,
          vendorFilter.end_date,
          "",
          vendorFilter.status,
          data,
          vendorFilter.created_by_id,
          true,
          vendorFilter.grn_number,
          vendorFilter.courier_to,
          vendorFilter.invoice_type,
          vendorFilter.courier_no,
          true
        );
      }
    }
  };
  const sscMembeDataList = (optionsStateList) => {
    return optionsStateList?.map((item, index) => (
      <option
        key={`state${index}`}
        value={`${item?.user_id}`}
        label={`${item?.username} (${item?.first_name} ${item?.last_name})`}
      >{`${item?.username} (${item?.first_name} ${item?.last_name})`}</option>
    ));
  };

  const getExportDetails = (choice, status) => {
    dispatch(setDisplayLoader("Display"));
    vendorInvoiceService
      .getExportData(choice, status)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetailsReport = () => {
    dispatch(setDisplayLoader("Display"));
    const start_date = vendorFilter.start_date;
    const end_date = vendorFilter.end_date;
    const status = vendorFilter.status;
    const created_by_id = vendorFilter.created_by_id;
    const grn_number = vendorFilter.grn_number;
    const courier_to = vendorFilter.courier_to;
    const invoice_type = vendorFilter.invoice_type;
    const courier_no = vendorFilter.courier_no;
    let choice = null
    let report = false
    let self_approval_data = false
    if (pathname === "/vendor-invoice-report") {
      report = true
    }
    if (pathname === "/vendor-invoice-pending") {
      choice = "myApproval"
    }
    if (pathname === "/vendor-invoice-approval") {
      self_approval_data = true
      report = true
    }
    vendorInvoiceService
      .getExportDetailsReport(
        report,
        choice,
        start_date,
        end_date,
        status,
        created_by_id,
        grn_number,
        courier_to,
        invoice_type,
        courier_no,
        self_approval_data
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getSscMembeList(1, 20);
    getSapSchedule()
    if (currentTab === "approval" && pathname === "/vendor-invoice") {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        "SUBMITTED",
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (
      currentTab === "Received" &&
      pathname === "/vendor-invoice-pending"
    ) {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "myApproval",
        vendorFilter.status === "" ? "SUBMITTED" : vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (
      currentTab === "report" &&
      pathname === "/vendor-invoice-report"
    ) {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (
      pathname === "/vendor-invoice-approval"
    ) {
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        true
      );
    }
  }, [pageSizeNo, pathname, currentTab]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (currentTab === "approval") {
      getVendorInvoiceList(
        activePage,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "Received") {
      getVendorInvoiceList(
        activePage,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "myApproval",
        vendorFilter.status === "" ? "SUBMITTED" : vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "report" && pathname === "vendor-invoice-report") {
      getVendorInvoiceList(
        activePage,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        false
      );
    } else if (currentTab === "report" && pathname === "vendor-invoice-approval") {
      getVendorInvoiceList(
        activePage,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        vendorFilter.status,
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no,
        true
      );
    }
  };

  // useEffect(() => {
  //   if (currentTab === "approval" && pathname === "/vendor-invoice") {
  //     getVendorInvoiceList(
  //       1,
  //       pageSizeNo,
  //       vendorFilter.start_date,
  //       vendorFilter.end_date,
  //       "",
  //       "SUBMITTED",
  //       searchStr,
  //       vendorFilter.created_by_id,
  //       true,
  //       vendorFilter.grn_number,
  //       vendorFilter.courier_to,
  //       vendorFilter.invoice_type,
  //       vendorFilter.courier_no
  //     );
  //   } else if (
  //     currentTab === "Received" &&
  //     pathname === "/vendor-invoice-pending"
  //   ) {
  //     getVendorInvoiceList(
  //       1,
  //       pageSizeNo,
  //       vendorFilter.start_date,
  //       vendorFilter.end_date,
  //       "myApproval",
  //       "SUBMITTED",
  //       searchStr,
  //       vendorFilter.created_by_id,
  //       true,
  //       vendorFilter.grn_number,
  //       vendorFilter.courier_to,
  //       vendorFilter.invoice_type,
  //       vendorFilter.courier_no
  //     );
  //   } else if (
  //     currentTab === "report" &&
  //     pathname === "/vendor-invoice-report"
  //   ) {
  //     getVendorInvoiceList(
  //       1,
  //       pageSizeNo,
  //       vendorFilter.start_date,
  //       vendorFilter.end_date,
  //       "",
  //       vendorFilter.status,
  //       searchStr,
  //       vendorFilter.created_by_id,
  //       true,
  //       vendorFilter.grn_number,
  //       vendorFilter.courier_to,
  //       vendorFilter.invoice_type,
  //       vendorFilter.courier_no
  //     );
  //   }
  //   clearFilter();
  // }, [currentTab, pathname]);

  const onPushCreatedData = (e) => {
    setVendorFilter({ ...vendorFilter, created_by_id: e.value });
    setCreatedValueBy(e);
  };

  useEffect(() => {
    if (locate?.permission) {
      setCurrentTab("approval");
    } else {
      setCurrentTab("Received");
    }
  }, [locate]);

  useEffect(() => {
    if (pathname === "/vendor-invoice-report" || pathname === "/vendor-invoice-approval") {
      setCurrentTab("report");
    } else if (pathname === "/vendor-invoice") {
      setCurrentTab("approval");
    } else {
      setCurrentTab("Received");
    }
  }, [pathname]);

  useEffect(() => {
    if (show) {
      getFilterVendorInvoiceList(
        1,
        pageSizeNo,
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        "",
        "",
        "",
        ""
      );
    }
  }, [show]);

  const deleteVendorInvoice = (id) => {
    confirmAlert({
      title: "Delete Vendor Invoice ",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteVendorRecord(id)
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteVendorRecord = (id) => {
    vendorInvoiceService.deleteVendor(id).then((res) => {
      toast.success("Invoice Deleted Successfully")
      getVendorInvoiceList(
        1,
        pageSizeNo,
        vendorFilter.start_date,
        vendorFilter.end_date,
        "",
        "SUBMITTED",
        searchStr,
        vendorFilter.created_by_id,
        true,
        vendorFilter.grn_number,
        vendorFilter.courier_to,
        vendorFilter.invoice_type,
        vendorFilter.courier_no
      );
    })
  }

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_vendor_invoice_tracking').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    // searchPoService.get_sap_schedule('sync_po_from_sap').then((response)=>{
    //   let next_sap_syncing_time = response?.data?.dataList?.result
    //   setNextSapSyncingTime(next_sap_syncing_time)
    // })
  }

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Vendor Invoice Tracking</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      Vendor Invoice Tracking
                    </Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            ID,
                            <br />
                            GRN Number,
                            <br />
                            SES Number
                            <br />
                            Vendor Code,
                            <br />
                            Invoice Number
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on ID, GRN No., SES No..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {currentTab == "approval" && currentTab == "Received" ? (
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  currentTab === "Received" ? "myApproval" : "",
                                  vendorFilter.status
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              style={{ border: 0, background: "none" }}
                              onClick={() => getExportDetailsReport()}
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    {/* {currentTab !== "approval" && ( */}
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {/* )} */}
                    {pathname === "/vendor-invoice" && finalUrlValue?.U ? (
                      <button class="btn btn-danger" onClick={importuser}>
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Import Data
                      </button>
                    ) : (
                      ""
                    )}
                    {pathname === "/vendor-invoice" && finalUrlValue?.A ? (
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => addNewInvoice()}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  {/* <b style={{fontSize:"13px"}}>Next SAP Syncing:</b> <span className="" style={{fontSize:"13px"}}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span> */}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey="approval"
                id="approvaltabs"
                activeKey={currentTab}
                onSelect={(e) => {
                  setCurrentTab(e);
                }}
              >
                {pathname === "/vendor-invoice" && (
                  <Tab
                    eventKey="approval"
                    title={<React.Fragment>My Invoice</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Invoice Type</th>
                            <th>GRN Number</th>
                            <th>SES Number</th>
                            <th>Vendor Code</th>
                            <th>Vendor Name</th>
                            <th>Invoice No.</th>
                            <th>Invoice Date</th>
                            <th>Bill Amount</th>
                            <th>Ariba No.</th>
                            <th>Miro</th>
                            <th>Miro Fi</th>
                            <th>Vim Dp</th>
                            <th>Payment</th>
                            <th>Payment Date</th>
                            <th>Invoice Status</th>
                            <th>Dispatch Date</th>
                            <th>Courier Addressed to</th>
                            <th>Courier Docket No.</th>
                            <th>Created By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorInvoicingList?.length ? (
                            vendorInvoicingList?.map((data, index) => (
                              <tr>
                                <td>
                                  {data?.status !== 'RECEIVED' ?
                                    <button
                                      type="button"
                                      onClick={() => {
                                        deleteVendorInvoice(data?.id);
                                      }}
                                      class="btn btn-blanktd text-danger ml-2 f-12"
                                    >
                                      <i class="far fa-trash-alt text-danger"></i>
                                    </button>
                                    :
                                    ""
                                  }
                                </td>
                                <td>{data?.id}</td>
                                <td>
                                  <span class="badge bad-status badge-success">
                                    {data?.status}
                                  </span>
                                </td>
                                <td>{data?.invoice_type}</td>
                                <td>{data?.grn_number}</td>
                                <td>{data?.ses_number}</td>
                                <td>{data?.vendor_code}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.invoice_no}</td>
                                <td>
                                  {moment(data?.invoice_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{data?.bill_amount}</td>
                                <td>{data?.ariba_no}</td>
                                <td>{data?.miro}</td>
                                <td>{data?.miro_fi}</td>
                                <td>{data?.vim_dp}</td>
                                <td>{data?.payment}</td>
                                <td>{data?.payment_date}</td>
                                <td>{data?.inv_status}</td>
                                <td>
                                  {moment(data?.dispatch_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{`${data?.courier_to_username} (${data?.courier_to_first_name} ${data?.courier_to_last_name})`}</td>
                                <td>{data?.courier_no}</td>
                                <td>
                                  {data?.created_by_first_name}{" "}
                                  {data?.created_by_last_name}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={22}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {vendorInvoicingList?.length == 0 ? (
                      ""
                    ) : (
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select
                              class="form-control main-pointer"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>
                                10
                              </option>
                              <option value={20} label={20}>
                                20
                              </option>
                              <option value={30} label={30}>
                                30
                              </option>
                              <option value={100} label={100}>
                                100
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                )}
                {pathname === "/vendor-invoice-pending" && (
                  <Tab
                    eventKey="Received"
                    title={
                      <React.Fragment>Invoice Received/Pending</React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>ID</th>
                            {(vendorFilter?.status === "SUBMITTED" ||
                              vendorFilter?.status === "") && (
                                <th>
                                  <label class="logCheck d-inline-block">
                                    <input
                                      type="checkbox"
                                      name="permission"
                                      onClick={(e) => {
                                        handleAllData(e);
                                      }}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </th>
                              )}
                            <th>Status</th>
                            <th>Invoice Type</th>
                            <th>GRN Number</th>
                            <th>SES Number</th>
                            <th>Vendor Code</th>
                            <th>Vendor Name</th>
                            <th>Invoice No.</th>
                            <th>Invoice Date</th>
                            <th>Bill Amount</th>
                            <th>Ariba No.</th>
                            <th>Miro</th>
                            <th>Miro Fi</th>
                            <th>Vim Dp</th>
                            <th>Payment</th>
                            <th>Payment Date</th>
                            <th>Invoice Status</th>
                            <th>Dispatch Date</th>
                            <th>Courier Addressed to</th>
                            <th>Courier Docket No.</th>
                            <th>Created By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorInvoicingList?.length ? (
                            vendorInvoicingList?.map((data, index) => (
                              <tr>
                                <td>{data?.id}</td>
                                {data?.status === "RECEIVED" ||
                                  data?.status === "REJECTED" ? (
                                  ""
                                ) : (
                                  <td>
                                    <label class="logCheck d-inline-block">
                                      <input
                                        type="checkbox"
                                        name="permission"
                                        id={"permission" + index}
                                        onClick={(e) => {
                                          updateCheckBoxValue(e, data);
                                        }}
                                        checked={data.checkData}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </td>
                                )}
                                <td>
                                  <span class="badge bad-status badge-success">
                                    {data?.status}
                                  </span>
                                </td>
                                <td>{data?.invoice_type}</td>
                                <td>{data?.grn_number}</td>
                                <td>{data?.ses_number}</td>
                                <td>{data?.vendor_code}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.invoice_no}</td>
                                <td>
                                  {moment(data?.invoice_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{data?.bill_amount}</td>
                                <td>{data?.ariba_no}</td>
                                <td>{data?.miro}</td>
                                <td>{data?.miro_fi}</td>
                                <td>{data?.vim_dp}</td>
                                <td>{data?.payment}</td>
                                <td>{data?.payment_date}</td>
                                <td>{data?.inv_status}</td>
                                <td>
                                  {moment(data?.dispatch_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{`${data?.courier_to_username} (${data?.courier_to_first_name} ${data?.courier_to_last_name})`}</td>
                                <td>{data?.courier_no}</td>
                                <td>
                                  {data?.created_by_first_name}{" "}
                                  {data?.created_by_last_name}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={22}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {vendorInvoicingList?.length == 0 ? (
                      ""
                    ) : (
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select
                              class="form-control main-pointer"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>
                                10
                              </option>
                              <option value={20} label={20}>
                                20
                              </option>
                              <option value={30} label={30}>
                                30
                              </option>
                              <option value={100} label={100}>
                                100
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                    <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          {finalUrlValue?.R ? (
                            <button
                              class="btn btn-danger mr-2"
                              onClick={() => {
                                if (checkedId.length === 0) {
                                  toast.error("Please select atleast one Data");
                                } else {
                                  submitPosting("REJECTED");
                                }
                              }}
                            >
                              <i class="far fa-times-circle"></i> Reject
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            class="btn btn-success"
                            onClick={() => {
                              if (checkedId.length === 0) {
                                toast.error("Please select atleast one Data");
                              } else {
                                submitPosting("RECEIVED");
                              }
                            }}
                          >
                            <i class="far fa-check-circle"></i> Received
                            Invoices
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
                {(pathname === "/vendor-invoice-report" || pathname === "/vendor-invoice-approval") && (
                  <Tab
                    eventKey="report"
                    title={<React.Fragment>Invoice Report</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Status</th>
                            <th>Invoice Type</th>
                            <th>GRN Number</th>
                            <th>SES Number</th>
                            <th>Vendor Code</th>
                            <th>Vendor Name</th>
                            <th>Invoice No.</th>
                            <th>Invoice Date</th>
                            <th>Bill Amount</th>
                            <th>Ariba No.</th>
                            <th>Miro</th>
                            <th>Miro Fi</th>
                            <th>Vim Dp</th>
                            <th>Payment</th>
                            <th>Payment Date</th>
                            <th>Invoice Status</th>
                            <th>Dispatch Date</th>
                            <th>Courier Addressed to</th>
                            <th>Courier Docket No.</th>
                            <th>Created By</th>
                            <th>Received At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorInvoicingList?.length ? (
                            vendorInvoicingList?.map((data, index) => (
                              <tr>
                                <td>{data?.id}</td>
                                {/* <td>{data?.name}</td> */}
                                <td>
                                  {data?.status === "SUBMITTED" ? (
                                    <span class="badge bg-success bad-status ">
                                      SUBMITTED
                                    </span>
                                  ) : data?.status === "REJECTED" ? (
                                    <span class="badge bg-warning bad-status">
                                      REJECTED
                                    </span>
                                  ) : data?.status === "RECEIVED" ? (
                                    <span class="badge bg-primary bad-status">
                                      RECEIVED
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{data?.invoice_type}</td>
                                <td>{data?.grn_number}</td>
                                <td>{data?.ses_number}</td>
                                <td>{data?.vendor_code}</td>
                                <td>{data?.vendor_name}</td>
                                <td>{data?.invoice_no}</td>
                                <td>
                                  {moment(data?.invoice_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{data?.bill_amount}</td>
                                <td>{data?.ariba_no}</td>
                                <td>{data?.miro}</td>
                                <td>{data?.miro_fi}</td>
                                <td>{data?.vim_dp}</td>
                                <td>{data?.payment}</td>
                                <td>{data?.payment_date}</td>
                                <td>{data?.inv_status}</td>
                                <td>
                                  {moment(data?.dispatch_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{`${data?.courier_to_username} (${data?.courier_to_first_name} ${data?.courier_to_last_name})`}</td>
                                <td>{data?.courier_no}</td>
                                <td>
                                  {data?.created_by_first_name}{" "}
                                  {data?.created_by_last_name}
                                </td>
                                <td>
                                  {data?.approved_at !== null &&
                                    moment(data?.approved_at)
                                      .utc()
                                      .format("DD-MMM-YYYY")}
                                </td>{" "}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={22}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    {vendorInvoicingList?.length == 0 ? (
                      ""
                    ) : (
                      <div class="row">
                        <div class="col-sm-5">
                          <div class="sortinglist">
                            Show
                            <select
                              class="form-control main-pointer"
                              onChange={(e) => {
                                setPageSizeNo(e.target.value);
                                setCurrentPage(1);
                              }}
                            >
                              <option value={10} label={10}>
                                10
                              </option>
                              <option value={20} label={20}>
                                20
                              </option>
                              <option value={30} label={30}>
                                30
                              </option>
                              <option value={100} label={100}>
                                100
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-7">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-left"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    )}
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>From Dispatch Date</label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  start_date: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={vendorFilter.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>To Dispatch Date</label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              min={vendorFilter.start_date}
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  end_date: e.target.value,
                });
              }}
              value={vendorFilter.end_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>GRN Number</label>
            <input
              type="number"
              class="form-control"
              name="grn_number"
              min={vendorFilter.grn_number}
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  grn_number: e.target.value,
                });
              }}
              value={vendorFilter.grn_number}
            />
          </div>
          <div class="form-group innergroup">
            <label>Invoice Type</label>
            <select
              class="form-control newbgselect"
              name="invoice_type"
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  invoice_type: e.target.value,
                });
              }}
              value={vendorFilter.invoice_type}
            >
              <option value="" disabled>
                Select Invoice Type
              </option>
              <option value="PO Based Material Invoices" selected>
                PO Based Material Invoices
              </option>
              <option value="PO Based Service Invoices">
                PO Based Service Invoices
              </option>
              <option value="Non PO Based Invoices">
                Non PO Based Invoices
              </option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Courier Addressed to</label>
            <select
              class="form-control newbgselect"
              name="courier_to"
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  courier_to: e.target.value,
                });
              }}
              value={vendorFilter.courier_to}
            >
              <option value="" disabled>
                Select
              </option>
              {sscMembeDataList(sscList)}
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Created By</label>
            <Select
              options={createdByData}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushCreatedData(e)}
              value={createdValueBy}
            />
          </div>
          <div class="form-group innergroup">
            <label>Courier Number</label>
            <input
              type="text"
              class="form-control"
              name="courier_no"
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  courier_no: e.target.value,
                });
              }}
              value={vendorFilter.courier_no}
            />
          </div>
          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setVendorFilter({
                  ...vendorFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={vendorFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value="SUBMITTED" label="Submitted" />
              <option value="RECEIVED" label="Received" />
              <option value="REJECTED" label="Rejected" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewopen ? (
        <>
          <AddVendorInvoicing
            edit={editMode}
            addnewshow={addnewshow}
            onClose={onClose}
            dataValue={dataValue}
            sscList={sscList}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      {importsetShow && (
        <BulkVendorImport importshow={importshow} importclose={importclose} />
      )}
    </>
  );
}

export default VendorInvoice;
