import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getShiftList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/shift?${queryParm}`);
};
export const getAllShift = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/shift?${paginate}&all=${true}`);
};
export const saveShift = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift`, data);
};
export const updateShift = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/shift/${id}`, data);
};
export const deleteShift = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/shift/${id}`, data);
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/shift/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
//shift Reason Api
export const getPersonalSubAreaData = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/personal-sub-area/drop-down/${queryParm}`);
};
export const getReasonList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/shift/reason?${queryParm}`);
};
export const saveReason = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift/reason`, data);
};
export const updateReason = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/shift/reason/${id}`, data);
};
export const deleteReason = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/shift/reason/${id}`, data);
};
//Shift Approval Api
export const getShiftApprovalList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/shift/approval_shift?${queryParm}`);
};
export const saveShiftApproval = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/shift/approve_decline_shift`, data);
};
export const updateShiftApproval = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/shift/approval_shift/${id}`, data);
};
export const deleteShiftApproval = async (id, data) => {
  return axios.delete(`${AUTH_BASE_URL}/shift/approval_shift/${id}`, data);
};
export const getExportValueAllocation = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/shift/approval_shift/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getAllUser = async (paginate, bhr) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/approval_shift?${paginate}&bhr=${bhr}`
  );
};
