import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Badge, Spinner } from "react-bootstrap";
import AddSkill from "./AddSkill";
import ViewSkill from "./ViewSkill";
import { Link } from "react-router-dom";
import * as SkillService from "../../service/skill";
import * as SkillGroupService from "../../service/skillGroup";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import "../SkillQuaterly/loader.css";
import Select from "react-select";
if (typeof window !== "undefined") {
  injectStyle();
}

function SkillMaster() {
  const dispatch = useDispatch();

  const [searchStr, setSearchStr] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [viewForm, setViewForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillData, setSkillData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewFormData, setViewFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [importshow, importsetShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const importuser = () => importsetShow(true);
  const [exDataDown, setExDataDown] = useState({});
  const [downloadButton, setDownloadButton] = useState(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [permissionAccess, setPermissionAccess] = useState({});
  const [showSkillTab, setShowSkillTab] = useState(false)
  const [locationData, setLocationData] = useState([])
  const [selectedLocation, setSelectedLocation] = useState({})
  const [groupData, setGroupData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})
  const [subGroupData, setSubGroupData] = useState([])
  const [selectedSubGroup, setSelectedSubGroup] = useState({})

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getSkills = (search, pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillService.getSkills(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (search, pageNo, page_size, sort_by, paginate) => {
    SkillService.getExportValue(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skill-master.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLocation = () => {
    SkillService.getSubPersonal(0)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: `${x?.name} ${x?.description}`,
          };
          data.push(data1);
        });
        setLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const importExel = (values) => {
    setIsLoading(true);
    SkillService.importExel(values)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          toast.success("Skill Bulk Uploaded Successfully");
          getSkills("", 1, pageSizeNo, "-id", true);

          setDownloadButton(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onProfileChange = (e) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    SkillService.uploadImages(fileData).then((response) => {
      let data = response.data;
      if (response.data.status === true) {
        importExel(data);
      }
    });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Skill.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  const openFilter = () => {
    importuser();
    setIsLoading(false);
  };

  const addSkills = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillData(null);
  };

  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };

  const editSkill = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillData(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setSkillData(null);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const onAddCallBack = () => {
    setShowForm(false);
    getSkills("", currentPage, pageSizeNo, sortData.sort_by, true);
  };
  const deleteSkill = (idx) => {
    SkillService.deleteSkill(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkills("", currentPage, pageSizeNo, sortData.sort_by, true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id, name) => {
    confirmAlert({
      title: "Delete Skill",
      message: `Are you sure to delete ${name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkill(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkills(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getSkills(data, 1, pageSizeNo, sortData.sort_by, true);
    }
  };

  const handleSearch = (e) => {
    getSkills(e.target.value.toLowerCase(), 1, pageSizeNo, "-id", true);
    setSearchStr(e.target.value);
  };

  const closeModal = () => {
    importsetShow(false);
    setDownloadButton(false);
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getParentGroup = () => {
    SkillGroupService.getSkillGroup(
      handleKey("", "0", "0", "-id", false)
    )
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.id,
            label: element.group_name
          })
        })
        setGroupData(plList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSubGroup = (e, isEdit) => {
    SkillGroupService.getSkillSubGroupByParent(e.value)
      .then((response) => {
        let plList = []
        response?.data?.dataList?.result.map((element) => {
          plList.push({
            value: element.id,
            label: element.group_name
          })
        })
        setSubGroupData(plList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const clearFilter = () => {
    setSelectedLocation({})
    setSelectedGroup({})
    setSelectedSubGroup({})
    getSkills("", currentPage, pageSizeNo, sortData.sort_by, true);
    setShowFilter(false)
  }

  const openFilterModal = () => {
    setShowFilter(true)
  }

  const onCloseFilter = () => {
    setShowFilter(false)
  }

  const SubmitHandler = (e) => {
    e.preventDefault()
    dispatch(setDisplayLoader("Display"));

    let queryParams = handleKey("", currentPage, pageSizeNo, sortData.sort_by, true)

    if (Object.keys(selectedLocation).length > 0) {
      queryParams += `&location=${selectedLocation.value}`
    }

    if (Object.keys(selectedGroup).length > 0) {
      queryParams += `&group_name=${selectedGroup.label}`
    }

    if (Object.keys(selectedSubGroup).length > 0) {
      queryParams += `&subgroup_name=${selectedSubGroup.label}`
    }

    SkillService.getSkills(queryParams)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setShowFilter(false)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      })
  }

  useEffect(() => {
    getLocation()
    getParentGroup()
  }, [])

  useEffect(() => {
    getSkillPermission();
    getSkills("", currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              {/* <CommonLinks/> */}
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li class="active">
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li>
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill-Master</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong>Skill ID, Skill Description</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div className="input-group table-search">
                        <span className="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e)}
                        />

                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <button
                        onClick={() => openFilterModal()}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button>
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              type="button"
                              disabled={skillList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {permissionAccess?.skill_list_update === true && (
                        <>
                          <button
                            className="btn btn-danger"
                            onClick={openFilter}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Import Data
                          </button>

                          <button
                            className="btn btn-primary-inner"
                            onClick={() => {
                              addSkills();
                            }}
                          >
                            <img
                              src="images/upload.png"
                              alt=""
                              className="mr-3"
                            />
                            Add New
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Skill ID</th>
                      <th>Description</th>
                      <th>Location</th>
                      <th>Group Name</th>
                      <th>Sub Group Name</th>
                      <th>Last Updated By</th>
                      <th> Last Updated On</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillList?.length ? (
                      skillList?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td>{data?.skill_id}</td>
                            <td className="text-theme fw-bold">{data?.desc}</td>

                            <td>
                              {data?.location?.length !== 0 ? (
                                data?.location.map((x) => (
                                  <p>
                                    <b>{x.loc_name}</b>{" "}
                                  </p>
                                ))
                              ) : (
                                <p>N/A</p>
                              )}
                            </td>
                            <td>{data?.group?.group_name}</td>
                            <td>{data?.sub_group?.sub_group_name}</td>
                            <td>{data?.updated_by}</td>
                            <td>
                              {" "}
                              {moment
                                .utc(data?.updated_at)
                                .format("Do MMM YYYY, H:mm:ss A")}
                            </td>
                            <td>
                              {data?.is_active ? (
                                <span className="badge bad-status badge-success">
                                  Active
                                </span>
                              ) : (
                                <span className="badge bad-status badge-danger">
                                  InActive
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-blanktd text-primary"
                                onClick={() => {
                                  onView(data);
                                }}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                              {permissionAccess?.skill_list_update === true && (
                                <>
                                  <button
                                    className="btn btn-blanktd text-primary ml-2"
                                    type="button"
                                    onClick={() => {
                                      editSkill(data);
                                    }}
                                  >
                                    <i className="far fa-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-blanktd text-danger ml-2"
                                    type="button"
                                    onClick={() => {
                                      submit(data?.id, data?.skill_id);
                                    }}
                                  >
                                    <i className="far fa-trash-alt text-danger"></i>
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {skillList?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={importshow}
        onHide={closeModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  {isLoading ? (
                    <div class="loadingss">
                      <p style={{ color: "#3498db" }}>
                        Please wait while uploading the data.
                      </p>
                      <span>
                        <i></i>
                        <i></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <i className="fas fa-file-upload fa-2x"></i>
                      <p>Upload the template file with updated data</p>
                      <button className="btn btn-primary-inner" type="button">
                        DRAG AND DROP HERE OR CHOOSE FILE
                      </button>
                      <div className="mt-3 downloadtemplate">
                        <button className="editbtn">
                          <i className="far fa-file-excel"></i>
                          <a href="skill-master-template.xlsx" download>
                            DOWNLOAD TEMPLATE
                          </a>
                        </button>
                      </div>
                    </>
                  )}

                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e)}
                  />
                </div>

                {downloadButton && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>Check the uploaded file in case of error.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFilter}
        onHide={onCloseFilter}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="form-group innergroup">
              <label>Location</label>
              <Select
                name="location"
                id="locations"
                options={locationData}
                classNamePrefix="select"
                className="basic-select"
                onChange={(e) => {
                  setSelectedLocation(e)
                }}
                value={selectedLocation}
              />
            </div>
            <div className="form-group innergroup">
              <label>Group Name</label>
              <Select
                name="group_name"
                id="group_name"
                options={groupData}
                classNamePrefix="select"
                className="basic-select"
                onChange={(e) => {
                  setSelectedGroup(e)
                  getSubGroup(e)
                }}
                value={selectedGroup}
              />
            </div>
            <div className="form-group innergroup">
              <label>Sub Group Name</label>
              <Select
                name="sub_group_name"
                id="sub_group_name"
                options={subGroupData}
                classNamePrefix="select"
                className="basic-select"
                onChange={(e) => {
                  setSelectedSubGroup(e)
                }}
                value={selectedSubGroup}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear
            </button>
            <button
              className="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={(e) => SubmitHandler(e)}
            >
              Apply
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {showForm ? (
        <>
          <AddSkill
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editSkills={skillData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
      {viewForm ? (
        <>
          <ViewSkill
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default SkillMaster;
