import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as moment from "moment";
import * as purchaseOrderService from "../../service/purchase-order";
import * as searchPoService from "../../service/searchpo";
import { toast, ToastContainer } from "react-toastify";
import * as surveyService from "../../service/Survey";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

function SurveyApproval(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const { onViewClose, viewFormData } = props;

  const [link, setLink] = useState(null);
  const [imgSize, setImgSize] = useState(false);
  const [dataViews, setDataViews] = useState([]);
  const [error, setError] = useState(false);
  const [publishSetting, setPublishSetting] = useState(null);
  const [subtype, setSubTypeId] = useState(null);

  const [uploadAttachments, setUploadAttachments] = useState({
    attachment: {},
  });

  const [searchData, setSearchData] = useState({
    id: 0,
    is_approved: true,
    attachment: {},
    additional_comments: "",
  });

  const getViewPurchaseOrder = (id) => {
    purchaseOrderService
      .getViewPurchaseOrder(id)
      .then((response) => {
        setDataViews(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    if (files.size < 1033415 || files.size === 1033415) {
      setLink(undefined);
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      purchaseOrderService.uploadAttachment(fileData).then((response) => {
        if (flag === "attachment") {
          setUploadAttachments({
            ...uploadAttachments,
            attachment: response.data,
          });
        }
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    } else {
      setImgSize(true);
    }
  };

  const saveSearchPo = (values) => {
    searchPoService
      .saveSearchPo(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          navigate(`/common-approve/${location?.state?.subId}`, {
            state: {
              subtypeid: location?.state?.subId,
              subTypeName: "PO",
            },
          });
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const surveyApprove = (data) => {
    surveyService
      .approveSurvey(data)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          navigate(`/common-approve/${location?.state?.subId}`);
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  function validate(value) {
    let data = {
      survey_id: location?.state?.from,
      sub_type: location?.state?.subId,
      is_approved: value,
    };

    surveyApprove(data);

    // if (searchData.additional_comments.length !== 0) {
    //   let data = {
    //     id: dataViews?.id,
    //     is_approved: `${value === "approve" ? true : false}`,
    //     attachment: uploadAttachments.attachment,
    //     additional_comments: searchData.additional_comments,
    //   };
    //   saveSearchPo(data);
    // } else {
    //   setError(true);
    // }
  }

  const onViewCloseForm = () => {
    navigate(`/common-approve/${location?.state?.subId}`);
  };

  useEffect(() => {
    let id = 0;
    if (location?.state?.from === undefined) {
      id = window.location.pathname?.split("/")[2];
    } else {
      id = location?.state?.from;
    }
    setSubTypeId(location?.state?.subId);
    // getViewPurchaseOrder(id);

    surveyService
      .getSurvey(`survey_id=${id}`)
      .then((response) => {
        console.log("viewing.,", response?.data?.dataList?.result);
        setDataViews(response?.data?.dataList?.result[0]);
        // getAllCategoryType();
        //   setAsmntData(response?.data?.dataList?.result.filter((data)=>{
        //     if (data.id==targetAsmnt.id) return data
        //   })[0])

        surveyService
          .getPublishData(id)
          .then((response) => {
            setPublishSetting(response.data.dataList);
          })
          .catch((error) => {
            toast.error(error.response.data.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }, []);

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };

  return (
    <>
      <div className="row">
        {/* <div className="col-md-3">
          <ul className="policylistnames">
           
            <li>
              <Link to="/search-po">Search Order</Link>
            </li>
            <li>
              <Link to="/active-po">Active PO Status</Link>
            </li>
            <li>
              <Link to="/approver-assignment">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/releasegroup">Release Group</Link>
            </li>
            <li>
              <Link to="/releasecode">Release Code</Link>
            </li>
            <li>
              <Link to="/releasestrat">Release Strategy</Link>
            </li>
            <li>
              <Link to="/releasestrategy">Approval Mapping</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    View approval
                    {/* {dataViews?.po_no} */}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Survey Type</label>
                    <p>{dataViews?.assessment_type_name}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Title</label>
                    <div dangerouslySetInnerHTML={{
                      __html: dataViews?.title
                    }} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Description</label>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataViews?.description,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Start Date</label>
                    <p>{publishSetting?.start_date}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>End Date</label>
                    <p>{publishSetting?.end_date}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Organisation</label>
                    <p>
                      {publishSetting?.organisation_ids?.map(
                        (orgItem, idx) => `${orgItem.name},`
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business</label>
                    <p>
                      {publishSetting?.branch_ids?.map(
                        (orgItem, idx) => `${orgItem.name},`
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <p>
                      {publishSetting?.department_ids?.map(
                        (orgItem, idx) => `${orgItem.name},`
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Users</label>
                    <p>
                      {publishSetting?.user_ids?.map(
                        (orgItem, idx) => `${orgItem.name},`
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permission</label>
                    <p>
                      {publishSetting?.permission_type === "SV"
                        ? "Submit and View"
                        : publishSetting?.permission_type === "SO"
                          ? "Submit Only"
                          : publishSetting?.permission_type === "SE"
                            ? "Submit and Edit"
                            : ""}
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>View Form</label>
                    <p>
                      {" "}
                      <Link
                        to={`/viewsurveyform/${location?.state?.from}`}
                        state={{
                          from: location?.state?.from,
                          subId: location?.state?.subId,
                        }}
                      >
                        <button class="btn btn-blanktd text-success">
                          <i
                            class="far fa-eye"
                            style={{ color: "#0195D4" }}
                          ></i>
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 text-right pb-3">
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-outline-danger ml-3"
                  type="button"
                  onClick={() => onViewCloseForm()}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary-inner  mr-3 ml-3 bpi-main"
                  type="submit"
                  onClick={() => validate(true)}
                >
                  Approve
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate(false)}
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SurveyApproval;
