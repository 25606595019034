import { AUTH_BASE_URL } from "../../constants/URL";
import axios from "../../utils/axios";

export const saveUserPlantMapping = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/reservation/userPlant`, data);
};

export const getUserPlantMapping = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/reservation/userPlant?${queryParam}`);
};

export const deleteUserPlantMapping = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/reservation/userPlant/${id}`);
};

export const addMaterialReservation = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/reservation`, data);
};

export const getMaterialReservations = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/reservation?${queryParam}`);
};

export const deleteMaterialReservation = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/reservation/${id}`);
};

export const updateMaterialReservation = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/reservation/${id}`, data);
};

export const getMaterialListByPlant = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/buss-transactions/material-list`, data);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/reservation/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getUserPlantMappingExport = async (queryParam) => {
  return axios({
    url: `${AUTH_BASE_URL}/reservation/userPlantExport?${queryParam}`,
    method: "GET",
    responseType: "blob",
  });
};
