import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import * as VehicalService from "../../service/VehicalService";
function PrintVehicleCheck() {
  let location = useLocation();
  const [data, setData] = useState({});

  const getData = (vehicle_id) => {
    // dispatch(setDisplayLoader("Display"));
    VehicalService.getVehicleReqDataByid(vehicle_id)
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));
        let data = response?.data?.dataList?.result[0];
        setData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const Print = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    // window.location.reload();
    document.body.innerHTML = originalContents;
  };

  window.onafterprint = () => {
    setTimeout(() => {
      document.getElementById("print-btn-1").onclick = Print;
      document.getElementById("btnPrint").onclick = Print;
    }, 1500);
  };

  useEffect(() => {
    getData(location?.state?.from);
  }, []);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <div class="defaultchatgptquest text-right">
                      <Link to="/vehicle-expense" class="badge badge-secondary">
                        <i class="fas fa-arrow-circle-left"></i> Back
                      </Link>
                      <button
                        className="badge badge-primary"
                        id="print-btn-1"
                        onClick={() => Print()}
                      >
                        <i className="fas fa-print"></i> Print
                      </button>
                    </div>
                  </div>
                </div>
                <div class="viewformaftersubmit" id="printablediv">
                  <div class="col-md-12">
                    <h3 class="policyhead">Vehicle Request</h3>
                  </div>
                  <div className="d-flex">
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Emp Code</label>
                        <p>{data?.created_by_username}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Name</label>
                        <p>
                          {data?._state?.fields_cache?.created_by?.first_name +
                            " " +
                            data?._state?.fields_cache?.created_by?.last_name}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Vendor Code</label>
                        <p>
                          {
                            data?._state?.fields_cache?.created_by
                              ?.employee_vendor_code
                          }
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Date of Joining</label>
                        <p>
                          {moment(
                            data?._state?.fields_cache?.created_by?.date_joined
                          ).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Applied on</label>
                        <p>
                          {moment.utc(data?.created_at).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Approved on</label>
                        <p>
                          {data?.status && data?.status[0] == "A"
                            ? moment(data?.updated_at).format("DD-MM-YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Relation</label>
                        <p>{data?.relation_type}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Vehicle Owner Name</label>
                        <p>{data?.VehicleOwnerName}</p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Vehicle Type</label>
                        <p>{data?.vehicleType}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Vehicle Number</label>
                        <p>{data?.vehicleNumber}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Reg Valid From</label>
                        <p>
                          {moment.utc(data?.validFromDate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>Reg Valid Till</label>
                        <p>
                          {moment.utc(data?.validTillDate).format("DD-MM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>License Number</label>
                        <p>{data?.licenseNumber}</p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>DL Valid From</label>
                        <p>
                          {moment(data?.licenseValidFromDate).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group innergroup">
                        <label>DL Valid Till</label>
                        <p>
                          {moment(data?.licenseValidTillDate).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                  </div>
                  {/* <div class="col-md-3">
                                        <div class="form-group innergroup">
                                        <label>Grade</label>
                                        <p>{data?._state?.fields_cache.created_by.employee_vendor_code}</p>
                                        </div>
                                    </div> */}
                  {/* <div class="col-md-3">
                                        <div class="form-group innergroup">
                                        <label>UniqueId</label>
                                        <p>{data?.created_by_username}</p>
                                        </div>
                                    </div> */}
                  {/* <div class="col-md-3">
                                        <div class="form-group innergroup">
                                        <label>Reporting Person</label>
                                        <p>{data?.relation_type}</p>
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div className="col-md-12 text-center mt-3 pb-3">
                <Link
                  to="/vehicle-expense"
                  className="btn btn-outline-secondary mr-2"
                >
                  Close
                </Link>
                <button
                  className="btn btn-primary-inner bpi-main"
                  id="btnPrint"
                  onClick={Print}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintVehicleCheck;
