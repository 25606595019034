import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import * as EmpDir from "../../service/employeeDir";
import * as EmpService from "../../service/employee";
import * as deptServices from "../../service/department";
import { Formik } from "formik";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
} from "react-share";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactPaginate from "react-paginate";

function EmployeeDir({ data, paginated, getEmp }) {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const [name, domain] = useState("");
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [addnewshowSocial, addnewsetShowSocial] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const addnewopenSocial = () => addnewsetShowSocial(true);
  const addnewcloseSocial = () => addnewsetShowSocial(false);
  const [dropDesig, setDropDesig] = useState([]);
  const [empDir, setEmpDir] = useState([]);
  const [digitalCardData, setDigitalCardData] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [dropDept, setDropDept] = useState([]);
  const [dropBranch, setDropBranch] = useState([]);
  const [deptid, setDeptId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [location_id, setLocation_id] = useState("");
  const [emploc, setemploc] = useState("");
  const [department1, setDepartment1] = useState("");
  const [empDesg1, setempDesg1] = useState("");
  const [departmentDataFilter, setDepartmentDataFilter] = useState({
    department_id: "",
    business_unit_id: "",
    designation_id: "",
  });
  const [deptFil, setDeptFil] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [pageCount, setpageCount] = useState(1);

  const [printable, setPrintable] = useState(false);
  const [printableData, setPrintableData] = useState([]);
  const [printableError, setPrintableError] = useState(false);
  
  const { state } = useLocation();
  useEffect(() => {
    if (
      state?.name?.toLowerCase() != "employee dir" &&
      state?.name?.toLowerCase() != undefined
    ) {
      getEmpDirectory("id", state?.name?.toLowerCase(), "", "");
      setSearchStr(state?.name);
    }
  }, [state]);

  const maskEmail = (email) => {
    if (email != "" && email != null) {
      const [name, domain] = email?.split("@");
      // }

      // else{

      // }

      const { length: len } = name;
      const maskedName = name[0] + "******" + name[len - 1];
      const maskedEmail = maskedName + "@" + domain;
      return maskedEmail;
    } else {
      return email;
    }
  };
  const maskNumber = (number) => {
    const endDigits = number.slice(-4);
    return endDigits.padStart(number.length, "*");
  };

  const getEmployee = (
    pageNo,
    pageSizeNo,
    sort_by,
    search,
    paginate,
    dept,
    branchId,
    desigId,
    locationid
  ) => {
    EmpService.getEmployee(
      pageNo,
      pageSizeNo,
      sort_by,
      search,
      paginate,
      dept,
      branchId,
      desigId,
      locationid
    )
      .then((response) => {
        let recordsForCard = response?.data?.dataList?.result
          .filter((obj) => {
            if (
              obj?.name === "" ||
              // obj?.email === "" ||
              // obj?.mobile === "" ||
              obj?.name === null
              // obj?.email === null ||
              // obj?.mobile === null
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((x) => {
            return x;
          });
        // setEmpForCard(recordsForCard);
        setEmpDir(recordsForCard);
        setDigitalCardData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getEmpDirectory = (
    sortBy,
    searchStr,
    designationId,
    departmentId,
    locationId
  ) => {
    EmpDir.getEmpDirectory(
      sortBy,
      searchStr,
      designationId,
      departmentId,
      locationId
    )
      .then((response) => {
        // setEmpDir(response?.data?.dataList?.result);
        // setDigitalCardData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getEmployee(
        currentPage,
        pageSizeNo,
        "id",
        e.target.value.toLowerCase(),
        true,
        "",
        "",
        "",
        ""
      );

      setSearchStr(e.target.value);
    }
  };

  const DepartmentFilter = (location_id) => {
    EmpService.DepartmentFilter(location_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDept(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const DesignationFilter = (location_ids, department_id) => {
    EmpService.DesignationFilter(location_ids, department_id)
      .then((response) => {
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res?.name + "(" + res?.code + ")",
          };
        });
        setDropDesig(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const filterShow = () => {
    filteropen();
    DepartmentFilter();
    DesignationFilter();
    LocationFilter();
    // getBranch(false);
    // getAllDepartment(false);
    // getAllDesignation(false);
    // personal_subAreafilter(false);
  };

  const onFilterSubmit = () => {
    setFilterApplied(true);
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(departmentDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDepartmentDataFilter(ClearData);
    setDepartmentDataFilter({
      department_id: "",
      business_unit_id: "",
      designation_id: "",
    });
    setemploc("");
    setLocation_id("");
    setDepartment1("");
    setempDesg1("");
    filterclose();
    getEmployee(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      searchStr,
      true,
      "",
      "",
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    getEmployee(
      currentPage,
      pageSizeNo,
      "-id",
      searchStr,
      true,
      deptid,
      "",
      designationId,
      deptFil
    );
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getEmployee(1, pageSizeNo, "id", data, true, "", "", "", "");
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getEmployee(
      activePage,
      pageSizeNo,
      "id",
      searchStr,
      true,
      departmentDataFilter?.department_id,
      "",
      departmentDataFilter?.designation_id,
      emploc
    );
    setCurrentPage(activePage);
  };

  useEffect(() => {
    getEmployee(
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      searchStr,
      true,
      departmentDataFilter?.department_id,
      "",
      departmentDataFilter?.designation_id,
      emploc
    );
  }, [pageSizeNo]);

  const LocationFilterPrint = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.name,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const printableExport = () => {
    setPrintable(true);
    LocationFilterPrint();
  };
  const closePrintable = () => {
    setPrintable(false);
    setPrintableData([]);
    setPrintableError(false);
  };

  const PrintExport = (location_name) => {
    EmpService.getExportPrint(location_name)
      .then((response) => {
        if (response.status === 200) {
          setPrintable(false);
        }
        const link = document.createElement("a");
        link.href = response?.data?.dataList?.file_url;
        link.setAttribute("download", "Print-Export.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const ValidateExport = () => {
    let data = printableData.value;
    if (printableData.length === 0) {
      setPrintableError(true);
    } else {
      PrintExport(data);
    }
  };
  console.log("digitalCardData", digitalCardData);
  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec pb-0">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Employee Directory</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Name, Mobile Number, Email Id
                        <br />
                        Designation, Department
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Name, Mobile Number,..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
              <button
                      type="button"
                      onClick={() => {
                        printableExport();
                      }}
                      className="btn btn-secondary-inner"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Printable Export
                    </button>
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <i className="fas fa-sort mr-2"></i> Sorting
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        getEmployee(
                          currentPage,
                          pageSizeNo,
                          "name",
                          searchStr,
                          true,
                          deptid,
                          "",
                          designationId,
                          emploc
                        )
                      }
                    >
                      <i className="fas fa-sort-alpha-down"></i>
                      Sort A to Z
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        getEmployee(
                          currentPage,
                          pageSizeNo,
                          "-name",
                          searchStr,
                          true,
                          deptid,
                          "",
                          designationId,
                          emploc
                        )
                      }
                    >
                      <i className="fas fa-sort-alpha-down-alt"></i>Sort Z to A
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        getEmployee(
                          currentPage,
                          pageSizeNo,
                          "id",
                          searchStr,
                          true,
                          deptid,
                          "",
                          designationId,
                          emploc
                        )
                      }
                    >
                      <i className="fas fa-redo"></i>Refresh
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  type="button"
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                  style={{
                    backgroundColor: filterApplied && "yellow",
                    color: filterApplied && userData?.dark_mode === false && "#000"
                  }}
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {empDir?.length
          ? empDir
            .filter((item) => item?.is_active === true)
            .map((data, index) => (
              <>
                <div className="col-md-4">
                  <div className="card package-section">
                    <div className="row">
                      <div className="col-4">
                        {
                          // data?.mobile != "" &&
                          // data?.email != "" &&
                          data?.masking_email != true &&
                          data?.masking_mobile != true && (
                            <div className="cc-image">
                              <img src={data?.qr_code_path} />
                            </div>
                          )}
                      </div>
                      <div className="col-8 pl-0">
                        <h5>
                          <Tooltip
                            title={`${data?.name} (${data?.code})`}
                            position="bottom"
                          >
                            {data?.name} ({data?.code})
                          </Tooltip>
                        </h5>
                        <h6>
                          <i className="fas fa-phone-alt"></i>{" "}
                          {data?.country_code}
                          &nbsp;
                          {data?.masking_mobile == true
                            ? maskNumber(data?.mobile)
                            : data?.mobile}{" "}
                        </h6>
                        <h6>
                          <i className="fas fa-phone-volume"></i>
                          &nbsp;{data?.telephone_ext}
                        </h6>
                        <h6>
                          <i className="far fa-envelope"></i>{" "}
                          {/* {data?.email} */}
                          {data?.masking_email == true
                            ? maskEmail(data?.email)
                            : data?.email}
                        </h6>
                        {/* <div className="grid-status">
                              <Link to="">
                                <i className="fas fa-cloud-download-alt"></i>
                              </Link>
                            </div> */}
                        <div class="grid-status">
                          {data?.mobile != "" &&
                            data?.email != "" &&
                            data?.masking_email != true &&
                            data?.masking_mobile != true && (
                              <button
                                onClick={() => {
                                  addnewopen();
                                  setDigitalCardData(data);
                                }}
                                class="btn btn-outline-secondary btn-digital"
                              >
                                Digital Card
                              </button>
                            )}
                        </div>
                      </div>

                      <div className="col-md-12 mt-3 mb-2">
                        <div className="border-top"></div>
                      </div>

                      <div className="col-5 pack-details">
                        <p>Designation</p>
                        <h5>{data?.designation_name}</h5>
                      </div>
                      <div className="col-4 pack-details">
                        <p>Department</p>
                        <h5>{data?.department_name}</h5>
                      </div>
                      <div className="col-3 pack-details">
                        <p>Location</p>
                        <h5>
                          {data?.sub_personal != "()"
                            ? data?.sub_personal
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          : "No Record Found"}
      </div>
      {data?.length == 0 ? (
        ""
      ) : (
        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                style={{ textAlign: "center" }}
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                }}
              >
                <option value={10} label={10}>10</option>
                <option value={20} label={20}>20</option>
                <option value={30} label={30}>30</option>
                <option value={100} label={100}>100</option>
              </select>
            </div>
          </div>
          <div className="col-sm-7 main-pointer">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose emp-digitalbox"
      >
        <Modal.Header closeButton>
          <Modal.Title>{digitalCardData?.name}</Modal.Title>
          {/* <button class="btn-share">
            <img src="images/share-icon.png" onClick={addnewopenSocial} />
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <div class="row align-items-end emp-digicontent">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <h2>
                    <div class="icondesign">
                      <img src="images/digi-card/digi-desig.svg" />
                    </div>
                    {digitalCardData?.designation_name}
                  </h2>
                </div>
                <div class="col-md-12">
                  <h2>
                    <div class="icondesign">
                      <img src="images/digi-card/digi-depart.svg" />
                    </div>
                    {digitalCardData?.department_name}
                  </h2>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <h3>
                <div class="icondesign">
                  <img src="images/digi-card/digi-mob.svg" />
                </div>
                {digitalCardData?.masking_mobile == true
                  ? maskNumber(digitalCardData?.mobile)
                  : digitalCardData?.mobile}
              </h3>
              <h3>
                <div class="icondesign">
                  <img src="images/digi-card/digi-phone.svg" />
                </div>

                {digitalCardData?.masking_mobile == true
                  ? maskNumber(digitalCardData?.telephone_ext)
                  : digitalCardData?.telephone_ext}
              </h3>
              <h4>
                <div class="icondesign">
                  <img src="images/digi-card/digi-mail.svg" />
                </div>
                {digitalCardData?.masking_email == true
                  ? maskNumber(digitalCardData?.email)
                  : digitalCardData?.email}
              </h4>
              <h5>
                <div class="icondesign">
                  <img src="images/digi-card/digi-map.svg" />
                </div>
                {digitalCardData?.office_address}
              </h5>
              {/* <div class="sharbox">
                <p>
                  <i class="fas fa-share-alt"></i> Share via
                </p>
                <div>
                  <FacebookShareButton url="/" quote="/">
                    <span className="share-icon">
                      <FacebookIcon size={21} round />
                    </span>
                  </FacebookShareButton>
                  <WhatsappShareButton url="/" quote="hello">
                    <span className="share-icon">
                      <WhatsappIcon size={21} round />
                    </span>
                  </WhatsappShareButton>
                  <EmailShareButton url="/" quote="hello">
                    <span className="share-icon">
                      <EmailIcon size={21} round />
                    </span>
                  </EmailShareButton>
                  <LinkedinShareButton url="/" quote="hello">
                    <span className="share-icon">
                      <LinkedinIcon size={21} round />
                    </span>
                  </LinkedinShareButton>
                </div>
              </div> */}
            </div>
            <div class="col-sm-4 col-5">
              <div class="border overflow-hidden mt-3 mt-sm-0">
                {/* {digitalCardData?.qr_code_path} */}
                <img src={digitalCardData?.qr_code_path} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addnewshowSocial}
        onHide={addnewcloseSocial}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose emp-digitalbox"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Via</Modal.Title>

          {/* <FacebookShareButton url="/" quote="/">
            <span className="share-icon">
              <FacebookIcon size={26} round />
            </span>
          </FacebookShareButton>
          <WhatsappShareButton url="/" quote="hello">
            <span className="share-icon">
              <WhatsappIcon size={26} round />
            </span>
          </WhatsappShareButton>
          <EmailShareButton url="/" quote="hello">
            <span className="share-icon">
              <EmailIcon size={26} round />
            </span>
          </EmailShareButton>
          <LinkedinShareButton url="/" quote="hello">
            <span className="share-icon">
              <LinkedinIcon size={26} round />
            </span>
          </LinkedinShareButton> */}

          {/* <button class="btn-share">
            <a href="https://www.instagram.com/" target="blank">
              <i class="fab fa-instagram"></i>
            </a>
          </button>
          <button class="btn-share">
            <a href="https://www.facebook.com/" target="blank">
              <i class="fab fa-facebook"></i>
            </a>
          </button> */}
        </Modal.Header>
        {/* <Modal.Body>hiiii</Modal.Body> */}
      </Modal>

      {/* <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            backdrop="static"
            keyboard={false}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group innergroup">
                  <label>
                    Select Business Unit <span className="text-danger"></span>
                  </label>
                 
                  <select
                    className="form-control newbgselect"
                    name="business_unit_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        business_unit_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" label="Select Branch" />

                    {renderBranchOptions(dropBranch)}
                  </select>
                </div>
                <div className="form-group innergroup">
                  <label>
                    Select Department <span className="text-danger"></span>
                  </label>
               =
                  <select
                    className="form-control newbgselect"
                    name="department_id"
                    onChange={(e) => {
                      handleChange(e);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        department_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" label="Select Department" />

                    {renderDeptOptions(dropDept)}
                  </select>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    onFilterClose();
                  }}
                >
                  Refresh
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    onFilterSubmit();
                  }}
                  className="btn btn-primary-inner bpi-main"
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik> */}
      <Formik
        initialValues={departmentDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            // backdrop="static"
            // keyboard={false}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group innergroup">
                  <label>
                    Select Location <span className="text-danger"></span>
                  </label>
                  <Select
                    name="business_unit_id"
                    isMulti
                    options={subPersonalArea}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      let ValueFilter = e?.map((data) => {
                        return data?.value;
                      });
                      DepartmentFilter(ValueFilter);
                      DesignationFilter(ValueFilter, "");
                      setDeptFil(ValueFilter);
                      setemploc(ValueFilter);
                      setDepartmentDataFilter({
                        ...departmentDataFilter,
                        business_unit_id: ValueFilter,
                      });
                      setLocation_id(e);
                      setCurrentPage(1);
                    }}
                    value={location_id}
                  />
                </div>

                <div className="form-group innergroup main-pointer">
                  <label>
                    Select Department<span className="text-danger"></span>
                  </label>
                  <Select
                    name="department_id"
                    options={dropDept}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      setDeptId(e.value);
                      setDepartmentDataFilter({
                        department_id: e.value,
                      });
                      DesignationFilter(deptFil, e.value);
                      setCurrentPage(1);
                      setDepartment1(e);
                    }}
                    value={department1}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>
                    Select Designation <span className="text-danger"></span>
                  </label>
                  <Select
                    name="designation_id"
                    options={dropDesig}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      setDesignationId(e.value);
                      setDepartmentDataFilter({
                        designation_id: e.value,
                      });
                      setCurrentPage(1);
                      setempDesg1(e);
                    }}
                    value={empDesg1}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    clearFilter();
                    // setCurrentPage(1);
                  }}
                >
                  CLEAR
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    onFilterSubmit();
                  }}
                  className="btn btn-primary-inner bpi-main"
                >
                  Apply
                </button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>

      <Modal
        show={printable}
        onHide={closePrintable}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Printable Export</Modal.Title>
        </Modal.Header>
        <form
        // onSubmit={handleSubmit}
        >
          <Modal.Body>
            <div className="form-group innergroup">
              <label>
                Select Location<span class="text-danger">*</span>
              </label>
              <Select
                name="business_unit_id"
                options={subPersonalArea}
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={true}
                onChange={(e) => {
                  setPrintableData(e);
                  setPrintableError(false);
                }}
                value={printableData}
              />
              {printableError && (
                <div className="small text-danger">
                  *Select Location For Printable Export
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                closePrintable();
              }}
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => {
                ValidateExport();
              }}
              className="btn btn-primary-inner bpi-main"
            >
              Print Export
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default EmployeeDir;
