import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import * as RelCodeServices from "../../service/releaseCode";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import ReactPaginate from "react-paginate";
import AddReleaseCode from "./AddReleaseCode";
import { setDisplayLoader } from "../redux/piDataStore";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import { useDispatch } from "react-redux";
if (typeof window !== "undefined") {
  injectStyle();
}

function ReleaseCode() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [pageCount, setpageCount] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [relGrpList, setRelGrpList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [relGrpData, setRelGrpData] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getReleaseCode = (search, pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    RelCodeServices.getReleaseCode(
      handleKey(search, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setRelGrpList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getExportDetails = (search, sort_by) => {
    RelCodeServices.getExportValue(search, sort_by)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "releasecode.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteReleaseCode = (idx) => {
    RelCodeServices.deleteReleaseCode(idx)
      .then((res) => {
        if (res.data.status === true) {
          getReleaseCode(
            searchStr,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id, grpName) => {
    confirmAlert({
      title: "Delete Release Code",
      message: `Are you sure to delete ${grpName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteReleaseCode(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSearch = (e) => {
    getReleaseCode(
      e.target.value.toLowerCase(),
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReleaseCode(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getReleaseCode(data, currentPage, pageSizeNo, sortData.sort_by, true);
    }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getReleaseCode(searchStr, currentPage, pageSizeNo, sdata.sort_by, true);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getReleaseCode(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const addForm = () => {
    setShowForm(true);
    setEditMode(false);
    setRelGrpData(null);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const editGrp = (data) => {
    setShowForm(true);
    setEditMode(true);
    setRelGrpData(data);
  };

  useEffect(() => {
    getReleaseCode(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      {showForm ? (
        <>
          <AddReleaseCode
            closeForm={closeForm}
            showForm={showForm}
            edit={editMode}
            editGrp={relGrpData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/search-po">Search Order</Link>
            </li>
            <li>
              <Link to="/active-po">Active PO Status</Link>
            </li>
            <li>
              <Link to="/approver-assignment">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/releasegroup">Release Group</Link>
            </li>
            <li class="active">
              <Link to="/releasecode">Release Code</Link>
            </li>
            <li>
              <Link to="/purchase-org">Purchase Organisation</Link>
            </li>
            <li>
              <Link to="/purchase-group">Purchase Group</Link>
            </li>
            <li>
              <Link to="/releasestrat">Release Strategy</Link>
            </li>
            <li>
              <Link to="/releasestrategy">Approval Mapping</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <ToastContainer autoClose={1000} />
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Release Code</h4>
                  {/* <Breadcrumb>
                                                <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                                                <Breadcrumb.Item href="#">
                                                    Transactions
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>Release Code</Breadcrumb.Item>
                                            </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on <strong>Code</strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        id="searchbar"
                        className="form-control"
                        placeholder="Search Code..."
                        onChange={(e) => handleSearch(e)}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={relGrpList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(searchStr, sortData.sort_by)
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => {
                        addForm();
                      }}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th
                      onClick={(e) => {
                        handlerData("code");
                      }}
                      className={
                        sortData["code"] === undefined
                          ? "unsortcls"
                          : sortData["code"]
                      }
                    >
                      Code
                    </th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {relGrpList?.length ? (
                    relGrpList?.map((data, index) => (
                      <>
                        <tr key={`desg${index}`}>
                          <td>
                            <button
                              class="btn btn-blanktd text-primary ml-2 f-12"
                              type="button"
                              onClick={() => {
                                editGrp(data);
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              class="btn btn-blanktd text-danger ml-2 f-12"
                              type="button"
                              onClick={() => {
                                submit(data?.id, data?.code);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                          <td>{data?.code}</td>
                          <td
                            style={{
                              maxWidth: "130px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.desc}
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {relGrpList?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseCode;
