import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as TravelListingAPI from "../../service/travelService";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import moment from "moment/moment";

const AddConveyence = (props) => {
  const {
    conveyanceValue,
    isEdit,
    conveyanceList,
    getConveyenceList,
    setIsEdit,
    setCheckActiveTab,
    att,
    formView,
    setFormView,
  } = props;

  const navigate = useNavigate();
  const { state } = useLocation();
  const [travelModeValue, setTravelModeValue] = useState();
  const [reasonModeValue, setReasonModeValue] = useState();
  const [stateData, setStateData] = useState([]);
  const [stateValue, setStateValue] = useState();
  const [codeFlagTravel, setCodeFlagTravel] = useState(false);
  const [codeFlagException, setCodeFlagException] = useState(false);
  const [codeFlagVendorState, setCodeFlagVendorState] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const userData = useSelector(selectUserData);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [flg, setFlg] = useState(false);
  const [chkUpdatedData, setChkUpdatedData] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [modeList, setModeList] = useState([]);
  const [shouldDeleteAttachment, setShouldDleteAttachment] = useState(true);
  // const [formView, setFormView] = useState(false);

  const [formData, setFormData] = useState([
    {
      conveyance_date: state?.travel_detail_data[0]?.from_date,
      // from_location_name: state?.travel_detail_data[0]?.from_city_id,
      // to_location_name: state?.travel_detail_data[1]?.to_city_id,
      from_location_name: "",
      to_location_name: "",
      reason_for_exception_name: undefined,
      mode_name: "",
      vendor_state_id: 0,
      vendor_name: undefined,
      vendor_invoice: undefined,
      invoice_date: undefined,
      amount: "",
      vendor_state_name: undefined,
      is_exception: false,
    },
  ]);
  let condition = "true";
  const [formValidation, setFormValidation] = useState({
    from_location_name: "",
    to_location_name: "",
    conveyance_date: "",
    mode_name: "",
    vendor_state_id: "",
    vendor_name: "",
    vendor_invoice: "",
    amount: "",
    reason_for_exception_name: "",
  });

  const travelByOption = [
    { value: "", label: "Select" },
    { value: "Taxi", label: "Taxi" },
    { value: "OLA/Uber sedan/Prime/XL", label: "OLA/Uber sedan/Prime/XL" },
    { value: "OLA/Uber micro/ Mini/Go", label: "OLA/Uber micro/ Mini/Go" },
    // { value: "OLA/Uber pool", label: "OLA/Uber pool" },
    { value: "Rickshaw(Cycle/Auto)", label: "Rickshaw(Cycle/Auto)" },
    { value: "Public Transport", label: "Public Transport" },
  ];
  const reasonForExeption = [
    // { value: "", label: "Select" },
    { value: "Travel more then 20Km", label: "Travel more then 20Km" },
    {
      value: "Entitled transport not available",
      label: "Entitled transport not available",
    },
  ];
  const pushTravelMode = (e, i) => {
    changeHandler(e.value, "mode_name", i);
  };
  const pushReasonException = (e, i) => {
    changeHandler(e.value, "reason_for_exception_name", i);
  };
  const pushState = (e, i) => {
    changeHandler(e.value, "vendor_state_id", i);
  };

  const getStateList = async (paginate) => {
    await TravelListingAPI.getStateList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.gst_state_code}- ${obj?.name}`,
          };
        });
        setStateData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getModeList = async () => {
    await TravelListingAPI.getModeList()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.mode}`,
          };
        });
        setModeList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    formData?.map((formData, i) => {
      if (!formData.conveyance_date) {
        isError = true;
        formerr.conveyance_date = "*Date is required ";
        setFormValidation(formerr);
      }
      if (!formData.from_location_name) {
        isError = true;
        formerr.from_location_name = "*From Location is required ";
        setFormValidation(formerr);
      }
      if (!formData.to_location_name) {
        isError = true;
        formerr.to_location_name = "*To Location is required ";
        setFormValidation(formerr);
      }
      if (!formData.mode_name) {
        isError = true;
        formerr.mode_name = "Travel By is required";
        setFormValidation(formerr);
      }
      if (
        formData.mode_name !== "Rickshaw(Cycle/Auto)" &&
        formData.mode_name !== "Public Transport" &&
        !formData.vendor_name
      ) {
        isError = true;
        formerr.vendor_name = "*Vendor Name is required";
        setFormValidation(formerr);
      }
      if (
        formData.mode_name !== "Rickshaw(Cycle/Auto)" &&
        formData.mode_name !== "Public Transport" &&
        formData.invoice_date == ""
      ) {
        isError = true;
        formerr.invoice_date = "Invoice Date is required";
        setFormValidation(formerr);
      }
      if (!formData.vendor_state_id) {
        isError = true;
        formerr.vendor_state_id = "*Vendor State is Required";
        setFormValidation(formerr);
      }
      if (
        formData.mode_name !== "Rickshaw(Cycle/Auto)" &&
        formData.mode_name !== "Public Transport" &&
        !formData.vendor_invoice
      ) {
        isError = true;
        formerr.vendor_invoice = "*Vendor Invoice is required";
        setFormValidation(formerr);
      }
      if (!formData.amount) {
        isError = true;
        formerr.amount = "*Amount is required";
        setFormValidation(formerr);
      }
      // if (
      //   (formData.mode_name === "Taxi" ||
      //     formData.mode_name === "OLA/Uber sedan/Prime/XL" ||
      //     formData.mode_name === "OLA/Uber micro/ Mini/Go") &&
      // ) {
      //   isError = true;
      //   formerr.reason_for_exception_name = "*Reason For Exception is required";
      //   setFormValidation(formerr);
      // }
    });
    return isError;
  };

  const validateSSCFeature = () => {
    let errorExist = false;
    formData?.map((item) => {
      if (
        item?.conveyance_date === "" ||
        item?.mode_name === "" ||
        item?.vendor_state_id === 0 ||
        item?.vendor_name === "" ||
        // item?.vendor_invoice === 0 ||
        item?.invoice_date === ""
        // item?.amount === 0
      ) {
        errorExist = true;
      }
      return item;
    });
    return errorExist;
  };
  const changeHandler = (value, name, index) => {
    let fd = JSON.parse(JSON.stringify(formData));
    if (name === "mode_name") {
      fd[index].amount = "";
    }
    if (name === "reason_for_exception_name" && value !== "") {
      fd[index].is_exception = true;
    }
    // if (
    //   name === "mode_name" &&
    //   (value === "Taxi" ||
    //     value === "OLA/Uber sedan/Prime/XL" ||
    //     value === "OLA/Uber micro/ Mini/Go")
    // ) {
    //   fd[index].is_exception = true;
    // }

    if (
      name === "mode_name" &&
      (value === "Taxi" ||
        value === "Rickshaw(Cycle/Auto)" ||
        value === "Public Transport")
    ) {
      fd[index].invoice_date = undefined;
      fd[index].vendor_name = undefined;
      fd[index].vendor_invoice = undefined;
    }
    if (name === "conveyance_date" && value !== "") {
      fd[index].invoice_date = undefined;
    }
    // if (name === "mode_name" && value === "Rickshaw(Cycle/Auto)") {
    //   fd[index].reason_for_exception_name = undefined;
    // }
    fd[index][name] = value;
    setFormData(fd);
  };
  const setValue = (id, value) => {
    return {
      value: id,
      label: value,
    };
  };

  const addMoreExpense = (ele, i) => {
    if (validateSSCFeature()) {
    } else {
      if (formData.length < 90) {
        const copyValue = JSON.parse(JSON.stringify(formData));
        const ele = {};
        ele.conveyance_date = 0;
        ele.mode_name = "";
        ele.vendor_state_id = "";
        ele.vendor_name = "";
        ele.vendor_invoice = "";
        ele.invoice_date = "";
        ele.amount = "";
        ele.from_location_name = "";
        ele.to_location_name = "";
        copyValue.push(ele);
        setFormData(copyValue);
      } else {
        toast.error("max");
      }
    }
  };
  function expenseConveyanceEdit() {
    const arrayGST = [];
    let object = JSON.parse(JSON.stringify(conveyanceValue));
    if (object) {
      for (let i = 0; i < object.length; i++) {
        arrayGST.push({
          id: object[i].id,
          conveyance_date: moment(object[i].conveyance_date).format(
            "YYYY-MM-DD"
          ),
          from_location_name: object[i].from_location_name,
          to_location_name: object[i].to_location_name,
          reason_for_exception_name: object[i].reason_for_exception_name,
          mode_name: object[i].mode_name,
          vendor_state_id: object[i].vendor_state_id,
          vendor_state_name: object[i].vendor_state_name,
          vendor_name: object[i].vendor_name,
          vendor_invoice: object[i].vendor_invoice,
          invoice_date: moment(object[i].invoice_date).format("YYYY-MM-DD"),
          amount: object[i].amount,
        });
      }
      setFormData(arrayGST);
      setAttachment(att);
      setUploadDataIm([]);
      setfiles([]);
    }
  }
  const saveConveyenceInfo = async (data) => {
    if (condition === "true") {
      condition = "false";

      let files = [];
      for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
        await TravelListingAPI.uploadFile(filesToUpload[afilee])
          .then((response) => {
            files.push(response.data);
          })
          .catch((error) => {
            // dispatch(setDisplayLoader("Hide"));

            toast.error(`${error} `, {
              autoClose: 2000,
            });
            return;
          });
      }
      data.attachment_path = files;
      await TravelListingAPI.saveConveyenceInfo(data)
        .then((response) => {
          condition = "true";
          if (response?.data.code == 200) {
            setFlg(false);
            // resetState();
            // setCheckActiveTab();
            setFormView(false);
            // navigate("/expense-listing");
            setDeleteAttachment([]);
            setFilesToUpload([]);
            setShouldDleteAttachment(true);
            addMoreExpense(formData);
            toast.success("Conveyance Added Successfully");
            setFormData([
              {
                conveyance_date: moment(
                  state?.travel_detail_data[1]?.from_date
                ).format("YYYY-MM-DD"),
                // from_location_name: state?.travel_detail_data[0]?.from_city_id,
                // to_location_name: state?.travel_detail_data[1]?.to_city_id,
                from_location_name: "",
                to_location_name: "",
                reason_for_exception_name: undefined,
                mode_name: "",
                vendor_state_id: 0,
                vendor_name: "",
                vendor_invoice: "",
                invoice_date: "",
                amount: "",
                vendor_state_name: undefined,
              },
            ]);
            setStateValue({});
            setTravelModeValue("");
            getConveyenceList(state?.id);
            setAttachment(att);
            // getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const saveConveyenceInfoNew = async (data) => {
    if (condition === "true") {
      condition = "false";
      await TravelListingAPI.saveConveyenceInfo(data)
        .then((response) => {
          condition = "true";
          if (response?.data.code == 200) {
            // resetState();
            setCheckActiveTab();
            // navigate("/expense-listing");
            addMoreExpense(formData);
            toast.success("Conveyance Added Successfully");
            setFormData([]);
            setTravelModeValue("");
            getConveyenceList(state?.id);
            // getReceiptDataList(false);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateConveyanceExpense = async (id, data) => {
    data?.map((fd, i) => {
      fd.is_saved = true;
    });

    let files = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      await TravelListingAPI.uploadFile(filesToUpload[afilee])
        .then((response) => {
          files.push(response.data);
        })
        .catch((error) => {
          // dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `, {
            autoClose: 2000,
          });
          return;
        });
    }

    let dataSave = {
      travel_id: state?.id,
      attachment_path: files,
      attachment_delete: deleteattachment,
      conveyance_data: data,
    };
    if (condition === "true") {
      condition = "false";
      await TravelListingAPI.updateConveyanceExpense(id, dataSave)
        .then((response) => {
          condition = "true";
          if (response.data.code == 200) {
            // setIsEdit(false);
            setAttachment([]);
            setfiles([]);
            setCheckActiveTab();
            setFormView(false);
            toast.success(response?.data?.message);
            getConveyenceList(state?.id);
            // setFormData("");
            // setIsDelete(false)
            setFilesToUpload([]);
            setFormData([]);
            setDeleteAttachment([]);
            setShouldDleteAttachment(true);
            setUploadDataIm([]);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const updateConveyanceExpenseNN = async (id, data) => {
    data?.map((fd, i) => {
      fd.is_saved = true;
      fd.is_exception = fd?.reason_for_exception_name ? true : false;
      fd.conveyance_date = moment(fd.conveyance_date).format("YYYY-MM-DD");
      fd.invoice_date = moment(fd.invoice_date).format("YYYY-MM-DD");
    });
    let dataSave = {
      travel_id: state?.id,
      attachment_path: uploadDataIm,
      attachment_delete: deleteattachment,
      conveyance_data: data,
    };
    if (condition === "true") {
      condition = "false";
      await TravelListingAPI.updateConveyanceExpense(id, dataSave)
        .then((response) => {
          condition = "true";
          if (response.data.code == 200) {
            setAttachment([]);
            setfiles([]);
            setFormView(false);
            toast.success(response?.data?.message);
            getConveyenceList(state?.id);
            setFormData([]);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  // useEffect(() => {
  //   let data = [];

  //   if (userData?.emp_sub_group?.includes("S")) {
  //     data.push({ value: "Taxi", label: "Taxi" });
  //   } else if (userData?.emp_sub_group?.includes("M")) {
  //     data.push(
  //       { value: "Taxi", label: "Taxi" },
  //       { value: "OLA/Uber sedan/Prime/XL", label: "OLA/Uber sedan/Prime/XL" }
  //     );
  //   } else if (userData?.emp_sub_group?.includes("J")) {
  //     data.push(
  //       { value: "Taxi", label: "Taxi" },
  //       { value: "OLA/Uber sedan/Prime/XL", label: "OLA/Uber sedan/Prime/XL" },
  //       { value: "Micro/Mini/Go", label: "Micro/Mini/Go" }
  //     );
  //   } else if (userData?.emp_sub_group?.includes("E")) {
  //     data.push({
  //       value: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //       label: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //     });
  //   } else if (userData?.emp_sub_group?.includes("A")) {
  //     data.push({
  //       value: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //       label: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //     });
  //   } else if (userData?.emp_sub_group?.includes("AO")) {
  //     data.push({
  //       value: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //       label: "Except Riksha & Public transport & OLA/Uber(Pool)",
  //     });
  //   } else {
  //     data.push(
  //       { value: "", label: "Select" },
  //       { value: "Taxi", label: "Taxi" },
  //       { value: "OLA/Uber sedan/Prime/XL", label: "OLA/Uber sedan/Prime/XL" },
  //       { value: "OLA/Uber micro/ Mini/Go", label: "OLA/Uber micro/ Mini/Go" },
  //       { value: "OLA/Uber pool", label: "OLA/Uber pool" },
  //       { value: "Rickshaw(Cycle/Auto)", label: "Rickshaw(Cycle/Auto)" },
  //       { value: "Public Transport", label: "Public Transport" }
  //     );
  //   }

  //   setOpt(data);
  // }, [userData]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();

  useEffect(() => {
    let data1 = [];
    conveyanceList?.map((data, i) => {
      data1.push(data);
    });
  }, [conveyanceList]);
  useEffect(() => {
    setAttachment(att);
  }, [att]);

  function hotelExpense() {
    const minVal = conveyanceList;
    const index = minVal?.findIndex((v) => v?.id == conveyanceValue[0]?.id);
    // minVal.splice(index, 1, formData[0]);
    updateConveyanceExpense(state.id, minVal);
  }
  function hotelExpenseEE() {
    const minVal = conveyanceList;
    const index = minVal?.findIndex(
      (v) => Number(v?.id) == Number(conveyanceValue[0]?.id)
    );
    minVal.splice(index, 1, formData[0]);
    updateConveyanceExpenseNN(state.id, minVal);
  }
  const handleValidateForm = (data) => {
    if (isEdit == "false") {
      if (handleValidate()) return false;
      data?.map((fd, i) => {
        fd.is_saved = false;
      });
      let payload = {
        travel_id: state?.id,
        attachment_path: uploadDataIm,
        attachment_delete: deleteattachment,
        conveyance_data: data,
      };
      saveConveyenceInfo(payload);
    } else {
      if (handleValidate()) return false;
      hotelExpense();
    }
  };
  const handleValidateFormNN = (data) => {
    if (isEdit == "false") {
      if (handleValidate()) return false;
      data?.map((fd, i) => {
        fd.is_saved = false;
      });
      let payload = {
        travel_id: state?.id,
        attachment_path: uploadDataIm,
        attachment_delete: deleteattachment,
        conveyance_data: data,
      };
      saveConveyenceInfo(payload);
    } else {
      if (handleValidate()) return false;
      hotelExpense();
    }
  };

  const handleAddMore = () => {
    addMoreExpense();
    setFormView(true);
  };

  const handleValidateFormNew = (data) => {
    if (isEdit == "false") {
      if (handleValidate()) return false;
      data?.map((fd, i) => {
        fd.is_saved = false;
        fd.conveyance_date = moment(fd.conveyance_date).format("YYYY-MM-DD");
        fd.invoice_date = moment(fd.invoice_date).format("YYYY-MM-DD");
      });
      let payload = {
        travel_id: state?.id,
        // attachment_path: [],
        // attachment_delete: [],
        // attachment_path: uploadDataIm,
        // attachment_delete: deleteattachment,
        conveyance_data: data,
      };

      saveConveyenceInfo(payload);
    } else {
      if (handleValidate()) return false;
      hotelExpenseEE();
      setChkUpdatedData(false);
    }
  };
  const onProfileChange = async (e, flag) => {
    if (shouldDeleteAttachment) {
      let delAtt = [...deleteattachment];
      attachment.forEach((obj) => delAtt.push(obj.id));
      setDeleteAttachment(delAtt);
      setShouldDleteAttachment(false);
    }
    let file = e.target.files;
    let tmp_filesToUpload = [...filesToUpload];
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      tmp_filesToUpload.push(fileData);

      // await TravelListingAPI.uploadFile(fileData).then((response) => {
      //   let name = { name: data.name };
      //   filename.push(name);
      //   files.push(response.data);
      // });
    }
    setFilesToUpload(tmp_filesToUpload);
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [...deleteattachment];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            if (!deleteId.includes(del.id)) deleteId.push(del.id);
          }
        });
        setAttachment(data2);
        setDeleteAttachment(deleteId);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1 col-2">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
              <br></br>
              <span>{data2?.name}</span>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const testFun = (e, i) => {
    formData[i].is_exception = true;
    if (
      (userData?.emp_sub_group === "S2" ||
        userData?.emp_sub_group === "S1" ||
        userData?.emp_sub_group === "S3" ||
        userData?.emp_sub_group === "S4") &&
      e.value === "Taxi"
    ) {
      formData[i].reason_for_exception_name = "Travel more then 20Km";
    } else if (
      (userData?.emp_sub_group === "M1" || userData?.emp_sub_group === "M2") &&
      (e.value === "Taxi" || e.value === "OLA/Uber sedan/Prime/XL")
    ) {
      formData[i].reason_for_exception_name = "Travel more then 20Km";
    } else if (
      (userData?.emp_sub_group === "J1" ||
        userData?.emp_sub_group === "J2" ||
        userData?.emp_sub_group === "J3") &&
      (e.value === "Taxi" ||
        e.value === "OLA/Uber sedan/Prime/XL" ||
        e.value === "OLA/Uber micro/Mini/Go")
    ) {
      formData[i].reason_for_exception_name = "Travel more then 20Km";
    } else if (
      (userData?.emp_sub_group === "E1" ||
        userData?.emp_sub_group === "E2" ||
        userData?.emp_sub_group === "E3" ||
        userData?.emp_sub_group === "E4" ||
        userData?.emp_sub_group === "AO1" ||
        userData?.emp_sub_group === "AO2" ||
        userData?.emp_sub_group === "AO3" ||
        userData?.emp_sub_group === "A1" ||
        userData?.emp_sub_group === "A2" ||
        userData?.emp_sub_group === "A3") &&
      (e.value === "Taxi" ||
        e.value === "OLA/Uber sedan/Prime/XL" ||
        e.value === "OLA/Uber micro/ Mini/Go")
    ) {
      formData[i].reason_for_exception_name = "Travel more then 20Km";
    } else {
      delete formData[i].reason_for_exception_name;
      formData[i].is_exception = false;
    }

    // let dd = JSON.parse(JSON.stringify(formData));
    // dd[i].amount = "";
    // setFormData(dd);
  };

  const removeHotel = (idx) => {
    setFormView(false);
    const newExpDetails = formData.filter((item, i) => i !== idx);
    setFormData(newExpDetails);
    setAttachment([]);
    setfiles([]);
  };
  const removeHotelData = (i) => {
    confirmAlert({
      title: "Delete Hotel Listing",
      message: `Are you sure to delete Hotel Listing?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => removeHotel(i),
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    getStateList(false);
    getModeList();
    setAttachment(att);
    // setFormData({
    //   ...formData,
    //   invoice_date: state?.travel_detail_data[0]?.from_date,
    // });
  }, [state]);
  useEffect(() => {
    if (isEdit == true) {
      setChkUpdatedData(true);
      expenseConveyanceEdit();
      setFormValidation({
        vendor_invoice: "",
        conveyance_date: "",
        from_location_name: "",
        to_location_name: "",
        mode_name: "",
        vendor_state_id: "",
        invoice_date: "",
        amount: "",
        vendor_name: "",
      });
    }
  }, [isEdit, conveyanceValue]);
  // useEffect(() => {
  //   setFormValidation({ ...formValidation, vendor_invoice: "" });
  // }, [formData]);
  return (
    <>
      <div class="px-4 pb-2">
        {formView == true &&
          formData?.map((expData, i) => (
            <>
              <div class="bg-light px-3 pt-3 mb-3">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group innergroup d-flex flex-column">
                      <label>
                        Date<span class="text-danger">*</span>
                      </label>
                      {/* <input
                        type="date"
                        class="form-control"
                        name="conveyance_date"
                        onChange={(e) => {
                          changeHandler(e.target.value, "conveyance_date", i);
                          setFormValidation({
                            ...formValidation,
                            conveyance_date: "",
                          });
                        }}
                        max={
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        }
                        min={state?.travel_detail_data[0]?.from_date}
                        value={formData[i]?.conveyance_date}
                      /> */}
                      <DatePicker
                        selected={
                          formData[i]?.conveyance_date &&
                          formData[i]?.conveyance_date !== ""
                            ? new Date(formData[i]?.conveyance_date)
                            : ""
                        }
                        onChange={(date) => {
                          changeHandler(date, "conveyance_date", i);
                          setFormValidation({
                            ...formValidation,
                            conveyance_date: "",
                          });
                        }}
                        minDate={
                          state?.travel_detail_data[0]?.from_date !== "" &&
                          new Date(state?.travel_detail_data[0]?.from_date)
                        }
                        maxDate={
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date !== "" &&
                          new Date(
                            state?.travel_detail_data[
                              state?.travel_detail_data?.length - 1
                            ]?.to_date
                          )
                        }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.conveyance_date}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        From<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="From Location"
                        // disabled
                        name="from_location_name"
                        onChange={(e) => {
                          changeHandler(
                            e.target.value,
                            "from_location_name",
                            i
                          );
                          setFormValidation({
                            ...formValidation,
                            from_location_name: "",
                          });
                        }}
                        value={formData[i]?.from_location_name}
                      />
                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.from_location_name}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        To<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="To Location"
                        name="to_location_name"
                        onChange={(e) => {
                          changeHandler(e.target.value, "to_location_name", i);
                          setFormValidation({
                            ...formValidation,
                            to_location_name: "",
                          });
                        }}
                        value={formData[i]?.to_location_name}
                        // disabled
                        // value={state?.travel_detail_data[1]?.to_city_name}
                      />
                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.to_location_name}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Travel By<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={travelByOption}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="mode_name"
                        onChange={(e) => {
                          testFun(e, i);
                          pushTravelMode(e, i);
                          setCodeFlagTravel(true);
                          setTravelModeValue(e);
                          setFormValidation({
                            ...formValidation,
                            mode_name: "",
                          });
                        }}
                        // value={
                        //   formData && codeFlagTravel == false
                        //     ? setValue(
                        //         formData[i].mode_name,
                        //         formData[i].mode_name
                        //         // ,
                        //         // formData[i].gl_id_name
                        //       )
                        //     : travelModeValue
                        // }
                        value={
                          travelByOption?.filter((each) => {
                            if (each?.value === formData[i]?.mode_name) {
                              return true;
                            }
                          })[0]
                        }
                      />

                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.mode_name}
                      </div>
                    </div>
                  </div>
                  {/* <Select
                      options={modeList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      name="paid_by"
                      onChange={(e) => {
                        pushTravelMode(e, i);
                        setCodeFlagTravel(true);
                        setFormValidation({
                          ...formValidation,
                          mode_name: "",
                        });
                      }}
                      value={
                        formData && codeFlagTravel == false
                          ? setValue(
                              formData[i].mode_name
                              // ,
                              // formData[i].gl_id_name
                            )
                          : travelModeValue
                      }
                    /> */}
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Vendor state<span class="text-danger">*</span>
                      </label>
                      <Select
                        options={stateData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        name="vendor_state_id"
                        onChange={(e) => {
                          pushState(e, i);
                          setCodeFlagVendorState(true);
                          setStateValue(e);
                          setFormValidation({
                            ...formValidation,
                            vendor_state_id: "",
                          });
                        }}
                        value={
                          stateData?.filter((fd) => {
                            if (fd.value === formData[i]?.vendor_state_id) {
                              return true;
                            }
                          })[0]
                        }
                      />
                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.vendor_state_id}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Vendor Name<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={30}
                        class="form-control"
                        name="vendor_name"
                        placeholder="Enter Vedor Name"
                        onChange={(e) => {
                          changeHandler(e.target.value, "vendor_name", i);
                          setFormValidation({
                            ...formValidation,
                            vendor_name: "",
                          });
                        }}
                        disabled={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                        }
                        value={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                            ? ""
                            : formData[i]?.vendor_name
                        }
                        // value={formData[i]?.vendor_name}
                      />
                      <div className="small text-danger">
                        {i == Number(formData?.length - 1) &&
                          formValidation?.vendor_name}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Vendor Invoice<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={20}
                        class="form-control"
                        name="vendor_invoice"
                        disabled={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                        }
                        placeholder="Enter Vedor Invoice"
                        onChange={(e) => {
                          changeHandler(e.target.value, "vendor_invoice", i);
                          setFormValidation({
                            ...formValidation,
                            vendor_invoice: "",
                          });
                        }}
                        value={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                            ? ""
                            : formData[i]?.vendor_invoice
                        }
                      />
                      <div className="small text-danger">
                        {i == Number(formData.length - 1) &&
                          formValidation.vendor_invoice}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup d-flex flex-column">
                      <label>
                        Invoice Date<span class="text-danger">*</span>
                      </label>
                      {/* <input
                        type="date"
                        class="form-control"
                        name="invoice_date"
                        disabled={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                        }
                        // min={state?.travel_detail_data[1]?.from_date}
                        onChange={(e) => {
                          changeHandler(e.target.value, "invoice_date", i);
                          setFormValidation({
                            ...formValidation,
                            invoice_date: "",
                          });
                        }}
                        max={
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date
                        }
                        min={formData[i]?.conveyance_date}
                        // min={state?.travel_detail_data[0]?.from_date}
                        value={
                          // formData[i].vendor_state_name
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                            formData[i].mode_name === "Public Transport"
                            ? ""
                            : formData[i]?.invoice_date
                        }
                      /> */}
                      <DatePicker
                        selected={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                            ? ""
                            : formData[i]?.invoice_date &&
                              formData[i]?.invoice_date !== ""
                            ? new Date(formData[i]?.invoice_date)
                            : ""
                        }
                        onChange={(date) => {
                          changeHandler(date, "invoice_date", i);
                          setFormValidation({
                            ...formValidation,
                            invoice_date: "",
                          });
                        }}
                        disabled={
                          formData[i].mode_name === "Rickshaw(Cycle/Auto)" ||
                          formData[i].mode_name === "Public Transport"
                        }
                        minDate={
                          formData[i]?.conveyance_date !== "" &&
                          new Date(formData[i]?.conveyance_date)
                        }
                        maxDate={
                          state?.travel_detail_data[
                            state?.travel_detail_data?.length - 1
                          ]?.to_date !== "" &&
                          new Date(
                            state?.travel_detail_data[
                              state?.travel_detail_data?.length - 1
                            ]?.to_date
                          )
                        }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                      <div className="small text-danger">
                        {i == Number(formData.length - 1) &&
                          formValidation.invoice_date}
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>
                        Amount<span class="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        pattern="\d*"
                        maxlength="14"
                        min="0"
                        class="form-control"
                        step="0.01"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={blockInvalidChar}
                        placeholder="Enter Amount"
                        name="amount"
                        onChange={(e) => {
                          changeHandler(e.target.value, "amount", i);
                          setFormValidation({
                            ...formValidation,
                            amount: "",
                          });
                        }}
                        value={
                          formData[i]?.amount &&
                          Math.max(1, formData[i]?.amount)
                        }
                      />
                      <div className="small text-danger">
                        {i == Number(formData.length - 1) &&
                          formValidation.amount}
                      </div>
                      {/* <div className="small text-danger">
                        {isEdit === true
                          ? ""
                          : i == Number(formData.length - 1) &&
                            formValidation.amount}
                      </div> */}
                    </div>
                  </div>

                  {(userData?.emp_sub_group === "S2" ||
                    userData?.emp_sub_group === "S1" ||
                    userData?.emp_sub_group === "S3" ||
                    userData?.emp_sub_group === "S4") &&
                  formData[i]?.mode_name === "Taxi" ? (
                    <>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Reason For Exception
                            <span class="text-danger">*</span>
                          </label>
                          <Select
                            options={reasonForExeption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="reason_for_exception_name"
                            // defaultValue={{
                            //   value: "Travel more then 20Km",
                            //   label: "Travel more then 20Km",
                            // }}
                            onChange={(e) => {
                              debugger;
                              pushReasonException(e, i);
                              setFormData({ is_exception: true });

                              setCodeFlagException(true);
                              setFormValidation({
                                ...formValidation,
                                reason_for_exception_name: "",
                              });
                              // setFormData({
                              //   ...formData,
                              //   is_exception: true
                              // })
                              setReasonModeValue(e);
                            }}
                            value={
                              formData && codeFlagException == false
                                ? setValue(
                                    formData[i].reason_for_exception_name,
                                    formData[i].reason_for_exception_name
                                    // ,
                                    // formData[i].gl_id_name
                                  )
                                : reasonModeValue
                            }
                          />

                          <div className="small text-danger">
                            {i == Number(formData?.length - 1) &&
                              formValidation?.reason_for_exception_name}
                          </div>
                        </div>
                      </div>
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style={{ color: "red", paddingTop: "10px" }}
                      ></i>
                    </>
                  ) : (
                    ""
                  )}

                  {(userData?.emp_sub_group === "M1" ||
                    userData?.emp_sub_group === "M2") &&
                  (formData[i]?.mode_name === "Taxi" ||
                    formData[i]?.mode_name === "OLA/Uber sedan/Prime/XL") ? (
                    <>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Reason For Exception
                            <span class="text-danger">*</span>
                          </label>
                          <Select
                            options={reasonForExeption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="reason_for_exception_name"
                            // defaultValue={{
                            //   value: "Travel more then 20Km",
                            //   label: "Travel more then 20Km",
                            // }}
                            onChange={(e) => {
                              pushReasonException(e, i);
                              setCodeFlagException(true);
                              setFormValidation({
                                ...formValidation,
                                reason_for_exception_name: "",
                              });
                              // setFormData({
                              //   ...formData,
                              //   is_exception: true
                              // })
                              setReasonModeValue(e);
                            }}
                            value={
                              formData && codeFlagException == false
                                ? setValue(
                                    formData[i].reason_for_exception_name,
                                    formData[i].reason_for_exception_name
                                    // ,
                                    // formData[i].gl_id_name
                                  )
                                : reasonModeValue
                            }
                          />

                          <div className="small text-danger">
                            {i == Number(formData.length - 1) &&
                              formValidation.reason_for_exception_name}
                          </div>
                        </div>
                      </div>
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style={{ color: "red", paddingTop: "10px" }}
                      ></i>
                    </>
                  ) : (
                    ""
                  )}
                  {(userData?.emp_sub_group === "J1" ||
                    userData?.emp_sub_group === "J2" ||
                    userData?.emp_sub_group === "J3") &&
                  (formData[i]?.mode_name === "Taxi" ||
                    formData[i]?.mode_name === "OLA/Uber sedan/Prime/XL" ||
                    formData[i]?.mode_name === "OLA/Uber micro/Mini/Go") ? (
                    <>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Reason For Exception
                            <span class="text-danger">*</span>
                          </label>
                          <Select
                            options={reasonForExeption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="reason_for_exception_name"
                            // defaultValue={{
                            //   value: "Travel more then 20Km",
                            //   label: "Travel more then 20Km",
                            // }}
                            onChange={(e) => {
                              pushReasonException(e, i);
                              setCodeFlagException(true);
                              setFormValidation({
                                ...formValidation,
                                reason_for_exception_name: "",
                              });
                              // setFormData({
                              //   ...formData,
                              //   is_exception: true
                              // })
                              setReasonModeValue(e);
                            }}
                            value={
                              formData && codeFlagException == false
                                ? setValue(
                                    formData[i].reason_for_exception_name,
                                    formData[i].reason_for_exception_name
                                    // ,
                                    // formData[i].gl_id_name
                                  )
                                : reasonModeValue
                            }
                          />

                          <div className="small text-danger">
                            {i == Number(formData.length - 1) &&
                              formValidation.reason_for_exception_name}
                          </div>
                        </div>
                      </div>
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style={{ color: "red", paddingTop: "10px" }}
                      ></i>
                    </>
                  ) : (
                    ""
                  )}
                  {(userData?.emp_sub_group === "E1" ||
                    userData?.emp_sub_group === "E2" ||
                    userData?.emp_sub_group === "E3" ||
                    userData?.emp_sub_group === "E4" ||
                    userData?.emp_sub_group === "AO1" ||
                    userData?.emp_sub_group === "AO2" ||
                    userData?.emp_sub_group === "AO3" ||
                    userData?.emp_sub_group === "A1" ||
                    userData?.emp_sub_group === "A2" ||
                    userData?.emp_sub_group === "A3") &&
                  (formData[i]?.mode_name === "Taxi" ||
                    formData[i]?.mode_name === "OLA/Uber sedan/Prime/XL" ||
                    formData[i]?.mode_name === "OLA/Uber micro/ Mini/Go") ? (
                    <>
                      <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>
                            Reason For Exception
                            <span class="text-danger">*</span>
                          </label>
                          <Select
                            options={reasonForExeption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            name="reason_for_exception_name"
                            // defaultValue={{
                            //   value: "Travel more then 20Km",
                            //   label: "Travel more then 20Km",
                            // }}
                            onChange={(e) => {
                              pushReasonException(e, i);
                              setCodeFlagException(true);
                              setFormValidation({
                                ...formValidation,
                                reason_for_exception_name: "",
                              });
                              // setFormData({
                              //   ...formData,
                              //   is_exception: true
                              // })
                              setReasonModeValue(e);
                            }}
                            value={
                              formData && codeFlagException == false
                                ? setValue(
                                    formData[i].reason_for_exception_name,
                                    formData[i].reason_for_exception_name
                                    // ,
                                    // formData[i].gl_id_name
                                  )
                                : reasonModeValue
                            }
                          />

                          <div className="small text-danger">
                            {i == Number(formData.length - 1) &&
                              formValidation.reason_for_exception_name}
                          </div>
                        </div>
                      </div>
                      <i
                        class="fa fa-flag"
                        aria-hidden="true"
                        style={{ color: "red", paddingTop: "10px" }}
                      ></i>
                    </>
                  ) : (
                    ""
                  )}

                  {formData?.length > 1 && (
                    <button
                      class="btn btnblankth text-danger p-0"
                      onClick={() => {
                        removeHotel(i);
                        setFormValidation({
                          conveyance_date: "",
                          mode_name: "",
                          vendor_state_id: "",
                          vendor_name: "",
                          vendor_invoice: "",
                          invoice_date: "",
                          amount: "",
                        });
                      }}
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  )}
                </div>
              </div>
            </>
          ))}
        <div class="col-sm-12">
          <div className="col-sm-10" style={{ float: "left" }}></div>
          {formView === false ? (
            <div
              class="col-sm-2 text-sm-right"
              style={{ float: "right", marginLeft: "20px" }}
            >
              <button
                class="btn btnblanktd text-primary"
                onClick={() => {
                  // addMoreExpense(formData);
                  // handleValidate();
                  // handleValidateForm(formData);
                  // handleValidateFormNew(formData);
                  handleAddMore();
                  setFlg(true);
                }}
              >
                <i class="far fa-plus-square mr-2"></i> Add More
              </button>
            </div>
          ) : (
            ""
          )}
          {formView === true && isEdit !== true ? (
            <div class="col-sm-2 text-sm-right" style={{ float: "right" }}>
              <button
                class="btn btnblanktd text-danger"
                onClick={() => {
                  removeHotel();
                }}
              >
                <i class="far fa-minus-square mr-2"></i> Remove
              </button>
            </div>
          ) : (
            ""
          )}
          {formView === true ? (
            <div
              class="col-sm-2 text-sm-right"
              style={{ float: "right", marginLeft: "20px" }}
            >
              <button
                class="btn btnblanktd text-primary"
                onClick={() => {
                  handleValidateFormNew(formData);
                }}
              >
                <i class="far fa-plus-square mr-2"></i>{" "}
                {chkUpdatedData === true ? "Update Record" : "Add Record"}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div class="mb-6">
          <div class="row">
            <div class="col-md-12">
              <div class="border-top my-3"></div>
            </div>
            <div class="col-md-4 d-flex flex-column mt-3">
              <h5 class="text-theme pb-2 mb-3">
                <strong>
                  Attachments<span class="text-danger">*</span>
                </strong>
              </h5>

              <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
                <label>
                  Attach File<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  disabled
                  value={
                    // edit === true
                    // ? editPolicy?.logo
                    uploadDataIm?.logo?.original_name
                  }
                  placeholder="(jpeg, png, jpg, pdf, msg, doc, docx, xlsx, xlsb)"
                />
                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input
                    type="file"
                    title=""
                    id="policyattachment"
                    multiple
                    name="attachment_path"
                    //  accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                    onChange={(e) => onProfileChange(e, "attachment_path")}
                  />
                </div>
                <p>
                  Selected files{" "}
                  {/* <span> {uploadDataIm[0]?.original_name}</span> */}
                </p>
              </div>
            </div>
          </div>
          {Object.keys(file).length !== 0 ? (
            <div className="d-flex flex-wrap">{attachments123()}</div>
          ) : attachment != undefined && attachment.length != 0 ? (
            <>
              <div class="d-flex flex-wrap">
                {attachment?.map((Data) => (
                  <div class="uploaded-image mr-1 col-2">
                    <i class="" style={{ width: "20px" }}>
                      <a
                        href={Data?.attachment_path}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {Data?.attachment_path?.includes(".pdf") ? (
                          <img
                            src={"/images/policy-icon/pdf.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".xlsx") ? (
                          <img
                            src={"/images/policy-icon/xlsx.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".xls") ? (
                          <img
                            src={"/images/policy-icon/xlsx.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".doc") ? (
                          <img
                            src={"/images/policy-icon/docs.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".docs") ||
                          Data?.attachment_path.includes(".csv") ? (
                          <img
                            src={"/images/policy-icon/docs.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".pptx") ? (
                          <img
                            src={"/images/policy-icon/ppt.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data.attachment_path.includes(".mp4") ? (
                          <img
                            src={"/images/policy-icon/video.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : Data?.attachment_path.includes(".msg") ? (
                          <img
                            src={"/images/policy-icon/msg.png"}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        ) : (
                          <img
                            src={Data.attachment_path}
                            alt="image"
                            width="70px"
                            height="70px"
                          />
                        )}
                      </a>
                    </i>
                    <button
                      type="button"
                      onClick={(e) => {
                        deleteAttachment(Data?.attachment_path?.split("/")[4]);
                      }}
                      className="btn btn-blanktd text-danger ml-2"
                    >
                      <i className="far fa-trash-alt text-danger"></i>
                    </button>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <div class="row">
            <div class="col-md-12">
              <div class="border-top my-3"></div>
            </div>
            {isEdit == true ? (
              <div class="col-md-12 mt-3 text-center">
                <button
                  type="button"
                  class="btn btn-outline-secondary mb-2 mt-2"
                  onClick={() => {
                    setCheckActiveTab("Summary");
                  }}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                <Tooltip
                  title={
                    flg ? "Please Add/Remove record to enable save button" : ""
                  }
                  position="bottom"
                >
                  <button
                    class="btn btn-primary-inner bpi-main"
                    disabled={formView}
                    onClick={() => handleValidateForm(formData)}
                  >
                    Update
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div class="col-md-12 mt-3 text-center">
                <button
                  type="button"
                  class="btn btn-outline-secondary mb-2 mt-2"
                  onClick={() => {
                    setCheckActiveTab("Summary");
                  }}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                <Tooltip
                  title={
                    flg ? "Please Add/Remove record to enable save button" : ""
                  }
                  position="bottom"
                >
                  <button
                    class="btn btn-primary-inner bpi-main"
                    disabled={flg}
                    onClick={() => hotelExpense(formData)}
                  >
                    Save
                  </button>
                </Tooltip>
              </div>
            )}

            {/*   
            <div class="col-md-12 mt-3 text-center">
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={() => {
                  handleValidateForm();
                }}
              >
                Save
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddConveyence;
