import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Pagination, Tabs, Tab } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { getPrTransactions, getPrApprovalData } from "../../service/prService";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { Tooltip } from "react-tippy";
import * as searchPoService from "../../service/searchpo";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

function PRApproval() {
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [data, setData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [currentTabType, setCurrentTabType] = useState("P");
  const [userOptions, setUserOptions] = useState([]);
  const [show, filtersetShow] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('');
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('');
  const [nextSapApprovalPostingTime, setNextSapApprovalPostingTime] = useState('');
  const [nextELNSyncingTime, setNextELNSyncingTime] = useState('');
  const [filterReport, setFilterReport] = useState({
    end_date: "",
    start_date: "",
    source: "",
    user: "",
    sap_pr_no: "",
    prId: "",
  });
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    fromDate,
    toDate,
    requestedBy,
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + currentTabType;
    }
    if (fromDate !== "" && fromDate !== undefined && fromDate !== null) {
      queryParm = queryParm + "&from_date=" + fromDate;
    }
    if (toDate !== "" && toDate !== undefined && toDate !== null) {
      queryParm = queryParm + "&to_date=" + toDate;
    }
    if (
      requestedBy !== "" &&
      requestedBy !== undefined &&
      requestedBy !== null &&
      requestedBy.length !== 0
    ) {
      queryParm = queryParm + "&requested_by=" + requestedBy;
    }
    return queryParm;
  };

  const getPRData = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    status,
    fromDate,
    toDate,
    requestedBy,
  ) => {
    // dispatch(setDisplayLoader("Display"));

    getPrApprovalData(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        status,
        fromDate,
        toDate,
        requestedBy,
      )
    )
      .then((response) => {
        // dispatch(setDisplayLoader("Hide"));

        setData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getPRData(
      "",
      1,
      pageSizeNo,
      "-id",
      true,
      currentTabType,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
    );
    getSapSchedule()
  }, [currentTabType, pageSizeNo]);

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getPRData(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      true,
      currentTabType,
      filterReport.end_date,
      filterReport.start_date,
      filterReport.sap_pr_no,
      filterReport.source,
      filterReport.user,
    );
  };
  const handleSearch = (text) => {
    if (text.length > 2 || text.length === 0) {
      setSearchStr(text)
      getPRData(
        text,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        currentTabType,
        filterReport.end_date,
        filterReport.start_date,
        filterReport.sap_pr_no,
        filterReport.source,
        filterReport.user,
      );
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterReport.end_date;
    const start_date = filterReport.start_date;
    let user = ""
    if (filterReport.user?.length > 0) {
      user = filterReport.user[0]?.value;
    }
    else {
      user = ""
    }
    const source = filterReport.source;
    const sap_pr_no = filterReport.sap_pr_no;
    getPRData(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      currentTabType,
      end_date,
      start_date,
      sap_pr_no,
      source,
      user,
    );
    filterclose();
  };

  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    // resetFilterData();
    setFilterReport({
      ...filterReport,
      end_date: "",
      start_date: "",
      source: "",
      user: "",
      sap_pr_no: "",
    })
    getPRData(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      currentTabType,
      "",
      "",
      "",
      "",
      "",
    );
  };

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_pr_on_sap_data').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_sap_data').then((response) => {
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_get_pr_through_eln_data').then((response) => {
      let next_eln_syncing_time = response?.data?.dataList?.result
      setNextELNSyncingTime(next_eln_syncing_time)
    })
    searchPoService.get_sap_schedule('sync_post_approved_pr_item_in_sap').then((response) => {
      let next_sap_approval_syncing_time = response?.data?.dataList?.result
      setNextSapApprovalPostingTime(next_sap_approval_syncing_time)
    })
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">PR Request Approval</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next ELN Syncing:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextELNSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{ fontSize: "13px" }}>Next SAP Posting of Approved Items:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapApprovalPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs defaultActiveKey="P" id="approvaltabs" onSelect={(e) => setCurrentTabType(e)}>
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      Pending
                      {/* <span class="numberpurchse">11</span> */}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          {/* <th>Action</th> */}
                          <th>PR No.</th>
                          <th>Item No.</th>
                          <th>Source</th>
                          <th>PR Type</th>
                          <th>PUR.GRP.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Material</th>
                          <th>Material Description</th>
                          <th>SAP PR No.</th>
                          <th>Qty.</th>
                          <th>Price/Unit</th>
                          <th>Total Price</th>
                          <th>Creation Date</th>
                          <th>Requestor</th>
                          <th>Pending Days</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ?
                          data?.map((res) => {
                            return (
                              <tr>
                                {/* <td>
                            <button class="btn btn-blanktd text-success">
                              <i class="far fa-check-circle"></i>{" "}
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2">
                              <i class="far fa-times-circle"></i>
                            </button>
                          </td> */}
                                <td class="text-theme">
                                  <Link
                                    to="/view-pr-approval"
                                    state={{
                                      from: res?.prItems_id,
                                      Itemsflag: true,
                                      view_level: "PR",
                                      master_flag: true,
                                      pr_mast_id: res?.pr_id,
                                    }}
                                    class="fw-bold text-theme"
                                  >
                                    PR{res?.pr_id}
                                  </Link>
                                </td>
                                <td>{res?.prItems_id} - {res?.itemno}</td>
                                {res?.pr_source === "M" ?
                                  <td>MYPI</td>
                                  : res?.pr_source === "E" ?
                                    <td>ELN</td>
                                    : res?.pr_source === "S" ?
                                      <td>SAP</td>
                                      :
                                      <td>{res?.pr_source}</td>
                                }
                                <td>{res?.prType}</td>
                                <td>{res?.purchase_group_name} ({res?.purchase_group_code})</td>
                                <td>
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                </td>
                                <td>
                                  <Tooltip title={res?.plant_name} position="bottom">
                                    {res?.plant_name}
                                  </Tooltip>
                                </td>
                                <td>{res?.material_code}</td>
                                <td>
                                  <Tooltip title={res?.material_description} position="bottom">
                                    {res?.material_description}
                                  </Tooltip>
                                </td>
                                <td>{res?.pr_num}</td>
                                <td>{res?.prQuantity}</td>
                                <td>{Number(res?.pr_valuation).toFixed(2)}</td>
                                <td>{res?.prQuantity * res?.pr_valuation}</td>
                                <td>{moment.utc(res?.pr_created_at).format("DD-MM-YYYY")}</td>
                                <td>
                                  <Tooltip title={res?.created_by_user} position="bottom">
                                    {res?.created_by_user}
                                  </Tooltip>
                                </td>
                                <td>
                                  {Math.round(
                                    (new Date() -
                                      new Date(res?.pr_created_at)) /
                                    (1000 * 60 * 60 * 24)
                                  )}
                                  Day
                                </td>
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={12}>No Pending Records...</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onClick={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1)
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="A"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>PR No.</th>
                          <th>Item No.</th>
                          <th>Source</th>
                          <th>PR Type</th>
                          <th>PUR.GRP.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Material</th>
                          <th>Material Description</th>
                          <th>SAP PR No.</th>
                          <th>Qty.</th>
                          <th>Price/Unit</th>
                          <th>Total Price</th>
                          <th>Creation Date</th>
                          <th>Approved Date</th>
                          <th>Requestor</th>
                          {/* <th>Pending Days</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ?
                          data?.map((res) => {
                            return (
                              <tr>
                                <td class="text-theme">
                                  <Link
                                    to="/view-pr-approval"
                                    state={{
                                      from: res?.prItems_id,
                                      Itemsflag: true,
                                      view_level: "PR",
                                      master_flag: true,
                                      pr_mast_id: res?.pr_id,
                                    }}
                                    class="fw-bold text-theme"
                                  >
                                    PR{res?.pr_id}
                                  </Link>
                                </td>
                                <td>{res?.prItems_id} - {res?.itemno}</td>
                                {res?.pr_source === "M" ?
                                  <td>MYPI</td>
                                  : res?.pr_source === "E" ?
                                    <td>ELN</td>
                                    : res?.pr_source === "S" ?
                                      <td>SAP</td>
                                      :
                                      <td>{res?.pr_source}</td>
                                }
                                <td>{res?.prType}</td>
                                <td>{res?.purchase_group_name} ({res?.purchase_group_code})</td>
                                <td>
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                </td>
                                <td>
                                  <Tooltip title={res?.plant_name} position="bottom">
                                    {res?.plant_name}
                                  </Tooltip>
                                </td>
                                <td>{res?.material_code}</td>
                                <td>
                                  <Tooltip title={res?.material_description} position="bottom">
                                    {res?.material_description}
                                  </Tooltip>
                                </td>
                                <td>{res?.pr_num}</td>
                                <td>{res?.prQuantity}</td>
                                <td>{Number(res?.pr_valuation).toFixed(2)}</td>
                                <td>{res?.prQuantity * res?.pr_valuation}</td>
                                <td>{moment.utc(res?.pr_created_at).format("DD-MM-YYYY")}</td>
                                <td>{moment.utc(res?.updated_at).format("DD-MM-YYYY")}</td>
                                <td>
                                  <Tooltip title={res?.created_by_user} position="bottom">
                                    {res?.created_by_user}
                                  </Tooltip>
                                </td>
                                {/* <td>
                          {Math.round(
                            (new Date(res?.updated_at) -
                              new Date(res?.pr_created_at)) /
                              (1000 * 60 * 60 * 24)
                          )}
                          Day
                          </td> */}
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={12}>No Approved Records...</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onClick={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1)
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="D"
                  title={<React.Fragment>Rejected</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                      <thead>
                        <tr>
                          <th>PR No.</th>
                          <th>Item No.</th>
                          <th>Source</th>
                          <th>PR Type</th>
                          <th>PUR.GRP.</th>
                          <th>Status</th>
                          <th>Plant</th>
                          <th>Material</th>
                          <th>Material Description</th>
                          <th>SAP PR No.</th>
                          <th>Qty.</th>
                          <th>Price/Unit</th>
                          <th>Total Price</th>
                          <th>Creation Date</th>
                          <th>Rejected Date</th>
                          <th>Requestor</th>
                          {/* <th>Pending Days</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ?
                          data?.map((res) => {
                            return (
                              <tr>
                                <td class="text-theme">
                                  <Link
                                    to="/view-pr-approval"
                                    state={{
                                      from: res?.prItems_id,
                                      Itemsflag: true,
                                      view_level: "PR",
                                      master_flag: true,
                                      pr_mast_id: res?.pr_id,
                                    }}
                                    class="fw-bold text-theme"
                                  >
                                    PR{res?.pr_id}
                                  </Link>
                                </td>
                                <td>{res?.prItems_id} - {res?.itemno}</td>
                                {res?.pr_source === "M" ?
                                  <td>MYPI</td>
                                  : res?.pr_source === "E" ?
                                    <td>ELN</td>
                                    : res?.pr_source === "S" ?
                                      <td>SAP</td>
                                      :
                                      <td>{res?.pr_source}</td>
                                }
                                <td>{res?.prType}</td>
                                <td>{res?.purchase_group_name} ({res?.purchase_group_code})</td>
                                <td>
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                </td>
                                <td>
                                  <Tooltip title={res?.plant_name} position="bottom">
                                    {res?.plant_name}
                                  </Tooltip>
                                </td>
                                <td>{res?.material_code}</td>
                                <td>
                                  <Tooltip title={res?.material_description} position="bottom">
                                    {res?.material_description}
                                  </Tooltip>
                                </td>
                                <td>{res?.pr_num}</td>
                                <td>{res?.prQuantity}</td>
                                <td>{Number(res?.pr_valuation).toFixed(2)}</td>
                                <td>{res?.prQuantity * res?.pr_valuation}</td>
                                <td>{moment.utc(res?.pr_created_at).format("DD-MM-YYYY")}</td>
                                <td>{moment.utc(res?.updated_at).format("DD-MM-YYYY")}</td>
                                <td>
                                  <Tooltip title={res?.created_by_user} position="bottom">
                                    {res?.created_by_user}
                                  </Tooltip>
                                </td>
                                {/* <td>
                          {Math.round(
                            (new Date(res?.updated_at) -
                              new Date(res?.pr_created_at)) /
                              (1000 * 60 * 60 * 24)
                          )}
                          Day
                          </td> */}
                              </tr>
                            )
                          })
                          :
                          <tr>
                            <td colSpan={12}>No Rejected Records...</td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control"
                          onClick={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1)
                          }}
                        >
                          <option value={10} label={10}>10</option>
                          <option value={20} label={20}>20</option>
                          <option value={30} label={30}>30</option>
                          <option value={100} label={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              PR ID<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter PR ID"
              value={filterReport.prId}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  prId: e.target.value,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>
              SAPPR NO<span class="text-danger"></span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter SAP PR NO"
              value={filterReport.sap_pr_no}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  sap_pr_no: e.target.value,
                })
              }
            />
          </div>

          <div class="form-group innergroup">
            <label>
              User<span class="text-danger"></span>
            </label>
            <Select
              isMulti
              options={userOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterReport.user}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  user: e,
                })
              }
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Source<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              value={filterReport.source}
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  source: e.target.value,
                })
              }
            >
              <option>Select</option>
              <option value={"M"}>MyPI</option>
              <option value={"E"}>ELN</option>
              <option value={"S"}>SAP</option>
            </select>
          </div>
          <div class="form-group innergroup">
            <label>Start Date</label>
            <input
              type="date"
              class="form-control"
              name="start_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  start_date: e.target.value,
                })
              }
              value={filterReport.start_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>End Date</label>
            <input
              type="date"
              class="form-control"
              name="end_date"
              onChange={(e) =>
                setFilterReport({
                  ...filterReport,
                  end_date: e.target.value,
                })
              }
              value={filterReport.end_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn-outline-danger" onClick={() => {
            resetFilter();
          }}>
            Cancel
          </button>
          <button type="button" class="btn btn-primary-inner bpi-main" onClick={() => mainDataFilter()}>Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PRApproval;
