import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";


export const getOrganisation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?${queryParm}`);
};

export const getAllOrganisation = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${queryParm}`);
};

export const getPolicy = async () => {
  return axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_policy_master?`);
};

export const submitinsurancematrix = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/mediclaim/mediclaim_insurance_broker`, data);
};

export const uploadfile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getinsurancedetails = async (paginate) => {
  // console.log("Paginate",paginate)
  return axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_insurance_broker?${paginate}`)
}

export const deletemediclaim = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/mediclaim/mediclaim_insurance_broker/${id}`);
};

export const submiteditinsurancematrix = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/mediclaim/mediclaim_insurance_broker/${id}`, data);
};


export const mediclaiminsurenceexportfile = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/mediclaim/mediclaim_insurance_broker_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
}

export const submitEssPolicy = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/mediclaim/ess-policy`, data)
}

export const removeEssPolicy = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/mediclaim/ess-policy/${id}`)
}

export const updateEssPolicy = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/mediclaim/ess-policy/${id}`, data)
}

export const getEssPolicy = async (query) => {
  return axios.get(`${AUTH_BASE_URL}/mediclaim/ess-policy?${query}`)
}