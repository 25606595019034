import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Sort from "../assets/images/sort.png";
// import Sidemenu from "./include/sidemenu";
// import Header from "./include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { useEffect } from "react";
import * as ShiftDataService from "../../service/changeShift";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";

function CompAll() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const [pageCount, setPageCount] = useState(1);
  const [currentTabType, setCurrentTabType] = useState("pending");
  const [postingData, setPostingData] = useState([]);
  const [dept, setDept] = useState([]);
  const [PSA, setPSA] = useState([]);
  const [allEmp, setAllEmp] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [shiftDataFilter, setShiftDataFilter] = useState({
    user_id: "",
    department_id: "",
    personal_sub_area: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [compAllowData, setCompAllowData] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [show, filtersetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [empFilter, setEmpFilter] = useState([]);
  const [deptFilter, setDeptFilter] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    setFilterApplied(false);
    filtersetShow(false);
    setShiftDataFilter({
      ...shiftDataFilter,
      personal_sub_area: "",
      user_id: "",
      department_id: "",
    });
    setEmpFilter([]);
    setDeptFilter([]);
    getCompensatoryAllowanceData(
      "",
      "",
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTabType
    );
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getCompensatoryAllowanceData(
      searchStr,
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTabType
    );
    getFilterData();
  }, [pageSizeNo, currentTabType]);

  const getFilterData = () => {
    ShiftDataService.getFilterData().then((response) => {
      let emp_obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.id,
          label: res?.fullname + "(" + res?.username + ")",
        };
      });
      setAllEmp(emp_obj);
      let dept_obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.department_id,
          label: res?.department_name,
        };
      });
      setDept(dept_obj);
      let unique = [];
      let psa_obj = response?.data?.dataList?.result
        ?.filter((obj) => {
          if (!unique.includes(obj?.personal_sub_area_id)) {
            unique.push(obj?.personal_sub_area_id);
            return true;
          }
        })
        .map((res) => {
          return {
            value: res?.psa_name,
            label: res?.psa_name + "-" + res?.psa_desc,
          };
        });
      setPSA(psa_obj);
    });
  };

  const handleKey = (
    search,
    user_id,
    department_id,
    personal_sub_area,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (
      department_id !== "" &&
      department_id !== undefined &&
      department_id !== null
    ) {
      queryParm = queryParm + "&department_id=" + department_id;
    }
    if (
      personal_sub_area !== "" &&
      personal_sub_area !== undefined &&
      personal_sub_area !== null
    ) {
      queryParm = queryParm + "&personal_sub_area=" + personal_sub_area;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  const getCompensatoryAllowanceData = (
    search,
    user_id,
    department_id,
    personal_sub_area,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    ShiftDataService.getCompAllowance(
      handleKey(
        search,
        user_id,
        department_id,
        personal_sub_area,
        page_no,
        page_size,
        sort_by,
        paginate,
        status
      )
    ).then((response) => {
      dispatch(setDisplayLoader("Hide"));
      setCompAllowData(response?.data?.dataList?.result);
      setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      setTotalCount(response?.data?.dataList?.pending_count);
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getCompensatoryAllowanceData(
      searchStr,
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTabType
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getCompensatoryAllowanceData(
        e.target.value.toLowerCase(),
        shiftDataFilter.user_id,
        shiftDataFilter.department_id,
        shiftDataFilter.personal_sub_area,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        currentTabType
      );
    }
    setSearchStr(e.target.value);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getCompensatoryAllowanceData(
        data,
        shiftDataFilter.user_id,
        shiftDataFilter.department_id,
        shiftDataFilter.personal_sub_area,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        currentTabType
      );
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    getCompensatoryAllowanceData(
      searchStr,
      shiftDataFilter.user_id,
      shiftDataFilter.department_id,
      shiftDataFilter.personal_sub_area,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      currentTabType
    );
    filtersetShow(false);
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(compAllowData));
    if (e.target.checked === true) {
      postingData.push(data);
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "Y";
        }
      });
      setCompAllowData(copyData1);
    } else {
      copyData1?.map((res) => {
        if (res?.id === data?.id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.filter((res) => {
        if (res?.id != data?.id) {
          return true;
        }
      });
      setPostingData(obj);
      setCompAllowData(copyData1);
    }
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(compAllowData));
    if (e.target.checked === true) {
      data?.map((res) => {
        if (res.sap_status == "pending") res.checked = "Y";
        postingData.push(res);
      });
      setCompAllowData(data);
    } else {
      data?.map((res) => {
        res.checked = "N";
      });
      setCompAllowData(data);
      let obj = postingData?.map((x) => {
        if (x?.id != data?.id) {
          return x;
        }
      });
      setPostingData(obj);
    }
  };

  const postAllData = () => {
    let temp = postingData.filter((res) => {
      if (res !== undefined) {
        return true;
      }
    });
    if (temp.length > 0 && !temp.includes(undefined)) {
      let obj = { data: temp };
      ShiftDataService.updatepostData(obj).then((res) => {
        toast.success("Data Posted Successfully");
        getCompensatoryAllowanceData(
          searchStr,
          shiftDataFilter.user_id,
          shiftDataFilter.department_id,
          shiftDataFilter.personal_sub_area,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true,
          currentTabType
        );
      });
    } else {
      toast.error("Please Select Records to Post");
    }
  };

  const exportData = (
    search,
    user_id,
    department_id,
    personal_sub_area,
    page_no,
    page_size,
    sort_by,
    paginate,
    status
  ) => {
    ShiftDataService.getCompOffExport(
      handleKey(
        search,
        user_id,
        department_id,
        personal_sub_area,
        page_no,
        page_size,
        sort_by,
        paginate,
        status
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Comp-Off Allowance.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3">
                        <ul class="policylistnames">
                            <li><Link to="/comp-off">Compensatory Allowance Master</Link></li> 
                            <li class="active"><Link to="/comp-allowance">Compensatory Allowance</Link></li>
                        </ul>
                    </div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Compensatory Allowance</h4>
                    {/* <Breadcrumb>
                                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/">
                                                Me@Pi
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>Compensatory Allowance</Breadcrumb.Item>
                                        </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top  mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on{" "}
                            <strong>
                              {" "}
                              Employee ID,
                              <br />
                              Employee Name,
                            </strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search Employee ID, Employee Name..."
                          onChange={(e) => handleSearch(e)}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img
                            src="images/export.png"
                            alt=""
                            className="mr-2"
                          />
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                exportData(
                                  searchStr,
                                  shiftDataFilter.user_id,
                                  shiftDataFilter.department_id,
                                  shiftDataFilter.personal_sub_area,
                                  currentPage,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true,
                                  currentTabType
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        class="btn btn-secondary-inner"
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <Tabs
                  defaultActiveKey="pending"
                  id="approvaltabs"
                  onSelect={(e) => setCurrentTabType(e)}
                >
                  <Tab
                    eventKey="pending"
                    title={
                      <React.Fragment>
                        Pending
                        <span class="numberpurchse">{totalCount}</span>
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    handleAllData(e);
                                  }}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </th>
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>Compensatory Date</th>
                            <th>Status</th>
                            <th>PSA</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Picked Date</th>
                            <th>Synced Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {compAllowData?.length > 0 &&
                            compAllowData?.map((data) => {
                              return (
                                <tr>
                                  <td>
                                    <label class="logCheck d-inline-block">
                                      <input
                                        type="checkbox"
                                        name="permission"
                                        onClick={(e) => {
                                          selectDataToPost(e, data);
                                        }}
                                        checked={data?.checked === "Y"}
                                        disabled={
                                          data?.sap_status === "Pending to Sync"
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </td>
                                  <td class="">{data?.emp_code}</td>
                                  <td>{data?.emp_fullname}</td>
                                  <td>
                                    {data?.attendance_data?.map(
                                      (res, index) => {
                                        return (
                                          <>
                                            <Tooltip
                                              title={res?.attendance_date}
                                              position="bottom"
                                            >
                                              {moment(
                                                res?.attendance_date
                                              ).format("DD-MM-YYYY")}{" "}
                                              {index < 2 && " | "}
                                            </Tooltip>
                                          </>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {data?.attendance_data?.map(
                                      (res, index) => {
                                        return (
                                          <>
                                            {res?.status} {index < 2 && " | "}
                                          </>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td>{data?.psa}</td>
                                  <td>{data?.emp_designation}</td>
                                  <td>{data?.department}</td>
                                  <td>
                                    {moment
                                      .utc(data?.created_at)
                                      .format("DD-MM-YYYY HH:mm")}{" "}
                                    hrs
                                  </td>
                                  {data?.sync_date !== null ? (
                                    <td>
                                      {moment
                                        .utc(data?.sync_date)
                                        .format("DD-MM-YYYY HH:mm")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={500} label={500}>
                              500
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                    <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          <button
                            class="btn btn-success"
                            type="button"
                            onClick={postAllData}
                          >
                            <i class="far fa-check-circle"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Pending to Sync"
                    title={
                      <React.Fragment>
                        Posted
                        {/* <span class="numberpurchse">{totalCount}</span> */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            {/* <th>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  onClick={(e) => {
                                    handleAllData(e);
                                  }}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </th> */}
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>Compensatory Date</th>
                            <th>Status</th>
                            <th>PSA</th>
                            <th>Designation</th>
                            <th>Department</th>
                            <th>Picked Date</th>
                            <th>SAP Status</th>
                            <th>Synced Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {compAllowData?.length > 0 &&
                            compAllowData?.map((data) => {
                              return (
                                <tr>
                                  {/* <td>
                                    <label class="logCheck d-inline-block">
                                      <input
                                        type="checkbox"
                                        name="permission"
                                        onClick={(e) => {
                                          selectDataToPost(e, data);
                                        }}
                                        checked={data?.checked === "Y"}
                                        disabled={
                                          data?.sap_status === "Pending to Sync"
                                        }
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </td> */}
                                  <td class="">{data?.emp_code}</td>
                                  <td>{data?.emp_fullname}</td>
                                  <td>
                                    {data?.attendance_data?.map(
                                      (res, index) => {
                                        return (
                                          <>
                                            <Tooltip
                                              title={res?.attendance_date}
                                              position="bottom"
                                            >
                                              {moment(
                                                res?.attendance_date
                                              ).format("DD-MM-YYYY")}{" "}
                                              {index < 2 && " | "}
                                            </Tooltip>
                                          </>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {data?.attendance_data?.map(
                                      (res, index) => {
                                        return (
                                          <>
                                            {res?.status} {index < 2 && " | "}
                                          </>
                                        );
                                      }
                                    )}
                                  </td>
                                  <td>{data?.psa}</td>
                                  <td>{data?.emp_designation}</td>
                                  <td>{data?.department}</td>
                                  <td>
                                    {moment
                                      .utc(data?.created_at)
                                      .format("DD-MM-YYYY HH:mm")}{" "}
                                    hrs
                                  </td>
                                  <td>{data?.sap_status}</td>
                                  {data?.sync_date !== null ? (
                                    <td>
                                      {moment
                                        .utc(data?.sync_date)
                                        .format("DD-MM-YYYY HH:mm")}
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={500} label={500}>
                              500
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          disableInitialCallback={true}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>

                    <div class="row jvactionsbutton">
                      <div class="col-md-12">
                        <div class="p-3">
                          <button
                            class="btn btn-success"
                            type="button"
                            onClick={postAllData}
                          >
                            <i class="far fa-check-circle"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Employee Name<span class="text-danger"></span>
            </label>
            <Select
              options={allEmp}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  user_id: e.value,
                });
                setCurrentPage(1);
                setEmpFilter(e);
              }}
              value={empFilter}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Department<span class="text-danger"></span>
            </label>
            <Select
              options={dept}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  department_id: e.value,
                });
                setCurrentPage(1);
                setDeptFilter(e);
              }}
              value={deptFilter}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Personal Sub-Area<span class="text-danger"></span>
            </label>
            <select
              class="form-control newbgselect"
              onChange={(e) => {
                setShiftDataFilter({
                  ...shiftDataFilter,
                  personal_sub_area: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={shiftDataFilter?.personal_sub_area}
            >
              <option>Select</option>
              {PSA?.length > 0 &&
                PSA?.map((obj) => {
                  return (
                    <option value={obj?.value} label={obj?.label}></option>
                  );
                })}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="button"
            onClick={mainDataFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompAll;
