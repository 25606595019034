import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as ShiftService from "../../service/ShiftMaster";
import { toast, ToastContainer } from "react-toastify";
import { Formik } from "formik";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import ShiftMaster from "./ShiftMaster";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getUsersListDataNew } from "../../service/userService";
import * as EmpService from "../../service/employee";
import * as UserService from "../../service/userService";

const ShiftApproval = ({ bhr }) => {
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [shiftApproveList, setShiftApproveList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [declinedList, setDeclinedList] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [currentTab, setCurrentTab] = useState("P");
  const [dropDept, setDropDept] = useState([]);
  const [filterUser, setFilterUser] = useState([]);
  const [subPersonalArea, setSubPersonalAreaData] = useState([]);
  const [psaVal, setPsaVal] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [pendingDataApproval, setPendingDataApproval] = useState({
    data: [],
  });
  const [shiftApproveDataForExport, setShiftApproveDataForExport] = useState({
    user_id: "",
    from_date: "",
    to_date: "",
    status: "",
    psa_id: [],
    business_units_id: "",
  });
  const [shiftApproveDataFilter, setShiftApproveDataFilter] = useState({
    user_id: "",
    from_date: "",
    to_date: "",
    status: "",
    psa_id: [],
    business_units_id: "",
  });
  const getShiftApprovalList = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    report_flag,
    psa_id,
    business_units_id
  ) => {
    ShiftService.getShiftApprovalList(
      handleKey(
        search,
        user_id,
        from_date,
        to_date,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        report_flag,
        psa_id,
        business_units_id
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        let pendingData = response?.data?.dataList.pending.map((pd, i) => {
          pd.status = "N";
          return pd;
        });
        setShiftApproveList({
          pending: pendingData,
        });
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setApprovedList(response?.data?.dataList?.approved);
        setDeclinedList(response?.data?.dataList?.rejected);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    report_flag,
    psa_id,
    business_units_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      report_flag !== "" &&
      report_flag !== undefined &&
      report_flag !== null
    ) {
      queryParm = queryParm + "&report_flag=" + report_flag;
    }
    if (bhr !== undefined) {
      queryParm = queryParm + "&bhr=" + bhr;
    }
    if (psa_id !== "" && psa_id !== undefined && psa_id !== null && psa_id.length !== 0) {
      queryParm = queryParm + "&psa_id=" + psa_id;
    }
    if (business_units_id !== "" && business_units_id !== undefined && business_units_id !== null) {
      queryParm = queryParm + "&business_units_id=" + business_units_id;
    }
    return queryParm;
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (
      pathname === "/shift-approval-report" ||
      pathname === "/shift-bhr-report"
    ) {
      getShiftApprovalList(
        searchStr,
        shiftApproveDataFilter.user_id,
        shiftApproveDataFilter.from_date,
        shiftApproveDataFilter.to_date,
        shiftApproveDataFilter.status,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    } else {
      getShiftApprovalList(
        searchStr,
        shiftApproveDataFilter.user_id,
        shiftApproveDataFilter.from_date,
        shiftApproveDataFilter.to_date,
        shiftApproveDataFilter.status,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true,
        false
      );
    }
  };
  const handleBulkData = (e, pid, stat) => {
    let pdata = {
      id: pid,
    };
    let findData = pendingDataApproval?.data.find((obj) => {
      if (obj.id === pid) {
        return true;
      }
    });
    if (findData === undefined) {
      setPendingDataApproval((prev) => ({
        ...prev,
        data: [...prev.data, pdata],
      }));
    } else {
      let curData = pendingDataApproval?.data
        .filter((obj) => {
          if (obj?.id !== pid) {
            return true;
          } else {
            return false;
          }
        })
        .map((obj) => obj);
      setPendingDataApproval((prev) => ({
        ...prev,
        data: curData,
      }));
    }
    let finalData = shiftApproveList;
    finalData.pending.map((fd, i) => {
      if (fd.id == pid) {
        fd.status = fd.status === "Y" ? "N" : "Y";
      }
    });
    setShiftApproveList(finalData);
  };
  const handleAllData = (e) => {
    if (e.target.checked === true) {
      let data = [];
      let finalData = shiftApproveList.pending;
      finalData.map((fd, i) => {
        fd.status = fd.status === "Y" ? "N" : "Y";
        data.push({
          id: fd.id,
        });
      });
      setPendingDataApproval((prev) => ({
        ...prev,
        data: data,
      }));
      setShiftApproveList((prev) => ({
        ...prev,
        pending: finalData,
      }));
    } else {
      let finalData = shiftApproveList.pending;
      finalData.map((fd, i) => {
        fd.status = fd.status === "Y" ? "N" : "Y";
      });
      setShiftApproveList((prev) => ({
        ...prev,
        pending: finalData,
      }));
      setPendingDataApproval((prev) => ({
        ...prev,
        data: [],
      }));
    }
  };
  const handlePendingData = (e) => {
    let payload = pendingDataApproval?.data.map((x) => {
      x.status = "A";
      return x;
    });
    console.log(payload);
    saveMultiApprove(payload);
  };

  const handleRejectedData = (e) => {
    let payload = pendingDataApproval?.data.map((x) => {
      x.status = "D";
      return x;
    });
    // console.log(payload);
    saveMultiApprove(payload);
  };
  //  onClick={(e) => handlePendingData(e)}
  // const saveMultiApprove = (data) => {
  //   if (data !== null) {
  //     ShiftMaster.saveShiftApproval.then((response) => {
  //       getShiftApprovalList(1, 10);
  //     });
  //   }
  // };
  const saveMultiApprove = (values) => {
    let datas = { data: values };
    ShiftService.saveShiftApproval(datas)
      .then((response) => {
        if (response.data.code == 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getExportValueAllocation = (
    search,
    user_id,
    from_date,
    to_date,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    report_flag
  ) => {
    ShiftService.getExportValueAllocation(
      handleKey(
        search,
        user_id,
        from_date,
        to_date,
        status,
        page_no,
        page_size,
        sort_by,
        paginate,
        report_flag
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ShiftListData.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getAllUser = (paginate) => {
    if (pathname === "/shift-approval-report") {
      getUsersListDataNew(0).then((response) => {
        if (response?.status === 200) {
          let obj = response?.data?.dataList?.result
            ?.filter((each) => {
              if (each?.username !== "admin" && each?.username !== "super_pi") {
                return true;
              }
            })
            .map((res) => {
              return {
                value: res?.id,
                label:
                  res?.first_name + res?.last_name + "(" + res?.username + ")",
              };
            });
          setDropDept(obj);
        }
      });
    } else {
      let flag = bhr === undefined ? false : true;
      ShiftService.getAllUser(paginate, flag)
        .then((response) => {
          let obj = response?.data?.dataList?.result?.map((res) => {
            return {
              value: res?.id,
              label:
                res?.first_name + res?.last_name + "(" + res?.username + ")",
            };
          });
          setDropDept(obj);
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };
  const saveShiftApproval = (e) => {
    ShiftService.saveShiftApproval(e)
      .then((response) => {
        if (response.data.code == 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      if (
        pathname === "/shift-approval-report" ||
        pathname === "/shift-bhr-report"
      ) {
        getShiftApprovalList(
          e.target.value.toLowerCase(),
          "",
          "",
          "",
          currentTab,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          true
        );
      } else {
        getShiftApprovalList(
          e.target.value.toLowerCase(),
          "",
          "",
          "",
          currentTab,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          false
        );
      }
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (
        pathname === "/shift-approval-report" ||
        pathname === "/shift-bhr-report"
      ) {
        getShiftApprovalList(
          data,
          shiftApproveDataFilter.user_id,
          shiftApproveDataFilter.from_date,
          shiftApproveDataFilter.to_date,
          currentTab,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          true
        );
      } else {
        getShiftApprovalList(
          data,
          shiftApproveDataFilter.user_id,
          shiftApproveDataFilter.from_date,
          shiftApproveDataFilter.to_date,
          currentTab,
          currentPage,
          pageSizeNo,
          "-id",
          true,
          false
        );
      }
    }
  };
  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    setShiftApproveDataForExport({
      ...shiftApproveDataForExport,
      user_id: shiftApproveDataFilter.user_id,
      from_date: shiftApproveDataFilter.from_date,
      to_date: shiftApproveDataFilter.to_date,
      status: shiftApproveDataFilter.status,
      psa_id: shiftApproveDataFilter?.psa_id,
      business_units_id: shiftApproveDataFilter?.business_units_id
    });
    const status = shiftApproveDataFilter.status;
    const user_id = shiftApproveDataFilter.user_id;
    const from_date = shiftApproveDataFilter.from_date;
    const to_date = shiftApproveDataFilter.to_date;
    const psa_id = shiftApproveDataFilter?.psa_id;
    const business_units_id = shiftApproveDataFilter?.business_units_id;

    if (
      pathname === "/shift-approval-report" ||
      pathname === "/shift-bhr-report"
    ) {
      getShiftApprovalList(
        searchStr,
        user_id,
        from_date,
        to_date,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true,
        psa_id,
        business_units_id
      );
    } else {
      getShiftApprovalList(
        searchStr,
        user_id,
        from_date,
        to_date,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        false,
        psa_id,
        business_units_id
      );
    }
    filterClose();
  };
  const renderDeptOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.first_name}`}
      />
    ));
  };
  const filterShow = () => {
    filteropen();
    getAllUser(false);
    LocationFilter()
    getBranchFilter()
  };
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    if (
      pathname === "/shift-approval-report" ||
      pathname === "/shift-bhr-report"
    ) {
      getShiftApprovalList(
        searchStr,
        "",
        "",
        "",
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    } else {
      getShiftApprovalList(
        searchStr,
        "",
        "",
        "",
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        false
      );
    }
  }, [currentTab]);

  const clearFilter = () => {
    setFilterApplied(false);
    shiftApproveDataFilter.from_date = ''
    shiftApproveDataFilter.to_date = ''
    shiftApproveDataFilter.user_id = ''
    shiftApproveDataFilter.psa_id = []
    shiftApproveDataFilter.business_units_id = ''
    setFilterUser([])
    setPsaVal([])
    if (
      pathname === "/shift-approval-report" ||
      pathname === "/shift-bhr-report"
    ) {
      getShiftApprovalList(
        searchStr,
        "",
        "",
        "",
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        true
      );
    } else {
      getShiftApprovalList(
        searchStr,
        "",
        "",
        "",
        currentTab,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        false
      );
    }
    filterClose()
  }

  const LocationFilter = () => {
    EmpService.LocationFilter()
      .then((response) => {
        let locationData = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.id,
            label: res.description + "(" + res?.name + ")",
          };
        });
        setSubPersonalAreaData(locationData);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const renderBranchOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name} (${item?.code})`}
      />
    ));
  };

  const getBranchFilter = () => {
    UserService.getBranchFilter()
      .then((response) => {
        setBranchData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">
                    {bhr !== undefined ? "BHR Shift Report" : "Shift Approval"}
                  </h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Shift Approval</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mt-2 mt-md-4 mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            User Name,
                            <br />
                            Request Date,
                            <br />
                            Reason
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        id="searchbar"
                        class="form-control"
                        placeholder="Search on User Name, Request Date..."
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            disabled={
                              shiftApproveList.length !== 0 ? false : true
                            }
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportValueAllocation(
                                searchStr,
                                shiftApproveDataForExport.user_id,
                                shiftApproveDataForExport.from_date,
                                shiftApproveDataForExport.to_date,
                                currentTab,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                pathname === "/shift-approval-report"
                                  ? true
                                  : false
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={() => {
                        filterShow();
                      }}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey="P"
                id="approvaltabs"
                onSelect={(e) => setCurrentTab(e)}
              >
                <Tab
                  eventKey="P"
                  title={
                    <React.Fragment>
                      Pending
                      <span class="numberpurchse">
                        {shiftApproveList?.pending?.length}
                      </span>
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          {/* <th>
                            <label class="logCheck d-inline-block">
                              <input
                                type="checkbox"
                                name="permission"
                                onClick={(e) => handleAllData(e)}
                              />
                              <span class="checkmark"></span>
                            </label>
                          </th> */}
                          <th>User Name</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Location</th>
                          <th>Shift Date</th>
                          <th>Assigned Shift</th>
                          <th>Assigned Shift Timing</th>
                          <th>Requested Shift</th>
                          <th>Requested Shift Timing</th>
                          <th>Reason</th>
                          <th>Apply Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shiftApproveList?.pending?.length ? (
                          shiftApproveList?.pending?.map(
                            (forApproval, index) => (
                              <tr>
                                {/* <td>
                                  <label class="logCheck d-inline-block">
                                    <input
                                      type="checkbox"
                                      name="permission"
                                      checked={forApproval?.status == "Y"}
                                      onClick={(e) =>
                                        handleBulkData(e, forApproval?.id, "P")
                                      }
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </td> */}
                                <td>
                                  <Tooltip title={forApproval?.user_name} position="bottom">
                                    {forApproval?.user_name}
                                  </Tooltip>
                                </td>
                                <td>{forApproval?.user_designation}</td>
                                <td>{forApproval?.user_department}</td>
                                <td>{forApproval?.user_location}</td>
                                <td>
                                  {" "}
                                  {moment(forApproval?.shift_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{forApproval?.prev_shift_name}</td>
                                <td>
                                  {
                                    forApproval?.assign_shift_time_in.split(
                                      ":"
                                    )[0]
                                  }
                                  :
                                  {
                                    forApproval?.assign_shift_time_in.split(
                                      ":"
                                    )[1]
                                  }{" "}
                                  {forApproval?.assign_shift_time_in.split(
                                    ":"
                                  )[0] <= 11 ||
                                    (forApproval?.assign_shift_time_in.split(
                                      ":"
                                    )[0] == 12 &&
                                      forApproval?.assign_shift_time_in.split(
                                        ":"
                                      )[1] == 0)
                                    ? "hrs"
                                    : "hrs"}{" "}
                                  to
                                  {
                                    forApproval?.assign_shift_time_out.split(
                                      ":"
                                    )[0]
                                  }
                                  :
                                  {
                                    forApproval?.assign_shift_time_out.split(
                                      ":"
                                    )[1]
                                  }{" "}
                                  {forApproval?.assign_shift_time_out.split(
                                    ":"
                                  )[0] <= 11 ||
                                    (forApproval?.assign_shift_time_out.split(
                                      ":"
                                    )[0] == 12 &&
                                      forApproval?.assign_shift_time_out.split(
                                        ":"
                                      )[1] == 0)
                                    ? "hrs"
                                    : "hrs"}{" "}
                                </td>
                                <td>{forApproval?.shift_name}</td>
                                <td>
                                  {" "}
                                  {forApproval?.shift_time_in.split(":")[0]}:
                                  {forApproval?.shift_time_in.split(":")[1]}{" "}
                                  {forApproval?.shift_time_in.split(":")[0] <=
                                    11 ||
                                    (forApproval?.shift_time_in.split(":")[0] ==
                                      12 &&
                                      forApproval?.shift_time_in.split(":")[1] ==
                                      0)
                                    ? "hrs"
                                    : "hrs"}{" "}
                                  to
                                  {forApproval?.shift_time_out.split(":")[0]}:
                                  {forApproval?.shift_time_out.split(":")[1]}{" "}
                                  {forApproval?.shift_time_out.split(":")[0] <=
                                    11 ||
                                    (forApproval?.shift_time_out.split(":")[0] ==
                                      12 &&
                                      forApproval?.shift_time_out.split(":")[1] ==
                                      0)
                                    ? "hrs"
                                    : "hrs"}{" "}
                                </td>
                                <td>{forApproval?.reasons}</td>
                                <td>{moment(forApproval?.created_at).format("DD-MM-YYYY")}</td>
                                <td>
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>

                  <div class="row jvactionsbutton">
                    <div class="col-md-12">
                      {/* <div class="p-3">
                        <button
                          class="btn btn-danger mr-2"
                          onClick={(e) => handleRejectedData(e)}
                        >
                          <i class="far fa-times-circle"></i> Reject
                        </button>
                        <button
                          class="btn btn-success"
                          onClick={(e) => handlePendingData(e)}
                        >
                          <i class="far fa-check-circle"></i> Approved
                        </button>
                      </div> */}
                    </div>
                  </div>
                </Tab>

                <Tab
                  eventKey="A"
                  title={<React.Fragment>Approved</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Location</th>
                          <th>Shift Date</th>
                          <th>Assigned Shift</th>
                          <th>Assigned Shift Timing</th>
                          <th>Requested Shift</th>
                          <th>Requested Shift Timing</th>
                          <th>Reason</th>
                          <th>Apply Date</th>
                          <th>Approved Date</th>
                          <th>Status</th>
                          <th>Savior Response</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvedList?.length ? (
                          approvedList?.map((approved, index) => (
                            <tr>
                              <td>
                                <Tooltip title={approved?.user_name} position="bottom">
                                  {approved?.user_name}
                                </Tooltip>
                              </td>
                              <td>{approved?.user_designation}</td>
                              <td>{approved?.user_department}</td>
                              <td>{approved?.user_location}</td>
                              <td>
                                {" "}
                                {moment(approved?.shift_date).format(
                                  " DD-MM-YY  "
                                )}
                              </td>
                              <td>{approved?.prev_shift_name}</td>
                              <td>
                                {
                                  approved?.assign_shift_time_in.split(
                                    ":"
                                  )[0]
                                }
                                :
                                {
                                  approved?.assign_shift_time_in.split(
                                    ":"
                                  )[1]
                                }{" "}
                                {approved?.assign_shift_time_in.split(
                                  ":"
                                )[0] <= 11 ||
                                  (approved?.assign_shift_time_in.split(
                                    ":"
                                  )[0] == 12 &&
                                    approved?.assign_shift_time_in.split(
                                      ":"
                                    )[1] == 0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                                to
                                {
                                  approved?.assign_shift_time_out.split(
                                    ":"
                                  )[0]
                                }
                                :
                                {
                                  approved?.assign_shift_time_out.split(
                                    ":"
                                  )[1]
                                }{" "}
                                {approved?.assign_shift_time_out.split(
                                  ":"
                                )[0] <= 11 ||
                                  (approved?.assign_shift_time_out.split(
                                    ":"
                                  )[0] == 12 &&
                                    approved?.assign_shift_time_out.split(
                                      ":"
                                    )[1] == 0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                              </td>
                              <td>{approved?.shift_name}</td>
                              <td>
                                {" "}
                                {approved?.shift_time_in.split(":")[0]}:
                                {approved?.shift_time_in.split(":")[1]}{" "}
                                {approved?.shift_time_in.split(":")[0] <=
                                  11 ||
                                  (approved?.shift_time_in.split(":")[0] ==
                                    12 &&
                                    approved?.shift_time_in.split(":")[1] ==
                                    0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                                to
                                {approved?.shift_time_out.split(":")[0]}:
                                {approved?.shift_time_out.split(":")[1]}{" "}
                                {approved?.shift_time_out.split(":")[0] <=
                                  11 ||
                                  (approved?.shift_time_out.split(":")[0] ==
                                    12 &&
                                    approved?.shift_time_out.split(":")[1] ==
                                    0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                              </td>
                              <td>{approved?.reasons}</td>
                              <td>{moment(approved?.created_at).format("DD-MM-YYYY")}</td>
                              <td>{moment(approved?.updated_at).format("DD-MM-YYYY")}</td>
                              <td>
                                <span class="badge bad-status badge-success">
                                  Approved
                                </span>
                              </td>
                              <td>
                                <Tooltip title={approved?.sap_error} position="bottom">
                                  {approved?.sap_error}
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="D"
                  title={<React.Fragment>Declined</React.Fragment>}
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Location</th>
                          <th>Shift Date</th>
                          <th>Assigned Shift</th>
                          <th>Assigned Shift Timing</th>
                          <th>Requested Shift</th>
                          <th>Requested Shift Timing</th>
                          <th>Reason</th>
                          <th>Apply Date</th>
                          <th>Rejected Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {declinedList?.length ? (
                          declinedList?.map((rej, index) => (
                            <tr>
                              <td>
                                <Tooltip title={rej?.user_name} position="bottom">
                                  {rej?.user_name}
                                </Tooltip>
                              </td>
                              <td>{rej?.user_designation}</td>
                              <td>{rej?.user_department}</td>
                              <td>{rej?.user_location}</td>
                              <td>
                                {" "}
                                {moment(rej?.shift_date).format(" DD-MM-YY  ")}
                              </td>
                              <td>{rej?.prev_shift_name}</td>
                              <td>
                                {
                                  rej?.assign_shift_time_in.split(
                                    ":"
                                  )[0]
                                }
                                :
                                {
                                  rej?.assign_shift_time_in.split(
                                    ":"
                                  )[1]
                                }{" "}
                                {rej?.assign_shift_time_in.split(
                                  ":"
                                )[0] <= 11 ||
                                  (rej?.assign_shift_time_in.split(
                                    ":"
                                  )[0] == 12 &&
                                    rej?.assign_shift_time_in.split(
                                      ":"
                                    )[1] == 0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                                to
                                {
                                  rej?.assign_shift_time_out.split(
                                    ":"
                                  )[0]
                                }
                                :
                                {
                                  rej?.assign_shift_time_out.split(
                                    ":"
                                  )[1]
                                }{" "}
                                {rej?.assign_shift_time_out.split(
                                  ":"
                                )[0] <= 11 ||
                                  (rej?.assign_shift_time_out.split(
                                    ":"
                                  )[0] == 12 &&
                                    rej?.assign_shift_time_out.split(
                                      ":"
                                    )[1] == 0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                              </td>
                              <td>{rej?.shift_name}</td>
                              <td>
                                {" "}
                                {rej?.shift_time_in.split(":")[0]}:
                                {rej?.shift_time_in.split(":")[1]}{" "}
                                {rej?.shift_time_in.split(":")[0] <=
                                  11 ||
                                  (rej?.shift_time_in.split(":")[0] ==
                                    12 &&
                                    rej?.shift_time_in.split(":")[1] ==
                                    0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                                to
                                {rej?.shift_time_out.split(":")[0]}:
                                {rej?.shift_time_out.split(":")[1]}{" "}
                                {rej?.shift_time_out.split(":")[0] <=
                                  11 ||
                                  (rej?.shift_time_out.split(":")[0] ==
                                    12 &&
                                    rej?.shift_time_out.split(":")[1] ==
                                    0)
                                  ? "hrs"
                                  : "hrs"}{" "}
                              </td>
                              <td>{rej?.reasons}</td>
                              <td>{moment(rej?.created_at).format("DD-MM-YYYY")}</td>
                              <td>{moment(rej?.updated_at).format("DD-MM-YYYY")}</td>
                              <td>
                                <span class="badge bad-status badge-danger">
                                  Rejected
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={12}> No Record Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Formik
        initialValues={shiftApproveDataFilter}
        onSubmit={async (values) => {
          mainDataFilter(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="form-group innergroup">
                  <label> Select User</label>
                  <Select
                    options={dropDept}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // id={"shift"+index}
                    name="shift"
                    // isDisabled={res?.disableField}
                    // isDisabled={res?.attendance_date < new Date().toJSON().slice(0, 10)}
                    onChange={(e) => {
                      // handleChange(e);
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        user_id: e.value,
                      });
                      setFilterUser(e);
                    }}
                    value={filterUser}
                  />
                  {/* <select
                    className="form-control main-pointer"
                    classNamePrefix="select"
                    style={{ width: "334px" }}
                    name="user_id"
                    onChange={(e) => {
                      handleChange(e);
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        user_id: e.target.value,
                      });
                    }}
                    value={shiftApproveDataFilter.user_id}
                  >
                    <option value="" label="Select Shift" disabled />
                    {renderDeptOptions(dropDept)}
                  </select> */}
                </div>

                <div class="form-group innergroup">
                  <label>
                    From Date<span class="text-danger"></span>
                  </label>
                  <input
                    onChange={(e) => {
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        from_date: e.target.value,
                      })
                    }}
                    value={shiftApproveDataFilter?.from_date}
                    name="from_date"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="form-group innergroup">
                  <label>
                    To Date<span class="text-danger"></span>
                  </label>
                  <input
                    onChange={(e) => {
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        to_date: e.target.value,
                      })
                    }}
                    value={shiftApproveDataFilter?.to_date}
                    name="to_date"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div className="form-group innergroup">
                  <label>
                    Select Location<span class="text-danger"></span>
                  </label>
                  <Select
                    isMulti
                    name="sub_personal_area"
                    options={subPersonalArea}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                    onChange={(e) => {
                      setPsaVal(e);
                      let psa_data = e?.map((value) => {
                        return value.value;
                      });
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        psa_id: psa_data,
                      })

                    }}
                    value={psaVal}
                  />
                </div>
                <div className="form-group innergroup">
                  <label>Business Unit</label>
                  <select
                    className="form-control newbgselect"
                    name="business_units_id"
                    onChange={(e) => {
                      handleChange(e);
                      setShiftApproveDataFilter({
                        ...shiftApproveDataFilter,
                        business_units_id: e.target.value,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    value={shiftApproveDataFilter?.business_units_id}
                  >
                    <option value="" label="Select Business Unit" disabled />
                    {renderBranchOptions(branchData)}
                  </select>
                </div>
                {/* <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Approved</option>
              <option>Rejected</option>
              <option>Pending</option>
            </select>
          </div> */}
              </Modal.Body>
              <Modal.Footer>
                <button class="btn btn-outline-danger" onClick={clearFilter}>
                  Cancel
                </button>
                <button class="btn btn-primary-inner bpi-main">Apply</button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default ShiftApproval;
