import React, { useState } from "react";
import {
  Modal,
  Dropdown
} from "react-bootstrap";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import CustomBreadcrumb from "./CustomBreadcrumb";

function MatDescrp() {
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Material Type Description' }
  ]

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Material Type Description</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Q.ID.</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>8</td>
                    <td>
                      Do you want to create material code for Engineering Spares
                      (94 series)? Example - Coupling, Flange, Bearing, Valve
                      etc.
                    </td>
                    <td>ERSA</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>9</td>
                    <td>
                      Do you want to create material code for General purchase
                      (95 series)? Example - Computer Accessories, Housekeeping
                      Items, Stationery, Building material, Hardware etc.
                    </td>
                    <td>ZR&D</td>
                    <td>
                      <span class="badge bad-status badge-success">Active</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>10</td>
                    <td>
                      Do you want to create material code for Lab consumables/
                      spares (96 series)? Example - Funnel, Beaker, Flask etc.
                    </td>
                    <td>ZLAB</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>11</td>
                    <td>
                      Do you want to create material code for R&D Chemicals (97
                      series)? Example - Benzonitrile, Isopropanol etc.
                    </td>
                    <td>ROH</td>
                    <td>
                      <span class="badge bad-status badge-success">Active</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td>12</td>
                    <td>
                      Do you want to create material code for CRE Research
                      Chemicals (76 series)? Example - Agar, Benzoic Acid etc.
                    </td>
                    <td>GEN</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        InActive
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <CustomPagination />

          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Material Type Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Material Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option value="ERSA">ERSA - SPARE PARTS</option>
                      <option value="GEN">GEN - GENERAL STORES</option>
                      <option value="POP">POP - Promotional Items</option>
                      <option value="ROH">ROH - RAW MATERIALS</option>
                      <option value="VERP">VERP - PACKAGING</option>
                      <option value="ZCRE">
                        ZCRE - EXTERNAL &amp; TEST COMPOUNDS
                      </option>
                      <option value="ZLAB">ZLAB - LAB MATERIALS</option>
                      <option value="ZR&amp;D">
                        ZR&amp;D - R&amp;D MATERIALS
                      </option>
                      <option value="ZSRC">ZSRC - CRE RESEARCH CHEMICAL</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Enter Description<span class="text-danger">*</span>
                    </label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Description"
                    ></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input type="radio" name="statusupdate1" checked />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input type="radio" name="statusupdate1" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={addnewclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MatDescrp;
