const AppConstant = (() => {
  return {
    passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    stripeCOKey:
      "pk_test_51IYRdHGC7nDidgr9AnrMNB6aovkLHxNL1ciAx0tQCcmBTXNcDByT6jjwQCaWkbpY0fxDUvuxTzcoPctrjZjLRwpm006RWdNhvc",
  };
})();

export const prMasterCodes = {
  servicePr: "S",
  standardPr: "U",
  textPr: "T",
  projectPr: "Q",
  assetPr: "A",
};
// prcode	prdesc
// U	Standard PR
// S	Service PR
// T	Text PR
// Q	Project PR
// A	Asset PR

export const _externalURLS = {
  pdf: (url) =>
    `https://docs.google.com/gview?url=/${url}file/filename.pdf&embedded=true`,
};
export default { AppConstant, prMasterCodes };
