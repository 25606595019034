import React, { useState, useEffect } from "react";
import * as moment from "moment";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { uploadfile } from "../../service/Survey";

import * as HigherEduService from "../../service/educationService";
import { useLocation, useNavigate } from "react-router-dom";
import { setDisplayLoader, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import { PdfIcon } from "./Icon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HigherEduApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [usersListApiData, set_usersListApiData] = useState([]);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [selectedUserItem, set_selectedUserItem] = useState(null);
  const currencyOption = [
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
  ];

  const [defaultUser, setDefaultUser] = useState(null);
  const [messageModal, setMessageModal] = useState({
    flag: false,
    heading: "",
    text: "",
  });

  const [postData, setPostData] = useState({
    user: 0,
    development_status: "",
    currency: "INR",
    course: "",
    university: "",
    university_address: "",
    course_content: "",
    justification: "",
    course_duration: "",
    course_fee: 0,
    attachments: [],
    start_date: "",
    end_date: "",
  });

  // const [editData,setEditData] = useState({})
  const [isSubmitBtnDisable, setIsSubmitBtnDisable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("PENDING");
  const [deleteImgList, setDeleteImgList] = useState([]);
  const [isThreeYearsComplete, setIsThreeYearsComplete] = useState(true);

  let abortApplicationUserList = null;

  // const years = range(1990, getYear(new Date()) + 100, 1);
  const startYear = 1990;
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 100 },
    (_, index) => startYear + index
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const customDatePicker = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="m-1 d-flex justify-content-between">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="rounded border p-1"
        style={{ width: 30 }}
      >
        {"<"}
      </button>
      <select
        value={years[date.getFullYear()]}
        onChange={({ target: { value } }) => changeYear(years.indexOf(value))}
        className="rounded border p-1"
        style={{ width: "25%" }}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[date.getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        className="rounded border p-1"
        style={{ width: "40%" }}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="rounded border p-1"
        style={{ width: 30 }}
      >
        {">"}
      </button>
    </div>
  );

  const onCourseFeeChangeHandler = (e) => {
    const numberRegex = /^[0-9]*$/;
    if (numberRegex.test(e.target.value)) {
      setPostData({
        ...postData,
        course_fee: e.target.value,
      });
    } else {
      e.preventDefault();
    }
  };

  const preventNonNumericalInput = (e) => {
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/) && e.key !== "Backspace") e.preventDefault();
  };

  const onStartDateChange = (e) => {
    let date = defaultUser?.date_of_joining
      ? new Date(defaultUser?.date_of_joining)
      : new Date();
    let days = "";
    if (postData.end_date !== "") {
      let endDate = new Date(postData.end_date);
      const diffTime = Math.abs(new Date(e) - endDate);
      days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    if (new Date(e) >= date) {
      setPostData({
        ...postData,
        start_date: moment.parseZone(e).format("YYYY-MM-DD"),
        course_duration: days,
      });
    }
  };
  const onEndDateChange = (e) => {
    let date = new Date();
    let days = "";
    if (postData.start_date !== "") {
      date = new Date(postData.start_date);
      const diffTime = Math.abs(date - new Date(e));
      days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    if (new Date(e) >= date) {
      setPostData({
        ...postData,
        end_date: moment.parseZone(e).format("YYYY-MM-DD"),
        course_duration: days,
      });
    }
  };

  const updateApplication = async (dataa) => {
    let attachmentList = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          attachmentList.push(response?.data);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          setMessageModal({ flag: true, heading: "OOPS!!", text: error });
          // toast.error(`${error} `)
          return;
        });
    }

    dispatch(setDisplayLoader("Display"));
    dataa.attachments = attachmentList;
    dataa.del_attachments = deleteImgList;
    HigherEduService.updateApplication(dataa.id, dataa)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        // setAsmntData(response.data?.dataList)
        toast.success(`Update successfully.`);
        setIsSubmitBtnDisable(false);
      })
      .catch((error) => {
        setIsSubmitBtnDisable(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
        setMessageModal({ flag: true, heading: "OOPS!!", text: error });
        // toast.error(error)
      });
  };

  const re_submitApplication = async (dataa) => {
    let attachmentList = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          attachmentList.push(response?.data);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          setMessageModal({ flag: true, heading: "OOPS!!", text: error });
          // toast.error(`${error} `)
          return;
        });
    }
    dataa.attachments = attachmentList;
    dispatch(setDisplayLoader("Display"));

    HigherEduService.re_submitApplication(dataa.id, dataa)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        // setAsmntData(response.data?.dataList)
        toast.success(`Application re-submitted.`);
        navigate("/higher-edu-listing");
      })
      .catch((error) => {
        setIsSubmitBtnDisable(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
        setMessageModal({ flag: true, heading: "OOPS!!", text: error });
        // toast.error(error)
      });
  };

  const onProfileChange = (e) => {
    let allfiles = e.target.files;
    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];

      let myUrl = URL.createObjectURL(afile);
      let urlSplitArr = myUrl.split("/");
      let typeArr = afile.type.split("/");
      urls.push({
        url: myUrl,
        key: urlSplitArr[urlSplitArr.length - 1],
        type: typeArr[1],
      });

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);
  };

  const removeDocumentByIdx = (idx, id) => {
    let newIList = imageLocalPreviewList.filter((data, idxx) => {
      return idxx != idx;
    });

    if (id > 0) {
      setDeleteImgList([...deleteImgList, id]);
    } else {
      let newFList = filesToUpload.filter((data, idxx) => {
        return idxx != idx;
      });
      setFilesToUpload(newFList);
    }
    set_imageLocalPreviewList(newIList);
  };

  const fileUpload = () => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        let attachments2 = [];
        for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
          await uploadfile(filesToUpload[afilee])
            .then((response) => {
              attachments2.push(response?.data);
            })
            .catch((error) => {
              dispatch(setDisplayLoader("Hide"));
              setMessageModal({ flag: true, heading: "OOPS!!", text: error });
              return;
            });
        }

        // Simulating success
        resolve(attachments2);
      }, 1000);
    });
  };

  const postApplicationRequest = async (data) => {
    dispatch(setDisplayLoader("Display"));
    fileUpload().then((result) => {
      data.attachments = result;
      HigherEduService.postApplicationRequest(data)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          toast.success("Request created successfully.");
          navigate("/higher-edu-listing");
        })
        .catch((error) => {
          setIsSubmitBtnDisable(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "api failed"
          );

          dispatch(setDisplayLoader("Hide"));
          const msg =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            "api failed";
          setMessageModal({ flag: true, heading: "OOPS!!", text: msg });

          // toast.error((error.response &&
          //   error.response.data && error.response.data.detail) ||
          //   "api failed"
          // )
        });
    });
  };

  const validateAndSubmit = () => {
    const stringRegex = /^[a-zA-Z. ]*$/;
    let flag = true;
    for (const [key, value] of Object.entries(postData)) {
      if (
        !value &&
        !(
          key == "approve_username" ||
          key == "approve" ||
          key == "approval_type" ||
          key == "user"
        )
      ) {
        flag = false;
        toast.error(`${key} is mandatory`);
      }
      if (key == "course_duration" && !parseInt(value)) {
        flag = false;
        toast.error("Course duration must be atleast 1 day");
      }
    }

    if (!stringRegex.test(postData.university)) {
      toast.warn("Please enter valid university name.");
      flag = false;
    }

    if (flag) submitApplication();
  };

  const submitApplication = () => {
    setIsSubmitBtnDisable(true);
    let payload = {
      ...postData,
      user: selectedUserItem?.value,
    };
    // if(Object.keys(editData).length){
    if (isEdit) {
      // console.log(`data ready! = `, location.state.is_ReSubmitting);
      if (currentStatus == "RESUBMIT") {
        payload = {
          id: payload.id,
          // attachments: postData.attachments,
          attachments: filesToUpload,
          del_attachments: deleteImgList,
          start_date: payload.start_date,
          end_date: payload.end_date,
          remarks: postData.justification,
        };
        re_submitApplication(payload);
      } else {
        updateApplication(payload);
      }
    } else {
      postApplicationRequest(payload);
    }

    // }
  };

  function applySelectedUserItem(e) {
    set_selectedUserItem(e);
  }

  const applySelectedUser = (user_id) => {
    for (const item of usersListApiData) {
      if (item.id == user_id) {
        const diffTime = Math.abs(new Date() - new Date(item.date_of_joining));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setIsThreeYearsComplete(diffDays > 1095);

        setDefaultUser(item);
        setPostData({
          ...postData,
          development_status: item?.development_status,
        });
        return;
      }
    }
  };

  const getApplicationUserList = async (
    pageNo,
    pageSizeNo,
    sort_by,
    search,
    paginate
    // dept,
    // branchId,
    // desigId,
    // locationId
    // // designationId,
    // // deepartmentId
  ) => {
    if (abortApplicationUserList) abortApplicationUserList.abort();
    abortApplicationUserList = new AbortController();
    dispatch(setDisplayLoader("Display"));
    await HigherEduService.getApplicationUserList(
      `pageNo=${pageNo}&` +
        `page_size=${pageSizeNo}&` +
        `sort_by=${sort_by}&` +
        `search=sort_by${search}&` +
        `paginate=${paginate}&`,
      abortApplicationUserList.signal
      //   dept,
      //   branchId,
      //   desigId,
      //   emploc?.map((obj) => {
      //     return obj?.value;
      //   })
      // designationId,
      // deepartmentId
    )
      .then((response) => {
        abortApplicationUserList = null;
        dispatch(setDisplayLoader("Hide"));
        // console.log("response==>", response.data);
        const data = [
          { value: null, label: "--select--" },
          ...response?.data?.dataList?.result?.map((x) => {
            return {
              value: x.id,
              label: `${x?.full_name} ( ${x.username} )`,
            };
          }),
        ];
        setUsersDropdown(data);
        set_usersListApiData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getApplicationUserList(1, 10, sortData.sort_by, "", false);

    // console.log(`got`,location.state?.editData);
    return () => {
      if (abortApplicationUserList) abortApplicationUserList.abort();
    };
  }, []);

  useEffect(() => {
    if (location.state?.editData) {
      let empCourseData = location.state?.editData;
      setPostData({
        id: empCourseData?.id,
        user: empCourseData?.user_id,
        course: empCourseData?.course,
        currency: empCourseData?.currency,
        university: empCourseData?.university,
        university_address: empCourseData?.university_address,
        course_content: empCourseData?.course_content,
        justification: empCourseData?.justification,
        course_duration: empCourseData?.course_duration,
        course_fee: empCourseData?.course_fee,
        attachments: empCourseData?.attachment_list,
        del_attachments: [],
        start_date: empCourseData?.start_date,
        end_date: empCourseData?.end_date,
      });

      // setEditData(location.state?.editData)
      setIsEdit(true);
      setCurrentStatus(empCourseData.status);
      let previewImgList = empCourseData?.attachment_list.map((obj) => ({
        url: obj.url,
        key: obj.id,
        id: obj.id,
      }));
      set_imageLocalPreviewList(previewImgList);

      // for (const [keyy,vall] of Object.entries(location.state?.editData)){
      // // console.log(`${keyy}-${vall}`);
      // editData[keyy]=vall

      // }
    }
  }, [usersListApiData]);

  useEffect(() => {
    if (isEdit) {
      applySelectedUser(postData.user);

      usersDropdown.every((eleme) => {
        if (eleme.value == postData.user) {
          applySelectedUserItem(eleme);
          return false;
        }
        return true;
      });

      // alert()
      setPostData(postData);
    }
  }, [usersListApiData]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">
                      Application for Higher Education
                    </h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <h5 className="text-theme border-bottom pb-2 mb-3">
                      <strong>Additional Details</strong>
                    </h5>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Employee Name & Code
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        options={usersDropdown}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            user: e.value,
                          });
                          applySelectedUser(e.value);
                          applySelectedUserItem(e);
                        }}
                        value={selectedUserItem}
                      />

                      {/* <select
                className="form-control newbgselect"
                name="insurance_required"
                // onChange={(e) => {
                //   handleChange(e, e.target.name);
                //   setFormAddValidation({
                //     ...formAddValidation,
                //     insurance_required: "",
                //   });
                // }}
                // value={additionalData.insurance_required}
              >
                <option value="">Select</option>
                <option value="true" selected>
                  Yes
                </option>
                <option value="false">No</option>
              </select> */}
                      <div className="small text-danger">
                        {/* {formAddValidation.insurance_required} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Designation/Grade<span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        name="passport_number"
                        className="form-control"
                        maxLength={12}
                        placeholder="Enter designation"
                        value={defaultUser?.designation_name}
                        // onChange={(e) => {
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     passport_number: "",
                        //   });
                        // }}
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.passport_number} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Department<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        name="passport_number"
                        className="form-control"
                        maxLength={12}
                        placeholder="Enter department"
                        value={defaultUser?.department_name}
                        // onChange={(e) => {
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     passport_number: "",
                        //   });
                        // }}
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.passport_number} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Location<span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        name="passport_number"
                        className="form-control"
                        maxLength={12}
                        placeholder="Enter location"
                        value={
                          defaultUser?.personal_area_description
                            ? defaultUser?.personal_area_description +
                              " (" +
                              defaultUser?.personal_area_name +
                              ")"
                            : ""
                        }
                        // onChange={(e) => {
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     passport_number: "",
                        //   });
                        // }}
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.passport_number} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>Unit</label>
                      <input
                        disabled
                        type="text"
                        name="unit"
                        className="form-control"
                        maxLength={12}
                        placeholder="Enter unit"
                        value={defaultUser?.branch_name}
                        // onChange={(e) => {
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     passport_number: "",
                        //   });
                        // }}
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.passport_number} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label className="d-flex justify-content-between">
                        <span>Date of Joining</span>
                        {isThreeYearsComplete ? (
                          ""
                        ) : (
                          <span style={{ color: "#ff0000" }} className="small">
                            Incomplete 3 Yrs.
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="valid_from"
                        disabled
                        // onChange={(e) => {
                        //   handleFromDateChange(e);
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     valid_from: "",
                        //   });
                        // }}
                        style={{
                          border: isThreeYearsComplete
                            ? ""
                            : "1px solid #ff0000",
                        }}
                        value={
                          defaultUser?.date_of_joining
                            ? moment
                                .parseZone(defaultUser?.date_of_joining)
                                .format("DD-MM-YYYY")
                            : ""
                        }
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.valid_from} */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>Date of Confirmation</label>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        name="valid_to"
                        // min={moment(additionalData?.valid_from).format("YYYY-MM-DD")}
                        // onChange={(e) => {
                        //   handleToDateChange(e);
                        //   handleChange(e, e.target.name);
                        //   setFormAddValidation({
                        //     ...formAddValidation,
                        //     valid_to: "",
                        //   });
                        // }}
                        value={
                          defaultUser?.date_of_confirmation
                            ? moment
                                .parseZone(defaultUser?.date_of_confirmation)
                                .format("DD-MM-YYYY")
                            : null
                        }
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.valid_to} */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Performance Rating
                      </label>
                      <input
                        disabled
                        type="text"
                        name="performance"
                        className="form-control"
                        maxLength={12}
                        placeholder="Enter performance"
                        value={defaultUser?.user_rating}
                      />
                      <div className="small text-danger"> {formAddValidation.passport_number} </div>
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>Area of Development</label>
                      <input
                        // disabled
                        disabled={currentStatus == "RESUBMIT"}
                        type="text"
                        name="dev"
                        className="form-control"
                        // maxLength={12}
                        placeholder="Enter dev"
                        value={postData?.development_status}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            development_status: e.target.value,
                          })
                        }
                      />
                      <div className="small text-danger">
                        {/* {formAddValidation.passport_number} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <h5 className="text-theme border-bottom pb-2 mb-3">
                      <strong>Course Info</strong>
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Course applying for
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            course: e.target.value,
                          })
                        }
                        disabled={currentStatus == "RESUBMIT"}
                        value={postData.course}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Name of Institute or University
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            university: e.target.value,
                          })
                        }
                        disabled={currentStatus == "RESUBMIT"}
                        value={postData.university}
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-group innergroup">
                      <label>
                        Currency
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={currencyOption}
                        onChange={(e) => setPostData({
                          ...postData,
                          currency: e.value
                        })}
                        value={{ label: postData.currency, value: postData.currency }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Course fee
                        <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex">
                        <Select
                          options={currencyOption}
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              currency: e.value,
                            })
                          }
                          value={{
                            label: postData.currency,
                            value: postData.currency,
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderRadius: "10px 0px 0px 10px",
                              width: 100,
                              height: 40,
                            }),
                          }}
                          isDisabled={currentStatus == "RESUBMIT"}
                        />
                        <input
                          type="number"
                          onChange={(e) => e.target.value < 10000000 ? onCourseFeeChangeHandler(e) : null}
                          onKeyDown={(e) => preventNonNumericalInput(e)}
                          disabled={currentStatus == "RESUBMIT"}
                          value={postData.course_fee}
                          className="form-control"
                          style={{ borderRadius: "0px 10px 10px 0px" }}
                          min={0}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup d-flex flex-column">
                      <label>
                        Course Start Date
                        <span className="text-danger">*</span>
                      </label>
                      {/* <input
                        type="date"
                        onChange={e => onStartDateChange(e.target.value)}
                        // disabled
                        value={postData.start_date}
                        className="form-control"
                        min={defaultUser?.date_of_joining}
                        max={postData.end_date}
                      /> */}
                      <DatePicker
                        // renderCustomHeader={customDatePicker}
                        selected={
                          postData?.start_date
                            ? new Date(postData.start_date)
                            : ""
                        }
                        onChange={(date) => onStartDateChange(date)}
                        minDate={new Date()}
                        maxDate={new Date(postData.end_date)}
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup d-flex flex-column">
                      <label>
                        Course End Date
                        <span className="text-danger">*</span>
                      </label>
                      {/* <input
                        type="date"
                        onChange={e => onEndDateChange(e.target.value)}
                        // disabled
                        value={postData.end_date}
                        className="form-control"
                        min={postData.start_date}
                      /> */}
                      <DatePicker
                        // renderCustomHeader={customDatePicker}
                        selected={
                          postData?.end_date ? new Date(postData.end_date) : ""
                        }
                        onChange={(date) => onEndDateChange(date)}
                        minDate={new Date(postData.start_date)}
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group innergroup">
                      <label>
                        Duration of the course (in Days)
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-control">
                        {postData.course_duration}
                      </div>
                      {/* <input
                        type="number"
                        onChange={(e) => setPostData({
                          ...postData,
                          course_duration: e.target.value
                        })}
                        disabled={currentStatus == 'RESUBMIT'}
                        value=
                        
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Address of Institute or University
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            university_address: e.target.value,
                          })
                        }
                        disabled={currentStatus == "RESUBMIT"}
                        value={postData.university_address}
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Course content
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            course_content: e.target.value,
                          })
                        }
                        disabled={currentStatus == "RESUBMIT"}
                        value={postData.course_content}
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group innergroup position-relative modaldefaultclose col-md-6">
                      <label>
                        Upload files
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control bg-white"
                        disabled
                        placeholder="(JPG, PNG Format only)"
                      />
                      <div className="upload-btn-wrapper up-loposition">
                        <button className="uploadBtn">Browse</button>
                        <input
                          type="file"
                          accept=".pdf, .jpeg, .jpg, .png"
                          multiple
                          onChange={(e) => onProfileChange(e)}
                        />
                      </div>
                    </div>
                    {imageLocalPreviewList.length ? (
                      <div className="d-flex flex-wrap">
                        {imageLocalPreviewList.map((idata, idx) => (
                          <DocumentPreview
                            docData={idata}
                            removeDocumentByIdx={removeDocumentByIdx}
                            index={idx}
                            key={idata.key}
                          />
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="col-md-6">
                    <span style={{ fontWeight: "bold" }}>Notes :-</span>
                    <p>
                      1. Upload "Course Brochure" containing on institute,
                      accreditation, course curriculum, elegibility for
                      admission, course start/end date, course duration,
                      assessment process, etc. (You may use multiple documents
                      or one page writeup to share the information).
                    </p>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group innergroup">
                      <label>
                        Justification for applying of the course (only with
                        relevance to PI Industries Ltd.)
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            justification: e.target.value,
                          })
                        }
                        // disabled={currentStatus == "RESUBMIT"}
                        value={postData.justification}
                        className="form-control"
                      ></textarea>
                    </div>
                  </div>

                  <div className="d-flex col-md-12 pt-4">
                    <button
                      className="btn bpi-main"
                      onClick={() => navigate("/higher-edu-listing")}
                      style={{ backgroundColor: "#b5babe" }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary-inner bpi-main ml-3"
                      disabled={isSubmitBtnDisable}
                      onClick={validateAndSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={messageModal.flag}
        onHide={() => setMessageModal({ flag: false, heading: "", text: "" })}
        backdrop="static"
        keyboard={false}
        className="modaldefaultclosecenter d-flex align-items-center"
      >
        <Modal.Header>
          <Modal.Title>{messageModal.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center ml-5 mr-5">
          <span style={{ textAlign: "center", color: "grey" }}>
            {messageModal.text ==
            "You are not eligible to submit the Higher Education Policy Request" ? (
              <>
                You are not eligible to submit the{" "}
                <b>Higher Education Policy Request</b>
                <br />
                <br />
                Kindly contact your HR Business Partner
              </>
            ) : (
              messageModal.text
            )}
          </span>

          <button
            className="btn btn-primary-inner bpi-main mt-4 mb-2"
            onClick={() =>
              setMessageModal({ flag: false, heading: "", text: "" })
            }
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

function DocumentPreview(props) {
  return (
    <div className="col-md-2 mb-3">
      <a href={props.docData.url} target="_blank" rel="noreferrer">
        {props.docData?.type ? (
          props.docData.type == "pdf" ? (
            <div style={{ aspectRatio: "16/9" }}>
              <PdfIcon width={120} height={85} />
            </div>
          ) : (
            <img src={props.docData.url} style={{ aspectRatio: "16/9" }} />
          )
        ) : props.docData.url.split(".")[
            props.docData.url.split(".").length - 1
          ] == "pdf" ? (
          <div style={{ aspectRatio: "16/9" }}>
            <PdfIcon width={120} height={85} />
          </div>
        ) : (
          <img src={props.docData.url} style={{ aspectRatio: "16/9" }} />
        )}
      </a>
      <div
        onClick={() =>
          props.removeDocumentByIdx(
            props.index,
            props.docData?.id ? props.docData?.id : 0
          )
        }
        style={{
          position: "absolute",
          top: 3,
          right: 20,
          borderRadius: "50%",
          backgroundColor: "#ff0000",
          width: 18,
          height: 18,
          cursor: "pointer"
        }}
        className="d-flex align-items-center justify-content-center p-1"
      >
        <i className="fas fa-times" style={{ color: "#fff" }}></i>
      </div>
    </div>
  );
}

export default HigherEduApplication;
