import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as surveyService from "../../service/Survey";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MultiOptionSurveyView from "./MultiOptionSurveyView";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import moment from "moment";
import { isAndroid, isIOS } from "react-device-detect";
import * as UserService from '../../service/userService'

var duration = 0;
function ViewExternalassess() {
  const [asmntData, setAsmntData] = useState({});
  const [submittedNotifier, setSubmitNotifier] = useState(false);
  const [curAssess, setCurAssess] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [countryDataP, setCountryDataP] = useState([]);
  const [dropDownVal, setDropDownVal] = useState({});
  const [countryCode, setCountryCode] = useState("");
  // const [duration, setDuration] = useState(0);
  const dispatch = useDispatch();
  const [userMailToken, setUserMailToken] = useState({
    userMail: null,
    userToken: null,
  });
  const navigate = useNavigate();
  const myRef = useRef(null);
  const [validation, setValidation] = useState([])
  const [formError, setFormError] = useState({
    email: false,
    contact: false,
    company: false,
    name: false,
    designation: false
  });
  let condition = "true";

  const [userInput, setUserInput] = useState({
    id: 0,
    is_draft: false,
    // survey_id:  0,
    questions: [],
    companyName: "",
    name: "",
    designation: "",
    email: "",
    contact: "",
  });
  const [isFeedback, setIsFeedback] = useState([]);
  const [secDesc, setSecDesc] = useState([]);

  const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/

  let currentCheckedRating = {};

  useEffect(() => {
    getCountryList(false);
    const _location_data = new URLSearchParams(window.location.search);
    console.log(_location_data.get("email"));
    // _user_email= _location_data.get("email")
    // _user_token=_location_data.get("token")
    // _assignment_type=_location_data.get("is_external")

    setUserMailToken({
      ...userMailToken,
      userMail: _location_data.get("email"),
      userToken: _location_data.get("token"),
    });

    dispatch(setDisplayLoader('Display'))

    surveyService
      .getExternalAssignmentDetails(
        "email=" +
        _location_data.get("email") +
        "&token=" +
        _location_data.get("token")
      )
      .then((response) => {
        dispatch(setDisplayLoader('Hide'))
        setIsLoading(false)
        setCurAssess(response?.data?.dataList?.result[0]);
        let finalRes = {};
        let questionsArr = [];
        let valid = []
        let secValid = {}
        response?.data?.dataList?.result[0]?.questions?.map((qItem, idx) => {
          let quesRespData = {
            question_id: qItem.id,
            option_id: [],
            multiOption: qItem?.multiOption,
            remark_text: "",
            feedback: "",
            is_mandatory: qItem?.is_mandatory
          };

          if (qItem?.answers?.length > 0) {
            qItem?.answers.map((sub_ansOp, sub_ansOpIdx) => {
              if (sub_ansOp?.remark_text?.length > 0) {
                quesRespData.remark_text = sub_ansOp?.remark_text;
              }

              qItem?.options.map((ansOp, ansOpIdx) => {
                if (sub_ansOp.answer_options === ansOp.option) {
                  quesRespData.option_id.push(ansOp.id);
                }
              });
            });
          }
          else {
            qItem?.options?.map((each) => {
              if (each?.type === 'Slider') {
                quesRespData.option_id.push({
                  id: 0,
                  remark_text: '0'
                });
                each.showFeedback = true;
              } else {
                quesRespData.option_id.push({
                  id: 0,
                  remark_text: ''
                });
              }
            })
          }

          if (Object.keys(finalRes).includes(qItem?.section)) {
            secValid[qItem?.section].push({
              mandatory: false,
              id: qItem?.id,
              feedback: false
            })
            finalRes[qItem?.section].push(qItem);
          } else {
            if (qItem?.section !== null) {
              finalRes[qItem?.section] = [qItem];
              secValid[qItem?.section] = []
              secValid[qItem?.section].push({
                mandatory: false,
                id: qItem?.id,
                feedback: false
              })
              setSecDesc((prev) => ([...prev, qItem?.section_desc]))
            } else {
              valid.push({
                mandatory: false
              })
              if ("questions" in finalRes) {
                finalRes["questions"].push(qItem);
              } else {
                finalRes["questions"] = [qItem];
              }
            }
          }
          questionsArr.push(quesRespData);
        });
        setValidation(Object.keys(secValid)?.length > 0 ? secValid : valid);
        setAsmntData(finalRes);
        setUserInput({
          ...userInput,
          questions: questionsArr,
        });
      })
      .catch((error) => {
        dispatch(setDisplayLoader('Hide'))
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }, []);

  useEffect(() => {
    setInterval(() => {
      duration += 1
    }, [1000])
  }, [])

  const getCountryList = (paginate) => {
    UserService.getCountryListSurvey(paginate)
      .then((response) => {
        let data1 = [];
        response?.data?.dataList?.result?.map((val) => {
          let dataSet1 = {
            value: val.dial_code,
            label: (
              <div className="dial-code-size">
                <img src={val.flag} className="countryimageph" />{" "}
                {`${val.dial_code} (${val?.code})`}
              </div>
            ),
          };
          if (val.dial_code === "91") {
            setCountryCode({
              value: val.dial_code,
              label: (
                <div className="dial-code-size">
                  <img src={val.flag} className="countryimageph" />{" "}
                  {val.dial_code}
                </div>
              ),
            })
          }
          data1.push(dataSet1);
        });
        setCountryDataP(data1);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  function updateLocalResponse(e, local_idx, qid, opid, qtype, qItem) {
    let assessData = JSON.parse(JSON.stringify(asmntData))
    let validate = JSON.parse(JSON.stringify(validation))
    if (e.target.name !== 'RatingFeedback') {
      assessData[qItem]?.map((each) => {
        if (each?.id === qid) {
          if (each?.options[local_idx]?.allow_feedback && each?.select_feedback
            && each?.select_feedback_option.includes(e.target.value)) {
            each.options[local_idx].showFeedback = true;
          } else {
            each.options[local_idx].showFeedback = false;
          }
        }
        return each;
      });
    }
    if (qItem !== "") {
      validate[qItem]?.map((each) => {
        if (each?.id === qid) {
          console.log(each, e.target.name);
          each.mandatory = false;
          if (e.target.name === "RatingFeedback") {
            each.feedback = false;
          }
        }
      })
    }
    setAsmntData(assessData)
    setValidation(validate)
    console.log(validate)
    let data = document.getElementsByClassName("checkboxstart");
    // Object.entries(data).map((x, i) => {
    //   if (i + 1 <= Number(e.target.value)) {
    //     x[1].checked = true;
    //   }
    // });
    // Object.entries(data).map((x, i) => {
    //   if (i + 1 > Number(e.target.value)) {
    //     x[1].checked = false;
    //   }
    // });
    for (let i = 0; i < data?.length; i++) {
      if (
        Number(data[i].getAttribute("ques")) === qid &&
        Number(data[i].getAttribute("value")) < Number(e?.target.value)
      ) {
        data[i].checked = data[i].checked ? false : true;
      }
    }
    let questionsArr = JSON.parse(JSON.stringify(userInput?.questions));
    let found = false;
    let index = null;
    let curQues = JSON.parse(JSON.stringify(curAssess))?.questions.filter((each, i) => {
      if (each?.id === qid) {
        index = i;
        return true;
      }
    })[0];

    if (questionsArr?.length > 0) {
      questionsArr?.forEach((qitem, idx) => {
        if (qitem.question_id === qid) {
          found = true;
          if (qtype === "Multiple Choice") {
            qitem.option_id.push(opid);
          } else {
            if (
              qtype === "Rating" ||
              qtype === "Short Answer" ||
              qtype === "Long Answer"
            ) {
              if (curQues?.multiOption) {
                if (qtype === "Rating") {
                  if (qitem.option_id[local_idx]) {
                    if (e.target.name === 'RatingFeedback') {
                      qitem.option_id[local_idx].feedback = e.target.value;
                    } else {
                      qitem.option_id[local_idx].remark_text = e?.currentTarget?.value === undefined ? e.target.value.toString() : e.currentTarget.value;
                    }
                  } else {
                    if (e.target.name === 'RatingFeedback') {
                      qitem.option_id.splice(local_idx, 0, {
                        feedback: e.target.value,
                        opid: 0
                      })
                    } else {
                      let curQues = JSON.parse(JSON.stringify(curAssess))
                      qitem.option_id.splice(local_idx, 0, {
                        remark_text: e?.currentTarget?.value === undefined ? e.target.value.toString() : e.currentTarget.value,
                        opid: 0
                      })
                    }
                  }
                }
              } else {
                if (qtype === "Rating") {
                  // if(qitem.id in currentCheckedRating){
                  //   currentCheckedRating[qitem.id].checked=false
                  // }
                  currentCheckedRating[qitem.id] = e;

                }
                qitem.remark_text = e.currentTarget.value;
              }
            } else {
              if (qtype === "Single") {
                qitem.remark_text = e.target.value;
              } else {
                let curQuesOpt = curAssess?.questions?.filter((each) => {
                  if (each?.id === qid) {
                    return true;
                  }
                })[0].options?.map((each) => each?.id);
                let curFeedbackList = isFeedback?.filter((x) => {
                  if (curQuesOpt.includes(x) === false) {
                    return true
                  }
                })
                curFeedbackList.push(opid)
                qitem.option_id.pop();
                qitem.option_id.push(opid);
                setIsFeedback(curFeedbackList)
              }
            }
          }
        }
        // console.log(qitem, 'values');
        return qitem;
      });
    }

    if (!found) {
      let quesRespData = {};
      if (qtype === "Rating" || qtype === "Short Answer") {
        quesRespData = {
          question_id: qid,
          option_id: [],
          remark_text: e.target.value,
        };
      } else {
        quesRespData = {
          question_id: qid,
          option_id: [opid],
          remark_text: "",
        };
      }
      questionsArr.push(quesRespData);
    }
    setUserInput({
      ...userInput,
      questions: questionsArr,
    });
  }

  const QuestionEditor = (question) => {
    let ques = question.split("<p>")[1].split("</p>")[0];
    return ques;
  };

  const handleValidate = () => {
    let remarkFilled = true;
    let validate = false;
    let valid = JSON.parse(JSON.stringify(validation))
    let userSubmitRes = JSON.parse(JSON.stringify(userInput))
    if ((userInput?.contact === "" || userInput?.contact?.length < 6 || userInput?.contact?.length > 10 || countryCode === "") || emailRegex.test(userInput?.email) === false ||
      userInput?.companyName === "" || userInput?.name === "" || userInput?.designation === "") {
      validate = true;
      setFormError((prev) => ({
        ...prev,
        contact: (userInput?.contact === "" || userInput?.contact?.length < 6 || userInput?.contact?.length > 10 || countryCode === "") ? true : false,
        email: emailRegex.test(userInput?.email) === false ? true : false,
        name: userInput?.name === "" ? true : false,
        company: userInput?.companyName === "" ? true : false,
        designation: userInput?.designation === "" ? true : false
      }));
    }
    let questions = []
    Object.values(asmntData).map((each) => {
      return questions.push(...each);
    });
    let sliders = questions?.filter((each) => {
      if (each?.multiOption) {
        let res = each?.options.filter((opt) => {
          if (opt?.type === "Slider") {
            return true
          }
        })
        if (res.length > 0) {
          return true
        }
      }
    })?.map((x) => {
      return x?.id
    })
    let check = 0
    userInput?.questions?.map((each, index) => {
      if (sliders.includes(each?.question_id) && each.option_id?.length === 0) {
        validate = true;
        check += 1;
      }
    });
    let is_feed = false
    function getAnswerStat(each, index) {
      let idx = null;
      let val = userInput?.questions?.filter((x, ind) => {
        if (x?.question_id === each?.id) {
          idx = ind;
          return true;
        }
      }).map((x) => {
        let isExist = x?.option_id?.filter((val, i) => {
          if (questions[index]?.options[i]?.showFeedback && questions[index]?.options[i]?.feedback_mandatory && (val?.feedback === undefined || val?.feedback === '')) {
            valid[each?.section]?.map((y) => {
              if (y?.id === each?.id) {
                y.feedback = true;
              }
            })
            validate = true;
            remarkFilled = false;
          } else {
            valid[each?.section]?.map((y) => {
              if (y?.id === each?.id) {
                y.feedback = false;
                if (!questions[index]?.options[i]?.showFeedback) {
                  userSubmitRes.questions[idx].option_id[i].feedback = ''
                }
              }
            })
          }
          if (val?.remark_text === "") {
            return true
          }
        });
        if (isExist?.length > 0) {
          return true;
        } else {
          return false;
        }
      })[0];
      return val;
    }
    questions?.map((each, index) => {
      if (each?.is_mandatory && (getAnswerStat(each, index))) {
        if (each?.section !== "") {
          valid[each?.section]?.map((x) => {
            if (each?.id === x?.id) {
              x.mandatory = true;
              return x
            }
          })
        } else {
          validate = true;
          valid[index].mandatory = true;
        }
      }
    });

    if (curAssess?.compulsory) {
      let notSubmitted = userInput?.questions?.filter((obj, index) => {
        let attempt = obj?.option_id?.filter((val) => {
          if (val?.remark_text === "") {
            return true;
          }
        })
        if (attempt?.length > 0 && questions[index]?.options?.length > 0) {
          return true;
        }
      });
      if ((curAssess?.compulsory && notSubmitted?.length > 0) || (!remarkFilled)) {
        toast.error("Kindly attempt all questions.");
        validate = true;
      }
    } else {
      if (check > 0) {
        toast.error("Kindly attempt Slider Question.")
      }
    }
    setValidation(valid)
    setUserInput(userSubmitRes)
    myRef.current.scrollIntoView()
    return {
      valid: validate,
      data: userSubmitRes
    };
  }

  function goNext(isDraft) {
    if (
      userInput?.questions.length <= 0 ||
      !userMailToken.userMail ||
      !userMailToken.userToken
    ) {
      return;
    } else {
      let { valid, data } = handleValidate()
      if (valid && !isDraft) {
        return;
      } else {
        if (condition == "true") {
          condition = 'false'
          let queryBody = data;
          queryBody.is_draft = isDraft;
          queryBody.contact = `${countryCode?.value}-${queryBody?.contact}`
          queryBody.duration = moment.duration((duration), 'seconds').asMinutes().toFixed(2)
          // queryBody.survey_id=asmntData.id
          console.log(`sending `, queryBody);
          dispatch(setDisplayLoader('Display'))
          surveyService
            .postExternalSurveyResponses(userMailToken, queryBody)
            .then((response) => {
              condition = "true";
              dispatch(setDisplayLoader('Hide'))
              console.log("viewing.,", response?.data?.dataList?.result);
              if (!isDraft) {
                toast.success("Response submitted successfully.");
                setSubmitNotifier(true);
              }
            })
            .catch((error) => {
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                "failed"
              );
            });
        }
      }
    }
  }

  // useEffect(() => {
  //   setInterval(() => {
  //     goNext(true);
  //   }, 1000 * 60 * 25)
  // }, [])

  if (curAssess?.status === "Submitted" || submittedNotifier) {
    return (
      <div class="row justify-content-center">
        <div className="mt-5" disabled style={{
          height: '400px',
          width: (isAndroid || isIOS) ? '300px' : '600px',
          border: '0.5px solid black',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '10%',
          textAlign: 'center',
        }}
        >
          <h4 >{submittedNotifier ? 'Thank you for your feedback. You can now close the browser.'
            : curAssess?.status === "Submitted" ? "You've already taken this Survey, thank you for your response." : ""}</h4>
          <button
            onClick={() => window.close()}
            class="btn btn-outline-danger mr-2"
          >
            Close
          </button>
          <button class="btn btn-outline-primary mr-2" onClick={() => {
            navigate("/extSurveyResponse", {
              state: {
                targetAsmnt: {
                  id: curAssess?.id,
                  type_id: "",
                  user_id: userMailToken?.userMail,
                  token: userMailToken?.userToken,
                  type_internal_external: 'External'
                },
                view: "true",
                flag: true,
                pdf: true
              },
            })
          }}>Print Response</button>
        </div>
      </div>
    )
  } else if (curAssess?.status === "Expired") {
    return (
      <div class="row justify-content-center">
        <div className="mt-5" disabled style={{
          height: '400px',
          width: (isAndroid || isIOS) ? '300px' : '600px',
          border: '0.5px solid black',
          borderRadius: '10px',
          padding: '20px',
          paddingTop: '10%',
          textAlign: 'center',
        }}
        >
          <h4 >This survey has expired. Kindly close this window.</h4>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container">
        <div class="row justify-content-center">
          {/* <SelectedTypeUser current={targetAsmnt.current}/> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner pt-0 border">
              <div class="innerheadsec p-0">
                <div className="row">
                  {(curAssess?.banner?.url !== "" && curAssess?.imgType === "Banner") ? (
                    <div class="col-12">
                      <img src={curAssess?.banner?.url} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="row surveyviewquestions">
                  <div class="col-12" ref={myRef}>
                    <h3 className="policyhead policyheadasssubhead text-center">
                      <div className="row align-item-center">
                        {curAssess?.banner?.url !== "" && curAssess?.imgType === "Logo" && (
                          <div className="col-sm-2 text-center text-sm-center">
                            <img src={curAssess?.banner?.url} class="mob-logoass" />
                          </div>
                        )}
                        <div className={curAssess?.banner?.url === "" ? "col-sm-12 survey-title-web" : "col-sm-10 survey-title-web"}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: curAssess?.title,
                            }}
                          ></span>
                        </div>
                      </div>
                    </h3>
                    <div
                      className="m-2"
                      dangerouslySetInnerHTML={{ __html: curAssess?.description }}
                    ></div>
                    {curAssess?.user_details === true ? (
                      <>
                        <h3 className="policyhead policyheadasssubhead">
                          Personal Details
                        </h3>
                        <div className="mx-sm-3 mt-sm-3 mx-2 mt-2">
                          <div class="row viewformaftersubmit">
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Company Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="company"
                                  style={{
                                    border: formError?.company && '0.5px solid red'
                                  }}
                                  onChange={(e) => {
                                    setUserInput((prev) => ({
                                      ...prev,
                                      companyName: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      company: false,
                                    }));
                                  }}
                                  value={userInput?.companyName}
                                />
                                {formError?.company && (
                                  <div className="small text-danger">
                                    Enter Company Name
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Name of the Person</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  style={{
                                    border: formError?.name && '0.5px solid red'
                                  }}
                                  onChange={(e) => {
                                    setUserInput((prev) => ({
                                      ...prev,
                                      name: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      name: false,
                                    }));
                                  }}
                                  value={userInput?.name}
                                />
                                {formError?.name && (
                                  <div className="small text-danger">
                                    Enter Name
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Designation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="designation"
                                  style={{
                                    border: formError?.designation && '0.5px solid red'
                                  }}
                                  onChange={(e) => {
                                    setUserInput((prev) => ({
                                      ...prev,
                                      designation: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      designation: false,
                                    }));
                                  }}
                                  value={userInput?.designation}
                                />
                                {formError?.designation && (
                                  <div className="small text-danger">
                                    Enter Designation
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <div class="form-group innergroup">
                                <label>Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  style={{
                                    border: formError?.email && '0.5px solid red'
                                  }}
                                  onChange={(e) => {
                                    setUserInput((prev) => ({
                                      ...prev,
                                      email: e.target.value,
                                    }));
                                    setFormError((prev) => ({
                                      ...prev,
                                      email: false
                                    }))
                                  }}
                                  value={userInput?.email}
                                />
                                {formError?.email && (
                                  <div className="small text-danger">
                                    Enter Email
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-sm-8 col-lg-5">
                              <div class="form-group innergroup">
                                <label>Contact No. (Country code + Telephone Number)</label>
                                <div className="mobile-country-drop">
                                  <div className="row">
                                    <div className="col-4 pr-0">
                                      <Select
                                        name="country__code"
                                        options={countryDataP}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="+91"
                                        onChange={(e) => {
                                          setCountryCode(e)
                                        }}
                                        value={countryCode}
                                      />
                                    </div>
                                    <div className="col-8 pl-0">
                                      <input
                                        type="number"
                                        className="form-control nonumbdrop"
                                        placeholder="Enter Mobile No."
                                        name="personal_mobile"
                                        maxLength={30}
                                        onKeyDown={(evt) =>
                                          evt.key === "e" && evt.preventDefault()
                                        }
                                        onInput={(e) =>
                                        (e.target.value = e.target.value.slice(
                                          0,
                                          10
                                        ))
                                        }
                                        onChange={(e) => {
                                          setUserInput((prev) => ({
                                            ...prev,
                                            contact: e.target.value
                                          }));
                                          setFormError((prev) => ({
                                            ...prev,
                                            contact: false
                                          }))
                                        }}
                                        value={userInput?.contact}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {formError?.contact && (
                                  <div className="small text-danger">
                                    Enter Contact Number
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div class="border-top"></div>
              <div class="p-sm-3 p-2">
                {Object.keys(asmntData)?.length > 0 &&
                  Object.keys(asmntData)?.map((qItem, idx) => {
                    return (
                      <div class="quesionBoxbig">
                        {qItem !== "questions" && (
                          <>
                            <h4 class="assess-title">
                              <div dangerouslySetInnerHTML={{
                                __html: qItem
                              }}></div>
                            </h4>

                          </>
                        )}
                        {asmntData[qItem]?.map((each, i) => {
                          if (each?.multiOption) {
                            return (
                              <>
                                <MultiOptionSurveyView
                                  question={each}
                                  prevQues={i === 0 ? null : asmntData[qItem][i - 1]}
                                  curAssess={curAssess}
                                  view={"false"}
                                  index={i}
                                  idx={idx}
                                  secDesc={secDesc}
                                  qItem={qItem}
                                  updateLocalResponse={updateLocalResponse}
                                  userInput={userInput}
                                  valid={validation}
                                />
                                {/* {((qItem === "questions" && validation[i]?.mandatory) || (qItem !== "questions") && validation[qItem][i]?.mandatory) ? <span class="text-danger" style={{
                                  fontSize: '0.8rem',
                                }}>*Kindly attempt this question</span> : ""} */}

                              </>
                            )
                          } else {
                            return (
                              <>
                                {each?.option_type === "" ? (
                                  <div className="row quesionBoxbignewrow">
                                    {i !== 0 && (
                                      <div className="col-md-12 mt-4">
                                        <hr />
                                      </div>
                                    )}
                                    <div className="col-md-12 d-flex mt-3">
                                      {curAssess?.allowSequence && `(${i + 1}.) `} {each?.is_mandatory && (<span className="text-danger">*</span>)}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: each?.question,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row g-0">
                                    {i === 0 && (
                                      <div class="col-md-12" dangerouslySetInnerHTML={{
                                        __html: secDesc[idx]
                                      }}></div>
                                    )}
                                    <div class={`col-md-${each?.quesWidth} question-boxpollsurvey`} >
                                      <div className="row">
                                        <div className="col-md-12 d-flex">
                                          {curAssess?.allowSequence && `(${i + 1}.) `}&nbsp;&nbsp; {each?.is_mandatory && (<span className="text-danger">*</span>)}
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: each?.question,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                      {each?.option_type === "txt" ? (
                                        each?.is_dropdown ? (
                                          <div class="col-md-4">
                                            <Select
                                              isDisabled={
                                                curAssess.permission_type === "SV" &&
                                                curAssess?.status === "Submitted"
                                              }
                                              defaultValue={
                                                each?.options
                                                  ?.filter((x) => {
                                                    if (
                                                      x?.option === each?.default_option
                                                    ) {
                                                      return true;
                                                    }
                                                  })
                                                  .map((each, index) => {
                                                    return {
                                                      label: each?.option,
                                                      value: each?.id,
                                                      key: index,
                                                    };
                                                  })[0]
                                              }
                                              options={each?.options?.map(
                                                (each, index) => {
                                                  return {
                                                    label: each?.option,
                                                    value: each?.id,
                                                    key: index,
                                                  };
                                                }
                                              )}
                                              onChange={(e) => {
                                                updateLocalResponse(
                                                  {
                                                    target: {
                                                      value: e?.value,
                                                    },
                                                  },
                                                  e?.index,
                                                  each.id,
                                                  e.value,
                                                  each.type
                                                );
                                                setDropDownVal(e);
                                              }}
                                              value={
                                                curAssess.permission_type === "SV" &&
                                                  curAssess?.status === "Submitted"
                                                  ? each?.options
                                                    ?.filter((x) => {
                                                      if (
                                                        x?.option ===
                                                        each?.answers[0]
                                                          ?.answer_options
                                                      ) {
                                                        return true;
                                                      }
                                                    })
                                                    .map((each, index) => {
                                                      return {
                                                        label: each?.option,
                                                        value: each?.id,
                                                        key: index,
                                                      };
                                                    })[0]
                                                  : each?.options
                                                    ?.filter((x) => {
                                                      if (
                                                        x?.option ===
                                                        each?.answers[0]
                                                          ?.answer_options
                                                      ) {
                                                        return true;
                                                      }
                                                    })
                                                    .map((each, index) => {
                                                      return {
                                                        label: each?.option,
                                                        value: each?.id,
                                                        key: index,
                                                      };
                                                    })[0]
                                              }
                                            />
                                          </div>
                                        ) : (
                                          each?.options.map((optItem, iddx) => (
                                            <div
                                            // class={
                                            //   [
                                            //     "file",
                                            //     "Rating",
                                            //     "Short Answer",
                                            //     "Long Answer",
                                            //     "Date"
                                            //   ].includes(each.type) === false
                                            //     ? `col-md-${optItem?.optWidth}`
                                            //     : "col-md-12"
                                            // }
                                            >
                                              {each.type === "Rating" ? (
                                                <div>
                                                  <div class="ratingpoll d-inline-block">
                                                    {Array.apply(null, {
                                                      length: optItem.max,
                                                    }).map((e, i) => (
                                                      <label class="logCheck d-inline-block">
                                                        {each?.answers.length > 0 ? (
                                                          each?.answers?.map(
                                                            (anitem, anidx) =>
                                                              Number(`${i + 1}`) <=
                                                                Number(
                                                                  anitem?.remark_text
                                                                ) ? (
                                                                <input
                                                                  checked
                                                                  className="checkboxstart"
                                                                  ques={each?.id}
                                                                  disabled={
                                                                    curAssess.permission_type ===
                                                                    "SV" &&
                                                                    curAssess?.status ===
                                                                    "Submitted"
                                                                  }
                                                                  type="checkbox"
                                                                  value={`${i + 1}`}
                                                                  onChange={(e) =>
                                                                    updateLocalResponse(
                                                                      e,
                                                                      i,
                                                                      each.id,
                                                                      optItem.id,
                                                                      "Rating",
                                                                      qItem
                                                                    )
                                                                  }
                                                                  name="surveyanswer1"
                                                                />
                                                              ) : (
                                                                <input
                                                                  className="checkboxstart"
                                                                  value={`${i + 1}`}
                                                                  ques={each?.id}
                                                                  disabled={
                                                                    curAssess.permission_type ===
                                                                    "SV" &&
                                                                    curAssess?.status ===
                                                                    "Submitted"
                                                                  }
                                                                  type="checkbox"
                                                                  onChange={(e) =>
                                                                    updateLocalResponse(
                                                                      e,
                                                                      i,
                                                                      each.id,
                                                                      optItem.id,
                                                                      each.type
                                                                    )
                                                                  }
                                                                  name="surveyanswer1"
                                                                />
                                                              )
                                                          )
                                                        ) : (
                                                          <input
                                                            value={`${i + 1}`}
                                                            className="checkboxstart"
                                                            type="checkbox"
                                                            name="surveyanswer1"
                                                            ques={each?.id}
                                                            onChange={(e) =>
                                                              updateLocalResponse(
                                                                e,
                                                                i,
                                                                each.id,
                                                                optItem.id,
                                                                each.type
                                                              )
                                                            }
                                                          />
                                                        )}

                                                        <span class="checkmark"></span>
                                                      </label>
                                                    ))}

                                                    <div class="d-flex justify-content-between mt-2">
                                                      <span>
                                                        1<br />
                                                        Poor
                                                      </span>
                                                      <span class="mr-2 pr-1 text-right">
                                                        {optItem.max}
                                                        <br />
                                                        Excellent
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : each.type === "Short Answer" ? (
                                                <div className="row">
                                                  <div className="col-12">
                                                    <div class="innergroup">
                                                      <input
                                                        disabled={
                                                          curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status === "Submitted"
                                                        }
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Please give your short Answer"
                                                        minLength={optItem.min}
                                                        maxLength={optItem.max}
                                                        value={
                                                          each?.answers[0]?.remark_text
                                                        }
                                                        onChange={(e) =>
                                                          updateLocalResponse(
                                                            e,
                                                            idx,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <br />
                                                </div>
                                              ) : each?.type === "Long Answer" ? (
                                                iddx === 0 ? (
                                                  <div class="answerboxpollsuy innergroup">
                                                    {curAssess.permission_type ===
                                                      "SV" &&
                                                      curAssess?.status ===
                                                      "Submitted" ? (
                                                      <div
                                                        class="col-md-12"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            each?.answers[0]
                                                              ?.remark_text,
                                                        }}
                                                      ></div>
                                                    ) : (
                                                      <CKEditor
                                                        editor={ClassicEditor}
                                                        name="question"
                                                        onReady={(editor) => {
                                                          console.log(
                                                            "Editor is ready to use!",
                                                            editor
                                                          );
                                                        }}
                                                        data={
                                                          each?.answers[0]?.remark_text
                                                        }
                                                        onChange={(event, editor) => {
                                                          const data = editor.getData();
                                                          updateLocalResponse(
                                                            {
                                                              currentTarget: {
                                                                value: data,
                                                              },
                                                            },
                                                            idx,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              ) : each?.type === "Date" ? (
                                                <div class="innergroup mb-2">
                                                  <input
                                                    disabled={
                                                      (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                        "Submitted")
                                                    }
                                                    type="date"
                                                    class="form-control"
                                                    value={
                                                      moment(each?.answers[0]?.remark_text).format('YYYY-MM-DD')
                                                    }
                                                    onChange={(e) =>
                                                      updateLocalResponse(
                                                        e,
                                                        idx,
                                                        each.id,
                                                        optItem.id,
                                                        "Rating",
                                                        qItem
                                                      )
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div class="answerboxpollsuy innergroup text-center" style={{
                                                  background: 'none',
                                                }}>
                                                  {/* <span>
                                                {String.fromCharCode(65 + iddx)}.
                                              </span> */}
                                                  {(i === 0 || (asmntData[qItem][i - 1]?.options[iddx]?.option !== optItem?.option)) &&
                                                    <div className="text-center">
                                                      {optItem.option}
                                                    </div>
                                                  }

                                                  <label class="logCheck d-inline-block">

                                                    {each?.answers?.length > 0 ? (
                                                      each?.answers?.map(
                                                        (anitem, anidx) =>
                                                          optItem.option ===
                                                            anitem?.answer_options ? (
                                                            <input
                                                              disabled={
                                                                curAssess.permission_type ===
                                                                "SV" &&
                                                                curAssess?.status ===
                                                                "Submitted"
                                                              }
                                                              checked
                                                              value={"demo"}
                                                              onChange={(e) =>
                                                                updateLocalResponse(
                                                                  e,
                                                                  idx,
                                                                  each.id,
                                                                  optItem.id,
                                                                  each.type
                                                                )
                                                              }
                                                              type="radio"
                                                              name={
                                                                each.type ===
                                                                  "Multiple Choice"
                                                                  ? `surveyanswer${each.id}${optItem.id}`
                                                                  : `surveyanswer${each.id}`
                                                              }
                                                            />
                                                          ) : (
                                                            <input
                                                              disabled={
                                                                curAssess.permission_type ===
                                                                "SV" &&
                                                                curAssess?.status ===
                                                                "Submitted"
                                                              }
                                                              value={"demo"}
                                                              onChange={(e) =>
                                                                updateLocalResponse(
                                                                  e,
                                                                  idx,
                                                                  each.id,
                                                                  optItem.id,
                                                                  each.type
                                                                )
                                                              }
                                                              type="radio"
                                                              name={
                                                                each.type ===
                                                                  "Multiple Choice"
                                                                  ? `surveyanswer${each.id}${optItem.id}`
                                                                  : `surveyanswer${each.id}`
                                                              }
                                                            />
                                                          )
                                                      )
                                                    ) : (
                                                      <input
                                                        value={"demo"}
                                                        disabled={
                                                          curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status ===
                                                          "Submitted"
                                                        }
                                                        onChange={(e) =>
                                                          updateLocalResponse(
                                                            e,
                                                            idx,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          )
                                                        }
                                                        type="radio"
                                                        name={
                                                          each.type ===
                                                            "Multiple Choice"
                                                            ? `surveyanswer${each.id}${optItem.id}`
                                                            : `surveyanswer${each.id}`
                                                        }
                                                      />
                                                    )}

                                                    <span class="checkmark"></span>
                                                  </label>
                                                  {(each?.select_feedback && each?.select_feedback_option === optItem?.option && isFeedback.includes(optItem?.id)) && (
                                                    <div class="mt-4 innergroup" style={{
                                                      border: '0.5px solid grey',
                                                      borderRadius: '10px'
                                                    }}>
                                                      <textarea style={{ height: "40px", fontWeight: 'normal', border: '1px solid darkgrey', }} maxLength={150} name='RatingFeedback' className="form-control" placeholder="Remarks" onChange={(e) => {
                                                        updateLocalResponse(
                                                          e,
                                                          iddx,
                                                          each.id,
                                                          optItem?.id,
                                                          "Single"
                                                        )
                                                      }}
                                                      //   value={question?.answers[iddx]?.feedback}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          ))
                                        )
                                      ) : each?.option_type === "file" ? (
                                        each?.options.map((optItem, iddx) => (
                                          // <div class="col-md-12">
                                          //   <div class="answerboxpollsuy innergroup">
                                          //     <span>A.</span>
                                          //     <label class="logCheck d-inline-block">
                                          //       {optItem.option}
                                          //       <input type="radio" name="surveyanswer" />
                                          //       <span class="checkmark"></span>
                                          //     </label>
                                          //   </div>
                                          // </div>

                                          // <div class="col-md-3">
                                          // <label class="logCheck d-inline-block">
                                          //   {/* <div class="mb-1">Lorem Ipsum is simply</div> */}
                                          //   <img src= {optItem.option} class="border" />
                                          //   <input disabled={curAssess.permission_type ==="SV"&& curAssess?.status==="Submitted"} type="radio" name="surveyanswer1" />
                                          //   <span class="checkmark"></span>
                                          // </label>
                                          // </div>

                                          <div class="col-md-3">
                                            <div class="answerboxpollsuy innergroup">
                                              <span>
                                                {String.fromCharCode(65 + iddx)}.
                                              </span>

                                              <label class="logCheck d-inline-block">
                                                <img
                                                  src={optItem.option}
                                                  class="border"
                                                />

                                                {each?.answers?.length > 0 ? (
                                                  each?.answers?.map((anitem, anidx) =>
                                                    optItem.option ===
                                                      anitem?.answer_options ? (
                                                      <input
                                                        disabled={
                                                          curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status ===
                                                          "Submitted"
                                                        }
                                                        checked
                                                        value={"demo"}
                                                        onChange={(e) =>
                                                          updateLocalResponse(
                                                            e,
                                                            idx,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          )
                                                        }
                                                        type="radio"
                                                        name={
                                                          each.type ===
                                                            "Multiple Choice"
                                                            ? `surveyanswer${each.id}${optItem.id}`
                                                            : `surveyanswer${each.id}`
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        disabled={
                                                          curAssess.permission_type ===
                                                          "SV" &&
                                                          curAssess?.status ===
                                                          "Submitted"
                                                        }
                                                        value={"demo"}
                                                        onChange={(e) =>
                                                          updateLocalResponse(
                                                            e,
                                                            idx,
                                                            each.id,
                                                            optItem.id,
                                                            each.type
                                                          )
                                                        }
                                                        type="radio"
                                                        name={
                                                          each.type ===
                                                            "Multiple Choice"
                                                            ? `surveyanswer${each.id}${optItem.id}`
                                                            : `surveyanswer${each.id}`
                                                        }
                                                      />
                                                    )
                                                  )
                                                ) : (
                                                  <input
                                                    value={"demo"}
                                                    disabled={
                                                      curAssess.permission_type ===
                                                      "SV" &&
                                                      curAssess?.status === "Submitted"
                                                    }
                                                    onChange={(e) =>
                                                      updateLocalResponse(
                                                        e,
                                                        idx,
                                                        each.id,
                                                        optItem.id,
                                                        each.type
                                                      )
                                                    }
                                                    type="radio"
                                                    name={
                                                      each.type === "Multiple Choice"
                                                        ? `surveyanswer${each.id}${optItem.id}`
                                                        : `surveyanswer${each.id}`
                                                    }
                                                  />
                                                )}

                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          </div>
                                        ))
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                )}
                                {((qItem === "questions" && validation[i]?.mandatory) || (qItem !== "questions") && validation[qItem][i]?.mandatory) ? <span class="text-danger ml-1">*Kindly attemp this question</span> : ""}
                                {asmntData[qItem]?.length > 1 &&
                                  i !== asmntData[qItem]?.length - 1 && each?.option_type !== "" && <hr />}
                              </>
                            );
                          }
                        })}
                        {/* {Object.keys(asmntData)?.length > 1 &&
                          idx !== Object.keys(asmntData)?.length - 1 && <hr />} */}
                      </div>
                    );
                  })}
              </div>

              <div class="border-top mb-3 mt-4 mt-sm-5"></div>
              {!isLoading && (
                <div class="innerheadsec">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button
                        onClick={() => window.close()}
                        class="btn btn-outline-danger mr-2"
                      >
                        Close
                      </button>
                      {curAssess?.status !== "Submitted" &&
                        curAssess?.user_view_type !== "full" ? (
                        <button class="btn btn-outline-secondary mr-2">
                          <i class="far fa-arrow-alt-circle-left"></i> Previous
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={() => goNext(false)}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-arrow-alt-circle-right"></i>{" "}
                        {curAssess?.user_view_type === "full"
                          ? "Submit"
                          : "Next/Submit"}
                      </button>

                      {asmntData?.compulsory === false && (
                        <button
                          onClick={() => goNext(true)}
                          class="ml-2 btn btn-primary-inner bpi-main"
                        >
                          <i class="far fa-arrow-alt-circle-right"></i> Skip
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div >
        </div >
      </div>
    );
  }
}

export default ViewExternalassess;
