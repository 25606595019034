import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import * as CreateSkillService from "../../service/createSkill";
import { injectStyle } from "react-toastify/dist/inject-style";
import ViewSkill from "../SkillMaster/ViewSkill";
import { object } from "yup";
import moment from "moment";
if (typeof window !== "undefined") {
  injectStyle();
}

const ReviewSkill = (props) => {
  const [error, setError] = useState(false);

  const { onAddCallBack, isDisplay } = props;
  const keyData = {
    id: 0,
    skill_id: 0,
    employee_id: 0,
    current_level: "",
    planned_level: "",
    actual_level: "",
    status: "",
    reason: "",
    other: "",
    remarks: "",
    quarter: "",
    year: "",
  };
  const [planData, setPlanData] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [reviewData, setReviewData] = useState({
    id: 0,
    skill_id: 0,
    employee_id: 0,
    current_level: "",
    planned_level: "",
    actual_level: "",
    status: "",
    reason: "",
    other: "",
    remarks: "",
    quarter: isDisplay.quarter,
    year: isDisplay.year,
  });

  const getSKillPlan = (sort_by, planId) => {
    CreateSkillService.getSKillPlan(sort_by, planId, "review")
      .then((response) => {
        let obj = response?.data?.dataList?.result.map((x) => {
          return {
            ...x,
            quarter: isDisplay.quarter,
            year: isDisplay.year,
          };
        });
        setPlanData(obj);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const validatereasons = () => {
    let finalData = planData.map((obj) => {
      let curObj = {};
      Object.keys(obj).map((x) => {
        if (Object.keys(keyData).includes(x)) {
          curObj[x] = obj[x];
        }
      });
      return curObj;
    });
    let count = 0;
    finalData.map((obj) => {
      if (
        (obj.status === "planning" || obj.status === "incomplete") &&
        (obj?.reason == null || obj?.reason == "")
      )
        count = count + 1;
    });
    if (count != 0) return false;
    else return true;
  };
  const updateReviewSkill = () => {
    let data = {};
    let finalData = planData.map((obj) => {
      let curObj = {};
      Object.keys(obj).map((x) => {
        if (Object.keys(keyData).includes(x)) {
          curObj[x] = obj[x];
        }
      });
      return curObj;
    });
    data["payload"] = finalData;
    data["remarks"] = reviewData.remarks;
    data["action"] = "review"
    data["plan_id"] = isDisplay.plan
    if (validatereasons()) {
      CreateSkillService.updateReviewSkill(data)
        .then((response) => {
          if (response.data.status === true && response.data.code === 200) {
            toast.success(response.data.message);
            getSKillPlan("id", isDisplay?.plan);
            onAddCallBack("Status");
            setReviewData({
              ...reviewData,
              remarks: "",
            });
          }
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    }
  };

  const onView = async (data) => {
    const response = await CreateSkillService.getSkillById(data.skill_Id)
    setViewForm(true);
    setViewFormData(response.data.dataList.result[0]);
  };

  const onViewClose = () => {
    setViewForm(false);
  };

  const handleValidate = () => {
    if (reviewData?.remarks?.length == 0) {
      setError(true);
    } else {
      updateReviewSkill();
    }
  };

  const changeData = (value, name, index) => {
    let data = JSON.parse(JSON.stringify(planData));
    data[index][`${name}`] = value;
    setPlanData(data);
  };

  useEffect(() => {
    if (isDisplay?.plan !== undefined) {
      getSKillPlan("id", isDisplay?.plan);
    }
  }, [isDisplay]);

  return (
    <>
      {isDisplay?.is_review ? (
        <>
          <div className="px-4 pb-2">
            <h5 className="text-dark fa-20 border-bottom pb-2 my-3">
              <strong>
                Review Skill Plan for Quarter:{" "}
                {isDisplay?.quarter?.replace("-", " to ")} {isDisplay?.year}
              </strong>
            </h5>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-bordered tablecured viewpo innergroup leavetableapply vehiclelog">
              <thead>
                <tr>
                  <th>Skill</th>
                  <th>Skill Description</th>
                  <th>Employee Code</th>
                  <th>Current Level</th>
                  <th>Planned Level</th>
                  <th>Actual Level</th>
                  <th>Status</th>
                  <th>Reason</th>
                  <th>Other</th>
                  <th>Updated At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {planData?.map((data, index) => (
                  <>
                    <tr key={`plan${index}`}>
                      <td>{data?.skill_Id}</td>
                      <td>{data?.skill_description}</td>
                      <td>
                        {data?.employee_code}- {data?.employee_name}
                      </td>
                      <td> {data?.current_level}</td>
                      <td>{data?.planned_level}</td>
                      <td>
                        <select
                          className="form-control newbgselect"
                          name="actual_level"
                          onChange={(e) => {
                            changeData(e.target.value, e.target.name, index);
                          }}
                          value={planData[index]?.actual_level}
                        >
                          <option value="">Select</option>
                          <option value={data?.current_level}>{data?.current_level}</option>
                          <option value={data?.planned_level}>{data?.planned_level}</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="form-control newbgselect"
                          name="status"
                          onChange={(e) => {
                            changeData(e.target.value, e.target.name, index);
                          }}
                          value={planData[index]?.status}
                        >
                          <option value="">Select</option>
                          <option value="planning">planning</option>
                          <option value="incomplete">incomplete</option>
                          <option value="complete">complete</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          maxLength={150}
                          name="reason"
                          className="form-control"
                          placeholder="Reason"
                          onChange={(e) => {
                            changeData(e.target.value, e.target.name, index);
                          }}
                          value={planData[index]?.reason}
                        />
                        <div className="small text-danger">
                          {planData[index]?.status != "" &&
                            (planData[index]?.status == "incomplete" ||
                              planData[index]?.status == "planning") &&
                            (planData[index]?.reason == null ||
                              planData[index]?.reason == "")
                            ? "Reason is required"
                            : ""}
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Other"
                          maxLength={150}
                          name="other"
                          onChange={(e) => {
                            changeData(e.target.value, e.target.name, index);
                          }}
                          value={planData[index]?.other}
                        />
                      </td>
                      <td>
                        {moment
                          .utc(data?.timestamp)
                          .format("Do MMM YYYY, H:mm:ss A")}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-blanktd text-primary"
                          onClick={() => {
                            onView(data);
                          }}
                        >
                          <img src="images/eye.png" alt="" />
                        </button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {
            planData.length!=0 && planData[0].approval_status == "rejected" ?
              <table className="table table-striped table-bordered tablecured viewpo innergroup leavetableapply vehiclelog">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>

                  <tr key={`reject01`}>
                    <td>Rejected</td>
                    <td>{planData[0].approval_remark}</td>
                    <td>
                      {moment
                        .utc(planData[0]?.timestamp)
                        .format("Do MMM YYYY, H:mm:ss A")}
                    </td>
                  </tr>
                </tbody>
              </table>
              :
              <></>
          }
          {planData?.length !== 0 ? (
            <>
              <div className="px-4 pb-2">
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group innergroup">
                        <label>
                          Remarks<span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Please provide consolidated remarks for review..."
                          maxLength={150}
                          name="remarks"
                          onChange={(e) => {
                            setError(false);
                            console.log(
                              "e.target.value",
                              e.target.value.length
                            );
                            setReviewData({
                              ...reviewData,
                              remarks: e.target.value.trimStart(),
                            });
                          }}
                          value={reviewData?.remarks}
                        ></textarea>
                        {error && (
                          <div className="small text-danger">
                            *Remark is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                      <button
                        className="btn btn-primary-inner bpi-main"
                        type="submit"
                        onClick={() => handleValidate()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <p style={{ color: "#abaaa6", marginTop: "47px", marginLeft: "25px" }}>
          Review skill plan is not open yet.
        </p>
      )
      }
      {
        viewForm ? (
          <>
            <ViewSkill
              viewForm={viewForm}
              onViewClose={onViewClose}
              viewFormData={viewFormData}
            />
          </>
        ) : (
          ""
        )
      }
    </>
  );
};

export default ReviewSkill;
