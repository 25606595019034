import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import {
  Tabs,
  Tab,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import * as PrService from "../../service/prService";
import {
  changestatus,
  getRequests,
  get_HSN,
} from "../../service/materialService";
import ViewSimilarMat from "./view-similar-mat-data";
function AppMaterReq() {
  const [error, seterror] = useState(false);
  const location = useLocation();
  const userData = useSelector(selectUserData);
  const [material_id, setid] = useState(location?.state?.from);
  const [materialCodeOptions, setMaterialCodeOptions] = useState([]);
  const [hsnData, setHSNData] = useState([]);
  const [dupCode, setDupCode] = useState("N");
  const [dupCodeFlag, setDupCodeFlag] = useState(false);
  const [statusData, setstatusData] = useState({
    status: "",
    remarks: "",
    material_id: material_id,
  });
  const [unitOptions, setUnitOptions] = useState([]);
  const [similarMatData, setSimilarMatData] = useState([]);
  const [matDataFlag, setMatDataFlag] = useState(false);
  const [hsnCode, setHSNCode] = useState("");
  const [UnitCode, setUnit] = useState("");
  // const [addDesc, setAddDesc] = useState("");
  const [casNo, setCasNo] = useState("");
  const [materialCode, setMaterialCode] = useState("");

  const [hsnCodeError, setHSNError] = useState(false);
  const [UnitCodeError, setUnitError] = useState(false);
  // const [addDescError, setAddDescError] = useState(false);
  const [casNoError, setCasNoError] = useState(false);
  const [materialCodeError, setMaterialCodeError] = useState(false);

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [list, setList] = useState([]);

  const handleKey = (search, id, page_no, page_size, sort_by, paginate, approvePage) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (id !== 0 && id !== undefined && id !== null) {
      queryParm = queryParm + "&material_id=" + id;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (approvePage !== "" && approvePage !== undefined && approvePage !== null) {
      queryParm = queryParm + "&approvePage=" + approvePage;
    }
    return queryParm;
  };
  const getData = async (search, id, page_no, page_size, sort_by, paginate, approvePage) => {
    await getRequests(
      handleKey(search, id, page_no, page_size, sort_by, paginate, approvePage)
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data=response?.data?.dataList?.result[0]
          data.hsn_code={value:"",label:data.hsn_code}
          data.unit={value:"",label:data.unit}
          data.dubCode=data?.dub_mat_code==null?"N":"Y"
          setList(data);
          if (data?.dub_mat_code!=null ){
            data.dub_mat_code={value:"",label:data.dub_mat_code}
            setDupCodeFlag(true)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData("", location?.state?.from, 1, 10, "id", true, true);
    getHSN();
    getMaterialCode();
    getPrUnits()
  }, []);

  const getHSN = () => {
    get_HSN().then((response) => {
      let obj = response?.data?.dataList?.result?.map((res) => {
        return {
          value: res?.id,
          label: res?.code,
        };
      });
      setHSNData(obj);
    });
  };

  const getMaterialCode = (search) => {
    PrService.getMaterialListByPlant({ plant_id: 0, search: search }).then(
      (response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options2 = list.map((item) => ({
            value: item?.id,
            label: `${item?.description}(${item.code})`,
            code: item?.code,
            material_type: item?.material_type,
          }));
          // setMaterialGroupOptions(options);
          setMaterialCodeOptions(options2);
        }
      }
    );
  };
const validate=()=>{
  if (statusData.status == "A"){
    if (dupCode=="N"){
      if (list?.hsn_code?.label == "" || list?.hsn_code?.label == null || list?.cas_no == "") {
        setHSNError(list?.hsn_code?.label == "" || list?.hsn_code?.label == null ? true : false);
        setCasNoError(list?.cas_no == "" ? true : false);
        return false
    }
    // else if( hsnCode == "" || casNo == ""){
    //   setHSNError(hsnCode == "" ? true : false);
    //   setCasNoError(casNo == "" ? true : false);
    //   return false
    // }
  }
  else{
    if (list?.dub_mat_code?.label==null){
      setMaterialCodeError(list?.dub_mat_code?.label == null ? true : false);
      return false
    }
   
  //    if (materialCode == ""){
  //     setMaterialCodeError(materialCode == "" ? true : false);
  //     return false
  // }else{
  //   return true
  // }
  }
  }
  if (statusData.status == "R"){
    if (dupCode!="N"){
      if ( list?.hsn_code?.label == "" || list?.cas_no == "" ||statusData.remarks == ""){
        seterror(statusData.remarks == ""?true : false);
        setHSNError(list?.hsn_code?.label == "" ? true : false);
        setCasNoError(list?.cas_no == "" ? true : false);
        return false
      }
    }
    else{
      if (list?.dub_mat_code?.label==null){
        setMaterialCodeError(list?.dub_mat_code?.label == null ? true : false);
        return false
      }
  }
  }
  return true
}
  const changeStatus = (status) => {
    statusData.status = status;
    statusData.hsn_code = hsnCode;
    // statusData.tech_desc = techDesc;
    // statusData.add_desc = addDesc;
    statusData.cas_no = casNo;
    statusData.material_code = materialCode;
    statusData.unit = UnitCode;

    if (
     validate()
     
    ) {
      changestatus(statusData)
        .then((response) => {
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } 
  };

  const handleHSNChange = (e) => {
    setList((prev) => ({
      ...prev,
      hsn_code: e,
    }));
    setHSNCode(e);
    setHSNError(false);
  };
  const handleUnit = (e) => {
    setList((prev) => ({
      ...prev,
      unit: e,
    }));
    setUnit(e.label);
  };

  // const handleDescriptionChange = (e, key) => {
  //   let tech_desc = "";
  //   let add_desc = "";
  //   if (key === "technical") {
  //     tech_desc = e.target.value;
  //     setTechDesc(tech_desc);
  //     setTechDescError(false);
  //   }
  //   if (key === "additional") {
  //     add_desc = e.target.value;
  //     setAddDesc(add_desc);
  //     setAddDescError(false);
  //   }
  // };

  const handleCasNoChange = (e) => {
    setList((prev) => ({
          ...prev,
          cas_no: e.target.value,
        }));
    setCasNo(e.target.value);
    setCasNoError(false);
  };

  const handleDuplicateCodeChange = (e) => {
    console.log();
    setList((prev) => ({
      ...prev,
      dubCode: e.target.value,
    }));
    if (e.target.value == "Y") {
      setHSNError(false);
      seterror(false)
      setCasNoError( false);
      setMaterialCodeError(false);
      setDupCodeFlag(true);
    }
    if (e.target.value == "N") {
      setDupCodeFlag(false);
    }
    setDupCode(e.target.value);
   
  };

  const handleMaterialCodeChange = (e) => {
    setList((prev) => ({
      ...prev,
      dub_mat_code: e,
    }));
    setMaterialCode(e.value);
    setMaterialCodeError(false);
  };
  const getPrUnits = () => {
    PrService.getPrUnits("paginate=false")
      .then((response) => {
        if (response.data.code === 200) {
          const list = response?.data?.dataList?.result;
          const options = list.filter((x)=>{if(x?.unit_desc !== null){return true}}).map((item) => ({
            value: item.id,
            label: `${item.unit_desc}(${item.unit_code})`,
          }));
          setUnitOptions(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMatchingMaterial = (mat_desc, search) =>{
    let search_str = ''
    if(search?.length > 0){
      search_str = search
    }
    else{
      search_str=''
    }
      PrService.getMaterialDataByDesc("mat_desc="+mat_desc+"&search="+search_str).then((response)=>{
        setSimilarMatData(response?.data?.dataList?.result)
        setMatDataFlag(true)
      })
  }

  const handleClose = () =>{
    setMatDataFlag(false)
    setSimilarMatData([])
  }

  const handleSearch  = (text) =>{
    let search_str = text
    if(text?.length > 2 || text?.length === 0){
    getMatchingMaterial(list?.description, search_str)
    }
  }

  return (
    <>
      <div class="row">
        {console.log(list)}
        {/* <div class="col-md-3">
          <CustomMenu />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View Material Request Approval</h3>
                </div>
              </div>
              <div class="backleftlist backviewpo">
                {/* <Link to="/mat-approval">
                  <span>
                    <i class="fas fa-arrow-alt-circle-left f-12"></i> Back
                  </span>
                </Link> */}
                <button className="btn btn-link" type="button" onClick={()=>{getMatchingMaterial(list?.description)}}>View Similar Materials</button>
              </div>
            </div>

            <div class="px-4 py-2">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Material Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={list?.description}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Select Plant<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={list?.plant_name}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Industry Sector<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value="C"
                      disabled
                      class="form-control"
                      placeholder="Enter Industry Sector"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Material Group<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={list?.group}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Material Type<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={list?.material_type}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Unit<span class="text-danger">*</span>
                    </label>
                    {/* <input
                      type="text"
                      class="form-control"
                      value={list?.unit}
                      // disabled
                    /> */}
                      <Select
                        isDisabled={false}
                        options={unitOptions}
                        classNamePrefix="select"
                        className="basic-multi-select"
                        onChange={(e) => {
                          handleUnit(e);
                        }}
                        value={list?.unit}
                        // {...getSelectFeilds(
                        //   unitOptions,
                        //   formIndex,
                        //   "pr_unit_id_name"
                        // )}
                      />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Material Category<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect" disabled>
                      <option>Select</option>
                      <option>A</option>
                      <option>B</option>
                      <option selected>C</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Storage Location<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={list?.store_location}
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Valuation Class<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect" disabled>
                      <option value="">Select</option>
                      <option value="8700" selected="selected">
                        8700 - Inv. R&D Material-Domestic
                      </option>
                      <option value="8300">
                        8300 - Inv. Store/Spare-Electrical
                      </option>
                      <option value="8301">
                        8301 - Inv. Store/Spare-Mechanical
                      </option>
                      <option value="8302">
                        8302 - Inv. Store/Spare-Instrument
                      </option>
                    </select>
                  </div>
                </div>
                {/* 
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Include Variation Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option selected>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                </div> */}

                {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Variation Type<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option>Select</option>
                      <option selected>Damaged</option>
                      <option>Good</option>
                      <option>Repaired</option>
                    </select>
                  </div>
                </div> */}

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Reference Material No.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Enter Reference Material No."
                      // value={list?.ref_material}
                      value="76000051"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      HSN Code<span class="text-danger">*</span>
                    </label>
                    {/* <input
                            type="text"
                            class="form-control"
                            placeholder="Enter HSN Code"
                            value={list?.hsn_code}
                          /> */}
                    <Select
                      options={hsnData}
                      classNamePrefix="select"
                      className="basic-multi-select"
                      onChange={(e) => {
                        handleHSNChange(e);
                      }}
                      value={list?.hsn_code}
                    />
                    <div hidden={!hsnCodeError} className="small text-danger">
                      Required
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      CAS No.<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter CAS No."
                      value={list?.cas_no}
                      onChange={(e) => {
                        handleCasNoChange(e);
                      }}
                    />
                    <div hidden={!casNoError} className="small text-danger">
                      Required
                    </div>
                  </div>
                </div>

                {/* <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Technical Description for HSN Code{" "}
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={techDesc}
                      placeholder="Enter Technical Description for HSN Code"
                      onChange={(e) => {
                        handleDescriptionChange(e, "technical");
                      }}
                    />
                    <div hidden={!techDescError} className="small text-danger">
                      Required
                    </div>
                  </div>
                </div> */}

                {/* <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Additional Description<span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Additional Description"
                      value={addDesc}
                      onChange={(e) => {
                        handleDescriptionChange(e, "additional");
                      }}
                    />
                    <div hidden={!addDescError} className="small text-danger">
                      Required
                    </div>
                  </div>
                </div> */}

                {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Serial No. Profile<span class="text-danger">*</span>
                    </label>
                    <select class="form-control newbgselect">
                      <option selected="" value="">
                        -Select-
                      </option>
                      <option value="ZPMA">
                        ZPMA - Administrative Equipment
                      </option>
                      <option value="ZPME">
                        ZPME - Pollution Control Equipment
                      </option>
                      <option value="ZPMF">
                        ZPMF - Fire Fighting Equipment
                      </option>
                      <option value="ZPMK">
                        ZPMK - Instrumentation Equipment
                      </option>
                      <option value="ZPML">ZPML - Electrical Equipment</option>
                      <option value="ZPMP">ZPMP - Process Equipment</option>
                      <option value="ZPMQ">ZPMQ - Test/measur Equipment</option>
                      <option value="ZPMU">ZPMU - Utility Equipment</option>
                    </select>
                  </div>
                </div> */}

                {/* <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Planned Delivery Time in Days{" "}
                   <span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Planned Delivery Time in Days"
                    />
                  </div>
                </div> */}

                <div class="col-md-12">
                  <div>
                    <div class="row">
                      {/* <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            HSN Code<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Enter HSN Code"
                            value={list?.hsn_code}
                          />
                        </div>
                      </div> */}

                      <div class="col-md-4">
                        <div class="form-group innergroup">
                          <label>
                            Duplicate Code<span class="text-danger">*</span>
                          </label>
                          <select
                            class="form-control newbgselect"
                            onChange={(e) => {
                              handleDuplicateCodeChange(e);
                            }}
                            value={list?.dubCode}
                          >
                            <option>Select</option>
                            <option value="Y">Yes</option>
                            <option selected value="N">
                              No
                            </option>
                          </select>
                        </div>
                      </div>

                      {dupCodeFlag && (
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label>
                              Material Code<span class="text-danger">*</span>
                            </label>
                            <Select
                              options={materialCodeOptions}
                              classNamePrefix="select"
                              className="basic-multi-select"
                              onChange={(e) => {
                                handleMaterialCodeChange(e);
                              }}
                              onInputChange={(e) => {
                                getMaterialCode(e);
                              }}
                              value={list?.dub_mat_code}
                            />
                            <div
                              hidden={!materialCodeError}
                              className="small text-danger"
                            >
                              Required
                            </div>
                          </div>
                        </div>
                      )}

                      {list?.status == "P" && (
                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Remarks<span class="text-danger">*</span>
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter Comments"
                              onChange={(e) => {
                                setstatusData((prev) => ({
                                  ...prev,
                                  remarks: e.target.value,
                                }));
                                seterror(false);
                              }}
                              // disabled={
                              //   list?.CureentApprover?.username ==
                              //   userData?.username
                              //     ? false
                              //     : true
                              // }
                            ></textarea>
                            {
                              <div
                                hidden={!error}
                                className="small text-danger"
                              >
                                Kindly give Remarks
                              </div>
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-4">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {list?.status == "P" &&
                    list?.CureentApprover?.username == userData?.username && (
                      <>
                        <button
                          onClick={() => {
                            changeStatus("R");
                          }}
                          class="btn btn-outline-danger mr-3"
                        >
                          <i class="far fa-times-circle"></i> Reject
                        </button>
                        <button
                          onClick={() => {
                            changeStatus("A");
                          }}
                          class="btn btn-primary-inner bpi-main"
                        >
                          <i class="far fa-check-circle"></i> Approve
                        </button>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {matDataFlag && (
        <ViewSimilarMat
        matDataFlag={matDataFlag}
        similarMatData={similarMatData}
        handleClose={handleClose}
        handleSearch={handleSearch}
        />
      )}
    </>
  );
}

export default AppMaterReq;
