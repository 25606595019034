import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Alert, Modal } from "react-bootstrap";
import AddInternational from "./AddInternational";
import * as TravelListingAPI from "../../../service/travelService";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment/moment";
import { selectUserData } from "../../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayLoader } from "../../redux/piDataStore";
import { injectStyle } from "react-toastify/dist/inject-style";
import * as DomesticService from "../../../service/domestic";
import * as TravelSrv from "../../../service/travellist";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from "react-tippy";

if (typeof window !== "undefined") {
  injectStyle();
}

const International = forwardRef((props, ref) => {
  const {
    checkActiveTab,
    condition,
    allGridData,
    allData,
    onViewClose,
    migrate,
    onCallBackTravel,
    onCloseForm,
    mainData,
    userBackDate,
    dataNew,
    managerEditing,
    dynamicUserData,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();
  const [outstandingValues, setOutstandingValues] = useState();
  const [interData, setinterData] = useState([]);
  const [outstandingAmt, setOutstandingAmt] = useState([]);
  const [changeFunc, setChangeFunc] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editInterData, setEditInterData] = useState(false);
  const [formView, setFormView] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [validLastData, setValidLastData] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [error, setError] = useState("");
  const [callGrid, setCallGrid] = useState(false);
  const [changeValueData, setChangeValueData] = useState(false);
  const [detailArr, setDetailArr] = useState([]);
  const [flagDataInter, setFlagDataInter] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [indexValue, setIndexValue] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [advanceAmountShow, setAdvanceAmountShow] = useState(false);
  const [checkAdvanceValidation, setCheckAdvanceValidation] = useState();
  const [timeCheck, setTimeCheck] = useState([]);
  const [daysDiffNo, setDaysDiffNo] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [passportRegexValid, setPassportRegexValid] = useState(false);
  useImperativeHandle(ref, () => ({
    isDataPresent() {
      return interData.length > 0 ? true : false;
    },
  }));

  const getInternationalTemp = () => {
    TravelListingAPI.getDomesticTemp()
      .then((response) => {
        setinterData(response?.data?.dataList?.result);
        setCallGrid(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getOutstandingAmount = (employee_code, travel_id) => {
    DomesticService.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);
        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setUserDetails(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const TravelAdvanceCheck = (data) => {
    TravelSrv.TravelAdvanceCheck(data)
      .then((response) => {
        const data = response?.data?.dataList?.is_exist;
        setCheckAdvanceValidation(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteData = (id) => {
    TravelListingAPI.deleteData(id)
      .then((res) => {
        if (res.data.status === true) {
          getInternationalTemp();
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteDataFromMain = (id) => {
    DomesticService.deleteDataFromMain(id)
      .then((res) => {
        if (res.data.status === true) {
          onCallBackTravel(allData?.id);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleDelete = (id) => {
    let deleteValue = interData?.filter((ele) => {
      if (ele.id !== id) {
        return true;
      }
    });
    setinterData(deleteValue);
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Data",
      message: `Are you sure to delete this record?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            migrate ? handleDelete(id) : deleteData(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const newPayload = interData.map((travelDetail) => {
    const {
      from_city_name,
      from_city_input,
      from_city_id,
      to_city_name,
      to_city_input,
      to_city_id,
    } = travelDetail;

    const fromCity =
      from_city_name === "Other" ? { from_city_input } : { from_city_id };

    const toCity =
      to_city_name === "Other" ? { to_city_input } : { to_city_id };

    return { ...fromCity, ...toCity };
  });

  const addShowRow = () => {
    setDataError(false);
    setFormView(true);
    setEditInterData(null);
    setEdit(false);
  };

  const editData = (data, index) => {
    setEdit(true);
    setEditInterData(data);
    setFormView(true);
    setShowButton(false);
    setIndexValue(true);
  };

  useEffect(() => {
    if (checkActiveTab !== "International") {
      setChangeFunc(false);
      setCallGrid(false);
    } else if (condition == undefined) {
      deleteData(0);
    }
  }, [checkActiveTab]);

  useEffect(() => {
    if (migrate == false) {
      getInternationalTemp();
    }
  }, [callGrid]);

  const onAddCallBack = () => {
    getInternationalTemp();
  };

  const toggleButton = () => {
    setShowButton(!showButton);
  };

  useEffect(() => {
    if (migrate && allData?.request_type == "International") {
      setinterData(allGridData);
      setDetailArr(allGridData);
    }
  }, [allGridData]);

  //Changes in International

  const [additionalData, setAdditionalData] = useState({
    insurance_required: "",
    passport_number: "",
    valid_from: "",
    valid_to: "",
    visa_number: "",
    remark: "",
  });

  const [formAddValidation, setFormAddValidation] = useState({
    insurance_required: "",
    passport_number: "",
    valid_from: "",
    valid_to: "",
    visa_number: "",
    remark: "",
  });
  const [finalData, setFinalData] = useState({
    advance_amount: "",
  });

  const [dataError, setDataError] = useState(false);
  const [currentData, setcurrentData] = useState([]);

  const handleChange = (e, name) => {
    switch (name) {
      case "insurance_required":
        setAdditionalData((prev) => ({
          ...prev,
          insurance_required: e.target.value,
        }));
        break;
      case "passport_number":
        setAdditionalData((prev) => ({
          ...prev,
          passport_number: e.target.value,
        }));

        break;
      case "valid_from":
        setAdditionalData((prev) => ({
          ...prev,
          valid_from: e,
        }));
        break;
      case "valid_to":
        setAdditionalData((prev) => ({
          ...prev,
          valid_to: e,
        }));
        break;
      case "visa_number":
        setAdditionalData((prev) => ({
          ...prev,
          visa_number: e.target.value,
        }));
        break;
      case "remark":
        setAdditionalData((prev) => ({
          ...prev,
          remark: e.target.value,
        }));
        break;
      default:
    }
  };

  const handlePassportValid = (value) => {
    // let passportRegex = /^[A-PR-W-ZYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/gm;
    let passportRegex = /^[A-Z][0-9]{7}$/;
    if (passportRegex?.test(value) == false) {
      setPassportRegexValid(true);
    } else {
      setPassportRegexValid(false);
    }
  };

  const validateAddRecords = () => {
    let isError = false;
    const fromAddData = { ...formAddValidation };
    if (typeof additionalData.insurance_required !== "boolean") {
      if (!additionalData.insurance_required) {
        isError = true;
        fromAddData.insurance_required = "*Insurance Field is required";
        setFormAddValidation(fromAddData);
      }
    }
    if (!additionalData.passport_number) {
      isError = true;
      fromAddData.passport_number = "*Passport Number is required";
      setFormAddValidation(fromAddData);
    }
    if (!additionalData.valid_from) {
      isError = true;
      fromAddData.valid_from = "*Valid From is required";
      setFormAddValidation(fromAddData);
    }

    if (!additionalData.valid_to) {
      isError = true;
      fromAddData.valid_to = "*Valid Till is required";
      setFormAddValidation(fromAddData);
    }

    if (error) {
      isError = true;
    }
    if (passportRegexValid) {
      isError = true;
    }
    return isError;
  };

  const validateDateOfVisa = () => {
    let fromDate = interData[0]?.from_date;
    let toDate = interData[interData.length - 1]?.to_date;
    if (additionalData?.valid_from !== "" && additionalData?.valid_to !== "") {
      if (
        fromDate < additionalData?.valid_from ||
        toDate > additionalData?.valid_to
      ) {
        setFlagDataInter(true);
      } else {
        setFlagDataInter(false);
      }
    }
  };

  useEffect(() => {
    if (userDetails[0]?.id !== undefined) {
      TravelAdvanceCheck(userDetails[0]?.id);
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      checkAdvanceValidation == true ||
      ((!["1003", "1007", "1017"].includes(
        userDetails[0]?.personal_area_name
      ) ||
        (["1003", "1007", "1017"].includes(
          userDetails[0]?.personal_area_name
        ) &&
          userDetails[0]?.sub_personal_area_name === "GG01")) &&
        userDetails[0]?.is_sf_user == true) ||
      checkAdvanceValidation == true
    ) {
      setAdvanceAmountShow(true);
    }
  }, [checkAdvanceValidation]);

  useEffect(() => {
    validateDateOfVisa();
  }, [additionalData]);

  const checkSave = (type) => {
    if (flagDataInter !== true) {
      if (validateAddRecords()) return false;
      submitAll(type);
    }
  };

  const checkToOrg = userDetails[0]?.organisation_code;

  const handleManagerDateChange = async () => {
    window.close();
  };

  const handleDateChange = (type) => {
    let data = [];
    let citySameValue = false;
    interData?.map((value, i) => {
      if (
        value?.from_date == value?.to_date &&
        (value?.to_city_input || value?.to_city_name == dataNew[0].location) &&
        (value?.from_city_input ||
          value?.from_city_name == dataNew[0].location) &&
        value?.hq == true
      ) {
        data?.push(value);
        citySameValue = true;
      }
    });
    let tt = moment(data[data?.length - 1]?.to_time, "HH:mm").diff(
      moment(data[0]?.from_time, "HH:mm"),
      "hours"
    );
    if (
      (checkToOrg == "PIIL" || checkToOrg == "PIJA") &&
      tt > 17 &&
      changeValueData == true
    ) {
      let val = data?.map((ele) => {
        ele.reason_for_exception_id = "5";
        ele.reason_for_exception_name = "Other";
        ele.reason = "Traveled more than 17hr on same day";
        return ele;
      });

      let mergeArray =
        interData && interData.map((t) => (t.id == val.id ? val : t));
      setinterData(mergeArray);
      timeCheckValidator(type);
    } else {
      timeCheckValidator(type);
    }
  };

  const submitAll = (type) => {
    let save = type === "P" ? "submit" : "draft";
    let data = type;
    confirmAlert({
      title: `Want to ${save}`,
      message: `Are you sure, you want to ${save} tour?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => saveTravelInfo(data),
        },
        {
          label: "No",
        },
      ],
    });
  };

  var check = false;
  const checkIsException = () => {
    const hasReasonForException = interData.some(
      (obj) => obj.reason_for_exception_id !== null
    );
    if (hasReasonForException) {
      check = true;
    } else {
      check = false;
    }
  };

  const TravelCityCheck = (data, type) => {
    TravelSrv.TravelCityCheck(data)
      .then((response) => {
        const data = response?.data?.message;
        setTimeCheck(response?.data?.message);
        if (data.length === 0) {
          checkSave(type);
          checkIsException();
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveTravelInfo = async (type) => {
    dispatch(setDisplayLoader("Display"));
    let mainArray = interData;
    let first = mainArray[0];
    let last = mainArray[mainArray.length - 1];
    let firstValue =
      first?.from_city_input == "" ? null : first.from_city_input;
    let toValue = last?.to_city_input == "" ? null : last.to_city_input;
    if (interData.length >= 2) {
      if (
        first?.from_city_name !== last?.to_city_name ||
        first?.from_continent_id !== last?.to_continent_id ||
        first?.from_country_id !== last?.to_country_id ||
        firstValue !== toValue
      ) {
        setValidLastData(true);
        dispatch(setDisplayLoader("Hide"));
      } else {
        setValidLastData(false);
        let data = {};
        data["status"] = type;
        data["days_no"] = daysDiffNo;
        data["request_type"] = "International";
        data["outstanding"] =
          outstandingAmt?.balance === "" || outstandingAmt?.balance === null
            ? 0
            : outstandingAmt?.balance;
        if (finalData?.advance_amount !== "")
          data["advance_amount"] =
            finalData?.advance_amount === null ? 0 : finalData?.advance_amount;
        data["travel_detail"] = interData;
        data["is_exception"] = check;
        data["insurance_required"] = additionalData?.insurance_required;
        data["passport_number"] = additionalData?.passport_number;
        data["valid_from"] = moment(additionalData?.valid_from).format(
          "YYYY-MM-DD"
        );
        data["valid_to"] = moment(additionalData?.valid_to).format(
          "YYYY-MM-DD"
        );
        data["visa_number"] = additionalData?.visa_number;
        data["remark"] = additionalData?.remark;

        if (managerEditing) {
          data["edit_by_manager_at"] = new Date().toISOString().split("T")[0];
          data["status"] = "A";
        }

        if (condition == "International") {
          await TravelListingAPI.updateTravelInfo(data, allData?.id)
            .then((response) => {
              dispatch(setDisplayLoader("Hide"));
              if (response.data.code == 200) {
                getOutstandingAmount(
                  managerEditing && dynamicUserData
                    ? dynamicUserData?.id
                    : userData?.id,
                  allData?.id
                );
                onViewClose();
                if (managerEditing) {
                  // window.close()
                } else {
                  navigate("/travel-listing");
                }
                toast.success(response.data.message);
                setDataError(false);
                setFinalData({
                  advance_amount: "",
                });
              }
            })
            .catch((error) => {
              dispatch(setDisplayLoader("Hide"));
              toast.warning(error.response.data.message);
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
            });
        } else {
          await TravelListingAPI.saveTravelInfo(data)
            .then((response) => {
              dispatch(setDisplayLoader("Hide"));
              if (response.data.code == 200) {
                let dataRes = response?.data?.dataList;
                getOutstandingAmount(
                  managerEditing && dynamicUserData
                    ? dynamicUserData?.id
                    : userData?.id,
                  dataRes?.id
                );
                if (managerEditing) {
                  // window.close()
                } else {
                  navigate("/travel-listing");
                }
                toast.success(response.data.message);
                setDataError(false);
                deleteData(0);
                setFinalData({
                  advance_amount: "",
                });
              }
            })
            .catch((error) => {
              dispatch(setDisplayLoader("Hide"));
              toast.warning(error.response.data.message);
              console.log(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                  "failed"
              );
            });
        }
      }
    } else {
      dispatch(setDisplayLoader("Hide"));
      setDataError(true);
    }
  };

  const onClose = () => {
    setFormView(false);
    setEditInterData(null);
    setShowButton(true);
  };

  const update = (data) => {
    if (data.travel_class === "Economy") {
      data.reason_for_exception_id = null;
      data.reason_for_exception_name = null;
    }
    let dataData = interData;
    let newDetail = dataData?.map((obj) => {
      if (obj?.id === data?.id) {
        return data;
      } else {
        return obj;
      }
    });
    if (managerEditing) {
      data["added_by_manager"] = true;
    }
    saveEditTravel(newDetail);
    setcurrentData(newDetail);
  };

  const saveEditTravel = async (values) => {
    dispatch(setDisplayLoader("Display"));
    let data = {};
    if (allData?.status == "A") {
      data["status"] = "pending";
    } else if (allData?.status == "pending") {
      data["status"] = "pending";
    } else {
      data["status"] = "draft";
    }
    data["request_type"] = "International";
    if (managerEditing) {
      data["edit_by_manager_at"] = new Date().toISOString().split("T")[0];
      data["status"] = "A";
    }
    data["travel_detail"] = values;
    data["outstanding"] =
      outstandingAmt?.balance === "" || outstandingAmt?.balance === null
        ? 0
        : outstandingAmt?.balance;
    if (finalData?.advance_amount !== "")
      data["advance_amount"] =
        finalData?.advance_amount === null ? 0 : finalData?.advance_amount;
    await TravelListingAPI.updateTravelInfo(data, allData?.id)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response.data.code === 200) {
          setinterData(values);
          // onViewClose();
          // navigate("/travel-listing");
          setValidLastData(false);
          onCallBackTravel(allData?.id);
          setCallGrid(true);
          onClose();
          // toast.success("Successfully updated");
        }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const AddFunction = (data) => {
    let xx = {};
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      if (data[key] !== "") {
        xx[key] = value;
      }
    });
    let dataData = [...interData];
    if (managerEditing) {
      xx["added_by_manager"] = true;
    }
    dataData.push(xx);
    setCallGrid(true);
    saveAddTravel(dataData);
  };

  const saveAddTravel = async (values) => {
    dispatch(setDisplayLoader("Display"));
    let data = {};
    data["status"] = "draft";
    data["request_type"] = "International";
    data["travel_detail"] = values;
    data["outstanding"] =
      outstandingAmt?.balance === "" || outstandingAmt?.balance === null
        ? 0
        : outstandingAmt?.balance;
    if (finalData?.advance_amount !== "")
      data["advance_amount"] =
        finalData?.advance_amount === null ? 0 : finalData?.advance_amount;
    await TravelListingAPI.updateTravelInfo(data, allData?.id)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response.data.code === 200) {
          setinterData(values);
          // onViewClose();
          // navigate("/travel-listing");
          onCallBackTravel(allData?.id);

          setCallGrid(true);
          onClose();
          toast.success("Successfully Added");
        }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const timeCheckValidator = (type) => {
    // let dataCheckUserDetails = userDetails[0];
    // if (!dataCheckUserDetails?.business_unit_code) {
    //   toast.error(
    //     "You can not submit the request as business area is not available"
    //   );
    //   return;
    // } else if (!dataCheckUserDetails?.employee_vendor_code) {
    //   toast.error(
    //     "You can not submit the request as vendor code is not available"
    //   );
    //   return;
    // } else if (!dataCheckUserDetails?.cost_center_code) {
    //   toast.error(
    //     "You can not submit the request as cost center is not available"
    //   );
    //   return;
    // } else {
    const payload = newPayload;
    TravelCityCheck(payload, type);
    // }
  };

  useEffect(() => {
    if (allData) {
      Object.keys(allData).forEach((item) => {
        additionalData[item] = allData[item];
      });
    }
  }, [allData]);

  useEffect(() => {
    getUsersDetails(
      managerEditing && dynamicUserData
        ? dynamicUserData?.username
        : userData?.username
    );
    getOutstandingAmount(
      managerEditing && dynamicUserData ? dynamicUserData?.id : userData?.id,
      ""
    );
  }, []);

  const validCountYtd = () => {
    let fromYtdDate = interData[0]?.from_date;
    let last = interData[interData.length - 1];
    let toYtdDate = last?.to_date;
    calculateDateDifference(fromYtdDate, toYtdDate);
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysDiffNo(diffDays);
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (migrate && mainData.length !== 0) {
      setinterData(mainData);
    }
  }, [mainData]);

  const handleFromDateChange = (date) => {
    // const { value } = event.target;
    setFromDate(date);
    validateDates(
      moment(date).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
  };

  const handleToDateChange = (date) => {
    // const { value } = event.target;
    setToDate(date);
    validateDates(
      moment(fromDate).format("YYYY-MM-DD"),
      moment(date).format("YYYY-MM-DD")
    );
  };

  const validateDates = (from, to) => {
    if (from && to && from > to) {
      setError("Valid from date should be before the valid till date.");
    } else if (
      moment(interData[interData?.length - 1]?.to_date).isAfter(moment(to))
    ) {
      setError("Your passport validity is expired ");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    // setFinalData({ ...finalData, advance_amount: allData?.advance_amount });
    setFinalData((prev) => ({
      ...prev,
      advance_amount: allData?.advance_amount,
    }));
  }, [migrate]);

  const handleLength = (value) => {
    if (Number(value) <= 100000) {
      setFinalData((prev) => ({
        ...prev,
        advance_amount: value,
      }));
    }
  };

  useEffect(() => {
    let dataCheckUserDetails = userDetails[0];
    if (!dataCheckUserDetails?.reporting_person_id) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.business_unit_code) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.employee_vendor_code) {
      setDisableButton(true);
    } else if (!dataCheckUserDetails?.cost_center_code) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [userDetails]);

  return (
    <>
      <div class="table-responsive">
        <table class="table table-striped table-bordered tablecured viewpo">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Action</th>
              <th>Departure</th>
              <th>Reaching</th>
              <th>Purpose of Travel</th>
              <th>Mode</th>
              <th>Colleague Empcode</th>
              <th>Hotel</th>
              <th>Ex</th>
              <th>Additional Information</th>
            </tr>
          </thead>
          <tbody>
            {interData?.length ? (
              interData?.map((data, index) => (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <button
                        class="btn btn-blanktd text-primary"
                        onClick={() => {
                          editData(data, index);
                        }}
                      >
                        <i class="far fa-edit"></i>
                      </button>

                      {managerEditing ? (
                        ""
                      ) : (
                        <button
                          class="btn btn-blanktd text-danger ml-2"
                          type="button"
                          onClick={() => {
                            submit(data?.id);
                          }}
                        >
                          <i class="far fa-trash-alt text-danger"></i>
                        </button>
                      )}
                    </td>
                    <td>
                      <div class="text-dark fw-bold f-14">
                        {data?.from_continent_name} ({data?.from_country_name} -{" "}
                        {data?.from_city_name === "Other" &&
                        data?.from_country_name == "India"
                          ? data?.from_city_input +
                            " - " +
                            data?.from_city_category
                          : data?.from_city_name !== "Other" &&
                            data?.from_country_name == "India"
                          ? data?.from_city_name +
                            " - " +
                            data?.from_city_category
                          : data?.from_city_name == "Other" &&
                            data?.from_country_name !== "India"
                          ? data?.from_city_input
                          : data?.from_city_name}
                        )
                      </div>
                      <Tooltip
                        title={`${moment(data?.from_date).format(
                          "DD-MM-YYYY"
                        )} ${moment(data?.from_time, "h:mm A").format(
                          "hh:mm A"
                        )}`}
                      >
                        {moment(data?.from_date).format("DD-MM-YYYY")}&nbsp;
                        {moment(data?.from_time, "h:mm A").format("hh:mm A")}
                      </Tooltip>
                    </td>
                    <td>
                      <div class="text-dark fw-bold f-14">
                        {data?.to_continent_name} ({data?.to_country_name} -{" "}
                        {data?.to_city_name === "Other" &&
                        data?.to_country_name == "India"
                          ? data?.to_city_input + " - " + data?.to_city_category
                          : data?.to_city_name !== "Other" &&
                            data?.to_country_name == "India"
                          ? data?.to_city_name + " - " + data?.to_city_category
                          : data?.to_city_name == "Other" &&
                            data?.to_country_name !== "India"
                          ? data?.to_city_input
                          : data?.to_city_name}
                        )
                      </div>
                      <Tooltip
                        title={`${moment(data?.to_date).format(
                          "DD-MM-YYYY"
                        )} ${moment(data?.to_time, "h:mm A").format(
                          "hh:mm A"
                        )}`}
                      >
                        {moment(data?.to_date).format("DD-MM-YYYY")}{" "}
                        {moment(data?.to_time, "h:mm A").format("hh:mm A")}
                      </Tooltip>
                    </td>
                    <td>{data?.purpose}</td>
                    <td>
                      {`${
                        data?.modee_name == undefined
                          ? data?.mode_name
                          : data?.modee_name
                      }`}
                      {(data?.mode_name == "Air" ||
                        data?.modee_name == "Air") &&
                        `(${
                          data?.travel_class !== null &&
                          data?.travel_class !== "" &&
                          data?.travel_class
                        })`}
                    </td>
                    <td>
                      {data?.colleague_user_name !== "" ||
                      data?.colleague_user_name !== null ? (
                        <Tooltip
                          title={data?.colleague_user_code}
                          position="left"
                        >
                          {data?.colleague_user_name !== undefined &&
                            data?.colleague_user_code !== null &&
                            `${data?.colleague_user_name} (${data?.colleague_user_code})`}
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{data?.stay_needed ? "Yes" : "No"}</td>
                    <td
                      style={{
                        maxWidth: "40px",
                      }}
                    >
                      {data?.reason_for_exception_name ? (
                        <i
                          class="fa fa-flag"
                          aria-hidden="true"
                          style={{ color: "red" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {data?.additional_information?.length > 20 ? (
                        <Tooltip
                          title={data?.additional_information}
                          position="left"
                        >
                          {`${data?.additional_information.substring(
                            0,
                            20
                          )}...`}
                        </Tooltip>
                      ) : (
                        data?.additional_information
                      )}
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={12}> No Record</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row align-items-center">
        {/* <div className="col-md-6">
          <h6 className="f-14 mt-2">
            <strong>Approver Name :</strong>
            <span className="text-muted">
              {userData?.reporting_to_fname} {userData?.reporting_to_lname} (
              {userData?.reporting_to_name})
            </span>
          </h6>
        </div> */}
        {showButton ? (
          <div className="col-md-12 text-sm-right">
            <button
              className="btn btnblanktd text-primary"
              onClick={() => {
                addShowRow();
                toggleButton();
              }}
              style={{ marginRight: "10px" }}
            >
              <i className="far fa-plus-square mr-2"></i> Add New Row
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {dataError && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;Please enter atleast two rows with return journey details.
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;{error}
        </Alert>
      )}
      {validLastData && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;First from location should be same last to location
        </Alert>
      )}
      {timeCheck.length > 0 && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          &nbsp;{timeCheck}
        </Alert>
      )}
      {passportRegexValid && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          Passport Number is Invalid
        </Alert>
      )}
      {formView && (
        <AddInternational
          setCallGrid={setCallGrid}
          interData={interData}
          allGridData={allGridData}
          allData={allData}
          setChangeFunc={setChangeFunc}
          changeFunc={changeFunc}
          edit={edit}
          userBackDate={userBackDate}
          editInterData={editInterData}
          setValidLastData={setValidLastData}
          setEditInterData={setEditInterData}
          setFormView={setFormView}
          formView={formView}
          dataNew={dataNew}
          onAddCallBack={onAddCallBack}
          setTimeCheck={setTimeCheck}
          saveEditTravel={saveEditTravel}
          onClose={onClose}
          update={update}
          AddFunction={AddFunction}
          dataError={dataError}
          migrate={migrate}
          mainData={mainData}
          indexValue={indexValue}
          setChangeValueData={setChangeValueData}
          managerEditing={managerEditing}
          dynamicUserData={dynamicUserData}
        />
      )}

      <div className="mb-3 px-4">
        <div className="row">
          <div className="col-md-12 mt-3">
            <h5 className="text-theme border-bottom pb-2 mb-3">
              <strong>Additional Details</strong>
            </h5>
          </div>

          <div className="col-md-4">
            <div className="form-group innergroup">
              <label>
                Insurance Required<span className="text-danger">*</span>
              </label>
              <select
                className="form-control newbgselect"
                name="insurance_required"
                onChange={(e) => {
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    insurance_required: "",
                  });
                }}
                value={additionalData.insurance_required}
              >
                <option value="">Select</option>
                <option value="true" selected>
                  Yes
                </option>
                <option value="false">No</option>
              </select>
              <div className="small text-danger">
                {formAddValidation.insurance_required}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group innergroup">
              <label>
                Passport No<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="passport_number"
                className="form-control"
                maxLength={12}
                placeholder="Enter Passport No"
                value={additionalData.passport_number}
                onBlur={(e) => handlePassportValid(e.target.value)}
                onChange={(e) => {
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    passport_number: "",
                  });
                }}
              />
              <div className="small text-danger">
                {formAddValidation.passport_number}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group innergroup d-flex flex-column">
              <label>
                Valid From<span className="text-danger">*</span>
              </label>
              {/* <input
                type="date"
                className="form-control"
                name="valid_from"
                onChange={(e) => {
                  handleFromDateChange(e);
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    valid_from: "",
                  });
                }}
                value={additionalData.valid_from}
              /> */}
              <DatePicker
                selected={
                  additionalData?.valid_from != ""
                    ? new Date(additionalData?.valid_from)
                    : ""
                }
                onChange={(date) => {
                  handleFromDateChange(date);
                  handleChange(date, "valid_from");
                  setFormAddValidation({
                    ...formAddValidation,
                    valid_from: "",
                  });
                }}
                placeholderText="DD-MM-YYYY"
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
              <div className="small text-danger">
                {formAddValidation.valid_from}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group innergroup d-flex flex-column">
              <label>
                Valid Till<span className="text-danger">*</span>
              </label>
              {/* <input
                type="date"
                className="form-control"
                name="valid_to"
                min={moment(additionalData?.valid_from).format("YYYY-MM-DD")}
                onChange={(e) => {
                  handleToDateChange(e);
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    valid_to: "",
                  });
                }}
                value={additionalData.valid_to}
              /> */}
              <DatePicker
                selected={
                  additionalData?.valid_to != ""
                    ? new Date(additionalData?.valid_to)
                    : ""
                }
                onChange={(date) => {
                  handleToDateChange(date);
                  handleChange(date, "valid_to");
                  setFormAddValidation({
                    ...formAddValidation,
                    valid_to: "",
                  });
                }}
                minDate={new Date(additionalData?.valid_from)}
                placeholderText="DD-MM-YYYY"
                className="form-control"
                dateFormat="dd-MM-yyyy"
              />
              <div className="small text-danger">
                {formAddValidation.valid_to}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group innergroup">
              <label>Visa No. (If Available)</label>
              <input
                type="text"
                className="form-control"
                maxLength={20}
                placeholder="Enter Visa No. (If Available)"
                name="visa_number"
                onChange={(e) => {
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    visa_number: "",
                  });
                }}
                value={additionalData.visa_number}
              />
              <div className="small text-danger">
                {formAddValidation.visa_number}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group innergroup">
              <label>Remarks</label>
              <input
                type="text"
                name="remark"
                maxLength={100}
                className="form-control"
                placeholder="Enter Remarks"
                onChange={(e) => {
                  handleChange(e, e.target.name);
                  setFormAddValidation({
                    ...formAddValidation,
                    remark: "",
                  });
                }}
                value={additionalData.remark}
              />
              <div className="small text-danger">
                {formAddValidation?.remark}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3">
            <h5 className="text-theme border-bottom pb-2 mb-3">
              <strong>
                {advanceAmountShow ? "Advance" : "Outstanding"} Details
              </strong>
            </h5>
          </div>
          <div className="col-md-4 ">
            <div className="form-group innergroup">
              <label>
                Outstanding <i className="fas fa-rupee-sign"></i>
              </label>
              <input
                type="text"
                disabled
                value={`${outstandingAmt?.remark} : Rs.${
                  outstandingAmt?.balance == null
                    ? 0
                    : Math.round(outstandingAmt?.balance)
                } `}
                className="form-control"
              />
            </div>
            <div className="col-md-14">
              <Link onClick={addnewopen}>
                Click here to view (Outstanding Details)
              </Link>
            </div>
          </div>
          {advanceAmountShow && (
            <div className="col-md-4">
              <div className="form-group innergroup">
                <label>
                  Advance Amount <i className="fas fa-rupee-sign"></i>{" "}
                  <span className="text-danger"></span>
                </label>
                <input
                  type="number"
                  name="advance_amount"
                  onWheel={(e) => e.target.blur()}
                  className="form-control"
                  onKeyDown={blockInvalidChar}
                  placeholder="Enter Advance Amount"
                  onChange={(e) => {
                    handleLength(e.target.value);
                  }}
                  min={0}
                  value={finalData?.advance_amount}
                />
              </div>
            </div>
          )}
        </div>
        {flagDataInter && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            Your Passport is not valid or you have entered invalid date
          </Alert>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="border-top my-3"></div>
          </div>

          <div className="col-md-12 mt-3 text-center">
            {allData?.status === "P" ||
            allData?.status === "A" ||
            allData?.status === "R" ? (
              ""
            ) : (
              <button
                type="button"
                className="btn btn-outline-primary mr-2"
                disabled={disableButton}
                onClick={() => handleDateChange("D")}
              >
                Save As Draft
              </button>
            )}
            <Tooltip
              title={
                disableButton == true && !userDetails[0]?.business_unit_code
                  ? "You can not submit the request as business unit is not available"
                  : disableButton == true &&
                    !userDetails[0]?.employee_vendor_code
                  ? "You can not submit the request as vendor code is not available"
                  : disableButton == true && !userDetails[0]?.cost_center_code
                  ? "You can not submit the request as cost center is not available"
                  : ""
              }
              position="bottom"
            >
              <button
                className="btn btn-primary-inner bpi-main"
                disabled={disableButton}
                onClick={() => handleDateChange("P")}
              >
                Submit
              </button>
            </Tooltip>
            &nbsp;&nbsp;
            {/* {migrate ? ( */}
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              // onClick={() => onCloseForm()}
              onClick={() => {
                if (allData == undefined) {
                  navigate("/travel-listing");
                } else {
                  onViewClose();
                }
              }}
            >
              <i class="far fa-arrow-alt-circle-left"></i> Back
            </button>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
      </div>

      <div className="travel-note px-4">
        <h4>Notes :-</h4>
        <ul>
          <li>
            Attendance and Travel claim will be marked/submit on the basis of
            approved tour intimation.
          </li>
          <li>Time to be filled as per 24 Hr clock.</li>
        </ul>
      </div>

      {/* <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <table class="table table-striped table-bordered tablecured travelintim">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Balance</th>
                  <th>Currency</th>
                  <th>Bill Date</th>
                  <th>Text</th>
                </tr>
              </thead>
              <tbody>
                {outstandingValues?.map((value, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{value?.__values__?.DMBTR}</td>
                    <td>{value?.__values__?.WAERS}</td>
                    <td>{value?.__values__?.BLDAT}</td>
                    <td>{value?.__values__?.SGTXT}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div className="col-md-12">
              <div className="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Bill Date</th>
                      <th>Text</th>
                      <th>Assignment number</th>
                      <th>Document Number</th>
                      <th>Posting Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outstandingValues?.length > 0 ? (
                      outstandingValues?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value?.__values__?.DMBTR}</td>
                          <td>{value?.__values__?.WAERS}</td>
                          <td>
                            {moment(value?.__values__?.BLDAT).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td
                            style={{
                              maxWidth: "80vh",
                            }}
                          >
                            {value?.__values__?.SGTXT}
                          </td>
                          <td>{value?.__values__?.ZUONR}</td>
                          <td>{value?.__values__?.BELNR}</td>
                          <td>{value?.__values__?.BUDAT}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default International;
