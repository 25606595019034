import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getEmpDirectory = async (
  sortBy,
  search,
  designationId,
  departmentId,
  locationId
) => {
  return axios.get(
    `${AUTH_BASE_URL}/employee_directory/view?search=${search}&locationId=${locationId}&designationId=${designationId}&department_id=${departmentId}&sort_by=${sortBy}`
  );
};
