import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import ReactQuill from "react-quill";

const modules = {
  toolbar: false,
};

const ViewFaq = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Question<span className="text-danger">*</span>
                    </label>
                    <ReactQuill
                      value={viewFormData?.question}
                      readOnly
                      modules={modules}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Answer<span className="text-danger">*</span>
                    </label>
                    <ReactQuill
                      value={viewFormData?.answer}
                      readOnly
                      modules={modules}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Category<span className="text-danger">*</span>
                    </label>
                    <p>{viewFormData?.category__category}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment.utc(viewFormData?.created_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated By</label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <p>
                      {moment.utc(viewFormData?.updated_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Attachments<span className="text-danger">*</span>
                    </label>
                    {viewFormData?.attachment.map((obj, index) => {
                      return (
                        <div>
                          <span>{`${index + 1} ${
                            obj?.attachment.split("/")[1]
                          }`}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewFaq;
