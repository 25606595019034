import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb } from "react-bootstrap";
import Select from "react-select";
import GlcostCenterForm from "./gl_code_mappingForm";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { getGlCodeList, getUserRmList } from "../../service/jv_service";
import {
  deleteGl,
  freezeMapping,
  getGlApprover,
} from "../../service/glService";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function GlCostCenter() {
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const navigate = useNavigate();
  const [GlData, setGlData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const [filterdata, setfilterData] = useState({
    approver: { value: 0 },
    requester: { value: 0 },
    glcode: { value: 0 },
  });
  const resetFilterData = () =>
    setfilterData({
      approver: { value: 0 },
      requester: { value: 0 },
      glcode: { value: 0 },
    });
  const [editData, seteditData] = useState([]);
  const resetEditState = () => seteditData([]);
  const [show, filtersetShow] = useState(false);
  const [edit, setedit] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userlist, setuserlist] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [search, setSearch] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [glcode, setGlcode] = useState([]);

  const [freezeeStatus, setFreezeeStatus] = useState(false);
  const getGlapprover = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    search,
    status,
    glcode,
    approvers,
    requester
  ) => {
    dispatch(setDisplayLoader("Display"));
    getGlApprover(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      search,
      status,
      glcode,
      approvers,
      requester
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setFreezeeStatus(response?.data?.dataList?.freezeeStatus);
        if (response?.data?.dataList?.freezeeStatus === true) {
          setBackgroundColor("#00bd47");
          setTextColor("black");
        } else {
          setBackgroundColor("#f0ad4e");
          setTextColor("black");
        }
        setGlData(response?.data?.dataList?.result);
        setDataCount(response?.data?.dataList?.paginated_data?.approval_count);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getGlapprover(
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true,
      "",
      true,
      0,
      0,
      0
    );
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deleteGlApprover = (id) => {
    deleteGl(id)
      .then((response) => {
        getGlapprover(
          currentPage,
          pageSizeNo,
          "-id",
          true,
          "",
          true,
          filterdata?.glcode.value,
          filterdata?.approver.value,
          filterdata?.requester.value
        );
        toast.warning("Mapping deleted successfully");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleDelete = (id) => {
    confirmAlert({
      title: "Delete GL User Mapping",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGlApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const freeze = () => {
    if (freezeeStatus == false) {
      confirmAlert({
        message: `Are you sure to freeze mapping for current month?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => FreezeMapping(),
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      confirmAlert({
        message: "Mapping is already freezed for current month.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    }
  };

  const FreezeMapping = () => {
    freezeMapping()
      .then((response) => {
        toast.success(response?.data?.dataList?.msg);
        console.log(response);
        getGlapprover(currentPage, pageSizeNo, "-id", true, "", true, 0, 0, 0);
      })
      .catch((error) => {
        toast.error("Some error occurred please try again");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const Glcode = () => {
    getGlCodeList()
      .then((response) => {
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.gl_account + "(" + x?.short_desc + ")",
          };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getGlapprover(currentPage, pageSizeNo, "-id", true, "", true, 0, 0, 0);
    getUserList();
    Glcode();
  }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getGlapprover(activePage, pageSizeNo, "-id", true, "", true, 0, 0, 0);
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "requester":
        setfilterData((prev) => ({
          ...prev,
          requester: event,
        }));
        break;
      case "glcode":
        setfilterData((prev) => ({
          ...prev,
          glcode: event,
        }));
        break;
    }
  };
  const resetFilter = () => {
    filterclose();
    resetFilterData();
    getGlapprover(currentPage, pageSizeNo, "-id", true, "", true, 0, 0, 0);
  };
  const mainDataFilter = () => {
    getGlapprover(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      true,
      filterdata?.glcode.value,
      filterdata?.approver.value,
      filterdata?.requester.value
    );
    filterclose();
  };
  const handleSearch = (e) => {
    getGlapprover(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      e.target.value.toLowerCase(),
      true,
      filterdata?.glcode.value,
      filterdata?.approver.value,
      filterdata?.requester.value
    );
    setSearch(e.target.value);
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li className="active">
              <Link to="/GlMapping">Mapped GL</Link>
            </li>
            <li>
              <Link to="/GlCostMapping">Gl costcenter user mapping</Link>
            </li>
            <li className="active">
              <Link to="/GlMapping">Mapped GL</Link>
            </li>
            <li>
              <Link to="/gi-balancesheet">GL Balancesheet</Link>
            </li>
            <li>
              <Link to="/gi-approval">GL Balancesheet Approval</Link>{" "}
              <span class="numberpurchse active float-right mr-2">
                {dataCount}
              </span>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Map User Reviewer</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => {
                        addnewopen();
                        setedit(false);
                      }}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Map Approver
                    </button>
                    <button
                      style={{ background: backgroundColor, color: textColor }}
                      onClick={() => freeze()}
                      class="btn btn-success"
                    >
                      <i class="far fa-check-circle mr-1"></i>Mapping Freeze For
                      Current Month
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th
                      onClick={(e) => {
                        handlerData("Gl_id__short_desc");
                      }}
                      className={
                        sortData["Gl_id__short_desc"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["Gl_id__short_desc"]
                      }
                    >
                      GL Code
                    </th>
                    <th>User ID</th>
                    <th>Reviewer ID</th>
                    <th>Mapped On</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {GlData?.map((x) => (
                    <tr>
                      <td>
                        {x?.gl_account}({x?.gl_desc})
                      </td>
                      <td>{x?.requester_name}</td>
                      <td>{x?.approver_name}</td>
                      <td>
                        {moment
                          .utc(x?.created_at)
                          .format("Do MMM YYYY, h:mm:ss a")}
                      </td>
                      {x?.is_active == true ? (
                        <td>
                          <span class="badge bad-status badge-success">
                            Active
                          </span>
                        </td>
                      ) : (
                        <td>
                          <span class="badge bad-status badge-danger">
                            InActive
                          </span>
                        </td>
                      )}
                      <td>
                        <button
                          type="submit"
                          onClick={() => {
                            setedit(true);
                            seteditData(x);
                            addnewopen();
                          }}
                          class="btn btn-blanktd text-primary ml-2 f-12"
                        >
                          <i class="far fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(x?.id)}
                          class="btn btn-blanktd text-danger ml-2 f-12"
                        >
                          <i class="far fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              GL Code<span class="text-danger">*</span>
            </label>
            <Select
              name="glcode"
              options={glcode}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.glcode}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Requester<span class="text-danger">*</span>
            </label>
            <Select
              name="requester"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.requester}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Approver Id<span class="text-danger">*</span>
            </label>
            <Select
              name="approver"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.approver}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <GlcostCenterForm
          show={addnewshow}
          edit={edit}
          getList={() =>
            getGlapprover(
              currentPage,
              pageSizeNo,
              "-id",
              true,
              "",
              true,
              0,
              0,
              0
            )
          }
          close={addnewclose}
          open={addnewopen}
          editState={editData}
          resetEditState={resetEditState}
        ></GlcostCenterForm>
      )}
    </>
  );
}

export default GlCostCenter;
