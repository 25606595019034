import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";
import * as PrService from "../../service/prService";
import { toast } from "react-toastify";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";

const options = [
  { value: "Option1", label: "Option1" },
  { value: "Option2", label: "Option2" },
  { value: "Option3", label: "Option3" },
  { value: "Option4", label: "Option4" },
  { value: "Option5", label: "Option5" },
];

function SyncPR() {

  const dispatch = useDispatch();

  const [prNumber, setPRNumber] = useState(null)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)

  const handleChange = (e, field_name) =>{
    if(field_name === "pr_number"){
      setPRNumber(e.target.value)
    }
    if(field_name === "date"){
      setDate(e.target.value)
      }
    if(field_name === "time"){
      setTime(e.target.value)
      }
  }

  const pullPR = () =>{
    dispatch(setDisplayLoader("Display"));
    let data = {"date":date, "time":time, "pr_number":prNumber}
    PrService.pullPrFromSap(data).then((response)=>{
      if(response?.data?.dataList?.response?.error === "PR Synced Successfully."){
      toast.success("PR Synced Successfully")
    }
    else{
      toast.error(response?.data?.dataList?.response?.error)
    }
      dispatch(setDisplayLoader("Hide"));
    })
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Sync PR Sap</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      onChange={(e)=>{handleChange(e, "date")}}
                    />
                   
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      Time<span class="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      class="form-control"
                      onChange={(e)=>{handleChange(e, "time")}}
                    />
                   
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 fw-bold mb-3">OR</div>

                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>
                      PR Number<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter PR Number"
                      onChange={(e)=>{handleChange(e, "pr_number")}}
                    />
                   
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <button
                   class="btn btn-outline-danger mr-3"
                   type="button"
                   onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                   >
                    Cancel
                   </button>
                  <button class="btn btn-primary-inner bpi-main" type="button" onClick={pullPR}>
                    Pull PR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SyncPR;
