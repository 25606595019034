import React, { useState, useEffect } from "react";
import "./loader.css";
import { Modal, Spinner } from "react-bootstrap";
import * as SkillService from "../../service/skill";
import * as SkillQuarterService from "../../service/skillquaterly";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
if (typeof window !== "undefined") {
  injectStyle();
}

const QuaterlyBulkImport = (props) => {
  const dispatch = useDispatch();

  const { importshow, importclose, getSkillUser } = props;
  const [downloadButton, setDownloadButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});

  const importExel = (values) => {
    setIsLoading(true);
    dispatch(setDisplayLoader("Display"));

    SkillQuarterService.importExel(values)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          toast.success("Quarterly Reports Bulk Uploaded Successfully");
          getSkillUser("", "", "", "", 1, 10, "-id", true);

          setDownloadButton(true);
          setIsLoading(false);
          dispatch(setDisplayLoader("Hide"));
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const onProfileChange = (e) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    SkillService.uploadImages(fileData).then((response) => {
      let data = response.data;
      if (response.data.status === true) {
        importExel(data);
      }
    });
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "SkillQuarterly.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Quarterly Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  {isLoading ? (
                    <div class="loadingss">
                      <p style={{ color: "#3498db" }}>
                        Please wait while uploading the data.
                      </p>
                      <span>
                        <i></i>
                        <i></i>
                      </span>
                    </div>
                  ) : (
                    <>
                      <i className="fas fa-file-upload fa-2x"></i>
                      <p>Upload the template file with updated data</p>

                      <button className="btn btn-primary-inner" type="button">
                        DRAG AND DROP HERE OR CHOOSE FILE
                      </button>
                      <div className="mt-3 downloadtemplate">
                        <button className="editbtn">
                          <i className="far fa-file-excel"></i>
                          <a href="quarterly.xlsx" download>
                            DOWNLOAD TEMPLATE
                          </a>
                        </button>
                      </div>
                    </>
                  )}

                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e)}
                  />
                </div>

                {downloadButton && (
                  <div className="drapdroparea upload-btn-wrapper text-center">
                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                    <p>Check the uploaded file in case of error.</p>
                    <button
                      className="btn btn-primary-inner"
                      type="button"
                      onClick={() => {
                        downloadError();
                      }}
                    >
                      Download
                    </button>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuaterlyBulkImport;
