import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import * as SkillLocationService from "../../service/skillLocation";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import moment from "moment";

const validationSchema = Yup.object({
  quarter: Yup.string().required("*Quarter is required"),
});

const AddSkillLocation = (props) => {
  const nameForm = "Skill Master";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;
  console.log("editSkills", editSkills);

  const [isLoading, setIsLoading] = useState(false);
  const [allLocation, setALlLocation] = useState([]);
  const [isSkillExist, setIsSkillExist] = useState(false);

  const [locationData, setLocationData] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);
  const [locationError, setLocationError] = useState(false);
  const [isSkillExistMsg, setIsSkillExistMsg] = useState("");
  const [skillDataLocation, setSkillDataLocation] = useState({
    location_id: "",
    quarter: "",
    action: "Create",
    status: "Open",
    is_renewable_skill: true
  });
  let condition = "false";

  const saveSkillLocation = (values) => {
    let data = dataLocation.value;
    values.location_id = data;
    setIsLoading(true);
    SkillLocationService.saveSkillLocation(values)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkillLocation = (values) => {
    let data = dataLocation.value;
    console.log("dtaa", data);
    values.location_id = data;
    setIsLoading(true);
    SkillLocationService.updateSkillLocation(values, values.id)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getSubPersonal = () => {
    SkillLocationService.getSubPersonal()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = { value: x.id, label: `${x.name} (${x?.description})` };
          data.push(data1);
        });
        setLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isDataExists = (values) => {
    let locatedata = dataLocation.value;
    let data = {};
    if (edit) {
      data["id"] = values.id;
      data["location_id"] = locatedata;
      data["quarter"] = values.quarter;
      data["status"] = values.status;
      data["action"] = values.action;
      data["is_renewable_skill"] = values.is_renewable_skill;
    } else {
      data["id"] = 0;
      data["location_id"] = locatedata;
      data["quarter"] = values.quarter;
      data["status"] = values.status;
      data["action"] = values.action;
      data["is_renewable_skill"] = values.is_renewable_skill;
    }
    if (condition == "false") {
      condition = "true";
      SkillLocationService.isDataExists(data)
        .then((res) => {
          setIsSkillExist(res?.data?.dataList?.is_exist);
          setIsSkillExistMsg(res?.data?.message);
          if (res?.data?.dataList?.is_exist === false && values?.id) {
            updateSkillLocation(values);
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            values?.id === undefined
          ) {
            saveSkillLocation(values);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = (values) => {
    let error = false;
    if (edit) {
      if (editSkills?.location_id !== skillDataLocation?.location_id) {
        error = true;
      }
      if (editSkills?.quarter !== values?.quarter) {
        error = true;
      }
      if (editSkills?.status !== values?.status) {
        error = true;
      }
      if (editSkills?.action !== values?.action) {
        error = true;
      }
    }
    if (error === false && values?.id) {
      updateSkillLocation(values);
    }
    if (error === true && values?.id) {
      isDataExists(values);
    }
    if (error === false && values?.id === undefined) {
      isDataExists(values);
    }
  };

  const locationPush = (e) => {
    setDataLocation(e);
    setSkillDataLocation({
      ...skillDataLocation,
      location_id: e.value,
    });
  };

  const submitHandler = () => {
    if (dataLocation.length === 0) {
      setLocationError(true);
    }
  };

  useEffect(() => {
    getSubPersonal();
  }, []);

  useEffect(() => {
    if (editSkills) {
      console.log(editSkills, "ddd");
      let locations = {
        value: editSkills?.location_id,
        label: editSkills?.location_name,
      };
      setDataLocation(locations);
      setSkillDataLocation(editSkills);
      Object.keys(editSkills).forEach((item) => {
        skillDataLocation[item] = editSkills[item];
      });
    }
  }, [editSkills]);

  useEffect(() => {
    console.log("auditValueLog", auditValueLog);
  }, [auditValueLog]);

  return (
    <>
      <Formik
        initialValues={skillDataLocation}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          AllCheck(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit === true ? "Edit" : "Add"} Skill Location Access
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Location<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="location"
                            id="locations"
                            options={locationData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              locationPush(e);
                              setLocationError(false);
                            }}
                            value={dataLocation}
                          />
                          {locationError && (
                            <div className="small text-danger">
                              *Location is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Quarter<span class="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="quarter"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setSkillDataLocation({
                                ...skillDataLocation,
                                quarter: e.target.value,
                              });
                            }}
                            value={values.quarter}
                          >
                            <option value="">Select</option>
                            <option value="Jan-Mar">Jan-Mar {[0, 1, .2].includes(moment().get("months")) ? moment().get("year") : moment().add(1, "year").get("year")}</option>
                            <option value="Apr-Jun">Apr-Jun {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().get("year")}</option>
                            <option value="Jul-Sep">Jul-Sep {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().get("year")}</option>
                            <option value="Oct-Dec">Oct-Dec {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().get("year")}</option>

                            <option value="Jan-Mar-P">Jan-Mar {[0, 1, .2].includes(moment().get("months")) ? moment().get("year") : moment().add(0, "year").get("year")}</option>
                            <option value="Apr-Jun-P">Apr-Jun {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().subtract(1, "year").get("year")}</option>
                            <option value="Jul-Sep-P">Jul-Sep {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().subtract(1, "year").get("year")}</option>
                            <option value="Oct-Dec-P">Oct-Dec {[0, 1, .2].includes(moment().get("months")) ? moment().subtract(1, "year").get("year") : moment().subtract(1, "year").get("year")}</option>
                            
                          </select>
                          {touched.quarter && errors.quarter ? (
                            <div className="small text-danger">
                              {errors.quarter}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">Action </label>
                          <label
                            class="logCheck d-inline-block mr-4"
                            htmlFor="Create"
                          >
                            Create
                            <input
                              type="radio"
                              name="action"
                              id="Create"
                              value={values.action}
                              onChange={() => {
                                setFieldValue("action", "Create");
                              }}
                              checked={values.action === "Create"}
                            />
                            <span class="checkmark"></span>
                          </label>
                          <label
                            class="logCheck d-inline-block"
                            htmlFor="Review"
                          >
                            Review
                            <input
                              type="radio"
                              name="action"
                              id="Review"
                              value={values.action}
                              onChange={(e) => {
                                setFieldValue("action", "Review");
                              }}
                              checked={values.action === "Review"}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label className="d-block mb-2">Status</label>
                          <label
                            class="logCheck d-inline-block mr-4"
                            htmlFor="Open"
                          >
                            Open
                            <input
                              type="radio"
                              name="status"
                              id="Open"
                              value={values.status}
                              onChange={() => {
                                setFieldValue("status", "Open");
                              }}
                              checked={values.status === "Open"}
                            />
                            <span class="checkmark"></span>
                          </label>
                          <label
                            class="logCheck d-inline-block"
                            htmlFor="Close"
                          >
                            Closed
                            <input
                              type="radio"
                              name="status"
                              id="Close"
                              value={values.status}
                              onChange={() => {
                                setFieldValue("status", "Close");
                              }}
                              checked={values.status === "Close"}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      {
                        edit ?
                          <></>
                          :
                          <div className="col-md-6">
                            <div className="form-group innergroup">
                              <label className="d-block mb-2">
                                Auto populate last year skills <span className="text-danger"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block mr-4"
                                htmlFor="active"
                              >
                                Yes
                                <input
                                  type="radio"
                                  name="is_renewable_skill"
                                  id="active"
                                  value={values.is_renewable_skill}
                                  onChange={() => {
                                    setFieldValue("is_renewable_skill", true);
                                  }}
                                  checked={values.is_renewable_skill === true}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="logCheck d-inline-block"
                                htmlFor="Inactive"
                              >
                                No
                                <input
                                  type="radio"
                                  name="is_renewable_skill"
                                  id="Inactive"
                                  value={values.is_renewable_skill}
                                  onChange={(e) => {
                                    setFieldValue("is_renewable_skill", false);
                                  }}
                                  checked={values.is_renewable_skill === false}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                      }

                    </div>

                  </div>
                </div>
              </Modal.Body>

              {isSkillExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;{isSkillExistMsg}
                </Alert>
              )}
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSkillLocation;
