import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Pagination, Dropdown } from "react-bootstrap";
import * as CityService from "../../service/city";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as moment from "moment";
import AddCityForm from "./AddCityForm";
import ReactPaginate from "react-paginate";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function CityIndex() {
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [cityDataFilter, setCityDataFilter] = useState({ status: "" });
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [city, setCity] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [departmentDataForExport, setDepartmentDataForExport] = useState({
    status: "",
  });
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const getCityList = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    CityService.getCityList(
      handleKey(search, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        setCityList(response?.data?.dataList?.result);
        setCityData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (search, status, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const getExportDetails = (
    search,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    CityService.getExportValue(
      handleKey(search, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "City.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const deleteCity = (id, idx) => {
    let dataId = { id: idx };
    CityService.deleteCity(id, dataId)
      .then((res) => {
        if (res.data.status == true) {
          getCityList(
            searchStr,
            cityDataFilter.status,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id, cityName) => {
    confirmAlert({
      title: "Delete City ",
      message: `Are you sure to delete ${cityName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCity(id, id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getCityList(
      searchStr,
      cityDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const onClose = () => {
    setShowForm(false);
  };
  const editState = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setCity(eData);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getCityList(
      searchStr,
      cityDataFilter.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const addNewState = () => {
    setCity(null);
    setEditMode(false);
    setShowForm(true);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getCityList(
        e.target.value.toLowerCase(),
        cityDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const mainDataFilter = () => {
    setDepartmentDataForExport({
      ...departmentDataForExport,
      status: cityDataFilter.status,
    });
    const stats = cityDataFilter.status;
    getCityList(
      searchStr,
      stats,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  useEffect(() => {
    getCityList(
      searchStr,
      cityDataFilter.status,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  const filterShow = () => {
    filteropen();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(cityDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setCityDataFilter(ClearData);
    filterClose();
    getCityList(searchStr, "", 1, pageSizeNo, sortData.sort_by, true);
  };

  const filterClose = () => {
    filterclose();
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getCityList(
        data,
        cityDataFilter.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getCityList(
      searchStr,
      cityDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <ToastContainer autoClose={1000} />
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">City Master</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>City Master </Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        State Name,
                        <br />
                        City Name
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on State Name, City Name..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          disabled={cityList.length !== 0 ? false : true}
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              departmentDataForExport.status,
                              1,
                              pageSizeNo,
                              sortData.sort_by,
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    class="btn btn-primary-inner"
                    onClick={() => {
                      addNewState();
                    }}
                  >
                    <img src="images/upload.png" alt="" class="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th
                // onClick={(e) => {
                //   handlerData("state_name");
                // }}
                // className={
                //   sortData["state_name"] === undefined
                //     ? "unsortcls main-pointer"
                //     : sortData["state_name"]
                // }
                >
                  State Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("name");
                  }}
                  className={
                    sortData["name"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["name"]
                  }
                >
                  City Name
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_by");
                  }}
                  className={
                    sortData["updated_by"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_by"]
                  }
                >
                  Last Updated By
                </th>
                <th
                  onClick={(e) => {
                    handlerData("updated_at");
                  }}
                  className={
                    sortData["updated_at"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["updated_at"]
                  }
                >
                  Last Updated On
                </th>
                <th
                  onClick={(e) => {
                    handlerData("is_active");
                  }}
                  className={
                    sortData["is_active"] === undefined
                      ? "unsortcls main-pointer"
                      : sortData["is_active"]
                  }
                >
                  Status
                </th>
                {finalUrlValue?.E || finalUrlValue?.R ? <th>Actions</th> : ""}
              </tr>
            </thead>
            <tbody>
              {cityList?.length ? (
                cityList?.map((data, index) => (
                  <tr>
                    <td
                      style={{
                        whiteSpace: "pre",
                      }}
                    >
                      {data?.state_name}
                    </td>
                    <td
                      style={{
                        whiteSpace: "pre",
                      }}
                      class="text-theme fw-bold"
                    >
                      {data?.name}
                    </td>
                    <td>
                      {data?.created_by == null ? " - " : data?.created_by}{" "}
                    </td>
                    <td>
                      {moment.utc(data?.updated_at).format("DD-MM-YYYY,HH:mm")}
                    </td>
                    <td>
                      {data?.is_active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    {finalUrlValue?.E || finalUrlValue?.R ? (
                      <td>
                        {finalUrlValue?.E ? (
                          <button
                            class="btn btn-blanktd text-primary"
                            onClick={() => {
                              editState(data);
                            }}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        {finalUrlValue?.R ? (
                          <button class="btn btn-blanktd text-danger ml-2">
                            <i
                              class="far fa-trash-alt text-danger"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            ></i>
                          </button>
                        ) : (
                          ""
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {cityList?.length == 0 ? (
          ""
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setCityDataFilter({
                  ...cityDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={cityDataFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value={true} label="Active" />
              <option value={false} label="Inactive" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddCityForm
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editState={city}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CityIndex;
