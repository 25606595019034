import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getFaqs = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/faq?${queryParam}`);
};

export const getFaqCategory = async () => {
  return axios.get(`${AUTH_BASE_URL}/faq/faq-category/list`);
};

export const addFaq = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/faq`, data);
};

export const updateFaq = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/faq/${id}`, data);
};

export const deleteFaq = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/faq/${id}`);
};

export const exportFaqData = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/faq/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
