import React, { useState, useEffect } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import * as HeaderService from "../../service/jv_service";

import { toast, ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import JvRmApproval from "./JvRmApproval";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
if (typeof window !== "undefined") {
  injectStyle();
}
function JvRmApprovalMaster() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [jvRmList, setJvRmList] = useState([]);
  const [jvRmPaginateData, setJvRmPaginateData] = useState([]);
  const [department, setDepartment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const getAllRmApprovalList = (search, page_no, page_size) => {
    dispatch(setDisplayLoader("Display"));
    HeaderService.getAllRmApprovalList(search, page_no, page_size)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setJvRmList(response?.data?.dataList?.result);
        setJvRmPaginateData(response?.data?.dataList?.paginated_data);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const deleteUserRmList = (id) => {
    HeaderService.deleteUserRmList(id).then((response) => {
      getAllRmApprovalList("", currentPage, pageSizeNo);
    });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Module",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteUserRmList(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addDepart = () => {
    setShowForm(true);
    setEditMode(false);
    setDepartment(null);
  };
  const editDept = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setDepartment(eData);
  };
  const onClose = () => {
    setShowForm(false);
    setDepartment(null);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getAllRmApprovalList("", currentPage, pageSizeNo);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getAllRmApprovalList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo
      );
      setSearchStr(e.target.value);
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getAllRmApprovalList(searchStr, activePage, pageSizeNo);
  };

  useEffect(() => {
    getAllRmApprovalList("", currentPage, pageSizeNo);
  }, [pageSizeNo]);

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAllRmApprovalList(data, currentPage, pageSizeNo);
    }
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">JV RM</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Jv Rm</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <div class="input-group table-search">
                <span class="input-group-text">
                  <img src="images/search.png" alt="" />
                </span>
                <input
                  type="text"
                  id="searchbar"
                  class="form-control"
                  placeholder="Search..."
                  onChange={(e) => {
                    handleSearch(e);
                    setCurrentPage(1);
                  }}
                />
                <span class="input-group-text border-0">
                  {searchStr !== "" ? (
                    <i
                      class="far fa-times-circle cursor-pointer"
                      onClick={() => {
                        closeButtonCallBack();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <button
                  className="btn btn-primary-inner"
                  onClick={() => {
                    addDepart();
                  }}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Reporting Manager</th>
                <th>User Name </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {jvRmList.length
                ? jvRmList?.map((data, index) => (
                    <>
                      <tr key={`dept${index}`}>
                        <td>
                          {data?.rm_id?.first_name} {data?.rm_id?.last_name} (
                          {data?.rm_id?.username})
                        </td>
                        <td className="text-theme fw-bold">
                          {data?.user_id?.first_name} {data?.user_id?.last_name}{" "}
                          ({data?.user_id?.username})
                        </td>
                        <td>
                          <button
                            className="btn btn-blanktd text-primary ml-2"
                            onClick={() => {
                              editDept(data);
                            }}
                          >
                            <i className="far fa-edit"></i>
                          </button>
                          <button
                            className="btn btn-blanktd text-danger ml-2"
                            onClick={() => {
                              submit(data?.id);
                            }}
                          >
                            <i className="far fa-trash-alt text-danger"></i>
                          </button>
                        </td>
                      </tr>
                    </>
                  ))
                : "No Record Found"}
            </tbody>
          </table>
        </div>
        {jvRmList?.length == 0 ? (
          ""
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      {showForm ? (
        <>
          <JvRmApproval
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editDepartment={department}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default JvRmApprovalMaster;
