import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import AllUrlsCashbook from "./AllUrlsCashbook";
import AddExpenses from "./add-expenses";
import { Tooltip } from "react-tippy";
import CustomPagination from "./CustomPagination";
import { toast, ToastContainer } from "react-toastify";
import * as cashbookService from "../../service/cashbookService";
import moment from "moment";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as searchPoService from "../../service/searchpo";

function CashExpenses() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location?.state?.defaultActiveTab
      ? location?.state?.defaultActiveTab
      : "addExpenses"
  );
  const initialValues = {
    list: [],
    totalPages: 0,
    query: {
      search: "",
      page_size: 10,
      page_no: 1,
      paginate: true,
      sort_by: "-id",
    },
  };
  const userData = useSelector(selectUserData);
  const [show, filtersetShow] = useState(false);
  const [data, setData] = useState(initialValues);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const navigate = useNavigate();

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const getData = async (query) => {
    try {
      const response = await cashbookService.getCashbookList(query);
      if (response.data.code === 200) {
        dispatch(setDisplayLoader("Hide"));
        setData({
          list: response?.data?.dataList?.result,
          totalPages: response?.data?.dataList?.paginated_data?.totalPages,
          query: {
            ...query,
            page_no: response?.data?.dataList?.paginated_data?.currentPage,
          },
        });
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      dispatch(setDisplayLoader("Hide"));
      toast.warn(error.message);
    }
  };
  const getExportDetails = () => {
    cashbookService
      .getExportValueExpenses(data?.query)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookExpenses.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKeyCB = (user_id, from_date, to_date, file_type) => {
    let queryParm = "user_id=" + user_id;

    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (file_type !== "" && file_type !== undefined && file_type !== null) {
      queryParm = queryParm + "&file_type=" + file_type;
    }

    return queryParm;
  };
  const newDateData = new Date();
  const fdd = moment(newDateData).format("YYYY-MM-DD");
  const getExportDetailsCB = (user_id, from_date, to_date, file_type) => {
    cashbookService
      .getExportValueCBReport(
        handleKeyCB(user_id, from_date, to_date, file_type)
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookExpenses.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPdfDetailsCB = (user_id, from_date, to_date, file_type) => {
    cashbookService
      .getExportValueCBReport(
        handleKeyCB(user_id, from_date, to_date, file_type)
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookReport.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const getExportDetailsCB = () => {
  //   cashbookService
  //     .getExportValueCBReport(
  //       `user_id=${userData?.id}&from_date=${fdd}&to_date=${fdd}&file_type=excel`
  //     )
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "CashbookReport.xlsx");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  // const getPdfDetailsCB = () => {
  //   cashbookService
  //     .getExportValueCBReport(
  //       `user_id=${userData?.id}&from_date=${fdd}&to_date=${fdd}&file_type=pdf`
  //     )
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "CashbookReport.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  // const handleKey = (search, page_no, page_size, sort_by, paginate) => {
  //   let queryParm =
  //     "page_no=" +
  //     page_no +
  //     "&page_size=" +
  //     page_size +
  //     "&paginate=" +
  //     paginate;
  //   if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
  //     queryParm = queryParm + "&sort_by=" + sort_by;
  //   }
  //   if (search !== "" && search !== undefined && search !== null) {
  //     queryParm = queryParm + "&search=" + search;
  //   }

  //   return queryParm;
  // };

  const handlePageClick = (page) => {
    let activePage = page.selected + 1;
    const query = { ...data.query, page_no: activePage };
    getData(query);
  };

  const handlePageCountChange = (count) => {
    const query = { ...data.query, page_size: count };
    getData(query);
  };

  const handleSearch = (text) => {
    let query = { ...data.query, search: text };
    // setSearchStr(text)
    // console.log({query});
    getData(query);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
    }
    const query = { ...data.query, search: "" };
    getData(query);
    // let closeBar = document.getElementById("searchbar");
    // if (closeBar.value != "") {
    //   closeBar.value = "";
    //   let data = "";
    //   setSearchStr("");
    //   getData(query);
    // }
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getData({ ...data.query, sort_by: sortData.sort_by });
  };

  useEffect(() => {
    if (activeTab === "addExpenses") return;
    const status = activeTab === "reverselEntry" ? "reverse" : undefined;
    const query = { ...initialValues.query, status };
    dispatch(setDisplayLoader("Display"));
    getData(query);
    // setData({ ...initialValues });
  }, [activeTab]);
  const filterShow = () => {
    filteropen();
  };

  const handleFilter = (key, value) => {
    const temp = { ...data, query: { ...data.query } };
    temp.query[key] = value;
    setData(temp);
    console.log(temp, "hitemp");
  };
  const applyFilter = () => {
    filterclose();
    getData(data?.query);
  };
  const resetFilter = () => {
    filterclose();
    getData({ searchStr: data.query.searchStr });
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getData(data.query);
    getSapSchedule()
  }, []);

  const rePostCashExpense = (id) => {
    let obj = {
      id: id
    }
    cashbookService.rePostExpense(obj).then((response)=>{
      dispatch(setDisplayLoader("Display"));
      getData(data.query);
      toast.success('Request submitted successfully, Kindly check after 30 Mins.')
    })
  }

  const getSapSchedule = () =>{
    searchPoService.get_sap_schedule('sync_post_expense_in_sap').then((response)=>{
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_reverse_payment_entry').then((response)=>{
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <div class="row">
        <ToastContainer autoClose={1000} />
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Manage Expenses</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Expenses</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              {activeTab === "addExpenses" ? (
                <div className="row">
                  <div class="col-lg-4"></div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={
                                data?.query?.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetailsCB(
                                  userData?.id,
                                  data.query.from_date == "" ||
                                    data.query.from_date == undefined
                                    ? fdd
                                    : data.query.from_date,
                                  data.query.to_date == "" ||
                                    data.query.to_date == undefined
                                    ? fdd
                                    : data.query.to_date,
                                  "excel"
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Print
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={
                                data?.query?.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getPdfDetailsCB(
                                  userData?.id,
                                  data.query.from_date == "" ||
                                    data.query.from_date == undefined
                                    ? fdd
                                    : data.query.from_date,
                                  data.query.to_date == "" ||
                                    data.query.to_date == undefined
                                    ? fdd
                                    : data.query.to_date,
                                  "pdf"
                                )
                              }
                              // onClick={() => getPdfDetailsCB()}
                            >
                              <i className="far fa-file-excel"></i>Print
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activeTab === "purchase" || activeTab === "reverselEntry" ? (
                <div className="row">
                  <div class="col-lg-4">
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="searchbar"
                        placeholder="Search..."
                        // value={searchStr}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <span class="input-group-text border-0">
                        {data?.query?.search?.length >= 3 ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={closeButtonCallBack}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">
                            <button
                              disabled={
                                data?.query?.length !== 0 ? false : true
                              }
                              style={{ border: 0, background: "none" }}
                              onClick={() =>
                                getExportDetails(
                                  searchStr,
                                  1,
                                  pageSizeNo,
                                  sortData.sort_by,
                                  true
                                )
                              }
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div class="approval-bg no-bg border-0 purchaseinnertabs">
              <Tabs
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                id="approvaltabs"
              >
                <Tab
                  activeKey={activeTab}
                  eventKey="addExpenses"
                  title={<React.Fragment>Add Expenses</React.Fragment>}
                >
                  {activeTab === "addExpenses" && (
                    <AddExpenses
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                    />
                  )}
                </Tab>
                <Tab
                  eventKey="purchase"
                  title={<React.Fragment>Status report</React.Fragment>}
                >
                  <div className="col-md-6 mt-2">
                      <b style={{fontSize:"13px"}}>Next SAP Posting:</b> <span className="mr-3" style={{fontSize:"13px"}}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                    </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            Request ID
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("created_by_name");
                            }}
                            className={
                              sortData["created_by_name"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["created_by_name"]
                            }
                          >
                            Created By
                          </th>

                          <th
                            onClick={(e) => {
                              handlerData("total_amount");
                            }}
                            className={
                              sortData["total_amount"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["total_amount"]
                            }
                          >
                            Claim Amount
                          </th>

                          <th>Approved Amount</th>
                          <th>SAP No.</th>
                          <th>SAP Message</th>
                          <th
                            onClick={(e) => {
                              handlerData("status");
                            }}
                            className={
                              sortData["status"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["status"]
                            }
                          >
                            Status
                          </th>
                          <th>Approver</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data.list) &&
                          data.list.map((item, i) => (
                            <tr key={`status-${item.id}`}>
                              <td
                                className="fw-bold text-theme"
                                onClick={() =>
                                  navigate("/view-cash-expenses", {
                                    state: item,
                                  })
                                }
                              >
                                PICB{item?.id}
                              </td>
                              {/* <td>{i + 1}</td> */}
                              {/* <td
                                class="fw-bold text-theme cursor-pointer"
                                onClick={() => {
                                  onView(item);
                                }}
                              >
                                {item?.id}
                              </td> */}
                              <td>{item.created_by_name}</td>

                              {/* <Tooltip
                                  title={
                                    item?.expense_detail[0]?.sap_reverse_message
                                  }
                                  position="bottom"
                                >
                                  {item?.expense_detail[0]
                                    ?.sap_reverse_message ?? "--"}
                                </Tooltip> */}

                              <td>{item.total_amount}</td>
                              {item.status == "A" ? (
                                <td>{item.total_amount}</td>
                              ) : (
                                "--"
                              )}
                              {item?.expense_detail?.length > 0 ?
                              <>
                              <td>{item?.expense_detail[0]?.document_no}</td>
                              <td>
                              <Tooltip title={item?.expense_detail[0]?.sap_message} position="bottom">
                                {item?.expense_detail[0]?.sap_message}
                              </Tooltip>
                              </td>
                              </>
                              :
                              <>
                              <td></td>
                              <td></td>
                              </>
                              }
                              <td>
                                {/* {item?.status ?? "-"} */}
                                {item.status == "A" ? (
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                ) : item.status == "P" ||
                                  item?.status == null ? (
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                ) : item.status == "R" ? (
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                ) : item.status == "reverse" ? (
                                  <span class="badge bad-status badge-danger">
                                    Reversed
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {
                                  item?.current_approver_data
                                    ?.current_approver_name
                                }
                                (
                                {
                                  item?.current_approver_data
                                    ?.current_approver_code
                                }
                                )
                                {/* {`${userData?.reporting_to_fname} ${userData?.reporting_to_lname} `}
                                ({userData?.reporting_to_name}) */}
                              </td>
                              <td>
                                {item?.expense_detail?.length > 0 && item?.expense_detail[0]?.sap_message !== null && (item?.expense_detail[0]?.document_no === null || item?.expense_detail[0]?.document_no === '123456') ?
                              <button class="btn" onClick={()=>{rePostCashExpense(item?.id)}}>
                                <span class="badge badge-secondary">
                                  <i class="far fa-copy"></i> Re-Post
                                </span>
                              </button>
                              :
                              ""
                                }
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* {data?.list} */}
                  {data?.list?.length == "" ? (
                    "No Record Found"
                  ) : (
                    <CustomPagination
                      onChange={(e) => handlePageCountChange(e.target.value)}
                      handlePageClick={(page) => handlePageClick(page)}
                      pageCount={data.totalPages}
                    />
                  )}
                </Tab>
                <Tab
                  eventKey="reverselEntry"
                  title={<React.Fragment>Entry Reversal</React.Fragment>}
                >
                  <div className="col-md-6 mt-2">
                      <b style={{fontSize:"13px"}}>Next SAP Posting:</b> <span className="" style={{fontSize:"13px"}}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                    </div>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured">
                      <thead>
                        <tr>
                          <th
                            onClick={(e) => {
                              handlerData("id");
                            }}
                            className={
                              sortData["id"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["id"]
                            }
                          >
                            Doc. No
                          </th>
                          <th>Status</th>
                          <th>GL Desc</th>
                          {/* <th>Bill Description</th> */}
                          <th
                            onClick={(e) => {
                              handlerData("total_amount");
                            }}
                            className={
                              sortData["total_amount"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["total_amount"]
                            }
                          >
                            Amount
                          </th>
                          <th
                            onClick={(e) => {
                              handlerData("created_at");
                            }}
                            className={
                              sortData["created_at"] === undefined
                                ? "unsortcls main-pointer"
                                : sortData["created_at"]
                            }
                          >
                            Date
                          </th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(data.list) &&
                          data.list.map((item, i) => (
                            <tr key={`status-${item.id}`}>
                              {/* <td class="text-theme"> */}
                              {console.log(item, "item")}
                              <td
                                className="fw-bold text-theme"
                                onClick={() =>
                                  navigate("/view-cash-expenses", {
                                    state: item,
                                  })
                                }
                              >
                                PICB{item.id}
                              </td>
                              {/* </Link> */}
                              {/* </td> */}
                              <td>
                                <span class="badge bad-status badge-success">
                                  {item?.status == "reverse" ? "Reversed" : ""}
                                </span>
                              </td>
                              <td>
                                {/* {item?.expense_detail?.map((itm, i) => {
                                  console.log(itm?.gl_description, "okitmkanu");
                                })} */}
                                {item?.expense_detail[0]?.expense_gl_desc}
                              </td>
                              {/* <td>
                                {item?.expense_detail?.map((itm, i) => {
                                  console.log(
                                    itm?.bill_description,
                                    "okitmkanu"
                                  );
                                })}
                              </td> */}
                              <td>{item.total_amount}</td>
                              <td>
                                {moment(item.created_at).format("DD-MM-YYYY")}
                              </td>

                              {/* <td>--</td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <CustomPagination
                    onChange={(e) => handlePageCountChange(e.target.value)}
                    handlePageClick={(page) => handlePageClick(page)}
                    pageCount={data.totalPages}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input
              onChange={(e) => handleFilter("from_date", e.target.value)}
              value={data.query?.from_date}
              // max={moment(data.query.to_date).format("YYYY-MM-DD")}
              name="from_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input
              onChange={(e) => handleFilter("to_date", e.target.value)}
              value={data.query?.to_date}
              // max={moment(data.query.from_date).format("YYYY-MM-DD")}
              name="to_date"
              type="date"
              class="form-control"
              placeholder="Placeholder"
            />
          </div>
          {activeTab === "addExpenses" ? (
            ""
          ) : (
            <>
            <div class="form-group innergroup">
              <label>
                Status<span class="text-danger">*</span>
              </label>
              <select
                class="form-control newbgselect"
                onChange={(e) => handleFilter("status", e.target.value)}
                value={data?.query?.status}
                >
                <option value="">Select</option>
                <option value="A">Approved</option>
                <option value="R">Rejected</option>
                <option value="P">Pending</option>
              </select>
            </div>
            <div class="form-group innergroup">
              <label>
                SAP Status<span class="text-danger">*</span>
              </label>
              <select
                class="form-control newbgselect"
                onChange={(e) => handleFilter("sap_status", e.target.value)}
                value={data?.query?.sap_status}
              >
                <option value="">Select</option>
                <option value="E">Error</option>
                <option value="S">Success</option>
              </select>
            </div>
                </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CashExpenses;
