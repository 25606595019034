import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";
import * as TravelLists from "../../service/travellist";
import { Modal, Badge } from "react-bootstrap";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import ViewTravelAll from "./Domestic/ViewTravelAll";
import { toast } from "react-toastify";
import ConfirmModal from "../CommonComp/ConfirmModal";
import { Tooltip } from "react-tippy";
import { confirmAlert } from "react-confirm-alert";
import { uploadfile } from "../../service/Survey";
import SapStatusModal from "./sapStatusModal";
import { PdfIcon } from "../HigherEducation/Icon";

function OtherExpenseListingSap() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [travelLogModal, setTravelLogModal] = useState(false);
  const [travelLogList, setTravelLogList] = useState([]);
  const [viewForm, setViewForm] = useState(false);
  const [flagValue, setFlagValue] = useState();
  const [viewFormData, setViewFormData] = useState(null);
  const [viewAllForm, setViewAllForm] = useState(false);
  const [viewInternationalForm, setViewInternationalForm] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [show, filtersetShow] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-from_date",
  });
  const [travelList, setTravelList] = useState([]);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [addnewOutstandingShow, addnewsetOutstandingShow] = useState(false);
  const addnewopenOut = () => addnewsetOutstandingShow(true);
  const addnewcloseOut = () => addnewsetOutstandingShow(false);
  const [userDataNew, setUserDataNew] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [cityDataTo, setCityDataTo] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendIdData, setSendIdData] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [visible, setVisible] = useState({ flag: false, id: "", data: "" });
  const [uploadData, setUploadData] = useState({});
  const userData = useSelector(selectUserData);
  const [modalState, setModalState] = useState(null);
  const [travelchats, setTravelChats] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const finalUser = useSelector(selectUserData);

  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    message: "",
    attachment_path: null,
  });
  const [viewSapData, setViewSapData] = useState();
  const [confirmSapModal, setConfirmSapModal] = useState(false);
  const [outstandingValues, setOutstandingValues] = useState();
  const [outstandingAmt, setOutstandingAmt] = useState([]);

  function handleCLOSE2() {
    setModalState("close");
  }
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });

  const [filterTravel, setFilterTravel] = useState({
    user_id: "",
    from_date: "",
    request_type: "",
    status: "A",
    request_type: "",
  });
  const userDetails = useSelector(selectUserData);

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];

    const fileData = new FormData();
    fileData.append("uploaded_file", files);

    uploadfile(fileData).then((response) => {
      // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      setUploadData(response.data);
      console.log(`response`, response.data);
      set_travelPostData({
        ...travelPostData,
        attachment_path: response.data,
      });
    });
  };

  const removeImageByIdx = (idx) => {
    console.log(`images bef`, imageLocalPreviewList);

    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    // console.log(`images aft`,newIList);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  const onProfileChange2 = (e, flag) => {
    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];

      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      // console.log("files", afile.type);

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);

      // console.log(`...`,fileData);

      // uploadfile(fileData).then((response) => {
      //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      //   setUploadData(response.data)
      //   console.log(`response`,response.data);
      //   setPostData({
      //     ...postData,
      //     attachment_path:response.data
      //   })

      // })
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);

    console.log(`files...`, filesToUpload);
  };

  const postTravelChat = async () => {
    dispatch(setDisplayLoader("Display"));
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.");
      return;
    }
    console.log("posted chat->", travelPostData);
    let payl = { ...travelPostData };

    let attachments2 = [];

    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          console.log(`uploaded for index`, afilee);

          attachments2.push(response?.data);
          // console.log(`arrres..`,attachments2);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `);
          return;
        });
    }

    payl.attachment_list = [...attachments2];

    // TravelListingAPI.postTravelChat(
    //   travelPostData.travel_id,
    //   travelPostData
    // ).then((response) => {
    //   console.log("posted chat->", travelPostData);
    //   getTravelChat(travelPostData.travel_id);
    //   set_travelPostData({
    //     ...travelPostData,
    //     message: "",
    //     attachment_path: null,
    //   });
    //   document.getElementById("querytext").value = "";
    // });

    TravelListingAPI.postTravelChat(travelPostData.travel_id, payl)
      .then((response) => {
        console.log("posted chat->", travelPostData);
        getTravelChat(travelPostData.travel_id);
        set_travelPostData({
          ...travelPostData,
          message: "",
          attachment_path: null,
        });
        document.getElementById("querytext").value = "";

        dispatch(setDisplayLoader("Hide"));
        setselectedFiles([]);
        setFilesToUpload([]);
        set_imageLocalPreviewList([]);
      })
      .catch((err) => {
        toast.error("Unable to post data.");
      });
  };

  const getTravelList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getTravelList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        // sap_trip_flag,
        status,
        travel_id,
        request_type,
        from_date,
        to_date
      ) + "&exclude_type=On Duty"
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        // response?.data?.dataList?.result?.map((data,idx) => {
        //   getTravelChat(data?.id)
        // })

        setTravelList(
          response?.data?.dataList?.result.map((obj) => {
            let lastestDate = null;
            let latestRemark = "";
            obj.expense_approver_remark_data.forEach((ele) => {
              if (
                lastestDate === null ||
                moment(ele.updated_at).isAfter(lastestDate)
              ) {
                lastestDate = ele.updated_at;
                latestRemark = ele.remark;
              }
            });
            obj.latestRemark = latestRemark;
            return obj;
          })
          // ?.filter(
          //   (data) => {
          //   if (!data.request_type.includes("On Duty")) {
          //     return true;
          //   }
          // }
          // )
        );
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    // sap_trip_flag,
    status,
    travel_id,
    request_type,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    if (
      request_type !== "" &&
      request_type !== undefined &&
      request_type !== null
    ) {
      queryParm = queryParm + "&request_type=" + request_type;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (travel_id !== "" && travel_id !== undefined && travel_id !== null) {
      queryParm = queryParm + "&travel_id=" + travel_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };
  const getUserDetails = async () => {
    await TravelListingAPI.getUserDetails()
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.username,
            label: `${obj?.first_name}`,
          };
        });
        setUserDataNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onConfirmClose = () => {
    setConfirmModal(false);
  };
  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityData(res);
        setCityDataTo(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var todayD = new Date();
  var timeD =
    todayD.getHours() + ":" + todayD.getMinutes() + ":" + todayD.getSeconds();
  console.log(timeD, "timeD");

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getTravelList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getTravelList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "A"
      );
      // "False"
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getTravelList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        "A"
        // "False"
      );
    }
  };

  const onView = (data) => {
    setViewAllForm(true);
    setViewFormData(data);
    setFlagValue(1);
  };

  const onViewClose = () => {
    setViewForm(false);
    setViewInternationalForm(false);
    setMigrate(false);
    setViewAllForm(false);
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };

  const filterClose = () => {
    filterclose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterTravel));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterTravel(ClearData);
    filterClose();
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      // "False",

      "A",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterTravel.from_date;
    const user_id = filterTravel.user_id;
    const request_type = filterTravel.request_type;
    const to_date = filterTravel.to_date;
    const status = "A";

    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      // "False",
      status,
      "",
      request_type,
      from_date,
      to_date
    );
    filterClose();
  };

  const onCallGrid = () => {
    getTravelList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  };

  const deleteTravel = (id) => {
    TravelListingAPI.deleteTravel(id)
      .then((res) => {
        if (res.data.status == true) {
          onCallGrid();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  function getTravelChat(id) {
    TravelListingAPI.getTravelChat(id).then((response) => {
      console.log("get chat for id=>", response);

      console.log("chat->", response?.data?.dataList?.result?.length);
      setTravelChats(response?.data?.dataList?.result);
      set_travelPostData({
        ...travelPostData,
        travel_id: id,
      });
      // return response?.data?.dataList?.result?.length

      // setChatStatus(response?.data?.dataList?.result?.length)

      // setChatStatus({
      //   ...chatStatus,
      //   id:response?.data?.dataList?.result?.length==0
      // })
    });
  }

  const handleShowModalTwo = (id) => {
    setModalState(`query-modal-${id}`);
  };

  const getOutstandingAmount = (employee_code, travel_id) => {
    TravelListingAPI.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);
        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };
  const handleClose = () => {
    setVisible({ ...visible, flag: false });
    // callBackCancel();
  };

  useEffect(() => {
    getTravelList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "A"
      // "False"
    );
  }, [pageSizeNo]);
  const sapTripPopup = (id, data) => {
    confirmAlert({
      title: "",
      message: `You are Generating SAP trip No, After this you will not able to change tour.`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            setVisible({ ...visible, flag: true, id: id, data: data }),

          // onClick: () => saveInDraft(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const TravelSapNumber = (id, data) => {
    TravelLists.TravelSapNumber(id)
      .then((res) => {
        if (res.data.code === 200) {
          let dataV = JSON.parse(JSON.stringify(data));
          dataV.sap_trip_number = res?.data?.message;
          if (res?.data?.message?.includes("already exists")) {
            setVisible({ ...visible, flag: false });
            toast.error(res?.data?.message);
          } else {
            toast.success(res?.data?.message);

            navigate("/expense-claim", {
              state: dataV,
            });
          }
        } else if (res?.data?.code == 400) {
          setVisible({ ...visible, flag: false });
          toast.error(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    let localGroup = "";

    finalUser.groups.every((data) => {
      if (
        data?.name.toLowerCase() === "ssc user" ||
        data?.name.toLowerCase() === "ssc manager"
      ) {
        localGroup = data?.name.toLowerCase();
        console.log(`-->`, localGroup);
        userGroup._userGroup = localGroup;
        return false;
      }
      return true;
    });

    getCityList(false);
    getUserDetails();
    getOutstandingAmount(userData?.id, "");
  }, []);

  const onViewData = (data) => {
    setViewSapData(data);
    setConfirmSapModal(true);
  };

  const onClose = () => {
    setConfirmSapModal(false);
  };

  const getTravelLogValueList = (id) => {
    dispatch(setDisplayLoader("Display"));
    TravelLists.getTravelLogValueList(
      `sort_by=id&paginate=false&travel_id=${id}`
    )
      .then((response) => {
        setTravelLogList(response?.data?.dataList?.result);
        setTravelLogModal(true);
        dispatch(setDisplayLoader("Hide"));
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      {viewAllForm ? (
        <>
          <ViewTravelAll
            viewFormData={viewFormData}
            onViewClose={onViewClose}
            flagValue={flagValue}
            userDetails={userData}
          />
        </>
      ) : (
        <>
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-12">
                      <div class="col-md-4" style={{ float: "left" }}>
                        {" "}
                        <h4 class="inner-page-title">
                          Travel Expense - {travelList?.length}
                        </h4>
                      </div>
                      <div
                        class="col-md-8"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          float: "right",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          color: "#0195d4",
                        }}
                      >
                        <p>
                          *Dear user please note sending physical copy of
                          Invoice/Receipt to SSC team is mandatory for
                          reimbursement of this claim
                        </p>
                        <p>
                          *The claim button will be visible on or after the
                          arrival date.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          id="searchbar"
                          class="form-control"
                          placeholder="Search..."
                          onChange={(e) => {
                            handleSearch(e);
                            setCurrentPage(1);
                          }}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <button
                          onClick={filteropen}
                          class="btn btn-secondary-inner"
                          style={{
                            backgroundColor: filterApplied && "yellow",
                            color:
                              filterApplied &&
                              userDetails?.dark_mode === false &&
                              "#000",
                          }}
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured travelintim">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Actions</th>
                        <th>SAP Trip No</th>
                        <th>Departure Date</th>
                        <th>Arrival Date</th>
                        <th>Travel Type</th>
                        <th>Location</th>
                        <th>Day's</th>
                        {/* <th>Deviation Type</th> */}
                        <th>Status</th>
                        <th>Pending At</th>
                        <th>Rejected By</th>
                        <th>Approved/Rejected On</th>
                        <th>Approver/Rejected Remarks</th>
                        <th>Expense Approved On</th>
                        <th>Savior Status</th>
                        <th>Outstanding Amount Status</th>
                        <th>Advance Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {travelList?.length ? (
                        travelList?.map((data, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {/* <Link to="/expense-claim" class="mr-2"> */}
                              {moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ).format("YYYY-MM-DD") <
                                moment(new Date()).format("YYYY-MM-DD") ===
                                true && data?.sap_trip_number === null ? (
                                <button
                                  class="btn btn-primary-inner bpi-main f-14 py-1"
                                  onClick={() => sapTripPopup(data?.id, data)}
                                >
                                  <i class="far fa-check-circle"></i>
                                  Claim
                                </button>
                              ) : moment(
                                  data?.travel_detail_data[
                                    data?.travel_detail_data?.length - 1
                                  ]?.to_date
                                ).format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD") &&
                                data?.request_type !== "On Duty" &&
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_time < timeD &&
                                data?.sap_trip_number == null &&
                                data?.sap_trip_number === null ? (
                                <button
                                  class="btn btn-primary-inner bpi-main f-14 py-1"
                                  onClick={() => sapTripPopup(data?.id, data)}
                                >
                                  <i class="far fa-check-circle"></i>
                                  Claim
                                </button>
                              ) : (data?.claim_flag === false &&
                                  data?.status == "A" &&
                                  data?.sap_trip_number !== null) ||
                                data?.is_edit ||
                                data?.expense_approval_status == "R" ? (
                                <button
                                  class="btn btn-primary bpi-main f-14 px-2"
                                  onClick={() =>
                                    navigate("/expense-claim", {
                                      state: data,
                                    })
                                  }
                                >
                                  {/* Add/Edit */}
                                  <i class="fas fa-edit"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {data?.claim_flag === true ||
                              data?.is_edit == true ? (
                                <button
                                  target="_blank"
                                  class="btn btn-primary bpi-main px-2 py-1 f-14"
                                  onClick={() =>
                                    navigate("/expensesPrint", {
                                      state: data,
                                    })
                                  }
                                >
                                  <i class="fas fa-print"></i>
                                </button>
                              ) : (
                                ""
                              )}
                              &nbsp;
                              {data?.chat_status > 0 && (
                                <button
                                  class="btn btn-primary bpi-main px-3 py-1 f-14"
                                  onClick={() =>
                                    handleShowModalTwo(`${data?.id}`)
                                  }
                                >
                                  Query
                                </button>
                              )}
                              {/* {/* </Link> */}
                            </td>
                            <td>
                              <Link onClick={() => onView(data)}>
                                {data?.sap_trip_number ?? "-"}
                              </Link>
                            </td>
                            <td>
                              {moment(
                                data?.travel_detail_data[0]?.from_date
                              ).format("DD-MM-YYYY")}{" "}
                              {moment(
                                data?.travel_detail_data[0]?.from_time,
                                "HH:mm"
                              ).format("hh:mm A")}
                            </td>
                            <td>
                              {moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_date
                              ).format("DD-MM-YYYY")}{" "}
                              {moment(
                                data?.travel_detail_data[
                                  data?.travel_detail_data?.length - 1
                                ]?.to_time,
                                "HH:mm"
                              ).format("hh:mm A")}
                            </td>
                            <td>{data?.request_type}</td>
                            <td>
                              {data?.travel_detail_data[0]?.to_city_name ===
                              "Other"
                                ? data?.travel_detail_data[0]?.to_city_input
                                : data?.travel_detail_data[0]?.to_city_name}
                            </td>
                            <td>
                              {data?.travel_detail_data?.length > 0 &&
                                calculateDateDifference(
                                  data?.travel_detail_data[
                                    data?.travel_detail_data?.length - 1
                                  ]?.to_date,
                                  data?.travel_detail_data[0]?.from_date
                                )}
                            </td>
                            <td>
                              {data?.is_edit ? (
                                <span class="badge bad-status badge-warning">
                                  Correction Required
                                </span>
                              ) : !data?.claim_flag ? (
                                !data?.sap_trip_number ? (
                                  <span class="badge bad-status badge-success">
                                    TI Approved
                                  </span>
                                ) : (
                                  <span className="badge bad-status badge-secondary">
                                    Expenses Saved
                                  </span>
                                )
                              ) : data?.is_picked &&
                                data?.ssc_user_status &&
                                (data?.ssc_manager_status == "A" ||
                                  data?.ssc_head_status == "A") ? (
                                <span class="badge bad-status badge-success">
                                  {data?.expense_post_sap_status ||
                                  data?.current_status == "POSTED"
                                    ? "Posted"
                                    : "Approved"}
                                </span>
                              ) : data?.expense_approval_status == "D" ? (
                                <span className="badge bad-status badge-warning">
                                  Discarded
                                </span>
                              ) : data?.expense_approval_status == "R" ? (
                                <Badge pill bg="danger">
                                  Rejected
                                </Badge>
                              ) : data?.expense_approval_status == "A" &&
                                !data?.is_picked ? (
                                <Tooltip title="Document sent but not received by SSC">
                                  Document sent but not received by SSC
                                </Tooltip>
                              ) : (
                                <span className="badge bad-status badge-secondary">
                                  Pending
                                </span>
                              )}
                            </td>
                            <td>
                              {data?.claim_flag ? (
                                data?.expense_approval_status ||
                                data?.is_edit ? (
                                  <Tooltip
                                    title={
                                      // data?.current_status=="POSTED" ? (
                                      //   // <span class="badge bad-status badge-success">
                                      //   "Posted"
                                      // ) : // </span>
                                      data?.is_edit ? (
                                        "Requestor"
                                      ) : data?.expense_approval_status ==
                                        "A" ? (
                                        !data?.is_picked ? (
                                          <Tooltip title="Document sent but not received by SSC">
                                            Document sent but not received by
                                            SSC
                                          </Tooltip>
                                        ) : data?.is_picked &&
                                          !data?.ssc_user_status ? (
                                          `SSC Executive (${data?.is_picked_by_user_name} (${data?.is_picked_by_user_code}))`
                                        ) : data?.is_picked &&
                                          data?.ssc_user_status &&
                                          !data?.ssc_manager_status ? (
                                          `SSC Manager `
                                        ) : data?.is_picked &&
                                          data?.ssc_user_status &&
                                          data?.ssc_manager_status == "F" &&
                                          !data?.ssc_head_status ? (
                                          `SSC Head `
                                        ) : (
                                          // : data?.is_picked &&
                                          //   data?.ssc_user_status &&
                                          //   data?.ssc_manager_status
                                          // ? "Approved"
                                          ""
                                        )
                                      ) : data?.expense_approval_status ==
                                        "R" ? (
                                        // ? data?.approver_remark_data?.map(
                                        //     (rdata) => {
                                        //       return (
                                        //         rdata?.status == "R" &&
                                        //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                        //       );
                                        //     }
                                        //   )
                                        // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`

                                        // data?.expense_approver_remark_data?.map(
                                        //   (rdata) => {
                                        //     return (
                                        //       (rdata?.status == "R" &&
                                        //       rdata?.approver_username ==
                                        //         data
                                        //           ?.current_expense_exception_approver_data
                                        //           ?.current_approver_code) ?
                                        //       `${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username}))`:""
                                        //     );
                                        //   }
                                        // )
                                        ""
                                      ) : (
                                        data?.current_approver_data
                                          .current_approver_name !==
                                          undefined &&
                                        data?.status !== "A" &&
                                        // data?.status !== "D" &&
                                        data?.status !== "R" &&
                                        data?.approver_remark_data?.map(
                                          (rdata) => {
                                            return (
                                              rdata?.approver_username ==
                                                data?.current_approver_data
                                                  .current_approver_code &&
                                              `${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username}))`
                                            );
                                          }
                                        )
                                      )
                                    }
                                  >
                                    {
                                      // data.current_status=="POSTED" ? (
                                      //   <span class="badge bad-status badge-success">
                                      //     Posted
                                      //   </span>
                                      // ) :
                                      data?.is_edit ? (
                                        "Requestor"
                                      ) : data?.expense_approval_status ==
                                        "A" ? (
                                        !data?.is_picked ? (
                                          <Tooltip title="Document sent but not received by SSC">
                                            Document sent but not received by
                                            SSC
                                          </Tooltip>
                                        ) : data?.is_picked &&
                                          !data?.ssc_user_status ? (
                                          `SSC Executive (${data?.is_picked_by_user_name} (${data?.is_picked_by_user_code}))`
                                        ) : data?.is_picked &&
                                          data?.ssc_user_status &&
                                          !data?.ssc_manager_status ? (
                                          `SSC Manager `
                                        ) : data?.is_picked &&
                                          data?.ssc_user_status &&
                                          data?.ssc_manager_status == "F" &&
                                          !data?.ssc_head_status ? (
                                          `SSC Head `
                                        ) : (
                                          // : data?.is_picked &&
                                          //   data?.ssc_user_status &&
                                          //   data?.ssc_manager_status
                                          // ? "Approved"
                                          ""
                                        )
                                      ) : data?.expense_approval_status ==
                                        "R" ? (
                                        // ? data?.approver_remark_data?.map(
                                        //     (rdata) => {
                                        //       return (
                                        //         rdata?.status == "R" &&
                                        //         `Rejected by (${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username})))`
                                        //       );
                                        //     }
                                        //   )

                                        // `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                        // data?.expense_approver_remark_data?.map(
                                        //   (rdata) => {
                                        //     return (
                                        //       (rdata?.status == "R" &&
                                        //       rdata?.approver_username ==
                                        //         data
                                        //           ?.current_expense_exception_approver_data
                                        //           ?.current_approver_code) ?
                                        //       `${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username}))`:""
                                        //     );
                                        //   }
                                        // )
                                        ""
                                      ) : (
                                        data?.current_approver_data
                                          .current_approver_name !==
                                          undefined &&
                                        data?.status !== "A" &&
                                        // data?.status !== "D" &&
                                        data?.status !== "R" &&
                                        data?.approver_remark_data?.map(
                                          (rdata) => {
                                            return (
                                              rdata?.approver_username ==
                                                data?.current_approver_data
                                                  .current_approver_code &&
                                              `${rdata?.approver_position} (${rdata?.approver_name} (${rdata?.approver_username}))`
                                            );
                                          }
                                        )
                                      )
                                    }
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={
                                      data
                                        ?.current_expense_exception_approver_data
                                        ?.current_approver_name
                                        ? `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                        : `${data?.current_expense_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                    }
                                  >
                                    {data
                                      ?.current_expense_exception_approver_data
                                      ?.current_approver_name
                                      ? `${data?.current_expense_exception_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`
                                      : `${data?.current_expense_approver_data?.current_approver_name} (${data?.current_expense_exception_approver_data?.current_approver_code})`}
                                  </Tooltip>
                                )
                              ) : (
                                ""
                              )}

                              {/* {
                                !data?.current_expense_approver_data?.current_approver_code
                                ?"In Pick List"
                                :
                              } */}
                              {/* {<Tooltip
                                title={
                                  data?.current_expense_approver_data
                                    ?.current_approver_code
                                }
                                position="bottom"
                              >
                                {data?.current_expense_approver_data ?.current_approver_name !== undefined &&
                                  `${data?.current_expense_approver_data?.current_approver_name}
                                - (
                                ${data?.current_expense_approver_data?.current_approver_code}
                                )`}
                              </Tooltip>

                            } */}

                              {/* <Tooltip
                                title={
                                  data?.current_approver_data
                                    ?.current_approver_code
                                }
                                position="bottom"
                              >
                                {data?.current_approver_data
                                  ?.current_approver_name !== undefined &&
                                  `${data?.current_approver_data?.current_approver_name}
                                - (
                                ${data?.current_approver_data?.current_approver_code}
                                )`}
                              </Tooltip> */}
                            </td>
                            <td>
                              {data?.expense_approver_remark_data[
                                data?.expense_approver_remark_data?.length - 1
                              ]?.status == "R" &&
                                data?.expense_approver_remark_data[
                                  data?.expense_approver_remark_data?.length - 1
                                ]?.approver_name}
                            </td>
                            <td>
                              {/* {data?.expense_approver_remark_data?.length > 1
                                ? moment
                                    .parseZone(
                                      data?.expense_approver_remark_data[
                                        data?.expense_approver_remark_data
                                          ?.length - 1
                                      ]?.updated_at
                                    )
                                    .format("DD-MM-YYYY hh:mm A")
                                : ""} */}
                              {data?.expense_approval_date !== null &&
                                moment
                                  .parseZone(data?.expense_approval_date)
                                  .format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>
                              {/* <Tooltip title={data?.remark} position="bottom">
                                {data?.remark}
                              </Tooltip> */}

                              {/* {data?.expense_approval_status == "R"
                                ? data?.expense_approver_remark_data?.map(
                                    (rdata) => {
                                      return (
                                        rdata?.status == "R" &&
                                        rdata?.approver_username ==
                                          data
                                            ?.current_expense_exception_approver_data
                                            ?.current_approver_code &&
                                        `${rdata?.remark}`
                                      );
                                    }
                                  )
                                : ""} */}
                              {data?.latestRemark}
                            </td>
                            <td>
                              {/* {data?.expense_approval_date === null
                                ? ""
                                : moment(data?.expense_approval_date)
                                    .utc()
                                    .format("DD-MM-YYYY")}{" "}
                              {data?.expense_approval_date === null
                                ? ""
                                : moment(
                                    data?.expense_approval_date,
                                    "HH:mm"
                                  ).format("hh:mm A")} */}
                              {data?.expense_exception
                                ? data?.expense_approver_remark_data?.length > 0
                                  ? moment
                                      .parseZone(
                                        data?.expense_approver_remark_data[
                                          data?.expense_approver_remark_data
                                            ?.length - 1
                                        ].updated_at
                                      )
                                      .format("DD-MM-YYYY hh:mm A")
                                  : ""
                                : data?.approver_remark_data?.length > 0
                                ? moment
                                    .parseZone(
                                      data?.approver_remark_data[
                                        data?.approver_remark_data?.length - 1
                                      ].updated_at
                                    )
                                    .format("DD-MM-YYYY hh:mm A")
                                : ""}
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                onClick={() => onViewData(data)}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                onClick={() => addnewopenOut()}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            </td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary"
                                type="button"
                                onClick={() => getTravelLogValueList(data.id)}
                              >
                                <img src="images/eye.png" alt="" />
                              </button>
                            </td>

                            {data?.chat_status > 0 && (
                              <Modal
                                show={modalState === `query-modal-${data?.id}`}
                                // onHide={addnewclose}
                                onHide={handleCLOSE2}
                                onShow={() => getTravelChat(data?.id)}
                                backdrop="static"
                                keyboard={false}
                                size="lg"
                                className="modaldefaultclose modaldefaultclosecenter"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Query to Manager</Modal.Title>
                                </Modal.Header>
                                <Modal.Body
                                  style={{
                                    maxHeight: "calc(100vh - 210px)",
                                    overflowY: "auto",
                                  }}
                                >
                                  <div class="row justify-content-center">
                                    <div class="col-md-11">
                                      <div class="row chtgptbox agent">
                                        {/* {travelchats?.length
                                          ? travelchats.map((cdata, idx) => (
                                              <div
                                                class={
                                                  cdata?.created_by_id !=
                                                  userDetails?.id
                                                    ? "col-md-12"
                                                    : "col-md-12 text-right"
                                                }
                                              >
                                                <div class="ticketreplytxt rounded text-break">
                                                  {cdata?.attachment && (
                                                    <a href={cdata?.attachment}>
                                                      <i class="far fa-file-pdf text-theme"></i>
                                                    </a>
                                                  )}
                                                  {cdata?.message}
                                                </div>
                                                <div class="mt-2">
                                                  <h6>
                                                    {moment
                                                      .parseZone(
                                                        cdata?.created_at
                                                      )
                                                      .format(
                                                        "DD-MM-YYYY hh:mm A"
                                                      )}
                                                  </h6>
                                                </div>
                                              </div>
                                            ))
                                          : ""} */}

                                        {travelchats?.length
                                          ? travelchats.map((cdata, idx) =>
                                              // <div class={ cdata?.created_by_id != userDetails?.id ? "col-md-12" : "col-md-12 text-right"}>
                                              //   <div class="ticketreplytxt rounded text-break">
                                              //   {cdata?.attachment && <a href={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></a>}
                                              //    {cdata?.message}

                                              //   </div>
                                              //   <div class="mt-2">
                                              //     <h6>{moment.parseZone(cdata?.created_at).format("DD-MM-YYYY hh:mm A")}</h6>
                                              //   </div>
                                              // </div>

                                              cdata?.created_by_id !=
                                              cdata?.travel_created_by_id ? (
                                                <div
                                                  style={{
                                                    borderRight:
                                                      "solid 2px #2e6da4",
                                                  }}
                                                  class={
                                                    "mb-3 col-md-12 text-right"
                                                  }
                                                >
                                                  <b>SSC</b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {cdata?.attachment && (
                                                      <Link
                                                        target="_blank"
                                                        to={cdata?.attachment}
                                                      >
                                                        <i class="far fa-file-pdf text-theme"></i>
                                                      </Link>
                                                    )}
                                                    {cdata?.attachment_list?.map(
                                                      (attdata, aidx) => (
                                                        <Link
                                                          target="_blank"
                                                          class="ml-2"
                                                          to={
                                                            attdata?.attachment_path
                                                          }
                                                        >
                                                          <i class="fas fa-paperclip text-theme"></i>
                                                        </Link>
                                                      )
                                                    )}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "solid 2px #eea236",
                                                  }}
                                                  class={
                                                    "mb-3 col-md-12 text-left "
                                                  }
                                                >
                                                  <b>User</b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {cdata?.attachment && (
                                                      <Link
                                                        target="_blank"
                                                        to={cdata?.attachment}
                                                      >
                                                        <i class="far fa-file-pdf text-theme"></i>
                                                      </Link>
                                                    )}
                                                    {cdata?.attachment_list?.map(
                                                      (attdata, aidx) => (
                                                        <Link
                                                          target="_blank"
                                                          class="ml-2"
                                                          to={
                                                            attdata?.attachment_path
                                                          }
                                                        >
                                                          <i class="fas fa-paperclip text-theme"></i>
                                                        </Link>
                                                      )
                                                    )}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : ""}

                                        <div class="col-md-12">
                                          <div class="border my-3"></div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="form-group innergroup position-relative modaldefaultclose ">
                                            <label>Upload files</label>
                                            <input
                                              type="text"
                                              class="form-control bg-white"
                                              disabled
                                              placeholder="(JPG, PNG Format only)"
                                            />
                                            <div class="upload-btn-wrapper up-loposition">
                                              <button class="uploadBtn">
                                                Browse
                                              </button>
                                              <input
                                                type="file"
                                                // onChange={(e) =>
                                                //   onProfileChange(
                                                //     e,
                                                //     "import_user"
                                                //   )
                                                // }
                                                onChange={(e) =>
                                                  onProfileChange2(
                                                    e,
                                                    "import_user"
                                                  )
                                                }
                                              />
                                            </div>
                                            {selectedFiles.map((dfile) => (
                                              <small class="mr-2">
                                                {dfile.name}
                                              </small>
                                            ))}
                                          </div>

                                          {imageLocalPreviewList.length ? (
                                            <div className="row">
                                              {imageLocalPreviewList.map(
                                                (idata, idx) => (
                                                  <>
                                                    {console.log(
                                                      "idata",
                                                      filesToUpload[idx].get(
                                                        "uploaded_file"
                                                      )
                                                    )}
                                                    {filesToUpload[idx]
                                                      .get("uploaded_file")
                                                      .name.includes(".pdf") ? (
                                                      <div class="col-md-4 text-center">
                                                        <img
                                                          src={
                                                            "images/policy-icon/pdf.png"
                                                          }
                                                          width="70px"
                                                          height="70px"
                                                        />
                                                        <i
                                                          role="button"
                                                          onClick={() =>
                                                            removeImageByIdx(
                                                              idx
                                                            )
                                                          }
                                                          class=" fas fa-trash-alt text-danger"
                                                        />
                                                      </div>
                                                    ) : filesToUpload[idx]
                                                        .get("uploaded_file")
                                                        .name.includes(
                                                          ".xls"
                                                        ) ? (
                                                      <div class="col-md-4 text-center">
                                                        <img
                                                          src={
                                                            "images/policy-icon/xlsx.png"
                                                          }
                                                          width="70px"
                                                          height="70px"
                                                        />
                                                        <i
                                                          role="button"
                                                          onClick={() =>
                                                            removeImageByIdx(
                                                              idx
                                                            )
                                                          }
                                                          class=" fas fa-trash-alt text-danger"
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div class="col-md-4 text-center">
                                                        <img
                                                          src={idata}
                                                          class="h-100"
                                                        />
                                                        <i
                                                          role="button"
                                                          onClick={() =>
                                                            removeImageByIdx(
                                                              idx
                                                            )
                                                          }
                                                          class=" fas fa-trash-alt text-danger"
                                                        />
                                                      </div>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div class="col-md-12">
                                          <div class="form-group innergroup">
                                            <label>
                                              Comment{" "}
                                              <span class="text-danger">*</span>
                                            </label>
                                            <textarea
                                              id="querytext"
                                              class="form-control"
                                              placeholder="Enter Comment"
                                              onChange={(e) =>
                                                set_travelPostData({
                                                  ...travelPostData,
                                                  message: e.target.value,
                                                })
                                              }
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    class="btn btn-outline-danger"
                                    // onClick={addnewclose}
                                    onClick={handleCLOSE2}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    class="btn btn-primary-inner bpi-main"
                                    onClick={postTravelChat}
                                  >
                                    Submit
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            )}
                            {/* <td>
                              {data?.status == "Cancelled" ||
                              data?.status == "C" ||
                              data?.status == "R" ||
                              data?.status == "A" ? (
                                ""
                              ) : data?.request_type == "On Duty" ? (
                                <button
                                  class="btn btn-danger bpi-main px-3 py-1 f-14"
                                  onClick={() => {
                                    sendId(data?.id);
                                  }}
                                >
                                  Cancel
                                </button>
                              ) : (
                                <>
                                  <button
                                    class="btn btn-blanktd text-primary"
                                    type="button"
                                    onClick={() => {
                                      OnEditGrid(data);
                                    }}
                                  >
                                    <i class="far fa-edit"></i>
                                  </button>
                                  <button
                                    class="btn btn-blanktd text-danger ml-2"
                                    type="button"
                                    onClick={() => submit(data?.id)}
                                  >
                                    <i class="far fa-trash-alt text-danger"></i>
                                  </button>
                                </>
                              )}
                              &nbsp; &nbsp;
                            </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={18}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {travelList?.length == 0 ? (
                  ""
                ) : (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div class="form-group innergroup">
                <label>
                  Employee Name<span class="text-danger">*</span>
                </label>
                <Select
                  options={userDataNew}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="user_id"
                  onChange={(e) => {
                    pushEmployee(e);
                  }}
                  value={userValue}
                />
              </div> */}

              <div class="form-group innergroup">
                <label>
                  From Date<span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  onChange={(e) =>
                    setFilterTravel({
                      ...filterTravel,
                      from_date: e.target.value,
                    })
                  }
                  value={filterTravel.from_date}
                />
              </div>
              {/* 
              <div class="form-group innergroup">
                <label>
                  Travel Type<span class="text-danger">*</span>
                </label>
                <select
                  class="form-control newbgselect"
                  placeholder="Select Travel Type"
                >
                  <option>Select</option>
                  <option>On Duty</option>
                  <option>Domestic</option>
                  <option>International</option>
                </select>
              </div> */}

              <div class="form-group innergroup">
                <label>Travel Type</label>
                <select
                  name="request_type"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      request_type: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.request_type}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {/* <option value="">All</option> */}
                  <option value="On Duty">On Duty</option>
                  <option value="Domestic">Domestic</option>
                  <option value="International">International</option>
                </select>
              </div>
              {/* 
              <div class="form-group innergroup">
                <label>Status</label>
                <select
                  name="status"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      status: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.status}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="R">Reject</option>
                  <option value="C">Cancelled</option>
                </select>
              </div> */}

              {/* <div class="form-group innergroup">
                <label>
                  Select Status<span class="text-danger">*</span>
                </label>
                <select class="form-control newbgselect">
                  <option>Select</option>
                  <option>Pending</option>
                  <option>Rejected</option>
                  <option>Approved</option>
                </select>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => clearFilter()}
              >
                Clear
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={addnewshow}
            onHide={addnewclose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                      <p class="fa-20 text-muted mb-4">
                        You are generating SAP Trip No, After this you will not
                        be able to change tour
                      </p>

                      <div class="mb-3">
                        <Link to="/claim-listing" class="mr-2">
                          <button class="btn btn-primary-inner bpi-main f-14 py-1">
                            Yes
                          </button>
                        </Link>
                        <button
                          class="btn btn-outline-primary f-14 py-1"
                          onClick={addnewclose}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      <Modal show={visible.flag} onHide={() => handleClose} centered>
        <Modal.Body
          style={{
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          Travel Expense should be matched with travel intimation created by
          employee
        </Modal.Body>
        <Modal.Footer>
          {/* {showCancel && ( */}
          <button
            type="button"
            className="btn btn-outline-secondary"
            variant="primary"
            style={{
              background: "#0195d4",
              borderColor: "#0195d4",
              color: "#fff",
              fontSize: "12px",
            }}
            onClick={() => TravelSapNumber(visible?.id, visible?.data)}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            variant="secondary"
            onClick={() => handleClose(false)}
            style={{
              background: "#0195d4",
              borderColor: "#0195d4",
              color: "#fff",
              fontSize: "12px",
            }}
          >
            No
          </button>
          {/* )} */}
        </Modal.Footer>
      </Modal>
      {confirmModal && (
        <ConfirmModal
          confirmModal={confirmModal}
          onConfirmClose={onConfirmClose}
          sendIdData={sendIdData}
          getTravelList={getTravelList}
        />
      )}

      {confirmSapModal && (
        <SapStatusModal
          viewSapData={viewSapData}
          confirmSapModal={confirmSapModal}
          onClose={onClose}
        />
      )}

      <Modal
        show={addnewOutstandingShow}
        onHide={addnewcloseOut}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div className="col-md-12">
              <div className="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Balance</th>
                      <th>Currency</th>
                      <th>Bill Date</th>
                      <th>Text</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outstandingValues?.length > 0 ? (
                      outstandingValues?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value?.__values__?.DMBTR}</td>
                          <td>{value?.__values__?.WAERS}</td>
                          <td>{value?.__values__?.BLDAT}</td>
                          <td
                            style={{
                              maxWidth: "80vh",
                            }}
                          >
                            {value?.__values__?.SGTXT}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={travelLogModal}
        // onHide={() => setTravelLogModal(false)}
        size="lg"
      >
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="fw-bold text-theme">Amount Logs</h5>
          <i
            className="fas fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => setTravelLogModal(false)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Advance</th>
                  <th>Sap Id</th>
                  <th>Sap Message</th>
                  <th>Sap Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {travelLogList?.length ? (
                  travelLogList?.map((data, index) => (
                    <tr>
                      <td
                        style={{
                          whiteSpace: "pre",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          whiteSpace: "pre",
                        }}
                        class="text-theme fw-bold"
                      >
                        {data?.advance}
                      </td>
                      <td>{data?.sap_id}</td>
                      <td title={data?.advance_sap_message}>
                        {data?.advance_sap_message}
                      </td>
                      <td>
                        {data?.sap_status == "S" ? "Posted" : data?.sap_status}
                      </td>
                      <td>
                        {moment
                          .utc(data?.created_at)
                          .format("DD-MM-YYYY,HH:mm")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OtherExpenseListingSap;
