import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import AddLeaveManage from "./AddLeaveManage";
import * as LeaveService from "../../service/leaveService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission, selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

if (typeof window !== "undefined") {
  injectStyle();
}
const LeaveManage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData)
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [leaveManageList, setLeaveManageList] = useState([]);
  const [leaveManageData, setLeaveManageData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [allLeaveManage, setAllLeaveManage] = useState([]);
  const [dropLeaveManage, setDropLeaveManage] = useState([]);
  const [allLeaveType, setAllLeaveType] = useState([]);
  const [leavemanage, setLeaveManage] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [leaveManageDataForExport, setLeaveManageDataForExport] = useState({
    leave_type_id: "",
    gender: "",
    status: "",
  });
  const [leaveManageDataFilter, setLeaveManageDataFilter] = useState({
    leave_type_id: "",
    gender: "",
    status: "",
  });

  const onClose = () => {
    setShowForm(false);
  };

  const getLeaveManageList = (
    search,
    leave_type_id,
    gender,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    LeaveService.getLeaveManageList(
      handleKey(
        search,
        leave_type_id,
        gender,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setLeaveManageList(response?.data?.dataList?.result);
        setLeaveManageData(response?.data?.dataList?.paginated_data);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const handleKey = (
    search,
    leave_type_id,
    gender,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (gender !== "" && gender !== undefined && gender !== null) {
      queryParm = queryParm + "&gender=" + gender;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    if (
      leave_type_id !== "" &&
      leave_type_id !== undefined &&
      leave_type_id !== null
    ) {
      queryParm = queryParm + "&leave_type_id=" + leave_type_id;
    }
    return queryParm;
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getLeaveManageList(
      searchStr,
      leaveManageDataFilter.leave_type_id,
      leaveManageDataFilter.gender,
      leaveManageDataFilter.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };
  const getLeaveManageListData = (paginate) => {
    let query = "sort_by=code";
    LeaveService.getLeaveManageListData(query)
      .then((response) => {
        setAllLeaveType(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const editLeaveManage = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setLeaveManage(eData);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    getLeaveManageList(
      searchStr,
      leaveManageDataFilter.leave_type_id,
      leaveManageDataFilter.gender,
      leaveManageDataFilter.status,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
  };
  const addNewBranch = () => {
    setEditMode(false);
    setShowForm(true);
    setLeaveManage(null);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    // let activePage = currentPage;
    setCurrentPage(activePage);
    getLeaveManageList(
      searchStr,
      leaveManageDataFilter.leave_type_id,
      leaveManageDataFilter.gender,
      leaveManageDataFilter.status,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length >= 2 || e.target.value.length === 0) {
      getLeaveManageList(
        e.target.value.toLowerCase(),
        leaveManageDataFilter.leave_type_id,
        leaveManageDataFilter.gender,
        leaveManageDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const renderOrgLeaveTypeOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`leaveType${index}`}
        value={`${item?.id}`}
        label={`${item?.code} (${item?.name})`}
      >{`${item?.code} (${item?.name})`}</option>
    ));
  };

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getLeaveManageList(
      searchStr,
      leaveManageDataFilter?.leave_type_id,
      leaveManageDataFilter?.gender,
      leaveManageDataFilter?.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);
  const deleteLeave = (idx) => {
    // let dataId = { id: idx };
    LeaveService.deleteLeaveManage(idx)
      .then((res) => {
        if (res.data.status == true) {
          getLeaveManageList(
            searchStr,
            "",
            "",
            leaveManageDataFilter.status,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const submit = (id, policyName) => {
    confirmAlert({
      title: "Delete Leave Manage",
      message: `Are you sure to delete ${policyName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteLeave(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getLeaveManageList(
        data,
        leaveManageDataFilter.leave_type_id,
        leaveManageDataFilter.gender,
        leaveManageDataFilter.status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  const mainDataFilter = (values) => {
    if (
      leaveManageDataFilter?.gender !== "" ||
      leaveManageDataFilter?.leave_type_id !== "" ||
      leaveManageDataFilter?.status !== ""
    ) {
      setFilterApplied(true);
    }
    getLeaveManageList(
      "",
      leaveManageDataFilter?.leave_type_id,
      leaveManageDataFilter?.gender,
      leaveManageDataFilter?.status,
      currentPage,
      pageSizeNo,
      "-id",
      true
    );
    filterClose();
  };
  const filterClose = () => {
    filterclose();
  };
  const filterShow = () => {
    filteropen();
    getLeaveManageListData(false);
  };
  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(leaveManageDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setLeaveManageDataFilter(ClearData);
    getLeaveManageList(
      searchStr,
      "",
      "",
      ClearData.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  return (
    <>
      <div>
        <ToastContainer autoClose={1000} />
        <div class="row">
          {/* <div class="col-md-3"></div> */}
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Leave Master</h4>
                    {/* <Breadcrumb>
                      <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                      <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                      <Breadcrumb.Item active>Leave Master</Breadcrumb.Item>
                    </Breadcrumb> */}
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-lg-4">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          className="bs-tooltip-end tooltip"
                          id="overlay-example"
                        >
                          <div class="tooltip-inner text-left">
                            Search on <strong> Code, Name, Status....</strong>
                          </div>
                        </Tooltip>
                      }
                    >
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="searchbar"
                          placeholder="Code, Name, Status"
                          onChange={(e) => handleSearch(e)}
                        />
                        <span class="input-group-text border-0">
                          {searchStr !== "" ? (
                            <i
                              class="far fa-times-circle cursor-pointer"
                              onClick={() => {
                                closeButtonCallBack();
                              }}
                            ></i>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div class="col-lg-8">
                    <div class="inline-spacing btn-betweenspaing">
                      <button
                        onClick={() => {
                          filterShow();
                        }}
                        style={{
                          backgroundColor: filterApplied && "yellow",
                          color: filterApplied && userData?.dark_mode === false && "#000"
                        }}
                        class="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" class="mr-3" />
                        Filter
                      </button>
                      {/* {finalUrlValue?.A ? ( */}
                      <button
                        class="btn btn-primary-inner"
                        onClick={() => {
                          addNewBranch();
                        }}
                      >
                        <img src="images/upload.png" alt="" class="mr-3" />
                        Add New
                      </button>
                      {/* ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured newSpace">
                  <thead>
                    <tr>
                      {/* {finalUrlValue?.E || finalUrlValue?.R ? ( */}
                      <th>Actions</th>
                      {/* ) : (
                        ""
                      )} */}
                      <th
                        onClick={(e) => {
                          handlerData("is_active");
                        }}
                        className={
                          sortData["is_active"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["is_active"]
                        }
                      >
                        Status
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("name");
                        }}
                        className={
                          sortData["name"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["name"]
                        }
                      >
                        Leave Type
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("code");
                        }}
                        className={
                          sortData["code"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["code"]
                        }
                      >
                        Leave Code
                      </th>
                      <th>Club With</th>
                      <th>Reason</th>
                      <th
                        onClick={(e) => {
                          handlerData("no_of_days");
                        }}
                        className={
                          sortData["no_of_days"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["no_of_days"]
                        }
                      >
                        Leave Frequency
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("gender");
                        }}
                        className={
                          sortData["gender"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["gender"]
                        }
                      >
                        Available For
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("no_of_days");
                        }}
                        className={
                          sortData["no_of_days"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["no_of_days"]
                        }
                      >
                        Leave Days
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_at");
                        }}
                        className={
                          sortData["updated_at"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_at"]
                        }
                      >
                        Last Updated On
                      </th>
                      <th
                        onClick={(e) => {
                          handlerData("updated_by_id");
                        }}
                        className={
                          sortData["updated_by_id"] === undefined
                            ? "unsortcls main-pointer"
                            : sortData["updated_by_id"]
                        }
                      >
                        Last Updated By
                      </th>
                      <th>System</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaveManageList?.length ? (
                      leaveManageList?.map((data, index) => (
                        <tr>
                          {/* {finalUrlValue?.E || finalUrlValue?.R ? ( */}
                          <td>
                            {/* {finalUrlValue?.E ? ( */}
                            <button class="btn btn-blanktd text-primary">
                              <i
                                class="far fa-edit"
                                onClick={() => {
                                  editLeaveManage(data);
                                }}
                              ></i>{" "}
                            </button>
                            {/* ) : (
                                ""
                              )} */}

                            {/* {finalUrlValue?.R ? ( */}
                            {/* <button
                              class="btn btn-blanktd text-danger ml-2"
                              onClick={() => {
                                submit(data?.id, data?.name);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button> */}
                            {/* ) : (
                                ""
                              )} */}
                          </td>
                          {/* ) : (
                            ""
                          )} */}
                          <td>
                            {data?.is_active ? (
                              <span className="badge bad-status badge-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge bad-status badge-danger">
                                InActive
                              </span>
                            )}
                          </td>
                          <td>{data?.name}</td>
                          <td>{data?.code}</td>
                          <td>
                            {data?.club?.length > 0
                              ? data?.club
                                ?.map((obj) => {
                                  return obj?.club_with_name;
                                })
                                .toString()
                              : ""}
                          </td>
                          <td>
                            {data?.reasons
                              ?.map((obj) => {
                                return obj?.reason;
                              })
                              .toString()}
                          </td>

                          <td>{data.frequency == "Y" ? "Yearly" : ""}</td>
                          <td>
                            {data?.gender == "F"
                              ? "Female"
                              : data?.gender === "M"
                                ? "Male"
                                : "All"}
                          </td>
                          <td>{data?.no_of_days}</td>
                          <td>
                            {moment
                              .utc(data?.updated_at)
                              .format("DD-MM-YYYY, h:mm:ss a")}
                          </td>
                          <td>{data?.updated_by}</td>
                          <td>
                            <span class="badge badge-secondary">
                              {data?.code === "OL" ||
                                data?.code === "PL" ||
                                data?.code === "COL"
                                ? "Saviour"
                                : "MYPI"}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {leaveManageList?.length == 0 ? (
                ""
              ) : (
                <div class="row">
                  <div class="col-sm-5">
                    <div class="sortinglist">
                      Show
                      <select
                        class="form-control main-pointer"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <option class=" main-pointer" value={10} label={10} />
                        <option class=" main-pointer" value={20} label={20} />
                        <option class=" main-pointer" value={30} label={30} />
                        <option class="main-pointer" value={100} label={100} />
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    {/* <Pagination>
                                        <Pagination.Prev className="previpag" />
                                        <Pagination.Item>{1}</Pagination.Item>
                                        <Pagination.Item active>{2}</Pagination.Item>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item>{9}</Pagination.Item>
                                        <Pagination.Item>{10}</Pagination.Item>
                                        <Pagination.Next className="previpag" />
                                    </Pagination> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select Leave Type</label>
            <select
              className="form-control newbgselect main-pointer"
              name="leave_type_id"
              onChange={(e) => {
                setLeaveManageDataFilter({
                  ...leaveManageDataFilter,
                  leave_type_id: e.target.value,
                });
              }}
              value={leaveManageDataFilter?.leave_type_id}
            >
              <option value="" label="Select Leave Type" hidden>
                Select Leave Type
              </option>

              {renderOrgLeaveTypeOptions(allLeaveType)}
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Available for</label>
            <select
              class="form-control newbgselect main-pointer"
              type="number"
              name="gender"
              onChange={(e) => {
                setLeaveManageDataFilter({
                  ...leaveManageDataFilter,
                  gender: e.target.value,
                });
              }}
              value={leaveManageDataFilter?.gender}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="A">All</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setLeaveManageDataFilter({
                  ...leaveManageDataFilter,
                  status: e.target.value,
                });
              }}
              value={leaveManageDataFilter?.status}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={true} label="Active">
                Active
              </option>
              <option value={false} label="In-Active">
                In-Active
              </option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={mainDataFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <AddLeaveManage
            leaveList={leaveManageList}
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editLeaveManage={leavemanage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LeaveManage;
