import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import JivaTab from "../../assets/images/loginjiva.png";
import MyPiTab from "../../assets/images/loginmypi.png";
import Jivasmall from "../../assets/images/jivasmall.png";
import { Formik } from "formik";
import { ChangePassword } from "../../service/login";
import * as Yup from "yup";

function ResetPassword() {
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("Please enter valid username."),
    new_password: Yup.string().required("Please enter valid username."),
    confirm_password: Yup.string().required("Please enter valid username."),
  });
  const [changePassword, setchangePassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
    return;
  };
  const [passwordType2, setPasswordType2] = useState("password");
  const togglePassword2 = () => {
    if (passwordType === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
    return;
  };
  const [passwordType1, setPasswordType1] = useState("password");
  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
    return;
  };
  const submitPassword = (values) => {
    ChangePassword(values)
      .then((response) => {
        navigate("/");
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const [logoValue, setLogoValue] = useState("");

  const handleLogo = (e) => {
    setLogoValue(e);
  };
  return (
    <>
      <div className="login-bg">
        <div className="container">
          <div class="row justify-content-between my-3">
            <div class="col-md-6 col-lg-6 d-flex flex-column text-center text-sm-left">
              <div>
                <img src={Logo} alt="" height="55px" />
              </div>
              <div class="mt-auto mb-auto">
                <h1>
                  Somewhere,
                  <br />
                  something
                </h1>
                <h2>incredible is waiting to be known.</h2>
              </div>
            </div>
            <div class="col-md-6 col-lg-5">
              <div className="loginTabs">
                <div class="tabfliping">
                  <h3>Reset Your Password!!</h3>

                  <Formik
                    initialValues={changePassword}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      submitPassword(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div class="form-group position-relative">
                          <label>Old Password</label>
                          <input
                            onBlur={handleBlur}
                            name="old_password"
                            value={values.old_password}
                            onChange={handleChange}
                            type={passwordType2}
                            class="form-control"
                            placeholder="Enter Old Password"
                          />
                          <i
                            className={
                              passwordType2 === "password"
                                ? "far fa-eye-slash eyepassword"
                                : " far fa-eye eyepassword"
                            }
                            onClick={() => togglePassword2()}
                          ></i>
                          {touched.old_password && errors.old_password ? (
                            <div className="small text-danger">
                              {errors.old_password}
                            </div>
                          ) : null}
                        </div>
                        <div class="form-group position-relative">
                          <label>New Password</label>
                          <input
                            onBlur={handleBlur}
                            name="new_password"
                            value={values.new_password}
                            onChange={handleChange}
                            type={passwordType}
                            class="form-control"
                            placeholder="Enter New Password"
                          />
                          <i
                            className={
                              passwordType === "password"
                                ? "far fa-eye-slash eyepassword"
                                : " far fa-eye eyepassword"
                            }
                            onClick={() => togglePassword()}
                          ></i>
                          {touched.new_password && errors.new_password ? (
                            <div className="small text-danger">
                              {errors.new_password}
                            </div>
                          ) : null}
                        </div>
                        <div class="form-group position-relative">
                          <label>Confirm Password</label>
                          <input
                            onBlur={handleBlur}
                            name="confirm_password"
                            type={passwordType1}
                            value={values.confirm_password}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Enter Confirm Password"
                          />
                          <i
                            className={
                              passwordType1 === "password"
                                ? "far fa-eye-slash eyepassword"
                                : " far fa-eye eyepassword"
                            }
                            onClick={() => togglePassword1()}
                          ></i>
                          {touched.confirm_password &&
                          errors.confirm_password ? (
                            <div className="small text-danger">
                              {errors.confirm_password}
                            </div>
                          ) : null}
                        </div>
                        <button type="submit" class="btn btn-block btn-primary">
                          Reset Password
                        </button>

                        <div className="text-center mt-4">
                          <Link to="/" class="forgettxt">
                            <i class="fas fa-chevron-left f-12"></i> Back to
                            Login
                          </Link>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
