import React from "react";
import { useState, useEffect } from "react";
import * as TravelListingAPI from "../../service/travelService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}
const TravelAdvanceAccess = (props) => {
  const { state } = useLocation();
  const {
    editCountry,
    edit,
    editValueData,
    editValue,
    setEditValue,
    handleCallBack,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [errorAttachment, setErrorAttachment] = useState(false);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [flag, setflag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [backDateData, setBackDateData] = useState();
  const [allEmployee, setAllEmployee] = useState([]);
  let condition = "false";
  const todayDate = new Date();
  const FromDate = moment(todayDate).format("YYYY-MM-DD");
  const ToDate = moment(todayDate).format("YYYY-MM-DD");
  const FinalDate = moment(todayDate).format("YYYY-MM-DD");
  const [formData, setFormData] = useState({
    user_id: "",
    is_active: true,
    attachment_path: undefined,
  });
  const backDate = new Date();
  const [empIdData, setEmpIdData] = useState();
  var d = new Date();
  d.setMonth(d.getMonth() - 3);

  const [formValidation, setFormValidation] = useState({
    user_id: "",
    is_active: "",
  });

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!formData.user_id) {
      isError = true;
      formerr.user_id = "*Employee ID is required ";
      setFormValidation(formerr);
    }

    return isError;
  };

  const getBackDateList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getBackDateList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        from_date,
        to_date
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(response?.data?.dataList, "om");
        setBackDateData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
        // setAttachment(response?.data?.dataList);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }

    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };
  const getEmployee = (paginate) => {
    TravelListingAPI.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
            username: x?.username,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveTravelBackDateInfo = async (formData) => {
    if (condition == "false") {
      condition = "true";
      let dd = formData;
      // dd.user_id = userData?.id;
      dd.attachment_path = uploadDataIm;
      if (Object.keys(uploadDataIm).length !== 0) {
        setIsLoading(true);
        await TravelListingAPI.saveTravelAdvanceAccess(dd)
          .then((response) => {
            condition = "false";
            if (response?.data.code == 200) {
              setFormData({
                // user_id: "",
                // from_date: "",
                // to_date: "",
                // opened_from: "",
                user_id: "",
                from_date: FromDate,
                to_date: ToDate,
                opened_from: FinalDate,
              });
              navigate("/travel-advance-access-list");
              toast.success("Record Created Successfully");
              setEmpIdData({});
              handleCallBack();
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error?.response?.data?.message);
            console.log(
              (error?.response &&
                error?.response?.data &&
                error?.response?.data?.message) ||
                "failed"
            );
          });
      }
    } else {
      setErrorAttachment(true);
    }
  };

  const updateTravelBackDateInfo = async (formData) => {
    if (condition == "false") {
      condition = "true";
      let dd = formData;
      dd.attachment_path = uploadDataIm;
      // dd.check_type = "travel";
      // dd.user_id = userData?.id;
      if (Object.keys(uploadDataIm).length !== 0) {
        setIsLoading(true);
        await TravelListingAPI.updateTravelAdvanceAccess(dd, formData?.id)
          .then((response) => {
            condition = "false";
            if (response?.data.code == 200) {
              // resetState();
              setFormData({
                user_id: "",
                is_active: "",
                attachment_path: "",
              });
              setFormValidation({
                user_id: "",
              });
              navigate("/travel-advance-access-list");
              setEditValue(false);
              // navigate("/travel-listing");
              toast.success("Record Update Successfully");
              setEmpIdData({});
              handleCallBack();
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error?.response?.data?.message);
            console.log(
              (error?.response &&
                error?.response?.data &&
                error?.response?.data?.message) ||
                "failed"
            );
          });
      }
    } else {
      setErrorAttachment(true);
    }
  };
  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await TravelListingAPI.uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }
    setflag(true);
    setErrorAttachment(false);
    // setAttachment([]);
    setUploadDataIm(files);
  };
  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1 col-sm-6">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
              <br></br>
              <span>{data2?.name}</span>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  // const isDataExists = () => {
  //   let data = {};
  //   // data["user_id"] = userData?.id;
  //   data["from_date"] = formData?.from_date;
  //   data["to_date"] = formData?.to_date;

  //   if (condition == "false") {
  //     condition = "true";
  //     TravelListingAPI.isDataExists(data)
  //       .then((res) => {
  //         setIsExist(res?.data?.dataList?.is_exist);
  //         if (res?.data?.dataList?.is_exist === false) {
  //           handleValidateForm();
  //         }
  //       })
  //       .catch((err) => console.log("Error=>", err));
  //   }
  // };
  const handleValidateForm = () => {
    if (handleValidate()) return false;
    if (Object.keys(uploadDataIm).length !== 0) {
      setErrorAttachment(false);
      saveTravelBackDateInfo(formData);
    } else {
      setErrorAttachment(true);
    }
    // if (editValue === false) {
    // } else {
    //   updateTravelBackDateInfo(formData);
    // }
  };
  const handleValidateFormNew = () => {
    if (handleValidate()) return false;
    if (Object.keys(uploadDataIm).length !== 0) {
      setErrorAttachment(false);
      updateTravelBackDateInfo(formData);
    } else {
      setErrorAttachment(true);
    }
  };
  const pushEmpId = (e, i) => {
    setEmpIdData(e);
    setFormData({ ...formData, user_id: e.value });
  };

  useEffect(() => {
    getBackDateList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userData?.id
    );
    getEmployee(false);
  }, []);

  useEffect(() => {
    if (state?.data) {
      setFormData(state?.data);
      let data = {
        value: state?.data.id,
        label: `${state?.data.user_name} ${state?.data.user_code}`,
      };
      setEmpIdData(data);
      setAttachment(state?.data?.attachment_path);

      // setAttachment(state?.data);
      Object.keys(state?.data).forEach((item) => {
        formData[item] = state?.data[item];
      });
    }
  }, [state?.data]);

  return (
    <>
      <div class="px-4 pb-2">
        <div class="row align-items-center">
          <div class="col-md-6 mt-5" style={{ float: "left" }}>
            {" "}
            <h5 class="inner-page-title"> Travel Advance Access</h5>
          </div>
        </div>
        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Allow Access To<span class="text-danger">*</span>
                </label>
                <Select
                  name="user_id"
                  options={allEmployee}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    pushEmpId(e);
                    setFormValidation({
                      ...formValidation,
                      user_id: "",
                    });
                  }}
                  value={empIdData}
                />
                <div className="small text-danger">
                  {formValidation?.user_id}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group innergroup">
                <label className="d-block">Status</label>
                <label
                  className="logCheck d-inline-block mr-4"
                  htmlFor="active"
                >
                  Active
                  <input
                    type="radio"
                    name="is_active"
                    id="active"
                    value={formData?.is_active}
                    onChange={() => {
                      setFormData({
                        ...formData,
                        is_active: true,
                      });
                      setFormValidation({
                        ...formValidation,
                        is_active: "",
                      });
                    }}
                    checked={formData?.is_active === true}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="logCheck d-inline-block" htmlFor="Inactive">
                  Inactive
                  <input
                    type="radio"
                    name="is_active"
                    id="Inactive"
                    value={formData?.is_active}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_active: false,
                      });
                      setFormValidation({
                        ...formValidation,
                        is_active: "",
                      });
                    }}
                    checked={formData?.is_active === false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>

            <div class="col-md-6 d-flex flex-column mt-3">
              <h5 class="text-theme pb-2 mb-3">
                <strong>
                  Attachments<span class="text-danger">*</span>
                </strong>
              </h5>

              <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
                <label>
                  Attach File<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  disabled
                  value={
                    // edit === true
                    // ? editPolicy?.logo
                    uploadDataIm?.logo?.original_name
                  }
                  placeholder="(JPG, PNG , Word, Pdf, ppt, Video)"
                />
                {errorAttachment ? (
                  <div className="small text-danger">
                    *Attachment is required
                  </div>
                ) : null}
                <div class="upload-btn-wrapper up-loposition">
                  <button class="uploadBtn">Browse</button>
                  <input
                    type="file"
                    title=""
                    id="policyattachment"
                    multiple
                    name="attachment_path"
                    accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                    onChange={(e) => onProfileChange(e, "attachment_path")}
                  />
                </div>

                <p>
                  Selected files
                  <span>{/* {uploadDataIm[0]?.original_name} */}</span>
                </p>
              </div>
              {Object.keys(file).length !== 0 ? (
                <div className="d-flex">{attachments123()}</div>
              ) : attachment != undefined && attachment.length != 0 ? (
                <>
                  <div class="d-flex">
                    {attachment?.map((Data) => (
                      <div class="uploaded-image mr-1">
                        <i class="" style={{ width: "20px" }}>
                          <a
                            href={Data?.attachment_path}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {Data?.attachment_path?.includes(".pdf") ? (
                              <img
                                src={"/images/policy-icon/pdf.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".xlsx") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".xls") ? (
                              <img
                                src={"/images/policy-icon/xlsx.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".doc") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".docs") ||
                              Data?.attachment_path.includes(".csv") ? (
                              <img
                                src={"/images/policy-icon/docs.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".pptx") ? (
                              <img
                                src={"/images/policy-icon/ppt.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data.attachment_path.includes(".mp4") ? (
                              <img
                                src={"/images/policy-icon/video.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : Data?.attachment_path.includes(".msg") ? (
                              <img
                                src={"/images/policy-icon/msg.png"}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            ) : (
                              <img
                                src={Data.attachment_path}
                                alt="image"
                                width="70px"
                                height="70px"
                              />
                            )}
                            {/* uploadDataIm[0]?.original_name */}

                            <p>{Data?.original_name}</p>
                          </a>
                        </i>
                        <button
                          type="button"
                          onClick={(e) => {
                            deleteAttachment(
                              Data?.attachment_path?.split("/")[4]
                            );
                          }}
                          className="btn btn-blanktd text-danger ml-2"
                        >
                          <i className="far fa-trash-alt text-danger"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="border-top my-3"></div>
          </div>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <div class="col-md-12 text-center">
              {state?.edit == true ? (
                <button
                  class="btn btn-outline-secondary mr-2"
                  onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                >
                  Back
                </button>
              ) : (
                ""
              )}
              {console.log(edit, "editMode")}
              {state?.edit === true ? (
                <button
                  class="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => {
                    handleValidateFormNew();
                  }}
                >
                  <i class="far fa-check-circle"></i> Update
                </button>
              ) : (
                <button
                  class="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => {
                    handleValidateForm();
                  }}
                >
                  <i class="far fa-check-circle"></i> Submit
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TravelAdvanceAccess;
