import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
    ProgressBar,
    Spinner,
} from "react-bootstrap";
import Sidebar from "./sidebar";
import { useDropzone } from "react-dropzone";
import { CustomBreadcrumb } from "./custom-breadcrump";
import * as MediclaimService from "../../service/medicalService";
import { toast } from "react-toastify";
import { validateFileTypeExcel } from "../../utils/helpers";

const CRUMB_ITEMS = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Me@PI' },
    { active: true, title: 'Upload Premium' }
]

const Mediclaim = {
    Premium: '0',
    TopupPremium: '1'
}

// const [_TOPUP_SAMPLE, _TOPUP_PREMIUM_SAMPLE] = ['https://mypi-new.s3.ap-south-1.amazonaws.com/ess/mediclaim_premium_format.csv', 'https://mypi-new.s3.ap-south-1.amazonaws.com/ess/mediclaim_topup_premium_format.csv']
const [_TOPUP_SAMPLE, _TOPUP_PREMIUM_SAMPLE] = ['/assets/mediclaim-sample/mediclaim_premium_format.csv', '/assets/mediclaim-sample/mediclaim_topup_premium_format.csv']


function UploadPremium() {
    // const options = [
    //   { value: "Option1", label: "Option1" },
    //   { value: "Option2", label: "Option2" },
    //   { value: "Option3", label: "Option3" },
    //   { value: "Option4", label: "Option4" },
    //   { value: "Option5", label: "Option5" },
    // ];

    // const [addnewshow, addnewsetShow] = useState(false);
    // const addnewopen = () => addnewsetShow(true);
    // const addnewclose = () => addnewsetShow(false);

    // const [outstandshow, outstandsetShow] = useState(false);
    // const outstanding = () => outstandsetShow(true);
    // const outstandclose = () => outstandsetShow(false);

    const [data, setData] = useState({})
    const [currentFile, setCurrentFile] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mediclaimType, setMediclaimType] = useState(Mediclaim.Premium)

    /* uploading states */
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);

    const onDrop = useCallback((acceptedFiles) => {
        console.log("🚀 ~ file: upload-premium.js:49 ~ onDrop ~ acceptedFiles:", acceptedFiles)
        if (
            Array.isArray(acceptedFiles)
            && acceptedFiles.length > 0
        ) {
            const [file] = acceptedFiles;
            console.log("🚀 ~ file: upload-premium.js:63 ~ onDrop ~ file:", file)
            const isValidFileType = validateFileTypeExcel(file?.type)
            if(!isValidFileType){
                toast.error('Only excel, csv files are allowed')
            } else {
                setCurrentFile(file);
            }
        }
    }, []);

    const handleUpload = async e => {
        e.stopPropagation && e.stopPropagation() /* for stopping parent click */
        if (!currentFile) return;
        setIsUploading(true)
        try {
            const axiosConfig = { onUploadProgress }
            const formData = new FormData();
            formData.append('uploaded_file', currentFile)

            const { data } = await MediclaimService.uploadFile({ data: formData, config: axiosConfig })
            setData(data)
        } catch (error) {
            toast(error?.response.data?.message || 'Some error occured')
            console.error("🚀 ~ file: upload-premium.js:78 ~ handleUpload ~ error:", error)
        } finally {
            // setCurrentFile(null)
            // setIsUploading(false)
        }
    }

    const onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadingProgress(percentCompleted)
    }

    /* handlers */
    const handleChangeType = ({ target: { value } }) => setMediclaimType(value)

    const handleMainSubmit = async () => {
        try {
            setIsSubmitting(true)
            let response = null
            if (mediclaimType === Mediclaim.Premium) {
                response = await MediclaimService.bulkUploadMediclaimPremium(data)
            } else {
                response = await MediclaimService.bulkUploadMediclaimTopupPremium(data)
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Uploaded.xlsx');
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();

            const response2 = await MediclaimService.globalDetailsOfUpload();
            if (
                Array.isArray(response2?.data?.dataList)
                && response2?.data.dataList.length > 0
            ) {
                const [result] = response2?.data.dataList;
                setData({
                    records: {
                        success: result?.records_uploaded,
                        failed: result?.records_failed
                    }
                })
            } else {
                setData({})
            }
            setCurrentFile(null)
            setIsUploading(false)
            setUploadingProgress(0)
        } catch (error) {
            console.log("🚀 ~ file: upload-premium.js:133 ~ handleMainSubmit ~ error:", error)

        }
        finally {
            setIsSubmitting(false)
        }
    }

    const handleFileClear = e => { /* when user clears the current file */
        e.stopPropagation && e.stopPropagation() /* for stopping parent click */
        setCurrentFile(null)
        setIsUploading(false)
        setUploadingProgress(0)
    }

    const handleDownloadTemplate = e => {
        e.stopPropagation && e.stopPropagation() /* for stopping parent click */
        window.open(mediclaimType === Mediclaim.Premium ? _TOPUP_SAMPLE : _TOPUP_PREMIUM_SAMPLE, '_blank')
    }

    const resetProcess = () => {
        setData({})
        setCurrentFile(null)
        setIsSubmitting(null)
        setIsUploading(false)
        setUploadingProgress(0)
    }



    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            <div className="row">
                {/* <div className="col-md-3">
                    <Sidebar />
                </div> */}
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec pb-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">Upload Premium</h4>
                                    <CustomBreadcrumb items={CRUMB_ITEMS} />
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mb-3"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-11">
                                <div className="form-group innergroup answerboxpollsuy">
                                    <label className="d-block mb-2">
                                        Upload Type<span className="text-danger">*</span>
                                    </label>
                                    <label className="logCheck d-inline-block mr-4">
                                        Mediclaim Premium
                                        <input
                                            type="radio"
                                            name="Available"
                                            value={Mediclaim.Premium}
                                            onClick={handleChangeType}
                                            checked={mediclaimType == Mediclaim.Premium}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="logCheck d-inline-block">
                                        Mediclaim Topup Premium
                                        <input
                                            type="radio"
                                            name="Available"
                                            onClick={handleChangeType}
                                            value={Mediclaim.TopupPremium}
                                            checked={mediclaimType == Mediclaim.TopupPremium}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-11">
                                {/* without upload */}
                                <div className="drapdroparea upload-btn-wrapper text-center" {...getRootProps()} style={{ height: '18rem' }}>
                                    {
                                        !isDragActive
                                            ? <>
                                                {
                                                    data?.records
                                                        ? <React.Fragment>
                                                            {data?.records?.failed > 0 && <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>}
                                                            {
                                                                data?.records?.failed > 0
                                                                    ? <p>
                                                                        The system was unable to read few records from the uploaded
                                                                        file.
                                                                        <br />
                                                                        Please correct or remove those records from the file and
                                                                        upload again.
                                                                    </p>
                                                                    : <p> All records imported successfully </p>
                                                            }
                                                            <span className="badge bad-status mb-3 badge-success">
                                                                Records Succeded: {data?.records?.success}
                                                            </span>
                                                            {
                                                                data?.records?.failed > 0
                                                                && <span className="badge bad-status mb-3 badge-danger">
                                                                    Records Failed: {data?.records?.failed}
                                                                </span>
                                                            }
                                                            <div className="mt-3 downloadtemplate">
                                                                <button className="editbtn" onClick={resetProcess}>
                                                                    <i className="fas fa-chevron-circle-left"></i> Upload again
                                                                </button>
                                                            </div>
                                                        </React.Fragment>
                                                        : !currentFile
                                                            ? <React.Fragment>
                                                                <i className="fas fa-file-upload fa-2x"></i>
                                                                <p>Upload the template file with updated data</p>
                                                                <button className="btn btn-primary-inner">
                                                                    DRAG AND DROP HERE OR CHOOSE FILE
                                                                </button>
                                                                <div className="mt-3 downloadtemplate">
                                                                    <button onClick={handleDownloadTemplate} className="editbtn">
                                                                        <i className="far fa-file-excel"></i> DOWNLOAD TEMPLATE
                                                                    </button>
                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    {...getInputProps()}
                                                                    disabled={!!currentFile}
                                                                    className="form-control"
                                                                    accept=".xlsx, .xls, .csv"
                                                                />
                                                            </React.Fragment>
                                                            : (
                                                                <React.Fragment>
                                                                    <span className="badge bad-status mb-3 badge-warning">
                                                                        {currentFile?.name} <i className="far fa-times-circle" onClick={handleFileClear} ></i>
                                                                    </span>
                                                                    <button disabled={isUploading} onClick={handleUpload} className="btn btn-primary-inner">
                                                                        Proceed to Upload
                                                                    </button>
                                                                    {isUploading && (
                                                                        <div className="w-75 mt-3">
                                                                            <ProgressBar
                                                                                striped
                                                                                variant="success"
                                                                                animated
                                                                                now={uploadingProgress}
                                                                                label={`${uploadingProgress}%`}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                }
                                            </>
                                            : (
                                                <div>
                                                    <Spinner variant='dark' animation='grow' />
                                                    <div>DROP FILES HERE...</div>
                                                </div>
                                            )
                                    }
                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className="border-top my-3"></div>
                            </div>

                            <div className="col-md-12 mt-3 text-center">
                                {/* <button className="btn btn-outline-primary mr-2">Save</button> */}
                                <button disabled={isSubmitting || Object.keys(data).length === 0} onClick={handleMainSubmit} className="btn btn-primary-inner bpi-main">Submit</button>
                            </div>

                            <div className="col-md-11">
                                <div className="travel-note">
                                    <h4>Notes :-</h4>
                                    <ul>
                                        {/* <li>
                                            Format - Please download import format by clicking{" "}
                                            <a onClick={handleDownloadTemplate} href="#">here</a>.
                                        </li> */}
                                        <li>
                                            First row in csv or excel must be header or blank, and
                                            columns sequence are Employee Sub Group, Sum Insured,
                                            Lower Age Limit, Upper Age Limit, Premium.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadPremium;


{/* Redudant modal */ }
{/* <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size=""
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Advance Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <table className="table table-advance">
                  <thead>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Amount</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>13-01-2023</td>
                      <td>
                        <i className="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>15-01-2023</td>
                      <td>
                        <i className="fas fa-rupee-sign"></i> 520
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>17-01-2023</td>
                      <td>
                        <i className="fas fa-rupee-sign"></i> 700
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>24-01-2023</td>
                      <td>
                        <i className="fas fa-rupee-sign"></i> 1220
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

{/* Redudant modal */ }
{/* <Modal
  show={outstandshow}
  onHide={outstandclose}
  backdrop="static"
  keyboard={false}
  size="lg"
  className="modaldefaultclose modaldefaultclosecenter"
>
  <Modal.Header closeButton>
    <Modal.Title>Outstanding</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="row justify-content-center">
      <div className="col-md-11">
        <div className="row">
          <table className="table table-advance">
            <thead>
              <th>S.No</th>
              <th>Date</th>
              <th>Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Amount</th>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>13-01-2023</td>
                <td>Description will come here</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 100
                </td>
                <td>-</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 520
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>15-01-2023</td>
                <td>Description will come here</td>
                <td>-</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 100
                </td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 520
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>17-01-2023</td>
                <td>Description will come here</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 100
                </td>
                <td>-</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 700
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>24-01-2023</td>
                <td>Description will come here</td>
                <td>-</td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 100
                </td>
                <td>
                  <i className="fas fa-rupee-sign"></i> 1220
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Modal.Body>
</Modal> */}


/* 
<div className="drapdroparea upload-btn-wrapper text-center">
                                    <i className="fas fa-exclamation-triangle fa-2x text-danger"></i>
                                    <p>
                                        The system was unable to read few records from the uploaded
                                        file.
                                        <br />
                                        Please correct or remove those records from the file and
                                        upload again.
                                    </p>
                                    <span className="badge bad-status mb-3 badge-success">
                                        Records Succeded: 230
                                    </span>
                                    <span className="badge bad-status mb-3 badge-danger">
                                        Records Failed: 50
                                    </span>
                                    <div className="mt-3 downloadtemplate">
                                        <button className="editbtn">
                                            <i className="fas fa-chevron-circle-left"></i> Upload again
                                        </button>
                                    </div>
                                </div> */

/*
 <table className="table uploadrecordstab">
    <thead>
        <tr>
            <th>Upload Date</th>
            <th>Uploaded By</th>
            <th>File Name</th>
            <th>Records Uploaded</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td className="text-theme">01-12-2022</td>
            <td>Aniket Singh</td>
            <td>employee_user.csv</td>
            <td>500</td>
        </tr>
        <tr>
            <td className="text-theme">01-12-2022</td>
            <td>Aniket Singh</td>
            <td>employee_user.csv</td>
            <td>500</td>
        </tr>
        <tr>
            <td className="text-theme">01-12-2022</td>
            <td>Aniket Singh</td>
            <td>employee_user.csv</td>
            <td>500</td>
        </tr>
        <tr>
            <td className="text-theme">01-12-2022</td>
            <td>Aniket Singh</td>
            <td>employee_user.csv</td>
            <td>500</td>
        </tr>
        <tr>
            <td className="text-theme">01-12-2022</td>
            <td>Aniket Singh</td>
            <td>employee_user.csv</td>
            <td>500</td>
        </tr>
    </tbody>
</table> */