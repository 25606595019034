import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import * as PolicyService from "../../service/policy";
import { getOrganisation } from "../../service/businessService";
import * as UserService from "../../service/userService";
import * as surveyService from "../../service/Survey";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import * as moment from "moment";
import { AUTH_BASE_URL } from "../../constants/URL";

const PublishSetting = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { tabType, responseData, tabStatus, edit, isViewing } = props;
  const [org, setOrg] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [orgListValue, setOrgListValue] = useState([]);
  const [branch, setBranchData] = useState([]);
  const [branchData, setBranch] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [dept, setdeptData] = useState([]);
  const [deptData, setDept] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [usersDataValue, setusersDataValue] = useState([]);
  const [editData, setEditData] = useState([]);
  const [dublicateOrgData, setDubOrgData] = useState([]);
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [permissionType, setPermissionType] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [external_mails, setexternal_mails] = useState([""]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailSign, setEmailSign] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [psaList, setPsaList] = useState([]);
  const [psa, setPsa] = useState([]);
  const [psaIds, setPsaIds] = useState([]);
  const [filename, setfilename] = useState("");
  const [reminderDates, setReminderDates] = useState([]);
  const [allReminderDates, setAllReminderDates] = useState([]);
  const [frequencyType, setFrequencyType] = useState("");
  const [customReminder, setCustomReminder] = useState(false);
  const [emailTime, setEmailTime] = useState('');

  const emailUploadRef = useRef(null);

  const [formValidation, setFormValidation] = useState({
    start_date: "",
    end_date: "",
    organisation_ids: "",
  });

  const [publishData, setPublishData] = useState({
    id: 0,
    start_date: "",
    end_date: "",
    status: true,
    survey_id: 0,
    organisation_ids: [],
    branch_ids: [],
    department_ids: [],
    psa_ids: [],
    user_ids: [],
    permission_type: "",
    publish: true,
    // is_draft:false,
    send_email: true,
    is_active: true,
    status: false,
    type: "",
    email_ids: [],
    compulsory: false,
    uploadedEmails: {},
    reminder: false,
  });

  const uploadEmailUploads = (e) => {
    const files = e.target.files[0];
    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    UserService.uploadImages(fileData).then((response) => {
      setPublishData((prev) => ({
        prev,
        uploadedEmails: response?.data,
      }));
      setfilename(response.data.original_name);
    });
  };

  const savePublishData = (value) => {
    // console.log(branchData);
    // return
    // setPublishData({
    //   ...publishData,
    //   is_draft:!value,
    //   publish:value,
    //   user_ids:userListData,
    //   organisation_ids : orgData,
    // branch_ids : branchData,
    // department_ids : deptData,
    // })

    let item = { ...publishData };
    item.organisation_ids =
      orgData === undefined || orgData === null ? [] : orgData;
    item.branch_ids =
      branchData === undefined || branchData === null ? [] : branchData;
    item.department_ids =
      deptData === undefined || deptData === null ? [] : deptData;
    item.user_ids =
      userListData === undefined || userListData === null ? [] : userListData;
    item.psa_ids = psaIds === undefined || psaIds === null ? [] : psaIds;
    item.publish = value;
    // item.is_draft = !value
    item.type = tabType;
    item.email_ids = external_mails;
    if (item.type === "External") {
      item.permission_type = "SO";
      item.base_url = AUTH_BASE_URL;
    }
    item.email_subject = emailSubject;
    item.email_time = emailTime;
    item.email_sign = emailSign;
    item.email_body = emailBody;
    item.reminder_frequency = frequencyType;
    item.reminder_dates = allReminderDates;
    item.reminder = publishData?.reminder;

    // console.log(`saving pubData`,publishData)
    dispatch(setDisplayLoader("Display"));
    surveyService
      .savePublishData(item)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        toast.success(response.data.message);
        navigate("/survey");
      })
      .catch((error) => {
        toast.error("Please fill mandatory data first.");
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updatedValue = (value, shouldPub) => {
    let dataEditPublish = {
      id: value.id,
      start_date: publishData.start_date,
      end_date: publishData.end_date,
      status: publishData.status,
      is_active: publishData.is_active,
      email_ids: external_mails,
      survey_id: value.survey_id,
      organisation_ids: orgData,
      branch_ids: branchData,
      department_ids: deptData,
      psa_ids: psaIds,
      user_ids: userListData,
      send_email: publishData?.send_email,
      compulsory: publishData?.compulsory,
      email_subject: emailSubject,
      email_time: emailTime,
      email_sign: emailSign,
      email_body: emailBody,
      // organisation_ids: collectOrg,
      // branch_ids: collectBranch,
      // department_ids: collectData,
      // user_ids: collectUsersId,
      permission_type: publishData.permission_type,
      publish: shouldPub,
      type: tabType,
      reminder_dates: allReminderDates,
      reminder: publishData?.reminder,
      reminder_frequency: frequencyType,
      uploadedEmails: publishData?.uploadedEmails,
    };

    updatePublishData(value.id, dataEditPublish);
  };

  const updatePublishData = (id, data) => {
    dispatch(setDisplayLoader("Display"));
    surveyService
      .updatePublishData(id, data)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        toast.success(response.data.message);
        navigate("/survey");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addExternalMailById = () => {
    let _external_mails = [...external_mails];
    _external_mails.push(document.getElementById("input_user_mail").value);
    setexternal_mails(_external_mails);
  };

  const _mailRegexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+$/;

  const addExternalMail = () => {
    let _external_mails = [...external_mails];
    for (const item of _external_mails) {
      if (!_mailRegexExp.test(item)) {
        toast.error(`Please enter valid email ${item}`);
        return;
      }
    }
    _external_mails.push("");
    setexternal_mails(_external_mails);
  };

  const set_inputemaildata = (mail_id, idex) => {
    let _external_mails = [...external_mails];
    _external_mails[idex] = mail_id;
    setexternal_mails(_external_mails);
  };

  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      let organisation = [];
      let organisation2 = [];

      response?.data?.dataList?.result.map((x) => {
        let data = { value: x.id, label: `${x.name} (${x.code})` };
        organisation.push(data);
        organisation2.push(data);
      });
      setDubOrgData(organisation2);
      let all = { value: "All", label: "All" };
      organisation.push(all);
      setOrg(organisation);
    });
  };

  const getDepartment = (data, bdata) => {
    surveyService
      .getDepartment(
        `paginate=false&type=Department&organisation_id=${orgData}&business_unit_id=${bdata}`
      )
      .then((response) => {
        // getBranch(`paginate=false&organisation_id=`);
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = { value: x.id, label: `${x.name} (${x.code})` };
          data.push(data2);
        });

        setdeptData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getUsersfromDepartment = (bdata) => {
    surveyService
      .getDepartment(
        `paginate=false&type=User&organisation_id=${orgData}&business_unit_id=${branchData}&department_id=${bdata}`
      )
      .then((response) => {
        // getBranch(`paginate=false&organisation_id=`);

        let data = response.data.dataList?.result;
        let users = [];
        data.map((x) => {
          let data2 = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name}(${x.username})`,
          };
          users.push(data2);
        });
        setUserList(users);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const loadUserMasterList = (pageNo, paginate, searchStr, sort_by) => {
    UserService.getUserListByBranch(
      pageNo,
      pageSizeNo,
      sort_by,
      paginate,
      searchStr
    )
      .then((response) => {
        let data = response.data.dataList?.result;
        let users = [];
        data.map((x) => {
          let data2 = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name}(${x.username})`,
          };
          users.push(data2);
        });
        setUserList(users);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getBranch = (paginate, orgg) => {
    // let collectOrg = orgListValue?.map((item) => {
    //   if (item !== []) {
    //     return item.value;
    //   }
    // });

    surveyService
      .getBranch(`paginate=${paginate}&organisation_id=${orgg}`)

      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: `${x.name} (${x.code})`,
            organisation: x?.organisation_name,
          };
          data.push(data2);
        });

        setBranchData(data);
        setBranchDatacopy(data);
      })

      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!publishData.start_date) {
      isError = true;
      formerr.start_date = "Start Date is required";
      setFormValidation(formerr);
    }
    if (!publishData.end_date) {
      isError = true;
      formerr.end_date = "End Date is required";
      setFormValidation(formerr);
    }
    if (orgData?.length === 0 && tabType !== "External") {
      isError = true;
      formerr.organisation_ids = "Organization is required ";
      setFormValidation(formerr);
    }
    if (publishData?.permission_type === "") {
      isError = true;
      toast.error("Kindly select a permission type.");
    }
    return isError;
  };

  const getPublishData = (id) => {
    dispatch(setDisplayLoader('Display'))
    surveyService
      .getPublishData(id)
      .then((response) => {
        dispatch(setDisplayLoader('Hide'))
        setEditData(response.data.dataList);
      })
      .catch((error) => {
        dispatch(setDisplayLoader('Hide'))
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleValidateTab = (value) => {
    if (handleValidate()) return false;
    // dispatch(setDisplayLoader("Display"));
    if (editData?.id) {
      updatedValue(editData, value);
    } else {
      savePublishData(value);
    }
  };

  const pushBranch = (e) => {
    let collectBranch = e?.map((item) => {
      if (item !== []) {
        return item.value;
      }
    });

    setBranch(collectBranch);
    setbranchList(e);

    setDept([]);
    setDeptList([]);

    getDepartment("false", collectBranch);
    getPsaByBranch(collectBranch);

    // e.map((data) => {
    //   if (data !== []) {
    //     const string = data.value;
    //     loadUserMasterList(1, false, string, "-id");
    //   }
    // });
  };

  const getPsaByBranch = (branch) => {
    surveyService.getPsaByBranch(branch.toString()).then((response) => {
      if (response?.status === 200) {
        setPsaList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: `${each?.description} (${each?.name})`,
              value: each?.id,
            };
          })
        );
      }
    });
  };

  const pushPsa = (e) => {
    let psa = e?.map((item) => {
      if (item !== []) {
        return item.value;
      }
    });
    setPsaIds(psa);
    setPsa(e);
  };

  const pushDept = (e) => {
    let collectData = e?.map((item) => {
      if (item !== []) {
        return item.value;
      }
    });
    setDept(collectData);
    setDeptList(e);
    setUserListData([]);
    setusersDataValue([]);
    getUsersfromDepartment(collectData);
  };
  const pushUsers = (e) => {
    let collectUsersId = e?.map((item) => {
      if (item !== []) {
        return item.value;
      }
    });

    setUserListData(collectUsersId);
    // setPublishData({
    //   ...publishData,
    //   user_ids:collectUsersId
    // })
    setusersDataValue(e);
  };

  const pushOrg = (e) => {
    let collectOrg = e?.map((item) => {
      if (item !== []) {
        return item.value;
      }
    });
    setOrgData(collectOrg);
    setOrgListValue(e);
    setBranch([]);
    setbranchList([]);
    setDept([]);
    setDeptList([]);
    setUserListData([]);
    setusersDataValue([]);
    getBranch(false, collectOrg);
  };

  useEffect(() => {
    // getDepartment(branchData);
    // let mdata=[]; mdata.push("")

    // setexternal_mails(mdata)
    getOrganisationList();
  }, []);

  useEffect(() => {
    setPublishData({ ...publishData, survey_id: responseData.id });
  }, [responseData]);

  useEffect(() => {
    if (tabStatus === "publish") {
      edit?.questions?.map((item) => {
        getPublishData(item.survey_id, tabType);
      });
    }
  }, [tabStatus, edit]);

  useEffect(() => {
    if (editData) {
      console.log({editData});
      let orgCode = [],
        orgIds = [];
      let branchCode = [],
        branchIds = [];
      let userCode = [],
        userIds = [];
      let deptCode = [],
        deptIds = [];
      let psaIds = [],
        psaData = [];

      setFrequencyType(editData?.reminder_frequency)

      editData?.branch_ids?.map((item) => {
        let branchData = { value: item?.id, label: item?.name };
        branchCode.push(branchData);
        branchIds.push(item?.id);
      });
      editData?.department_ids?.map((item) => {
        let deptData = { value: item?.id, label: item?.name };
        deptCode.push(deptData);
        deptIds.push(item?.id);
      });
      editData?.organisation_ids?.map((item) => {
        let orgData = { value: item?.id, label: item?.name };
        orgCode.push(orgData);
        orgIds.push(item?.id);
      });
      editData?.user_ids?.map((item) => {
        let userData = { value: item?.id, label: item?.name };
        userCode.push(userData);
        userIds.push(item?.id);
      });
      editData?.psa_ids?.map((item) => {
        let psa = {
          value: item?.id,
          label: `${item?.description} (${item?.name})`,
        };
        psaData.push(psa);
        psaIds.push(item?.id);
      });
      //user inputs
      setDeptList(deptCode);
      setusersDataValue(userCode);
      setOrgListValue(orgCode);
      setbranchList(branchCode);
      setPsa(psaData);
      setPsaIds(psaIds);
      setEmailBody((editData?.email_body === undefined || editData?.email_body === null) ? "" : editData?.email_body)
      setEmailSubject((editData?.email_subject === undefined || editData?.email_subject === null) ? "" : editData?.email_subject)
      setEmailSign((editData?.email_signature === undefined || editData?.email_signature === null) ? "" : editData?.email_signature)
      setEmailTime((editData?.email_time === undefined || editData?.email_time === null) ? "" : moment
        .utc(editData?.email_time)
        .format("YYYY-MM-DDTHH:mm"))

      Object.keys(editData).forEach((item) => {
        publishData[item] = editData[item];
      });

      setOrgData(orgIds);

      getBranch(
        false,
        editData?.organisation_ids?.map((item) => {
          if (item !== []) {
            return item.id;
          }
        })
      );
      setBranch(branchIds);

      getDepartment(
        false,
        editData?.branch_ids?.map((item) => {
          if (item !== []) {
            return item.id;
          }
        })
      );
      setDept(deptIds);

      getUsersfromDepartment(
        editData?.department_ids?.map((item) => {
          if (item !== []) {
            return item.id;
          }
        })
      );
      setUserListData(userIds);
    }
  }, [editData]);

  const reminderDatesFunction = () => {
    let dateList = [];
    let start = moment(publishData?.start_date);
    let end = moment(publishData?.end_date);
    while (start <= end) {
      let obj = {
        value: start.format("YYYY-MM-DD"),
        label: start.format("YYYY-MM-DD"),
      };
      dateList.push(obj);
      start.add(1, "days");
    }
    setReminderDates(dateList);
  };

  const handleChange = (event, name) => {
    let obj = event?.map((obj) => {
      return obj.value;
    });
    setAllReminderDates(obj);
  };
  return (
    <div class="approval-bg no-bg border-0 purchaseinnertabs mt-0 position-relative">
      {/* <ToastContainer autoClose={1000} /> */}
      {tabType === "Internal" ? (
        <div class="row mt-2">
          <div class="col-md-12">
            <div class=" border-bottom mb-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Start Date<span class="text-danger">*</span>
                    </label>
                    <input
                      disabled={isViewing}
                      type="date"
                      class="form-control"
                      name="start_date"
                      onChange={(e) => {
                        setPublishData({
                          ...publishData,
                          start_date: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          start_date: "",
                        });
                      }}
                      value={publishData.start_date}
                    />
                    <div className="small text-danger">
                      {formValidation.start_date}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      End Date<span class="text-danger">*</span>
                    </label>
                    <input
                      disabled={isViewing}
                      type="date"
                      class="form-control"
                      name="end_date"
                      onChange={(e) => {
                        setPublishData({
                          ...publishData,
                          end_date: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          end_date: "",
                        });
                      }}
                      value={publishData.end_date}
                    />
                    <div className="small text-danger">
                      {formValidation.end_date}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-3">
                    <label class="fw-bold mb-1">Reminder:</label>
                    <label class="logCheck d-block">
                      Yes:
                      <input
                        type="radio"
                        checked={publishData.reminder === true}
                        onClick={() => {
                          setPublishData({
                            ...publishData,
                            reminder: true,
                          });
                          reminderDatesFunction();
                        }}
                        name="reminder"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-block">
                      No:
                      <input
                        type="radio"
                        checked={publishData.reminder === false}
                        onClick={() =>
                          setPublishData({
                            ...publishData,
                            reminder: false,
                          })
                        }
                        name="reminder"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                {publishData?.reminder && (
                  <>
                    <div className="col-md-2">
                      <label className="form-group innergroup fw-bold">
                        {" "}
                        Select Reminder Frequency
                      </label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setFrequencyType(e.target.value);
                          if (e.target.value === "Custom") {
                            setCustomReminder(true);
                          } else {
                            setCustomReminder(false);
                          }
                        }}
                      >
                        <option>Select</option>
                        <option value="Daily" label={"Daily"}>
                          Daily
                        </option>
                        <option value="Weekly" label={"Weekly"}>
                          Weekly
                        </option>
                        <option value="Monthly" label={"Monthly"}>
                          Monthly
                        </option>
                        <option value="Quarterly" label={"Quarterly"}>
                          Quarterly
                        </option>
                        <option value="Custom" label={"Custom"}>
                          Custom
                        </option>
                      </select>
                    </div>
                    {customReminder && (
                      <div className="col-md-2">
                        <label className="form-group innergroup fw-bold">
                          {" "}
                          Select Reminder Dates
                        </label>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          options={reminderDates}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select..."
                          onChange={(event, action) => {
                            handleChange(event, action.name);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {/* <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="status"
                        value={publishData.status}
                        onChange={() =>
                          setPublishData({
                            ...publishData,
                            status: true,
                          })
                        }
                        checked={publishData.status === true}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="status"
                        value={publishData.status}
                        onChange={() =>
                          setPublishData({
                            ...publishData,
                            status: false,
                          })
                        }
                        checked={publishData.status === false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-md-3 innergroup">
            <div class="form-group">
              <h6 class="fw-bold mb-1">Email:</h6>
              <label class="logCheck d-inline-block mr-4">
                Active:
                <input
                  type="radio"
                  checked={publishData.send_email === true}
                  onChange={() =>
                    setPublishData((prev) => ({
                      ...prev,
                      send_email: true,
                    }))
                  }
                  name="emailactive"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block">
                Inactive:
                <input
                  type="radio"
                  checked={publishData.send_email === false}
                  onChange={() =>
                    setPublishData((prev) => ({
                      ...prev,
                      send_email: false,
                    }))
                  }
                  name="emailactive"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="col-md-3 innergroup">
            <div class="form-group">
              <h6 class="fw-bold mb-1">Email Date & Time:</h6>
              <div class="form-group innergroup">

                <div id="external_mails">
                  <input
                    type="datetime-local"
                    class="form-control mt-2"
                    // id="input_user_mail"
                    placeholder="Enter Email Time"
                    // disabled={isViewing}
                    // value={mailItem}
                    onChange={(e) => setEmailTime(e.target.value)}
                    value={emailTime}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email Subject
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    <input
                      type="text"
                      class="form-control mt-2"
                      // id="input_user_mail"
                      placeholder="Enter email Subject"
                      // disabled={isViewing}
                      // value={mailItem}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      value={emailSubject}
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email Body
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    <CKEditor
                      editor={ClassicEditor}
                      name="question"
                      onReady={(editor) => {
                        // console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        console.log(editor, editor.getData());
                        const data = editor.getData();
                        setEmailBody(data);
                      }}
                      data={emailBody}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group innergroup">
              <label>
                Email Signature
                {publishData?.send_email && <span class="text-danger">*</span>}
              </label>

              <div id="external_mails">
                <CKEditor
                  editor={ClassicEditor}
                  name="email_sign"
                  onReady={(editor) => {
                    // console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEmailSign(data);
                  }}
                  data={emailSign}
                />
                {/* <input
                      type="text"
                      class="form-control mt-2"
                      // id="input_user_mail"
                      placeholder="Enter email signature"
                      // disabled={isViewing}
                      // value={mailItem}
                      onChange={(e) => setEmailSign(e.target.value)}
                    /> */}
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Organisation<span class="text-danger">*</span>
                </label>
                <Select
                  isDisabled={isViewing}
                  name="organisation"
                  isMulti
                  options={org}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    pushOrg(e);
                    setFormValidation({
                      ...formValidation,
                      organisation_ids: "",
                    });
                  }}
                  value={orgListValue}
                />
                <div className="small text-danger">
                  {formValidation.organisation_ids}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Business unit<span class="text-danger"></span>
                </label>
                <Select
                  isDisabled={isViewing}
                  name="branch_id"
                  isMulti
                  options={branch}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    pushBranch(e);
                  }}
                  value={branchList}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Personal Sub Area<span class="text-danger"></span>
                </label>
                <Select
                  isDisabled={isViewing}
                  name="psa"
                  isMulti
                  options={psaList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    pushPsa(e);
                  }}
                  value={psa}
                />
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Department<span class="text-danger"></span>
                </label>
                <Select
                  isDisabled={isViewing}
                  name="dept_id"
                  isMulti
                  options={dept}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    pushDept(e);
                  }}
                  value={deptList}
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Users<span class="text-danger"></span>
                </label>
                <Select
                  isDisabled={isViewing}
                  isMulti
                  options={userList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    pushUsers(e);
                  }}
                  value={usersDataValue}
                />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class=" border-bottom mb-3">
              <h6 class="fw-bold mb-1">Permissions</h6>
              <p class="f-14 text-muted">Select user Permissions settings</p>

              <div class="row">
                <div class="col-md-6">
                  <div class="privatepublic permissionpublic mb-2 innergroup">
                    <label class="logCheck d-block">
                      Submit Only
                      <br />
                      <span>Invited people can submit the form only.</span>
                      <input
                        disabled={isViewing}
                        type="radio"
                        name="woirowe"
                        onClick={() => {
                          setPublishData({
                            ...publishData,
                            permission_type: "SO",
                          });
                        }}
                        value={publishData.permission_type}
                        checked={
                          publishData.permission_type == "SO" ? true : false
                        }
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-block">
                      Submit & View Later
                      <br />
                      <span>
                        Invited People can submit the form and view their
                        submissions later
                      </span>
                      <input
                        disabled={isViewing}
                        type="radio"
                        name="weirowe"
                        onClick={() => {
                          setPublishData({
                            ...publishData,
                            permission_type: "SV",
                          });
                        }}
                        value={publishData.permission_type}
                        checked={
                          publishData.permission_type == "SV" ? true : false
                        }
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-block">
                      Submit & Edit Later
                      <br />
                      <span>
                        Invited People can submit the form and view and edit
                        their submissions later.
                      </span>
                      <input
                        disabled={isViewing}
                        type="radio"
                        name="oeitueor"
                        onClick={() => {
                          setPublishData({
                            ...publishData,
                            permission_type: "SE",
                          });
                        }}
                        value={publishData.permission_type}
                        checked={
                          publishData.permission_type == "SE" ? true : false
                        }
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class=" border-bottom mb-3">
              <h6 class="fw-bold mb-1">Status:</h6>
              <label class="logCheck d-block">
                Active:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.is_active === true}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      is_active: true,
                      status: true,
                    })
                  }
                  name="askdjaks"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-block">
                Inactive:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.is_active === false}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      is_active: false,
                      status: false,
                    })
                  }
                  name="asjkdas"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="col-md-12">
            <div class="mb-3">
              <label class="fw-bold mb-1">Compulsory:</label>
              <label class="logCheck d-block">
                Yes:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.compulsory === true}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      compulsory: true,
                    })
                  }
                  name="comp"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-block">
                No:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.compulsory === false}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      compulsory: false,
                    })
                  }
                  name="comp"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="col-md-12 mt-2">
            <button
              class="btn btn-outline-secondary mr-3"
              onClick={() => navigate("/survey")}
            >
              <i class="far fa-times-circle"></i> Cancel
            </button>
            <button
              hidden={isViewing}
              disabled={edit?.survey_status === "Published"}
              class="btn btn-outline-primary mr-3"
              onClick={() => handleValidateTab(false)}
            >
              <i class="far fa-save"></i> Save as Draft
            </button>
            <button
              hidden={isViewing}
              class="btn btn-primary-inner bpi-main"
              onClick={() => handleValidateTab(true)}
            >
              <i class="far fa-check-circle"></i> Publish
            </button>
          </div>
        </div>
      ) : (
        <div class="row mt-2">
          <div class="col-md-12">
            <div class=" border-bottom mb-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Start Date<span class="text-danger">*</span>
                    </label>
                    <input
                      disabled={isViewing}
                      type="date"
                      class="form-control"
                      name="start_date"
                      onChange={(e) => {
                        setPublishData({
                          ...publishData,
                          start_date: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          start_date: "",
                        });
                      }}
                      value={publishData.start_date}
                    />
                    <div className="small text-danger">
                      {formValidation.start_date}
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      End Date<span class="text-danger">*</span>
                    </label>
                    <input
                      disabled={isViewing}
                      type="date"
                      class="form-control"
                      name="end_date"
                      onChange={(e) => {
                        setPublishData({
                          ...publishData,
                          end_date: e.target.value,
                        });
                        setFormValidation({
                          ...formValidation,
                          end_date: "",
                        });
                      }}
                      value={publishData.end_date}
                    />
                    <div className="small text-danger">
                      {formValidation.end_date}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="mb-3">
                    <label class="fw-bold mb-1">Reminder:</label>
                    <label class="logCheck d-block">
                      Yes:
                      <input
                        type="radio"
                        checked={publishData.reminder === true}
                        onClick={() => {
                          setPublishData({
                            ...publishData,
                            reminder: true,
                          });
                          reminderDatesFunction();
                        }}
                        name="reminder"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-block">
                      No:
                      <input
                        type="radio"
                        checked={publishData.reminder === false}
                        onClick={() =>
                          setPublishData({
                            ...publishData,
                            reminder: false,
                          })
                        }
                        name="reminder"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                {publishData?.reminder && (
                  <>
                    <div className="col-md-2">
                      <label className="form-group innergroup fw-bold">
                        {" "}
                        Select Reminder Frequency
                      </label>
                      <select
                        class="form-control"
                        onChange={(e) => {
                          setFrequencyType(e.target.value);
                          if (e.target.value === "Custom") {
                            setCustomReminder(true);
                          } else {
                            setCustomReminder(false);
                          }
                        }}
                      >
                        <option>Select</option>
                        <option value="Daily" label={"Daily"}>
                          Daily
                        </option>
                        <option value="Weekly" label={"Weekly"}>
                          Weekly
                        </option>
                        <option value="Monthly" label={"Monthly"}>
                          Monthly
                        </option>
                        <option value="Quarterly" label={"Quarterly"}>
                          Quarterly
                        </option>
                        <option value="Custom" label={"Custom"}>
                          Custom
                        </option>
                      </select>
                    </div>
                    {customReminder && (
                      <div className="col-md-2">
                        <label className="form-group innergroup fw-bold">
                          {" "}
                          Select Reminder Dates
                        </label>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          options={reminderDates}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select..."
                          onChange={(event, action) => {
                            handleChange(event, action.name);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                {/* <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="status"
                        value={publishData.status}
                        onChange={() =>
                          setPublishData({
                            ...publishData,
                            status: true,
                          })
                        }
                        checked={publishData.status === true}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="status"
                        value={publishData.status}
                        onChange={() =>
                          setPublishData({
                            ...publishData,
                            status: false,
                          })
                        }
                        checked={publishData.status === false}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div class="col-md-3 innergroup">
            <div class="form-group">
              <h6 class="fw-bold mb-1">Email:</h6>
              <label class="logCheck d-inline-block mr-4">
                Active:
                <input
                  type="radio"
                  checked={publishData.send_email === true}
                  onChange={() =>
                    setPublishData((prev) => ({
                      ...prev,
                      send_email: true,
                    }))
                  }
                  name="emailactive"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block">
                Inactive:
                <input
                  type="radio"
                  checked={publishData.send_email === false}
                  onChange={() =>
                    setPublishData((prev) => ({
                      ...prev,
                      send_email: false,
                    }))
                  }
                  name="emailactive"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="col-md-3 innergroup">
            <div class="form-group">
              <h6 class="fw-bold mb-1">Email Date & Time:</h6>
              <div class="form-group innergroup">

                <div id="external_mails">
                  <input
                    type="datetime-local"
                    class="form-control mt-2"
                    // id="input_user_mail"
                    placeholder="Enter Email Time"
                    // disabled={isViewing}
                    // value={mailItem}
                    onChange={(e) => setEmailTime(e.target.value)}
                    value={emailTime}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    {/* <div>
                          <input
                            type="text"
                            class="form-control mt-2"
                            id="input_user_mail"
                            placeholder="Enter email"
                            name="email_external"
                      
                          />
                        </div>   */}

                    {external_mails.map((mailItem, idexx) => (
                      <div>
                        <input
                          type="email"
                          class="form-control mt-2"
                          // id="input_user_mail"
                          placeholder="Enter email Id"
                          disabled={isViewing || filename !== ""}
                          value={mailItem}
                          onChange={(e) =>
                            set_inputemaildata(e.currentTarget.value, idexx)
                          }
                        />
                      </div>
                    ))}

                    <hr />
                    <button
                      disabled={isViewing || filename !== ""}
                      type="button"
                      class="btn btn-secondary btn-sm mr-2"
                      // onClick={()=>addExternalMailById()}
                      onClick={() => addExternalMail()}
                    >
                      <i class="fas fa-plus-square mr-1"></i> Add more emails
                    </button>
                    <label htmlFor="emailExcel">
                      <button
                        disabled={isViewing}
                        type="button"
                        class="btn btn-secondary btn-sm"
                        onClick={() => {
                          emailUploadRef.current.click();
                        }}
                      >
                        <i class="fas fa-plus-square mr-1"></i>Upload File
                      </button>
                    </label>
                    <input
                      type="file"
                      name="email-excel"
                      id="emailExcel"
                      ref={emailUploadRef}
                      onChange={(e) => {
                        uploadEmailUploads(e);
                      }}
                      hidden
                    />
                    {filename !== "" && (
                      <>
                        <label className="ml-2">
                          ({filename !== "" && filename})
                        </label>
                        <i
                          className="far fa-trash-alt text-danger ml-1"
                          onClick={() => {
                            setPublishData((prev) => ({
                              ...prev,
                              uploadedEmails: {},
                            }));
                            setfilename("");
                          }}
                        ></i>
                      </>
                    )}
                    <div
                      className="mt-2"
                      style={{
                        marginLeft: "120px",
                      }}
                    >
                      <label htmlFor="">
                        <button class="editbtn">
                          <i class="far fa-file-excel"></i>
                          <a href="/EmailUpload.xlsx" download>Download Template</a>
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email Subject
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    <input
                      type="text"
                      class="form-control mt-2"
                      // id="input_user_mail"
                      placeholder="Enter email Subject"
                      // disabled={isViewing}
                      // value={mailItem}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      value={emailSubject}
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email Body
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    <CKEditor
                      editor={ClassicEditor}
                      name="question"
                      onReady={(editor) => {
                        // console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEmailBody(data);
                      }}
                      data={emailBody}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Email Signature
                    {publishData?.send_email && (
                      <span class="text-danger">*</span>
                    )}
                  </label>

                  <div id="external_mails">
                    <CKEditor
                      editor={ClassicEditor}
                      name="email_sign"
                      onReady={(editor) => {
                        // console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setEmailSign(data);
                      }}
                      data={emailSign}
                    />
                    {/* <input
                      type="text"
                      class="form-control mt-2"
                      // id="input_user_mail"
                      placeholder="Enter email signature"
                      // disabled={isViewing}
                      // value={mailItem}
                      onChange={(e) => setEmailSign(e.target.value)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class=" mb-3">
              <h6 class="fw-bold mb-1">Permissions</h6>
              <p class="f-14 text-muted">Select user Permissions settings</p>

              <div class="row">
                <div class="col-md-6">
                  <div class="privatepublic permissionpublic mb-2 innergroup">
                    <label class="logCheck d-block">
                      Submit Only
                      <br />
                      <span>
                        Invited people can view and submit the form only.
                      </span>
                      <input
                        disabled={isViewing}
                        type="radio"
                        name="permission_type"
                        onChange={() => {
                          setPublishData({
                            ...publishData,
                            permission_type: "SO",
                          });
                        }}
                        value={publishData.permission_type}
                        checked={publishData?.permission_type === "SO"}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 innergroup">
            <div class="form-group pb-3">
              <h6 class="fw-bold mb-1">Status:</h6>
              <label class="logCheck d-inline-block mr-4">
                Active:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.is_active === true}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      is_active: true,
                      status: true,
                    })
                  }
                  name="statuss"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-inline-block">
                Inactive:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.is_active === false}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      is_active: false,
                      status: false,
                    })
                  }
                  name="statuss"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="col-md-12">
            <div class="mb-3">
              <label class="fw-bold mb-1">Compulsory:</label>
              <label class="logCheck d-block">
                Yes:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.compulsory === true}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      compulsory: true,
                    })
                  }
                  name="comp"
                />
                <span class="checkmark"></span>
              </label>
              <label class="logCheck d-block">
                No:
                <input
                  disabled={isViewing}
                  type="radio"
                  checked={publishData.compulsory === false}
                  onClick={() =>
                    setPublishData({
                      ...publishData,
                      compulsory: false,
                    })
                  }
                  name="comp"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <div class="col-md-12 mt-2">
            <button
              class="btn btn-outline-secondary mr-3"
              onClick={() => navigate("/survey")}
            >
              <i class="far fa-times-circle"></i> Cancel
            </button>
            <button
              hidden={isViewing}
              //  disabled
              class="btn btn-outline-primary mr-3"
              onClick={() => handleValidateTab(false)}
            >
              <i class="far fa-save"></i> Save as Draft
            </button>
            <button
              // disabled
              hidden={isViewing}
              class="btn btn-primary-inner bpi-main"
              onClick={() => handleValidateTab(true)}
            >
              <i class="far fa-check-circle"></i> Publish
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublishSetting;
