import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getUserRmList, getGlCodeList } from "../../service/jv_service";
import { toast, ToastContainer } from "react-toastify";
import { AddGlApprover, updateGlApprover } from "../../service/glService";
import { selectUserData } from "../redux/piDataStore";

function GlApproverFrom(props) {
  const userData = useSelector(selectUserData);

  const {
    show,
    close,
    edit,
    getList,
    editState,
    resetEditState,
    poOrgList,
    pGroupList,
    plantlist,
    releaseStrat,
  } = props;
  let condition = "false";
  const navigate = useNavigate();
  const [glData, setglData] = useState({
    Requestor: {},
    Glcode: {},
    Approvers: [],
    is_active: true,
  });
  const [sesError, setsesError] = useState({
    Requestor: false,
    Glcode: false,
    Approvers: false,
    is_active: false,
  });
  const [userlist, setuserlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [glcode, setGlcode] = useState([]);
  const options = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
  ];
  const userlistedit = (data2) => {
    console.log(data2);
    let data = data2.filter((obj) => {
      if (
        obj.value !== editState?.approver_id &&
        obj.value !== editState?.requester_id
      ) {
        return obj;
      }
    });
    console.log(data);
    setuserlist(data);
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let user = [];
        response?.data?.dataList?.result?.map((x) => {
          // if (userData?.username !== x.username) {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          user.push(data);
          // }
        });

        if (edit != true) {
          setuserlist(user);
        } else {
          userlistedit(user);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getGlcodeList = () => {
    getGlCodeList()
      .then((response) => {
        console.log(response?.data?.dataList?.result);
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.gl_account + "(" + x?.short_desc + ")",
          };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    console.log(editState);
    if (edit) {
      Object.keys(editState).forEach((item) => {
        glData[item] = editState[item];
      });
    } else {
      setglData({
        Requestor: {},
        Glcode: {},
        Approvers: [],
        is_active: true,
      });
    }
  }, [editState]);
  useEffect(() => {
    getUserList();
    getGlcodeList();
  }, []);
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    // console.log(type, event.target.value);
    switch (type) {
      case "Requestor":
        if (glData?.Requestor == "") {
          setglData((prev) => ({
            ...prev,
            Requestor: event,
          }));
          setsesError((prev) => ({
            ...prev,
            Requestor: false,
          }));
          let data = userlist.filter((obj) => {
            if (obj.label !== event.label) {
              return obj;
            }
          });
          setuserlist(data);
          console.log(data);
        } else {
          setglData((prev) => ({
            ...prev,
            Requestor: event,
          }));
          let currentData = glData?.Requestor;
          setsesError((prev) => ({
            ...prev,
            Requestor: false,
          }));
          let data = userlist.filter((obj) => {
            if (obj.label !== event.label) {
              return obj;
            }
          });
          data.push(currentData);

          setuserlist(data);
        }
        // setglData((prev) => ({
        //   ...prev,
        //   Requestor: event,
        // }));
        // setsesError((prev) => ({
        //   ...prev,
        //   Requestor: false,
        // }));
        break;
      case "Approvers":
        if (glData?.Approvers == "") {
          setglData((prev) => ({
            ...prev,
            Approvers: event,
          }));
          setsesError((prev) => ({
            ...prev,
            Approvers: false,
          }));
          let data = userlist.filter((obj) => {
            if (obj.label !== event.label) {
              return obj;
            }
          });
          setuserlist(data);
          console.log(data);
        } else {
          setglData((prev) => ({
            ...prev,
            Approvers: event,
          }));
          let currentData = glData?.Approvers;
          setsesError((prev) => ({
            ...prev,
            Approvers: false,
          }));
          let data = userlist.filter((obj) => {
            if (obj.label !== event.label) {
              return obj;
            }
          });
          data.push(currentData);

          setuserlist(data);
        }

        setglData((prev) => ({
          ...prev,
          Approvers: event,
        }));
        setsesError((prev) => ({
          ...prev,
          Approvers: false,
        }));
        break;
      case "Glcode":
        setglData((prev) => ({
          ...prev,
          Glcode: event,
        }));
        setsesError((prev) => ({
          ...prev,
          Glcode: false,
        }));
        break;
      case "is_active":
        setglData((prev) => ({
          ...prev,
          is_active: event.target.value == "true" ? true : false,
        }));
        setsesError((prev) => ({
          ...prev,
          is_active: false,
        }));
        break;
      default:
        break;
    }
  };
  const resetForm = () => {
    setglData({
      Requestor: {},
      Glcode: {},
      Approvers: [],
      is_active: "true",
    });
    resetEditState();
  };
  const submit = () => {
    setIsLoading(true);
    console.log(editState.length === 0);
    if (condition == "false") {
      condition = "true";
      if (editState.length === 0) {
        AddGlApprover(glData)
          .then((response) => {
            if (response?.data?.message.includes("already")) {
              setIsLoading(false);
              toast.warning(response?.data?.message);
            } else {
              setIsLoading(false);
              toast.success(response?.data?.message);
            }
            condition = "false";
            getList();
            close();
            resetForm();
          })
          .catch((error) => {
            condition = "false";
            setIsLoading(false);
            toast.warning(error?.data?.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      } else {
        setIsLoading(true);
        console.log("editttt", glData);
        updateGlApprover(glData)
          .then((response) => {
            condition = "false";
            setIsLoading(false);
            if (response?.data?.message.includes("already")) {
              toast.warning(response?.data?.message);
            } else {
              toast.success(response?.data?.message);
            }
            getList();
            close();
            resetForm();
          })
          .catch((error) => {
            condition = "false";
            setIsLoading(false);
            toast.warning(error?.data?.message);
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
                "failed"
            );
          });
      }
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Map User Reviewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      GL Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="Glcode"
                      options={glcode}
                      className="basic-multi-select"
                      value={glData?.Glcode}
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.Glcode}
                      className="small text-danger"
                    >
                      Kindly select Gl Code
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Requestor<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="Requestor"
                      options={userlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={glData?.Requestor}
                      placeholder="Enter Requestor SAP ID"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.Requestor}
                      className="small text-danger"
                    >
                      Kindly select Requestor
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approvers<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="Approvers"
                      options={userlist}
                      value={glData?.Approvers}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.Approvers}
                      className="small text-danger"
                    >
                      Kindly select Approvers
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input
                        name="is_active"
                        value={true}
                        type="radio"
                        onChange={(e) => handleChange(e)}
                        checked={glData?.is_active == true ? true : false}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input
                        name="is_active"
                        value={false}
                        type="radio"
                        onChange={(e) => handleChange(e)}
                        checked={glData?.is_active == true ? false : true}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button class="btn btn-outline-danger" onClick={close}>
                Cancel
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={() => submit()}
              >
                Submit
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GlApproverFrom;
