import React, { useState, useEffect } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import CustomMenu from "./CustomMenu";
import CustomPagination from "./CustomPagination";
import CustomBreadcrumb from "./CustomBreadcrumb";
import AddPowerUser from "./AddPowerUser";
import * as HSNService from "../../service/hsnService";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

function PowerUser() {
  const CustomBreadcrumbItems = [
    { to: "/home", title: "Home" },
    { to: "/", title: "Transactions" },
    { active: true, title: "Power User" },
  ];
  const [approverList, setApproverList] = useState();
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [editData, setEditData] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const getHsnApproverListing = (page_no, page_size) => {
    HSNService.getHsnApproverListing(page_no, page_size)
      .then((response) => {
        setApproverList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const editHsnApproverList = (data) => {
    setEditData(data);
    addnewopen();
    setEditFlag(true);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getHsnApproverListing(activePage, pageSizeNo);
  };

  // useEffect(() => {
  //   getHsnApproverListing("1", "10");
  // }, []);

  useEffect(() => {
    getHsnApproverListing(currentPage, pageSizeNo);
  }, [pageSizeNo]);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => {
     addnewsetShow(false);
     setEditData()
     setEditFlag(false)
  }

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to proceed",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await HSNService.deleteHsnPowerUser(id)
              .then((response) => {
                if (response?.data?.code === 200) {
                  getHsnApproverListing(currentPage, pageSizeNo)
                  toast.success(response?.data?.message);
                }
              })
              .catch((error) => {
                toast.error(error?.response?.data?.message ?? error.message);
              });
          },
        },
        {
          label: "No",
          onClick: () => console.log("bye"),
        },
      ],
    });
  };

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <CustomMenu />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Power User</h4>
                  {/* <CustomBreadcrumb items={CustomBreadcrumbItems} /> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <button class="btn btn-primary-inner" onClick={addnewopen}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Material Type</th>
                    <th>Plant</th>
                    <th>Approver 1</th>
                    <th>Approver 2</th>
                  </tr>
                </thead>
                <tbody>
                  {approverList?.length > 0 ? (
                    approverList?.map((items) => (
                      <tr>
                        <td>
                          <button
                            class="btn btn-blanktd text-primary f-12"
                            onClick={() => editHsnApproverList(items)}
                          >
                            <i class="far fa-edit"></i>
                          </button>
                          <button class="btn btn-blanktd text-danger ml-2 f-12"  onClick={() => handleDelete(items?.id)}>
                            <i class="far fa-trash-alt text-danger"></i>
                          </button>
                        </td>
                        <td class="text-theme fw-bold">
                          {items?.material_type_name}
                        </td>
                        <td>{`${items?.plant_description} (${items?.plant_code})`}</td>
                        <td>{items?.approver_1}</td>
                        <td>{items?.approver_2}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <CustomPagination
              setPageSizeNo={setPageSizeNo}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
      {addnewshow === true && (
        <AddPowerUser
          addnewshow={addnewshow}
          addnewclose={addnewclose}
          getHsnApproverListing={() => {
            getHsnApproverListing(1,10)
          }}
          editData={editData}
          editFlag={editFlag}
        />
      )}
    </>
  );
}

export default PowerUser;
