import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as CountryService from "../../service/country";
import { Formik } from "formik";
import * as Yup from "yup";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field should contain minimum 2 alphabet")
    .required("*Country Name is required")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'])+$/u,
      "Insert only character"
    )
    .nullable(),
  dial_code: Yup.string()

    .max(5, "Maximum 5 digits")
    .required("*Country Dial Code is required")
    .nullable(),
  alias: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field should contain minimum 2 alphabet")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only character"
    )
    .required("*Country Prefix Name is required")
    .nullable(),
  currency: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field should contain minimum 2 alphabet")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only character"
    )
    .required("*Currency is required")
    .nullable(),
  // flag: Yup.mixed().required("*Flag is required").nullable(),
});

const AddCountry = (props) => {
  const nameForm = "Country Master";
  const { edit, showForm, onClose, editCountry, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDeptExist, setIsDeptExist] = useState(false);
  const [isDeptCodeExist, setIsDeptCodeExist] = useState(null);
  const [link, setLink] = useState(null);
  const [isAliasExist, setIsAliasExist] = useState(false);
  const [imgSize, setImgSize] = useState(false);
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [uploadFlag, setUploadFlag] = useState({ flag: {} });
  const [isLogo, setIsLogo] = useState(false);

  const [countryData, setCountryData] = useState({
    name: "",
    dial_code: "",
    alias: "",
    currency: "",
    is_active: true,
    flag: {},
  });

  const saveCountry = (values) => {
    if (uploadFlag?.flag?.original_name === undefined && link === null) {
      toast.error("Please Select Flag");
    } else {
      let data = values;
      data.flag = uploadFlag.flag;
      setIsLoading(true);
      CountryService.saveCountry(values)
        .then((response) => {
          if (response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
            setUploadFlag({ flag: {} });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.detail);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const updateCountry = (id, values) => {
    if (uploadFlag?.flag?.original_name === undefined && link === null) {
      toast.error("Please Select Flag");
    } else {
      setIsLoading(true);
      let datass = values;
      datass.flag = uploadFlag.flag;
      CountryService.updateCountry(id, datass)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
            setUploadFlag({ flag: {} });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.detail);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const dataBlank = () => {
    onClose();
  };
  const isCountryExists = (e, fieldName) => {
    if (e.target.value !== "") {
      let data = {};
      data[fieldName] = e.target.value;
      if (editCountry?.name == e.target.value) {
        setIsDeptExist(false);
        return;
      } else if (editCountry?.dial_code == e.target.value) {
        setIsDeptCodeExist(false);
        return;
      } else if (editCountry?.alias == e.target.value) {
        setIsAliasExist(false);
        return;
      } else {
        CountryService.isCountryExists(data)
          .then((res) => {
            if (e.target.name == "name") {
              setIsDeptExist(res?.data?.dataList?.is_exist);
            } else if (e.target.name == "dial_code") {
              setIsDeptCodeExist(res?.data?.dataList?.is_exist);
            } else {
              setIsAliasExist(res?.data?.dataList?.is_exist);
            }
          })
          .catch((err) => console.log("Error=>", err));
      }
    }
  };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    if (files.size < 1033415 || files.size === 1033415) {
      if (
        files.type === "image/png" ||
        files.type === "image/svg" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        setLink(undefined);
        setImgSize(false);
        const fileData = new FormData();
        fileData.append("uploaded_file", files);
        CountryService.uploadImages(fileData).then((response) => {
          if (flag == "flag") {
            setUploadFlag({ ...uploadFlag, flag: response.data });
            setIsLogo(false);
          }
        });
      } else {
        toast.error("Only jpg, png, jpeg and svg files are supported");
        setUploadFlag({
          flag: {},
        });
        setLink(null);
        setIsLogo(false);
      }
    } else {
      setImgSize(true);
      setIsLogo(false);
    }
  };
  useEffect(() => {
    if (editCountry) {
      setLink(editCountry.flag.split("/")[4]);
      setCountryData(editCountry);
      setIsLogo(false);
      Object.keys(editCountry).forEach((item) => {
        countryData[item] = editCountry[item];
      });
      countryData["flag"] = {};
    }
  }, [editCountry]);

  useEffect(() => {}, [auditValueLog]);
  const submitProfile = () => {
    if (!editCountry) {
      if (document.getElementById("logoFile")?.files?.length == 0) {
        setIsLogo(true);
      }
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={countryData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (
            isDeptExist !== true &&
            isDeptCodeExist !== true &&
            isLogo !== true &&
            isAliasExist !== true
          ) {
            if (values.id) {
              updateCountry(values.id, values);
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              saveCountry(values);
              AuditLog.addAuditLog(dispatch, nameForm);
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Country
              </Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div class="row justify-content-center">
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Country Dial Code
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="number"
                              class="form-control nonumbdrop"
                              placeholder="Enter Country Dial Code"
                              name="dial_code"
                              maxLength={5}
                              // min="1"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onBlur={handleBlur}
                              onChange={(e) => {
                                isCountryExists(e, "dial_code");
                                handleChange(e);
                              }}
                              value={values.dial_code}
                            />
                            {touched.dial_code && errors.dial_code ? (
                              <div className="small text-danger">
                                {errors.dial_code}
                              </div>
                            ) : isDeptCodeExist === true ? (
                              <div className="small text-danger">
                                <span class="text-danger">*</span>Country Dail
                                Code already exists
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Country Name<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Country Name"
                              maxLength={30}
                              name="name"
                              onChange={handleChange}
                              onBlur={(e) => {
                                isCountryExists(e, "name");
                                handleBlur(e);
                              }}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="small text-danger">
                                {errors.name}
                              </div>
                            ) : isDeptExist === true ? (
                              <div className="small text-danger">
                                <span class="text-danger">*</span>Country Name
                                already exists
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Alias Country Prefix
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Country prefix"
                              maxLength={5}
                              name="alias"
                              onChange={handleChange}
                              onBlur={(e) => {
                                isCountryExists(e, "alias");
                                handleBlur(e);
                              }}
                              value={values.alias}
                            />
                            {touched.alias && errors.alias ? (
                              <div className="small text-danger">
                                {errors.alias}
                              </div>
                            ) : isAliasExist === true ? (
                              <div className="small text-danger">
                                <span class="text-danger">*</span>Country prefix
                                already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>
                              Currency<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Currency"
                              maxLength={30}
                              name="currency"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.currency}
                            />
                            {touched.currency && errors.currency ? (
                              <div className="small text-danger">
                                {errors.currency}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Flag
                              <span className="text-danger">*</span>
                            </label>
                            {console.log(uploadFlag)}
                            {uploadFlag?.flag?.original_name != undefined ? (
                              <input
                                type="text"
                                className="form-control bg-white"
                                disabled
                                accept="image/*"
                                value={
                                  uploadFlag?.flag?.original_name?.slice(
                                    0,
                                    28
                                  ) + "..."
                                }
                                placeholder="(Image, PDF Format)"
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control bg-white"
                                disabled
                                placeholder="(Image, PDF Format)"
                                value=""
                              />
                            )}

                            {isLogo && !editCountry ? (
                              <div className="small text-danger">
                                *Flag is required
                              </div>
                            ) : null}
                            <div className="upload-btn-wrapper up-loposition">
                              <button className="uploadBtn">Browse</button>
                              <input
                                type="file"
                                title=""
                                name="flag"
                                id="logoFile"
                                accept="image/*"
                                onChange={(e) => onProfileChange(e, "flag")}
                              />
                            </div>
                            {imgSize ? (
                              <div className="small text-danger">
                                File size should not exceed 1mb
                              </div>
                            ) : null}

                            {/* {console.log(uploadFlag?.flag?.original_name, 'pick')} */}
                            {uploadFlag?.flag?.original_name != undefined && (
                              <p>
                                Selected file{" "}
                                <span> {uploadFlag?.flag?.original_name}</span>
                              </p>
                            )}
                            {link != undefined && (
                              <p>Selected file {<span> {link}</span>}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label className="d-block">Status</label>
                            <label
                              className="logCheck d-inline-block mr-4"
                              htmlFor="active"
                            >
                              Active
                              <input
                                type="radio"
                                name="is_active"
                                id="active"
                                value={values.is_active}
                                onChange={() => {
                                  setFieldValue("is_active", true);
                                }}
                                checked={values.is_active === true}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block"
                              htmlFor="Inactive"
                            >
                              Inactive
                              <input
                                type="radio"
                                name="is_active"
                                id="Inactive"
                                value={values.is_active}
                                onChange={(e) => {
                                  setFieldValue("is_active", false);
                                }}
                                checked={values.is_active === false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          dataBlank();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => submitProfile()}
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddCountry;
