import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

const allUsers = async () => axios.get(`${AUTH_BASE_URL}/users?paginate=false`);

const mediclaminDetails = async (queryParam) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim`, {
    params: queryParam,
  }); /* get mediclaim listing */

const mediclaminDeclaration = async (org_id) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaimdeclaration?org_id=${org_id}`); /* get mediclaim listing */

const addMediclaminDetail = async (values) =>
  axios.post(
    `${AUTH_BASE_URL}/mediclaim/mediclaim`,
    values
  ); /* Add mediclaim details */
const updateMediclaminDetail = async (values, id) =>
  axios.put(
    `${AUTH_BASE_URL}/mediclaim/mediclaim/${id}`,
    values
  ); /* Update mediclaim details */
const deleteMediclaimDetail = async (id) =>
  axios.delete(
    `${AUTH_BASE_URL}/mediclaim/mediclaim_detail/${id}`
  ); /* Update mediclaim details */

const getMediclaimPremiums = async (queryParam, raw = false) => {
  console.log(
    "🚀 ~ file: medicalService.js:26 ~ getMediclaimPremiums ~ queryParam:",
    queryParam
  );
  if (raw) {
    Object.keys(queryParam).forEach((key) => {
      if (queryParam[key] === "" || queryParam[key]?.trim?.() === "") {
        delete queryParam[key];
      }
    });
  }
  return axios.get(
    `${AUTH_BASE_URL}/mediclaim/mediclaim_premium?${!raw && queryParam}`,
    { params: raw && queryParam }
  );
};
const addMediclaimPremium = async (values) =>
  axios.post(`${AUTH_BASE_URL}/mediclaim/mediclaim_premium`, values);

const updateMediclaimPremium = async (values) =>
  axios.put(`${AUTH_BASE_URL}/mediclaim/mediclaim_premium/${values?.id}`, values);

const getMediclaimPremiumsTopUp = async (queryParam, raw = false) =>
  axios.get(
    `${AUTH_BASE_URL}/mediclaim/mediclaim_top_up_premium?${!raw && queryParam}`,
    { params: raw && queryParam }
  );
const addMediclaimPremiumTopUp = async (values) =>
  axios.post(`${AUTH_BASE_URL}/mediclaim/mediclaim_top_up_premium`, values);

const updateMediclaimPremiumTopUp = async (values) =>
  axios.put(`${AUTH_BASE_URL}/mediclaim/mediclaim_top_up_premium/${values?.id}`, values);

const getOpenMediclaimDecl = async () =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_open_declaration`);
const openMediclaimDecl = async (values) =>
  axios.post(`${AUTH_BASE_URL}/mediclaim/mediclaim_open_declaration`, values);

const vpfOpenDecl = async (values) =>
  axios.post(`${AUTH_BASE_URL}/vpf/vpf_open_decl`, values);
const getvpfOpenDecl = async () =>
  axios.get(`${AUTH_BASE_URL}/vpf/vpf_open_decl`);

const npsOpenDecl = async (values) =>
  axios.post(`${AUTH_BASE_URL}/nps/nps_open_decl`, values);

/* bulk upload */
const bulkUploadMediclaimPremium = async (values) =>
  axios.post(
    `${AUTH_BASE_URL}/mediclaim/bulk_import_mediclaim_premium`,
    values,
    { responseType: "blob" }
  );
const bulkUploadMediclaimTopupPremium = async (values) =>
  axios.post(
    `${AUTH_BASE_URL}/mediclaim/bulk_import_mediclaim_top_up_premium`,
    values,
    { responseType: "blob" }
  );

  const reimbOpenDecl = async (values) =>
  axios.post(`${AUTH_BASE_URL}/reimbursement/reimb_open_decl`, values);

const getReimbOpenDecl = async () =>
  axios.get(`${AUTH_BASE_URL}/reimbursement/reimb_open_decl`);

// const getUserData = async (bussiness_unit_id) => {
//     return axios.get(
//         `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${bussiness_unit_id}`
//     );
// };

const getUserData = async (params) =>
  axios.get(`${AUTH_BASE_URL}/users`, { params });

const getEmployeeSubgroups = async (params) =>
  axios.get(`${AUTH_BASE_URL}/groups/getEmp_subGroup`, { params });

const mediclaimReportBHRReportList = async (queryParam) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_bhr_report`, {
    params: queryParam,
  });
const mediclaimReportDDList = async (queryParam) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_report`, {
    params: queryParam,
  });

const uploadFile = async ({ data, config }) =>
  axios.post(`${AUTH_BASE_URL}/upload`, data, config);

const isMediclaimOpen = (params) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_open_declaration_status`, {
    params,
  });

const changeMediclaimStatusForUser = (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/mediclaim/mediclaim_open_declaration_status`,
    data
  );
};

const isMediclaimAlreadyFilled = (payload) =>
  axios.post(`${AUTH_BASE_URL}/mediclaim/mediclaim_exists`, payload);

const exportMedicalReport = (params) =>
  axios.get(`${AUTH_BASE_URL}/mediclaim/mediclaim_report_export`, {
    params,
    responseType: "blob",
  });

const exportMediclaimPremium = (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/mediclaim/mediclaim_premium/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

const exportMediclaimPremiumTopup = (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/mediclaim/mediclaim_top_up_premium/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

const syncReportData = (users) => {
  return axios.get(
    `${AUTH_BASE_URL}/mediclaim/mediclaim-report-sync?users=${users.toString()}`
  );
};

const globalDetailsOfUpload = (params) =>
  axios.get(`${AUTH_BASE_URL}/global_import_details`);

const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/mediclaim/mediclaim-card-import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export {
  allUsers,
  uploadFile,
  getUserData,
  isMediclaimOpen,
  mediclaminDetails,
  mediclaminDeclaration,
  openMediclaimDecl,
  exportMedicalReport,
  addMediclaminDetail,
  addMediclaimPremium,
  updateMediclaimPremium,
  getMediclaimPremiums,
  getOpenMediclaimDecl,
  getEmployeeSubgroups,
  deleteMediclaimDetail,
  globalDetailsOfUpload,
  mediclaimReportDDList,
  updateMediclaminDetail,
  isMediclaimAlreadyFilled,
  addMediclaimPremiumTopUp,
  getMediclaimPremiumsTopUp,
  bulkUploadMediclaimPremium,
  mediclaimReportBHRReportList,
  bulkUploadMediclaimTopupPremium,
  vpfOpenDecl,
  reimbOpenDecl,
  npsOpenDecl,
  changeMediclaimStatusForUser,
  getvpfOpenDecl,
  getReimbOpenDecl,
  exportMediclaimPremium,
  exportMediclaimPremiumTopup,
  importExel,
  syncReportData,
  updateMediclaimPremiumTopUp
};
