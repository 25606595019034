import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import Select from "react-select";
import ServiceApproverFrom from "./serviceAproverForm";
import {
  deleteses,
  getAllSes,
  getAllSesApproverExport,
} from "../../service/sesService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { getPlantList, getUserRmList } from "../../service/jv_service";
import { getPGroupDrop, getPoOrgDrop } from "../../service/source-list";
import { getAllStrat } from "../../service/approver";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tippy";
import {
  selectUserData,
  selectFinalUrlPermission,
  setDisplayLoader,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";

function ServiceApprover() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [sesData, setSesData] = useState([]);

  const [filterdata, setfilterData] = useState({
    releaseStrat: { value: 0, label: "Select..." },
    plant: { value: 0, label: "Select..." },
    po: { value: 0, label: "Select..." },
    pg: { value: 0, label: "Select..." },
    approverid: { value: 0, label: "Select..." },
  });
  const resetFilterData = () =>
    setfilterData({
      releaseStrat: { value: 0, label: "Select..." },
      plant: { value: 0, label: "Select..." },
      po: { value: 0, label: "Select..." },
      pg: { value: 0, label: "Select..." },
      approverid: { value: 0, label: "Select..." },
    });
  const [editData, seteditData] = useState([]);
  const resetEditState = () => seteditData([]);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [userlist, setuserlist] = useState([]);
  const [poOrgList, setPoOrgList] = useState([]);
  const [pGroupList, setPGroupList] = useState([]);
  const [plantlist, setPlantList] = useState([]);
  const [releaseStrat, setReleaseStrat] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [search, setSearch] = useState("");
  const getSes = (
    search,
    page_no,
    page_size,
    paginate,
    release_start,
    plant_code,
    po_code,
    approver_1,
    pg_code,
    sort_by
  ) => {
    dispatch(setDisplayLoader("Display"));

    getAllSes(
      search,
      page_no,
      page_size,
      paginate,
      release_start,
      plant_code,
      po_code,
      approver_1,
      pg_code,
      sort_by
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setSesData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getExportDetails = (
    search,
    page_no,
    page_size,
    paginate,
    release_start,
    plant_code,
    po_code,
    approver_1,
    pg_code,
    sort_by
  ) => {
    getAllSesApproverExport(
      search,
      page_no,
      page_size,
      paginate,
      release_start,
      plant_code,
      po_code,
      approver_1,
      pg_code,
      sort_by
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SES_Assign_Approver.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const plantList = () => {
    getPlantList(0)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.description + "(" + x?.code + ")",
          };

          plant.push(data);
        });
        setPlantList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getPoList = () => {
    getPoOrgDrop("-id", false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.desc + "(" + x?.code + ")" };
          plant.push(data);
        });
        setPoOrgList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getPgList = () => {
    getPGroupDrop("-id", false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.pgcode + "(" + x?.pgdesc + ")" };
          plant.push(data);
        });
        setPGroupList(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getReleaseStrat = () => {
    getAllStrat(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = { value: x?.id, label: x?.code };
          plant.push(data);
        });
        setReleaseStrat(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const deleteApprover = (id) => {
    deleteses(id)
      .then((response) => {
        getSes("", currentPage, pageSizeNo, true, 0, 0, 0, 0, 0, "-id", false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    getPoList();
    plantList();
    getUserList();
    getReleaseStrat();
    getPgList();
  }, []);
  const handleDelete = (id) => {
    confirmAlert({
      title: "DeleteA Apply Leave",
      message: `Are you sure to delete ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  useEffect(() => {
    getSes("", currentPage, pageSizeNo, true, 0, 0, 0, 0, 0, "-id", false);
  }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSes("", activePage, pageSizeNo, true, 0, 0, 0, 0, 0, "-id", false);
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "approverId":
        setfilterData((prev) => ({
          ...prev,
          approverid: event,
        }));
        break;
      case "rls":
        setfilterData((prev) => ({
          ...prev,
          releaseStrat: event,
        }));
        break;
      case "po":
        setfilterData((prev) => ({
          ...prev,
          po: event,
        }));
        break;
      case "pg":
        setfilterData((prev) => ({
          ...prev,
          pg: event,
        }));
        break;
      case "plant":
        setfilterData((prev) => ({
          ...prev,
          plant: event,
        }));
        break;
    }
  };
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getSes("", currentPage, pageSizeNo, true, 0, 0, 0, 0, 0, "-id", false);
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    getSes(
      search,
      currentPage,
      pageSizeNo,
      true,
      filterdata?.releaseStrat.value,
      filterdata?.plant.value,
      filterdata?.po.value,
      filterdata?.approverid.value,
      filterdata?.pg.value,
      "-id",
      false
    );
    filterclose();
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getSes(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        true,
        filterdata?.releaseStrat.value,
        filterdata?.plant.value,
        filterdata?.po.value,
        filterdata?.approverid.value,
        filterdata?.pg.value,
        "-id",
        false
      );
      setSearch(e.target.value.toLowerCase());
    }
  };
  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/ses-search">Search</Link>
            </li>
            <li>
              <Link to="/service-entry-sheet">Service Entry Sheet</Link>
            </li>
            <li>
              <Link to="/service-entry-all">All Pending</Link>
            </li>
            <li class="active">
              <Link to="/service-approver">Assign Approver</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Assign Approver</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={sesData.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                search,
                                currentPage,
                                pageSizeNo,
                                true,
                                filterdata?.releaseStrat.value,
                                filterdata?.plant.value,
                                filterdata?.po.value,
                                filterdata?.approverid.value,
                                filterdata?.pg.value,
                                "-id",
                                false
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => {
                        setEditMode(false);
                        addnewopen();
                        seteditData([]);
                      }}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Approver
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Requester</th>
                    <th>Rel. Strategy</th>
                    <th>Plant</th>
                    <th>Doctype</th>
                    <th>Pur. Org</th>
                    <th>Pur. Grp</th>
                    <th>App. ID</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                    {/* <th>App. Name</th> */}
                  </tr>
                </thead>
                <tbody>
                  {sesData?.map((x) => (
                    <tr>
                      <td>
                        <button
                          type="submit"
                          onClick={() => {
                            setEditMode(true);
                            seteditData(x);
                            addnewopen();
                          }}
                          class="btn btn-blanktd text-primary ml-2 f-12"
                        >
                          <i class="far fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDelete(x?.id)}
                          class="btn btn-blanktd text-danger ml-2 f-12"
                        >
                          <i class="far fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                      <td>{x?.Requester_id}</td>
                      <td>
                        <Tooltip
                          title={x?.ReleaseStrategy?.map((r) => r?.label + ",")}
                          position="bottom"
                        >
                          {" "}
                          {x?.ReleaseStrategy?.map((r) => r?.label + ",")}{" "}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title={x?.Plant?.map((p) => p?.label + ",")}>
                          {x?.Plant?.map((p) => p?.label + ",")}
                        </Tooltip>
                      </td>
                      <td>{x?.documentType}</td>
                      <td>
                        <Tooltip
                          title={x?.PurchasingOrganization?.map(
                            (po) => po?.label + ","
                          )}
                        >
                          {x?.PurchasingOrganization?.map(
                            (po) => po?.label + ","
                          )}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          title={x?.PurchasingGroup?.map(
                            (pg) => pg?.label + ","
                          )}
                        >
                          {x?.PurchasingGroup?.map((pg) => pg?.label + ",")}
                        </Tooltip>
                      </td>
                      <td>
                        {x?.approver_name}({x?.aprover_username})
                      </td>
                      {/* <td>{x?.approver_name}</td> */}
                      <td>{x?.created_by_name}</td>
                      <td>{moment(x?.created_at).format('DD-MM-YYYY')}</td>
                      <td>{x?.updated_by_name}</td>
                      <td>{moment(x?.updated_at).format('DD-MM-YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select class="form-control">
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Release Strategy</label>
            <Select
              name="rls"
              options={releaseStrat}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.releaseStrat}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Plant</label>
            <Select
              name="plant"
              placeholder="Select"
              options={plantlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.plant}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Purchase Org.</label>
            <Select
              name="po"
              options={poOrgList}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.po}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Purchase Group</label>
            <Select
              name="pg"
              placeholder="Select"
              options={pGroupList}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.pg}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>Approver Id</label>
            <Select
              name="approverId"
              placeholder="Select"
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              value={filterdata?.approverid}
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {addnewshow && (
        <ServiceApproverFrom
          show={addnewshow}
          edit={editMode}
          getList={() =>
            getSes(
              "",
              currentPage,
              pageSizeNo,
              true,
              0,
              0,
              0,
              0,
              0,
              "-id",
              false
            )
          }
          poOrgList={poOrgList}
          pGroupList={pGroupList}
          plantlist={plantlist}
          releaseStrat={releaseStrat}
          close={addnewclose}
          open={addnewopen}
          editState={editData}
          resetEditState={resetEditState}
        ></ServiceApproverFrom>
      )}
    </>
  );
}

export default ServiceApprover;
