import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";

const ViewSkill = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  const design = {
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  return (
    <>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Skill</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>
                      Skill ID<span className="text-danger"></span>
                    </label>
                    <p style={design}>{viewFormData?.skill_id}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Description</label>
                    <p style={design}>{viewFormData?.desc}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Group Name</label>
                    <p style={design}>{viewFormData?.group?.group_name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sub Group name</label>
                    <p style={design}>{viewFormData?.sub_group?.sub_group_name}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <p>{viewFormData?.is_active ? "Active" : "Inactive"}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Location</label>
                    <p>
                      {" "}
                      {viewFormData?.location?.length !== 0 ? (
                        viewFormData?.location.map((x) => (
                          <p>
                            <b>{x.loc_name}</b>{" "}
                          </p>
                        ))
                      ) : (
                        <p>N/A</p>
                      )}
                    </p>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <p>{viewFormData?.created_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <p>
                      {moment
                        .utc(viewFormData?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated By</label>
                    <p>{viewFormData?.updated_by}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <p>
                      {moment
                        .utc(viewFormData?.updated_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewSkill;
