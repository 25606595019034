import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import { Breadcrumb, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import * as VendorService from "../../service/cashbookService";
import AddCashBookVendor from "./AddCashBookVendor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUrlsCashbook from "./AllUrlsCashbook";
import * as moment from "moment";
import AddCashBookDemand from "./AddCashbookDemand.";

const ViewCashbookReceipt = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;
  const [reverseData, setReverseData] = useState([]);
  console.log(viewFormData, "viewFormData");
  const updateCashbook = (id, data, rev) => {
    let obj = {
      amount: data?.amount,
      check_no: data?.check_no,
      receipt_date: data?.receipt_date,
      receipt_gl_id: data?.receipt_gl_id,

      data,
      status: rev,
      sap_posting_status: "false",
    };
    VendorService.updateReceipt(id, obj)
      .then((response) => {
        setReverseData(response?.data?.dataList?.result);
        toast.success("Cashbook Receipt Reversed Successfully");
        onViewClose();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  {
    console.log(viewFormData, "viewFormData");
  }
  var TodayDate = new Date();
  var myDate = new Date(viewFormData?.created_at);
  myDate.setHours(myDate.getHours() + 24);
  return (
    <div>
      <Modal
        show={viewForm}
        onHide={onViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cash Receipt Request Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row viewformaftersubmit">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Request No</label>
                <p>PICASHDE{viewFormData?.id}</p>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cashbook Id</label>
                <p>{viewFormData?.cashbook_id}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Request Date</label>
                <p> {moment(viewFormData?.created_at).format("DD-MM-YYYY")}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cash Transfer Request</label>
                <p>{viewFormData?.amount}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Created By</label>
                <p>{viewFormData?.created_by}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Receipt Gl Code</label>
                <p>{viewFormData?.receipt_gl_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Receipt Gl Desc</label>
                <p>{viewFormData?.receipt_gl_desc}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Cheque No</label>
                <p>{viewFormData?.check_no}</p>
              </div>
            </div>
            {/* <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Indentification Doc. No</label>
                <p>{viewFormData?.doc_no}</p>
              </div>
            </div> */}
            {/* <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Remarks</label>
                <p>{viewFormData?.remarks}</p>
              </div>
            </div> */}
            {/* <div class="col-md-4">
              <div class="form-group innergroup">
                <label>SAP Remarks</label>
                <p>{viewFormData?.sap_confirmation_message}</p>
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {viewFormData?.status == "reverse" || viewFormData?.status == "R" ? (
            ""
          ) : (
            <>
              {myDate > TodayDate == true ? (
                <button
                  class="btn btn-primary-inner mr-2"
                  onClick={(e) => {
                    updateCashbook(viewFormData?.id, viewFormData, "R");
                    // navigate("/cashbook-receipt");
                  }}
                >
                  <i class="far fa-times-circle"></i> Reverse
                </button>
              ) : (
                ""
              )}
            </>
          )}

          <button class="btn btn-outline-secondary" onClick={onViewClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewCashbookReceipt;
