import React, { useState, useEffect } from "react";
import * as moment from "moment";
import * as HigherEduService from "../../service/educationService";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  selectFinalUrlPermission,
  setDisplayLoader,
  selectUserData,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
// import ViewEmployee from "./ViewEmployee";
import DataTable from "./DataTable";
import { Dropdown, Modal } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HigherEduReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const [show, filtersetShow] = useState(false);
  const [requestList, setReqList] = useState([]);
  const [paginatedData, setPaginatedData] = useState(null);
  const [currentTab, setCurrentTab] = useState("pending");

  const [certificateData, setCertificateData] = useState([]);
  const [certificatePreviewList, setCertificatePreviewList] = useState([]);
  const [deleteCertificateList, setDeleteCertificateList] = useState([]);
  const [uploadCertificateModalFlag, setUploadCertificateModalFlag] = useState({
    flag: false,
    id: null,
    data: [],
  });

  const [filterdata, setfilterData] = useState({
    start_date: new Date(),
    end_date: new Date(),
    level: "",
    status: "",
  });

  const statusOption = [
    { label: "Pending", value: "PENDING" },
    { label: "Re-Submit", value: "RESUBMIT" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Approved", value: "ACCEPTED" },
  ];

  const levelOption = [
    { label: "RM", value: "RM" },
    { label: "BHR", value: "BHR" },
    {
      label: "CPO (Chief People Officer)",
      value: "CPO (Chief People Officer)",
    },
    { label: "Higher Education Council", value: "Higher Education Council" },
  ];

  let abortAllHigherEduRequestData = null;

  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    filtersetShow(false);
    getAllHigherEduRequest(1, 300, sortData.sort_by, "", true);
  };

  const amountFormatter = (number, currency) =>
    new Intl.NumberFormat(currency == "INR" ? "en-In" : "en-Us", {
      style: "currency",
      currency: currency,
    }).format(number);
  const statusColumn = (value, row) => {
    let status = value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
    if (value == "PENDING" && row.is_resubmitted) status = "Re-Submitted";
    return (
      <span
        className={
          "badge bad-status badge-" +
          (status == "Rejected"
            ? "danger"
            : status == "Approved"
            ? "success"
            : "warning")
        }
      >
        {status}
      </span>
    );
  };

  const nameFormatter = (name, id) => {
    const full_name = `${name} (${id})`;
    if (name) {
      return (
        <span
          style={{ whiteSpace: full_name.length > 25 ? "pre-wrap" : "nowrap" }}
        >
          {full_name}
        </span>
      );
    }
  };

  const tableColumn = [
    {
      datafield: "id",
      text: "Request ID",
      formatter: (value) => <span> HEP{value} </span>,
    },
    {
      datafield: "created_at",
      text: "Date",
      formatter: (value) => moment.parseZone(value).format("DD-MM-YYYY"),
    },
    {
      datafield: "",
      text: "Name",
      formatter: (value, row) =>
        nameFormatter(row?.user_full_name, row?.username),
    },
    {
      datafield: "",
      text: "Location",
      formatter: (value, row) =>
        nameFormatter(row?.personal_area_description, row?.personal_area_name),
    },
    {
      datafield: "course",
      text: "Course Name",
    },
    {
      datafield: "university",
      text: "Name of Institute",
    },
    {
      datafield: "start_date",
      text: "Start Date",
    },
    {
      datafield: "end_date",
      text: "End Date",
    },
    {
      datafield: "course_duration",
      text: "Duration",
    },
    {
      datafield: "course_fee",
      text: "Fees",
      formatter: (value, row) => amountFormatter(value, row.currency),
    },
    {
      datafield: "sanctioned_amount",
      text: "Sanctioned Amount",
      formatter: (value, row) => amountFormatter(value, row.currency),
    },
    {
      datafield: "claimed_amount",
      text: "Claim Amount",
      formatter: (value, row) => amountFormatter(value, row.currency),
    },
    {
      datafield: "balance_amount",
      text: "Balance Amount",
      formatter: (value, row) => amountFormatter(value, row.currency),
    },
    {
      datafield: "justification",
      text: "Justification",
    },
    {
      datafield: "",
      text: "Created By",
      formatter: (value, row) =>
        nameFormatter(row?.created_by_full_name, row?.created_by_username),
    },
    {
      datafield: "status",
      text: "Status",
      formatter: (value, row) => statusColumn(value, row),
    },
    {
      datafield: "approval_type",
      text: "Level",
    },
    {
      datafield: "",
      text: "Pending At",
      formatter: (value, row) =>
        nameFormatter(row?.approve, row?.approve_username),
    },
  ];

  const getAllHigherEduRequest = (
    pageNo,
    pageSizeNo,
    sort_by,
    search,
    paginate
  ) => {
    if (abortAllHigherEduRequestData) abortAllHigherEduRequestData.abort();

    dispatch(setDisplayLoader("Display"));
    setReqList([]);
    let payload = `page_no=${pageNo}&page_size=${pageSizeNo}&sort_by=${sort_by}&search=sort_by${search}&paginate=${paginate}`;

    abortAllHigherEduRequestData = new AbortController();
    HigherEduService.getAllHigherEduRequest(
      payload,
      abortAllHigherEduRequestData.signal
    )
      .then((response) => {
        abortAllHigherEduRequestData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result);
        setPaginatedData(response.data?.dataList.paginated_data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const debounce = (func, delay) => {
    let timeout = 0;
    return (...args) => {
      timeout = setTimeout(() => func(...args), delay);
      clearTimeout(timeout - 1);
    };
  };

  const getSearchApplicationRequests = (
    pageNo,
    pageSizeNo,
    search,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    let payload = `pageNo=${pageNo}&page_size=${pageSizeNo}&search=${search}&paginate=${paginate}`;
    if (currentTab != "pending") {
      payload += `&status=${currentTab.toUpperCase()}`;
    }
    abortAllHigherEduRequestData = new AbortController();
    HigherEduService.getAllHigherEduRequest(
      payload,
      abortAllHigherEduRequestData.signal
    )
      .then((response) => {
        abortAllHigherEduRequestData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getSearchApplicationRequestsWithDebounce = debounce(
    getSearchApplicationRequests,
    500
  );

  const handleSearch = (e) => {
    getSearchApplicationRequestsWithDebounce(
      currentPage,
      pageSizeNo,
      e.target.value,
      true
    );
    setSearchStr(e.target.value);
  };

  const filterApplicationRequestData = () => {
    const payload = `from_date=${moment(filterdata.start_date).format(
      "YYYY-MM-DD"
    )}&to_date=${moment(filterdata.end_date)
      .add(1, "days")
      .format("YYYY-MM-DD")}&status=${filterdata.status}&approval_type=${
      filterdata.level
    }`;

    abortAllHigherEduRequestData = new AbortController();
    HigherEduService.getAllHigherEduRequest(
      payload,
      abortAllHigherEduRequestData.signal
    )
      .then((response) => {
        abortAllHigherEduRequestData = null;
        dispatch(setDisplayLoader("Hide"));
        setReqList(response.data?.dataList.result);
        filtersetShow(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const exportAllHigherEduRequest = () => {
    dispatch(setDisplayLoader("Display"));
    HigherEduService.exportAllHigherEduRequest()
      .then((res) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Higher_Education_Report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getAllHigherEduRequest(1, 300, sortData.sort_by, "", true);
    return () => {
      if (abortAllHigherEduRequestData) abortAllHigherEduRequestData.abort();
    };
  }, [pageSizeNo]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="content-wrapper-inner content-wrapper-inner2">
              <div className="innerheadsec">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="inner-page-title">
                      Higher Education Report
                    </h4>
                  </div>
                  <div className="col-md-12">
                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input-group table-search">
                      <span className="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="inline-spacing btn-betweenspaing">
                      <Dropdown show>
                        <Dropdown.Toggle
                          variant="primary-inner dropdownbtn"
                          id="dropdown-basic"
                        >
                          <img src="images/export.png" alt="" class="mr-2" />{" "}
                          Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <button
                              type="button"
                              // disabled={travelList.length !== 0 ? false : true}
                              style={{ border: 0, background: "none" }}
                              onClick={() => exportAllHigherEduRequest()}
                            >
                              <i className="far fa-file-excel"></i>Excel
                            </button>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        onClick={filteropen}
                        className="btn btn-secondary-inner"
                      >
                        <img src="images/filter.png" alt="" className="mr-3" />
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="approval-bg no-bg border-0 purchaseinnertabs position-relative">
                <DataTable
                  column={tableColumn}
                  list={requestList}
                  option={{
                    pagination: {
                      show: true,
                      currentPaginatedData: paginatedData,
                      dataCount: 300,
                      getNextData: getAllHigherEduRequest,
                      payload: [sortData.sort_by, "", true],
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => filtersetShow(false)}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup d-flex flex-column">
            <label>
              Start Date <span className="text-danger">*</span>
            </label>
            <DatePicker
              selected={
                filterdata.start_date !== ""
                  ? new Date(filterdata.start_date)
                  : new Date()
              }
              onChange={(date) =>
                setfilterData({
                  ...filterdata,
                  start_date: date,
                })
              }
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>

          <div className="form-group innergroup d-flex flex-column">
            <label>
              End Date <span className="text-danger">*</span>
            </label>
            <DatePicker
              selected={
                filterdata.end_date !== ""
                  ? new Date(filterdata.end_date)
                  : new Date()
              }
              onChange={(date) =>
                setfilterData({
                  ...filterdata,
                  end_date: date,
                })
              }
              placeholderText="DD-MM-YYYY"
              className="form-control"
              dateFormat="dd-MM-yyyy"
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Level <span className="text-danger">*</span>
            </label>
            <Select
              options={levelOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  level: e.value,
                })
              }
            />
          </div>

          <div className="form-group innergroup">
            <label>
              Status <span className="text-danger">*</span>
            </label>
            <Select
              options={statusOption}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) =>
                setfilterData({
                  ...filterdata,
                  status: e.value,
                })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            //  onClick={() => clearfilter()}
            onClick={filterclose}
          >
            clear
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={filterApplicationRequestData}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HigherEduReport;
