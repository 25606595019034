import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Spinner,
  Pagination,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import {
  getUserListByBranch,
  getUserByReporting,
} from "../../service/userService";
import {
  addModule,
  assignModule,
  getDelegatedModules,
  getDelegateList,
  updateAssignedModules,
} from "../../service/delegateService";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-tippy";
import { getChildModuleList, getParentModuleList } from "../../service/moduleService";

function Delegate() {
  const [show, filtersetShow] = useState(false);
  const [fromerror, setformerror] = useState(false);
  const [toerror, settoerror] = useState(false);
  const [fromdateerror, setfromdateerror] = useState(false);
  const [todateerror, settodateerror] = useState(false);
  const [moduleerror, setmoduleerror] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [searchStr, setSearchStr] = useState("");
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const addnewclose = () => addnewsetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [moduleDataFilter, setModuleDataFilter] = useState({
    delegatedUser: "",
  });
  const [selectedParent, setSelectedParent] = useState([]);
  let moduleToExclude = [
    "Market Size",
    "Channel Size",
    "Market Player",
    "Major Product",
    "Crop Pattern",
    "Crop Scenario",
    "Competing Brand",
    "Competing Molecule",
    "Overall Strategy",
    "Crop Plan",
    "Sale Plan",
    "Product Strategy",
    "Detailed Sale Plan",
    "Field Work Plan",
    "Expense Plan",
    "POP Item",
  ];
  const [formData, setFormData] = useState({
    id: null,
    from: "",
    to: "",
    fromDate: "",
    toDate: "",
    active: true,
    authModule: false,
  });
  const [moduleArr, setModuleArr] = useState({
    module_id: [],
  });
  const [userFromList, setUserFromList] = useState([]);
  const [userFromcopyList, setUserFromcopyList] = useState([]);
  const [userToList, setUserToList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [delegatedModules, setDelegatedModules] = useState([]);
  const [parentModules, setParentModules] = useState([]);
  const [childModules, setChildModules] = useState([]);
  const [delegateList, setDelegateList] = useState([]);
  const [edit, isEdit] = useState(false);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    getAssignedDelegates("");
    getDropdownData();
    getModules();
  }, [userData]);

  useEffect(() => {
    if (selectedParent?.length > 0) {
      let modIds = selectedParent?.map((each) => each?.value).toString()
      getChildModuleList(modIds).then((response) => {
        let childMod = response?.data?.dataList?.result?.map((each) => {
          return {
            label: each?.name,
            value: each?.id
          }
        });
        setChildModules(childMod);
      })
    } else {
      setChildModules([])
    }
  }, [selectedParent])

  const getAssignedDelegates = async (search) => {
    // let all = userData.is_superuser ? true : false;
    let all = true;
    let delegated_to =
      moduleDataFilter.delegatedUser !== ""
        ? moduleDataFilter.delegatedUser.value
        : 0;
    let queryParam = `search=${search}&all=${all}&paginate=${true}&page_no=${currentPage}&page_size=${pageSizeNo}&delegated_to=${delegated_to}`;
    await getDelegateList(queryParam).then((response) => {
      if (response?.status === 200) {
        setDelegateList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  useEffect(() => {
    getAssignedDelegates(searchStr);
  }, [pageSizeNo]);

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getAssignedDelegates(e.target.value);
      setSearchStr(e.target.value);
    }
  };

  const getModules = async () => {
    await getParentModuleList(`paginate=${false}`).then((response) => {
      if (response?.status === 200) {
        let modules = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj?.name,
            value: obj?.id
          }
        });
        setParentModules(modules);
      }
    })
    await getDelegatedModules()
      .then((response) => {
        if (response?.status === 200) {
          let modules = response?.data?.dataList?.result.filter((obj) => {
            if (moduleToExclude.includes(obj?.name) === false) {
              return true;
            }
          });
          setDelegatedModules(modules);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsersByReportingId = async (id) => {
    await getUserByReporting(id, true, "id", false)
      .then((response) => {
        if (response?.status === 200) {
          let userDropdown = response?.data?.dataList?.result?.map((obj) => {
            return {
              label: `${obj.first_name} ${obj.last_name} (${obj?.username})`,
              value: obj.id,
            };
          });
          setUserToList(userDropdown);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDropdownData = async () => {
    await getUserListByBranch(1, 100, "id", false, "")
      .then((response) => {
        if (response?.status === 200) {
          let userDropdown = response?.data?.dataList?.result?.map((obj) => {
            return {
              label: `${obj.first_name} ${obj.last_name} ${obj.username == null ? "" : " (" + obj.username + ")"
                }`,
              value: obj.id,
            };
          });
          setUserFromList(userDropdown);
          setUserFromcopyList(userDropdown);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // if (userData?.is_superuser === false) {
    //   setFormData((prev) => ({
    //     ...prev,
    //     from: {
    //       label: userData.full_name,
    //       value: userData.id,
    //     },
    //   }));
    //   await getUserByReporting(userData.id, true, "id", false)
    //     .then((response) => {
    //       if (response?.status === 200) {
    //         let userDropdown = response?.data?.dataList?.result?.map((obj) => {
    //           return {
    //             label: `${obj.first_name} ${obj.last_name} (${obj?.username})`,
    //             value: obj.id,
    //           };
    //         });
    //         setUserToList(userDropdown);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
  };

  useEffect(() => {
    getAssignedDelegates("");
  }, [currentPage]);

  const handleChange = (event, name) => {
    if (typeof name === "object" && name !== undefined) {
      if (!moduleArr.module_id.includes(String(name.id))) {
        setModuleArr((prev) => ({
          ...prev,
          module_id: [...prev.module_id, String(name.id)],
        }));
        setmoduleerror(false);
      } else {
        setModuleArr((prev) => ({
          ...prev,
          module_id: prev.module_id.filter((id) => id != String(name.id)),
        }));
        setmoduleerror(false);
      }
      let curObj = JSON.parse(JSON.stringify(delegatedModules));
      curObj.map((obj) => {
        if (obj.id === name.id) {
          obj.active =
            obj.active === "Y" && obj.active !== undefined ? "N" : "Y";
        }
      });
      setDelegatedModules(curObj);
    } else if (name === 'auth') {
      setModuleArr((prev) => ({
        ...prev,
        module_id: event?.length > 0 ? event?.map((each) => each?.value) : [],
      }));
      setmoduleerror(false);
    } else {
      const type = name !== undefined ? name : event.target.name;
      switch (type) {
        case "fromUser":
          let data = userFromList
            .filter((obj, i) => {
              if (obj.label == event.label) {
                return false;
              }
              return true;
            })
            .map((x) => x);
          setUserFromcopyList(data);
          setFormData((prev) => ({
            ...prev,
            from: event,
            to: "",
          }));
          // getUsersByReportingId(event.value);
          setformerror(false);
          break;
        case "toUser":
          setFormData((prev) => ({
            ...prev,
            to: event,
          }));
          settoerror(false);
          break;
        case "fromDate":
          setFormData((prev) => ({
            ...prev,
            fromDate: event.target.value,
          }));
          setfromdateerror(false);
          break;
        case "toDate":
          setFormData((prev) => ({
            ...prev,
            toDate: event.target.value,
          }));
          settodateerror(false);
          break;
        default:
          break;
      }
    }
  };

  const assignDelegate = async () => {
    console.log(moduleArr);
    if (!edit) {
      let recordFound = delegateList?.filter((obj) => {
        if (
          obj?.to_user_id === formData?.to?.value &&
          obj?.from_user_id === formData?.from?.value &&
          moment(obj?.end_date).isAfter(moment(formData?.to))
        ) {
          return true;
        }
      });
      if (recordFound?.length > 0) {
        toast.error(
          `Entry for ${formData?.to?.label} already exist. Kindly update.`
        );
      } else {
        let obj = {
          from_user_id: formData.from.value,
          to_user_id: formData.to.value,
          start_date: formData.fromDate,
          end_date: formData.toDate,
          active: formData.active,
          mod_type: formData?.authModule ? "module" : "approvals"
        };
        if (
          obj.from_user_id !== undefined &&
          obj.to_user_id !== undefined &&
          obj.start_date !== "" &&
          obj.end_date !== "" &&
          moduleArr.module_id.length > 0
        ) {
          console.log(obj, moduleArr);
          setIsLoading(true);
          await assignModule(obj)
            .then((response) => {
              if (response?.status === 200) {
                setIsLoading(false);
                resetFormData();
                addDelegatedModules(response?.data?.dataList?.id);
                getAssignedDelegates("");
              }
            })
            .catch((error) => {
              setIsLoading(false);
              toast.error(error.response.data.message);
              console.log(error);
            });
        } else {
          if (obj.from_user_id == undefined) {
            setformerror(true);
          }
          if (obj.to_user_id == undefined) {
            settoerror(true);
          }
          if (obj.start_date == "") {
            setfromdateerror(true);
          }
          if (obj.end_date == "") {
            settodateerror(true);
          }
          if (moduleArr.module_id.length == 0) {
            setmoduleerror(true);
          }
        }
      }
    } else {
      let obj = {
        id: formData.id,
        from_user_id: formData.from.value,
        to_user_id: formData.to.value,
        start_date: formData.fromDate,
        end_date: formData.toDate,
        moduleList: delegatedModules,
        active: formData.active,
      };
      if (
        obj.from_user_id !== undefined &&
        obj.to_user_id !== undefined &&
        obj.start_date !== "" &&
        obj.end_date !== "" &&
        obj.moduleList.filter((obj) => {
          if (obj?.active == "Y") {
            return true;
          }
        }).length > 0
      ) {
        setIsLoading(true);

        await updateAssignedModules(obj.id, obj)
          .then((response) => {
            if (response?.status === 200) {
              setIsLoading(false);
              getAssignedDelegates("");
              isEdit(false);
              resetFormData();
              toast.success(response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.message);
            console.log("error", error);
          });
      } else {
        if (obj.from_user_id == undefined) {
          setformerror(true);
        }
        if (obj.to_user_id == undefined) {
          settoerror(true);
        }
        if (obj.start_date == "") {
          setfromdateerror(true);
        }
        if (obj.end_date == "") {
          settodateerror(true);
        }
        if (
          obj.moduleList.filter((obj) => {
            if (obj?.active == "Y") {
              return true;
            }
          }).length === 0
        ) {
          setmoduleerror(true);
        }
      }
    }
  };

  const addDelegatedModules = async (id) => {
    let obj = {
      delegate_id: id,
      module_id: moduleArr.module_id,
      revoked: false,
    };
    setIsLoading(true);
    await addModule(obj)
      .then((response) => {
        if (response?.status === 200) {
          setIsLoading(false);
          getAssignedDelegates("");
          resetFormData();
          getDropdownData();
          if (response?.data?.dataList?.not_delegated?.length === 0) {
            toast.success(response?.data?.message);
          } else if (
            moduleArr.module_id.length ===
            response?.data?.dataList?.not_delegated?.length
          ) {
            toast.error("Modules already assigned to some user.");
          } else {
            toast.success(
              `Modules other than ${response?.data?.dataList?.not_delegated.toString()} assigned to user successfuly.`
            );
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  const resetFormData = () => {
    setFormData({
      from: "",
      to: "",
      fromDate: "",
      toDate: "",
      active: true,
    });
    setModuleArr({
      module_id: [],
    });
    addnewclose();
    let modules = delegatedModules.map((obj) => {
      obj.active = "N";
      return obj;
    });
    setDelegatedModules(modules);
  };

  const updateRecord = (obj) => {
    isEdit(true);
    setFormData((prev) => ({
      ...prev,
      id: obj.id,
      from: {
        label: obj.from_user_name,
        value: obj.from_user_id,
      },
      to: {
        label: obj.to_user_name,
        value: obj.to_user_id,
      },
      fromDate: obj.start_date,
      toDate: obj.end_date,
      active: obj.active,
    }));
    let modules = obj?.assigned_modules.map((obj) => obj.module_id);
    let total_modules = delegatedModules.map((obj) => {
      if (modules.includes(obj.id)) {
        obj.active = "Y";
        return obj;
      } else {
        obj.active = "N";
        return obj;
      }
    });
    setDelegatedModules(total_modules);
    addnewopen();
  };

  const getFilteredData = async () => {
    getAssignedDelegates();
  };

  const resetFilter = () => {
    setModuleDataFilter({
      delegatedUser: "",
    });
  };

  const selectAllTypes = (e) => {
    let modules = JSON.parse(JSON.stringify(delegatedModules));
    let selectedData = modules
      .filter((obj) => {
        if (obj?.name !== "" && obj?.name !== null) {
          return true;
        }
      })
      .map((obj) => {
        if (e.target.checked) {
          obj.active = "Y";
          return obj;
        } else {
          obj.active = "N";
          return obj;
        }
      });
    setModuleArr((prev) => ({
      ...prev,
      module_id: selectedData.map((obj) => {
        return obj?.id;
      }),
    }));
    setDelegatedModules(selectedData);
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getAssignedDelegates("");
    }
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div className="row">
            <div class="col-md-12">
              <h4 class="inner-page-title">Delegate Setting</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Delegate</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div class="col-md-12">
              <div class="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on <strong>User, Delegate To</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="searchbar"
                    placeholder="Search on User, Delegate To"
                    onChange={(e) => {
                      handleSearch(e);
                      setCurrentPage(1);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                {/* <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <i class="far fa-file-excel"></i>Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <button onClick={filteropen} class="btn btn-secondary-inner">
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button> */}
                <button class="btn btn-primary-inner" onClick={addnewopen}>
                  <img src="images/upload.png" alt="" class="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>User</th>
                <th>Delegate To</th>
                <th>From Date</th>
                <th>To date</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {delegateList?.map((obj, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <Tooltip title={obj?.from_user_name} position="bottom">
                        <span className="fw-bold">
                          {obj.from_user_name}
                        </span>
                      </Tooltip> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.from_user_desig}
                      </span> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.from_user_dept}
                      </span> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.from_user_location}
                      </span>
                    </td>
                    <td>
                      <Tooltip title={obj?.to_user_name} position="bottom">
                        <span className="fw-bold">
                          {obj.to_user_name}
                        </span>
                      </Tooltip> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.to_user_desig}
                      </span> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.to_user_dept}
                      </span> <br />
                      <span style={{ fontSize: "13px" }}>
                        {obj?.to_user_location}
                      </span>
                    </td>
                    <td>{moment(obj.start_date).format("DD-MM-YYYY")}</td>
                    <td>{moment(obj.end_date).format("DD-MM-YYYY")}</td>
                    <td>
                      {moment
                        .utc(obj.created_at)
                        .format("DD-MM-YYYY, h:mm:ss A")}
                    </td>
                    <td>
                      {moment
                        .utc(obj.updated_at)
                        .format("DD-MM-YYYY, h:mm:ss A")}
                    </td>
                    <td>
                      {obj?.active ? (
                        <span className="badge bad-status badge-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge bad-status badge-danger">
                          InActive
                        </span>
                      )}
                    </td>
                    <td>
                      <button
                        class="btn btn-blanktd text-primary"
                        onClick={() => updateRecord(obj)}
                      >
                        <i class="far fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {delegateList?.length == 0 ? (
          <tr>
            <td colSpan={12}> No Record Found</td>
          </tr>
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              {/* <Pagination>
                <Pagination.Prev className="previpag" />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Item active>{2}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{9}</Pagination.Item>
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Next className="previpag" />
              </Pagination> */}
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Select Delegate User<span class="text-danger">*</span>
            </label>
            {userData.is_superuser === true ? (
              <Select
                options={userFromList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setModuleDataFilter((prev) => ({
                    ...prev,
                    delegatedUser: e,
                  }));
                }}
                value={moduleDataFilter.delegatedUser}
              />
            ) : (
              <Select
                options={userToList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setModuleDataFilter((prev) => ({
                    ...prev,
                    delegatedUser: e,
                  }));
                }}
                value={moduleDataFilter.delegatedUser}
              />
            )}
          </div>

          {/* <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>

          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div> */}

          {/* <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Active</option>
              <option>Expired</option>
            </select>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={() => resetFilter()}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={getFilteredData}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addnewshow}
        onHide={() => {
          addnewclose();
          resetFormData();
          isEdit(false);
          setformerror(false);
          settoerror(false);
          setfromdateerror(false);
          settodateerror(false);
          setmoduleerror(false);
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delegate Access Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      From<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={userFromList}
                      value={formData.from}
                      name="fromUser"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                    />
                    {fromerror && (
                      <div className="small text-danger">
                        {"*Please add From"}
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      To<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={userFromcopyList}
                      value={formData.to}
                      name="toUser"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(event, action) => {
                        handleChange(event, action.name);
                      }}
                    />
                    {toerror && (
                      <div className="small text-danger">
                        {"*Please add To"}
                      </div>
                    )}
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Status<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={formData.active === true}
                        onChange={() => {
                          setFormData((prev) => ({
                            ...prev,
                            active: true,
                          }));
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={formData.active === false}
                        onChange={() => {
                          setFormData((prev) => ({
                            ...prev,
                            active: false,
                          }));
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      From Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="fromDate"
                      max={moment(formData.toDate).format("YYYY-MM-DD")}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      value={formData.fromDate}
                    />
                    {fromdateerror && (
                      <div className="small text-danger">
                        {"*Please add from date"}
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      To Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      name="toDate"
                      min={moment(
                        formData?.fromDate !== ""
                          ? formData?.fromDate
                          : new Date()
                      ).format("YYYY-MM-DD")}
                      // min={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      value={formData.toDate}
                    />
                    {todateerror && (
                      <div className="small text-danger">
                        {"*Please add to date"}
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label className="d-block mb-2">
                      Switch Modules<span class="text-danger">*</span>
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      Modules
                      <input
                        type="radio"
                        name="modulesSelect"
                        checked={formData.authModule === true}
                        onChange={() => {
                          setFormData((prev) => ({
                            ...prev,
                            authModule: true,
                          }));
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="logCheck d-inline-block">
                      Approval Sub Types
                      <input
                        type="radio"
                        name="modulesSelect"
                        checked={formData.authModule === false}
                        onChange={() => {
                          setFormData((prev) => ({
                            ...prev,
                            authModule: false,
                          }));
                        }}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="perhead">
                <h4>Modules</h4>
                <p>Select Desired Module</p>
              </div>

              {formData?.authModule === false ? (

                <div class="table-responsive">
                  <table class="table table-bordered permission-box">
                    <tr>
                      <td width={300}>
                        <h4>Administrator Access</h4>
                      </td>
                      <td>
                        <label class="logCheck d-inline-block">
                          Select All
                          <input
                            type="checkbox"
                            name="permission"
                            onChange={(e) => selectAllTypes(e)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    {delegatedModules
                      .filter((obj) => {
                        if (obj?.name !== "" && obj?.name !== null) {
                          return true;
                        }
                      })
                      .map((obj, index) => {
                        return (
                          <tr key={index + 1}>
                            <td>
                              <h5>
                                <i class="far fa-arrow-alt-circle-right"></i>
                                {obj?.name}
                              </h5>
                            </td>
                            <td>
                              <label class="logCheck d-inline-block">
                                <input
                                  type="checkbox"
                                  name="permission"
                                  checked={obj?.active === "Y"}
                                  onChange={(event) => handleChange(event, obj)}
                                />
                                <span class="checkmark"></span>
                              </label>
                            </td>
                          </tr>
                        );
                      })}
                    {/* <tr>
                    <td>
                      <h5>
                        <i class="far fa-arrow-alt-circle-right"></i> Me@PI
                      </h5>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Attendance &
                        Holiday
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Shift Changes
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Travel & Tour
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Travel & Other
                        Expense
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Reimbursement
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5>
                        <i class="far fa-arrow-alt-circle-right"></i> CMS
                      </h5>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> News
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>
                        <i class="fas fa-chevron-right"></i> Notice
                      </h6>
                    </td>
                    <td>
                      <label class="logCheck d-inline-block">
                        <input type="checkbox" name="permission" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr> */}
                  </table>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      isMulti
                      options={parentModules}
                      className="basic-multi-select"
                      placeholder={<div>Select Parent</div>}
                      classNamePrefix="select"
                      onChange={(e) => {
                        setSelectedParent(e)
                      }}
                      value={selectedParent}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      isMulti
                      options={childModules}
                      placeholder={<div>Select Child</div>}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        handleChange(e, 'auth')
                      }}
                    />
                  </div>
                </div>
              )}
              {moduleerror && (
                <div className="small text-danger">
                  {"*Please select modules"}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  addnewclose();
                  resetFormData();
                  setformerror(false);
                  settoerror(false);
                  setfromdateerror(false);
                  settodateerror(false);
                  setmoduleerror(false);
                  isEdit(false);
                }}
              >
                Cancel
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={assignDelegate}
              >
                Apply
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Delegate;
