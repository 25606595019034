import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "react-tippy";
import { getTravelDataById, updateStatus } from "../../service/travelService";
import * as DomesticService from "../../service/domestic";

function ApproveTravelModules(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState(null);
  const [error, seterror] = useState(false);
  const [objectValue, setObjectValue] = useState();
  const [remarksDataList, setRemarksDataList] = useState();
  const [status, setStatus] = useState({
    status: "",
    remark: "",
  });

  const getTravelDataByIds = async (travel_id) => {
    let queryParam = {
      travel_id: travel_id,
    };
    await getTravelDataById("travel_id=" + travel_id).then((response) => {
      setData(response?.data?.dataList?.result[0]);
    });
  };

  const getRemarksValue = (travelId) => {
    DomesticService.getRemarksValue(travelId)
      .then((response) => {
        setRemarksDataList(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let jsonObj = {};
    Data?.approver_remark_data?.map((value, index) => {
      jsonObj[value.approver_type] = value;
    });
    setObjectValue(jsonObj);
  }, [Data]);

  const submitAction = (id, stat) => {
    let temp = [];

    let data = status;
    data.status = stat;
    data.id = id;
    temp.push(data);
    let obj = { approval_data: temp };

    changeReceiptRemarks(obj);
  };

  const changeReceiptRemarks = (data) => {
    if (
      data.approval_data[0].remark == "" ||
      data.approval_data[0].remark == null
    ) {
      seterror(true);
    } else {
      setIsLoading(true);
      updateStatus(data)
        .then((response) => {
          // getTravelDataById("", 1, 10, "id", true, id);
          setStatus({
            status: "",
            remark: "",
          });
          setIsLoading(false);
          if (data.approval_data[0].status == "A") {
            toast.success("Approved Successfully.");
          } else {
            toast.success("Rejected Successfully.");
          }
          if (
            location?.state?.currentsubTypename == "Travel Intimation Exception"
          ) {
            if (location?.state?.expCount > 1) {
              window.history.go(-1);
            } else if (location?.state?.nonExpCount > 0) {
              navigate(`/common-approve/${location?.state?.subId}`, {
                state: {
                  subtypeid: location?.state?.subId,
                  subTypeName: "Travel Intimation",
                },
              });
            } else {
              navigate("/dashboard");
            }
          } else {
            if (location?.state?.nonExpCount > 1) {
              window.history.go(-1);
            } else if (location?.state?.expCount > 0) {
              navigate(`/common-approve/${location?.state?.subId}`, {
                state: {
                  subtypeid: location?.state?.subId,
                  subTypeName: "Travel Intimation Exception",
                },
              });
            } else {
              navigate("/dashboard");
            }
          }
          return false;
        })
        .catch((error) => {
          toast.success(error?.response?.data?.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  useEffect(() => {
    console.log("location = ", location?.state);
    getTravelDataByIds(id);
  }, []);

  useEffect(() => {
    if (Data !== undefined) {
      getRemarksValue(Data?.id);
    }
  }, [Data]);

  return (
    <>
      <ToastContainer></ToastContainer>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              {/* <Link>
                {location?.state?.type === "Demand"
                  ? "Cash Demand"
                  : location?.state?.type === "Expense"
                  ? "Cash Expense"
                  : location?.state?.type}
              </Link> */}
              <Link>{location?.state?.type}</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject {location?.state?.type} -{" "}
                    {Data?.travel_intimation_number}
                    {/* {location?.state?.type === "Demand"
                      ? "Cash Demand"
                      : location?.state?.type === "Expense"
                      ? "Cash Expense"
                      : location?.state?.type} */}
                  </h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Requester</label>
                      <p>
                        {Data?.travel_detail_data[0]?.user_name} ({Data?.emp_id}
                        )
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Approver</label>
                      <p>
                        {Data?.travel_detail_data[0]?.approver_name} (
                        {Data?.travel_detail_data[0].approver_code})
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Travel Type</label>
                      <p>
                        {Data?.request_type == "On Duty"
                          ? "In Station"
                          : Data?.request_type}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label> Req. Advance Amount</label>
                      <p>
                        {Data?.advance_amount == "" ||
                        Data?.advance_amount == null
                          ? 0
                          : Data?.advance_amount}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label> Approve Advance Amount</label>
                      <p>{Data?.appr_advance_amount}</p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Requester At</label>
                      <p>
                        {moment(Data?.created_at)
                          // .utc()
                          .format("DD-MM-YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Reason For Cancellation</label>
                      <p>{Data?.cancel_reason}</p>
                    </div>
                  </div>
                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Departure Location </label>
                      <p>
                        {Data?.request_type === "On Duty"
                          ? Data?.travel_detail_data[0]?.from_city_input +
                            "-" +
                            Data?.travel_detail_data[0]?.from_city_category
                          : Data?.travel_detail_data[0]?.from_city_name +
                            "-" +
                            Data?.travel_detail_data[0]?.from_city_category}
                      </p>
                      <br></br>
                      <p
                        style={{
                          fontSize: "11px",
                          marginTop: "-44px",
                          borderBottom: "0",
                        }}
                      >
                        {moment(Data?.travel_detail_data[0]?.from_date).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        {Data?.travel_detail_data[0]?.from_time}
                      </p>
                    </div>
                  </div> */}
                  <div style={{ overflow: "auto", width: "100%" }}>
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          {/* {Data?.request_type === "International" && (
                          <>
                            <th>Continent</th>
                            <th>Country</th>
                          </>
                        )} */}
                          <th>Departure Location</th>
                          <th>Reaching Location</th>
                          <th>Purpose</th>
                          <th>Mode</th>
                          <th>Stay</th>
                          <th>
                            Colleague <br />
                            Empcode
                          </th>
                          <th>Exception</th>
                          <th>Reason</th>
                          <th>Additional Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data?.travel_detail_data?.map((data, index) => (
                          <>
                            <tr>
                              {/* {Data?.request_type === "International" && (
                              <>
                                <td class="text-dark fw-bold f-14">
                                  {data?.from_continent_name}
                                </td>
                                <td class="text-dark fw-bold f-14">
                                  {data?.from_country_name}
                                </td>
                              </>
                            )} */}
                              <td>
                                <div class="text-dark fw-bold f-14">
                                  {Data?.request_type == "International" &&
                                    `${data?.from_continent_name} |
                                ${data?.from_country_name}|`}
                                  {data?.from_city_name === "Other"
                                    ? data?.from_city_input
                                    : `${data?.from_city_name}
                                - ${data?.from_city_category}`}
                                </div>{" "}
                                {moment(data?.from_date).format("DD-MM-YYYY")}
                                &nbsp;{" "}
                                {moment(data?.from_time, "hh:mm ").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td>
                                <div class="text-dark fw-bold f-14">
                                  {Data?.request_type == "International" &&
                                    `${data?.to_continent_name} |
                                ${data?.to_country_name}|`}
                                  {data?.to_city_name === "Other"
                                    ? data?.to_city_input
                                    : ` ${data?.to_city_name}
                                - ${data?.to_city_category}`}
                                </div>
                                {moment(data?.to_date).format("DD-MM-YYYY")}
                                &nbsp;
                                {moment(data?.to_time, "hh:mm ").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td> {data?.purpose}</td>
                              <td>
                                {data?.mode_id == null &&
                                Data?.request_type == "On Duty"
                                  ? "-"
                                  : `${
                                      data?.modee_name == undefined
                                        ? data?.mode_name
                                        : data?.modee_name
                                    }`}
                                {(data?.mode_name == "Air" ||
                                  data?.modee_name == "Air") &&
                                  data?.travel_class !== null &&
                                  data?.travel_class !== "" &&
                                  `(${data?.travel_class})`}
                              </td>
                              <td> {data?.stay_needed ? "Yes" : "No"}</td>
                              <td
                                style={{
                                  maxWidth: "40px",
                                }}
                              >
                                {data?.colleague_user_code}
                              </td>
                              <td
                                style={{
                                  maxWidth: "40px",
                                }}
                              >
                                {" "}
                                {data?.reason_for_exception_name !== null ? (
                                  <i
                                    class="fa fa-flag"
                                    aria-hidden="true"
                                    style={{ color: "red" }}
                                  ></i>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td
                                style={{
                                  maxWidth: "40px",
                                }}
                              >
                                <Tooltip
                                  title={
                                    data?.reason_for_exception_name != null
                                      ? data.reason_for_exception_name
                                      : data.reason
                                  }
                                  position="left"
                                >
                                  {data?.reason_for_exception_name != null
                                    ? data?.reason_for_exception_name
                                    : data?.reason}
                                </Tooltip>
                              </td>
                              {/* <td>{data?.hq ? "Yes" : "No"}</td> */}

                              <td
                                style={{
                                  maxWidth: "130px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Tooltip
                                  title={data?.additional_information}
                                  position="left"
                                >
                                  {data?.additional_information}
                                </Tooltip>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        {/* <th>Approver Type</th> */}
                        <th>Approver Name</th>
                        <th>Designation</th>
                        <th>Approved At</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {remarksDataList?.length ? (
                        remarksDataList?.map((data, index) => (
                          <tr>
                            <td>{data?.approver_user_name}</td>
                            <td>
                              {data?.approver_position === null
                                ? ""
                                : data?.approver_position}
                            </td>
                            <td>
                              {data?.status !== null &&
                                moment(data?.created_at)
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>
                              {data?.status === "R" ? "Rejected" : "Approved"}
                            </td>
                            <td style={{ overflow: "hidden" }}>
                              <Tooltip title={data?.remark} position="left">
                                {data?.remark}
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Destination Location </label>
                      <p>
                        {Data?.request_type === "On Duty"
                          ? Data?.travel_detail_data[0]?.to_city_input +
                            "-" +
                            Data?.travel_detail_data[0]?.to_city_category
                          : Data?.travel_detail_data[0]?.to_city_name +
                            "-" +
                            Data?.travel_detail_data[0]?.to_city_category}
                      </p>
                      <br></br>
                      <p
                        style={{
                          fontSize: "11px",
                          marginTop: "-44px",
                          borderBottom: "0",
                        }}
                      >
                        {moment(Data?.travel_detail_data[1]?.to_date).format(
                          "DD-MM-YYYY"
                        )}{" "}
                      </p>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Purpose</label>
                      <p>{Data?.travel_detail_data[0]?.purpose}</p>
                    </div>
                  </div> */}
                </>
              </div>
            </div>
            {Data?.status == "P" ||
            Data?.status === null ||
            Data?.status == "CA" ? (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Remarks<span class="text-danger">*</span>
                    </label>
                    <textarea
                      style={{ width: "100%", height: "auto" }}
                      rows="2"
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remark: e.target.value,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give comment{error}
                      </div>
                    }
                  </div>
                </div>
                {console.log(location?.state?.type, "location")}
                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      if (
                        location?.state?.currentsubTypename ==
                        "Travel Intimation Exception"
                      ) {
                        navigate(`/common-approve/${location?.state?.subId}`, {
                          state: {
                            subtypeid: location?.state?.subId,
                            subTypeName: "Travel Intimation Exception ",
                          },
                        });
                      } else {
                        navigate(`/common-approve/${location?.state?.subId}`, {
                          state: {
                            subtypeid: location?.state?.subId,
                            subTypeName: "Travel Intimation",
                          },
                        });
                      }
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Back
                  </button>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          submitAction(
                            Data?.id,
                            Data?.status == "CA" ? "A" : "R"
                          );
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(
                            Data?.id,
                            Data?.status == "CA" ? "C" : "A"
                          );
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div class="col-md-12 pb-3">
                <button
                  class="btn btn-outline-secondary mr-2"
                  onClick={() => {
                    if (
                      location?.state?.currentsubTypename ==
                      "Travel Intimation Exception"
                    ) {
                      navigate(`/common-approve/${location?.state?.subId}`, {
                        state: {
                          subtypeid: location?.state?.subId,
                          subTypeName: "Travel Intimation Exception ",
                        },
                      });
                    } else {
                      navigate(`/common-approve/${location?.state?.subId}`, {
                        state: {
                          subtypeid: location?.state?.subId,
                          subTypeName: "Travel Intimation",
                        },
                      });
                    }
                  }}
                >
                  <i class="fas fa-arrow-circle-left"></i> Back
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveTravelModules;
