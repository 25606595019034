import React from "react";
import { useState, useEffect } from "react";
import * as TravelListingAPI from "../../service/travelService";
import OnDuty from "./OnDuty";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData } from "../redux/piDataStore";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { injectStyle } from "react-toastify/dist/inject-style";
import BackDateListing from "./BackDateListing";
if (typeof window !== "undefined") {
  injectStyle();
}
const ApplyOtherAdvance = (props) => {
  const { state, flag } = useLocation();
  const { viewForm, viewFormDataNew, edit, idUpdate, onViewClose } = props;
  const dispatch = useDispatch();
  const { checkActiveTab } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector(selectUserData);
  const [cityData, setCityData] = useState([]);
  const [cityDataTo, setCityDataTo] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [cityValueTo, setCityValueTo] = useState();
  const [cityValueNew, setCityValueNew] = useState();
  const [cityValueToNew, setCityValueToNew] = useState();
  const [purposeValue, setPurposeValue] = useState([]);
  const [purposeValueNew, setPurposeValueNew] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [chkNum, setChkNum] = useState(false);
  //   const [backDateData, setBackDateData] = useState();
  console.log(location.state, "om");
  let condition = "false";
  const todayDate = new Date();
  const FromDate = moment(todayDate).format("YYYY-MM-DD");
  const [formData, setFormData] = useState({
    reason_advance_amount: "",
    advance_amount: "",
    advance_amount_date: "",
    // advance_amount_date: FromDate,
  });

  const [formValidation, setFormValidation] = useState({
    reason_advance_amount: "",
    advance_amount: "",
    advance_amount_date: "",
  });
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (formData.reason_advance_amount === "") {
      isError = true;
      formerr.reason_advance_amount = "*Reason For Advance is required ";
      setFormValidation(formerr);
    }
    if (formData.advance_amount == 0 || formData.advance_amount === "") {
      isError = true;
      formerr.advance_amount = "*Advance Amount is required";
      setFormValidation(formerr);
    } else if (formData.advance_amount > 200000) {
      isError = true;
      formerr.advance_amount = "*Advance Amount cannot be greater then 2 Lacs";
      setFormValidation(formerr);
    }
    if (formData.advance_amount_date === "") {
      isError = true;
      formerr.advance_amount_date = "*Advance Settlement Date is required";
      setFormValidation(formerr);
    }

    return isError;
  };
  const checkValue = (e) => {
    console.log(e, e > 20, formData.advance_amount, "sonnn");
    if (formData.advance_amount + e < 20) {
      setFormData({
        ...formData,
        advance_amount: e,
      });
      setFormValidation({
        ...formValidation,
        advance_amount: "",
      });
    } else {
      setFormValidation({
        ...formValidation,
        advance_amount: "Cannot be greater then 2000",
      });
      setFormData({
        ...formData,
        advance_amount: 0,
      });
    }
  };

  //   const getBackDateList = async (data) => {
  //     await TravelListingAPI.getBackDateList(data)
  //       .then((response) => {
  //         console.log(response, "kanu");
  //         setBackDateData(response?.dataList?.result);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
  //   console.log(backDateData, "backDateData");

  // const saveTravelBackDateInfo = async (formData) => {
  //   let dd = formData;
  //   // dd.check_type = "travel";
  //   // dd.user_id = userData?.id;
  //   await TravelListingAPI.saveTravelBackDateInfo(dd)
  //     .then((response) => {
  //       condition = "false";
  //       if (response?.data.code == 200) {
  //         setFormData({
  //           reason_advance_amount: "",
  //           advance_amount: "",
  //           advance_amount_date: "",
  //         });
  //         // navigate("/travel-listing");
  //         toast.success("Other reason_advance_amount Created Successfully");
  //         //   getBackDateList(userData?.id);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message);
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const saveHotelExpenseData = (data) => {
    TravelListingAPI.saveTravelAdvDateInfo(data)
      .then((response) => {
        if (response?.data.code == 200) {
          // resetState();
          navigate("/otheradvance/myotheradvance");
          toast.success("Other Advance Applied Successfully");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateHotelExpenseData = (id, data) => {
    console.log(state.id, data, "saj");
    let dd = {
      reason_advance_amount: data?.reason_advance_amount,
      advance_amount: data?.advance_amount,
      advance_amount_date: data?.advance_amount_date,
    };
    TravelListingAPI.updateAdvDetails(id, dd)
      .then((response) => {
        if (response?.data.code == 200) {
          // resetState();
          navigate("/otheradvance/myotheradvance");
          toast.success("Other Advance Updated Successfully");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // const isDataExists = () => {
  //   let data = {};
  //   data["user_id"] = userData?.id;
  //   data["from_date"] = formData?.from_date;
  //   data["to_date"] = formData?.to_date;

  //   if (condition == "false") {
  //     condition = "true";
  //     TravelListingAPI.isDataExists(data)
  //       .then((res) => {
  //         setIsExist(res?.data?.dataList?.is_exist);
  //         if (res?.data?.dataList?.is_exist === false) {
  //           handleValidateForm();
  //         }
  //       })
  //       .catch((err) => console.log("Error=>", err));
  //   }
  // };
  const handleValidateForm = () => {
    if (handleValidate()) return false;

    saveHotelExpenseData(formData);
  };
  const handleValidateFormUp = () => {
    if (handleValidate()) return false;
    console.log(state?.id, formData, "pppppj");

    updateHotelExpenseData(formData?.id, formData);
  };

  useEffect(() => {
    if (state?.data) setFormData(state?.data);
  }, [state]);

  console.log(state?.flag, "himykanu");

  return (
    <>
      {/* <OnDuty data={travelInt} /> */}
      <div class="px-4 pb-2">
        <div class="row align-items-center">
          <div class="col-md-6 mt-5" style={{ float: "left" }}>
            {" "}
            <h5 class="inner-page-title">Apply Other Advance</h5>
          </div>
          {/* <div class="col-md-6 text-sm-right">
            <button
              class="btn btnblanktd text-primary"
              onClick={addMoreTravelInfo}
            >
              <i class="far fa-plus-square mr-2"></i> Add More
            </button>
          </div> */}
        </div>
        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Reason For Other Advance<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="text"
                  class="form-control"
                  name="reason_advance_amount"
                  maxLength={100}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reason_advance_amount: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      reason_advance_amount: "",
                    });
                  }}
                  value={formData?.reason_advance_amount}
                />

                <div className="small text-danger">
                  {formValidation.reason_advance_amount}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Advance Amount(Rs.)<span class="text-danger">*</span>
                </label>

                <input
                  style={{ fontSize: "13px" }}
                  type="number"
                  pattern="[0-9]{2}"
                  min="0"
                  max="23"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  class="form-control"
                  name="advance_amount"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advance_amount:
                        e.target.value > 200000 ? 200000 : e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      advance_amount: "",
                    });
                  }}
                  value={formData?.advance_amount}
                />
                <div className="small text-danger">
                  {formValidation.advance_amount}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>
                  Projected Date Of Advance Settelment
                  <span class="text-danger">*</span>
                </label>

                <input
                  style={{ fontSize: "13px" }}
                  type="date"
                  class="form-control"
                  min={moment(new Date()).format("YYYY-MM-DD")}
                  name="advance_amount_date"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advance_amount_date: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      advance_amount_date: "",
                    });
                  }}
                  value={formData?.advance_amount_date}
                />
                <div className="small text-danger">
                  {formValidation.advance_amount_date}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="border-top my-3"></div>
          </div>

          <div class="col-md-12 text-center">
            {edit == true ? (
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={onViewClose}
              >
                Back
              </button>
            ) : (
              <button
                class="btn btn-outline-secondary mr-2"
                onClick={() => navigate("/otheradvance/myotheradvance")}
              >
                Back
              </button>
            )}

            {state?.flag === true ? (
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={() => {
                  handleValidateFormUp(formData);
                }}
              >
                <i class="far fa-check-circle"></i> Update
              </button>
            ) : (
              <button
                class="btn btn-primary-inner bpi-main"
                type="submit"
                onClick={() => {
                  handleValidateForm(formData);
                }}
              >
                <i class="far fa-check-circle"></i> Submit
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <BackDateListing backDateData={backDateData} /> */}
    </>
  );
};

export default ApplyOtherAdvance;
