import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { saveReleaseCode, updateReleaseCode } from '../../../service/purchaseOrg';


const validationSchema = Yup.object({
    code: Yup.string()
        .matches(
            /[^\s*].*[^\s*]/g,
            "* This field should contain minimum 2 alphabet"
        )
        .matches(
            /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
            "Insert only character"
        )
        .required("*Purchasing Organization Code is required")
        .nullable(),
    desc: Yup.string()
        .min(2)
        .required("*Purchasing Organization Description is required")
        .nullable(),
});

const initialValues = {
    code: "",
    desc: "",
}

const PrAddOrgModal = ({ isOpen, handleModalClose, currentItem, refreshData }) => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let response = null;
                if (currentItem) {
                    response = await updateReleaseCode(values, currentItem.id)
                } else {
                    response = await saveReleaseCode(values)
                }
                if (response.data?.status) {
                    toast.success(response.message)
                    refreshData()
                    _handleModalClose()
                } else {
                    toast.error(response.message)
                }
            } catch (error) {
                console.error("🚀 ~ file: pr-add-org-master.js:39 ~ onSubmit: ~ error:", error)
            } finally { setSubmitting(false) }
        }
    })
    const { handleSubmit, getFieldProps, values, errors, setFieldValue, touched, isSubmitting, resetForm } = formik;


    const _handleModalClose = () => {
        handleModalClose()
        resetForm();
    }

    useEffect(() => {
        if (currentItem) {
            setFieldValue('code', currentItem.code)
            setFieldValue('desc', currentItem.desc)
        }
    }, [currentItem])
    return (
        <Modal
            show={isOpen}
            onHide={_handleModalClose}
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Purchasing Organization</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group innergroup">
                                        <label>
                                            Purchasing Organization Code{" "}
                                         <span class="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            {...getFieldProps('code')}
                                            placeholder="Enter Purchasing Organization Code"
                                        />
                                        {touched.code && errors.code ? (
                                            <div className="small text-danger">
                                                {errors.code}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group innergroup">
                                        <label>
                                            Purchasing Organization Description{" "}
                                         <span class="text-danger">*</span>
                                        </label>
                                        <textarea
                                            class="form-control"
                                            {...getFieldProps('desc')}
                                            placeholder="Enter Purchasing Organization Description"
                                        />
                                        {touched.desc && errors.desc ? (
                                            <div className="small text-danger">
                                                {errors.desc}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmitting
                            ? <Spinner animation="border" variant="primary" as="span" role="status" aria-hidden="true" />
                            : (
                                <>
                                    <div class="btn btn-outline-danger" onClick={_handleModalClose}>
                                        Cancel
                                    </div>
                                    <button class="btn btn-primary-inner bpi-main">Submit</button>
                                </>
                            )
                    }
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default PrAddOrgModal