import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as HeaderService from "../../service/jv_service";
import { Alert } from "react-bootstrap";
import Select from "react-select";

const DocumentsItems = (props) => {
  const {
    setTabStatus,
    jvDocDetails,
    setJvDocDetails,
    removeJvDetailRow,
    companyCodeValue,
    editFlag3,
  } = props;
  const [glCodeList, setGlCodeList] = useState([]);
  const [glCode, setGlCode] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [costCenter1, setCostCenter1] = useState([]);
  const [profitCenter, setProfitCenter] = useState([]);
  const [profitCenter1, setProfitCenter1] = useState([]);
  const [businessArea, setBusinessArea] = useState([]);
  const [businessArea1, setBusinessArea1] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [plant1, setPlant] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currency1, setCurrency] = useState([]);
  const [businessPlaceList, setBusinessPlaceList] = useState([]);
  const [businessPlace, setBusinessPlace] = useState([]);
  const [alertForAmount, setAlertForAmount] = useState(false);
  const [error, setError] = useState(false);
  const [errorRate, setErrorRate] = useState(false);
  const [codeFlag, setCodeFlag] = useState(false);
  const [costFlag, setCostFlag] = useState(false);
  const [profitFlag, setProfitFlag] = useState(false);
  const [businessAreaFlag, setBusinessAreaFlag] = useState(false);
  const [plantFlag, setPlantFlag] = useState(false);
  const [currencyFlag, setCurrencyFlag] = useState(false);
  const [businessPlaceFlag, setBusinessPlaceFlag] = useState(false);
  const [formValidation, setFormValidation] = useState({
    posting_key: "",
    gl_code: "",
    amount: "",
    currency: "",
    // rate: "",
  });

  const validateSSCFeature = () => {
    let errorExist = false;
    jvDocDetails.map((item) => {
      if (
        item?.posting_key?.trim()?.length === 0 ||
        item?.gl_code === 0 ||
        item?.amount?.trim().length === 0 ||
        item?.currency === 0
      ) {
        errorExist = true;
      }
      return item;
    });
    return errorExist;
  };

  const saveBlankValue = (field, i, values) => {
    const copyValue = JSON.parse(JSON.stringify(jvDocDetails));
    copyValue[i][field] = values;
    setJvDocDetails(copyValue);
  };

  const updateValue = (field, i, e) => {
    const copyValue = [...jvDocDetails];
    copyValue[i][field] = e;
    setJvDocDetails(copyValue);
    if (e.length > 13 && field == "amount") {
      setError(true);
    } else {
      setError(false);
    }

    if (e.length > 9 && field == "rate") {
      setErrorRate(true);
    } else {
      setErrorRate(false);
    }
  };

  const setValue = (id, value) => {
    return {
      value: id,
      label: value,
    };
  };

  const addMoreInputField = (ele, i) => {
    setAlertForAmount(false);
    if (validateSSCFeature()) {
    } else {
      if (jvDocDetails.length < 20) {
        const copyValue = JSON.parse(JSON.stringify(jvDocDetails));
        const ele = {};
        ele.posting_key = "";
        ele.gl_code = 0;
        ele.cost_center = 0;
        ele.profit_center = 0;
        ele.business_area = 0;
        ele.internal_order = "";
        ele.amount = "";
        ele.assignment = "";
        ele.plant = 0;
        ele.item_text = "";
        ele.currency = 0;
        ele.rate = "";
        ele.tax_code = "";
        ele.business_place = "";
        copyValue.push(ele);
        setJvDocDetails(copyValue);
      } else {
        toast.error("max");
      }
    }
  };

  const getGlCodeList = (id) => {
    HeaderService.getGlCodeList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.short_desc + "(" + res.gl_account + ")",
          };
        });
        setGlCodeList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCostCenter = (id) => {
    HeaderService.getCostCenter(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.name + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setCostCenterList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getProfitCenterList = (id) => {
    HeaderService.getProfitCenterList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setProfitCenter(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessAreaList = (id) => {
    HeaderService.getBusinessAreaList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setBusinessArea(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getPlantList = (id) => {
    HeaderService.getPlantList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.description + "(" + res.code + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setPlantList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCurrencyList = (id) => {
    HeaderService.getCurrencyList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.description + ")",
            name: res.code,
          };
        });

        // let dataValue = response?.data?.dataList?.result?.map((res) => {
        //   if (res?.code === "dollar") {
        //     return {
        //       value: res.id,
        //       label: res.code + "(" + res.description + ")",
        //     };
        //   }
        // });
        // setDefaultValueCurrency(dataValue);

        setCurrencyList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getBusinessPlaceList = (id) => {
    HeaderService.getBusinessPlaceList(id)
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.code + "(" + res.description + ")",
          };
        });
        data.unshift({ value: "", label: "Select" });
        setBusinessPlaceList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    jvDocDetails.map((item, index) => {
      if (!item.posting_key) {
        isError = true;
        formerr.posting_key = "*Required Posting Key";
        setFormValidation(formerr);
      }
      if (!item.gl_code) {
        isError = true;
        formerr.gl_code = "*Required GL Code ";
        setFormValidation(formerr);
      }
      if (!item.amount) {
        isError = true;
        formerr.amount = "*Required Amount";
        setFormValidation(formerr);
      }
      if (!item.currency) {
        isError = true;
        formerr.currency = "*Required Currency";
        setFormValidation(formerr);
      }
      // if (!item.rate && currency1.label !== "INR") {
      //   isError = true;
      //   formerr.rate = "*Required Rate";
      //   setFormValidation(formerr);
      // }
    });
    return isError;
  };

  const checkValidate = () => {
    let sum = 0;
    let sum1 = 0;
    let currencyData = "";
    let currencyData2 = "";

    jvDocDetails.map((x) => {
      if (x.posting_key == "40") {
        sum = Number(x.amount) + sum;
        currencyData = Number(x.currency);
      }
      if (x.posting_key == "50") {
        sum1 = Number(x.amount) + sum1;
        currencyData2 = Number(x.currency);
      }
    });
    if (sum1.toFixed(2) == sum.toFixed(2) && currencyData == currencyData2) return true;
    else return false;
  };

  const handleValidateTab = () => {
    let data = checkValidate();
    if (error === false) {
      if (handleValidate()) return false;
      if (data === true) {
        setTabStatus("Addressess");
        setAlertForAmount(false);
      } else {
        setAlertForAmount(true);
      }
    }
  };

  useEffect(() => {
    if (companyCodeValue !== undefined || editFlag3 == true) {
      getGlCodeList(companyCodeValue);
      getCostCenter(companyCodeValue);
      getProfitCenterList(companyCodeValue);
      getCurrencyList(companyCodeValue);
      getBusinessPlaceList(companyCodeValue);
      getPlantList(companyCodeValue);
      getBusinessAreaList(companyCodeValue);
    }
  }, [companyCodeValue, editFlag3]);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          {jvDocDetails?.map((items, index) => (
            <div class="bg-light px-3 pt-3 mb-3" key={`doc_${index}`}>
              <div class="row">
                <div class="col-md-12 text-right">
                  {jvDocDetails.length > 1 && (
                    <button
                      class="btn btnblankth text-danger p-0"
                      onClick={() => {
                        removeJvDetailRow(index);
                        setFormValidation({
                          posting_key: "",
                          gl_code: "",
                          amount: "",
                          currency: "",
                        });
                      }}
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  )}
                </div>
                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Posting Key<span class="text-danger">*</span>
                    </label>
                    <select
                      className="form-control newbgselect main-pointer"
                      classNamePrefix="select"
                      name="posting_key"
                      value={
                        jvDocDetails ? jvDocDetails[index].posting_key : ""
                      }
                      onChange={(e) => {
                        updateValue("posting_key", index, e.target.value);
                        setFormValidation({
                          ...formValidation,
                          posting_key: "",
                        });
                      }}
                    >
                      <option value="" label="Select Code" disabled />
                      <option value="40" label="40" />
                      <option value="50" label="50" />
                    </select>
                    <div className="small text-danger">
                      {index == Number(jvDocDetails.length - 1) &&
                        formValidation.posting_key}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      GL Code<span class="text-danger">*</span>
                    </label>
                    <Select
                      options={glCodeList}
                      name="gl_code"
                      id={"gl_code" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("gl_code", index, e.value);
                        setFormValidation({
                          ...formValidation,
                          gl_code: "",
                        });
                        setGlCode(e);
                        setCodeFlag(true);
                      }}
                      value={
                        jvDocDetails && codeFlag == false
                          ? setValue(
                              jvDocDetails[index].gl_code,
                              jvDocDetails[index].gl_desc
                            )
                          : jvDocDetails[index]?.glCode
                      }
                    />
                    <div className="small text-danger">
                      {index == Number(jvDocDetails.length - 1) &&
                        formValidation.gl_code}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Cost Center</label>
                    <span
                      className="mt-1"
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={() => {
                        saveBlankValue("cost_center", index, 0);
                        setCostCenter1(0);
                      }}
                    >
                      Reset
                    </span>
                    <Select
                      options={costCenterList}
                      name="cost_center"
                      id={"cost_center" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("cost_center", index, e?.value);
                        setCostCenter1(e);
                        setCostFlag(true);
                      }}
                      value={
                        jvDocDetails && costFlag == false
                          ? setValue(
                              jvDocDetails[index].cost_center,
                              jvDocDetails[index].cost_center_name
                            )
                          : jvDocDetails[index]?.costCenter1
                      }
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Profit Center</label>

                    <Select
                      options={profitCenter}
                      name="profit_center"
                      id={"profit_center" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("profit_center", index, e?.value);
                        setProfitCenter1(e);
                        setProfitFlag(true);
                      }}
                      value={
                        jvDocDetails && profitFlag == false
                          ? setValue(
                              jvDocDetails[index].profit_center,
                              jvDocDetails[index].profit_center_name
                            )
                          : jvDocDetails[index]?.profitCenter1
                      }
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Business Area</label>

                    <Select
                      options={businessArea}
                      name="business_area"
                      id={"business_area" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("business_area", index, e?.value);
                        setBusinessArea1(e);
                        setBusinessAreaFlag(true);
                      }}
                      value={
                        jvDocDetails && businessAreaFlag == false
                          ? setValue(
                              jvDocDetails[index].business_area,
                              jvDocDetails[index].business_area_name
                            )
                          : jvDocDetails[index]?.businessArea1
                      }
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Internal Order</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={30}
                      placeholder="Enter Internal Order"
                      name="internal_order"
                      onChange={(e) => {
                        updateValue("internal_order", index, e.target.value);
                      }}
                      value={
                        jvDocDetails ? jvDocDetails[index].internal_order : ""
                      }
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Amount<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Amount"
                      name="amount"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      min={0}
                      onChange={(e) => {
                        updateValue("amount", index, e.target.value);
                        setFormValidation({
                          ...formValidation,
                          amount: "",
                        });
                      }}
                      value={jvDocDetails ? jvDocDetails[index].amount : ""}
                    />
                    <div className="small text-danger">
                      {index == Number(jvDocDetails.length - 1) &&
                        formValidation.amount}
                      {index == Number(jvDocDetails.length - 1) &&
                        error === true &&
                        "Amount is not greater than 13 digit number"}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Assignment</label>
                    <input
                      type="text"
                      class="form-control"
                      maxLength={18}
                      placeholder="Enter Assignment"
                      name="assignment"
                      onChange={(e) => {
                        updateValue("assignment", index, e.target.value);
                      }}
                      value={jvDocDetails ? jvDocDetails[index].assignment : ""}
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Plant</label>
                    <Select
                      options={plantList}
                      name="plant"
                      id={"plant" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("plant", index, e?.value);
                        setPlant(e);
                        setPlantFlag(true);
                      }}
                      value={
                        jvDocDetails && plantFlag == false
                          ? setValue(
                              jvDocDetails[index].plant,
                              jvDocDetails[index].plant_name
                            )
                          : jvDocDetails[index]?.plant1
                      }
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Item Text</label>
                    <input
                      type="text"
                      maxLength={50}
                      class="form-control"
                      placeholder="Enter Item Text"
                      name="item_text"
                      onChange={(e) => {
                        updateValue("item_text", index, e.target.value);
                      }}
                      value={jvDocDetails ? jvDocDetails[index].item_text : ""}
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>
                      Currency{" "}
                      <span class="text-danger">
                        {currency1.name !== "INR" && "*"}
                      </span>
                    </label>

                    <Select
                      options={currencyList}
                      name="currency"
                      // defaultValue={defaultValueCurrency[0].label}
                      id={"currency" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("currency", index, e?.value);
                        setCurrency(e);
                        setFormValidation({
                          ...formValidation,
                          currency: "",
                        });
                        setCurrencyFlag(true);
                      }}
                      value={
                        jvDocDetails && currencyFlag == false
                          ? setValue(
                              jvDocDetails[index].currency,
                              jvDocDetails[index].currency_name
                            )
                          : jvDocDetails[index]?.currency1
                      }
                    />
                    <div className="small text-danger">
                      {index == Number(jvDocDetails.length - 1) &&
                        formValidation.currency}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Rate</label>
                    {/* <span class="text-danger">*</span> */}
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Enter Rate"
                      name="rate"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={blockInvalidChar}
                      maxLength={9}
                      onChange={(e) => {
                        updateValue("rate", index, e.target.value);
                        // setFormValidation({
                        //   ...formValidation,
                        //   rate: "",
                        // });
                      }}
                      value={jvDocDetails ? jvDocDetails[index].rate : ""}
                    />
                    <div className="small text-danger">
                      {/* {index == Number(jvDocDetails.length - 1) &&
                        formValidation.rate} */}
                      {index == Number(jvDocDetails.length - 1) &&
                        errorRate === true &&
                        "Rate is not greater than 9 digit number"}
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Tax Code</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Tax Code"
                      name="tax_code"
                      maxLength={30}
                      onChange={(e) => {
                        updateValue("tax_code", index, e.target.value);
                      }}
                      value={jvDocDetails ? jvDocDetails[index].tax_code : ""}
                    />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group innergroup">
                    <label>Business Place</label>

                    <Select
                      options={businessPlaceList}
                      name="business_place"
                      id={"business_place" + index}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        updateValue("business_place", index, e?.value);
                        setBusinessPlace(e);
                        setBusinessPlaceFlag(true);
                      }}
                      value={
                        jvDocDetails && businessPlaceFlag == false
                          ? setValue(
                              jvDocDetails[index].business_place,
                              jvDocDetails[index].business_place_name
                            )
                          : jvDocDetails[index]?.businessPlace
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div class="text-right">
            <button
              class="btn btnblanktd text-primary"
              onClick={() => {
                addMoreInputField(jvDocDetails);
                handleValidate();
              }}
            >
              <i class="far fa-plus-square mr-2"></i> Add More
            </button>
          </div>
        </div>
        {alertForAmount && (
          <Alert variant="danger" className="mb-5 mt-2">
            <i className="fa fa-exclamation-triangle"></i>
            Posting key 40 and 50 values sum doesn't match and currency must be
            same
          </Alert>
        )}
        <div class="col-md-12 mt-2">
          <button
            class="btn btn-outline-secondary mr-3"
            onClick={() => setTabStatus("Primary")}
          >
            <i class="far fa-arrow-alt-circle-left"></i> Previous
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => {
              handleValidateTab();
            }}
          >
            <i class="far fa-arrow-alt-circle-right"></i> Save & Next
          </button>
        </div>
      </div>
    </>
  );
};

export default DocumentsItems;
