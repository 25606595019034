import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

const CustomBreadcrumb = ({ items }) => {
  return (
    <>
      <Breadcrumb>
        {
          Array.isArray(items) && items.map(item => {
            const itemProps = {};
            if (item.to) {
              itemProps.href = item.to;
            }
            if (item.active) {
              itemProps.active = true
            }
            return (
              <>
                <Breadcrumb.Item {...itemProps}>{item.title}</Breadcrumb.Item>
              </>
            )
          })
        }
      </Breadcrumb>
    </>
  )
}

export default CustomBreadcrumb