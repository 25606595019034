import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";

function DataTable(props) {
    const [pageCount, setPageCount] = useState(1);
    const [pageNo, setPageNo] = useState(1);
    const [availablePageRange, setAvailablePageRange] = useState([1,1]);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const [totalListData, setTotalListData] = useState([]);
    const [sortedColumn, setSortedColumn] = useState({idx: null, sort: 'asccls'});
    const [nextDataFlag, setNextDataFlag] = useState(false);

    const dataCount = props?.option?.pagination?.show ? props?.option?.pagination?.dataCount : props?.list?.length

    useEffect(() => {
        const maxPage = props?.option?.pagination?.show ? Math.ceil(props?.option?.pagination?.dataCount/recordPerPage) : Math.ceil(props?.list?.length/recordPerPage);
        if(props?.list){
            const pageRange = [pageNo, pageNo + maxPage - 1]
            setAvailablePageRange(pageRange)
            if(maxPage) paginate(pageNo, recordPerPage, pageRange);
        }
        if(props?.option?.pagination?.show){
            setPageCount(Math.ceil(props?.option?.pagination?.currentPaginatedData?.totalRecords/recordPerPage))
        }else{
            setPageCount(maxPage)
        }
    }, [props])
    
    const paginate = (pgNum, recordCount, pageRange) => {
        let lastIdx = pgNum * recordCount;
        let firstIdx = lastIdx - recordCount;
        if(pgNum >= pageRange[0] && pgNum <= pageRange[1]){
            if(lastIdx > dataCount){
                if(nextDataFlag){
                    lastIdx = recordCount
                }else{
                    lastIdx -= dataCount
                }
            }
            if(firstIdx > props.list.length){
                if(nextDataFlag){
                    firstIdx = 0
                }else{
                    firstIdx -= dataCount
                }
            }
            setNextDataFlag(false)
        }else{
            setNextDataFlag(true)
            props.option.pagination.getNextData(Math.ceil(lastIdx/dataCount), dataCount, ...props?.option?.pagination?.payload);
        }
        let listData = [];
        while(firstIdx < lastIdx && props.list[firstIdx]){
            listData.push(props.list[firstIdx]);
            firstIdx++;
        }
        setPageNo(pgNum)
        setTotalListData(listData)
    }

    const onSelectRecordPerPage = (record) => {
        setRecordPerPage(record);
        if(props?.option?.pagination?.show){
            setPageCount(Math.ceil(props?.option?.pagination?.currentPaginatedData?.totalRecords/record))
        }else{
            setPageCount(Math.ceil(dataCount/record))
        }
        paginate(1, record, availablePageRange)
    }

    const sortColumn = (datafield, index) => {
        let listData = [...totalListData];
        if(datafield == '$er1@LN0'){
            listData.reverse();
        }else{
            listData.sort((obj1, obj2) => {
                let a = obj1[datafield];
                let b = obj2[datafield];
                if(typeof a == 'string'){
                    a = a.toLowerCase();
                    b = b.toLowerCase();
                }
                switch(sortedColumn.sort){
                    case 'asccls':
                        if(a < b) return 1;
                        if(a > b) return -1;
                        return 0;
                    default:
                        if(a > b) return 1;
                        if(a < b) return -1;
                        return 0;
                }
            })
        }
        setTotalListData(listData)
        setSortedColumn({idx: index, sort: sortedColumn.sort == 'asccls' ? 'desccls' : 'asccls'})
    }

    return (
        <>
            <div className="table-responsive" style={props?.style ? { ...props.style } : {}}>
                <table className="table table-striped table-bordered tablecured">
                    <thead>
                        {props?.option?.serialNo?.show ? <th
                            className={props?.option?.serialNo?.sort ? (sortedColumn.idx == -1 ? sortedColumn.sort : "unsortcls") : ""}
                            onClick={() => props?.option?.serialNo?.sort ? sortColumn('$er1@LN0', -1) : {}}
                        >
                            <span className="mr-2">{props?.option?.serialNo?.label ? props?.option?.serialNo?.label : '#'}</span>
                        </th> : <></>}
                        {props?.column?.map((col, idx) => <th 
                            style={{ whiteSpace: 'nowrap' }}
                            className={col?.sort ? (sortedColumn.idx == idx ? sortedColumn.sort : "unsortcls") : ""}
                            onClick={() => col?.sort ? sortColumn(col.datafield, idx) : {}}
                        >
                            <span className="mr-2">{col.text}</span>
                        </th>)}
                    </thead>
                    <tbody>
                        {/* {props.option.pagination.dataCount ? props?.list?.map((data, index) => <tr> */}
                        {totalListData.length ? totalListData?.map((data, index) => <tr>
                            {props?.option?.serialNo?.show ? <td>{recordPerPage * (pageNo - 1) + (index + 1)}</td> : <></>}
                            {props?.column.map(col => <CellData rowObj={data} colObj={col} idx={index} />)}
                        </tr>) :
                        <tr><td colSpan={props?.column?.length + (props?.option?.serialNo?.show ? 1 : 0)} style={{textAlign: 'center'}}>No Records Found...</td></tr>}
                    </tbody>
                </table>
            </div>
            {props?.option?.pagination?.show ? <div className="row">
                <div className="col-sm-5">
                    <div className="sortinglist">
                        Show
                        <select
                            className="form-control"
                            onChange={e => onSelectRecordPerPage(e.target.value)}
                        >
                            {/* <option value={1}>1</option> */}
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-7">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={(e) => paginate(e.selected + 1, recordPerPage, availablePageRange)}
                        disableInitialCallback={true}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            </div> : <></>}
        </>
    )
}

function CellData(props) {
    if (props.colObj?.formatter) {
        return <td>{props.colObj.formatter(props.rowObj[props.colObj.datafield], props.rowObj, props.idx)}</td>
    } else {
        return <td>{props.rowObj[props.colObj.datafield]}</td>
    }
}
export default DataTable