import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getSkillTab = async (queryData) => {
  return axios.get(`${AUTH_BASE_URL}/skill-matrix-usage-master?${queryData}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/skill-matrix-usage-master/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
