import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/piDataStore";
import * as DomesticService from "../../../service/domestic";
import * as TravelSrv from "../../../service/travellist";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tippy";

function ViewTravelInter(props) {
  const userData = useSelector(selectUserData);
  const { viewFormData, onViewClose } = props;
  const todaysD = new Date();
  const fDate = moment(todaysD).format("DDMMYYYY");
  const [outstandingAmt, setOutstandingAmt] = useState([]);
  const [checkAdvanceValidation, setCheckAdvanceValidation] = useState();
  const [advanceAmountShow, setAdvanceAmountShow] = useState(false);
  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [outstandingValues, setOutstandingValues] = useState();

  const getOutstandingAmount = (employee_code, travel_id) => {
    DomesticService.getOutstandingAmount(employee_code, travel_id)
      .then((response) => {
        let data = response?.data?.dataList;
        setOutstandingAmt(data);
        setOutstandingValues(data?.GT_BSIK?.__values__?.item);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const TravelAdvanceCheck = (data) => {
    TravelSrv.TravelAdvanceCheck(data)
      .then((response) => {
        const data = response?.data?.dataList?.is_exist;
        setCheckAdvanceValidation(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getOutstandingAmount(userData?.id, "");
  }, []);

  useEffect(() => {
    if (userData?.id !== undefined) {
      TravelAdvanceCheck(userData?.id);
    }
  }, [userData]);
  console.log(advanceAmountShow, "advanceAmountShow");

  useEffect(() => {
    if (
      checkAdvanceValidation == true ||
      ((!["1003", "1007", "1017"].includes(userData?.personal_area_name) ||
        (["1003", "1007", "1017"].includes(userData?.personal_area_name) &&
          userData?.sub_personal_area_name === "GG01")) &&
        userData?.is_sf_user == true) ||
      checkAdvanceValidation == true
    ) {
      setAdvanceAmountShow(true);
    }
  }, [checkAdvanceValidation]);

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <TravelLinks />
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    View Travel Intimation:
                    {/* PITI{fDate}
                    {userData?.username}
                    {viewFormData?.id} */}
                    {viewFormData?.travel_intimation_number}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="defaultchatgptquest text-left">
                    <Link
                      onClick={onViewClose}
                      className="badge badge-secondary"
                    >
                      <i className="fas fa-arrow-circle-left"></i> Back
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-12" style={{ textAlign: "right" }}>
                <h6 class="f-14 mt-2">
                  <span class="text-muted">
                    Reporting Manager - {userData?.reporting_to_fname}{" "}
                    {userData?.reporting_to_lname} (
                    {userData?.reporting_to_name})
                  </span>
                </h6>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
              <Tabs defaultActiveKey="International" id="approvaltabs">
                <Tab
                  eventKey="International"
                  title={
                    <React.Fragment>
                      {viewFormData?.request_type}
                    </React.Fragment>
                  }
                >
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered tablecured viewpo">
                      <thead>
                        <tr>
                          <th>Departure</th>
                          <th>Reaching</th>
                          <th>Purpose of Travel</th>
                          <th>Mode</th>
                          <th>Colleague Empcode</th>
                          <th>Hotel</th>
                          <th>Additional Information</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewFormData?.travel_detail_data?.map(
                          (data, index) => (
                            <>
                              <tr>
                                <td>
                                  <div class="text-dark fw-bold f-14">
                                    {data?.from_continent_name} (
                                    {data?.from_country_name} -{" "}
                                    {data?.from_city_name == "Other"
                                      ? data?.from_city_input
                                      : data?.from_city_name}
                                    ){" "}
                                  </div>
                                  {moment(data?.from_date).format("DD-MM-YYYY")}
                                  &nbsp;
                                  {/* {data?.from_time.substring(0, 5)} */}
                                  {moment(data?.from_time, "h:mm A").format(
                                    "HH:mm A"
                                  )}
                                </td>
                                <td>
                                  <div class="text-dark fw-bold f-14">
                                    {data?.to_continent_name} (
                                    {data?.to_country_name} -{" "}
                                    {data?.to_city_name === "Other"
                                      ? data?.to_city_input
                                      : data?.to_city_name}
                                    ){" "}
                                  </div>
                                  {moment(data?.to_date).format("DD-MM-YYYY")}
                                  &nbsp;
                                  {/* {data?.to_time.substring(0, 5)} */}
                                  {moment(data?.to_time, "h:mm A").format(
                                    "HH:mm A"
                                  )}
                                </td>
                                <td>{data?.purpose}</td>
                                <td>{data?.mode_name}</td>
                                <td>
                                  {data?.colleague_user_name !== "" ||
                                  data?.colleague_user_name !== null ? (
                                    <Tooltip
                                      title={data?.colleague_user_code}
                                      position="left"
                                    >
                                      {data?.colleague_user_name !==
                                        undefined &&
                                        data?.colleague_user_code !== null &&
                                        `${data?.colleague_user_name} (${data?.colleague_user_code})`}
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>{data?.stay_needed ? "Yes" : "No"}</td>
                                <td>
                                  {data?.additional_information?.length > 20 ? (
                                    <Tooltip
                                      title={data?.additional_information}
                                      position="left"
                                    >
                                      {`${data?.additional_information.substring(
                                        0,
                                        20
                                      )}...`}
                                    </Tooltip>
                                  ) : (
                                    data?.additional_information
                                  )}
                                </td>
                              </tr>
                            </>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <p
                        style={{
                          color: "#0195d4",
                          fontWeight: "bold",
                        }}
                      >
                        Approve Details
                      </p>
                      <div class="table-responsive ">
                        <table class="table table-striped table-bordered tablecured viewpo">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Approver Name</th>
                              <th>Approver Level</th>
                              <th>Status</th>
                              <th>Approved Date</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {viewFormData?.approver_remark_data?.map(
                              (data, index) => (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {`${data?.approver_name} (${data?.approver_username})`}
                                    </td>
                                    <td>
                                      <span>{data?.approver_type}</span>
                                    </td>
                                    <td>
                                      {!data?.status
                                        ? "Pending"
                                        : data?.status == "A"
                                        ? "Approved"
                                        : data?.status == "R"
                                        ? "Rejected"
                                        : ""}
                                    </td>
                                    <td>
                                      {data?.status &&
                                        data?.status != "P" &&
                                        data?.updated_at &&
                                        moment
                                          .parseZone(data?.updated_at)
                                          .format("DD-MM-YYYY")}
                                      &nbsp;
                                      {moment(
                                        data?.updated_at.split("T")[1],
                                        "h:mm A"
                                      ).format("HH:mm A")}
                                    </td>
                                    <td>{data?.remark}</td>
                                  </tr>
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <h5 className="text-theme border-bottom pb-2 mb-3">
                          <strong>Additional Details</strong>
                        </h5>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Insurance Required</label>
                          <select
                            className="form-control newbgselect"
                            name="insurance_required"
                            disabled
                            value={
                              viewFormData.insurance_required ? true : false
                            }
                          >
                            <option value="">Select</option>
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Passport No</label>
                          <input
                            type="text"
                            disabled
                            name="passport_number"
                            className="form-control"
                            maxLength={12}
                            placeholder="Enter Passport No"
                            value={viewFormData.passport_number}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Valid From</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            name="valid_from"
                            value={moment(viewFormData?.valid_from).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Valid Till</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            name="valid_to"
                            value={moment(viewFormData?.valid_to).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Visa No. (If Available)</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            maxLength={20}
                            placeholder="Enter Visa No. (If Available)"
                            name="visa_number"
                            value={viewFormData.visa_number}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Remarks</label>
                          <input
                            type="text"
                            disabled
                            name="remark"
                            maxLength={100}
                            className="form-control"
                            placeholder="Enter Remarks"
                            value={viewFormData.remark}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-4 pb-2">
                    <div class="mb-3">
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <h5 class="text-theme border-bottom pb-2 mb-3">
                            <strong>
                              {advanceAmountShow ? "Advance" : "Outstanding"}{" "}
                              Details
                            </strong>
                          </h5>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group innergroup">
                            <label class="d-flex w-100 align-items-center">
                              Outstanding{" "}
                              <i class="fas fa-rupee-sign ml-1 mr-1"></i>{" "}
                            </label>
                            <input
                              type="text"
                              disabled
                              value={`${outstandingAmt?.remark} : Rs.${outstandingAmt?.balance} `}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-14">
                            <Link onClick={addnewopen}>
                              Click here to view (Outstanding Details)
                            </Link>
                          </div>
                        </div>
                        {advanceAmountShow && (
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label class="d-flex w-100 align-items-center">
                                Advance Amount{" "}
                                <i class="fas fa-rupee-sign ml-1 mr-1"></i>{" "}
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                disabled
                                placeholder="Enter Advance Amount"
                                value={viewFormData?.advance_amount}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div class="col-md-12 mt-3 text-center">
                        <button
                          type="button"
                          class="btn btn-outline-secondary mr-2"
                          onClick={onViewClose}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={addnewshow}
        onHide={addnewclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
        <Modal.Header closeButton>
          <Modal.Title>SAP Outstanding Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div className="col-md-12">
              <div className="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Bill Date</th>
                      <th>Text</th>
                      <th>Assignment number</th>
                      <th>Document Number</th>
                      <th>Posting Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {outstandingValues?.length > 0 ? (
                      outstandingValues?.map((value, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{value?.__values__?.DMBTR}</td>
                          <td>{value?.__values__?.WAERS}</td>
                          <td>
                            {moment(value?.__values__?.BLDAT).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td
                            style={{
                              maxWidth: "80vh",
                            }}
                          >
                            {value?.__values__?.SGTXT}
                          </td>
                          <td>{value?.__values__?.ZUONR}</td>
                          <td>{value?.__values__?.BELNR}</td>
                          <td>{value?.__values__?.BUDAT}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewTravelInter;
