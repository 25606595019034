import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import "../../alert/CustomUi.css";
import SearchLoader from "../../common/SearchLoader";
import { setDisplayLoader } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import * as RoleService from "../../service/roleRightMgt";
import { confirmAlert } from "react-confirm-alert";

function CustomRoleAssign() {
  const dispatch = useDispatch();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [addNew, setAddNew] = useState(false);
  const addNewOpen = () => setAddNew(true);
  const addNewClose = () => setAddNew(false);
  const [viewForm, setViewForm] = useState(false);
  const [loaderActive, isLoaderActive] = useState(false);
  const [filterData, setFilterData] = useState({
    status: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "id" });
  const [tableList, setTableList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [table, setTable] = useState("");
  const [column, setColumn] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [group, setGroup] = useState("");
  const [isUpdate, setIsUpdate] = useState(null);
  const [formErrors, setFormErrors] = useState({
    tableName: false,
    columnName: false,
    roleName: false,
  });
  const [records, setRecords] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getRecords(searchStr, currentPage, pageSizeNo, sortData?.sort_by, true);
    getTableDetails();
    getRoles();
  }, [pageSizeNo]);

  const handleKey = (search, pageNo, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getRecords = (search, pageNo, pageSizeNo, sortBy, paginate) => {
    RoleService.getCustomRole(
      handleKey(search, pageNo, pageSizeNo, sortBy, paginate)
    ).then((response) => {
      if (response?.status === 200) {
        setRecords(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      }
    });
  };

  const getRoles = () => {
    RoleService.getGroupList().then((response) => {
      if (response?.status === 200) {
        setGroupList(
          response?.data?.dataList?.result?.map((each) => {
            return {
              label: each?.name,
              value: each?.id,
            };
          })
        );
      }
    });
  };

  const getTableDetails = () => {
    RoleService.getTableList().then((response) => {
      if (response?.status === 200) {
        let tableList = response?.data?.dataList?.result?.map((each) => {
          return {
            label: each?.db_name,
            value: each?.db_name,
            columns: each?.columns?.map((obj) => {
              return {
                label: obj,
                value: obj,
              };
            }),
          };
        });
        setTableList(tableList);
      }
    });
  };

  const submitForm = () => {
    if (handleErrors()) {
      return;
    } else {
      handleFormSubmit();
    }
  };

  const handleErrors = () => {
    let flag = false;
    if (table === "" || column === "" || group === "") {
      flag = true;
    }
    setFormErrors((prev) => ({
      ...prev,
      tableName: table === "" ? true : false,
      columnName: column === "" ? true : false,
      roleName: group === "" ? true : false,
    }));
    return flag;
  };

  const handleFormSubmit = () => {
    let obj = {
      tableName: table?.label,
      columnName: column?.value,
      role: group?.map((each) => each?.value),
    };
    addNewClose();
    dispatch(setDisplayLoader("Display"));
    RoleService.submitCustomRole(obj).then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        getRecords(searchStr, currentPage, pageSizeNo, sortData?.sort_by, true);
        toast.success("Role Allocated Successfully");
        setTable("");
        setColumn("");
        setGroup("");
      }
    });
  };

  useEffect(() => {
    if (isUpdate !== null) {
      dispatch(setDisplayLoader('Display'))
      let payload = {
        is_active: isUpdate?.is_active
      }
      RoleService.updateCustomRole(isUpdate?.id, payload).then((res) => {
        if (res?.status === 200) {
          dispatch(setDisplayLoader('Hide'));
          toast.success("Record Updated Successfully")
          getRecords(searchStr, currentPage, pageSizeNo, "-id", true);
          setIsUpdate(null)
        }
      }).catch((err) => {
        console.log(err)
        dispatch(setDisplayLoader("Hide"))
      })
    }
  }, [isUpdate]);

  const deleteEntry = (id) => {
    let payload = {
      id: id
    }
    RoleService.deleteCustomRole(payload).then((response) => {
      if (response?.status === 200) {
        toast.success('Record Deleted Successfully.')
        getRecords(searchStr, currentPage, pageSizeNo, sortData?.sort_by, true);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const deleteRecord = (id) => {
    confirmAlert({
      title: "Delete Role",
      message: `Are you sure to delete this record.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEntry(id),
        },
        {
          label: "No",
        },
      ],
    });
  }

  const getExportData = (search, pageNo, pageSizeNo, sortBy, paginate) => {
    dispatch(setDisplayLoader("Display"))
    RoleService.getExportValue(handleKey(
      search, pageNo, pageSizeNo, sortBy, paginate
    )).then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CustomRole.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
  }

  return (
    <>
      <div className="content-wrapper-inner" onClick={() => setSearchStr("")}>
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Custom Role Allocation</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-10">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      // onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  {loaderActive ? <SearchLoader /> : ""}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" className="mr-2" />
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button style={{ border: 0, background: "none" }} onClick={() => {
                        getExportData(searchStr, currentPage, pageSizeNo, sortData?.sort_by, true)
                      }}>
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  onClick={() => {
                    filteropen();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                <button className="btn btn-primary-inner" onClick={addNewOpen}>
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Table Name</th>
                <th>Column Name</th>
                <th>Role Name</th>
                <th>Active/In-active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {records?.length > 0 &&
                records?.map((each, index) => (
                  <tr>
                    <td>{each?.table_name}</td>
                    <td>{each?.column_name}</td>
                    <td>{each?.role.map((x) => x?.name).toString()}</td>
                    <td>
                      <div class="answerboxpollsuy innergroup">
                        <input type="checkbox" className="form-control" checked={each?.is_active} onChange={(e) => {
                          let data = JSON.parse(JSON.stringify(records))
                          data[index].is_active = e.target.checked ? true : false
                          setIsUpdate(data[index])
                          setRecords(data)
                        }} />
                      </div>
                    </td>
                    <td>
                      <button className="btn btn-blanktd text-danger" onClick={() => {
                        deleteRecord(each?.id)
                      }}>
                          <i className="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7"></div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup"></div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-danger" type="button">
            <i className="fa fa-undo"></i>Clear
          </button>
          <button className="btn btn-primary-inner bpi-main" type="submit">
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={addNew}
        onHide={addNewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Role Allocation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Table Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="table"
                      options={tableList}
                      className="basic-multi-select"
                      classNamePrefix="Select"
                      closeMenuOnSelect={true}
                      onChange={(e) => {
                        setTable(e);
                        setColumnList(e?.columns);
                        setFormErrors((prev) => ({
                          ...prev,
                          tableName: false,
                        }));
                      }}
                      value={table}
                    />
                    {formErrors.tableName ? (
                      <div className="small text-danger">Select table name</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Column Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="column"
                      options={columnList}
                      className="basic-multi-select"
                      classNamePrefix="Select"
                      closeMenuOnSelect={true}
                      onChange={(e) => {
                        setColumn(e);
                        setFormErrors((prev) => ({
                          ...prev,
                          columnName: false,
                        }));
                      }}
                      value={column}
                    />
                    {formErrors.columnName ? (
                      <div className="small text-danger">
                        Select column name
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>
                      Role Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      name="role"
                      options={groupList}
                      className="basic-multi-select"
                      classNamePrefix="Select"
                      closeMenuOnSelect={false}
                      onChange={(e) => {
                        setGroup(e);
                        setFormErrors((prev) => ({
                          ...prev,
                          roleName: false,
                        }));
                      }}
                      value={group}
                    />
                    {formErrors.roleName ? (
                      <div className="small text-danger">Select roles</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-danger">
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={submitForm}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomRoleAssign;
