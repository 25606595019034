import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getCommonApproval } from "../../service/dashboard";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as OtvService from "../../service/otvServices";
import {
  changeLoanRequestStatus,
  changeSalaryAdvRequestStatus,
  changeShiftStatus,
  changeVPFRequestStatus,
} from "../ShiftMaster/shiftservice";
import * as moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeLeaveStatus } from "../../service/leaveService";
import { changeNewsStatus } from "../../service/Cms-newsService";
import { changePolicyStatus } from "../../service/policy";
import { changeBrandingStatus } from "../../service/brandService";
import * as cashbookService from "../../service/cashbookService";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as HeaderService from "../../service/jv_service";
import { Dropdown, Modal } from "react-bootstrap";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { changeReimbursementStatus } from "../ClaimReimb/claimReimbServices";
import { changeprstatus } from "../../service/prService";
import { updateStatus } from "../../service/travelService";
import * as GLService from "../../service/glService";
import { changebulkmatstatus } from "../../service/materialService";
import { changeBulksesstatus } from "../../service/sesService";
import * as TravelListingAPI from "../../service/travelService";
import * as HSNService from "../../service/hsnService";
import * as SourceList from "../../service/source-list";

function CommonApprover(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  let dataPath = pathname.split("/");
  const [currentsubType, setCurrentsubType] = useState("");
  const [dataCountOtherExpExtra, setdataCountOtherExpExtra] = useState({
    otherExpException: {},
    otherExp: {},
  });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [currentType, setCurrentType] = useState("");
  const [currentsubTypename, setCurrentsubTypename] = useState("");
  const [postingData, setPostingData] = useState([]);
  const [travelCheckPoint, setTravelCheckPoint] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [activeTab, setActiveTabData] = useState([]);
  const [stateSubName, setStateSubName] = useState();
  const [gLReviewerFlag, setGLReviewerFlag] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [remarksModal, setRemarksModal] = useState(false);
  const [remarkStatus, setRemarkStatus] = useState("");
  const [remarksForRejection, setRemarksForRejection] = useState("");
  const userData = useSelector(selectUserData);
  const [yearYtd, setYearYtd] = useState();
  const [valueMerge, setValueMerge] = useState(false);
  const [errorTravelShow, setErrorTravelShow] = useState();
  const [filterDataValue, setFilterDataValue] = useState({
    is_exception: "false",
  });
  const [dataTravel, setDataTravel] = useState();
  const [otherfilterDataValue, setOtherfilterDataValue] = useState({
    is_exception: "false",
  });
  const [isLoading, setIsLoading] = useState(false);
  const todaysD = new Date();
  const fDate = moment(todaysD).format("DDMMYYYY");

  const [exceptionCount, setExceptionCount] = useState(0);
  const [nonExceptionCount, setNonExceptionCount] = useState(0);

  const getApproval = (
    sub_type_id,
    page_no,
    page_size,
    sortby,
    paginate,
    search,
    is_exception
  ) => {
    setActiveTabData([]);
    dispatch(setDisplayLoader("Display"));
    getCommonApproval(
      sub_type_id,
      page_no,
      page_size,
      sortby,
      paginate,
      search,
      is_exception
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response?.data?.dataList?.result?.sub_type.length == 0) {
          navigate("/dashboard");
        }
        // if (response?.data?.dataList?.result?.approvals?.result?.length == 0) {
        //   navigate("/dashboard");
        // }
        let idx = response?.data?.dataList?.result?.sub_type?.findIndex(
          (obj) => obj.sub_type_id == sub_type_id
        );
        let expCount = 0;
        let nonExpCount = 0;
        if (idx > -1) {
          if (`${is_exception}` == "true") {
            expCount = response?.data?.dataList?.result?.approvals
              ?.paginated_data
              ? response?.data?.dataList?.result?.approvals?.paginated_data
                  ?.totalRecords
              : 0;
            nonExpCount =
              response?.data?.dataList?.result?.sub_type[idx].approvals_count -
              expCount;
          } else {
            nonExpCount = response?.data?.dataList?.result?.approvals
              ?.paginated_data
              ? response?.data?.dataList?.result?.approvals?.paginated_data
                  ?.totalRecords
              : 0;
            expCount =
              response?.data?.dataList?.result?.sub_type[idx].approvals_count -
              nonExpCount;
          }
        }
        setExceptionCount(expCount);
        setNonExceptionCount(nonExpCount);
        setActiveTabData(response?.data?.dataList?.result);
        setCurrentType(response?.data?.dataList?.result?.type_name);
        setpageCount(
          response?.data?.dataList?.result?.approvals?.paginated_data
            ?.totalPages
        );
        response?.data?.dataList?.result?.sub_type?.map((item) => {
          if (item?.sub_type_id == dataPath[2]) {
            setStateSubName(item.sub_type_name);
          }
        });
        let name =
          location?.state?.subTypeName === undefined
            ? stateSubName
            : location?.state?.subTypeName;
        if (name === "GL Sign-off") {
          response?.data?.dataList?.result?.approvals?.result?.map((res) => {
            if (res?.details?.level === "Reviewer") {
              setGLReviewerFlag(true);
            } else {
              setGLReviewerFlag(false);
            }
          });
        }
      })
      .catch((error) => {
        navigate("/dashboard");
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getOtherEpExtra = (
    sub_type_id,
    page_no,
    page_size,
    sortby,
    paginate,
    search,
    is_exception
  ) => {
    getCommonApproval(
      sub_type_id,
      page_no,
      page_size,
      sortby,
      paginate,
      search,
      is_exception
    )
      .then((response) => {
        // setdataCountOtherExpExtra(response?.data?.dataList?.result?.approvals?.result?.length)
        if (is_exception) {
          dataCountOtherExpExtra["otherExpException"]["total"] =
            response?.data?.dataList?.result?.approvals?.result?.length;
          dataCountOtherExpExtra["otherExpException"]["id"] = sub_type_id;
          dataCountOtherExpExtra["otherExpException"]["type"] =
            "Other Expense Exception";
        } else {
          dataCountOtherExpExtra["otherExp"]["total"] =
            response?.data?.dataList?.result?.approvals?.result?.length;
          dataCountOtherExpExtra["otherExp"]["id"] = sub_type_id;
          dataCountOtherExpExtra["otherExp"]["type"] = "Other Expense";
        }
      })
      .catch((error) => {
        // navigate("/dashboard");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let qry = new URLSearchParams(window.location.search);

    let name = qry.get("subTypeName")
      ? qry.get("subTypeName")
      : location?.state?.subTypeName === undefined
      ? stateSubName
      : location?.state?.subTypeName;
    if (valueMerge == false) {
      // setValueMerge(true);
      let id = dataPath[2];
      setCurrentsubTypename(name);
      setCurrentsubType(id);
      let exp_flag =
        name === "Other Expense Exception" ||
        name === "Travel Intimation Exception" ||
        name == "Travel Expense Exception"
          ? "true"
          : "false";
      getApproval(
        id,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        exp_flag
      );
    } else if (
      name === "Other Expense Exception" ||
      // name === "Travel Intimation Exception" ||
      name === "Travel Expense Exception"
    ) {
      getApproval(
        currentsubType,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        "false"
      );
    } else {
      getApproval(
        currentsubType,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        filterDataValue?.is_exception
      );
    }

    if (
      currentsubType &&
      currentsubTypename &&
      currentsubTypename?.toLowerCase()?.includes("other expense")
    ) {
      activeTab?.sub_type?.map((s) =>
        s?.sub_type_name?.toLowerCase() == "other expense exception"
          ? getOtherEpExtra(
              s?.sub_type_id,
              currentPage,
              pageSizeNo,
              "-created_at",
              true,
              "",
              true
            )
          : ""
      );

      activeTab?.sub_type?.map((s) =>
        s?.sub_type_name?.toLowerCase() == "other expense"
          ? getOtherEpExtra(
              s?.sub_type_id,
              currentPage,
              pageSizeNo,
              "-created_at",
              true,
              "",
              false
            )
          : ""
      );
    }
  }, [pageSizeNo, stateSubName]);

  const curentsubtype = (id, name) => {
    let name1 = name === "Travel" ? "Travel Intimation" : name;
    setCurrentsubTypename(name1);
    setCurrentsubType(id);
    if (name1 == "Other Expense Exception") {
      getApproval(id, currentPage, pageSizeNo, "-created_at", true, "", "true");
    } else {
      getApproval(
        id,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        filterDataValue?.is_exception
      );
    }
  };

  const curentsubtype2 = (name) => {
    // let name1 = name === "Travel" ? "Travel Intimation" : name;
    setCurrentsubTypename(name);

    activeTab?.sub_type?.map((s) =>
      s?.sub_type_name == name ? setCurrentsubType(s?.sub_type_id) : ""
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getApproval(
      currentsubType,
      activePage,
      pageSizeNo,
      "-id",
      true,
      "",
      filterDataValue?.is_exception
    );
  };
  const handleSearch = (e) => {
    if (e.target.value.length >= 2 || e.target.value.length === 0) {
      getApproval(
        currentsubType,
        1,
        pageSizeNo,
        "-id",
        true,
        e.target.value.toLowerCase(),
        filterDataValue?.is_exception
      );

      setSearch(e.target.value);
    }
  };

  const handleAllData = (e) => {
    let data = JSON.parse(JSON.stringify(activeTab));
    if (e.target.checked === true) {
      data?.approvals?.result?.map((res) => {
        setDataTravel(res);
        if (
          currentsubTypename === "GL Sign-off" &&
          res?.details?.level === "Reviewer"
        ) {
          res.checked = "Y";
          postingData.push(res.transaction_id);
        }
        if (currentsubTypename === "Travel") {
          res.checked = "Y";
          postingData.push(res.transaction_id);
        }
        if (
          res?.last_approved !== null &&
          currentsubTypename !== "GL Sign-off"
        ) {
          res.checked = "Y";
          currentsubTypename === "JV"
            ? postingData.push(res?.details?.jv_approve_id)
            : postingData.push(res.transaction_id);
        }
        if (
          (res?.details?.is_exception == false &&
            data?.type_name == "Travel And Expense") ||
          data?.type_name == "ESS" ||
          data?.type_name == "Other Expense" ||
          data?.type_name == "Other Advance"
        ) {
          travelCheckPoint.push(res.transaction_id);
        }
      });
      setActiveTabData(data);
    } else {
      data?.approvals?.result?.map((res) => {
        res.checked = "N";
      });
      setActiveTabData(data);
      let obj = postingData?.map((x) => {
        if (x != data?.transaction_id) {
          return x;
        }
      });
      setPostingData([]);
      setTravelCheckPoint([]);
    }
  };

  const selectDataToPost = (e, data) => {
    let copyData1 = JSON.parse(JSON.stringify(activeTab));
    if (e.target.checked === true) {
      // postingData.push(data.transaction_id);
      copyData1?.approvals?.result?.map((res) => {
        if (res?.transaction_id === data?.transaction_id) {
          res.checked = "Y";
        }
      });
      setActiveTabData(copyData1);
    } else {
      copyData1?.approvals?.result?.map((res) => {
        if (res?.transaction_id === data?.transaction_id) {
          res.checked = "N";
        }
      });
      let copyData = postingData;
      let obj = copyData?.map((res) => {
        if (res != data?.transaction_id) {
          return res;
        }
      });
      // setPostingData(obj);
      setActiveTabData(copyData1);
    }
  };

  const submitAction = (data, stat) => {
    let arrData = { approval_data: [] };
    let arrTravelData = { approval_data: [] };
    let obj = {};
    if (data.length > 0) {
      // eslint-disable-next-line default-case
      switch (currentsubTypename) {
        case "Loan":
          obj.status = stat;
          obj.loan_id = data;
          changeLoanRequestRemarks(obj);
          break;
        case "Salary Advance":
          obj.status = stat;
          obj.salary_id = data;
          changeSalaryAdvRequestRemarks(obj);
          break;
        case "Shift":
          obj.status = stat;
          obj.shift_id = data;
          changeShiftChangeRemarks(obj);
          break;
        case "VPF":
          obj.status = stat;
          obj.vpf_id = data;
          changeVPFRequestRemarks(obj);
          break;
        case "Leave":
          obj.status = stat;
          obj.leave_id = data;
          changeLeaveRemarks(obj);
          break;
        case "News, Notice & Popup":
          obj.status = stat;
          obj.news_id = data;
          changeNewsRemarks(obj);
          break;
        case "Policy":
          obj.status = stat;
          obj.policy_id = data;
          changePolicyRemarks(obj);
          break;
        case "Branding":
          obj.status = stat;
          obj.branding_id = data;
          changeBrandRemarks(obj);
          break;
        case "JV":
          obj.status = stat == "A" && "APPROVED";
          obj.id_list = data;
          saveMultiApprove(obj);
          break;
        case "OTV":
          data.map((item, i) => {
            let Object = { status: "", otv_id: "" };
            Object.status = stat;
            Object.otv_id = item;
            arrData.approval_data.push(Object);
          });
          saveOTVMultiApprove(arrData);
          break;
        case "Mobile":
          obj.status = stat;
          obj.id = data;
          obj.reimb_type = "1";
          changeReimbursementStatusBulk(obj);
          break;
        case "Marriage":
          obj.status = stat;
          obj.id = data;
          obj.reimb_type = "2";
          changeReimbursementStatusBulk(obj);
          break;
        case "Purchase Requisition":
          obj.status = stat;
          obj.remarks = remarksForRejection;
          obj.itemId = data;
          changePrStatusRemarks(obj);
          break;
        case "Travel Intimation":
          travelCheckPoint.map((item, i) => {
            let Object = { status: "", id: "" };
            Object.status = dataTravel?.details?.status == "CA" ? "C" : "A";
            Object.id = item;
            arrTravelData.approval_data.push(Object);
          });
          changeReceiptRemarks(arrTravelData);
          break;
        case "Other Expense":
          travelCheckPoint.map((item, i) => {
            let Object = { status: "", id: "" };
            Object.status = stat;
            Object.id = item;
            arrTravelData.approval_data.push(Object);
          });
          saveRemarksData(arrTravelData);
          break;
        case "Other Advance":
          travelCheckPoint.map((item, i) => {
            let Object = { status: "", id: "" };
            Object.status = stat;
            Object.id = item;
            arrTravelData.approval_data.push(Object);
          });
          approveOtherExpFinal(arrTravelData);
          break;
        case "Expense":
          obj.receipt_id = 0;
          obj.expense_id = data;
          obj.demand_id = 0;
          obj.status = stat;
          changeExpenseRemarks(obj);
          break;
        case "GL Sign-off":
          obj.By = "Reviewer";
          obj.status = stat;
          obj.idList = data;
          changeGLRemarks(obj);
          break;
        case "Material Creation":
          obj.status = stat;
          obj.material_id = data;
          changeMatCreationRemarks(obj);
          break;
        case "Service Entry Sheet":
          obj.status = stat;
          obj.id = data;
          ChangeSesStatus(obj);
        case "HSN":
          obj.status = "APPROVED";
          obj.hsn_id = data;
          changeHsnStatus(obj);
          break;
        case "Source List":
          obj.status = stat;
          obj.sourcelist_ids = data;
          changeSourceListStatus(obj);
          break;
      }
    } else {
      toast.error("Kindly select a record to Approve/Reject");
    }
  };

  const ChangeSesStatus = (data) => {
    changeBulksesstatus(data).then((response) => {
      toast.success("Approved Successfully.");
      window.history.go(-1);
      return false;
    });
  };
  const changeHsnStatus = (data) => {
    HSNService.changeBulkHsnstatus(data).then((response) => {
      toast.success("Approved Successfully.");
      window.history.go(-1);
      return false;
    });
  };

  const changeSourceListStatus = (data) => {
    SourceList.changeBulkSourceListstatus(data).then((response) => {
      toast.success("Approved Successfully.");
      window.history.go(-1);
      return false;
    });
  };

  const changeReceiptRemarks = (data) => {
    setIsLoading(true);
    updateStatus(data)
      .then((response) => {
        toast.success("Approved Successfully.");
        setIsLoading(false);
        if (nonExceptionCount - travelCheckPoint.length == 0) {
          if (exceptionCount > 0) {
            setFilterDataValue({ is_exception: "true" });
            setCurrentsubTypename("Travel Intimation Exception");
            getApproval(
              currentsubType,
              currentPage,
              pageSizeNo,
              "-id",
              true,
              "",
              true
            );
          } else {
            navigate("/dashboard");
          }
        } else {
          setNonExceptionCount(nonExceptionCount - travelCheckPoint.length);
          setTravelCheckPoint([]);
          getApproval(
            currentsubType,
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            filterDataValue?.is_exception
          );
        }
        return false;
      })
      .catch((error) => {
        setIsLoading(false);
        toast.success(error?.response?.data?.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeExpenseRemarks = (data) => {
    setIsLoading(true);
    cashbookService
      .updateStatus(data)
      .then((response) => {
        // getReceiptDataById("", 1, 10, "id", true, id);
        setIsLoading(false);
        toast.success("Approved Successfully.");
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        setIsLoading(false);
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeGLRemarks = (data) => {
    GLService.Changeuserstaus(data)
      .then((response) => {
        // getReceiptDataById("", 1, 10, "id", true, id);

        toast.success("Approved Successfully.");
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeMatCreationRemarks = (data) => {
    changebulkmatstatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeLoanRequestRemarks = (data) => {
    changeLoanRequestStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeReimbursementStatusBulk = (data) => {
    changeReimbursementStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const changePrStatusRemarks = async (obj) => {
    try {
      const response = await changeprstatus(obj);
      if (response.data.code === 200) {
        toast.success("PR Item Status Updated");
        window.history.go(-1);
        return false;
      } else {
        toast.warn(response.data.message);
      }
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const saveMultiApprove = (data) => {
    HeaderService.saveMultiApproveList(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveRemarksData = (data) => {
    setIsLoading(true);
    TravelListingAPI.saveRemarksData(data)
      .then((response) => {
        setIsLoading(false);
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const approveOtherExpFinal = (data) => {
    TravelListingAPI.approveOtherExpFinal(data)
      .then((response) => {
        toast.success(response.data.message);
        if (activeTab?.approvals?.result?.length == 1) {
          window.history.go(-1);
        } else {
          getApproval(
            currentsubType,
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            filterDataValue?.is_exception
          );
        }
        return false;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveOTVMultiApprove = (data) => {
    OtvService.saveApprovalData(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeSalaryAdvRequestRemarks = (data) => {
    // setIsLoading(true);
    changeSalaryAdvRequestStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        // setIsLoading(false);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeShiftChangeRemarks = (data) => {
    changeShiftStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const newDateData = new Date();
  const fdd = moment(newDateData).format("YYYY-MM-DD");
  const getExportDetailsCB = () => {
    cashbookService
      .getExportValueCBReport(
        `from_date=${fdd}&to_date=${fdd}&file_type=excel&user_id=${userData?.id}`
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookReport.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getExportDetailsCBPdf = () => {
    cashbookService
      .getExportValueCBReport(
        `from_date=${fdd}&to_date=${fdd}&file_type=pdf&user_id=${userData?.id}`
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookReport.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const changeVPFRequestRemarks = (data) => {
    changeVPFRequestStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeLeaveRemarks = (data) => {
    changeLeaveStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveYtdValue = (id, year) => {
    HeaderService.getYtdValue(id, year)
      .then((response) => {
        setYearYtd(response?.data?.dataList?.result);
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    saveYtdValue(userData.id, new Date().getFullYear());
  }, [userData]);

  const changeNewsRemarks = (data) => {
    changeNewsStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changePolicyRemarks = (data) => {
    changePolicyStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const changeBrandRemarks = (data) => {
    changeBrandingStatus(data)
      .then((response) => {
        toast.success(response.data.message);
        window.history.go(-1);
        return false;
      })
      .catch((error) => {
        toast.success(error.response.data.detail);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearch("");
      let id = location?.state?.subtypeid;
      getApproval(
        id,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        filterDataValue?.is_exception
      );
    }
  };

  const mainDataFilter = () => {
    const is_exception = filterDataValue.is_exception;
    getApproval(
      currentsubType,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      filterDataValue?.is_exception
    );
    filterclose();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(filterDataValue));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setFilterDataValue({
      ...filterDataValue,
      is_exception: "false",
    });
    filterclose();
    getApproval(
      currentsubType,
      currentPage,
      pageSizeNo,
      "-created_at",
      true,
      "",
      filterDataValue?.is_exception
    );
  };

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
  }
  const handleAllData2 = (e, id, data) => {
    selectDataToPost(e, data);
    setDataTravel(data);
    if (e.target.checked) {
      setPostingData([...postingData, id]);
      setTravelCheckPoint([...travelCheckPoint, id]);
    } else {
      const index = postingData.indexOf(id);
      const index1 = travelCheckPoint.indexOf(id);
      if (index > -1) {
        let data = postingData;
        data.splice(index, 1);
        setPostingData(data);
      }
      if (index1 > -1) {
        let data1 = travelCheckPoint;
        data1.splice(index, 1);
        setTravelCheckPoint(data1);
      }
    }
  };

  const changeData = (num) => {
    const result = Number(num).toFixed(2);
    return result;
  };

  useEffect(() => {
    if (
      currentsubTypename === "Travel Intimation Exception" ||
      currentsubTypename === "Other Expense Exception"
    ) {
      setFilterDataValue({ ...filterDataValue, is_exception: "true" });
      getApproval(
        currentsubType,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        "true"
      );
    } else if (
      currentsubTypename === "Travel Intimation" ||
      currentsubTypename === "Other Expense"
    ) {
      setFilterDataValue({ ...filterDataValue, is_exception: "false" });
      getApproval(
        currentsubType,
        currentPage,
        pageSizeNo,
        "-created_at",
        true,
        "",
        "false"
      );
    }
  }, [currentsubTypename]);

  // useEffect(() => {
  //   if (currentsubTypename === "Other Expense") {
  //     setOtherfilterDataValue({
  //       ...otherfilterDataValue,
  //       is_exception: "false",
  //     });
  //     getApproval(
  //       currentsubType,
  //       currentPage,
  //       pageSizeNo,
  //       "-created_at",
  //       true,
  //       "",
  //       "false"
  //     );
  //   } else if (currentsubTypename === "Other Expense Exception") {
  //     setOtherfilterDataValue({
  //       ...otherfilterDataValue,
  //       is_exception: "true",
  //     });
  //     getApproval(
  //       currentsubType,
  //       currentPage,
  //       pageSizeNo,
  //       "-created_at",
  //       true,
  //       "",
  //       "true"
  //     );
  //   }
  // }, [currentsubTypename]);

  useEffect(() => {
    let data = [];
    activeTab?.approvals?.result?.map((y) => {
      [y?.details]?.map((x) => {
        if (x?.length !== 0) {
          data?.push(x);
        }
      });
    });
    setErrorTravelShow(data);
  }, [activeTab]);

  const getTotalApprovedAmt = (arr) => {
    let total = 0;
    arr.forEach((obj) => (total += obj.approved_amount));
    return total;
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div class="col-md-3">
          <h3 class="policyhead page-headapprover">
            <i class="far fa-arrow-alt-circle-right"></i> {activeTab?.type_name}
          </h3>
          <ul class="policylistnames mt-0">
            {activeTab?.sub_type?.map((s) => (
              <li class={s?.sub_type_id == currentsubType ? "active" : ""}>
                <Link
                  onClick={() =>
                    curentsubtype(s?.sub_type_id, s?.sub_type_name)
                  }
                >
                  {capitalizeFirstLetter(s?.sub_type_name) === "Demand"
                    ? "Cash Demand"
                    : capitalizeFirstLetter(s?.sub_type_name) === "Otv"
                    ? "OTV/NONPO"
                    : capitalizeFirstLetter(s?.sub_type_name) === "Expense"
                    ? "Cash Expense"
                    : capitalizeFirstLetter(s?.sub_type_name) === "Receipt"
                    ? "Cash Receipt"
                    : s?.sub_type_name === "Purchase Requisition"
                    ? "PR"
                    : s?.sub_type_name === "PO"
                    ? "PO"
                    : s?.sub_type_name === "Vehicle Insurance"
                    ? "Vehicle Insurance"
                    : s?.sub_type_name === "Other Advance"
                    ? "Other Advance"
                    : s?.sub_type_name === "Vehicle Registration"
                    ? "Vehicle Registration"
                    : s?.sub_type_name === "Travel Expense Exception"
                    ? "Travel Expense Ex."
                    : s?.sub_type_name === "Travel Expense"
                    ? "Travel Expense"
                    : s?.sub_type_name?.toLowerCase() == "other expense" ||
                      s?.sub_type_name?.toLowerCase() ==
                        "other expense exception" ||
                      s?.sub_type_name?.toLowerCase() == "other expense ssc"
                    ? s?.sub_type_name
                    : capitalizeFirstLetter(s?.sub_type_name)}
                  {s?.sub_type_name?.length < 15 ? " Approval" : " App."}
                </Link>{" "}
                <span class="numberpurchse float-right mr-2">
                  {s?.approvals_count}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div
              class="col-md-5"
              style={{
                fontSize: "14px",
                color: "grey",
                float: "right",
                fontStyle: "italic",
                fontWeight: "bold",
                color: "#0195d4",
              }}
            >
              <p>*Click 'approve' to approve cancellation requests.</p>
            </div>
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  {currentsubTypename === "INVOICE" ? (
                    <h4 class="inner-page-title">Pending Invoice</h4>
                  ) : (
                    <h4 class="inner-page-title">
                      {capitalizeFirstLetter() === "Otv"
                        ? "OTV/NONPO"
                        : capitalizeFirstLetter(currentsubTypename) === "Demand"
                        ? "Cash Demand"
                        : capitalizeFirstLetter(currentsubTypename) ===
                          "Expense"
                        ? "Cash Expense"
                        : capitalizeFirstLetter(currentsubTypename) ===
                          "Receipt"
                        ? "Cash Receipt"
                        : currentsubTypename === "Purchase Requisition"
                        ? "Purchase Requisition"
                        : currentsubTypename === "PO"
                        ? "PO"
                        : currentsubTypename === "Travel Expense SSC"
                        ? "Travel Expense SSC"
                        : currentsubTypename === "Travel Expense"
                        ? "Travel Expense"
                        : currentsubTypename === "Travel Intimation"
                        ? "Travel Intimation"
                        : currentsubTypename === "Travel Intimation Exception"
                        ? "Travel Intimation Exception"
                        : currentsubTypename === "Other Advance"
                        ? "Other Advance"
                        : currentsubTypename === "Vehicle Insurance"
                        ? currentsubTypename
                        : currentsubTypename === "Vehicle Registration"
                        ? currentsubTypename
                        : currentsubTypename === "Travel Expense Exception"
                        ? currentsubTypename
                        : currentsubTypename?.toLowerCase() ==
                            "other expense" ||
                          currentsubTypename?.toLowerCase() ==
                            "other expense exception" ||
                          currentsubTypename?.toLowerCase() ==
                            "other expense ssc"
                        ? currentsubTypename
                        : capitalizeFirstLetter(currentsubTypename)}
                      &nbsp;Approval
                    </h4>
                  )}
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/dashboard">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/dashboard">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Source List</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on
                          <strong>
                            {" "}
                            ID <br /> Requested By,{" "}
                            {currentsubTypename === "Purchase Requisition"
                              ? "SAPPR No."
                              : currentsubTypename === "PO"
                              ? "PO No."
                              : currentsubTypename === "CONTRACT"
                              ? "PO No."
                              : currentsubTypename === "Service Entry Sheet"
                              ? "SES No."
                              : ""}
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on ID, Requested By..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {search !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="col-lg-1"></div>
                {(currentsubTypename == "Travel Intimation" ||
                  currentsubTypename == "Travel Intimation Exception") && (
                  <div className="col-lg-5">
                    <div className="form-group innergroup">
                      <label className="d-block">Status</label>
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={filterDataValue.is_exception}
                        onChange={() => {
                          setCurrentsubTypename("Travel Intimation");
                        }}
                        checked={filterDataValue.is_exception == "false"}
                      />
                      &nbsp;
                      <label className=" mr-2" htmlFor="active">
                        Travel Intimation
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={filterDataValue.is_exception}
                        onChange={(e) => {
                          setCurrentsubTypename("Travel Intimation Exception");
                        }}
                        checked={filterDataValue?.is_exception == "true"}
                      />
                      &nbsp;
                      <label htmlFor="Inactive">
                        Travel Intimation Exception
                      </label>
                    </div>
                  </div>
                )}
                {(currentsubTypename == "Other Expense" ||
                  currentsubTypename == "Other Expense Exception") && (
                  <div className="col-lg-5">
                    <div className="form-group innergroup">
                      <label className="d-block">Status</label>
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={otherfilterDataValue?.is_exception}
                        onChange={() => {
                          // setOtherfilterDataValue({
                          //   ...otherfilterDataValue,
                          //   is_exception: "false",
                          // });
                          // setCurrentsubTypename("Other Expense");
                          curentsubtype2("Other Expense");
                        }}
                        checked={filterDataValue.is_exception == "false"}
                      />
                      &nbsp;
                      <label className=" mr-4" htmlFor="active">
                        Non Exception
                      </label>
                      &nbsp;
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={otherfilterDataValue.is_exception}
                        onChange={(e) => {
                          // setOtherfilterDataValue({
                          //   ...otherfilterDataValue,
                          //   is_exception: "true",
                          // });
                          // setCurrentsubTypename("Other Expense Exception");
                          curentsubtype2("Other Expense Exception");
                        }}
                        checked={filterDataValue.is_exception == "true"}
                      />
                      &nbsp;
                      <label htmlFor="Inactive">Exception</label>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div class="col-lg-2"></div>
                  {capitalizeFirstLetter(currentsubTypename) === "Expense" ? (
                    <>
                      <div class="col-lg-4">
                        <div class="inline-spacing btn-betweenspaing">
                          <Dropdown show>
                            <Dropdown.Toggle
                              variant="primary-inner dropdownbtn"
                              id="dropdown-basic"
                            >
                              <img
                                src="images/export.png"
                                alt=""
                                class="mr-2"
                              />{" "}
                              Export
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#">
                                <button
                                  // disabled={
                                  //   data?.query?.length !== 0 ? false : true
                                  // }
                                  style={{ border: 0, background: "none" }}
                                  onClick={() => getExportDetailsCB()}
                                >
                                  <i className="far fa-file-excel"></i>Excel
                                </button>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="inline-spacing btn-betweenspaing">
                          <Dropdown show>
                            <Dropdown.Toggle
                              variant="primary-inner dropdownbtn"
                              id="dropdown-basic"
                            >
                              <img
                                src="images/export.png"
                                alt=""
                                class="mr-2"
                              />{" "}
                              Print
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#">
                                <button
                                  // disabled={
                                  //   data?.query?.length !== 0 ? false : true
                                  // }
                                  style={{ border: 0, background: "none" }}
                                  onClick={() => getExportDetailsCBPdf()}
                                >
                                  <i className="far fa-file-excel"></i>Print
                                </button>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-12 text-sm-right">
                  {currentsubTypename !== "" && errorTravelShow?.length > 0 ? (
                    (currentType === "Business Transaction" &&
                      currentsubTypename === "JV") ||
                    currentsubTypename === "Shift" ||
                    currentsubTypename === "Loan" ||
                    currentsubTypename === "Salary Advance" ||
                    currentsubTypename === "Leave" ||
                    currentsubTypename === "Branding" ||
                    currentsubTypename === "News, Notice & Popup" ||
                    currentsubTypename === "Policy" ||
                    currentsubTypename === "Mobile" ||
                    currentsubTypename === "Marriage" ||
                    currentsubTypename === "OTV" ||
                    currentsubTypename === "Purchase Requisition" ||
                    currentsubTypename === "Source List" ||
                    currentsubTypename === "HSN" ||
                    currentsubTypename === "Material Creation" ||
                    currentsubTypename === "Expense" ||
                    (currentsubTypename === "Travel Intimation" &&
                      filterDataValue.is_exception === "false") ||
                    currentsubTypename == "Other Expense" ||
                    currentsubTypename == "Other Advance" ? (
                      <>
                        {isLoading ? (
                          <Spinner
                            animation="border"
                            variant="primary"
                            as="span"
                            role="status"
                            aria-hidden="true"
                          ></Spinner>
                        ) : (
                          <>
                            <button
                              class="btn btn-success"
                              type="button"
                              onClick={() => {
                                if (
                                  currentsubTypename === "Purchase Requisition"
                                ) {
                                  setRemarksModal(true);
                                  setRemarkStatus("A");
                                } else {
                                  submitAction(postingData, "A");
                                }
                              }}
                            >
                              <i class="far fa-check-circle"></i> Approve
                            </button>
                          </>
                        )}
                        {(currentsubTypename === "Purchase Requisition" ||
                          gLReviewerFlag) && (
                          <button
                            class="btn btn-danger ml-2"
                            type="button"
                            onClick={() => {
                              if (
                                currentsubTypename === "Purchase Requisition"
                              ) {
                                setRemarksModal(true);
                                setRemarkStatus("D");
                              } else {
                                submitAction(postingData, "D");
                              }
                            }}
                          >
                            <i class="far fa-check-circle"></i> Reject
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div class="table-responsive common-approvaltable">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    {currentsubTypename !== "" ? (
                      (currentType === "Business Transaction" &&
                        currentsubTypename === "JV") ||
                      currentsubTypename === "Shift" ||
                      currentsubTypename === "Loan" ||
                      currentsubTypename === "Salary Advance" ||
                      currentsubTypename === "Leave" ||
                      currentsubTypename === "Branding" ||
                      currentsubTypename === "News, Notice & Popup" ||
                      currentsubTypename === "Policy" ||
                      currentsubTypename === "Mobile" ||
                      currentsubTypename === "Marriage" ||
                      currentsubTypename === "OTV" ||
                      currentsubTypename === "Purchase Requisition" ||
                      currentsubTypename === "Source List" ||
                      currentsubTypename === "HSN" ||
                      currentsubTypename === "Material Creation" ||
                      currentsubTypename === "Expense" ||
                      (currentsubTypename === "Travel Intimation" &&
                        filterDataValue.is_exception === "false") ||
                      currentsubTypename == "Other Expense" ||
                      currentsubTypename == "Other Advance" ? (
                        <th>
                          <label class="logCheck d-inline-block">
                            <input
                              type="checkbox"
                              name="permission"
                              onClick={(e) => {
                                handleAllData(e);
                              }}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </th>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <th>Action</th>
                    {currentType === "Assignments" && (
                      <>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Notification Trigger Time</th>
                      </>
                    )}
                    {(currentsubTypename == "Travel Expense" ||
                      currentsubTypename == "Travel Expense Exception") && (
                      <>
                        <th>Trip Number</th>
                      </>
                    )}
                    {currentsubTypename === "Leave" && <th>Request Type</th>}
                    {currentsubTypename == "Policy" ? (
                      <th>Policy ID</th>
                    ) : currentsubTypename === "Branding" ? (
                      <th>Branding ID</th>
                    ) : currentsubTypename == "Source List" ? (
                      <th>Source List ID</th>
                    ) : currentsubTypename == "Service Entry Sheet" ? (
                      <th>SES ID</th>
                    ) : currentsubTypename === "Shift" ? (
                      <>
                        <th>Requested Shift ID</th>
                        <th>Requested By</th>
                        <th>Requested Date</th>
                        <th>Assign Shift</th>
                        <th>Requested Shift</th>
                        <th>Reason</th>
                      </>
                    ) : currentsubTypename === "Loan" ? (
                      <th>Requested Loan ID</th>
                    ) : currentsubTypename === "Salary Advance" ? (
                      <th>Requested Salary Advance ID</th>
                    ) : currentsubTypename === "VPF" ? (
                      <th>Requested VPF ID</th>
                    ) : currentsubTypename !== "Leave" &&
                      currentsubTypename !== "Travel Expense SSC" &&
                      currentsubTypename !== "Travel Expense Exception" &&
                      currentsubTypename == "Travel Intimation" ? (
                      <th>Travel Intimation No.</th>
                    ) : currentsubTypename == "Travel Expense SSC" ? (
                      <th>Emp ID</th>
                    ) : (
                      currentsubTypename !== "Travel SSC" &&
                      currentsubTypename !== "Leave" &&
                      currentsubTypename !== "Travel Expense Exception" && (
                        <th>ID</th>
                      )
                    )}
                    {/* {currentsubTypename !== "" ? (
                      currentsubTypename === "PO" ||
                      currentsubTypename === "CONTRACT" ? (
                        ""
                      ) : (
                        <th>PO No.</th>
                      )
                    ) : (
                      ""
                    )} */}

                    {currentsubTypename === "Travel Expense SSC" ? (
                      <>
                        <th>Name</th>
                        <th>SAP Trip No.</th>
                        <th>Courier/Submit Date</th>
                        <th>Recieved On</th>
                        <th>Processed On</th>
                      </>
                    ) : (
                      <></>
                    )}

                    {currentsubTypename === "PO" ||
                    currentsubTypename === "CONTRACT" ? (
                      <th>PO No.</th>
                    ) : (
                      ""
                    )}
                    {currentsubTypename === "PO" ||
                    currentsubTypename === "CONTRACT" ? (
                      <th>Vendor Name</th>
                    ) : (
                      ""
                    )}

                    {currentsubTypename === "News, Notice & Popup" && (
                      <th>Type</th>
                    )}
                    {currentsubTypename == "Vehicle Registration" && (
                      <th>Vehicle Type</th>
                    )}
                    {(currentsubTypename == "Vehicle Registration" ||
                      currentsubTypename == "Vehicle Insurance") && (
                      <>
                        <th>Vehicle Number</th>
                        <th>Valid From</th>
                        <th>Valid Till</th>
                      </>
                    )}

                    {currentsubTypename?.toLowerCase() ==
                      "travel expense ssc" && <th>Sent by</th>}

                    {currentsubTypename !== "Purchase Requisition" &&
                      currentsubTypename !== "GL Sign-off" &&
                      currentsubTypename?.toLowerCase() !==
                        "travel expense ssc" &&
                      currentsubTypename !== "Shift" && <th>Requested By</th>}
                    {currentsubTypename === "Loan" && (
                      <>
                        <th>Loan Amount</th>
                        <th>Loan Type</th>
                        <th>Last Approved By</th>
                        <th>Exception Reason</th>
                      </>
                    )}
                    {currentsubTypename === "Salary Advance" && (
                      <>
                        <th>Amount</th>
                        <th>Last Approved By</th>
                      </>
                    )}
                    {currentsubTypename === "PO" ||
                    currentsubTypename === "CONTRACT" ? (
                      <th>Total Price</th>
                    ) : (
                      ""
                    )}
                    {currentsubTypename === "Service Entry Sheet" && (
                      <>
                        <th>Po No.</th>
                        <th>Short Text</th>
                        <th>Gross Price</th>
                      </>
                    )}
                    {currentsubTypename !== "Purchase Requisition" &&
                      currentsubTypename !== "GL Sign-off" &&
                      currentsubTypename !== "Expense" &&
                      currentsubTypename !== "Travel Intimation Exception" &&
                      currentsubTypename !== "Travel Intimation" && (
                        <th>Requested At</th>
                      )}
                    {currentsubTypename === "PO" ||
                    currentsubTypename === "CONTRACT" ? (
                      <th>Status</th>
                    ) : (
                      ""
                    )}
                    {currentsubTypename === "INVOICE" ? (
                      <th>Total Price</th>
                    ) : (
                      ""
                    )}
                    {currentsubTypename === "JV" ? <th>Company Code</th> : ""}
                    {currentsubTypename === "JV" ? <th>SubAmount</th> : ""}
                    {currentsubTypename === "JV" ? <th>Remarks</th> : ""}
                    {currentsubTypename === "GL Sign-off" ? (
                      <>
                        <th>Gl Desc</th>
                        <th>User Name</th>
                        <th>Reviewer Name</th>
                        <th>SAP Balance</th>
                        <th>Confirmed Balance</th>
                        <th>Variance</th>
                      </>
                    ) : (
                      ""
                    )}
                    {currentsubTypename === "GL Sign-off" ? (
                      <th>Group Name</th>
                    ) : (
                      ""
                    )}
                    {/* {currentsubTypename === "GL Sign-off" ? (
                      <th>Sap Amount</th>
                    ) : (
                      ""
                    )} */}
                    {currentsubTypename === "GL Sign-off" ? (
                      <>
                        <th>Sap Location</th>
                        <th>Month</th>
                        <th>Requested At</th>
                      </>
                    ) : (
                      ""
                    )}

                    {currentsubTypename === "Leave" && (
                      <>
                        <th>Leave Type</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Leave Days</th>
                        <th>Reason</th>
                      </>
                    )}
                    {(currentsubTypename === "Demand" ||
                      currentsubTypename === "Expense") && (
                      <>
                        <th>GL Description</th>
                        <th>Amount</th>
                        <th>Item No.</th>
                        <th>Expense GL</th>
                      </>
                    )}

                    {currentsubTypename === "JV" && <th>Ageing Days</th>}
                    {(currentsubTypename === "OTV" ||
                      currentsubTypename === "INVOICE") && (
                      <th>Invoice Number</th>
                    )}
                    {currentsubTypename === "OTV" && <th>Type</th>}
                    {currentsubTypename === "OTV" && <th>GSTCode</th>}
                    {currentsubTypename === "OTV" && <th>Amount</th>}
                    {currentsubTypename === "OTV" && <th>Vendor</th>}

                    {(currentsubTypename === "OTV" ||
                      currentsubTypename === "INVOICE") && (
                      <th>Invoice Date</th>
                    )}

                    {currentsubTypename === "Service Entry Sheet" && (
                      <th>Date</th>
                    )}
                    {currentsubTypename === "Source List" && (
                      <th>Material Name</th>
                    )}
                    {currentsubTypename === "Source List" && (
                      <th>Vendor Name</th>
                    )}
                    {currentsubTypename === "Mobile" && (
                      <>
                        <th>Amount</th>
                        <th>Bill Date</th>
                      </>
                    )}
                    {currentsubTypename === "Marriage" && (
                      <>
                        <th>Marriage Date</th>
                      </>
                    )}
                    {(currentsubTypename?.toLowerCase() === "other expense" ||
                      currentsubTypename?.toLowerCase() ===
                        "other expense exception" ||
                      currentsubTypename?.toLowerCase() ===
                        "other expense ssc") && (
                      <>
                        <th>Expense Type</th>
                        <th>Amount</th>

                        {userData.groups.map((data) =>
                          data?.name.toLowerCase()?.includes("ssc manager") ? (
                            <th>Approved Amount</th>
                          ) : (
                            <></>
                          )
                        )}

                        <th>For Month Of</th>
                        <th>Print</th>
                      </>
                    )}
                    {currentsubTypename == "Purchase Requisition" && (
                      <>
                        <th>SAP PR NO.</th>
                        <th>Requested By</th>
                        <th>Material</th>
                        <th>Material Desc</th>
                        <th>Price/Unit</th>
                        <th>Total Price</th>
                        <th>Currency</th>
                        <th>Quantity</th>
                        <th>PR Type</th>
                        <th>Purchase Group</th>
                        <th>Source</th>
                        <th>Plant</th>
                        <th>Requested At</th>
                        <th>Pending Days</th>
                      </>
                    )}
                    {currentsubTypename == "HSN" && (
                      <>
                        <th>HSN Code</th>
                        <th>Material</th>
                        <th>Plant</th>
                        <th>Description</th>
                      </>
                    )}
                    {(currentsubTypename == "Travel Expense" ||
                      currentsubTypename == "Travel Expense Exception") && (
                      <>
                        <th>Cost Center</th>
                        <th>Departure Date</th>
                        <th>Arrival Date</th>
                        <th>Location</th>
                        <th>Deviation</th>
                      </>
                    )}
                    {currentsubTypename == "Material Creation" && (
                      <>
                        <th>Temporary Code</th>
                        <th>Description</th>
                        <th>Plant</th>
                        <th>Group</th>
                        <th>Type</th>
                        <th>Unit</th>
                      </>
                    )}
                    {currentsubTypename == "Expense" && (
                      <>
                        <th>Frequency Type</th>
                        <th>Frequency Limit</th>
                        <th>Current Balance</th>
                        <th>Cash Limit</th>
                        <th>Invoice No.</th>
                        <th>Invoice Date</th>
                        <th>Description</th>
                        <th>Opening Balance</th>
                        <th>Provision Balance</th>
                        <th>Requested At</th>
                      </>
                    )}
                    {(currentsubTypename == "Travel Intimation Exception" ||
                      currentsubTypename == "Travel Intimation") && (
                      <>
                        {" "}
                        <th>Travel Type</th>
                        <th>From</th>
                        <th>Till</th>
                        <th>Advance</th>
                        <th>Location</th>
                        <th>Ytd</th>
                        <th>Days</th>
                        <th>Exception</th>
                        <th>Status</th>
                        <th>Requested At</th>
                      </>
                    )}
                    {currentsubTypename == "Other Advance" && (
                      <>
                        <th>Advance Amount</th>
                        <th>Settlement Date</th>
                        {/* <th>Advance Amount Reason</th> */}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {activeTab?.approvals?.result?.length > 0 &&
                  errorTravelShow?.length > 0 ? (
                    activeTab?.approvals?.result?.map(
                      (x) =>
                        x?.details !== "" &&
                        x?.details !== undefined &&
                        x?.details?.length !== 0 ? (
                          <tr>
                            {currentsubTypename !== "" ? (
                              (currentType === "Business Transaction" &&
                                currentsubTypename === "JV") ||
                              currentsubTypename === "Shift" ||
                              currentsubTypename === "Loan" ||
                              currentsubTypename === "Salary Advance" ||
                              currentsubTypename === "Leave" ||
                              currentsubTypename === "Branding" ||
                              currentsubTypename === "News, Notice & Popup" ||
                              currentsubTypename === "Policy" ||
                              currentsubTypename === "OTV" ||
                              currentsubTypename === "Mobile" ||
                              currentsubTypename === "Marriage" ||
                              currentsubTypename === "Purchase Requisition" ||
                              currentsubTypename === "Source List" ||
                              currentsubTypename === "HSN" ||
                              currentsubTypename === "Material Creation" ||
                              currentsubTypename === "Expense" ||
                              (currentsubTypename === "Travel Intimation" &&
                                x?.details?.is_exception === false) ||
                              currentsubTypename === "Loan" ||
                              currentsubTypename === "Salary Advance" ||
                              currentsubTypename == "Other Expense" ||
                              currentsubTypename == "Other Advance" ? (
                                <td>
                                  <label class="logCheck d-inline-block">
                                    <input
                                      type="checkbox"
                                      name="permission"
                                      // onClick={(e) => {
                                      //   selectDataToPost(e, x);
                                      // }}
                                      // disabled={currentsubTypename === "Loan" && x?.last_approved === null}
                                      checked={x?.checked === "Y"}
                                      onClick={(e) => {
                                        handleAllData2(
                                          e,
                                          currentsubTypename === "JV"
                                            ? x?.details?.jv_approve_id
                                            : x?.transaction_id,
                                          x
                                        );
                                      }}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </td>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            {x?.details !== "" && x?.details !== undefined && (
                              <td>
                                <Link
                                  to={
                                    currentsubTypename == "Survey" ||
                                    currentsubTypename == "Polls" ||
                                    currentsubTypename == "Assessment" ||
                                    currentType === "Assignments"
                                      ? `/survey-approvals/${x?.transaction_id}`
                                      : currentsubTypename ==
                                        "Service Entry Sheet"
                                      ? "/approve-service"
                                      : currentsubTypename ==
                                        "Material Creation"
                                      ? "/approve-mat-req"
                                      : currentsubTypename ==
                                        "Purchase Requisition"
                                      ? "/view-pr-approval"
                                      : currentsubTypename == "Source List"
                                      ? "/approve-source"
                                      : currentsubTypename == "PO"
                                      ? "/viewpurchase/:id"
                                      : currentsubTypename ==
                                        "Travel Expense SSC"
                                      ? `/travel-check-expense?id=${x?.details?.id}`
                                      : currentsubTypename?.toLowerCase() ==
                                          "other expense" ||
                                        currentsubTypename?.toLowerCase() ===
                                          "other expense exception" ||
                                        currentsubTypename?.toLowerCase() ==
                                          "other expense ssc"
                                      ? x?.details[0]?.status == "A"
                                        ? `/otherexp-check-expense?id=${x?.transaction_id}`
                                        : `/viewOther`
                                      : currentsubTypename == "Policy" ||
                                        currentsubTypename === "Branding" ||
                                        currentsubTypename ===
                                          "News, Notice & Popup"
                                      ? "/view-approve-cms"
                                      : currentsubTypename == "CONTRACT"
                                      ? "/view-ca"
                                      : currentsubTypename ==
                                          "Travel Intimation Exception" ||
                                        currentsubTypename ==
                                          "Travel Intimation"
                                      ? "/view-travel"
                                      : currentsubTypename ==
                                          "Travel Expense" ||
                                        currentsubTypename ==
                                          "Travel Expense Exception"
                                      ? "/viewExpenseTravel"
                                      : currentsubTypename === "OTV"
                                      ? `/viewotv/${x?.transaction_id}`
                                      : currentsubTypename === "JV"
                                      ? `/viewjv/${x?.transaction_id}`
                                      : currentsubTypename === "Receipt" ||
                                        currentsubTypename === "Expense" ||
                                        currentsubTypename === "Demand"
                                      ? "/view-approve-cashbook-modules"
                                      : currentsubTypename ===
                                        "Higher Education"
                                      ? "/higher-edu-approval"
                                      : currentsubTypename ===
                                        "Higher Education Reimbursement"
                                      ? "/higher-edu-claim-approval"
                                      : currentsubTypename ===
                                        "Vehicle Registration"
                                      ? "/vehicle-check"
                                      : currentsubTypename ===
                                        "Vehicle Insurance"
                                      ? "/vehicle-check-insurance"
                                      : currentsubTypename === "GL Sign-off"
                                      ? "/approve-gi"
                                      : currentsubTypename === "INVOICE"
                                      ? "/vendor-invoice-pending"
                                      : currentsubTypename === "Leave" ||
                                        currentsubTypename === "Shift" ||
                                        currentsubTypename === "Loan" ||
                                        currentsubTypename ===
                                          "Salary Advance" ||
                                        currentsubTypename === "VPF"
                                      ? "/approveEss"
                                      : currentsubTypename === "HSN"
                                      ? "/view-hsn-approval"
                                      : currentsubTypename === "Other Advance"
                                      ? "/approveOtherAdv"
                                      : currentsubTypename === "Mobile" ||
                                        currentsubTypename === "Marriage" ||
                                        currentsubTypename === "Notice"
                                      ? `/reimbursement-approval/${currentsubTypename}`
                                      : currentsubTypename === "Major Product"
                                      ? `/mymarket/majorproducts/table?mode=approve&id=${x?.transaction_id}`
                                      : currentsubTypename === "Market Size" ||
                                        "Channel Size" ||
                                        "Market Player" ||
                                        "Crop Pattern" ||
                                        "Crop Scenario" ||
                                        "Competing Brand" ||
                                        "Competing Molecule"
                                      ? `/mymarket/${currentsubTypename
                                          ?.split(" ")
                                          ?.join("")
                                          ?.toLowerCase()}/table?mode=approve&id=${
                                          x?.transaction_id
                                        }`
                                      : currentsubTypename ===
                                          "Overall Strategy" ||
                                        "Product Strategy"
                                      ? `/mystrategy/${currentsubTypename
                                          ?.split(" ")
                                          ?.join("")
                                          ?.toLowerCase()}/table?mode=approve&id=${
                                          x?.transaction_id
                                        }`
                                      : currentsubTypename === "Crop Plan" ||
                                        "Sale Plan" ||
                                        "Detailed Sale Plan" ||
                                        "Field Work Plan" ||
                                        "Expense Plan" ||
                                        "Pop Item"
                                      ? `/myplan/${currentsubTypename
                                          ?.split(" ")
                                          ?.join("")
                                          ?.toLowerCase()}/table?mode=approve&id=${
                                          x?.transaction_id
                                        }`
                                      : ""
                                  }
                                  state={{
                                    from: x?.transaction_id,
                                    subId: currentsubType,
                                    other_exp_user_org:
                                      x?.details[0]?.expense_type?.toLowerCase() ==
                                      "petty"
                                        ? x?.details[0]?.organisation_code +
                                          "PE"
                                        : x?.details[0]
                                            ?.user_organisation_code + "OE",
                                    exp_uid: x?.created_by_id,
                                    Itemsflag: true,
                                    view_level: "item",
                                    flag: false,
                                    approval_flag: true,
                                    master_flag: false,
                                    otherExpData:
                                      currentsubTypename === "Other Advance"
                                        ? x
                                        : "",
                                    pr_mast_id:
                                      x?.details?.length > 0
                                        ? x?.details[0]?.pr_id
                                        : "",
                                    type:
                                      currentsubTypename === "Policy"
                                        ? "Policy"
                                        : currentsubTypename === "Branding"
                                        ? "Branding"
                                        : currentsubTypename ===
                                          "News, Notice & Popup"
                                        ? "News, Notice & Popup"
                                        : currentsubTypename === "Leave"
                                        ? "Leave"
                                        : currentsubTypename === "Shift"
                                        ? "Shift"
                                        : currentsubTypename === "Loan"
                                        ? "Loan"
                                        : currentsubTypename ===
                                          "Salary Advance"
                                        ? "Salary Advance"
                                        : currentsubTypename === "VPF"
                                        ? "VPF"
                                        : currentsubTypename === "Receipt"
                                        ? "Receipt"
                                        : currentsubTypename === "Expense"
                                        ? "Expense"
                                        : currentsubTypename === "Demand"
                                        ? "Demand"
                                        : currentsubTypename === "INVOICE"
                                        ? "INVOICE"
                                        : currentsubTypename === "Mobile"
                                        ? "Mobile"
                                        : currentsubTypename === "Marriage"
                                        ? "Marriage"
                                        : currentsubTypename === "Notice"
                                        ? "Notice"
                                        : currentsubTypename ===
                                          "Vehicle Insurance"
                                        ? "Vehicle Insurance"
                                        : currentsubTypename === "HSN"
                                        ? "HSN"
                                        : currentsubTypename === "Other Expense"
                                        ? "Other Expense"
                                        : currentsubTypename ===
                                          "Vehicle Registration"
                                        ? "Vehicle Registration"
                                        : currentsubTypename ===
                                          "Other Expense SSC"
                                        ? "Other Expense SSC"
                                        : currentsubTypename ===
                                          "Other Expense Exception"
                                        ? "Other Expense Exception"
                                        : currentsubTypename ===
                                          "Higher Education"
                                        ? "Higher Education"
                                        : currentsubTypename ===
                                          "Higher Education Reimbursement"
                                        ? "Higher Education Reimbursement"
                                        : currentsubTypename === "Other Advance"
                                        ? "Other Advance"
                                        : currentsubTypename ===
                                          "Travel Expense Exception"
                                        ? "Travel Expense Exception"
                                        : currentsubTypename ===
                                          "Travel Expense"
                                        ? "Travel Expense"
                                        : currentsubTypename ==
                                            "Travel Intimation" ||
                                          currentsubTypename ==
                                            "Travel Intimation Exception"
                                        ? "Tour & Travel"
                                        : "",
                                    isApproval:
                                      currentsubTypename ===
                                        "Higher Education" ||
                                      currentsubTypename ===
                                        "Higher Education Reimbursement"
                                        ? true
                                        : false,
                                    id:
                                      currentsubTypename ===
                                      "Higher Education Reimbursement"
                                        ? x.details.application_request_id
                                        : null,
                                    claimId:
                                      currentsubTypename ===
                                      "Higher Education Reimbursement"
                                        ? x.transaction_id
                                        : null,
                                    isPreview: false,
                                    dataCount:
                                      activeTab?.approvals?.result?.length,
                                    dataCountOtherExpExtras:
                                      dataCountOtherExpExtra,
                                    expCount: exceptionCount,
                                    nonExpCount: nonExceptionCount,
                                    currentsubTypename: currentsubTypename,
                                  }}
                                  class="text-theme"
                                >
                                  <button class="btn btn-blanktd text-success">
                                    <i
                                      class="far fa-eye"
                                      style={{ color: "#0195D4" }}
                                    ></i>
                                  </button>
                                </Link>
                              </td>
                            )}
                            {currentType === "Assignments" && (
                              <>
                                <td>{x?.details?.a_type}</td>
                                <td>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: x?.details?.title,
                                    }}
                                  />
                                </td>
                                <td>
                                  {moment(x?.details?.start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(x?.details?.end_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment
                                    .utc(x?.details?.notification_time)
                                    .format("DD-MM-YYYY h:mm:ss")}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "Leave" && (
                              <td>
                                {x?.details?.length > 0 &&
                                x?.details[0]?.for_cancel === true
                                  ? "Cancel"
                                  : "Apply"}
                              </td>
                            )}
                            {currentsubTypename !== "Leave" &&
                              // (currentsubTypename == "Other Expense SSC" ||
                              //   currentsubTypename == "Travel Expense SSC") &&
                              currentsubTypename !==
                                "Travel Expense Exception" &&
                              currentsubTypename !== "Travel SSC" && (
                                <td>
                                  {currentsubTypename ==
                                  "Purchase Requisition" ? (
                                    <div>
                                      <Link
                                        to="/view-pr-approval"
                                        state={{
                                          from: x?.transaction_id,
                                          subId: currentsubType,
                                          Itemsflag: true,
                                          view_level: "item",
                                          flag: false,
                                          approval_flag: true,
                                          master_flag: false,
                                          pr_mast_id:
                                            x?.details?.length > 0
                                              ? x?.details[0]?.pr_id
                                              : "",
                                        }}
                                        class="text-theme"
                                      >
                                        {x?.transaction_id} -{" "}
                                        {x?.details[0]?.item_no}
                                      </Link>
                                    </div>
                                  ) : currentsubTypename == "Travel" ? (
                                    <div>
                                      {x?.details !== "" &&
                                        x?.details !== undefined && (
                                          <>
                                            PITI{fDate}
                                            {userData?.username}
                                            {x?.transaction_id}
                                          </>
                                        )}
                                    </div>
                                  ) : currentsubTypename?.toLowerCase() ==
                                      "other expense" ||
                                    currentsubTypename?.toLowerCase() ===
                                      "other expense exception" ||
                                    currentsubTypename?.toLowerCase() ==
                                      "other expense ssc" ? (
                                    <div>
                                      {x?.details[0]?.request_id
                                        ? x?.details[0]?.request_id
                                        : (x?.details[0]?.expense_type ==
                                          "petty"
                                            ? `${x?.details[0]?.organisation_code}PE-`
                                            : `${x?.details[0]?.user_organisation_code}OE`) +
                                          x?.transaction_id}
                                    </div>
                                  ) : currentsubTypename ===
                                    "Travel Intimation" ? (
                                    <div>
                                      {/* PIIL{fDate}
                                      {userData?.username}
                                      {x?.transaction_id} */}
                                      {x?.details?.travel_intimation_number}
                                    </div>
                                  ) : currentsubTypename ==
                                    "Higher Education" ? (
                                    <div>HEP{x?.transaction_id}</div>
                                  ) : currentsubTypename == "Other Advance" ? (
                                    <div>
                                      {x?.details[0]?.user_branch}OA
                                      {x?.transaction_id}
                                    </div>
                                  ) : currentsubTypename == "Travel Expense" ||
                                    currentsubTypename ==
                                      "Travel Intimation Exception" ? (
                                    <div>
                                      PIIL{fDate}
                                      {userData?.username}
                                      {x?.transaction_id}
                                    </div>
                                  ) : currentsubTypename ===
                                    "Vehicle Registration" ? (
                                    <div>
                                      {x?.details[0]?.org_code}VR
                                      {x?.transaction_id}
                                    </div>
                                  ) : currentsubTypename ===
                                    "Vehicle Insurance" ? (
                                    <div>
                                      {x?.details[0]?.org_code}VI
                                      {x?.transaction_id}
                                    </div>
                                  ) : currentsubTypename ===
                                    "Travel Expense SSC" ? (
                                    <div>{x?.details?.user_code}</div>
                                  ) : (
                                    <div>{x?.transaction_id}</div>
                                  )}
                                </td>
                              )}
                            {(currentsubTypename == "Travel Expense" ||
                              currentsubTypename ==
                                "Travel Expense Exception") && (
                              <>
                                <td>{x?.details[0]?.sap_trip_number}</td>
                              </>
                            )}
                            {currentsubTypename === "Travel Expense SSC" ? (
                              <>
                                <td>{x?.details?.user_name}</td>
                                <td>{x?.details?.sap_trip_number}</td>
                                <td>
                                  {x?.details?.claim_date
                                    ? moment
                                        .parseZone(x?.details?.claim_date)
                                        .format("DD-MM-YYYY hh:mm A")
                                    : "-"}
                                </td>
                                <td>
                                  {x?.details?.is_picked_at
                                    ? moment
                                        .parseZone(x?.details?.is_picked_at)
                                        .format("DD-MM-YYYY hh:mm A")
                                    : "-"}
                                </td>
                                <td>
                                  {/* {x?.details?.ssc_user_approved_at
                                    ? moment.parseZone(
                                        x?.details?.ssc_user_approved_at
                                      ).format("DD-MM-YYYY hh:mm A")
                                    : "-"} */}
                                  {userData?.groups?.map((data) =>
                                    data?.name
                                      ?.toLowerCase()
                                      ?.includes("ssc user")
                                      ? x?.expense_approver_remark_data[
                                          x?.expense_approver_remark_data
                                            ?.length - 1
                                        ]?.updated_at
                                        ? moment
                                            .parseZone(
                                              x?.expense_approver_remark_data[
                                                x?.expense_approver_remark_data
                                                  ?.length - 1
                                              ]?.updated_at
                                            )
                                            .format("DD-MM-YYYY hh:mm A")
                                        : x?.details?.claim_date
                                        ? moment
                                            .parseZone(x?.details?.claim_date)
                                            .format("DD-MM-YYYY hh:mm A")
                                        : ""
                                      : data?.name
                                          .toLowerCase()
                                          ?.includes("ssc manager")
                                      ? x?.details?.ssc_user_approved_at
                                        ? moment
                                            .parseZone(
                                              x?.details?.ssc_user_approved_at
                                            )
                                            .format("DD-MM-YYYY hh:mm A")
                                        : ""
                                      : x?.details?.ssc_head_approved_at
                                      ? moment
                                          .parseZone(
                                            x?.details?.ssc_head_approved_at
                                          )
                                          .format("DD-MM-YYYY hh:mm A")
                                      : ""
                                  )}
                                </td>
                              </>
                            ) : (
                              <></>
                            )}

                            {currentsubTypename == "Vehicle Registration" && (
                              <td>{x?.details[0]?.vehicleType}</td>
                            )}
                            {(currentsubTypename == "Vehicle Registration" ||
                              currentsubTypename == "Vehicle Insurance") && (
                              <>
                                <td>{x?.details[0]?.vehicleNumber}</td>
                                <td>
                                  {moment(x?.details[0]?.validFromDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>
                                  {moment(x?.details[0]?.validTillDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "Shift" ||
                            currentsubTypename == "Travel Expense" ||
                            currentsubTypename == "Travel Expense Exception" ||
                            currentsubTypename == "Other Advance" ? (
                              <td>{x?.created_by_name}</td>
                            ) : (
                              currentsubTypename &&
                              (currentsubTypename === "Higher Education" ||
                                currentsubTypename ===
                                  "Higher Education Reimbursement") && (
                                <td>{`${x?.created_by_name} (${x?.details?.created_by_username})`}</td>
                              )
                            )}
                            {currentsubTypename === "PO" ||
                            currentsubTypename === "CONTRACT" ? (
                              <td>{x?.details?.pono}</td>
                            ) : (
                              ""
                            )}
                            {currentsubTypename === "PO" ||
                            currentsubTypename === "CONTRACT" ? (
                              <td>{x?.details?.vendor_name}</td>
                            ) : (
                              ""
                            )}
                            {currentsubTypename === "News, Notice & Popup" && (
                              <td>{x?.type}</td>
                            )}
                            {currentsubTypename === "Shift" && (
                              <>
                                {x?.details?.map((item) => (
                                  <>
                                    <td>
                                      {moment(item?.shift_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {item?.assigned_shift_id__shift_name}(
                                      {item?.assigned_shift_id__shift_time_in !=
                                      null
                                        ? item?.assigned_shift_id__shift_time_in.slice(
                                            0,
                                            5
                                          ) + "AM to"
                                        : ""}{" "}
                                      {item?.assigned_shift_id__shift_time_out !=
                                      null
                                        ? item?.assigned_shift_id__shift_time_out.slice(
                                            0,
                                            5
                                          ) + "PM"
                                        : ""}
                                      )
                                    </td>
                                    <td>
                                      {item?.change_to_id__shift_name}(
                                      {item?.change_to_id__shift_time_in != null
                                        ? item?.change_to_id__shift_time_in.slice(
                                            0,
                                            5
                                          ) + "AM to"
                                        : ""}{" "}
                                      {item?.change_to_id__shift_time_out !=
                                      null
                                        ? item?.change_to_id__shift_time_out.slice(
                                            0,
                                            5
                                          ) + "PM"
                                        : ""}
                                      )
                                    </td>
                                    <td>{item?.reason_id__reason}</td>
                                  </>
                                ))}
                              </>
                            )}

                            {currentsubTypename !== "Purchase Requisition" &&
                              currentsubTypename !== "GL Sign-off" &&
                              currentsubTypename !== "Shift" &&
                              currentsubTypename !== "Higher Education" &&
                              currentsubTypename !==
                                "Higher Education Reimbursement" &&
                              currentsubTypename != "Travel Expense" &&
                              currentsubTypename !=
                                "Travel Expense Exception" &&
                              currentsubTypename != "Other Advance" &&
                              x.details !== "" &&
                              x.details !== undefined && (
                                <td>
                                  <Tooltip
                                    title={
                                      currentsubTypename ==
                                      "Higher Education Reimbursement"
                                        ? `${x?.created_by_name} (${x?.details?.created_by_username})`
                                        : currentsubTypename
                                            ?.toLowerCase()
                                            ?.includes("other expense ssc")
                                        ? x?.details?.length > 0
                                          ? x.details[0]?.user_name
                                          : ""
                                        : x?.created_by_name
                                    }
                                    position="bottom"
                                  >
                                    {currentsubTypename ==
                                    "Higher Education Reimbursement"
                                      ? `${x?.created_by_name} (${x?.details?.created_by_username})`
                                      : currentsubTypename
                                          ?.toLowerCase()
                                          ?.includes("other expense ssc")
                                      ? x?.details?.length > 0
                                        ? x.details[0]?.user_name
                                        : ""
                                      : x?.created_by_name}
                                  </Tooltip>
                                </td>
                              )}
                            {currentsubTypename === "Loan" && (
                              <>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.amount
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.loan_type
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.last_approved?.last_approver_name} (
                                  {x?.last_approved?.approver_code})
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.exception_reason
                                    : "N/A"}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "Salary Advance" && (
                              <>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.amount
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.last_approved?.last_approver_name} (
                                  {x?.last_approved?.approver_code})
                                </td>
                              </>
                            )}
                            {currentsubTypename === "PO" ||
                            currentsubTypename === "CONTRACT" ? (
                              <td> {changeData(x?.details?.totalprice)}</td>
                            ) : (
                              ""
                            )}
                            {currentsubTypename === "Service Entry Sheet" && (
                              <>
                                <td>{x?.details?.requester}</td>
                                <td>{x.details.pono}</td>
                                <td>{x.details.shorttext}</td>
                                <td>{x.details.gross_price}</td>
                              </>
                            )}

                            {currentsubTypename !== "Purchase Requisition" &&
                              currentsubTypename !== "GL Sign-off" &&
                              currentsubTypename !== "Expense" &&
                              (currentsubTypename !=
                                "Travel Expense Exception" ||
                                currentsubTypename != "Travel Expense") &&
                              currentsubTypename !=
                                "Travel Intimation Exception" &&
                              currentsubTypename != "Travel Intimation" &&
                              x.details !== "" &&
                              x?.details !== undefined && (
                                <td>
                                  {moment(x?.created_at)
                                    .utc()
                                    .format("DD-MM-YYYY hh:mm A")}
                                </td>
                              )}
                            {currentsubTypename === "PO" ||
                            currentsubTypename === "CONTRACT" ? (
                              <td>{x?.details?.status}</td>
                            ) : (
                              ""
                            )}
                            {currentsubTypename === "Leave" && (
                              <>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.name
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? `${moment(
                                        x?.details[0]?.from_date
                                      ).format("DD-MM-YYYY")}${
                                        x?.details[0]?.from_type !== ""
                                          ? x?.details[0]?.from_type ===
                                              "First" ||
                                            x?.details[0]?.from_type === "F"
                                            ? " | F"
                                            : x?.details[0]?.from_type ===
                                                "Second" ||
                                              x?.details[0]?.from_type === "S"
                                            ? " | S"
                                            : ""
                                          : ""
                                      }`
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? `${moment(x?.details[0]?.to_date).format(
                                        "DD-MM-YYYY"
                                      )}${
                                        x?.details[0]?.to_type !== ""
                                          ? x?.details[0]?.to_type ===
                                              "First" ||
                                            x?.details[0]?.to_type === "F"
                                            ? " | F"
                                            : x?.details[0]?.to_type ===
                                                "Second" ||
                                              x?.details[0]?.to_type === "S"
                                            ? " | S"
                                            : ""
                                          : ""
                                      }`
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.leave_duration
                                    : "N/A"}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.reason
                                    : "N/A"}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "Demand" && (
                              <td>{x?.details?.amount}</td>
                            )}
                            {currentsubTypename === "Expense" && (
                              <>
                                <td>
                                  <Tooltip
                                    position="bottom"
                                    title={
                                      x?.details?.gl_desc +
                                      "-" +
                                      x?.details?.gl_code
                                    }
                                  >
                                    {x?.details?.gl_desc} -{" "}
                                    {x?.details?.gl_code}
                                  </Tooltip>
                                </td>
                                <td>{x?.details?.total_amount}</td>
                                <td>
                                  <Tooltip
                                    position="bottom"
                                    title={x?.details?.item_no}
                                  >
                                    {x?.details?.item_no}
                                  </Tooltip>
                                </td>
                                <td>
                                  <Tooltip
                                    position="bottom"
                                    title={x?.details?.expense_gl_code}
                                  >
                                    {x?.details?.expense_gl_code}
                                  </Tooltip>
                                </td>
                              </>
                            )}
                            {currentsubTypename == "Expense" && (
                              <>
                                <td>{x?.details?.frequencyType}</td>
                                <td>{x?.details?.frequency_limit}</td>
                                <td>{x?.details?.currentBalance}</td>
                                <td>{x?.details?.cashLimit}</td>
                                <td>{x?.details?.invoice_no}</td>
                                <td>
                                  {moment
                                    .utc(x?.details?.invoice_date)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>{x?.details?.description}</td>
                                <td>{x?.details?.openingBalance}</td>
                                <td>{x?.details?.provisionBalance}</td>
                                <td>
                                  {moment
                                    .utc(x?.created_at)
                                    .format("DD-MM-YYYY hh:mm ")}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "JV" && (
                              <td>{x.details?.companycode}</td>
                            )}
                            {currentsubTypename === "JV" && (
                              <td>{x.details?.sumamount}</td>
                            )}
                            {currentsubTypename === "JV" && (
                              <td>{x.details?.remarks}</td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>{x?.details?.invoice_number}</td>
                            )}
                            {currentsubTypename === "GL Sign-off" && (
                              <>
                                <td>{x?.details?.Gl_desc}</td>
                                <td>{x?.details?.user_name}</td>
                                <td>{x?.details?.Reviewer_name}</td>
                                <td>{x?.details?.sap_amount}</td>
                                <td>{x?.details?.balance}</td>
                                <td>{x?.details?.variance}</td>
                              </>
                            )}
                            {currentsubTypename === "GL Sign-off" && (
                              <td>{x?.details?.name_of_group}</td>
                            )}
                            {/* {currentsubTypename === "GL Sign-off" && (
                              <td>{x?.details?.sap_amount}</td>
                            )} */}
                            {currentsubTypename === "GL Sign-off" && (
                              <>
                                <td>{x?.details?.saplocation}</td>
                                <td>{x?.month}</td>
                                <td>
                                  {" "}
                                  {moment
                                    .utc(x?.created_at)
                                    .format("DD-MM-YYYY hh:mm ")}
                                </td>
                              </>
                            )}
                            {currentsubTypename === "INVOICE" && (
                              <td>{x?.details?.bill_amount}</td>
                            )}
                            {currentsubTypename === "INVOICE" && (
                              <td>{x?.details?.invoice_no}</td>
                            )}
                            {currentsubTypename === "INVOICE" && (
                              <td>
                                {moment(x?.details?.invoice_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            )}
                            {currentsubTypename === "Source List" && (
                              <td>
                                {" "}
                                <Tooltip
                                  title={x?.details?.material_code}
                                  position="bottom"
                                >
                                  {`${x?.details?.material_code_name} (${x?.details?.material_code})`}
                                </Tooltip>
                              </td>
                            )}
                            {currentsubTypename === "Source List" && (
                              <td>
                                <Tooltip
                                  title={x?.details?.vendor_code}
                                  position="bottom"
                                >
                                  {`${x?.details?.vendor_code_name} (${x?.details?.vendor_code})`}
                                </Tooltip>
                              </td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>{x?.details?.type}</td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>{x?.details?.gst_code}</td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>{x?.details?.gross_amount}</td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>{x?.details?.vendor_name}</td>
                            )}
                            {currentsubTypename === "OTV" && (
                              <td>
                                {moment(x?.details?.invoice_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            )}
                            {currentsubTypename === "Service Entry Sheet" && (
                              <Tooltip
                                title={x.details.created_at}
                                position="bottom"
                              >
                                <td>
                                  {moment(x.details.created_at).format(
                                    "DD-MM-YYYY hh:mm A"
                                  )}
                                </td>
                              </Tooltip>
                            )}
                            {currentsubTypename === "Loan" ||
                              (currentsubTypename === "JV" && (
                                <td>
                                  {Math.round(
                                    (new Date() - new Date(x?.created_at)) /
                                      (1000 * 60 * 60 * 24)
                                  )}
                                  Day
                                </td>
                              ))}
                            {currentsubTypename === "Mobile" && (
                              <>
                                <td>
                                  {x?.details?.length > 0
                                    ? x?.details[0]?.amount
                                    : ""}
                                </td>
                                <td>
                                  {x?.details?.length > 0
                                    ? moment(x?.details[0]?.bill_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : ""}
                                </td>
                              </>
                            )}

                            {currentsubTypename === "Marriage" && (
                              <>
                                <td>
                                  {x?.details?.length > 0
                                    ? moment(
                                        x?.details[0]?.marriage_date
                                      ).format("DD-MM-YYYY")
                                    : ""}
                                </td>
                              </>
                            )}
                            {(currentsubTypename?.toLowerCase() ===
                              "other expense" ||
                              currentsubTypename?.toLowerCase() ===
                                "other expense exception" ||
                              currentsubTypename?.toLowerCase() ===
                                "other expense ssc") && (
                              <>
                                <td>
                                  {x?.details[0]?.expense_type[0]?.toUpperCase() +
                                    x?.details[0]?.expense_type?.slice(1)}{" "}
                                </td>
                                <td>
                                  {x?.details[0]?.total_bill_amount?.toFixed(2)}
                                </td>

                                {userData.groups.map((data) =>
                                  data?.name
                                    .toLowerCase()
                                    ?.includes("ssc manager") ? (
                                    <td>
                                      {getTotalApprovedAmt(
                                        x?.details[0].expense_data
                                      ).toFixed(2)}
                                    </td>
                                  ) : (
                                    <></>
                                  )
                                )}

                                <td>
                                  {x?.details[0]?.expense_type?.toLowerCase() ==
                                  "other"
                                    ? "NA"
                                    : x?.details[0]?.expense_data[0]
                                        ?.for_month_of &&
                                      moment(
                                        x?.details[0]?.expense_data[0]
                                          ?.for_month_of
                                      )?.format("MMMM")}
                                </td>
                                <td>
                                  <Link
                                    to={`/print-other-listing?id=${x?.details[0]?.id}&user_id=${x?.created_by_id}`}
                                    state={{
                                      prev: `/common-approve/${currentsubType}`,
                                      subtypeid: currentsubType,
                                      prevState: { ...location.state },
                                    }}
                                  >
                                    <i class="fas fa-print"></i>
                                  </Link>
                                </td>
                              </>
                            )}

                            {currentsubTypename == "Purchase Requisition" && (
                              <>
                                {x?.details?.length > 0 ? (
                                  <>
                                    <td>
                                      <Link
                                        to="/view-pr-approval"
                                        state={{
                                          from: x?.transaction_id,
                                          subId: currentsubType,
                                          Itemsflag: true,
                                          view_level: "PR",
                                          flag: false,
                                          approval_flag: true,
                                          master_flag: true,
                                          pr_mast_id:
                                            x?.details?.length > 0
                                              ? x?.details[0]?.pr_id
                                              : "",
                                        }}
                                        class="text-theme"
                                      >
                                        {x?.details[0]?.sap_pr_no}
                                      </Link>
                                    </td>
                                    <td>{x?.created_by_name}</td>
                                    <td>{x?.details[0]?.material_code}</td>
                                    <td>{x?.details[0]?.material_desc}</td>
                                    {x?.details[0]?.pr_type ===
                                    "S-Service PR" ? (
                                      <>
                                        <td>
                                          {Number(x?.sum_price).toFixed(2)}
                                        </td>
                                        <td>
                                          {Number(x?.sum_price).toFixed(2)}
                                        </td>
                                        <td>{x?.currency}</td>
                                        <td>1</td>
                                      </>
                                    ) : (
                                      <>
                                        <td>
                                          {Number(
                                            x?.details[0]?.valuation
                                          ).toFixed(2)}
                                        </td>
                                        <td>
                                          {x?.details[0]?.valuation *
                                            x?.details[0]?.requisition_quantity}
                                        </td>
                                        <td></td>
                                        <td>
                                          {x?.details[0]?.requisition_quantity}
                                        </td>
                                      </>
                                    )}
                                    <td>{x?.details[0]?.pr_type}</td>
                                    <td>
                                      {x?.details[0]?.purchaseGroupName} -{" "}
                                      {x?.details[0]?.purchaseGroup}
                                    </td>
                                    {x?.details[0]?.source === "M" ? (
                                      <td>MYPI</td>
                                    ) : x?.details[0]?.source === "E" ? (
                                      <td>ELN</td>
                                    ) : x?.details[0]?.source === "S" ? (
                                      <td>SAP</td>
                                    ) : (
                                      <td>{x?.details[0]?.source}</td>
                                    )}
                                    <td>{x?.details[0]?.plant_code}</td>
                                    <td>
                                      {" "}
                                      {moment
                                        .utc(x?.details[0]?.created_at)
                                        .format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                    <td>
                                      {Math.round(
                                        (new Date() -
                                          new Date(x?.details[0]?.created_at)) /
                                          (1000 * 60 * 60 * 24)
                                      )}
                                      Day
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}
                              </>
                            )}
                            {currentsubTypename == "Material Creation" && (
                              <>
                                {x?.details?.length > 0 ? (
                                  <>
                                    <td>{x?.details[0]?.temp_code}</td>
                                    <td>{x?.details[0]?.description}</td>
                                    <td>{x?.details[0]?.plant_name}</td>
                                    <td>{x?.details[0]?.group}</td>
                                    <td>{x?.details[0]?.material_type}</td>
                                    <td>{x?.details[0]?.unit}</td>
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}
                              </>
                            )}
                            {currentsubTypename == "HSN" && (
                              <>
                                {x?.details?.length > 0 ? (
                                  <>
                                    {x?.details[0]?.hsn_code !== 0 &&
                                    x?.details[0]?.hsn_code !== "0" ? (
                                      <td>{x?.details[0]?.hsn_code}</td>
                                    ) : (
                                      <td>{x?.details[0]?.new_hsn_code}</td>
                                    )}
                                    <td>
                                      <Tooltip
                                        title={x?.details[0]?.material_details}
                                        position="bottom"
                                      >
                                        {x?.details[0]?.material_details}
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip
                                        title={x?.details[0]?.plant_details}
                                        position="bottom"
                                      >
                                        {x?.details[0]?.plant_details}
                                      </Tooltip>
                                    </td>
                                    <td>{x?.details[0]?.desc}</td>
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}
                              </>
                            )}
                            {(currentsubTypename == "Travel Expense" ||
                              currentsubTypename ==
                                "Travel Expense Exception") &&
                              x?.details?.length > 0 && (
                                <>
                                  <td>
                                    {` ${
                                      x &&
                                      x?.details &&
                                      x?.details[0] &&
                                      x?.details[0]?.travel_detail &&
                                      x?.details[0]?.travel_detail[0]
                                        ?.user_cost_center
                                    } (${
                                      x?.details[0]?.travel_detail[0]
                                        ?.user_cost_center_code
                                    })`}
                                  </td>
                                  <td>
                                    {moment(
                                      x?.details[0]?.travel_detail[0]?.from_date
                                    ).format("DD-MM-YYYY")}
                                  </td>
                                  <td>
                                    {moment(
                                      x?.details[0]?.travel_detail[
                                        x?.details[0]?.travel_detail?.length - 1
                                      ].to_date
                                    ).format("DD-MM-YYYY")}
                                  </td>

                                  <td>
                                    {x?.details[0]?.travel_detail &&
                                    x?.details[0]?.travel_detail[0]
                                      ?.to_city_name == null
                                      ? x?.details[0]?.travel_detail &&
                                        x?.details[0]?.travel_detail[0]
                                          ?.to_city_input
                                      : x?.details[0]?.travel_detail &&
                                        x?.details[0]?.travel_detail[0]
                                          ?.to_city_name !== "Other"
                                      ? x?.details[0]?.travel_detail &&
                                        x?.details[0]?.travel_detail[0]
                                          ?.to_city_name
                                      : x?.details[0]?.travel_detail &&
                                        x?.details[0]?.travel_detail[0]
                                          ?.to_city_input}
                                  </td>
                                  <td>
                                    {x?.details[0]?.expense_exception
                                      ? "Yes"
                                      : ""}
                                  </td>
                                </>
                              )}
                            {(currentsubTypename === "Travel Intimation" ||
                              currentsubTypename ===
                                "Travel Intimation Exception") && (
                              <>
                                <td>
                                  {x?.details?.request_type == "On Duty"
                                    ? "In Station"
                                    : x?.details?.request_type}
                                </td>
                                {/* <td>{x?.request_at}</td> */}
                                <td>
                                  {x?.details?.travel_detail_data?.length > 0 &&
                                    x?.details?.travel_detail_data[0]
                                      ?.from_date !== null &&
                                    moment(
                                      x?.details?.travel_detail_data[0]
                                        ?.from_date
                                    ).format("DD-MM-YYYY")}
                                  {/* :{x?.details?.travel_detail_data[0]?.from_time} */}
                                </td>
                                <td>
                                  {x?.details?.travel_detail_data?.length > 0 &&
                                    moment(
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_date
                                    ).format("DD-MM-YYYY")}{" "}
                                  {/* {x?.details?.travel_detail_data[1]?.to_date} */}
                                </td>
                                <td>
                                  {x?.details?.advance_amount == null
                                    ? ""
                                    : x?.details?.advance_amount}
                                </td>

                                <td>
                                  {x?.details?.travel_detail_data &&
                                  x?.details?.travel_detail_data[
                                    x?.details?.travel_detail_data?.length - 1
                                  ]?.to_city_name == null
                                    ? x?.details?.travel_detail_data &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_input
                                    : x?.details?.travel_detail_data &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_name !== "Other"
                                    ? x?.details?.travel_detail_data &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_name
                                    : x?.details?.travel_detail_data &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_input}
                                </td>
                                {/* <td>
                                  {x && 
                                  x?.details &&
                                  x?.details?.travel_detail_data &&
                                  x?.details?.travel_detail_data[0]
                                    ?.to_city_input == null
                                    ? x?.details &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_name
                                    : x?.details?.travel_detail_data &&
                                      x?.details?.travel_detail_data[0]
                                        ?.to_city_input !== null
                                    ? x?.details &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_input
                                    : x?.details &&
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.to_city_name}
                                </td> */}
                                <td>{x?.details?.ytd_data}</td>
                                <td>
                                  {x?.details?.travel_detail_data?.length > 0 &&
                                    calculateDateDifference(
                                      x?.details?.travel_detail_data[0]
                                        ?.to_date,
                                      x?.details?.travel_detail_data[
                                        x?.details?.travel_detail_data?.length -
                                          1
                                      ]?.from_date
                                    )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "40px",
                                  }}
                                >
                                  {x.details?.is_exception == true && (
                                    <i
                                      class="fa fa-flag"
                                      aria-hidden="true"
                                      style={{ color: "red" }}
                                    ></i>
                                  )}
                                </td>

                                <td>
                                  {x?.details?.status == "A" ? (
                                    <span class="badge bad-status badge-success">
                                      Approved
                                    </span>
                                  ) : x?.details?.status == "P" ||
                                    x?.details?.status == "PENDING" ||
                                    x?.details?.status == null ? (
                                    <span class="badge bad-status badge-warning">
                                      Pending
                                    </span>
                                  ) : x?.details?.status == "Cancelled" ? (
                                    <span class="badge bad-status badge-danger">
                                      Cancelled
                                    </span>
                                  ) : x?.details?.status == "CA" ? (
                                    <span class="badge bad-status badge-danger">
                                      Cancellation Approval Pending
                                    </span>
                                  ) : (
                                    <tr>
                                      <td colSpan={14}>No Record Found</td>
                                    </tr>
                                  )}
                                </td>
                                <td>
                                  {x?.created_at !== null &&
                                    moment
                                      .utc(x?.created_at)
                                      .format("DD-MM-YYYY hh:mm A")}
                                </td>
                              </>
                            )}

                            {currentsubTypename == "Other Advance" && (
                              <>
                                <td>{x?.details[0]?.advance_amount}</td>
                                <td>
                                  {moment(
                                    x?.details[0]?.advance_amount_date
                                  ).format("DD-MM-YYYY")}
                                </td>
                                {/* <td>
                                  <bu>View</bu>
                                </td> */}
                                {/* <td
                                  style={{
                                    maxWidth: "80px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Tooltip
                                    title={x?.details[0]?.reason_advance_amount}
                                    position="bottom"
                                  >
                                    {x?.details[0]?.reason_advance_amount}
                                  </Tooltip>
                                </td> */}
                              </>
                            )}
                          </tr>
                        ) : (
                          <></>
                        )
                      // (
                      //   <tr>
                      //     {errorTravelShow?.length == 0 && (
                      //       <td colSpan={12}> No Record Found</td>
                      //     )}
                      //   </tr>
                      // )
                    )
                  ) : (
                    <tr>
                      <td colSpan={14}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                    <option value={500} label={500}>
                      500
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={remarksModal}
        onHide={() => setRemarksModal(false)}
        size="lg"
        centered
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>Remarks</Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label>Remarks</label>
                    <span class="text-danger">*</span>
                    <textarea
                      type="text"
                      name="remarks"
                      class="form-control"
                      // value={underImpForm.remark}
                      // disabled={viewOnly}
                      placeholder="Enter Remarks here..."
                      onChange={(e) => {
                        setRemarksForRejection(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              setRemarksForRejection("");
              setRemarksModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={remarksForRejection === ""}
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => {
              submitAction(postingData, remarkStatus);
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonApprover;
