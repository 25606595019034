import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setDisplayLoader } from "./redux/piDataStore";
import { logoutUser } from "../service/userService";
import { useMsal } from "@azure/msal-react";

function AccessDenied() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  const [fromAuth, setFromAuth] = useState(false);
  useEffect(() => {
    if (state?.auth) {
      setFromAuth(true);
    }
  }, [state]);

  const logoutHandle = async () => {
    dispatch(setDisplayLoader("Display"));
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        handleLogout("redirect");
        localStorage.clear();
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
    }
  };

  return (
    <>
      <div class="content-wrapper-inner">
        <div class="innerheadsec">
          <div class="row justify-content-center">
            <div class="col-md-5 text-center">
              <h1 class="fw-bold text-theme">Access Denied</h1>
              <img src="images/404.jpg" />
              {fromAuth ? (
                <>
                  <p>
                    Your Employee ID is not updated in AD account you logged in here, please reach out to IT Support team.
                  </p>
                  <li onClick={() => logoutHandle()}>
                    <Link>
                      <img src="images/icon/logout-hover.svg" alt="" /> Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <p>
                    Page not found your Internet not working properly.
                    <br />
                    Please go back to home page
                  </p>
                  <Link to="/dashboard" class="btn btn-outline-danger">
                    Back to Home
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AccessDenied;
