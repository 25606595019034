import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import * as LoanDataService from "../../service/loansalary";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { get_sap_schedule } from "../../service/searchpo";

const LoanReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [userLoanReport, setUserLoanReport] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterEmp, setFilterEmp] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [loanReportFilter, setLoanReportFilter] = useState({
    emp_full_name: "",
    type: "",
    status: "",
  });
  const filteropen = () => filtersetShow(true);
  const filterclose = () => {
    setFilterApplied(false);
    filtersetShow(false);
    setFilter("");
    setFilterEmp("");
    setLoanReportFilter((prev) => ({
      ...prev,
      emp_full_name: "",
      status: "",
      type: "",
    }));
    getUserLoan(searchStr, currentPage, pageSizeNo, sortData.sort_by, true, "", true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [availEmp, setAvailEmp] = useState([]);
  const [availEmp1, setAvailEmp1] = useState([]);

  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')

  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getUserLoan1(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      false,
      loanReportFilter,
      true
    );
    getUserLoan(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      loanReportFilter,
      true
    );
    getSapSchedule();
  }, [pageSizeNo]);


  const getSapSchedule = () => {
    get_sap_schedule('sync_loan_advance').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const handleFilter = () => {
    setFilterApplied(true);
    getUserLoan(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      loanReportFilter,
      true
    );
    filtersetShow(false);
  };

  const getUserLoan = (search, page_no, page_size, sort_by, paginate, filter, show_all) => {
    LoanDataService.getUserLoan(
      handleKey(search, page_no, page_size, sort_by, paginate, filter, show_all)
    )
      .then((response) => {
        let loanData = response?.data?.dataList?.result;
        // let salaryData = response?.data?.dataList?.result1;
        // loanData.push(...salaryData);
        setUserLoanReport(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        dispatch(setDisplayLoader("Hide"));
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        let jsonObject = obj.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        let obj1 = response?.data?.dataList?.result1?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        // setAvailEmp(uniqueArray);
        // setAvailEmp1(obj1);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getUserLoan1 = (search, page_no, page_size, sort_by, paginate, filter, show_all) => {
    LoanDataService.getUserLoan(
      handleKey(search, page_no, page_size, sort_by, paginate, filter, show_all)
    )
      .then((response) => {
        let loanData = response?.data?.dataList?.result;
        dispatch(setDisplayLoader("Hide"));
        let obj = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        let jsonObject = obj.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        let obj1 = response?.data?.dataList?.result1?.map((res) => {
          return {
            value: res?.emp_id,
            label: res?.emp_full_name + "(" + res?.emp_code + ")",
          };
        });
        setAvailEmp(uniqueArray);
        setAvailEmp1(obj1);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  // const myEmployeeType = () => {
  //   let myObj = userLoanReport.map((data) => {

  //   })
  //   setEmployeeType(myObj)
  // }

  const handleKey = (search, page_no, page_size, sort_by, paginate, filter, show_all) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (
      filter.type !== "" &&
      filter.type !== undefined &&
      filter.type !== null
    ) {
      queryParm = queryParm + "&type_is=" + filter.type;
    }
    if (
      filter.emp_full_name !== "" &&
      filter.emp_full_name !== undefined &&
      filter.emp_full_name !== null
    ) {
      queryParm = queryParm + "&emp_id=" + filter.emp_full_name[0].value;
    }
    if (
      filter.status !== "" &&
      filter.status !== undefined &&
      filter.status !== null
    ) {
      queryParm = queryParm + "&status=" + filter.status;
    }
    if (
      show_all !== "" &&
      show_all !== undefined &&
      show_all !== null
    ) {
      queryParm = queryParm + "&show_all=" + show_all;
    }
    if (
      search !== "" &&
      search !== undefined &&
      search !== null
    ) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getUserLoan(
      "",
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      loanReportFilter,
      true
    );
    setCurrentPage(activePage);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      getUserLoan(
        e.target.value.toLowerCase(),
        1,
        pageSizeNo,
        sortData.sort_by,
        true,
        loanReportFilter,
        true
      );
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      getUserLoan(
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        loanReportFilter,
        true
      );
      setSearchStr("");
    }
  };

  const myShowHandle = (e) => {
    getUserLoan(
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      loanReportFilter,
      true
    );
    setPageSizeNo(e.target.value);
    setCurrentPage(1);
  };

  const getExportValue = (pageNo, page_size, sort_by, paginate, filter, show_all) => {
    dispatch(setDisplayLoader("Display"));
    LoanDataService.getExportValue_loan_report(
      handleKey("", pageNo, page_size, sort_by, paginate, filter, show_all)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "loanreport.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  return (
    <>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/loan-apply">Loan & Salary Advance</Link>
            </li>
            <li class="active">
              <Link to="/loan-report">Loan & Salary Advance Report</Link>
            </li>
            <li>
              <Link to="/salary-approver">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/loan-approval">For Approval</Link>{" "}
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title" />
                  Loan & Salary Advance Report
                  {/* </Breadcrumb.Item>
                      </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Advance Type,
                            <br />
                            Advance No,
                            <br />
                            Amount
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Advance Type, Advance No, Amount..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            // disabled={changeShiftData.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportValue(
                                currentPage,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                loanReportFilter,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Advance Type</th>
                    <th>Document No</th>
                    <th>Requested By</th>
                    <th>Requested On</th>
                    <th>Status</th>
                    <th>Remarks</th>
                    <th>Amount</th>
                    <th>Applied On</th>
                    <th>Approved By</th>
                    <th>Last Modified</th>
                    <th>Exception Reason</th>
                    <th>Current Approver</th>
                    {/* <th>Sap No</th> */}
                    <th>Sap Status</th>
                    <th>Sap Response</th>
                    <th>Loan Type</th>
                    <th>Installments No</th>
                    <th>Recovery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {userLoanReport.length ? (
                    userLoanReport?.map((data) => (
                      <tr>
                        <td>{data?.type}</td>
                        <td>{data?.document_no}</td>
                        <td>{data?.emp_full_name} ({data?.emp_code})</td>
                        <td>
                          {moment(data?.apply_date).format("DD-MM-YYYY")}
                        </td>
                        {data?.status == "P" ? (
                          <td>
                            <span class="badge bad-status badge-warning">
                              Pending
                            </span>
                          </td>
                        ) : data?.status == "A" ? (
                          <td>
                            <span class="badge bad-status badge-success">
                              Approved
                            </span>
                          </td>
                        ) : (
                          <td>
                            <span class="badge bad-status badge-danger">
                              Rejected
                            </span>
                          </td>
                        )}
                        <td>{data?.comments}</td>
                        <td>{data?.amount}</td>
                        <td>
                          {moment(data?.apply_date).format("DD-MM-YYYY")}
                        </td>
                        {data?.status == "P" ? (
                          <td></td>
                        ) : data?.status == "A" && data?.currentApprover?.length > 0 ? (
                          <td>{data?.currentApprover[0]?.approver_name} ({data?.currentApprover[0]?.emp_code})</td>
                        ) : data?.currentApprover?.length > 0 ? (
                          <td>{data?.currentApprover[0]?.approver_name} ({data?.currentApprover[0]?.emp_code})</td>
                        ) : <td></td>
                        }
                        {data?.updated_at !== null ?
                          <td>
                            {moment(data?.updated_at).format("DD-MM-YYYY")}
                          </td>
                          :
                          <td></td>
                        }
                        <td>
                          {data?.exception_reason}
                        </td>
                        {data?.status == "P" ? (
                          <td>{data?.currentApprover[0]?.approver_name}</td>
                        ) : data?.status == "A" ? (
                          <td></td>
                        ) : (
                          <td></td>
                        )}
                        {/* <td></td> */}
                        {data?.sap_status === true ?
                          <td>Synced</td>
                          :
                          <td>Pending to sync</td>
                        }
                        <td>{data?.sap_error}</td>
                        <td>{data?.loan_type}</td>
                        <td>{data?.no_of_installment}</td>
                        {data?.recovery_date != null ?
                          <td>
                            {moment(data?.recovery_date).format("DD-MM-YYYY")}
                          </td>
                          :
                          <td></td>
                        }
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={19}> No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {userLoanReport?.length == 0 ? (
              ""
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control"
                      onClick={(e) => {
                        myShowHandle(e);
                      }}
                    >
                      <option value={10} label={10}>10</option>
                      <option value={20} label={20}>20</option>
                      <option value={30} label={30}>30</option>
                      <option value={100} label={100}>100</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Employee ID</label>
            <Select
              isMulti
              options={availEmp}
              // name="emp_full_name"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setLoanReportFilter({
                  ...loanReportFilter,
                  emp_full_name: e,
                });
                setAvailEmp(e);
              }}
              value={loanReportFilter.emp_full_name}
            />
          </div>

          {/* <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>

          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div> */}

          <div class="form-group innergroup">
            <label>Advance Type</label>
            <select
              class="form-control newbgselect"
              name="type"
              onChange={(e) => {
                setLoanReportFilter({
                  ...loanReportFilter,
                  type: e.target.value,
                });
              }}
              value={loanReportFilter?.type}
            >
              <option>Select</option>
              <option>Loan</option>
              <option>Salary</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              name="status"
              class="form-control newbgselect"
              onChange={(e) => {
                setLoanReportFilter({
                  ...loanReportFilter,
                  status: e.target.value,
                });
              }}
              value={loanReportFilter?.status}
            >
              <option value="">Select</option>
              <option value="P">Pending</option>
              <option value="A">Approved</option>
              <option value="D">Rejected</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main" onClick={handleFilter}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanReport;
