import React from "react";
import { useState, useEffect } from "react";
import * as TravelListingAPI from "../../service/travelService";
import OnDuty from "./OnDuty";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import { useNavigate } from "react-router-dom";
import { Spinner, Alert, NavItem } from "react-bootstrap";
import { injectStyle } from "react-toastify/dist/inject-style";
import * as DomesticService from "../../service/domestic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (typeof window !== "undefined") {
  injectStyle();
}
const AddTravelIntimation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector(selectUserData);
  const {
    viewForm,
    viewFormDataNew,
    edit,
    idUpdate,
    onViewClose,
    userBackDate,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  const [cityData, setCityData] = useState([]);
  const [cityDataTo, setCityDataTo] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [cityValueTo, setCityValueTo] = useState();
  const [cityDataNew, setCityDataNew] = useState([]);
  const [cityDataToNew, setCityDataToNew] = useState([]);
  const [cityValueNew, setCityValueNew] = useState();
  const [cityValueToNew, setCityValueToNew] = useState();
  const [purpose, setPurpose] = useState([]);
  const [purposeNew, setPurposeNew] = useState([]);
  const [purposeValue, setPurposeValue] = useState([]);
  const [purposeValueNew, setPurposeValueNew] = useState([]);
  const [valueItem, setValueItem] = useState();
  const [isExist, setIsExist] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [dataNew, setDataNew] = useState([]);
  const [validDateJoin, setValidDateJoin] = useState(false);
  const [isLeaveExist, setIsLeaveExist] = useState(false);
  const [timeSetValue, setTimeSetValue] = useState([]);
  const [timeSetValueNew, setTimeSetValueNew] = useState([]);
  const [fromTimeValue, setFromTimeValue] = useState();
  const [toTimeValue, setToTimeValue] = useState();
  const [fromTwoWayTime, setfromTwoWayTime] = useState();
  const [toTwoWayTime, setToTwoWayTime] = useState();
  const [minBackDateValue, setMinBackDateValue] = useState("");
  const [maxBackDateValue, setMaxBackDateValue] = useState("");
  const [backDatedError, setBackDatedError] = useState(false);
  const [validTimeZone, setValidTimeZone] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  let date = new Date();
  let valueDate = moment(date).format("YYYY-MM-DD");

  let condition = "false";
  const todayDate = new Date();
  const FromDate = moment(todayDate).format("YYYY-MM-DD");
  const ToDate = moment(todayDate).format("YYYY-MM-DD");
  const [formData, setFormData] = useState({
    from_city_input: dataNew[0]?.location,
    to_city_input: "",
    from_date: FromDate,
    from_time: "",
    to_time: "",
    to_date: ToDate,
    reason: "",
    purpose_of_travel_id: "",
  });
  const [type, setType] = useState("oneway");
  const [formDataNew, setFormDataNew] = useState({
    from_city_input: "",
    to_city_input: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    reason: "",
    purpose_of_travel_id: "",
  });
  const [formValidation, setFormValidation] = useState({
    from_city_input: "",
    to_city_input: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    reason: "",
    purpose_of_travel_id: "",
  });
  const [formValidationNew, setFormValidationNew] = useState({
    from_city_input: "",
    to_city_input: "",
    from_date: "",
    from_time: "",
    to_time: "",
    to_date: "",
    reason: "",
    purpose_of_travel_id: "",
  });
  // const checkFunction = (e) => {
  //   if (formDataNew?.from_time == e.target.value) {
  //     setCheckTime(true);
  //     // formDataNew?.to_date == "";

  //     return false;
  //   }
  // };

  const [minDateValue, setMinDateValue] = useState("");
  const [timeOption, setTimeOption] = useState([]);
  const [timeOptionNew, setTimeOptionNew] = useState([]);

  useEffect(() => {
    if (moment(new Date()).date() >= 23) {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
          "MM"
        )}-21`
      );
    } else if (
      `${moment(new Date()).format("YYYY")}-${moment(new Date()).format(
        "MM"
      )}-${moment(new Date()).format("DD")}` <= "2024-01-22"
    ) {
      setMinDateValue(
        `${moment(new Date()).subtract(1, "years").format("YYYY")}-${moment(
          new Date()
        )
          .subtract(1, "month")
          .format("MM")}-21`
      );
    } else {
      setMinDateValue(
        `${moment(new Date()).format("YYYY")}-${moment(new Date())
          .subtract(1, "month")
          .format("MM")}-21`
      );
    }
  }, []);

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPurposeList = async (paginate) => {
    await TravelListingAPI.getPurposeList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.purpose}`,
          };
        });
        setPurpose(
          res.filter((obj) => {
            if (
              !obj.label.includes("Leave") &&
              !obj.label.includes("Holiday/WeeklyOff")
            ) {
              return true;
            }
          })
        );
        setPurposeNew(
          res.filter((obj) => {
            if (
              !obj.label.includes("Leave") &&
              !obj.label.includes("Holiday/WeeklyOff")
            ) {
              return true;
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let regexName = /^[A-Za-z]+$/;
    if (!formData.from_city_input) {
      isError = true;
      formerr.from_city_input = "*From Location is required ";
      setFormValidation(formerr);
    }
    if (!formData.to_city_input) {
      isError = true;
      formerr.to_city_input = "To Location is required";
      setFormValidation(formerr);
    }
    if (!formData.from_date) {
      isError = true;
      formerr.from_date = "*From Date is required";
      setFormValidation(formerr);
    }
    if (!formData.from_time) {
      isError = true;
      formerr.from_time = "*From Time is required";
      setFormValidation(formerr);
    }
    if (!formData.to_time) {
      isError = true;
      formerr.to_time = "*To Time is required";
      setFormValidation(formerr);
    }
    if (!formData.to_date) {
      isError = true;
      formerr.to_date = "*To Date is required";
      setFormValidation(formerr);
    }

    if (!formData.purpose_of_travel_id) {
      isError = true;
      formerr.purpose_of_travel_id = "*Purpose of Travel is required";
      setFormValidation(formerr);
    }

    return isError;
  };
  const handleValidateNew = () => {
    let isError = false;
    const formerr = { ...formValidationNew };
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let regexName = /^[A-Za-z]+$/;
    if (!formDataNew.from_city_input) {
      isError = true;
      formerr.from_city_input = "*From Location is required ";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.to_city_input) {
      isError = true;
      formerr.to_city_input = "To Location is required";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.from_date) {
      isError = true;
      formerr.from_date = "From Date is required";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.from_time) {
      isError = true;
      formerr.from_time = "*From Time is required";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.to_time) {
      isError = true;
      formerr.to_time = "*To Time is required";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.to_date) {
      isError = true;
      formerr.to_date = "*To Date is required";
      setFormValidationNew(formerr);
    }
    if (!formDataNew.purpose_of_travel_id) {
      isError = true;
      formerr.purpose_of_travel_id = "*Purpose of Travel is required";
      setFormValidationNew(formerr);
    }

    return isError;
  };
  const pushCityId = (e) => {
    setCityValue(e);
    setFormData({
      ...formData,
      from_city_input: e.value,
    });
    pushCityIdToNew(e);
  };
  const pushPurpose = (e) => {
    setPurposeValue(e);
    setFormData({
      ...formData,
      purpose_of_travel_id: e.value,
    });
  };
  const pushCityIdTo = (e) => {
    setCityValueTo(e);
    setFormData({
      ...formData,
      to_city_input: e.value,
    });
    pushCityIdNew(e);
  };

  const pushCityIdNew = (e) => {
    setCityValueNew(e);
    setFormDataNew({
      ...formDataNew,
      from_city_input: e.value,
    });
  };
  const pushPurposeNew = (e) => {
    setPurposeValueNew(e);
    setFormDataNew({
      ...formDataNew,
      purpose_of_travel_id: e.value,
    });
  };
  const pushCityIdToNew = (e) => {
    setCityValueToNew(e);
    setFormDataNew({
      ...formDataNew,
      to_city_input: e.value,
    });
  };

  const isLeaveExists = (fromDate, toDate) => {
    let data = {};
    data["user_id"] = userData?.id;
    data["from_date"] = moment(fromDate).format("YYYY-MM-DD");
    data["to_date"] = moment(toDate).format("YYYY-MM-DD");
    if (fromDate !== "" && toDate !== "") {
      DomesticService.isLeaveExists(data)
        .then((res) => {
          // setIsLeaveExist(res?.data?.dataList?.is_exist);
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const getCityList = async (paginate) => {
    await TravelListingAPI.getCityList(paginate)
      .then((response) => {
        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.id,
            label: `${obj?.name}`,
          };
        });
        setCityData(res);
        setCityDataTo(res);
        setCityDataNew(res);
        setCityDataToNew(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isDataExists = (fromDate, toDate, fromTime, toTime) => {
    let addTime = ":00";
    let totime = toTime?.split(":").length < 3 ? toTime + addTime : toTime;
    let fromValuetime =
      fromTime?.split(":").length < 3 ? fromTime + addTime : fromTime;
    let data = {};
    data["user_id"] = userData?.id;
    data["from_date"] = fromDate;
    data["to_date"] = toDate;
    data["from_time"] = fromValuetime;
    data["to_time"] = totime;
    data["trip_start_date"] = fromDate;
    data["trip_start_time"] = fromValuetime;
    data["trip_end_date"] = toDate;
    data["trip_end_time"] = totime;
    TravelListingAPI.isDataExists(data)
      .then((res) => {
        setIsExist(res?.data?.dataList?.is_exist);
      })
      .catch((err) => console.log("Error=>", err));
  };

  const resetState = () => {
    setCityValue("");
    setPurposeValue("");
    setCityDataTo("");
    setCityValueTo("");
    setCityValueNew("");
    setCityValueToNew("");
    setPurposeValueNew("");
    setFormData({
      from_city_input: "",
      to_city_input: "",
      from_date: "",
      from_time: "",
      to_time: "",
      to_date: "",
      reason: "",
      purpose_of_travel_id: "",
    });
    setFormDataNew({
      from_city_input: "",
      to_city_input: "",
      from_date: "",
      from_time: "",
      to_time: "",
      to_date: "",
      reason: "",
      purpose_of_travel_id: "",
    });
  };
  const saveTravelInfo = async (formData) => {
    dispatch(setDisplayLoader("Display"));
    if (condition == "false") {
      condition = "true";
      setIsLoading(true);
      let datas = formData;
      datas.status = "P";
      datas.outstanding = 0;
      datas.advance_amount = 0;
      await TravelListingAPI.saveTravelInfo(datas)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          condition = "false";
          if (response?.data.code == 200) {
            resetState();
            setFormData({
              from_city_input: "",
              to_city_input: "",
              from_date: "",
              from_time: "",
              to_time: "",
              to_date: "",
              reason: "",
              purpose_of_travel_id: "",
              from_city_input: "",
              to_city_input: "",
              from_date: "",
              from_time: "",
              to_time: "",
              to_date: "",
              reason: "",
              purpose_of_travel_id: "",
            });
            navigate("/travel-listing");
            toast.success("Travel Intimation Created Successfully");
            // getReceiptDataList(false);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateTravelInfo = async (formData) => {
    dispatch(setDisplayLoader("Display"));
    let datas = formData;
    datas.id = idUpdate;
    datas.outstanding = 0;
    datas.advance_amount = 0;
    await TravelListingAPI.updateTravelInfo(datas, datas?.id)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        condition = "false";
        if (response?.data.code == 200) {
          resetState();
          setFormData({
            from_city_input: "",
            to_city_input: "",
            from_date: "",
            from_time: "",
            to_time: "",
            to_date: "",
            reason: "",
            purpose_of_travel_id: "",
            from_city_input: "",
            to_city_input: "",
            from_date: "",
            from_time: "",
            to_time: "",
            to_date: "",
            reason: "",
            purpose_of_travel_id: "",
          });
          onViewClose();
          toast.success("Travel Intimation Updated Successfully");
          // getReceiptDataList(false);
        }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidateForm = () => {
    scrollWin();
    if (isExist === false && isLeaveExist === false && validDateJoin == false) {
      if (type == "oneway") {
        if (handleValidate()) return false;
      } else if (type == "twoway") {
        if (handleValidate()) return false;
        if (handleValidateNew()) return false;
      }
      let tempFormData = { ...formData };
      tempFormData.from_date = moment(formData?.from_date).format("YYYY-MM-DD");
      tempFormData.to_date = moment(formData?.to_date).format("YYYY-MM-DD");
      let payload = {
        request_type: "On Duty",
        travel_detail: [tempFormData],
      };
      if (type == "twoway") {
        let tempFormDataNew = { ...formDataNew };
        tempFormDataNew.from_date = moment(formDataNew?.from_date).format(
          "YYYY-MM-DD"
        );
        tempFormDataNew.to_date = moment(formDataNew?.to_date).format(
          "YYYY-MM-DD"
        );
        payload.travel_detail.push(tempFormDataNew);
      }
      if (edit == undefined || edit == false) {
        if (formData?.from_time == formData?.to_time) {
          setValidTimeZone(true);
        } else {
          saveTravelInfo(payload);
        }
      } else {
        payload.id = idUpdate?.id;
        updateTravelInfo(payload);
      }
    }
  };

  const currentDate = new Date();
  const minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    22
  )
    .toISOString()
    .split("T")[0];
  useEffect(() => {
    if (viewFormDataNew) {
      setFormData(viewFormDataNew[0]);
      setFormDataNew(viewFormDataNew[1]);
      let obj1 = {
        value: formData?.from_city_input,
        label: formData?.from_city_name,
      };
      setCityValue(obj1);
      let obj2 = {
        value: formData?.to_city_input,
        label: formData?.to_city_name,
      };
      setCityValueTo(obj2);
      let obj3 = {
        value: formData?.purpose_of_travel_id,
        label: formData?.purpose,
      };
      setPurposeValue(obj3);
      let obj4 = {
        value: formDataNew?.from_city_input,
        label: formDataNew?.from_city_name,
      };
      setCityValueNew(obj4);
      let obj5 = {
        value: formDataNew?.to_city_input,
        label: formDataNew?.to_city_name,
      };
      setCityValueToNew(obj5);
      let obj6 = {
        value: formDataNew?.purpose_of_travel_id,
        label: formDataNew?.purpose,
      };
      setPurposeValueNew(obj6);
    }
  }, [valueItem, cityData]);

  useEffect(() => {
    getCityList(false);
    getPurposeList(false);
    timeOptionFunc();
  }, []);

  const validateToDate = (to_time) => {
    if (formData?.from_date == formData?.to_date) {
      if (formData?.from_time < to_time) {
        setFormData({
          ...formData,
          to_time: to_time,
        });
        setFormValidation({
          ...formValidation,
          to_time: "",
        });
      } else {
        setFormValidation({
          ...formValidation,
          to_time: "To Time should be greater then from time",
        });
      }
    } else {
      setFormData({
        ...formData,
        to_time: to_time,
      });
      setFormValidation({
        ...formValidation,
        to_time: "",
      });
    }
  };
  const validateToDateNew = (to_time) => {
    if (
      moment(formDataNew?.from_date).format("DD-MM-YYYY") ==
      moment(formDataNew?.to_date).format("DD-MM-YYYY")
    ) {
      if (formDataNew?.from_time < to_time) {
        setFormDataNew({
          ...formDataNew,
          to_time: to_time,
        });
        setFormValidationNew({
          ...formValidationNew,
          to_time: "",
        });
      } else {
        // setFormDataNew({
        //   ...formDataNew,
        //   to_time: "",
        // });
        setFormValidationNew({
          ...formValidationNew,
          to_time: "To Time should be greater then from time",
        });
      }
    } else {
      setFormDataNew({
        ...formDataNew,
        to_time: to_time,
      });
      setFormValidationNew({
        ...formValidationNew,
        to_time: "",
      });
    }
  };
  const validateTDepFromDate = (to_time) => {
    if (formData?.to_date == formDataNew?.from_date) {
      if (formData?.to_time < to_time) {
        setFormDataNew({
          ...formDataNew,
          from_time: to_time,
          to_time: "",
        });
        setFormValidationNew({
          ...formValidationNew,
          from_time: "",
        });
      } else {
        setFormDataNew({
          ...formDataNew,
          from_time: "",
        });
        setFormValidationNew({
          ...formValidationNew,
          from_time: "From time should be greater then departure time",
        });
      }
    } else {
      setFormDataNew({
        ...formDataNew,
        from_time: to_time,
      });
      setFormValidationNew({
        ...formValidationNew,
        from_time: "",
      });
    }
  };
  const handleFromDateChange = (date) => {
    let fromDate = moment(date).format("YYYY-MM-DD");
    let toDateValue =
      formDataNew?.to_date == ""
        ? moment(date).format("YYYY-MM-DD")
        : formDataNew?.to_date;
    let toWaytime =
      toTwoWayTime?.value == undefined
        ? toTimeValue?.value
        : toTwoWayTime?.value;
    let fromWayTime =
      fromTwoWayTime?.value == undefined
        ? fromTimeValue?.value
        : fromTwoWayTime?.value;
    if (type == "oneway") {
      if (date !== "") {
        setFromDate(date);
        setToDate(date);
        // isLeaveExists(date, date);
        isDataExists(fromDate, toDateValue, fromWayTime, toWaytime);
      }
    } else {
      setFromDate(date);
      isDataExists(
        fromDate,
        moment(formDataNew?.to_date).format("YYYY-MM-DD"),
        fromWayTime,
        formDataNew?.to_time
      );
    }
  };

  useEffect(() => {
    if (userBackDate?.length > 0) {
      let curData = userBackDate[userBackDate?.length - 1];
      setMinBackDateValue(curData?.opened_from);
      setMaxBackDateValue(curData?.to_date);
    }
  }, [userBackDate]);

  useEffect(() => {
    setFormData({ ...formData, to_date: fromDate });
  }, [fromDate]);

  const handleFromTimeChange = (event) => {
    setFromTimeValue(event);
    const { value } = event;
    let dateFrom = fromDate === "" ? FromDate : fromDate;
    let dateTo = toDate === "" ? ToDate : toDate;
    if (type === "oneway") {
      if (fromDate === "" || toDate === "" || toTime !== "") {
        setFromTime(value);
        isDataExists(
          moment(dateFrom).format("YYYY-MM-DD"),
          moment(dateTo).format("YYYY-MM-DD"),
          value,
          toTime
        );
      } else {
        setFromTime(value);
        isDataExists(
          moment(dateFrom).format("YYYY-MM-DD"),
          moment(dateTo).format("YYYY-MM-DD"),
          value,
          toTime
        );
      }
    } else {
      setFromTime(value);
      isDataExists(
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(formDataNew?.to_date).format("YYYY-MM-DD"),
        value,
        formDataNew?.to_time
      );
    }
  };
  const handleFromTimeDepChange = (event) => {
    const { value } = event;

    isDataExists(
      formData?.from_date,
      moment(formDataNew?.to_date).format("YYYY-MM-DD"),
      formData?.from_time,
      toTwoWayTime?.value
    );
  };

  const handleFromDateDepChange = (date) => {
    // const { value } = event;

    isDataExists(
      formData?.from_date,
      moment(date).format("YYYY-MM-DD"),
      formData?.from_time,
      toTwoWayTime?.value
    );
  };

  const handleToTimeChange = (event) => {
    setToTimeValue(event);
    const { value } = event;
    let dateFrom = fromDate === "" ? FromDate : fromDate;
    let dateTo = toDate === "" ? ToDate : toDate;
    if (type === "oneway") {
      if (fromDate === "" || toDate === "") {
        setToTime(value);
        isDataExists(
          moment(dateFrom).format("YYYY-MM-DD"),
          moment(dateTo).format("YYYY-MM-DD"),
          fromTime,
          value
        );
      } else {
        setToTime(value);
        isDataExists(
          moment(dateFrom).format("YYYY-MM-DD"),
          moment(dateTo).format("YYYY-MM-DD"),
          fromTime,
          value
        );
      }
    } else {
      isDataExists(
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(formDataNew?.to_date).format("YYYY-MM-DD"),
        fromTime,
        formDataNew?.to_time
      );
    }
  };
  const handleToTimeDepChange = (event) => {
    const { value } = event;
    isDataExists(
      moment(formData?.from_date).format("YYYY-MM-DD"),
      moment(formDataNew?.to_date).format("YYYY-MM-DD"),
      formData?.from_time,
      value
    );
  };

  const handleToDateDepChange = (date) => {
    // const { value } = event.target;
    isDataExists(
      moment(formData?.from_date).format("YYYY-MM-DD"),
      moment(date).format("YYYY-MM-DD"),
      formData?.from_time,
      toTwoWayTime?.value
    );
  };
  const customSelectStyle = {
    padding: "7px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#fff",
    color: "#333",
    cursor: "pointer",
    outline: "none",
    width: "150px",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    backgroundImage: "url('path/to/your/custom-arrow.png')",
    backgroundPosition: "right 8px center",
    backgroundRepeat: "no-repeat",
  };

  const timeOptionFunc = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const timeValue = `${formattedHour}:${formattedMinute}`;
        timeOptions.push({ label: timeValue, value: timeValue });
      }
    }
    const timeOptionsnew = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const timeValue = `${formattedHour}:${formattedMinute}`;
        timeOptionsnew.push({ label: timeValue, value: timeValue });
      }
    }

    setTimeOption(timeOptions);
    setTimeOptionNew(timeOptionsnew);
  };

  useEffect(() => {
    let data = timeOption?.map((option) => {
      return {
        value: option.value,
        label: option.label,
      };
    });
    setTimeSetValue(data);
  }, [timeOption]);

  useEffect(() => {
    let data = timeOptionNew?.map((option) => {
      return {
        value: option.value,
        label: option.label,
      };
    });
    setTimeSetValueNew(data);
  }, [timeOptionNew]);

  const handleDOJValid = (value) => {
    let DOJ = userData?.joiningDate?.date_of_joining;
    let date = value;
    if (moment(DOJ).isAfter(moment(date))) {
      setValidDateJoin(true);
    }
  };

  const handleBackDated = (value) => {
    let date = value;
    if (
      moment(date).isAfter(moment(maxBackDateValue)) ||
      moment(date).isBefore(moment(minBackDateValue))
    ) {
      setBackDatedError(true);
    } else {
      setBackDatedError(false);
    }
  };
  function scrollWin() {
    window.scrollTo(0, 100);
  }

  useEffect(() => {
    setFormData({
      ...formData,
      from_city_input: dataNew[0]?.location,
    });
  }, [dataNew]);
  useEffect(() => {
    if (userDetails?.username !== undefined) {
      getUsersDetails(userDetails?.username);
    }
    if (
      userDetails?.reporting_to_id == undefined &&
      userDetails?.reporting_to_id == null
    ) {
      setDisableButton(true);
    }
  }, [userDetails]);

  return (
    <>
      {/* <OnDuty data={travelInt} /> */}
      {isExist && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> This date and time is
          already reserved for another journey.
        </Alert>
      )}

      {validDateJoin && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> You can not travel
          before Date of joining
        </Alert>
      )}
      {isLeaveExist && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> You have already
          applied leave on this date.
        </Alert>
      )}
      {validTimeZone && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> Reaching time cannot be
          less than or same as departure time for same date
        </Alert>
      )}
      <div className="row px-4">
        <div className="col-md-2">
          <input
            // style={{ marginLeft: "10px" }}
            type="radio"
            name="typejourney"
            onChange={() => {
              setType("oneway");
              setFormDataNew({
                from_city_input: "",
                to_city_input: "",
                from_date: "",
                from_time: "",
                to_time: "",
                to_date: "",
                reason: "",
              });
              setFormData({
                from_city_input: dataNew[0]?.location,
                to_city_input: "",
                from_date: FromDate,
                from_time: "",
                to_time: "",
                to_date: ToDate,
                reason: "",
              });
              setFormValidation({
                from_city_input: "",
                to_city_input: "",
                from_date: "",
                from_time: "",
                to_time: "",
                to_date: "",
                reason: "",
                purpose_of_travel_id: "",
              });
              setPurposeValue([]);
              setPurposeValueNew([]);
            }}
            checked={type === "oneway"}
          />
          <label
            // class="logCheck d-inline-block mr-4"
            htmlFor="typejourney"
            style={{
              fontSize: "14px",
              marginBottom: "1px",
              fontWeight: "bold",
              marginLeft: "9px",
            }}
          >
            One Way
            {/* <span class="checkmark"></span> */}
          </label>
        </div>
        <div className="col-md-3 ">
          <input
            // style={{ marginLeft: "-5px" }}
            type="radio"
            name="typejourney"
            onChange={() => {
              setType("twoway");
              setFormData({
                from_city_input: dataNew[0]?.location,
                to_city_input: "",
                from_date: FromDate,
                from_time: "",
                to_time: "",
                to_date: ToDate,
                reason: "",
                purpose_of_travel_id: "",
              });
              setFormValidation({
                from_city_input: "",
                to_city_input: "",
                from_date: "",
                from_time: "",
                to_time: "",
                to_date: "",
                reason: "",
                purpose_of_travel_id: "",
              });
              setFormValidationNew({
                from_city_input: "",
                to_city_input: "",
                from_date: "",
                from_time: "",
                to_time: "",
                to_date: "",
                reason: "",
                purpose_of_travel_id: "",
              });
              setPurposeValue([]);
              setPurposeValueNew([]);
            }}
            checked={type === "twoway"}
          />
          <label
            htmlFor="typejourney"
            // class="logCheck d-inline-block" style={{ fontSize: "12px" }}
            style={{
              fontSize: "14px",
              marginBottom: "1px",
              fontWeight: "bold",
              marginLeft: "9px",
            }}
          >
            Two Way
            {/* <span class="checkmark"></span> */}
          </label>
        </div>
      </div>
      <div class="px-4 pb-2">
        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group innergroup">
                <label>
                  From Location<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="text"
                  class="form-control"
                  name="from_city_input"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      from_city_input: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      from_city_input: "",
                    });
                  }}
                  value={formData?.from_city_input}
                />
                {/* <Select
                  // style={{ fontSize: "4px" }}
                  options={cityData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="from_city_input"
                  onChange={(e) => {
                    pushCityId(e);
                    setFormValidation({
                      ...formValidation,
                      from_city_input: "",
                    });
                  }}
                  value={cityValue}
                /> */}
                <div className="small text-danger">
                  {formValidation.from_city_input}
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group innergroup d-flex flex-column">
                <label>
                  From Date<span class="text-danger">*</span>
                </label>
                {/* <input
                  // style={{ fontSize: "13px" }}
                  // style={customSelectStyle}
                  type="date"
                  maxlength="4"
                  className="form-control dateIconClick"
                  name="from_date"
                  min={
                    userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? minBackDateValue
                      : minDateValue
                  }
                  max={
                    (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate))) &&
                    maxBackDateValue
                  }
                  onBlur={(e) => {
                    if (
                      userBackDate?.length > 0 &&
                      moment(maxBackDateValue).isAfter(moment(valueDate))
                    ) {
                      handleBackDated(e.target.value);
                    }
                  }}
                  onChange={(e) => {
                    handleFromDateChange(e);
                    handleDOJValid(e.target.value);
                    setFormData({
                      ...formData,
                      from_date: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  value={formData?.from_date}
                /> */}
                <DatePicker
                  selected={
                    formData?.from_date ? new Date(formData?.from_date) : ""
                  }
                  onChange={(date) => {
                    handleFromDateChange(date);
                    handleDOJValid(date);
                    setFormData({
                      ...formData,
                      from_date: date,
                    });
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  minDate={
                    userBackDate?.length > 0 &&
                    (moment(maxBackDateValue).isSame(valueDate) ||
                      moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                      moment(maxBackDateValue).isSame(moment(valueDate)))
                      ? new Date(minBackDateValue)
                      : new Date(minDateValue)
                  }
                  maxDate={new Date().setDate(new Date().getDate() + 90)}
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                />
                <div className="small text-danger">
                  {formValidation.from_date}
                </div>
                {backDatedError && (
                  <div className="small text-danger">
                    You cannot enter BackDate
                  </div>
                )}
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group innergroup">
                <label>
                  From Time<span class="text-danger">*</span>
                </label>

                <Select
                  name="from_time"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  style={customSelectStyle}
                  options={timeSetValue}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      from_time: e.value,
                      to_time: "",
                    });
                    handleFromTimeChange(e);
                    setFormValidation({
                      ...formValidation,
                      from_time: "",
                    });
                  }}
                  value={fromTimeValue}
                ></Select>
                {/* <input
                  type="time"
                  class="form-control"
                  name="from_time"
                  onChange={(e) => {
                    handleFromTimeChange(e);
                    setFormData({
                      ...formData,
                      from_time: e.target.value,
                      to_time: "",
                    });
                    setFormValidation({
                      ...formValidation,
                      from_time: "",
                    });
                  }}
                  value={formData?.from_time}
                /> */}

                <div className="small text-danger">
                  {formValidation.from_time}
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group innergroup">
                <label>
                  To Location<span class="text-danger">*</span>
                </label>

                <input
                  style={{ fontSize: "13px" }}
                  type="text"
                  class="form-control"
                  name="to_city_input"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      to_city_input: e.target.value,
                    });
                    setFormDataNew({
                      ...formDataNew,
                      from_city_input: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      to_city_input: "",
                    });
                  }}
                  value={formData?.to_city_input}
                />
                <div className="small text-danger">
                  {formValidation.to_city_input}
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group innergroup d-flex flex-column">
                <label>
                  To Date<span class="text-danger">*</span>
                </label>
                {/* <input
                  style={{ fontSize: "13px" }}
                  type="date"
                  maxlength="4"
                  class="form-control "
                  name="to_date"
                  disabled
                  min={moment(formData?.from_date).format("YYYY-MM-DD")}
                  // onChange={(e) => {
                  //   handleToDateChange(e);
                  //   setFormData({
                  //     ...formData,
                  //     to_date: formData.from_date,
                  //   });
                  //   setFormValidation({
                  //     ...formValidation,
                  //     to_date: "",
                  //   });
                  // }}
                  value={formData?.from_date}
                /> */}
                <div className="form-control">
                  {formData?.from_date && formData?.from_date !== ""
                    ? moment(formData?.from_date).format("DD-MM-YYYY")
                    : ""}
                </div>
                <div className="small text-danger">
                  {formValidation.to_date}
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group innergroup">
                <label>
                  To Time<span class="text-danger">*</span>
                </label>
                <Select
                  name="to_time"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  style={customSelectStyle}
                  options={timeSetValue}
                  onChange={(e) => {
                    handleToTimeChange(e);
                    validateToDate(e.value);
                  }}
                  value={toTimeValue}
                ></Select>

                {/* <input
                  type="time"
                  class="form-control"
                  name="to_time"
                  // max={moment(new Date()).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    handleToTimeChange(e);
                    validateToDate(e.target.value);
                  }}
                  value={formData?.to_time}
                /> */}
                <div className="small text-danger">
                  {formValidation.to_time}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>
                  Purpose of Travel<span class="text-danger">*</span>
                </label>

                <Select
                  options={purpose}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="purpose_of_travel_id"
                  onChange={(e) => {
                    pushPurpose(e);
                    setFormValidation({
                      ...formValidation,
                      purpose_of_travel_id: "",
                    });
                  }}
                  value={purposeValue}
                />
                <div className="small text-danger">
                  {formValidation.purpose_of_travel_id}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>Remark/ Visit</label>
                <input
                  type="text"
                  maxLength={100}
                  class="form-control"
                  name="reason"
                  placeholder="Enter Remark/ Visit ..."
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      reason: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      reason: "",
                    });
                  }}
                  value={formData?.reason}
                />
              </div>
            </div>
          </div>
        </div>
        {type === "twoway" ? (
          <div class="bg-light px-3 pt-3 mb-3">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group innergroup">
                  <label>From Location</label>
                  {/* <Select
                  options={cityDataNew}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="from_city_input"
                  onChange={(e) => {
                    pushCityIdNew(e);
                    setFormValidationNew({
                      ...setFormValidationNew,
                      from_city_input: "",
                    });
                  }}
                  value={cityValueNew}
                /> */}
                  <input
                    style={{ fontSize: "13px" }}
                    type="text"
                    class="form-control"
                    name="from_city_input"
                    onChange={(e) => {
                      setFormDataNew({
                        ...formDataNew,
                        from_city_input: e.target.value,
                      });
                      setFormValidationNew({
                        ...setFormValidationNew,
                        from_city_input: "",
                      });
                    }}
                    value={formDataNew?.from_city_input}
                  />
                  <div className="small text-danger">
                    {formValidationNew.from_city_input}
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group innergroup d-flex flex-column">
                  <label>
                    From Date<span class="text-danger">*</span>
                  </label>
                  {/* <input
                    style={{ fontSize: "13px" }}
                    type="date"
                    class="form-control dateIconClick"
                    name="from_date"
                    min={moment(toDate).format("YYYY-MM-DD")}
                    max={
                      userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate))) &&
                      maxBackDateValue
                    }
                    onChange={(e) => {
                      setFormDataNew({
                        ...formDataNew,
                        from_date: e.target.value,
                        to_date: "",
                      });
                      handleFromDateDepChange(e);
                      setFormValidationNew({
                        ...formValidationNew,
                        from_date: "",
                      });
                    }}
                    value={formDataNew?.from_date}
                  /> */}
                  <DatePicker
                    selected={
                      formDataNew?.from_date
                        ? new Date(formDataNew?.from_date)
                        : ""
                    }
                    onChange={(date) => {
                      setFormDataNew({
                        ...formDataNew,
                        from_date: date,
                        to_date: "",
                      });
                      handleFromDateDepChange(date);
                      setFormValidationNew({
                        ...formValidationNew,
                        from_date: "",
                      });
                    }}
                    minDate={
                      userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isSame(valueDate) ||
                        moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate)))
                        ? new Date(minBackDateValue)
                        : new Date(minDateValue)
                    }
                    // maxDate={
                    //   userBackDate?.length > 0 &&
                    //   (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                    //     moment(maxBackDateValue).isSame(moment(valueDate)))
                    //     ? new Date(maxBackDateValue)
                    //     : new Date(minDateValue)
                    // }
                    placeholderText="DD-MM-YYYY"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                  <div className="small text-danger">
                    {formValidationNew.from_date}
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group innergroup">
                  <label>
                    From Time<span class="text-danger">*</span>
                  </label>

                  <Select
                    name="from_time"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={customSelectStyle}
                    options={timeSetValueNew}
                    onChange={(e) => {
                      setfromTwoWayTime(e);
                      validateTDepFromDate(e.value);
                      handleFromTimeDepChange(e);
                    }}
                    value={fromTwoWayTime}
                  ></Select>

                  {/* <input
                    type="time"
                    class="form-control"
                    name="from_time"
                    onChange={(e) => {
                      validateTDepFromDate(e.target.value);
                    }}
                    value={formDataNew?.from_time}
                  /> */}
                  <div className="small text-danger">
                    {formValidationNew.from_time}
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group innergroup">
                  <label>
                    To Location<span class="text-danger">*</span>
                  </label>
                  <input
                    style={{ fontSize: "13px" }}
                    type="text"
                    class="form-control"
                    name="to_city_input"
                    onChange={(e) => {
                      setFormDataNew({
                        ...formDataNew,
                        to_city_input: e.target.value,
                      });
                      setFormValidationNew({
                        ...setFormValidationNew,
                        to_city_input: "",
                      });
                    }}
                    value={formDataNew?.to_city_input}
                  />
                  {/* <Select
                  // isDisabled={true}
                  options={cityDataToNew}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  name="to_city_input"
                  onChange={(e) => {
                    pushCityIdToNew(e);
                    setFormValidationNew({
                      ...formValidationNew,
                      to_city_input: "",
                    });
                  }}
                  value={cityValueToNew}
                /> */}
                  <div className="small text-danger">
                    {formValidationNew.to_city_input}
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group innergroup d-flex flex-column">
                  <label>
                    To Date<span class="text-danger">*</span>
                  </label>

                  {/* <input
                    style={{ fontSize: "13px" }}
                    type="date"
                    class="form-control dateIconClick"
                    name="to_date"
                    onChange={(e) => {
                      setFormDataNew({
                        ...formDataNew,
                        to_date: e.target.value,
                      });
                      handleToDateDepChange(e);
                      setFormValidationNew({
                        ...formValidationNew,
                        to_date: "",
                      });
                    }}
                    min={moment(formDataNew?.from_date).format("YYYY-MM-DD")}
                    max={
                      userBackDate?.length > 0 &&
                      (moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                        moment(maxBackDateValue).isSame(moment(valueDate))) &&
                      maxBackDateValue
                    }
                    value={formDataNew?.to_date}
                  /> */}
                  <DatePicker
                    selected={
                      formDataNew?.to_date ? new Date(formDataNew?.to_date) : ""
                    }
                    onChange={(date) => {
                      setFormDataNew({
                        ...formDataNew,
                        to_date: date,
                      });
                      handleToDateDepChange(date);
                      setFormValidationNew({
                        ...formValidationNew,
                        to_date: "",
                      });
                    }}
                    minDate={new Date(formDataNew?.from_date)}
                    // maxDate={
                    //   userBackDate?.length > 0 &&
                    //   (moment(maxBackDateValue).isSame(valueDate) ||
                    //     moment(maxBackDateValue).isAfter(moment(valueDate)) ||
                    //     moment(maxBackDateValue).isSame(moment(valueDate)))
                    //     ? new Date(minBackDateValue)
                    //     : new Date(formDataNew?.from_date)
                    // }
                    placeholderText="DD-MM-YYYY"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                  <div className="small text-danger">
                    {formValidationNew.to_date}
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group innergroup">
                  <label>
                    To Time<span class="text-danger">*</span>
                  </label>
                  <Select
                    name="to_time"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={customSelectStyle}
                    options={timeSetValueNew}
                    onChange={(e) => {
                      setToTwoWayTime(e);
                      validateToDateNew(e.value);
                      handleToTimeDepChange(e);
                    }}
                    value={toTwoWayTime}
                  ></Select>
                  {/* <input
                    type="time"
                    class="form-control"
                    name="to_time"
                    // max={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      validateToDateNew(e.target.value);
                    }}
                    value={formDataNew?.to_time}
                  /> */}
                  {/* {checkTime == true ? (
                  <div className="small text-danger">
                    To Time cannot be same as from time
                  </div>
                ) : (
                  ""
                )} */}

                  <div className="small text-danger">
                    {formValidationNew.to_time}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Purpose of Travel<span class="text-danger">*</span>
                  </label>

                  <Select
                    options={purposeNew}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="purpose_of_travel_id"
                    onChange={(e) => {
                      pushPurposeNew(e);
                      setFormValidationNew({
                        ...formValidationNew,
                        purpose_of_travel_id: "",
                      });
                    }}
                    value={purposeValueNew}
                  />
                  <div className="small text-danger">
                    {formValidationNew.purpose_of_travel_id}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>Remark/ Visit</label>
                  <input
                    type="text"
                    placeholder="Enter Remark/ Visit ..."
                    class="form-control"
                    name="reason"
                    maxLength={100}
                    onChange={(e) => {
                      setFormDataNew({
                        ...formDataNew,
                        reason: e.target.value,
                      });
                      setFormValidationNew({
                        ...formValidationNew,
                        reason: "",
                      });
                    }}
                    value={formDataNew?.reason}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="mb-3">
          <div class="row">
            <div class="col-md-12">
              <div class="border-top my-3"></div>
            </div>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <div class="col-md-12 mt-3 text-center">
                {/* {edit == true ? ( */}
                <button
                  class="btn btn-outline-secondary mr-2"
                  onClick={() => navigate("/travel-listing")}
                >
                  Back
                </button>
                {/* ) : (
                  ""
                )} */}

                <button
                  class="btn btn-primary-inner bpi-main"
                  type="submit"
                  disabled={disableButton}
                  onClick={() => {
                    handleValidateForm();
                  }}
                >
                  <i class="far fa-check-circle"></i> Submit
                </button>
              </div>
            )}
          </div>
        </div>
        <div class="travel-note">
          <h4>Notes :-</h4>
          <ul>
            <li>
              Attendance and Travel claim will be marked/submit on the basis of
              approved tour intimation.
            </li>
            <li>Time to be filled as per 24 Hr clock.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AddTravelIntimation;
