import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from "./../include/sidemenu";
import Header from "./../include/header";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import AllUrlsCashbook from "./AllUrlsCashbook";
import AddExpense from "./AddExpense";

function ManageExpenseGrid() {
  const navigate = useNavigate();
  const [activeTab, setactivetab] = useState("Topup");
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  return (
    <>
      <div>
        <div class="row">
          <AllUrlsCashbook />
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Manage Expenses</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                {activeTab == "Topup" ? (
                  ""
                ) : (
                  <div className="row">
                    <div class="col-lg-4">
                      <div class="input-group table-search">
                        <span class="input-group-text">
                          <img src="images/search.png" alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <Dropdown show>
                          <Dropdown.Toggle
                            variant="primary-inner dropdownbtn"
                            id="dropdown-basic"
                          >
                            <img src="images/export.png" alt="" class="mr-2" />{" "}
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#">
                              <i class="far fa-file-excel"></i>Excel
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          onClick={filteropen}
                          class="btn btn-secondary-inner"
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div class="approval-bg no-bg border-0 purchaseinnertabs">
                <Tabs
                  defaultActiveKey={activeTab}
                  id="approvaltabs"
                  onSelect={(e) => {
                    setactivetab(e);
                  }}
                >
                  <Tab
                    eventKey="Topup"
                    title={<React.Fragment>Add Expenses</React.Fragment>}
                  >
                    <AddExpense setactivetab={() => setactivetab("Topup")} />
                  </Tab>
                  <Tab
                    eventKey="purchase"
                    title={<React.Fragment>Status report</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Request ID</th>
                            <th>Created By</th>
                            <th>Claim Amount</th>
                            <th>Approved Amount</th>
                            <th>Status</th>
                            <th>Approver</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td class="text-theme">
                              <Link
                                to="/view-cash-expenses"
                                class="fw-bold text-theme"
                              >
                                PICB6841
                              </Link>
                            </td>
                            <td>Anoop Srivastava</td>
                            <td>940.00</td>
                            <td>940.00</td>
                            <td>
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            </td>
                            <td>Mahesh Soni</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td class="text-theme">
                              <Link
                                to="/view-cash-expenses"
                                class="fw-bold text-theme"
                              >
                                PICB6841
                              </Link>
                            </td>
                            <td>Anoop Srivastava</td>
                            <td>940.00</td>
                            <td>940.00</td>
                            <td>
                              <span class="badge bad-status badge-danger">
                                Rejected
                              </span>
                            </td>
                            <td>Mahesh Soni</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td class="text-theme">
                              <Link
                                to="/view-cash-expenses"
                                class="fw-bold text-theme"
                              >
                                PICB6841
                              </Link>
                            </td>
                            <td>Anoop Srivastava</td>
                            <td>940.00</td>
                            <td>940.00</td>
                            <td>
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            </td>
                            <td>Mahesh Soni</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td class="text-theme">
                              <Link
                                to="/view-cash-expenses"
                                class="fw-bold text-theme"
                              >
                                PICB6841
                              </Link>
                            </td>
                            <td>Anoop Srivastava</td>
                            <td>940.00</td>
                            <td>940.00</td>
                            <td>
                              <span class="badge bad-status badge-warning">
                                Pending
                              </span>
                            </td>
                            <td>Mahesh Soni</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td class="text-theme">
                              <Link
                                to="/view-cash-expenses"
                                class="fw-bold text-theme"
                              >
                                PICB6841
                              </Link>
                            </td>
                            <td>Anoop Srivastava</td>
                            <td>940.00</td>
                            <td>940.00</td>
                            <td>
                              <span class="badge bad-status badge-danger">
                                Rejected
                              </span>
                            </td>
                            <td>Mahesh Soni</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select class="form-control">
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <Pagination>
                          <Pagination.Prev className="previpag" />
                          <Pagination.Item>{1}</Pagination.Item>
                          <Pagination.Item active>{2}</Pagination.Item>
                          <Pagination.Ellipsis />
                          <Pagination.Item>{9}</Pagination.Item>
                          <Pagination.Item>{10}</Pagination.Item>
                          <Pagination.Next className="previpag" />
                        </Pagination>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="approved"
                    title={<React.Fragment>Entry Reversal</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured">
                        <thead>
                          <tr>
                            <th>Doc. No</th>
                            <th>Status</th>
                            <th>GL Desc</th>
                            <th>Bill Description</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-theme">234455</td>
                            <td>
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            </td>
                            <td>388019 - PRofessioanl Tax Payable</td>
                            <td>Courier Expenses</td>
                            <td>940.00</td>
                            <td>10-03-2023</td>
                            <td>
                              <button class="btn btn-blanktd text-success">
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-theme">234455</td>
                            <td>
                              <span class="badge bad-status badge-warning">
                                Pending
                              </span>
                            </td>
                            <td>388019 - PRofessioanl Tax Payable</td>
                            <td>Courier Expenses</td>
                            <td>940.00</td>
                            <td>10-03-2023</td>
                            <td>
                              <button class="btn btn-blanktd text-success">
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-theme">234455</td>
                            <td>
                              <span class="badge bad-status badge-danger">
                                Rejected
                              </span>
                            </td>
                            <td>388019 - PRofessioanl Tax Payable</td>
                            <td>Courier Expenses</td>
                            <td>940.00</td>
                            <td>10-03-2023</td>
                            <td>
                              <button class="btn btn-blanktd text-success">
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-theme">234455</td>
                            <td>
                              <span class="badge bad-status badge-success">
                                Approved
                              </span>
                            </td>
                            <td>388019 - PRofessioanl Tax Payable</td>
                            <td>Courier Expenses</td>
                            <td>940.00</td>
                            <td>10-03-2023</td>
                            <td>
                              <button class="btn btn-blanktd text-success">
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-theme">234455</td>
                            <td>
                              <span class="badge bad-status badge-danger">
                                Rejected
                              </span>
                            </td>
                            <td>388019 - PRofessioanl Tax Payable</td>
                            <td>Courier Expenses</td>
                            <td>940.00</td>
                            <td>10-03-2023</td>
                            <td>
                              <button class="btn btn-blanktd text-success">
                                <i class="far fa-check-circle"></i>{" "}
                              </button>
                              <button class="btn btn-blanktd text-danger ml-2">
                                <i class="far fa-times-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select class="form-control">
                            <option>10</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <Pagination>
                          <Pagination.Prev className="previpag" />
                          <Pagination.Item>{1}</Pagination.Item>
                          <Pagination.Item active>{2}</Pagination.Item>
                          <Pagination.Ellipsis />
                          <Pagination.Item>{9}</Pagination.Item>
                          <Pagination.Item>{10}</Pagination.Item>
                          <Pagination.Next className="previpag" />
                        </Pagination>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              From Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>
          <div class="form-group innergroup">
            <label>
              To Date<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" />
          </div>
          <div class="form-group innergroup">
            <label>
              Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Approved</option>
              <option>Rejected</option>
              <option>Pending</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ManageExpenseGrid;
