/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import * as ClaimTravel from "../../service/claimTravel";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import * as TravelListingAPI from "../../service/travelService";
import { Spinner, Alert, NavItem } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

if (typeof window !== "undefined") {
  injectStyle();
}

const Ticket = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setCheckActiveTab, orgCode } = props;
  const userData = useSelector(selectUserData);
  const [airline, setAirline] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [taxRate, setTaxRate] = useState([]);
  const [gstState, setGstState] = useState([]);
  const [newData, setNewData] = useState([state?.travel_detail_data]);
  const [allData, setAllData] = useState([]);
  const [ticketTotal, setTicketTotal] = useState({ final_tax_bill_amount: 0 });
  const [ticketCondition, setTicketCondition] = useState([]);
  const [fcmData, setFcmData] = useState([]);
  const [newAddData, setNewAddData] = useState([]);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [taxValueInt, setTaxValueInt] = useState();
  const [invoiceChk, setInvoiceChk] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [vCat, setVCat] = useState(false);
  const [gstValidate, setGstValidate] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);
  const [flag, setflag] = useState(false);
  const [deleteattachment, setDeleteAttachment] = useState([]);
  const [gstTicketError, setGstTicketError] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [indexValueNew, setIndexValueNew] = useState();
  const [compareTicket, setCompareTicket] = useState();
  const [visibleD, setVisibleD] = useState(false);
  const [ticketDateModal, setTicketDateModal] = useState(false);
  const [ticketDateOption, setTicketDateOption] = useState([]);
  const [ticketDate, setTicketDate] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [doubleClick, setDoubleClick] = useState("false");
  const [formValidation, setFormValidation] = useState([
    {
      paid_by: "",
      vendor_name: "",
      airline_id: "",
      vendor_category: "",
      vendor_gst_state: "",
      vendor_gst_no: "",
      invoice_no: "",
      invoice_date: "",
      ticket_no: "",
      pnr_no: "",
      taxable_amount: "",
      tax_rate_id: "",
      tax_amount: "",
      other_charges: "",
      tax_bill_amount: "",
      isAllFieldEmpty: true,
    },
  ]);
  const [newAddErr, setNewAddErr] = useState([]);
  let isAllFieldEmpty = true;
  let errEle = null;

  const defaultGst = {
    PIJA: {
      stateId: "27",
      gst: "27AAECJ7119A1ZV",
    },
    PIHS: {
      stateId: "08",
      gst: "08AAMCP2623D1ZK",
    },
    PILS: {
      stateId: "08",
      gst: "08AADCP4552G1ZF",
    },
    Other: {
      stateId: "06",
      gst: "06AABCP2183M1Z9",
    },
  };

  const [alertMsg, setAlertMsg] = useState(null);
  const [shouldDeleteAttachment, setShouldDleteAttachment] = useState(true);
  const alertRef = useRef();

  const handleKeyPress = (e) => {
    ["e", "E", "+", "-", "ArrowDown"].includes(e.key) && e.preventDefault();
  };

  const onProfileChange = async (e, flag) => {
    if (shouldDeleteAttachment) {
      let delAtt = [...deleteattachment];
      attachment.forEach((obj) => delAtt.push(obj.id));
      setDeleteAttachment(delAtt);
      setShouldDleteAttachment(false);
    }
    let valid_files_index = [];
    let _file = [];
    let valid_mime = [
      "image/jpeg", // for JPEG
      "image/png", // for PNG
      "application/pdf", // for PDF
      // MSG files don't have a standard MIME type;  might need custom handling
      "application/msword", // for DOC
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for DOCX
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for XLSX
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12", // for XLSB
    ];

    for (let i = 0; i < e.target.files.length; i++) {
      if (valid_mime.includes(e.target.files[i].type)) {
        valid_files_index.push(i);
        _file.push(e.target.files[i]);
      }
    }
    let ooold = [...file, ..._file];
    let tmp_filesToUpload = [...filesToUpload];
    setfiles(ooold);

    let files = [];
    let myAllPic = [];
    for (let i = 0; i < e.target.files.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);

      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      tmp_filesToUpload.push(fileData);

      // await TravelListingAPI.uploadFile(fileData).then((response) => {
      //   let name = { name: data.name };
      //   filename.push(name);
      //   files.push(response.data);
      // });
    }
    setFilesToUpload(tmp_filesToUpload);
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };

  const getTicketData = (ticket_id) => {
    ClaimTravel.getTicketData(ticket_id)
      .then((response) => {
        setTicketCondition(response?.data?.dataList?.result);
        setAllData(response?.data?.dataList?.result);
        setTicketTotal({
          final_tax_bill_amount:
            Math.round(response?.data?.dataList.final_tax_bill_amount * 100) /
            100,
        });
        setAttachment(response?.data?.dataList?.attachment_path);
        setUploadDataIm([]);
        setfiles([]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const removeNullVlaue = (data) => {
    let data2 = [];
    data.map((x) => {
      for (let key in x) {
        if (x.hasOwnProperty(key)) {
          let value = x[key];
          if (value === null || value === "") {
            delete x[key];
          }
        }
      }
      data2.push(x);
    });
    return data2;
  };
  const saveTicket = async () => {
    if (doubleClick == "false") {
      setDoubleClick("true");
      if (newAddData.length > 0) updateTicket([], newAddData);
      dispatch(setDisplayLoader("Display"));
      const Travel_date = "travel_date";
      const from_loc = "from_location_id";
      const to_loc = "to_location_id";
      const mode = "mode_id";
      const from_location_input = "from_location_input";
      const Invoice_date = "invoice_date";

      const newArray = newData.map((item, index) => ({
        ...item,
        [Travel_date]: `${moment
          .parseZone(item.from_date)
          .format("YYYY-MM-DD")}`,
        [Invoice_date]: item?.invoice_date
          ? `${moment.parseZone(item.invoice_date).format("YYYY-MM-DD")}`
          : "",
        [from_loc]: `${Number(item.from_city_id)}`,
        [to_loc]: `${Number(item.to_city_id)}`,
        [mode]: Number(item.mode_id) == 0 ? null : `${Number(item.mode_id)}`,
        [from_location_input]: `${Number(
          state?.travel_detail_data?.from_city_input
        )}`,
      }));
      // let dataNew = newArray?.splice?.apply(
      //   newArray,
      //   [newLength, 0].concat(newAddData)
      // );
      let files = [];
      for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
        await TravelListingAPI.uploadFile(filesToUpload[afilee])
          .then((response) => {
            // let name = { name: data.name };
            // filename.push(name);
            files.push(response.data);
          })
          .catch((error) => {
            dispatch(setDisplayLoader("Hide"));

            toast.error(`${error} `, {
              autoClose: 2000,
            });
            return;
          });
      }

      let payload = {
        travel_id: state?.id,
        attachment_path: files,
        attachment_delete: deleteattachment,
        ticket_data: removeNullVlaue(newArray),
      };
      ClaimTravel.saveTicket(payload)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            toast.success(response.data.message);
            getTicketData(state?.id);
            setCheckActiveTab();
            setNewAddData([]);
            setDoubleClick("false");
            setFilesToUpload([]);
            // navigate("/expense-listing");
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateTicket = async (data1, data2) => {
    if (doubleClick == "false") {
      setDoubleClick("true");
      dispatch(setDisplayLoader("Display"));
      const Invoice_date = "invoice_date";
      let dataNew = [...data1, ...data2];
      // let dataNew = data1?.splice?.apply(
      //   data1,
      //   [newLength, 0].concat(data2)
      //   );
      const newArray = dataNew.map((item) => ({
        ...item,
        [Invoice_date]:
          item.invoice_date != null
            ? `${moment.parseZone(item.invoice_date).format("YYYY-MM-DD")}`
            : null,
        tax_rate_id: !item?.tax_rate_id ? null : item.tax_rate_id,
        airline_id: !item?.airline_id ? null : item.airline_id,
      }));

      let files = [];
      for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
        await TravelListingAPI.uploadFile(filesToUpload[afilee])
          .then((response) => {
            // let name = { name: data.name };
            // filename.push(name);
            files.push(response.data);
          })
          .catch((error) => {
            dispatch(setDisplayLoader("Hide"));

            toast.error(`${error} `, {
              autoClose: 2000,
            });
            return;
          });
      }
      let payload = {
        travel_id: state?.id,
        attachment_path: files,
        attachment_delete: deleteattachment,
        ticket_data: removeNullVlaue(newArray),
      };
      ClaimTravel.updateTicket(payload, state?.id)
        .then((response) => {
          dispatch(setDisplayLoader("Hide"));
          if (response.data.status === true && response.data.code === 200) {
            if (data1.length > 0) toast.success(response.data.message);
            getTicketData(state?.id);
            setCheckActiveTab();
            setNewAddData([]);
            setDoubleClick("false");
            setFilesToUpload([]);
          }
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getAirline = () => {
    ClaimTravel.getAirline()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.airline_data?.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.name,
          };
          data.push(data1);
        });
        setAirline(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleClose = () => {
    setVisibleD(false);
  };
  const getTaxRate = () => {
    ClaimTravel.getTaxRate()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.tax_rate_data?.map((item) => {
          let data1 = {
            value: item?.id,
            label: item?.name,
          };
          data.push(data1);
        });
        setTaxRate(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getFcmList = async (index, gst_no, isNew) => {
    await TravelListingAPI.getFcmList(gst_no)
      .then((response) => {
        let res = response?.data?.dataList?.result;
        if (gst_no !== "") {
          setFcmData(res);
          if (res.length > 0) {
            setAlertMsg(
              "You have entered PI GST No. instead of vendor GST No."
            );
          } else {
            setAlertMsg(null);
          }
        } else {
          firstdata(index, res, isNew);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getGstState = (paginate) => {
    ClaimTravel.getGstState(paginate)
      .then((response) => {
        let data = response?.data?.dataList?.result.map((item) => {
          let code =
            item?.gst_state_code?.length === 1
              ? 0 + item?.gst_state_code
              : item?.gst_state_code;
          return {
            value: item.id,
            label: `${code} -${item?.name} `,
          };
        });
        setGstState(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const firstdata = (index, fcmData1, isNew) => {
    let data = JSON.parse(JSON.stringify(isNew ? newAddData : newData));
    data[index]["vendor_name"] = fcmData1[0]?.name;
    data[index]["vendor_gst_no"] = fcmData1[0]?.gst_no;
    data[index]["vendor_gst_state"] =
      fcmData1[0]?.state_id.toString().length == 1
        ? `${0}${fcmData1[0]?.state_id}`
        : fcmData1[0]?.state_id;
    data[index]["paid_by"] = "company";
    data[index]["invoice_no"] = "";
    data[index]["vendor_category"] = "Registered";
    if (isNew) {
      setNewAddData(data);
    } else {
      setNewData(data);
    }
  };

  const handleValidateGst = (i) => {
    let data = JSON.parse(JSON.stringify(newData));
    let value = data[i].vendor_gst_no;
    if (data[i].paid_by == "self") {
      getFcmList(i, value);
    }
  };

  // const debounce = (func, delay) => {
  //   let timeout = 0;
  //   return (...args) => {
  //     timeout = setTimeout(() => func(...args), delay);
  //     clearTimeout(timeout - 1);
  //   }
  // }

  // const scrollToAlert = () => {
  //   alertRef?.current?.scrollIntoView({ behavior: 'smooth' })
  // }

  // const scrollToAlertWithDebounce = debounce(scrollToAlert, 500);

  const changeHandler = (value, name, index) => {
    errEle = null;
    setIndexValue(index);
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let data = JSON.parse(JSON.stringify(newData));
    if (name === "paid_by") {
      if (value === "company" && data[index]["mode_id"] != 4) {
        data[index]["vendor_name"] = "FCM";
        data[index]["vendor_gst_no"] = defaultGst[orgCode].gst;
        data[index]["vendor_gst_state"] = defaultGst[orgCode].stateId;
        data[index]["vendor_category"] = "Registered";
        handleTaxRateChange("", index, "ven_cat");
      } else {
        data[index]["vendor_category"] = "Not-Registered";
      }
    }
    if (value === "self" && name === "paid_by") {
      data[index]["vendor_name"] = "";
      data[index]["vendor_gst_no"] = "";
      data[index]["vendor_gst_state"] = "";
      data[index]["vendor_category"] = "";
    }
    if (
      value === "self" &&
      name === "paid_by" &&
      data[index]["vendor_category"] == ""
    ) {
      setVCat(true);
    } else {
      setVCat(false);
    }

    if (value != "Company" && name == "vendor_name") {
      data[index]["vendor_name"] = "";
    }
    if (value === "Not-Registered" && name === "vendor_category") {
      data[index]["vendor_gst_state"] = "";
      data[index]["vendor_gst_no"] = "";
      data[index]["vendor_gst_no"] = "";
    }

    if (name === "vendor_gst_no") {
      if (regexforGst.test(value) === false) {
        setGstValidate(true);
        setAlertMsg(
          "Invoice Number should Start from U and Y and Length should not be less then 8"
        );
      } else {
        setGstValidate(false);
        setAlertMsg(null);
      }
    }

    // if (data[index].paid_by === "company" && name === "invoice_no") {
    //   if (
    //     (Array.from(value)[0] == "Y" || Array.from(value)[0] == "U") &&
    //     value.length == 8 &&
    //     value !== ""
    //   ) {
    //     setInvoiceChk(prev => prev.filter(idx => idx !== index));
    //     setAlertMsg(null);
    //   }
    //   else {
    //     if(!invoiceChk.includes(index)) setInvoiceChk(prev => [...prev, index]);
    //     setAlertMsg(
    //       "Invoice Number should Start from U and Y and Length should not be less then 8"
    //     );
    //     if (value !== "") scrollToAlertWithDebounce()
    //   }
    // } else
    if (data[index].paid_by === "self") {
      // setInvoiceChk(prev => prev.filter(idx => idx !== index));
      setAlertMsg(null);
    }

    if (data[index].paid_by === "company" && name === "invoice_no") {
      if (value.length <= 8) data[index].invoice_no = value;
    } else if (name == "invoice_date") {
      data[index][`${name}`] = value ? moment(value).format("YYYY-MM-DD") : "";
    } else {
      data[index][`${name}`] = value;
    }
    setNewData(data);
  };

  const changeHandlerNew = (value, name, index) => {
    errEle = null;
    setIndexValueNew(index);
    let data = JSON.parse(JSON.stringify(newAddData));
    if (name === "paid_by") {
      if (value === "company" && data[index]["mode_id"] != 4) {
        data[index]["vendor_name"] = "FCM";
        data[index]["vendor_gst_no"] = defaultGst[orgCode].gst;
        data[index]["vendor_gst_state"] = defaultGst[orgCode].stateId;
        data[index]["vendor_category"] = "Registered";
        handleTaxRateChangeNew("", index, "vendor_category");
      } else {
        data[index]["vendor_category"] = "Not-Registered";
      }
    }
    if (name === "paid_by") {
      let dataErr = JSON.parse(JSON.stringify(newAddErr));
      dataErr[index]["vendor_name"] = "";
      dataErr[index]["vendor_gst_no"] = "";
      dataErr[index]["vendor_gst_state"] = "";
      dataErr[index]["vendor_category"] = "";
      setNewAddErr(dataErr);
    }
    if (value === "self" && name === "paid_by") {
      data[index]["vendor_name"] = "";
      data[index]["vendor_gst_no"] = "";
      data[index]["vendor_gst_state"] = "";
      data[index]["vendor_category"] = "";
    }
    if (value != "company" && name == "vendor_name") {
      data[index]["vendor_name"] = "";
    }
    if (value === "Not-Registered" && name === "vendor_category") {
      data[index]["vendor_gst_state"] = "";
      data[index]["tax_rate_id"] = "";
      data[index]["vendor_gst_no"] = "";
    }
    // if (data[index].paid_by === "company" && name === "invoice_no") {
    //   if (
    //     (Array.from(value)[0] == "Y" || Array.from(value)[0] == "U") &&
    //     value.length == 8 &&
    //     value !== ""
    //   ) {
    //     setInvoiceChkNew(prev => prev.filter(idx => idx !== index));
    //     setAlertMsg(null);
    //   }
    //   else {
    //     if(!invoiceChk.includes(index)) setInvoiceChk(prev => [...prev, index]);
    //     setAlertMsg(
    //       "Invoice Number should Start from U and Y and Length should not be less then 8"
    //     );
    //     if (value !== "") scrollToAlertWithDebounce()
    //   }
    // } else
    if (data[index].paid_by === "self") {
      // setInvoiceChkNew(prev => prev.filter(idx => idx !== index));
      setAlertMsg(null);
    }
    if (data[index].paid_by === "company" && name === "invoice_no") {
      if (value.length <= 8) data[index].invoice_no = value;
    } else {
      data[index][`${name}`] = value;
    }
    setNewAddData(data);
  };

  // const validateInvoice = () => {
  //   let data = JSON.parse(JSON.stringify(newData));
  //   if (data[indexValue].paid_by === "company") {
  //     if (
  //       !((Array.from(data[indexValue].invoice_no)[0] == "Y" || Array.from(data[indexValue].invoice_no)[0] == "U") &&
  //         data[indexValue].invoice_no.length == 8 &&
  //         data[indexValue].invoice_no !== "")
  //     ) {
  //       if (!invoiceChk.includes(indexValue)) setInvoiceChk(prev => [...prev, indexValue]);
  //       setAlertMsg(
  //         "Invoice Number should Start from U and Y and Length should not be less then 8"
  //       );
  //       if (data[indexValue].invoice_no !== "") scrollToAlertWithDebounce()
  //     }
  //   }
  // }

  // const validateInvoiceNew = () => {
  //   let data = JSON.parse(JSON.stringify(newAddData));
  //   if (data[indexValueNew].paid_by === "company") {
  //     if (
  //       !((Array.from(data[indexValueNew].invoice_no)[0] == "Y" || Array.from(data[indexValueNew].invoice_no)[0] == "U") &&
  //         data[indexValueNew].invoice_no.length == 8 &&
  //         data[indexValueNew].invoice_no !== "")
  //     ) {
  //       if (!invoiceChk.includes(indexValueNew)) setInvoiceChkNew(prev => [...prev, indexValueNew]);
  //       setAlertMsg(
  //         "Invoice Number should Start from U and Y and Length should not be less then 8"
  //       );
  //       if (data[indexValueNew].invoice_no !== "") scrollToAlertWithDebounce()
  //     }
  //   }
  // }

  const handleTaxRateChange = (selectedOption, index, flag) => {
    let data = JSON.parse(JSON.stringify(newData));
    let val = 0;
    if (flag == "amount") {
      data[index].taxable_amount = selectedOption;
      let taxRateVal =
        selectedOption?.label === undefined
          ? taxRate?.filter((each) => {
              if (each?.value === data[index]?.tax_rate_id) {
                return true;
              }
            })[0]?.label
          : selectedOption?.label;
      let bn = calculateTaxAmount(
        selectedOption,
        taxRateVal,
        index,
        "",
        newData
      );
      data[index].tax_amount = bn;
      changeHandler(selectedOption, "taxable_amount", index);
      val = calculateTaxBillAmountNon(
        selectedOption,
        newData[index].other_charges,
        bn,
        index
      );
      data[index].tax_bill_amount = val;
    } else {
      data[index].tax_rate_id = selectedOption?.value;
      data[index].tax_rate = selectedOption?.label;
      let bn = calculateTaxAmount(
        data[index].taxable_amount,
        selectedOption.label == undefined ? 0 : selectedOption.label,
        index,
        data[index].tax_amount,
        newData
      );
      data[index].tax_amount = bn;
      val = calculateTaxBillAmount(
        newAddData[index]?.taxable_amount,
        newData[index]?.other_charges,
        index,
        bn,
        flag,
        newData
      );
      data[index].tax_bill_amount = val;
      changeHandler(selectedOption?.value, "tax_rate_id", index);
    }
    if (flag != "ven_cat") {
      let prevTicketTotal = ticketTotal?.final_tax_bill_amount
        ? ticketTotal.final_tax_bill_amount
        : 0;
      let prevTotal = newData[index]?.tax_bill_amount
        ? newData[index].tax_bill_amount
        : 0;
      setTicketTotal({
        final_tax_bill_amount:
          Math.round((prevTicketTotal - prevTotal + val) * 100) / 100,
      });
    }
    setNewData(data);
  };

  const handleTaxRateChangeNew = (selectedOption, index, flag) => {
    let data = JSON.parse(JSON.stringify(newAddData));
    let val = 0;
    if (flag == "amount") {
      data[index].taxable_amount = selectedOption;
      let taxRateVal =
        selectedOption?.label === undefined
          ? taxRate?.filter((each) => {
              if (each?.value === data[index]?.tax_rate_id) {
                return true;
              }
            })[0]?.label
          : selectedOption?.label;
      let bn = calculateTaxAmount(
        selectedOption,
        taxRateVal,
        index,
        "",
        newAddData
      );
      data[index].tax_amount = bn;
      changeHandlerNew(selectedOption, "taxable_amount", index);
      val = calculateTaxBillAmountNon(
        selectedOption,
        newAddData[index].other_charges,
        bn,
        index
      );
      data[index].tax_bill_amount = val;
    } else {
      data[index].tax_rate_id = selectedOption.value;
      data[index].tax_rate = selectedOption?.label;
      let bn = calculateTaxAmount(
        data[index].taxable_amount,
        selectedOption.label == undefined ? 0 : selectedOption.label,
        index,
        data[index].tax_amount,
        newAddData
      );
      data[index].tax_amount = bn;
      val = calculateTaxBillAmount(
        newAddData[index]?.taxable_amount,
        newAddData[index]?.other_charges,
        index,
        bn,
        flag,
        newAddData
      );
      data[index].tax_bill_amount = val;
      changeHandlerNew(selectedOption?.value, "tax_rate_id", index);
    }
    if (flag != "ven_cat") {
      let prevTicketTotal = ticketTotal?.final_tax_bill_amount
        ? ticketTotal.final_tax_bill_amount
        : 0;
      let prevTotal = newAddData[index]?.tax_bill_amount
        ? newAddData[index].tax_bill_amount
        : 0;
      setTicketTotal({
        final_tax_bill_amount:
          Math.round((prevTicketTotal - prevTotal + val) * 100) / 100,
      });
    }
    setNewAddData(data);
  };

  const calculateTaxAmount = (taxableAmount, taxRate, index, flag, data) => {
    let tax =
      taxRate === undefined && flag !== "ven_cat"
        ? data[index]?.tax_rate
        : flag === "ven_cat"
        ? 0
        : taxRate;
    if ((tax && taxableAmount) || taxableAmount) {
      let taxC = tax == undefined ? "0" : tax;
      const ratePercentage = parseFloat(Number(taxC));
      const rate = parseFloat(Number(taxableAmount));
      let systemAmount = parseFloat(rate * ratePercentage) / 100;
      return Math.round(systemAmount * 100) / 100;
    }
    return 0;
  };

  const tooltipTableComp = (
    <table
      class="table table-striped table-bordered tablecured viewpo"
      style={{ backgroundColor: "white" }}
    >
      <thead>
        <tr>
          <th>GSTIN</th>
          <th>Explanation</th>
          <th>Form</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fw-bold text-theme">08</td>
          <td class="fw-bold text-theme">First Two Digit Denotes State Code</td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">A</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">A</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">B</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">C</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">P</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">2</td>
          <td class="fw-bold text-theme">Next Ten Digit Denotes PAN number</td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">1</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>

        <tr>
          <td class="fw-bold text-theme">8</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">3</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">M</td>
          <td class="fw-bold text-theme"></td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">1</td>
          <td class="fw-bold text-theme">
            3th Number Denotes number of Entity
          </td>
          <td class="fw-bold text-theme">Numeric</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">Z</td>
          <td class="fw-bold text-theme">14TH one is always default (Z)</td>
          <td class="fw-bold text-theme">Alphabet</td>
        </tr>
        <tr>
          <td class="fw-bold text-theme">5</td>
          <td class="fw-bold text-theme">Last one is check code</td>
          <td class="fw-bold text-theme">Alphabet/Numeric</td>
        </tr>
      </tbody>
    </table>
  );

  const handleOtherChargesChange = (value, index) => {
    let data = JSON.parse(JSON.stringify(newData));
    let val = 0;
    if (newData[index]?.vendor_category == "Registered") {
      data[index].other_charges = value;
      val = calculateTaxBillAmount(
        newData[index].taxable_amount,
        value,
        index,
        newData[index].tax_amount,
        "",
        newData
      );
      data[index].tax_bill_amount = val;
    } else {
      data[index].other_charges = value;
      val = calculateTaxBillAmountNon(
        newData[index].taxable_amount,
        value,
        newData[index].tax_amount,
        index
      );
      data[index].tax_bill_amount = val;
    }
    let prevTicketTotal = ticketTotal?.final_tax_bill_amount
      ? ticketTotal.final_tax_bill_amount
      : 0;
    let prevTotal = newData[index]?.tax_bill_amount
      ? newData[index].tax_bill_amount
      : 0;
    setTicketTotal({
      final_tax_bill_amount:
        Math.round((prevTicketTotal - prevTotal + val) * 100) / 100,
    });
    setNewData(data);
  };
  const handleErrorMsg = (flag, index) => {
    errorMsg[index] = flag;
  };

  const handleOtherChargesChangeNew = (value, index) => {
    let data = JSON.parse(JSON.stringify(newAddData));
    let val = 0;
    if (newAddData[index]?.vendor_category == "Registered") {
      data[index].other_charges = value;
      val = calculateTaxBillAmount(
        newAddData[index].taxable_amount,
        value,
        index,
        newAddData[index].tax_amount,
        "",
        newAddData
      );
      data[index].tax_bill_amount = val;
    } else {
      data[index].other_charges = value;
      val = calculateTaxBillAmountNon(
        newAddData[index].taxable_amount,
        value,
        newAddData[index].tax_amount,
        index
      );
      data[index].tax_bill_amount = val;
    }
    let prevTicketTotal = ticketTotal?.final_tax_bill_amount
      ? ticketTotal.final_tax_bill_amount
      : 0;
    let prevTotal = newAddData[index]?.tax_bill_amount
      ? newAddData[index].tax_bill_amount
      : 0;
    setTicketTotal({
      final_tax_bill_amount:
        Math.round((prevTicketTotal - prevTotal + val) * 100) / 100,
    });
    setNewAddData(data);
  };
  const calculateTaxBillAmount = (
    taxableAmount,
    otherCharges,
    index,
    taxAmt,
    flag,
    data
  ) => {
    let tax =
      taxableAmount === undefined ? data[index]?.taxable_amount : taxableAmount;
    let other =
      otherCharges === undefined ? data[index]?.other_charges : otherCharges;
    let taxAmount =
      taxAmt === undefined && flag !== "ven_cat"
        ? data[index]?.tax_amount
        : flag === "ven_cat"
        ? 0
        : taxAmt;
    if (tax && other) {
      let dd = parseFloat(Number(tax));
      let pp = parseFloat(Number(other));
      let vv = parseFloat(Number(taxAmount));
      let systemCharges = parseFloat(dd + pp + vv);
      return systemCharges;
    } else {
      let dd = parseFloat(Number(tax));
      // let pp = parseFloat(Number(other));
      let vv = parseFloat(Number(taxAmount));
      let systemCharges1 = parseFloat(dd + vv);
      return systemCharges1;
    }
  };
  const calculateTaxBillAmountNon = (
    taxableAmount,
    otherCharges,
    taxAmt,
    index
  ) => {
    let tax =
      taxableAmount === undefined
        ? newData[index]?.taxable_amount
        : taxableAmount;
    // if (tax && otherCharges) {
    let dd = tax != undefined ? parseFloat(Number(tax)) : 0;
    let pp = otherCharges != undefined ? parseFloat(Number(otherCharges)) : 0;
    let ta = taxAmt != undefined ? parseFloat(Number(taxAmt)) : 0;

    let systemCharges = parseFloat(dd + pp + ta);
    return Math.round(systemCharges * 100) / 100;
    // } else {
    //   let dd = parseFloat(Number(tax));
    //   let ta = parseFloat(Number(taxAmt));

    //   // let pp = parseFloat(Number(otherCharges));
    //   let systemCharges1 = parseFloat(dd);
    //   return systemCharges1;
    // }
    return 0;
  };

  const chkGstExist = (e, i, flag) => {
    if (e.target.value === "") return;
    let payload = {
      gst_no: e.target.value,
    };
    TravelListingAPI.chkGst(payload).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.dataList?.is_exist == true) {
          const Formerrr = JSON.parse(
            JSON.stringify(flag ? newAddErr : formValidation)
          );
          Formerrr[indexValue].vendor_gst_no =
            "Organization's GST no. isn't allowed";
          if (flag) {
            setNewAddErr(Formerrr);
          } else {
            setFormValidation(Formerrr);
          }
          // setGstTicketError(true);
          // setAlertMsg("You can not enter the organization's GST number");
        } else {
          setGstTicketError(false);
          setAlertMsg(null);
        }
      }
    });
  };

  const handleValidateNewAddData = () => {
    let flag = true;
    let isEveryFieldEmpty = true;
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let data = JSON.parse(JSON.stringify(newData));
    let newAddErrData = [...newAddErr];
    newAddData.forEach((obj, index) => {
      newAddErrData[index].isAllFieldEmpty = true;
      if (obj.paid_by === "") {
        newAddErrData[index].paid_by = "*Paid By is required";
        flag = false;
        if (!errEle) errEle = obj.key;
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      if (obj.vendor_name === "") {
        if (data[indexValue]?.mode_id != "2" && obj?.paid_by != "company") {
          newAddErrData[index].vendor_name = "Vendor Category is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        }
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      if (obj?.modee_name == "Air" || obj?.mode_name == "Air") {
        if (!obj.airline_id) {
          newAddErrData[index].airline_id = "Airline is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        } else {
          newAddErrData[index].isAllFieldEmpty = false;
          isEveryFieldEmpty = false;
        }
      }

      if (obj.vendor_category === "") {
        newAddErrData[index].vendor_category = "Vendor Category is required";
        flag = false;
        if (!errEle) errEle = obj.key;
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      if (obj.vendor_gst_state === "") {
        if (data[indexValue]?.mode_id != "2" && obj?.paid_by != "company") {
          newAddErrData[index].vendor_gst_state =
            "Vendor Gst State is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        }
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }

      if (obj.vendor_gst_no === "" && obj.vendor_category === "Registered") {
        newAddErrData[index].vendor_gst_no = "Vendor GST No. is required";
        flag = false;
        if (!errEle) errEle = obj.key;
      } else if (
        obj.vendor_gst_no !== "" &&
        regexforGst.test(obj.vendor_gst_no) === false &&
        obj.paid_by === "company"
      ) {
        newAddErrData[index].vendor_gst_no = "Wrong GST format";
        if (!errEle) errEle = obj.key;
      } else if (
        obj.vendor_gst_state !== "" &&
        obj.vendor_gst_no !== "" &&
        obj?.vendor_gst_no?.includes(
          gstState
            ?.filter((each) => each?.value == obj?.vendor_gst_state)[0]
            ?.label.slice(0, 2)
        ) === false
      ) {
        newAddErrData[index].vendor_gst_no = "State Code not matching";
        flag = false;
        if (!errEle) errEle = obj.key;
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }

      if (obj.invoice_no === "") {
        if (obj?.paid_by == "company") {
          newAddErrData[index].invoice_no = "Invoice No. is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        }
      } else if (
        !(
          Array.from(obj.invoice_no)[0] == "Y" ||
          Array.from(obj.invoice_no)[0] == "U"
        ) &&
        obj.paid_by === "company"
      ) {
        flag = false;
        newAddErrData[index].invoice_no = "Should start from U or Y";
        if (!errEle) errEle = obj.key;
      } else if (obj.invoice_no.length !== 8 && obj.paid_by === "company") {
        flag = false;
        newAddErrData[index].invoice_no = "Length should be 8";
        if (!errEle) errEle = obj.key;
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      if (obj.invoice_date === "") {
        if (data[indexValue]?.mode_id != "2" && obj?.paid_by != "company") {
          newAddErrData[index].invoice_date = "Invoice date. is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        }
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      let isAirOrTrain =
        obj?.mode_name === "Air" ||
        obj?.modee_name == "Air" ||
        obj?.mode_name === "Train" ||
        obj?.modee_name === "Train"
          ? true
          : false;
      if (isAirOrTrain) {
        if (!obj.pnr_no) {
          newAddErrData[index].pnr_no = "PNR No. is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        } else {
          newAddErrData[index].isAllFieldEmpty = false;
          isEveryFieldEmpty = false;
        }
      } else {
        if (!obj.ticket_no) {
          if (data[indexValue]?.mode_id != "2" && obj?.paid_by != "company") {
            newAddErrData[index].ticket_no = "Ticket No. is required";
            flag = false;
            if (!errEle) errEle = obj.key;
          }
        } else {
          newAddErrData[index].isAllFieldEmpty = false;
          isEveryFieldEmpty = false;
        }
      }
      if (obj.taxable_amount === 0) {
        if (data[indexValue]?.mode_id != "2" && obj?.paid_by != "company") {
          newAddErrData[index].taxable_amount = "Taxable Amount is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        }
      } else {
        newAddErrData[index].isAllFieldEmpty = false;
        isEveryFieldEmpty = false;
      }
      if (obj.vendor_category == "Registered" && obj?.paid_by !== "company") {
        if (!obj.tax_rate_id) {
          newAddErrData[index].tax_rate_id = "Tax Rate Id is required";
          flag = false;
          if (!errEle) errEle = obj.key;
        } else {
          newAddErrData[index].isAllFieldEmpty = false;
          isEveryFieldEmpty = false;
        }
      }
      // if (!obj.other_charges) {
      //   newAddErrData[index].other_charges = "Other Charges is required";
      //   flag = false;
      //   if (!errEle) errEle = obj.key;
      // } else {
      //   newAddErrData[index].isAllFieldEmpty = false;
      //   isEveryFieldEmpty = false;
      // }
    });
    if (isEveryFieldEmpty) errEle = null;
    isAllFieldEmpty = isAllFieldEmpty && isEveryFieldEmpty;
    if (!flag && errEle !== null) {
      document.getElementById(errEle)?.scrollIntoView({ behavior: "smooth" });
    }
    setNewAddErr(newAddErrData);
    return !flag;
  };

  const handleValidateForm = () => {
    let dataValue = JSON.stringify(compareTicket) == JSON.stringify(newData);
    // if () return;
    let validateNewData = handleValidateNewData();
    let validateNewAddData = handleValidateNewAddData();
    if (isAllFieldEmpty) navigate("/expense-listing");
    if (validateNewData || validateNewAddData || isAllFieldEmpty) return;
    if (dataValue == false) {
      if (invoiceChk.length === 0 && fcmData?.length == 0) {
        if (ticketCondition.length === 0 || ticketCondition === undefined) {
          saveTicket();
        } else {
          updateTicket(newData, newAddData);
        }
      }
    } else {
      if (invoiceChk.length === 0 && fcmData?.length == 0) {
        if (ticketCondition.length === 0 || ticketCondition === undefined) {
          saveTicket();
        } else {
          updateTicket(newData, newAddData);
        }
      }
    }
  };

  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (attachment == undefined) {
      let obj = file.filter((ele) => ele.name !== id);
      // let obj = {};
      // Object.keys(file)
      //   .filter((del) => {
      //     if (file[del].name !== id) {
      //       return true;
      //     }
      //   })
      //   .map((item) => {
      //     obj[item] = file[item];
      //   });
      document.getElementById("policyattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("policyattachment").value = "";
      } else {
        let obj = file.filter((ele) => ele.name !== id);
        // let obj = {};
        // Object.keys(file)
        //   .filter((del) => {
        //     if (file[del].name !== id) {
        //       return true;
        //     }
        //   })
        //   .map((item) => {
        //     obj[item] = file[item];
        //   });
        document.getElementById("policyattachment").value = "";
        setfiles(obj);
      }
    }
  };

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1 col-2">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ||
                data2.name.includes(".csv") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2?.name.includes(".msg") ? (
                <img
                  src={"/images/policy-icon/msg.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
            <br></br>
            <span>{data2?.name}</span>
          </div>
        </>
      );
    }
    return data;
  };

  const AddMoreData = () => {
    if (newAddData?.length < 2) {
      const copyValue = JSON.parse(JSON.stringify(newAddData));
      const ele = {};
      ele.travel_date = ticketDate?.date;
      ele.travel_time = ticketDate?.travel_time;
      ele.from_location_name = ticketDate?.fromCity;
      ele.to_location_name = ticketDate?.toCity;
      ele.from_location_id = ticketDate?.fromCityId;
      ele.to_location_id = ticketDate?.toCityId;
      ele.paid_by = "";
      ele.vendor_name = "";
      ele.airline_id = 0;
      ele.vendor_category = "";
      ele.vendor_gst_state = "";
      ele.vendor_gst_no = "";
      ele.invoice_no = "";
      ele.invoice_date = "";
      if (
        (newData[0]?.mode_name === "Air" && newData[0]?.modee_name == "Air") ||
        newData[0]?.mode_name === "Train" ||
        newData[0]?.modee_name === "Train"
      ) {
        ele.pnr_no = 0;
      } else {
        ele.ticket_no = 0;
      }
      ele.taxable_amount = 0;
      ele.tax_rate_id = 0;
      ele.tax_amount = 0;
      ele.other_charges = 0;
      ele.tax_bill_amount = 0;
      let len = newData.length;
      ele.mode_id = newData[len - 1]?.mode_id;
      ele.mode_name = newData[len - 1]?.mode_name
        ? newData[len - 1]?.mode_name
        : newData[len - 1].modee_name;
      ele.key =
        copyValue.length > 0 ? copyValue[copyValue.length - 1].key + 1 : 1;
      copyValue.push(ele);
      let errData = [...newAddErr];
      let errTemp = { ...ele };
      errTemp.airline_id = "";
      errTemp.vendor_gst_no = "";
      errTemp.ticket_no = "";
      errTemp.pnr_no = "";
      errTemp.taxable_amount = "";
      errTemp.tax_rate_id = "";
      errTemp.tax_amount = "";
      errTemp.other_charges = "";
      errTemp.tax_bill_amount = "";
      errTemp.isAllFieldEmpty = true;
      errData.push(errTemp);
      setNewAddErr(errData);
      setNewAddData(copyValue);
      setTicketDateModal(false);
    }
  };

  const deleteTicket = (idx) => {
    setTicketTotal({
      final_tax_bill_amount:
        Math.round(
          (ticketTotal.final_tax_bill_amount -
            newAddData[idx].tax_bill_amount) *
            100
        ) / 100,
    });
    let ticketData = newAddData.filter((obj, index) => idx != index);
    let ticketErrData = newAddErr.filter((obj, index) => idx != index);
    setNewAddData(ticketData);
    setNewAddErr(ticketErrData);
  };

  const handleValidateNewData = () => {
    let isError = false;
    let regexforGst = new RegExp(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    );
    let data = JSON.parse(JSON.stringify(newData));
    const Formerrr = JSON.parse(JSON.stringify(formValidation));
    // let value = newData[indexValue];
    newData?.map((value, indexValue) => {
      Formerrr[indexValue].isAllFieldEmpty = true;
      if (!value.paid_by) {
        isError = true;
        Formerrr[indexValue].paid_by = "*Paid By is required ";
        if (!errEle) errEle = value.id;
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (!value.vendor_name) {
        if (data[indexValue]?.mode_id != "2" && value?.paid_by != "company") {
          isError = true;
          Formerrr[indexValue].vendor_name = "Vendor Category is required";
          if (!errEle) errEle = value.id;
        }
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (!value.airline_id) {
        if (value?.modee_name == "Air" || value?.mode_name == "Air") {
          isError = true;
          Formerrr[indexValue].airline_id = "Airline is required";
          if (!errEle) errEle = value.id;
        }
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (!value.vendor_category) {
        isError = true;
        Formerrr[indexValue].vendor_category = "Vendor Category is required";
        if (!errEle) errEle = value.id;
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (!value.vendor_gst_state) {
        if (data[indexValue]?.mode_id != "2" && value?.paid_by != "company") {
          isError = true;
          Formerrr[indexValue].vendor_gst_state =
            "Vendor Gst State is required";
          if (!errEle) errEle = value.id;
        }
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (!value.vendor_gst_no && value.vendor_category == "Registered") {
        isError = true;
        Formerrr[indexValue].vendor_gst_no = "Vendor GST No. is required";
        if (!errEle) errEle = value.id;
      } else if (
        value.vendor_gst_no !== "" &&
        value.vendor_category == "Registered" &&
        regexforGst.test(value.vendor_gst_no) === false
      ) {
        isError = true;
        Formerrr[indexValue].vendor_gst_no = "Wrong GST format";
        if (!errEle) errEle = value.id;
      } else if (
        value.vendor_gst_no !== "" &&
        value?.vendor_gst_no?.includes(
          gstState
            ?.filter((each) => {
              if (each?.value == value?.vendor_gst_state) {
                return each;
              }
            })[0]
            ?.label.slice(0, 2)
        ) === false
      ) {
        isError = true;
        Formerrr[indexValue].vendor_gst_no = "State Code not matching";
        if (!errEle) errEle = value.id;
      } else if (value?.vendor_gst_no && value?.vendor_gst_no !== "") {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }

      if (!value.invoice_no) {
        if (value?.paid_by == "company") {
          isError = true;
          Formerrr[indexValue].invoice_no = "Invoice No. is required";
          if (!errEle) errEle = value.id;
        }
      } else if (
        !(
          Array.from(value.invoice_no)[0] == "Y" ||
          Array.from(value.invoice_no)[0] == "U"
        ) &&
        value.paid_by === "company"
      ) {
        isError = true;
        Formerrr[indexValue].invoice_no = "Should start from U or Y";
        if (!errEle) errEle = value.id;
      } else if (value.invoice_no.length !== 8 && value.paid_by === "company") {
        isError = true;
        Formerrr[indexValue].invoice_no = "Length should be 8";
        if (!errEle) errEle = value.id;
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }

      if (!value.invoice_date) {
        if (data[indexValue]?.mode_id != "2" && value?.paid_by != "company") {
          isError = true;
          Formerrr[indexValue].invoice_date = "Invoice date. is required";
          if (!errEle) errEle = value.id;
        }
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }

      let isAirOrTrain =
        value?.mode_name === "Air" ||
        value?.modee_name == "Air" ||
        value?.mode_name === "Train" ||
        value?.modee_name === "Train"
          ? true
          : false;
      if (isAirOrTrain) {
        if (!value.pnr_no) {
          isError = true;
          Formerrr[indexValue].pnr_no = "PNR No. is required";
          if (!errEle) errEle = value.id;
        } else {
          Formerrr[indexValue].isAllFieldEmpty = false;
          isAllFieldEmpty = false;
        }
      } else {
        if (!value.ticket_no) {
          if (data[indexValue]?.mode_id != "2" && value?.paid_by != "company") {
            isError = true;
            Formerrr[indexValue].ticket_no = "Ticket No. is required";
            if (!errEle) errEle = value.id;
          }
        } else {
          Formerrr[indexValue].isAllFieldEmpty = false;
          isAllFieldEmpty = false;
        }
      }
      if (!value.taxable_amount) {
        if (data[indexValue]?.mode_id != "2" && value?.paid_by != "company") {
          isError = true;
          Formerrr[indexValue].taxable_amount = "Taxable Amount is required";
          if (!errEle) errEle = value.id;
        }
      } else {
        Formerrr[indexValue].isAllFieldEmpty = false;
        isAllFieldEmpty = false;
      }
      if (
        value.vendor_category == "Registered" &&
        value?.paid_by !== "company"
      ) {
        if (!value.tax_rate_id) {
          isError = true;
          Formerrr[indexValue].tax_rate_id = "Tax Rate Id is required";
          if (!errEle) errEle = value.id;
        } else {
          Formerrr[indexValue].isAllFieldEmpty = false;
          isAllFieldEmpty = false;
        }
      }
      // if (!value.other_charges) {
      //   isError = true;
      //   Formerrr[indexValue].other_charges = "Other Charges is required";
      //   if (!errEle) errEle = value.id;
      // } else {
      //   Formerrr[indexValue].isAllFieldEmpty = false;
      //   isAllFieldEmpty = false;
      // }

      if (errEle == value.id && Formerrr[indexValue].isAllFieldEmpty)
        errEle = null;
    });
    if (isAllFieldEmpty) errEle = null;
    if (isError && errEle !== null) {
      document.getElementById(errEle)?.scrollIntoView({ behavior: "smooth" });
    }
    setFormValidation(Formerrr);
    return isError && errEle !== null ? isError : false;
  };

  useEffect(() => {
    let data = [];
    if (ticketCondition.length !== 0) {
      setFormValidation(
        Array.from(allData).map((val) => {
          return formValidation[0];
        })
      );
      data = allData?.filter((res)=>{
        if(res?.mode_id !== null){
          return true
        }
      });
    } else {
      setFormValidation(
        Array.from(state?.travel_detail_data).map((val) => {
          return formValidation;
        })
      );
      data = state?.travel_detail_data?.filter((res)=>{
        if(res?.mode_id !== null){
          return true
        }
      });
    }
    let dateOption = [];
    let dateList = [];
    let key = ticketCondition.length !== 0 ? "travel_date" : "from_date";
    let tempData = data.filter((obj, index) => {
      let mode = obj?.mode_name ? obj.mode_name : obj.modee_name;
      // if (
      //   mode != "Car" &&
      //   mode != "Bike" &&
      //   mode != "Personal Car" &&
      //   mode != "Travel with Colleague"
      // ) {
      let from =
        obj?.from_city_input === "Other" ||
        (obj?.from_location_name === undefined && obj?.from_city_input == null)
          ? obj?.from_city_name
          : obj?.from_city_input == null
          ? obj?.from_location_name
          : obj?.from_city_name === "Other"
          ? obj?.from_city_input
          : obj?.from_city_input;

      let to =
        obj?.to_city_input === "Other" ||
        (obj?.to_location_name === undefined && obj?.to_city_input == null)
          ? obj?.to_city_name
          : obj?.to_city_input == null
          ? obj?.to_location_name
          : obj?.to_city_name === "Other"
          ? obj?.to_city_input
          : obj?.to_city_input;

      let fromId = obj.from_city_id ? obj.from_city_id : obj.from_location_id;
      let toId = obj.to_city_id ? obj.to_city_id : obj.to_location_id;
      let time = obj?.travel_time
        ? obj.travel_time
        : state?.travel_detail_data[index]?.from_time;
      obj.travel_time = time?.substring(0, 5);
      if (from !== null && to !== null) {
        let date_location = moment(obj[key]).format("DD-MM-YYYY") + from + to;
        if (obj[key] && !dateList.includes(date_location)) {
          dateList.push(date_location);
          dateOption.push({
            label: `${moment(obj[key]).format("DD-MM-YYYY")} (${from} - ${to})`,
            value: `${moment(obj[key]).format("DD-MM-YYYY")} (${from} - ${to})`,
            date: obj[key],
            travel_time: obj?.travel_time?.substring(0, 5),
            fromCity: from,
            toCity: to,
            fromCityId: fromId,
            toCityId: toId,
          });
        }
      }
      return true;
      // }
      return false;
    });
    setNewData(tempData);
    setTicketDateOption(dateOption);
    if (dateOption.length > 0) {
      setTicketDate(dateOption[0]);
      setEndDate(dateOption[dateOption.length - 1].date);
    }
    setCompareTicket(tempData);
  }, [state, allData]);

  useEffect(() => {
    getTicketData(state?.id);
  }, [state]);

  useEffect(() => {
    getTicketData(state?.id);
    getAirline();
    getTaxRate();
    getGstState(false);
  }, []);
  return (
    <>
      {/* {invoiceChk && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> Invoice Number should
          Start from U and Y and Length should not be less then 8
        </Alert>
      )}
      {fcmData?.length !== 0 && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>You have entered PI GST No. instead of vendor GST No.
        </Alert>
      )}
      {gstTicketError && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i> You can not enter the organization's GST number
        </Alert>
      )} */}
      <div ref={alertRef}>
        {alertMsg ? (
          <Alert variant="danger" className="mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>
            {alertMsg}
          </Alert>
        ) : (
          <></>
        )}
      </div>

      <div className="table-responsive"></div>
      <div className="px-4 pb-2">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h6 className="f mt-2">
              <strong>Total : </strong>
              <span className="text-muted">
                <i className="fas fa-rupee-sign f-14"></i>
                {" " + ticketTotal.final_tax_bill_amount.toFixed(2)}
              </span>
            </h6>
          </div>
        </div>
        {newData?.length ? (
          newData?.map((data, index) => (
            <>
              <span style={{ color: "#959595", fontWeight: "bold" }}>
                Ticket {index + 1}
              </span>
              <div
                className="tickethistorbg mt-2 mx-6"
                // style={{ background: "#dae4e9" }}
              >
                <div className="row viewformaftersubmit">
                  <div className="col-md-3">
                    <div className="innergroup">
                      <p>Travel Date</p>
                      <label>
                        {ticketCondition.length === 0
                          ? moment(data?.from_date).format("DD-MM-YYYY")
                          : moment(data?.travel_date).format("DD-MM-YYYY")}
                        &nbsp;&nbsp;
                        {ticketCondition.length === 0
                          ? // ? state?.travel_detail_data[index]?.from_time
                            // : state?.travel_detail_data[index]?.from_time}
                            data?.travel_time
                          : data?.travel_time}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="innergroup">
                      <p>From Location</p>
                      <label>
                        {" "}
                        {/* {ticketCondition.length === 0
                          ? data?.from_city_name
                          : data?.from_city_input === null
                          ? data?.from_location_name
                          : data?.from_city_input} */}
                        {data?.from_city_input === "Other" ||
                        (data?.from_location_name == undefined &&
                          data?.from_city_input == null)
                          ? data?.from_city_name
                          : data?.from_city_input == null
                          ? data?.from_location_name
                          : data?.from_city_name === "Other"
                          ? data?.from_city_input
                          : data?.from_city_input}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="innergroup">
                      <p>To Location</p>
                      <label>
                        {" "}
                        {/* {ticketCondition.length === 0
                          ? data?.to_city_name
                          : data?.to_city_input === null
                          ? data?.to_location_name
                          : data?.to_city_input} */}
                        {data?.to_city_input === "Other" ||
                        (data?.to_location_name === undefined &&
                          data?.to_city_input == null)
                          ? data?.to_city_name
                          : data?.to_city_input == null
                          ? data?.to_location_name
                          : data?.to_city_name === "Other"
                          ? data?.to_city_input
                          : data?.to_city_input}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="innergroup">
                      <p>Mode</p>
                      <label>
                        {" "}
                        {data?.mode_name === "" || data?.mode_name === undefined
                          ? data?.modee_name
                          : data?.mode_name}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-light px-3 pt-3 mb-3 border-bottom"
                id={data?.id}
              >
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Paid by</label>
                      <span class="text-danger">*</span>
                      <select
                        className="form-control newbgselect"
                        name="paid_by"
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].paid_by = "";
                          setFormValidation(curVal);
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "company") {
                            handleTaxRateChange("", index, "ven_cat");
                          }
                        }}
                        onKeyDown={handleKeyPress}
                        value={newData[index]?.paid_by}
                      >
                        <option value="">Select</option>
                        <option value="self">Self</option>
                       
                        {(newData[index]?.mode_name === undefined
                          ? newData[index]?.modee_name
                          : newData[index]?.mode_name) === "Car" ||
                        (newData[index]?.mode_name === undefined
                          ? newData[index]?.modee_name
                          : newData[index]?.mode_name) === "Air" ||
                        (newData[index]?.mode_name === undefined
                          ? newData[index]?.modee_name
                          : newData[index]?.mode_name) === "Train" ? (
                          <option value="company">Company</option>
                        ) : (
                          ""
                        )}

                        {userData?.emp_sub_group === "T1" ||
                        userData?.emp_sub_group === "T2" ? (
                          <option value="Corporate Credit Card">
                            Corporate Credit Card
                          </option>
                        ) : (
                          ""
                        )}
                      </select>
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.paid_by}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor Name</label>
                      <span class="text-danger">*</span>
                      <input
                        name="vendor_name"
                        type="text"
                        disabled={
                          newData[index]?.paid_by == "company" ? true : false
                        }
                        className="form-control ticket-dis"
                        maxLength={30}
                        placeholder="Enter Vendor Name"
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].vendor_name = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.vendor_name}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.vendor_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Airline</label>
                      <span class="text-danger">*</span>
                      <Select
                        name="airline_id"
                        options={airline}
                        className="basic-multi-select ticket-dis"
                        isDisabled={
                          (data?.mode_name === undefined
                            ? data?.modee_name
                            : data?.mode_name) !== "Air"
                        }
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          changeHandler(e.value, action.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].airline_id = "";
                          setFormValidation(curVal);
                        }}
                        value={airline
                          .filter((obj) => {
                            if (obj?.value === newData[index]?.airline_id) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.airline_id}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor Catagory</label>
                      <span class="text-danger">*</span>
                      <select
                        name="vendor_category"
                        className="form-control newbgselect ticket-dis"
                        disabled={newData[index]?.paid_by == "company"}
                        onBlur={(e) => {
                          if (e.target.value === "Not-Registered") {
                            handleTaxRateChange("", index, "ven_cat");
                          }
                        }}
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].vendor_category = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.vendor_category}
                      >
                        <option value="">Select</option>
                        <option value="Registered">Registered</option>
                        <option value="Not-Registered">Not-Registered</option>
                      </select>
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.vendor_category}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor GST State</label>
                      <span class="text-danger">*</span>
                      <Select
                        name="vendor_gst_state"
                        isDisabled={
                          newData[index]?.paid_by == "company" ? true : false
                        }
                        options={gstState}
                        className="basic-multi-select ticket-dis"
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          changeHandler(e.value, action.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].vendor_gst_state = "";
                          setFormValidation(curVal);
                        }}
                        value={gstState
                          .filter((obj) => {
                            if (
                              obj?.value == newData[index]?.vendor_gst_state
                            ) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.vendor_gst_state}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor GST No.</label>

                      <span class="text-danger">*</span>

                      {/* <Tooltip
                        html={tooltipTableComp}
                        style={{ backgroundColor: "white" }}
                        position="right"
                      > */}

                      <i
                        onClick={() => setVisibleD(true)}
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#4A9BD3" }}
                      ></i>
                      {/* </Tooltip> */}

                      <input
                        name="vendor_gst_no"
                        type="text"
                        maxLength={15}
                        className="form-control ticket-dis"
                        disabled={
                          newData[index]?.vendor_category == "" ||
                          newData[index]?.vendor_category == "Not-Registered" ||
                          newData[index]?.paid_by == "company"
                        }
                        autocomplete="off"
                        placeholder="Enter Vendor GST No."
                        onBlur={(e) => {
                          // handleValidateGst(index);
                          chkGstExist(e, index, false);
                        }}
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          setGstTicketError(false);
                          setAlertMsg(null);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].vendor_gst_no = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.vendor_gst_no}
                        style={{
                          border:
                            (gstTicketError || fcmData.length > 0) &&
                            newData[index]?.paid_by == "self"
                              ? "1px solid #ff0000"
                              : "",
                        }}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.vendor_gst_no}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Invoice No</label>
                      <span class="text-danger">*</span>
                      <input
                        type="text"
                        name="invoice_no"
                        className="form-control"
                        maxLength={16}
                        placeholder="Enter Invoice No"
                        autocomplete="off"
                        // onBlur={validateInvoice}
                        onFocus={() => setIndexValue(index)}
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].invoice_no = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.invoice_no}
                        style={{
                          border:
                            invoiceChk.includes(index) &&
                            newData[index]?.paid_by == "company"
                              ? "1px solid #ff0000"
                              : "",
                        }}
                      />
                      <div className="small text-danger">
                        {index == Number(newData.length - 1) &&
                          formValidation.invoice_no}
                      </div>
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.invoice_no}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup d-flex flex-column">
                      <label>
                        Invoice Date<span class="text-danger">*</span>
                      </label>
                      {/* <input
                        type="date"
                        name="invoice_date"
                        // min={data?.from_date}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        className="form-control"
                        placeholder="Enter Invoice Date"
                        onChange={(e) => {
                          changeHandler(e.target.value, e.target.name, index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].invoice_date = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.invoice_date}
                      /> */}
                      <DatePicker
                        selected={
                          newData[index]?.invoice_date &&
                          newData[index]?.invoice_date !== ""
                            ? new Date(newData[index]?.invoice_date)
                            : null
                        }
                        onChange={(date) => {
                          changeHandler(date, "invoice_date", index);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].invoice_date = "";
                          setFormValidation(curVal);
                        }}
                        maxDate={new Date(endDate)}
                        // minDate={
                        //   new Date(state?.travel_detail_data[0]?.from_date)
                        // }
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.invoice_date}
                      </div>
                    </div>
                  </div>

                  {newData[index]?.mode_name === "Air" ||
                  newData[index]?.modee_name == "Air" ||
                  newData[index]?.mode_name === "Train" ||
                  newData[index]?.modee_name === "Train" ? (
                    <div className="col-md-2">
                      <div className="form-group innergroup">
                        <label>PNR No</label>
                        <span class="text-danger">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="pnr_no"
                          maxLength={14}
                          placeholder="Enter PNR No"
                          onChange={(e) => {
                            changeHandler(e.target.value, e.target.name, index);
                            let curVal = JSON.parse(
                              JSON.stringify(formValidation)
                            );
                            curVal[index].pnr_no = "";
                            setFormValidation(curVal);
                          }}
                          value={newData[index]?.pnr_no}
                        />
                        <div className="small text-danger">
                          {formValidation[index].isAllFieldEmpty
                            ? ""
                            : formValidation[index]?.pnr_no}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2">
                      <div className="form-group innergroup">
                        <label>Ticket No</label>
                        <span class="text-danger">*</span>
                        <input
                          type="text"
                          className="form-control"
                          name="ticket_no"
                          maxLength={30}
                          autocomplete="off"
                          placeholder="Enter Ticket No"
                          onChange={(e) => {
                            changeHandler(e.target.value, e.target.name, index);
                            let curVal = JSON.parse(
                              JSON.stringify(formValidation)
                            );
                            curVal[index].ticket_no = "";
                            setFormValidation(curVal);
                          }}
                          value={newData[index]?.ticket_no}
                        />
                        <div className="small text-danger">
                          {formValidation[index].isAllFieldEmpty
                            ? ""
                            : formValidation[index]?.ticket_no}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Taxable Amount</label>
                      <span class="text-danger">*</span>
                      <input
                        type="number"
                        className="form-control"
                        name="taxable_amount"
                        autocomplete="off"
                        min={0}
                        step="0.01"
                        placeholder="Enter Taxable Amount"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyPress}
                        onChange={(e, action) => {
                          handleTaxRateChange(e.target.value, index, "amount");
                          // handleOtherChargesChange(
                          //   (e.target.value),
                          //   index,
                          //   "other"
                          // );
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].taxable_amount = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.taxable_amount}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.taxable_amount}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Tax Rate</label>
                      <span class="text-danger">*</span>
                      <Select
                        name="tax_rate_id"
                        options={taxRate}
                        isDisabled={
                          newData[index]?.vendor_category == "Not-Registered" ||
                          newData[index]?.paid_by == "company"
                        }
                        className="basic-multi-select ticket-dis"
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          handleTaxRateChange(e, index, "taxId");
                          setTaxValueInt(e);
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].tax_rate_id = "";
                          setFormValidation(curVal);
                        }}
                        value={taxRate
                          .filter((obj) => {
                            if (obj?.value == newData[index]?.tax_rate_id) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.tax_rate_id}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Tax Amount</label>
                      <input
                        type="number"
                        name="tax_amount"
                        className="form-control"
                        disabled
                        value={
                          (newData[index]?.vendor_category == "Registered" ||
                            newData[index]?.vendor_category == undefined) &&
                          newData[index]?.paid_by == "self"
                            ? newData[index]?.tax_amount?.toFixed(2)
                            : 0
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Other Charges</label>
                      <span class="text-danger">*</span>
                      <input
                        type="number"
                        name="other_charges"
                        className="form-control"
                        onKeyDown={handleKeyPress}
                        min={0}
                        onWheel={(e) => e.target.blur()}
                        onClick={() => handleErrorMsg(true, index)}
                        // disabled={
                        //   newData[index]?.taxable_amount == 0 ||
                        //   newData[index]?.taxable_amount == "" ||
                        //   newData[index]?.taxable_amount == undefined
                        // }
                        onBlur={() => handleErrorMsg(false, index)}
                        placeholder="Enter Other Charges"
                        onChange={(e) => {
                          handleOtherChargesChange(
                            e.target.value,
                            index,
                            "other"
                          );
                          let curVal = JSON.parse(
                            JSON.stringify(formValidation)
                          );
                          curVal[index].other_charges = "";
                          setFormValidation(curVal);
                        }}
                        value={newData[index]?.other_charges}
                      />
                      <div className="small text-danger">
                        {formValidation[index].isAllFieldEmpty
                          ? ""
                          : formValidation[index]?.other_charges}
                      </div>

                      {newData[index]?.paid_by == "company" ? (
                        <>
                          {" "}
                          {errorMsg[index] && (
                            <p style={{ color: "red" }}>
                              {" "}
                              Note:Enter Service Charge and applicable GST
                            </p>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Total Bill Amount</label>
                      <input
                        type="number"
                        name="total_bill_amount"
                        disabled
                        value={(
                          Math.round(newData[index]?.tax_bill_amount * 100) /
                          100
                        ).toFixed(2)}
                        className="form-control ticket-dis"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <tr>
            <td colSpan={12}>
              <strong>No Record Found</strong>
            </td>
          </tr>
        )}

        {
          newAddData?.map((data, index) => (
            <div key={data.key} id={data.key}>
              <div className="d-flex justify-content-between">
                <span style={{ color: "#959595", fontWeight: "bold" }}>
                  Ticket {index + newData.length + 1}
                </span>
                <span
                  onClick={() => deleteTicket(index)}
                  style={{ cursor: "pointer" }}
                  className="text-danger"
                >
                  <i className="far fa-minus-square mr-2"></i>Remove
                </span>
              </div>
              <div className="bg-light px-3 pt-3 mb-3 border-bottom">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Paid by</label>
                      <select
                        className="form-control newbgselect"
                        name="paid_by"
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].paid_by = "";
                          setNewAddErr(prev);
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                        }}
                        value={newAddData[index]?.paid_by}
                      >
                        <option value="">Select</option>
                        <option value="self">Self</option>
                        {newAddData[index]?.mode_name === "Car" ||
                        newAddData[index]?.mode_name === "Air" ||
                        newAddData[index]?.mode_name === "Train" ? (
                          <option value="company">Company</option>
                        ) : (
                          <></>
                        )}

                        {userData?.emp_sub_group === "T1" ||
                        userData?.emp_sub_group === "T2" ? (
                          <option value="Corporate Credit Card">
                            Corporate Credit Card
                          </option>
                        ) : (
                          ""
                        )}
                      </select>
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.paid_by}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor Name</label>
                      <input
                        name="vendor_name"
                        type="text"
                        disabled={
                          newAddData[index]?.paid_by == "company" ? true : false
                        }
                        className="form-control ticket-dis"
                        maxLength={30}
                        placeholder="Enter Vendor Name"
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].vendor_name = "";
                          setNewAddErr(prev);
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                        }}
                        value={newAddData[index]?.vendor_name}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.vendor_name}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Airline</label>
                      <Select
                        name="airline_id"
                        options={airline}
                        className="basic-multi-select ticket-dis"
                        isDisabled={data?.mode_name !== "Air"}
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          let prev = [...newAddErr];
                          prev[index].airline_id = "";
                          setNewAddErr(prev);
                          changeHandlerNew(e.value, action.name, index);
                        }}
                        value={airline
                          .filter((obj) => {
                            if (obj?.value === newAddData[index]?.airline_id) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.airline_id}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor Category</label>
                      <select
                        name="vendor_category"
                        className="form-control newbgselect ticket-dis"
                        disabled={newAddData[index]?.paid_by == "company"}
                        onBlur={(e) => {
                          if (e.target.value === "Not-Registered") {
                            handleTaxRateChangeNew("", index, "ven_cat");
                          }
                        }}
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].vendor_category = "";
                          setNewAddErr(prev);
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                          // handleOtherChargesChange(
                          //   newAddData[index]?.other_charges,
                          //   index
                          // );
                        }}
                        value={newAddData[index]?.vendor_category}
                      >
                        <option value="">Select</option>
                        <option value="Registered">Registered</option>
                        <option value="Not-Registered">Not-Registered</option>
                      </select>
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.vendor_category}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor GST State</label>
                      <Select
                        name="vendor_gst_state"
                        isDisabled={
                          newAddData[index]?.paid_by == "company" ? true : false
                        }
                        options={gstState}
                        className="basic-multi-select ticket-dis"
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          let prev = [...newAddErr];
                          prev[index].vendor_gst_state = "";
                          setNewAddErr(prev);
                          changeHandlerNew(e.value, action.name, index);
                        }}
                        value={gstState
                          .filter((obj) => {
                            if (
                              obj?.value == newAddData[index]?.vendor_gst_state
                            ) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.vendor_gst_state}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Vendor GST No.</label>
                      <i
                        onClick={() => setVisibleD(true)}
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        style={{ color: "#4A9BD3" }}
                      ></i>
                      <input
                        name="vendor_gst_no"
                        type="text"
                        maxLength={15}
                        className="form-control ticket-dis"
                        // isDisabled={
                        //   newData[index]?.vendor_category == "Not-Registered" ||
                        //   newData[index]?.paid_by == "Company"
                        //     ? true
                        //     : false
                        // }
                        onBlur={(e) => {
                          // handleValidateGst(index);
                          chkGstExist(e, index, true);
                        }}
                        disabled={
                          newAddData[index]?.vendor_category == "" ||
                          newAddData[index]?.vendor_category ==
                            "Not-Registered" ||
                          newAddData[index]?.paid_by == "company"
                        }
                        placeholder="Enter Vendor GST No."
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].vendor_gst_no = "";
                          setNewAddErr(prev);
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                        }}
                        value={newAddData[index]?.vendor_gst_no}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.vendor_gst_no}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Invoice No</label>
                      <input
                        type="text"
                        name="invoice_no"
                        className="form-control"
                        maxLength={16}
                        placeholder="Enter Invoice No"
                        // onBlur={validateInvoiceNew}
                        onFocus={() => setIndexValueNew(index)}
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].invoice_no = "";
                          setNewAddErr(prev);
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                          setFormValidation({
                            ...formValidation,
                            invoice_no: "",
                          });
                        }}
                        value={newAddData[index]?.invoice_no}
                        style={{
                          border:
                            invoiceChk.includes(index) &&
                            newData[index]?.paid_by == "company"
                              ? "1px solid #ff0000"
                              : "",
                        }}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.invoice_no}
                      </div>
                      {/* <div className="small text-danger">
                        {index == Number(newAddData.length - 1) &&
                          formValidation.invoice_no}
                      </div> */}
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup d-flex flex-column">
                      <label>Invoice Date</label>
                      {/* <input
                        type="date"
                        name="invoice_date"
                        // min={data?.from_date}
                        // max={data?.to_date}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        className="form-control"
                        placeholder="Enter Invoice Date"
                        onChange={(e) => {
                          changeHandlerNew(
                            e.target.value,
                            e.target.name,
                            index
                          );
                        }}
                        value={newAddData[index]?.invoice_date}
                      /> */}
                      <DatePicker
                        selected={
                          newAddData[index]?.invoice_date &&
                          newAddData[index]?.invoice_date !== ""
                            ? new Date(newAddData[index]?.invoice_date)
                            : null
                        }
                        onChange={(date) => {
                          let prev = [...newAddErr];
                          prev[index].invoice_date = "";
                          setNewAddErr(prev);
                          changeHandlerNew(date, "invoice_date", index);
                        }}
                        maxDate={new Date(endDate)}
                        placeholderText="DD-MM-YYYY"
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.invoice_date}
                      </div>
                    </div>
                  </div>

                  {newAddData[index]?.mode_name === "Air" ||
                  newAddData[index]?.mode_name === "Train" ? (
                    <div className="col-md-2">
                      <div className="form-group innergroup">
                        <label>PNR No</label>
                        <input
                          type="text"
                          className="form-control"
                          name="pnr_no"
                          maxLength={30}
                          placeholder="Enter PNR No"
                          onChange={(e) => {
                            let prev = [...newAddErr];
                            prev[index].pnr_no = "";
                            setNewAddErr(prev);
                            changeHandlerNew(
                              e.target.value,
                              e.target.name,
                              index
                            );
                          }}
                          value={newAddData[index]?.pnr_no}
                        />
                        <div className="small text-danger">
                          {newAddErr[index].isAllFieldEmpty
                            ? ""
                            : newAddErr[index]?.pnr_no}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2">
                      <div className="form-group innergroup">
                        <label>Ticket No</label>
                        <input
                          type="text"
                          className="form-control"
                          name="ticket_no"
                          maxLength={30}
                          placeholder="Enter Ticket No"
                          onChange={(e) => {
                            let prev = [...newAddErr];
                            prev[index].ticket_no = "";
                            setNewAddErr(prev);
                            changeHandlerNew(
                              e.target.value,
                              e.target.name,
                              index
                            );
                          }}
                          value={newAddData[index]?.ticket_no}
                        />
                        <div className="small text-danger">
                          {newAddErr[index].isAllFieldEmpty
                            ? ""
                            : newAddErr[index]?.ticket_no}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Taxable Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        name="taxable_amount"
                        autocomplete="off"
                        step="0.01"
                        min={0}
                        placeholder="Enter Taxable Amount"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyPress}
                        // onChange={(e, action) => {
                        //   handleTaxRateChangeNew(e, index);
                        // }}
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].taxable_amount = "";
                          setNewAddErr(prev);
                          handleTaxRateChangeNew(
                            e.target.value,
                            index,
                            "amount"
                          );
                          // changeHandlerNew(
                          //   e.target.value.trim(),
                          //   e.target.name,
                          //   index
                          // );
                        }}
                        value={newAddData[index]?.taxable_amount}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.taxable_amount}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Tax Rate</label>
                      <Select
                        name="tax_rate_id"
                        options={taxRate}
                        isDisabled={
                          newAddData[index]?.vendor_category ==
                            "Not-Registered" ||
                          newAddData[index]?.paid_by == "company"
                        }
                        className="basic-multi-select ticket-dis"
                        classNamePrefix="select"
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].tax_rate_id = "";
                          setNewAddErr(prev);
                          handleTaxRateChangeNew(e, index, "taxId");
                        }}
                        value={taxRate
                          .filter((obj) => {
                            if (obj?.value == newAddData[index]?.tax_rate_id) {
                              return true;
                            }
                          })
                          .map((obj) => {
                            return obj;
                          })}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.tax_rate_id}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Tax Amount</label>
                      <input
                        type="number"
                        name="tax_amount"
                        className="form-control"
                        disabled
                        value={
                          newAddData[index]?.vendor_category == "Registered"
                            ? newAddData[index].tax_amount.toFixed(2)
                            : 0
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Other Charges</label>
                      <input
                        type="number"
                        name="other_charges"
                        min={0}
                        // disabled={
                        //   newAddData[index]?.taxable_amount == 0 ||
                        //   newAddData[index]?.taxable_amount == "" ||
                        //   newAddData[index]?.taxable_amount == undefined
                        // }
                        className="form-control"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={handleKeyPress}
                        placeholder="Enter Other Charges"
                        onChange={(e) => {
                          let prev = [...newAddErr];
                          prev[index].other_charges = "";
                          setNewAddErr(prev);
                          handleOtherChargesChangeNew(
                            e.target.value,
                            index,
                            "other"
                          );
                        }}
                        value={newAddData[index].other_charges}
                      />
                      <div className="small text-danger">
                        {newAddErr[index].isAllFieldEmpty
                          ? ""
                          : newAddErr[index]?.other_charges}
                      </div>
                      {newAddData[index]?.paid_by == "company" ? (
                        <>
                          {" "}
                          {newAddData[index].other_charges > 0 && (
                            <p style={{ color: "red" }}>
                              {" "}
                              Note:Enter Service Charge and applicable GST
                            </p>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group innergroup">
                      <label>Total Bill Amount</label>
                      <input
                        type="number"
                        name="tax_bill_amount"
                        disabled
                        value={(
                          Math.round(newAddData[index].tax_bill_amount * 100) /
                          100
                        ).toFixed(2)}
                        className="form-control ticket-dis"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          // : ""
        }
        <div class="text-sm-right">
          <button
            class="btn btnblanktd text-primary"
            onClick={() => {
              if (ticketDateOption.length === 1) {
                AddMoreData();
              } else if (newAddData?.length < 2) {
                setTicketDateModal(true);
              }
              // handleValidate();
            }}
            disabled={newData.length == 0}
          >
            <i class="far fa-plus-square mr-2"></i> Add More
          </button>
        </div>

        <div class="row">
          <div class="col-md-4 d-flex flex-column mt-3">
            <h5 class="text-theme pb-2 mb-3">
              <strong>
                Attachments<span class="text-danger">*</span>
              </strong>
            </h5>

            <div class="form-group mt-auto mb-0 innergroup position-relative modaldefaultclose ">
              <label>
                Attach File<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control bg-white"
                disabled
                value={
                  // edit === true
                  // ? editPolicy?.logo
                  uploadDataIm?.logo?.original_name
                }
                placeholder="(jpeg, png, jpg, pdf, msg, doc, docx, xlsx, xlsb)"
              />
              <div class="upload-btn-wrapper up-loposition">
                <button class="uploadBtn">Browse</button>
                <input
                  type="file"
                  title=""
                  id="policyattachment"
                  multiple
                  name="attachment_path"
                  //  accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                  onChange={(e) => onProfileChange(e, "attachment_path")}
                />
              </div>
              <p>
                Selected files{" "}
                <span>{/* {uploadDataIm[0]?.original_name} */}</span>
              </p>
            </div>
          </div>
        </div>
        {Object.keys(file).length !== 0 ? (
          <div className="d-flex flex-wrap">{attachments123()}</div>
        ) : attachment != undefined && attachment.length != 0 ? (
          <>
            <div class="d-flex flex-wrap">
              {attachment?.map((Data) => (
                <div class="uploaded-image mr-1 col-2">
                  <i class="" style={{ width: "20px" }}>
                    <a
                      href={Data?.attachment_path}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {Data?.attachment_path?.includes(".pdf") ? (
                        <img
                          src={"/images/policy-icon/pdf.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".xlsx") ? (
                        <img
                          src={"/images/policy-icon/xlsx.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".xls") ? (
                        <img
                          src={"/images/policy-icon/xlsx.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".doc") ? (
                        <img
                          src={"/images/policy-icon/docs.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".docs") ||
                        Data?.attachment_path.includes(".csv") ? (
                        <img
                          src={"/images/policy-icon/docs.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".pptx") ? (
                        <img
                          src={"/images/policy-icon/ppt.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data.attachment_path.includes(".mp4") ? (
                        <img
                          src={"/images/policy-icon/video.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : Data?.attachment_path.includes(".msg") ? (
                        <img
                          src={"/images/policy-icon/msg.png"}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      ) : (
                        <img
                          src={Data.attachment_path}
                          alt="image"
                          width="70px"
                          height="70px"
                        />
                      )}
                      {/* uploadDataIm[0]?.original_name */}

                      <p>{Data?.original_name}</p>
                    </a>
                  </i>
                  <button
                    type="button"
                    onClick={(e) => {
                      deleteAttachment(Data?.attachment_path?.split("/")[4]);
                    }}
                    className="btn btn-blanktd text-danger ml-2"
                  >
                    <i className="far fa-trash-alt text-danger"></i>
                  </button>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}

        <div className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <div className="border-top my-3"></div>
            </div>

            <div className="col-md-12 mt-3 text-center">
              <button
                type="button"
                class="btn btn-outline-secondary mb-2 mt-2"
                onClick={() => {
                  setCheckActiveTab("Summary");
                }}
              >
                Back
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-primary-inner bpi-main"
                type="button"
                onClick={() => {
                  handleValidateForm();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={ticketDateModal}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="fw-bold text-theme">Select Date & Location</h5>
          <i
            className="fas fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => setTicketDateModal(false)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <Select
            options={ticketDateOption}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => setTicketDate(e)}
            value={ticketDate}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary-inner bpi-main"
            onClick={AddMoreData}
          >
            Add Ticket
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={visibleD} onHide={() => handleClose(true)}>
        <Modal.Header className="d-flex justify-content-between">
          <h5 className="fw-bold text-theme">GST NUMBER: 08AABCP2183M1Z5</h5>
          <i
            className="fas fa-times"
            style={{ cursor: "pointer" }}
            onClick={() => handleClose(true)}
          ></i>
        </Modal.Header>
        <Modal.Body>{tooltipTableComp}</Modal.Body>
        <Modal.Footer>
          {/* <button variant="primary" onClick={() => handleClose(true)}>
            Ok
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Ticket;
