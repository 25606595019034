import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Breadcrumb, Badge, Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as otvServiceData from "../../service/otvServices";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { selectUserData } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import OtvReport from "./otvReport";
import * as OtvService from "../../service/otvServices";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { setDisplayLoader } from "../redux/piDataStore";
import * as searchPoService from "../../service/searchpo";

function VendorPayReq() {
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const [otvListData, setOtvListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseValue, setExpenseValue] = useState([]);
  const [vendorFilterList, setVendorFilterList] = useState([]);
  const [vendorFilterValue, setVendorFilterValue] = useState([]);
  const [invoiceFilterList, setinvoiceFilterList] = useState([]);
  const [invoiceFilterValue, setinvoiceFilterValue] = useState([]);
  const [reqIdList, setReqIdList] = useState([]);
  const [reqIdValue, setReqIdValue] = useState([]);
  const [reqByNameList, setReqByNameList] = useState([]);
  const [reqByNameValue, setReqByNameValue] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [draftPageCount, setDraftPageCount] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [showModal, setShowModal] = useState(false);
  const [approverData, setApproverData] = useState([]);
  const [currentTab, setCurrentTab] = useState("otv");
  const [filterApplied, setFilterApplied] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')
  const [filterNonOtv, setFilterNonOtv] = useState({
    end_date: "",
    start_date: "",
    id: "",
    type: "",
    vendor_id: "",
    invoice_no: "",
    expense_category_id: "",
    status: "",
    sap_status: "",
    requested_by_id: "",
  });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const handleKey = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status
  ) => {
    let queryParm = "sort_by=" + sort_by;
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    if (paginate !== "" && paginate !== undefined && paginate !== null) {
      queryParm = queryParm + "&paginate=" + paginate;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    if (end_date !== "" && end_date !== undefined && end_date !== null) {
      queryParm = queryParm + "&end_date=" + end_date;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (sap_status !== "" && sap_status !== undefined && sap_status !== null) {
      queryParm = queryParm + "&sap_status=" + sap_status;
    }
    if (start_date !== "" && start_date !== undefined && start_date !== null) {
      queryParm = queryParm + "&start_date=" + start_date;
    }
    if (type !== "" && type !== undefined && type !== null) {
      queryParm = queryParm + "&type=" + type;
    }
    if (
      requested_by_id !== "" &&
      requested_by_id !== undefined &&
      requested_by_id !== null
    ) {
      queryParm = queryParm + "&requested_by_id=" + requested_by_id;
    }
    if (vendor_id !== "" && vendor_id !== undefined && vendor_id !== null) {
      queryParm = queryParm + "&vendor_id=" + vendor_id;
    }
    if (invoice_no !== "" && invoice_no !== undefined && invoice_no !== null) {
      queryParm = queryParm + "&invoice_no=" + invoice_no;
    }
    if (
      expense_category_id !== "" &&
      expense_category_id !== undefined &&
      expense_category_id !== null
    ) {
      queryParm = queryParm + "&expense_category_id=" + expense_category_id;
    }
    return queryParm;
  };

  const getOtvDataList = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status
  ) => {
    otvServiceData
      .getOtvDataList(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          sap_status
        )
      )
      .then((response) => {
        
        setOtvListData(response?.data?.dataList?.result);
        setDraftPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getFilterOtvDataList = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    otvServiceData
      .getOtvDataList(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        let vendorData = [];
        let invoiceNo = [];
        let requestedBy = [];
        let requestedId = [];

        response?.data?.dataList?.result?.map((res) => {
          let venData = {
            value: res.vendor_code,
            label: res.vendor_name,
          };
          vendorData.push(venData);
          let invoiceData = {
            value: res.invoice_number,
            label: res.invoice_number,
          };
          invoiceNo.push(invoiceData);
          let reqData = {
            value: res.id,
            label: res.id,
          };
          requestedId.push(reqData);
          let reqByData = {
            value: res.created_by,
            label: res.created_by_name,
          };
          requestedBy.push(reqByData);
        });
        let jsonObject = requestedBy.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        let jsonObjectVend = vendorData.map(JSON.stringify);
        let uniqueSetVend = new Set(jsonObjectVend);
        let uniqueArrayVend = Array.from(uniqueSetVend).map(JSON.parse);
        setVendorFilterList(uniqueArrayVend);
        setinvoiceFilterList(invoiceNo);
        setReqIdList(requestedId);
        setReqByNameList(uniqueArray);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addAttachmentData = (id, data) => {
    let value = { is_active: data };
    OtvService.addAttachmentData(id, value)
      .then((response) => {
        getOtvDataList(
          filterNonOtv.type,
          searchStr,
          filterNonOtv.end_date,
          filterNonOtv.start_date,
          filterNonOtv.id,
          userData.id,
          filterNonOtv.vendor_id,
          filterNonOtv.invoice_no,
          filterNonOtv.expense_category_id,
          filterNonOtv.status,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      })
      .catch((error) => {
        // setIsLoading(false);
        toast.error(error?.response?.data?.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExpenseList = () => {
    otvServiceData
      .getExpenseList()
      .then((response) => {
        let data = response?.data?.dataList?.result?.map((res) => {
          return {
            value: res.id,
            label: res.category_name,
          };
        });
        setExpenseList(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getOtvDataList(
      filterNonOtv.type,
      searchStr,
      filterNonOtv.end_date,
      filterNonOtv.start_date,
      filterNonOtv.id,
      userData.id,
      filterNonOtv.vendor_id,
      filterNonOtv.invoice_no,
      filterNonOtv.expense_category_id,
      filterNonOtv.status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      if (currentTab === "otv") {
        getOtvDataList(
          filterNonOtv.type,
          e.target.value.toLowerCase(),
          filterNonOtv.end_date,
          filterNonOtv.start_date,
          filterNonOtv.requested_by_id,
          userData.id,
          filterNonOtv.vendor_id,
          filterNonOtv.invoice_no,
          filterNonOtv.expense_category_id,
          filterNonOtv.status,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      } else {
        getOtvDataList(
          filterNonOtv.type,
          e.target.value.toLowerCase(),
          filterNonOtv.end_date,
          filterNonOtv.start_date,
          filterNonOtv.id,
          "",
          filterNonOtv.vendor_id,
          filterNonOtv.invoice_no,
          filterNonOtv.expense_category_id,
          filterNonOtv.status,
          currentPage,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      }
    }
    setSearchStr(e.target.value);
  };

  const getExportValue = (
    type,
    search,
    end_date,
    start_date,
    id,
    requested_by_id,
    vendor_id,
    invoice_no,
    expense_category_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate,
    sap_status
  ) => {
    dispatch(setDisplayLoader("Display"));
    otvServiceData
      .getExportValue(
        handleKey(
          type,
          search,
          end_date,
          start_date,
          id,
          requested_by_id,
          vendor_id,
          invoice_no,
          expense_category_id,
          status,
          page_no,
          page_size,
          sort_by,
          paginate,
          sap_status
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Otv-Report.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const onPushVendorFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, vendor_id: ids });
    setVendorFilterValue(e);
  };

  const onPushInvoiceFilter = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    if (ids !== "") {
      setFilterNonOtv((prev) => ({ ...prev, invoice_no: ids }));
    }
    setinvoiceFilterValue(e);
  };

  const onRequestId = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, id: ids });
    setReqIdValue(e);
  };

  const onPushRequestBy = (e) => {
    let ids = e.map((item) => {
      return item?.value;
    });
    setFilterNonOtv({ ...filterNonOtv, requested_by_id: ids });
    setReqByNameValue(e);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    if (currentTab === "otv") {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        userData.id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        "",
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        activePage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const submit = (id, value) => {
    confirmAlert({
      title: "Delete OTV ",
      message: `Are you sure to delete OTV ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => addAttachmentData(id, value),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const filterClose = () => {
    filterclose();
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      if (currentTab === "otv") {
        getOtvDataList(
          "",
          "",
          "",
          "",
          "",
          userData.id,
          "",
          "",
          "",
          "",
          1,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      } else {
        getOtvDataList(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          1,
          pageSizeNo,
          sortData.sort_by,
          true
        );
      }
    }
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(filterNonOtv));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setReqIdValue([]);
    setinvoiceFilterValue([]);
    setReqByNameValue([]);
    setVendorFilterValue([]);
    setExpenseValue([]);
    setFilterNonOtv(ClearData);
    filterClose();
    if (currentTab === "otv") {
      getOtvDataList(
        "",
        searchStr,
        "",
        "",
        "",
        userData.id,
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else {
      getOtvDataList(
        "",
        searchStr,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const mainDataFilter = () => {
    setFilterApplied(true);
    const end_date = filterNonOtv.end_date;
    const start_date = filterNonOtv.start_date;
    const id = filterNonOtv.id;
    const vendor_id = filterNonOtv.vendor_id;
    const invoice_no = filterNonOtv.invoice_no;
    const expense_category_id = filterNonOtv.expense_category_id;
    const status = filterNonOtv.status;
    const requestId = filterNonOtv.requested_by_id;
    const sap_status = filterNonOtv.sap_status;
    if (currentTab === "otv") {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        end_date,
        start_date,
        id,
        userData.id,
        vendor_id,
        invoice_no,
        expense_category_id,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        sap_status
      );
    } else {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        end_date,
        start_date,
        id,
        requestId,
        vendor_id,
        invoice_no,
        expense_category_id,
        status,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        sap_status
      );
    }
    filterClose();
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onShowModel = (data) => {
    setApproverData(data);
    setShowModal(true);
  };

  const onDataViewNavigate = (id, flag) => {
    navigate(`/viewotv/${id}`, {
      state: { from: id, flag: flag },
    });
  };

  const onEditNavigate = (data) => {
    if (data.type === "OTV") {
      navigate(`/otv-payment-request`, {
        state: { from: data, flag: "edit" },
      });
    } else if (data?.type === "NONPO") {
      navigate(`/non-po-payment-request`, {
        state: { from: data, flag: "edit" },
      });
    } else {
      navigate(`/vendor-reimb`, {
        state: { from: data, flag: "edit" },
      });
    }
  };

  useEffect(() => {
    getExpenseList();
    getSapSchedule()
    if (pathname === "/otv-report") {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        "",
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else {
      getOtvDataList(
        filterNonOtv.type,
        searchStr,
        filterNonOtv.end_date,
        filterNonOtv.start_date,
        filterNonOtv.requested_by_id,
        userData.id,
        filterNonOtv.vendor_id,
        filterNonOtv.invoice_no,
        filterNonOtv.expense_category_id,
        filterNonOtv.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  }, [pageSizeNo]);

  useEffect(() => {
    clearFilter();
    if (pathname === "/otv-report") {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    } else {
      getOtvDataList(
        "",
        "",
        "",
        "",
        "",
        userData.id,
        "",
        "",
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  }, [currentTab]);

  useEffect(() => {
    if (pathname === "/otv-report") {
      setCurrentTab("otvReport");
    } else {
      setCurrentTab("otv");
    }
  }, [pathname]);

  useEffect(() => {
    if (show) {
      getFilterOtvDataList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        sortData.sort_by,
        false
      );
    }
  }, [show]);

  const getSapSchedule = () => {
    searchPoService.get_sap_schedule('sync_post_otv_or_nonPO_in_sap').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    // searchPoService.get_sap_schedule('sync_po_from_sap').then((response)=>{
    //   let next_sap_syncing_time = response?.data?.dataList?.result
    //   setNextSapSyncingTime(next_sap_syncing_time)
    // })
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">OTV/NONPO</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transaction</Breadcrumb.Item>
                    <Breadcrumb.Item active>My Request</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Invoice,
                            <br />
                            bank name
                            <br />
                            bank acc
                            <br />
                            bank ifsc
                            <br />
                            status
                            <br />
                            type
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Invoice, bank name, bank ifsc ..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    {/* {pathname == "/otv-report" && ( */}
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportValue(
                                "",
                                searchStr,
                                filterNonOtv.end_date,
                                filterNonOtv.start_date,
                                filterNonOtv.id,
                                pathname === "/otv-report" ? filterNonOtv.requested_by_id : userData.id,
                                filterNonOtv.vendor_id,
                                filterNonOtv.invoice_no,
                                filterNonOtv.expense_category_id,
                                filterNonOtv.status,
                                "",
                                "",
                                "id",
                                "",
                                filterNonOtv.sap_status
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* )} */}
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{ fontSize: "13px" }}>Next SAP Posting:</b> <span className="mr-3" style={{ fontSize: "13px" }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  {/* <b style={{fontSize:"13px"}}>Next SAP Syncing:</b> <span className="" style={{fontSize:"13px"}}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span> */}
                </div>
              </div>
            </div>

            <div class="approval-bg no-bg border-0 purchaseinnertabs position-relative">
              <Tabs
                defaultActiveKey={
                  pathname == "/otv-report" ? "otvReport" : "otv"
                }
                id="approvaltabs"
              >
                {pathname == "/otv-vendor-request" && (
                  <Tab
                    eventKey="otv"
                    title={
                      <React.Fragment>
                        OTV/NONPO
                        {/* <span class="numberpurchse">11</span> */}
                      </React.Fragment>
                    }
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered tablecured travelintim newSpace">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Pending At</th>
                            <th>Action</th>
                            <th
                              onClick={(e) => {
                                handlerData("id");
                              }}
                              className={
                                sortData["id"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["id"]
                              }
                            >
                              Request ID
                            </th>
                            <th
                              onClick={(e) => {
                                handlerData("code");
                              }}
                              className={
                                sortData["code"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["code"]
                              }
                            >
                              Vendor Code
                            </th>
                            <th
                              onClick={(e) => {
                                handlerData("name");
                              }}
                              className={
                                sortData["name"] === undefined
                                  ? "unsortcls main-pointer"
                                  : sortData["name"]
                              }
                            >
                              Vendor Name
                            </th>
                            <th>GST</th>
                            <th>PAN</th>
                            <th>Location</th>
                            <th>Invoice No</th>
                            <th>Invoice Date</th>
                            <th>Expense Category</th>
                            <th>Base Amount</th>
                            <th>GL Code</th>
                            <th>Cost Center</th>
                            <th>Company Code</th>
                            <th>Bank Name</th>
                            <th>Bank A/c No</th>
                            <th>IFC Code</th>
                            <th>SAP Document No</th>
                            <th>SAP Message</th>
                            <th>SAP status</th>
                            <th>Type</th>
                            <th>Requested By</th>
                            <th>Requested On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {otvListData?.length ? (
                            otvListData?.map((data) => (
                              <tr>
                                <td>
                                  {(data?.ssc_status === true && data?.picked_by_ssc === false) ?
                                    <Badge bg="primary">In SSC Pick List</Badge>
                                    : data.is_active === true ? (
                                      data?.is_bulk_uploaded === true ?
                                        <Badge bg="primary">Draft</Badge>
                                        :
                                        data?.status === null ? (
                                          <Badge bg="secondary">Pending</Badge>
                                        ) : data?.status == "A" ? (
                                          <Badge bg="success">Approved</Badge>
                                        ) : (
                                          <Badge bg="danger">Rejected</Badge>
                                        )
                                    ) : (
                                      <Badge bg="danger">Deleted</Badge>
                                    )}
                                </td>
                                <td>
                                  {data?.status == "A" ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={`${data?.current_approver_data?.current_approver_code}`}
                                      position="bottom"
                                    >
                                      {data?.current_approver_data
                                        ?.current_approver_name !== undefined
                                        ? `${data?.current_approver_data?.current_approver_name} (${data?.current_approver_data?.current_approver_code})`
                                        : ""}
                                    </Tooltip>
                                  )}
                                </td>
                                <td>
                                  <button
                                    class="btn btn-blanktd text-primary f-12"
                                    onClick={() =>
                                      onDataViewNavigate(data.id, 1)
                                    }
                                  >
                                    <i class="far fa-eye"></i>
                                  </button>
                                  &nbsp;
                                  {((data?.attachments?.length === 0 && data?.is_bulk_uploaded) || data?.status === "D") && (
                                    <button
                                      className="btn btn-blanktd text-primary"
                                      onClick={() => {
                                        onEditNavigate(data);
                                      }}
                                    >
                                      <i className="far fa-edit"></i>
                                    </button>
                                  )}
                                  {data.is_active === true &&
                                    ((data?.status !== "A" && data?.approve_at_any_level !== true) || (data?.status == 'A' && data?.sap_status == false) || ((data?.status == 'D' && data?.sap_status == false)) ) && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          submit(data.id, "true");
                                        }}
                                        className="btn btn-blanktd text-danger ml-2"
                                      >
                                        <i className="far fa-trash-alt text-danger"></i>
                                      </button>
                                    )}
                                </td>
                                <td class="text-theme fw-bold">{`${data?.type}${data?.id}`}</td>
                                <td>
                                  {data?.vendor_code === null
                                    ? "104746"
                                    : data?.vendor_code}
                                </td>
                                <td>
                                  {data?.otv_name === null
                                    ? data?.vendor_name
                                    : data?.otv_name}
                                </td>
                                <td>{data?.vendor_gst}</td>
                                <td>{data?.pan_no}</td>
                                {/* <td>
                                    <button
                                      class="btn btn-blanktd text-primary f-12"
                                      onClick={() => onShowModel(data)}
                                    >
                                      Approve
                                    </button>
                                  </td> */}
                                <td>{data?.vendor_location}</td>
                                <td>{data?.invoice_number}</td>
                                <td>
                                  {moment(data?.invoice_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{data?.expense_name}</td>
                                <td>{data?.gross_amount}</td>
                                <td>
                                  <Tooltip
                                    title={`${data?.gl_code}`}
                                    position="bottom"
                                  >{`${data?.gl_desc} (${data?.gl_code})`}</Tooltip>
                                </td>
                                {data?.cost_center_id !== null ?
                                  <td>
                                    {" "}
                                    <Tooltip
                                      title={`${data?.cost_center_code}`}
                                      position="bottom"
                                    >{`${data?.cost_center_name} (${data?.cost_center_code})`}</Tooltip>
                                  </td>
                                  :
                                  <td></td>
                                }
                                <td>{data?.organisation_name} - {data?.organisation_code}</td>
                                <td>{data?.bank_name}</td>
                                <td>{data?.bank_account_number}</td>
                                <td>{data?.bank_ifsc_code}</td>
                                <td>{data?.sap_doc_no}</td>
                                <td>
                                  <Tooltip
                                    title={`${data?.sap_message}`}
                                    position="bottom"
                                  >
                                    {data?.sap_message}
                                  </Tooltip>
                                </td>
                                <td>{data?.sap_status}</td>
                                <td>
                                  {data?.type === "OTV" ? (
                                    <Tooltip
                                      title="OTV Payment Request"
                                      position="bottom"
                                    >
                                      OTV Payment Request
                                    </Tooltip>
                                  ) : data?.type === "NONPO" ? (
                                    <Tooltip
                                      title="Non PO Vendor Payment Request"
                                      position="bottom"
                                    >
                                      Non PO Vendor Payment Request
                                    </Tooltip>
                                  ) : data?.type === "NONREIMB" ? (
                                    <Tooltip
                                      title="Vendor Invoice Reimb below 5K"
                                      position="bottom"
                                    >
                                      Vendor Invoice Reimb below 5K
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{data?.created_by}</td>
                                <td>
                                  {moment(data?.created_at)
                                    .utc()
                                    .format("DD-MMM-YYYY hh:mm a")}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={22}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-sm-5">
                        <div class="sortinglist">
                          Show
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setPageSizeNo(e.target.value);
                              setCurrentPage(1);
                            }}
                          >
                            <option value={10} label={10}>
                              10
                            </option>
                            <option value={20} label={20}>
                              20
                            </option>
                            <option value={30} label={30}>
                              30
                            </option>
                            <option value={100} label={100}>
                              100
                            </option>
                            <option value={500} label={500}>
                              500
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-7">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={draftPageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-left"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </Tab>
                )}

                {pathname == "/otv-report" && (
                  <Tab
                    eventKey="otvReport"
                    title={<React.Fragment>NONPO/OTV-Report</React.Fragment>}
                  >
                    <OtvReport
                      otvListData={otvListData}
                      handlerData={handlerData}
                      sortData={sortData}
                      handlePageClick={handlePageClick}
                      draftPageCount={draftPageCount}
                      setPageSizeNo={setPageSizeNo}
                      setCurrentPage={setCurrentPage}
                      onDataViewNavigate={onDataViewNavigate}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="start_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      start_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.start_date}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group innergroup">
                <label>End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="end_date"
                  onChange={(e) =>
                    setFilterNonOtv({
                      ...filterNonOtv,
                      end_date: e.target.value,
                    })
                  }
                  value={filterNonOtv.end_date}
                />
              </div>
            </div>
          </div>

          <div class="form-group innergroup">
            <label>Select Type</label>
            <select
              class="form-control newbgselect"
              name="type"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  type: e.target.value,
                })
              }
              value={filterNonOtv.type}
            >
              <option value="">Select</option>
              <option value="OTV">OTV Payment Request</option>
              <option value="NONPO">Non PO Vendor Payment Request</option>
              <option value="NONREIMB">Vendor Invoice Reimb below 5K</option>
            </select>
          </div>

          {currentTab === "otvReport" && (
            <div class="form-group innergroup">
              <label>Request Id</label>
              <Select
                isMulti
                options={reqIdList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => onRequestId(e)}
                value={reqIdValue}
              />
            </div>
          )}
          <div class="form-group innergroup">
            <label>Request By</label>
            <Select
              isMulti
              options={reqByNameList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushRequestBy(e)}
              value={reqByNameValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Vednor Name</label>
            <Select
              isMulti
              options={vendorFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                onPushVendorFilter(e);
              }}
              value={vendorFilterValue}
            />
          </div>

          <div class="form-group innergroup">
            <label>Invoice No.</label>
            <Select
              isMulti
              options={invoiceFilterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushInvoiceFilter(e)}
              value={invoiceFilterValue}
            />
          </div>
          {/* <div class="form-group innergroup">
            <label>Expenses Category</label>
            <Select
              options={expenseList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => onPushExpensesFilter(e)}
              value={expenseValue}
            />
          </div> */}

          <div class="form-group innergroup">
            <label>Select Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  status: e.target.value,
                })
              }
              value={filterNonOtv.status}
            >
              <option value="">Select</option>
              <option value="P">Pending</option>
              <option value="D">Rejected</option>
              <option value="A">Approved</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>Select SAP Status</label>
            <select
              class="form-control newbgselect"
              name="status"
              onChange={(e) =>
                setFilterNonOtv({
                  ...filterNonOtv,
                  sap_status: e.target.value,
                })
              }
              value={filterNonOtv?.sap_status}
            >
              <option value="">Select</option>
              <option value="E">Error</option>
              <option value="S">Success</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            class="btn btn-outline-danger"
            onClick={() => clearFilter()}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VendorPayReq;
