import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import * as PolicyService from "../../service/policy";
import * as BrandService from "../../service/brandService";
import * as CmsNewsService from "../../service/Cms-newsService";
import { replace } from "formik";
import {
  getReceiptData,
  updateStatus,
  getCashbookList,
  getDemandList,
} from "../../service/cashbookService";
import { selectUserData } from "../redux/piDataStore";
import * as VendorService from "../../service/cashbookService";

function ApproveCashbookModules(props) {
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState(null);
  const [error, seterror] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [status, setStatus] = useState({
    status: "",
    remarks: "",
  });
  const userData = useSelector(selectUserData);

  const getPolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    PolicyService.getPolicy(
      handleValuePolicy(
        search,
        organisation_id,
        category,
        policy_id,
        status,
        pageNo,
        page_size,
        sortBy,
        paginate
      )
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValuePolicy = (
    search,
    organisation_id,
    category,
    policy_id,
    status,
    pageNo,
    page_size,
    sortBy,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sortBy +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (policy_id !== "" && policy_id !== undefined && policy_id !== null) {
      queryParm = queryParm + "&policy_id=" + policy_id;
    }
    return queryParm;
  };

  const handleValueBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by
  ) => {
    let queryParm =
      "page_no=" + page_no + "&page_size=" + page_size + "&sort_by=" + sort_by;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (category !== "" && category !== undefined && category !== null) {
      queryParm = queryParm + "&category=" + category;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (
      branding_id !== "" &&
      branding_id !== undefined &&
      branding_id !== null
    ) {
      queryParm = queryParm + "&branding_id=" + branding_id;
    }
    return queryParm;
  };

  const getBrand = (
    search,
    organisation_id,
    branding_id,
    category,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    BrandService.getBrand(
      handleValueBrand(
        search,
        organisation_id,
        branding_id,
        category,
        status,
        page_no,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getData = async (
    currentpage,
    pagesize,
    sort_by,
    search,
    paginate,
    type,
    from_date,
    to_date,
    data_id
  ) => {
    await CmsNewsService.getNotice(
      currentpage,
      pagesize,
      sort_by,
      search,
      paginate,
      type,
      from_date,
      to_date,
      data_id
    )
      .then((response) => {
        setData(response?.data?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    switch (location?.state?.type) {
      case "Receipt":
        getReceiptDataById("", 1, 10, "id", true, id);
        break;
      case "Expense":
        getCashbookExpense("", 1, 10, "id", true, "", "", id);
        break;
      case "Demand":
        getDemandListById("", 1, 10, "id", true, id);
        break;
      default:
        break;
    }
  }, []);

  const getMasterData = (data) => {
    VendorService.getMasterData(data)
      .then((response) => {
        setMasterData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getDemandListById = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    demand_id
  ) => {
    let queryParam =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&sort_by=" +
      sort_by +
      "&search=" +
      search +
      "&demand_id=" +
      demand_id +
      "&approval=" +
      true;
    getDemandList(queryParam)
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
        getMasterData(response?.data?.dataList?.result[0]?.created_by_id);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCashbookExpense = async (
    search,
    page_no,
    page_size,
    sort_by,
    status,
    to_date,
    from_date,
    expense_id
  ) => {
    let queryParam = {
      page_no: page_no,
      page_size: page_size,
      paginate: true,
      sort_by: sort_by,
      search: search,
      status: true,
      expense_id: expense_id,
    };
    await getCashbookList(queryParam).then((response) => {
      console.log(response?.data?.dataList?.result[0]);
      setData(response?.data?.dataList?.result[0]);
    });
  };

  const getReceiptDataById = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    receipt_id
  ) => {
    let queryParam =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate +
      "&sort_by=" +
      sort_by +
      "&search=" +
      search +
      "&receipt_id=" +
      receipt_id;
    getReceiptData(queryParam)
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submitAction = (id, stat) => {
    let data = status;
    data.status = stat;
    switch (location?.state?.type) {
      case "Receipt":
        data.receipt_id = id;
        data.expense_id = [];
        data.demand_id = 0;
        changeReceiptRemarks(data);
        break;
      case "Expense":
        data.receipt_id = 0;
        data.expense_id = [id];
        data.demand_id = 0;
        changeReceiptRemarks(data);
        break;
      case "Demand":
        data.receipt_id = 0;
        data.expense_id = [];
        data.demand_id = id;
        changeReceiptRemarks(data);
        break;
    }
  };

  const changeReceiptRemarks = (data) => {
    if (data.remarks != "") {
      setIsLoading(true);
      updateStatus(data)
        .then((response) => {
          // getReceiptDataById("", 1, 10, "id", true, id);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success("Approved Successfully.");
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeBrandingRemarks = (data) => {
    if (data.remarks != "") {
      setIsLoading(true);
      BrandService.changeBrandingStatus(data)
        .then((response) => {
          getBrand("", "", id, "", true, 1, 10, "id", true);
          setStatus({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  const changeNewsRemarks = (data) => {
    if (data.remarks != "") {
      setIsLoading(true);
      CmsNewsService.changeNewsStatus(data)
        .then((response) => {
          // getData(1, 10, "id", "", true, "", "", "", id);
          setData({
            status: "",
            remarks: "",
          });
          setIsLoading(false);
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.success(error.response.data.detail);
          setIsLoading(false);
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>
                {location?.state?.type === "Demand"
                  ? "Cash Demand"
                  : location?.state?.type === "Expense"
                  ? "Cash Expense"
                  : location?.state?.type}
              </Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">
                    Approve/Reject{" "}
                    {location?.state?.type === "Demand"
                      ? "Cash Demand"
                      : location?.state?.type === "Expense"
                      ? "Cash Expense"
                      : location?.state?.type}
                  </h3>
                </div>
                {console.log("hi")}
              </div>
              <div class="row viewformaftersubmit">
                {location?.state?.type === "Receipt" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester At</label>
                        <p>
                          {moment(Data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    {console.log(Data, "pink")}
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Check Number</label>
                        <p>{Data?.check_no}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Receipt Date</label>
                        <p>
                          {moment(Data?.receipt_date).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Amount</label>
                        <p>{Data?.amount}</p>
                      </div>
                    </div>
                  </>
                ) : location?.state?.type === "Expense" ? (
                  <>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Request ID</label>
                        <p>PICB{Data?.id}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester At</label>
                        <p>
                          {moment(Data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Cost Center</label>
                        <p>{Data?.cost_center__cost_center}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Invoice Date</label>
                        <p>
                          {moment(Data?.invoice_date).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Invoice Number</label>
                        <p>{Data?.invoice_no}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>GST Number</label>
                        <p>{Data?.pi_gst_no}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Total Amount</label>
                        <p>{Data?.total_amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Vendor</label>
                        <p>{Data?.vendor__name} </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Vendor Address</label>
                        <p>{Data?.vendor_add} </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Vendor GST No</label>
                        <p>{Data?.vendor_gst} </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>GL Code</label>
                        <p>{Data?.expense_detail[0]?.expense_gl_code}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>GL Description</label>
                        <p>{Data?.expense_detail[0]?.expense_gl_desc}</p>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 mt-3">
                      <p>Expense Details</p>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Item No.</th>
                              <th>Expense GL</th>
                              <th>HSN</th>
                              <th>UOM</th>
                              <th>Quantity</th>
                              <th>GST Credit</th>
                              <th>Tax Rate</th>
                              <th>Tax Code</th>
                              <th>Base Amount</th>
                              <th>Tax Amount</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Data?.expense_detail?.map((obj) => (
                              <tr>
                                <td>{obj?.item_no}</td>
                                <td>{`${obj?.expense_gl_desc} (${obj?.expense_gl_code})`}</td>
                                <td>{obj?.hsn}</td>
                                <td>{obj?.uom}</td>
                                <td>{obj?.quantity}</td>
                                <td>{obj?.credit}</td>
                                <td>{obj?.tax_rate}%</td>
                                <td>{obj?.tax_code}</td>
                                <td>{obj?.base_amount}</td>
                                <td>{obj?.tax_amount}</td>
                                <td>{obj?.total_amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="col-md-4">
                      {console.log(Data, "pom")}
                      <div class="form-group innergroup">
                        <label>Requester</label>
                        <p>{Data?.created_by}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Requester At</label>
                        <p>
                          {moment(Data?.created_at).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>State</label>
                        <p>{Data?.state_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Document Type</label>
                        <p>
                          {Data?.doc_type === "P"
                            ? "Pancard"
                            : Data?.doc_type === "A"
                            ? "Aadhar Card"
                            : Data?.doc_type === "A"
                            ? "Driving License"
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Beneficiary Name</label>
                        <p>{Data?.beneficiary_user_name}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Beneficiary Code</label>
                        <p>{Data?.beneficiary_code}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Cashbook Description</label>
                        <p>{Data?.cashbook_desc}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Amount</label>
                        <p>{Data?.amount}</p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Remarks</label>
                        <p>{Data?.remarks}</p>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-4">
                      <div class="form-group innergroup">
                        <label>Cashbook Balance</label>
                        <p>
                          {masterData?.length > 0
                            ? masterData[0]?.current_balance
                            : ""}
                        </p>
                      </div>
                    </div>
                    <br />
                    <div className="col-md-12 mt-3">
                      <p>Last 5 cash demand requests</p>
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Cash Request No.</th>
                              <th>Amount</th>
                              <th>Request Date</th>
                              <th>SAP Doc No.</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Data?.last_five?.map((obj) => (
                              <tr>
                                <td>PICASHDE{obj?.id}</td>
                                <td>{obj?.amount}</td>
                                <td>
                                  {moment(obj?.created_at).format(
                                    "DD-MM-YYYY hh:mm:ss A"
                                  )}
                                </td>
                                <td>{obj?.sap_doc_no}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {(Data?.status == "P" || Data?.status === null) && (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label></label>
                    <textarea
                      onChange={(e) => {
                        setStatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give comment
                      </div>
                    }
                  </div>
                </div>

                <div class="col-md-12 pb-3">
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={() => {
                      window.history.go(-1);
                      return false;
                    }}
                  >
                    <i class="fas fa-arrow-circle-left"></i> Cancel
                  </button>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          submitAction(Data?.id, "R");
                        }}
                        class="btn btn-outline-danger mr-2"
                      >
                        <i class="far fa-times-circle"></i> Reject
                      </button>

                      <button
                        onClick={() => {
                          submitAction(Data?.id, "A");
                        }}
                        class="btn btn-primary-inner bpi-main"
                      >
                        <i class="far fa-check-square"></i> Approve
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveCashbookModules;
