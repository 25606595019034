import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const saveVehicleLog = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle`, data);
};

export const getModeList = async (is_vehicle) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/mode/drop-down?is_vehicle=${is_vehicle}`
  );
};
export const getVehicleLog = async (travelId, user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle?travel_id=${travelId}&user_id=${user_id}`
  );
};

export const updateVehicleLog = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle/${id}`,
    data
  );
};
export const updateVehicleLogssc = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle_by_ssc/${id}`,
    data
  );
};

export const saveVehicleMaster = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/travel-n-expanse/vehicle_master`, data);
};

export const updateVehicleMaster = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_master/${id}`,
    data
  );
};

export const getVehicleData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_master?${queryParm}`
  );
};

export const saveVehicleAssignMaster = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_assignment`,
    data
  );
};

export const updateVehicleAssignMaster = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_assignment/${id}`,
    data
  );
};

export const getVehicleAssignData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_assignment?${queryParm}`
  );
};
export const getVehicleHistoryData = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_log?${queryParm}`
  );
};

export const getExportValueDemand = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/vehicle_assignment?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/vehicle_log_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getVehicleModeRate = async (travel_mode_id, organisation_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/mode_rates?travel_mode_id=${travel_mode_id}&organisation_id=${organisation_id}`
  );
};
export const getVehicleLogMonthly = async (user_id, travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/vehicle_log_monthly_limit?user_id=${user_id}&travel_id=${travel_id}`
  );
};
export const getVehicleDailyWages = async (user_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/user_daily_wages?user_id=${user_id}`
  );
};
export const getUserDriverExists = async (user_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/user_driver_exists?user_id=${user_id}`
  );
};
export const getvehicleExists = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/travel-n-expanse/expense_vehicle_is_exist`,
    data
  );
};
export const saveExceptionData = async (
  id,
  expense_exception,
  vehicle_exception,
  data
) => {
  return axios.put(
    `${AUTH_BASE_URL}/travel-n-expanse/update_2/${id}?${
      expense_exception == undefined ||
      expense_exception == "" ||
      expense_exception == null
        ? ""
        : `expense_exception=${expense_exception}`
    }${
      vehicle_exception == undefined ||
      vehicle_exception == "" ||
      vehicle_exception == null
        ? ""
        : `&vehicle_exception=${vehicle_exception}`
    }`,
    data
  );
};

export const getForeignExpenseData = async (travel_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/travel-n-expanse/foreign_expense_dates/${travel_id}`
  );
};

export const exportResExcel = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/travel-n-expanse/vehicle_master?sort_by=id&${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
