import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

export const CustomBreadcrumb = ({ items }) => {
    return <></>;
    return (
        <>
            <Breadcrumb>
                {
                    Array.isArray(items) && items.map(item => {
                        const itemProps = {};
                        if (item.to) {
                            itemProps.href = item.to;
                        }
                        if (item.active) {
                            itemProps.active = true
                        }
                        return (
                            <>
                                <Breadcrumb.Item {...itemProps}>{item.title}</Breadcrumb.Item>
                            </>
                        )
                    })
                }
            </Breadcrumb>
        </>
    )
}