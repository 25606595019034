import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import {
  changeStatus,
  editApprover,
  getSourcelist,
} from "../../service/source-list";
import { getUserDetails } from "../../service/header";
import { selectUserData } from "../redux/piDataStore";

function ViewSource(props) {
  const currentuserdata = useSelector(selectUserData);
  const location = useLocation();
  console.log(location?.state?.from);
  const [id, setid] = useState(location?.state?.from);
  const navigate = useNavigate();

  const [Data, setData] = useState();
  const [edit, setedit] = useState(false);
  const [error, seterror] = useState(false);
  const [Status, setstatus] = useState({
    sourcelist_id: 0,
    status: "",
    remarks: "",
  });
  const getlist = async (
    search,
    pageno,
    page_size,
    sort_by,
    status,
    created_at,
    created_to,
    category,
    sourcelist_id,
    material_id,
    plant_id,
    vendor_id
  ) => {
    await getSourcelist(
      search,
      pageno,
      page_size,
      sort_by,
      status,
      created_at,
      created_to,
      category,
      sourcelist_id,
      material_id,
      plant_id,
      vendor_id
    )
      .then((response) => {
        setData(response?.data?.dataList?.result[0]);
        if (
          response?.data?.dataList?.result[0]?.currentApprover[0].userid ==
          currentuserdata.id
        ) {
          setedit(true);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getlist("", 1, 10, "-id", "", "", "", "", id, "", "", "");
  }, []);
  const submitAction = (id, status) => {
    let data = Status;
    data.status = status;
    data.sourcelist_id = id;
    console.log(data);
    if (data.remarks != "") {
      changeStatus(data)
        .then((response) => {
          getlist("", 1, 10, "-id", "", "", "", id, "", "", "");
          setstatus({
            sourcelist_id: 0,
            status: "",
            remarks: "",
          });
          toast.success(response.data.message);
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          toast.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };
  return (
    <>
      <ToastContainer></ToastContainer>
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>Source List</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            {
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h3 class="policyhead">Approve/Reject Source</h3>
                  </div>
                </div>
                <div class="row viewformaftersubmit">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Requester</label>
                      <p>{Data?.created_by}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Plant</label>
                      <p>{Data?.plant_code}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Purchasing Organization</label>
                      <p>{Data?.purchase_org_code}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Material Code</label>
                      <p>
                        {Data?.material_code}-{Data?.material_code_name}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Vendor Code</label>
                      <p>
                        {Data?.vendor_code}-{Data?.vendor_code_name}
                      </p>
                    </div>
                  </div>
                  {/* <div class="col-md-4">
                                                <div class="form-group innergroup">
                                                    <label>Total Gross Value</label>
                                                    <p>14,65,900.00</p>
                                                </div>
                                            </div> */}
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Category</label>
                      <p>{Data?.category}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Vendor Category</label>
                      <p>{Data?.vendor_category}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Valid From</label>
                      <p>{moment(Data?.valid_from).format("Do MMM YYYY")}</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>Valid To</label>
                      <p>{moment(Data?.valid_to).format("Do MMM YYYY")}</p>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group innergroup">
                      <label>Download Attachments</label>
                      <p>
                        {Data?.attachment.map((a) => (
                          <a
                            target="_blank"
                            href={a?.attachment}
                            rel="noreferrer"
                          >
                            <span class="mr-3">
                              <i class="fas fa-file-download"></i>{" "}
                              {a?.attachment.split("/")[6]}
                            </span>
                          </a>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div class="row">
              <div class="col-md-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Approver Stage</th>
                        <th>Approver Name</th>
                        <th>Approved Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Data?.approver.filter((obj) => {
                        if ((Data?.category === 'Capex' || Data?.category === 'Engineering') && obj?.type === 'Additional Approver'){
                          return false;
                        } else {
                          return true;
                        }
                      }).map((a, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {a?.type == "Additional Approver"
                              ? "Final Approver"
                              : a?.type}
                          </td>
                          <td class="text-theme fw-bold">{a?.approver}</td>
                          <td>
                            {a?.updated_at === null
                              ? ""
                              : moment(a?.updated_at).format("Do MMM YYYY")}
                          </td>
                          <td>{a?.remarks}</td>
                          {a?.updated_at === null ? (
                            ""
                          ) : (
                            <td>
                              {a?.status == null ? (
                                <span class="badge badge-secondary">
                                  Pending
                                </span>
                              ) : a?.status == "A" ? (
                                <span class="badge badge-success">
                                  Approved
                                </span>
                              ) : a?.status == "D" ? (
                                <span class="badge badge-danger">Rejected</span>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      ))}
                      {/* <tr>
                        <td>2</td>
                        <td>Approver 2</td>
                        <td class="text-theme fw-bold">
                          {Data?.approver[1]?.approver}
                        </td>
                        <td>
                          {moment(Data?.approver[1]?.updated_at).format(
                            "Do MMM YYYY"
                          )}
                        </td>
                        <td>{Data?.approver[1]?.remarks}</td>
                        <td>
                        {Data?.approver[1]?.status == null ? (
                            <span class="badge badge-secondary">Pending</span>
                          ) : Data?.approver[1]?.status == "A" ? (
                            <span class="badge badge-success">Approved</span>
                          ) : Data?.approver[1]?.status == "D" ?  <span class="badge badge-danger">Rejected</span>:null}
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Final Approver</td>
                        <td class="text-theme fw-bold">
                          {Data?.approver[2]?.approver}
                        </td>
                        <td>
                          {moment(Data?.approver[2]?.updated_at).format(
                            "Do MMM YYYY"
                          )}
                        </td>

                        <td>{Data?.approver[2]?.remarks}</td>
                        <td>
                          {Data?.approver[2]?.status == null ? (
                            <span class="badge badge-secondary">Pending</span>
                          ) : Data?.approver[2]?.status == "A" ? (
                            <span class="badge badge-success">Approved</span>
                          ) : Data?.approver[2]?.status == "D" ?  <span class="badge badge-danger">Rejected</span>:null}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {Data?.status == null && edit ? (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Add Comments<span class="text-danger">*</span>
                    </label>
                    <textarea
                      onChange={(e) => {
                        setstatus((prev) => ({
                          ...prev,
                          remarks: e.target.value,
                        }));
                        seterror(false);
                      }}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give comment
                      </div>
                    }
                  </div>
                </div>

                {
                  <div class="col-md-12 pb-3">
                    <button
                      onClick={() => {
                        window.history.go(-1);
                        return false;
                      }}
                      class="btn btn-outline-secondary mr-2"
                    >
                      <i class="fas fa-arrow-circle-left"></i> Cancel
                    </button>
                    <button
                      onClick={() => {
                        submitAction(Data?.id, "D");
                      }}
                      class="btn btn-outline-danger mr-2"
                    >
                      <i class="far fa-times-circle"></i> Reject
                    </button>
                    <button
                      onClick={() => {
                        submitAction(Data?.id, "A");
                      }}
                      class="btn btn-primary-inner bpi-main"
                    >
                      <i class="far fa-check-square"></i> Approve
                    </button>
                  </div>
                }
              </>
            ) : (
              <button
                onClick={() => {
                  window.history.go(-1);
                  return false;
                }}
                class="btn btn-outline-secondary mr-2"
              >
                <i class="fas fa-arrow-circle-left"></i> Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSource;
