import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";
import * as surveyService from "../../service/Survey";
import MultiOptionSurveyView from "../company/MultiOptionSurveyView";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ViewSurveyform() {
  const [asmntData, setAsmntData] = useState({});
  const [curAssess, setCurAssess] = useState([]);
  const [secDesc, setSecDesc] = useState([]);
  const [validation, setValidation] = useState([])
  const [userInput, setUserInput] = useState({
    id: 0,
    is_draft: false,
    survey_id: 0,
    questions: [],
  });

  const location = useLocation();
  //   const { targetAsmnt, flag } = state;

  const navigate = useNavigate();
  var view = 'true';

  useEffect(() => {
    surveyService
      .getSurvey(`survey_id=${location?.state?.from}`)
      .then((response) => {
        console.log("viewing.,", response?.data?.dataList?.result);
        let finalRes = {};
        let valid = []
        let secValid = {}
        // getAllCategoryType();
        setAsmntData(response?.data?.dataList?.result[0]);
        response?.data?.dataList?.result[0]?.questions?.map((qItem, index) => {
          if (Object.keys(finalRes).includes(qItem?.section)) {
            secValid[qItem?.section].push({
              mandatory: false,
              id: qItem?.id,
              feedback: false
            })
            finalRes[qItem?.section].push(qItem);
          } else {
            if (qItem?.section !== null) {
              secValid[qItem?.section] = []
              secValid[qItem?.section].push({
                mandatory: false,
                id: qItem?.id,
                feedback: false
              })
              finalRes[qItem?.section] = [qItem];
              setSecDesc((prev) => ([...prev, qItem?.section_desc]))
            } else {
              valid.push({
                mandatory: false
              })
              if ("questions" in finalRes) {
                finalRes["questions"].push(qItem);
              } else {
                finalRes["questions"] = [qItem];
              }
            }
          }
        });
        setValidation(Object.keys(secValid)?.length > 0 ? secValid : valid);
        setCurAssess(finalRes)
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  }, []);

  const QuestionEditor = (question) => {
    let ques = question.split("<p>")[1].split("</p>")[0];
    return ques;
  };

  return (
    <div className="container">
      <div class="row justify-content-center">
        <div class="surveyviewquestions mt-4 mr-3">
          <div class="text-sm-right" style={{
            position: 'sticky',
            top: '100px'
          }}>
            {view === "false" && curAssess?.category_name === "Quiz" && (
              <>
                <h2 className="text-center">Time</h2>
                <h3 id="info2"></h3>
              </>
            )}
          </div>
        </div>
        <div class="col-md-12">
          <div class="content-wrapper-inner pt-0 border">
            <div class="innerheadsec p-0">
              <div className="row">
                {(curAssess?.banner?.url !== "" && curAssess?.imgType === "Banner") ? (
                  <div class="col-md-12">
                    <img src={curAssess?.banner?.url} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="row surveyviewquestions">
                <div class="col-12">
                  <h3 className="policyhead policyheadasssubhead text-center">
                    <div className="row align-item-center">
                      <div className="col-sm-2 text-center text-sm-center">
                        <img src={curAssess?.banner?.url} class="mob-logoass" />
                      </div>
                      <div className={"col-sm-10 survey-title-web"}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: curAssess?.title,
                          }}
                        ></span>
                      </div>
                    </div>
                  </h3>
                  <div
                    className="m-2"
                    dangerouslySetInnerHTML={{ __html: curAssess?.description }}
                  ></div>
                  {curAssess?.user_details === true ? (
                    <>
                      <h3 className="policyhead policyheadasssubhead">
                        Personal Details
                      </h3>
                      <div className="mx-sm-3 mt-sm-3 mx-2 mt-2">
                        <div class="row viewformaftersubmit">
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Company Name</label>
                              <p>{curAssess?.user_entry?.company}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Name of the Person</label>
                              <p>{curAssess?.user_entry?.name}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Designation</label>
                              <p>{curAssess?.user_entry?.designation}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Email</label>
                              <p>{curAssess?.user_entry?.input_email}</p>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group innergroup">
                              <label>Contact No.</label>
                              <p>{curAssess?.user_entry?.contact}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div class="border-top mb-3"></div>
            <div class="p-sm-3 p-2">
              {Object.keys(curAssess)?.length > 0 &&
                Object.keys(curAssess)?.map((qItem, idx) => {
                  return (
                    <div className="quesionBoxbig">
                      {qItem !== "questions" && (
                        <>
                          <h4 class="assess-title">
                            <div dangerouslySetInnerHTML={{
                              __html: qItem
                            }}></div>
                          </h4>

                        </>
                      )}
                      {curAssess[qItem]?.map((each, i) => {
                        if (each?.multiOption) {
                          return (
                            <MultiOptionSurveyView
                              question={each}
                              prevQues={i === 0 ? null : asmntData[qItem][i - 1]}
                              curAssess={curAssess}
                              view={view}
                              index={i}
                              secDesc={secDesc}
                              idx={idx}
                              qItem={qItem}
                              updateLocalResponse=""
                              userInput={userInput}
                              valid={validation}
                            />
                          )
                        } else {
                          return (
                            <>
                              {each?.option_type === "" ? (
                                <div className="row quesionBoxbignewrow">
                                  <div className="col-md-12 d-flex mt-3">
                                    {curAssess?.allowSequence && (`${i + 1}. `)} {each?.is_mandatory && (<span className="text-danger">*</span>)}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: each?.question,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row g-0">
                                  <div class={`col-md-${each?.quesWidth} question-boxpollsurvey`}>
                                    <div className="row">
                                      <div className="col-md-12 d-flex">
                                        {curAssess?.allowSequence && (`${i + 1}. `)} {each?.is_mandatory && (<span className="text-danger">*</span>)}
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: each?.question,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  {each?.option_type === "txt" ? (
                                    each?.is_dropdown ? (
                                      <div class="col-md-4">
                                        <Select
                                          isDisabled={
                                            (curAssess.permission_type === "SV" &&
                                              curAssess?.status === "Submitted") ||
                                            view === "true"
                                          }
                                          defaultValue={
                                            each?.options
                                              ?.filter((x) => {
                                                if (
                                                  x?.option === each?.default_option
                                                ) {
                                                  return true;
                                                }
                                              })
                                              .map((each, index) => {
                                                return {
                                                  label: each?.option,
                                                  value: each?.id,
                                                  key: index,
                                                };
                                              })[0]
                                          }
                                          options={each?.options?.map(
                                            (each, index) => {
                                              return {
                                                label: each?.option,
                                                value: each?.id,
                                                key: index,
                                              };
                                            }
                                          )}
                                          value={
                                            (curAssess.permission_type === "SV" &&
                                              curAssess?.status === "Submitted") ||
                                              view === "true"
                                              ? each?.options
                                                ?.filter((x) => {
                                                  if (
                                                    x?.option ===
                                                    each?.answers[0]
                                                      ?.answer_options
                                                  ) {
                                                    return true;
                                                  }
                                                })
                                                .map((each, index) => {
                                                  return {
                                                    label: each?.option,
                                                    value: each?.id,
                                                    key: index,
                                                  };
                                                })[0]
                                              : each?.options
                                                ?.filter((x) => {
                                                  if (
                                                    x?.option ===
                                                    each?.answers[0]
                                                      ?.answer_options
                                                  ) {
                                                    return true;
                                                  }
                                                })
                                                .map((each, index) => {
                                                  return {
                                                    label: each?.option,
                                                    value: each?.id,
                                                    key: index,
                                                  };
                                                })[0]
                                          }
                                        />
                                      </div>
                                    ) : (
                                      each?.options.map((optItem, iddx) => (
                                        <div
                                          class={
                                            [
                                              "file",
                                              "Rating",
                                              "Short Answer",
                                              "Long Answer"
                                            ].includes(each.type) === false
                                              ? "col-md-6"
                                              : "col-md-12"
                                          }
                                        >
                                          {each.type === "Rating" ? (
                                            <div>
                                              <div class="ratingpoll d-inline-block">
                                                {Array.apply(null, {
                                                  length: optItem.max,
                                                }).map((e, i) => (
                                                  <label class="logCheck d-inline-block">
                                                    {each?.answers.length > 0 ? (
                                                      each?.answers?.map(
                                                        (anitem, anidx) =>
                                                          Number(`${i + 1}`) <=
                                                            Number(
                                                              anitem?.remark_text
                                                            ) ? (
                                                            <input
                                                              checked
                                                              className="checkboxstart"
                                                              ques={each?.id}
                                                              disabled={
                                                                (curAssess.permission_type ===
                                                                  "SV" &&
                                                                  curAssess?.status ===
                                                                  "Submitted") ||
                                                                view === "true"
                                                              }
                                                              type="checkbox"
                                                              value={`${i + 1}`}
                                                              name="surveyanswer1"
                                                            />
                                                          ) : (
                                                            <input
                                                              className="checkboxstart"
                                                              value={`${i + 1}`}
                                                              ques={each?.id}
                                                              disabled={
                                                                (curAssess.permission_type ===
                                                                  "SV" &&
                                                                  curAssess?.status ===
                                                                  "Submitted") ||
                                                                view === "true"
                                                              }
                                                              type="checkbox"
                                                              name="surveyanswer1"
                                                            />
                                                          )
                                                      )
                                                    ) : (
                                                      <input
                                                        value={`${i + 1}`}
                                                        className="checkboxstart"
                                                        type="checkbox"
                                                        name="surveyanswer1"
                                                        ques={each?.id}
                                                      />
                                                    )}

                                                    <span class="checkmark"></span>
                                                  </label>
                                                ))}

                                                <div class="d-flex justify-content-between mt-2">
                                                  <span>
                                                    1<br />
                                                    Poor
                                                  </span>
                                                  <span class="mr-2 pr-1 text-right">
                                                    {optItem.max}
                                                    <br />
                                                    Excellent
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : each.type === "Short Answer" ? (
                                            <div class="answerboxpollsuy innergroup">
                                              <input
                                                disabled={
                                                  (curAssess.permission_type ===
                                                    "SV" &&
                                                    curAssess?.status ===
                                                    "Submitted") ||
                                                  view === "true"
                                                }
                                                type="text"
                                                class="form-control"
                                                placeholder="Please give your short Answer"
                                                minLength={optItem.min}
                                                maxLength={optItem.max}
                                                value={
                                                  each?.answers[0]?.remark_text
                                                }
                                              />

                                              {/* {
                                            each?.answers.length>0?(
                                              each?.answers?.map((anitem,anidx)=>{
                                                
                                                  
                                                <input
                                                  disabled={curAssess.permission_type ==="SV"&& curAssess?.status==="Submitted"}
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Please give your short Answer"
                                                  minLength={optItem.min}
                                                  maxLength={optItem.max}
                                                  // value={anitem?.remark_text}
                                                  
                                                  onChange={(e)=>updateLocalResponse(e,idx,each.id,optItem.id,each.type)} 
                                                />
                                              
                                                
                                                })
                                              ):(<input
                                                
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Please give your short Answer"
                                                  minLength={optItem.min}
                                                  maxLength={optItem.max}
                                                  
                                                  
                                                  onChange={(e)=>updateLocalResponse(e,idx,each.id,optItem.id,each.type)} 
                                                />)
                                          } */}
                                            </div>
                                          ) : each?.type === "Long Answer" ? (
                                            iddx === 0 ? (
                                              <div class="answerboxpollsuy innergroup">
                                                {(curAssess.permission_type ===
                                                  "SV" &&
                                                  curAssess?.status ===
                                                  "Submitted") ||
                                                  view === "true" ? (
                                                  <div
                                                    class="col-md-12"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        each?.answers[0]
                                                          ?.remark_text,
                                                    }}
                                                  ></div>
                                                ) : (
                                                  <CKEditor
                                                    editor={ClassicEditor}
                                                    name="question"
                                                    onReady={(editor) => {
                                                      console.log(
                                                        "Editor is ready to use!",
                                                        editor
                                                      );
                                                    }}
                                                    data={
                                                      each?.answers[0]?.remark_text
                                                    }
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            <div class="answerboxpollsuy innergroup text-center" style={{
                                              background: 'none',
                                            }}>
                                              {/* <span>
                                                  {String.fromCharCode(65 + iddx)}.
                                                </span> */}
                                              {(i === 0 || (asmntData[qItem][i - 1]?.options[iddx]?.option !== optItem?.option)) &&
                                                <div className="text-center">
                                                  {optItem.option}
                                                </div>
                                              }

                                              <label class="logCheck d-inline-block">
                                                {each?.answers?.length > 0 ? (
                                                  each?.answers?.map(
                                                    (anitem, anidx) =>
                                                      optItem.option ===
                                                        anitem?.answer_options ? (
                                                        <input
                                                          disabled={
                                                            (curAssess.permission_type ===
                                                              "SV" &&
                                                              curAssess?.status ===
                                                              "Submitted") ||
                                                            view === "true"
                                                          }
                                                          checked
                                                          value={"demo"}
                                                          type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                          name={
                                                            each.type ===
                                                              "Multiple Choice"
                                                              ? `surveyanswer${each.id}${optItem.id}`
                                                              : `surveyanswer${each.id}`
                                                          }
                                                        />
                                                      ) : (
                                                        <input
                                                          disabled={
                                                            (curAssess.permission_type ===
                                                              "SV" &&
                                                              curAssess?.status ===
                                                              "Submitted") ||
                                                            view === "true"
                                                          }
                                                          value={"demo"}
                                                          type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                          name={
                                                            each.type ===
                                                              "Multiple Choice"
                                                              ? `surveyanswer${each.id}${optItem.id}`
                                                              : `surveyanswer${each.id}`
                                                          }
                                                        />
                                                      )
                                                  )
                                                ) : (
                                                  <input
                                                    value={"demo"}
                                                    disabled={
                                                      (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                        "Submitted") ||
                                                      view === "true"
                                                    }
                                                    type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                    name={
                                                      each.type ===
                                                        "Multiple Choice"
                                                        ? `surveyanswer${each.id}${optItem.id}`
                                                        : `surveyanswer${each.id}`
                                                    }
                                                  />
                                                )}

                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    )
                                  ) : each?.option_type === "file" ? (
                                    each?.options.map((optItem, iddx) => (
                                      // <div class="col-md-12">
                                      //   <div class="answerboxpollsuy innergroup">
                                      //     <span>A.</span>
                                      //     <label class="logCheck d-inline-block">
                                      //       {optItem.option}
                                      //       <input type="radio" name="surveyanswer" />
                                      //       <span class="checkmark"></span>
                                      //     </label>
                                      //   </div>
                                      // </div>

                                      // <div class="col-md-3">
                                      // <label class="logCheck d-inline-block">
                                      //   {/* <div class="mb-1">Lorem Ipsum is simply</div> */}
                                      //   <img src= {optItem.option} class="border" />
                                      //   <input disabled={curAssess.permission_type ==="SV"&& curAssess?.status==="Submitted"} type="radio" name="surveyanswer1" />
                                      //   <span class="checkmark"></span>
                                      // </label>
                                      // </div>

                                      <div class="col-md-3">
                                        <div class="answerboxpollsuy innergroup">
                                          <span>
                                            {String.fromCharCode(65 + iddx)}.
                                          </span>

                                          <label class="logCheck d-inline-block">
                                            <img
                                              src={optItem.option}
                                              class="border"
                                            />

                                            {each?.answers?.length > 0 ? (
                                              each?.answers?.map((anitem, anidx) =>
                                                optItem.option ===
                                                  anitem?.answer_options ? (
                                                  <input
                                                    disabled={
                                                      (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                        "Submitted") ||
                                                      view === "true"
                                                    }
                                                    checked
                                                    value={"demo"}
                                                    type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                    name={
                                                      each.type ===
                                                        "Multiple Choice"
                                                        ? `surveyanswer${each.id}${optItem.id}`
                                                        : `surveyanswer${each.id}`
                                                    }
                                                  />
                                                ) : (
                                                  <input
                                                    disabled={
                                                      (curAssess.permission_type ===
                                                        "SV" &&
                                                        curAssess?.status ===
                                                        "Submitted") ||
                                                      view === "true"
                                                    }
                                                    value={"demo"}
                                                    type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                    name={
                                                      each.type ===
                                                        "Multiple Choice"
                                                        ? `surveyanswer${each.id}${optItem.id}`
                                                        : `surveyanswer${each.id}`
                                                    }
                                                  />
                                                )
                                              )
                                            ) : (
                                              <input
                                                value={"demo"}
                                                disabled={
                                                  (curAssess.permission_type ===
                                                    "SV" &&
                                                    curAssess?.status ===
                                                    "Submitted") ||
                                                  view === "true"
                                                }
                                                type={each?.type === "Multiple Choice" ? "checkbox" : "radio"}
                                                name={
                                                  each.type === "Multiple Choice"
                                                    ? `surveyanswer${each.id}${optItem.id}`
                                                    : `surveyanswer${each.id}`
                                                }
                                              />
                                            )}

                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                              {asmntData[qItem]?.length > 1 &&
                                i !== asmntData[qItem]?.length - 1 && each?.option_type !== "" && <hr />}
                            </>
                          );
                        }
                      })}
                      {Object.keys(curAssess)?.length > 1 &&
                        idx !== Object.keys(curAssess)?.length - 1 && <hr />}
                    </div>
                  );
                })}
            </div>

            <div class="border-top mb-3 mt-4 mt-sm-5"></div>

            <div class="innerheadsec">
              <div class="row">
                <div class="col-md-12 text-center">
                  <Link
                    to={`/survey-approvals/${location?.state?.from}`}
                    state={{
                      from: location?.state?.from,
                      subId: location?.state?.subId,
                    }}
                  >
                    <button class="btn btn-outline-secondary mr-2">
                      <i class="far fa-arrow-alt-circle-left"></i> Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSurveyform;
