import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as OrgService from "../../../service/organisation";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

const validationSchema = Yup.object({
  name: Yup.string().required("*Organization Name is required").nullable(),
  code: Yup.string().required("*Organization Code is required").nullable(),
  sub_domain: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    .required("*Sub-Domain is required")
    .nullable(),
  // title_color: Yup.string().required("Title code is required").nullable(),
  bg_image: Yup.object().required("Title code is required").nullable(),
  // heading_color: Yup.string().required("Heading code is required").nullable(),
  // text_link_color: Yup.string()
  //   .required("Text Link code is required")
  //   .nullable(),
  // menu_background: Yup.string()
  //   .required("Menu Background code is required")
  //   .nullable(),
  // menu_text_color: Yup.string()
  //   .required("Menu Text Color is required")
  //   .nullable(),
});

const AddOrganisation = (props) => {
  const nameForm = "Organization Master";
  const { edit, showForm, onClose, editOrganisation, onAddCallBack, employeeOption } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isOrgExist, setIsOrgExist] = useState(false);
  const [isOrgCodeExist, setIsOrgCodeExist] = useState(null);
  const [isOrgSubDomainExist, setIsOrgSubDomainExist] = useState(null);
  const [imgSize, setImgSize] = useState(false);
  const [img2Size, setImg2Size] = useState(false);
  const [link, setLink] = useState(null);
  const [isLogo, setIsLogo] = useState(false);
  const [beImageLogo, setBeImageLogo] = useState(false);
  const [cpo, setCpo] = useState({});
  const [isCpoEmpty, setIsCpoEmpty] = useState(false);

  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [uploadData, setUploadData] = useState({
    logo: {},
  });
  const [uploadDataIm, setUploadDataIm] = useState({
    bg_image: {},
  });
  const [orgData, setOrgData] = useState({
    id: 0,
    name: "",
    code: "",
    sub_domain: "",
    title_color: "#2E8BC0",
    heading_color: "#2E8BC0",
    text_link_color: "#2E8BC0",
    menu_background: "#2E8BC0",
    menu_text_color: "#2E8BC0",
    application_background_color: "gradientselect",
    logo: {},
    bg_image: {},
    parent_id: 0,
    is_active: true,
  });

  const saveOrganisation = (values) => {
    setIsLoading(true);
    let datass = values;
    datass.logo = uploadData.logo;
    datass.bg_image = uploadDataIm.bg_image;
    OrgService.saveOrganisation(values)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
          setUploadData({ logo: {} });
          setUploadDataIm({ bg_image: {} });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const updateOrganisation = (values) => {
    setIsLoading(true);
    let datass = values;
    datass.logo = uploadData.logo;
    datass.bg_image = uploadDataIm.bg_image;
    OrgService.updateOrganisation(datass, values.id)
      .then((response) => {
        if (response.data.status == true && response.data.code == 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
          setUploadData({ logo: {} });
          setUploadDataIm({ bg_image: {} });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isOrgExists = (e, fieldName) => {
    let data = {};
    data[fieldName] = e.target.value;
    if (editOrganisation?.name == e.target.value) {
      setIsOrgExist(false);
      return;
    } else if (editOrganisation?.code == e.target.value) {
      setIsOrgCodeExist(false);
      return;
    } else if (editOrganisation?.sub_domain == e.target.value) {
      setIsOrgSubDomainExist(false);
      return;
    } else {
      OrgService.isOrgExists(data)
        .then((res) => {
          if (e.target.name == "name") {
            setIsOrgExist(res?.data?.dataList?.is_exist);
          } else if (e.target.name == "sub_domain") {
            setIsOrgSubDomainExist(res?.data?.dataList?.is_exist);
          } else if (e.target.name == "code") {
            setIsOrgCodeExist(res?.data?.dataList?.is_exist);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    if (files.size < 1033415 || files.size === 1033415) {
      if (
        files.type === "image/png" ||
        files.type === "image/svg" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        setImgSize(false);
        setImg2Size(false);
        setLink(undefined);
        const fileData = new FormData();
        fileData.append("uploaded_file", files);
        OrgService.uploadImages(fileData).then((response) => {
          if (flag == "logo") {
            setUploadData({ ...uploadData, logo: response.data });
            setIsLogo(false);
          } else {
            setUploadDataIm({ ...uploadDataIm, bg_image: response.data });
            setBeImageLogo(false);
          }
        });
      } else {
        toast.error("Only jpg, png, jpeg and svg files are supported");
      }
    } else {
      if (flag === "logo") {
        setImgSize(true);
        setIsLogo(false);
      } else {
        setImg2Size(true);
        setBeImageLogo(false);
      }
    }
  };

  const dataBlank = () => {
    onClose();
  };
  // const meraFunc = () => {
  //   if (document.getElementById("logoFile").files.length == 0 && document.getElementById("bg_image").files.length == 0) {
  //     setIsLogo(true)
  //     setBeImageLogo(true)
  //     return;
  //   }
  // }

  useEffect(() => {}, []);
  const meraFunc = () => {
    if (!editOrganisation) {
      if (
        document.getElementById("logoFile").files.length == 0 &&
        document.getElementById("bg_image").files.length == 0
      ) {
        setIsLogo(true);
        setBeImageLogo(true);
      }
    }
  };

  useEffect(() => {
    if (editOrganisation) {
      let cpo_idx = employeeOption.findIndex(obj => obj.id == editOrganisation?.head_id);
      if(cpo_idx > -1) setCpo(employeeOption[cpo_idx]);
      setOrgData(editOrganisation);
      Object.keys(editOrganisation).forEach((item) => {
        if (orgData[item] !== undefined && orgData[item] !== null) {
          orgData[item] = editOrganisation[item];
        }
      });
      orgData["bg_image"] = {};
      orgData["logo"] = {};
    }
  }, [editOrganisation]);

  return (
    <>
      <ToastContainer autoClose={1000} />
      <Formik
        initialValues={orgData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log(values);
          if (
            isOrgExist !== true &&
            isOrgCodeExist !== true &&
            isOrgSubDomainExist !== true &&
            isLogo !== true &&
            beImageLogo !== true
          ) {
            if (values.id) {
              updateOrganisation({...values, head_id: cpo.id});
              AuditLog.addAuditLog(dispatch, nameForm);
            } else {
              saveOrganisation({...values, head_id: cpo.id});
              AuditLog.addAuditLog(dispatch, nameForm);
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Organization
              </Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                <Modal.Body>
                  <div className="row justify-content-center">
                    <div className="col-md-11">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Organization Code
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              name="code"
                              type="text"
                              className="form-control"
                              placeholder="Enter Organization Code"
                              maxLength={10}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isOrgExists(e, "code");
                                handleBlur(e);
                              }}
                              value={values.code}
                            />

                            {touched.code && errors.code ? (
                              <div className="small text-danger">
                                {errors.code}
                              </div>
                            ) : isOrgCodeExist === true ? (
                              <div className="small text-danger">
                                *Organisation Code already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Organization Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Enter Organization Name"
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isOrgExists(e, "name");
                                handleBlur(e);
                              }}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="small text-danger">
                                {errors.name}
                              </div>
                            ) : isOrgExist === true ? (
                              <div className="small text-danger">
                                *Organisation Name already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Upload Logo<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control bg-white"
                              disabled
                              value={
                                edit === true
                                  ? editOrganisation?.logo
                                  : uploadData?.logo?.original_name
                              }
                              placeholder="(Image, PDF Format)"
                            />
                            {isLogo ? (
                              <div className="small text-danger">
                                *Logo is required
                              </div>
                            ) : null}
                            {imgSize ? (
                              <div className="small text-danger">
                                File size should not exceed 1mb
                              </div>
                            ) : null}
                            <div className="upload-btn-wrapper up-loposition">
                              <button type="button" className="uploadBtn">
                                Browse
                              </button>
                              <input
                                type="file"
                                title=""
                                id="logoFile"
                                name="logo"
                                accept="image/png, image/jpeg"
                                onChange={(e) => onProfileChange(e, "logo")}
                              />
                            </div>
                            <p>
                              Selected file
                              <span> {uploadData?.logo?.original_name}</span>
                            </p>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Upload Login Image
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control bg-white"
                              disabled
                              value={
                                edit === true
                                  ? editOrganisation?.bg_image
                                  : uploadDataIm?.bg_image?.original_name
                              }
                              placeholder="(Image, PDF Format)"
                            />
                            {beImageLogo ? (
                              <div className="small text-danger">
                                *Login image is required
                              </div>
                            ) : null}
                            {img2Size ? (
                              <div className="small text-danger">
                                File size should not exceed 1mb
                              </div>
                            ) : null}
                            <div className="upload-btn-wrapper up-loposition">
                              <button className="uploadBtn">Browse</button>
                              <input
                                type="file"
                                title=""
                                name="bg_image"
                                id="bg_image"
                                accept="image/png, image/jpeg"
                                onChange={(e) => onProfileChange(e, "bg_image")}
                              />
                            </div>
                            {touched.bg_image && errors.bg_image ? (
                              <div className="small text-danger">
                                {errors.bg_image}
                              </div>
                            ) : null}
                            {/* <p>
                              Selected file
                              <span>
                                {uploadDataIm?.bg_image?.original_name}
                              </span>
                            </p> */}
                            <p>
                              Selected file{" "}
                              <span>
                                {" "}
                                {uploadDataIm?.bg_image?.original_name}
                              </span>
                            </p>
                            {link != undefined && (
                              <p>Selected file {<span> {link}</span>}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Sub-Domain<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="sub_domain"
                              className="form-control"
                              placeholder="Enter Sub-Domain"
                              maxLength={30}
                              onChange={handleChange}
                              onBlur={(e) => {
                                isOrgExists(e, "sub_domain");
                                handleBlur(e);
                              }}
                              value={values.sub_domain}
                            />
                            {touched.sub_domain && errors.sub_domain ? (
                              <div className="small text-danger">
                                {errors.sub_domain}
                              </div>
                            ) : isOrgSubDomainExist === true ? (
                              <div className="small text-danger">
                                *Sub-Domain already exists
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label htmlFor="title_color">
                              Title Color<span className="text-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              name="title_color"
                              style={{ cursor: "pointer" }}
                              value={values.title_color}
                              placeholder="Enter Color Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.title_color && errors.title_color ? (
                              <div className="small text-danger">
                                {errors.title_color}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label htmlFor="heading_color">
                              Heading Color
                              <span className="text-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              name="heading_color"
                              style={{ cursor: "pointer" }}
                              value={values.heading_color}
                              placeholder="Enter Color Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.heading_color && errors.heading_color ? (
                              <div className="small text-danger">
                                {errors.heading_color}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label htmlFor="text_link_color">
                              Text Link Color
                              <span className="text-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              style={{ cursor: "pointer" }}
                              name="text_link_color"
                              value={values.text_link_color}
                              placeholder="Enter Color Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.text_link_color &&
                            errors.text_link_color ? (
                              <div className="small text-danger">
                                {errors.text_link_color}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label htmlFor="menu_background">
                              Menu Background
                              <span className="text-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              style={{ cursor: "pointer" }}
                              name="menu_background"
                              value={values.menu_background}
                              placeholder="Enter Color Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.menu_background &&
                            errors.menu_background ? (
                              <div className="small text-danger">
                                {errors.menu_background}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label htmlFor="menu_text_color">
                              Menu Text Color
                              <span className="text-danger"></span>
                            </label>
                            <input
                              className="form-control"
                              type="color"
                              style={{ cursor: "pointer" }}
                              name="menu_text_color"
                              value={values.menu_text_color}
                              placeholder="Enter Color Code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.menu_text_color &&
                            errors.menu_text_color ? (
                              <div className="small text-danger">
                                {errors.menu_text_color}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group innergroup position-relative">
                            <label>
                              Gradient Background
                              <span className="text-danger"></span>
                            </label>
                            <br />
                            <div className="img-sectionemper gradientselect mb-3 mb-md-0">
                              <label className="logCheck">
                                <input
                                  type="radio"
                                  id="gradientselect"
                                  value={values.application_background_color}
                                  name="application_background_color"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "application_background_color",
                                      "gradientselect"
                                    );
                                  }}
                                  checked={
                                    values.application_background_color ==
                                    "gradientselect"
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="img-sectionemper gradientselect gradientselect1 mb-3 mb-md-0">
                              <label className="logCheck">
                                <input
                                  type="radio"
                                  id="gradientselect1"
                                  name="application_background_color"
                                  value={values.application_background_color}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "application_background_color",
                                      "gradientselect1"
                                    );
                                  }}
                                  checked={
                                    values.application_background_color ==
                                    "gradientselect1"
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="img-sectionemper gradientselect gradientselect2 mb-3 mb-md-0">
                              <label className="logCheck">
                                <input
                                  type="radio"
                                  id="gradientselect2"
                                  name="application_background_color"
                                  value={values.application_background_color}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "application_background_color",
                                      "gradientselect2"
                                    );
                                  }}
                                  checked={
                                    values.application_background_color ==
                                    "gradientselect2"
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="img-sectionemper gradientselect gradientselect3 mb-3 mb-md-0">
                              <label className="logCheck">
                                <input
                                  type="radio"
                                  id="gradientselect3"
                                  name="application_background_color"
                                  value={values.application_background_color}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "application_background_color",
                                      "gradientselect3"
                                    );
                                  }}
                                  checked={
                                    values.application_background_color ==
                                    "gradientselect3"
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group innergroup">
                            <label>
                              Select CPO
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              options={employeeOption}
                              classNamePrefix="select"
                              name="select_cpo"
                              onChange={obj => {setCpo(obj); setIsCpoEmpty(false)}}
                              value={cpo}
                            />
                            {isCpoEmpty ? (
                              <div className="small text-danger">
                                {"*Please select CPO"}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group innergroup">
                            <label className="d-block">Status</label>
                            <label
                              className="logCheck d-inline-block mr-4"
                              htmlFor="active"
                            >
                              Active
                              <input
                                type="radio"
                                name="is_active"
                                id="active"
                                value={values.is_active}
                                onChange={(e) => {
                                  setFieldValue("is_active", true);
                                }}
                                checked={values.is_active === true}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block"
                              htmlFor="Inactive"
                            >
                              Inactive
                              <input
                                type="radio"
                                name="is_active"
                                id="Inactive"
                                value={values.is_active}
                                onChange={(e) => {
                                  setFieldValue("is_active", false);
                                }}
                                checked={values.is_active === false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          dataBlank();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => meraFunc()}
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddOrganisation;
