import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getPendingPoDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/pending_po_details?${queryParm}`
  );
};
export const getApprovedPoDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/approved_po_list?${queryParm}`
  );
};

export const getDeclinedPoDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/purchase-order/declined_po_details?${queryParm}`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/purchase-order/pending_po_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getExportDeclined = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/purchase-order/declined_po_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getExportApproved = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/purchase-order/export_approved_po?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const sendEmail = async (email, type, user_name, po_no, po_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/send_reminder?email=${email}&type=${type}&user_name=${user_name}&po_no=${po_no}&category_type=PO&po_id=${po_id}`
  );
};
export const sendEmailCA = async (email, type, user_name, po_no, po_id) => {
  return axios.post(
    `${AUTH_BASE_URL}/purchase-order/send_reminder?email=${email}&type=${type}&user_name=${user_name}&po_no=${po_no}&category_type=CONTRACT&po_id=${po_id}`
  );
};

export const getVendorId = async (search) => {
  return axios.get(
    `${AUTH_BASE_URL}/vendor-code/drop-down/undefined?search=${search}`
  );
};
