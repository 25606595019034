import React, { useEffect, useState } from "react";
import Select from "react-select";

function MaterialRecord({
  edit,
  view,
  recordArr,
  removeRecord,
  materialOpt,
  handleChange,
  handleBlur,
}) {
  const [updatedItems, setUpdatedItems] = useState([]);
  useEffect(() => {
    if (edit === false) {
      setUpdatedItems([]);
    } else {
      let items = recordArr.map((obj) => {
        if (obj.id !== undefined) {
          return {
            ...obj,
            count: obj.item_no + 1,
            materialName: {
              label: obj.material__code,
              value: obj.material_id,
            },
          };
        } else {
          return {
            ...obj,
            count: obj.item_no + 1,
          };
        }
      });
      setUpdatedItems(items);
    }
  }, [edit, recordArr]);

  if (recordArr.length > 0) {
    return (edit === true ? updatedItems : recordArr).map((obj) => {
      const { count, materialName, item_no, quantity, description } = obj;
      return (
        <tr key={count} className="innergroup">
          <td>{item_no}</td>
          <td
            style={{
              overflow: "visible",
            }}
          >
            <Select
              options={materialOpt}
              value={materialName}
              isDisabled={view}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Press enter to Search"
            />
          </td>
          <td>
            <input
              type="text"
              name="quantity"
              placeholder="Enter Quantity"
              className="form-control"
              value={quantity}
              maxLength={4}
              disabled={view}
              onChange={(event) => {
                handleChange(event, undefined, count);
              }}
            />{" "}
          </td>
          <td>
            <input
              type="text"
              name="desc"
              placeholder="Please Enter Description"
              value={description}
              disabled={view}
              className="form-control"
              onChange={(event) => {
                handleChange(event, undefined, count);
              }}
            />{" "}
          </td>
          {!view && (
            <td>
              <button
                className="btn btn-blanktd text-danger"
                onClick={() => removeRecord(count)}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </td>
          )}
        </tr>
      );
    });
  }
}

export default MaterialRecord;
