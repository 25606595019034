import { useEffect, useState } from "react";
import { Modal, Breadcrumb, Dropdown, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AddIcon from "../../../../assets/images/plus.png"
import CloseIcon from "../../../../assets/images/close.png"
import ModularTable from "../../../../elements/ModularTable";
import {
  getTerritoryCust,
  getTerritoryEmp,
  getETList,
  getTerritoryList,
  postETCreation,
  getReqListById,
  postETUpdate,
  getMonthYear
} from "../../../../service/AgriPortalService/ETReview";

import styles from "./Table.module.css";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT,getRegionDropDown } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";
import { param } from "jquery";
import { dialogTitleClasses, paginationItemClasses } from "@mui/material";
const Root = (props) => {
  //HOOKS
 
  const [requestID, setRequestID] = useState();
  const [digitalRemark, setDigitalRemark]= useState();
  const [agilityRemark, setAgilityRemark] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [marketSize, setMarketSizeData] = useState({});
  const [ZRT, setZRT] = useState([]);
  const [aGSalesList, setAGSalesList] = useState([]);
  const [mode, setMode] = useState("");
  const [excelListImport, setExcelListImport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [etList, setEtList] = useState([]);
  const [freezeCTA, setFreezeCTA] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clfy, setClfy] = useState();
  const [lfy, setLfy] = useState();
  const [month, setMonth] = useState("Mar");
  const [monthN, setMonthN] = useState("");
  const [cfy, setCfy] = useState("");
  const [reqid, setreqId] = useState("");
  const [flag, setFlag] = useState(true);
  const [rcode, setRcode] = useState();
  const [tflag, setTflag] = useState(true);
  const [terList, setTerList] = useState([])
  const [etrList, setEtrList] = useState([])
  const [custList, setCustList] = useState([])
  const [empList, setEmpList] = useState([])
  const [selectedT,setSelectedT] = useState();
  const [curYear, setCurYear] = useState()
  const [curmonth , setCurMonth] = useState()
  const [etstatus, setEtstatus] = useState();
  const [editable, setEditable] = useState();

  

  useEffect(() => {
    getMonthYear()
    .then((res) => {
     
      let dupstr = res.data[0];
      setCurMonth(dupstr.slice(0,3))
      setCurYear(res.data[1]);
    }
    ).catch((err) => {
      toast.error("No data found!");
      console.log(err)
    })
  }, [])

 

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    if( params.get("mode") != undefined && params.get("mode") == "create"){
    getRegionDropDown()
    .then((res) => {
      setRcode(res.data.dataList[0].id)
     
      let query = "";
      query = "?region=" + res.data.dataList[0].id
      getTerritoryList(query)
      .then((re) => {
       
        setTerList(re.data)
      })
      .catch((err) => {
        console.log("region error", err);
      });
    
    }

    ).catch((err) => console.log(err));
  }
  }, []);


  // show zone territory and region
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setRequestID(parseInt(params.get("id")))
    setDigitalRemark(params.get("digital_remark"))
    setAgilityRemark(params.get("agility_remark"))
   
    setEtstatus(params.get("status"))
    setEditable(params.get("editable"))
    if (params.get("mode") != "create") {

      const params_region = {
        zone_name: params.get("zone_name"),
        territory_name: params.get("territory_name"),
        region_name: params.get("region_name"),
        zone_code: params.get("zone_code"),
        region_code: params.get("region_code"),
        territory_code: params.get("territory_code")
      }
    
      setZRT(params_region)
    }
 
    if ((params.get("mode") == "edit" || params.get("mode") == "view") && params.get("editable")) {
 
      getetlistidfun();
    }

    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
         
          setZRT(res?.data?.dataList);

        })
        .catch((err) => {
          console.log(err);
        });
    }



  }, [mode]);


  const addRow = ()=>{
              if(mode=="create"){
                    
                const dupRow = [...etrList]
               
                const duprow = {et_id: "", cust_id:"", city_name:""}
                const updatedInvoiceData = [
                  ...etrList, duprow,
                ];
                setEtrList(updatedInvoiceData);
               
              }else if( mode == "edit"){
                const dupRow = [...etrList]
             
                const duprow = {et_id: "", cust_id:"", city_name:"", is_active:"True"}
                const updatedInvoiceData = [
                  ...etrList, duprow,
                ];
                setEtrList(updatedInvoiceData); 
             
              }
              }

              const searchETList = () => {
                 if(selectedT != undefined && selectedT != "select territory"){
                setTflag(false)
                let query = "";
                let querylist = "";
                query ="?territory_id=" + selectedT
               
               
                getETList(query)
                  .then((response) => {
                    if(response.data[0].message !=undefined && response.data[0].message.length != undefined && response.data[0].message.length !=0){
                      setEtrList([])
                      toast.info("window for current month is closed");
                      setTimeout(() => {
                        navigate("/etreview/root");
                      }, 1000);
                    }else{
                 
                    setEtrList(response.data)}
                  })
                  .catch((error) => {
                
                    console.log(
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      "failed"
                    );
                  });
            
                  getTerritoryCust(query)
                  .then((response) => {
                   
                    let alist = response.data
                  const blist =   alist.sort((a, b) => {
                      let fa = a.cust_name.toLowerCase(),
                          fb = b.cust_name.toLowerCase();
                  
                      if (fa < fb) {
                          return -1;
                      }
                      if (fa > fb) {
                          return 1;
                      }
                      return 0;
                  });

                    setCustList(blist)
                
                  })
                  .catch((error) => {
                
                    console.log(
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      "failed"
                    );
                  })
                  getTerritoryEmp(query)
                  .then((response) => {
                    
                    setEmpList(response.data)
                  })
                  .catch((error) => {
                   
                    console.log(
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      "failed"
                    );
                  })
                }else {
                  toast.error("Please select valid Territory");
                }
              }

              const PendingETList = () => {

                getTerritoryList()
                  .then((response) => {
                    
                    setEtrList(response.data)
                  })
                  .catch((error) => {
                
                    console.log(
                      (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                      "failed"
                    );
                  });
              }
              

  const deleteRow = (index) => {
    if(mode != undefined && mode == "create"){
      const tableRowData = [...etrList]
       const tablead = tableRowData.splice(index, 1)
      setEtrList(tableRowData);

    }else if(mode == "edit"){
      setEtrList(etrList.map((elem,i)=>{
        if(index ==i){
            elem.is_active ="False";  
              return elem;
            }else{
              return elem;
            }
          }))
    
          let query = "";
           query = "?status=" + etstatus + "&request_id=" + requestID + "&digital_remark=" + digitalRemark + "&agility_remark=" + agilityRemark
        
              postETUpdate(query,etrList)
             .then((res) => {
               toast.success("delete succesfull!");
               setTimeout(() => {
               }, 1000);
             })
             .catch((err) => {
               toast.error("delete failed!");
               console.log("error", err)
               setTimeout(() => {
               }, 1000);
             });  

             const tableRowData = [...etrList]
             const tablead = tableRowData.splice(index, 1)
            setEtrList(tableRowData);
    }
   
  //  setTableRowReRender(!tableRowReRender);
  };
 
  const dropdownempHandler = (empid, index) => {
    if (empid != "") {
      const emp_row = empList.find((elem) => elem.et_id == empid)
      setEtrList(etrList.map((elem, i) => {
        if (index == i) {
            elem["et_id"] = emp_row.et_id;
            

          return elem;
        } else {
          return elem;
        }
      }))

    }else{
      setEtrList(etrList.map((elem, i) => {
        if (index == i) {
            elem["et_id"] = "";

          return elem;
        } else {
          return elem;
        }
      }))
    }

  }

  const dropdowncustHandler = (custid, index) => {
   
    if (custid != "") {
      const cust_row = custList.find((elem) => elem.cust_id == custid)
      setEtrList(etrList.map((elem, i) => {
        if (index == i) {
            elem["city_name"] = cust_row.city_name;
            elem["cust_id"] = cust_row.cust_id;

          return elem;
        } else {
          return elem;
        }
      }))
 

    }else{
      setEtrList(etrList.map((elem, i) => {
        if (index == i) {
            elem["cust_id"] = "";
            elem["city_name"] = "";

          return elem;
        } else {
          return elem;
        }
      }))
    }



  }

  const TerritoryChangeHandler = (code) =>{
    setSelectedT(code)
  }
  const getetlistidfun = () => {
    const params = new URLSearchParams(window.location.search);
    let queryp;
    let query;
    queryp = "?request_id=" + params.get("id")

    query ="?territory_id=" + params.get("territory_id")

    

    getReqListById(queryp)
      .then((res) => {
        setLoader(false);
        
        setEtrList(res.data);
        if (res.status == 200) {
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });


          getTerritoryCust(query)
          .then((response) => {
            let alist = response.data
            
          const blist =   alist.sort((a, b) => {
              let fa = a.cust_name.toLowerCase(),
                  fb = b.cust_name.toLowerCase();
          
              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
              return 0;
          });

            setCustList(blist)
        
          })
          .catch((error) => {
           
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          })
          getTerritoryEmp(query)
          .then((response) => {
            
            setEmpList(response.data)
          })
          .catch((error) => {
          
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          })

      


  }


  

  


 // On Validation 
 const onValidation = (type)=>{
  if(etrList.length !=0){
  
    let flag_partial = false;
    let dupRow = etrList;

    dupRow.map((el,i)=>{
        if(el.et_id == "" || el.et_id == "None") { 
          flag_partial = true;
       }
       else if (el.cust_name == "" || el.cust_id == "None") {   
          flag_partial = true;
      }
      else if (el.city_name == "" || el.et_id == "None") {
          flag_partial = true;
       }
 
       if(digitalRemark == null || digitalRemark == ""){
              flag_partial = true;
       }
       if(agilityRemark == null || agilityRemark == ""){
               flag_partial = true
       }
    })

      if(flag_partial){
        if(type == "S"){
          if (freezeCTA) return;
        
        
            confirmAlert({
              title: "Validation failure?",
              message: `Please fill all required data.`
                  ,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    setFreezeCTA(false);
                  },
                },
              ],
              onClickOutside: () => {
                setFreezeCTA(false);
              },
              onKeypressEscape: () => {
                setFreezeCTA(false);
              },
            });
          
        }else{
          checkDup(type)
          
        }
      }else{
        checkDup(type)
        
      }
  }else{
    toast.error("No Data added");
  }
  
}



  //EVENT HANDLERS
  const onSaveHandler = (event) => {
   
    if (etrList.length != 0 || etrList != undefined) {
      setLoader(true);
      let queryParm
      if (freezeCTA) return;

      if (mode == "create") {
        
           
        if(digitalRemark == null && agilityRemark == null){
          queryParm = "?status=" + "D" + "&territory_id=" + selectedT + "&digital_remark=" + "" + "&agility_remark=" + ""
       
        }else if(digitalRemark == null && agilityRemark != null){
          queryParm = "?status=" + "D" + "&territory_id=" + selectedT + "&digital_remark=" + "" + "&agility_remark=" + agilityRemark
         
        }else if(digitalRemark != null && agilityRemark == null){
          queryParm = "?status=" + "D" + "&territory_id=" + selectedT + "&digital_remark=" + digitalRemark + "&agility_remark=" + ""
          
        }else{
          queryParm = "?status=" + "D" + "&territory_id=" + selectedT + "&digital_remark=" + digitalRemark + "&agility_remark=" + agilityRemark
         
        }
       
       
        postETCreation(queryParm, etrList)
          .then((response) => {
         
            if(response.data[0].message !=undefined && response.data[0].message.length != undefined && response.data[0].message.length !=0 && response.data[0].message == "window for current month is closed"){
            
              setLoader(false)
              toast.info("window for current month is closed");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
            }else{
              setLoader(false);
           
              toast.success("Save succesfull!");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
          }
           
          })
          .catch((err) => {
           
            setLoader(false);
            toast.error("Save failed!");
            setTimeout(() => {
              navigate("/etreview/root");
            }, 1000);
          });
      }
      if (mode == "edit") {
        if(digitalRemark == null && agilityRemark == null){
          queryParm =  "?request_id=" + requestID + "&status=" + "D"  + "&digital_remark=" + "" + "&agility_remark=" + ""
         
        }else if(digitalRemark == null && agilityRemark != null){
          queryParm =  "?request_id=" + requestID + "&status=" + "D"  + "&digital_remark=" + "" + "&agility_remark=" + agilityRemark
         
        }else if(digitalRemark != null && agilityRemark == null){
          queryParm =  "?request_id=" + requestID + "&status=" + "D"  + "&digital_remark=" + digitalRemark + "&agility_remark=" + ""
         
        }else{
          queryParm =  "?request_id=" + requestID + "&status=" + "D"  + "&digital_remark=" + digitalRemark + "&agility_remark=" + agilityRemark
         
        }
        
        
        postETUpdate(queryParm, etrList)
          .then((response) => {
            if(response.data[0].message !=undefined && response.data[0].message.length != undefined && response.data[0].message.length !=0 && response.data[0].message == "window for current month is closed"){
            
              setLoader(false)
              toast.info("window for current month is closed");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
            }else{
              setLoader(false);
           
              toast.success("Save succesfull!");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
          }
          })
          .catch((err) => {
            setLoader(false);
        
            toast.error("Save failed!");
            setTimeout(() => {
              navigate("/etreview/root");
            }, 1000);
          });
      }
    } else {
      toast.success("No Data Available!");
    }
  };

  const confirmDialog = (type) => {
  
    if (freezeCTA) return;
    confirmAlert({
      title: type == "S" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "S"
          ? `On Submission, this Mapping will be visible to ZM, TM, ET Please confirm.`
          : `This is a draft and not be visible to ZM, TM and ET You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "S" ?  onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const checkDup = (type) => {
   
        let ar = [];
        let flag = false;
    for (let i = 0; i < etrList.length; i++) {
      if(etrList[i].et_id == "None" || etrList[i].et_id== "" ){
           continue;
      }else{
        ar.push(etrList[i].et_id + "-" + etrList[i].cust_id)
      }
         
    }
   
    if(ar.length == 1 ){
      confirmDialog(type);
    }else if(ar.length > 1){
     
        for (let i = 0; i < ar.length - 1; i++) {
           for (let j = i + 1; j < ar.length; j++) {
           if (ar[i] === ar[j]) {
               flag = true
              }
           }
        }
     
    }

    if(flag){
    
      if (freezeCTA) return;
    
    
        confirmAlert({
          title: "ET Customer Mapping Failure!",
          message: `Please map ET to Customer correctly because system has found duplicate entries for mapping with same ET with same Customer.`
              ,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                setFreezeCTA(false);
              },
            },
          ],
          onClickOutside: () => {
            setFreezeCTA(false);
          },
          onKeypressEscape: () => {
            setFreezeCTA(false);
          },
        });
      
    }else{
      confirmDialog(type);
     
    }
  
  }
  const onSubmitHandler = () => {
    if (etrList.length != 0 && etrList != undefined) {
      if(digitalRemark.length !=undefined && agilityRemark.length != undefined && digitalRemark.length !=0 && agilityRemark.length !=0){
      setLoader(true);
      if (freezeCTA) return;
      let queryParm;
         
      if (mode == "create") {
        if(digitalRemark == null && agilityRemark == null){
          queryParm = "?status=" + "S" + "&territory_id=" + selectedT + "&digital_remark=" + "" + "&agility_remark=" + ""
        
        }else if(digitalRemark == null && agilityRemark != null){
          queryParm = "?status=" + "S" + "&territory_id=" + selectedT + "&digital_remark=" + "" + "&agility_remark=" + agilityRemark
         
        }else if(digitalRemark != null && agilityRemark == null){
          queryParm = "?status=" + "S" + "&territory_id=" + selectedT + "&digital_remark=" + digitalRemark + "&agility_remark=" + ""
        
        }else{
          queryParm = "?status=" + "S" + "&territory_id=" + selectedT + "&digital_remark=" + digitalRemark + "&agility_remark=" + agilityRemark
       
        }  
       
        if(etrList.length > 0){
            postETCreation(queryParm, etrList)
            .then((response) => {
              if(response.data[0].message !=undefined && response.data[0].message.length != undefined && response.data[0].message.length !=0 && response.data[0].message == "window for current month is closed"){
            
                setLoader(false)
                toast.info("window for current month is closed");
                setTimeout(() => {
                  navigate("/etreview/root");
                }, 1000);
              }else{
                setLoader(false)
               
                toast.success("Submit succesfull!");
                setTimeout(() => {
                  navigate("/etreview/root");
                }, 1000);
            }

            })
            .catch((err) => {
              
              setLoader(false);
              toast.error("Submit failed!");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
            });
          }else{
            toast.info("Please add data!");
          }
       
      }
      if (mode == "edit") {
        if(digitalRemark == null && agilityRemark == null){
          queryParm =  "?request_id=" + requestID + "&status=" + "S"  + "&digital_remark=" + "" + "&agility_remark=" + ""
         
        }else if(digitalRemark == null && agilityRemark != null){
          queryParm =  "?request_id=" + requestID + "&status=" + "S"  + "&digital_remark=" + "" + "&agility_remark=" + agilityRemark
         
        }else if(digitalRemark != null && agilityRemark == null){
          queryParm =  "?request_id=" + requestID + "&status=" + "S"  + "&digital_remark=" + digitalRemark + "&agility_remark=" + ""
         
        }else{
          queryParm =  "?request_id=" + requestID + "&status=" + "S"  + "&digital_remark=" + digitalRemark + "&agility_remark=" + agilityRemark
         
        }
       
        postETUpdate(queryParm, etrList)
          .then((response) => {
            if(response.data[0].message !=undefined && response.data[0].message.length != undefined && response.data[0].message.length !=0 && response.data[0].message == "window for current month is closed"){
            
              setLoader(false)
              toast.info("window for current month is closed");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
            }else{
              setLoader(false)
           
              toast.success("Submit succesfull!");
              setTimeout(() => {
                navigate("/etreview/root");
              }, 1000);
          }
 
          })
          .catch((err) => {
            setLoader(false);
         
            toast.error("Submit failed!");
            setTimeout(() => {
              navigate("/etreview/root");
            }, 1000);
          });
      }
    }else{
      toast.info("Please fill Digital or Agility Remark");
    }
    } else {
      toast.info("No Data Available!");
    }
  };

  const onCancelHandler = () => {
    return navigate("/etreview/root");
  };

 

  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();
  
  //JSX MARKUP
  return (
    <>
      <div className={styles["agri-portal-container"]}>
        <ToastContainer autoClose={1000} />
        <div className={styles["agri-portal-content"]}>
          <div
            className={styles["heading"]}
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <h4 class="inner-page-title" style={{ margin: "0" }}>
            ET To Customer Mapping
            </h4>
          
            <div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>For The Month-Year</b>
                </div>
                <div className={styles["valuebox"]}>{ curmonth + "-" + curYear }</div>
              </div>
            </div>
          </div>
          <div className="col-md-12 my-2">
            <div className="border-top md-3"> </div>
          </div>
          <div
            className={styles["heading"]}
            style={{
              paddingTop: "0",
              marginTop: "10px",
              paddingBottom: "15px",
              // justifyContent: "start",
            }}
          >
            <div
              className={styles["agri-portal-label"]}
              style={{ display: "flex" }}
            >
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>ZONE</b>
                </div>
                <div className={styles["valuebox"]}>{ZRT?.zone_name + "(" + ZRT?.zone_code + ")"}</div>
              </div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>REGION</b>
                </div>
                <div className={styles["valuebox"]}>
                  {ZRT?.region_name + "(" + ZRT?.region_code + ")"}
                </div>
              </div>
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>TERRITORY</b>
                </div>
                { mode == "create" ?(
                      <div className={styles["valueboxone"]}>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <select
                          className="form-control newbgselect"
                          name="business_unit_id"
                          
                          style={
                            {
                            opacity : tflag == false ? 0.5 :1,
                            cursor: "pointer",
                            border: "1px solid #0195D4",
                            color: "#0195DA",
                            minWidth: "120px",
                          }}

                          disabled={!tflag}
    
                         value={selectedT}
                          classNamePrefix="select"
                          onChange={(e) => {
    
                            TerritoryChangeHandler(e.target.value);
                          }}
                        >
                  
                          {mode == "create" ?
                            <option value={"select territory"} label={"Territory Name"} /> :
                            ""
                          }
                          {mode == "create" && terList.length >= 0 &&
                            terList?.map((el, index) => {
                              return <option key={index} value={el.id} label={el.name + "(" + el.code + ")"} />;
                            })}
    
                          {mode !== "create" && terList.length >= 0 &&
                            terList?.map((el, index) => {
                              return <option key={index} value={el.id} label={el.name + "(" + el.code+ ")"} />;
                            })}
    
                        </select>
    
    
                      </div>
                    </div> ):(  <div className={styles["valuebox"]}>
                  {ZRT?.territory_name + "(" + ZRT?.territory_code + ")"} 
                </div>)
                }
              </div>
              <div className={styles["valueboxone"]}>
                  <div style={{ display: "flex", gap: "10px" , marginLeft:"80px" }}>
                    {
                      mode == "create" && mode != undefined ? (
                        <button type="button" class="btn btn-success btn-sm" onClick={searchETList}>Search</button>
                      ) : (
                        ""
                      )
                    }

                  </div>
                </div>


        
            

            </div>
          </div>

          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div>
                <div class="table-responsive" className={styles["table_css"]} style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }} >
                  <table class="table table-striped table-bordered tablecured ">
                    <thead>
                      <tr style={{ minWidth: "150px" }}>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>Employee Name</th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}> Customer Name  </th>
                        <th className={styles["agri-portal-table"]} style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "2",
                          textOverflow: "clip",
                          whiteSpace: "normal",
                          textAlign: "center"
                        }}>Customer City</th>
                          {
                            mode == "view" || mode == "approve" ?
                                <></>
                                :
                                (
                                    <th classname={styles["agri-portal-table"]} style={{ position: "sticky", top: "0", zIndex: "2", backgroundColor: "#FFF", cursor: "pointer", width: "30px", height: 50, minWidth: "30px" }}
                                        onClick={() => { addRow() }}
                                    >
                                        <img src={AddIcon} alt="add" style={{ width: 20, height: 15, visibility: "visible", objectFit: "cover" }}
                                        />
                                    </th>
                                )
                        }
                      </tr>
                    </thead>
                  
                    <tbody>


                      {((mode != undefined && mode == "view")) &&
                        (etrList != undefined && (etrList.map((el, i) => {
                          return <tr>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.et_first_name + " " +el.et_last_name +"(" + el.et_id_username + ")"}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>{el.cust_name  +"("+ el.cust_code +")"}  </td>
                            <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal", textAlign: "right" }}>{el.city_name}  </td>
                           
                          </tr>
                        })))}
              
                      {etrList.length ? (
                        (mode != undefined) && (mode == "create" || mode == "edit") &&
                        (etrList != undefined && (etrList.map((el, i) => {
                          return <tr>
                            <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "160px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                value={el.et_id}
                                //  label= {el.transporter_id__name}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  dropdownempHandler(e.target.value, i)
                                }} >
                                <option value="" >
                                  ET Name
                                </option>


                                {empList != undefined && empList.length >= 0 &&
                                  empList.map((el, Index) => {

                                    return <option key={Index} value={el.et_id}>
                                      {el.et_first_name + " " +el.et_last_name +"(" +el.et_id_username + ")"}
                                    </option>

                                  })}
                              
                              </select></td>
                              <td className={styles["transport-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "160px", textOverflow: "clip", whiteSpace: "normal" }}>
                              <select
                                className="form-control newbgselect"
                                name="business_unit_id"
                                isSearchable true
                                value={el.cust_id}
                                //  label= {el.transporter_id__name}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  dropdowncustHandler(e.target.value, i)
                                }} >
                                <option value="" >
                                  Customer Name
                                </option>


                                {custList != undefined && custList.length >= 0 &&
                                  custList.map((el, Index) => {

                                    return <option key={Index} value={el.cust_id}>
                                      {el.cust_name +"("+ el.cust_code +")"}
                                    </option>

                                  })}
                              
                              </select></td>
                       
                               <td className={styles["agri-portal-table"]} style={{ textAlign: "left", textOverflow: "clip", whiteSpace: "normal" }}>{el.city_name}  </td>
                           
                            
                            {
                                    (mode == "view" || mode == "approve") ?
                                        null
                                        :
                                        (
                                            <td classname={styles["agri-portal-table"]} style={{ backgroundColor: "#FFF", cursor: "pointer", textOverflow: "clip", whiteSpace: "normal" }}
                                                onClick={() => { deleteRow(i) }}
                                            >
                                                <img src={CloseIcon} alt="" style={{ width: 25, height: 15, objectFit: "cover" }}
                                                />
                                            </td>
                                        )
                                }


                          </tr>
                        })))) : (
                        <tr>
                          <td colSpan={17}> No Record Found</td>
                        </tr>)}
                    </tbody>

                  </table>
                </div>
              </div>

            </div>
          </div>

       


          <div className={styles["ctas"]}>
            {/* {mode != "view" && mode != "approve" && (
              <div>
              <div className={styles["ctas_left"]} style={{display:"flex"}}>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Digital Usage <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    ></textarea>
                  </div>
                </div>
                 
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Agility to Learn and Development <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      onChange={(e) => setRemark(e.target.value)}
                      value={remark}
                    ></textarea>
                  </div>
                </div>
                </div>
                <div
                  class="btn btn-success"
                  onClick={() => {
                    confirmDialog("P");
                  }}
                >
                  Submit
                </div>
                <div
                  class="btn btn-primary-inner"
                  onClick={() => {
                    confirmDialog("D");
                  }}
                >
                  Save
                </div>
              </div>
            )} */}
            {/* mode == "approve" || approvalList?.for_approval */}
            {mode == "create" || mode == "edit"? (
              <div>
                <div style={{display:"flex"}}>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Digital Usage <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      maxlength="100"
                      onChange={(e) => setDigitalRemark(e.target.value)}
                      value={digitalRemark}
                    ></textarea>
                  </div>
                </div>
               
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Agility to Learn and Development <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      maxlength="100"
                      onChange={(e) => setAgilityRemark(e.target.value)}
                      value={agilityRemark}
                    ></textarea>
                  </div>
                </div>
                </div>
                <div className="col-md-12 text-right pb-3">
                  { (etstatus !=undefined && etstatus == "D" || mode == "create") &&
                <div style={{marginRight:"40px"}}
                  class="btn btn-primary-inner"
                  onClick={() => {
                    onValidation("D");
                   
                  }}
                >
                  Save
                </div>
                  } 
                  <div style={{marginRight:"40px"}}
                  class="btn btn-success"
                  onClick={() => {
                    onValidation("S");
                    
                  }}
                >
                  Submit
                </div>
                <div style={{marginRight:"60px"}} class="btn btn-danger" onClick={onCancelHandler}>
                  Cancel
                </div>
                </div>
              </div>
            ) : (
              <div>
                <div style={{display:"flex"}}>
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Digital Usage <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      value={digitalRemark}
                    ></textarea>
                  </div>
                </div>
               
                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                    Agility to Learn and Development <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      value={agilityRemark}
                    ></textarea>
                  </div>
                </div>
                </div>
                <div className="col-md-12 text-right pb-3">
            
                <div style={{marginRight:"60px"}} class="btn btn-danger" onClick={onCancelHandler}>
                  Cancel
                </div>
                </div>
              </div>  
            )}
          </div>
        </div>
      </div>

 
    </>
  );
};
export default Root;

