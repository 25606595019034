import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const setAttendanceMain = async (allData) => {
  return axios.post(`${AUTH_BASE_URL}/attendance/get_my_attendance`, allData);
};

export const setSearchData = async (search) => {
  return axios.get(`${AUTH_BASE_URL}/search-globally?search=${search}`);
};

export const saveDarkModeData = async (dark_mode) => {
  return axios.post(`${AUTH_BASE_URL}/users/dark_mode?dark_mode=${dark_mode}`);
};

export const saveReportIssue = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/report-issue`, data);
};

export const uploadImages = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const getUserDetails = async (user_id) => {
  return axios.get(`${AUTH_BASE_URL}/users/get-user-data?user_id=${user_id}`);
};

export const getNotification = async (sort_by, paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/notification?sort_by=${sort_by}&paginate=${paginate}`
  );
};

export const getSeenData = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/notification/${id}`);
};

export const getMarkReadAll = async () => {
  return axios.get(`${AUTH_BASE_URL}/notification/seen`);
};
export const getPermissionUrlData = async (module_end_point) => {
  return axios.get(
    `${AUTH_BASE_URL}/permissions/get-my-url-permission?module_end_point=${module_end_point}`
  );
};

export const saveActivityTracker = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/my-team-activity/activity_tracker`, data);
};

export const getFullMonthAttendance = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/attendance/full_month_attendance?${queryParm}`
  );
};

export const getUserDelegates = async (id, from) => {
  return axios.get(`${AUTH_BASE_URL}/auth/get_delegates/${id}/${from}`);
};
