import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const displaySkillPlan = async () => {
  return axios.post(`${AUTH_BASE_URL}/skill-plan-master/display`);
};

export const updateReviewSkill = async (data) => {
  return axios.put(`${AUTH_BASE_URL}/skill-plan-master`, data);
};

export const getAllSkills = async (paginate, planId) => {
  return axios.get(`${AUTH_BASE_URL}/skill-master?paginate=${paginate}`);
};

export const getAllSkillsByCreator = async (paginate, planId) => {
  return axios.get(`${AUTH_BASE_URL}/skill-plan-master/skill-master?paginate=${paginate}`);
};

export const getSKillPlan = async (sort_by, planId, type) => {
  console.log("TYPE", type)
  return axios.get(
    `${AUTH_BASE_URL}/skill-plan-master?sort_by=${sort_by}&plan_id=${planId}&p_type=${type}`
  );
};

export const userAccess = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/skill-plan-master/check-access`,
    data
  );
};

export const saveCreateSkill = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-plan-master`, data);
};

export const deleteCreate = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-plan-master/${id}`);
};

// export const getSkillStatusData = async (sort_by) => {
//   return axios.get(
//     `${AUTH_BASE_URL}/skill-plan-master/status?sort_by=${sort_by}`
//   );
// };

export const getSkillStatusDatas = async (sort_by, planId, type) => {
  return axios.get(
    `${AUTH_BASE_URL}/skill-plan-master/status?sort_by=${sort_by}&plan=${planId}&p_type=${type}`
  );
};

export const getUserSkill = async () => {
  return axios.get(
    `${AUTH_BASE_URL}/skill-plan-master/user_assigned_skill_status`
  );
};

export const getSkillById = async (skill_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/skill-master?search=${skill_id}`
  );
};

export const isDataExists = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-plan-master/exists`, data);
};
