import React, {useState, useEffect } from "react";
import * as PolicyService from "../../service/policy"

export default function BalanceSheet(){
    const [Errorattachment, setErrorattachment] = useState(false);
    const [attachment, setAttachment] = useState([]);
    const [uploadDataIm, setUploadDataIm] = useState([]);
    const handleFile = async(e) =>{
        let file = e.target.files
        let files = [];
        let filename = [];
        for (let i = 0; i < file.length; i++) {
            let data = e.target.files[i];
            const fileData = new FormData();
            fileData.append("uploaded_file", data);
            await PolicyService.uploadFile(fileData).then((response) => {
                let name = { name: data.name };
                filename.push(name);
                files.push(response.data);
      });
      setErrorattachment(false);
    setAttachment(filename);
    setUploadDataIm(files);
    }
    }
    const submitFile = async() =>{
        console.log("ghjk",uploadDataIm);
        await PolicyService.importExel(uploadDataIm[0]).then((res)=>{
            console.log("===========",res);
            GetBalanceSheet();
        })
    }
    const GetBalanceSheet = async() => {
        let data = {
            sort_by: "-id", search: '', status: true, page_no:1,page_size:10,
        costcenter:1,glcode:3,user_id: 0,paginate:true
        }
        await PolicyService.getBalancesheet(data).then((res)=>{
            console.log("++++++++++",res)
        })
    }
    return(
        <>
        <input type="file" placeholder="select file" onChange={handleFile}></input>
        <button type="btn" onClick={submitFile}>Upload</button>
        </>
    )
}