import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as MandatoryService from "../../service/mandatory";
import * as moment from "moment";
import { changeSkipStatus, getCourseList } from "../../service/dashboard";
import { logoutUser } from "../../service/userService";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { MD5 } from "crypto-js";
import { quaterlyTerms } from "../../service/agree-terms";

function Mandatory() {
  const [courses, setCourses] = useState([]);
  const [isSkipTwo, setIsSkipTwo] = useState(false)
  const userData = useSelector(selectUserData);
  const openSearch = () => {
    const someElement = document.getElementById("mandseach");
    someElement.classList.add("inputshowmand");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  let url = `${process.env.REACT_APP_PROD_URL}"${localStorage?.getItem(
    "secret"
  )}"`;
  const SUCCESS_URL = 'https://performancemanager10.successfactors.com/sf/learning?company=PI'

  const Location = useLocation();

  const getCourses = async (pageNo, pageSize, sortBy) => {
    dispatch(setDisplayLoader("Display"));
    getCourseList()
      .then((response) => {
        if(response?.data?.dataList){
          dispatch(setDisplayLoader("Hide"));
          setCourses(response?.data?.dataList);
          if(response?.data?.dataList?.posh == true && response?.data?.dataList?.coc == true && response?.data?.dataList?.cyber == true){
            navigate("/dashboard")
          }
        }else{
          navigate("/");
        }
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
        navigate("/");
      });
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (courses?.joining_date !== undefined) {
      let dateDiff = moment(new Date()).diff(moment(courses?.joining_date[0]), 'days')
      console.log(dateDiff);
      if (dateDiff > 180) {
        setIsSkipTwo(true)
      }
    }
  }, [courses])

  const skipCourse = () => {
    let payload = null;
    if (isSkipTwo === false) {
      payload = {
        cur_skip: moment(new Date()).format("YYYY-MM-DD"),
      };
    } else {
      payload = {
        cur_skip_two: moment(new Date()).format("YYYY-MM-DD"),
      }
    }
    changeSkipStatus(payload).then((response) => {
      if (response?.status === 200) {
        if (
          !JSON.parse(Location.state.res).data.dataList.accept_tnc &&
          !JSON.parse(Location.state.res).data.dataList.accept_social
        ) {
          navigate("/agree-terms", {
            state: {
              component: "social-policy",
            },
          });
        } else if (
          !JSON.parse(Location.state.res).data.dataList.accept_tnc &&
          JSON.parse(Location.state.res).data.dataList.accept_social
        ) {
          navigate("/agree-terms", {
            state: {
              component: "quater-terms",
            },
          });
        } else if (
          JSON.parse(Location.state.res).data.dataList.accept_tnc &&
          !JSON.parse(Location.state.res).data.dataList.accept_social
        ) {
          navigate("/social-policy", {
            state: {
              component: "quater-terms",
            },
          });
        } else if (
          JSON.parse(Location.state.res).data.dataList.accept_tnc &&
          JSON.parse(Location.state.res).data.dataList.accept_social
        ) {
          getQuaterlyChecks();
        }
      }
    });
  };

  const logoutHandle = async () => {
    dispatch(setDisplayLoader("Display"));
    await logoutUser().then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        handleLogout("redirect");
        localStorage.clear();
      }
    });
  };

  const getQuaterlyChecks = () => {
    let queryParam = `curMonth=${moment(new Date()).month() + 1}`;
    quaterlyTerms(queryParam).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.dataList?.checked === true) {
          navigate("/dashboard");
        } else {
          navigate("/quater-terms", {
            state: {
              component: "dashboard",
            },
          });
        }
      }
    });
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      const logoutRequest = {
        account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest);
      // const logoutRequest = {
      //   redirectUri: "/",
      //   postLogoutRedirectUri: "/",
      // };
      // instance.logoutPopup(logoutRequest);
    }
  };

  return (
    <>
      <iframe
        src={url}
        allowFullScreen
        style={{
          width: "100%",
          height: "100%",
        }}
        hidden
      ></iframe>
      <iframe
        src={SUCCESS_URL}
        allowFullScreen
        style={{
          width: "100%",
          height: "100%",
        }}
        hidden
      ></iframe>
      <div className="mandatory-bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div class="col-4">
              <img src={Logo} alt="" height={33} />
            </div>
            <div class="col-4 text-center">
              <img src="images/mypilogo.png" alt="" height={29} />
            </div>
            <div class="col-4">
              <ul class="mand-tophead">
                <li>
                  <div
                    className="mandtop-user mandatory-newlogout"
                    onClick={() => {
                      logoutHandle();
                    }}
                  >
                    <img
                      src="images/logout-mand-01.png"
                      class="madlogout"
                      alt=""
                    />{" "}
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="row mt-4 mt-sm-3 mt-md-5">
            <div className="col-md-12">
              <div className="px-md-4">
                <div className="row">
                  <div className="col-md-10">
                    <h2 className="mand-page-head">Mandatory Courses</h2>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <div
                      className="course-view-list mb-3 mb-lg-0"
                      id="scrollpart"
                    >
                      {Object.keys(courses).length > 0 &&
                        Object.keys(courses)
                          ?.filter((obj) => {
                            if (courses[obj] !== false && obj !== "skip_date" && obj !== "joining_date" && obj !== 'skip_date_two') {
                              return true;
                            }
                          })
                          .map((data, index) => (
                            <>
                              <div className="course-view">
                                <div className="row align-items-center">
                                  <div className="col-sm-2">
                                    <div className="mad-course-image">
                                      <img
                                        src={
                                          data === "coc"
                                            ? "images/coc_course.png"
                                            : data === "cyber"
                                              ? "images/cyber_course.png"
                                              : "images/posh.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-8">
                                    <div
                                    // className={
                                    //   data?.completed_at == null
                                    //     ? "mand-cou-warning"
                                    //     : "mand-cou-success"
                                    // }
                                    >
                                      <i className="fas fa-exclamation-circle"></i>{" "}
                                      {/* Cyber Security e-learning Module
                                    completion&nbsp;
                                    {data?.completed_at == null
                                      ? "pending"
                                      : ""} */}
                                      {courses[data]?.desc}&nbsp;
                                    </div>
                                    <h3 className="mand-coursehead">
                                      {/* {data?.course_title} */}
                                    </h3>
                                    <h4 className="mand-coursedesc">
                                      {/* {data?.description} */}
                                    </h4>
                                  </div>
                                  <div className="col-sm-2">
                                    <Link
                                      target="_blank"
                                      to={courses[data]?.url}
                                      className="btn btn-success mand-cousebtn"
                                    >
                                      View Course
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                  </div>
                  {Object.keys(courses).length > 0 && (
                    <div className="col-md-12 col-lg-3 d-flex flex-column">
                      <div className="mand-rightwrng mb-3 mb-lg-0">
                        {
                          isSkipTwo === false ? courses?.skip_date >= moment(new Date()).format('YYYY-MM-DD') ?
                            (
                              "Please complete your mandatory courses because your login will expire on " + (
                                courses?.skip_date === ""
                                  ? moment(new Date())
                                    .add(1, "month")
                                    .format("Do MMM YYYY")
                                  : isSkipTwo === false ? moment(courses?.skip_date).format("Do MMM YYYY")
                                    : (isSkipTwo && courses?.skip_date_two !== "") ? moment(courses?.skip_date_two).format("Do MMM YYYY")
                                      : moment(new Date())
                                        .add(31, "days")
                                        .format("Do MMM YYYY")
                              )
                            ) : (
                              "Your MyPi has been locked as you have already passed the deadline. MyPi will be automatically unlocked after completing pending courses."
                            )
                            :
                            isSkipTwo && courses?.skip_date_two !== "" ?
                              courses?.skip_date_two >= moment(new Date()).format('YYYY-MM-DD') ?
                                (
                                  "Please complete your mandatory courses because your login will expire on " + (
                                    courses?.skip_date === ""
                                      ? moment(new Date())
                                        .add(1, "month")
                                        .format("Do MMM YYYY")
                                      : isSkipTwo === false ? moment(courses?.skip_date).format("Do MMM YYYY")
                                        : (isSkipTwo && courses?.skip_date_two !== "") ? moment(courses?.skip_date_two).format("Do MMM YYYY")
                                          : moment(new Date())
                                            .add(31, "days")
                                            .format("Do MMM YYYY")
                                  )
                                ) : (
                                  "Your MyPi has been locked as you have already passed the deadline. MyPi will be automatically unlocked after completing pending courses."
                                )
                              :
                              moment(new Date())
                                .add(31, "days")
                                .format("Do MMM YYYY")
                        }
                        {/* on{" "}
                        {courses?.skip_date === ""
                          ? moment(new Date())
                            .add(1, "month")
                            .format("Do MMM YYYY")
                          : isSkipTwo === false ? moment(courses?.skip_date).format("Do MMM YYYY")
                            : (isSkipTwo && courses?.skip_date_two !== "") ? moment(courses?.skip_date_two).format("Do MMM YYYY")
                            : moment(new Date())
                              .add(31, "days")
                              .format("Do MMM YYYY")} */}
                      </div>
                      <br />
                      {/* <div className="mt-auto mx-4">
                        <Link
                          // to={}
                          className="btn btn-primary-inner btn-block"
                        >
                          Assigned Learning
                        </Link>
                      </div> */}
                      {/* <br /> */}
                      {isSkipTwo === false && (moment(courses?.skip_date).diff(
                        moment(new Date()),
                        "days"
                      ) >= 0 ||
                        courses?.skip_date === "") && (
                          <div
                            className="mt-auto mx-4"
                            onClick={() => {
                              skipCourse();
                            }}
                          >
                            <Link
                              // to={
                              //   (moment(courses?.skip_date).diff(
                              //     moment(new Date()),
                              //     "days"
                              //   ) >= 0 ||
                              //     courses?.skip_date === "") &&
                              //   "/agree-terms"
                              // }
                              state={{
                                component: "social-policy",
                              }}
                              className="btn btn-warning btn-block"
                            >
                              Skip
                            </Link>
                          </div>
                        )}
                      {console.log(courses, isSkipTwo, moment(courses?.skip_date_two).diff(
                        moment(new Date()),
                        "days"
                      ))}
                      {isSkipTwo === true && (moment(courses?.skip_date_two).diff(
                        moment(new Date()),
                        "days"
                      ) >= 0 ||
                        courses?.skip_date_two === "") && (
                          <div
                            className="mt-auto mx-4"
                            onClick={() => {
                              skipCourse();
                            }}
                          >
                            <Link
                              // to={
                              //   (moment(courses?.skip_date).diff(
                              //     moment(new Date()),
                              //     "days"
                              //   ) >= 0 ||
                              //     courses?.skip_date === "") &&
                              //   "/agree-terms"
                              // }
                              state={{
                                component: "social-policy",
                              }}
                              className="btn btn-warning btn-block"
                            >
                              Skip
                            </Link>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mandatory;
