import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Badge } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import * as TravelListingAPI from "../../service/travelService";
import moment from "moment";

const ViewOtherExpense = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [id, setid] = useState(location?.state?.from);
  const [otherExpUserOrg, setOtherExpUserOrg] = useState(
    location?.state?.other_exp_user_org
  );
  const [otherExpUserID, setOtherExpUserID] = useState(
    location?.state?.exp_uid
  );
  const [viewExpenseData, setViewExpenseData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [remarksData, setRemarksData] = useState({
    id: "",
    status: "",
    remark: "",
  });
  const [formValidation, setFormValidation] = useState({
    remark: "",
  });

  const saveRemarksData = (value) => {
    setIsLoading(true);
    let arrOtherExpense = { approval_data: [] };
    let data = remarksData;
    data.id = id;
    data.status = value;
    data.remark = remarksData.remark;
    arrOtherExpense?.approval_data?.push(data);





    // return 
    
    TravelListingAPI.saveRemarksData(arrOtherExpense)
      .then((response) => {
        toast.success(response.data.message);
        // getViewData(id);
        setIsLoading(false);

        // navigate(`/common-approve/${location?.state?.subId}`, {
        //   state: {
        //     subtypeid: location?.state?.subId,
        //     subTypeName: location?.state?.type,
        //   },
        // });


        
    let datas=location?.state?.dataCountOtherExpExtras
    console.log(`type`,datas,location?.state?.type);

    if(datas?.otherExpException?.total>1 && datas?.otherExp?.total>1){
      navigate(`/common-approve/${location?.state?.subId}`, {
        state: {
          subtypeid: location?.state?.subId,
          subTypeName: location?.state?.type,
        },
      });      
    }

    else if((!datas?.otherExpException?.total ||datas?.otherExpException?.total==1) && datas?.otherExp?.total>1){
      navigate(`/common-approve/${datas?.otherExp?.id}`, {
        state: {
          // subtypeid: location?.state?.subId,
          subtypeid: datas?.otherExp?.id,
          subTypeName: data?.otherExp?.type,
        },
      });      
    }
    else  if(datas?.otherExpException?.total>1 && (!datas?.otherExp?.total || datas?.otherExp?.total==1)){
      navigate(`/common-approve/${datas?.otherExpException?.id}`, {
        state: {
          // subtypeid: location?.state?.subId,
          subtypeid: datas?.otherExpException?.id,
          subTypeName: datas?.otherExpException?.type,
        },
      });      
    }

    else{
      navigate("/dashboard")
    }

        
    
        
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getOtherExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    from_date,
    to_date,
    status,
    id
  ) => {
    TravelListingAPI.getOtherExpenseList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status,
        id
      )
    )
      .then((response) => {
        setViewExpenseData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    from_date,
    to_date,
    status,
    id
  ) => {
    let queryParm = "&paginate=" + paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (id !== "" && id !== undefined && id !== null) {
      queryParm = queryParm + "&id=" + id;
    }
    return queryParm;
  };
  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!remarksData.remark) {
      isError = true;
      formerr.remark = "Remarks Required";
      setFormValidation(formerr);
    }
    return isError;
  };
  const handleSaveValue = (value) => {
    if (handleValidate()) return false;
   
    saveRemarksData(value);
  };

  useEffect(() => {
    getOtherExpenseList("", "", "", "", true, otherExpUserID, "", "", "", id);
  }, []);

  return (
    <div class="row">
      <div class="col-md-12">
        <div class="content-wrapper-inner content-wrapper-inner2">
          <div class="innerheadsec pb-0">
            <div className="row">
              <div class="col-md-12">
                <h3 class="policyhead">
                  Request ID: {otherExpUserOrg ? otherExpUserOrg : ""}
                  {viewExpenseData?.id}
                </h3>
              </div>
            </div>
          </div>
          <div class="d-flex viewformaftersubmit flex-wrap">
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>User Code</label>
                <p>{viewExpenseData?.user_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Name</label>
                <p>{viewExpenseData?.user_name}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Vendor Code</label>
                <p>{viewExpenseData?.user_vendor_code}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Request Id</label>
                <p>
                  {" "}
                  {otherExpUserOrg ? otherExpUserOrg : ""}
                  {viewExpenseData?.id}
                </p>
              </div>
            </div>

            {/* <div class="d-flex viewformaftersubmit"> */}
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Location</label>
                <p>{viewExpenseData?.user_location}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Grade</label>
                <p>{viewExpenseData?.user_grade}</p>
              </div>
            </div>
            {/* </div> */}

            {/* <div class="col-md-3 ml-2 viewformaftersubmit"> */}
              <div class="form-group innergroup col-md-4">
                <label>Download Attachments</label>
                <div class="d-flex">
                  {viewExpenseData?.attachment?.map((item, idx) => (
                    <>
                      <br />
                      <span class="mr-3">
                        <i class="fas fa-file-download"></i>
                        <a
                          href={item?.attachment_path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.attachment_path?.includes(".pdf") ? (
                            <img
                              src={"/images/policy-icon/pdf.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : item?.attachment_path?.includes(".xlsb") ||
                            item?.attachment_path?.includes(".xlsx") ||
                            item?.attachment_path?.includes(".xls") ||
                            item?.attachment_path?.includes(".csv") ? (
                            <img
                              src={"/images/policy-icon/xlsx.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : item?.attachment_path?.includes(".doc") ||
                            item?.attachment_path?.includes(".docs") ||
                            item?.attachment_path?.includes(".docx") ||
                            item?.attachment_path?.includes(".png") ||
                            item?.attachment_path?.includes(".jpg") ||
                            item?.attachment_path?.includes(".jpeg") ? (
                            <img
                              src={"/images/policy-icon/docs.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : item?.attachment_path?.includes(".msg") ? (
                            <img
                              src={"/images/policy-icon/msg.png"}
                              alt="image"
                              width="70px"
                              height="70px"
                            />
                          ) : (
                            ""
                          )}
                        </a>
                      </span>
                    </>
                  ))}
                </div>
              </div>
            {/* </div> */}

            <div class="col-md-4">
              <div class="form-group innergroup">
                <label>Type</label>
                <p>
                  {viewExpenseData?.expense_type[0]?.toUpperCase() +
                    viewExpenseData?.expense_type?.slice(1)}
                </p>
              </div>
            </div>

            <div class="col-md-4 ">
              <div class="form-group innergroup">
                <label>Applied On</label>
                <p>
                  {moment(viewExpenseData?.created_at).format("DD-MM-YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
            <Tabs defaultActiveKey="Domestic" id="approvaltabs">
              <Tab
                eventKey="Domestic"
                title={<React.Fragment>Other Expense</React.Fragment>}
              >
                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        {/* <th>Action</th> */}
                        <th>S.no</th>
                        <th>GL Account</th>
                        {viewExpenseData?.expense_type?.toLowerCase()?.includes("petty") && (
                          <th>Cost Center</th>
                        )}
                        <th>Travel By/Machine No.</th>
                        <th>Bill No.</th>
                        <th>For month of</th>
                        <th>Bill Date</th>
                        <th>Bill Amount</th>
                        <th>Reason for Expense</th>
                        {/* <th>File</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {viewExpenseData?.expense_data?.length ? (
                        viewExpenseData?.expense_data?.map((data, index) => (
                          <tr>
                            {/* <td>
                            <button class="btn btn-blanktd text-primary">
                              <i class="far fa-edit"></i>
                            </button>
                            <button class="btn btn-blanktd text-danger ml-2">
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td> */}
                            <td>{index + 1}</td>
                            <td>
                              {data?.gl_account}-{data?.gl_desc}
                            </td>
                            {viewExpenseData?.expense_type == "petty" && (
                              <td>
                                {data?.cost_center_code}-
                                {data?.cost_center_name}
                              </td>
                            )}
                            <td>{data?.travel_by ? data?.travel_by : data?.machine_no}</td>
                            <td>{data?.bill_number}</td>
                            <td>{viewExpenseData?.expense_type == "petty" ? moment.parseZone(data?.for_month_of).format("MMMM") : 'N/A'}</td>
                            
                            <td>
                              {moment(data?.bill_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{data?.bill_amount?.toFixed(2)}</td>
                            <td>{data?.remarks}</td>
                            {/* <td>
                            <a href="#">
                              <i class="fas fa-paperclip"></i> View Doc
                            </a>
                          </td> */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>

          <div class="row">
            <div class="col-md-12">
              <p
                style={{
                  color: "#0195d4",
                  fontWeight: "bold",
                }}
              >
                Approve Details
              </p>
              <div class="table-responsive ">
                <table class="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Approver Name</th>
                      <th>Approver Level</th>
                      <th>Status</th>
                      <th>Approved Date</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewExpenseData?.approver_remark_data?.map(
                      (data, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {`${data?.approver_name} (${data?.approver_username})`}
                            </td>
                            <td>
                              <span>{data?.approver_type}</span>
                            </td>
                            <td>
                            {!data?.status?"Pending":data?.status=='A'
                                        ?"Approved"
                                        :data?.status=='R'
                                        ?"Rejected"
                                        :data?.status}
                            </td>
                              
                            <td>
                              {data?.remark !== null &&
                                moment(data?.updated_at).format("DD-MM-YYYY")}
                            </td>
                            <td>{data?.remark}</td>
                          </tr>
                        </>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-14">
          <div class="form-group innergroup">
            <label>
              Add Comments<span class="text-danger">*</span>
            </label>
            <textarea
              class="form-control"
              placeholder="Enter Comments here..."
              name="remark"
              style={{ width: "100%", height: "auto" }}
              rows="2"
              onChange={(e) => {
                setRemarksData({
                  ...remarksData,
                  remark: e.target.value,
                });
                setFormValidation({
                  ...formValidation,
                  remark: "",
                });
              }}
              value={remarksData.remark}
            ></textarea>
            <div className="small text-danger">{formValidation.remark}</div>
          </div>
        </div>
        {console.log(location?.state, "locationnnn")}
        <div class="col-md-12 pb-3">
          <button
            class="btn btn-outline-secondary mr-2"
            onClick={() => {
              navigate(`/common-approve/${location?.state?.subId}`, {
                state: {
                  subtypeid: location?.state?.subId,
                  subTypeName: location?.state?.type,
                },
              });
            }}
          >
            <i class="fas fa-arrow-circle-left"></i> Cancel
          </button>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <button
                class="btn btn-outline-danger mr-2"
                onClick={() => handleSaveValue("R")}
              >
                <i class="far fa-times-circle"></i> Reject
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => handleSaveValue("A")}
              >
                <i class="far fa-check-square"></i> Approve
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewOtherExpense;
