import React, { useState, useEffect } from "react";
import { Tabs, Tab, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as moment from "moment";
import avatar from "../../../assets/images/avatarImg.png";
import Avatar from "@mui/material/Avatar";
import avtar from "../../../assets/images/avtar.png";
import femaleAvatr from "../../../assets/images/Female-Avatar.png";
import AvatarGroup from "@mui/material/AvatarGroup";

const ViewUser = (props) => {
  window.scrollTo(0, 0);
  const { viewForm, onViewClose, viewFormData } = props;
  const [group, setGroups] = useState([]);
  const [currentTab, setCurrentTab] = useState("primary");

  useEffect(() => {
    console.log("viewFormData.groups", viewFormData.groups);
    let data = viewFormData?.groups;
    let groups = [];
    data?.map((x) => {
      let data2 = { value: x?.id, label: x?.name };
      groups.push(data2);
    });
    setGroups(groups);
  }, []);

  return (
    <>
      {" "}
      <div className="content-wrapper-inner">
        <div className="innerheadsec pb-0">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">View User</h4>
              {/* <Breadcrumb>
                                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item href="/home">
                                                Role Management
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item href="/user">
                                                User Master
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>View User</Breadcrumb.Item>
                                        </Breadcrumb> */}
            </div>
          </div>
        </div>

        <div className="addusertabs">
          <Tabs
            activeKey={currentTab}
            id="adduserinfo"
            onSelect={(e) => setCurrentTab(e)}
          >
            <Tab
              eventKey="primary"
              title={
                <React.Fragment>
                  <span>1</span> Primary Info
                </React.Fragment>
              }
            >
              
              <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-4">
              <div class="team-profile">
                <Avatar
                  alt="Remy Sharp"
                  src={`data:image/gif;base64,${viewFormData?.image}`
                    }
                  />
                </div>
              </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Organisation</label>

                    <input
                      type="text"
                      className="form-control"
                      value={
                        viewFormData?.organisation_name +
                        "(" +
                        viewFormData?.organisation_code +
                        ")"
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Unit</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.business_unit_name +
                          "(" +
                          viewFormData?.business_unit_code +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Id</label>
                    <input
                      type="email"
                      className="form-control"
                      value={viewFormData?.username}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.first_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.last_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>PAN No.</label>
                    <input
                      type="text"
                      className="form-control text-uppercase"
                      value={viewFormData?.pan_no}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Vendor Code</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Vendor Code"
                      name="employee_vendor_code"
                      value={viewFormData?.employee_vendor_code}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Vendor Code</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData?.vendor_name != null
                            ? viewFormData?.vendor_name
                            : ""
                        } ${
                          viewFormData?.vendor_code != null
                            ? "(" + viewFormData?.vendor_code + ")"
                            : ""
                        }`}
                      />
                    </select>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={viewFormData?.business_email}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>HR Name</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.hr_first_name +
                          " " +
                          viewFormData?.hr_last_name +
                          " " +
                          "(" +
                          viewFormData?.hr_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Mobile No.</label>
                    {console.log({viewFormData})}
                    <div className="mobile-country-drop">
                      <div className="row">
                        <div className="col-3 pr-0">
                          <select
                            className="form-control newbgselect"
                            name="business_unit_id"
                            disabled
                            style={{paddingLeft:"20px"}}
                          >
                            <option
                              value=""
                              label={viewFormData?.business_country_dial_code}
                            />
                          </select>
                        </div>
                        <div className="col-9 pl-0">
                          <input
                            type="number"
                            className="form-control"
                            value={viewFormData?.business_mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Reporting Manager</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.reporting_person_firstname +
                          " " +
                          viewFormData?.reporting_person_lastname +
                          " " +
                          "(" +
                          viewFormData?.reporting_person_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Functional Head</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.functional_head_first_name +
                          " " +
                          viewFormData?.functional_head_last_name +
                          " " +
                          "(" +
                          viewFormData?.functional_head_username +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Cost Center</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData?.cost_center_name != null
                            ? viewFormData?.cost_center_name
                            : ""
                        } ${
                          viewFormData?.cost_center_code != null
                            ? "(" + viewFormData?.cost_center_code + ")"
                            : ""
                        }`}
                        //  label={viewFormData?.cost_center_name!=null ? viewFormData?.cost_center_name : "" + "("+viewFormData?.cost_center_code!=null ? viewFormData?.cost_center_code : ""+")"}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Department</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.department_name +
                          "(" +
                          viewFormData?.department_code +
                          ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Designation</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={`${
                          viewFormData?.designation_name != null
                            ? viewFormData?.designation_name
                            : ""
                        } (${
                          viewFormData?.designation_code != null
                            ? viewFormData?.designation_code
                            : ""
                        })`}
                        //  label={viewFormData?.designation_name + "("+viewFormData?.designation_code+")"}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>MyPI Role</label>
                    <Select
                      isMulti
                      // options={selrole}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                      isDisabled
                      value={group}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>SAP Role</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`${
                        viewFormData?.sap_role +
                        "(" +
                        viewFormData?.sap_role_code +
                        ")"
                      }`}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Gender</label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        value=""
                        label={viewFormData?.gender == "M" ? "Male" : viewFormData?.gender == "F" ? "Female" : ""}
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Mandatory Course Days </label>
                    <input
                      type="number"
                      className="form-control"
                      value={viewFormData?.course_days}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Joining</label>
                    <input
                      type="text"
                      value={moment(viewFormData?.date_of_joining).format(
                        "DD-MM-YYYY"
                      )}
                      className="form-control"
                      placeholder="Enter Mandatory course days"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Leaving</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment(viewFormData?.date_of_leaving).format(
                        "DD-MM-YYYY"
                      )}
                      disabled
                    />
                  </div>
                </div>
                {/* <div  className="col-md-4">
                                                        <div  className="form-group innergroup position-relative inneereye">
                                                            <label>Password <span  className="text-danger">*</span></label>
                                                            <input type="password"  className="form-control"  disabled/>
                                                            <i  className="far fa-eye-slash eyepassword"></i>
                                                             
                                                        </div> 
                                                    </div> */}
                {/* <div  className="col-md-4">
                                                        <div  className="form-group innergroup position-relative inneereye">
                                                            <label>Confirm Password <span  className="text-danger">*</span></label>
                                                            <input type="password"  className="form-control" disabled/>
                                                            <i  className="far fa-eye eyepassword"></i>
                                                             
                                                        </div> 
                                                    </div>  */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Is SF User</label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        value=""
                        label={viewFormData?.is_sf_user == true ? "Yes" : "No"}
                      />
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup position-relative">
                    <label>Attachment</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      value={viewFormData?.image}
                    /> */}
                    {/* <div className="upload-btn-wrapper up-loposition">
                      <button className="uploadBtn">Browse</button>
                      <input type="file" title="" disabled />
                    </div> */}
                    {/* <div className="mt-2">
                      <a href={viewFormData?.image} target="_blank">
                        <img
                          src={viewFormData?.image}
                          style={{ width: "22%" }}
                        />
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.personal_area_desc == null
                            ? ""
                            : viewFormData?.personal_area_desc +
                              "(" +
                              viewFormData?.personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sub-Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.sub_personal_area_desc == null
                            ? ""
                            : viewFormData?.sub_personal_area_desc +
                              "(" +
                              viewFormData?.sub_personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Status</label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      disabled
                      value={viewFormData?.emp_status}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Employee Sub Group</label>
                          <select
                            className="form-control newbgselect"
                            name="emp_sub_group"
                            // classNamePrefix="select"
                            
                            value={viewFormData?.emp_sub_group}
                            disabled
                          >
                            <option value={viewFormData?.emp_sub_group}>{viewFormData?.emp_sub_group}</option>
                            
                          </select>
                          
                        </div>
                      </div>
                      { viewFormData?.emp_sub_group ==='C1' ?
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>Consultant Sub Group</label>
                          <select
                            className="form-control newbgselect"
                            name="emp_sub_group"
                            // classNamePrefix="select"
                            
                            value={viewFormData?.emp_subgroup_cons}
                            disabled
                          >
                            <option value={viewFormData?.emp_subgroup_cons}>{viewFormData?.emp_subgroup_cons}</option>
                            
                          </select>
                          
                        </div>
                      </div>
                      :
                      <div></div>
                      }
                      <div className="col-md-4">
                        <div className="form-group innergroup">
                          <label>
                            Location<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"                            
                            name="location"
                            value={viewFormData?.location}
                            disabled
                          />
                         
                        </div>
                      </div>
                    
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">Block User</label>
                    <label className="logCheck d-inline-block mr-4">
                      Block
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={viewFormData?.is_blocked === true}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="logCheck d-inline-block">
                      UnBlock
                      <input
                        type="radio"
                        name="statusupdate2"
                        checked={viewFormData?.is_blocked === false}
                        disabled
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label className="d-block mb-2">Status</label>
                    <label className="logCheck d-inline-block mr-4">
                      Active
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData?.is_active === true}
                        disabled
                      />
                      <span className="checkmark check-cursor"></span>
                    </label>
                    <label className="logCheck d-inline-block">
                      Inactive
                      <input
                        type="radio"
                        name="statusupdate1"
                        checked={viewFormData?.is_active === false}
                        disabled
                      />
                      <span className="checkmark check-cursor"></span>
                    </label>
                  </div>
                </div>

                <div className="row"></div>

                <div className="col-md-12 mt-4">
                  <button
                    onClick={() => {
                      onViewClose();
                    }}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Back
                  </button>
                  <button
                    onClick={() => {
                      setCurrentTab("personal");
                    }}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="personal"
              title={
                <React.Fragment>
                  <span>2</span> Personal Details
                </React.Fragment>
              }
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Date of Birth</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment(viewFormData?.date_of_birth).format(
                        "DD-MM-YYYY"
                      )}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Birthday Visibility</label>
                    <select className="form-control newbgselect" disabled>
                      <option
                        label={
                          viewFormData?.birthday_visible === true ? "Yes" : "No"
                        }
                      >
                        Select
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Email</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.personal_email}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Mobile No.</label>
                    <div class="mobile-country-drop">
                      <div className="row">
                        <div className="col-3 pr-0">
                          <select
                            className="form-control newbgselect"
                            name="business_unit_id"
                            disabled
                            style={{paddingLeft:"20px"}}
                          >
                            <option
                              value=""
                              label={viewFormData?.country_dial_code}
                            />
                          </select>
                        </div>
                        <div className="col-9 pl-0">
                          <input
                            type="text"
                            className="form-control"
                            value={viewFormData?.personal_mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Blood Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.blood_group}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Marital Status</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.marital_status}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <button
                    onClick={() => setCurrentTab("primary")}
                    type="button"
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentTab("other")}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab>
            {/* <Tab
              eventKey="Addressess"
              title={
                <React.Fragment>
                  <span>3</span> Addressess
                </React.Fragment>
              }
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Country</label>

                    <input
                      type="text"
                      className="form-control"
                      value={
                        viewFormData?.country_name == null
                          ? ""
                          : viewFormData?.country_name +
                            " (" +
                            viewFormData?.country_dial_code +
                            ")"
                      }
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>State</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData?.state_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>City</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData?.city_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>District</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option value="" label={viewFormData?.district_name} />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.personal_area_desc == null
                            ? ""
                            : viewFormData?.personal_area_desc +
                              "(" +
                              viewFormData?.personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sub-Personal Area</label>
                    <select
                      className="form-control newbgselect"
                      name="business_unit_id"
                      disabled
                    >
                      <option
                        value=""
                        label={
                          viewFormData?.sub_personal_area_desc == null
                            ? ""
                            : viewFormData?.sub_personal_area_desc +
                              "(" +
                              viewFormData?.sub_personal_area_name +
                              ")"
                        }
                      />
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.address_line_1}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.address_line_2}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.address_line_3}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Landmark</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.landmark}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.pincode}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    type="button"
                    onClick={() => setCurrentTab("personal")}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentTab("other")}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Next
                  </button>
                </div>
              </div>
            </Tab> */}
            <Tab
              eventKey="other"
              title={
                <React.Fragment>
                  <span>3</span> Other
                </React.Fragment>
              }
            >
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.ex_company}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Blood Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.blood_group}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Marital Status</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.marital_status}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_address_line_1}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_address_line_2}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Address Line 3</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_address_line_3}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_country_name}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent State</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_state_name}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_city_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent District</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_district_name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Permanent Pincode</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.perm_pincode}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bank Key</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.bank_key}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Bank Account Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.bank_account}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payment Method</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.payment_method}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.payment_method}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale Level</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.payscalelevel}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.payscalegroup}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.empgroup}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Login Status</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.loginstatus}
                      disabled
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Payscale group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.payscalegroup}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Dist.</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.sales_dist}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Office</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.sales_off}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Sales Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.sales_grp}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created By</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.created_by}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created At</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment
                        .utc(viewFormData?.created_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Modified By</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.updated_by}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Updated At</label>
                    <input
                      type="text"
                      className="form-control"
                      value={moment
                        .utc(viewFormData?.updated_at)
                        .format("Do MMM YYYY, h:mm:ss A")}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>SAP Sync</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.sap_sync}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Employee Sub Group</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.emp_sub_group}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>SAP Role</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`${
                        viewFormData?.sap_role == null
                          ? ""
                          : viewFormData?.sap_role +
                              " " +
                              "(" +
                              viewFormData?.sap_role_code ==
                            null
                          ? ""
                          : viewFormData?.sap_role_code + ")"
                      }`}
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Business Area</label>
                    <input
                      type="text"
                      className="form-control"
                      value={viewFormData?.business_area}
                      disabled
                    />
                  </div>
                </div> */}

                <div className="col-md-12 mt-4">
                  <button
                    type="button"
                    onClick={() => setCurrentTab("personal")}
                    className="btn btn-outline-secondary mr-3"
                  >
                    <i className="far fa-arrow-alt-circle-left"></i> Previous
                  </button>
                  <button
                    onClick={() => {
                      onViewClose();
                    }}
                    className="btn btn-primary-inner bpi-main"
                  >
                    <i className="far fa-arrow-alt-circle-right"></i> Back to
                    Users
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
