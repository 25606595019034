import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const saveOtvPayment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/otv/otv`, data);
};

export const updateOtvPayment = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/otv/otv_2/${id}`, data);
};

export const importExel = async (data) => {
  return axios({
    url: `${AUTH_BASE_URL}/otv/otv_bulk_import`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};
export const importExelNOnPo = async (data, type) => {
  return axios({
    url: `${AUTH_BASE_URL}/otv/non_po_bulk_import?type=${type}`,
    method: "POST",
    responseType: "blob",
    data: data,
  });
};

export const getDirectoryDetails = async () => {
  return axios.get(`${AUTH_BASE_URL}/global_import_details`);
};
export const getOtvDataList = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv?${queryParm}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/otv/otv_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getGlCodeList = async (type) => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv_gl_code?type=${type}`);
};
export const getExpenseList = async () => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv_expense_category`);
};
export const saveApprover = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/otv/approver_assignment`, data);
};

export const updateApprover = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/otv/approver_assignment/${id}`, data);
};

export const getApproverMaster = (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/otv/approver_assignment?${queryParm}`);
};

export const saveApprovalData = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/otv/action`, data);
};

export const referToSsc = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/otv/action_to_refer`, data);
};

export const getOtvViewData = async (id) => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv?id=${id}`);
};
export const checkInvoiceNumber = async (invoice_number) => {
  return axios.post(
    `${AUTH_BASE_URL}/otv/otv_invoice_no_exists?invoiceNumber=${invoice_number.invoiceNumber}&invoiceDate=${invoice_number.invoiceDate}`
  );
};

export const isBankAccNumber = async (bank_ac_no) => {
  return axios.post(`${AUTH_BASE_URL}/otv/vendor_bank_acc_no_exists?bank_ac_no=${bank_ac_no}
  `);
};
export const isIfscCode = async (country, ifsc_no, bank_ac_no) => {
  return axios.post(`${AUTH_BASE_URL}/otv/otv_ifsc_no_exists?country=${country}&ifsc_no=${ifsc_no}&bank_ac_no=${bank_ac_no}
  `);
};

export const addAttachmentData = async (otv_id, data) => {
  return axios.put(`${AUTH_BASE_URL}/otv/otv/${otv_id}`, data);
};

export const editOtvData = async (otv_id, data) => {
  return axios.put(`${AUTH_BASE_URL}/otv/otv_2/${otv_id}`, data);
};

export const getGSTList = async () => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv_mast_gst_code`);
};
export const getTDSList = async (otv_id) => {
  return axios.get(`${AUTH_BASE_URL}/otv/otv_mast_tds_code?otv_id=${otv_id}`);
};
export const updateTdsAndGSt = async (otv_id, data) => {
  return axios.put(`${AUTH_BASE_URL}/otv/otv_3/${otv_id}`, data);
};

export const checkValidationByGL = async (gl_code) => {
  return axios.get(`${AUTH_BASE_URL}/otv/check_gl_validation?gl_code=${gl_code}`);
};

export const getOtvStateList = async (code) => {
  return axios.post(`${AUTH_BASE_URL}/otv/state/list?code=${code}`);
};

export const receivePickList = async(data)=>{
  return axios.post(`${AUTH_BASE_URL}/otv/receive_pick_list`, data)
}