import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  ProgressBar,
  Tabs,
  Tab,
} from "react-bootstrap";
import * as SkillLocationService from "../../service/skillLocation";
import * as SkillService from "../../service/skill";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import AddSkillLocation from "./AddSkillLocation";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}

function SkillLocation() {
  const dispatch = useDispatch();

  const [skillLocation, setSkillLocation] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [skillLocationData, setSkillLocationData] = useState(null);
  const [permissionAccess, setPermissionAccess] = useState({});
  const [showSkillTab, setShowSkillTab] = useState(false)

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  const getSkillLocation = (pageNo, page_size, sort_by, paginate) => {
    dispatch(setDisplayLoader("Display"));

    SkillLocationService.getSkillLocation(pageNo, page_size, sort_by, paginate)
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setSkillLocation(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const deleteSkillLocation = (idx) => {
    SkillLocationService.deleteSkillLocation(idx)
      .then((res) => {
        if (res.data.status === true) {
          getSkillLocation(currentPage, pageSizeNo, sortData.sort_by, true);
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addSkillLocation = () => {
    setShowForm(true);
    setEditMode(false);
    setSkillLocationData(null);
  };

  const editSkillLocation = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setSkillLocationData(eData);
  };

  const onClose = () => {
    setShowForm(false);
    setSkillLocationData(null);
  };
  const onAddCallBack = () => {
    setShowForm(false);
    getSkillLocation(currentPage, pageSizeNo, sortData.sort_by, true);
  };

  const submit = (id, name) => {
    confirmAlert({
      title: "Delete Skill Location",
      message: `Are you sure to delete location?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSkillLocation(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getSkillLocation(activePage, pageSizeNo, sortData.sort_by, true);
  };

  const getSkillPermission = () => {
    let query = "/skill-tab";
    SkillService.getPermissions(query)
      .then((res) => {
        let findIndex = res.data.dataList.findIndex(el => el.name == "Can Add Skill Tab Access")
        if (findIndex != -1) {
          setShowSkillTab(true)
        }
      })
      .catch((err) => console.log(err));
    SkillService.getSkillPermission()
      .then((response) => {
        setPermissionAccess(response?.data?.dataList);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  useEffect(() => {
    getSkillPermission();
    getSkillLocation(currentPage, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      <div>
        <div class="row">
          <div class="col-md-3">
            <ul class="policylistnames">
              <li>
                <Link to="/skill">Home</Link>
              </li>
              {permissionAccess?.report_view === true && (
                <li>
                  <Link to="/skill-report">Report</Link>
                </li>
              )}
              {permissionAccess?.skill_department_view === true && (
                <li>
                  <Link to="/skill-department">Department Master</Link>
                </li>
              )}
              {permissionAccess?.skill_plant_view === true && (
                <li>
                  <Link to="/skill-plant">Plant Master</Link>
                </li>
              )}
              {permissionAccess?.skill_list_view === true && (
                <li>
                  <Link to="/skill-master">Skill Master</Link>
                </li>
              )}
              {permissionAccess?.skill_group_view === true && (
                <li>
                  <Link to="/skill-group">Skill Group Master</Link>
                </li>
              )}
              {permissionAccess?.skill_sub_group_view === true && (
                <li>
                  <Link to="/skill-sub-group">Sub Group Master</Link>
                </li>
              )}
              {permissionAccess?.group_owner_mapping_view === true && (
                <li>
                  <Link to="/skill-group-mapping">Skill Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.quarterly_report_view === true && (
                <li>
                  <Link to="/skill-quarterly">Quarterly Reports</Link>
                </li>
              )}
              {permissionAccess?.skill_matrix_usage_view === true && (
                <li>
                  <Link to="/skill-usage">Skill Matrix Usage</Link>
                </li>
              )}
              {permissionAccess?.user_group_mapping_view === true && (
                <li>
                  <Link to="/skill-user">User Group Mapping</Link>
                </li>
              )}
              {permissionAccess?.skill_location_view === true && (
                <li class="active">
                  <Link to="/skill-location">Skill Location Access</Link>
                </li>
              )}
              {
                showSkillTab && (
                  <li>
                    <Link to="/skill-tab">Skill Tab Access</Link>
                  </li>
                )
              }
            </ul>
          </div>
          <div class="col-md-9">
            <ToastContainer autoClose={1000} />
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec">
                <div className="row">
                  <div class="col-md-12">
                    <h4 class="inner-page-title">Skill Location Access</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="border-top mb-3"></div>
                  </div>
                </div>
                {
                  permissionAccess?.skill_location_update === true && (
                    <div className="row">
                      <div class="col-lg-12">
                        <div class="inline-spacing btn-betweenspaing">
                          <button
                            class="btn btn-primary-inner"
                            onClick={addSkillLocation}
                          >
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Add New
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>

              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Applied Action</th>
                      <th>Quarter</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skillLocation?.length ? (
                      skillLocation?.map((data, index) => (
                        <>
                          <tr key={`desg${index}`}>
                            <td class="text-theme fw-bold">
                              {data?.location_name}
                            </td>
                            <td>{data?.action}</td>

                            <td>{data?.quarter}</td>
                            <td>
                              {data?.status === "Open" ? (
                                <span class="badge bad-status badge-danger">
                                  Open
                                </span>
                              ) : (
                                <span class="badge bad-status badge-success">
                                  Close
                                </span>
                              )}
                            </td>
                            <td>{data?.created_at}</td>
                            <td>{data?.updated_at}</td>
                            <td>
                              <button
                                class="btn btn-blanktd text-primary f-12"
                                type="button"
                                onClick={() => editSkillLocation(data)}
                              >
                                <i class="far fa-edit"></i>
                              </button>
                              <button
                                class="btn btn-blanktd text-danger ml-2 f-12"
                                type="button"
                                onClick={() => {
                                  submit(data?.id);
                                }}
                              >
                                <i class="far fa-trash-alt text-danger"></i>
                              </button>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {skillLocation?.length == 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-sm-5">
                    <div className="sortinglist">
                      Show
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setPageSizeNo(e.target.value);
                        }}
                      >
                        <option value={10} label={10}>
                          10
                        </option>
                        <option value={20} label={20}>
                          20
                        </option>
                        <option value={30} label={30}>
                          30
                        </option>
                        <option value={100} label={100}>
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-left"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showForm ? (
        <>
          <AddSkillLocation
            edit={editMode}
            showForm={showForm}
            onClose={onClose}
            editSkills={skillLocationData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default SkillLocation;
