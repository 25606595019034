import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { changesesstatus, getAllSesmaster } from "../../service/sesService";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import { toast, ToastContainer } from "react-toastify";

function ApproveService() {
  const currentuserdata = useSelector(selectUserData);
  const [statusData, setstatusData] = useState({
    status: "",
    remarks: "",
  });
  const [error, seterror] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [data, setData] = useState(location?.state?.from);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sesData, setSesData] = useState([]);
  const navigate = useNavigate();
  const changeStatus = (status) => {
    let obj = {
      permission_id: finalUrlValue?.A_P?.id,
      transaction_id: data
    }
    if(finalUrlValue?.A_P?.id !== null && finalUrlValue?.A_P?.id !== undefined){
    dispatch(setTracker(obj))
    }
    statusData.status = status;
    statusData.id = data;
    if (statusData.remarks != "") {
      changesesstatus(statusData)
        .then((response) => {
          getSes(
            "",
            currentPage,
            pageSizeNo,
            true,
            "-id",
            data,
            "",
            "",
            "",
            "",
            false,
            false
          );
          if (status == "A") {
            toast.success("SES Approved");
          } else if (status == "D") {
            toast.warning("SES Rejected");
          }
          window.history.go(-1);
          return false;
        })
        .catch((error) => {
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      seterror(true);
    }
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "remarks":
        setstatusData((prev) => ({
          ...prev,
          remarks: event.target.value,
        }));
        seterror(false);
        break;
      case "status":
        setstatusData((prev) => ({
          ...prev,
          status: event.target.value,
        }));
        break;
    }
  };
  const getSes = (
    search,
    page_no,
    page_size,
    paginate,
    sort_by,
    sheet_no,
    created_at,
    created_by,
    status,
    approver_1,
    myses,
    self_approval_data
  ) => {
    getAllSesmaster(
      search,
      page_no,
      page_size,
      paginate,
      sort_by,
      sheet_no,
      created_at,
      created_by,
      status,
      approver_1,
      myses,
      self_approval_data
    )
      .then((response) => {
        setSesData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    getSes(
      "",
      currentPage,
      pageSizeNo,
      true,
      "-id",
      data,
      "",
      "",
      "",
      "",
      false,
      false
    );
  }, []);

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <ul class="policylistnames">
            <li class="active">
              <Link>SES</Link>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h3 class="policyhead">View SES No.: {sesData?.sesno}</h3>
                </div>
              </div>
              <div class="row viewformaftersubmit">
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Requester</label>
                    <p>{sesData?.created_by_name} - {sesData?.createdBy}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>
                      PO No.{" "}
                      <Link
                        to={`/viewpurchase/${sesData?.po_id}`}
                        state={{ from: sesData?.po_id }}
                        target="_blank"
                      >
                        <span class="ml-1">
                          <i class="fas fa-external-link-alt text-theme"></i>
                        </span>
                      </Link>
                    </label>
                    <p>{sesData?.pono}</p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Line Item</label>
                    <p>{sesData?.items?.length}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>PR No</label>
                    <p>122709</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>PR Item</label>
                    <p>{sesData?.items?.length}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Currency</label>
                    <p>{sesData?.currency_symbol}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Document Date</label>
                    <p>{moment(sesData?.docdate).format("Do MMM YYYY")}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Posting Date</label>
                    <p>{moment(sesData?.postingdate).format("Do MMM YYYY")}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Reference</label>
                    <p>{sesData?.reference}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Account Assignment</label>
                    <p>Order</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Total Value</label>
                    <p>{sesData?.totalvalue}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group innergroup">
                    <label>Net Value</label>
                    <p>{sesData?.netvalue}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>Long Text</label>
                    <p>{sesData?.doctext}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>ITM.</th>
                    <th>Serv. No</th>
                    <th>Short Text</th>
                    <th>Qty.</th>
                    <th>Unit</th>
                    <th>Gross Price</th>
                    <th>Currency</th>
                    <th>Cost Center</th>
                    <th>G/L Account</th>
                  </tr>
                </thead>
                <tbody>
                  {sesData?.items?.map((x) => (
                    <tr>
                      <td>{x?.id}</td>
                      <td> {sesData?.sesno}</td>
                      <td>{x?.shorttext}</td>
                      <td>{x?.quantity}</td>
                      <td>{x?.unit_data}</td>
                      <td>{x?.gross_price}</td>
                      <td>{x?.currency_data}</td>
                      <td>{x?.cost_center_data}</td>
                      <td>{x?.glaccount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {sesData?.requestedAt != undefined &&
            sesData?.requestedAt == currentuserdata?.username ? (
              <>
                <div class="col-md-12">
                  <div class="form-group innergroup">
                    <label>
                      Add Comments<span class="text-danger">*</span>
                    </label>
                    <textarea
                      value={
                        sesData?.status != "P"
                          ? sesData?.remarks
                          : statusData?.remarks
                      }
                      disabled={sesData?.status == "P" ? false : true}
                      name="remarks"
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Enter Comments here..."
                    ></textarea>
                    {
                      <div hidden={!error} className="small text-danger">
                        Kindly give comment
                      </div>
                    }
                  </div>
                </div>
                {sesData?.status == "P" && (
                  <div class="col-md-12 pb-3">
                    <button
                      class="btn btn-outline-secondary mr-2"
                      onClick={() => {
                        window.history.go(-1);
                        return false;
                      }}
                    >
                      <i class="fas fa-arrow-circle-left"></i> Cancel
                    </button>
                    <button
                      name="status"
                      value="D"
                      onClick={() => {
                        changeStatus("D");
                      }}
                      class="btn btn-outline-danger mr-2"
                    >
                      <i class="far fa-times-circle"></i> Reject
                    </button>
                    <button
                      name="status"
                      value="A"
                      onClick={() => {
                        changeStatus("A");
                      }}
                      class="btn btn-primary-inner bpi-main"
                    >
                      <i class="far fa-check-square"></i> Approve
                    </button>
                  </div>
                )}
              </>
            ) : (
              <button
                onClick={() => {
                  window.history.go(-1);
                  return false;
                }}
                class="btn btn-outline-secondary mr-2"
              >
                <i class="fas fa-arrow-circle-left"></i> Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApproveService;
