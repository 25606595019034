import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import * as CityService from "../../service/city";
import { Formik } from "formik";
import * as Yup from "yup";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(
      /[^\s*].*[^\s*]/g,
      "* This field should contain minimum 2 alphabet"
    )
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only character"
    )
    .required("*City Name is required")
    .nullable(),
  alias: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "*This field can contain minimum 2 alphabet")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only  character"
    )
    .required("*City Prefix Name is required")
    .nullable(),
  // state_id: Yup.string().required("*Select State").nullable(),
});

const AddCityForm = (props) => {
  const nameForm = "City Master";
  const { edit, showForm, onClose, editState, onAddCallBack } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isselectstate, setselectstate] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [isCityExist, setIsCityExist] = useState("");
  const [isAliasCodeExist, setIsAliasCodeExist] = useState(null);
  const dispatch = useDispatch();
  const [cityData, setCityData] = useState({
    name: "",
    alias: "",
    is_active: true,
    state_id: "",
  });
  const [dropDownData, setDropDownData] = useState({
    // select_state: { value: "", label: "" },
    select_state: { value: "", label: "" },
  });

  const getAllStateList = (paginate) => {
    CityService.getAllStateList(paginate)
      .then((response) => {
        setStateData(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveCity = (values) => {
    if (dropDownData?.select_state.label !== "") {
      setIsLoading(true);
      CityService.saveCity(values)
        .then((response) => {
          if (response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      setselectstate(true);
    }
  };

  const updateCity = (id, values) => {
    if (dropDownData?.select_state.label !== "") {
      setIsLoading(true);
      let datass = values;
      CityService.updateCity(id, datass)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsLoading(false);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      setselectstate(true);
    }
  };

  const dataBlank = () => {
    onClose();
  };

  const isCityExists = (values) => {
    let data = {};
    data["name"] = values.name;
    data["state_id"] = values.state_id;

    CityService.isCityExists(data)
      .then((res) => {
        setIsCityExist(res?.data?.dataList?.is_exist);
        if (res?.data?.dataList?.is_exist === false && values?.id) {
          updateCity(values.id, values);
          AuditLog.addAuditLog(dispatch, nameForm);
        }
        if (
          res?.data?.dataList?.is_exist === false &&
          values?.id === undefined
        ) {
          saveCity(values);
          AuditLog.addAuditLog(dispatch, nameForm);
        }
      })
      .catch((err) => console.log("Error=>", err));
  };

  const isAliasExist = (e, type) => {
    if (e.target.value !== "") {
      let data = {};
      data[type] = e.target.value;
      if (editState?.alias == e.target.value) {
        setIsAliasCodeExist(false);
        return;
      } else {
        CityService.isCityExists(data)
          .then((res) => {
            if (res?.data?.dataList?.is_exist === true) {
              setIsAliasCodeExist(true);
            } else {
              setIsAliasCodeExist(false);
            }
          })
          .catch((err) => console.log("Error=>", err));
      }
    }
  };

  const isExistsValue = (value) => {
    Object.keys(value).forEach((item) => {
      if (item === "state_id") {
        value[item] = Number(dropDownData?.select_state.value);
      }
    });
    let error = false;
    if (edit) {
      if (editState?.name !== value.name) {
        error = true;
      }
      if (editState?.state_id !== value.state_id) {
        error = true;
      }
    }
    if (error === false && value?.id) {
      updateCity(value.id, value);
      AuditLog.addAuditLog(dispatch, nameForm);
    }
    if (error === true && value?.id) {
      isCityExists(value);
    }
    if (error === false && value?.id === undefined) {
      isCityExists(value);
    }
  };

  const stateListData = (optionsStateList) => {
    // return optionsStateList?.map((item, index) => (
    //   <option
    //     key={`state${index}`}
    //     value={`${item?.id}`}
    //     label={`${item?.name}`}
    //   />
    // ));
    let opt = optionsStateList?.map((item, index) => {
      return {
        key: `org${index}`,
        value: item?.id,
        label: item?.name,
      };
    });
    return opt;
  };

  const handleDropData = (value, name) => {
    switch (name) {
      case "select_state":
        setDropDownData((prev) => ({
          ...prev,
          select_state: value,
        }));
        setselectstate(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getAllStateList(false);
  }, []);

  useEffect(() => {
    if (editState) {
      Object.keys(editState).forEach((item) => {
        cityData[item] = editState[item];
        if (item === "state_id" && editState["state_is_active"] === true) {
          setDropDownData((prev) => ({
            ...prev,
            select_state: { ...prev.select_state, value: editState[item] },
          }));
          //   cityData[item] =
          //     editState["state_is_active"] === false ? "0" : editState[item];
          //   if (editState["state_is_active"] === false) {
          //     cityData[item] = "";
          //   }
          // } else {
          //   cityData[item] = editState[item];
        }
        if (item === "state_name" && editState["state_is_active"] === true) {
          setDropDownData((prev) => ({
            ...prev,
            select_state: { ...prev.select_state, label: editState[item] },
          }));
        }
      });
    }
  }, [editState]);

  return (
    <>
      <Formik
        initialValues={cityData}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (isAliasCodeExist !== true) {
            isExistsValue(values);
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title> {edit === true ? "Edit" : "Add"} City</Modal.Title>
            </Modal.Header>

            <form onSubmit={handleSubmit}>
              <>
                {console.log(editState, "editState")}
                <Modal.Body>
                  <div class="row justify-content-center">
                    <ToastContainer autoClose={1000} />
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              Select State<span class="text-danger">*</span>
                            </label>
                            {/* <select
                              class="form-control newbgselect main-pointer "
                              name="state_id"
                              type="number"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                setCityData({
                                  ...cityData,
                                  state_id: e.target.value,
                                });
                              }}
                              value={Number(values.state_id)}
                            >
                              <option value="0" label="Select State" disabled />

                              {stateListData(stateData)}
                            </select> */}
                            <Select
                              options={stateListData(stateData)}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="select_state"
                              placeholder="Select State "
                              onBlur={handleBlur}
                              onChange={(newValue, action) => {
                                // handleChange(newValue)
                                handleDropData(newValue, action.name);
                              }}
                              value={dropDownData?.select_state}
                            />
                            {isselectstate ? (
                              <div className="small text-danger">
                                {"*Please select state"}
                              </div>
                            ) : null}
                            {/* {editState?.} */}
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group innergroup">
                            <label>
                              City Name<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter City Name"
                              maxLength={30}
                              name="name"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              value={values.name}
                            />
                            {touched.name && errors.name ? (
                              <div className="small text-danger">
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group innergroup">
                            <label>Alias City Prefix</label>
                            <span class="text-danger">*</span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter City prefix"
                              maxLength={5}
                              name="alias"
                              onChange={handleChange}
                              onBlur={(e) => {
                                isAliasExist(e, "alias");
                                handleBlur(e);
                              }}
                              value={values.alias}
                            />

                            {/* {touched.alias && errors.alias ? (
                              <div className="small text-danger">
                                {errors.alias}
                              </div>a
                            ) : null} */}

                            {touched.alias && errors.alias ? (
                              <div className="small text-danger">
                                {errors.alias}
                              </div>
                            ) : isAliasCodeExist === true ? (
                              <div className="small text-danger">
                                City Code already exists
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group innergroup">
                            <label className="d-block">Status</label>
                            <label
                              className="logCheck d-inline-block mr-4"
                              htmlFor="active"
                            >
                              Active
                              <input
                                type="radio"
                                name="is_active"
                                id="active"
                                value={values.is_active}
                                onChange={() => {
                                  setFieldValue("is_active", true);
                                }}
                                checked={values.is_active === true}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label
                              className="logCheck d-inline-block"
                              htmlFor="Inactive"
                            >
                              Inactive
                              <input
                                type="radio"
                                name="is_active"
                                id="Inactive"
                                value={values.is_active}
                                onChange={(e) => {
                                  setFieldValue("is_active", false);
                                }}
                                checked={values.is_active === false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                {isCityExist && (
                  <Alert variant="danger" className="mb-5 mt-2 text-center">
                    <i className="fa fa-exclamation-triangle"></i>
                    &nbsp;City Name Already Exists.
                  </Alert>
                )}
                <Modal.Footer>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      as="span"
                      role="status"
                      aria-hidden="true"
                    ></Spinner>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          dataBlank();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary-inner bpi-main"
                      >
                        Submit
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddCityForm;
