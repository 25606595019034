import React, { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import AllUrlsCashbook from "./AllUrlsCashbook";
import CustomPagination from "./CustomPagination";
import AddReceipt from "./add-receipt";
import * as ReceiptService from "../../service/cashbookService";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ViewCashbookReceipt from "./ViewCashbookReceipt";
import * as searchPoService from "../../service/searchpo";
import moment from "moment/moment";

function CashbookReceipt() {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const initialValues = {
    list: [],
    limit: 10,
    page: 1,
    totalPages: 0,
    filter: {},
  };
  const [receipts, setReceipts] = useState(initialValues);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [receiptList, setReceiptList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editModuleData, setEditModuleData] = useState(null);
  const [addnewshow, setAddNewShow] = useState(false);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [nextSapSyncingTime, setNextSapSyncingTime] = useState('')

  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const getReceipts = () => {
    try {
    } catch (error) {
      toast.warn(error.message);
    }
  };

  const getReceiptData = (search, page_no, page_size, sort_by, paginate) => {
    ReceiptService.getReceiptData(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setReceiptList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getReceiptData(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const getExportDetails = (search, page_no, page_size, sort_by, paginate) => {
    ReceiptService.getExportValueDemand(
      handleKey(search, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CashbookReceipt.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getReceiptData(data, currentPage, pageSizeNo, sortData.sort_by, true);
    }
  };
  const addNewVendorCashbook = () => {
    setAddNewShow(true);
    setEditMode(false);
    setEditModuleData(null);
  };

  const onClose = () => {
    setEditModuleData(null);
    setAddNewShow(false);
  };
  const onAddCallBack = (response) => {
    setAddNewShow(false);
    getReceiptData(searchStr, 1, pageSizeNo, sortData.sort_by, true);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReceiptData(searchStr, activePage, pageSizeNo, sortData.sort_by, true);
  };
  const editDemand = (eData) => {
    setAddNewShow(true);
    setEditMode(true);
    setEditModuleData(eData);
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };
  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getReceiptData(searchStr, currentPage, pageSizeNo, sortData.sort_by, true);
  };
  useEffect(() => {
    dispatch(setDisplayLoader("Display"));
    getReceiptData(searchStr, 1, pageSizeNo, sortData.sort_by, true);
    getSapSchedule()
  }, [pageSizeNo]);

  const getSapSchedule = () =>{
    searchPoService.get_sap_schedule('sync_insert_receipts_in_sap').then((response)=>{
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
    searchPoService.get_sap_schedule('sync_reverse_receipt_entry').then((response)=>{
      let next_sap_syncing_time = response?.data?.dataList?.result
      setNextSapSyncingTime(next_sap_syncing_time)
    })
  }

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <AllUrlsCashbook /> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Manage Receipts</h4>
                  {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/">Transactions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Receipts</Breadcrumb.Item>
                  </Breadcrumb> */}
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on{" "}
                          <strong>
                            {" "}
                            Amount, <br /> status
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Amount,  Doc No,status..."
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                      />
                      <span class="input-group-text border-0">
                        {searchStr !== "" ? (
                          <i
                            class="far fa-times-circle cursor-pointer"
                            onClick={() => {
                              closeButtonCallBack();
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>

                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            disabled={receiptList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => addNewVendorCashbook()}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Receipt
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <b style={{fontSize:"13px"}}>Next SAP Posting:</b> <span className="mr-3" style={{fontSize:"13px"}}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                  <b style={{fontSize:"13px"}}>Next Reverse SAP Posting:</b> <span className="" style={{fontSize:"13px"}}>{moment.utc(nextSapSyncingTime).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th
                      onClick={(e) => {
                        handlerData("id");
                      }}
                      className={
                        sortData["id"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["id"]
                      }
                    >
                      Request ID
                    </th>
                    <th>Receipt Gl</th>
                    <th
                      onClick={(e) => {
                        handlerData("created_by");
                      }}
                      className={
                        sortData["created_by"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["created_by"]
                      }
                    >
                      Created By
                    </th>
                    <th
                      onClick={(e) => {
                        handlerData("amount");
                      }}
                      className={
                        sortData["amount"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["amount"]
                      }
                    >
                      Amount
                    </th>
                    <th>Sap Number</th>
                    <th>SAP Message</th>
                    <th>SAP Reverse Message</th>
                    <th>Reverse Doc No</th>

                    <th
                      onClick={(e) => {
                        handlerData("status");
                      }}
                      className={
                        sortData["status"] === undefined
                          ? "unsortcls main-pointer"
                          : sortData["status"]
                      }
                    >
                      Status
                    </th>
                    <th>Approver</th>
                  </tr>
                </thead>
                <tbody>
                  {receiptList?.length ? (
                    receiptList?.map((data, index) => (
                      <tr>
                        <td>
                          {/* {data?.status === "A" && data?.status === "R" ? (
                            ""
                          ) : ( */}
                          {data?.status == "reverse" || data?.status == "A" ? (
                            ""
                          ) : (
                            <button class="btn btn-blanktd text-primary f-12">
                              <i
                                class="far fa-edit"
                                onClick={() => {
                                  editDemand(data);
                                }}
                              ></i>
                            </button>
                          )}

                          {/* )} */}
                        </td>
                        <td
                          class="fw-bold text-theme"
                          onClick={() => {
                            onView(data);
                          }}
                        >
                          PICASHDE{data?.id}{" "}
                        </td>
                        <td>
                          {data?.receipt_gl_code} - {data?.receipt_gl_desc}
                        </td>
                        <td>{data?.created_by}</td>
                        <td>{data?.amount}</td>
                        <td>{data?.document_no ?? "-"}</td>

                        <td>
                          <Tooltip title={data?.sap_message} position="bottom">
                            {data?.sap_message ?? "--"}
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            title={data?.sap_reverse_message}
                            position="bottom"
                          >
                            {data?.sap_reverse_message ?? "--"}
                          </Tooltip>
                        </td>
                        <td> {data?.reverse_document_no ?? "--"}</td>
                        <td>
                          {data?.status == "A" ? (
                            <span class="badge bad-status badge-success">
                              Approved{" "}
                            </span>
                          ) : data?.status == "P" || data?.status == null ? (
                            <span class="badge bad-status badge-warning">
                              Pending{" "}
                            </span>
                          ) : data?.status == "R" ? (
                            <span class="badge bad-status badge-danger">
                              Reversed{" "}
                            </span>
                          ) : (
                            <span class="badge bad-status badge-danger">
                              Reversed{" "}
                            </span>
                          )}
                        </td>
                        <td>
                          {`${userData?.reporting_to_fname} ${userData?.reporting_to_lname} `}
                          ({userData?.reporting_to_name})
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {receiptList?.length == 0 ? (
              <td colSpan={12}> No Record Found</td>
            ) : (
              <div class="row">
                <div class="col-sm-5">
                  <div class="sortinglist">
                    Show
                    <select
                      class="form-control main-pointer"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
            {/* <CustomPagination
              onChange={(val) => setReceipts((pre) => ({ ...pre, limit: val }))}
              handlePageClick={(page) =>
                setReceipts((pre) => ({ ...pre, page }))
              }
              pageCount={receipts.totalPages}
            /> */}
          </div>
        </div>
      </div>
      {addnewshow && (
        <AddReceipt
          edit={editMode}
          addnewshow={addnewshow}
          onClose={onClose}
          onAddCallBack={onAddCallBack}
          editModuleData={editModuleData}
        />
      )}
      {viewForm ? (
        <>
          <ViewCashbookReceipt
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default CashbookReceipt;
