import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Dropdown
} from "react-bootstrap";
import Select from "react-select";
import * as MaterialService from "../../service/materialService";
import CustomPagination from "./CustomPagination";
import CustomMenu from "./CustomMenu";
import CustomBreadcrumb from "./CustomBreadcrumb";
import {
  setDisplayLoader
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";

function MaterialRequest() {
  const dispatch = useDispatch();
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Material Request' }
  ]

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  /* isolated states */
  const [show, filtersetShow] = useState(false);

  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [pageCount, setpageCount] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: 'id'
  });
  const [searchStr, setSearchStr] = useState("");

  const handleSearch = (e) => {
    getData(
      e.target.value.toLowerCase(),
      filter,
      1,
      pageSizeNo,
      "id",
      true
    );
    setSearchStr(e.target.value);
  };

  const handlePageClick = (action) => {
    const activePage = action.selected + 1;
    setCurrentPage(activePage);
    getData(
      searchStr,
      filter,
      activePage,
      pageSizeNo,
      "id",
      true
    );
  };

  const handleKey = (search, filter, page_no, page_size, sort_by, paginate) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      filter !== "" &&
      filter !== undefined &&
      filter !== null &&
      filter !== {}
    ) {
      queryParm =
        queryParm
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    return queryParm;
  };

  const getData = async (
    search,
    filter,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    
    await MaterialService.getRequests(
      handleKey(search, filter, page_no, page_size, sort_by, paginate)
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        if (response.data.code === 200) {
          setList(response?.data?.dataList?.result);
          setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData(
      searchStr,
      filter,
      currentPage,
      pageSizeNo,
      filter.sort_by,
      true
    );
  }, [pageSizeNo]);
  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Material Request</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <i class="fas fa-print"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-alt"></i>CSV
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-file-pdf"></i>Pdf
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i class="far fa-copy"></i>Copy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <Link to="/addnewmaterial" class="btn btn-primary-inner">
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add New
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Description</th>
                    <th>Plant</th>
                    <th>Mat. Group</th>
                    <th>Mat. Type</th>
                    <th>Unit</th>
                    <th>Material No.</th>
                    <th>Status</th>
                    <th>Approved / Declined by</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="fw-bold">
                      <Link to="/approve-mat-req" class="text-theme">
                        Handheld digital colony counter
                      </Link>
                    </td>
                    <td>GG01</td>
                    <td>C001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94029651</td>
                    <td>
                      <span class="badge bad-status badge-success">
                        Approved
                      </span>
                    </td>
                    <td>Ajitabh Singh</td>
                    <td>Ok</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="fw-bold">
                      <Link to="/approve-mat-req" class="text-theme">
                        Handheld digital colony counter
                      </Link>
                    </td>
                    <td>GG01</td>
                    <td>C001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94029651</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        Declined
                      </span>
                    </td>
                    <td>Ajitabh Singh</td>
                    <td>Ok</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="fw-bold">
                      <Link to="/approve-mat-req" class="text-theme">
                        Handheld digital colony counter
                      </Link>
                    </td>
                    <td>GG01</td>
                    <td>C001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94029651</td>
                    <td>
                      <span class="badge bad-status badge-success">
                        Approved
                      </span>
                    </td>
                    <td>Ajitabh Singh</td>
                    <td>Ok</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="fw-bold">
                      <Link to="/approve-mat-req" class="text-theme">
                        Handheld digital colony counter
                      </Link>
                    </td>
                    <td>GG01</td>
                    <td>C001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94029651</td>
                    <td>
                      <span class="badge bad-status badge-warning">
                        Pending
                      </span>
                    </td>
                    <td>Ajitabh Singh</td>
                    <td>Ok</td>
                  </tr>
                  <tr>
                    <td>
                      <button class="btn btn-blanktd text-primary f-12">
                        <i class="far fa-edit"></i>
                      </button>
                      <button class="btn btn-blanktd text-danger ml-2 f-12">
                        <i class="far fa-trash-alt text-danger"></i>
                      </button>
                    </td>
                    <td class="fw-bold">
                      <Link to="/approve-mat-req" class="text-theme">
                        Handheld digital colony counter
                      </Link>
                    </td>
                    <td>GG01</td>
                    <td>C001</td>
                    <td>ERSA</td>
                    <td>NO</td>
                    <td>94029651</td>
                    <td>
                      <span class="badge bad-status badge-danger">
                        Declined
                      </span>
                    </td>
                    <td>Ajitabh Singh</td>
                    <td>Ok</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <CustomPagination
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />

          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              Material Description<span class="text-danger">*</span>
            </label>
            <input class="form-control" placeholder="Enter Description" />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Plant<span class="text-danger">*</span>
            </label>
            <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select class="form-control newbgselect">
              <option>Select</option>
              <option>Approved</option>
              <option>Declined</option>
              <option>Pending</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={filterclose}>
            Cancel
          </button>
          <button class="btn btn-primary-inner bpi-main">Apply</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MaterialRequest;
