import React, { useState, useEffect } from "react";
import AddNewVehicle from "./addNewVehicle";
import ReactPaginate from "react-paginate";
import * as vehicleService from "../../service/vehiclelog";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Modal, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";

const AddNewVehicleIndex = () => {
  const dispatch = useDispatch();
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editVehicleData, setEditVehicleData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const [vehicleValues, setVehicleValues] = useState();
  const [searchStr, setSearchStr] = useState();
  const [showFilter, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [vehicleDataFilter, setVehicleDataFilter] = useState({ status: "" });

  const handleKey = (
    search,
    is_active,
    pageNo,
    page_size,
    sort_by,
    paginate,
    user_id,
    is_export
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_active !== "" && is_active !== undefined && is_active !== null) {
      queryParm = queryParm + "&is_active=" + is_active;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (is_export !== "" && is_export !== undefined && is_export !== null) {
      queryParm = queryParm + "&is_export=" + is_export;
    }
    return queryParm;
  };

  const getVehicleData = (
    search,
    is_active,
    pageNo,
    page_size,
    sort_by,
    paginate,
    user_id,
    is_export
  ) => {
    vehicleService
      .getVehicleData(
        handleKey(
          search,
          is_active,
          pageNo,
          page_size,
          sort_by,
          paginate,
          user_id,
          is_export
        )
      )
      .then((response) => {
        setVehicleValues(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getVehicleData(
        e.target.value.toLowerCase(),
        "",
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        false
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getVehicleData(data, "", currentPage, pageSizeNo, "-id", true, "", false);
    }
  };

  const onCloseButtonClick = () => {
    setShow(false);
  };

  const onAddCallBack = () => {
    getVehicleData("", "", currentPage, pageSizeNo, "-id", true, "", false);
    setShow(false);
  };

  const editData = (data) => {
    setShow(true);
    setEditVehicleData(data);
    setEditMode(true);
  };

  const onAddButtonClick = () => {
    setShow(true);
    setEditMode(false);
    setEditVehicleData(null);
  };

  const filterClose = () => {
    filterclose();
  };

  const mainDataFilter = () => {
    const stats = vehicleDataFilter.status;
    getVehicleData(
      searchStr,
      stats,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      false
    );
    filterClose();
  };

  const exportExcel = (
    search,
    is_active,
    pageNo,
    page_size,
    sort_by,
    paginate,
    user_id,
    is_export
  ) => {
    dispatch(setDisplayLoader("Display"));
    vehicleService
      .exportResExcel(
        handleKey(
          search,
          is_active,
          pageNo,
          page_size,
          sort_by,
          paginate,
          user_id,
          is_export
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vehicle_res.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getVehicleData(
      searchStr,
      vehicleDataFilter.status,
      activePage,
      pageSizeNo,
      "-id",
      true,
      "",
      false
    );
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(vehicleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setVehicleDataFilter(ClearData);
    filterClose();
    getVehicleData(searchStr, "", 1, pageSizeNo, "-id", true, "", false);
  };

  useEffect(() => {
    getVehicleData("", "", currentPage, pageSizeNo, "-id", true, "", false);
  }, [pageSizeNo]);

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Add New Vehicle</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on
                      <strong>
                        Vehicle Number,
                        <br />
                        Location
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Vehicle Number, Location..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        type="button"
                        // disabled={otherExpData.length !== 0 ? false : true}
                        style={{ border: 0, background: "none" }}
                        onClick={() =>
                          exportExcel(
                            "",
                            vehicleDataFilter.status,
                            currentPage,
                            pageSizeNo,
                            "-id",
                            true,
                            "",
                            true
                          )
                        }
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  onClick={() => {
                    filteropen();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={onAddButtonClick}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Vehicle Number</th>
                <th>Location</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vehicleValues?.length ? (
                vehicleValues?.map((data, index) => (
                  <>
                    <tr>
                      <td>{data?.vehicle_no}</td>
                      <td>{data?.name}</td>
                      <td>
                        {data?.is_active === false ? "Terminated" : "Activated"}
                      </td>
                      <td>
                        {`${data?.created_by_username} (${moment(
                          data?.created_at
                        )
                          .utc()
                          .format("DD-MM-YYYY")})`}
                      </td>

                      <td>
                        <button
                          className="btn btn-blanktd text-primary"
                          type="button"
                          onClick={() => editData(data)}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {vehicleValues?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={showFilter}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Select Status</label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  status: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={vehicleDataFilter.status}
            >
              <option value="" label="Select" disabled />
              <option value={true} label="Activated" />
              <option value={false} label="Terminated" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {show && (
        <AddNewVehicle
          show={show}
          onCloseButtonClick={onCloseButtonClick}
          edit={editMode}
          editVehicleData={editVehicleData}
          onAddCallBack={onAddCallBack}
        />
      )}
    </>
  );
};

export default AddNewVehicleIndex;
