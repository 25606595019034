import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import { uploadFile } from "../../service/Cms-newsService";
import {
  companySignoff,
  getbl,
  getExportGLData,
  ImportBl,
  ImportBlRecords,
} from "../../service/glService";
import * as moment from "moment";
import ReactPaginate from "react-paginate";
import { toast, ToastContainer } from "react-toastify";
import { getGlCodeList, getUserRmList } from "../../service/jv_service";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
  selectUserData,
} from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function GIBalancesheet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const userData = useSelector(selectUserData);

  let condition = "false";
  const [filterData, setfilterData] = useState({
    approver: { value: 0 },
    requester: { value: 0 },
    glcode: { value: 0 },
    year: "",
    month: "",
    status: "",
  });
  const resetFilterData = () => {
    setfilterData({
      approver: { value: 0 },
      requester: { value: 0 },
      glcode: { value: 0 },
      year: "",
      month: "",
      status: "",
    });
  };
  const [companyFlag, setcompanyFlag] = useState({});
  const [glcode, setGlcode] = useState([]);
  const [userlist, setuserlist] = useState([]);
  const [BLData, setGLData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [records, setRecords] = useState({});
  const [textColor, setTextColor] = useState("");
  const [search, setSearch] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [imgSize, setImgSize] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [uploadAttachments, setUploadAttachments] = useState({});
  const [exDataDown, setExDataDown] = useState({});
  const [importshow, importsetShow] = useState(false);
  const importuser = () => {
    importsetShow(true);
    setRecords({});
    setExDataDown({});
  };
  const importclose = () => importsetShow(false);
  const onProfileChange = (e) => {
    const files = e.target.files[0];
    if (files.size < 1033415 || files.size === 1033415) {
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      uploadFile(fileData).then((response) => {
        setUploadAttachments(response.data);
        if (response?.data?.status === false) {
          toast.error(response?.data?.message)
        }
      });
    } else {
      setImgSize(true);
    }
  };
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const getBL = (
    pageNo,
    pageSize,
    sortBy,
    paginate,
    search,
    status,
    glcode,
    level,
    user_id,
    blcode,
    month,
    year,
    approvers
  ) => {
    dispatch(setDisplayLoader("Display"));

    getbl(
      pageNo,
      pageSize,
      sortBy,
      paginate,
      search,
      status,
      glcode,
      level,
      user_id,
      blcode,
      month,
      year,
      approvers
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setGLData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setDataCount(response?.data?.dataList?.paginated_data?.approval_count);
        setcompanyFlag(response?.data?.dataList?.companylevelSignoff);
        if (response?.data?.dataList?.companylevelSignoff === true) {
          setBackgroundColor("#00bd47");
          setTextColor("black");
        } else {
          setBackgroundColor("#f0ad4e");
          setTextColor("black");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extensio
    document.body.appendChild(link);
    link.click();
  };

  const importsRecords = () => {
    if (JSON.stringify(uploadAttachments) !== JSON.stringify({})) {
      ImportBlRecords(uploadAttachments)
        .then((response) => {
          getBL(
            currentPage,
            pageSizeNo,
            "-id",
            true,
            "",
            "",
            0,
            "",
            0,
            0,
            "",
            "",
            0
          );
          condition = "false";
          setRecords(response?.data?.records?.record_saved);
          toast.success(
            response?.data?.records?.record_saved?.success +
            " GL uploaded successfully"
          );
        })
        .catch((error) => {
          toast.error("Some error occurred please try again");
          condition = "false";
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            "failed"
          );
        });
    } else {
      toast.warning("Kindly select the file to upload");
    }
  };
  const imports = () => {
    if (condition == "false") {
      condition = "true";
      if (JSON.stringify(uploadAttachments) !== JSON.stringify({})) {
        ImportBl(uploadAttachments)
          .then((response) => {
            importsRecords();
            // setRecords({})

            console.log(response);
            setExDataDown(response.data);
            document.getElementById("blsheet").value = "";
            setUploadAttachments({});
          })
          .catch((error) => {
            toast.error("Some error occurred please try again");
            condition = "false";
            console.log(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "failed"
            );
          });
      } else {
        toast.warning("Kindly select the file to upload");
      }
    }
  };
  const levelSignoff = () => {
    companySignoff()
      .then((response) => {
        if (response?.data?.dataList?.flag == false) {
          confirmAlert({
            message: response?.data?.dataList?.msg,
            buttons: [
              {
                label: "Ok",
              },
            ],
          });
        } else {
          toast.success(response?.data?.dataList?.msg);
        }
        getBL(
          currentPage,
          pageSizeNo,
          "-id",
          true,
          "",
          "",
          0,
          "",
          0,
          0,
          "",
          "",
          0
        );
      })
      .catch((error) => {
        toast.error("Some error occurred please try again");
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  const companylevelsignoff = () => {
    if (!companyFlag) {
      confirmAlert({
        message: "Few requests are under approval, are you sure to Sign Off",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              levelSignoff();
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };
  const Glcode = () => {
    getGlCodeList()
      .then((response) => {
        let glcode = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.gl_account + "(" + x?.short_desc + ")",
          };
          glcode.push(data);
        });
        setGlcode(glcode);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };
  useEffect(() => {
    setRecords({});
    setUploadAttachments({});
    getBL(currentPage, pageSizeNo, "-id", true, "", "", 0, "", 0, 0, "", "", 0);
    Glcode();
    getUserList();
  }, []);
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getBL(activePage, pageSizeNo, "-id", true, "", "", 0, "", 0, 0, "", "", 0);
  };
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "approver":
        setfilterData((prev) => ({
          ...prev,
          approver: event,
        }));
        break;
      case "requester":
        setfilterData((prev) => ({
          ...prev,
          requester: event,
        }));
        break;
      case "glcode":
        setfilterData((prev) => ({
          ...prev,
          glcode: event,
        }));
        break;

      case "status":
        setfilterData((prev) => ({
          ...prev,
          status: event.target.value,
        }));
        break;
      case "year":
        setfilterData((prev) => ({
          ...prev,
          year: event.target.value,
        }));
        break;
      case "month":
        setfilterData((prev) => ({
          ...prev,
          month: event.target.value,
        }));
        break;
    }
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    getBL(
      currentPage,
      pageSizeNo,
      "-id",
      true,
      search,
      filterData?.status,
      filterData?.glcode.value,
      "",
      filterData?.requester.value,
      0,
      filterData?.month,
      filterData?.year,
      filterData?.approver.value
    );
    filterclose();
  };
  const resetFilter = () => {
    setFilterApplied(false);
    filterclose();
    resetFilterData();
    getBL(currentPage, pageSizeNo, "-id", true, "", "", 0, "", 0, 0, "", "", 0);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getBL(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        e.target.value.toLowerCase(),
        filterData?.status,
        filterData?.glcode.value,
        "",
        filterData?.requester.value,
        0,
        filterData?.month,
        filterData?.year,
        filterData?.approver.value
      );
      setSearch(e.target.value);
    }
  };
  useEffect(() => {
    getBL(currentPage, pageSizeNo, "-id", true, "", "", 0, "", 0, 0, "", "", 0);
  }, [pageSizeNo]);

  const getExportDetails = () => {
    getExportGLData(
      handleKey(
        currentPage,
        pageSizeNo,
        "-id",
        true,
        search,
        filterData?.status,
        filterData?.glcode.value,
        "",
        filterData?.requester.value,
        0,
        filterData?.month,
        filterData?.year,
        filterData?.approver.value
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GLBalanceSheet.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message
        );
        console.log("error", error);
      });
  };

  const handleKey = (
    page_no,
    page_size,
    sort_by,
    paginate,
    search,
    status,
    glcode,
    level,
    user_id,
    blcode,
    month,
    year,
    approvers
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (
      glcode !== "" &&
      glcode !== undefined &&
      glcode !== null
    ) {
      queryParm = queryParm + "&glcode=" + glcode;
    }
    if (level !== "" && level !== undefined && level !== null) {
      queryParm = queryParm + "&level=" + level;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (blcode !== "" && blcode !== undefined && blcode !== null) {
      queryParm = queryParm + "&blcode=" + blcode;
    }
    if (month !== "" && month !== undefined && month !== null) {
      queryParm = queryParm + "&month=" + month;
    }
    if (year !== "" && year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (approvers !== "" && approvers !== undefined && approvers !== null) {
      queryParm = queryParm + "&approvers=" + approvers;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }

    return queryParm;
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div class="row">
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/GlMapping">Mapped GL</Link>
            </li>
            <li class="active">
              <Link to="/gi-balancesheet">GL Balancesheet</Link>
            </li>
            <li>
              <Link to="/gi-approval">GL Balancesheet Approval</Link>{" "}
              <span class="numberpurchse float-right mr-2">{dataCount}</span>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">GL Balancesheet Listing</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-4">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip
                        className="bs-tooltip-end tooltip"
                        id="overlay-example"
                      >
                        <div class="tooltip-inner text-left">
                          Search on
                          <strong>
                            {" "}
                            Plant,
                            <br />
                            Pur.Org,
                            <br />
                            vendor,
                            <br />
                            material,
                            <br />
                            category,
                          </strong>
                        </div>
                      </Tooltip>
                    }
                  >
                    <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        onChange={(e) => {
                          handleSearch(e);
                          setCurrentPage(1);
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
                <div class="col-lg-8">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={getExportDetails}>
                          <i class="far fa-file-excel"></i>Excel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={filteropen}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    {finalUrlValue?.A && (
                      <>
                        <button class="btn btn-danger" onClick={importuser}>
                          <img src="images/upload.png" alt="" class="mr-3" />
                          Import Data
                        </button>
                        <button
                          style={{
                            background: backgroundColor,
                            color: textColor,
                          }}
                          onClick={() => companylevelsignoff()}
                          class="btn btn-success"
                        >
                          <i class="far fa-check-circle mr-1"></i>Company Level
                          Signoff
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured tablcuredlastnew">
                <thead>
                  <tr>
                    <th>BL Code</th>
                    <th>GL Code</th>
                    <th>GL Desc</th>
                    <th>Sap Amount</th>
                    <th>Balance</th>
                    <th>Variance</th>
                    <th>Currency</th>
                    <th>FC Amount</th>
                    <th>Schedules</th>
                    <th>User Id</th>
                    <th>Reviewer Id</th>
                    <th>Status</th>
                    <th>Level</th>
                    <th>Month</th>
                    <th>Created On</th>
                    <th>Remarks</th>
                    <th>Approved On</th>
                  </tr>
                </thead>
                <tbody>
                  {BLData?.map((x) => (
                    <tr>
                      <td class="text-theme">
                        <Link
                          to="/approve-gi"
                          state={{ from: x?.id }}
                          class="fw-bold text-theme"
                        >
                          {x?.id}
                        </Link>
                      </td>
                      <td>{x?.gl_code}</td>
                      <td>{x?.Gl_desc}</td>
                      <td>{x?.sap_amount}</td>
                      <td>{x?.balance}</td>
                      <td>{x?.sap_amount - x?.balance}</td>
                      <td>{x?.cureency_code}</td>
                      <td>{x?.fc_amount}</td>
                      <td>No</td>

                      <td>
                        <Tooltip
                          title={x?.user_name + "(" + x?.user_username + ")"}
                          position="bottom"
                        >
                          {x?.user_name + "(" + x?.user_username + ")"}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          title={x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                          position="bottom"
                        >
                          {x?.Reviewer_name + "(" + x?.Reviewer + ")"}
                        </Tooltip>
                      </td>
                      <td>
                        {x?.status == "A" ? (
                          <span class="badge bad-status badge-success">
                            Approved
                          </span>
                        ) : x?.status == "P" ? (
                          <span class="badge bad-status badge-warning">
                            Pending
                          </span>
                        ) : (
                          <span class="badge bad-status badge-danger">
                            Rejected
                          </span>
                        )}
                      </td>
                      <td>{x?.level}</td>
                      <td>{x?.month}</td>
                      <td>
                        {moment
                          .utc(x?.created_at)
                          .format("Do MMM YYYY, h:mm:ss a")}
                      </td>
                      <td>{x?.remarks}</td>
                      <td>
                        {x?.approval_date2 != null &&
                          moment
                            .utc(x?.approval_date2)
                            .format("Do MMM YYYY, h:mm:ss a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div class="row">
              <div class="col-sm-5">
                <div class="sortinglist">
                  Show
                  <select
                    class="form-control main-pointer"
                    onChange={(e) => {
                      setPageSizeNo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value={10} label={10}>
                      10
                    </option>
                    <option value={20} label={20}>
                      20
                    </option>
                    <option value={30} label={30}>
                      30
                    </option>
                    <option value={100} label={100}>
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-7">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-left"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>
              GL Code<span class="text-danger">*</span>
            </label>
            <Select
              options={glcode}
              value={filterData?.glcode}
              className="basic-multi-select"
              classNamePrefix="select"
              name="glcode"
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Select User<span class="text-danger">*</span>
            </label>
            <Select
              name="requester"
              value={filterData?.requester}
              options={userlist}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Reviewer<span class="text-danger">*</span>
            </label>
            <Select
              options={userlist}
              value={filterData?.approver}
              name="approver"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(newValue, action) => {
                handleChange(newValue, action.name);
              }}
            />
          </div>

          <div class="form-group innergroup">
            <label>
              Year<span class="text-danger">*</span>
            </label>
            <select
              onChange={(newValue) => {
                handleChange(newValue, "year");
              }}
              name="year"
              value={filterData?.year}
              class="form-control newbgselect"
            >
              <option>Select</option>
              <option>2023</option>
              <option>2022</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Month<span class="text-danger">*</span>
            </label>
            <select
              onChange={(newValue) => {
                handleChange(newValue, "month");
              }}
              name="month"
              value={filterData?.month}
              class="form-control newbgselect"
            >
              <option value="">Select Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <div class="form-group innergroup">
            <label>
              Select Status<span class="text-danger">*</span>
            </label>
            <select
              onChange={(newValue) => {
                handleChange(newValue, "status");
              }}
              name="status"
              value={filterData?.status}
              class="form-control newbgselect"
            >
              <option value="">Select Month</option>
              <option value="P">Pending</option>
              <option value="R">Rejected</option>
              <option value="A">Approved</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              resetFilter();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => mainDataFilter()}
            class="btn btn-primary-inner bpi-main"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={importshow}
        onHide={importclose}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title> Upload GL Balancesheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-file-upload fa-2x"></i>
                  <p>Upload the template file with updated data</p>
                  <button class="btn btn-primary-inner">
                    DRAG AND DROP HERE OR CHOOSE FILE
                  </button>
                  <div className="mt-3 downloadtemplate">
                    <a href="/Balancesheet.xlsx" class="editbtn">
                      <i class="far fa-file-excel"></i> DOWNLOAD TEMPLATE
                    </a>
                  </div>
                  <input
                    id="blsheet"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    className="form-control"
                    onChange={(e) => onProfileChange(e)}
                  />
                </div>

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    {uploadAttachments?.original_name}
                    <i class="far fa-times-circle"></i>
                  </span>
                  <button
                    onClick={() => imports()}
                    class="btn btn-primary-inner"
                  >
                    Proceed to Upload
                  </button>
                </div>

                {/* <div className="drapdroparea upload-btn-wrapper text-center">
                  <span class="badge bad-status mb-3 badge-warning">
                    employee_user.csv <i class="far fa-times-circle"></i>
                  </span>
                  <button class="btn btn-primary-inner" disabled>
                    Proceed to Upload
                  </button>
                  <div class="w-75 mt-3">
                    <ProgressBar
                      striped
                      variant="success"
                      animated
                      now={45}
                      label="40%"
                    />
                  </div>
                </div> */}

                <div className="drapdroparea upload-btn-wrapper text-center">
                  <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
                  <p>
                    The system was unable to read few records from the uploaded
                    file.
                    <br />
                    Please correct or remove those records from the file and
                    upload again.
                  </p>
                  <span class="badge bad-status mb-3 badge-success">
                    GL Succeded:{records?.success}
                  </span>
                  <span class="badge bad-status mb-3 badge-danger">
                    GL Failed: {records?.failure}
                  </span>
                  <p>Check the uploaded file in case of error.</p>
                  <button
                    className="btn btn-primary-inner"
                    type="button"
                    onClick={() => {
                      downloadError();
                    }}
                  >
                    Download
                  </button>
                  {/* <div className="mt-3 downloadtemplate">
                    <button class="editbtn">
                      <i class="fas fa-chevron-circle-left"></i> Upload again
                    </button>
                  </div> */}
                </div>

                <div class="mb-3">
                  <div class="travel-note">
                    <h4 class="mt-0">Notes :-</h4>
                    <ul>
                      {/* <li>
                        Format - Please download import format by clicking{" "}
                        <a href="#">here</a>.
                      </li> */}
                      <li>
                        First row in csv or excel must be header and columns
                        sequence is -&gt; GLCODE, SAPLOCATION,TYPE,
                        NAME_OF_GROUP,SAP_AMOUNT.
                      </li>
                      <li>
                        Please make sure column data doesn't contain comma in
                        it's value.
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <table class="table uploadrecordstab">
                  <thead>
                    <tr>
                      <th>Upload Date</th>
                      <th>Uploaded By</th>
                      <th>File Name</th>
                      <th>Records Uploaded</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td class="text-theme">01-12-2022</td>
                      <td>Aniket Singh</td>
                      <td>employee_user.csv</td>
                      <td>500</td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GIBalancesheet;
