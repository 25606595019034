import { useEffect } from "react";
import { useState } from "react";
import { getCompanyProduct, getGenricProduct } from "../../../../service/AgriPortalService/CropScenarioService";
import styles from "./ModularTable.module.css";
import { MAX_NUMBER_LENGTH, MAX_TEXT_LENGTH } from "../../utils/constant"

function restrictToWords(word, limit) {
    if (!word) return "";

    if (word.length >= limit) {
        return word.slice(0, limit) + "...";
    }
    return word;
}
const ModularTable = ({ crop_id, columns, data, onTableDataChange }) => {
    const [internalData, setInternalData] = useState(data);

    const [blacklistObj, setBlacklistObj] = useState({});


    useEffect(() => {
        let BLCols = {};

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].nonrepeat) {
                BLCols[i] = [];
            }
        }

        setBlacklistObj(BLCols);
    }, [columns]);

    useEffect(() => {
        if (onTableDataChange)
            onTableDataChange(internalData);

        setBlacklistObj(state => {
            const BLObj = {};
            for (let i = 0; i < Object.keys(state).length; i++) {

                BLObj[Object.keys(state)[i]] = [];
                for (let j = 0; j < internalData.length; j++) {
                    BLObj[Object.keys(state)[i]].push(internalData[j][Object.keys(state)[i]].selected);
                }
            }



            return BLObj;
        })


    }, [internalData]);

    useEffect(() => { setInternalData(data) }, [data]);

    const onChangeHandler = (e, col, row, type) => {
        if (e.target.value.length >= (MAX_NUMBER_LENGTH + 1) && e.key != "Backspace" && type == "N") { e.target.value = 99999 }
        if (e.target.value.length >= (MAX_TEXT_LENGTH + 1) && e.key != "Backspace" && type == "T") { e.preventDefault() }
        setInternalData(state => {
            const newData = [...state];
            newData[col][row].value[0] = e.target.value;
            return newData;
        })
    }

    const dropdownClickedHandler = (data, col, row) => {

        if (row == 3) {
            const id = internalData[col][row]?.meta?.raw?.find(el => el.name == data)?.id;
            if (id) {
                const query = "?category_id=" + id + "&crop_id=" + crop_id;
                getCompanyProduct(query)
                    .then(res => {
                        const query2 = "?category_id=" + id + "&is_new=false";
                        const query3 = "?category_id=" + id + "&is_new=true";

                        getGenricProduct(query2)
                            .then(res2 => {

                                getGenricProduct(query3)
                                    .then(res3 => {

                                        const values = res?.data?.dataList?.map(el => el.name);
                                        const values2 = res2?.data?.dataList?.map(el => el.name);
                                        const values3 = res3?.data?.dataList?.map(el => el.name);


                                        setInternalData(state => {
                                            const newData = [...state];

                                            newData[col][5].value = values;
                                            // newData[col][5].selected = values[0];
                                            newData[col][5].meta.raw = res?.data?.dataList;

                                            newData[col][6].value = values;
                                            // newData[col][6].selected = values[0];
                                            newData[col][6].meta.raw = res?.data?.dataList;

                                            newData[col][7].value = values2;
                                            // newData[col][7].selected = values2[0];
                                            newData[col][7].meta.raw = res2?.data?.dataList;

                                            newData[col][8].value = values2;
                                            // newData[col][8].selected = values2[0];
                                            newData[col][8].meta.raw = res2?.data?.dataList;

                                            newData[col][9].value = values3;
                                            // newData[col][9].selected = values3[0];
                                            newData[col][9].meta.raw = res3?.data?.dataList;

                                            newData[col][row].selected = data;
                                            return newData;
                                        })
                                    })
                                    .catch(err => console.log(err));
                            })
                            .catch(err => console.log(err));

                    })
                    .catch(err => console.log(err));
            }
        }
        else
            setInternalData(state => {
                const newData = [...state];
                newData[col][row].selected = data;
                return newData;
            })
    }
    return <div>
        <div class="table-responsive" style={{ overflow: "auto", maxWidth: "180vw", maxHeight: "65vh" }}>
            <table class="table table-striped table-bordered tablecured">
                <thead>
                    <tr style={{ minWidth: "150px" }}>
                        {
                            columns && columns.map(el => {
                                return <th className={styles["agri-portal-table"]} style={{ position: "sticky", top: "0", zIndex: "2", textAlign: "center", textOverflow: "clip", whiteSpace: "normal" }}>{el.name}</th>
                            })
                        }

                        {/* <th>Territory Code</th>
                        <th>Territory Name</th>
                        <th>Submitted On</th>
                        <th>Submitted By</th>
                        <th>Financial Year</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>

                    {
                        data && data.filter((each) => {
                            if (each[3]?.selected) {
                                return true;
                            }
                        }).map((el, i) => {
                            const ele = el && el.map((e, idx) => {
                                if (!columns) return <></>;
                                if (!internalData) return <></>;
                                if (!columns[idx]) return <></>;
                                if (!internalData[i]) return <></>;

                                //FOR EDITABLE TEXT INPUTS
                                if (columns[idx].type == "T" && columns[idx].isEditable)
                                    return <td className={styles["agri-portal-table"]} style={{ maxWidth: "100px", minWidth: "70px", textOverflow: "clip", whiteSpace: "normal" }}>
                                        <input type="text" value={internalData[i][idx].value} style={{ width: "100%" }} onChange={(e) => onChangeHandler(e, i, idx, "T")} onKeyDown={(event) => { if (event.target.value.length >= MAX_TEXT_LENGTH && event.key != "Backspace") { event.preventDefault() } }} />
                                    </td>;

                                //FOR EDITABLE NUMBER INPUTS
                                else if (columns[idx].type == "N" && columns[idx].isEditable)
                                    return <td className={styles["agri-portal-table"]} style={{ textAlign: "right", maxWidth: "100px", minWidth: "70px", textOverflow: "clip", whiteSpace: "normal" }}>
                                        <input type="number" onWheel={(e) => e.target.blur()} value={internalData[i][idx].value} style={{ width: "100%" }} onChange={(e) => onChangeHandler(e, i, idx, "N")} onKeyDown={(event) => { if (event.target.value.length >= MAX_NUMBER_LENGTH && event.key != "Backspace") { event.preventDefault() } else if (event.key == "ArrowUp" || event.key == "ArrowDown") { event.preventDefault() } else if ((!columns[idx].allowDecimalUpto && event.key === '.') || event.key === "-") { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) > 100 && event.key != "Backspace") && columns[idx].isPercentage) { event.preventDefault(); } else if ((parseInt(event.target.value + event.key) >= 6 && event.key != "Backspace") && columns[idx].isRating) { event.preventDefault(); } }} onInput={(event) => { if (!columns[idx].allowDecimalUpto) event.target.value = event.target.value.replace(/[^0-9]*/g, ''); else if (event?.target?.value?.split(".")[1]?.length > 2) { event.target.value = event.target.value.split(".")[0] + "." + event.target.value.split(".")[1].slice(0, 2) } }} />
                                    </td>;

                                // FOR EDITABLE DROPDOWNS
                                // else if(columns[idx].type=="D"&&columns[idx].isEditable)
                                // return <td style={{overflow:"visible",width:"110%"}}>
                                // <div class="dropdown" >
                                //     <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{position:"relative",zIndex:1000, width:"170px", display:"flex", justifyContent:"space-between"}}>
                                //         {
                                //             internalData[i][idx].selected?restrictToWords(internalData[i][idx].selected,20):restrictToWords("Select "+columns[idx].name,20)
                                //         }
                                //     </button>
                                //     <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{zIndex:1001,width:"170px"}}>
                                //         {
                                //             data && data[i][idx].value.map(e=>{
                                //                 return <div className="dropdown-item"  onClick={(event)=>dropdownClickedHandler(e,i,idx)}>{restrictToWords(e,17)}</div>
                                //             })
                                //         }
                                //     </div>
                                //     </div>
                                // </td>;

                                else if (columns[idx].type == "D" && columns[idx].isEditable)
                                    return <td className={styles["agri-portal-table"]} style={{ border: "none", margin: "0 auto", minWidth: "100px", textOverflow: "clip", whiteSpace: "normal" }}><select
                                        className="form-control newbgselect"
                                        name="business_unit_id"
                                        style={{ cursor: "pointer" }}
                                        // classNamePrefix="select"
                                        onChange={(e) => {
                                            dropdownClickedHandler(e.target.value, i, idx);
                                        }}
                                    >
                                        <option value="" label="None" />
                                        {
                                            data && data[i][idx].value.map(e => {

                                                if (blacklistObj[idx]) {
                                                    if (blacklistObj[idx].find(ell => ell == e) != undefined && e !== data[i][idx].selected) return <></>;
                                                }

                                                if (internalData[i][idx].selected == e) {

                                                    return <option value={e} label={e} selected />
                                                }
                                                else
                                                    return <option value={e} label={e} />
                                            })
                                        }
                                    </select></td>;

                                //FOR UNEDITABLE VALUES 
                                else if (columns[idx].type == "D" && !columns[idx].isEditable)
                                    return <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.selected}
                                    </td>

                                else if (columns[idx].type == "N" && !columns[idx].isEditable)
                                    return <td className={styles["agri-portal-table"]} style={{ textAlign: "right", textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.value}
                                    </td>

                                else
                                    return <td className={styles["agri-portal-table"]} style={{ textOverflow: "clip", whiteSpace: "normal" }}>
                                        {e.value}
                                    </td>
                            });
                            return <tr>{ele}</tr>;
                        })
                    }
                    {/* <td>T1</td>
                        <td>T1</td>
                        <td>1-March-2022</td>
                        <td>Raj</td>
                        <td>FY 22-23</td>
                        <td>Approved</td>
                        <td>All good</td>
                        <td>View</td>                              */}
                    {/* </tr> */}
                </tbody>
            </table>
        </div>
    </div>
}

export default ModularTable;