import React from "react";
import ReactPaginate from "react-paginate";

const CustomPagination = (props) => {
  const { setPageSizeNo, setCurrentPage, pageCount, handlePageClick } = props;
  return (
    <>
      <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select
              class="form-control"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
};

export default CustomPagination;

CustomPagination.defaultProps = {
  onChange: () => {},
  handlePageClick: () => {},
  pageCount: () => {},
};
