import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getBrandCategoryList = async (
  search,
  pageNo,
  pageSize,
  sortBy,
  paginate
) => {
  return axios.get(
    `${AUTH_BASE_URL}/branding_category?search=${search}&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};

export const getBrandingCategory = async (
  pageNo,
  pageSize,
  sortBy,
  search,
  paginate,
  org,
  dept,
  stats
) => {
  return axios.get(
    `${AUTH_BASE_URL}/branding_category?search=${search}${
      org == undefined || org == "" || org == null
        ? ""
        : `&organisation_id=${org}`
    }${
      dept == undefined || dept == "" || dept == null
        ? ""
        : `&brand_category_id=${dept}`
    }${
      stats == undefined || stats == "" || stats == null
        ? ""
        : `&status=${stats}`
    }&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
  );
};

export const saveBrandingCategory = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/branding_category`, data);
};

export const updateBrandingCategory = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/branding_category/${id}`, data);
};

export const deleteBrandingCategory = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/branding_category/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getModule = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/modules?paginate=${paginate}`);
};

export const isBrandingCategoryExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/branding_category/exists`, isData);
};

export const getAllBrandingCategory = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/branding_category?paginate=${paginate}&sort_by=name`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/branding_category/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
export const getBrandingByCategory = async () => {
  return axios.get(`${AUTH_BASE_URL}/branding_client`);
};
