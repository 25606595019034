import React, { useEffect, useState } from "react";
import { Alert, Tab, ProgressBar, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as HeaderService from "../../service/jv_service";
import * as otvService from "../../service/otvServices";
import { ToastContainer, toast } from "react-toastify";
import * as TravelListingAPI from "../../service/travelService";
import "react-toastify/dist/ReactToastify.css";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";

const BulkUploadOtherExpense = (props) => {
  const { importclose } = props;
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadData, setUploadData] = useState({});
  const [pettyOrgList, setpettyOrgList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exDataDown, setExDataDown] = useState({});
  const [glData, setGlData] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [showExistsError, setShowExistsError] = useState("");
  const [pettyUser, setPettyUser] = useState([]);
  const [costData, setCostData] = useState([]);
  const [selectedPettyOrg, set_selectedPettyOrg] = useState("");
  const [excelListImport, setExcelListImport] = useState([]);
  const [totalDraftRecords, set_totalDraftRecords] = useState({
    total: 0,
    expenseType: null,
    pettyCard: null,
  });
  const [lastRecentBill, setlastRecentBill] = useState({
    back_date_id: "",
    back_date_from: "",
    back_date_to: "",
    back_date_opened_from: "",
    back_date_opened_to: "",
    max_bill_date: "",
    other: {},
    petty: {},
  });
  const [otherExpUserProfileData, setotherExpUserProfileData] = useState([]);
  const [formData, setFormData] = useState({
    expense_type: "Other",
  });
  const [showImport, setShowImport] = useState({
    downloadFlag: false,
    importFlag: false,
  });

  const getEmployee = (empid) => {
    TravelListingAPI.getEmployee2(`employee_code=` + empid)
      .then((response) => {
        setotherExpUserProfileData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isDataExist = (userId, e, flag) => {
    if (!otherExpUserProfileData?.business_unit_code) {
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li>You can not submit the request as business unit is not available</li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );

      return;
    }
    if (!otherExpUserProfileData?.employee_vendor_code) {
      // toast.error("You can not submit the request as vendor code is not available",{
      //   autoClose:5000
      // })

      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li> You can not submit the request as vendor code is not available </li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );

      return;
    }
    if (!otherExpUserProfileData?.cost_center_code) {
      // toast.error("You can not submit the request as cost center is not available",{
      //   autoClose:5000
      // })
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li> You can not submit the request as cost center is not available </li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );
      return;
    }
    // if(!otherExpUserProfileData?.profit_center ){

    //   toast.error("You can not submit the request as profit center is not available",{
    //     autoClose:5000
    //   })
    //   return

    // }

    // if (lastRecentBill?.back_date_id) {
    //   TravelListingAPI.updateTravelBackDateInfo(
    //     {
    //       is_active: false,
    //       from_date: lastRecentBill?.back_date_from,
    //       to_date: lastRecentBill?.back_date_to,
    //       opened_from: lastRecentBill?.back_date_opened_from,
    //       opened_to: lastRecentBill?.back_date_opened_to,
    //     },
    //     lastRecentBill?.back_date_id
    //   )
    //     .then((resp) => {
    //       // toast.success("back date deactivated");
    //       allJvData(type);
    //     })
    //     .catch((err) => {
    //       toast.error("back date err");
    //     });
    // }
    if (lastRecentBill?.back_date_id) {
      if (
        totalDraftRecords?.total &&
        totalDraftRecords?.expenseType?.toLowerCase() == "other"
      ) {
        toast.error(
          `Draft record already exists for ${formData?.expense_type} expense`,
          {
            autoClose: 3500,
          }
        );
        return;
      } else {
        console.log(`yes back date no draft`);
        onProfileChange(e, flag);
      }
    } else {
      TravelListingAPI.isExistsValue(userId)
        .then((res) => {
          let isExist = res?.data?.dataList?.is_exist;
          setIsExist(isExist);
          if (!isExist) {
            onProfileChange(e, flag);
          } else {
            setShowExistsError(res?.data?.message);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const onProfileChangeProxy = (e, flag) => {
    if (formData?.expense_type?.toLowerCase() == "other") {
      isDataExist(userData?.id, e, flag);
    } else {
      onProfileChange(e, flag);
    }
  };

  const onProfileChange = (e, flag) => {
    if (formData?.expense_type?.toLowerCase() == "petty" && !selectedPettyOrg) {
      toast.error("Please select organisation first.");
      return;
    }
    setShowImport({ ...showImport, downloadFlag: false });
    const files = e.target.files[0];
    console.log("selected file type ", files?.type);
    if (
      files.name.toLowerCase().lastIndexOf(".xls") ||
      files.name.toLowerCase().lastIndexOf(".xlsx") ||
      files?.type === "text/csv" ||
      files?.type === "image/xls" ||
      files?.type === "image/xlsx" ||
      files?.type === "image/XLSX" ||
      files?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      files?.type === "application/vnd.ms-excel"
    ) {
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      HeaderService.uploadImages(fileData).then((response) => {
        if (flag === "import_NonPo_data") {
          setUploadData(response.data);
          setShowImport({ ...showImport, downloadFlag: true });
        }
      });
    } else {
      toast.error("Only csv, xls and xlsx files are supported");
      setUploadData({});
    }
  };

  const getOtherExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    from_date,
    to_date,
    status,
    expense_type,
    company
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getOtherExpenseList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status,
        expense_type,
        company
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        props.openOnDuty();
        // navigate(
        // `/print-other-listing?id=${response?.data?.dataList?.result[0]?.id}&user_id=${response?.data?.dataList?.result[0]?.user_id}`,
        //                        { state:{
        //                           data: response?.data?.dataList?.result[0],
        //                           flag: false,
        //                           prev:"/other-listing"
        //                         }},
        //                       )
        // setOtherExpList(response?.data?.dataList?.result[0]);
        // setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const importExel = (values) => {
    if (values?.toLowerCase() == "petty") {
      values += "&org_id=" + selectedPettyOrg?.code;
    }

    setShowImport({ ...showImport, importFlag: false });
    setIsLoading(true);

    TravelListingAPI.importExel(values, uploadData)
      .then((response) => {
        setExDataDown(response.data);
        if (response.status === 200) {
          if (lastRecentBill?.back_date_id) {
            TravelListingAPI.updateTravelBackDateInfo(
              {
                is_active: false,
                user_id: userData?.id,
                check_type: "other",
                from_date: lastRecentBill?.back_date_from,
                to_date: lastRecentBill?.back_date_to,
                opened_from: lastRecentBill?.back_date_opened_from,
                opened_to: lastRecentBill?.back_date_opened_to,
              },
              lastRecentBill?.back_date_id
            )
              .then((resp) => {
                // toast.success("back date deactivated");
                // allJvData(type);
              })
              .catch((err) => {
                toast.error("Back date Expired");
              });
          }

          setIsLoading(false);
          getDirectoryDetails();

          // getOtherExpenseList(
          //   "",
          //   1,
          //   2,
          //   "-id",
          //   true,
          //   userData?.id
          // );
        }
        //  else {
          setShowImport({ ...showImport, importFlag: true });
        // }
      })
      .catch(async (error) => {
        const erro = JSON.parse(await error.response.data.text());
        console.log("error: ", erro);

        // console.log(error);
        // console.log(error.response);
        // console.log(error.response.data.message);

        toast.error(erro.message);

        setIsLoading(false);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPettyUser = async (id) => {
    await TravelListingAPI.getPettyUser(id)
      .then((response) => {
        let res = response?.data?.dataList?.is_exist;
        setPettyUser(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDirectoryDetails = () => {
    otvService
      .getDirectoryDetails()
      .then((response) => {
        setExcelListImport(response?.data?.dataList);
        if (response?.data?.dataList[0]?.records_uploaded > 0) {
          toast.success(
            `${response?.data?.dataList[0]?.records_uploaded} record sucessfully`
          );
          navigate("/other-listing");
        }
        if (
          response?.data?.dataList[0]?.records_uploaded === 0 &&
          response?.data?.dataList[0]?.records_failed === 0
        )
          if (response?.data?.dataList[0]?.records_failed > 0) {
            //  {
            //   toast.warn("No Record Found");
            // }
            toast.error(
              `${response?.data?.dataList[0]?.records_failed} record unsucessfull`
            );
          }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getGlList = async (gl_type, organisation_id, user_id) => {
    await TravelListingAPI.getGlListOtherExp(gl_type, organisation_id, user_id)
      .then((response) => {
        console.log(response?.data?.dataList?.result, "gl_id");
        // let res = response?.data?.dataList?.gl_id?.map((obj) => {
        //   return {
        //     value: obj.id,
        //     label: `${obj?.gl_desc} (${obj?.gl_account})`,
        //     code: obj?.gl_account,
        //   };
        // });
        // setGlData(res);
        setGlData(response?.data?.dataList?.gl_id);
      })
      .catch((error) => {});
  };

  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    gl_type,
    organisation_id
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (gl_type !== "" && gl_type !== undefined && gl_type !== null) {
      queryParm = queryParm + "&gl_type=" + gl_type;
    }
    if (
      organisation_id !== "" &&
      organisation_id !== undefined &&
      organisation_id !== null
    ) {
      queryParm = queryParm + "&organisation_id=" + organisation_id;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    return queryParm;
  };

  const downloadError = () => {
    const url = window.URL.createObjectURL(new Blob([exDataDown]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ImportErrors.xlsx.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const getGlListPetty = async (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    gl_type,
    organisation_id,
    is_active = ""
  ) => {
    await TravelListingAPI.getGlListOtherExpNew(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        gl_type,
        organisation_id,
        is_active
      )
    )
      .then((response) => {
        // let res = response?.data?.dataList?.result[0]?.gl_id?.map((obj) => {
        //   return {
        //     value: obj.gl_id,
        //     label: `${obj?.gl_desc} (${obj?.gl_account})`,
        //     code: obj?.gl_account,
        //   };
        // });
        // let newRes = response?.data?.dataList?.result[0]?.cost_center_id?.map(
        //   (obj) => {
        //     return {
        //       value: obj.id,
        //       label: `${obj?.cost_center_name} (${obj?.cost_center_code})`,
        //     };
        //   }
        // );
        // setCostCenterData(newRes);
        // setGlData(res);

        let res = response?.data?.dataList?.result?.map((obj) => {
          return {
            value: obj.organisation_id,
            label: `${obj?.organisation_name} (${obj?.organisation_code})`,
            code: obj?.organisation_code,
            gl_ids: obj?.gl_id,
            cost_ids: obj?.cost_center_id,
            max_bill_date: obj?.max_bill_date,
          };
        });
        let pp = res.filter(
          (ele, ind) =>
            ind === res.findIndex((elem) => elem.label === ele.label)
        );
        setpettyOrgList(pp);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (formData?.expense_type == "Other") {
      getGlList("Other", "", userData?.id);
    }
  }, [formData?.expense_type]);

  useEffect(() => {
    setShowImport({
      ...showImport,
      importFlag: false,
      downloadFlag: false,
    });
  }, [importclose]);

  useEffect(() => {
    getEmployee(userData?.username);
    getPettyUser(userData?.id);
    getGlListPetty(
      "",
      1,
      10,
      "-id",
      true,
      Number(userData?.id),
      "Petty",
      "",
      true
    );

    TravelListingAPI.getOtherExpenseList(
      `user_id=${userData?.id}&status=D&sort_by=-id&expense_type=${formData?.expense_type}`
    )
      .then((response) => {
        set_totalDraftRecords((pre) => ({
          ...pre,
          total: response?.data?.dataList?.result?.length,
          expenseType: response?.data?.dataList?.result[0]?.expense_type,
        }));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });

    TravelListingAPI.getBackDateList(
      `user_id=${userData?.id}&check_type=other&sort_by=-id&is_active=true`
    )
      .then((resp) => {
        if (
          resp?.data?.dataList?.result?.length > 0 &&
          resp?.data?.dataList?.result[0].is_active
        ) {
          setlastRecentBill((pre) => ({
            ...pre,
            back_date_id: resp?.data?.dataList?.result[0]?.id,
            back_date_from: resp?.data?.dataList?.result[0]?.from_date,
            back_date_to: resp?.data?.dataList?.result[0]?.to_date,
            back_date_opened_from: resp?.data?.dataList?.result[0]?.opened_from,
            back_date_opened_to: resp?.data?.dataList?.result[0]?.opened_to,
          }));
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  }, []);

  return (
    <div class="px-4 pb-2">
      {showExistsError !== "" && (
        <Alert variant="danger" className="mb-5 mt-2 text-center">
          <i className="fa fa-exclamation-triangle"></i>
          {showExistsError}
        </Alert>
      )}
      <ToastContainer autoClose={1000} />
      <div class="row">
        <div
          class="col-md-12 form-group d-flex pt-3"
          style={{ background: "rgba(182, 182, 182, 0.2)" }}
        >
          <label class="logCheck d-inline-block mr-4">
            Others
            <input
              type="radio"
              name="expense_type"
              // disabled={editData?.case == "valid"}
              defaultChecked={true}
              onChange={() => {
                setFormData({
                  ...formData,
                  expense_type: "Other",
                });
                setShowImport([
                  {
                    downloadFlag: "",
                    importFlag: "",
                  },
                ]);
                setCostData([]);
                setGlData([]);
              }}
              checked={formData?.expense_type === "Other"}
            />
            <span class="checkmark"></span>
          </label>
          {pettyUser === true ? (
            <label class="logCheck d-inline-block" style={{ width: "200px" }}>
              Petty Expense
              <input
                type="radio"
                name="expense_type"
                // disabled={editData?.case == "exception"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    expense_type: "petty",
                  });
                  setShowImport({
                    downloadFlag: false,
                    importFlag: false,
                  });
                  setCostData([]);
                  setGlData([]);
                  setShowExistsError("");
                }}
                checked={formData?.expense_type === "petty"}
              />
              <span class="checkmark"></span>
            </label>
          ) : (
            ""
          )}

          {formData?.expense_type == "petty" ? (
            // <Select
            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

            //   options={pettyOrgList}
            //   className="basic-multi-select ml-5"
            //   classNamePrefix="select"
            //   placeholder={<div>Select Organisation</div>}
            //   name="org_id"
            //   onChange={(e) => {
            //     // onSelectPettyOrg("", "", "", e.value);
            //     set_selectedPettyOrg(e);
            //     // pushCityId(e, i);
            //     // setGlValue(e);
            //     // setCodeFlag(true);
            //     // setFormValidation({
            //     //   ...formValidation,
            //     //   organisation_id: "",
            //     // });
            //     // getGlList("Petty", e.value, userData?.id);
            //   }}
            //   value={
            //     selectedPettyOrg
            //   }
            // />

            <select
              className="form-control mb-3"
              style={{ width: 350 }}
              onChange={(e) => {
                // console.log("dropd",e.target.value);
                // console.log("dropd",JSON.parse(e.target.value)?.code);
                if (e.target.value !== "") {
                  let obj = JSON.parse(e.target.value);
                  setCostData(obj.cost_ids);
                  setGlData(obj.gl_ids);
                  set_selectedPettyOrg(obj);
                } else {
                  set_selectedPettyOrg("");
                }
              }}
            >
              <option value="">Select Organisation</option>
              {pettyOrgList?.map((data) => (
                <option value={JSON.stringify(data)}>{data?.label}</option>
              ))}
            </select>
          ) : (
            ""
          )}
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-11">
          <div className="drapdroparea upload-btn-wrapper text-center">
            <i class="fas fa-file-upload fa-2x"></i>
            <p>Upload the template file with updated data</p>
            <button class="btn btn-primary-inner">
              DRAG AND DROP HERE OR CHOOSE FILE
            </button>
            {formData?.expense_type == "Other" ? (
              <div className="mt-3 downloadtemplate">
                <button class="editbtn">
                  <i class="far fa-file-excel"></i>
                  <a href="\other_expense.xlsx" download>
                    DOWNLOAD TEMPLATE
                  </a>
                </button>
              </div>
            ) : (
              <div className="mt-3 downloadtemplate">
                <button class="editbtn">
                  <i class="far fa-file-excel"></i>{" "}
                  <a href="\petty_expense.xlsx" download>
                    DOWNLOAD TEMPLATE
                  </a>
                </button>
              </div>
            )}

            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              className="form-control"
              onChange={(e) => onProfileChangeProxy(e, "import_NonPo_data")}
            />
          </div>

          {showImport.downloadFlag === true && (
            <div className="drapdroparea upload-btn-wrapper text-center">
              <span class="badge bad-status mb-3 badge-warning">
                {uploadData?.original_name}
                <i
                  class="far fa-times-circle"
                  onClick={() => {
                    setUploadData({});
                    setIsLoading(false);
                    setShowImport({
                      ...showImport,
                      importFlag: false,
                      downloadFlag: false,
                    });
                  }}
                ></i>
              </span>

              {isLoading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  as="span"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                showImport.importFlag === false && (
                  <button
                    className="btn btn-primary-inner"
                    type="button"
                    onClick={() => importExel(formData?.expense_type)}
                  >
                    Proceed to Upload
                  </button>
                )
              )}
            </div>
          )}
          {showImport.importFlag === true && (
            <div className="drapdroparea upload-btn-wrapper text-center">
              <i class="fas fa-exclamation-triangle fa-2x text-danger"></i>
              <p>
                The system was unable to read few records from the uploaded
                file.
                <br />
                Please correct or remove those records from the file and upload
                again.
              </p>
              <span className="badge bad-status mb-3 badge-success">
                Records Succeded:
                {excelListImport[0]?.records_uploaded}
              </span>
              <span className="badge bad-status mb-3 badge-danger">
                Records Failed:
                {excelListImport[0]?.records_failed}
              </span>
              <div className="mt-3 downloadtemplate">
                <p>Check the uploaded file in case of error.</p>
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={() => {
                    downloadError();
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          )}
        </div>
        <div class="col-md-12">
          {formData?.expense_type == "Other" ? (
            <div class="travel-note">
              <h4>Notes :-</h4>
              <ul>
                {/* <li>Maximum 100 line items are allowed in one request ID.</li> */}
                <li>
                  First line in excel file should be as per specified format.
                </li>
                <li>
                  Select mode of transport as
                  Bike,Car,Ola,Uber,Taxi,Bus,Auto,Public Transport in case claim
                  of Local conveyance.
                </li>
                <li>
                  Put Machine number in case claim of Spray Machine Fuel Bill.
                </li>
                <li>
                  Please make sure column data doesn't contain comma in entire
                  format.
                </li>
                <li>Bill date must be in dd-mm-yyyy format.</li>
              </ul>
            </div>
          ) : (
            <div class="travel-note">
              <h4>Notes :-</h4>
              <ul>
                {/* <li>
                  Please download XLS format by <a href="#">here</a>.
                </li> */}
                {/* <li>Maximum 100 line items are allowed in one request ID</li> */}
                <li>
                  First line in excel file should be as per specified format.
                </li>
                <li>Cost center & GL code must be filled</li>
                <li>
                  Select mode of transport as
                  Bike,Car,Ola,Uber,Taxi,Bus,Auto,Public Transport in case claim
                  of Local conveyance.
                </li>
                <li>Put SELF/CARD in column heading Expense By</li>
                <li>
                  Please make sure column data doesn't contain comma in entire
                  format.
                </li>
                <li>For the Month of & Bill date must be in dd-mm-yyyy.</li>
              </ul>
            </div>
          )}
        </div>
        {formData?.expense_type == "Other" ? (
          <>
            <div
              class="table table-striped table-bordered tablecured mt-3"
              style={{
                backgroundColor: "#3498db",
                color: "white",
                fontSize: "13px",
                padding: "7px 10px",
              }}
            >
              GL DETAILS
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    {/* <th>GL ID</th> */}
                    <th>GL CODE</th>
                    <th>GL DESC</th>
                  </tr>
                </thead>
                <tbody>
                  {glData?.length ? (
                    glData?.map((data, index) => (
                      <tr>
                        {/* <td>{data?.value ?? "-"}</td> */}
                        <td>{data?.gl_account ?? "-"}</td>
                        <td>
                          {data?.gl_desc ?? "-"}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {data?.gl_account == 754001
                            ? "Travel by is mandatory & must be from -Bike,Car,Ola,Uber,Taxi,Bus,Auto,Public Transport"
                            : [
                                "721008",
                                "722010",
                                "766044",
                                "766045",
                                "766046",
                              ]?.includes(data?.gl_account) &&
                              "Machine no. is mandatory for this GL"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="col-md-12">
            <div className="col-md-6" style={{ float: "left" }}>
              <div
                class="table table-striped table-bordered tablecured mt-3"
                style={{
                  backgroundColor: "#3498db",
                  color: "white",
                  fontSize: "13px",
                  padding: "7px 10px",
                }}
              >
                GL DETAILS
              </div>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      {/* <th>GL ID</th> */}
                      <th>GL CODE</th>
                      <th>GL DESC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {glData?.length ? (
                      glData?.map((data, index) => (
                        <tr>
                          {/* <td>{data?.value ?? "-"}</td> */}
                          <td>{data?.gl_account ?? "-"}</td>
                          <td>
                            <Tooltip
                              title={
                                data?.gl_account == 754001 &&
                                "Travel by is mandatory & must be from -Bike,Car,Ola,Uber,Taxi,Bus,Auto,Public Transport"
                              }
                              position="bottom"
                            >
                              {data?.gl_desc ?? "-"}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {data?.gl_account == 754001
                                ? "Travel by is mandatory & must be from -Bike,Car,Ola,Uber,Taxi,Bus,Auto,Public Transport"
                                : [
                                    "721008",
                                    "722010",
                                    "766044",
                                    "766045",
                                    "766046",
                                  ]?.includes(data?.gl_account) &&
                                  "Machine no. is mandatory for this GL"}
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6" style={{ float: "right" }}>
              <div
                class="table table-striped table-bordered tablecured mt-3"
                style={{
                  backgroundColor: "#3498db",
                  color: "white",
                  fontSize: "13px",
                  padding: "7px 10px",
                }}
              >
                COST CENTER
              </div>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      {/* <th>GL CODE</th> */}
                      <th>COST CENTER CODE</th>
                      <th>COST CENTER DESC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {costData?.length ? (
                      costData?.map((data, index) => (
                        <tr>
                          {/* <td>{"-"}</td> */}
                          <td>{data?.cost_center_code ?? "-"}</td>
                          <td>{data?.cost_center_name ?? "-"}</td>
                          {/* <td>
                      <strong>{data?.total_claim_amount}</strong>
                    </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div
          className="d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          <button
            type="button"
            class="btn btn-outline-secondary mb-2 mt-2"
            onClick={() => navigate("/other-listing")}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadOtherExpense;
