import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { PdfIcon } from "./Icon";
// import * as HigherEduService from "../../service/educationService";
// import { setDisplayLoader } from "../redux/piDataStore";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import DataTable from "./DataTable";

function HigherEduClaimDetail(props) {
    const [previewDocModalFlag, setPreviewDocModalFlag] = useState({ flag: false, data: [] });

    const location = useLocation();
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const reimData = location?.state?.reimData;

    // const debounce = (func, delay) => {
    //     let timeout = 0;
    //     return (...args) => {
    //         timeout = setTimeout(() => func(...args), delay);
    //         clearTimeout(timeout - 1);
    //     }
    // }

    const column = [
        {
            datafield: 'id',
            text: 'ID'
        },
        {
            datafield: 'type',
            text: 'Category',
            formatter: value => value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
        },
        {
            datafield: 'total_amount',
            text: 'Total Amount',
            formatter: value => `${value}/-`
        },
        {
            datafield: 'requested_amount',
            text: 'Requested Amount',
            formatter: value => `${value}/-`
        },
        {
            datafield: 'attachments',
            text: 'Action',
            formatter: value => {
                return <button
                    className="btn btn-primary-inner"
                    style={{ padding: '5px 10px' }}
                    onClick={() => setPreviewDocModalFlag({ flag: true, data: value })}
                >
                    <i className="far fa-check-circle"></i>&nbsp;
                    <span style={{ fontSize: 14 }}>Preview</span>
                </button>
            }
        },
    ];

    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="content-wrapper-inner content-wrapper-inner2">
                        <div className="innerheadsec">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="inner-page-title">Reimbursement Detail</h4>
                                </div>
                                <div className="col-md-12">
                                    <div className="border-top mt-2 mt-md-4 mb-3"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="input-group table-search">
                                        <span className="input-group-text">
                                            <img src="images/search.png" alt="" />
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                        // onChange={(e) => {
                                        //     handleSearch(e);
                                        // }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="inline-spacing btn-betweenspaing">
                                        <button
                                            // onClick={filteropen}
                                            className="btn btn-secondary-inner"
                                        >
                                            <img src="images/filter.png" alt="" className="mr-3" />
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable column={column} list={reimData}/>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            show={previewDocModalFlag.flag}
            onHide={() => setPreviewDocModalFlag({ flag: false, data: [] })}
            className="modaldefaultclose modaldefaultclosecenter"
        >
            <Modal.Header closeButton>
                <Modal.Title> Documents </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {previewDocModalFlag.data.length > 0 ? previewDocModalFlag.data.map(doc => <DocumentPreviewRow id={doc.id} url={doc.url} />) :
                    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                        <span style={{ color: '#bfbfbf' }} className="small">
                            <em>No document uploaded</em>
                        </span>
                    </div>}
            </Modal.Body>
        </Modal>
    </>);
}

function DocumentPreviewRow(props) {
    const urlStrArr = props.url.split('/');
    const type = urlStrArr[urlStrArr.length - 1].split('.')[1];
    return (
        <li key={props.id} className="row d-flex align-items-center mb-4">
            <div className="col-md-3">
                {type == 'pdf' ?
                    <div style={{ aspectRatio: '16/9' }} >
                        <PdfIcon width={95} height={65} />
                    </div> : <img src={props.url} style={{ aspectRatio: '16/9' }} />}
            </div>
            <div className="col-md-6">{urlStrArr[urlStrArr.length - 1]}</div>
            <div className="col-md-3 d-flex justify-content-center">
                <a href={props.url} target="_blank">
                    <i className="fas fa-eye"></i>
                </a>
            </div>
        </li>
    )
}
export default HigherEduClaimDetail