import React, { useState, useEffect } from "react";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Formik } from "formik";
import * as ShiftService from "../../service/ShiftMaster";
import AddReasonMaster from "./AddReasonMaster";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";

const ReasonMaster = (props) => {
  const {
    reasonNewData,
    reasonflag,
    showForm1,
    setShowForm1,
    onClose1,
    onAddCallBack1,
    getReasonList,
    pageCount1,
  } = props;
  const [reasonList, setReasonList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [editMode, setEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [shiftManage, setShiftManage] = useState(null);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  //   const [reasonDataForExport, setReasonDataForExport] = useState({
  //     shift_id: "",
  //     status: "",
  //   });
  //   const [reasonDataFilter, setReasonDataFilter] = useState({
  //     shift_id: "",
  //     status: "",
  //   });

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    getReasonList(
      searchStr,
      "",
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setCurrentPage(activePage);
  };
  const editReason = (eData) => {
    onAddCallBack1("editReason");
    setEditMode(true);
    setShiftManage(eData);
  };
  const deleteReasonConfirm = (id) => {
    confirmAlert({
      title: "Delete Reason",
      message: `Are you sure to delete Reason?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteReason(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteReason = (idx) => {
    ShiftService.deleteReason(idx)
      .then((res) => {
        if (res.data.status == true) {
          getReasonList(
            searchStr,
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
        }
        toast.success(res.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const addNewReason = () => {
    setEditMode(false);
    setShowForm(true);
    setShiftManage(null);
  };
  const onClose = () => {
    setShowForm1(false);
    onClose1();
  };

  const filterClose = () => {
    filterclose();
  };
  const onAddCallBack = (response) => {
    setShowForm1(false);
    onAddCallBack1(response);
    setShiftManage(null);
    getReasonList(searchStr, "", currentPage, pageSizeNo, "-id", true);
    onClose1();
  };
  useEffect(() => {
    getReasonList(
      searchStr,
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  }, [pageSizeNo]);

  return (
    <>
      <div class="table-responsive">
        <ToastContainer autoClose={1000} />
        <table class="table table-striped table-bordered tablecured">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Reason</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reasonNewData?.length ? (
              reasonNewData?.map((data, index) => (
                <tr>
                  <td>{index + 1 + (currentPage - 1) * 10}</td>
                  <td>{data?.reason}</td>
                  <td>
                    {/* <span class="badge bad-status badge-success">Active</span> */}
                    {data?.is_active ? (
                      <span className="badge bad-status badge-success">
                        Active
                      </span>
                    ) : (
                      <span className="badge bad-status badge-danger">
                        InActive
                      </span>
                    )}
                  </td>
                  <td>
                    <button
                      class="btn btn-blanktd text-primary"
                      onClick={() => {
                        editReason(data);
                      }}
                    >
                      <i class="far fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-blanktd text-danger ml-2"
                      onClick={() => {
                        deleteReasonConfirm(data?.id, data?.name);
                      }}
                    >
                      <i class="far fa-trash-alt text-danger"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12}> No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div class="sortinglist">
            Show
            <select
              class="form-control main-pointer"
              onChange={(e) => {
                setPageSizeNo(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value={10} label={10}>
                10
              </option>
              <option value={20} label={20}>
                20
              </option>
              <option value={30} label={30}>
                30
              </option>
              <option value={100} label={100}>
                100
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-7">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={pageCount1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-left"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      <div class="row jvactionsbutton">
        <div class="col-md-12">
          <div class="p-3">
            {/* <button
              class="btn btn-success"
              onClick={() => {
                addNewReason();
              }}
            >
              <i class="far fa-check-circle"></i> Add Reason
            </button> */}
          </div>
        </div>
      </div>
      {showForm1 ? (
        <>
          <AddReasonMaster
            edit={editMode}
            showForm={reasonflag}
            onClose={onClose}
            editShift={shiftManage}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ReasonMaster;
