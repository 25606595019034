import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

// export const getAttendance = async (queryParm) => {
//   return axios.get(`${AUTH_BASE_URL}/attendance/get_attendance?${queryParm}`);
// };
// ttendance/get_my_subordinates_attendance_v2
export const getAttendance = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/attendance/get_my_subordinates_attendance_v2?${queryParm}`
  );
};
export const getMySubAttendance = async (combinedData) => {
  return axios.post(
    `${AUTH_BASE_URL}/attendance/get_my_subordinates_attendance`,
    combinedData
  );
};

export const getUserData = async (bussiness_unit_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details?bussiness_unit_id=${bussiness_unit_id}`
  );
};
export const getUserDataNew = async (bussiness_unit_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details-new?bussiness_unit_id=${bussiness_unit_id}`
  );
};
export const getAllUserDataNew = async (bussiness_unit_id, forBhr) => {
  return axios.get(
    `${AUTH_BASE_URL}/users/get-user-details-new?bussiness_unit_id=${bussiness_unit_id}&attendance_report_flag=${true}&forBhr=${forBhr}`
  );
};
export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/attendance/get_attendance_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getAttendanceReport = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/attendance/get_attendance_report?${queryParm}`
  );
};

export const getAbsconding = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/attendance/get_absconding_employee?${queryParm}`
  );
};

export const getAbscondingExport = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/attendance/get_absconding_employee_export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const actionOnAbsconding = async (data) => {
  return axios.post(
    `${AUTH_BASE_URL}/attendance/action_on_absconding`, data
  )
}

export const stopAbsconding = async (id, data) => {
  return axios.put(
    `${AUTH_BASE_URL}/attendance/stop_absconding/${id}`, data
  )
}