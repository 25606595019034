import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import {
    Modal,
    Breadcrumb,
    Dropdown,
    Pagination,
    Badge,
    Tabs,
    Tab,
} from "react-bootstrap";
import Select from "react-select";
import Logo from "../../assets/images/logo.png";
import Sidebar from "./sidebar";
import { CustomBreadcrumb } from "./custom-breadcrump";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";

const [_EMPLOYEE_BENEFIT_GUIDE, _PERSONAL_ACCIDENT, _PERSONAL_ACCIDENT_PIIL, _PERSONAL_ACCIDENT_PIHS, _EMPLOYEE_BENEFIT_GUIDE_JIVAGRO] = [
    '/assets/mediclaim-sample/employee_benefit.pdf',
    '/assets/mediclaim-sample/GPA_Policy_Benefits_P I Industries Ltd_FY_ 2023-24.pdf',
    '/assets/mediclaim-sample/GPA Policy Benefits - Ms. P I Industries Ltd._Annexure.pdf',
    '/assets/mediclaim-sample/GPA Policy Benefits - Ms. P I Health Sciences Ltd._ Annexure.pdf',
    '/assets/mediclaim-sample/employee_benefit_jivagro.pdf'
]

const [_EMPLOYEE_BENEFIT__PROCESS_GUIDE, _REIMBURSEMENT_CHECKLIST, _REIMBURSEMENT_CLAIM_FORM, _VIDAL_INTIMATION_FORMAT] = [
    '/assets/mediclaim-sample/Group Mediclaim Policy Benefits & Process Guide.pptx',
    '/assets/mediclaim-sample/Reimbursement checklist.pdf',
    '/assets/mediclaim-sample/Reimbursement claim Form.pdf',
    '/assets/mediclaim-sample/Vidal Intimation Format.xlsx'
]
const currentDate = moment().format('DD-MMM-YY')

const CRUMB_ITEMS = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Me@PI' },
    { active: true, title: 'Employee Insurance Policy' }
]

function EmployeeInsurance() {
    const userData = useSelector(selectUserData);
    const options = [
        { value: "Option1", label: "Option1" },
        { value: "Option2", label: "Option2" },
        { value: "Option3", label: "Option3" },
        { value: "Option4", label: "Option4" },
        { value: "Option5", label: "Option5" },
    ];

    const [addnewshow, addnewsetShow] = useState(false);
    const addnewopen = () => addnewsetShow(true);
    const addnewclose = () => addnewsetShow(false);

    const [outstandshow, outstandsetShow] = useState(false);
    const outstanding = () => outstandsetShow(true);
    const outstandclose = () => outstandsetShow(false);

    return (
        <>
            <div class="row">
                {/* <div class="col-md-3">
                    <Sidebar />
                </div> */}
                <div class="col-md-12">
                    <div class="content-wrapper-inner content-wrapper-inner2">
                        <div class="innerheadsec pb-0">
                            <div className="row">
                                <div class="col-md-12">
                                    <h4 class="inner-page-title">Insurance Policy</h4>
                                    <CustomBreadcrumb items={CRUMB_ITEMS} />
                                </div>
                            </div>

                            <div class="row">
                            {userData?.organisation_name === 'Jivagro Limited' ?
                                <div class="col-md-4">
                                    <div class="policy-boxhead mt-3">
                                        <a href={_EMPLOYEE_BENEFIT_GUIDE_JIVAGRO} target="_blank">
                                            <h4>
                                                <strong>Employee Benefits for GMC & Topup policy</strong>
                                            </h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                : (userData?.organisation_name === 'PI INDUSTRIES LTD.' || userData?.organisation_name === 'PI Health Sciences Ltd.') ?
                                <div class="col-md-6">
                                <div class="policy-boxhead mt-3">                                    
                                        <h4>
                                            <strong>Employee Benefits for GMC & Topup policy</strong>
                                        </h4>
                                        <a href={_EMPLOYEE_BENEFIT__PROCESS_GUIDE} target="_blank">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>1. Group Mediclaim Policy Benefits & Process Guide
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                        <a href={_REIMBURSEMENT_CHECKLIST} target="_blank">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>2. Reimbursement checklist
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                        <a href={_REIMBURSEMENT_CLAIM_FORM} target="_blank">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>3. Reimbursement claim Form
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                        <a href={_VIDAL_INTIMATION_FORMAT} target="_blank">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>4. Vidal Intimation Format
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>                                       
                                </div>
                            </div>
                            : <div class="col-md-4">
                            <div class="policy-boxhead mt-3">
                                <a href={_EMPLOYEE_BENEFIT_GUIDE} target="_blank">
                                    <h4>
                                        <strong>Employee Benefits for GMC & Topup policy</strong>
                                    </h4>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <img src="images/policy-icon/pdf.png" />
                                        </div>
                                        <h5>{currentDate}</h5>
                                    </div>
                                </a>
                            </div>
                        </div>
                        }

                                {userData?.organisation_name === 'PI INDUSTRIES LTD.' ?
                                <div class="col-md-4">
                                    <div class="policy-boxhead mt-3">
                                        <a href={_PERSONAL_ACCIDENT_PIIL} target="_blank">
                                            <h4>
                                                <strong>Personal Accidental Policy</strong>
                                            </h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                : userData?.organisation_name === 'PI Health Sciences Ltd.' ?
                                <div class="col-md-4">
                                    <div class="policy-boxhead mt-3">
                                        <a href={_PERSONAL_ACCIDENT_PIHS} target="_blank">
                                            <h4>
                                                <strong>Personal Accidental Policy</strong>
                                            </h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                :""
                                }

                                {/* <div class="col-md-4">
                                    <div class="policy-boxhead mt-3">
                                        <a href={_TOP_UP_POLICY} target="_blank">
                                            <h4>
                                                <strong>Top up Mediclaim Policy</strong>
                                            </h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <img src="images/policy-icon/pdf.png" />
                                                </div>
                                                <h5>{currentDate}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={addnewshow}
                onHide={addnewclose}
                backdrop="static"
                keyboard={false}
                size=""
                className="modaldefaultclose modaldefaultclosecenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Advance Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <table class="table table-advance">
                                    <thead>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>13-01-2023</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 520
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>15-01-2023</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 520
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>17-01-2023</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 700
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>24-01-2023</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 1220
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={outstandshow}
                onHide={outstandclose}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modaldefaultclose modaldefaultclosecenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Outstanding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row">
                                <table class="table table-advance">
                                    <thead>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Amount</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>13-01-2023</td>
                                            <td>Description will come here</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 100
                                            </td>
                                            <td>-</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 520
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>15-01-2023</td>
                                            <td>Description will come here</td>
                                            <td>-</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 100
                                            </td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 520
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>17-01-2023</td>
                                            <td>Description will come here</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 100
                                            </td>
                                            <td>-</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 700
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>24-01-2023</td>
                                            <td>Description will come here</td>
                                            <td>-</td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 100
                                            </td>
                                            <td>
                                                <i class="fas fa-rupee-sign"></i> 1220
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EmployeeInsurance;
