import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as TravelLists from "../../service/travellist";
import * as moment from "moment";
import * as travelService from "../../service/travelService";
import { toast, ToastContainer } from "react-toastify";

const SapStatusModal = (props) => {
  const { viewSapData, confirmSapModal, onClose } = props;
  const [sapStatusValue, setSapStatusValue] = useState();
  const [refreshLogId, setRefreshLogId] = useState(null)

  const sapStatusData = (travel_id) => {
    TravelLists.sapStatusData(travel_id)
      .then((res) => {
        setSapStatusValue(res.data.dataList.result);
        setRefreshLogId(0)
      })
      .catch((error) => {
        console.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const rePost = (id,date) => {
    travelService.repostTravel(id,date).then((response)=>{
      toast.success('Travel repost successfully')
      setRefreshLogId(id)
      // onCloseRepost()
    })
    .catch((error) => {
      toast.error(
        (error.response &&
          error.response.data.message) ||
          "failed"
      );
    });
  }

  useEffect(() => {
    if(refreshLogId !== 0){
    sapStatusData(
      viewSapData?.id
      // viewSapData?.travel_detail_data[0]?.from_date,
      // viewSapData?.travel_detail_data[
      //   viewSapData?.travel_detail_data?.length - 1
      // ]?.to_date
    );
  }
  }, [viewSapData, refreshLogId]);

  return (
    <Modal
      show={confirmSapModal}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Savior Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured travelintim">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Travel Date</th>
                      <th>Travel Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sapStatusValue?.length > 0 ? (
                      sapStatusValue?.map((value, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {moment(value?.leave_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{value?.leave_type}</td>
                          <td>{value?.status}</td>
                          {value?.status !== 'OK' ?
                          <td><button class="btn btn-primary-inner bpi-main" onClick={()=>{rePost(value.travel_id, value.leave_date)}}>Repost</button></td>
                          :
                          <td></td>
                              }
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={16}> No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SapStatusModal;
