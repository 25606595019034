import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Modal, Pagination } from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";
import * as TravelListingAPI from "../../service/travelService";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AddOtherExpense from "./AddOtherExpense";
import { uploadfile } from "../../service/Survey";
import { toast } from "react-toastify";
import * as otherExpService from "../../service/otherExpService";
import * as userGl from "../../service/userGl";

function OtherExpListing() {
  const userDetails = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyCode, setCompanyCode] = useState([]);
  const [otherExpUserProfileData, setotherExpUserProfileData] = useState([]);

  const [otherExpList, setOtherExpList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-created_at",
  });
  const [showViewForm, setShowViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [showLeaveForm, setShowLeaveForm] = useState(false);
  const [viewLeaveData, setViewLeaveData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [filterTravel, setFilterTravel] = useState({
    from_date: "",
    to_date: "",
    status: "",
    expense_type: "",
    organisation: { code: undefined },
  });

  const [uploadData, setUploadData] = useState({});
  const handleShowModalTwo = (id) => {
    setModalState(`query-modal-${id}`);
  };

  const [modalState, setModalState] = useState(null);
  const [travelchats, setTravelChats] = useState([]);
  const [travelPostData, set_travelPostData] = useState({
    travel_id: null,
    message: "",
    u_type: "User",
    attachment_path: null,
    attachment_list: [],
  });

  const [imageLocalPreviewList, set_imageLocalPreviewList] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleCLOSE2() {
    setModalState("close");
  }

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [addnewshow, addnewsetShow] = useState(false);
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);

  function getTravelChat(id) {
    otherExpService.getOtherExpChat(id).then((response) => {
      console.log("get chat for id=>", response);

      console.log("chat->", response?.data?.dataList?.result?.length);
      setTravelChats(response?.data?.dataList?.result);
      set_travelPostData({
        ...travelPostData,
        travel_id: id,
      });
      // return response?.data?.dataList?.result?.length

      // setChatStatus(response?.data?.dataList?.result?.length)

      // setChatStatus({
      //   ...chatStatus,
      //   id:response?.data?.dataList?.result?.length==0
      // })
    });
  }

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    console.log("files", files.type);

    const fileData = new FormData();
    fileData.append("uploaded_file", files);
    console.log(`...`, fileData);

    uploadfile(fileData).then((response) => {
      // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      setUploadData(response.data);
      console.log(`response`, response.data);
      set_travelPostData({
        ...travelPostData,
        attachment_path: response.data,
      });
    });
  };

  const onProfileChange2 = (e, flag) => {
    let allfiles = e.target.files;

    let urls = [...imageLocalPreviewList];
    let tmp_filesToUpload = [...filesToUpload];

    for (let i = 0; i < allfiles.length; i++) {
      const afile = e.target.files[i];

      let myUrl = URL.createObjectURL(afile);
      urls.push(myUrl);

      // console.log("files", afile.type);

      const fileData = new FormData();
      fileData.append("uploaded_file", afile);
      tmp_filesToUpload.push(fileData);

      // console.log(`...`,fileData);

      // uploadfile(fileData).then((response) => {
      //   // console.log(`uploaded for qindex`,qIndex,'optIndex',idx);
      //   setUploadData(response.data)
      //   console.log(`response`,response.data);
      //   setPostData({
      //     ...postData,
      //     attachment_path:response.data
      //   })

      // })
    }

    set_imageLocalPreviewList(urls);
    setFilesToUpload(tmp_filesToUpload);
  };

  const removeImageByIdx = (idx) => {
    let newIList = [...imageLocalPreviewList];
    newIList.splice(idx, 1);

    // console.log(`images aft`,newIList);

    let newFList = [...filesToUpload];
    newFList.splice(idx, 1);

    set_imageLocalPreviewList(newIList);
    setFilesToUpload(newFList);
  };

  const postTravelChat = async () => {
    dispatch(setDisplayLoader("Display"));
    if (!travelPostData.message) {
      toast.error("Please enter query for sending it to ssc.");
      return;
    }
    let payl = { ...travelPostData };
    delete payl["travel_id"];

    let attachments2 = [];
    for (let afilee = 0; afilee < filesToUpload.length; afilee++) {
      // continue;
      await uploadfile(filesToUpload[afilee])
        .then((response) => {
          console.log(`uploaded for index`, afilee);

          attachments2.push(response?.data);
          // console.log(`arrres..`,attachments2);
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));

          toast.error(`${error} `);
          return;
        });
    }

    payl.attachment_list = [...attachments2];

    console.log("chatter chat->", payl);

    otherExpService
      .postOtherExpChat(travelPostData.travel_id, payl)
      .then((response) => {
        getTravelChat(travelPostData.travel_id);
        set_travelPostData({
          ...travelPostData,
          message: "",
          attachment_path: null,
          attachment_list: [],
        });
        document.getElementById("querytext").value = "";
        dispatch(setDisplayLoader("Hide"));
        setselectedFiles([]);
        setFilesToUpload([]);
        set_imageLocalPreviewList([]);
      })
      .catch((err) => {
        dispatch(setDisplayLoader("Hide"));

        toast.error("Unable to post data.");
      });
  };

  const getOtherExpenseList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    from_date,
    to_date,
    status,
    expense_type,
    company
  ) => {
    TravelListingAPI.getOtherExpenseList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        from_date,
        to_date,
        status,
        expense_type,
        company
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setOtherExpList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    from_date,
    to_date,
    status,
    expense_type,
    company
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (
      expense_type !== "" &&
      expense_type !== undefined &&
      expense_type !== null
    ) {
      queryParm = queryParm + "&expense_type=" + expense_type;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&organisation=" + company;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 1 || e.target.value.length === 0) {
      getOtherExpenseList(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id,
        filterTravel.from_date,
        filterTravel.to_date,
        filterTravel.status,
        filterTravel.expense_type,
        filterTravel.organisation.code
      );
      setSearchStr(e.target.value);
    }
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getOtherExpenseList(
        data,
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true,
        userDetails?.id
      );
    }
  };
  const onView = (data) => {
    setShowViewForm(true);
    setViewFormData(data);
  };
  const editDemand = (data) => {
    setIsEdit(true);
    setShowLeaveForm(true);
    setViewLeaveData(data);
  };
  const onViewClose = () => {
    setShowViewForm(false);
  };
  const onViewCloseEdit = () => {
    setShowLeaveForm(false);
  };
  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getOtherExpenseList(
      searchStr,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };
  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setFilterApplied(true);
    const from_date = filterTravel.from_date;
    const to_date = filterTravel.to_date;
    const status = filterTravel.status;

    getOtherExpenseList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      from_date,
      to_date,
      status,
      filterTravel.expense_type,
      filterTravel.organisation.code
    );
    filterClose();
  };
  const clearFilter = () => {
    setFilterApplied(false);
    // let ClearData = JSON.parse(JSON.stringify(filterTravel));
    // Object.entries(ClearData).map((type) => {
    //   if (
    //     type[1].constructor.name.toLowerCase() === "string" ||
    //     type[1].constructor.name.toLowerCase() === "array"
    //   ) {
    //     if (type[1].length > 0) {
    //       ClearData[type[0]] = "";
    //     }
    //   } else {
    //     if (type[1].length > 0) {
    //       ClearData[type[0]] = "";
    //     }
    //   }
    // });
    // setFilterTravel(ClearData);
    setFilterTravel({
      from_date: "",
      to_date: "",
      status: "",
      expense_type: "",
      organisation: { value: undefined },
    });
    filterClose();
    getOtherExpenseList(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id,
      "",
      "",
      "",
      "",
      ""
    );
  };

  const onAddCallBack = () => {
    getOtherExpenseList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  };

  const handleNavigate = () => {
    if (!otherExpUserProfileData?.business_unit_code) {
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li>You can not submit the request as business unit is not available</li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );

      return;
    }
    if (!otherExpUserProfileData?.employee_vendor_code) {
      // toast.error("You can not submit the request as vendor code is not available",{
      //   autoClose:5000
      // })

      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li> You can not submit the request as vendor code is not available </li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );

      return;
    }
    if (!otherExpUserProfileData?.cost_center_code) {
      // toast.error("You can not submit the request as cost center is not available",{
      //   autoClose:5000
      // })
      toast.error(
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<ul style="list-style-type: square;margin-left:10px">' +
              "<li> You can not submit the request as cost center is not available </li>" +
              "</ul>",
          }}
        ></div>,
        {
          autoClose: 5000,
        }
      );
      return;
    }
    navigate("/add-other-expense");
  };

  const handleClose = () => {
    setShowLeaveForm(false);
  };

  const getCompany = (paginate) => {
    userGl
      .getCompany(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataCompany = {
            value: x.id,
            label: `${x?.name}`,
            code: x?.code,
          };
          data.push(dataCompany);
        });
        setCompanyCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getEmployee = (empid) => {
    TravelListingAPI.getEmployee2(`employee_code=` + empid)
      .then((response) => {
        setotherExpUserProfileData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(`being called`);
    dispatch(setDisplayLoader("Display"));
    getEmployee(userDetails?.username);
    getCompany(false);
    getOtherExpenseList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userDetails?.id
    );
  }, [pageSizeNo]);
  return (
    <>
      {showLeaveForm === true ? (
        <AddOtherExpense
          onAddCallBack={onAddCallBack}
          isEdit={isEdit}
          showLeaveForm={showLeaveForm}
          onViewCloseEdit={onViewCloseEdit}
          viewLeaveData={viewLeaveData}
          handleClose={handleClose}
        />
      ) : (
        <>
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    {/* <div class="col-md-12">
                      <h4 class="inner-page-title"> Other Expenses</h4>
                    </div> */}

                    <div className="col-md-3">
                      <h4 class="inner-page-title">Other Expenses</h4>
                    </div>
                    <div className="col-md-9 text-right">
                      <span
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        *If other expense draft entry exists in your listing
                        then will not be able to create another other expense
                        entry as draft.
                      </span>
                    </div>

                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on{" "}
                              <strong> Name, Emp code, Expense ID</strong>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            id="searchbar"
                            type="text"
                            class="form-control"
                            placeholder="Search on Status..."
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack();
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        <button
                          className="btn btn-primary-inner"
                          onClick={handleNavigate}
                        >
                          Add Expense
                        </button>
                        <button
                          onClick={filteropen}
                          style={{
                            backgroundColor: filterApplied && "yellow",
                            color:
                              filterApplied &&
                              userDetails?.dark_mode === false &&
                              "#000",
                          }}
                          class="btn btn-secondary-inner"
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered tablecured">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Req Id</th>
                        <th>Print</th>
                        <th>Status</th>
                        <th>Requested On</th>
                        <th>Expense Type</th>
                        <th>For Month Of</th>
                        <th>Amount</th>
                        <th>Approved Amount</th>
                        <th>Pending At/Final Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {otherExpList?.length ? (
                        otherExpList?.map((data, index) => (
                          <tr>
                            <td>
                              {data?.status == "D" ||
                              data?.status == "R" ||
                              data?.status == "SSC Sent Back" ? (
                                <button class="btn btn-blanktd text-primary f-12">
                                  <i
                                    class="far fa-edit"
                                    onClick={() => {
                                      editDemand(data);
                                    }}
                                  ></i>
                                </button>
                              ) : (
                                ""
                              )}

                              {data?.chat_status > 0 && (
                                <button
                                  class="btn btn-primary bpi-main px-3 py-1 f-14"
                                  style={{ marginLeft: "3px" }}
                                  onClick={() =>
                                    handleShowModalTwo(`${data?.id}`)
                                  }
                                >
                                  Query
                                </button>
                              )}
                            </td>

                            {data?.chat_status > 0 && (
                              <Modal
                                show={modalState === `query-modal-${data?.id}`}
                                // onHide={addnewclose}
                                onHide={handleCLOSE2}
                                onShow={() => getTravelChat(data?.id)}
                                backdrop="static"
                                keyboard={false}
                                size="lg"
                                className="modaldefaultclose modaldefaultclosecenter"
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Query to Manager</Modal.Title>
                                </Modal.Header>
                                <Modal.Body
                                  style={{
                                    maxHeight: "calc(100vh - 210px)",
                                    overflowY: "auto",
                                  }}
                                >
                                  <div class="row justify-content-center">
                                    <div class="col-md-11">
                                      <div class="row chtgptbox agent">
                                        {travelchats?.length
                                          ? travelchats.map((cdata, idx) =>
                                              cdata?.created_by_id ==
                                              userDetails?.id ? (
                                                <div
                                                  style={{
                                                    borderLeft:
                                                      "solid 2px #2e6da4",
                                                  }}
                                                  class={"mb-3 col-md-12"}
                                                >
                                                  <b>
                                                    {cdata?.created_by_id !=
                                                    userDetails?.id
                                                      ? "SSC "
                                                      : `User (${
                                                          cdata?.created_by_user_name +
                                                          " " +
                                                          cdata?.created_by_user_code
                                                        })`}
                                                  </b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                                                    {cdata?.attachment_list?.map(
                                                      (attdata, aidx) => (
                                                        <Link
                                                          target="_blank"
                                                          class="ml-2"
                                                          to={
                                                            attdata?.attachment_path
                                                          }
                                                        >
                                                          <i class="fas fa-paperclip text-theme"></i>
                                                        </Link>
                                                      )
                                                    )}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    borderRight:
                                                      "solid 2px #eea236",
                                                  }}
                                                  class={
                                                    "mb-3 col-md-12 text-right "
                                                  }
                                                >
                                                  <b>
                                                    {cdata?.created_by_id !=
                                                    userDetails?.id
                                                      ? `SSC (${
                                                          cdata?.created_by_user_name +
                                                          " " +
                                                          cdata?.created_by_user_code
                                                        })`
                                                      : "User"}
                                                  </b>

                                                  <p>
                                                    {cdata?.message}
                                                    <br />
                                                    {/* {cdata?.attachment && <Link target="_blank" to={cdata?.attachment}><i class="far fa-file-pdf text-theme"></i></Link>} */}
                                                    {cdata?.attachment_list?.map(
                                                      (attdata, aidx) => (
                                                        <Link
                                                          target="_blank"
                                                          class="ml-2"
                                                          to={
                                                            attdata?.attachment_path
                                                          }
                                                        >
                                                          <i class="fas fa-paperclip text-theme"></i>
                                                        </Link>
                                                      )
                                                    )}

                                                    <small className="ml-2">
                                                      {moment
                                                        .parseZone(
                                                          cdata?.created_at
                                                        )
                                                        .format(
                                                          "DD-MM-YYYY hh:mm A"
                                                        )}
                                                    </small>
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <div class="col-md-12">
                                    <div
                                      style={{ width: "244px" }}
                                      class="form-group innergroup position-relative modaldefaultclose "
                                    >
                                      <label>Upload files</label>
                                      <input
                                        type="text"
                                        class="form-control bg-white"
                                        disabled
                                        placeholder="(JPG, PNG Format only)"
                                      />
                                      <div class="upload-btn-wrapper up-loposition">
                                        <button class="uploadBtn">
                                          Browse
                                        </button>
                                        <input
                                          type="file"
                                          multiple
                                          onChange={(e) =>
                                            onProfileChange2(e, "import_user")
                                          }
                                        />
                                      </div>
                                      {selectedFiles.map((dfile) => (
                                        <small class="mr-2">{dfile.name}</small>
                                      ))}
                                    </div>

                                    {imageLocalPreviewList.length ? (
                                      <div className="row">
                                        {imageLocalPreviewList.map(
                                          (idata, idx) => (
                                            <>
                                              {filesToUpload[idx]
                                                .get("uploaded_file")
                                                .name.includes(".pdf") ? (
                                                <div class="col-md-4 text-center">
                                                  <img
                                                    src={
                                                      "images/policy-icon/pdf.png"
                                                    }
                                                    width="70px"
                                                    height="70px"
                                                  />
                                                  <i
                                                    role="button"
                                                    onClick={() =>
                                                      removeImageByIdx(idx)
                                                    }
                                                    class=" fas fa-trash-alt text-danger"
                                                  />
                                                </div>
                                              ) : filesToUpload[idx]
                                                  .get("uploaded_file")
                                                  .name.includes(".xls") ? (
                                                <div class="col-md-4 text-center">
                                                  <img
                                                    src={
                                                      "images/policy-icon/xlsx.png"
                                                    }
                                                    width="70px"
                                                    height="70px"
                                                  />
                                                  <i
                                                    role="button"
                                                    onClick={() =>
                                                      removeImageByIdx(idx)
                                                    }
                                                    class=" fas fa-trash-alt text-danger"
                                                  />
                                                </div>
                                              ) : (
                                                <div class="col-md-4 text-center">
                                                  <img
                                                    src={idata}
                                                    class="h-100"
                                                  />
                                                  <i
                                                    role="button"
                                                    onClick={() =>
                                                      removeImageByIdx(idx)
                                                    }
                                                    class=" fas fa-trash-alt text-danger"
                                                  />
                                                </div>
                                              )}
                                              {/* <div class="col-md-4 text-center">
                                                <img
                                                  src={idata}
                                                  class="h-100"
                                                />
                                                <i
                                                  role="button"
                                                  onClick={() =>
                                                    removeImageByIdx(idx)
                                                  }
                                                  class=" fas fa-trash-alt text-danger"
                                                />
                                              </div> */}
                                            </>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div class="col-md-12">
                                    <div class="form-group innergroup">
                                      <label>
                                        Comment{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <textarea
                                        id="querytext"
                                        class="form-control"
                                        placeholder="Enter Comment"
                                        onChange={(e) =>
                                          set_travelPostData({
                                            ...travelPostData,
                                            message: e.target.value,
                                          })
                                        }
                                      ></textarea>
                                    </div>
                                  </div>
                                  <button
                                    class="btn btn-outline-danger"
                                    // onClick={addnewclose}
                                    onClick={handleCLOSE2}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    class="btn btn-primary-inner bpi-main"
                                    onClick={postTravelChat}
                                  >
                                    Submit
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            )}

                            <td
                              class="text-theme fw-bold"
                              // onClick={() => {
                              //   onView(data);
                              // }}
                              // style={{ cursor: "pointer" }}
                            >
                              <Link
                                to={`/print-other-listing?id=${data?.id}&user_id=${data?.user_id}`}
                                state={{
                                  data: data,
                                  flag: false,
                                  prev: "/other-listing",
                                  dontShowPrint: true,
                                }}
                              >
                                {/* {data?.expense_type == "petty"
                                  ? `${data?.organisation_code}PE`
                                  : `${data?.user_organisation_code}OE`}
                                {data?.id} */}

                                {data?.request_id
                                  ? data?.request_id
                                  : data?.expense_type == "petty"
                                  ? `${data?.organisation_code}PE`
                                  : `${data?.user_organisation_code}OE` +
                                    data?.id}
                              </Link>
                            </td>

                            <td
                              class="btn btn-blanktd text-primary"
                              // onClick={() => {
                              //   onView(data);
                              // }}
                            >
                              <Link
                                to={`/print-other-listing?id=${data?.id}&user_id=${data?.user_id}`}
                                state={{
                                  data: data,
                                  flag: false,
                                  prev: "/other-listing",
                                  dontShowPrint: false,
                                }}
                              >
                                <i class="fas fa-print"></i>
                              </Link>
                              {/* <button
                                class="btn btn-blanktd text-primary"
                                // onClick={() => window.print()}
                              >
                                <i class="fas fa-print"></i>
                              </button> */}
                            </td>
                            <td>
                              {
                                // data?.is_discarded?(
                                //   <Badge pill bg="secondary">Discarded</Badge>
                                // ):

                                data?.status === "D" ? (
                                  <span class="badge bad-status badge-warning">
                                    Draft
                                  </span>
                                ) : data?.status === "P" ? (
                                  <span class="badge bad-status badge-warning">
                                    Pending
                                  </span>
                                ) : data?.status === "A" ? (
                                  <span class="badge bad-status badge-success">
                                    Approved
                                  </span>
                                ) : data?.status === "R" ? (
                                  <span class="badge bad-status badge-danger">
                                    Rejected
                                  </span>
                                ) : data?.status === "SSC Sent Back" ? (
                                  <span class="badge bad-status badge-danger">
                                    Correction required
                                  </span>
                                ) : (
                                  ""
                                )
                              }
                            </td>
                            <td>
                              {moment(data?.created_at)
                                .utc()
                                .format("DD-MM-YYYY")}{" "}
                              {moment(data?.created_at).utc().format("hh:mm")}
                            </td>
                            <td>
                              {data?.expense_type[0].toUpperCase() +
                                data?.expense_type.slice(1)}
                            </td>
                            <td>
                              {data?.expense_type?.toLowerCase() == "other"
                                ? "NA"
                                : data?.expense_data[0]?.for_month_of
                                ? moment
                                    .parseZone(
                                      data?.expense_data[0]?.for_month_of
                                    )
                                    .format("MMMM")
                                : "-"}
                            </td>
                            <td>{data?.total_bill_amount?.toFixed(2)}</td>
                            <td>
                              {data?.status === "A" &&
                              data?.is_picked &&
                              data?.ssc_user_status
                                ? data?.total_approved?.toFixed(2)
                                : "-"}
                            </td>
                            <td style={{ maxWidth: "80vh" }}>
                              {data?.status == "SSC Sent Back" ? (
                                <span class="badge bad-status badge-danger">
                                  Requestor{" "}
                                  {`(${userDetails?.first_name} ${userDetails?.last_name} (${userDetails?.username}))`}
                                </span>
                              ) : data?.status == "A" ? (
                                data?.is_discarded ? (
                                  <span class="badge bad-status badge-warning">
                                    <Tooltip title="Discarded">
                                      Discarded ({data?.discard_reason??"-"})
                                    </Tooltip>
                                  </span>
                                ) :!data?.is_picked ? (
                                  <span class="badge bad-status badge-warning">
                                    <Tooltip title="Document sent but not received by SSC">
                                      Document sent but not received by SSC
                                    </Tooltip>
                                  </span>
                                ) : data?.is_picked &&
                                  !data?.ssc_user_status ? (
                                  <span class="badge bad-status badge-warning">
                                    {`SSC Executive (${data?.is_picked_by_user_name} (${data?.is_picked_by_user_code}))`}
                                  </span>
                                ) : data?.is_picked &&
                                  data?.ssc_user_status &&
                                  !data?.ssc_manager_status ? (
                                  <span class="badge bad-status badge-warning">
                                    SSC Manager
                                  </span>
                                ) : data?.is_picked &&
                                  data?.ssc_user_status &&
                                  data?.ssc_manager_status ? (
                                  <span class="badge bad-status badge-success">
                                    Request approved and posted to SAP
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : data?.status == "R" ? (
                                data?.approver_remark_data?.map((rdata) => {
                                  return (
                                    rdata?.status == "R" && (
                                      <span class="badge bad-status badge-danger">
                                        {`Rejected by (${rdata?.approver_name} (${rdata?.approver_username}))`}
                                      </span>
                                    )
                                  );
                                })
                              ) : (
                                data?.current_approver_data
                                  .current_approver_name !== undefined &&
                                data?.status !== "A" &&
                                data?.status !== "D" &&
                                data?.status !== "R" &&
                                data?.approver_remark_data?.map((rdata) => {
                                  return (
                                    rdata?.approver_username ==
                                      data?.current_approver_data
                                        .current_approver_code && (
                                      <span class="badge bad-status badge-warning">
                                        {rdata?.approver_name} (
                                        {rdata?.approver_username})
                                      </span>
                                    )
                                  );
                                })
                              )}
                            </td>
                            <td>
                              {data?.status == "R" &&
                                data?.approver_remark_data[
                                  data?.approver_remark_data.length - 1
                                ]?.remark}
                              {/* {data?.status == "R" &&
                                  data?.approver_remark_data?.map((rdata)=>{
                                    return rdata?.approver_username==data?.current_approver_data.current_approver_code &&
                                    `${rdata?.remark}`
                                })} */}
                              {data?.status
                                ?.toLowerCase()
                                ?.includes("ssc sent back") &&
                              data?.send_back_ssc_remark
                                ? data?.send_back_ssc_remark
                                : data?.send_back_remark}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {otherExpList?.length !== 0 && (
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="sortinglist">
                        Show
                        <select
                          class="form-control main-pointer"
                          onChange={(e) => {
                            setPageSizeNo(e.target.value);
                            setCurrentPage(1);
                          }}
                        >
                          <option value={10} label={10}>
                            10
                          </option>
                          <option value={20} label={20}>
                            20
                          </option>
                          <option value={30} label={30}>
                            30
                          </option>
                          <option value={100} label={100}>
                            100
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="form-group innergroup">
                <label>
                  Expense Type<span class="text-danger">*</span>
                </label>
                <select
                  name="status"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      expense_type: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.expense_type}
                >
                  <option value="" disabled>
                    Select
                  </option>

                  <option value="Other">Other</option>
                  <option value="Petty">Petty</option>
                </select>
              </div>

              <div class="form-group innergroup">
                <label>
                  Company<span class="text-danger">*</span>
                </label>
                <Select
                  options={companyCode}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) =>
                    setFilterTravel({
                      ...filterTravel,
                      organisation: e,
                    })
                  }
                  value={filterTravel.organisation}
                />
              </div>
              <div class="form-group innergroup">
                <label>
                  Start Date<span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  onChange={(e) =>
                    setFilterTravel({
                      ...filterTravel,
                      from_date: e.target.value,
                    })
                  }
                  value={filterTravel.from_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>
                  End Date<span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="to_date"
                  min={moment(filterTravel?.from_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setFilterTravel({
                      ...filterTravel,
                      to_date: e.target.value,
                    })
                  }
                  value={filterTravel.to_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>Status</label>
                <select
                  name="status"
                  class="form-control newbgselect"
                  onChange={(e) => {
                    setFilterTravel({
                      ...filterTravel,
                      status: e.target.value,
                    });
                    setCurrentPage(1);
                  }}
                  value={filterTravel.status}
                >
                  <option value="" disabled>
                    Select
                  </option>

                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="R">Reject</option>
                  <option value="D">Draft</option>
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => clearFilter()}
              >
                Cancel
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={addnewshow}
            onHide={addnewclose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <i class="fas fa-check-circle fa-2x text-success mt-3 mb-3"></i>
                      <p class="fa-20 text-muted mb-4">
                        You are generating SAP Trip No, After this you will not
                        be able to change tour.
                      </p>

                      <div class="mb-3">
                        <Link to="/claim-listing" class="mr-2">
                          <button class="btn btn-primary-inner bpi-main f-14 py-1">
                            Yes
                          </button>
                        </Link>
                        <button
                          class="btn btn-outline-primary f-14 py-1"
                          onClick={addnewclose}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default OtherExpListing;
