import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import * as HeaderService from "../../service/header";
import { Link } from "react-router-dom";
import * as moment from "moment";
import avtar from "../../assets/images/avtar.png";
import { Tooltip } from "react-tippy";

function Notification(props) {
  const [notificationData, setNotificationData] = useState([]);
  const [dataLength, setDataLength] = useState(null);
  const [lengthValue, setLengthValue] = useState(null);

  const getNotification = async (sort_by, paginate) => {
    await HeaderService.getNotification(sort_by, paginate)
      .then((response) => {
        setNotificationData(response?.data?.dataList?.result);
        let value = response?.data?.dataList?.result;
        let dd = value
          ?.filter((obj) => {
            if (obj.is_seen == true) {
              return obj;
            }
          })
          .map((data) => data);
        setDataLength(dd.length);
        setLengthValue(value.length - dd.length);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getSeenData = async (value) => {
    HeaderService.getSeenData(value)
      .then((response) => {
        getNotification("-id", false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getMarkReadAll = async () => {
    HeaderService.getMarkReadAll()
      .then((response) => {
        getNotification("-id", false);
        profileDropopen();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    getNotification("-id", true);
  }, []);

  const blackshadow = () => {
    const someElement = document.getElementById("root");
    someElement.classList.add("blackoverlay");
  };

  const blackshadowclose = () => {
    const someElement = document.getElementById("root");
    someElement.classList.remove("blackoverlay");
  };

  const [isShown, setActive] = useState("false");

  const profileDropopen = () => {
    setActive(!isShown);
    //const someElement = document.getElementById("root");
    //someElement.classList.toggle("blackoverlay");
  };

  const AllGridDetails = () => {
    return (
      <>
        <div className="notofyscrollsec" id="scrollpart">
          {notificationData.map((items) => (
            <>
              <div
                class={
                  items?.is_seen === false
                    ? "notftsection no-read"
                    : "notftsection"
                }
              >
                <div>
                  <div class="notifyimage">
                    <img src={avtar} alt="" />
                    <i class="fas fa-circle activeiconuser"></i>
                  </div>
                </div>
                <Link to={"/" + items?.redirect_url}>
                  <div style={{ color: "black" }}>
                    <h4>{items?.subject}</h4>
                    <h4>
                      <Link onClick={() => getSeenData(items?.id)}>
                        <span>
                          <Tooltip title={items?.message} position="bottom">
                            {items?.message?.replace(/[<]br[^>]*[>]/gi, "")}
                          </Tooltip>
                        </span>
                      </Link>
                    </h4>
                    <p class=" d-flex align-items-center">
                      {items?.created_by}{" "}
                      <i class="fas mx-1 fa-circle f-11"></i>{" "}
                      {moment
                        .utc(items?.created_at)
                        .format("DD MMM YYYY hh:mm:ss")}{" "}
                    </p>
                  </div>
                </Link>
              </div>
            </>
          ))}
        </div>
      </>
    );
  };

  const AllGridDetailsSeen = () => {
    return (
      <>
        <div className="notofyscrollsec" id="scrollpart">
          {notificationData?.map((items) => (
            <>
              {items?.is_seen && (
                <div
                  class={
                    items?.is_seen === false
                      ? "notftsection no-read"
                      : "notftsection"
                  }
                >
                  <div>
                    <div class="notifyimage">
                      <img src={avtar} alt="" />
                      <i class="fas fa-circle activeiconuser"></i>
                    </div>
                  </div>
                  <Link to={"/" + items?.redirect_url}>
                    <div style={{ color: "black" }}>
                      <h4>{items?.subject}</h4>
                      <h4>
                        <Link onClick={() => getSeenData(items?.id)}>
                          <span>
                            <Tooltip title={items?.message} position="bottom">
                              {items?.message?.replace(/[<]br[^>]*[>]/gi, "")}
                            </Tooltip>
                          </span>
                        </Link>
                      </h4>
                      <p class=" d-flex align-items-center">
                        {items?.created_by}{" "}
                        <i class="fas mx-1 fa-circle f-11"></i>{" "}
                        {moment
                          .utc(items?.created_at)
                          .format("DD MMM YYYY hh:mm:ss")}{" "}
                      </p>
                    </div>
                  </Link>
                </div>
              )}
            </>
          ))}
        </div>
      </>
    );
  };

  const AllGridDetailsUnSeen = () => {
    return (
      <>
        <div className="notofyscrollsec" id="scrollpart">
          {notificationData?.map((items) => (
            <>
              {items?.is_seen === false && (
                <div
                  class={
                    items?.is_seen === false
                      ? "notftsection no-read"
                      : "notftsection"
                  }
                >
                  <div>
                    <div class="notifyimage">
                      <img src={avtar} alt="" />
                      <i class="fas fa-circle activeiconuser"></i>
                    </div>
                  </div>
                  <Link to={"/" + items?.redirect_url}>
                    <div style={{ color: "black" }}>
                      <h4>{items?.subject}</h4>
                      <h4>
                        <Link onClick={() => getSeenData(items?.id)}>
                          <span>
                            <Tooltip title={items?.message} position="bottom">
                              {items?.message?.replace(/[<]br[^>]*[>]/gi, "")}
                            </Tooltip>
                          </span>
                        </Link>
                      </h4>
                      <p class=" d-flex align-items-center">
                        {items?.created_by}{" "}
                        <i class="fas mx-1 fa-circle f-11"></i>{" "}
                        {moment
                          .utc(items?.created_at)
                          .format("DD MMM YYYY hh:mm:ss")}{" "}
                      </p>
                    </div>
                  </Link>
                </div>
              )}
            </>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <li
        className={
          isShown
            ? "notificonhoverbox ml-4"
            : "usermyprofilesettingshow notificonhoverbox ml-4"
        }
      >
        <div class="notifyicon" onClick={profileDropopen}>
          <i class="far fa-bell"></i>
          {lengthValue !== 0 && <span>{lengthValue}</span>}
        </div>

        <div class="blackoverlay" onClick={profileDropopen}></div>

        <div class="notificationbox">
          <div class="notifytabs">
            <Tabs defaultActiveKey="people" id="notofytabs">
              <Tab
                eventKey="people"
                title={
                  <React.Fragment>
                    Unread
                    <span class="badge bg-secondary">{lengthValue}</span>
                  </React.Fragment>
                }
              >
                <AllGridDetailsUnSeen />
              </Tab>

              <Tab
                eventKey="files"
                title={
                  <React.Fragment>
                    Read
                    <span class="badge bg-secondary">{dataLength}</span>
                  </React.Fragment>
                }
              >
                <AllGridDetailsSeen />
              </Tab>

              <Tab
                eventKey="all"
                title={
                  <React.Fragment>
                    All
                    <span class="badge bg-secondary">
                      {notificationData.length}
                    </span>
                  </React.Fragment>
                }
              >
                <AllGridDetails />
              </Tab>
            </Tabs>
          </div>

          <div
            class="input-group-text text-right d-block cursor-pointer"
            onClick={() => {
              getMarkReadAll();
            }}
          >
            <h6>Mark all Read</h6>
          </div>
        </div>
      </li>
    </>
  );
}

export default Notification;
