import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown, Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import * as moment from "moment";
import ApplyLeave from "./ApplyLeave";
import * as leaveDataService from "../../service/leaveService";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectUserData } from "../redux/piDataStore";
import { useLocation } from "react-router-dom";
import { selectFinalUrlPermission } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { get_sap_schedule } from "../../service/searchpo";

if (typeof window !== "undefined") {
  injectStyle();
}
function MyLeave() {
  const dispatch = useDispatch();
  let { state } = useLocation();

  useEffect(() => {
    if (state?.fromCalender) {
      setShowForm(true)
    }
  }, [state])


  let month_start = `${moment(new Date()).format("YYYY")}-${moment(new Date())
    .subtract(1, "month")
    .format("MM")}-20`;
  let month_end = `${moment(new Date()).format("YYYY")}-${moment(
    new Date()
  ).format("MM")}-22`;

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [leaveMgmtData, setLeaveMgmtData] = useState([]);
  const [currentTab, setCurrentTab] = useState("P");
  const [pageCount, setpageCount] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [leave, setLeave] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaveList, setleaveList] = useState([]);
  const [dataMList, setDataMList] = useState([]);
  const [managementLeaveList, setManagementLeaveList] = useState([]);
  const [nextSapPostingTime, setNextSapPostingTime] = useState('')
  const [leaveCounts, setLeaveCounts] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
    cancelled: 0
  })
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "-from_date",
  });
  const [lastAppliedLeave, setLastAppliedLeave] = useState(null);
  const [mandatoryHolidays, setMandatoryHolidays] = useState([]);
  const [userWeekOff, setUserWeekOff] = useState(null);
  const [attendanceWeekOff, setAttendanceWeekOff] = useState([]);
  const [userBackDate, setUserBackDate] = useState([]);
  const [backDateForm, setBackDateForm] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [allowBackDate, setAllowBackDate] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [userLeaveSummaryData, setuserLeaveSummaryData] = useState([]);
  const userDetails = useSelector(selectUserData);
  const [filterValue, setFilterValue] = useState({
    leave_type_id: "",
    from_date: "",
    to_date: "",
    status: "",
  });
  const [leaveFilterValue, setLeaveFilterValue] = useState({
    leave_type_id: "",
    from_date: "",
    to_date: "",
    status: "",
    year: ""
  });

  const getSapSchedule = () => {
    get_sap_schedule('sync_apply_leave_balance_from_savior').then((response) => {
      let next_sap_posting_time = response?.data?.dataList?.result
      setNextSapPostingTime(next_sap_posting_time)
    })
  }

  const userData = useSelector(selectUserData);
  const getLeaveDataList = (
    search,
    leave_type_id,
    from_date,
    to_date,
    year,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    leaveDataService
      .getLeaveDataList(
        handleKey(
          search,
          leave_type_id,
          from_date,
          to_date,
          year,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setLeaveMgmtData(response?.data?.dataList);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        getLeaveManageDataList(userData?.id);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getLeaveManageDataList = (id) => {
    leaveDataService
      .getLeaveManageDataList(id)
      .then((response) => {
        setleaveList(
          response?.data?.dataList?.result.filter((obj) => {
            if (obj?.leave_type__is_active === true) {
              return true;
            }
          })
        );
        let leaveM = [];
        response?.data?.dataList?.result.map((value) => {
          let data = {
            value: value.leave_type_id,
            label: value.leave_type_name,
          };
          leaveM.push(data);
        });
        setManagementLeaveList(leaveM);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportValue = (
    search,
    leave_type_id,
    from_date,
    to_date,
    year,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    leaveDataService
      .getExportValue(
        handleKey(
          search,
          leave_type_id,
          from_date,
          to_date,
          year,
          status,
          page_no,
          page_size,
          sort_by,
          paginate
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Leave.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleKey = (
    search,
    leave_type_id,
    from_date,
    to_date,
    year,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }
    if (year !== undefined && year !== null) {
      queryParm = queryParm + "&year=" + year;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      leave_type_id !== "" &&
      leave_type_id !== undefined &&
      leave_type_id !== null
    ) {
      queryParm = queryParm + "&leave_type_id=" + leave_type_id;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }

    return queryParm;
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getLeaveDataList(
        e.target.value.toLowerCase(),
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue?.year,
        currentTab,
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getLeaveDataList(
        data,
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue?.year,
        leaveFilterValue.status,
        1,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };

  const onClose = () => {
    setShowForm(false);
    setBackDateForm(false);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.year,
      "P",
      1,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const editState = (eData) => {
    setShowForm(true);
    setEditMode(true);
    setLeave(eData);
  };

  const addLeaveApply = () => {
    setLeave(null);
    setEditMode(false);
    setShowForm(true);
  };

  const addBackLeaveApply = () => {
    setLeave(null);
    setEditMode(false);
    setBackDateForm(true);
  };

  const handlePushData = (e) => {
    setLeaveFilterValue({
      ...leaveFilterValue,
      leave_type_id: e.value,
    });
    setDataMList(e);
  };

  const onAddCallBack = (response) => {
    setShowForm(false);
    setBackDateForm(false);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.year,
      "P",
      currentPage,
      pageSizeNo,
      "-from_date",
      true
    );
    getLastLeaveData();
    getUserLeaveSummary()
  };

  const handlerData = (backdata) => {
    let sdata = {};
    if (sortData["colName"] === backdata) {
      if (sortData["sort_by"].includes("-")) {
        sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
      } else {
        sdata = {
          colName: backdata,
          sort_by: "-" + backdata,
          [backdata]: "desccls",
        };
      }
    } else {
      sdata = { colName: backdata, sort_by: backdata, [backdata]: "asccls" };
    }
    setSortData(sdata);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.year,
      leaveFilterValue.status,
      currentPage,
      pageSizeNo,
      sdata.sort_by,
      true
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.year,
      currentTab,
      activePage,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const mainDataFilter = () => {
    if (
      leaveFilterValue?.from_date !== "" ||
      leaveFilterValue?.leave_type_id !== "" ||
      leaveFilterValue?.status !== "" ||
      leaveFilterValue?.to_date !== ""
    ) {
      setFilterApplied(true);
    }
    setFilterValue({
      ...filterValue,
      status: currentTab,
      leave_type_id: leaveFilterValue.leave_type_id,
      from_date: leaveFilterValue.from_date,
      to_date: leaveFilterValue.to_date,
    });
    const status = currentTab;
    const leaveType = leaveFilterValue.leave_type_id;
    const fromDate = leaveFilterValue.from_date;
    const toDate = leaveFilterValue.to_date;
    const year = leaveFilterValue?.year
    getLeaveDataList(
      searchStr,
      leaveType,
      fromDate,
      toDate,
      year,
      status,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterClose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(leaveFilterValue));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setLeaveFilterValue(ClearData);
    filterClose();
    setDataMList("");
    getLeaveDataList(
      searchStr,
      "",
      "",
      "",
      "",
      currentTab,
      1,
      pageSizeNo,
      sortData?.sort_by,
      true
    );
  };

  const deleteApplyLeave = (idx, flag) => {
    leaveDataService
      .deleteApplyLeave(idx)
      .then((res) => {
        if (flag !== undefined) {
          if(res?.data?.message === "Tour initiated already, you cannot cancel this leave"){
            toast.error("Tour initiated already, you cannot cancel this leave");
          } else{
            toast.success("Cancel Request Successfully.");
          }          
        } else {
          if(res?.data?.message === "Data deleted successfully."){
            toast.success("Revoked Successfully");
          }
          else{
            toast.error(res?.data?.message)
          }
        }
        getLeaveDataList("", "", "", "", "", currentTab, 1, 10, "-id", true);
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const handleDelete = (id, flag) => {
    confirmAlert({
      message: `Are you sure to ${flag !== undefined ? "cancel" : "cancel"
        } this leave ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApplyLeave(id, flag),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const filterClose = () => {
    filterclose();
  };

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  useEffect(() => {
    if (userData?.id !== undefined) {
      dispatch(setDisplayLoader("Display"));
      getLeaveDataList(
        searchStr,
        leaveFilterValue.leave_type_id,
        leaveFilterValue.from_date,
        leaveFilterValue.to_date,
        leaveFilterValue.year,
        currentTab,
        1,
        pageSizeNo,
        sortData?.sort_by,
        true
      );
      getLastLeaveData();
      getUserSaviorData();
      getBackDateData();
    }
  }, [pageSizeNo, userData]);

  useEffect(() => {
    getUserLeaveSummary(null, leaveFilterValue?.year);
  }, [leaveFilterValue]);

  useEffect(() => {
    getSapSchedule();
  })

  const getLastLeaveData = () => {
    leaveDataService.getLeaveDataList("status=A,P").then((response) => {
      if (response?.status === 200) {
        let pendingLeaves = response?.data?.dataList?.pending;
        let approvedLeaves = response?.data?.dataList?.approved;
        let totalRecord = [];
        if (pendingLeaves !== 0) {
          totalRecord.push(...pendingLeaves);
        }
        if (approvedLeaves !== 0) {
          totalRecord.push(...approvedLeaves);
        }
        // let nearRecord = null;
        // let nearDateDiff = 0;

        // pendingLeaves?.map((obj) => {
        //   let curDateDiff = moment(new Date()).diff(obj?.toDate, "days");
        //   if (nearRecord === null) {
        //     nearRecord = obj;
        //     nearDateDiff = curDateDiff;
        //   } else if (curDateDiff < nearDateDiff) {
        //     nearRecord = obj;
        //     nearDateDiff = curDateDiff;
        //   }
        // });

        // approvedLeaves?.map((obj) => {
        //   let curDateDiff = moment(new Date()).diff(obj?.toDate, "days");
        //   if (curDateDiff < nearDateDiff) {
        //     nearRecord = obj;
        //     nearDateDiff = curDateDiff;
        //   }
        // });
        setLastAppliedLeave(totalRecord);
      }
    });
  };

  const getBackDateData = async () => {
    let queryParam = `user_id=${userData?.id}`;
    await leaveDataService.getBackDatesEntries(queryParam).then((response) => {
      if (response?.status === 200) {
        setUserBackDate(response?.data?.dataList?.result);
        if (response?.data?.dataList?.result?.length > 0) {
          let userBackDate = response?.data?.dataList?.result[0];
          let fromDate = moment(userBackDate?.from_date);
          let toDate = moment(userBackDate?.to_date);
          let today = moment(new Date());
          if (today.isBetween(fromDate, toDate, "date", "[]")) {
            setAllowBackDate(true);
          }
        }
      }
    });
  };

  const getUserSaviorData = async () => {
    await leaveDataService.getUserHolidays("", "").then((response) => {
      if (response?.status === 200) {
        let holidays = response?.data?.dataList?.userHoliday?.map((obj) => {
          return obj?.day_date;
        });
        setMandatoryHolidays(holidays);
        setUserWeekOff(response?.data?.dataList?.week_off);
        setAttendanceWeekOff(response?.data?.dataList?.attendanceWeekOff);
      }
    });
  };

  const getUserLeaveSummary = (id, year) => {
    leaveDataService
      .getUserLeaveSummary(id, year, "")
      .then((response) => {
        let data = response?.data?.dataList?.leave_type_summary?.map((each) => {
          return {
            leave_name: each.leave_type_name,
            balance: each?.bal,
            availed: each?.avail
          }
        })
        setuserLeaveSummaryData(data);
        setLeaveCounts((prev) => ({
          ...prev,
          pending: response?.data?.dataList?.leave_status?.pending,
          approved: response?.data?.dataList?.leave_status?.approved,
          rejected: response?.data?.dataList?.leave_status?.rejected,
          cancelled: response?.data?.dataList?.leave_status?.cancelled
        }))
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };


  useEffect(() => {
    getLeaveDataList(
      searchStr,
      leaveFilterValue.leave_type_id,
      leaveFilterValue.from_date,
      leaveFilterValue.to_date,
      leaveFilterValue?.year,
      currentTab,
      1,
      10,
      "-from_date",
      true
    );
  }, [currentTab]);

  const checkForCancel = (data, fromDate) => {
    if (currentTab === "A") {
      let cycleFrom = moment(new Date()).month() === 0 ? `${moment(new Date()).format("YYYY")}-01-01` : `${moment(new Date()).format("YYYY")}-${moment(new Date())
        .subtract(1, "month")
        .format("MM")}-20`;
      let cycleTo = `${moment(new Date()).format("YYYY")}-${moment(
        new Date()
      ).format("MM")}-20`;
      let userBackDateData = userBackDate;
      let leave_from = moment(fromDate);
      let leave_to = moment(data?.to_date);
      console.log(
        "hello",
        leave_from.format("DD-MM-YYYY"),
        leave_from.isBetween(moment(cycleFrom), moment(cycleTo))
      );
      if (
        moment(new Date()).date() <= 22 &&
        leave_from.isAfter(moment(cycleFrom))
      ) {
        return true;
      } else if (
        userBackDateData.length > 0 &&
        leave_from >= moment(userBackDateData[0]?.period_from) &&
        leave_to <= moment(userBackDateData[0]?.period_to)
      ) {
        return true;
      } else {
        if (leave_from.date() > 20) {
          if (leave_from.month() >= moment(new Date()).month() && leave_from.year() === moment(new Date()).year()) {
            return true;
          }
        } else {
          if (leave_from.month() >= moment(new Date()).month() && leave_from.year() === moment(new Date()).year()) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  };

  const displayLogs = (data) => {
    let dates = [];
    let finalData = data?.filter((each) => {
      if (
        dates.includes(each?.leave_date) === false &&
        each?.sync_date !== null
      ) {
        dates.push(each?.leave_date);
        return true;
      }
    });
    setDetailsData(finalData);
  };

  return (
    <>
      {showForm ? (
        <>
          <ApplyLeave
            edit={editMode}
            leaveList={leaveList}
            lastLeave={lastAppliedLeave}
            mandatory={mandatoryHolidays}
            weekOff={userWeekOff}
            attendanceWeekOff={attendanceWeekOff}
            onClose={onClose}
            editState={leave}
            backDate={[]}
            editBackDate={userBackDate}
            userLeaves={leaveMgmtData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : backDateForm ? (
        <>
          <ApplyLeave
            edit={editMode}
            leaveList={leaveList}
            lastLeave={lastAppliedLeave}
            mandatory={mandatoryHolidays}
            weekOff={userWeekOff}
            attendanceWeekOff={attendanceWeekOff}
            onClose={onClose}
            editState={leave}
            backDate={userBackDate}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        <>
          <div class="row">
            <ToastContainer autoClose={1000} />
            {/* <div class="col-md-3">
              <ul class="policylistnames">
                <li>
                  <Link to="/manage-leave">Leave Master</Link>
                </li>
                <li>
                  <Link to="/allocation-leave">Leave Rule</Link>
                </li>
                <li>
                  <Link to="/allocation-leave">Leave Allocation</Link>
                </li>
                <li class="active">
                  <Link to="/my-leave">My Leaves</Link>
                </li>
                <li>
                  <Link to="/bulk-leave">Leave Bulk Upload</Link>
                </li>
                <li>
                  <Link to="/leave-approval">For Approval Leaves</Link>{" "}
                </li>
                <li>
                  <Link to="/leave-backdate">Back Date Entry Exception</Link>
                </li>
              </ul>
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec">
                  <div className="row">
                    <div class="col-md-7">
                      <h4 class="inner-page-title">My Leaves</h4>
                      {/* <Breadcrumb>
                        <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/">Me@PI</Breadcrumb.Item>
                        <Breadcrumb.Item active>My Leaves</Breadcrumb.Item>
                      </Breadcrumb> */}
                    </div>
                    <div className="col-md-5">
                      <div className="d-flex" style={{
                        fontSize: '14px',
                        justifyContent: 'space-between',
                        color: userDetails?.dark_mode === false && "#FFF"
                      }}>
                        {userLeaveSummaryData?.length > 0 && userLeaveSummaryData?.map((each) => (
                          <>
                            <div>
                              <span className="fw-bold text-theme ml-2">{each?.leave_name}</span>
                              <ul className="ml-4">
                                <li><span>Balance - {each?.balance}</span></li>
                                <li><span>Availed - {each?.availed}</span></li>
                              </ul>
                            </div>
                            <br />
                          </>
                        ))}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="border-top mb-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-lg-4">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip
                            className="bs-tooltip-end tooltip"
                            id="overlay-example"
                          >
                            <div class="tooltip-inner text-left">
                              Search on <strong> Leave Type</strong>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div class="input-group table-search">
                          <span class="input-group-text">
                            <img src="images/search.png" alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            id="searchbar"
                            placeholder="Search on Leave Type"
                            onChange={(e) => {
                              handleSearch(e);
                              setCurrentPage(1);
                            }}
                          />
                          <span class="input-group-text border-0">
                            {searchStr !== "" ? (
                              <i
                                class="far fa-times-circle cursor-pointer"
                                onClick={() => {
                                  closeButtonCallBack();
                                }}
                              ></i>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div class="col-lg-8">
                      <div class="inline-spacing btn-betweenspaing">
                        {/* {finalUrlValue?.D ? (
                          <Dropdown show>
                            <Dropdown.Toggle
                              variant="primary-inner dropdownbtn"
                              id="dropdown-basic"
                            >
                              <img
                                src="images/export.png"
                                alt=""
                                class="mr-2"
                              />{" "}
                              Export
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#">
                                <button
                                  style={{ border: 0, background: "none" }}
                                  onClick={() =>
                                    getExportValue(
                                      searchStr,
                                      leaveFilterValue.leave_type_id,
                                      leaveFilterValue.from_date,
                                      leaveFilterValue.to_date,
                                      currentTab,
                                      1,
                                      pageSizeNo,
                                      sortData.sort_by,
                                      true
                                    )
                                  }
                                >
                                  <i className="far fa-file-excel"></i>Excel
                                </button>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )} */}
                        <button
                          onClick={filteropen}
                          class="btn btn-secondary-inner"
                          style={{
                            backgroundColor: filterApplied && "yellow",
                            color: filterApplied && userData?.dark_mode === false && "#000"
                          }}
                        >
                          <img src="images/filter.png" alt="" class="mr-3" />
                          Filter
                        </button>
                        {finalUrlValue?.A && (
                          <button
                            class="btn btn-primary-inner"
                            onClick={() => addLeaveApply()}
                          >
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Apply Leave
                          </button>
                        )}
                        {userBackDate?.length > 0 && allowBackDate && finalUrlValue?.A && (
                          <button
                            class="btn btn-primary-inner mt-2"
                            onClick={() => addBackLeaveApply()}
                          >
                            <img src="images/upload.png" alt="" class="mr-3" />
                            Apply Back Date Leave
                          </button>
                        )}
                        {/* ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-6 mt-2">
                      <b style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF" }}>Next Savior Posting:</b> <span className="mr-3" style={{ fontSize: "13px", color: userDetails?.dark_mode === false && "#FFF"  }}>{moment.utc(nextSapPostingTime).format('DD-MM-YYYY hh:mm A')}</span>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs">
                  <Tabs
                    defaultActiveKey="P"
                    id="approvaltabs"
                    onSelect={(e) => {
                      setCurrentTab(e);
                      setPageSizeNo(10);
                      setCurrentPage(1);
                    }}
                  >
                    <Tab
                      eventKey="P"
                      title={
                        <React.Fragment>
                          Pending
                          <span class="numberpurchse">
                            {leaveCounts?.pending !== undefined
                              && leaveCounts?.pending}
                          </span>
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Approver</th>
                              <th
                                onClick={(e) => {
                                  handlerData("from_date");
                                }}
                                className={
                                  sortData["from_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["from_date"]
                                }
                              >
                                From
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("to_date");
                                }}
                                className={
                                  sortData["to_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["to_date"]
                                }
                              >
                                To
                              </th>
                              {/* <th
                                onClick={(e) => {
                                  handlerData("from_type");
                                }}
                                className={
                                  sortData["from_type"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["from_type"]
                                }
                              >
                                From Type
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("to_type");
                                }}
                                className={
                                  sortData["to_type"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["to_type"]
                                }
                              >
                                To Type
                              </th> */}
                              <th
                                onClick={(e) => {
                                  handlerData("leave_duration");
                                }}
                                className={
                                  sortData["leave_duration"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_duration"]
                                }
                              >
                                Total Leaves
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_type__name");
                                }}
                                className={
                                  sortData["leave_type__name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_type__name"]
                                }
                              >
                                Leave Type
                              </th>
                              <th>Reason</th>
                              <th
                                onClick={(e) => {
                                  handlerData("status");
                                }}
                                className={
                                  sortData["status"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["status"]
                                }
                              >
                                Status
                              </th>
                              <th>Requested At</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaveMgmtData?.pending?.length > 0 &&
                              leaveMgmtData?.pending?.map((data, index) => (
                                <tr>
                                  <td>
                                    {data?.action_by_id === null
                                      ? userData?.reporting_to_fname +
                                      " " +
                                      userData?.reporting_to_lname +
                                      "(" +
                                      userData?.reporting_to_name +
                                      ")"
                                      : data?.action_by}
                                  </td>
                                  <td className="text-center">
                                    {`${moment(data?.from_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  ${data?.from_type !== ""
                                        ? data?.from_type === "First" ||
                                          data?.from_type === "F"
                                          ? " | F"
                                          : data?.from_type === "Second" ||
                                            data?.from_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>
                                  <td className="text-center">
                                    {`${moment(data?.to_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              ${data?.to_type !== ""
                                        ? data?.to_type === "First" ||
                                          data?.to_type === "F"
                                          ? " | F"
                                          : data?.to_type === "Second" ||
                                            data?.to_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>
                                  <td>{data?.leave_duration}</td>
                                  <td>{data?.leave_type_name}</td>
                                  <td>
                                    {data?.reasons
                                      ?.map((obj) => {
                                        return obj?.reason;
                                      })
                                      .toString()}
                                  </td>
                                  <td>
                                    {data?.status === "D"
                                      ? "Rejected"
                                      : data?.status === "A"
                                        ? "Approved"
                                        : "Pending"}
                                  </td>
                                  {/* {finalUrlValue?.R ? ( */}
                                  <td>
                                    {moment
                                      .utc(data?.requested_at)
                                      .format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-blanktd text-danger "
                                      onClick={() => handleDelete(data.id)}
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                    <button
                                      className="btn btn-blanktd text-primary"
                                      onClick={() => editState(data)}
                                    >
                                      <i class="far fa-edit"></i>
                                    </button>
                                  </td>
                                  {/* ) : (
                                  ""
                                )} */}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      {leaveMgmtData?.pending?.length == 0 ? (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      ) : (
                        <div class="row">
                          <div class="col-sm-5">
                            <div class="sortinglist">
                              Show
                              <select
                                class="form-control main-pointer"
                                onChange={(e) => {
                                  setPageSizeNo(e.target.value);
                                  setCurrentPage(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab
                      eventKey="A"
                      title={
                        <React.Fragment>
                          Approved
                          <span class="numberpurchse">
                            {leaveCounts?.approved !== undefined
                              && leaveCounts?.approved}
                          </span>
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Approved By</th>
                              <th
                                onClick={(e) => {
                                  handlerData("from_date");
                                }}
                                className={
                                  sortData["from_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["from_date"]
                                }
                              >
                                From
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("to_date");
                                }}
                                className={
                                  sortData["to_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["to_date"]
                                }
                              >
                                To
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_duration");
                                }}
                                className={
                                  sortData["leave_duration"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_duration"]
                                }
                              >
                                Total Leaves
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_type__name");
                                }}
                                className={
                                  sortData["leave_type__name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_type__name"]
                                }
                              >
                                Leave Type
                              </th>
                              <th>Requested On</th>
                              <th>Approved On</th>
                              <th>Reason</th>
                              <th>Remarks</th>
                              <th
                                onClick={(e) => {
                                  handlerData("status");
                                }}
                                className={
                                  sortData["status"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["status"]
                                }
                              >
                                Status
                              </th>
                              <th>Savior Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaveMgmtData?.approved?.length > 0 &&
                              leaveMgmtData?.approved
                                ?.filter((ele) => ele.status === "A")
                                ?.map((data, index) => (
                                  <tr>
                                    <td>
                                      {data?.action_by}
                                      {/* {userData?.reporting_to_fname}{" "}
                                    {userData?.reporting_to_lname} (
                                    {userData?.reporting_to_name}) */}
                                    </td>
                                    <td className="text-center">
                                      {`${moment(data?.from_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                  ${data?.from_type !== ""
                                          ? data?.from_type === "First" ||
                                            data?.from_type === "F"
                                            ? " | F"
                                            : data?.from_type === "Second" ||
                                              data?.from_type === "S"
                                              ? " | S"
                                              : ""
                                          : ""
                                        }`}
                                    </td>
                                    <td className="text-center">
                                      {`${moment(data?.to_date).format(
                                        "DD-MM-YYYY"
                                      )}
                              ${data?.to_type !== ""
                                          ? data?.to_type === "First" ||
                                            data?.to_type === "F"
                                            ? " | F"
                                            : data?.to_type === "Second" ||
                                              data?.to_type === "S"
                                              ? " | S"
                                              : ""
                                          : ""
                                        }`}
                                    </td>

                                    <td>{parseFloat(data?.leave_duration)}</td>
                                    <td>{data?.leave_type_name}</td>
                                    <td>
                                      {moment
                                        .utc(data?.requested_at)
                                        .format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                    <td>
                                      {data?.action_at === null
                                        ? "-- : --"
                                        : moment.utc(data?.action_at).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}
                                    </td>
                                    <td>
                                      {data?.reasons
                                        ?.map((obj) => {
                                          return obj?.reason;
                                        })
                                        .toString()}
                                    </td>
                                    <td>{data?.action_comments}</td>
                                    <td>
                                      {data?.status === "D"
                                        ? "Rejected"
                                        : data?.status === "A" &&
                                          data?.for_cancel === false
                                          ? "Approved"
                                          : data?.status === "A" &&
                                            data?.for_cancel === true
                                            ? "For Cancellation"
                                            : "Pending"}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-blanktd text-primary ml-4"
                                        onClick={() => {
                                          setViewDetails(true);
                                          displayLogs(
                                            data?.leave_detail_status
                                          );
                                        }}
                                      >
                                        <img src="images/eye.png" alt="" />
                                      </button>
                                    </td>
                                    <td>
                                      {data?.for_cancel === false &&
                                        checkForCancel(data, data?.from_date) ? (
                                        <Tooltip
                                          title="Cancel Leave"
                                          position="bottom"
                                        >
                                          <button
                                            className="btn btn-dangerNew"
                                            onClick={() => {
                                              handleDelete(data.id, true);
                                            }}
                                          >
                                            <i class="far fa-times-circle"></i>
                                          </button>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                      {/* data?.for_cancel === true &&
                                      data?.status === "A" ? (
                                      <div>For Cancellation</div>
                                    ) : (
                                      <div>Leave Cancelled</div>
                                    )} */}
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>

                      {leaveMgmtData?.approved?.length == 0 ? (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      ) : (
                        <div class="row">
                          <div class="col-sm-5">
                            <div class="sortinglist">
                              Show
                              <select
                                class="form-control main-pointer"
                                onChange={(e) => {
                                  setPageSizeNo(e.target.value);
                                  setCurrentPage(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab
                      eventKey="D"
                      title={
                        <React.Fragment>
                          Rejected{" "}
                          <span class="numberpurchse">
                            {leaveCounts?.rejected !== undefined
                              && leaveCounts?.rejected}
                          </span>
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Rejected From</th>
                              <th
                                onClick={(e) => {
                                  handlerData("from_date");
                                }}
                                className={
                                  sortData["from_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["from_date"]
                                }
                              >
                                From
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("to_date");
                                }}
                                className={
                                  sortData["to_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["to_date"]
                                }
                              >
                                To
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_duration");
                                }}
                                className={
                                  sortData["leave_duration"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_duration"]
                                }
                              >
                                Total Leaves
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_type__name");
                                }}
                                className={
                                  sortData["leave_type__name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_type__name"]
                                }
                              >
                                Leave Type
                              </th>
                              <th>Requested At</th>
                              <th>Rejected At</th>
                              <th>Reason</th>
                              <th>Remarks</th>
                              <th
                                onClick={(e) => {
                                  handlerData("status");
                                }}
                                className={
                                  sortData["status"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["status"]
                                }
                              >
                                Status
                              </th>
                              <th>Savior Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaveMgmtData?.rejected?.length > 0 &&
                              leaveMgmtData?.rejected?.map((data, index) => (
                                <tr>
                                  <td>
                                    {userData?.reporting_to_fname}{" "}
                                    {userData?.reporting_to_lname} (
                                    {userData?.reporting_to_name})
                                  </td>
                                  <td className="text-center">
                                    {`${moment(data?.from_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  ${data?.from_type !== ""
                                        ? data?.from_type === "First" ||
                                          data?.from_type === "F"
                                          ? " | F"
                                          : data?.from_type === "Second" ||
                                            data?.from_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>
                                  <td className="text-center">
                                    {`${moment(data?.to_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              ${data?.to_type !== ""
                                        ? data?.to_type === "First" ||
                                          data?.to_type === "F"
                                          ? " | F"
                                          : data?.to_type === "Second" ||
                                            data?.to_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>

                                  <td>{data?.leave_duration}</td>
                                  <td>{data?.leave_type_name}</td>
                                  <td>
                                    {moment
                                      .utc(data?.requested_at)
                                      .format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td>
                                    {moment.utc(data?.action_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {data?.reasons
                                      ?.map((obj) => {
                                        return obj?.reason;
                                      })
                                      .toString()}
                                  </td>
                                  <td>
                                  <Tooltip title={data?.action_comments} position="bottom">
                                  {data?.action_comments}
                                      </Tooltip>
                                    </td>                                 
                                    
                                  <td>
                                    {data?.status === "D"
                                      ? "Rejected"
                                      : data?.status === "A"
                                        ? "Approved"
                                        : "Pending"}
                                  </td>
                                  <td>
                                    {data?.savior_status === ""
                                      ? "OK"
                                      : data?.savior_status}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      {leaveMgmtData?.rejected?.length == 0 ? (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      ) : (
                        <div class="row">
                          <div class="col-sm-5">
                            <div class="sortinglist">
                              Show
                              <select
                                class="form-control main-pointer"
                                onChange={(e) => {
                                  setPageSizeNo(e.target.value);
                                  setCurrentPage(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>

                    <Tab
                      eventKey="C"
                      title={
                        <React.Fragment>
                          Cancelled{" "}
                          <span class="numberpurchse">
                            {leaveCounts?.cancelled !== undefined
                              && leaveCounts?.cancelled}
                          </span>
                        </React.Fragment>
                      }
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured">
                          <thead>
                            <tr>
                              <th>Rejected From</th>
                              <th
                                onClick={(e) => {
                                  handlerData("from_date");
                                }}
                                className={
                                  sortData["from_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["from_date"]
                                }
                              >
                                From
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("to_date");
                                }}
                                className={
                                  sortData["to_date"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["to_date"]
                                }
                              >
                                To
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_duration");
                                }}
                                className={
                                  sortData["leave_duration"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_duration"]
                                }
                              >
                                Total Leaves
                              </th>
                              <th
                                onClick={(e) => {
                                  handlerData("leave_type__name");
                                }}
                                className={
                                  sortData["leave_type__name"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["leave_type__name"]
                                }
                              >
                                Leave Type
                              </th>
                              <th>Requested At</th>
                              <th>Rejected At</th>
                              <th>Reason</th>
                              <th>Remarks</th>
                              <th
                                onClick={(e) => {
                                  handlerData("status");
                                }}
                                className={
                                  sortData["status"] === undefined
                                    ? "unsortcls main-pointer"
                                    : sortData["status"]
                                }
                              >
                                Status
                              </th>
                              <th>Savior Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaveMgmtData?.cancelled?.length > 0 &&
                              leaveMgmtData?.cancelled?.map((data, index) => (
                                <tr>
                                  <td>{data?.action_by}</td>
                                  <td className="text-center">
                                    {`${moment(data?.from_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  ${data?.from_type !== ""
                                        ? data?.from_type === "First" ||
                                          data?.from_type === "F"
                                          ? " | F"
                                          : data?.from_type === "Second" ||
                                            data?.from_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>
                                  <td className="text-center">
                                    {`${moment(data?.to_date).format(
                                      "DD-MM-YYYY"
                                    )}
                              ${data?.to_type !== ""
                                        ? data?.to_type === "First" ||
                                          data?.to_type === "F"
                                          ? " | F"
                                          : data?.to_type === "Second" ||
                                            data?.to_type === "S"
                                            ? " | S"
                                            : ""
                                        : ""
                                      }`}
                                  </td>

                                  <td>{data?.leave_duration}</td>
                                  <td>{data?.leave_type_name}</td>
                                  <td>
                                    {moment
                                      .utc(data?.requested_at)
                                      .format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td>
                                    {moment.utc(data?.action_at).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {data?.reasons
                                      ?.map((obj) => {
                                        return obj?.reason;
                                      })
                                      .toString()}
                                  </td>
                                  <td>{data?.action_comments}</td>
                                  <td>
                                    {data?.status === "D"
                                      ? "Rejected"
                                      : data?.status === "A" &&
                                        data?.for_cancel === false
                                        ? "Approved"
                                        : data?.status === "A" &&
                                          data?.for_cancel === true
                                          ? "For Cancellation"
                                          : data?.status === "C"
                                            ? "Cancelled"
                                            : "Pending"}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-blanktd text-primary ml-4"
                                      onClick={() => {
                                        setViewDetails(true);
                                        displayLogs(data?.leave_detail_status);
                                      }}
                                    >
                                      <img src="images/eye.png" alt="" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      {leaveMgmtData?.cancelled?.length == 0 ? (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      ) : (
                        <div class="row">
                          <div class="col-sm-5">
                            <div class="sortinglist">
                              Show
                              <select
                                class="form-control main-pointer"
                                onChange={(e) => {
                                  setPageSizeNo(e.target.value);
                                  setCurrentPage(1);
                                }}
                              >
                                <option value={10} label={10}>
                                  10
                                </option>
                                <option value={20} label={20}>
                                  20
                                </option>
                                <option value={30} label={30}>
                                  30
                                </option>
                                <option value={100} label={100}>
                                  100
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-7">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={2}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-left"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={filterclose}
            className="filter-popup modaldefaultclose"
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="form-group innergroup">
                <label>Select Leave Type</label>
                <Select
                  options={managementLeaveList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => handlePushData(e)}
                  value={dataMList}
                />
              </div>

              <div class="form-group innergroup">
                <label>From Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="from_date"
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      from_date: e.target.value,
                      to_date: "",
                    })
                  }
                  value={leaveFilterValue.from_date}
                />
              </div>

              <div class="form-group innergroup">
                <label>To Date</label>
                <input
                  type="date"
                  name="to_date"
                  class="form-control"
                  min={moment(leaveFilterValue?.from_date).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      to_date: e.target.value,
                    })
                  }
                  value={leaveFilterValue.to_date}
                />
              </div>
              <div class="form-group innergroup">
                <label>Select Year</label>
                <select
                  className="form-control newbgselect main-pointer"
                  name="year"
                  onChange={(e) => {
                    setLeaveFilterValue((prev) => ({
                      ...prev,
                      year: e.target.value,
                    }));
                  }}
                  value={leaveFilterValue?.year}
                >
                  <option value="" label="Select Leave Type" hidden />
                  <option
                    value={moment(new Date()).subtract(1, "year").format("YYYY")}
                    label={moment(new Date()).subtract(1, "year").format("YYYY")}
                  >
                    {moment(new Date()).subtract(1, "year").format("YYYY")}
                  </option>
                </select>
              </div>

              {/* <div class="form-group innergroup">
                <label>Select Status</label>
                <select
                  class="form-control newbgselect"
                  name="status"
                  onChange={(e) =>
                    setLeaveFilterValue({
                      ...leaveFilterValue,
                      status: e.target.value,
                    })
                  }
                  value={leaveFilterValue.status}
                >
                  <option value="">Select</option>
                  <option value="P">Pending</option>
                  <option value="A">Approved</option>
                  <option value="D">Rejected</option>
                </select>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  clearFilter();
                  setCurrentPage(1);
                }}
              >
                CLEAR
              </button>
              <button
                class="btn btn-primary-inner bpi-main"
                onClick={() => mainDataFilter()}
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={viewDetails}
            onHide={() => {
              setViewDetails(false);
              setDetailsData(null);
            }}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Leave Status Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="table-responsive">
                <table class="table table-striped table-bordered tablecured">
                  <thead>
                    <tr>
                      <th>Sno.</th>
                      <th>Leave Date</th>
                      <th>Savior Response</th>
                      <th>Synced At</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsData?.length > 0 ? (
                      detailsData?.map((data, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {moment(data?.leave_date).format("DD-MM-YYYY")}
                          </td>
                          <td>{data?.savior_response}</td>
                          <td>
                            {moment
                              .utc(data?.sync_date)
                              .format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td>
                            {data?.status
                              ? `Posted ${data?.is_cancelled ? "(Cancelled)" : ""
                              }`
                              : "Not Posted"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          {" "}
                          Pending For Posting
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  setViewDetails(false);
                  setDetailsData(null);
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
export default MyLeave;
