import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getDepartment = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/department?${queryParm}`);
};

// export const getDepartment = async (
//   pageNo,
//   pageSize,
//   sortBy,
//   search,
//   paginate,
//   org,
//   dept,
//   stats
// ) => {
//   return axios.get(
//     `${AUTH_BASE_URL}/department?search=${search}${org == undefined || org == "" || org == null
//       ? ""
//       : `&business_unit_id=${org}`
//     }${dept == undefined || dept == "" || dept == null
//       ? ""
//       : `&department_id=${dept}`
//     }${stats == undefined || stats == "" || stats == null
//       ? ""
//       : `&status=${stats}`
//     }&page_no=${pageNo}&page_size=${pageSize}&sort_by=${sortBy}&paginate=${paginate}`
//   );
// };

export const saveDepartment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/department`, data);
};

export const updateDepartment = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/department/${id}`, data);
};

export const deleteDepartment = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/department/${id}`);
};
export const getOrganisation = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/organisations?paginate=${paginate}&sort_by=name`
  );
};
export const getBranch = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/business_units?paginate=${paginate}&sort_by=name`
  );
};

export const isDeptExists = async (isData) => {
  return axios.post(`${AUTH_BASE_URL}/department/exists`, isData);
};

export const getAllDepartment = async (paginate) => {
  return axios.get(
    `${AUTH_BASE_URL}/department?paginate=${paginate}&sort_by=name`
  );
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/department/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};
