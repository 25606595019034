import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ModularTable from "./../../../../elements/ModularTable";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import {
  getLastMarketSize,
  getMarketSizeApprovalLogs,
  postMarketSize,
  putMarketSize,
} from "../../../../service/AgriPortalService/MarketSizeService";
import styles from "./Table.module.css";
import {
  getExpensePlan,
  fetchGlData,
  getExpensePlanApprovalLogs,
  getLastExpensePlan,
  postExpensePlan,
  postExpensePlanAction,
  putExpensePlan,
  getExpensePlanLowerGrid,
} from "../../../../service/AgriPortalService/ExpensePlanService";

import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS
  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [updatedTableData, setUpdatedTableData] = useState([[]]);

  const [tableData2, setTableData2] = useState([]);
  const [tableHeadData2, setTableHeadData2] = useState([]);

  const [mode, setMode] = useState();
  const [locationData, setLocationData] = useState({});

  const [freezeCTA, setFreezeCTA] = useState(false);
  const [loader, setLoader] = useState(false);
  const [approvalList, setApprovalList] = useState([]);
  const [expenseLowerGrid, setExpenseLowerGrid] = useState([]);
  const [gl, setGl] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    if (state?.flag === false) {
      setLoader(true);
      getExpensePlanLowerGrid(state?.code)
        .then((res) => {
          setLoader(false);
          setExpenseLowerGrid(res.data.dataList);
          setTableData2((state) => {
            let tableData = [];
            let newTableData = [];
            let months = [];

            res.data.dataList.map((ele) => {
              console.log("EXPENSE", ele);
              newTableData.push({
                value: [ele.field_work_name],
                meta: {},
              });
              let dataMonth = [];
              if (ele.month_data.length == 0) {
                dataMonth.push(
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  },
                  {
                    value: [0],
                    meta: {},
                  }
                );
              } else {
                let total = 0;
                ele.month_data.map((e) => {
                  total += e.total_cost;
                  dataMonth.push({
                    value: [e.total_cost],
                    meta: {},
                  });
                });
                dataMonth.push({
                  value: [total],
                  meta: {},
                });
              }
              months.push(dataMonth);
            });
            for (let i = 0; i < newTableData.length; i++) {
              tableData.push([newTableData[i], ...months[i]]);
            }
            return tableData;
          });
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, []);

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getExpensePlan()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  const [cfy, setCfy] = useState("");
  useEffect(() => {
    getCurrentFy()
      .then((res) => setCfy(res.data.dataList.fy))
      .catch((err) => console.log(err));

    fetchGlData()
      .then((res) => setGl(res?.data?.dataList))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!mode) return;
    let query = "/myplan/expenseplan";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    const totals = [{ value: "Total" }];

    for (let i = 0; i < data?.length; i++) {
      let total = 0;

      for (let j = 1; j < 13; j++) {
        if (!parseInt(data[i][j]?.value[0])) total += 0;
        else total += parseInt(data[i][j]?.value[0]);
      }
      data[i][13].value[0] = total;
    }

    for (let i = 1; i < data[0]?.length; i++) {
      let total = 0;
      for (let j = 0; j < data.length - 1; j++) {
        if (!parseInt(data[j][i].value[0])) total += 0;
        else total += parseInt(data[j][i].value[0]);
      }
      totals.push({ value: [total] });
    }

    data[data.length - 1] = totals;

    setUpdatedTableData([...data]);
  };

  const onTableDataChange2 = (data) => {
    // const totals = [{ value: "Total" }];
    // for (let i = 0; i < data?.length; i++) {
    //     let total = 0;
    //     for (let j = 1; j < 13; j++) {
    //         total += parseInt(data[i][j]?.value[0]);
    //     }
    //     data[i][13].value[0] = total;
    // }
    // for (let i = 1; i < data[0]?.length; i++) {
    //     let total = 0;
    //     for (let j = 0; j < data.length - 1; j++) {
    //         total += parseInt(data[j][i].value[0]);
    //     }
    //     totals.push({ value: [total] });
    // }
    // data[data.length - 1] = totals;
    // // setTableData2([...data]);
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;

    data.forEach((row) => {
      if (row[0].value != "Total") {
        let month_data = [];
        for (let i = 1; i < 13; i++) {
          month_data.push({
            id: 0,
            month_id: i,
            value: row[i]?.value[0] == "" ? "0" : row[i]?.value[0],
          });
        }

        let obj = {
          gl_id: row[0]?.meta?.gl_id,
          month_data,
        };

        ans.push(obj);
      }
    });

    return ans;
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      expense_plan_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postExpensePlanAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));
    let query = "";
    if (params.get("mode") != "create") {
      query = "?expense_plan_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getExpensePlanApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }

    getLastExpensePlan(query)
      .then((res) => {
        setRawData(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Expense Plan already exists!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        }
      });
  }, []);

  //Set table data after past data is fetched
  useEffect(() => {
    // if(!rawData.category_data) return;

    if (!gl || (mode != "create" && !rawData?.expense_data)) return;

    setTableHeadData((state) => {
      // if(!rawData) return [];

      let headData;

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Expense Head",
            type: "T",
            isEditable: false,
          },
          {
            name: "Apr",
            type: "N",
            isEditable: false,
          },
          {
            name: "May",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jun",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jul",
            type: "N",
            isEditable: false,
          },
          {
            name: "Aug",
            type: "N",
            isEditable: false,
          },
          {
            name: "Sep",
            type: "N",
            isEditable: false,
          },
          {
            name: "Oct",
            type: "N",
            isEditable: false,
          },
          {
            name: "Nov",
            type: "N",
            isEditable: false,
          },
          {
            name: "Dec",
            type: "N",
            isEditable: false,
          },
          {
            name: "Jan",
            type: "N",
            isEditable: false,
          },
          {
            name: "Feb",
            type: "N",
            isEditable: false,
          },
          {
            name: "Mar",
            type: "N",
            isEditable: false,
          },
          {
            name: "Total",
            type: "N",
            isEditable: false,
          },
        ];

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Expense Head",
            type: "T",
            isEditable: false,
          },
          {
            name: "Apr",
            type: "N",
            isEditable: true,
          },
          {
            name: "May",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jun",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jul",
            type: "N",
            isEditable: true,
          },
          {
            name: "Aug",
            type: "N",
            isEditable: true,
          },
          {
            name: "Sep",
            type: "N",
            isEditable: true,
          },
          {
            name: "Oct",
            type: "N",
            isEditable: true,
          },
          {
            name: "Nov",
            type: "N",
            isEditable: true,
          },
          {
            name: "Dec",
            type: "N",
            isEditable: true,
          },
          {
            name: "Jan",
            type: "N",
            isEditable: true,
          },
          {
            name: "Feb",
            type: "N",
            isEditable: true,
          },
          {
            name: "Mar",
            type: "N",
            isEditable: true,
          },
          {
            name: "Total",
            type: "N",
            isEditable: false,
          },
        ];

      return headData;
    });

    setTableHeadData2((state) => {
      // if(!rawData) return [];

      let headData;

      headData = [
        {
          name: "",
          type: "T",
          isEditable: false,
        },
        {
          name: "Apr",
          type: "N",
          isEditable: false,
        },
        {
          name: "May",
          type: "N",
          isEditable: false,
        },
        {
          name: "Jun",
          type: "N",
          isEditable: false,
        },
        {
          name: "Jul",
          type: "N",
          isEditable: false,
        },
        {
          name: "Aug",
          type: "N",
          isEditable: false,
        },
        {
          name: "Sep",
          type: "N",
          isEditable: false,
        },
        {
          name: "Oct",
          type: "N",
          isEditable: false,
        },
        {
          name: "Nov",
          type: "N",
          isEditable: false,
        },
        {
          name: "Dec",
          type: "N",
          isEditable: false,
        },
        {
          name: "Jan",
          type: "N",
          isEditable: false,
        },
        {
          name: "Feb",
          type: "N",
          isEditable: false,
        },
        {
          name: "Mar",
          type: "N",
          isEditable: false,
        },
        {
          name: "Total",
          type: "N",
          isEditable: false,
        },
      ];

      return headData;
    });

    setTableData((state) => {
      let newTableData = [];

      // let val1=0,val2=0,val3=0,val4=0,val5=0;

      for (let i = 0; i < gl?.length; i++) {
        newTableData.push([
          {
            value: [gl[i]?.gl_name],
            meta: gl[i],
          },
        ]);
      }
      newTableData.push([
        {
          value: ["Total"],
          meta: {},
        },
      ]);

      const months = [
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
        {
          value: [0],
          meta: {},
        },
      ];

      for (let i = 0; i < newTableData.length; i++) {
        newTableData[i] = [
          ...newTableData[i],
          ...JSON.parse(JSON.stringify(months)),
        ];
      }

      if (mode != "create") {
        for (let i = 0; i < rawData?.expense_data?.length; i++) {
          let idx = newTableData?.findIndex(
            (el) => el[0]?.meta?.gl_id == rawData?.expense_data[i]?.gl_id
          );

          if (idx == -1) continue;

          for (
            let j = 0;
            j < rawData?.expense_data[i]?.month_data?.length;
            j++
          ) {
            newTableData[idx][
              rawData?.expense_data[i]?.month_data[j]?.month_id
            ].value[0] = rawData?.expense_data[i]?.month_data[j]?.value;
          }
        }
      }
      return newTableData;
    });
  }, [gl, rawData]);

  //EVENT HANDLERS
  const onSaveHandler = async (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);

    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot save as cfy could not be fetched!");
    }
    cfy = cfy.data.dataList.fy;
    let territory_id = 0;
    if(localStorage.getItem("territory") !== 'undefined'){
      territory_id = JSON.parse(localStorage.getItem("territory"));
      territory_id = territory_id?.id;
    }
    // if (!territory_id) return;

    let body = {
      cfy,
      territory_id:territory_id,
      status: "D",
    };
    console.log(body);
    const expense_data = convertCategoryData(updatedTableData);
    console.log("EXPENSE", expense_data);
    if (!expense_data) return;
    body.expense_data = expense_data;
    body.cfy = cfy;
    console.log("BODY", body);
    if (mode == "create") {
      dispatch(setDisplayLoader("Display"));
      postExpensePlan(body)
        .then((res) => {
          dispatch(setDisplayLoader("Hide"));
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader("Hide"));
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        });
    }

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = cfy;
      dispatch(setDisplayLoader("Display"));
      putExpensePlan(body)
        .then((res) => {
          dispatch(setDisplayLoader("Hide"));
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader("Hide"));
          toast.error("Save Failed!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        });
    }
  };

  const confirmDialog = (type) => {
    if (freezeCTA) return;
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
          onClick: () => {
            setFreezeCTA(false);
          },
        },
      ],
      onClickOutside: () => {
        setFreezeCTA(false);
      },
      onKeypressEscape: () => {
        setFreezeCTA(false);
      },
    });
  };

  const onSubmitHandler = async (event) => {
    if (freezeCTA) return;

    setFreezeCTA(true);
    // let cfy = rawData?.cfy.split("-")[1];
    // if(cfy.length!==4) return;

    // cfy=rawData?.cfy;

    let cfy = await getCurrentFy();
    if (!cfy.data.dataList) {
      return toast.error("Cannot save as cfy could not be fetched!");
    }

    cfy = cfy.data.dataList.fy;

    let territory_id = 0;
    if(localStorage.getItem("territory") !== 'undefined'){
      territory_id = JSON.parse(localStorage.getItem("territory"));
      territory_id = territory_id?.id;
    }

    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    const expense_data = convertCategoryData(updatedTableData);

    if (!expense_data) return;
    body.expense_data = expense_data;

    if (mode == "create") {
      dispatch(setDisplayLoader("Display"));
      postExpensePlan(body)
        .then((res) => {
          dispatch(setDisplayLoader("Hide"));
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader("Hide"));
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        });
    }
    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      body.cfy = cfy;
      dispatch(setDisplayLoader("Display"));
      putExpensePlan(body)
        .then((res) => {
          dispatch(setDisplayLoader("Hide"));
          toast.success("Submit Succesfull!");
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        })
        .catch((err) => {
          dispatch(setDisplayLoader("Hide"));
          if (err?.response?.data?.code === 409) {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error("Submit Failed!");
          }
          setTimeout(() => {
            navigate("/myplan/expenseplan");
          }, 1000);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/myplan/expenseplan");
  };

  //JSX MARKUP
  return (
    <div className={styles["agri-portal-container"]}>
      {loader ? (
        <div class="loader_page">
          <img src="/images/loader.gif" alt="Mypi" />
        </div>
      ) : (
        <></>
      )}
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Expense Plan
          </h4>
          <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            VALUE IN RS
          </p>
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{cfy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            {locationData?.region_name && (
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>REGION</b>
                </div>
                <div className={styles["valuebox"]}>
                  {locationData?.region_name}
                </div>
              </div>
            )}
            {locationData?.territory_name && (
              <div
                style={{
                  zIndex: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <div className={styles["agri-portal-label"]}>
                  <b>TERRITORY</b>
                </div>
                <div className={styles["valuebox"]}>
                  {locationData?.territory_name}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              showTotal={true}
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
            />
          </div>
        </div>
        {expenseLowerGrid.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <ModularTable
                showTotal={false}
                columns={tableHeadData2}
                data={tableData2}
                onTableDataChange={onTableDataChange2}
              />
            </div>
          </div>
        )}
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                  ? "-"
                                  : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  confirmDialog("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;

// const columnSettings = [
//     {
//         name:"Category",
//         type:"T",
//         isEditable:false,
//     },
//     {
//         name:"2021-22",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2022-23",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2023-24",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2024-25",
//         type:"N",
//         isEditable:true,
//     },
//     {
//         name:"2025-26",
//         type:"N",
//         isEditable:true,
//     }
// ];

// const data = [
//     [
//         {
//             value:['Category 1'],
//             meta:{
//                 id:'abc'
//             }
//         },
//         {
//             value:[1234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[124],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[134],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[234],
//             meta:{
//                 id:12,
//             }
//         },
//         {
//             value:[12345],
//             meta:{
//                 id:12,
//             }
//         },
//     ],
// ]
