import React, { useState, useEffect } from "react";
import { Modal, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import Select from "react-select";
import * as userGl from "../../service/userGl";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";

const AddUserGl = (props) => {
  const { show, onCloseButtonClick, edit, editGlData, onAddCallBack } = props;
  let condition = "false";
  const dispatch = useDispatch();
  const type = [
    { value: "Petty", label: "Petty" },
    { value: "Other", label: "Other" },
    { value: "Gl Exception", label: "Gl Exception" },
  ];

  const [allEmployee, setAllEmployee] = useState([]);
  const [multiUser, setMultiUser] = useState([]);
  const [multiUserData, setMultiUserData] = useState([]);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [companyCode, setCompanyCode] = useState([]);
  const [companyCodeData, setCompanyCodeData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [glCodeOptions, setGlCodeOptions] = useState([]);
  const [glCodeOptionsData, setGlCodeOptionsData] = useState([]);
  const [costCentreOptions, setCostCentreOptions] = useState([]);
  const [personalAreaOptions, setPersonalAreaOptions] = useState([]);
  const [empGroup, setEmpGroup] = useState([]);
  const [empGroupData, setEmpGroupData] = useState([]);
  const [personalAreaOptionsData, setPersonalAreaOptionsData] = useState([]);
  const [costCentreOptionsData, setCostCentreOptionsData] = useState([]);
  const [typeCondition, setTypeCondition] = useState([]);
  const [imgSize, setImgSize] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [link, setLink] = useState(null);

  // const [uploadAttachments, setUploadAttachments] = useState({
  //   attachment_path: {},
  // });


  const [uploadAttachments, setUploadAttachments] = useState({
    attachment_path: [],
  });

  const [userGlForm, setUserGlForm] = useState({
    organisation_id: "",
    gl_type: "",
    gl_id: "",
    personal_area_id: "",
    user_id: "",
    start_date: "",
    end_date: "",
    is_active: true,
  });
  const [formValidation, setFormValidation] = useState({
    organisation_id: "",
    gl_id: "",
    user_id: "",
    gl_type: "",
    personal_area_id: "",
    end_date: "",
  });

  const validateRecords = () => {
    let isError = false;

    const valid = { ...formValidation };
    if (!userGlForm.organisation_id && typeCondition === "Petty") {
      isError = true;
      valid.organisation_id = "*Company Code is required ";
      setFormValidation(valid);
    }
    if (!userGlForm.gl_type) {
      isError = true;
      valid.gl_type = "*GL Type is required ";
      setFormValidation(valid);
    }
    if (!userGlForm.gl_id) {
      isError = true;
      valid.gl_id = "*GL Code is required ";
      setFormValidation(valid);
    }
    if (!userGlForm.user_id && typeCondition === "Petty") {
      isError = true;
      valid.user_id = "*User Code is required ";
      setFormValidation(valid);
    }
    if (!userGlForm.personal_area_id && typeCondition == "Other") {
      isError = true;
      valid.personal_area_id = "*Personal Area is required ";
      setFormValidation(valid);
    }
    // if (!userGlForm.start_date) {
    //   isError = true;
    //   valid.start_date = "*Start Date is required ";
    //   setFormValidation(valid);
    // }
    // if (!userGlForm.end_date) {
    //   isError = true;
    //   valid.end_date = "*End Date is required ";
    //   setFormValidation(valid);
    // }
    return isError;
  };

  const handleChange = (e, name) => {
    switch (name) {
      case "organisation_id":
        setUserGlForm((prev) => ({
          ...prev,
          organisation_id: e.value,
          gl_id:""
        }));
        setCompanyCodeData(e);
        break;
      case "gl_type":
        setUserGlForm((prev) => ({
          ...prev,
          gl_type: e.value,
        }));
        setTypeData(e);
        break;
      case "is_active":
        setUserGlForm((prev) => ({
          ...prev,
          is_active: e,
        }));
        break;
      case "gl_id":
        setUserGlForm((prev) => ({
          ...prev,
          gl_id: e.value,
        }));
        break;
      case "personal_area_id":
        setUserGlForm((prev) => ({
          ...prev,
          personal_area_id: e.value,
        }));
        break;
      case "user_id":
        setUserGlForm((prev) => ({
          ...prev,
          user_id: e.value,
        }));
        break;
      // case "vendor_card_id":
      //   setUserGlForm((prev) => ({
      //     ...prev,
      //     vendor_card_id: e.value,
      //   }));
      //   setVendorCardData(e);
      //   break;

      default:
    }
  };
  const saveTable = (values) => {
    let data = {};
    let glValues = glCodeOptionsData.map((x) => x?.value);
    let costcentreValues = costCentreOptionsData.map((x) => {
      return x.value;
    });
    let personalAreaValues = personalAreaOptionsData.map((x) => {
      return x.value;
    });

    let userSingle = allEmployeeData.value;
    let empsub = empGroupData?.map((x) => {
      return x.value;
    });

    let users = multiUserData?.value;
    let arr = [
      "gl_id",
      "user_id",
      "personal_area_id",
      "emp_sub_group",
      "cost_center_id",
      "attachment_path",
    ];
    data["gl_id"] = glValues;
    data["user_id"] = userSingle == null ? users : userSingle;
    data["personal_area_id"] = personalAreaValues;
    data["emp_sub_group"] = empsub;
    data["cost_center_id"] = costcentreValues;
    data["attachment_path"] = uploadAttachments?.attachment_path;

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      if (values[key] !== "" && arr.includes(key) === false) {
        data[key] = value;
      }
    });
    if (values?.id === undefined && condition == "false") {
      dispatch(setDisplayLoader("Display"));
      condition = "true";
      userGl
        .saveUserGl(data)
        .then((response) => {
          if (response.data.status === true && response.data.code === 200) {
            onAddCallBack();
            toast.success(response?.data?.message);
            condition = "false";
          }
          dispatch(setDisplayLoader("Hide"));

        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {

      dispatch(setDisplayLoader("Display"));

      userGl
        .updateUserGl(values?.id, data)
        .then((response) => {
          if (response.data.status === true && response.data.code === 200) {
            onAddCallBack();
            toast.success(response?.data?.message);
            condition = "false";
          }
          dispatch(setDisplayLoader("Hide"));
        })
        .catch((error) => {
          dispatch(setDisplayLoader("Hide"));
          console.error(error?.response?.data?.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const handleKey = (search, page_no, page_size, sort_by, paginate) => {
    let queryParm = "paginate=" + paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (page_no !== "" && page_no !== undefined && page_no !== null) {
      queryParm = queryParm + "&page_no=" + page_no;
    }
    if (page_size !== "" && page_size !== undefined && page_size !== null) {
      queryParm = queryParm + "&page_size=" + page_size;
    }
    return queryParm;
  };

  const getUsers = (search, page_no, page_size, sort_by, paginate) => {
    userGl
      .getOtherExpensePetty(
        handleKey(search, page_no, page_size, sort_by, paginate)
      )
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.user_id,
            label: `${x?.user_name}  (${x?.user_code})(${x?.business_email})`,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // const getVendorCard = () => {
  //   userGl
  //     .getVendorCard()
  //     .then((response) => {
  //       let data = [];
  //       response?.data?.dataList?.result.map((x) => {
  //         let empData = {
  //           value: x.id,
  //           label: `${x?.vendor} - ${x?.card_no}`,
  //           card: x?.card_no,
  //         };
  //         data.push(empData);
  //       });
  //       setVendorCard(data);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const getMultiUser = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {

          if(x?.is_active){

            let empData = {
              value: x.id,
              label: `${x?.first_name} ${x?.last_name} (${x?.username}) (${x?.business_email})`,
              record: x?.username,
            };
            data.push(empData);
          }

        });
        setMultiUser(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  // const getEmployeeData = (employee_code) => {
  //   userGl
  //     .getEmployeeData(employee_code)
  //     .then((response) => {
  //       let data = response?.data?.dataList?.result;
  //       setUserData(data);
  //       setUserFields(true);
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };
  const getCompany = (paginate) => {
    userGl
      .getCompany(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataCompany = {
            value: x.id,
            label: `${x?.name}`,
          };
          data.push(dataCompany);
        });
        setCompanyCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getGlCode = (organisation_id, value) => {
    userGl
      .getGlCode(organisation_id, value)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataGl = {
            value: x.id,
            label: `${x?.short_desc} (${x?.gl_account}) ${x?.is_blocked?"- Blocked":""}`,
          };
          data.push(dataGl);
        });
        let pp = data.filter(
          (ele, ind) =>
            ind === data.findIndex((elem) => elem.label === ele.label)
        );
        setGlCodeOptions(pp);
        getPersonalArea();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getCostCentre = (id) => {
    userGl
      .getCostCentre(id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataGl = {
            value: x.id,
            label: `${x?.name} (${x?.code}) ${x?.is_blocked?"- Blocked":""}`,
          };
          data.push(dataGl);
        });
        setCostCentreOptions(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getPersonalArea = () => {
    userGl
      .getPersonalArea()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let dataGl = {
            value: x.id,
            label: `${x?.description} (${x?.name})`,
          };
          data.push(dataGl);
        });
        setPersonalAreaOptions(data);
        getMultiUser(false);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getEmpSubGroup = () => {
    userGl
      .getEmpSubGroup()
      .then((response) => {
        let data = [];
        let res = response?.data?.dataList?.result;
        res.map((obj) => {
          let dataGl = {
            value: obj.emp_sub_group,
            label: `${obj?.emp_sub_group}`,
          };
          data.push(dataGl);
        });
        setEmpGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const getEmpSubData = () => {
  //   let data = personalAreaOptionsData.map((item) => {
  //     return item.id;
  //   });
  //   getEmpSubGroup(data);
  // };



  // const onProfileChange = (e, flag) => {
  //   const files = e.target.files[0];
  //   if (
  //     (files.size < 1033415 || files.size === 1033415) &&
  //     (files?.type === "application/pdf" ||
  //       files?.type === "image/jpeg" ||
  //       files?.name.includes(".msg"))
  //   ) {
  //     setLink(undefined);
  //     setImgSize(false);
  //     const fileData = new FormData();
  //     fileData.append("uploaded_file", files);
  //     userGl.uploadAttachment(fileData).then((response) => {
  //       if (flag === "attachment_path") {
  //         setUploadAttachments({
  //           ...uploadAttachments,
  //           attachment_path: response.data,
  //         });
  //       }
  //     });
  //   } else {
  //     setImgSize(true);
  //   }
  // };


  
  const onProfileChange = async (e, flag) => {
    let filesArr = e.target.files
    let _attachment_path=[]
    // console.log(`files`,filesArr);

    for(let i=0;i<filesArr.length;i++) {
      const files = filesArr[i];

      // console.log(`filing`,files);

    if (
      (files.size < 1033415 || files.size === 1033415) &&
      (files?.type === "application/pdf" ||
        files?.type === "image/jpeg" ||
        files?.type === "image/jpg" ||
        files?.name.includes(".msg"))
    ) {
      setLink(undefined);
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      await userGl.uploadAttachment(fileData).then((response) => {
        if (flag === "attachment_path") {
          // console.log(`response`,response.data);
          _attachment_path.push(response.data)
          
        }
      });
    } else {
      setImgSize(true);
    }
  }

  setUploadAttachments((pre)=>({
    ...pre,
    attachment_path:_attachment_path
  }));

  // console.log('_att',_attachment_path);
  };



  const handleExists = (data) => {
    if (validateRecords()) return false;
    if (data?.gl_type !== "Other") {
      let error = false;
      let users = multiUserData?.value;
      let usersGl = allEmployeeData?.value;

      if (edit) {
        if (editGlData?.organisation_id !== data?.organisation_id) {
          error = true;
        }
        if (editGlData?.gl_type !== data?.gl_type) {
          error = true;
        }
        if (editGlData?.user_id !== users) {
          error = true;
        }
        if (editGlData?.user_id !== usersGl) {
          error = true;
        }
      }
      if (error === false && data?.id) {
        saveTable(data);
      }
      if (error === true && data?.id) {
        isDataExist(data);
      }
      if (error === false && data?.id === undefined) {
        isDataExist(data);
      }
    } else {
      saveTable(data);
    }
  };

  const isDataExist = (userGlForm) => {

    if(userGlForm?.gl_type == "Gl Exception"){
      console.log(`condition..`,condition);
      console.log(`values`,userGlForm);
      saveTable(userGlForm)
      return
    }
    
    let users = multiUserData?.value;
    let usersGl = allEmployeeData?.value;

    userGl
      .isExists(
        userGlForm?.gl_type == "Gl Exception" ? users : usersGl,
        userGlForm?.organisation_id,
        userGlForm?.gl_type
      )
      .then((res) => {
        let data = res?.data?.dataList?.result  ?.exist;
        setIsExist(res?.data?.dataList?.result?.exist);
        if (data == false) {
          saveTable(userGlForm);
        }
      })
      .catch((err) => console.log("Error=>", err));
  };

  useEffect(() => {
    getUsers("", "", "", "", false);
    getCompany(false);
    getEmpSubGroup();
  }, []);

  useEffect(() => {
    if (editGlData) {

      console.log(`editdata`,editGlData);

      // setUploadAttachments({
      //   ...uploadAttachments,
      //   attachment_path:editGlData?.attachment
      // })
      
      getCostCentre(editGlData?.organisation_id);
      setTypeCondition(editGlData?.gl_type);
      let companyCode = {
        value: editGlData?.organisation_id,
        label: editGlData?.organisation_name,
      };
      setCompanyCodeData(companyCode);

      let userCode = {
        value: editGlData.user_id,
        label: editGlData?.user_name + " " + "(" + editGlData?.user_code + ")",
      };

      setAllEmployeeData(userCode);
      setMultiUserData(userCode);
      let typess = { value: editGlData?.gl_type, label: editGlData?.gl_type };
      setTypeData(typess);
      let empsubgrp = editGlData?.emp_sub_group.map((x) => {
        return {
          value: x.emp_sub_group,
          label: x?.emp_sub_group,
        };
      });
      setEmpGroupData(empsubgrp);

      let gLcode = editGlData?.gl_id.map((x) => {
        return { value: x.gl_id, label: `${x.gl_desc} (${x?.gl_account})` };
      });
      setGlCodeOptionsData(gLcode);
      let personalArea = editGlData?.personal_area_id.map((x) => {
        return {
          value: x.personal_area_id,
          label: `${x?.personal_area_description} (${x?.personal_area_name})`,
        };
      });
      setPersonalAreaOptionsData(personalArea);
      getEmpSubGroup();
      let costcentre = editGlData?.cost_center_id.map((x) => {
        return { value: x.cost_center_id, label: `${x?.cost_center_name} (${x?.cost_center_code})` };
      });

      setCostCentreOptionsData(costcentre);
      Object.keys(editGlData).forEach((item) => {
        userGlForm[item] = editGlData[item];
      });
      setUserGlForm(editGlData);
    }
  }, [editGlData]);

  const pushGlCode = (e) => {
    e?.map((value) => {
      handleChange(value, "gl_id");
    });
    setGlCodeOptionsData(e);
  };
  const pushCostCentreCode = (e) => {
    setCostCentreOptionsData(e);
  };
  const pushPersonalArea = (e) => {
    e?.map((value) => {
      handleChange(value, "personal_area_id");
    });
    setPersonalAreaOptionsData(e);
  };
  const pushEmpSubGroup = (e) => {
    setEmpGroupData(e);
  };
  const pushUsers = (e, flag) => {
    if (flag === "singleSelect") {
      // e?.map((value) => {
      handleChange(e, "user_id");
      // });
      let userCode = {
        value: e.value,
        label: e?.label,
      };
      setAllEmployeeData(userCode);
    } else {
      // e?.map((value) => {
      handleChange(e, "user_id");
      handleChange(e, "personal_area_id");
      // });
      let userCode = {
        value: e.value,
        label: e?.label,
      };
      setMultiUserData(userCode);
      console.log(e.value, "userId");
    }
  };

  useEffect(() => {
    if (userGlForm.gl_type == "Other" && userGlForm.gl_type !== "") {
      getGlCode("", 7);
    } else if (
      (userGlForm.gl_type == "Petty" ||
        userGlForm.gl_type === "Gl Exception") &&
      userGlForm.organisation_id !== ""
    ) {
      getGlCode(userGlForm.organisation_id, 7);
    }
  }, [userGlForm]);

  return (
    <>
      <Modal
        show={show}
        onHide={onCloseButtonClick}
        size="lg"
        backdrop="static"
        keyboard={false}
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <ToastContainer autoClose={1000} />

        <Modal.Header closeButton>
          <Modal.Title>{edit ? "Edit" : "Add"} GL Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label style={{ marginRight: "61px" }}>
                      Type<span className="text-danger">*</span>
                    </label>
                    <Select
                      options={type}
                      name="gl_type"
                      isDisabled={edit}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e, action) => {
                        handleChange(e, action.name);
                        setTypeCondition(e.value);
                        setFormValidation({
                          ...formValidation,
                          gl_type: "",
                        });
                      }}
                      value={typeData}
                    />
                    <div className="small text-danger">
                      {formValidation.gl_type}
                    </div>

                    {/* <label
                      class="logCheck d-inline-block mr-4"
                      style={{ marginTop: "29px" }}
                    >
                      <input
                        type="radio"
                        name="gl_type"
                        value="Petty"
                        checked={typeCondition === "Petty"}
                        onChange={handleRadioChange}
                      />
                      <span class="checkmark"></span>
                      Petty
                    </label>
                    <label class="logCheck d-inline-block mr-4">
                      <input
                        type="radio"
                        name="gl_type"
                        value="Other"
                        checked={typeCondition === "Other"}
                        onChange={handleRadioChange}
                      />
                      Other
                      <span class="checkmark"></span>
                    </label> */}
                  </div>
                </div>
                {typeCondition === "Petty" ||
                typeCondition == "Gl Exception" ? (
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Company Code<span className="text-danger">*</span>
                      </label>
                      <Select
                        name="organisation_id"
                        options={companyCode}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          handleChange(e, action.name);
                          getCostCentre(e.value);
                          setGlCodeOptions([]);
                          setCostCentreOptionsData([]);
                          setCostCentreOptions([]);
                          setGlCodeOptionsData([]);
                          setMultiUserData([]);
                          setEmpGroupData([]);
                          setPersonalAreaOptionsData([]);
                          setFormValidation({
                            ...formValidation,
                            organisation_id: "",
                          });
                        }}
                        value={companyCodeData}
                      />
                      <div className="small text-danger">
                        {formValidation.organisation_id}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* {typeCondition === "Petty" && (
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>
                        Vendor Code & Card Number
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="vendor_card_id"
                        options={vendorCard}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e, action) => {
                          handleChange(e, action.name);
                          setFormValidation({
                            ...formValidation,
                            vendor_card_id: "",
                          });
                        }}
                        value={vendorCardData}
                      />
                      <div className="small text-danger">
                        {formValidation.vendor_card_id}
                      </div>
                    </div>
                  </div>
                )} */}
                {typeCondition === "Petty" ||
                typeCondition === "Other" ||
                typeCondition === "Gl Exception" ? (
                  <div className="col-md-6">
                    <div className="form-group innergroup">
                      <label>GL Code</label>
                      <span className="text-danger">*</span>
                      <Select
                        name="gl_id"
                        isMulti
                        options={glCodeOptions}
                        className="basic-multi-select"
                        closeMenuOnSelect={false}
                        classNamePrefix="select"
                        onChange={(e) => {
                          pushGlCode(e);
                          setFormValidation({
                            ...formValidation,
                            gl_id: "",
                          });
                        }}
                        value={glCodeOptionsData}
                      />
                      <div className="small text-danger">
                        {formValidation.gl_id}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {typeCondition === "Petty" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>Cost Centre</label>
                        <Select
                          name="cost_center_id"
                          options={costCentreOptions}
                          isMulti
                          className="basic-multi-select"
                          closeMenuOnSelect={false}
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushCostCentreCode(e);
                          }}
                          value={costCentreOptionsData}
                        />
                      </div>
                    </div>
                  </>
                )}
                {typeCondition === "Other" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>Personal Area</label>
                        <span className="text-danger">*</span>
                        <Select
                          name="personal_area_id"
                          options={personalAreaOptions}
                          isMulti
                          className="basic-multi-select"
                          closeMenuOnSelect={false}
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushPersonalArea(e);
                            setFormValidation({
                              ...formValidation,
                              personal_area_id: "",
                            });
                          }}
                          value={personalAreaOptionsData}
                        />
                        <div className="small text-danger">
                          {formValidation.personal_area_id}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {typeCondition === "Other" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>Emp Sub Group</label>
                        <Select
                          name="emp_sub_group"
                          options={empGroup}
                          isMulti
                          className="basic-multi-select"
                          closeMenuOnSelect={false}
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushEmpSubGroup(e);
                          }}
                          value={empGroupData}
                        />
                      </div>
                    </div>
                  </>
                )}

                {typeCondition === "Petty" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>
                          User Code<span className="text-danger">*</span>
                        </label>
                        <Select
                          name="user_id"
                          options={allEmployee}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushUsers(e, "singleSelect");
                            setFormValidation({
                              ...formValidation,
                              user_id: "",
                            });
                          }}
                          value={allEmployeeData}
                        />
                        <div className="small text-danger">
                          {formValidation.user_id}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {typeCondition === "Gl Exception" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>Username</label>
                        <Select
                          name="user_id"
                          options={multiUser}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            pushUsers(e, "multiSelect");
                          }}
                          value={multiUserData}
                        />
                        <div className="small text-danger">
                          {formValidation.user_id}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {typeCondition === "Gl Exception" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>Start Date</label>
                        <span className="text-danger">*</span>
                        <input
                          type="date"
                          name="start_date"
                          className="form-control"
                          onChange={(e) => {
                            setUserGlForm({
                              ...userGlForm,
                              start_date: e.target.value,
                            });
                          }}
                          value={userGlForm?.start_date}
                        />
                        <div className="small text-danger">
                          {formValidation.start_date}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {typeCondition === "Gl Exception" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group innergroup">
                        <label>End Date</label>
                        <span className="text-danger">*</span>
                        <input
                          type="date"
                          name="end_date"
                          className="form-control"
                          onChange={(e) => {
                            setUserGlForm({
                              ...userGlForm,
                              end_date: e.target.value,
                            });
                          }}
                          value={userGlForm?.end_date}
                        />
                        <div className="small text-danger">
                          {formValidation.end_date}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-6">
                  <div className="form-group innergroup position-relative modaldefaultclose ">
                    <label>
                      Upload files <span className="text-danger"></span>
                    </label>

                    {/* <input
                      type="text"
                      className="form-control bg-white"
                      value={uploadAttachments?.attachment_path?.original_name}
                      disabled
                      placeholder="(Image, PDF, MSG Format)"
                    />
                     */}
                    <input
                      type="text"
                      className="form-control bg-white"
                      // value={uploadAttachments?.attachment_path?.original_name}
                      disabled
                      placeholder="(Image, PDF, MSG Format)"
                    />
                    

                    <div className="upload-btn-wrapper up-loposition">
                      <button className="uploadBtn">Browse</button>
                      <input
                        type="file"
                        multiple
                        // id="attachment_path"
                        // name="attachment_path"
                        accept=".msg, .pdf, .jpg"
                        onChange={(e) => onProfileChange(e, "attachment_path")}
                      />
                    </div>
                    

                    {imgSize ? (
                      <div className="small text-danger">
                        File size should not exceed 1mb
                      </div>
                    ) : null}
                    {uploadAttachments?.attachment_path?.length >0 && (
                      <p>
                        Selected files{" "}
                        {/* <span>
                          {" "}
                          {uploadAttachments?.attachment_path?.original_name}
                        </span> */}

                        { uploadAttachments?.attachment_path?.map((at_data,_idx)=>(
                          <span >
                          {at_data?.original_name+(uploadAttachments?.attachment_path?.length!=_idx+1?", ":"")}
                        </span>
                        ))}

                      </p>
                    )}
                    
                    {/* {link !== undefined && (
                      <p>Selected file {<span> {link}</span>}</p>
                    )} */}

                     {/* { 
                         editGlData ?
                        
                         editGlData?.attachment?.map((adata,_idx)=>(
                           <div>{_idx+1}.<Link target="_blank" to={adata?.attachment_path}>
                           View attachment
                         </Link></div>)):""
                      } */}
                    
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group innergroup">
                    <label className="d-block">Status</label>
                    <label
                      className="logCheck d-inline-block mr-4"
                      htmlFor="active"
                    >
                      Active
                      <input
                        type="radio"
                        name="is_active"
                        id="active"
                        value={userGlForm.is_active}
                        onChange={() => {
                          setUserGlForm({
                            ...userGlForm,
                            is_active: true,
                          });
                        }}
                        checked={userGlForm.is_active === true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label
                      className="logCheck d-inline-block"
                      htmlFor="Inactive"
                    >
                      Inactive
                      <input
                        type="radio"
                        name="is_active"
                        id="Inactive"
                        value={userGlForm.is_active}
                        onChange={(e) => {
                          setUserGlForm({
                            ...userGlForm,
                            is_active: false,
                          });
                        }}
                        checked={userGlForm.is_active === false}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {/* { 
                         (userGlForm?.attachment_path?.match(new RegExp('[^.]+$')) != "pdf" || userGlForm?.attachment_path?.match(new RegExp('[^.]+$')) != "msg")
                          ?                         
                           <img src={userGlForm?.attachment_path} /> 
                          : ""
                      

                     } */}
                </div>
              </div>
              <div className="row">
              {
                        editGlData && editGlData?.attachment?.length>0?
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable">
                            <thead>
                                <tr>
                              
                                  <th>FILE NAME	</th>
                                  <th>VIEW</th>
                                
                                </tr>
                            </thead>
                            <tbody>
                            {editGlData?.attachment?.map((adata,idx)=>(
                                <tr>           
                                  <td>{adata?.attachment_path?.substring(adata?.attachment_path?.lastIndexOf("/")+1)}</td>
                                  <td>
                                      <Link target="_Blank" to={adata?.attachment_path}>View</Link>
                                  </td>
                                </tr>
                                
                            ))}
                            </tbody>
                           </table>
                        :""
                      }

              </div>
            </div>
          </div>
        </Modal.Body>
        {isExist && (
          <Alert variant="danger" className="mb-5 mt-2 text-center">
            <i className="fa fa-exclamation-triangle"></i>This user and company code already exists 
          </Alert>
        )}
        <Modal.Footer>
          <button
            className="btn btn-primary-inner bpi-main"
            type="button"
            onClick={() => {
              handleExists(userGlForm);
            }}
          >
            Submit
          </button>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={onCloseButtonClick}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUserGl;
