import React from "react";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import Select from "react-select";

const ViewCategorySurvey = (props) => {
  const { viewForm, onViewClose, viewFormData } = props;

  console.log(viewFormData, "ffff");
  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];
  return (
    <Modal
      show={viewForm}
      onHide={onViewClose}
      size="lg"
      backdrop="static"
      className="modaldefaultclose modaldefaultclosecenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>View Survey Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Survey Category Name<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    value={viewFormData?.name}
                    placeholder="Enter Policy Category Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group innergroup">
                  <label>
                    Organization<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    value={viewFormData?.organisation_name}
                    placeholder="Enter Policy Category Name"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group innergroup">
                  <label className="d-block">
                    Status<span class="text-danger">*</span>
                  </label>
                  <label class="logCheck d-inline-block mr-4">
                    Active
                    <input
                      type="radio"
                      name="statusupdate1"
                      checked={viewFormData.is_active === true}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <label class="logCheck d-inline-block">
                    Inactive
                    <input
                      type="radio"
                      name="statusupdate1"
                      checked={viewFormData.is_active === false}
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewCategorySurvey;
