import axios from "../utils/axios";
import { AUTH_BASE_URL } from "../constants/URL";

export const getDepartment = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/skill-dept-master?${queryParm}`);
};
export const postDepartment = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/skill-dept-master`,data);
};
export const updateDepartment = async (data,id) => {
  return axios.put(`${AUTH_BASE_URL}/skill-dept-master/${id}`,data);
};
export const deleteSkill = async (id) => {
  return axios.delete(`${AUTH_BASE_URL}/skill-dept-master/${id}`);
};
