import React from "react";
import PropTypes from "prop-types";
import { Attachment } from "./Attachment";
import { useDispatch } from "react-redux";
import { setDisplayLoader, selectLoader } from "../../../../pages/redux/piDataStore";
const Attachments = (props) => {
  const {attachmentlist} = props;
  const attachmentData = [
    {
      index: 0,
      id: "bill",
      label : 'Invoice Attachment',
      placeHolder:'(PDF, PNG, JPEG, JPG, xlsx Format only)',
      errorAttachment : '',
      accept:'image/png,image/jpg,image/jpeg,video/mp4,application/pdf,.xlsx,.xls,.doc,.docx',
    },
    {
      index: 1,
      id: "additional",
      label : 'Bill Attachment',
      placeHolder:'(PDF, PNG, JPEG, JPG, xlsx Format only)',
      errorAttachment : '',
      accept:'image/png,image/jpg,image/jpeg,video/mp4,application/pdf,.xlsx,.xlsx,.doc,.docx',
    },
    {
      index: 2,
      id: "other",
      label : 'Other Attachment',
      placeHolder:'(PDF, PNG, JPEG, JPG, xlsx Format only)',
      errorAttachment : '',
      accept:'image/png,image/jpg,image/jpeg,video/mp4,application/pdf,.xlsx,.xlsx,.doc,.docx',
    }
  ];
  return (
    <div class="bg-light px-3 pt-3 mb-3">
      <div class="row">
        {attachmentlist.length && attachmentData.map((item)=> <Attachment key={item.index} {...item} {...props} />)}
      </div>
    </div>
  );
};

Attachments.propTypes = {};

export { Attachments };
