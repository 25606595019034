import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import JivaTab from "../../assets/images/loginjiva.png";
import MyPiTab from "../../assets/images/loginmypi.png";
import { SendOTP } from "../../service/login";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";

function GetOTP() {
  const validationSchema = Yup.object().shape({
    email_otp: Yup.string().required("Please enter valid username."),
    // username: Yup.string().required("Please enter valid username."),
    mobile_otp: Yup.string()
      .email("Please enter valid email")
      .required("Please enter  email."),
  });
  let navigate = useNavigate();
  const [logoValue, setLogoValue] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [MobileError, setMobileError] = useState(false);

  const [otpData, setOtptData] = useState({
    email_otp: "",
    mobile_otp: "",
    username: "",
  });

  const handleLogo = (e) => {
    setLogoValue(e);
  };
  const SubmitOTP = (value) => {
    value.username = localStorage.getItem("username");
    console.log(Number(value.email_otp.length) == 6);
    if (Number(value.email_otp.length) != 6) {
      setEmailError(true);
    }
    if (Number(value.mobile_otp.length) != 6) {
      setMobileError(true);
    }
    if (!emailError && !MobileError) {
      SendOTP(value)
        .then((response) => {
          navigate("/");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const Input = (e, id) => {
    setEmailError(false);

    let email_otp = [];
    let data = document.getElementsByClassName("jai");
    if (data[id].value != "") {
      if (id <= 4) {
        data[id].nextElementSibling.focus();
      }
    } else {
      if (e.key == "Backspace") {
        data[id].previousElementSibling.focus();
      }
    }
    for (let i = 0; i <= 5; i++) {
      email_otp.push(data[i].value);
    }
    otpData.email_otp = email_otp.toString().replaceAll(",", "");

    console.log("email_otp", email_otp.toString().replaceAll(",", ""));
  };
  const Input2 = (e, id) => {
    setMobileError(false);
    let mobile_otp = [];
    let data = document.getElementsByClassName("jai2");
    if (data[id].value != "") {
      if (id <= 4) {
        data[id].nextElementSibling.focus();
      }
    } else {
      if (e.key == "Backspace") {
        data[id].previousElementSibling.focus();
      }
    }
    for (let i = 0; i <= 5; i++) {
      mobile_otp.push(data[i].value);
    }

    otpData.mobile_otp = mobile_otp.toString().replaceAll(",", "");
  };
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className={logoValue === "jiva" ? "login-bg login-bg2" : "login-bg"}>
        <div className="container">
          <div className="row justify-content-between my-3">
            <div className="col-md-6 col-lg-6">
              <img src={Logo} alt="" />
              <h1>
                Somewhere,
                <br />
                something
              </h1>
              <h2>incredible is waiting to be known.</h2>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="loginTabs">
                <Formik
                  initialValues={otpData}
                  onSubmit={async (values) => {
                    SubmitOTP(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <div className="tabfliping">
                      <h3>Verify your Email ID and Mobile Number</h3>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label className="mb-0">
                            Enter Email OTP
                           <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex justify-content-start otp-input">
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 0)}
                              min="1"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 1)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 2)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 3)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 4)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai"
                              onKeyUp={(e) => Input(e, 5)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                          </div>
                          {emailError && (
                            <span className="small text-danger">
                              Please enter valid Email OTP
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-0">
                            Enter Mobile OTP
                           <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex justify-content-start otp-input">
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 0)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 1)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 2)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 3)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 4)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                            <input
                              type="number"
                              className="jai2"
                              onKeyUp={(e) => Input2(e, 5)}
                              min="0"
                              max="9"
                              maxlength="1"
                              placeholder="enter"
                            />
                          </div>
                          {MobileError && (
                            <span className="small text-danger">
                              Please enter valid OTP
                            </span>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-block btn-primary"
                        >
                          Get Password
                        </button>
                        <div className="text-center mt-4">
                          <Link to="/forgot" className="forgettxt">
                            <i className="fas fa-chevron-left f-12"></i> Back to
                            Forgot
                          </Link>
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetOTP;
