import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getCommonApproval } from "../../service/dashboard";
import { Spinner } from "react-bootstrap";
import * as TravelListingAPI from "../../service/travelService";
import * as DomesticService from "../../service/domestic";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";

function ViewTravelExpenseData() {
  const { state } = useLocation();
  let doubleClick = "false";
  let navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector(selectUserData);
  const [id, setid] = useState(location?.state?.from);
  const [udata, setUdata] = useState();
  const [travelDetails, setTravelDetails] = useState([]);
  const [dataNew, setDataNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [foreignExpData, setForeignExpData] = useState(undefined);
  const [foreignHotel, setForeignHotel] = useState();
  const [foreignFood, setForeignFood] = useState();
  const [foreignDa, setForeignDa] = useState();
  const [foreignConv, setForeignConv] = useState();
  const [remarksData, setRemarksData] = useState({
    id: "",
    status: "",
    remark: "",
  });
  const [sd, setSd] = useState();
  const [vehicleData, setVehicalData] = useState({});
  const [formValidation, setFormValidation] = useState({
    remark: "",
  });
  const [amountHotel, setAmountHotel] = useState();
  let abortTravelExpenseVehicleSummary = null;

  const saveExpenseApproveData = (value) => {
    if (doubleClick === "false") {
      doubleClick = "true";
      setIsLoading(true);
      let arrOtherExpense = { approval_data: [] };
      let data = remarksData;
      data.id = id;
      data.status = value;
      data.remark = remarksData.remark;
      arrOtherExpense?.approval_data?.push(data);
      TravelListingAPI.saveExpenseApproveData(arrOtherExpense)
        .then((response) => {
          toast.success(response.data.message);
          setIsLoading(false);
          doubleClick = "false";
          navigate(`/common-approve/${location?.state?.subId}`, {
            state: {
              subtypeid: location?.state?.subId,
              subTypeName: location?.state?.type,
            },
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const getTravelExpenseVehicleSummary = (userId, travelId) => {
    abortTravelExpenseVehicleSummary = new AbortController();
    TravelListingAPI.getTravelExpenseVehicleSummary(
      userId,
      travelId,
      abortTravelExpenseVehicleSummary.signal
    ).then((res) => {
      if (res.status) setVehicalData(res.data.dataList);
    });
  };
  const getApproval = (
    sub_type_id,
    page_no,
    page_size,
    sortby,
    paginate,
    search,
    is_exception
  ) => {
    getCommonApproval(
      sub_type_id,
      page_no,
      page_size,
      sortby,
      paginate,
      search,
      is_exception
    )
      .then((response) => {
        let data = response?.data?.dataList?.result?.approvals?.result?.filter(
          (ele) => {
            if (Number(ele?.transaction_id) == Number(location?.state?.from)) {
              return ele;
            }
          }
        );
        let value = data[0];
        setTravelDetails(value?.details[0]);
        setUdata(value);
        getTravelExpenseVehicleSummary(data[0]?.created_by_id, state?.from);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getForeignExpData = async (data) => {
    await TravelListingAPI.getForeignExpData(data)
      .then((response) => {
        let res = response?.data?.dataList;
        setForeignExpData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let sum = 0;
    travelDetails?.hotel_data?.map((value, i) => {
      sum = value?.claim_amount + value?.tax_amount + sum;
    });
    setAmountHotel(sum);
  }, [travelDetails]);
  const calculateDateDifference = (fromDate, tillDate) => {
    const startDate = new Date(fromDate);
    const endDate = new Date(tillDate);

    if (startDate && endDate && !isNaN(startDate) && !isNaN(endDate)) {
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    } else {
      return 0;
    }
  };

  const getUsersDetails = (employee_code) => {
    DomesticService.getUsersDetails(employee_code)
      .then((response) => {
        let data = response?.data?.dataList?.result;
        setDataNew(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleValidate = () => {
    let isError = false;

    const formerr = { ...formValidation };
    if (!remarksData.remark) {
      isError = true;
      formerr.remark = "Remarks Required";
      setFormValidation(formerr);
    }
    return isError;
  };

  const handleSaveValue = (value) => {
    if (handleValidate()) return false;
    saveExpenseApproveData(value);
  };

  useEffect(() => {
    if (location?.state?.type === "Travel Expense Exception") {
      getApproval(state?.subId, 1, 10, "-id", true, "", "true");
    } else {
      getApproval(state?.subId, 1, 10, "-id", true, "", "false");
    }
  }, [location?.state]);
  useEffect(() => {
    getForeignExpData(state?.from);
  }, [location?.state]);

  useEffect(() => {
    let sum = 0;
    travelDetails?.da_data?.map((ddd, i) => {
      sum = ddd?.gross_total + sum;
    });
    setSd(sum);
  }, [travelDetails]);

  useEffect(() => {
    getUsersDetails(userData?.username);
    return () => {
      if (abortTravelExpenseVehicleSummary)
        abortTravelExpenseVehicleSummary.abort();
    };
  }, []);
  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    let sum2 = 0;
    let sum3 = 0;
    foreignExpData?.hotel_data?.map((ddd, i) => {
      sum = ddd?.amount_in_inr + sum;
    });
    foreignExpData?.food_data?.map((ddd, i) => {
      sum1 = ddd?.amount_in_inr + sum1;
    });
    foreignExpData?.da_data?.map((ddd, i) => {
      sum2 = ddd?.amount_in_inr + sum2;
    });
    foreignExpData?.conveyance_data?.map((ddd, i) => {
      sum3 = ddd?.amount_in_inr + sum3;
    });
    setForeignHotel(sum);
    setForeignFood(sum1);
    setForeignDa(sum2);
    setForeignConv(sum3);
  }, [foreignExpData]);

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="row" id="printablediv">
              <div class="col-md-12">
                <div class="content-wrapper-inner content-wrapper-inner2">
                  <div class="innerheadsec">
                    <div className="row">
                      <div class="col-md-12">
                        <div class="defaultchatgptquest text-right">
                          <button
                            onClick={() => {
                              navigate(
                                `/common-approve/${location?.state?.subId}`,
                                {
                                  state: {
                                    subtypeid: location?.state?.subId,
                                    subTypeName: location?.state?.type,
                                  },
                                }
                              );
                            }}
                            class="badge badge-secondary"
                          >
                            <i class="fas fa-arrow-circle-left"></i> Back
                          </button>
                          {/* <button
                            class="badge badge-primary"
                            onClick={() => Print()}
                          >
                            <i class="fas fa-print"></i> Print
                          </button>  */}
                        </div>
                        <h3 class="policyhead">
                          {travelDetails?.travel_intimation_number?.includes(
                            "PIJA"
                          )
                            ? "JIVAGRO INDUSTRIES LIMITED"
                            : "PI INDUSTRIES LIMITED"}
                          <br />
                          <strong class="text-dark f-15">
                            Travel Expense Claim
                          </strong>
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Emp No</label>
                          <p>
                            {travelDetails &&
                              travelDetails?.travel_detail &&
                              travelDetails?.travel_detail[0]?.user_code}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Name</label>
                          <p>{udata?.created_by_name}</p>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Grade</label>
                          <p>{travelDetails?.grade}</p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Head Quarters</label>
                          <p>{travelDetails?.headquarter}</p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Intimation Number</label>
                          <p>{travelDetails?.travel_intimation_number}</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div class="col-md-2">
                        <div class="form-group innergroup ">
                          <label class="text-danger">Deputation</label>
                          <p class="text-danger">
                            {" "}
                            {dataNew[0]?.action_code === "10"
                              ? "Yes"
                              : "No" ?? "-"}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Vendor Code</label>
                          <p>{travelDetails?.vendor_code}</p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>SAP Trip No.</label>
                          <p>
                            {/* {travelDetails?.sap_trip_number} */}

                            {travelDetails?.sap_trip_number?.split(" ", 1)}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Tour Applied on</label>
                          <p>
                            {moment(travelDetails?.created_at)
                              .utc()
                              .format("DD-MM-YYYY")}{" "}
                            {moment(travelDetails?.created_at)
                              .utc()
                              .format("HH:mm")}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Tour Approved on</label>
                          <p>
                            {moment(travelDetails?.updated_at).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            {moment(travelDetails?.updated_at)
                              .utc()
                              .format("HH:mm")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {console.log("travelDetails = ", travelDetails)}
                    <div className="d-flex justify-content-between">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Expense Applied on</label>
                          <p>
                            {travelDetails?.claim_date
                              ? `${moment(travelDetails?.claim_date).format(
                                  "DD-MM-YYYY"
                                )} ${moment(travelDetails?.claim_date)
                                  .utc()
                                  .format("HH:mm")}`
                              : "-"}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Zone</label>
                          <p> {dataNew[0]?.sales_dist ?? "-"}</p>
                        </div>
                      </div>

                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>From Location</label>
                          {/* <p>
                            {travelDetails?.travel_detail[0]?.from_city_name ===
                            "Other"
                              ? travelDetails?.travel_detail[0]?.from_city_input
                              : travelDetails?.travel_detail[0]?.from_city_name}
                            -
                            {
                              travelDetails?.travel_detail[0]
                                ?.from_city_category
                            }
                          </p> */}
                          {console.log(travelDetails, "travelDetailsp")}
                          {
                            <p>
                              {travelDetails &&
                                travelDetails?.travel_detail &&
                                (travelDetails?.travel_detail[0]
                                  ?.from_city_name === "Other"
                                  ? travelDetails?.travel_detail[0]
                                      ?.from_city_input
                                  : travelDetails?.travel_detail[0]
                                      ?.from_city_name)}
                            </p>
                          }
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Departure Date</label>
                          <p>
                            {moment(
                              travelDetails &&
                                travelDetails?.travel_detail &&
                                travelDetails?.travel_detail[0]?.from_date
                            ).format("DD-MM-YYYY")}{" "}
                            {
                              travelDetails &&
                                travelDetails?.travel_detail &&
                                travelDetails?.travel_detail[0]?.from_time.slice(
                                  0,
                                  5
                                )
                              /* {travelDetails?.travel_detail[0]?.from_time.slice(
                              0,
                              3
                            ) > 12
                              ? "PM"
                              : "AM"}
                            {/* {console.log(travelDetails?.travel_detail[0].from_time.splice(0,3),'pista')} */
                            }
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>To Location</label>
                          <p>
                            {travelDetails &&
                            travelDetails?.travel_detail &&
                            travelDetails?.travel_detail[0]?.to_city_name ===
                              "Other"
                              ? travelDetails &&
                                travelDetails?.travel_detail[0]?.to_city_input
                              : travelDetails?.travel_detail &&
                                travelDetails?.travel_detail[0]?.to_city_name}
                            {/* -
                            {travelDetails &&
                              travelDetails?.travel_detail &&
                              travelDetails?.travel_detail[0]?.to_city_category} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Arrival Date</label>
                          <p>
                            {moment(
                              travelDetails &&
                                travelDetails?.travel_detail &&
                                travelDetails?.travel_detail[
                                  travelDetails?.travel_detail?.length - 1
                                ]?.to_date
                            ).format("DD-MM-YYYY")}{" "}
                            {travelDetails &&
                              travelDetails?.travel_detail &&
                              travelDetails?.travel_detail[
                                travelDetails?.travel_detail?.length - 1
                              ]?.to_time.slice(0, 5)}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group innergroup">
                          <label>Tour Day(s)</label>
                          <p>
                            {" "}
                            {travelDetails?.travel_detail?.length > 0 &&
                              calculateDateDifference(
                                travelDetails &&
                                  travelDetails?.travel_detail &&
                                  travelDetails?.travel_detail[0]?.from_date,
                                travelDetails?.travel_detail[
                                  travelDetails?.travel_detail?.length - 1
                                ]?.to_date
                              )}{" "}
                            Days
                          </p>
                        </div>
                      </div>
                      <div class="col-md-2"></div>
                      <div class="col-md-2"></div>
                      <div class="col-md-2"></div>

                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Teritory</label>
                          <p class="text-danger">No</p>
                        </div>
                      </div> */}
                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Date of Joining</label>
                          <p>
                            {moment(
                              userData?.joiningDate?.date_of_joining
                            ).format("DD-MM-YYYY")}
                          </p>
                        </div>
                      </div> */}
                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Tour Status</label>
                          <p>
                            {travelDetails?.status === "A"
                              ? "Approved"
                              : "Cancelled"}
                          </p>
                        </div> */}
                      {/* </div> */}
                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Expense Status</label>
                          <p>
                            {travelDetails?.claim_flag === true
                              ? "Submitted"
                              : "Saved"}
                          </p>
                        </div>
                      </div> */}
                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Reporting Person</label>
                          <p>
                            {travelDetails &&
                              travelDetails?.travel_detail &&
                              travelDetails?.travel_detail[0]?.approver_name}
                            - (
                            {travelDetails &&
                              travelDetails?.travel_detail &&
                              travelDetails?.travel_detail[0]?.approver_code}
                            )
                          </p>
                        </div>
                      </div> */}

                      {/* <div class="col-md-3">
                        <div class="form-group innergroup">
                          <label>Exception Approved By</label>
                          <p>
                            {travelDetails?.is_exception == true
                              ? travelDetails &&
                                travelDetails?.approver_remark_data &&
                                travelDetails?.approver_remark_data[0]
                                  ?.approver_name
                              : "-"}
                          </p>
                        </div> */}
                      {/* </div> */}
                      {/* <div class="col-md-6 viewformaftersubmitapproval">
                          <div class="form-group innergroup">
                            <label>Download Attachments</label>
                            <p>
                              <span class="mr-3">
                                <i class="fas fa-file-download"></i> file.pdf
                              </span>{" "}
                              <span class="mr-3">
                                <i class="fas fa-file-download"></i> file-01.pdf
                              </span>
                            </p>
                          </div>
                        </div> */}
                    </div>
                  </div>

                  <div class="mb-2 ml-3">
                    <h6 class="text-theme mb-0">
                      <i class="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Travel Intimation</strong>
                    </h6>
                  </div>

                  <div class="table-responsive mb-4">
                    <table class="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                      <thead>
                        <tr>
                          <th>From</th>
                          <th>Departure Date</th>
                          <th>Departure Time</th>
                          <th>Places Visited</th>
                          <th>To</th>
                          <th>Reaching Date</th>
                          <th>Reaching Time</th>
                          <th>HQ</th>
                          <th>Purpose</th>
                          <th>Mode</th>
                          <th>Territory</th>
                          <th>Hotel</th>
                        </tr>
                      </thead>
                      <tbody>
                        {travelDetails?.travel_detail?.length > 0 ? (
                          travelDetails?.travel_detail?.map((data) => (
                            // return res?.travel_detail_data?.map((data, index) => {
                            <tr>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {" "}
                                {travelDetails?.request_type === "International"
                                  ? `${data?.from_continent_name} (${
                                      data?.from_country_name
                                    } ||  ${
                                      data?.from_city_name === "Other"
                                        ? data?.from_city_input
                                        : data?.from_city_name
                                    })`
                                  : `${
                                      data?.from_city_name === "Other"
                                        ? data?.from_city_input
                                        : data?.from_city_name
                                    }`}
                                {/* {data?.from_city_name === "Other"
                                  ? data?.from_city_input
                                  : data?.from_city_name} */}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {moment(data?.from_date).format("DD-MM-YYYY")}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {moment(data?.from_time, "hh:mm").format(
                                  "hh:mm A"
                                )}
                              </td>
                              {console.log("rowData = ", data)}
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {data?.additional_information}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {console.log(travelDetails, "travelDetails")}
                                {travelDetails?.request_type === "International"
                                  ? `${data?.to_continent_name} (${
                                      data?.to_country_name
                                    } ||  ${
                                      data?.to_city_name === "Other"
                                        ? data?.to_city_input
                                        : data?.to_city_name
                                    })`
                                  : `${
                                      data?.to_city_name === "Other"
                                        ? data?.to_city_input
                                        : data?.to_city_name
                                    }`}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {moment(data?.to_date).format("DD-MM-YYYY")}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {moment(data?.to_time, "hh:mm").format(
                                  "hh:mm A"
                                )}
                              </td>
                              {data?.hq === true ? (
                                <td
                                  style={
                                    data?.reason_for_exception_name === null ||
                                    data?.reason_for_exception_name ===
                                      undefined
                                      ? {}
                                      : { color: "red" }
                                  }
                                >
                                  Yes
                                </td>
                              ) : (
                                <td
                                  style={
                                    data?.reason_for_exception_name === null ||
                                    data?.reason_for_exception_name ===
                                      undefined
                                      ? {}
                                      : { color: "red" }
                                  }
                                >
                                  No
                                </td>
                              )}
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {data?.purpose}
                              </td>
                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {data?.mode_name}
                                {data?.colleague_user_code === null ||
                                data?.colleague_user_code === undefined
                                  ? ""
                                  : data?.colleague_user_code}
                              </td>

                              <td
                                style={
                                  data?.reason_for_exception_name === null ||
                                  data?.reason_for_exception_name === undefined
                                    ? {}
                                    : { color: "red" }
                                }
                              >
                                {data?.territory === true ? "Yes" : "No"}
                              </td>
                              {data?.stay_needed === true ? (
                                <td
                                  style={
                                    data?.reason_for_exception_name === null ||
                                    data?.reason_for_exception_name ===
                                      undefined
                                      ? {}
                                      : { color: "red" }
                                  }
                                >
                                  Yes
                                </td>
                              ) : (
                                <td
                                  style={
                                    data?.reason_for_exception_name === null ||
                                    data?.reason_for_exception_name ===
                                      undefined
                                      ? {}
                                      : { color: "red" }
                                  }
                                >
                                  No
                                </td>
                              )}
                            </tr>

                            // });
                          ))
                        ) : (
                          <tr>
                            <td colSpan={20}>No Records Found...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {travelDetails?.ticket_data?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>Ticket Details</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-3">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Boarding On</th>
                              <th>Ticket Between</th>
                              <th>Ticket By</th>
                              <th>Bill Amt</th>
                              <th>Other Charges</th>
                              <th>Tax Amount</th>
                              <th>Total Claim</th>
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr Bill Amt</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr Other Charges</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr Tax Amt</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr Tax Claim</th>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {travelDetails?.ticket_data?.length > 0 ? (
                              travelDetails?.ticket_data?.map((data) => {
                                return (
                                  <tr>
                                    <td>
                                      {moment(data?.travel_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      {data?.travel_time !== null &&
                                        `${moment(
                                          data?.travel_time,
                                          "hh:mm"
                                        ).format("hh:mm A")}`}
                                    </td>
                                    <td>
                                      {data?.from_location_name == "Other"
                                        ? data?.from_city_input
                                        : data?.from_location_name}{" "}
                                      -{" "}
                                      {data?.to_location_name == "Other"
                                        ? data?.to_city_input
                                        : data?.to_location_name}
                                    </td>
                                    <td>{data?.paid_by ?? "-"}</td>
                                    <td>
                                      {JSON.stringify(
                                        data?.taxable_amount
                                      )?.includes(".")
                                        ? data?.taxable_amount
                                        : data?.taxable_amount + ".00"}{" "}
                                    </td>
                                    <td>
                                      {data?.other_charges === null ||
                                      data?.other_charges === undefined
                                        ? "0.00"
                                        : data?.other_charges?.toFixed(2)}
                                    </td>
                                    <td>
                                      {JSON.stringify(
                                        data?.tax_amount
                                      )?.includes(".")
                                        ? data?.tax_amount
                                        : data?.tax_amount + ".00"}
                                    </td>
                                    <td>
                                      {JSON.stringify(
                                        data?.tax_bill_amount
                                      )?.includes(".")
                                        ? data?.tax_bill_amount
                                        : data?.tax_bill_amount + ".00"}
                                    </td>
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>
                                        {JSON.stringify(
                                          data?.tax_bill_amount
                                        )?.includes(".")
                                          ? data?.tax_bill_amount
                                          : data?.tax_bill_amount + ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>{data?.other_charges}</td>
                                    ) : (
                                      ""
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>{data?.tax_amount}</td>
                                    ) : (
                                      ""
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>{data?.tax_bill_amount}</td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={27}>No Records Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="col-sm-12">
                          {/* <p style={{color:'black', float:'left'}}></p> */}
                          <p
                            style={{
                              color: "black",
                              marginTop: "10px",
                              fontWeight: "700",
                            }}
                          >
                            Applied: ₹
                            {Number(travelDetails?.ticket_amount).toFixed(2)}
                            {/* Approved: ₹
                            {travelDetails?.ssc_head_status === "A"
                              ? travelDetails?.final_vehicle_amount
                              : "0"} */}
                            {/* {amountVehicle} */}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {travelDetails?.hotel_data?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>Hotel Details</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-3">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Check in Date</th>
                              <th>Check Out Date</th>
                              <th>Days</th>
                              <th>City/Entitlement</th>
                              <th>Stay At</th>
                              <th>Hotel Name</th>
                              <th>Hotel GST No.</th>
                              <th>Invoice</th>
                              <th>Invoice Date</th>
                              <th>Sharing</th>
                              <th>Bill Basic Amt</th>
                              <th>Tax Amount</th>

                              <th>Total Bill Amt</th>
                              <th>Claim Amount</th>
                              <th>Total Claim Amt</th>

                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr. Bill Base Amt</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr. Claim Amt</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr. Tax Amt</th>
                              ) : (
                                ""
                              )}

                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>Appr. Total Claim</th>
                              ) : (
                                ""
                              )}
                              <th>Exc.Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            {travelDetails?.hotel_data?.length > 0 ? (
                              travelDetails?.hotel_data?.map((data) => {
                                return (
                                  <tr>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {moment(data?.check_in_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      {moment(data?.check_out_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.days}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {/* {data?.city_name}-{data?.city_entitlement}
                                      -{data?.claim_amount} */}
                                      {data?.city_name !== "Other"
                                        ? data?.city_name
                                        : data?.city_input}
                                      -{data?.city_category} -
                                      {data?.city_entitlement}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.stay_at}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.hotel_name}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.hotel_gst_no}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.invoice_no}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      {data?.invoice_date === null
                                        ? "-"
                                        : moment(data?.invoice_date).format(
                                            "DD-MM-YYYY"
                                          )}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.person_one_username}
                                      {data?.person_two_username
                                        ? `, ${data?.person_two_username}`
                                        : ""}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {/* {JSON.stringify(
                                        data?.taxable_amount
                                      )?.includes(".")
                                        ? data?.taxable_amount == null
                                          ? "0.00"
                                          : data?.taxable_amount
                                        : data?.taxable_amount == null
                                        ? "0.00"
                                        : data?.taxable_amount + ".00"} */}
                                      {data?.taxable_amount == null
                                        ? "0.00"
                                        : data?.taxable_amount?.toFixed(2)}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {/* {JSON.stringify(
                                        data?.tax_amount
                                      )?.includes(".")
                                        ? data?.tax_amount == null
                                          ? "0.00"
                                          : data?.tax_amount
                                        : data?.tax_amount == null
                                        ? "0.00"
                                        : data?.tax_amount + ".00"} */}
                                      {data?.tax_amount == null
                                        ? "0.00"
                                        : data?.tax_amount?.toFixed(2)}
                                    </td>

                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      {/* {JSON.stringify(
                                        data?.tax_bill_amount
                                      )?.includes(".")
                                        ? data?.tax_bill_amount == null
                                          ? "0.00"
                                          : data?.tax_bill_amount
                                        : data?.tax_bill_amount == null
                                        ? "0.00"
                                        : data?.tax_bill_amount + ".00"} */}
                                      {data?.tax_bill_amount == null
                                        ? "0.00"
                                        : data?.tax_bill_amount?.toFixed(2)}
                                    </td>
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {/* {JSON.stringify(
                                        data?.claim_amount
                                      )?.includes(".")
                                        ? data?.claim_amount == null
                                          ? "0.00"
                                          : data?.claim_amount
                                        : data?.claim_amount == null
                                        ? "0.00"
                                        : data?.claim_amount + ".00"} */}
                                      {data?.claim_amount == null
                                        ? "0.00"
                                        : data?.claim_amount?.toFixed(2)}
                                    </td>
                                    {/* <td>
                                        {JSON.stringify(
                                          data?.total_claim_amount
                                        )?.includes(".")
                                          ? data?.total_claim_amount == null
                                            ? "0.00"
                                            : data?.total_claim_amount
                                          : data?.total_claim_amount == null
                                          ? "0.00"
                                          : data?.total_claim_amount + ".00"}
                                      </td> */}
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      {/* {JSON.stringify(
                                        data?.total_claim_amount
                                      )?.includes(".")
                                        ? data?.total_claim_amount == null
                                          ? "0.00"
                                          : data?.total_claim_amount
                                        : data?.total_claim_amount == null
                                        ? "0.00"
                                        : data?.total_claim_amount + ".00"} */}
                                      {data?.total_claim_amount == null
                                        ? "0.00"
                                        : data?.total_claim_amount?.toFixed(2)}
                                    </td>

                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {" "}
                                        {travelDetails?.ssc_head_status ===
                                          "A" ||
                                        travelDetails?.ssc_manager_status ===
                                          "A"
                                          ? data?.taxable_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                        data?.taxable_amount
                                      )?.includes(".")
                                        ? ".00"
                                        : ""} */}
                                      </td>
                                    ) : (
                                      <></>
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {travelDetails?.ssc_head_status ===
                                          "A" ||
                                        travelDetails?.ssc_manager_status ===
                                          "A"
                                          ? data?.claim_amount?.toFixed(2)
                                          : "0.00"}
                                        {/* {JSON.stringify(
                                          data?.claim_amount
                                        )?.includes(".")
                                          ? ""
                                          : ".00"} */}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {travelDetails?.ssc_head_status ===
                                          "A" ||
                                        travelDetails?.ssc_manager_status ===
                                          "A"
                                          ? data?.tax_amount?.toFixed(2)
                                          : "0.00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td
                                        style={
                                          data?.is_exception == true
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {travelDetails?.ssc_head_status ===
                                          "A" ||
                                        travelDetails?.ssc_manager_status ===
                                          "A"
                                          ? data?.total_claim_amount?.toFixed(2)
                                          : "0.00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    <td
                                      style={
                                        data?.is_exception == true
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.reason_for_exception_name}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={25}>No Records Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="col-sm-12">
                          {/* <p style={{color:'black', float:'left'}}></p> */}
                          <p
                            style={{
                              color: "black",
                              marginTop: "10px",
                              fontWeight: "700",
                            }}
                          >
                            Applied: ₹{Number(amountHotel)?.toFixed(2)}
                            {/* {JSON.stringify(amountHotel)?.includes(".")
                              ? ""
                              : ".00"} */}
                            {/* {amountVehicle} */}
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A"
                              ? ` Approved: ₹ ${amountHotel}`
                              : ""}
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A"
                              ? JSON.stringify(amountHotel)?.includes(".")
                                ? ""
                                : ".00"
                              : ""}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {travelDetails?.da_data?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>DA Details</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-3">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Hours</th>
                              <th>City/Entitlement</th>
                              <th>Remarks</th>
                              <th>Total Claim Amt</th>
                              <th>Day/Leave</th>
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>App DA %</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>App DA Text</th>
                              ) : (
                                ""
                              )}
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>APP DA AMT</th>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {travelDetails?.da_data?.length > 0 ? (
                              travelDetails?.da_data?.map((data) => {
                                return (
                                  <tr>
                                    <td>
                                      {moment(data?.da_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>{Number(data?.hours)?.toFixed(2)}</td>
                                    <td>
                                      {data?.city_name === "Other"
                                        ? data?.city_input
                                        : data?.city_name}
                                      -{data?.category}-{" "}
                                      {JSON.stringify(
                                        data?.entitlement
                                      )?.includes(".")
                                        ? data?.entitlement == null
                                          ? "0.00"
                                          : data?.entitlement?.toFixed(2)
                                        : data?.entitlement == undefined
                                        ? "0.00"
                                        : data?.entitlement?.toFixed(2)}
                                    </td>

                                    <td>
                                      <Tooltip
                                        title={
                                          data?.appr_da_remarks === null ||
                                          data?.appr_da_remarks === ""
                                            ? data?.remarks
                                            : data?.appr_da_remarks
                                        }
                                        position="bottom"
                                      >
                                        {data?.appr_da_remarks === null ||
                                        data?.appr_da_remarks === ""
                                          ? data?.remarks
                                          : data?.appr_da_remarks}
                                      </Tooltip>
                                    </td>
                                    <td>
                                      {JSON.stringify(
                                        data?.gross_total
                                      )?.includes(".")
                                        ? data?.gross_total == null
                                          ? "0.00"
                                          : data?.gross_total?.toFixed(2)
                                        : data?.gross_total == undefined
                                        ? "0.00"
                                        : data?.gross_total?.toFixed(2)}
                                    </td>

                                    <td style={{ color: "red" }}>
                                      {(data?.annual_meet === "Leave" ||
                                        data?.annual_meet ===
                                          "Holiday/WeeklyOff") &&
                                      moment(data?.da_date).day() === 0
                                        ? "Sunday"
                                        : ""}
                                      {moment(data?.da_date).day() !== 0 &&
                                      data?.annual_meet === "Leave"
                                        ? "Leave"
                                        : data?.annual_meet ===
                                          "Holiday/WeeklyOff"
                                        ? "Holiday/WeeklyOff"
                                        : ""}
                                    </td>
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>
                                        {
                                          data?.appr_da_percent

                                          // === null
                                          //   ? data?.gross_total === 0
                                          //     ? data?.gross_total
                                          //     : Math.round(
                                          //         (data?.gross_total /
                                          //           data?.entitlement) *
                                          //           100
                                          //       )
                                          //   : data?.appr_da_percent
                                        }
                                        %
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>
                                        {" "}
                                        <Tooltip
                                          title={data?.appr_da_remarks}
                                          position="bottom"
                                        >
                                          {travelDetails?.ssc_head_status ===
                                            "A" ||
                                          travelDetails?.ssc_manager_status ===
                                            "A"
                                            ? data?.appr_da_remarks
                                            : "0"}
                                        </Tooltip>
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td>
                                        {travelDetails?.ssc_head_status ===
                                          "A" ||
                                        travelDetails?.ssc_manager_status ===
                                          "A"
                                          ? data?.appr_da_amount
                                          : ""}
                                        {JSON.stringify(
                                          data?.appr_da_amount
                                        )?.includes(".")
                                          ? ""
                                          : ".00"}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={20}>No Records Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="col-sm-12">
                          {/* <p style={{color:'black', float:'left'}}></p> */}
                          <p
                            style={{
                              color: "black",
                              marginTop: "10px",
                              fontWeight: "700",
                            }}
                          >
                            Applied: ₹{sd}
                            {JSON.stringify(sd)?.includes(".") ? "" : ".00"}
                            {/* {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A"
                              ? ` Approved: ₹${sd}`
                              : "0"} */}
                            {/* {amountVehicle} */}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {travelDetails?.vehicle_data?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>Vehicle Log Details Vehicle No</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-3">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Starting Kms</th>
                              <th>Closing Kms</th>
                              <th>Total Kms</th>
                              <th>Fuel Qty</th>
                              <th>Fuel Amount</th>
                              <th>Driver Daily Wages</th>
                              {/* <th>Appr. Wages</th> */}
                              <th>Driver DA</th>
                              <th>Driver DA Val</th>
                              {/* <th>Appr. Drv. DA Val</th> */}
                              <th>Other</th>
                              {/* <th>Appr. Other</th> */}
                              <th>Travel With</th>
                              <th>Partner Name</th>
                              <th>Total(₹)</th>
                              {/* <th>Total Appr. Amt.</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            {travelDetails?.vehicle_data?.length > 0 ? (
                              travelDetails?.vehicle_data
                                ?.filter((ele) => ele.start_kms !== 0)
                                ?.map((data) => {
                                  return (
                                    <tr>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {moment(data?.travel_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.start_kms}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.close_kms}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.total_kms}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.fuel_quantity}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {JSON.stringify(
                                          data?.fuel_amount
                                        )?.includes(".")
                                          ? data?.fuel_amount
                                          : data?.fuel_amount + ".00"}
                                      </td>

                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {JSON.stringify(
                                          data?.driver_daily_wages
                                        )?.includes(".")
                                          ? data?.driver_daily_wages
                                          : data?.driver_daily_wages + ".00"}
                                      </td>

                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.driver_da_type}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.driver_da_value}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.other_expenses}
                                      </td>

                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.travel_with}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {data?.partner_name}
                                      </td>
                                      <td
                                        style={
                                          data?.is_exception == true &&
                                          (data?.is_personal_car_exception ==
                                            undefined ||
                                            data?.is_personal_car_exception ==
                                              false)
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {JSON.stringify(
                                          data?.total_amount
                                        )?.includes(".")
                                          ? data?.total_amount
                                          : data?.total_amount + ".00"}
                                      </td>
                                      {/* <td>175.00</td> */}
                                    </tr>
                                  );
                                })
                            ) : (
                              <tr>
                                <td colSpan={20}>No Records Found...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="col-sm-12">
                          {/* <p style={{color:'black', float:'left'}}></p> */}
                          <p
                            style={{
                              color: "black",
                              marginTop: "10px",
                              fontWeight: "700",
                            }}
                          >
                            Applied: ₹
                            {Number(travelDetails?.vehicle_amount)?.toFixed(2)}
                            {/* Approved:
                            ₹
                            {travelDetails?.ssc_head_status === "A"
                              ? travelDetails?.vehicle_amount
                              : "0"} */}
                            {/* {amountVehicle} */}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {vehicleData["monthly limit"] ? (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>VEHICLE SUMMARY</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-4">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Monthly KM limit</th>
                              <th>KM Travelled in current Month</th>
                              <th>KM Travelled - in current travel claim</th>
                              <th>Travelled KM MTD</th>
                              <th>Excess KM</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{vehicleData["monthly limit"]}</td>
                              <td>{vehicleData["km traveled"]}</td>
                              <td>
                                {vehicleData["current travel km travelled"]}
                              </td>
                              <td>{vehicleData["traveled km MTD"]}</td>
                              <td>{vehicleData["Excess KM"]}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {travelDetails?.conveyance_data?.length === 0 ? (
                    ""
                  ) : (
                    <>
                      <div class="mb-2 ml-3">
                        <h6 class="text-theme mb-0">
                          <i class="far fa-arrow-alt-circle-right"></i>{" "}
                          <strong>Local Conveyance Details</strong>
                        </h6>
                      </div>

                      <div class="table-responsive mb-3">
                        <table class="table table-striped table-bordered tablecured viewpo innergroup checkexpentable viewpoprinttab">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>From-To</th>
                              <th>Travel By</th>
                              <th>Claim Amount</th>
                              {travelDetails?.ssc_head_status === "A" ||
                              travelDetails?.ssc_manager_status === "A" ? (
                                <th>App Amount </th>
                              ) : (
                                ""
                              )}
                              <th>Exception</th>
                            </tr>
                          </thead>

                          <tbody>
                            {travelDetails?.conveyance_data?.length > 0
                              ? travelDetails?.conveyance_data?.map((data) => (
                                  <tr>
                                    <td
                                      style={
                                        data?.reason_for_exception_name !==
                                          null &&
                                        (data?.mode_name === "Taxi" ||
                                          data?.mode_name ===
                                            "OLA/Uber sedan/Prime/XL" ||
                                          data?.mode_name ===
                                            "OLA/Uber micro/ Mini/Go")
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {moment(data?.conveyance_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>

                                    <td
                                      style={
                                        data?.reason_for_exception_name !==
                                          null &&
                                        (data?.mode_name === "Taxi" ||
                                          data?.mode_name ===
                                            "OLA/Uber sedan/Prime/XL" ||
                                          data?.mode_name ===
                                            "OLA/Uber micro/ Mini/Go")
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {" "}
                                      {data?.from_location_name}-
                                      {data?.to_location_name}
                                    </td>
                                    <td
                                      style={
                                        data?.reason_for_exception_name !==
                                          null &&
                                        (data?.mode_name === "Taxi" ||
                                          data?.mode_name ===
                                            "OLA/Uber sedan/Prime/XL" ||
                                          data?.mode_name ===
                                            "OLA/Uber micro/ Mini/Go")
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.mode_name}
                                    </td>
                                    <td
                                      style={
                                        data?.reason_for_exception_name !==
                                          null &&
                                        (data?.mode_name === "Taxi" ||
                                          data?.mode_name ===
                                            "OLA/Uber sedan/Prime/XL" ||
                                          data?.mode_name ===
                                            "OLA/Uber micro/ Mini/Go")
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {JSON.stringify(data?.amount)?.includes(
                                        "."
                                      )
                                        ? data?.amount
                                        : data?.amount + ".00"}
                                    </td>
                                    {travelDetails?.ssc_head_status === "A" ||
                                    travelDetails?.ssc_manager_status ===
                                      "A" ? (
                                      <td
                                        style={
                                          data?.reason_for_exception_name !==
                                            null &&
                                          (data?.mode_name === "Taxi" ||
                                            data?.mode_name ===
                                              "OLA/Uber sedan/Prime/XL" ||
                                            data?.mode_name ===
                                              "OLA/Uber micro/ Mini/Go")
                                            ? { color: "red" }
                                            : {}
                                        }
                                      >
                                        {travelDetails?.ssc_head_status === "A"
                                          ? data?.amount
                                          : "0"}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    <td
                                      style={
                                        data?.reason_for_exception_name !==
                                          null &&
                                        (data?.mode_name === "Taxi" ||
                                          data?.mode_name ===
                                            "OLA/Uber sedan/Prime/XL" ||
                                          data?.mode_name ===
                                            "OLA/Uber micro/ Mini/Go")
                                          ? { color: "red" }
                                          : {}
                                      }
                                    >
                                      {data?.reason_for_exception_name ==
                                        null ||
                                      data?.mode_name ===
                                        "Rickshaw(Cycle/Auto)" ||
                                      data?.mode_name === "Public Transport" ||
                                      data?.mode_name === "OLA/Uber pool" ? (
                                        "-"
                                      ) : (
                                        <i
                                          class="fa fa-flag"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        ></i>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              : "No Records Found..."}
                          </tbody>
                        </table>
                        <div className="col-sm-12">
                          {/* <p style={{color:'black', float:'left'}}></p> */}
                          <p
                            style={{
                              color: "black",
                              marginTop: "10px",
                              fontWeight: "700",
                            }}
                          >
                            Applied: ₹
                            {Number(travelDetails?.conveyance_amount)?.toFixed(
                              2
                            )}
                            {/* {JSON.stringify(
                              travelDetails?.conveyance_amount
                            )?.includes(".")
                              ? ""
                              : ".00"} */}
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A"
                              ? ` Approved: ₹${travelDetails?.conveyance_amount}`
                              : ""}
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A"
                              ? JSON.stringify(
                                  travelDetails?.conveyance_amount
                                )?.includes(".")
                                ? ""
                                : ".00"
                              : ""}
                            {/* {amountVehicle} */}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!foreignExpData ||
          (foreignExpData?.hotel_data?.length === 0 &&
            foreignExpData?.food_data?.length === 0 &&
            foreignExpData?.da_data?.length === 0 &&
            foreignExpData?.conveyance_data?.length === 0) ? (
            ""
          ) : (
            <>
              <div className="mb-2 ml-3">
                <h6 className="text-theme mb-0">
                  <strong>Foreign Expenses</strong>
                </h6>
              </div>
              {foreignExpData?.hotel_data?.length === 0 ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="mb-2 ml-3">
                    <h6 className="text-theme mb-0">
                      <i className="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Hotel Details</strong>
                    </h6>
                  </div>
                  <div className="table-responsive mb-4">
                    <table className="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                      <thead>
                        <tr>
                          <th>Hotel Name</th>
                          <th>Stay From</th>
                          <th>Stay Till</th>
                          <th>Paid By</th>
                          <th>Vendor Invoice</th>
                          <th>Invoive Date</th>
                          <th>Currency</th>
                          <th>Amt(In Forex)</th>
                          <th>Amt(In Inr)</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In Forex)</th>
                          ) : (
                            ""
                          )}
                          <th>Exchange Rate</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In INR)</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {foreignExpData?.hotel_data?.map((data) => (
                          <tr>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.hotel_name ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.check_in_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.check_out_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.paid_by}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.vendor_invoice}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.invoice_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.currency_code}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_forex)?.includes(
                                "."
                              )
                                ? data?.amount_in_forex?.toFixed(2)
                                : data?.amount_in_forex + ".00"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_inr)?.includes(
                                "."
                              )
                                ? data?.amount_in_inr?.toFixed(2)
                                : data?.amount_in_inr + ".00"}
                            </td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {foreignExpData?.currency_data_list
                                  ?.filter(
                                    (ele) => ele?.name == data?.currency_code
                                  )
                                  ?.map((item, idx) => {
                                    <span>{item?.amount}</span>;
                                  })}
                                {/* {JSON.stringify(
                                  data?.amount_in_forex
                                )?.includes(".")
                                  ? data?.amount_in_forex.toFixed(2)
                                  : data?.amount_in_forex + ".00"} */}
                              </td>
                            ) : (
                              ""
                            )}

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            ></td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(data?.amount_in_inr)?.includes(
                                  "."
                                )
                                  ? data?.amount_in_inr?.toFixed(2)
                                  : data?.amount_in_inr + ".00"}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="col-sm-12">
                      <p
                        style={{
                          color: "black",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Applied: ₹
                        {foreignHotel === null || foreignHotel === undefined
                          ? "0"
                          : foreignHotel?.toFixed(2)}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? ` Approved: ₹ ${foreignHotel?.toFixed(2)}`
                          : ""}
                        {/* {state?.ssc_head_status === "A" ||
                                      state?.ssc_manager_status === "A"
                                        ? JSON.stringify(
                                            foreignHotel
                                          )?.includes(".")
                                          ? ""
                                          : ".00"
                                        : ""} */}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {/* Food Details */}
              {foreignExpData?.food_data?.length === 0 ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="mb-2 ml-3">
                    <h6 className="text-theme mb-0">
                      <i className="far fa-arrow-alt-circle-right"></i>{" "}
                      <strong>Food Details</strong>
                    </h6>
                  </div>
                  <div className="table-responsive mb-4">
                    <table className="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Vendor Name</th>
                          <th>Paid By</th>
                          <th>Currency</th>
                          <th>Amt(In Forex)</th>
                          <th>Amt(In Inr)</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In Forex)</th>
                          ) : (
                            ""
                          )}
                          <th>Exchange Rate</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In INR)</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {foreignExpData?.food_data?.map((data) => (
                          <tr>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.food_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.vendor_name ?? "-"}
                            </td>

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.paid_by ?? "-"}
                            </td>

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.currency_code}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_forex)?.includes(
                                "."
                              )
                                ? data?.amount_in_forex?.toFixed(2)
                                : data?.amount_in_forex + ".00"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_inr)?.includes(
                                "."
                              )
                                ? data?.amount_in_inr?.toFixed(2)
                                : data?.amount_in_inr + ".00"}
                            </td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(
                                  data?.amount_in_forex
                                )?.includes(".")
                                  ? data?.amount_in_forex?.toFixed(2)
                                  : data?.amount_in_forex + ".00"}
                              </td>
                            ) : (
                              ""
                            )}

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            ></td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(data?.amount_in_inr)?.includes(
                                  "."
                                )
                                  ? data?.amount_in_inr.toFixed(2)
                                  : data?.amount_in_inr + ".00"}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="col-sm-12">
                      {/* <p style={{color:'black', float:'left'}}></p> */}
                      <p
                        style={{
                          color: "black",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Applied: ₹
                        {foreignFood === null || foreignFood === undefined
                          ? "0"
                          : foreignFood?.toFixed(2)}
                        {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? ` Approved: ₹ ${foreignFood}`
                          : ""}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? JSON.stringify(foreignFood)?.includes(".")
                            ? ""
                            : ".00"
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {/* Incedental Details */}
              {foreignExpData?.da_data?.length === 0 ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="mb-2 ml-3">
                    <h6 className="text-theme mb-0">
                      <strong>Incidental Details</strong>
                      <i className="far fa-arrow-alt-circle-right"></i>{" "}
                    </h6>
                  </div>
                  <div className="table-responsive mb-4">
                    <table className="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>ENT Currency</th>
                          <th>ENT Amount(In Forex)</th>
                          <th>ENT Amount(In INR)</th>
                          <th>Currency</th>
                          <th>Amt(In Forex)</th>
                          <th>Amt(In Inr)</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In Forex)</th>
                          ) : (
                            ""
                          )}
                          <th>Exchange Rate</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In INR)</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {foreignExpData?.da_data?.map((data) => (
                          <tr>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.incidental_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.currency_code ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.ent_amount_in_forex === null ||
                              data?.ent_amount_in_forex === undefined
                                ? "0.00"
                                : data?.ent_amount_in_forex.toFixed(2)}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.ent_amount_in_inr === null ||
                              data?.ent_amount_in_inr === undefined
                                ? "0.00"
                                : data?.ent_amount_in_inr.toFixed(2)}
                            </td>

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.currency_code ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_forex)?.includes(
                                "."
                              )
                                ? data?.amount_in_forex.toFixed(2)
                                : data?.amount_in_forex + ".00"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {JSON.stringify(data?.amount_in_inr)?.includes(
                                "."
                              )
                                ? data?.amount_in_inr.toFixed(2)
                                : data?.amount_in_inr + ".00"}
                            </td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(
                                  data?.amount_in_forex
                                )?.includes(".")
                                  ? data?.amount_in_forex.toFixed(2)
                                  : data?.amount_in_forex + ".00"}
                              </td>
                            ) : (
                              ""
                            )}

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            ></td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(data?.amount_in_inr)?.includes(
                                  "."
                                )
                                  ? data?.amount_in_inr.toFixed(2)
                                  : data?.amount_in_inr + ".00"}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="col-sm-12">
                      {/* <p style={{color:'black', float:'left'}}></p> */}
                      <p
                        style={{
                          color: "black",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Applied: ₹
                        {foreignDa === null || foreignDa === undefined
                          ? "0"
                          : foreignDa?.toFixed(2)}
                        {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? ` Approved: ₹ ${foreignDa}`
                          : ""}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? JSON.stringify(foreignDa)?.includes(".")
                            ? ""
                            : ".00"
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {/* Conveyance Details */}
              {foreignExpData?.conveyance_data?.length === 0 ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="mb-2 ml-3">
                    <h6 className="text-theme mb-0">
                      <strong>Conveyance Details</strong>
                      <i className="far fa-arrow-alt-circle-right"></i>{" "}
                    </h6>
                  </div>
                  <div className="table-responsive mb-4">
                    <table className="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Travel Mode</th>
                          <th>From</th>

                          <th>To</th>
                          <th>Paid By</th>
                          <th>Currency</th>

                          <th>Amt(In Forex)</th>
                          <th>Amt(In Inr)</th>

                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In Forex)</th>
                          ) : (
                            ""
                          )}
                          <th>Exchange Rate</th>
                          {travelDetails?.ssc_head_status === "A" ||
                          travelDetails?.ssc_manager_status === "A" ? (
                            <th>Approved Amt(In INR)</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {foreignExpData?.conveyance_data?.map((data) => (
                          <tr>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {moment(data?.conveyance_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.modee_name ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.from_location ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.to_location ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.paid_by ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.currency_code ?? "-"}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.amount_in_forex === null ||
                              data?.amount_in_forex === undefined
                                ? "0.00"
                                : data?.amount_in_forex.toFixed(2)}
                            </td>
                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {data?.amount_in_inr === null ||
                              data?.amount_in_inr === undefined
                                ? "0.00"
                                : data?.amount_in_inr.toFixed(2)}
                            </td>

                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(
                                  data?.amount_in_forex
                                )?.includes(".")
                                  ? data?.amount_in_forex.toFixed(2)
                                  : data?.amount_in_forex + ".00"}
                              </td>
                            ) : (
                              ""
                            )}

                            <td
                              style={
                                data?.is_exception === true
                                  ? { color: "red" }
                                  : {}
                              }
                            ></td>
                            {travelDetails?.ssc_head_status === "A" ||
                            travelDetails?.ssc_manager_status === "A" ? (
                              <td
                                style={
                                  data?.is_exception === true
                                    ? { color: "red" }
                                    : {}
                                }
                              >
                                {JSON.stringify(data?.amount_in_inr)?.includes(
                                  "."
                                )
                                  ? data?.amount_in_inr.toFixed(2)
                                  : data?.amount_in_inr + ".00"}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="col-sm-12">
                      {/* <p style={{color:'black', float:'left'}}></p> */}
                      <p
                        style={{
                          color: "black",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                      >
                        Applied: ₹
                        {foreignConv === null || foreignConv === undefined
                          ? "0"
                          : foreignConv?.toFixed(2)}
                        {/* {JSON.stringify(foreignHotel)?.includes(".")
                                        ? ""
                                        : ".00"} */}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? ` Approved: ₹ ${foreignConv}`
                          : ""}
                        {travelDetails?.ssc_head_status === "A" ||
                        travelDetails?.ssc_manager_status === "A"
                          ? JSON.stringify(foreignConv)?.includes(".")
                            ? ""
                            : ".00"
                          : ""}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <>
            <div className="mb-2 ml-3">
              <h6 className="text-theme mb-0">
                <i className="far fa-arrow-alt-circle-right"></i>{" "}
                <strong>Expense Approve History</strong>
              </h6>
            </div>

            <div class="table-responsive mb-4">
              <table class="table table-striped table-bordered tablecured viewpo viewpoprinttab">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Approver Name</th>
                    <th>Approver Level</th>
                    <th>Status</th>
                    <th>Approved Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {travelDetails?.approver_remark_log?.map((data, idx) => (
                    <tr>
                      <td>{idx + 1}</td>

                      <td>{data?.approver_name}</td>

                      <td>{data?.approver_position}</td>

                      <td>
                        {data?.status == "A" ? (
                          <span class="badge bad-status badge-success">
                            Approved
                          </span>
                        ) : data?.status == "R" ? (
                          `Rejected`
                        ) : !data?.status ? (
                          <span class="badge bad-status badge-warning">
                            Pending
                          </span>
                        ) : (
                          ""
                        )}
                      </td>

                      <td>
                        {data?.status &&
                          data?.status != "P" &&
                          data?.updated_at &&
                          moment
                            .parseZone(data?.updated_at)
                            .format("DD-MM-YYYY")}
                      </td>

                      <td>{data?.remark}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between viewformaftersubmit m-0">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Expense By Self</label>
                    <p>
                      <i className="fas fa-rupee-sign f-12"></i>
                      {Number(
                        amountHotel +
                          travelDetails?.conveyance_amount +
                          sd +
                          // (state?.foreign_conveyance_self
                          //   ? Number(state?.foreign_conveyance_self)
                          //   : 0) +
                          Number(
                            travelDetails?.ticket_data
                              ?.filter((obj) => {
                                if (obj.paid_by?.toLowerCase() === "self")
                                  return true;
                              })
                              .reduce((sum, obj) => {
                                return (
                                  sum +
                                  (obj?.taxable_amount !== null ||
                                  obj?.taxable_amount !== undefined
                                    ? obj.taxable_amount
                                    : 0) +
                                  (obj?.tax_amount !== null ||
                                  obj?.tax_amount !== undefined
                                    ? obj.tax_amount
                                    : 0) +
                                  (obj?.other_charges !== null ||
                                  obj?.other_charges !== undefined
                                    ? obj.other_charges
                                    : 0)
                                );
                              }, 0)
                          ) +
                          travelDetails?.vehicle_amount +
                          (foreignFood === "" ||
                          foreignFood === null ||
                          foreignFood === undefined
                            ? 0
                            : foreignFood) +
                          (foreignDa === "" ||
                          foreignDa === null ||
                          foreignDa === undefined
                            ? 0
                            : foreignDa) +
                          (foreignConv === "" ||
                          foreignConv === null ||
                          foreignConv === undefined
                            ? 0
                            : foreignConv)
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Expense By Company</label>
                    <p>
                      <i className="fas fa-rupee-sign f-12"></i>{" "}
                      {Number(
                        travelDetails?.ticket_data
                          ?.filter((obj) => {
                            if (
                              obj.paid_by === "Company" ||
                              obj.paid_by === "company"
                            )
                              return true;
                          })
                          .reduce((sum, obj) => {
                            return (
                              sum +
                              (obj?.taxable_amount !== null ||
                              obj?.taxable_amount !== undefined
                                ? obj.taxable_amount
                                : 0) +
                              (obj?.tax_amount !== null ||
                              obj?.tax_amount !== undefined
                                ? obj.tax_amount
                                : 0) +
                              (obj?.other_charges !== null ||
                              obj?.other_charges !== undefined
                                ? obj.other_charges
                                : 0)
                            );
                          }, 0)
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Total Expense</label>
                    <p>
                      <i className="fas fa-rupee-sign f-12"></i>
                      {Number(
                        amountHotel +
                          travelDetails?.conveyance_amount +
                          sd +
                          // (state?.foreign_conveyance_self
                          //   ? Number(state?.foreign_conveyance_self)
                          //   : 0) +
                          travelDetails?.ticket_amount +
                          travelDetails?.vehicle_amount +
                          (foreignFood === "" ||
                          foreignFood === null ||
                          foreignFood === undefined
                            ? 0
                            : foreignFood) +
                          (foreignDa === "" ||
                          foreignDa === null ||
                          foreignDa === undefined
                            ? 0
                            : foreignDa) +
                          (foreignConv === "" ||
                          foreignConv === null ||
                          foreignConv === undefined
                            ? 0
                            : foreignConv)
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
          <div class="col-md-12">
            <div class="form-group innergroup">
              <label>
                Add Comments<span class="text-danger">*</span>
              </label>
              <textarea
                class="form-control"
                placeholder="Enter Comments here..."
                name="remark"
                onChange={(e) => {
                  setRemarksData({
                    ...remarksData,
                    remark: e.target.value,
                  });
                  setFormValidation({
                    ...formValidation,
                    remark: "",
                  });
                }}
                value={remarksData.remark}
              ></textarea>
              <div className="small text-danger">{formValidation.remark}</div>
            </div>
          </div>

          <div class="col-md-12 pb-3">
            <button
              class="btn btn-outline-secondary mr-2"
              onClick={() => {
                navigate(`/common-approve/${location?.state?.subId}`, {
                  state: {
                    subtypeid: location?.state?.subId,
                    subTypeName: location?.state?.type,
                  },
                });
              }}
            >
              <i class="fas fa-arrow-circle-left"></i> Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                variant="primary"
                as="span"
                role="status"
                aria-hidden="true"
              ></Spinner>
            ) : (
              <>
                <button
                  class="btn btn-outline-danger mr-2"
                  onClick={() => handleSaveValue("R")}
                >
                  <i class="far fa-times-circle"></i> Reject
                </button>
                <button
                  class="btn btn-primary-inner bpi-main"
                  onClick={() => handleSaveValue("A")}
                >
                  <i class="far fa-check-square"></i> Approve
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTravelExpenseData;
