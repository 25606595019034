import React from "react";
import { useSelector } from "react-redux";
import { selectLoader } from "../pages/redux/piDataStore";

function LoaderContainer() {
  const show = useSelector(selectLoader);

  return (
    <>
      {show && (
        <div class="loader_page">
          <img src="/images/New Gif for PI.gif" alt="Mypi" />
        </div>
      )}
    </>
  );
}

export default LoaderContainer;
