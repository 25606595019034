import axios from "../utils/axios";
// import { AUTH_BASE_URL_SURVEY } from "../constants/URL";
import { AUTH_BASE_URL } from "../constants/URL";

export const getExternalAssignmentDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/survey/get-external-assignment-details?${queryParm}`
  );
};

export const postExternalSurveyResponses = async (qData, pData) => {
  // console.log(`${AUTH_BASE_URL}/survey/external-responses?email=${qData.userMail}&token=${qData.userToken}`,pData)
  return axios.post(
    `${AUTH_BASE_URL}/survey/external-responses?email=${qData.userMail}&token=${qData.userToken}`,
    pData
  );
};

export const postSurveyResponses = async (data) => {
  // console.log(`${AUTH_BASE_URL}/survey/survey-responses?thankuEmail=${qData.userMail}&token=${qData.userToken}`,pData)
  return axios.post(
    `${AUTH_BASE_URL}/survey/survey-responses/thankuEmail`, data
  );
};

export const getSurvey = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/survey/get-survey-details?${queryParm}`);
};

export const getDepartment = async (queryParam) => {
  return axios.get(`${AUTH_BASE_URL}/survey/get-dropdown-data?${queryParam}`);
};

export const getBranch = async (params) => {
  return axios.get(`${AUTH_BASE_URL}/business_units?${params}`);
};

export const getSurveyUserResponse = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/survey/get-survey-details?${queryParm}`);
};

export const getSurveyUserDetails = async (queryParm) => {
  return axios.get(
    `${AUTH_BASE_URL}/survey/get-survey-user-details?${queryParm}`
  );
};

export const postResponse = async (pData) => {
  return axios.post(`${AUTH_BASE_URL}/survey/responses`, pData);
};

export const getPolls = async (queryParm) => {
  return axios.get(`${AUTH_BASE_URL}/survey/get-polls-details?${queryParm}`);
};

//   export const getAllOrganisation = async (paginate) => {
//     return axios.get(`${AUTH_BASE_URL}/organisations?paginate=${paginate}`);
//   };

export const saveSurvey = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/survey/add-survey`, data);
};
export const updateSurveyData = async (id, data) => {
  return axios.put(`${AUTH_BASE_URL}/survey/${id}`, data);
};

export const updateSurvey = async (data, id) => {
  return axios.put(`${AUTH_BASE_URL}/survey/${id}`, data);
};

export const deleteSurvey = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/survey/${id}`,
    method: "DELETE",
    data: id,
  });
};

export const getAllSurveyType = async () => {
  return axios.get(`${AUTH_BASE_URL}/survey/survey-type-dropdown`);
};

export const getAllCategoryType = async (paginate) => {
  return axios.get(`${AUTH_BASE_URL}/survey-category?${paginate}`);
};

export const getExportValue = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/survey/export?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const getExportSurveyUser = async (queryParm) => {
  return axios({
    url: `${AUTH_BASE_URL}/survey/export-survey-user?${queryParm}`,
    method: "GET",
    responseType: "blob",
  });
};

export const savePublishData = async (pData) => {
  return axios.post(`${AUTH_BASE_URL}/survey/publish-settings`, pData);
};

export const updatePublishData = async (id, uData) => {
  return axios.put(`${AUTH_BASE_URL}/survey/publish-settings/${id}`, uData);
};

export const getPublishData = async (survey_id) => {
  return axios.get(
    `${AUTH_BASE_URL}/survey/publish-settings?survey_id=${survey_id}`
  );
};

export const uploadfile = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/upload`, data);
};

export const approveSurvey = async (pData) => {
  return axios.post(`${AUTH_BASE_URL}/survey/approve-survey`, pData);
};

export const getPsaByBranch = async (branch) => {
  return axios.get(
    `${AUTH_BASE_URL}/personal-sub-area/subAreaByBranch?branch=${branch}`
  );
};

export const modifyUserSetting = async (data) => {
  return axios.post(`${AUTH_BASE_URL}/survey/modify-survey-user`, data);
};
