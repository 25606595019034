import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from "react-select";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// import * as PrServices from '../../../service/prService';
import { Link, useNavigate, useLocation } from "react-router-dom";

const ViewSimilarMat = (props) => {
  const location = useLocation();
  const { handleClose,matDataFlag, similarMatData, handleSearch} = props;

  return (
    <div >
      <Modal
        show={matDataFlag}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter maxwidth90"
      >
          <Modal.Header closeButton>
            <Modal.Title>View Similar Materials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
                <div className='col-md-4'>
            <div class="input-group table-search">
                      <span class="input-group-text">
                        <img src="images/search.png" alt="" />
                      </span>
                      <input
                        id="searchbar"
                        type="text"
                        class="form-control"
                        placeholder="Search on Code, Description..."
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                     
                    </div>
                    </div>
                    </div>
            <div className="row justify-content-center mt-3">
              <div className="table-responsive">
                <table className="table table-striped table-bordered tablecured viewpo">
                  <thead>
                    <tr>
                      <th>Material ID</th>
                      <th>Material Code</th>
                      <th>Description</th>
                      <th>Material Type</th>
                      <th>HSN Code</th>
                      <th>Category</th>
                      <th>CAS No.</th>
                      <th>Plant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {similarMatData?.length > 0 ?
                      similarMatData.map((data, i) => (
                        <tr>
                          <td>{data.id}</td>
                          <td>{data.code}</td>
                          <td>{data.description}</td>
                          <td>{data.material_type}</td>
                          <td>{data.hsn_code}</td>
                          <td>{data?.category}</td>
                          <td>{data.cas_no}</td>
                          <td>{data.plant_desc}</td>
                        </tr>
                      ))
                      :
                      <tr>
                        <td colSpan={12}>No Matching Records Found...</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
             
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={()=>{handleClose()}} className="btn btn-primary-inner bpi-main">Back</button>
          </Modal.Footer>
      </Modal>
    </div >
  )
}

export default ViewSimilarMat