import React, { useState } from "react";
import * as PoService from "../../service/searchpo";
import { toast } from "react-toastify";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";

function SyncPO() {

  const dispatch = useDispatch();

  const [poNumber, setPoNumber] = useState(null)

  const handleChange = (e, field_name) =>{
    if(field_name === "po_number"){
        setPoNumber(e.target.value)
    }
  }

  const pullPO = () =>{
    dispatch(setDisplayLoader("Display"));
    let data = {"po_number" : poNumber}
    PoService.syncPoFromSap(data).then((response)=>{
      dispatch(setDisplayLoader("Hide"));
      if(response?.data?.dataList?.response?.error === "PO Synced Successfully."){
      toast.success("PO Synced Successfully")
    }
    else{
      toast.error(response?.data?.dataList?.response?.error)
    }
      dispatch(setDisplayLoader("Hide"));
    })
  }

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Sync PO</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group innergroup">
                    <label>
                      PO Number<span class="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter PO Number"
                      onChange={(e)=>{handleChange(e, "po_number")}}
                    />
                   
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <button
                   class="btn btn-outline-danger mr-3"
                   type="button"
                   onClick={() => {
                    window.history.go(-1);
                    return false;
                  }}
                   >
                    Cancel
                   </button>
                  <button class="btn btn-primary-inner bpi-main" type="button" onClick={pullPO}>
                    Pull PO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SyncPO;
