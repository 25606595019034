import React from 'react'
import PropTypes from 'prop-types'
import {Preview} from './Preview';
import { isEmpty } from "lodash";
import { uploadImages } from "../../../../service/header";
import { setDisplayLoader, selectLoader } from "../../../../pages/redux/piDataStore";
import { useDispatch } from 'react-redux';
const Attachment = props => {
    const { id,label,placeHolder,errorAttachment, accept, index, attachmentlist, setAttachmentlist, showAction} = props;
    const FD = new FormData();
    const getExtension = (temp_name) => {
      try {
          const arr = temp_name.split(".");
          return arr[arr.length-1];
      } catch (error) {
          return '';
      }
    };
    if(attachmentlist[index].temp_name !== ''){
      FD.path = attachmentlist[index].temp_name;
      FD.name = attachmentlist[index].temp_name;
      FD.type = getExtension(attachmentlist[index].temp_name);
    }
    const [file, setFile] = React.useState(FD);
    const handelClear = () => {
        setFile({});
        attachmentlist[index].temp_name =  '';
        setAttachmentlist([...attachmentlist]);
    }
    const dispatch = useDispatch();
    const uploadFile = (e) => {
        const file = e.target.files[0];
        if(file){
            file.path = e.target.value;
            const fileData = new FormData();
            fileData.append("uploaded_file", file);
            setFile(file);
            dispatch(setDisplayLoader("Display"));
            uploadImages(fileData).then((response) => {
                if(response.data.code === 200){
                  console.log("response data result", response.data.code)
                    if(attachmentlist[index] === undefined) { attachmentlist[index] = {}; }
                    attachmentlist[index].temp_name =  response.data.temp_name;
                    attachmentlist[index].original_name =  response.data.original_name;
                    
                    setAttachmentlist([...attachmentlist])
                }
                }).finally(()=>{
                dispatch(setDisplayLoader("Hide"));
                });
            }
      };
  return (
    <div class="col-md-4">
          <div class="form-group innergroup position-relative modaldefaultclose ">
            <label>
              {label}
              {index === 0 ? <span class="text-danger">*</span> : null}
            </label>
            {showAction && <>
            <input
              type="text"
              class="form-control bg-white"
              disabled
              placeholder={placeHolder}
              id={id}
            />
            {errorAttachment ? (
              <div className="small text-danger">*Attachment is required</div>
            ) : null}
            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input
                type="file"
                id="attachmentsFile"
                accept={accept}
                onChange={uploadFile}
              />
            </div>
            {false ? (
              <div className="small text-danger">
                *File size should not exceed 1mb
              </div>
            ) : null}
            </>}
            {!isEmpty(file) ? <Preview file={file} clearFile={handelClear} showAction={showAction}  /> : null}
          </div>
        </div>
  )
}
Attachment.defaultProps = {
    id: '',
    label: 'Label',
    placeHolder: '',
    errorAttachment: false,
    accept: '',
    onchange:()=>{},
    attachmentlist:[{"name":"Invoice", "value":"INV"},{"name":"Bill", "value":"BILL"},{"name":"Other", "value":"OTH"}],
    setAttachmentlist: ()=>{},
    file:{},
    showAction:true,
}

Attachment.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    errorAttachment: PropTypes.string,
    accept: PropTypes.string,
    attachmentlist: PropTypes.array,
    onchange:PropTypes.func,
    setAttachmentlist:PropTypes.func,
    showAction:PropTypes.bool,
}

export { Attachment }