import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import { Tooltip } from "react-tippy";
import { Badge } from "react-bootstrap";
import copy from "copy-to-clipboard";

const OtvReport = (props) => {
  const {
    otvListData,
    handlerData,
    sortData,
    handlePageClick,
    setPageSizeNo,
    setCurrentPage,
    draftPageCount,
    onDataViewNavigate,
  } = props;

  const copyToClipboard = (text) => {
    copy(text);
    if(text !== null){
    alert(`You have copied "${text}"`);
    }
}

  return (
    <>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured travelintim newSpace">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Pending At</th>
                  <th>Action</th>
                  <th
                    onClick={(e) => {
                      handlerData("id");
                    }}
                    className={
                      sortData["id"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["id"]
                    }
                  >
                    Request ID
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("code");
                    }}
                    className={
                      sortData["code"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["code"]
                    }
                  >
                    Vendor Code
                  </th>
                  <th
                    onClick={(e) => {
                      handlerData("name");
                    }}
                    className={
                      sortData["name"] === undefined
                        ? "unsortcls main-pointer"
                        : sortData["name"]
                    }
                  >
                    Vendor Name
                  </th>
                  <th>GST</th>
                  <th>Location</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Expense Category</th>
                  <th>Base Amount</th>
                  <th>GL Code</th>
                  <th>Cost Center</th>
                  <th>Company Code</th>
                  <th>Bank Name</th>
                  <th>Bank A/c No</th>
                  <th>IFC Code</th>
                  <th>SAP Document No</th>
                  <th>SAP Message</th>
                  <th>SAP status</th>
                  <th>TDS Code</th>
                  <th>GST Code</th>
                  <th>Type</th>
                  <th>Requested By</th>
                  <th>Requested On</th>
                </tr>
              </thead>
              <tbody>
                {otvListData?.length ? (
                  otvListData?.map((data) => (
                    <tr>
                      <td>
                      {(data?.ssc_status === true && data?.picked_by_ssc === false) ?
                                    <Badge bg="primary">In SSC Pick List</Badge>
                                    : data.is_active === true ? (
                                      data?.is_bulk_uploaded === true ?
                                        <Badge bg="primary">Draft</Badge>
                                        :
                                        data?.status === null ? (
                                          <Badge bg="secondary">Pending</Badge>
                                        ) : data?.status == "A" ? (
                                          <Badge bg="success">Approved</Badge>
                                        ) : (
                                          <Badge bg="danger">Rejected</Badge>
                                        )
                                    ) : (
                                      <Badge bg="danger">Deleted</Badge>
                                    )}
                      </td>
                      <td>
                        {data?.status == "A" ? (
                          ""
                        ) : (
                          <Tooltip
                            title={`${data?.current_approver_data?.current_approver_code}`}
                            position="bottom"
                          >
                            {data?.current_approver_data
                              ?.current_approver_name !== undefined
                              ? `${data?.current_approver_data?.current_approver_name} (${data?.current_approver_data?.current_approver_code})`
                              : ""}
                          </Tooltip>
                        )}
                      </td>
                      <td>
                        <button
                          class="btn btn-blanktd text-primary f-12"
                          onClick={() => onDataViewNavigate(data.id, 2)}
                        >
                          <i class="far fa-eye"></i>
                        </button>
                      </td>
                      <td class="text-theme fw-bold">{`${data?.type}${data?.id}`}</td>
                      <td>
                        {data?.vendor_code === null
                          ? "104746"
                          : data?.vendor_code}
                      </td>
                      <td>
                        {data?.otv_name === null
                          ? data?.vendor_name
                          : data?.otv_name}
                      </td>
                      <td>{data?.vendor_gst}</td>
                      <td>{data?.vendor_location}</td>
                      <td>{data?.invoice_number}</td>
                      <td>{moment(data?.invoice_date).format("DD-MM-YYYY")}</td>
                      <td>{data?.expense_name}</td>
                      <td>{data?.gross_amount}</td>
                      <td>
                        <Tooltip
                          title={`${data?.gl_code}`}
                          position="bottom"
                        >{`${data?.gl_desc} (${data?.gl_code})`}</Tooltip>
                      </td>
                      <td>
                        {" "}
                        <Tooltip
                          title={`${data?.cost_center_code}`}
                          position="bottom"
                        >{`${data?.cost_center_name} (${data?.cost_center_code})`}</Tooltip>
                      </td>
                      <td>{data?.organisation_name} - {data?.organisation_code}</td>
                      <td>{data?.bank_name}</td>
                      <td>{data?.bank_account_number}</td>
                      <td>{data?.bank_ifsc_code}</td>
                      <td>{data?.sap_doc_no}</td>
                      <td onClick={()=>{copyToClipboard(data?.sap_message)}} class="otv-message-sap">
                        <Tooltip
                          title={`${data?.sap_message}`}
                          position="bottom"
                        >
                          {data?.sap_message}
                        </Tooltip>
                      </td>
                      <td>{data?.sap_status ? "Posted" : "Not Posted"}</td>
                      <td>
                        <Tooltip
                          title={`${data?.tdscode} ${data?.tds_applicable_section} (${data?.tds_desc})`}
                          position="bottom"
                        >
                          {data?.tdscode !== null &&
                            `${data?.tdscode} ${data?.tds_applicable_section} (${data?.tds_desc})`}{" "}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          title={`${data?.gst_section_desc} (${data?.gstcode})`}
                          position="bottom"
                        >
                          {data?.gstcode !== null &&
                            `${data?.gst_section_desc} (${data?.gstcode})`}
                        </Tooltip>
                      </td>

                      <td>
                        {data?.type === "OTV" ? (
                          <Tooltip
                            title="OTV Payment Request"
                            position="bottom"
                          >
                            OTV Payment Request
                          </Tooltip>
                        ) : data?.type === "NONPO" ? (
                          <Tooltip
                            title="Non PO Vendor Payment Request"
                            position="bottom"
                          >
                            Non PO Vendor Payment Request
                          </Tooltip>
                        ) : data?.type === "NONREIMB" ? (
                          <Tooltip
                            title="Vendor Invoice Reimb below 5K"
                            position="bottom"
                          >
                            Vendor Invoice Reimb below 5K
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{data?.created_by}</td>
                      <td>
                        {moment(data?.created_at)
                          .utc()
                          .format("DD-MMM-YYYY hh:mm a")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={22}> No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                  <option value={500} label={500}>
                    500
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={draftPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtvReport;
