import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { selectUserData } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import * as TravelListingAPI from "../../service/travelService";
import AddTravelIntimation from "./AddTravelIntimation";
import TravelLinks from "./TravelLinks";
import TravelIntimation from "./TravelIntimation";
import { ToastContainer, toast } from "react-toastify";
function ViewTravelApproval(props) {
  const { viewForm, onViewClose, viewFormData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  console.log(userData, "userData");
  const [addnewshow, addnewsetShow] = useState(false);
  console.log(viewForm, viewFormData, "okkanu");
  const addnewopen = () => addnewsetShow(true);
  const addnewclose = () => addnewsetShow(false);
  const [outstandshow, outstandsetShow] = useState(false);
  const outstanding = () => outstandsetShow(true);
  const [editMode, setEditMode] = useState(false);
  const [idUpdate, setIdUpdate] = useState("");
  const outstandclose = () => outstandsetShow(false);
  const onView = (data) => {
    setIdUpdate(data);
    console.log(data, "dataa");
    setViewFormNew(true);
    setViewFormDataNew(data?.travel_detail_data);
    setEditMode(true);
  };
  const [viewFormNew, setViewFormNew] = useState(false);
  const [viewFormDataNew, setViewFormDataNew] = useState(null);
  const onViewCloseNew = () => {
    setViewFormNew(false);
  };
  const deleteOnDuty = (id) => {
    TravelListingAPI.deleteOnDuty(id)
      .then((res) => {
        if (res.data.status == true) {
          // getCountryList(
          //   searchStr,
          //   countryDataFilter.status,
          //   currentPage,
          //   pageSizeNo,
          //   sortData.sort_by,
          //   true
          // );
          // navigate("/travel-listing");
          onViewClose();
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const submit = (id) => {
    confirmAlert({
      title: "Delete Country ",
      message: `Are you sure to delete On-Duty?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteOnDuty(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {viewFormNew ? (
        <>
          <AddTravelIntimation
            viewForm={viewForm}
            onViewCloseNew={onViewCloseNew}
            onViewClose={onViewClose}
            viewFormDataNew={viewFormDataNew}
            idUpdate={idUpdate}
            edit={editMode}
          />
        </>
      ) : (
        <>
          <div class="row">
            {/* <div class="col-md-3">
              <TravelLinks />
            </div> */}
            <div class="col-md-12">
              <div class="content-wrapper-inner content-wrapper-inner2">
                <div class="innerheadsec pb-0">
                  <div className="row">
                    <div class="col-md-12">
                      <h3 class="policyhead">View Travel Detail</h3>
                    </div>
                    <div class="col-md-12">
                      <h3 class="policyhead">
                        Travel Type : {viewFormData?.request_type}
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
                  <Tabs defaultActiveKey="Domestic" id="approvaltabs">
                    <Tab
                      eventKey="Domestic"
                      title={<> {viewFormData?.request_type}</>}
                    >
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered tablecured viewpo">
                          <thead>
                            <tr>
                              <th style={{ width: "15%" }}>From</th>
                              <th style={{ width: "15%" }}>To</th>
                              <th style={{ width: "15%" }}>Departure Date</th>
                              {/* <th>DEPARTURE TIME</th> */}

                              <th style={{ width: "15%" }}>Reaching Date</th>
                              {/* <th>REACHING TIME</th> */}
                              {/* <th style={{ width: "15%" }}>Purpose</th>
                              <th>Remarks</th> */}
                            </tr>
                          </thead>
                          {console.log(
                            viewFormData?.travel_detail_data,
                            "viewFormData?.travel_detail_data"
                          )}
                          <tbody>
                            {viewFormData?.travel_detail_data?.length ? (
                              viewFormData?.travel_detail_data
                                ?.reverse()
                                ?.map((data, index) => (
                                  <tr>
                                    <td class="text-dark fw-bold f-14">
                                      {data?.from_city_name}
                                    </td>
                                    <td class="text-dark fw-bold f-14">
                                      {data?.to_city_name}
                                    </td>
                                    <td>
                                      {moment(data?.from_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      {moment(
                                        data?.from_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm ")}
                                    </td>
                                    {/* <td>
                                      {moment(
                                        data?.from_time,
                                        "HH:mm:ss"
                                      ).format("hh:mm A")}
                                    </td> */}

                                    <td>
                                      {moment(data?.to_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      {moment(data?.to_time, "HH:mm:ss").format(
                                        "hh:mm "
                                      )}
                                    </td>
                                    {/* <td>
                                      {moment(data?.to_time, "HH:mm:ss").format(
                                        "hh:mm A"
                                      )}
                                    </td> */}
                                    {/* <td>{data?.purpose}</td>
                                    <td>{data?.reason}</td> */}
                                  </tr>
                                ))
                            ) : (
                              <tr>
                                <td colSpan={12}> No Record Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div class="col-md-12 mt-3 text-center">
                          <button
                            class="btn btn-outline-secondary mr-2"
                            onClick={onViewClose}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={addnewshow}
            onHide={addnewclose}
            backdrop="static"
            keyboard={false}
            size=""
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Advance Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-11">
                  <div class="row">
                    <table class="table table-advance">
                      <thead>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>13-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>15-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>17-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 700
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>24-01-2023</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 1220
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={outstandshow}
            onHide={outstandclose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>Outstanding</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="row justify-content-center">
                <div class="col-md-11">
                  <div class="row">
                    <table class="table table-advance">
                      <thead>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>13-01-2023</td>
                          <td>Description will come here</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>15-01-2023</td>
                          <td>Description will come here</td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 520
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>17-01-2023</td>
                          <td>Description will come here</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 700
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>24-01-2023</td>
                          <td>Description will come here</td>
                          <td>-</td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 100
                          </td>
                          <td>
                            <i class="fas fa-rupee-sign"></i> 1220
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default ViewTravelApproval;
