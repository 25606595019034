import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as vehicleService from "../../service/vehiclelog";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { Modal, Breadcrumb, Pagination, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as userGl from "../../service/userGl";

const VehicleHistory = () => {
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearchStr] = useState();
  const [vehicleHistoryData, setVehicleHistoryData] = useState();
  const [vehicleIdData, setVehicleIdData] = useState();
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [vehicleDataFilter, setVehicleDataFilter] = useState({
    action: "",
    employee_id: "",
    from_assigned_date: "",
    to_assigned_date: "",
    from_free_date: "",
    to_free_date: "",
  });
  const [allEmployee, setAllEmployee] = useState([]);
  const [dataEmp, setDataEmp] = useState([]);

  let location = useLocation();

  const handleKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    vehicle_id,
    action,
    employee_id,
    from_assigned_date,
    to_assigned_date,
    from_free_date,
    to_free_date
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (vehicle_id !== "" && vehicle_id !== undefined && vehicle_id !== null) {
      queryParm = queryParm + "&vehicle_id=" + vehicle_id;
    }
    if (action !== "" && action !== undefined && action !== null) {
      queryParm = queryParm + "&action=" + action;
    }
    if (
      employee_id !== "" &&
      employee_id !== undefined &&
      employee_id !== null
    ) {
      queryParm = queryParm + "&employee_id=" + employee_id;
    }
    if (
      from_assigned_date !== "" &&
      from_assigned_date !== undefined &&
      from_assigned_date !== null
    ) {
      queryParm = queryParm + "&from_assigned_date=" + from_assigned_date;
    }
    if (
      to_assigned_date !== "" &&
      to_assigned_date !== undefined &&
      to_assigned_date !== null
    ) {
      queryParm = queryParm + "&to_assigned_date=" + to_assigned_date;
    }
    if (
      from_free_date !== "" &&
      from_free_date !== undefined &&
      from_free_date !== null
    ) {
      queryParm = queryParm + "&from_free_date=" + from_free_date;
    }
    if (
      to_free_date !== "" &&
      to_free_date !== undefined &&
      to_free_date !== null
    ) {
      queryParm = queryParm + "&to_free_date=" + to_free_date;
    }
    return queryParm;
  };

  const getEmployeeData = (paginate) => {
    userGl
      .getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let empData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
            record: x?.username,
          };
          data.push(empData);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    vehicle_id,
    action,
    employee_id,
    from_assigned_date,
    to_assigned_date,
    from_free_date,
    to_free_date
  ) => {
    vehicleService
      .getExportValue(
        handleKey(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          vehicle_id,
          action,
          employee_id,
          from_assigned_date,
          to_assigned_date,
          from_free_date,
          to_free_date
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vehicleHistory.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getVehicleHistoryData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    vehicle_id,
    action,
    employee_id,
    from_assigned_date,
    to_assigned_date,
    from_free_date,
    to_free_date
  ) => {
    vehicleService
      .getVehicleHistoryData(
        handleKey(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          vehicle_id,
          action,
          employee_id,
          from_assigned_date,
          to_assigned_date,
          from_free_date,
          to_free_date
        )
      )
      .then((response) => {
        setVehicleHistoryData(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getVehicleHistoryData(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      true,
      vehicleIdData
    );
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getVehicleHistoryData(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        true,
        vehicleIdData
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getVehicleHistoryData(
        data,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        vehicleIdData
      );
    }
  };

  useEffect(() => {
    getVehicleHistoryData(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      vehicleIdData
    );
  }, [pageSizeNo, vehicleIdData]);

  useEffect(() => {
    let data = location?.state?.data?.vehicle_id;
    setVehicleIdData(data);
  }, [location]);

  useEffect(() => {
    getEmployeeData(false);
  }, [show]);

  const filterShow = () => {
    filteropen();
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(vehicleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setVehicleDataFilter(ClearData);
    filterClose();
    setDataEmp([]);
    getVehicleHistoryData(
      searchStr,
      1,
      pageSizeNo,
      "-id",
      true,
      vehicleIdData,
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const filterClose = () => {
    filterclose();
  };

  const mainDataFilter = () => {
    setVehicleDataFilter({
      ...vehicleDataFilter,
      action: vehicleDataFilter.action,
      employee_id: vehicleDataFilter.employee_id,
      from_assigned_date: vehicleDataFilter.from_assigned_date,
      to_assigned_date: vehicleDataFilter.to_assigned_date,
      from_free_date: vehicleDataFilter.from_free_date,
      to_free_date: vehicleDataFilter.to_free_date,
    });
    const action = vehicleDataFilter.action;
    const employee_id = vehicleDataFilter.employee_id;
    const from_assigned_date = vehicleDataFilter.from_assigned_date;
    const to_assigned_date = vehicleDataFilter.to_assigned_date;
    const from_free_date = vehicleDataFilter.from_free_date;
    const to_free_date = vehicleDataFilter.to_free_date;
    getVehicleHistoryData(
      searchStr,
      1,
      pageSizeNo,
      "-id",
      true,
      vehicleIdData,
      action,
      employee_id,
      from_assigned_date,
      to_assigned_date,
      from_free_date,
      to_free_date
    );
    filterClose();
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <div className="defaultchatgptquest text-right">
                <Link to="/vehicle-assign" className="badge badge-secondary">
                  <i className="fas fa-arrow-circle-left"></i> Back
                </Link>
              </div>
              <h4 className="inner-page-title">Vehicle History Log</h4>
            </div>

            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on
                      <strong>Vehicle Number</strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Vehicle Number"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div class="col-lg-8">
              <div class="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        type="button"
                        style={{ border: 0, background: "none" }}
                        onClick={() =>
                          getExportDetails(
                            searchStr,
                            1,
                            pageSizeNo,
                            "-id",
                            true,
                            vehicleDataFilter?.vehicle_id,
                            vehicleDataFilter?.action,
                            vehicleDataFilter?.employee_id,
                            vehicleDataFilter.from_assigned_date,
                            vehicleDataFilter.to_assigned_date,
                            vehicleDataFilter.from_free_date,
                            vehicleDataFilter.to_free_date
                          )
                        }
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Vehicle Number</th>
                <th>Employee</th>
                <th>Assign Date</th>
                <th>Free Date</th>
                <th>Created By</th>
                <th>Updated By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vehicleHistoryData?.length ? (
                vehicleHistoryData?.map((data, index) => (
                  <>
                    <tr>
                      <td>{data?.vehicle_no}</td>
                      <td>{data?.user_name}</td>
                      <td>
                        {data?.assigned_date !== null &&
                          moment(data?.assigned_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {data?.free_date !== null &&
                          moment(data?.free_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {data?.created_at !== null &&
                          `${data?.created_by_user_name} (${moment(
                            data?.created_at
                          )
                            .utc()
                            .format("DD-MM-YYYY")})`}
                      </td>
                      <td>
                        {data?.created_at !== null &&
                          `${data?.updated_by_user_name} (${moment(
                            data?.updated_at
                          )
                            .utc()
                            .format("DD-MM-YYYY")})`}
                      </td>
                      <td>{data?.action}</td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {vehicleHistoryData?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group innergroup">
            <label>Select Action</label>
            <select
              className="form-control newbgselect main-pointer"
              id="selectFilter"
              name="status"
              onChange={(e) => {
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  action: e.target.value,
                });
                setCurrentPage(1);
              }}
              value={vehicleDataFilter.action}
            >
              <option value="" label="Select" disabled />
              <option value="Create" label="Create" />
              <option value="Terminate" label="Terminate" />
              <option value="Re-Activate" label="Re-Activate" />
              <option value="Assign" label="Assign" />
              <option value="Release" label="Release" />
            </select>
          </div>
          <div className="form-group innergroup">
            <label>Employee ID</label>
            <Select
              name="employee_id"
              id="employee_ids"
              options={allEmployee}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setDataEmp(e);
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  employee_id: e.value,
                });
              }}
              value={dataEmp}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              From Assigned Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  from_assigned_date: e.currentTarget.value,
                })
              }
              value={vehicleDataFilter.from_assigned_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Assigned Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  to_assigned_date: e.currentTarget.value,
                })
              }
              value={vehicleDataFilter.to_assigned_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              From Free Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  from_free_date: e.currentTarget.value,
                })
              }
              value={vehicleDataFilter.from_free_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              To Free Date <span class="text-danger"></span>
            </label>
            <input
              type="date"
              class="form-control"
              onChange={(e) =>
                setVehicleDataFilter({
                  ...vehicleDataFilter,
                  to_free_date: e.currentTarget.value,
                })
              }
              value={vehicleDataFilter.to_free_date}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              clearFilter();
              setCurrentPage(1);
            }}
          >
            CLEAR
          </button>
          <button
            type="submit"
            className="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VehicleHistory;
