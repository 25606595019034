import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getReimbEntitlement,
  saveMobileReimbursement,
  updateReimbursementData,
  uploadfile,
} from "./claimReimbServices";

import Select from "react-select";
import * as moment from "moment";
import { useSelector } from "react-redux";
import { selectUserData } from "../redux/piDataStore";
import { toast } from "react-toastify";
import { get_all_recruiters,updateNoticeReimbursementData, get_curruser_data, submit_notice_form } from "./NoticeReimbServices";

import {getUserDetails} from "../../service/header";
import { isInteger } from "formik";

function EmpRefReimbursement(props) {
  const { onClose, edit, editRecord } = props;
  const manufacturerOptions = [
    { value: "Select", label: "Select" },
    { value: "Apple", label: "Apple" },
    { value: "Blackberry", label: "Blackberry" },
    { value: "Asus", label: "Asus" },
    { value: "Coolpad", label: "Coolpad" },
    { value: "Gionne", label: "Gionne" },
  ];

  const osOptions = [
    { value: "Select", label: "Select" },
    { value: "iOS8", label: "iOS8" },
    { value: "iOS9", label: "iOS9" },
    { value: "iOS10", label: "iOS10" },
    { value: "Android4.x", label: "Android4.x" },
  ];

  const [formObj, setFormObj] = useState({
    reimburseType: "4",
    // recruiter: "",
    amount: "" ,
    consent: false,
    attachment: null,
    refEmployee:""
    
  });

  const [formError, setFormError] = useState({
    // recruiter: false,
    amount: false,

    consentErr: false,
    osVer: false,
    billNo: false,
    billDate: false,
    particulars: false,
    amount: false,

    attachment: false
  });

  const userData = useSelector(selectUserData);

  const [lastClaimDate, _ ] = useState("");
  const [filename, setFilename] = useState("");
  const [fileurl, setfileurl] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [myrecruiter,setRecruiter] = useState("");


  const fileUploadHandler = (e) => {
    const fileData = new FormData();
    let files = [];
    let urls = [];
    let fileNames = [];
    let file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      let myUrl = URL.createObjectURL(e.target.files[i]);
      urls.push(myUrl);
      let data = e.target.files[i];
      fileData.append("uploaded_file", data);
      let name = { name: data.name };
      fileNames.push(name);
      uploadfile(fileData).then((response) => {
        files.push(response.data);
      });
    }
    setFormObj((prev) => ({
      ...prev,
      attachment: files,
    }));
    setFilename(fileNames);
    setfileurl(urls);

    setFormError((prev) => ({
      ...prev,
     
      attachment: false
    
    }))

  };


  const checkErrors = () => {
    console.log(Object.values(formObj))
   
    let flag = Object.values(formObj)
      .filter((obj) => { 
        if (obj !== "1" || obj !== null ) {
          
          return true;
        }
      })
      .includes("");
    
      // console.log(`flag=${flag}`);console.log(flag)
      // console.log(`formObj=${formObj}`);console.log(formObj)
      
    if (flag || (!formObj.consent ) ||( formObj.attachment === undefined)) {
      setFormError((prev) => ({
        ...prev,
        amount: formObj?.amount === "" ? true : false,
        recruiter: formObj?.recruiter === "" ? true : false,
        consentErr: !formObj?.consent  ? true : false,
        attachment: formObj?.attachment == null ? true : false
      
      }));
      return false;
    }
     else {
      return true;
    }
  };




  const submitReimbursement = () => {
    if (checkErrors()) {
      if (edit) {
        updateNoticeReimbursementData(editRecord?.id, formObj).then((response) => {
          if (response?.status === 200) {
            toast.success(response?.data?.message);
            // onAddOrUpdate();
            onClose()
          }
        });
      } else {
       
        submit_notice_form(formObj).then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            onClose()
           
          }

        });


      }
    }
  };



  const getEntitlement = (empSubGroup) => {
    getReimbEntitlement(empSubGroup, "1").then((response) => {
      if (response?.status === 200) {
        setFormObj((prev) => ({
          ...prev,
          mobEntitlement: response?.data?.dataList?.amount,
        }));
      }
    });
  };

  

  const changeHandler = (type, value) => {
    switch (type) {
      case "consent":
        if (value) {
          setFormObj((prev) => ({
            ...prev,
            consent: true,
          }));

          setFormError((prev) => ({
            ...prev,
            consentErr: false,
          }));
        } else {
           setFormObj((prev) => ({
          ...prev,
          consent: false,
        }));
          setFormError((prev) => ({
            ...prev,
            consentErr: true,
          }));
        }


       



      break;


      case "amount":

        if (value !== "" && value>0) {
          setFormError((prev) => ({
            ...prev,
            amount: false,
          }));
        } else {
          setFormError((prev) => ({
            ...prev,
            amount: true,
          }));
        }


        setFormObj((prev) => ({
          ...prev,
          amount: value,
        }));
        break;
        case "recruiter":
          if (value !== "" ) {
            setFormError((prev) => ({
              ...prev,
              recruiter: false,
            }));
  
          } else {
            setFormError((prev) => ({
              ...prev,
              recruiter: true,
            }));
          }
  
  
          setFormObj((prev) => ({
            ...prev,
            recruiter: value,
          }));
          break;
      case "refEmployee":
        if (value !== "" ) {
          setFormError((prev) => ({
            ...prev,
            recruiter: false,
          }));


          refEmpList.filter((item)=>{
            if (item.username===value.value){
                getUserDetails(item.id).then(resp=>{
                  console.log(resp)
                  setRefState({
                    ...refState,
                    desgn:resp.data.dataList.designation_name,
                    dept:resp.data.dataList.department_name,
                    bunit:resp.data.dataList.business_unit_name,
                    // level:resp.data.dataList[0].business_unit_name,
                    // location:resp.data.dataList[0].business_unit_name,

                  })
                })
                .catch(e=>{
                  alert(e)
                })
            }
          })


        } else {
          setFormError((prev) => ({
            ...prev,
            recruiter: true,
          }));
        }


        setFormObj((prev) => ({
          ...prev,
          refEmployee: value,
        }));
        break;
      // case "consent":
      //   console.log(value)
      //   if (!value.target.checked ) {
      //     setFormError((prev) => ({
      //       ...prev,
      //       consentErr: true,
      //     }));
      //   } else {
      //     setFormError((prev) => ({
      //       ...prev,
      //       consentErr: false,
      //     }));
      //   }
      //   break;
      case "manufacturer":
        setFormObj((prev) => ({
          ...prev,
          manufacturer: value,
        }));
        break;
      case "osVer":
        setFormObj((prev) => ({
          ...prev,
          osVer: value,
        }));
        break;
      case "billNo":
        setFormObj((prev) => ({
          ...prev,
          billNo: value,
        }));
        break;
      case "billDate":
        setFormObj((prev) => ({
          ...prev,
          billDate: value,
        }));
        break;
      case "particulars":
        setFormObj((prev) => ({
          ...prev,
          particulars: value,
        }));
        break;
      case "amount":
        setFormObj((prev) => ({
          ...prev,
          amount: value,
        }));
        break;
      default:
        break;
    }
  };

  const [currState, setcurrState] = useState({
    username: "",
    department: "",
    designation: "",
    level: "",
    business_unit: "",
    location: "",
    hr:""
   
  });


  const [recruiterList, setrecruiterList] = useState([]);
  const [refEmpList, setRefEmployees] = useState([]);
  const [refState,setRefState] = useState({
    desgn:"",
    dept:"",
    level:"",
    location:"",
    bunit:""
  })

  

  const get_curruser=()=>{
    get_curruser_data().then((response)=>{

        if (response?.status === 200) {

            setcurrState(response?.data?.dataList)
            
          }

    })
  }



  const get_recruiters=()=>{

    get_all_recruiters().then((response)=>{

        if (response?.status === 200) {

          let resData= response.data.dataList.result?.map((res)=>{
            return{
                value:res.username,
                label:res.first_name+" "+res.last_name+` (${res.username})`
            }
        })


            setrecruiterList(resData)

             resData= response.data.dataList.result?.map((res)=>{
              return{
                username:res.username,
                  id:res.id
              }
            })
            setRefEmployees(resData)
            // alert('..',resData)
            // console.log(resData)
            
          }

    })
  }


  useEffect(() => {
    
   
    
    
    get_curruser()

    get_recruiters()

    if (userData?.emp_sub_group) {
      getEntitlement(userData?.emp_sub_group);
    }
  }, [userData]);

  useEffect(() => {
    console.log('.....',editRecord);
    if (edit) {

      let ele=document.getElementById("consentBox")
      ele.checked=true

      setFormObj((prev) => ({
        ...prev,
        amount: editRecord?.amount,
        consent: true,
     
        // recruiter: recruiterList?.filter((obj) => {
          
        //   if (`${obj?.value}` === `${editRecord?.recruiter}`) {
        //     return true;
        //   }

        // })[0]
        // lastClaimed: moment(editRecord?.last_claimed).format("DD-MM-YYYY"),
        // billDate: moment(editRecord?.bill_date).format("YYYY-MM-DD"),
        // billNo: editRecord?.bill_no,
        // mobEntitlement: editRecord?.mobile_entitlement,
        // companyMob: editRecord?.company_mobile,
        // imeiNo: editRecord?.imei_no,
        // osVer: osOptions?.filter((obj) => {
        //   if (obj?.value === editRecord?.os_ver) {
        //     return true;
        //   }
        // }),
        // manufacturer: manufacturerOptions?.filter((obj) => {
        //   if (obj?.value === editRecord?.manufacturer) {
        //     return true;
        //   }
        // }),
        // particulars: editRecord?.particulars,
      }));
      setFilename(editRecord?.attachment);
    }
    else{
      setFormObj({
        amount: "",
        consent: false,
        // recruiter: ""
        })
    }
  // }, [edit, recruiterList]);
}, [edit]);

  return (
    <>
      <div class="row">
      <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Employee Code</label>
            <input
              type="text"
              class="form-control"
              value={currState.username}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Designation</label>
            <input type="text" class="form-control"
              value={currState.designation}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Department</label>
            <input
              type="text"
              class="form-control"
              value={currState.department}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Level</label>
            <input
              type="text"
              class="form-control"
              value={currState.level}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Location</label>
            <input
              type="text"
              class="form-control"
              value={currState.location}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

      

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Business Unit</label>
            <input
              type="text"
              class="form-control"
              value={currState.business_unit}
             
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
              Select Recruiter <span class="text-danger">*</span>
            </label>

            <Select
              options={recruiterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler("recruiter", e);
              }}
              value={formObj?.recruiter}
            />

            {formError?.recruiter ? (
              <div className="small text-danger">Select recruiter</div>
            ) : null}
          </div>
        </div>      

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>
            Referred Employee Name/Code <span class="text-danger">*</span>
            </label>

            <Select
              options={recruiterList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                changeHandler("refEmp", e);
              }}
              value={formObj?.refEmployee}
            />

            {formError?.recruiter ? (
              <div className="small text-danger">Select recruiter</div>
            ) : null}
          </div>
        </div>    

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Designation</label>
            <input type="text" class="form-control"
              value={refState.desgn}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Department</label>
            <input
              type="text"
              class="form-control"
              value={refState.dept}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Level</label>
            <input
              type="text"
              class="form-control"
              value={currState.level}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Location</label>
            <input
              type="text"
              class="form-control"
              value={currState.location}
              placeholder="Enter Last Claimed On"
              disabled
            />
          </div>
        </div>

      

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Business Unit</label>
            <input
              type="text"
              class="form-control"
              value={refState.bunit}
             
              disabled
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group innergroup">
            <label>Ref Amount</label>
            <input
              type="number"
              class="form-control"
              disabled
              value={currState.level >="J1" && currState.level <="J3"  ? 18000 : 
                    currState.level >="S1" && currState.level <="S4"  ? 50000 :
                    currState.level >="M1" && currState.level <="M2"  ? 35000 :
                    8000                     
                }  
            />
          </div>
        </div>


        </div>

      <div class="row">

         <div class="col-md-12">
          <div class="form-group innergroup">
            <label>
              Consent <span class="text-danger"> * </span>
            </label>
            <br/>
            <input 
              type="checkbox"
              class=""
              id="consentBox"
             
              onClick={(e) => {
                changeHandler("consent", e.target.checked);
                console.log(e.target.checked);
                
              }}
             
            />
            <span> Consent if employee leave within 2 years from DOJ
same amount will recover from F&amp;F Settlement</span>
            {formError?.consentErr ? (
              <div className="small text-danger">Please consent</div>
            ) : null
            }
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group innergroup position-relative modaldefaultclose ">
            <label>
              Attachment <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              disabled
              class="form-control bg-white"
              
              placeholder={
                filename?.length > 0 ? filename : "JPG,PNG,PDF Format Only"
              }
            />
            <div class="upload-btn-wrapper up-loposition">
              <button class="uploadBtn">Browse</button>
              <input type="file" onChange={fileUploadHandler} />
            </div>
            {formError?.attachment ? (
              <div className="small text-danger">Please select an attachment</div>
            ) : null}
            <br/>
            <p>
              Selected files{" "}
              <span>
                {filename?.length > 0
                  ? filename?.map((obj, i) => (
                      <>
                        <a href={fileurl[i]} target="_blank">
                          {edit ? obj?.name.split("/").pop() : obj?.name}
                        </a>
                        <br />
                      </>
                    ))
                  : ""}
              </span>
            </p>
          </div>
        </div>

        
      </div>
      <Modal.Footer>
        <button class="btn btn-outline-danger" onClick={onClose}>
          Cancel
        </button>

        <button
          class="btn btn-primary-inner bpi-main"
          onClick={submitReimbursement}
        >
          Submit
        </button>
      </Modal.Footer>
    </>
  );
}

export default EmpRefReimbursement;
