import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import * as vehicleService from "../../service/vehiclelog";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import AssignNewVehicle from "./assignNewVehicle";
import { useNavigate } from "react-router";
import copy from "copy-to-clipboard";
import * as OrganisationService from "../../service/organisation";
import Select from "react-select";

const AssignNewVehicleIndex = () => {
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editVehicleAssignData, setEditVehicleAssignData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const [vehicleAssignValues, setVehicleAssignValues] = useState();
  const [searchStr, setSearchStr] = useState();
  const [assignFlag, setAssignFlag] = useState();
  const [fShow, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [vehicleAssignFilter, setVehicleAssignFilter] = useState({
    free_date: "",
    company: "",
    is_free: "",
  });
  const [vehicleDataFilter, setVehicleDataFilter] = useState();
  const [companyDropdown, setCompanyDropdown] = useState([]);

  let navigate = useNavigate();

  const handleKey = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    free_date,
    company,
    is_export
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (is_free !== "" && is_free !== undefined && is_free !== null) {
      queryParm = queryParm + "&is_free=" + is_free;
    }
    if (free_date !== "" && free_date !== undefined && free_date !== null) {
      queryParm = queryParm + "&free_date=" + free_date;
    }
    if (company !== "" && company !== undefined && company !== null) {
      queryParm = queryParm + "&company=" + company;
    }
    if (is_export !== "" && is_export !== undefined && is_export !== null) {
      queryParm = queryParm + "&is_export=" + is_export;
    }
    return queryParm;
  };

  const getVehicleAssignData = (
    search,
    pageNo,
    page_size,
    sort_by,
    paginate,
    is_free,
    free_date,
    company,
    is_export
  ) => {
    vehicleService
      .getVehicleAssignData(
        handleKey(
          search,
          pageNo,
          page_size,
          sort_by,
          paginate,
          is_free,
          free_date,
          company,
          is_export
        )
      )
      .then((response) => {
        setVehicleAssignValues(response?.data?.dataList?.result);
        setPageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllOrganisation = () => {
    OrganisationService.getAllOrganisation(false).then((res) => {
      // setApproverList(res?.data?.dataList?.result);
      const data = [
        { value: null, label: "--select--" },
        ...res?.data?.dataList?.result?.map((x) => {
          return {
            value: x.code,
            label: `${x?.name} ( ${x.code} )`,
          };
        }),
      ];
      setCompanyDropdown(data);
    });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getVehicleAssignData(
        e.target.value.toLowerCase(),
        currentPage,
        pageSizeNo,
        "-id",
        true,
        "",
        "",
        ""
      );
      setSearchStr(e.target.value);
    }
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getVehicleAssignData(
        data,
        currentPage,
        pageSizeNo,
        "-id",
        true,
        false,
        "",
        "",
        ""
      );
    }
  };

  const onCloseButtonClick = () => {
    setShow(false);
  };

  const onAddCallBack = () => {
    getVehicleAssignData(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      false,
      "",
      "",
      ""
    );
    setShow(false);
  };

  const editData = (data) => {
    setShow(true);
    setEditVehicleAssignData(data);
    setEditMode(true);
    setAssignFlag(false);
  };

  const onAddButtonClick = () => {
    setShow(true);
    setEditMode(false);
    setAssignFlag(false);
    setEditVehicleAssignData(null);
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getVehicleAssignData(
      searchStr,
      activePage,
      pageSizeNo,
      "-id",
      true,
      vehicleAssignFilter.is_free,
      vehicleAssignFilter.free_date,
      vehicleAssignFilter.company,
      ""
    );
  };

  const handleHistory = (data) => {
    navigate("/vehicle_history", { state: { data: data } });
  };

  const getExportDetailsReport = () => {
    const free_date = vehicleAssignFilter.free_date;
    const company = vehicleAssignFilter.company;
    const is_free = vehicleAssignFilter.is_free;

    vehicleService
      .getExportValueDemand(
        handleKey(
          searchStr,
          1,
          10,
          "-id",
          true,
          is_free,
          free_date,
          company,
          true
        )
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vehicle_assign.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleRemoveAssign = (value, data) => {
    setEditVehicleAssignData(data);
    setAssignFlag(value);
    setShow(true);
  };

  useEffect(() => {
    getAllOrganisation();
    getVehicleAssignData(
      "",
      currentPage,
      pageSizeNo,
      "-id",
      true,
      false,
      "",
      "",
      ""
    );
  }, [pageSizeNo]);

  const mainDataFilter = () => {
    const free_date = vehicleAssignFilter.free_date;
    const company = vehicleAssignFilter.company;
    const is_free = vehicleAssignFilter.is_free;
    getVehicleAssignData(
      searchStr,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      is_free,
      free_date,
      company,
      ""
    );
    filterClose();
  };

  const filterClose = () => {
    filterclose();
  };

  const clearfilter = () => {
    filterClose();
    getVehicleAssignData(
      searchStr,
      currentPage,
      pageSizeNo,
      "-id",
      true,
      "",
      "",
      "",
      ""
    );
    setVehicleDataFilter();
    setVehicleAssignFilter({
      free_date: "",
      company: "",
      is_free: "",
    });
  };

  return (
    <>
      <div className="content-wrapper-inner">
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">Add Assign Vehicle</h4>
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div class="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on
                      <strong>
                        Vehicle Number,
                        <br />
                        UserName,
                        <br />
                        Location
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Vehicle Number, UserName,Location..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                <Dropdown show>
                  <Dropdown.Toggle
                    variant="primary-inner dropdownbtn"
                    id="dropdown-basic"
                  >
                    <img src="images/export.png" alt="" class="mr-2" /> Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <button
                        style={{ border: 0, background: "none" }}
                        onClick={() => getExportDetailsReport()}
                      >
                        <i className="far fa-file-excel"></i>Excel
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button onClick={filteropen} class="btn btn-secondary-inner">
                  <img src="images/filter.png" alt="" class="mr-3" />
                  Filter
                </button>
                <button
                  className="btn btn-primary-inner"
                  type="button"
                  onClick={onAddButtonClick}
                >
                  <img src="images/upload.png" alt="" className="mr-3" />
                  Add New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>UserName</th>
                <th>Vehicle Number</th>
                <th>Location</th>
                <th>Assign Date</th>
                <th>Company</th>
                <th>Free Date</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vehicleAssignValues?.length ? (
                vehicleAssignValues?.map((data, index) => (
                  <>
                    <tr>
                      <td>
                        {data?.user_name}({data?.user_code})
                      </td>
                      <td className="d-flex justify-content-around">
                        <div
                          class="text-theme fw-bold"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleHistory(data, "redirect")}
                        >
                          {data?.vehicle_no}
                        </div>
                        <button
                          className="btn btn-blanktd text-primary ml-2"
                          type="button"
                          onClick={() => copy(data?.vehicle_no)}
                        >
                          <i class="fas fa-copy"></i>
                        </button>
                      </td>

                      <td>{data?.vehicle_name}</td>
                      <td>{moment(data?.assign_date).format("DD-MM-YYYY")}</td>
                      <td>{data?.company}</td>
                      <td>
                        {data?.free_date !== null &&
                          moment(data?.free_date).format("DD-MM-YYYY")}
                      </td>
                      <td>{data?.is_free == false ? "Active" : "Inactive"}</td>
                      <td>
                        {data?.created_by_fullname !== null &&
                          `${data?.created_by_fullname} (${moment(
                            data?.created_at
                          )
                            .utc()
                            .format("DD-MM-YYYY")})`}
                      </td>
                      <td>
                        <button
                          className="btn btn-blanktd text-primary"
                          type="button"
                          onClick={() => editData(data)}
                        >
                          <i className="far fa-edit"></i>
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          style={{ "font-size": "75%" }}
                          className="btn btn-primary-inner bpi-main"
                          type="button"
                          onClick={() => handleRemoveAssign(true, data)}
                        >
                          Remove Assigned Vehicle
                        </button>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={6}> No Record Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {vehicleAssignValues?.length == 0 ? (
          ""
        ) : (
          <div className="row">
            <div className="col-sm-5">
              <div className="sortinglist">
                Show
                <select
                  className="form-control"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>

      {show && (
        <AssignNewVehicle
          show={show}
          onCloseButtonClick={onCloseButtonClick}
          edit={editMode}
          editVehicleAssignData={editVehicleAssignData}
          vehicleAssignValues={vehicleAssignValues}
          onAddCallBack={onAddCallBack}
          assignFlag={assignFlag}
        />
      )}

      <Modal
        show={fShow}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Free Date</label>
            <input
              type="date"
              class="form-control"
              name="free_date"
              min={vehicleAssignFilter.free_date}
              onChange={(e) => {
                setVehicleAssignFilter({
                  ...vehicleAssignFilter,
                  free_date: e.target.value,
                });
              }}
              value={vehicleAssignFilter.free_date}
            />
          </div>
          <div class="form-group innergroup">
            <label>
              Select Company <span class="text-danger"></span>
            </label>
            <Select
              options={companyDropdown}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setVehicleAssignFilter({
                  ...vehicleAssignFilter,
                  company: e.value,
                });
                setVehicleDataFilter(e);
              }}
              value={vehicleDataFilter}
            />
          </div>
          <div className="form-group innergroup">
            <label>
              Select Status <span className="text-danger"></span>
            </label>
            <select
              className="form-control newbgselect main-pointer"
              name="status"
              onChange={(e) => {
                setVehicleAssignFilter({
                  ...vehicleAssignFilter,
                  is_free: e.target.value,
                });
              }}
              value={vehicleAssignFilter?.is_free}
            >
              <option value="" disabled>
                Select
              </option>
              <option value={false} label="Active" />
              <option value={true} label="In-Active" />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              clearfilter();
              setCurrentPage(1);
            }}
          >
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignNewVehicleIndex;
