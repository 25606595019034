import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Breadcrumb,
  Dropdown,
  Pagination,
  Badge,
  Tabs,
  Tab,
} from "react-bootstrap";
import Select from "react-select";
import AddreleaseStrategy from "./AddreleaseStrategy";
import * as RelStrategyServices from "../../service/releaseStrategy";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Formik } from "formik";
import * as moment from "moment";
import { setDisplayLoader } from "../redux/piDataStore";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tippy";

if (typeof window !== "undefined") {
  injectStyle();
}

function ReleaseStrategy() {
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [releaseList, setReleaseList] = useState([]);
  const [relData, setRelData] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allStrat, setAllStrat] = useState([]);
  const [allStratData, setAllStratData] = useState([]);
  const [allCode, setAllCode] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [dataFilter, setDataFilter] = useState({
    release_group_id: "",
    release_strat_id: "",
  });

  const navigate = useNavigate();

  const options = [
    { value: "Option1", label: "Option1" },
    { value: "Option2", label: "Option2" },
    { value: "Option3", label: "Option3" },
    { value: "Option4", label: "Option4" },
    { value: "Option5", label: "Option5" },
  ];

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const handleKey = (
    search,
    release_group_id,
    release_strat_id,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (
      release_group_id !== "" &&
      release_group_id !== undefined &&
      release_group_id !== null
    ) {
      queryParm = queryParm + "&release_group_id=" + release_group_id;
    }
    if (
      release_strat_id !== "" &&
      release_strat_id !== undefined &&
      release_strat_id !== null
    ) {
      queryParm = queryParm + "&release_strat_id=" + release_strat_id;
    }

    return queryParm;
  };

  const getReleaseStrategy = (
    search,
    release_group_id,
    release_strat_id,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));

    RelStrategyServices.getReleaseStrategy(
      handleKey(
        search,
        release_group_id,
        release_strat_id,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));

        setReleaseList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getExportDetails = (
    search,
    release_group_id,
    release_strat_id,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    RelStrategyServices.getExportValue(
      handleKey(
        search,
        release_group_id,
        release_strat_id,
        pageNo,
        page_size,
        sort_by,
        paginate
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "releasestrategy.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const addForm = () => {
    setShowForm(true);
    setEditMode(false);
    setRelData(null);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const editGrp = (data) => {
    setShowForm(true);
    setEditMode(true);
    setRelData(data);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getReleaseStrategy(
      searchStr,
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReleaseStrategy(
      searchStr,
      dataFilter.release_group_id,
      dataFilter.release_strat_id,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
  };

  const deleteRelease = (idx) => {
    RelStrategyServices.deleteRelease(idx)
      .then((res) => {
        if (res.data.status === true) {
          getReleaseStrategy(
            "",
            "",
            "",
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Release Strategy",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRelease(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      getReleaseStrategy(
        data,
        "",
        "",
        currentPage,
        pageSizeNo,
        sortData.sort_by,
        true
      );
    }
  };
  const mainDataFilter = (e) => {
    e.preventDefault();
    const relgrp = dataFilter?.release_group_id;
    const relstrt = dataFilter?.release_strat_id;
    getReleaseStrategy(
      searchStr,
      relgrp,
      relstrt,
      1,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    filterclose();
  };

  const handleSearch = (e) => {
    getReleaseStrategy(
      e.target.value.toLowerCase(),
      "",
      "",
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true
    );
    setSearchStr(e.target.value);
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter(ClearData);
    setDataFilter({ release_group_id: "", release_strat_id: "" });
    setAllGroupsData([]);
    setAllStratData([]);
    setCurrentPage(1);
    filterclose();
    getReleaseStrategy("", "", "", 1, pageSizeNo, sortData.sort_by, true);
  };

  const getAllStrat = (paginate) => {
    RelStrategyServices.getAllStrat(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code} ${
              x?.desc === null ? "" : "-" + x?.desc
            }`,
          };
          data.push(newData);
        });
        setAllStrat(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getAllGroups = (paginate) => {
    RelStrategyServices.getAllGroups(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code === null ? "" : x?.code}  ${
              x?.desc === null ? "" : "-" + x?.desc
            }`,
          };
          data.push(newData);
        });
        setAllGroups(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const openFilter = () => {
    filteropen();
    getAllGroups(false);
    getAllStrat(false);
  };

  useEffect(() => {
    getReleaseStrategy("", "", "", 1, pageSizeNo, sortData.sort_by, true);
  }, [pageSizeNo]);

  return (
    <>
      {showForm ? (
        <>
          <AddreleaseStrategy
            closeForm={closeForm}
            showForm={showForm}
            edit={editMode}
            editGrp={relData}
            onAddCallBack={onAddCallBack}
          />
        </>
      ) : (
        ""
      )}

      <div className="row">
        <ToastContainer autoClose={1000} />
        {/* <div className="col-md-3">
          <ul className="policylistnames">
            <li>
              <Link to="/search-po">Search Order</Link>
            </li>
            <li>
              <Link to="/active-po">Active PO Status</Link>
            </li>
            <li>
              <Link to="/approver-assignment">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/releasegroup">Release Group</Link>
            </li>
            <li>
              <Link to="/releasecode">Release Code</Link>
            </li>
            <li>
              <Link to="/purchase-org">Purchase Organisation</Link>
            </li>
            <li>
              <Link to="/purchase-group">Purchase Group</Link>
            </li>
            <li>
              <Link to="/releasestrat">Release Strategy</Link>
            </li>
            <li className="active">
              <Link to="/releasestrategy">Approval Mapping</Link>
            </li>
          </ul>
        </div> */}
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="inner-page-title">
                    Release Strategy Approver Mapping
                  </h4>
                </div>
                <div className="col-md-12">
                  <div className="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="input-group table-search">
                    <span className="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      id="searchbar"
                      className="form-control"
                      placeholder="Search Code..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <span className="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          className="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" className="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={releaseList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                searchStr,
                                dataFilter.release_group_id,
                                dataFilter.release_strat_id,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={() => openFilter()}
                      className="btn btn-secondary-inner"
                    >
                      <img src="images/filter.png" alt="" className="mr-3" />
                      Filter
                    </button>
                    <button
                      className="btn btn-primary-inner"
                      onClick={() => {
                        addForm();
                      }}
                    >
                      <img src="images/upload.png" alt="" className="mr-3" />
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Rel. Group</th>
                    <th>Rel. Strategy</th>
                    <th>Rel. Code 1</th>
                    <th>Rel. Code 2</th>
                    <th>Rel. Code 3</th>
                    <th>Rel. Code 4</th>
                    <th>Rel. Code 5</th>
                    <th>Rel. Code 6</th>
                    <th>Rel. Code 7</th>
                    <th>Rel. Code 8</th>
                    <th
                      style={{
                        width: "200px",
                      }}
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {releaseList?.length ? (
                    releaseList?.map((data, index) => (
                      <>
                        <tr key={`desg${index}`}>
                          <td>
                            <button className="btn btn-blanktd text-primary ml-2 f-12">
                              <i
                                className="far fa-edit"
                                type="button"
                                onClick={() => editGrp(data)}
                              ></i>
                            </button>
                            <button className="btn btn-blanktd text-danger ml-2 f-12">
                              <i
                                className="far fa-trash-alt text-danger"
                                type="button"
                                onClick={() => {
                                  submit(data?.id);
                                }}
                              ></i>
                            </button>
                          </td>

                          <td>{data?.release_group_name}</td>
                          <td>{data?.release_strat_name}</td>
                          <td>{data?.release_code_1_name}</td>
                          <td>{data?.release_code_2_name}</td>
                          <td>{data?.release_code_3_name}</td>
                          <td>{data?.release_code_4_name}</td>
                          <td>{data?.release_code_5_name}</td>
                          <td>{data?.release_code_6_name}</td>
                          <td>{data?.release_code_7_name}</td>
                          <td>{data?.release_code_8_name}</td>
                          <td
                            style={{
                              maxWidth: "130px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Tooltip
                              title={data?.description}
                              position="bottom"
                            >
                              {data?.description}
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {releaseList?.length == 0 ? (
              ""
            ) : (
              <div className="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="form-group innergroup">
              <label>Release Group</label>
              <Select
                name="release_group_id"
                id="release_group_id"
                options={allGroups}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllGroupsData(e);
                  setDataFilter({
                    ...dataFilter,
                    release_group_id: e.value,
                  });
                }}
                value={allGroupsData}
              />
            </div>
            <div className="form-group innergroup">
              <label>Release Strategy</label>
              <Select
                name="release_strat_id"
                id="release_strat_id"
                options={allStrat}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllStratData(e);
                  setDataFilter({
                    ...dataFilter,
                    release_strat_id: e.value,
                  });
                }}
                value={allStratData}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear
            </button>
            <button
              className="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={(e) => mainDataFilter(e)}
            >
              Apply
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ReleaseStrategy;
