import React from "react";
import CustomMenu from "./CustomMenu";
import CustomBreadcrumb from "./CustomBreadcrumb";
import { Link } from "react-router-dom";

function UpdateCAS() {
  const CustomBreadcrumbItems = [
    { to: '/home', title: 'Home' },
    { to: '/', title: 'Transactions' },
    { active: true, title: 'Update CAS' }
  ]

  return (
    <>
      <div class="row">
        <div class="col-md-3">
          <CustomMenu />
        </div>
        <div class="col-md-9">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec pb-0">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Update CAS</h4>
                  <CustomBreadcrumb items={CustomBreadcrumbItems} />
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
            </div>

            <div class="px-4 pb-2">
              <div class="bg-light px-3 pt-3 mb-3">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        Country<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value="IN"
                        placeholder="Enter Country"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        CAS No.<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter CAS No"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group innergroup">
                      <label>
                        CAS Description<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter CAS Description"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="row">
                  <div class="col-md-12 mt-3 text-center">
                    <Link to="/mat-ext" class="btn btn-outline-secondary mr-2">
                      Cancel
                    </Link>
                    <button class="btn btn-primary-inner bpi-main">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCAS;
