import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../../audit/auditlog";
import { selectAudit } from "../../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as BrandService from "../../../service/brandService";
import Select from "react-select";
import * as UserService from "../../../service/userService";
import { getOrganisation } from "../../../service/businessService";

const validationSchema = Yup.object({
  category_id: Yup.string().required("Branding Category is required."),
  title: Yup.string()
    .matches(/[^\s*].*[^\s*]/g, "* This field cannot contain only blank spaces")
    .required("*Branding Title is required"),
  // desc: Yup.string().required("*Brand Description is required"),
  category_id: Yup.string().required("*Brand category is required"),
});

const AddBrand = (props) => {
  const nameForm = "Policy Master";
  const [Errororganisation, setErrororganisation] = useState(false);

  const [flag, setflag] = useState(false);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [deleteattachment, setdeleteAttachment] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);

  const { edit, showForm, onClose, editPolicy, onAddCallBack, branchcopy1 } =
    props;
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [policyExist, setIsPolicyExist] = useState(false);
  const [branch, setBranchData] = useState([]);
  const [branchData, setBranch] = useState([]);
  const [branchcopy, setBranchDatacopy] = useState([]);
  const [brandCategory, setbrandCategory] = useState([]);
  const [dept, setdeptData] = useState([]);
  const [deptData, setDept] = useState([]);
  const [subDept, setSubDeptData] = useState([]);
  const [subDeptData, setSubDept] = useState([]);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [userList, setUserList] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [org, setOrg] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [orgOldData, setOldOrgData] = useState([]);
  const [dublicateOrgData, setDubOrgData] = useState([]);
  const [brand, setBrand] = useState({
    category_id: "",
    title: "",
    desc: "",
    attachment_path: {},
    branch_id: [],
    dept_id: [],
    sub_dept_id: [],
    is_active: true,
  });
  const [link, setLink] = useState();

  const submitHandler = () => {
    if (orgData.length == 0) {
      setErrororganisation(true);
    }
    if (editPolicy) {
      if (flag == true && file.length === 0) {
        setErrorattachment(true);
      } else if (flag == false && attachment.length === 0) {
        setErrorattachment(true);
      }
    } else {
      if (uploadDataIm.length == 0) {
        setErrorattachment(true);
      }
    }
  };
  const saveBrand = (values) => {
    let datass = values;
    datass.attachment_path = uploadDataIm;
    datass.dept_id = deptData;
    datass.branch_id = branchData;
    datass.sub_dept_id = subDeptData;
    datass.users = userListData;
    datass.organisation = orgData;

    if (!Errorattachment && !Errororganisation) {
      setIsLoading(true);
      BrandService.saveBrand(datass)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
            setUploadDataIm([]);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);

          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const updateBrand = (values) => {
    let datass = values;
    datass.dept_id = deptData;
    datass.branch_id = branchData;
    datass.sub_dept_id = subDeptData;
    datass.users = userListData;
    datass.deleteAttachment = deleteattachment;
    datass.attachment_path = uploadDataIm;
    datass.organisation = orgData;
    datass["orgOldData"] = orgOldData;
    if (!Errorattachment && !Errororganisation) {
      setIsLoading(true);
      BrandService.updateBrand(values, values.id)
        .then((response) => {
          if (response.data.status == true && response.data.code == 200) {
            setdeleteAttachment([]);
            setUploadDataIm([]);
            onAddCallBack(response);
            setIsLoading(false);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.success(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const getBranch = async (paginate) => {
    await BrandService.getBranch(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data2 = {
            value: x.id,
            label: x.name + " (" + x.code + ")",
            organisation:
              x?.organisation_name + " (" + x.organisation_code + ")",
          };
          data.push(data2);
        });
        setBranchData(data);
        setBranchDatacopy(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getBrandingCategory = (paginate) => {
    BrandService.getBrandingCategory(paginate)
      .then((response) => {
        setbrandCategory(response?.data?.dataList?.result);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getOrganisationList = () => {
    getOrganisation(false).then((response) => {
      let organisation = [];
      let organisation2 = [];

      response?.data?.dataList?.result.map((x) => {
        let data = { value: x.id, label: x.name + " (" + x.code + ")" };
        organisation.push(data);
        organisation2.push(data);
      });
      setDubOrgData(organisation2);
      let all = { value: "All", label: "All" };
      organisation.push(all);
      setOrg(organisation);
    });
  };
  const getDepartment = (data) => {
    BrandService.getDepartment(data)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.map((x) => {
          let data2 = { value: x.id, label: x.name + " (" + x.code + ")" };
          data.push(data2);
        });
        setdeptData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const getSubDepartment = (data) => {
    BrandService.getSubDepartment(data)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.map((x) => {
          let data2 = { value: x.id, label: x.name };
          data.push(data2);
        });
        setSubDeptData(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const isBrandExists = (values) => {
    // let data = {};
    // data["title"] = values.title;
    // data["category_id"] = values.category_id;
    // data["org"] = orgData.map((obj) => {
    //   return obj?.value;
    // });
    // BrandService.isBrandExists(data)
    //   .then((res) => {
    //     setIsPolicyExist(res?.data?.dataList?.is_exist);
    if (
      // res?.data?.dataList?.is_exist === false &&
      values?.id !== undefined
    ) {
      updateBrand(values);
      // AuditLog.addAuditLog(dispatch, nameForm);
    }
    if (
      // res?.data?.dataList?.is_exist === false &&
      values?.id === undefined
    ) {
      saveBrand(values);
      // AuditLog.addAuditLog(dispatch, nameForm);
    }
    // })
    // .catch((err) => console.log("Error=>", err));
  };

  const isExistsValue = (value) => {
    // let error = false;
    // if (edit) {
    //   if (editPolicy?.title !== value.title) {
    //     error = true;
    //   }
    //   if (editPolicy?.category_id !== value.category_id) {
    //     error = true;
    //   }
    // }
    // if (error === false && value?.id !== undefined) {
    //   updateBrand(value);
    //   AuditLog.addAuditLog(dispatch, nameForm);
    // } else {
    //   isBrandExists(value);
    // }
    isBrandExists(value);
  };
  // const isBrandExists = (e, fieldName) => {
  //   let data = {};
  //   data[fieldName] = e.target.value;
  //   if (editPolicy?.name == e.target.value) {
  //     setIsPolicyExist(false);
  //     return;
  //   } else {
  //     BrandService.isBrandExists(data)
  //       .then((res) => {
  //         if (e.target.name == "name") {
  //           setIsPolicyExist(res?.data?.dataList?.is_exist);
  //         }
  //       })
  //       .catch((err) => console.log("Error=>", err));
  //   }
  // };
  const pushBranch = (e) => {
    setBranch(e);

    e.map((data) => {
      if (data !== []) {
        const string = data.value;
        loadUserMasterList(1, false, string, "-id");
      }
    });
  };
  const pushUsers = (e) => {
    setUserListData(e);
  };
  const pushDept = (e) => {
    setDept(e);
    // e.map((data) => {
    //     if (data !== []) {
    //         setDept([...deptData, Number(data.value)]);
    //     }
    // })
  };

  const pushSubDept = (e) => {
    setSubDept(e);
  };

  const onProfileChange = async (e, flag) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();
      fileData.append("uploaded_file", data);
      await BrandService.uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    }
    setAttachment([]);
    setErrorattachment(false);
    setflag(true);
    // setAttachment(filename);
    setUploadDataIm(files);
  };

  const deleteAttachment = (id) => {
    let uploadData = [];
    uploadDataIm?.map((x) => {
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    if (edit == false) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          console.log(file[del].name);

          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("brandattachment").value = "";
      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          if (del.attachment_path?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("brandattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("brandattachment").value = "";
        setfiles(obj);
      }
    }
  };
  const policyData = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`org${index}`}
        value={`${item?.id}`}
        label={`${item?.name}`}
      />
    ));
  };
  useEffect(() => {
    loadUserMasterList(1, false, "", "-id");
  }, []);
  const loadUserMasterList = (pageNo, paginate, searchStr, sort_by) => {
    UserService.getUserListByBranch(
      pageNo,
      pageSizeNo,
      sort_by,
      paginate,
      searchStr
    )
      .then((response) => {
        let data = response.data.dataList?.result;
        let users = [];
        data.map((x) => {
          let data2 = {
            value: x.id,
            label: x.first_name + " " + x.last_name + " (" + x.username + ")",
          };
          users.push(data2);
        });
        setUserList(users);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  useEffect(() => {
    if (editPolicy) {
      let datas = editPolicy;
      let branch = [];
      let dept = [];
      let subdept = [];
      let users = [];
      let filename = [];

      datas?.branch?.map((x) => {
        let data1 = { value: x.branch_id, label: x.branch_name };
        branch.push(data1);
      });
      datas?.department?.map((x) => {
        let data1 = { value: x.department_id, label: x.department_name };
        dept.push(data1);
      });
      datas?.sub_department?.map((x) => {
        let data1 = {
          value: x.sub_department_id,
          label: x.sub_department_name,
        };
        subdept.push(data1);
      });
      datas?.user?.map((x) => {
        let data1 = { value: x.user_id, label: x.user_name };
        users.push(data1);
      });
      datas?.attachment?.map((x) => {
        let data1 = {
          name:
            x.attachment_path != null && x.attachment_path?.split("/").pop(),
        };
        filename.push(data1);
      });
      let organisation = [];
      datas?.organisation?.map((x) => {
        let data = { value: x.id, label: x.name + " (" + x.code + ")" };
        organisation.push(data);
      });
      pushOrg(organisation);
      // setfiles(datas?.attachment);

      setOldOrgData(organisation);
      setOrgData(organisation);
      setBranch(branch);
      setDept(dept);
      setSubDept(subdept);
      setUserListData(users);
      setAttachment(datas?.attachment);
      setBrand(editPolicy);
      Object.keys(editPolicy).forEach((item) => {
        brand[item] = editPolicy[item];
      });
    }
  }, [editPolicy]);
  useEffect(() => {
    if (edit == false) {
      getBranch(false);
    }
    getBrandingCategory(false);
  }, []);

  useEffect(() => {
    getDepartment(branchData);
    getOrganisationList();
  }, []);

  useEffect(() => {
    getSubDepartment(deptData);
  }, []);
  const pushOrg = async (e) => {
    await getBranch(false);
    let branches = edit == true ? branchcopy1 : branchcopy;
    let newbranch = [];
    let check = 0;
    let jai = branchcopy1 !== undefined ? branchcopy1 : [];
    console.log("---------", e, branchcopy, jai);
    e.map((x) => {
      branches.map((b) => {
        console.log(
          "-------------",
          x?.label === b?.organisation,
          x?.label,
          b?.organisation
        );
        if (x?.label === b?.organisation) {
          newbranch.push(b);
        }
      });
      console.log(newbranch);
      if (x.label == "All") {
        check++;
      }
    });
    if (check == 0) {
      setBranchData(newbranch);
      newbranch = [];
      setOrgData(e);
      getOrganisationList();
    } else {
      let all = [{ value: "All", label: "All" }];
      setBranchData(branchcopy);
      setOrgData(dublicateOrgData);
      setOrg([]);
    }
    setErrororganisation(false);
  };

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ||
                data2.name.includes(".m4v") ||
                data2.name.includes(".mkv") ||
                data2.name.includes(".mov") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  return (
    <>
      <Formik
        initialValues={brand}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          isExistsValue(values);
          await new Promise((resolve) => setTimeout(resolve, 500));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            size="lg"
            backdrop="static"
            keyboard={false}
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <ToastContainer autoClose={1000} />
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                {edit === true ? "Edit" : "Add"} Branding
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div class="row justify-content-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>Branding Title</label>
                          <span class="text-danger">*</span>
                          <input
                            class="form-control"
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="Enter Branding Title"
                            maxLength={256}
                            onChange={handleChange}
                            l
                            onBlur={(e) => {
                              isBrandExists(e, "name");
                              handleBlur(e);
                            }}
                            value={values.title}
                          />
                          {touched.title && errors.title ? (
                            <div className="small text-danger">
                              {errors.title}
                            </div>
                          ) : isBrandExists === true ? (
                            <div className="small text-danger">
                              Brand Category Name already exists
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>Branding Category</label>
                          <span class="text-danger">*</span>
                          <select
                            className="form-control newbgselect main-pointer"
                            classNamePrefix="select"
                            style={{ width: "334px" }}
                            type="number"
                            name="category_id"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setBrand({
                                ...brand,
                                category_id: e.target.value,
                              });
                            }}
                            value={values.category_id}
                          >
                            <option value="" label="Select Branding Category" />

                            {policyData(brandCategory)}
                          </select>
                          {touched.category_id && errors.category_id ? (
                            <div className="small text-danger">
                              {errors.category_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* <div class="col-md-6">
                                                <div class="form-group innergroup">
                                                    <label>Policy Category<span class="text-danger">*</span></label>
                                                    <Select
                                                        name="category_id"
                                                        isMulti
                                                        options={brandCategory}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        closeMenuOnSelect={false}
                                                        onChange={(e) => {

                                                            pushPolicyCategory(e);
                                                        }}

                                                    // value={policyCategoryData}
                                                    />
                                                    <div className="small text-danger">
                                                        {errors.category_id}
                                                    </div>
                                                </div>
                                            </div> */}
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>Organisation</label>
                          <span class="text-danger">*</span>
                          <Select
                            name="organisation"
                            isMulti
                            options={org}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              pushOrg(e);
                            }}
                            value={orgData}
                          />
                          {Errororganisation && (
                            <div className="small text-danger">
                              *Organizations is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group innergroup main-pointer">
                          <label>
                            Business unit<span class="text-danger"></span>
                          </label>
                          <Select
                            name="branch_id"
                            isMulti
                            options={branch}
                            className="basic-multi-select "
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              pushBranch(e);
                            }}
                            value={branchData}
                          />
                          <div className="small text-danger">
                            {errors.branch_id}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Department<span class="text-danger"></span>
                          </label>
                          <Select
                            name="dept_id"
                            isMulti
                            options={dept}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              pushDept(e);
                            }}
                            value={deptData}
                          />
                          <div className="small text-danger">{errors.dept}</div>
                        </div>
                      </div>
                      {/* <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Sub-Department<span class="text-danger">*</span>
                          </label>
                          <Select
                            name="sub_dept_id"
                            isMulti
                            options={subDept}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(e) => {
                              pushSubDept(e);
                            }}
                            value={subDeptData}
                          />
                          <div className="small text-danger">
                            {errors.sub_dept_id}
                          </div>
                        </div>
                      </div> */}
                      <div class="col-md-6">
                        <div class="form-group innergroup">
                          <label>
                            Users<span class="text-danger"></span>
                          </label>
                          <Select
                            disabled
                            isMulti
                            options={userList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              pushUsers(e);
                            }}
                            value={userListData}
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group innergroup">
                          <label>Description</label>
                          <textarea
                            maxLength={4000}
                            value={values.desc}
                            name="desc"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            class="form-control"
                            rows={100}
                            placeholder="Enter Description"
                          ></textarea>
                          {touched.desc && errors.desc && (
                            <div className="small text-danger">
                              {errors.desc}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup position-relative">
                          <label>
                            Upload File<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control bg-white"
                            disabled
                            value={
                              edit === true
                                ? editPolicy?.logo
                                : uploadDataIm?.logo?.original_name
                            }
                            placeholder="(JPG, PNG , Word, Pdf, ppt, Video)"
                          />
                          <div className="upload-btn-wrapper up-loposition">
                            <button type="button" className="uploadBtn">
                              Browse
                            </button>
                            <input
                              multiple
                              id="brandattachment"
                              type="file"
                              title=""
                              name="attachment_path"
                              accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                              onChange={(e) =>
                                onProfileChange(e, "attachment_path")
                              }
                            />
                          </div>
                          {Errorattachment && (
                            <div className="small text-danger">
                              *Attachment is required
                            </div>
                          )}
                          <p>
                            Selected files{" "}
                            {[file].map((a) => (
                              <span>
                                <br />
                                {a.name}
                              </span>
                            ))}
                          </p>

                          <div>
                            {Object.keys(file).length !== 0 ? (
                              <div className="d-flex">{attachments123()}</div>
                            ) : edit === true && editPolicy ? (
                              <>
                                <div class="d-flex">
                                  {editPolicy &&
                                    attachment?.map((Data) => (
                                      <div class="uploaded-image mr-1">
                                        <i class="" style={{ width: "20px" }}>
                                          <a
                                            href={Data?.attachment_path}
                                            target={"_blank"}
                                            rel="noreferrer"
                                          >
                                            {Data?.attachment_path.includes(
                                              ".pdf"
                                            ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/pdf.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data?.attachment_path.includes(
                                                ".xlsx"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/xlsx.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data?.attachment_path.includes(
                                                ".xls"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/xlsx.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data?.attachment_path.includes(
                                                ".doc"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/docs.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data?.attachment_path.includes(
                                                ".docs"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/docs.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data?.attachment_path.includes(
                                                ".pptx"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/ppt.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : Data.attachment_path.includes(
                                                ".mp4"
                                              ) ||
                                              Data.attachment_path.includes(
                                                ".m4v"
                                              ) ||
                                              Data.attachment_path.includes(
                                                ".mov"
                                              ) ||
                                              Data.attachment_path.includes(
                                                ".mkv"
                                              ) ? (
                                              <img
                                                src={
                                                  "images/policy-icon/video.png"
                                                }
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            ) : (
                                              <img
                                                src={Data.attachment_path}
                                                alt="image"
                                                width="70px"
                                                height="70px"
                                              />
                                            )}
                                          </a>
                                        </i>
                                        <button
                                          type="button"
                                          onClick={(e) => {
                                            deleteAttachment(
                                              Data?.attachment_path?.split(
                                                "/"
                                              )[4]
                                            );
                                          }}
                                          className="btn btn-blanktd text-danger ml-2"
                                        >
                                          <i className="far fa-trash-alt text-danger"></i>
                                        </button>
                                      </div>
                                    ))}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label className="d-block">Status</label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="active"
                          >
                            Active
                            <input
                              type="radio"
                              name="is_active"
                              id="active"
                              value={values.is_active}
                              onChange={() => {
                                setFieldValue("is_active", true);
                              }}
                              checked={values.is_active === true}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Inactive"
                          >
                            Inactive
                            <input
                              type="radio"
                              name="is_active"
                              id="Inactive"
                              value={values.is_active}
                              onChange={(e) => {
                                setFieldValue("is_active", false);
                              }}
                              checked={values.is_active === false}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      {/*                                             
                                            <div class="col-md-12">
                                                <table class="table uploadrecordstab">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Approval Level</th>
                                                            <th>Select Approval</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td class="text-theme">Approval 1</td>
                                                            <td>
                                                                <Select
                                                                    options={options}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                />
                                                            </td>
                                                            <td>
                                                                <button class="btn btn-blanktd text-primary ml-2"><i class="far fa-plus-square"></i></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td class="text-theme">Approval 2</td>
                                                            <td>
                                                                <Select
                                                                    options={options}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                />
                                                            </td>
                                                            <td>
                                                                <button class="btn btn-blanktd text-danger ml-2"><i class="far fa-trash-alt text-danger"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div> */}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              {policyExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;This Entry Already Exists.
                </Alert>
              )}
              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddBrand;
