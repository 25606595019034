import React, { useState, useEffect } from "react";
import { Modal, Breadcrumb, Dropdown, Pagination } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  getFaqCategory,
  getFaqs,
  addFaq,
  updateFaq,
  deleteFaq,
  exportFaqData,
} from "../../service/faq";
import { confirmAlert } from "react-confirm-alert";
import "../../alert/CustomUi.css";
import * as moment from "moment";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  setDisplayLoader,
  selectFinalUrlPermission,
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import ReactQuill from "react-quill";
import { uploadFile } from "../../service/policy";
import ViewFaq from "./ViewFaq";
import ReactPaginate from "react-paginate";

function FaqMaster() {
  let buttonflag = true;
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [Errorattachment, setErrorattachment] = useState(false);
  const [flag, setflag] = useState(false);
  const [deleteattachment, setdeleteAttachment] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [uploadDataIm, setUploadDataIm] = useState([]);
  const [file, setfiles] = useState([]);

  const [pageCount, setpageCount] = useState(1);
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [showForm, setShowForm] = useState(false);
  const [categoryerror, setCategoryError] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [viewFormData, setViewFormData] = useState(null);
  const [faqList, setFaqList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [searchStr, setSearchStr] = useState("");
  const [faq, setFaq] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectCat, setSelectCat] = useState(false);
  const [organizData, setOrganizData] = useState([]);
  const [submitdisable, setsubmitdisable] = useState(false);
  const [sortData, setSortData] = useState({ colName: "-id", sort_by: "-id" });
  const [valueExportData, setValueExportData] = useState({
    id: "",
    status: "",
  });
  const [formObj, setFormObj] = useState({
    category: "",
    status: "Y",
    question: "",
    answer: "",
    // attachment: [],
  });
  const resetForm = () => {
    setFormObj({
      category: "",
      status: "Y",
      question: "",
      answer: "",
      // attachment: [],
    });
    // setAttachment([])
    setError({
      category: false,
      status: false,
      question: false,
      answer: false,
      attachment: false,
    });
  };
  const [error, setError] = useState({
    category: false,
    status: false,
    question: false,
    answer: false,
    attachment: false,
  });
  const [errors, setErrors] = useState({
    isCategory: false,
    isQuestion: false,
    isAnswer: false,
    isStatus: false,
    isAttachment: false,
  });
  const [faqCategory, setFaqCategory] = useState([]);
  const [moduleDataFilter, setModuleDataFilter] = useState({});
  const [attachmentError, setAttachmentError] = useState(true);
  const [uploadData, setUploadData] = useState([]);

  const handleKey = (
    search,
    faq_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate;
    if (
      status !== "" &&
      status !== undefined &&
      status !== null &&
      status !== []
    ) {
      queryParm = queryParm + "&status=" + status;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (faq_id !== "" && faq_id !== undefined && faq_id !== null) {
      queryParm = queryParm + "&faq_id=" + faq_id;
    }
    return queryParm;
  };

  const getExportDetails = (
    search,
    faq_id,
    status,
    pageNo,
    page_size,
    sort_by,
    paginate
  ) => {
    exportFaqData(
      handleKey(search, faq_id, status, pageNo, page_size, sort_by, paginate)
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "faq.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      getFaqData(
        e.target.value.toLowerCase(),
        "",
        "",
        1,
        pageSizeNo,
        "-id",
        true
      );
      setSearchStr(e.target.value);
    }
  };
  const onView = (data) => {
    setViewForm(true);
    setViewFormData(data);
  };
  const onViewClose = () => {
    setViewForm(false);
  };

  const getNextPrevPage = (action) => {
    let activePage = currentPage;
    if (action === 1) {
      if (activePage <= organizData.totalPages - 1) {
        activePage = activePage + 1;
        setCurrentPage(activePage);
      }
    } else {
      if (activePage > 1) {
        activePage = activePage - 1;
        setCurrentPage(activePage);
      }
    }
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getFaqData(searchStr, "", true, activePage, pageSizeNo, "-id", true);
  };
  useEffect(() => {
    getFaqCategories();
    getFaqData(searchStr, "", true, currentPage, pageSizeNo, "-id", true);
  }, [pageSizeNo]);

  const editFaq = (data) => {
    // let curAttachments = data?.attachment.map((obj) => {
    //   return {
    //     name: obj?.attachment.split("/")[1],
    //   };
    // });
    setfiles([]);
    setAttachment(data?.attachment);
    setFormObj((prev) => ({
      ...prev,
      id: data?.id,
      question: data?.question,
      answer: data?.answer,
      category: {
        label: data?.category__category,
        value: data?.category__id,
      },
      status: "Y",
    }));
    setEditMode(true);
    setShowForm(true);
    setSelectCat(true);
  };

  const getFaqData = async (
    search,
    faq_id,
    status,
    page_no,
    page_size,
    sort_by,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    await getFaqs(
      handleKey(search, faq_id, status, page_no, page_size, sort_by, paginate)
    )
      .then((response) => {
        if (response?.status === 200) {
          dispatch(setDisplayLoader("Hide"));
          setFaqList(response?.data?.dataList?.result);
          setOrganizData(response?.data?.dataList?.paginated_data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFaqCategories = async () => {
    await getFaqCategory().then((response) => {
      if (response?.status === 200) {
        let faqCat = response?.data?.dataList?.result.map((obj) => {
          return {
            label: obj.category,
            value: obj.id,
          };
        });
        if (faqCat?.length > 0) {
          setSelectCat(true);
        }
        setFaqCategory(faqCat);
      }
    });
  };

  const clearFilter = () => {
    let ClearData = JSON.parse(JSON.stringify(moduleDataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setModuleDataFilter(ClearData);
    filterClose();
  };

  const filterShow = () => {
    filteropen();
    // getAllEvent(false);
  };

  const renderEventNameOptions = (optionsList) => {
    return optionsList?.map((item, index) => (
      <option
        key={`event${index}`}
        value={`${item?.value}`}
        label={`${item?.label}`}
      />
    ));
  };
  const filterClose = () => {
    filterclose();
  };
  const mainDataFilter = () => {
    setValueExportData({
      ...valueExportData,
      id: moduleDataFilter.event_id,
      status: moduleDataFilter.status,
    });
    const event = moduleDataFilter.event_id;
    const stats = moduleDataFilter.status;
    filterClose();
  };

  const handleChange = (value, name) => {
    switch (name) {
      case "category":
        if (typeof value === "string") {
          setFormObj((prev) => ({
            ...prev,
            category: {
              label: value,
              value: 0,
            },
          }));
          setError((prev) => ({
            ...prev,
            category: false,
          }));
          break;
        } else {
          setFormObj((prev) => ({
            ...prev,
            category: value,
          }));
          setError((prev) => ({
            ...prev,
            category: false,
          }));
          break;
        }
      case "status":
        setFormObj((prev) => ({
          ...prev,
          status: value,
        }));
        setError((prev) => ({
          ...prev,
          status: false,
        }));
        break;
      case "ques":
        if (value == "<p><br></p>") {
          setFormObj((prev) => ({
            ...prev,
            question: "",
          }));
        } else {
          setFormObj((prev) => ({
            ...prev,
            question: value,
          }));
        }

        setError((prev) => ({
          ...prev,
          question: false,
        }));
        break;
      case "ans":
        if (value == "<p><br></p>") {
          setFormObj((prev) => ({
            ...prev,
            answer: "",
          }));
        } else {
          setFormObj((prev) => ({
            ...prev,
            answer: value,
          }));
        }
        setError((prev) => ({
          ...prev,
          answer: false,
        }));
        break;
      default:
        break;
    }
  };

  // const onAttachmentSelect = async (e) => {
  //   let file = e.target.files;
  //   let files = [];
  //   let filename = attachment.length > 0 ? attachment : [];
  //   for (let i = 0; i < file.length; i++) {
  //     let data = e.target.files[i];
  //     const fileData = new FormData();
  //     fileData.append("uploaded_file", data);
  //     await uploadFile(fileData).then((response) => {
  //       let name = { name: data.name };
  //       filename.push(name);
  //       files.push(response.data);
  //     });
  //   }
  //   setAttachment(filename);
  //   setUploadData(files);
  //   setFormObj((prev) => ({
  //     ...prev,
  //     attachment: files,
  //   }));
  //   setError((prev) => ({
  //     ...prev,
  //     attachment : false
  //   }))
  //   setAttachmentError(false);
  // };
  const onProfileChange = async (e) => {
    let file = e.target.files;
    setfiles(file);
    let files = [];
    let filename = [];
    let myAllPic = [];
    for (let i = 0; i < file.length; i++) {
      let data = e.target.files[i];
      let myUrl = URL.createObjectURL(e.target.files[i]);
      myAllPic.push(myUrl);
      const fileData = new FormData();

      fileData.append("uploaded_file", data);
      await uploadFile(fileData).then((response) => {
        let name = { name: data.name };
        filename.push(name);
        files.push(response.data);
      });
    }
    setflag(true);
    setErrorattachment(false);
    setAttachment([]);
    setUploadDataIm(files);
  };
  const deleteAttachment = (id) => {
    console.log(id);
    let uploadData = [];
    uploadDataIm?.map((x) => {
      console.log(x?.original_name !== id, x?.original_name, id);
      if (x?.original_name !== id) {
        uploadData.push(x);
      }
    });
    setUploadDataIm(uploadData);
    console.log(editMode);
    console.log("", id, file);
    if (editMode == false) {
      let obj = {};
      Object.keys(file)
        .filter((del) => {
          console.log(file[del].name);

          if (file[del].name !== id) {
            return true;
          }
        })
        .map((item) => {
          obj[item] = file[item];
        });
      document.getElementById("faqattachment").value = "";

      setfiles(obj);
    } else {
      let deleteId = [];
      if (attachment.length != 0) {
        let data2 = [];
        attachment.filter((del) => {
          console.log(del.attachment?.split("/")[4]);
          if (del.attachment?.split("/")[4] !== id) {
            data2.push(del);
          } else {
            deleteattachment.push(del.id);
          }
        });
        setAttachment(data2);
        document.getElementById("faqattachment").value = "";
      } else {
        let obj = {};
        Object.keys(file)
          .filter((del) => {
            console.log(file[del].name);

            if (file[del].name !== id) {
              return true;
            }
          })
          .map((item) => {
            obj[item] = file[item];
          });
        document.getElementById("faqattachment").value = "";

        setfiles(obj);
      }
    }
  };

  const attachments123 = () => {
    let data = [];
    for (let i = 0; i < Object.keys(file).length; i++) {
      let data2 = file[Object.keys(file)[i]];
      data.push(
        <>
          <div class="uploaded-image mr-1">
            <a
              href={URL.createObjectURL(data2)}
              target={"_blank"}
              rel="noreferrer"
            >
              {data2.name.includes(".pdf") ? (
                <img
                  src={"images/policy-icon/pdf.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xlsx") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".xls") ? (
                <img
                  src={"images/policy-icon/xlsx.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".doc") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".docs") ? (
                <img
                  src={"images/policy-icon/docs.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".pptx") ? (
                <img
                  src={"images/policy-icon/ppt.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : data2.name.includes(".mp4") ||
                data2.name.includes(".m4v") ||
                data2.name.includes(".mkv") ||
                data2.name.includes(".mov") ? (
                <img
                  src={"images/policy-icon/video.png"}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              ) : (
                <img
                  src={URL.createObjectURL(data2)}
                  alt="image"
                  width="70px"
                  height="70px"
                />
              )}
            </a>
            <button
              type="button"
              onClick={() => {
                deleteAttachment(data2.name);
              }}
              className="btn btn-blanktd text-danger ml-2"
            >
              <i className="far fa-trash-alt text-danger"></i>
            </button>
          </div>
        </>
      );
    }
    return data;
  };
  const createFaq = async () => {
    setsubmitdisable(true);
    // category: "",
    // status: "Y",
    // question: "",
    // answer: "",
    // attachment: [],

    if (!editMode) {
      formObj.attachment = uploadDataIm;

      if (
        formObj?.category !== "" &&
        formObj.answer !== "" &&
        formObj.question !== ""
      ) {
        buttonflag = false;
        await addFaq(formObj).then((response) => {
          if (response?.status === 200) {
            buttonflag = true;
            resetForm();
            setUploadDataIm([]);
            setsubmitdisable(false);
            getFaqData(
              searchStr,
              "",
              true,
              currentPage,
              pageSizeNo,
              "-id",
              true
            );
            setShowForm(false);
            toast.success("FAQ Created Successfully.");
          }
        });
      } else {
        if (attachment.length == 0 || uploadDataIm.length == 0) {
          setErrorattachment(true);
        }
        if (formObj.category == "") {
          setError((prev) => ({
            ...prev,
            category: true,
          }));
          setsubmitdisable(false);
        }

        if (formObj.question == "") {
          setError((prev) => ({
            ...prev,
            question: true,
          }));
          setsubmitdisable(false);
        }
        if (formObj.answer == "") {
          setError((prev) => ({
            ...prev,
            answer: true,
          }));
          setsubmitdisable(false);
        }
        // if(formObj.attachment.length == 0 ){
        //   setError((prev) => ({
        //     ...prev,
        //     attachment: true,
        //   }));
        // }
      }
    } else {
      formObj.deleteAttachment = deleteattachment;
      formObj.attachment = uploadDataIm;

      if (
        formObj?.category !== "" &&
        formObj.answer !== "" &&
        formObj.question !== ""
        // && (attachment.length != 0 || file.length != 0)
      ) {
        buttonflag = false;
        await updateFaq(formObj.id, formObj).then((response) => {
          if (response?.status === 200) {
            buttonflag = true;
            setdeleteAttachment([]);
            setUploadDataIm([]);
            setsubmitdisable(false);
            getFaqData(
              searchStr,
              "",
              true,
              currentPage,
              pageSizeNo,
              "-id",
              true
            );
            setShowForm(false);
            toast.success("FAQ Updated Successfully.");
          }
        });
      } else {
        if (flag == true && file.length === 0) {
          setErrorattachment(true);
        } else if (flag == false && attachment.length === 0) {
          setErrorattachment(true);
        }
        if (formObj.category == "") {
          setError((prev) => ({
            ...prev,
            category: true,
          }));
          setsubmitdisable(false);
        }

        if (formObj.question == "") {
          setError((prev) => ({
            ...prev,
            question: true,
          }));
          setsubmitdisable(false);
        }
        if (formObj.answer == "") {
          setError((prev) => ({
            ...prev,
            answer: true,
          }));
          setsubmitdisable(false);
        }
      }
    }
    // }
    // else {
    // error.category = formObj?.category === "" ? true : false;
    // error.answer = formObj.answer == "" ? true : false;
    // error.question = formObj.question == "" ? true : false;
    // setAttachmentError(formObj.attachment.length == 0 ? false : true)
    // }
  };

  const checkCatExists = (event) => {
    let category = event.target.value;
    let isExist = faqCategory.find((obj) => {
      if (obj.label.toLowerCase() === category.toLowerCase()) {
        return true;
      }
    });
    if (isExist !== undefined) {
      toast.warning("Category already exists.");
    }
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete FAQ",
      message: `Are you sure to delete this FAQ ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
      // getFaqData(searchStr, "", true, currentPage, pageSizeNo, "-id", true);
      window.location.reload();
    }
  };

  const deleteRecord = async (id) => {
    await deleteFaq(id)
      .then((response) => {
        if (response?.status === 200) {
          getFaqData(searchStr, "", true, currentPage, pageSizeNo, "-id", true);
          toast.success("Faq Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <>
      <div className="content-wrapper-inner" onClick={() => setSearchStr("")}>
        <ToastContainer autoClose={1000} />
        <div className="innerheadsec">
          <div className="row">
            <div className="col-md-12">
              <h4 className="inner-page-title">FAQ</h4>
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Role Management</Breadcrumb.Item>
                <Breadcrumb.Item active>Event</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
            <div className="col-md-12">
              <div className="border-top mt-2 mt-md-4 mb-3"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip
                    className="bs-tooltip-end tooltip"
                    id="overlay-example"
                  >
                    <div class="tooltip-inner text-left">
                      Search on{" "}
                      <strong>
                        {" "}
                        Question,
                        <br />
                        Answer
                      </strong>
                    </div>
                  </Tooltip>
                }
              >
                <div class="input-group table-search">
                  <span class="input-group-text">
                    <img src="images/search.png" alt="" />
                  </span>
                  <input
                    id="searchbar"
                    type="text"
                    class="form-control"
                    placeholder="Search on Question, Answer ..."
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                  />
                  <span class="input-group-text border-0">
                    {searchStr !== "" ? (
                      <i
                        class="far fa-times-circle cursor-pointer"
                        onClick={() => {
                          closeButtonCallBack();
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="col-lg-8">
              <div className="inline-spacing btn-betweenspaing">
                {finalUrlValue?.D ? (
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" className="mr-2" />
                      Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#">
                            <i className="fas fa-print"></i>Print
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-file-alt"></i>CSV
                          </Dropdown.Item> */}
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() =>
                            getExportDetails(
                              searchStr,
                              "",
                              true,
                              currentPage,
                              pageSizeNo,
                              "-id",
                              true
                            )
                          }
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                      {/* <Dropdown.Item href="#">
                            <i className="far fa-file-pdf"></i>Pdf
                          </Dropdown.Item>
                          <Dropdown.Item href="#">
                            <i className="far fa-copy"></i>Copy
                          </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
                <button
                  onClick={() => {
                    filterShow();
                  }}
                  className="btn btn-secondary-inner"
                >
                  <img src="images/filter.png" alt="" className="mr-3" />
                  Filter
                </button>
                {finalUrlValue?.A ? (
                  <button
                    className="btn btn-primary-inner"
                    onClick={() => {
                      setEditMode(false);
                      setShowForm(true);
                      setfiles([]);
                      setUploadDataIm([]);
                    }}
                  >
                    <img src="images/upload.png" alt="" className="mr-3" />
                    Add New
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-bordered tablecured">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Category</th>
                <th>Last Updated By</th>
                <th>Last Updated At</th>
                <th>Updated At</th>
                {finalUrlValue?.V || finalUrlValue?.E || finalUrlValue?.R ? (
                  <th>Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {faqList.length
                ? faqList?.map((data, index) => (
                    <>
                      <tr key={`faq${index}`}>
                        <td>
                          {data?.question
                            .replace("<p>", "")
                            .replace("</p>", "")
                            .replace("<br>", "")}
                        </td>
                        <td>
                          {data?.answer
                            .replace("<p>", "")
                            .replace("</p>", "")
                            .replace("<br>", "")}
                        </td>

                        <td>{data?.category__category}</td>
                        <td>{data?.created_by}</td>
                        <td>
                          {moment
                            .utc(data?.created_at)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td>
                        <td>
                          {moment
                            .utc(data?.updated_at)
                            .format("DD-MM-YYYY,HH:mm")}
                        </td>
                        <td>
                          {finalUrlValue?.V ? (
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary"
                              onClick={() => {
                                onView(data);
                              }}
                            >
                              <img src="images/eye.png" alt="" />
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.E ? (
                            <button
                              type="button"
                              className="btn btn-blanktd text-primary ml-2"
                              onClick={() => {
                                editFaq(data);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {finalUrlValue?.R ? (
                            <button
                              className="btn btn-blanktd text-danger ml-2"
                              type="button"
                            >
                              <i
                                className="far fa-trash-alt text-danger"
                                onClick={() => {
                                  submit(data?.id);
                                }}
                              ></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </>
                  ))
                : "No Record Found"}
            </tbody>
          </table>
        </div>

        <div className="row">
          <div className="col-sm-5">
            <div className="sortinglist">
              Show
              <select
                className="form-control"
                onChange={(e) => {
                  setPageSizeNo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value={10} label={10}>
                  10
                </option>
                <option value={20} label={20}>
                  20
                </option>
                <option value={30} label={30}>
                  30
                </option>
                <option value={100} label={100}>
                  100
                </option>
              </select>
            </div>
          </div>
          <div className="col-sm-7">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-left"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group innergroup">
            <label>
              Select Category<span className="text-danger"></span>
            </label>
            {/* <Select
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            /> */}

            <select
              className="form-control newbgselect"
              name="organisation_id"
              onChange={(e) => {
                setModuleDataFilter({
                  ...moduleDataFilter,
                  event_id: e.target.value,
                });
              }}
              value={moduleDataFilter?.event_id}
            >
              <option value="" label="Select" />
              {renderEventNameOptions(faqCategory)}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={() => {
              clearFilter();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>

      {showForm ? (
        <>
          <Modal
            show={showForm}
            onHide={() => {
              resetForm();
              setShowForm(false);
              setfiles([]);
              setUploadDataIm([]);
              setAttachment([]);
            }}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title> Add FAQ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group innergroup">
                        <label>
                          Category
                          <span className="text-danger">*</span>
                        </label>
                        <span
                          className="mt-1"
                          style={{ float: "right", cursor: "pointer" }}
                          onClick={() => {
                            setSelectCat(!selectCat);
                          }}
                        >
                          <i className="fas fa-plus-circle"></i> Enter a new
                          category
                        </span>
                        {selectCat ? (
                          <>
                            <Select
                              name="category"
                              options={faqCategory}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              onChange={(event, action) => {
                                handleChange(event, action.name);
                              }}
                              value={formObj.category}
                            />
                            {categoryerror && (
                              <div className="small text-danger">
                                {"*Please add category"}
                              </div>
                            )}
                          </>
                        ) : (
                          <input
                            type="text"
                            name="category"
                            className="form-control"
                            onChange={(event) => {
                              handleChange(
                                event.target.value,
                                event.target.name
                              );
                            }}
                            onBlur={checkCatExists}
                          />
                        )}
                        <div
                          hidden={!error?.category}
                          className="small text-danger"
                        >
                          *Category is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group innergroup ml-5">
                        <label className="d-block mb-2">Status</label>
                        <label class="logCheck d-inline-block mr-4">
                          Active
                          <input
                            type="radio"
                            name="statusupdate2"
                            checked={formObj.status === "Y"}
                            onChange={() => handleChange("Y", "status")}
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="logCheck d-inline-block">
                          Inactive
                          <input
                            type="radio"
                            name="statusupdate2"
                            checked={formObj.status === "N"}
                            onChange={() => handleChange("N", "status")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div
                        class="form-group innergroup"
                        style={{ marginTop: 20 }}
                      >
                        <label className="d-block">
                          Question<span class="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formObj.question}
                          onChange={(event) => {
                            handleChange(event, "ques");
                          }}
                          style={{ height: "80px" }}
                        />
                        <div
                          hidden={!error?.question}
                          className="small text-danger"
                          style={{ marginTop: "70px" }}
                        >
                          *Question is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-4">
                      <div class="form-group innergroup">
                        <label className="d-block">
                          Answer<span class="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formObj.answer}
                          onChange={(event) => {
                            handleChange(event, "ans");
                          }}
                          style={{ height: "80px" }}
                        />
                        <div
                          hidden={!error?.answer}
                          className="small text-danger"
                          style={{ marginTop: "50px" }}
                        >
                          *Answer is required
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <div className="form-group innergroup position-relative">
                        <label>
                          Upload File<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control bg-white"
                          disabled
                          placeholder="(JPG, PNG , Word, Pdf, ppt, Video)"
                        />
                        <div className="upload-btn-wrapper up-loposition">
                          <button type="button" className="uploadBtn">
                            Browse
                          </button>
                          <input
                            multiple
                            id="faqattachment"
                            type="file"
                            title=""
                            name="attachment"
                            accept="image/png,image/jpg,image/jpeg,video/mp4,application/pdf"
                            onChange={(e) => onProfileChange(e, "attachment")}
                          />
                        </div>
                        {Errorattachment && (
                          <div className="small text-danger">
                            *Attachment is required
                          </div>
                        )}
                        <p>
                          Selected files{" "}
                          {[file].map((a) => (
                            <span>
                              <br />
                              {a.name}
                            </span>
                          ))}
                        </p>

                        <div>
                          {Object.keys(file).length !== 0 ? (
                            <div className="d-flex">{attachments123()}</div>
                          ) : editMode === true ? (
                            <>
                              <div class="d-flex">
                                {attachment?.map((Data) => (
                                  <div class="uploaded-image mr-1">
                                    <i class="" style={{ width: "20px" }}>
                                      <a
                                        href={Data?.attachment}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {Data?.attachment.includes(".pdf") ? (
                                          <img
                                            src={"images/policy-icon/pdf.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data?.attachment.includes(
                                            ".xlsx"
                                          ) ? (
                                          <img
                                            src={"images/policy-icon/xlsx.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data?.attachment.includes(
                                            ".xls"
                                          ) ? (
                                          <img
                                            src={"images/policy-icon/xlsx.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data?.attachment.includes(
                                            ".doc"
                                          ) ? (
                                          <img
                                            src={"images/policy-icon/docs.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data?.attachment.includes(
                                            ".docs"
                                          ) ? (
                                          <img
                                            src={"images/policy-icon/docs.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data?.attachment.includes(
                                            ".pptx"
                                          ) ? (
                                          <img
                                            src={"images/policy-icon/ppt.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : Data.attachment.includes(".mp4") ||
                                          Data.attachment.includes(".m4v") ||
                                          Data.attachment.includes(".mov") ||
                                          Data.attachment.includes(".mkv") ? (
                                          <img
                                            src={"images/policy-icon/video.png"}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        ) : (
                                          <img
                                            src={Data.attachment}
                                            alt="image"
                                            width="70px"
                                            height="70px"
                                          />
                                        )}
                                      </a>
                                    </i>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        deleteAttachment(
                                          Data?.attachment?.split("/")[4]
                                        );
                                      }}
                                      className="btn btn-blanktd text-danger ml-2"
                                    >
                                      <i className="far fa-trash-alt text-danger"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ marginTop: "80px" }}>
              <button
                class="btn btn-outline-danger"
                onClick={() => {
                  resetForm();
                  setShowForm(false);
                }}
              >
                Cancel
              </button>

              <button
                class="btn btn-primary-inner bpi-main"
                onClick={createFaq}
                disabled={!buttonflag}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        ""
      )}
      {viewForm ? (
        <>
          <ViewFaq
            viewForm={viewForm}
            onViewClose={onViewClose}
            viewFormData={viewFormData}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default FaqMaster;
