import axios from "../utils/axios";
// import { AUTH_BASE_URL_SURVEY } from "../constants/URL";
import { AUTH_BASE_URL } from "../constants/URL";

export const getCapexExport = async (queryParm) => {
    return axios({
      url: `${AUTH_BASE_URL}/mdo/export?${queryParm}`,
      method: "GET",
      responseType: "blob",
    });
  };


  export const getCapex = async (queryParm) => {
    return axios.get(`${AUTH_BASE_URL}/mdo?${queryParm}`);
  };

  export const postCapex = async (pData) => {
    return axios.post(`${AUTH_BASE_URL}/mdo`,pData);
  };

  export const updateCapex = async (id,uData) => {
    return axios.put(`${AUTH_BASE_URL}/mdo/${id}`, uData);
  };

  
  
export const deleteCapex = async (id) => {
  return axios({
    url: `${AUTH_BASE_URL}/mdo/${id}`,
    method: "DELETE",
    data: id,
  });
};


export const isMdoExist = async (pData) => {
  return axios.post(`${AUTH_BASE_URL}/mdo/exists`,pData);
};