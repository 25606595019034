import { useEffect, useState } from "react";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ModularTable from "../ModularTable";
import {
  getAllDates,
  getCategoryDropdown,
  getCropsByCatId,
  getLastCropPattern,
  postChannelSize,
  postCropPatternSize,
  putCropPatternSize,
} from "../../../../service/AgriPortalService/CropPatternService";
import {
  getOverallStrategy,
  getCurrentFY,
  getLastOverallStrategy,
  getOverallDropdown,
  getOverallMonthDropdown,
  postOverallStrategy,
  putOverallStrategy,
  getOverallStrategyApprovalLogs,
  postOverallAction,
} from "../../../../service/AgriPortalService/OverallStrategyService";
import styles from "./Table.module.css";
import { getPermissions } from "../../../../service/AgriPortalService/PermissionService";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { getCurrentFy } from "../../../../service/AgriPortalService/CropScenarioService";
import moment from "moment";
import { getZRT } from "../../../../service/AgriPortalService/CommonService";
import { useDispatch } from "react-redux";
import { setDisplayLoader } from "../../../redux/piDataStore";

const Root = (props) => {
  //HOOKS

  const [dropdownProducts, setDropdownProducts] = useState({});

  const [rawData, setRawData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([]);
  const [tableRowReRender, setTableRowReRender] = useState(false);
  const [pageId, setPageId] = useState(-1);
  const [remark, setRemark] = useState("");
  const [currentfy, setCurrentfy] = useState({});
  const [updatedTableData, setUpdatedTableData] = useState([[]]);
  const [loader, setLoader] = useState(true);
  const [months, setMonths] = useState([]);
  const [objective, setObjectives] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [mode, setMode] = useState();

  const [approvalList, setApprovalList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // show zone territory and region
  useEffect(() => {
    if (!mode) return;
    if (mode == "create") {
      getZRT()
        .then((res) => {
          setLocationData(res?.data?.dataList);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getOverallStrategy()
        .then((res) => {
          let index = res.data.dataList.findIndex((el) => el.id == pageId);
          setLocationData(res?.data?.dataList[index]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [mode]);

  useEffect(() => {
    getCurrentFy()
      .then((res) => {
        setCurrentfy(res.data.dataList);

        setLoader(false);
        // console.log(res,"Result")
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }, []);

  // useEffect(() => console.log("THD", tableHeadData), [tableHeadData]);

  //Called to set table data everytime an value is updated
  const onTableDataChange = (data) => {
    setUpdatedTableData(data);
  };

  //To preload dropdown player values
  useEffect(() => {
    getOverallMonthDropdown()
      .then((res) => {
        setMonths(res.data.dataList);
      })
      .catch((err) => console.log(err));

    getOverallDropdown()
      .then((res) => {
        setObjectives(res.data.dataList);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(months);
  }, [months, months.length]);

  useEffect(() => {
    if (!mode) return;
    let query = "/mystrategy/overallstrategy";
    getPermissions(query)
      .then((res) => {
        let modeType = "";
        if (mode == "approve") modeType = "V";
        if (mode == "create") modeType = "A";
        if (mode == "view") modeType = "V";
        if (mode == "edit") modeType = "E";
        const index = res.data.dataList.findIndex((e) => e.code == modeType);
        if (index == -1) {
          toast.error(`You are not authorized to ${mode} this page`);
          setTimeout(() => {
            navigate("/mystrategy/overallstrategy");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  }, [mode]);

  //To fetch past data
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "?strategy_trans_id=" + params.get("id");
    }

    getLastOverallStrategy(query)
      .then((res) => {
        setRawData(res.data.dataList);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code == 409) {
          toast.error("Overall Strategy already exists!");
          setTimeout(() => {
            navigate("/mystrategy/overallstrategy");
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMode(params.get("mode"));
    setPageId(params.get("id"));

    let query = "";
    if (params.get("mode") != "create") {
      query = "?overall_strategy_id=" + params.get("id");
    }

    if (params.get("mode") != "create") {
      getOverallStrategyApprovalLogs(query)
        .then((res) => setApprovalList(res.data.dataList))
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (mode == "create") {
      let headData;
      headData = [
        {
          name: "Objective",
          type: "D",
          isEditable: true,
        },

        {
          name: "Strategy",
          type: "T",
          isEditable: true,
        },
        {
          name: "Milestone",
          type: "T",
          isEditable: true,
        },
        {
          name: "Month",
          type: "D",
          isEditable: true,
        },
      ];
      setTableHeadData(headData);
      let rowData = [];
      rowData.push([
        {
          value: objective?.length > 0 ? objective?.map((el) => el.name) : [],
          meta: {
            raw: objective,
          },
          selected: objective[0]?.name,
        },
        {
          value: [""],
          meta: {},
        },
        {
          value: [""],
          meta: {},
        },
        {
          value: months?.length > 0 ? months?.map((el) => el.name) : [],
          meta: {
            raw: months,
          },
          selected: months[0]?.name,
        },
      ]);

      setTableData(rowData);
    }
  }, [mode, objective, months, months.length]);
  //Set table data after past data is fetched
  useEffect(() => {
    if (mode == "create") return;
    if (Object.keys(objective)?.length == 0) return;

    setTableHeadData((state) => {
      if (!rawData) return [];
      let headData;

      if (mode != "view" && mode != "approve")
        headData = [
          {
            name: "Objective",
            type: "D",
            isEditable: true,
          },

          {
            name: "Strategy",
            type: "T",
            isEditable: true,
          },
          {
            name: "Milestone",
            type: "T",
            isEditable: true,
          },
          {
            name: "Month",
            type: "D",
            isEditable: true,
          },
        ];

      if (mode == "view" || mode == "approve")
        headData = [
          {
            name: "Objective",
            type: "D",
            isEditable: false,
          },

          {
            name: "Strategy",
            type: "T",
            isEditable: false,
          },
          {
            name: "Milestone",
            type: "T",
            isEditable: false,
          },
          {
            name: "Month",
            type: "D",
            isEditable: false,
          },
        ];

      return headData;
    });
    let rawStrategyData = rawData?.strategy_trans_data;
    const newTableData = [];
    for (let i = 0; i < rawStrategyData?.length; i++) {
      newTableData.push([
        {
          value: objective?.length > 0 ? objective?.map((el) => el.name) : [],
          meta: {
            raw: objective,
          },
          selected: rawStrategyData[i]?.objective_name,
        },
        {
          value: [rawStrategyData[i]?.strategy],
          meta: {},
        },
        {
          value: [rawStrategyData[i]?.milestone],
          meta: {},
        },
        {
          value: months?.length > 0 ? months?.map((el) => el.name) : [],
          meta: {
            raw: months,
          },
          selected: rawStrategyData[i]?.month_name,
        },
      ]);
    }
    setTableData(newTableData);
  }, [rawData, objective, mode, months, months.length]);

  const addRow = () => {
    const tableRowData = tableData;
    tableRowData.push([
      {
        value: objective?.length > 0 ? objective?.map((el) => el.name) : [],
        meta: {
          raw: objective,
        },
        selected: objective[0]?.name,
      },
      {
        value: [""],
        meta: {},
      },
      {
        value: [""],
        meta: {},
      },
      {
        value: months?.length > 0 ? months?.map((el) => el.name) : [],
        meta: {
          raw: months,
        },
        selected: months[0]?.name,
      },
    ]);
    setTableData(tableRowData);
    setTableRowReRender(!tableRowReRender);
  };

  const deleteRow = (index) => {
    const tableRowData = tableData;
    tableRowData.splice(index, 1);
    setTableData(tableRowData);
    setTableRowReRender(!tableRowReRender);
  };

  //Called to format data for output body
  const convertCategoryData = (data) => {
    const ans = [];

    if (!data) return;

    data.forEach((row) => {
      let objective_id = -1;
      objective_id = row[0].meta.raw.find((el) => el.name == row[0].selected);
      objective_id = objective_id?.id;

      let month_id = -1;
      month_id = row[3].meta.raw.find((el) => el.name == row[3].selected);
      month_id = month_id?.id;

      ans.push({
        objective_id: objective_id,
        month_id: month_id,
        strategy: row[1].value[0],

        milestone: row[2].value[0],
      });
    });
    return ans;
  };

  //EVENT HANDLERS
  const onSaveHandler = (event) => {
    var cfy = -1;
    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;
    if (mode == "create") {
      getCurrentFY()
        .then((res) => {
          let body = {
            cfy: res.data.dataList.fy,
            territory_id,
            status: "D",
          };
          let strategy_trans_data = convertCategoryData(updatedTableData);
          body.strategy_trans_data = strategy_trans_data;

          postOverallStrategy(body)
            .then((res) => {
              toast.success("Save Succesfull!");
              setTimeout(() => {
                navigate("/mystrategy/overallstrategy");
              }, 1000);
            })
            .catch((err) => {
              toast.error("Save Failed!");
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    cfy = rawData?.cfy.split("-")[1];
    if (cfy?.length !== 4) return;

    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);

    // if(mode=="edit")
    cfy = rawData?.cfy;
    const strategy_trans_data = convertCategoryData(updatedTableData);
    let body = {
      cfy,
      territory_id,
      status: "D",
    };
    if (!strategy_trans_data) return;
    body.strategy_trans_data = strategy_trans_data;

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putOverallStrategy(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/overallstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          console.log(err);
        });
    }
  };

  const confirmDialog = (type) => {
    confirmAlert({
      title: type == "P" ? "Are you sure?" : "Save as Draft!",
      message:
        type == "P"
          ? `You won't be able to edit it later, confirm submit.`
          : `This is a draft, You can edit it anytime.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            type == "P" ? onSubmitHandler() : onSaveHandler();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const onSubmitHandler = (event) => {
    var cfy = -1;
    let territory_id = JSON.parse(localStorage.getItem("territory"));
    if (!territory_id) return;
    territory_id = territory_id?.id;
    if (mode == "create") {
      getCurrentFY()
        .then((res) => {
          let body = {
            cfy: res.data.dataList.fy,
            territory_id,
            status: "P",
          };
          let strategy_trans_data = convertCategoryData(updatedTableData);
          body.strategy_trans_data = strategy_trans_data;

          postOverallStrategy(body)
            .then((res) => {
              toast.success("Submit Succesfull!");

              setTimeout(() => {
                navigate("/mystrategy/overallstrategy");
              }, 1000);
            })
            .catch((err) => {
              toast.error("Submit Failed!");
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    cfy = rawData?.cfy.split("-")[1];
    if (cfy?.length !== 4) return;

    // if(mode=="create")
    // cfy=cfy+"-"+cfy[0]+cfy[1]+cfy[2]+(parseInt(cfy[3])+1);

    // if(mode=="edit")
    cfy = rawData?.cfy;
    const strategy_trans_data = convertCategoryData(updatedTableData);
    let body = {
      cfy,
      territory_id,
      status: "P",
    };
    if (!strategy_trans_data) return;
    body.strategy_trans_data = strategy_trans_data;

    if (mode == "edit") {
      const params = new URLSearchParams(window.location.search);
      body.id = params.get("id");
      putOverallStrategy(body)
        .then((res) => {
          toast.success("Save Succesfull!");
          setTimeout(() => {
            navigate("/mystrategy/overallstrategy");
          }, 1000);
        })
        .catch((err) => {
          toast.error("Save Failed!");
          console.log(err);
        });
    }
  };

  const onCancelHandler = () => {
    return navigate("/mystrategy/overallstrategy");
  };

  const onApprovalCancelHandler = () => {
    return navigate("/dashboard");
  };

  // approval/reject validate
  const validate = (value) => {
    if (remark.length == 0) {
      toast.error("Please Enter Remark");
      return;
    }
    let data = {
      overall_strategy_id: pageId,
      status: `${value === "approve" ? "A" : "R"}`,
      remarks: remark,
    };
    postOverallAction(data)
      .then((res) => {
        toast.success(
          `${value === "approve" ? "Approved" : "Rejected"} succesfull!`
        );
        setTimeout(() => {
          navigate(`/dashboard`);
        }, 1000);
      })
      .catch((err) => {
        toast.error(`${value === "approve" ? "Approved" : "Rejected"} failed!`);
        console.log(err);
      });
  };
  (function () {
    if (loader) {
      dispatch(setDisplayLoader("Display"));
    } else {
      dispatch(setDisplayLoader("Hide"));
    }
  })();

  return (
    <div className={styles["agri-portal-container"]}>
      <ToastContainer autoClose={1000} />
      <div className={styles["agri-portal-content"]}>
        {/* <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crop Pattern</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                  </Breadcrumb> */}

        <div
          className={styles["heading"]}
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <h4 class="inner-page-title" style={{ margin: "0" }}>
            Overall Strategy
          </h4>
          {/* <p
            style={{
              color: "#0195D4",
              padding: "4px 8px",
              border: "1px solid #0195D4",
              borderRadius: "4px",
              margin: "0",
            }}
          >
            RS IN LAKHS
          </p> */}
          <div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>CURRENT FY</b>
              </div>
              <div className={styles["valuebox"]}>{currentfy?.fy}</div>
            </div>
          </div>
        </div>
        <div className="col-md-12 my-2">
          <div className="border-top md-3"> </div>
        </div>
        <div
          className={styles["heading"]}
          style={{
            paddingTop: "0",
            paddingBottom: "15px",
            // justifyContent: "start",
          }}
        >
          <div
            className={styles["agri-portal-label"]}
            style={{ display: "flex" }}
          >
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>ZONE</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.zone_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>REGION</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.region_name}
              </div>
            </div>
            <div
              style={{
                zIndex: 0,
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <div className={styles["agri-portal-label"]}>
                <b>TERRITORY</b>
              </div>
              <div className={styles["valuebox"]}>
                {locationData?.territory_name}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["table_contain"]}>
          <div className={styles["agri-portal-table"]}>
            <ModularTable
              columns={tableHeadData}
              data={tableData}
              onTableDataChange={onTableDataChange}
              addRow={addRow}
              deleteRow={deleteRow}
              tableRowReRender={tableRowReRender}
              mode={mode}
            />
          </div>
        </div>
        {approvalList?.approvals && approvalList?.approvals.length > 0 && (
          <div className={styles["table_contain"]}>
            <div className={styles["agri-portal-table"]}>
              <div
                class="table-responsive"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <table
                  class="table table-striped table-bordered tablecured"
                  style={{ maxHeight: "200px" }}
                >
                  <thead style={{ position: "sticky", top: 0 }}>
                    <tr style={{ textAlign: "center" }}>
                      <th>Action At</th>
                      <th>Action By</th>
                      <th>Action User</th>
                      <th>Action Status</th>
                      <th>Action Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalList?.approvals &&
                      approvalList?.approvals.map((el, i) => {
                        let status;

                        const findPendingIndex =
                          approvalList.approvals.findIndex(
                            (ele) => ele.status == "P" && ele.discarded == false
                          );

                        if (el.status == "D") status = "Saved";
                        else if (el.status == "P" && el.discarded == true)
                          status = "Auto Discarded";
                        else if (el.status == "P") status = "Pending";
                        else if (el.status == "A") status = "Approved";
                        else if (el.status == "R") status = "Rejected";
                        else if (el.status == "RP") status = "Auto Rejected";

                        return (
                          <tr>
                            <td>
                              {status == "Pending"
                                ? "-"
                                : moment(el.action_at)
                                    .utc()
                                    .format("DD-MM-YYYY HH:mm:ss")}
                            </td>
                            <td>{el.action_by_id}</td>
                            <td>{el.action_user_name}</td>
                            <td>
                              {findPendingIndex == -1
                                ? status
                                : i > findPendingIndex
                                ? "-"
                                : status}
                            </td>
                            <td>{el.remarks}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className={styles["ctas"]}>
          {mode != "view" && mode != "approve" && (
            <div className={styles["ctas_left"]}>
              <div
                class="btn btn-success"
                onClick={() => {
                  confirmDialog("P");
                }}
              >
                Submit
              </div>
              <div
                class="btn btn-primary-inner"
                onClick={() => {
                  confirmDialog("D");
                }}
              >
                Save
              </div>
            </div>
          )}
          {mode == "approve" || approvalList?.for_approval ? (
            <div>
              <div className="col-md-12">
                <div className="form-group innergroup">
                  <label>
                    Add Comments <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="additional_comments"
                    placeholder="Enter Comments here..."
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("approve")}
                >
                  Approve
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => {
                    onApprovalCancelHandler();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="submit"
                  onClick={() => validate("reject")}
                >
                  Decline
                </button>
              </div>
            </div>
          ) : (
            <div className={styles["ctas_right"]}>
              <div class="btn btn-primary-inner" onClick={onCancelHandler}>
                Back
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Root;
