import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { getUserRmList } from "../../service/jv_service";
import { addses, updateses } from "../../service/sesService";
import { toast } from "react-toastify";

function ServiceApproverFrom(props) {
  const {
    show,
    close,
    edit,
    getList,
    editState,
    resetEditState,
    poOrgList,
    pGroupList,
    plantlist,
    releaseStrat,
  } = props;

  const navigate = useNavigate();
  const [sesData, setsesData] = useState({
    RequestorSAPID: {},
    DocumentType: {},
    Plant: [],
    PurchasingOrganization: [],
    ReleaseStrategy: [],
    PurchasingGroup: [],
    AccountAssignment: { value: "" },
    ApproverID_1: {},
  });
  const [sesError, setsesError] = useState({
    RequestorSAPID: false,
    DocumentType: false,
    Plant: false,
    PurchasingOrganization: false,
    ReleaseStrategy: false,
    PurchasingGroup: false,
    AccountAssignment: false,
    ApproverID_1: false,
  });

  const [oldPlant, setoldPlant] = useState([]);
  const [oldPurchasingGroup, setoldPurchasingGroup] = useState([]);
  const [oldPurchasingOrganization, setoldPurchasingOrganization] = useState(
    []
  );
  const [oldReleaseStrategy, setoldReleaseStrategy] = useState([]);
  const [userlist, setuserlist] = useState([]);

  const options = [
    { value: "", label: "-select-" },
    { value: "N", label: "N" },
    // { value: "B", label: "B" },
    // { value: "C", label: "C" },
    // { value: "D", label: "D" },
    // { value: "E", label: "E" },
  ];
  const options1 = [
    { value: "CRE", label: "CRE-Agro" },
    { value: "HR", label: "HR-Human Resources" },
    { value: "NB", label: "NB-UNKNOWN" },
    { value: "JA", label: "JA-UNKNOWN" },
    { value: "PH - PI Health Sciences", label: "PH - PI Health Sciences" },
  ];
  const getUserList = () => {
    getUserRmList(false)
      .then((response) => {
        let plant = [];
        response?.data?.dataList?.result?.map((x) => {
          let data = {
            value: x?.id,
            label: x?.first_name + " " + x?.last_name + "(" + x?.username + ")",
          };
          plant.push(data);
        });
        setuserlist(plant);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    if (editState) {
      Object.keys(editState).forEach((item) => {
        sesData[item] = editState[item];
      });
      setoldPlant(editState?.Plant);
      setoldPurchasingGroup(editState?.PurchasingGroup);
      setoldPurchasingOrganization(editState?.PurchasingOrganization);
      setoldReleaseStrategy(editState?.ReleaseStrategy);
    }
  }, [editState]);
  useEffect(() => {
    if (edit == false) {
      setsesData({
        RequestorSAPID: { value: 0, label: "Select..." },
        DocumentType: { value: 0, label: "Select..." },
        Plant: [],
        PurchasingOrganization: [],
        ReleaseStrategy: [],
        PurchasingGroup: [],
        AccountAssignment: { value: "", label: "Select..." },
        ApproverID_1: { value: 0, label: "Select..." },
      });
    }
    getUserList();
  }, []);
  const handleChange = (event, name) => {
    let type = name !== undefined ? name : event.target.name;
    switch (type) {
      case "RequestorSAPID":
        setsesData((prev) => ({
          ...prev,
          RequestorSAPID: event,
        }));
        setsesError((prev) => ({
          ...prev,
          RequestorSAPID: false,
        }));
        break;
      case "DocumentType":
        setsesData((prev) => ({
          ...prev,
          DocumentType: event,
        }));
        setsesError((prev) => ({
          ...prev,
          DocumentType: false,
        }));
        break;
      case "Plant":
        setsesData((prev) => ({
          ...prev,
          Plant: event,
        }));
        setsesError((prev) => ({
          ...prev,
          Plant: false,
        }));
        break;
      case "PurchasingOrganization":
        setsesData((prev) => ({
          ...prev,
          PurchasingOrganization: event,
        }));
        setsesError((prev) => ({
          ...prev,
          PurchasingOrganization: false,
        }));
        break;
      case "PurchasingGroup":
        setsesData((prev) => ({
          ...prev,
          PurchasingGroup: event,
        }));
        setsesError((prev) => ({
          ...prev,
          PurchasingGroup: false,
        }));
        break;
      case "ReleaseStrategy":
        setsesData((prev) => ({
          ...prev,
          ReleaseStrategy: event,
        }));
        setsesError((prev) => ({
          ...prev,
          ReleaseStrategy: false,
        }));
        break;
      case "ApproverID_1":
        setsesData((prev) => ({
          ...prev,
          ApproverID_1: event,
        }));
        setsesError((prev) => ({
          ...prev,
          ApproverID_1: false,
        }));
        break;
      case "AccountAssignment":
        setsesData((prev) => ({
          ...prev,
          AccountAssignment: event,
        }));
        setsesError((prev) => ({
          ...prev,
          AccountAssignment: false,
        }));
        break;
      default:
        break;
    }
  };
  const resetForm = () => {
    setsesData({
      RequestorSAPID: {},
      DocumentType: {},
      Plant: [],
      PurchasingOrganization: [],
      ReleaseStrategy: [],
      PurchasingGroup: [],
      AccountAssignment: { value: "" },
      ApproverID_1: {},
    });
    resetEditState();
  };
  const submit = () => {
    if (editState.length === 0) {
      addses(sesData)
        .then((response) => {
          toast.success(response.data.message);
          getList();
          close();
          resetForm();
        })
        .catch((error) => {
          toast.error("Some error occoured please try again later");

          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    } else {
      sesData.oldPlant = oldPlant;
      sesData.oldPurchasingOrganization = oldPurchasingOrganization;
      sesData.oldReleaseStrategy = oldReleaseStrategy;
      sesData.oldPurchasingGroup = oldPurchasingGroup;
      updateses(sesData)
        .then((response) => {
          toast.success(response.data.message);

          getList();
          close();
          resetForm();
        })
        .catch((error) => {
          toast.error("Some error occoured please try again later");
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Approver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Requestor ID<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="RequestorSAPID"
                      options={userlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={sesData?.RequestorSAPID}
                      placeholder="Enter Requestor SAP ID"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.RequestorSAPID}
                      className="small text-danger"
                    >
                      Kindly select RequestorSAPID
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Document Type<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="DocumentType"
                      options={options1}
                      className="basic-multi-select"
                      value={sesData?.DocumentType}
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.DocumentType}
                      className="small text-danger"
                    >
                      Kindly select DocumentType
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Plant<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="Plant"
                      isMulti
                      value={sesData?.Plant}
                      options={plantlist}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.Plant}
                      className="small text-danger"
                    >
                      Kindly select Plant
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Purchasing Organization<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="PurchasingOrganization"
                      value={sesData?.PurchasingOrganization}
                      isMulti
                      options={poOrgList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.PurchasingOrganization}
                      className="small text-danger"
                    >
                      Kindly select PurchasingOrganization
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Purchasing Group<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="PurchasingGroup"
                      value={sesData?.PurchasingGroup}
                      isMulti
                      options={pGroupList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.PurchasingGroup}
                      className="small text-danger"
                    >
                      Kindly select PurchasingGroup
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Release Strategy<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="ReleaseStrategy"
                      isMulti
                      value={sesData?.ReleaseStrategy}
                      options={releaseStrat}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.ReleaseStrategy}
                      className="small text-danger"
                    >
                      Kindly select ReleaseStrategy
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>
                      Approver ID 1<span class="text-danger">*</span>
                    </label>
                    <Select
                      name="ApproverID_1"
                      options={userlist}
                      value={sesData?.ApproverID_1}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Press enter to search"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.ApproverID_1}
                      className="small text-danger"
                    >
                      Kindly select ApproverID_1
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group innergroup">
                    <label>Account Assignment</label>
                    <Select
                      name="AccountAssignment"
                      value={sesData?.AccountAssignment}
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(newValue, action) => {
                        handleChange(newValue, action.name);
                      }}
                    />
                    <div
                      hidden={!sesError?.AccountAssignment}
                      className="small text-danger"
                    >
                      Kindly select AccountAssignment
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button class="btn btn-outline-danger" onClick={close}>
            Cancel
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            type="submit"
            onClick={() => submit()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceApproverFrom;
